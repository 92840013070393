import React from 'react';
import { Button } from 'react-covideo-common';
import { MdDone } from 'react-icons/md';
import {
  ActiveHeader,
  ActiveOptionContainer,
  AddonFooter,
  Feature,
  FeatureIcon,
  FeatureList,
  FeatureText,
  OptionContainer,
  Options,
  PageContainer,
  PageDescription,
  PageHeader,
} from '../components/PageElements';

enum optionSelections {
  None = 0,
  Videos50 = 1,
  Videos200 = 2,
  Videos1k = 3,
}

export const ManageAnnotations = () => {
  const [selectedOption, setSelectedOption] = React.useState(
    optionSelections.None
  );

  const videos50 = (
    <>
      <label>
        <b>50 videos</b>
      </label>
      <label>$7/mo</label>
      <Button
        text={
          selectedOption === optionSelections.Videos50
            ? 'Deactivate'
            : selectedOption === optionSelections.None
              ? 'Activate'
              : 'Downgrade'
        }
        variant={
          selectedOption === optionSelections.Videos50
            ? 'red'
            : selectedOption === optionSelections.None
              ? 'primary'
              : 'ghost'
        }
        onClick={() => {
          if (selectedOption === optionSelections.Videos50)
            setSelectedOption(optionSelections.None);
          else setSelectedOption(optionSelections.Videos50);
        }}
      />
    </>
  );

  const videos200 = (
    <>
      <label>
        <b>200 videos</b>
      </label>
      <label>$15/mo</label>
      <Button
        text={
          selectedOption === optionSelections.None
            ? 'Activate'
            : selectedOption === optionSelections.Videos50
              ? 'Upgrade'
              : selectedOption == optionSelections.Videos200
                ? 'Deactivate'
                : 'Downgrade'
        }
        variant={
          selectedOption === optionSelections.Videos200
            ? 'red'
            : selectedOption == optionSelections.Videos1k
              ? 'ghost'
              : 'primary'
        }
        onClick={() => {
          if (selectedOption === optionSelections.Videos200)
            setSelectedOption(optionSelections.None);
          else setSelectedOption(optionSelections.Videos200);
        }}
      />
    </>
  );

  const videos1k = (
    <>
      <label>
        <b>1,000 videos</b>
      </label>
      <label>$60/mo</label>
      <Button
        text={
          selectedOption === optionSelections.None
            ? 'Activate'
            : selectedOption === optionSelections.Videos50 ||
                selectedOption === optionSelections.Videos200
              ? 'Upgrade'
              : 'Deactivate'
        }
        variant={
          selectedOption === optionSelections.Videos1k ? 'red' : 'primary'
        }
        onClick={() => {
          if (selectedOption === optionSelections.Videos1k)
            setSelectedOption(optionSelections.None);
          else setSelectedOption(optionSelections.Videos1k);
        }}
      />
    </>
  );

  return (
    <PageContainer>
      <PageHeader>Annotations</PageHeader>
      <PageDescription>
        Polish your videos by adding your contact info and company logo. Include
        hyperlinked CTAs like email me or schedule a call on sales videos to
        drive engagement and increase responses.
      </PageDescription>
      <Options>
        {(selectedOption === optionSelections.Videos50 && (
          <ActiveOptionContainer>
            <ActiveHeader>Active</ActiveHeader>
            {videos50}
          </ActiveOptionContainer>
        )) || <OptionContainer>{videos50}</OptionContainer>}
        {(selectedOption === optionSelections.Videos200 && (
          <ActiveOptionContainer>
            <ActiveHeader>Active</ActiveHeader>
            {videos200}
          </ActiveOptionContainer>
        )) || <OptionContainer>{videos200}</OptionContainer>}
        {(selectedOption === optionSelections.Videos1k && (
          <ActiveOptionContainer>
            <ActiveHeader>Active</ActiveHeader>
            {videos1k}
          </ActiveOptionContainer>
        )) || <OptionContainer>{videos1k}</OptionContainer>}
      </Options>
      <FeatureList>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Protect Privacy: Record, send and track messages from your
              personal device without using your personal phone number
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Keep it Professional: Monitor team outreach and video statistics
              from your Covideo dashboard
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Prevent Dropped Leads: Never miss out on leads or conversations,
              even if there’s staff turnover
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize='28px' />
          </FeatureIcon>
          <FeatureText>
            <label>
              Comply with Policy: Centralize dealership communication and adhere
              to messaging compliance policies
            </label>
          </FeatureText>
        </Feature>
      </FeatureList>
      <AddonFooter>
        Have a question? Contact your account manager, contact us at
        support@covideo.com or give us a call at 1.800.306.1445.
      </AddonFooter>
    </PageContainer>
  );
};
