import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { theme } from 'lib/style';
import { useFormikContext } from 'formik';
import { FormikMeetingsValues } from '../../pages/MeetingDetails';
import { useIsMutating } from 'react-query';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';

const Content = styled.div`
  width: 600px;
  line-height: 28px;
  color: #272a32;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
};

export const SendEmailNotificationsMeetingModal = (props: Props) => {
  const { handleModalClose } = props;
  const { setFieldValue, submitForm } =
    useFormikContext<FormikMeetingsValues>();
  const isSubmitting = useIsMutating();

  return (
    <NewModal
      headerText='Live Meeting Update'
      headerTextStyle={{
        fontSize: theme.fontSizes.lg,
        lineHeight: '24px',
        color: theme.palette.themeDark,
      }}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        setFieldValue('sendEmail', false);
        submitForm();
        handleModalClose();
      }}
      style={{
        content: {
          padding: '32px',
          minWidth: 'auto',
        },
      }}
    >
      <Content>
        Do you want to send email notification about your live meeting update?
      </Content>
      <Gap justifyContent='flex-end'>
        <Button
          onClick={async () => {
            setFieldValue('sendEmail', false);
            submitForm();
          }}
          disabled={!!isSubmitting}
          text="No, Don't Send"
          variant='ghost'
        />
        <Button
          text='Yes, Send'
          disabled={!!isSubmitting}
          onClick={async () => {
            setFieldValue('sendEmail', true);
            submitForm();
          }}
          variant='red'
        />
      </Gap>
    </NewModal>
  );
};
