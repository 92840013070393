import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { guidesKeyes } from './guidesKeyes';

export interface EditableGuides {
  guideCategoryId: number;
  userId: number;
  customerId: number;
  name: string;
  order: number;
  private: boolean;
  createdAt: string;
  updatedAt: string;
}

const getEditables = async (): Promise<EditableGuides[]> => {
  return (await EXPRESS_API.get(`guide-category/editable`))
    .data as EditableGuides[];
};
export const useEditableGuideCategoriesQuery = () => {
  return useQuery(guidesKeyes.editableCategories(), () => getEditables(), {
    refetchOnMount: true,
  });
};
