export enum LANDING_PAGE_URL {
  DEVELOPMENT = 'http://localhost:3002/',
  STAGING = 'https://landing-staging.covideo.com/',
  SANDBOX = 'https://landing-sandbox.covideo.com/',
  PRODUCTION = 'https://landing.covideo.com/',
  SUSTAINING = 'https://landing-sustaining.covideo.com/',
}

export enum LIVE_PAGE_URL {
  DEVELOPMENT = 'http://localhost:3004/',
  STAGING = 'https://live-staging.covideo.com/',
  SANDBOX = 'https://live-sandbox.covideo.com/',
  PRODUCTION = 'https://live.covideo.com/',
}

export enum COVIDEO_APP_URL {
  DEVELOPMENT = 'http://localhost:3004/',
  STAGING = 'https://app-staging.covideo.com',
  SANDBOX = 'https://app-sandbox.covideo.com',
  PRODUCTION = 'https://app.covideo.com',
  SUSTAINING = 'https://app-sustaining.covideo.com',
}
