import { useQuery } from 'react-query';
import { billingKeys } from './billingKeys';
import { Invoice } from './useInvoicesQuery';
import { Card } from './useCardsQuery';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';

export type Addon = {
  id: string;
  active: boolean;
  name: string;
};

export type GetSubscriptionResponse = {
  card: Card;
  plan: {
    id: string;
    interval: string;
    valid: boolean;
  };
  upcomingInvoice: Invoice;
  totalActiveUsers: number;
  totalAllowedUsers: number;
  addons: Addon[];
  cancelAt: number | null;
  currentPeriodEnd: number | null;
  nextPeriodStart: number | null;
};

export const getSubscription = async () => {
  const response = await EXPRESS_API.get('/register/payment/subscriptions');
  return response.data;
};

export const useSubscriptionQuery = () => {
  const { showError } = useToastError();

  return useQuery(billingKeys.subscription(), () => getSubscription(), {
    onError: showError,
    refetchOnMount: true,
  });
};
