import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { VideoTranscriptionBody } from '../types';
import { EXPRESS_API } from 'configs/api/expressApi';

type Props = {
  videoId: string;
  mediaBody: VideoTranscriptionBody;
};
const createTranscription = async (data: Props) => {
  const { videoId, mediaBody } = data;
  return (await EXPRESS_API.post(`/transcripts/${videoId}`, mediaBody)).data;
};
export const useVideoTranscriptionUpdate = () => {
  return useMutation(
    async (data: Props) => {
      await createTranscription(data);
    },
    {
      onSuccess: () => {
        successToast({ title: 'Subtitles updated!' });
      },
      onError: (_, variables: Props) => {
        errorToast({
          title: variables.mediaBody.transcript
            ? 'Whoops! Error updating subtitles. Try again later!'
            : variables.mediaBody.enabled
              ? 'Whoops! Could not disable subtitles. Try again later!'
              : 'Whoops! Could not enable subtitles. Try again later!',
        });
      },
    }
  );
};
