import React from 'react';
import styled from 'styled-components/macro';
import { MdKeyboardBackspace } from 'react-icons/md';
import { theme } from 'lib/style';
import {
  Search,
  Dropdown,
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  NewModal,
} from 'lib/components';
import { EmptyDrops } from '../components';
import { DropItem } from 'lib/api/droplr/types';
import {
  BOARD_PRIVACY,
  DROPTYPE_OPTIONS,
  DropType,
  SortFields,
} from 'lib/const/Droplr';
import { useGetDrops } from 'lib/api/droplr/getDrops';
import { Button } from 'react-covideo-common';

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  min-width: 528px;
  width: auto;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  width: 200px;
`;

const Item = styled.div`
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  box-sizing: border-box;
`;

type ThumbnailProps = {
  image: string;
};

const Thumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
`;

const ListThumbnail = styled(Thumbnail)`
  width: 96px;
  height: 100%;
  flex-shrink: 0;
  cursor: pointer;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.coal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Actions = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blue60};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const ListView = styled.div`
  margin-top: 40px;
`;

const ListItem = styled(Item)`
  margin-bottom: 8px;
  height: 64px;
  display: flex;
  align-items: center;
`;

const ListTitle = styled.div`
  margin-left: 24px;
  width: 20%;
  cursor: pointer;
`;

const ListAction = styled.div`
  margin-left: auto;
  margin-right: 30px;
  width: 20%;
`;

const EmptySearchContainer = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  > h1 {
    font-size: ${theme.fontSizes.md};
    line-height: ${theme.fontSizes.lg};
    letter-spacing: 1px;
  }
`;

const TextLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  color: #4e5461;

  margin-top: 25px;
`;

type Props = {
  setShowFileListModal: React.Dispatch<React.SetStateAction<boolean>>;
  setThumbnailImageUrl: React.Dispatch<React.SetStateAction<string>>;
  setIsCustomImage: React.Dispatch<React.SetStateAction<boolean>>;
  editorImage: string;
  setEditorImage: React.Dispatch<React.SetStateAction<string>>;
};

export const FileListModal = ({
  setShowFileListModal,
  setIsCustomImage,
  setEditorImage,
}: Props) => {
  const initialDropParams = {
    page: 0,
    size: 10,
    search: '',
    sort: SortFields[3].key,
    order: SortFields[3].order,
    type: DropType.IMAGE,
    dropType: DROPTYPE_OPTIONS.OWNED,
  };

  const [filter, setFilter] = React.useState(initialDropParams);
  const {
    data: dropsResult,
    isLoading: loading,
    refetch,
  } = useGetDrops(filter);
  const { drops: dropsData, count } = dropsResult || { drops: [], count: 0 };
  const drops = dropsData.filter(
    (drop: DropItem) => drop.privacy === BOARD_PRIVACY.PUBLIC
  );

  const handleSelectFileClick = (drop: DropItem) => {
    setIsCustomImage(true);
    setEditorImage(drop.previewMedium || drop.content || '');
    setShowFileListModal(false);
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setFilter(prev => ({ ...prev, size: newSize, page: newPage }));
  };

  const getCurrentSortFieldValue = () => {
    return (
      SortFields.find(
        field => field.key === filter.sort && field.order === filter.order
      ) || SortFields[0]
    );
  };
  return (
    <>
      <NewModal
        hideCloseButtonInHeader
        closeModal={() => setShowFileListModal(false)}
        hideHeader
      >
        <div>
          <Header>
            <Button
              variant='secondary'
              icon={<MdKeyboardBackspace />}
              text={'Back'}
              onClick={() => {
                setShowFileListModal(false);
              }}
            />
          </Header>
          <Content>
            {loading && <LoadingIndicator isLoading={loading} height='500px' />}
            {!loading && (
              <>
                {(filter.search || !!drops.length) && (
                  <div>
                    <TextLabel>Select an Image from Library</TextLabel>
                    <Menu>
                      <div style={{ width: '320px' }}>
                        <Search
                          placeholder={`Search files...`}
                          onSearch={(query: string) => {
                            setFilter(prev => ({
                              ...prev,
                              page: 0,
                              search: query.toLowerCase(),
                            }));
                          }}
                          prevSearch={filter.search}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <DropdownContainer>
                          <Dropdown
                            value={getCurrentSortFieldValue()}
                            onChange={newValue => {
                              setFilter(prev => ({
                                ...prev,
                                sort: newValue.key,
                                order: newValue.order,
                              }));
                            }}
                            options={SortFields}
                            dropdownHeight={450}
                            height={40}
                          />
                        </DropdownContainer>
                      </div>
                    </Menu>
                    {!!drops.length && (
                      <>
                        <div style={{ height: '420px', overflowY: 'scroll' }}>
                          <ListView>
                            {drops.map((drop: DropItem) => {
                              return (
                                <ListItem key={drop.code}>
                                  <ListThumbnail image={drop.previewSmall} />
                                  <ListTitle>
                                    <Title>{drop.title}</Title>
                                  </ListTitle>
                                  <ListAction>
                                    <Actions
                                      onClick={() => {
                                        handleSelectFileClick(drop);
                                      }}
                                    >
                                      Select File
                                    </Actions>
                                  </ListAction>
                                </ListItem>
                              );
                            })}
                          </ListView>
                        </div>
                        {count > 0 && (
                          <TableContextProvider
                            total={count}
                            initSize={filter.size}
                            initPage={filter.page}
                            onChange={onPaginationChange}
                          >
                            <TableFooter>
                              <TablePaginationNew />
                              <TablePaginationSizeNew globalName='drops_list' />
                            </TableFooter>
                          </TableContextProvider>
                        )}
                      </>
                    )}
                    {!drops.length && (
                      <EmptySearchContainer>
                        <h1>
                          There are no images with "{filter.search}" in the
                          title
                        </h1>
                      </EmptySearchContainer>
                    )}
                  </div>
                )}
                {!filter.search && !drops.length && (
                  <EmptyDrops
                    hideVideoLibrary={true}
                    excludeFilesTab={true}
                    onUploadDropModalClosed={refetch}
                  />
                )}
              </>
            )}
          </Content>
        </div>
      </NewModal>
    </>
  );
};
