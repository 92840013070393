import * as React from 'react';
import styled from 'styled-components/macro';

import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { SliderRail, Handle, Track, Tick } from './components';
import { theme } from '../../../../../../../lib/style';
import { VideoPreviewTrack } from 'app/pages/library/videos/videoRequests/components/VideoCropAndVoiceover';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: 40px;
  height: 44px;
  order: -1;
  ${theme.mediaQueryMinWidth.md} {
    order: 3;
  }
`;

type Props = {
  videoDuration: number;
  videoRef: any;
  videoSource: string;
  setTrimDuration?: any;
  setStart?: any;
  value: any;
  setValue: any;
  addHistory?: any;
  onSlideEnd?: any;
  valueRef: React.MutableRefObject<Array<number>>;
  hideTime?: boolean;
  changeVideoPosition?: boolean;
  marginLeft?: string /* SUS-905 changes */;
  showPreviewTrack?: boolean;
};

const sliderStyle = {
  position: 'relative' as 'relative',
  width: '100%',
  backgroundColor: 'rgba(0, 27, 83, 0.4)',
};

export const TimelineRange = (props: Props) => {
  const {
    videoDuration,
    videoSource,
    setTrimDuration = () => {},
    setStart = () => {},
    value,
    setValue,
    addHistory = () => {},
    onSlideEnd = () => {},
    valueRef,
    videoRef,
    hideTime = false,
    changeVideoPosition = true,
    marginLeft /* SUS-905 changes */,
    showPreviewTrack = true,
  } = props;

  const [domain, setDomain] = React.useState([0, videoDuration]);

  React.useEffect(() => {
    const duration = videoDuration ? videoDuration : 0;
    const initialValues = [0, duration];
    setDomain(initialValues);
    // update value if endTime in undefined
    if (!value[1]) {
      setValue(initialValues);
    }
  }, [videoDuration]);

  const onChange = (values: ReadonlyArray<number>) => {
    if (!isNaN(values[0]) && !isNaN(values[1])) {
      const newValue = values.slice();
      addHistory(newValue);
      setValue(newValue);
      valueRef.current = newValue;
    }
  };

  const onUpdate = (update: ReadonlyArray<number>) => {
    setTrimDuration(update[1] - update[0]);
    setStart(update[0]);
  };

  const onSlideEndHandle = (args: any) => {
    onSlideEnd(args);
  };

  const numberOfStills = 5;

  return (
    <Wrap>
      <Slider
        rootStyle={sliderStyle}
        mode={1}
        step={0.1}
        domain={domain}
        reversed={false}
        onChange={onChange}
        onUpdate={onUpdate}
        values={value}
        onSlideEnd={onSlideEndHandle}
      >
        {showPreviewTrack && (
          <VideoPreviewTrack
            numberOfStills={numberOfStills}
            videoSource={videoSource}
            videoDuration={videoDuration}
            top='-24px'
          />
        )}
        <Rail>{railProps => <SliderRail {...railProps} />}</Rail>
        <Handles>
          {({ handles, activeHandleID, getHandleProps }) => (
            <div className='slider-handles'>
              {handles.map((handle, key) => (
                <Handle
                  order={key}
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  isActive={handle.id === activeHandleID}
                  getHandleProps={getHandleProps}
                  videoSource={videoSource}
                  videoRef={videoRef}
                  changeVideoPosition={changeVideoPosition}
                  marginLeft={marginLeft} /* SUS-905 changes */
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className='slider-tracks'>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={10}>
          {({ ticks }) => (
            <div className='slider-ticks'>
              {ticks.map(tick => (
                <Tick
                  hideTime={hideTime}
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </Wrap>
  );
};
