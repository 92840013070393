import * as React from 'react';
import { useTheme } from 'styled-components/macro';
import {
  LoadingIndicator,
  ModalDelete,
  ModalVideoAttributes,
} from 'lib/components';

import { NoAttributes, AttributesList } from '../components';
import {
  Container,
  HeaderWrapper,
  MainWrapper,
} from 'lib/components/styles/layout';
import { MdAdd } from 'react-icons/md';
import { Heading } from 'lib/components/styles/typography';
import { Button } from 'react-covideo-common';
import { useRemoveAttribute } from 'lib/api/videoAttributes/useRemoveVideoAttribute';
import { useUpdateOrderAttributes } from 'lib/api/videoAttributes/useUpdateOrderAttributes';
import { useGetAllVideoAttributesQuery } from 'lib/api/videoAttributes/useGetAllVideoAttributesQuery';

export const Main = () => {
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedAttribute, setSelectedAttribute] = React.useState<any>({});
  const [reorderLoading, setReorderLoading] = React.useState(false);
  const themes = useTheme();
  const { mutateAsync: removeAttribute } = useRemoveAttribute();
  const { mutateAsync: updateOrder } = useUpdateOrderAttributes();

  const { data, refetch, isLoading } = useGetAllVideoAttributesQuery({
    page: 0,
    size: 200,
  });
  const { attributes: attributesList } = data || {
    attributes: [],
    count: 0,
  };

  const openCreateAttributeModal = () => {
    setSelectedAttribute({
      title: '',
      slug: '',
      type: 'text',
      default: '',
      dropdownValues: [],
    });
    setShowCreateModal(true);
  };

  const openEditAttributeModal = (selectedAttribute: any) => {
    setSelectedAttribute(selectedAttribute);
    setShowCreateModal(true);
  };

  const openDeleteAttributeModal = (selectedAttribute: any) => {
    setSelectedAttribute(selectedAttribute);
    setShowDeleteModal(true);
  };

  const updateAttributes = async (updatedAttributes: any) => {
    setReorderLoading(true);
    await updateOrder(updatedAttributes);
    await refetch();

    setReorderLoading(false);
  };

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <LoadingIndicator isLoading={isLoading} />
        {!isLoading && (
          <>
            <HeaderWrapper>
              <Heading>Custom Video Attributes</Heading>
              <Button
                text='Create New'
                onClick={() => openCreateAttributeModal()}
                icon={<MdAdd />}
              />
            </HeaderWrapper>

            {!attributesList.length && (
              <NoAttributes
                showModal={() => openCreateAttributeModal()}
                color={themes.colors.primary[100]}
              />
            )}
            {!!attributesList.length && (
              <AttributesList
                attributes={attributesList}
                showEditModal={openEditAttributeModal}
                showDeleteModal={openDeleteAttributeModal}
                updateAttributesOrder={updateAttributes}
                reorderLoading={reorderLoading}
              />
            )}
            {showCreateModal && (
              <ModalVideoAttributes
                attributes={attributesList}
                selectedAttribute={selectedAttribute}
                handleModalClose={() => {
                  refetch();
                  setShowCreateModal(false);
                }}
              />
            )}
            {showDeleteModal && (
              <ModalDelete
                whiteButtonText='Yes, Delete'
                orangeButtonText="No, Don't Delete"
                title='Delete Custom Attribute?'
                text={`You’re going to delete ${selectedAttribute.title} attribute. This action can’t be undone.`}
                handleModalClose={() => setShowDeleteModal(false)}
                onClickWhiteButton={async () => {
                  await removeAttribute(selectedAttribute.attributeId);
                  refetch();
                  setShowDeleteModal(false);
                }}
                onClickOrangeButton={() => setShowDeleteModal(false)}
              />
            )}
          </>
        )}
      </MainWrapper>
    </Container>
  );
};
