import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { facebookKeys } from './queryKeys';
import { useToastError } from 'lib/hooks';
import { errorToast } from 'lib/components/toasts/error';

export const queryFacebookVideoAnalytics = async (id: number) => {
  const response = await EXPRESS_API.get(`/facebook/video/${id}/analytics`);
  const data = response.data.data;
  // Facebook API returns an object containing error details when there is an issue with the request
  if (data.status === 404) {
    return;
  }
  if (data.status === 401) {
    errorToast({
      title: data.data.message,
    });
    return;
  }
  return data;
};

export const useQueryFacebookVideoAnalytics = (
  id: number,
  enabled: boolean
) => {
  const { showError } = useToastError();
  return useQuery(
    facebookKeys.facebook_analytics(id),
    () => queryFacebookVideoAnalytics(id),
    {
      onError: showError,
      refetchOnMount: true,
      enabled,
    }
  );
};
