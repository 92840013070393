import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdUpload, MdVideocam } from 'react-icons/md';
import { useAuth } from '../../context';
import { MdNotifications } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { NotificationBell } from '../NotificationBell';
import { VideoLimitStatus } from 'lib/utils/productFeature';
import { Gap } from '../styles/layout';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { useNavigationColors } from 'lib/hooks/useNavigationColor';
import { useVideoLimit } from 'lib/context/videolimit/VideoLimitContext';

interface Props {
  onClick: () => void;
  isBottom?: boolean;
  isModalOpen?: boolean;
  isAdmin?: boolean;
  showNotificationIcon?: number;
}

type StylingProps = {
  isBottom?: boolean;
  isModalOpen?: boolean;
  isAdmin?: boolean;
  isDisabled?: boolean;
};

type BellButtonProps = {
  primaryColor?: any;
  themeName: string;
};

const BellButton = styled.div<BellButtonProps>`
  margin-top: 10px;
  margin-left: 32px;
  margin-right: 0px;
  position: relative;
  .highlight-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -12px;
    left: 12px;
    background: ${props =>
      props.themeName === 'branding'
        ? '#E9F55F'
        : props.theme.colors.primary[100]};
    width: 22px;
    height: 22px;
    border: 2px solid
      ${props =>
        props.themeName === 'branding'
          ? theme.palette.darkGreen
          : 'transparent'};
    border-radius: 50%;
    color: ${props =>
      props.themeName === 'branding'
        ? theme.palette.darkGreen
        : props.theme.colors.white[100]};
  }
  cursor: pointer;
`;

const UploadAndRecordWrap = styled.div<StylingProps>`
  display: ${props => (props.isModalOpen ? 'none' : 'flex')};
  flex-direction: row;
  // transition: width 0.1s ease;
  z-index: 4;
  width: 100%;
  ${theme.mediaQueryMinWidth.md} {
    position: static;
  }
  .link-wrapper {
    cursor: not-allowed;
  }
  .Tooltip-Wrapper {
    .record-disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
    .Tooltip-Tip.top {
      top: calc(var(--tooltip-margin) * 1.5) !important;
      left: 80px !important;
    }
  }
`;

export const UploadAndRecordButtons = (props: Props) => {
  const { width } = useWindowSize();
  const isLargeScreen = width >= 1560;
  const { onClick, isModalOpen, showNotificationIcon } = props;
  const [recordDisable, setRecordDisable] = React.useState(false);
  const { userData } = useAuth();
  const { trialExpired } = userData;
  const { videoLimitStatus } = useVideoLimit();
  const { color } = useNavigationColors();

  let showBellIcon = showNotificationIcon != 1;
  const history = useHistory();
  const { themeName } = useAuth();
  const themes = useCovideoTheme();
  React.useEffect(() => {
    setRecordDisable(videoLimitStatus !== VideoLimitStatus.NOT_REACHED);
  }, [videoLimitStatus]);

  if (userData.access.toString() === '4') {
    return null;
  }

  return (
    <UploadAndRecordWrap isModalOpen={isModalOpen}>
      <Gap gap='12px' flexWrap='no-wrap'>
        <div className={recordDisable ? 'link-wrapper' : ''}>
          {!trialExpired && recordDisable ? (
            <ProductInfoTooltip
              style={{
                top: '40px',
                right: '0',
                left: '0',
              }}
            >
              <Button
                data-cy='video-record-button'
                variant='white'
                text={isLargeScreen ? 'Upload' : ''}
                icon={<MdUpload size={18} />}
                onClick={(
                  e?:
                    | React.SyntheticEvent
                    | React.SyntheticEvent<Element, Event>
                    | React.MouseEvent
                ) => {
                  e?.preventDefault();
                  if (!recordDisable) {
                    onClick();
                  }
                }}
                disabled={recordDisable}
              />
            </ProductInfoTooltip>
          ) : (
            <Button
              data-cy='video-record-button'
              variant='white'
              text={isLargeScreen ? 'Upload' : ''}
              icon={<MdUpload size={18} />}
              onClick={(
                e?:
                  | React.SyntheticEvent
                  | React.SyntheticEvent<Element, Event>
                  | React.MouseEvent
              ) => {
                e?.preventDefault();
                if (!recordDisable) {
                  onClick();
                }
              }}
              disabled={recordDisable || trialExpired}
            />
          )}
        </div>
        <div className={recordDisable ? 'link-wrapper' : ''}>
          {!trialExpired && recordDisable ? (
            <ProductInfoTooltip
              style={{
                top: '40px',
                right: '0',
                left: '0',
              }}
            >
              <Button
                data-cy='video-record-button'
                variant='red'
                text={isLargeScreen ? 'Record' : ''}
                icon={<MdVideocam size={24} />}
                disabled={recordDisable}
                onClick={() => history.push('/record/home')}
              />
            </ProductInfoTooltip>
          ) : (
            <Gap gap='8px'>
              <Button
                data-cy='video-record-button'
                variant='red'
                text={isLargeScreen ? 'Record' : ''}
                icon={<MdVideocam size={24} />}
                onClick={() => history.push('/record/home')}
              />
            </Gap>
          )}
        </div>
      </Gap>
      {showBellIcon ? (
        <BellButton
          themeName={themeName}
          primaryColor={themes.colors.primary[100]}
          onClick={() => {
            history.push(`/notifications`);
          }}
        >
          <MdNotifications color={color} size='24px' />
          <NotificationBell />
        </BellButton>
      ) : null}
    </UploadAndRecordWrap>
  );
};
