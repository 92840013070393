import { EXPRESS_API } from 'configs/api/expressApi';
import { VideoRequest } from 'lib/context';
import { useMutation, useQueryClient } from 'react-query';
import { videoRequestKeys } from './videoRequestKeys';

type CreateVideoRequestParams = {
  data: Partial<VideoRequest>;
};

export const createVideoRequest = async ({
  data,
}: CreateVideoRequestParams) => {
  const response = await EXPRESS_API.post(`/video-requests`, data);
  return response.data;
};

export const useCreateVideoRequestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createVideoRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(videoRequestKeys.all());
    },
  });
};
