export enum VariantTypes {
  VIDEOS = 'videos',
  USERS = 'users',
  CTA_SETS = 'cta-sets',
  CTA_LINKS = 'cta-links',
  TABLE_SCOREBOARD = 'table-scoreboard',
}

export interface IParams {
  from: string;
  to: string;
}

export interface Items {
  id: 'clicks' | 'views' | 'engagements';
  label: string;
}

export const WIDGET_TABS = {
  VARIANTS_VIDEOS: [
    { id: 'views', label: 'Views' },
    { id: 'engagement', label: 'Engagement' },
    { id: 'cta-clicks', label: 'CTA Clicks' },
  ],
  VARIANTS_USERS: [
    { id: 'recorded', label: 'Recorded' },
    { id: 'engagements', label: 'Engagements' },
    { id: 'views', label: 'Views' },
    { id: 'clicks', label: 'Clicks' },
  ],
  VARIANTS_LEADERBOARD: [
    { id: '1', label: 'Service' },
    { id: '2', label: 'Sales' },
  ],
};

export enum WidgetValues {
  VIDEO_DELIVERY = 'VIDEO_DELIVERY',
  VIDEO_ENGAGEMENTS = 'VIDEO_ENGAGEMENTS',
  NEW_VIDEOS = 'NEW_VIDEOS',
  TOP_VIDEOS = 'TOP_VIDEOS',
  TOP_USERS = 'TOP_USERS',
  TOP_CTA_SETS = 'TOP_CTA_SETS',
  TOP_FIVE_USER_COMPARISON = 'TOP_FIVE_USER_COMPARISON',
  VIEWS_BY_VIDEO_TYPE = 'VIEWS_BY_VIDEO_TYPE',
  DEPARTMENTS = 'DEPARTMENTS',
  TOP_CTA_LINKS = 'TOP_CTA_LINKS',
  DELIVERY_METHOD = 'DELIVERY_METHOD',
  VIEW_RATE_BY_DELIVERY_METHOD = 'VIEW_RATE_BY_DELIVERY_METHOD',
  LEADERBOARD = 'LEADERBOARD',
}

export enum WidgetLabels {
  VIDEO_DELIVERY = 'Unique Video Delivery',
  VIDEO_ENGAGEMENTS = 'Video Engagement',
  NEW_VIDEOS = 'New Videos',
  TOP_VIDEOS = 'Top Videos',
  TOP_USERS = 'Top Users',
  TOP_CTA_SETS = 'Top CTA Sets',
  TOP_FIVE_USER_COMPARISON = 'Top Five User Comparison',
  VIEWS_BY_VIDEO_TYPE = 'Views by Video Type',
  DEPARTMENTS = 'Departments',
  TOP_CTA_LINKS = 'Top CTA Links',
  DELIVERY_METHOD = 'Delivery Method',
  VIEW_RATE_BY_DELIVERY_METHOD = 'View Rate by Delivery Method',
  LEADERBOARD = 'Leaderboard',
}

export const COMMON_WIDGETS = [
  { label: WidgetLabels.VIDEO_DELIVERY, value: WidgetValues.VIDEO_DELIVERY },
  {
    label: WidgetLabels.VIDEO_ENGAGEMENTS,
    value: WidgetValues.VIDEO_ENGAGEMENTS,
  },
  { label: WidgetLabels.NEW_VIDEOS, value: WidgetValues.NEW_VIDEOS },
  { label: WidgetLabels.DELIVERY_METHOD, value: WidgetValues.DELIVERY_METHOD },
  {
    label: WidgetLabels.VIEW_RATE_BY_DELIVERY_METHOD,
    value: WidgetValues.VIEW_RATE_BY_DELIVERY_METHOD,
  },
  { label: WidgetLabels.TOP_VIDEOS, value: WidgetValues.TOP_VIDEOS },
  { label: WidgetLabels.TOP_USERS, value: WidgetValues.TOP_USERS },
  { label: WidgetLabels.TOP_CTA_SETS, value: WidgetValues.TOP_CTA_SETS },
  {
    label: WidgetLabels.TOP_FIVE_USER_COMPARISON,
    value: WidgetValues.TOP_FIVE_USER_COMPARISON,
  },
  { label: WidgetLabels.DEPARTMENTS, value: WidgetValues.DEPARTMENTS },
  { label: WidgetLabels.TOP_CTA_LINKS, value: WidgetValues.TOP_CTA_LINKS },
];

export const AUTOMOTIVE_WIDGETS = [
  ...COMMON_WIDGETS,
  {
    label: WidgetLabels.VIEWS_BY_VIDEO_TYPE,
    value: WidgetValues.VIEWS_BY_VIDEO_TYPE,
  },
  { label: WidgetLabels.LEADERBOARD, value: WidgetValues.LEADERBOARD },
];

export const DEFAULT_WIDGETS = [
  {
    label: WidgetLabels.VIDEO_ENGAGEMENTS,
    value: WidgetValues.VIDEO_ENGAGEMENTS,
  },
  {
    label: WidgetLabels.VIDEO_DELIVERY,
    value: WidgetValues.VIDEO_DELIVERY,
  },
  {
    label: WidgetLabels.TOP_VIDEOS,
    value: WidgetValues.TOP_VIDEOS,
  },
  {
    label: WidgetLabels.TOP_CTA_SETS,
    value: WidgetValues.TOP_CTA_SETS,
  },
  {
    label: WidgetLabels.TOP_USERS,
    value: WidgetValues.TOP_USERS,
  },
];

export const GENERAL_WIDGETS = [...COMMON_WIDGETS];

export interface IDepartmentsWidget {
  views: CtaClick[] | [];
  'cta-clicks': CtaClick[] | [];
  engagement: CtaClick[] | [];
}

export type IDepartmentsWidgetKeys = keyof IDepartmentsWidget;

export interface CtaClick {
  name: string;
  cnt: string;
}

export interface Field {
  value: string;
  label: string;
}

interface VariantProps {
  label: string;
  tabItems: { id: string; label: string }[];
  tableHeaders: string[];
  flexHeaders: string[];
}

export interface VariantData {
  [key: string]: VariantProps;
}

export const REPORTS_STALE_TIME = 1000 * 60 * 4;
