import { useQuery } from 'react-query';
import { getInventoryItem } from 'lib/api';
import { inventoryItemKeys } from './inventoryItemKeys';

type Props = {
  itemId: string;
};

export const useGetInventoryItem = ({ itemId }: Props) => {
  return useQuery(
    [inventoryItemKeys.details(itemId), itemId],
    async () => getInventoryItem(itemId),
    {
      refetchOnWindowFocus: false,
    }
  );
};
