import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { ProgressCircle } from './ProgressCircle';
import { RECORD_LOADER_STATUS } from './types';
import { useCovideoTheme } from 'react-covideo-common';

export const FlexCenter = styled.div`
  margin-top: 16px;
  background-color: #f6f7f9;
  border-radius: 4px;
  padding: 32px;
  height: 168px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  color: ${theme.palette.blackRgb75};
  margin: 0;
  width: 100%;
  margin-bottom: 32px;
  min-height: 190px;
  position: relative;
`;
type ProgressBarProps = {
  color: string;
};
const ProgressBar = styled.progress<ProgressBarProps>`
  z-index: 1;
  margin-top: -14.5px;
  width: 100%;
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 15px;
  height: 4px;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 15px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: ${({ color }) => color};
    border-radius: 15px;
    height: 4px;
  }
  &::-webkit-progress-value {
    float: left;
    height: 4px;
    margin: 0px -10px 0 0;
    background: ${({ color }) => color};
    border-radius: 15px;
  }
`;
const ProgressText = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #4e5461;
  margin-top: 32px;
`;

const toMBString = (loaded: number, total: number) => {
  const loadedMB = (loaded / 1000).toFixed(2);
  const totalMB = (total / 1000).toFixed(2);
  return `${loadedMB} MB/${totalMB} MB`;
};

interface IProgress {
  loaded: number;
  total: number;
  percentage: number;
}

interface IUploadLoaderProps {
  progressState: RECORD_LOADER_STATUS;
  uploadToS3Progress: IProgress;
  createProgress: IProgress;
  encodeProgress: IProgress;
}

export const UploadLoader = ({
  progressState,
  uploadToS3Progress,
  createProgress,
  encodeProgress,
}: IUploadLoaderProps) => {
  const { colors } = useCovideoTheme();
  const getPercentage = () => {
    if (!progressState) {
      return 0;
    }
    if (progressState == RECORD_LOADER_STATUS.UPLOADING) {
      return (uploadToS3Progress.percentage * 50) / 100;
    }
    if (progressState == RECORD_LOADER_STATUS.CREATE) {
      return 50 + (createProgress.percentage || 0);
    }
    return 100;
  };

  return (
    <ContentBody>
      <FlexCenter>
        <ProgressContainer>
          <ProgressCircle progress={uploadToS3Progress} progressType='upload' />
          <ProgressCircle progress={createProgress} progressType='create' />
          <ProgressCircle progress={encodeProgress} progressType='encode' />
        </ProgressContainer>
        <ProgressBar
          value={getPercentage()}
          max={100}
          color={colors.primary[100]}
        />
        <ProgressText>
          {toMBString(uploadToS3Progress.loaded, uploadToS3Progress.total)}
        </ProgressText>
      </FlexCenter>
    </ContentBody>
  );
};
