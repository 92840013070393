export enum FREQUENCY_TYPE {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum FREQUENCY_DETAIL {
  EVERYDAY = 'everyday',
  WEEKDAYS = 'weekdays',
}

type FrequencyOption = {
  value: string;
  label: string;
};

export const frequencyOptions: FrequencyOption[] = [
  {
    value: FREQUENCY_TYPE.DAILY,
    label: 'Daily',
  },
  {
    value: FREQUENCY_TYPE.WEEKLY,
    label: 'Weekly',
  },
  {
    value: FREQUENCY_TYPE.MONTHLY,
    label: 'Monthly',
  },
];
