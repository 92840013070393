import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory, RouteComponentProps } from 'react-router';
import { MdKeyboardArrowRight, MdSave } from 'react-icons/md';
import { withRouter } from 'react-router-dom';
import { theme } from 'lib/style';

import {
  LoadingIndicator,
  MainContainer,
  Tabs,
  ButtonSwitch,
} from 'lib/components';
import { Tab } from 'lib/components/tabs';
import { UserAddons } from './UserAddons';
import { UserEditor } from './UserEditor';
import { fetchCustomerUser } from 'lib/api';
import { NotFound } from 'app/pages/notFound/NotFound';
import { MainContainer as CustomMainContainer } from '../userEntry/index.styled';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { getSubscription } from 'lib/api/billing/useSubscriptionQuery';

interface WidthProps {
  isWidthAuto?: boolean;
}

const MainContainerWrapper = styled(MainContainer)`
  align-items: start;
  ${theme.mediaQueryMinWidth.md} {
    &.has-sticky {
      padding-top: 119px;
    }
  }
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  margin-top: 15px;
  ${theme.mediaQueryMinWidth.md} {
    &.sticky {
      background: ${theme.palette.white};
      position: fixed;
      z-index: 99;
      top: 64px;
      left: 0;
      right: 0;
      width: auto;
      margin-top: 0;
      padding: 12px 76px 12px 276px;
      box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
    }
  }
  ${theme.mediaQueryMinWidth.mb} {
    padding: 0;
    nav {
      &:nth-of-type(1) {
        display: flex;
      }
      width: 100%;
      height: 48px;
      > div {
        float: left;
        display: block;
        background-color: ${theme.palette.white};
        border-top: 1px solid ${theme.palette.grayBorder};
        border-left: 1px solid ${theme.palette.grayBorder};
        border-right: 1px solid ${theme.palette.grayBorder};
        height: 48px;
        box-sizing: border-box;
        line-height: 1.5;
        padding-top: 12px;
        &:nth-of-type(1) {
          width: 152px;
        }
        &:nth-of-type(2) {
          width: 115px;
          border-left: none;
          border-right: none;
        }
        &:nth-of-type(3) {
          width: 100px;
        }
        &:nth-of-type(4) {
          flex-grow: 2;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid ${theme.palette.grayBorder};
          background-color: #fbfbfb;
          &:hover {
            cursor: default;
          }
          disabled: true;
        }
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 20px;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    margin-top: 32px;
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const RequiredField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.primary[100]};
  ${theme.mediaQueryMinWidth.sm} {
    padding: 10px 24px;
    flex-direction: row;
    justify-content: space-between;
  }
  label {
    color: #ff8b22;
  }
`;

const Filter = styled.div<WidthProps>`
  float: right;
  ${theme.mediaQueryMinWidth.sm} {
    width: ${props => (!props.isWidthAuto ? '205px' : 'auto')};
  }
  /** SUS-1223 changes **/
  ${theme.mediaQueryMinWidth.md} {
    width: ${props => (!props.isWidthAuto ? '470px' : 'auto')};
  }
`;

const UserCounter = styled.div`
  display: flex;
`;

const FieldContainer = styled.div`
  display: flex;
`;

const BreadcrumbsContainer = styled.div`
  display: flex;
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const LinkText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

const UserTitle = styled.h2`
  color: ${theme.palette.coal};
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface MatchParams {
  id: string;
}

const views = [
  {
    value: 'enabled',
    text: 'Active',
  },
  {
    value: 'disabled',
    text: 'Inactive',
  },
];

export const UserDetailLayout = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const { userData } = useAuth();

    const [activeTab, setActiveTab] = React.useState(0);
    const [action, setAction] = React.useState({ save: () => {} });
    const [actionAddon, setActionAddon] = React.useState({ save: () => {} });
    const [updateVerifyFun, setUpdateVerifyFun] = React.useState({
      // eslint-disable-next-line unused-imports/no-unused-vars
      update: (e: any) => {},
    });
    const [updateVerifyFunAddon, setUpdateVerifyFunAddon] = React.useState({
      // eslint-disable-next-line unused-imports/no-unused-vars
      update: (e: any) => {},
    });
    const [verifiedStatus, setVerifiedStatus] = React.useState(false);
    const [stickyHeader, setStickyHeader] = React.useState(false);
    const [config, setConfig] = React.useState({
      title: '',
      isValidUser: true,
      showAddView: false,
      user: {},
    });
    const [loading, setLoading] = React.useState(true);
    const [subscription, setSubscription] = React.useState({});
    const [isUsernameValid, setUsernameValid] = React.useState(true);
    const [automotiveRole, setAutomotiveRole] = React.useState<string | null>(
      userData.isAutomotive ? null : ''
    );

    const history = useHistory();
    const { id } = props.match.params;

    React.useEffect(() => {
      async function fetchUser() {
        if (id) {
          try {
            const user = await fetchCustomerUser(id);
            const doesUserExist = Object.keys(user).length > 0;
            setConfig({
              title: doesUserExist ? `${user.firstName} ${user.lastName}` : '',
              isValidUser: doesUserExist ? true : false,
              showAddView: false,
              user: doesUserExist ? user : {},
            });
            if (doesUserExist) {
              setVerifiedStatus(!!user.verified);
            }
            setLoading(false);
          } catch (e) {
            setConfig({
              title: '',
              isValidUser: false,
              showAddView: false,
              user: {},
            });
            setLoading(false);
          }
          return;
        }
        // If id is not present in url
        setConfig({
          title: 'New User',
          isValidUser: false,
          showAddView: true,
          user: {},
        });
        setLoading(false);
      }
      fetchUser();
    }, []);

    const goToUsers = () => history.push('/users/list');

    let tabs: Tab[] = [
      {
        title: 'User Info',
        component:
          activeTab == 0 ? (
            <UserEditor
              setAction={setAction}
              fetchedUserData={config.user}
              setUpdateVerifyFun={setUpdateVerifyFun}
              setVerifiedStatus={setVerifiedStatus}
              subscription={subscription}
              setUsernameValid={setUsernameValid}
              isUsernameValid={isUsernameValid}
            />
          ) : null,
        takeConfirmation: true,
      },
      {
        title: 'Add-ons',
        component:
          activeTab == 1 ? (
            <UserAddons
              fetchedUserData={config.user}
              setUpdateVerifyFunAddon={setUpdateVerifyFunAddon}
              setActionAddon={setActionAddon}
              setVerifiedStatus={setVerifiedStatus}
              subscription={subscription}
            />
          ) : null,
        takeConfirmation: true,
      },
    ];

    const loadSubscription = async () => {
      try {
        const response = await getSubscription();
        setSubscription(response);
      } catch (e) {}
    };

    useEffect(() => {
      updateVerifyFun.update(verifiedStatus);
      updateVerifyFunAddon.update(verifiedStatus);
    }, [verifiedStatus]);

    const handleScroll = () => {
      setStickyHeader(window.pageYOffset > 64);
    };

    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    useEffect(() => {
      loadSubscription();
    }, []);

    if (loading) {
      return <LoadingIndicator isLoading={loading} height='500px' />;
    }

    if (!config.isValidUser && !config.showAddView) {
      return <NotFound />;
    }

    return (
      <CustomMainContainer>
        <MainContainerWrapper className={stickyHeader ? 'has-sticky' : ''}>
          <BreadcrumbsContainer>
            <LinkBtn onClick={goToUsers}>Manage users</LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <LinkText>
              {!config.isValidUser ? 'New User' : 'Edit User'}
            </LinkText>
          </BreadcrumbsContainer>
          <TabsContainer className={stickyHeader ? 'sticky' : ''}>
            <FiltersContainer>
              <FieldContainer>
                <Filter isWidthAuto={!config.isValidUser}>
                  <UserTitle>
                    {!config.isValidUser ? 'New User' : config.title}
                  </UserTitle>
                </Filter>
                <UserCounter>
                  <RequiredField>* indicates Required Field</RequiredField>
                </UserCounter>
              </FieldContainer>
              {activeTab == 0 && !config.isValidUser && (
                <ButtonContainer>
                  <Button
                    variant='secondary'
                    text={'Cancel'}
                    onClick={goToUsers}
                  />
                  <Button
                    icon={<MdSave color={theme.palette.white || '#fff'} />}
                    text={'Add User'}
                    disabled={!isUsernameValid || automotiveRole === null}
                    onClick={() => action.save()}
                  />
                </ButtonContainer>
              )}
              {config.isValidUser && (
                <ButtonContainer>
                  <ButtonSwitch
                    defaultValue={verifiedStatus ? 'enabled' : 'disabled'}
                    values={views}
                    onChange={newView => {
                      setVerifiedStatus(newView == 'enabled' ? true : false);
                    }}
                  />
                  <Button
                    disabled={isUsernameValid ? false : true}
                    icon={<MdSave color={theme.palette.white || '#fff'} />}
                    text={'Save Changes'}
                    onClick={() =>
                      activeTab == 0 ? action.save() : actionAddon.save()
                    }
                  />
                </ButtonContainer>
              )}
            </FiltersContainer>
          </TabsContainer>
          <Content>
            {config.isValidUser ? (
              <Tabs
                tabs={tabs}
                defaultActiveTab={activeTab}
                onChange={setActiveTab}
                takeConfirmation={true}
              />
            ) : config.showAddView ? (
              <UserEditor
                setAction={setAction}
                fetchedUserData={config.user}
                setUpdateVerifyFun={setUpdateVerifyFun}
                setVerifiedStatus={setVerifiedStatus}
                subscription={subscription}
                setUsernameValid={setUsernameValid}
                isUsernameValid={isUsernameValid}
                setAutomotiveRole={setAutomotiveRole}
                automotiveRole={automotiveRole}
              />
            ) : null}
          </Content>
        </MainContainerWrapper>
      </CustomMainContainer>
    );
  }
);
