import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import styled from 'styled-components/macro';

const EditorContainer = styled.div`
  position: relative;
  width: 528px;
  height: 254px;
`;

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    try {
      let urlObject = new URL(url);
      if (urlObject && urlObject.search && urlObject.search !== '') {
        image.src = url + '&ttt=' + new Date().getTime();
      } else {
        image.src = url + '?' + new Date().getTime();
      }
    } catch (ex) {}

    image.setAttribute('crossOrigin', ''); // needed to avoid cross-origin issues on CodeSandbox
  });

function getRadianAngle(degreeValue: any) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

const blobToFile = (theBlob: Blob | null, fileName: string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  //Cast to a File() type
  return theBlob as File;
};

type Size = {
  width: number;
  height: number;
};

type Props = {
  zoom: number;
  setZoom: any;
  imageUrl: string;
  setCroppedImage: any;
  croppedImage: any;
  onCropStart: any;
  onCropEnd: any;
  cropAreaSize?: Size;
};

export const ImageCropper = ({
  zoom = 1,
  setZoom,
  imageUrl,
  setCroppedImage,
  onCropStart,
  onCropEnd,
  cropAreaSize,
}: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  async function getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0) {
    onCropStart();
    const image = (await createImage(imageSrc)) as HTMLImageElement;
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx?.translate(safeArea / 2, safeArea / 2);
    ctx?.rotate(getRadianAngle(rotation));
    ctx?.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx?.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx?.getImageData(0, 0, safeArea, safeArea) as ImageData;

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx?.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise(resolve => {
      let fileExtension = 'jpeg';
      try {
        const url = new URL(imageUrl);
        const pathArray = url.pathname.split('/');
        if (pathArray.length) {
          let desiredPath = pathArray[pathArray.length - 1];
          let finalPathArray = desiredPath.split('.');
          if (finalPathArray.length) {
            fileExtension = finalPathArray[finalPathArray.length - 1];
          }
        }
      } catch (ex) {}
      let mimeType = 'image/' + fileExtension;
      if (fileExtension === 'jpg') {
        mimeType = 'image/jpeg';
      } else if (fileExtension === 'svg') {
        mimeType = 'image/svg+xml';
      }
      canvas.toBlob(file => {
        const fileName = 'croppedImage.' + fileExtension;
        const newFile = blobToFile(file, fileName);
        setCroppedImage(newFile);
        onCropEnd();
        resolve(newFile);
      }, mimeType);
    });
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    showCroppedImage(croppedAreaPixels);
  }, []);

  const cropSize: Size = cropAreaSize || {
    width: 350,
    height: 168,
  };

  const showCroppedImage = useCallback(
    async croppedAreaPixels2 => {
      try {
        const croppedImage = (await getCroppedImg(
          imageUrl,
          croppedAreaPixels2,
          rotation
        )) as any;
        setCroppedImage(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedAreaPixels, rotation]
  );

  return (
    <div>
      <EditorContainer>
        <Cropper
          image={imageUrl}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          cropSize={cropSize}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          showGrid={false}
          zoomWithScroll={false}
          objectFit={'horizontal-cover'}
        />
      </EditorContainer>
    </div>
  );
};
