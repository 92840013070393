import { VirtualBackground } from '../types';

const initialVirtualBgState: VirtualBackground = {
  url: '',
  type: 'none',
  index: -1,
  privacy: 'PUBLIC',
  id: '',
};

const VB_STORAGE_KEY = 'covideoVirtualBackground';

export const getVirtualBackgroundFromStorage = (
  id: string
): VirtualBackground => {
  const vbSelection = localStorage.getItem(VB_STORAGE_KEY) || '';
  try {
    const savedVirtualBackground: Record<string, VirtualBackground> =
      JSON.parse(vbSelection);
    if (savedVirtualBackground && typeof savedVirtualBackground === 'object') {
      return savedVirtualBackground?.[id] || initialVirtualBgState;
    }
    return initialVirtualBgState;
  } catch (ex) {
    return initialVirtualBgState;
  }
};

export const getAllVBFromStorage = (): Record<string, VirtualBackground> => {
  const vbSelection = localStorage.getItem(VB_STORAGE_KEY) || '';
  try {
    const savedVirtualBackground: Record<string, VirtualBackground> =
      JSON.parse(vbSelection);
    if (savedVirtualBackground && typeof savedVirtualBackground === 'object') {
      return savedVirtualBackground;
    }
    return {};
  } catch (ex) {
    return {};
  }
};

export const saveVirtualBackgroundToStorage = (
  currentVirtualBackground: VirtualBackground,
  id: string
) => {
  const currentVb = getAllVBFromStorage();
  const newItems = { ...currentVb, [id]: currentVirtualBackground };

  localStorage.setItem(VB_STORAGE_KEY, JSON.stringify(newItems));
};
