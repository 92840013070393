import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';
import { decodeText, encodeText } from './utils';
import { CreateSnippetParams } from './types';

export const createSnippet = async ({ data }: CreateSnippetParams) => {
  const { emailText, smsText } = data;
  const encodedData = encodeText(emailText, smsText);
  const response = await EXPRESS_API.post(`/snippets`, {
    ...data,
    emailText: encodedData.emailText,
    smsText: encodedData.smsText,
  });
  const decodedSnippet = await decodeText(response.data);
  return decodedSnippet;
};

export const useCreateSnippetMutation = () => {
  return useMutation((data: CreateSnippetParams) => createSnippet(data), {
    onError: () => {
      errorToast({
        title: 'An error occurred creating template, please try again later!',
      });
    },
    onSuccess: () => {
      successToast({
        title: `You have successfully created a new template`,
      });
    },
  });
};
