import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { metricsKeys } from './metricsKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export type TopVideosCtasParams = {
  from?: string;
  to?: string;
  users?: string;
  retryCount: number;
};

export type TopCtaVideosResponseData = {
  linksetID: number;
  setTitle: string;
  linkId: number;
  linkText: string;
  cnt: string;
};

const getTopCtaVideos = async (
  params: TopVideosCtasParams
): Promise<TopCtaVideosResponseData[]> => {
  return (
    await EXPRESS_API.get(`metrics/top/ctas`, {
      params,
    })
  ).data;
};

export const useGetTopCtaVideosQuery = ({
  params,
  enabled,
}: {
  params: TopVideosCtasParams;
  enabled: boolean;
}) => {
  const { showError } = useToastError();
  return useQuery(metricsKeys.topCta(params), () => getTopCtaVideos(params), {
    onError: showError,
    refetchOnMount: true,
    enabled,
  });
};
