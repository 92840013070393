import * as React from 'react';
import styled from 'styled-components/macro';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { theme } from 'lib/style';
import { reorder } from 'lib/utils/array';
import { LoadingIndicator, Tooltip } from 'lib/components';
import { useAuth } from 'lib/context';
import { FiCheck } from 'react-icons/fi';
import { AccessRole } from '../../../../../../lib/const';
import { IoMdReorder } from 'react-icons/io';
import { MdDeleteForever, MdEdit } from 'react-icons/md';

interface Props {
  attributes: any[];
  showEditModal: (params: any) => void;
  showDeleteModal: (params: any) => void;
  updateAttributesOrder: (params: any) => void;
  reorderLoading?: boolean;
}

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #eeeff2;
  padding: 16px 0;
`;

const HeaderTitle = styled.div`
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  box-sizing: border-box;
`;

const AttributeItem = styled.div`
  display: flex;
  border-bottom: 1px solid #eeeff2;
`;

const AttributeValue = styled.div`
  width: 100%;
  height: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  padding: 16px 0;
  display: flex;
  // div {
  //   margin-left: 24px;
  // }
`;

const Actions = styled.div`
  display: flex;
  padding-top: 8px;
  width: 116px;
  flex: 0 0 116px;
`;

const DefaultButton = styled.div`
  min-width: 20px;
  margin-left: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary['non_alpha']};
  height: 40px;
  width: 46px;
  border-radius: 4px;
  cursor: pointer;
  transition: width 0.2s ease;
  overflow: hidden;
  &:hover {
    opacity: 0.6;
  }
  ${theme.mediaQueryMinWidth.md} {
    margin-left: 12px;
    margin-right: 0;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  line-height: 1.1;
  &:hover {
    cursor: pointer;
    figcaption {
      z-index: 2;
      opacity: 1;
      width: auto;
      position: absolute;
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  background-color: rgba(0, 27, 83, 0.05);
  color: rgb(0, 27, 83);
  height: 32px;
  width: 32px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 4px;
`;

export const AttributesList = ({
  attributes,
  showEditModal,
  showDeleteModal,
  updateAttributesOrder,
  reorderLoading,
}: Props) => {
  const { userData } = useAuth();

  const showReadOnlyAttribute =
    userData.customer.hasReadOnlyVideoAttributeConstraint === '1';

  const showRequiredAttribute = userData.access === AccessRole.ADMIN;

  const showDepartments =
    userData.customer.hasVideoAttributeDepartmentLimit === '1';

  const onDragEnd = async (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const updatedAttributes = reorder(
      attributes,
      result.source.index,
      result.destination.index
    );

    updateAttributesOrder(updatedAttributes);
  };

  const trimDepartmentNames = (departmentArray: any) => {
    if (!departmentArray || !departmentArray.length) {
      return;
    }
    let limit = 5;
    let vDept = [...departmentArray];
    const rDept = [];
    while (limit > 0) {
      let t = vDept.shift();
      if (!t) {
        return;
      }
      rDept.push(t.name);
      limit -= t.name.length;
      t = t.name;
    }
    if (vDept.length) {
      rDept.push(`+${vDept.length}`);
      vDept = vDept.map(dept => dept.name);
    }

    return { vDept, rDept };
  };

  return (
    <div>
      <LoadingIndicator isLoading={reorderLoading} />
      {!reorderLoading && (
        <>
          <Header>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <HeaderTitle style={{ width: '48px', minWidth: '48px' }} />
              <HeaderTitle>Title</HeaderTitle>
              <HeaderTitle>ID</HeaderTitle>
              <HeaderTitle>Type</HeaderTitle>
              {showDepartments && <HeaderTitle>Departments</HeaderTitle>}
              {showRequiredAttribute && (
                <HeaderTitle style={{ width: '50%' }}>Required</HeaderTitle>
              )}
              {showReadOnlyAttribute && (
                <HeaderTitle style={{ width: '50%' }}>Read-only</HeaderTitle>
              )}
              <HeaderTitle style={{ width: '116px', minWidth: '116px' }} />
            </div>
          </Header>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='attributes'>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {attributes.map((attribute, index) => {
                    (attribute.departments || []).map((department: any) => {
                      return department.name;
                    });

                    const trimResult = trimDepartmentNames(
                      attribute.departments
                    );
                    return (
                      <Draggable
                        draggableId={attribute.slug}
                        index={index}
                        key={attribute.attributeId}
                      >
                        {provided => (
                          <AttributeItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              style={{
                                fontSize: '16px',
                                width: '48px',
                                height: '56px',
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: '48px',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                              }}
                            >
                              <IoMdReorder />
                            </div>
                            <div style={{ display: 'flex', width: '100%' }}>
                              {/* SUS-796 changes */}
                              <AttributeValue>
                                {attribute.title.length > 20
                                  ? attribute.title.substring(0, 20) + '...'
                                  : attribute.title}
                              </AttributeValue>
                              {/* SUS-796 changes */}
                              <AttributeValue>
                                {attribute.slug.length > 20
                                  ? attribute.slug.substring(0, 20) + '...'
                                  : attribute.slug}
                              </AttributeValue>
                              <AttributeValue>{attribute.type}</AttributeValue>
                              {showDepartments && (
                                <AttributeValue>
                                  <TooltipWrapper>
                                    {trimResult?.rDept.join(', ') || '-'}

                                    <Tooltip
                                      text={trimResult?.vDept.join(', ')}
                                      topPixels={-60}
                                      widthPixels={240}
                                    />
                                  </TooltipWrapper>
                                </AttributeValue>
                              )}
                              {showRequiredAttribute && (
                                <AttributeValue style={{ width: '50%' }}>
                                  {!!attribute.isRequired && (
                                    <IconContainer>
                                      <FiCheck />
                                    </IconContainer>
                                  )}
                                </AttributeValue>
                              )}
                              {showReadOnlyAttribute && (
                                <AttributeValue style={{ width: '50%' }}>
                                  {!!attribute.isReadOnly && (
                                    <IconContainer>
                                      <FiCheck />
                                    </IconContainer>
                                  )}
                                </AttributeValue>
                              )}
                            </div>
                            <Actions>
                              <DefaultButton
                                onClick={() => showEditModal(attribute)}
                              >
                                <MdEdit
                                  color={theme.palette.primaryDarkBlue}
                                  size={18}
                                />
                              </DefaultButton>
                              <DefaultButton
                                onClick={() => showDeleteModal(attribute)}
                                style={{ backgroundColor: '#e84c3d19' }}
                              >
                                <MdDeleteForever
                                  size={18}
                                  color={theme.palette.buttonDelete}
                                />
                              </DefaultButton>
                            </Actions>
                          </AttributeItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </div>
  );
};
