import { Gap } from 'lib/components/styles/layout';
import { toMMSS } from 'lib/utils/functions';
import React from 'react';
import { Button } from 'react-covideo-common';
import { IoMdCheckmark } from 'react-icons/io';
import { MdOutlinePause, MdVideocam } from 'react-icons/md';
import styled from 'styled-components/macro';
import { RECORDING_STATUS } from './types';

const RecordingFlowWrapper = styled(Gap)`
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;
const FinishBtnWrapper = styled(Gap)`
  position: absolute;
  width: 100%;
  @media screen and (max-width: 768px) {
    position: initial;
    width: unset;
  }
`;

interface VideoRecorderActionsProps {
  status: RECORDING_STATUS;
  timeInSeconds: number;
  startCountDown: () => void;
  stopCountDown: () => void;
  pauseRecording: () => void;
  stopRecording: () => void;
  isLoadingVB?: boolean;
}
const VideoRecorderActions: React.FC<VideoRecorderActionsProps> = ({
  status,
  timeInSeconds,
  startCountDown,
  stopCountDown,
  pauseRecording,
  stopRecording,
  isLoadingVB,
}) => {
  const preparingFlow =
    status === RECORDING_STATUS.CAMERA_READY ||
    status === RECORDING_STATUS.COUNTDOWN;

  const recordingFlow =
    status === RECORDING_STATUS.PAUSED || status === RECORDING_STATUS.RECORDING;

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {preparingFlow && (
        <Gap justifyContent='center' width='100%' m='36px 0 0 0'>
          {status === RECORDING_STATUS.CAMERA_READY && !isLoadingVB && (
            <Button
              variant='red'
              icon={<MdVideocam size={24} />}
              text='Start recording'
              onClick={startCountDown}
            />
          )}
          {status === RECORDING_STATUS.COUNTDOWN && (
            <Button
              variant='secondary'
              text={'Cancel'}
              onClick={stopCountDown}
            />
          )}
        </Gap>
      )}
      {recordingFlow && (
        <RecordingFlowWrapper m='32px 0 0 0'>
          <Gap style={{ position: 'relative', zIndex: 22 }}>
            <Button
              variant={status === RECORDING_STATUS.PAUSED ? 'red' : 'secondary'}
              icon={
                status === RECORDING_STATUS.PAUSED ? (
                  <MdVideocam size={24} />
                ) : (
                  <MdOutlinePause />
                )
              }
              text={status === RECORDING_STATUS.PAUSED ? 'Resume' : 'Pause'}
              onClick={pauseRecording}
            />
            <span style={{ width: 50 }}>{toMMSS(timeInSeconds, 0)}</span>
          </Gap>
          <FinishBtnWrapper alignItems='center' justifyContent='center'>
            <Button
              icon={<IoMdCheckmark size={20} />}
              variant='primary'
              text={'Finish'}
              onClick={stopRecording}
            />
          </FinishBtnWrapper>
        </RecordingFlowWrapper>
      )}
    </div>
  );
};

export default VideoRecorderActions;
