import { EXPRESS_API } from 'configs/api/expressApi';
import { customVirtualBackgroundKeys } from './customVirtualBackgroundKeys';
import { useQuery } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';

export interface ICustomVirtualBackground {
  id: number;
  resellerId: number | null;
  customerId: number | null;
  url: string;
}
const getCustomVirtualBackgrounds = async (): Promise<
  ICustomVirtualBackground[]
> => {
  return (await EXPRESS_API.get(`custom-virtual-background`)).data;
};

export const useCustomVirtualBackgroundQuery = () => {
  const { data, isLoading, refetch } = useQuery(
    customVirtualBackgroundKeys.all(),
    () => getCustomVirtualBackgrounds(),
    {
      refetchOnMount: true,
      onError: () => {
        errorToast({
          title:
            "We're currently experiencing difficulties fetching custom virtual backgrounds",
        });
      },
    }
  );
  return { data: data || [], isLoading, refetch };
};
