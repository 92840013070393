import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

type ContainerProps = {
  isModalOpen?: boolean;
};

const Container = styled.div<ContainerProps>`
  height: calc(100vh - 120px);
`;

const MainArea = styled.div`
  padding-bottom: 64px;
  overflow-y: auto;
  ${theme.mediaQueryMinWidth.md} {
    padding-bottom: 0;
  }
`;

type Props = {
  children?: React.ReactNode;
  main: React.ReactNode;
  modal?: React.ReactNode[];
  contentSupport?: React.ReactNode;
  switchToClassic?: React.ReactNode;
};

export const PageBodyTemplate = ({
  main,
  modal,
  children,
  contentSupport,
  switchToClassic,
}: Props) => {
  const isModalOpen = !!modal && modal.some(element => !!element);
  return (
    <Container isModalOpen={isModalOpen}>
      {children}
      <MainArea>
        <>
          {main}
          {switchToClassic}
          {contentSupport}
        </>
      </MainArea>
      {modal}
    </Container>
  );
};
