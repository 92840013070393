import { EXPRESS_API } from 'configs/api/expressApi';
import { UpdateCarServiceParams } from 'lib/api/carService/types';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { carServiceKeys } from './carServiceKeys';

const updateCarService = async ({
  serviceId,
  data,
}: UpdateCarServiceParams) => {
  const response = await EXPRESS_API.put(`/services/${serviceId}`, data);
  return response.data;
};

export const useUpdateCarServiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCarService, {
    onSuccess: () => {
      queryClient.invalidateQueries(carServiceKeys.all());
      successToast({
        title: `Service successfully updated!`,
      });
    },
    onError: () => {
      errorToast({
        title: `Failed to update service.`,
      });
    },
  });
};
