import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { emailKeys } from './emailKeys';
import { SendEmailParams } from './types';

interface IResponse {
  message: string;
}

const sendVideoEmail = async (
  videoId: string | number,
  body: SendEmailParams
) => {
  const { data } = await EXPRESS_API.post(`email/${videoId}/video`, body);

  return data as IResponse;
};

export const useSendVideoEmail = (videoId: string | number) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation((data: SendEmailParams) => sendVideoEmail(videoId, data), {
    onError: (err: AxiosError) => showError(err),
    onSuccess: () => {
      queryClient.invalidateQueries(emailKeys.limit());
    },
  });
};
