import * as React from 'react';
import { Main } from './main';
import { VideoData, VideoAppearanceData } from 'lib/hooks';

interface ComponentProps {
  videoAppearanceData: VideoAppearanceData;
  videoDetails: VideoData;
  view?: string;
  openVideoDeleteModal: Function;
  setVideoIdState: (videoId: string) => void;
  setReloadVideoDetails: Function;
}

export const PreLoader = (props: ComponentProps) => {
  const {
    videoDetails,
    openVideoDeleteModal,
    view,
    videoAppearanceData,
    setVideoIdState,
    setReloadVideoDetails,
  } = props;

  return (
    <Main
      videoAppearanceData={videoAppearanceData}
      videoDetails={videoDetails}
      setReloadVideoDetails={setReloadVideoDetails}
      view={view}
      openVideoDeleteModal={openVideoDeleteModal}
      setVideoIdState={setVideoIdState}
    />
  );
};
