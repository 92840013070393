import React, { useState } from 'react';
import { theme } from 'lib/style';
import onboardingStepTwoAnimationForMyself from 'lib/animations/OnBoarding_Step_4.json';
import personWithCar from 'assets/images/freemiumOnboarding/personWithCar.svg';
import { FreemiumOnboardingPageTemplate } from '../components';
import { useFreemiumOnboardingQuery } from 'lib/api/freemiumOnboarding/getFreemiumOnboarding';
import { LoadingIndicator } from 'lib/components';
import { Heading, Label, MainHeading, TextInputField } from './index.styled';
import { useAuth } from 'lib/context';
import CountryCodePicker from 'lib/components/country-code-picker/CountryCodePicker';
import styled, { useTheme } from 'styled-components/macro';
import {
  getCountries,
  getCountryCallingCode,
  isSupportedCountry,
} from 'react-phone-number-input/input';
import laptopIcon from 'assets/images/freemiumOnboarding/laptop.svg';
import { Gap } from 'lib/components/styles/layout';

const PhoneWrapper = styled.div`
  width: 488px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  ${theme.mediaQueryMaxWidth.sm} {
    width: 100%;
  }
  ${theme.mediaQueryMaxWidth.xs} {
    width: 100%;
  }
`;

const CountryCode = styled.div`
  width: 136px;
`;

const Phone = styled.div`
  padding-left: 8px;
  width: 348px;
`;

const HighlightedText = styled.div<{ color: string }>`
  display: inline;
  color: ${props =>
    props.color ? props.color : theme.palette.primaryThemeColor};
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const Myself = () => {
  const { userData } = useAuth();
  const themes = useTheme();
  const { data: freemiumOnboardingData, isLoading } =
    useFreemiumOnboardingQuery();
  const [jobTitle, setJobTitle] = useState(
    freemiumOnboardingData?.jobTitle || ''
  );

  const isAutomotive = userData.isAutomotive;

  const [phone, setPhone] = useState(freemiumOnboardingData?.phone || '');

  const [extension, setExtension] = useState('US');

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  const getCountryCode = () => {
    const country = getCountries().find(country => country === extension);
    if (!country) {
      return '';
    }
    return `+${
      isSupportedCountry(country || 'US') && getCountryCallingCode(country)
    }`;
  };

  const isNextStepDisabled = () => {
    if (jobTitle === '') {
      return true;
    }
    if (isAutomotive && phone === '') {
      return true;
    }

    return false;
  };
  return (
    <FreemiumOnboardingPageTemplate
      currentStep={2}
      stepText='About you'
      nextStepRoute='/email-verification'
      goToNextStepDisabled={isNextStepDisabled()}
      rightColumnAnimationSrc={
        isAutomotive ? undefined : onboardingStepTwoAnimationForMyself
      }
      rightColumnChildren={
        <Gap justifyContent='center'>
          {isAutomotive ? (
            <img alt='icon' src={personWithCar} />
          ) : (
            <img alt='laptop' src={laptopIcon} />
          )}
        </Gap>
      }
      animationLoop={true}
      dataToBeSaved={{
        jobTitle,
        phone: !!phone ? `${getCountryCode()}${phone}` : '',
      }}
      leftColumnHeader={
        <>
          <MainHeading>Hi {userData?.firstName}!</MainHeading>
          <Heading>
            Let’s personalize your Covideo experience{' '}
            <HighlightedText color={themes.colors.primary[100]}>
              in just 2 steps!
            </HighlightedText>
          </Heading>
        </>
      }
      leftColumnChildren={
        <>
          <Label margin='0 0 8px 0'>Job Title</Label>
          <TextInputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setJobTitle(e.target.value)
            }
            value={jobTitle}
            autoFocus={true}
          />
          <Label margin='16px 0 8px 0'>
            Phone Number {isAutomotive ? '' : '(Optional)'}
          </Label>
          <PhoneWrapper>
            <CountryCode>
              <CountryCodePicker ext={extension} setExtension={setExtension} />
            </CountryCode>
            <Phone>
              <TextInputField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPhone(e.target.value)
                }
                value={phone}
                placeholder='Phone Number'
                autoFocus={true}
              />
            </Phone>
          </PhoneWrapper>
        </>
      }
    />
  );
};
