import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List, SendAndShare } from './';
import { VideoPages } from 'lib/const';

export const Main = () => {
  return (
    <Switch>
      <Route path='/home' component={List} exact={true} />
      <Route
        path={`/home/${VideoPages.SEND_AND_SHARE}/:videoId`}
        component={SendAndShare}
        exact={true}
      />
    </Switch>
  );
};
