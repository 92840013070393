import React, { useState } from 'react';
import { MdInfo } from 'react-icons/md';
import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { LoadingIndicator, Modal } from 'lib/components';
import { theme } from 'lib/style';
import { REPORT_SCOPE, sendReportData } from 'lib/api';
import { useAuth } from 'lib/context';
import { RecipientsSelector } from './RecipientsSelector';
import { ReportTypes } from 'lib/const/ReportData';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 32px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: center;
  background: rgba(182, 224, 236, 0.2);
  border-radius: 5px;
  padding: 12px;
  > svg {
    margin-right: 10px;
  }
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
  margin-bottom: 8px;
`;

const Footer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

type Props = {
  title: string;
  handleModalClose: () => void;
  onSent: () => void;
  startDate: Date;
  endDate: Date;
  range: string;
  reportUrl: string;
  users?: string[];
  customer?: string;
  showRecipientsSelector?: boolean;
  regionId?: number;
  organizationRegionId?: number;
  reportScope?: REPORT_SCOPE;
  organizationId?: string | number;
  reports?: ReportTypes[];
};

export const GenerateReportModal = (props: Props) => {
  const [recipients, setRecipients] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const {
    userData: { email },
  } = useAuth();

  const {
    title,
    startDate,
    endDate,
    reportUrl,
    handleModalClose,
    onSent,
    range,
    users = [],
    customer,
    showRecipientsSelector = false,
    regionId = 0,
    organizationRegionId = 0,
    reportScope = REPORT_SCOPE.RESELLER,
    organizationId = 0,
    reports,
  } = props;

  const { showError } = useToastError();

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const generateReport = async () => {
    setLoading(true);
    try {
      await sendReportData(
        reportUrl,
        startDate,
        endDate,
        range,
        [email, ...recipients],
        users,
        customer,
        { regionId, organizationRegionId },
        reportScope,
        organizationId,
        reports
      );
      onSent();
    } catch (error) {
      console.log(error);
      setLoading(false);
      showError(error);
    }
  };

  return (
    <Modal>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>{title}</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>

        <Content>
          {loading && <LoadingIndicator isLoading={loading} height='50px' />}
          {!loading && (
            <div>
              <InfoBox>
                <MdInfo size={20} color='rgba(0, 27, 83, 0.6)' />
                <div>The exported file will be sent to {email}.</div>
              </InfoBox>
              {showRecipientsSelector && (
                <div style={{ marginTop: '24px' }}>
                  <Label>Share the report with additional recipients</Label>
                  <RecipientsSelector
                    onChange={recipients => setRecipients(recipients)}
                    showIcon={true}
                    isClearable={false}
                    onError={email => {
                      if (email) {
                        setError('Invalid email address.');
                      } else {
                        setError('');
                      }
                    }}
                  />
                </div>
              )}
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </div>
          )}
        </Content>
        <Footer>
          <Button
            text='Generate and Send'
            onClick={() => generateReport()}
            disabled={loading}
          />
        </Footer>
      </div>
    </Modal>
  );
};
