import React, { useEffect, useState } from 'react';
import { useGetRessellers } from 'lib/api/superadmin/resellerQuery';
import { VISIBILITY_TYPE } from './SelectableArea';
import { useVisibilityContextProvider } from '../provider/context';
import { SelectableBodyWrapper } from './components/SelectableBodyWrapper';
import { SelectableMainWrapper } from './components/SelectableMainWrapper';
import { SelectableBodySearch } from './components/SelectableBodySearch';
import { SelectableListItem } from './components/SeletableListItem';
import { LoadingIndicator } from 'lib/components';
import { SelectableLoadingWrapper } from './components/SelectableLoaderWrapper';
import { VisibilityMessage } from '../VisibilityMessage';
import { MdRefresh } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { useQueryClient } from 'react-query';
import { superAdminKeys } from 'lib/api/superadmin/superAdminKeys';
import { sortItemsByChecked } from '../provider/const';
import { Reseller } from '../provider/types';
import { SelectableResellersHeader } from './components/SelectableResellersHeader';

interface IProps {
  item: VISIBILITY_TYPE;
  onItemClick: (id: number, item: VISIBILITY_TYPE) => void;
  selectedResellerId: number | undefined;
  openHowToShareModal: () => void;
  resetAllSelection: () => void;
  resetSelectedCustomers: () => void;
}

interface ITransformedResslers {
  displayText: string;
  checked: boolean;
  id: number;
  isPartiallySelected: boolean;
  dealer: number;
}

export const SelectableReslleres = ({
  item,
  onItemClick,
  selectedResellerId,
  openHowToShareModal,
  resetAllSelection,
  resetSelectedCustomers,
}: IProps) => {
  const [search, setSearch] = useState('');
  const [isInitiated, setIsInitiated] = useState(false);
  const {
    setResellers,
    state,
    incrementResellersPageCount,
    selectAllResellers,
    deselectAllResellers,
    updateCovideoShare,
    updateResellerChecked,
  } = useVisibilityContextProvider();
  const SIZE = 500;
  const queryClient = useQueryClient();

  const { data, isLoading, refetch, isRefetching } = useGetRessellers(
    search,
    state.resellersCurrentPage * SIZE,
    SIZE,
    item === VISIBILITY_TYPE.RESELLERS
  );

  const onSearchHandler = (value: string) => {
    setSearch(value);
  };

  const onSwitchButtonChangeHandler = (value: string) => {
    queryClient.removeQueries(superAdminKeys.all_customers());
    queryClient.removeQueries(superAdminKeys.all_users());
    if (data) {
      updateCovideoShare(value === 'all' ? true : false, data?.[0]);
      resetAllSelection();
    }
  };

  const onCheckboxClickHandler = (id: number, checked: boolean) => {
    resetSelectedCustomers?.();
    return updateResellerChecked(id, checked);
  };

  const filterBySearchValue = (reseller: ITransformedResslers) =>
    reseller.displayText
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase());

  useEffect(() => {
    if (data) {
      setResellers(data?.[0], isInitiated);
      setIsInitiated(true);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [search, state.resellersCurrentPage]);

  const resellers = state?.resellers || {};

  const checkGroupIndicator =
    state.resellerCount > 0 &&
    Object.keys(resellers).length !== state.resellerCount;

  const checked = Object.keys(resellers).length === state.resellerCount;

  if (isLoading) {
    return (
      <SelectableLoadingWrapper>
        <LoadingIndicator isLoading={true} />
      </SelectableLoadingWrapper>
    );
  }
  const { initialCheckedResselers } = state;
  const sortedResellers = sortItemsByChecked(
    resellers,
    initialCheckedResselers,
    'resellerId'
  );

  const transformedResellers = Object.keys(resellers).length
    ? sortedResellers
        ?.map((reseller: Reseller) => {
          return {
            displayText: `${reseller?.resellerName}`,
            checked: reseller?.checked,
            id: reseller.resellerId,
            isPartiallySelected: reseller.isPartiallySelected,
            dealer: reseller.dealer,
          };
        })
        .sort((a, b) => {
          if (b.dealer !== a.dealer) {
            return b.dealer - a.dealer;
          }

          return 1;
        })
    : [];

  return (
    <SelectableMainWrapper>
      <SelectableResellersHeader
        isRefetching={isRefetching}
        onSearch={onSearchHandler}
        defaultValue={state.sharedWithCovideo ? 'all' : 'manually'}
        isAllChecked={state.sharedWithCovideo}
        onSwitchButtonChange={onSwitchButtonChangeHandler}
      />
      {state.sharedWithCovideo ? (
        <VisibilityMessage
          message='This guide will be shared with all current and new resellers.'
          openHowToShareModal={openHowToShareModal}
        />
      ) : (
        <SelectableBodyWrapper>
          <SelectableBodySearch
            checkGroupIndicator={checkGroupIndicator}
            checked={checked}
            onSelectAll={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              const { checked } = event.target as HTMLInputElement;
              resetAllSelection();
              if (checked) {
                return selectAllResellers();
              }
              return deselectAllResellers();
            }}
            message={`${state.resellerCount} resellers`}
          />
          <SelectableListItem
            onCheckboxClick={onCheckboxClickHandler}
            resetSelectedCustomers={resetSelectedCustomers}
            data={
              !!search
                ? transformedResellers.filter(filterBySearchValue)
                : transformedResellers
            }
            onItemClick={onItemClick}
            type={item}
            selectedId={selectedResellerId}
          />

          {data && data?.[1] <= Object.keys(resellers).length ? null : (
            <Button
              onClick={incrementResellersPageCount}
              text={isRefetching ? 'Loading...' : 'Load more'}
              icon={<MdRefresh size={16} />}
              variant='secondary'
              disabled={isRefetching}
            />
          )}
        </SelectableBodyWrapper>
      )}
    </SelectableMainWrapper>
  );
};
