import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { EXPRESS_API } from 'configs/api/expressApi';

type LesaParams = {
  url: string;
  fileName: string;
};

const copyLesaVideo = async (params: LesaParams) => {
  return await EXPRESS_API.post(`lesa/videos`, { ...params });
};

export const useLesaVideosCopyMutation = ({
  onSuccesCallback,
}: {
  onSuccesCallback: () => void;
}) => {
  const { showError } = useToastError();
  return useMutation(copyLesaVideo, {
    onSuccess: () => {
      successToast({ title: 'Video has been added!' });
      onSuccesCallback();
    },
    onError: error =>
      showError(error, 'An error occured while trying to add the video!'),
  });
};
