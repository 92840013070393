import { useVisibilityContextProvider } from 'app/pages/guides/provider/context';
import { useQuery } from 'react-query';
import { guidesKeyes } from './guidesKeyes';
import { Ressellers } from 'app/pages/guides/provider/types';
import { useAuth } from 'lib/context';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { EXPRESS_API } from 'configs/api/expressApi';

export type QueryListParams = {
  categoryId: number | undefined;
};

const getVisibilty = async ({
  categoryId,
}: QueryListParams): Promise<{
  sharedWithCovideo: boolean;
  resellers: Ressellers;
}> => {
  return (await EXPRESS_API.get(`guide-category/${categoryId}/visibility`))
    .data as { sharedWithCovideo: boolean; resellers: Ressellers };
};

export const useVisibilityQuery = (id: number | undefined) => {
  const { setInitialState } = useVisibilityContextProvider();
  const { userData } = useAuth();
  const isSuperAdmin =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN;
  const isAccess = userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;

  return useQuery(
    guidesKeyes.visibility(),
    () => getVisibilty({ categoryId: id }),
    {
      onSuccess: data => {
        const resellerId = Number(userData.resellerId);
        const customerId = Number(userData.customerId);
        const init = {
          [resellerId]: {
            dealer: Number(userData.reseller.dealer),
            resellerId: resellerId,
            resellerName: userData.reseller.resellerName,
            checked: !isAccess
              ? data?.resellers?.[resellerId]?.checked || false
              : false,
            customers: {
              [customerId]: {
                resellerId: resellerId,
                business: userData.customer.business,
                customerId: customerId,
                checked:
                  data?.resellers?.[resellerId]?.customers?.[customerId]
                    ?.checked || false,
              },
            },
          },
        } as unknown as Ressellers;

        const state =
          Object.keys(data?.resellers || {}).length === 0
            ? init
            : data.resellers;

        setInitialState(isSuperAdmin ? data.sharedWithCovideo : false, state);
      },
      enabled: !!id,
    }
  );
};
