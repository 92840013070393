import { EXPRESS_API } from 'configs/api/expressApi';
import { getCurrentToken } from 'configs/api/token';
import { useMutation } from 'react-query';

export interface IRegenerateTokenResponse {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
}

const regenerateToken = async (): Promise<IRegenerateTokenResponse> => {
  const { token, refreshToken } = getCurrentToken();

  const response = await EXPRESS_API.post('auth/regenerate', {
    accessToken: token,
    refreshToken: refreshToken,
  });
  return response.data;
};

export const useRegenerateTokenMutation = () => {
  return useMutation(regenerateToken);
};
