import React from 'react';
import {
  FieldLabel,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
  getInputField,
} from '../../../components/AdminFormComponents';
import { Tooltip } from 'react-covideo-common';
import { MdInfo } from 'react-icons/md';
import { theme } from 'lib/style';

export const ContactSection = (props: UserFormProps) => {
  const { isSubmitting, isNewUser, hasEmail2Field } = props;

  return (
    <Section id={'contactInfo'}>
      <SectionTitle>Contact</SectionTitle>
      <RowWrapper>
        {!isNewUser && (
          <Row>
            <FieldLabel>Email</FieldLabel>
            {getInputField('email', true, false, isSubmitting, 'email')}
          </Row>
        )}
        {!isNewUser && hasEmail2Field && (
          <Row>
            <FieldLabel>Email 2</FieldLabel>
            {getInputField('email2', false, false, isSubmitting, 'email')}
          </Row>
        )}
        <Row>
          <FieldLabel>Phone 1</FieldLabel>
          {getInputField('phone1', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>Phone 2</FieldLabel>
          {getInputField('phone2', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>
            Cell Phone
            <Tooltip
              popup={
                'This is useful for delivering important onboarding content to each user.'
              }
              position='right'
              extendStyles={{
                popup: {
                  width: '310px',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: theme.palette.neutral100,
                  padding: '8px 12px',
                  fontWeight: '400',
                },
              }}
            >
              <MdInfo
                style={{
                  top: '2px',
                }}
              />
            </Tooltip>
          </FieldLabel>
          {getInputField('cellPhone', true, false, isSubmitting)}
        </Row>
      </RowWrapper>
    </Section>
  );
};
