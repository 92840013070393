import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { videoRequestKeys } from './videoRequestKeys';

const getVideoRequest = async (videoRequestId: string | number) => {
  const response = await EXPRESS_API.get(`/video-requests/${videoRequestId}`);
  return response.data;
};

export const useVideoRequestQuery = (videoRequestId: string) => {
  return useQuery(
    videoRequestKeys.single(videoRequestId),
    () => getVideoRequest(videoRequestId),
    {
      enabled: !!videoRequestId,
      refetchOnMount: true,
    }
  );
};
