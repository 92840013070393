import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { smsKeys } from './smsKeys';
import { SmsOverviewParams, SmsOverviewResponse } from './types';

export const getSmsOverview = async (params: SmsOverviewParams) => {
  const response = await EXPRESS_API.get(`/sms/overview`, {
    params: {
      ...params,
      utcOffset: new Date().getTimezoneOffset(),
    },
  });

  return response.data as SmsOverviewResponse;
};

export const useQuerySmsOverview = (params: SmsOverviewParams) => {
  const { showError } = useToastError();
  return useQuery(smsKeys.all(), () => getSmsOverview(params), {
    onError: showError,
    refetchOnMount: true,
  });
};
