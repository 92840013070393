import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';
import { IoMdSearch } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';
import { getTags } from '../../api/videoTagsApi';
import { Folder } from 'lib/api/folders/getFolders';

interface ComponentProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: (params: any) => void;
  placeholder?: string;
  initialValue?: string;
  disabled?: boolean;
  prevSearch?: string;
  width?: string;
  videoTags?: object[];
  currentFolder?: Folder;
  useTags?: boolean;
  className?: string;
  bgColor?: string;
  dataCy?: string;
}

type SearchBarWrapperProps = {
  disabled?: boolean;
  width: string;
  bgColor?: string;
};

const SearchBarWrapper = styled.div<SearchBarWrapperProps>`
  display: flex;
  justify-content: space-between;
  ${theme.fontNormal500}
  font-size: ${theme.fontSizes.md};
  color: black;
  border: 1px solid ${theme.palette.grayBorder};
  background-color: ${props => (props.bgColor ? props.bgColor : '#F2F4F6')};
  border-radius: 4px;
  box-sizing: border-box;
  width: ${props => (props.width ? props.width : '200px')};
  height: 40px;
  opacity: ${props => (props.disabled ? 0.6 : 1)};

  ${theme.mediaQueryMinWidth.lg} {
    width: ${props => props.width};
  }
`;

const Icon = styled.div<{ disabled?: boolean; bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
  border-radius: 5px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#F2F4F6')};
  color: ${theme.palette.themeDark};
  font-size: 34px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

const Input = styled.input<{ bgColor?: string }>`
  background-color: ${props => (props.bgColor ? props.bgColor : '#F2F4F6')};
  box-sizing: border-box;
  ${theme.fontNormal500};
  border-radius: 5px;
  font-size: ${theme.fontSizes.md};
  border: 0;
  padding: 12px 8px 12px 12px;
  flex: 1;
  &:focus {
    outline: 0;
  }
`;

const TagOptions = styled.div`
  position: absolute;
  background-color: white;
  width: 266px;
  margin-top: 42px;
  max-height: 200px;
  z-index: 3;
  border-radius: 4px;
  border: 1px solid ${theme.palette.lightgray};
  max-height: 100px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const TagOption = styled.div`
  padding: 6px 12px;
  &:hover {
    cursor: pointer;
    background-color: ${theme.palette.secondaryBlue10Percent};
  }
`;

const Component = ({
  onSearch,
  placeholder,
  onChange,
  disabled,
  prevSearch,
  width = '100%',
  currentFolder,
  useTags = false,
  className = '',
  bgColor = '',
  dataCy = '',
}: ComponentProps) => {
  const [focused, setFocused] = useState(false);
  const [initialTags, setInitialTags] = useState([] as object[]);
  const [tags, setTags] = useState([] as object[]);
  const [searchTerm, setSearchTerm] = React.useState(prevSearch || '');

  const loadTags = async () => {
    let tags: any = await getTags({
      all: currentFolder?.name === 'Company',
      refresh: false,
    });
    const data = (tags || [])
      .map((t: any) => ({ value: t.tagId, label: t.tag }))
      .filter((t: any) => searchTerm !== t.value);
    setTags(data);
    setInitialTags(data);
  };

  React.useEffect(() => {
    if (!useTags) {
      return;
    }
    loadTags();
  }, [currentFolder]);

  React.useEffect(() => {
    if (typeof prevSearch !== 'undefined') {
      setSearchTerm(prevSearch);
    }
  }, [prevSearch]);

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearch(searchTerm);
    }
  };

  const handleSearchSubmit = () => {
    onSearch(searchTerm);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    setTags(
      initialTags.filter((tag: any) =>
        tag.label.match(new RegExp(e.currentTarget.value, 'ig'))
      )
    );
    if (onChange) {
      onChange(e);
    }
  };

  const onFocus = () => setFocused(true);
  const onBlur = () => {
    setTimeout(() => {
      setFocused(false);
    }, 300);
  };

  const clear = () => {
    setSearchTerm('');
    onSearch('');
  };
  return (
    <SearchBarWrapper
      {...(className !== '' ? { className: className } : {})}
      disabled={disabled}
      width={width}
      bgColor={bgColor}
    >
      <Input
        data-cy={dataCy}
        disabled={disabled}
        type='text'
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        onKeyUp={handleEnterKey}
        onFocus={onFocus}
        onBlur={onBlur}
        bgColor={bgColor}
      />
      {focused && tags && !!tags.length && (
        <TagOptions>
          {tags.map((tag: any) => (
            <TagOption onClick={() => onSearch(tag.label)} key={tag.value}>
              {tag.label}
            </TagOption>
          ))}
        </TagOptions>
      )}
      {searchTerm && (
        <>
          <Icon
            onClick={clear}
            style={{ width: 20, backgroundColor: 'transparent' }}
          >
            <MdCancel size={'18px'} color='#e84c3d' />
          </Icon>
          <div
            style={{
              margin: '8px 0 8px 6px',
              width: 1,
              background: theme.palette.grayBorder,
            }}
          />
        </>
      )}

      <Icon
        disabled={disabled}
        onClick={disabled ? () => {} : handleSearchSubmit}
        bgColor={bgColor}
        data-cy={dataCy ? `${dataCy}-icon` : ''}
      >
        <IoMdSearch size={'24px'} color={'#4e5461'} />
      </Icon>
    </SearchBarWrapper>
  );
};

export const Search = React.memo(Component);
