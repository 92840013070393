import React, { useState } from 'react';
import {
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';
import { Button } from 'react-covideo-common';
import { FastPasswordResetModal } from 'app/pages/management/components/FastPasswordResetModal';

type Props = { userId: number };

export const FastPasswordSection = ({ userId }: Props) => {
  const [showFastPasswordResetModal, setShowFastPasswordResetModal] =
    useState(false);
  return (
    <>
      <Section id={'fastPassword'}>
        <SectionTitle>Password</SectionTitle>
        <RowWrapper>
          <Row>
            <Button
              onClick={() => {
                setShowFastPasswordResetModal(true);
              }}
              text='Fast Password Reset'
            />
          </Row>
        </RowWrapper>
      </Section>

      {showFastPasswordResetModal && (
        <FastPasswordResetModal
          selectedUserIds={[userId]}
          handleModalClose={() => {
            setShowFastPasswordResetModal(false);
          }}
        />
      )}
    </>
  );
};
