import * as React from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';
import { useTableContext } from './TableContext';
import { useEffect } from 'react';
import { MdArrowDropUp } from 'react-icons/md';

const MAX_SIZE = 100;
type Props = {
  initSize?: number;
  onSizeChange?: Function;
  sizeOptions?: number[];
  text?: string;
};

const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 40px;
  box-sizing: border-box;
  border: solid 1px #f2f8ff;
  background-color: #f2f8ff;
`;

const PaginationLimitText = styled.div<{ margin: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.25;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
  font-weight: normal;
  font-size: ${theme.fontSizes.md};
  margin-left: ${props => props.margin};
`;

const ArrowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 32px;
  margin: 0 12px 0 4px;
  svg {
    opacity: 0.2;
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
    margin: 0 0 -4px 0;
    &:nth-of-type(2) {
      margin: -4px 0 0 0;
      transform: rotate(180deg);
    }
  }
`;

const PaginationLimitSelect = styled.input.attrs({ type: 'number' })`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.md};
  background-color: #f2f8ff;
  border: 0;
  color: black;
  display: flex;
  opacity: ${props => props.disabled && '.6'};
  width: 100%;
  height: 100%;
  margin: 0 0 0 16px;
  text-align: center;
  &::-webkit-inner-spin-button {
    display: none;
  }
  -moz-appearance: textfield;
  &:focus {
    outline: 0;
  }
`;

export const TablePaginationSize = ({
  initSize,
  onSizeChange = () => {},
  text,
}: Props) => {
  const { size, setSize } = useTableContext();

  useEffect(() => {
    if (!initSize) {
      return;
    }
    setSize(size);
  }, [initSize]);

  useEffect(() => {
    onSizeChange(size);
  }, [size]);

  const debouncedChangeHandler = (value: number) => {
    setSize(value);
  };

  const handleChange = (num: number) => {
    if (!num || num < 1 || num > MAX_SIZE) {
      return;
    }

    setSize(num);
    debouncedChangeHandler(num);
  };

  const increase = () => {
    handleChange(size + 10);
  };

  const decrease = () => {
    handleChange(size - 10);
  };

  return (
    <>
      {text && (
        <PaginationLimitText margin='0'>
          {text ? text : 'Results per page'} &nbsp;
        </PaginationLimitText>
      )}
      <SelectWrapper>
        <PaginationLimitSelect
          aria-label='Page Size'
          value={size}
          min={1}
          max={100}
          step={1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(Number(e.target.value))
          }
        />
        <ArrowsWrapper>
          <MdArrowDropUp
            onClick={increase}
            color={'#001B53'}
            width={'16px'}
            height={'16px'}
          />
          <MdArrowDropUp
            onClick={decrease}
            color={'#001B53'}
            width={'16px'}
            height={'16px'}
          />
        </ArrowsWrapper>
      </SelectWrapper>
      {!text && (
        <PaginationLimitText margin='16px'>
          Results per page
        </PaginationLimitText>
      )}
    </>
  );
};
