import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { Dropdown, NewModal } from 'lib/components';
import { SuperAdminCustomer, SuperAdminUser } from 'lib/context';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { getAllCustomersForSuperAdmin, updateMultipleUsers } from 'lib/api';
import { uniqBy, debounce } from 'lodash';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedUsers: SuperAdminUser[];
};

type Option = {
  value: number;
  label: string | number;
};

type DefaultOption = {
  customerId: number;
  business: string;
};

export const ChangeUserCustomerModal = ({
  handleModalClose,
  selectedUsers,
}: Props) => {
  const [loaded, setLoaded] = React.useState(false);
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [customerId, setUserCustomer] = useState<number>(0);
  const [customerDropdownOptions, setCustomersData] = useState<Option[]>([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [defaultData, setDefaultData] = React.useState<DefaultOption>({
    customerId: 0,
    business: 'All Customers',
  });
  const [page, setPage] = React.useState(0);
  const [pageCopy, setPageCopy] = React.useState(0);
  const [search, setSearch] = React.useState('');

  const headerText = `Change Customer for ${selectedUsers.length} user${
    selectedUsers.length > 1 ? 's' : ''
  }!`;

  const searchInput = debounce((e: string) => {
    setSearch(e);
    e ? setPage(0) : setPage(pageCopy);
  }, 600);

  const fetchCustomers = async () => {
    try {
      const limit = 10;
      const start = page * limit;
      const params = {
        start,
        limit,
        search,
        status: VerificationStatus.ALL,
      };
      const { customers, count } = await getAllCustomersForSuperAdmin(
        params
      ).catch(() => {
        return { customers: [] as SuperAdminCustomer[], count: 0 };
      });
      setLoaded(true);
      let customerOptions: Option[] = JSON.parse(
        JSON.stringify(customerDropdownOptions)
      );
      customers.forEach((e: SuperAdminCustomer) => {
        customerOptions = [
          ...customerOptions,
          {
            label:
              e.business && e.business.trim()
                ? `${e.business} - ${e.customerId}`
                : `No Name - ${e.customerId}`,
            value: e.customerId,
          },
        ];
      });
      customerOptions = uniqBy(customerOptions, 'value');
      setCustomersData(customerOptions);
      setCustomersCount(count);
      setLoadingModal(false);
    } catch (error) {
      setLoadingModal(false);
    }
  };

  const handleUpdateUsersCustomer = async () => {
    setLoadingModal(true);
    let errorMessage = '';
    try {
      let userIds = selectedUsers.map(user => user.id);
      await updateMultipleUsers({
        userIds,
        customerId,
      });
    } catch (error) {
      errorMessage = `Error in updating users Customer.`;
      console.log('Error', error);
    }
    handleModalClose(1);
    if (errorMessage) {
      errorToast({ title: errorMessage });
    } else {
      selectedUsers.length &&
        successToast({
          title: `Customer updated successfully for ${
            selectedUsers.length
          } user${selectedUsers.length > 1 ? 's' : ''}!`,
        });
    }
    setLoadingModal(false);
  };

  React.useEffect(() => {
    fetchCustomers();
  }, [page, search]);

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Header>
        <Content>You are about to {headerText}</Content>
      </Header>
      <Content>
        {!loadingModal && loaded && customerDropdownOptions && (
          <Dropdown
            menuPortalTarget={document.body}
            menuPosition='fixed'
            menuPlacement={'bottom'}
            placeholder='All Customers'
            creatable={false}
            dropdownHeight={300}
            options={customerDropdownOptions}
            value={customerDropdownOptions.find((o: Option) => {
              return o.value == defaultData.customerId;
            })}
            onChange={(option: Option) => {
              setDefaultData({
                ...defaultData,
                customerId: option.value,
              });
              setUserCustomer(option.value);
              setLoadingModal(false);
            }}
            onMenuScrollToBottom={() => {
              if (customerDropdownOptions.length < customersCount)
                setPage(page + 1);
              if (!search) setPageCopy(page + 1);
            }}
            onInputChange={searchInput}
            isLoading={loadingModal}
            onMenuClose={() => setLoadingModal(false)}
            className='option-container'
          />
        )}
        <SubmitButtonWrapper>
          <Button
            text={'Change Customer'}
            onClick={() => {
              handleUpdateUsersCustomer();
            }}
            disabled={!customerId || loadingModal}
          />
        </SubmitButtonWrapper>
      </Content>
    </NewModal>
  );
};
