import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';

import * as theme from 'lib/style/theme';

type RowConfig = {
  key: string | number;
  columns: React.ReactNode[];
  onClick?: () => void;
};

type Props = {
  headers?: React.ReactNode[];
  rows?: RowConfig[];
  columnWidths?: any[];
  hoverable?: boolean;
  isLoading?: boolean;
  loaderHeight?: number;
  error?: boolean | null;
  compact?: boolean;
  relative?: boolean;
  fixColumnIndex?: string;
  cellHeight?: string;
  style?: React.CSSProperties;
  dataCy?: string;
  borderColor?: string;
  thFontSize?: string;
  tdFontSize?: string;
  thFontColor?: string;
  tdFontWeight?: number;
};

const TableWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow: overlay;
`;
type ThProps = { compact: boolean; fix: boolean | string };

type TbodyTrProps = { hoverable: boolean; compact: boolean };

type TdProps = {
  index: number;
  compact: boolean;
  fix?: boolean | string;
  cellHeight: string;
};

export const Table = ({
  headers,
  compact = false,
  columnWidths = [],
  cellHeight = '',
  rows = [],
  hoverable = false,
  isLoading = false,
  error = false,
  relative = false,
  fixColumnIndex = '',
  style,
  dataCy,
  borderColor = '#dee2e8',
  thFontSize = theme.fontSizes.sm,
  tdFontSize = theme.fontSizes.md,
  thFontColor = theme.palette.blackRgb60,
  tdFontWeight = 500,
  loaderHeight = 300,
}: Props) => {
  const TbodyTr = styled.tr<TbodyTrProps>`
    ${props =>
      props.hoverable &&
      css`
        cursor: pointer;
        &:hover {
          background-color: ${theme.palette.white};
          box-shadow: 0 2px 4px 0 rgba(52, 58, 64, 0.1),
            0 4px 24px 0 rgba(52, 58, 64, 0.15);
        }
      `}

    &:last-of-type > td {
      &:first-of-type,
      &:last-of-type {
        border-bottom: ${props =>
          props.compact ? `2px solid ${borderColor}!important` : '0'};
      }
      border-bottom: 2px solid ${borderColor};
    }
  `;

  const Th = styled.th<ThProps>`
    ${theme.fontNormal500};
    font-size: ${thFontSize};
    color: ${thFontColor};
    border-width: 1px 0 2px 0;
    padding: ${props => (props.compact ? '0' : '8px 8px')};
    border-bottom: 2px solid ${borderColor};
    text-align: start;

    &:nth-of-type(2) {
      text-align: start;
    }
    /*
  &:nth-of-type(6) {
    text-align: end;
  }
  */
    &:nth-last-of-type(2) {
      text-align: end;
    }

    &:first-of-type {
      border-bottom: ${props =>
        props.compact ? `2px solid ${borderColor}` : '0'};
      padding: ${props => (props.compact ? '0' : '0px 4px')};
    }

    &:last-of-type {
      border-bottom: ${props =>
        props.compact ? `2px solid ${borderColor}!important` : '0'};
      padding: ${props => (props.compact ? '0' : '0px 4px')};
    }
    ${props =>
      props.fix &&
      css`
        background-color: white;
        /* Stick to the left/right */
        left: ${props.fix == 'left' ? '0' : undefined};
        right: ${props.fix == 'right' ? '0' : undefined};
        position: sticky;
        /* Displayed on top of other rows when scrolling */
        z-index: 4;
      `}
  `;

  const Td = styled.td<TdProps>`
    ${theme.fontNormal500}
    white-space: nowrap;
    font-size: ${tdFontSize};
    color: ${theme.palette.themeDark};
    font-weight: ${tdFontWeight};
    padding: ${props =>
      props.compact
        ? '0px'
        : Number(props.index) === 1
        ? '6px 8px'
        : '20px 8px'};
    height: ${props =>
      !props.cellHeight && props.compact ? '55px' : props.cellHeight || 'auto'};
    ${props =>
      props.index === 1 &&
      css`
        width: 150px;
      `}
    border-bottom: 1px solid ${borderColor};
    text-align: start;

    &:nth-of-type(2) {
      text-align: left;
    }

    &:nth-last-of-type(2) {
      text-align: end;
      position: relative;
    }

    &:first-of-type {
      border-bottom: ${props =>
        props.compact ? `1px solid ${borderColor}` : '0'};
      padding: ${props => (props.compact ? '0' : '0px 4px')};
    }

    &:last-of-type {
      border-bottom: ${props =>
        props.compact ? `1px solid ${borderColor}!important` : '0'};
      padding: ${props => (props.compact ? '0' : '0px 4px')};
    }
    ${props =>
      props.fix &&
      css`
        background-color: white;
        /* Stick to the left/right */
        left: ${props.fix == 'left' ? '0' : undefined};
        right: ${props.fix == 'right' ? '0' : undefined};
        position: sticky !important;
        /* Displayed on top of other rows when scrolling */
        z-index: 4;
      `}
  `;
  return (
    <TableWrapper>
      {!error && (
        <StyledTable data-cy={dataCy} style={style}>
          {headers && (
            <thead>
              <tr style={{ height: compact ? 50 : 'auto' }}>
                {headers.map((x, idx) => (
                  <Th
                    key={idx}
                    compact={compact}
                    fix={
                      (fixColumnIndex == 'left' && idx == 0) ||
                      (fixColumnIndex == 'right' && idx == headers.length - 1)
                        ? fixColumnIndex
                        : false
                    }
                    style={{
                      width:
                        columnWidths.length > idx - 1 ? columnWidths[idx] : '',
                    }}
                  >
                    {x}
                  </Th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {isLoading && (
              <tr>
                <Td
                  index={0}
                  compact={true}
                  colSpan={4}
                  cellHeight={cellHeight}
                >
                  <LoadingIndicator
                    isLoading={true}
                    height={`${loaderHeight}px`}
                  />
                </Td>
              </tr>
            )}
            {!isLoading &&
              rows.map(({ key, columns, onClick }) => (
                <TbodyTr
                  key={key}
                  onClick={onClick}
                  compact={compact}
                  hoverable={hoverable}
                >
                  {columns.map((x, idx) => (
                    <Td
                      key={`${key}${idx}`}
                      index={idx}
                      compact={compact}
                      fix={
                        (fixColumnIndex == 'left' && idx == 0) ||
                        (fixColumnIndex == 'right' && idx == columns.length - 1)
                          ? fixColumnIndex
                          : false
                      }
                      style={{
                        width:
                          columnWidths.length > idx - 1
                            ? columnWidths[idx]
                            : '',
                        position: relative ? 'relative' : undefined,
                      }}
                      cellHeight={cellHeight}
                    >
                      {x}
                    </Td>
                  ))}
                </TbodyTr>
              ))}
          </tbody>
        </StyledTable>
      )}
    </TableWrapper>
  );
};
