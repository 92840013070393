import { useQuery } from 'react-query';
import { videoKeys } from './videosKeys';
import { CDSVideoListFilter, VideoListAutomotiveItem } from 'lib/context';
import { VideoListParams } from '../types';
import { EXPRESS_API } from 'configs/api/expressApi';

export const getVideos = async (props: VideoListParams) => {
  const start = (props.page || 0) * (props.size || 10);

  return (
    await EXPRESS_API.get(`videos`, {
      params: {
        start,
        limit: props.size,
        search: props.search,
        folder: props.folder,
        sort: props.sort,
        userId: props.filter ? props.filter.userId : '',
        advisorId: props.filter ? props.filter.advisorId : '',
        videoType: props.filter ? props.filter.videoType : '',
        status: props.filter ? props.filter.status : '',
        emailStatus: props.filter ? props.filter.emailStatus : '',
      },
    })
  ).data as { videos: VideoListAutomotiveItem[]; count: number };
};

export const useGetVideos = (
  filter: CDSVideoListFilter,
  folder: string,
  enabled: boolean = true
) => {
  return useQuery(
    videoKeys.list(folder, filter.searchQuery),
    () =>
      getVideos({
        folder,
        size: filter.size,
        page: filter.page,
        search: filter.searchQuery,
        filter,
      }),
    {
      enabled,
    }
  );
};
