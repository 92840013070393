import React from 'react';
import styled from 'styled-components/macro';
import {
  VideoItemThumbnail,
  VideoItemStatus,
} from 'app/pages/library/videos/components';
import { useAuth } from 'lib/context';
import { VideoStatus } from 'lib/const';
import { VideoItemType } from 'app/pages/library/videos/components/VideoItem';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';

const Wrapper = styled.div`
  width: 130px;
  flex-shrink: 0;
  margin: 16px 0;
`;
type Props = {
  videoId: string;
  thumbnail: string;
  videoLength: number;
  processing: VideoEncodingStatus;
  previewVideo: (videoId: string) => void;
};
export const TableFieldVideo = ({
  videoId,
  thumbnail,
  previewVideo,
  videoLength,
  processing,
}: Props) => {
  const { userData } = useAuth();
  return (
    <>
      <Wrapper>
        <VideoItemThumbnail
          handleClick={() => previewVideo(videoId)}
          videoId={videoId}
          thumbnail={thumbnail || ''}
          duration={videoLength || 0}
          processing={processing}
        />
        <VideoItemStatus
          videoId={videoId}
          status={VideoStatus.NoStatus}
          videoItemType={VideoItemType.Video}
          automotiveRole={userData.automotiveRole}
        />
      </Wrapper>
    </>
  );
};
