import React from 'react';
import {
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from '../../../../lib/components';
import dayjs from 'dayjs';
import { STANDARD_DATE_FORMAT } from '../../../../lib/const/DateFormat';
import { TableCell } from 'app/pages/admin/ims/components/style';
import { NormalText } from 'app/pages/admin/index.styled';
import { detailsTableFields } from '../custom/main/const';
import { SortButtons } from 'app/pages/admin/components/SortButtons';
import {
  DEFAULT_PAGE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { IoIosArrowForward } from 'react-icons/io';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import { useHistory } from 'react-router';
import { Gap } from 'lib/components/styles/layout';
import { timeRangeConstants } from './DateRangeSelector/DateRangeSelector';
import queryString from 'query-string';

type Props = {
  data: any[];
  fields: string[];
  count: number;
  onPaginationChange: (page: number, size: number) => void;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
};

export const HistoryTable = (props: Props) => {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);

  const {
    data,
    fields,
    count,
    onPaginationChange,
    onSortingUpdate,
    sortKey,
    order,
    setActiveTab,
  } = props;

  const onHistoryPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
    onPaginationChange(page, size);
  };

  const rows = data.map((report: any, index: number) => ({
    key: index,
    columns: [
      ...fields.map(field => {
        if (field === 'dateSent') {
          return (
            <NormalText>
              {dayjs(report[field]).format(STANDARD_DATE_FORMAT)}
            </NormalText>
          );
        }
        if (field === 'email') {
          return <NormalText>{report[field]}</NormalText>;
        }
        if (field === 'action') {
          return (
            <Gap
              flexDirection='row'
              gap='8px'
              alignItems='end'
              width='100%'
              justifyContent='flex-end'
            >
              <Button
                variant='transparent'
                icon={
                  <IoIosArrowForward
                    color={theme.palette.neutral60}
                    size={20}
                  />
                }
                onClick={() => {
                  const params = {
                    start: dayjs(report.from).format(STANDARD_DATE_FORMAT),
                    end: dayjs(report.to).format(STANDARD_DATE_FORMAT),
                    range: timeRangeConstants.CUSTOM,
                  };
                  history.push({
                    pathname: `/reports/custom/${report.reportId}`,
                    search: queryString.stringify(params),
                  });
                  setActiveTab(0);
                }}
              />
            </Gap>
          );
        }
        return null;
      }),
    ],
  }));

  return (
    <div>
      {!!data.length && (
        <TableContextProvider
          total={count}
          onChange={onHistoryPaginationChange}
          initPage={page}
          initSize={size}
        >
          <div style={{ overflowX: 'auto' }}>
            <Table
              compact={true}
              headers={[
                ...detailsTableFields.map((item, index) => {
                  return (
                    <TableCell
                      key={index}
                      width={item.width}
                      onClick={() => {}}
                    >
                      {item.label}
                      {item.sortable && item.sortKey && (
                        <SortButtons
                          isSorted={sortKey === item.sortKey}
                          order={order}
                          onChange={value => {
                            onSortingUpdate(item.sortKey ?? '', value);
                            setPage(DEFAULT_PAGE);
                          }}
                        />
                      )}
                    </TableCell>
                  );
                }),
                '',
              ]}
              hoverable={false}
              rows={rows}
            />
          </div>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew globalName='history_reports_list' />
          </TableFooter>
        </TableContextProvider>
      )}
      {!data.length && (
        <div style={{ textAlign: 'center' }}>No data to show.</div>
      )}
    </div>
  );
};
