import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { multilocationKeys } from './multilocationsKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IAddChildCustomer {
  customerId: number;
  parentCustomerId: number;
}

const addChildCustomer = async (
  params: IAddChildCustomer
): Promise<{ name: string }> => {
  const { parentCustomerId, customerId } = params;

  return (
    await EXPRESS_API.post(
      `superadmin/multilocation/${parentCustomerId}/customer`,
      { customerId }
    )
  ).data;
};

export const useAddChildCustomerMutation = (closeModalHandler: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(addChildCustomer, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries(
        multilocationKeys.single(`${variables.parentCustomerId}`)
      );
      successToast({ title: 'You have successfully added location!' });
      closeModalHandler();
    },
  });
};
