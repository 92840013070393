import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { ButtonIconSwitch, TextInput } from 'lib/components';
import {
  MdCode,
  MdContentCopy,
  MdDeleteForever,
  MdEdit,
  MdOutlineTextFields,
} from 'react-icons/md';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { useAuth } from 'lib/context';
import { EmailIconDefault, VideoListItem } from 'lib/api';
import {
  EmailBuilderElement,
  EmailBuilderElementType,
  EmailContent,
} from '../types';
import { useGetEmailIconsQuery } from 'lib/api/emailIcons/getEmailIcons';
import {
  BUILDER_IDS,
  EMAIL_DESKTOP_WIDTH,
  EMAIL_MOBILE_WIDTH,
  previewOptions,
  PREVIEWS,
} from '../const';
import {
  chooseDefaultVideoThumbnail,
  generateHTMLBoxHTML,
  generateHtmlFromElements,
  generateImageBoxHTML,
  generateSignatureBoxHTML,
  generateTextBoxHTML,
  generateVideoThumbnailHTML,
  isHtmlEmpty,
} from '../utils';
import { v4 as uuidv4 } from 'uuid';
import { EmailBuilderElementPreview } from './EmailBuilderElementPreview';
import { ModalChooseSnippet } from 'app/pages/snippets/components';
import { SnippetType } from 'lib/const';
import { useGetSignature } from 'lib/api/signature/getSignature';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from '../../../../../../../../lib/utils/productFeature';
import { CovideoMagicButton, PROMPT_TYPE } from 'react-covideo-ai-assist';
import { useSendAndShare } from 'lib/context/send-and-share/provider';
import { NotSurePopup } from 'lib/components/magic/popups/NotSurePopup';
import { getCurrentToken } from 'configs/api/token';
import { Button } from 'react-covideo-common';
import { AddVariableButton } from 'lib/components/textEditorAutomotive';
import { getDefaultPromptUseCase } from 'lib/utils/functions';
import { RiImageAddFill } from 'react-icons/ri';
import { CovideoIcon } from 'lib/components/CovideoIcon';
import { FaSignature } from 'react-icons/fa';

type WrapperProps = {
  disabled: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `};
`;

const Input = styled(TextInput)`
  font-family: 'Work Sans';
  &:focus {
    border: 1px solid ${theme.palette.gray40};
    box-shadow: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 8px;
`;

const TogglePreviewWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const EmailContentWrapper = styled.div`
  position: relative;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray40};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 56px 0;
`;

type EmailContentPreviewProps = {
  width: number;
};

const EmailContentPreview = styled.div<EmailContentPreviewProps>`
  margin: 0 auto;
  width: ${({ width }) => `${width}px`};
  min-height: 160px;
  p {
    margin: 0;
    font-size: 16px;
  }
`;

type ElementPreviewButtonProps = {
  disabled?: boolean;
};

const ElementPreviewButton = styled.div<ElementPreviewButtonProps>`
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      pointer-events: none;
    `};
`;

type ElementPreviewSideProps = {
  side: 'left' | 'right';
};

const ElementPreviewSide = styled.div<ElementPreviewSideProps>`
  position: absolute;
  top: 0;
  height: 100%;
  ${({ side }) =>
    side === 'left'
      ? css`
          right: 100%;
        `
      : css`
          left: 100%;
        `};
  display: none;
  width: 48px;
`;

const PreviewBtsnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  gap: 16px;
  border-radius: 5px;
  background: #334975; // bluea80 without opacity
`;

const ElementPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 16px;
  &:hover {
    box-shadow:
      0px 0px 4px rgba(66, 79, 104, 0.08),
      0px 6px 12px rgba(66, 79, 104, 0.06);
    border: 1px solid ${theme.palette.babyBlue100};
    ${ElementPreviewSide} {
      display: block;
    }
  }
`;

const SubjectContainer = styled.div`
  position: relative;
`;

type Props = {
  emailContent: EmailContent;
  setEmailContent: React.Dispatch<React.SetStateAction<EmailContent>>;
  video: VideoListItem;
  changeElementBeingEdited: (value: string) => void;
};
export const EmailBuilder = ({
  emailContent,
  setEmailContent,
  video,
  changeElementBeingEdited,
}: Props) => {
  const { userData } = useAuth();
  const { vin } = useSendAndShare();
  const isTemplatesDisabled = !checkIfFeatureIsEnabled(
    userData,
    productFeature.TEMPLATES
  );
  const from =
    userData.firstName + (userData.lastName ? ' ' + userData.lastName : '');

  let elements = emailContent?.elements || [];
  if (emailContent.signature) {
    elements = [...elements, emailContent.signature];
  }

  const disableSignature = elements.some(
    element => element.type === EmailBuilderElementType.SIGNATURE
  );

  const [preview, setPreview] = useState(PREVIEWS.desktop);
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const { data: signatureData } = useGetSignature({
    onSuccess: signatureData => {
      const profileSignature = signatureData?.signature || '';
      if (isHtmlEmpty(profileSignature)) {
        return;
      }
      addSignatureBox(profileSignature);
    },
  });
  const signature = signatureData?.signature || '';
  const { data } = useGetEmailIconsQuery({
    limit: 100,
  });
  const defaultEmailIcon = data?.default;

  const handleMoveItem = ({
    index,
    newIndex,
  }: {
    index: number;
    newIndex: number;
  }) => {
    if (newIndex < 0) {
      return;
    }
    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])];
      const element = newElements.splice(index, 1)[0];
      newElements.splice(newIndex, 0, element);
      return { ...prev, elements: newElements };
    });
  };

  const handleDuplicate = (index: number) => {
    const newId = uuidv4();
    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])];
      let element = { ...newElements[index], id: newId };
      newElements.splice(index + 1, 0, element);
      return { ...prev, elements: newElements };
    });
  };

  const handleEditItemClick = (id: string) => {
    changeElementBeingEdited(id);
  };

  const handleRemove = (id: string) => {
    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])].filter(
        element => element.id !== id
      );
      let newSignature =
        !prev?.signature || prev.signature.id === id
          ? undefined
          : prev?.signature;
      return { ...prev, elements: newElements, signature: newSignature };
    });
    changeElementBeingEdited(BUILDER_IDS.EMPTY);
  };

  const addTextBox = (html = '') => {
    const id = uuidv4();
    const newTextBox = {
      id,
      type: EmailBuilderElementType.TEXT,
      html: html || generateTextBoxHTML(),
    };
    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])];
      newElements.unshift(newTextBox);
      return {
        ...prev,
        elements: newElements,
      };
    });
    changeElementBeingEdited(id);
  };

  const addHTMLBox = () => {
    const id = uuidv4();
    const newHTMLBox = {
      id,
      type: EmailBuilderElementType.HTML,
      html: generateHTMLBoxHTML(),
    };
    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])];
      newElements.unshift(newHTMLBox);
      return { ...prev, elements: newElements };
    });
    changeElementBeingEdited(id);
  };

  const addSignatureBox = (signature: string) => {
    const html = generateSignatureBoxHTML({
      html: !isHtmlEmpty(signature)
        ? signature
        : '<p>This is a signature box. </p>',
    });
    const signatureBox = {
      id: BUILDER_IDS.SIGNATURE,
      type: EmailBuilderElementType.SIGNATURE,
      html,
    };
    setEmailContent(prev => {
      return { ...prev, signature: signatureBox };
    });
  };

  const updateVideoThumbBox = (defaultEmailIcon?: EmailIconDefault) => {
    if (!defaultEmailIcon) {
      return;
    }
    let { thumbnail, thumbnailOption } = chooseDefaultVideoThumbnail({
      defaultEmailIcon,
      video,
    });

    const videoThumbBoxElement = elements.find(
      element => element.type === EmailBuilderElementType.VIDEO_THUMB
    );

    const html = generateVideoThumbnailHTML({
      from,
      thumbnail,
      maxWidth: !!videoThumbBoxElement
        ? videoThumbBoxElement.maxWidth
        : undefined,
      isPortrait: videoThumbBoxElement?.isPortrait,
      customerId: userData.customerId,
      videoShareLinkText: userData.videoShareLinkText,
    });
    const videoThumbnailBox = {
      id: BUILDER_IDS.VIDEO_THUMB,
      type: EmailBuilderElementType.VIDEO_THUMB,
      html,
      thumbnailOption: thumbnailOption,
      maxWidth: !!videoThumbBoxElement
        ? videoThumbBoxElement.maxWidth
        : undefined,
    };

    if (!!videoThumbBoxElement) {
      setEmailContent(prev => {
        const newElements = [...(prev.elements || [])].map(element => {
          if (element.type === EmailBuilderElementType.VIDEO_THUMB) {
            element.thumbnailOption = videoThumbnailBox.thumbnailOption;
            element.html = videoThumbnailBox.html;
            element.maxWidth = videoThumbnailBox.maxWidth;
          }
          return element;
        });
        return { ...prev, elements: newElements };
      });
      return;
    }
    setEmailContent(prev => {
      const newElements = [videoThumbnailBox, ...(prev.elements || [])];
      return { ...prev, elements: newElements };
    });
  };

  const addImageBox = () => {
    const html = generateImageBoxHTML({ imageUrl: '' });
    const id = uuidv4();
    const imageBox = {
      id: id,
      type: EmailBuilderElementType.IMAGE_EMPTY,
      html,
    };
    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])];
      newElements.unshift(imageBox);
      return { ...prev, elements: newElements };
    });
    changeElementBeingEdited(id);
  };

  const useTranslatedText = userData?.customerId === '57756';

  const englishText = `<p><a class='video-url' href='~ShortURL~' target='_blank'>${userData.videoShareLinkText || `Click the image or view your video from ${from}`}</a>. </p>`;
  const frenchText = `Cliquez sur l'image ou regardez votre vidéo de <a class='video-url' href='~ShortURL~' target='_blank'>ici</a>. </p>`;
  const htmlText = useTranslatedText ? frenchText : englishText;
  const defaultPromptUseCase = getDefaultPromptUseCase(userData.isAutomotive);
  useEffect(() => {
    if (emailContent.elements && !!emailContent.elements.length) {
      return;
    }
    const DEFAULT_ELEMENTS: EmailBuilderElement[] = [
      {
        id: BUILDER_IDS.VIDEO_URL,
        type: EmailBuilderElementType.VIDEO_URL,
        html: htmlText,
      },
    ];
    const body = generateHtmlFromElements(DEFAULT_ELEMENTS);
    setEmailContent(prev => ({ ...prev, body, elements: DEFAULT_ELEMENTS }));
  }, []);

  useEffect(() => {
    if (!emailContent.elements) {
      return;
    }
    let elements = emailContent.elements || [];
    const signature = emailContent?.signature;
    if (signature) {
      elements = [...elements, signature];
    }
    const body = generateHtmlFromElements(elements);
    setEmailContent(prev => ({ ...prev, body }));
  }, [emailContent.elements, emailContent.signature]);

  useEffect(() => {
    updateVideoThumbBox(defaultEmailIcon);
  }, [defaultEmailIcon]);

  return (
    <Wrapper disabled={false}>
      <SubjectContainer>
        <Input
          onChange={e =>
            setEmailContent(prev => ({ ...prev, subject: e.target.value }))
          }
          value={emailContent.subject}
          margin={'0 0 32px 0'}
          placeholder='Subject'
        />
        <AddVariableButton
          buttonPadding='8px 16px'
          buttonBorderRadius='0 0.25rem 0.25rem 0'
          onTextChange={text =>
            setEmailContent(prev => ({
              ...prev,
              subject: `${prev.subject} ${text}`,
            }))
          }
          extendsStyles={{
            wrapper: {
              position: 'absolute',
              right: '1px',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
            },
          }}
        />
      </SubjectContainer>
      <ButtonsWrapper>
        <Button
          variant='secondary'
          text='Text Box'
          icon={<MdOutlineTextFields />}
          onClick={() => addTextBox()}
        />
        {!isTemplatesDisabled && (
          <Button
            variant='secondary'
            text='Templates'
            icon={<CovideoIcon name='snippets' />}
            onClick={() => setShowTemplatesModal(true)}
          />
        )}
        <Button
          variant='secondary'
          text='Image'
          icon={<RiImageAddFill size={18} />}
          onClick={() => addImageBox()}
        />
        <Button
          variant='secondary'
          text='HTML'
          icon={<MdCode />}
          onClick={() => addHTMLBox()}
        />
        <Button
          variant='secondary'
          text='Signature'
          icon={<FaSignature />}
          onClick={() => addSignatureBox(signature)}
          disabled={disableSignature}
        />
        <CovideoMagicButton
          defaultPromptType={PROMPT_TYPE.EMAIL}
          defaultPromptUseCase={defaultPromptUseCase}
          defaultVin={vin}
          userInput={''}
          margin='0 0 0 auto'
          handleSubmitGeneratedContent={(content: string) =>
            addTextBox(content)
          }
          variant='gradient'
          text='AI Assist'
          hoverPopup={<NotSurePopup />}
          token={getCurrentToken().token as string}
          userData={{
            customer: {
              business: userData.customer.business,
              hasCovideoMagic:
                userData?.customer?.hasCovideoMagic?.toString() === '1',
              markVideosAsSent: userData.customer.markVideosAsSent,
            },
            firstName: userData.firstName,
            isAutomotiveSalesRole: userData.isAutomotiveSalesRole,
            isAutomotiveServiceRole: userData.isAutomotiveServiceRole,
            isIMSEnabled: userData.isIMSEnabled,
            isAutomotive: userData.isAutomotive,
            isCompanyAdmin: userData.isCompanyAdmin,
          }}
          aiAssistTheme={'branding'}
          apiKey={process.env.REACT_APP_APIKEY}
        />
      </ButtonsWrapper>
      <EmailContentWrapper>
        <TogglePreviewWrapper>
          <ButtonIconSwitch
            defaultValue={PREVIEWS.desktop}
            onChange={setPreview}
            values={previewOptions}
          />
        </TogglePreviewWrapper>
        <EmailContentPreview
          width={
            preview === PREVIEWS.desktop
              ? EMAIL_DESKTOP_WIDTH
              : EMAIL_MOBILE_WIDTH
          }
        >
          {elements.map((element, index) => {
            const showEditButton = [
              EmailBuilderElementType.IMAGE,
              EmailBuilderElementType.IMAGE_EMPTY,
              EmailBuilderElementType.VIDEO_THUMB,
              EmailBuilderElementType.SIGNATURE,
              EmailBuilderElementType.TEXT,
              EmailBuilderElementType.HTML,
            ].includes(element.type);
            const showDuplicateButton = [
              EmailBuilderElementType.TEXT,
              EmailBuilderElementType.HTML,
            ].includes(element.type);
            const showMoveButtons =
              element.type !== EmailBuilderElementType.SIGNATURE;
            const showRemoveButton = [
              EmailBuilderElementType.SIGNATURE,
              EmailBuilderElementType.TEXT,
              EmailBuilderElementType.IMAGE,
              EmailBuilderElementType.IMAGE_EMPTY,
              EmailBuilderElementType.HTML,
            ].includes(element.type);
            return (
              <ElementPreviewWrapper key={index}>
                {showMoveButtons && (
                  <ElementPreviewSide side='left'>
                    <PreviewBtsnWrapper>
                      <ElementPreviewButton
                        disabled={!index}
                        onClick={() =>
                          handleMoveItem({ index, newIndex: index - 1 })
                        }
                      >
                        <IoChevronUp color={theme.palette.white} />
                      </ElementPreviewButton>
                      <ElementPreviewButton
                        disabled={
                          index >= (emailContent?.elements?.length || 0) - 1
                        }
                        onClick={() =>
                          handleMoveItem({ index, newIndex: index + 1 })
                        }
                      >
                        <IoChevronDown color={theme.palette.white} />
                      </ElementPreviewButton>
                    </PreviewBtsnWrapper>
                  </ElementPreviewSide>
                )}
                <EmailBuilderElementPreview
                  onClick={() =>
                    showEditButton && handleEditItemClick(element.id)
                  }
                  element={element}
                  video={video}
                  setEmailContent={setEmailContent}
                  from={from}
                />
                {(showEditButton ||
                  showDuplicateButton ||
                  showRemoveButton) && (
                  <ElementPreviewSide side='right'>
                    <PreviewBtsnWrapper>
                      {showEditButton && (
                        <ElementPreviewButton
                          onClick={() => handleEditItemClick(element.id)}
                        >
                          <MdEdit color={theme.palette.white} />
                        </ElementPreviewButton>
                      )}
                      {showDuplicateButton && (
                        <ElementPreviewButton
                          onClick={() => handleDuplicate(index)}
                        >
                          <MdContentCopy color={theme.palette.white} />
                        </ElementPreviewButton>
                      )}
                      {showRemoveButton && (
                        <ElementPreviewButton
                          onClick={() => handleRemove(element.id)}
                        >
                          <MdDeleteForever color={theme.palette.white} />
                        </ElementPreviewButton>
                      )}
                    </PreviewBtsnWrapper>
                  </ElementPreviewSide>
                )}
              </ElementPreviewWrapper>
            );
          })}
        </EmailContentPreview>
      </EmailContentWrapper>
      {showTemplatesModal && (
        <ModalChooseSnippet
          handleModalClose={() => setShowTemplatesModal(false)}
          snippetType={SnippetType.Email}
          handleSubmit={(content: string) => addTextBox(content)}
        />
      )}
    </Wrapper>
  );
};
