import { useFormikContext } from 'formik';
import { ParentCustomer } from 'lib/api/multiLocations/multiLocationQuery';
import { Gap } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import { UsersHeader } from '../UsersHeader';
import { AddUsersColumns } from './AddUsersColumn';
import { AddUsersView } from './AddUsersView';
import { EditUsersView } from './EditUsersView';

const ColumnsWrapper = styled.div`
  min-width: 350px;
  max-width: 350px;
  background: ${theme.palette.white};
  padding-bottom: 21px;
  margin-left: 1px;
`;

const ScrollWrapper = styled.div`
  overflow-x: auto;
  display: inline-flex;
  padding-bottom: 55px;
  background: white;
`;

export const UsersColumns = ({ isEditMode }: { isEditMode: boolean }) => {
  const { values } = useFormikContext<ParentCustomer>();
  return (
    <>
      <ScrollWrapper>
        {values.users.map((user, userIdx) => (
          <ColumnsWrapper key={userIdx}>
            <UsersHeader
              headerName={`User #${userIdx + 1}`}
              userIdx={userIdx}
              isEditMode={isEditMode}
              user={user}
            />
            <Gap flexDirection='column' alignItems='flex-start' gap='0'>
              {isEditMode ? (
                <EditUsersView userIdx={userIdx} user={user} />
              ) : (
                <AddUsersView user={user} userIdx={userIdx} />
              )}
            </Gap>
          </ColumnsWrapper>
        ))}
      </ScrollWrapper>
      <AddUsersColumns isEditMode={isEditMode} />
    </>
  );
};
