import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { SectionTabContent } from './SectionTabContent';
import { PageTabContent } from './PageTabContent';
import { useLandingPageBuilderContext } from '../context';
import { EditSectionView } from '.';
import { Button } from 'react-covideo-common';
import { MdAdd, MdArrowBackIosNew } from 'react-icons/md';

interface ContainerProps {
  flexDirection?: string;
  flex?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 0 0 100%;
  .alignRight {
    float: right;
    cursor: pointer;
  }
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  height: max-content;
  flex-wrap: wrap;
`;
const TabContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
`;

const RealTabContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 16px);
  border-radius: 24px;
  background-color: rgba(242, 244, 246, 1);
  padding: 4px;
  box-sizing: border-box;
  margin: 16px auto 0 auto;
`;

const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  text-align: center;
  align-items: center;
  flex: 0 0 50%;
  justify-content: center;
  cursor: pointer;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 27, 83, 0.4);
  height: 32px;
  ${({ isActive }) =>
    isActive &&
    css`
      color: rgba(0, 27, 83, 1);
      background: white;
      border-radius: 24px;
      box-shadow: rgba(255, 255, 255, 1);
    `}
`;

const BackContainer = styled.label`
  width: 100%;
  padding: 12.5px;
  border-top: 1px solid #eeeff2;
  border-bottom: 1px solid #eeeff2;
  display: flex;
  align-items: center;
  gap: 8px;
  &:hover {
    polygon {
      opacity: 1;
    }
    cursor: pointer;
  }
`;

const Text = styled.span`
  vertical-align: middle;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 500;
  color: rgba(0, 27, 83, 0.8);
`;

const InsertButtonContainer = styled.div`
  padding-left: 15px;
`;

export const PageEditorSection = () => {
  const [currentTab, setCurrentTab] = React.useState('sections');
  const [showBackButton, setShowBackButton] = React.useState(true);
  const {
    setShowAddSectionModal,
    elementToEdit,
    setElementToEdit,
    setElementIndex,
    setElementSectionKey,
  } = useLandingPageBuilderContext();

  function addSectionButtonClickHandler() {
    setShowAddSectionModal(true);
  }

  function onClickHandler(e: any) {
    setCurrentTab(e.target.title);
  }

  function getTabContent() {
    if (currentTab == 'sections') {
      return (
        <>
          {' '}
          <SectionTabContent />
          <InsertButtonContainer>
            <Button
              variant='secondary'
              icon={<MdAdd size={18} />}
              text={'Insert section'}
              onClick={addSectionButtonClickHandler}
            />
          </InsertButtonContainer>
        </>
      );
    } else {
      return <PageTabContent setShowBackButton={setShowBackButton} />;
    }
  }
  React.useEffect(() => {}, []);
  return (
    <Container flexDirection={'row'}>
      <>
        {elementToEdit != null ? (
          <TabContainer>
            <BackContainer
              {...(showBackButton && { disabled: true })}
              onClick={() => {
                if (!showBackButton) {
                  return;
                }
                setElementToEdit(null);
                setElementIndex('');
                setElementSectionKey('');
              }}
            >
              <MdArrowBackIosNew color='#272a32' />
              <Text>Back</Text>
            </BackContainer>
          </TabContainer>
        ) : (
          <RealTabContainer>
            <Tab
              onClick={onClickHandler}
              title={'sections'}
              isActive={currentTab == 'sections'}
            >
              Sections
            </Tab>
            <Tab
              onClick={onClickHandler}
              title={'page'}
              isActive={currentTab == 'page'}
            >
              Page Settings
            </Tab>
          </RealTabContainer>
        )}
      </>
      <TabContainer>
        {elementToEdit != null ? (
          <>
            <EditSectionView
              setShowBackButton={setShowBackButton}
            ></EditSectionView>
          </>
        ) : (
          getTabContent()
        )}
      </TabContainer>
    </Container>
  );
};
