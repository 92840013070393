import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';

import { RadioInput, TextInput } from 'lib/components';
import { useLandingPageBuilderContext } from '../../context';
import {
  defaultFontFamilyOptions,
  fontSizeOptionsForLinks,
  ResetIcon,
  FontFamilyOption,
  FontStyleOption,
  FontFamilyVariants,
} from '..';
import { EnhancedColorPicker } from '../../../../video/videoDetails/components';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { ColorResult } from 'react-color';
import Switch from '../Switch';
import Select from 'react-select';
import { IoIosAdd } from 'react-icons/io';
import { useGetGoogleFontsQuery } from 'lib/api/googleFonts/getGoogleFonts';
import { convertToHttps } from 'lib/utils/functions';
import {
  CustomLandingPageFontOption,
  GlobalFontStyles,
} from './EditTextSection';
import { Button } from 'react-covideo-common';
import { MdDeleteForever } from 'react-icons/md';

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}
interface RadioButtonsWrapperProps {
  flexDirection?: string;
  alignItems?: string;
}
const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;
`;

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  flex-wrap: wrap;
  .adjustWidth {
    width: 90%;
  }
  .alignCenter {
    margin: auto;
    cursor: pointer;
  }
  .pointer {
    cursor: pointer;
  }
  .widthFitContent {
    width: fit-content;
    margin: 0;
    height: 24px;
    line-height: 1.5;
  }
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  // margin: 25px auto 0 auto;
`;

const TextSpan = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
`;

const RadioButtonsWrapper = styled.div<RadioButtonsWrapperProps>`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'center'};
  width: 100%;
  height: auto;
  box-sizing: border-box;
`;

export const EditCTAsSection = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    setElementToEdit,
    elementSectionKey,
    elementToEdit,
    elementIndex,
    selectedStyleProperties,
  } = useLandingPageBuilderContext();
  const themes = useTheme();

  const [allFontFamilyOptions, setAllFontFamilyOptions] = React.useState<
    FontFamilyOption[]
  >([...defaultFontFamilyOptions]);
  const [allFontStyleOptions, setAllFontStyleOptions] = React.useState<
    FontStyleOption[]
  >([]);

  const { data: googleFonts } = useGetGoogleFontsQuery();

  React.useEffect(() => {
    let googleFontsMapped: FontFamilyOption[] = [];
    let newFontFamilyOptions: FontFamilyOption[] = [
      ...defaultFontFamilyOptions,
    ];

    if (googleFonts && googleFonts.items) {
      // Map the google fonts list response
      googleFontsMapped = googleFonts.items.map((font: any) => {
        return {
          value: font.family,
          label: font.family,
          variants: font.variants,
          files: font.files,
        };
      });

      // Remove fonts that match our default ones
      const googleFontsFiltered = googleFontsMapped.filter(elem => {
        return newFontFamilyOptions.every(ele => {
          return ele.value !== elem.value;
        });
      });

      // Sort all fonts in ascending order
      newFontFamilyOptions = [
        ...newFontFamilyOptions,
        ...googleFontsFiltered,
      ].sort((a, b) => (a.value > b.value ? 1 : -1));

      setAllFontFamilyOptions(newFontFamilyOptions);
    }

    const elementFontFamily = elementToEdit?.elementProperties?.fontFamily;
    const fontFamily = !!elementFontFamily
      ? elementFontFamily
      : !!selectedStyleProperties && selectedStyleProperties.fontFamily
        ? selectedStyleProperties.fontFamily
        : 'Work Sans';
    if (fontFamily) {
      const selectedFontFamily = newFontFamilyOptions.find(
        (o: FontFamilyOption) => {
          const fontFamilyList = fontFamily.split('-');
          const fontFamilyValue = fontFamilyList[0];
          return o.value == fontFamilyValue;
        }
      );

      if (selectedFontFamily) {
        const initialFontStyleOptions: FontStyleOption[] =
          selectedFontFamily.variants.map((variant: FontFamilyVariants) => {
            return {
              value: variant,
              label: variant,
              url: selectedFontFamily.files[variant] || '',
            };
          });
        setAllFontStyleOptions(initialFontStyleOptions || []);
        return;
      }
    }
  }, [googleFonts]);

  const data = { ...selectedLayoutData };
  if (!elementSectionKey || !data) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  if (!sectionDetails) {
    return <></>;
  }

  if (!sectionDetails.elementProperties) {
    sectionDetails.elementProperties = {};
  }

  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }
  const isVisible = isElementCase
    ? data[elementSectionKey].childSections[elementIndex]?.isVisible
    : data[elementSectionKey]?.isVisible;
  if (!isVisible) {
    return <></>;
  }

  let overrideCTA = sectionDetails.isOverrideCTA || false;
  let addMouseOverStyle = sectionDetails?.addMouseOverStyle || false;
  let elementProperties = sectionDetails?.elementProperties || {};
  let buttonBackgroundColor =
    elementProperties.elementBackgroundColor ||
    selectedStyleProperties.textColor ||
    '#F8444F';
  let buttonTextColor =
    elementProperties.elementTextColor ||
    selectedStyleProperties.backgroundColor ||
    '#fff';
  let buttonHoverBackgroundColor =
    elementProperties.elementHoverBackgroundColor;
  let buttonHoverTextColor = elementProperties.elementHoverTextColor;
  let buttonShape = elementProperties.elementShape || 'rounded';
  let fontFamily =
    elementProperties.fontFamily ||
    selectedStyleProperties.fontFamily ||
    'Work Sans';
  let fontSize = elementProperties.fontSize || '16px';
  let fontStyle =
    elementProperties.fontStyle ||
    selectedStyleProperties.fontWeight ||
    'normal';

  const linksArray = sectionDetails.links || [];

  const handleChangeButtonColor = (color: ColorResult) => {
    const hexAlpha: string = calculateHexAlpha(color);
    buttonBackgroundColor = hexAlpha;
    sectionDetails.elementProperties.elementBackgroundColor = hexAlpha;

    updateContext();
  };

  const handleChangeButtonHoverColor = (color: any) => {
    if (color) {
      const hexAlpha: string = calculateHexAlpha(color as ColorResult);
      color = hexAlpha;
    } else {
      color = '';
    }
    buttonHoverBackgroundColor = color;
    sectionDetails.elementProperties.elementHoverBackgroundColor = color;
    updateContext();
  };

  const handleChangeButtonTextColor = (color: ColorResult) => {
    const hexAlpha: string = calculateHexAlpha(color);
    buttonTextColor = hexAlpha;
    sectionDetails.elementProperties.elementTextColor = hexAlpha;
    updateContext();
  };

  const handleChangeButtonHoverTextColor = (color?: any) => {
    if (color) {
      const hexAlpha: string = calculateHexAlpha(color as ColorResult);
      color = hexAlpha;
    } else {
      color = '';
    }

    buttonHoverTextColor = color;
    sectionDetails.elementProperties.elementHoverTextColor = color;
    updateContext();
  };

  const handleAddLink = () => {
    linksArray.push({ linkText: '', linkValue: '' });
    sectionDetails.links = linksArray;
    updateContext();
  };

  const handleInputChange = (e: any) => {
    if (!e || !e.target || !e.target.id) {
      return;
    }

    let id = e.target.id;
    let array = id.split('-');
    if (!array || !array.length || !array[0] || !array[1]) {
      return;
    }

    let index = array[0];
    let type = array[1];
    linksArray[index][type] = e.target.value;
    sectionDetails.links = linksArray;
    updateContext();
  };

  function handleDelete(index: number) {
    linksArray.splice(index, 1);
    sectionDetails.links = linksArray;
    updateContext();
  }

  const handleAddMouseOverStyleToggle = () => {
    addMouseOverStyle = !addMouseOverStyle;
    sectionDetails.addMouseOverStyle = addMouseOverStyle;
    if (addMouseOverStyle == false) {
      handleChangeButtonHoverColor('');
      handleChangeButtonHoverTextColor('');
    }

    updateContext();
  };

  const handleChangeFontStyle = (style: string, url: string) => {
    fontStyle = style;
    sectionDetails.elementProperties.fontStyle = style;
    sectionDetails.elementProperties.fontUrl = convertToHttps(url);

    let newFontFamily = fontFamily.split('-');
    newFontFamily = newFontFamily[0];
    newFontFamily += '-' + style;
    updateContext();
    handleChangeFontFamily(newFontFamily);
  };

  const handleChangeFontFamily = (fontFamilyName: string) => {
    if (!fontFamilyName) {
      return;
    }

    if (fontFamilyName.indexOf('-') == -1) {
      fontFamilyName += '-' + fontStyle;
    }
    fontFamily = fontFamilyName;
    // sectionDetails.textFont = fontFamilyName;
    sectionDetails.elementProperties.fontFamily = fontFamily;
    updateContext();
  };

  const handleChangeFontSize = (size: string) => {
    fontSize = size;
    sectionDetails.elementProperties.fontSize = size;
    updateContext();
  };

  const updateContext = () => {
    if (isElementCase) {
      data[elementSectionKey].childSections[elementIndex] = sectionDetails;
    } else {
      data[elementSectionKey] = sectionDetails;
    }
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(data);
  };

  const handleUpdateShape = (shape: string) => {
    buttonShape = shape;
    sectionDetails.elementProperties.elementShape = shape;
    updateContext();
  };

  const reset = (key: string) => {
    switch (key) {
      case 'buttonBackgroundColor': {
        buttonBackgroundColor = selectedStyleProperties.textColor;
        sectionDetails.elementProperties.elementBackgroundColor =
          buttonBackgroundColor;
        updateContext();
        break;
      }

      case 'textStyle': {
        fontFamily = selectedStyleProperties.fontFamily;
        fontStyle = selectedStyleProperties.fontWeight;
        sectionDetails.elementProperties.fontFamily = fontFamily;
        sectionDetails.elementProperties.fontStyle = fontStyle;
        updateContext();
        break;
      }

      case 'buttonTextColor': {
        buttonTextColor = selectedStyleProperties.backgroundColor;
        sectionDetails.elementProperties.elementTextColor = buttonTextColor;
        updateContext();
        break;
      }

      case 'buttonHoverBackgroundColor': {
        buttonHoverBackgroundColor = '';
        sectionDetails.elementProperties.elementHoverBackgroundColor = '';
        updateContext();
        break;
      }

      case 'buttonHoverTextColor': {
        buttonHoverTextColor = '';
        sectionDetails.elementProperties.elementHoverTextColor = '';
        updateContext();
        break;
      }
    }
  };
  return (
    <>
      {/* <Row>
        <InfoWrapper>
          <InfoQuestionMark
            icon={'i'}
            infoText={'Edit your CTA sets here.'}
            iconHeight={'17px'}
            iconWidth={'17px'}
            iconBackgroundColor={'rgba(0, 27, 83, 0.8);'}
          />
          &nbsp;&nbsp;Edit your&nbsp;
          <a href="/design/calls-to-action" target="_blank">
            {' '}
            CTA sets here.
          </a>
        </InfoWrapper>
      </Row>
      <Row justifyContent={'space-between'}>
        <TextSpan> Overwrite CTA links </TextSpan>
        <Switch
          id={'switch-1'}
          isOn={overrideCTA}
          onColor={'#FF8B22'}
          handleToggle={handleOverrideCTAToggle}
        />
      </Row> */}

      <GlobalFontStyles fonts={allFontFamilyOptions} />

      {overrideCTA && (
        <Row padding={'0px'} alignItems={'center'}>
          {linksArray.map((item: any, index: number) => {
            return (
              <>
                <Row display={'inline-flex'} width={'85%'} key={index}>
                  <TextInput
                    id={index + '-linkText'}
                    margin={'5px 0px 0px 0px'}
                    type={'text'}
                    placeholder={'Text to display'}
                    value={item.linkText}
                    onChange={handleInputChange}
                    maxLength={30}
                  />
                  <TextInput
                    id={index + '-linkValue'}
                    margin={'5px 0px 0px 0px'}
                    type={'text'}
                    placeholder={'URL'}
                    value={item.linkValue}
                    onChange={handleInputChange}
                  />
                </Row>
                <Row display={'inline-flex'} width={'15%'} key={index + '-1'}>
                  <MdDeleteForever
                    onClick={() => {
                      handleDelete(index);
                    }}
                    className={'alignCenter'}
                  />
                </Row>
              </>
            );
          })}
          <Row>
            <Button
              variant='secondary'
              icon={<IoIosAdd />}
              text={'Add link'}
              onClick={handleAddLink}
            />
          </Row>
        </Row>
      )}
      <Row>
        <TopLabel>Button color</TopLabel>
        <EnhancedColorPicker
          disableZIndex={true}
          handleColorChange={handleChangeButtonColor}
          playerColor={buttonBackgroundColor || 'orange'}
          wrapperWidth={'100%'}
          wrapperMargin={'5px auto 0 auto'}
          className={'adjustWidth'}
        />

        <ResetIcon
          onClick={() => {
            reset('buttonBackgroundColor');
          }}
          className={'alignCenter'}
        />
      </Row>
      <Row>
        <TopLabel className={'widthFitContent'}>Text style</TopLabel>
        <ResetIcon
          className={'pointer'}
          onClick={() => {
            reset('textStyle');
          }}
        />
        <SelectInput
          styles={{
            control: (base: any) => ({ ...base, height: '40px' }),
            indicatorSeparator: () => ({ display: 'none' }),
          }}
          options={allFontFamilyOptions}
          menuPlacement={'bottom'}
          maxMenuHeight={200}
          value={allFontFamilyOptions.find(o => {
            fontFamily = fontFamily.split('-');
            fontFamily = fontFamily[0];
            return o.value == fontFamily;
          })}
          components={{
            Option: CustomLandingPageFontOption,
          }}
          onChange={(option: any) => {
            handleChangeFontFamily(option.value);
            if (option.variants) {
              const newStyleOptions: FontStyleOption[] = option.variants.map(
                (variant: FontFamilyVariants) => {
                  return {
                    label: variant,
                    value: variant,
                    url: option.files[variant],
                  };
                }
              );
              setAllFontStyleOptions(newStyleOptions);
              // check if the already selected value is present in options
              // if not, select the first one
              const selectedStyleOption = newStyleOptions.find(
                o => o.value == fontStyle
              );
              if (!selectedStyleOption) {
                handleChangeFontStyle(
                  newStyleOptions[0].value,
                  newStyleOptions[0].url
                );
                return;
              }
              handleChangeFontStyle(
                selectedStyleOption.value,
                selectedStyleOption.url
              );
            }
          }}
        />
        <SelectInput
          styles={{
            control: (base: any) => ({ ...base, height: '40px' }),
            indicatorSeparator: () => ({ display: 'none' }),
          }}
          menuPlacement={'top'}
          width={'65%'}
          maxMenuHeight={300}
          options={allFontStyleOptions}
          value={allFontStyleOptions.find(o => o.value == fontStyle)}
          onChange={(option: any) => {
            handleChangeFontStyle(option.value, option.url);
          }}
        />
        <SelectInput
          styles={{
            control: (base: any) => ({ ...base, height: '40px' }),
            indicatorSeparator: () => ({ display: 'none' }),
          }}
          menuPlacement={'top'}
          width={'35%'}
          options={fontSizeOptionsForLinks}
          value={fontSizeOptionsForLinks.find(o => o.value == fontSize)}
          onChange={(option: any) => {
            handleChangeFontSize(option.value);
          }}
        />
        <EnhancedColorPicker
          disableZIndex={true}
          handleColorChange={handleChangeButtonTextColor}
          playerColor={buttonTextColor || '#ffffff'}
          wrapperWidth={'100%'}
          wrapperMargin={'5px auto 0 auto'}
          className={'adjustWidth'}
        />
        <ResetIcon
          onClick={() => {
            reset('buttonTextColor');
          }}
          className={'alignCenter'}
        />
      </Row>
      <Row justifyContent={'space-between'}>
        <TextSpan> Add style on mouse over </TextSpan>
        <Switch
          id={'switch-2'}
          isOn={addMouseOverStyle}
          onColor={themes.colors.primary[100]}
          handleToggle={handleAddMouseOverStyleToggle}
        />
        {addMouseOverStyle == true && (
          <>
            <TopLabel>Button color</TopLabel>
            <EnhancedColorPicker
              disableZIndex={true}
              handleColorChange={handleChangeButtonHoverColor}
              playerColor={buttonHoverBackgroundColor}
              wrapperWidth={'100%'}
              wrapperMargin={'5px auto 0 auto'}
              className={'adjustWidth'}
            />
            <ResetIcon
              onClick={() => {
                reset('buttonHoverBackgroundColor');
              }}
              className={'alignCenter'}
            />
            <TopLabel>Text color</TopLabel>
            <EnhancedColorPicker
              disableZIndex={true}
              handleColorChange={handleChangeButtonHoverTextColor}
              playerColor={buttonHoverTextColor || 'white'}
              wrapperWidth={'100%'}
              wrapperMargin={'5px auto 0 auto'}
              className={'adjustWidth'}
            />
            <ResetIcon
              onClick={() => {
                reset('buttonHoverTextColor');
              }}
              className={'alignCenter'}
            />
          </>
        )}
      </Row>
      <Row>
        <TopLabel>Shape style</TopLabel>
        <RadioButtonsWrapper flexDirection={'column'} alignItems='flex-start'>
          <RadioInput
            name={'textAndIconColor'}
            value={'sharp'}
            text={'Sharp'}
            checkedColor={themes.colors.primary[100]}
            checked={buttonShape == 'sharp'}
            onChange={() => {
              handleUpdateShape('sharp');
            }}
            onClick={() => {
              handleUpdateShape('sharp');
            }}
          />
          <RadioInput
            name={'textAndIconColor'}
            value={'rounded'}
            text={'Rounded'}
            checkedColor={themes.colors.primary[100]}
            checked={buttonShape == 'rounded'}
            onChange={() => {
              handleUpdateShape('rounded');
            }}
            onClick={() => {
              handleUpdateShape('rounded');
            }}
          />
          <RadioInput
            name={'textAndIconColor'}
            value={'circular'}
            text={'Circular'}
            checkedColor={themes.colors.primary[100]}
            checked={buttonShape == 'circular'}
            onChange={() => {
              handleUpdateShape('circular');
            }}
            onClick={() => {
              handleUpdateShape('circular');
            }}
          />
        </RadioButtonsWrapper>
      </Row>
    </>
  );
};
