import * as React from 'react';
import { useAuth } from 'lib/context';
import styled from 'styled-components/macro';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { Button } from 'react-covideo-common';
import { useHistory } from 'react-router-dom';
import { AccessRole } from 'lib/const';

const ItemContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 32px;
`;

export const CaptionsNotEnabled = () => {
  const { userData } = useAuth();
  const history = useHistory();

  const buyCaptions = () => {
    history.push('/profile/add-ons/captions/manage');
  };

  return (
    <>
      <ItemContainer>
        {userData.access == AccessRole.ADMIN ? (
          <>
            <ParagraphNormal>Captions are not enabled</ParagraphNormal>
            <Button text={'Buy Captions now'} onClick={buyCaptions} />
          </>
        ) : (
          <ParagraphNormal>
            Captions are not enabled for your account. Contact your account
            Admin.
          </ParagraphNormal>
        )}
      </ItemContainer>
    </>
  );
};
