import { MutationFunction, useMutation, useQueryClient } from 'react-query';
import { videoTagsKeys } from './videoTagsKeys';
import { VideoTag, UpdateVideoTagsParams } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

const updateVideoTags: MutationFunction<
  VideoTag[],
  UpdateVideoTagsParams
> = async ({ videoId, videoTags }: UpdateVideoTagsParams) => {
  return (await EXPRESS_API.put(`videos/${videoId}/video-tags`, videoTags))
    .data;
};

export const useUpdateVideoTags = (videoId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (videoTags: VideoTag[]) => updateVideoTags({ videoId, videoTags }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(videoTagsKeys.video(videoId));
      },
    }
  );
};
