import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { ModalImportCsv, ModalServiceDetails, ServiceListing } from '.';
import { MdAdd } from 'react-icons/md';
import { CarServiceItem } from 'lib/api/carService/types';
import { ServiceActive } from 'lib/const';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { Heading } from 'lib/components/styles/typography';
import { HeaderWrapper } from 'lib/components/styles/layout';
import { useCreateCarServiceMutation } from 'lib/api/carService/useCreateCarServiceMutation';
import { useUpdateCarServiceMutation } from 'lib/api/carService/useUpdateCarServiceMutation';
import { useCreateCarServiceBulkMutation } from 'lib/api/carService/useCreateCarServiceBulkMutation';
import { useUpdateCarServicesMutation } from 'lib/api/carService/useUpdateCarServicesMutation';
import { useToastError } from 'lib/hooks';

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ListManager = () => {
  const { showError } = useToastError();
  const { mutateAsync: createCarService } = useCreateCarServiceMutation();
  const { mutateAsync: createBulkCarService } =
    useCreateCarServiceBulkMutation();
  const { mutateAsync: updateCarServices } = useUpdateCarServicesMutation();
  const { mutateAsync: updateCarService } = useUpdateCarServiceMutation();
  const [showServiceDetailsModal, setShowServiceDetailsModal] = useState(false);
  const [showImportCsvModal, setShowImportCsvModal] = useState(false);
  const [filterByActive, setFilterByActive] = useState(ServiceActive.Active);
  const [serviceData, setServiceData] = useState({
    serviceId: '',
    title: '',
    price: '0',
    active: 1,
  });

  const handleCreateServiceClick = () => {
    setServiceData({
      serviceId: '',
      title: '',
      price: '0',
      active: 1,
    });
    setShowServiceDetailsModal(true);
  };

  const handleImportClick = () => {
    setFilterByActive(ServiceActive.Active);
    setShowImportCsvModal(true);
  };

  const handleUpdateServiceClick = (service: CarServiceItem) => {
    setServiceData(service);
    setShowServiceDetailsModal(true);
  };

  const validateService = (service: CarServiceItem) => {
    return service.title !== '';
  };

  const onSubmitService = async (service: CarServiceItem) => {
    if (!validateService(service)) {
      errorToast({
        title: `Please fill out required fields.`,
      });
      return false;
    }
    if (service.serviceId) {
      await updateCarService({
        serviceId: service.serviceId,
        data: service,
      });
    } else {
      await createCarService({
        data: service,
      });
      setFilterByActive(ServiceActive.Active);
    }
    setShowServiceDetailsModal(false);
  };

  const handleImportService = async (
    newServices: CarServiceItem[],
    updatedServices: CarServiceItem[]
  ) => {
    try {
      //import new
      const newData = newServices.map((service: CarServiceItem) => {
        return {
          serviceId: service.serviceId,
          title: service.title,
          price: `${service.price}`,
          active: 1,
        };
      });
      const countNew = newData.length;
      await createBulkCarService({ data: newData });
      successToast({
        title: `${countNew} service${countNew > 1 ? 's' : ''} imported!`,
      });
      //update matching
      const updateData = updatedServices.map((service: CarServiceItem) => {
        return {
          serviceId: service.serviceId,
          price: service?.price.toString() || '0',
        };
      });
      const countUpdated = updateData.length;
      await updateCarServices({ data: updateData });
      setFilterByActive(ServiceActive.Active);
      successToast({
        title: `${countUpdated} matching service${
          countUpdated > 1 ? 's' : ''
        } updated!`,
      });
      setShowImportCsvModal(false);
    } catch (error) {
      showError(error, 'Failed to import services.');
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Heading>Services</Heading>
        <IconButtonWrapper>
          <Button
            variant='secondary'
            text='Import .csv'
            onClick={() => handleImportClick()}
          />
          <Button
            data-cy='create-service-button'
            variant='primary'
            text='Create'
            icon={<MdAdd width='12px' height='12px' />}
            onClick={() => handleCreateServiceClick()}
          />
        </IconButtonWrapper>
      </HeaderWrapper>
      <ServiceListing
        handleUpdateServiceClick={handleUpdateServiceClick}
        filterByActive={filterByActive}
        setFilterByActive={setFilterByActive}
      />
      {showServiceDetailsModal && (
        <ModalServiceDetails
          serviceData={serviceData}
          handleModalClose={() => setShowServiceDetailsModal(false)}
          handleSubmit={onSubmitService}
        />
      )}
      {showImportCsvModal && (
        <ModalImportCsv
          handleModalClose={() => setShowImportCsvModal(false)}
          handleImportService={handleImportService}
        />
      )}
    </>
  );
};
