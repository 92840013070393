export enum InternalCustomers {
  COVIDEO = '3',
  AUTOMOTIVE = '50303',
  DEV = '54188',
  INTERNAL = '57316',
  FREEMIUM_DEV = '58410',
  FREEMIUM_PRODUCT = '58371',
  AUTOMOTIVE_LEADS_DEV = '55174',
  QA_COVIDEO = '63514',
  CDS_MARKETING = '1210',
}
