import dayjs from 'dayjs';
import {
  MeetingResponse,
  MEETING_STATUSES,
  MeetingStatus,
} from 'lib/api/meetings/types';
import { AutomotiveInput, CheckboxInput, Dropdown } from 'lib/components';
import CustomDateTimePicker from 'lib/components/custom-time-picker/CustomDateTimePicker';
import React from 'react';
import { HOURS, MINUTES } from '../../constants';
import {
  Card,
  CardTitle,
  DetailsWrapper,
  Duration,
  DurationWrapper,
  InputWrapper,
  Label,
  Textarea,
} from '../../styles';
import { useAuth } from 'lib/context';
import { CheckboxLabel } from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';
import { checkIfMultipleAttendeesAvailable } from 'lib/utils/productFeature';
import { useFormikContext } from 'formik';
import { FormikMeetingsValues } from '../../pages/MeetingDetails';
import { convertTime24to12 } from 'lib/utils/datetime';
import MultiEmailInput from 'lib/components/inputs/MultiEmailInput/MultiEmailInput';
import { FrequencySelector } from 'lib/components/frequency/FrequencySelector';
import {
  TimezoneSelector,
  timezonesOptions,
} from 'lib/components/timezoneSelector/TimezoneSelector';

interface MeetingDetailsFormProps {
  meetingDetails: MeetingResponse | undefined;
  userOptions: {
    value: string;
    label: string;
  }[];
  createMeetingStatus: MeetingStatus | null;
}

const MeetingDetailsForm = ({
  meetingDetails,
  userOptions,
  createMeetingStatus,
}: MeetingDetailsFormProps) => {
  const formik = useFormikContext<FormikMeetingsValues>();
  const isCanceled =
    meetingDetails?.meetingStatus === MEETING_STATUSES.CANCELED;
  const isFinished =
    meetingDetails?.meetingStatus === MEETING_STATUSES.FINISHED;
  const isRecurring =
    createMeetingStatus === MEETING_STATUSES.RECURRING ||
    meetingDetails?.meetingStatus === MEETING_STATUSES.RECURRING;

  const { userData } = useAuth();
  const { isCompanyAdmin } = userData;

  const hasAccessToMultiple = checkIfMultipleAttendeesAvailable(userData);

  const selectedOption = userOptions.find(
    ({ value }) => value.toString() === formik.values.userId.toString()
  );

  const disabled = isFinished || isCanceled;

  const showMeetingPeriod = () => {
    const tempDate = dayjs(formik.values.customDateTimePicker);
    const minutes = formik.values.durationMinutes.value;
    const hours = formik.values.durationHours.value;
    const totalMinutes = hours * 60 + minutes;
    const result = tempDate.add(totalMinutes, 'minutes');
    return `${convertTime24to12(tempDate.toDate())} - ${convertTime24to12(
      result.toDate()
    )}`;
  };

  return (
    <>
      <Card>
        <DetailsWrapper>
          <CardTitle>Meeting Details</CardTitle>
          <InputWrapper>
            <Label>Meeting Title</Label>
            <AutomotiveInput
              radius='6px'
              value={formik.values.title}
              placeholder='Add Title'
              disabled={disabled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formik.setFieldValue('title', e.target.value)
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Description</Label>
            <Textarea
              value={formik.values.description}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                formik.setFieldValue('description', e.target.value)
              }
              disabled={disabled}
            />
          </InputWrapper>
          {isRecurring && (
            <InputWrapper>
              <Label>Recurrence</Label>
              <FrequencySelector
                margin='0px'
                frequency={formik.values.frequency}
                setFrequency={recurring => {
                  formik.setFieldValue('frequency', recurring);
                }}
              />
            </InputWrapper>
          )}
          <InputWrapper>
            <Label>Time</Label>
            <CustomDateTimePicker
              hideDatePicker={isRecurring}
              setDate={date => {
                formik.setFieldValue('customDateTimePicker', date);
                formik.setFieldValue(
                  'deliveryTime',
                  `${dayjs(date).format('YYYY-MM-DD;hh:mm;A')};${
                    formik?.values?.preferedUserTimezone?.utc[0] ||
                    timezonesOptions[0].utc[0]
                  }`
                );
              }}
              date={formik.values.customDateTimePicker}
              useTimeSelectDropdown={true}
              disabled={disabled}
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Duration</Label>
            <DurationWrapper>
              <Dropdown
                placeholder='Hours'
                value={formik.values.durationHours}
                creatable={false}
                onChange={newValue =>
                  formik.setFieldValue('durationHours', newValue)
                }
                options={HOURS}
                className='hoursDropdown'
                width={128}
                disabled={disabled}
              />
              <Dropdown
                placeholder='Minutes'
                value={formik.values.durationMinutes}
                creatable={false}
                onChange={newValue =>
                  formik.setFieldValue('durationMinutes', newValue)
                }
                options={MINUTES}
                className='minutesDropdown'
                width={128}
                disabled={disabled}
              />
              <Duration>{showMeetingPeriod()}</Duration>
            </DurationWrapper>
          </InputWrapper>
          <InputWrapper>
            <Label>Time Zone</Label>
            <TimezoneSelector
              value={formik.values.preferedUserTimezone?.value}
              onChange={newValue => {
                formik.setFieldValue('preferedUserTimezone', newValue);
                const newDeliveryTime = formik.values.deliveryTime
                  .split(';')
                  .slice(0, -1)
                  .concat(newValue.utc[0] || timezonesOptions[0].utc[0])
                  .join(';');
                formik.setFieldValue('deliveryTime', `${newDeliveryTime}`);
              }}
              disabled={disabled}
            />
          </InputWrapper>
          {isCompanyAdmin && (
            <InputWrapper>
              <Label>Assigned To</Label>
              <Dropdown
                value={selectedOption}
                options={userOptions}
                placeholder='Select a user...'
                creatable={false}
                onChange={option =>
                  formik.setFieldValue('userId', Number(option.value))
                }
                disabled={disabled}
              />
            </InputWrapper>
          )}
          <InputWrapper>
            <Gap gap='12px'>
              <CheckboxInput
                id={'enableGuestScreenShare'}
                width={'24px'}
                blueCheck={true}
                checked={formik.values.enableGuestScreenShare}
                onChange={(e: React.SyntheticEvent) => {
                  const { checked } = e.target as HTMLInputElement;
                  formik.setFieldValue('enableGuestScreenShare', checked);
                }}
                disabled={disabled}
              />
              <CheckboxLabel htmlFor='enableGuestScreenShare'>
                Allow participants to share screen
              </CheckboxLabel>
            </Gap>
          </InputWrapper>
          {hasAccessToMultiple && (
            <InputWrapper>
              <Gap gap='12px'>
                <CheckboxInput
                  id={'hostApproval'}
                  width={'24px'}
                  blueCheck={true}
                  checked={formik.values.hostApproval}
                  onChange={(e: React.SyntheticEvent) => {
                    const { checked } = e.target as HTMLInputElement;
                    formik.setFieldValue('hostApproval', checked);
                  }}
                  disabled={disabled}
                />
                <CheckboxLabel htmlFor='hostApproval'>
                  Host must approve before joining a live
                </CheckboxLabel>
              </Gap>
            </InputWrapper>
          )}
        </DetailsWrapper>
      </Card>
      <Card>
        <CardTitle>Participant details</CardTitle>
        <MultiEmailInput
          emails={formik.values.attendees.map(atendee => atendee.email)}
          onEmailsChange={emails => {
            formik.setFieldValue(
              'attendees',
              emails.map(email => ({ email }))
            );
          }}
          disabled={isCanceled || isFinished}
        />
      </Card>
    </>
  );
};

export default MeetingDetailsForm;
