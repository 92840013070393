export const CTA_TYPES = {
  URL: 'url',
  EMAIL: 'email',
  VIDEO: 'video',
  LIBRARY: 'library',
  BOARD: 'board',
  URL_700_CREDIT: 'url700Credit',
  PERSONAL_PHONE: 'personalPhone',
  PERSONAL_EMAIL: 'personalEmail',
  FILE: 'file',
};
