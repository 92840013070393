import React from 'react';
import { DropdownContainer } from './style';
import { monthlyDaysOptions } from 'app/pages/reports/util';
import { Dropdown } from 'lib/components';
import { CustomReportModalFormikValues } from '../types';
import { useFormikContext } from 'formik';
import { FREQUENCY_TYPE } from 'lib/components/frequency/constants';

export const MonthlyFrequency = () => {
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();

  const getDropdownValue = () => {
    const current = values?.recurrings?.frequency?.split(';')[1];
    return monthlyDaysOptions.find(option => option.value === current);
  };
  return (
    <DropdownContainer
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      }}
    >
      <span>Send on day</span>
      <Dropdown
        width={71}
        placeholder='Select day of month'
        value={getDropdownValue()}
        creatable={false}
        onChange={newValue => {
          setFieldValue(
            'recurrings.frequency',
            `${FREQUENCY_TYPE.MONTHLY};${newValue.value}`
          );
        }}
        options={monthlyDaysOptions}
      />
      <span>of month</span>
    </DropdownContainer>
  );
};
