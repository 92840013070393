import {
  fetchCustomers,
  getAllDepartmentsForCustomer,
  getProfileData,
} from 'lib/api';
import {
  ACCESS_RIGHTS,
  FolderAccessResponse,
  UserData,
} from 'lib/api/folderAccess/types';
import { LoadingIndicator, NewModal, Search } from 'lib/components';
import { Button } from 'react-covideo-common';
import { Customer } from 'lib/context';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import EditUserItem from './EditUserItem';
import { theme } from 'lib/style';
import {
  ShareToWrapper,
  UsersEditList,
  SharedWith,
  EditContent,
  Menu,
  Footer,
  ScrollAbleUserArea,
} from './style';
import { MdSupervisorAccount } from 'react-icons/md';
import { DepartmentsCheckboxDropdown } from 'app/pages/reports/components';
import { ResellerModal, Viewer } from './ResellerModal';
import { DepartmentsResponse } from './types';
import { OptionType } from './data';
import { successToast } from 'lib/components/toasts/success';
import { useUpdateFolderAccess } from 'lib/api/folderAccess/updateFolderAccess';
import { useGetFolderAccess } from 'lib/api/folderAccess/getFolderAccess';
import { Folder } from 'lib/api/folders/getFolders';

type ModalProps = {
  handleModalClose: () => void;
  folderId: string;
  folder: Folder;
};

export type SimplifiedUserObject = {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  customerId: number;
  business?: string;
  departmentId: string;
  departmentName: string;
  access: ACCESS_RIGHTS;
  removed?: boolean;
};

export type UserObjectType = {
  [userId: number]: SimplifiedUserObject;
};

export type CustomerObjectType = {
  [customerId: number]: UserObjectType;
};

type Department = {
  label: string;
  value: string;
};

const Body = styled.div``;

const CompanyName = styled.div`
  color: ${theme.palette.gray100};
  font-size: 15px;
  font-weight: 500;
  margin: 20px 0;
`;

const ResellerEditModal = ({
  handleModalClose,
  folderId,
  folder,
}: ModalProps) => {
  const [customerUsers, setCustomerUsers] = useState<CustomerObjectType>({}); // parsed userAccess
  const [filteredCustomerUsers, setFilteredCustomerUsers] =
    useState<CustomerObjectType>({}); // filtered and parsed userAccess

  const [tempfilteredCustomerUsers, setTempFilteredCustomerUsers] =
    useState<CustomerObjectType>({});

  const [showResellerModal, setShowResellerModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const [departments, setDepartments] = useState<Department[]>([]); // all departments
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

  const [newUsers, setNewUsers] = useState<SimplifiedUserObject[]>([]); // newly added users
  const [isSelectAll, setIsSelectAll] = useState<boolean>(
    !!folder?.includeNewUsers
  );
  const [folderAccessPermission, setGlobalPermission] = useState<string>(
    folder?.folderAccessPermission || 'view'
  );
  const [initialUsers, setInitialUsers] = useState<CustomerObjectType>({});
  const { data: accessUsers, refetch } = useGetFolderAccess(
    Number(folderId) || 0
  );
  const [isCancel, setIsCancel] = useState(false);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);
  const { mutateAsync: updateFolderAccess } = useUpdateFolderAccess();

  const fetchCustomerInformation = async () => {
    let customerUserObject = {} as CustomerObjectType;

    const customersOfReseller = (await fetchCustomers()) || [];
    const userAccessArray: SimplifiedUserObject[] = [];

    if (!accessUsers || !accessUsers.length) return;

    await Promise.allSettled(
      accessUsers.map(async (userAccess: FolderAccessResponse) => {
        try {
          const { userId, access } = userAccess;
          const userObject = await getProfileData(userId);

          if (userObject) {
            const { customerId } = userObject;
            const customerInfo = customersOfReseller.find(
              (customer: Customer) =>
                customer.customerId.toString() === customerId.toString()
            );

            const customerUserInfo = {
              userId,
              firstName: userObject.firstName,
              lastName: userObject.lastName,
              business: customerInfo?.business || '',
              access,
              departmentId: userObject.Dept,
              departmentName: userObject.DeptName,
              customerId,
              email: userObject.email,
              removed: false,
            };
            if (!customerUserObject[customerId])
              customerUserObject[customerId] = {};

            customerUserObject[customerId][userId] = customerUserInfo;
            userAccessArray.push(customerUserInfo);
          }
        } catch (e) {
          console.log('error', e);
        }
      })
    );

    const parsedCustomerUserObject = JSON.parse(
      JSON.stringify(customerUserObject)
    );

    setCustomerUsers(parsedCustomerUserObject); // needs to be deep copy
    setFilteredCustomerUsers(parsedCustomerUserObject);
    setInitialUsers(parsedCustomerUserObject);
    setTempFilteredCustomerUsers(parsedCustomerUserObject);
    setLoading(false);
  };

  const fetchDepartments = async () => {
    // fetch for all customers
    const customerIds = Object.keys(customerUsers).map((customerId: string) =>
      Number(customerId)
    );
    const allDepartments: Department[] = [];
    await Promise.allSettled(
      customerIds.map(async (customerId: number) => {
        try {
          const departmentsResponse = (await getAllDepartmentsForCustomer(
            customerId
          )) as DepartmentsResponse;
          const { data: customerDepts } = departmentsResponse;
          if (customerDepts.length) {
            const parsedCustomerDepts = customerDepts.map(dept => ({
              label: dept.name.trim(),
              value: dept.id.toString(),
            }));
            allDepartments.push(...parsedCustomerDepts);
          }

          setDepartments(allDepartments);
        } catch (e) {}
      })
    );
  };

  const onSearch = () => {
    const customersInformation = JSON.parse(
      JSON.stringify(tempfilteredCustomerUsers)
    );

    const shouldSearchByKeyword = !!searchTerm.length;
    let shouldSearchByDepartment = true;

    if (
      !selectedDepartments.length ||
      selectedDepartments.length === departments.length
    )
      shouldSearchByDepartment = false;

    Object.keys(customersInformation).forEach((customerId: string) => {
      const usersObject = customersInformation[Number(customerId)];
      const userIds = Object.keys(usersObject).map((userId: string) =>
        Number(userId)
      );

      if (shouldSearchByDepartment) {
        userIds.forEach((userId: number) => {
          if (
            !selectedDepartments.includes(
              usersObject[userId].departmentId?.toString()
            )
          )
            delete customersInformation[Number(customerId)][userId];
        });
      }

      if (shouldSearchByKeyword) {
        const term = searchTerm.toLowerCase();
        Object.keys(usersObject).forEach((userId: string) => {
          const userInfo = usersObject[Number(userId)];
          const nameString = `${userInfo.firstName.toLowerCase()} ${userInfo.lastName.toLowerCase()}`;

          if (!nameString.includes(term))
            delete customersInformation[Number(customerId)][Number(userId)];
        });
      }
    });

    setFilteredCustomerUsers(customersInformation);
    setInitialUsers(customersInformation);
  };

  const handleRemoveOrUndoUser = (userId: number, status: boolean) => {
    const customerUserInfo = JSON.parse(JSON.stringify(filteredCustomerUsers));
    const tempCustomerUserInfo = { ...tempfilteredCustomerUsers };
    Object.keys(customerUserInfo).forEach((customerId: string) => {
      if (customerUserInfo[Number(customerId)][userId]) {
        customerUserInfo[Number(customerId)][userId].removed = status;
        return;
      }
    });
    Object.keys(tempCustomerUserInfo).forEach((customerId: string) => {
      if (tempCustomerUserInfo[Number(customerId)][userId]) {
        tempCustomerUserInfo[Number(customerId)][userId].removed = status;
        return;
      }
    });
    setFilteredCustomerUsers(customerUserInfo);
    setInitialUsers(customerUserInfo);
    setTempFilteredCustomerUsers(tempCustomerUserInfo);
  };

  const updateFilterAccess = (
    filteredCustomerObject: CustomerObjectType,
    userId: number,
    newAccess: OptionType
  ) => {
    const { value } = newAccess;
    const newCustomerUserData = JSON.parse(
      JSON.stringify(filteredCustomerObject)
    );
    Object.keys(newCustomerUserData).forEach((customerId: string) => {
      const userObject = newCustomerUserData[Number(customerId)];
      Object.keys(userObject).forEach((uid: string) => {
        const user = userObject[Number(uid)];
        if (user.userId === userId) {
          newCustomerUserData[Number(customerId)][userId].access = value;
        }
      });
    });

    return newCustomerUserData;
  };

  const onUpdateAccess = (newAccess: OptionType, userId: number) => {
    const filtered = updateFilterAccess(
      filteredCustomerUsers,
      userId,
      newAccess
    );
    const tempFiltered = updateFilterAccess(
      tempfilteredCustomerUsers,
      userId,
      newAccess
    );

    setFilteredCustomerUsers(filtered);
    setInitialUsers(filtered);
    setTempFilteredCustomerUsers(tempFiltered);
  };

  const extractViewerData = () => {
    const viewerInfo: Viewer[] = [];
    Object.keys(filteredCustomerUsers).forEach((customerId: string) => {
      const usersInfo = filteredCustomerUsers[Number(customerId)];
      Object.keys(usersInfo).forEach((userId: string) =>
        viewerInfo.push({
          userId: Number(userId),
          customerId: Number(customerId),
        })
      );
    });

    return viewerInfo;
  };

  useEffect(() => {
    if (!departments.length) {
      fetchDepartments();
    }
  }, [customerUsers]);

  useEffect(() => {
    fetchCustomerInformation();
  }, []);

  useEffect(() => {
    onSearch();
  }, [searchTerm, selectedDepartments]);

  useEffect(() => {
    if (!showResellerModal) {
      // add to accessUsers and to fitered users

      if (!!isCancel) {
        fetchCustomerInformation();
      }
      const newUserAccessData = JSON.parse(
        JSON.stringify(filteredCustomerUsers)
      );
      newUsers.forEach((newUser: SimplifiedUserObject) => {
        const { customerId, userId } = newUser;
        if (!newUserAccessData[customerId]) newUserAccessData[customerId] = {};

        newUserAccessData[customerId][userId] = newUser;
      });

      setFilteredCustomerUsers(newUserAccessData);
      setInitialUsers(newUserAccessData);
      setTempFilteredCustomerUsers(newUserAccessData);
    }
    setIsSelectAll(isSelectAll);
    setGlobalPermission(folderAccessPermission);
    setIsCancel(false);
  }, [newUsers, showResellerModal]);

  const countSharedUsers = () => {
    const userInfo: string[] = [];
    Object.keys(tempfilteredCustomerUsers).forEach((customerId: string) => {
      const userObject = tempfilteredCustomerUsers[Number(customerId)];
      Object.keys(userObject)
        .filter(u => !userObject[Number(u)].removed)
        .forEach((userId: string) => userInfo.push(userId));
    });
    return userInfo.length;
  };

  const sharedWithText = `Shared with ${countSharedUsers()} ${
    countSharedUsers() === 1 ? 'user' : 'users'
  }`;

  if (showResellerModal) {
    return (
      <ResellerModal
        handleModalClose={() => setShowResellerModal(false)}
        viewers={extractViewerData()}
        folderId={folderId}
        setSelection={setNewUsers}
        skipCreateOnSave
        setIsSelectAll={setIsSelectAll}
        setGlobalPermission={setGlobalPermission}
        setIsCancel={setIsCancel}
        initialUsers={initialUsers}
        setSelectedCustomerIds={setSelectedCustomerIds}
        folder={folder}
      />
    );
  }

  const handleUpdateFolderAccess = async () => {
    const userInfo: UserData[] = [];
    Object.keys(tempfilteredCustomerUsers).forEach((customerId: string) => {
      const userObject = tempfilteredCustomerUsers[Number(customerId)];
      const userArray = Object.keys(userObject)
        .filter(u => !userObject[Number(u)].removed)
        .map((userId: string) => {
          const user = userObject[Number(userId)];
          return {
            userId: user.userId,
            access: user.access as ACCESS_RIGHTS,
          };
        });
      userInfo.push(...userArray);
    });

    const accessObject = {
      folderId: parseInt(folderId),
      users: userInfo,
      isSelectAll,
      folderAccessPermission,
      selectedCustomerIds,
    };

    const access = await updateFolderAccess(accessObject);
    if (access) {
      successToast({ title: 'Successfully Edited Access Rights' });
      refetch();
      handleModalClose();
    }
  };

  return (
    <NewModal
      headerText='Edit Folder Access'
      closeModal={handleModalClose}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          paddingLeft: '32px',
          minHeight: '592px',
          boxSizing: 'border-box',
        },
      }}
    >
      <Body>
        {loading ? (
          <LoadingIndicator isLoading={loading} />
        ) : (
          <>
            <ShareToWrapper>
              <SharedWith>{sharedWithText}</SharedWith>
              <Button
                icon={<MdSupervisorAccount size={24} />}
                text={'Share to...'}
                variant='secondary'
                onClick={() => setShowResellerModal(true)}
              />
            </ShareToWrapper>
            <EditContent>
              <Menu>
                <div style={{ width: '60%' }}>
                  <Search
                    placeholder='Search users...'
                    onSearch={(term: string) => {
                      setSearchTerm(term);
                    }}
                    bgColor={theme.palette.white}
                  />
                </div>

                <DepartmentsCheckboxDropdown
                  departments={departments}
                  onDepartmentsSelected={(departmentValues: string[]) => {
                    setSelectedDepartments(departmentValues);
                  }}
                  selectInputWidth='224px'
                  menuShouldBlockScroll={false}
                />
              </Menu>
            </EditContent>
            <div style={{ overflow: 'hidden' }}>
              <UsersEditList style={{ boxSizing: 'border-box' }}>
                <ScrollAbleUserArea id='scrollContainerReseller'>
                  {Object.keys(filteredCustomerUsers).map(
                    (customerId: string) => {
                      const usersObject =
                        filteredCustomerUsers[Number(customerId)];
                      const users = Object.keys(usersObject).map(
                        (userId: string) => usersObject[Number(userId)]
                      );
                      const companyName = users.length ? users[0].business : '';

                      return (
                        <React.Fragment key={customerId}>
                          <CompanyName>{companyName}</CompanyName>
                          {users.map((user: SimplifiedUserObject) => (
                            <EditUserItem
                              key={user.userId}
                              user={user}
                              onChange={onUpdateAccess}
                              handleRemoveOrUndoUser={handleRemoveOrUndoUser}
                            />
                          ))}
                        </React.Fragment>
                      );
                    }
                  )}
                </ScrollAbleUserArea>
              </UsersEditList>
            </div>
            <Footer marginTop='12px'>
              <Button
                text='Cancel'
                variant='secondary'
                onClick={handleModalClose}
              />
              <Button
                text='Save changes'
                variant='primary'
                onClick={handleUpdateFolderAccess}
              />
            </Footer>
          </>
        )}
      </Body>
    </NewModal>
  );
};

export default ResellerEditModal;
