import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import {
  FieldLabel,
  FormProps,
  getCheckboxField,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const AutominerSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  const { colors } = useCovideoTheme();
  return (
    <Section>
      <SectionTitle>Autominer</SectionTitle>
      <RowWrapper>
        <Row>
          {getCheckboxField(
            'autominerEnabled',
            'Enable Autominer',
            !!values.autominerEnabled,
            () => setFieldValue('autominerEnabled', !values.autominerEnabled),
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>

        {!!values.autominerEnabled && (
          <Row>
            <FieldLabel>Autominer Group ID</FieldLabel>
            {getInputField('autominerGroupId', false, false, isSubmitting)}
          </Row>
        )}
      </RowWrapper>
    </Section>
  );
};
