import { VIDE0_ACTIVITY_TYPE } from 'lib/api/videoActivities/types';
import { useQuickShare } from 'lib/hooks/useQuickShare';
import React from 'react';
import { Button } from 'react-covideo-common';
import { IoMdShareAlt } from 'react-icons/io';
type Props = {
  videoId: number | string;
  userId: number | string;
};
export const CopyVideoAsOtherUserButton = ({ videoId, userId }: Props) => {
  const { isQuickshareLoading, onQuickShareButtonClick } = useQuickShare({});

  const handleQuickShare = async (videoId: number | string) => {
    onQuickShareButtonClick({
      videoId: videoId.toString(),
      shareAsUserId: userId.toString(),
      videoActivityType: VIDE0_ACTIVITY_TYPE.COPY_EMAIL,
      toastType: 'copyEmail',
    });
  };

  return (
    <Button
      icon={<IoMdShareAlt size={20} />}
      disabled={isQuickshareLoading}
      onClick={() => {
        handleQuickShare(videoId);
      }}
    />
  );
};
