import React from 'react';
import { useField } from 'formik';
import { ValueType } from 'react-select';
import { FormikErrorMessage } from './FormikErrorMessage';
import { FormikLabel } from './FormikLabel';
import { Dropdown } from 'lib/components';

type OptionValue = ValueType<
  {
    value: string;
    label: string;
  },
  false
>;

interface CustomDropdownProps {
  name: string;
  label: string;
  isRequired: boolean;
  options: { value: string; label: string }[];
  disabled: boolean;
}

export const FormikDropdownField = ({
  label,
  isRequired,
  options,
  disabled,
  ...props
}: CustomDropdownProps) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (option: OptionValue) => {
    helpers.setValue(option ? option.value : '');
  };

  return (
    <div style={{ width: '100%' }}>
      <FormikLabel label={label} isRequired={isRequired} />
      <Dropdown
        disabled={disabled}
        value={options.find(option => option.value === field.value)}
        onChange={handleChange}
        placeholder={'Select...'}
        onMenuOpen={() => helpers.setTouched(true)}
        options={options}
        menuPortalTarget={document.body}
        inputId={props.name}
        creatable={false}
      />
      <FormikErrorMessage meta={meta} />
    </div>
  );
};
