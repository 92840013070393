import React from 'react';
import { Field, FieldProps } from 'formik';
import { SelectInput } from 'lib/components';
import {
  AccountManagerResponse,
  AgentResponse,
  CrmProviders,
  IPackageDetails,
} from 'lib/context';
import {
  FieldLabel,
  FormProps,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';
import { DateDropdownPicker } from 'lib/components/DateDropDownPicker';
import {
  getAccountManagers,
  getAgents,
  getCrmProviders,
  getPackages,
} from 'lib/api';
import { Red } from './ResellerSection';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { TimezoneSelector } from 'lib/components/timezoneSelector/TimezoneSelector';

export const CovideoSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values, isNewCustomer } = props;
  const [loadingAgents, setLoadingAgents] = React.useState(false);
  const [agents, setAgents] = React.useState<AgentResponse[]>([]);
  const [loadingPackages, setLoadingPackages] = React.useState(false);
  const [packages, setPackages] = React.useState<IPackageDetails[]>([]);
  const [selectedPackage, setSelectedPackage] = React.useState(
    {} as IPackageDetails | undefined
  );
  const [loadingCrmProviders, setLoadingCrmProviders] = React.useState(false);
  const [crmProviders, setCrmProviders] = React.useState<CrmProviders[]>([]);

  const [loadingAccountManagers, setLoadingAccountManagers] =
    React.useState(false);
  const [accountManagers, setAccountManagers] = React.useState<
    AccountManagerResponse[]
  >([]);

  const loadAgents = async () => {
    setLoadingAgents(true);
    try {
      const agentsResp = await getAgents();
      setAgents(agentsResp);
      setLoadingAgents(false);
    } catch (error) {
      setLoadingAgents(false);
    }
  };

  const loadPackages = async () => {
    setLoadingPackages(true);
    try {
      const packagesResp = await getPackages();
      const legacyDetails = {
        id: 0,
        stripePackageId: '0',
        products: {},
        maxVideosCreated: 0,
        monthlyVideos: 0,
        maxLength: 0,
        maxQuality: 1080,
        editingType: 'Basic',
        title: 'Legacy',
      };
      packagesResp.splice(0, 0, legacyDetails);
      setPackages(packagesResp);
      setLoadingPackages(false);
    } catch (error) {
      setLoadingPackages(false);
    }
  };

  const loadCrmProviders = async () => {
    setLoadingCrmProviders(true);
    try {
      const agentsResp = await getCrmProviders();
      setCrmProviders(agentsResp);
      setLoadingCrmProviders(false);
    } catch (error) {
      setLoadingCrmProviders(false);
    }
  };

  const loadAccountManagers = async () => {
    setLoadingAccountManagers(true);
    try {
      const agentsResp = await getAccountManagers();
      setAccountManagers(agentsResp);
      setLoadingAccountManagers(false);
    } catch (error) {
      setLoadingAccountManagers(false);
    }
  };

  const setInitialPackage = async () => {
    setSelectedPackage(
      await packages.find(el => el.id === values.covideoPackageId)
    );
  };

  React.useEffect(() => {
    loadAgents();
    loadCrmProviders();
    loadAccountManagers();
    loadPackages();
    if (!isNewCustomer) {
      setInitialPackage();
    }
  }, []);

  return (
    <Section>
      <SectionTitle>Covideo</SectionTitle>
      <RowWrapper>
        {!isNewCustomer && (
          <Row>
            <FieldLabel>Business ID</FieldLabel>
            {getInputField(
              'customerId',
              false,
              !isNewCustomer,
              isSubmitting,
              'text',
              !isNewCustomer
            )}
          </Row>
        )}
        <Row>
          <FieldLabel>Referred by</FieldLabel>
          {getInputField('referredBy', false, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>
            <div>
              Salesforce Account Id<Red>*</Red>
            </div>
          </FieldLabel>
          {getInputField('salesforceAccountId', false, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>
            Package <Red>*</Red>
          </FieldLabel>
          <Field type='select' name='covideoPackageId'>
            {({ field }: FieldProps) => {
              const { value, ...rest } = field;
              if (loadingPackages) return null;
              return (
                <SelectInput
                  {...rest}
                  value={value}
                  disabled={isSubmitting}
                  onChange={event => {
                    const newPackageId = parseInt(event.target.value, 10);
                    const enableVersionThree =
                      newPackageId !== PackageName.LEGACY &&
                      newPackageId !== PackageName.ENTERPRISE;

                    setFieldValue(
                      `versionThreeEnabled`,
                      enableVersionThree ? 1 : 0
                    );
                    setFieldValue(
                      `versionCDSEnabled`,
                      enableVersionThree ? 0 : 1
                    );

                    setFieldValue(`covideoPackageId`, newPackageId);
                  }}
                >
                  {isNewCustomer && <option value=''>Select a Package</option>}
                  {!isNewCustomer && (
                    <option value={selectedPackage?.id}>
                      {selectedPackage?.title || `Select a Package`}
                    </option>
                  )}
                  {packages.map(
                    (packagesResp: IPackageDetails, index: number) => (
                      <option key={index} value={packagesResp.id}>
                        {packagesResp.title}
                      </option>
                    )
                  )}
                </SelectInput>
              );
            }}
          </Field>
        </Row>
        <Row>
          <FieldLabel>Referral Agent</FieldLabel>
          <Field type='select' name='referralAgent'>
            {({ field }: FieldProps) => {
              const { value, ...rest } = field;
              if (loadingAgents) return null;
              return (
                <SelectInput
                  {...rest}
                  value={value}
                  disabled={isSubmitting}
                  onChange={event => {
                    setFieldValue(
                      `referralAgent`,
                      parseInt(event.target.value, 10)
                    );
                  }}
                >
                  <option value=''>Select an Agent</option>
                  {agents.map((agent: AgentResponse, index: number) => (
                    <option key={index} value={agent.agentId}>
                      {agent.lastName}, {agent.firstName} - {agent.business}
                    </option>
                  ))}
                </SelectInput>
              );
            }}
          </Field>
        </Row>
        <Row>
          <FieldLabel>Account Manager</FieldLabel>
          <Field type='select' name='accountManager'>
            {({ field }: FieldProps) => {
              const { value, ...rest } = field;
              if (loadingAccountManagers) return null;
              return (
                <SelectInput
                  {...rest}
                  value={value}
                  disabled={isSubmitting}
                  onChange={event => {
                    setFieldValue(
                      `accountManager`,
                      parseInt(event.target.value, 10)
                    );
                  }}
                >
                  <option value=''>Select an Account Manager</option>
                  {accountManagers.map(
                    (accountManager: AccountManagerResponse, index: number) => (
                      <option key={index} value={accountManager.agentId}>
                        {accountManager.firstName}, {accountManager.lastName}
                      </option>
                    )
                  )}
                </SelectInput>
              );
            }}
          </Field>
        </Row>
        <Row>
          <FieldLabel>CRM Provider</FieldLabel>
          <Field type='select' name='crmProviderId'>
            {({ field }: FieldProps) => {
              const { value, ...rest } = field;
              if (loadingCrmProviders) return null;
              return (
                <SelectInput
                  {...rest}
                  value={value}
                  disabled={isSubmitting}
                  onChange={event => {
                    setFieldValue(
                      `crmProviderId`,
                      parseInt(event.target.value, 10)
                    );
                  }}
                >
                  <option value=''>None (Generic CRM)</option>
                  {crmProviders.map(
                    (crmProvider: CrmProviders, index: number) => (
                      <option key={index} value={crmProvider.id}>
                        {crmProvider.name}
                      </option>
                    )
                  )}
                </SelectInput>
              );
            }}
          </Field>
        </Row>
        <Row>
          <FieldLabel>Launch Date</FieldLabel>
          <Field type='select' name='customerLaunchDate'>
            {() => {
              if (loadingCrmProviders) return null;
              return (
                <DateDropdownPicker
                  extendStyles={{
                    valueContainer: {
                      flexWrap: 'nowrap',
                    },
                  }}
                  value={
                    values.customerLaunchDate
                      ? new Date(values.customerLaunchDate)
                      : undefined
                  }
                  onChange={newDate =>
                    setFieldValue(`customerLaunchDate`, newDate.valueOf())
                  }
                />
              );
            }}
          </Field>
        </Row>
        <Row>
          <FieldLabel>Timezone</FieldLabel>
          <Field type='select' name='timezone'>
            {({ field }: FieldProps) => {
              const { value } = field;
              return (
                <TimezoneSelector
                  value={value}
                  onChange={newValue =>
                    setFieldValue('timezone', newValue?.utc[0])
                  }
                  disabled={isSubmitting}
                  useUtcValue={true}
                />
              );
            }}
          </Field>
        </Row>
      </RowWrapper>
    </Section>
  );
};
