import { theme } from 'lib/style';
import React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;

  background-color: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.covideoBlack05};
  color: ${theme.palette.covideoBlue100};
  border-radius: 6px;
  padding: 12px;
`;

const IconContainer = styled.div`
  flex-shrink: none;
`;

type InformationProps = {
  text: string;
  width?: string;
};

export const InformationBox = ({ text, width }: InformationProps) => {
  return (
    <Container style={{ width }}>
      <IconContainer>
        <AiFillInfoCircle size={24} color={theme.palette.covideoBlue60} />
      </IconContainer>
      <div>{text}</div>
    </Container>
  );
};
