import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { videoAttributesKeys } from './videoAttributesKeys';
import { VideoAttributeValue } from '../types';

export const getVideoAttributes = async (videoId: number) => {
  const { data } = await EXPRESS_API.get(`/videos/${videoId}/video-attributes`);
  return data as VideoAttributeValue[];
};

export const useGetVideoAttributes = (videoId: number) => {
  return useQuery(
    videoAttributesKeys.detail(videoId),
    () => getVideoAttributes(videoId),
    {}
  );
};
