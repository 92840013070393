import React, { useMemo } from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { PreLoader } from './PreLoader';

const Wrapper = () => {
  return useMemo(() => <PreLoader />, []);
};

export const Cards = () => (
  <>
    <DocumentHead title="Cards" />
    <PageTemplate main={<Wrapper />} />
  </>
);
