import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const Sidebar = styled.div`
  z-index: 6;
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 100px;
  min-width: 225px;
  background-color: ${theme.palette.covideoBlue02NonAlpha};
  height: calc(100% - 146px);
  overflow-x: hidden;
  overflow-y: auto;
  ${theme.mediaQueryMinWidth.mb} {
    display: flex;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const FilterContainer = styled.div`
  padding-left: 32px;
`;
export const FilterType = styled.div`
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${theme.palette.covideoBlue40};
`;

export const HorizontalLine = styled.div`
  height: 1px;
  width: 74px;
  background-color: ${theme.palette.blue05};
`;
export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 12px;
  padding-bottom: 16px;
`;
export const CheckboxLabel = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${theme.palette.neutral100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  cursor: pointer;
`;
export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap 24px;
  align-items: start;
`;
