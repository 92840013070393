import { theme } from 'lib/style';
import styled from 'styled-components/macro';

type TitleProps = {
  type?: string;
};
export const Title = styled.h5<TitleProps>`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${props =>
    props.type === 'infoSuccess'
      ? theme.palette.green100
      : theme.palette.covideoGray100};
  margin: 0;
`;
export const AdditionalInfo = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
  color: ${theme.palette.covideoGray60};
`;

export const MainToastWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const ToastTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ToastRightWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: flex-start;
  flex-direction: column;
`;

export const ToastLeftWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
