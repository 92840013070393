import { useQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';
import { USER_INVITES_STATUS_FILTER } from 'app/pages/admin/customers/components/manageInvites/types';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IUserInvitesResponse {
  users: UserInvite[];
  count: number;
}

export interface UserInvite {
  id: number;
  customerId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  userInvite: UserInviteOptions | null;
}

interface UserInviteOptions {
  status: string;
  deliveryTime: null | string;
  deliveryUnixTimestamp: number;
}

export type UserInvitesParams = {
  customerId?: number;
  start?: number;
  limit?: number;
  search?: string;
  sortKey?: string;
  order?: string;
  status?: USER_INVITES_STATUS_FILTER;
};

const getCustomerUserInvites = async (
  params: UserInvitesParams
): Promise<IUserInvitesResponse> => {
  const { customerId, ...rest } = params;

  return (
    await EXPRESS_API.get(`/superadmin/customers/${customerId}/user-invites`, {
      params: rest,
    })
  ).data as IUserInvitesResponse;
};

export const useUserInvitesQuery = (params: UserInvitesParams) => {
  return useQuery(
    superAdminKeys.invite_users(params),
    () => getCustomerUserInvites(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!params.customerId,
    }
  );
};
