import { Formik, FormikHelpers, FormikValues } from 'formik';
import { Dropdown, TextInput } from 'lib/components';
import { ChatsModal } from 'lib/components/modal/chatModal/ChatsModal';
import React from 'react';
import { MdAttachFile, MdSend } from 'react-icons/md';
import { FormStyles } from '../../styles/UsersConversationsLayout';
import { InputField } from '../../components/InputField';
import { IFormProps } from '../../conversations.types';
import {
  ISMSParams,
  useSendSMSMutation,
} from 'lib/api/conversations/sendSMSMessage';
import { CovideoMagicButton, PROMPT_TYPE } from 'react-covideo-ai-assist';
import { LaunchPopup } from 'lib/components/magic/popups/LaunchPopup';
import { getCurrentToken } from 'configs/api/token';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { getDefaultPromptUseCase } from 'lib/utils/functions';

interface ISmsForm extends IFormProps {
  copiedMessage: string | null;
}
interface IFormikValues extends ISMSParams {}

export const SmsForm = ({
  singleConversation,
  setModals,
  modals,
  dropdownValue,
  onDropdownChangeHandler,
  items,
  copiedMessage,
}: ISmsForm) => {
  const { mutateAsync, isLoading } = useSendSMSMutation();
  const { userData } = useAuth();
  const submitForm = async (
    values: FormikValues,
    formik: FormikHelpers<IFormikValues>
  ) => {
    mutateAsync({
      conversationId: values.conversationId,
      text: values.text,
      formik,
      to: values.to,
    });
  };

  const INITIAL_TEXT_VALUE = copiedMessage === null ? '' : copiedMessage;
  const recipientDisplayName = [
    singleConversation?.contact?.firstName,
    singleConversation?.contact?.lastName,
  ]
    .filter(Boolean)
    .join(' ');

  const defaultPromptUseCase = getDefaultPromptUseCase(userData.isAutomotive);
  if (!singleConversation) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        text: INITIAL_TEXT_VALUE,
        to: singleConversation?.contact?.phone || '',
        conversationId: singleConversation.conversationId,
      }}
      enableReinitialize
      onSubmit={submitForm}
    >
      {({ setFieldValue, submitForm, values }) => (
        <FormStyles
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submitForm();
            }
          }}
        >
          <Button
            icon={<MdAttachFile size={20} />}
            variant='secondary'
            onClick={() => setModals('chooseFile')}
          />
          <Dropdown
            options={items}
            value={dropdownValue}
            onChange={onDropdownChangeHandler}
            creatable={false}
            width={100}
            menuPlacement='top'
            extendStyles={{ control: { minHeight: 42 } }}
            isSearchable={false}
          />
          <InputField
            style={{ margin: 0, width: '100%', maxWidth: '100%' }}
            placeholder='Write a reply...'
            name='text'
            as={TextInput}
            type='text'
          />
          <CovideoMagicButton
            userInput={values.text}
            defaultPromptType={PROMPT_TYPE.SMS}
            defaultPromptUseCase={defaultPromptUseCase}
            defaultRecipient={recipientDisplayName}
            handleSubmitGeneratedContent={(generatedContent: string) => {
              setFieldValue('text', generatedContent);
            }}
            variant='gradient'
            hoverPopup={<LaunchPopup />}
            token={getCurrentToken().token as string}
            userData={{
              customer: {
                business: userData.customer.business,
                hasCovideoMagic:
                  userData?.customer?.hasCovideoMagic?.toString() === '1',
                markVideosAsSent: userData.customer.markVideosAsSent,
              },
              firstName: userData.firstName,
              isAutomotiveSalesRole: userData.isAutomotiveSalesRole,
              isAutomotiveServiceRole: userData.isAutomotiveServiceRole,
              isIMSEnabled: userData.isIMSEnabled,
              isAutomotive: userData.isAutomotive,
              isCompanyAdmin: userData.isCompanyAdmin,
            }}
            aiAssistTheme={'branding'}
            apiKey={process.env.REACT_APP_APIKEY}
          />

          <Button
            type='submit'
            text={isLoading ? 'Sending' : 'Send SMS'}
            icon={<MdSend size={20} />}
            onClick={submitForm}
            disabled={isLoading || !values.text}
          />
          {modals === 'chooseFile' && (
            <ChatsModal
              handleModalClose={() => {
                setModals(false);
              }}
              onSelect={(item: any, itemType: string) => {
                if (itemType === 'video') {
                  return setFieldValue('text', item.url);
                }
                if (itemType === 'file' || itemType === 'board') {
                  return setFieldValue('text', item.shortlink);
                }
              }}
            />
          )}
        </FormStyles>
      )}
    </Formik>
  );
};
