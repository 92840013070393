export const emailIconOptions = [
  { title: 'Do not change', id: -1 },
  { title: 'Animated snapshot', id: 135 },
  { title: 'Video snapshot', id: 8 },
];

export const captionOptions = [
  { title: 'Do not change', id: -1 },
  { title: 'Let me choose which videos to transcribe', id: 0 },
  { title: 'Automatically transcribe every new video', id: 1 },
];

export const captionVisibilityOptions = [
  { title: 'Do not change', id: -1 },
  { title: 'Let me verify captions before showing on video', id: 0 },
  { title: 'Automatically show captions on all videos', id: 1 },
];

export const emailOptions = [
  { title: 'Do not change', id: -1 },
  { title: 'Enable', id: 1 },
  { title: 'Disabled', id: 0 },
];
