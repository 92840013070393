import { UsageReportPackageTypes } from './UsageReportPackageTypes';

export const UsageReportPackageOptions = [
  { value: UsageReportPackageTypes.ALL_USERS, label: 'All Users' },
  { value: UsageReportPackageTypes.ALL_PAID, label: 'All Paid Packages' },
  { value: UsageReportPackageTypes.LEGACY, label: 'Legacy' },
  { value: UsageReportPackageTypes.TRIAL, label: 'Trial' },
  { value: UsageReportPackageTypes.FREEMIUM, label: 'Freemium' },
  { value: UsageReportPackageTypes.PRO, label: 'Pro' },
  { value: UsageReportPackageTypes.BUSINESS, label: 'Business' },
  { value: UsageReportPackageTypes.ENTERPRISE, label: 'Enterprise' },
];
