import React from 'react';
import styled from 'styled-components/macro';
import { Heading } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { MdAdd } from 'react-icons/md';
import { Button } from 'react-covideo-common';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 32px;
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  gap: 48px;
`;

type Props = {
  onAddNewReseller(): void;
};

export const ResellersHeader = ({ onAddNewReseller }: Props) => {
  return (
    <HeaderWrapper>
      <LeftHeaderWrapper>
        <Heading color={theme.palette.gray100}>Resellers</Heading>
      </LeftHeaderWrapper>
      <div>
        <Button
          onClick={onAddNewReseller}
          text='New Reseller'
          icon={<MdAdd color={theme.palette.black || '#fff'} />}
          variant='secondary'
        />
      </div>
    </HeaderWrapper>
  );
};
