import { OrganizationRegion, SuperAdminCustomer } from 'lib/context';
import React from 'react';
import styled from 'styled-components/macro';
import {
  CheckboxInput,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { theme } from 'lib/style';
import { PaginationWrapper } from '../../../index.styled';
import { CustomerRegion } from '../LinkCustomersModal';
import { SORTKEYS } from 'lib/const/SuperAdminConstants';
import {
  CustomerBusiness,
  CustomerIdContainer,
  CustomerInfoContainer,
  CustomerLeft,
  RegionDropdownContainer,
  TableCell,
  TableContainer,
} from './styles';
import CustomSelect from '../CustomSelect';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';

const Container = styled.div``;

const tableFields = [
  {
    value: 'business',
    label: 'Business Name',
    sortable: true,
    sortKey: SORTKEYS.BUSINESS,
  },
  {
    value: 'reseller',
    label: 'Reseller',
  },
  {
    value: 'users',
    label: 'Active Users',
  },
];

export type PaginationData = {
  page: number;
  size: number;
  sort: string;
};

type LinkCustomersProps = {
  customerCount: number;
  customerArray: SuperAdminCustomer[];
  selectedCustomers: CustomerRegion[];
  page: number;
  size: number;
  order: PaginationConstants;
  organizationRegions: OrganizationRegion[];
  setOrder: (arg: PaginationConstants) => void;
  onCustomerSelect(customer: SuperAdminCustomer, checked: boolean): void;
  onPaginationChange: (data: PaginationData) => void;
  onRegionSelect: (regionId: number, customerId: number) => void;
  isLoading: boolean;
};

type Option = {
  value: number;
  label: string;
};

const LinkCustomersTable = ({
  customerCount,
  customerArray,
  selectedCustomers,
  page,
  size,
  order,
  organizationRegions,
  setOrder,
  onPaginationChange,
  onCustomerSelect,
  onRegionSelect,
  isLoading,
}: LinkCustomersProps) => {
  const organizationRegionOptions: Option[] = organizationRegions.map(
    (region: OrganizationRegion) => ({
      value: region.organizationRegionId,
      label: region.name,
    })
  );

  const isChecked = (customerId: number) => {
    return selectedCustomers.some(
      (sc: CustomerRegion) => sc.customerId === customerId
    );
  };

  const getSelectedRegion = (customerId: number) => {
    const data = selectedCustomers.find(
      (sc: CustomerRegion) => sc.customerId === customerId
    );
    if (!data || !data.organizationRegionId)
      return { value: 0, label: 'Select Region' };

    const region = organizationRegionOptions.find(
      (region: Option) => region.value === data.organizationRegionId
    );

    return region || { value: 0, label: 'Select Region' };
  };

  return (
    <Container style={{ position: 'relative' }}>
      <TableContextProvider
        total={customerCount}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <TableContainer isLoading={isLoading}>
          <Table
            loaderHeight={400}
            compact={true}
            relative={true}
            headers={[
              ...tableFields.map((item, index) => {
                const isAscending = order === PaginationConstants.ASCENDING;
                const isDescending = order === PaginationConstants.DESCENDING;
                return (
                  <TableCell
                    onClick={() => {
                      if (item.sortable) {
                        setOrder(
                          isAscending
                            ? PaginationConstants.DESCENDING
                            : PaginationConstants.ASCENDING
                        );
                      }
                    }}
                    key={index}
                    style={{
                      ...(item.sortable && {
                        cursor: 'pointer',
                        userSelect: 'none',
                      }),
                    }}
                  >
                    {item.label}
                    {item.sortable && (
                      <div style={{ marginLeft: '5px' }}>
                        <HiArrowNarrowUp
                          color={
                            isAscending
                              ? theme.palette.primaryDarkBlue
                              : theme.palette.gray60
                          }
                          width={8}
                          height={12}
                        />
                        <HiArrowNarrowDown
                          color={
                            isDescending
                              ? theme.palette.primaryDarkBlue
                              : theme.palette.gray60
                          }
                          width={8}
                          height={12}
                        />
                      </div>
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            rows={customerArray.map((customer: SuperAdminCustomer) => ({
              key: customer.customerId,
              columns: [
                ...tableFields.map(item => {
                  const isBusiness = item.value === 'business';
                  const isReseller = item.value === 'reseller';

                  if (isBusiness) {
                    return (
                      <CustomerLeft>
                        <TableCell>
                          <CheckboxInput
                            width='24px'
                            blueCheck={true}
                            checked={selectedCustomers.some(
                              (sc: CustomerRegion) =>
                                sc.customerId === customer.customerId
                            )}
                            onChange={(e: React.SyntheticEvent) => {
                              e.stopPropagation();
                              let { checked } = e.target as HTMLInputElement;
                              onCustomerSelect(customer, checked);
                            }}
                          />
                          <CustomerInfoContainer>
                            <CustomerBusiness>
                              {customer.business || '-'}
                            </CustomerBusiness>
                            <CustomerIdContainer>
                              ID: {customer.customerId}
                            </CustomerIdContainer>
                          </CustomerInfoContainer>
                        </TableCell>
                        <TableCell>
                          <RegionDropdownContainer
                            checked={isChecked(customer.customerId)}
                          >
                            <CustomSelect
                              value={getSelectedRegion(customer.customerId)}
                              options={[
                                { value: 0, label: 'Select Region' },
                                ...organizationRegionOptions,
                              ]}
                              onSelect={(region: Option) =>
                                onRegionSelect(
                                  region?.value || 0,
                                  customer.customerId
                                )
                              }
                              isClearable={
                                !!getSelectedRegion(customer.customerId).value
                              }
                            />
                          </RegionDropdownContainer>
                        </TableCell>
                      </CustomerLeft>
                    );
                  }

                  if (isReseller) {
                    return <TableCell>{customer.resellerName || ''}</TableCell>;
                  }
                  return (
                    <TableCell style={{ justifyContent: 'right' }}>
                      {customer.userCount}
                    </TableCell>
                  );
                }),
              ],
            }))}
            isLoading={isLoading}
          ></Table>
        </TableContainer>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Container>
  );
};

export default LinkCustomersTable;
