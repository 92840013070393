import React from 'react';
import styled from 'styled-components/macro';
import { MessageLayoutIndividual } from '../../components';

const Container = styled.div`
  margin: 0px 0px 0px 150px;
  padding: 15px 20px 80px 80px;
  min-height: 700px;
`;

export const MessageIndividual = () => {
  return (
    <Container>
      <MessageLayoutIndividual />
    </Container>
  );
};
