export const TranscriptFileTypes = [
  {
    type: 'srt',
    label: '.srt',
    fileName: 'transcript.srt',
  },
  {
    type: 'vtt',
    label: '.vtt',
    fileName: 'transcript.vtt',
  },
];
