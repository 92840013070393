import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useGetReport } from 'lib/hooks';
import { LoadingIndicator } from 'lib/components';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import { REPORT_SCOPE } from 'lib/api';

const Container = styled.div`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 #1d1e240a;
  border: solid 1px #f7f8fa;
  background-color: #fff;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Value = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #001b53;
  margin: 8px 0 16px 0;
`;

type Props = {
  reportType: string;
  dateRange: string;
  from: Date;
  to: Date;
  users?: string[];
  customer?: string;
  reportScope?: REPORT_SCOPE;
  organizationId?: string | number;
  organizationRegionId?: string | number;
};

export const ReportCard = (props: Props) => {
  const {
    reportType,
    from,
    to,
    dateRange,
    users,
    customer = '',
    reportScope = REPORT_SCOPE.RESELLER,
    organizationId,
    organizationRegionId,
  } = props;
  const [retryCount, setRetryCount] = useState(0);

  const { data, error, loading, label } = useGetReport({
    reportType,
    from,
    to,
    dateRange,
    users,
    retryCount,
    customer,
    reportScope,
    organizationId,
    organizationRegionId,
  });

  const showLoading = error || loading;

  return (
    <Container>
      {showLoading && (
        <LoadingIndicator
          isLoading={loading}
          height='70px'
          error={error}
          onRetry={() => setRetryCount(retryCount + 1)}
          zIndex={0}
        />
      )}
      {!showLoading && (
        <div>
          <Title>{label}</Title>
          <Wrapper>
            <Value>{addThousandCommaSeparator(data.cnt)}</Value>
          </Wrapper>
        </div>
      )}
    </Container>
  );
};
