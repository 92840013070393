import { fetchUserData } from 'lib/api';
import React, { useContext, createContext, ReactNode, useEffect } from 'react';

interface IContextProps {
  isEnabled: boolean;
  isFullScreen: boolean;
  setIsFullScreen: any;
  selectedStyle: string;
  selectedLayout: string;
  undoState: any;
  setRedoState: any;
  redoState: any;
  isUpdateMode: boolean;
  pageBackgroundColor: string;
  pageBackgroundImageUrl: string;
  setPageBackgroundImageUrl: any;
  selectedLayoutData: any;
  setUndoState: any;
  setSelectedStyle: any;
  setIsEnabled: any;
  setSelectedLayout: any;
  setSelectedLayoutData: any;
  showAddSectionModal: any;
  setShowAddSectionModal: any;
  elementToEdit: any;
  setElementToEdit: any;
  elementSectionKey: any;
  elementIndex: any;
  setElementIndex: any;
  setElementSectionKey: any;
  setPageBackgroundColor: any;
  defaultCta: any;
  defaultSocialLinks: any;
  setDefaultCta: any;
  setDefaultSocialLinks: any;
  showSaveDesignModal: boolean;
  setShowSaveDesignModal: any;
  selectedStyleProperties: any;
  setSelectedStyleProperties: any;
  pageTitle: string;
  pageId: number | null;
  isShared: boolean;
  isDefault: boolean;
  allowDuplicates: boolean;
  setPageTitle: any;
  setPageId: any;
  setIsShared: any;
  setIsDefault: any;
  setAllowDuplicates: any;
  designId: number | null;
  setDesignId: any;
  builderVersion: string;
  setIsUpdateMode: any;
  isImageUploadInProgress: boolean;
  setIsImageUploadInProgress: any;
  userProfile: any;
  setUserProfile: any;
  pageBackgroundSize: string;
  pageBackgroundPosition: string;
  setPageBackgroundPosition: any;
  setPageBackgroundSize: any;
}

export const LandingPageBuilderContext = createContext({} as IContextProps);

type Props = {
  children: ReactNode;
};

export const LandingPageBuilderContextProvider = ({ children }: Props) => {
  const [isEnabled, setIsEnabled] = React.useState(false);
  const [selectedStyle, setSelectedStyle] = React.useState('');
  const [selectedLayout, setSelectedLayout] = React.useState('');
  const [selectedLayoutData, setSelectedLayoutData] = React.useState(null);
  const [undoState, setUndoState] = React.useState<any>([]);
  const [redoState, setRedoState] = React.useState<any>([]);
  const [isUpdateMode, setIsUpdateMode] = React.useState(false);
  const [pageBackgroundColor, setPageBackgroundColor] = React.useState('');
  const [showAddSectionModal, setShowAddSectionModal] = React.useState(false);
  const [elementToEdit, setElementToEdit] = React.useState(null);
  const [elementSectionKey, setElementSectionKey] = React.useState('');
  const [elementIndex, setElementIndex] = React.useState('');
  const [defaultCta, setDefaultCta] = React.useState(null);
  const [defaultSocialLinks, setDefaultSocialLinks] = React.useState(null);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState('');
  const [pageId, setPageId] = React.useState(null);
  const [isShared, setIsShared] = React.useState(false);
  const [isDefault, setIsDefault] = React.useState(false);
  const [allowDuplicates, setAllowDuplicates] = React.useState(false);
  const [designId, setDesignId] = React.useState(null);
  const [pageBackgroundImageUrl, setPageBackgroundImageUrl] =
    React.useState('');
  const [showSaveDesignModal, setShowSaveDesignModal] = React.useState(false);
  const [selectedStyleProperties, setSelectedStyleProperties] = React.useState(
    {}
  );

  const [builderVersion] = React.useState('1.0.0');
  const [isImageUploadInProgress, setIsImageUploadInProgress] =
    React.useState(false);

  const [userProfile, setUserProfile] = React.useState(null);
  const [pageBackgroundPosition, setPageBackgroundPosition] =
    React.useState('left top');
  const [pageBackgroundSize, setPageBackgroundSize] = React.useState('cover');

  const getUserProfile = () => {
    if (userProfile == null) {
      fetchUserData().then(user => {
        setUserProfile(user);
      });
    }
  };

  React.useEffect(() => {
    getUserProfile();
  }, []);

  const value = {
    isEnabled,
    setIsEnabled,
    setSelectedStyle,
    setSelectedLayout,
    setSelectedLayoutData,
    undoState,
    setUndoState,
    redoState,
    setRedoState,
    elementToEdit,
    setElementToEdit,
    elementSectionKey,
    elementIndex,
    setElementIndex,
    setElementSectionKey,
    selectedStyle,
    selectedStyleProperties,
    setSelectedStyleProperties,
    selectedLayout,
    isUpdateMode,
    selectedLayoutData,
    pageBackgroundColor,
    pageBackgroundImageUrl,
    showAddSectionModal,
    setShowAddSectionModal,
    setPageBackgroundColor,
    defaultSocialLinks,
    setDefaultSocialLinks,
    defaultCta,
    setDefaultCta,
    isFullScreen,
    setIsFullScreen,
    setPageBackgroundImageUrl,
    showSaveDesignModal,
    setShowSaveDesignModal,
    pageTitle,
    pageId,
    isShared,
    isDefault,
    allowDuplicates,
    setPageTitle,
    setPageId,
    setIsShared,
    setIsDefault,
    setAllowDuplicates,
    designId,
    setDesignId,
    builderVersion,
    setIsUpdateMode,
    isImageUploadInProgress,
    setIsImageUploadInProgress,
    userProfile,
    setUserProfile,
    pageBackgroundSize,
    pageBackgroundPosition,
    setPageBackgroundPosition,
    setPageBackgroundSize,
  };

  useEffect(() => {}, [selectedLayoutData]);

  return (
    <LandingPageBuilderContext.Provider value={value}>
      {children}
    </LandingPageBuilderContext.Provider>
  );
};

export const useLandingPageBuilderContext = () => {
  const value = useContext(LandingPageBuilderContext);
  return value;
};
