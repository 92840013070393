import React from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components/macro';
import { last } from 'lodash';
import { CheckboxInput } from 'lib/components';

const MenuHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
  padding: 8px 24px;
  border-bottom: 1px solid #eeeff2;
  cursor: pointer;
`;

const MenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      <MenuHeader
        onClick={() => {
          props.selectProps.onChange([
            {
              value: 'all',
              label: 'All Departments',
            },
          ]);
        }}
      >
        All Departments
      </MenuHeader>
      {props.children}
    </components.MenuList>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  > label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #272a32;
    margin-left: 8px;
    cursor: pointer;
    width: 168px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
`;

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <OptionWrapper>
          <CheckboxInput
            checked={props.isSelected}
            onChange={() => null}
            onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
              event.preventDefault();
            }}
          />
          <label title={props.label}>{props.label}</label>
        </OptionWrapper>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }: any) => {
  const values = props.getValue();

  // to enable closing on outside click
  const selectOptionsAndInput = React.Children.map(children, child => {
    if (child && [components.SingleValue].indexOf(child.type) === -1) {
      return child;
    }
    return null;
  });

  let searchInput = last(selectOptionsAndInput);

  let text = 'All Departments';
  if (values.length) {
    if (values[0].label.trim().length > 15) {
      text = `${values[0].label.trim().substring(0, 15) + '...'}`;
    } else {
      text = `${values[0].label}`;
    }
  }
  if (values.length > 1) text = `${values.length} departments`;

  return (
    <components.ValueContainer {...props}>
      <div>{text}</div>
      {searchInput}
    </components.ValueContainer>
  );
};

type Department = {
  label: string;
  value: string;
};

type Props = {
  onDepartmentsSelected: (values: string[]) => void;
  departments: Department[];
  selectInputWidth?: string;
  showMenu?: boolean;
  setShowMenu?: (arg: boolean) => void;
  menuPortalTarget?: HTMLElement | null;
  menuShouldBlockScroll?: boolean;
};

export const DepartmentsCheckboxDropdown = ({
  onDepartmentsSelected,
  departments,
  selectInputWidth = '225px',
  menuPortalTarget = document.body,
  menuShouldBlockScroll = true,
}: Props) => {
  const [selectedDepartments, setSelectedDepartments] = React.useState(
    [] as Department[]
  );

  const onChangeDepartments = (newValues: Department[]) => {
    newValues = newValues || [];
    if (newValues[0]?.value === 'all') {
      setSelectedDepartments(departments);
      onDepartmentsSelected(departments?.map(value => value.value));
      return;
    }
    setSelectedDepartments(newValues);
    onDepartmentsSelected(newValues.map(value => value.value));
  };

  return (
    <Select
      placeholder={'All departments'}
      options={departments}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuShouldBlockScroll={menuShouldBlockScroll}
      menuPortalTarget={menuPortalTarget}
      menuPosition='absolute'
      isClearable={false}
      isSearchable={false}
      components={{
        Option,
        ValueContainer,
        IndicatorSeparator: () => null,
        MenuList,
      }}
      // @ts-ignore
      onChange={value => onChangeDepartments(value)}
      allowSelectAll={true}
      value={selectedDepartments}
      styles={{
        menu: (base: any) => ({
          ...base,
          width: 'fit-content',
          right: 0,
          zIndex: 999,
        }),
        menuList: (base: any) => ({
          ...base,
          width: '100%',
          overflow: 'auto',
          maxHeight: '220px',
          marginBottom: '10px',
        }),
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 9999,
        }),
        option: (base: any) => ({
          ...base,
          cursor: 'pointer',
          background: 'transparent',
          display: 'flex',
          justifyContent: 'flex-start',
          '&:hover': {
            background: 'transparent',
          },
          '&:active': {
            background: 'transparent',
          },
        }),
        control: (base: any) => ({
          ...base,
          border: '1px solid #D0D3D9',
          height: '40px',
          width: selectInputWidth,
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #001B53',
          },
        }),
      }}
    />
  );
};
