import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

type SendEmailParams = {
  dropTitle: string;
  dropCreatedDate: string;
  dropType: string;
  dropMessage: string;
  dropUrl: string;
  recipientEmails: string;
  dropThumbnailUrl: string;
  notificationProp?: string;
};

const sendShareNotificationEmail = async ({
  dropTitle,
  dropMessage,
  dropCreatedDate,
  dropType,
  dropUrl,
  recipientEmails,
  dropThumbnailUrl,
  notificationProp,
}: SendEmailParams) => {
  const response = await EXPRESS_API.post('/droplr/sendShareNotification', {
    dropTitle,
    dropMessage,
    dropCreatedDate,
    dropType,
    dropUrl,
    recipientEmails,
    dropThumbnailUrl,
    notificationProp,
  });
  return response.data;
};

export const useSendShareNotificationEmail = () => {
  const { showError } = useToastError();
  return useMutation(
    (params: SendEmailParams) => sendShareNotificationEmail(params),
    {
      onError: (err: AxiosError) => {
        showError(err);
      },
    }
  );
};
