import * as React from 'react';

export const useOnClickOutside = callback => {
  const [node, setNode] = React.useState(null);

  React.useEffect(() => {
    if (!node) return;

    const handleClickOutside = e => {
      if (!node.contains(e.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () =>
      document.removeEventListener('click', handleClickOutside, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node]);

  // Pass ref as callback so that it runs when node mounts:
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const ref = React.useCallback(node => setNode(node), []);

  return { ref };
};
