import { UserOrganizationAccess } from 'lib/context';

export const checkIfHasAccessToMultipleOrg = (
  userOrganizationAccess: UserOrganizationAccess[]
) => [...new Set(userOrganizationAccess.map(access => access.id))].length > 1;

export const getOrgNameFromAccess = (
  organizationId: string | number,
  userOrganizationAccess: UserOrganizationAccess[]
): string => {
  if (!organizationId || !userOrganizationAccess.length) {
    return '';
  }
  const orgAccess = userOrganizationAccess.find(
    access => access.organizationId === organizationId
  );
  return orgAccess?.organization?.name || '';
};
