import { EXPRESS_API } from 'configs/api/expressApi';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks/useToastError';
import { useMutation, useQueryClient } from 'react-query';
import { notificationKeys } from './queryKeys';

const readNotification = async (notificationId: string): Promise<any> => {
  return (await EXPRESS_API.put(`notification/update/${notificationId}`)).data;
};

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient();
  const { showError } = useToastError();
  const { invalidateNotificationCount } = useAuth();
  return useMutation(readNotification, {
    onError: showError,
    onSuccess: async () => {
      await queryClient.invalidateQueries(notificationKeys.details());
      await invalidateNotificationCount();
    },
  });
};
