export interface Filters {
  search: string;
  emailStatus: string | null;
  SMS: number;
}

export enum StatusOptionsValues {
  DELIVERED = 'delivered',
  OPEN = 'open',
  BOUNCE = 'bounce',
  DROPPED = 'dropped',
  PROCESSED = 'processed',
  SPAM = 'spamreport',
  DEFERRED = 'deferred',
  UNSUBSCRIBED = 'unsubscribed',
  INVALID_EMAIL = 'invalid_email',
  //check this
  VIEWED = 'viewed',
  REQUESTS = 'requests',
  BLOCKED = 'blocked',
}

export interface IDropdown {
  label: string;
  value: string;
}

interface DeliveryMethodIDropdown {
  label: string;
  value: number | string;
}

export const deliveryMethodOptions: DeliveryMethodIDropdown[] = [
  { label: 'All delivery methods', value: 'all' },
  { label: 'SMS', value: 1 },
  { label: 'Email', value: 0 },
];

export interface Stats {
  all?: number;
  delivered?: number;
  viewed?: number;
  invalid_email?: number;
  unsubscribed?: number;
}

export const statusOptions: IDropdown[] = [
  { label: 'All statuses', value: 'all' },
  { label: 'Delivered', value: StatusOptionsValues.DELIVERED },
  { label: 'Viewed', value: StatusOptionsValues.VIEWED },
  { label: 'Invalid email', value: StatusOptionsValues.INVALID_EMAIL },
  { label: 'Unsubscribed', value: StatusOptionsValues.UNSUBSCRIBED },
  // { label: 'Requests', value: StatusOptionsValues.REQUESTS },
  { label: 'Opened', value: StatusOptionsValues.OPEN },
  { label: 'Bounced', value: StatusOptionsValues.BOUNCE },
  { label: 'Blocked', value: StatusOptionsValues.BLOCKED },
  { label: 'Dropped', value: StatusOptionsValues.DROPPED },
  { label: 'Sent', value: StatusOptionsValues.PROCESSED },
  { label: 'Spam', value: StatusOptionsValues.SPAM },
  { label: 'Deferred', value: StatusOptionsValues.DEFERRED },
];

export const quickFilters: IDropdown[] = [
  { label: 'Delivered', value: StatusOptionsValues.DELIVERED },
  { label: 'Sent', value: StatusOptionsValues.PROCESSED },
  { label: 'Opened', value: StatusOptionsValues.OPEN },
  { label: 'Viewed', value: StatusOptionsValues.VIEWED },
];

export const getStatusColors = (value: StatusOptionsValues) => {
  switch (value.toLowerCase()) {
    case StatusOptionsValues.VIEWED:
    case StatusOptionsValues.PROCESSED:
      return {
        wrapper: {
          backgroundColor: 'rgba(30, 164, 95, 0.05)',
        },
        paragraph: {
          color: 'rgba(30, 164, 95, 0.8)',
        },
      };
    case StatusOptionsValues.OPEN:
    case StatusOptionsValues.DELIVERED:
    case StatusOptionsValues.DROPPED:
    case StatusOptionsValues.REQUESTS:
    case StatusOptionsValues.DEFERRED:
      return {
        wrapper: {
          backgroundColor: 'rgba(0, 27, 83, 0.05)',
        },
        paragraph: {
          color: 'rgba(0, 27, 83, 0.8)',
        },
      };
    case StatusOptionsValues.INVALID_EMAIL:
    case StatusOptionsValues.BOUNCE:
    case StatusOptionsValues.UNSUBSCRIBED:
    case StatusOptionsValues.BLOCKED:
    case StatusOptionsValues.SPAM:
      return {
        wrapper: {
          backgroundColor: 'rgba(232, 76, 61, 0.05)',
        },
        paragraph: {
          color: 'rgba(232, 76, 61, 0.8)',
        },
      };
    default:
      return {
        wrapper: {
          backgroundColor: 'rgba(0, 27, 83, 0.05)',
        },
        paragraph: {
          color: 'rgba(0, 27, 83, 0.8)',
        },
      };
  }
};
