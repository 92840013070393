import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useHistory } from 'react-router';

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 12px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;
const Manage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary[100]}; /** SUS-961 changes **/
  text-decoration-line: underline;
  cursor: pointer;
`;
const Main = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.blue100};
`;
type Props = {
  totalActiveUsers: any;
  totalAllowedUsers: any;
};
export const UsersOverview = ({
  totalActiveUsers,
  totalAllowedUsers,
}: Props) => {
  const history = useHistory();

  return (
    <>
      <Header>
        <Title>Users</Title>
        <Manage onClick={() => history.push('/users/list')}>Manage</Manage>
      </Header>
      <Main>
        {totalActiveUsers}/{totalAllowedUsers} active
      </Main>
    </>
  );
};
