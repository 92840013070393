import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks/useToastError';
import { useMutation, useQueryClient } from 'react-query';
import { IQuickFilterValues } from './getQuickFilters';
import { quickFilterKeys } from './quickFilterKey';

const deleteQuickFilter = async (id: number): Promise<any> => {
  return (await EXPRESS_API.delete(`quick-filters/${id}`)).data;
};

export const useQuickQueryDeleteMutation = (
  resetFormikValues: () => void,
  onModalClose: () => void
) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(deleteQuickFilter, {
    onSuccess: () => {
      queryClient.invalidateQueries(quickFilterKeys.all());
    },
    onMutate: id => {
      queryClient.cancelQueries(quickFilterKeys.all());
      const oldData = queryClient.getQueryData(
        quickFilterKeys.all()
      ) as IQuickFilterValues[];

      const newData = oldData?.filter(item => item.quickFilterId !== id) || [];

      newData.length === 0 && onModalClose();

      queryClient.setQueryData(quickFilterKeys.all(), newData);
      resetFormikValues();

      return { oldData };
    },
    onError: (err, _, context: any) => {
      queryClient.setQueryData(
        quickFilterKeys.all(),
        (context?.oldData as IQuickFilterValues[]) || []
      );
      showError(err);
    },
  });
};
