/**
 * @desc
 * NOTE: widget keys are bit different and keys should
 * be used in this way
 * https://tkdodo.eu/blog/effective-react-query-keys
 */
export const widget = {
  all: ['widget'] as const,
  details: () => [...widget.all, 'detail'] as const,
  /**
   * @desc
   * use without params only if
   * refetching and removing multiple widgets
   * with same key but different params
   */
  detail: (widgetName: string, filters?: object) =>
    [
      ...widget.details(),
      widgetName,
      { ...(!!filters ? { filters } : {}) },
    ] as const,
};
