import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PaymentForm } from './main/PaymentForm';

export const RegisterRoutes = () => {
  return (
    <>
      <Switch>
        <Route path="/register/trial/payment" component={PaymentForm} />
      </Switch>
    </>
  );
};
