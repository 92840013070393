import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const FrequencyContainer = styled.div`
  display: flex;
`;

export const DropdownContainer = styled.div`
  margin-left: 12px;
  & > .recurringDropdown > div {
    border-radius: 4px;
    > div {
      flex-wrap: nowrap;
    }
  }
  & > .recurringMonthlyDropdown {
    margin: 0 10px;
  }
  & > .recurringMonthlyDropdown > div {
    border-radius: 4px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  cursor: pointer;
  height: 24px;
  margin-right: 32px;
  > span {
    margin-left: 12px;
    margin-top: 2px;
  }
`;

export const WeeklyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const WeeklyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
