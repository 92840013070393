import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import CustomLegend from '../legend/CustomLegend';
import WidgetHeader from './common/WidgetHeader';
import { WidgetWrapper } from './common/styles';
import { getReports } from 'lib/api';
import { useQuery } from 'react-query';
import { widget } from './keys';
import {
  IParams,
  REPORTS_STALE_TIME,
  WidgetLabels,
  WidgetValues,
} from './types';
import { EmptyWidget } from '../EmptyWidget';
import CustomTooltip from '../tooltip/CustomTooltip';

const StatsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
const RecordedWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
const RecordedNumber = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #272a32;
`;
const RecordedDesc = styled.p`
  color: #9297a2;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const COLORS = ['#001B53', '#B6E0EC', '#FF8B22', '#1EA45F', '#D0D3D9'];

interface PieChartDescProps {
  title: string;
  params: IParams;
  addDataForExport: (data: any) => void;
}

interface IApiData {
  id: number;
  name: string;
  value: number;
  views: number;
}

interface IMapperData {
  data: IApiData[];
  views: number;
}

const apiUrl = 'metrics/videos/video-purpose';

const PieChartDesc = ({
  title,
  params,
  addDataForExport,
}: PieChartDescProps) => {
  const { data, isLoading, isRefetching } = useQuery(
    widget.detail(WidgetValues.VIEWS_BY_VIDEO_TYPE, params),
    () => getReports(params, apiUrl),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const mapDataForExport = (widgetData: any) => {
    let exportData: any = {
      title: WidgetLabels.VIEWS_BY_VIDEO_TYPE,
      data: [],
      arr: [],
    };
    if (widgetData && widgetData.total !== 0) {
      let temp: any = {};
      temp['Total Views'] = widgetData.views;
      widgetData?.data?.forEach((el: any) => {
        const percentage = (el.value / widgetData.views) * 100;
        temp[el.name] = `${percentage.toFixed(1)}%`;
      });
      exportData.data.push(temp);
    }
    return exportData;
  };

  const organizeWidgetData = (data: IApiData[], limit: number) => {
    if (!data || data.length === 0) {
      return { data: [], views: 0 };
    }
    let totalViews = 0;
    let mapper: IMapperData = { data: [], views: 0 };
    let other = 0;

    //Filter data to put every name=null \\ name='' to 'Other'
    const filterData = data
      .filter(function (val: any) {
        totalViews += parseInt(val.views) ? parseInt(val.views) : 0;
        if (val.name === null || val.name === '') {
          other += parseInt(val.views) ? parseInt(val.views) : 0;
          return false; // skip
        }
        if (val.name === 'Thanks For Your Business') {
          val.name = 'TFYB';
        }
        return true;
      })
      .map(function (val: any) {
        return {
          ...val,
          value: parseInt(val.value),
        };
      });

    //Calculating data greater than limit and adding it into 'Other' so we have the data combined from the function above
    const wrappedData = filterData.map((val: any, index: number) => {
      if (index + 2 > limit) {
        other += parseInt(val.views) ? parseInt(val.views) : 0;
      }
      return {
        ...val,
        value: parseInt(val.value),
      };
    });

    mapper.data = wrappedData.slice(0, limit - 1);
    mapper.data.push({ id: 0, name: 'Other', value: other, views: other });
    mapper.views = totalViews;

    return mapper;
  };

  const widgetData = organizeWidgetData(data, 5);

  useEffect(() => {
    addDataForExport(mapDataForExport(widgetData));
  }, [data]);

  if (isLoading) {
    return <SkeletonLoader imageName='pie-desc' />;
  }

  return (
    <WidgetWrapper>
      <WidgetHeader title={title} isRefetching={isRefetching}>
        <StatsWrapper>
          {/* <TrendUpWrapper>
            <TrendUp />
            <TrendUpPercentage>12.4%</TrendUpPercentage>
          </TrendUpWrapper> */}
          <RecordedWrapper>
            <RecordedNumber>{widgetData.views}</RecordedNumber>
            <RecordedDesc>views</RecordedDesc>
          </RecordedWrapper>
        </StatsWrapper>
      </WidgetHeader>
      {widgetData?.data?.length ? (
        <PieChart width={400} height={185}>
          <Pie
            data={widgetData.data}
            dataKey='value'
            fill='#FC0'
            innerRadius={30}
            cx={65}
            cy={75}
          >
            {widgetData?.data.map((entry: any, index: number) => (
              <Cell key={entry.id} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend
            content={
              <CustomLegend payload={organizeWidgetData(data, 5).data} />
            }
            layout='vertical'
            verticalAlign='middle'
            align='right'
          />
          <Tooltip content={<CustomTooltip customName='view' pluralActive />} />
        </PieChart>
      ) : (
        <EmptyWidget />
      )}
    </WidgetWrapper>
  );
};

export default PieChartDesc;
