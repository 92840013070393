import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdSave } from 'react-icons/md';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { UserDetailsData } from 'lib/context';
import { useHistory } from 'react-router';
import { Button } from 'react-covideo-common';
import { ButtonSwitch } from 'lib/components';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 32px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const UserCounter = styled.div`
  display: flex;
`;

const Heading = styled.div`
  font-family: 'Work Sans';
  margin: 0;
  text-align: left;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  line-break: anywhere;
  overflow: hidden;
  height: 40px;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RequiredField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.primary[100]};
  ${theme.mediaQueryMinWidth.sm} {
    padding: 10px 24px;
    flex-direction: row;
    justify-content: space-between;
  }
  label {
    color: #ff8b22;
  }
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
`;

const RightHeaderWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const TrialBadge = styled.div`
  padding: 2px 8px;
  width: 72px;
  height: 24px;
  display: flex;
  flex-direction: row;
  // flex: none;
  // flex-grow: 0;
  align-items: center;
  justify-content: center;
  background: rgba(182, 224, 236, 0.2);
  border-radius: 4px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 27, 83, 0.8);
`;

const views = [
  {
    value: 'enabled',
    text: 'Active',
  },
  {
    value: 'disabled',
    text: 'Inactive',
  },
];

type Props = {
  user: UserDetailsData;
  onSave: () => void;
  onStatusChange(value: VerificationStatus): void;
  disableSaveButton: boolean;
  isEditView: boolean;
};

export const UserDetailsHeader = ({
  user,
  onSave,
  onStatusChange,
  disableSaveButton,
  isEditView,
}: Props) => {
  const history = useHistory();

  return (
    <HeaderWrapper>
      <LeftHeaderWrapper>
        <Heading color={theme.palette.gray100}>
          {isEditView ? user.firstName + ' ' + user.lastName : 'New User'}
        </Heading>
        {isEditView
          ? !!user.trialAccount && <TrialBadge>TRIAL</TrialBadge>
          : ''}
        {isEditView && (
          <ButtonSwitch
            defaultValue={!!user.verified ? 'enabled' : 'disabled'}
            skipConfirmation={false}
            values={views}
            onChange={newView => {
              onStatusChange(
                newView === 'enabled'
                  ? VerificationStatus.ACTIVE
                  : VerificationStatus.INACTIVE
              );
            }}
          />
        )}
      </LeftHeaderWrapper>
      {!isEditView && (
        <UserCounter>
          <RequiredField>* indicates Required Field</RequiredField>
        </UserCounter>
      )}
      <RightHeaderWrapper>
        {isEditView && (
          <Button
            icon={<MdSave color={theme.palette.white || '#fff'} />}
            onClick={() => {
              onSave();
            }}
            text={'Save changes'}
            disabled={disableSaveButton}
          />
        )}
        {!isEditView && (
          <ButtonContainer>
            <Button
              variant='secondary'
              text={'Cancel'}
              onClick={() => history.push(`/admin/users`)}
            />
            <Button
              icon={<MdSave />}
              text={'Add User'}
              disabled={disableSaveButton}
              onClick={() => {
                onSave();
              }}
            />
          </ButtonContainer>
        )}
      </RightHeaderWrapper>
    </HeaderWrapper>
  );
};
