export const PreferenceSounds = [
  {
    name: 'Mute',
    value: '',
  },
  {
    name: 'Pop Ding',
    value: 'Pop Ding',
  },
  {
    name: 'Bongo Drum',
    value: 'Bongo Drum',
  },
  {
    name: 'Jingle Bells',
    value: 'Jingle Bells',
  },
  {
    name: 'Ding Dong',
    value: 'Ding Dong',
  },
  {
    name: 'Flute',
    value: 'Flute',
  },
  {
    name: 'Piano',
    value: 'Piano',
  },
];
