import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import searchGuideEmpty from '../assets/images/searchGuideEmpty.png';

const ThumbnailWrapper = styled.div`
  max-width: calc((100% / 4) - 10px);
  width: calc((100% / 4) - 10px);
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
`;

const GuidesCategoryEmptyWrapper = styled.div`
  display: flex;
  gap: 13px;
  flex-wrap: wrap;
  position: relative;

  max-height: 201px;
`;

const loading = keyframes`
 0% { transform: translateX(-150%) }
 50% { transform: translateX(-60%) }
 100% { transform: translateX(150%) }
`;
const ShimmerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${loading} 1s infinite;
`;

const Shimmer = styled.div`
  position: relative;
  top: 4px;
  width: 30%;
  height: calc(100% - 20px);
  background-color: rgba(255, 255, 255, 0.8);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
`;

const Message = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
  color: #4e5461;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  flex-direction: column;
  gap: 6px;
`;
export const CategorizedLoader = () => {
  const emptyArray = [...Array(4)];
  return (
    <GuidesCategoryEmptyWrapper>
      <InfoWrapper>
        <Message>Loading....</Message>
      </InfoWrapper>
      {emptyArray.map((_, index) => (
        <ThumbnailWrapper key={index}>
          <img src={searchGuideEmpty} alt='searchGuideEmpty' height='201px' />
        </ThumbnailWrapper>
      ))}
      <ShimmerWrapper>
        <Shimmer />
      </ShimmerWrapper>
    </GuidesCategoryEmptyWrapper>
  );
};
