import React, { useState, useEffect } from 'react';

import styled from 'styled-components/macro';
import { LoadingIndicator, NewModal } from 'lib/components';
import { RecipientsSelector } from 'app/pages/reports/components/RecipientsSelector';
import { useMutation } from 'react-query';
import { inviteFriends } from 'lib/api/inviteFriendsApi';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from '../toasts/error';
import { Button } from 'react-covideo-common';

const Content = styled.div`
  max-width: 592px;
  width: 592px;
  display: flex;
  flex-direction: column;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
  position: relative;
`;

const Footer = styled.div`
  margin-top: 32px;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  position: absolute;
  bottom: -20px;
`;

const Paragraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272a32;
  margin: 0px 0px 16px 0;
`;

type Props = {
  handleModalClose: () => void;
  onDismissHandler: () => void;
  onMaybeLaterHandler: () => void;
};

export const InviteFriendsModal = ({
  handleModalClose,
  onDismissHandler,
  onMaybeLaterHandler,
}: Props) => {
  const [friends, setFriends] = useState([] as string[]);
  const [error, setError] = useState('');

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
      onMaybeLaterHandler();
    }
  };

  const { mutateAsync, isLoading } = useMutation(inviteFriends, {
    onSuccess: () => {
      handleModalClose();
      onDismissHandler();
      successToast({ title: 'Your invites are sent successfully!' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred saving your information, please try again later.',
      });
    },
  });

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  return (
    <NewModal
      headerText='Invite friends'
      closeModal={() => {
        handleModalClose();
        onMaybeLaterHandler();
      }}
    >
      <Content>
        {isLoading && <LoadingIndicator isLoading={isLoading} height='104px' />}
        {!isLoading && (
          <>
            <Paragraph>
              Enter users' email addresses to invite them to join Covideo. These
              emails will in no way be used for marketing purposes.
            </Paragraph>
            <RecipientsSelector
              placeholder='Add friends...'
              onChange={(friends: string[]) => setFriends(friends)}
              onError={(email: string) => {
                if (email) {
                  setError('Invalid email address.');
                } else {
                  setError('');
                }
              }}
              styles={{
                placeholder: (provided: object) => ({
                  ...provided,
                  // workaround zone
                  pointerEvents: 'none',
                  userSelect: 'none',
                  MozUserSelect: 'none',
                  WebkitUserSelect: 'none',
                  msUserSelect: 'none',
                }),
                input: (css: object) => ({
                  ...css,
                  // expand the Input Component div
                  flex: '1 1 auto',
                  //outline: "1px dotted red",
                  // expand the Input Component child div
                  '> div': {
                    width: '100%',
                  },
                  // expand the Input Component input
                  input: {
                    width: '100% !important',
                    textAlign: 'left',
                  },
                }),
              }}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </>
        )}
      </Content>
      <Footer>
        {!isLoading && (
          <Button
            text='Invite'
            disabled={!friends.length}
            onClick={() => mutateAsync(friends)}
          />
        )}
      </Footer>
    </NewModal>
  );
};
