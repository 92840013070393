import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as ThreeDotIcons } from '../../chats/images/icon.svg';

export const MainWrapper = styled.div<{
  isLoading?: boolean;
}>`
  position: relative;
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
export const ThreeDotsWrapper = styled.div<{
  border?: string;
  borderRadius?: string;
}>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.blue05};
  border-radius: 6px;
  border: ${props => (props.border ? props.border : 'none')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : 'none')};
`;
export const ThreeDots = styled(ThreeDotIcons)`
  color: ${theme.palette.primaryDarkBlue};
`;

export const ItemsWrapper = styled.div`
  max-width: 200px;
  padding: 8px 0;
  position: absolute;
  width: 200px;
  background-color: white;
  right: 0;
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 6px 16px rgba(29, 30, 36, 0.06);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

export const Item = styled.div<{ disabled: boolean; id: string }>`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  .delete {
    color: rgba(232, 76, 61, 1);
  }

  :hover {
    background: ${theme.palette.gray};
    .delete {
      color: #e84c3d;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        :hover {
          background: transparent;
        }
      `}
  }
  ${({ id }) =>
    id === 'delete' &&
    css`
  :hover {
    background: #fef6f5;
    .delete {
      color: #e84c3d;
    }
  `}
`;

export const ItemIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemParagraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  margin: 0px;
`;
