import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { useToastError } from 'lib/hooks/useToastError';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface ISendTranscriptPayload {
  emails: string[];
  message?: string;
}

export interface ISendTranscriptConversationParams {
  conversationId: number;
  payload: ISendTranscriptPayload;
}
const sendTranscript = async ({
  conversationId,
  payload,
}: ISendTranscriptConversationParams) => {
  const body = {
    ...payload,
  };

  return (
    await EXPRESS_API.post(`conversations/${conversationId}/transcript`, body)
  ).data;
};

export const useSendTranscriptConversationMutation = (cb: () => void) => {
  const { showError } = useToastError();

  return useMutation(sendTranscript, {
    onError: (err: AxiosError) => showError(err),
    onSuccess: () => {
      successToast({ title: 'Your transcript is sent successfully.' });
      cb();
    },
  });
};
