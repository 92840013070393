import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components/macro';
import { OptionType, dropdownAccessData } from './data';
import { dropdownStylesEdit } from './style';
import { SimplifiedUserObject } from './ResellerEditModal';

const UserEditItem = styled.div<{ removed?: boolean }>`
  display: flex;
  align-items: center;
  padding: 4px 7px 4px 16px;
  background: ${theme.palette.white};
  margin-bottom: 8px;
  border-radius: 8px;
  position: relative;
  opacity: ${props => (props.removed ? 0.5 : 1)};
  transition: opacity 550ms ease;
`;

const UserTitle = styled.div<{ width?: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${theme.palette.neutral100};
  margin-left: 8px;
  width: ${props => props.width || '272px'};
`;

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const UserEditActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

type EditUserProps = {
  user: SimplifiedUserObject;
  onChange: (arg1: OptionType, arg2: number) => void;
  handleRemoveOrUndoUser: (userId: number, status: boolean) => void;
};

const EditUserItem = ({
  user,
  onChange,
  handleRemoveOrUndoUser,
}: EditUserProps) => {
  return (
    <UserEditItem removed={user.removed}>
      <UserDetails>
        <UserTitle width='140px'>
          {`${user.firstName} ${user.lastName}`}
        </UserTitle>
        <UserEditActionsWrapper>
          <Select
            closeMenuOnScroll={(event: any) => {
              // Dropdown remains fixed in place and does not scroll with parent container so this function is added to close menu on scroll so it's a great workaround since there is no valid solution available
              return event.target?.id === 'scrollContainerReseller';
            }}
            menuShouldBlockScroll={true}
            isSearchable={false}
            menuPortalTarget={document.body}
            placeholder='Select Option'
            value={dropdownAccessData.find(item => item.value === user.access)}
            options={dropdownAccessData}
            onChange={value => onChange(value as OptionType, user.userId)}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={dropdownStylesEdit}
            isDisabled={user.removed}
          />
          {!user.removed && (
            <Button
              text='Remove'
              variant='destructive'
              size='small'
              onClick={() => handleRemoveOrUndoUser(user.userId, true)}
            />
          )}
          {user.removed && (
            <Button
              text='Undo'
              size='small'
              variant='secondary'
              onClick={() => handleRemoveOrUndoUser(user.userId, false)}
            />
          )}
        </UserEditActionsWrapper>
      </UserDetails>
    </UserEditItem>
  );
};

export default EditUserItem;
