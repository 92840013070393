import * as React from 'react';
import styled, { css } from 'styled-components/macro';

interface Props {
  thumbnailSource?: string | undefined;
  isDefault?: boolean;
  backgroundColor?: string;
  fontFamily?: string | undefined;
  styleName?: string | undefined;
  fontWeight?: string | undefined;
  fontStyle?: string | undefined;
  color?: string | undefined;
  isSelected?: boolean | undefined;
  onClick?: (params?: any) => void;
}

const StyleWrapper = styled.div`
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
`;

const StyleDiv = styled.div<any>`
  display: flex;
  min-width: 45px;
  justify-content: center;
  height: 100%;
  border: 7px solid
    ${props =>
      ({ theme }: any) =>
        props.isSelected ? theme.colors.primary[100] : 'transparent'};
  border-radius: 14px;
`;

const Style = styled.div<Props>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  height: 10rem;
  width: calc(19rem - 2px);
  max-width: 100%;
  font-family: ${props => props.fontFamily};
  font-size: 36px;
  align-items: center;
  justify-content: center;
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  font-style: ${props => props.fontStyle};
  border-radius: 7px;
  border: 1px solid transparent;
  cursor: pointer;
  ${({ styleName }) =>
    styleName === 'Blank' &&
    css`
      border: 1px solid black;
    `}}
`;

// Component
export const PageStyleListItem = (props: Props) => {
  const {
    backgroundColor,
    fontFamily,
    styleName,
    fontWeight,
    fontStyle,
    color,
    onClick,
    isSelected,
  } = props;

  return (
    <StyleWrapper>
      <StyleDiv onClick={onClick} isSelected={isSelected}>
        <Style
          fontFamily={fontFamily}
          backgroundColor={backgroundColor}
          fontWeight={fontWeight}
          fontStyle={fontStyle}
          color={color}
          styleName={styleName}
        >
          {styleName}
        </Style>
      </StyleDiv>
    </StyleWrapper>
  );
};
