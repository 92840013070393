import React from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  MdDeleteForever,
  MdEdit,
  MdKeyboardArrowRight,
  MdPlayArrow,
  MdSave,
} from 'react-icons/md';
import { LoadingIndicator, ModalDelete } from 'lib/components';
import { successToast } from 'lib/components/toasts/success';
import dayjs from 'dayjs';
import { NotFound } from 'app/pages/notFound/NotFound';
import { errorToast } from 'lib/components/toasts/error';
import { useShareWithCompany } from 'lib/api/teleprompter/shareScript';
import { useAuth } from 'lib/context';
import { CovideoMagicButton, PROMPT_TYPE } from 'react-covideo-ai-assist';
import { NotSurePopup } from 'lib/components/magic/popups/NotSurePopup';
import { SaveTeleprompterScriptModal } from 'lib/components/modal/SaveTeleprompterScriptModal';
import { getCurrentToken } from 'configs/api/token';
import {
  decodeAndReplaceText,
  getDefaultPromptUseCase,
} from 'lib/utils/functions';
import { Button } from 'react-covideo-common';
import { useQueryScript } from 'lib/api/teleprompter/useQueryScript';
import { useAddScript } from 'lib/api/teleprompter/useAddScript';
import { useEditScript } from 'lib/api/teleprompter/useEditScript';
import { useRemoveScript } from 'lib/api/teleprompter/useRemoveScript';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';

const Container = styled.div`
  margin-top: 60px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  box-sizing: border-box;
  padding: 24px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #001b53cc;
  > svg {
    margin-left: 8px;
    cursor: pointer;
  }
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const LinkText = styled.div`
  font-weight: 400;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

const TitleInput = styled.input`
  width: 200px;

  font-weight: 400;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  height: 40px;
  padding: 0 12px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  &:focus {
    outline: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Content = styled.div`
  background: #f6f7f9;
  height: calc(100vh - 250px);
  display: flex;
  justify-content: center;
  padding: 32px 320px;
`;

const ScriptInput = styled.textarea`
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(39, 42, 50, 0.1);
  width: 800px;
  resize: none;
  outline: none;
  border-style: none;
  border-color: transparent;
  overflow: auto;
  padding: 40px;
  &:focus {
    outline: none;
  }
`;

const SharedText = styled.div`
  font-weight: 600;
  font-size: 15px;
  margin-right: 20px;
`;

interface MatchParams {
  scriptId: string;
}

export const Details = withRouter((props: RouteComponentProps<MatchParams>) => {
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  const [isEditing, setIsEditing] = React.useState(false);
  const titleInputRef = React.useRef(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showSaveTeleprompterScriptModal, setShowSaveTeleprompterScriptModal] =
    React.useState(false);

  const [scriptId, setScriptId] = React.useState(props.match.params.scriptId);
  const [isSaveDisabled, setIsSaveDisabled] = React.useState(
    !title.length || !content.length
  );

  const { data: script, refetch: loadScript } = useQueryScript(
    scriptId,
    !(scriptId === 'add')
  );

  const { mutateAsync: addScript } = useAddScript();

  const { mutateAsync: editScript } = useEditScript();

  const { mutateAsync: removeScript } = useRemoveScript();

  const isNewScript = scriptId === 'add';

  const [isShareDisabled, setIsShareDisabled] = React.useState(
    isNewScript || !title.length || !content.length
  );

  const [isShared, setIsShared] = React.useState(!!script?.sharedWithCompany);

  const { mutateAsync: shareWithCompany } = useShareWithCompany('');

  const history = useHistory();
  const { userData } = useAuth();

  React.useEffect(() => {
    if (isNewScript) {
      setTitle(`New Script ${dayjs(new Date()).format('YYYY-MM-DD')}`);
      setContent('');
    } else {
      loadScript();
    }
  }, [scriptId]);

  React.useEffect(() => {
    if (script) {
      setTitle(script.title || '');
      setContent(decodeAndReplaceText(script.content || ''));
      setIsShared(script.sharedWithCompany);
    }
  }, [script]);

  React.useEffect(() => {
    setIsSaveDisabled(
      !title.length || !content.length || content === script?.content
    );
    setIsShareDisabled(!title.length || !content.length);
  }, [title, content, script]);

  const goToScripts = () => history.push(`/profile/teleprompter`);

  //TODO: Preview is based on constant values as of now
  // Open the preview of unsaved Script in a new tab
  const goToPreview = async () => {
    localStorage.setItem('editor_preview_title', title);
    localStorage.setItem('editor_preview_content', content);
    localStorage.setItem(
      'editor_preview_state',
      JSON.stringify({
        scriptSpeed: {
          value: 20,
          label: '240 words / min',
        },
        position: 'top',
        size: 'large',
      })
    );

    window.open('/profile/teleprompter/editor/preview', '_blank');
  };

  const showLoading = !isNewScript && !script;

  const updateScript = async (newTitle?: string) => {
    const mainTitle = newTitle || title;
    if (!mainTitle?.trim()) {
      errorToast({ title: `Script title can't be empty` });
      return;
    }

    if (!content?.trim()) {
      errorToast({ title: `Script content can't be empty` });
      return;
    }
    const encodedContent = encodeURIComponent(content);
    let updatedScript = script;
    const notificationTitle = isNewScript
      ? 'Successfully saved script!'
      : 'Successfully updated script!';
    if (isNewScript) {
      updatedScript = await addScript({
        data: {
          title: mainTitle,
          content: encodedContent,
        },
      });

      successToast({ title: notificationTitle });
      return updatedScript;
    }
    updatedScript = await editScript({
      data: {
        title: mainTitle,
        content: encodedContent,
      },
      scriptId,
    });

    successToast({ title: notificationTitle });
    return updatedScript;
  };

  const handleSubmit = async (title: string) => {
    const updatedScript = await updateScript(title);
    if (updatedScript?.scriptId) {
      setScriptId(updatedScript.scriptId.toString());
      setIsSaveDisabled(true);
      setIsShareDisabled(false);
    }
    setShowSaveTeleprompterScriptModal(false);
  };

  const defaultPromptUseCase = getDefaultPromptUseCase(userData.isAutomotive);

  if (showLoading) {
    return (
      <Container>
        <LoadingIndicator isLoading={showLoading} height='300px' />
      </Container>
    );
  }

  if (!isNewScript && !script) {
    return <NotFound />;
  }

  return (
    <Container>
      <>
        <RouteLeavingGuard
          when={true}
          stay={true}
          onStay={() => setShowSaveTeleprompterScriptModal(true)}
          navigate={path => {
            history.push(path);
          }}
          onConfirm={() => {}}
          onDiscard={() => {}}
          shouldBlockNavigation={() => {
            return isNewScript ? !isSaveDisabled : script?.content !== content;
          }}
        />
        <Header>
          <LinkWrapper>
            <LinkBtn onClick={() => goToScripts()}>
              Teleprompter Scripts
            </LinkBtn>
            <MdKeyboardArrowRight size={24} />
            {!isEditing && (
              <>
                <LinkText>{title}</LinkText>
                <MdEdit
                  size='18px'
                  onClick={() => {
                    setIsEditing(true);
                    setTimeout(() => {
                      // @ts-ignore
                      titleInputRef.current.focus();
                    }, 100);
                  }}
                />
              </>
            )}
            {isEditing && (
              <TitleInput
                ref={titleInputRef}
                value={title}
                onChange={event => {
                  setTitle(event.target.value);
                }}
                onBlur={() => {
                  setIsEditing(false);
                  // Cannot save a new script before clicking on save button
                  if (!isNewScript) {
                    updateScript();
                  }
                }}
              />
            )}
          </LinkWrapper>
          <Actions>
            <CovideoMagicButton
              userInput={content}
              defaultPromptType={PROMPT_TYPE.TELEPROPTER_SCRIPT}
              defaultPromptUseCase={defaultPromptUseCase}
              handleSubmitGeneratedContent={(generatedContent: string) => {
                setContent(generatedContent);
              }}
              margin='0 12px 0 0'
              hoverPopup={<NotSurePopup />}
              hoverPosition='below'
              text='AI Assist'
              variant='gradient'
              token={getCurrentToken().token as string}
              userData={{
                customer: {
                  business: userData.customer.business,
                  hasCovideoMagic:
                    userData?.customer?.hasCovideoMagic?.toString() === '1',
                  markVideosAsSent: userData.customer.markVideosAsSent,
                },
                firstName: userData.firstName,
                isAutomotiveSalesRole: userData.isAutomotiveSalesRole,
                isAutomotiveServiceRole: userData.isAutomotiveServiceRole,
                isIMSEnabled: userData.isIMSEnabled,
                isAutomotive: userData.isAutomotive,
                isCompanyAdmin: userData.isCompanyAdmin,
              }}
              aiAssistTheme={'branding'}
              apiKey={process.env.REACT_APP_APIKEY}
            />
            {isShared ? (
              <SharedText>Shared with Company</SharedText>
            ) : (
              <Button
                variant='secondary'
                text='Share with Company'
                disabled={isShareDisabled}
                onClick={async () => {
                  let id = Number(scriptId);
                  if (isNewScript) {
                    const updatedScript = await updateScript();
                    id = Number(updatedScript?.scriptId);
                  }
                  await shareWithCompany(id);
                  setIsShared(true);
                }}
              />
            )}
            <Button
              variant='secondary'
              icon={<MdPlayArrow size={20} />}
              text='Preview'
              onClick={() => goToPreview()}
              disabled={!content.length}
            />
            {!isNewScript && (
              <Button
                text='Delete'
                variant='destructive'
                onClick={() => setShowDeleteModal(true)}
                disabled={script?.sharedWithCompany && !userData.isCompanyAdmin}
                icon={<MdDeleteForever size='20px' />}
              />
            )}
            <Button
              text='Save'
              disabled={isSaveDisabled}
              icon={<MdSave size={20} />}
              onClick={() => {
                setShowSaveTeleprompterScriptModal(true);
              }}
            />
          </Actions>
        </Header>
        <Content>
          <ScriptInput
            value={content}
            onChange={event => {
              setContent(event.target.value);
            }}
          />
        </Content>
      </>
      {showDeleteModal && (
        <ModalDelete
          whiteButtonText='Yes, Delete'
          orangeButtonText='No, Cancel'
          title={`You want to move script to recently deleted bin?`}
          text={`Scripts are automatically deleted after 30 days.`}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickWhiteButton={async () => {
            try {
              setShowDeleteModal(false);
              await removeScript({ scriptId });
              goToScripts();
              successToast({
                title:
                  'You have successfully move script to recently deleted bin',
              });
            } catch (e) {
              console.log(e);
            }
          }}
          onClickOrangeButton={() => setShowDeleteModal(false)}
        />
      )}
      {showSaveTeleprompterScriptModal && (
        <SaveTeleprompterScriptModal
          closeModalHandler={() => setShowSaveTeleprompterScriptModal(false)}
          titleInitialValue={title}
          handleSubmit={handleSubmit}
        />
      )}
    </Container>
  );
});
