import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import Switch from 'app/pages/design/landingPageBuilder/components/Switch';
import { QuoteManager } from '.';
import { Button } from 'react-covideo-common';
import { QuoteItem } from 'lib/api/repairOrders/types';
import { MdOutlineUnfoldLess, MdOutlineUnfoldMore } from 'react-icons/md';

const Card = styled.div`
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  margin-bottom: 12px;
  padding: 24px;
  min-height: 50px;
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const Expander = styled.div`
  cursor: pointer;
`;
interface SwitchInputWrapperProps {
  disabled?: boolean;
}
const SwitchInputWrapper = styled.div<SwitchInputWrapperProps>`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  > div {
    position: relative;
  }
  input {
    position: absolute;
  }
  ${props =>
    props.disabled &&
    `
    opacity:.4;
    pointer-events:none;
  `}
`;
const CardTitle = styled.div`
  margin-left: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: ${theme.palette.covideoBlue100};
`;
const Warning = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.red100};
  margin-left: 12px;
  margin-right: auto;
`;
const CardRow = styled.div`
  display: flex;
`;
const CardLabel = styled.div`
  width: 64px;
  margin-right: 8px;
  color: ${theme.palette.gray80};
  font-weight: 600;
  font-size: 14px;
  flex-shrink: 0;
  line-height: 20px;
`;
const CardValue = styled.div`
  color: ${theme.palette.gray80};
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  switchId: string;
  switchIsOn: boolean;
  handleToggle: () => void;
  title: string;
  subject?: string;
  content?: string;
  requiredQuoteItems: QuoteItem[];
  optionalQuoteItems: QuoteItem[];
  setRequiredQuoteItems: (requiredQuoteItems: QuoteItem[]) => void;
  setOptionalQuoteItems: (optionalQuoteItems: QuoteItem[]) => void;
  handleDeleteQuote?: () => void;
};
export const QuoteCard = ({
  switchId,
  switchIsOn,
  handleToggle,
  title,
  subject,
  content,
  requiredQuoteItems = [],
  optionalQuoteItems = [],
  setRequiredQuoteItems,
  setOptionalQuoteItems,
  handleDeleteQuote,
}: Props) => {
  const [switchDisabled, setSwitchDisabled] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const themes = useTheme();

  useEffect(() => {
    const switchOffAndDisable =
      !requiredQuoteItems.length && !optionalQuoteItems.length;
    setSwitchDisabled(switchOffAndDisable);
  }, [requiredQuoteItems, optionalQuoteItems]);

  return (
    <Card>
      <CardHeader>
        <SwitchInputWrapper disabled={switchDisabled}>
          <div>
            <Switch
              id={switchId}
              isOn={switchIsOn}
              onColor={themes.colors.primary[100]}
              handleToggle={handleToggle}
            />
          </div>
          <CardTitle>{title}</CardTitle>
        </SwitchInputWrapper>
        {switchDisabled && <Warning>Add items to enable Quote</Warning>}
        <Expander onClick={() => setExpanded(!expanded)}>
          {expanded && (
            <MdOutlineUnfoldLess color={theme.palette.covideoBlue40} />
          )}
          {!expanded && (
            <MdOutlineUnfoldMore color={theme.palette.covideoBlue40} />
          )}
        </Expander>
      </CardHeader>
      {subject && expanded && (
        <CardRow>
          <CardLabel>Subject:</CardLabel>
          <CardValue>{subject}</CardValue>
        </CardRow>
      )}
      {content && expanded && (
        <CardRow>
          <CardLabel>Content:</CardLabel>
          <CardValue>{content}</CardValue>
        </CardRow>
      )}
      {expanded && (
        <QuoteManager
          requiredQuoteItems={requiredQuoteItems}
          optionalQuoteItems={optionalQuoteItems}
          setRequiredQuoteItems={setRequiredQuoteItems}
          setOptionalQuoteItems={setOptionalQuoteItems}
        />
      )}

      {handleDeleteQuote &&
        (!!requiredQuoteItems.length || !!optionalQuoteItems.length) && (
          <Button
            text='Delete Quote Data'
            variant='white'
            onClick={() => handleDeleteQuote()}
          />
        )}
    </Card>
  );
};
