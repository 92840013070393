import { VideoItemThumbnail } from 'app/pages/library/videos/components';
import { downloadVideo } from 'lib/api';
import { MeetingRecording } from 'lib/api/meetings/types';
import { ListDropdownMenu, ModalVideoListVideo } from 'lib/components';
import {
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { VideoPages } from 'lib/const';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import { theme } from 'lib/style';
import { serverTimeToUserTime } from 'lib/utils/time';
import React, { useEffect, useState } from 'react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { HiPencil } from 'react-icons/hi';
import { MdBarChart, MdDownload } from 'react-icons/md';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { RecordingQuickshare } from './meetingRecordingsListActions/RecordingQuickshare';

interface Props {
  meetingRecording: MeetingRecording;
}

const ListItem = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  padding: 16px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid ${theme.palette.blue05};
  gap: 24px;
`;

const ThumbnailWrapper = styled.div`
  width: 130px;
  flex-shrink: 0;
`;

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ViewCount = styled(ParagraphSmall)`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const ButtonsWrapper = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DropdownElement = styled.div`
  position: relative;
  .recording-show-more-dropdown {
    right: 0;
  }
`;

const IconWrapper = styled.span`
  margin-left: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid transparent;
  border-radius: 5px;
  &:hover {
    background: ${({ theme }) => theme.colors.secondary['non_alpha']};
    border: 1px solid ${theme.palette.blue05};
  }
`;

export const MeetingRecordingListItem = ({ meetingRecording }: Props) => {
  const history = useHistory();
  const { userData } = useAuth();
  const { showError } = useToastError();
  const [showModalPreviewVideo, setShowModalPreviewVideo] = useState(false);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setShowMoreDropdown(false);
  }, [isDownloading]);

  const handleVideoThumbnailClick = () => setShowModalPreviewVideo(true);

  const handleViewDetailsClick = () =>
    history.push(`/classic/edit-video/${video.id}/${VideoPages.DETAILS}`);

  const handleAnalyticsClick = () =>
    history.push(`/classic/edit-video/${video.id}/${VideoPages.ANALYTICS}`);

  const handleMoreClick = () => {
    setShowMoreDropdown(prev => !prev);
  };

  const handleDownloadClick = async () => {
    if (isDownloading) {
      successToast({
        title: 'Downloading is already in progress...',
      });
      return;
    }
    if (!meetingRecording?.video?.id || !meetingRecording?.video?.title) {
      return;
    }
    successToast({
      title: 'Download Started...',
    });
    setIsDownloading(true);
    try {
      await downloadVideo(
        meetingRecording.video.id.toString(),
        meetingRecording.video.title
      );
    } catch (error) {
      showError(error);
    }
    setIsDownloading(false);
  };

  const { video } = meetingRecording;

  if (!video) {
    return null;
  }

  const clientTime = serverTimeToUserTime(
    video.recordDate,
    userData,
    `${STANDARD_DATE_FORMAT} hh:mm:ss A`,
    false
  );

  const dropdownItems = [
    {
      title: 'More Options',
      icon: <HiPencil color={theme.palette.blue40} size={20} />,
      onClick: handleViewDetailsClick,
    },
    {
      title: 'Download',
      icon: <MdDownload color={theme.palette.blue40} size={20} />,
      onClick: handleDownloadClick,
    },
  ];

  return (
    <>
      <ListItem>
        <ThumbnailWrapper title='Preview'>
          <VideoItemThumbnail
            handleClick={() => handleVideoThumbnailClick()}
            videoId={video.id.toString()}
            thumbnail={video.thumbnail}
            duration={video.videoLength || 0}
            processing={video.processing}
          />
        </ThumbnailWrapper>
        <InfoWrapper>
          <ParagraphNormalBold
            onClick={handleViewDetailsClick}
            style={{ cursor: 'pointer' }}
            title='View details'
          >
            {video.title}
          </ParagraphNormalBold>
          <ParagraphSmall>{clientTime}</ParagraphSmall>
          <ViewCount onClick={handleAnalyticsClick} title='View analytics'>
            <MdBarChart color={theme.palette.gray60} size={18} />
            {`${video.views} view${video.views === 1 ? '' : 's'}`}
          </ViewCount>
        </InfoWrapper>
        <ButtonsWrapper>
          <RecordingQuickshare video={video} />
          <DropdownElement onClick={handleMoreClick}>
            <IconWrapper>
              <BiDotsVerticalRounded size={20} color={theme.palette.blue100} />
            </IconWrapper>
            <ListDropdownMenu
              className={'recording-show-more-dropdown'}
              isMenuOpen={showMoreDropdown}
              items={dropdownItems}
              id={'recording-show-more-dropdown'}
              setIsMenuOpen={() => setShowMoreDropdown(false)}
            />
          </DropdownElement>
        </ButtonsWrapper>
      </ListItem>
      {showModalPreviewVideo && (
        <ModalVideoListVideo
          videoId={meetingRecording.video.id.toString()}
          handleModalClose={() => setShowModalPreviewVideo(false)}
        />
      )}
    </>
  );
};
