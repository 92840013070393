import { CUSTOMER_IDS } from './Customers';
import { RESELLER_IDS } from './Resellers';

export const EMAIL_2_ACCOUNTS = {
  RESELLERS: {
    [RESELLER_IDS.PENSKE_AUTOMOTIVE]: true,
  },
  CUSTOMERS: {
    [CUSTOMER_IDS.COVIDEO_DEVS]: true,
  },
};

export const PERSONAL_INFO_CTAS_ACCOUNTS = {
  RESELLERS: {
    [RESELLER_IDS.PENSKE_AUTOMOTIVE]: true,
  },
  CUSTOMERS: {
    [CUSTOMER_IDS.COVIDEO_DEVS]: true,
  },
};

export const USERS_IDS_WITH_NO_ALL_TEAMS: { [key: number]: string } = {
  100054748: 'Ryan Scheffer',
  100054746: 'Ryan Stembridge',
  100054870: 'Danielle Martin ',
  100054871: 'Kala Cross',
  100054873: 'Veronica Johnson',
  100054867: 'Allie Stratchan',
  100054868: 'Kim Elder',
  100054874: 'Presont Gilbert',
  100052787: 'Casee Hamilton',
  100052786: 'Eryn Haugen',
};
