import ThreeCircle from './svg/three-circle-report.svg';
import PieDesc from './svg/pie-desc-report.svg';
import TopFiveUsers from './svg/top-five-users.svg';
import VerticalReports from './svg/vertical-reports.svg';
import LineReport from './svg/line-report.svg';
import TopTable from './svg/top-table.svg';

interface ImageProps {
  id: string;
  svg: any;
}

interface ImagesInterface {
  'three-circles': ImageProps;
  'pie-desc': ImageProps;
  'top-users': ImageProps;
  'vertical-reports': ImageProps;
  'line-report': ImageProps;
  'top-table': ImageProps;
}

export const images: ImagesInterface = {
  'three-circles': { id: '1', svg: ThreeCircle },
  'line-report': { id: '2', svg: LineReport },
  'top-table': { id: '3', svg: TopTable },
  'pie-desc': { id: '4', svg: PieDesc },
  'vertical-reports': { id: '5', svg: VerticalReports },
  'top-users': { id: '6', svg: TopFiveUsers },
};

export type ImagesTypes = keyof ImagesInterface;
