const Pricing: any = {
  droplr: { monthly: 10, annual: 84 },
  lpb: { monthly: 25, annual: 210 },
  captions: { monthly: 20, annual: 240 },
  smsTierOne: { monthly: 25, annual: 300 },
  smsTierTwo: { monthly: 50, annual: 600 },
  smsTierThree: { monthly: 75, annual: 900 },
  cvlp: { monthly: 250, annual: 250 },
  wheels: { monthly: 100, annual: 1200 },
};

export const SubscriptionMapping: any = {
  annual: [
    {
      id: 'droplr',
      title: 'File Hosting & Sharing',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.droplr.annual,
      priceDescription: '/user/year',
      type: 'users',
      extraLabel: '',
    },
    {
      id: 'lpb',
      title: 'Video Landing Page Builder',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.lpb.annual,
      priceDescription: '/year',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'captions',
      title: 'Captions & Translations',
      summary: 'Instantly generate text that syncs to your video’s audio',
      symbol: '$',
      price: Pricing.captions.annual,
      priceDescription: '/user/year',
      type: 'users',
      extraLabel: 'POPULAR',
    },
    {
      id: 'sms',
      title: 'SMS Messaging (2,500 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: 25,
      priceDescription: '/2500 sms/year',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'smsTierOne',
      title: 'SMS Messaging (2,500 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.smsTierOne.annual,
      priceDescription: '/2500 sms/year',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'smsTierTwo',
      title: 'SMS Messaging (5,000 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.smsTierTwo.annual,
      priceDescription: '/5000 sms/year',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'smsTierThree',
      title: 'SMS Messaging (7,500 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.smsTierThree.annual,
      priceDescription: '/7500 sms/year',
      type: 'team',
      extraLabel: '',
    },
  ],
  monthly: [
    {
      id: 'droplr',
      title: 'File Hosting & Sharing',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.droplr.monthly,
      priceDescription: '/user/month',
      type: 'users',
      extraLabel: '',
    },
    {
      id: 'lpb',
      title: 'Video Landing Page Builder',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.lpb.monthly,
      priceDescription: '/month',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'captions',
      title: 'Captions & Translations',
      summary: 'Instantly generate text that syncs to your video’s audio',
      symbol: '$',
      price: Pricing.captions.monthly,
      priceDescription: '/user/month',
      type: 'users',
      extraLabel: 'POPULAR',
    },
    {
      id: 'sms',
      title: 'SMS Messaging (2,500 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: 30,
      priceDescription: '/2500 sms/month',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'smsTierOne',
      title: 'SMS Messaging (2,500 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.smsTierOne.monthly,
      priceDescription: '/2500 sms/year',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'smsTierTwo',
      title: 'SMS Messaging (5,000 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.smsTierTwo.monthly,
      priceDescription: '/5000 sms/year',
      type: 'team',
      extraLabel: '',
    },
    {
      id: 'smsTierThree',
      title: 'SMS Messaging (7,500 SMS)',
      summary: 'Stand out with a beautifully designed video template',
      symbol: '$',
      price: Pricing.smsTierThree.monthly,
      priceDescription: '/7500 sms/year',
      type: 'team',
      extraLabel: '',
    },
  ],
};

export const SubscriptionPrice: any = {
  monthly: 69,
  annual: 49,
};

export const SubscriptionIndex: any = {
  droplr: 0,
  lpb: 1,
  captions: 2,
  sms: 3,
  smsTierOne: 4,
  smsTierTwo: 5,
  smsTierThree: 6,
};

export const SubscriptionMappingForUpgrade: any = {
  annual: [
    {
      id: 'captions',
      title: 'Captions and Translations',
      summary:
        'Auto generate captions for your videos with the click of a button.',
      symbol: '$',
      price: Pricing.captions.annual,
      priceDescription: '/user/year',
      type: 'users',
      extraLabel: 'POPULAR',
      onetimePayment: false,
    },
    {
      id: 'smsTierOne',
      title: 'Add SMS',
      summary: 'Message leads and customers from a unique, local number',
      symbol: '$',
      price: Pricing.smsTierOne.annual,
      // displayPrice: Pricing.smsTierOne.monthly,
      priceDescription: ' for 2,500 messages/year',
      type: 'team',
      extraLabel: '',
      onetimePayment: false,
    },
    {
      id: 'lpb',
      title: 'Video Landing Page Builder',
      summary: 'Create, save and reuse endless video landing pages.',
      symbol: '$',
      price: Pricing.lpb.annual,
      priceDescription: '/year',
      type: 'team',
      extraLabel: '',
      onetimePayment: false,
    },
    {
      id: 'droplr',
      title: 'File Hosting & Sharing',
      summary:
        'Use Covideo to snap, store and share all of your digital assets.',
      symbol: '$',
      price: Pricing.droplr.annual,
      priceDescription: '/user/year',
      type: 'users',
      extraLabel: '',
      onetimePayment: false,
    },
    {
      id: 'cvlp',
      title: 'Custom Video Landing Page',
      summary: 'Maintain brand consistency',
      symbol: '$',
      price: Pricing.cvlp.annual,
      priceDescription: '/one-time per design',
      type: 'team',
      extraLabel: '',
      onetimePayment: true,
    },
    {
      id: 'wheels',
      title: 'Wheels TV',
      summary:
        'Combine your videos with award winning vehicle walkaround videos',
      symbol: '$',
      price: Pricing.wheels.annual,
      displayPrice: Pricing.wheels.monthly,
      priceDescription: '/dealership/month',
      type: 'team',
      extraLabel: '',
      onetimePayment: false,
    },
  ],
  monthly: [
    {
      id: 'captions',
      title: 'Captions and Translations',
      summary:
        'Auto generate captions for your videos with the click of a button.',
      symbol: '$',
      price: Pricing.captions.monthly,
      priceDescription: ' user/month',
      type: 'users',
      extraLabel: 'POPULAR',
      onetimePayment: false,
    },
    {
      id: 'smsTierOne',
      title: 'Add SMS',
      summary: 'Message leads and customers from a unique, local number',
      symbol: '$',
      price: Pricing.smsTierOne.monthly,
      priceDescription: ' for 2,500 messages/month',
      type: 'team',
      extraLabel: '',
      onetimePayment: false,
    },
    {
      id: 'lpb',
      title: 'Video Landing Page Builder',
      summary: 'Create, save and reuse endless video landing pages.',
      symbol: '$',
      price: Pricing.lpb.monthly,
      priceDescription: ' /month',
      type: 'team',
      extraLabel: '',
      onetimePayment: false,
    },
    {
      id: 'droplr',
      title: 'File Hosting & Sharing',
      summary:
        'Use Covideo to snap, store and share all of your digital assets.',
      symbol: '$',
      price: Pricing.droplr.monthly,
      priceDescription: ' user/month',
      type: 'users',
      extraLabel: '',
      onetimePayment: false,
    },
    {
      id: 'cvlp',
      title: 'Custom Video Landing Page',
      summary: 'Maintain brand consistency',
      symbol: '$',
      price: Pricing.cvlp.monthly,
      priceDescription: '/one-time per design',
      type: 'team',
      extraLabel: '',
      onetimePayment: true,
    },
    {
      id: 'wheels',
      title: 'Wheels TV',
      summary:
        'Combine your videos with award winning vehicle walkaround videos',
      symbol: '$',
      price: Pricing.wheels.monthly,
      priceDescription: ' /dealership / month',
      type: 'team',
      extraLabel: '',
      onetimePayment: false,
    },
  ],
};

export type subscriptionMappingItemType = {
  [key: string]: number | string | boolean;
};

export type subscriptionDataType = {
  amount: string;
  amountPerSeat: number;
  annual: boolean;
  addons: string[];
  selectedAddonDetails: subscriptionMappingItemType[];
  quantity: number;
  discountAmount: number;
  planAmount: number;
  addonAmount: number;
  isProPackage: boolean;
};
