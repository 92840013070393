import { addHours, addMonths } from 'lib/utils/datetime';
const initStartDate = new Date();

export const LINK_EXPIRATION_TYPES = {
  NEVER: 'NEVER',
  '1_DAY': '1_DAY',
  '1_WEEK': '1_WEEK',
  '1_MONTH': '1_MONTH',
  CUSTOM: 'CUSTOM',
};

export interface ExpDropdownItemsProps {
  id: string;
  value: Date | null;
  label: string;
}

export const dropdownItems: ExpDropdownItemsProps[] = [
  {
    id: LINK_EXPIRATION_TYPES.NEVER,
    value: null,
    label: 'Never',
  },
  {
    id: LINK_EXPIRATION_TYPES['1_DAY'],
    value: addHours(new Date(), 24),
    label: 'Expires in 24 hours',
  },
  {
    id: LINK_EXPIRATION_TYPES['1_WEEK'],
    value: addHours(new Date(), 168),
    label: 'Expires in 7 days',
  },
  {
    id: LINK_EXPIRATION_TYPES['1_MONTH'],
    value: addMonths(new Date(), 1),
    label: 'Expires in 30 days',
  },
  {
    id: LINK_EXPIRATION_TYPES.CUSTOM,
    value: initStartDate,
    label: 'Set custom expiration...',
  },
];
