import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import {
  FieldLabel,
  FormProps,
  getCheckboxField,
  getNumberInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const CaptionSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  const { colors } = useCovideoTheme();
  return (
    <Section>
      <SectionTitle>Captions</SectionTitle>
      <RowWrapper>
        <Row>
          {getCheckboxField(
            'transcriptions',
            'Enable Captions',
            !!values.transcriptions,
            () =>
              setFieldValue('transcriptions', values.transcriptions ? 0 : 1),
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>

        {!!values.transcriptions && (
          <Row>
            <FieldLabel>Number of Licenses</FieldLabel>
            {getNumberInputField(
              'transcriptionLicenses',
              true,
              false,
              isSubmitting
            )}
          </Row>
        )}
      </RowWrapper>
    </Section>
  );
};
