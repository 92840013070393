import React from 'react';
import {
  FieldContainer,
  FieldLabel,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
} from '../../../components/AdminFormComponents';
import { CustomersDropdown } from 'app/pages/admin/components/CustomersDropdown';
import { Field, FieldProps } from 'formik';
import { AccessRole } from 'lib/const';
import { VerificationStatus } from 'lib/const/VerificationStatus';

export const CustomerSection = (props: UserFormProps) => {
  const { setFieldValue, isNewUser, customerName } = props;
  return (
    <Section>
      <SectionTitle>Customer</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>{!isNewUser ? 'Customer' : 'Company Name'}</FieldLabel>
          <Field type='select' name='customerId'>
            {({ field }: FieldProps) => {
              const { value } = field;
              return (
                <FieldContainer>
                  <CustomersDropdown
                    showAllCustomersOption={false}
                    customerFilter={value}
                    customerName={customerName}
                    placeholder='Select a customer'
                    zIndexProp={1}
                    onSelectCustomer={customerId => {
                      setFieldValue('customerId', customerId);
                      setFieldValue(`access`, parseInt(AccessRole.USER, 10));
                    }}
                    status={VerificationStatus.ACTIVE}
                  />
                </FieldContainer>
              );
            }}
          </Field>
        </Row>
      </RowWrapper>
    </Section>
  );
};
