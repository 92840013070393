import React from 'react';
import { Dropdown, NewModal, RadioInput } from 'lib/components';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { Gap, Stack } from 'lib/components/styles/layout';
import {
  ParagraphNormal,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { useRecordSettings } from 'lib/context/record/provider';
import { VIDEO_QUALITY } from 'lib/context/record/types';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const LabelContainerWrapper = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  padding: 0 12px;
  height: 38px;
`;

const LabelContainer = styled.div`
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  ${theme.mediaQueryMinWidth.mb} {
    width: 250px;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  gap: 4px;
  color: ${theme.palette.black_1_100};
  cursor: pointer;
`;

const CustomValueContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <LabelContainerWrapper>
      <LabelContainer>{children}</LabelContainer>
    </LabelContainerWrapper>
  );
};

interface IProps {
  handleModalClose: () => void;
}

type Option = {
  label: string;
  value: string;
};

export const ModalRecordingVideosSettings = ({ handleModalClose }: IProps) => {
  const { colors } = useCovideoTheme();
  const {
    recorderSettings,
    setUserSettings,
    userSettings,
    onSubmitModalHandler,
    initialUserSettings,
  } = useRecordSettings();

  const onSubmit = () => {
    onSubmitModalHandler();
    handleModalClose();
  };

  const onCancelOrCloseModalSettings = () => {
    setUserSettings(initialUserSettings);
    handleModalClose();
  };

  const onChangeVideoSettingsHandler = (value: Option) =>
    setUserSettings({
      ...userSettings,
      videoSource: value,
    });

  const onChangeAudioSettingsHandler = (value: Option) =>
    setUserSettings({
      ...userSettings,
      audioSource: value,
    });

  const onVideoQualityHandler = (videoQuality: VIDEO_QUALITY) =>
    setUserSettings({
      ...userSettings,
      videoQuality,
    });

  return (
    <NewModal
      headerText={'Recording Settings'}
      closeModal={onCancelOrCloseModalSettings}
      style={{
        content: {
          maxWidth: '592px',
        },
      }}
    >
      <Gap>
        {!!recorderSettings.videoSource.length && (
          <Stack width='100%' alignItems='start' gap='4px'>
            <ParagraphSmall color={colors.neutral[60]}>
              Video source
            </ParagraphSmall>
            <Dropdown
              creatable={false}
              value={userSettings.videoSource}
              placeholder='No settings'
              options={recorderSettings.videoSource}
              onChange={onChangeVideoSettingsHandler}
              height={40}
              extendStyles={{ container: { width: '100%' } }}
              customComponents={{ ValueContainer: CustomValueContainer }}
              isSearchable={false}
            />
          </Stack>
        )}
        {!!recorderSettings.audioSource.length && (
          <Stack width='100%' alignItems='start' gap='4px'>
            <ParagraphSmall color={colors.neutral[60]}>
              Audio source
            </ParagraphSmall>
            <Dropdown
              creatable={false}
              placeholder='No settings'
              value={userSettings.audioSource}
              options={recorderSettings.audioSource}
              onChange={onChangeAudioSettingsHandler}
              extendStyles={{ container: { width: '100%' } }}
              height={40}
              customComponents={{ ValueContainer: CustomValueContainer }}
              isSearchable={false}
            />
          </Stack>
        )}
        <>
          <ParagraphSmall color={colors.neutral[60]}>
            Video quality
          </ParagraphSmall>
          <Stack alignItems='flex-start' width='100%' gap='8px' m='0 0 16px 0'>
            <Gap width='100%' justifyContent='space-between'>
              <RadioLabel>
                <RadioInput
                  checked={userSettings.videoQuality === VIDEO_QUALITY.LOW}
                  name='quality'
                  value={VIDEO_QUALITY.LOW}
                  onClick={() => onVideoQualityHandler(VIDEO_QUALITY.LOW)}
                  onChange={() => onVideoQualityHandler(VIDEO_QUALITY.LOW)}
                />
                <ParagraphNormal color={colors.neutral[100]}>
                  Low
                </ParagraphNormal>
              </RadioLabel>
              <ParagraphSmall>Faster Upload</ParagraphSmall>
            </Gap>
            <Gap width='100%' justifyContent='space-between'>
              <RadioLabel>
                <RadioInput
                  checked={userSettings.videoQuality === VIDEO_QUALITY.STANDARD}
                  name='quality'
                  value={VIDEO_QUALITY.STANDARD}
                  onClick={() => onVideoQualityHandler(VIDEO_QUALITY.STANDARD)}
                  onChange={() => onVideoQualityHandler(VIDEO_QUALITY.STANDARD)}
                />
                <ParagraphNormal color={colors.neutral[100]}>
                  Standard{' '}
                </ParagraphNormal>
              </RadioLabel>
              <ParagraphSmall>Fast Upload</ParagraphSmall>
            </Gap>
            <Gap width='100%' justifyContent='space-between'>
              <RadioLabel>
                <RadioInput
                  checked={userSettings.videoQuality === VIDEO_QUALITY.HIGH}
                  name='quality'
                  value={VIDEO_QUALITY.HIGH}
                  onClick={() => onVideoQualityHandler(VIDEO_QUALITY.HIGH)}
                  onChange={() => onVideoQualityHandler(VIDEO_QUALITY.HIGH)}
                />
                <ParagraphNormal color={colors.neutral[100]}>
                  High
                </ParagraphNormal>
              </RadioLabel>
              <ParagraphSmall>Slow Upload</ParagraphSmall>
            </Gap>
          </Stack>
        </>
      </Gap>
      <Gap width='100%' justifyContent='flex-end'>
        <Button
          variant='secondary'
          text='Cancel'
          onClick={onCancelOrCloseModalSettings}
        />
        <Button text='Save' onClick={onSubmit} />
      </Gap>
    </NewModal>
  );
};
