import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import {
  CommonTypography,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import SkeletonLoader from 'app/pages/reports/dashboard/skeletonLoaders/Loader';

const Stats = styled.div`
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  width: 228px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px ${theme.palette.boxShadow};
`;

const Count = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: ${theme.palette.blue100};
  margin-top: 8px;
`;

type Props = {
  count: number;
  text: string;
  isLoading: boolean;
};

export const UsageStatsCard = ({ count, text, isLoading }: Props) => {
  if (isLoading) {
    return <SkeletonLoader imageName='pie-desc' />;
  }

  return (
    <Stats>
      <ParagraphSmall color={theme.palette.gray100}>{text}</ParagraphSmall>
      <Count>{addThousandCommaSeparator(count)}</Count>
    </Stats>
  );
};
