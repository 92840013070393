import React, { useState } from 'react';
import onboardingStepTwoAnimationForMyself from 'lib/animations/OnBoarding_Step_4.json';
import { ItemSelector, FreemiumOnboardingPageTemplate } from '../components';
import { covideoUsageOptionsForMyself } from '../const';
import { useFreemiumOnboardingQuery } from 'lib/api/freemiumOnboarding/getFreemiumOnboarding';
import { LoadingIndicator } from 'lib/components';
import { SelectorItem } from '../types';
import laptopIcon from 'assets/images/freemiumOnboarding/laptop.svg';
import { Gap } from 'lib/components/styles/layout';

export const StepTwoForMyself = () => {
  const { data: freemiumOnboardingData, isLoading } =
    useFreemiumOnboardingQuery();
  const [selectedUsage, setSelectedUsage] = useState(
    freemiumOnboardingData?.usage || ''
  );

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  const covideoOptionsForMySelf = covideoUsageOptionsForMyself.reduce(
    (arr: SelectorItem[], ele: SelectorItem) => {
      if (ele.value === 'Dealership') {
        return [ele, ...arr];
      }
      return [...arr, ele];
    },
    []
  );

  return (
    <FreemiumOnboardingPageTemplate
      currentStep={2}
      stepText='I plan to use Covideo for...'
      nextStepRoute='/onboarding-freemium/step-three/myself'
      goToNextStepDisabled={selectedUsage === ''}
      rightColumnAnimationSrc={onboardingStepTwoAnimationForMyself}
      rightColumnChildren={
        <Gap justifyContent='center'>
          <img alt='laptop' src={laptopIcon} />
        </Gap>
      }
      animationLoop={true}
      dataToBeSaved={{
        usage: selectedUsage,
      }}
      leftColumnChildren={
        <ItemSelector
          itemHeight='152px'
          itemWidth='110px'
          items={covideoOptionsForMySelf}
          selectedItem={selectedUsage}
          setSelectedValue={setSelectedUsage}
        />
      }
    />
  );
};
