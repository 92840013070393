import { IReassignAdvisorData, RepairOrderItem } from './types';
import { useMutation, useQueryClient } from 'react-query';
import { repairOrderKeys } from './repairOrderKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const reassignAdvisor = async ({
  advisorId,
  repairOrderId,
}: IReassignAdvisorData) => {
  return (
    await EXPRESS_API.put(`repair-orders/${repairOrderId}/reassign`, {
      advisorId,
    })
  ).data;
};

export const useReassignAdvisor = () => {
  const queryClient = useQueryClient();
  return useMutation(reassignAdvisor, {
    onSuccess: (res: RepairOrderItem) => {
      // invalidate
      queryClient.invalidateQueries(repairOrderKeys.repairOrders());
      queryClient.invalidateQueries(
        repairOrderKeys.repairOrder(res.repairOrderId)
      );
    },
  });
};
