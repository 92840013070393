import * as Layouts from 'app/pages/design/landingPageBuilder/layouts';
import { ReactComponent as editorCTAIcon } from 'assets/images/landingPage/pageCta.svg';
import { ReactComponent as editorHeaderIcon } from 'assets/images/landingPage/header.svg';
import { ReactComponent as editorImageIcon } from 'assets/images/landingPage/editorImage.svg';
import { ReactComponent as editorSocialIcon } from 'assets/images/landingPage/socialLinks.svg';
import { ReactComponent as editorTextIcon } from 'assets/images/landingPage/editorText.svg';
import { ReactComponent as editorVideoIcon } from 'assets/images/landingPage/videoPlayer.svg';
import modalCTASection from 'assets/icons/landingPage/modalCTASection.svg';
import modalImageSection from 'assets/icons/landingPage/modalImageSection.svg';
import modalLinksSection from 'assets/icons/landingPage/modalLinksSection.svg';
import modalSocialLinksSection from 'assets/icons/landingPage/modalSocialLinksSection.svg';
import modalTextSection from 'assets/icons/landingPage/modalTextSection.svg';
import modalVideoSection from 'assets/icons/landingPage/modalVideoSection.svg';
import { theme } from 'lib/style';
import * as React from 'react';
import styled, { StyledComponent } from 'styled-components/macro';
import { ReactComponent as resetIcon } from 'assets/icons/reset.svg';

import bni from 'assets/images/socialIcons/bni.webp';
import cars from 'assets/images/socialIcons/carscom.webp';
import dealerReater from 'assets/images/socialIcons/dealerreater.webp';
import edmunds from 'assets/images/socialIcons/edmunds.webp';
import facebook from 'assets/images/socialIcons/facebook.webp';
import instagram from 'assets/images/socialIcons/instagram.webp';
import linkedIn from 'assets/images/socialIcons/linkedin.webp';
import pinterest from 'assets/images/socialIcons/pinterest.webp';
import tikTok from 'assets/images/socialIcons/tiktok.webp';
import twitter_new from 'assets/images/socialIcons/twitter.webp';
import whatsapp from 'assets/images/socialIcons/whatsapp.webp';
import yelp from 'assets/images/socialIcons/yelp.webp';
import youtube from 'assets/images/socialIcons/youtube.webp';
import zeemee from 'assets/images/socialIcons/zeemee.webp';
import { FontFamilyOption } from './types';

type keyed = {
  [key: number]: any;
};

type keyedString = {
  [key: string]: any;
};
interface Props {
  className?: string;
  src?: string;
  alt?: string;
  commonStyles?: any;
}
const EditorImageIcon = styled(editorImageIcon)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

const EditorCTAIcon = styled(editorCTAIcon)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

const EditorSocialIcon = styled(editorSocialIcon)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

const EditorTextIcon = styled(editorTextIcon)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

const EditorVideoIcon = styled(editorVideoIcon)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

const EditorHeaderIcon = styled(editorHeaderIcon)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

export const ResetIcon = styled(resetIcon)`
  width: ${props => props.width || '24px'};
  height: ${props => props.height || '24px'};
  path {
    fill: ${props => props.color || 'currentColor'};
    opacity: ${props => props.opacity || 'inherit'};
  }
`;

// 1- Header, 2- VideoPlayer 3- CTA, 4-SocialLinks, 5-Text 6- Image, 7-Video, 8- Links
export const sectionTypeAndIconMapping: {
  [key: string]: StyledComponent<
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >,
    any,
    {},
    never
  >;
} = {
  1: EditorHeaderIcon,
  2: EditorVideoIcon,
  3: EditorCTAIcon,
  4: EditorSocialIcon,
  5: EditorTextIcon,
  6: EditorImageIcon,
  7: EditorVideoIcon,
  8: EditorCTAIcon,
  9: EditorImageIcon,
};

export const defaultDisplayNamesForSections: keyed = {
  1: 'Header',
  2: 'Video player',
  3: 'CTAs',
  4: 'Social links',
  5: 'Text',
  6: 'Image',
  7: 'Embed Video',
  8: 'Links',
  9: 'Logo',
  /* 10: 'Columns', */
};

export const getNewSection = (sectionType: number) => {
  return {
    sectionType: sectionType,
    sectionDisplayName: defaultDisplayNamesForSections[sectionType] || '',
    childSections: [],
    sectionPosition: 'center',
    isVisible: true,
    videos: [],
  };
};

export const DefaultSectionDetails = [
  {
    sectionType: 5,
    sectionName: 'Text Section',
    sectionIcon: modalTextSection,
    sectionDetails: 'Additional descriptions or explanations',
  },
  {
    sectionType: 7,
    sectionName: 'Embed Video or Playlist',
    sectionIcon: modalVideoSection,
    sectionDetails: 'Video or video playlists from other platforms',
  },
  {
    sectionType: 6,
    sectionName: 'Photo Section',
    sectionIcon: modalImageSection,
    sectionDetails: 'Often used for personal photos or logos',
  },
  {
    sectionType: 8,
    sectionName: 'Links',
    sectionIcon: modalLinksSection,
    sectionDetails: 'Textual links in case CTA section isn’t enough',
  },
  {
    sectionType: 4,
    sectionName: 'Social Links',
    sectionIcon: modalSocialLinksSection,
    sectionDetails: 'Connect with people on your social profiles',
  },
  {
    sectionType: 3,
    sectionName: 'CTAs',
    sectionIcon: modalCTASection,
    sectionDetails: 'Show your CTA set or custom buttons',
  },
];

export const gridSizeOptions = [
  {
    value: 1,
    label: '1 photo',
  },
  {
    value: 2,
    label: '2 photos',
  },
  {
    value: 3,
    label: '3 photos',
  },
  {
    value: 4,
    label: '4 photos',
  },
  {
    value: 5,
    label: '5 photos',
  },
  {
    value: 6,
    label: '6 photos',
  },
  {
    value: 7,
    label: '7 photos',
  },
  {
    value: 8,
    label: '8 photos',
  },
  {
    value: 9,
    label: '9 photos',
  },
  {
    value: 10,
    label: '10 photos',
  },
];
export const playButtonPositionOptions = [
  {
    value: 'center',
    label: 'center',
  },
  {
    value: 'top-left',
    label: 'top left',
  },
  {
    value: 'bottom-left',
    label: 'bottom left',
  },
  {
    value: 'bottom-right',
    label: 'bottom right',
  },
  {
    value: 'top-right',
    label: 'top right',
  },
];

export const defaultFontFamilyOptions: FontFamilyOption[] = [
  {
    value: 'Montserrat',
    label: 'Montserrat',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/MontserratRegular.ttf',
      bold: 'assets/fonts/MontserratBold.ttf',
      bolder: 'assets/fonts/MontserratExtraBold.ttf',
      lighter: 'assets/fonts/MontserratLight.ttf',
    },
  },
  {
    value: 'Oswald',
    label: 'Oswald',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/OswaldRegular.ttf',
      bold: 'assets/fonts/OswaldSemiBold.ttf',
      bolder: 'assets/fonts/OswaldBold.ttf',
      lighter: 'assets/fonts/OswaldLight.ttf',
    },
  },
  {
    value: 'Oxygen',
    label: 'Oxygen',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/OxygenRegular.ttf',
      bold: 'assets/fonts/OxygenRegular.ttf',
      bolder: 'assets/fonts/OxygenBold.ttf',
      lighter: 'assets/fonts/OxygenLight.ttf',
    },
  },
  {
    value: 'Exo2',
    label: 'Exo2',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/Exo2Regular.ttf',
      bold: 'assets/fonts/Exo2Bold.ttf',
      bolder: 'assets/fonts/Exo2ExtraBold.ttf',
      lighter: 'assets/fonts/Exo2Light.ttf',
    },
  },
  {
    value: 'GreatVibes',
    label: 'Great Vibes',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/GreatVibesRegular.ttf',
      bold: 'assets/fonts/GreatVibesRegular.ttf',
      bolder: 'assets/fonts/GreatVibesRegular.ttf',
      lighter: 'assets/fonts/GreatVibesRegular.ttf',
    },
  },
  {
    value: 'PlayfairDisplay',
    label: 'Playfair Display',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/PlayfairDisplaySemiBold.ttf',
      bold: 'assets/fonts/PlayfairDisplayBold.ttf',
      bolder: 'assets/fonts/PlayfairDisplayExtraBold.ttf',
      lighter: 'assets/fonts/PlayfairDisplayRegular.ttf',
    },
  },
  {
    value: 'PlayfairDisplayItalic',
    label: 'Playfair Display Italic',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/PlayfairDisplaySemiBoldItalic.ttf',
      bold: 'assets/fonts/PlayfairDisplayBoldItalic.ttf',
      bolder: 'assets/fonts/PlayfairDisplayExtraBoldItalic.ttf',
      lighter: 'assets/fonts/PlayfairDisplayItalic.ttf',
    },
  },
  {
    value: 'BarlowCondensed',
    label: 'Barlow Condensed',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/BarlowCondensedRegular.ttf',
      bold: 'assets/fonts/BarlowCondensedBold.ttf',
      bolder: 'assets/fonts/BarlowCondensedExtraBold.ttf',
      lighter: 'assets/fonts/BarlowCondensedLight.ttf',
    },
  },
  {
    value: 'NunitoBlack',
    label: 'Nunito-Black',
    variants: ['normal', 'bold', 'bolder', 'lighter'],
    files: {
      normal: 'assets/fonts/NunitoRegular.ttf',
      bold: 'assets/fonts/NunitoBold.ttf',
      bolder: 'assets/fonts/NunitoBlack.ttf',
      lighter: 'assets/fonts/NunitoLight.ttf',
    },
  },
];

export const fontSizeOptions = [
  {
    value: '10px',
    label: '10',
  },
  {
    value: '11px',
    label: '11',
  },
  {
    value: '12px',
    label: '12',
  },
  {
    value: '14px',
    label: '14',
  },
  {
    value: '16px',
    label: '16',
  },
  {
    value: '18px',
    label: '18',
  },
  {
    value: '22px',
    label: '22',
  },
  {
    value: '24px',
    label: '24',
  },
  {
    value: '26px',
    label: '26',
  },
  {
    value: '28px',
    label: '28',
  },
  {
    value: '30px',
    label: '30',
  },
  {
    value: '32px',
    label: '32',
  },
  {
    value: '34px',
    label: '34',
  },
  {
    value: '36px',
    label: '36',
  },
];

export const fontSizeOptionsForLinks = [
  {
    value: '10px',
    label: '10',
  },
  {
    value: '12px',
    label: '12',
  },
  {
    value: '14px',
    label: '14',
  },
  {
    value: '16px',
    label: '16',
  },
  {
    value: '18px',
    label: '18',
  },
  {
    value: '20px',
    label: '20',
  },
];

type IconContainerProps = {
  bgColor?: string;
  margin?: string;
  onClick?: any;
};

const IconContainer = styled.div<IconContainerProps>`
  box-sizing: border-box;
  background-color: ${props => props.bgColor || theme.palette.gray};
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin: ${props => props.margin || 0};
  padding: 5px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: black;
`;

export const FacebookIcon = (props: Props) => {
  return (
    <IconContainer
      bgColor={'#1877f2'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const InstagramIcon = (props: Props) => {
  return (
    <IconContainer
      bgColor={'#e4405f'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const LinkedinIcon = (props: Props) => {
  return (
    <IconContainer
      bgColor={'#0077b5'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const YoutubeIcon = (props: Props) => {
  return (
    <IconContainer
      bgColor={'#ff0000'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const TwitterIcon = (props: Props) => {
  return (
    <IconContainer
      bgColor={'#fff'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const YelpIcon = (props: Props) => {
  return (
    <IconContainer
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const TiktokIcon = (props: Props) => {
  return (
    <IconContainer
      bgColor={'#000000'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const ZeeMeeIcon = (props: Props) => {
  return (
    <IconContainer
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const EdmundsIcon = function (props: Props) {
  return (
    <IconContainer
      bgColor={'#007ee5'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const BNIIcon = function (props: Props) {
  return (
    <IconContainer
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const CarsDotComIcon = function (props: Props) {
  return (
    <IconContainer
      bgColor={'#652399'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const DealerRaterIcon = function (props: Props) {
  return (
    <IconContainer
      bgColor={'#cfe5f2'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const WhatsappIcon = function (props: Props) {
  return (
    <IconContainer
      bgColor={'#08d94c'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const PinterestIcon = function (props: Props) {
  return (
    <IconContainer
      bgColor={'#E60023'}
      margin={'7.5px 10px 7.5px 10px;'}
      className={props.className || ''}
    >
      <img src={props.src} alt={props.alt} style={props.commonStyles} />
    </IconContainer>
  );
};

export const getSocialIconObject = (className: string) => {
  const commonStyles = {
    maxWidth: '40px',
    maxHeight: '40px',
    borderRadius: `${className === 'circularShape' ? '20px' : '4px'}`,
    cursor: 'pointer',
  };
  return {
    facebook: (
      <FacebookIcon
        className={className}
        src={facebook}
        alt={'Facebook icon'}
        commonStyles={commonStyles}
      />
    ),
    twitter: (
      <TwitterIcon
        className={className}
        src={twitter_new}
        alt={'Twitter icon'}
        commonStyles={commonStyles}
      />
    ),
    linkedIn: (
      <LinkedinIcon
        className={className}
        src={linkedIn}
        alt={'LinkedIn icon'}
        commonStyles={commonStyles}
      />
    ),
    youtube: (
      <YoutubeIcon
        className={className}
        src={youtube}
        alt={'Youtube icon'}
        commonStyles={commonStyles}
      />
    ),
    instagram: (
      <InstagramIcon
        className={className}
        src={instagram}
        alt={'Instagram icon'}
        commonStyles={commonStyles}
      />
    ),
    yelp: (
      <YelpIcon
        className={className}
        src={yelp}
        alt={'Yelp icon'}
        commonStyles={commonStyles}
      />
    ),
    edmunds: (
      <EdmundsIcon
        className={className}
        src={edmunds}
        alt={'Edmunds icon'}
        commonStyles={commonStyles}
      />
    ),
    carsdotcom: (
      <CarsDotComIcon
        className={className}
        src={cars}
        alt={'CarsDotCom icon'}
        commonStyles={commonStyles}
      />
    ),
    dealerRater: (
      <DealerRaterIcon
        className={className}
        src={dealerReater}
        alt={'Dealer Rater icon'}
        commonStyles={commonStyles}
      />
    ),
    bni: (
      <BNIIcon
        className={className}
        src={bni}
        alt={'BNI icon'}
        commonStyles={commonStyles}
      />
    ),
    tikTok: (
      <TiktokIcon
        className={className}
        src={tikTok}
        alt={'TikTok icon'}
        commonStyles={commonStyles}
      />
    ),
    zeemee: (
      <ZeeMeeIcon
        className={className}
        src={zeemee}
        alt={'ZeeMee icon'}
        commonStyles={commonStyles}
      />
    ),
    whatsApp: (
      <WhatsappIcon
        className={className}
        src={whatsapp}
        alt={'Whatsapp icon'}
        commonStyles={commonStyles}
      />
    ),
    pinterest: (
      <PinterestIcon
        className={className}
        src={pinterest}
        alt={'Pinterest icon'}
        commonStyles={commonStyles}
      />
    ),
  };
};

export const defaultStyleProperties: keyedString = {
  BOLD: {
    backgroundColor: '#232224',
    fontFamily: 'Oswald-bolder',
    textColor: '#F8BD25',
    fontWeight: 'bolder',
    fontStyle: 'normal',
  },
  SPORTY: {
    backgroundColor: '#88CB5E',
    fontFamily: 'Exo2-bolder',
    textColor: '#1D232F',
    fontWeight: 'bolder',
    fontStyle: 'normal',
  },
  'AUTO MOTO': {
    backgroundColor: '#B41631',
    fontFamily: 'Oxygen-normal',
    textColor: '#FFFFFF',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  Classic: {
    backgroundColor: '#ECEADB',
    fontFamily: 'PlayfairDisplay-bolder',
    textColor: '#554337',
    fontWeight: 'bolder',
    fontStyle: 'normal',
  },
  Rose: {
    backgroundColor: '#A25179',
    fontFamily: 'GreatVibes-normal',
    textColor: '#FFFFFF',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  Blank: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    textColor: 'black',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  FRESH: {
    backgroundColor: '#CED5D4',
    fontFamily: 'PlayfairDisplayItalic-bolder',
    textColor: '#495F54',
    fontWeight: 'bolder',
    fontStyle: 'italic',
  },
  MODERN: {
    backgroundColor: '#202E42',
    fontFamily: 'Montserrat-bolder',
    textColor: '#E84C3D',
    fontWeight: 'bolder',
    fontStyle: 'normal',
  },
  INDUSTRIAL: {
    backgroundColor: '#EFEFEF',
    fontFamily: 'BarlowCondensed-normal',
    textColor: '#000000',
    fontWeight: 'normal',
    fontStyle: 'normal',
  },
  Soft: {
    backgroundColor: '#DDE1EB',
    fontFamily: 'NunitoBlack-bolder',
    textColor: '#4D5678',
    fontWeight: 'bolder',
    fontStyle: 'normal',
  },
};

export function getLayoutForLayoutType(
  logoUrl: string,
  layoutName: string,
  ctas: object[],
  socialLinks: object
) {
  switch (layoutName) {
    case 'standard': {
      return Layouts.StandardLayout(ctas, socialLinks, logoUrl);
    }
    case 'digitalPresence': {
      return Layouts.DigitalPresenceLayout(ctas, socialLinks, logoUrl);
    }
    case 'mediaFocus': {
      return Layouts.MediaFocusLayout(ctas, socialLinks, logoUrl);
    }
    case 'social': {
      return Layouts.SocialAndCtaLayout(ctas, socialLinks);
    }
    case 'brand': {
      return Layouts.BrandAndCtaLayout(ctas, socialLinks, logoUrl);
    }
    case 'mediaImage': {
      return Layouts.MediaImage(ctas, socialLinks, logoUrl);
    }
    default: {
      return Layouts.StandardLayout(ctas, socialLinks, logoUrl);
    }
  }
}

export const playButtonPositionMapping: any = {
  '0': 'center',
  '1': 'top-left',
  '2': 'top-right',
  '3': 'bottom-left',
  '4': 'bottom-right',
};
