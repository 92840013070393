import React from 'react';
import { CustomReportBodyWrapper } from '../styles';
import { reportData } from 'lib/const';
import { ICombinedUserData, useAuth } from 'lib/context';
import { VIDEO_ATTRIBUTES } from 'lib/utils/videoAttributes';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues } from '../types';
import { SelectFields } from 'lib/components/selectFields/SelectFields';
import { groupBy } from 'lodash';
import {
  REPORT_GROUP_TYPE,
  ReportTypes,
  SENT_UNIQUE_REPORT_DATA,
} from 'lib/const/ReportData';
import { SelectField } from 'lib/components/selectFields/types';
import { checkIfUniqueSentReportTypeAvailable } from 'lib/utils/productFeature';

const getAllReports = (userData: ICombinedUserData): SelectField[] => {
  const reports = Object.keys(reportData)
    .filter(
      reportValue =>
        !reportData[reportValue].isAutomotive ||
        (!!userData.isAutomotive && !!reportData[reportValue].isAutomotive)
    )
    .map(reportValue => {
      const isVideoAttributes = reportValue.includes(VIDEO_ATTRIBUTES);
      if (isVideoAttributes)
        return {
          value: 'video-attributes~~details',
          label: reportData[reportValue]?.label,
          description: reportData[reportValue]?.description || '',
          group: reportData[reportValue]?.group || '',
        };

      return {
        value: reportValue,
        label: reportData[reportValue].label,
        description: reportData[reportValue]?.description || '',
        group: reportData[reportValue]?.group || '',
      };
    });
  const hasUniqueSent = checkIfUniqueSentReportTypeAvailable(userData);
  return [
    ...reports,
    ...(hasUniqueSent
      ? [
          {
            value: ReportTypes.SENT_UNIQUE,
            label: SENT_UNIQUE_REPORT_DATA.label,
            description: SENT_UNIQUE_REPORT_DATA.description,
            group: SENT_UNIQUE_REPORT_DATA.group,
          },
        ]
      : []),
  ];
};

export const CustomModalReportDataStep = () => {
  const { userData } = useAuth();
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();

  const allReports = getAllReports(userData);

  const onAddClick = (fields: SelectField[]) => {
    const fieldIds = fields.map(field => field.value);
    setFieldValue('reports', fieldIds);
  };

  const onRemoveClick = (fields: SelectField[]) => {
    const fieldIds = fields.map(field => field.value);
    setFieldValue('reports', fieldIds);
  };

  const onDragItemHandler = (fields: SelectField[]) => {
    const fieldIds = fields.map(field => field.value);
    setFieldValue('reports', fieldIds);
  };

  const selectedFields = allReports.filter(item =>
    values?.reports?.includes(item.value)
  );

  const groupedFields = groupBy(allReports, 'group') as Record<
    REPORT_GROUP_TYPE,
    SelectField[]
  >;

  return (
    <CustomReportBodyWrapper>
      <SelectFields
        allFields={groupedFields}
        initialFields={selectedFields}
        onRemoveClick={onRemoveClick}
        onAddClick={onAddClick}
        onDragItem={onDragItemHandler}
        scrollableHeight='580px'
      />
    </CustomReportBodyWrapper>
  );
};
