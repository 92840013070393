import { AxiosError } from 'axios';
import { successToast } from 'lib/components/toasts/success';
import { droplrKeys } from './queryKeys';
import { CombinedBoard, CreateBoardBody } from './types';
import { checkDropAuth, client } from './droplrAuth';
import { pick } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useToastError } from 'lib/hooks';
import { EXPRESS_API } from 'configs/api/expressApi';

const createBoard = async (body: CreateBoardBody) => {
  // create board on droplr
  await checkDropAuth();
  let droplrBody = pick(body, [
    'accessList',
    'groupsAccessList',
    'isPublic',
    'name',
    'poweredBy',
    'privacy',
    'uploadPrivacy',
  ]) as Partial<CreateBoardBody>;
  if (!!body.password) {
    droplrBody.password = body.password;
  }

  const droplrResponse = await client.boards.create(droplrBody);

  // create board on covideo
  const covideoBody = pick(body, ['userId', 'customerId', 'thumbnailUrl']);
  const covideoResponse = (
    await EXPRESS_API.post('/board', {
      ...covideoBody,
      droplrBoardId: droplrResponse.id,
    })
  ).data;

  return {
    ...droplrResponse,
    thumbnailUrl: covideoResponse?.board?.thumbnailUrl || '',
  } as CombinedBoard;
};

type Params = {
  userId: number;
  customerId: number;
};

export const useCreateBoard = (params: Params) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation((body: CreateBoardBody) => createBoard(body), {
    onSuccess: () => {
      successToast({
        title: 'Board created successfully!',
      });
      queryClient.invalidateQueries(droplrKeys.boards_list(params));
    },
    onError: (err: AxiosError) => {
      showError(err);
    },
  });
};
