import { PaginationConstants } from 'lib/const/PaginationConstants';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import {
  CDSVideoListFilter,
  InventoryItem,
  SuperAdminUser,
  VideoAttributesExtraParams,
  VideoListAutomotiveItem,
} from 'lib/context';

export type GetSignedUrlsParams = {
  fileName: string;
  mimeType: string;
  folder: string;
};

export type GetSignedUrlsResponse = {
  uploadUrl: string;
  downloadUrl: string;
};
export interface ListWebsiteOverlay extends WebsiteOverlay {
  isDefault?: boolean;
}

/**
 * Calls-To-Action
 */

export type ValidTinyInt = 0 | 1;
export type CustomerAndUserIds = {
  customerId: number;
  userId: number;
};

export type CTA = CustomerAndUserIds & {
  id: number;
  ctaSetId: number;
  type: string;
  linkText: string;
  linkValue: string;
  autoOpen: boolean;
  linkPosition: number;
  newWindow: ValidTinyInt;
  fileTitle: string;
  fileThumbnail: string;
  itemType: string;
};
export type InteractiveCTA = {
  title: string;
  url: string;
  isVisible: boolean;
  type: string;
  linkText: string;
  linkValue: string;
  fileTitle: string;
  fileThumbnail: string;
  itemType: string;
};

export type CTASet = CustomerAndUserIds & {
  id: number;
  company: ValidTinyInt;
  title: string;
  defaultLinks: ValidTinyInt;
  links: CTA[];
};

export type GetCtaSetParams = {
  search?: string;
  start?: number;
  limit?: number;
};

export type GetCtaSetResponse = {
  linksets: CTASet[];
  default: CTASet;
};

export type CallToActionBase = {
  linkText: string;
  linkValue: string;
  autoOpen: boolean;
  linkPosition: number;
  newWindow: ValidTinyInt;
  ctaSetId: number;
  id?: number;
};

export type CreateCTASetParams = {
  title: string;
  default: ValidTinyInt;
  company: ValidTinyInt;
  ctaSetLinks?: CallToActionBase[];
};

export type UpdateCTASetParams = {
  id: number;
  title: string;
  isDefault: boolean;
  isCompany: boolean;
  ctas: CTA[];
};

export type CreateCTASetResponse = {
  success: boolean;
  message: string;
  ctaSet: {
    userId: number;
    customerId: number;
    title: string;
    defaultLinks: ValidTinyInt;
    company: boolean;
    id: number;
    links: CTA[];
  };
  errors?: string[];
};

export type UpdateCTASetResponse = CreateCTASetResponse;

export type SetCTAAsDefaultResponse = {
  isDefault: boolean;
  success: boolean;
  message: string;
  ctaSetId: number;
};

export type UpdateCTAParams = {
  data: CTA;
};

export type UpdateCTAResponse = CTA;

export type DeleteCTAResponse = {
  success: boolean;
  message: string;
  deletedId?: number;
};

export type SetLockForSocialLinkResponse = {
  success: boolean;
  message: string;
  isLocked: boolean;
};

/**
 * Get Email thumbnails
 */

export type EmailIconDefaultType = 'templates' | 'icons';

export type EmailIcon = {
  id: number;
  title: string;
  thumbnail: string;
};

export type EmailTemplate = {
  id: number;
  title: string;
  filename: string;
  customerId: number;
  typeId: number;
  thumbnail: string;
  resellerId: number;
};

export type EmailIconDefault = {
  title: string;
  thumbnail: string;
  type: EmailIconDefaultType;
  id: number;
};

export type GetEmailThumbnailsResponse = {
  icons: EmailIcon[];
  templates: EmailTemplate[];
  iconsCount: number;
  templatesCount: number;
  default: EmailIconDefault;
};

export type SetEmailIconDefaultsBody = {
  type: EmailIconDefaultType;
  id: number;
};

export type SetEmailIconDefaultResponse = {
  success: boolean;
  message: string;
  errors?: string[];
  defaultsData: SetEmailIconDefaultsBody;
};

export type CreateThumbnailRecordParams = {
  title: string;
  thumbnail: string;
};

export type UpdateThumbnailRecordParams = CreateThumbnailRecordParams & {
  id: number;
};

export type CreateThumbnailRecordResponse = {
  success: boolean;
  message: string;
  data: EmailIcon;
};

export type UploadEmailThumbnailParams = {
  data: {
    uploadURL: string;
    file: File;
    mimeType: string;
  };
  onUploadProgress: (p: ProgressEvent) => void;
};

export type ListThumbnail = EmailIcon & {
  type: EmailIconDefaultType;
  isDefault?: boolean;
};

/**
 * Videos
 */
export type VideoListItem = {
  id: string;
  wtvId: string;
  title: string;
  dateCreated: string;
  views: string;
  thumbnail: string;
  tags: object[];
  processing: number;
  folder?: string /* SUS-1143 changes */;
  folderAccess?: string;
  videoSource: string;
  reactionsPreference?: boolean;
  autogeneratedGif?: string;
  autogeneratedThumbnail?: string;
  recordDate?: string;
  videoReplyEnabled?: boolean;
  videoLength?: number;
};

export type GetLandingPagesParams = {
  search?: string;
  start?: number;
  limit?: number;
  showUserCustomLandingPages?: boolean;
};

export type GetWebsiteOverlaysParams = GetLandingPagesParams;

export type VideoListParams = {
  size: number | undefined;
  page: number | undefined;
  search?: string | null;
  folder?: string;
  sort?: string;
  count?: number;
  filter?: CDSVideoListFilter;
};

export enum GenericCountFilter {
  ALL = '',
  WITH_COUNT = '1',
  WITHOUT_COUNT = '0',
}

export type QueryListParams = {
  groupId?: string;
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  reportId?: string;
  accountManagerId?: string;
  resellerId?: string;
  integrationId?: number;
  all?: boolean;
  filterByDepartment?: boolean;
  refresh?: boolean;
  startDate?: Date;
  endDate?: Date;
  conversation?: boolean;
  senderId?: number | null;
  order?: string;
  recurring?: number;
  sendsFilter?: GenericCountFilter;
  sortData?: {
    sortKey: string;
    order: PaginationConstants;
  };
};

export type ContactListItem = {
  contactId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  groups?: { name: string; groupId: number }[];
};

export interface ContactOptOutListItem extends ContactListItem {
  contactId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  fax: string;
  notes: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  share: boolean;
  dateOpted: string;
}

export interface Contact extends ContactListItem {
  contactId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  fax: string;
  notes: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  additionalFields?: { key: string; value: any }[];
}

export type GroupListItem = {
  groupId: string;
  name: string;
  contacts?: ContactListItem[];
};

export type ContactsBulkUpdateParams = {
  contacts: ContactListItem[];
  groups: GroupListItem[];
};

export type CreateParams = {
  data: object;
};

export type TranscriptionData = {
  videoId: string;
  userId: string;
  originalTranscript: string;
  modifiedTranscript: string;
  enabled: number;
  status: string;
};

export type TranscriptionResponse = TranscriptionData;

export type TranslationData = {
  videoId: string;
  userId: string;
  translatedText: string;
};

export type TrimVideoData = {
  title?: string;
  start?: number;
  duration?: number;
};

export type AttributeListItem = {
  attributeId?: string;
  title: string;
  slug: string;
  type: number;
  default?: string;
};

export type VideoAttribute = {
  attributeId: number;
  customerId: number;
  departments: string[];
  default?: string;
  dropdownValues: Array<any>; // revisit
  index: number;
  isLocked: number;
  isReadOnly: number;
  isRequired: number;
  slug: string;
  title: string;
  type: string; // text | dropdown
};

export type VideoAttributeValue = {
  id: number;
  attributeId: number;
  value: string;
  videoId: number;
};

export type AnnotationListItem = {
  annotationId?: string;
  type: string;
  startTime: number;
  endTime: number;
  startTimeText?: string;
  endTimeText?: string;
  linkType?: string;
  url?: string;
  email?: string;
  phone?: string;
  isLinkEnabled?: boolean;
  videoId?: number;
  customerId?: string;
};

export type CreateTemplateParams = {
  data: any;
  templateId?: string;
};

/**
 * Design API Params and Types
 */

export type LandingPage = {
  id: number;
  title: string;
  thumbnailURL: string;
  typeId?: number;
  landingPage?: {
    [key: string]: any;
  };
};

export type WebsiteOverlay = {
  id: number;
  url: string;
  title: string;
  defaultflag: 0 | 1;
  fileThumbnail: string;
  fileTitle: string;
  type: string;
  itemType: string;
};

export type GetLandingPagesResponse = {
  templates: LandingPage[];
  default: LandingPage;
  count: number;
};

export type GetWebsiteOverlaysResponse = {
  websiteUrls: WebsiteOverlay[];
  default: WebsiteOverlay;
  count: number;
};

export type GetLandingPagePreviewResponse = {
  url: string;
  success: boolean;
  message?: string;
};

export type SetDefaultLandingPageResponse = {
  newDefaultLandingPageId?: number;
};

export type SetDefaultWebsiteOverlayResponse = {
  success: boolean;
  newDefaultWebsiteOverlayId: number;
  message: string;
};

export type CreateWebsiteOverlayParams = {
  title: string;
  url: string;
  isDefault?: boolean;
  fileThumbnail: string;
  fileTitle: string;
  type: string;
  itemType: string;
};

export type UpdateWebsiteOverlayParams = CreateWebsiteOverlayParams & {
  id: number;
};

export type CreatedWebsiteOverlay = WebsiteOverlay & {
  userId: number;
  customerId: number;
};

export type CreateWebsiteOverlayResponse = {
  success: boolean;
  message: string;
  websiteUrl: CreatedWebsiteOverlay;
  default: WebsiteOverlay;
};

export type UpdateWebsiteOverlayResponse = CreateWebsiteOverlayResponse;

export type CreateCustomReportParams = {
  data: object;
  reportId?: string;
  recurring?: string;
  recurringId?: string;
  params?: VideoAttributesExtraParams;
};

export type CreateTeleprompterScriptParams = {
  data: object;
  scriptId?: string;
};

export type CreateCustomerParams = {
  data: object;
  customerId?: string | number;
};

export type CreateVideoRequestParams = {
  data: object;
  videoRequestId?: string | number;
};

export type ApproveDenyRequestParams = {
  videoRequestId?: string | number;
  note?: string;
};

export type DuplicateVideoApiParams = {
  videosToDuplicate: (string | number)[];
};

export type VdpItem = {
  id: number;
  createdAt: Date;
  dealerId: string;
  userId: number;
  videoId: number;
  vin: string;
  user: any;
  integrationType: string;
  inventoryItem?: InventoryItem;
  video?: VideoListAutomotiveItem;
  order?: number;
};

export type MoveVideoApiParams = {
  copy?: boolean;
  videoFolder?: string;
  fromCompany?: boolean;
};
export type VideoChapterItem = {
  playbackPosition: number;
  title: string;
};
export type VideoChapterItemExtended = VideoChapterItem & {
  ref: React.RefObject<HTMLProgressElement>;
  duration: number;
  bubblePosition: number;
  playbackPositionText: string;
};

export type QRVideo = {
  qrId?: number;
  userId?: number;
  customerId?: number;
  videoId?: number;
  designId?: number;
  title?: string;
  url?: string;
  qrUrl?: string;
  qrType?: number;
  templateLinks?: number;
  overlayUrlId?: number;
  itemType?: string;
};

export type GroupItems = {
  name: string;
};

export type CustomerListParams = {
  start?: number;
  limit?: number;
  search?: string;
  sortKey?: string;
  order?: string;
  status:
    | VerificationStatus.ACTIVE
    | VerificationStatus.INACTIVE
    | VerificationStatus.ALL;
  resellerId?: number;
};

export type OrganizationListParams = {
  start?: number;
  limit?: number;
  sortKey?: string;
  order?: string;
  search?: string;
};

export type UserListParams = {
  start?: number;
  limit?: number;
  search?: string;
  sortKey?: string;
  order?: string;
  status:
    | VerificationStatus.ACTIVE
    | VerificationStatus.INACTIVE
    | VerificationStatus.ALL;
  customerId?: number;
  departmentId?: number;
};

export type UserStatisticsParams = {
  customerId?: number;
  packageId?: number;
  search?: string;
  status?:
    | VerificationStatus.ACTIVE
    | VerificationStatus.INACTIVE
    | VerificationStatus.ALL;
};

export type OptOutListParams = {
  start?: number;
  limit?: number;
  search?: string;
  sortKey?: string;
  order?: string;
  customerId?: number | undefined;
};

export type User = {
  firstName: string;
  lastName: string;
  email: string;
};

export interface OptOutContact extends User {
  user: SuperAdminUser;
  contactId: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  fax: string;
  notes: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  share: boolean;
  dateOpted: string;
}

export type UsageReportsListParams = {
  start?: number;
  limit?: number;
  search?: string;
  sortKey?: string;
  order?: string;
  status:
    | VerificationStatus.ACTIVE
    | VerificationStatus.INACTIVE
    | VerificationStatus.ALL;
  customerId?: number;
  packageId?: number;
};

export interface VideoTranscriptionResponse {
  success: boolean;
  data: {
    error: boolean;
    message: string;
  };
}

export interface VideoTranscriptionBody {
  transcript?: string;
  enabled: number;
}

export type SuperAdminSearchParams = {
  search?: string;
  start?: number;
  limit?: number;
  customerId?: number;
};
