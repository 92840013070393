import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { groupKeys } from './groupKeys';
import { QueryParams } from './useQueryGroups';

export const deleteGroups = async (selectedGroups: string[]) => {
  const response = await EXPRESS_API.delete('/groups', {
    data: selectedGroups,
  });
  return response.data;
};

export const useDeleteGroupsMutation = (
  selectedGroups: string[],
  onSuccess?: () => void,
  queryParams?: QueryParams
) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(() => deleteGroups(selectedGroups), {
    onError: err => showError(err),
    onSuccess: async () => {
      successToast({
        title: `Group${selectedGroups.length > 1 ? 's' : ''} successfully deleted!`,
      });
      await queryClient.invalidateQueries(groupKeys.groups(queryParams));
      onSuccess && onSuccess();
    },
  });
};
