import React, { useState } from 'react';
import { NotFound } from 'app/pages/notFound/NotFound';
import { CustomModalReportBasicStep } from './steps/Basic';
import { CustomModalReportDataStep } from './steps/Data';
import { CustomModalReportTimeframeStep } from './steps/Timeframe';
import { CustomModalReportUsersStep } from './steps/users/Users';
import { CUSTOM_REPORT_STEPS, STEPS } from './types';
import { IResellerCustomersResponse } from 'lib/api/superadmin/useCustomersResellersQuery';

interface IProps {
  currentStepIndex: number;
  steps: STEPS;
}

export const CustomReportBody = ({ currentStepIndex, steps }: IProps) => {
  const [resellerCustomersResponse, setResellerCustomersResponse] = useState<
    IResellerCustomersResponse | undefined
  >(undefined);
  const value = steps[currentStepIndex].value;

  switch (value) {
    case CUSTOM_REPORT_STEPS.BASICS:
      return <CustomModalReportBasicStep />;
    case CUSTOM_REPORT_STEPS.USERS:
      return (
        <CustomModalReportUsersStep
          setResellerCustomersResponse={setResellerCustomersResponse}
          resellerCustomersResponse={resellerCustomersResponse}
        />
      );
    case CUSTOM_REPORT_STEPS.DATA:
      return <CustomModalReportDataStep />;
    case CUSTOM_REPORT_STEPS.TIMEFRAME:
      return <CustomModalReportTimeframeStep />;
    default:
      return <NotFound style={{ minHeight: '80vh' }} />;
  }
};
