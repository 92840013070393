import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import { Button } from 'react-covideo-common';
import { MdMovie } from 'react-icons/md';
import RouteLeavingGuard from '../video/videoDetails/main/RouteLeavingGuard';
import { RECORDING_STATUS } from './types';

interface RecordingControlProps {
  status: RECORDING_STATUS;
  handleGoToRecordHome: () => void;
  teleprompterActions?: React.ReactNode;
}

export const RecordingHeaderWithRouteGuard = ({
  status,
  handleGoToRecordHome,
  teleprompterActions,
}: RecordingControlProps) => {
  const shouldHideChangeRecordingOptionBtn =
    status === RECORDING_STATUS.RECORDING || status === RECORDING_STATUS.PAUSED;
  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={handleGoToRecordHome}
        shouldBlockNavigation={() => {
          return status === RECORDING_STATUS.RECORDING;
        }}
        title='Leave without saving the recording?'
        text='Your recording will not be saved. This action can’t be undone.'
        confirmButtonText='Continue'
        discardButtonText='Leave'
      />
      <Gap m='72px 0 32px 0' width='100%' justifyContent='space-between'>
        {!shouldHideChangeRecordingOptionBtn ? (
          <Button
            icon={<MdMovie size={18} />}
            variant='secondary'
            text={'Change recording option'}
            onClick={handleGoToRecordHome}
            disabled={status === RECORDING_STATUS.COUNTDOWN}
          />
        ) : (
          <div style={{ height: 40 }}></div>
        )}
        {teleprompterActions}
      </Gap>
    </>
  );
};
