export const shouldInvalidatePage = (
  count: number,
  length: number,
  page: number,
  size: number
): boolean => {
  const remaining = count - length;
  const threshold = page * size + 1;
  const invalidatePage = remaining > 0 && remaining < threshold;

  return invalidatePage && page !== 0;
};
