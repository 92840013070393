import React from 'react';
import { useParams, useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdOutlineChevronRight } from 'react-icons/md';
import { TabsManager } from '../../customers/components/TabsManager';
import { LoadingIndicator } from 'lib/components';
import { Container, MainContainer, MainWrapper } from '../../index.styled';
import { ResellerDetailsHeader } from '../components/ResellerDetailsHeader';
import {
  addReseller,
  editReseller,
  getResellerDetails,
  useCustomersStatisticsQuery,
} from 'lib/api/superAdminApi';
import { Formik } from 'formik';
import { OptionalError, ResellerDetails } from '../components/ResellerDetails';
import { CustomerListFilter, SuperAdminReseller } from 'lib/context';
import * as Yup from 'yup';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { CustomerListing } from '../components/CustomerListing';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { DEFAULT_PLAYBACK_URL_VALUE } from 'lib/const/SuperAdminConstants';

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: ${theme.palette.covideoBlue100};
  font-weight: 500;
  font-size: 15px;
  margin-right: 8px;
  cursor: pointer;
`;

const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  span {
    color: ${theme.palette.gray80};
    font-weight: normal;
    font-size: 16px;
    margin-left: 8px;
  }
`;

export enum TABS {
  RESELLER_DETAILS,
  CUSTOMERS,
}

const fieldsSchema = Yup.object({
  phoneNumber: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(7, 'Please enter a phone number equal or longer than 7 characters.'),
  resellerName: Yup.string()
    .required('Reseller Name is required')
    .min(2, 'Reseller Name is too small'),
  url: Yup.string().required('URL is required'),
});

let fields: SuperAdminReseller = {
  resellerName: '',
  resellerId: 0,
  url: '',
  resellerReportingEnabled: false,
  phoneNumber: '',
  playbackUrl: DEFAULT_PLAYBACK_URL_VALUE,
  email: '',
  customerId: 0,
  integration: 0,
  fullReportAccess: 0,
  dealer: 0,
  easyCare: 0,
};

const loadFields = (data: SuperAdminReseller) => {
  return {
    resellerId: data.resellerId || 0,
    resellerName: data.resellerName || '',
    url: data.url || '',
    phoneNumber: data.phoneNumber || '',
    playbackUrl: data.playbackUrl || DEFAULT_PLAYBACK_URL_VALUE,
    resellerReportingEnabled: data.resellerReportingEnabled,
    dealer: data.dealer,
    active: data.active,
  };
};

export const Details = () => {
  const [loading, setLoading] = React.useState(true);
  const [isEditView, setIsEditView] = React.useState(false);
  const [reseller, setReseller] = React.useState<SuperAdminReseller>(
    fields as SuperAdminReseller
  );
  const [updatedReseller, setUpdatedReseller] =
    React.useState<SuperAdminReseller>(fields as SuperAdminReseller);
  const [isFormDirty, setIsFormDirty] = React.useState(false);
  const [config, setConfig] = React.useState({
    title: 'New Reseller',
    isValidUser: true,
    showAddView: false,
    reseller: {},
  });
  const [optionalError, setOptionalError] = React.useState<OptionalError>({});

  let { id } = useParams() as {
    id: string;
    fromScreen: string;
    cusId: string;
  };
  const isIdNumeric = /^[0-9]+$/.test(id);
  const history = useHistory();
  const goToResellersList = () => history.push(`/admin/resellers`);

  const loadReseller = async () => {
    setLoading(true);
    try {
      if (!id || !isIdNumeric) {
        setConfig({
          title: 'New Reseller',
          isValidUser: false,
          showAddView: true,
          reseller: {},
        });
        setIsEditView(false);
        fields = { ...fields, ...loadFields({} as SuperAdminReseller) };
        setReseller(fields);
      }
      if (id && isIdNumeric) {
        const res = await getResellerDetails(parseInt(id, 10));
        setConfig({
          title: `${res.resellerName}`,
          isValidUser: false,
          showAddView: false,
          reseller: res,
        });
        fields = { ...fields, ...loadFields(res) };
        setReseller(fields);
        setIsEditView(true);
      }
      setLoading(false);
    } catch (error) {
      setConfig({
        title: '',
        isValidUser: false,
        showAddView: false,
        reseller: {},
      });
      setIsEditView(false);
      fields = { ...fields, ...loadFields({} as SuperAdminReseller) };
      setReseller(fields);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    loadReseller();
  }, []);

  const filter: CustomerListFilter = {
    status: VerificationStatus.ALL,
    resellerId: parseInt(id, 10),
    page: 0,
    size: 0,
  };

  const { statistics, isLoading: statsLoading } = useCustomersStatisticsQuery({
    params: {
      status: VerificationStatus.ALL,
      resellerId: filter.resellerId >= 0 ? filter.resellerId : undefined,
    },
    filter,
  });

  const [activeTab, setActiveTab] = React.useState(TABS.RESELLER_DETAILS);
  const tabs = [
    { value: TABS.RESELLER_DETAILS, label: 'Reseller Details' },
    { value: TABS.CUSTOMERS, label: 'Customers' },
  ];

  if (loading) {
    return <LoadingIndicator isLoading={loading} />;
  }

  const handleSave = async () => {
    fieldsSchema
      .validate(updatedReseller)
      .then(async function () {
        setOptionalError({});
        if (!id || !isIdNumeric) {
          if (!isEditView) {
            await addReseller(updatedReseller);
            successToast({
              title: `New Reseller saved successfully.`,
            });
            goToResellersList();
          } else {
            await editReseller(parseInt(id, 10), updatedReseller);
            setReseller(updatedReseller);
            successToast({
              title: `New Reseller edited successfully.`,
            });
          }
          return;
        } else {
          await editReseller(parseInt(id, 10), updatedReseller);
          setReseller(updatedReseller);
          successToast({
            title: `New Reseller edited successfully.`,
          });
        }
      })
      .catch(function (err) {
        setOptionalError(err);
        errorToast({
          title: err.message || `Error in saving new user.`,
        });
      });
  };

  return (
    <>
      <MainContainer>
        <MainWrapper>
          <Container>
            <Breadcrumbs>
              <Button
                onClick={() => {
                  goToResellersList();
                }}
              >
                Resellers
              </Button>
              <MdOutlineChevronRight
                size={16}
                color={theme.palette.covideoBlue100}
              />
              <span>{config.title}</span>
            </Breadcrumbs>
            <ResellerDetailsHeader
              reseller={reseller}
              onSave={() => handleSave()}
              disableSaveButton={!isFormDirty}
              isEditView={isEditView}
              activeTab={activeTab}
              statistics={statistics}
              loading={statsLoading}
            />
            {isEditView && (
              <TabsManager
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabTitlePadding='0'
                tabTitleMargin='0 30px 0 0'
                roundedBorder={false}
              />
            )}
            {!isEditView && (
              <Formik
                initialValues={reseller}
                onSubmit={() => {}}
                validate={() => {}}
                enableReinitialize
                validationSchema={fieldsSchema}
              >
                {props => (
                  <ResellerDetails
                    {...props}
                    reseller={reseller}
                    setReseller={setUpdatedReseller}
                    onFormDirty={setIsFormDirty}
                    isEditView={isEditView}
                    optionalError={optionalError}
                  />
                )}
              </Formik>
            )}
            {isEditView && activeTab === TABS.RESELLER_DETAILS && (
              <>
                <Formik
                  initialValues={reseller}
                  onSubmit={() => {}}
                  validate={() => {}}
                  enableReinitialize
                  validationSchema={fieldsSchema}
                >
                  {props => (
                    <ResellerDetails
                      {...props}
                      reseller={reseller}
                      setReseller={setUpdatedReseller}
                      onFormDirty={setIsFormDirty}
                      isEditView={isEditView}
                      optionalError={optionalError}
                    />
                  )}
                </Formik>
              </>
            )}
            {activeTab === TABS.CUSTOMERS && (
              <>
                <CustomerListing resellerId={reseller.resellerId} />
              </>
            )}
          </Container>
        </MainWrapper>
      </MainContainer>
    </>
  );
};
