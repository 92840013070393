export enum RECORDING_STATUS {
  IDLE = 'IDLE',
  RECORDING = 'RECORDING',
  PAUSED = 'PAUSED',
  CAMERA_READY = 'CAMERA_READY',
  COUNTDOWN = 'COUNTDOWN',
  DONE = 'DONE',
}

export enum RECORD_LOADER_STATUS {
  UPLOADING = 1,
  CREATE = 2,
  ENCODE = 3,
  UPLOAD_COMPLETED = 4,
  ERROR = 5,
}

export interface IVideoQuality {
  width: { ideal: number };
  height: { ideal: number };
}

export interface IVideoSettings extends IVideoQuality {
  frameRate: number;
  deviceId?: string | undefined;
}

export type VirtualBackgroundTypes = 'none' | 'default' | 'custom';
export type PrivacyTypes = 'PUBLIC' | 'PRIVATE' | 'TEAM';

export type VirtualBackground = {
  url: string; // required in case of custom backgrounds
  type: VirtualBackgroundTypes;
  index: number; // required in case of default backgrounds
  privacy: PrivacyTypes; // required in case of custom backgrounds
  id: string; // required in case of custom backgrounds
};
