import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';

const Title = styled.div`
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.coal};
  padding: 8px 12px;
`;
type TitleProps = {
  title: string;
};

const FileTitle = ({ title }: TitleProps) => {
  return <Title>{title}</Title>;
};

export default FileTitle;
