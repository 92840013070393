export const FOLDER_ACCESS = {
  VIEW: 'view',
  EDIT: 'edit',
  COPY: 'copy',
};

export const FOLDER_GROUP_LABEL = {
  MY_FOLDERS: 'MY FOLDERS',
  SHARED_WITH_ME: 'SHARED WITH ME',
};

export const FOLDER_GROUP_VALUE = {
  MY_FOLDERS: 'my_folders',
  SHARED_WITH_ME: 'shared_with_me',
};
