import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { SupportBadge } from 'lib/components';
import { useCookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { PageContextProvider } from './PageContext';

import { useAuth } from 'lib/context/auth';

import { isEnvDevelopment } from 'configs/api/enviroments';
import { ContactUsModalProvider } from 'lib/context';
import { VideoLimitProvider } from 'lib/context/videolimit/VideoLimitContext';
import { initFacebookSdk } from './pages/account/facebook/service/facebookInit';
import { MainRoutes } from './routes/main';

const DEFAULT_DOMAIN = 'www.covideo.com';

export const App = () => {
  const { userData, whitelabel } = useAuth();
  const [cookies] = useCookies();

  const isUpgrade = window.location.pathname.includes('/upgrade');
  const isSignUp = window.location.pathname.includes('/signup');
  const isResetPassword = window.location.pathname.includes(
    'verify-reset-password'
  );
  const packageDetailsExist = userData?.user?.packageDetails;

  const redirectParam =
    whitelabel?.name === 'Covideo'
      ? `?redirect=${window.location.toString()}`
      : '';
  if (!cookies.jwt && !isEnvDevelopment && !(isSignUp || isResetPassword)) {
    window.location.href = `https://${whitelabel?.domainV2 || DEFAULT_DOMAIN}/backoffice/incs/logout.php${redirectParam}`;
    return <></>;
  }

  // from AuthorizationContext
  if (!packageDetailsExist && !isSignUp && !isResetPassword) {
    window.location.href = `https://${whitelabel?.domainV2 || DEFAULT_DOMAIN}/backoffice/incs/logout.php${redirectParam}`;
    return <></>;
  }

  if (!userData.verified && !isUpgrade && !(isSignUp || isResetPassword)) {
    window.location.href = '/upgrade';
    return <></>;
  }

  initFacebookSdk();
  return (
    <>
      <link
        key={whitelabel?.name}
        type='text/css'
        rel='stylesheet'
        href={
          'https://' +
          whitelabel?.domainV2 +
          whitelabel?.contentPath +
          '/css/style.css'
        }
      />
      <PageContextProvider>
        <VideoLimitProvider>
          <ContactUsModalProvider>
            <BrowserRouter>
              <MainRoutes />
              {!isSignUp && <SupportBadge />}
            </BrowserRouter>
          </ContactUsModalProvider>
        </VideoLimitProvider>
      </PageContextProvider>
    </>
  );
};
