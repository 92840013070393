import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { MdCheck, MdClose, MdDeleteForever } from 'react-icons/md';
import dayjs from 'dayjs';

import { LoadingIndicator, RadioInput, TextInput } from 'lib/components';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import Switch from 'app/pages/design/landingPageBuilder/components/Switch';
import Select, { ValueType } from 'react-select';
import { ModalDeleteSnippets } from '../components';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { getDepartments } from 'lib/api';

import { SharedWith, SnippetType } from 'lib/const';
import {
  FontFamilyDropdown,
  FontSizeDropdown,
  TextEditor,
} from 'lib/components/textEditorAutomotive';
import { NotFound } from 'app/pages/notFound/NotFound';
import { LaunchPopup } from 'lib/components/magic/popups/LaunchPopup';
import { useCreateSnippetMutation } from 'lib/api/snippets/useCreateSnippetMutation';
import { useQuerySnippet } from 'lib/api/snippets/useQuerySnippet';
import { useUpdateSnippetMutation } from 'lib/api/snippets/useUpdateSnippetMutation';
import { useDeleteSnippetMutation } from 'lib/api/snippets/useDeleteSnippetMutation';
import { ManagedDepartmentResponse } from 'lib/api/departments/types';
import { SnippetDeparment, SnippetDepartment } from 'lib/api/snippets/types';

const FixedBody = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: white;
  overflow-y: auto;
  padding-bottom: 15px;
`;

const Header = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 37px;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
  margin-bottom: 32px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LinkBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: ${theme.palette.coal};
  cursor: pointer;
  span {
    margin-left: 5px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1300px;
  max-width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 15px;
`;
const LeftContent = styled.div`
  width: 860px;
  max-width: 100%;
  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 32px;
  }
`;
const RightContent = styled.div`
  width: 384px;
  max-width: 100%;
`;
interface InputGroupProps {
  padding?: string;
  disabled?: boolean;
}
const InputGroup = styled.div<InputGroupProps>`
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  margin-bottom: 16px;
  position: relative;
  padding: ${props => props.padding || '16px'};
  ${props =>
    props.disabled === true &&
    `
    .demo-wrapper{
      opacity:.2;
      pointer-events:none;
    }
  `}
`;
const Input = styled(TextInput)`
  width: 100%;
  max-width: initial;
`;
const SwitchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    position: relative;
  }
  > span {
    margin-left: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    color: ${theme.palette.covideoBlue100};
  }
  input {
    position: absolute;
  }
  margin-bottom: 16px;
`;
const Info = styled.div`
  font-size: 14px;
  color: ${theme.palette.gray100};
`;
interface LabelProps {
  margin?: string;
}
const Label = styled.p<LabelProps>`
  font-weight: 500;
  font-size: 14px;
  color: ${theme.palette.gray60};
  margin: ${props => props.margin || '0 0 8px 0'};
`;
/**** SUS-796 changes ***/
const ErrorInfo = styled.div`
  margin-bottom: 14px;
  ${theme.mediaQueryMaxWidth.md} {
    display: none;
  }
  span {
    color: rgb(232, 76, 61);
  }
`;

const emailEditorToolbar = {
  options: ['fontFamily', 'fontSize', 'inline', 'remove', 'link'],
  fontFamily: {
    options: ['Work Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Verdana'],
    component: FontFamilyDropdown,
  },
  fontSize: {
    options: [10, 12, 14, 16, 18, 24, 30, 48, 72, 96],
    component: FontSizeDropdown,
  },
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon large-icon',
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon large-icon',
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon large-icon',
    },
  },
  remove: {
    className: 'toolbar-custom-icon toolbar-remove-icon medium-icon',
  },
  link: {
    inDropdown: true,
    className:
      'toolbar-custom-icon toolbar-custom-link-dropdown-option toolbar-link-icon',
    options: ['link', 'unlink'],
    link: {
      className: 'toolbar-custom-icon add-content-link toolbar-link-icon',
    },
    unlink: {
      className: 'toolbar-custom-icon add-content-unlink toolbar-unlink-icon',
    },
  },
};
const smsEditorToolbar = {
  options: [],
};

const sharedWithOptions = Object.values(SharedWith)
  .filter((sharedWith: string) => !!sharedWith)
  .map((sharedWith: string) => {
    return {
      value: sharedWith,
      label: sharedWith,
    };
  });

interface MatchParams {
  snippetId: string;
}

const SNIPPET_CREATE = 'create';

export const Details = withRouter((props: RouteComponentProps<MatchParams>) => {
  const history = useHistory();
  const { snippetId } = props.match.params;
  const { data: snippet, isLoading: isLoadingSnippet } =
    useQuerySnippet(snippetId);
  const { mutateAsync: addSnippet, isLoading: isCreatingSnippet } =
    useCreateSnippetMutation();
  const { mutateAsync: editSnippet, isLoading: isUpdatingSnippet } =
    useUpdateSnippetMutation(undefined, snippetId);
  const loading = isCreatingSnippet || isUpdatingSnippet || isLoadingSnippet;
  const { mutateAsync: removeSnippet } = useDeleteSnippetMutation();

  const isCreate = snippetId === SNIPPET_CREATE;

  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState(false);
  const [emailText, setEmailText] = useState<string | null>(null);
  const [sms, setSms] = useState(false);
  const [smsText, setSmsText] = useState<string | null>(null);
  const [sharedWith, setSharedWith] = useState(SharedWith.OnlyMe as string);
  const [departments, setDepartments] = useState<SnippetDepartment[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<
    SnippetDepartment[]
  >([]);
  const [snippetValid, setSnippetValid] = useState(false);
  const [showDepartmentSelect, setShowDepartmentSelect] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formIsTouched, setFormIsTouched] = useState(false);
  const {
    userData: { customerId, droplrAccess },
  } = useAuth();
  const themes = useTheme();

  const refreshDepartments = async () => {
    const response = await getDepartments(customerId, {}).catch(err => err);
    const departmentData = await (response?.data || []).map(
      (department: ManagedDepartmentResponse) => {
        return { value: department.DepartmentID, label: department.Name };
      }
    );
    setDepartmentOptions(departmentData);
  };

  useEffect(() => {
    refreshDepartments();
  }, [snippetId]);

  useEffect(() => {
    if (isCreate) {
      setTitle(`New Template ${dayjs(new Date()).format('YYYY-MM-DD')}`);
      setSubject(subject);
    }
    if (snippet) {
      setTitle(snippet.title || '');
      setSubject(snippet.subject || '');
      setSms(snippet.sms || false);
      setEmail(snippet.email || false);
      setEmailText(snippet.emailText ?? null);
      setSmsText(snippet.smsText ?? null);
      setSharedWith(snippet.sharedWith || SharedWith.NotShared);
      setDepartments(
        snippet.departments?.map((d: SnippetDeparment) => ({
          value: d.id,
          label: d.name,
        })) || []
      );
    }
  }, [snippet]);

  const handleShareWithClick = (value: string) => {
    setSharedWith(value);
    handleFormChange();
  };

  const validateSnippet = () => {
    const hasTitle = title.trim().length > 0;
    const emailOrSmsSelected = sms || email;
    const notEmptyDepartmentList =
      sharedWith !== SharedWith.Department || departments.length > 0;
    return hasTitle && emailOrSmsSelected && notEmptyDepartmentList;
  };

  const handleFormChange = () => {
    setFormIsTouched(true);
  };

  useEffect(() => {
    setSnippetValid(validateSnippet());
  }, [sms, email, title, sharedWith, departments]);

  const handleEmailTextChange = (value: string) => {
    setEmail(value.length > 1);
    setEmailText(value);
    handleFormChange();
  };

  const handleSmsTextChange = (value: string) => {
    setSms(value.length > 1);
    setSmsText(value);
    handleFormChange();
  };

  const handleDepartmentChange = (
    value: ValueType<SnippetDepartment, true>
  ) => {
    if (!value) {
      setDepartments([]);
      return;
    }

    const departmentsArray = Array.isArray(value)
      ? value
      : [value as unknown as SnippetDepartment];
    setDepartments(departmentsArray);
  };

  useEffect(() => {
    setShowDepartmentSelect(sharedWith === 'Department');
  }, [sharedWith]);

  const goToSnippets = () => history.push(`/profile/templates`);

  const updateSnippet = async () => {
    const departmentIds = departments.map((d: SnippetDepartment) => {
      return { id: d.value };
    });
    await editSnippet({
      ...snippet,
      title,
      subject,
      email,
      emailText: emailText ?? '',
      sms,
      smsText: smsText ?? '',
      sharedWith,
      departments: departmentIds,
    });
    await setFormIsTouched(false);
    goToSnippets();
  };

  const createSnippet = async () => {
    const departmentIds = departments.map((d: SnippetDepartment) => {
      return { id: d.value };
    });
    await addSnippet({
      data: {
        title,
        subject,
        email,
        emailText: emailText ?? '',
        sms,
        smsText: smsText ?? '',
        sharedWith,
        departments: departmentIds,
      },
    });
    await setFormIsTouched(false);
    goToSnippets();
  };

  const handleDeleteClick = async () => {
    if (!snippet) {
      return;
    }
    await removeSnippet({ data: snippet });
    setFormIsTouched(false);
    goToSnippets();
  };

  if (isLoadingSnippet) {
    return (
      <FixedBody>
        <LoadingIndicator isLoading={isLoadingSnippet} height='300px' />
      </FixedBody>
    );
  }

  if (!isCreate && !snippet) {
    return <NotFound />;
  }

  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return formIsTouched;
        }}
        title='Close Template Without Saving?'
        text='This template is not saved yet.'
        confirmButtonText='Keep Editing'
        discardButtonText='Yes, Close'
        showDiscardIcon={false}
        showSaveIcon={false}
      />
      <FixedBody>
        <>
          <Header>
            <LinkWrapper>
              <LinkBtn
                data-cy='template-close-button'
                onClick={() => goToSnippets()}
              >
                <MdClose size='18' />
                <span>Close</span>
              </LinkBtn>
            </LinkWrapper>
            <Actions>
              {!isCreate && (
                <Button
                  variant='destructive'
                  text='Delete'
                  icon={
                    <MdDeleteForever
                      color={theme.palette.primaryRedDanger}
                      size={24}
                    />
                  }
                  onClick={() => setShowDeleteModal(true)}
                />
              )}
              <Button
                data-cy='template-save-button'
                disabled={!snippetValid || title.length > 100 || loading}
                variant='primary'
                text='Save Template'
                icon={<MdCheck />}
                onClick={async () => {
                  return isCreate
                    ? await createSnippet()
                    : await updateSnippet();
                }}
              />
            </Actions>
          </Header>
          <ContentWrapper>
            <LeftContent>
              <Label>Title</Label>
              <Input
                data-cy='template-title-text'
                onChange={(e: any) => {
                  setTitle(e.target.value);
                  handleFormChange();
                }}
                value={title}
                margin={
                  title && title.length > 100 ? '0 0 15px 0' : '0 0 32px 0'
                }
                placeholder='Title'
              />
              {/* SUS-796 changes */}
              {title && title.length > 100 && (
                <ErrorInfo>
                  <span>Title can not contain more than 100 characters. </span>
                </ErrorInfo>
              )}
              {!!email && (
                <>
                  <Label>Subject</Label>
                  <Input
                    data-cy='template-subject-text'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSubject(e.target.value);
                      handleFormChange();
                    }}
                    value={subject}
                    margin={
                      title && title.length > 100 ? '0 0 15px 0' : '0 0 32px 0'
                    }
                    placeholder='Subject'
                  />
                </>
              )}
              <InputGroup padding={'24px'} disabled={!email}>
                <SwitchInputWrapper>
                  <div>
                    <Switch
                      id={'switch-1'}
                      isOn={email}
                      onColor={themes.colors.primary[100]}
                      handleToggle={() => {
                        setEmail(prevEmail => !prevEmail);
                        handleFormChange();
                      }}
                    />
                  </div>
                  <span>Email Template</span>
                </SwitchInputWrapper>
                <TextEditor
                  dataCy='template-email-textarea'
                  height={'160px'}
                  onTextEditorChange={handleEmailTextChange}
                  initialContent={emailText ?? snippet?.emailText}
                  toolbar={emailEditorToolbar}
                  showFilesButton={droplrAccess}
                  showMagicButton={true}
                  magicHoverPopup={<LaunchPopup />}
                  setSubject={setSubject}
                />
              </InputGroup>
              <InputGroup padding={'24px'} disabled={!sms}>
                <SwitchInputWrapper>
                  <div>
                    <Switch
                      id={'switch-2'}
                      isOn={sms}
                      onColor={themes.colors.primary[100]}
                      handleToggle={() => {
                        setSms(prevSms => !prevSms);
                        handleFormChange();
                      }}
                    />
                  </div>
                  <span>SMS Template</span>
                </SwitchInputWrapper>
                <TextEditor
                  dataCy='template-sms-textarea'
                  height={'160px'}
                  onTextEditorChange={handleSmsTextChange}
                  initialContent={smsText ?? snippet?.smsText}
                  toolbar={smsEditorToolbar}
                  showFilesButton={droplrAccess}
                  showMagicButton={true}
                  magicHoverPopup={<LaunchPopup />}
                  snippetType={SnippetType.Sms}
                />
              </InputGroup>
              <Info>
                Customer might not receive both, if we don't have that contact
                information
              </Info>
            </LeftContent>
            <RightContent>
              <InputGroup>
                <Label margin={'0 0 16px 0'}>Share With</Label>
                {sharedWithOptions.map(
                  (option: { label: string; value: string }, index: number) => (
                    <div
                      key={'radio-input-wrapper-' + index.toString()}
                      style={{ marginBottom: 6 }}
                    >
                      <RadioInput
                        name={'sharedWith'}
                        value={option.value}
                        text={option.label}
                        checkedColor={themes.colors.primary[100]}
                        checked={sharedWith === option.value}
                        onClick={() => handleShareWithClick(option.value)}
                        onChange={() => {}}
                      />
                    </div>
                  )
                )}
                {showDepartmentSelect && (
                  <div style={{ marginTop: 11 }}>
                    <Select
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          minHeight: '40px',
                        }),
                        indicatorSeparator: () => ({ display: 'none' }),
                        option: (styles: any) => {
                          return {
                            ...styles,
                            fontWeight: 600,
                          };
                        },
                      }}
                      isMulti
                      options={departmentOptions}
                      value={departments}
                      onChange={handleDepartmentChange}
                      isSearchable={true}
                      placeholder={'Search for a department'}
                    />
                  </div>
                )}
              </InputGroup>
            </RightContent>
          </ContentWrapper>
        </>
      </FixedBody>
      {showDeleteModal && (
        <ModalDeleteSnippets
          count={1}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickPrimaryButton={handleDeleteClick}
          onClickSecondaryButton={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
});
