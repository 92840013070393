import { theme } from 'lib/style';
import styled from 'styled-components/macro';

type TableProps = {
  isLoading?: boolean;
};

export const TableContainer = styled.div<TableProps>`
  max-height: 400px;
  overflow-y: ${props => (props.isLoading ? `hidden` : `auto`)};

  th {
    text-align: left;
  }

  th:nth-last-of-type(2) {
    div {
      justify-content: right;
    }
  }

  td:first-of-type {
    width: 60%;
  }
`;

export const TableCell = styled.div`
  display: flex;
  align-items: center;

  :last-child {
    padding-right: 10px;
  }
`;

export const CustomerInfoContainer = styled.div`
  margin-left: 10px;
`;

export const CustomerBusiness = styled.div`
  font-size: 16px;
  font-weight: 500;

  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CustomerIdContainer = styled.div`
  color: ${theme.palette.covideoGray60};
  font-size: 14px;
  font-weight: 500;
`;

export const CustomerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type RegionContainerProps = {
  checked: boolean;
};

export const RegionDropdownContainer = styled.div<RegionContainerProps>`
  position: relative;
  margin-right: 84px;
  visibility: ${props => (props.checked ? `visible` : `hidden`)};
`;
