import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { VideoPlayer } from '../../videoPlayer';
import { useVideoDeleteQuery } from 'lib/api/videoDeletes/useVideoDeleteQuery';
import { useUpdateVideoMutation } from 'lib/api/videos/useUpdateVideoMutation';
import {
  AttributesForm,
  Header,
  CountLabel,
} from '../components/AttributesForm';
import { useAuth } from 'lib/context';
import { VideoTags } from '../components/VideoTags';
import { getVideoTags, putVideoTags, Tag, VideoAttribute } from 'lib/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { downloadVideo } from 'lib/api/videosApi';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import RouteLeavingGuard from './RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { AutomotiveAttributesFields } from '../components';
import {
  checkIfVideoRequestFieldsRequired,
  validateAutomotiveFields,
} from 'lib/utils/automotiveRolePermissionChecks';
import Switch from 'app/pages/design/landingPageBuilder/components/Switch';
import {
  MdDeleteForever,
  MdDownload,
  MdEdit,
  MdLock,
  MdOutlineMic,
  MdSave,
} from 'react-icons/md';
import {
  CHeading,
  Heading,
  ParagraphNormalBold,
} from 'lib/components/styles/typography';
import {
  FormGroup,
  InputLabel,
  InputPlaceholder,
  InputPlaceholderText,
  InputWrapper,
  ItemContainer,
  ItemsWrapper,
} from '../styles/layout';
import { checkIfCustomerReactionsEnabled } from 'lib/utils/reactions';
import { isEqual } from 'lodash';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import {
  PackageNameLabel,
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import DeletionInfo from '../components/DeletionInfo';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { useQueryClient } from 'react-query';
import { Button } from 'react-covideo-common';
import { VideoEmailStatus } from 'lib/const/VideoEmailStatus';
import { useEditVideoRequestMutation } from 'lib/api/videoRequests/useEditVideoRequestMutation';
import { useGetVideoAttributes } from 'lib/api/videoAttributes/useVideoAttributesQuery';
import { useEditVideoAttributeByVideoId } from 'lib/api/videoAttributes/useEditVideoAttributeByVideoId';
import { useGetAllVideoAttributesQuery } from 'lib/api/videoAttributes/useGetAllVideoAttributesQuery';
import { calculateDaysLeft } from 'lib/utils/datetime';
import { StopRequestData } from 'lib/api/videoDeletes/types';
import { IoMdClose } from 'react-icons/io';
import { RESELLER_IDS } from 'lib/const';

interface Props {
  videoId: string;
  wtvId?: number;
  videoTitle: string;
  hourOfCreation: string;
  dateOfCreation: string;
  openVideoDeleteModal: Function;
  reactionsPreference: boolean;
  personalThumbnail: string;
  videoSource: string;
  playButtonPosition: string;
  playerBackgroundColor: string;
  playerIconsAndTextColor: string;
  videoReplyPreference: boolean;
  videoRequest: any;
  setVideoIdState: (videoId: string) => void;
  saveVideoTitle: (title: string) => void;
}

export const TitleInput = styled.input`
  ${CHeading}
  border-radius: 4px;
  padding: 0px;
  line-height: normal;
  width: 60%;
  border: solid 1px #e1e2e5;
  &:focus {
    outline: none;
    border: solid 1px #80bdff;
  }
`;

const TitleWrapper = styled.div<{ editTitle?: boolean }>`
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  width: ${props => (props.editTitle ? '75%' : '40%')};
  ${theme.fontBold700}
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  color: ${theme.palette.title};
  line-height: 1.82;
  margin-right: auto;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  .blur {
    min-height: 40px;
    line-height: 1.82;
    max-width: 360px;
    overflow: hidden;
    text-align: left !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .focus {
    min-height: 40px;
    line-height: 1.82;
    border: 1px solid ${theme.palette.secondaryBlue};
    border-radius: 4px;
    max-width: none;
  }
`;

const VideoDeleteContainer = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  width: 380px;
  margin-top: 18px;
`;

const VideoDeleteText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;

const ErrorInfo = styled.div`
  margin-bottom: 14px;
  ${theme.mediaQueryMaxWidth.md} {
    display: none;
  }
  span {
    color: rgb(232, 76, 61);
  }
`;

const VideoSharingSwitchWrapper = styled.div`
  display: block;
  input {
    position: absolute;
  }
`;

function addDays(date: string, days: number) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  const today = new Date();

  if (result.getTime() <= today.getTime()) {
    result = today;
  }

  let d: string = '';
  if (result.getMonth() + 1 < 10) {
    d += '0';
  }
  d += `${result.getMonth() + 1}/`;
  if (result.getDate() < 10) {
    d += '0';
  }
  d += `${result.getDate()}/`;
  d += `${result.getFullYear()}`;

  return d;
}

export const Details = (props: Props) => {
  const { userData } = useAuth();
  const {
    videoTitle,
    videoId,
    wtvId = 0,
    dateOfCreation,
    hourOfCreation,
    openVideoDeleteModal,
    reactionsPreference,
    videoReplyPreference,
    videoSource,
    playButtonPosition,
    playerBackgroundColor,
    playerIconsAndTextColor,
    videoRequest,
    setVideoIdState,
    saveVideoTitle,
  } = props;
  const { mutateAsync: updateVideo } = useUpdateVideoMutation();

  const enabled = userData.customer?.videoDeletionAccess?.toString() === '1';
  const { data: videoDelete } = useVideoDeleteQuery(enabled);

  const queryClient = useQueryClient();
  const { mutateAsync: editVideoRequestMutation } =
    useEditVideoRequestMutation();

  const reactionsCustomerEnabled = checkIfCustomerReactionsEnabled(userData);
  const hasReactions = checkIfFeatureIsEnabled(
    userData,
    productFeature.VIDEO_REPLY
  );

  const { isAutomotiveServiceRole, isAutomotiveSalesRole } = userData;
  const videoRequestFieldsRequired =
    checkIfVideoRequestFieldsRequired(userData);
  let countRequiredFields = 0;
  if (isAutomotiveSalesRole && videoRequestFieldsRequired)
    countRequiredFields = 1;
  if (isAutomotiveServiceRole && videoRequestFieldsRequired)
    countRequiredFields = 2;

  const inputRef = React.useRef<any>(null);
  const [editTitle, setEditTitle] = React.useState(false);
  const [titleValue, setTitleValue] = React.useState(videoTitle);
  const [videoTags, setVideoTags] = React.useState([] as object[]);
  const hideContextMenu = wtvId > 0;
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [formIsTouched, setFormIsTouched] = React.useState(false);
  const [updatedVideoRequest, setUpdatedVideoRequest] = React.useState<any>({});
  const [videoRequestFieldsValid, setVideoRequestFieldsValid] = React.useState(
    !videoRequestFieldsRequired
  );
  const [reactionsEnabled, setReactionsEnabled] =
    React.useState(reactionsPreference);
  const [isDirty, setIsDirty] = React.useState(false);
  const [voiceoverEnabled, setVoiceoverEnabled] = React.useState(true);
  const [videoReplyEnabled, setVideoReplyEnabled] =
    React.useState(videoReplyPreference);
  const [showVideoReplyToggle, setShowVideoReplyToggle] = React.useState(true);
  const history = useHistory();
  const themes = useTheme();

  const { data } = useGetAllVideoAttributesQuery({
    page: 0,
    size: 200, // attempt to fetch all on the first render
    filterByDepartment: true,
  });

  const { attributes: attributesList } = data || {
    attributes: [],
    count: 0,
  };

  const { data: videoAttributes } = useGetVideoAttributes(Number(videoId));
  const videoAttributesList = videoAttributes || []; // list of attribute values for this video

  const { mutateAsync: updateByVideoId } = useEditVideoAttributeByVideoId();

  const loadTags = async () => {
    const data = await getVideoTags({ videoId });
    setVideoTags(
      data.videoTags.map((t: Tag) => ({ value: t.tagId, label: t.tag }))
    );
  };

  const showDeleteButton =
    userData.allowDelete &&
    userData?.resellerId?.toString() !== RESELLER_IDS.MOSS_AUTO_GROUP;
  React.useEffect(() => {
    loadTags();
  }, []);

  React.useEffect(() => {
    setUpdatedVideoRequest(videoRequest);
  }, [videoRequest]);

  React.useEffect(() => {
    validateVideoRequestFields();
  }, [updatedVideoRequest]);

  React.useEffect(() => {
    setVoiceoverEnabled(
      checkIfFeatureIsEnabled(userData, productFeature.VIDEO_VOICEOVER)
    );
    // if feature is enabled && customer lever for video reply is enabled - show toggle on video level
    setShowVideoReplyToggle(
      checkIfFeatureIsEnabled(userData, productFeature.VIDEO_REPLY) &&
        userData.customer.allowReplies?.toString() === '1'
    );
  }, [userData]);

  React.useEffect(() => {
    if (videoTitle !== titleValue) {
      setIsDirty(true);
      return;
    }

    if (reactionsPreference !== reactionsEnabled) {
      setIsDirty(true);
      return;
    }

    if (videoReplyPreference !== videoReplyEnabled) {
      setIsDirty(true);
      return;
    }

    for (const key of Object.keys(updatedVideoRequest)) {
      if (!videoRequest[key] && updatedVideoRequest[key] === '') {
        continue;
      }

      if (videoRequest[key] !== updatedVideoRequest[key]) {
        setIsDirty(true);
        return;
      }
    }
    setIsDirty(false);
  }, [
    videoRequest,
    updatedVideoRequest,
    reactionsEnabled,
    titleValue,
    videoReplyEnabled,
  ]);

  const validateVideoRequestFields = () => {
    setVideoRequestFieldsValid(
      !videoRequestFieldsRequired ||
        (updatedVideoRequest &&
          validateAutomotiveFields(userData, updatedVideoRequest))
    );
  };

  const updateVideoTitle = async (
    newTitle: string,
    showToastMessage: boolean
  ) => {
    if (!newTitle) {
      return;
    }

    const oldTitle = videoTitle;
    if (newTitle !== oldTitle) {
      try {
        await updateVideo({
          videoId,
          videoBody: { title: newTitle },
        });
        if (showToastMessage) {
          successToast({ title: 'Title successfully updated' });
        }
      } catch (ex) {
        if (showToastMessage) {
          errorToast({ title: 'Title update failed' });
        }
      }
    }
  };

  const handleFormTouch = () => {
    setFormIsTouched(true);
  };

  const handleDeleteBtnClick = () => {
    setVideoIdState(videoId);
    openVideoDeleteModal();
  };

  const onUpdateVideoAttributes = async (videoAttributes: any) => {
    setDisableSubmit(true);
    const data = attributesList.map(attribute => ({
      attributeId: attribute.attributeId,
      value: videoAttributes[`attributes${attribute.index}`],
      videoId: Number(videoId),
    }));
    await updateByVideoId({
      videoId,
      data,
    });
    setFormIsTouched(false);
    setDisableSubmit(false);
  };

  const handleDownloadBtnClick = async (e?: React.SyntheticEvent) => {
    if (e) e.stopPropagation();
    const video = await getVideo(videoId).catch(err => err);
    downloadVideo(videoId, `${video.title}.mp4`);
  };

  const handleTitleChange = async (e: any) => {
    // Update title toast message should be hidden if all video details are being saved
    // To not have two toast messages at the same time
    const showToastMessage = !!e;
    const updatedTitleVal =
      showToastMessage || editTitle
        ? titleValue
        : videoTitle; /*** SUS-911 changes ***/
    updateVideoTitle(
      updatedTitleVal,
      showToastMessage
    ); /*** SUS-911 changes ***/
    saveVideoTitle(updatedTitleVal);
    closeEditTitle();
    setFormIsTouched(false);
    setIsDirty(false);
  };

  const openEditTitle = () => {
    setTitleValue(!editTitle ? titleValue : videoTitle);
    setEditTitle(true);
  };

  const closeEditTitle = () => {
    setEditTitle(false);
    setTitleValue(titleValue);
  };

  const onUpdateVideoTags = async (tags: object[]) => {
    const data = await putVideoTags(videoId, tags);

    setVideoTags(
      data.videoTags.map((t: Tag) => ({ value: t.tagId, label: t.tag }))
    );
    loadTags();
  };

  const createInitialFormikValues = () => {
    const initValueObject: any = {};
    attributesList.map((att: VideoAttribute) => {
      const videoAttributeIndex = videoAttributesList.findIndex(
        val => val.attributeId === att.attributeId
      );

      if (att.type === 'dropdown') {
        const defaultValue = att.dropdownValues.find(
          (value: any) => value.default
        );
        if (defaultValue && defaultValue.value) {
          initValueObject[`attributes${att.index}`] =
            videoAttributesList[videoAttributeIndex]?.value ||
            defaultValue.value;
          return (
            videoAttributesList[videoAttributeIndex]?.value ||
            defaultValue.value
          );
        }

        initValueObject[`attributes${att.index}`] = '';
        return '';
      }

      initValueObject[`attributes${att.index}`] =
        videoAttributesList[videoAttributeIndex]?.value || att.default || '';

      return (
        videoAttributesList[videoAttributeIndex]?.value || att.default || null
      );
    });
    return initValueObject;
  };

  const getFormValidations = () => {
    const validations: any = {};
    attributesList.forEach((attr: VideoAttribute) => {
      if (attr && attr.isRequired) {
        validations[`attributes${attr.index}`] = new Yup.string()
          .required('This field can’t be empty')
          .min(1, 'This field can’t be empty');
      }
    });

    return validations;
  };

  const attributesSchema = Yup.object().shape(getFormValidations()).required();

  const onSaveClick = async (values: any) => {
    await onUpdateVideoAttributes(values);
    if (isAutomotiveServiceRole || isAutomotiveSalesRole) {
      await editVideoRequestMutation({
        videoRequestId: videoRequest.videoRequestId,
        data: {
          ...videoRequest,
          ...updatedVideoRequest,
        },
      });
    }
    await updateVideo({
      videoId,
      videoBody: {
        reactionsPreference: reactionsEnabled,
        videoReplyEnabled: videoReplyEnabled,
      },
    });
    queryClient.removeQueries(['video', videoId]);

    setFormIsTouched(false);
  };

  const onToggleReactionsPreference = () => {
    setFormIsTouched(true);
    setReactionsEnabled(prev => !prev);
  };
  const onToggleVideoViewPreference = () => {
    setFormIsTouched(true);
    setVideoReplyEnabled(prev => !prev);
  };

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const showSettingsTitle =
    (reactionsCustomerEnabled && hasReactions) || showVideoReplyToggle;

  const initValueObject = createInitialFormikValues();

  const stopRequest = videoDelete?.stopRequests?.find(
    (sr: StopRequestData) => sr.videoId == parseInt(videoId)
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initValueObject}
      validationSchema={attributesSchema}
      validateOnMount={true}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={async () => {}}
    >
      {({ isValid, isValidating, values }) => (
        <>
          <RouteLeavingGuard
            when={true}
            onConfirm={async () => {
              await handleTitleChange(undefined);
              await onSaveClick(values);
            }}
            navigate={path => history.push(path)}
            shouldBlockNavigation={() => {
              return (
                (formIsTouched || editTitle) &&
                (isDirty || !isEqual(initValueObject, values))
              );
            }}
            title='Leave without saving changes?'
            text='Your updates will not be saved. This action can’t be undone.'
            confirmButtonText='Save changes'
            discardButtonText='Discard Changes'
            disableSave={
              (videoRequestFieldsRequired && !videoRequestFieldsValid) ||
              !isValid ||
              isValidating ||
              !(formIsTouched || editTitle) ||
              !(isDirty || !isEqual(initValueObject, values)) ||
              disableSubmit
            }
          />
          <HeaderWrapper>
            <TitleWrapper editTitle={editTitle}>
              {editTitle ? (
                <TitleInput
                  type='text'
                  autoFocus
                  ref={inputRef}
                  value={titleValue}
                  onChange={(e: any) => setTitleValue(e.currentTarget.value)}
                />
              ) : (
                <Heading>{titleValue}</Heading>
              )}
            </TitleWrapper>
            <Gap>
              {!editTitle && (
                <>
                  <Button
                    variant='secondary'
                    icon={<MdEdit />}
                    onClick={openEditTitle}
                    disabled={
                      videoRequest?.emailStatus === VideoEmailStatus.Deleted
                    }
                  />

                  <Button
                    variant='secondary'
                    icon={<MdDownload size={20} />}
                    onClick={handleDownloadBtnClick}
                  />
                  {showDeleteButton && (
                    <Button
                      variant='destructive'
                      icon={
                        <MdDeleteForever
                          size={18}
                          color={theme.palette.buttonDelete}
                        />
                      }
                      onClick={handleDeleteBtnClick}
                    />
                  )}
                  <ProductInfoTooltip
                    direction='left'
                    hideToolTip={voiceoverEnabled}
                    nextPlan={PackageNameLabel.TEAMS}
                    productFeatureId={productFeature.VIDEO_VOICEOVER}
                    style={{
                      top: '115px',
                      left: '-50px',
                    }}
                  >
                    <Button
                      variant='secondary'
                      text='Make Voiceover'
                      icon={
                        voiceoverEnabled ? (
                          <MdOutlineMic size={20} />
                        ) : (
                          <MdLock size={18} />
                        )
                      }
                      onClick={() =>
                        history.push(`/classic/voiceover/${videoId}`)
                      }
                      disabled={!voiceoverEnabled}
                    />
                  </ProductInfoTooltip>
                  <Button
                    text='Save Changes'
                    icon={<MdSave size={18} />}
                    disabled={
                      (videoRequestFieldsRequired &&
                        !videoRequestFieldsValid) ||
                      !isValid ||
                      isValidating ||
                      !formIsTouched ||
                      !(isDirty || !isEqual(initValueObject, values)) ||
                      disableSubmit
                    }
                    onClick={async () => onSaveClick(values)}
                  />
                </>
              )}
              {editTitle && (
                <>
                  <Button
                    variant='secondary'
                    icon={<IoMdClose size={20} />}
                    onClick={closeEditTitle}
                  />

                  <Button
                    disabled={
                      titleValue.trim() === '' ||
                      titleValue.length > 100 ||
                      titleValue.includes('.')
                    } /* SUS-797 changes */
                    onClick={handleTitleChange}
                    text='Save Title'
                  />
                </>
              )}
            </Gap>
          </HeaderWrapper>
          {/* Added to check if video title is empty or not (SUS-477) */}
          {editTitle && (!titleValue || titleValue.trim() === '') && (
            <ErrorInfo>
              <span>Video Title is required!</span>
            </ErrorInfo>
          )}
          {/* SUS-797 changes */}
          {editTitle && titleValue.length > 100 && (
            <ErrorInfo>
              <span>Video Title can not be more than 100 characters!</span>
            </ErrorInfo>
          )}
          {editTitle && titleValue.includes('.') && (
            <ErrorInfo>
              <span>Video Title can not contain dot(.)!</span>
            </ErrorInfo>
          )}
          <ItemsWrapper>
            <ItemContainer>
              <div style={{ width: 380 }}>
                <FormGroup>
                  <InputWrapper>
                    <InputLabel>Date Created</InputLabel>
                    <InputPlaceholder>
                      <InputPlaceholderText>
                        {dateOfCreation}
                      </InputPlaceholderText>
                    </InputPlaceholder>
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>Time Created</InputLabel>
                    <InputPlaceholder>
                      <InputPlaceholderText>
                        {hourOfCreation}
                      </InputPlaceholderText>
                    </InputPlaceholder>
                  </InputWrapper>
                </FormGroup>
              </div>
              {videoDelete && videoDelete.deleteId && (
                <VideoDeleteContainer>
                  <DeletionInfo
                    bgColor={theme.palette.covideoBlue100}
                    fontColor={theme.palette.white}
                    videoId={videoId}
                    dateOfCreation={dateOfCreation}
                  />
                  {!!videoDelete?.notify && (
                    <VideoDeleteText>
                      Video will be deleted on{' '}
                      {addDays(
                        dateOfCreation,
                        calculateDaysLeft(
                          videoDelete,
                          stopRequest,
                          dateOfCreation
                        )
                      )}{' '}
                      at 11:59PM
                    </VideoDeleteText>
                  )}
                </VideoDeleteContainer>
              )}
              <VideoTags
                videoTags={videoTags}
                onUpdate={onUpdateVideoTags}
                emailStatus={videoRequest?.emailStatus}
              />
              {(isAutomotiveServiceRole || isAutomotiveSalesRole) && (
                <>
                  <Header>
                    Video Attributes
                    <CountLabel>{countRequiredFields} required</CountLabel>
                  </Header>
                  <AutomotiveAttributesFields
                    videoRequest={updatedVideoRequest}
                    onChangeVideoRequestData={setUpdatedVideoRequest}
                    setFormIsTouched={setFormIsTouched}
                    emailStatus={videoRequest?.emailStatus}
                  />
                </>
              )}
              {!!attributesList.length && (
                <AttributesForm
                  onUpdate={onUpdateVideoAttributes}
                  attributes={attributesList}
                  handleFormTouch={handleFormTouch}
                  emailStatus={videoRequest?.emailStatus}
                />
              )}
            </ItemContainer>
            <ItemContainer>
              <VideoPlayer
                playerBackgroundColor={playerBackgroundColor}
                videoSource={videoSource}
                videoId={videoId}
                playButtonPosition={playButtonPosition}
                playerIconsColor={playerIconsAndTextColor}
                videoRef={videoRef}
                hideContext={hideContextMenu}
                height='280px'
              />
              {showSettingsTitle && (
                <ParagraphNormalBold m='32px 0 16px 0'>
                  Video Sharing Settings
                </ParagraphNormalBold>
              )}
              {reactionsCustomerEnabled && hasReactions && (
                <InputWrapper direction='row'>
                  <InputLabel>Enable video reactions & comments</InputLabel>
                  <VideoSharingSwitchWrapper>
                    <Switch
                      id={'switch-reactions'}
                      isOn={reactionsEnabled}
                      onColor={themes.colors.primary[100]}
                      handleToggle={() => onToggleReactionsPreference()}
                    />
                  </VideoSharingSwitchWrapper>
                </InputWrapper>
              )}
              {showVideoReplyToggle && (
                <InputWrapper direction='row' marginTop='16px'>
                  <InputLabel>Enable reply with video</InputLabel>
                  <VideoSharingSwitchWrapper>
                    <Switch
                      id={'switch-video-reply'}
                      isOn={videoReplyEnabled}
                      onColor={themes.colors.primary[100]}
                      handleToggle={() => onToggleVideoViewPreference()}
                    />
                  </VideoSharingSwitchWrapper>
                </InputWrapper>
              )}
            </ItemContainer>
          </ItemsWrapper>
        </>
      )}
    </Formik>
  );
};
