import * as React from 'react';
import styled from 'styled-components/macro';
import selectors from '../../../../../cypress/selectors';
import { theme } from 'lib/style';
import { ModalVideoTags } from 'lib/components/modal/ModalVideoTags';
import { useState } from 'react';
import { VideoTag } from 'lib/components/VideoTag';
import { useAuth } from 'lib/context';
import {
  PackageNameLabel,
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { VideoEmailStatus } from '../../../../../lib/const';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';

type ComponentProps = {
  videoTags: any[];
  onUpdate: Function;
  emailStatus: string;
};

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  text-decoration-line: underline;
  color: #001b53;
  font-weight: 700;

  .disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }
`;

const Content = styled.div`
  width: 100px;
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  ${theme.mediaQueryMinWidth.xs} {
    width: 248px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 380px;
  }
`;

const ManageTagButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  height: 24px;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
  display: flex;
  justify-content: space-between;

  .Tooltip-Wrapper {
    width: auto;
    .Tooltip-Tip {
      left: 220px;
    }
  }
`;

const NoTags = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${theme.palette.midGrey};
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-content: flex-start;
`;

export const VideoTags = ({
  videoTags,
  onUpdate,
  emailStatus,
}: ComponentProps) => {
  const { userData } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [tagDisabled, setTagDisabled] = useState(true);
  videoTags.sort((a, b) => a.label.localeCompare(b.label));
  const isFieldDisabled = emailStatus === VideoEmailStatus.Deleted;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSave = (values: object[]) => {
    onUpdate(values);
    closeModal();
  };

  React.useEffect(() => {
    const tagsStatus = !checkIfFeatureIsEnabled(
      userData,
      productFeature.VIDEO_TAGS
    );
    setTagDisabled(tagsStatus);
  }, []);

  return (
    <Content>
      <Header className='new-display'>
        <span
          style={{
            ...(tagDisabled && { opacity: 0.5, cursor: 'not-allowed' }),
          }}
        >
          Video Tags
        </span>
        {!isFieldDisabled && !tagDisabled ? (
          <Button
            data-cy={selectors.buttons.manageTagsButton}
            onClick={(e: any) => {
              e.preventDefault();
              if (!tagDisabled) {
                openModal();
              }
            }}
          >
            Manage Tags
          </Button>
        ) : (
          <ProductInfoTooltip
            productFeatureId={productFeature.VIDEO_TAGS}
            style={{
              top: '-155px',
              left: '50px',
              width: '280px',
            }}
            nextPlan={PackageNameLabel.TEAMS}
          >
            <ManageTagButtonWrapper>
              <Button
                className={tagDisabled ? 'disabled' : ''}
                style={{
                  opacity: 0.5,
                  cursor: 'not-allowed',
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                }}
              >
                Manage Tags
              </Button>
            </ManageTagButtonWrapper>
          </ProductInfoTooltip>
        )}
      </Header>
      {!videoTags.length && <NoTags>No tags selected</NoTags>}
      {!!videoTags.length && (
        <TagsContainer>
          {!tagDisabled &&
            videoTags.map(tag => <VideoTag key={tag.value} tag={tag} />)}
        </TagsContainer>
      )}
      {showModal && (
        <ModalVideoTags
          videoTags={videoTags}
          handleModalClose={closeModal}
          onSubmit={onSave}
          title={'Manage Tags'}
          buttonTitle={'Save Changes'}
        />
      )}
    </Content>
  );
};
