import { DateRangeSelector, SendReportModal } from '../../../components';
import React, { useState } from 'react';
import { MdEmail, MdFileDownload } from 'react-icons/md';
import { downloadReportData } from 'lib/api';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { successToast } from 'lib/components/toasts/success';
import { Heading } from 'lib/components/styles/typography';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import styled from 'styled-components/macro';
import { useToastError } from 'lib/hooks';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';

type DownloadDataProps = {
  url: string;
  reportTitle?: string;
  data: any;
  from: Date;
  to: Date;
};

type VideoAttributesHeaderProps = {
  title: string;
  downloadData?: DownloadDataProps;
  onDateRangeChange: (startDate: Date, endDate: Date, range: string) => void;
  sendUrl?: string;
};

const VideoAttributesWrapper = styled.div`
  .dropdown-container {
    margin: 0;
  }
`;

const VideoAttributesHeader = ({
  title,
  downloadData,
  sendUrl,
  onDateRangeChange,
}: VideoAttributesHeaderProps) => {
  const [showSendReportModal, setShowSendReportModal] = useState(false);

  const location = useLocation();
  const { showError } = useToastError();

  const downloadReport = () => {
    if (downloadData) {
      try {
        const { url, reportTitle, from, to, data } = downloadData;
        downloadReportData(
          url,
          // eslint-disable-next-line no-useless-concat
          reportTitle || 'video-attributes-report' + '.csv',
          from,
          to,
          [],
          '',
          'POST',
          data
        );

        successToast({ title: 'Successfully downloaded report.' });
      } catch (e) {
        showError(
          'custom-error',
          'There has been an error downloading your report. Please try again.'
        );
      }
    }
  };

  const queryParams: any = queryString.parse(location.search);

  return (
    <VideoAttributesWrapper>
      <Heading m='0 0 32px 0'>{title}</Heading>
      <HeaderWrapper>
        <DateRangeSelector onDateRangeChange={onDateRangeChange} />

        <Gap>
          <Button
            icon={<MdEmail size={20} />}
            onClick={() => setShowSendReportModal(true)}
          />
          <Button
            icon={<MdFileDownload size={20} />}
            onClick={() => downloadReport()}
          />
        </Gap>
      </HeaderWrapper>
      {showSendReportModal && (
        <SendReportModal
          handleModalClose={() => {
            setShowSendReportModal(false);
          }}
          startDate={downloadData?.from || queryParams.start}
          endDate={downloadData?.to || queryParams.end}
          reportUrl={sendUrl || ''}
          range={queryParams.dateRange}
          extraParams={downloadData?.data || {}}
          onError={() =>
            errorToast({
              title:
                'There was an error sending your report. Please try again.',
            })
          }
        />
      )}
    </VideoAttributesWrapper>
  );
};

export default VideoAttributesHeader;
