import React, { useRef } from 'react';
import { MdAttachFile, MdClose } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';

interface AttachmentFieldProps {
  onAttachmentChange: (file: File | null) => void;
  value: File | null;
  error: boolean;
}

export const AttachmentField: React.FC<AttachmentFieldProps> = ({
  onAttachmentChange,
  value,
  error,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    onAttachmentChange(file);
  };

  const handleClearAttachment = () => {
    onAttachmentChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
      {value ? (
        <Gap alignItems='center' gap='8px'>
          <Button
            icon={<MdClose size={20} />}
            onClick={handleClearAttachment}
            variant='secondary'
          />
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: 250,
            }}
          >
            {error ? 'File size is too large' : value.name}
          </span>
        </Gap>
      ) : (
        <Button
          variant='secondary'
          icon={<MdAttachFile size={20} />}
          onClick={() => fileInputRef.current && fileInputRef.current.click()}
        />
      )}
    </div>
  );
};
