import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useAuth } from 'lib/context';
import { RecordHomePage } from './RecordHomePage';
import { useVideosCountQuery } from 'lib/api/videos/useVideosCountQuery';
import { LoadingIndicator } from 'lib/components';

export const RecordingRoutes = () => {
  const { userData } = useAuth();
  const [isRecordAllowed, setRecordAllowed] = useState(true);
  const { data, isLoading } = useVideosCountQuery();

  useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id !== 0) {
        if (
          userPackageDetails.maxVideosCreated !== 0 &&
          (data?.count || 0) >= userPackageDetails.maxVideosCreated
        ) {
          setRecordAllowed(false);
        }
      }
    }
  }, [userData, data]);

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }
  return (
    <Switch>
      <Route
        path='/record/home'
        component={
          isRecordAllowed
            ? RecordHomePage
            : () => <Redirect to='/upgrade-plan' />
        }
      />
    </Switch>
  );
};
