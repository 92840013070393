import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { Button } from 'react-covideo-common';
import selectors from '../../../../../../../../cypress/selectors';
import { IoMdClose } from 'react-icons/io';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 592px;
  padding: 32px;
  background-color: #fff;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  color: ${theme.palette.coal};
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;
const Content = styled.div`
  margin-top: 32px;
`;
const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;
const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 16px;
`;

type Props = {
  handleModalClose: () => void;
  handleSubmit: () => void;
};
export const ModalNoQuotePrompt = ({
  handleModalClose,
  handleSubmit,
}: Props) => {
  return (
    <Modal>
      <ModalItem>
        <Header>
          <Title>Send Without Quote?</Title>
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Content>
          Are you sure you want to send a video with an RO number but without a
          quote?
        </Content>
        <ButtonsWrapper>
          <Button
            text='No, Add Quote'
            onClick={() => handleModalClose()}
            variant='secondary'
          />
          <Button
            text='Yes, Send Without Quote'
            variant='primary'
            onClick={() => handleSubmit()}
            data-cy={selectors.libraryPage.sendAndShareModal.sendWithoutQuote}
          />
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
