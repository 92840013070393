import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

interface NotifyDataResponse {
  success: boolean;
  message: string;
}

interface PostData {
  notify: boolean;
  videoId: string;
  recipientId: number;
}

const fetchVideoViewers = async (
  data: PostData
): Promise<NotifyDataResponse> => {
  const { videoId, recipientId, notify } = data;
  const response = await EXPRESS_API.patch(
    `/videos/${videoId}/metrics/recipients/${recipientId}`,
    { notify }
  );
  return response.data;
};

export const useVideoViewersNotify = () => {
  return useMutation(fetchVideoViewers, {
    onSuccess: () => {
      successToast({ title: 'Video metrics recipients updated successfully' });
    },
    onError: () => {
      errorToast({
        title: 'Failed to update recipients metrics. Please try again later.',
      });
    },
  });
};
