import { CloseButton } from 'lib/components';
import {
  ButtonContainer,
  Content,
  ContentBody,
  ContentHeader,
  ContentHeaderWrap,
  Description,
  ListContainer,
  ListItem,
  ModalItem,
  UserExistLabel,
  UserExistWrapper,
} from 'lib/components/modal/importModal/Components';
import * as React from 'react';
import { Button } from 'react-covideo-common';

type Props = {
  disabled: boolean;
  nonImportedUser: {
    discardedUsers: {
      firstName: string;
      lastName: string;
      email: string;
      failReason: string;
    }[];
    message: string;
  };
  invalidRows: { [key: string]: boolean };
  handleModalClose(shouldRefresh?: boolean): void;
};

export const NonImportedUsers = ({
  disabled,
  nonImportedUser,
  invalidRows,
  handleModalClose,
}: Props) => {
  return (
    <ModalItem>
      <Content style={{ width: '700px' }}>
        <ContentHeaderWrap>
          <ContentHeader>Import Complete</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <Description
            style={{ justifyContent: 'start', background: 'transparent' }}
          >
            {nonImportedUser.message}
          </Description>
          <Description
            style={{
              justifyContent: 'start',
              background: 'transparent',
              paddingLeft: '17px',
            }}
          >
            List of skipped (not imported) users
          </Description>
          <ListContainer>
            {nonImportedUser.discardedUsers.map((user, i: number) => {
              if (invalidRows[i]) return null;
              return (
                <ListItem key={`user-item-${i}`}>
                  <div className='item--label'>
                    <label>{`${user.firstName} ${user.lastName}`}</label>
                  </div>
                  <UserExistWrapper>
                    <UserExistLabel>{user.failReason}</UserExistLabel>
                    <div style={{ color: '#9297A2' }}>{user.email}</div>
                  </UserExistWrapper>
                </ListItem>
              );
            })}
          </ListContainer>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          text='Finish'
          onClick={() => {
            handleModalClose(true);
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );
};
