import React, { useEffect, useState } from 'react';
import VinSolutionsImage from 'assets/images/integrations/vinSolutions.webp';
import edealer from 'assets/images/integrations/edealer.webp';
import autoSweet from 'assets/images/integrations/autoSweet.webp';
import predian from 'assets/images/integrations/predian.webp';
import dealerInspire from 'assets/images/integrations/dealerInspire.webp';
import homenet from 'assets/images/integrations/homeNet.webp';
import vauto from 'assets/images/integrations/vAuto.webp';
import dealerslink from 'assets/images/integrations/dealersLink.webp';
import promax from 'assets/images/integrations/proMax.webp';
import acvmMax from 'assets/images/integrations/acvMax.webp';
import autominer from 'assets/images/integrations/autoMiner.webp';
import vincue from 'assets/images/integrations/dealerCue.webp';
import autorevolution from 'assets/images/integrations/autoRevolution.webp';
import dealerCenter from 'assets/images/integrations/dealerCenter.webp';
import tms from 'assets/images/integrations/tms.webp';
import dealercloud from 'assets/images/integrations/dealerCloud.webp';
import dealercom from 'assets/images/integrations/dealerCom.webp';
import wheelstv from 'assets/images/integrations/wheelsTv.webp';
import credit700 from 'assets/images/integrations/700credit.webp';
import youtube from 'assets/images/integrations/youtube.webp';
import facebook from 'assets/images/integrations/facebook.webp';
import linkedin from 'assets/images/integrations/linkedin.webp';
import lesa from 'assets/images/integrations/lesa.webp';
import vinmotion from 'assets/images/integrations/vinMotion.webp';
import dealercarsearch from 'assets/images/integrations/dealerCarSearch.webp';
import carsforsale from 'assets/images/integrations/carsForSale.webp';
import parse from 'html-react-parser';

import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import {
  ANDROID_INSTALL_LINK,
  CHROME_DOWNLOAD_LINK,
  GMAIL_INSTALL_LINK,
  IntegrationProps,
  IOS_URL,
  LINKEDIN_INSTALL_LINK,
  OFFICE_365_HELP_DESK_LINK,
} from './constants';
import {
  ActionTypes,
  IntegrationDetailsPage,
} from '../details/IntegrationDetailsPage';
import { Zoom } from '../Zoom';
import { Salesforce } from '../Salesforce';
import { useAuth } from 'lib/context';
import {
  checkIfDealerSocketAccessible,
  checkIfHubspotAndElementAccessible,
} from 'lib/utils/automotiveRolePermissionChecks';
import {
  Integration,
  useGetAllIntegrations,
} from 'lib/api/integrations/getAllIntegrations';

export const useIntegrationData = () => {
  const { userData, whitelabel } = useAuth();
  const [integrations, setIntegrations] = useState<IntegrationProps[]>([]);

  /* SUS-1234 changes integrations/dealersocket route are accessible to CDS account with SERVICE_MANAGER, SERVICE_ADVISOR, SALES_MANAGER, TECHNICIAN, SALESPERSON role only and Supervisor CDS user  */
  const isDealerSocketAccessible = checkIfDealerSocketAccessible(userData);

  /* SUS-1234 changes integrations/hubspot and integrations/element451 route are accessible to Supervisor CDS user, Legacy User and standard user with no CDS  */
  const isHubspotAndElementAccessible =
    checkIfHubspotAndElementAccessible(userData);

  /******/
  const isSalesforceFeatureEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.SALESFORCE_INTEGRATION_BASIC
  );

  const { data: integrationData, isLoading: areIntegrationsLoading } =
    useGetAllIntegrations();

  const INTEGRATION = integrationData?.integrations || {};
  const DISCOVER_BY_FILTERS = integrationData?.filters || {};
  const CDS_CATEGORIES = integrationData?.cdsCategories || {};
  const INTEGRATIONS_CATEGORIES = integrationData?.categories || {};

  const IntegrationComponentMapping: object = {
    [INTEGRATION.CHROME]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(CHROME_DOWNLOAD_LINK, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(CHROME_DOWNLOAD_LINK, '"_new"');
      },
    },
    [INTEGRATION.GMAIL]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(GMAIL_INSTALL_LINK, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(GMAIL_INSTALL_LINK, '"_new"');
      },
    },
    [INTEGRATION.LINKEDIN]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          connectSocialProfile
          onClick={() => {
            window.open(LINKEDIN_INSTALL_LINK, '"_new"');
          }}
        />
      ),
      imgSrc: linkedin,
      onClick: () => {
        window.open(LINKEDIN_INSTALL_LINK, '"_new"');
      },
    },
    [INTEGRATION.OFFICE_365]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(OFFICE_365_HELP_DESK_LINK, '_blank', 'noopener');
          }}
        />
      ),
      onClick: () => {
        window.open(OFFICE_365_HELP_DESK_LINK, '_blank', 'noopener');
      },
    },
    [INTEGRATION.OUTLOOK]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.DOWNLOAD}
          onClick={() => {
            window.open(whitelabel.outlookURL, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(whitelabel.outlookURL, '"_new"');
      },
    },
    [INTEGRATION.ZOOM]: {
      component: Zoom,
    },
    [INTEGRATION.FACEBOOK]: {
      component: () => <IntegrationDetailsPage connectSocialProfile />,
      imgSrc: facebook,
    },
    [INTEGRATION.YOUTUBE]: {
      component: () => <IntegrationDetailsPage connectSocialProfile />,
      imgSrc: youtube,
    },
    [INTEGRATION.IOS]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(IOS_URL, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(IOS_URL, '"_new"');
      },
    },
    [INTEGRATION.ANDROID]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(ANDROID_INSTALL_LINK, '"_new"');
          }}
        />
      ),
      onClick: () => {
        window.open(ANDROID_INSTALL_LINK, '"_new"');
      },
    },
    [INTEGRATION.SALESFORCE]: {
      component: Salesforce,
    },
    [INTEGRATION.ELEMENT_451]: {
      component: () => <IntegrationDetailsPage />,
    },
    [INTEGRATION.HUBSPOT]: {
      component: () => (
        <IntegrationDetailsPage
          actionType={ActionTypes.INSTALL}
          onClick={() => {
            window.open(CHROME_DOWNLOAD_LINK, '"_new"');
          }}
        />
      ),
    },
    [INTEGRATION.CREDIT700]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: credit700,
    },
    [INTEGRATION.DEALERSOCKET]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
    },
    [INTEGRATION.VIN_SULUTIONS]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: VinSolutionsImage,
    },
    [INTEGRATION.ELEAD]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
    },
    [INTEGRATION.IPACKET]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
    },
    [INTEGRATION.PROMAX]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: promax,
    },
    [INTEGRATION.TMS]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: tms,
    },
    [INTEGRATION.AUTOMINER]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: autominer,
    },
    [INTEGRATION.EDEALER]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: edealer,
    },
    [INTEGRATION.AUTO_SWEET]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: autoSweet,
    },
    [INTEGRATION.PREDIAN]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: predian,
    },
    [INTEGRATION.DEALER_INSPIRE]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealerInspire,
    },
    [INTEGRATION.HOMENET]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: homenet,
    },
    [INTEGRATION.VAUTO]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: vauto,
    },
    [INTEGRATION.DEALERSLINK]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealerslink,
    },
    [INTEGRATION.JD_POWER]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
    },
    [INTEGRATION.AUTO_VERIFY]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
    },
    [INTEGRATION.INVENTORY_PLUS]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
    },
    [INTEGRATION.ACV_MAX]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: acvmMax,
    },
    [INTEGRATION.VIN_CUE]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: vincue,
    },
    [INTEGRATION.AUTO_REVOLUTION]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: autorevolution,
    },
    [INTEGRATION.DEALER_CENTER]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealerCenter,
    },
    [INTEGRATION.WHEELS_TV]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: wheelstv,
    },
    [INTEGRATION.DEALER_CLOUD]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealercloud,
    },
    [INTEGRATION.DEALER_COM]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealercom,
    },
    [INTEGRATION.LESA]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: lesa,
    },
    [INTEGRATION.VINMOTION]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: vinmotion,
    },
    [INTEGRATION.DEALER_CAR_SEARCH]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: dealercarsearch,
    },
    [INTEGRATION.CARS_FOR_SALE]: {
      component: () => (
        <IntegrationDetailsPage actionType={ActionTypes.CONTACT} />
      ),
      imgSrc: carsforsale,
    },
  };

  useEffect(() => {
    if (!areIntegrationsLoading && !!integrationData) {
      const processedIntegrations = integrationData?.integrationDetails?.map(
        (integration: Integration) => {
          let enabled = true;
          if (integration.name === INTEGRATION.SALESFORCE)
            enabled = isSalesforceFeatureEnabled;

          if (
            integration.name === INTEGRATION.ELEMENT_451 ||
            integration.name === INTEGRATION.HUBSPOT
          )
            enabled = isHubspotAndElementAccessible;

          if (integration.name === INTEGRATION.DEALERSOCKET)
            enabled = isDealerSocketAccessible;

          const { component, imgSrc, onClick } =
            IntegrationComponentMapping[
              integration.name as keyof typeof IntegrationComponentMapping
            ];

          return {
            ...integration,
            key: integration.name.toLowerCase(),
            enabled,
            description: parse(integration.description),
            path: `/integrations/${integration.name.toLowerCase()}`,
            component,
            imgSrc: integration.imgSrc || imgSrc,
            onClick,
          };
        }
      );

      setIntegrations(processedIntegrations);
    }
  }, [integrationData, areIntegrationsLoading]);

  return {
    INTEGRATIONS: integrations,
    DISCOVER_BY_FILTERS,
    CDS_CATEGORIES,
    INTEGRATIONS_CATEGORIES,
    isLoading: areIntegrationsLoading,
  };
};
