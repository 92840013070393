import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';
import {
  ModalAddContact,
  NewContactGroup,
  NewContactProps,
} from 'lib/components/modal/ModalAddContact';
import { anyInMap } from 'lib/utils/object';
import { MdAdd, MdFileDownload } from 'react-icons/md';
import { IoIosLogIn } from 'react-icons/io';
import { useHistory } from 'react-router';

import {
  Table,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  TableContextProvider,
  Search,
} from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { FaChevronRight } from 'react-icons/fa';
import { IoMdOptions } from 'react-icons/io';

import { theme } from 'lib/style';
import { NoContacts } from './components/NoContacts';
import { Button } from 'react-covideo-common';
import { map, includes } from 'lodash';
import { ContactListItem, GroupItems } from 'lib/api/types';
import {
  ContactsHeader,
  ExportContactListItem,
} from './components/ContactsHeader';
import {
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';
import { ContactsBulkUpdateParams } from 'lib/api';
import { exportCSVFile } from 'lib/utils/functions';
import { useEffect, useState } from 'react';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import selectors from '../../../../../cypress/selectors';
import {
  getContacts,
  useContactsQuery,
} from 'lib/api/contacts/useContactsQuery';
import { useCreateContactsMutation } from 'lib/api/contacts/useCreateContactsMutation';
import { useCreateContactMutation } from 'lib/api/contacts/useCreateContactMutation';
import { CreateSingleContactParams } from 'lib/api/contacts/types';
import { useUpdateMultipleContactsMutation } from 'lib/api/contacts/useUpdateMultipleContactsMutation';

const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TableCell = styled.div<{
  width: number;
  cursor?: 'pointer' | 'default' | 'auto';
}>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  cursor: ${props => (props.cursor ? props.cursor : 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

type SelectedContacts = {
  count: number;
  contacts: Map<string, ContactListItem | undefined>;
};

const initialAllSelected: any = {};
const tableName = 'Contacts';
const allTableFields = [
  {
    value: 'email',
    label: 'Email',
    sortValue: 'contact.email',
  },
  {
    value: 'lastName',
    label: 'Last Name',
    sortValue: 'contact.lastName',
  },
  {
    value: 'city',
    label: 'City',
    sortValue: 'contact.city',
  },
  {
    value: 'companyName',
    label: 'Company',
    sortValue: 'contact.companyName',
  },
  {
    value: 'phone',
    label: 'Phone',
    sortValue: 'contact.phone',
  },
  {
    value: 'firstName',
    label: 'First Name',
    sortValue: 'contact.firstName',
  },
  {
    value: 'country',
    label: 'Country',
    sortValue: 'contact.country',
  },
  {
    value: 'website',
    label: 'Website',
    sortValue: 'contact.website',
  },
  {
    value: 'groups',
    label: 'Groups',
    sortValue: 'contact.groups',
  },
];
const initialTableFields = [
  allTableFields[0],
  allTableFields[5],
  allTableFields[1],
  allTableFields[3],
];

export const ContactList = () => {
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [field, setField] = React.useState('');
  const [isAllSelected, setIsAllSelected] = React.useState(initialAllSelected);
  const [tableFields, seTableFields] = React.useState(initialTableFields);
  const [isAllGlobalSelect, setIsAllGlobalSelect] = React.useState(false);

  const onCreateContactSuccess = () => {
    setIsModalAddContactVisible(false);
  };
  const onImportContactSuccess = () => {
    setIsModalImportVisible(false);
  };

  const { mutateAsync: updateContactsBulkMutation } =
    useUpdateMultipleContactsMutation();

  const queryParams = {
    page,
    size,
    sort: field,
    search: searchTerm,
    conversation: true,
  };

  const { mutateAsync: createContactsMutation } = useCreateContactsMutation(
    onImportContactSuccess,
    queryParams
  );

  const { mutateAsync: createContactMutation } = useCreateContactMutation(
    onCreateContactSuccess,
    queryParams
  );

  const {
    data,
    isLoading: loading,
    refetch,
  } = useContactsQuery(queryParams, true);

  const contacts = data?.contacts ?? [];
  const count = data?.count;

  useEffect(() => {
    refetch();
  }, [page, size]);

  const [selectedContacts, setSelectedContacts] =
    React.useState<SelectedContacts>({
      count: 0,
      contacts: new Map<string, ContactListItem | undefined>(),
    });

  React.useEffect(() => {
    if (!isAllSelected[page] && isAllGlobalSelect) {
      const selected = new Map<string, ContactListItem | undefined>();
      setSelectedContacts({
        count: 0,
        contacts: selected,
      });
    } else {
      const selected = new Map<string, ContactListItem | undefined>(
        selectedContacts.contacts
      );
      const isAllSelectedOnPage = isAllSelected[page];
      contacts?.forEach(c => {
        if (isAllSelectedOnPage) {
          selected.set(c.contactId, c);
        } else {
          selected.set(c.contactId, undefined);
        }
      });

      setSelectedContacts({
        count: Array.from(selected.values()).filter(Boolean).length,
        contacts: selected,
      });
    }
  }, [isAllSelected]);

  const selectAll = async () => {
    setIsAllSelected((all: any) => ({ ...all, [page]: !all[page] }));
  };
  const [isModalAddContactVisible, setIsModalAddContactVisible] =
    React.useState(false);
  const [isModalImportVisible, setIsModalImportVisible] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [deselectedContacts, setDeselectedContacts] = useState<any>([]);
  const [loadingForSelectAll, setLoadingForSelectAll] = useState(false);

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const loadTableFields = () => {
    const selectedFields = localStorage.getItem(TABLE_NAME_PREFIX + tableName);
    if (selectedFields) {
      seTableFields(JSON.parse(selectedFields));
    }
  };
  const onSaveCustomTableFields = (selectedFields: any) => {
    seTableFields(selectedFields);
    localStorage.setItem(
      `${TABLE_NAME_PREFIX + tableName}`,
      JSON.stringify(selectedFields)
    );
    setIsModalVisible(false);
  };
  const themes = useTheme();

  React.useEffect(() => {
    loadTableFields();
  }, []);

  const checkIndicator = () => {
    return (
      anyInMap(contacts, selectedContacts.contacts, 'contactId') &&
      !isAllSelected[page]
    );
  };

  const handleUpdateGroupSubmit = async (
    updateContacts: NewContactProps[],
    addedGroupd: NewContactGroup[]
  ) => {
    await updateMultiple(updateContacts, addedGroupd);
  };

  const handleAddContact = async (
    newContacts: object,
    updatedContacts: any,
    updatedGroupsOnlyContacts: any
  ) => {
    if (updatedGroupsOnlyContacts && updatedGroupsOnlyContacts.length) {
      if (updatedContacts && updatedContacts.length) {
        const updatedContactIds = map(updatedContacts, 'contactId');
        updatedGroupsOnlyContacts.forEach((contactData: any) => {
          contactData.isUpdationRequired = includes(
            updatedContactIds,
            contactData.contactId
          )
            ? true
            : false;
        });
      }
      const groups =
        updatedGroupsOnlyContacts && updatedGroupsOnlyContacts.length
          ? updatedGroupsOnlyContacts[0]?.groups
          : [];
      await updateMultiple(updatedGroupsOnlyContacts, groups);
    }

    if (newContacts instanceof Array) {
      await createContactsMutation(newContacts); // Imports multiple contacts from .csv file
    } else {
      await createContactMutation(newContacts as CreateSingleContactParams);
    }
  };

  const updateMultiple = async (contacts: object[], groups: object[]) => {
    const data = {
      contacts: contacts,
      groups: groups,
    };
    await updateContactsBulkMutation(data as ContactsBulkUpdateParams);
  };

  const onDelete = () => {
    setIsAllSelected(initialAllSelected);
    setDeselectedContacts([]);
    setIsAllGlobalSelect(false);
    setSelectedContacts({ count: 0, contacts: new Map() });
  };
  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };

  useEffect(() => {
    if (isAllGlobalSelect) {
      const selected = new Map<string, ContactListItem | undefined>(
        selectedContacts.contacts
      );
      contacts.forEach(c => {
        //check if item is deselected
        if (!deselectedContacts.includes(c.contactId)) {
          selected.set(c.contactId, c);
        }
      });
      setSelectedContacts({
        count: Array.from(selected.values()).filter(Boolean).length,
        contacts: selected,
      });
    }
  }, [contacts]);

  const exportAllContacts = async () => {
    let contactsToExport: ContactListItem[] = [];
    const { contacts: allContacts } = await getContacts({
      page: 0,
      size: count,
    });
    contactsToExport = allContacts || [];
    let exportContactList: ExportContactListItem[] = [];
    contactsToExport.forEach(ele => {
      exportContactList.push({
        ...ele,
        groups: ele?.groups?.map((key: GroupItems) => key.name).join(' | '),
      });
    });

    let exportHeader: object = {};
    tableFields.forEach(field => {
      // @ts-ignore
      exportHeader[field.value] = field.label;
    });
    exportCSVFile(exportHeader, exportContactList, 'selected_contacts');
  };

  const handleGlobalSelectAll = async () => {
    setLoadingForSelectAll(true);
    const result = await getContacts({
      search: '',
      page: 0,
      size: count,
    }).catch(() => null);
    const contacts = result?.contacts || [];
    setIsAllGlobalSelect(true);
    const selected = new Map<string, ContactListItem | undefined>();
    contacts?.forEach(c => {
      selected.set(c.contactId, c);
    });
    setSelectedContacts({
      count: Array.from(selected.values()).filter(Boolean).length,
      contacts: selected,
    });
    setLoadingForSelectAll(false);
  };

  const setCurrentViewContacts = async () => {
    setIsAllGlobalSelect(false);
    const selected = new Map<string, ContactListItem | undefined>();
    contacts?.forEach(c => {
      selected.set(c.contactId, c);
    });
    setSelectedContacts({
      count: Array.from(contacts.values()).filter(Boolean).length,
      contacts: selected,
    });
  };
  // email,lastname,firstname,phone,company
  const columnWidths = [
    24,
    194 - 24,
    99 + 80 - 24,
    83 + 80 - 24,
    83 + 140 - 24,
    83 + 69 - 24,
  ]; //[24]  {/* SUS-796 changes */}
  return (
    <Container>
      <MainWrapper resetPadding>
        <HelpDesk name={EHelpDesk.CONTACTS} />
        <ContactsHeader
          contacts={contacts}
          count={count}
          onDelete={onDelete}
          isAllSelected={isAllSelected[page]}
          checkIndicator={checkIndicator}
          selectAll={selectAll}
          tableFields={tableFields}
          deselected={deselectedContacts.length}
          handleDeselected={() => setDeselectedContacts([])}
          setGlobalSelectAll={handleGlobalSelectAll}
          selectedContacts={selectedContacts}
          setCurrentViewContacts={setCurrentViewContacts}
          setPage={setPage}
          page={page}
          size={size}
        />
        {isModalImportVisible && (
          <ModalAddContact
            modalType='bulk'
            title='Import Contacts'
            text=''
            handleModalClose={() => setIsModalImportVisible(false)}
            handleSubmit={handleAddContact}
            handleUpdateGroupSubmit={handleUpdateGroupSubmit}
          />
        )}
        {isModalAddContactVisible && (
          <ModalAddContact
            modalType='one'
            title='Add Contact'
            text=''
            handleModalClose={() => setIsModalAddContactVisible(false)}
            handleSubmit={handleAddContact}
          />
        )}
        {isModalVisible && (
          <ModalCustomTableFields
            title='Customize Contacts Table'
            onClose={() => setIsModalVisible(false)}
            allFields={allTableFields}
            initialFields={tableFields}
            actionButtons={[
              {
                onClick: onSaveCustomTableFields,
                text: 'Update',
              },
            ]}
          />
        )}

        <>
          {loadingForSelectAll || (loading && contacts.length == 0) ? (
            <LoadingIndicator
              isLoading={loading}
              text={
                loadingForSelectAll
                  ? 'Selecting All Contacts...'
                  : 'Loading Contacts...'
              }
              height={400}
            />
          ) : !loading && contacts.length == 0 && !searchTerm ? (
            <>
              <NoContacts handleAddContact={handleAddContact} />
            </>
          ) : (
            <Layout>
              <Row style={{ marginBottom: 20 }}>
                <div style={{ width: 300 }}>
                  <Search
                    dataCy={selectors.contactsPage.searchField}
                    prevSearch={searchTerm}
                    placeholder='Search Contacts...'
                    onSearch={(value: string) => handleSearch(value)}
                  />
                </div>
                <Row style={{ gap: 12 }}>
                  <Button
                    onClick={() => setIsModalVisible(true)}
                    text=''
                    icon={<IoMdOptions width={20} />}
                    variant='ghost'
                  />
                  <Button
                    onClick={() => setIsModalImportVisible(true)}
                    text='Import'
                    icon={<IoIosLogIn />}
                    variant='ghost'
                  />
                  <Button
                    onClick={() => exportAllContacts()}
                    text='Export All'
                    icon={<MdFileDownload />}
                    variant='ghost'
                  />
                  <Button
                    variant='primary'
                    onClick={() => setIsModalAddContactVisible(true)}
                    text='Create'
                    icon={<MdAdd color={themes.colors.white[100]} />}
                  />
                </Row>
              </Row>
              <TableContextProvider
                total={count}
                onChange={onPaginationChange}
                initPage={page}
                initSize={size}
              >
                <div style={{ overflowX: 'auto' }}>
                  <Table
                    dataCy={selectors.contactsPage.contactsTable}
                    compact={true}
                    headers={[
                      '',
                      ...tableFields.map((item, index) => {
                        return (
                          <TableCell
                            width={columnWidths[index + 1]}
                            onClick={() => {
                              if (
                                item.value == 'firstName' ||
                                item.value == 'lastName'
                              ) {
                                if (field.length && field[0] == '-') {
                                  setField(item.sortValue);
                                } else if (field.length && field[0] != '-') {
                                  setField('-' + item.sortValue);
                                } else {
                                  setField(item.sortValue);
                                }
                              }
                            }}
                          >
                            {item.label}
                          </TableCell>
                        );
                      }),
                      <TableCell width={columnWidths[tableFields.length]} />,
                    ]}
                    hoverable={false}
                    columnWidths={columnWidths}
                    rows={contacts.map((contact: any, index: number) => {
                      return {
                        key: index,
                        columns: [
                          <CheckboxInput
                            width='24px'
                            blueCheck={true}
                            checked={
                              !!selectedContacts.contacts.get(contact.contactId)
                            }
                            onChange={(e: React.SyntheticEvent) => {
                              e.stopPropagation();
                              let { checked } = e.target as HTMLInputElement;
                              let selected = new Map<
                                string,
                                ContactListItem | undefined
                              >(selectedContacts.contacts);
                              let count = selectedContacts.count;
                              if (checked) {
                                selected.set(contact.contactId, contact);
                                count++;
                              } else {
                                setDeselectedContacts([
                                  ...deselectedContacts,
                                  contact.contactId,
                                ]);
                                selected.set(contact.contactId, undefined);
                                count--;
                              }
                              setSelectedContacts({
                                count: count,
                                contacts: selected,
                              });
                            }}
                          />,

                          ...tableFields.map((item, index) => {
                            let groupName: string = '';
                            groupName =
                              contact?.groups && contact?.groups.length
                                ? contact?.groups
                                    ?.map((key: GroupItems) => key.name)
                                    .join(', ')
                                : '-';
                            let contactvalue = contact[item.value];
                            if (item.value === 'groups') {
                              contactvalue = groupName;
                            }
                            return (
                              <TableCell
                                width={columnWidths[index + 1]}
                                onClick={() => {
                                  history.push(
                                    '/contacts/list/' + contact.contactId
                                  );
                                }}
                              >
                                {
                                  // @ts-ignore
                                  contactvalue
                                }
                              </TableCell>
                            );
                          }),
                          <TableCell
                            style={{ textAlign: 'right' }}
                            cursor={'default'}
                            width={columnWidths[tableFields.length] - 24}
                          >
                            <FaChevronRight
                              size={13}
                              onClick={() => {
                                history.push(
                                  '/contacts/list/' + contact.contactId
                                );
                              }}
                            />
                          </TableCell>,
                        ],
                        onClick: () => {},
                      };
                    })}
                  />
                </div>
                {contacts.length === 0 && (
                  <p
                    data-cy={selectors.contactsPage.contactsNoResults}
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      margin: '36px 0',
                    }}
                  >
                    No matching results found.{' '}
                  </p>
                )}

                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew globalName='contact_list' />
                </TableFooter>
              </TableContextProvider>
            </Layout>
          )}
        </>
      </MainWrapper>
    </Container>
  );
};
