import { useAuth } from 'lib/context';
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MdGroup, MdForum } from 'react-icons/md';
import { IoMdChatboxes } from 'react-icons/io';
import { SidebarNavigation } from 'lib/components/SidebarNavigation';
import { SmsOverview } from './smsOverview';
import { Users } from './users';
import { Messages } from './messages';
import { Chats } from './chats';
import { SmsDisabled } from './smsOverview/SmsDisabled';
import { productFeature } from 'lib/utils/productFeature';
import { NotFoundTemplate } from '../notFound';
import { checkIfSMSoverviewAndSMSusersAccessible } from 'lib/utils/automotiveRolePermissionChecks'; /* SUS-1234 changes */

export const SmsRoutes = () => {
  const [isSmsDisabled, setIsSmsDisabled] = React.useState(false);
  const iconSize = '24px';

  const { userData } = useAuth();
  const smsEnabled = !!userData?.customer?.smsEnabled;

  React.useEffect(() => {
    if (userData) {
      const packageDetails = userData.user?.packageDetails;
      if (packageDetails && packageDetails.id && packageDetails.products) {
        setIsSmsDisabled(
          !packageDetails.products.includes(productFeature.SMS_ADMIN_CONTROLS)
        );
      }
    }
  }, [userData]);

  const adminItems = [
    {
      title: 'Overview',
      icon: <MdForum size={iconSize} />,
      path: `/sms/overview`,
    },
    {
      title: 'Users',
      icon: <MdGroup size={iconSize} />,
      path: `/sms/users`,
    },
    {
      title: 'Messages',
      icon: <IoMdChatboxes size={iconSize} />,
      path: `/sms/messages`,
    },
  ];

  const userItems = [
    {
      title: 'Messages',
      icon: <IoMdChatboxes size={iconSize} />,
      path: `/sms/messages`,
    },
  ];

  /* SUS-1234 changes sms/user and sms/overview routes are accessible to Access Role Admin */
  const isAdminAccessible = checkIfSMSoverviewAndSMSusersAccessible(userData);
  /******/

  return (
    <>
      {smsEnabled && (
        <SidebarNavigation
          items={isAdminAccessible ? adminItems : userItems}
          hasBack={false}
        />
      )}
      <Switch>
        {isAdminAccessible && (
          <Route
            path='/sms/overview'
            component={
              isSmsDisabled
                ? () => <Redirect to={'/upgrade-plan'} />
                : smsEnabled
                  ? SmsOverview
                  : SmsDisabled
            }
            exact
          />
        )}
        {isAdminAccessible && (
          <Route
            path='/sms/users'
            component={
              isSmsDisabled ? () => <Redirect to={'/upgrade-plan'} /> : Users
            }
            exact
          />
        )}
        <Route
          path='/sms/messages'
          component={
            isSmsDisabled ? () => <Redirect to={'/upgrade-plan'} /> : Messages
          }
          exact
        />
        <Route
          path='/sms/messages/:id'
          component={
            isSmsDisabled ? () => <Redirect to={'/upgrade-plan'} /> : Messages
          }
          exact
        />
        <Route
          path='/sms/chats'
          component={
            isSmsDisabled ? () => <Redirect to={'/upgrade-plan'} /> : Chats
          }
        />
        <Route
          path='/sms/chats/:id'
          component={
            isSmsDisabled ? () => <Redirect to={'/upgrade-plan'} /> : Chats
          }
          exact
        />
        <Route path='/sms' exact>
          <Redirect to='/sms/overview' />
        </Route>
        <Route path='*' component={NotFoundTemplate} />
      </Switch>
    </>
  );
};
