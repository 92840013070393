import React from 'react';
import { theme } from 'lib/style';
import { BsPlayFill } from 'react-icons/bs';
import styled from 'styled-components/macro';
import { Spinner } from 'lib/components';
import useImageLoadRetry from 'lib/hooks/useImageLoadRetry';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';
import { toMMSSTimestamp } from 'lib/utils/functions';
import useVideoLoadRetry from 'lib/hooks/useVideoLoadRetry';

type VideoThumbnailWrapperProps = {
  status?: string;
  thumbnailHeight?: string;
  thumbnailWidth?: string;
};
const VideoThumbnailWrapper = styled.div<VideoThumbnailWrapperProps>`
  position: relative;
  width: ${props => (props.thumbnailWidth ? props.thumbnailWidth : '100%')};
  height: ${props => (props.thumbnailHeight ? props.thumbnailHeight : '69px')};
  border-radius: 5px;
  background: ${theme.palette.black};
  cursor: pointer;
  overflow: hidden;
  opacity: ${props => (props.status === 'deleted' ? 0.5 : 1)};
`;
type VideoThumbnailProps = {
  visible: boolean;
};
const VideoThumbnail = styled.img<VideoThumbnailProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  opacity: ${props => (props.visible ? 1 : 0)};
`;
const VideoPlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.palette.primaryDarkBlue25Percent};
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${theme.palette.red100};
  background-color: ${theme.palette.black};
`;

const VideoDuration = styled.div`
  position: absolute;
  left: 8px;
  bottom: 6px;
  color: ${theme.palette.white};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 2px 4px;
  border-radius: 5px;
  background: ${theme.palette.primaryBlack40Percent};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
`;

type Props = {
  handleClick: () => void;
  thumbnail: string;
  duration?: number;
  videoId: string;
  emailStatus?: string;
  processing?: VideoEncodingStatus;
  thumbnailHeight?: string;
  thumbnailWidth?: string;
  refreshVideoList?: () => void;
};

export const VideoItemThumbnail = ({
  handleClick,
  thumbnail,
  duration,
  videoId,
  emailStatus,
  processing,
  thumbnailHeight,
  thumbnailWidth,
  refreshVideoList,
}: Props) => {
  const prepareTime = (d: number) => {
    return toMMSSTimestamp(d / 1000).split('.')[0];
  };

  const { videoData } = useVideoLoadRetry({
    videoId,
    enabled: processing === VideoEncodingStatus.PROCESSING,
  });

  React.useEffect(() => {
    if (processing !== videoData?.processing && refreshVideoList) {
      refreshVideoList();
    }
  }, [videoData]);

  const {
    imgRef,
    retryCount,
    error: imageLoadError,
  } = useImageLoadRetry({
    src: videoData?.autogeneratedThumbnail || thumbnail,
  });

  const thumbnailAvailable = !imageLoadError && retryCount === 0;

  const newDuration = duration
    ? prepareTime(duration)
    : videoData?.videoLength
      ? prepareTime(videoData?.videoLength)
      : '...';

  const isProcessingFailed =
    processing === VideoEncodingStatus.FAILED ||
    videoData?.processing === VideoEncodingStatus.FAILED;

  const isVideoReady =
    processing === VideoEncodingStatus.READY ||
    videoData?.processing === VideoEncodingStatus.READY;

  const isVideoFinishedProcessing = isProcessingFailed || isVideoReady;

  return (
    <VideoThumbnailWrapper
      onClick={() => handleClick()}
      status={emailStatus}
      thumbnailHeight={thumbnailHeight}
      thumbnailWidth={thumbnailWidth}
    >
      <VideoThumbnail
        src={thumbnail}
        key={retryCount}
        ref={imgRef}
        visible={thumbnailAvailable}
      />
      {isVideoReady && (
        <VideoPlay>
          <BsPlayFill color={theme.palette.white} size={30} />
        </VideoPlay>
      )}
      {!isVideoFinishedProcessing && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      {isProcessingFailed && <Label>Failed</Label>}
      {!isProcessingFailed && duration && (
        <VideoDuration>{newDuration}</VideoDuration>
      )}
    </VideoThumbnailWrapper>
  );
};
