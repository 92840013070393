import { hex } from 'color-convert';

export const InvertColor = hexColor => {
  CheckIfValidColor(hexColor);
  const rgbColor = hex.rgb(hexColor);

  const r = rgbColor[0];
  const g = rgbColor[1];
  const b = rgbColor[2];

  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const a = r * 0.299 + g * 0.587 + b * 0.114 > 130 ? '#000000' : '#FFFFFF';
  return a;
};

const CheckIfValidColor = hex => {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }

  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
};
