import { RepairOrderItem, RepairOrderState } from './types';

export const initialState: RepairOrderState = {
  repairOrders: {},
  order: [],
  count: 0,
  loading: false,
  sentTotal: 0,
  respondedTotal: 0,
  avgCloseRatioItems: 0,
  avgCloseRatioDollars: 0,
};

export const initialRepairOrder: RepairOrderItem = {
  repairOrderId: '',
  videoId: '',
  repairOrderNumber: '',
  status: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  emailSubject: '',
  emailText: '',
  smsText: '',
  sendEmail: false,
  sendQuote: false,
  sendSms: false,
  sendVehicle: false,
  videoRequest: undefined,
  quoteItems: [],
  activity: [],
  user: {},
  advisor: {},
  createdAt: '',
  closeRatio: 0,
  totalPrice: 0,
  totalPriceApproved: 0,
  video: undefined,
  expirationDate: null,
};

export const initialStatistics = {
  sentTotal: 0,
  respondedTotal: 0,
  avgCloseRatioItems: 0,
  avgCloseRatioDollars: 0,
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const RO_REFETCH_INTERVAL = 150000;
