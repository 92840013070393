import { ICombinedUserData } from 'lib/context';

export const checkIfCustomerReactionsEnabled = (
  userData: ICombinedUserData
) => {
  return !!(
    userData.customer?.reactionsPreference &&
    userData.customer.reactionsPreference.toString() !== '0'
  );
};
