type PageDataType = {
  [key: number]: any;
};

export function DigitalPresenceLayout(
  ctas?: object[],
  socialLinks?: object,
  logoUrl?: string
): PageDataType {
  const pageData: PageDataType = {
    1: {
      sectionType: 1,
      sectionDisplayName: 'Header',
      isVisible: true,
      sectionPosition: 'left',
      sectionBackgroundColor: '',
      sectionBackgroundImageUrl: '',
      childSections: [
        {
          sectionDisplayName: 'Logo',
          sectionType: 9,
          isVisible: true,
          sectionPosition: 'left',
          sectionBackgroundColor: '',
          sectionBackgroundImageUrl: '',
          logoUrl,
          logoWidth: '300px',
          logoHeight: '65px',
          childSections: [],
        },
        {
          sectionType: 4,
          sectionDisplayName: 'Social Links',
          isVisible: true,
          elementContainerPosition: 'right',
          sectionPosition: 'right',
          sectionBackgroundColor: '',
          sectionBackgroundImageUrl: '',
          childSections: [],
          socialLinks: socialLinks || {},
          elementProperties: {},
        },
      ],
    },
    2: {
      sectionType: 2,
      sectionDisplayName: 'Video player',
      isVisible: true,
      sectionPosition: 'center',
      sectionBackgroundColor: '',
      sectionBackgroundImageUrl: '',
      isOverrideVideoSettings: false,
      videoUrl: '',
      videoPlayerProperties: {
        playerBackgroundColor: null,
        playerButtonPosition: null,
        playerIconsColor: null,
      },
      childSections: [],
    },
    3: {
      sectionType: 3,
      sectionDisplayName: 'CTAs',
      isVisible: true,
      elementContainerPosition: 'center',
      sectionPosition: 'center',
      sectionBackgroundColor: '',
      sectionBackgroundImageUrl: '',
      childSections: [],
      links: ctas || [],
      elementProperties: {},
      isVertical: false,
    },
    4: {
      sectionDisplayName: 'Text',
      sectionType: 5,
      isVisible: true,
      sectionPosition: 'center',
      sectionBackgroundColor: '',
      sectionBackgroundImageUrl: '',
      textContent: `{{firstname}} {{lastname}}  |  {{email}} {{phone1}}  |  {{phone2}} {{department}}`,
      childSections: [],
      textColor: '',
      isUserContactInfo: true,
    },
  };

  return pageData;

  // 1- Header, 2- VideoPlayer 3- CTA, 4-SocialLinks, 5-Text 6- Image, 7-Video, 8- Links - 9 Logo

  //Section Types 1-Header 2- Video Player 3- cta 4- social-links 5- text -6 video 7-image 8-links
  //SECTION TYPES  1- Header 2-Text 3-Video-PLayer 4-link -5photo (6-socialLinks) buttons
  // Element Types 1-
}

// export default StandardLayout;
