import { Button } from 'react-covideo-common';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import dayjs from 'dayjs';
import {
  Dropdown,
  Search,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { SendReactionsModal } from 'lib/components/modal/SendReactionsModal';
import { VideoReactionType } from 'lib/const/VideoReactionsDictionary';
import { VideoReactionItem } from 'lib/context';
import { theme } from 'lib/style';
import { exportCSVFile, toMMSSTimestamp } from 'lib/utils/functions';
import { getSearchData, prepareExportDataItem } from 'lib/utils/videoReactions';
import React, { useState, useEffect } from 'react';
import { MdDeleteForever, MdEmail, MdFileDownload } from 'react-icons/md';
import styled from 'styled-components/macro';
import { MESSAGES } from 'lib/const/Common';
import { useVideoReactionsQuery } from 'lib/api/videos/useGetVideoReactions';

interface VideoCommentsProps {
  videoId: string;
  handleDeleteVideoReaction: (
    videoId: string,
    reactionId: string,
    type: VideoReactionType
  ) => void;
}

const Comment = styled.div`
  background-color: ${theme.palette.covideoSecondaryButtonBg};
  padding: 12px 12px 15px 12px;
  margin-bottom: 12px;
  width: 903px;
  border-radius: 8px;
  margin-left: 24px;
`;

const CommentText = styled.div`
  width: calc(100% - 95px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CommentTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CommenterContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Commenter = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const Timestamp = styled.div`
  font-size: 14px;
  margin-left: 16px;
  color: ${theme.palette.gray80};
`;

const PlaybackTimestamp = styled.div`
  margin-top: 12px;
  width: 40px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const CommentTimestampContainer = styled.div`
  display: flex;
`;

const Container = styled.div``;

export const VideoComments = ({
  videoId,
  handleDeleteVideoReaction,
}: VideoCommentsProps) => {
  const { data: videoReactions } = useVideoReactionsQuery(videoId);

  const [comments, setComments] = React.useState<VideoReactionItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [selectedComments, setSelectedComments] = useState<VideoReactionItem[]>(
    []
  );
  const [sort, setSort] = useState('');
  const [commentList, setCommentList] = useState<VideoReactionItem[]>(comments);
  const [showSendReportModal, setShowSendReportModal] = useState(false);

  const options = [
    { value: '', label: 'All comments' },
    { value: 'playbackPosition', label: 'By Timestamp' },
    { value: 'createdAt', label: 'By Post Time' },
  ];

  const fieldsToBeSearched = ['playBackPosition', 'createdAt', 'name', 'text'];
  const exportHeaders = {
    playbackPosition: 'Timestamp',
    name: 'Name',
    email: 'Email Address / Phone',
    createdAt: 'Date',
    text: 'Text',
  };

  const onPaginationChange = ({ page, size }: any) => {
    setPage(page);
    setSize(size);
  };

  const prepareInitialData = () => {
    setCommentList(comments);
    setCount(comments.length);
    setSelectedComments(comments.slice(0, size));
  };

  const getData = () => {
    if (!commentList.length) return;
    const commentsArray = getSearchData({
      fieldsToBeSearched,
      searchTerm,
      allData: commentList,
    });

    const start = page ? page * size : page;
    const end = page ? page * size + (size - 1) : size - 1;

    setSelectedComments(commentsArray.slice(start, end));
    setCount(commentsArray.length);
  };

  const exportData = () => {
    const data = selectedComments.map((item: any) =>
      prepareExportDataItem(item)
    );
    exportCSVFile(exportHeaders, data, 'video_comments');
  };

  const getLabelText = (field: string) => {
    return options.find(opt => opt.value === field)?.label;
  };

  const sortData = (field: string) => {
    const allComments = getSearchData({
      fieldsToBeSearched,
      searchTerm,
      allData: commentList,
    });

    switch (field) {
      case 'playbackPosition':
        allComments.sort((a: any, b: any) => {
          return a[field] - b[field];
        });
        break;
      case 'createdAt':
        allComments.sort((a: any, b: any) => {
          return dayjs(b[field]).diff(dayjs(a[field]));
        });
        break;
      default:
        break;
    }

    setCommentList(field ? allComments : comments);
    setSelectedComments((field ? allComments : comments).slice(0, size));
    setPage(0);
  };

  useEffect(() => {
    prepareInitialData();
  }, [comments]);

  useEffect(() => {
    if (videoReactions) {
      let commentsArray: VideoReactionItem[] = [];
      Object.values(videoReactions).forEach(
        reaction =>
          reaction.type === VideoReactionType.COMMENT &&
          commentsArray.push(reaction)
      );
      setComments(commentsArray);
    }
  }, [videoReactions]);

  useEffect(() => {
    getData();
  }, [page, size, searchTerm]);

  useEffect(() => {
    sortData(sort);
  }, [sort]);

  return (
    <TableContextProvider
      total={count}
      initSize={size}
      initPage={page}
      onChange={onPaginationChange}
    >
      <Container>
        <TopRow>
          <InputsContainer>
            <Search
              onSearch={(value: string) => setSearchTerm(value)}
              className='search'
              prevSearch={searchTerm}
              placeholder='Search'
            ></Search>
            <Dropdown
              value={sort}
              defaultValue={sort ? getLabelText(sort) : 'All Comments'}
              placeholder={sort ? getLabelText(sort) : 'All Comments'}
              options={options}
              onChange={sort => {
                setSort(sort.value);
              }}
              width={243}
            ></Dropdown>
          </InputsContainer>

          {!!selectedComments.length && (
            <ButtonsContainer>
              <Button
                onClick={() => setShowSendReportModal(true)}
                text='Send'
                variant='ghost'
                icon={<MdEmail />}
              />
              <Button
                onClick={exportData}
                text='Download'
                variant='ghost'
                icon={<MdFileDownload />}
              />
            </ButtonsContainer>
          )}
        </TopRow>
        {selectedComments.length
          ? selectedComments.map((item: any) => (
              <CommentTimestampContainer key={item.reactionId}>
                <PlaybackTimestamp>
                  {toMMSSTimestamp(item.playbackPosition, 0)}
                </PlaybackTimestamp>
                <Comment>
                  <CommentTitleRow>
                    <CommenterContainer>
                      <Commenter>{item.name}</Commenter>
                      <Timestamp>
                        {dayjs(item.createdAt.toString()).format(
                          `${STANDARD_DATE_FORMAT} hh:mm:ss A`
                        )}
                      </Timestamp>
                    </CommenterContainer>

                    <Button
                      onClick={() => {
                        handleDeleteVideoReaction(
                          videoId,
                          item.reactionId,
                          VideoReactionType.COMMENT
                        );
                      }}
                      text='Delete'
                      variant='destructive'
                      icon={
                        <MdDeleteForever
                          color={theme.palette.primaryRedDanger}
                        />
                      }
                    />
                  </CommentTitleRow>

                  <CommentText>{item.text}</CommentText>
                </Comment>
              </CommentTimestampContainer>
            ))
          : MESSAGES.NO_DATA_FOUND}

        <TableFooter>
          <TablePaginationNew />
          <TablePaginationSizeNew text='Show comments:' />
        </TableFooter>
      </Container>

      {showSendReportModal && (
        <SendReactionsModal
          videoId={videoId}
          type='text'
          handleModalClose={() => setShowSendReportModal(false)}
          data={selectedComments.map((item: any) =>
            prepareExportDataItem(item)
          )}
        ></SendReactionsModal>
      )}
    </TableContextProvider>
  );
};
