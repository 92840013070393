import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { scheduleListKeys } from './scheduleListKeys';

const updateSingleSchedule = async (scheduleId: number) => {
  const response = await EXPRESS_API.delete(`/schedule/${scheduleId}`);
  return response.data;
};

export const useDeleteScheduleMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation(updateSingleSchedule, {
    onSuccess: () => {
      successToast({ title: 'Scheduled item is canceled successfully' });
      queryClient.invalidateQueries(scheduleListKeys.lists());
    },
    onError: (err: AxiosError) => {
      showError(err);
    },
  });
};
