import ThumbsUpIcon from 'assets/images/videoReactions/ThumbsUpIcon';
import ThumbsDownIcon from 'assets/images/videoReactions/ThumbsDownIcon';
import LaughingIcon from 'assets/images/videoReactions/LaughingIcon';
import LoveItIcon from 'assets/images/videoReactions/LoveItIcon';
import ClappingIcon from 'assets/images/videoReactions/ClappingIcon';
import WowIcon from 'assets/images/videoReactions/WowIcon';
import React, { ReactElement } from 'react';
import CommentsIcon from 'assets/images/videoReactions/CommentsIcon';
import CommentsCountIcon from 'assets/images/videoReactions/CommentsCountIcon';

export enum VideoReactionType {
  COMMENT = 1,
  EMOTE = 2,
}

export type VideoReactionsDictionaryType = {
  [key: number]: {
    id: number;
    title: string;
    icon: ReactElement;
    smallIcon: ReactElement;
    type: VideoReactionType;
  };
};

export const VideoReactionsDictionary: VideoReactionsDictionaryType = {
  1: {
    id: 1,
    title: 'Thumbs up',
    icon: <ThumbsUpIcon />,
    smallIcon: <ThumbsUpIcon width={20} height={20} />,
    type: VideoReactionType.EMOTE,
  },
  2: {
    id: 2,
    title: 'Thumbs down',
    icon: <ThumbsDownIcon />,
    smallIcon: <ThumbsDownIcon width={20} height={20} />,
    type: VideoReactionType.EMOTE,
  },
  3: {
    id: 3,
    title: 'Laughing',
    icon: <LaughingIcon />,
    smallIcon: <LaughingIcon width={20} height={20} />,
    type: VideoReactionType.EMOTE,
  },
  4: {
    id: 4,
    title: 'Love it',
    icon: <LoveItIcon />,
    smallIcon: <LoveItIcon width={20} height={20} />,
    type: VideoReactionType.EMOTE,
  },
  5: {
    id: 5,
    title: 'Clapping',
    icon: <ClappingIcon />,
    smallIcon: <ClappingIcon width={20} height={20} />,
    type: VideoReactionType.EMOTE,
  },
  6: {
    id: 6,
    title: 'Wow',
    icon: <WowIcon />,
    smallIcon: <WowIcon width={20} height={20} />,
    type: VideoReactionType.EMOTE,
  },
  7: {
    id: 7,
    title: 'Comments',
    icon: <CommentsIcon />,
    smallIcon: <CommentsCountIcon />,
    type: VideoReactionType.COMMENT,
  },
};
