const USD = 'USD';
const EUR = 'EUR';

const isUSD = (localCurrency: any) => !localCurrency || localCurrency === USD;

const formatPrice = (
  price: any,
  localCurrency: any,
  format: boolean = false
) => {
  let newPrice: any = price;
  if (format)
    newPrice = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(newPrice);
  if (isUSD(localCurrency)) return `$${newPrice}`;
  if (localCurrency === EUR) return `${newPrice}€`;

  return `${newPrice} ${localCurrency}`;
};

const addCurrency = (price: any, currency: any, format: boolean = false) => {
  const localCurrency = currency ? currency.toUpperCase() : undefined;

  return formatPrice(price, localCurrency, format);
};

export const preparePrice = (
  amount: any,
  currency: any,
  format: boolean = false
) => {
  if (!amount && amount !== 0) return 'unknown';
  const price = amount / 100;
  const priceWithCurrency = addCurrency(price, currency, format);
  return priceWithCurrency;
};
