import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { useQuery } from 'react-query';
import { GetWebsiteOverlaysParams, GetWebsiteOverlaysResponse } from '../types';
import { OVERLAY_QUERY } from 'lib/const/SendAndShare';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';

const getWebsiteOverlays = async (
  params: GetWebsiteOverlaysParams
): Promise<GetWebsiteOverlaysResponse> => {
  const response = await EXPRESS_API.get('/overlays', {
    params,
  });
  return response.data;
};

export const useGetOverlays = (
  params: GetWebsiteOverlaysParams,
  enabled: boolean = true
) => {
  const { showError } = useToastError();
  const { userData } = useAuth();
  const isWebsiteOverlayEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.WEBSITE_OVERLAY
  );
  return useQuery([OVERLAY_QUERY], () => getWebsiteOverlays(params), {
    onError: showError,
    enabled: isWebsiteOverlayEnabled && enabled,
    refetchOnMount: true,
  });
};
