import { EXPRESS_API } from 'configs/api/expressApi';

export type GetVideoReactionsParams = {
  videoId: string;
};

export const getVideoReactions = async ({
  videoId,
}: GetVideoReactionsParams) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}/reactions`);
  return response.data;
};

export const deleteVideoReaction = async (
  videoId: string,
  reactionId: string
) => {
  await EXPRESS_API.delete(`/videos/${videoId}/reactions/${reactionId}`);
  return { reactionId };
};

export const sendReactions = async (videoId: string, data: Object) => {
  const response = await EXPRESS_API.post(
    `/videos/${videoId}/reactions/send`,
    data
  );
  return response.data;
};
