export const multilocationKeys = {
  customers_free: (search: string) => ['CUSTOMERS_FREE', search],
  users_free: (search: string, customerId: string) => [
    'USERS_FREE',
    search,
    customerId,
  ],
  all: () => ['ML_CONFIGURATION'] as const,
  list: () => ['ML_CONFIGURATION', 'LIST'] as const,
  search: (search: string) => ['ML_CONFIGURATION', 'LIST', search] as const,
  single: (customerId: string) => ['ML_CONFIGURATION', customerId] as const,
};
