import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { IoIosInformationCircle } from 'react-icons/io';
import { useHistory } from 'react-router';
import { FaCog } from 'react-icons/fa';
import { Button } from 'react-covideo-common';

const PublishNotAvailableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;
  width: 592px;
  background: ${theme.palette.red05};
  border-radius: 5px;
  margin-bottom: 32px;
`;

const IconContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

const MessageTextWrapper = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

export const PublishNotAvailable = () => {
  const history = useHistory();

  const navigateToSocialProfileSettings = () => {
    history.push('/profile/social-profiles');
  };

  return (
    <PublishNotAvailableContainer>
      <MessageContainer>
        <IconContainer>
          <IoIosInformationCircle
            size='20'
            color={theme.palette.red60}
          ></IoIosInformationCircle>
        </IconContainer>
        <MessageTextWrapper>
          In order to publish your video natively to your social media profiles
          (Facebook, YouTube, LinkedIn) you have to{' '}
          <u>connect your social media profiles.</u>
        </MessageTextWrapper>
      </MessageContainer>
      <Button
        text='Open Social Profiles Settings'
        onClick={navigateToSocialProfileSettings}
        icon={<FaCog color={theme.palette.white} />}
      />
    </PublishNotAvailableContainer>
  );
};
