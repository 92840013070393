import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { TextInput, CheckboxInput } from 'lib/components';
import { NAME_REGEX } from 'lib/utils/regexes';

const AddMissingWrapper = styled.div`
  margin-bottom: 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

type InputProps = {
  hasError: boolean;
};

const Input = styled(TextInput)<InputProps>`
  font-family: 'Work Sans';
  width: 372px;
  max-width: 100%;
  margin: 0;
  &:focus {
    border: 1px solid ${theme.palette.gray40};
    box-shadow: none;
  }
  ${props => {
    if (props.hasError) {
      return `border-color: ${theme.palette.red100} !important;`;
    }
  }}
`;

const CheckboxInputWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

type CheckboxLabelProps = {
  disabled: boolean;
};

const CheckboxLabel = styled.label<CheckboxLabelProps>`
  margin-left: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  opacity: ${props => (props.disabled ? '.4' : '1')};
`;

type Props = {
  name: string;
  onChangeName: (name: string) => void;
  lastName: string;
  onChangeLastName: (lastName: string) => void;
  nameValid: boolean;
  updatable: boolean;
  setNameValid: (inputValid: boolean) => void;
  setIsChecked?: (isChecked: boolean) => void;
  isChecked?: boolean;
  checkId?: string;
};
export const AddMissingName = ({
  name,
  onChangeName,
  nameValid,
  lastName,
  onChangeLastName,
  setNameValid,
  isChecked = false,
  updatable,
  setIsChecked = () => {},
  checkId = '',
}: Props) => {
  const validateName = (name: string) => {
    return !!name && NAME_REGEX.test(name);
  };

  const handleChangeName = (value: string) => {
    onChangeName(value);
    setNameValid(validateName(value));
  };

  return (
    <AddMissingWrapper>
      <Row>
        <Input
          onChange={(e: any) => handleChangeName(e.target.value)}
          value={name}
          margin={'0 0 32px 0'}
          placeholder='First Name'
          hasError={false}
        />
        <Input
          onChange={(e: any) => onChangeLastName(e.target.value)}
          value={lastName}
          margin={'0 0 32px 0'}
          placeholder='Last Name'
          hasError={false}
        />
      </Row>
      {updatable && (
        <CheckboxInputWrapper>
          <CheckboxInput
            id={checkId}
            width='24px'
            blueCheck={true}
            checked={isChecked && nameValid}
            onChange={(e: React.SyntheticEvent) => {
              const { checked } = e.target as HTMLInputElement;
              setIsChecked(checked);
            }}
            disabled={!nameValid}
          />
          <CheckboxLabel htmlFor={checkId} disabled={!nameValid}>
            Update Customer details with this info
          </CheckboxLabel>
        </CheckboxInputWrapper>
      )}
    </AddMissingWrapper>
  );
};
