export type CardProps = {
  title: string;
};

export const keyMetricsCardTitles = {
  leads: 'Leads',
  views: 'Views',
  conversionRateWithViews: 'Conversion Rate w/ video',
  conversionRateWithoutViews: 'Conversion Rate w/o video',
};
export const keyMetricsCards: CardProps[] = [
  {
    title: keyMetricsCardTitles.leads,
  },
  {
    title: keyMetricsCardTitles.views,
  },
  {
    title: keyMetricsCardTitles.conversionRateWithViews,
  },
  {
    title: keyMetricsCardTitles.conversionRateWithoutViews,
  },
];

export interface KeyMetricsTableFieldsProps {
  value: string;
  label: string;
}

export const keyMetricsTableFields: KeyMetricsTableFieldsProps[] = [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phone',
    label: 'Phone Number',
  },
  {
    value: 'views',
    label: 'Views',
  },
  {
    value: 'sold',
    label: 'Sold',
  },
  {
    value: 'leadType',
    label: 'Lead Type',
  },
  {
    value: 'leadStatus',
    label: 'Lead Status',
  },
  {
    value: 'leadStatusType',
    label: 'Lead Status Type',
  },

  {
    value: 'source',
    label: 'Lead Source',
  },
  {
    value: 'salesRep',
    label: 'Sales Rep',
  },
];

export const keyMetricsInitialTableFields: KeyMetricsTableFieldsProps[] = [
  keyMetricsTableFields[0],
  keyMetricsTableFields[1],
  keyMetricsTableFields[2],
  keyMetricsTableFields[6],
  keyMetricsTableFields[7],
];
