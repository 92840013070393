import React, { useEffect } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  Area,
  Tooltip,
} from 'recharts';
import styled from 'styled-components/macro';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import WidgetTabs from '../tabs';
import CustomTick from '../customized/CustomTick';
import { calculateTotal } from '../../utils';
import { WidgetWrapper } from './common/styles';
import { useTabs } from './useTabs';
import { EmptyWidget } from '../EmptyWidget';
import dayjs from 'dayjs';
import { getVideoEngagementWidgetStats } from 'lib/api';
import { useQuery } from 'react-query';
import { widget } from './keys';
import { Items, REPORTS_STALE_TIME, WidgetLabels, WidgetValues } from './types';

const WidgetTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #001b53;
  margin: 0 0 12px 0;
`;

const StatsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;

const CurrentViews = styled.p`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9297a2;
  b {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #272a32;
  }
`;

const CustomTooltip = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 #1d1e2414;
  border: solid 1px #f7f8fa;
  background-color: #fff;
`;

const CustomTooltipHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
`;

const CustomTooltipTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  margin: 0;
`;
const CustomTooltipSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #4e5461;

  margin: 0;
`;

const videoEngagementsItems: Items[] = [
  { id: 'views', label: 'Views' },
  { id: 'engagements', label: 'Engagement' },
  { id: 'clicks', label: 'CTA Clicks' },
];

const TooltipChart = ({ active, payload, tab }: any) => {
  if (active && payload?.[0]?.payload) {
    const data: any = payload[0].payload;
    return (
      <CustomTooltip>
        <CustomTooltipHeader>
          {dayjs(data.date).format('MM/DD')}
        </CustomTooltipHeader>
        <CustomTooltipTitle>
          {data.cnt} <CustomTooltipSpan>{tab}</CustomTooltipSpan>
        </CustomTooltipTitle>
      </CustomTooltip>
    );
  }

  return null;
};

const VideoEngagements = ({ params, addDataForExport }: any) => {
  const tabs = useTabs(videoEngagementsItems[0]);

  const { data, isLoading, isRefetching } = useQuery(
    widget.detail(WidgetValues.VIDEO_ENGAGEMENTS, params),
    () => getVideoEngagementWidgetStats({ ...params, users: 'all' }),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const viewsTotal = calculateTotal(data?.views).total;
  const clicksTotal = calculateTotal(data?.clicks).total;

  useEffect(() => {
    if (data) {
      const xmlData = {
        title: WidgetLabels.VIDEO_ENGAGEMENTS,
        data: [
          {
            Views: viewsTotal,
            Engagement: `${data?.engagements?.engagement || 0}%`,
            'CTA Clicks': clicksTotal,
          },
        ],
      };
      addDataForExport(xmlData);
    }
  }, [data]);

  if (isLoading || !data) {
    return <SkeletonLoader imageName='line-report' />;
  }

  const { engagements } = data;

  const getMaxValue = (data: any) => {
    return data.reduce((prev: { cnt: number }, current: { cnt: number }) =>
      prev.cnt > current.cnt ? prev : current
    );
  };

  const transformData = {
    views: {
      total: viewsTotal,
      data: data.views,
    },
    engagements: {
      total: engagements.engagement,
      data: engagements.data,
    },
    clicks: {
      total: clicksTotal,
      data: data.clicks,
    },
  };

  const tabId = tabs.selected.id;
  const total = transformData?.[tabId]?.total;

  return (
    <WidgetWrapper>
      <WidgetTitle>
        Video Engagement {isRefetching && <span>Updating ...</span>}
      </WidgetTitle>
      <WidgetTabs items={videoEngagementsItems} {...tabs} />
      {total === 0 ? (
        <EmptyWidget />
      ) : (
        <>
          <StatsWrapper>
            <CurrentViews>
              {tabId === 'views' && (
                <>
                  {' '}
                  <b>{total}</b> views
                </>
              )}
              {tabId === 'clicks' && (
                <>
                  {' '}
                  <b>{total}</b> clicks
                </>
              )}
              {tabId === 'engagements' && (
                <>
                  {' '}
                  <b>{engagements.engagement}% </b>engagement rate
                </>
              )}
            </CurrentViews>
            {/* TO_DO <CompareViews>
              <Increase /> <b>15.8</b>
              <p>views</p>
            </CompareViews> */}
          </StatsWrapper>
          <AreaChart
            width={408}
            height={125}
            data={transformData?.[tabId]?.data}
            margin={{ left: -20 }}
          >
            <defs>
              <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                <stop stopColor='#FF8B22' stopOpacity={0.3} />
                <stop offset='1' stopColor='#FF8B22' stopOpacity={0} />
              </linearGradient>
              <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                <stop stopColor='#FF8B22' stopOpacity={0.2} />
                <stop offset='1' stopColor='#FF8B22' stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey='date' hide />
            <YAxis
              axisLine={false}
              allowDecimals={false}
              tick={props => (
                <CustomTick
                  {...props}
                  hasPercentage={tabId === 'engagements'}
                />
              )}
              ticks={
                tabId === 'engagements'
                  ? [0, 50, 100]
                  : [0, tabId === 'views' ? getMaxValue(data.views).cnt : total]
              }
            />
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip
              content={props => TooltipChart({ ...props, tab: tabId })}
            />
            <Area
              type='linear'
              dataKey='cnt'
              stroke='#FF8B22'
              strokeWidth={2}
              fillOpacity={1}
              fill='url(#colorUv)'
            />
            <Area
              type='linear'
              dataKey='pv'
              stroke='#FF8B22'
              strokeWidth={2}
              fillOpacity={1}
              fill='url(#colorPv)'
              strokeDasharray='5 5'
            />
          </AreaChart>
        </>
      )}
    </WidgetWrapper>
  );
};

export default VideoEngagements;
