import { useMutation, useQueryClient } from 'react-query';
import { UpdateDropBody } from './types';
import { checkDropAuth, client } from './droplrAuth';
import { omit } from 'lodash';
import { droplrKeys } from './queryKeys';
import { GetDropParams } from './getDrop';

export const editDrop = async (body: UpdateDropBody) => {
  await checkDropAuth();

  return client.drops.update(body.dropId, omit(body, 'dropId'));
};

export const useEditDrop = (invalidationParams?: GetDropParams) => {
  const queryClient = useQueryClient();
  return useMutation((body: UpdateDropBody) => editDrop(body), {
    onSuccess: () => {
      if (invalidationParams) {
        queryClient.invalidateQueries(
          droplrKeys.drop_details(invalidationParams)
        );
      }
    },
    mutationKey: droplrKeys.edit_drop_mutation(),
  });
};
