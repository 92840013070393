import { useMutation, useQueryClient } from 'react-query';
import { GuideDetails } from './guideDetailsQuery';
import { useToastError } from 'lib/hooks';
import { useParams } from 'react-router';
import { successToast } from 'lib/components/toasts/success';
import { guidesKeyes } from './guidesKeyes';
import { EXPRESS_API } from 'configs/api/expressApi';

const editGuide = async (
  data: Partial<GuideDetails>,
  videoId: number
): Promise<void> => {
  await EXPRESS_API.put(`guides/${videoId}`, data);
};

export const useEditGuideMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  const { videoId } = useParams() as { videoId: string };
  const parsedVideoId = Number(videoId);
  return useMutation(
    (data: Partial<GuideDetails>) => editGuide(data, parsedVideoId),
    {
      onError: err => showError(err),
      onSuccess: async () => {
        await queryClient.invalidateQueries(guidesKeyes.details(parsedVideoId));
        queryClient.refetchQueries(guidesKeyes.categorized());
        successToast({ title: 'You have successfully updated the guide!' });
      },
    }
  );
};
