import { IConversation } from 'lib/api/conversations/getConversations';
import {
  CONVERSATION_TYPES,
  ISingleConversation,
} from 'lib/api/conversations/getSingleConversation';
import { InfiniteData } from 'react-query';

export type ModalsType =
  | 'chooseFile'
  | 'reassign'
  | 'add'
  | 'send_transcript'
  | 'send_transcript_closed';
export type SelectedConversationType =
  | CONVERSATION_TYPES.SMS
  | CONVERSATION_TYPES.LIVE_CHAT
  | CONVERSATION_TYPES.EMAIL;

export const enum CONVERSATION_ROUTE_TYPES {
  ALL = 'all',
  UNASSIGNED = 'unassigned',
  UNREAD = 'unread',
  OPEN = 'open',
  CLOSED = 'closed',
  DELETED = 'deleted',
}
export interface MatchParams {
  type: CONVERSATION_ROUTE_TYPES | undefined;
}

export interface MatchParamsWithId extends MatchParams {
  id: string;
}

export enum SEARCH_CONVERSATIONS_ENUMS {
  E_MAIL = 'E-MAIL',
  PHONE = 'PHONE',
  TEXT = 'TEXT',
}

interface IBase {
  setModals: React.Dispatch<React.SetStateAction<false | ModalsType>>;
  modals: false | ModalsType;
  singleConversation: ISingleConversation;
  isLoadingConversation: boolean;
}

export interface IConversationArea extends Omit<IBase, 'singleConversation'> {
  data: InfiniteData<IConversation> | undefined;
  singleConversation: ISingleConversation | undefined;
  dropdownItems: IDropDownItems[];
  isRefetchingSingleConversations: boolean;
}

export interface IDropDownItems {
  value: SelectedConversationType;
  label: string;
}

export interface IFormProps extends IBase {
  onDropdownChangeHandler: (option: IDropDownItems) => void;
  dropdownValue: IDropDownItems | undefined;
  items: IDropDownItems[];
}
export interface IConversationFooter extends IBase {
  setSelectedConversation: React.Dispatch<
    React.SetStateAction<SelectedConversationType>
  >;
  selectedConversation: SelectedConversationType;
  copiedMessage: string | null;
  setCopiedMessage: React.Dispatch<React.SetStateAction<string | null>>;
  dropdownItems: IDropDownItems[];
}

export interface IConversationBody {
  singleConversation: ISingleConversation;
  setSelectedConversation: React.Dispatch<
    React.SetStateAction<SelectedConversationType>
  >;
  setCopiedMessage: React.Dispatch<React.SetStateAction<string | null>>;
  selectedConversation: SelectedConversationType;
}
