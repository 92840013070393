import React from 'react';

import styled, { css } from 'styled-components/macro';
import { MdArrowForwardIos } from 'react-icons/md';
import { HiChevronLeft } from 'react-icons/hi';
import { theme } from 'lib/style';

const ArrowStyles = styled.div<{ direction: string }>`
  display: flex;
  position: absolute;
  top: 50%;
  ${({ direction }) =>
    direction === 'right'
      ? css`
          right: 25px;
        `
      : css`
          left: 25px;
        `};
  height: 50px;
  width: 50px;
  justify-content: center;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  transition: transform ease-in 0.1s;
  &:hover {
    transform: scale(1.1);
  }
  img {
    transform: ${({ direction }) =>
      `translateX(${direction === 'left' ? '-2' : '2'}px);`} 
    &:focus {
      outline: 0;
    }
  }
`;

interface Props {
  direction?: any;
  handleClick?: any;
}
const Arrow = ({ direction, handleClick }: Props) => (
  <ArrowStyles onClick={handleClick} direction={direction}>
    {direction === 'right' ? (
      <MdArrowForwardIos />
    ) : (
      <HiChevronLeft
        color={theme.palette.covideoBlue100}
        width={24}
        height={24}
      />
    )}
  </ArrowStyles>
);

export default Arrow;
