import * as React from 'react';
import styled from 'styled-components/macro';
import Select from 'react-select';
import dayjs from 'dayjs';
import { FaChevronRight } from 'react-icons/fa';
import { fetchCustomerUsers } from 'lib/api';
import {
  Search,
  Table,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  TableContextProvider,
  LoadingIndicator,
} from 'lib/components';

import { orderBy, isNull } from 'lodash';
import { calculateRange } from 'lib/utils/functions';
import { theme } from 'lib/style';
import { AccessRole } from 'lib/const';
import { isMessageRead } from 'lib/utils/time';
import { useAuth } from 'lib/context';
import { useHistory } from 'react-router-dom';
import { useUpdateMessagesMutation } from 'lib/api/messages/useUpdateMessagesMutation';
import { useMessagesQuery } from 'lib/api/messages/useMessagesQuery';

const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
  td {
    height: 80px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TableCell = styled.div<{ width: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  &.class--right {
    display: flex;
    justify-content: end;
  }
  &.margin--height {
    margin-bottom: 25px;
  }
`;

const DateLabel = styled.div`
  color: #9297a2;
  font-size: 14px;
  margin-top: 5px;
`;

const tableFields = [
  {
    value: 'contactPhone',
    label: 'Name + Number',
  },
  {
    value: 'lastMessageText',
    label: 'Last Message',
  },
  {
    value: 'user.firstName',
    label: 'Owner',
  },
  {
    value: 'sentCount',
    label: 'Sent',
  },
  {
    value: 'receivedCount',
    label: 'Received',
  },
  {
    value: 'notAnswered',
    label: 'Not Answered',
  },
];

type MessageListProps = {
  setRangeChange?: React.Dispatch<React.SetStateAction<any>>;
  id?: string | number;
  setChatOwnerName?: React.Dispatch<React.SetStateAction<any>>;
};

export const MessageConatiner = (props: MessageListProps) => {
  const { userData } = useAuth();
  const { customerId } = userData;
  const isAdmin =
    userData.access === AccessRole.ADMIN ||
    userData.access === AccessRole.SUPERVISOR;
  const { setRangeChange, id, setChatOwnerName } = props;
  const defaultOption = isAdmin
    ? { firstName: 'All Users', id: 0 }
    : { firstName: userData.firstName, id: userData.userId };

  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [selectedUser, setSelectedUser] = React.useState<any>(
    id || defaultOption.id
  );
  const [dateSelected, setDateSelected] = React.useState(
    localStorage.getItem('SMS_FILTER') || '7d'
  );
  const [users, setUsers] = React.useState<any>([defaultOption]);

  const columnWidths = [200, 350, 150, 50, 80];
  const dateObj = calculateRange(dateSelected);
  const { mutateAsync: updateChatAsync, isLoading } =
    useUpdateMessagesMutation(dateSelected);
  const history = useHistory();

  const { isLoading: loading, data: messages } = useMessagesQuery({
    size: 100,
    search: search,
    from: dateObj.from,
    userId: isAdmin ? selectedUser : userData.userId,
  });

  const onSearch = (query: string) => {
    setSearch(query.toLowerCase());
    setPage(0);
  };
  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const getFieldValue = (dataSet: any, data: string) => {
    const isUnread = !isMessageRead(
      dataSet.lastReadAt,
      dataSet.lastMessageCreatedAt
    );
    const isUsersChat =
      `${dataSet.userId}` === `${userData.userId}` || dataSet.userId === -1;

    switch (data) {
      case 'sentCount':
      case 'receivedCount':
        return dataSet[data] || '--';
      case 'contactPhone':
        return (
          <span
            style={{
              fontSize: '18px',
              fontWeight: isUnread && isUsersChat ? '800' : 'normal',
            }}
          >
            {`${dataSet.contactFirstName || ''} ${
              dataSet.contactLastName || ''
            }`}
          </span>
        );
      case 'lastMessageText':
        return (
          <span
            style={{ fontWeight: isUnread && isUsersChat ? '800' : 'normal' }}
          >
            {dataSet.lastMessageText ? dataSet.lastMessageText : '--'}
          </span>
        );
      case 'user.firstName':
        return (
          `${!isNull(dataSet.ownerFirstName) ? dataSet.ownerFirstName : ''} 
           ${!isNull(dataSet.ownerLastName) ? dataSet.ownerLastName : ''}` ||
          '--'
        );
      case 'notAnswered':
        return dataSet.userId == -1 ? dataSet.receivedCount : '0';
      default:
        return '--';
    }
  };

  React.useEffect(() => {
    if (setRangeChange) setRangeChange({ action: setDateSelected });
  }, [page, size, search, dateSelected, selectedUser]);

  // React.useEffect(() => {
  //   if (setRangeChange) setRangeChange({ action: setDateSelected });
  // }, [page, size, search, dateSelected, selectedUser]);

  React.useEffect(() => {
    if (!isAdmin) {
      return;
    }
    fetchCustomerUsers(customerId).then((data: any) => {
      const mapArr: any = [{ firstName: 'All Users', id: 0 }];
      setUsers([...mapArr, ...data]);
      if (setChatOwnerName && id) {
        const obj = data.filter((e: any) => e.id == id);
        if (obj.length) setChatOwnerName(obj[0].firstName);
      }
    });
  }, [isAdmin]);

  const start = page * size;

  const allMessages = orderBy(
    messages?.chats,
    function (dateObj: any) {
      return new Date(dateObj.lastMessageCreatedAt);
    },
    ['desc']
  );

  return (
    <Layout>
      <Row style={{ marginBottom: 20 }}>
        {id ? <h3>Messages</h3> : null}
        <div style={{ width: 300 }}>
          <Search
            prevSearch={search}
            placeholder='Search by number or name…'
            onSearch={onSearch}
          />
        </div>
        {isAdmin && !id && (
          <Row>
            <Select
              styles={{
                control: (base: any) => ({
                  ...base,
                  height: '40px',
                  width: '280px',
                }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
              options={users}
              menuPortalTarget={document.body}
              menuPlacement={'bottom'}
              getOptionLabel={option =>
                `${option.firstName} ${option.lastName ? option.lastName : ''}`
              }
              getOptionValue={option => option.id}
              value={users.find((o: any) => {
                return o.id === selectedUser;
              })}
              onChange={(option: any) => {
                setSelectedUser(option.id);
              }}
            />
          </Row>
        )}
      </Row>
      <TableContextProvider
        total={messages?.count}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div style={{ overflowX: 'auto' }}>
          {!loading && messages?.chats?.length ? (
            <>
              <Table
                compact={true}
                headers={[
                  ...tableFields.map((item, index) => {
                    return (
                      <TableCell
                        width={columnWidths[index]}
                        className={
                          item.value == 'notAnswered' ||
                          item.value == 'receivedCount' ||
                          item.value == 'sentCount'
                            ? 'class--right'
                            : ''
                        }
                        onClick={() => {}}
                      >
                        {item.label}
                      </TableCell>
                    );
                  }),
                  <TableCell
                    width={columnWidths[tableFields.length]}
                  ></TableCell>,
                ]}
                hoverable={false}
                columnWidths={columnWidths}
                rows={allMessages
                  .slice(start, start + size)
                  .map((message: any, index: number) => ({
                    key: index,
                    columns: [
                      ...tableFields.map((item, index) => {
                        return (
                          <TableCell
                            width={columnWidths[index]}
                            className={
                              item.value == 'notAnswered' ||
                              item.value == 'receivedCount' ||
                              item.value == 'sentCount'
                                ? 'class--right margin--height'
                                : !['contactPhone', 'lastMessageText'].includes(
                                      item.value
                                    )
                                  ? 'margin--height'
                                  : ''
                            }
                            onClick={() => {}}
                          >
                            {
                              // @ts-ignore
                              // message[item.value]
                              getFieldValue(message, item.value)
                            }
                            <br />
                            {
                              // @ts-ignore
                              item.value == 'lastMessageText' &&
                              message.lastMessageText ? (
                                <DateLabel>
                                  {dayjs(message.lastMessageCreatedAt).format(
                                    'YYYY-MM-DD hh:mm A'
                                  )}
                                </DateLabel>
                              ) : null
                            }
                            {
                              // @ts-ignore
                              item.value == 'contactPhone' &&
                              message.contactPhone ? (
                                <DateLabel>{message.contactPhone}</DateLabel>
                              ) : null
                            }
                          </TableCell>
                        );
                      }),
                      <TableCell
                        style={{ textAlign: 'right', paddingLeft: '25px' }}
                        width={columnWidths[tableFields.length]}
                        className='margin--height'
                      >
                        <FaChevronRight size={13} />
                      </TableCell>,
                    ],
                    onClick: () => {
                      if (isLoading) {
                        return null;
                      }
                      const isUnread = !isMessageRead(
                        message.lastReadAt,
                        message.lastMessageCreatedAt
                      );
                      const isUsersChat =
                        `${message.userId}` === `${userData.userId}` ||
                        message.userId === -1;

                      if (isUnread && isUsersChat) {
                        return updateChatAsync(`${message.chatId}`);
                      }
                      history.push(
                        `/sms/chats/${message.chatId}?range=${dateSelected}`
                      );
                    },
                  }))}
              />
              <TableFooter>
                <TablePaginationNew />
                <TablePaginationSizeNew globalName='sms_user_list' />
              </TableFooter>
            </>
          ) : loading ? (
            <LoadingIndicator isLoading={loading} height='500px' />
          ) : messages?.chats?.length === 0 ? (
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                margin: '36px 0',
              }}
            >
              No matching results found.{' '}
            </p>
          ) : null}
        </div>
      </TableContextProvider>
    </Layout>
  );
};
