import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { servicesKeys } from './servicesKeys';
import { SERVICE } from './types';

interface ServiceMapsResponse {
  success: boolean;
  message: string;
  data: Array<any>;
}

const fetchServicesSubscriptions = async (
  service: SERVICE
): Promise<ServiceMapsResponse> => {
  const response = await EXPRESS_API.get(`/${service}/subscriptions`);
  return response.data;
};

export const useServicesSubcriptionsQuery = (service: SERVICE) => {
  return useQuery(
    servicesKeys.subscriptions(),
    () => fetchServicesSubscriptions(service),
    {
      enabled: false,
    }
  );
};
