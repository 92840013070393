import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import {
  ContactsBulkUpdateParams,
  UpdateMultipleContactsResponse,
} from './types';
import { contactsKeys } from './queryKeys';
import { groupKeys } from '../group/groupKeys';

export const updateMultipleContacts = async (
  data: ContactsBulkUpdateParams
) => {
  const response = await EXPRESS_API.put('/contacts', data);
  return response.data;
};

export const useUpdateMultipleContactsMutation = (
  onSuccess?: () => void,
  id?: string
) => {
  const queryClient = useQueryClient();
  return useMutation(updateMultipleContacts, {
    onSuccess: (data: UpdateMultipleContactsResponse) => {
      successToast({
        title: `Contact${
          data.contacts.length > 1 ? 's' : ''
        } successfully updated!`,
      });
      queryClient.invalidateQueries(contactsKeys.all());
      if (id) {
        queryClient.invalidateQueries(contactsKeys.contact_groups(id));
      }
      queryClient.invalidateQueries(groupKeys.groups());
      onSuccess && onSuccess();
    },
    onError: () => {
      errorToast({
        title: 'An error occurred updating contacts, please try again later!',
      });
    },
  });
};
