import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { Contact, GroupListItem } from '../types';

export const updateGroup = async (data: GroupListItem) => {
  const response = await EXPRESS_API.put(`/groups/${data.groupId}`, data);
  return response.data;
};

export const useUpdateGroupMutation = (
  onSuccess?: (contact: Contact) => void
) => {
  return useMutation(updateGroup, {
    onSuccess: (res: Contact) => {
      successToast({
        title: `Group successfully updated.`,
      });
      onSuccess && onSuccess(res);
    },
    onError: () => {
      errorToast({
        title: 'An error occurred updating group, please try again later!',
      });
    },
  });
};
