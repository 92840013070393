import { useMutation } from 'react-query';
import { useToastError } from 'lib/hooks';
import axios, { CancelToken } from 'axios';
import { useState } from 'react';
import React from 'react';

export interface UploadData {
  url: string;
  file: File;
  contentType?: string;
  id?: number;
}

interface IProgress {
  loaded: number;
  total: number;
  percentage: number;
}

export const DEFAULT_UPLOAD_ID = 0;
export const DEFAULT_UPLOAD_PROGRESS = {
  loaded: 0,
  total: 0,
  percentage: 0,
};
const S3UploadService = async (
  data: UploadData,
  onProgress?: (progress: { [key: string]: IProgress }) => void,
  cancelToken?: CancelToken
): Promise<any> => {
  const { url, file, contentType } = data;
  const isFirefox = navigator?.userAgent?.indexOf('Firefox') !== -1;

  const config = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      if (onProgress) {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        onProgress({
          [data.id || DEFAULT_UPLOAD_ID]: {
            loaded: Math.floor(loaded / 1024),
            total: Math.floor(total / 1024),
            percentage: percentCompleted,
          },
        });
      }
    },
    cancelToken,
  };

  const response = await axios.put(url, file, {
    headers: {
      'content-type': !isFirefox && contentType ? contentType : file.type,
    },
    ...config,
  });

  return response;
};

export const useS3UploadMutation = () => {
  const [uploadToS3Progress, setUploadToS3Progress] = useState<{
    [key: string]: IProgress;
  }>({});
  const { showError } = useToastError();

  const cancelTokenSources = React.useRef<{ [key: string]: any }>({});

  const cancelS3Upload = () => {
    Object.values(cancelTokenSources.current).forEach(cancelSource => {
      cancelSource.cancel('Upload canceled by user.');
    });
    cancelTokenSources.current = {};
  };

  const mutateAsync = useMutation(
    (data: UploadData) => {
      const { id } = data;
      if (id !== undefined) {
        if (cancelTokenSources.current[id]) {
          cancelTokenSources.current[id].cancel('Upload canceled by user.');
        }

        cancelTokenSources.current[id] = axios.CancelToken.source();

        return S3UploadService(
          data,
          setUploadToS3Progress,
          cancelTokenSources.current[id].token
        );
      }
      return S3UploadService(data, setUploadToS3Progress);
    },
    {
      onError: err => showError(err),
    }
  );

  return {
    ...mutateAsync,
    progress: uploadToS3Progress,
    cancelS3Upload,
  };
};
