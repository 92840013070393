import React from 'react';
import { useTheme } from 'styled-components/macro';
import { Label, ToggleContainer, Text } from './styles';
import Switch from '../../design/landingPageBuilder/components/Switch';

interface LabelSwitchProps {
  text?: string;
  onTextClick?: (value: React.SetStateAction<boolean>) => void;
  isOn?: boolean;
  handleToggle: () => void;
  switchId: string;
  margin?: string;
}

export const LabelSwitch = ({
  text,
  onTextClick,
  isOn,
  handleToggle,
  switchId,
  margin,
}: LabelSwitchProps) => {
  const themes = useTheme();
  return (
    <ToggleContainer margin={margin}>
      <Label>
        <Text cursor={'pointer'} onClick={onTextClick}>
          {text}
        </Text>
      </Label>
      <Switch
        id={switchId}
        isOn={isOn}
        onColor={themes.colors.primary[100]}
        handleToggle={handleToggle}
      />
    </ToggleContainer>
  );
};
