import React from 'react';
import { FieldArray, useFormikContext } from 'formik';

import { FormikDropdownField } from '../../../../../lib/components/formik/FormikDropdown';
import { DetailsPreviewRecordFormValues } from '../types';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import { FormikTextAreaField } from 'lib/components/formik/FormikTextAreaField';
import { Stack } from 'lib/components/styles/layout';

enum ATTRIBUTES_TYPES {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  DROPDOWN = 'dropdown',
}

export const DetailsPreviewVideoAttributes = () => {
  const { values } = useFormikContext<DetailsPreviewRecordFormValues>();

  if (!values?.attributes?.length) {
    return null;
  }

  return (
    <Stack width='100%' alignItems='flex-start'>
      <FieldArray name='attributes'>
        {() => {
          return (
            <>
              {values?.attributes?.map((attribute, index: number) => {
                const isRequired = !!attribute.isRequired;
                const isDisabled =
                  !!attribute.isReadOnly || !!attribute.isLocked;

                return (
                  <React.Fragment key={index}>
                    {attribute.type === ATTRIBUTES_TYPES.TEXT && (
                      <FormikInputField
                        name={`attributes.${index}.value`}
                        label={attribute.slug}
                        disabled={isDisabled}
                        isRequired={isRequired}
                      />
                    )}

                    {attribute.type === ATTRIBUTES_TYPES.TEXTAREA && (
                      <FormikTextAreaField
                        name={`attributes.${index}.value`}
                        label={attribute.slug}
                        disabled={isDisabled}
                        isRequired={isRequired}
                      />
                    )}
                    {attribute.type === ATTRIBUTES_TYPES.DROPDOWN && (
                      <FormikDropdownField
                        name={`attributes.${index}.value`}
                        label={attribute.slug}
                        disabled={isDisabled}
                        isRequired={isRequired}
                        options={
                          attribute?.dropdownValues.map(a => ({
                            value: a.value,
                            label: a.value,
                          })) || []
                        }
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </>
          );
        }}
      </FieldArray>
    </Stack>
  );
};
