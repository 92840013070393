import React from 'react';
import styled from 'styled-components/macro';
import { SuperAdminCustomer } from 'lib/context';
import { ChangeCustomerStatusModal } from './ChangeCustomerStatusModal';
import { ChangeCustomerResellerModal } from './ChangeCustomerResellerModal';
import { Button } from 'react-covideo-common';

const ActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const SelectedCustomersActions = ({
  selectedCustomers,
  showResellerButton,
  onSuccessfullUpdate,
}: {
  selectedCustomers: SuperAdminCustomer[];
  showResellerButton: boolean;
  onSuccessfullUpdate(): void;
}) => {
  const [showCustomerResellerPopup, setShowCustomerResellerPopup] =
    React.useState(false);
  const [showCustomerStatusPopup, setShowCustomerStatusPopup] =
    React.useState(false);

  return (
    <>
      <ActionsWrapper>
        {showResellerButton && (
          <Button
            onClick={() => {
              setShowCustomerResellerPopup(true);
            }}
            text='Change Reseller'
            variant='secondary'
          />
        )}
        <Button
          onClick={() => {
            setShowCustomerStatusPopup(true);
          }}
          text='Change Status'
          variant='secondary'
        />
      </ActionsWrapper>
      {showCustomerStatusPopup && (
        <ChangeCustomerStatusModal
          selectedCustomers={selectedCustomers}
          handleModalClose={shouldRefresh => {
            setShowCustomerStatusPopup(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
      {showCustomerResellerPopup && (
        <ChangeCustomerResellerModal
          selectedCustomers={selectedCustomers}
          handleModalClose={shouldRefresh => {
            setShowCustomerResellerPopup(false);
            if (shouldRefresh) onSuccessfullUpdate();
          }}
        />
      )}
    </>
  );
};
