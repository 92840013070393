import { useFormikContext } from 'formik';
import React from 'react';
import { filterTimeframe } from './const';

import { CustomReportModalFormikValues, TIMEFRAME } from './types';
import { FREQUENCY_TYPE } from './frequency/constants';
import { CustomReportFrequency } from './frequency/Frequency';
import { CustomReportPreferedDeliveryTime } from './preferedDeliveryTime/CustomReportPreferedDeliveryTime';
import { Timeframe } from './timeframe/Timeframe';

interface IProps {
  currentRangeValue: string;
  updateRangeValue: (rangeValue: string) => void;
}

const getTimeframeOptions = (frequency: string) => {
  if (frequency.includes(FREQUENCY_TYPE.WEEKLY)) {
    return filterTimeframe(TIMEFRAME, [TIMEFRAME.DAY, TIMEFRAME.CUSTOM]);
  }

  if (frequency.includes(FREQUENCY_TYPE.MONTHLY)) {
    return filterTimeframe(TIMEFRAME, [
      TIMEFRAME.DAY,
      TIMEFRAME.WEEK,
      TIMEFRAME.CUSTOM,
    ]);
  }

  return filterTimeframe(TIMEFRAME, [TIMEFRAME.CUSTOM]);
};

export const RecurringCustomReport = ({
  currentRangeValue,
  updateRangeValue,
}: IProps) => {
  const { values } = useFormikContext<CustomReportModalFormikValues>();
  const timeframeOptions = getTimeframeOptions(values.recurrings.frequency);
  return (
    <>
      <CustomReportFrequency updateRangeValue={updateRangeValue} />
      <CustomReportPreferedDeliveryTime />
      <Timeframe
        currentRangeValue={currentRangeValue}
        updateRangeValue={updateRangeValue}
        timeframeOptions={timeframeOptions}
      />
    </>
  );
};
