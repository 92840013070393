import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';
import { VideoDetails } from './VideoDetails';
import { UserDetails } from './UserDetails';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { REPORT_SCOPE } from 'lib/api';
import { OrganizationPickerPage } from './OrganizationPicker';

export const getReportBaseUrl = (
  reportScope: REPORT_SCOPE = REPORT_SCOPE.RESELLER,
  organizationId?: string
) =>
  reportScope === REPORT_SCOPE.RESELLER
    ? '/reports/companies'
    : `/reports/organization/${
        organizationId ? organizationId : ':organizationId'
      }/companies`;

export const getReportTitle = (
  reportScope: REPORT_SCOPE = REPORT_SCOPE.RESELLER
) =>
  reportScope === REPORT_SCOPE.RESELLER ? 'All Teams' : 'Organization Report';

export const ORGANIZATION_PICKER_TITLE = 'Select Organization';
export const ORGANIZATION_PICKER_LINK = '/reports/organization/';

export const Main = () => {
  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <Switch>
          <Route path={getReportBaseUrl()} component={List} exact={true} />
          <Route
            path={`${getReportBaseUrl()}/:companyId`}
            component={Details}
            exact={true}
          />
          <Route
            path={`${getReportBaseUrl()}/:companyId/users/:userId`}
            component={UserDetails}
            exact={true}
          />
          <Route
            path={`${getReportBaseUrl()}/:companyId/users/:userId/videos/:videoId`}
            component={VideoDetails}
            exact={true}
          />
          <Route
            path={`/report/organization`}
            component={OrganizationPickerPage}
            exact={true}
          />
          <Route
            path={`${getReportBaseUrl(REPORT_SCOPE.ORGANIZATION)}`}
            component={() => <List reportScope={REPORT_SCOPE.ORGANIZATION} />}
            exact={true}
          />
          <Route
            path={`${getReportBaseUrl(REPORT_SCOPE.ORGANIZATION)}/:companyId`}
            component={(props: any) => (
              <Details {...props} reportScope={REPORT_SCOPE.ORGANIZATION} />
            )}
            exact={true}
          />
          <Route
            path={`${getReportBaseUrl(
              REPORT_SCOPE.ORGANIZATION
            )}/:companyId/users/:userId`}
            component={(props: any) => (
              <UserDetails {...props} reportScope={REPORT_SCOPE.ORGANIZATION} />
            )}
            exact={true}
          />
          <Route
            path={`${getReportBaseUrl(
              REPORT_SCOPE.ORGANIZATION
            )}/:companyId/users/:userId/videos/:videoId`}
            component={(props: any) => (
              <VideoDetails
                {...props}
                reportScope={REPORT_SCOPE.ORGANIZATION}
              />
            )}
            exact={true}
          />
        </Switch>
      </MainWrapper>
    </Container>
  );
};
