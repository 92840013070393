import { PageHeader } from 'app/pages/account/userAccount/addOns/components/PageElements';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from './Modal';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

enum optionSelections {
  None = 0,
  SMS2500 = 1,
  SMS5k = 2,
  SMS7k = 3,
}

enum subscriptionTypes {
  SMS = 1,
  Captions = 2,
  Files = 3,
  LandingPageBuilder = 4,
}

const ModalBody = styled.div`
  width: 592px;
  height: 216px;
`;

const ModalHeader = styled(PageHeader)`
  font-size: 18px;
  padding-right: 32px;
  margin-bottom: 16px;
  justify-content: space-between;
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const ModalMessage = styled.div`
  margin: 0 32px 0 32px;
  font-size: 14px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 48px;
  margin-right: 32px;
`;

export const ModalConfirmSubscription = ({
  handleModalClose,
  handleModalConfirm,
  oldSelection,
  newSelection,
  subscriptionType,
}: any) => {
  const { ref } = useOnClickOutside(handleModalClose);

  const domain = window.location.hostname;
  const captions_cost = domain === 'app.moov.ie' ? 25 : 20;

  const upgrade = oldSelection < newSelection;
  const cancel = newSelection === optionSelections.None;

  let subscription_amount = '0';
  let subscription_cost = '0';
  let subscription_name = '';

  switch (subscriptionType) {
    case subscriptionTypes.SMS:
      subscription_name = 'SMS';

      switch (newSelection) {
        case optionSelections.SMS2500:
          subscription_amount = '2,500';
          subscription_cost = '$25.00';

          break;
        case optionSelections.SMS5k:
          subscription_amount = '5,000';
          subscription_cost = '$50.00';

          break;
        case optionSelections.SMS7k:
          subscription_amount = '7,500';
          subscription_cost = '$75.00';

          break;
      }

      break;
    case subscriptionTypes.Captions:
      subscription_name = 'Captions';
      subscription_amount = newSelection;
      let discount =
        newSelection < 10
          ? 0
          : newSelection >= 10 && newSelection < 25
            ? 1
            : newSelection >= 25 && newSelection < 50
              ? 2
              : 3;
      subscription_cost =
        (domain === 'app.moov.ie' ? '£' : '$') +
        (newSelection * captions_cost - 5 * discount) +
        '.00';
      break;
    case subscriptionTypes.Files:
      subscription_name = 'Files';
      subscription_amount = newSelection;
      subscription_cost =
        '$' +
        (newSelection < 5 ? newSelection * 10 : newSelection * 10) +
        '.00';
      break;
    case subscriptionTypes.LandingPageBuilder:
      subscription_name = 'Landing Page Builder';
      subscription_amount = 'the';
      subscription_cost = '$25.00';
      break;
  }

  const header_text = cancel
    ? 'You will lose all access to ' + subscription_name
    : 'Confirm ' +
      (upgrade ? 'upgrading' : 'downgrading') +
      ' your subscription';
  const body_text = cancel
    ? 'You will lose access to ' +
      subscription_name +
      ' for you and your team once your new billing cycle begins. You can reactivate it at any point.'
    : 'The plan you’ve selected contains ' +
      subscription_amount +
      ' ' +
      subscription_name +
      ' for you and your team and costs ' +
      subscription_cost +
      ' per month.';
  const submit_text = cancel
    ? 'Confirm Deactivation'
    : upgrade
      ? 'Confirm Upgrade'
      : 'Confirm Downgrade';

  return (
    <Modal>
      <div ref={ref}>
        <ModalBody>
          <ModalHeader>
            {header_text}
            <CloseButtonWrapper>
              <IoMdClose size={24} onClick={handleModalClose} />
            </CloseButtonWrapper>
          </ModalHeader>
          <ModalMessage>{body_text}</ModalMessage>
          <ButtonsWrapper>
            <Button
              variant={upgrade ? 'primary' : cancel ? 'red' : 'secondary'}
              text={submit_text}
              onClick={handleModalConfirm}
            />
          </ButtonsWrapper>
        </ModalBody>
      </div>
    </Modal>
  );
};
