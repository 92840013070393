import { REPORT_GROUP_TYPE } from 'lib/const/ReportData';
import React from 'react';
import { useTheme } from 'styled-components/macro';
import { ParagraphExtraSmallBold } from '../styles/typography';
import { SingleAvailableField } from './SingleAvailableField';
import { AvailableGroupListProps } from './types';

const getFieldTitle = (name: string) => {
  switch (name) {
    case REPORT_GROUP_TYPE.POPULAR:
      return 'Most Popular';
    case REPORT_GROUP_TYPE.COVIDEO:
      return 'Sending from Covideo';
    case REPORT_GROUP_TYPE.SERVICE:
      return 'Service Related';
    default:
      return '';
  }
};

export const AvailableGroupFieldsList = ({
  allFields,
  isMaxFieldReached,
  selectedFields,
  onAddClickHandler,
}: AvailableGroupListProps) => {
  const { colors } = useTheme();
  return (
    <>
      {Object.keys(allFields).map((groupField, idx) => (
        <React.Fragment key={idx}>
          <ParagraphExtraSmallBold
            color={colors.neutral[60]}
            m={idx === 0 ? '0px 0 8px 0' : '16px 0 8px 0'}
            style={{ textTransform: 'uppercase' }}
          >
            {getFieldTitle(groupField)}
          </ParagraphExtraSmallBold>
          {allFields[groupField as REPORT_GROUP_TYPE].map(field => {
            const isSelected = selectedFields.some(
              item => item.value === field.value
            );

            const isSelectionOrMaxReached = isSelected || isMaxFieldReached;
            return (
              <SingleAvailableField
                key={field.value}
                onAddClickHandler={onAddClickHandler}
                isSelectionOrMaxReached={isSelectionOrMaxReached}
                isSelected={isSelected}
                field={field}
              />
            );
          })}
        </React.Fragment>
      ))}
    </>
  );
};
