import { timeRangeConstants } from 'app/pages/reports/components';
import dayjs from 'dayjs';
import { LoadingIndicator } from 'lib/components';
import { SwitchToClassicDesktop } from 'lib/components/page/SwitchToClassic';
import { Topbar } from 'lib/components/page/Topbar';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { NotFoundTemplate } from 'app/pages/notFound';
import { useAuth } from 'lib/context/auth';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { AccessRole } from 'lib/const';
import { useSignIntoLocation } from 'lib/api/locations/signIntoLocation';
import { useGetLocationsQuery } from 'lib/api/locations/getUserLocations';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { Button } from 'react-covideo-common';
import { getClassicLayoutVisibility } from 'lib/utils/functions';

const containerPadding = 112;

const Layout = styled.div`
  min-height: 100vh;
  background-color: ${theme.palette.gray10};
`;

const LocationSwtichContainer = styled.div`
  padding-top: 64px;
`;

const PageBody = styled.div`
  padding-top: 64px; // compensate header height
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const PageHeading = styled.div`
  font-weight: 800;
  font-size: 24px;
  color: ${theme.palette.covideoGray100};
  margin-top: 32px;
`;

const LocationsContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${containerPadding}px;
  gap: 32px;
  margin-bottom: 112px; // 64 + 48

  ${theme.mediaQueryMinWidth.sm} and ${theme.mediaQueryMaxWidth.lg} {
    padding: 0 60px;
  }
`;

type LocationCardProps = {
  hasManageUsers: boolean;
};

const LocationCard = styled.div<LocationCardProps>`
  background-color: white;
  width: 280px;
  height: ${props => (props.hasManageUsers ? `276px` : `246px`)};
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
`;

const LocationTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${theme.palette.covideoBlue100};
  text-align: left;
  line-height: 24px;
`;

const SubtitleContainer = styled.div`
  margin-top: 8px;
`;

const LocationSubtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-top: 8px;
  color: ${theme.palette.covideoGray80};
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`;

export const LocationSelect = () => {
  const { userData } = useAuth();

  const { data: locations, isLoading } = useGetLocationsQuery(
    userData.isMultiLocation,
    userData.id
  );
  const [isLoggingIntoLocation, setIsLoggingIntoLocation] = useState(false);
  const [navigateTo, setNavigateTo] = useState({
    path: '',
    locationId: 0,
  });
  const location = useLocation(); // from router

  const { mutateAsync: signIn } = useSignIntoLocation();

  const isNotFound = location.pathname === '/not-found';

  const customer = userData.customer;
  let isClassicLayoutVisible = false;

  if (!isNotFound) {
    isClassicLayoutVisible = getClassicLayoutVisibility(customer);
  }

  const { width } = useWindowSize();
  let locationContainerStyle = {};
  const containerWidth = width - 2 * containerPadding;
  if (containerWidth <= locations.length)
    locationContainerStyle = { justifyContent: 'center' };
  else locationContainerStyle = { justifyContent: 'left' };

  const queryParams = {} as any;
  const queryDateFormat = STANDARD_DATE_FORMAT;
  if (navigateTo.path.includes('reports')) {
    queryParams.start = dayjs().format(queryDateFormat);
    queryParams.end = dayjs().format(queryDateFormat);
    queryParams.range = timeRangeConstants.LAST_7_DAYS;
  }

  useEffect(() => {
    if (!!navigateTo.path && !!navigateTo.locationId) onLocationChange();
  }, [navigateTo]);

  const onLocationChange = async () => {
    try {
      setIsLoggingIntoLocation(true);
      const url = isEmpty(queryParams)
        ? navigateTo.path
        : `${navigateTo.path}?${queryString.stringify(queryParams)}`;

      await signIn({ locationId: navigateTo.locationId });
      window.location.assign(url);
    } catch (e) {
      console.log(e);
      setIsLoggingIntoLocation(false);
    }
  };

  const canNavigateToManageUsers =
    checkIfFeatureIsEnabled(userData, productFeature.MANAGE_USERS_AS_TEAMS) &&
    (userData.access === AccessRole.ADMIN ||
      userData.access === AccessRole.SUPERVISOR);

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} height='500px' />;
  }

  if (!locations.length) {
    window.location.assign('/home');
    return <></>;
  }

  if (isLoggingIntoLocation) {
    return (
      <LocationSwtichContainer>
        <LoadingIndicator
          color={theme.palette.covideoBlue100}
          text='Logging into location, please wait...'
          isLoading={isLoggingIntoLocation}
        />
      </LocationSwtichContainer>
    );
  }

  if (userData && userData.customer && !userData.isMultiLocation) {
    return <NotFoundTemplate />;
  }

  return (
    <Layout>
      <Topbar showNavigation={false} />
      <PageBody>
        <PageHeading>Your Locations</PageHeading>
        <LocationsContainer style={locationContainerStyle}>
          {locations.map(location => (
            <LocationCard
              hasManageUsers={canNavigateToManageUsers}
              key={location.locationId}
              onClick={() => {
                setNavigateTo({
                  path: '/home',
                  locationId: location.locationId,
                });
              }}
            >
              <div>
                <LocationTitle>{location.name}</LocationTitle>
                <SubtitleContainer>
                  <LocationSubtitle>{location.streetAddress}</LocationSubtitle>
                  <LocationSubtitle>{location.state}</LocationSubtitle>
                </SubtitleContainer>
              </div>
              <ButtonContainer>
                <Button
                  text='Video Library'
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setNavigateTo({
                      path: '/home',
                      locationId: location.locationId,
                    });
                  }}
                  variant='secondary'
                />
                <Button
                  text='Reports'
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setNavigateTo({
                      path: '/reports/data',
                      locationId: location.locationId,
                    });
                  }}
                  variant='secondary'
                />
                {canNavigateToManageUsers && (
                  <Button
                    text='Manage Users'
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setNavigateTo({
                        path: '/users/list',
                        locationId: location.locationId,
                      });
                    }}
                    variant='secondary'
                  />
                )}
              </ButtonContainer>
            </LocationCard>
          ))}
        </LocationsContainer>
      </PageBody>

      <SwitchToClassicDesktop
        isVisible={isClassicLayoutVisible}
        isModalOpen={false}
      />
    </Layout>
  );
};
