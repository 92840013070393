import React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { Dropdown, NewModal } from 'lib/components';
import { updateMultipleUsersAutomotiveRole } from 'lib/api';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { automotiveRoleOptions } from 'lib/const/AutomotiveRole';
import { Button } from 'react-covideo-common';
import { InformationBox } from 'lib/components/InformationBox';

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

const InfoDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedUsers: number[];
  selectedAutomotiveRole?: number;
  changeRoleInfoText?: string;
};

type Option = {
  value: number;
  label: string | number;
};

type DefaultOption = {
  automotiveRole: number;
  business: string;
};

export const ChangeAutomotiveRoleModal = ({
  handleModalClose,
  selectedUsers,
  selectedAutomotiveRole = 0,
  changeRoleInfoText,
}: Props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [automotiveRole, setUserAutomotiveRole] = useState<number>(0);
  const [defaultData, setDefaultData] = React.useState<DefaultOption>({
    automotiveRole: selectedAutomotiveRole,
    business: 'Select a Role',
  });

  const usersLength = selectedUsers.length;

  const headerText = `Assign/Change Automotive Role for ${
    usersLength
  } user${usersLength > 1 ? 's' : ''}!`;

  const handleUpdateUsersAutomotiveRole = async () => {
    setLoadingModal(true);
    try {
      await updateMultipleUsersAutomotiveRole(selectedUsers, automotiveRole);
    } catch (error) {
      errorToast({ title: `Error in updating users Automotive Role.` });
      handleModalClose(1);
      return;
    }
    handleModalClose(1);
    usersLength &&
      successToast({
        title: `Automotive Role updated successfully for ${
          usersLength
        } user${usersLength > 1 ? 's' : ''}!`,
      });
    setLoadingModal(false);
  };

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Content>
        <InfoDropdownWrapper>
          {changeRoleInfoText && <InformationBox text={changeRoleInfoText} />}
          <Dropdown
            menuPortalTarget={document.body}
            menuPosition='fixed'
            menuPlacement={'bottom'}
            creatable={false}
            dropdownHeight={300}
            options={automotiveRoleOptions}
            value={automotiveRoleOptions.find((o: any) => {
              return o.value == defaultData.automotiveRole;
            })}
            placeholder='Select role'
            onChange={(option: Option) => {
              setDefaultData({
                ...defaultData,
                automotiveRole: option.value,
              });
              setUserAutomotiveRole(option.value);
              setLoadingModal(false);
            }}
            isLoading={loadingModal}
            onMenuClose={() => setLoadingModal(false)}
            className='option-container'
          />
        </InfoDropdownWrapper>
        <SubmitButtonWrapper>
          <Button
            text={'Save Changes'}
            onClick={() => {
              handleUpdateUsersAutomotiveRole();
            }}
            disabled={loadingModal}
          />
        </SubmitButtonWrapper>
      </Content>
    </NewModal>
  );
};
