import styled from 'styled-components/macro';
import { theme } from 'lib/style';

interface TextInputProps {
  margin?: string;
  background?: string | null;
  width?: string;
  dataCy?: string;
}
export const TextInput = styled.input.attrs<TextInputProps>(props => ({
  type: props.type || 'text',
  'data-cy': props.dataCy,
}))<TextInputProps>`
  display: block;
  width: ${props => props.width || '100%'};
  max-width: 800px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: ${props => props.background || '#fff'};
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin: ${props => props.margin || ''};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &:disabled {
    border: 0;
    cursor: not-allowed;
    background-color: #f6f7f9;
  }
`;

export const NewTextInput = styled.input.attrs({
  type: 'text',
})<any>`
  display: block;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  &::placeholder {
    font-size: 16px;
    color: ${theme.palette.black_1_100};
    opacity: 0.6;
    ${theme.fontNormal500}
  }
  border: ${({ error }) =>
    error
      ? `1px solid ${theme.palette.primaryRedDanger}`
      : 'solid 1px #dfe3e6'};
  height: 40px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
  }
`;
