import { useEffect, useRef, useState } from 'react';

export const useRecordingTime = () => {
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      // Clean up on component unmount
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
      }
    };
  }, []);

  const startRecordingTime = () => {
    recordingIntervalRef.current = setInterval(() => {
      setTimeInSeconds(prevTime => prevTime + 1);
    }, 1000);
  };

  const stopRecordingTime = () => {
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
    }
  };

  return { timeInSeconds, startRecordingTime, stopRecordingTime };
};
