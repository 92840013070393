import * as React from 'react';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  Dropdown,
  LoadingIndicator,
  NewModal,
  TextInput,
} from 'lib/components';
import {
  IntegrationCustomer,
  integrations,
  VinsolutionDealer,
  INTEGRATIONS_TYPE,
} from '../../../app/pages/admin/integrations/Main';
import { MdPeople } from 'react-icons/md';
import { CRMAssignUserModal } from './CRMAssignUserModal';
import {
  connectELead,
  connectVinSolution,
  connectDealersocket,
} from '../../api/crmIntegrationsApi';
import Switch from '../../../app/pages/design/landingPageBuilder/components/Switch';
import { successToast } from '../toasts/success';
import { Button } from 'react-covideo-common';
import { useGetVinsolutionUsersQuery } from 'lib/api/crmIntegrations/getVinsolutionUsers';

const AuthorizeWrapper = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 32px 0;
  margin-bottom: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  margin-top: auto;
  margin-bottom: auto;
`;

type Props = {
  handleModalClose: () => void;
  onSubmit: () => void;
  customer: IntegrationCustomer;
  vinsolutionDealers: VinsolutionDealer[];
  integrationType: number;
};

export const CRMIntegrationModal = ({
  handleModalClose,
  onSubmit,
  customer,
  vinsolutionDealers,
  integrationType,
}: Props) => {
  const themes = useTheme();

  const [assignUserModal, setAssignUserModal] = useState(false);
  const [dealerAdmin, setDealerAdmin] = useState(
    !!customer.vinSolutionsDealer
      ? customer.vinSolutionsDealer?.adminVinUserId
      : 0
  );

  const [selectedDealer, setSelectedDealer] = useState(
    !!customer.vinSolutionsDealer ? customer.vinSolutionsDealer?.dealerId : 0
  );
  const [isEleadEnabled, setIsELeadEnabled] = useState(!!customer.eLeadEnabled);
  const [dealerSocketId, setDealerSocketId] = useState(
    !!customer.dealerSocketId
      ? `${customer.dealerSocketId}_${customer.dealerSocketFranchiseId}`
      : ''
  );
  const [isLoading, setIsLoading] = useState(false);
  const { data: vinsolutionDealerUsers, isLoading: usersLoading } =
    useGetVinsolutionUsersQuery(
      selectedDealer || 0,
      integrationType === INTEGRATIONS_TYPE.VINSOLUTIONS
    );

  const handleAssignUserModal = async () => {
    successToast({
      title: `User connected`,
    });
    setAssignUserModal(false);
  };

  const handleEleadIntegrationChange = () => {
    setIsELeadEnabled(!isEleadEnabled);
  };

  const handleEleadSubmit = async () => {
    await connectELead(
      {
        eleadEnabled: Number(isEleadEnabled),
      },
      customer.customerId
    ).catch(error => console.log(error));
    successToast({
      title: `${customer.business} connected with eLead Integration`,
    });
    onSubmit();
  };

  const handleVinsolutionConnect = async (showToast: boolean = true) => {
    await connectVinSolution(
      {
        dealerId: selectedDealer,
        vinSolutionsEnabled: true,
        adminVinUserId: !!dealerAdmin ? dealerAdmin : null,
      },
      customer.customerId
    ).catch(error => console.log(error));
    setIsLoading(false);
    if (showToast) {
      successToast({
        title: `${customer.business} connected with dealer ID: ${selectedDealer}`,
      });
    }
  };

  const handleDealerSocketSubmit = async () => {
    const dealerSocket = dealerSocketId.split('_');
    if (dealerSocket.length !== 2) {
      return;
    }

    await connectDealersocket(
      customer.customerId,
      true,
      dealerSocket[0],
      dealerSocket[1]
    ).catch(error => console.log(error));
    successToast({
      title: `${customer.business} connected with Dealersocket Integration`,
    });
    onSubmit();
  };

  const switchToAssignUsersModal = async () => {
    setIsLoading(true);
    await handleVinsolutionConnect(false);
    setAssignUserModal(true);
  };

  return (
    <>
      {assignUserModal && !isLoading && (
        <CRMAssignUserModal
          handleModalClose={() => setAssignUserModal(false)}
          handleModalSave={handleAssignUserModal}
          data={customer}
          vinsolutionDealerUsers={vinsolutionDealerUsers?.users}
        />
      )}
      {!assignUserModal && (
        <NewModal
          closeModalOnBackdropOrEscPress={true}
          closeModal={() => handleModalClose()}
          headerText={customer.business}
        >
          <div>
            <Row>
              <div style={{ flex: 1 }}>
                <Label>CRM Integration</Label>
                <Dropdown
                  placeholder={'Select CRM Integration'}
                  value={integrations.find(o => o.value == integrationType)}
                  options={integrations}
                  onChange={() => {}}
                  disabled={true}
                />
              </div>
            </Row>
            {integrationType === INTEGRATIONS_TYPE.VINSOLUTIONS && (
              <>
                <Row>
                  <div
                    style={{
                      marginTop: 30,
                      flex: 1,
                    }}
                  >
                    <Label>Dealership</Label>
                    <Dropdown
                      placeholder={'Select Dealership'}
                      options={vinsolutionDealers}
                      dropdownHeight={
                        vinsolutionDealers.length > 4 ? 220 : 'auto'
                      }
                      onChange={(option: VinsolutionDealer) => {
                        setSelectedDealer(option.dealerId);
                      }}
                      getOptionValue={option => option.dealerId}
                      getOptionLabel={option => option.name}
                      value={vinsolutionDealers.find(
                        o => o.dealerId == selectedDealer
                      )}
                    />
                  </div>
                </Row>
                <Row>
                  <div
                    style={{
                      marginTop: 30,
                      flex: 1,
                    }}
                  >
                    <Label>Lead Owner</Label>
                    <Dropdown
                      placeholder={'Select Lead Owner'}
                      options={vinsolutionDealerUsers?.users}
                      dropdownHeight={
                        vinsolutionDealerUsers?.users?.length > 4 ? 220 : 'auto'
                      }
                      onChange={option => {
                        setDealerAdmin(option.vinUserId);
                      }}
                      getOptionValue={option => option.vinUserId}
                      getOptionLabel={option =>
                        option.firstName + ' ' + option.lastName
                      }
                      value={vinsolutionDealerUsers?.users?.find(
                        (o: any) => o.vinUserId == dealerAdmin
                      )}
                    />
                  </div>
                </Row>
              </>
            )}

            {integrationType === INTEGRATIONS_TYPE.ELEAD && (
              <>
                <Row>
                  <div
                    style={{
                      marginRight: 12,
                      marginTop: 30,
                      flex: 1,
                    }}
                  >
                    <ToggleContainer>
                      <Switch
                        id={'switch-integrations'}
                        isOn={isEleadEnabled}
                        onColor={themes.colors.primary[100]}
                        handleToggle={() => {
                          handleEleadIntegrationChange();
                        }}
                      />
                    </ToggleContainer>
                  </div>
                </Row>
                <Footer>
                  <Button
                    variant='primary'
                    text={'Save Changes'}
                    onClick={handleEleadSubmit}
                  />
                </Footer>
              </>
            )}
            {integrationType === INTEGRATIONS_TYPE.VINSOLUTIONS && (
              <Footer>
                <AuthorizeWrapper>
                  <Button
                    onClick={() => switchToAssignUsersModal()}
                    icon={<MdPeople />}
                    text={'Assign Users'}
                    variant='secondary'
                    disabled={!selectedDealer}
                  />
                </AuthorizeWrapper>
                <Button
                  onClick={async () => {
                    await handleVinsolutionConnect();
                    onSubmit();
                  }}
                  text={'Save Changes'}
                  type='submit'
                  disabled={!selectedDealer}
                  variant='primary'
                />
              </Footer>
            )}
            {integrationType === INTEGRATIONS_TYPE.DEALERSOCKET && (
              <>
                <Row>
                  <div
                    style={{
                      marginTop: 30,
                      flex: 1,
                    }}
                  >
                    <Label>DealerSocket ID</Label>

                    <TextInput
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setDealerSocketId(e.target.value);
                      }}
                      value={dealerSocketId}
                      margin={'0 0 32px 0'}
                      placeholder='DealerSocketID should be written in xxxx_x format'
                    />
                  </div>
                </Row>
                <Footer>
                  <Button
                    text={'Save Changes'}
                    onClick={handleDealerSocketSubmit}
                    variant='primary'
                  />
                </Footer>
              </>
            )}
          </div>
        </NewModal>
      )}
      {(isLoading || usersLoading) && (
        <LoadingIndicator isLoading={isLoading || usersLoading} zIndex={999} />
      )}
    </>
  );
};
