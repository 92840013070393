import { EXPRESS_API } from 'configs/api/expressApi';

export type GetInventoryParams = {
  search?: string;
  start?: number;
  limit?: number;
  sort?: string;
  soldFilter?: string;
  videosRecordedFilter?: string;
  countRecorded?: boolean;
};

export const getInventoryItems = async (params: GetInventoryParams) => {
  const response = await EXPRESS_API.get(`/inventory`, { params });
  return response.data;
};

export const getInventoryItem = async (itemId: string) => {
  const response = await EXPRESS_API.get(`/inventory/${itemId}`);
  return response.data;
};
