import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { RadioInput } from 'lib/components'; //IoIosAdd
import Switch from '../Switch';
import Select from 'react-select';
import { EnhancedColorPicker } from '../../../../video/videoDetails/components';
import { ColorResult } from 'react-color';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { playButtonPositionOptions, ResetIcon } from '../Constants';
import { useLandingPageBuilderContext } from '../../context';

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

interface TextProps {
  fontWeight?: number;
  fontSize?: string;
}

interface RadioButtonsWrapperProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
}
const SelectInput = styled(Select)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 5px;
`;

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  flex-wrap: wrap;
  .adjustWidth {
    width: 90%;
  }
  .alignCenter {
    margin: auto;
    cursor: pointer;
  }
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const Text = styled.span<TextProps>`
  font-weight: ${props => props.fontWeight || '400'};
  font-size: ${props => props.fontSize || '14px'};

  line-height: 20px;
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  // margin: 25px auto 0 auto;
`;

const TextSpan = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
`;

const RadioButtonsWrapper = styled.div<RadioButtonsWrapperProps>`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-between'};
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'center'};
  width: ${props => props.width || '100%'};
  height: auto;
  box-sizing: border-box;
`;

export const EditVideoPlayerSection = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    setElementToEdit,
    elementSectionKey,
    elementToEdit,
    elementIndex,
  } = useLandingPageBuilderContext();
  const data = { ...selectedLayoutData };
  const [counter, setCounter] = React.useState(1);
  const themes = useTheme();

  if (!elementSectionKey || !data) {
    return <></>;
  }

  const sectionDetails = elementToEdit || data[elementSectionKey];
  if (!sectionDetails || !sectionDetails.videoPlayerProperties) {
    return <></>;
  }

  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }
  let isVisible = true;
  if (isElementCase) {
    isVisible = data[elementSectionKey].childSections[elementIndex]?.isVisible;
  } else {
    isVisible = data[elementSectionKey]?.isVisible;
  }

  let overrideVideoSettings =
    data[elementSectionKey].overrideVideoSettings || false;
  const videoElementProperties = sectionDetails.videoPlayerProperties;
  if (!isVisible) {
    return <></>;
  }

  let isWhiteSelected = false;
  if (!data[elementSectionKey]?.videoPlayerProperties?.playerIconsColor) {
    videoElementProperties.playerIconsColor = '#ffffff';
  }

  if (
    data[elementSectionKey]?.videoPlayerProperties?.playerIconsColor &&
    (data[
      elementSectionKey
    ]?.videoPlayerProperties?.playerIconsColor.toLowerCase() == '#ffffff' ||
      data[
        elementSectionKey
      ]?.videoPlayerProperties?.playerIconsColor.toLowerCase() == 'white' ||
      data[
        elementSectionKey
      ]?.videoPlayerProperties?.playerIconsColor.toLowerCase() == '#fff')
  ) {
    isWhiteSelected = true;
  }

  let playerButtonPosition =
    data[elementSectionKey]?.videoPlayerProperties?.playerButtonPosition ||
    'center';
  let playerMainColor =
    data[elementSectionKey]?.videoPlayerProperties?.playerBackgroundColor ||
    '#585B5D';

  const handleChangeMainColor = (color: ColorResult) => {
    const hexAlpha: string = calculateHexAlpha(color);
    videoElementProperties.playerBackgroundColor = hexAlpha;
    sectionDetails.videoPlayerProperties = videoElementProperties;
    // data[elementSectionKey] = sectionDetails;
    updateContext();
  };

  const handleChangePlayButtonPosition = (position: string) => {
    videoElementProperties.playerButtonPosition = position;
    sectionDetails.videoPlayerProperties = videoElementProperties;
    // data[elementSectionKey] = sectionDetails;
    updateContext();
  };

  const handleChangeIconAndTextColor = (color: string) => {
    videoElementProperties.playerIconsColor = color;
    sectionDetails.videoPlayerProperties = videoElementProperties;
    // data[elementSectionKey] = sectionDetails;
    updateContext();
  };

  const handleOverrideAppearanceToggle = () => {
    overrideVideoSettings = !overrideVideoSettings;
    sectionDetails.overrideVideoSettings = overrideVideoSettings;
    updateContext();
  };

  const updateContext = () => {
    if (isElementCase) {
      data[elementSectionKey].childSections[elementIndex] = sectionDetails;
    } else {
      data[elementSectionKey] = sectionDetails;
    }
    let v = counter + 1;
    setCounter(v);
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(data);
  };

  const reset = () => {
    videoElementProperties.playerBackgroundColor = '#585B5D';
    videoElementProperties.playerIconsColor = 'white';
    videoElementProperties.playerButtonPosition = 'center';
    sectionDetails.videoPlayerProperties = videoElementProperties;
    updateContext();
  };

  return (
    <>
      <Row>
        <Text>
          The player is usually styled based on the Appearance Settings set in
          each video’s details.
        </Text>
      </Row>
      <Row justifyContent={'space-between'}>
        <TextSpan> Change appearance </TextSpan>
        <Switch
          id={'switch-1'}
          isOn={overrideVideoSettings}
          onColor={themes.colors.primary[100]}
          handleToggle={() => {
            handleOverrideAppearanceToggle();
          }}
        />
      </Row>
      {overrideVideoSettings == true && (
        <>
          <Row>
            <TopLabel>Main color</TopLabel>
            <EnhancedColorPicker
              disableZIndex={true}
              handleColorChange={handleChangeMainColor}
              playerColor={playerMainColor || 'orange'}
              wrapperWidth={'100%'}
              wrapperMargin={'5px auto 0 auto'}
              className={'adjustWidth'}
            />

            <ResetIcon
              onClick={() => {
                reset();
              }}
              className={'alignCenter'}
            />
          </Row>
          <Row>
            <TopLabel>Play Button Position</TopLabel>
            <SelectInput
              styles={{
                control: (base: any) => ({ ...base, height: '40px' }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
              options={playButtonPositionOptions}
              value={playButtonPositionOptions.find(
                o => o.value == playerButtonPosition
              )}
              onChange={(option: any) => {
                handleChangePlayButtonPosition(option.value);
              }}
            />
          </Row>
          <Row>
            <TopLabel>Icons and Text Color</TopLabel>
            <RadioButtonsWrapper width={'90%'}>
              <RadioInput
                name={'textAndIconColor'}
                value={'#FFFFFF'}
                text={'White'}
                checkedColor={themes.colors.primary[100]}
                checked={isWhiteSelected}
                onChange={() => {}}
                onClick={() => {
                  handleChangeIconAndTextColor('#FFFFFF');
                }}
              />
              <RadioInput
                name={'textAndIconColor'}
                value={'#000000'}
                text={'Black'}
                checkedColor={themes.colors.primary[100]}
                checked={!isWhiteSelected}
                onClick={() => {
                  handleChangeIconAndTextColor('#000000');
                }}
              />
            </RadioButtonsWrapper>
          </Row>
        </>
      )}
    </>
  );
};
