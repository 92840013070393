import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { conversationsKeys } from './queryKeys';

import { useToastError } from 'lib/hooks/useToastError';
import { IContact } from '../contacts/getSingleContact';
import { EXPRESS_API } from 'configs/api/expressApi';
export interface IConversationUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const enum CONVERSATION_TYPES {
  SMS = 'sms',
  EMAIL = 'email',
  LIVE_CHAT = 'chat',
}

export interface ISingleConversation {
  conversationId: number;
  customerId: number;
  userId: number | null;
  contactId: string | null;
  createdAt: string;
  lastReadAt: string;
  unread: number;
  closed: number;
  closedAt: string | null;
  deleted: number;
  deletedAt: string;
  lastMessageAt: string;
  messages: ISingleMessage[];
  contact: IContact;
}
export interface ISingleMessage {
  messageId: number;
  conversationId: number;
  customerId: number;
  userId: number;
  smsId: string;
  text: string;
  type: string;
  isMMS: number;
  integrationType: CONVERSATION_TYPES;
  emailText: null;
  emailSubject: null;
  status: string;
  isChatActive: boolean;
  createdAt: string;
  deliveredAt: null;
  images: any[];
  user: IConversationUser;
}

const getChatHistory = async (
  conversationId: string
): Promise<ISingleConversation> => {
  return (await EXPRESS_API.get(`conversations/${conversationId}`))
    .data as ISingleConversation;
};

export const useGetSingleConversationQuery = () => {
  const { id } = useParams() as { id: string };
  const { showError } = useToastError();
  return useQuery(conversationsKeys.detail(id), () => getChatHistory(id), {
    enabled: !!id,
    onError: (err: AxiosError) => showError(err),
    refetchOnMount: true,
  });
};
