import React from 'react';
import { toMMSS } from 'lib/utils/functions';

import { SliderItem } from 'react-compound-slider';
import { theme } from 'lib/style';

interface TickProps {
  tick: SliderItem;
  count: number;
  format?: (val: number) => string;
}

export const Tick: React.FC<TickProps> = ({ tick, count }) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          width: 2,
          height: 48,
          opacity: 0.4,
          backgroundColor: theme.palette.blackRgb60,
          left: `${tick.percent}%`,
          zIndex: 1,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 52,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {toMMSS(tick.value).split('.')[0]}
      </div>
    </div>
  );
};
