import { SendData } from 'app/pages/library/videos/videoList/components/SendAndShare';
import { useMutation } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';

const createRepairOrder = async (data: SendData) => {
  return (await EXPRESS_API.post(`repair-orders`, data)).data;
};

export const useCreateRepairOrder = () => {
  return useMutation(createRepairOrder, {
    onError: () => {
      errorToast({
        title:
          'An error occurred creating the repair order, please try again later!',
      });
    },
  });
};
