import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export enum BadgeType {
  GRAY = 0,
  LIGHT_ORANGE = 1,
  DARK = 2,
  ORANGE = 3,
  GREEN = 4,
  NEUTRAL = 5,
  DESTRUCTIVE = 6,
}

const BadgeBackgroundColorMap: { [key in BadgeType]: string } = {
  [BadgeType.GRAY]: theme.palette.covideoBlack05,
  [BadgeType.DARK]: theme.palette.gray100,
  [BadgeType.LIGHT_ORANGE]: theme.palette.covideoOrange05,
  [BadgeType.ORANGE]: theme.palette.covideoOrange05,
  [BadgeType.GREEN]: theme.palette.green02NonAlpha,
  [BadgeType.NEUTRAL]: theme.palette.neutral10,
  [BadgeType.DESTRUCTIVE]: theme.palette.red02NonAlpha,
};

const BadgeColorMap = {
  [BadgeType.GRAY]: theme.palette.gray60,
  [BadgeType.DARK]: theme.palette.white,
  [BadgeType.LIGHT_ORANGE]: theme.palette.covideoOrange100,
  [BadgeType.ORANGE]: theme.palette.covideoOrange80,
  [BadgeType.GREEN]: theme.palette.green80,
  [BadgeType.NEUTRAL]: theme.palette.neutral80,
  [BadgeType.DESTRUCTIVE]: theme.palette.red100,
};

const DEFAULT_WIDTH = 72;
const DEFAULT_HEIGHT = 24;

interface Props {
  text: string;
  type?: BadgeType;
  width?: number | string;
  radius?: number;
}

type BadgeContainerProps = {
  width?: number | string;
  height?: number;
  backgroundColor?: string;
  color?: string;
  radius?: number;
};

const BadgeContainer = styled.div<BadgeContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 8px;
  border-radius: ${props => (props.radius ? `${props.radius}px;` : '4px')};
  width: ${props => (props.width ? `${props.width}px;` : 'auto')};
  ${props => {
    if (typeof props.width === 'number') return `height:${props.width}px`;
    if (typeof props.width === 'string') return `height:${props.width}`;
    return 'height: auto';
  }};
  height: ${props => (props.height ? `${props.height}px;` : '24px')};
  color: ${props =>
    props.color ? props.color : theme.palette.covideoOrange100};
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : theme.palette.covideoOrange05};
`;

export const Badge = ({ text, type, width, radius }: Props) => {
  return (
    <BadgeContainer
      backgroundColor={BadgeBackgroundColorMap[type ?? BadgeType.LIGHT_ORANGE]}
      color={BadgeColorMap[type ?? BadgeType.LIGHT_ORANGE]}
      width={width || DEFAULT_WIDTH}
      height={DEFAULT_HEIGHT}
      radius={radius}
    >
      {text}
    </BadgeContainer>
  );
};
