export const statuses = {
  WAITING: 'waiting',
  UPLOADING: 'uploading',
  CANCELING: 'canceling',
  CANCELED: 'canceled',
  FINISHED: 'finished',
  GENERATING_PREVIEW: 'preview',
};

export const acceptedFormats = [
  'image/*',
  '.aac',
  '.abw',
  '.arc',
  '.azw',
  '.bin',
  '.bmp',
  '.bz',
  '.bz2',
  '.cda',
  '.csh',
  '.css',
  '.csv',
  '.doc',
  '.docx',
  '.eot',
  '.epub',
  '.gz',
  '.gif',
  '.htm',
  '.html',
  '.ico',
  '.ics',
  '.jar',
  '.jpeg',
  '.jpg',
  '.js',
  '.json',
  '.jsonld',
  '.mjs',
  '.mpkg',
  '.odp',
  '.ods',
  '.odt',
  '.ogx',
  '.otf',
  '.png',
  '.pdf',
  '.php',
  '.ppt',
  '.pptx',
  '.rar',
  '.rtf',
  '.sh',
  '.svg',
  '.swf',
  '.tar',
  '.tif',
  '.tiff',
  '.ttf',
  '.txt',
  '.vsd',
  '.webp',
  '.woff',
  '.woff2',
  '.xhtml',
  '.xls',
  '.xlsx',
  '.xml',
  '.xul',
  '.zip',
  '.7z',
  'video/*',
];

export type ExtendedFile = {
  name: string;
  sizeText: string;
  size: number;
  currentUpload: number;
  status: string;
  progress: number;
  type?: string;
};
