import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './';

export const Main = () => {
  return (
    <Switch>
      <Route path="/vdps" component={List} exact={true} />
    </Switch>
  );
};
