import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { useToastError } from 'lib/hooks/useToastError';
import { conversationsKeys } from './queryKeys';
import { ISingleConversation } from './getSingleConversation';
import { useHistory } from 'react-router';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IMarkAsReadResponse extends ISingleConversation {}

const markConversationAsRead = async (
  conversationId: string
): Promise<IMarkAsReadResponse> => {
  return (await EXPRESS_API.patch(`conversations/${conversationId}/read`))
    .data as IMarkAsReadResponse;
};

export const useMarkAsReadConversationMutation = () => {
  const { showError } = useToastError();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(markConversationAsRead, {
    mutationKey: ['markAsRead'],
    onSuccess: async ({ conversationId }) => {
      await queryClient.invalidateQueries(
        conversationsKeys.detail(`${conversationId}`)
      );
      queryClient.invalidateQueries(conversationsKeys.lists());
      queryClient.invalidateQueries(conversationsKeys.count());
      history.replace({
        pathname: `/conversations/all/${conversationId}`,
      });
    },
    onError: (err: AxiosError) => {
      showError(err);
    },
  });
};
