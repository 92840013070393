import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdFileUpload } from 'react-icons/md';
import { useAuth } from 'lib/context';
import AddNewDropModal from './AddNewDropModal';
import { DEFAULT_BOARD, DROPTYPE_OPTIONS } from 'lib/const/Droplr';
import { useGetBoard } from 'lib/api/droplr/getBoard';
import { Button } from 'react-covideo-common';

const Container = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${theme.palette.coal};
  margin-bottom: 16px;
`;

type Props = {
  boardId?: string;
  onUploadDropModalClosed?: () => void;
  dropType?: string;
  excludeFilesTab?: boolean;
  hideVideoLibrary?: boolean;
};

export const EmptyDrops = ({
  onUploadDropModalClosed,
  boardId,
  dropType = DROPTYPE_OPTIONS.OWNED,
  excludeFilesTab = false,
  hideVideoLibrary = false,
}: Props) => {
  const { userData } = useAuth();
  const [showAddNewDropModal, setShowAddNewDropModal] = useState(false);

  const { data: board = DEFAULT_BOARD } = useGetBoard({
    boardId: boardId || '',
    userId: Number(userData.id),
    customerId: Number(userData.customerId),
  });

  const isDropOwned = dropType === DROPTYPE_OPTIONS.OWNED;
  const isDropShared = dropType === DROPTYPE_OPTIONS.SHARED;

  const isBoard = !!boardId;
  let userStatus: any;

  if (isBoard) {
    dropType = DROPTYPE_OPTIONS.OWNED;
    userStatus = board.userStatus;
  }

  let emptyDropListTitle = isDropOwned
    ? 'You don’t have files'
    : 'You don’t have shared files';
  let emptyBoardTitle =
    userStatus && userStatus !== 'readCollaborator'
      ? 'This Board is empty. Upload files or add from the Files list.'
      : 'This Board is empty.';
  const uploadFilesBtn = () => {
    if (isDropShared || userStatus === 'readCollaborator') {
      return <></>;
    }

    return (
      <Button
        text='Upload files'
        icon={<MdFileUpload size='18px' color={theme.palette.white} />}
        onClick={() => setShowAddNewDropModal(true)}
      />
    );
  };

  return (
    <Container>
      {isBoard && (
        <ButtonWrapper>
          <Title>{emptyBoardTitle}</Title>
          {uploadFilesBtn()}
        </ButtonWrapper>
      )}
      {!isBoard && (
        <ButtonWrapper>
          <Title>{emptyDropListTitle}</Title>
          {isDropOwned && uploadFilesBtn()}
        </ButtonWrapper>
      )}
      {showAddNewDropModal && (
        <AddNewDropModal
          boardId={boardId}
          excludeFilesTab={excludeFilesTab}
          isGuide={hideVideoLibrary}
          closeModal={() => {
            onUploadDropModalClosed && onUploadDropModalClosed();
            setShowAddNewDropModal(false);
          }}
        />
      )}
    </Container>
  );
};
