import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { videoKeys } from './videosKeys';

export type DeleteVideoApiParams = {
  videoIds: (string | number)[];
  deleteMultiple?: boolean;
  companyVideos?: boolean;
};

export const deleteVideo = async ({
  videoIds,
  deleteMultiple,
  companyVideos,
}: DeleteVideoApiParams): Promise<{ [key: string]: string }> => {
  let data: {
    [key: string]: string | boolean | (string | number)[] | undefined;
  } = { isCompanyVideo: companyVideos };
  let url = `/videos/${videoIds[0]}`;
  if (deleteMultiple) {
    url = '/videos';
    data = { ids: videoIds, isCompanyVideo: companyVideos };
  }
  const response = await EXPRESS_API.delete(url, { data });
  return response.data;
};

export const deleteVideoWithRO = async (videoId: string) => {
  const response = await EXPRESS_API.put(
    `/video-requests/${videoId}/delete-with-ro`
  );
  return response.data;
};

export const useDeleteVideoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteVideo, {
    onSuccess: () => {
      queryClient.invalidateQueries(videoKeys.list('Primary'));
      successToast({
        title: `Videos successfully moved to recently deleted bin`,
      });
    },
    onError: (error: AxiosError) => {
      const errorMsg = error.response?.data?.message || 'Error deleting video';
      errorToast({
        title: errorMsg,
      });
    },
  });
};
