import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { Main } from './main';

export const Custom = () => (
  <>
    <DocumentHead title='Custom Reports' />
    <PageTemplate main={<Main />} />
  </>
);
