import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { linkedinKeys } from './queryKeys';

export const queryLinkedInIntegrationStatus = async () => {
  const response = await EXPRESS_API.get('/linkedin/integration');
  return response.data;
};

export const useQueryLinkedInIntegrationStatus = () => {
  const { showError } = useToastError();
  return useQuery(
    linkedinKeys.linkedin_integration_status(),
    () => queryLinkedInIntegrationStatus(),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
