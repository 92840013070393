import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';
import { Video } from './guidesQuery';

type PrepareVideosResponse = {
  videos: Video[];
};

const prepareVideos = async ({ videoIds }: { videoIds: number[] }) => {
  return (
    await EXPRESS_API.post(`guides/prepare`, {
      videoIds,
    })
  ).data;
};

export const usePrepareVideos = () => {
  const { showError } = useToastError();
  return useMutation(prepareVideos, {
    onError: err => showError(err),
    onSuccess: (res: PrepareVideosResponse) => {
      const isPlural = res.videos.length > 1;
      successToast({
        title: `You have successfully prepared video${isPlural ? 's' : ''}!`,
      });
    },
  });
};
