import React from 'react';
import styled from 'styled-components/macro';
import { CheckboxInput } from 'lib/components';
import { UserInvite } from 'lib/api/superadmin/useUserInvitesQuery';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { useCovideoTheme, Button } from 'react-covideo-common';
import { MdCancelScheduleSend } from 'react-icons/md';

const SelectionWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 65px;
  background: ${({ theme }) => theme.colors.white[100]};
  height: 64px;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border-bottom: solid 1px ${({ theme }) => theme.colors.neutral[20]};
`;

const SelectionContainer = styled.div`
  padding-left: 232px;
  width: 1060px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

type Props = {
  selectedUsers: UserInvite[];
  users: UserInvite[];
  setSelectedUsers(selectedUsers: UserInvite[]): void;
  onInviteAllHandler: (users: UserInvite[]) => void;
  onCancelAllHandler: (users: UserInvite[]) => void;
};

export const ManageInvitesSelectedUsers = ({
  selectedUsers,
  users,
  setSelectedUsers,
  onInviteAllHandler,
  onCancelAllHandler,
}: Props) => {
  const { colors } = useCovideoTheme();

  const isScheduled = selectedUsers.some(
    user => user.userInvite?.status === 'scheduled'
  );
  return (
    <SelectionWrapper>
      <SelectionContainer>
        <Gap gap='16px'>
          <CheckboxInput
            blueCheck={true}
            checkGroupIndicator={
              selectedUsers.length > 0 && users.length !== selectedUsers.length
            }
            checked={users.length == selectedUsers.length}
            ignoreGrayForAllSelect={true}
            grayCheck={true}
            onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              const { checked } = event.target as HTMLInputElement;
              setSelectedUsers(checked ? users : []);
            }}
          />
          <Gap gap='8px'>
            <ParagraphSmall color={colors.black[100]}>
              Users selected:
            </ParagraphSmall>
            <b>{selectedUsers.length}</b>
          </Gap>
        </Gap>
        <Gap>
          {isScheduled && (
            <Button
              variant='destructive'
              text='Cancel Scheduled'
              icon={<MdCancelScheduleSend />}
              onClick={() => onCancelAllHandler(selectedUsers)}
            />
          )}
          <Button
            variant='primary'
            text='Invite Selected'
            onClick={() => onInviteAllHandler(selectedUsers)}
          />
        </Gap>
      </SelectionContainer>
    </SelectionWrapper>
  );
};
