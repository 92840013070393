import { CustomersMetricsQueryParams } from './getCustomerMetrics';
import { UsersMetricsQueryParams } from './getUserMetrics';
import { ReportParams } from './queryReport';
import { TopVideosCtasParams } from './queryTopCta';

export const metricsKeys = {
  report: (params: ReportParams, reportType: string) =>
    ['REPORT', params, reportType] as const,
  topCta: (params: TopVideosCtasParams) => ['TOP_CTA', params] as const,
  topVideos: (params: TopVideosCtasParams) => ['TOP_VIDEOS', params] as const,
  users: (params: UsersMetricsQueryParams) =>
    ['USERS_METRICS', params] as const,
  customers: (params: CustomersMetricsQueryParams) =>
    ['CUSTOMERS_METRICS', params] as const,
};
