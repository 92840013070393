import React from 'react';
import { Dropdown } from 'lib/components';

import { getAllCustomersForSuperAdmin } from 'lib/api';
import { uniqBy, debounce } from 'lodash';
import { SuperAdminCustomer } from 'lib/context';
import { Option } from 'lib/components/dropdown/DropdownOptions';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { AllCustomerOption } from 'lib/const/SuperAdminOptions';

type Props = {
  width?: number;
  showAllCustomersOption?: boolean;
  customerFilter?: number;
  customerName?: string;
  placeholder?: string;
  zIndexProp?: number;
  onSelectCustomer(resellerId: number): void;
  status?: number;
};

export const CustomersDropdown = ({
  width,
  showAllCustomersOption = false,
  placeholder = 'All Customers',
  customerFilter,
  customerName,
  zIndexProp,
  onSelectCustomer,
  status = VerificationStatus.ALL,
}: Props) => {
  const [page, setPage] = React.useState(0);
  const [pageCopy, setPageCopy] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [customersCount, setCustomersCount] = React.useState(0);
  const [customerSearch, setCustomerSearch] = React.useState('');
  const [dropdownOptions, setDropdownOptions] = React.useState<Option[]>(
    showAllCustomersOption ? [AllCustomerOption] : []
  );

  const searchInput = debounce((search: string) => {
    search ? setDropdownOptions([]) : setDropdownOptions(dropdownOptions);
    setCustomerSearch(search);
    search ? setPage(0) : setPage(pageCopy);
  }, 600);

  const fetchCustomers = async () => {
    try {
      const limit = 10;
      const start = page * limit;
      const params = {
        start,
        limit,
        search: customerSearch,
        status,
      };
      const { customers, count } = await getAllCustomersForSuperAdmin(
        params
      ).catch(() => {
        return { customers: [], count: 0 };
      });

      let customerOptions: Option[] = [...dropdownOptions];
      customers.forEach((customer: SuperAdminCustomer) => {
        customerOptions = [
          ...customerOptions,
          {
            label:
              customer.business && customer.business.trim()
                ? `${customer.business} - ${customer.customerId}`
                : `No Name - ${customer.customerId}`,
            value: customer.customerId,
          },
        ];
      });

      const selectedCustomer = customerOptions.find(
        option => option.value === customerFilter
      );
      if (!selectedCustomer && !!customerFilter && !!customerName) {
        customerOptions.push({
          value: customerFilter,
          label: customerName,
        });
      }

      customerOptions = uniqBy(customerOptions, 'value');
      setDropdownOptions(customerOptions);
      setCustomersCount(count);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchCustomers();
  }, [page, customerSearch]);

  return (
    <Dropdown
      width={width}
      menuPortalTarget={document.body}
      menuPlacement={'bottom'}
      placeholder={placeholder}
      creatable={false}
      dropdownHeight={300}
      options={dropdownOptions}
      value={dropdownOptions.find(option => option.value === customerFilter)}
      onChange={option => onSelectCustomer(option.value)}
      onMenuScrollToBottom={() => {
        if (dropdownOptions.length < customersCount) setPage(page + 1);
        if (!customerSearch) setPageCopy(page + 1);
      }}
      onInputChange={searchInput}
      isLoading={loading}
      onMenuClose={() => setLoading(false)}
      className='option-container'
      isSearchable={true}
      zIndexProp={zIndexProp || 999}
      extendStyles={{
        valueContainer: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          flexWrap: 'nowrap',
        },
      }}
      filterOption={() => {
        return true;
      }}
    />
  );
};
