import React, { useEffect, useState } from 'react';
import { theme } from 'lib/style';
import {
  Container,
  MainContainer,
  MainWrapper,
  NoList,
} from '../../index.styled';
import { MdAdd } from 'react-icons/md';
import { Heading } from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';
import { useGetConnectedIMSConnections } from 'lib/api/superAdminApi';
import { InventoryTable } from '../components/InventoryTable';
import { LoadingIndicator } from 'lib/components';
import { Customer } from 'lib/context';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { SearchAndFilter } from '../../resellers/components/SearchAndFilter';
import { ModalAddEditIMSConnection } from '../components/ModalAddEditIMSConnection';
import { ModalDeleteIMSConnection } from '../components/ModalDeleteIMSConection';
import { Button } from 'react-covideo-common';

const InventoryManagementSystem = () => {
  const [showIMSConnectionModal, setShowIMSConnectionModal] = useState<{
    modal: boolean;
    customerId: string | number | null;
  }>({ modal: false, customerId: null });

  const [showIMSDeleteModal, setshowIMSDeleteModal] = useState<{
    modal: boolean;
    connection: Customer | null;
  }>({ modal: false, connection: null });
  const [search, setConnectionsSearch] = useState('');
  const [connectionsPage, setConnectionsPage] = useState(DEFAULT_PAGE);
  const [connectionsSize, setConnectionsSize] = useState(DEFAULT_SIZE);
  const [connectionsSortKey, setConnectionsSortKey] = React.useState('');
  const [connectionsSortOrder, setConnectionsSortOrder] = React.useState(
    PaginationConstants.ASCENDING
  );
  const sort = {
    sortKey: connectionsSortKey,
    order: connectionsSortOrder || PaginationConstants.ASCENDING,
  };

  const limit = connectionsSize || 10;
  const start = connectionsPage * limit;

  const {
    data: connectedData,
    isLoading: isLoadingConnected,
    refetch,
  } = useGetConnectedIMSConnections(search, start, limit, sort);

  const onPaginationChange = async ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    setConnectionsPage(page);
    setConnectionsSize(size);
  };

  useEffect(() => {
    refetch();
  }, [
    connectionsPage,
    connectionsSize,
    search,
    connectionsSortOrder,
    connectionsSortKey,
  ]);

  const openModal = (customerId: string | number | null) =>
    setShowIMSConnectionModal({ modal: true, customerId });
  const closeModal = () =>
    setShowIMSConnectionModal({ modal: false, customerId: null });

  const openDeleteModal = (connection: Customer) => {
    setshowIMSDeleteModal({ modal: true, connection });
  };

  const closeDeleteModal = () => {
    setshowIMSDeleteModal({ modal: false, connection: null });
  };

  return (
    <MainContainer>
      <MainWrapper>
        <Container overflowX='visible'>
          <Gap
            alignItems='flex-start'
            flexDirection='column'
            gap='8px'
            width='100%'
          >
            <Gap
              alignItems='center'
              justifyContent='space-between'
              width='100%'
            >
              <Heading color={theme.palette.gray100}>
                Inventory Management System
              </Heading>
              <Button
                onClick={() => openModal(null)}
                text='Add Connection'
                icon={<MdAdd color={theme.palette.white} />}
              />
            </Gap>
            <SearchAndFilter
              onSearch={setConnectionsSearch}
              prevSearch={search}
              placeholder='Search by Customer Name or ID…'
              width={496}
            />
          </Gap>

          {isLoadingConnected && (
            <div style={{ width: '100%', height: '300px' }}>
              <LoadingIndicator isLoading={isLoadingConnected} height='300px' />
            </div>
          )}
          {!isLoadingConnected && !!connectedData?.customers.length && (
            <InventoryTable
              sortKey={sort.sortKey}
              order={sort.order}
              deleteAction={openDeleteModal}
              editAction={openModal}
              connectionsCount={connectedData.count}
              connectionsArray={connectedData.customers}
              page={connectionsPage}
              size={connectionsSize}
              onPaginationChange={onPaginationChange}
              onSortingUpdate={(connectionsSortKey, order) => {
                setConnectionsSortKey(connectionsSortKey);
                setConnectionsSortOrder(order);
                setConnectionsPage(DEFAULT_PAGE);
              }}
            />
          )}
          {!isLoadingConnected && !connectedData?.customers.length && (
            <NoList>No connections to show.</NoList>
          )}
        </Container>
      </MainWrapper>
      {showIMSConnectionModal.modal && (
        <ModalAddEditIMSConnection
          handleModalClose={closeModal}
          showIMSConnectionModal={showIMSConnectionModal}
        />
      )}
      {showIMSDeleteModal.modal && (
        <ModalDeleteIMSConnection
          connection={showIMSDeleteModal.connection}
          handleModalClose={closeDeleteModal}
        />
      )}
    </MainContainer>
  );
};

export default InventoryManagementSystem;
