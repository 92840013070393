import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { InformationBox } from '../InformationBox';
import { IoMdClose } from 'react-icons/io';
import { MdDeleteForever, MdSave } from 'react-icons/md';

type Props = {
  open?: boolean;
  disableSave?: boolean;
  infoBoxText?: string;
  title?: string;
  discardButtonText?: string;
  confirmButtonText?: string;
  text?: string;
  showDiscardIcon?: boolean;
  showSaveIcon?: boolean;
  onClickDiscardButton: () => void;
  onClickConfirmButton: () => void;
  handleModalClose?: () => void;
};

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 592px;
  min-height: 212px;
  background-color: #fff;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
  justify-content: space-between;
  p {
    margin: 0;
  }
`;

const ContentBody = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.palette.black_1_100};
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 528px;
  justify-content: flex-end;
  align-items: right;
  padding: 0 32px 32px;
  gap: 16px;
`;

export const ModalLeave = ({
  open = false,
  title = '',
  text = '',
  disableSave = false,
  infoBoxText,
  discardButtonText = 'Keep video',
  confirmButtonText = 'Delete video',
  onClickDiscardButton,
  onClickConfirmButton,
  showDiscardIcon = true,
  showSaveIcon = true,
  handleModalClose = () => {},
}: Props) => {
  if (!open) {
    return null;
  }
  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed', zIndex: 999 }}>
      <ModalItem>
        <Content>
          <ContentHeader>
            <p>{title}</p>
            <CloseButtonWrapper>
              <IoMdClose size={24} onClick={handleModalClose} />
            </CloseButtonWrapper>
          </ContentHeader>
          <ContentBody>{text}</ContentBody>
          {infoBoxText && <InformationBox text={infoBoxText} width='460px' />}
        </Content>
        <ButtonsWrapper>
          <Button
            icon={showDiscardIcon ? <MdDeleteForever size={24} /> : undefined}
            text={discardButtonText}
            onClick={onClickDiscardButton}
            variant='destructive'
          />
          <Button
            icon={showSaveIcon ? <MdSave size={18} /> : undefined}
            text={confirmButtonText}
            onClick={onClickConfirmButton}
            variant='primary'
            disabled={disableSave}
          />
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
