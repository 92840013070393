import { Dropdown, LoadingIndicator, ButtonSwitch } from 'lib/components';
import { Button } from 'react-covideo-common';
import React from 'react';
import { ConnectionButton } from './ConnectionButton';
import { ConnectionChip } from './ConnectionChip';
import { CONNECTION_BUTTON_TYPES, CONNECTION_TYPES } from './constants';
import {
  CardWrapper,
  HeaderWrapper,
  HeaderTitle,
  SocialDescription,
  Content,
  ButtonsWrapper,
  ChannelName,
  Channel,
  ChannelWrapper,
  ConnectionBtnWrapper,
  DropdownWrapper,
  DropdownLabel,
  ButtonSwitchWrapper,
  DropdownContainer,
  PrivacyTitle,
} from './style';
interface ProfileCardProps {
  title: string;
  connection: CONNECTION_TYPES;
  description: string;
  button: JSX.Element;
  onSaveClick: () => void;
  onDisconnect: () => void;
  onReconnect: () => void;
  channelName?: string;
  dropdown?: { label: string; items: any[] | null } | null;
  toggleItems?: any[];
  onDropdownChange?: React.Dispatch<React.SetStateAction<any>>;
  onToggleSwitch?: any;
  selectedItem?: any;
  loading: boolean;
  hasPages?: boolean;
  hasPrivacyToggle?: boolean;
  toggleValue?: string;
  onSaveDisabled?: boolean;
}

export const ProfileCard = ({
  title,
  connection,
  description,
  button,
  onSaveClick,
  onDisconnect,
  onReconnect,
  channelName,
  onDropdownChange,
  onToggleSwitch,
  selectedItem,
  dropdown,
  loading,
  hasPages = true,
  hasPrivacyToggle = false,
  toggleValue,
  toggleItems,
  onSaveDisabled,
}: ProfileCardProps) => {
  const SaveButton = (props: any) => (
    <Button
      variant={props.isActive ? 'secondary' : 'primary'}
      text={props.text}
      disabled={props.disabled}
      {...props}
    />
  );
  return (
    <CardWrapper>
      <Content>
        <HeaderWrapper>
          <HeaderTitle>{title}</HeaderTitle>
          <ConnectionChip type={connection} />
        </HeaderWrapper>
        <SocialDescription>{description}</SocialDescription>
        {channelName && (
          <ChannelWrapper>
            <Channel>Channel:</Channel>
            <ChannelName>{channelName}</ChannelName>
          </ChannelWrapper>
        )}
        {connection === CONNECTION_TYPES.CONNECTED &&
          hasPages &&
          dropdown &&
          dropdown?.items &&
          dropdown?.items?.length > 1 && (
            <DropdownContainer>
              <DropdownLabel>{dropdown?.label}</DropdownLabel>
              <DropdownWrapper>
                <Dropdown
                  options={dropdown?.items}
                  value={dropdown?.items?.find(o => {
                    return o.value === selectedItem;
                  })}
                  onChange={(option: any) => onDropdownChange?.(option.value)}
                  placeholder={
                    dropdown?.items?.length
                      ? 'Select page or organization'
                      : 'No pages connected to your account.'
                  }
                  creatable={false}
                  width={360}
                  disabled={dropdown?.items?.length === 0}
                  showIndicator={dropdown?.items?.length !== 0}
                />
                <SaveButton
                  text='Save'
                  onClick={onSaveClick}
                  width='67px'
                  disabled={onSaveDisabled || dropdown?.items?.length === 0}
                />
              </DropdownWrapper>
            </DropdownContainer>
          )}
        {connection === CONNECTION_TYPES.CONNECTED &&
          hasPages &&
          dropdown &&
          dropdown?.items &&
          dropdown?.items?.length === 1 && (
            <ChannelWrapper>
              <Channel>Publishing on:</Channel>
              <ChannelName>{dropdown?.items[0].label}</ChannelName>
            </ChannelWrapper>
          )}
        {connection === CONNECTION_TYPES.CONNECTED && hasPrivacyToggle && (
          <ButtonSwitchWrapper>
            <PrivacyTitle>Post Privacy</PrivacyTitle>
            <ButtonSwitch
              defaultValue={
                !!toggleValue ? toggleValue.toLowerCase() : 'private'
              }
              values={toggleItems as any[]}
              onChange={newValue => onToggleSwitch(newValue)}
            />
          </ButtonSwitchWrapper>
        )}
      </Content>
      {loading && (
        <ConnectionBtnWrapper isLoading={loading}>
          <LoadingIndicator isLoading={loading} />
        </ConnectionBtnWrapper>
      )}
      {!loading && (
        <>
          {connection === CONNECTION_TYPES.CONNECTED ? (
            <ConnectionBtnWrapper>
              <ConnectionButton
                handleClick={onDisconnect}
                type={CONNECTION_BUTTON_TYPES.DISCONNECT}
              />
              <ConnectionButton
                handleClick={onReconnect}
                type={CONNECTION_BUTTON_TYPES.RECONNECT}
              />
            </ConnectionBtnWrapper>
          ) : (
            <ButtonsWrapper>{button}</ButtonsWrapper>
          )}
        </>
      )}
    </CardWrapper>
  );
};
