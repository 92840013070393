import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { theme } from 'lib/style';
import { NavLink } from 'react-router-dom';
import { Location } from 'lib/context/types';
import { Spinner } from '../Spinner';
import { useAuth } from 'lib/context/auth';
import { useSignIntoLocation } from 'lib/api/locations/signIntoLocation';
import { useGetLocationsQuery } from 'lib/api/locations/getUserLocations';
import { PackageNameLabel } from 'lib/utils/productFeature';
import { CommonTypography } from '../styles/typography';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { IoMdCheckmark } from 'react-icons/io';
import {
  MdLock,
  MdOutlineUnfoldLess,
  MdOutlineUnfoldMore,
} from 'react-icons/md';
import { SOCIAL_PROFILES } from 'lib/utils/UserNavItems';
import { socialProfile } from '../SidebarNavigation';

type DDProps = {
  isOpen: boolean;
  aligment: boolean;
  autoPosition: boolean;
  hasDisabledItems?: boolean;
};

const Dropdown = styled.div<DDProps>`
  overflow: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  .disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: rgba(255, 255, 255, 0.5);
  }
  background: white;
  box-shadow:
    0 2px 4px 0 rgba(52, 58, 64, 0.1),
    0 4px 24px 0 rgba(52, 58, 64, 0.15);
  margin-left: -6px;
  ${props =>
    props.isOpen &&
    css`
      top: ${props.autoPosition ? '' : '64px'};
      z-index: 999;
      max-height: 87vh;
      ${!props.hasDisabledItems && `overflow-y: overlay; `}
      ::-webkit-scrollbar {
        height: 16px;
        width: 8px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${theme.palette.covideoBlue20};
      }
      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
    `};
  ${props =>
    !props.autoPosition &&
    (props.aligment
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `)}

  ${props =>
    !props.isOpen &&
    css`
      display: none;
    `}
`;
const DropdownItem = styled(NavLink)<{
  color?: string;
  fontSize?: string;
  fontWeight?: number;
}>`
  display: flex;
  margin-top: 1px;
  padding: 2px 7px;
  min-height: 24px;
  align-items: center;
  text-align: center; /* to center icons */
  transition: all 0.2s;
  width: 240px;

  font-size: ${props => (props.fontSize ? props.fontSize : theme.fontSizes.sm)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 500)};

  color: ${props =>
    props.color ? props.color : theme.palette.primaryDarkBlue};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary[100]};
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary[100]};
  }
  .Tooltip-Wrapper {
    .Tooltip-Tip.top {
      top: calc(var(--tooltip-margin) * 1) !important;
      left: 68px !important;
    }
  }

  &.disabled {
    &:hover {
      color: ${theme.palette.primaryDarkBlue};
    }
  }
`;

const CustomDropdownItem = styled.a<{
  color?: string;
  fontSize?: string;
  fontWeight?: number;
}>`
  display: flex;
  margin-top: 1px;
  padding: 2px 7px;
  min-height: 24px;
  align-items: center;
  text-align: center; /* to center icons */
  transition: all 0.2s;
  min-width: 190px;
  font-size: ${props => (props.fontSize ? props.fontSize : theme.fontSizes.sm)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 500)};
  color: ${props =>
    props.color ? props.color : theme.palette.primaryDarkBlue};

  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary[100]}; /** SUS-961 changes **/
  }

  &.active {
    color: ${props => props.theme.colors.primary[100]};
  }
`;

const Icon = styled.span<{ color?: string }>`
  margin: 0;
  padding: 5px;
  font-size: ${theme.fontSizes.sm};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${props => (props.color ? props.color : 'currentColor')};
`;

const Badge = styled.span`
  font-size: 10px;
  padding: 2px 4px;
  margin-left: 8px;
  border-radius: 4px;
  color: ${theme.palette.white};
  background-color: ${props => props.theme.colors.primary[100]};
`;

const LocationContainer = styled.div``;
const MainLocation = styled.div`
  white-space: nowrap;
  max-width: 280px;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  color: ${theme.palette.covideoBlue100};
  padding: 16px;
  user-select: none;
`;
const LocationComponent = styled.div`
  white-space: nowrap;
  max-width: 280px;
  padding: 16px;
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  color: ${theme.palette.covideoBlue80};
  background-color: ${theme.palette.secondaryButtonBlueBg};
`;
const LocationTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const LocationSubtitle = styled.div`
  font-weight: normal;
  text-align: left;
  color: ${theme.palette.covideoBlue60};
  margin-top: 5px;
`;

const LocationHeading = styled.div`
  font-weight: 500;
  text-align: left;
  color: ${theme.palette.covideoBlue60};
  font-size: 10px;
`;

const DisabledItem = styled.div`
  cursor: not-allowed;
  display: flex;
  align-items: center;
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 16px 0px 16px;
  gap: 12px;
`;

export const Label = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.palette.covideoBlue40};
`;

const Line = styled.div`
  flex: 1;
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.blue05};
`;

type Props = {
  items: any[];
  OnClickOutside: () => void;
  isDropdownOpen: boolean;
  aligment?: boolean;
  autoPosition?: boolean;
  showLocations?: boolean;
  isLocationChanging?: boolean;
  setIsLocationChanging?: (arg: boolean) => void;
};

const MenuDropdown = ({
  items,
  OnClickOutside,
  isDropdownOpen,
  aligment = false,
  autoPosition = false,
  showLocations = false,
  isLocationChanging,
  setIsLocationChanging,
}: Props) => {
  const { ref } = useOnClickOutside(OnClickOutside);

  const { userData, whitelabel } = useAuth();
  const { data: locations } = useGetLocationsQuery(
    userData.isMultiLocation,
    userData.id
  );
  const [isLocationMenuOpen, setIsLocationMenuOpen] = React.useState(false);
  const [locationChange, setLocationChange] = React.useState(-1);
  const { mutateAsync: signIn } = useSignIntoLocation();
  const [currentLocation, setCurrentLocation] = React.useState({
    name: '',
    streetAddress: '',
    state: '',
    locationId: 0,
  } as Location);

  const showTeleprompter = [
    WHITELABEL_NAME.COVIDEO,
    WHITELABEL_NAME.APP_2_VID,
    WHITELABEL_NAME.PRO_VIDEO_NOW,
  ].includes(whitelabel.name);

  const currentLocationId = Number(userData.customer.locationId);

  React.useEffect(() => {
    if (!isDropdownOpen && showLocations) setIsLocationMenuOpen(false);
  }, [isDropdownOpen]);

  React.useEffect(() => {
    if (locations.length) {
      const current = locations.find(
        (location: Location) => location.locationId === currentLocationId
      );
      if (current) setCurrentLocation(current);
    }
  }, [locations]);

  React.useEffect(() => {
    if (isLocationChanging && locationChange !== -1) {
      signIntoLocation();
    }
  }, [locationChange, isLocationChanging]);

  const signIntoLocation = async () => {
    await signIn({ locationId: locationChange });
    window.location.reload();
  };

  const onSelectLocation = async (locationId: number) => {
    if (locationId === currentLocationId) return;
    setLocationChange(locationId);
    if (setIsLocationChanging) setIsLocationChanging(true); // for loading screen
  };

  const hasDisabledItems = !!(
    items && items.filter(item => item.productFeatureId && item.isDisabled)
  );

  return (
    <Dropdown
      ref={ref}
      isOpen={isDropdownOpen}
      aligment={aligment}
      autoPosition={autoPosition}
      hasDisabledItems={hasDisabledItems}
    >
      {showLocations && !!locations.length && (
        <LocationContainer>
          <MainLocation
            onClick={() => setIsLocationMenuOpen(!isLocationMenuOpen)}
          >
            <LocationHeading>STORE</LocationHeading>
            <LocationTitle>
              <TitleWrapper> {currentLocation.name}</TitleWrapper>

              {isLocationMenuOpen ? (
                <MdOutlineUnfoldLess color={theme.palette.covideoBlue40} />
              ) : (
                <MdOutlineUnfoldMore color={theme.palette.covideoBlue40} />
              )}
            </LocationTitle>
            <LocationSubtitle>
              {`${currentLocation.streetAddress || ''}${
                currentLocation.state ? `, ${currentLocation.state}` : ``
              }`}
            </LocationSubtitle>
          </MainLocation>
          {locations.map((location: Location) => (
            <LocationComponent
              onClick={() => onSelectLocation(location.locationId)}
              style={{ ...(!isLocationMenuOpen && { display: 'none' }) }}
              key={`location-${location.locationId}`}
            >
              <LocationTitle>
                <TitleWrapper style={{ maxWidth: '210px' }}>
                  {location.name}
                </TitleWrapper>
                {location.locationId === currentLocation.locationId &&
                  locationChange === -1 && <IoMdCheckmark size={20} />}
                {location.locationId === locationChange && (
                  <Spinner color={theme.palette.covideoBlue100} thickness={2} />
                )}
              </LocationTitle>
              <LocationSubtitle
                style={{
                  ...(location.locationId === currentLocation.locationId && {
                    marginTop: '2px',
                  }),
                }}
              >
                {`${location.streetAddress || ''}${
                  location.state ? `, ${location.state}` : ``
                }`}
              </LocationSubtitle>
            </LocationComponent>
          ))}
        </LocationContainer>
      )}
      {items.map(
        (
          {
            title,
            path,
            icon,
            customLink,
            onItemClick,
            badge,
            isDisabled,
            productFeatureId,
            isLabel,
            color,
            iconColor,
            fontSize,
            fontWeight,
          },
          index
        ) => {
          if (!showTeleprompter && title === 'Teleprompter') return null;

          if (!!isLabel) {
            return (
              <LabelContainer key={index}>
                <Label>{title}</Label>
                <Line />
              </LabelContainer>
            );
          }
          return customLink ? (
            <CustomDropdownItem
              key={index}
              href={customLink}
              color={color}
              fontSize={fontSize}
              fontWeight={fontWeight}
              target='_blank'
            >
              {icon && <Icon color={iconColor}>{icon}</Icon>}
              <span>{title}</span>
            </CustomDropdownItem>
          ) : (
            <DropdownItem
              className={isDisabled ? 'disabled' : ''}
              key={index}
              to={isDisabled ? '/' + index.toString() : path}
              color={color}
              fontSize={fontSize}
              fontWeight={fontWeight}
              onClick={e => {
                if (isDisabled) {
                  e.preventDefault();
                } else {
                  onItemClick && onItemClick();
                }
              }}
              isActive={(match, location) => {
                return path.includes(location.pathname);
              }}
            >
              {isDisabled && !userData.trialAccount ? (
                <DisabledItem>
                  <Icon color={iconColor}>{icon}</Icon>
                  <ProductInfoTooltip
                    nextPlan={
                      title === 'Video Attributes' ||
                      title === 'Schedules' ||
                      title === 'Templates' ||
                      title === 'Video Expiration' ||
                      title === socialProfile.title
                        ? PackageNameLabel.TEAMS
                        : PackageNameLabel.PRO
                    }
                    productFeatureId={productFeatureId}
                    style={{
                      width: '254px',
                      left: '90px',
                      top: title === SOCIAL_PROFILES.title ? '-160px' : '30px',
                      boxSizing: 'border-box',
                    }}
                  >
                    <span>{title}</span>
                    <MdLock size={'18px'} color={'#001B53'} />
                    {!!badge && <Badge>{badge}</Badge>}
                  </ProductInfoTooltip>
                </DisabledItem>
              ) : (
                <>
                  <Icon color={iconColor}>{icon}</Icon>
                  <span>{title}</span>
                  {!!badge && <Badge>{badge}</Badge>}
                </>
              )}
            </DropdownItem>
          );
        }
      )}
    </Dropdown>
  );
};

export { MenuDropdown };
