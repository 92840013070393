import * as React from 'react';
import './rangeSlider.css';
import { useTheme } from 'styled-components/macro';

type Props = {
  handleChange: any;
  value: any;
};
const genSlideStyle = (value: any) => {
  return {
    point: {
      left: `calc(${value * 20}% - ${5 + 3 * value}px)`,
    },
    range: {
      width: `${value * 20}%`,
    },
  };
};

export const RangeSlider = ({ handleChange, value = 1 }: Props) => {
  const themes = useTheme();
  const slideStyle = genSlideStyle(value);

  return (
    <div className='range'>
      <span
        className='range-value'
        style={{
          ...slideStyle.range,
          backgroundColor: themes.colors.primary[100],
        }}
      />
      <span className='circle' style={slideStyle.point} />
      <input
        className='range-slide'
        name='range'
        type='range'
        min={0}
        max={5}
        value={value}
        step={0.1}
        onChange={(e: any) => {
          handleChange(e.target.value);
        }}
      />
    </div>
  );
};
