import { FilesTabContent } from 'app/pages/record/virtualBackgrounds/FilesTabContent';
import { NewModal } from 'lib/components';
import React from 'react';

type Props = {
  handleModalClose: () => void;
  onSelect: (selectedImageUrl: string) => void;
};

export const ModalFilesChooseImage = ({
  handleModalClose,
  onSelect,
}: Props) => {
  return (
    <NewModal headerText='Select your image' closeModal={handleModalClose}>
      <FilesTabContent
        onSelect={onSelect}
        handleModalClose={handleModalClose}
        selectedVirtualBackgroundUrl=''
      />
    </NewModal>
  );
};
