import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { groupKeys } from './groupKeys';
import { QueryParams } from './useQueryGroups';

export const createGroup = async (name: string) => {
  const response = await EXPRESS_API.post('/groups', { name });
  return response.data;
};

export const useCreateGroupMutation = (
  onSuccess?: () => void,
  queryParams?: QueryParams
) => {
  const queryClient = useQueryClient();
  return useMutation((name: string) => createGroup(name), {
    onError: () => {
      errorToast({
        title: 'An error occurred creating group, please try again later!',
      });
    },
    onSuccess: async () => {
      successToast({
        title: `You have successfully created a new group`,
      });
      await queryClient.invalidateQueries(groupKeys.groups(queryParams));
      await queryClient.invalidateQueries(groupKeys.groups());
      onSuccess && onSuccess();
    },
  });
};
