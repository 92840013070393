import React, { Fragment } from 'react';
import { toMMSS } from 'lib/utils/functions';

import {
  SliderItem,
  GetRailProps,
  GetTrackProps,
  GetHandleProps,
} from 'react-compound-slider';
import { theme } from 'lib/style';
import { useTheme } from 'styled-components/macro';

// *******************************************************
// SLIDER RAIL (no tooltips)
// *******************************************************
const railOuterStyle = {
  position: 'absolute' as 'absolute',
  transform: 'translate(0%, -50%)',
  width: '100%',
  height: 48,
  cursor: 'pointer',
};

const railInnerStyle = {
  position: 'absolute' as 'absolute',
  width: '100%',
  height: 48,
  transform: 'translate(0%, -50%)',
  pointerEvents: 'none' as 'none',
  backgroundColor: 'rgba(0,27,83,0.15)',
};

interface SliderRailProps {
  getRailProps: GetRailProps;
}

export const SliderRail: React.FC<SliderRailProps> = ({ getRailProps }) => (
  <Fragment>
    <div style={railOuterStyle} {...getRailProps()} />
    <div style={railInnerStyle} />
  </Fragment>
);

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
interface HandleProps {
  isActive: boolean;
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  disabled?: boolean;
  videoSource: string;
  videoRef: any;
  changeVideoPosition?: boolean;
  order: number;
  marginLeft?: string /* SUS-905 changes */;
}
export const Handle = (props: HandleProps) => {
  const {
    domain: [min, max],
    handle: { id, value, percent },
    isActive,
    disabled,
    getHandleProps,
    videoRef,
    changeVideoPosition = true,
    order,
    marginLeft /* SUS-905 changes */,
  } = props;

  const [mouseOver, setMouseOver] = React.useState(false);

  const previewvideoRef = React.createRef<HTMLVideoElement>();

  const onMouseEnter = () => {
    setMouseOver(true);
  };

  const onMouseLeave = () => {
    setMouseOver(false);
  };

  React.useEffect(() => {
    if (isActive) {
      if (previewvideoRef.current) {
        previewvideoRef.current.currentTime = value ? value : 0;
        if (changeVideoPosition) {
          if (videoRef.current) {
            videoRef.current.currentTime = value ? value : 0;
          }
        }
      }
    }
  }, [value, previewvideoRef, changeVideoPosition, videoRef]);

  const seconds = toMMSS(value).split('.')[0];
  const miliseconds = toMMSS(value).split('.')[1];
  const themes = useTheme();
  return (
    <Fragment>
      {(mouseOver || isActive) && !disabled ? (
        <div
          style={{
            left: `${percent}%`,
            position: 'absolute',
            marginLeft: '-22px',
            marginTop: '22px',
            zIndex: 1,
          }}
        >
          <div
            style={{
              width: 0,
              height: 0,
              marginLeft: 21,
              borderLeft: '6px solid transparent',
              borderRight: '6px solid transparent',
              borderBottom: `6px solid ${themes.colors.primary[100]}`,
            }}
          />
          <div
            style={{
              width: 48,
              padding: 8,
              fontSize: 12,
              fontWeight: 500,
              letterSpacing: 0.34,
              backgroundColor: themes.colors.primary[100],
              color: '#fff',
              textAlign: 'center',
            }}
          >
            {seconds}
            <span
              style={{
                fontSize: 9,
                fontWeight: 400,
                letterSpacing: 0.34,
                color: '#fff',
                opacity: 0.7,
              }}
            >
              {'.' + miliseconds}
            </span>
          </div>
        </div>
      ) : null}
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          marginLeft: marginLeft ? marginLeft : '-75px' /* SUS-905 changes */,
          marginTop: '-112px',
          zIndex: 3,
          visibility:
            (mouseOver || isActive) && !disabled ? 'visible' : 'hidden',
        }}
      >
        <div
          style={{
            width: 141,
            height: 80,
            border: `solid 2px ${themes.colors.primary[100]}`,
            backgroundColor: theme.palette.themeDark75Percent,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <video
            key='previewvideoRef'
            ref={previewvideoRef}
            height={'100%'}
            src={props.videoSource}
          />
        </div>
      </div>
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 400,
          width: 24,
          height: 44,
          cursor: 'pointer',
          backgroundColor: '#none',
        }}
        {...getHandleProps(id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <div
        role='slider'
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 2,
          backgroundColor: disabled ? '#666' : themes.colors.primary[100],
          width: 24,
          height: 48,
          borderTopLeftRadius: order === 0 ? '4px' : '0px',
          borderBottomLeftRadius: order === 0 ? '4px' : '0px',
          borderBottomRightRadius: order === 1 ? '4px' : '0px',
          borderTopRightRadius: order === 1 ? '4px' : '0px',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            borderLeft: '4px solid white',
            borderRadius: '4px',
            height: '12px',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </div>
    </Fragment>
  );
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled?: boolean;
}

export const Track: React.FC<TrackProps> = ({
  source,
  target,
  getTrackProps,
  disabled,
}) => {
  const themes = useTheme();
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: 44,
        zIndex: 1,
        backgroundColor: disabled ? '#999' : 'rgba(0, 27, 83, 0.4)',
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
        border: `2px solid ${themes.colors.primary[100]}`,
      }}
      {...getTrackProps()}
    />
  );
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
interface TickProps {
  tick: SliderItem;
  count: number;
  format?: (val: number) => string;
  hideTime?: boolean;
}

export const Tick: React.FC<TickProps> = ({
  tick,
  count,
  hideTime = false,
}) => {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          width: 2,
          height: 44,
          opacity: 0.4,
          backgroundColor: theme.palette.blackRgb60,
          left: `${tick.percent}%`,
          transform: 'translate(0%, -50%)',
          zIndex: 1,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 25,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {!hideTime && toMMSS(tick.value).split('.')[0]}
      </div>
    </div>
  );
};
