import { CheckboxInput, CloseButton } from 'lib/components';
import {
  ButtonContainer,
  Content,
  ContentBody,
  ContentHeader,
  ContentHeaderWrap,
  ListContainer,
  ListItem,
  ModalItem,
} from 'lib/components/modal/importModal/Components';
import { csvDataProps } from 'lib/components/modal/importModal/Types';
import { getColValue } from 'lib/components/modal/importModal/Utils';
import * as React from 'react';
import { Button } from 'react-covideo-common';

type Props = {
  disabled: boolean;
  selectedUser: string[];
  colMapping: { [key: string]: string };
  invalidCount: number;
  CSVData: csvDataProps;
  invalidRows: { [key: string]: boolean };
  onSubmit(): void;
  handleModalClose(): void;
  setSelectedUser(user: string[]): void;
};
export const ImportedUsers = ({
  disabled,
  selectedUser,
  invalidCount,
  CSVData,
  colMapping,
  invalidRows,
  handleModalClose,
  setSelectedUser,
  onSubmit,
}: Props) => {
  return (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>Choose users to import</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <ListContainer>
            <ListItem key={`user-item-global`} bgColor={'#FFFFF'}>
              <div className='item--label'>
                <CheckboxInput
                  blueCheck={true}
                  checkGroupIndicator={false}
                  checked={
                    selectedUser.length == CSVData.data.length - invalidCount
                  }
                  onChange={(event: React.SyntheticEvent) => {
                    event.stopPropagation();
                    let { checked } = event.target as HTMLInputElement;
                    if (checked) {
                      setSelectedUser(
                        CSVData.data
                          .filter((e, i: number) => !invalidRows[i])
                          .map(e => getColValue(colMapping, e, 'username'))
                      );
                    } else setSelectedUser([]);
                  }}
                />
                <label>All Users</label>
              </div>
            </ListItem>
            {CSVData.data.map((e, i: number) => {
              if (invalidRows[i]) return null;
              return (
                <ListItem key={`user-item-${i}`}>
                  <div className='item--label'>
                    <CheckboxInput
                      blueCheck={true}
                      checkGroupIndicator={false}
                      checked={selectedUser.includes(
                        getColValue(colMapping, e, 'username')
                      )}
                      onChange={(event: React.SyntheticEvent) => {
                        event.stopPropagation();
                        let { checked } = event.target as HTMLInputElement;
                        if (checked)
                          setSelectedUser([
                            ...selectedUser,
                            getColValue(colMapping, e, 'username'),
                          ]);
                        else
                          setSelectedUser(
                            selectedUser.filter(
                              el => el != getColValue(colMapping, e, 'username')
                            )
                          );
                      }}
                    />
                    <label>{getColValue(colMapping, e, 'firstName')}</label>
                  </div>
                  <label>{getColValue(colMapping, e, 'email')}</label>
                </ListItem>
              );
            })}
          </ListContainer>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          disabled={!selectedUser.length}
          text={`Import ${selectedUser.length} users`}
          onClick={onSubmit}
        />
      </ButtonContainer>
    </ModalItem>
  );
};
