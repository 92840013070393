export enum UsageReportPackageTypes {
  ALL_USERS = 1,
  ALL_PAID = 2,
  LEGACY = 3,
  TRIAL = 4,
  FREEMIUM = 5,
  PRO = 6,
  BUSINESS = 7,
  ENTERPRISE = 8,
}

export const UsageReportPackageNames = {
  1: 'All users',
  2: 'All paid users',
  3: 'Legacy',
  4: 'Trial',
  5: 'Freemium',
  6: 'Pro',
  7: 'Business',
  8: 'Enterprise',
};
