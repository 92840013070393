import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const GridView = styled.div`
  margin-top: 40px;
  display: grid;
  box-sizing: border-box;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, 320px);
`;

const Item = styled.div`
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  box-sizing: border-box;
`;

export const GridItem = styled(Item)`
  position: relative;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  width: 300px;
  height: auto;
  margin: 0 0 45px 0;
`;

export const GridContent = styled.div`
  margin: 10px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.coal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  .alignDropdownMenu {
    top: 10px;
    right: 0px;
  }
  position: relative;
`;

export const ButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    background: ${theme.palette.blue05};
    border-radius: 5px;
  }
`;

export const LinkButton = styled(ButtonWrapper)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${theme.palette.blue80};
`;

type ThumbnailProps = {
  image: string;
};

const Thumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
`;

export const GridThumbnail = styled(Thumbnail)`
  height: 145px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${theme.palette.white};
  width: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  .activeTab {
    color: ${({ theme }) => theme.colors.primary[100]};
    border-bottom: 5px solid ${({ theme }) => theme.colors.primary[100]};
  }
  margin-top: 25px;
`;

interface TabStyleProperties {
  width?: string;
}

export const Tab = styled.div<TabStyleProperties>`
  border-bottom: 5px solid transparent;
  display: inline-flex;
  text-align: center;
  font-size: 18px;
  padding: 5px 15px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.palette.coal};
  align-items: center;
  width: ${props => (props.width ? props.width : 'fit-content')};
`;

export const ICON_COLOR = '#99A4BA';
