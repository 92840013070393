import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { ContentWrapper } from 'app/pages/library/videos/videoList/components/SendAndShare/send/SendTab';
import { useAuth } from 'lib/context';
import {
  Dropdown,
  LoadingIndicator,
  ModalVideoListVideo,
  TableContextProvider,
  Search,
  TablePaginationNew,
} from 'lib/components';
import { Button } from 'react-covideo-common';
import { HelpDesk } from '../../helpDesk';
import { EHelpDesk } from '../../helpDesk/utils';
import { SortButtons } from 'app/pages/admin/components/SortButtons';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { Gap } from '../../styles/layout';
import {
  useWheelsTvVideosQuery,
  WTVListItem,
} from 'lib/api/wheelsTv/useWheelsTvVideosQuery';
import { useWheelsTvSourceQuery } from 'lib/api/wheelsTv/useWheelsTvSourceQuery';
import { useWheelsTvCopyMutation } from 'lib/api/wheelsTv/useCopyWheelsTvMutation';
import { ParagraphSmallBold } from '../../styles/typography';
import { theme } from 'lib/style';
import { VideoItemThumbnail } from 'app/pages/library/videos/components';
import imagePlaceholder from 'assets/images/single/imagePlaceholder.webp';
import selectors from '../../../../cypress/selectors';
import { debounce } from 'lodash';

const ButtonWrapper = styled(Gap)`
  margin-bottom: 20px;
  svg {
    fill: rgba(52, 58, 64, 0.6);
  }
  svg.active {
    fill: black;
  }
`;

const Content = styled(ContentWrapper)`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 956px;
  padding-bottom: 0;
`;

const Video = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid rgb(223, 227, 230);
`;

const VideosList = styled.div`
  width: 100%;
  min-height: 530px;
`;

const BaseModalText = styled.div`
  height: 24px;
  margin-left: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left !important;
  color: ${theme.palette.title};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const BaseHeaderText = styled(BaseModalText)`
  font-size: 14px;
  color: ${theme.palette.blackRgb60};
`;
// vehicle year
const VehicleYearWidth = css`
  max-width: 185px;
  min-width: 40px;
`;
const VehicleYear = styled(BaseModalText)`
  ${VehicleYearWidth}
`;

const VehicleYearHeader = styled(BaseHeaderText)`
  ${VehicleYearWidth}
`;
// vehicle make
const VehicleMakeWidth = css`
  max-width: 180px;
  min-width: 130px;
`;
const VehicleMake = styled(BaseModalText)`
  ${VehicleMakeWidth}
`;
const VehicleMakeHeader = styled(BaseHeaderText)`
  ${VehicleMakeWidth}
`;
// vehicle model
const VehicleModelWidth = css`
  width: inherit;
`;
const VehicleModel = styled(BaseModalText)`
  ${VehicleModelWidth}
`;

const VehicleModelHeader = styled(BaseHeaderText)`
  ${VehicleModelWidth}
`;

const PreviewModelHeader = styled(BaseHeaderText)`
  width: 105px;
  margin-left: 0;
`;

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

const Footer = styled(ContentWrapper)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  padding-bottom: 0;
`;

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 10;
const DEFAULT_YEAR = new Date().getFullYear() + 1;

type Props = {
  showAddToCovideo?: boolean;
  handleModalClose?: () => void;
  onCopy?: () => void;
  onSelectVideo?: (lesaVideo: WTVListItem) => void;
};

export const WheelsTVTable = ({
  handleModalClose,
  onCopy,
  showAddToCovideo = true,
  onSelectVideo,
}: Props) => {
  const { userData } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [wtvVideoId, setWtvVideoId] = useState('');
  const [videoSource, setVideoSource] = useState('');
  const [selectedYear, setSelectedYear] = useState(DEFAULT_YEAR);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [order, setOrder] = useState<PaginationConstants>(
    PaginationConstants.ASCENDING
  );

  // consts
  const yearsOptions = () => {
    const years: { label: string; value: number }[] = [];

    for (let i = DEFAULT_YEAR; i > 2007; i--) {
      years.push({ value: i, label: i.toString() });
    }

    return years;
  };

  const resetPage = () => {
    setPage(0);
  };

  const onSuccesCallback = () => {
    onCopy?.();
    handleModalClose?.();
  };

  //queries
  const { data, refetch, isFetching } = useWheelsTvVideosQuery({
    start: page * DEFAULT_SIZE,
    limit: DEFAULT_SIZE,
    page,
    year: selectedYear,
    search: searchTerm,
    order,
  });

  useWheelsTvSourceQuery({ wtvVideoId, setVideoSource });

  // mutations
  const { mutateAsync: copyWheelsTvMutation, isLoading: isCopyingVideo } =
    useWheelsTvCopyMutation({
      onSuccesCallback,
    });

  const isLoading = isCopyingVideo || isFetching;

  // on click handlers
  const onOrderChangeHandler = (value: PaginationConstants) => {
    setOrder(value);
    resetPage();
  };

  const onYearChangeHandler = (value: { label: string; value: number }) => {
    setSelectedYear(value.value);
    resetPage();
  };

  const onSearchChangeHandler = async (search: string = '') => {
    setSearchTerm(search);
    resetPage();
  };

  const onPaginationChangeHandler = debounce(
    async ({ page: newPage }: { page: number }) => {
      if (page !== newPage) {
        setPage(newPage);
      }
    },
    300
  );

  const copyVideoTVMutationHandler = (video: WTVListItem) => {
    copyWheelsTvMutation({
      title: video.year + ' ' + video.make + ' ' + video.model,
      flvName: video.wtvVideoId,
      animatedGifPath: video.animatedGifPath,
      year: parseInt(video.year),
      make: video.make,
      model: video.model,
      wtvId: video.wtvId,
    });
  };

  useEffect(() => {
    refetch();
  }, [searchTerm, page, order, selectedYear]);

  const showSelectVideo = !!onSelectVideo;

  return (
    <Content>
      {userData.isAutomotive ? <HelpDesk name={EHelpDesk.WHEELS} /> : null}
      <ButtonWrapper>
        <SortButtons
          isSorted={true}
          order={order}
          onChange={onOrderChangeHandler}
        />

        <Dropdown
          className='dropdown'
          placeholder={'Filter by year...'}
          value={{
            value: selectedYear.toString() || '',
            label: selectedYear.toString() || '',
          }}
          extendStyles={{
            container: {
              width: '80px',
            },
          }}
          onChange={onYearChangeHandler}
          options={yearsOptions()}
        />

        <Search
          placeholder='Search videos...'
          onSearch={onSearchChangeHandler}
          width='300px'
        />
      </ButtonWrapper>
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
          {!data?.videos?.length && searchTerm ? (
            <EmptySearch>
              <ParagraphSmallBold color={theme.palette.blue60}>
                There are no videos with “{searchTerm}” in the title
              </ParagraphSmallBold>
              <ParagraphSmallBold color={theme.palette.blue60}>
                Try using a different search term
              </ParagraphSmallBold>
            </EmptySearch>
          ) : (
            <>
              <VideosList>
                <Video>
                  <PreviewModelHeader>Preview</PreviewModelHeader>
                  <VehicleYearHeader>Year</VehicleYearHeader>
                  <VehicleMakeHeader>Make</VehicleMakeHeader>
                  <VehicleModelHeader>Model</VehicleModelHeader>
                </Video>
                {data?.videos?.map((video: WTVListItem) => (
                  <Video key={video.wtvId}>
                    <VideoItemThumbnail
                      handleClick={() => setWtvVideoId(video.wtvVideoId)}
                      thumbnail={video.animatedGifPath || imagePlaceholder}
                      videoId={video.wtvVideoId}
                      processing={0}
                      thumbnailHeight={'80%'}
                      thumbnailWidth={'15%'}
                    />
                    <VehicleYear>{video.year}</VehicleYear>
                    <VehicleMake>{video.make}</VehicleMake>
                    <VehicleModel>{video.model}</VehicleModel>
                    <Gap flexWrap='noWrap' gap='10px'>
                      {showAddToCovideo && (
                        <Button
                          variant='secondary'
                          text='Add to Covideo'
                          onClick={() => copyVideoTVMutationHandler(video)}
                          data-cy={
                            selectors.libraryPage.wheelsTvAddToCovideoButton
                          }
                        />
                      )}
                      {showSelectVideo && (
                        <Button
                          variant='secondary'
                          text='Select Video'
                          onClick={() => onSelectVideo(video)}
                        />
                      )}
                    </Gap>
                  </Video>
                ))}
              </VideosList>
              <Footer>
                <TableContextProvider
                  total={data?.count || 0}
                  initSize={DEFAULT_SIZE}
                  onChange={onPaginationChangeHandler}
                  initPage={page}
                >
                  <TablePaginationNew disabled={isLoading} />
                </TableContextProvider>
              </Footer>
            </>
          )}

          {!!videoSource && (
            <ModalVideoListVideo
              videoId={''}
              source={videoSource}
              handleModalClose={() => {
                setVideoSource('');
                setWtvVideoId('');
              }}
              hideContext={true}
            />
          )}
        </>
      )}
    </Content>
  );
};
