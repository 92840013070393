import { OptOutListFilter, SuperAdminCustomerDetails } from 'lib/context';
import React from 'react';
import { NoList } from '../../index.styled';
import { SearchAndFilterForOptOuts } from './SearchAndFilterForOptOuts';
import { LoadingIndicator } from 'lib/components';
import { debounce } from 'lodash';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import {
  exportOptOutReportsData,
  useOptOutReportsQuery,
} from 'lib/api/superAdminApi';
import { OptInModal } from './OptInModal';
import { OptOutTable } from './OptOutsTable';
import { OptOutContact } from 'lib/api';
import { errorToast } from 'lib/components/toasts/error';

const tableFields = [
  {
    value: 'firstName',
    label: 'First Name',
    sortable: true,
    sortKey: 'FIRSTNAME',
  },
  {
    value: 'lastName',
    label: 'LastName',
    sortable: true,
    sortKey: 'LASTNAME',
  },
  {
    value: 'email',
    label: 'Sender Email',
  },
  {
    value: 'optOutEmail',
    label: 'Opt Out Email',
  },
  {
    value: 'optOutDate',
    label: 'Opt Out Date',
    sortable: true,
    sortKey: 'OPTOUTDATE',
  },
];

type Props = {
  customer: SuperAdminCustomerDetails;
  prevSearch?: string;
  sortKey?: string;
  order?: PaginationConstants;
  page?: number;
  size?: number;
  onSearch(search: string): void;
  onSorting(sortKey: string, order: PaginationConstants): void;
  onPageAndSizeChange(page: number, size: number): void;
};

export const OptOutReports = ({
  customer,
  prevSearch,
  sortKey,
  order,
  page,
  size,
  onSearch,
  onSorting,
  onPageAndSizeChange,
}: Props) => {
  const [optOutRecord, setSelectedOptOutReport] = React.useState(
    {} as OptOutContact
  );
  const [showOptInModal, setShowOptInModal] = React.useState(false);

  const filter: OptOutListFilter = {
    sortKey: sortKey,
    order: order ?? PaginationConstants.ASCENDING,
    page: page ?? DEFAULT_PAGE,
    size: size ?? DEFAULT_SIZE,
    searchQuery: prevSearch,
    customerId: customer.customerId,
  };

  const {
    count,
    optOutReportsArray,
    refetch: refreshOptOutReports,
    isLoading: showLoading,
  } = useOptOutReportsQuery({
    params: {
      start: (filter.page || DEFAULT_PAGE) * (filter.size || DEFAULT_SIZE),
      limit: filter.size || DEFAULT_SIZE,
      search: filter.searchQuery,
      sortKey: filter.sortKey,
      order: filter.order,
      customerId: filter?.customerId,
    },
    filter,
    enabled: true,
    onSuccess: () => setSelectedOptOutReport({} as OptOutContact),
  });

  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      onPageAndSizeChange(newPage, newSize);
    },
    300
  );

  const downloadOptOutReports = async () => {
    try {
      const result = await exportOptOutReportsData(`opt-outs.csv`, {
        search: filter.searchQuery,
        sortKey: filter.sortKey,
        order: filter.order,
        customerId: filter.customerId,
      });
      return result;
    } catch (error) {
      errorToast({ title: `Error in downloading Opt Out Reports.` });
    }
  };

  return (
    <>
      <SearchAndFilterForOptOuts
        onSearch={onSearch}
        prevSearch={filter.searchQuery}
        downloadOptOutReports={downloadOptOutReports}
      />
      {showLoading && (
        <LoadingIndicator isLoading={showLoading} height='300px' />
      )}
      {!showLoading && !!optOutReportsArray.length && (
        <OptOutTable
          count={count}
          optOutReportsArray={optOutReportsArray}
          page={filter.page}
          size={filter.size}
          sortKey={filter.sortKey}
          order={filter.order}
          tableFields={tableFields}
          onPaginationChange={onPaginationChange}
          onSortingUpdate={onSorting}
          setSelectedOptOutReport={setSelectedOptOutReport}
          setShowOptInModal={setShowOptInModal}
        />
      )}
      {showOptInModal && (
        <OptInModal
          optOutRecord={optOutRecord}
          handleModalClose={shouldRefresh => {
            setShowOptInModal(false);
            if (shouldRefresh) refreshOptOutReports();
          }}
        />
      )}
      {!showLoading && !optOutReportsArray.length && (
        <NoList>No Opt Out Reports to show.</NoList>
      )}
    </>
  );
};
