import React from 'react';
import { InfoIcon } from './assets/social-icons';
import { BoxContent, BoxContentWrapper, InfoBoxWrapper } from './style';

export const InfoBox = () => {
  return (
    <InfoBoxWrapper>
      <InfoIcon style={{ flexShrink: 0 }} />
      <BoxContentWrapper>
        <BoxContent>
          Publish your video natively to your social media profile. Currently,
          this feature supports publishing directly to Facebook business pages,
          Youtube channels, LinkedIn organizations pages and LinkedIn personal
          profiles.
        </BoxContent>
        <BoxContent>
          Captions, annotations, and the customized player won't be available on
          those platforms since we are publishing only an original video file.
        </BoxContent>
        <BoxContent>
          You'll find the 'Social' section within a video's analytics page,
          where you can see how a video is performing on social platforms where
          you've published a video.
        </BoxContent>
      </BoxContentWrapper>
    </InfoBoxWrapper>
  );
};
