import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation, useQueryClient } from 'react-query';
import { folderAccessKeys } from './folderAccessKeys';
import { FolderAccessBody } from './types';
import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';

export const updateFolderAccess = async (data: Partial<FolderAccessBody>) => {
  const response = await EXPRESS_API.put(`/folders/${data.folderId}/access`, {
    users: data.users,
    isSelectAll: data.isSelectAll,
    selectedCustomerIds: data.selectedCustomerIds,
    folderAccessPermission: data.folderAccessPermission,
  });
  return response.data;
};

export const useUpdateFolderAccess = () => {
  const queryClient = useQueryClient();
  const { showError } = useToastError();

  return useMutation(
    (data: Partial<FolderAccessBody>) => updateFolderAccess(data),
    {
      onSuccess: res => {
        queryClient.invalidateQueries(
          folderAccessKeys.folder_access_update(res.folderId)
        );
      },
      onError: (err: AxiosError) => {
        showError(
          err ||
            'An error occurred updating the folder access, please try again later!'
        );
      },
    }
  );
};
