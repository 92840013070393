import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { VideoDeletionApprovalProps } from './types';
import { videoDeletesKeys } from './queryKeys';

export const denyVideoDelete = async (data: VideoDeletionApprovalProps) => {
  const response = await EXPRESS_API.put(
    `/stop-video-deletes/${data.requestId}/deny`,
    data
  );
  return response.data;
};

export const useDenyVideoDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(denyVideoDelete, {
    onSuccess: () => {
      successToast({
        title: 'Video deletion successfully denied!',
      });
      queryClient.refetchQueries(videoDeletesKeys.get_video_delete());
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred when attempting to deny the video. Please try again later.',
      });
    },
  });
};
