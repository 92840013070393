import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
const Bull = styled.span`
  :after {
    content: '•';
    margin: 0 6px;
    color: ${theme.palette.gray80};
  }
`;
const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
`;
const NoteTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.palette.covideoBlue100};
`;
const NoteInfo = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
  margin-left: 20px;
`;
const NoteText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.coal};
`;
type Props = {
  note: string;
  user?: string;
  createdAt: string;
};
export const Note = ({ note, user = '', createdAt }: Props) => {
  return (
    <>
      <NoteHeader>
        <NoteTitle>Note</NoteTitle>
        <NoteInfo>
          {user}
          <Bull />
          {createdAt}
        </NoteInfo>
      </NoteHeader>
      <NoteText>{note}</NoteText>
    </>
  );
};
