import React from 'react';
import styled from 'styled-components/macro';
import { Search, Dropdown } from 'lib/components';
import { VerificationStatusOptions } from 'lib/const/VerificationsStatusOptions';
import { VerificationStatus } from 'lib/const/VerificationStatus';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  justify-content: start;
`;

type FilterProps = {
  marginRight?: number;
  width?: number;
};
const Filter = styled.div<FilterProps>`
  min-width: 224px;
  width: ${props => `${props.width}px` || 'auto'};
  margin-right: ${props => `${props.marginRight}px` || '0px'};
`;

type Props = {
  prevSearch?: string;
  statusFilter?: number;
  onSelectStatus(statusValue: VerificationStatus): void;
  onSearch(search: string): void;
};

export const UsersSearchAndFilter = ({
  prevSearch,
  statusFilter,
  onSelectStatus,
  onSearch,
}: Props) => {
  return (
    <FiltersContainer>
      <Filter width={240} marginRight={12}>
        <Search
          placeholder='Search users...'
          onSearch={onSearch}
          prevSearch={prevSearch}
        />
      </Filter>
      <Filter width={152} marginRight={12}>
        <Dropdown
          menuPortalTarget={document.body}
          menuPlacement={'bottom'}
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'Filter by Statuses'}
          zIndexProp={1}
          value={VerificationStatusOptions.find(
            option => option.value === statusFilter
          )}
          onChange={value => onSelectStatus(value.value)}
          options={VerificationStatusOptions}
        />
      </Filter>
    </FiltersContainer>
  );
};
