import React, { useEffect, useState } from 'react';

import { ModalVideoTags } from 'lib/components/modal/ModalVideoTags';
import { VideoTag } from 'lib/components/VideoTag';
import { useHistory } from 'react-router';
import { useAuth } from 'lib/context';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';
import { Button } from 'react-covideo-common';
import { useFormikContext } from 'formik';
import { Gap, Stack } from 'lib/components/styles/layout';
import { DetailsPreviewRecordFormValues, VideoTagOption } from '../types';
import { MdDeleteForever } from 'react-icons/md';
import { FaHashtag } from 'react-icons/fa';

export const DetailsRecordPreviewTags = () => {
  const history = useHistory();
  const { values, setFieldValue } =
    useFormikContext<DetailsPreviewRecordFormValues>();
  const { userData } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSave = (values: VideoTagOption[]) => {
    setFieldValue('videoTags', values);
    closeModal();
  };

  const [isTagFeatureAvailable, setIsTagFeatureAvailable] = useState(true);

  useEffect(() => {
    const isTagEnabled = checkIfFeatureIsEnabled(
      userData,
      productFeature.VIDEO_TAGS
    );
    setIsTagFeatureAvailable(isTagEnabled);
  }, []);

  return (
    <Stack alignItems='flex-start' width='100%' m='32px 0 32px 0'>
      <Gap width='100%' justifyContent='space-between'>
        <Button
          variant='destructive'
          icon={<MdDeleteForever size={18} />}
          text={'Remove this video'}
          onClick={() => {
            history.push('/record/home');
          }}
        />
        {isTagFeatureAvailable && (
          <Button
            variant='secondary'
            icon={<FaHashtag size={'16px'} />}
            text={'Add Tags...'}
            onClick={openModal}
          />
        )}
      </Gap>
      {!!values?.videoTags?.length && (
        <Gap gap='4px' m='4px 0 0 0'>
          {values?.videoTags?.map(tag => (
            <VideoTag key={tag.value} tag={tag} />
          ))}
        </Gap>
      )}
      {showModal && (
        <ModalVideoTags
          videoTags={values?.videoTags}
          handleModalClose={closeModal}
          onSubmit={onSave}
          title={'Manage Tags'}
          buttonTitle={'Save Changes'}
        />
      )}
    </Stack>
  );
};
