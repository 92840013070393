import React from 'react';
import { DocumentHead } from 'lib/components';
import { ImsRoutes } from './main/routes';

export const InventoryManagmentSystem = () => (
  <>
    <DocumentHead title='Inventory Managment System' />
    <ImsRoutes />
  </>
);
