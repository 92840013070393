import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { LoadingIndicator } from 'lib/components';
import { EXPRESS_API } from 'configs/api/expressApi';

export const Main = () => {
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const history = useHistory();

  const shareVideoToLinkedin = async (
    videoId: string,
    title: string,
    message: string,
    url: string
  ) => {
    setLoading(true);

    try {
      const response = await EXPRESS_API.post('/linkedin/share-video', {
        videoId,
        title,
        message,
      });
      if (response.data && response.data.postId) {
        setTimeout(() => {
          window.location.href = `https://www.linkedin.com/company/${response.data.organizationId}/admin/`;
          setLoading(false);
        }, 20000);
      } else {
        navigateToSharePost(url);
      }
    } catch (error) {
      navigateToSharePost(url);
    }
  };

  const navigateToSharePost = (url: string) => {
    window.location.href = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
  };

  React.useEffect(() => {
    const queryParams: any = queryString.parse(location.search);
    const videoId = Cookies.get('shareVideoId');
    const postMessage = Cookies.get('sharePost') || '';
    const postTitle = Cookies.get('shareTitle') || '';
    if (queryParams.code && videoId) {
      shareVideoToLinkedin(queryParams.code, videoId, postTitle, postMessage);
    } else {
      history.push('/home');
    }
  }, [location.search]);

  if (!loading) {
    return null;
  }

  return <LoadingIndicator isLoading={loading} height='700px' />;
};
