import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';

export const Overlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.palette.whiteRgb75};
  z-index: 4;
`;

type fileProps = {
  fileLength?: any;
};

export const Input = styled.input`
  font-size: ${theme.fontSizes.md};
  color: black;
  outline: none;
  border: 0px solid ${theme.palette.grayBorder};
  box-sizing: border-box;
  display: block;
  width: 65%;
  padding: 12px 16px;
  height: 40px;
  border-radius: 6px;
  margin: 0 0 0;
`;

export const Content = styled.div`
  padding: 60px 30px 40px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Tooltip-Wrapper {
    width: auto;
    top: -12px;
    left: 20px;
    .Tooltip-Tip {
      left: 248px;
      top: -106px !important;
    }
  }
`;

export const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  margin-bottom: 10px;
  position: absolute;
  top: 32px;
  padding: 0 32px;
  width: 528px;
`;

type BodyProps = {
  showBackground: boolean;
  showProgressHeaderInline?: boolean;
};

export const ContentBody = styled.div<BodyProps>`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  color: ${theme.palette.blackRgb75};
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  width: 592px;
  min-height: 340px;
  max-height: 340px;
  background-color: ${props => (props.showBackground ? '#f6f7f9' : '')};
  overflow-y: auto;
  overflow-x: hidden !important;

  ${props =>
    props.showProgressHeaderInline &&
    css`
      .pd30 {
        padding: 30px;
      }
      .header {
        background-color: ${props.showBackground ? '#f6f7f9' : ''};
        padding: 10px;
        height: 20px;
        top: 17.5px;
      }
    `}
`;

export const ContentFooter = styled.div<fileProps>`
  display: flex;
  visibility: ${props => (props.fileLength ? 'visible' : 'hidden')};
  position: absolute;
  bottom: -12px;
  background-color: #ffffff;
  justify-content: flex-end;
  margin-top: 12px;
  width: 592px;
`;

export const CancelContainer = styled.div`
  display: flex;
  height: 249px;
  padding: 32px;
  align-items: center;
`;

type InputLabelProps = {
  noButtonPadding?: boolean;
  isAddMoreEnabled?: boolean;
  disabled?: boolean;
};

export const InputLabel = styled.label<InputLabelProps>`
  padding: ${props =>
    props.disabled ? `8px 16px 8px 35px` : `8px 16px 8px 12px`};
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${props => (props.noButtonPadding ? 0 : '20px')};
  display: flex;
  align-items: center;
  cursor: ${props => (props.isAddMoreEnabled ? 'pointer' : 'not-allowed')};
  height: 24px;
  font-size: 15px;
  color: ${theme.palette.covideoBlue100};
  background-color: ${theme.palette.covideoBlue02NonAlpha};
  border-radius: 6px;
  border: 1px solid ${theme.palette.covideoBlack05};
  font-weight: 600;

  ${props =>
    props.disabled &&
    css`
      cursor: ${props.isAddMoreEnabled ? 'pointer' : 'not-allowed'};
    `}
`;

export const LockIconContainer = styled.div<InputLabelProps>`
  margin-left: 10px;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: ${props => (props.noButtonPadding ? 0 : '20px')};
`;

type DisabledInputContainerProps = {
  isAddMoreEnabled?: boolean;
};

export const DisabledInputContainer = styled.div<DisabledInputContainerProps>`
  position: relative;
  bottom: ${props => (props.isAddMoreEnabled ? '18px' : '30px')};
  width: 300px;
  opacity: 0.4;
`;
