import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${theme.palette.label};
  border-bottom: 4px solid transparent;
  &.active {
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary[100]};
    color: ${({ theme }) => theme.colors.primary[100]};
  }
`;

export const ActivityList = styled.div`
  padding: 24px 16px;
  overflow-y: auto;
  height: calc(100vh - 280px);
`;

export const ActivityItem = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6px;
  box-sizing: border-box;
`;

export const ActivityTitle = styled.label``;

export const ActivityDate = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blue60};
  margin-top: 8px;
`;

export const ThumbnailContainer = styled.div`
  font-size: 120px;
  text-align: center;
`;

export const Heading = styled.h2`
  margin-top: 10px;
  color: #001b53;
  text-align: center;
`;

export const SubHeading = styled.h5`
  margin-top: 10px;
  color: #001b53;
  text-align: center;
`;

export const Container = styled.div`
  margin-top: 60px;
`;

export const Menu = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeff2;
  background-color: ${theme.palette.white};
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  font-size: 15px;
  color: #001b53cc;
`;

export const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
  margin-right: 8px;
`;

export const LinkText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

export const EditIconWrapper = styled.div`
  margin-left: 8px;
  cursor: pointer;
  display: flex;
`;

export const ActionsButtons = styled.div<{ display?: string; gap?: string }>`
  margin-right: 24px;
  display: ${props => props.display || ''};
  gap: ${props => props.gap || 0};
`;

export const Content = styled.div`
  display: flex;
  height: calc(100vh - 180px);
`;

export const ImageWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  .flexButton {
    display: flex;
  }
  align-content: center;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  background: #f6f7f9;
`;

export const ImagePreview = styled.div`
  max-width: 1028px;
  video {
    cursor: pointer;
  }
`;

export const Image = styled.img`
  position: relative;
  z-index: 2;
  max-width: 100%;
  max-height: calc(100vh - 240px);
  height: auto;
  width: auto;
  display: block;
  margin: auto;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${theme.palette.white};
`;

export const Sidebar = styled.div`
  max-width: 256px;
  width: 100%;
`;

export const SidebarHeader = styled.div`
  height: 50px;
  display: flex;
  border-bottom: 1px solid #eeeff2;
  justify-content: center;
  align-content: center;
  cursor: pointer;
`;

export const Permissions = styled.div`
  padding: 24px 16px;
  border-bottom: 1px solid #eeeff2;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

export const Info = styled.div`
  padding: 32px 16px;
  border-bottom: 1px solid #eeeff2;
`;

export const Views = styled.div`
  padding: 32px 16px;
  display: flex;
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.label};
  width: 50%;
`;

export const LabelValue = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.coal};
`;

export const SidebarTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #001b53;
  margin-top: 24px;
  margin-bottom: 10px;
`;

export const NoTags = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${theme.palette.midGrey};
  margin-top: 8px;
`;

export const RemainingDropdown = styled.div`
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 8px 6px 14px 14px;
  border-radius: 4px;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
  margin-left: -8px;
`;
