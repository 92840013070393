import React, { useEffect, useRef, useState } from 'react';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Gap, Stack } from 'lib/components/styles/layout';
import {
  Heading,
  ParagraphNormal,
  ParagraphSmallBold,
} from 'lib/components/styles/typography';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { IoPersonCircle } from 'react-icons/io5';
import { MdLaptop, MdPerson, MdSettings } from 'react-icons/md';
import styled from 'styled-components/macro';
import {
  enableCamera,
  getSafariScreenAndCameraStreams,
  getScreenAndCameraStreams,
  handleScreenCapture,
} from './utils/media';
import { ModalRecordingVideosSettings } from './ModalRecordingVideosSettings';
import { RecordBoth } from './RecordBoth';
import { RecordCam } from './RecordCam';
import { ScreenRecorder } from './ScreenRecorder';
import { useRecordSettings } from 'lib/context/record/provider';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { useAuth } from 'lib/context';
import { isOpera, isFirefox, isSafari } from 'lib/utils/browserChecks';
import { TeleprompterProvider } from 'lib/context/teleprompter/provider';
import { getAudioSettings, getVideoSettings } from './utils/recorderSettings';
import { useHistory } from 'react-router-dom';

const RecordOption = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  width: 108px;
  height: 108px;
  padding: 32px;
  border-radius: 4px;
  border: solid 2px #eeeff2;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[80]};

  &:hover {
    cursor: ${({ error }) => (error ? 'not-allowed' : 'pointer')};
    border: solid 2px #d0d3d9;
  }
`;

const MainRecordContentWrapper = styled(Gap)`
  width: 592px;
  padding: 32px 104px 51px;
  border-radius: 8px;
  margin: 56px auto 0 auto;
  background-color: ${({ theme }) => theme.colors.neutral[10]};
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 24px 0;
  }
`;

const LegacyLink = styled.a`
  color: #0000ee;
  &:visited {
    color: #0000ee;
  }
`;

const RecordingOptionWrapper = styled(Gap)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ShowRecorderWrapper = styled.div`
  width: 770px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const IoPersonCircleWrapper = styled.div`
  background-color: white;
  border-radius: 50px;
  position: absolute;
  border: 2px solid white;
  bottom: 65px;
  left: 35px;
`;

export const ChooseRecordingOptions = ({
  extendStyles,
  showHelpDesk,
  showPreview,
  onStartUpladingVoiceOverHandler,
  showLegacyRecorderSwitch,
}: {
  extendStyles?: {
    mainRecordContentWrapper?: React.CSSProperties;
    showRecorderWrapper?: React.CSSProperties;
  };
  showHelpDesk: boolean;
  showPreview?: boolean;
  onStartUpladingVoiceOverHandler?: (blob: Blob) => void;
  showLegacyRecorderSwitch: boolean;
}) => {
  const history = useHistory();
  const { whitelabel } = useAuth();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { recordingError, updateErrorMessage, userSettings } =
    useRecordSettings();
  const { colors } = useCovideoTheme();
  const [showRecordOption, setShowRecordOption] = useState<
    'cam' | 'screen' | 'both' | null
  >(null);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  // main stream
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
  // helpers for cleaning streams
  const displayMediaRef = useRef<MediaStream | null>(null);
  const cameraStreamRef = useRef<MediaStream | null>(null);
  // PIP ref
  const videoCameraPipRef = useRef<HTMLVideoElement>(null);

  const audioSettings = getAudioSettings(userSettings);
  const videoSettings = getVideoSettings(userSettings);

  const stopAllStreams = async () => {
    if (displayMediaRef.current) {
      displayMediaRef.current.getTracks().forEach(track => track.stop());
      displayMediaRef.current = null;
    }
    if (cameraStreamRef.current) {
      cameraStreamRef.current.getTracks().forEach(track => track.stop());
      cameraStreamRef.current = null;
    }
    if (screenStream) {
      screenStream.getTracks().forEach(track => track.stop());
    }
    if (document.pictureInPictureElement) {
      await document.exitPictureInPicture();
    }
    if (videoCameraPipRef.current && videoCameraPipRef.current.srcObject) {
      const stream = videoCameraPipRef.current.srcObject as MediaStream;

      stream.getTracks().forEach(track => track.stop());

      videoCameraPipRef.current.srcObject = null;
    }
  };

  const screenCaptureHandler = async () => {
    if (recordingError || isPromptOpen) {
      return;
    }
    try {
      setIsPromptOpen(true);
      const stream = await handleScreenCapture(
        displayMediaRef,
        audioSettings,
        videoSettings
      );
      if (stream) {
        setScreenStream(stream);
        setShowRecordOption('screen');
      }
      setIsPromptOpen(false);
    } catch (error) {
      stopAllStreams();
      setIsPromptOpen(false);
      console.log(error, 'screenCaptureHandler');
    }
  };

  const enableCameraHandler = async () => {
    if (recordingError || isPromptOpen) {
      return;
    }
    try {
      setIsPromptOpen(true);
      const stream = await enableCamera(audioSettings, videoSettings);
      if (stream) {
        setScreenStream(stream);
        setShowRecordOption('cam');
      }
      setIsPromptOpen(false);
    } catch (error) {
      stopAllStreams();
      setIsPromptOpen(false);
      console.log(error, 'enableCameraHandler');
      updateErrorMessage();
    }
  };

  const enableBothHandler = async () => {
    if (recordingError || videoCameraPipRef.current === null || isPromptOpen) {
      return;
    }

    try {
      setIsPromptOpen(true);
      const stream = !isSafari
        ? await getScreenAndCameraStreams(
            displayMediaRef,
            cameraStreamRef,
            audioSettings,
            videoSettings,
            videoCameraPipRef
          )
        : await getSafariScreenAndCameraStreams(
            displayMediaRef,
            cameraStreamRef,
            audioSettings,
            videoSettings,
            videoCameraPipRef
          );

      setScreenStream(stream);
      setShowRecordOption('both');
      setIsPromptOpen(false);
    } catch (error) {
      stopAllStreams();
      console.log(error, 'enableCameraHandler');
      setIsPromptOpen(false);
    }
  };

  const handleGoToRecordHome = () => {
    setShowRecordOption(null);
    stopAllStreams();
  };

  useEffect(() => {
    handleGoToRecordHome();
  }, [history.location]);

  return (
    <>
      {showHelpDesk && showRecordOption === null && (
        <HelpDesk name={EHelpDesk.RECORDED} />
      )}

      <Stack gap='32px'>
        {showRecordOption === null && (
          <MainRecordContentWrapper
            flexDirection='column'
            gap='44px'
            style={extendStyles?.mainRecordContentWrapper}
          >
            <Heading color={colors.black[100]}>Choose recording option</Heading>
            <RecordingOptionWrapper gap='32px'>
              {!isOpera && !isFirefox && (
                <RecordOption
                  onClick={enableBothHandler}
                  style={{ position: 'relative' }}
                  error={!!recordingError}
                >
                  <MdLaptop size={72} />
                  <IoPersonCircleWrapper>
                    <IoPersonCircle size={28} />
                  </IoPersonCircleWrapper>
                  <ParagraphNormal color={colors.black[100]}>
                    Screen+Cam
                  </ParagraphNormal>
                </RecordOption>
              )}
              <RecordOption
                onClick={screenCaptureHandler}
                error={!!recordingError}
              >
                <MdLaptop size={72} />
                <ParagraphNormal color={colors.black[100]}>
                  Screen Only
                </ParagraphNormal>
              </RecordOption>
              <RecordOption
                onClick={enableCameraHandler}
                error={!!recordingError}
              >
                <MdPerson size={72} />
                <ParagraphNormal color={colors.black[100]}>
                  Cam Only
                </ParagraphNormal>
              </RecordOption>
            </RecordingOptionWrapper>
          </MainRecordContentWrapper>
        )}
        {!!recordingError && showRecordOption === null && (
          <ParagraphSmallBold>{recordingError}</ParagraphSmallBold>
        )}

        {showRecordOption === 'screen' &&
          screenStream instanceof MediaStream && (
            <ShowRecorderWrapper style={extendStyles?.showRecorderWrapper}>
              <ScreenRecorder
                screenStream={screenStream}
                stopAllStreams={stopAllStreams}
                handleGoToRecordHome={handleGoToRecordHome}
                showPreview={showPreview}
                onStartUpladingVoiceOverHandler={
                  onStartUpladingVoiceOverHandler
                }
              />
            </ShowRecorderWrapper>
          )}

        {showRecordOption === 'cam' && screenStream instanceof MediaStream && (
          <ShowRecorderWrapper style={extendStyles?.showRecorderWrapper}>
            <TeleprompterProvider>
              <RecordCam
                screenStream={screenStream}
                stopAllStreams={stopAllStreams}
                handleGoToRecordHome={handleGoToRecordHome}
                showPreview={showPreview}
                onStartUpladingVoiceOverHandler={
                  onStartUpladingVoiceOverHandler
                }
              />
            </TeleprompterProvider>
          </ShowRecorderWrapper>
        )}
        {showRecordOption === 'both' && screenStream instanceof MediaStream && (
          <ShowRecorderWrapper style={extendStyles?.showRecorderWrapper}>
            <RecordBoth
              screenStream={screenStream}
              stopAllStreams={stopAllStreams}
              handleGoToRecordHome={handleGoToRecordHome}
              showPreview={showPreview}
              onStartUpladingVoiceOverHandler={onStartUpladingVoiceOverHandler}
            />
          </ShowRecorderWrapper>
        )}

        <video ref={videoCameraPipRef} style={{ display: 'none' }} />

        {showRecordOption === null && (
          <Button
            icon={<MdSettings size={18} />}
            variant='secondary'
            text={'Recording settings'}
            onClick={() => setSettingsOpen(true)}
          />
        )}
        {whitelabel.name === WHITELABEL_NAME.COVIDEO &&
          showLegacyRecorderSwitch &&
          showRecordOption === null && (
            <LegacyLink href='/Email_RecordVideo'>
              Switch to Legacy Recorder
            </LegacyLink>
          )}
      </Stack>
      {settingsOpen && (
        <ModalRecordingVideosSettings
          handleModalClose={() => setSettingsOpen(false)}
        />
      )}
    </>
  );
};
