import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { teleprompterKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const copyScript = async (scriptId: number) => {
  const response = await EXPRESS_API.post(`/teleprompter/${scriptId}/copy`);
  return response.data;
};

export const useCopyScript = (scope: string) => {
  const queryClient = useQueryClient();

  return useMutation((scriptId: number) => copyScript(scriptId), {
    onSuccess: () => {
      queryClient.invalidateQueries(teleprompterKeys.list(scope));

      successToast({ title: 'Successfully copied to My Scripts!' });
    },
    onError: () => {
      errorToast({ title: 'There was an error with copying your script.' });
    },
  });
};
