import {
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { VideoRequest, useAuth } from 'lib/context';
import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { VideoItem } from '../../components';
import { VideoItemType } from '../../components/VideoItem';
import { successToast } from 'lib/components/toasts/success';
import { AutomotiveRole, VideoEmailStatus, VideoStatus } from 'lib/const';
import { Filters } from '../components';
import { toNumber } from 'lodash';
import { NotFound } from 'app/pages/notFound/NotFound';
import {
  useVideoRequestsQuery,
  VideoRequestListFilter,
} from 'lib/api/videoRequests/useVideoRequestsQuery';
import { useEditVideoRequestMutation } from 'lib/api/videoRequests/useEditVideoRequestMutation';

const Wrapper = styled.div`
  margin: 64px auto 64px 208px;
  min-height: calc(100vh - 195px);
  padding: 32px;
`;
const Container = styled.div`
  width: 1048px;
  max-width: 100%;
  margin: 0 auto;
`;
const Title = styled.h1`
  color: ${theme.palette.gray100};
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 32px;
`;
const Subtitle = styled.h3`
  color: ${theme.palette.gray80};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 48px;
`;

const EmptyList = styled.p`
  margin: 0;
  font-size: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PendingVideoRequestsWrapper = styled.div`
  margin-bottom: 8px;
`;

export const List = () => {
  const { userData } = useAuth();
  const isTechnician = userData.automotiveRole === AutomotiveRole.TECHNICIAN;

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [filter, setFilter] = useState<VideoRequestListFilter>({
    videoType: '',
    userId: '',
    advisorId: '',
    search: '',
    statuses: VideoStatus.NoStatus,
  });
  const isFiltered = !!Object.values(filter).find(f => f);

  const { mutateAsync: editVideoRequestMutation } =
    useEditVideoRequestMutation();

  const {
    data: pastVideoRequestsData,
    isLoading: isPastVideoRequestsLoading,
    refetch: refetchPastVideoRequests,
  } = useVideoRequestsQuery(
    {
      start: page * size,
      limit: size,
      filter: {
        ...filter,
        statuses: isFiltered
          ? filter.statuses || ''
          : `${VideoStatus.Approved},${VideoStatus.Denied}`,
      },
    },
    true
  );

  const {
    data: pendingVideoRequestsData,
    isLoading: isPendingVideoRequestsLoading,
    refetch: refetchPendingVideoRequests,
  } = useVideoRequestsQuery(
    {
      start: 0,
      limit: 100,
      filter: {
        statuses: `${VideoStatus.Pending}`,
      },
    },
    !isFiltered
  );

  const pastVideoRequests = (pastVideoRequestsData?.videoRequests || []).filter(
    (videoRequest: VideoRequest) => !!videoRequest?.video
  );
  const pastVideoRequestsCount = pastVideoRequestsData?.count || 0;
  const pendingVideoRequests = (
    pendingVideoRequestsData?.videoRequests || []
  ).filter((videoRequest: VideoRequest) => !!videoRequest?.video);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  const refreshVideoRequests = async () => {
    refetchPastVideoRequests();
    refetchPendingVideoRequests();
  };

  const handleReassignSubmit = async (
    advisorId: string,
    videos: any[],
    advisor: any
  ) => {
    const advisorName =
      advisor.firstName && advisor.lastName
        ? `${advisor.firstName} ${advisor.lastName}`
        : 'New Advisor';
    const videoRequestArray = [...pastVideoRequests, ...pendingVideoRequests];
    const videoRequestIds = videoRequestArray
      .filter((videoRequest: VideoRequest) => {
        return videos.find((video: any) => video.id === videoRequest.videoId);
      })
      .map((videoRequest: VideoRequest) => {
        return videoRequest.videoRequestId;
      });
    await Promise.all(
      videoRequestIds.map(async (videoRequestId: string | number) => {
        const videoRequest = videoRequestArray.find(
          vr => vr.videoRequestId === videoRequestId
        );
        if (videoRequest) {
          await editVideoRequestMutation({
            data: {
              advisorId: advisorId,
              status:
                videoRequest?.emailStatus === VideoEmailStatus.NotSent
                  ? VideoStatus.Pending
                  : videoRequest.status,
            },
            videoRequestId,
          });
        }
      })
    );
    await refreshVideoRequests();
    successToast({
      title: `${advisorName} is now assigned to ${
        videos.length > 1 ? 'these video requests' : 'this video request'
      }!`,
    });
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const hasPendingVideoRequests = !!pendingVideoRequests.length;
  const hasPastVideoRequests = !!pastVideoRequests.length;

  // show pending vr separately if there are any and if vr list not filtered (when fitlered show all together)
  const showPendingSeparately = hasPendingVideoRequests && !isFiltered;

  const handleOnMarkAsSentSuccessCallback = async () => {
    await refreshVideoRequests();
  };

  // video request page available only for service non technician roles
  if (!userData.isAutomotiveServiceRole || isTechnician) {
    return <NotFound />;
  }

  const isLoading = isPastVideoRequestsLoading || isPendingVideoRequestsLoading;

  if (isLoading) {
    return (
      <Wrapper>
        <Container>
          <Header>
            <Title>Video Requests</Title>
            <Filters filter={filter} updateFilter={setFilter} />
          </Header>
          <LoadingIndicator isLoading={true} height='500px' />
        </Container>
      </Wrapper>
    );
  }

  if (!hasPastVideoRequests && !showPendingSeparately) {
    return (
      <Wrapper>
        <Container>
          <Header>
            <Title>Video Requests</Title>
            <Filters filter={filter} updateFilter={setFilter} />
          </Header>
          <EmptyList>No Video Requests to show</EmptyList>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>Video Requests</Title>
          <Filters filter={filter} updateFilter={setFilter} />
        </Header>
        {showPendingSeparately && (
          <PendingVideoRequestsWrapper>
            {pendingVideoRequests.map((videoRequest: VideoRequest) => (
              <VideoItem
                key={'videoRequestsPending-' + videoRequest.videoRequestId}
                videoItemType={VideoItemType.VideoRequest}
                recordedBy={videoRequest.user}
                assignedTo={videoRequest.advisor}
                video={videoRequest.video}
                videoRequest={videoRequest}
                handleReassignSubmit={handleReassignSubmit}
              />
            ))}
            <Subtitle>Past requests</Subtitle>
          </PendingVideoRequestsWrapper>
        )}
        <TableContextProvider
          total={toNumber(pastVideoRequestsCount)}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          {pastVideoRequests.map((videoRequest: VideoRequest) => (
            <VideoItem
              key={'videoRequestsPaginated-' + videoRequest.videoRequestId}
              videoItemType={VideoItemType.VideoRequest}
              recordedBy={videoRequest.user}
              assignedTo={videoRequest.advisor}
              video={videoRequest.video}
              videoRequest={videoRequest}
              repairOrder={videoRequest.video.repairOrder}
              handleReassignSubmit={handleReassignSubmit}
              handleOnMarkAsSentSuccessCallback={
                handleOnMarkAsSentSuccessCallback
              }
            />
          ))}
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show videos:' />
          </TableFooter>
        </TableContextProvider>
      </Container>
    </Wrapper>
  );
};
