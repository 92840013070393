import { EXPRESS_API } from 'configs/api/expressApi';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { QueryListParams } from '.';
import axios from 'axios';

export interface ReportUserData {
  videos: number;
  userId: string;
  userFullName: string;
  dept: null | string;
  business: string;
  customerId: number;
  lastLogin: string;
}

const DATE_FORMAT = 'YYYY-MM-DD';

export const getCustomReport = async (reportId: string) => {
  const response = await EXPRESS_API.get(`/custom-reports/${reportId}`);
  return response.data;
};

export const getCustomReportHistory = async ({
  reportId,
  page = 0,
  size = 10,
  sortData,
}: QueryListParams) => {
  const start = page * size;

  const response = await EXPRESS_API.get(
    `/custom-reports/${reportId}/history`,
    {
      params: {
        start,
        limit: size,
        ...sortData,
      },
    }
  );
  return response.data;
};

export const getCustomReportData = async (
  reportId: string,
  from: Date,
  to: Date
) => {
  const response = await EXPRESS_API.get(`/custom-reports/${reportId}/data`, {
    params: {
      to: dayjs(to).format(DATE_FORMAT),
      from: dayjs(from).format(DATE_FORMAT),
    },
  });
  return response.data;
};

export const downloadCustomReportData = async (
  reportId: string,
  name: string,
  from: Date,
  to: Date
) => {
  const response = await EXPRESS_API.get(
    `/custom-reports/${reportId}/download`,
    {
      params: {
        to: dayjs(to).format(DATE_FORMAT),
        from: dayjs(from).format(DATE_FORMAT),
      },
      responseType: 'blob',
    }
  ).catch(async err => {
    if (axios.isAxiosError(err) && !!err.response?.data) {
      const error = await new Response(err.response?.data).json();
      if (error && !!error.message && !!error.message.length) {
        throw new Error(error.message);
      }
    }

    throw err;
  });

  saveAs(new Blob([response.data]), name);
};
