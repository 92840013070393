import { useFormikContext } from 'formik';
import { ButtonPillSwitch } from 'lib/components';
import React from 'react';
import { CustomReportModalFormikValues, TYPE_OF_REPORT } from './types';

export const TypeOfReport = () => {
  const { values, setFieldValue } =
    useFormikContext<CustomReportModalFormikValues>();
  return (
    <ButtonPillSwitch
      defaultValue={values.typeOfReport}
      values={TYPE_OF_REPORT}
      onChange={newTab =>
        setFieldValue('typeOfReport', newTab as TYPE_OF_REPORT)
      }
      pillStyle={{ width: 'calc(50% - 4px)' }}
      textStyle={{ width: '50%' }}
    />
  );
};
