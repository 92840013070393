import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';

import { servicesKeys } from './servicesKeys';
import { SERVICE } from './types';

interface ServiceStatusResponse {
  error: boolean;
  message: {
    authorized: boolean;
  };
}

const fetchServicesStatus = async (
  service: SERVICE
): Promise<ServiceStatusResponse> => {
  const response = await EXPRESS_API.get(`/${service}/status`);
  return response.data?.data;
};

export const useServicesStatusQuery = (service: SERVICE) => {
  return useQuery(servicesKeys.info(), () => fetchServicesStatus(service));
};
