import { Ressellers } from '../provider/types';

export function extractCheckedIds(data: Ressellers) {
  const resellerIds = [];
  const customerIds = [];
  const userIds = [];

  for (const resellerId in data) {
    const reseller = data[resellerId];
    if (reseller.checked) {
      resellerIds.push(reseller.resellerId);
    }

    const customers = reseller.customers;
    for (const customerId in customers) {
      const customer = customers[customerId];
      if (customer.checked) {
        customerIds.push(customer.customerId);
      }

      const users = customer.users;
      for (const userId in users) {
        const user = users[userId];
        if (user.checked) {
          userIds.push(user.id);
        }
      }
    }
  }

  return {
    resellerIds,
    customerIds,
    userIds,
  };
}
