import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'lib/components';
import { theme } from 'lib/style';
import { LoadingIndicator } from 'lib/components';
import { getInvoiceData } from 'lib/api';
import { MdLaunch, MdError } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  font-family: Work Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 600px;
  height: 550px;
  margin-top: 24px;
  .pdf-preview {
    width: 100%;
    height: inherit;
  }
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const PopOutIcon = styled.a`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ErrorContainer = styled.div`
  margin: auto;
  text-align: center;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  previewUrl?: string;
};

export const PreviewModal = (props: Props) => {
  const { handleModalClose, previewUrl } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [pdfDataUrl, setPdfDataUrl] = useState('');

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const loadPdf = async () => {
    if (!previewUrl) {
      setError(true);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(false);
    try {
      const response = await getInvoiceData(previewUrl);
      if (!response) {
        setError(true);
        setLoading(false);
        return;
      }

      const blob = new Blob([response], { type: 'application/pdf' });
      const pdfurl =
        URL.createObjectURL(blob) + '#toolbar=1&scrollbar=0&navpanes=1';
      setPdfDataUrl(pdfurl);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    loadPdf();
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  return (
    <Modal style={{ position: 'fixed' }}>
      <div style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            {!loading && !error && (
              <PopOutIcon
                href={`https://docs.google.com/viewer?url=${previewUrl}`}
                target='_blank'
              >
                <MdLaunch color='#000' size={22} />
              </PopOutIcon>
            )}
          </HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              onClick={() => handleModalClose()}
              size={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          {loading && <LoadingIndicator isLoading={loading} height='500px' />}
          {!loading && error && (
            <ErrorContainer>
              <MdError />
              <div>Couldn't load the Invoice PDF. Try again later.</div>
            </ErrorContainer>
          )}
          <embed
            title='pdf-preview'
            className='pdf-preview'
            style={{
              opacity: loading ? 0 : 1,
              position: 'relative',
              zIndex: 100,
            }}
            src={pdfDataUrl}
            type='application/pdf'
          />
        </Content>
        <Footer></Footer>
      </div>
    </Modal>
  );
};
