import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { EXPRESS_API } from 'configs/api/expressApi';
import { emailKeys } from './emailKeys';

const getEmailLimit = async () => {
  const response = await EXPRESS_API.get(`/email/limit`);
  return response.data as { count: number };
};

export const useEmailLimitQuery = () => {
  const { showError } = useToastError();
  return useQuery(emailKeys.limit(), () => getEmailLimit(), {
    onError: showError,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });
};
