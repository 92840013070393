import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { ResendEmailBatchParams } from './types';

const resendEmailBatch = async ({
  batchId,
  subject,
}: ResendEmailBatchParams) => {
  const response = await EXPRESS_API.get(
    `/email-batches/${batchId}/resend?subject=${subject}`
  );
  return response.data;
};

export const useResendEmailBatchMutation = () => {
  return useMutation(resendEmailBatch, {
    onSuccess: (_, variables) => {
      if (variables.handleModalClose) {
        variables.handleModalClose();
      }
      successToast({
        title: 'Batch Resent!',
      });
    },
    onError: () => {
      errorToast({ title: 'An error occurred while resending email batch.' });
    },
  });
};
