import React from 'react';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { theme } from 'lib/style';
import { Heading, ParagraphSmall } from 'lib/components/styles/typography';
import {
  MainWrapper,
  Union,
  ContentWrapper,
  BtnWrapper,
  ImageWrapper,
} from './styles';
import proCardImgReports from './not-connected.png';
import { Button } from 'react-covideo-common';

type Props = {
  position?: 'relative' | 'fixed';
  style?: React.CSSProperties | undefined;
};

const resetErrorBoundary = () => {
  window.location.href = '/home';
};

export const NotFound = ({ position = 'relative', style }: Props) => {
  return (
    <MainWrapper position={position} style={style}>
      <ContentWrapper>
        <Heading color={theme.palette.primaryDarkBlue}>
          Oops! Something’s not right...
        </Heading>
        <BtnWrapper>
          <Button
            onClick={resetErrorBoundary}
            text='Go to your library'
            icon={<Union width={16} />}
          />
          <ParagraphSmall color='#9297a2'>or</ParagraphSmall>
          <a style={{ textDecoration: 'none' }} href='tel:+1800.306.1445'>
            <Button
              text={'Contact Support'}
              icon={<MdOutlineSupportAgent size={24} />}
              variant='secondary'
            />
          </a>
        </BtnWrapper>
      </ContentWrapper>
      <ImageWrapper>
        <img alt='not connected' src={proCardImgReports} />
      </ImageWrapper>
    </MainWrapper>
  );
};
