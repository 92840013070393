import React from 'react';

import styled from 'styled-components/macro';

const Dot = styled.span<{ active: boolean }>`
  padding: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background: ${({ active }) => (active ? 'black' : 'white')};
`;
const DotWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  slides?: any;
  activeIndex?: any;
}

const Dots = ({ slides, activeIndex }: Props) => (
  <DotWrapper>
    {slides.map((slide: any, i: number) => {
      if (slide && slide.trim() !== '') {
        return <Dot key={'slide-dot-' + i} active={activeIndex === i} />;
      }

      return null;
    })}
  </DotWrapper>
);

export default Dots;
