import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path='/admin/resellers' component={List} exact={true} />
      <Route path='/admin/resellers/:id' component={Details} />
      <Route path='/admin/resellers/entry' component={Details} />
    </Switch>
  );
};
