import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { DocumentHead, PageTemplate } from 'lib/components';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { ReviewList } from './ReviewList';
import RouteLeavingGuard from './RouteLeavingGuard';
import { VideoListItem } from 'lib/api';
import { CustomizedState } from 'app/pages/guides/main/pages/constants';

export const ReviewUploadedFiles = () => {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const history = useHistory();
  const [videoArray, setVideoArray] = React.useState<VideoListItem[]>([]);
  const [isSaved, setIsSaved] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [targetFolderId, setTargetFolderId] = React.useState(-2);

  React.useEffect(() => {
    if (!state || !state.videos || !state.videos.length) {
      setIsLoading(false);
      setIsSaved(true);
    } else {
      const videoIds = state.videos;
      Promise.all(
        videoIds.map((id: string) => {
          return getVideo(id);
        })
      )
        .then(results => {
          setVideoArray(results);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  React.useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      setVideoArray([]);
      window.onbeforeunload = null;
    };
  }, []);

  React.useEffect(() => {
    if (isSaved) {
      history.push(`/home?folderId=${targetFolderId}&page=0`);
    }
  }, [isSaved]);

  return (
    <>
      <DocumentHead title='Review Videos' />
      <PageTemplate
        main={
          <>
            <RouteLeavingGuard
              when={true}
              stay={true}
              onConfirm={() => {}}
              navigate={path => history.push(path)}
              shouldBlockNavigation={() => {
                return !isSaved;
              }}
              title='Leave without saving details?'
              text='Your changes will not be saved. This action can’t be undone.'
              confirmButtonText='Continue'
              discardButtonText='Leave'
            />
            <ReviewList
              isLoading={isLoading}
              videoArray={videoArray}
              setIsSaved={setIsSaved}
              setTargetFolderId={setTargetFolderId}
            />
          </>
        }
      />
    </>
  );
};
