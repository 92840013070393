import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Notifications } from './notifications';

export const NotificationRoutes = () => {
  //const { droplrAccess, reseller, resellerId, customerId, access } = userData;

  return (
    <Switch>
      <Route path="/notifications" component={Notifications} />
    </Switch>
  );
};
