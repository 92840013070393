import { Field, FieldProps } from 'formik';
import { SelectInput } from 'lib/components';
import { soundType } from 'lib/const/notifications';
import * as React from 'react';
import { Button } from 'react-covideo-common';
import { MdPlayArrow } from 'react-icons/md';
import {
  Container,
  FieldLabel,
  Row,
  RowWrapper,
  SectionTitle,
} from './common/components';
import { PreferenceSounds } from './common/constants';

interface FormProps {
  isSubmitting?: boolean;
  setFieldValue?: (field: string, value: string) => void;
}

const playSound = (sound: string = 'piano') => {
  sound = sound.toLowerCase().replaceAll(' ', '');
  if (soundType[sound]) {
    new Audio(soundType[sound]).play();
  }
};

export const NotificationSoundComponent = (props: FormProps) => {
  const { isSubmitting, setFieldValue } = props;

  return (
    <Container>
      <SectionTitle>Notification Sound</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Select Sound</FieldLabel>
          <Field type='select' name='notificationPreferences.sound'>
            {({ field }: FieldProps) => {
              const { value, ...rest } = field;
              return (
                <>
                  <SelectInput
                    {...rest}
                    value={value}
                    disabled={isSubmitting}
                    style={{ width: '65%' }}
                    onChange={e => {
                      if (setFieldValue) {
                        setFieldValue(
                          'notificationPreferences.sound',
                          e.target.value
                        );
                      }
                    }}
                  >
                    {PreferenceSounds.map(
                      (sound: { [key: string]: string }, index: number) => (
                        <option key={index} value={sound.value || ''}>
                          {sound.name}
                        </option>
                      )
                    )}
                  </SelectInput>
                  <Button
                    style={{ marginLeft: 'auto', marginRight: 0 }}
                    disabled={isSubmitting}
                    variant='secondary'
                    onClick={() => {
                      playSound(value);
                    }}
                    icon={<MdPlayArrow height={40} width={40} />}
                  />
                </>
              );
            }}
          </Field>
        </Row>
      </RowWrapper>
    </Container>
  );
};
