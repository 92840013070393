import { orderBy } from 'lodash';
import { ResellerCustomer, ResellerUser, Ressellers } from './types';

export const sortItemsByChecked = (
  res:
    | Ressellers
    | Record<string, ResellerCustomer>
    | Record<string, ResellerUser>,
  initialValues: Record<string, number[]>,
  key: string
) => {
  const { checkedIds, partiallySelectedIds } = initialValues;

  const sortedItems = orderBy(
    Object.values(res),
    [
      item => checkedIds?.includes(item[key]),
      item => partiallySelectedIds?.includes(item[key]),
    ],
    ['desc', 'desc'] // Sort in descending order, true before false
  );

  return sortedItems;
};
