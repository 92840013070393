import React from 'react';
import styled from 'styled-components/macro';
import {
  Tooltip,
  TopVariantsTooltipWrapper,
} from '../widgets/styles/topVariants';

const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  background: ${props => props.color};
  border-radius: 5px;
  margin-right: 8px;
`;

const LegendPercentage = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #272a32;
  width: 44px;
`;

const LegendName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #272a32;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CustomLegend = (props: { payload: any; data?: any }) => {
  const { payload, data } = props;

  return (
    <>
      {payload.map((entry: any) => (
        <TopVariantsTooltipWrapper>
          <LegendContainer key={entry.payload.id}>
            <Icon color={entry.color} />
            {data && (
              <Tooltip className='tooltip'>
                {data[entry.value.toLowerCase()].total}
              </Tooltip>
            )}

            <LegendWrapper>
              <LegendPercentage>
                {(entry.payload.percent * 100).toFixed(1)}%
              </LegendPercentage>
              <LegendName>{entry.payload.name}</LegendName>
            </LegendWrapper>
          </LegendContainer>
        </TopVariantsTooltipWrapper>
      ))}
    </>
  );
};

export default CustomLegend;
