import styled, { css } from 'styled-components/macro';

export const DisabledWrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      pointer-events: none;
      :after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #f6f7f9;
        opacity: 0.4;
      }
    `}
`;
