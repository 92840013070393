import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const Container = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 20px 200px;
  min-height: 700px;
`;

export const MainContainerWrapper = styled.div`
  display: flex;
  padding: 0;
  width: 100%;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 10px 24px 40px 24px;
  }
  h2 {
    font-weight: 800;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  div {
    margin-left: 6px;
  }
`;

export const Filter = styled.div`
  float: right;
  ${theme.mediaQueryMinWidth.sm} {
    width: 300px;
  }
`;

export const Customize = styled.div`
  float: right;
  color: #001b53;
  ${theme.mediaQueryMinWidth.sm} {
    width: 340px;
  }
  text-align: end;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const ListBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div<{ isRead: boolean }>`
  opacity: ${props => (props.isRead ? 0.6 : 1)};
  width: inherit;
  display: flex;
  flex-direction: row;
  border: 1px solid transparent;
  border-bottom: 1px solid #00000014;
  padding: 20px;
  .item--text {
    width: 70%;
    .item--text--2nd-div {
      padding: 10px 0px 0px 0px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      &-time {
        font-size: 13px;
        line-height: 16px;
        color: rgba(0, 27, 83, 0.6);
      }
    }
    &-title {
      cursor: pointer;
      color: #001b53;
      line-height: 24px;
    }
  }
  .item--btn {
    width: 30%;
    display: flex;
    justify-content: end;
    .item--btn-circle {
      justify-content: end;
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
    .item--btn-createBtn {
      display: none;
    }
  }
  &:hover {
    .item--btn-createBtn {
      justify-content: end;
      display: flex;
      align-items: center;
      column-gap: 10px;
      svg {
        cursor: pointer;
      }
    }
    .item--btn-circle {
      display: none;
    }
    border: 1px solid
      ${props => props.theme.colors.primary[100] || theme.palette.tertiaryBlue};
    border-radius: 4px;
    opacity: 1;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;

export const HoverElement = styled.div<{ padding?: string; top?: string }>`
  padding-left: 0px;
  position: relative;

  .hover-content {
    display: none;
  }
  &.padding--25 {
    padding-left: 25px;
  }
  &:hover {
    .hover-content {
      width: 110px;
      top: ${props => props.top || '40px'};
      right: 0px;
      border: 1px solid #f7f8fa;
      background: white;
      padding: ${props => props.padding || '10px 10px 10px 10px'};
      display: block;
      position: absolute;
      border-radius: 5px;
      box-shadow: 0 8px 32px rgba(29, 30, 36, 0.08);
      z-index: 10;
    }
  }
`;

// SUS-453 changes
export const CustomizeDisable = styled.div`
  float: right;
  color: #001b53;
  ${theme.mediaQueryMinWidth.sm} {
    width: 205px;
  }
  text-align: end;
  cursor: default;
  display: flex;
  align-items: center;
  column-gap: 10px;
  opacity: 0.5;
`;
