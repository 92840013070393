import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import { useToastError } from 'lib/hooks/useToastError';
import { successToast } from 'lib/components/toasts/success';
import { conversationsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const markConversationAsOpened = async (
  conversationId: string
): Promise<any> => {
  return (await EXPRESS_API.patch(`conversations/${conversationId}/reopen`))
    .data;
};

export const useReopenedConversationMutation = () => {
  const { showError } = useToastError();

  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(markConversationAsOpened, {
    onError: (err: AxiosError) => showError(err),
    onSuccess: () => {
      successToast({ title: 'Your message is reopened successfully' });
      queryClient.invalidateQueries(conversationsKeys.lists());

      history.replace({
        pathname: `/conversations`,
      });
    },
  });
};
