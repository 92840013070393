import React, { useState, useEffect } from 'react';
import {
  Badge,
  CustomOptionLabel,
  Email,
  Id,
  Name,
  SearchResultsContainer,
} from '../organizations/styles';
import { Search } from 'lib/components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SuperAdminUser } from 'lib/context';
import { useOnClickOutside } from 'lib/utils';
import { ParagraphSmall } from 'lib/components/styles/typography';
import styled from 'styled-components/macro';
import { useGetUserSearchForSuperAdmin } from 'lib/api/superadmin/userSearchQuery';
import { getUserAccessAsString } from 'lib/utils/userRoles';
import { AccessRole } from 'lib/const';

const LoaderContainer = styled.div`
  padding-left: 15px;
`;

type Style = {
  searchResultStyle?: React.CSSProperties;
  scrollStyle?: React.CSSProperties;
  endMessageStyle?: React.CSSProperties;
};

type Props = {
  onSelectUser: (user: SuperAdminUser) => void;
  style?: Style;
  closeMenuAfterSelect?: boolean;
  loading?: boolean;
};

const SuperAdminSearchDropdown = ({
  onSelectUser,
  closeMenuAfterSelect = true,
  style,
  loading,
}: Props) => {
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);
  const { ref } = useOnClickOutside(() => {
    setIsSearchResultOpen(false);
  });

  const [size, setSize] = useState(20);
  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<SuperAdminUser[]>([]);
  const { data, isFetching } = useGetUserSearchForSuperAdmin({
    params: {
      search,
      limit: size,
      start: 0,
    },
    enabled: search.length >= 3,
  });

  const { users: superAdminUsers, count } = data || { users: [], count: 0 };
  const superAdminUserCount = Number(count);

  useEffect(() => {
    if (!isFetching) {
      setFilteredUsers(superAdminUsers);
    }
  }, [isFetching]);

  useEffect(() => {
    setIsSearchResultOpen(search.length >= 3);
  }, [search]);

  const getUserAccessBadge = (access: number | undefined) => {
    if (!access || access?.toString() === AccessRole.USER) {
      return '';
    }
    return getUserAccessAsString(access || 0)?.[0] || '';
  };

  return (
    <>
      <Search
        onSearch={(term: string) => {
          setIsSearchResultOpen(term.length >= 3);
          setSearch(term);
        }}
        onChange={() => {
          setIsSearchResultOpen(false);
        }}
        bgColor='white'
        placeholder='Search by username, email or customer...'
        disabled={loading}
      />
      <SearchResultsContainer ref={ref} style={style?.searchResultStyle}>
        {isSearchResultOpen && (
          <InfiniteScroll
            dataLength={superAdminUserCount}
            hasMore={isFetching || superAdminUserCount > filteredUsers.length}
            loader={
              <LoaderContainer>
                <h4>Loading...</h4>
              </LoaderContainer>
            }
            next={() => {
              setSize(size + 20);
            }}
            style={{
              overflowX: 'visible',
              overflowY: 'auto',
              overflow: 'initial',
              ...style?.scrollStyle,
            }}
            height={266}
            endMessage={
              !!superAdminUserCount || isFetching ? (
                <></>
              ) : (
                <ParagraphSmall
                  textAlign='center'
                  style={{ marginTop: '110px', ...style?.endMessageStyle }}
                >
                  There is no users that match this criteria
                </ParagraphSmall>
              )
            }
          >
            {filteredUsers.map((user: SuperAdminUser) => {
              const userBadge = getUserAccessBadge(user?.access);
              return (
                <CustomOptionLabel
                  userSearch={true}
                  key={user.id}
                  onClick={() => {
                    onSelectUser(user);
                    if (closeMenuAfterSelect) setIsSearchResultOpen(false);
                  }}
                >
                  <Badge isEmpty={!userBadge}>{userBadge}</Badge>
                  <Name>
                    {user.firstName} {user.lastName}
                  </Name>
                  <Email>{user.username}</Email>
                  <Id>{user.customerId}</Id>
                </CustomOptionLabel>
              );
            })}
          </InfiniteScroll>
        )}
      </SearchResultsContainer>
    </>
  );
};

export default SuperAdminSearchDropdown;
