import { NewModal } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { ChildCustomer } from 'lib/api/multiLocations/multiLocationQuery';
import { useUpdateChildUserAccessMutation } from 'lib/api/multiLocations/useUpdateChildUserMutation';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
  parentUserId: string | number;
  childCustomer: ChildCustomer | undefined;
}

export const RemoveUserAccessModal = ({
  closeModalHandler,
  childCustomer,
  parentUserId,
}: IProps) => {
  const { mutateAsync, isLoading } =
    useUpdateChildUserAccessMutation(closeModalHandler);
  return (
    <NewModal
      headerText={`Remove access to location?`}
      closeModal={closeModalHandler}
      style={{ content: { minWidth: 592, width: 592 } }}
    >
      <Gap
        m='32px 0 0 0'
        alignItems='center'
        justifyContent='flex-end'
        gap='12px'
      >
        <Button
          text='No, Keep'
          variant='secondary'
          disabled={isLoading}
          onClick={() => {
            closeModalHandler();
          }}
        />
        <Button
          text={isLoading ? 'Deleting...' : 'Yes, Remove'}
          variant='destructive'
          disabled={isLoading}
          onClick={() => {
            if (!!childCustomer) {
              mutateAsync({
                parentUserId: `${parentUserId}`,
                userId: 'remove',
                customerId: childCustomer.customerId,
              });
            }
          }}
        />
      </Gap>
    </NewModal>
  );
};
