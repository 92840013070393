import { CONVERSATION_TYPES } from 'lib/api/conversations/getSingleConversation';
import { useAuth } from 'lib/context';
import React from 'react';
import { IConversationFooter, IDropDownItems } from '../conversations.types';
import { ChatForm } from '../forms/chat';
import { EmailForm } from '../forms/email';
import { SmsForm } from '../forms/sms';
import { isNotAssignedToYou, isUnassigned } from '../utils/helper';
import { FooterInfoMsg, InfoMsg } from './FooterInfoMsg';

export const ConversationFooter = (props: IConversationFooter) => {
  const { copiedMessage, setCopiedMessage, ...formProps } = props;
  const { userData } = useAuth();

  const {
    singleConversation,
    setModals,
    selectedConversation,
    setSelectedConversation,
    dropdownItems,
  } = props;

  const { userId, closed, deleted } = singleConversation;

  const dropdownValue = dropdownItems?.find((o: IDropDownItems) => {
    return o.value === selectedConversation;
  });

  const onDropdownChangeHandler = (option: IDropDownItems) =>
    setSelectedConversation?.(option.value);

  const isFooterVisible =
    isUnassigned(userId) ||
    isNotAssignedToYou(userId, userData) ||
    closed ||
    deleted;

  if (isFooterVisible) {
    return (
      <FooterInfoMsg
        singleConversation={singleConversation}
        setModals={setModals}
      />
    );
  }

  switch (selectedConversation) {
    case CONVERSATION_TYPES.SMS:
      return (
        <SmsForm
          dropdownValue={dropdownValue}
          onDropdownChangeHandler={onDropdownChangeHandler}
          copiedMessage={copiedMessage}
          items={dropdownItems}
          {...formProps}
        />
      );
    case CONVERSATION_TYPES.EMAIL:
      return (
        <EmailForm
          dropdownValue={dropdownValue}
          onDropdownChangeHandler={onDropdownChangeHandler}
          copiedMessage={copiedMessage}
          items={dropdownItems}
          {...formProps}
        />
      );
    case CONVERSATION_TYPES.LIVE_CHAT:
      return (
        <ChatForm
          dropdownValue={dropdownValue}
          onDropdownChangeHandler={onDropdownChangeHandler}
          setCopiedMessage={setCopiedMessage}
          items={dropdownItems}
          {...formProps}
        />
      );
    default:
      return <InfoMsg>There is no chat option</InfoMsg>;
  }
};
