import { IOnPaginationChange } from 'app/pages/account/userAccount/schedule/types';
import {
  CheckboxInput,
  Dropdown,
  LoadingIndicator,
  Search,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { DEFAULT_PAGE, DEFAULT_SIZE } from 'lib/const/PaginationConstants';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import ItemCard from 'app/pages/guides/components/ItemCard';
import dayjs from 'dayjs';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { successToast } from 'lib/components/toasts/success';
import {
  DROPTYPE_OPTIONS,
  DROP_FILE_TYPES,
  SortFields,
} from 'lib/const/Droplr';
import { DropItem } from 'lib/api/droplr/types';
import { useEditDrop } from 'lib/api/droplr/editDrop';
import { useGetDrops } from 'lib/api/droplr/getDrops';
import { useIsMutating } from 'react-query';
import { droplrKeys } from 'lib/api/droplr/queryKeys';
import { Button } from 'react-covideo-common';

const Container = styled.div`
  margin-top: 16px;
`;

const Body = styled.div`
  overflow-y: auto;
  height: 216px;
  margin-top: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

const NoFilesMessage = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

type Props = {
  closeModal: () => void;
  refetch: () => void;
  boardId: string;
  isGuide?: boolean;
  onFileClick?: (drop: DropItem) => void;
};

const FETCH_MESSAGE = 'Loading files, please wait...';
const UPDATE_MESSAGE = 'Adding files to board, please wait...';

const FilesTab = ({
  closeModal,
  boardId,
  refetch,
  isGuide,
  onFileClick,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [loadingMessage, setLoadingMessage] = useState(FETCH_MESSAGE);
  const [type, setType] = useState(
    isGuide ? DROP_FILE_TYPES[1] : DROP_FILE_TYPES[0]
  );
  const [selectedDrops, setSelectedDrops] = useState<DropItem[]>([]);
  const [field, setField] = useState(SortFields[1]);

  const { data: dropsResult, isLoading: areDropsLoading } = useGetDrops({
    search: searchQuery,
    sort: field.key,
    order: field.order,
    page,
    size,
    boardId: '',
    type: type.value,
    variant: '',
    dropType: DROPTYPE_OPTIONS.OWNED,
  });

  const { drops, count } = dropsResult || { drops: [], count: 0 };

  const { mutateAsync: editDrop } = useEditDrop();
  const areDropsEditing =
    useIsMutating({
      mutationKey: droplrKeys.edit_drop_mutation(),
    }) > 0;

  const onSubmit = async () => {
    setLoadingMessage(UPDATE_MESSAGE);

    await Promise.allSettled(
      selectedDrops.map(async (drop: DropItem) => {
        await editDrop({
          dropId: drop.code,
          boards: [...(drop?.boards || []), boardId],
        });
      })
    );
    setLoadingMessage(FETCH_MESSAGE);
    successToast({ title: 'Successfully added files to board!' });
    refetch();
    closeModal();
  };

  const onSelectDrop = (drop: DropItem) => {
    const exists = selectedDrops.some(
      (selectedDrop: DropItem) => selectedDrop.code === drop.code
    );
    if (exists) {
      setSelectedDrops(
        selectedDrops.filter(
          (selectedDrop: DropItem) => selectedDrop.code !== drop.code
        )
      );
      return;
    }
    setSelectedDrops([...selectedDrops, drop]);
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: IOnPaginationChange) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };
  const hasFiles = !!drops.length;

  const isChecked = (drop: DropItem) =>
    selectedDrops.some(
      (selectedDrop: DropItem) => selectedDrop.code === drop.code
    );

  const isDropInBoard = (drop: DropItem) =>
    !!drop?.boards &&
    !!drop.boards.length &&
    !!boardId &&
    drop.boards.includes(boardId);

  const areLoading = areDropsEditing || areDropsLoading;

  return (
    <Container>
      <Gap gap={'10px'}>
        <Search
          placeholder={'Search files'}
          onSearch={(search: string) => setSearchQuery(search)}
          prevSearch={searchQuery}
          width={'280px'}
        />
        {isGuide ? (
          <Dropdown
            value={field}
            onChange={newValue => {
              setField(newValue);
            }}
            height={40}
            width={290}
            options={SortFields}
            dropdownHeight={250}
          />
        ) : (
          <Dropdown
            options={DROP_FILE_TYPES}
            value={type}
            defaultValue={isGuide ? DROP_FILE_TYPES[1] : DROP_FILE_TYPES[0]}
            onChange={newValue => setType(newValue)}
            height={40}
            width={290}
            menuPortalTarget={document.body}
            menuShouldBlockScroll={true}
            menuPosition='absolute'
            menuPlacement='bottom'
            placeholder='Select file type'
            disabled={areLoading || isGuide}
          />
        )}
      </Gap>
      <TableContextProvider
        total={count}
        initSize={size}
        initPage={page}
        onChange={onPaginationChange}
      >
        <Body>
          {areLoading ? (
            <LoadingIndicator
              isLoading={true}
              height='250px'
              text={loadingMessage}
            />
          ) : hasFiles ? (
            drops.map((drop: DropItem, index: number) => {
              return (
                <Gap
                  key={drop.code}
                  justifyContent='flex-start'
                  style={{
                    ...(index !== drops.length - 1 && {
                      borderBottom: `2px solid ${theme.palette.neutral20}`,
                    }),
                  }}
                >
                  {!isGuide && (
                    <CheckboxInput
                      onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                        event.stopPropagation();
                      }}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        event.stopPropagation();
                        onSelectDrop(drop);
                      }}
                      checked={isChecked(drop) || isDropInBoard(drop)}
                      disabled={isDropInBoard(drop)}
                    />
                  )}

                  <ItemCard
                    disabled={isDropInBoard(drop)}
                    isGuide={isGuide}
                    onFileClick={() => {
                      onFileClick && onFileClick(drop);
                    }}
                    data={{
                      id: drop?.id || '',
                      thumbnail: drop.previewSmall,
                      title: drop.title,
                      source: drop.previewMedium,
                      type: drop.variant,
                      createdAt:
                        dayjs(drop?.createdAt).format(STANDARD_DATE_FORMAT) ||
                        '',
                    }}
                  />
                </Gap>
              );
            })
          ) : (
            <NoFilesMessage>
              There is no files matching your criteria.
            </NoFilesMessage>
          )}
        </Body>
        {hasFiles ? (
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew />
          </TableFooter>
        ) : (
          <div style={{ height: '56px' }}></div>
        )}
      </TableContextProvider>

      <ButtonContainer>
        <Button
          onClick={onSubmit}
          text={'Add'}
          disabled={!selectedDrops.length}
        />
      </ButtonContainer>
    </Container>
  );
};

export default FilesTab;
