import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { useMutation } from 'react-query';
import { UpdateVideoChaptersGlobalParams } from './types';

const updateVideoChapters = async ({
  videoId,
  videoBody,
}: UpdateVideoChaptersGlobalParams) => {
  const response = await EXPRESS_API.patch(
    `/videos/${videoId}/chapters`,
    videoBody
  );
  return response.data;
};

export const useUpdateVideoChaptersMutation = () => {
  return useMutation(
    (data: UpdateVideoChaptersGlobalParams) => updateVideoChapters(data),
    {
      onError: () => {
        errorToast({
          title: 'Failed to update video chapters. Please try again later.',
        });
      },
    }
  );
};
