export const DefaultFolderIds = {
  PRIMARY: -2,
  PRIVATE: -3,
  COMPANY: -1,
  VAULT: 0,
  LEARNING_OPPORTUNITIES: -4,
  SUPPORT_TRAINING: -5,
};

export const DEFAULT_COMPANY_FOLDER = 'Company';
export const SHARED_FOLDER_PREFIX = '~~shared~~';

export const ALL_FOLDERS = {
  value: '_all',
  label: 'All folders',
};

export const DEFAULT_FOLDER = {
  folderId: -2,
  name: 'Primary',
  isDeletable: false,
  access: '',
  isFolderShared: false,
  userId: undefined,
};
