import { useVideoReactionsQuery } from 'lib/api/videos/useGetVideoReactions';
import { ReactionWithIcon, ReactionsPosition } from 'lib/context';
import { VideoData } from 'lib/hooks';
import { theme } from 'lib/style';
import { summarizePlayerReactions } from 'lib/utils/functions';
import React from 'react';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';

const ReactionBar = styled.div`
  position: absolute;
  bottom: 46px;
  height: 20px;
  width: 100%;
`;

type ReactionPositionProps = {
  position: number;
};

const ReactionPosition = styled.div<ReactionPositionProps>`
  left: ${({ position }) => `${position}%`};
  position: absolute;
  bottom: 0px;
  padding-bottom: 10px;
  width: 10%;
  text-align: center;
`;

type ReactionsBubbleProps = {
  position: number;
};

const ReactionsBubble = styled.div<ReactionsBubbleProps>`
  ${({ position }) => {
    if (position === 0) {
      return {
        left: '16px',
      };
    } else if (position === 10) {
      return {
        transform: 'translateX(-30%)',
        left: '50%',
      };
    } else if (position === 80) {
      return {
        transform: 'translateX(-60%)',
        left: '50%',
      };
    } else if (position === 90) {
      return {
        right: '16px',
      };
    } else {
      return {
        transform: 'translateX(-50%)',
        left: `${position}%`,
      };
    }
  }};
  display: none;
  box-sizing: border-box;
  position: absolute;
  bottom: 20px;
  width: auto;
  min-width: 150px;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
  border-radius: 4px;
  padding: 8px 12px;
  flex-direction: column;
  gap: 8px;
  z-index: 999;
  ${ReactionPosition}:hover & {
    display: flex;
  }
`;

const ReactionItem = styled.div`
  display: block;
  margin-bottom: -10px;
  &:nth-child(n + 15) {
    display: none;
  }
`;

const BubbleItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BubbleIcon = styled.div`
  display: block;
`;

const BubbleName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #272a32;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BubbleText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  max-width: 300px;
  color: ${theme.palette.gray100};
`;

const BubbleDelete = styled.div`
  margin-left: auto;
  color: ${theme.palette.gray60};
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${theme.palette.red100};
    color: ${theme.palette.white};
  }
`;

type Props = {
  videoDetails: VideoData;
  handleDeleteVideoReaction: (videoId: string, reactionId: string) => void;
};

export const PlayerReactions = ({
  videoDetails,
  handleDeleteVideoReaction,
}: Props) => {
  const { data: videoReactions } = useVideoReactionsQuery(videoDetails.id);

  const videoLengthSeconds = Math.floor((videoDetails.videoLength || 0) / 1000);
  const videoId = videoDetails.id;

  const { reactionsPositions } = summarizePlayerReactions(
    videoReactions || {},
    videoLengthSeconds
  );

  return (
    <ReactionBar>
      {Object.values(reactionsPositions).map(
        (reactionPosition: ReactionsPosition) => (
          <ReactionPosition
            key={reactionPosition.position}
            position={reactionPosition.position}
          >
            {reactionPosition.reactions.map((reaction: ReactionWithIcon) => (
              <ReactionItem key={reaction.reactionId}>
                {reaction.smallIcon}
              </ReactionItem>
            ))}
            <ReactionsBubble position={reactionPosition.position}>
              {reactionPosition.reactions.map((reaction: ReactionWithIcon) => (
                <BubbleItem key={reaction.reactionId}>
                  <BubbleIcon>{reaction.icon}</BubbleIcon>
                  <BubbleName>{reaction.name}</BubbleName>
                  <BubbleText>{reaction.text || ''}</BubbleText>
                  <BubbleDelete
                    onClick={() =>
                      handleDeleteVideoReaction(videoId, reaction.reactionId)
                    }
                  >
                    <MdClose />
                  </BubbleDelete>
                </BubbleItem>
              ))}
            </ReactionsBubble>
          </ReactionPosition>
        )
      )}
    </ReactionBar>
  );
};
