import { FieldArrayRenderProps, useFormikContext } from 'formik';
import {
  Customer,
  useFreeCustomersQuery,
} from 'lib/api/multiLocations/useFreeCustomersQuery';
import { Dropdown } from 'lib/components';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { NEW_ML_USER_PREFIX } from '../../const';
import { CustomLabel } from './CustomDropdownLabel';
import {
  ChildCustomer,
  ParentCustomer,
} from 'lib/api/multiLocations/multiLocationQuery';

export const CustomersDropdown = ({
  arrayHelpers,
}: {
  arrayHelpers: FieldArrayRenderProps;
}) => {
  const [search, setSearchText] = useState('');
  const { values, setValues } = useFormikContext<ParentCustomer>();

  const { data, refetch, isLoading } = useFreeCustomersQuery({
    search,
  });

  const selectedCustomers = [
    ...(values.childCustomers.map(customer => customer.customerId) || []),
    ...(values?.customerId ? [values?.customerId] : []),
  ];

  const debouncedInputChange = useCallback(
    debounce(inputValue => {
      setSearchText(inputValue);
    }, 300),
    []
  );

  const onInputChangeHandler = (newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  useEffect(() => {
    refetch();
  }, [search]);

  const onDropdownChangeHandler = (dropdownItem: {
    customerId: number;
    business: string;
  }) => {
    if (values.business) {
      return arrayHelpers.push({
        customerId: dropdownItem.customerId,
        parentCustomerId: values.customerId,
        business: dropdownItem.business,
        location: {
          locationId: null,
          parentCustomerId: values.customerId,
          customerId: dropdownItem.customerId,
          name: dropdownItem.business,
        },
        users: values.users.map(user => ({
          id: NEW_ML_USER_PREFIX,
          customerId: dropdownItem.customerId,
          parentUserId: user.id,
          parentCustomerId: values.customerId,
          email: '',
          username: '',
          lastName: '',
          firstName: '',
        })),
      } as ChildCustomer);
    }
    const initialFormValues = {
      parentCustomerId: 0,
      business: dropdownItem.business,
      customerId: dropdownItem.customerId,
      users: [],
      location: {
        locationId: null,
        name: dropdownItem.business,
        customerId: dropdownItem.customerId,
        parentCustomerId: dropdownItem.customerId,
      },
      childCustomers: [],
    };

    setValues(initialFormValues);
  };

  const optionsValues =
    data?.customers
      ?.filter(
        (customer: Customer) => !selectedCustomers.includes(customer.customerId)
      )
      .map((customer: Customer) => ({
        value: customer.customerId,
        label: customer.business,
        business: customer.business,
        resellerName: customer.resellerName,
        customerId: customer.customerId,
      })) || [];

  return (
    <Dropdown
      options={optionsValues}
      value={null}
      onChange={onDropdownChangeHandler}
      placeholder={
        isLoading
          ? ''
          : values.business
            ? 'Add Child Customer'
            : 'Select Main Customer'
      }
      width={240}
      isLoading={isLoading}
      creatable={false}
      filterOption={null}
      formatOptionLabel={CustomLabel}
      onInputChange={onInputChangeHandler}
      extendStyles={{
        container: {
          marginTop: 16,
        },
        menu: {
          width: '450px',
        },
      }}
    />
  );
};
