import React from 'react';
import { EmptyWidget } from '../EmptyWidget';
import Header from './common/Header';
import {
  Body,
  BodyParagraph,
  ItemsWrapper,
  TopVariantsTooltipWrapper,
  WidgetTitle,
} from './styles/topVariants';

import { NotFound } from 'app/pages/notFound/NotFound';
import styled from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components/LoadingIndicator';
import { VariantTypes } from './types';
import { Tooltip } from 'react-covideo-common';

const Container = styled.div`
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 #1d1e240a;
  border: solid 1px #f7f8fa;
  padding: 24px;
`;

interface VariantProps {
  label: string;
  tabItems: { id: string; label: string }[];
  tableHeaders: string[];
  flexHeaders: string[];
}

interface VariantData {
  [key: string]: VariantProps;
}

type TableScoreboardData = {
  title: string;
  cnt: string;
};

type TableScoreboardProps = {
  label: string;
  setRetryCount: React.Dispatch<React.SetStateAction<number>>;
  retryCount: number;
  isLoading: boolean;
  data: TableScoreboardData[] | undefined;
  isRefetching: boolean;
  error: unknown;
};

export const TableScoreboard = ({
  label,
  data,
  error,
  isLoading,
  isRefetching,
  setRetryCount,
  retryCount,
}: TableScoreboardProps) => {
  const variantData = {
    [VariantTypes.TABLE_SCOREBOARD]: {
      label,
      tabItems: [],
      tableHeaders: ['', 'Video Name', 'Views'],
      flexHeaders: ['0.1', '0.7', '0.2'],
    },
  } as VariantData;

  if (isLoading) {
    return (
      <LoadingIndicator
        isLoading={isLoading}
        height='250px'
        error={error}
        onRetry={() => setRetryCount(retryCount + 1)}
      />
    );
  }

  if (error) {
    return <NotFound />;
  }

  const TableScoreboardBody = ({ data }: { data: TableScoreboardData[] }) => {
    return (
      <div>
        {data?.map((item: TableScoreboardData, index: number) => (
          <Body key={index} lastRow={index === data?.length - 1}>
            <BodyParagraph flex='0.1' bold>
              {index + 1}
            </BodyParagraph>
            <TopVariantsTooltipWrapper flex='0.7'>
              <Tooltip
                popup={item.title}
                extendStyles={{
                  element: {
                    width: '185px',
                  },
                }}
              >
                <BodyParagraph>{item.title}</BodyParagraph>
              </Tooltip>
            </TopVariantsTooltipWrapper>

            <BodyParagraph flex='0.2' bold textAlign='right'>
              {item.cnt}
            </BodyParagraph>
          </Body>
        ))}
      </div>
    );
  };

  return (
    <Container>
      <ItemsWrapper mb='0px'>
        <WidgetTitle>
          {label} {isRefetching && <span>Updating ...</span>}
        </WidgetTitle>
      </ItemsWrapper>

      {data?.length ? (
        <>
          <Header
            selectedLabel={label}
            variantData={variantData}
            variant={VariantTypes.TABLE_SCOREBOARD}
          />
          <TableScoreboardBody data={data} />
        </>
      ) : (
        <EmptyWidget />
      )}
    </Container>
  );
};
