import { useQuery } from 'react-query';
import { getRecipientsMessageData } from 'lib/api/videosApi';

interface Props {
  videoId: string;
  recipientId: number;
}

export const useVideoViewersMessagesStore = ({
  videoId,
  recipientId,
}: Props) => {
  const { data, error, isLoading } = useQuery(
    ['video-viewers-message-store', { videoId, recipientId }],
    async () => await getRecipientsMessageData(videoId, recipientId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  return { response: data, loading: isLoading, error };
};
