import { IRecipient } from 'app/pages/reports/custom/createCustomReportModal/types';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation } from 'react-query';

export interface ICustomReportReccuringPayload {
  frequency: string;
  deliveryTime: string;
  recipients: IRecipient[];
  reportId: number;
  recurringId: string;
}

export interface ICustomReportReccuringResponse {
  frequency: string;
  deliveryTime: string;
  recipients: string[];
  reportId: string;
  lastDate: string;
  recurringId: number;
}

const editReccuringReport = async (
  payload: ICustomReportReccuringPayload
): Promise<ICustomReportReccuringResponse> => {
  const { reportId, recurringId, ...rest } = payload;
  const response = await EXPRESS_API.put(
    `/custom-reports/${reportId}/recurrings/${recurringId}`,
    {
      ...rest,
    }
  );
  return response.data;
};

export const useEditReccuringReportMutation = () => {
  return useMutation(editReccuringReport);
};
