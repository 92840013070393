import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { useTableContext } from './TableContext';

type PagesProps = {
  offset: number;
};

type InfoWrapperProps = {
  numberOfElements: number;
};

type PageProps = {
  isSelected: boolean;
};

export const PaginationInfoWrapper = styled.div<InfoWrapperProps>`
  ${theme.fontNormal400};
  font-size: ${theme.fontSizes.md};
  color: black;
  width: ${props => 40 * props.numberOfElements}px;
  overflow: hidden;
`;

export const Pages = styled.div<PagesProps>`
  height: 40px;
  display: inline-flex;
  transition: all 0.3s;
  margin-left: ${props => props.offset}px;
`;

export const PageNumber = styled.div<PageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 16px;
  background-color: ${props =>
    props.isSelected ? theme.palette.grayBorder : 'transparent'};
  color: ${theme.palette.blue100};
  ${props => (props.isSelected ? 'opacity: 1' : 'opacity:0.5')};
  padding: 8px;
  width: 40px;
  cursor: pointer;
  ${props =>
    props.isSelected &&
    css`
      cursor: default;
    `}
  ${props =>
    !props.isSelected &&
    css`
      &:hover {
        background-color: rgba(0, 27, 83, 0.05);
        opacity: 0.75;
      }
    `}
`;

export const TablePaginationNumbers = () => {
  const { page, lastPage, setPage } = useTableContext();
  let pages = [];
  let lastIndex = lastPage > page + 10 ? page + 10 : lastPage;
  let firstIndex = page > 3 ? page - 3 : page;
  if (lastPage < 6) {
    firstIndex = 0;
  } else if (lastPage - page < 4 && page - 4 > 0) {
    firstIndex = page - 4;
  }
  if (page === 3 && lastPage > 6) {
    firstIndex = page - 1;
  }

  for (let index = firstIndex; index < lastIndex; index++) {
    const element = (
      <PageNumber
        key={index}
        isSelected={index === page}
        onClick={() => setPage(index)}
      >
        {index + 1}
      </PageNumber>
    );
    pages.push(element);
  }

  let currentPagePosition = 0;
  if (page < 4) {
    currentPagePosition = 0;
  } else if (page > lastPage - 5) {
    currentPagePosition = 0;
  } else {
    currentPagePosition = 1;
  }
  const pageOffset = currentPagePosition * -40;
  return (
    <PaginationInfoWrapper numberOfElements={lastIndex > 5 ? 5 : lastIndex}>
      <Pages offset={pageOffset}>{pages}</Pages>
    </PaginationInfoWrapper>
  );
};
