export const guidesKeyes = {
  all: () => ['GUIDES'] as const,
  new_count: () => ['NEW_COUNT'] as const,
  search: (search: string) => ['GUIDES', search] as const,
  editableCategories: () => ['EDITABLES_CATEGORIES'] as const,
  categorized: () => ['GUIDES_CATEGORIZED'] as const,
  singleCategory: (id: string) => ['GUIDES_CATEGORIZED', id] as const,
  details: (videoId: number) => ['GUIDES_DETAILS', videoId] as const,
  visibility: () => ['VISIBILITY'] as const,
  deleteMutationKey: (guideCategoryId: number) =>
    ['DELETE-GUIDE-MUTATION', guideCategoryId] as const,
};
