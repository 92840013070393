import React from 'react';
import styled from 'styled-components/macro';
import { useReactToPrint } from 'react-to-print';
import { MdFileDownload } from 'react-icons/md';
import { IoMdOptions } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { theme } from 'lib/style';
import { reportData, reportTypes } from 'lib/const';
import {
  Field,
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';

import {
  AreaChart,
  calculateDatesForRange,
  DateRangeSelector,
  timeRangeConstants,
} from '../../components';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Gap, HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { MostViewedVideos } from '../../dashboard/main/widgets/MostViewedVideos';
import { BestPerformingCtas } from '../../dashboard/main/widgets/BestPerformingCtas';
import { SelectField } from 'lib/components/selectFields/types';

const Container = styled(MainWrapper)`
  @media print {
    padding: 20px;
    @page {
      size: portrait;
    }
  }
  .dropdown-container {
    margin: 0;
  }
`;

const Content = styled.div`
  display: block;
  margin-top: 16px;
  ${theme.mediaQueryMinWidth.lg} {
    display: flex;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  ${theme.mediaQueryMinWidth.lg} {
    width: 66%;
  }
  @media print {
    display: block;
  }
`;

const ChartGroup = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  ${theme.mediaQueryMinWidth.lg} {
    width: 50%;
  }
  @media print {
    width: 50%;
    display: inline-block;
    page-break-after: always;
  }
  > div {
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 #1d1e240a;
    border: solid 1px #f7f8fa;
    margin: 16px 32px 16px 0;
    @media print {
      box-shadow: none;
      border: none;
    }
  }
`;

const ListContainer = styled.div`
  width: 100%;
  > div {
    margin: 16px 0 32px 0;
  }
  @media print {
    display: none;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 34%;
  }
`;

const sectionName = 'Overview';

export const List = () => {
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const [reports, setReports] = React.useState<Field[]>([]);
  const [showCustomizeModal, setShowCustomizeModal] = React.useState(false);
  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );
  const { userData } = useAuth();

  const allReports: Field[] = Object.keys(reportData)
    .filter(
      reportValue =>
        reportValue !== reportTypes.LAST_LOGIN &&
        (!reportData[reportValue].isAutomotive ||
          (!!userData.isAutomotive && !!reportData[reportValue].isAutomotive))
    )
    .map(reportValue => ({
      value: reportValue,
      label: reportData[reportValue].label,
      description: reportData[reportValue]?.description || '',
    }));

  const [startDate, setStartDate] = React.useState<Date>(start);
  const [endDate, setEndDate] = React.useState<Date>(end);
  const [dateRange, setDateRange] = React.useState(initRange);
  const chartContainerRef = React.useRef(null);
  const handleDownload = useReactToPrint({
    content: () => chartContainerRef.current,
  });

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  const onSaveCustomTableFields = (fields: Field[]) => {
    localStorage.setItem(
      `${TABLE_NAME_PREFIX + sectionName}`,
      JSON.stringify(fields)
    );
    setReports(fields);
    setShowCustomizeModal(false);
  };

  React.useEffect(() => {
    let selectedReports: any = localStorage.getItem(
      TABLE_NAME_PREFIX + sectionName
    );
    if (selectedReports) {
      selectedReports = JSON.parse(selectedReports);
      const finalReports: SelectField[] = selectedReports.reduce(
        (reports: SelectField[], report: any) => {
          const reportSelected = reportData[report.value];
          if (
            !reportSelected.isAutomotive ||
            (!!userData.isAutomotive && !!reportSelected.isAutomotive)
          ) {
            // fix recorded report constant
            if (report.value === 'recorded') {
              report.value = 'videos';
            }
            reports.push(report);
          }
          return reports;
        },
        []
      );
      setReports(finalReports);
    } else {
      setReports(allReports.slice(0, 4));
    }
  }, []);

  return (
    <Container ref={chartContainerRef} resetPadding={true}>
      <HelpDesk name={EHelpDesk.MY_STATS} />
      <Heading m='0 0 32px 0'>My Stats</Heading>
      <HeaderWrapper>
        <DateRangeSelector
          initialValue={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
        <Gap>
          <Button
            text='Download'
            icon={<MdFileDownload size={20} />}
            onClick={handleDownload}
          />
          <Button
            text='Customize'
            icon={<IoMdOptions size={20} />}
            onClick={() => setShowCustomizeModal(true)}
          />
        </Gap>
      </HeaderWrapper>
      <Content>
        <ChartContainer>
          {reports.map(report => (
            <ChartGroup key={report.value}>
              <AreaChart
                reportType={report.value}
                from={startDate}
                to={endDate}
                dateRange={dateRange}
              />
            </ChartGroup>
          ))}
        </ChartContainer>
        <ListContainer>
          <MostViewedVideos
            from={startDate}
            to={endDate}
            label='Most Viewed Videos'
          />
          <BestPerformingCtas
            from={startDate}
            to={endDate}
            label='Best Performing CTAs'
          />
        </ListContainer>
      </Content>
      {showCustomizeModal && (
        <ModalCustomTableFields
          title='Customize Overviews'
          onClose={() => setShowCustomizeModal(false)}
          allFields={allReports}
          initialFields={reports}
          actionButtons={[
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
    </Container>
  );
};
