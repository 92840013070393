import { GetRecentlyDeletedParams } from 'lib/api/recentlyDeletedApi';

export enum FileTypes {
  VIDEO = 'video',
  LANDING_PAGE = 'landingPageBuilder',
  FILE = 'file',
  TELE_PROMPTER_SCRIPT = 'telepromterScript',
  CONTACT = 'contact',
  BOARD = 'board',
  CTA_SET = 'ctaSet',
}

export interface LandingPageData {
  thumbnail: string;
  userId: number;
  customerId: number;
  isDefault: number;
  isShared: number;
  isArchived: number;
  allowDuplicates: number;
  builderVersion: string;
  id: number;
  title: string;
  pageData: object;
  hidden: number;
  layoutName: string;
  styleName: string;
  designId: number;
}

export enum TableFieldsValue {
  TITLE = 'title',
  TYPE = 'type',
  CREATED_AT = 'createdAt',
  DAYS = 'days',
  RESTORE = 'restore',
}

export type SearchParams = Pick<GetRecentlyDeletedParams, 'search' | 'type'>;

export interface ITableFields {
  value: TableFieldsValue;
  label: string;
}
export interface IOnPaginationChange {
  page: number;
  size: number;
}

export type ModalTypes =
  | 'DELETE'
  | 'RESTORE'
  | 'PREVIEW'
  | 'RESTORE_ERROR'
  | null;

export interface ISelectedItems {
  count: number;
  data: Map<any, any>;
}
