import React from 'react';
import { RepairOrderListing } from '../components';
import { Container, MainWrapper } from 'lib/components/styles/layout';

export const List = () => {
  return (
    <Container noLeftPadding>
      <MainWrapper resetPadding={false} fixedWidth='1216px'>
        <RepairOrderListing />
      </MainWrapper>
    </Container>
  );
};
