export function isObject(item: any) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target: any, ...sources: any): object {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function anyInMap(arr: any[], map: Map<any, any>, id: string = 'id') {
  let found = false;
  for (let i = 0; i < arr.length; i += 1) {
    if (map.get(arr[i][id])) {
      found = true;
      break;
    }
  }
  return found;
}
