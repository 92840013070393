import React, { useCallback } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';

import { useOnClickOutside } from 'lib/utils';
import {
  ButtonSwitch,
  Dropdown,
  LoadingIndicator,
  Modal,
  Search,
} from 'lib/components';
import { theme } from 'lib/style';
import { formatBytes } from 'lib/utils/functions';
import {
  acceptedFormats,
  ExtendedFile,
  statuses,
} from '../../library/components/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CtaBoardsModalContent } from './CtaBoardsModalContent';
import {
  getStatusSize,
  getStatusText,
} from 'app/pages/library/components/uploadDropTabs/UploadDropTab';
import { DropItem } from 'lib/api/droplr/types';
import { DROPTYPE_OPTIONS, SortFields } from 'lib/const/Droplr';
import { uploadDrop } from 'lib/api/droplr/uploadDrop';
import { getDrops } from 'lib/api/droplr/getDrops';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.div<{ hideToggle: boolean }>`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  min-width: ${props => (props.hideToggle ? '25%' : '')};
`;

const CloseButtonWrap = styled.div<{ hideToggle: boolean }>`
  margin-left: ${props => (props.hideToggle ? '70%' : '10px')};
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const DropContent = styled.div<{ isDragActive: boolean }>`
  margin-top: 24px;
  padding: 0 32px;
  width: 520px;
  height: 120px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${props => (props.isDragActive ? '#F6F7F9' : '#F6F7F9')};
  border: 1px dashed #d0d3d9;
  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.coal};
  }
`;

const UploadFileContent = styled.div`
  margin-top: 24px;
  background: #f6f7f9;
  border-radius: 4px;
  display: flex;
  padding: 16px 32px;
`;

const UploadFileDescription = styled.div`
  width: 90%;
`;

const UploadFileAction = styled.div`
  width: 10%;
`;

const UploadFileTitle = styled.div`
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.coal};
  padding: 8px 12px;
`;

const UploadFileProgress = styled.progress`
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 2px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    border-radius: 2px;
    height: 4px;
  }
  &::-webkit-progress-value {
    float: left;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    border-radius: 2px;
  }
`;

const UploadFileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const FileStatus = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.color ? props.color : '#9297A2')};
`;

const FileSize = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${theme.palette.coal};
`;

const LibraryContainer = styled.div`
  margin-top: 24px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const DropdownContainer = styled.div`
  width: 200px;
`;

const FilesSelector = styled.div`
  margin-top: 24px;
  height: 300px;
  overflow-y: auto;
`;

const FilesItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  margin-bottom: 8px;
  height: 64px;
  box-sizing: border-box;
`;

const FilesItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

type ThumbnailProps = {
  image: string;
};

const FilesItemThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 96px;
  height: 100%;
  flex-shrink: 0;
`;

const FilesItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 24px;
  width: 220px;
`;

const EmptyFiles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
`;

const FieldWrapper = styled.div<{ width: string }>`
  width: ${props => (props.width ? props.width : 'auto')};
  margin-left: auto;

  button {
    margin-left: 0;
  }
`;

export const ItemTypes = [
  {
    value: 'file',
    text: 'Files',
  },
  {
    value: 'board',
    text: 'Boards',
  },
];

type Props = {
  selectedFile?: any;
  view?: string;
  hideToggle?: boolean;
  handleModalClose: () => void;
  onSelect: (drop: DropItem, itemType: string) => void;
};

export const CtaFilesModal = (props: Props) => {
  const {
    handleModalClose,
    onSelect,
    selectedFile,
    view = ItemTypes[0].value,
    hideToggle = false,
  } = props;
  const [file, setFile] = React.useState({
    name: '',
    sizeText: '',
    size: 0,
    currentUpload: 0,
    status: '',
    progress: 0,
  } as ExtendedFile);
  const { colors } = useCovideoTheme();
  const [page, setPage] = React.useState(0);
  const [res, setRes] = React.useState({ count: '0', hasMore: 'false' } as any);
  const [drops, setDrops] = React.useState([] as DropItem[]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [field, setField] = React.useState(SortFields[1]);
  const [itemType, setItemType] = React.useState(
    selectedFile?.itemType || view || ItemTypes[0].value
  );

  const { ref } = useOnClickOutside(handleModalClose);
  const themes = useTheme();

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const onDrop = useCallback(async acceptedFiles => {
    let f = {
      name: acceptedFiles[0].name,
      status: statuses.WAITING,
      sizeText: formatBytes(acceptedFiles[0].size, 2),
      size: acceptedFiles[0].size,
      progress: 0,
      currentUpload: 0,
    };
    setFile(f);

    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = async () => {
      f = {
        ...f,
        status: statuses.UPLOADING,
      };
      setFile(f);

      const uploadedDrop: DropItem = await uploadDrop(
        acceptedFiles[0].name,
        acceptedFiles[0].type,
        reader.result
      );

      f = {
        ...f,
        status: statuses.FINISHED,
        progress: 100,
      };
      setFile(f);

      setTimeout(() => {
        onSelect(uploadedDrop, itemType);
      }, 100);
    };
    reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedFormats.join(', '),
  });

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const fetchDrops = async () => {
    const response = await getDrops({
      sort: field.key,
      order: field.order,
      search: searchQuery,
      page: page + 1,
      dropType: DROPTYPE_OPTIONS.OWNED,
    });
    setPage(page + 1);
    setRes(response);
    setDrops([...drops, ...response.drops]);
  };

  const refreshDrops = async () => {
    if (itemType !== 'file') {
      return;
    }
    setLoading(true);
    const response = await getDrops({
      sort: field.key,
      order: field.order,
      search: searchQuery,
      dropType: DROPTYPE_OPTIONS.OWNED,
    });
    setRes(response);
    const f = response.drops.find(
      (r: any) => r.shortlink === selectedFile.linkValue
    );
    if (f) {
      f.selected = true;
      response.drops = response.drops.filter((r: any) => r !== f);
      response.drops.unshift(f);
    } else if (selectedFile.fileTitle && selectedFile.itemType === 'file') {
      response.drops.unshift({
        code: selectedFile.fileTitle,
        title: selectedFile.fileTitle,
        previewSmall: selectedFile.fileThumbnail,
        shortlink: selectedFile.linkValue,
        selected: true,
      } as DropItem);
    }

    setLoading(false);
    setDrops(response.drops);
  };

  React.useEffect(() => {
    if (itemType !== 'file') {
      return;
    }
    refreshDrops();
  }, [field, searchQuery, itemType]);

  const uploadingFiles = !!file.name;

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px', minWidth: '520px' }}>
        <Header>
          <HeaderTitle hideToggle={hideToggle}>{`Select ${
            view === 'file' ? `File` : `Board`
          }`}</HeaderTitle>
          {!hideToggle && (
            <FieldWrapper width={'150px'}>
              <ButtonSwitch
                defaultValue={itemType}
                values={ItemTypes}
                onChange={newValue => {
                  setItemType(newValue);
                }}
              />
            </FieldWrapper>
          )}
          <CloseButtonWrap hideToggle={hideToggle} title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        {itemType === 'file' && (
          <>
            {!uploadingFiles && (
              <DropContent isDragActive={isDragActive} {...getRootProps()}>
                <input {...getInputProps()} />
                {!isDragActive && (
                  <>
                    <p>Drag and drop files here to upload new file</p>
                    <Button variant='white' text='Browse files...' />
                  </>
                )}
                {isDragActive && <p>Drop files here</p>}
              </DropContent>
            )}
            {uploadingFiles && (
              <UploadFileContent>
                <UploadFileDescription>
                  <UploadFileTitle>{file.name}</UploadFileTitle>
                  <UploadFileProgress value={file.progress} max={100} />
                  <UploadFileDetails>
                    <FileStatus
                      color={
                        file.status === statuses.WAITING
                          ? '9297A2'
                          : colors.primary[100]
                      }
                    >
                      {getStatusText(file.status)}
                    </FileStatus>
                    <FileSize>{getStatusSize(file)}</FileSize>
                  </UploadFileDetails>
                </UploadFileDescription>
                <UploadFileAction></UploadFileAction>
              </UploadFileContent>
            )}
            <LibraryContainer>
              {/* <Label>Library</Label> */}
              <Menu>
                <div style={{ width: '280px' }}>
                  <Search
                    placeholder={`Search files...`}
                    onSearch={onSearch}
                    prevSearch={searchQuery}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <DropdownContainer>
                    <Dropdown
                      value={field}
                      onChange={newValue => {
                        setField(newValue);
                      }}
                      options={SortFields}
                      dropdownHeight={450}
                      height={40}
                    />
                  </DropdownContainer>
                </div>
              </Menu>
              {loading && (
                <LoadingIndicator isLoading={loading} height='200px' />
              )}
              {!loading && !!drops.length && (
                <FilesSelector>
                  <InfiniteScroll
                    dataLength={page * parseInt(res.count)}
                    next={fetchDrops}
                    hasMore={res.hasMore === 'true'}
                    loader={<h4>Loading...</h4>}
                    height={300}
                    endMessage={
                      <p style={{ textAlign: 'center' }}>
                        <b>No more files</b>
                      </p>
                    }
                  >
                    {drops.map(drop => (
                      <FilesItem key={drop.code}>
                        <FilesItemWrapper>
                          <FilesItemThumbnail image={drop.previewSmall} />
                          <FilesItemTitle>{drop.title}</FilesItemTitle>
                        </FilesItemWrapper>
                        {!drop.selected && (
                          <Button
                            onClick={() => onSelect(drop, itemType)}
                            text='Select File'
                          />
                        )}
                        {drop.selected && (
                          <Button
                            color={themes.colors.primary[100]}
                            text='Selected'
                            style={{
                              opacity: 0.5,
                            }}
                          />
                        )}
                      </FilesItem>
                    ))}
                  </InfiniteScroll>
                </FilesSelector>
              )}
              {!loading && !drops.length && (
                <EmptyFiles>No files found.</EmptyFiles>
              )}
            </LibraryContainer>
          </>
        )}
        {itemType === 'board' && (
          <>
            <CtaBoardsModalContent
              onSelect={onSelect}
              selectedFile={selectedFile}
              isQrModal={false}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
