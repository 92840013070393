import React, { useState } from 'react';
import { Formik, FieldArray, Form, FormikValues, FormikHelpers } from 'formik';
import styled, { css } from 'styled-components/macro';
import { SubHeading } from 'lib/components/styles/typography';

import { Gap } from 'lib/components/styles/layout';
import { MdAdd, MdEdit, MdOutlineArrowForwardIos } from 'react-icons/md';
import { ModalSaveFilters } from './modals/SaveFiltersModal';
import {
  IQuickFilterQuery,
  IQuickFilterRule,
  useQuickFiltersQuery,
} from 'lib/api/quickFilters/getQuickFilters';
import { useQuickQueryMutation } from 'lib/api/quickFilters/createQuickFilter';
import { ModalManageFilters } from './modals/ManageFiltersModal';
import { QuickFilterGroup } from './QuickFiltersGroup';
import { useUpdateQuickQueryMutation } from 'lib/api/quickFilters/updateQuickFilter';
import { Button } from 'react-covideo-common';

export interface IQuickQueryValues {
  rules: IQuickFilterQuery;
  name: string;
  id: number | null;
}

//INITIAL VALUE IS ALWAYS engagement_rate
const INITIAL_RULE: IQuickFilterRule = {
  engagement_rate: {
    $gt: '25',
  },
};

const INITIAL_VALUES: IQuickQueryValues = {
  id: null,
  name: '',
  rules: {
    $and: [
      {
        $and: [INITIAL_RULE],
      },
    ],
  },
};

const Footer = styled.div`
  background: #ffffff;
  height: 72px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px -4px 8px rgba(66, 79, 104, 0.03);
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
`;

const Header = styled.div`
  background: white;
  height: 72px;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0;
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px -4px 8px rgba(66, 79, 104, 0.03);
  display: flex;
  align-items: center;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Filters = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  overflow: scroll;
  height: 32px;
  padding: 20px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const FilterItem = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  display: flex;
  gap: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(0, 27, 83, 0.4);
  border-radius: 24px;
  padding: 0px 14px;
  color: rgba(0, 27, 83, 0.6);
  ${({ selected }) =>
    selected &&
    css`
      background: white;
      color: rgba(0, 27, 83, 1);
      border: 1px dashed #001b53;
    `}
  :hover {
    background: white;
    color: rgba(0, 27, 83, 1);
  }
`;

const ManageFiltersParagraph = styled.p<{ color: string; underline?: boolean }>`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ color }) => color};
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

const NoFiltersMessage = styled.div`
  margin:0:
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

export const QuickFiltersBuilder = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const [formikValues, setFormikValues] = useState(INITIAL_VALUES);

  const resetFormikValues = () => {
    setFormikValues(INITIAL_VALUES);
  };
  const { data } = useQuickFiltersQuery();
  const { mutate: mutateCreate } = useQuickQueryMutation();
  const { mutate: mutateUpdate } = useUpdateQuickQueryMutation();
  const [showSaveFilters, setShowSaveFilters] = useState(false);
  const [showManageFilters, setShowManageFilters] = useState(false);
  const handleSave = (values: FormikValues, formik: FormikHelpers<any>) => {
    if (values.id) {
      mutateUpdate({
        payload: { rules: values.rules, title: values.name, id: values.id },
        actions: {
          resetFormikValues,
          setShowSaveFilters,
          formik,
        },
      });
      return;
    }

    mutateCreate({
      payload: { rules: values.rules, title: values.name },
      actions: {
        resetFormikValues,
        setShowSaveFilters,
        formik,
      },
    });
  };

  const isEmpty = data?.length === 0;

  return (
    <div
      style={{
        marginTop: 72,
        width: 912,
        margin: '90px auto 0 auto',
        padding: '0 8px',
      }}
    >
      <Gap>
        <ManageFiltersParagraph color='rgba(0, 27, 83, 0.8)'>
          Saved filters
        </ManageFiltersParagraph>
        {!isEmpty && (
          <ManageFiltersParagraph
            underline
            color='#001B53'
            onClick={() => setShowManageFilters(true)}
          >
            Manage
          </ManageFiltersParagraph>
        )}
      </Gap>

      <Formik
        initialValues={formikValues}
        onSubmit={handleSave}
        enableReinitialize
      >
        {({ values, setFieldValue, resetForm, submitForm }) => {
          // grab rules.root
          const root = Object.keys(values.rules)[0] as keyof IQuickFilterQuery;
          return (
            <>
              <Form>
                <Filters>
                  <FilterItem onClick={() => setShowSaveFilters(true)}>
                    {formikValues.id ? (
                      <MdEdit size={18} />
                    ) : (
                      <MdAdd size={18} />
                    )}
                    {formikValues.id ? 'Edit' : 'Save'} current
                  </FilterItem>
                  {isEmpty ? (
                    <NoFiltersMessage>No filters</NoFiltersMessage>
                  ) : (
                    <>
                      {data?.map(item => (
                        <FilterItem
                          key={item.quickFilterId}
                          selected={item.quickFilterId === formikValues.id}
                          onClick={() => {
                            if (item.quickFilterId === formikValues.id) {
                              return setFormikValues(INITIAL_VALUES);
                            }

                            setFormikValues({
                              name: item.title,
                              rules: item.rules,
                              id: item.quickFilterId,
                            });
                          }}
                        >
                          {item.title}
                        </FilterItem>
                      ))}
                    </>
                  )}
                </Filters>
                <Header>
                  <Gap onClick={handleClose} style={{ cursor: 'pointer' }}>
                    <MdOutlineArrowForwardIos
                      style={{ transform: 'rotate(180deg)' }}
                    />
                    <SubHeading>Select filters</SubHeading>
                  </Gap>
                  <Gap>
                    <Button
                      variant='secondary'
                      text={'Close without Saving'}
                      onClick={handleClose}
                    />
                    <Button
                      variant='destructive'
                      text={'Clear all Filters'}
                      onClick={() => resetForm()}
                    />
                  </Gap>
                </Header>
                <FieldArray name={`rules.${root}`}>
                  {({ push, remove }) => {
                    return (
                      <QuickFilterGroup
                        values={values}
                        root={root}
                        setFieldValue={setFieldValue}
                        push={push}
                        remove={remove}
                        INITIAL_RULE={INITIAL_RULE}
                      />
                    );
                  }}
                </FieldArray>
                <Footer>
                  <Button
                    text={'Run Report'}
                    type='button'
                    onClick={() => console.log('RUN REPORT')}
                  />
                </Footer>
                {showSaveFilters && (
                  <ModalSaveFilters
                    onModalClose={() => setShowSaveFilters(false)}
                    submitForm={submitForm}
                    values={values}
                  />
                )}
                {showManageFilters && (
                  <ModalManageFilters
                    onModalClose={() => setShowManageFilters(false)}
                    quickFilters={data}
                    resetFormikValues={resetFormikValues}
                  />
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
