import React from 'react';
import { useFormikContext } from 'formik';
import { Gap } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';

import { Button, useCovideoTheme } from 'react-covideo-common';
import { MdDeleteForever, MdEmail, MdSave } from 'react-icons/md';

interface IDetailsPreviewHeaderProps {
  setContinueToSendShare: React.Dispatch<React.SetStateAction<boolean>>;
  handleGoToRecordHome: () => void;
}

export const DetailsPreviewHeader = ({
  setContinueToSendShare,
  handleGoToRecordHome,
}: IDetailsPreviewHeaderProps) => {
  const { isValid, submitForm } = useFormikContext();
  const { userData } = useAuth();
  const { colors } = useCovideoTheme();

  const handleContinueToSendShare = () => {
    setContinueToSendShare(true);
    submitForm();
  };

  return (
    <>
      <Gap width='100%' justifyContent='space-between' m='0 0 32px 0'>
        <Heading color={colors.black[100]}>Preview recorded video</Heading>
        <Gap gap='8px'>
          <Button
            icon={<MdDeleteForever height={18} />}
            text={'Discard'}
            variant='destructive'
            onClick={handleGoToRecordHome}
          />
          <Button
            disabled={!isValid}
            icon={<MdSave size={18} />}
            text={userData.isAutomotiveSalesRole ? 'Save' : 'Save Recording'}
            onClick={submitForm}
          />

          {userData.isAutomotiveSalesRole && (
            <Button
              variant='primary'
              disabled={!isValid}
              icon={<MdEmail size={20} />}
              text='Continue to Send & Share'
              onClick={handleContinueToSendShare}
            />
          )}
        </Gap>
      </Gap>
    </>
  );
};
