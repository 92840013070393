import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import dayjs from 'dayjs';

export const DATETIME_MERIDIEM = `${STANDARD_DATE_FORMAT} hh:mm A`;

export const convertTime12to24 = (time: string, meridiem: string) => {
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (meridiem === 'pm') {
    let parse = parseInt(hours, 10) + 12;
    hours = parse.toString();
  }

  return `${hours}:${minutes}`;
};

export const convertTime24to12 = (dt: Date) => {
  var hours = dt.getHours(); // gives the value in 24 hours format
  var AmOrPm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12;
  var minutes = dt.getMinutes();
  const digit = minutes.toString();
  return `${hours}:${digit.length === 1 ? '0' + minutes : minutes} ${AmOrPm}`;
};

export const combineDateAndTime = (
  date: Date | null,
  time: Date | null,
  meridiem: string
) => {
  const convertDate = dayjs(date).format('YYYY-MM-DD');
  const convertTime = dayjs(time).format('hh:mm');

  const convertMeridiem = convertTime12to24(convertTime, meridiem);

  const m = Date.parse(convertDate + ' ' + convertMeridiem);
  return new Date(m);
};

export const calculateDaysBetween = (date: Date) => {
  const current = dayjs();
  return dayjs(date).diff(current, 'day');
};

export const addHours = (date: Date, hours: number) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

export const addMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() + months);
  return date;
};

export const addOneYear = (date: Date) => {
  date.setFullYear(date.getFullYear() + 1);
  return date;
};

export const calculateDaysLeft = (
  videoDelete: any,
  stopRequest: any,
  dateOfCreation: any
) => {
  const daysPassed = Math.floor(
    (Date.now() - Date.parse(dateOfCreation)) / 86400000
  );
  if (videoDelete?.days) {
    if (stopRequest && stopRequest.approved === 1 && stopRequest.days) {
      const extendDays = videoDelete.days + stopRequest.days;
      return extendDays - daysPassed;
    }
    return videoDelete.days - daysPassed;
  }
  return -1;
};

// Round a Date() to the nearest x minutes
export const getRoundedDate = (minutes: number, date: Date = new Date()) => {
  const coeff = 1000 * 60 * minutes;
  return new Date(Math.ceil(date.getTime() / coeff) * coeff);
};
