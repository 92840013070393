import React from 'react';
import { theme } from 'lib/style';

import styled from 'styled-components/macro';
import {
  Dropdown,
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { CustomReportItem, useAuth } from 'lib/context';
import { reportData, reportTypes } from 'lib/const';
import { ReportUserData, getCustomReportData } from 'lib/api';
import {
  addThousandCommaSeparator,
  formatSecondsToTime,
} from 'lib/utils/functions';
import { AreaChart, DateRangeSelector } from '../../components';
/**** SUS-926 changes ****/
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { Gap } from 'lib/components/styles/layout';
import { useDepartmentsQuery } from 'lib/api/superadmin/departments';
import { SENT_UNIQUE_REPORT_DATA } from 'lib/const/ReportData';
/******/
const Container = styled.div`
  margin-top: 24px;
  @media print {
    padding: 20px;
  }
`;

const DateRangeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Header = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #272a32;
`;

const ChartContent = styled.div`
  margin-top: 32px;
  width: 100%;
  display: block;
  ${theme.mediaQueryMinWidth.lg} {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ChartGroup = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  > div {
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 #1d1e240a;
    border: solid 1px #f7f8fa;
    margin: 16px 32px 16px 0;
  }
  ${theme.mediaQueryMinWidth.xlg} {
    width: 33%;
  }
`;
/**** SUS-926 changes ****/
const TableCell = styled.div<{ clicked?: boolean; textAlign?: string }>`
  padding-left: 24px;
  cursor: ${props => (props.clicked ? 'pointer' : 'auto')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
`;

type TableFieldType = {
  value: string;
  label: string;
  type: string;
  formatter?: (value?: string | number) => string;
  clickable: boolean;
};

type Props = {
  customReport: CustomReportItem;
  dateRange: string;
  from: Date | null;
  to: Date | null;
  onDateRangeChange: (startDate: Date, endDate: Date, range: string) => void;
};

const queryDateFormat = STANDARD_DATE_FORMAT; /**** SUS-926 changes ****/

export const ReportTab = (props: Props) => {
  const {
    userData: { access, isAutomotive, customerId },
  } = useAuth();

  const { customReport, dateRange, from, to, onDateRangeChange } = props;

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [data, setData] = React.useState<ReportUserData[]>([]);
  const [filteredData, setFilteredData] = React.useState<ReportUserData[]>([]);
  const isCompanyAdmin = access === '3' || access === '4';
  const [hasVideoAttributesReport, setHasVideoAttributesReport] =
    React.useState(false);
  const [search, setSearch] = React.useState('');
  const [department, setDepartment] = React.useState<string>('All');

  const fetchData = async () => {
    if (dateRange && from && to && !loading) {
      setLoading(true);
      const reportData = await getCustomReportData(
        customReport.reportId || '',
        from,
        to
      );
      const isVideoAttributes = reportData.length
        ? !!reportData.filter((item: any) => !!item.records).length
        : !!reportData.records;

      if (isVideoAttributes) setHasVideoAttributesReport(true);
      if (reportData.length === 1 && isVideoAttributes) {
        setData([]);
      } else setData(reportData as ReportUserData[]);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (isCompanyAdmin) {
      fetchData();
    }
  }, [isCompanyAdmin, dateRange, from, to]);

  React.useEffect(() => {
    if (data.length === 0) return;

    const filtered = [...data];
    const searchLower = search.toLowerCase();
    const filteredBySearch =
      search === ''
        ? filtered
        : filtered.filter(item =>
            item.userFullName.toLowerCase().includes(searchLower)
          );

    const filteredByDepartment =
      department === 'All'
        ? filteredBySearch
        : filteredBySearch.filter(item => item.dept === department.toString());

    const start = page * size;
    const end = start + size;

    setFilteredData([...filteredByDepartment.slice(start, end)]);
  }, [data, page, size, search, department]);

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const tableFields: TableFieldType[] = [
    {
      value: 'userFullName',
      label: 'User Name',
      type: 'string',
      clickable: true,
    },
    {
      value: 'business',
      label: 'Company Name',
      type: 'string',
      clickable: true,
    },
    {
      value: 'dept',
      label: 'Department',
      type: 'string',
      clickable: true,
    },
  ];

  if (customReport && !!customReport.reports && !!customReport.reports.length) {
    if (isAutomotive) {
      tableFields[0].label = 'Advisor Name';
    }
    customReport.reports.forEach(report => {
      if (reportData[report]) {
        tableFields.push({
          value: report,
          label: reportData[report].label,
          type: report !== reportTypes.LAST_LOGIN ? 'number' : 'string',
          formatter:
            report === reportTypes.TIME_WATCHED
              ? value =>
                  formatSecondsToTime(parseInt(value?.toString() || '0', 10))
              : undefined,
          clickable: ![
            reportTypes.LAST_LOGIN,
            reportTypes.SENT_UNIQUE,
          ].includes(report),
        });
      }
      if (!reportData[report] && report === reportTypes.SENT_UNIQUE) {
        tableFields.push({
          value: report,
          label: SENT_UNIQUE_REPORT_DATA.label,
          type: 'number',
          formatter: undefined,
          clickable: false,
        });
      }
    });
  }
  const history = useHistory(); /**** SUS-926 changes ****/
  const location = useLocation(); /**** SUS-926 changes ****/

  const { data: departments } = useDepartmentsQuery({
    customerId,
  });

  const departmentsOptions =
    departments?.data?.map(department => ({
      value: department.id,
      label: department.name,
    })) || [];

  const onCustomerDropdownChangeHandler = (value: {
    label: string;
    value: number;
  }) => {
    setDepartment(value.label);
  };

  return (
    <Container key={`${from?.toISOString()}${to?.toISOString()}`}>
      <Gap justifyContent='space-between' width='100%'>
        <Header>Overview</Header>
        <DateRangeContainer>
          <DateRangeSelector
            hideCustom={true}
            onDateRangeChange={onDateRangeChange}
            initialValue={customReport.range}
            reverse={true}
            customStartDate={from ?? new Date()}
            customEndDate={to ?? new Date()}
          />
        </DateRangeContainer>
      </Gap>

      {from && to && (
        <ChartContent key={dateRange}>
          {(customReport.reports || [])
            .filter(
              report => reportData[report] && !reportData[report]?.hideWidget
            )
            .map(report => {
              return (
                <ChartGroup key={report}>
                  <AreaChart
                    reportType={report}
                    from={from}
                    to={to}
                    dateRange={dateRange}
                    reportId={customReport.reportId}
                    users={customReport.users || []}
                  />
                </ChartGroup>
              );
            })}
        </ChartContent>
      )}

      {isCompanyAdmin && !hasVideoAttributesReport && (
        <div style={{ marginTop: '24px' }}>
          {loading && <LoadingIndicator isLoading={loading} height='300px' />}
          {!loading && (
            <div style={{ marginTop: '18px' }}>
              <Gap justifyContent='space-between'>
                <Search
                  onSearch={value => setSearch(value)}
                  placeholder='Search advisor names'
                  width='280px'
                  prevSearch={search || ''}
                />
                <Dropdown
                  creatable={false}
                  onChange={value => onCustomerDropdownChangeHandler?.(value)}
                  options={[
                    { value: null, label: 'All' },
                    ...(departmentsOptions || []),
                  ]}
                  value={departmentsOptions?.find(
                    dept => dept.label === department
                  )}
                  extendStyles={{ container: { width: '200px' } }}
                  placeholder='All Departments'
                  className='dropdown'
                />
              </Gap>

              {!!data.length && (
                <TableContextProvider
                  total={data.length}
                  onChange={onPaginationChange}
                  initPage={page}
                  initSize={size}
                >
                  <div style={{ overflowX: 'auto' }}>
                    <Table
                      compact={true}
                      headers={[
                        ...tableFields.map(item => {
                          return (
                            <TableCell
                              textAlign={
                                item.value === 'userFullName' ||
                                item.value === reportTypes.LAST_LOGIN ||
                                item.value === 'dept'
                                  ? 'left'
                                  : 'center'
                              }
                            >
                              {item.label}
                            </TableCell>
                          );
                        }),
                      ]}
                      hoverable={false}
                      rows={filteredData.map((video: any, index: number) => ({
                        key: index,
                        columns: [
                          ...tableFields.map(item => {
                            /**** SUS-926 changes ****/
                            const onClick = () => {
                              if (!item.clickable) {
                                return;
                              }
                              history.push(
                                `/reports/users/${video.userId}?start=${dayjs(
                                  from
                                ).format(queryDateFormat)}&end=${dayjs(
                                  to
                                ).format(queryDateFormat)}&range=${dateRange}`
                              );
                            };
                            /******/
                            if (item.type === 'number') {
                              /**** SUS-926 changes ****/
                              const queryParams: any = queryString.parse(
                                location.search
                              );
                              queryParams.start =
                                dayjs(from).format(queryDateFormat);
                              queryParams.end =
                                dayjs(to).format(queryDateFormat);
                              queryParams.range = dateRange;
                              queryParams.user = video.userId;
                              const onNumberClick = () => {
                                if (!item.clickable) {
                                  return;
                                }
                                history.push(
                                  `/reports/overview/${
                                    item.value
                                  }?${queryString.stringify(queryParams)}`
                                );
                              };
                              /****/
                              return (
                                <TableCell
                                  clicked={item.clickable}
                                  onClick={
                                    item.clickable ? onNumberClick : undefined
                                  }
                                >
                                  {item.formatter
                                    ? item.formatter(video[item.value] || 0)
                                    : addThousandCommaSeparator(
                                        video[item.value] || 0
                                      )}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell
                                textAlign={
                                  item.value === 'userFullName' ||
                                  item.value === reportTypes.LAST_LOGIN ||
                                  item.value === 'dept'
                                    ? 'left'
                                    : 'center'
                                }
                                clicked={item.clickable}
                                onClick={item.clickable ? onClick : undefined}
                              >
                                {/**** SUS-926 changes ****/}
                                {
                                  // @ts-ignore
                                  video[item.value] || '-'
                                }
                              </TableCell>
                            );
                          }),
                        ],
                      }))}
                    />
                  </div>
                  <TableFooter>
                    <TablePaginationNew />
                    <TablePaginationSizeNew globalName='contact_list' />
                  </TableFooter>
                </TableContextProvider>
              )}
              {!data.length && (
                <div style={{ textAlign: 'center' }}>No data to show.</div>
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};
