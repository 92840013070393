import { CombinedBoard, DropItem } from 'lib/api/droplr/types';
import { ICombinedUserData } from 'lib/context';

export const getShortLink = (drop: DropItem): string => {
  let url = drop.shortlink || '';

  if (drop.privacy === 'TEAM') {
    url += '?type=team';
  } else if (drop.privacy === 'PRIVATE') {
    url += '?type=private';
  }

  return url;
};

export const prepareBoardNotification = (
  board: CombinedBoard,
  recipients: string[],
  userData: ICombinedUserData
) => {
  return {
    entityName: board.name,
    entityUrl: board.shortlink || '',
    recipientIds: recipients,
    type: 'boards',
    entityId: board.id,
    senderId: parseInt(userData.userId, 10),
    senderName: `${userData.firstName} ${userData.lastName}`,
    notificationProp: ['boardsEmail', 'boardsInApp'],
    sendEmail: () => {},
  };
};

export const sortSharedDrops = (drops: DropItem[], field: any) => {
  if (!drops) {
    return drops;
  }

  switch (field.value) {
    case 'createdAtASC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          (drop1.createdAt || 0) - (drop2.createdAt || 0)
      );
      break;
    }
    case 'createdAtDESC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          (drop2.createdAt || 0) - (drop1.createdAt || 0)
      );
      break;
    }
    case 'titleASC': {
      drops.sort((drop1: DropItem, drop2: DropItem) =>
        drop1.title.localeCompare(drop2.title)
      );
      break;
    }

    case 'titleDESC': {
      drops.sort((drop1: DropItem, drop2: DropItem) =>
        drop2.title.localeCompare(drop1.title)
      );
      break;
    }

    case 'sizeASC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          (drop1.size || 0) - (drop2.size || 0)
      );
      break;
    }

    case 'sizeDESC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          (drop2.size || 0) - (drop1.size || 0)
      );
      break;
    }

    case 'viewsASC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          (drop1.views || 0) - (drop2.views || 0)
      );
      break;
    }

    case 'viewsDESC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          (drop2.views || 0) - (drop1.views || 0)
      );
      break;
    }

    case 'activityASC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          drop1.lastAccess - drop2.lastAccess
      );
      break;
    }

    case 'activityDESC': {
      drops.sort(
        (drop1: DropItem, drop2: DropItem) =>
          drop2.lastAccess - drop1.lastAccess
      );
      break;
    }

    default: {
      break;
    }
  }

  return drops;
};
