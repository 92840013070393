import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { useState } from 'react';

const Switch = styled.div<{ disabled?: boolean }>`
  box-sizing: border-box;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #d0d3d9;
  background-color: rgba(0, 27, 83, 0.05);
  display: flex;
  align-items: center;
  & > span {
    margin: 12px;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      > button {
        opacity: 0.5;
      }
    `}
`;

const NonSelectedBtn = styled.span<any>`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #9297a2;
  cursor: pointer;
`;

type Value = {
  text: string;
  value: string;
  width?: string;
};
type Props = {
  defaultValue: string;
  onChange: (value: string) => void;
  values: Value[];
  skipConfirmation?: boolean;
  className?: string;
  disabled?: boolean;
};

export const ButtonSwitch = (props: Props) => {
  const {
    defaultValue,
    values,
    onChange,
    className,
    skipConfirmation = true,
  } = props;

  const [value, setValue] = useState(defaultValue);

  return (
    <Switch
      {...(className && { className: className })}
      disabled={props.disabled}
    >
      {values.map(btn => {
        if (btn.value === value) {
          return (
            <div style={{ width: btn.width }} key={btn.value}>
              <Button text={btn.text} size='large' />
            </div>
          );
        }
        return (
          <NonSelectedBtn
            key={btn.value}
            onClick={() => {
              onChange(btn.value);
              if (skipConfirmation) setValue(btn.value);
            }}
          >
            {btn.text}
          </NonSelectedBtn>
        );
      })}
    </Switch>
  );
};
