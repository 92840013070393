import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { departmentKeys } from './queryKeys';

const updateDepartmentDefault = async ({
  id,
  criteria,
}: {
  id: number | string;
  criteria: string | number;
}) => {
  const params = {
    removeDefault: criteria,
  };
  const data = {};
  const response = await EXPRESS_API.put(`/departments/${id}`, data, {
    params,
  });
  return response.data;
};

export const useUpdateDepartmentDefaultMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateDepartmentDefault, {
    onSuccess: () => {
      queryClient.invalidateQueries(departmentKeys.all());
      successToast({ title: 'Default department updated' });
    },
    onError: () => {
      errorToast({
        title: 'Error in updating department',
      });
    },
  });
};
