import { HoverPopup, ListDropdownMenu, Spinner } from 'lib/components';
import { successToast } from 'lib/components/toasts/success';
import { VideoListAutomotiveItem, VideoRequest, useAuth } from 'lib/context';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import { MdCreateNewFolder, MdDeleteForever, MdDownload } from 'react-icons/md';
import styled from 'styled-components/macro';
import { DropdownElement, MoreDropdownItems } from '../VideoItemActions';
import { VideoEmailStatus } from 'lib/const';
import { DeleteVideoModal } from 'lib/components/modal/DeleteVideoModal';
import { text } from 'lib/utils';
import {
  checkIfUserIsServiceAdvisor,
  checkIfUserIsServiceManager,
} from 'lib/utils/automotiveRolePermissionChecks';
import { DefaultFolderIds, SHARED_FOLDER_PREFIX } from 'lib/const/Folders';
import { pinVideos, unpinVideos } from 'lib/api';
import { errorToast } from 'lib/components/toasts/error';
import { BsFillPinFill } from 'react-icons/bs';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { Folder } from 'lib/api/folders/getFolders';
import { useVideoMoveCopy } from 'lib/api/videos/useVideoMoveCopy';

type IconWrapperProps = {
  disabled?: boolean;
};

const IconWrapper = styled.span<IconWrapperProps>`
  margin-left: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid transparent;
  border-radius: 5px;
  svg {
    fill: ${props =>
      !!props.disabled ? theme.palette.gray40 : theme.palette.primaryDarkBlue};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary['non_alpha']};
    border: 1px solid ${theme.palette.blue05};
    svg {
      fill: ${props =>
        !!props.disabled
          ? theme.palette.gray40
          : theme.palette.primaryDarkBlue};
    }
  }
`;

type Props = {
  video: VideoListAutomotiveItem;
  handleDownload: (downloadData: { videoId: string; title: string }[]) => void;
  access?: string;
  videoRequest: VideoRequest;
  handleDeleteSubmit?: (videoIds: string[]) => void;
  disablePinOption?: boolean;
  currentFolder?: Folder | null;
  isFolderShared?: boolean;
  refreshVideoList?: () => void;
};

export const VideoItemCompanyActions = ({
  video,
  handleDownload,
  access,
  videoRequest,
  handleDeleteSubmit,
  disablePinOption,
  currentFolder = null,
  isFolderShared = false,
  refreshVideoList,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const { isAutomotive, isAutomotiveSalesRole, trialExpired } = userData;

  const isServiceAdvisor = checkIfUserIsServiceAdvisor(userData);
  const isServiceManager = checkIfUserIsServiceManager(userData);
  const [showMoreDropdown, setShowMoreDropdown] = useState('');
  const [showModalDelete, setShowModalDelete] = useState(false);
  const isPinned = !!video.pinnedVideo?.pinnedAt;

  const handleDownloadClick = async () => {
    const downloadData = { videoId: video.id, title: video.title };
    handleDownload([downloadData]);
  };

  const handleDeleteClick = () => {
    setShowModalDelete(true);
  };

  const handlePinButtonClick = async () => {
    if (!currentFolder) {
      errorToast({
        title: 'Folder not found',
      });
      return;
    }

    const folderName = isFolderShared
      ? `${SHARED_FOLDER_PREFIX}${currentFolder.folderId}`
      : currentFolder.name;
    const videoId = [parseInt(video.id, 10)];
    try {
      !isPinned
        ? await pinVideos(videoId, folderName)
        : await unpinVideos(videoId, folderName);
      successToast({
        title: `Video ${!isPinned ? 'pinned' : 'unpinned'} successfully`,
      });
    } catch (error) {}
    refreshVideoList && refreshVideoList();
  };

  const pinOption = {
    title: isPinned ? 'Unpin' : 'Pin',
    icon: <BsFillPinFill color={theme.palette.blue40} />,
    onClick: handlePinButtonClick,
    isDisabled: disablePinOption && !isPinned,
    showNonFreemiumTooltip: true,
    tooltipText: 'You can pin up to 5 videos',
    productTooltipStyle: {
      padding: '0px 15px',
      width: 'max-content',
      color: theme.palette.black,
      opacity: 1,
    },
  };
  const dropdownItems = [
    {
      title: 'Delete',
      titleColor: theme.palette.buttonDelete,
      icon: <MdDeleteForever color={theme.palette.buttonDelete} size={20} />,
      onClick: handleDeleteClick,
    },
  ];

  const { mutate } = useVideoMoveCopy();

  const handleCopyToLibraryClick = async () => {
    const obj = {
      videoId: video.id,
      copy: true,
      videoFolder: 'Primary',
      successMessage: 'Video copied successfully.',
    };
    setLoading(true);
    try {
      await mutate(obj);
    } catch (err) {
      console.log('err', err);
    }
    setLoading(false);
  };

  const onDeleteSubmit = () => {
    handleDeleteSubmit?.([video.id]);
    setShowModalDelete(false);
  };

  let moreDropdownItems: MoreDropdownItems[] = [];

  const userId = parseInt(userData.id, 10);
  if (
    ((video?.user && video.user.id === userId) ||
      parseInt(video?.videoRequest?.userId ?? '0', 10) === userId) &&
    (!isAutomotive ||
      isAutomotiveSalesRole ||
      isServiceAdvisor ||
      isServiceManager)
  ) {
    moreDropdownItems = dropdownItems;
  }

  moreDropdownItems.push(pinOption);
  if (access === 'view') {
    return null;
  }

  return (
    <>
      <Gap gap='8px'>
        <HoverPopup
          width='130px'
          position='above'
          padding='8px 12px'
          hoverable={
            <Button
              onClick={() => handleCopyToLibraryClick()}
              disabled={loading || trialExpired}
              text='Copy to Primary'
              variant='secondary'
              icon={
                !loading ? (
                  <MdCreateNewFolder size={20} />
                ) : (
                  <Spinner
                    size={8}
                    color={theme.palette.blue100}
                    thickness={2}
                  />
                )
              }
            />
          }
          popup={<>Copy to Primary</>}
        />
        <HoverPopup
          width='auto'
          position='above'
          padding='8px 12px'
          hoverable={
            <Button
              onClick={() => handleDownloadClick()}
              variant='secondary'
              icon={<MdDownload size={20} />}
              text='Download'
              disabled={trialExpired}
            />
          }
          popup={<>Download</>}
        />
      </Gap>
      <DropdownElement
        disabled={trialExpired}
        onClick={() => {
          if (
            !moreDropdownItems.length ||
            videoRequest?.emailStatus === VideoEmailStatus.Deleted ||
            trialExpired
          ) {
            return;
          }
          setShowMoreDropdown(showMoreDropdown !== video.id ? video.id : '');
        }}
      >
        <IconWrapper
          disabled={
            !moreDropdownItems.length ||
            videoRequest?.emailStatus === VideoEmailStatus.Deleted ||
            trialExpired
          }
        >
          <BiDotsVerticalRounded
            size={20}
            color={theme.palette.covideoBlue40}
          />
        </IconWrapper>
        <ListDropdownMenu
          className={'show-more-dropdown'}
          isMenuOpen={showMoreDropdown === video.id} /** SUS-973 changes **/
          items={moreDropdownItems}
          id={'show-more-dropdown'}
          setIsMenuOpen={() => setShowMoreDropdown('')} /** SUS-973 changes **/
        />
      </DropdownElement>
      {showModalDelete && (
        <DeleteVideoModal
          handleModalClose={() => setShowModalDelete(false)}
          handleModalSubmit={() => onDeleteSubmit()}
          content={text.deleteModal.deleteModalBody}
          isCompanyVideo={currentFolder?.folderId === DefaultFolderIds.COMPANY}
        />
      )}
    </>
  );
};
