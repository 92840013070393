import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'lib/context';

const Container = styled.div`
  z-index: 5;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 16px;
  top: 64px;
`;

const Button = styled.button`
  background: ${theme.palette.white};
  color: ${theme.palette.gray100};
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  padding: 8px 16px;
  outline: none;
  display: inline-block;
  cursor: pointer;
  border: none;
  margin-left: 16px;
`;

const Text = styled.span`
  color: ${theme.palette.white};
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
`;

type Props = {
  isVisible: boolean;
};

export const UpgradeToday = ({ isVisible }: Props) => {
  const { userData } = useAuth();
  const getSignUpTime = () => {
    if (userData.intercom && userData.intercom.signup_date) {
      const signupTime = new Date(userData.intercom.signup_date).getTime();
      return signupTime;
    }
    return new Date().getTime();
  };

  const trialPeriod = parseInt(userData.userId) > 130968 ? 7 : 14;
  const trialDays = trialPeriod ? trialPeriod : 14;
  const history = useHistory();
  const purchaseDate = new Date(getSignUpTime());
  purchaseDate.setDate(purchaseDate.getDate() + trialDays);
  let timeLeft = purchaseDate.getTime() - new Date().getTime();
  timeLeft = timeLeft < 0 ? 0 : timeLeft;
  const daysLeft = Math.round(timeLeft / (1000 * 60 * 60 * 24));

  const UPGRADE_PATH = '/profile/upgrade-account';

  const handleClick = () => {
    history.push(UPGRADE_PATH);
  };

  return isVisible && userData.trialAccount ? (
    <Container>
      <Text>
        You have{' '}
        {daysLeft + ' day' + (daysLeft > 1 || daysLeft == 0 ? 's' : '')} left in
        your free trial.
      </Text>
      <Button onClick={handleClick}>Upgrade Today</Button>
    </Container>
  ) : (
    <></>
  );
};
