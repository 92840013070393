import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { IRepairOrderMutationData, RepairOrderItem } from './types';
import { repairOrderKeys } from './repairOrderKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const updateRepairOrder = async ({
  data,
  repairOrderId,
}: IRepairOrderMutationData): Promise<RepairOrderItem> => {
  return (await EXPRESS_API.put(`repair-orders/${repairOrderId}`, data)).data;
};

export const useUpdateRepairOrder = (
  setShowLoading: (arg: boolean) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(updateRepairOrder, {
    onSuccess: res => {
      queryClient.invalidateQueries(
        repairOrderKeys.repairOrder(res.repairOrderId)
      );
      successToast({
        title: `Repair order successfully updated.`,
      });
    },
    onError: () => {
      setShowLoading(false);
      errorToast({
        title:
          'An error occurred preparing your message, please try again later!',
      });
    },
  });
};
