import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { VideoData } from 'lib/hooks';
import { VideoPlayer } from '../../videoPlayer';

const Wrapper = styled.div<{ margin: string }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  margin: ${({ margin }) => margin};
`;

type VideoPlayerWrapperProps = {
  width: number;
};

const VideoPlayerWrapper = styled.div<VideoPlayerWrapperProps>`
  width: ${({ width }) => `${width}px`};
  > video {
    display: block;
    width: 100%;
    border-radius: 4px;
    height: ${({ width }) => `${width * 0.5625}px`};
    object-fit: contain;
    background: ${theme.palette.blue100};
  }
`;

type Props = {
  video: VideoData;
  videoRef: React.RefObject<HTMLVideoElement>;
  useCovideoPlayer?: boolean;
  width?: number;
  margin?: string;
};

export const VideoPreview = ({
  video,
  videoRef,
  width = 696,
  margin = '0',
  useCovideoPlayer = false,
}: Props) => {
  return (
    <Wrapper margin={margin}>
      {!useCovideoPlayer && (
        <VideoPlayerWrapper width={width}>
          <video ref={videoRef} preload="metadata" width={width}>
            <source src={video.videoSource} type="video/mp4" />
          </video>
        </VideoPlayerWrapper>
      )}
      {useCovideoPlayer && (
        <VideoPlayer
          width={`${width}px`}
          playerBackgroundColor={video.playerBackgroundColor || ''}
          videoSource={video.videoSource}
          videoId={video.id}
          playButtonPosition={video.playButtonPosition || ''}
          playerIconsColor={video.playerIconsAndTextColor || ''}
          videoRef={videoRef}
          autoplay={false}
          disableControls={true}
          startWithControls={false}
        />
      )}
    </Wrapper>
  );
};
