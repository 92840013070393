import React, { useEffect, useState } from 'react';
import {
  Container,
  Day,
  DayContainer,
  DropdownContainer,
  FrequencyContainer,
  Label,
  RadioGroup,
  RadioLabel,
} from './style';
import { Dropdown } from '../dropdown/Dropdown';
import {
  FREQUENCY_DETAIL,
  FREQUENCY_TYPE,
  frequencyOptions,
} from './constants';
import { RadioInput } from '..';
import { dayOptions, monthlyDaysOptions } from 'app/pages/reports/util';
import { useTheme } from 'styled-components/macro';

interface FrequencySelectorProps {
  frequency: string | undefined;
  setFrequency: React.Dispatch<React.SetStateAction<string | undefined>>;
  hasLabel?: boolean;
  margin?: string;
}

export const FrequencySelector = ({
  frequency,
  setFrequency,
  hasLabel = true,
  margin,
}: FrequencySelectorProps) => {
  const themes = useTheme();
  const [weekly, setWeekly] = useState([] as string[]);
  const [monthlyDay, setMonthlyDay] = useState(monthlyDaysOptions[0]);
  const [frequencyType, setFrequencyType] = useState(frequencyOptions[0]);
  const [frequencyDetails, setFrequencyDetails] = useState<string>(
    FREQUENCY_DETAIL.EVERYDAY
  );

  useEffect(() => {
    if (frequency) {
      const frequencyArray = frequency.split(';');
      const defaultFrequency = frequencyOptions.find(
        option => option.value === frequencyArray[0]
      );
      if (defaultFrequency) {
        setFrequencyType(defaultFrequency);
        setFrequencyDetails(frequencyArray[1]);

        if (defaultFrequency.value === FREQUENCY_TYPE.WEEKLY) {
          setWeekly(frequencyArray[1].split(','));
        }

        if (defaultFrequency.value === FREQUENCY_TYPE.MONTHLY) {
          const defaultMonthlyDay = monthlyDaysOptions.find(
            option => option.value === frequencyArray[1]
          );
          if (defaultMonthlyDay) {
            setMonthlyDay(defaultMonthlyDay);
          }
        }
      }
    } else {
      setFrequency(`${frequencyType.value};${frequencyDetails}`);
    }
  }, []);

  useEffect(() => {
    setFrequency(`${frequencyType.value};${frequencyDetails}`);
  }, [frequencyType, frequencyDetails]);

  return (
    <Container margin={margin}>
      {hasLabel && <Label>Frequency</Label>}
      <FrequencyContainer>
        <DropdownContainer style={{ marginLeft: 0 }}>
          <Dropdown
            width={184}
            placeholder='Frequency'
            value={frequencyType}
            creatable={false}
            onChange={newValue => {
              setFrequencyType(newValue);
              if (newValue.value === FREQUENCY_TYPE.MONTHLY) {
                setFrequencyDetails(monthlyDaysOptions[0].value);
              } else if (newValue.value === FREQUENCY_TYPE.DAILY) {
                setFrequencyDetails(FREQUENCY_DETAIL.EVERYDAY);
              } else {
                setFrequencyDetails('');
              }
            }}
            options={frequencyOptions}
          />
        </DropdownContainer>
        {frequencyType.value === FREQUENCY_TYPE.DAILY && (
          <RadioGroup role='group' aria-labelledby='type-group'>
            <RadioLabel>
              <RadioInput
                name={FREQUENCY_TYPE.DAILY}
                value={FREQUENCY_DETAIL.EVERYDAY}
                checked={frequencyDetails === FREQUENCY_DETAIL.EVERYDAY}
                onClick={() => setFrequencyDetails(FREQUENCY_DETAIL.EVERYDAY)}
                onChange={() => setFrequencyDetails(FREQUENCY_DETAIL.EVERYDAY)}
              />
              <span>Every Day</span>
            </RadioLabel>
            <RadioLabel>
              <RadioInput
                name={FREQUENCY_TYPE.DAILY}
                value={FREQUENCY_DETAIL.WEEKDAYS}
                checked={frequencyDetails === FREQUENCY_DETAIL.WEEKDAYS}
                onClick={() => setFrequencyDetails(FREQUENCY_DETAIL.WEEKDAYS)}
                onChange={() => setFrequencyDetails(FREQUENCY_DETAIL.WEEKDAYS)}
              />
              <span>Weekdays</span>
            </RadioLabel>
          </RadioGroup>
        )}
        {frequencyType.value === FREQUENCY_TYPE.WEEKLY && (
          <DayContainer>
            {dayOptions.map(day => (
              <Day
                key={day.value}
                className={weekly.includes(day.value) ? 'active' : ''}
                activeBackgroundColor={themes.colors.primary[100]}
                onClick={() => {
                  let newWeekly = [...weekly, day.value];
                  if (weekly.includes(day.value)) {
                    newWeekly = weekly.filter(
                      weeklyItem => weeklyItem !== day.value
                    );
                  }
                  setWeekly(newWeekly);
                  setFrequencyDetails(newWeekly.join(','));
                }}
              >
                {day.label}
              </Day>
            ))}
          </DayContainer>
        )}
        {frequencyType.value === FREQUENCY_TYPE.MONTHLY && (
          <DropdownContainer
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <span>On </span>
            <Dropdown
              width={71}
              placeholder='Select day of month'
              value={monthlyDay}
              creatable={false}
              onChange={newValue => {
                setFrequencyDetails(newValue.value);
                setMonthlyDay(newValue);
              }}
              options={monthlyDaysOptions}
            />
            <span>day of month</span>
          </DropdownContainer>
        )}
      </FrequencyContainer>
    </Container>
  );
};
