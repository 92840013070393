import { useMutation } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';

export const deleteScript = async ({
  scriptId,
}: {
  scriptId: string;
}): Promise<void> => {
  const response = await EXPRESS_API.delete(`/teleprompter/${scriptId}`);
  return response.data;
};

export const useRemoveScript = () => {
  return useMutation(deleteScript, {
    onSuccess: () => {
      successToast({
        title: 'You successfully moved script to recently deleted bin!',
      });
    },
    onError: () => {
      errorToast({ title: 'An error occurred while removing script.' });
    },
  });
};
