import React from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, BadgeType } from 'lib/components/Badge';
import {
  Content,
  IntegrationCard,
  Tags,
  Title,
  Image,
  ImageContainer,
} from './style';
import { IntegrationProps } from '../main/constants';
import { useIntegrationData } from '../main/data';

interface IntegrationData {
  integration: IntegrationProps;
}

export const Integration = ({ integration }: IntegrationData) => {
  const history = useHistory();
  const data = useIntegrationData();
  const { DISCOVER_BY_FILTERS } = data;

  const { categories, path, imgSrc, name } = integration;
  const getBadge = (category: string, idx: number) => {
    switch (category) {
      case DISCOVER_BY_FILTERS.FREE_TRIAL:
        return (
          <Badge
            key={idx}
            width='auto'
            radius={6}
            type={BadgeType.GREEN}
            text={'Free Trial'}
          />
        );
      case DISCOVER_BY_FILTERS.FEATURED:
        return (
          <Badge
            key={idx}
            width='auto'
            radius={6}
            type={BadgeType.ORANGE}
            text={'Featured'}
          />
        );
      case DISCOVER_BY_FILTERS.NEWEST:
        return (
          <Badge
            key={idx}
            width='auto'
            radius={6}
            type={BadgeType.GREEN}
            text={'New'}
          />
        );
      default:
        return (
          <Badge
            key={idx}
            width='auto'
            radius={6}
            type={BadgeType.NEUTRAL}
            text={category}
          />
        );
    }
  };
  return (
    <IntegrationCard
      onClick={() => {
        history.push(path);
      }}
    >
      <ImageContainer>
        <Image src={imgSrc} />
      </ImageContainer>
      <Content>
        <Title>{name}</Title>
      </Content>
      <Tags>{categories.map((category, idx) => getBadge(category, idx))}</Tags>
    </IntegrationCard>
  );
};
