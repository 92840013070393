import { VideoRequestsParams } from './useVideoRequestsQuery';

export const videoRequestKeys = {
  all: () => ['VIDEO_REQUESTS'],
  count_pending: () => [videoRequestKeys.all(), 'COUNT_PENDING'],
  list: (params: VideoRequestsParams) => [videoRequestKeys.all(), params],
  single: (videoRequestId: string) => [
    videoRequestKeys.all(),
    { videoRequestId },
  ],
  single_by_video_id: (videoId: string | number) => [
    ...videoRequestKeys.all(),
    videoId,
  ],
};
