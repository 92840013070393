import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { usersKeys } from './queryKeys';
import { Params } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IUsersCustomersResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phone1: string;
  Dept: null | string;
  DeptId: null | string;
  access?: string;
  removed?: boolean;
}

const getUsersCustomers = async (
  params: Params
): Promise<IUsersCustomersResponse[]> => {
  const { customerId, sort } = params;

  return (
    await EXPRESS_API.get(`users`, {
      params: { customerId, sort },
    })
  ).data as IUsersCustomersResponse[];
};

export const useGetUsersCustomerQuery = (params: Params, enabled?: boolean) => {
  const { showError } = useToastError();

  return useQuery(usersKeys.customer_user(), () => getUsersCustomers(params), {
    onError: showError,
    enabled: enabled,
  });
};
