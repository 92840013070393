import * as React from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';
import { useOnClickOutside } from 'lib/utils';

export type Option = {
  value: string | number;
  label: string | number;
};

type Props = {
  options: Array<Option>;
  onSelect: (value: string, index?: number) => void;
  onClickOutside: () => void;
  positionTop?: number;
};
type WrapperProps = {
  positionTop?: number;
};
const OptionWrapper = styled.div<WrapperProps>`
  position: absolute;
  display: grid;
  background-color: white;
  top: ${props =>
    props.positionTop !== undefined ? `${props.positionTop}px` : '48px'};
  width: 100%;
  max-height: 240px;
  left: 0px;
  overflow: auto;
  border-radius: 4px;
  box-shadow:
    0 2px 4px 0 rgba(52, 58, 64, 0.1),
    0 4px 24px 0 rgba(52, 58, 64, 0.15);
  z-index: 2;
`;

const OptionItem = styled.div`
  ${theme.fontNormal500};
  color: ${theme.palette.primaryDarkBlue};
  font-size: ${theme.fontSizes.m};
  padding: 12px 16px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primary[100]};
  }
`;

export const DropdownOptions = (props: Props) => {
  const { options, onSelect, onClickOutside, positionTop } = props;

  const { ref } = useOnClickOutside(onClickOutside);

  return (
    <OptionWrapper ref={ref} positionTop={positionTop}>
      {options.map(({ value, label }, index) => (
        <OptionItem
          key={value}
          onClick={() => onSelect(value.toString(), index)}
        >
          {label}
        </OptionItem>
      ))}
    </OptionWrapper>
  );
};
