import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';
import { useAuth } from 'lib/context';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';

export const Main = () => {
  const { userData } = useAuth();

  return (
    <Switch>
      {userData.superAdminRole !== SuperAdminRole.ADMIN && (
        <Route path='/admin/customers' component={List} exact={true} />
      )}
      <Route
        path='/admin/customers/:id/:fromScreen/:resId'
        component={Details}
      />
    </Switch>
  );
};
