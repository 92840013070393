import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { conversationsKeys } from './queryKeys';

import { useToastError } from 'lib/hooks/useToastError';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { IWhiteLabel, WHITELABEL_NAME } from '../whitelabel/getWhiteLabel';
import { ICombinedUserData } from 'lib/context';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IConversationCountResponse {
  unread: number;
  unassigned: number;
}

const getConversationCount = async (): Promise<IConversationCountResponse> => {
  return (await EXPRESS_API.get(`conversations/count/all`))
    .data as IConversationCountResponse;
};

export const useGetConversationCount = (
  userData: ICombinedUserData | undefined,
  whitelabel: IWhiteLabel | undefined
) => {
  const { showError } = useToastError();
  const triggerUseConversationCount =
    !!userData &&
    whitelabel?.name.includes(WHITELABEL_NAME.COVIDEO) &&
    checkIfFeatureIsEnabled(userData, productFeature.BUILT_IN_SMS);

  return useQuery(conversationsKeys.count(), () => getConversationCount(), {
    onError: (err: AxiosError) => showError(err),
    enabled: triggerUseConversationCount,
  });
};
