import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { superAdminKeys } from './superAdminKeys';

export type UpdateMultipleUserTypeParams = {
  userIds: number[];
  access: number;
};

export const updateBulkUsersAccessRoleBySuperAdmin = async ({
  userIds,
  access,
}: UpdateMultipleUserTypeParams) => {
  const data = {
    userIds,
    access,
  };
  const response = await EXPRESS_API.patch(`/superadmin/users/access`, data);
  return response.data;
};

export const useBulkEditUserAccessRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBulkUsersAccessRoleBySuperAdmin, {
    onSuccess: (_, variables: UpdateMultipleUserTypeParams) => {
      queryClient.invalidateQueries([
        superAdminKeys.all_users_for_superadmin(),
      ]);
      successToast({
        title: `User type updated successfully for ${
          variables.userIds.length
        } user${variables.userIds.length > 1 ? 's' : ''}!`,
      });
    },
    onError: () => {
      queryClient.invalidateQueries([
        superAdminKeys.all_users_for_superadmin(),
      ]);
      errorToast({
        title: 'Error in updating users type',
      });
    },
  });
};
