import { EXPRESS_API } from 'configs/api/expressApi';
import { QueryListParams } from '.';

export const getCrmCustomers = async ({
  page = 0,
  size = 10,
  search,
  sort,
  accountManagerId,
  integrationId,
  resellerId,
}: QueryListParams) => {
  const start = page * size;

  const response = await EXPRESS_API.get('/crm/customers', {
    params: {
      start,
      limit: size,
      search,
      sort,
      integrationId,
      ...(accountManagerId ? { accountManagerId } : {}),
      ...(resellerId ? { resellerId } : {}),
    },
  });
  return response.data;
};

export const getCrmAccountManagers = async () => {
  const response = await EXPRESS_API.get('/crm/account-managers');
  return response.data;
};

export const getResellers = async () => {
  const response = await EXPRESS_API.get('/crm/resellers');
  return response.data;
};

export const getVinsolutionsDealers = async () => {
  const response = await EXPRESS_API.get('/crm/vinsolutions/dealers');
  return response.data;
};

export const connectVinSolution = async (data: any, customerId: number) => {
  const response = await EXPRESS_API.put(
    `/crm/vinsolutions/${customerId}/dealers`,
    data
  );
  return response.data;
};

export const connectVinsolutionUsers = async (
  data: any,
  customerId: number
) => {
  const response = await EXPRESS_API.put(
    `/crm/vinsolutions/${customerId}/users`,
    data
  );
  return response.data;
};

export const connectELead = async (data: any, customerId: number) => {
  const response = await EXPRESS_API.put(`/crm/elead/${customerId}`, data);
  return response.data;
};

export const connectDealersocket = async (
  customerId: number,
  enabled: boolean,
  dealerSocketId?: string,
  dealerSocketFranchiseId?: string
) => {
  const response = await EXPRESS_API.put(`/crm/dealersocket/${customerId}`, {
    enabled,
    dealerSocketId,
    dealerSocketFranchiseId,
  });
  return response.data;
};
