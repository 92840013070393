import React from 'react';
import styled from 'styled-components/macro';
import { getCustomReportHistory } from 'lib/api';
import { LoadingIndicator } from 'lib/components';
import { HistoryTable } from '../../components';
import { CustomReportItem } from '../../../../../lib/context';
import { PaginationConstants } from 'lib/const/PaginationConstants';

const Container = styled.div`
  margin-top: 24px;
`;

const fields = ['dateSent', 'email', 'action'];

type Props = {
  customReport: CustomReportItem;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
};

export const HistoryTab = (props: Props) => {
  const [reports, setReports] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [historySortKey, setHistorySortKey] = React.useState('');
  const [historySortOrder, setHistorySortOrder] = React.useState(
    PaginationConstants.ASCENDING
  );
  const sortData = {
    sortKey: historySortKey,
    order: historySortOrder || PaginationConstants.ASCENDING,
  };
  const { customReport, setActiveTab } = props;

  const fetchHistory = async (page: number, size: number) => {
    setLoading(true);
    const { count, reportHistories } = await getCustomReportHistory({
      reportId: customReport.reportId,
      page,
      size,
      sortData,
    });
    setLoading(false);
    setCount(count);
    setReports(reportHistories);
  };

  React.useEffect(() => {
    fetchHistory(0, 10);
  }, [customReport, historySortKey, historySortOrder]);

  const onPaginationChange = async (page: number, size: number) => {
    await fetchHistory(page, size);
  };

  return (
    <Container>
      {!loading && (
        <HistoryTable
          data={reports}
          fields={fields}
          count={count}
          onPaginationChange={onPaginationChange}
          onSortingUpdate={(historySortKey, order) => {
            setHistorySortKey(historySortKey);
            setHistorySortOrder(order);
          }}
          sortKey={sortData.sortKey}
          order={sortData.order}
          setActiveTab={setActiveTab}
        />
      )}
      {loading && <LoadingIndicator isLoading={loading} height='300px' />}
    </Container>
  );
};
