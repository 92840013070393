import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import laptopPlayButtonIcon from 'assets/images/freemiumOnboarding/laptopPlayButton.webp';
import chromeExtensionIcon from 'assets/images/freemiumOnboarding/chromeExtension.webp';
import mobileLogo from 'assets/images/freemiumOnboarding/mobileLogo.svg';
import { useAuth } from 'lib/context';
import { FreemiumOnboardingPageTemplate } from '../components';
import { useHistory } from 'react-router';
import { useFreemiumOnboardingMutation } from 'lib/api/freemiumOnboarding/createOrUpdateFreemiumOnboarding';
import {
  ANDROID_GOOGLE_PLAY_STORE_URL,
  browserExtensionURL,
  IOS_APP_STORE_URL,
  MOBILE_COVIDEO_URL,
} from '../const';
import { WellDonePageOption } from '../types';
import { Badge, BadgeType } from 'lib/components/Badge';
import { Button } from 'react-covideo-common';

const DesktopWrapper = styled.div`
  display: block;
  padding-top: 30px;
  ${theme.mediaQueryMaxWidth.sm} {
    display: none;
  }
`;
const TabletAndMobileWrapper = styled.div`
  display: none;
  ${theme.mediaQueryMaxWidth.sm} {
    display: block;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  ${theme.mediaQueryMaxWidth.sm} {
    max-width: 422px;
  }
  ${theme.mediaQueryMaxWidth.mb} {
    max-width: 100%;
  }
`;
const MainHeading = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: ${theme.palette.covideoBlue100};
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
`;
const Heading = styled.h2`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: ${theme.palette.covideoBlue100};
  margin-bottom: 40px;
  span {
    color: ${props => props.theme.colors.primary[100]};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
  }
`;
const WelcomeText = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  word-wrap: break-word;
  color: ${theme.palette.covideoBlue100};
  margin-bottom: 40px;
  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
  }
`;
const AdditionalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 32px;
    gap: 0;
  }
`;
const AdditionalText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.covideoBlue100};
  flex: none;
  flex-grow: 0;
`;
const OptionsWrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;
type OptionsContainerProps = {
  borderColor?: string;
};
const OptionContainer = styled.div<OptionsContainerProps>`
  box-sizing: border-box;
  flex: 1;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px 0px;
  gap: 8px;
  background: ${theme.palette.white};
  border: 1px solid
    ${props => props.borderColor || theme.palette.covideoOrange100};
  border-radius: 6px;
  ${theme.mediaQueryMaxWidth.sm} {
    max-width: 308px;
    margin: auto;
  }
  ${theme.mediaQueryMaxWidth.mb} {
    max-width: 100%;
  }
`;
const OptionText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  flex: none;
  flex-grow: 0;
`;
const Image = styled.img`
  height: 48px;
  width: 48px;
`;
const SkipWrapper = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;
const SkipText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.covideoBlue100};
  cursor: pointer;
`;
const ColumnWrapper = styled.div`
  margin-bottom: 200px;
  ${theme.mediaQueryMaxWidth.sm} {
    margin-bottom: 0;
  }
`;

export const WellDone = () => {
  const { userData } = useAuth();
  const history = useHistory();
  const { mutateAsync, isLoading } = useFreemiumOnboardingMutation();

  const userAgent = navigator.userAgent;
  const isAndroid = /android/i.test(userAgent);
  const isIos = /iPad|iPhone|iPod/.test(userAgent);
  const isMobile = isAndroid || isIos;
  const isAutomotive = userData.isAutomotive;

  const handleOpenAppStore = async () => {
    if (isAutomotive) {
      return;
    }
    await mutateAsync({
      clickOpenAppStore: true,
    });
  };

  const handleOpenGooglePlayStore = () => {
    if (isAutomotive) {
      return;
    }
    mutateAsync({
      clickOpenGooglePlayStore: true,
    });
  };

  const handleInstallChromeExtension = async () => {
    await mutateAsync({
      clickInstallExtension: true,
    });
  };

  const handleOpenRecorder = async () => {
    await mutateAsync({
      clickOpenRecorder: true,
    });
    return history.push('/onboarding-freemium/record');
  };

  const handleSkip = async () => {
    await mutateAsync({
      clickSkip: true,
    });
    return history.push('/email-verification');
  };

  const wellDonePageOptionsForTabletAndMobile: WellDonePageOption[] = [
    {
      iconSrc: mobileLogo,
      badgeText: 'Fast & Easy',
      badgeVariant: BadgeType.GREEN,
      OptionText: 'Covideo Mobile App',
      buttonText: isIos ? 'Open App Store' : 'Open Google Play Store',
      buttonVariant: 'primary',
      borderColor: isAutomotive
        ? theme.palette.tertiaryBlue
        : theme.palette.primaryColor,
      onClick: isIos ? handleOpenAppStore : handleOpenGooglePlayStore,
      href: isIos ? IOS_APP_STORE_URL : ANDROID_GOOGLE_PLAY_STORE_URL,
    },
    {
      iconSrc: '',
      badgeText: 'Limited',
      badgeVariant: BadgeType.DESTRUCTIVE,
      OptionText: '',
      buttonText: 'Use Covideo in Browser',
      onClick: isIos ? handleOpenAppStore : handleOpenGooglePlayStore,
      buttonVariant: 'secondary',
      borderColor: 'transparent',
      href: MOBILE_COVIDEO_URL,
    },
  ];
  const renderOptionsForTabletAndMobile = () => {
    return wellDonePageOptionsForTabletAndMobile.map((item, index) => (
      <OptionContainer
        key={`well-done-page-option-${index}`}
        borderColor={item.borderColor}
      >
        {item.iconSrc && <Image src={item.iconSrc} />}
        <Badge text={item.badgeText} type={item.badgeVariant} width='auto' />
        <OptionText>{item.OptionText}</OptionText>
        <a
          href={item.href}
          target='_blank'
          rel='noreferrer'
          onClick={isLoading ? undefined : item.onClick}
        >
          <Button
            text={item.buttonText}
            variant={item.buttonVariant}
            disabled={isLoading}
            style={{ marginBottom: '16px' }}
          />
        </a>
      </OptionContainer>
    ));
  };

  const wellDonePageOptionsForDesktop: WellDonePageOption[] = [
    {
      iconSrc: laptopPlayButtonIcon,
      badgeText: 'Quick & Easy',
      badgeVariant: BadgeType.GREEN,
      OptionText: 'Web recorder',
      buttonText: 'Open Recorder',
      onClick: handleOpenRecorder,
      borderColor: theme.palette.covideoOrange100,
      buttonVariant: 'primary',
    },
    {
      iconSrc: chromeExtensionIcon,
      badgeText: 'Easily Accessible',
      badgeVariant: BadgeType.GREEN,
      OptionText: 'Chrome Extension',
      buttonText: 'Install',
      onClick: handleInstallChromeExtension,
      borderColor: theme.palette.gray20,
      buttonVariant: 'secondary',
      href: browserExtensionURL,
    },
  ];
  const renderOptionsForDesktop = () => {
    return wellDonePageOptionsForDesktop.map((item, index) => (
      <OptionContainer
        key={`well-done-page-option-${index}`}
        borderColor={item.borderColor}
      >
        <Image src={item.iconSrc} />
        <Badge text={item.badgeText} type={item.badgeVariant} width='auto' />
        <OptionText>{item.OptionText}</OptionText>
        <a
          href={item.href}
          target='_blank'
          rel='noreferrer'
          onClick={isLoading ? undefined : item.onClick}
        >
          <Button
            text={item.buttonText}
            variant={item.buttonVariant}
            disabled={isLoading}
            style={{ marginBottom: '16px' }}
          />
        </a>
      </OptionContainer>
    ));
  };

  return (
    <FreemiumOnboardingPageTemplate
      currentStep={0}
      rightColumnChildren={
        <DesktopWrapper>
          <ColumnWrapper>
            {!isMobile && (
              <>
                <OptionsWrapper>{renderOptionsForDesktop()}</OptionsWrapper>
                <SkipWrapper>
                  <SkipText onClick={!isLoading ? handleSkip : undefined}>
                    Skip for now
                  </SkipText>
                </SkipWrapper>
              </>
            )}
            {isMobile && (
              <OptionsWrapper>
                {renderOptionsForTabletAndMobile()}
              </OptionsWrapper>
            )}
          </ColumnWrapper>
        </DesktopWrapper>
      }
      leftColumnChildren={
        <ColumnWrapper>
          <TextWrapper>
            {userData.onboardedFreemium === '1' && (
              <MainHeading>Well done, {userData?.firstName}!</MainHeading>
            )}
            {!isMobile && (
              <>
                <Heading>
                  Let's take a moment to <span>record your first video.</span>
                </Heading>
                <AdditionalTextContainer>
                  <AdditionalText>
                    It's easy to get started. We'll walk you through how to use
                    our recording tool with your webcam or camera.
                  </AdditionalText>
                  <AdditionalText>
                    Don't worry about getting it perfect on the first try!
                    Covideo stores your videos securely in your library until
                    you're ready to share with your contacts.
                  </AdditionalText>
                </AdditionalTextContainer>
              </>
            )}
            {isMobile && (
              <>
                <WelcomeText>
                  Welcome to Covideo, {userData?.firstName}!
                </WelcomeText>
                <Heading>
                  Grab the Covideo app for the{' '}
                  <span>best mobile experience!</span>
                </Heading>
                <AdditionalTextContainer>
                  <AdditionalText>
                    Currently, our web-based recorder is only compatible with
                    the desktop version of Chrome and Mozilla Firefox.
                  </AdditionalText>
                </AdditionalTextContainer>
              </>
            )}
          </TextWrapper>
          <TabletAndMobileWrapper>
            {renderOptionsForTabletAndMobile()}
          </TabletAndMobileWrapper>
        </ColumnWrapper>
      }
    />
  );
};
