import { ParagraphSmallBold } from 'lib/components/styles/typography';
import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { AvailableFieldsList } from './AvailableFieldsList';
import { AvailableGroupFieldsList } from './AvailableGroupFieldsList';
import { ItemsWrapper } from './styles';
import { AvailableFieldsProps } from './types';

const AllFieldsWrap = styled.div<{ scrollableHeight: string }>`
  padding: 20px 24px;
  background: ${({ theme }) => theme.colors.neutral[10]};
  display: flex;
  gap: 8px;
  flex-direction: column;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.neutral[10]};
  max-height: ${({ scrollableHeight }) => scrollableHeight || 'auto'};
  min-height: ${({ scrollableHeight }) => scrollableHeight || 'auto'};
`;

export const AvailableFields = ({
  onAddClickHandler,
  isMaxFieldReached,
  scrollableHeight,
  allFields,
  selectedFields,
}: AvailableFieldsProps) => {
  const { colors } = useTheme();
  return (
    <ItemsWrapper>
      <ParagraphSmallBold color={colors.neutral[80]}>
        Select Data to show
      </ParagraphSmallBold>
      <AllFieldsWrap scrollableHeight={scrollableHeight}>
        {!Array.isArray(allFields) ? (
          <AvailableGroupFieldsList
            onAddClickHandler={onAddClickHandler}
            selectedFields={selectedFields}
            allFields={allFields}
            isMaxFieldReached={isMaxFieldReached}
          />
        ) : (
          <AvailableFieldsList
            onAddClickHandler={onAddClickHandler}
            selectedFields={selectedFields}
            allFields={allFields}
            isMaxFieldReached={isMaxFieldReached}
          />
        )}
      </AllFieldsWrap>
    </ItemsWrapper>
  );
};
