import React from 'react';
import { useParams } from 'react-router';
import { ThreeDotsDropdown } from 'app/pages/sms/components/threeDots';
import { useThreeDotsActions } from '../hooks/useThreeDotsActions';
import {
  ChatHeader,
  ConversationDetails,
  MsgTitle,
} from '../styles/UsersConversationsLayout';
import { Button } from 'react-covideo-common';

import { getConversationHeader } from '../utils/helper';
import { useDeleteConversationMutation } from 'lib/api/conversations/setConversationAsDeleted';
import { useReopenedConversationMutation } from 'lib/api/conversations/setConversationAsOpened';
import { ModalsType } from '../conversations.types';
import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';
import { useRestoredConversationMutation } from 'lib/api/conversations/setConversationAsRestored';
import { useIsMutating } from 'react-query';
import { conversationsKeys } from 'lib/api/conversations/queryKeys';

interface IConversationsHeaderProps {
  singleConversation: ISingleConversation;
  setModals: React.Dispatch<React.SetStateAction<false | ModalsType>>;
}

export const ConversationsHeader = ({
  singleConversation,
  setModals,
}: IConversationsHeaderProps) => {
  const { id } = useParams() as { id: string };

  const isMutationActive = useIsMutating(conversationsKeys.header_action());

  const { mutateAsync: deleteConversationAsync } =
    useDeleteConversationMutation();

  const { mutateAsync: markAsReopenAsync, isLoading: isLoadingReopening } =
    useReopenedConversationMutation();
  const { isLoading: isLoadingRestore, mutateAsync: markAsRestoredAsync } =
    useRestoredConversationMutation();

  const threeDotsDropdownItems = useThreeDotsActions({
    deleteAction: deleteConversationAsync,
    setModals: setModals,
    singleConversation,
  });

  const performButtonClick = () => {
    if (singleConversation?.deleted) {
      return markAsRestoredAsync(id);
    }
    if (singleConversation?.closed) {
      return markAsReopenAsync(id);
    }
    return setModals('send_transcript_closed');
  };

  const getButtonText = () => {
    if (singleConversation?.deleted) {
      return isLoadingRestore ? 'Restoring...' : 'Restore';
    }
    if (singleConversation?.closed) {
      return isLoadingReopening ? 'Reopening...' : 'Reopen';
    }
    return 'Mark as closed';
  };

  return (
    <ChatHeader>
      <ConversationDetails>
        <MsgTitle>
          Conversation with {getConversationHeader(singleConversation.contact)}
        </MsgTitle>
      </ConversationDetails>

      <div style={{ display: 'flex', gap: 8 }}>
        <Button
          disabled={!!isMutationActive}
          onClick={() => performButtonClick()}
          text={getButtonText()}
          variant='primary'
        ></Button>
        <ThreeDotsDropdown items={threeDotsDropdownItems} />
      </div>
    </ChatHeader>
  );
};
