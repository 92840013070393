import React, { useState } from 'react';
import styled from 'styled-components/macro';
import DefaultManagerImage from 'assets/images/accountManagers/supportBadge.webp';
import { useAuth } from 'lib/context';
import AliVelez from 'assets/images/accountManagers/aliVelez.webp';
import EmilyG1 from 'assets/images/accountManagers/emilyG1.webp';
import EmilyS1 from 'assets/images/accountManagers/emilyS1.webp';
import KeirstinMinton from 'assets/images/accountManagers/keirstinMinton.webp';
import Mariah from 'assets/images/accountManagers/mariah.webp';
import McKenzie from 'assets/images/accountManagers/mcKenzie.webp';
import MonicaMuniz from 'assets/images/accountManagers/monicaMuniz.webp';
import Caitlin1 from 'assets/images/accountManagers/caitlin.webp';
import ChrisBill from 'assets/images/accountManagers/chriBill.webp';
import AshleyManner from 'assets/images/accountManagers/ashleyManner.webp';

import { FaMinus } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useGetAccountManagersQuery } from 'lib/api/crmIntegrations/useGetAccountManagersQuery';
import { CrmAccountManager } from 'lib/api/crmIntegrations/types';

const ShowWrapper = styled.div`
  transition: 0.3s;
`;

const SupportContainer = styled.div`
  width: 233px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgb(29 30 36 / 6%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 65px;
  left: 15px;
  z-index: 999;
  &:not(:hover) {
    ${ShowWrapper} {
      height: 0px;
      overflow: hidden;
    }
  }
`;
const ImageContainer = styled.div`
  justify-content: center;
  margin: auto;
  padding-top: 28px;
  padding-bottom: 12px;
`;

const TitleContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 27, 83, 0.6);
  text-align: center;
  padding-bottom: 4px;
`;

const NameContainer = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #001b53;
  text-align: center;
  padding-bottom: 24px;
`;

const ContactContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #001b53;
  cursor: pointer;
`;

const EmailContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #001b53;
  padding-bottom: 16px;
  cursor: pointer;
`;
const ManagerImages = styled.div.attrs(
  (props: { image: string; border: boolean }) => props
)`
  background-image: url(${props => props.image});
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-repeat: no-repeat, repeat;
  background-position: center top;
  background-size: cover;
  border: ${props => (props.border ? `5px solid #ffff` : 'none')};
  cursor: pointer;
`;
const MinusIcon = styled.div`
  margin-top: 10px;
  position: absolute;
  right: 12px;
  cursor: pointer;
`;

const MinimiseContainer = styled.div`
  position: fixed;
  bottom: 65px;
  left: 70px;
  z-index: 999;
`;

const managerImages: Record<string, string> = {
  2: Caitlin1,
  3: KeirstinMinton,
  4: EmilyS1,
  8: EmilyG1,
  12: MonicaMuniz,
  13: McKenzie,
  14: AliVelez,
  15: Mariah,
  17: ChrisBill,
  20: AshleyManner,
};

export const SupportBadge = () => {
  const [isMinimise, setIsMinimise] = useState(
    localStorage?.getItem('supportBadgeState') === 'true'
  );
  const { userData } = useAuth(); // To ger user information from the context store.
  const { isAutomotive } = userData;
  const { accountManager } = userData.customer;
  const location = useLocation();

  const { data: crmAccountManagers, isLoading } = useGetAccountManagersQuery({
    enabled: isAutomotive,
  });

  if (isLoading) {
    return <></>;
  }

  // CDS-78 hide support badge for send&share pages
  const hideSupportBadge = location.pathname.includes('/home/send-and-share/');

  const filterAccountManager = crmAccountManagers?.accountManagers?.find(
    (item: CrmAccountManager) => item?.agentId == accountManager
  );

  const isManagerPresent = !!filterAccountManager;
  const agentId = filterAccountManager?.agentId || '';
  const managerData = filterAccountManager?.user;

  const changeSupportBadgeState = (type: boolean) => {
    localStorage.setItem('supportBadgeState', `${type}`);
    setIsMinimise(type);
  };

  const shouldHideBadge =
    hideSupportBadge || !isManagerPresent || !isAutomotive;
  return (
    <>
      {shouldHideBadge ? null : isMinimise ? (
        <MinimiseContainer>
          <ImageContainer onClick={() => changeSupportBadgeState(false)}>
            <ManagerImages
              image={managerImages?.[agentId] || DefaultManagerImage}
              border={true}
            ></ManagerImages>
          </ImageContainer>
        </MinimiseContainer>
      ) : (
        <SupportContainer>
          <MinusIcon onClick={() => changeSupportBadgeState(true)}>
            <FaMinus size={15} />
          </MinusIcon>
          <ImageContainer>
            <ManagerImages
              image={managerImages?.[agentId] || DefaultManagerImage}
              border={false}
            ></ManagerImages>
          </ImageContainer>
          <TitleContainer>Your personal support manager</TitleContainer>
          <NameContainer>
            {managerData?.firstName} {managerData?.lastName}
          </NameContainer>
          <ShowWrapper>
            <ContactContainer
              onClick={() =>
                (window.location.href = `tel:${managerData?.phone1}`)
              }
            >
              {managerData?.phone1}{' '}
            </ContactContainer>
            <EmailContainer
              onClick={() =>
                (window.location.href = `mailto:${managerData?.email}`)
              }
            >
              {managerData?.email}
            </EmailContainer>
          </ShowWrapper>
        </SupportContainer>
      )}
    </>
  );
};
