import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';
import { useAuth } from 'lib/context';
import { EXPRESS_API } from 'configs/api/expressApi';

const updateOnboardedFreemiumFlag = async (newFlagValue: number) => {
  const response = await EXPRESS_API.patch(
    `/freemium-onboarding/onboarded`,
    undefined,
    {
      params: {
        value: newFlagValue,
      },
    }
  );
  return response.data;
};

export const useOnboardedFreemiumFlagMutation = () => {
  const { invalidateUser } = useAuth();
  const { showError } = useToastError();

  return useMutation(updateOnboardedFreemiumFlag, {
    onSuccess: async () => {
      await invalidateUser();
    },
    onError: (err: AxiosError) => {
      showError(err);
    },
  });
};
