import { useAwsVoiceoverAudioSignedUrlMutation } from 'lib/api/aws/useAwsVoiceoverAuditoSignedUrlMutation';
import { useUploadToAwsSignedUrlMutation } from 'lib/api/aws/useUploadToAwsSignedUrlMutation';
import { generateTempFileName } from '../../videoVoiceover/components/util';

export const useVoiceOverSignUrlWithAudioUpload = () => {
  const {
    mutateAsync: voiceoverAudioSignedUrlMutation,
    isLoading: isGettingVoiceiver,
  } = useAwsVoiceoverAudioSignedUrlMutation();

  const { mutateAsync: voiceoverMutation, isLoading: isUploadingVoiceover } =
    useUploadToAwsSignedUrlMutation();

  const startUpoladingVoiceover = async (blob: Blob) => {
    const url = URL.createObjectURL(blob);

    const tempFileName = generateTempFileName('merge_recording', 'webm');
    const file = new File([blob], tempFileName);

    const voiceoverAudioSignedUrl = await voiceoverAudioSignedUrlMutation({
      fileName: file?.name || '',
      mimeType: 'video/webm',
      folder: '',
    });

    await voiceoverMutation({
      file: file,
      uploadURL: voiceoverAudioSignedUrl.uploadUrl,
      mimeType: 'video/webm',
    });

    return { downloadUrl: voiceoverAudioSignedUrl.downloadUrl, url };
  };

  return {
    isLoading: isGettingVoiceiver || isUploadingVoiceover,
    startUpoladingVoiceover,
  };
};
