import React from 'react';
import { theme } from 'lib/style';
import Select, { components } from 'react-select';
import { Search } from 'lib/components';
import styled from 'styled-components/macro';
import { secondarySelectStyle } from 'lib/style/commonStyles';
import { createVDPIntegrationOptions } from 'lib/utils/vdp';
import { useAuth } from 'lib/context';
import { AuthorInfo, VdpParams, VehicleInfo } from 'lib/api/vdp/types';
import { useGetUsersWithVdp } from 'lib/api/vdp/useGetUsersWithVdp';
import { useGetUniqueVins } from 'lib/api/vdp/useGetUniqueVins';
import { getDisplayName } from 'lib/utils/functions';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Filter = styled.div<{ minWidth: string }>`
  margin-right: 12px;
  min-width: ${({ minWidth }) => minWidth};
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;

const OptionLabel = styled.div`
  margin-left: 0px;
  width: 700px;
  max-width: 100%;
`;

const CustomOptionLabel = styled.div`
  display: flex;
  align-items: center;
`;

const Vin = styled.div`
  margin-right: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blue100};
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blue40};
  width: 230px;
  text-align: right;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SingleValueWrapper = styled.div`
  padding-left: 6px;
`;

const vinSelectStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    height: '40px',
    boxShadow: 'none',
    borderColor: state.isFocused
      ? theme.palette.covideoBlue100
      : theme.palette.gray40,
    ':hover': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':focus': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':active': {
      borderColor: theme.palette.covideoBlue100,
    },

    cursor: 'pointer',
  }),
  option: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
    color: theme.palette.gray100,
    padding: '8px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
    ':focus': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: (base: any) => ({
    ...base,
    minWidth: 448,
  }),
};

const SingleValue = ({ ...props }: any) => {
  return <SingleValueWrapper>{props.data.valueLabel}</SingleValueWrapper>;
};

const Option = (props: any) => {
  return (
    <OptionWrapper>
      <OptionLabel>
        <components.Option {...props} />
      </OptionLabel>
    </OptionWrapper>
  );
};

type OptionLabelProps = {
  value: string;
  label: string;
  title?: string;
  valueLabel?: string;
};

const formatOptionLabel = ({
  valueLabel = '',
  title = '',
}: OptionLabelProps) => (
  <CustomOptionLabel>
    <Vin>{valueLabel}</Vin>
    <Title>{title}</Title>
  </CustomOptionLabel>
);

type Props = {
  params: VdpParams;
  setParams: React.Dispatch<React.SetStateAction<VdpParams>>;
};

export const VdpFilters = ({ params, setParams }: Props) => {
  const { userData } = useAuth();
  const { data: vdpAuthors } = useGetUsersWithVdp();
  const { data: vdpVins } = useGetUniqueVins();

  const authorOptions: OptionLabelProps[] = [
    {
      value: '0',
      label: 'All Users',
    },
    ...(vdpAuthors || []).map((author: AuthorInfo) => ({
      label: getDisplayName([author.firstName, author.lastName]),
      value: author.userId.toString(),
    })),
  ];

  const vinOptions = [
    { value: '', label: 'All VINs', valueLabel: 'All VINs' },
    ...(vdpVins || []).map((vehicle: VehicleInfo) => {
      const vehicleTitle = getDisplayName([
        vehicle.make,
        vehicle.model,
        vehicle.year,
      ]);
      return {
        value: vehicle.vin,
        label: `${vehicle.vin} ${vehicleTitle}`,
        title: vehicleTitle,
        valueLabel: vehicle.vin,
      };
    }),
  ];

  const integrationTypeOptions = createVDPIntegrationOptions(userData);

  return (
    <FiltersContainer>
      <Filter minWidth='342px'>
        <Search
          placeholder='Search videos by name or VIN…'
          onSearch={(term: string) =>
            setParams(prev => ({ ...prev, search: term, start: 0 }))
          }
          width={'342px'}
        />
      </Filter>
      <Filter minWidth='230px'>
        <Select
          options={authorOptions}
          value={authorOptions.find(
            option => option?.value === params.userId?.toString()
          )}
          onChange={option => {
            setParams(prev => ({
              ...prev,
              userId: Number(option?.value) || 0,
              start: 0,
            }));
          }}
          styles={secondarySelectStyle}
        />
      </Filter>
      <Filter minWidth='260px'>
        <Select
          options={vinOptions}
          value={vinOptions.find(option => option?.value === params.vin)}
          onChange={option =>
            setParams(prev => ({ ...prev, vin: option?.value || '', start: 0 }))
          }
          styles={vinSelectStyle}
          components={{ Option, SingleValue }}
          formatOptionLabel={formatOptionLabel}
        />
      </Filter>

      <Filter minWidth='140px'>
        <Select
          options={integrationTypeOptions}
          styles={secondarySelectStyle}
          value={integrationTypeOptions.find(
            option => option?.value === params.integrationType
          )}
          onChange={option => {
            setParams(prev => ({
              ...prev,
              integrationType: option?.value || '',
              start: 0,
            }));
          }}
        />
      </Filter>
    </FiltersContainer>
  );
};
