import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { RecordHome } from './RecordHome';

export const RecordHomePage = () => (
  <>
    <DocumentHead title='Record Home' />
    <PageTemplate main={<RecordHome />} />
  </>
);
