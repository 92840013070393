import * as React from 'react';
import { createRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { VideoItem } from 'lib/api/videos/types';
import { MdChevronLeft, MdSave } from 'react-icons/md';
import { useHistory } from 'react-router';
import { LoadingIndicator } from 'lib/components';
import { Button } from 'react-covideo-common';
import { AudioRecorder, VideoPreview } from '../components';
import { useToastError, VideoData } from 'lib/hooks';
import {
  getVoiceoverSignedURLs,
  GetSignedUrlsParams,
  GetSignedUrlsResponse,
  uploadVoiceoverAudio,
  addVoiceoverToVideo,
} from 'lib/api';
import { FILE_EXTENSION } from 'lib/hooks/useMediaRecorder';
import { successToast } from 'lib/components/toasts/success';
import RouteLeavingGuard from '../../videoDetails/main/RouteLeavingGuard';
import { ModalInputText } from 'lib/components/modal/ModalInputText';
import { CardAlert } from 'lib/components/CardAlert';
import { MdInfo } from 'react-icons/md';
import { generateTempFileName } from '../components/util';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
type BackButtonProps = {
  disabled: boolean;
};

const BackButton = styled.div<BackButtonProps>`
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
    `}
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: ${theme.palette.gray100};
`;

type Props = {
  video: VideoItem;
};

export const Voiceover = ({ video }: Props) => {
  const history = useHistory();
  const { showError } = useToastError();
  const videoRef = createRef<HTMLVideoElement>();
  const [voiceoverValid, setVoiceoverValid] = useState(false);
  const [recordingBlobUrl, setRecordingBlobUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [showModalRename, setShowModalRename] = useState(false);
  const [useVoiceover, setUseVoiceover] = useState(false);
  const [showCard, setShowCard] = React.useState(true);

  const submitVoiceover = async () => {
    setShowModalRename(true);
  };

  const submitVideo = async (newVideoTitle: string) => {
    setShowModalRename(false);
    setUploading(true);
    const blob = await fetch(recordingBlobUrl).then(r => r.blob());
    const newAudioFileName = generateTempFileName(video.id, FILE_EXTENSION);
    const file = new File([blob], newAudioFileName);

    const signedUrlData: GetSignedUrlsParams = {
      fileName: file.name,
      mimeType: 'audio/webm',
      folder: '',
    };

    const signedURL: GetSignedUrlsResponse =
      await getVoiceoverSignedURLs(signedUrlData);

    if (!signedURL || signedURL instanceof Error) {
      setUploading(false);
      showError(signedURL);
      return;
    }

    const data = {
      file: file as File,
      uploadURL: signedURL.uploadUrl,
      mimeType: signedUrlData.mimeType,
    };

    try {
      await uploadVoiceoverAudio({ data });
      await addVoiceoverToVideo({
        videoId: video.id,
        newVideoTitle,
        audioFileName: newAudioFileName,
      });
    } catch (error) {
      setUploading(false);
      showError(error);
      return;
    }
    setUseVoiceover(true);
    successToast({ title: 'Voiceover successfully created!' });
    setUploading(false);
    setVoiceoverValid(false);
    history.push('/home');
  };

  useEffect(() => {
    if (useVoiceover && window.Intercom) {
      window.Intercom('trackEvent', 'voiceover-used');
    }
  }, [useVoiceover]);

  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return voiceoverValid || uploading;
        }}
        title='Leave without saving the voiceover?'
        text='Your voiceover will not be saved. This action can’t be undone.'
        confirmButtonText='Continue'
        discardButtonText='Leave'
      />
      {showCard && (
        <CardAlert
          handleClose={() => setShowCard(false)}
          title='Duplicated video ready for voiceover'
          icon={<MdInfo size={22} color={theme.palette.gray80} />}
          content={
            <>
              This video won’t be available in your Library{' '}
              <b>until you save it</b>. A new voiceover will{' '}
              <b>replace the original audio</b> in this duplicated video. You
              can trim the final video after you save it.
            </>
          }
        />
      )}
      <Header>
        <HeaderLeft>
          <BackButton onClick={() => history.goBack()} disabled={uploading}>
            <MdChevronLeft size={20} color={theme.palette.gray60} />
          </BackButton>
          <Title>Record voiceover</Title>
        </HeaderLeft>
        <Button
          variant='primary'
          text={'Save Video'}
          icon={<MdSave />}
          disabled={!voiceoverValid || uploading}
          onClick={() => submitVoiceover()}
        />
      </Header>
      {uploading && <LoadingIndicator isLoading={true} />}
      {!uploading && (
        <>
          <VideoPreview
            video={video as unknown as VideoData}
            videoRef={videoRef}
            margin='32px 0 0'
          />
          <AudioRecorder
            videoRef={videoRef}
            video={video as unknown as VideoData}
            onChangeVoiceoverValid={setVoiceoverValid}
            handleShowCard={setShowCard}
            onRecordingUrlGeneration={(url: string) => {
              setRecordingBlobUrl(url);
            }}
            margin='32px auto 0'
          />
        </>
      )}
      {showModalRename && (
        <ModalInputText
          title='Name new video'
          label='Give your new video a name'
          defaultValue={video.title}
          secondaryButtonText='Go Back'
          primaryButtonText='Save Video'
          handleSubmit={submitVideo}
          handleModalClose={() => setShowModalRename(false)}
        />
      )}
    </>
  );
};
