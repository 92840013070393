import { AccessRole } from 'lib/const';
import { ICombinedUserData } from 'lib/context';

export const getUserAccessAsString = (userAccess: number) => {
  let access;
  switch (userAccess.toString()) {
    case AccessRole.ADMIN:
      access = 'ADMIN';
      break;
    case AccessRole.SUPERVISOR:
      access = 'SUPERVISOR';
      break;
    case AccessRole.USER:
      access = 'USER';
      break;
    default:
      access = '';
  }
  return access;
};

export const checkIfTrialUser = (userData: ICombinedUserData) => {
  return userData.trialAccount;
};
