import React from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal } from 'lib/components';
import { theme } from 'lib/style';
import { createDepartment } from 'lib/api';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const AlertLabel = styled.label`
  color: ${theme.palette.primaryRedDanger};
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  font-family: Work Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
`;

const Label = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #001b53;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
};

export const SendPasswordModal = (props: Props) => {
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { handleModalClose } = props;

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  const { showError } = useToastError();

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const sendEmail = async () => {
    const emailRegex = /^[0-9a-z\\._-]+@[0-9a-z-]+\..+$/i;
    if (!emailRegex.test(email)) {
      errorToast({ title: 'Email entered is not  valid' });
      return;
    }
    setLoading(true);
    try {
      await createDepartment({ email });
      setLoading(false);
      successToast({ title: 'Email sent successfully!' });
      handleModalClose(true);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Sent a password</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <AlertLabel>Be careful who you send the password to!</AlertLabel>
          <InputWrapper>
            <Label>Email address</Label>
            <Input
              type='text'
              value={email}
              placeholder='Email'
              onChange={event => setEmail(event.target.value)}
            />
          </InputWrapper>
        </Content>
        <Footer>
          <Button
            text='Send'
            onClick={async () => {
              await sendEmail();
            }}
            disabled={!email || loading}
          />
        </Footer>
      </div>
    </Modal>
  );
};
