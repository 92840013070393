import { NewModal, Search } from 'lib/components';
import {
  CustomerListFilter,
  CustomerOrganization,
  SuperAdminCustomer,
  SuperAdminOrganization,
} from 'lib/context';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ResellersDropdown } from '../../customers/components/ResellersDropdown';
import { useCustomersQuery, useLinkCustomers } from 'lib/api';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import LinkCustomersTable, {
  PaginationData,
} from './LinkCustomersTable/LinkCustomersTable';
import { SORTKEYS } from 'lib/const/SuperAdminConstants';
import { Button } from 'react-covideo-common';

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Filter = styled.div`
  width: 300px;
  margin-left: 10px;
`;

const TableContainer = styled.div`
  margin-bottom: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type LinkCustomersModalProps = {
  handleModalClose: () => void;
  organization: SuperAdminOrganization;
};

export type CustomerRegion = {
  customerId: number;
  organizationRegionId: number;
};
const LinkCustomersModal = ({
  handleModalClose,
  organization,
}: LinkCustomersModalProps) => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [order, setOrder] = useState(PaginationConstants.ASCENDING);
  const [resellerId, setResellerId] = useState(0);
  const [search, setSearch] = useState('');
  const {
    name,
    organizationId,
    customerOrganizations: linkedCustomers,
  } = organization;

  const [selectedCustomers, setSelectedCustomers] = useState<CustomerRegion[]>(
    linkedCustomers?.map((lc: CustomerOrganization) => ({
      customerId: lc.customerId,
      organizationRegionId: lc.organizationRegionId || 0,
    })) || []
  );

  const filter: CustomerListFilter = {
    page,
    size,
    status: VerificationStatus.ACTIVE,
    searchQuery: search,
    order,
    sortKey: SORTKEYS.BUSINESS,
    resellerId,
  };

  const {
    count: customerCount,
    customerArray,
    isLoading,
  } = useCustomersQuery({
    params: {
      limit: size,
      start: page,
      resellerId: resellerId > 0 ? resellerId : undefined,
      status: VerificationStatus.ACTIVE,
      search,
      order,
      sortKey: SORTKEYS.BUSINESS,
    },
    filter,
    enabled: true,
    onSuccess: () => {},
  });

  const { mutateAsync: linkCustomers } = useLinkCustomers();

  const onPaginationChange = (data: PaginationData) => {
    const { page: newPage, size: newSize } = data;
    setPage(newPage);
    setSize(newSize);
  };

  const onCustomerSelect = (customer: SuperAdminCustomer, checked: boolean) => {
    setSelectedCustomers(
      checked
        ? [
            ...selectedCustomers,
            {
              customerId: customer.customerId,
              organizationRegionId: 0,
            },
          ]
        : selectedCustomers.filter(
            (sc: CustomerRegion) => sc.customerId !== customer.customerId
          )
    );
  };

  const onRegionSelect = (regionId: number, customerId: number) => {
    const index = selectedCustomers.findIndex(
      (sc: CustomerRegion) => sc.customerId === customerId
    );

    if (index < 0) return;
    const updatedCustomers = [...selectedCustomers];
    updatedCustomers[index].organizationRegionId = regionId;

    setSelectedCustomers(updatedCustomers);
  };

  const onLinkCustomers = async () => {
    await linkCustomers({
      organizationId,
      data: selectedCustomers,
    });
    handleModalClose();
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText={`Select Customer to link to ${name}`}
      style={{
        content: {
          width: '1008px',
        },
      }}
    >
      <div>
        <FilterContainer>
          <Search
            onSearch={(term: string) => {
              setSearch(term);
              setPage(0);
            }}
            placeholder='Search by business name, id, admin name or email…'
          />
          <Filter>
            <ResellersDropdown
              onSelectReseller={(id: number) => {
                setResellerId(id);
                setPage(0);
              }}
              showAllResellerOption
            />
          </Filter>
        </FilterContainer>

        <TableContainer>
          <LinkCustomersTable
            customerCount={customerCount}
            customerArray={customerArray}
            selectedCustomers={selectedCustomers}
            page={page}
            size={size}
            order={order}
            setOrder={setOrder}
            onPaginationChange={onPaginationChange}
            onCustomerSelect={onCustomerSelect}
            organizationRegions={organization.organizationRegions || []}
            onRegionSelect={onRegionSelect}
            isLoading={isLoading}
          />
        </TableContainer>

        <ButtonContainer>
          <Button onClick={onLinkCustomers} text='Link Selected Customers' />
        </ButtonContainer>
      </div>
    </NewModal>
  );
};

export default LinkCustomersModal;
