const sizeToSpeedRatioLarge = [
  {
    value: 4000,
    label: '160 words / min',
  },
  {
    value: 2000,
    label: '200 words / min',
  },
  {
    value: 1000,
    label: '240 words / min',
  },
];

export const sizeToSpeedRatioMedium = [
  {
    value: 6000,
    label: '160 words / min',
  },
  {
    value: 4000,
    label: '200 words / min',
  },
  {
    value: 2000,
    label: '240 words / min',
  },
];

const sizeToSpeedRatioSmall = [
  {
    value: 8000,
    label: '160 words / min',
  },
  {
    value: 6000,
    label: '200 words / min',
  },
  {
    value: 4000,
    label: '240 words / min',
  },
];

export const handleScriptSpeedUsingScriptSize = (ele: any) => {
  switch (ele) {
    case 'small':
      return sizeToSpeedRatioSmall;

    case 'large':
      return sizeToSpeedRatioMedium;

    case 'x-large':
      return sizeToSpeedRatioLarge;

    default:
      return sizeToSpeedRatioMedium;
  }
};
