import React, { useEffect } from 'react';
import { BarChart, Bar, YAxis, XAxis, Tooltip } from 'recharts';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import WidgetHeader from './common/WidgetHeader';
import CustomTick from '../customized/CustomTick';
import { CartesianGrid } from 'recharts';
import WidgetTabs from '../tabs';
import { WidgetWrapper } from './common/styles';
import { percentage } from '../../utils';
import { EmptyWidget } from '../EmptyWidget';
import { useTabs } from './useTabs';
import {
  IParams,
  Items,
  REPORTS_STALE_TIME,
  WidgetLabels,
  WidgetValues,
} from './types';
import { User } from './interfaces/widgets';
import { handleTypeReturn } from './helper';
import CustomTooltip from '../tooltip/CustomTooltip';
import { getReports } from 'lib/api';
import { useQuery } from 'react-query';
import { widget } from './keys';
import { ReportTypes } from 'lib/const/ReportData';

const videoEngagementsItems: Items[] = [
  { id: 'engagements', label: 'Engagement' },
  { id: 'views', label: 'Views' },
  { id: 'clicks', label: 'CTA Clicks' },
];

interface UserDetails {
  count: number;
  users: User[];
}

interface ITopUsersComparison {
  params: IParams;
  addDataForExport: (data: any) => void;
}

const TopUsersComparison = ({
  params,
  addDataForExport,
}: ITopUsersComparison) => {
  const tabs = useTabs(videoEngagementsItems[0]);

  const queryParams = {
    ...params,
    start: 0,
    limit: 1000,
    reports: [
      ReportTypes.RECORDED,
      ReportTypes.VIEWS,
      ReportTypes.CTA,
      ReportTypes.ENGAGEMENT,
    ],
  };

  const {
    data: topUsers,
    isLoading,
    isRefetching,
  } = useQuery(
    widget.detail(WidgetValues.TOP_USERS, queryParams),
    () => getReports(queryParams, 'metrics/users'),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const calculateTotalValue = (type: string, data: any) => {
    let total: number = 0;
    if (data.length) {
      data.forEach((element: any) => {
        total += handleTypeReturn(element, type);
      });
    }
    return total;
  };

  const generateWidgetData = (type: string, data: UserDetails) => {
    if (data && Array.isArray(data.users) && data.users.length) {
      const topValue = calculateTotalValue(type, data.users);
      let mapper = data.users.map((user: User) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        key: user.key,
        current:
          tabs.selected.id === 'engagements'
            ? +handleTypeReturn(user, type).toFixed(1)
            : +percentage(handleTypeReturn(user, type), topValue),
        value:
          tabs.selected.id === 'engagements'
            ? +handleTypeReturn(user, type).toFixed(1)
            : +handleTypeReturn(user, type),
      }));
      return mapper
        .filter(widgetData => widgetData.current !== 0)
        .sort((a: any, b: any) => a.current - +b.current)
        .reverse()
        .slice(0, 5);
    } else {
      return [];
    }
  };

  const mapDataForExport = (data: any) => {
    let exportData: any = {
      title: WidgetLabels.TOP_FIVE_USER_COMPARISON,
      data: [],
      arr: [],
    };

    let dataObjects: any[] = [];

    const { topUsers } = data;
    if (topUsers && topUsers?.users.length) {
      videoEngagementsItems.forEach((item: any) => {
        let tmpObj: any = { data: [] };
        // eslint-disable-next-line no-useless-concat
        tmpObj.title = 'user-comparison' + '-' + item.id;
        let generate = [];

        switch (item.id) {
          case 'views':
            generate = generateWidgetData('views', topUsers);
            break;
          case 'engagements':
            generate = generateWidgetData('engagement', topUsers);
            break;
          case 'clicks':
            generate = generateWidgetData('cta-clicks', topUsers);
            break;
          default:
            return [];
        }

        generate?.forEach((el: any) => {
          const obj: any = {};
          obj['User'] = el.name;
          obj[item.label] = el.value;
          tmpObj.data.push(obj);
        });
        dataObjects.push(tmpObj.data);
        exportData.arr.push(tmpObj);
      });
      const maxLength = Math.max(...dataObjects.map(e => e.length));
      for (let i = 0; i < maxLength; i++) {
        let singleObject = {
          'User by Engagement': dataObjects[0][i]?.['User'] ?? '',
          'Engagement Rate': `${dataObjects[0][i]?.['Engagement']}%` ?? '',
          '': '',
          'User by Views': dataObjects[1][i]?.['User'] ?? '',
          Views: dataObjects[1][i]?.['Views'] ?? '',
          ' ': '',
          'User by Clicks': dataObjects[2][i]?.['User'] ?? '',
          Clicks: dataObjects[2][i]?.['CTA Clicks'] ?? '',
          '  ': '',
        };
        exportData.data.push(singleObject);
      }
    }

    return exportData;
  };

  useEffect(() => {
    addDataForExport(mapDataForExport({ topUsers }));
  }, [topUsers]);

  const widgetData = {
    engagements: generateWidgetData('engagement', topUsers),
    views: generateWidgetData('views', topUsers),
    clicks: generateWidgetData('cta-clicks', topUsers),
  };

  const chartData = widgetData[tabs.selected.id];

  //Check if all current values are empty
  const hasCurrentValues = () => {
    return chartData.filter(item => item.current > 0).length;
  };

  const handleLegendSelection = () => {
    switch (tabs.selected.id) {
      case 'engagements':
        return 'engagement rate';
      case 'views':
        return 'views';
      case 'clicks':
        return 'cta clicks';
    }
  };

  if (isLoading || !topUsers) {
    return <SkeletonLoader imageName='top-users' />;
  }

  return (
    <WidgetWrapper>
      <WidgetHeader
        title={'Top 5 User Comparison'}
        isRefetching={isRefetching}
      />
      <WidgetTabs items={videoEngagementsItems} {...tabs} />
      {chartData.length && hasCurrentValues() ? (
        <BarChart
          width={408}
          height={200}
          data={chartData}
          margin={{ left: -20, bottom: 2 }}
        >
          <CartesianGrid strokeDasharray='0' vertical={false} />
          <XAxis
            dataKey='name'
            tick={{
              fontWeight: 'bold',
              fill: 'rgba(0, 27, 83, 1)',
              fontSize: 12,
              width: '68px',
            }}
            interval={0}
            axisLine={false}
          />
          <YAxis
            allowDecimals={false}
            tick={props => <CustomTick {...props} hasPercentage />}
            ticks={[0, 50, 100]}
            tickFormatter={tick => {
              return `${tick}%`;
            }}
            axisLine={false}
          />
          <Bar dataKey='current' radius={8} fill='#CCD1DD' />
          <Tooltip
            content={
              <CustomTooltip
                customName={handleLegendSelection()}
                percentage={tabs.selected.id === 'engagements'}
              />
            }
          />
        </BarChart>
      ) : (
        <EmptyWidget />
      )}
    </WidgetWrapper>
  );
};

export default TopUsersComparison;
