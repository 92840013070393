import React from 'react';
import { Dropdown } from 'lib/components';

import { getResellers } from 'lib/api';
import { uniqBy, debounce } from 'lodash';
import { Reseller } from 'lib/context';
import { MenuPosition } from 'react-select';

type Props = {
  showAllResellerOption?: boolean;
  resellerFilter?: number;
  placeholder?: string;
  menuPosition?: MenuPosition;
  zIndexProp?: number;
  onSelectReseller(resellerId: number): void;
};

type DropDownOption = {
  label: string;
  value: number;
};

export const ResellersDropdown = ({
  showAllResellerOption = false,
  placeholder = 'All Resellers',
  resellerFilter,
  onSelectReseller,
  menuPosition,
  zIndexProp,
}: Props) => {
  const [page, setPage] = React.useState(0);
  const [pageCopy, setPageCopy] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [resellerDropdownOptions, setResellerDropdownOptions] = React.useState<
    DropDownOption[]
  >(
    showAllResellerOption
      ? [
          {
            label: 'All resellers',
            value: -1,
          },
        ]
      : []
  );
  const [loadingResellers, setLoadingResellers] = React.useState(false);
  const [resellerCount, setResellersCount] = React.useState(0);

  const searchInput = debounce((e: string) => {
    setSearch(e);
    e ? setPage(0) : setPage(pageCopy);
  }, 600);

  const fetchResellers = async (_search?: string) => {
    setLoadingResellers(true);
    try {
      const limit = 10;
      const start = page * limit;
      const [_resellers, count] = await getResellers(search, start, limit);
      if (!count) {
        setLoadingResellers(false);
      }

      let resellerOptions: DropDownOption[] = JSON.parse(
        JSON.stringify(resellerDropdownOptions)
      );

      _resellers.forEach((e: Reseller) => {
        resellerOptions = [
          ...resellerOptions,
          { label: `${e.resellerName} - ${e.resellerId}`, value: e.resellerId },
        ];
      });
      resellerOptions = uniqBy(resellerOptions, 'value');
      setResellerDropdownOptions(resellerOptions);
      setResellersCount(count);
      setLoadingResellers(false);
    } catch (error) {
      setLoadingResellers(false);
    }
  };

  React.useEffect(() => {
    fetchResellers();
  }, [page, search]);

  return (
    <Dropdown
      menuPortalTarget={document.body}
      menuPosition={menuPosition}
      menuPlacement={'bottom'}
      placeholder={placeholder}
      creatable={false}
      dropdownHeight={300}
      options={resellerDropdownOptions}
      value={resellerDropdownOptions.find(
        option => option.value === resellerFilter
      )}
      onChange={option => onSelectReseller(option.value)}
      onMenuScrollToBottom={() => {
        if (resellerDropdownOptions.length < resellerCount) setPage(page + 1);
        if (!search) setPageCopy(page + 1);
      }}
      onInputChange={searchInput}
      isLoading={loadingResellers}
      onMenuClose={() => setLoadingResellers(false)}
      className='option-container'
      isSearchable={true}
      zIndexProp={zIndexProp || 999}
      extendStyles={{
        valueContainer: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          flexWrap: 'nowrap',
        },
      }}
    />
  );
};
