import { useFormikContext } from 'formik';
import { Folder, useGetFolders } from 'lib/api/folders/getFolders';
import { Dropdown } from 'lib/components';
import React, { useEffect } from 'react';
import { FormikValue } from '../modals/AddGuideModal';

const FolderDropdown = () => {
  const { data: folders = [], isLoading } = useGetFolders();
  const { values, setFieldValue } = useFormikContext<FormikValue>();

  useEffect(() => {
    if (folders && !!folders.length && !values.folder.label) {
      setFieldValue('folder', {
        value: folders[0].folderId,
        label: folders[0].name,
      });
    }
  }, [folders]);

  return (
    <Dropdown
      options={folders.map((folder: Folder) => ({
        value: folder.folderId,
        label: folder.name,
      }))}
      value={{
        value: values.folder.value,
        label: values.folder.label,
      }}
      onChange={newValue => {
        setFieldValue('folder', {
          value: newValue.value,
          label: newValue.label,
        });
      }}
      height={40}
      width={290}
      menuPortalTarget={document.body}
      menuShouldBlockScroll={true}
      menuPosition='absolute'
      menuPlacement='bottom'
      placeholder='Select Folder'
      disabled={isLoading}
    />
  );
};

export default FolderDropdown;
