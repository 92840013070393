import { isSafari } from 'lib/utils/browserChecks';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

export const MIME_TYPE = isSafari ? 'video/mp4' : 'video/webm;codecs=vp8,opus';
export const CONTENT_TYPE = 'video/webm';
export const FILE_EXTENSION = 'webm';

export type ReactMediaRecorderRenderProps = {
  error: string;
  muteAudio: () => void;
  unMuteAudio: () => void;
  startRecording: () => void;
  startRecordingForCanvas: (canvasStream: any) => void;
  pauseRecording: () => void;
  resumeRecording: () => void;
  stopRecording: () => Promise<void>;
  mediaBlobUrl: null | string;
  status: StatusMessages;
  isAudioMuted: boolean;
  previewStream: MediaStream | null;
  previewAudioStream: MediaStream | null;
  clearBlobUrl: () => void;
};

export type ReactMediaRecorderHookProps = {
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
  screen?: boolean;
  onStop?: (blobUrl: string, blob: Blob) => void;
  blobPropertyBag?: BlobPropertyBag;
  mediaRecorderOptions?: any | null;
  mimeType?: string;
  quality?: string;
};
export type ReactMediaRecorderProps = ReactMediaRecorderHookProps & {
  render: (props: ReactMediaRecorderRenderProps) => ReactElement;
};

const stopStream = (s: any) => {
  try {
    s.getTracks().forEach((t: any) => t.stop());
  } catch (e) {}
};

export type StatusMessages =
  | 'media_aborted'
  | 'permission_denied'
  | 'no_specified_media_found'
  | 'media_in_use'
  | 'invalid_media_constraints'
  | 'no_constraints'
  | 'recorder_error'
  | 'idle'
  | 'acquiring_media'
  | 'delayed_start'
  | 'recording'
  | 'stopping'
  | 'stopped';

export enum RecorderErrors {
  AbortError = 'media_aborted',
  NotAllowedError = 'permission_denied',
  NotFoundError = 'no_specified_media_found',
  NotReadableError = 'media_in_use',
  OverconstrainedError = 'invalid_media_constraints',
  TypeError = 'no_constraints',
  NONE = '',
  NO_RECORDER = 'recorder_error',
}

const BITRATES: { [key: string]: number } = {
  low: 1000000,
  standard: 2500000,
  high: 5000000,
  ultra: 8000000,
};

export function useMediaRecorder({
  audio = true,
  video = false,
  onStop = () => null,
  blobPropertyBag,
  screen = false,
  mediaRecorderOptions = null,
  mimeType = '',
  quality = 'low',
}: ReactMediaRecorderHookProps): ReactMediaRecorderRenderProps {
  const mediaRecorder = useRef<any | null>(null);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [status, setStatus] = useState<StatusMessages>('idle');
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | null>(null);
  const [error, setError] = useState<keyof typeof RecorderErrors>('NONE');
  const mountedRef = useRef<boolean>(false);
  const videoBitsPerSecond = BITRATES[quality] || BITRATES.low;
  //@ts-ignore
  if (!window.streams) {
    //@ts-ignore
    window.streams = [];
  }
  //@ts-ignore
  window.streams = window.streams.filter(Boolean);

  const mergeAudioStreams = (
    desktopStream: MediaStream,
    micStream: MediaStream
  ) => {
    const context = new AudioContext();
    const desktopStreamSource = context.createMediaStreamSource(desktopStream);
    const micStreamSource = context.createMediaStreamSource(micStream);
    const destination = context.createMediaStreamDestination();

    const desktopGain = context.createGain();
    const micGain = context.createGain();

    desktopGain.gain.value = 0.7;
    micGain.gain.value = 0.7;

    desktopStreamSource.connect(desktopGain).connect(destination);
    micStreamSource.connect(micGain).connect(destination);

    return destination.stream.getTracks();
  };
  const getMediaStream = useCallback(async () => {
    setStatus('acquiring_media');
    const requiredMedia: MediaStreamConstraints = {
      audio: typeof audio === 'boolean' ? !!audio : audio,
      video: typeof video === 'boolean' ? !!video : video,
    };
    let stream: any;
    let audioStream: any;
    try {
      if (screen) {
        //@ts-ignore
        stream = (await window.navigator.mediaDevices.getDisplayMedia({
          video: video || true,
          audio: audio || true,
          //@ts-ignore
          systemAudio: 'include',
        })) as MediaStream;
        //@ts-ignore
        window.streams.push(stream);
        if (stream && audio) {
          audioStream = await window.navigator.mediaDevices.getUserMedia({
            audio,
          });

          //@ts-ignore
          window.streams.push(audioStream);

          try {
            const combinedStreamTracks = [
              ...stream.getVideoTracks(),
              ...mergeAudioStreams(stream, audioStream),
            ];
            stream = new MediaStream(combinedStreamTracks);
          } catch (ex) {
            audioStream
              .getAudioTracks()
              .forEach((audioTrack: any) => stream.addTrack(audioTrack));
          }
        }
        mediaStream.current = stream;
      } else {
        stream =
          await window.navigator.mediaDevices.getUserMedia(requiredMedia);
        //@ts-ignore
        window.streams.push(stream);
        mediaStream.current = stream;
      }
      if (!mountedRef.current) {
        stopStream(stream);
        return;
      }
      if (stream) {
        setStatus('idle');
      }
    } catch (error) {
      stopStream(stream);
      stopStream(audioStream);
      setError(error.name);
      setStatus('idle');
    }
  }, [audio, video, screen]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (!window.MediaRecorder) {
      throw new Error('Unsupported Browser');
    }

    if (screen) {
      //@ts-ignore
      if (!window.navigator.mediaDevices.getDisplayMedia) {
        throw new Error("This browser doesn't support screen capturing");
      }
    }

    const checkConstraints = (mediaType: MediaTrackConstraints) => {
      const supportedMediaConstraints =
        navigator.mediaDevices.getSupportedConstraints();
      const unSupportedConstraints = Object.keys(mediaType).filter(
        constraint =>
          !(supportedMediaConstraints as { [key: string]: any })[constraint]
      );

      if (unSupportedConstraints.length > 0) {
        console.error(
          `The constraints ${unSupportedConstraints.join(
            ','
          )} doesn't support on this browser. Please check your ReactMediaRecorder component.`
        );
      }
    };

    if (typeof audio === 'object') {
      checkConstraints(audio);
    }
    if (typeof video === 'object') {
      checkConstraints(video);
    }

    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      //@ts-ignore
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(
          `The specified MIME type you supplied for MediaRecorder doesn't support this browser`
        );
      }
    }

    if (!mediaStream.current) {
      getMediaStream();
    }
  }, [audio, screen, video, getMediaStream, mediaRecorderOptions]);

  // Media Recorder Handlers

  const startRecording = async () => {
    setError('NONE');
    if (!mediaStream.current) {
      await getMediaStream();
    }
    if (mediaStream.current) {
      const isStreamEnded = mediaStream.current
        .getTracks()
        .some(track => track.readyState === 'ended');
      if (isStreamEnded) {
        await getMediaStream();
      }
      //@ts-ignore
      mediaRecorder.current = new MediaRecorder(mediaStream.current, {
        mimeType,
        videoBitsPerSecond,
      });
      mediaRecorder.current.ondataavailable = onRecordingActive;
      mediaRecorder.current.onstop = onRecordingStop;
      mediaRecorder.current.onerror = () => {
        setError('NO_RECORDER');
        setStatus('idle');
      };
      mediaRecorder.current.start();
      setStatus('recording');
    }
  };

  const startRecordingForCanvas = async (canvasStream: any) => {
    if (!canvasStream) {
      setError('NO_RECORDER');
      return;
    }

    if (audio) {
      let audioStream = await window.navigator.mediaDevices.getUserMedia({
        audio,
      });

      //@ts-ignore
      window.streams.push(audioStream);

      audioStream
        .getAudioTracks()
        .forEach((audioTrack: any) => canvasStream.addTrack(audioTrack));
    }

    //@ts-ignore
    mediaRecorder.current = new MediaRecorder(canvasStream, {
      mimeType,
      videoBitsPerSecond,
    });
    mediaRecorder.current.ondataavailable = onRecordingActive;
    mediaRecorder.current.onstop = onRecordingStop;
    mediaRecorder.current.onerror = () => {
      setError('NO_RECORDER');
      setStatus('idle');
    };
    mediaRecorder.current.start();
    setStatus('recording');
  };

  const onRecordingActive = ({ data }: any) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStop = () => {
    const [chunk] = mediaChunks.current;
    const blobProperty: BlobPropertyBag = Object.assign(
      { type: chunk.type },
      blobPropertyBag || (video ? { type: 'video/mp4' } : { type: 'audio/wav' })
    );
    const blob = new Blob(mediaChunks.current, blobProperty);
    const url = URL.createObjectURL(blob);
    mediaChunks.current = [];
    setStatus('stopped');
    setMediaBlobUrl(url);
    onStop(url, blob);
  };

  const muteAudio = (mute: boolean) => {
    setIsAudioMuted(mute);
    if (mediaStream.current) {
      mediaStream.current
        .getAudioTracks()
        .forEach(audioTrack => (audioTrack.enabled = !mute));
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('stopping');
        await mediaRecorder.current.stop();
        mediaStream.current &&
          mediaStream.current.getTracks().forEach(track => track.stop());
      }
    }
  };

  const previewStream = mediaStream.current
    ? new MediaStream(mediaStream.current.getVideoTracks())
    : null;
  //@ts-ignore
  window.streams.push(previewStream);

  const previewAudioStream = mediaStream.current
    ? new MediaStream(mediaStream.current.getAudioTracks())
    : null;
  //@ts-ignore
  window.streams.push(previewAudioStream);

  return {
    error: RecorderErrors[error],
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    startRecording,
    startRecordingForCanvas,
    pauseRecording,
    resumeRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    isAudioMuted,
    previewStream,
    previewAudioStream,
    clearBlobUrl: () => setMediaBlobUrl(null),
  };
}
