import { Button } from 'react-covideo-common';
import { VideoData } from 'lib/hooks';
import React, { createRef, useEffect, useState } from 'react';
import { theme } from 'lib/style';
import { MdMic, MdPause, MdPlayArrow } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';
import { MdRefresh } from 'react-icons/md';
import { BsRecordCircle } from 'react-icons/bs';
import { useMediaRecorder } from 'lib/hooks/useMediaRecorder';
import { useHistory } from 'react-router';
import { toMMSS } from 'lib/utils/functions';
import { ModalPrompt } from 'lib/components/modal';
import { errorToast } from 'lib/components/toasts/error';

type WrapperProps = {
  margin: string;
  width: number;
  disabled: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 11px;
  width: ${({ width }) => `${width}px`};
  margin: ${({ margin }) => margin};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
`;

type RecordingProgressProps = {
  disabled: boolean;
};

const RecordingProgress = styled.div<RecordingProgressProps>`
  position: relative;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.blue05};
  border-radius: 6px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const ProgressBar = styled.div`
  position: relative;
  height: 12px;
  width: 100%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
`;

type DoneProps = {
  position: number;
  background: string;
};

const Done = styled.div<DoneProps>`
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 0;
  width: ${({ position }) => `${position}%`};
  background: ${({ background }) => background};
  border-radius: 8px;
  max-width: 100%;
`;

const RecordingInfo = styled.div`
  box-sizing: border-box;
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px;
`;

const MicIconWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 17px;
  padding-top: 4px;
`;

const CurrentTime = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

const Duration = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 60px;
  padding-left: 12px;
  margin-right: 12px;
  color: ${theme.palette.gray60};
`;

type SliderProps = {
  disabled: boolean;
};

const Slider = styled.input<SliderProps>`
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  cursor: pointer;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 4px;
    height: 40px;
    margin-top: 6px;
    z-index: 3;
  }
  &::-moz-range-thumb {
    width: 4px;
    height: 40px;
    margin-top: 6px;
    z-index: 3;
  }
  &::-ms-thumb {
    width: 4px;
    height: 40px;
    z-index: 3;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      &::-webkit-slider-thumb {
        display: none;
      }
      &::-moz-range-thumb {
        display: none;
      }
      &::-ms-thumb {
        display: none;
      }
    `};
  &:focus {
    outline: none;
  }
  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
`;

const Audio = styled.audio`
  display: none;
`;

const DEFAULT_SETTINGS = {
  audioSource: { value: 'default' },
};

enum RecordingStatus {
  NotRecording = 'NotRecording',
  Recording = 'Recording',
  RecordingPaused = 'RecordingPaused',
  Finished = 'Finished',
}

enum PreviewingStatus {
  NotPreviewing = 'NotPreviewing',
  Previewing = 'Previewing',
  PreviewingPaused = 'PreviewingPaused',
}

type Props = {
  video: VideoData;
  videoRef: React.RefObject<HTMLVideoElement>;
  onChangeVoiceoverValid: (valid: boolean) => void;
  onRecordingUrlGeneration: (url: string) => void;
  handleShowCard?: (show: boolean) => void;
  margin?: string;
  width?: number;
  disabled?: boolean;
  setFormIsTouched?: (arg1: boolean) => void;
};

export const AudioRecorder = ({
  video,
  videoRef,
  onChangeVoiceoverValid,
  onRecordingUrlGeneration,
  handleShowCard,
  margin = '0',
  width = 696,
  disabled = false,
  setFormIsTouched,
}: Props) => {
  const audioRef = createRef<HTMLAudioElement>();
  const [recordingStatus, setRecordingStatus] = useState(
    RecordingStatus.NotRecording
  );
  const [previewingStatus, setPreviewingStatus] = useState(
    PreviewingStatus.NotPreviewing
  );
  const [currentTime, setCurrentTime] = useState(0);
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement>();
  const [showRetakePrompt, setShowRetakePrompt] = useState(false);
  const history = useHistory();

  const settings = {
    audioSource: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).audioSource as any,
  };

  let audio: any;
  try {
    audio = { deviceId: settings.audioSource.value };
  } catch (e) {}
  if (!settings || !settings.audioSource || !settings.audioSource.value) {
    audio = false;
  }

  const {
    error,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    mediaBlobUrl,
  } = useMediaRecorder({
    audio,
  });

  // TODO: maybe should handle error without redirect and add record settings to voiceover, or add some voiceover support on record_home
  if (!audio || error) {
    errorToast({
      title: 'Please enable audio in your recording settings and try again!',
    });
    history.push('/record/home');
  }

  const startRecordingClick = async () => {
    if (!videoRef.current) {
      return;
    }
    handleShowCard?.(false);
    videoRef.current.volume = 0;
    videoRef.current.play();
    startRecording();
    setRecordingStatus(RecordingStatus.Recording);
    if (setFormIsTouched) setFormIsTouched(true);
  };

  const pauseRecordingClick = () => {
    if (!videoRef.current) {
      return;
    }
    pauseRecording();
    videoRef.current.pause();
    setRecordingStatus(RecordingStatus.RecordingPaused);
  };

  const resumeRecordingClick = () => {
    if (!videoRef.current) {
      return;
    }
    resumeRecording();
    videoRef.current?.play();
    setRecordingStatus(RecordingStatus.Recording);
  };

  const retakeButtonClick = () => {
    if (!videoRef.current || !audioPlayer) {
      return;
    }
    audioPlayer.pause();
    videoRef.current.pause();
    if (previewingStatus !== PreviewingStatus.NotPreviewing) {
      setPreviewingStatus(PreviewingStatus.PreviewingPaused);
    }
    if (recordingStatus !== RecordingStatus.Finished) {
      setRecordingStatus(RecordingStatus.RecordingPaused);
    }
    setShowRetakePrompt(true);
  };

  const retakeRecording = async () => {
    if (!videoRef.current || !audioPlayer) {
      return;
    }
    setShowRetakePrompt(false);
    await stopRecording();
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
    audioPlayer.pause();
    audioPlayer.currentTime = 0;
    setRecordingStatus(RecordingStatus.NotRecording);
    setPreviewingStatus(PreviewingStatus.NotPreviewing);
  };

  const startPreview = () => {
    if (!videoRef.current || !audioPlayer) {
      return;
    }
    setPreviewingStatus(PreviewingStatus.Previewing);
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    audioPlayer.play();
  };

  const pausePreview = () => {
    if (!videoRef.current || !audioPlayer) {
      return;
    }
    audioPlayer.pause();
    videoRef.current.pause();
    setPreviewingStatus(PreviewingStatus.PreviewingPaused);
  };

  const resumePreview = () => {
    if (!videoRef.current || !audioPlayer) {
      return;
    }
    audioPlayer.play();
    videoRef.current.play();
    setPreviewingStatus(PreviewingStatus.Previewing);
  };

  const seekTo = (position: string) => {
    if (!videoRef.current || !audioPlayer) {
      return;
    }
    videoRef.current.currentTime = parseFloat(position);
    audioPlayer.currentTime = parseFloat(position);
    audioPlayer.play();
    videoRef.current.play();
    setPreviewingStatus(PreviewingStatus.Previewing);
  };

  // close/reset everything
  const closeAndClear = () => {
    //@ts-ignore
    const streams = [...window.streams];
    //@ts-ignore
    streams.filter(Boolean).forEach(s => stopStream(s));
    //@ts-ignore
    window.streams = [];
    setRecordingStatus(RecordingStatus.NotRecording);
    setPreviewingStatus(PreviewingStatus.NotPreviewing);
  };

  const stopStream = (s: any) => {
    try {
      s.getTracks().forEach((t: any) => t.stop());
    } catch (e) {}
  };

  // clear all on component unmount
  useEffect(() => {
    return () => {
      closeAndClear();
    };
  }, []);

  // handle video events
  useEffect(() => {
    const handleTimeUpdate = () => {
      let currentTime = 0;
      if (videoRef.current) {
        currentTime = videoRef?.current?.currentTime || 0;
        if (videoRef.current.currentTime >= videoRef.current.duration) {
          currentTime = videoRef.current.duration;
        }
      }
      setCurrentTime(currentTime);
    };
    const handleVideoEnded = async () => {
      await stopRecording();
      setRecordingStatus(RecordingStatus.Finished);
      setPreviewingStatus(PreviewingStatus.NotPreviewing);
    };
    const handleVideoPlay = () => {
      setRecordingStatus(prev =>
        prev === RecordingStatus.Finished ? prev : RecordingStatus.Recording
      );
      setPreviewingStatus(prev =>
        prev === PreviewingStatus.PreviewingPaused
          ? PreviewingStatus.Previewing
          : prev
      );
    };
    const handleVideoPause = () => {
      setRecordingStatus(prev =>
        prev === RecordingStatus.Finished
          ? prev
          : RecordingStatus.RecordingPaused
      );
      setPreviewingStatus(prev =>
        prev === PreviewingStatus.Previewing
          ? PreviewingStatus.PreviewingPaused
          : prev
      );
    };
    const handleVideoSeeked = () => {
      if (!videoRef.current || !audioPlayer) {
        return;
      }
      audioPlayer.currentTime = videoRef.current.currentTime;
    };
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate, true);
      videoRef.current.addEventListener('ended', handleVideoEnded, true);
      videoRef.current.addEventListener('pause', handleVideoPause, true);
      videoRef.current.addEventListener('play', handleVideoPlay, true);
      videoRef.current.addEventListener('seeked', handleVideoSeeked, true);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          'timeupdate',
          handleTimeUpdate,
          true
        );
        videoRef.current.removeEventListener('ended', handleVideoEnded, true);
        videoRef.current.removeEventListener('pause', handleVideoPause, true);
        videoRef.current.removeEventListener('play', handleVideoPlay, true);
        videoRef.current.removeEventListener('seeked', handleVideoSeeked, true);
      }
    };
  }, [videoRef]);

  // voiceover valid when recording finished
  useEffect(() => {
    onChangeVoiceoverValid(
      !!mediaBlobUrl && recordingStatus === RecordingStatus.Finished
    );
  }, [recordingStatus, mediaBlobUrl]);

  useEffect(() => {
    if (!mediaBlobUrl || !audioRef.current) return;
    onRecordingUrlGeneration(mediaBlobUrl);
    // add recording to audio player
    audioRef.current.load();
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    setAudioPlayer(audioRef.current);
  }, [audioRef.current]);

  const isPreview = recordingStatus === RecordingStatus.Finished;
  const isRecording = recordingStatus === RecordingStatus.Recording;
  const duration =
    videoRef?.current?.duration ||
    (video?.videoLength ? video?.videoLength / 1000 : 0);
  const currentPosition = !duration
    ? 100
    : !currentTime
      ? 0
      : (parseFloat(currentTime.toString()) / parseFloat(duration.toString())) *
        100;

  const updatePosition = (e: any) => {
    const newPosition = e.target.value.toString();
    const time = duration * (newPosition / 100);
    seekTo(time.toString());
  };

  // toMMSS util function was displaying 00:010
  const currentTimeMMSS = toMMSS(currentTime, 0);
  return (
    <Wrapper margin={margin} width={width} disabled={disabled}>
      <Audio ref={audioRef}>
        <source src={mediaBlobUrl || ''} />
      </Audio>
      <ButtonsWrapper>
        {recordingStatus === RecordingStatus.NotRecording && (
          <Button
            variant='red'
            icon={<BsRecordCircle size={12} />}
            text={'Record'}
            onClick={() => startRecordingClick()}
          />
        )}
        {recordingStatus === RecordingStatus.Recording && (
          <>
            <Button
              icon={<MdPause />}
              text={'Pause'}
              onClick={() => pauseRecordingClick()}
              variant='secondary'
            />
          </>
        )}
        {recordingStatus === RecordingStatus.RecordingPaused && (
          <Button
            icon={<MdPlayArrow />}
            text={'Resume'}
            onClick={() => resumeRecordingClick()}
            variant='secondary'
          />
        )}
        {recordingStatus === RecordingStatus.Finished &&
          previewingStatus === PreviewingStatus.NotPreviewing && (
            <Button
              icon={<MdPlayArrow />}
              onClick={() => startPreview()}
              variant='secondary'
            />
          )}
        {previewingStatus === PreviewingStatus.Previewing && (
          <Button
            icon={<MdPause />}
            onClick={() => pausePreview()}
            variant='secondary'
          />
        )}
        {previewingStatus === PreviewingStatus.PreviewingPaused && (
          <Button
            icon={<MdPlayArrow />}
            onClick={() => resumePreview()}
            variant='secondary'
          />
        )}
      </ButtonsWrapper>
      <RecordingProgress
        disabled={!isPreview}
        title={!isPreview ? 'Controls disabled before preview.' : ''}
      >
        <RecordingInfo>
          <MicIconWrapper>
            <MdMic
              size={22}
              color={isRecording ? theme.palette.red100 : theme.palette.gray60}
            />
          </MicIconWrapper>
          <CurrentTime>{currentTimeMMSS}</CurrentTime>
        </RecordingInfo>
        <ProgressBar>
          <Slider
            disabled={!isPreview}
            type='range'
            min='0'
            max='100'
            value={currentPosition}
            onChange={e => updatePosition(e)}
            step='1'
          />
          <Done
            background={
              isRecording ? theme.palette.red100 : theme.palette.blue100
            }
            position={currentPosition}
          />
        </ProgressBar>
        <Duration>{toMMSS(duration, 0)}</Duration>
      </RecordingProgress>
      {[RecordingStatus.RecordingPaused, RecordingStatus.Finished].includes(
        recordingStatus
      ) && (
        <ButtonsWrapper>
          <Button
            variant='destructive'
            icon={<MdRefresh />}
            text={'Start Over'}
            onClick={() => retakeButtonClick()}
          />
        </ButtonsWrapper>
      )}
      {showRetakePrompt && (
        <ModalPrompt
          title={'Start Over?'}
          content={<>Your voiceover recording will be erased.</>}
          secondaryButtonText={'Go Back'}
          primaryButtonText={'Start Over'}
          primaryButtonType={'destructive'}
          handleSubmit={() => retakeRecording()}
          handleModalClose={() => setShowRetakePrompt(false)}
        />
      )}
    </Wrapper>
  );
};
