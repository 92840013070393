import { ExitLink, ExitLinkResponse, VideoLinksParams } from './types';
import dayjs from 'dayjs';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { useQuery } from 'react-query';
import { videoKeys } from './videosKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getVideoLinks = async (
  data: VideoLinksParams
): Promise<ExitLinkResponse> => {
  const { videoId, params, onCountChange } = data;

  const response = (
    await EXPRESS_API.get(`videos/${videoId}/links`, {
      params,
    })
  ).data;

  if (response) {
    onCountChange && onCountChange(response.length);

    response.forEach((d: ExitLink) => {
      d.clickDate = d.clickDate
        ? dayjs(new Date(d.clickDate)).format(STANDARD_DATE_FORMAT)
        : '-';
      d.email = d.email || '-';
    });

    return {
      links: response,
    };
  }

  return {
    links: [],
  };
};

export const useGetVideoLinks = (params: VideoLinksParams) => {
  return useQuery(videoKeys.ctas(params), () => getVideoLinks(params));
};
