import * as React from 'react';
import styled from 'styled-components/macro';
import { MdClose } from 'react-icons/md';

import * as theme from 'lib/style/theme';

type BtnProps = {
  light?: boolean;
  zIndex?: boolean;
  padding?: string;
  marginTop?: string;
  marginRight?: string;
};

const Close = styled.button<BtnProps>`
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  color: ${props =>
    props.light ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)'};
  opacity: 0.6;
  background-color: transparent;
  transition: all 0.3s;
  font-size: 20px;
  padding: ${props => props.padding || '34px 10px 0px'};
  margin-top: ${props => props.marginTop || ''};
  margin-right: ${props => props.marginRight || ''};
  display: flex;
  z-index: ${props => (props.zIndex ? '1' : 'auto')};
  &:focus {
    outline: 0;
  }

  &:hover {
    color: ${props =>
      props.light ? theme.palette.white : theme.palette.themeDark};
    opacity: 1;
  }

  span {
    transform: rotate(45deg);
    display: block;
  }
`;

type Props = {
  disabled?: boolean;
  light?: boolean;
  zIndex?: boolean;
  padding?: string;
  marginTop?: string;
  marginRight?: string;
  onClick: () => void;
};

const Component = ({
  disabled = false,
  onClick,
  light = false,
  zIndex = false,
  padding = '',
  marginTop = '',
  marginRight = '',
}: Props) => {
  return (
    <Close
      disabled={disabled}
      onClick={onClick}
      light={light}
      zIndex={zIndex}
      padding={padding}
      marginTop={marginTop}
      marginRight={marginRight}
    >
      <MdClose />
    </Close>
  );
};

export const CloseButton = React.memo(Component);
