import { theme } from 'lib/style';
import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components/macro';
import { debounce } from 'lodash'; /* SUS-1119 changes */
import { Dropdown } from 'lib/components';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { useAuth } from 'lib/context';
import { checkIfOverlayValid } from 'lib/utils/functions';
import { ParagraphExtraSmallBold } from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';
import { MdError } from 'react-icons/md';
import { QUOTIBLE_LANDING_PAGE_TYPE_ID } from 'lib/const/LandingPage';

type CardProps = {
  orientation: 'horizontal' | 'vertical';
};
const Card = styled.div<CardProps>`
  border-radius: 7px;
  margin-top: 24px;
  ${({ orientation }) =>
    orientation === 'horizontal' &&
    css`
      width: 100%;
    `}
`;

type RowProps = {
  orientation: 'horizontal' | 'vertical';
};
const Row = styled.div<RowProps>`
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  ${({ orientation }) =>
    orientation === 'horizontal' &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: baseline;
    `}
`;

const Piece = styled.div`
  width: 100%;
`;
type NewWebsiteInputProps = {
  error?: boolean;
};
/* SUS-1119 changes */
const NewWebsiteInput = styled.input<NewWebsiteInputProps>`
  width: calc(100% - 24px);
  height: 24px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  border: solid 1px
    ${({ error }) => (error ? theme.palette.error : theme.palette.lightgray)};
  outline: none;
  padding: 8px 12px;
`;

const AddWebsiteButton = styled.a`
  max-height: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  color: ${theme.palette.blue80};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;
/*****/
const Label = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin: 0;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type Props = {
  templateOptions: any;
  selectedTemplate: any;
  setSelectedTemplate: Function;
  ctaOptions: any;
  selectedCta: any;
  setSelectedCta: Function;
  overlaysOptions: any /* SUS-1119 changes */;
  selectedOverlay: any /* SUS-1119 changes */;
  setSelectedOverlay: Function /* SUS-1119 changes */;
  orientation?: 'horizontal' | 'vertical';
  extraStyle?: {
    label?: CSSProperties;
    wrapper?: CSSProperties;
    valueContainer?: CSSProperties;
    control?: CSSProperties;
  };
};

export const LandingPageCard = ({
  templateOptions,
  selectedTemplate,
  setSelectedTemplate,
  ctaOptions,
  selectedCta,
  setSelectedCta,
  overlaysOptions /* SUS-1119 changes */,
  selectedOverlay /* SUS-1119 changes */,
  setSelectedOverlay /* SUS-1119 changes */,
  orientation = 'vertical',
  extraStyle,
}: Props) => {
  /* SUS-1119 changes */
  const isWebsiteOverlaySelected = selectedTemplate?.value === -1;
  const [newWebsiteSelected, setNewWebsiteSelected] = React.useState(false);
  const { userData } = useAuth();
  const isCTASEnabled = checkIfFeatureIsEnabled(userData, productFeature.CTAS);
  const isQuotibleTemplateSelected =
    selectedTemplate.typeId === QUOTIBLE_LANDING_PAGE_TYPE_ID;

  React.useEffect(() => {
    if (
      newWebsiteSelected ||
      !overlaysOptions ||
      !overlaysOptions.length ||
      !Array.isArray(overlaysOptions)
    ) {
      setSelectedOverlay({ value: '', label: '' });
      return;
    }
    setSelectedOverlay({
      ...overlaysOptions,
      label: overlaysOptions[0].label,
      value: overlaysOptions[0].value,
    });
  }, [newWebsiteSelected]);

  React.useEffect(() => {
    if (selectedTemplate?.templateId !== -1) {
      setNewWebsiteSelected(false);
    }
  }, [isWebsiteOverlaySelected]);

  const ctaDropdownLabel = () => {
    if (!isWebsiteOverlaySelected) {
      return 'No Calls-To-Action';
    }

    if (!!overlaysOptions && overlaysOptions.length > 0) {
      return 'Select a website';
    }

    return 'No Websites';
  };

  const isOverlayURLValid = checkIfOverlayValid(
    selectedTemplate?.value,
    selectedOverlay?.value
  );

  const setWebsite = debounce(value => {
    setSelectedOverlay({ value: value, label: '' });
  }, 500);
  /*****/
  return (
    <Card orientation={orientation} style={extraStyle?.wrapper}>
      <Row orientation={orientation}>
        <InputWrapper>
          <Piece>
            <Label style={extraStyle?.label}>Landing Page</Label>
          </Piece>
          <Piece>
            <Dropdown
              value={selectedTemplate}
              placeholder={'No Landing Pages'}
              data-cy='landing-page-dropdown'
              options={templateOptions}
              creatable={false}
              onChange={(value: any) =>
                value !== null ? setSelectedTemplate(value) : () => {}
              }
              extendStyles={{
                valueContainer: {
                  display: 'inline',
                  ...(extraStyle?.valueContainer || {}),
                },
                control: extraStyle?.control,
                input: {
                  display: 'inline',
                },
              }}
            />
          </Piece>
        </InputWrapper>
        {!isQuotibleTemplateSelected && (
          <InputWrapper>
            <Piece
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '6px',
                ...(extraStyle?.label ? extraStyle.label : {}),
              }}
            >
              {/*  SUS-1119 changes */}
              <Label style={extraStyle?.label}>
                {isWebsiteOverlaySelected ? 'Overlay URL' : 'Calls-To-Action'}
              </Label>
              {isWebsiteOverlaySelected && (
                <AddWebsiteButton
                  onClick={() => setNewWebsiteSelected(prev => !prev)}
                >
                  {newWebsiteSelected ? 'Use Existing' : 'Add New'}
                </AddWebsiteButton>
              )}
            </Piece>
            <Piece>
              {!newWebsiteSelected /* SUS-1119 changes */ && (
                <>
                  <Dropdown
                    value={
                      isWebsiteOverlaySelected
                        ? overlaysOptions.find((o: any) => {
                            return o.value == selectedOverlay.value;
                          }) || null
                        : ctaOptions.find((o: any) => {
                            return o.value == selectedCta?.value;
                          })
                    }
                    error={!isOverlayURLValid}
                    isClearable={isCTASEnabled}
                    creatable={false}
                    placeholder={ctaDropdownLabel()}
                    options={
                      isWebsiteOverlaySelected ? overlaysOptions : ctaOptions
                    }
                    onChange={(option: any) => {
                      isWebsiteOverlaySelected
                        ? setSelectedOverlay(option || '')
                        : setSelectedCta(option || '');
                    }}
                    extendStyles={{
                      valueContainer: {
                        display: 'inline',
                        ...(extraStyle?.valueContainer || {}),
                      },
                      control: extraStyle?.control,
                      input: {
                        display: 'inline',
                      },
                    }}
                  />
                  {!isOverlayURLValid && (
                    <Gap gap={'4px'} m='8px 0 0'>
                      <MdError color={theme.palette.error} size={14} />
                      <ParagraphExtraSmallBold color={theme.palette.error}>
                        Overlay URL required.
                      </ParagraphExtraSmallBold>
                    </Gap>
                  )}
                </>
              )}
              {/* SUS-1119 changes */}
              {newWebsiteSelected && (
                <NewWebsiteInput
                  type='text'
                  placeholder='Enter website'
                  error={isWebsiteOverlaySelected && !selectedOverlay.value}
                  onChange={(e: any) => {
                    setWebsite(e.target.value);
                  }}
                />
              )}
            </Piece>
          </InputWrapper>
        )}
      </Row>
    </Card>
  );
};
