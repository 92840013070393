import { FormikValues } from 'formik';
import { IMS_IDS, options } from './const';
import { InitialValuesType, ConvertedValuesType } from './types';

export const getUnusedOptions = (values: {
  [x: string]: string | number | string[];
}) =>
  options.filter(option => {
    const id = option.value;
    const value = values?.[id];

    return !value || (Array.isArray(value) && value.length === 0);
  });

export const getUsedOptions = (values: {
  [x: string]: string | number | string[];
}) =>
  options.filter(option => {
    const id = option.value;
    const value = values?.[id];

    return value && Array.isArray(value) && value.length > 0;
  });

export const convertIMSResponseToFormikValues = (
  idsArray: string[],
  initialValues: InitialValuesType
): ConvertedValuesType => {
  const result: ConvertedValuesType = {};

  idsArray.forEach((id: string) => {
    const value = initialValues[id];
    if (typeof value === 'string') {
      result[id] = value.split('|');
    } else {
      result[id] = [];
    }
  });

  return result;
};

export const checkForEmptyStrings = (values: FormikValues) =>
  IMS_IDS.filter(provider => {
    const array = values?.[provider];
    return array?.includes('');
  }).length !== 0;
