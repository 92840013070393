import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { FaAlignCenter, FaAlignRight, FaAlignLeft } from 'react-icons/fa';
import { useLandingPageBuilderContext } from '../../context';

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
`;

export const ChangePositionComponent = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    setElementToEdit,
    elementSectionKey,
    elementToEdit,
    elementIndex,
  } = useLandingPageBuilderContext();
  const themes = useTheme();

  let data = { ...selectedLayoutData };
  if (!elementSectionKey || !data) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  if (!sectionDetails) {
    return <></>;
  }
  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }
  const isVisible = isElementCase
    ? data[elementSectionKey].childSections[elementIndex]?.isVisible
    : data[elementSectionKey]?.isVisible;

  if (!isVisible) {
    return <></>;
  }
  let currentPosition = '';
  if (isElementCase) {
    currentPosition =
      data[elementSectionKey].childSections[elementIndex]?.sectionPosition;
  } else {
    currentPosition = data[elementSectionKey]?.sectionPosition;
  }
  return (
    <Row>
      <TopLabel>Position</TopLabel>
      <Button
        style={{
          marginLeft: 10,
          marginTop: 10,
          opacity: currentPosition == 'left' ? 1 : 0.5,
        }}
        variant='secondary'
        onClick={() => {
          sectionDetails.sectionPosition = 'left';
          if (isElementCase) {
            data[elementSectionKey].childSections[elementIndex] =
              sectionDetails;
          } else {
            data[elementSectionKey] = sectionDetails;
          }
          setSelectedLayoutData(data);
          setElementToEdit(sectionDetails);
        }}
        icon={
          <FaAlignLeft
            height={40}
            width={40}
            color={
              currentPosition == 'left'
                ? themes.colors.primary[100]
                : 'rgba(0, 27, 83)'
            }
          />
        }
      />

      <Button
        style={{
          marginLeft: 10,
          marginTop: 10,
          opacity: currentPosition == 'center' ? 1 : 0.5,
        }}
        variant='secondary'
        onClick={() => {
          sectionDetails.sectionPosition = 'center';
          if (isElementCase) {
            data[elementSectionKey].childSections[elementIndex] =
              sectionDetails;
          } else {
            data[elementSectionKey] = sectionDetails;
          }
          setSelectedLayoutData(data);
          setElementToEdit(sectionDetails);
        }}
        icon={
          <FaAlignCenter
            height={40}
            width={40}
            color={
              currentPosition == 'center'
                ? themes.colors.primary[100]
                : 'rgba(0, 27, 83)'
            }
          />
        }
      />
      <Button
        style={{
          marginLeft: 10,
          marginTop: 10,
          opacity: currentPosition == 'right' ? 1 : 0.5,
        }}
        variant='secondary'
        onClick={() => {
          sectionDetails.sectionPosition = 'right';
          if (isElementCase) {
            data[elementSectionKey].childSections[elementIndex] =
              sectionDetails;
          } else {
            data[elementSectionKey] = sectionDetails;
          }
          setSelectedLayoutData(data);
          setElementToEdit(sectionDetails);
        }}
        icon={
          <FaAlignRight
            height={40}
            width={40}
            color={
              currentPosition == 'right'
                ? themes.colors.primary[100]
                : 'rgba(0, 27, 83)'
            }
          />
        }
      />
    </Row>
  );
};
