import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'lib/style';
import { ModalVideoListVideo, Spinner } from 'lib/components';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';
import useVideoLoadRetry from 'lib/hooks/useVideoLoadRetry';
import useImageLoadRetry from 'lib/hooks/useImageLoadRetry';
import { EXTERNAL_VIDEO_PREFIX } from 'app/pages/record/const';
import { IoMdReorder } from 'react-icons/io';

const VideoWrapper = styled.div`
  background-color: ${theme.palette.themeDark};
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16 / 9;
  max-height: 80px;
  min-width: 140px;
  align-self: center;
  cursor: pointer;
  div {
    display: contents;
    opacity: 0;
    div {
      display: none;
      opacity: 0;
    }
    svg {
      display: none;
      opacity: 0;
    }
    path {
      display: none;
      opacity: 0;
    }
    figure {
      display: contents;
      opacity: 0;
    }
    video {
      display: contents;
      opacity: 0;
    }
    img {
      display: contents;
      opacity: 0;
    }
  }
`;

const VideoImg = styled.img`
  background-color: ${theme.palette.themeDark};
  display: block;
  height: 100%;
  margin: auto;
  border-radius: 6px;
`;

const Row = styled.div`
  padding-right: 32px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: #fff;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

type DragContainerProps = {
  disabled?: boolean;
};
const DragContainer = styled.div<DragContainerProps>`
  display: flex;
  justify-content: center;
  height: 80px;
  width: 48px;
  align-items: center;
  opacity: 0.8;
  &:hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'grab')};
    opacity: 1;
  }
`;

const PreviewVideo = styled.video`
  height: 80px;
  object-fit: contain;
  object-position: center;
  aspect-ratio: 16 / 9;
  max-height: 80px;
  min-width: 140px;
`;

const SpinnerWrapper = styled.div`
  background-color: ${theme.palette.themeDark};

  aspect-ratio: 16 / 9;
  max-height: 80px;
  min-width: 140px;
  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoThumbnail = ({
  video = {},
  setVideos = () => {},
  setVideoRef,
  index,
  dragDisabled,
}: any) => {
  const videoRef = React.createRef<HTMLVideoElement>();
  const previewVideoRef = React.createRef<HTMLVideoElement>();
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [openVideoPreviewModal, setOpenVideoPreviewModal] = useState(false);

  const isExternalVideo = video?.id.includes(EXTERNAL_VIDEO_PREFIX);

  const { videoData } = useVideoLoadRetry({
    videoId: video?.id || 0,
    enabled:
      !isExternalVideo && video?.processing === VideoEncodingStatus.PROCESSING,
  });

  const {
    imgRef,
    retryCount,
    error: imageLoadError,
  } = useImageLoadRetry({
    src: videoData?.autogeneratedThumbnail || video?.autoGeneratedGifPath,
  });

  const thumbnailAvailable =
    !imageLoadError &&
    retryCount === 0 &&
    (videoData?.autogeneratedThumbnail || video?.autoGeneratedGifPath);

  const onLoadedMetaData = () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      if (isFinite(duration)) {
        setVideoDuration(duration);
        return;
      }
      // fix for duration infinity
      videoRef.current.currentTime = 1e101;
      videoRef.current.ontimeupdate = function () {
        this.ontimeupdate = () => {
          return;
        };
        if (videoRef.current) {
          videoRef.current.currentTime = 0;
        }
      };
    }
  };

  // update duration when changed
  const onDurationChange = () => {
    if (videoRef.current && isFinite(videoRef.current.duration)) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  useEffect(() => {
    if (!videoDuration) {
      return;
    }
    setVideos((videos: any) => {
      const vtm = [...videos];
      vtm[index] = {
        ...vtm[index],
        duration: videoDuration,
        trimDuration: videoDuration,
      };
      return vtm;
    });
  }, [videoDuration]);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.onloadedmetadata = () => onLoadedMetaData();
      videoRef.current.ondurationchange = () => onDurationChange();
      setVideoRef(index, videoRef);
    }
  }, [videoRef]);

  const isFinishedProcessing =
    video.processing === VideoEncodingStatus.FAILED ||
    video.processing === VideoEncodingStatus.READY;

  return useMemo(
    () => (
      <>
        <Row key={video.id}>
          <DragContainer disabled={dragDisabled}>
            <IoMdReorder />
          </DragContainer>

          {isFinishedProcessing ? (
            <VideoWrapper onClick={() => setOpenVideoPreviewModal(true)}>
              {!isExternalVideo && thumbnailAvailable ? (
                imgRef.current ? (
                  <VideoImg ref={imgRef} />
                ) : (
                  <VideoImg src={video.autogeneratedThumbnail} />
                )
              ) : (
                <PreviewVideo src={video.videoSource} ref={previewVideoRef} />
              )}
              <video src={video.videoSource} ref={videoRef} />
            </VideoWrapper>
          ) : (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
        </Row>

        {openVideoPreviewModal && (
          <ModalVideoListVideo
            handleModalClose={() => setOpenVideoPreviewModal(false)}
            videoId=''
            source={video?.videoSource || ''}
          />
        )}
      </>
    ),
    [video.videoSource, openVideoPreviewModal, video.processing, videoData]
  );
};
