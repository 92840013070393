import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';
import VideoAttributeDetails from './VideoAttributeDetails/VideoAttributeDetails';
import VideoAttributeValueDetails from './VideoAttributeDetails/VideoAttributeValueDetails';
import { Container } from 'lib/components/styles/layout';
import styled from 'styled-components/macro';

const MainWrapper = styled(Container)`
  @media print {
    margin: 0;
    padding: 20px;
  }
`;

export const Main = () => {
  return (
    <MainWrapper>
      <Switch>
        <Route path="/reports/overview" component={List} exact={true} />
        <Route
          exact
          path="/reports/overview/video-attributes/:videoAttributeId"
          component={VideoAttributeDetails}
        />
        <Route
          exact
          path="/reports/overview/video-attributes/:videoAttributeId/:valueId"
          component={VideoAttributeValueDetails}
        />
        <Route path="/reports/overview/:reportType" component={Details} />
      </Switch>
    </MainWrapper>
  );
};
