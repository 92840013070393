import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';

export const Main = () => {
  return (
    <Switch>
      <Route path="/billing" component={List} exact={true} />
    </Switch>
  );
};
