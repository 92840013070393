import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation } from 'react-query';

const updateEmailReceiver = async ({
  receiverId,
  expirationDate,
}: {
  receiverId: string;
  expirationDate: Date | null;
}) => {
  const response = await EXPRESS_API.put(`/emailreceiver/${receiverId}`, {
    expirationDate,
  });
  return response.data;
};

export const useEmailReceiverMutation = () => {
  return useMutation(updateEmailReceiver, {
    onError: (err: AxiosError) => console.log(err),
    onSuccess: () => {},
  });
};
