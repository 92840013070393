import { Gap } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components/macro';

interface OptionLabelProps {
  value: number | string;
  label: string;
  username: string;
  lastName: string;
  firstName: string;
  email: string;
  customerId: number;
}

const CustomSpanStyles = styled.span<{ width: string; color: string }>`
  width: ${({ width }) => width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${({ color }) => color};
`;

export const CustomUserSelectionLabel = (user: OptionLabelProps) => {
  return (
    <Gap justifyContent='flex-start' alignItems='center'>
      {user.value === 'add' ? (
        <>
          <MdAdd color={theme.palette.primaryDarkBlue} />
          <CustomSpanStyles
            color={theme.palette.primaryDarkBlue}
            width={'auto'}
          >
            {user.label}
          </CustomSpanStyles>
        </>
      ) : (
        <>
          <CustomSpanStyles
            color={theme.palette.primaryDarkBlue}
            width={'150px'}
            style={{ flex: 1 }}
          >
            {user.firstName} {user.lastName}{' '}
          </CustomSpanStyles>
          <CustomSpanStyles
            color={theme.palette.primaryBlack40Percent}
            width={'250px'}
          >
            {user.username}{' '}
          </CustomSpanStyles>
          <CustomSpanStyles
            color={theme.palette.primaryBlack40Percent}
            width={'55px'}
            style={{ textAlign: 'right' }}
          >
            {user.value}{' '}
          </CustomSpanStyles>
        </>
      )}
    </Gap>
  );
};
