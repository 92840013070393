import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { useOnClickOutside } from 'lib/utils';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
  padding: 32px;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 0 32px;
  min-width: 800px;
  min-height: 300px;
`;

const TableHeader = styled.div`
  display: flex;
  border-bottom: 1px solid #eeeff2;
  padding: 16px 0;
`;

const RecHeaderTitle = styled.div`
  width: 30.5%;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  box-sizing: border-box;
`;

const RecipientItem = styled.div`
  display: flex;
`;

const RecipientValue = styled.div`
  width: 30%;
  height: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  padding: 16px 0;
  display: flex;
  div {
    margin-left: 24px;
  }
`;

export const ModalEmailBatchRecipients = ({
  emailBatch = {},
  handleModalClose = () => {},
}: any) => {
  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose && handleModalClose();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  return (
    <Modal>
      <div ref={ref}>
        <Header>
          <HeaderTitle>{`Batch ${emailBatch.subject} recipients`}</HeaderTitle>
          <CloseButtonWrap title={'Close recipients'}>
            <IoMdClose
              size={24}
              onClick={handleModalClose}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <TableHeader>
            <RecHeaderTitle style={{ paddingLeft: '24px' }}>
              Email
            </RecHeaderTitle>
            <RecHeaderTitle>Phone</RecHeaderTitle>
            <RecHeaderTitle>Status</RecHeaderTitle>
          </TableHeader>
          {emailBatch.recipients.map((r: any) => (
            <div key={r.email}>
              <RecipientItem>
                <RecipientValue style={{ paddingLeft: '16px' }}>
                  {r.email}
                </RecipientValue>
                <RecipientValue>{r.phone || '-'}</RecipientValue>
                <RecipientValue>{r.status || 'Unknown'}</RecipientValue>
              </RecipientItem>
            </div>
          ))}
        </Content>
      </div>
    </Modal>
  );
};
