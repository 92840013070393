import React from 'react';
import { TIMEFRAME } from './types';
import { Timeframe } from './timeframe/Timeframe';

interface IProps {
  updateRangeValue: (rangeValue: string) => void;
  currentRangeValue: string;
}

export const StaticCustomReport = ({
  currentRangeValue,
  updateRangeValue,
}: IProps) => {
  return (
    <Timeframe
      currentRangeValue={currentRangeValue}
      updateRangeValue={updateRangeValue}
      timeframeOptions={TIMEFRAME}
    />
  );
};
