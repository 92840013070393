import { QuoteItem } from 'lib/api/repairOrders/types';
import { Button } from 'react-covideo-common';
import { Dropdown } from 'lib/components';
import { RepairOrderStatus, VideoEmailStatus } from 'lib/const';
import { theme } from 'lib/style';
import { priceFormatter } from 'lib/utils/functions';
import React from 'react';
import styled from 'styled-components/macro';
import { HiOutlineCheck } from 'react-icons/hi';
import { FiCheck } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

const Container = styled.div`
  width: 800px;
  max-width: 100%;
  min-height: calc(100vh - 83px);
`;
type RowProps = {
  justifyContent?: string;
  margin?: string;
};
const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  margin: ${props => props.margin || '0'};
  justify-content: ${props => props.justifyContent || 'space-between'};
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 24px;
  color: ${theme.palette.gray80};
`;
const EditAnchor = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  color: ${props => props.theme.colors.primary[100]};
`;
const Quote = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 8px;
`;
type QuoteFieldProps = {
  width?: string;
  align?: string;
  strike?: string;
  background?: string;
};
const QuoteHeading = styled.div<QuoteFieldProps>`
  font-weight: 500;
  font-size: 14px;
  color: ${theme.palette.gray60};
  width: ${props => props.width || 'auto'};
  text-align: ${props => props.align || 'left'};
`;
const QuoteBody = styled.div<QuoteFieldProps>`
  width: ${props => props.width || 'auto'};
  text-align: ${props => props.align || 'left'};
  text-decoration-line: ${props => props.strike};
`;
const QuoteTitle = styled.div<QuoteFieldProps>`
  color: ${theme.palette.gray100};
  font-weight: 500;
  font-size: 14px;
`;
const QuoteApproved = styled.div<QuoteFieldProps>`
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: ${props => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const QuotePrice = styled.div<QuoteFieldProps>`
  font-weight: 500;
  font-size: 16px;
  color: ${theme.palette.gray80};
`;
const QuoteFooter = styled.div`
  padding: 8px;
  background: ${theme.palette.blue05};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p {
    margin: 0;
    color: ${theme.palette.covideoBlue100};
    font-weight: 600;
    font-size: 14px;
  }
`;
const Total = styled.div`
  text-align: right;
  color: ${theme.palette.covideoBlue100};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;
const Info = styled.div`
  margin-top: 16px;
  color: ${theme.palette.gray60};
  font-size: 14px;
  padding: 0 8px;
`;
const SelectWrapper = styled.div`
  width: 128px;
`;
const Textarea = styled.textarea`
  display: block;
  width: 100%;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray40};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  height: 120px;
  resize: none;
  outline: 0;
  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;

const columWidth = ['calc(100% - 220px)', '120px', '100px'];
const approvedOptions = [
  {
    label: 'Approved',
    value: true,
  },
  {
    label: 'Denied',
    value: false,
  },
];
type Props = {
  total: number;
  status: string;
  submitEditQuote: (quoteItems: QuoteItem[], note: string) => void;
  emailStatus: string;
  updatedQuoteItems: QuoteItem[];
  setUpdatedQuoteItems: any;
  note: string;
  setNote: (arg1: string) => void;
  isEditingQuote: boolean;
  setIsEditingQuote: (arg: boolean) => void;
  handleCancelClick: () => void;
};
export const QuoteList = ({
  total,
  status,
  submitEditQuote,
  emailStatus,
  note,
  setNote,
  updatedQuoteItems,
  setUpdatedQuoteItems,
  isEditingQuote,
  setIsEditingQuote,
  handleCancelClick,
}: Props) => {
  const isResponded = status === RepairOrderStatus.Responded;
  const handleChangeQuoteItem = (option: any, i: number, item: QuoteItem) => {
    setUpdatedQuoteItems((prev: QuoteItem[]) => {
      prev.splice(i, 1, { ...item, approved: option.value });
      return prev;
    });
  };

  return (
    <Container>
      <Row>
        <Title>Quote</Title>
        {!isEditingQuote && emailStatus !== VideoEmailStatus.Deleted && (
          <EditAnchor onClick={() => setIsEditingQuote(true)}>
            Edit item status
          </EditAnchor>
        )}
      </Row>
      <Quote>
        <QuoteHeading width={`${columWidth[0]}`}>Item</QuoteHeading>
        <QuoteHeading width={`${columWidth[1]}`} align={'center'}>
          <>Approved/Denied</>
        </QuoteHeading>
        <QuoteHeading width={`${columWidth[2]}`} align={'right'}>
          Price
        </QuoteHeading>
      </Quote>
      {updatedQuoteItems.map((item: QuoteItem, i: number) => (
        <Quote key={`quote-${i}`}>
          <QuoteBody width={`${columWidth[0]}`}>
            <QuoteTitle>
              {item.title}
              {item.required && ' *'}
            </QuoteTitle>
          </QuoteBody>
          {!isEditingQuote && isResponded && (
            <QuoteBody width={`${columWidth[1]}`} align={'center'}>
              <QuoteApproved
                background={
                  item.approved ? theme.palette.blue05 : theme.palette.red10
                }
              >
                {item.approved ? (
                  <HiOutlineCheck color={theme.palette.covideoBlue100} />
                ) : (
                  <MdClose color={theme.palette.buttonDelete} />
                )}
              </QuoteApproved>
            </QuoteBody>
          )}
          {!isEditingQuote && !isResponded && (
            <QuoteBody width={`${columWidth[1]}`} align={'center'} />
          )}
          {isEditingQuote && (
            <SelectWrapper>
              <Dropdown
                options={approvedOptions}
                defaultValue={approvedOptions.find(o => {
                  return o.value === updatedQuoteItems[i].approved;
                })}
                onChange={(option: any) =>
                  handleChangeQuoteItem(option, i, item)
                }
                value={undefined}
              />
            </SelectWrapper>
          )}
          <QuoteBody
            width={`${columWidth[2]}`}
            align={'right'}
            strike={isResponded && !item.approved ? 'line-through' : 'none'}
          >
            <QuotePrice>{priceFormatter(item.price || 0)}</QuotePrice>
          </QuoteBody>
        </Quote>
      ))}
      {isResponded && (
        <QuoteFooter>
          <p>Total Approved:</p>
          <Total style={{ width: `${columWidth[columWidth.length - 1]}` }}>
            {priceFormatter(total)}
          </Total>
        </QuoteFooter>
      )}
      <Info>* required items</Info>
      {isEditingQuote && (
        <>
          <Row margin='16px 0 0'>
            <Textarea
              value={note}
              onChange={(e: any) => setNote(e.target.value)}
              placeholder='Add a note'
            />
          </Row>
          <Row justifyContent='flex-end' margin='16px 0 0'>
            <Button
              text='Cancel'
              variant='secondary'
              onClick={() => {
                handleCancelClick();
                setIsEditingQuote(false);
              }}
              style={{ marginRight: '14px' }}
            />
            <Button
              text='Save item status'
              variant='primary'
              icon={<FiCheck />}
              onClick={() => submitEditQuote(updatedQuoteItems, note)}
            />
          </Row>
        </>
      )}
    </Container>
  );
};
