import styled, { css } from 'styled-components/macro';
import { Form } from 'formik';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const MainChatBox = styled.div`
  display: flex;
  width: 100%;
`;

export const UserListBox = styled.div`
  width: 100%;
  max-width: 296px;
  color: #001b5399;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  box-sizing: border-box;
  .divider {
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid #f6f7f9;
  }
`;

export const UserChatBox = styled.div`
  width: 100%;
  color: #001b5399;
  box-sizing: border-box;
  height: 100%;
  .divider {
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid #f6f7f9;
  }
`;

export const Messages = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding: 12px 24px;
  max-width: 320px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f4f6;
  cursor: pointer;
  position: relative;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #f2f4f6;
    `}
`;

export const ImageWrapper = styled.div<{ isUnread: boolean }>`
  position: relative;
  margin-top: 6px;
  ${({ isUnread, theme }) =>
    isUnread &&
    css`
      :after {
        content: '';
        width: 6px;
        height: 6px;
        background: ${theme.colors.primary[100]};
        position: absolute;
        border-radius: 6px;
        right: -4px;
        top: -2px;
      }
    `}
`;

export const RefreshIconWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: calc(50% - 8px);
`;

export const MessagesInfoWrapper = styled.div`
  overflow: hidden;
`;

export const MsgTitle = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #001b53;
  margin: 0;
`;

export const MsgTime = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

export const MsgData = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #001b53;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EmptyMsg = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

export const MessagesContainer = styled.div<{ type: string }>`
  padding: 0px 10px 20px 10px;
  display: flex;
  align-items: ${({ type }) =>
    type === 'inbound' ? 'flex-start' : 'flex-end'};
  flex-direction: column;
`;

export const ReceivedMessages = styled.div<{ type: string; resend?: boolean }>`
  padding: 12px 16px;
  max-width: 352px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 8px;
  ${({ type }) =>
    type !== 'inbound' &&
    css`
      background: rgba(0, 27, 83, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 8px 8px 0px 8px;
    `}
  ${({ type }) =>
    type === 'inbound' &&
    css`
      background: white;
      color: #001b53;
      border-radius: 8px 8px 8px 0px;
    `}
  ${({ resend }) =>
    !!resend &&
    css`
      opacity: 0.5;
    `}

    > img {
    max-width: 100%;
  }
`;

export const ReceivedMessageInfo = styled.div<{ type: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 27, 83, 0.6);
  text-align: ${({ type }) => (type !== 'inbound' ? 'right' : 'left')};
  width: 260px;
`;

export const FormStyles = styled(Form)`
  display: flex;
  margin-top: 12px;
  gap: 12px;
  > input {
    flex: 1;
  }
  > button {
    margin: 0;
    height: 42px;
  }
`;

export const AttachmentWrapper = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 27, 83, 0.4);
  border-radius: 6px;
  background: #f2f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  :hover > div {
    display: block;
  }
`;

export const NoMatchingConversations = styled.p`
  margin: 16px 0 0 0;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 27, 83, 0.6);
`;

export const ChatBody = styled.div<{ isEmailSelected: boolean }>`
  background: #f2f4f6;
  width: 100%;
  height: calc(100% - 72px - 62px);
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ isEmailSelected }) =>
    isEmailSelected &&
    css`
      height: calc(100% - 72px - 320px);
    `}
`;

export const ChatBodyWithOutData = styled.div<{
  withHeader?: boolean;
  isEmailSelected?: boolean;
}>`
  background: #f2f4f6;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  ${({ withHeader }) =>
    withHeader &&
    css`
      height: calc(100% - 72px - 62px);
    `}
  ${({ isEmailSelected }) =>
    isEmailSelected &&
    css`
      height: calc(100% - 72px - 320px);
    `}
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #f6f7f9;
  padding: 0px 16px 0px 24px;
  position: relative;
  justify-content: space-between;
  max-height: 72px;
  height: 100%;
  box-sizing: border-box;
`;

export const MainContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  margin-top: 64px;
  box-sizing: border-box;
  height: calc(100vh - 64px - 50px);
`;

export const SearchNewWrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 296px;
  position: fixed;
  background: white;
  padding: 16px 0;
  z-index: 2;
`;

export const ConversationDetails = styled.main`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  height: 100%;
  padding-left: 208px;
`;

export const NewMessageWrapper = styled.div`
  position: fixed;
  width: calc(100% - 800px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const NewMessage = styled.p`
  margin: 0;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.primary[100]};
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 16px;
`;
