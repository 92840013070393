import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CallsToAction from './CallsToAction';

export const Main = () => {
  return (
    <Switch>
      <Route
        path="/design/calls-to-action"
        component={CallsToAction}
        exact={true}
      />
    </Switch>
  );
};
