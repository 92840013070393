import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';
import { videoKeys } from './videosKeys';
import { useToastError } from 'lib/hooks';

type Props = {
  videoId: string;
  copy: boolean;
  videoFolder: string;
  successMessage?: string;
  itemId?: number;
};
const moveCopyVideo = async (data: Props) => {
  const { videoId, copy, videoFolder } = data;
  const response = await EXPRESS_API.put(`/videos/${videoId}/move`, {
    copy,
    videoFolder,
  });
  return response.data;
};
export const useVideoMoveCopy = () => {
  const queryClient = useQueryClient();
  const { showError } = useToastError();
  return useMutation(moveCopyVideo, {
    onSuccess: (_, variables: Props) => {
      successToast({
        title: variables?.successMessage || 'Videos copied/moved successfully.',
      });
      queryClient.invalidateQueries(videoKeys.inventoryItem(variables.itemId));
    },
    onError: error => {
      showError(error || 'An error occurred while moving/copying the videos.');
    },
  });
};
