import React, { Fragment } from 'react';
import { toMMSS } from 'lib/utils/functions';
import { SliderItem, GetHandleProps } from 'react-compound-slider';
import { theme } from 'lib/style';
import styled, { useTheme } from 'styled-components/macro';
type MainHandleProps = {
  percent: string;
  transform: string;
};
const MainHandle = styled.div<MainHandleProps>`
  left: ${props => props.percent};
  position: absolute;
  transform: ${props => props.transform};
  z-index: 400;
  width: 24px;
  height: 48px;
  cursor: pointer;
`;
type SliderProps = {
  percent: string;
  isDisabled?: boolean;
  transform: string;
  borderRadius: string;
};
const Slider = styled.div<SliderProps>`
  left: ${props => props.percent};
  background-color: ${props =>
    props.isDisabled ? '#666' : props.theme.colors.primary[100]};
  position: absolute;
  transform: ${props => props.transform};
  z-index: 2;
  width: 24px;
  height: 48px;
  border-radius: ${props => props.borderRadius};
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 12px;
    background: ${theme.palette.white};
    border-radius: 4px;
  }
`;
interface HandleProps {
  isActive: boolean;
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  disabled?: boolean;
  videoSource: string;
  videoRef: any;
  nChild: number;
  changeVideoPosition?: boolean;
  showFloatingPreview?: boolean;
}
export const Handle = (props: HandleProps) => {
  const {
    domain: [min, max],
    handle: { id, value, percent },
    showFloatingPreview = true,
    isActive,
    disabled,
    getHandleProps,
    videoRef,
    changeVideoPosition = true,
    nChild,
  } = props;
  const [mouseOver, setMouseOver] = React.useState(false);

  const previewvideoRef = React.createRef<HTMLVideoElement>();

  const onMouseEnter = () => {
    setMouseOver(true);
  };

  const onMouseLeave = () => {
    setMouseOver(false);
  };

  React.useEffect(() => {
    if (isActive) {
      if (showFloatingPreview) {
        previewvideoRef!.current!.currentTime = value ? value : 0;
      }
      if (changeVideoPosition) {
        videoRef!.current!.currentTime = value ? value : 0;
      }
    }
  }, [value, previewvideoRef, changeVideoPosition, videoRef]);

  const seconds = toMMSS(value).split('.')[0];
  const miliseconds = toMMSS(value).split('.')[1];
  const themes = useTheme();
  return (
    <Fragment>
      {showFloatingPreview && (
        <>
          {(mouseOver || isActive) && !disabled ? (
            <div
              style={{
                left: `${percent}%`,
                position: 'absolute',
                marginLeft: '-15px',
                marginTop: '52px',
                zIndex: 1,
              }}
            >
              <div
                style={{
                  width: 0,
                  height: 0,
                  marginLeft: 21,
                  borderLeft: '6px solid transparent',
                  borderRight: '6px solid transparent',
                  borderBottom: `6px solid `,
                }}
              />
              <div
                style={{
                  width: 40,
                  padding: 8,
                  fontSize: 12,
                  fontWeight: 500,
                  letterSpacing: 0.34,
                  backgroundColor: themes.colors.primary[100],
                  color: '#fff',
                  textAlign: 'center',
                }}
              >
                {seconds}
                <span
                  style={{
                    fontSize: 9,
                    fontWeight: 400,
                    letterSpacing: 0.34,
                    color: '#fff',
                    opacity: 0.7,
                  }}
                >
                  {'.' + miliseconds}
                </span>
              </div>
            </div>
          ) : null}
          <div
            style={{
              left: `${percent}%`,
              position: 'absolute',
              marginLeft: '-75px',
              marginTop: '-92px',
              zIndex: 3,
              visibility:
                (mouseOver || isActive) && !disabled ? 'visible' : 'hidden',
            }}
          >
            <div
              style={{
                width: 141,
                height: 80,
                border: `solid 2px ${themes.colors.primary[100]}`,
                backgroundColor: theme.palette.themeDark75Percent,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <video
                key='previewvideoRef'
                ref={previewvideoRef}
                height={'100%'}
                src={props.videoSource}
              />
            </div>
          </div>
        </>
      )}
      <MainHandle
        percent={`${percent}%`}
        {...getHandleProps(id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        transform={nChild === 0 ? 'translate(0px,0%)' : 'translate(-15px,0%)'}
      />
      <Slider
        role='slider'
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        percent={`${percent}%`}
        borderRadius={nChild === 0 ? '5px 0px 0px 5px;' : '0px 5px 5px 0px'}
        isDisabled={disabled}
        transform={nChild === 0 ? 'translate(0px,0%)' : 'translate(-15px,0%)'}
      />
    </Fragment>
  );
};
