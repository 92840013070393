import {
  ChildCustomer,
  ParentCustomer,
} from 'lib/api/multiLocations/multiLocationQuery';
import { TableField } from 'lib/context';

export const NEW_ML_USER_PREFIX = 'add';

export enum MultiLocationTableColumns {
  MAIN_CUSTOMER = 'customer',
  LOCATIONS = 'locations',
  USERS = 'users',
}

export enum MULTILOCATIONS_SORT_KEYS {
  CUSTOMER_NAME = 'CUSTOMER_NAME',
}

export const tableFields: TableField[] = [
  {
    value: MultiLocationTableColumns.MAIN_CUSTOMER,
    label: 'Main Customer',
    sortable: true,
    sortKey: MULTILOCATIONS_SORT_KEYS.CUSTOMER_NAME,
  },
  {
    value: MultiLocationTableColumns.LOCATIONS,
    label: 'Locations',
  },
  {
    value: MultiLocationTableColumns.USERS,
    label: 'Users',
  },
];

export const INITIAL_VALUES: ParentCustomer = {
  customerId: null,
  parentCustomerId: 0,
  business: null,
  users: [],
  location: {
    locationId: null,
    parentCustomerId: null,
    customerId: null,
    name: null,
  },
  childCustomers: [] as ChildCustomer[],
};
