import * as React from 'react';
import styled from 'styled-components/macro';
import { ColorResult } from 'react-color';
import { theme } from 'lib/style';
import { InvertColor } from 'lib/utils';
import { Analytics, Appearance, Captions, Details } from '.';
import { VideoAppearanceData, VideoData } from 'lib/hooks';
import dayjs from 'dayjs';
import { Route, Switch, useLocation } from 'react-router-dom';
import { SidebarNavigation } from 'lib/components/SidebarNavigation';
import { useAuth } from 'lib/context';
import { FaQuoteLeft, FaRegSmile } from 'react-icons/fa';
import { IoMdColorPalette, IoMdCut } from 'react-icons/io';
import { MdInsertChart, MdLock, MdTune } from 'react-icons/md';
import { VideoTrim } from './videoTrim/VideoTrim';
import { VideoAnnotations } from './videoAnnotations/VideoAnnotations';
import { calculateHexAlpha } from 'lib/utils/annotations';
import {
  productFeature,
  isVideoCountValid,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';
import { Security } from './Security';
import { useState } from 'react';
import { getVideosCount } from 'lib/api';
import { VideoReactions } from './videoReactions/VideoReactions';
import { NotFound } from 'app/pages/notFound/NotFound';
import { checkIfCustomerReactionsEnabled } from 'lib/utils/reactions';
import { MainWrapper } from 'lib/components/styles/layout';
import { VideoChapters } from './chapters/VideoChapters';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { GuideManagePage } from 'app/pages/guides/main/pages/GuideManagePage';
import { serverTimeToUserTime } from 'lib/utils/time';
import { useVideoRequestByVideoIdQuery } from 'lib/api/videoRequests/useVideoRequestByVideoIdQuery';
import { VideoEmailStatus } from 'lib/const';
import { CovideoIcon } from 'lib/components/CovideoIcon';

interface Props {
  videoAppearanceData: VideoAppearanceData;
  videoDetails: VideoData;
  openVideoDeleteModal: Function;
  view?: string;
  setVideoIdState: (videoId: string) => void;
  setReloadVideoDetails: Function;
}
const Container = styled.div`
  padding-left: 208px;
`;

type SidebarItemType = {
  isDisabled?: boolean;
  id: number;
  title: string;
  icon: JSX.Element;
  path: string;
  isPaid: boolean;
  paidUrl: string;
  additionalIcon?: JSX.Element;
  productFeatureId?: number;
};

const SidebarItemOptions = {
  MANAGE: { title: 'Manage', id: 1 },
  APPEARANCE: { title: 'Appearance', id: 2 },
  TRIMMING: { title: 'Trimming', id: 3 },
  ANNOTATIONS: { title: 'Annotations', id: 4 },
  CAPTIONS: { title: 'Captions', id: 5 },
  ANALYTICS: { title: 'Analytics', id: 6 },
  SECURITY: { title: 'Security', id: 7 },
  REACTIONS: { title: 'Reactions', id: 8 },
  CHAPTERS: { title: 'Chapters', id: 9 },
  REACTIONS_FREEMIUM: { title: 'Reactions', id: 10 },
};

export const Main = ({
  videoDetails,
  view,
  videoAppearanceData,
  openVideoDeleteModal,
  setVideoIdState,
  setReloadVideoDetails,
}: Props): React.ReactElement => {
  const {
    userData,
    userData: { user },
  } = useAuth();
  const location = useLocation();
  const { isAutomotive } = userData;
  const reactionsEnabled = checkIfCustomerReactionsEnabled(userData);
  const isGuide = !!videoDetails.isGuide;

  const { data: videoRequest = {} } = useVideoRequestByVideoIdQuery(
    videoDetails?.id || '',
    isAutomotive
  );

  const iconSize = '24px';
  let items = [
    {
      isDisabled: false,
      id: SidebarItemOptions.MANAGE.id,
      title: SidebarItemOptions.MANAGE.title,
      icon: <MdTune size={iconSize} />,
      path: `/classic/edit-video/${videoDetails.id}/details`,
      isPaid: false,
      paidUrl: '',
    },
    {
      isDisabled: false,
      id: SidebarItemOptions.APPEARANCE.id,
      title: SidebarItemOptions.APPEARANCE.title,
      icon: <IoMdColorPalette size={iconSize} />,
      path: `/classic/edit-video/${videoDetails.id}/appearance`,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
      productFeatureId: productFeature.VIDEO_PLAYER_APPEARANCE,
    },
    {
      id: SidebarItemOptions.TRIMMING.id,
      title: SidebarItemOptions.TRIMMING.title,
      icon: <IoMdCut size={iconSize} />,
      path: `/classic/edit-video/${videoDetails.id}/trim`,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
    },
    {
      isDisabled: false,
      id: SidebarItemOptions.ANNOTATIONS.id,
      title: SidebarItemOptions.ANNOTATIONS.title,
      icon: (
        <CovideoIcon name='annotations' width={iconSize} height={iconSize} />
      ),
      path: `/classic/edit-video/${videoDetails.id}/annotations`,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
      productFeatureId: productFeature.ANNOTATIONS,
    },
    {
      isDisabled: false,
      id: SidebarItemOptions.CAPTIONS.id,
      title: SidebarItemOptions.CAPTIONS.title,
      icon: <FaQuoteLeft size={16} style={{ padding: '0px 4px' }} />,
      path: `/classic/edit-video/${videoDetails.id}/captions`,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
      productFeatureId: productFeature.CAPTIONS,
    },
    {
      id: SidebarItemOptions.CHAPTERS.id,
      title: 'Chapters',
      isDisabled: false,
      icon: <CovideoIcon name='chapters' width={iconSize} height={iconSize} />,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      path: `/classic/edit-video/${videoDetails.id}/chapters`,
      isPaid: false,
      paidUrl: '',
      productFeatureId: productFeature.CHAPTERS,
    },
  ];
  if (!isGuide) {
    const analytics = {
      isDisabled: false,
      id: SidebarItemOptions.ANALYTICS.id,
      title: SidebarItemOptions.ANALYTICS.title,
      icon: <MdInsertChart size={'26px'} />,
      path: `/classic/edit-video/${videoDetails.id}/analytics`,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
      productFeatureId: productFeature.HOTSPOTS_AND_ANALYTICS,
    };
    const security = {
      isDisabled: false,
      id: SidebarItemOptions.SECURITY.id,
      title: SidebarItemOptions.SECURITY.title,
      icon: <MdLock size={'26px'} />,
      path: `/classic/edit-video/${videoDetails.id}/security`,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
      productFeatureId: productFeature.SECURITY,
    };
    items.splice(5, 0, analytics);
    items.splice(6, 0, security);
  }

  if (reactionsEnabled && !isGuide) {
    const reactionsSidebarItem = {
      isDisabled: false,
      id: SidebarItemOptions.REACTIONS.id,
      title: SidebarItemOptions.REACTIONS.title,
      icon: <FaRegSmile size={iconSize} />,
      path: `/classic/edit-video/${videoDetails.id}/reactions`,
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
      productFeatureId: productFeature.VIDEO_REPLY,
    };
    items = [...items, reactionsSidebarItem];
  }
  // add disabled reactions tab for freemium
  if (!reactionsEnabled && user?.packageDetails?.id === 1 && !isGuide) {
    const reactionsFreemiumSidebarItem = {
      isDisabled: true,
      id: SidebarItemOptions.REACTIONS_FREEMIUM.id,
      title: SidebarItemOptions.REACTIONS_FREEMIUM.title,
      icon: <FaRegSmile size={iconSize} />,
      path: '',
      additionalIcon: <MdLock size={18} color={'#001B53'} />,
      isPaid: false,
      paidUrl: '',
      productFeatureId: 0,
    };
    items = [...items, reactionsFreemiumSidebarItem];
  }

  const [sidebarItems, setSideBarItems] = useState<SidebarItemType[]>(items);

  const [_, setIsSecurityEnabled] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [totalVideoCount, setTotalVideoCount] = React.useState(0);

  React.useEffect(() => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    getVideosCount()
      .then(result => {
        setIsFetching(false);
        setTotalVideoCount(result.count);
      })
      .catch(() => setIsFetching(false));
  }, [userData]);

  React.useEffect(() => {
    if (!user?.packageDetails?.id) {
      return;
    }
    const temp = [
      {
        isDisabled: !checkIfFeatureIsEnabled(
          userData,
          productFeature.VIDEO_PLAYER_APPEARANCE
        ),
        id: SidebarItemOptions.APPEARANCE.id,
      },
      {
        isDisabled: !checkIfFeatureIsEnabled(
          userData,
          productFeature.ANNOTATIONS
        ),
        id: SidebarItemOptions.ANNOTATIONS.id,
      },
      {
        isDisabled: !checkIfFeatureIsEnabled(userData, productFeature.CAPTIONS),
        isPaid: false,
        paidUrl: '/profile/add-ons/captions/manage',
        id: SidebarItemOptions.CAPTIONS.id,
      },
      {
        isDisabled: !checkIfFeatureIsEnabled(
          userData,
          productFeature.HOTSPOTS_AND_ANALYTICS
        ),
        id: SidebarItemOptions.ANALYTICS.id,
      },
      {
        isDisabled: !checkIfFeatureIsEnabled(
          userData,
          productFeature.PASSWORD_PROTECTION
        ),
        id: SidebarItemOptions.SECURITY.id,
      },
      {
        isDisabled: !checkIfFeatureIsEnabled(
          userData,
          productFeature.VIDEO_REPLY
        ),
        id: SidebarItemOptions.REACTIONS.id,
      },
      {
        isDisabled: !isVideoCountValid(userData, totalVideoCount, true),
        id: SidebarItemOptions.TRIMMING.id,
      },
      {
        isDisabled: !checkIfFeatureIsEnabled(userData, productFeature.CHAPTERS),
        id: SidebarItemOptions.CHAPTERS.id,
      },
    ];
    setIsSecurityEnabled(
      !checkIfFeatureIsEnabled(userData, productFeature.PASSWORD_PROTECTION)
    );

    const updateSideNav = sidebarItems.map(item => {
      const item2 = temp.find(i2 => i2.id === item.id);
      return item2 ? { ...item, ...item2 } : item;
    });

    setSideBarItems(updateSideNav);
  }, [user, totalVideoCount]);

  React.useEffect(() => {
    if (videoRequest?.emailStatus === VideoEmailStatus.Deleted) {
      setSideBarItems(prev =>
        prev.filter(item => item.id === 1 || item.id === 6)
      );
    }
  }, [videoRequest]);

  const defaultPlayerBackgroundColor =
    videoDetails.playerBackgroundColor ||
    videoAppearanceData.playerBackgroundColor ||
    theme.palette.blue80;
  const defaultPlayerIconsAndTextColor =
    videoDetails.playerIconsAndTextColor ||
    videoAppearanceData.playerIconsAndTextColor ||
    theme.palette.white;
  const defaultPlayButtonPosition =
    videoDetails.playButtonPosition || videoAppearanceData.playButtonPosition;
  const { personalThumbnail, autogeneratedThumbnail } = videoDetails;
  const thumbnail = personalThumbnail
    ? personalThumbnail
    : autogeneratedThumbnail;
  const [newColor, setNewColor] = React.useState(defaultPlayerBackgroundColor);
  const [iconColor, setIconColor] = React.useState(
    defaultPlayerIconsAndTextColor
  );
  const [newPlayerButtonPosition, setPlayerPosition] = React.useState(
    defaultPlayButtonPosition
  );

  const clientTime = serverTimeToUserTime(
    videoDetails.recordDate,
    userData,
    `${STANDARD_DATE_FORMAT} hh:mm:ss A`,
    false
  );

  const [newThumbnail, setNewThumbnail] = React.useState(thumbnail);
  const [isDirty, setIsDirty] = React.useState(false);
  const [videoData, setVideoData] = React.useState<VideoData>(videoDetails);
  const date = dayjs(new Date(clientTime));
  const dateOfCreation = date.format(STANDARD_DATE_FORMAT);
  const hourOfCreation = date.format('hh:mm:ss A');
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const handleChangePlayerBackgroundColor = (color: ColorResult) => {
    const hexAlpha: string = calculateHexAlpha(color);
    setNewColor(hexAlpha);

    const isBlack = InvertColor(color.hex);

    if (isBlack !== iconColor) {
      setIconColor(isBlack);
    }
  };

  const saveVideoTitle = (videoTitle: string) => {
    setVideoData({ ...videoDetails, title: videoTitle });
  };

  const handleChangeIconColor = (color: string) => {
    setIconColor(color);
  };

  const handleChangeThumbnail = (media: string) => {
    setNewThumbnail(media);
  };

  const handlePlayerSettings = (position: string) => {
    setPlayerPosition(position);
  };

  const saveAppearance = () => {
    setIsDirty(false);
  };

  const checkIfDirty = () => {
    setIsDirty(
      newPlayerButtonPosition !== defaultPlayButtonPosition ||
        iconColor !== defaultPlayerIconsAndTextColor ||
        newColor !== defaultPlayerBackgroundColor
    );
  };

  const resetForm = () => {
    setPlayerPosition(defaultPlayButtonPosition);
    setIconColor(defaultPlayerIconsAndTextColor);
    setNewColor(defaultPlayerBackgroundColor);
  };

  React.useEffect(
    () => checkIfDirty(),
    [newPlayerButtonPosition, newColor, iconColor]
  );

  const lastItem = location.pathname.split('/').pop() || '';

  const hasHelpDesk = !![
    'trim',
    'annotations',
    'captions',
    'analytics',
    'security',
    'reactions',
  ].find(item => item.includes(lastItem));

  return (
    <>
      <SidebarNavigation items={sidebarItems} />
      <Container>
        <Switch>
          <Route
            path='/classic/edit-video/:videoId/details'
            render={props => (
              <MainWrapper resetPadding={hasHelpDesk}>
                {isGuide ? (
                  <GuideManagePage />
                ) : (
                  <Details
                    {...props}
                    videoId={videoDetails.id}
                    wtvId={videoDetails.wtvId}
                    videoTitle={videoData.title}
                    reactionsPreference={!!videoDetails.reactionsPreference}
                    videoReplyPreference={!!videoDetails.videoReplyEnabled}
                    dateOfCreation={dateOfCreation}
                    hourOfCreation={hourOfCreation}
                    openVideoDeleteModal={openVideoDeleteModal}
                    setVideoIdState={setVideoIdState}
                    personalThumbnail={newThumbnail}
                    videoSource={videoDetails.videoSource}
                    playButtonPosition={newPlayerButtonPosition}
                    playerBackgroundColor={newColor}
                    playerIconsAndTextColor={iconColor}
                    videoRequest={videoRequest}
                    saveVideoTitle={saveVideoTitle}
                  />
                )}
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/appearance'
            render={props => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <Appearance
                  {...props}
                  playerBackgroundColor={newColor}
                  handleChangePlayerBackgroundColor={
                    handleChangePlayerBackgroundColor
                  }
                  videoTitle={videoData.title}
                  playerIconsColor={iconColor}
                  handleChangeIconColor={handleChangeIconColor}
                  playButtonPosition={newPlayerButtonPosition}
                  handlePlayerSettings={handlePlayerSettings}
                  personalThumbnail={newThumbnail}
                  videoId={videoDetails.id}
                  videoAppearanceData={videoAppearanceData}
                  handleChangeThumbnail={handleChangeThumbnail}
                  videoSource={videoDetails.videoSource}
                  videoRef={videoRef}
                  isDirty={isDirty}
                  onSave={saveAppearance}
                  resetForm={resetForm}
                  isGuide={isGuide}
                  setVideoData={setVideoData}
                />
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/trim'
            render={() => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <VideoTrim
                  videoDetails={videoData}
                  setReloadVideoDetails={setReloadVideoDetails}
                />
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/chapters'
            render={() => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <VideoChapters
                  videoDetails={videoData}
                  setVideoData={setVideoData}
                  resetForm={resetForm}
                />
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/annotations'
            render={() => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <VideoAnnotations videoDetails={videoData} />
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/captions'
            render={props => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <Captions
                  {...props}
                  key={view}
                  videoTitle={videoData.title}
                  videoId={videoData.id}
                  playButtonPosition={videoData.playButtonPosition}
                  personalThumbnail={videoData.personalThumbnail}
                  playerBackgroundColor={videoData.playerBackgroundColor}
                  playerIconsAndTextColor={videoData.playerIconsAndTextColor}
                  videoSource={videoData.videoSource}
                />
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/analytics'
            render={props => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <Analytics
                  {...props}
                  key={view}
                  video={videoData}
                  videoId={videoDetails.id}
                />
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/security'
            render={props => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <Security
                  {...props}
                  key={view}
                  videoTitle={videoData.title}
                  playButtonPosition={videoData.playButtonPosition}
                  personalThumbnail={videoData.personalThumbnail}
                  playerBackgroundColor={videoData.playerBackgroundColor}
                  playerIconsAndTextColor={videoData.playerIconsAndTextColor}
                  videoSource={videoData.videoSource}
                  videoId={videoData.id}
                  passwordEnabled={videoData.protected?.toString() === '1'}
                />
              </MainWrapper>
            )}
          />
          <Route
            path='/classic/edit-video/:videoId/reactions'
            render={() => (
              <MainWrapper resetPadding={hasHelpDesk}>
                <VideoReactions videoDetails={videoData} />
              </MainWrapper>
            )}
          />
          <Route path='*' render={() => <NotFound />} />
        </Switch>
      </Container>
    </>
  );
};
