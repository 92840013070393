import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { VIDEO_RECIPIENTS } from '../recipients/recipientsKeys';

export type UpdateVideoViewNotificationsParams = {
  videoId: number;
  notify: boolean;
};

export const updateVideoViewNotifications = async ({
  videoId,
  notify,
}: UpdateVideoViewNotificationsParams) => {
  const data = {
    notify,
  };
  const response = await EXPRESS_API.put(
    `/videos/${videoId}/notification`,
    data
  );
  return response.data;
};

export const useUpdateVideoViewNotificationsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateVideoViewNotifications, {
    onSuccess: (_, variables: UpdateVideoViewNotificationsParams) => {
      queryClient.invalidateQueries([VIDEO_RECIPIENTS, variables.videoId]);
      successToast({
        title: 'Video notifications updated successfully!',
      });
    },
    onError: (_, variables: UpdateVideoViewNotificationsParams) => {
      queryClient.invalidateQueries([VIDEO_RECIPIENTS, variables.videoId]);
      errorToast({
        title: 'Error in updating video notifications',
      });
    },
  });
};
