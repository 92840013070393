import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CallToActionForm, CovideoUseOptions, Header } from '../components';
import styled from 'styled-components/macro';
import { MainContainer } from 'lib/components';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { updateOnboardingStatus } from 'lib/api';
import { errorToast } from 'lib/components/toasts/error';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

type CTA = {
  id: number;
  actionText: string;
  url: string;
  isSaved: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1032px;
  min-height: 664px;
  background-color: #fff;
  border: 1px solid #dde1e8;
  margin: 16px 0 0 0;
`;

const Title = styled.h1`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${theme.palette.primaryDarkBlue};
`;

const SubTitle = styled.p`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  color: ${theme.palette.black_1_100};
  font-size: 16px;
  max-width: 600px;
  text-align: center;
`;

const PresentationVideo = styled(VideoPlayer)`
  margin-top: 40px;
  figure {
    width: 100%;
    max-width: 636px;
    min-height: 344px;
  }
`;

export const Main = () => {
  const [step, setStep] = React.useState(1);
  const [ctas, setCtas] = React.useState([
    { id: 1, actionText: '', url: '', isSaved: false },
  ] as CTA[]);
  const history = useHistory();
  const { userData, whitelabel } = useAuth();
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    const setOnboardingAsDone = async () => {
      const res = await updateOnboardingStatus(userData.id).catch(err => err);
      if (res instanceof Error) {
        process.env.NODE_ENV === 'development' &&
          errorToast({
            title:
              'Error setting onboarding as done in the first login of the user!',
          });
      }
    };
    setOnboardingAsDone();
  }, []);

  const handleNextStep = async () => {
    history.push('/home');
    /* TODO: REENABLE WHEN BACKEND ISSUE SAVING EXIT ILNKS (CTAS) IS FIXED
    if (step < 3) {
      setStep(step + 1);
    } else {
      const res = await updateOnboardingStatus({ finished: true }).catch(
        err => err
      );
      if (res instanceof Error) {
        toast({
          message:
            'An error occurred finishing your onboarding, please contact support!',
        });
      } else {
        history.push('/home');
      }
    }
    */
  };
  /* TODO: REENABLE WHEN BACKEND ISSUE SAVING EXIT ILNKS (CTAS) IS FIXED
  React.useEffect(() => {
    if (
      step > 2 &&
      ctas.some(cta => cta.actionText !== '' && cta.url !== '' && !cta.isSaved)
    ) {
      saveCtas(ctas, setCtas);
    } else {
      saveCtas.cancel();
    }
  }, [step]);
*/
  return (
    <MainContainer>
      <Container>
        <Header step={step} handleNextStep={handleNextStep} setStep={setStep} />
        <Title>
          {step === 1 &&
            `Welcome to ${whitelabel.name}${
              userData.firstName ? `, ${userData.firstName}` : ''
            }`}
          {step === 2 && 'Add Call to Action'}
          {step === 3 && `How would you like to use ${whitelabel.name}?`}
        </Title>
        <SubTitle>
          {step === 1 &&
            'Follow the prompt below so we can prepare the best experience for you.'}
          {step === 2 &&
            'CTAs encourage your viewers to take inmediate action. Fill in a few CTAs below - you can change them later under Design > Calls-to-action'}
          {step === 3 &&
            'Select one or multiple ways to record, send and track videos.'}
        </SubTitle>
        {step === 1 && whitelabel.name === WHITELABEL_NAME.COVIDEO ? (
          <PresentationVideo
            playerBackgroundColor={theme.palette.primaryDarkBlue}
            videoSource='https://videos.covideo.com/videos/19410_3_5ffef87b840a41610545418.mp4'
            playButtonPosition={'none'}
            playerIconsColor={'#fff'}
            thumbnail='https://video.covideo.com/thumbnails/19416_3_6dgky0o0gd1615393521_0001.png'
            videoRef={videoRef}
          />
        ) : (
          <p>Welcome!</p>
        )}
        {step === 2 && <CallToActionForm ctas={ctas} setCtas={setCtas} />}
        {step === 3 && <CovideoUseOptions />}
      </Container>
    </MainContainer>
  );
};
