import React from 'react';
import { ModalHeader, Title } from './styles';
import { MdClose, MdArrowBack, MdArrowForward, MdSave } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues, STEPS } from './types';
import { IoMdCheckmark } from 'react-icons/io';
import { Gap } from 'lib/components/styles/layout';

interface IProps {
  handleModalClose: () => void;
  goToPreviousStep: () => void;
  goToNextStep: () => void | ((() => Promise<void>) & (() => Promise<any>));
  currentStepIndex: number;
  isEdit: boolean;
  isLoading: boolean;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
  steps: STEPS;
}

export const CustomReportModalHeader = ({
  handleModalClose,
  goToPreviousStep,
  goToNextStep,
  currentStepIndex,
  isEdit,
  isLoading,
  submitForm,
  steps,
}: IProps) => {
  const { errors, dirty } = useFormikContext<CustomReportModalFormikValues>();

  const getStepsState = () => {
    const errorKey = steps?.[currentStepIndex]
      ?.errorName as keyof CustomReportModalFormikValues;
    const lastStepIndex = steps.length - 1;

    const forward = {
      disabled: !!errors[errorKey],
      title: (errors?.[errorKey] as string) || '',
    };

    if (currentStepIndex === 0) {
      return {
        back: {
          disabled: true,
          title: 'No previous step to go back to!',
        },
        forward,
      };
    }

    if (currentStepIndex === lastStepIndex) {
      return {
        forward: {
          disabled: true,
          title: 'There are no more steps to proceed to.',
        },
      };
    }
    return {
      forward,
    };
  };

  const isSubmitDisabled = !!Object.keys(errors).length || isLoading || !dirty;

  const getSubmitTitleMessage = () => {
    if (errors.users) {
      return errors.users as string;
    }
    if (errors.reports) {
      return errors.reports as string;
    }
    if (errors.title) {
      return errors.title as string;
    }
  };

  const { forward, back } = getStepsState() || {};
  const submitTitle = getSubmitTitleMessage();

  return (
    <ModalHeader>
      <Button
        variant='transparent'
        onClick={handleModalClose}
        icon={<MdClose size='22' />}
        style={{ position: 'absolute', left: 32 }}
      />

      <Title>{isEdit ? 'Edit' : 'Create'} custom report</Title>
      <Gap gap='8px' style={{ position: 'absolute', right: 32 }}>
        <Button
          onClick={goToPreviousStep}
          icon={<MdArrowBack size='18' />}
          variant='secondary'
          disabled={isLoading || back?.disabled}
          title={back?.title}
        />
        <Button
          onClick={goToNextStep}
          icon={<MdArrowForward size='18' />}
          variant='secondary'
          disabled={isLoading || forward?.disabled}
          text='Next'
          iconPosition='right'
          title={forward?.title}
        />
        <Button
          onClick={submitForm}
          text={isEdit ? 'Save Changes' : 'Create Report'}
          disabled={isSubmitDisabled}
          icon={isEdit ? <MdSave size='18' /> : <IoMdCheckmark size='18' />}
          title={submitTitle}
        />
      </Gap>
    </ModalHeader>
  );
};
