import React, { ReactNode } from 'react';
import { theme } from 'lib/style';
import { MdUndo } from 'react-icons/md';
import { IoReorderThree } from 'react-icons/io5';

import { Order, ReorderCellWrapper } from '../styles';
import { VdpItem } from 'lib/api';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import dayjs from 'dayjs';
import { VDP_IntegrationTypes } from 'lib/const/vdp';
import { getDisplayName } from 'lib/utils/functions';
import { Button } from 'react-covideo-common';
import { FcCheckmark } from 'react-icons/fc';

export type TableRow = {
  id: number;
  videoTitle: string;
  recordedBy: string;
  pushedBy: string;
  vin: string;
  createdAt: string;
  vehicleTitle: string;
  buttons: ReactNode;
  reorder?: ReactNode;
  videoId: number;
  integrationType: string;
};

type TableColumn = {
  value: string;
  label: string;
  padding: string;
  sortable: boolean;
  align?: string;
};

const MAX_VDP_ALLOWED = 0;

type CellButtonsProps = {
  handlePullClick: () => void;
};

const CellButtons = ({ handlePullClick }: CellButtonsProps) => {
  return (
    <Button
      variant='destructive'
      icon={<MdUndo color={theme.palette.red100} />}
      onClick={handlePullClick}
      text='Pull'
    />
  );
};

type ReorderProps = {
  order: number;
  setTooltipIndex: (arg1: number) => void;
};
const CellReorder = ({ order, setTooltipIndex }: ReorderProps) => {
  return (
    <ReorderCellWrapper
      onMouseOver={() => setTooltipIndex(order - 1)}
      onMouseOut={() => setTooltipIndex(-1)}
    >
      <IoReorderThree color={theme.palette.gray60} size={24} />
      <Order>{order}</Order>
      <FcCheckmark
        style={{ opacity: MAX_VDP_ALLOWED && order <= MAX_VDP_ALLOWED ? 1 : 0 }}
      />
    </ReorderCellWrapper>
  );
};
export const tableCols: TableColumn[] = [
  {
    value: 'reorder',
    label: '#',
    padding: '0 8px 0 0',
    sortable: false,
    align: 'center',
  },
  {
    value: 'videoTitle',
    label: 'Video Title',
    padding: '0 8px 0 0',
    sortable: false,
  },
  {
    value: 'recordedBy',
    label: 'Recorded by',
    padding: '0 8px 0 0',
    sortable: false,
  },
  {
    value: 'vin',
    label: 'VIN',
    padding: '0 8px 0 0',
    sortable: false,
  },
  {
    value: 'createdAt',
    label: 'Pushed on',
    padding: '0 8px 0 0',
    sortable: true,
  },
  {
    value: 'integrationType',
    label: 'Destination',
    padding: '0',
    sortable: false,
  },
  {
    value: 'buttons',
    label: '',
    padding: '0',
    sortable: false,
  },
];

export const formatTableRows = (
  vdps: VdpItem[],
  setTooltipIndex: (arg: number) => void,
  setVdpToPull: (vdp: VdpItem) => void
): TableRow[] =>
  vdps.map((vdp, index) => ({
    id: vdp.id,
    videoTitle: vdp?.video?.title || '',
    recordedBy: vdp?.video?.user
      ? `${vdp.video.user.firstName || ''} ${vdp.video.user.lastName || ''}`
      : '',
    pushedBy: vdp?.user
      ? `${vdp.user.firstName || ''} ${vdp.user.lastName || ''}`
      : '',
    vin: vdp?.vin || '',
    vehicleTitle: vdp?.inventoryItem
      ? getDisplayName([
          vdp.inventoryItem.make,
          vdp.inventoryItem.model,
          vdp.inventoryItem.year,
        ])
      : '',
    createdAt: vdp?.createdAt
      ? dayjs(vdp.createdAt).format(`${STANDARD_DATE_FORMAT} HH:mm A`)
      : '',
    buttons: <CellButtons handlePullClick={() => setVdpToPull(vdp)} />,
    reorder: (
      <CellReorder order={index + 1} setTooltipIndex={setTooltipIndex} />
    ),
    videoId: vdp.videoId,
    integrationType: VDP_IntegrationTypes[vdp.integrationType]?.label || '',
  }));
