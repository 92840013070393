import { NewModal } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import {
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { ReactComponent as VisibilityOverlay } from 'assets/icons/visibility/overlay.svg';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import howToShareOne from '../assets/images/how-to-share-1.png';
import howToShareTwo from '../assets/images/how-to-share-2.png';
import { HOW_TO_SHARE_MODAL } from '../const';
import { Button } from 'react-covideo-common';

const Content = styled.div`
  border-radius: 8px;
  border: 1px solid #eeeff2;
  background: #fafafc;
  padding: 32px 16px 0 16px;
  position: relative;
`;
const Overlay = styled.div`
  height: 48px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

interface IProps {
  closeModalHandler: () => void;
}

const SpanNormalBold = styled(ParagraphNormalBold).attrs({ as: 'span' })``;

export const HowToShareModal = ({ closeModalHandler }: IProps) => {
  useEffect(() => {
    localStorage.setItem(HOW_TO_SHARE_MODAL, 'visited');
  }, []);

  return (
    <NewModal
      headerText='How To share Training Videos'
      closeModal={closeModalHandler}
      style={{
        content: {
          maxWidth: 1012,
        },
      }}
    >
      <Content>
        <Gap gap='36px' justifyContent='space-between'>
          <Gap flexDirection='column'>
            <ParagraphSmall textAlign='center'>
              Choose &lt;All&gt; if you want to share a <br />
              training video with{' '}
              <SpanNormalBold as='span'>
                all current and new users.
              </SpanNormalBold>
            </ParagraphSmall>
            <img src={howToShareOne} alt='how to share' />
          </Gap>
          <Gap flexDirection='column'>
            <ParagraphSmall textAlign='center'>
              Choose &lt;Manually&gt; to
              <SpanNormalBold> select individual users. </SpanNormalBold>
              <br /> New users should be added manually.
            </ParagraphSmall>
            <img src={howToShareTwo} alt='how to share' />
          </Gap>
        </Gap>
        <Overlay>
          <VisibilityOverlay />
        </Overlay>
      </Content>
      <Gap width='100%' justifyContent='flex-end' m='32px 0 0 0'>
        <Button
          text='I understand'
          onClick={closeModalHandler}
          variant='primary'
        />
      </Gap>
    </NewModal>
  );
};
