// Websites with regex generators:
// https://ihateregex.io
// https://regexr.com/

export const URL_REGEX =
  /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;
export const NAME_REGEX = /[a-zA-Z0-9\.\-\_\s<>!@]{1,24}$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export const PASSWORD_REGEX =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/;

// Common import regexes
export const FIRST_NAME_REG = new RegExp('first|^name$', 'ig');
export const LAST_NAME_REG = new RegExp('last|surname', 'ig');
export const USER_NAME_REG = new RegExp('username', 'ig');
export const PASSWORD_REG = new RegExp('password', 'ig');
export const EMAIL_REG = new RegExp('email', 'ig');
export const PHONE_REG = new RegExp('phone', 'ig');

// User import regexes
export const TITLE_REG = new RegExp('title', 'ig');
export const DEPARTMENT_REG = new RegExp('dept|department', 'ig');
export const ADMIN_REG = new RegExp('admin', 'ig');
export const AUTOMOTIVE_ROLE_REG = new RegExp('automotive|role', 'ig');
export const NMLS_REG = new RegExp('nmls', 'ig');
export const USER_TYPE_REG = new RegExp('user type', 'ig');

export const VERIFIED_REG = new RegExp('active', 'ig');
export const CAPTION_REG = new RegExp('enable captions', 'ig');
export const MOBILE_SMS_REG = new RegExp('mobile sms', 'ig');

// Customer import regexes
export const CUSTOMER_FIRST_NAME_REG = new RegExp('first name', 'ig');
export const BUSINESS_REG = new RegExp('business|name', 'ig');
export const RESELLER_REG = new RegExp('reseller', 'ig');
export const URL_REG = new RegExp('url', 'ig');
export const PACKAGE_REG = new RegExp('package', 'ig');
export const ACCOUNT_MANAGER_REG = new RegExp('account manager', 'ig');
export const CRM_PROVIDER_REG = new RegExp('crm provider', 'ig');
export const USER_SEATS_REG = new RegExp('number of user seats', 'ig');
export const VERSION_ACCESS_REG = new RegExp('version access', 'ig');
export const REFERRAL_AGENT_REG = new RegExp('referral agent', 'ig');
export const SF_ACCOUNT_ID_REG = new RegExp('salesforce account id', 'ig');
