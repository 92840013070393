import { debounce } from 'lodash';
import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from 'react';
import { usePrevious } from '../../hooks';

interface IContextProps {
  count: number;
  size: number;
  page: number;
  lastPage: number;
  search: string;
  sort: string;
  setCount: (count: number) => void;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  setSearch: (search: string) => void;
  setSort: (sort: string) => void;
}

const TableContext = createContext({} as IContextProps);

type Props = {
  children: ReactNode;
  extraData?: { [key: string]: any };
  total?: number;
  initSize?: number;
  initPage?: number;
  onChange?: Function;
};

export const TableContextProvider = ({
  children,
  total = 0,
  initSize = 10,
  initPage = 0,
  extraData = {},
  onChange = () => {},
}: Props) => {
  const [restartData, setRestartData] = useState(extraData);
  const [count, setCount] = useState(total);
  const [size, setSize] = useState(initSize);
  const [page, setPage] = useState(initPage);
  const [lastPage, setLastPage] = useState(Math.ceil(count / size));
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const prevSort = usePrevious(sort);
  const prevPage = usePrevious(page);
  const prevSize = usePrevious(size);

  useEffect(() => {
    const keys = Object.keys(extraData);
    if (keys.length > 0) {
      const isDiff = keys.some(key => extraData[key] !== restartData[key]);
      if (isDiff) {
        setRestartData(extraData);
        setPage(0);
      }
    }
  }, [extraData]);

  useEffect(
    debounce(() => {
      if (!size) {
        return;
      }
      setLastPage(Math.ceil(count / size));
    }, 400),
    [size, count]
  );

  useEffect(() => {
    setCount(total);
  }, [total]);

  useEffect(() => {
    if ((prevPage || page) && prevPage !== page) {
      onChange({ page, size, sort });
    }
  }, [page]);

  useEffect(() => {
    if (prevSize && prevSize !== size) {
      onChange({ page, size, sort });
    }
  }, [size]);

  useEffect(() => {
    if ((prevSort || sort) && prevSort !== sort) {
      setPage(0);
      onChange({ page, size, sort });
    }
  }, [sort]);

  useEffect(() => {
    if (initPage !== page) {
      setPage(initPage);
    }
  }, [initPage]);

  const value = {
    count,
    size,
    page,
    lastPage,
    search,
    sort,
    setCount,
    setSize,
    setPage,
    setSearch,
    setSort,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export const useTableContext = () => useContext(TableContext);
