import React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import {
  IntegrationCustomer,
  integrations,
} from '../../../app/pages/admin/integrations/Main';
import { NewModal } from './NewModal';

const ModalMessage = styled.div`
  font-size: 14px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
  gap: 30px;
`;

type Props = {
  handleModalClose: () => void;
  handleModalConfirm: () => void;
  newSelection: number;
  customer: IntegrationCustomer | null;
};

export const ChangeCRMIntegrationModal = ({
  handleModalClose,
  handleModalConfirm,
  newSelection,
  customer,
}: Props) => {
  const newIntegration = integrations.find(
    integration => integration.value === newSelection
  );

  if (!newIntegration || !customer) {
    return null;
  }

  const headerText = `Change CRM Integration for the ‘${customer.business}’`;

  let currentIntegration = 'None';
  if (customer.eLeadEnabled) {
    currentIntegration = 'Elead';
  } else if (customer.vinSolutionsDealer) {
    currentIntegration = 'VinSolutions';
  } else if (customer.dealerSocketId) {
    currentIntegration = 'Dealersocket';
  }

  return (
    <NewModal
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose()}
      headerText={headerText}
    >
      <ModalMessage>
        You’re about to change CRM integration for the{' '}
        <strong>’{customer.business}’</strong> from{' '}
        <strong>{currentIntegration}</strong> to{' '}
        <strong>{newIntegration?.label}</strong>.
        <br />
        <br />
        Assigned account manager and the accounting team will be notified about
        this change.
      </ModalMessage>
      <ButtonsWrapper>
        <Button
          text={'No, cancel'}
          variant='ghost'
          onClick={handleModalClose}
        />

        <Button
          text={'Yes, Change Integration'}
          variant='primary'
          onClick={handleModalConfirm}
        />
      </ButtonsWrapper>
    </NewModal>
  );
};
