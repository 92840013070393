enum CouponDuration {
  once = 'once',
  repeating = 'repeating',
  forever = 'forever',
}

export type CouponFromStripe = {
  amount_off: number | null;
  created: number;
  currency: string | null;
  duration: CouponDuration;
  duration_in_months: number | string | null;
  id: string;
  livemode: boolean;
  max_redemptions: number | string | null;
  metadata: any;
  name: string;
  object: string;
  percent_off: number | null;
  redeem_by: string | null;
  times_redeemed: number;
  valid: boolean;
};

type CalculateFinalPriceParams = {
  basePrice: number;
  autoCaptionPrice: number;
  templatePrice: number;
  users: number;
  couponData: CouponFromStripe | null;
};

const calculateDiscount = (
  coupon: CouponFromStripe,
  priceWithoutDiscount: number
): number => {
  let discount = 0;
  const { amount_off, percent_off } = coupon;

  const term = coupon.metadata.term;
  if (!term || term === 'monthly') {
    if (percent_off !== null) {
      discount = priceWithoutDiscount * (percent_off / 100);
    } else if (amount_off !== null) {
      discount = amount_off / 100;
    }
  }

  return discount;
};

export const calculateFinalPrice = ({
  basePrice,
  autoCaptionPrice,
  templatePrice,
  users,
  couponData,
}: CalculateFinalPriceParams): number => {
  let discount = 0;
  const priceWithoutDiscount =
    basePrice * users + autoCaptionPrice * users + templatePrice;
  if (couponData) {
    discount = calculateDiscount(couponData, priceWithoutDiscount);
  }
  const totalPrice = priceWithoutDiscount - discount;
  return totalPrice;
};
