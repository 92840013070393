import * as React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  MdNoAccounts,
  MdChatBubbleOutline,
  MdDeleteForever,
  MdNotifications,
  MdChat,
} from 'react-icons/md';
import { IoMdChatboxes } from 'react-icons/io';
import { SidebarNavigation } from 'lib/components/SidebarNavigation';

import { NotFoundTemplate } from '../notFound';
import { Conversations } from './Conversations';
import { useAuth } from 'lib/context';
import { checkIfUserIsTechnician } from 'lib/utils/automotiveRolePermissionChecks';
import { useGetConversationCount } from 'lib/api/conversations/useConversationCount';

export const ConversationRoutes = () => {
  const iconSize = '24px';
  const { userData, whitelabel } = useAuth();

  const { isCompanyAdmin } = userData;

  const { data: conversationCount } = useGetConversationCount(
    userData,
    whitelabel
  );

  const { pathname } = useLocation();

  const adminItemsConversations = [
    {
      title: 'All',
      icon: <IoMdChatboxes size={iconSize} />,
      path: `/conversations`,
      exact: !pathname.includes('all'),
    },
    // {
    //   title: 'Mentions',
    //   icon: <MdAlternateEmail size={iconSize} />,
    //   path: `/conversations/mentions`,
    // },
    //add unassigned tab if user is company admin

    ...(isCompanyAdmin
      ? [
          {
            title: 'Unassigned',
            icon: <MdNoAccounts size={iconSize} />,
            path: `/conversations/unassigned`,
            ...(!!conversationCount?.unassigned
              ? { notificationCount: `${conversationCount.unassigned}` }
              : {}),
          },
        ]
      : []),

    {
      title: 'Unread',
      icon: <MdNotifications size={iconSize} />,
      path: `/conversations/unread`,
      ...(!!conversationCount?.unread
        ? { notificationCount: `${conversationCount.unread}` }
        : {}),
    },
    {
      title: 'Open',
      icon: <MdChat size={iconSize} />,
      path: `/conversations/open`,
    },
    {
      title: 'Closed',
      icon: <MdChatBubbleOutline size={iconSize} />,
      path: `/conversations/closed`,
    },
    {
      title: 'Deleted',
      icon: <MdDeleteForever size={iconSize} />,
      path: `/conversations/deleted`,
    },
  ];

  const getConversationComponent = () => {
    if (checkIfUserIsTechnician(userData)) {
      return () => <Redirect to='/home' />;
    }
    return Conversations;
  };

  return (
    <>
      <SidebarNavigation items={adminItemsConversations} hasBack={false} />
      <Switch>
        <Route
          path='/conversations'
          component={getConversationComponent()}
          exact
        ></Route>
        <Route
          path='/conversations/:type'
          component={getConversationComponent()}
          exact
        />
        <Route
          path='/conversations/:type/:id'
          component={getConversationComponent()}
          exact
        />
        <Route path='*' component={NotFoundTemplate} />
      </Switch>
    </>
  );
};
