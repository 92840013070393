import { useMutation, useQueryClient } from 'react-query';

import { successToast } from 'lib/components/toasts/success';
import { vdpQueryKeys } from './vdpQueryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const deleteVdp = async (id: string | number) => {
  return (await EXPRESS_API.delete(`vdp/${id}`)).data;
};

export const useDeleteVdp = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteVdp, {
    onSuccess: () => {
      queryClient.invalidateQueries(vdpQueryKeys.all);
      successToast({ title: 'Video pulled successfully.' });
    },
  });
};
