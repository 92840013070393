import {
  FormProps,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  getCheckboxField,
} from 'app/pages/admin/components/AdminFormComponents';
import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';

export const VdpSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  const { colors } = useCovideoTheme();
  return (
    <Section>
      <SectionTitle>VDP</SectionTitle>
      <RowWrapper>
        <Row>
          {getCheckboxField(
            'vdpHomeNetEnabled',
            'HomeNet',
            !!values.vdpHomeNetEnabled,
            () =>
              setFieldValue(
                'vdpHomeNetEnabled',
                values.vdpHomeNetEnabled ? 0 : 1
              ),
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>
        <Row>
          {getCheckboxField(
            'vdpIPacketEnabled',
            'IPacket',
            !!values.vdpIPacketEnabled,
            () =>
              setFieldValue(
                'vdpIPacketEnabled',
                values.vdpIPacketEnabled ? 0 : 1
              ),
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>
        <Row>
          {getCheckboxField(
            'jdPowerVDPEnabled',
            'JDPower',
            !!values.jdPowerVDPEnabled,
            () =>
              setFieldValue(
                'jdPowerVDPEnabled',
                values.jdPowerVDPEnabled ? 0 : 1
              ),
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>
      </RowWrapper>
    </Section>
  );
};
