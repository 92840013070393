import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import { MdAdd } from 'react-icons/md';

const Card = styled.div`
  height: 88px;
  border: 1px dashed ${theme.palette.gray40};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 7px;
  &:hover {
    background: ${theme.palette.babyBlue10};
    border: 1px dashed ${theme.palette.babyBlue100};
  }
`;
const CardContent = styled.div`
  display: flex;
  align-items: center;
`;
const CardTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin-left: 8px;
  color: ${theme.palette.covideoBlue100};
`;

type Props = {
  handleClick: () => void;
};
export const CardAddQuote = ({ handleClick }: Props) => {
  return (
    <Card onClick={() => handleClick()}>
      <CardContent>
        <MdAdd color={theme.palette.covideoBlue100} size={20} />
        <CardTitle>Add Quote</CardTitle>
      </CardContent>
    </Card>
  );
};
