import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { Modal, Search } from 'lib/components';
import { Button } from 'react-covideo-common';
import { useMutation, useQuery } from 'react-query';
import {
  ContactListItem,
  fetchCustomer,
  getLeads,
  recipientAndSender,
} from 'lib/api';
import { MdClose } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router';
import { LeadItem, useAuth } from 'lib/context';
import { getContacts } from 'lib/api/contacts/useContactsQuery';

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Subheading = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #272a32;
  margin: 0;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;
`;

const Icon = styled.div`
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ModalBody = styled.div<{ isEmpty: boolean }>`
  background: #f6f7f9;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
  gap: 12px;
  max-height: 560px;
  flex-direction: column;
  display: flex;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  height: calc(560px - 200px);
  box-sizing: border-box;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Paragraph = styled.p<{ fontWeight: string; color: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 14px;
  line-height: 20px;
  color: ${({ color }) => color};
  margin: 0;
  flex: 1;
`;

const SearchWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const EmptyMsg = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

export const ModalAddSMS = ({ onModalClose, setChatData }: any) => {
  const history = useHistory();
  const { userData } = useAuth();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const range = (query.get('range') || 'YTD').trim();
  // check if leads integration active, if active use leads instead of contacts
  const leadsIntegrationActive = !!userData?.customer?.leadsIntegrationActive;

  const [searchValue, setSearch] = useState('');
  const { data, isLoading } = useQuery(['contacts', searchValue], () =>
    leadsIntegrationActive
      ? getLeads({ start: 0, limit: 50, search: searchValue })
      : getContacts({ size: 50, search: searchValue })
  );

  const { data: customer, isLoading: isLoadingCustomer } = useQuery(
    ['userPhone'],
    () => fetchCustomer(userData.customerId)
  );

  const { mutateAsync } = useMutation(recipientAndSender);

  if (isLoadingCustomer) {
    return null;
  }

  const fromUserPhone = customer?.smsBilling?.assignedNumber;

  const onModalCloseHandler = () => {
    onModalClose();
    history.replace({
      search: `?range=${range}`,
    });
  };

  const startNewConversation = async (
    contactPhone: string,
    firstName?: string,
    lastName?: string
  ) => {
    const res = await mutateAsync({
      from: fromUserPhone,
      to: contactPhone,
    });
    history.replace({
      pathname: !!res.chats.length
        ? `/sms/chats/${res.chats[0].chatId}`
        : '/sms/chats/new',
    });

    setChatData({
      contactFirstName: firstName ? firstName : '',
      contactLastName: lastName ? lastName : '',
      contactPhone: contactPhone,
      ownerFirstName: null,
      ownerLastName: null,
      chatId: !!res.chats.length ? res.chats[0].chatId : null,
      messages: !!res.chats.length ? res.chats[0].messages : [],
    });
    onModalCloseHandler();
  };

  const containsOnlyLetters = searchValue.match('[a-zA-Z]+');

  const filteredData = leadsIntegrationActive
    ? data?.leads.filter((contact: LeadItem) => contact.phone)
    : data?.contacts.filter((contact: ContactListItem) => contact.phone);

  return (
    <Modal
      widthOnDesktop={'852px'}
      maxHeight='570px'
      bodyOverflowX='hidden'
      bodyOverflowY='hidden'
    >
      <Content>
        <ModalHeader>
          <Subheading>New Conversation</Subheading>
          <Icon onClick={onModalCloseHandler}>
            <MdClose size={18} color='rgba(39, 42, 50, 0.2)' />
          </Icon>
        </ModalHeader>
        <SearchWrapper>
          <Search
            prevSearch={searchValue}
            onSearch={value => {
              setSearch(value);
            }}
            placeholder='Search for customers or type phone number'
            width='100%'
          />
        </SearchWrapper>
        <ModalBody isEmpty={!filteredData?.length || isLoading}>
          {isLoading ? (
            <EmptyMsg>Loading....</EmptyMsg>
          ) : (
            <>
              {filteredData?.length ? (
                filteredData?.map((contact: ContactListItem | LeadItem) => {
                  return (
                    <ContactItem>
                      <Paragraph fontWeight='600' color='#272A32'>
                        {contact.phone}
                      </Paragraph>
                      <Paragraph fontWeight='400' color='#9297A2'>
                        {contact.firstName} {contact.lastName}
                      </Paragraph>
                      <Button
                        text='Start Conversation'
                        onClick={() =>
                          startNewConversation(
                            contact.phone || '',
                            contact.firstName,
                            contact.lastName
                          )
                        }
                        variant='white'
                      />
                    </ContactItem>
                  );
                })
              ) : (
                <>
                  <EmptyMsg>
                    {containsOnlyLetters
                      ? 'No matching contacts. You can type a phone number instead.'
                      : 'No matching contacts.'}
                  </EmptyMsg>
                  {!containsOnlyLetters && (
                    <Button
                      text={`+ Start Conversation with ${searchValue}`}
                      onClick={() => startNewConversation(searchValue)}
                      variant='primary'
                    />
                  )}
                </>
              )}
            </>
          )}
        </ModalBody>
      </Content>
    </Modal>
  );
};
