import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Youtube } from './youtube';

export const YoutubeIntegration = () => {
  return (
    <>
      <DocumentHead title="Youtube" />
      <PageTemplate main={<Youtube />} />
    </>
  );
};
