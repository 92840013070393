import { getReports } from 'lib/api';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import styled, { css } from 'styled-components/macro';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import { WidgetWrapper } from './common/styles';
import { widget } from './keys';
import { EmptyWidget } from '../EmptyWidget';
import WidgetHeader from './common/WidgetHeader';
import {
  IParams,
  REPORTS_STALE_TIME,
  WidgetLabels,
  WidgetValues,
} from './types';

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid rgba(242, 244, 246, 1);
  box-shadow:
    0px 0px 0px rgb(66 79 104 / 8%),
    0px 4px 8px rgb(66 79 104 / 3%);
`;
const Body = styled.div`
  display: flex;
  box-shadow:
    0px 0px 0px rgb(66 79 104 / 8%),
    0px 4px 8px rgb(66 79 104 / 3%);
  border-radius: 5px;
  border-bottom: 1px solid rgba(242, 244, 246, 1);
  padding: 12px 0;
`;

const Title = styled.p<{ flex?: string }>`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #9297a2;
  flex: ${({ flex }) => flex};
`;

const BodyParagraph = styled.p<{ flex?: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #272a32;
  flex: ${({ flex }) => flex};
  margin: 0;
`;

const CommonProgressStyles = css`
  border: 0;
  height: 20px;
  width: 120px;
  border-radius: 20px;
  overflow: hidden;
`;

const Progress = styled.progress<{ percent: string }>`
  ${CommonProgressStyles}
  background:rgba(242, 244, 246, 1);

  ::-webkit-progress-bar {
    ${CommonProgressStyles}
    background:rgba(242, 244, 246, 1);
  }
  ::-webkit-progress-value {
    ${CommonProgressStyles}
    border-top-right-radius: ${({ percent }) =>
      percent === '100' ? '20px' : '0px'};
    border-bottom-right-radius: ${({ percent }) =>
      percent === '100' ? '20px' : '0px'};
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
    background: rgba(0, 27, 83, 0.6);
  }
  ::-moz-progress-bar {
    ${CommonProgressStyles}
    background:rgba(242, 244, 246, 1);
  }
`;

const BodyResult = styled.div`
  display: flex;
  align-items: center;
  flex: 0.4;
  gap: 12px;
  justify-content: space-between;
`;

const Label = styled.label`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #272a32;
`;

interface DeliveryMethodViewRate {
  params: IParams;
  addDataForExport: (data: any) => void;
}

interface IApiData {
  id: number | string;
  name: string;
  value: number;
}

interface IMapperData {
  data: IApiData[];
  total: number;
}

const apiUrl = 'metrics/videos/delivery-method';

const ViewRateByDelivery = ({
  params,
  addDataForExport,
}: DeliveryMethodViewRate) => {
  const { data, isLoading, isRefetching } = useQuery(
    widget.detail(WidgetValues.DELIVERY_METHOD, params),
    () => getReports(params, apiUrl),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const mapDataForExport = (data: any) => {
    const { widgetData } = data;
    let exportData: any = {
      title: WidgetLabels.VIEW_RATE_BY_DELIVERY_METHOD,
      data: [],
      arr: [],
    };
    if (widgetData && widgetData.total !== 0) {
      let temp: any = {};
      widgetData?.data?.forEach((el: any) => {
        if (el.value === null || isNaN(el.value)) {
          el.value = 0;
        }
        temp[el.name] = ((el.value * 100) / widgetData.total).toFixed(2) + '%';
      });
      temp['Total'] = widgetData.total;
      exportData.data.push(temp);
    }
    return exportData;
  };

  const organizeWidgetData = (data: any) => {
    let mapper: IMapperData = { data: [], total: 0 };
    let total = 0;
    mapper.data.push({
      id: 'Email',
      name: 'Email',
      value: parseFloat(data?.emails.views),
    });
    if (!isNaN(parseFloat(data?.emails.views))) {
      total += parseFloat(data?.emails.views);
    }
    mapper.data.push({
      id: 'SMS',
      name: 'SMS',
      value: parseFloat(data?.sms.views),
    });
    if (!isNaN(parseFloat(data?.sms.views))) {
      total += parseFloat(data?.sms.views);
    }
    mapper.data.push({
      id: 'Other',
      name: 'Other',
      value: parseFloat(data?.other.views),
    });
    if (!isNaN(parseFloat(data?.other.views))) {
      total += parseFloat(data?.other.views);
    }
    mapper.data.sort(function (a, b) {
      return b.value - a.value;
    });
    mapper.total = total;
    return mapper;
  };

  const widgetData = organizeWidgetData(data);

  useEffect(() => {
    if (widgetData) {
      addDataForExport(mapDataForExport({ widgetData }));
    }
  }, [data]);

  if (isLoading) {
    return <SkeletonLoader imageName='vertical-reports' />;
  }

  return (
    <WidgetWrapper>
      <WidgetHeader
        title='View Rate By Delivery Method'
        isRefetching={isRefetching}
      />
      {widgetData.total === 0 ? (
        <EmptyWidget />
      ) : (
        <>
          <Header>
            <Title flex='0.6'>Delivery Method</Title>
            <Title flex='0.4'>View Rate</Title>
          </Header>

          {widgetData.data.map(({ value, name }: any, idx: number) => {
            return (
              <Body key={idx}>
                <BodyParagraph flex='0.6'>
                  {name === null ? 'N/A' : name}
                </BodyParagraph>
                <BodyResult>
                  <Label htmlFor='file'>
                    {((value / widgetData.total) * 100).toFixed(2)}%
                  </Label>
                  <Progress
                    id='file'
                    value={(value / widgetData.total) * 100}
                    percent={value.toString()}
                    max='100'
                  />
                </BodyResult>
              </Body>
            );
          })}
        </>
      )}
    </WidgetWrapper>
  );
};

export default ViewRateByDelivery;
