import React from 'react';
import styled from 'styled-components/macro';
import { ErrorMessage } from 'formik';
import { theme } from 'lib/style';
import { PASSWORD_FORMAT_ERROR } from 'app/pages/account/userAccount/editMyProfile/components';
import { ErrorMsg } from './AdminFormComponents';
import { MdHelp } from 'react-icons/md';

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipContent = styled.div`
  position: absolute;
  width: 250px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  background-color: ${theme.palette.white};
  box-shadow: 0 8px 32px 0 rgba(29, 30, 36, 0.08);
  border: solid 1px rgb(247, 248, 250);
  padding: 5px;
  font-size: 14px;
  opacity: 0;
  z-index: 9;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;

  & ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
  }

  & li {
    margin: 5px 0;
  }
`;

const TooltipTrigger = styled.div`
  display: inline-block;
  cursor: pointer;

  &:hover + ${TooltipContent} {
    opacity: 1;
    visibility: visible;
  }
`;

const GuideWrapper = styled.div<{ color: string }>`
  padding-top: 10px;
  font-size: 14px;
  color: ${({ color }) => (color ? color : theme.palette.gray100)};
`;

const ErrorList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin: 0;
`;

const ErrorItem = styled.li`
  margin: 5px 0;
`;

export const PasswordGuide = ({ color }: { color: string }) => (
  <GuideWrapper color={color}>
    Password must be:
    <ErrorList>
      <ErrorItem>at least 8 characters in length</ErrorItem>
      <ErrorItem>contain one or more numeric numbers</ErrorItem>
      <ErrorItem>contain one or more upper case characters</ErrorItem>
      <ErrorItem>
        contain one or more special characters (only !@#$%^&* special characters
        can be used)
      </ErrorItem>
    </ErrorList>
  </GuideWrapper>
);

export const CustomPasswordErrorMessage = ({ name }: { name: string }) => (
  <ErrorMessage name={name}>
    {error => {
      return error !== PASSWORD_FORMAT_ERROR ? (
        <ErrorMsg>{error}</ErrorMsg>
      ) : (
        <PasswordGuide color={theme.palette.red100} />
      );
    }}
  </ErrorMessage>
);

export const getTooltip = () => {
  return (
    <TooltipWrapper>
      <TooltipTrigger>
        <MdHelp />
      </TooltipTrigger>
      <TooltipContent>
        <PasswordGuide color={theme.palette.gray100} />
      </TooltipContent>
    </TooltipWrapper>
  );
};
