import { Field, FieldProps, useFormikContext } from 'formik';
import { ButtonSwitch } from 'lib/components';
import React from 'react';
import styled from 'styled-components/macro';
import MaskedInput from 'react-text-mask';
import { Gap } from 'lib/components/styles/layout';
import { HiClock } from 'react-icons/hi';
import { UserValues } from '../userValidator';

const COLON = ':';
const timeMask = (value: string) => {
  const chars = value.split('');
  let hours = [];
  if (chars[0] === '0' || chars[0] === '1') {
    if (chars[1] === COLON) {
      hours = [/[0-1]/];
    } else {
      hours = [/[0-1]/, chars[0] === '1' ? /[0-2]/ : /[0-9]/];
    }
  } else {
    hours = [/[2-9]/];
  }

  const minutes = [/[0-5]/, /[0-9]/];

  return [...hours, ':', ...minutes];
};

const timePipe = (conformedValue: string) => {
  if (!!conformedValue && !!conformedValue.length) {
    const parts = conformedValue.split(COLON);
    if (!!parts && !!parts.length) {
      const hour = parts[0];
      if (hour.length === 1 && (parts.length === 2 || parseInt(hour, 10) > 1)) {
        parts[0] = `0${hour}`;
      }

      return parts.join(COLON);
    }
  }
  return conformedValue;
};

const MaskedInputStyles = styled(MaskedInput)<{ error?: string }>`
  background: #ffffff;
  border: 1px solid
    ${({ error }) => (!!error ? 'rgba(232, 76, 61, 1)' : '#d0d3d9')};
  border-radius: 4px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 8px 8px 40px;
  color: #272a32;
  width: 46px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

interface IMeridiemPicker {
  hoursName: string;
  defaultMeridiemValue: string;
  meridiemValue: string;
  isError?: boolean;
}

export const MeridiemPicker = ({
  hoursName,
  defaultMeridiemValue,
  meridiemValue,
  isError,
}: IMeridiemPicker) => {
  const { setFieldValue } = useFormikContext<UserValues>();

  return (
    <Gap gap='4px'>
      <Field name={hoursName}>
        {({ field }: FieldProps) => {
          return (
            <InputWrapper>
              <HiClock
                style={{ position: 'absolute', left: 10, top: 10 }}
                size='20'
                color='rgba(146, 151, 162, 1)'
              />
              <MaskedInputStyles
                {...field}
                mask={timeMask}
                placeholder='00:00'
                guide={false}
                error={isError ? 'error' : ''}
                pipe={timePipe}
                onChange={event =>
                  setFieldValue(hoursName, `${event.target.value}`)
                }
              />
            </InputWrapper>
          );
        }}
      </Field>

      <ButtonSwitch
        className='btn-switch'
        defaultValue={defaultMeridiemValue}
        onChange={value => setFieldValue(meridiemValue, `${value}`)}
        values={[
          { value: 'AM', text: 'AM' },
          { value: 'PM', text: 'PM' },
        ]}
      />
    </Gap>
  );
};
