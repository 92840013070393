import styled, { css } from 'styled-components/macro';
import { ReactComponent as Icon } from './union.svg';

export const MainWrapper = styled.div<{ position?: 'relative' | 'fixed' }>`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ position }) => position || 'relative'};
  ${({ position }) =>
    position === 'fixed' &&
    css`
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `}
`;
export const Union = styled(Icon)`
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  path {
    fill: ${({ color }) => color || 'currentColor'};
    opacity: ${({ opacity }) => opacity || 'inherit'};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
export const BtnWrapper = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  bottom: 64px;
  left: 0;
`;
