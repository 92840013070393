import { subscriptionMappingItemType } from 'lib/const/Subscription';
import { theme } from 'lib/style';
import * as React from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import styled from 'styled-components/macro';

type Props = {
  subscriptionData: any;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 30px 0px 0px 0px;
  flex-direction: column;
  max-width: 380px;
`;

const Title = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${theme.palette.neutral100};
  @media screen and (max-width: 480px) {
    text-align: center;
  }
`;

const GridContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 52% 21% 21%;
  grid-gap: 10px;
  padding: 10px 0px 15px 0px;
  border-bottom: 2px solid ${theme.palette.neutral20};
`;

const AddonTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.neutral100};
`;

const Value = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: ${theme.palette.neutral100};
`;

const TotalAmountSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  justify-content: space-between;
  color: ${theme.palette.covideoOrange100};
`;

const InformationBox = styled.div`
  margin-top: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  height: fit-content;
  background: ${theme.palette.covideoBlue02NonAlpha};
  border: 1px solid rgba(39, 42, 50, 0.05);
  border-radius: 6px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  padding-right: 20px;
`;

export const SubscriptionBreakdown = ({ subscriptionData }: Props) => {
  const {
    annual,
    quantity,
    amount,
    planAmount,
    selectedAddonDetails,
    discountAmount,
  } = subscriptionData;
  const getUiFragmentForBreakdown = () => {
    return (
      <>
        <AddonTitle>{annual ? 'Annual' : 'Monthly'} subscription</AddonTitle>
        <Value>{quantity}x</Value>
        <Value>{`$${planAmount}`}</Value>
        {selectedAddonDetails && !!Object.keys(selectedAddonDetails).length && (
          <>
            {selectedAddonDetails.map((addon: subscriptionMappingItemType) => {
              return (
                <>
                  <AddonTitle>{addon.title}</AddonTitle>
                  <Value>{`${addon.type === 'users' ? quantity : 1}x`}</Value>
                  <Value>{`$${
                    addon.type === 'users'
                      ? Math.round(
                          quantity * parseInt(addon.price.toString(), 10)
                        )
                      : addon.price
                  }`}</Value>
                </>
              );
            })}
          </>
        )}
        {!!discountAmount && (
          <>
            <AddonTitle>Discount</AddonTitle>
            <Value />
            <Value>{`- $${discountAmount}`}</Value>
          </>
        )}
      </>
    );
  };

  const getUIFragmentForTotalAmount = () => {
    return (
      <TotalAmountSection>
        <div>Total</div>
        <div>{amount}</div>
      </TotalAmountSection>
    );
  };

  return (
    <Container>
      <Title>Subscription Breakdown</Title>
      <GridContainer>{getUiFragmentForBreakdown()}</GridContainer>
      {getUIFragmentForTotalAmount()}
      <InformationBox>
        <div style={{ fontSize: 20 }}>
          <AiFillInfoCircle />
        </div>
        {annual ? 'Annual' : 'Monthly'} subscription renews automatically.
        Cancel any time.
      </InformationBox>
    </Container>
  );
};
