import { uploadImage } from 'lib/api';
import { wrapWithHttp } from 'lib/utils/functions';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from '../constants/style';
import { annotationSteps, initialTemplate } from '../constants/templates';
import { typeValues } from '../constants/types';

type PrepareAnnotationProps = {
  data: any;
  time: ReadonlyArray<number>;
  selectedImage?: File;
  userData: any;
};

export const hasAnnotationCRUDPrivileges = (
  user: any,
  video: any,
  annotation?: any
) => {
  const isCompanyAnnotation = annotation?.isCompanyAnnotation;
  const isAdmin = user.access >= 3;
  const hasAutomotiveEditPrivilege = user.automotiveRole > 1;
  const isOwner = Number(video.userId) === Number(user.id);
  const hasAccessToSharedFolder =
    !!video.folderAccess && video.folderAccess === 'edit';

  return isCompanyAnnotation
    ? isAdmin
    : isAdmin ||
        isOwner ||
        hasAutomotiveEditPrivilege ||
        hasAccessToSharedFolder;
};

export const capitalize = (value: string | undefined) => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const calculateImageDimensions = (
  width: number,
  height: number,
  ratio = 0
) => {
  if (!ratio) {
    ratio = width / height;
  }

  if (width > height) {
    width = width > MAX_IMAGE_WIDTH ? MAX_IMAGE_WIDTH : width;
    height =
      Math.floor(width / ratio) > MAX_IMAGE_HEIGHT
        ? MAX_IMAGE_HEIGHT
        : Math.floor(width / ratio);
  } else {
    height = height > MAX_IMAGE_HEIGHT ? MAX_IMAGE_HEIGHT : height;
    width =
      Math.floor(height * ratio) > MAX_IMAGE_WIDTH
        ? MAX_IMAGE_WIDTH
        : Math.floor(height * ratio);
  }

  return {
    width,
    height,
    ratio,
  };
};

export const getImageRatio = (annotation: any) => {
  let imageRatio = 0;
  if (annotation.type === 'image') {
    imageRatio = annotation.imageWidthPercentage
      ? parseInt(annotation.imageWidthPercentage, 10) /
        parseInt(annotation.imageHeightPercentage, 10)
      : annotation.imageWidth / annotation.imageHeight;
  }

  return imageRatio;
};

export const determineAnnotationValue = (keyword: string) => {
  const annotationValue = typeValues.filter(
    typeValue => typeValue.text === keyword
  );
  if (!annotationValue.length) return keyword;
  return annotationValue[0].value;
};

export const determineAnnotationText = (keyword: string) => {
  const annotationValue = typeValues.filter(
    typeValue => typeValue.value === keyword
  );
  if (!annotationValue.length) return keyword;
  return annotationValue[0].text;
};

export const prepareAnnotation = async ({
  data,
  time,
  selectedImage,
  userData,
}: PrepareAnnotationProps) => {
  const annotationData = {
    ...data,
  };
  if (annotationData.url) {
    annotationData.url = wrapWithHttp(annotationData.url);
  }
  annotationData.startTime = time[0];
  annotationData.endTime = time[1];

  if (annotationData.type === 'image') {
    delete annotationData.text;
    delete annotationData.text2;
    delete annotationData.fontFamily;
    delete annotationData.fontSize;
    delete annotationData.textColor;
    delete annotationData.textBold;
    delete annotationData.textItalic;
    delete annotationData.textUnderline;
    delete annotationData.backgroundColor;
    delete annotationData.backgroundOpacity;
    delete annotationData.borderColor;
    delete annotationData.borderWidth;

    if (annotationData.useImagePercentage) {
      delete annotationData.imageWidth;
      delete annotationData.imageHeight;
    } else {
      delete annotationData.imageWidthPercentage;
      delete annotationData.imageHeightPercentage;
    }

    if (selectedImage) {
      annotationData.imageUrl = await uploadImage(
        selectedImage,
        userData.userId
      );
    }
  }

  if (annotationData.type === 'text') {
    delete annotationData.imageWidth;
    delete annotationData.imageHeight;
    delete annotationData.imageWidthPercentage;
    delete annotationData.imageHeightPercentage;
    delete annotationData.imageRatio;
    delete annotationData.imageUrl;
    delete annotationData.imageOpacity;
    delete annotationData.imageName;
  }

  if (annotationData.templateId) delete annotationData.templateId;

  return annotationData;
};

type SelectedTemplateProps = {
  template: any;
  setStep: (arg: string) => void;
  setSelectedTemplate: (arg: object) => void;
};

export const selectTemplate = ({
  template,
  setStep,
  setSelectedTemplate,
}: SelectedTemplateProps) => {
  const temp = { ...template };
  temp.imageRatio = getImageRatio(temp);
  temp.useImagePercentage = !!temp.imageWidthPercentage;
  delete temp.customerId;
  setStep(annotationSteps.STYLE);
  setSelectedTemplate({
    ...initialTemplate,
    ...temp,
    url: temp.url || '',
    email: temp.email || '',
    phone: temp.phone || '',
  });
};

export const calculatePercentageOnChange = (
  widthPercentage: string,
  imageRatio: number,
  setFieldValue: Function
) => {
  const { width, height } = calculateImageDimensions(
    parseInt(widthPercentage, 10),
    0,
    imageRatio
  );
  setFieldValue('imageWidthPercentage', width + '%');
  setFieldValue('imageHeightPercentage', height + '%');
};

export const calculateDimensionsOnChange = (
  currentWidth: number,
  currentHeight: number,
  imageRatio: number,
  setFieldValue: Function
) => {
  const { width, height } = calculateImageDimensions(
    currentWidth,
    currentHeight,
    imageRatio
  );
  setFieldValue('imageWidth', width);
  setFieldValue('imageHeight', height);
};
