import React from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'lib/context';
import { DetailsDrops } from '../../components';

export const Details = () => {
  const { userData } = useAuth();

  const history = useHistory();

  if (!userData.droplrAccess) {
    history.push('/profile/add-ons');
    return null;
  }

  return <DetailsDrops />;
};
