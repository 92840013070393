import React, { useEffect, useState } from 'react';
import { SnippetType } from 'lib/const';
import { useAuth } from 'lib/context';
import { TextEditor } from 'lib/components/textEditorAutomotive';
import { checkIfTemplatesAccessible } from 'lib/utils/automotiveRolePermissionChecks';

const smsEditorToolbar = {
  options: [],
};
type SmsContent = {
  body: string;
};
type Props = {
  smsContent: SmsContent;
  setSmsContent: (newContent: SmsContent) => void;
  height?: string;
  placeholder?: string;
  showMagicButton?: boolean;
  magicBtnLabel?: string;
  magicHoverPopup?: JSX.Element;
  defaultVin?: string;
};
export const SmsEditor = ({
  smsContent,
  setSmsContent,
  placeholder = '',
  height = '160px',
  showMagicButton = false,
  magicBtnLabel = '',
  defaultVin = '',
  magicHoverPopup,
}: Props) => {
  const { userData } = useAuth();
  const { droplrAccess } = userData;

  const snippetButtonPosition = checkIfTemplatesAccessible(userData)
    ? 'inline'
    : 'hidden';

  const [body, setBody] = useState(smsContent.body || '');

  useEffect(() => {
    setSmsContent({ body: body });
  }, [body]);

  return (
    <>
      <TextEditor
        initialContent={body}
        onTextEditorChange={setBody}
        height={height}
        placeholder={placeholder}
        toolbar={smsEditorToolbar}
        showCharacterCounter={true}
        snippetButtonPosition={snippetButtonPosition}
        showFilesButton={droplrAccess}
        snippetType={SnippetType.Sms}
        hideAddVariableButton={false}
        showMagicButton={showMagicButton}
        magicBtnLabel={magicBtnLabel}
        magicHoverPopup={magicHoverPopup}
        defaultVin={defaultVin}
      />
    </>
  );
};
