import { Field, FieldProps } from 'formik';
import { RadioInput, SelectInput } from 'lib/components';
import { ReactionPreferenceOptionsForUser } from 'lib/const';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import * as React from 'react';
import { MdModeEdit, MdPlayArrow } from 'react-icons/md';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from '../../../../../../lib/style';
import { NotificationSoundComponent } from './Notifications/NotificationSoundComponent';
import { NotificationPreferencesComponent } from './Notifications/NotificationPreferences';
import { TimezoneSelector } from 'lib/components/timezoneSelector/TimezoneSelector';

interface FormProps {
  isSubmitting?: boolean;
  setSearch?: (text: string) => void;
  deptList?: any;
  setDeptValue?: (id: any) => void;
  deptLoading?: any;
  setFieldValue?: any;
  showReactionsPreference?: any;
  packageId?:
    | PackageName.LEGACY
    | PackageName.FREE
    | PackageName.PRO
    | PackageName.TEAMS
    | PackageName.ENTERPRISE;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldLabel = styled.label`
  width: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  width: 100%;
  max-width: 200px;
  line-height: 24px;
`;

const IconWrapper = styled.div`
  position: absolute;
  color: #9297a2;
  margin-top: 8px;
  margin-left: 135px;
`;

const RadioInputWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #272a32;
  }
`;

const RowWrapper = styled.div`
  width: 100%;
  div:first-child,
  p:first-child {
    padding-top: 0px;
  }
`;

const RadioOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  max-width: 800px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: '#fff';
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem;
  box-sizing: border-box;
  resize: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

export const PreferencesForm = (props: FormProps) => {
  const {
    isSubmitting,
    setFieldValue,
    showReactionsPreference,
    packageId = 0,
  } = props;
  const themes = useTheme();
  const isFreemiumUser = packageId === PackageName.FREE;

  return (
    <>
      {/* System Settings */}
      <div
        style={{
          display: 'flex',
          maxWidth: 700,
        }}
      >
        <SectionTitle>System Settings</SectionTitle>
        <RowWrapper>
          {/* Timezone */}
          <Row>
            <FieldLabel>Timezone</FieldLabel>
            <Field type='select' name='timezone'>
              {({ field }: FieldProps) => {
                const { value } = field;
                return (
                  <TimezoneSelector
                    value={value}
                    onChange={newValue =>
                      setFieldValue('timezone', newValue?.utc[0])
                    }
                    disabled={isSubmitting}
                    useUtcValue={true}
                  />
                );
              }}
            </Field>
          </Row>
        </RowWrapper>
      </div>

      <div
        style={{
          display: 'flex',
          maxWidth: 700,
          marginTop: 8,
        }}
      >
        {!isFreemiumUser && (
          <>
            <SectionTitle>Videos</SectionTitle>
            <RowWrapper>
              {/* Play Button Position */}
              <Row>
                <FieldLabel>Play Button Position</FieldLabel>
                <Field name='playButtonPosition'>
                  {({ field }: FieldProps) => {
                    const { value, ...rest } = field;
                    return (
                      <SelectInput
                        {...rest}
                        defaultValue={value}
                        disabled={isSubmitting}
                        style={{ paddingLeft: '36px' }}
                      >
                        <option value='0'>Center</option>
                        <option value='1'>Top Left</option>
                        <option value='2'>Top Right</option>
                        <option value='3'>Bottom Left</option>
                        <option value='4'>Bottom Right</option>
                      </SelectInput>
                    );
                  }}
                </Field>
                <IconWrapper>
                  <MdPlayArrow width={24} height={24} />
                </IconWrapper>
              </Row>
              {showReactionsPreference && (
                <Row>
                  <FieldLabel>Reactions & Comments</FieldLabel>
                  <Field name='reactionsPreference' readonly={isSubmitting}>
                    {({ field }: FieldProps) => {
                      const { value } = field;
                      return (
                        <RadioOptions>
                          <RadioInputWrapper>
                            <RadioInput
                              checkedColor={themes.colors.primary[100]}
                              checked={
                                value ==
                                ReactionPreferenceOptionsForUser.TURN_ON_DEFAULT
                              }
                              name='reactionsPreference'
                              value={''}
                              onChange={() =>
                                setFieldValue(
                                  'reactionsPreference',
                                  ReactionPreferenceOptionsForUser.TURN_ON_DEFAULT
                                )
                              }
                              onClick={() =>
                                setFieldValue(
                                  'reactionsPreference',
                                  ReactionPreferenceOptionsForUser.TURN_ON_DEFAULT
                                )
                              }
                            />
                            <span>Turn on by default</span>
                          </RadioInputWrapper>
                          <RadioInputWrapper>
                            <RadioInput
                              checkedColor={themes.colors.primary[100]}
                              checked={
                                value ==
                                ReactionPreferenceOptionsForUser.TURN_OFF_DEFAULT
                              }
                              name='reactionsPreference'
                              value={''}
                              onChange={() =>
                                setFieldValue(
                                  'reactionsPreference',
                                  ReactionPreferenceOptionsForUser.TURN_OFF_DEFAULT
                                )
                              }
                              onClick={() =>
                                setFieldValue(
                                  'reactionsPreference',
                                  ReactionPreferenceOptionsForUser.TURN_OFF_DEFAULT
                                )
                              }
                            />
                            <span>Turn off by default</span>
                          </RadioInputWrapper>
                        </RadioOptions>
                      );
                    }}
                  </Field>
                </Row>
              )}
            </RowWrapper>
          </>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          maxWidth: 700,
          marginTop: 8,
        }}
      >
        <SectionTitle>Video Sharing</SectionTitle>
        <RowWrapper>
          <Row>
            <FieldLabel>Link Text</FieldLabel>
            <Field name='videoShareLinkText'>
              {({ field }: FieldProps) => {
                return (
                  <FieldContainer>
                    <TextArea
                      {...field}
                      disabled={isSubmitting}
                      placeholder='Link Text'
                    />
                  </FieldContainer>
                );
              }}
            </Field>
            <IconWrapper style={{ marginTop: 20, marginLeft: 475 }}>
              <MdModeEdit width={30} height={30} />
            </IconWrapper>
          </Row>
        </RowWrapper>
      </div>
      <NotificationSoundComponent setFieldValue={setFieldValue} />
      <NotificationPreferencesComponent setFieldValue={setFieldValue} />
    </>
  );
};
