import { useMutation, useQueryClient } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { superAdminKeys } from './superAdminKeys';

interface IUserInvitesIds {
  userId: number;
}

interface UserInvitesParams {
  customerId?: number;
  invites: IUserInvitesIds[];
}

const deleteCustomerUserInvites = async (
  params: UserInvitesParams
): Promise<void> => {
  const { customerId, ...rest } = params;

  return (
    await EXPRESS_API.delete(
      `/superadmin/customers/${customerId}/user-invites`,
      {
        data: rest,
      }
    )
  ).data;
};

interface IUseCancelUserInvitesMutation {
  onSuccessCallback: () => void;
}

export const useUserCancelInvitesMutation = ({
  onSuccessCallback,
}: IUseCancelUserInvitesMutation) => {
  const queryClient = useQueryClient();
  return useMutation(deleteCustomerUserInvites, {
    onSuccess: async (_, params) => {
      await queryClient.invalidateQueries(superAdminKeys.all_invite_users());
      const inviteWording = params.invites.length > 1 ? 'invites' : 'invite';
      successToast({ title: `User ${inviteWording} canceled successfully.` });
      onSuccessCallback();
    },
    onError: (_, params) => {
      const inviteWording = params.invites.length > 1 ? 'invites' : 'invite';
      errorToast({
        title: `Failed to cancel user ${inviteWording}. Please try again.`,
      });
    },
  });
};
