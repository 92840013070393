import React from 'react';
import { VideoActivity } from 'lib/context';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { Spinner } from 'lib/components';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

const ActivityListWrapper = styled.div`
  width: 327px;
  max-height: 50vh;
  overflow-y: auto;
`;
const ActivityList = styled.div`
  padding-left: 12px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background: ${theme.palette.secondaryButtonBlueBg};
  }
`;
const ActivityListTitle = styled.div`
  color: ${theme.palette.covideoBlue100};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;
const Activity = styled.div`
  margin-bottom: 8px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: -18px;
    width: 6px;
    height: 6px;
    border: 4px solid ${theme.palette.white};
    border-radius: 50%;
    background: ${theme.palette.secondaryButtonBlueBg};
  }
`;
const ActivityAction = styled.span`
  color: ${theme.palette.gray100};
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  > b {
    color: ${theme.palette.covideoBlue100};
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-right: 4px;
  }
`;
const ActivityTime = styled.div`
  color: ${theme.palette.gray60};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  activities: VideoActivity[];
};
export const VideoActivities = ({ activities = [] }: Props) => {
  const loading = !activities.length;

  return (
    <>
      {!loading ? (
        <ActivityListWrapper>
          <ActivityListTitle>Video Activity</ActivityListTitle>
          <ActivityList>
            {activities.map((activity: VideoActivity, index: number) => (
              <Activity key={`video-activity-${index}`}>
                <ActivityAction>
                  {parse(activity.activityText || '')}
                </ActivityAction>
                {activity.createdAt && (
                  <ActivityTime>
                    {dayjs(activity.createdAt).format('YYYY-MM-DD hh:mm A')}
                  </ActivityTime>
                )}
              </Activity>
            ))}
          </ActivityList>
        </ActivityListWrapper>
      ) : (
        <SpinnerWrapper>
          <Spinner color={theme.palette.coal} />
        </SpinnerWrapper>
      )}
    </>
  );
};
