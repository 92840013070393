import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Scroller } from 'lib/components/Scroller';
import { decodeAndReplaceText } from 'lib/utils/functions';
import { animateScroll } from 'react-scroll';
import { RECORDING_STATUS } from '../types';
import { useTeleprompter } from 'lib/context/teleprompter/provider';

const TeleprompterShadow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  &.top {
    top: 0;
    bottom: 55%;
    background: linear-gradient(
      180deg,
      rgba(39, 42, 50, 0.54) 0%,
      rgba(39, 42, 50, 0.48) 47.4%,
      rgba(39, 42, 50, 0) 100%
    );
  }
  &.bottom {
    top: 30%;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(39, 42, 50, 0) 0%,
      rgba(39, 42, 50, 0.48) 47.4%,
      rgba(39, 42, 50, 0.54) 100%
    );
  }
  overflow: hidden;
`;

const TeleprompterContainer = styled.div`
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  z-index: 2;
  border-radius: 8px;
  padding: 32px 128px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  white-space: pre-line;
  position: absolute;
  overflow: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  max-height: 300px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 16px;
    max-height: 135px;
  }
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  .marquee-container {
    width: 500px;
  }
  .marquee-container:not(.horizontal)::before {
    background: transparent;
  }
  .marquee-container:not(.horizontal)::after {
    background: transparent;
  }
`;

interface TeleprompterProps {
  status: RECORDING_STATUS;
}

export const TeleprompterDisplay = ({ status }: TeleprompterProps) => {
  const { teleprompterSettings, isUsingTeleprompter, isTeleprompterPlaying } =
    useTeleprompter();

  const play = () => {
    animateScroll.scrollMore(20, {
      containerId: 'teleprompter',
      duration: 50,
      smooth: true,
      offset: 50,
    });
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (isTeleprompterPlaying && status === RECORDING_STATUS.RECORDING) {
      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(play, teleprompterSettings?.speed?.value || 100);
    } else {
      if (interval) clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTeleprompterPlaying, teleprompterSettings?.speed, status]);

  if (!isUsingTeleprompter) {
    return <></>;
  }

  return (
    <>
      <TeleprompterShadow className={teleprompterSettings?.scriptPosition} />
      <TeleprompterContainer
        id='teleprompter'
        style={{
          top: teleprompterSettings?.scriptPosition === 'top' ? '0px' : '30%',
        }}
      >
        <Scroller
          content={decodeAndReplaceText(teleprompterSettings?.content || '')}
          fontSize={teleprompterSettings?.scriptSize || '16px'}
        />
      </TeleprompterContainer>
    </>
  );
};

export default TeleprompterDisplay;
