import React from 'react';

import { ListManager } from '../../components';
import { Container } from 'lib/components/styles/layout';

export const List = () => {
  return (
    <Container>
      <ListManager />
    </Container>
  );
};
