import React from 'react';
import { useQuery } from 'react-query';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import { WidgetWrapper } from './common/styles';
import {
  IParams,
  REPORTS_STALE_TIME,
  VariantTypes,
  WidgetLabels,
  WidgetValues,
} from './types';
import { EmptyWidget } from '../EmptyWidget';
import Header from './common/Header';
import {
  Body,
  BodyParagraph,
  ItemsWrapper,
  Tooltip,
  TopVariantsTooltipWrapper,
  WidgetTitle,
} from './styles/topVariants';

import { getReports } from 'lib/api';
import { widget } from './keys';

interface ITopVariantsProps {
  params: IParams;
  variant: string;
  addDataForExport: (data: any) => void;
}
interface VariantProps {
  label: string;
  tabItems: { id: string; label: string }[];
  tableHeaders: string[];
  flexHeaders: string[];
}

interface VariantData {
  [key: string]: VariantProps;
}

const variantData = {
  [VariantTypes.CTA_LINKS]: {
    label: 'Top CTA Links',
    tabItems: [],
    tableHeaders: ['', 'CTA Link', 'CTA Set', 'Clicks'],
    flexHeaders: ['0.1', '0.4', '0.35', '0.15'],
  },
} as VariantData;

const TopCtaLinks = ({
  variant,
  params,
  addDataForExport,
}: ITopVariantsProps) => {
  const {
    data: topCtas,
    isLoading,
    isRefetching,
  } = useQuery(
    widget.detail(WidgetValues.TOP_CTA_LINKS, params),
    () => getReports({ ...params, users: 'all' }, 'metrics/top/ctas'),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const mapDataForExport = (data: any) => {
    let exportData: any = { title: '', data: [], arr: [] };
    exportData.title = WidgetLabels.TOP_CTA_LINKS;
    data?.topCtas?.forEach((el: any) => {
      exportData.data.push({
        'CTA Link': el.linkText,
        'CTA Set': el.setTitle,
        Clicks: el.cnt,
      });
    });
    return exportData;
  };
  React.useEffect(() => {
    addDataForExport(
      mapDataForExport({
        topCtas,
      })
    );
  }, [topCtas]);

  if (isLoading) {
    return <SkeletonLoader imageName='line-report' />;
  }

  const TopCtaLinksTableBody = (data: any) => {
    return data?.data?.map((item: any, index: number) => (
      <Body key={index} lastRow={index === data?.data?.length - 1}>
        <BodyParagraph flex='0.1' bold>
          {index + 1}
        </BodyParagraph>
        <TopVariantsTooltipWrapper flex='0.4'>
          <BodyParagraph>{item.linkText}</BodyParagraph>
          <Tooltip className='tooltip'>{item.linkText}</Tooltip>
        </TopVariantsTooltipWrapper>
        <TopVariantsTooltipWrapper flex='0.35'>
          <BodyParagraph>{item.setTitle}</BodyParagraph>
          <Tooltip className='tooltip'>{item.setTitle}</Tooltip>
        </TopVariantsTooltipWrapper>

        <BodyParagraph bold flex='0.15' textAlign='right'>
          {item.cnt}
        </BodyParagraph>
      </Body>
    ));
  };

  return (
    <WidgetWrapper>
      <ItemsWrapper mb='0px'>
        <WidgetTitle>
          Top CTA Links {isRefetching && <span>Updating ...</span>}
        </WidgetTitle>
      </ItemsWrapper>
      {topCtas?.length ? (
        <>
          <Header
            selectedLabel='Top CTA Links'
            variantData={variantData}
            variant={variant}
          />
          <TopCtaLinksTableBody data={topCtas} />
        </>
      ) : (
        <EmptyWidget />
      )}
    </WidgetWrapper>
  );
};

export default TopCtaLinks;
