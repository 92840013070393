import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Breadcrumbs } from '../../index.styled';
import { MdOutlineChevronRight } from 'react-icons/md';
import { SCREEN_VALUES } from 'lib/const/SuperAdminConstants';

export enum ClickType {
  RESELLER_LIST = 1,
  RESELLER_DETAILS = 2,
  CUSTOMER_LIST = 3,
  USER_LIST = 4,
  CUSTOMER_DETAILS = 5,
}

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: ${theme.palette.covideoBlue100};
  font-weight: 500;
  font-size: 15px;
  margin-right: 8px;
  cursor: pointer;
`;

type Props = {
  onClick: (type: ClickType) => void;
  showCustomerUserBreadcrumb: boolean;
  fromScreen: string;
  businessName: string;
  title: string;
  resellerName: string;
};

export const DetailsBreadcrumbs = ({
  onClick,
  showCustomerUserBreadcrumb,
  fromScreen,
  businessName,
  title,
  resellerName,
}: Props) => {
  const showResellerCrumb = fromScreen === SCREEN_VALUES.RESELLERS;
  const showCustomerCrumb = fromScreen === SCREEN_VALUES.CUSTOMERS;
  const showUserCrumb = fromScreen === SCREEN_VALUES.USERS;
  return (
    <Breadcrumbs>
      {showCustomerUserBreadcrumb && showResellerCrumb && (
        <Button onClick={() => onClick(ClickType.RESELLER_LIST)}>
          Resellers
        </Button>
      )}
      {showCustomerUserBreadcrumb && showResellerCrumb && (
        <MdOutlineChevronRight size={16} color={theme.palette.covideoBlue100} />
      )}
      {showCustomerUserBreadcrumb && showResellerCrumb && (
        <Button onClick={() => onClick(ClickType.RESELLER_DETAILS)}>
          {resellerName}
        </Button>
      )}
      {showCustomerUserBreadcrumb && showResellerCrumb && (
        <MdOutlineChevronRight size={16} color={theme.palette.covideoBlue100} />
      )}
      {showCustomerUserBreadcrumb && (showCustomerCrumb || showUserCrumb) && (
        <Button
          onClick={() => {
            onClick(
              fromScreen === SCREEN_VALUES.CUSTOMERS
                ? ClickType.CUSTOMER_LIST
                : ClickType.USER_LIST
            );
          }}
        >
          {fromScreen === SCREEN_VALUES.CUSTOMERS ? 'Customers' : 'Users'}
        </Button>
      )}
      {(showCustomerCrumb || showUserCrumb) && (
        <MdOutlineChevronRight size={16} color={theme.palette.covideoBlue100} />
      )}
      {(showCustomerCrumb || showResellerCrumb) && (
        <Button onClick={() => onClick(ClickType.CUSTOMER_DETAILS)}>
          {businessName}
        </Button>
      )}
      {(showCustomerCrumb || showResellerCrumb) && (
        <MdOutlineChevronRight size={16} color={theme.palette.covideoBlue100} />
      )}
      <span>{title}</span>
    </Breadcrumbs>
  );
};
