import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { TextInput } from 'lib/components';
import { SnippetType } from 'lib/const';
import { useAuth } from 'lib/context';
import {
  TextEditor,
  FontFamilyDropdown,
  FontSizeDropdown,
} from 'lib/components/textEditorAutomotive';
import { EmailContent } from 'app/pages/library/videos/videoList/components/SendAndShare';

const Input = styled(TextInput)`
  font-family: 'Work Sans';
  &:focus {
    border: 1px solid ${theme.palette.gray40};
    box-shadow: none;
  }
`;

const Info = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
  margin-top: 16px;
`;

const emailEditorToolbar = {
  options: ['fontFamily', 'fontSize', 'inline', 'remove', 'link'],
  fontFamily: {
    options: ['Work Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Verdana'],
    component: FontFamilyDropdown,
  },
  fontSize: {
    options: [10, 12, 14, 16, 18, 24, 30, 48, 72, 96],
    component: FontSizeDropdown,
  },
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon large-icon',
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon large-icon',
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon large-icon',
    },
  },
  remove: {
    className: 'toolbar-custom-icon toolbar-remove-icon medium-icon',
  },
  link: {
    inDropdown: true,
    className:
      'toolbar-custom-icon toolbar-custom-link-dropdown-option toolbar-link-icon',
    options: ['link', 'unlink'],
    link: {
      className: 'toolbar-custom-icon add-content-link toolbar-link-icon',
    },
    unlink: {
      className: 'toolbar-custom-icon add-content-unlink toolbar-unlink-icon',
    },
  },
};
type Props = {
  emailContent: EmailContent;
  setEmailContent: (newContent: EmailContent) => void;
  height?: string;
  placeholder?: string;
};
export const EmailEditor = ({
  emailContent,
  setEmailContent,
  placeholder = '',
  height = '160px',
}: Props) => {
  const { userData } = useAuth();
  const { droplrAccess } = userData;

  const [subject, setSubject] = useState<string>(emailContent.subject || '');
  const [body, setBody] = useState(emailContent.body || '');

  useEffect(() => {
    setEmailContent({ body: body, subject: subject });
  }, [subject, body]);

  const enableHtmlButton = ['50303', '1210'].includes(
    userData.customerId.toString()
  );

  const hideAddVariableButton = !userData.isAutomotive;
  const snippetButtonPosition = userData.isAutomotive ? 'bottom' : 'hidden';

  return (
    <>
      <Input
        onChange={(e: any) => setSubject(e.target.value)}
        value={subject}
        margin={'0 0 32px 0'}
        placeholder='Subject'
      />
      <TextEditor
        initialContent={body}
        onTextEditorChange={setBody}
        height={height}
        placeholder={placeholder}
        toolbar={emailEditorToolbar}
        snippetButtonPosition={snippetButtonPosition}
        showSourceButton={enableHtmlButton}
        showFilesButton={droplrAccess}
        snippetType={SnippetType.Email}
        forceSoftNewLine={true} /** SUS-1003 changes **/
        hideAddVariableButton={hideAddVariableButton}
        setSubject={setSubject}
      />
      <Info>Video thumbnail will be included in the body of the email.</Info>
    </>
  );
};
