import React from 'react';
import { NewModal } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { SelectedConversationType } from '../conversations.types';
import { useTypeOfConversation } from '../hooks/useTypeOfConversation';
import {
  CONVERSATION_TYPES,
  ISingleConversation,
} from 'lib/api/conversations/getSingleConversation';
import { Button } from 'react-covideo-common';

interface IRepassingConversationModal {
  onModalClose: () => void;
  setSelectedConversation: React.Dispatch<
    React.SetStateAction<SelectedConversationType>
  >;
  singleConversation: ISingleConversation;
}

export const ModalUserLeftLandingPage = ({
  onModalClose,
  setSelectedConversation,
  singleConversation,
}: IRepassingConversationModal) => {
  const conversationItems = useTypeOfConversation(singleConversation);
  return (
    <NewModal
      headerText='The customer closed the landing page!'
      closeModal={onModalClose}
      style={{
        content: {
          maxWidth: '582px',
        },
      }}
    >
      <Gap gap='32px' flexDirection='column' alignItems='flex-start'>
        <ParagraphNormal>
          The customer is no longer available via live chat.
        </ParagraphNormal>

        <Gap gap='8px' style={{ marginLeft: 'auto' }}>
          {conversationItems.map(item => {
            if (item.value === CONVERSATION_TYPES.SMS) {
              return (
                <Button
                  variant='secondary'
                  text='Switch to SMS'
                  onClick={() => {
                    setSelectedConversation(CONVERSATION_TYPES.SMS);
                    onModalClose();
                  }}
                />
              );
            }
            if (item.value === CONVERSATION_TYPES.EMAIL) {
              return (
                <Button
                  text='Switch to email'
                  onClick={() => {
                    setSelectedConversation(CONVERSATION_TYPES.EMAIL);
                    onModalClose();
                  }}
                />
              );
            }
            return null;
          })}
        </Gap>
      </Gap>
    </NewModal>
  );
};
