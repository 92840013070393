import { VerificationStatus } from './VerificationStatus';

export const AllResellerOption = {
  value: -1,
  label: 'All resellers',
};

export const AllCustomerOption = {
  value: -1,
  label: 'All customers',
};

export const ACTIVE_UNTIL = 2;
export const CustomerStatusOptions = [
  {
    value: VerificationStatus.INACTIVE,
    label: 'No',
  },
  {
    value: VerificationStatus.ACTIVE,
    label: 'Yes',
  },
  {
    value: ACTIVE_UNTIL,
    label: 'Active until...',
  },
];
