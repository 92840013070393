import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { hex } from 'color-convert';
import { MdPlayArrow } from 'react-icons/md';

type OnScreenPlayButtonProps = {
  r: number;
  g: number;
  b: number;
  hex: string;
  playButtonPosition?: string;
  isVideoPaused?: boolean;
  small?: boolean;
  isFreemium?: boolean;
};

const OnScreenPlayButtonWrapper = styled.div<OnScreenPlayButtonProps>`
  display: ${props => (props.isVideoPaused ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: ${props => props.hex};
  opacity: 0.5;
  font-size: ${props => (props.small ? '10px' : '40px')};
  cursor: pointer;
  border-radius: ${props => (props.isFreemium ? '80px' : 'none')};
  width:${props =>
    `${
      props.small ? '30px' : '54px'
    }`}; /*TODO: Check with Andrija, it's 120px on one screen and 100px on another*/
  height:${props =>
    `${
      props.small ? '30px' : '54px'
    }`}; /*TODO: Check with Andrija, it's 120px on one screen and 100px on another*/
  position: absolute;

  z-index: 9;
  ${props =>
    props.playButtonPosition === 'center' &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
    
  ${props =>
    props.playButtonPosition === 'top-left' &&
    css`
      top: 5%;
      left: 5%;
    `}

  ${props =>
    props.playButtonPosition === 'top-right' &&
    css`
      top: 5%;
      right: 5%;
    `}

  ${props =>
    props.playButtonPosition === 'bottom-left' &&
    css`
      bottom: 23%;
      left: 5%;
    `}

  ${props =>
    props.playButtonPosition === 'bottom-right' &&
    css`
      bottom: 23%;
      right: 5%;
    `}

  ${props =>
    props.playButtonPosition === 'none' &&
    css`
      display: none;
    `}
`;

type Props = {
  playerBackgroundColor: string;
  playButtonPosition: string;
  isVideoPaused: boolean;
  togglePlay: () => void;
  playerIconsColor: string;
  small?: boolean;
  isFreemium?: boolean;
};

export const OnScreenPlayButton = (props: Props) => {
  const {
    playerBackgroundColor,
    playButtonPosition,
    isVideoPaused,
    togglePlay,
    playerIconsColor,
    small,
    isFreemium,
  } = props;

  const newColor = hex.rgb(playerBackgroundColor);

  return (
    <OnScreenPlayButtonWrapper
      isVideoPaused={isVideoPaused}
      onClick={togglePlay}
      r={newColor[0]}
      g={newColor[1]}
      b={newColor[2]}
      hex={playerBackgroundColor}
      playButtonPosition={playButtonPosition}
      small={small}
      isFreemium={isFreemium}
    >
      <MdPlayArrow color={playerIconsColor} />
    </OnScreenPlayButtonWrapper>
  );
};
