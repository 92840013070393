import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { CommonTypography } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';

const SubmitButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: right;
  gap: 10px;
`;

const Body = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${theme.palette.covideoGray100};
`;

type Props = {
  handleModalClose(deactivate: boolean): void;
};

export const SMSSettingChangeConfirmationModal = ({
  handleModalClose,
}: Props) => {
  return (
    <NewModal
      headerText='Deactivate'
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose(false)}
      style={{
        content: {
          minWidth: '592px',
          paddingLeft: '32px',
        },
      }}
    >
      <Body>Are you sure you want to deactivate SMS settings?</Body>
      <Body>
        The assigned phone number will be forfeited, and recovery will not be
        possible afterward.
      </Body>
      <SubmitButtonWrapper>
        <Button
          variant='secondary'
          onClick={() => handleModalClose(false)}
          text='No, Keep Activated'
        />
        <Button
          variant='red'
          onClick={() => handleModalClose(true)}
          text='Yes, Deactivate'
        />
      </SubmitButtonWrapper>
    </NewModal>
  );
};
