import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';
import { SendMultipleSMSRequest } from './types';

interface IResponse {
  message: string;
}

const sendVideoSMS = async (
  videoId: string | number,
  body: SendMultipleSMSRequest
) => {
  const { data } = await EXPRESS_API.post(`/sms/send/${videoId}/video`, body);

  return data as IResponse;
};

export const useSendVideoSMS = (videoId: string | number) => {
  const { showError } = useToastError();

  return useMutation(
    (data: SendMultipleSMSRequest) => sendVideoSMS(videoId, data),
    {
      onError: (err: AxiosError) => showError(err),
    }
  );
};
