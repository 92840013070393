import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import {
  ContactsResponse,
  CreateContactsParams,
  QueryContactsParams,
} from './types';
import { groupKeys } from '../group/groupKeys';
import { contactsKeys } from './queryKeys';

export const createContacts = async (data: CreateContactsParams[]) => {
  const response = await EXPRESS_API.post('/contacts/bulk', data);
  return response.data;
};

export const useCreateContactsMutation = (
  onCreateContactsSuccess: () => void,
  queryParams: QueryContactsParams
) => {
  const queryClient = useQueryClient();
  return useMutation((data: CreateContactsParams[]) => createContacts(data), {
    onError: () => {
      errorToast({
        title: `An error occurred creating contacts, please try again later!`,
      });
    },
    onSuccess: async (data: ContactsResponse) => {
      onCreateContactsSuccess();
      successToast({
        title: `Imported ${data.count} new contact${
          data.count === 0 || data.count > 1 ? 's' : ''
        }. `,
      });
      await queryClient.invalidateQueries(contactsKeys.list(queryParams));
      await queryClient.invalidateQueries(groupKeys.groups());
    },
  });
};
