import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdOutlineUnfoldLess, MdOutlineUnfoldMore } from 'react-icons/md';

const Wrapper = styled.div<{ disabled: boolean }>`
  padding: 16px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.blue02};
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 6px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div<{ disabled: boolean }>`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.blue100};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
    `}
`;

const Expander = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
    `}
`;

const Content = styled.div<{ show: boolean }>`
  margin-top: 16px;
  display: ${({ show }) => (show ? `block` : `none;`)};
`;

type BadgeStatus = undefined | 'success' | 'danger' | 'default';

const Badge = styled.div<{ status: BadgeStatus }>`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  background-color: ${theme.palette.blue02};
  padding: 2px 8px;
  border-radius: 6px;
  margin-right: 14px;
  color: ${theme.palette.blue80};
  margin-left: auto;
  ${({ status }) => {
    if (status === 'success') {
      return css`
        background-color: ${theme.palette.green05};
        color: ${theme.palette.green80};
      `;
    }
    if (status === 'danger') {
      return css`
        background-color: ${theme.palette.red05};
        color: ${theme.palette.red80};
      `;
    }
  }}
`;

type Props = {
  children?: React.ReactNode;
  title: string;
  expandedDefault?: boolean;
  disabled?: boolean;
  badgeStatus?: undefined | 'success' | 'danger' | 'default';
  badgeText?: string;
  onExpand?: () => void;
};

export const StepCard = ({
  children = null,
  title,
  expandedDefault = false,
  badgeStatus,
  badgeText,
  disabled = false,
  onExpand,
}: Props) => {
  const [expanded, setExpanded] = useState(expandedDefault);
  const onChangeExpanded = () => {
    onExpand?.();
    setExpanded(prevExpanded => !prevExpanded);
  };
  return (
    <Wrapper disabled={disabled}>
      <Header>
        <Title disabled={disabled}>{title}</Title>
        {badgeText && <Badge status={badgeStatus}>{badgeText}</Badge>}
        <Expander disabled={disabled} onClick={() => onChangeExpanded()}>
          {expanded && (
            <MdOutlineUnfoldLess color={theme.palette.covideoBlue40} />
          )}
          {!expanded && (
            <MdOutlineUnfoldMore color={theme.palette.covideoBlue40} />
          )}
        </Expander>
      </Header>
      {children && <Content show={expanded}>{children}</Content>}
    </Wrapper>
  );
};
