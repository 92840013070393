import React from 'react';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
} from 'lib/components';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { SortButtons } from '../../../components/SortButtons';
import { Layout, NormalText, TableCell } from '../../../index.styled';
import { Gap } from 'lib/components/styles/layout';
import dayjs from 'dayjs';
import { ACCOUNT_CHANGES_COLUMNS, tableFields } from './constants';
import { AccountChange } from './types';
import { PaginationWrapper, UserWrapper, ViewerText } from './style';
import { USER_DATE_FORMAT } from 'lib/const/DateFormat';

interface AccountChangesTableProps {
  accountChanges: AccountChange[];
  count: number;
  page: number;
  size: number;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  onSortingUpdate: (
    accountChangesSortKey: string,
    order: PaginationConstants
  ) => void;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
}

export const AccountChangesTable = ({
  accountChanges,
  count,
  page,
  size,
  sortKey,
  order,
  onSortingUpdate,
  onPaginationChange,
}: AccountChangesTableProps) => {
  return (
    <Layout>
      <TableContextProvider
        total={count}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div
          className='scroll--container'
          style={{ overflowX: 'auto', paddingBottom: '20px' }}
        >
          <Table
            compact={true}
            relative={true}
            headers={[
              ...tableFields.map((item, index) => {
                return (
                  <TableCell key={index} width={item.width} onClick={() => {}}>
                    {item.label}
                    {item.sortable && item.sortKey && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value =>
                          onSortingUpdate(item.sortKey ?? '', value)
                        }
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            rows={accountChanges.map(
              (activity: AccountChange, index: number) => ({
                key: index,
                columns: [
                  ...tableFields.map((item, index) => {
                    let columnValue = <></>;
                    switch (item.value) {
                      case ACCOUNT_CHANGES_COLUMNS.CREATED_AT:
                        columnValue = (
                          <Gap flexDirection='column' gap='4px'>
                            <NormalText>
                              {dayjs(activity.createdAt).format(
                                USER_DATE_FORMAT
                              )}
                            </NormalText>
                            <ViewerText>
                              {dayjs(activity.createdAt).format('hh:mm:ss A')}
                            </ViewerText>
                          </Gap>
                        );
                        break;
                      case ACCOUNT_CHANGES_COLUMNS.CURRENT_USER_ID:
                        columnValue = (
                          <UserWrapper>
                            <NormalText
                              title={`${activity.currentUser?.firstName} ${activity.currentUser?.lastName}`}
                            >
                              {activity.currentUser?.firstName ?? ''}{' '}
                              {activity.currentUser?.lastName ?? ''}
                            </NormalText>
                            <ViewerText>
                              {activity.viewer?.firstName ?? ''}{' '}
                              {activity.viewer?.lastName ?? ''}
                            </ViewerText>
                          </UserWrapper>
                        );
                        break;
                      case ACCOUNT_CHANGES_COLUMNS.TARGET_USER_ID:
                        columnValue = (
                          <UserWrapper>
                            <NormalText
                              title={`${activity.targetUser?.firstName} ${activity.targetUser?.lastName}`}
                            >
                              {activity.targetUser?.firstName ?? ''}{' '}
                              {activity.targetUser?.lastName ?? ''}
                            </NormalText>
                          </UserWrapper>
                        );
                        break;
                      case ACCOUNT_CHANGES_COLUMNS.CHANGE_TYPE:
                        columnValue = (
                          <NormalText title={activity.changeLabel}>
                            {activity.changeLabel}
                          </NormalText>
                        );
                        break;
                      case ACCOUNT_CHANGES_COLUMNS.OLD_VALUE:
                        columnValue = (
                          <NormalText title={activity.oldValue}>
                            {activity.oldValue ?? 'null'}
                          </NormalText>
                        );
                        break;
                      case ACCOUNT_CHANGES_COLUMNS.NEW_VALUE:
                        columnValue = (
                          <NormalText title={activity.newValue}>
                            {activity.newValue ?? 'null'}
                          </NormalText>
                        );
                        break;
                      case ACCOUNT_CHANGES_COLUMNS.CHANGE_ORIGIN:
                        columnValue = (
                          <NormalText title={activity.changeOriginLabel}>
                            {activity.changeOriginLabel}
                          </NormalText>
                        );
                        break;

                      default:
                        columnValue = <></>;
                    }
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      >
                        {columnValue}
                      </TableCell>
                    );
                  }),
                ],
                onClick: () => {},
              })
            )}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
