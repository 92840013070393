import { useQuery } from 'react-query';
import { MeetingResponse } from './types';
import { meetingKeys } from './meetingKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getMeetingDetails = async (
  meetingId: string | null
): Promise<MeetingResponse> => {
  const response = await EXPRESS_API.get(`/meeting/${meetingId}`);
  return response.data;
};

export const useGetMeetingDetailsQuery = (meetingId: string) => {
  return useQuery(
    meetingKeys.meeting_details(meetingId),
    () => getMeetingDetails(meetingId),
    {
      enabled: meetingId !== '',
    }
  );
};
