import { getCurrentEnvUrl } from '../api/enviroments';
import { COVIDEO_APP_URL, LANDING_PAGE_URL, LIVE_PAGE_URL } from './types';

const landingPageEnviroments = {
  defaultValue: LANDING_PAGE_URL.DEVELOPMENT,
  development: LANDING_PAGE_URL.DEVELOPMENT,
  staging: LANDING_PAGE_URL.STAGING,
  sandbox: LANDING_PAGE_URL.SANDBOX,
  sustaining: LANDING_PAGE_URL.SUSTAINING,
  production: LANDING_PAGE_URL.PRODUCTION,
};

const landingPageUrl = getCurrentEnvUrl(landingPageEnviroments);

const livePageEnviroments = {
  defaultValue: LIVE_PAGE_URL.DEVELOPMENT,
  development: LIVE_PAGE_URL.DEVELOPMENT,
  staging: LIVE_PAGE_URL.STAGING,
  sandbox: LIVE_PAGE_URL.SANDBOX,
  production: LIVE_PAGE_URL.PRODUCTION,
};

const livePageUrl = getCurrentEnvUrl(livePageEnviroments);

const covideoAppDefaultUrl = process.env.REACT_APP_APPURL;

const covideoUrlEnviroments = {
  defaultValue: covideoAppDefaultUrl || COVIDEO_APP_URL.PRODUCTION,
  development: '',
  staging: covideoAppDefaultUrl || COVIDEO_APP_URL.STAGING,
  sandbox: covideoAppDefaultUrl || COVIDEO_APP_URL.SANDBOX,
  sustaining: covideoAppDefaultUrl || COVIDEO_APP_URL.SUSTAINING,
  production: covideoAppDefaultUrl || COVIDEO_APP_URL.PRODUCTION,
};

const covideoAppUrl = getCurrentEnvUrl(covideoUrlEnviroments);

export { landingPageUrl, livePageUrl, covideoAppUrl };
