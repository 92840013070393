export const VideoTemplateItems = [
  {
    title: 'Black Pattern (Your Default)',
  },
  {
    title: 'Blue Link Template',
  },
  {
    title: 'Gray Generic',
  },
  {
    title: 'Plain',
  },
  {
    title: 'White',
  },
  {
    title: 'Red Generic',
  },
];

// Free user'd default design wit typeID = 20 amd customerID = 0
export const FreemiumTemplateId = 332887;
