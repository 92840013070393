import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { VideoData } from 'lib/hooks';
import { createRef, useEffect } from 'react';
import { LoadingIndicator, Tabs } from 'lib/components';
import { ReactionSums } from './components/ReactionSums';
import { PlayerReactions } from './components/PlayerReactions';
import { VideoPlayer } from '../../../videoPlayer';
import { VideoReactionsTable } from './VideoReactionsTable';
import { VideoComments } from './VideoComments';
import { VideoReactionType } from 'lib/const/VideoReactionsDictionary';
import { Heading } from 'lib/components/styles/typography';
import { checkIfCustomerReactionsEnabled } from 'lib/utils/reactions';
import { useLocation } from 'react-router-dom';
import { HeaderWrapper } from 'lib/components/styles/layout';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { useVideoReactionsQuery } from 'lib/api/videos/useGetVideoReactions';
import { useRemoveVideoReactionMutation } from 'lib/api/videos/useRemoveVideoReactions';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  margin-bottom: 48px;
  align-items: flex-start;
`;

const BottomRow = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 48px;

  .tab-navigation {
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .tab-container,
  .tab-content {
    max-width: 100%;
    width: 100%;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: ${theme.palette.black};
`;

type Props = {
  videoDetails: VideoData;
};

export const VideoReactions = ({ videoDetails }: Props) => {
  const { userData } = useAuth();
  const search = useLocation().search;
  const tab = new URLSearchParams(search).get('tab');

  const reactionsCustomerEnabled = checkIfCustomerReactionsEnabled(userData);
  const [activeTab, setActiveTab] = React.useState(0);

  const {
    data: videoReactions,
    isLoading: loading,
    refetch: loadVideoReactions,
  } = useVideoReactionsQuery(videoDetails.id);
  const { mutateAsync: mutateRemoveReaction } =
    useRemoveVideoReactionMutation();

  const videoRef = createRef<HTMLVideoElement>();

  useEffect(() => {
    if (!reactionsCustomerEnabled) {
      return;
    }
    if (tab) {
      setActiveTab(parseInt(tab));
    }
    loadVideoReactions();
  }, [reactionsCustomerEnabled]);

  const handleDeleteVideoReaction = async (
    videoId: string,
    reactionId: string,
    type?: VideoReactionType
  ) => {
    await mutateRemoveReaction({ videoId, reactionId, type });
    await loadVideoReactions();
  };

  const commentCount = !videoReactions
    ? 0
    : Object.values(videoReactions).reduce(
        (sum, reaction) =>
          sum + (reaction?.type === VideoReactionType.COMMENT ? 1 : 0),
        0
      );
  const reactionCount = !videoReactions
    ? 0
    : Object.values(videoReactions).reduce(
        (sum, reaction) =>
          sum + (reaction?.type === VideoReactionType.EMOTE ? 1 : 0),
        0
      );

  if (!reactionsCustomerEnabled) {
    return (
      <HeaderWrapper style={{ paddingTop: 83 }}>
        <Heading>Reactions disabled.</Heading>
      </HeaderWrapper>
    );
  }

  return (
    <>
      <HelpDesk name={EHelpDesk.REACTIONS} />
      <HeaderWrapper>
        <Heading>{videoDetails.title}</Heading>
      </HeaderWrapper>
      <LoadingIndicator isLoading={loading} />
      {!loading && (
        <>
          <Row>
            <ReactionSums videoId={videoDetails.id} />
            <VideoWrapper>
              <VideoPlayer
                playerBackgroundColor={videoDetails.playerBackgroundColor}
                videoId={videoDetails.id}
                videoSource={videoDetails.videoSource}
                playButtonPosition={videoDetails.playButtonPosition}
                playerIconsColor={videoDetails.playerIconsAndTextColor}
                thumbnail={videoDetails.personalThumbnail}
                videoRef={videoRef}
                width='680px'
                previewAnnotation={true}
              />
              <PlayerReactions
                videoDetails={videoDetails}
                handleDeleteVideoReaction={handleDeleteVideoReaction}
              />
            </VideoWrapper>
          </Row>
          <BottomRow>
            <Tabs
              defaultActiveTab={activeTab}
              onChange={i => setActiveTab(i)}
              tabs={[
                {
                  title: `${reactionCount} Reaction${
                    reactionCount === 1 ? '' : 's'
                  }`,
                  component: (
                    <VideoReactionsTable
                      videoId={videoDetails.id}
                      handleDeleteVideoReaction={handleDeleteVideoReaction}
                    />
                  ),
                },
                {
                  title: `${commentCount} Comment${
                    commentCount === 1 ? '' : 's'
                  }`,
                  component: (
                    <VideoComments
                      videoId={videoDetails.id}
                      handleDeleteVideoReaction={handleDeleteVideoReaction}
                    />
                  ),
                },
              ]}
            ></Tabs>
          </BottomRow>
        </>
      )}
    </>
  );
};
