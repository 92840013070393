import React from 'react';
import styled from 'styled-components/macro';

const WidgetHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const WidgetTitle = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #001b53;
`;

interface WidgetHeaderProps {
  children?: JSX.Element;
  title: string;
  isRefetching: boolean;
}

const WidgetHeader = ({ children, title, isRefetching }: WidgetHeaderProps) => {
  return (
    <WidgetHeaderWrapper>
      <WidgetTitle>
        {title} {isRefetching && <span>Updating ...</span>}
      </WidgetTitle>
      {children}
    </WidgetHeaderWrapper>
  );
};

export default WidgetHeader;
