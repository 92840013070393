import { getUserTimezone } from 'app/pages/meetings/utils';
import { Gap } from 'lib/components/styles/layout';
import {
  ParagraphNormal,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { useAuth } from 'lib/context/auth';
import React from 'react';
import { Tooltip, useCovideoTheme } from 'react-covideo-common';
import { MdInfo } from 'react-icons/md';
import { MAX_EMAIL_RECIPIENTS } from '../const';

export const EmailCount = ({ emailLimit }: { emailLimit: number }) => {
  const {
    userData: { timezone, customer },
  } = useAuth();
  const emailsSentLimit = customer.emailsSentLimit || MAX_EMAIL_RECIPIENTS;
  const isLimitExceeded = emailLimit >= emailsSentLimit;
  const theme = useCovideoTheme();
  const color = isLimitExceeded
    ? theme.colors.danger[100]
    : theme.colors.black[80];

  const tz = getUserTimezone(undefined, timezone);

  return (
    <ParagraphSmall color={color} style={{ marginBottom: 8 }}>
      <Gap gap='4px' justifyContent='center' alignItems='center'>
        <strong> Your daily limit:</strong> {`${emailLimit}`}/
        {`${emailsSentLimit}`}
        <Tooltip
          position='right'
          extendStyles={{
            popup: {
              width: '380px',
            },
          }}
          popup={
            <div>
              <ParagraphNormal color={theme.colors.neutral[100]}>
                Your daily limit of emails you can send. If you reach your daily
                maximum you will not be able to sent more email.
                <strong>
                  {' '}
                  Daily limit will restart each day at {tz?.label || ''} time.
                </strong>
              </ParagraphNormal>
            </div>
          }
        >
          <Gap justifyContent='center' alignItems='center'>
            <MdInfo color={theme.colors.neutral[80]} size={18} />
          </Gap>
        </Tooltip>
      </Gap>
    </ParagraphSmall>
  );
};
