import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { TimelineRange } from '../../videoDetails/main/videoTrim/components';
import { toHHMMSSFF } from 'lib/utils/functions';
import useVideoLoadRetry from 'lib/hooks/useVideoLoadRetry';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';

const Row = styled.div`
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-left: 20px;
  height: 80px;
`;

type RangeProps = {
  width: string;
  offset: string;
};

const RangeWrapper = styled.div<RangeProps>`
  position: relative;
  left: ${props => props.offset};
  width: ${props => props.width};
`;

export const VideoRow = ({
  index,
  totalDuration,
  offset,
  width,
  video = {},
  setVideos = () => {},
  showPreviewTrack = true,
  hasProcessingVideos,
}: any) => {
  const valueRef = React.useRef<Array<number>>([0, 0]);
  const [_, setTrimDuration] = useState(video.trimDuration || 0);
  const [resetKey] = useState(0);
  const [__, setStart] = useState<number>(0);
  const [value, setValue] = useState<ReadonlyArray<number>>([
    0,
    video.duration,
  ]);
  const isProcessing = video.processing === VideoEncodingStatus.PROCESSING;

  const { videoData } = useVideoLoadRetry({
    videoId: video.id,
    enabled: isProcessing,
  });

  useEffect(() => {
    setVideos((videos: any) => {
      const vtm = [...videos];
      const startSec = value[0];
      const endSec = value[1];
      const time = `#t=${startSec ? startSec : 0}${endSec ? ',' + endSec : ''}`;

      vtm[index] = {
        ...vtm[index],
        start: toHHMMSSFF(value[0]),
        end: toHHMMSSFF(value[1]),
        startSec,
        endSec,
        source: ('' + video.source).split('#t=')[0] + time,
      };
      return vtm;
    });
  }, [value]);

  useEffect(() => {
    if (
      !!videoData &&
      videoData.processing !== VideoEncodingStatus.PROCESSING &&
      isProcessing
    ) {
      setVideos((prev: any) => {
        const vtm = [...prev];
        vtm[index].processing = videoData.processing;
        return vtm;
      });
    }
  }, [videoData]);

  const onUpdate = (args: any) => {
    const duration = args[1] - args[0];
    setVideos((videos: any) => {
      const vtm = [...videos];
      vtm[index] = {
        ...vtm[index],
        width: (duration * 100) / totalDuration,
        trimDuration: duration,
      };
      return vtm;
    });
  };

  useEffect(() => {
    if (!totalDuration) {
      return;
    }
    setVideos((videos: any) => {
      const vtm = [...videos];
      vtm[index] = {
        ...vtm[index],
        width: (video.trimDuration * 100) / totalDuration,
      };
      return vtm;
    });
  }, [totalDuration]);

  return useMemo(
    () => (
      <Row key={video.id}>
        <RangeWrapper offset={`${offset || 0}px`} width={`${width || 0}px`}>
          {!!video.duration && video.ref && !hasProcessingVideos && (
            <TimelineRange
              key={resetKey}
              videoRef={video.ref}
              videoDuration={video.duration}
              videoSource={video.videoSource}
              setStart={setStart}
              setValue={setValue}
              value={value}
              valueRef={valueRef}
              setTrimDuration={setTrimDuration}
              hideTime={true}
              onSlideEnd={onUpdate}
              marginLeft={'0px'} /* SUS-905 changes */
              showPreviewTrack={showPreviewTrack}
            />
          )}
        </RangeWrapper>
      </Row>
    ),
    [video.width, video.ref, value, offset, width, video.processing]
  );
};
