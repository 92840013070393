import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { useMutation } from 'react-query';
import { MeetingResponse } from './types';

interface ICreateMeetingParams
  extends Omit<Partial<MeetingResponse>, 'attendees'> {
  attendees?: { email: string }[];
}

export const createMeeting = async (data: ICreateMeetingParams) => {
  const response = await EXPRESS_API.post(`/meeting`, data);
  return response.data;
};

export const useCreateMeetingMutation = (
  handleCloseLiveMeetingModal: () => void
) => {
  return useMutation((data: ICreateMeetingParams) => createMeeting(data), {
    onError: () => {
      errorToast({
        title:
          'An error occurred creating the live meeting, please try again later!',
      });
    },
    onSuccess: () => {
      handleCloseLiveMeetingModal();
    },
  });
};
