import { getCovideoBoards } from 'lib/api/covideoBoardApi';
import { Search, LoadingIndicator, Dropdown } from 'lib/components';
import { useAuth } from 'lib/context';
import React, { useEffect, useState } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from '../../../../lib/style';
import { SortFields } from 'lib/const/Droplr';
import { getBoards } from 'lib/api/droplr/getBoards';
import { Button } from 'react-covideo-common';

const ListView = styled.div`
  margin-top: 40px;
  height: 400px;
  max-height: 400px;
  .listThumbnailIcons {
    width: 24px;
    height: auto;
    margin: auto;
    display: block;
  }
`;

const BoardsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 3px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  margin-bottom: 8px;
  height: 64px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary[100]};
  }
`;

const BoardsItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 24px;
  width: calc(100% - 125px);
`;

const BoardsItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoardsSelectedButton = styled.div<any>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${props => props.color};
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  position: static;
  height: 36px;
  right: 12px;
  top: calc(50% - 36px / 2);
  background: rgba(255, 139, 34, 0.05);
  border: 1px solid ${props => props.color};
  border-radius: 5px;
  margin-right: 12px;
  box-sizing: border-box;
  cursor: default;
  width: 85px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  .fullWidth {
    width: 100%;
  }
`;

interface TabStyleProperties {
  width?: string;
}

const Tab = styled.div<TabStyleProperties>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  color: ${theme.palette.coal};
  align-items: center;
  color: #9297a2;
  width: 45%;
  margin: 0;
  justify-content: center;
  height: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 0 0 100%;
  flex-wrap: wrap;
  .activeTab {
    color: ${props => props.color};
    border-bottom: 1px solid ${props => props.color};
  }
  border-bottom: 1px solid #eeeff2;
  margin-top: 25px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const EmptyBoards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

type Props = {
  selectedFile?: any;
  onSelect: (item: any, itemType: string) => void;
  isQrModal: boolean;
  handleModalClose?: () => void;
};

export const CtaBoardsModalContent = (props: Props) => {
  const {
    onSelect,
    selectedFile,
    isQrModal,
    handleModalClose = () => null,
  } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [boards, setBoards] = useState([]);
  const [ownedBoards, setOwnedBoards] = useState([]);
  const [sharedBoards, setSharedBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredBoard, setHoveredBoard] = useState(null);
  const [boardThumbnailsMapping, setBoardThumbnailsMapping] = useState<{
    [key: string]: string;
  }>({});
  const [dropType, setDropType] = useState<'owned' | 'shared'>('owned');
  const [field, setField] = useState(SortFields[1]);
  const themes = useTheme();

  const {
    userData: { userId, customerId },
  } = useAuth();
  const fallBackThumbnail =
    'https://www.covideo.com/images/boards/folderSet.svg';

  React.useEffect(() => {
    let boards: any = [];
    getBoards({
      userId: Number(userId),
      customerId: Number(customerId),
    })
      .then(data => {
        if (data && data.boards && data.boards.length) {
          boards = data.boards;
          const ownedBoards = boards.filter((board: any) => {
            return board.userStatus === 'creator';
          });
          setOwnedBoards(ownedBoards);
          const sharedBoards = boards.filter((board: any) => {
            return board.userStatus !== 'creator';
          });
          setSharedBoards(sharedBoards);

          boards = boards
            .filter((board: any) => {
              return board.userStatus === 'creator';
            })
            .map((board: any) => {
              if (selectedFile && selectedFile.linkValue === board.shortlink) {
                board.selected = true;
              }

              return board;
            });

          return getCovideoBoards({
            userId: Number(userId),
            customerId: Number(customerId),
          });
        } else {
          setIsLoading(false);
        }
      })
      .then(result => {
        return setBoardAndThumbnailMappingHelper(result.boards);
      })
      .then(() => {
        setBoards(boards);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    dropType === 'owned' && isQrModal
      ? setBoards(ownedBoards)
      : setBoards(sharedBoards);
  }, [dropType]);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const setBoardAndThumbnailMappingHelper = (boards: any) => {
    return new Promise(resolve => {
      if (!boards || !boards.length) {
        return resolve({});
      }

      const mapping: { [key: string]: string } = {};
      boards.forEach((board: any) => {
        if (board && board.droplrBoardId) {
          mapping[board.droplrBoardId] = board.thumbnailUrl;
        }
      });

      setBoardThumbnailsMapping(mapping);
      return resolve(mapping);
    });
  };

  return (
    <div>
      {isQrModal && (
        <TabContainer color={themes.colors.primary[100]}>
          <Tab
            className={dropType === 'owned' ? 'activeTab' : ''}
            onClick={() => {
              setDropType('owned');
            }}
            title={'myFiles'}
          >
            &nbsp;My Boards
          </Tab>
          <Tab
            className={dropType === 'shared' ? 'activeTab' : ''}
            onClick={() => {
              setDropType('shared');
            }}
            title={'sharedWithMe'}
          >
            &nbsp;Shared with me
          </Tab>
        </TabContainer>
      )}
      <Menu>
        <Row>
          <Col>
            <Search
              placeholder={`Search boards...`}
              onSearch={onSearch}
              prevSearch={searchQuery}
              className={'fullWidth'}
              width={'50%'}
            />
          </Col>

          <Col style={{ marginLeft: '12px' }}>
            <Dropdown
              value={field}
              onChange={newValue => {
                setField(newValue);
              }}
              options={SortFields}
              dropdownHeight={450}
              height={40}
            />
          </Col>
        </Row>
      </Menu>
      {isLoading && <LoadingIndicator isLoading={isLoading} height='200px' />}
      {!isLoading && (
        <ListView>
          {boards.map((board: any) => {
            if (searchQuery) {
              if (
                board.name.toLowerCase().search(searchQuery.toLowerCase()) < 0
              ) {
                return <React.Fragment key={board.id} />;
              }
            }
            return (
              <BoardsItem
                key={board.id}
                onMouseEnter={() => {
                  setHoveredBoard(board.id);
                }}
                onMouseLeave={() => {
                  setHoveredBoard(null);
                }}
              >
                <BoardsItemWrapper>
                  <div
                    style={{
                      position: 'relative',
                      height: '100%',
                      width: '125px' /*** SUS-826 changes ***/,
                      cursor: 'pointer',
                      maxWidth: '125px',
                      minWidth: '125px',
                    }}
                  >
                    <img
                      alt={board.name + '_thumbnail'}
                      src={
                        boardThumbnailsMapping[board.id] || fallBackThumbnail
                      }
                      style={{
                        borderRadius: '2px',
                        width: '130px' /*** SUS-826 changes ***/,
                        position: 'absolute',
                      }}
                    />
                    {hoveredBoard === board.id && !isQrModal && (
                      <div
                        style={{
                          borderRadius: '2px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          width: '125px' /*** SUS-826 changes ***/,
                          height: '64px',
                          background:
                            'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
                        }}
                        onClick={() => {
                          window.open(
                            board.shortlink.includes('http')
                              ? board.shortlink
                              : `http://${board.shortlink}`,
                            '_blank'
                          );
                        }}
                      >
                        <BsBoxArrowUpRight size='28px' color={'white'} />
                      </div>
                    )}
                  </div>
                  <BoardsItemTitle>{board.name}</BoardsItemTitle>
                </BoardsItemWrapper>
                {!board.selected && hoveredBoard === board.id && (
                  <Button
                    onClick={() => {
                      board.thumbnailUrl = boardThumbnailsMapping[board.id];
                      onSelect(board, 'board');
                      handleModalClose();
                    }}
                    text='Select Board'
                    style={{
                      marginRight: 16,
                    }}
                  />
                )}
                {board.selected && (
                  <BoardsSelectedButton color={themes.colors.primary[100]}>
                    Selected
                  </BoardsSelectedButton>
                )}
              </BoardsItem>
            );
          })}
          {!boards.length && <EmptyBoards>No boards found.</EmptyBoards>}
        </ListView>
      )}
    </div>
  );
};
