import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 120px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: none !important;
    border: 1px solid #001b53;
  }
  &:hover {
    border: 1px solid #001b53;
    cursor: pointer;
  }
  padding: 8px 16px;
  color: hsl(0, 0%, 20%);
`;

export const Gap = styled.div<{
  gap?: string;
  center?: boolean;
  m?: string;
  direction?: string;
}>`
  display: flex;
  margin: ${({ m }) => m || '0px'};
  gap: ${({ gap }) => gap || '16px'};
  flex-direction: ${({ direction }) => direction || 'row'};
`;
