import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import dayjs from 'dayjs';
import { VideoReactionsDictionary } from 'lib/const/VideoReactionsDictionary';
import { VideoReactionItem } from 'lib/context';
import { toMMSSTimestamp } from './functions';

type SearchDataProps = {
  fieldsToBeSearched: Array<string>;
  searchTerm?: string;
  allData: Array<any>;
};

export const getSearchData = ({
  fieldsToBeSearched,
  searchTerm,
  allData,
}: SearchDataProps) => {
  let tableRows = [] as any;

  if (searchTerm) {
    allData.forEach(item => {
      const items = [] as any;

      Object.keys(item).forEach(itemKey => {
        if (fieldsToBeSearched.includes(itemKey)) {
          item[itemKey] &&
            items.push(
              item[itemKey]
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            );
        }
      });

      if (items.some((el: boolean) => el)) tableRows.push(item);
    });
  } else tableRows = allData;

  return tableRows;
};

export const prepareExportDataItem = (item: VideoReactionItem) => {
  const { playbackPosition, emoticonId, email, name, createdAt, phone, text } =
    item;
  const emoticon = emoticonId
    ? VideoReactionsDictionary[emoticonId]
    : { title: '' };
  const escapedText = text ? `"${text.replace(/\"/g, '""')}"` : '';

  return {
    playbackPosition: toMMSSTimestamp(playbackPosition, 0),
    ...(emoticonId && { emoticonId: emoticon.title }),
    ...(text && { text: escapedText }),
    name,
    email: email ? email : phone,
    createdAt: dayjs(createdAt).format(`${STANDARD_DATE_FORMAT} hh:mm:ss A`),
  };
};
