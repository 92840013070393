import { annotationTypes } from './types';
import { CovideoIcon } from 'lib/components/CovideoIcon';
import React from 'react';

export const positions = [
  'Top Left',
  'Top Center',
  'Top Right',
  'Bottom Left',
  'Bottom Center',
  'Bottom Right',
  'Center',
];

export const positionsWithIcons = [
  {
    value: 'Bottom Center',
    icon: <CovideoIcon name='bottom-center' width={20} />,
    for: annotationTypes.ANNOTATION_TEXT,
  },
  {
    value: 'Bottom Left',
    icon: <CovideoIcon name='bottom-left' width={20} />,
    for: annotationTypes.ANNOTATION_TEXT,
  },

  {
    value: 'Bottom Right',
    icon: <CovideoIcon name='bottom-right' width={20} />,
    for: annotationTypes.ANNOTATION_TEXT,
  },

  {
    value: 'Top Left',
    for: annotationTypes.ANNOTATION_IMAGE,
    icon: <CovideoIcon name='top-left' width={20} />,
  },
  {
    value: 'Top Right',
    for: annotationTypes.ANNOTATION_IMAGE,
    icon: <CovideoIcon name='top-right' width={20} />,
  },
];
