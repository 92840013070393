import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Contact } from 'lib/api';

import {
  LoadingIndicator,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { NoHistory } from './NoHistory';
import { useQueryEmailReceiver } from 'lib/api/emailReceiver/useQueryEmailReceiver';
import { EmailEvent } from 'lib/api/emailReceiver/types';
import { IOnPaginationChange } from 'app/pages/account/userAccount/schedule/types';

const Content = styled.div`
  width: 100%;
`;

const TableCellHeader = styled.div`
  text-align: left;
`;

const TableCell = styled.div`
  text-align: left;
`;

const ReceiverStatus = {
  SENT: {
    value: 0,
    text: 'Sent',
    prefix: 'Email: ',
    color: 'black',
    defaultText: 'No Subject',
  },
  OPENED: {
    value: 1,
    text: 'Opened',
    prefix: 'Email: ',
    color: 'orange',
    defaultText: 'No Subject',
  },
  WATCHED: {
    value: 2,
    text: 'Video Play',
    prefix: 'Video: ',
    color: 'blue',
    defaultText: 'Missing Video Title',
  },
  CLICKED: {
    value: 3,
    text: 'Video Clicked',
    prefix: 'Exit Link: ',
    color: 'blue',
    defaultText: 'Missing Video Title',
  },
  EXITLINK: {
    value: 4,
    text: 'CTA Click',
    prefix: 'Exit Link: ',
    color: 'blue',
    defaultText: 'Missing ExitLink Title',
  },
  BOUNCED: {
    value: 5,
    text: 'Bounced',
    prefix: 'Email: ',
    color: 'orange',
    defaultText: 'No Subject',
  },
  DROPPED: {
    value: 6,
    text: 'Dropped',
    prefix: 'Email: ',
    color: 'orange',
    defaultText: 'No Subject',
  },
};
const getReceiverStatus = (item: any) => {
  let status = ReceiverStatus.SENT;
  switch (item.EventType) {
    case 'OPEN':
      status = ReceiverStatus.OPENED;
      break;
    case 'SENT':
      status = ReceiverStatus.SENT;
      break;
    case 'CLICKED':
      status = ReceiverStatus.CLICKED;
      break;
    case 'EXITLINK':
      status = ReceiverStatus.EXITLINK;
      break;
    case 'WATCHED':
      status = ReceiverStatus.WATCHED;
      break;
    case 'BOUNCE':
      status = ReceiverStatus.BOUNCED;
      break;
    case 'DROPPED':
      status = ReceiverStatus.DROPPED;
      break;
  }
  return status;
};

type Props = {
  contact: Contact;
  preloadData?: boolean;
};
export const ContactActivityHistory = ({
  contact,
  preloadData = false,
}: Props) => {
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const contactEventsParams = {
    search: contact.email,
    start: page * size,
    limit: size,
    searchExactEmail: true,
  };

  const {
    data: contactEventsData,
    isLoading,
    refetch,
  } = useQueryEmailReceiver({
    params: contactEventsParams,
  });
  const contactEvents = contactEventsData?.events ?? [];

  useEffect(() => {
    if (contactEventsData && contactEventsData.events) {
      setCount(contactEventsData.count);
    }
  }, [contactEventsData]);

  useEffect(() => {
    if (!preloadData || isLoading || !contact) {
      return;
    }
    refetch();
  }, [contact, preloadData, page, size]);

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: IOnPaginationChange) => {
    if (page !== newPage || size !== newSize) {
      setPage(newPage);
      setSize(newSize);
    }
  };

  return (
    <Content>
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} />
      ) : !isLoading && contactEvents.length == 0 ? (
        <NoHistory />
      ) : (
        <div style={{ marginTop: 16 }}>
          <TableContextProvider
            initPage={page}
            initSize={size}
            total={count}
            onChange={onPaginationChange}
          >
            <Table
              compact={true}
              columnWidths={[200, 230]}
              headers={[
                <TableCellHeader>Date</TableCellHeader>,
                <TableCellHeader>Action</TableCellHeader>,
                <TableCellHeader>Activity</TableCellHeader>,
              ]}
              hoverable={false}
              rows={contactEvents.map((x: EmailEvent, index: number) => {
                let status = getReceiverStatus(x);
                return {
                  key: index,
                  columns: [
                    <TableCell>
                      {
                        new Date(parseInt(x.StatusUpdateTime + '000'))
                          .toLocaleString()
                          .split(',')[0]
                      }
                    </TableCell>,
                    <TableCell>{status.text}</TableCell>,
                    <TableCell>
                      {status.prefix + (x.Title ? x.Title : status.defaultText)}
                    </TableCell>,
                  ],
                  onClick: () => {},
                };
              })}
            />
            <TableFooter>
              <TablePaginationNew />
              <TablePaginationSizeNew globalName='contact_activity' />
            </TableFooter>
          </TableContextProvider>
        </div>
      )}
    </Content>
  );
};
