import {
  formatDeliveryTime,
  formatMeetingTime,
  identifyDate,
} from 'app/pages/account/userAccount/schedule/utils';
import { MeetingResponse } from 'lib/api/meetings/types';
import { HoverPopup } from 'lib/components';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { getDisplayName } from 'lib/utils/functions';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import { TAB_IDS } from '../pages/MeetingList';
import { successToast } from 'lib/components/toasts/success';
import { livePageUrl } from 'configs/domains/main';
import { FaRegCalendarXmark } from 'react-icons/fa6';
import { MdEdit, MdPerson, MdVideoCameraFront } from 'react-icons/md';
import { IoMdSend } from 'react-icons/io';

type MeetingListItemProps = {
  data: MeetingResponse;
  startNow: (meetingId: string | null) => void;
  sendInvite: (meetingId: string) => void;
  onCancel: (meeting: MeetingResponse) => void;
  onDelete: (meeting: MeetingResponse) => void;
  edit: () => void;
  emailSending?: boolean;
  activeTab: TAB_IDS;
};
type CtaButtonProps = {
  isDisabled?: boolean;
};
type CTAButtonTextProps = {
  color?: string;
};

const Row = styled.div`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  margin-bottom: 8px;
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 6px;
  padding: 26px 24px 26px 40px;
`;
const Left = styled.div`
  width: 160px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Center = styled.div`
  width: 100%;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Right = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
`;
const Title = styled.h3`
  color: ${theme.palette.gray100};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
`;
const Info = styled.div`
  color: ${theme.palette.gray80};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  > b {
    font-weight: 600;
  }
`;
const CTAButton = styled.div<CtaButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 34px;
  padding: 4px;
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `};
`;
const CTAButtonText = styled.span<CTAButtonTextProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.color ? props.color : theme.palette.neutral80)};
  padding-top: 8px;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  svg {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 14px;
  }
  span {
    line-height: 20px;
    color: ${theme.palette.neutral80};
    font-weight: 400;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
  }
`;
const MeetingDuration = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.neutral100};
  margin-left: auto;
  margin-right: auto;
`;
const MeetingDate = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.neutral80};
  margin-left: auto;
  margin-right: auto;
`;
const MeetingDateBadge = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary[80]};
  background-color: ${({ theme }) => theme.colors.primary[5]};
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  width: 83px;
  height: 20px;
  margin-top: 6px;
  text-align: center;
`;
const PopupContentWrapper = styled.div`
  text-align: center;
`;
export const MeetingListItem = (props: MeetingListItemProps) => {
  const {
    meetingId,
    duration,
    deliveryUnixTimestamp,
    title,
    deliveryTime,
    userId: hostId,
    schedulerId,
    host,
    scheduler,
    attendees,
    nextOccurrence,
  } = props.data;
  const { edit, sendInvite, emailSending, onCancel, onDelete, activeTab } =
    props;
  const { userData } = useAuth();
  const isEndedOrCanceled =
    activeTab === TAB_IDS.ENDED || activeTab === TAB_IDS.CANCELED;
  const isRecurring = activeTab === TAB_IDS.RECURRING;

  const meetingDate = new Date(
    isRecurring ? nextOccurrence * 1000 : deliveryUnixTimestamp * 1000
  );
  const meetingTime = formatMeetingTime(meetingDate, duration, userData);
  const dayIdentifier = identifyDate(meetingDate);

  const showAssignTo =
    Number(userData.id) === schedulerId && schedulerId !== hostId;
  const showScheduledBy =
    Number(userData.id) !== schedulerId && schedulerId !== hostId;
  const attendeeEmails = (attendees || [])
    .map(attendee => attendee.email)
    .filter(Boolean)
    .join(', ');

  const getParticipantInfo = () => {
    if (!attendees?.length) {
      return 'No participants';
    }
    if (attendees.length === 1) {
      return attendees[0].email;
    }
    return `${attendees.length} participants`;
  };

  const onRedirectToMeeting = () => {
    if (!!meetingId) {
      const liveLink = livePageUrl + meetingId;
      navigator.clipboard.writeText(liveLink);
      successToast({
        title: 'Live link copied to clipboard',
      });
      window.open(liveLink, '_blank');
    }
  };

  return (
    <React.Fragment>
      <Row>
        <HoverPopup
          width='200px'
          position='above'
          padding='8px 12px'
          cursor='initial'
          showTriangle
          hoverable={
            <Left>
              <MeetingDuration>{meetingTime.meetingDuration}</MeetingDuration>
              {dayIdentifier === '' ? (
                <MeetingDate>{meetingTime.day}</MeetingDate>
              ) : (
                <MeetingDateBadge>{dayIdentifier}</MeetingDateBadge>
              )}
            </Left>
          }
          popup={
            <PopupContentWrapper>
              {isRecurring
                ? meetingDate.toDateString()
                : formatDeliveryTime(deliveryTime || '')}
            </PopupContentWrapper>
          }
        />

        <Center>
          <TitleWrapper>
            <Title>{title}</Title>
            <>
              <MdPerson color={theme.palette.neutral40} size={16} />
              <span title={attendees?.length > 1 ? attendeeEmails : ''}>
                {getParticipantInfo()}
              </span>
            </>
          </TitleWrapper>
          <Info>
            Meeting ID: <b>{meetingId}</b>
          </Info>
          {showAssignTo && (
            <Info>
              Assigned to:{' '}
              <b>
                {getDisplayName([host?.firstName || '', host?.lastName || ''])}
              </b>
            </Info>
          )}
          {showScheduledBy && (
            <Info>
              Scheduled by:{' '}
              <b>
                {getDisplayName([
                  scheduler?.firstName || '',
                  scheduler?.lastName || '',
                ])}
              </b>
            </Info>
          )}
        </Center>
        <Right>
          {!isEndedOrCanceled && (
            <CTAButton onClick={() => onRedirectToMeeting()}>
              <MdVideoCameraFront color={theme.palette.neutral80} size={20} />
              <CTAButtonText>Start Now</CTAButtonText>
            </CTAButton>
          )}
          <>
            {!isEndedOrCanceled && !isRecurring && (
              <CTAButton
                onClick={() => {
                  !!attendees?.length &&
                    !emailSending &&
                    meetingId &&
                    sendInvite(meetingId);
                }}
                isDisabled={!attendees?.length || emailSending}
              >
                <IoMdSend color={theme.palette.neutral80} size={18} />
                <CTAButtonText>Invite</CTAButtonText>
              </CTAButton>
            )}

            <CTAButton onClick={edit}>
              <MdEdit color={theme.palette.neutral80} height={18} />
              <CTAButtonText>Edit</CTAButtonText>
            </CTAButton>
          </>

          {isEndedOrCanceled && (
            <CTAButton onClick={() => onDelete(props.data)}>
              <FaRegCalendarXmark color={theme.palette.red100} size={20} />
              <CTAButtonText color={theme.palette.red100}>Delete</CTAButtonText>
            </CTAButton>
          )}

          {!isEndedOrCanceled && (
            <CTAButton onClick={() => onCancel(props.data)}>
              <FaRegCalendarXmark color={theme.palette.red100} size={20} />
              <CTAButtonText color={theme.palette.red100}>Cancel</CTAButtonText>
            </CTAButton>
          )}
        </Right>
      </Row>
    </React.Fragment>
  );
};
