import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CheckboxInput, Dropdown } from 'lib/components';
import { Option, freemiumCTAOption } from '..';
import { debounce } from 'lodash';
import { LandingPageOption } from '..';
import { CTASet, EmailIconDefault } from 'lib/api';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { checkIfOverlayValid } from 'lib/utils/functions';
import { ModalVideoExpiration } from 'lib/components/modal/ModalVideoExpiration';
import {
  ExpDropdownItemsProps,
  LINK_EXPIRATION_TYPES,
  dropdownItems,
} from 'app/pages/library/videos/components/videoExpiration/videoExpiration';
import dayjs from 'dayjs';
import { DATETIME_MERIDIEM } from 'lib/utils/datetime';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { GetQuickshareParams } from 'lib/api/quickshare/useGetQuickshareDetails';
import { QUOTIBLE_LANDING_PAGE_TYPE_ID } from 'lib/const/LandingPage';

type RowProps = {
  marginTop?: string;
};

const Row = styled.div<RowProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

const CheckboxInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

type FormGroupProps = {
  marginBottom?: string;
};

const FormGroup = styled.div<FormGroupProps>`
  width: 100%;
`;

const Label = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin: 0 0 8px 0;
`;
const CheckboxesLabel = styled(Label)`
  margin: 0 0 16px 0;
`;

const CheckboxLabel = styled.label`
  margin-left: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

const AddWebsiteButton = styled.a`
  max-height: 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  text-align: right;
  color: ${theme.palette.blue80};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

type NewWebsiteInputProps = {
  error?: boolean;
};
const NewWebsiteInput = styled.input<NewWebsiteInputProps>`
  width: calc(100% - 24px);
  height: 24px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  border: solid 1px
    ${({ error }) => (error ? theme.palette.error : theme.palette.lightgray)};
  outline: none;
  padding: 8px 12px;
`;

const checkboxes: Option[] = [];

type Props = {
  templateOptions: LandingPageOption[];
  linksetsData: Array<CTASet>;
  overlaysData: Array<Option>;
  shareData: GetQuickshareParams;
  setShareData: (shareData: GetQuickshareParams) => void;
  selectedTemplate: LandingPageOption;
  setSelectedTemplate: (option: LandingPageOption) => void;
  emailIconOptions: EmailIconDefault[];
  selectedEmailIcon?: EmailIconDefault;
  setSelectedEmailIcon: Function;
};

export const ShareSettings = ({
  linksetsData,
  templateOptions,
  overlaysData,
  shareData,
  setShareData,
  selectedTemplate,
  setSelectedTemplate,
  emailIconOptions,
  selectedEmailIcon,
  setSelectedEmailIcon,
}: Props) => {
  const isQuotibleTemplateSelected =
    selectedTemplate.typeId === QUOTIBLE_LANDING_PAGE_TYPE_ID;
  const { userData } = useAuth();
  const isCTASEnabled = checkIfFeatureIsEnabled(userData, productFeature.CTAS);
  const [linksetOptions, setLinksetOptions] = useState<Option[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<Option[]>([]);
  const [newWebsiteSelected, setNewWebsiteSelected] = useState(false);
  const getCustomDateInitialValue = () => {
    return new Date();
  };
  const isCustomLinkExp = (id: string) => {
    return id === LINK_EXPIRATION_TYPES.CUSTOM;
  };
  const getExpirationInitialValue = () => {
    return {
      ...dropdownItems[0],
    };
  };
  const [customDate, setCustomDate] = useState<Date>(
    getCustomDateInitialValue()
  );
  const [customOpened, setCustomOpened] = useState<boolean>(false);
  const [expiration, setExpiration] = useState<ExpDropdownItemsProps>(
    getExpirationInitialValue()
  );
  const closeCustomLinkExpirationModal = () => {
    setCustomOpened(false);
  };

  const setLinkExpiration = () => {
    setExpiration({
      id: LINK_EXPIRATION_TYPES.CUSTOM,
      value: customDate,
      label: dayjs(customDate.toString()).format(DATETIME_MERIDIEM),
    });
    setCustomOpened(false);
  };
  const isWebsiteOverlaySelected = selectedTemplate.value.toString() === '-1';
  const isExpirationActivated = userData.customer.videoExpiration === '1';
  useEffect(() => {
    if (newWebsiteSelected || !overlaysData.length) {
      return;
    }
    setShareData({ ...shareData, overlayId: overlaysData[0].value });
  }, [newWebsiteSelected]);

  const setWebsite = debounce(value => {
    isWebsiteOverlaySelected
      ? setShareData({ ...shareData, overlayId: value })
      : setShareData({ ...shareData, linksetId: value });
  }, 1000);

  useEffect(() => {
    if (!isCTASEnabled) {
      setLinksetOptions([freemiumCTAOption]);
      return;
    }
    let defaultArray = [{ value: '-1', label: 'None' }];
    defaultArray = (linksetsData || []).every(key => key.id !== -1)
      ? defaultArray.concat(
          (linksetsData || []).map(({ id, title }) => ({
            value: id.toString(),
            label: title,
          }))
        )
      : (linksetsData || []).map(({ id, title }) => ({
          value: id.toString(),
          label: title,
        }));
    setLinksetOptions(defaultArray);
  }, [linksetsData]);

  // added below to fix Website overlay/landing page change for CTA and Website
  React.useEffect(() => {
    if (selectedTemplate.value !== -1) {
      setNewWebsiteSelected(false);
    }
  }, [isWebsiteOverlaySelected]);

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }
    setShareData({
      ...shareData,
      templateId: parseInt(selectedTemplate.value.toString(), 10),
    });
  }, [selectedTemplate]);

  useEffect(() => {
    if (!selectedEmailIcon) {
      return;
    }
    setShareData({
      ...shareData,
      iconId: selectedEmailIcon.id,
    });
  }, [selectedEmailIcon]);

  useEffect(() => {
    if (!expiration) {
      return;
    }

    setShareData({
      ...shareData,
      expirationDate: expiration.value,
    });
  }, [expiration]);

  return (
    <>
      <Column>
        <FormGroup>
          {isExpirationActivated && !isQuotibleTemplateSelected && (
            <>
              <Label>Link Expiration</Label>
              <Dropdown
                options={
                  !isCustomLinkExp(expiration.id)
                    ? dropdownItems
                    : dropdownItems.map(element =>
                        isCustomLinkExp(element.id)
                          ? {
                              ...element,
                              label: `Custom (${dayjs(
                                expiration.value?.toString()
                              ).format(`${STANDARD_DATE_FORMAT} hh:mm A`)})...`,
                            }
                          : element
                      )
                }
                value={expiration}
                onChange={(option: any) => {
                  isCustomLinkExp(option.id)
                    ? setCustomOpened(true)
                    : setExpiration(option);
                }}
                creatable={false}
                disabled={dropdownItems.length === 0}
                showIndicator={dropdownItems.length !== 0}
                chipValueSelected={isCustomLinkExp(expiration.id)}
              />
              {customOpened && (
                <ModalVideoExpiration
                  handleModalClose={closeCustomLinkExpirationModal}
                  handleSubmit={setLinkExpiration}
                  expiration={customDate}
                  setExpiration={setCustomDate}
                  buttonText='Set Link Expiration'
                />
              )}
            </>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Landing Page</Label>
          <Dropdown
            value={selectedTemplate}
            options={templateOptions}
            onChange={(value: any) =>
              value !== null ? setSelectedTemplate(value) : () => {}
            }
            creatable={false}
          />
        </FormGroup>
        {!isQuotibleTemplateSelected && (
          <FormGroup>
            <Row marginTop='6px'>
              <Label>
                {isWebsiteOverlaySelected ? 'Overlay URL' : 'Calls-To-Action'}
              </Label>
              {isWebsiteOverlaySelected && (
                <AddWebsiteButton
                  onClick={() => setNewWebsiteSelected(prev => !prev)}
                >
                  {newWebsiteSelected ? 'Use Existing' : 'Add New'}
                </AddWebsiteButton>
              )}
            </Row>

            {/* DOUBLE CHECK LOADER */}
            {!newWebsiteSelected && (
              <Dropdown
                value={
                  isWebsiteOverlaySelected
                    ? overlaysData.find((o: any) => {
                        return o.value == shareData.overlayId;
                      })
                    : linksetOptions.find((o: any) => {
                        return o.value == shareData.linksetId;
                      })
                }
                creatable={false}
                isClearable={isWebsiteOverlaySelected}
                placeholder={
                  isWebsiteOverlaySelected
                    ? 'No Websites'
                    : 'No Calls-To-Action'
                }
                options={
                  isWebsiteOverlaySelected ? overlaysData : linksetOptions
                }
                error={
                  !checkIfOverlayValid(
                    selectedTemplate.value.toString(),
                    shareData?.overlayId || ''
                  )
                }
                onChange={(selectedItem: any) => {
                  if (selectedItem) {
                    const selector = isWebsiteOverlaySelected
                      ? setShareData({
                          ...shareData,
                          overlayId: selectedItem.value,
                        })
                      : setShareData({
                          ...shareData,
                          linksetId: selectedItem.value,
                        });
                    return selector;
                  }
                }}
              />
            )}
            {newWebsiteSelected && (
              <NewWebsiteInput
                type='text'
                placeholder='Enter website'
                error={
                  !checkIfOverlayValid(
                    selectedTemplate.value.toString(),
                    shareData?.overlayId || ''
                  )
                }
                onChange={(e: any) => {
                  setWebsite(e.target.value);
                }}
              />
            )}
          </FormGroup>
        )}
        {!isQuotibleTemplateSelected && (
          <FormGroup>
            <Label>Video Thumbnails</Label>
            <Dropdown
              value={emailIconOptions
                .map(emailIcon => ({
                  label: emailIcon.title,
                  value: emailIcon.id,
                }))
                .find(option => option.value === selectedEmailIcon?.id)}
              placeholder={'No Thumbnail'}
              options={emailIconOptions.map(emailIcon => ({
                label: emailIcon.title,
                value: emailIcon.id,
              }))}
              creatable={false}
              onChange={value =>
                value !== null
                  ? setSelectedEmailIcon(
                      emailIconOptions.find(
                        emailIcon => emailIcon.id === value.value
                      )
                    )
                  : () => {}
              }
            />
          </FormGroup>
        )}
      </Column>
      {!!checkboxes.length && (
        <>
          <CheckboxesLabel>
            Show response options at the end of a video
          </CheckboxesLabel>
          <Row>
            {checkboxes.map((checkbox: Option, index: number) => (
              <CheckboxInputWrapper key={`check-${index}`}>
                <CheckboxInput
                  id={`check-${index}`}
                  width='24px'
                  blueCheck={true}
                  checked={selectedCheckboxes.includes(checkbox)}
                  onChange={(e: React.SyntheticEvent) => {
                    let { checked } = e.target as HTMLInputElement;
                    if (checked)
                      setSelectedCheckboxes([...selectedCheckboxes, checkbox]);
                    else
                      setSelectedCheckboxes(
                        selectedCheckboxes.filter((c: Option) => c !== checkbox)
                      );
                  }}
                />
                <CheckboxLabel htmlFor={`check-${index}`}>
                  {checkbox.label}
                </CheckboxLabel>
              </CheckboxInputWrapper>
            ))}
          </Row>
        </>
      )}
    </>
  );
};
