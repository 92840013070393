import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Main } from './main';
import { pageTitle } from './const';

export const Schedule = () => {
  return (
    <>
      <DocumentHead title={pageTitle} />
      <PageTemplate main={<Main />} />
    </>
  );
};
