import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Search } from 'lib/components';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { Dropdown } from 'lib/components';

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FiltersRight = styled.div`
  display: flex;
  grid-gap: 12px;
`;
type Option = {
  value: string;
  label: string;
};
type SelectProps = {
  data: Option[];
  onChange: (arg1: any) => void;
  value: string;
  setValue: (arg1: any) => void;
  queryParam: string;
};
type FilterStripProps = {
  onSearch: (arg1: string) => void;
  selectData: SelectProps[];
};

const FilterStrip = ({ onSearch, selectData }: FilterStripProps) => {
  const location = useLocation();
  const history = useHistory();

  const changeLink = (param: string, value: any) => {
    const queryParams: any = queryString.parse(location.search);
    queryParams[param] = value;

    // set query params
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const loadDataFromLink = () => {
    const queryParams: any = queryString.parse(location.search);

    const matches = selectData.filter((select: any) =>
      Object.keys(queryParams).includes(select.queryParam)
    );

    if (matches.length) {
      matches.forEach(item => {
        const value = queryParams[item.queryParam];
        item.setValue(value);
      });
    }
  };

  useEffect(() => {
    loadDataFromLink();
  }, [location.search]);

  return (
    <Filters>
      <Search
        onSearch={onSearch}
        placeholder={'Search...'}
        width={'243px'}
      ></Search>
      <FiltersRight>
        {selectData.map((select, index) => (
          <Dropdown
            key={`attribute-select-${index}`}
            options={select.data}
            value={
              select.data.find(o => {
                return o.value.toString() === select.value.toString();
              }) || { label: '', value: '' }
            }
            onChange={(option: any) => {
              select.onChange(option);
              changeLink(select.queryParam, option.value);
            }}
            width={200}
            creatable={false}
          />
        ))}
      </FiltersRight>
    </Filters>
  );
};

export default FilterStrip;
