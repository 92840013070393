import React from 'react';
import { Button } from 'react-covideo-common';
import { BUTTON_VARIANT } from 'react-covideo-common/dist/components/Button/types';
import { FiPlus } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

interface IProps {
  text: string;
  isEdit?: boolean;
  variant?: BUTTON_VARIANT;
  disabled?: boolean;
  reportId?: string;
}

export const CustomReportButton = ({
  text,
  isEdit,
  variant,
  disabled = false,
  reportId,
}: IProps) => {
  const history = useHistory();

  return (
    <>
      <Button
        text={`${text}`}
        disabled={disabled}
        variant={variant || 'primary'}
        icon={isEdit ? <MdEdit size='20' /> : <FiPlus size={20} />}
        onClick={() => {
          const url = !!reportId
            ? `/reports/custom/edit/${reportId}`
            : '/reports/custom/add';
          history.push({ pathname: url });
        }}
      />
    </>
  );
};
