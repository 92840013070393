import { departmentsStats } from 'lib/api';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled, { css } from 'styled-components/macro';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import WidgetTabs from '../tabs';
import { WidgetWrapper } from './common/styles';
import { widget } from './keys';
import { EmptyWidget } from '../EmptyWidget';
import WidgetHeader from './common/WidgetHeader';
import {
  CtaClick,
  IDepartmentsWidgetKeys,
  REPORTS_STALE_TIME,
  WidgetLabels,
  WidgetValues,
} from './types';

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid rgba(242, 244, 246, 1);
  box-shadow: 0px 0px 0px rgb(66 79 104 / 8%), 0px 4px 8px rgb(66 79 104 / 3%);
`;
const Body = styled.div`
  display: flex;
  box-shadow: 0px 0px 0px rgb(66 79 104 / 8%), 0px 4px 8px rgb(66 79 104 / 3%);
  border-radius: 5px;
  border-bottom: 1px solid rgba(242, 244, 246, 1);
  padding: 12px 0;
`;

const Title = styled.p<{ flex?: string }>`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #9297a2;
  flex: ${({ flex }) => flex};
`;

const BodyParagraph = styled.p<{ flex?: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #272a32;
  flex: ${({ flex }) => flex};
  margin: 0;
`;

const CommonProgressStyles = css`
  border: 0;
  height: 20px;
  width: 120px;
  border-radius: 20px;
  overflow: hidden;
`;

const Progress = styled.progress<{ percent: string }>`
  ${CommonProgressStyles}
  background:rgba(242, 244, 246, 1);

  ::-webkit-progress-bar {
    ${CommonProgressStyles}
    background:rgba(242, 244, 246, 1);
  }
  ::-webkit-progress-value {
    ${CommonProgressStyles}
    border-top-right-radius: ${({ percent }) =>
      percent === '100' ? '20px' : '0px'};
    border-bottom-right-radius:  ${({ percent }) =>
      percent === '100' ? '20px' : '0px'};
    border-top-left-radius:  20px;
    border-bottom-left-radius:  20px;
    overflow: hidden;
    background: rgba(0, 27, 83, 0.6);
  }
  ::-moz-progress-bar {
    ${CommonProgressStyles}
    background:rgba(242, 244, 246, 1);
  }
`;

const BodyResult = styled.div`
  display: flex;
  align-items: center;
  flex: 0.4;
  gap: 12px;
  justify-content: space-between;
`;

const Label = styled.label<{ isEngagement: boolean }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #272a32;

  ${({ isEngagement }) =>
    !isEngagement &&
    css`
      width: 100%;
      text-align: right;
    `}
`;

const videoEngagementsItems = [
  { id: 'engagement', label: 'Engagement' },
  { id: 'views', label: 'Views' },
  { id: 'cta-clicks', label: 'CTA Clicks' },
];

const mapperFunc = (element: any, key: string) => {
  if (key === 'engagement') {
    return {
      name: element.name,
      cnt: `${(element.cnt / element.total).toFixed(1)}%`,
    };
  }
  return { ...element };
};

const xmlData = (element: any, key: string) => {
  if (key === 'engagement') {
    return `${(element.cnt / element.total).toFixed(1)}%`;
  }
  return element.cnt;
};

const calculateData = (data: any, key: string) => {
  const transformedData = data
    ?.map((element: any) => mapperFunc(element, key))
    .sort((a: any, b: any) => {
      return parseFloat(b.cnt) - parseFloat(a.cnt);
    })
    .slice(0, 5);

  return transformedData;
};

const Departments = ({ params, addDataForExport }: any) => {
  const [selected, setSelected] = useState(videoEngagementsItems[0]);

  const { data, isLoading, isRefetching } = useQuery(
    widget.detail(WidgetValues.DEPARTMENTS, params),
    () => departmentsStats(params),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  useEffect(() => {
    if (data) {
      let exportData: any = { title: WidgetLabels.DEPARTMENTS, data: [] };
      let dataObjects: any[] = [];

      const engagement = (data?.engagement as CtaClick[])
        .map(element => ({
          Name: element.name,
          Engagement: xmlData(element, 'engagement'),
        }))
        .sort((a: any, b: any) => {
          return parseFloat(b['Engagement']) - parseFloat(a['Engagement']);
        })
        .slice(0, 5);
      dataObjects.push(engagement);
      const views = (data?.views as CtaClick[])
        .map(element => ({
          Name: element.name,
          Views: xmlData(element, 'views'),
        }))
        .sort((a: any, b: any) => {
          return parseFloat(b['Views']) - parseFloat(a['Views']);
        })
        .slice(0, 5);
      dataObjects.push(views);

      const clicks = (data?.['cta-clicks'] as CtaClick[])
        .map(element => ({
          Name: element.name,
          Clicks: xmlData(element, 'cta-clicks'),
        }))
        .sort((a: any, b: any) => {
          return parseFloat(b['Clicks']) - parseFloat(a['Clicks']);
        })
        .slice(0, 5);
      dataObjects.push(clicks);

      const maxLength = Math.max(...dataObjects.map(e => e.length));
      for (let i = 0; i < maxLength; i++) {
        let singleObject = {
          'Departments by Engagement': dataObjects[0][i]?.['Name'] ?? '',
          Engagement: dataObjects[0][i]?.['Engagement'] ?? '',
          '': '',
          'Departments by Views': dataObjects[1][i]?.['Name'] ?? '',
          Views: dataObjects[1][i]?.['Views'] ?? '',
          ' ': '',
          'Departments by Cta clicks': dataObjects[2][i]?.['Name'] ?? '',
          Clicks: dataObjects[2][i]?.['Clicks'] ?? '',
          '  ': '',
        };
        exportData.data.push(singleObject);
      }
      addDataForExport(exportData);
    }
  }, [data]);

  if (isLoading || !data) {
    return <SkeletonLoader imageName="vertical-reports" />;
  }

  const isEngagement = selected.id === 'engagement';

  const selectedData = data?.[selected.id as IDepartmentsWidgetKeys] || [];

  const transformedData = calculateData(selectedData, selected.id);

  return (
    <WidgetWrapper>
      <WidgetHeader title="Departments" isRefetching={isRefetching} />
      <WidgetTabs
        items={videoEngagementsItems}
        selected={selected}
        setSelected={setSelected}
      />
      {transformedData.length === 0 ? (
        <EmptyWidget />
      ) : (
        <>
          <Header>
            <Title flex="0.6">Department name</Title>
            <Title
              flex="0.4"
              style={{ textAlign: isEngagement ? 'left' : 'right' }}
            >
              {selected.id === 'engagement' && 'Eng. Rate'}
              {selected.id === 'views' && 'Views'}
              {selected.id === 'cta-clicks' && 'CTA Clicks'}
            </Title>
          </Header>

          {transformedData.map(({ cnt, name }: any, idx: number) => {
            return (
              <Body key={idx}>
                <BodyParagraph flex="0.6">
                  {name === null ? 'N/A' : name}
                </BodyParagraph>
                <BodyResult>
                  <Label htmlFor="file" isEngagement={isEngagement}>
                    {cnt}
                  </Label>
                  {isEngagement && (
                    <Progress
                      id="file"
                      value={parseFloat(cnt)}
                      percent={cnt}
                      max="100"
                    />
                  )}
                </BodyResult>
              </Body>
            );
          })}
        </>
      )}
    </WidgetWrapper>
  );
};

export default Departments;
