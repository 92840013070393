import { IDepartmentsWidget } from 'app/pages/reports/dashboard/main/widgets/types';
import { StatusOptionsValues } from 'app/pages/video/videoDetails/components/const';
import { ResponseType, Method } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { ReportTypes } from 'lib/const/ReportData';

const DATE_FORMAT = 'YYYY-MM-DD';

export enum REPORT_SCOPE {
  RESELLER = 'reseller',
  ORGANIZATION = 'organization',
}

type Params = {
  department?: string;
  user?: string;
  attributeType?: string;
  search?: string;
  sort?: string;
};

export const getEmailsForReport = async (
  url: string,
  from: Date,
  to: Date,
  users: string[],
  videoAttributeParams?: Params,
  reportId?: string
) => {
  const params = {
    to: dayjs(to).format(DATE_FORMAT),
    from: dayjs(from).format(DATE_FORMAT),
    users: !reportId ? users.join(',') : '',
    timezoneOffset: new Date().getTimezoneOffset(),
    reportId,
    ...(!!videoAttributeParams && videoAttributeParams),
  };

  const response = await EXPRESS_API.get(url, {
    params,
  });

  return response.data;
};

export const downloadReportData = async (
  url: string,
  name: string,
  from: Date,
  to: Date,
  users?: string[],
  customer?: string,
  method?: Method,
  extraParams?: any,
  reportScope: REPORT_SCOPE = REPORT_SCOPE.RESELLER,
  organizationId?: string | number,
  reports?: ReportTypes[]
) => {
  const params = {
    to: dayjs(to).format(DATE_FORMAT),
    from: dayjs(from).format(DATE_FORMAT),
    users: (users || []).join(','),
    customer,
    reportScope,
    organizationId,
    timezoneOffset: new Date().getTimezoneOffset(),
    reports,
    ...extraParams,
  };

  let response;
  const axiosConfig = {
    responseType: 'blob' as ResponseType,
    params,
  };

  if (method === 'get') {
    response = await EXPRESS_API.get(url, axiosConfig);
  } else {
    response = await EXPRESS_API.post(url, {}, axiosConfig);
  }
  const blobResponse = new Blob([response?.data]);

  return saveAs(blobResponse, name);
};

export const sendReportData = async (
  url: string,
  from: Date,
  to: Date,
  range: string,
  receivers: string[],
  users?: string[],
  customer?: string,
  extraParams?: any,
  reportScope: REPORT_SCOPE = REPORT_SCOPE.RESELLER,
  organizationId?: string | number,
  reports?: ReportTypes[]
) => {
  const params = {
    to: dayjs(to).format(DATE_FORMAT),
    from: dayjs(from).format(DATE_FORMAT),
    range,
    users: (users || []).join(','),
    customer,
    reportScope,
    organizationId,
    timezoneOffset: new Date().getTimezoneOffset(),
    reports,
    ...extraParams,
  };
  const data = {
    receivers,
  };

  const response = await EXPRESS_API.post(`${url}/send`, data, {
    params,
  });

  return response.data;
};

export const getUsersMetrics = async (
  from: Date,
  to: Date,
  search: string,
  customer: string = '',
  reports?: string[]
) => {
  const params = {
    to: dayjs(to).format(DATE_FORMAT),
    from: dayjs(from).format(DATE_FORMAT),
    start: 0,
    limit: 1000,
    search,
    customer,
    timezoneOffset: new Date().getTimezoneOffset(),
    reports,
  };

  const response = await EXPRESS_API.get('/metrics/users', {
    params,
  });

  return response.data;
};

export const getCompaniesMetrics = async (
  from: Date,
  to: Date,
  page: number,
  size: number,
  search: string,
  sort: string = '',
  order: string = '',
  customerIds: string[] = [],
  regionId: number = 0,
  organizationRegionId: number = 0,
  reportScope: REPORT_SCOPE = REPORT_SCOPE.RESELLER,
  organizationId?: string | number
) => {
  const start = page * size;

  const params = {
    to: dayjs(to).format(DATE_FORMAT),
    from: dayjs(from).format(DATE_FORMAT),
    timezoneOffset: new Date().getTimezoneOffset(),
    start,
    limit: size,
    search,
    sort,
    order,
    customerIds,
    regionId,
    organizationRegionId,
    reportScope,
    organizationId,
  };

  const response = await EXPRESS_API.get('/metrics/companies', {
    params,
  });

  return response.data;
};

export const getUsersDetailsMetrics = async (
  userId: string,
  from: Date,
  to: Date
) => {
  const params = {
    to: dayjs(to).format(DATE_FORMAT),
    from: dayjs(from).format(DATE_FORMAT),
    timezoneOffset: new Date().getTimezoneOffset(),
  };

  const response = await EXPRESS_API.get(`/metrics/users/${userId}`, {
    params,
  });

  response?.data.forEach((video: any) => {
    video.cnt = parseInt(video.cnt, 10);
  });
  return response?.data;
};

//Analytics Reports
interface IAnalyticsReport {
  params?:
    | {
        search: string;
        emailStatus: StatusOptionsValues;
        SMS: number;
      }
    | {};
  videoId: string;
}
interface ISendReport extends IAnalyticsReport {
  payload: {
    receivers: string[];
  };
}

export const sendAnalyticsReport = async (config: ISendReport) => {
  const { params, payload, videoId } = config;

  const response = await EXPRESS_API.post(
    `/metrics/analytics/${videoId}/send`,
    payload,
    { params }
  );

  return response?.data;
};

export const downloadXLSXAnalyticsReport = async (body: any) => {
  const data = { data: body };
  const response = await EXPRESS_API.post(
    `/metrics/xlsx-report/download`,
    data,
    {
      responseType: 'blob' as ResponseType,
    }
  );

  return saveAs(new Blob([response?.data]), 'report.xlsx');
};

export const sendXLSXAnalyticsReport = async (body: any, recievers: any) => {
  const data = { data: body, recievers };

  const response = await EXPRESS_API.post(`/metrics/xlsx-report/send`, data);

  return response.data;
};

export const downloadAnalyticsReport = async (config: IAnalyticsReport) => {
  const { params, videoId } = config;

  const response = await EXPRESS_API.post(
    `/metrics/analytics/${videoId}/download`,
    {},
    {
      params,
      responseType: 'blob' as ResponseType,
    }
  );

  return saveAs(new Blob([response?.data]), 'report.csv');
};

export type GetLeaderboardParams = {
  startDate?: Date;
  endDate?: Date;
  department?: string;
};

type LeaderboardData = {
  headers: { [key: string]: string };
  csvData: any[];
};

export type SendLeaderboardBody = {
  data: LeaderboardData;
  recipients: string[];
};

export const getLeaderboard = async (params: GetLeaderboardParams) => {
  const response = await EXPRESS_API.get('/metrics/leaderboard', {
    params: {
      startDate: dayjs(params.startDate).format(DATE_FORMAT),
      endDate: dayjs(params.endDate).format(DATE_FORMAT),
      department: params.department,
    },
  });

  return response.data;
};

export const sendLeaderboard = async (
  body: SendLeaderboardBody
): Promise<string> => {
  const response = await EXPRESS_API.post(`/metrics/leaderboard/send`, body);

  return response.data.message;
};

//REPORTS 3.0
export const getReports = async (params: any, url: string): Promise<any> => {
  params.timezoneOffset = new Date().getTimezoneOffset();

  const response = await EXPRESS_API.get(url, {
    params,
  });

  return response.data;
};

export interface ITopVideos {
  views: TopVideosViews[];
  ctaClick: TopVideosCTAClicks[];
  engagements: TopVideosEngagement[];
}

export interface TopVideosCTAClicks {
  linksetID: number;
  setTitle: string;
  videoId: number;
  Title: null | string;
  FirstName: string | null;
  LastName: string | null;
  cnt: string;
}

export interface TopVideosEngagement {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  heatMap: string;
  userId: number;
  userFullName: string;
  date: string;
  contactName: null;
  videoTitle: string;
  videoID: number;
  engagement: number;
  name: string;
  watched: string;
  watchedNumber: number;
}

export interface TopVideosViews {
  VideoID: number | null;
  Title: null | string;
  FirstName: string;
  LastName: string;
  videoId: number | null;
  title: null | string;
  cnt: string;
}
export interface IVideoEngagement {
  clicks: Click[];
  views: Click[];
  engagements: Engagements;
}
export interface IVIdeoDelivery {
  sentEmails: number;
  clicks: Click[];
  views: number;
  videos: number;
  engagements: Engagements;
}
export interface Click {
  date: string;
  cnt: number;
}
export interface Engagements {
  data: Datum[];
  engagement: string;
}
export interface Datum {
  date: string;
  cnt: number;
  views: number;
}

export const deliveryStats = async (params: any): Promise<IVIdeoDelivery> => {
  const response = await EXPRESS_API.get('/metrics/widget/video-delivery', {
    params,
  });

  return response.data;
};

export const departmentsStats = async (
  params: any
): Promise<IDepartmentsWidget> => {
  const response = await EXPRESS_API.get('/metrics/widget/departments', {
    params,
  });

  return response.data;
};

export const getVideoEngagementWidgetStats = async (
  params: any
): Promise<IVideoEngagement> => {
  const response = await EXPRESS_API.get('/metrics/widget/video-engagement', {
    params,
  });

  return response.data;
};

export const getTopVideos = async (params: any): Promise<ITopVideos> => {
  const response = await EXPRESS_API.get('/metrics/widget/top-videos', {
    params,
  });

  return response.data;
};

type ResellerReportBody = {
  range: string;
  frequency: string;
  deliveryTime: string;
  customers: number[];
};
export const createResellerReport = async (body: ResellerReportBody) => {
  const response = await EXPRESS_API.post('/reseller-reports', body);

  return response.data;
};

type ResellerReportUpdateBody = {
  frequency: string;
  deliveryTime: string;
  customers: number[];
};
export const updateResellerReport = async (
  body: ResellerReportUpdateBody,
  reportId: number
) => {
  const response = await EXPRESS_API.put(`/reseller-reports/${reportId}`, body);

  return response.data;
};

export const getResellerReports = async () => {
  const response = await EXPRESS_API.get('/reseller-reports');

  return response.data;
};

export const deleteResellerReport = async (reportId: number) => {
  const response = await EXPRESS_API.delete(`/reseller-reports/${reportId}`);
  return response.data;
};
