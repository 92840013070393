import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';
import { MdLaunch } from 'react-icons/md';
import { useHistory } from 'react-router';
import { Button } from 'react-covideo-common';

const Content = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding-top: 16px;
  min-height: 145px;
  background-color: #fff;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 30px;
`;

const Label = styled.label`
  font-size: 16px;
  letter-spacing: 0.4px;
  color: ${theme.palette.themeDark75Percent};
  font-weight: normal;
  line-height: 1.71;
  color: #000;
`;

interface Props {
  handleModalClose: () => void;
}

export const ModalRestoreRecentlyDeletedError = ({
  handleModalClose,
}: Props) => {
  const history = useHistory();

  const handleOpenLibrary = () => {
    history.push(`/home/`);
  };

  const handleUpgradeOptions = () => {
    history.push(`/upgrade-plan/`);
  };

  return (
    <NewModal
      headerText="Can't Restore Selected Videos"
      closeModal={handleModalClose}
      headerMargin={'0'}
      style={{ content: { padding: '26px 32px' } }}
    >
      <Content>
        <Label>
          You've reached the limit of videos in your library. Upgrade to Pro or
          delete some videos in order to restore selected videos.
        </Label>
        <ButtonsWrapper>
          <Button
            text={`Open Video Library`}
            onClick={handleOpenLibrary}
            variant='ghost'
            icon={<MdLaunch />}
            iconPosition='right'
          />
          <Button text={`See Upgrade Options`} onClick={handleUpgradeOptions} />
        </ButtonsWrapper>
      </Content>
    </NewModal>
  );
};
