import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Message } from './Message';
import { MessageIndividual } from './MessageIndividual';
// import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path="/sms/messages" component={Message} exact={true} />
      <Route
        path="/sms/messages/:id"
        component={MessageIndividual}
        exact={true}
      />
      {/* <Route path="/users/:userId" component={Details} /> */}
    </Switch>
  );
};
