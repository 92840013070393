import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Main } from './main';

export const VideoDeletion = () => (
  <>
    <DocumentHead title='Video Expiration' />
    <PageTemplate main={<Main />} />
  </>
);
