import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { ActivateLPB, DeactivateLPB, GetLPBStats } from 'lib/api/addonsApi';
import { Button } from 'react-covideo-common';
import { ModalConfirmSubscription } from 'lib/components/modal/ModalConfirmSubscription';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { useAuth } from 'lib/context';
import React from 'react';
import { MdDone } from 'react-icons/md';
import {
  ActiveHeader,
  ActiveOptionContainer,
  AddonFooter,
  Feature,
  FeatureIcon,
  FeatureList,
  FeatureText,
  OptionContainer,
  Options,
  PageContainer,
  PageDescription,
  PageHeader,
} from '../components/PageElements';
import { ModalActivateSubscription } from 'lib/components/modal/ModalActivateSubscription';
import { useHistory } from 'react-router-dom';
import { successToast } from 'lib/components/toasts/success';

export const ManageLPBAddon = () => {
  const [lpbActivated, setLPBActivated] = React.useState(false);
  const [showActivationModal, setShowActivationModal] = React.useState(false);
  const [showContactForm, setShowContactForm] = React.useState(false);
  const [contactFormSubject, setContactFormSubject] = React.useState('');
  const [activateModalTitle, setActivateModalTitle] = React.useState('');

  const { userData } = useAuth();

  const history = useHistory();

  React.useEffect(() => {
    GetLPBStats().then(data => {
      setLPBActivated(data.activated);
    });
  }, []);

  const optionAllUsers = (
    <>
      <label>
        <b>Video Landing Page Builder</b>
      </label>
      <label>$25/mo</label>
      <Button
        style={{
          margin: '20px 12px 0px 12px',
        }}
        text={
          userData.access === '3' || userData.access === '4'
            ? lpbActivated
              ? 'Deactivate'
              : 'Activate'
            : 'Contact your Account Manager'
        }
        variant='primary'
        onClick={async () => {
          if (userData.access === '3' || userData.access === '4') {
            userData.trialAccount
              ? setActivateModalTitle('Video Landing Page Builder')
              : setShowActivationModal(true);
          } else {
            setContactFormSubject('Video Landing Page Builder');
            setShowContactForm(true);
          }
        }}
      />
    </>
  );

  return (
    <>
      {showActivationModal && (
        <ModalConfirmSubscription
          subscriptionType={4}
          oldSelection={lpbActivated ? 1 : 0}
          newSelection={lpbActivated ? 0 : 1}
          handleModalClose={() => {
            setShowActivationModal(false);
          }}
          handleModalConfirm={async () => {
            setShowActivationModal(false);
            setLPBActivated(true);

            if (lpbActivated) {
              await DeactivateLPB().then(data => {
                setLPBActivated(false);

                successToast({
                  title: data,
                });
              });
            } else {
              await ActivateLPB().then(data => {
                setLPBActivated(true);

                successToast({
                  title: data,
                });
              });
            }
          }}
        />
      )}
      {activateModalTitle.length ? (
        <ModalActivateSubscription
          handleModalClose={() => setActivateModalTitle('')}
          handleSubmit={addon =>
            history.push({ pathname: '/upgrade', state: { addon } })
          }
          title={activateModalTitle}
          handleCheckBox={true}
        />
      ) : null}
      <PageContainer>
        <PageHeader>Video Landing Page Builder</PageHeader>
        <PageDescription>
          Create, save and reuse endless designs with Covideo's new video
          landing page builder. Select your layout, style, colors, fonts, and
          more. Use your logo, images, and graphics to build your perfect page
          and then share your masterpiece with other team members to use inside
          Covideo.
        </PageDescription>
        <VideoPlayer
          margin={'16px 0 0 0'}
          videoRef={React.createRef()}
          playButtonPosition='center'
          playerBackgroundColor='#000'
          playerIconsColor='#ffffff'
          thumbnail='https://covideosystems.s3.amazonaws.com/thumbnails/LandingPagePromoFF.png'
          videoSource='https://covideosystems.s3.amazonaws.com/videos/LandingPageBuilderPromo.mp4'
        />
        <Options>
          {(lpbActivated && (
            <ActiveOptionContainer>
              <ActiveHeader>Active</ActiveHeader>
              {optionAllUsers}
            </ActiveOptionContainer>
          )) || <OptionContainer>{optionAllUsers}</OptionContainer>}
        </Options>
        <FeatureList>
          <Feature>
            <FeatureIcon>
              <MdDone fontSize='28px' />
            </FeatureIcon>
            <FeatureText>
              <label>
                Cobrand landing pages for client presentations, projects, and
                proposals
              </label>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon>
              <MdDone fontSize='28px' />
            </FeatureIcon>
            <FeatureText>
              <label>
                Customize pages for specific events, webinars, and conferences
              </label>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureIcon>
              <MdDone fontSize='28px' />
            </FeatureIcon>
            <FeatureText>
              <label>
                Or use them to promote new products, features, and
                collaborations
              </label>
            </FeatureText>
          </Feature>
          <Feature>
            <FeatureText>
              You're in the designer's seat now with full control to customize
              and configure your landing pages, your way, for your look.
            </FeatureText>
          </Feature>
        </FeatureList>
        <AddonFooter>
          Have a question? Contact your account manager, contact us at
          support@covideo.com or give us a call at 1.800.306.1445.
        </AddonFooter>
        {showContactForm && (
          <ModalContactAM
            handleModalClose={() => setShowContactForm(false)}
            subject={contactFormSubject}
          />
        )}
      </PageContainer>
    </>
  );
};
