import bongodrum from 'lib/const/notifications/sounds/bongo_drum.wav';
import dingdong from 'lib/const/notifications/sounds/ding_dong.wav';
import flute from 'lib/const/notifications/sounds/flute.wav';
import jinglebells from 'lib/const/notifications/sounds/jingle_bells.wav';
import piano from 'lib/const/notifications/sounds/piano.wav';
import popding from 'lib/const/notifications/sounds/pop_ding.wav';

export const soundType: Record<string, string> = {
  bongodrum: bongodrum,
  dingdong: dingdong,
  flute: flute,
  jinglebells: jinglebells,
  piano: piano,
  popding: popding,
};
