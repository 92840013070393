import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const Right = styled.div`
  display: flex;
`;
export const KeyMetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardsContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: ${theme.palette.blue05};
  border: 1px solid rgba(0, 27, 83, 0.05);
  border-radius: 12px;
  padding: 14px 0px;
  justify-items: center;
  align-items: center;
  min-width: 200px;
  position: relative;
`;
export const Total = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: ${theme.palette.covideoBlue100};
`;
export const Title = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.palette.covideoBlue60};
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 12px;
  width: 100%;
  align-items: center;
`;
export const FiltersLeft = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
export const FiltersRight = styled.div`
  display: flex;
  gap: 4px;
`;
