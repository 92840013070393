import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useEffect } from 'react';

type Props = {
  children?: React.ReactNode;
  widthOnDesktop?: string | number;
  disableFullScreen?: boolean;
  style?: object;
  maxHeight?: string;
  overrideBodyHeight?: boolean;
  bodyOverflowY?: string;
  bodyOverflowX?: string;
};

type ModalStyles = {
  widthOnDesktop?: string | number;
  heightOnDesktop?: string | number;
  disableFullScreen?: boolean;
  maxHeight?: string;
  overrideBodyHeight?: boolean;
  overflowY?: string;
  overflowX?: string;
};

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: ${theme.palette.blackRgba75};
  z-index: 998;
  width: 100% !important;
`;

const ModalContainer = styled.div`
  width: auto;
  height: 100vh;
  ${theme.mediaQueryMinWidth.mb} {
    width: 100vw;
  }
  transition: height 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const ModalBody = styled.div<ModalStyles>`
  height: ${props => (props.maxHeight ? props.maxHeight : '100vh')};
  background-color: #fff;
  overflow-y: ${props => props.overflowY};
  overflow-x: ${props => props.overflowX};
  border-radius: 4px;
  ${props => {
    if (props.disableFullScreen) {
      return `
      height: ${
        props.overrideBodyHeight && props.maxHeight ? props.maxHeight : 'auto'
      };
      width: ${props.widthOnDesktop ? props.widthOnDesktop : 'auto'};
    `;
    }
  }}
  ${theme.mediaQueryMinWidth.cmd} {
    height: ${props =>
      props.overrideBodyHeight && props.maxHeight ? props.maxHeight : 'auto'};
    width: ${props => (props.widthOnDesktop ? props.widthOnDesktop : 'auto')};
    max-height: ${props => (props.maxHeight ? props.maxHeight : '90%')};
  }
`;

export const Modal = ({
  style = {},
  children,
  widthOnDesktop,
  disableFullScreen,
  maxHeight,
  overrideBodyHeight = false,
  bodyOverflowY = 'auto',
  bodyOverflowX = 'hidden',
}: Props) => {
  useEffect(() => {
    if (document && document.body) {
      const orig = document.body.className;
      document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    }

    return () => {
      if (document && document.body) {
        document.body.className = document.body.className.replace(
          / ?modal-open/,
          ''
        );
      }
    };
  }, []);

  return (
    <DarkBackground style={style}>
      <ModalContainer>
        <ModalBody
          disableFullScreen={disableFullScreen}
          widthOnDesktop={widthOnDesktop}
          maxHeight={maxHeight}
          overrideBodyHeight={overrideBodyHeight}
          overflowX={bodyOverflowX}
          overflowY={bodyOverflowY}
        >
          {children}
        </ModalBody>
      </ModalContainer>
    </DarkBackground>
  );
};
