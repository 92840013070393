import { Dropdown, Search } from 'lib/components';
import { CustomReportTypeDropdown } from 'lib/components/CustomReportTypeDropdown/CustomReportTypeDropdown';
import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import { SenderDropdownOptionProps } from '../sharedReports/main/List';

type Props = {
  prevSearch?: string;
  onSelect(option: SenderDropdownOptionProps): void;
  onSearch(search: string): void;
  dropdownOptions: SenderDropdownOptionProps[];
  selectedOption: number | null;
  setSelectedTypeOption: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTypeOption: string | null;
};

export const SharedReportSearchAndFilter = ({
  prevSearch,
  onSearch,
  onSelect,
  dropdownOptions,
  selectedOption,
  selectedTypeOption,
  setSelectedTypeOption,
}: Props) => {
  const onDropdownChangeHandler = (value: string) => {
    setSelectedTypeOption(value);
  };
  return (
    <Gap justifyContent='space-between' m='0 0 16px 0'>
      <Search
        placeholder='Search reports...'
        onSearch={onSearch}
        prevSearch={prevSearch}
        width={'250px'}
      />
      <Gap>
        <CustomReportTypeDropdown
          selectedTypeOption={selectedTypeOption}
          onDropdownChangeHandler={onDropdownChangeHandler}
          extendStyles={{
            valueContainer: {
              overflow: 'hidden',
              height: '40px',
              width: 200,
            },
          }}
        />
        <Dropdown
          extendStyles={{
            valueContainer: {
              overflow: 'hidden',
              height: '40px',
              width: 200,
            },
          }}
          menuPortalTarget={document.body}
          zIndexProp={1}
          menuPlacement={'bottom'}
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'Sender'}
          value={dropdownOptions.find(
            option => option.value === selectedOption && selectedOption
          )}
          onChange={value => onSelect(value)}
          options={dropdownOptions}
        />
      </Gap>
    </Gap>
  );
};
