import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation, useQueryClient } from 'react-query';
import { videoRequestKeys } from './videoRequestKeys';

type ApproveVideoRequestParams = {
  videoRequestId?: string | number;
  note?: string;
};

const approveVideoRequest = async ({
  videoRequestId,
}: ApproveVideoRequestParams) => {
  const response = await EXPRESS_API.put(
    `/video-requests/${videoRequestId}/approve`
  );
  return response.data;
};

export const useApproveVideoRequestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(approveVideoRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(videoRequestKeys.all());
    },
  });
};
