import React from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps, useHistory } from 'react-router';
import { SendAndShareModal } from '../components/SendAndShare/SendAndShareModal';
import { NotFound } from 'app/pages/notFound/NotFound';
import { toNumber } from 'lodash';
import { SendAndShareProvider } from 'lib/context/send-and-share/provider';

interface MatchParams {
  videoId: string;
}

export const SendAndShare = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const videoId = props?.match?.params?.videoId
      ? toNumber(props?.match?.params?.videoId)
      : 0;
    const history = useHistory();
    const goToVideoList = () => history.push(`/home`);
    if (!videoId) {
      return <NotFound />;
    }
    return (
      <SendAndShareProvider videoId={toNumber(videoId)}>
        <SendAndShareModal handleModalClose={goToVideoList} />
      </SendAndShareProvider>
    );
  }
);
