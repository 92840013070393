import { ActivityResponse } from 'lib/context';
import { ActivityParams } from './types';

import { useQuery } from 'react-query';
import { activityKeys } from './activityKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { AxiosError } from 'axios';

export const getUserActivities = async ({
  start = 0,
  limit = 10,
  search = '',
  date = '',
  from = '',
  to = '',
  senderIds = [],
  emailStatus = '',
  receiveMethod = '',
}: ActivityParams): Promise<ActivityResponse> => {
  const params = {
    start,
    limit,
    search,
    from,
    to,
    ...(!!senderIds && senderIds.length ? { senderIds } : {}),
    ...(!!emailStatus ? { emailStatus } : {}),
    ...(!!receiveMethod ? { receiveMethod } : {}),
    ...(!!date ? { date } : {}),
  };

  return (await EXPRESS_API.get(`activity-feed`, { params })).data;
};

export const useGetUserActivitiesQuery = (params: ActivityParams) => {
  const { showError } = useToastError();
  return useQuery(activityKeys.list(params), () => getUserActivities(params), {
    onSuccess: () => {},
    onError: (err: AxiosError) => showError(err),
  });
};
