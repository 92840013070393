import { Gap } from 'lib/components/styles/layout';
import { ParagraphExtraSmallBold } from 'lib/components/styles/typography';
import React from 'react';
import styled from 'styled-components/macro';

type OptionLabelProps = {
  value: string;
  label: string;
  resellerName?: string;
  business?: string;
  customerId?: number;
};

const CustomSpanStyles = styled.span`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const CustomLabel = (customer: OptionLabelProps) => {
  return (
    <Gap justifyContent='space-between' width='calc(100% - 6px)'>
      <CustomSpanStyles>
        ({customer.customerId}) - {customer.business}
      </CustomSpanStyles>
      <ParagraphExtraSmallBold
        textAlign='right'
        style={{ whiteSpace: 'nowrap' }}
        color='#001B5366'
        ellipsis
        width='120px'
      >
        {customer.resellerName}
      </ParagraphExtraSmallBold>
    </Gap>
  );
};
