import { Customer } from 'lib/api/superadmin/customersQuery';
import { IResseler } from 'lib/api/superadmin/resellerQuery';
import { User } from 'lib/api/superadmin/usersQuery';
import React, { createContext, useContext, useReducer } from 'react';
import { VISIBILITY_ACTIONS } from './actions';
import { visibilityReducer } from './reducer';
import { IState, Props, Ressellers } from './types';

const VisibilityContext = createContext(
  {} as {
    state: IState;
    setResellers: (data: IResseler[], isInitiated: boolean) => void;
    setCustomers: (
      data: Customer[],
      resellerId: number,
      isInitiated: boolean
    ) => void;
    setUsers: (
      data: User[],
      customerId: number,
      resellerId: number,
      isInitiated: boolean
    ) => void;
    updateResellerChecked: (resellerId: number, checked: boolean) => void;
    updateCustomerChecked: (
      resellerId: number,
      customerId: number,
      checked: boolean
    ) => void;
    updateUsersChecked: (
      resellerId: number,
      customerId: number,
      userId: number,
      checked: boolean
    ) => void;
    selectAllResellers: () => void;
    deselectAllResellers: () => void;
    selectAllCustomers: (resellerId: number) => void;
    deselectAllCustomers: (resellerId: number) => void;
    selectAllUsers: (resellerId: number, customerId: number) => void;
    deselectAllUsers: (resellerId: number, customerId: number) => void;
    updateCovideoShare: (checked: boolean, data: IResseler[]) => void;
    incrementResellersPageCount: () => void;
    incrementCustomerPageCount: (resellerId: number) => void;
    incrementUserPageCount: (customerId: number) => void;
    setInitialState: (
      sharedWithCovideo: boolean,
      resellers: Ressellers
    ) => void;
  }
);

const initialState: IState = {
  sharedWithCovideo: false,
  resellers: {},
  resellerCount: 0,
  customerCount: {},
  usersCount: {},
  resellersCurrentPage: 0,
  customersCurrentPage: {},
  usersCurrentPage: {},
  initialCheckedResselers: {},
  initialCheckedCustomers: {},
  initialCheckedUsers: {},
};

export const VisibilityContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(visibilityReducer, initialState);

  const setInitialState = (
    sharedWithCovideo: boolean,
    resellers: Ressellers
  ) => {
    dispatch({
      type: VISIBILITY_ACTIONS.SET_INITIAL_STATE,
      payload: { sharedWithCovideo, resellers },
    });
  };

  const setResellers = (data: IResseler[], isInitiated: boolean) => {
    dispatch({ type: VISIBILITY_ACTIONS.SET_RESSELERS, payload: data });
    dispatch({ type: VISIBILITY_ACTIONS.SET_RESSELERS_PARTIAL });
    dispatch({ type: VISIBILITY_ACTIONS.GET_RESELLER_COUNT });
    if (!isInitiated) {
      dispatch({ type: VISIBILITY_ACTIONS.GET_INITIAL_CHECKED_RESSELLERS });
    }
  };

  const setCustomers = (
    data: Customer[],
    resellerId: number,
    isInitiated: boolean
  ) => {
    dispatch({
      type: VISIBILITY_ACTIONS.SET_CUSTOMERS,
      payload: { data, resellerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.GET_CUSTOMER_COUNT,
      payload: { resellerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.SET_CUSTOMERS_PARTIAL,
      payload: { resellerId },
    });
    if (!isInitiated) {
      dispatch({
        type: VISIBILITY_ACTIONS.GET_INITIAL_CHECKED_CUSTOMERS,
        payload: { resellerId },
      });
    }
  };

  const setUsers = (
    data: User[],
    customerId: number,
    resellerId: number,
    isInitiated: boolean
  ) => {
    dispatch({
      type: VISIBILITY_ACTIONS.SET_USERS,
      payload: { data, customerId, resellerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.GET_USERS_COUNT,
      payload: { resellerId, customerId },
    });
    if (!isInitiated) {
      dispatch({
        type: VISIBILITY_ACTIONS.GET_INITIAL_CHECKED_USERS,
        payload: { resellerId, customerId },
      });
    }
  };

  const updateResellerChecked = (resellerId: number, checked: boolean) => {
    dispatch({
      type: VISIBILITY_ACTIONS.UPDATE_RESELLER_CHECKED,
      payload: {
        resellerId,
        checked,
      },
    });
    if (checked) {
      return dispatch({ type: VISIBILITY_ACTIONS.INCREMENT_RESELLER_COUNT });
    }
    dispatch({ type: VISIBILITY_ACTIONS.DECREMENT_RESELLER_COUNT });
  };

  const updateCustomerChecked = (
    resellerId: number,
    customerId: number,
    checked: boolean
  ) => {
    dispatch({
      type: VISIBILITY_ACTIONS.UPDATE_CUSTOMER_CHECKED,
      payload: {
        resellerId,
        customerId,
        checked,
      },
    });

    dispatch({
      type: VISIBILITY_ACTIONS.SET_CUSTOMERS_PARTIAL,
      payload: { resellerId },
    });
    if (checked) {
      return dispatch({
        type: VISIBILITY_ACTIONS.INCREMENT_CUSTOMERS_COUNT,
        payload: {
          resellerId,
          customerId,
        },
      });
    }
    dispatch({
      type: VISIBILITY_ACTIONS.DECREMENT_CUSTOMERS_COUNT,
      payload: {
        resellerId,
        customerId,
      },
    });
  };

  const updateUsersChecked = (
    resellerId: number,
    customerId: number,
    userId: number,
    checked: boolean
  ) => {
    dispatch({
      type: VISIBILITY_ACTIONS.UPDATE_USER_CHECKED,
      payload: {
        resellerId,
        customerId,
        checked,
        userId,
      },
    });

    dispatch({
      type: VISIBILITY_ACTIONS.SET_USERS_PARTIAL,
      payload: { resellerId, customerId },
    });

    if (checked) {
      return dispatch({
        type: VISIBILITY_ACTIONS.INCREMENT_USERS_COUNT,
        payload: { userId, customerId },
      });
    }
    dispatch({
      type: VISIBILITY_ACTIONS.DECREMENT_USERS_COUNT,
      payload: { userId, customerId },
    });
  };

  const selectAllResellers = () => {
    dispatch({ type: VISIBILITY_ACTIONS.SELECT_ALL_RESELLERS });
    dispatch({ type: VISIBILITY_ACTIONS.GET_RESELLER_COUNT });
  };

  const deselectAllResellers = () => {
    dispatch({ type: VISIBILITY_ACTIONS.DESELECT_ALL_RESELLERS });
    dispatch({ type: VISIBILITY_ACTIONS.GET_RESELLER_COUNT });
  };

  const selectAllCustomers = (resellerId: number) => {
    dispatch({
      type: VISIBILITY_ACTIONS.SELECT_ALL_CUSTOMERS,
      payload: { resellerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.GET_CUSTOMER_COUNT,
      payload: { resellerId },
    });
  };

  const deselectAllCustomers = (resellerId: number) => {
    dispatch({
      type: VISIBILITY_ACTIONS.DESELECT_ALL_CUSTOMERS,
      payload: { resellerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.GET_CUSTOMER_COUNT,
      payload: { resellerId },
    });
  };

  const selectAllUsers = (resellerId: number, customerId: number) => {
    dispatch({
      type: VISIBILITY_ACTIONS.SELECT_ALL_USERS,
      payload: { resellerId, customerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.GET_USERS_COUNT,
      payload: { resellerId, customerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.SET_USERS_PARTIAL,
      payload: { resellerId, customerId },
    });
  };

  const deselectAllUsers = (resellerId: number, customerId: number) => {
    dispatch({
      type: VISIBILITY_ACTIONS.DESELECT_ALL_USERS,
      payload: { customerId, resellerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.GET_USERS_COUNT,
      payload: { customerId, resellerId },
    });
    dispatch({
      type: VISIBILITY_ACTIONS.SET_USERS_PARTIAL,
      payload: { resellerId, customerId },
    });
  };

  const updateCovideoShare = (checked: boolean, data: IResseler[]) => {
    dispatch({
      type: VISIBILITY_ACTIONS.SHARE_WITH_COVIDEO,
      payload: { checked },
    });
    if (!checked) {
      dispatch({ type: VISIBILITY_ACTIONS.SET_RESSELERS, payload: data });
      dispatch({ type: VISIBILITY_ACTIONS.GET_RESELLER_COUNT });
    }
  };

  const incrementResellersPageCount = () => {
    dispatch({ type: VISIBILITY_ACTIONS.RESELLERS_PAGE_COUNT });
  };

  const incrementCustomerPageCount = (resellerId: number) => {
    dispatch({
      type: VISIBILITY_ACTIONS.CUSTOMERS_PAGE_COUNT,
      payload: { resellerId },
    });
  };

  const incrementUserPageCount = (customerId: number) => {
    dispatch({
      type: VISIBILITY_ACTIONS.USERS_PAGE_COUNT,
      payload: { customerId },
    });
  };

  return (
    <VisibilityContext.Provider
      value={{
        state,
        setResellers,
        setCustomers,
        setUsers,
        updateResellerChecked,
        updateCustomerChecked,
        updateUsersChecked,
        selectAllResellers,
        deselectAllResellers,
        selectAllCustomers,
        deselectAllCustomers,
        selectAllUsers,
        deselectAllUsers,
        updateCovideoShare,
        incrementResellersPageCount,
        incrementCustomerPageCount,
        incrementUserPageCount,
        setInitialState,
      }}
    >
      {children}
    </VisibilityContext.Provider>
  );
};

export const useVisibilityContextProvider = () => useContext(VisibilityContext);
