import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { NotFound as Main } from './NotFound';

export const NotFoundTemplate = () => (
  <>
    <DocumentHead title="Not found" />
    <PageTemplate main={<Main />} />
  </>
);
