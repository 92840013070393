import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { CloseButton, Modal } from 'lib/components';
import { theme } from 'lib/style';
import DragAndDrop from '../inputs/DragAndDrop';
import { IoMdCheckmark, IoMdInformationCircle } from 'react-icons/io';
import { addTranscriptionInfo } from 'lib/api';
import { MoonLoader } from 'react-spinners';
import { Circle } from 'rc-progress/lib';
import { useToastError } from 'lib/hooks';
import { successToast } from '../toasts/success';

type fileProps = {
  fileLength?: any;
};

const InfoIcon = styled.div`
  font-size: 24px;
  margin-right: 8px;
  opacity: 0.6;
  color: #001b53;
  margin-left: 8px;
`;

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 592px;
  overflow: hidden;
  background-color: #fff;
`;

const Content = styled.div`
  padding: 60px 30px 40px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  margin-bottom: 10px;
  position: absolute;
  top: 32px;
  padding: 0 32px;
  width: 528px;
`;

type BodyProps = {
  showBackground: boolean;
};

const ContentBody = styled.div<BodyProps>`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  color: ${theme.palette.blackRgb75};
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  width: 592px;
  min-height: 340px;
  max-height: 340px;
  background-color: ${props => (props.showBackground ? '#f6f7f9' : '')};
  overflow-y: auto;
  overflow-x: hidden !important;
`;

const ContentFooter = styled.div<fileProps>`
  display: flex;
  visibility: ${props => (props.fileLength ? 'visible' : 'hidden')};
  position: absolute;
  bottom: -12px;
  background-color: #ffffff;
  justify-content: flex-end;
  margin-top: 12px;
  width: 592px;
`;

const UploadWarning = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 0 0 0;
  margin-top: 6px;

  width: 528px;
  height: 32px;

  background: rgba(182, 224, 236, 0.2);
  border-radius: 5px;
`;

const ProgressBar = styled.progress`
  z-index: 1;
  margin-top: 12px;
  width: 100%;
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 15px;
  height: 4px;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 15px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: ${props => props.theme.colors.primary[100]};
    border-radius: 15px;
    height: 4px;
  }
  &::-webkit-progress-value {
    display: inline-block;
    float: left;
    height: 4px;
    margin: 0px -10px 0 0;
    background: ${props => props.theme.colors.primary[100]};
    border-radius: 15px;
  }
`;

const ProgressText = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 16px;
  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  color: #4e5461;
  margin-top: 32px;
`;

const ProgressBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  color: ${theme.palette.blackRgb75};
  margin: 0;
  width: 100%;
  margin-bottom: 32px;
  min-height: 190px;
  position: relative;
`;

const FlexCenter = styled.div`
  margin-top: 16px;
  background-color: #f6f7f9;
  border-radius: 4px;
  padding: 32px;
  height: 168px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CircleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
type TextProps = {
  isActive?: boolean;
};

const CircleText = styled.div<TextProps>`
  display: flex;
  flex-direction: column;
  &: nth-child(2) {
    align-items: center;
    justify-content: space-between;
  }
  &: nth-child(3) {
    align-items: flex-end;
    justify-content: flex-end;
  }
  span {
    top: 0px;
    font-size: 16px;
    line-height: 1.5;
    color: ${props =>
      props.isActive ? theme.palette.black_1_100 : 'rgba(39, 42, 50, 0.4)'};
    margin-bottom: 8px;
  }
  .checkmark {
    border-radius: 20px;
    position: absolute;
    top: 106px;
    z-index: 4;
    color: white;
    background-color: ${props => props.theme.colors.primary[100]};
  }
  .rc-progress-circle {
    background-color: #f6f7f9;
  }
  & > div {
    background-color: #f6f7f9;
    z-index: 3;
    border-radius: 50px;
    position: absolute;
    top: 105px;
  }
`;

const circleStyle = {
  width: '24px',
  zIndex: 2,
  backgroundColor: 'f6f7f9',
};

export const ModalUploadSrt = withRouter((props: any) => {
  const { handleModalClose } = props;
  const { showError } = useToastError();
  const [uploadProgress, setUploadProgress] = React.useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });

  const onUploadProgress = handleUploadProgress(setUploadProgress);

  function handleUploadProgress(setUploadProgress: any) {
    return (e: ProgressEvent) => {
      const percentCompleted = Math.round((e.loaded * 100) / e.total);
      setUploadProgress({
        loaded: Math.floor(e.loaded / 1024),
        total: Math.floor(e.total / 1024),
        percentage: percentCompleted,
      });
    };
  }

  const handleSelectedFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.dataTransfer || e.target;
    if (target && target.files && target.files.length) {
      const data = new FormData();
      const imagedata = target.files[0];
      data.append('file', imagedata);

      addTranscriptionInfo(props.videoId, data, onUploadProgress)
        .then((response: any) => {
          setTimeout(() => {
            handleModalClose();
            successToast({
              title: 'Caption uploaded successfully.',
            });
            props.onFileUpload(response);
          }, 1000);
        })
        .catch(error => {
          console.log(error);
          showError(error);
          handleModalClose();
        });
    } else {
      showError(
        'custom-error',
        'Oops! Something went wrong, try again in a few moments'
      );
    }
  };

  const parseOptions = {
    accept: '.srt,.vtt',
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const handleClose = () => {
    return handleModalClose();
  };

  const uploadFormView = (
    <Content>
      <ContentHeader>Upload Caption File</ContentHeader>
      <ContentBody showBackground={false}>
        <DragAndDrop
          accept={parseOptions.accept}
          marginTop={0}
          width={532}
          height={338}
          showIcon={false}
          button={true}
          text='Drag and drop a .srt file here to upload'
          onDrop={handleSelectedFile}
          parserOptions={parseOptions}
        />
      </ContentBody>
      {props.isFileUploaded && (
        <UploadWarning>
          <InfoIcon>
            <IoMdInformationCircle width={'24px'} height={'24px'} />
          </InfoIcon>
          New uploaded .srt file will replace the current captions.
        </UploadWarning>
      )}
      <ContentFooter />
    </Content>
  );

  const isUploading = uploadProgress.percentage >= 100 ? false : true;
  const isEncoding = uploadProgress.percentage >= 100 ? true : false;
  const themes = useTheme();

  const progessContent = (
    <Content>
      <>
        <ContentHeader>Upload Caption File</ContentHeader>
        <ProgressBody>
          <FlexCenter>
            <CircleContainer>
              <CircleText isActive={isUploading}>
                <span>{'Uploading'}</span>
                {uploadProgress.percentage < 100 && (
                  <MoonLoader
                    size={19.5}
                    color={themes.colors.primary[100]}
                    loading={true}
                  />
                )}
                {!uploadProgress.percentage && (
                  <Circle
                    style={circleStyle}
                    percent={0}
                    trailWidth={10}
                    strokeWidth={10}
                    strokeColor={themes.colors.primary[100]}
                  />
                )}
                {uploadProgress.percentage >= 100 && (
                  <IoMdCheckmark
                    className='checkmark'
                    style={{ left: '32px' }}
                    size={20}
                  />
                )}
              </CircleText>
              <CircleText isActive={isEncoding}>
                <span>{'Uploaded'}</span>
                {uploadProgress.percentage < 100 && (
                  <MoonLoader
                    size={19.5}
                    color={themes.colors.primary[100]}
                    loading={true}
                  />
                )}
                {!uploadProgress.percentage && (
                  <Circle
                    style={circleStyle}
                    percent={0}
                    trailWidth={10}
                    strokeWidth={10}
                    strokeColor={themes.colors.primary[100]}
                  />
                )}
                {uploadProgress.percentage >= 100 && (
                  <IoMdCheckmark
                    className='checkmark'
                    style={{ right: '32px' }}
                  />
                )}
              </CircleText>
            </CircleContainer>
            <ProgressBar value={uploadProgress.percentage} max={100} />
            <ProgressText>
              {uploadProgress.loaded + ' KB/' + uploadProgress.total + ' KB'}
            </ProgressText>
          </FlexCenter>
        </ProgressBody>
      </>
    </Content>
  );

  return (
    <Modal>
      <ModalItem>
        <CloseButton disabled={false} zIndex={true} onClick={handleClose} />
        {uploadProgress.percentage > 0 ? progessContent : uploadFormView}
      </ModalItem>
    </Modal>
  );
});
