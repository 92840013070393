import styled from 'styled-components/macro';

type ArrowProps = {
  rotate?: string;
};

export const Arrow = styled.div<ArrowProps>`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  margin: 0 8px;
  transform: ${props => props.rotate === 'up' && 'rotate(180deg)'};
  transform: ${props => props.rotate === 'left' && 'rotate(90deg)'};
  transform: ${props => props.rotate === 'right' && 'rotate(270deg)'};
  transform: ${props => props.rotate === 'down' && 'unset'};
`;
