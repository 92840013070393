import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';

const TabsHeader = styled.div`
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid ${theme.palette.gray20};
  margin-bottom: 32px;
`;

type TabsTitleProps = {
  color: string;
  padding: string;
  margin: string;
};
const TabsTitle = styled.div<TabsTitleProps>`
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: ${props => props.margin};
  position: relative;
  padding: ${props => props.padding};
  color: ${props => props.color};
`;
type TabsTitleActiveProps = {
  roundedBorder: boolean;
  color: string;
};
const TabsTitleActive = styled.div<TabsTitleActiveProps>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -8px;
  height: 4px;
  background: ${props => props.color};
  border-radius: ${props =>
    props.roundedBorder ? '100px 100px 0px 0px' : '0'};
`;
type TabItem = {
  value: number;
  label: string;
};
type Props = {
  tabs: TabItem[];
  activeTab: number;
  setActiveTab: (tab: number) => void;
  tabTitlePadding?: string;
  tabTitleMargin?: string;
  roundedBorder?: boolean;
};
export const TabsManager = ({
  tabs,
  activeTab,
  setActiveTab,
  tabTitlePadding = '0',
  tabTitleMargin = '0 32px 0 0',
  roundedBorder = true,
}: Props) => {
  const themes = useTheme();
  return (
    <>
      <TabsHeader>
        {tabs.map((tab, index) => (
          <TabsTitle
            onClick={() => setActiveTab(tab.value)}
            key={`tab-${index}`}
            padding={tabTitlePadding}
            margin={tabTitleMargin}
            color={
              activeTab === tab.value
                ? themes.colors.primary[100]
                : theme.palette.gray60
            }
          >
            {tab.label}
            {activeTab === tab.value && (
              <TabsTitleActive
                roundedBorder={roundedBorder}
                color={themes.colors.primary[100]}
              />
            )}
          </TabsTitle>
        ))}
      </TabsHeader>
    </>
  );
};
