import * as React from 'react';
import { VideoPlayer } from '../../videoPlayer';
import { theme } from 'lib/style';
import { EditableSubs } from '../components';
import { getTranscriptionInfo, deleteTranscriptionInfo } from 'lib/api';
import { MenuDropdown } from 'lib/components/page/MenuDropdown';
import { useAuth } from 'lib/context';
import RouteLeavingGuard from './RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { ModalUploadSrt } from 'lib/components';
import { ModalDeleteCaptions } from 'lib/components/modal';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Heading, ParagraphNormal } from 'lib/components/styles/typography';
import {
  InputLabel,
  InputWrapper,
  ItemContainer,
  ItemsWrapper,
} from '../styles/layout';
import { HeaderWrapper, Gap } from 'lib/components/styles/layout';
import { TranscriptFileTypes } from 'lib/const/transcripts';
import { downloadTranscript } from 'lib/api/transcriptApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { AccessRole } from 'lib/const';
import { useVideoTranscriptionUpdate } from 'lib/api/transcription/useVideoTranscriptionUpdate';
import { useVideoTranscriptionStart } from 'lib/api/videos/useVideoTranscriptionStart';
import { MdDeleteForever, MdDownload, MdSave, MdUpload } from 'react-icons/md';
import Switch from 'app/pages/design/landingPageBuilder/components/Switch';

interface ComponentProps {
  videoTitle: string;
  videoId: string;
  personalThumbnail: string;
  playerBackgroundColor: string;
  playerIconsAndTextColor: string;
  playButtonPosition: string;
  videoSource: string;
}

type Cue = {
  orgTime: string;
  displayStartTime: string;
  start: number;
  end: number;
  text: string;
};

enum TransciptStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  FINISHED = 'finished',
  FAILED = 'failed',
}

const timeoutHandler = (
  value: number,
  setValue: React.Dispatch<React.SetStateAction<number>>,
  retryTime = 1000
) => {
  return setTimeout(() => {
    setValue(value + 1);
  }, retryTime);
};

export const Captions = (props: ComponentProps) => {
  const {
    videoId,
    playerBackgroundColor,
    playerIconsAndTextColor,
    playButtonPosition,
    videoSource,
    videoTitle,
  } = props;

  const [retryCount, setRetryCount] = React.useState(0);
  const { colors } = useCovideoTheme();
  const {
    mutateAsync: startTranscription,
    isLoading: isStartTranscriptionLoading,
  } = useVideoTranscriptionStart();

  const { mutate: videoUploadMediaMutate } = useVideoTranscriptionUpdate();
  const { userData } = useAuth();
  const history = useHistory();

  const [enabled, setEnabled] = React.useState(false);
  const [text, setText] = React.useState('');
  const [cues, setCues] = React.useState([] as Cue[]);
  const [currentTime, setCurrentTime] = React.useState<number>(0);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [isFailed, setIsFailed] = React.useState(false);
  const [isTranscribed, setIsTranscribed] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [downloadDropdownOpen, setDownloadDropdownOpen] = React.useState(false);
  const [videoTranscription, setVideoTranscription] = React.useState({
    videoId: videoId,
    userId: '',
    originalTranscript: '',
    modifiedTranscript: '',
    enabled: 0,
    status: '',
  });
  const [isDirty, setIsDirty] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement>();
  const [isUploadVisible, setIsUploadVisible] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showRouteLeavingGuard, setShowRouteLeavingGuard] =
    React.useState(true);

  const handleSubmit = async () => {
    const obj = {
      videoId,
      mediaBody: {
        transcript: text,
        enabled: enabled ? 1 : 0,
      },
    };
    await videoUploadMediaMutate(obj);
    setIsDirty(false);
    setIsTouched(false);
  };

  const handleStartTranscription = async (videoId: string) => {
    try {
      await getTranscriptionInfo(videoId);
      await startTranscription(videoId);
      setIsError(false);
      setIsProcessing(true);
      setRetryCount(retryCount + 1);
    } catch (err) {
      errorToast({
        title:
          err.error ||
          'An error occurred while starting transcription. Please try again later.',
      });
    }
  };

  React.useEffect(() => setIsTouched(isTouched || isDirty), [isDirty]);

  const handleEnableSubtitles = async (enabled: boolean) => {
    const { current } = videoRef;
    if (current && current.textTracks && current.textTracks[0]) {
      current.textTracks[0].mode = enabled ? 'showing' : 'hidden';
    }

    const updatedData = {
      videoId,
      mediaBody: {
        enabled: enabled ? 1 : 0,
      },
    };

    setEnabled(enabled);
    await videoUploadMediaMutate(updatedData);
  };

  const onTimeUpdate = () => {
    const { current } = videoRef;
    if (current) {
      const time = current.currentTime;
      setCurrentTime(time);
    }
  };

  const convertTimeToSeconds = (time: string) => {
    const a = time.split(':').map((x: string) => parseInt(x));
    return a[2]
      ? a[0] * 3600 + a[1] * 60 + a[2] * 1
      : a[1]
        ? a[0] * 60 + a[1] * 1
        : a[0] * 1;
  };

  const parseVttText = () => {
    let cuesTmp: Cue[] = [];
    const paragraphs = text.split('\n\n');
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = paragraphs[i].trim().split('\n');
      if (paragraph.length == 1) {
        continue;
      }
      if (paragraph.length == 2) {
        paragraph.push('');
      }
      const orgTime = paragraph[1];
      const start =
        orgTime.split('-->')[0] && orgTime.split('-->')[0].trimRight();
      const end = orgTime.split('-->')[1] && orgTime.split('-->')[1].trimLeft();
      if (!start || !end) {
        continue;
      }
      let cue: Cue = {
        orgTime: orgTime,
        displayStartTime: start.split('.')[0],
        start: convertTimeToSeconds(start),
        end: convertTimeToSeconds(end),
        text: '',
      };
      for (let j = 2; j < paragraph.length; j++) {
        cue.text += paragraph[j];
        if (paragraph.length - 1 !== j) {
          cue.text += '\n';
        }
      }
      cuesTmp.push(cue);
    }
    setCues(cuesTmp);
  };

  const setSubtitles = React.useCallback(() => {
    const { current } = videoRef;
    if (current) {
      let video = current;
      const file: File = new File([new Blob([text])], 'subtitle.vtt', {
        type: 'text/vtt',
      });

      const url = window.URL.createObjectURL(file);
      if (video.textTracks.length > 0) {
        video.removeChild(video.children[1]);
      }
      const track = document.createElement('track');
      track.kind = 'captions';
      track.label = 'English';
      track.srclang = 'en';
      track.default = true;
      track.src = url;
      video.appendChild(track);
      video.textTracks[0].mode = enabled ? 'showing' : 'hidden';
    }
  }, [videoRef.current, text]);

  const updateSubtitle = () => {
    let fileText = `WEBVTT`;
    for (let index = 0; index < cues.length; index++) {
      let cue = cues[index];
      fileText += `\n\n${index + 1}\n${cue.orgTime}\n${cue.text}`;
    }
    setText(fileText);
  };

  const updateCueText = (cueText: string, index: number) => {
    let newCues = [...cues];
    newCues[index].text = cueText;
    setCues(newCues);
    setIsDirty(true);
  };

  React.useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.addEventListener('timeupdate', onTimeUpdate);
      ontimeupdate = () => onTimeUpdate();
    }
    return () => {
      if (videoRef && videoRef.current)
        videoRef.current.removeEventListener('timeupdate', onTimeUpdate);
    };
  }, [videoRef]);

  React.useEffect(() => {
    getTranscriptionInfo(videoId)
      .then(data => {
        setVideoTranscription(data);
        setIsTranscribed(data.status !== TransciptStatus.NOT_STARTED);
        setIsProcessing(data.status === TransciptStatus.IN_PROGRESS);
        setIsFailed(data.status === TransciptStatus.FAILED);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    if (retryCount >= 1) {
      getTranscriptionInfo(videoId)
        .then(data => {
          setVideoTranscription(data);
          let currentTimeout = timeoutHandler(retryCount, setRetryCount);
          if (data.status === TransciptStatus.IN_PROGRESS) {
            setIsProcessing(true);
            setIsFailed(false);
            timeoutHandler(retryCount, setRetryCount);
          } else if (data.status === TransciptStatus.FINISHED) {
            clearTimeout(currentTimeout);
            setRetryCount(0);
            setIsTranscribed(true);
            setIsProcessing(false);
            setIsFailed(false);
          } else if (data.status === TransciptStatus.FAILED) {
            clearTimeout(currentTimeout);
            setRetryCount(0);
            setIsTranscribed(false);
            setIsProcessing(false);
            setIsFailed(true);
          }
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'development') {
            console.error(
              '\nRequest for Transcription status failed!\n',
              error
            );
          }
          if (retryCount > 15) {
            timeoutHandler(retryCount, setRetryCount, 5000);
            setIsError(true);
          } else if (isError && retryCount > 20) {
            timeoutHandler(retryCount, setRetryCount, 20000);
          } else {
            setRetryCount(retryCount + 1);
          }
        });
    }
  }, [retryCount]);

  React.useEffect(() => {
    parseVttText();
    setSubtitles();
  }, [text]);

  React.useEffect(() => {
    updateSubtitle();
  }, [cues]);

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id) {
        const isCaptionAllowed = checkIfFeatureIsEnabled(
          userData,
          productFeature.CAPTIONS
        );
        if (!isCaptionAllowed) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, []);

  React.useEffect(() => {
    const { enabled } = videoTranscription;
    const numberifiedEnabled =
      typeof enabled === 'string' ? Number(enabled) : enabled;
    const booleanifiedEnabled = !!numberifiedEnabled;

    if (videoTranscription.modifiedTranscript !== '') {
      setText(videoTranscription.modifiedTranscript);
    } else {
      setText(videoTranscription.originalTranscript);
    }
    setEnabled(booleanifiedEnabled);
  }, [videoTranscription.modifiedTranscript, videoTranscription.enabled]);

  const transcribed =
    isTranscribed || isProcessing || isStartTranscriptionLoading;

  const buyCaptions = () => {
    if (typeof window !== 'undefined') {
      window.location.href = `/profile/add-ons/captions/manage`;
    }
  };

  const handleFileUploadSuccess = (data: any) => {
    setVideoTranscription(data);
    setIsTranscribed(data.status !== TransciptStatus.NOT_STARTED);
    setIsProcessing(data.status === TransciptStatus.IN_PROGRESS);
    setIsTouched(true);
  };

  const handleDeleteClick = () => {
    deleteTranscriptionInfo(videoId)
      .then(() => {
        setVideoTranscription({
          videoId: videoId,
          userId: '',
          originalTranscript: '',
          modifiedTranscript: '',
          enabled: 0,
          status: '',
        });
        setIsTranscribed(false);
        setIsProcessing(false);
        setIsFailed(false);
        successToast({
          title: `Caption successfully deleted!`,
        });
        setShowDeleteModal(false);
      })
      .catch(err => {
        errorToast({
          title:
            `The transcription job cannot be deleted right now. Please try again after some time.` ||
            err.toString(),
        });
        setShowDeleteModal(false);
      });
  };

  const isCaptionsEnabledForCustomer =
    userData?.customer?.transcriptions?.toString() === '1';
  const isCaptionsEnabledForUser = userData?.transcriptionAccess;
  const isCaptionsEnabled =
    isCaptionsEnabledForCustomer && isCaptionsEnabledForUser;

  const handleCaptionsNotEnabled = () => {
    if (isCaptionsEnabledForCustomer) {
      history.push('/profile/add-ons/captions/manage', {
        from: 'video-details-page',
      });
      return;
    }

    return (
      <>
        {userData.access == AccessRole.ADMIN ? (
          <>
            <ParagraphNormal>Captions are not enabled !!!</ParagraphNormal>
            <Button text={'Buy Captions now'} onClick={buyCaptions} />
          </>
        ) : (
          <ParagraphNormal>
            Captions are not enabled for your account. Contact your account
            Admin.
          </ParagraphNormal>
        )}
      </>
    );
  };
  return (
    <>
      <HelpDesk name={EHelpDesk.CAPTIONS} />
      {showRouteLeavingGuard && (
        <RouteLeavingGuard
          when={true}
          navigate={path => history.push(path)}
          onConfirm={handleSubmit}
          shouldBlockNavigation={() => {
            return isDirty;
          }}
        />
      )}
      <HeaderWrapper>
        <Heading>{videoTitle}</Heading>
        <Gap>
          {transcribed && !isFailed && (
            <>
              <Button
                variant='destructive'
                icon={
                  <MdDeleteForever
                    size={18}
                    color={theme.palette.buttonDelete}
                  />
                }
                onClick={() => setShowDeleteModal(true)}
              />
              <Button
                variant='secondary'
                icon={<MdDownload size='20px' />}
                disabled={isProcessing}
                onClick={() => transcribed && setDownloadDropdownOpen(true)}
              />
              <MenuDropdown
                autoPosition={true}
                items={TranscriptFileTypes.map(filetype => {
                  return {
                    title: filetype.label,
                    onItemClick: () => {
                      setDownloadDropdownOpen(false);
                      setShowRouteLeavingGuard(false);
                      downloadTranscript(
                        videoId,
                        filetype.type,
                        filetype.fileName
                      );
                    },
                    path: '#',
                  };
                })}
                isDropdownOpen={downloadDropdownOpen}
                OnClickOutside={() => setDownloadDropdownOpen(false)}
              />
              <Button
                variant='secondary'
                text='Upload .srt'
                icon={<MdUpload size={18} />}
                onClick={() => setIsUploadVisible(true)}
              />
              <Button
                text='Save Changes'
                icon={<MdSave size={18} />}
                disabled={!isTouched}
                onClick={() => transcribed && handleSubmit()}
              />
            </>
          )}
          {showDeleteModal && (
            <ModalDeleteCaptions
              whiteButtonText='No, Keep'
              redButtonText='Yes, Delete'
              title='Delete Captions?'
              text="Are you sure you want to delete captions? This action can't be undone."
              handleModalClose={() => setShowDeleteModal(false)}
              onClickWhiteButton={() => setShowDeleteModal(false)}
              onClickRedButton={handleDeleteClick}
            />
          )}
          {isUploadVisible && (
            <ModalUploadSrt
              onFileUpload={handleFileUploadSuccess}
              isFileUploaded={true}
              videoId={videoId}
              handleModalClose={() => setIsUploadVisible(false)}
            />
          )}
        </Gap>
      </HeaderWrapper>
      <ItemsWrapper>
        {transcribed && !isFailed && userData.transcriptionAccess ? (
          <EditableSubs
            updateSubtitleText={updateCueText}
            currentTime={currentTime}
            isProcessing={isProcessing}
            cues={cues}
          />
        ) : (
          <ItemContainer gap='32px'>
            {isCaptionsEnabled ? (
              <>
                {isFailed && (
                  <ParagraphNormal>
                    The transcription for this video has been failed. <br />
                    Please try again.
                  </ParagraphNormal>
                )}
                {!isFailed && (
                  <ParagraphNormal>
                    This video has not yet been transcribed. <br />
                    Please click on the button bellow to start an automated
                    transcription process.
                  </ParagraphNormal>
                )}
                <Gap>
                  <Button
                    text='Start Transcription'
                    onClick={() => handleStartTranscription(videoId)}
                  />
                  <Button
                    text='Upload .srt'
                    icon={<MdUpload size={18} />}
                    onClick={() => setIsUploadVisible(true)}
                  />
                  {isUploadVisible && (
                    <ModalUploadSrt
                      onFileUpload={handleFileUploadSuccess}
                      isFileUploaded={false}
                      videoId={videoId}
                      handleModalClose={() => setIsUploadVisible(false)}
                    />
                  )}
                </Gap>
              </>
            ) : (
              handleCaptionsNotEnabled()
            )}
          </ItemContainer>
        )}
        <ItemContainer gap='16px'>
          <VideoPlayer
            playerBackgroundColor={playerBackgroundColor}
            videoSource={videoSource}
            videoId={videoId}
            playButtonPosition={playButtonPosition}
            playerIconsColor={playerIconsAndTextColor}
            videoRef={videoRef}
            height='280px'
          />
          {isTranscribed && (
            <InputWrapper direction='row'>
              <InputLabel>Show Captions by default</InputLabel>

              <Switch
                id={'switch-caption-by-default'}
                isOn={enabled}
                onColor={colors.primary[100]}
                handleToggle={() => {
                  handleEnableSubtitles(!enabled);
                }}
              />
            </InputWrapper>
          )}
        </ItemContainer>
      </ItemsWrapper>
    </>
  );
};
