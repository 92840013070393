import { statuses } from 'app/pages/library/components/Constants';
import { uploadAttachmentExpress } from 'lib/api';
import { AttachedFile } from 'lib/context';
import { formatBytes } from './functions';

export const MAX_ATTACHMENT_SIZE = 20 * Math.pow(1024, 2); // 20 MB
export const EXCEEDED_MESSAGE = `By adding this file, you will exceed the limit of ${formatBytes(
  MAX_ATTACHMENT_SIZE,
  2
)}.`;
export type StatusOptions = {
  status?: string;
  currentUpload?: number;
  progress?: number;
};

export const uploadAttachmentWithProgress = async (
  file: any,
  updateStatus: (arg1: StatusOptions) => void,
  cancelToken?: any
) => {
  const formData = new FormData();

  formData.append('file', file);
  // change status to uploading
  updateStatus({ status: statuses.UPLOADING });

  const uploadedFile = await uploadAttachmentExpress(
    formData,
    (total: any, uploaded: any, progress: any) => {
      // upload progress
      updateStatus({
        currentUpload: Number(formatBytes(uploaded, 2).split(' ')[0]),
        progress: progress,
      });
    },
    cancelToken
  );

  updateStatus({
    status: statuses.FINISHED,
    progress: 100,
  });

  return uploadedFile;
};

export const checkUploadedSize = (
  uploadedFiles: AttachedFile[],
  max: number
) => {
  const summedSize = uploadedFiles.reduce(
    (a: number, b: File | AttachedFile) => a + b.size,
    0
  );
  return {
    summedSize,
    remaningSize: max - summedSize,
  };
};

export const getImageFileExtensions = () => [
  '.png',
  '.jpg',
  '.jpeg',
  '.svg',
  '.tiff',
];
