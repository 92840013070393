import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { theme } from 'lib/style';
import { VideoRow } from '../components/VideoRow';
import { VideoThumbnail } from '../components/VideoThumbnail';
import { toMMSS } from 'lib/utils/functions';
import InputRange from 'react-input-range';
import { Player } from '../components/Player';
import { HiChevronLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { reorder } from 'lib/utils/array';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { MdAdd, MdSave, MdVideocam, MdZoomIn, MdZoomOut } from 'react-icons/md';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';
import { InformationBox } from 'lib/components/InformationBox';
import { IoMdCloseCircle } from 'react-icons/io';
import selectors from '../../../../../cypress/selectors';

const VideoMergeLayout = styled.div`
  background-color: ${theme.palette.white};
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  justify-content: space-between;
  overflow: overlay;
  margin: auto;
  width: 592px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 32px;
  margin-top: 32px;
`;

const Title = styled.span`
  font-size: 24px;
  font-weight: 800;
  line-height: 1.67;
  color: ${theme.palette.black_1_100};
`;

const ContentWrapper = styled.div`
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -36px;
  min-height: 400px;
`;

const Content = styled.div`
  background-color: #f6f7f9;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 115px;
  padding-left: 8px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

type ThumbnailProps = {
  marginTop?: number;
};

const ThumbnailWrapper = styled.div<ThumbnailProps>`
  padding-top: 67px; /* SUS-905 changes */
  margin-top: ${props => props.marginTop || 0}px;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-top: 8px;
`;

type RowProps = {
  width?: number;
  height?: number;
  marginTop?: number;
};

const RowWrapper = styled.div<RowProps>`
  margin: 0;
  float: left;
  flex-direction: column;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`;

const RangeContainer = styled.div<RowProps>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  margin-bottom: 8px;
`;

const RowContainer = styled.div<RowProps>`
  height: ${props => (props.height ? `${props.height}px` : '')};
  min-height: ${props =>
    props.height ? `${props.height + 7}px` : ''}; /* SUS-905 changes */
  overflow-x: auto;
  overflow-y: hidden;
  width: 80%;
  padding-top: 39px; /* SUS-905 changes */
  padding-bottom: 26px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: auto; /* Firefox */
  ::-webkit-scrollbar {
    display: block;
  }

  -ms-overflow-style: scrollbar;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 4px; /* SUS-905 changes */
  }
  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.gray};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 27, 83, 0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 27, 83, 0.4);
  }
`;

const BlankRowsContainer = styled.div<RowProps>`
  position: absolute;
  width: 84%;
`;

const ContentHeader = styled.div`
  padding: 32px;
  height: 10px; /* SUS-905 changes */
  width: calc(100%-64px);
  display: flex;
  justify-content: space-between;
`;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  border-radius: 6px;
`;

const RowTime = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20px;
`;

const RowTicker = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 8px;
`;

type TimeProps = {
  width?: number;
  rightBorder?: boolean;
  inside?: boolean;
};

const Tick = styled.div<TimeProps>`
  width: ${props => props.width || 20}px;
  height: ${props => (props.inside ? 4 : 8)}px;
  border-left: 1px solid rgba(0, 27, 83, 0.2);
  border-right: ${props =>
    props.rightBorder && '1px solid rgba(0, 27, 83, 0.2)'};
  border-bottom: ${props =>
    props.inside && '4px solid' + theme.palette.primaryDarkBlue10Percent};
`;

const Time = styled.div<TimeProps>`
  font-size: 12px;
  line-height: 1.43;
  color: #001b53;
  width: ${props => props.width || 220}px;
  height: 20px;
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div`
  background: #ffffff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  height: 80px;
  margin-bottom: 8px;
  position: relative;
`;

type InputControlProps = {
  disabled?: boolean;
};
const InputControl = styled.div<InputControlProps>`
  width: 200px;
  height: 4px;
  border-radius: 4px;
  background-color: #d0d3d9;
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 6px 12px rgba(66, 79, 104, 0.06);

  .input-range__slider {
    appearance: none;
    background: ${props =>
      props.disabled ? theme.palette.gray60 : theme.palette.secondaryBlue};
    border: 2px solid #ffffff;
    border-radius: 100%;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    display: block;
    width: 16px;
    height: 16px;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
  }
  .input-range__track--active {
    background: ${props => props.theme.colors.primary[100]};
    height: 4px;
    border-radius: 10px;
  }

  .input-range__track {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;
  }
`;

type ButtonProps = {
  disabled?: boolean;
};

const ZoomButton = styled.div<ButtonProps>`
  min-width: 20px;
  margin-left: 0;
  margin-right: 12px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  height: 40px;
  width: 46px;
  border-radius: 4px;
  transition: width 0.2s ease;
  overflow: hidden;
  ${theme.mediaQueryMinWidth.md} {
    margin-right: 0;
  }
`;

const ZoomContainer = styled.div`
  width: 200px;
  height: 40px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  gap: 13px;
  align-items: center;
  background: #ffffff;
  border: 1px solid rgba(0, 27, 83, 0.1);
  border-radius: 6px;
`;

const BackButton = styled(Link)`
  margin-left: -35px;
  margin-top: -30px;
  position: absolute;
  border: none;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  line-height: 1.6;
  padding: 10px 16px 10px 32px;
  color: ${theme.palette.primaryDarkBlue};
  font-weight: 500;
  min-width: 80px;
  text-decoration: underline;
  &:hover {
    color: ${theme.palette.primaryDarkBlue};
    background-color: ${theme.palette.white};
  }
  ${theme.mediaQueryMinWidth.mb} {
    aside {
      display: none;
    }
  }
  ${theme.mediaQueryMinWidth.md} {
    margin-top: 0;
    margin-left: -22%;
  }
  ${theme.mediaQueryMinWidth.sm} {
    width: 100px;
    aside {
      display: block;
    }
    img {
      margin: 0 16px 0 0;
    }
  }
`;

const HelpDeskWrapper = styled.div`
  width: 592px;
  margin: 0 auto;
`;

const CloseContainer = styled.div`
  position: absolute;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export type VideosProps = {
  id: string;
  start: string;
  end: string;
  startSec: number;
  endSec: number;
  flvName: string;
  duration: number;
  trimDuration: number;
  width: number;
  ref: any;
  source: string;
  processing: number;
};

const findNearestMultiple = (n: number, multiple: number) => {
  if (n > 0) return Math.ceil(n / multiple) * multiple;
  else return multiple;
};

export const VideoMerge = ({
  videos = [],
  handleShowRecordScreen = () => {},
  setVideos = () => {},
  mergeVideos = () => {},
  openModal = () => {},
}: any) => {
  const [duration, setDuration] = useState(0);
  const [trimDuration, setTrimDuration] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [secWidth, setSecWidth] = useState(10);
  const [tickerCount, setTickerCount] = useState(20);
  const [_, setHasHorizontalScrollbar] = useState(false);

  const hasProcessingVideos = !!videos.find(
    (v: any) => v?.processing === VideoEncodingStatus.PROCESSING
  );

  useEffect(() => {
    setDuration(
      videos.reduce((sum: number, v: any) => sum + v.duration, 0) || 0
    );
    setTrimDuration(
      videos.reduce((sum: number, v: any) => sum + v.trimDuration, 0) || 0
    );
  }, [videos]);

  useEffect(() => {
    setHeight(28 + videos.length * 88 + 4);
  }, [videos]);

  useEffect(() => {
    function handleResize() {
      const w = Math.max(
        findNearestMultiple(duration * secWidth, secWidth),
        window.innerWidth - 223
      );
      setWidth(w);
      setTickerCount(Math.round(w / secWidth));
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [duration, secWidth]);

  const removeVideo = (index: number) => {
    setVideos((vtm: any) => {
      const newVideos = [...vtm];
      newVideos.splice(index, 1);
      return newVideos;
    });
  };

  const setVideoRef = (index: number, videoRef: any) => {
    const vtm = [...videos];
    vtm[index].ref = videoRef;
    setVideos(vtm);
  };

  useEffect(() => {
    const container = document.getElementById('row-container');
    const hasScrollbar =
      !!container && container.clientWidth < duration * secWidth;
    setHasHorizontalScrollbar(hasScrollbar);
  }, [duration, secWidth]);

  const calculateVideoOffset = (i: number): number => {
    if (i === 0) {
      return 0;
    }
    const previousVideo = videos[i - 1];
    const video = videos[i];
    if (i === 1) {
      return previousVideo.endSec * secWidth - video.startSec * secWidth;
    }
    return (
      previousVideo.trimDuration * secWidth +
      previousVideo.startSec * secWidth -
      video.startSec * secWidth +
      calculateVideoOffset(i - 1)
    );
  };

  const onDragEnd = async (result: any) => {
    if (!result.destination) {
      return;
    }
    const updatedVideos = reorder(
      videos,
      result.source.index,
      result.destination.index
    );

    setVideos(updatedVideos);
  };

  return (
    <VideoMergeLayout>
      <HelpDeskWrapper>
        <HelpDesk name={EHelpDesk.MERGE} />
      </HelpDeskWrapper>
      {hasProcessingVideos && (
        <HelpDeskWrapper>
          <InformationBox text='Some of the videos are still processing. Please wait until they are done, and then proceed with merging.' />
        </HelpDeskWrapper>
      )}

      <Wrapper>
        <BackButton to={'/'}>
          <HiChevronLeft
            color={theme.palette.covideoBlue100}
            width={16}
            height={16}
          />

          <aside>Back</aside>
        </BackButton>
        <Title>Merge Videos</Title>
        <Button
          onClick={mergeVideos}
          text={'Save Video'}
          variant='primary'
          disabled={!videos || videos.length < 2 || hasProcessingVideos}
          icon={<MdSave size={18} />}
          data-cyk={selectors.mergeVideosPage.saveMergedVideosButton}
        />
      </Wrapper>
      <PreviewContainer>
        {videos && !!videos.length && (
          <Player
            width={592}
            height={332}
            displayVideos={videos}
            hasProcessingVideos={hasProcessingVideos}
          />
        )}
      </PreviewContainer>
      <ContentWrapper>
        <ContentHeader>
          <Gap height='40px' gap='8px'>
            <Button
              text={'Add More Videos'}
              onClick={openModal}
              variant='white'
              icon={<MdAdd size={18} />}
            />

            <Button
              text={'Record'}
              onClick={handleShowRecordScreen}
              variant='red'
              icon={<MdVideocam size={24} />}
            />
          </Gap>
          <ZoomContainer>
            <ZoomButton
              onClick={() =>
                !hasProcessingVideos &&
                secWidth > 10 &&
                setSecWidth(secWidth - 5)
              }
              disabled={secWidth === 10 || hasProcessingVideos}
            >
              <MdZoomOut size={24} color={theme.palette.covideoBlue100} />
            </ZoomButton>
            <InputControl disabled={hasProcessingVideos}>
              <InputRange
                maxValue={200}
                minValue={10}
                value={secWidth}
                onChange={value => setSecWidth(value as number)}
                onChangeComplete={value => setSecWidth(value as number)}
                disabled={hasProcessingVideos}
              />
            </InputControl>
            <ZoomButton
              onClick={() =>
                !hasProcessingVideos &&
                secWidth < 200 &&
                setSecWidth(secWidth + 5)
              }
              disabled={secWidth === 200 || hasProcessingVideos}
            >
              <MdZoomIn size={24} color={theme.palette.covideoBlue100} />
            </ZoomButton>
          </ZoomContainer>
        </ContentHeader>
        {videos && !!videos.length && (
          <Content>
            <ThumbnailWrapper>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='videos'>
                  {provided => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {videos.map((v: any, i: number) => (
                        <Draggable
                          draggableId={`${i}${v.id.toString()}`}
                          index={i}
                          key={`${i}${v.id}`}
                          isDragDisabled={hasProcessingVideos}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <VideoThumbnail
                                index={i}
                                video={v}
                                key={`${v.id}${i}thumb`}
                                setVideos={setVideos}
                                removeVideo={removeVideo}
                                setVideoRef={setVideoRef}
                                dragDisabled={hasProcessingVideos}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ThumbnailWrapper>
            {videos && videos.length && (
              <RowContainer height={height} id='row-container'>
                <RangeContainer width={width}>
                  <RowTime>
                    {[...Array(Math.round(tickerCount / 12)).keys()].map(k => (
                      <Time width={12 * secWidth} key={k}>
                        <span>{toMMSS(k * 12, 0)}</span>
                        {k + 1 === Math.round(tickerCount / 12) && (
                          <span>{toMMSS((k + 1) * 12, 0)}</span>
                        )}
                      </Time>
                    ))}
                  </RowTime>
                  <RowTicker>
                    {[...Array(tickerCount).keys()].map(k => (
                      <Tick
                        width={secWidth - 1}
                        key={k}
                        rightBorder={k + 1 === tickerCount}
                        inside={
                          k >= videos[0].startSec &&
                          k <= videos[0].startSec + trimDuration
                        }
                      />
                    ))}
                  </RowTicker>
                </RangeContainer>
                <BlankRowsContainer>
                  {videos.map((v: any, i: number) => (
                    <Row key={`${v.id}${i}`}>
                      <CloseContainer>
                        <IoMdCloseCircle
                          onClick={() => removeVideo(i)}
                          size={24}
                          color='rgba(232, 76, 61, 0.6)'
                        />
                      </CloseContainer>
                    </Row>
                  ))}
                </BlankRowsContainer>
                <RowWrapper>
                  {videos.map((v: any, i: number) => (
                    <VideoRow
                      index={i}
                      totalDuration={duration}
                      offset={calculateVideoOffset(i)}
                      width={v.duration * secWidth}
                      video={v}
                      key={`${v.id}${i}row`}
                      setVideos={setVideos}
                      /* hide preview track for merge since it is not optimized for multiple videos */
                      showPreviewTrack={false}
                      hasProcessingVideos={hasProcessingVideos}
                    />
                  ))}
                </RowWrapper>
              </RowContainer>
            )}
          </Content>
        )}
      </ContentWrapper>
    </VideoMergeLayout>
  );
};
