import { ISOCKET_ACTIONS, ISOCKET_ON_TYPES } from './types';

export const SOCKETS_ON_TYPES: ISOCKET_ON_TYPES = {
  CONNECT: ISOCKET_ACTIONS.CONNECT,
  DISCONNECT: ISOCKET_ACTIONS.DISCONNECT,
  CONNECT_USER: ISOCKET_ACTIONS.CONNECT_USER,
  LANDING_PAGE_ONLINE: ISOCKET_ACTIONS.LANDING_PAGE_ONLINE,
  LANDING_PAGE_OFFLINE: ISOCKET_ACTIONS.LANDING_PAGE_OFFLINE,
  NOTIFICATION_NEW: ISOCKET_ACTIONS.NOTIFICATION_NEW,
  MESSAGE_RECEIVED: ISOCKET_ACTIONS.MESSAGE_RECEIVED,
  CONVERSATION_RECEIVED: ISOCKET_ACTIONS.CONVERSATION_RECEIVED,
  INIT_RETRIEVE_CONVERSATION: ISOCKET_ACTIONS.INIT_RETRIEVE_CONVERSATION,
  RETRIEVE_CONVERSATION: ISOCKET_ACTIONS.RETRIEVE_CONVERSATION,
} as const;

export const SOCKETS_EMIT_TYPES = {
  CONNECT_USER: ISOCKET_ACTIONS.CONNECT_USER,
  INIT_RETRIEVE_CONVERSATION: ISOCKET_ACTIONS.INIT_RETRIEVE_CONVERSATION,
} as const;
