import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { contactsKeys } from './queryKeys';
import { DeleteContactParams } from './types';

export const deleteContacts = async (ids: string[]) => {
  const response = await EXPRESS_API.delete('/contacts', { data: ids });
  return response.data;
};

export const useDeleteContactsMutation = (params: DeleteContactParams) => {
  const { ids, onDeleteContactsMutationSuccess } = params;
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation(() => deleteContacts(ids), {
    onError: err => showError(err),
    onSuccess: async () => {
      successToast({
        title: `Contact${ids.length > 1 ? 's' : ''} successfully deleted!`,
      });
      await queryClient.invalidateQueries(contactsKeys.all());
      if (onDeleteContactsMutationSuccess) {
        onDeleteContactsMutationSuccess();
      }
    },
  });
};
