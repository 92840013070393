import { EXPRESS_API } from 'configs/api/expressApi';
import { CreateCarServiceBulkParams } from 'lib/api/carService/types';
import { useMutation, useQueryClient } from 'react-query';
import { carServiceKeys } from './carServiceKeys';

const createCarServiceBulk = async ({ data }: CreateCarServiceBulkParams) => {
  const response = await EXPRESS_API.post(`/services/bulk`, { services: data });
  return response.data;
};

export const useCreateCarServiceBulkMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createCarServiceBulk, {
    onSuccess: () => {
      queryClient.invalidateQueries(carServiceKeys.all());
    },
  });
};
