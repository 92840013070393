import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import CustomLegend from '../legend/CustomLegend';
import WidgetHeader from './common/WidgetHeader';
import { WidgetWrapper } from './common/styles';
import { getReports } from 'lib/api';
import { useQuery } from 'react-query';
import { widget } from './keys';
import {
  IParams,
  REPORTS_STALE_TIME,
  WidgetLabels,
  WidgetValues,
} from './types';
import { EmptyWidget } from '../EmptyWidget';
import CustomTooltip from '../tooltip/CustomTooltip';

const StatsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
const RecordedWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;
const RecordedNumber = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #272a32;
`;
const RecordedDesc = styled.p`
  color: #9297a2;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const COLORS = ['#001B53', '#B6E0EC', '#FF8B22'];

interface PieChartDescProps {
  title: string;
  params: IParams;
  addDataForExport: (data: any) => void;
}

interface IApiData {
  id: number | string;
  name: string;
  value: number;
}

interface IMapperData {
  data: IApiData[];
  total: number;
}

const apiUrl = 'metrics/videos/save-type';

const VideoPieChart = ({
  title,
  params,
  addDataForExport,
}: PieChartDescProps) => {
  const { data, isLoading, isRefetching } = useQuery(
    widget.detail(WidgetValues.NEW_VIDEOS, params),
    () => getReports(params, apiUrl),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const mapDataForExport = (data: any) => {
    const { widgetData } = data;
    let exportData: any = { title: WidgetLabels.NEW_VIDEOS, data: [], arr: [] };
    if (widgetData && widgetData.total !== 0) {
      let temp: any = {};
      temp['Recorded'] = widgetData.total;
      widgetData?.data?.forEach((el: any) => {
        const percentage = (el.value / widgetData.total) * 100;
        temp[el.name] = `${percentage.toFixed(1)}%`;
      });
      exportData.data.push(temp);
    }
    return exportData;
  };

  const organizeWidgetData = (data: any) => {
    let mapper: IMapperData = { data: [], total: 0 };

    if (!data || !data.length) {
      return mapper;
    }
    let total = 0;
    for (const key in data[0]) {
      if (key !== 'Total') {
        mapper.data.push({
          id: key,
          name: key,
          value: parseInt(data[0][key]),
        });
      }
      total = parseInt(data[0]['Total']);
    }
    mapper.data.sort(function (a, b) {
      return b.value - a.value;
    });
    mapper.total = total;

    return mapper;
  };

  let widgetData = organizeWidgetData(data);

  useEffect(() => {
    if (widgetData) {
      addDataForExport(mapDataForExport({ widgetData }));
    }
  }, [data]);

  if (isLoading) {
    return <SkeletonLoader imageName='pie-desc' />;
  }

  return (
    <WidgetWrapper>
      <WidgetHeader title={title} isRefetching={isRefetching}>
        <StatsWrapper>
          {/* <TrendUpWrapper>
            <TrendUp />
            <TrendUpPercentage>12.4%</TrendUpPercentage>
          </TrendUpWrapper> */}
          {widgetData.total !== 0 && (
            <RecordedWrapper>
              <RecordedNumber>{widgetData.total}</RecordedNumber>
              <RecordedDesc>recorded</RecordedDesc>
            </RecordedWrapper>
          )}
        </StatsWrapper>
      </WidgetHeader>
      {widgetData.total !== 0 ? (
        <PieChart width={400} height={185}>
          <Pie
            data={widgetData.data}
            dataKey='value'
            fill='#FC0'
            innerRadius={30}
            cx={65}
            cy={75}
          >
            {widgetData.data.map((entry: any, index: number) => (
              <Cell key={entry.id} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend
            content={<CustomLegend payload={widgetData} />}
            layout='vertical'
            verticalAlign='middle'
            align='right'
          />
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      ) : (
        <EmptyWidget />
      )}
    </WidgetWrapper>
  );
};

export default VideoPieChart;
