import { IVideoSettings } from '../types';

const mergeAudioStreams = (
  desktopStream: MediaStream,
  micStream: MediaStream
) => {
  const context = new AudioContext();
  const desktopStreamSource = context.createMediaStreamSource(desktopStream);
  const micStreamSource = context.createMediaStreamSource(micStream);
  const destination = context.createMediaStreamDestination();

  const desktopGain = context.createGain();
  const micGain = context.createGain();

  desktopGain.gain.value = 0.7;
  micGain.gain.value = 0.7;

  desktopStreamSource.connect(desktopGain).connect(destination);
  micStreamSource.connect(micGain).connect(destination);

  return destination.stream.getTracks();
};

export const handleScreenCapture = async (
  displayMediaRef: React.MutableRefObject<MediaStream | null>,
  audioSettings: boolean | { deviceId: string },
  videoSettings: MediaTrackConstraints
) => {
  const displayMediaStreamConstraints: DisplayMediaStreamOptions = {
    video: {
      ...videoSettings,
      displaySurface: 'monitor',
    } as MediaTrackConstraints,
    audio: true,
  };

  // Get video stream from screen sharing
  const displayMediaStream = await navigator.mediaDevices.getDisplayMedia(
    displayMediaStreamConstraints
  );

  // Display media stream helper for clean up
  displayMediaRef.current = displayMediaStream;
  const audioTracks = displayMediaStream.getAudioTracks();
  // check if audio settings exist
  if (audioSettings) {
    const userMediaStream = await navigator.mediaDevices.getUserMedia({
      audio: audioSettings,
    });
    // if audio tab is shared
    if (audioTracks.length > 0) {
      const combinedStreamTracks = [
        ...displayMediaStream.getVideoTracks(),
        ...mergeAudioStreams(displayMediaStream, userMediaStream),
      ];

      return new MediaStream(combinedStreamTracks);
    }

    // of tab audio is not shared
    const combinedStreamTracks = [
      ...displayMediaStream.getVideoTracks(),
      ...userMediaStream.getAudioTracks(),
    ];
    return new MediaStream(combinedStreamTracks);
  }

  return displayMediaStream;
};

export const enableCamera = async (
  audioSettings: boolean | { deviceId: string },
  videoSettings: IVideoSettings
) => {
  const cameraStream = await navigator.mediaDevices.getUserMedia({
    audio: audioSettings,
    video: videoSettings,
  });

  return cameraStream;
};

export const getScreenAndCameraStreams = async (
  displayMediaRef: React.MutableRefObject<MediaStream | null>,
  cameraStreamRef: React.MutableRefObject<MediaStream | null>,
  audioSettings: boolean | { deviceId: string },
  videoSettings: IVideoSettings,
  videoCameraPipRef: React.RefObject<HTMLVideoElement>
) => {
  const displayMediaStreamConstraints: DisplayMediaStreamOptions = {
    video: {
      displaySurface: 'monitor',
      logicalSurface: true,
      cursor: 'always',
    } as MediaTrackConstraints,
  };

  // Get camera stream
  const userMediaStream = await navigator.mediaDevices.getUserMedia({
    audio: audioSettings,
    video: videoSettings,
  });
  if (videoCameraPipRef.current) {
    videoCameraPipRef.current.srcObject = userMediaStream;
    videoCameraPipRef.current.muted = true;

    // Play the video element to ensure it's ready for PiP
    await videoCameraPipRef.current.play();
    videoCameraPipRef.current.requestPictureInPicture();
  }
  // Get screen stream
  const displayMediaStream = await navigator.mediaDevices.getDisplayMedia(
    displayMediaStreamConstraints
  );

  // Display media stream helper for clean up
  displayMediaRef.current = displayMediaStream;

  // Camera stream media helper for clean up
  cameraStreamRef.current = userMediaStream;

  const combinedStream = new MediaStream();
  // Add audio track from  userMediaStream to combined stream
  userMediaStream.getAudioTracks().forEach(track => {
    combinedStream.addTrack(track);
  });

  // Add video track from  displayMediaStream to combined stream
  displayMediaStream.getVideoTracks().forEach(track => {
    combinedStream.addTrack(track);
  });

  return combinedStream;
};

export const getSafariScreenAndCameraStreams = async (
  displayMediaRef: React.MutableRefObject<MediaStream | null>,
  cameraStreamRef: React.MutableRefObject<MediaStream | null>,
  audioSettings: boolean | { deviceId: string },
  videoSettings: IVideoSettings,
  videoCameraPipRef: React.RefObject<HTMLVideoElement>
) => {
  const displayMediaStreamConstraints: DisplayMediaStreamOptions = {
    video: {
      displaySurface: 'browser',
      logicalSurface: true,
      cursor: 'always',
    } as MediaTrackConstraints,
  };
  // Get screen stream
  const displayMediaStream = await navigator.mediaDevices.getDisplayMedia(
    displayMediaStreamConstraints
  );
  // Get camera stream
  const userMediaStream = await navigator.mediaDevices.getUserMedia({
    audio: audioSettings,
    video: videoSettings,
  });

  // Display media stream helper for clean up
  displayMediaRef.current = displayMediaStream;

  // Camera stream media helper for clean up
  cameraStreamRef.current = userMediaStream;

  const combinedStream = new MediaStream();
  // Add audio track from  userMediaStream to combined stream
  userMediaStream.getAudioTracks().forEach(track => {
    combinedStream.addTrack(track);
  });

  // Add video track from  displayMediaStream to combined stream
  displayMediaStream.getVideoTracks().forEach(track => {
    combinedStream.addTrack(track);
  });
  if (videoCameraPipRef.current) {
    videoCameraPipRef.current.srcObject = userMediaStream;
    videoCameraPipRef.current.muted = true;

    // Play the video element to ensure it's ready for PiP
    await videoCameraPipRef.current.play();
  }
  return combinedStream;
};
