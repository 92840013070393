import React from 'react';
import { ErrorMessage, Field, FieldProps } from 'formik';
import { TextInput } from 'lib/components';
import { searchPhoneNumberByLocation } from 'lib/api';
import { debounce } from 'lodash';
import {
  ErrorMsg,
  FieldContainer,
  FieldLabel,
  FormProps,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';
import { errorToast } from 'lib/components/toasts/error';

export const SmsPhoneNumberSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue } = props;

  const getPhoneByLocation = React.useCallback(
    debounce(async (location: string) => {
      try {
        if (!location || !location.trim()) {
          setFieldValue('number', '');
          return;
        }

        const params = location.split(',');
        let city = 'Indianapolis';
        let state = 'IN';
        const country = 'US';
        if (params[0] && params[0].trim()) {
          city = params[0].trim();
        }

        if (params[1] && params[1].trim()) {
          state = params[1].trim();
        }

        const resp = await searchPhoneNumberByLocation(city, state, country);
        if (resp && resp.data && resp.data.length > 0) {
          setFieldValue('number', resp.data[0].phone_number);
        } else {
          errorToast({ title: 'Could not find Phone number.' });
        }
      } catch (error) {
        console.log(error);
        errorToast({ title: 'Could not find Phone number.' });
      }
    }, 2500),
    []
  );

  return (
    <Section style={{ paddingTop: '0.5rem' }}>
      <SectionTitle>SMS Phone number</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Location</FieldLabel>
          <Field name='location'>
            {({ field }: FieldProps) => {
              return (
                <FieldContainer>
                  <TextInput
                    {...field}
                    name='location'
                    type='text'
                    onChange={event => {
                      getPhoneByLocation(event.target.value);
                      setFieldValue('location', event.target.value);
                    }}
                  />
                  <ErrorMessage name='location' component={ErrorMsg} />
                </FieldContainer>
              );
            }}
          </Field>
        </Row>
        <Row>
          <FieldLabel>Number</FieldLabel>
          {getInputField('number', true, true, isSubmitting, 'text', true)}
        </Row>
      </RowWrapper>
    </Section>
  );
};
