import { useQuery } from 'react-query';
import { videoTagsKeys } from './videoTagsKeys';
import { useToastError } from 'lib/hooks/useToastError';
import { VideoTag } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

const getVideoTags = async (videoId: number) => {
  const res = await EXPRESS_API.get(`videos/${videoId}/video-tags`);
  return res.data as VideoTag[];
};

export const useGetVideoTags = (videoId: number) => {
  const { showError } = useToastError();
  return useQuery(videoTagsKeys.video(videoId), () => getVideoTags(videoId), {
    onError: showError,
    enabled: !!videoId,
  });
};
