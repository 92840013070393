import React from 'react';
import { SocialButtonWrapper, ButtonTitle } from './style';

interface SocialButtonProps {
  title: string;
  icon: any;
  onClick: () => void;
  color: string;
  padding?: string;
  fontsize?: string;
  font?: string;
}

export const SocialButton = ({
  title,
  icon,
  onClick,
  color,
  padding,
  font,
  fontsize,
}: SocialButtonProps) => {
  return (
    <SocialButtonWrapper
      color={color}
      padding={padding}
      font={font}
      fontsize={fontsize}
      onClick={onClick}
    >
      {icon}
      <ButtonTitle>{title}</ButtonTitle>
    </SocialButtonWrapper>
  );
};
