import axios, { AxiosInstance } from 'axios';
import { API_EXPRESS_URLS } from './types';
import { getCurrentEnvUrl } from './enviroments';
import { getCurrentToken } from './token';

const apiExpressEnvironments = {
  defaultValue: API_EXPRESS_URLS.DEVELOPMENT,
  development: API_EXPRESS_URLS.DEVELOPMENT,
  staging: API_EXPRESS_URLS.STAGING,
  sandbox: API_EXPRESS_URLS.SANDBOX,
  sustaining: API_EXPRESS_URLS.SUSTAINING,
  production: API_EXPRESS_URLS.PRODUCTION,
};

export const apiExpressUrl = getCurrentEnvUrl(apiExpressEnvironments);
const { token, refreshToken } = getCurrentToken();

export const BEARER_TOKEN = `Bearer ${token}`;
export const REFRESH_TOKEN = refreshToken;

export const EXPRESS_API: AxiosInstance = axios.create({
  baseURL: apiExpressUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    ...(!!process.env.REACT_APP_APIKEY
      ? { apikey: process.env.REACT_APP_APIKEY }
      : {}),
  },
});
