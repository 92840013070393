import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Chip } from 'app/pages/account/userAccount/socialProfiles/style';
import { theme } from 'lib/style';
import { calculateDaysLeft } from 'lib/utils/datetime';
import { ModalVideoDeletionRequest } from 'lib/components/modal/ModalVideoDeletionRequest';
import { isObjEmpty } from 'app/pages/library/videos/videoList/components/SendAndShare';
import { StopRequestData } from 'lib/api/videoDeletes/types';
import { useVideoDeleteQuery } from 'lib/api/videoDeletes/useVideoDeleteQuery';
import { useCreateStopVideoDeleteMutation } from 'lib/api/videoDeletes/useCreateStopVideoDeleteMutation';

const VideoDeleteWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  margin-top: 6px;
  font-size: 15px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #001b53;
  cursor: pointer;
`;

const PendingRequest = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 27, 83, 0.4);
`;

interface DeletionInfoProps {
  bgColor?: string;
  fontColor?: string;
  dateOfCreation: string;
  videoId: string;
}

const DeletionInfo = ({
  bgColor,
  fontColor,
  dateOfCreation,
  videoId,
}: DeletionInfoProps) => {
  const { data: videoDelete } = useVideoDeleteQuery();

  const [hasRequest, setHasRequest] = useState<StopRequestData | undefined>(
    undefined
  );
  const [daysLeft, setDaysLeft] = useState(-1);
  const [pendingRequest, setPendingRequest] = useState(false);

  const [showVideoDeletionRequest, setShowVideoDeletionRequest] =
    useState(false);

  const closeVideoDeletionRequest = () => {
    setShowVideoDeletionRequest(false);
  };

  const { mutateAsync: stopVideoDeleteRequest } =
    useCreateStopVideoDeleteMutation(
      closeVideoDeletionRequest,
      setPendingRequest
    );

  const handleCreateVideoDeletionRequest = async (message: string) => {
    if (videoDelete && videoDelete.deleteId) {
      const data = {
        deleteId: videoDelete.deleteId,
        videoId,
        messages: [message],
      };
      await stopVideoDeleteRequest(data);
    }
  };

  const openVideoDeletionRequest = () => {
    setShowVideoDeletionRequest(true);
  };

  useEffect(() => {
    if (videoDelete) {
      const stopRequest =
        videoDelete &&
        videoDelete.stopRequests &&
        videoDelete.stopRequests.find &&
        videoDelete.stopRequests.find((sr: any) => sr.videoId == videoId);

      setHasRequest(stopRequest);
      setDaysLeft(calculateDaysLeft(videoDelete, stopRequest, dateOfCreation));
    }
  }, [videoDelete]);

  useEffect(() => {
    if (hasRequest) {
      setPendingRequest(hasRequest.approved === null);
    }
  }, [hasRequest]);

  if (videoDelete && isObjEmpty(videoDelete)) {
    return <></>;
  }

  return (
    <VideoDeleteWrapper>
      {!!videoDelete?.notify && (
        <Chip
          bgColor={bgColor || theme.palette.covideoBlue100}
          fontColor={fontColor || theme.palette.white}
        >
          {`${daysLeft} days until deletion`}
        </Chip>
      )}
      {!!videoDelete?.exclude &&
        (!hasRequest || hasRequest.approved !== null) &&
        !pendingRequest && (
          <Button onClick={openVideoDeletionRequest}>Request extension</Button>
        )}
      {pendingRequest && <PendingRequest>Request Sent</PendingRequest>}
      {showVideoDeletionRequest && (
        <ModalVideoDeletionRequest
          handleModalClose={closeVideoDeletionRequest}
          handleSubmit={handleCreateVideoDeletionRequest}
        />
      )}
    </VideoDeleteWrapper>
  );
};

export default DeletionInfo;
