import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getDepartments } from 'lib/api';
import { Search, Dropdown, LoadingIndicator } from 'lib/components';
import { useAuth } from 'lib/context';
import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { MdAddCircle, MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';
import { ShareWithMessageModal } from './ShareWithMessageModal';
import { FileTypes } from 'app/pages/account/userAccount/recentlyDeleted/types';
import { CombinedBoard, DropItem } from 'lib/api/droplr/types';
import { getDroplrTeamUsers } from 'lib/api/droplr/getDroplrTeamUsers';
import { getTeamMembersForUser } from 'lib/api/droplr/getTeamMembersForUser';

dayjs.extend(utc);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #eeeff2;
  border-radius: 7px 7px 0px 0px;
  height: auto;
  padding: 8px;
  .fullWidthSearchBar {
    width: 60% !important;
    display: inline-flex;
  }
  width: 100%;
  margin-top: 5px;
  box-sizing: border-box;
`;

const Separator = styled.div`
  position: static;
  height: 1px;
  left: 8px;
  right: 8px;
  top: 56px;

  /* Covideo/Grey/10 */

  background: #f6f7f9;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
`;

const TextLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  color: #4e5461;
`;

const List = styled.ul`
  padding: 0px;
  width: 100%;
`;

const ListItem = styled.li`
  width: 100%;
  height: 40px;
  margin: 8px 0px;
  background: #ffffff;
  border-radius: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  color: #272a32;
  padding: 0 8px;
  box-sizing: border-box;
`;

const DropdownContainer = styled.div`
  width: 135px;
  height: 40px;
  margin-right: 15px;
  float: right;
  cursor: pointer;
  & > div > div:first-of-type {
    border: none !important;
    height: 40px !important;
    min-height: 20px !important;
  }

  & > div > div > div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-style: normal;
    font-size: 14px;
    color: #9297a2;
    line-height: 1;
  }
`;

const DropdownContainerDept = styled.div`
  width: 38%;
  height: 39px;
  float: right;
  cursor: pointer;
`;

interface Props {
  covideoList?: any;
  setCovideoList?: React.Dispatch<React.SetStateAction<any>>;
  accessList: Array<any>;
  setAccessList: React.Dispatch<React.SetStateAction<any[]>>;
  itemType: 'drop' | 'board';
  showShareWithMessageModal: any;
  setShowShareWithMessageModal: any;
  setDisableButton?: React.Dispatch<React.SetStateAction<boolean>>;
  thumbnailUrl: string;
  sendEmail: any;
  storeNotification: any;

  board?: CombinedBoard;
  drop?: DropItem; // refactor later
}

const permissionTypes = [
  {
    value: 'read',
    label: 'Can view',
  },
  {
    value: 'edit',
    label: 'Can edit',
  },
];

const permissionTypesForFiles = [
  {
    value: 'read',
    label: 'Can view',
  },
  {
    value: 'download',
    label: 'Can download',
  },
];

let departmentDropdownOptions = [
  {
    value: 0,
    label: 'All departments',
  },
];

export const SharingTabContent = ({
  covideoList,
  setCovideoList,
  accessList,
  setAccessList,
  itemType,
  setDisableButton,
  showShareWithMessageModal,
  setShowShareWithMessageModal,
  thumbnailUrl,
  sendEmail,
  storeNotification,
  board,
  drop,
}: Props) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [originalAccessObject, setOriginalAccessObject] = React.useState<any>(
    {}
  );
  const [accessObject, setAccessObject] = React.useState<any>({});
  const [selectedUserCount, setSelectedUserCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentHoverIndex, setCurrentHoverIndex] = React.useState(-1);
  const [departmentOptions, setDepartmentOptions] = React.useState(
    departmentDropdownOptions
  );
  const [selectedDepartment, setSelectedDepartment] = React.useState(
    departmentOptions[0].value
  );
  const [orderedArray, setOrderedArray] = React.useState<any>([]);
  const [covideoUserIdAndDetailsMapping, setCovideoUserIdAndDetailsMapping] =
    React.useState<any>({});
  const [teamMembers, setTeamMembers] = React.useState<any>({});
  const { userData } = useAuth();

  let covideoTeamMembersArray: any;
  let droplrUsersArray: any;
  let covideoTeamObject: any = {};

  const getDepartmentLists = async () => {
    const dept = await getDepartments(userData.customerId, {});
    if (dept.data && dept.data.length) {
      const mapArr: any = [{ label: 'All Departments', value: 0 }];
      dept.data.forEach((element: any) => {
        mapArr.push({ label: element.Name, value: element.DepartmentID });
      });

      setDepartmentOptions(mapArr);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    if (setDisableButton) {
      setDisableButton(true);
    }
    const newAccessObject = { ...accessObject };
    accessList.forEach((item: any) => {
      if (itemType === FileTypes.BOARD) {
        newAccessObject[item.email] = { ...item };
      } else {
        newAccessObject[item.id] = { ...item };
      }
    });

    setAccessObject(newAccessObject);
    setOriginalAccessObject(newAccessObject);
    getTeamMembersForUser()
      .then(data => {
        covideoTeamMembersArray = data.teamMembers;
        return getDroplrTeamUsers();
      })
      .then(droplrUsers => {
        droplrUsersArray = droplrUsers.results;
        const idAndDetailsMapping: any = {};
        covideoTeamMembersArray.forEach((covideoTeamMember: any) => {
          const data = {
            firstName: covideoTeamMember.firstName,
            lastName: covideoTeamMember.lastName,
            dept: covideoTeamMember.Dept,
            deptId: covideoTeamMember.DeptId,
            covideoEmail: covideoTeamMember.email,
            covideoUserId: covideoTeamMember.id,
          };

          covideoTeamObject[covideoTeamMember.droplrEmail] = data;
        });

        setCovideoUserIdAndDetailsMapping(idAndDetailsMapping);
        let maxCount = droplrUsersArray.length;
        let currentCount = 0;
        let teamObject: any = {};
        let orderedDataArray: any = [];
        droplrUsersArray.some((droplrUser: any) => {
          let droplrUserEmail = droplrUser.email;
          if (
            covideoTeamObject[droplrUserEmail] &&
            !teamObject[droplrUserEmail]
          ) {
            ++currentCount;
            teamObject[droplrUserEmail] = {
              ...covideoTeamObject[droplrUserEmail],
              userId: droplrUser.id,
              email: droplrUserEmail,
            };

            idAndDetailsMapping[droplrUser.id] = {
              ...covideoTeamObject[droplrUserEmail],
            };

            let key;
            if (itemType === FileTypes.BOARD) {
              key = droplrUserEmail;
            } else {
              key = droplrUser.id;
            }

            if (newAccessObject[key]) {
              orderedDataArray.push(droplrUserEmail);
            }
          }

          if (currentCount >= maxCount) {
            return true;
          } else {
            return false;
          }
        });

        Object.keys(teamObject).forEach((key: any) => {
          if (orderedDataArray.indexOf(teamObject[key].email) === -1) {
            orderedDataArray.push(teamObject[key].email);
          }
        });
        setCovideoUserIdAndDetailsMapping(idAndDetailsMapping);
        if (setCovideoList) {
          setCovideoList(idAndDetailsMapping);
        }

        setOrderedArray(orderedDataArray);
        setTeamMembers(teamObject);
        setIsLoading(false);
        if (setDisableButton) {
          setDisableButton(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        if (setDisableButton) {
          setDisableButton(false);
        }
      });

    getDepartmentLists();
  }, []);

  function setGroupAccessListArrayFromAccessObject() {
    let accessArray = [];
    for (let key in accessObject) {
      let data = accessObject[key];
      delete data['timestamp'];
      accessArray.push(data);
    }

    setAccessList(accessArray);
  }

  const getSelectedUsers = () => {
    let selectedUsersArray: string[] = [];
    Object.keys(teamMembers).forEach((key: string) => {
      Object.keys(accessObject).forEach((id: string) => {
        if (
          teamMembers[key].userId ===
          (accessObject[id]?.userId || accessObject[id]?.id) // The key name for 'user id' is different in case of files(id) and boards(userId)
        ) {
          selectedUsersArray.push(id);
        }
      });
    });
    return selectedUsersArray;
  };

  React.useEffect(() => {
    const countUsers = getSelectedUsers();
    setSelectedUserCount(countUsers.length);
    setGroupAccessListArrayFromAccessObject();
  }, [teamMembers, accessObject]);

  const sendNotification = (message: string) => {
    const emailArray: any = [];
    const covideoIds: any = [];
    let dropTitle = '';
    let dropUrl = '';
    let dropId = '';
    let dropCreatedDate = null;
    Object.keys(accessObject).forEach((key: any) => {
      if (originalAccessObject[key]) {
        return null;
      }

      if (itemType === FileTypes.BOARD && board) {
        emailArray.push(teamMembers[key].covideoEmail);
        covideoIds.push(teamMembers[key].covideoUserId);
        const item = board;
        dropTitle = item?.name;
        dropUrl = item?.shortlink || '';
        dropId = item?.id;
        dropCreatedDate = '-';
      } else if (itemType === 'drop' && drop) {
        emailArray.push(covideoUserIdAndDetailsMapping[key].covideoEmail);
        covideoIds.push(covideoUserIdAndDetailsMapping[key].covideoUserId);
        const item = drop;
        dropTitle = item.title;
        dropUrl = item.shortlink || '';
        dropId = item.code;
        dropCreatedDate = item.createdAt
          ? dayjs(item.createdAt).format('D MMM, YYYY')
          : '-';
      }
    });

    const dropType = itemType === FileTypes.BOARD ? 'Board' : 'File';
    const userWithSuffix = `${selectedUserCount > 1 ? 'users' : 'user'}`;
    const toastMessage = !!emailArray.length
      ? `${dropType} shared with ${selectedUserCount} ${userWithSuffix}`
      : `${dropType} already shared with the selected ${userWithSuffix}`;

    const emailData = {
      dropMessage: message,
      recipientEmails: emailArray.join(','),
      dropTitle,
      dropType,
      dropUrl,
      dropCreatedDate,
      dropThumbnailUrl: thumbnailUrl,
    };
    if (covideoIds && covideoIds.length > 0) {
      const notificationData = {
        entityName: dropTitle,
        entityUrl: dropUrl,
        recipientIds: covideoIds,
        type: dropType.toLowerCase() + 's',
        entityId: dropId,
        senderId: parseInt(userData.userId, 10),
        senderName: `${userData.firstName} ${userData.lastName}`,
      };
      storeNotification(notificationData);
    }
    sendEmail(emailData, toastMessage);
  };

  const addPermision = (email: string, userId: number, accessRight: string) => {
    const newEntry = {
      email,
      userId,
      accessRight,
    };

    let data = { ...accessObject };
    data[email] = newEntry;

    setAccessObject(data);
  };

  const addPermissionForDrop = (userId: number) => {
    const newEntry = {
      id: userId,
    };

    let data = { ...accessObject };
    data[userId] = newEntry;

    setAccessObject(data);
  };

  const addPermissionForAll = () => {
    const accessRightData = { ...accessObject };
    Object.keys(teamMembers).forEach((key: any) => {
      const member = teamMembers[key];
      const { email, userId } = member;
      const accessRight = permissionTypes[0].value;
      if (!accessRightData[email]) {
        accessRightData[email] = { email, userId, accessRight };
      }
    });

    setAccessObject(accessRightData);
  };

  const addPermissionForAllForDrop = () => {
    const accessRightData = { ...accessObject };
    Object.keys(teamMembers).forEach((key: any) => {
      const member = teamMembers[key];
      const { userId } = member;
      if (!accessRightData[userId]) {
        accessRightData[userId] = { id: userId };
      }
    });

    setAccessObject(accessRightData);
  };

  const removePermissionForAll = () => {
    setAccessObject({});
  };
  const removePermission = (email: string, userId?: number) => {
    let accessData = { ...accessObject };
    delete accessData[email];
    setAccessObject(accessData);
    if (userId) {
      let accessData2 = { ...covideoList };
      delete accessData2[userId];
      if (setCovideoList) {
        setCovideoList(accessData2);
      }
    }
  };

  const removePermissionForDrop = (userId: number) => {
    let accessData = { ...accessObject };
    delete accessData[userId];
    setAccessObject(accessData);
    let accessData2 = { ...covideoList };
    delete accessData2[userId];
    if (setCovideoList) {
      setCovideoList(accessData2);
    }
  };

  const search = (e: any) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      {isLoading && <LoadingIndicator isLoading={isLoading} height='200px' />}
      {!isLoading && (
        <>
          {showShareWithMessageModal && (
            <ShareWithMessageModal
              onMainButtonClick={(message: string) => {
                sendNotification(message);
              }}
              onBackButtonPress={() => {
                setShowShareWithMessageModal(false);
              }}
            />
          )}
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {selectedUserCount === 0 && <TextLabel>Select users</TextLabel>}
            {selectedUserCount > 0 && (
              <TextLabel>
                {selectedUserCount}{' '}
                {'selected user' + (selectedUserCount > 1 ? 's' : '')}
              </TextLabel>
            )}
            {selectedUserCount !== Object.keys(teamMembers).length && (
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: '#001B53',
                  textDecoration: 'underline',
                }}
                onClick={
                  itemType === FileTypes.BOARD
                    ? addPermissionForAll
                    : addPermissionForAllForDrop
                }
              >
                Select all
              </div>
            )}
            {selectedUserCount === Object.keys(teamMembers).length && (
              <div
                style={{
                  cursor: 'pointer',
                  fontWeight: 600,
                  fontSize: '14px',
                  color: '#001B53',
                  textDecoration: 'underline',
                }}
                onClick={removePermissionForAll}
              >
                Deselect all
              </div>
            )}
          </div>

          <Container>
            <div style={{ position: 'static', width: '100%' }}>
              <Search
                placeholder={`Search users...`}
                onSearch={setSearchQuery}
                onChange={(e: any) => {
                  search(e);
                }}
                className={'fullWidthSearchBar'}
                prevSearch={searchQuery}
              />
              <DropdownContainerDept>
                <Dropdown
                  value={departmentOptions.find(department => {
                    return selectedDepartment === department.value;
                  })}
                  onChange={newValue => {
                    setSelectedDepartment(newValue.value);
                  }}
                  options={departmentOptions}
                  dropdownHeight={160}
                  height={39}
                />
              </DropdownContainerDept>
              <Separator />
            </div>
            <div
              style={{
                height: 'auto',
                maxHeight: '260px',
                width: '100%',
                overflowY: 'scroll',
              }}
            >
              <List>
                {orderedArray.map((key: any, index: number) => {
                  let member: any = teamMembers[key];
                  let memberDepartment = member.deptId || 0;
                  if (selectedDepartment && selectedDepartment !== 0) {
                    if (memberDepartment !== selectedDepartment) {
                      return <React.Fragment key={member.userId} />;
                    }
                  }

                  if (!member.userId) {
                    return <React.Fragment key={member.userId} />;
                  }

                  let memberAccess;
                  if (itemType === FileTypes.BOARD) {
                    memberAccess = accessObject[member.email] || null;
                  } else {
                    memberAccess = accessObject[member.userId];
                  }

                  let memberName = member.firstName + ' ' + member.lastName;
                  if (searchQuery) {
                    if (
                      memberName
                        .toLocaleLowerCase()
                        .search(searchQuery.toLowerCase()) < 0
                    ) {
                      return <React.Fragment key={member.userId} />;
                    }
                  }

                  return (
                    <ListItem
                      key={member.userId}
                      onMouseEnter={() => setCurrentHoverIndex(index)}
                      onMouseLeave={() => setCurrentHoverIndex(-1)}
                    >
                      {memberName}
                      <div
                        style={{
                          float: 'right',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '20px',
                        }}
                      >
                        {memberAccess && (
                          <>
                            {currentHoverIndex !== index && (
                              <BsCheck
                                style={{ cursor: 'pointer', color: '#1EA45F' }}
                              />
                            )}
                            {currentHoverIndex === index && (
                              <MdClose
                                style={{ cursor: 'pointer', color: '#E84C3D' }}
                                onClick={() => {
                                  if (itemType === FileTypes.BOARD) {
                                    removePermission(
                                      member.email,
                                      member.userId
                                    );
                                  } else {
                                    removePermissionForDrop(member.userId);
                                  }
                                }}
                              />
                            )}
                          </>
                        )}
                        {!memberAccess && (
                          <MdAddCircle
                            style={{ cursor: 'pointer', color: '#4E5461' }}
                            onClick={() => {
                              if (itemType === FileTypes.BOARD) {
                                addPermision(
                                  member.email,
                                  member.userId,
                                  permissionTypes[0].value
                                );
                              } else {
                                addPermissionForDrop(member.userId);
                              }
                            }}
                          />
                        )}
                      </div>
                      {itemType === FileTypes.BOARD && memberAccess && (
                        <DropdownContainer>
                          <Dropdown
                            value={permissionTypes.find(type => {
                              if (itemType === FileTypes.BOARD) {
                                return (
                                  type.value ===
                                  accessObject[member.email].accessRight
                                );
                              } else {
                                return true;
                              }
                            })}
                            onChange={newValue => {
                              addPermision(
                                member.email,
                                member.userId,
                                newValue.value
                              );
                            }}
                            options={
                              itemType === FileTypes.BOARD
                                ? permissionTypes
                                : permissionTypesForFiles
                            }
                            dropdownHeight={75}
                          />
                        </DropdownContainer>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Container>
        </>
      )}
    </>
  );
};
