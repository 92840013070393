import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components/macro';

import { FormikErrorMessage } from './FormikErrorMessage';
import { FormikLabel } from './FormikLabel';
import { theme } from 'lib/style';

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 8px 12px;
  min-height: 100px;
  max-height: 300px;
  border-radius: 4px;
  box-sizing: border-box;
  border: solid 1px #d0d3d9;
  &:focus {
    outline: none;
    border: solid 1px ${theme.palette.covideoBlue100};
  }
`;

interface CustomTextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
  isRequired: boolean;
}

export const FormikTextAreaField = ({
  label,
  isRequired,
  ...props
}: CustomTextareaProps) => {
  const [field, meta] = useField(props);
  return (
    <div style={{ width: '100%' }}>
      <FormikLabel label={label} name={props.name} isRequired={isRequired} />
      <Textarea {...field} {...props} id={props.name} />
      <FormikErrorMessage meta={meta} />
    </div>
  );
};
