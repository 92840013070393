import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';

import { useToastError } from 'lib/hooks/useToastError';
import { successToast } from 'lib/components/toasts/success';
import { conversationsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IReassignConversationParams {
  conversationId: number;
  payload: {
    userId: number;
    note?: string;
  };
}
const reassignConversation = async ({
  conversationId,
  payload,
}: IReassignConversationParams) => {
  const body = {
    ...payload,
  };

  return (
    await EXPRESS_API.patch(`conversations/${conversationId}/reassign`, body)
  ).data;
};

export const useReassignConversationMutation = (cb: () => void) => {
  const { showError } = useToastError();

  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(reassignConversation, {
    onError: (err: AxiosError) => showError(err),
    onSuccess: () => {
      successToast({ title: 'Your message is reassigned successfully.' });
      queryClient.invalidateQueries(conversationsKeys.lists());
      queryClient.refetchQueries(conversationsKeys.count());
      history.replace({
        pathname: `/conversations`,
      });
      cb();
    },
  });
};
