import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { TextInput, CheckboxInput } from 'lib/components';
import { EMAIL_REGEX } from 'lib/utils/regexes';

const AddMissingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

type InputProps = {
  hasError: boolean;
};
const Input = styled(TextInput)<InputProps>`
  font-family: 'Work Sans';
  width: 380px;
  max-width: 100%;
  margin: 0;
  &:focus {
    border: 1px solid ${theme.palette.gray40};
    box-shadow: none;
  }
  ${props => {
    if (props.hasError) {
      return `border-color: ${theme.palette.red100} !important;`;
    }
  }}
`;

const CheckboxInputWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

type CheckboxLabelProps = {
  disabled: boolean;
};

const CheckboxLabel = styled.label<CheckboxLabelProps>`
  margin-left: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  opacity: ${props => (props.disabled ? '.4' : '1')};
`;

type Props = {
  inputType: string;
  inputValue: string;
  onChangeInputValue: (inputValue: string) => void;
  inputValid: boolean;
  setInputValid: (inputValid: boolean) => void;
  inputPlaceholder?: string;
  setIsChecked?: (isChecked: boolean) => void;
  isChecked?: boolean;
  checkId?: string;
  updatable?: boolean;
  skipInitialValidation?: boolean;
  isSwitchDisabled?: boolean;
};
export const AddMissingInfo = ({
  inputType,
  inputValue,
  onChangeInputValue,
  isChecked = false,
  inputValid,
  setInputValid,
  setIsChecked = () => {},
  checkId = '',
  inputPlaceholder = '',
  updatable = true,
  isSwitchDisabled,
  skipInitialValidation,
}: Props) => {
  const [shouldValidate, setShouldValidate] = useState(!skipInitialValidation);
  const [hideErrorCSS, setHideErrorCSS] = useState(false);
  const validateEmail = (email: string) => {
    return email !== '' && EMAIL_REGEX.test(email);
  };

  const validatePhone = (phone: string) => {
    return !!phone && phone.length >= 7;
  };

  const handleChangeInput = (value: string) => {
    setShouldValidate(true);
    onChangeInputValue(value);
    const valid =
      inputType === 'email'
        ? validateEmail(value)
        : inputType === 'phone'
        ? validatePhone(value)
        : false;

    // Hide error css for email field if the value is an empty string
    setHideErrorCSS(inputType === 'email' && value === '');
    setInputValid(valid);
  };

  useEffect(() => {
    if (isSwitchDisabled && !inputValue.length) setShouldValidate(false);
  }, [inputValue, isSwitchDisabled]);

  return (
    <AddMissingWrapper>
      <Input
        onChange={(e: any) => handleChangeInput(e.target.value)}
        value={inputValue}
        margin={'0 0 32px 0'}
        placeholder={inputPlaceholder}
        hasError={shouldValidate ? !(inputValid || hideErrorCSS) : false}
      />
      {checkId && updatable && (
        <CheckboxInputWrapper>
          <CheckboxInput
            id={checkId}
            width='24px'
            blueCheck={true}
            checked={isChecked && inputValid}
            onChange={(e: React.SyntheticEvent) => {
              const { checked } = e.target as HTMLInputElement;
              setIsChecked(checked);
            }}
            disabled={!inputValid}
          />
          <CheckboxLabel htmlFor={checkId} disabled={!inputValid}>
            Update Customer details with this info
          </CheckboxLabel>
        </CheckboxInputWrapper>
      )}
    </AddMissingWrapper>
  );
};
