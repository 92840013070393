import { getTimezoneByUtc } from 'app/pages/account/userAccount/schedule/utils';
import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import dayjs from 'dayjs';
import { infoSuccessToast } from 'lib/components/toasts/infoSuccess';
import { useToastError } from 'lib/hooks/useToastError';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { scheduleListKeys } from './scheduleListKeys';
import { Schedule } from './types';

const createSchedule = async (data: Partial<Schedule>) => {
  const response = await EXPRESS_API.post(`/schedule`, data);
  return response.data;
};

export const useScheduleMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation((data: Partial<Schedule>) => createSchedule(data), {
    onSuccess: (data: Schedule) => {
      const toastTitle = data.sendEmail
        ? data.sendSms
          ? 'Email and SMS are scheduled successfully!'
          : 'Email is scheduled successfully!'
        : 'SMS is scheduled successfully!';
      const tempList = data.deliveryTime.split(';');
      let dateFormatted = null;
      let timeFormatted = null;
      let timezoneFormatted = '';
      if (tempList.length >= 4) {
        dateFormatted = dayjs(`${tempList[0]}`).format('DD MMMM YYYY');
        let tempTimezoneList: string[] = [];
        const selectedTimezone = getTimezoneByUtc(tempList[3]);
        if (selectedTimezone) {
          tempTimezoneList = selectedTimezone.label.split(')');
        }
        if (tempTimezoneList.length > 0) {
          timezoneFormatted = `${tempTimezoneList[0]})`;
        }

        timeFormatted = `${tempList[1]} ${tempList[2]} ${timezoneFormatted}`;
      }
      infoSuccessToast({
        title: toastTitle,
        additionalInfo:
          dateFormatted && timeFormatted
            ? `It will be sent on ${dateFormatted} at ${timeFormatted}.`
            : undefined,
        button: {
          text: 'View Schedules',
          variant: 'ghost',
          action: () => {
            history.push('/profile/schedules');
          },
        },
      });
      queryClient.invalidateQueries(scheduleListKeys.lists());
    },
    onError: (err: AxiosError) => {
      showError(err);
    },
  });
};
