import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MainGuidePage } from './pages/MainGuidePage';
import { SingleCategory } from './pages/SingleCategory';
import { GuideDetailsPage } from './pages/GuideDetailsPage';

export const GuideRoutes = () => {
  return (
    <Switch>
      <Route path='/guides' component={MainGuidePage} exact={true} />
      <Route path='/guides/:id' component={SingleCategory} exact={true} />
      <Route
        path='/guides/:videoId/details'
        component={GuideDetailsPage}
        exact={true}
      />
    </Switch>
  );
};
