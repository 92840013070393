export const CUSTOMER_IDS = {
  COVIDEO: '3',
  BENOIST_ROUSSEAU: '57756',
  COVIDEO_DEVS: '54188',
  PENSKE_WEST_REGION: {
    BMW_OF_AUSTIN: '67982',
    GENESIS_OF_ROUND_ROCK: '67984',
    HONDA_LEANDER: '67985',
    HYUNDAI_OF_PHARR: '67986',
    LEXUS_OF_AUSTIN: '67939',
    LEXUS_OF_LAKEWAY: '67988',
    MINI_OF_AUSTIN: '67966',
    ROUND_ROCK_HONDA: '67978',
    ROUND_ROCK_HYUNDAI: '67973',
    ROUND_ROCK_TOYOTA: '67977',
    TOYOTA_OF_PHARR: '67942',
    ACURA_OF_ESCONDIDO: '67941',
    AUDI_OF_ESCONDIDO: '67950',
    BMW_MINI_OF_ESCONDIDO: '67990',
    BMW_OF_SAN_DIEGO: '67957',
    HONDA_OF_ESCONDIDO: '67959',
    KEARNY_MESA_ACURA: '67976',
    KEARNY_MESA_TOYOTA: '67960',
    LEXUS_OF_SAN_DIEGO: '67964',
    MAZDA_OF_ESCONDIDO: '67991',
    MERCEDES_BENZ_OF_SAN_DIEGO: '67979',
    MINI_OF_SAN_DIEGO: '67969',
    AUDI_NORTH_OC: '67981',
    AUDI_SOUTH_COAST: '67952',
    BMW_OF_ONTARIO: '67956',
    CREVIER_BMW: '67945',
    CREVIER_MINI: '67958',
    LINCOLN_SOUTH_COAST: '67989',
    MINI_OF_ONTARIO: '67968',
    SUBARU_ORANGE_COAST: '67992',
    VOLKSWAGEN_SOUTH_COAST: '67975',
    AUDI_SAN_JOSE: '67953',
    CAPITOL_ACURA: '67994',
    CAPITOL_HONDA: '67940',
    HONDA_NORTH: '67943',
    MINI_OF_MARIN: '67967',
    PETER_PAN_BMW: '67971',
    PORSCHE_STEVENS_CREEK: '67947',
    TOYOTA_OF_CLOVIS: '67993',
    ACURA_NORTH_SCOTTSDALE: '67948',
    AUDI_CHANDLER: '67949',
    AUDI_NORTH_SCOTTSDALE: '67951',
    BENTLEY_SCOTTSDALE: '67954',
    BMW_NORTH_SCOTTSDALE: '67955',
    LAND_ROVER_CHANDLER: '67987',
    LAND_ROVER_NORTH_SCOTTSDALE: '67962',
    LAMBORGHINI_NORTH_SCOTTSDALE: '67961',
    LEXUS_OF_CHANDLER: '67963',
    MERCEDES_BENZ_OF_CHANDLER: '67946',
    MERCEDES_BENZ_OF_NORTH_SCOTTSDALE: '67980',
    MINI_NORTH_SCOTTSDALE: '67965',
    MINI_OF_TEMPE: '67970',
    PORSCHE_NORTH_SCOTTSDALE: '67972',
    SCOTTSDALE_FERRARI_MASERATI: '67983',
    TEMPE_HONDA: '67944',
    TOYOTA_OF_SURPRISE: '67995',
    VOLKSWAGEN_NORTH_SCOTTSDALE: '67974',
  },
};
