import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const MultiEmailInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 592px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const InputWrapper = styled.div<{ disabled?: boolean }>`
  border: 1px solid ${theme.palette.gray90};
  border-radius: 6px;
  overflow: auto;
  outline: none;
  padding: 4px 12px;
  width: 566px;
  &:hover {
    border: 1px solid ${theme.palette.covideoBlue100};
  }
  background: ${props =>
    props.disabled ? theme.palette.neutral10 : theme.palette.white};
`;

export const ValuesContainer = styled.div<{ hasPadding: boolean }>`
  background: white;
  width: 100%;
  border: 1px solid ${theme.palette.covideoGray70};
  border-radius: 6px;
  position: absolute;
  z-index: 2;
  top: 102%;
  margin-bottom: 20px;
  color: ${theme.palette.primaryDarkBlue};
  max-height: 120px;
  overflow-y: auto;
  ${props => {
    if (props.hasPadding) {
      return `padding: 20px 0px`;
    }
  }}
`;

export const ValueItem = styled.div`
  display: flex;
  padding: 8px 24px;
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  &:hover {
    background: ${theme.palette.covideoBlue10};
  }
`;

export const EmailItem = styled.div`
  display: flex;
  align-items: center;

  line-height: 24px;
  max-height: 32px;
  padding: 4px 6px;
  border-radius: 6px;
  background: ${theme.palette.covideoBlue02NonAlpha};
  color: ${theme.palette.blue100};
`;

export const Email = styled.div`
  margin: 0 8px 0 0;
  font-size: 16px;
  font-weight: 500;
`;

export const StyledInput = styled.input<{ error: string }>`
  font-size: 16px;
  line-height: 22px;
  padding: 4px 6px;
  width: 250px;
  outline: none;
  border-radius: 6px;
  border: none;
  ${props => {
    if (props.error) {
      return `color: ${theme.palette.red100}`;
    }
  }};
`;
