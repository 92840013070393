import {
  Dropdown,
  LoadingIndicator,
  NewModal,
  RadioInput,
} from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Field, Formik, Form, FormikValues, FieldProps } from 'formik';
import styled, { useTheme } from 'styled-components/macro';
import { CategorizedGuide } from 'lib/api/guides/guideCategoryQuery';
import {
  EditableGuides,
  useEditableGuideCategoriesQuery,
} from 'lib/api/guides/editableGuideCategoriesQuery';
import { useDeleteCategory } from 'lib/api/guides/deleteCategoryMutation';
import {
  ParagraphNormal,
  ParagraphNormalBold,
} from 'lib/components/styles/typography';
import { Button } from 'react-covideo-common';

enum TYPE_OF_DELETION {
  DELETE_AND_MOVE = 0,
  DELETE_ALL = 1,
}
interface IProps {
  closeModalHandler: () => void;
  selectedGuideCategory: Pick<
    CategorizedGuide,
    'guideCategoryId' | 'name' | 'guidesCount'
  > | null;
}

interface IDropdown {
  value: number;
  label: string;
}

const Description = styled(ParagraphNormalBold).attrs({ as: 'span' })``;

export const DeleteCategoryModal = ({
  closeModalHandler,
  selectedGuideCategory,
}: IProps) => {
  const themes = useTheme();
  const { data, isLoading } = useEditableGuideCategoriesQuery();
  const { mutateAsync, isLoading: isDeletingOrMoving } =
    useDeleteCategory(closeModalHandler);
  const onSubmitHander = (values: FormikValues) => {
    if (!selectedGuideCategory?.guideCategoryId) {
      return;
    }
    mutateAsync({
      newCategoryId: values.newCategoryId,
      categoryId: selectedGuideCategory?.guideCategoryId,
    });
  };

  const modalHeight = !!selectedGuideCategory?.guidesCount ? 351 : 145;

  const dropdownOptions =
    (data || [])
      .map((editableGuide: EditableGuides) => ({
        value: editableGuide.guideCategoryId,
        label: editableGuide.name,
      }))
      .filter(
        (item: IDropdown) =>
          item.value !== selectedGuideCategory?.guideCategoryId
      ) || [];

  return (
    <NewModal
      headerText='Delete Category?'
      closeModal={closeModalHandler}
      style={{
        content: {
          maxHeight: modalHeight,
          height: modalHeight,
          overflow: 'hidden',
        },
      }}
    >
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={true} />
        </div>
      ) : (
        <Formik
          initialValues={{
            deletionType: !selectedGuideCategory?.guidesCount
              ? TYPE_OF_DELETION.DELETE_ALL
              : TYPE_OF_DELETION.DELETE_AND_MOVE,
            newCategoryId: null,
          }}
          onSubmit={onSubmitHander}
        >
          {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
            const isDisabled =
              values.deletionType === 0 && values.newCategoryId === null;
            return (
              <Form onSubmit={handleSubmit}>
                <Gap
                  flexDirection='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                >
                  <ParagraphNormal>
                    Are you sure you want to delete{' '}
                    <Description>“{selectedGuideCategory?.name}”</Description>{' '}
                    category? <br />
                    {!!selectedGuideCategory?.guidesCount && (
                      <>
                        Deleting this category will also delete all training
                        videos in it, unless <br /> they are moved to different
                        category. This action can’t be undone.
                      </>
                    )}
                  </ParagraphNormal>

                  {!!selectedGuideCategory?.guidesCount && (
                    <Field name='deletionType' readonly={isSubmitting}>
                      {({ field }: FieldProps) => {
                        const { value } = field;
                        return (
                          <>
                            <Gap
                              flexDirection='column'
                              alignItems='flex-start'
                              width='100%'
                            >
                              <RadioInput
                                text={'Move guides to'}
                                disabled={
                                  isDeletingOrMoving ||
                                  !selectedGuideCategory?.guidesCount
                                }
                                checkedColor={themes.colors.primary[100]}
                                checked={
                                  value == TYPE_OF_DELETION.DELETE_AND_MOVE
                                }
                                name='deletionType'
                                value={''}
                                onChange={() => {
                                  if (
                                    isDeletingOrMoving ||
                                    !selectedGuideCategory?.guidesCount
                                  ) {
                                    return;
                                  }
                                  setFieldValue(
                                    'deletionType',
                                    TYPE_OF_DELETION.DELETE_AND_MOVE
                                  );
                                }}
                                onClick={() => {
                                  if (
                                    isDeletingOrMoving ||
                                    !selectedGuideCategory?.guidesCount
                                  ) {
                                    return;
                                  }
                                  setFieldValue(
                                    'deletionType',
                                    TYPE_OF_DELETION.DELETE_AND_MOVE
                                  );
                                }}
                              />
                              <Dropdown
                                options={dropdownOptions}
                                value={
                                  dropdownOptions.find(
                                    item => item.value === values.newCategoryId
                                  ) || null
                                }
                                onChange={(item: IDropdown) =>
                                  setFieldValue('newCategoryId', item.value)
                                }
                                creatable={false}
                                extendStyles={{
                                  control: { minHeight: 42 },
                                  container: {
                                    marginLeft: 36,
                                    width: 'calc(100% - 36px)',
                                  },
                                }}
                                isSearchable={false}
                                placeholder='Select category'
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                menuPosition='absolute'
                                menuPlacement='bottom'
                                disabled={
                                  values.deletionType ===
                                    TYPE_OF_DELETION.DELETE_ALL ||
                                  isDeletingOrMoving
                                }
                              />
                            </Gap>
                            <RadioInput
                              text={'Delete all videos'}
                              checkedColor={themes.colors.primary[100]}
                              checked={value == TYPE_OF_DELETION.DELETE_ALL}
                              name='deletionType'
                              value={''}
                              onChange={() => {
                                if (isDeletingOrMoving) {
                                  return;
                                }
                                setFieldValue('newCategoryId', null);
                                setFieldValue(
                                  'deletionType',
                                  TYPE_OF_DELETION.DELETE_ALL
                                );
                              }}
                              onClick={() => {
                                if (isDeletingOrMoving) {
                                  return;
                                }
                                setFieldValue('newCategoryId', null);
                                setFieldValue(
                                  'deletionType',
                                  TYPE_OF_DELETION.DELETE_ALL
                                );
                              }}
                            />
                          </>
                        );
                      }}
                    </Field>
                  )}
                </Gap>
                <Gap
                  m='32px 0 0 0'
                  alignItems='center'
                  justifyContent='flex-end'
                  gap='12px'
                >
                  <Button
                    disabled={isDeletingOrMoving}
                    text='No, Keep'
                    variant='secondary'
                    onClick={async () => {
                      closeModalHandler();
                    }}
                  />
                  <Button
                    text={
                      values.deletionType === TYPE_OF_DELETION.DELETE_ALL
                        ? 'Yes, Delete'
                        : 'Move Training Video & Delete Category'
                    }
                    disabled={!!isDisabled || isDeletingOrMoving}
                    type='submit'
                    variant='primary'
                  />
                </Gap>
              </Form>
            );
          }}
        </Formik>
      )}
    </NewModal>
  );
};
