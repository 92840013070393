import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { VideoLimitStatus } from 'lib/utils/productFeature';

type VideoLimitContextType = {
  videoLimitStatus: VideoLimitStatus;
  setVideoLimitStatus: Dispatch<SetStateAction<VideoLimitStatus>>;
};

type Props = {
  children: ReactNode;
};

const VideoLimitContext = createContext<VideoLimitContextType | undefined>(
  undefined
);

export const VideoLimitProvider = ({ children }: Props) => {
  const [videoLimitStatus, setVideoLimitStatus] = useState(
    VideoLimitStatus.NOT_REACHED
  );

  return (
    <VideoLimitContext.Provider
      value={{ videoLimitStatus, setVideoLimitStatus }}
    >
      {children}
    </VideoLimitContext.Provider>
  );
};

export const useVideoLimit = (): VideoLimitContextType => {
  const context = useContext(VideoLimitContext);
  if (!context) {
    throw new Error('useVideoLimit must be used within a VideoLimitProvider');
  }
  return context;
};
