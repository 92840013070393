import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UpgradePackage } from './upgradePackage';

export const UpgradeRoutes = () => {
  return (
    <Switch>
      <Route
        path='/upgrade'
        component={() => <Redirect to={'/upgrade-plan'} />}
      />
      <Route path='/upgrade-plan' component={UpgradePackage} />
    </Switch>
  );
};
