import { useQuery } from 'react-query';
import { checkDropAuth, client } from './droplrAuth';
import { droplrKeys } from './queryKeys';

const getCurrentDroplrUser = async () => {
  await checkDropAuth();
  return client.users.current();
};

export const useGetCurrentDroplrUser = () => {
  return useQuery(droplrKeys.current_user(), () => getCurrentDroplrUser());
};
