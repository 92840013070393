import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { noSmsMsg } from 'app/pages/library/videos/videoList/components/SendAndShare';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useHistory } from 'react-router';
import { useAuth } from 'lib/context';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

const NoSms = styled.div`
  padding-top: 96px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: ${theme.palette.gray100};
`;

export const SmsDisabled = () => {
  const history = useHistory();
  const { whitelabel } = useAuth();

  // Redirect to /home for whitelabels
  React.useEffect(() => {
    if (whitelabel?.name !== WHITELABEL_NAME.COVIDEO) {
      history.push('/home');
    }
  }, [whitelabel]);
  return (
    <>
      <DocumentHead title='SMS' />
      <PageTemplate main={<NoSms>{noSmsMsg}</NoSms>} />
    </>
  );
};
