import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { CreateSingleContactParams, QueryContactsParams } from './types';
import { IContact } from './getSingleContact';
import { groupKeys } from '../group/groupKeys';
import { contactsKeys } from './queryKeys';

export const createContact = async (data: CreateSingleContactParams) => {
  const response = await EXPRESS_API.post('/contacts', data);
  return response.data;
};

export const useCreateContactMutation = (
  onCreateContactsSuccess: (contact?: IContact) => void,
  queryParams: QueryContactsParams
) => {
  const queryClient = useQueryClient();
  return useMutation((data: CreateSingleContactParams) => createContact(data), {
    onError: () => {
      errorToast({
        title: 'An error occurred creating contact, please try again later!',
      });
    },
    onSuccess: async (data: IContact) => {
      successToast({
        title: `You have successfully imported 1 new contact`,
      });

      await queryClient.invalidateQueries(contactsKeys.list(queryParams));
      await queryClient.invalidateQueries(groupKeys.groups());
      onCreateContactsSuccess(data);
    },
  });
};
