import React from 'react';
import { AddGuideModal } from './AddGuideModal';

export type SimplifiedVideo = {
  videoId: number;
  title: string;
};

type ReplaceGuideProps = {
  video: SimplifiedVideo;
  handleClose: () => void;
};

const ReplaceGuideModal = ({ video, handleClose }: ReplaceGuideProps) => {
  return (
    <AddGuideModal
      closeModalHandler={handleClose}
      video={video}
      isMultiple={false}
      hasConfirmation
    />
  );
};
export default ReplaceGuideModal;
