import { Container, MainWrapper } from 'lib/components/styles/layout';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';

export const Main = () => {
  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <Switch>
          <Route path='/reports/shared' component={List} exact={true} />
        </Switch>
      </MainWrapper>
    </Container>
  );
};
