import React from 'react';
import {
  ErrorMsg,
  FieldContainer,
  FieldLabel,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
  getInputField,
} from '../../../components/AdminFormComponents';
import { ErrorMessage, Field } from 'formik';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdAdd } from 'react-icons/md';
import { createDepartment, getDepartments } from 'lib/api';
import { Dropdown } from 'lib/components';
import { Option } from 'lib/components/dropdown/Dropdown';
import { useToastError } from 'lib/hooks';

const CircleAdd = styled.span`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 1.5;
  background: ${props => props.theme.colors.primary[100]};
  svg {
    padding-left: 2px;
  }
`;

export const JobDescriptionSection = (props: UserFormProps) => {
  const { isSubmitting, values, setFieldValue } = props;
  const [deptOption, setDeptOption] = React.useState<Option[]>([]);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const { showError } = useToastError();

  React.useEffect(() => {
    if (values.customerId <= 0) {
      return;
    }

    const deptCall = async () => {
      setProcessing(true);
      try {
        const dept = await getDepartments(values.customerId, {});
        const mapArr: Option[] = [];
        if (dept.data && dept.data.length) {
          dept.data.forEach((e: { Name: string; DepartmentID: number }) => {
            mapArr.push({ label: e.Name, value: e.DepartmentID });
          });
        }
        setDeptOption(mapArr);
        setProcessing(false);
      } catch (error) {
        setDeptOption([]);
        setProcessing(false);
      }
    };
    deptCall();
  }, [values.customerId]);

  const allotDept = async (obj: {
    label: string;
    value: string;
    __isNew__: boolean;
  }) => {
    if (obj.__isNew__) {
      setProcessing(true);
      try {
        const respDeptCreate = await createDepartment({
          name: obj.value,
          customerId: values.customerId,
        });
        setFieldValue('departmentId', respDeptCreate.data.id);
        const newArray: Option[] = deptOption;
        newArray.push({
          label: respDeptCreate.data.name,
          value: respDeptCreate.data.id,
        });
        setDeptOption(newArray);
        setProcessing(false);
      } catch (error) {
        showError(error);
        setProcessing(false);
      }
      setProcessing(false);
    } else {
      // set form field
      setFieldValue('departmentId', obj.value);
    }
  };

  return (
    <Section id={'jobDetailsSection'}>
      <SectionTitle>Job Position</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Title</FieldLabel>
          {getInputField('title', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>Department</FieldLabel>
          {!processing && (
            <Field name='departmentId' disabled={processing}>
              {() => {
                return (
                  <FieldContainer>
                    <Dropdown
                      options={deptOption}
                      value={deptOption.find(
                        o => o.value == values.departmentId
                      )}
                      onChange={e => allotDept(e)}
                      placeholder={'Add department...'}
                      creatable={true}
                      showIndicator={true}
                      formatCreateLabel={inputValue => (
                        <div
                          style={{
                            color: theme.palette.primaryColor,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CircleAdd>
                            <MdAdd color={theme.palette.white} />
                          </CircleAdd>{' '}
                          &nbsp;Create {`"${inputValue}"`}
                        </div>
                      )}
                    />

                    <ErrorMessage name='departmentId' component={ErrorMsg} />
                  </FieldContainer>
                );
              }}
            </Field>
          )}
        </Row>
      </RowWrapper>
    </Section>
  );
};
