import * as React from 'react';
import styled from 'styled-components/macro';

interface MessageProps {
  success: boolean;
  children?: string;
}

const Container = styled.div`
  display: block;
  color: #333333;
  line-height: 24px;
  width: 865px;
  margin-top: 7px;
  padding: 25px 0.5rem 0.5rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
`;

const MessageContainer = styled(Container)`
  padding: 22px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #f6f7f9;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 5px;
  width: 100%;
  height: 140px;
  margin-top: 3%;
`;

const MessageWrapper = styled.p<MessageProps>`
  color: ${props => (props.success === true ? '#4e5461' : 'red')};
  display: flex;
  flex-direction: column;
  flex: 8;
`;

export const JumboMessage = (props: MessageProps) => {
  let successMessage =
    'Thank you! Your application has been received. You will be contacted via email regarding approval status.';
  let errorMessage =
    'Something went wrong requesting your affiliation, please try again later. If this has happened before, please contact us through Intercom.';
  if (props.children) {
    errorMessage = props.children;
    successMessage = props.children;
  }
  return (
    <MessageContainer>
      <MessageWrapper {...props}>
        {(props.success && successMessage) || errorMessage}
      </MessageWrapper>
    </MessageContainer>
  );
};
