import VideoUpload, { View } from 'lib/components/videoUpload/VideoUpload';
import React, { useState, useEffect } from 'react';
import { useReplaceGuideMutation } from 'lib/api/guides/replaceGuideMutation';
import { useFormikContext } from 'formik';
import { FormikValue } from '../modals/AddGuideModal';
import { CreateVideoResponse } from 'lib/api/videos/useCreateVideoMutation';

type Props = {
  closeModal: () => void;
  setIsTabDisabled: (arg: boolean) => void;
  setChosenVideosCount?: (arg: number) => void;
  isMultiple?: boolean;

  videoId?: number;
  setIsConfirmModalOpen?: (arg: boolean) => void;
  proceedUpload?: boolean;
  uploadButtonText?: string;
};

export const UploadTabContent = ({
  closeModal,
  setIsTabDisabled,
  videoId,
  isMultiple = true,
  setIsConfirmModalOpen,
  setChosenVideosCount,
  proceedUpload,
  uploadButtonText,
}: Props) => {
  const [canceling, setCanceling] = useState(false);
  const [progress, setProgress] = useState(View.FORM);
  const { mutateAsync: replaceGuide } = useReplaceGuideMutation(videoId || 0);
  const { values } = useFormikContext<FormikValue>();

  useEffect(() => {
    setIsTabDisabled(progress !== View.FORM);
  }, [progress]);

  const onUploadSuccess = async (
    videoData: CreateVideoResponse | undefined
  ) => {
    if (!isMultiple && videoId && videoData) {
      await replaceGuide({
        videoId,
        newVideoId: Number(videoData.videoId),
      });
      closeModal();
    }
  };

  return (
    <VideoUpload
      handleModalClose={closeModal}
      canceling={canceling}
      setCanceling={setCanceling}
      setProgress={setProgress}
      progress={progress}
      hideHeader
      uploadButtonText={uploadButtonText}
      noButtonPadding
      showProgressHeaderInline
      isGuide
      dndWidth={580}
      dndHeight={365}
      setShowConfirmModal={setIsConfirmModalOpen}
      proceedUpload={proceedUpload}
      onSuccess={isMultiple ? undefined : onUploadSuccess}
      isMultiple={isMultiple}
      guideCategoryId={values?.guideCategoryId}
      setChosenVideosCount={setChosenVideosCount}
      contentStyle={{
        main: {
          padding: 0,
        },
        progress: {
          padding: '0 10px 20px 10px',
        },
        body: {
          maxHeight: '365px',
          minHeight: '365px',
        },
        cancelBody: {
          marginBottom: '20px',
          boxSizing: 'border-box',
          maxHeight: '345px',
          height: '345px',
        },
        footer: {
          paddingTop: '12px',
          gap: 12,
        },
      }}
    />
  );
};
