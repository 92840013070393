import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { FormikHelpers } from 'formik';
import { conversationsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface ILiveChatParams {
  message: string;
  conversationId: number;
}

interface IMutationData extends ILiveChatParams {
  formik: FormikHelpers<ILiveChatParams>;
}

//Update response to match ISingleMessage on BE
export interface IResponse {
  customerId: number;
  userId: number;
  text: string;
  conversationId: number;
  integrationType: string;
  emailSubject: string;
  messageId: number;
  createdAt: string;
}

const sendLiveChat = async (params: ILiveChatParams) => {
  const { message, conversationId } = params;

  const body = {
    message,
  };

  const { data } = await EXPRESS_API.post(
    `conversations/${conversationId}/chat/send`,
    body
  );

  return data as IResponse;
};

export const useSendLiveChatMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation((data: IMutationData) => sendLiveChat(data), {
    onSuccess: (_, { formik, conversationId }) => {
      successToast({ title: 'Your message is sent successfully!' });
      formik.resetForm();
      queryClient.invalidateQueries(
        conversationsKeys.detail(`${conversationId}`)
      );
      queryClient.invalidateQueries(conversationsKeys.lists());
    },
    onError: (err: AxiosError) => showError(err),
  });
};
