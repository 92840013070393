import { notificationKeys } from './queryKeys';
import { useToastError } from 'lib/hooks/useToastError';
import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';

export type GetNotificationParams = {
  notificationType: string;
  start: number;
  limit: number;
  search: string;
};

interface INotificationResponse {
  count: number;
  notifications: ISingleNotification[];
}

export interface ISingleNotification {
  notificationId: number;
  type: string;
  entityName: string;
  recipientId: number;
  senderName: string;
  actionId: string;
  actionUrl: string;
  isRead: boolean;
  isInteracted: boolean;
  meta: Record<any, any>;
  createdAt: string;
  approvalType?: number;
}

const getNotifications = async (
  params: GetNotificationParams
): Promise<INotificationResponse> => {
  return (
    await EXPRESS_API.get(`notification`, {
      params,
    })
  ).data as INotificationResponse;
};

export const useNotificationsQuery = (params: GetNotificationParams) => {
  const { showError } = useToastError();
  return useQuery(
    notificationKeys.detail(params.search, params.start),
    () => getNotifications(params),
    {
      onError: showError,
    }
  );
};
