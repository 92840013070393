import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import Select, { components, OptionProps } from 'react-select';
import {
  getCountries,
  getCountryCallingCode,
  isSupportedCountry,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import ReactCountryFlag from 'react-country-flag';

interface PhoneNumberInputProps {
  setExtension: (ext: string) => void;
  ext?: string;
}

const OptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  justify-content: space-between;
  align-items: center;
`;

const CountryCode = styled.div`
  width: 100%;
  overflow: hidden;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${theme.palette.covideoBlue100};
`;

const CountryName = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: ${theme.palette.covideoBlue40};
`;

const OptionIconWrapper = styled.div`
  display: flex;
  text-align: center;
`;

type CountryOption = {
  value: string;
  label: string;
};

const CustomOption = (props: OptionProps<CountryOption, false>) => {
  const { data } = props;
  const country = getCountries().find(country => country === data.value);
  if (!country) {
    return <></>;
  }

  return (
    <components.Option {...props}>
      <OptionWrapper>
        <OptionIconWrapper>
          <ReactCountryFlag
            countryCode={country}
            svg
            style={{
              width: '28px',
              height: '20px',
              borderRadius: '4px',
            }}
          />
        </OptionIconWrapper>
        <CountryCode>
          +
          {isSupportedCountry(country || 'US') &&
            getCountryCallingCode(country)}
        </CountryCode>
        <CountryName>{en[country]}</CountryName>
      </OptionWrapper>
    </components.Option>
  );
};

const CountryCodePicker = ({ ext, setExtension }: PhoneNumberInputProps) => {
  const themes = useTheme();

  const options = getCountries().map(country => {
    return {
      value: country,
      label: en[country],
    };
  });

  const customStyles = {
    option: (base: React.CSSProperties): React.CSSProperties => {
      return {
        ...base,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        color: themes.colors.primary[100],
        backgroundColor: themes.colors.secondary[5],
      };
    },
    menu: (base: React.CSSProperties) => ({
      ...base,
      width: '320px',
    }),
  };

  return (
    <Select
      optionHeight={48}
      maxMenuHeight={220}
      menuShouldScrollIntoView={false}
      isLoading={false}
      components={{
        IndicatorSeparator: () => null,
        Option: CustomOption,
      }}
      formatOptionLabel={option => {
        const country = getCountries().find(
          country => country === option.value
        );
        if (!country) {
          return;
        }

        return (
          <OptionWrapper>
            <OptionIconWrapper>
              <ReactCountryFlag
                countryCode={country}
                svg
                style={{
                  width: '28px',
                  height: '20px',
                  borderRadius: '4px',
                }}
              />
            </OptionIconWrapper>
            <CountryCode>
              +
              {isSupportedCountry(country || 'US') &&
                getCountryCallingCode(country)}
            </CountryCode>
          </OptionWrapper>
        );
      }}
      isSearchable={true}
      value={options.find(option => option.value === ext)}
      options={options}
      styles={customStyles}
      onChange={option => setExtension(option?.value || 'US')}
    />
  );
};

export default CountryCodePicker;
