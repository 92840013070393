import React from 'react';
import { CheckboxInput } from 'lib/components';
import styled from 'styled-components/macro';
import { ResellerUser } from 'lib/api/superadmin/useCustomersResellersQuery';
import { ParagraphNormal } from 'lib/components/styles/typography';
interface IProps {
  users: ResellerUser[];
  selectedUsers: string[];
  setSelectedUsersHandler: (userId: string, customerId: string) => void;
  customerId: string;
}

const UserItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom &:last-child {
    border-bottom: 1px solid #eeeff2;
  }
`;

export const UsersList = ({
  users,
  selectedUsers,
  setSelectedUsersHandler,
  customerId,
}: IProps) => {
  return (
    <>
      {users.map(user => (
        <UserItem key={user.id}>
          <CheckboxInput
            checked={selectedUsers?.includes(`${user.id}`)}
            onChange={() => setSelectedUsersHandler(`${user.id}`, customerId)}
          />

          <ParagraphNormal
            color='#272a32'
            onClick={() => setSelectedUsersHandler(`${user.id}`, customerId)}
          >
            {`${user.firstName} ${user.lastName}`}
          </ParagraphNormal>
        </UserItem>
      ))}
    </>
  );
};
