import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { signatureKeys } from './signatureKeys';

const createUpdateSignature = async (body: string) => {
  const response = await EXPRESS_API.post(`/signature`, { body });
  return response.data;
};

export const useCreateUpdateSignature = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation((data: string) => createUpdateSignature(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(signatureKeys.signature());
    },
    onError: (err: AxiosError, _, previousData) => {
      queryClient.setQueryData(signatureKeys.signature(), previousData);
      showError(err);
    },
  });
};
