import { moveVideo, VideoListItem } from 'lib/api';
import { putVideoAttributes } from 'lib/api/videoAttributes/useEditVideoAttributeByVideoId';
import { useGetAllVideoAttributesQuery } from 'lib/api/videoAttributes/useGetAllVideoAttributesQuery';
import { createVideoRequest } from 'lib/api/videoRequests/useCreateVideoRequestMutation';
import { deleteVideo } from 'lib/api/videos/useDeleteVideoMutation';
import { updateVideo } from 'lib/api/videos/useUpdateVideoMutation';
import { putVideoTags } from 'lib/api/videoTagsApi';
import { LoadingIndicator, Spinner } from 'lib/components';
import { successToast } from 'lib/components/toasts/success';
import { DefaultFolderIds, SHARED_FOLDER_PREFIX } from 'lib/const/Folders';
import { useAuth, VideoRequest } from 'lib/context';
import { theme } from 'lib/style';
import {
  checkIfVideoRequestFieldsRequired,
  validateAutomotiveFields,
} from 'lib/utils/automotiveRolePermissionChecks';
import _, { isEmpty } from 'lodash';
import * as React from 'react';
import { Button } from 'react-covideo-common';
import { MdSave } from 'react-icons/md';
import styled from 'styled-components/macro';
import { DetailedVideoPreview } from './DetailedVideoPreview';
import { Option } from 'lib/components/dropdown/Dropdown';

const Content = styled.div`
  margin-top: 64px;
  padding: 32px 112px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
`;

const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
  order: 4;
  ${theme.mediaQueryMinWidth.md} {
    width: 100%;
    order: 0;
    flex-direction: row;
    justify-content: center;
    height: 40px;
  }
  a {
    justify-content: center;
    margin: 0;
    height: 40px;
    &:nth-of-type(n + 2) {
      margin: 20px 0 0 0;
    }
    ${theme.mediaQueryMinWidth.xs} {
      width: 30vw;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 12px;
      }
    }
    ${theme.mediaQueryMinWidth.md} {
      width: 30%;
      max-width: 200px;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 24px;
      }
    }
    div {
      width: auto;
      &:nth-of-type(1) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 18px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 24px;
        }
      }
      &:nth-of-type(2) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 14px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 16px;
        }
      }
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  width: auto;
  ${theme.fontBold800}
  font-size: 24px;
  line-height: 1.82;
  letter-spacing: normal;
  color: ${theme.palette.title};
  line-height: 1.82;
  margin-right: auto;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  .blur {
    min-height: 40px;
    line-height: 1.82;
    max-width: 360px;
    overflow: hidden;
    text-align: left !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .focus {
    min-height: 40px;
    line-height: 1.82;
    border: 1px solid ${theme.palette.secondaryBlue};
    border-radius: 4px;
    max-width: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  ${theme.mediaQueryMinWidth.md} {
    margin-top: 0;
  }
  width: auto;
`;

const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin-top: 0;
`;

const CountGroup = styled.div`
  display: flex;
  width: 425px;
  justify-content: space-evenly;
`;
const CountLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.primary[100]};
`;

interface Props {
  videoArray: VideoListItem[];
  isLoading: boolean;
  setIsSaved: (value: boolean) => void;
  setTargetFolderId: (value: number) => void;
}

export const ReviewList = ({
  videoArray,
  isLoading,
  setIsSaved,
  setTargetFolderId,
}: Props) => {
  const [validFormsCount, setValidFormsCount] = React.useState(0);
  // left as any because Formik values depend on video attributes
  const inputEl = React.useRef<{ [key: string]: any }>({});
  const [tagsObject, setTagsObject] = React.useState<{
    [key: string]: object[];
  }>({});
  const [automotiveValidation, setAutomotiveValidation] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [videoRequestData, setVideoRequestData] = React.useState<{
    [key: string]: Partial<VideoRequest>;
  }>({});
  const [deleteVideoTracker, setDeletedVideoTracker] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [isSaving, setIsSaving] = React.useState(false);
  const [totalRequiredAttributeCount, setTotalRequiredAttributeCount] =
    React.useState(0);
  const { data, isLoading: areAttributesLoading } =
    useGetAllVideoAttributesQuery({
      page: 0,
      size: 200, // attempt to fetch all on the first render
      filterByDepartment: true,
    });

  const { attributes: attributesList } = data || {
    attributes: [],
    count: 0,
  };

  const { userData } = useAuth();
  const { isAutomotive, isAutomotiveSalesRole, isAutomotiveServiceRole } =
    userData;
  // set required attribute number tracker
  const videoRequestFieldsRequired =
    checkIfVideoRequestFieldsRequired(userData);

  React.useEffect(() => {
    if (!attributesList || !videoArray.length) {
      return;
    }
    // set number of required attributes
    const totalVideoCount = videoArray.length;
    const deletedVideoCount = Object.keys(deleteVideoTracker).length;
    const finalVideoCount = Math.abs(totalVideoCount - deletedVideoCount);

    let requiredAttributesCount = attributesList.filter(
      attribute => attribute.isRequired
    ).length;

    if (isAutomotiveSalesRole && videoRequestFieldsRequired)
      requiredAttributesCount += 1;
    if (isAutomotiveServiceRole && videoRequestFieldsRequired)
      requiredAttributesCount += 2;
    setTotalRequiredAttributeCount(finalVideoCount * requiredAttributesCount);
  }, [attributesList, deleteVideoTracker, videoArray]);

  React.useEffect(() => {
    if (!videoArray || !videoArray.length) {
      return;
    }

    let validation: { [key: string]: boolean } = {};
    videoArray.forEach((video: VideoListItem) => {
      if (isAutomotive && !deleteVideoTracker[video.id]) {
        validation[video.id] = validateAutomotiveFields(
          userData,
          videoRequestData[video.id] || {}
        );
      }
    });
    setAutomotiveValidation(validation);
  }, [videoArray, deleteVideoTracker]);

  React.useEffect(() => {
    if (!videoArray.length) {
      return;
    }

    const formRefsObject = inputEl.current;
    const actions = [];
    if (formRefsObject && Object.keys(formRefsObject).length) {
      for (const key in formRefsObject) {
        if (formRefsObject[key] && !deleteVideoTracker[key]) {
          actions.push(formRefsObject[key].validateForm);
        }
      }

      Promise.all(actions.map(async action => await action())).then(results => {
        let validCount = 0;
        results.forEach(result => {
          if (isEmpty(result)) {
            ++validCount;
          }
        });
        setValidFormsCount(validCount);
      });
    }
  }, [videoArray, automotiveValidation, attributesList, deleteVideoTracker]);

  React.useEffect(() => {
    if (areAttributesLoading || isLoading) {
      return;
    }

    if (videoArray.length === Object.keys(deleteVideoTracker).length) {
      setIsSaved(true);
    }
  }, [areAttributesLoading, isLoading, deleteVideoTracker, videoArray]);

  const onAttributeChangeHandler = async () => {
    const formRefsObject = inputEl.current;

    if (formRefsObject && Object.keys(formRefsObject).length) {
      let validCount = 0;
      for (const key in formRefsObject) {
        let errors = {};
        try {
          if (formRefsObject[key]) {
            errors = await formRefsObject[key].validateForm();
          }
        } catch (ex) {}

        if (isEmpty(errors)) {
          ++validCount;
        }

        if (
          validCount >
          videoArray.length - Object.keys(deleteVideoTracker).length
        ) {
          validCount =
            videoArray.length - Object.keys(deleteVideoTracker).length;
        }

        setValidFormsCount(validCount);
      }
    }
  };

  const tagsUpdateHandler = (id: string, videoTags: object[]) => {
    const newTagsObject = { ...tagsObject };
    newTagsObject[id] = videoTags;
    setTagsObject(newTagsObject);
  };

  const save = async () => {
    if (
      validFormsCount !==
      Math.abs(videoArray.length - Object.keys(deleteVideoTracker).length)
    ) {
      return;
    }

    setIsSaving(true);
    const formRefsObject = inputEl.current;
    if (formRefsObject && Object.keys(formRefsObject).length) {
      for (const key in formRefsObject) {
        if (formRefsObject[key]) {
          const attributeValues = formRefsObject[key].values;
          await saveHandler(key, attributeValues, tagsObject[key] || []).catch(
            () => {}
          );
          const targetFolder = attributeValues['folder'];
          if (parseInt(targetFolder.value) !== DefaultFolderIds.PRIMARY) {
            await moveVideo(key, {
              videoFolder: !!targetFolder.access
                ? `${SHARED_FOLDER_PREFIX}${targetFolder.value}`
                : targetFolder.label,
              copy: false,
            });
            setTargetFolderId(targetFolder.value);
          }
        }
      }
    }

    setIsSaving(false);
    setIsSaved(true);
  };

  const onDeleteHandler = async (videoId: string) => {
    const newDeletedVideoTracker = { ...deleteVideoTracker };
    newDeletedVideoTracker[videoId] = true;
    setDeletedVideoTracker(newDeletedVideoTracker);

    const response = await deleteVideo({
      videoIds: [videoId],
      deleteMultiple: false,
    });

    if (response && !!response.deleted) {
      successToast({ title: 'Video deleted successfully.' });
    }
  };

  const saveHandler = async (
    videoId: string,
    attributeValues: { [key: string]: string | number | Option },
    videoTags: object[]
  ) => {
    await Promise.all([
      await updateVideoTags(videoId, videoTags),
      await updateVideoTitle(
        videoId,
        attributeValues['covideoName'].toString()
      ),
      await updateVideoAttributes(videoId, attributeValues),
    ]);

    if (isAutomotive) {
      // do not call mutation to avoid rerender
      await createVideoRequest({
        data: {
          videoId,
          ...videoRequestData[videoId],
        },
      });
    }
  };

  const updateVideoTitle = async (videoId: string, newTitle: string) => {
    if (!newTitle) {
      return;
    }

    await updateVideo({
      videoId,
      videoBody: { title: newTitle },
    }).catch(() => {});
  };

  const updateVideoTags = async (videoId: string, videoTags: object[]) => {
    if (!videoTags || !videoTags.length) {
      return;
    }

    await putVideoTags(videoId, videoTags).catch(() => {});
  };

  const updateVideoAttributes = async (
    videoId: string,
    attributeValues: { [key: string]: string | number | Option }
  ) => {
    if (!attributeValues || _.isEmpty(attributeValues)) {
      return;
    }

    let attributesToKeep = { ...attributeValues };

    // remove values that are not video attributes
    if (!!attributesToKeep['covideoName']) {
      delete attributesToKeep['covideoName'];
    }
    if (!!attributesToKeep['folder']) {
      delete attributesToKeep['folder'];
    }

    const attributes = Object.keys(attributesToKeep).map((key: string) => {
      const splitValues = key.split('-');
      const attributeId = splitValues[1];
      return {
        attributeId,
        value: attributesToKeep[key],
        videoId: parseInt(videoId),
      };
    });
    await putVideoAttributes({
      videoId: videoId,
      data: attributes,
    }).catch(err => err);
  };

  if (areAttributesLoading || isLoading) {
    return <LoadingIndicator isLoading={isLoading} text={'Loading...'} />;
  }
  if (
    !videoArray.length ||
    videoArray.length === Object.keys(deleteVideoTracker).length
  ) {
    return (
      <Container style={{ height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            marginTop: '250px',
            textAlign: 'center',
          }}
        >
          No videos for review.
        </div>
      </Container>
    );
  }

  let canSubmitAutomotiveVideo = true;
  Object.keys(automotiveValidation).forEach(videoId => {
    if (!automotiveValidation[videoId]) {
      canSubmitAutomotiveVideo = false;
    }
  });

  return (
    <Content>
      <Container>
        <ButtonsBox>
          <TitleWrapper>Review uploaded videos</TitleWrapper>
          <CountGroup>
            <CountLabel>
              <b>{validFormsCount}</b>/
              {Math.abs(
                videoArray.length - Object.keys(deleteVideoTracker).length
              )}{' '}
              <span style={{ color: '#272A32' }}>videos are done</span>
            </CountLabel>
            <CountLabel>
              <b>{totalRequiredAttributeCount}</b>{' '}
              <span style={{ color: '#272A32' }}>required attributes</span>
            </CountLabel>
          </CountGroup>
          <ButtonsWrapper>
            <Button
              disabled={
                validFormsCount !==
                  Math.abs(
                    videoArray.length - Object.keys(deleteVideoTracker).length
                  ) ||
                (!canSubmitAutomotiveVideo &&
                  totalRequiredAttributeCount !== 0) ||
                isSaving
              }
              icon={isSaving ? <Spinner size={8} /> : <MdSave size={18} />}
              text={isSaving ? 'Saving...' : 'Save Recording'}
              onClick={() => save()}
            />
          </ButtonsWrapper>
        </ButtonsBox>
        <List>
          {videoArray.map(video => {
            if (deleteVideoTracker[video.id]) {
              return <React.Fragment key={video.id} />;
            }

            return (
              <li key={video.id}>
                <DetailedVideoPreview
                  formId={video.id}
                  title={''}
                  isReviewCase={true}
                  onTagsUpdate={tagsUpdateHandler}
                  videoSource={video.videoSource}
                  handleUpload={() => {}}
                  videoTitle={video.title}
                  handleAttributeChange={onAttributeChangeHandler}
                  onChangeVideoRequestData={data => {
                    if (isAutomotive) {
                      const isValid = validateAutomotiveFields(userData, data);
                      const validation = {
                        ...automotiveValidation,
                        [video.id]: isValid,
                      };
                      setAutomotiveValidation(validation);
                      videoRequestData[video.id] = data;
                      setVideoRequestData(videoRequestData);
                    }
                  }}
                  videoRequestData={videoRequestData[video.id] || {}}
                  formRef={inputEl}
                  onDelete={onDeleteHandler}
                  showFoldersDropdown={true}
                />
              </li>
            );
          })}
        </List>
      </Container>
    </Content>
  );
};
