import * as React from 'react';
import styled from 'styled-components/macro';
import { VideoChapterItemExtended } from 'lib/api/types';
import { theme } from '../../../../../lib/style';

type ProgressProps = {
  value?: string;
  min?: string;
  max?: string;
  color?: string;
  small?: boolean;
  videoChaptersData?: VideoChapterItemExtended[];
};
type ProgressWrapperProps = {
  small?: boolean;
};
type VideoChapterProgressProps = {
  value?: string;
  min?: string;
  max?: string;
  color?: string;
  small?: boolean;
  title?: string;
};
type VideoChapterProgressItemWrapperProps = {
  small?: boolean;
  width?: string;
};
type VideoChapterBubbleProps = {
  position: number;
};

const ProgressWrapper = styled.div<ProgressWrapperProps>`
  width: 100%;
  display: flex;
  cursor: pointer;
  flex: 1;
  padding-bottom: ${props => `${props.small ? '0px' : ' 0px 12px'}`};
`;

const Progress = styled.progress<ProgressProps>`
  width: 100%;
  color: white;
  opacity: 1;
  height: ${props =>
    `${props.small ? '4px' : '4px'}`}; /* TODO: it's 4px in design */
  border-radius: ${props => `${props.small ? '1px' : '4px'}`};
  border: 1px solid transparent;
  box-sizing: border-box;

  /* TODO - come back to this */
  &::-webkit-progress-bar {
    background-color: ${props =>
      props.color === '#FFFFFF' && 'rgba(255,255,255, .25)'};
    background-color: ${props =>
      props.color === '#000000' && 'rgba(0,0,0, .25)'};
    border-radius: 4px;
  }
  &::-webkit-progress-value {
    background-color: ${props =>
      props.color === '#FFFFFF' && 'rgba(255,255,255, .75)'};
    background-color: ${props =>
      props.color === '#000000' && 'rgba(0,0,0, .75)'};

    border-radius: 4px;
  }
`;
const VideoChapterProgressListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const VideoChapterProgressItemWrapper = styled.div<
  VideoChapterProgressItemWrapperProps
>`
  display: flex;
  align-items: center;
  width: ${props => props.width};
  height: ${props =>
    `${props.small ? '4px' : '4px'}`}; /* TODO: it's 4px in design */
`;

const VideoChapterProgress = styled.progress<VideoChapterProgressProps>`
  width: 100%;
  height: 100%;
  color: ${theme.palette.white};
  opacity: 1;
  border-radius: ${props => `${props.small ? '1px' : '4px'}`};
  border: 1px solid transparent;
  box-sizing: border-box;

  &::-webkit-progress-bar {
    background-color: ${props =>
      props.color === '#FFFFFF' && 'rgba(255,255,255, .25)'};
    background-color: ${props =>
      props.color === '#000000' && 'rgba(0,0,0, .25)'};
    border-radius: 4px;
  }
  &::-webkit-progress-value {
    background-color: ${props =>
      props.color === '#FFFFFF' && 'rgba(255,255,255, .75)'};
    background-color: ${props =>
      props.color === '#000000' && 'rgba(0,0,0, .75)'};

    border-radius: 4px;
  }
  &:hover {
    height: ${props => `${props.small ? '6px' : '6px'}`};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    &:hover {
      height: ${props => `${props.small ? '4px' : '4px'}`};
    }
  }
`;

const VideoChapterBubble = styled.div<VideoChapterBubbleProps>`
  ${({ position }) => {
    if (position < 10) {
      return {
        left: '16px',
      };
    }
    if (position > 84) {
      return {
        right: '16px',
      };
    } else {
      return {
        transform: 'translateX(-50%)',
        left: `${position}%`,
      };
    }
  }};
  display: none;
  box-sizing: border-box;
  position: absolute;
  bottom: 50px;
  width: auto;
  min-width: 50px;
  max-height: 50px;
  overflow-x: ellipsis;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 6px 12px rgba(66, 79, 104, 0.06);
  border-radius: 6px;
  padding: 4px 12px 6px;
  flex-direction: column;
  gap: 4px;
  z-index: 999;
  ${VideoChapterProgressItemWrapper}:hover & {
    display: flex;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    ${VideoChapterProgressItemWrapper}:hover & {
      display: none;
    }
  }
`;

const BubbleItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BubbleTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  max-width: 250px;
  color: ${theme.palette.gray100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

const BubbleTime = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  max-width: 250px;
  color: ${theme.palette.gray100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  duration?: number;
  currentTime?: number;
  scrub: (
    e: any,
    progressRef: React.RefObject<HTMLProgressElement>,
    offset?: number,
    duration?: number
  ) => void;
  color?: string;
  small?: boolean;
  videoChaptersData?: VideoChapterItemExtended[];
};

export const ProgressBar = (props: Props) => {
  const {
    duration = 0.1,
    currentTime = 0,
    scrub,
    color,
    small,
    videoChaptersData,
  } = props;
  const progressRef = React.createRef<HTMLProgressElement>();

  return (
    <ProgressWrapper small={small}>
      {videoChaptersData && videoChaptersData.length > 0 ? (
        <VideoChapterProgressListContainer>
          {videoChaptersData.map(
            (videoChapter: VideoChapterItemExtended, i: number) => (
              <VideoChapterProgressItemWrapper
                key={i}
                width={
                  ((videoChapter.duration / duration) * 100).toString() + '%'
                }
                small={small}
              >
                <VideoChapterProgress
                  ref={videoChapter.ref}
                  onClick={event =>
                    scrub(
                      event,
                      videoChapter.ref,
                      videoChapter.playbackPosition,
                      videoChapter.duration
                    )
                  }
                  value={
                    currentTime < videoChapter.playbackPosition
                      ? '0'
                      : (currentTime - videoChapter.playbackPosition).toString()
                  }
                  min="0"
                  max={videoChapter.duration.toString()}
                  color={color}
                  small={small}
                  title={videoChapter.title}
                ></VideoChapterProgress>
                <VideoChapterBubble position={videoChapter.bubblePosition}>
                  <BubbleItem>
                    <BubbleTitle>{videoChapter.title}</BubbleTitle>
                    <BubbleTime>{videoChapter.playbackPositionText}</BubbleTime>
                  </BubbleItem>
                </VideoChapterBubble>
              </VideoChapterProgressItemWrapper>
            )
          )}
        </VideoChapterProgressListContainer>
      ) : (
        <Progress
          ref={progressRef}
          onClick={event => scrub(event, progressRef)}
          value={currentTime.toString()}
          min="0"
          max={duration.toString()}
          color={color}
          small={small}
        />
      )}
    </ProgressWrapper>
  );
};
