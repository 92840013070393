import * as React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Button } from 'react-covideo-common';
import { AvailableFields } from './AvailableFields';
import { SelectedFields } from './SelectedFields';
import { SelectField, SelectFieldsProps } from './types';

export const SelectFields = ({
  onRemoveClick,
  onAddClick,
  onDragItem,
  allFields,
  initialFields,
  maxFieldConfig = { count: 100, hideText: true },
  scrollableHeight,
  actionButtons,
}: SelectFieldsProps) => {
  const [selectedFields, setSelectedFields] = React.useState<SelectField[]>(
    initialFields || []
  );

  const onDragEnd = (items: SelectField[]) => {
    onDragItem?.(items);
    setSelectedFields(items);
  };

  const onAddClickHandler = (field: SelectField) => {
    onAddClick?.([...selectedFields, field]);
    setSelectedFields([...selectedFields, field]);
  };

  const onRemoveClickHandler = (field: SelectField) => {
    const removedItems = selectedFields.filter(
      item => item.value !== field.value
    );
    setSelectedFields(removedItems);
    onRemoveClick?.(removedItems);
  };

  const isMaxFieldReached =
    maxFieldConfig && selectedFields.length >= maxFieldConfig.count;

  return (
    <Gap
      alignItems='flex-start'
      style={{ flexFlow: 'initial', width: '100%', flexDirection: 'column' }}
    >
      <Gap
        flexDirection='column'
        alignItems='flex-start'
        style={{ flexFlow: 'initial', width: '100%' }}
      >
        <AvailableFields
          onAddClickHandler={onAddClickHandler}
          isMaxFieldReached={isMaxFieldReached}
          scrollableHeight={scrollableHeight}
          allFields={allFields}
          selectedFields={selectedFields}
        />
        <SelectedFields
          scrollableHeight={scrollableHeight}
          selectedFields={selectedFields}
          onRemoveClickHandler={onRemoveClickHandler}
          onDragEnd={onDragEnd}
          maxFieldConfig={maxFieldConfig}
        />
      </Gap>

      {actionButtons?.length && (
        <Gap
          style={{ justifyContent: 'flex-end', width: '100%', marginTop: 16 }}
        >
          {actionButtons.map(buttonProps => (
            <Button
              {...buttonProps}
              onClick={() => buttonProps.onClick(selectedFields)}
            />
          ))}
        </Gap>
      )}
    </Gap>
  );
};
