import React from 'react';
import { useState } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { updateMultipleUsers } from 'lib/api';
import { Dropdown } from 'lib/components';
import { SuperAdminUser } from 'lib/context';
import { VerificationStatusOptions } from 'lib/const/VerificationsStatusOptions';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';

const filteredStatusOptions = VerificationStatusOptions.filter(
  option => option.value !== VerificationStatus.ALL
);

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedUsers: SuperAdminUser[];
};

type OptionType = {
  value: string;
  label: string;
};

export const ChangeUserStatusModal = ({
  handleModalClose,
  selectedUsers,
}: Props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = useState(2);

  const headerText = `Change Status for ${selectedUsers.length} user${
    selectedUsers.length > 1 ? 's' : ''
  }`;

  const handleUpdateStatus = async () => {
    setLoadingModal(true);
    const count = selectedUsers.length;
    let errorMessage = '';
    try {
      const userIds = selectedUsers.map(user => user.id);
      await updateMultipleUsers({
        userIds,
        status: selectedStatus,
      });
    } catch (error) {
      errorMessage = `Error in updating user status.`;
      console.log('Error', error);
    }
    handleModalClose(1);

    setLoadingModal(false);
    if (errorMessage) {
      errorToast({ title: errorMessage });
    } else {
      count &&
        successToast({
          title: `Status updated successfully for ${count} user${
            count > 1 ? 's' : ''
          }!`,
        });
    }
    setLoadingModal(false);
  };

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose()}
      headerMargin='0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Header>
        <Content>You are about to {headerText?.toLowerCase()}</Content>
      </Header>
      <Content>
        <Dropdown
          menuPosition='fixed'
          options={filteredStatusOptions}
          value={filteredStatusOptions.find(option => {
            return option && option.value === selectedStatus;
          })}
          onChange={(option: OptionType) =>
            setSelectedStatus(parseInt(option.value, 10))
          }
          menuPlacement='bottom'
          menuPortalTarget={document.body}
          placeholder={'Select New Status'}
        />
        <SubmitButtonWrapper>
          <Button
            text={'Change Status'}
            onClick={() => handleUpdateStatus()}
            disabled={
              !(
                selectedStatus === VerificationStatus.ACTIVE ||
                selectedStatus === VerificationStatus.INACTIVE
              ) || loadingModal
            }
          />
        </SubmitButtonWrapper>
      </Content>
    </NewModal>
  );
};
