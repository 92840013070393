import { useMutation } from 'react-query';
import { checkDropAuth, client } from './droplrAuth';
import { DownloadDropBody } from './types';
import { blockToast } from 'lib/components/toasts/block';
import { AxiosError } from 'axios';

const downloadDrop = async (body: DownloadDropBody): Promise<void> => {
  try {
    await checkDropAuth();
    const drop = await client.drops.get(
      body.dropId,
      {},
      {
        params: {
          'content-disposition': `attachment`,
        },
      }
    );
    window.open(drop.content, '_blank');
  } catch (error) {
    throw new Error('Download permission is denied for file: ' + body.fileName);
  }
};

export const useDownloadDrop = () => {
  return useMutation((body: DownloadDropBody) => downloadDrop(body), {
    onError: (err: AxiosError) => {
      blockToast({
        title: err.message,
      });
    },
  });
};
