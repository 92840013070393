import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';
import { Button } from 'react-covideo-common';
import Select, { components } from 'react-select';
import { useEffect, useState } from 'react';
import { AutomotiveRole } from 'lib/const/AutomotiveRole';
import { getUsersByAutomotiveRoles } from 'lib/api';
import { useAuth } from 'lib/context';
import { keyBy } from 'lodash';

const Content = styled.div`
  box-sizing: border-box;
  width: 592px;
  background-color: #fff;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
const FilterWrapper = styled.div`
  margin-bottom: 12px;
`;
const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  color: ${theme.palette.gray80};
`;

const OptionWrapper = styled.div`
  position: relative;
`;

const Name = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
  position: relative;
  z-index: 3;
`;

const Email = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  position: absolute;
  z-index: 2;
  right: 36px;
  top: 50%;
  transform: translateY(-50%);
  color: ${theme.palette.gray60};
`;

const selectStyle = {
  option: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
    color: theme.palette.gray100,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
    ':focus': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
  }),
  control: (base: any) => ({ ...base, height: '40px' }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 999,
  }),
};

type Option = {
  value: string | number;
  label: string | number;
};
type Props = {
  title: string;
  assignTo: any[];
  handleModalClose: () => void;
  handleSubmit: (advisorId: string, ids: string[], advisor: any) => void;
};
export const ModalReassign = ({
  title,
  assignTo,
  handleModalClose,
  handleSubmit,
}: Props) => {
  const { userData } = useAuth();
  const { customer } = userData;

  const [advisorId, setAdvisorId] = useState('');
  const [advisorOptions, setAdvisorOptions] = useState<Option[]>([]);
  const [userDictionary, setUserDictionary] = useState(
    {} as { [key: string]: any }
  );
  const getAssignees = async () => {
    const advisors = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_ADVISOR,
    });

    const managers = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_MANAGER,
    });

    const users = [...advisors, ...managers];

    setUserDictionary(keyBy(users, 'id'));

    const options = users.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    setAdvisorOptions(options);
  };

  useEffect(() => {
    getAssignees();
  }, []);

  const onSubmit = () => {
    handleSubmit(advisorId, assignTo, userDictionary[advisorId]);
    handleModalClose();
  };

  const Option = (props: any) => {
    return (
      <OptionWrapper>
        <Name>
          <components.Option {...props} />
        </Name>
        <Email>
          {userDictionary[props.value] ? userDictionary[props.value].email : ''}
        </Email>
      </OptionWrapper>
    );
  };

  return (
    <NewModal
      headerText={title}
      headerTextStyle={{
        fontSize: theme.fontSizes.lg,
        lineHeight: '24px',
        fontWeight: 'bold',
        color: theme.palette.coal,
      }}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      style={{
        content: {
          padding: '32px',
          minWidth: 'auto',
        },
      }}
    >
      <Content>
        <Label>Select another advisor</Label>
        <FilterWrapper>
          <Select
            styles={selectStyle}
            menuPortalTarget={document.body}
            menuPosition='fixed'
            menuPlacement={'bottom'}
            options={advisorOptions}
            value={advisorOptions.find(o => {
              return o.value === advisorId;
            })}
            onChange={(option: any) => setAdvisorId(option.value)}
            isSearchable={true}
            placeholder={'Search advisors...'}
            components={{ Option }}
          />
        </FilterWrapper>
      </Content>
      <ButtonsWrapper>
        <Button
          text={`Assign Advisor`}
          variant='primary'
          onClick={() => onSubmit()}
          disabled={!advisorId}
        />
      </ButtonsWrapper>
    </NewModal>
  );
};
