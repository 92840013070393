import React, { SVGProps } from 'react';
import { IconName, svgIconsConfig } from 'assets/icons';

interface CovideoIconProps extends SVGProps<SVGSVGElement> {
  name: IconName;
}

export const CovideoIcon: React.FC<CovideoIconProps> = ({
  name,
  ...svgProps
}) => {
  const IconComponent = svgIconsConfig[name].component;

  return <IconComponent {...svgProps} />;
};
