import React, { forwardRef } from 'react';
import {
  Actions,
  HeaderCenter,
  HeaderSide,
  LinkBtn,
  LinkWrapper,
  ModalHeader,
} from 'app/pages/library/videos/videoList/components/SendAndShare';
import { MdClose, MdSave } from 'react-icons/md';
import { ModalTitle } from '../styles';
import { ButtonPillSwitch } from 'lib/components';
import { theme } from 'lib/style';
import { MeetingResponse, MEETING_STATUSES } from 'lib/api/meetings/types';

import { MEETING_TABS } from '../constants';
import { useStartNowMeetingMutation } from 'lib/api/meetings/useStartNowMeetingMutation';
import { useAuth } from 'lib/context';
import { ModalMeetingsDetails } from '../pages/MeetingDetails';
import { useIsMutating } from 'react-query';
import { Button } from 'react-covideo-common';
import { FaRegCalendarXmark } from 'react-icons/fa6';
import { IoMdSend } from 'react-icons/io';

interface IProps {
  setShowDetailsModals: React.Dispatch<
    React.SetStateAction<ModalMeetingsDetails>
  >;
  handleCloseFullScreenModal: () => void;
  meetingDetails: MeetingResponse | undefined;
  meetingId: string | null;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  activeTab: string;
  isRecurring?: boolean;
}

export const MeetingsDetailsHeader = forwardRef((props: IProps, ref: any) => {
  const {
    handleCloseFullScreenModal,
    meetingId,
    meetingDetails,
    setShowDetailsModals,
    setActiveTab,
    activeTab,
    isRecurring,
  } = props;
  const { userData } = useAuth();

  const { mutateAsync: startNowAsync } = useStartNowMeetingMutation();

  const onStartNowClick = async () => {
    startNowAsync({
      meetingId,
      userId: Number(userData.id),
    });
  };

  const isCanceled =
    meetingDetails?.meetingStatus === MEETING_STATUSES.CANCELED;
  const isScheduled =
    meetingDetails?.meetingStatus === MEETING_STATUSES.SCHEDULED;
  const isFinished =
    meetingDetails?.meetingStatus === MEETING_STATUSES.FINISHED;

  const showSendInviteButton = !meetingId;
  const showActionButtons = (isScheduled || isRecurring) && meetingId;
  const showDeleteButton = isFinished || isCanceled;
  const showButtonPillSwitch = !!meetingId;

  const isSubmitting = useIsMutating();

  const headerText = isRecurring
    ? 'Create Recurring Meeting'
    : 'Schedule Meeting';

  return (
    <ModalHeader>
      <HeaderSide>
        <LinkWrapper>
          <LinkBtn
            onClick={() => {
              handleCloseFullScreenModal();
            }}
          >
            <MdClose size='18' />
            <span>Close</span>
          </LinkBtn>
        </LinkWrapper>
      </HeaderSide>
      <HeaderCenter>
        <ModalTitle>
          {showButtonPillSwitch ? (
            <ButtonPillSwitch
              defaultValue={activeTab}
              values={MEETING_TABS}
              onChange={newTab => setActiveTab(newTab)}
            />
          ) : (
            headerText
          )}
        </ModalTitle>
      </HeaderCenter>
      <HeaderSide>
        <Actions>
          <>
            {showDeleteButton && (
              <Button
                text='Delete'
                onClick={() => setShowDetailsModals('delete')}
                disabled={!!isSubmitting}
                variant='destructive'
              />
            )}
            {showActionButtons && (
              <>
                <Button
                  onClick={() => setShowDetailsModals('cancel')}
                  icon={
                    <FaRegCalendarXmark
                      color={theme.palette.red100}
                      size={20}
                    />
                  }
                  disabled={!!isSubmitting}
                  variant='ghost'
                />
                <Button
                  text='Start now'
                  onClick={onStartNowClick}
                  disabled={!!isSubmitting}
                  variant='secondary'
                />
                <Button
                  onClick={() => {
                    if (!!ref.current.values?.attendees?.length) {
                      setShowDetailsModals('send-notifications');
                      return;
                    }
                    ref.current.submitForm();
                  }}
                  text={'Save changes'}
                  disabled={!!isSubmitting}
                  variant='primary'
                  icon={<MdSave color={theme.palette.white} />}
                />
              </>
            )}
            {showSendInviteButton && (
              <Button
                icon={<IoMdSend size={18} />}
                disabled={!!isSubmitting}
                text='Send invite'
                onClick={() => ref.current.submitForm()}
                variant='primary'
              />
            )}
          </>
        </Actions>
      </HeaderSide>
    </ModalHeader>
  );
});
