import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';
import { SnippetItem } from './types';

export const deleteSnippet = async ({ data }: { data: SnippetItem }) => {
  const response = await EXPRESS_API.delete(`/snippets/${data.snippetId}`, {
    data,
  });
  return response.data;
};

export const useDeleteSnippetMutation = () => {
  const { showError } = useToastError();
  return useMutation(deleteSnippet, {
    onError: err => showError(err),
    onSuccess: async () => {
      successToast({ title: 'You have successfully deleted template!' });
    },
  });
};
