import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdDeleteForever, MdEmail, MdFileDownload } from 'react-icons/md';
import { useAuth } from 'lib/context';

import { LoadingIndicator, Tabs } from 'lib/components';
import { Tab } from 'lib/components/tabs';
import { downloadCustomReportData } from 'lib/api';
import { MenuDropdown } from 'lib/components/page/MenuDropdown';
import { ModalDelete } from 'lib/components/modal';
import { SendReportModal, timeRangeConstants } from '../../components';
import { ReportTab } from './ReportTab';
import { HistoryTab } from './HistoryTab';
import { NotFound } from 'app/pages/notFound/NotFound';
import { MainContainer } from '../index.styled';
import { Heading } from 'lib/components/styles/typography';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { Button } from 'react-covideo-common';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { getDisplayName } from 'lib/utils/functions';
import { CustomReportButton } from '../CustomReportButton';
import { useCustomReportQuery } from 'lib/api/customReports/useCustomReportQuery';
import { useDeleteCustomReport } from 'lib/api/customReports/deleteCustomReport';
import { errorToast } from 'lib/components/toasts/error';
import { formatCustomReportDate } from './const';

const ReportOwner = styled.div`
  color: ${theme.palette.neutral80};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Content = styled.div`
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    margin-top: 32px;
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

interface MatchParams {
  reportId: string;
}

export const Details = withRouter((props: RouteComponentProps<MatchParams>) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const paramsStartDate = urlParams.get('start');
  const paramsEndDate = urlParams.get('end');
  const paramsShowOwner = urlParams.get('showOwner');
  const [showSendReportModal, setShowSendReportModal] = React.useState(false);
  const [startDate, setStartDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [dateRange, setDateRange] = React.useState('');
  const [downloadDropdownOpen, setDownloadDropdownOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);

  const { reportId } = props.match.params;

  const [showDeleteModal, setShowDeleteModal] = React.useState<string | null>(
    null
  );

  const { data: customReport, isFetching: loading } = useCustomReportQuery(
    reportId || ''
  );

  const { userData } = useAuth();

  const { userId, isCompanyAdmin } = userData;

  const { mutateAsync: deleteCustomReport, isLoading: isDeletingCustomReport } =
    useDeleteCustomReport({});

  const BREADCRUMBS = [
    {
      link: '/reports/custom',
      label: 'Custom Reports',
    },
    {
      link: undefined,
      label: customReport?.title ?? '',
    },
  ];

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    if (range !== dateRange) {
      setStartDate(start);
      setEndDate(end);
      setDateRange(range);
    }
  };

  const isUserReportOwner = customReport?.user?.id.toString() === userId;
  const showOwner = paramsShowOwner === 'true';

  useEffect(() => {
    if (loading) {
      return;
    }
    if (paramsStartDate && paramsEndDate) {
      setStartDate(new Date(paramsStartDate));
      setEndDate(new Date(paramsEndDate));
    } else if (
      !paramsStartDate &&
      !paramsEndDate &&
      customReport &&
      customReport.range === timeRangeConstants.CUSTOM
    ) {
      setStartDate(formatCustomReportDate(customReport.startDate));
      setEndDate(formatCustomReportDate(customReport.endDate));
    }
  }, [paramsStartDate, paramsEndDate, customReport, loading]);

  const handleSendReportModalClose = async () => {
    setShowSendReportModal(false);
  };

  const reportTabRef = React.useRef(null);
  const downloadReportScreenshot = useReactToPrint({
    content: () => reportTabRef.current,
  });

  const downloadReportCsv = async () => {
    if (customReport && startDate && endDate) {
      try {
        return await downloadCustomReportData(
          customReport.reportId || '',
          `${customReport.title}.csv`,
          startDate,
          endDate
        );
      } catch (error) {
        errorToast({
          title:
            error.message ||
            'Something went wrong while downloading the report.',
        });
      }
    }
  };

  if (loading) {
    return (
      <MainContainer>
        <LoadingIndicator isLoading={loading} height='300px' />
      </MainContainer>
    );
  }

  if (!customReport) {
    return <NotFound style={{ marginTop: '-80px' }} />;
  }

  const recurrings =
    customReport.recurrings && customReport.recurrings.length > 0;

  const tabs: Tab[] = [
    {
      title: 'Report',
      component: (
        <div ref={reportTabRef}>
          <ReportTab
            customReport={customReport}
            from={startDate}
            to={endDate}
            dateRange={dateRange}
            onDateRangeChange={onDateRangeChange}
          />
        </div>
      ),
    },
    recurrings && {
      title: 'Historical Data',
      component: (
        <HistoryTab customReport={customReport} setActiveTab={setActiveTab} />
      ),
    },
  ];
  return (
    <>
      <Container>
        <MainWrapper resetPadding={false}>
          <Breadcrumbs crumbs={BREADCRUMBS}></Breadcrumbs>
          <HeaderWrapper>
            {showOwner ? (
              <Gap gap='16px'>
                <Heading>{customReport.title}</Heading>
                <ReportOwner>
                  by{' '}
                  {getDisplayName([
                    customReport?.user?.firstName ?? '',
                    customReport?.user?.lastName ?? '',
                  ])}{' '}
                </ReportOwner>
              </Gap>
            ) : (
              <Heading>{customReport.title}</Heading>
            )}
            <Gap>
              <Button
                text='Send'
                icon={<MdEmail size={20} />}
                onClick={() => setShowSendReportModal(true)}
              />
              <Button
                text='Download'
                icon={<MdFileDownload size={20} />}
                onClick={() => {
                  // show download menu for admins
                  if (isCompanyAdmin) {
                    setDownloadDropdownOpen(true);
                  } else {
                    if (downloadReportScreenshot) {
                      downloadReportScreenshot();
                    }
                  }
                }}
              />
              {isUserReportOwner && (
                <CustomReportButton
                  text='Edit'
                  isEdit
                  reportId={customReport.reportId}
                />
              )}
              {isUserReportOwner && (
                <Button
                  variant='destructive'
                  icon={
                    <MdDeleteForever
                      size={18}
                      color={theme.palette.buttonDelete}
                    />
                  }
                  onClick={() => setShowDeleteModal(reportId)}
                />
              )}

              <MenuDropdown
                aligment={true}
                items={[
                  {
                    title: 'PDF',
                    onItemClick: () => {
                      setDownloadDropdownOpen(false);
                      if (downloadReportScreenshot) {
                        downloadReportScreenshot();
                      }
                    },
                    path: '#',
                  },
                  {
                    title: 'CSV',
                    onItemClick: () => {
                      setDownloadDropdownOpen(false);
                      downloadReportCsv();
                    },
                    path: '#',
                  },
                ]}
                isDropdownOpen={downloadDropdownOpen}
                OnClickOutside={() => setDownloadDropdownOpen(false)}
              />
            </Gap>
          </HeaderWrapper>
          <Content>
            <Tabs
              key={activeTab}
              tabs={tabs}
              defaultActiveTab={activeTab}
              onChange={setActiveTab}
            />
          </Content>

          {showDeleteModal && (
            <ModalDelete
              whiteButtonText='Yes, Delete'
              orangeButtonText='No, Cancel'
              title='Delete custom report?'
              text={`This action can’t be undone.`}
              handleModalClose={() => setShowDeleteModal(null)}
              onClickWhiteButton={async () => {
                await deleteCustomReport(showDeleteModal);
                setShowDeleteModal(null);
                history.push('/reports/custom');
              }}
              onClickOrangeButton={() => setShowDeleteModal(null)}
              loading={isDeletingCustomReport}
            />
          )}
          {showSendReportModal && startDate && endDate && (
            <SendReportModal
              handleModalClose={() => handleSendReportModalClose()}
              startDate={startDate}
              endDate={endDate}
              range={dateRange}
              reportId={customReport.reportId}
              isRecurring={!!customReport.recurrings.length}
            />
          )}
        </MainWrapper>
      </Container>
    </>
  );
});
