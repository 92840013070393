import { IPackageProduct, IPackageDetails } from 'lib/context';
import { Dictionary } from 'lodash';

export enum PackageName {
  LEGACY = 0,
  FREE = 1,
  PRO = 2,
  TEAMS = 3,
  ENTERPRISE = 4,
}

type PackageAdditionalInfo = {
  title: string;
  feeMonthly: number;
  feeYearly: number;
  feeMonthlySecondary?: number; // adding this because of marketing team's request. They dont want to show the prices on upgrade page but they want to show the prices on the signup page.
  feeYearlySecondary?: number; // adding this because of marketing team's request. They dont want to show the prices on upgrade page but they want to show the prices on the signup page.
  labelMonthly: string;
  labelYearly: string;
  yearlyOffer: string;
  showUpgrade: boolean;
  showDowngrade: boolean;
  showEditSubscription: boolean;
  showCancelSubscription: boolean;
  upgradeButtonOrange: boolean;
  showContact: boolean;
  showPrice: boolean;
  multipleSeats: boolean;
  mainFeatures: string[];
  additionalProducts: number[];
  isHidden?: boolean;
  getLabel: (isAnnual?: boolean) => string;
  legacyLabel?: string;
};

export type PackageWithAdditionalInfo = IPackageDetails & PackageAdditionalInfo;
export const packageAdditionalInfo = {
  1: {
    title: 'Free',
    feeMonthly: 0,
    feeYearly: 0,
    labelMonthly: 'per month',
    labelYearly: 'per year',
    yearlyOffer: '$0 per year',
    showUpgrade: false,
    showDowngrade: true,
    upgradeButtonOrange: false,
    showEditSubscription: false,
    showCancelSubscription: false,
    showContact: false,
    showPrice: true,
    multipleSeats: false,
    mainFeatures: [
      'Up to 5 mins/video',
      'Host up to 10 videos',
      'Trim videos',
      'Custom thumbnails',
      'Chrome extension',
      'Mobile app',
    ],
    isHidden: true,
    additionalProducts: [
      901, 902, 903, 904, 905, 907, 908, 909, 910, 911, 912, 921,
    ],
    legacyLabel: "Let's talk",
    getLabel: function () {
      return this.labelMonthly;
    },
  },
  2: {
    title: 'Pro',
    feeMonthly: 29,
    feeYearly: 19,
    labelMonthly: 'per month',
    labelYearly: 'per year',
    yearlyOffer: '',
    showUpgrade: true,
    showDowngrade: true,
    upgradeButtonOrange: true,
    showEditSubscription: false,
    showCancelSubscription: true,
    showContact: false,
    showPrice: true,
    multipleSeats: false,
    mainFeatures: [
      'Up to 1 hour/video',
      'Host unlimited videos',
      'Trim videos',
      'Screenshots',
      'Virtual Backgrounds',
      'Folders',
    ],
    additionalProducts: [
      901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912, 915, 916, 917,
      921, 922, 923, 924, 925,
    ],
    legacyLabel: "Let's talk",
    getLabel: function (isAnnual: boolean) {
      return isAnnual ? this.labelYearly : this.labelMonthly;
    },
  },
  3: {
    title: 'Teams',
    feeMonthly: 0,
    feeYearly: 0,
    feeMonthlySecondary: 79,
    feeYearlySecondary: 59,
    labelMonthly: "Let's talk",
    labelYearly: "Let's talk",
    yearlyOffer: '',
    showUpgrade: false,
    showDowngrade: false,
    upgradeButtonOrange: false,
    showEditSubscription: true,
    showCancelSubscription: false,
    showContact: true,
    showPrice: false,
    multipleSeats: true,
    mainFeatures: [
      'Up to 1 hour/video',
      'Host unlimited videos',
      'Company folder',
      'Merge Videos',
      'Custom attributes',
      'Website overlay',
      'Landing page builder',
      'Reply with video',
      'Tags',
      'Password protection',
      'Folder organization',
    ],
    additionalProducts: [
      901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912, 915, 916, 917,
      918, 919, 920, 921, 922, 923, 924, 925,
    ],
    legacyLabel: "Let's talk",
    getLabel: function (isAnnual: boolean) {
      return isAnnual ? this.labelYearly : this.labelMonthly;
    },
  },
  4: {
    title: 'Enterprise',
    feeMonthly: 0,
    feeYearly: 0,
    labelMonthly: "Let's talk",
    labelYearly: "Let's talk",
    yearlyOffer: '',
    showUpgrade: false,
    showDowngrade: false,
    showCancelSubscription: false,
    upgradeButtonOrange: false,
    showEditSubscription: false,
    showContact: true,
    showPrice: false,
    multipleSeats: false,
    mainFeatures: [
      'Up to 1 hour/video',
      'Host unlimited videos',
      'Remove Covideo logo',
      'Video expiration',
      'SSO (SAML)',
      'Custom domain',
    ],
    additionalProducts: [
      901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912, 915, 916, 917,
      918, 919, 920, 921, 922, 923, 924, 925,
    ],
    legacyLabel: "Let's talk",
    getLabel: function (isAnnual: boolean) {
      return isAnnual ? this.labelYearly : this.labelMonthly;
    },
  },
} as Dictionary<PackageAdditionalInfo>;

export const productCategories = {
  1: {
    title: 'Video Creation & Hosting',
    products: [901, 902, 903, 904],
  },
  2: {
    title: 'Recording Options',
    products: [2, 3, 4],
  },
  3: {
    title: 'Video Options and Management',
    products: [905, 10, 50, 11, 12, 49, 47],
  },
  4: {
    title: 'Screenshots',
    products: [906, 1],
  },
  5: {
    title: 'Video Optimization',
    products: [13, 14, 15, 16, 17, 18, 34],
  },
  6: {
    title: 'Video Sharing',
    products: [19, 20, 21, 22, 23, 24],
  },
  7: {
    title: 'Playback Experience',
    products: [27, 28, 29, 30],
  },
  8: {
    title: 'Video Analytics',
    products: [31, 48, 32, 35],
  },
  9: {
    title: 'Video Security',
    products: [36, 26],
  },
  10: {
    title: 'Contact Management',
    products: [907, 908, 909],
  },
  11: {
    title: 'Integrations',
    products: [7, 8, 6, 5, 9, 37, 910, 911, 912, 915],
  },
  12: {
    title: 'Add-Ons',
    products: [922, 923, 924, 925],
  },
  13: {
    title: 'Admin Controls',
    products: [40, 43],
  },
  14: {
    title: 'Support',
    products: [916, 917, 918, 919, 920, 921],
  },
};

export const missingProducts = {
  901: {
    id: 901,
    name: 'Video Creation',
  },
  902: {
    id: 902,
    name: 'Video Uploading',
  },
  903: {
    id: 903,
    name: 'Video Recording Length',
  },
  904: {
    id: 904,
    name: 'Video Hosting',
  },
  905: {
    id: 905,
    name: 'Video Quality',
  },
  906: {
    id: 906,
    name: 'Screenshots',
  },
  907: {
    id: 907,
    name: 'Contacts',
  },
  908: {
    id: 908,
    name: 'Groups',
  },
  909: {
    id: 909,
    name: 'Opt-Outs',
  },
  910: {
    id: 910,
    name: 'Chrome',
  },
  911: {
    id: 911,
    name: 'HubSpot',
  },
  912: {
    id: 912,
    name: 'LinkedIn',
  },
  913: {
    id: 913,
    name: 'Elead',
  },
  914: {
    id: 914,
    name: 'VinSolutions',
  },
  915: {
    id: 915,
    name: 'Element451',
  },
  916: {
    id: 916,
    name: 'Chat Support',
  },
  917: {
    id: 917,
    name: 'Email Support',
  },
  918: {
    id: 918,
    name: 'Phone Support',
  },
  919: {
    id: 919,
    name: 'Onboarding',
  },
  920: {
    id: 920,
    name: 'Dedicated Account Manager',
  },
  921: {
    id: 921,
    name: 'Access to Help Center Tutorials',
  },
  922: {
    id: 922,
    name: 'SMS',
  },
  923: {
    id: 923,
    name: 'Video Landing Page Builder',
  },
  924: {
    id: 924,
    name: 'Captions and Translations',
  },
  925: {
    id: 925,
    name: 'Files and Boards',
  },
  926: {
    id: 926,
    name: 'Codirect',
  },
  927: {
    id: 927,
    name: 'WheelsTV',
  },
} as Dictionary<IPackageProduct>;

type ProductValueProduct = {
  value: string;
  type?: number;
};

export const packageProductValue = {
  901: {
    1: {
      value: 'Unlimited',
    },
    2: {
      value: 'Unlimited',
    },
    3: {
      value: 'Unlimited',
    },
    4: {
      value: 'Unlimited',
    },
  },
  903: {
    1: {
      value: 'Up to 5 minutes',
    },
    2: {
      value: 'Up to 1 hour',
    },
    3: {
      value: 'Up to 1 hour',
    },
    4: {
      value: 'Up to 1 hour',
    },
  },
  904: {
    1: {
      value: 'Up to 10 videos',
    },
    2: {
      value: 'Unlimited',
      type: 2,
    },
    3: {
      value: 'Unlimited',
      type: 2,
    },
    4: {
      value: 'Unlimited',
      type: 2,
    },
  },
  905: {
    1: {
      value: 'Up to 1080p Full HD',
    },
    2: {
      value: 'Up to 1080p Full HD',
    },
    3: {
      value: 'Up to 1080p Full HD',
    },
    4: {
      value: 'Up to 1080p Full HD',
    },
  },
  906: {
    2: {
      value: 'Unlimited',
      type: 2,
    },
    3: {
      value: 'Unlimited',
      type: 2,
    },
    4: {
      value: 'Unlimited',
      type: 2,
    },
  },
  922: {
    2: {
      value: '$',
    },
    3: {
      value: '$',
    },
    4: {
      value: '$',
    },
  },
  923: {
    2: {
      value: '$',
    },
    3: {
      value: '$',
    },
    4: {
      value: '$',
    },
  },
  924: {
    2: {
      value: '$',
    },
    3: {
      value: '$',
    },
    4: {
      value: '$',
    },
  },
  925: {
    2: {
      value: '$',
    },
    3: {
      value: '$',
    },
    4: {
      value: '$',
    },
  },
} as Dictionary<Dictionary<ProductValueProduct>>;
