import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';

import { IQuickFilterValues } from 'lib/api/quickFilters/getQuickFilters';
import { useQuickQueryDeleteMutation } from 'lib/api/quickFilters/deleteQuickFilter';
import { IoMdCloseCircle } from 'react-icons/io';

const Gap = styled.div<{
  gap?: string;
  m?: string;
  direction?: string;
}>`
  display: flex;
  margin: ${({ m }) => m || '0px'};
  gap: ${({ gap }) => gap || '16px'};
  flex-direction: ${({ direction }) => direction || 'row'};
`;
const FilterWrapper = styled.div`
  height: 18px;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  border-radius: 5px;
  padding: 4px 8px;
  background: rgba(0, 27, 83, 0.05);
  color: rgb(78, 84, 97);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

interface IProps {
  onModalClose: () => void;
  quickFilters: IQuickFilterValues[] | undefined;
  resetFormikValues: () => void;
}

export const ModalManageFilters = ({
  onModalClose,
  quickFilters,
  resetFormikValues,
}: IProps) => {
  const { mutate } = useQuickQueryDeleteMutation(
    resetFormikValues,
    onModalClose
  );

  return (
    <NewModal
      headerText='Manage filters'
      closeModal={onModalClose}
      style={{
        content: {
          maxWidth: '592px',
        },
      }}
    >
      <Gap direction='column' gap='16px'>
        <Gap gap='8px' style={{ flexWrap: 'wrap' }}>
          {quickFilters?.map(item => (
            <FilterWrapper>
              {item.title}

              <IoMdCloseCircle
                onClick={() => mutate(item.quickFilterId)}
                className='close-icon'
                color='rgba(0, 27, 83, 0.4)'
                cursor='pointer'
              />
            </FilterWrapper>
          ))}
        </Gap>
        <Gap direction='row-reverse'></Gap>
      </Gap>
    </NewModal>
  );
};
