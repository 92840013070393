import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { signatureKeys } from './signatureKeys';
import { SignatureQueryI } from './types';

const getSignature = async (): Promise<string> => {
  const response = await EXPRESS_API.get(`/signature`);
  return response.data;
};
export const useGetSignature = ({ onSuccess }: SignatureQueryI) => {
  return useQuery(signatureKeys.signature(), getSignature, {
    refetchOnMount: true,
    onSuccess,
  });
};
