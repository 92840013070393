import React from 'react';
import { SingleAvailableField } from './SingleAvailableField';
import { AvailableFieldsListProps } from './types';

export const AvailableFieldsList = ({
  allFields,
  selectedFields,
  isMaxFieldReached,
  onAddClickHandler,
}: AvailableFieldsListProps) => {
  return (
    <>
      {allFields.map(field => {
        const isSelected = selectedFields.some(
          item => item.value === field.value
        );

        const isSelectionOrMaxReached = isSelected || isMaxFieldReached;
        return (
          <SingleAvailableField
            key={field.value}
            onAddClickHandler={onAddClickHandler}
            isSelectionOrMaxReached={isSelectionOrMaxReached}
            isSelected={isSelected}
            field={field}
          />
        );
      })}
    </>
  );
};
