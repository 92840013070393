import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import onboardingWelcomePageAnimation from 'lib/animations/OnBoarding_Step_1.json';
import startOnboardingButtonBottomRightExtra from 'assets/images/freemiumOnboarding/onboardingButtonBottomRight.svg';
import startOnboardingButtonTopLeftExtra from 'assets/images/freemiumOnboarding/onboardingButtonTopLeft.svg';
import { useAuth } from 'lib/context';
import { useHistory } from 'react-router';
import { FreemiumOnboardingPageTemplate } from '../components';
import { checkIfFreemium } from '../../../../lib/utils/functions';
import { Button } from 'react-covideo-common';
import welcome from 'assets/images/freemiumOnboarding/welcome.svg';
import { Gap } from 'lib/components/styles/layout';

const MainHeading = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 16px;
  color: ${theme.palette.covideoBlue100};
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 24px;
    line-height: 32px;
  }
`;
const Heading = styled.h2`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: ${theme.palette.covideoBlue100};
  span {
    color: ${({ theme }) => theme.colors.primary[100]};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 24px;
    line-height: 32px;
  }
`;
const StartOnboardingButtonWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-top: 64px;
  ${theme.mediaQueryMaxWidth.sm} {
    width: 100%;
  }
`;
type StartOnboardingButtonExtrasWrapperProps = {
  top?: string;
  bottom?: string;
  left?: string;
  rigth?: string;
};
const StartOnboardingButtonExtrasWrapper = styled.div<StartOnboardingButtonExtrasWrapperProps>`
  position: absolute;
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  right: ${props => props.rigth};
`;
const StartButtonContainer = styled.div`
  button {
    font-size: 28px;
    height: 80px;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    button {
      font-size: 20px;
      height: 64px;
    }
  }
`;

export const Welcome = () => {
  const { userData } = useAuth();
  const history = useHistory();

  const handleStartOnboarding = () => {
    const url = checkIfFreemium(userData)
      ? '/onboarding-freemium/step-one/choose-path'
      : '/onboarding-freemium/step-two/my-team';
    history.push(url);
  };

  return (
    <FreemiumOnboardingPageTemplate
      currentStep={0}
      rightColumnAnimationSrc={onboardingWelcomePageAnimation}
      rightColumnChildren={
        <Gap justifyContent='center'>
          <img alt='welcome' src={welcome} />
        </Gap>
      }
      leftColumnChildren={
        <>
          <MainHeading>Hi {userData?.firstName}!</MainHeading>
          <Heading>
            Let’s personalize your Covideo experience{' '}
            <span>in just 3 steps!</span>
          </Heading>
          <StartOnboardingButtonWrapper>
            <StartOnboardingButtonExtrasWrapper top='-29px' left='-27px'>
              <img src={startOnboardingButtonTopLeftExtra} alt='icon' />
            </StartOnboardingButtonExtrasWrapper>
            <StartButtonContainer>
              <Button
                text='Start Here'
                onClick={() => handleStartOnboarding()}
              />
            </StartButtonContainer>

            <StartOnboardingButtonExtrasWrapper rigth='-27px' bottom='-34px'>
              <img src={startOnboardingButtonBottomRightExtra} alt='icon' />
            </StartOnboardingButtonExtrasWrapper>
          </StartOnboardingButtonWrapper>
        </>
      }
    />
  );
};
