import React, { useMemo } from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Route, Switch } from 'react-router-dom';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { KeyMetrics } from './main/KeyMetrics';
import { QuickFiltersBuilder } from './filters/QuickFiltersBuilder';

const Wrapper = () => {
  return useMemo(
    () => (
      <Container>
        <MainWrapper resetPadding={false}>
          <Switch>
            <Route
              path='/reports/key-metrics'
              component={KeyMetrics}
              exact={true}
            />
            <Route
              path='/reports/key-metrics/filters'
              component={QuickFiltersBuilder}
              exact={true}
            />
          </Switch>
        </MainWrapper>
      </Container>
    ),
    []
  );
};

export const KeyMetricsPage = () => (
  <>
    <DocumentHead title='Key Metrics Report' />
    <PageTemplate main={<Wrapper />} />
  </>
);
