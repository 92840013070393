import { useQuery } from 'react-query';
import { vdpQueryKeys } from './vdpQueryKeys';
import { VdpParams, VdpResponse } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

const getVdps = async (params: VdpParams): Promise<VdpResponse> => {
  return (await EXPRESS_API.get(`vdp`, { params })).data;
};

export const useGetVdps = (params: VdpParams) => {
  return useQuery(vdpQueryKeys.list(params), async () => getVdps(params), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};
