import { FieldMetaProps } from 'formik';
import { ParagraphExtraSmall } from 'lib/components/styles/typography';
import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';

export const FormikErrorMessage = ({ meta }: { meta: FieldMetaProps<any> }) => {
  const themes = useCovideoTheme();
  return (
    <div style={{ height: 16 }}>
      {meta.error && meta.touched ? (
        <ParagraphExtraSmall color={themes.colors.danger[80]}>
          {meta.error}
        </ParagraphExtraSmall>
      ) : null}
    </div>
  );
};
