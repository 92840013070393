import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import DragAndDrop from '../inputs/DragAndDrop';
import { NewModal } from './NewModal';
import styled, { useTheme } from 'styled-components/macro';
import { Search } from '../inputs';
import FileCard from '../FileCard';
import { Dropdown } from '../dropdown/Dropdown';
import { AttachedFile, FileUploadStatus } from 'lib/context';
import InfiniteScroll from 'react-infinite-scroll-component';
import UploadProgressModal from './UploadProgressModal';
import { MdOutlineArrowBack } from 'react-icons/md';
import {
  checkUploadedSize,
  EXCEEDED_MESSAGE,
  MAX_ATTACHMENT_SIZE,
  StatusOptions,
  uploadAttachmentWithProgress,
} from 'lib/utils/files';
import { errorToast } from '../toasts/error';
import {
  acceptedFormats,
  statuses,
} from 'app/pages/library/components/Constants';
import {
  DROPTYPE_OPTIONS,
  SortField,
  SortFields,
  getIconForFileType,
} from 'lib/const/Droplr';
import { DropItem } from 'lib/api/droplr/types';
import { getSharedDrops, useGetDrops } from 'lib/api/droplr/getDrops';
import { sortSharedDrops } from 'lib/utils/drop';

const Container = styled.div`
  margin-top: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilesContainer = styled.div`
  overflow-y: auto;
  height: 288px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TabContainer = styled.div<any>`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  .activeTab {
    color: ${props => props.color};
    border-bottom: 1px solid ${props => props.color};
  }
  border-bottom: 1px solid #eeeff2;
  margin-top: 25px;
`;

interface TabStyleProperties {
  width?: string;
}

const Tab = styled.div<TabStyleProperties>`
  text-align: center;
  font-size: 15px;
  padding-bottom: 11px;
  cursor: pointer;
  font-weight: 600;
  width: 50%;
`;

const DropdownContainer = styled.div`
  width: 100%;
  margin-left: 12px;
  & > div > div {
    border-radius: 4px;
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

type ModalProps = {
  handleModalClose: () => void;
  uploadedFiles?: AttachedFile[];
  setUploadedFiles?: React.Dispatch<React.SetStateAction<AttachedFile[]>>;
  setAttachmentUploadStatus: React.Dispatch<
    React.SetStateAction<FileUploadStatus[]>
  >;
};

const ModalAttachment = ({
  handleModalClose,
  uploadedFiles,
  setUploadedFiles,
  setAttachmentUploadStatus,
}: ModalProps) => {
  const initialFilter = {
    page: 0,
    size: 10,
    sort: SortFields[0].key,
    order: SortFields[0].order,
    search: '',
    dropType: DROPTYPE_OPTIONS.OWNED,
  };

  const [field, setField] = useState(SortFields[0]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [file, setFile] = useState<File>(new File([], ''));
  const [filteredDrops, setFilteredDrops] = useState<DropItem[]>([]);
  const [loading, setLoading] = useState(true); // for shared drops
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [isDropUploading, setIsDropUploading] = useState('');

  const [filter, setFilter] = useState(initialFilter);

  const isSharedTab = filter.dropType === DROPTYPE_OPTIONS.SHARED;
  const isOwnedTab = filter.dropType === DROPTYPE_OPTIONS.OWNED;

  const { data: dropData } = useGetDrops(filter);
  const { drops } = dropData || { drops: [] };
  const themes = useTheme();

  const handleSelectedFile = (e: InputEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const target = e.dataTransfer || (e.target as HTMLInputElement);
    if (target && target.files && target.files.length && !!uploadedFiles) {
      const file = target.files[0];

      const uploadedSize = checkUploadedSize(
        uploadedFiles,
        MAX_ATTACHMENT_SIZE
      );
      if (uploadedSize.remaningSize < file.size) {
        errorToast({
          title: EXCEEDED_MESSAGE,
        });
        handleModalClose();
        return;
      }

      setFile(file);
      setShowUploadModal(true);
    }
  };

  const loadMoreDrops = async () => {
    setFilter(prev => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const searchSharedDrops = async (searchQuery: string) => {
    // fetch shared drops and manually filter (droplr does not support sorting shared drops on api)
    const refreshedDropList = await getSharedDrops({
      page: filter.page,
      size: filter.size,
    });
    const { drops: refreshedSharedDrops, count: sharedDropCount } =
      refreshedDropList;
    setCount(sharedDropCount);

    if (!searchQuery.length) {
      setLoading(false);
      return [...refreshedSharedDrops]; // return all if nothing is typed in
    }

    const searchResult = refreshedSharedDrops.filter((drop: DropItem) =>
      drop.title.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
    );
    return searchResult;
  };

  const searchAndSortSharedDrops = async (searchTerm: string) => {
    setLoading(true);
    const searchResults = await searchSharedDrops(searchTerm);
    const sortedResults = sortSharedDrops(searchResults, field);
    setFilteredDrops(sortedResults);
    setLoading(false);
  };

  const onSelectDrop = async (id: string) => {
    const drop = filteredDrops.find(drop => drop.code === id);
    if (!drop || !drop.content || !setUploadedFiles || !uploadedFiles) return;

    // check if it can be uploaded
    const uploadedSize = checkUploadedSize(uploadedFiles, MAX_ATTACHMENT_SIZE);
    if (uploadedSize.remaningSize < drop.size) {
      errorToast({
        title: EXCEEDED_MESSAGE,
      });
      handleModalClose();
      return;
    }
    try {
      setIsDropUploading(drop.code);
      const fileId = `attachments-${Math.floor(Math.random() * 1000)}`;
      const fileUrl = drop.content;
      const filename = drop.title;
      const fileFetch = await fetch(fileUrl);
      const contentType =
        fileFetch.headers.get('content-type') ?? 'application/octet-stream';
      const blob = await fileFetch.blob();
      const file = new File([blob], filename, { type: contentType });

      const newUploadedFile = await uploadAttachmentWithProgress(
        file,
        (options: StatusOptions) => {
          const { status, currentUpload, progress } = options;
          const currentFileStatus: FileUploadStatus = {
            status: status || statuses.WAITING,
            progress: progress || 0,
            currentUpload: currentUpload || 0,
          };
          setAttachmentUploadStatus(prev => [
            ...(prev || []),
            currentFileStatus,
          ]);
        }
      );
      if (!newUploadedFile) {
        errorToast({
          title: 'Something went wrong.',
        });
        handleModalClose();
        return;
      }
      setUploadedFiles(prev => [
        ...(prev || []),
        {
          name: filename,
          size: file.size,
          type: file.type,
          url: newUploadedFile.url,
          id: fileId,
        },
      ]);
      setShowUploadModal(false);
      setIsDropUploading('');
      handleModalClose();
    } catch (error) {
      errorToast({
        title: 'Something went wrong.',
      });
      setIsDropUploading('');
      handleModalClose();
      return;
    }
  };

  const onTabChange = (newTab: DROPTYPE_OPTIONS) => {
    setFilteredDrops([]);
    setLoading(true);
    setFilter(prev => ({
      ...prev,
      page: 0,
      dropType: newTab,
    }));
  };

  const onSortFieldChange = (newFieldValue: SortField) => {
    if (newFieldValue !== field) {
      setLoading(true);
      setField(newFieldValue);
      setFilteredDrops([]);
    }
  };

  const Loader = () => <h4>Loading...</h4>;

  useEffect(() => {
    if (isSharedTab) {
      searchAndSortSharedDrops(search);
      return;
    }
    setFilter(prev => ({
      ...prev,
      sort: field.key,
      order: field.order,
    }));
  }, [field]);

  useEffect(() => {
    if (uploadedFiles && uploadedFiles.length && showUploadModal) {
      setShowUploadModal(false);
      handleModalClose();
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (dropData?.drops) {
      setFilteredDrops(prev => [...prev, ...drops]);
      setCount(dropData.count);
      if (loading) {
        setLoading(false);
      }
    }
  }, [dropData]);

  if (showUploadModal) {
    return (
      <UploadProgressModal
        handleModalClose={() => setShowUploadModal(false)}
        initialFile={file}
        setUploadedFiles={setUploadedFiles}
        uploadedFiles={uploadedFiles}
      />
    );
  }

  return (
    <NewModal
      style={{
        overlay: {
          background: 'rgba(19, 34, 66, 0.4)',
        },
        content: {
          width: '592px',
          boxSizing: 'border-box',
          padding: '32px 32px 0 32px',
        },
      }}
      headerText='Select File or Upload'
      closeModal={handleModalClose}
      closeIcon={
        <CloseIcon onClick={handleModalClose}>
          <MdOutlineArrowBack color={theme.palette.gray60} size={20} />
        </CloseIcon>
      }
      closeIconPosition='left'
    >
      <DragAndDrop
        height={120}
        width={525}
        showIcon={false}
        button={true}
        text='Drag and drop files here to upload new file'
        onDrop={handleSelectedFile}
        textStyle={{
          marginBottom: '0',
          width: '100%',
          fontWeight: 400,
        }}
        variant='white'
        showAddIcon={false}
        accept={acceptedFormats.join(', ')}
      />

      <TabContainer color={themes.colors.primary[100]}>
        <Tab
          className={isOwnedTab ? 'activeTab' : ''}
          onClick={() => {
            onTabChange(DROPTYPE_OPTIONS.OWNED);
          }}
          title={'myFiles'}
        >
          My Files
        </Tab>
        <Tab
          className={isSharedTab ? 'activeTab' : ''}
          onClick={() => {
            onTabChange(DROPTYPE_OPTIONS.SHARED);
          }}
          title={'sharedWithMe'}
        >
          Shared with me
        </Tab>
      </TabContainer>

      <Container>
        <FlexContainer>
          <Search
            onSearch={(query: string) => {
              const term = query.toLowerCase();
              if (isOwnedTab) {
                setLoading(true);
                setFilteredDrops([]);
                setFilter(prev => ({
                  ...prev,
                  search: term,
                }));
              } else {
                setSearch(term);
                searchAndSortSharedDrops(term);
              }
            }}
            placeholder='Search files and tags...'
            width='258px'
            prevSearch={filter.search}
          />

          <DropdownContainer>
            <Dropdown
              value={field}
              onChange={newValue => {
                onSortFieldChange(newValue);
              }}
              options={SortFields}
              dropdownHeight={450}
              height={40}
            />
          </DropdownContainer>
        </FlexContainer>
      </Container>

      <Container>
        <FilesContainer>
          {loading ? (
            <Loader />
          ) : !filteredDrops.length ? (
            <h4>There is no files matching this criteria.</h4>
          ) : (
            <InfiniteScroll
              hasMore={filteredDrops.length < count}
              next={loadMoreDrops}
              dataLength={filteredDrops.length}
              height={300}
              loader={<Loader />}
            >
              {filteredDrops.map((item: DropItem, index: number) => {
                return (
                  <FileCard
                    key={(item?.shortlink || '') + index}
                    src={item.previewSmall}
                    icon={getIconForFileType(item.variant, 'grid')}
                    title={item.title}
                    id={item.code}
                    onSelectDrop={onSelectDrop}
                    previewSrc={
                      item.variant.includes('video')
                        ? item.content
                        : item?.previewMedium
                    }
                    type={item.variant}
                    isUploading={isDropUploading === item.code}
                    disabled={!!isDropUploading.length}
                  />
                );
              })}
            </InfiniteScroll>
          )}
        </FilesContainer>
      </Container>
    </NewModal>
  );
};

export default ModalAttachment;
