import React, { useEffect, useState } from 'react';
import { useGetVideos } from 'lib/api/videos/useGetVideos';
import { DEFAULT_PAGE } from 'lib/const/PaginationConstants';
import { IOnPaginationChange } from 'app/pages/account/userAccount/schedule/types';
import VideoUploadFolderDropdown from '../../videoUpload/VideoUploadFolderDropdown';
import { Option } from '../../dropdown/Dropdown';
import { DEFAULT_UPLOAD_FOLDER } from '../../videoUpload/VideoUpload';
import { anyInMap } from '../../../utils/object';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  CheckboxInput,
  LoadingIndicator,
  Search,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { SelectedVideosToMerge } from './types';
import { ALL_FOLDERS, SHARED_FOLDER_PREFIX } from 'lib/const/Folders';

const Counter = styled.i`
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.palette.black_1_100};
  line-height: 1.5;
`;

const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 100%;
  color: ${theme.palette.blackRgb60};
  ${theme.mediaQueryMinWidth.md} {
    margin-right: 12px;
  }
`;

const SelectionCountText = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #53565f;
  padding-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  width: 160px;
`;

const VideosList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Video = styled.div`
  width: 50%;
  height: 84px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
  ${theme.mediaQueryMinWidth.md} {
    margin-bottom: 24px;
    height: 126px;
  }
`;

const VideoImgContainer = styled.div`
  background-color: ${theme.palette.blackRgba75};
  display: flex;
  justify-content: center;
  width: 144px;
  position: relative;
  margin-left: 16px;
  &:hover {
    cursor: pointer;
    .play-button {
      opacity: 1;
    }
  }
`;

const VideoImage = styled.div`
  display: flex;
  min-width: 45px;
  transition: all 0.2s;
  justify-content: center;
  height: 84px;
`;

const VideoImg = styled.img`
  background-color: ${theme.palette.white};
  display: block;
  height: 100%;
  width: 100%;
`;

const VideoTitle = styled.div`
  height: 24px;
  margin-left: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: left !important;
  color: ${theme.palette.title};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 220px;
  &:hover {
    cursor: pointer;
  }
`;

const LoadingWrapper = styled.div`
  margin-top: 100px;
`;

const EmptySearch = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
`;

const initialAllSelected: { [key: string]: boolean } = {};

interface Props {
  changeSelectedVideoIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const TabLibrary = ({ changeSelectedVideoIds }: Props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [size, setSize] = useState(6);
  const [selectedFolderOption, setSelectedFolderOption] = useState<Option>(
    DEFAULT_UPLOAD_FOLDER
  );
  const [isAllSelected, setIsAllSelected] = useState(initialAllSelected);
  const [selectedVideos, setSelectedVideos] = useState<SelectedVideosToMerge>({
    count: 0,
    videos: new Map<string, boolean>(),
  });
  const [selectedFolder, setSelectedFolder] = useState<number | string>(
    DEFAULT_UPLOAD_FOLDER.label
  );

  const videoFilter = {
    videoType: '',
    advisorId: '',
    userId: '',
    status: '',
    emailStatus: '',
    folderId: 0,
    page,
    size,
    searchQuery,
  };

  const {
    data,
    isLoading: loading,
    refetch: refetchVideos,
  } = useGetVideos(videoFilter, selectedFolder?.toString() || '');

  const videos = data?.videos || [];
  const count = data?.count || 0;

  useEffect(() => {
    refetchVideos();
  }, [page, size]);

  useEffect(() => {
    const folder =
      selectedFolderOption &&
      selectedFolderOption.access &&
      Number(selectedFolderOption.value) >= 0
        ? `${SHARED_FOLDER_PREFIX}${selectedFolderOption.value}`
        : selectedFolderOption.value === ALL_FOLDERS.value
          ? ALL_FOLDERS.value
          : selectedFolderOption.label;
    setSelectedFolder(folder);
  }, [selectedFolderOption]);

  useEffect(() => {
    const selectedVideoIds = Array.from(selectedVideos.videos)
      .filter(([_, isSelected]) => !!isSelected)
      .map(([videoId, _]) => videoId);
    changeSelectedVideoIds(selectedVideoIds);
  }, [selectedVideos]);

  const handleSearch = async (newSearchTerm: string = '') => {
    if (searchQuery === newSearchTerm) {
      return;
    }
    setSearchQuery(newSearchTerm);

    if (page !== 0) {
      setPage(0);
      return;
    }
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: IOnPaginationChange) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const checkIndicator = () => {
    return (
      anyInMap(videos || [], selectedVideos.videos, 'id') &&
      !isAllSelected[page]
    );
  };
  return (
    <>
      <Gap alignItems='center' width='100%' justifyContent='space-between'>
        <SelectionCountWrap>
          <CheckboxInput
            blueCheck={true}
            checkGroupIndicator={checkIndicator()}
            checked={isAllSelected[page] || false}
            onChange={(event: any) => {
              setIsAllSelected({
                ...isAllSelected,
                [page]: event.target.checked,
              });
              const selected = new Map<string, boolean>(selectedVideos.videos);
              (videos || []).forEach(vid => {
                selected.set(vid.id, event.target.checked);
              });

              setSelectedVideos({
                count: Array.from(selected.values()).filter(Boolean).length,
                videos: selected,
              });
            }}
          />
          <SelectionCountText>
            <div>
              Videos selected: <Counter>{selectedVideos.count}</Counter>
            </div>
          </SelectionCountText>
        </SelectionCountWrap>
        <Gap gap={'10px'}>
          <Search
            placeholder={'Search videos'}
            onSearch={handleSearch}
            prevSearch={searchQuery}
            width={'242px'}
          />
          <VideoUploadFolderDropdown
            value={selectedFolderOption}
            setValue={setSelectedFolderOption}
            showSharedWithMeFolders={true}
            showLabel={false}
            width='242px'
          />
        </Gap>
      </Gap>
      {loading && (
        <LoadingWrapper>
          <LoadingIndicator isLoading={loading} />
        </LoadingWrapper>
      )}
      {!loading && (
        <>
          {!videos?.length && searchQuery && (
            <EmptySearch>
              <p>There are no videos with “{searchQuery}” in the title</p>
              <p>Try using a different search term</p>
            </EmptySearch>
          )}
          {!videos?.length && !searchQuery && (
            <EmptySearch>
              <p>There are no videos in this folder</p>
              <p>You can merge videos from another folder</p>
            </EmptySearch>
          )}
          <VideosList>
            {videos.map(video => {
              return (
                <Video
                  key={video.id}
                  onClick={() => {
                    let selected = new Map<string, boolean>(
                      selectedVideos.videos
                    );
                    let count = selectedVideos.count;
                    const isSelected = !selected.get(video.id);
                    selected.set(video.id, isSelected);
                    isSelected ? count++ : count--;
                    setSelectedVideos({ count, videos: selected });
                  }}
                >
                  <section
                    onClick={(e: React.SyntheticEvent) => {
                      e.stopPropagation();
                    }}
                  >
                    <CheckboxInput
                      width='100%'
                      blueCheck={true}
                      checked={selectedVideos.videos.get(video.id)}
                      onChange={() => {
                        let selected = new Map<string, boolean>(
                          selectedVideos.videos
                        );
                        let count = selectedVideos.count;
                        const isSelected = !selected.get(video.id);
                        selected.set(video.id, isSelected);
                        isSelected ? count++ : count--;
                        setSelectedVideos({ count, videos: selected });
                      }}
                    />
                  </section>
                  <section>
                    <VideoImgContainer>
                      <VideoImage>
                        <VideoImg src={video.thumbnail} />
                      </VideoImage>
                    </VideoImgContainer>
                  </section>
                  <section>
                    <VideoTitle>{video.title}</VideoTitle>
                  </section>
                </Video>
              );
            })}
          </VideosList>
        </>
      )}
      <TableContextProvider
        total={count}
        initSize={size}
        initPage={page}
        onChange={onPaginationChange}
      >
        <TableFooter>
          <TablePaginationNew />
          <TablePaginationSizeNew />
        </TableFooter>
      </TableContextProvider>
    </>
  );
};
