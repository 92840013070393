import { Button } from 'react-covideo-common';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import React from 'react';
import { ItemContainer } from '../ItemContainer';
import { MdEdit } from 'react-icons/md';

interface IProps {
  label: string | null;
  openModalHandler: () => void;
  customerId: number | null;
}
export const LocationItem = ({
  label,
  openModalHandler,
  customerId,
}: IProps) => {
  return (
    <ItemContainer
      width='100%'
      justifyContent='space-between'
      data-set={customerId}
    >
      <ParagraphSmall
        color={theme.palette.gray100}
        ellipsis
        style={{ whiteSpace: 'nowrap', flex: 1, fontSize: 12 }}
      >
        {label || 'N/A'}
      </ParagraphSmall>
      <Button
        onClick={openModalHandler}
        variant='secondary'
        size='small'
        icon={<MdEdit color={theme.palette.covideoBlue100} size={'18px'} />}
      />
    </ItemContainer>
  );
};
