import React from 'react';
import { LoadingIndicator } from 'lib/components';
import { checkZoomIntegration, disconnectZoomIntegration } from 'lib/api';
import { BackToIntegrations } from './BackToIntegrations';
import { IntegrationHeadline } from './IntegrationHeadline';
import { Video } from './Video';
import { Gap } from 'lib/components/styles/layout';
import { ZOOM_HELP_DESK_LINK } from '../main/constants';
import { Button } from 'react-covideo-common';

const ZOOM_URL = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ZOOM_REDIRECT_URL}`;

export const ZoomIntegration = () => {
  const [isConnected, setIsConnected] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const checkIntegration = async () => {
    try {
      const response = await checkZoomIntegration();
      setIsConnected(response.authorized);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const connect = () => {
    window.location.href = ZOOM_URL;
  };

  const disconnect = async () => {
    const response = await disconnectZoomIntegration();

    if (response && response.success) {
      setIsConnected(false);
    }
  };

  React.useEffect(() => {
    checkIntegration();
  }, []);

  return (
    <>
      <Gap flexDirection='row' justifyContent='space-between' width='100%'>
        <BackToIntegrations />
        <Button
          variant={isConnected ? 'destructive' : 'primary'}
          text={isConnected ? 'Disconnect' : 'Connect'}
          onClick={isConnected ? disconnect : connect}
        />
      </Gap>
      {loading && <LoadingIndicator isLoading={loading} height='300px' />}
      {!loading && (
        <>
          <IntegrationHeadline
            title='Covideo for Zoom'
            imgSrc='https://www.covideo.com/backoffice/images/zoom-logo.svg'
          />
          <Gap>
            <Video
              thumbnail='https://images.covideo.com/thumbnails/zoom_integration.png'
              videoSource='https://videos.covideo.com/website/zoom_integration.mp4'
            />
            <Gap>
              <div>
                Connect Zoom to Covideo to turn your recordings into engaging
                content! This integration allows any Zoom recording you’ve saved
                to the Cloud to automatically upload into your Covideo account.
              </div>
              <div>
                Unlimited Covideo storage means you’ll never need to delete
                recordings to make space. You can take advantage of Covideo’s
                helpful editing tools, like the ability to generate captions,
                trim and merge recordings, annotate your videos, and more. You
                can also easily share and track these videos.
              </div>
              <div>
                <a href={ZOOM_HELP_DESK_LINK} target='_blank' rel='noreferrer'>
                  Check out our Help Desk article
                </a>{' '}
                for information on setting up the Covideo Zoom Integration.
              </div>
            </Gap>
          </Gap>
        </>
      )}
    </>
  );
};
