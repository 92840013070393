import { useEffect, useRef, useState } from 'react';

type Props = {
  src: string;
  onLoad?: (width: number, height: number) => void;
};

const useImageLoadRetry = ({ src, onLoad }: Props) => {
  const MAX_RETRIES = 30;
  const MAX_DELAY = 5_000;
  const imgRef = useRef<HTMLImageElement>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [error, setError] = useState(false);

  const handleLoad = () => {
    setError(false);
    setRetryCount(0);
    if (onLoad) {
      onLoad(
        imgRef.current?.offsetWidth ?? 0,
        imgRef.current?.offsetHeight ?? 0
      );
    }
  };

  const handleError = () => {
    setError(true);
    if (retryCount >= MAX_RETRIES) {
      return;
    }
    let timeout = Math.pow(2, retryCount) * 1000;
    if (timeout > MAX_DELAY) {
      timeout = MAX_DELAY;
    }
    setTimeout(() => setRetryCount(retryCount + 1), timeout);
  };

  useEffect(() => {
    if (imgRef?.current) {
      imgRef.current.onload = handleLoad;
      imgRef.current.onerror = handleError;
      imgRef.current.src = src;
    }

    return () => {
      if (imgRef?.current) {
        imgRef.current.onload = null;
        imgRef.current.onerror = null;
      }
    };
  }, [src, retryCount]);

  return { imgRef, retryCount, error };
};

export default useImageLoadRetry;
