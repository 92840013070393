import { NotFoundTemplate } from 'app/pages/notFound'; /* SUS-1234 changes */
import { useAuth } from 'lib/context'; /* SUS-1234 changes */
import { checkIfVideoRequestAccessible } from 'lib/utils/automotiveRolePermissionChecks'; /* SUS-1234 changes */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Review } from './Review';

export const Main = () => {
  /* SUS-1234 changes changes video-requests/:videoRequestId route are accessible to CDS account with SERVICE_MANAGER and SERVICE_ADVISOR role only*/
  const { userData } = useAuth();
  const isVideoRequestAccessible = checkIfVideoRequestAccessible(userData);
  /******/

  return (
    <Switch>
      <Route path='/video-requests' component={List} exact={true} />
      {/* SUS-1234 changes */}
      {isVideoRequestAccessible && (
        <Route
          path='/video-requests/:videoRequestId'
          component={Review}
          exact={true}
        />
      )}
      {/* SUS-1234 changes */}
      <Route path='*' component={NotFoundTemplate} />
    </Switch>
  );
};
