import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { snippetsKeys } from './queryKeys';
import { QuerySnippetsParams } from './types';

export const deleteSnippets = async ({ data }: { data: string[] }) => {
  const response = await EXPRESS_API.delete(`/snippets`, { data });
  return response.data;
};

export const useDeleteSnippetsMutation = (
  selectedSnippets: string[],
  queryParams: QuerySnippetsParams
) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(() => deleteSnippets({ data: selectedSnippets }), {
    onError: err => showError(err),
    onSuccess: async () => {
      successToast({
        title: `Template${selectedSnippets.length > 1 ? 's' : ''} successfully deleted!`,
      });
      queryClient.invalidateQueries(snippetsKeys.snippets(queryParams));
    },
  });
};
