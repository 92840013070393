import { getCodirectUrl } from 'lib/api/codirectApi';
import { LoadingIndicator } from 'lib/components';
import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  margin: 64px 0px 30px 208px;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  height: 100vh;
`;
export function Main() {
  const [loading, setLoading] = React.useState(true);
  const [codirectUrl, setCodirectUrl] = React.useState('');
  const iRef = React.useRef<HTMLIFrameElement>(null);
  React.useEffect(() => {
    getCodirectUrl()
      .then(result => {
        if (result && result.url) {
          if (iRef && iRef.current) {
            iRef.current.setAttribute('allow-same-origin', '');
          }
          setCodirectUrl(result.url);
          //setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(ex => {
        console.log(ex);
        setLoading(false);
      });
  }, []);
  return (
    <Container>
      {loading && <LoadingIndicator isLoading={loading} text={'Loading...'} />}
      {codirectUrl !== '' && (
        <iframe
          title="codirect url"
          frameBorder="0"
          onLoad={() => setLoading(false)}
          style={{ margin: 'auto', height: '100%', width: '100%' }}
          src={codirectUrl}
          ref={iRef}
        ></iframe>
      )}
      {!loading && codirectUrl == '' && (
        <h2 style={{ margin: 'auto' }}>Unable to connect.</h2>
      )}
    </Container>
  );
}
