import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { errorToast } from '../toasts/error';
import { successToast } from '../toasts/success';
import { removeFolderAccess } from 'lib/api/folderAccess/removeFolderAccess';
import { Button } from 'react-covideo-common';

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  refreshFolderList: () => void;
  folderName: string;
  folderId: string | number;
};

export const ModalLeaveFolder = ({
  handleModalClose,
  refreshFolderList,
  folderName,
  folderId,
}: Props) => {
  const headerText = `Leave "${folderName}" Folder?`;

  const handleLeaveFolder = async () => {
    let errorMessage = '';
    try {
      await removeFolderAccess(folderId);
      await refreshFolderList();
    } catch (error) {
      errorMessage = `Error in leaving folder.`;
    }
    handleModalClose(true);

    if (errorMessage) {
      errorToast({ title: errorMessage });
      return;
    }
    successToast({
      title: `Folder left successfully.`,
    });
  };

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose()}
      headerMargin='0'
      style={{
        content: {
          padding: '32px',
          width: '597px',
        },
      }}
    >
      <Footer>
        <Button
          text='Stay'
          variant='white'
          onClick={() => {
            handleModalClose(true);
          }}
        />
        <Button
          text='Yes, Leave'
          variant='destructive'
          onClick={() => {
            handleLeaveFolder();
          }}
        />
      </Footer>
    </NewModal>
  );
};
