import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

const createDropFromVideo = async ({
  videoId,
  boardId,
}: {
  videoId: number;
  boardId: string;
}) => {
  return (
    await EXPRESS_API.post(`/droplr/drop/${videoId}`, {
      boardId,
    })
  ).data;
};

export const useCreateDropFromVideo = () => {
  return useMutation(createDropFromVideo, {
    onError: () => {
      errorToast({
        title: 'An error occured while uploading drop. Please try again.',
      });
    },
    onSuccess: () => {
      successToast({ title: `Successfully added video!` });
    },
  });
};
