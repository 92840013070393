import React from 'react';
import { RadioGroup, RadioLabel } from './style';
import { FREQUENCY_DETAIL, FREQUENCY_TYPE } from './constants';

import { RadioInput } from 'lib/components';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues } from '../types';

export const DailyFrequency = () => {
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();

  return (
    <RadioGroup role='group' aria-labelledby='type-group'>
      <RadioLabel>
        <RadioInput
          name={FREQUENCY_TYPE.DAILY}
          value={FREQUENCY_DETAIL.EVERYDAY}
          checked={values.recurrings.frequency.includes(
            FREQUENCY_DETAIL.EVERYDAY
          )}
          onClick={() =>
            setFieldValue(
              'recurrings.frequency',
              `${FREQUENCY_TYPE.DAILY};${FREQUENCY_DETAIL.EVERYDAY}`
            )
          }
          onChange={() =>
            setFieldValue(
              'recurrings.frequency',
              `${FREQUENCY_TYPE.DAILY};${FREQUENCY_DETAIL.EVERYDAY}`
            )
          }
        />
        <span>Every Day</span>
      </RadioLabel>
      <RadioLabel>
        <RadioInput
          name={FREQUENCY_TYPE.DAILY}
          value={FREQUENCY_DETAIL.WEEKDAYS}
          checked={values.recurrings.frequency.includes(
            FREQUENCY_DETAIL.WEEKDAYS
          )}
          onClick={() =>
            setFieldValue(
              'recurrings.frequency',
              `${FREQUENCY_TYPE.DAILY};${FREQUENCY_DETAIL.WEEKDAYS}`
            )
          }
          onChange={() =>
            setFieldValue(
              'recurrings.frequency',
              `${FREQUENCY_TYPE.DAILY};${FREQUENCY_DETAIL.WEEKDAYS}`
            )
          }
        />
        <span>Weekdays</span>
      </RadioLabel>
    </RadioGroup>
  );
};
