import get from 'lodash/get';
import { NONE } from './Constants';

export const getColValue = (
  colMapping: { [key: string]: string },
  data: string[],
  field: string
) => {
  const columnValue = get(colMapping, field, '');
  if (!columnValue || columnValue === NONE) {
    return '';
  }
  return data[parseInt(columnValue, 10)]?.toString().trim() || '';
};
