import React, { useState } from 'react';
import { Dropdown, NewModal } from 'lib/components';
import { Form, Formik, useField } from 'formik';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { Gap, TextArea } from '../styles/modal.styles';
import { useAuth } from 'lib/context';
import { useGetUsersCustomerQuery } from 'lib/api/users/getUsersCustomers';
import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';
import * as Yup from 'yup';
import {
  IReassignConversationParams,
  useReassignConversationMutation,
} from 'lib/api/conversations/reassignConversation';
import { Button } from 'react-covideo-common';
interface TextInputProps {
  name: string;
  id?: string;
}

interface IRepassingConversationModal {
  onModalClose: () => void;
  singleConversation: ISingleConversation;
}

interface IValues {
  userId: number | null;
  note: string;
}

const reassignValidationSchema = Yup.object({
  userId: Yup.number().required(),
});

const TextAreaInput = ({ ...props }: TextInputProps) => {
  const [field] = useField(props);
  return <TextArea {...field} {...props} />;
};

export const ReassignConversationModal = ({
  onModalClose,
  singleConversation,
}: IRepassingConversationModal) => {
  const {
    userData: { customerId },
  } = useAuth();

  const INITIAL_VALUE = singleConversation.userId;
  const { mutateAsync } = useReassignConversationMutation(onModalClose);

  const [selectedConversation, setSelectedConversation] = useState<
    number | null
  >(INITIAL_VALUE);

  const { data, isLoading } = useGetUsersCustomerQuery({ customerId });

  if (isLoading) {
    return null;
  }

  const filteredItems = data?.map(user => {
    return {
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    };
  });

  const dropdownValue = filteredItems?.find((o: any) => {
    return o.value === selectedConversation;
  });

  const onSubmitHandler = async (values: IValues) => {
    if (values.userId === null) {
      return;
    }
    mutateAsync({
      payload: values,
      conversationId: singleConversation.conversationId,
    } as IReassignConversationParams);
  };

  return (
    <NewModal
      headerText={
        singleConversation.userId === null
          ? 'Assign Conversation'
          : 'Reassign Conversation'
      }
      closeModal={onModalClose}
      style={{
        content: {
          padding: '32px',
          maxWidth: '582px',
        },
      }}
    >
      <Formik
        validationSchema={reassignValidationSchema}
        initialValues={{
          userId: INITIAL_VALUE,
          note: '',
        }}
        onSubmit={onSubmitHandler}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form>
            <Gap direction='column' gap='16px'>
              <Gap direction='column' gap='8px'>
                <ParagraphSmall as='label' color='rgba(78, 84, 97, 1)'>
                  Select a user to assign conversation to
                </ParagraphSmall>
                <Dropdown
                  error={!!(errors['userId'] && touched['userId'])}
                  options={filteredItems}
                  value={dropdownValue}
                  onChange={(option: { value: number }) => {
                    setSelectedConversation(option.value);
                    setFieldValue('userId', option.value);
                  }}
                  creatable={false}
                  name='userId'
                  placeholder='Select users'
                />
              </Gap>
              <Gap direction='column' gap='8px'>
                <ParagraphSmall as='label' color='rgba(78, 84, 97, 1)'>
                  Note
                </ParagraphSmall>
                <TextAreaInput name='note' />
              </Gap>
              <Gap direction='row-reverse'>
                <Button
                  text='Assign Conversation'
                  type='submit'
                  disabled={values['userId'] === null}
                />
              </Gap>
            </Gap>
          </Form>
        )}
      </Formik>
    </NewModal>
  );
};
