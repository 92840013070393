import { AccessRole } from 'lib/const';
import { CUSTOM_REPORT_STEPS, IRecipient, STEPS } from './types';

export const getSteps = ({
  isCompanyAdmin,
  isResellerAdmin,
}: {
  isCompanyAdmin: boolean;
  isResellerAdmin: boolean;
}): STEPS => {
  const steps = [
    ...(isCompanyAdmin || isResellerAdmin
      ? [
          {
            value: CUSTOM_REPORT_STEPS.USERS,
            label: 'Users',
            errorName: 'users',
          },
        ]
      : []),
    { value: CUSTOM_REPORT_STEPS.DATA, label: 'Data', errorName: 'reports' },
    {
      value: CUSTOM_REPORT_STEPS.TIMEFRAME,
      label: 'Timeframe',
      errorName: 'isTimeFrameStep',
    },
    {
      value: CUSTOM_REPORT_STEPS.BASICS,
      label: 'Finalize',
      errorName: 'title',
    },
  ];

  return steps;
};

export const timeRangeConstants = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last_7_days',
  LAST_MONTH: 'last_month',
  MONTH_TO_DATE: 'month_to_date',
  LAST_7_DAYS: 'previous_7_days',
  LAST_30_DAYS: 'last_30_days',
  YEAR_TO_DATE: 'year_to_date',
  CUSTOM: 'custom',
};

export const DAILY_TIME_RANGE_OPTIONS = [
  {
    value: timeRangeConstants.TODAY,
    label: 'Today',
  },
  {
    value: timeRangeConstants.YESTERDAY,
    label: 'Yesterday',
  },
];

export const MONTHLY_TIME_RANGE_OPTIONS = [
  {
    value: timeRangeConstants.LAST_MONTH,
    label: 'Last Month',
  },
  {
    value: timeRangeConstants.MONTH_TO_DATE,
    label: 'Month to Date',
  },
  {
    value: timeRangeConstants.LAST_30_DAYS,
    label: 'Last 30 days',
  },
];

export const WEEKLY_TIME_RANGE_OPTIONS = [
  {
    value: timeRangeConstants.LAST_WEEK,
    label: 'Last Week',
  },

  {
    value: timeRangeConstants.LAST_7_DAYS,
    label: 'Last 7 days',
  },
];

export const YEARLY_TIME_RANGE_OPTIONS = [
  {
    value: timeRangeConstants.YEAR_TO_DATE,
    label: 'Year to Date',
  },
];

export const CUSTOM_TIME_RANGE_OPTIONS = [
  {
    value: timeRangeConstants.CUSTOM,
    label: 'Custom...',
  },
];

export const filterTimeframe = <T extends { [index: string]: U }, U>(
  enumeration: T,
  valuesToRemove: U[]
): Partial<T> => {
  return (Object.keys(enumeration) as Array<keyof T>)
    .filter(key => !valuesToRemove.includes(enumeration[key]))
    .reduce((acc, key) => {
      acc[key] = enumeration[key];
      return acc;
    }, {} as Partial<T>);
};

export const RecipientTypeALL = '0';
export const RecipientTypeOptions = [
  {
    value: RecipientTypeALL,
    label: 'All Users',
  },
  {
    value: AccessRole.ADMIN,
    label: 'All Managers',
  },
  {
    value: AccessRole.SUPERVISOR,
    label: 'All Supervisors',
  },
  {
    value: AccessRole.USER,
    label: 'All Standard Users',
  },
];

export const getTheAccessRole = (access?: String) => {
  if (!access) {
    return '';
  }

  switch (access) {
    case AccessRole.ADMIN:
      return 'Manager';
    case AccessRole.SUPERVISOR:
      return 'Supervisor';
    case AccessRole.USER:
    default:
      return 'Standard';
  }
};

export const getAdditionalRecipients = (receivers: IRecipient[]) =>
  receivers.filter(receiver => !receiver.id);

export const getAdditionalRecipientEmails = (receivers: IRecipient[]) =>
  getAdditionalRecipients(receivers).map(receiver => receiver.email);

export const getUserRecipients = (receivers: IRecipient[]) =>
  receivers.filter(receiver => !!receiver.id);

export const checkDuplicatesIn = (
  userRecipients: IRecipient[],
  additionalRecipients: string[]
) => {
  const userRecipientsSet = new Set(userRecipients.map(obj => obj.email));
  return !!additionalRecipients.find(email => userRecipientsSet.has(email));
};
