import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdSave } from 'react-icons/md';
import { CustomerStatistics, SuperAdminReseller } from 'lib/context';
import { useHistory } from 'react-router';
import { TABS } from '../main/Details';
import { CardType, StatsCard } from '../../customers/components/StatsCard';
import { SCREEN_VALUES } from 'lib/const/SuperAdminConstants';
import { Button } from 'react-covideo-common';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 32px;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StatsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

const Heading = styled.div`
  font-family: 'Work Sans';
  margin: 0;
  text-align: left;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  line-break: anywhere;
  overflow: hidden;
  height: 40px;
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
`;

const RightHeaderWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

type Props = {
  reseller: SuperAdminReseller | undefined;
  onSave: () => void;
  disableSaveButton: boolean;
  isEditView: boolean;
  activeTab: number;
  statistics: CustomerStatistics;
  loading: boolean;
};

export const ResellerDetailsHeader = ({
  reseller,
  onSave,
  disableSaveButton,
  isEditView,
  activeTab,
  statistics,
  loading,
}: Props) => {
  const history = useHistory();

  return (
    <HeaderWrapper>
      <LeftHeaderWrapper>
        <Heading color={theme.palette.gray100}>
          {isEditView ? reseller?.resellerName : 'New Reseller'}
        </Heading>
      </LeftHeaderWrapper>
      <RightHeaderWrapper>
        {isEditView && activeTab === TABS.CUSTOMERS && (
          <StatsWrapper>
            <StatsCard
              count={!loading && statistics.active ? statistics.active : 0}
              text={'active customers'}
            />
            <StatsCard
              count={!loading && statistics.inActive ? statistics.inActive : 0}
              text={'inactive customers'}
              cardType={CardType.SECONDARY}
            />
          </StatsWrapper>
        )}
        {isEditView && (
          <Button
            text={
              activeTab !== TABS.CUSTOMERS ? 'Save changes' : 'New Customer'
            }
            disabled={activeTab === TABS.CUSTOMERS ? false : disableSaveButton}
            onClick={() => {
              activeTab === TABS.CUSTOMERS
                ? history.push(
                    `/admin/customers/create/${SCREEN_VALUES.RESELLERS}/${reseller?.resellerId}`
                  )
                : onSave();
            }}
            icon={<MdSave color={theme.palette.white || '#fff'} />}
          />
        )}
        {!isEditView && (
          <ButtonContainer>
            <Button
              variant='secondary'
              text={'Cancel'}
              onClick={() => history.push(`/admin/resellers`)}
            />
            <Button
              icon={<MdSave />}
              text={'Save'}
              disabled={disableSaveButton}
              onClick={() => {
                onSave();
              }}
            />
          </ButtonContainer>
        )}
      </RightHeaderWrapper>
    </HeaderWrapper>
  );
};
