import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { multilocationKeys } from './multilocationsKeys';
import { useParams } from 'react-router-dom';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IRemoveChildUser {
  userId: string | number;
}

const removeChildUser = async (params: IRemoveChildUser): Promise<{}> => {
  const { userId } = params;

  return (await EXPRESS_API.delete(`superadmin/multilocation/${userId}/user`))
    .data;
};

export const useRemoveChildUserMutation = (closeModalHandler: () => void) => {
  const queryClient = useQueryClient();
  const { customerId } = useParams() as { customerId: string };
  return useMutation(removeChildUser, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(
        multilocationKeys.single(`${customerId}`)
      );
      successToast({ title: 'You have successfully removed user!' });
      closeModalHandler();
    },
  });
};
