import { useContactUsModal } from 'lib/context';
import React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ContactUs = () => {
  const { setShowContactUsModal } = useContactUsModal();
  return (
    <Container>
      <div>
        To manage or upgrade your subscription, please contact Account
        Management.
      </div>
      <br />
      <div>
        <Button
          variant='secondary'
          text='Contact Us'
          onClick={() => setShowContactUsModal(true)}
        />
      </div>
    </Container>
  );
};
