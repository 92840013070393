import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { User } from './User';
// import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path="/sms/users" component={User} exact={true} />
      {/* <Route path="/users/:userId" component={Details} /> */}
    </Switch>
  );
};
