import { Gap } from 'lib/components/styles/layout';
import React from 'react';

export const SelectableMainWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Gap
      width='calc(33.33% - 12px)'
      style={{
        padding: 16,
        borderRight: '1px solid #EEEFF2',
        overflow: 'hidden',
        boxSizing: 'border-box',
        height: '100%',
      }}
      alignItems='flex-start'
      justifyContent='flex-start'
    >
      {children}
    </Gap>
  );
};
