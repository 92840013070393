import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { freemiumOnboardingKeys } from './freemiumOnboardingKeys';
import { FreemiumOnboardingData } from './types';

const getFreemiumOnboardingData = async (): Promise<FreemiumOnboardingData> => {
  const response = await EXPRESS_API.get(`/freemium-onboarding`);
  return response.data;
};

export const useFreemiumOnboardingQuery = () => {
  return useQuery(
    freemiumOnboardingKeys.freemium_onboarding(),
    getFreemiumOnboardingData
  );
};
