import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { multilocationKeys } from './multilocationsKeys';

export interface IFreeUsers {
  users: User[];
  count: number;
}

export interface User {
  id: number;
  customerId: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  parentUserId: number;
  parentCustomerId: number;
  verified: number;
}

interface IParams {
  search: string;
  customerId: number | null;
}

const getFreeUsers = async ({
  search,
  customerId,
}: IParams): Promise<IFreeUsers> => {
  return (
    await EXPRESS_API.get(`superadmin/multilocation/${customerId}/free-users`, {
      params: {
        search,
        start: 0,
        limit: 50,
      },
    })
  ).data as IFreeUsers;
};

export const useFreeUsersQuery = ({ search, customerId }: IParams) => {
  return useQuery(
    multilocationKeys.users_free(search, `${customerId}` || ''),
    () => getFreeUsers({ search, customerId })
  );
};
