import React from 'react';
import { priceFormatter } from 'lib/utils/functions';
import { QuoteItem } from 'lib/api/repairOrders/types';

enum TableFields {
  total = 'total',
  approved = 'approved',
}

type Props = {
  quoteItems: QuoteItem[];
  field: string;
};
export const TableCellTotalApproved = ({ quoteItems = [], field }: Props) => {
  const sumAll = field === TableFields.total;
  const quotesToSum = quoteItems.filter(
    (quoteItem: QuoteItem) => sumAll || quoteItem.approved
  );
  const price = quotesToSum
    ? quotesToSum.reduce(
        (sum: number, q: QuoteItem) => sum + (q.price ? Number(q.price) : 0),
        0
      )
    : 0;
  return (
    <>
      {priceFormatter(price)} ({quotesToSum.length})
    </>
  );
};
