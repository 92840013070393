import React from 'react';
import { useField } from 'formik';
import { Label } from './Label';
import { EnhancedColorPicker } from '../../../components';
import { calculateHexAlpha } from 'lib/utils/annotations';
import { ColorResult } from 'react-color';

interface FormColorPickerProps {
  label: string;
  name: string;
  width?: string;
  menuPlacement?: string;
  defaultColor?: string;
  manual?: boolean;
  newValue?: string;
  setNewValue?: (value: any, shouldValidate?: boolean | undefined) => void;
  isClearable?: boolean;
  isDisabled?: boolean;
  colorPickerClassName?: string;
  isAutomotive?: boolean;
}

export const FormColorPicker = ({
  label,
  name,
  width = '100%',
  menuPlacement = 'top',
  defaultColor = '',
  manual = false,
  newValue,
  setNewValue,
  isClearable = true,
  isDisabled,
  colorPickerClassName,
  isAutomotive,
}: FormColorPickerProps) => {
  const [, meta, helpers] = useField(name);
  let { value } = meta;
  let { setValue } = helpers;

  React.useEffect(() => {
    if (manual) setValue(newValue);
  }, [newValue]);

  const handleColorChange = (color: ColorResult) => {
    if (isDisabled) return;
    setValue(calculateHexAlpha(color).toUpperCase());
    if (manual && setNewValue)
      setNewValue(calculateHexAlpha(color).toUpperCase());
  };

  return (
    <div style={{ width: width, position: 'relative' }}>
      <Label isAutomotive={isAutomotive} htmlFor={name}>
        <div>{label}</div>
      </Label>
      <EnhancedColorPicker
        className={colorPickerClassName}
        handleColorChange={handleColorChange}
        playerColor={value || ''}
        width="160"
        menuPlacement={menuPlacement}
        isClearable={isClearable}
        onClearColor={!isClearable ? () => {} : () => setValue(defaultColor)}
      />
    </div>
  );
};
