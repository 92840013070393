import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { guidesKeyes } from './guidesKeyes';
import { useAuth } from 'lib/context';
import { EXPRESS_API } from 'configs/api/expressApi';
import { WHITELABEL_NAME } from '../whitelabel/getWhiteLabel';

const getNewGuidesCount = async (): Promise<{ count: number }> => {
  return (await EXPRESS_API.get(`guides/count-new`)).data;
};

export const useGetNewGuidesCount = () => {
  const { showError } = useToastError();
  const { whitelabel } = useAuth();
  return useQuery(guidesKeyes.new_count(), () => getNewGuidesCount(), {
    onError: showError,
    refetchOnMount: true,
    enabled: whitelabel.name.includes(WHITELABEL_NAME.COVIDEO),
  });
};
