import * as React from 'react';
import styled from 'styled-components/macro';
import { Field, FieldProps } from 'formik';
import ReactQuill, { Quill } from 'react-quill';
import { useAuth } from 'lib/context';
import { random } from 'lodash';
import { useS3UploadMutation } from 'lib/api/aws/useS3UploadMutation';
import 'react-quill/dist/quill.snow.css';
import { EXPRESS_API } from 'configs/api/expressApi';

interface FormProps {
  isSubmitting?: boolean;
  setSignatureData: (text: string) => void;
  setFieldValue: (field: string, value: string) => void;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .ql-toolbar {
    border-radius: 5px 5px 0px 0px;
  }
  .ql-container {
    border-radius: 0px 0px 5px 5px;
  }
  .covideo-signature-class {
    margin: 0;
    font-size: 20px;
  }
`;

var BaseImageFormat = Quill.import('formats/image');
let Block = Quill.import('blots/block');

class BlockFormat extends Block {
  static create(value: object) {
    let node = super.create(value);
    node.setAttribute('class', 'covideo-signature-class');
    return node;
  }
}
BlockFormat.tagName = 'div';

class ImageFormat extends BaseImageFormat {
  static create(value: any) {
    let node = super.create(value);

    if (value.url !== undefined) node.setAttribute('src', value.url);

    node.setAttribute('width', value.width);

    return node;
  }

  static value(node: any) {
    return {
      width: node.getAttribute('width'),
      url: node.getAttribute('src'),
    };
  }
}

const image_resize_module = require('@looop/quill-image-resize-module-react');

Quill.register('modules/imageResize', image_resize_module.default);
Quill.register(ImageFormat, true);
Quill.register(BlockFormat, true);

export const SignatureForm = (props: FormProps) => {
  const { setSignatureData, setFieldValue } = props;
  const {
    userData: { userId },
  } = useAuth();

  const { mutateAsync: uploadVideo } = useS3UploadMutation();

  const quillRef = React.useRef(null);

  const imagePresignedURL = async (file: File): Promise<any> => {
    const response = await EXPRESS_API.post('/aws/signedurl/user-images', {
      fileName: userId + '_' + random(100000) + '_' + file.name,
      mimeType: file.type,
      folder: '',
    });

    return response.data;
  };

  const uploadFile = async (file: File): Promise<any> => {
    const presignedUrl = await imagePresignedURL(file);
    if (presignedUrl && presignedUrl.uploadUrl) {
      let image_url = presignedUrl.downloadUrl;

      await uploadVideo({
        url: presignedUrl.uploadUrl,
        file,
      });

      return image_url;
    }
  };

  // added function for handling image upload on Text Editor
  const imageHandler = async () => {
    let quill: any = quillRef.current;
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute(
      'accept',
      'image/gif, image/jpg, image/jpeg, image/png, image/bmp'
    );
    input.click();
    input.onchange = async () => {
      if (input.files && input.files?.length > 0) {
        const fileName = input.files[0].name.replace(/ /g, '_');
        const renamedFile = new File([input.files[0]], fileName);
        let image_url = await uploadFile(renamedFile);
        if (image_url === '') return;
        let quillEditor = quill.getEditor();
        const range = quillEditor.getSelection()?.index || null;
        quillEditor.insertEmbed(range, 'image', image_url);
      }
    };
  };

  const editor_modules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
        ],
        ['link', 'image'],
        [{ size: [] }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ['clean'],
      ],
      handlers: {
        image: imageHandler, //call imageHandler function when image is uploading on Text editor
      },
    },
    history: {
      delay: 500,
      maxStack: 150,
      userOnly: true,
    },
    imageResize: {
      modules: ['Resize', 'DisplaySize'],
    },
  };

  const editor_formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'size',
    'font',
    'background',
    'color',
    'align',
    'image',
  ];

  return React.useMemo(
    () => (
      <>
        {/* Signature */}
        <Row>
          <Field name='signature'>
            {({ field }: FieldProps) => {
              const { value } = field;
              return (
                <ReactQuill
                  ref={quillRef}
                  theme='snow'
                  style={{ width: '100%', maxWidth: '800px' }}
                  modules={editor_modules}
                  formats={editor_formats}
                  defaultValue={value}
                  onChange={newValue => {
                    setFieldValue('signature', newValue);
                    setSignatureData(newValue);
                  }}
                />
              );
            }}
          </Field>
        </Row>
      </>
    ),
    []
  );
};
