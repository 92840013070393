import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { FolderAccessResponse } from './types';

const getFolderAccess = async (
  folderId: number
): Promise<FolderAccessResponse[]> => {
  if (folderId <= 0) {
    return [] as FolderAccessResponse[];
  }

  const response = await EXPRESS_API.get(`/folders/${folderId}/access`);
  return response.data;
};
export const useGetFolderAccess = (folderId: number) => {
  return useQuery(['folder-access', folderId], () => getFolderAccess(folderId));
};
