import React from 'react';
import styled from 'styled-components/macro';

const CustomTooltip = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 #1d1e2414;
  border: solid 1px #f7f8fa;
  background-color: #fff;
`;

const CustomTooltipHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
`;

const CustomTooltipTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  margin-bottom: 4px;
`;

export const TooltipChart = ({
  active,
  payload,
  displayKey,
  headerKey,
  isAreaChart = false,
}: any) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0].payload &&
    !isAreaChart
  ) {
    const data: any = payload[0].payload;
    return (
      <CustomTooltip>
        <CustomTooltipHeader>{data[headerKey]}</CustomTooltipHeader>
        <CustomTooltipTitle>{`Sent: ${data[displayKey]}`}</CustomTooltipTitle>
      </CustomTooltip>
    );
  } else if (
    active &&
    payload &&
    payload.length &&
    payload[0].payload &&
    payload[1].payload &&
    isAreaChart
  ) {
    const data: any = payload[0].payload;
    const data2: any = payload[1].payload;
    return (
      <CustomTooltip>
        <CustomTooltipHeader>{data[headerKey]}</CustomTooltipHeader>
        <CustomTooltipTitle>{`Received: ${data.receivedCount}`}</CustomTooltipTitle>
        <CustomTooltipTitle>{`Sent: ${data2.sentCount}`}</CustomTooltipTitle>
      </CustomTooltip>
    );
  }

  return null;
};
