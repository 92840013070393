import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { useMutation, useQueryClient } from 'react-query';
import { videoKeys } from './videosKeys';
import { successToast } from 'lib/components/toasts/success';
import { VideoReactionType } from 'lib/const/VideoReactionsDictionary';

export type UpdateVideoReactionsParams = {
  videoId: string;
  reactionId: string;
  type?: VideoReactionType;
};

export const deleteVideoReaction = async ({
  videoId,
  reactionId,
}: UpdateVideoReactionsParams) => {
  await EXPRESS_API.delete(`/videos/${videoId}/reactions/${reactionId}`);
  return { reactionId };
};

export const useRemoveVideoReactionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteVideoReaction, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(videoKeys.reactions(variables.videoId));
      if (variables.type) {
        successToast({
          title: `${variables.type === VideoReactionType.COMMENT ? 'Comment' : 'Reaction'} removed successfully.`,
        });
      }
    },
    onError: () => {
      errorToast({
        title: 'Failed to remove video reaction. Please try again later.',
      });
    },
  });
};
