import { useGetFolders } from 'lib/api/folders/getFolders';
import { FolderDropdown } from 'lib/components';
import { useAuth } from 'lib/context';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Option } from 'lib/components/dropdown/Dropdown';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { ALL_FOLDERS } from 'lib/const/Folders';
import {
  FOLDER_GROUP_LABEL,
  FOLDER_GROUP_VALUE,
} from 'app/pages/library/videos/videoList/constants';

interface Props {
  value: Option;
  setValue: React.Dispatch<React.SetStateAction<Option>> | undefined;
  showSharedWithMeFolders?: boolean;
  showAllFolderOption?: boolean;
  showLabel?: boolean;
  width?: string;
}

const DropdownWrapper = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: center;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin-bottom: 8px;
`;

const VideoUploadFolderDropdown = ({
  value,
  setValue,
  showSharedWithMeFolders = true,
  showLabel = true,
  width = '100%',
  showAllFolderOption = true,
}: Props) => {
  const { userData } = useAuth();
  const { data: folders = [], refetch: getLatestFolders } = useGetFolders({
    getShared: true,
  });
  const isFolderOrganizationEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.FOLDER_ORGANIZATION
  );

  useEffect(() => {
    getLatestFolders();
  }, []);

  let folderOptions: {
    value: number | string;
    label: string;
    isFolderShared?: boolean;
    access: boolean;
  }[] = [];

  if (isFolderOrganizationEnabled) {
    let ownedFolders =
      folders
        ?.filter(
          folder =>
            !!!folder.hasOwnProperty('access') &&
            folder.folderId !== ALL_FOLDERS.value
        )
        .map(folder => ({
          value: folder.folderId,
          label: folder.name,
          isFolderShared: !!folder.isFolderShared,
          access: !!folder.hasOwnProperty('access'),
        })) || [];
    let sharedFolders =
      folders
        ?.filter(
          folder =>
            !!folder.hasOwnProperty('access') && folder.access === 'edit'
        )
        .map(folder => ({
          value: folder.folderId,
          label: folder.name,
          isFolderShared: !!folder.isFolderShared,
          access: !!folder.hasOwnProperty('access'),
          sharedBy: folder.sharedBy,
        })) || [];
    if (showAllFolderOption) {
      folderOptions = [
        {
          value: ALL_FOLDERS.value,
          label: ALL_FOLDERS.label,
          isFolderShared: false,
          access: false,
        },
      ];
    }
    folderOptions = folderOptions.concat([
      {
        value: FOLDER_GROUP_VALUE.MY_FOLDERS,
        label: FOLDER_GROUP_LABEL.MY_FOLDERS,
        isFolderShared: false,
        access: false,
      },
    ]);

    if (ownedFolders.length) {
      folderOptions = folderOptions.concat(ownedFolders);
    }
    if (showSharedWithMeFolders) {
      if (sharedFolders.length) {
        folderOptions = folderOptions.concat(
          [
            {
              value: FOLDER_GROUP_VALUE.SHARED_WITH_ME,
              label: FOLDER_GROUP_LABEL.SHARED_WITH_ME,
              isFolderShared: false,
              access: false,
            },
          ],
          sharedFolders
        );
      }
    }
  }

  useEffect(() => {
    if (folders && !!folders.length && setValue) {
      setValue({
        value: folders[0].folderId.toString(),
        label: folders[0].name,
      });
    }
  }, [folders]);

  return (
    <DropdownWrapper width={width}>
      {showLabel && <Label>Select Folder</Label>}
      <FolderDropdown
        value={value}
        onChange={option => {
          setValue && setValue(option);
        }}
        options={[...folderOptions]}
      />
    </DropdownWrapper>
  );
};

export default VideoUploadFolderDropdown;
