import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';
import { useEffect, useState } from 'react';
import { LeadItem } from 'lib/context';
import { MdSave } from 'react-icons/md';
import { AutomotiveInput } from 'lib/components/inputs';
import { Button } from 'react-covideo-common';

const Content = styled.div`
  display: block;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const Label = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  color: ${theme.palette.gray80};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 24px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const ErrorMessage = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.red80};
  height: 20px;
  margin-top: 24px;
`;

type Props = {
  handleModalClose: () => void;
  handleSubmit: (data: Partial<LeadItem>) => void;
};

export const ModalCreateLead = ({ handleModalClose, handleSubmit }: Props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [valid, setValid] = useState(false);

  useEffect(() => {
    validateLead();
  }, [firstName, lastName, email, phone]);

  const validateLead = () => {
    const emailRegex = /^[0-9a-z\\._-]+@[0-9a-z-]+\..+$/i;
    const phoneRegex = /^[0-9()\-.\s]+$/i;
    const emailValid = emailRegex.test(email);
    const emailEmpty = email.trim() === '';
    const phoneValid = phoneRegex.test(phone);
    const phoneEmpty = phone.trim() === '';
    // both need to be valid and at least one needs to be not empty
    const leadValid =
      (emailEmpty || emailValid) &&
      (phoneEmpty || phoneValid) &&
      ((!emailEmpty && emailValid) || (!phoneEmpty && phoneValid));
    setValid(leadValid);
  };

  return (
    <NewModal headerText='New Customer' closeModal={handleModalClose}>
      <Content>
        <Row>
          <InputWrapper>
            <Label>First Name</Label>
            <AutomotiveInput
              width='100%'
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Label>Last Name</Label>
            <AutomotiveInput
              width='100%'
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
            />
          </InputWrapper>
        </Row>
        <InputWrapper>
          <Label>Email Address</Label>
          <AutomotiveInput
            margin='0 0 24px 0'
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Phone Number</Label>
          <AutomotiveInput
            value={phone}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPhone(e.target.value)
            }
          />
        </InputWrapper>
        <ErrorMessage>
          {!valid &&
            'In order to create a customer, please enter email and/or phone number'}
        </ErrorMessage>
        <ButtonsWrapper>
          <Button
            text={`Save Customer`}
            variant='primary'
            icon={<MdSave />}
            disabled={!valid}
            onClick={() => handleSubmit({ firstName, lastName, email, phone })}
          />
        </ButtonsWrapper>
      </Content>
    </NewModal>
  );
};
