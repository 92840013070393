import { TextInput } from 'lib/components';
import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';

export const TabWrapper = styled.div<{ display: string }>`
  display: ${({ display }) => display};
`;

export const ModalFullscreen = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: white;
  overflow: hidden;
`;

export const ModalBody = styled.div`
  box-sizing: border-box;
  padding: 32px 0;
  height: calc(100vh - 64px);
  overflow-y: scroll;
`;

export const ModalHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 37px;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
`;

export const HeaderSide = styled.div`
  flex-grow: 1;
  flex-basis: 0;
`;

export const HeaderCenter = styled.div`
  margin: 0;
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: ${theme.palette.coal};
  cursor: pointer;
  span {
    margin-left: 5px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

type LabelProps = {
  margin?: string;
};

export const Label = styled.p<LabelProps>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin: ${({ margin }) => margin || '0 0 8px 0'};
`;

export const HyperlinkText = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
  color: ${theme.palette.blue100};
  margin-left: 36px;
  cursor: pointer;
`;

export const CheckboxInputWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;

export const CheckboxLabel = styled.label`
  cursor: pointer;
  margin-left: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

export const NotifyMeTooltip = styled.div`
  width: 170px;
  line-height: 24px;
`;

type InputProps = {
  borderColor?: string;
  hasError?: boolean;
  disabled?: boolean;
};

export const Input = styled(TextInput)<InputProps>`
  font-family: 'Work Sans';
  border: 1px solid ${props => props.borderColor || theme.palette.gray40};
  &:focus {
    border: 1px solid ${props => props.borderColor || theme.palette.gray40};
    box-shadow: none;
  }
  ${props => {
    if (props.hasError) {
      return `border-color: ${theme.palette.red100} !important;`;
    }
  }}
  ${props => {
    if (props.hasError) {
      return `border-color: ${theme.palette.red100} !important;`;
    }
  }}
  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;
        background: ${theme.palette.primaryBlack5Percent};
        border: none;
      `;
    }
  }};
`;
