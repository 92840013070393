import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewTextInput, DefaultLabel, NewModal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { useIsMutating } from 'react-query';

type Props = {
  disabled?: boolean;
  title?: string;
  whiteButtonText?: string;
  orangeButtonText?: string;
  text?: string;
  text1?: React.ReactNode;
  hasConfirm?: boolean;
  whiteButtonDisabled?: boolean;
  onClickWhiteButton: () => void;
  onClickOrangeButton: () => void;
  handleModalClose: () => void;
  loading?: boolean;
};

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

const Text = styled.p`
  font-size: 16px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 48px;
  flex-wrap: wrap;
  gap: 12px;
`;

const DELETE = 'DELETE';

export const ModalDelete = ({
  title = '',
  text1,
  text = '',
  whiteButtonText = 'Keep video',
  orangeButtonText = 'Delete video',
  hasConfirm = false,
  whiteButtonDisabled = false,
  onClickWhiteButton,
  onClickOrangeButton,
  handleModalClose,
  loading = false,
}: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const [deleteConfirm, setDeleteConfirm] = React.useState('');

  return (
    <NewModal closeModal={handleModalClose} headerText={title}>
      <Content>
        {text1}
        <Text>{text}</Text>
        {hasConfirm && (
          <>
            <DefaultLabel>{`Enter '${DELETE}' to confirm`}</DefaultLabel>
            <NewTextInput
              style={{ marginTop: 4 }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDeleteConfirm(e.target.value)
              }
              value={deleteConfirm}
              placeholder={DELETE}
              autoFocus={true}
            />
          </>
        )}
      </Content>
      <ButtonsWrapper>
        <Button
          variant='red'
          text={whiteButtonText}
          onClick={onClickWhiteButton}
          disabled={
            loading ||
            (hasConfirm ? deleteConfirm !== DELETE : whiteButtonDisabled) ||
            isDisabled
          }
        />
        <Button
          variant='secondary'
          text={orangeButtonText}
          onClick={onClickOrangeButton}
          disabled={loading || isDisabled}
        />
      </ButtonsWrapper>
    </NewModal>
  );
};
