export const EmailStatus = {
  DELIVERED: 'delivered',
  BOUNCE: 'bounce',
  PROCESSED: 'processed',
  OPEN: 'open',
  DROPPED: 'dropped',
  SPAM: 'spamreport',
  DEFFERED: 'deffered',
  VIEWED: 'viewed',
};

export enum RECEIVE_METHOD {
  SMS = 'sms',
  EMAIL = 'email',
  QUICKSHARE = 'quickshare',
}
