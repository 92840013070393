import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { LoadingIndicator } from 'lib/components';
import { ModalAddGroup } from 'lib/components/modal/ModalAddGroup';
import { anyInMap } from 'lib/utils/object';
import { useHistory } from 'react-router';
import { Button } from 'react-covideo-common';

import {
  Table,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  TableContextProvider,
  Search,
} from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { FaChevronRight } from 'react-icons/fa';

import { theme } from 'lib/style';
import { NoGroups } from './components/NoGroups';
import { GroupsHeader } from './components/GroupsHeader';
import { GroupListItem } from 'lib/api';
import { MdAdd } from 'react-icons/md';
import { MainContainer } from '../../index.styled';
import { useQueryGroups } from 'lib/api/group/useQueryGroups';
import { useEffect, useState } from 'react';
import { useCreateGroupMutation } from 'lib/api/group/useCreateGroupMutation';

const MainWrapper = styled.div`
  margin-top: 52px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 24px 32px 32px 32px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    max-width: 1064px;
    margin-top: 96px;
  }
`;

const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

type SelectedGroups = {
  count: number;
  groups: Map<string, GroupListItem | undefined>;
};

const TableCell = styled.div<{ width: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const initialAllSelected: { [key: string]: boolean } = {};

export const GroupList = () => {
  const history = useHistory();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState('');

  const queryParams = {
    search,
    page,
    size,
  };

  const { data, isLoading: loading } = useQueryGroups(queryParams);

  const groups = data?.items ?? [];
  const count = data?.count ?? 0;

  const onSuccessCreateGroupMutation = () => {
    setIsModalAddGroupVisible(false);
  };

  const { mutateAsync: createGroupMutation } = useCreateGroupMutation(
    onSuccessCreateGroupMutation,
    queryParams
  );

  const [isAllSelected, setIsAllSelected] = useState(initialAllSelected);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const themes = useTheme();
  const [selectedGroups, setSelectedGroups] = useState<SelectedGroups>({
    count: 0,
    groups: new Map<string, GroupListItem | undefined>(),
  });

  useEffect(() => {
    const selected = new Map<string, GroupListItem | undefined>(
      selectedGroups.groups
    );
    const isAllSelectedOnPage = isAllSelected[page];
    groups.forEach(g => {
      if (isAllSelectedOnPage) {
        selected.set(g.groupId, g);
      } else {
        selected.set(g.groupId, undefined);
      }
    });

    setSelectedGroups({
      count: Array.from(selected.values()).filter(Boolean).length,
      groups: selected,
    });
  }, [isAllSelected]);

  const selectAll = () => {
    setIsAllSelected((all: any) => ({ ...all, [page]: !all[page] }));
  };
  const [isModalAddGroupVisible, setIsModalAddGroupVisible] = useState(false);

  const onPaginationChange = ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(size);
    setPage(page);
  };

  const checkIndicator = () => {
    return (
      anyInMap(groups, selectedGroups.groups, 'groupId') && !isAllSelected[page]
    );
  };

  const onCreateGroup = async (data: { name: string }) => {
    await createGroupMutation(data.name);
  };

  const onDelete = () => {
    setIsAllSelected(initialAllSelected);
    setSelectedGroups({ count: 0, groups: new Map() });

    setIsHeaderVisible(false);
  };

  const handleSearch = (search: string) => {
    setSearch(search);
  };
  const columnWidths = [24, 130 + 241 - 24, 28 + 537 - 24, 24];
  return (
    <MainContainer>
      <GroupsHeader
        selectedGroups={selectedGroups}
        onDelete={onDelete}
        isAllSelected={isAllSelected[page]}
        checkIndicator={checkIndicator}
        selectAll={selectAll}
        isVisible={isHeaderVisible}
        queryParams={queryParams}
      />
      <MainWrapper>
        {isModalAddGroupVisible && (
          <ModalAddGroup
            title='Add Group'
            text=''
            handleModalClose={() => setIsModalAddGroupVisible(false)}
            handleSubmit={onCreateGroup}
          />
        )}

        <>
          {loading && groups.length == 0 ? (
            <LoadingIndicator
              isLoading={loading}
              text='Loading Groups...'
              height={400}
            />
          ) : !loading && groups.length == 0 && !search ? (
            <>
              <NoGroups onCreateGroup={onCreateGroup} />
            </>
          ) : (
            <Layout>
              <Row style={{ marginBottom: 20 }}>
                <div style={{ width: 300 }}>
                  <Search
                    prevSearch={search}
                    placeholder='Search Groups...'
                    onSearch={(value: string) => handleSearch(value)}
                  />
                </div>
                <Row>
                  <Button
                    variant='primary'
                    onClick={() => setIsModalAddGroupVisible(true)}
                    text='Create'
                    icon={<MdAdd color={themes.colors.white[100]} />}
                  />
                </Row>
              </Row>
              <TableContextProvider
                total={count}
                onChange={onPaginationChange}
                initPage={page}
                initSize={size}
              >
                <Table
                  compact={true}
                  headers={[
                    '',
                    <TableCell width={columnWidths[1]}>Group Name</TableCell>,
                    <TableCell width={columnWidths[2]}>
                      # of Contacts
                    </TableCell>,
                    <TableCell width={columnWidths[3]}></TableCell>,
                  ]}
                  hoverable={false}
                  columnWidths={[60]}
                  rows={groups.map((group: GroupListItem, index: number) => ({
                    key: index,
                    columns: [
                      <CheckboxInput
                        width='24px'
                        blueCheck={true}
                        checked={
                          !!selectedGroups.groups.get(group.groupId) || false
                        }
                        onChange={(e: React.SyntheticEvent) => {
                          e.stopPropagation();
                          let { checked } = e.target as HTMLInputElement;
                          let selected = new Map<
                            string,
                            GroupListItem | undefined
                          >(selectedGroups.groups);
                          let count = selectedGroups.count;
                          if (checked) {
                            selected.set(group.groupId, group);
                            count++;
                          } else {
                            selected.set(group.groupId, undefined);
                            count--;
                          }
                          setSelectedGroups({
                            count: count,
                            groups: selected,
                          });
                        }}
                      />,
                      <TableCell
                        width={columnWidths[1]}
                        onClick={() => {
                          history.push('/contacts/groups/' + group.groupId);
                        }}
                      >
                        {group.name}
                      </TableCell>,
                      <TableCell
                        width={columnWidths[2]}
                        onClick={() => {
                          history.push('/contacts/groups/' + group.groupId);
                        }}
                      >
                        {group.contacts && group.contacts.length}
                      </TableCell>,
                      <TableCell width={columnWidths[3]}>
                        <FaChevronRight
                          size={13}
                          onClick={() => {
                            history.push('/contacts/groups/' + group.groupId);
                          }}
                        />
                      </TableCell>,
                    ],
                    onClick: () => {},
                  }))}
                />
                {groups.length === 0 && (
                  <p
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      margin: '36px 0',
                    }}
                  >
                    No matching results found.{' '}
                  </p>
                )}
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew globalName='group_list' />
                </TableFooter>
              </TableContextProvider>
            </Layout>
          )}
        </>
      </MainWrapper>
    </MainContainer>
  );
};
