import React from 'react';

interface ITick {
  textAnchor: string;
  verticalAnchor: string;
  orientation: string;
  width: number;
  height: number;
  type: string;
  x: number;
  y: number;
  className: string;
  stroke: string;
  fill: string;
  index: number;
  payload: Payload;
  visibleTicksCount: number;
  hasPercentage: boolean;
}

interface Payload {
  coordinate: number;
  value: number;
  offset: number;
  tickCoord: number;
  isShow: boolean;
}

const CustomTick = ({ x, y, width, payload, hasPercentage }: ITick) => {
  return (
    <text
      style={{ fontWeight: 'bold', fill: 'rgba(0, 27, 83, 1)', fontSize: 12 }}
      x={x}
      y={y}
      dx={-width / 2}
      dy={4}
    >
      {payload.value}
      {hasPercentage && '%'}
    </text>
  );
};

export default CustomTick;
