import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';

const FileUploadProgress = styled.progress`
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 2px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: #ff8b22;
    border-radius: 2px;
    height: 4px;
  }
  &::-webkit-progress-value {
    display: inline-block;
    float: left;
    height: 4px;
    background: #ff8b22;
    border-radius: 2px;
  }
`;

type FileProgressProps = {
  progress: number;
  max: number;
};

const FileProgress = ({ progress, max }: FileProgressProps) => {
  return <FileUploadProgress value={progress} max={max} />;
};

export default FileProgress;
