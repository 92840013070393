import { MeetingAttendee } from 'lib/api/meetings/types';
import { useUpdateMeetingCancelStatusMutation } from 'lib/api/meetings/useUpdateMeetingCancelStatusMutation';
import { CheckboxInput } from 'lib/components';
import { NewModal } from 'lib/components/modal';
import { Gap } from 'lib/components/styles/layout';
import { CheckboxLabel } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';

interface Props {
  meetingId: string;
  attendees: MeetingAttendee[];
  handleModalClose: () => void;
  handleCloseAllModals: () => void;
}

const Textarea = styled.textarea`
  box-sizing: border-box;
  padding: 8px 12px;
  height: 80px;
  border-radius: 6px;
  width: 100%;
  border: solid 1px ${theme.palette.gray40};
  resize: vertical;
  margin-top: 12px;
  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
    border: 1px solid ${theme.palette.covideoBlue100};
  }
`;

export const CancelMeetingModal = ({
  meetingId,
  handleModalClose,
  handleCloseAllModals,
  attendees,
}: Props) => {
  const hasAttendees = !!attendees?.length;
  const [sendEmail, setSendEmail] = useState<boolean>(hasAttendees);
  const [emailNote, setEmailNote] = useState('');
  const { mutateAsync: cancelMeetingAsync, isLoading: isLoadingUpdate } =
    useUpdateMeetingCancelStatusMutation(handleCloseAllModals);

  const handleSubmit = async () => {
    await cancelMeetingAsync({
      meetingId,
      emailNote,
      sendEmail,
    });
  };

  return (
    <NewModal closeModal={handleModalClose} headerText={'Cancel meeting'}>
      Are you sure you want to cancel the meeting?
      {!!hasAttendees && (
        <>
          <Gap gap='12px' m='18px 0 0'>
            <CheckboxInput
              id={'sendEmail'}
              width={'24px'}
              blueCheck={true}
              checked={sendEmail}
              onChange={(e: React.SyntheticEvent) => {
                const { checked } = e.target as HTMLInputElement;
                setSendEmail(checked);
              }}
            />
            <CheckboxLabel htmlFor='sendEmail'>
              Send a cancellation email to attendees.
            </CheckboxLabel>
          </Gap>
          <>
            {sendEmail && (
              <Textarea
                value={emailNote}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setEmailNote(e.target.value)
                }
                placeholder='Add an optional message for cancelation'
              />
            )}
          </>
        </>
      )}
      <Gap m='24px 0 0' gap='12px' justifyContent='flex-end'>
        <Button
          text='Close'
          variant='secondary'
          onClick={handleModalClose}
          disabled={isLoadingUpdate}
        />
        <Button
          variant='destructive'
          text={isLoadingUpdate ? 'Canceling...' : 'Cancel Meeting'}
          disabled={isLoadingUpdate}
          onClick={handleSubmit}
        />
      </Gap>
    </NewModal>
  );
};
