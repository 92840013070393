import { useMutation, useQueryClient } from 'react-query';
import { annotationsKeys } from './annotationsKeys';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';
import { CreateTemplateParams } from '../types';

export const createTemplate = async ({ data }: CreateTemplateParams) => {
  const response = await EXPRESS_API.post('/annotation-templates', data);

  return response.data;
};

export const useCreateTemplateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(annotationsKeys.templates());
      successToast({ title: 'Template created successfully!' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred creating the template, please try again later!',
      });
    },
  });
};
