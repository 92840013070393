import { useFormikContext } from 'formik';
import { TextInput } from 'lib/components';
import React from 'react';
import { InputField } from '../InputField';
import emptyLocation from 'assets/images/single/cell.webp';
import { ParentCustomer } from 'lib/api/multiLocations/multiLocationQuery';
import { ItemContainer } from '../ItemContainer';

export const AddLocationView = () => {
  const { values } = useFormikContext<ParentCustomer>();
  return (
    <>
      {values.business ? (
        <ItemContainer width='100%' justifyContent='space-between'>
          <InputField
            style={{
              margin: 0,
              width: '100%',
              maxWidth: '100%',
              height: 40,
            }}
            name={`location.name`}
            as={TextInput}
            type='text'
          />
        </ItemContainer>
      ) : (
        <ItemContainer
          width='100%'
          justifyContent='space-between'
          style={{ border: 'none' }}
        >
          <img src={emptyLocation} alt='empty' style={{ height: 50 }} />
        </ItemContainer>
      )}
      {values.childCustomers.map((_, index) => (
        <ItemContainer width='100%' justifyContent='space-between' key={index}>
          <InputField
            style={{
              margin: 0,
              width: '100%',
              maxWidth: '100%',
              height: 40,
            }}
            name={`childCustomers[${index}].location.name`}
            as={TextInput}
            type='text'
          />
        </ItemContainer>
      ))}
    </>
  );
};
