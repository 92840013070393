import React from 'react';
import styled from 'styled-components/macro';
import { ScheduleAction } from './ScheduleAction';
import { StartAction } from './StartAction';
import { TAB_IDS } from '../../pages/MeetingList';
import { RecurringAction } from './RecurringAction';
import { MeetingStatus } from 'lib/api/meetings/types';

const ActionButtons = styled.div`
  margin-left: auto;
  display: flex;
  gap: 16px;
`;

interface MeetingListActionsProps {
  handleOpenLiveDetailsModal: (
    meetingId: string | null,
    meetingStatus: MeetingStatus | null
  ) => void;

  activeTab: TAB_IDS;
}

export const MeetingListActions = ({
  handleOpenLiveDetailsModal,
  activeTab,
}: MeetingListActionsProps) => {
  const isScheduled = activeTab === TAB_IDS.SCHEDULED;
  const isRecurring = activeTab === TAB_IDS.RECURRING;

  return (
    <ActionButtons>
      {isScheduled && (
        <>
          <ScheduleAction
            handleOpenLiveDetailsModal={handleOpenLiveDetailsModal}
          />
          <StartAction />
        </>
      )}
      {isRecurring && (
        <RecurringAction
          handleOpenLiveDetailsModal={handleOpenLiveDetailsModal}
        />
      )}
    </ActionButtons>
  );
};
