import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { Button } from 'react-covideo-common';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  AddonFooter,
  Options,
  PageContainer,
  PageDescription,
  PageHeader,
} from '../components/PageElements';

const WheelsTVPageContainer = styled(PageContainer)`
  margin: 60px;
`;

export const WheelsTVAddon = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const ContactForm = async () => {
    setShowContactForm(true);
  };

  return (
    <WheelsTVPageContainer>
      {showContactForm && (
        <ModalContactAM
          handleModalClose={() => setShowContactForm(false)}
          subject='WheelsTV Request'
        />
      )}
      <PageHeader>
        <Button
          text='Contact your Account Manager'
          variant='primary'
          onClick={ContactForm}
        />
      </PageHeader>
      <PageDescription>
        Access WheelsTV’s library of more than 6,000 video test drive reports,
        for the in-depth, relevant video content buyers are searching for.
      </PageDescription>
      <Options>
        <VideoPlayer
          videoRef={React.createRef()}
          playButtonPosition='center'
          playerBackgroundColor='#000'
          playerIconsColor='#ffffff'
          thumbnail='https://images.covideo.com/thumbnails/71305_3_pf6ywpxpls1604691452_0001.png'
          videoSource='https://videos.covideo.com/videos/71305_3_pf6ywpxpls1604691452.mp4'
        />
      </Options>
      <AddonFooter>
        Have a question? Contact your account manager, contact us at
        support@covideo.com or give us a call at 1.800.306.1445.
      </AddonFooter>
    </WheelsTVPageContainer>
  );
};
