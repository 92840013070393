import { Formik, FormikHelpers, FormikValues } from 'formik';
import {
  ILiveChatParams,
  useSendLiveChatMutation,
} from 'lib/api/conversations/sendLiveChat';
import { Dropdown, TextInput } from 'lib/components';
import { ChatsModal } from 'lib/components/modal/chatModal/ChatsModal';
import React, { SyntheticEvent } from 'react';
import { MdOutlineAttachFile, MdSend } from 'react-icons/md';
import { Tooltip } from 'recharts';
import { InputField } from '../../components/InputField';
import { IFormProps } from '../../conversations.types';
import {
  FormStyles,
  AttachmentWrapper,
} from '../../styles/UsersConversationsLayout';
import { Button } from 'react-covideo-common';

interface IChatForm extends IFormProps {
  setCopiedMessage: React.Dispatch<React.SetStateAction<string | null>>;
}
interface IFormikValues extends ILiveChatParams {}
export const ChatForm = ({
  singleConversation,
  setModals,
  modals,
  dropdownValue,
  onDropdownChangeHandler,
  items,
  setCopiedMessage,
}: IChatForm) => {
  const { mutateAsync, isLoading } = useSendLiveChatMutation();

  const submitForm = async (
    values: FormikValues,
    formik: FormikHelpers<IFormikValues>
  ) => {
    mutateAsync({
      conversationId: values.conversationId,
      message: values.message,
      formik,
    });
  };

  if (!singleConversation) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        message: '',
        conversationId: singleConversation?.conversationId,
      }}
      enableReinitialize
      onSubmit={submitForm}
    >
      {({ setFieldValue, submitForm, values }) => (
        <FormStyles
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submitForm();
            }
          }}
        >
          <AttachmentWrapper onClick={() => setModals('chooseFile')}>
            <MdOutlineAttachFile height={25} color='rgba(0, 27, 83, 1)' />
            <Tooltip>Attach video, image or file</Tooltip>
          </AttachmentWrapper>
          <Dropdown
            options={items}
            value={dropdownValue}
            onChange={onDropdownChangeHandler}
            creatable={false}
            width={100}
            menuPlacement='top'
            extendStyles={{ control: { minHeight: 42 } }}
            isSearchable={false}
          />
          <InputField
            style={{ margin: 0, width: '100%', maxWidth: '100%' }}
            placeholder='Enter message...'
            name='message'
            onChange={(e: SyntheticEvent) => {
              const currentValue = (e.target as HTMLInputElement).value;
              setFieldValue('message', currentValue);
              setCopiedMessage(currentValue);
            }}
            as={TextInput}
            type='text'
          />
          <Button
            type='submit'
            text={isLoading ? 'Sending' : 'Send chat'}
            icon={<MdSend size={20} />}
            onClick={submitForm}
            disabled={!values.message || isLoading}
          />
          {modals === 'chooseFile' && (
            <ChatsModal
              handleModalClose={() => {
                setModals(false);
              }}
              onSelect={(item: any, itemType: string) => {
                if (itemType === 'video') {
                  return setFieldValue('message', item.url);
                }
                if (itemType === 'file' || itemType === 'board') {
                  return setFieldValue('message', item.shortlink);
                }
              }}
            />
          )}
        </FormStyles>
      )}
    </Formik>
  );
};
