import { useAuth } from 'lib/context';
import * as React from 'react';
import Helmet from 'react-helmet';

type Props = {
  description?: string;
  lang?: string;
  title: string;
};

export const DocumentHead = ({ description, lang = 'en', title }: Props) => {
  const { whitelabel } = useAuth();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={'%s - ' + whitelabel?.name}
      meta={[
        {
          name: `description`,
          content: description,
        },
      ]}
    />
  );
};
