import * as React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { ButtonSwitch, Modal } from 'lib/components';
import { AiFillInfoCircle } from 'react-icons/ai';
import { MdEdit, MdLaunch } from 'react-icons/md';
import { ListWebsiteOverlay } from 'lib/api';
import { wrapWithHttp } from 'lib/utils/functions';
import { URL_REGEX } from 'lib/utils/regexes';
import { useAuth } from 'lib/context';
import { CtaFilesModal } from 'app/pages/design/callsToAction/CtaFilesModal';
import { IoMdClose } from 'react-icons/io';
import { Gap, Stack } from '../styles/layout';

interface Props {
  onClose: (params?: any) => void;
  onMainButtonClick: (params?: any) => void;
  data?: ListWebsiteOverlay;
  modalLoading: boolean;
  type: 'add' | 'edit';
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Text = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.coal};
  margin: 0;
  padding: 0;
`;

const InputLabels = styled(Text)`
  color: #9297a2;

  font-weight: 500;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const Subtitle = styled(Title)`
  font-size: 16px;
  margin-bottom: 8px;
`;

const BlueBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 528px;
  height: 112px;
  margin-bottom: 24px;
  padding: 20px 16px;
  border-radius: 5px;
  background-color: rgba(182, 224, 236, 0.2);
`;

const Disclaimer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DisclaimerIconWrap = styled.section`
  margin-right: 14px;
  height: 100%;
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;
type TextInputProps = {
  width?: string;
};
const TextInput = styled.input<TextInputProps>`
  padding-left: 8px;
  height: 40px;
  width: ${({ width }) => width || '100%'};
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  cursor: pointer;
  height: 40px;
  width: 395px;
  position: relative;
`;

type ThumbnailProps = {
  image: string;
};

const FilesThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 64px;
  height: 32px;
  flex-shrink: 0;
  margin-left: 4px;
`;

const FilesTitle = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FieldWrapper = styled.div<{ width: string }>`
  width: ${props => (props.width ? props.width : 'auto')};
  > div {
    margin-left: 0;
    width: 100%;
  }
`;

/**** SUS-796 changes ***/
const ErrorInfo = styled.div`
  margin-bottom: 14px;
  ${theme.mediaQueryMaxWidth.md} {
    display: none;
  }
  span {
    color: rgb(232, 76, 61);
  }
`;

const ctaTypes = [
  {
    value: 'url',
    text: 'URL',
  },
  {
    value: 'library',
    text: 'Library',
  },
];

export const ModalWebsiteOverlayAddOrEdit = (props: Props) => {
  const { data, onClose, onMainButtonClick, modalLoading, type } = props;
  const [webTitle, setWebTitle] = React.useState(data ? data.title : '');
  const [url, setUrl] = React.useState(data ? data.url : '');
  const [validUrl, setValidUrl] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [overlayType, setOverlayType] = React.useState(data?.type || 'url');
  const [showFilesModal, setShowFilesModal] = React.useState(false);
  const [fileTitle, setFileTitle] = React.useState(data?.fileTitle || '');
  const [fileThumbnail, setFileThumbnail] = React.useState(
    data?.fileThumbnail || ''
  );
  const [itemType, setItemType] = React.useState(data?.itemType || '');
  const title = type === 'add' ? 'New Overlay URL' : 'Edit Overlay URL';
  const buttonText = type === 'add' ? 'Add URL' : 'Update URL';

  const {
    userData: { droplrAccess: hasDroplrAccess },
    whitelabel,
  } = useAuth();

  React.useEffect(() => {
    if (data) {
      setValidUrl(URL_REGEX.test(data.url));
    }
  }, []);

  const handleUrlInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.currentTarget.value);
    if (URL_REGEX.test(e.currentTarget.value)) {
      setValidUrl(true);
    } else {
      setValidUrl(false);
    }
    setTouched(true);
  };

  const handleAddOrEdit = () => {
    if (type === 'add') {
      const wrappedUrl = wrapWithHttp(url);
      const notEmptyTitle = webTitle ? webTitle : wrappedUrl;
      onMainButtonClick({
        title: notEmptyTitle,
        url: wrappedUrl,
        fileTitle,
        fileThumbnail,
        type: overlayType,
        itemType,
      });
    } else if (data) {
      const wrappedUrl = wrapWithHttp(url);
      const notEmptyTitle = webTitle ? webTitle : wrappedUrl;
      onMainButtonClick({
        title: notEmptyTitle,
        url: wrappedUrl,
        id: data.id,
        fileTitle,
        fileThumbnail,
        type: overlayType,
        itemType,
      });
    }
  };

  return (
    <>
      <Modal>
        {showFilesModal && (
          <CtaFilesModal
            selectedFile={{
              fileTitle,
              fileThumbnail,
              linkValue: url,
              itemType,
            }}
            handleModalClose={() => {
              setShowFilesModal(false);
            }}
            onSelect={(drop: any, itemType: string) => {
              setUrl(drop.shortlink as string);
              setValidUrl(true);
              setFileTitle(drop.title || drop.name);
              setFileThumbnail(
                drop.previewSmall || drop.thumbnailUrl || drop.content
              );
              setItemType(itemType);
              setShowFilesModal(false);
            }}
          />
        )}
        <Content>
          <Gap justifyContent={'space-between'} width='100%' m='0 0 24px'>
            <Title>{title}</Title>
            <CloseIconWrap>
              <IoMdClose
                size={24}
                onClick={onClose}
                color={theme.palette.midGrey}
              />
            </CloseIconWrap>
          </Gap>
          <BlueBox>
            <DisclaimerIconWrap>
              <AiFillInfoCircle size={24} color={'rgba(0, 27, 83, 0.4)'} />
            </DisclaimerIconWrap>
            <Disclaimer>
              <Subtitle>Test your URL before</Subtitle>
              <Text>
                Some websites including Google, Facebook, and Zillow, use
                security settings which prevent the Website Overlay feature from
                working properly. Please be sure to test your overlay to ensure
                proper compatibility.
              </Text>
            </Disclaimer>
          </BlueBox>
          <form
            id='formTestOverlay'
            name='formTestOverlay'
            method='post'
            action={`https://${
              whitelabel.domainV2 || 'www.covideo.com'
            }/backoffice/Test_Overlay.php`}
            target='_blank'
            style={{ width: '100%' }}
          >
            <Stack alignItems='flex-start' gap='12px'>
              <InputLabels>Website Title</InputLabels>
              <TextInput
                placeholder={'Example Page'}
                value={webTitle}
                type={'text'}
                onChange={e => setWebTitle(e.currentTarget.value)}
              />
              {/* SUS-796 changes */}
              {webTitle && webTitle.length > 100 && (
                <ErrorInfo>
                  <span>
                    Website title can not contain more than 100 characters.
                  </span>
                </ErrorInfo>
              )}
              <InputLabels>URL</InputLabels>

              {hasDroplrAccess && (
                <>
                  <Gap flexWrap='nowrap' width='100%' alignItems='flex-start'>
                    <FieldWrapper width={'150px'}>
                      <ButtonSwitch
                        defaultValue={overlayType || 'url'}
                        values={ctaTypes}
                        onChange={newValue => {
                          setUrl('');
                          setValidUrl(false);
                          setFileTitle('');
                          setFileThumbnail('');
                          setItemType('');
                          setOverlayType(newValue);
                        }}
                      />
                    </FieldWrapper>
                    <>
                      {overlayType === 'library' && (
                        <>
                          {!fileTitle && (
                            <Button
                              variant='secondary'
                              text='Select File or Board...'
                              onClick={() => {
                                setShowFilesModal(true);
                              }}
                            />
                          )}
                          {fileTitle && (
                            <>
                              <FileWrapper
                                onClick={() => setShowFilesModal(true)}
                              >
                                <FilesThumbnail image={fileThumbnail} />
                                <FilesTitle>{fileTitle}</FilesTitle>
                                <input
                                  style={{ display: 'none' }}
                                  value={url}
                                  id='txt_URL_Text'
                                  name='txt_URL_Text'
                                />
                                <MdEdit
                                  size='18px'
                                  height='18px'
                                  color='#9297A2'
                                  style={{
                                    position: 'absolute',
                                    right: '12px',
                                  }}
                                />
                              </FileWrapper>
                            </>
                          )}
                        </>
                      )}
                      {overlayType === 'url' && (
                        <Stack alignItems='flex-start' gap='4px' width='100%'>
                          <TextInput
                            placeholder={'http://www.example.com'}
                            value={url}
                            type={'text'}
                            onChange={e => handleUrlInput(e)}
                            id='txt_URL_Text'
                            name='txt_URL_Text'
                            style={{ width: '100%' }}
                          />
                          {touched && !validUrl ? (
                            <ErrorMessage className='error'>
                              Please enter a valid url
                            </ErrorMessage>
                          ) : null}
                        </Stack>
                      )}
                    </>
                  </Gap>
                </>
              )}
              {!hasDroplrAccess && (
                <FieldWrapper width={'100%'}>
                  <Gap justifyContent='space-between'>
                    <TextInput
                      placeholder={'http://www.example.com'}
                      value={url}
                      type={'text'}
                      onChange={e => handleUrlInput(e)}
                      id='txt_URL_Text'
                      name='txt_URL_Text'
                      width={'375px'}
                    />
                    <Button
                      text={'Test Overlay URL'}
                      variant='secondary'
                      icon={<MdLaunch />}
                      disabled={!validUrl || modalLoading}
                      type='submit'
                    />
                  </Gap>
                  {touched && !validUrl ? (
                    <ErrorMessage className='error'>
                      Please enter a valid url
                    </ErrorMessage>
                  ) : null}
                </FieldWrapper>
              )}

              <Gap justifyContent={'flex-end'} width='100%' m='24px 0 0'>
                {hasDroplrAccess && (
                  <Button
                    text={'Test Overlay URL'}
                    icon={<MdLaunch />}
                    variant='secondary'
                    disabled={!validUrl || modalLoading}
                    type='submit'
                  />
                )}
                <Button
                  text={buttonText}
                  variant='primary'
                  onClick={() => validUrl && handleAddOrEdit()}
                  disabled={
                    !validUrl || webTitle.length > 100 || modalLoading
                  } /***SUS-796 changes***/
                />
              </Gap>
            </Stack>
          </form>
        </Content>
      </Modal>
    </>
  );
};
