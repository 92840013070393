export enum FREQUENCY_TYPE {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum FREQUENCY_DETAIL {
  EVERYDAY = 'everyday',
  WEEKDAYS = 'weekdays',
}
