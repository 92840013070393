import { v4 as uuidv4 } from 'uuid';
import { EXPRESS_API } from 'configs/api/expressApi';
import axios from 'axios';

export const uploadImage = async (file: File, userId: string) => {
  const fileExtension = file.name.split('.').pop();
  const data = {
    fileName: `${userId}_${uuidv4()}.${fileExtension}`,
    mimeType: file.type,
  };
  const response = await EXPRESS_API.post(`/aws/signedurl/user-images`, data);

  const url = response.data.uploadUrl;
  await axios.put(url, file, { headers: { 'content-type': file.type } });

  return response.data.downloadUrl;
};
