import Cookies from 'js-cookie';
import {
  AddNewCard,
  PaymentCardType,
  addOrUpdatePlanSubscription,
  upgradeOrCreateSubscription,
} from 'lib/api';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import {
  ButtonSwitch,
  CheckboxInput,
  Dropdown,
  LoadingIndicator,
} from 'lib/components';
import { successToast } from 'lib/components/toasts/success';
import { SubscriptionMappingForUpgrade, SubscriptionPrice } from 'lib/const';
import {
  PackageName,
  packageAdditionalInfo,
} from 'lib/const/PackageAdditionalInfo';
import {
  subscriptionDataType,
  subscriptionMappingItemType,
} from 'lib/const/Subscription';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import { theme } from 'lib/style';
import { preparePrice } from 'lib/utils/invoice';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { PaymentDetails } from './PaymentDetails';
import { Button } from 'react-covideo-common';
import { useRegenerateTokenMutation } from 'lib/api/auth/useRegenerateTokenMutation';

const MAX_USERS = 5;
interface PriceDetailsWrapperProps {
  width?: string;
  padding?: string;
  margin?: string;
  height?: string;
  justifyContent?: string;
  alignItems?: string;
  gap?: string;
  fontSize?: string;
}

interface PriceBoldProps {
  fontSize?: string;
}

const MainContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 30px 0px 0px 0px;
  row-gap: 20px;
`;

interface MainProps {
  padding?: string;
}
const MainContainer = styled.main<MainProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${props => (props.padding ? props.padding : '0px')};
  min-height: 500px;
  width: 100%;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.xl};
  color: ${theme.palette.covideoBlue100};

  font-weight: 800;
  text-align: left;
  margin: 40px 0px;
`;

const LoaderContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 9;
  min-height: 100%;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

const UnderlineText = styled.span`
  text-decoration-line: underline;
  cursor: pointer;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 592px;
  min-height: 588px;
  background: #ffffff;
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 12px 20px rgba(66, 79, 104, 0.06);
  border-radius: 16px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  margin-top: 56px;
`;

const SwitchWrapper = styled.div`
  box-sizing: border-box;
  width: 167px;
  margin: 0 auto;
  position: relative;
`;

const PriceDetailsWrapper = styled.div<PriceDetailsWrapperProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'center'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  padding: ${props => (props.padding ? props.padding : '16px 24px')};
  box-sizing: border-box;
  gap: ${props => (props.gap ? props.gap : '12px')};
  background: #f6f7f9;
  border-radius: 6px;
  min-width: ${props => (props.width ? props.width : '228px')};
  max-width: 100%;
  height: ${props => (props.height ? props.height : '96px')};
  margin: ${props => (props.margin ? props.margin : '0px')};
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  line-height: 20px;
  color: ${theme.palette.covideoBlue60};
  @media screen and (max-width: 480px) {
    width: auto;
    height: auto;
    min-width: auto;
  }
`;

const PriceBold = styled.span<PriceBoldProps>`
  color: ${theme.palette.covideoBlue100};
  font-weight: 600;
  font-size: ${props => (props.fontSize ? props.fontSize : '32px')};
`;

const AddonListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 10px;
  width: 455px;
  max-width: 455px;
  margin-bottom: 25px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const AddonListItem = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  background: #ffffff;
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  gap: 15px;
`;

const AddonTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: ${theme.palette.covideoBlue100};
`;

const AddonDescription = styled.div`
  margin-top: 12px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.neutral80};
`;

const UserSeatsContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  margin-top: 35px;

  > svg {
    position: absolute;
    top: 38px;
    left: 7px;
    color: ${theme.palette.covideoBlue40};
  }
`;

const MoreThanFiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 300px;
  width: 100%;
  padding: 0 0 0 16px;
  height: 48px;
  align-items: center;
  p {
    margin: 0;
    font-size: 14px;
    a {
      text-decoration: none;
    }
  }
`;

const Label = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${theme.palette.neutral80};
  margin-bottom: 10px;
`;

const billingCycles = [
  {
    value: 'monthly',
    text: 'Monthly',
  },
  {
    value: 'annual',
    text: 'Annual',
  },
];

const userSeatsOptions = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5+',
  },
];
interface UpgradeProps {
  signupPage?: boolean;
  setSubscriptionData?: React.Dispatch<React.SetStateAction<any>>;
  couponData?: any;
  couponCode?: string;
  showPayment?: boolean;
  onSignup?(subscriptionData: subscriptionDataType): void;
  prevSubscriptionData?: subscriptionDataType;
}

const urlParams = new URLSearchParams(window.location.search);
const defaultInterval = urlParams.get('pricing');
const type = urlParams.get('type');
const isLegacy = !type || type === '0';
const isProPackage = type === '1' && !isLegacy;
const addonList = isLegacy ? ['captions', 'lpb', 'droplr', 'cvlp'] : ['cvlp'];
const packageDetails = isLegacy
  ? SubscriptionPrice
  : isProPackage
    ? packageAdditionalInfo[PackageName.PRO]
    : packageAdditionalInfo[PackageName.TEAMS];
const annualRate = isLegacy
  ? packageDetails.annual
  : packageDetails.feeYearly || packageDetails.feeYearlySecondary;
const monthlyRate = isLegacy
  ? packageDetails.monthly
  : packageDetails.feeMonthly || packageDetails.feeMonthlySecondary;

const defaultSeats = 1;

export const UpgradeLayout = (props: UpgradeProps) => {
  const { mutateAsync: regenerateJwtToken } = useRegenerateTokenMutation();
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState<PaymentCardType>(
    {} as PaymentCardType
  );
  const [cardId, setCardId] = React.useState('');
  const {
    signupPage,
    couponData,
    showPayment,
    onSignup,
    prevSubscriptionData,
  } = props;
  const [addCardView, setAddCardView] = useState(!!showPayment);
  const { showError } = useToastError();
  const { setJwtAndUpdateToken, whitelabel } = useAuth();

  const [billingCycle, setBillingCycle] = useState(
    defaultInterval === 'monthly' ? defaultInterval : billingCycles[1].value
  );
  const isAnnual = billingCycle === 'annual';
  const [seat, setSeat] = useState(
    !!prevSubscriptionData ? prevSubscriptionData.quantity : defaultSeats
  );
  const [selectedAddon, setSelectedAddon] = useState<string[]>(
    !!prevSubscriptionData ? prevSubscriptionData.addons : []
  );

  const [subscriptionData, setSubscriptionData] =
    React.useState<subscriptionDataType>(
      prevSubscriptionData || {
        amount: preparePrice(
          Math.round(annualRate * 12 * seat) * 100,
          'USD',
          true
        ),
        amountPerSeat: isAnnual ? annualRate : monthlyRate,
        annual: isAnnual,
        addons: selectedAddon,
        selectedAddonDetails: [],
        quantity: seat,
        discountAmount: 0,
        planAmount: Math.round(annualRate * 12 * 1),
        addonAmount: 0,
        isProPackage,
      }
    );
  const filteredAddons = SubscriptionMappingForUpgrade[billingCycle].filter(
    (item: subscriptionMappingItemType) => {
      return addonList.includes(item.id as string);
    }
  );

  useEffect(() => {
    if (prevSubscriptionData) {
      return;
    }
    const updatedAmount = preparePrice(
      Math.round(getTotalAmount() * 100),
      'USD',
      true
    );
    setSubscriptionData(prevValue => {
      return { ...prevValue, amount: updatedAmount };
    });
  }, [seat, billingCycle, selectedAddon, couponData, prevSubscriptionData]);

  useEffect(() => {
    if (prevSubscriptionData) {
      return;
    }
    const newPlanAmount = Math.round(
      billingCycle === 'annual' ? annualRate * 12 * seat : monthlyRate * seat
    );
    setSubscriptionData(prevValue => {
      return { ...prevValue, planAmount: newPlanAmount, annual: isAnnual };
    });
  }, [billingCycle, seat, prevSubscriptionData, isAnnual]);

  useEffect(() => {
    if (prevSubscriptionData) {
      return;
    }
    const selectedAddonNewDetails = SubscriptionMappingForUpgrade[
      billingCycle
    ].filter((item: subscriptionMappingItemType) => {
      return selectedAddon.includes(item.id as string);
    });

    setSubscriptionData(prevValue => {
      return {
        ...prevValue,
        selectedAddonDetails: selectedAddonNewDetails,
        addons: selectedAddon,
      };
    });
  }, [billingCycle, selectedAddon, prevSubscriptionData]);

  useEffect(() => {
    if (prevSubscriptionData) {
      return;
    }
    setSubscriptionData(prevValue => {
      return { ...prevValue, quantity: seat || 1 };
    });
  }, [seat, prevSubscriptionData]);

  useEffect(() => {
    if (cardId.length) {
      if (isLegacy) {
        handleSubscriptionCreate();
        return;
      }

      handleSubscriptionForFreemium();
    }
  }, [cardId]);

  useEffect(() => {
    if (Object.keys(cardDetails).length) {
      handleAddCard();
    }
  }, [cardDetails]);

  const selectAddon = (addon: string) => {
    const index = selectedAddon.findIndex((e: any) => e == addon);
    if (index == -1) {
      setSelectedAddon([...selectedAddon, addon]);
    } else {
      setSelectedAddon(selectedAddon.filter((e: any) => e != addon));
    }
  };

  const calculateDiscount = (price: any) => {
    if (
      signupPage &&
      couponData &&
      isLegacy &&
      Object.keys(couponData).length
    ) {
      if (!couponData.amount_off && !couponData.percent_off) {
        return price;
      }
      let discount: number;
      if (couponData.amount_off) {
        discount = Math.round(couponData.amount_off / 100);
      } else {
        discount = Math.round(price * (couponData.percent_off / 100));
      }

      const amount = price - discount;
      setSubscriptionData(prevValue => {
        return { ...prevValue, discountAmount: discount };
      });
      return amount > 0 ? amount : 0;
    }
    return price;
  };

  const getTotalAmount = () => {
    if (!isAnnual) {
      let price = Math.round(monthlyRate * seat);
      SubscriptionMappingForUpgrade.monthly.forEach((addon: any) => {
        if (selectedAddon.includes(addon.id))
          price += addon.price * (addon.type == 'users' ? seat : 1);
      });
      return calculateDiscount(price);
    } else {
      let price = Math.round(annualRate * seat * 12);
      SubscriptionMappingForUpgrade.annual.forEach((addon: any) => {
        if (selectedAddon.includes(addon.id))
          price += addon.price * (addon.type == 'users' ? seat : 1);
      });

      const finalAmount = Math.round(calculateDiscount(price));
      return finalAmount;
    }
  };

  const handleSubscription = () => {
    const jwt = Cookies.get('jwt');
    if (!!onSignup && !jwt) {
      onSignup(subscriptionData);
    } else {
      setAddCardView(true);
    }
  };

  const handleSubscriptionForFreemium = async () => {
    try {
      setLoading(true);
      await addOrUpdatePlanSubscription({
        paymentId: cardId,
        addons: selectedAddon || [],
        quantity: seat,
        interval: isAnnual ? 'annual' : 'Monthly',
        packageId: isProPackage ? PackageName.PRO : PackageName.TEAMS,
      });

      const tokens = await regenerateJwtToken();
      setJwtAndUpdateToken(tokens);
      setLoading(false);
      successToast({ title: 'Subscription updated successfully.' });
      window.location.href = '/home';
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };
  const handleSubscriptionCreate = async () => {
    try {
      setLoading(true);
      await upgradeOrCreateSubscription({
        paymentId: cardId,
        addons: selectedAddon || [],
        quantity: seat,
        interval: isAnnual ? 'year' : 'month',
      });
      setLoading(false);
      successToast({ title: 'Subscription updated successfully.' });
      window.location.href = '/home';
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  const handleAddCard = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const response = await AddNewCard(cardDetails);
      setCardId(response.paymentId);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  const uiFragmentForWhitelabels = () => {
    return (
      <MainContainer style={{ justifyContent: 'center' }}>
        <MainContainerWrapper
          style={{ flexDirection: 'row', fontSize: '18px' }}
        >
          Please contact your Account Manager&nbsp;
          {whitelabel &&
            whitelabel.supportEmail &&
            whitelabel.supportEmail !== '' && (
              <>
                or&nbsp;
                <UnderlineText
                  onClick={() =>
                    (window.location.href = `mailto:${whitelabel.supportEmail}`)
                  }
                >
                  contact our support
                </UnderlineText>
                &nbsp; if you need help.
              </>
            )}
        </MainContainerWrapper>
      </MainContainer>
    );
  };

  if (whitelabel?.name !== WHITELABEL_NAME.COVIDEO) {
    return uiFragmentForWhitelabels();
  }

  const getAddonsListItems = () => {
    return filteredAddons.map(
      (item: subscriptionMappingItemType, index: number) => {
        return (
          <AddonListItem key={item.id as string}>
            <div>
              <CheckboxInput
                name={`check-${index}`}
                id={`check-${index}`}
                checked={selectedAddon.includes(item.id as string)}
                onChange={(e: SyntheticEvent) => {
                  let { checked } = e.target as HTMLInputElement;
                  const addonAmount = parseInt((item.price || 0).toString());
                  if (checked) {
                    setSubscriptionData(prevValue => {
                      const newAmount = prevValue.addonAmount + addonAmount;
                      return { ...prevValue, addonAmount: newAmount };
                    });
                  } else {
                    setSubscriptionData(prevValue => {
                      const newAmount = prevValue.addonAmount - addonAmount;
                      return { ...prevValue, addonAmount: newAmount };
                    });
                  }

                  selectAddon(item.id as string);
                }}
              />
            </div>
            <div style={{ maxWidth: '295px' }}>
              <AddonTitle>{item.title}</AddonTitle>
              <AddonDescription>{item.summary}</AddonDescription>
            </div>
            <PriceDetailsWrapper
              margin='auto 0 auto auto'
              width='128px'
              height='60px'
              padding='10px'
              alignItems='flex-start'
              gap='0px'
              fontSize='10px'
            >
              <div>
                <PriceBold fontSize='13px'>{`${item.symbol} ${item.price}`}</PriceBold>
              </div>
              <div>{item.priceDescription}</div>
            </PriceDetailsWrapper>
          </AddonListItem>
        );
      }
    );
  };

  const getUiFragmentForAddons = () => {
    return (
      <AddonListContainer>
        {(!isProPackage || isLegacy) && <Label>{'Add-ons (optional)'}</Label>}
        {getAddonsListItems()}
      </AddonListContainer>
    );
  };

  const getPlanPriceDetails = () => {
    return (
      <PriceDetailsWrapper margin={'30px 0 0 0'}>
        <div>
          <PriceBold>{`$${
            isAnnual ? Math.round(annualRate * 12) : monthlyRate
          }`}</PriceBold>
          {`${isAnnual ? '/year' : '/month'}${isProPackage ? '' : '/user'}`}
        </div>
        <div>{`Billed ${isAnnual ? 'annually' : 'monthly'} at $${
          isAnnual
            ? Math.round(annualRate * 12) * (seat || 1)
            : Math.round(monthlyRate * (seat || 1))
        }`}</div>
      </PriceDetailsWrapper>
    );
  };
  const getHeader = () => {
    return (
      <Wrapper>
        <SwitchWrapper>
          <ButtonSwitch
            defaultValue={billingCycle}
            values={billingCycles}
            onChange={newValue => {
              setBillingCycle(newValue);
            }}
          />
        </SwitchWrapper>
      </Wrapper>
    );
  };

  const getUiFragmentForSeats = () => {
    return (
      <UserSeatsContainer>
        {(!isProPackage || isLegacy) && <Label>No. of users</Label>}
        <Dropdown
          options={userSeatsOptions}
          value={userSeatsOptions.find(option => {
            return option.value === seat;
          })}
          onChange={(option: any) => setSeat(option.value)}
          placeholder='...'
          width={100}
          height={40}
          creatable={false}
        />
      </UserSeatsContainer>
    );
  };

  const getMoreThanFiveUsers = () => {
    return (
      <MoreThanFiveWrapper>
        <p>
          For 5+ users please&nbsp;
          <a
            href={whitelabel.homepage + '/contact'}
            target='_blank'
            rel='noreferrer'
          >
            Contact us
          </a>
          .
        </p>
      </MoreThanFiveWrapper>
    );
  };
  const getSelectPlanTypeAndAddon = () => {
    return (
      <>
        <ContentHeader>Select plan & Add-ons</ContentHeader>
        <Container>
          {getHeader()}
          {getPlanPriceDetails()}
          {!isProPackage && getUiFragmentForSeats()}
          {seat === MAX_USERS ? (
            getMoreThanFiveUsers()
          ) : (
            <>
              {getUiFragmentForAddons()}
              <Button
                text={'Continue'}
                onClick={handleSubscription}
                disabled={!seat}
              />
            </>
          )}
        </Container>
      </>
    );
  };

  return (
    <>
      <MainContainer padding={signupPage ? '0px' : '55px 0px 0px 0px'}>
        {addCardView ? (
          <MainContainerWrapper>
            {loading && (
              <LoaderContainer>
                <LoadingIndicator isLoading={true} />
              </LoaderContainer>
            )}
            <PaymentDetails
              subscriptionData={subscriptionData}
              onBackButtonPress={() => {
                setAddCardView(false);
              }}
              onSubmit={setCardDetails}
            />
          </MainContainerWrapper>
        ) : (
          <>{getSelectPlanTypeAndAddon()}</>
        )}
      </MainContainer>
    </>
  );
};
