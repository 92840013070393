import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 272px;
  padding: 8px 12px;
`;
const Title = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;
const Text = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  > span {
    color: ${theme.palette.magic100};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const NotSurePopup = () => {
  return (
    <Wrapper>
      <Title>Not sure what to write or ask?</Title>
      <Text>
        Try our <span>AI Assist</span> and increase customer's engagement.
      </Text>
    </Wrapper>
  );
};
