import { OptOutData, OptOutParams } from './types';
import { useQuery } from 'react-query';
import { contactsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export const getOptOutContacts = async (params: OptOutParams) => {
  const result = (await EXPRESS_API.get(`opt-outs`, { params })).data;
  return {
    optOutContacts: result.optOutContacts,
    count: result.count,
  } as OptOutData;
};

export const useGetOptOutContacts = (params: OptOutParams) => {
  return useQuery(contactsKeys.opt_out_list(params), () =>
    getOptOutContacts(params)
  );
};
