import {
  FilesetResolver,
  ImageSegmenter,
  ImageSegmenterResult,
} from '@mediapipe/tasks-vision';
import { useRef } from 'react';
import {
  clear,
  createTexture,
  initWebGL,
  render,
  setupRectangle,
  updateTexture,
  VIEWPORT_HEIGHT,
  VIEWPORT_WIDTH,
} from './webgl/webglUtils';

export type WebGlState = {
  gl: WebGL2RenderingContext | null;
  program: WebGLProgram | null;
};

const bgImgCanvasElement = document.createElement('canvas');
const bgImgCanvasCtx = bgImgCanvasElement.getContext('2d');
let lastTimestamp = 0;

const calculateBackgroundImageData = (
  imgElement: HTMLImageElement,
  videoWidth: number,
  videoHeight: number
) => {
  let newWidth = videoWidth;
  let newHeight = videoHeight;
  let startX = 0;
  let startY = 0;

  if (videoWidth / videoHeight < imgElement.width / imgElement.height) {
    newWidth = videoHeight * (imgElement.width / imgElement.height);
    startX = (newWidth - videoWidth) / 2;
  } else {
    newHeight = (videoWidth * imgElement.height) / imgElement.width;
    startY = (newHeight - videoHeight) / 2;
  }

  bgImgCanvasElement.width = newWidth;
  bgImgCanvasElement.height = newHeight;

  bgImgCanvasCtx!.drawImage(imgElement, 0, 0, newWidth, newHeight);
  return bgImgCanvasCtx!.getImageData(startX, startY, videoWidth, videoHeight)
    .data;
};

const calculateBackgroundScale = (
  canvasWidth: number,
  canvasHeight: number,
  backgroundWidth: number,
  backgroundHeight: number
) => {
  const canvasAspect = canvasWidth / canvasHeight;
  const backgroundAspect = backgroundWidth / backgroundHeight;
  let scaleX = 1.0;
  let scaleY = 1.0;

  // If the background aspect ratio is less than the canvas aspect ratio, scale the background to fit width
  if (backgroundAspect < canvasAspect) {
    scaleY = backgroundAspect / canvasAspect;
  }
  // Otherwise, scale the background to fit height
  else {
    scaleX = canvasAspect / backgroundAspect;
  }

  return [scaleX, scaleY];
};

export const useImageSegmenter = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  videoRef: React.RefObject<HTMLVideoElement>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const imageSegmenterRef = useRef<ImageSegmenter | null>(null);
  const isFirstFrameRef = useRef<boolean>(true);
  const resultimageRef = useRef<Uint8ClampedArray | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const webGlRef = useRef<WebGlState>({ gl: null, program: null });
  const bgTexture = useRef<WebGLTexture>();
  const backgroundScale = useRef([1.0, 1.0]);

  let fgTexture: WebGLTexture;

  const initTextures = (gl: WebGLRenderingContext) => {
    fgTexture = createTexture(gl);
    bgTexture.current = createTexture(gl);
  };

  const initWebGLContext = () => {
    // Initialize WebGL context and program
    if (!canvasRef.current) {
      return;
    }
    webGlRef.current = initWebGL(canvasRef.current);
    if (webGlRef.current.gl) {
      initTextures(webGlRef.current.gl);
      if (imageRef.current) {
        updateTexture(
          webGlRef.current.gl,
          bgTexture.current!,
          imageRef.current,
          canvasRef.current.width,
          canvasRef.current.height
        );
        backgroundScale.current = calculateBackgroundScale(
          VIEWPORT_WIDTH,
          VIEWPORT_HEIGHT,
          imageRef.current.width,
          imageRef.current.height
        );
      }
    }
  };

  const onCreateVirtualBackgroundHandler = async () => {
    if (imageSegmenterRef.current) {
      return;
    }
    const audio = await FilesetResolver.forVisionTasks(
      'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.2/wasm'
    );

    initWebGLContext();

    const segmenter = await ImageSegmenter.createFromOptions(audio, {
      baseOptions: {
        modelAssetPath:
          'https://storage.googleapis.com/mediapipe-models/image_segmenter/selfie_multiclass_256x256/float32/latest/selfie_multiclass_256x256.tflite',
        delegate: 'GPU',
      },
      runningMode: 'VIDEO', // Always set to 'VIDEO' for webcam
      outputCategoryMask: false,
      outputConfidenceMasks: true,
      canvas: canvasRef.current!,
    });

    imageSegmenterRef.current = segmenter;

    setIsLoading(false);
    predictWebcam();
  };

  const onDestroyVirtualBackgroundHandler = () => {
    if (imageSegmenterRef.current) {
      imageSegmenterRef.current.close();
      imageSegmenterRef.current = null;
    }
    if (webGlRef.current.gl) {
      clear(webGlRef.current.gl);
    }

    if (canvasRef.current) {
      const canvasRefCtx = canvasRef.current.getContext('2d');
      if (canvasRefCtx) {
        canvasRefCtx.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      }
    }
  };

  const drawSegmentationResult = (result: ImageSegmenterResult) => {
    if (
      !result.confidenceMasks ||
      !canvasRef.current ||
      !resultimageRef.current ||
      !imageRef.current ||
      !videoRef.current
    ) {
      result.close();
      return;
    }
    const { gl, program } = webGlRef.current;

    if (!gl || !program) return;

    // Setup positions and texture coordinates for the rectangle
    setupRectangle(gl, program);

    const confidenceMaskTexture = result.confidenceMasks[0].getAsWebGLTexture();
    const confidenceMaskHairTexture =
      result.confidenceMasks[1].getAsWebGLTexture();
    const confidenceMaskBodyTexture =
      result.confidenceMasks[2].getAsWebGLTexture();
    const confidenceMaskFaceTexture =
      result.confidenceMasks[3].getAsWebGLTexture();
    const confidenceMaskClothesTexture =
      result.confidenceMasks[4].getAsWebGLTexture();
    const confidenceMaskOtherTexture =
      result.confidenceMasks[5].getAsWebGLTexture();

    // // Render the result using WebGL
    render(
      gl,
      program,
      fgTexture,
      bgTexture.current!,
      confidenceMaskTexture,
      confidenceMaskHairTexture,
      confidenceMaskBodyTexture,
      confidenceMaskFaceTexture,
      confidenceMaskClothesTexture,
      confidenceMaskOtherTexture,
      backgroundScale.current
    );

    // Continue processing the next frame.
    window.requestAnimationFrame(predictWebcam);
    result.close();
  };

  const getCavansBgImage = () => {
    if (canvasRef.current && videoRef.current && imageRef.current) {
      resultimageRef.current = calculateBackgroundImageData(
        imageRef.current,
        canvasRef.current.width,
        canvasRef.current.height
      );
      if (webGlRef.current.gl) {
        backgroundScale.current = calculateBackgroundScale(
          VIEWPORT_WIDTH,
          VIEWPORT_HEIGHT,
          imageRef.current.width,
          imageRef.current.height
        );
        updateTexture(
          webGlRef.current.gl,
          bgTexture.current!,
          imageRef.current,
          canvasRef.current.width,
          canvasRef.current.height
        );
      }
    }
  };

  function getTimestamp() {
    const currentTimestamp = Math.floor(performance.now());
    if (currentTimestamp <= lastTimestamp) {
      lastTimestamp += 1; // Ensure it's strictly increasing
    } else {
      lastTimestamp = currentTimestamp;
    }
    return lastTimestamp;
  }

  const predictWebcam = async () => {
    if (
      canvasRef.current &&
      videoRef.current &&
      imageSegmenterRef.current &&
      imageRef.current
    ) {
      const width = videoRef.current.videoWidth;
      const height = videoRef.current.videoHeight;
      if (isFirstFrameRef.current) {
        isFirstFrameRef.current = false;
        canvasRef.current.width = width;
        canvasRef.current.height = height;
        getCavansBgImage();
      }

      let nowInMs = getTimestamp();

      if (webGlRef.current.gl) {
        // Update the foreground texture with the current video frame
        updateTexture(
          webGlRef.current.gl,
          fgTexture,
          videoRef.current,
          width,
          height
        );
      }

      imageSegmenterRef.current.segmentForVideo(
        videoRef.current,
        nowInMs,
        drawSegmentationResult
      );
    }
  };

  return {
    imageRef,
    onDestroyVirtualBackgroundHandler,
    getCavansBgImage,
    onCreateVirtualBackgroundHandler,
  };
};
