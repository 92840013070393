import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type ContactUsModalContextType = {
  showContactUsModal: boolean;
  setShowContactUsModal: Dispatch<SetStateAction<boolean>>;
};

type Props = {
  children: ReactNode;
};

const ShowContactUsModalContext = createContext<
  ContactUsModalContextType | undefined
>(undefined);

export const ContactUsModalProvider = ({ children }: Props) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);

  return (
    <ShowContactUsModalContext.Provider
      value={{ showContactUsModal, setShowContactUsModal }}
    >
      {children}
      {showContactUsModal && (
        <ModalContactAM
          subject='Covideo Subscription Update Request'
          handleModalClose={() => setShowContactUsModal(false)}
        />
      )}
    </ShowContactUsModalContext.Provider>
  );
};

export const useContactUsModal = (): ContactUsModalContextType => {
  const context = useContext(ShowContactUsModalContext);
  if (!context) {
    throw new Error(
      'useContactUsModal must be used within a ShowContactUsModalProvider'
    );
  }
  return context;
};
