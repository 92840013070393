import { useAuth } from 'lib/context';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MeetingList } from './pages/MeetingList';

export const MeetingRoutes = () => {
  const { userData } = useAuth();
  const showLiveVideo = !!Number(userData.customer.liveAccess);
  return (
    <Switch>
      {showLiveVideo && (
        <Route path='/meetings' component={MeetingList} exact={true} />
      )}
    </Switch>
  );
};
