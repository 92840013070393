import React from 'react';
import styled from 'styled-components/macro';
import { Search, Dropdown } from 'lib/components';
import {
  activeStatusOptions,
  RESELLER_STATUS_OPTIONS,
} from './ChangeResellerStatusModal';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
`;

type FilterProps = {
  marginRight?: number;
  width?: number;
};
const Filter = styled.div<FilterProps>`
  min-width: 224px;
  width: ${props => `${props.width}px` || 'auto'};
  margin-right: ${props => `${props.marginRight}px` || '0px'};
`;

type Props = {
  prevSearch?: string;
  onSearch(search: string): void;
  placeholder?: string;
  width?: number;
  selectedResellerActiveStatus?: RESELLER_STATUS_OPTIONS | null;
  onSelectResellerStatusHandler?: (
    value: RESELLER_STATUS_OPTIONS | null
  ) => void;
};

export const SearchAndFilter = ({
  prevSearch,
  onSearch,
  placeholder,
  width = 404,
  selectedResellerActiveStatus,
  onSelectResellerStatusHandler,
}: Props) => {
  return (
    <FiltersContainer>
      <Filter width={width} marginRight={12}>
        <Search
          placeholder={
            placeholder ? placeholder : 'Search by name, ID, or URL...'
          }
          onSearch={onSearch}
          prevSearch={prevSearch}
        />
      </Filter>
      {onSelectResellerStatusHandler && (
        <Dropdown
          menuPortalTarget={document.body}
          zIndexProp={1}
          menuPlacement={'bottom'}
          height={40}
          extendStyles={{ container: { width: '200px' } }}
          creatable={false}
          className='dropdown'
          placeholder={'Filter by Statuses'}
          value={activeStatusOptions.find(option => {
            return option && option.value === selectedResellerActiveStatus;
          })}
          onChange={value => onSelectResellerStatusHandler(value.value)}
          options={[
            { value: null, label: 'All statuses' },
            ...activeStatusOptions,
          ]}
        />
      )}
    </FiltersContainer>
  );
};
