import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { ModalLeave } from 'lib/components/modal/ModalLeave';

interface Props {
  when?: boolean | undefined;
  navigate?: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  onConfirm?: Function;
  onDiscard?: Function;
  onStay?: Function;
  stay?: Boolean;
  title?: string;
  text?: string;
  discardButtonText?: string;
  confirmButtonText?: string;
  showDiscardIcon?: boolean;
  showSaveIcon?: boolean;
  infoBoxText?: string;
  disableSave?: boolean;
}

const RouteLeavingGuard = ({
  when,
  navigate = () => {},
  shouldBlockNavigation,
  onConfirm = () => {},
  onDiscard = () => {},
  onStay = () => {},
  stay = false,
  title = 'Leave without saving changes?',
  text = 'Your updates will not be saved. This action can’t be undone.',
  discardButtonText = 'Discard Changes',
  confirmButtonText = 'Save Changes',
  showDiscardIcon = true,
  showSaveIcon = true,
  infoBoxText,
  disableSave,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    onConfirm();
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  const handleDiscardNavigationClick = () => {
    onDiscard();
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  const handleStay = () => {
    closeModal();
    onStay();
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ModalLeave
        open={modalVisible}
        title={title}
        text={text}
        discardButtonText={discardButtonText}
        confirmButtonText={confirmButtonText}
        showDiscardIcon={showDiscardIcon}
        showSaveIcon={showSaveIcon}
        handleModalClose={closeModal}
        onClickDiscardButton={handleDiscardNavigationClick}
        onClickConfirmButton={stay ? handleStay : handleConfirmNavigationClick}
        infoBoxText={infoBoxText}
        disableSave={disableSave}
      />
    </>
  );
};

export default RouteLeavingGuard;
