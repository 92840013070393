import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from './NewModal';

const Image = styled.img`
  max-width: 100%;
  max-height: calc(100vh - 240px);
`;

type ModalProps = {
  handleModalClose: () => void;
  src: string;
};

const FilePreviewModal = ({ handleModalClose, src }: ModalProps) => {
  return (
    <NewModal
      hideHeader
      closeModal={handleModalClose}
      style={{
        content: { padding: 0, border: 0, maxWidth: '1028px' },
      }}
    >
      <Image src={src} />
    </NewModal>
  );
};

export default FilePreviewModal;
