import { AccessRole } from 'lib/const';
import { IUserData } from 'lib/api/users/getUser';
import {
  SuperAdminRole,
  SUPER_ADMIN_CUSTOMER_IDS,
} from 'lib/const/SuperAdminRole';

export const getSuperAdminRole = (userData?: IUserData) => {
  if (!userData) {
    return SuperAdminRole.NONE;
  }

  const isSuperAdminCustomerId = !!SUPER_ADMIN_CUSTOMER_IDS[
    userData.customerId
  ];
  if (isSuperAdminCustomerId && userData.resellerAdmin && userData.admin) {
    return SuperAdminRole.COVIDEO_SUPER_ADMIN;
  }
  if (!isSuperAdminCustomerId && userData.resellerAdmin && userData.admin) {
    return SuperAdminRole.NON_COVIDEO_SUPER_ADMIN;
  }
  if (userData.resellerAdmin) {
    return isSuperAdminCustomerId
      ? SuperAdminRole.COVIDEO_RESELLER_ADMIN
      : SuperAdminRole.RESELLER_ADMIN;
  }

  if (userData.admin) {
    return isSuperAdminCustomerId
      ? SuperAdminRole.COVIDEO_ADMIN
      : SuperAdminRole.ADMIN;
  }

  if (`${userData.access}` === AccessRole.ADMIN) {
    return SuperAdminRole.ACCESS;
  }

  return SuperAdminRole.NONE;
};
