import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import queryString from 'query-string';
import styled from 'styled-components/macro';
import {
  MdEmail,
  MdFileDownload,
  MdArrowUpward,
  MdArrowDownward,
  MdRemoveRedEye,
} from 'react-icons/md';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { AccessRole, reportTypes, RESELLER_IDS } from 'lib/const';
import {
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import {
  REPORT_SCOPE,
  downloadReportData,
  fetchCustomer,
  getUsersMetrics,
} from 'lib/api';
import { addThousandCommaSeparator } from 'lib/utils/functions';

import {
  DateRangeSelector,
  timeRangeConstants,
  ReportCard,
  SendReportModal,
  LoginAsModal,
  calculateDatesForRange,
} from '../../components';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import {
  ORGANIZATION_PICKER_LINK,
  ORGANIZATION_PICKER_TITLE,
  getReportBaseUrl,
  getReportTitle,
} from './Main';
import { useAuth } from 'lib/context';
import { Breadcrumbs, Crumb } from '../../components/Breadcrumbs';
import {
  checkIfHasAccessToMultipleOrg,
  getOrgNameFromAccess,
} from 'lib/utils/organization';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import { Button } from 'react-covideo-common';
import { NotFound } from 'app/pages/notFound/NotFound';
import { useLoginAsUser } from 'lib/api/users/useLoginasUser';
import { useToastError } from 'lib/hooks';
import { IoKey } from 'react-icons/io5';

const order = {
  ASC: 'asc',
  DESC: 'desc',
};

interface ITableField {
  value: string;
  label: string;
  type: string;
  defaultOrder: string;
}

const getTableFields = (resellerId: string) => {
  return [
    {
      value: 'name',
      label: 'User',
      type: 'string',
      defaultOrder: order.ASC,
    },
    {
      value: 'recorded',
      label: 'Recorded',
      type: 'number',
      defaultOrder: order.DESC,
    },
    {
      value: 'sentEmails',
      label: 'Sent',
      type: 'number',
      defaultOrder: order.DESC,
    },
    {
      value: 'views',
      label: 'Views',
      type: 'number',
      defaultOrder: order.DESC,
    },
    {
      value: 'clicks',
      label: 'CTA Clicks',
      type: 'number',
      defaultOrder: order.DESC,
    },
    ...(resellerId === RESELLER_IDS.PENSKE_AUTOMOTIVE
      ? [
          {
            value: 'loginDate',
            label: 'Last Login',
            type: 'number',
            defaultOrder: order.DESC,
          },
        ]
      : []),
  ];
};

const getColumnWidths = (tableFields: ITableField[]) => {
  // need to append extra column width for proper alignment
  return [...tableFields.map(item => (item.value === 'name' ? 200 : 100)), 100];
};
const DetailsWrapper = styled.div`
  .dropdown-container {
    margin: 0;
  }
`;

const TableCell = styled.div<{ disabled?: boolean }>`
  padding-left: 24px;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'normal')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  align-items: center;
`;

const queryDateFormat = STANDARD_DATE_FORMAT;

type User = {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  recorded: string;
  views: string;
  sent: string;
  email: string;
  key: string;
  timestamp: number;
};

const ALL_USERS = ['all'];
const sortUsers = (
  users: User[],
  field: string,
  sortOrder: string,
  tableFields: ITableField[]
) => {
  const fieldDetails = tableFields.find(
    tableField => tableField.value === field
  );
  const sortUsers = [...users];
  const sorting = sortOrder === order.ASC ? 1 : -1;

  return sortUsers.sort((userA: any, userB: any) => {
    let valueA = userA[field]?.toString().toLowerCase();
    let valueB = userB[field]?.toString().toLowerCase();
    let result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    if (fieldDetails && fieldDetails.type === 'number') {
      valueA = parseInt(valueA, 10);
      valueB = parseInt(valueB, 10);

      result = valueA - valueB;
    }

    return result * sorting;
  });
};

export const Details = (props: any) => {
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const { organizationId } = useParams<{ organizationId: string }>();
  const { reportScope = REPORT_SCOPE.RESELLER } = props;
  const { userData } = useAuth();
  const tableFields = useMemo(
    () => getTableFields(userData.resellerId),
    [userData.resellerId]
  );
  const columnWidths = useMemo(
    () => getColumnWidths(tableFields),
    [tableFields]
  );
  const { userOrganizationAccess = [] } = userData;
  const hasMultipleOrgs = checkIfHasAccessToMultipleOrg(userOrganizationAccess);
  const organizationName = getOrgNameFromAccess(
    organizationId,
    userOrganizationAccess
  );
  const allowSignInAs =
    reportScope !== REPORT_SCOPE.ORGANIZATION ||
    userOrganizationAccess.some(
      access =>
        organizationId &&
        access.organizationId === organizationId &&
        !!access.enableSignInAs
    );

  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );

  const [startDate, setStartDate] = React.useState<Date>(start);
  const [endDate, setEndDate] = React.useState<Date>(end);
  const [dateRange, setDateRange] = React.useState(initRange);

  const [loading, setLoading] = React.useState(false);
  const [company, setCompany] = React.useState({} as any);
  const [users, setUsers] = React.useState([] as User[]);
  const [filteredUsers, setFilteredUsers] = React.useState([] as User[]);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showSendReportModal, setShowSendReportModal] = React.useState(false);
  const [sortField, setSortField] = React.useState('recorded');
  const [sortOrder, setSortOrder] = React.useState(order.DESC);
  const [showLoginAsModal, setShowLoginAsModal] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const history = useHistory();
  const { showError } = useToastError();
  const { mutateAsync: loginAsUser, isLoading: loginAsLoading } =
    useLoginAsUser();

  const { companyId } = props.match.params;

  const fetchUsers = async (
    from: Date,
    to: Date,
    search: string,
    customerId: string = ''
  ) => {
    setLoading(true);
    try {
      const customer = await fetchCustomer(customerId);
      const data = await getUsersMetrics(from, to, search, customerId);
      data.users.forEach((user: User) => {
        user.name = `${user.firstName} ${user.lastName}`;
      });
      setCompany(customer);
      setUsers(data.users);
      setLoading(false);
      setIsError(false);
    } catch (error) {
      console.log('error', error);
      setIsError(true);
      setLoading(false);
    }
  };

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
    fetchUsers(start, end, searchQuery, companyId);
  };

  const onSearch = (query: string) => {
    query = query.toLowerCase();
    setPage(0);
    setSearchQuery(query.toLowerCase());

    fetchUsers(startDate, endDate, query, companyId);
  };

  React.useEffect(() => {
    const start = page * size;
    setFilteredUsers([
      ...sortUsers(users, sortField, sortOrder, tableFields).slice(
        start,
        start + size
      ),
    ]);
  }, [users, sortField, sortOrder, page, size]);

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const downloadUsersList = () => {
    try {
      return downloadReportData(
        `/metrics/users/download`,
        'users.csv',
        startDate,
        endDate,
        [],
        companyId
      );
    } catch (error) {
      showError(error);
    }
  };

  let breadcrumbs: Crumb[] = [
    {
      label:
        reportScope === REPORT_SCOPE.ORGANIZATION && hasMultipleOrgs
          ? organizationName
          : getReportTitle(reportScope),
      link: `${getReportBaseUrl(reportScope, organizationId)}${
        location.search
      }`,
    },
    {
      label: company.business,
    },
  ];

  if (reportScope === REPORT_SCOPE.ORGANIZATION && hasMultipleOrgs) {
    breadcrumbs = [
      {
        label: ORGANIZATION_PICKER_TITLE,
        link: ORGANIZATION_PICKER_LINK,
      },
      ...breadcrumbs,
    ];
  }

  const isPenskeUser = userData.resellerId === RESELLER_IDS.PENSKE_AUTOMOTIVE;
  if (isError) {
    return <NotFound />;
  }

  return (
    <DetailsWrapper>
      <Breadcrumbs crumbs={breadcrumbs} />
      <HeaderWrapper>
        <DateRangeSelector
          initialValue={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
        <Gap>
          <Button
            text='Send'
            icon={<MdEmail size={20} />}
            onClick={() => setShowSendReportModal(true)}
          />
          <Button
            text='Download'
            icon={<MdFileDownload size={20} />}
            onClick={() => downloadUsersList()}
          />
        </Gap>
      </HeaderWrapper>
      <Gap flexWrap='nowrap' style={{ margin: '32px 0' }}>
        <ReportCard
          reportType={reportTypes.RECORDED}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={companyId}
          reportScope={reportScope}
        />
        <ReportCard
          reportType={reportTypes.SENT}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={companyId}
          reportScope={reportScope}
        />
        <ReportCard
          reportType={reportTypes.VIEWS}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={companyId}
          reportScope={reportScope}
        />
        <ReportCard
          reportType={reportTypes.CTA}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={companyId}
          reportScope={reportScope}
        />
      </Gap>
      <div style={{ width: '300px' }}>
        <Search placeholder='Search users...' onSearch={onSearch} />
      </div>
      <div style={{ marginTop: '32px' }}>
        {loading && <LoadingIndicator isLoading={loading} height='300px' />}
        {!loading && (
          <>
            {!!users.length && (
              <TableContextProvider
                total={users.length}
                onChange={onPaginationChange}
                initPage={page}
                initSize={size}
              >
                <div style={{ overflowX: 'auto' }}>
                  <Table
                    compact={true}
                    columnWidths={columnWidths}
                    headers={[
                      ...tableFields.map(item => {
                        return (
                          <TableCell
                            onClick={() => {
                              setSortField(item.value);
                              if (sortField !== item.value) {
                                setSortOrder(item.defaultOrder);
                              } else {
                                setSortOrder(
                                  sortOrder === order.ASC
                                    ? order.DESC
                                    : order.ASC
                                );
                              }
                            }}
                          >
                            {sortField === item.value && (
                              <div>
                                {sortOrder === order.ASC && (
                                  <MdArrowUpward size={16} />
                                )}
                                {sortOrder === order.DESC && (
                                  <MdArrowDownward size={16} />
                                )}
                              </div>
                            )}
                            <div style={{ marginLeft: '4px' }}>
                              {item.label}
                            </div>
                          </TableCell>
                        );
                      }),
                      '',
                    ]}
                    hoverable={false}
                    rows={filteredUsers.map((user: any, index: number) => {
                      if (isPenskeUser) {
                        user.loginDate = user.loginDate
                          ? dayjs(user.loginDate).format('MMM D YYYY')
                          : '-';
                      }
                      return {
                        key: index,
                        columns: [
                          ...tableFields.map(item => {
                            const onClick = () => {
                              history.push(
                                `${getReportBaseUrl(
                                  reportScope,
                                  organizationId
                                )}/${companyId}/users/${user.id}?start=${dayjs(
                                  startDate
                                ).format(queryDateFormat)}&end=${dayjs(
                                  endDate
                                ).format(queryDateFormat)}&range=${dateRange}`
                              );
                            };
                            if (item.type === 'number') {
                              return (
                                <TableCell onClick={onClick}>
                                  {addThousandCommaSeparator(
                                    user[item.value] || 0
                                  )}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell onClick={onClick}>
                                {user[item.value] || '-'}
                                {item.value === 'name' &&
                                  user.access.toString() ===
                                    AccessRole.SUPERVISOR && (
                                    <MdRemoveRedEye size={12} />
                                  )}
                                {item.value === 'name' &&
                                  user.access.toString() ===
                                    AccessRole.ADMIN && <IoKey size={16} />}
                              </TableCell>
                            );
                          }),
                          allowSignInAs && (
                            <TableCell
                              title='Log in as user'
                              style={{ textAlign: 'right' }}
                              disabled={loginAsLoading}
                              onClick={async () => {
                                if (loginAsLoading) {
                                  return;
                                }
                                try {
                                  await loginAsUser({
                                    userId: user.id,
                                    timestamp: user.timestamp,
                                    key: user.key,
                                  });
                                  setShowLoginAsModal(true);
                                } catch (error) {
                                  console.log(error);
                                  window.location.reload();
                                }
                              }}
                            >
                              <BsBoxArrowUpRight color='#9297A2' size={18} />
                            </TableCell>
                          ),
                        ],
                      };
                    })}
                  />
                </div>
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew globalName='users_list' />
                </TableFooter>
              </TableContextProvider>
            )}
            {!users.length && (
              <div style={{ textAlign: 'center' }}>No data to show.</div>
            )}
          </>
        )}
      </div>

      {showSendReportModal && (
        <SendReportModal
          handleModalClose={() => {
            setShowSendReportModal(false);
          }}
          startDate={startDate}
          endDate={endDate}
          range={dateRange}
          reportUrl='/metrics/users'
          customer={companyId}
        />
      )}

      {showLoginAsModal && (
        <LoginAsModal
          handleModalClose={() => {
            setShowLoginAsModal(false);
            window.location.reload();
          }}
        />
      )}
    </DetailsWrapper>
  );
};
