import styled from 'styled-components/macro';

export const WidgetTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #001b53;
  margin: 0 0 12px 0;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1008px;
`;

export const ItemsWrapper = styled(Container)<{ mb?: string }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb }) => mb || '16px'};
  > div > div:first-child {
    margin-left: 0;
  }
`;

export const Body = styled.div<{ lastRow?: boolean }>`
  display: flex;
  border-bottom: ${({ lastRow }) =>
    lastRow ? 'none' : '1px solid rgba(242, 244, 246, 1)'};
  padding: 12px 0;
  gap: 8px;
`;

export const TopVariantsTooltipWrapper = styled.div<{
  flex?: string;
}>`
  position: relative;
  display: grid;
  flex: ${({ flex }) => flex};
  :hover {
    .tooltip {
      display: block;
    }
  }
`;

export const BodyParagraph = styled.p<{
  flex?: string;
  bold?: boolean;
  opacity?: boolean;
  textAlign?: 'left' | 'right' | 'center';
}>`
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${({ opacity }) => (!opacity ? '#272a32' : '#9297a2')};
  flex: ${({ flex }) => flex};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin: 0;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Tooltip = styled.div`
  display: none;
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 14px;
  color: #9297a2;
  line-height: 24px;
  position: absolute;
  background-color: white;
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  width: max-content;
  padding: 6px 12px;
  border-radius: 6px;
  border: 1px solid rgba(242, 244, 246, 1);
  top: -40px;
  :after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(242, 244, 246, 1) transparent transparent transparent;
  }
`;
