export enum MEETING_STATUSES {
  STARTED = 'started',
  SCHEDULED = 'scheduled',
  FINISHED = 'finished',
  CANCELED = 'canceled',
  RECURRING = 'recurring',
}
export type MeetingStatus =
  | MEETING_STATUSES.SCHEDULED
  | MEETING_STATUSES.FINISHED
  | MEETING_STATUSES.STARTED
  | MEETING_STATUSES.CANCELED
  | MEETING_STATUSES.RECURRING;

type LiveMeetingUser = {
  username: string;
  email: string;
  id: number;
  firstName?: string;
  lastName?: string;
};

export type MeetingAttendee = {
  meetingId: string;
  meetingAttendeeId: number;
  email: string;
  createdAt: string;
};

export type MeetingResponse = {
  meetingId: string | null;
  userId: number;
  schedulerId: number;
  customerId: number;
  createdAt: string;
  title: string | null;
  description: string | null;
  duration: number;
  deliveryTime: string;
  deliveryUnixTimestamp: number;
  hostApproval: boolean;
  enableGuestScreenShare: boolean;
  meetingStatus: MEETING_STATUSES;
  startDate: Date;
  timezone: string;
  host?: LiveMeetingUser;
  scheduler?: LiveMeetingUser;
  landingImageUrl: string;
  attendees: MeetingAttendee[];
  nextOccurrence: number;
  frequency: string;
};

export interface MeetingRecording {
  id: number;
  meetingId: string;
  videoId: number;
  video: {
    id: number;
    userId: number;
    customerId: number;
    title: string;
    thumbnail: string;
    videoSource: string;
    videoLength: number;
    processing: number;
    recordDate: string;
    views: number;
    videoRequest?: {
      videoRequestId: number;
      vin: string | number;
    };
  };
}
