export enum CONNECTION_TYPES {
  'CONNECTED',
  'NOT CONNECTED',
  'EXPIRED',
}
export enum CONNECTION_BUTTON_TYPES {
  'RECONNECT',
  'DISCONNECT',
}

export const privacyOptions = [
  {
    value: 'private',
    text: 'Private'
  },
  {
    value: 'public',
    text: 'Public'
  },
  {
    value: 'unlisted',
    text: 'Unlisted'
  }
]
