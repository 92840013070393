import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { guidesKeyes } from './guidesKeyes';

const moveGuides = async ({
  guideCategoryId,
  videoIds,
  keepCopy,
}: {
  videoIds: number[];
  guideCategoryId: number[];
  keepCopy: string;
}): Promise<void> => {
  return (
    await EXPRESS_API.patch(
      `guides/move`,
      { videoIds, guideCategoryId },
      { params: { keepCopy } }
    )
  ).data;
};
export const useMoveGuideMutation = (resetAndCloseModal: () => void) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  const { id } = useParams() as { id: string };
  return useMutation(moveGuides, {
    onError: err => showError(err),
    onSuccess: async () => {
      await queryClient.invalidateQueries(guidesKeyes.singleCategory(id));
      queryClient.refetchQueries(guidesKeyes.categorized());
      successToast({ title: 'You have successfully moved the guides!' });
      resetAndCloseModal();
    },
  });
};
