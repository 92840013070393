import { useQuery } from 'react-query';
import { vdpQueryKeys } from './vdpQueryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getUsersWithVdp = async () => {
  return (await EXPRESS_API.get(`vdp/users`)).data;
};

export const useGetUsersWithVdp = () => {
  return useQuery(vdpQueryKeys.user_list(), getUsersWithVdp);
};
