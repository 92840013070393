import { LoadingIndicator, Tabs } from 'lib/components';
import { Tab } from 'lib/components/tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { ContactDetailsForm } from './components/ContactDetailsForm';
import styled, { useTheme } from 'styled-components/macro';
import { FaChevronLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdSave } from 'react-icons/md';
import { ContactGroups } from './components/ContactGroups';
import { ContactActivityHistory } from './components/ContactActivityHistory';
import { useParams, useHistory, useLocation } from 'react-router';
import { Contact } from 'lib/api';
import { NotFound } from 'app/pages/notFound/NotFound';
import { MainContainer } from '../../index.styled';
import { errorToast } from 'lib/components/toasts/error';
import { validateInputForCsvFormulaInjection } from 'lib/utils/functions';
import { useGetOptOutContacts } from 'lib/api/contacts/getOptOutContacts';
import { useContactsQuery } from 'lib/api/contacts/useContactsQuery';
import { useDeleteContactsMutation } from 'lib/api/contacts/useDeleteContactsMutation';
import { useUpdateContactMutation } from 'lib/api/contacts/useUpdateContactMutation';
import { useQueryContact } from 'lib/api/contacts/useQueryContact';
import { DeleteContactParams } from 'lib/api/contacts/types';

const MainWrapper = styled.div`
  margin-top: 52px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 24px 32px 32px 32px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    max-width: 1064px;
  }
  .tab-container {
    max-width: 100%;
  }
  .tab-content {
    width: 100%;
  }
  .tab-navigation {
    padding: 0;
    margin: 0 32px 0 32px;
    justify-content: left;
    .tab {
      & > div {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.33;
      }
      justify-content: center;
      align-items: center;
      margin-left: 0;
      margin-right: 32px;
    }
  }
`;

const Header = styled.div`
  height: 40px;
  font-size: 24px;
  ${theme.fontBold700}
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 24px;
  margin-bottom: 32px;
  padding-left: 32px;
`;

const NavLinkItem = styled(NavLink)`
  ${theme.fontNormal500};
  color: rgba(0, 27, 83, 0.8);
  font-size: ${theme.fontSizes.m};
  padding-left: 32px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    color: ${theme.palette.primaryDarkBlue};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1064px;
  width: 100%;
`;
type Props = {
  visible: boolean;
};
const TopWrap = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 64px;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
  background-color: white;
  padding-left: 272px;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 0.15s;
  z-index: 1;
`;

const HeaderInfo = styled.div`
  /* SUS-908 changes */
  width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  @media screen and (max-width: 768px) {
    width: 250px;
  }
  @media screen and (max-width: 1366px) {
    width: 530px;
  }
`;

const EMAIL_VALIDATION_REG = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const FAX_VALIDATION_REG = /^[0-9]+$/;

type ContactsLocationTypes = 'activity' | 'groups' | 'details' | null;

const getActiveTab = (tabParams: ContactsLocationTypes) => {
  switch (tabParams) {
    case 'activity':
      return 2;
    case 'groups':
      return 1;
    default:
      return 0;
  }
};

export const ContactDetails = () => {
  const [contact, setContact] = useState<Contact>({} as Contact);
  // @ts-ignore
  let { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabParams = searchParams.get('tab') as ContactsLocationTypes;

  const { data: contactResult, isLoading: loading } = useQueryContact(id, !!id);

  useEffect(() => {
    if (contactResult) setContact(contactResult);
  }, [contactResult]);

  const onUpdateContactMutationSuccess = (contact: Contact) => {
    setContact(contact);
    setIsTouched(false);
    setFormErrors(false);
  };

  const { mutateAsync: updateContactMutation } = useUpdateContactMutation(
    id,
    onUpdateContactMutationSuccess
  );

  const history = useHistory();

  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [formErrors, setFormErrors] = useState(false); /* SUS-908 changes */

  const queryParams = {
    search: contact.email,
    size: 100000,
  };

  const { data: contactsResult } = useContactsQuery(queryParams, true);

  const onDeleteContactsMutationSuccess = () => {
    history.goBack();
  };

  const deleteContactsParams: DeleteContactParams = {
    ids: [contact && contact.contactId],
    onDeleteContactsMutationSuccess,
  };

  const { mutateAsync: deleteContacts } =
    useDeleteContactsMutation(deleteContactsParams);

  const contactsData = {
    items: contactsResult?.contacts || [],
    count: contactsResult?.count || 0,
  };
  const { data: optOutResult } = useGetOptOutContacts({
    search: contact.email,
    limit: 100000,
  });
  const emptyOptOutContactsArray = useMemo(() => [], []);
  const optOutContacts = {
    items: optOutResult?.optOutContacts || emptyOptOutContactsArray,
    count: optOutResult?.count || 0,
  };

  const themes = useTheme();

  const validateEmail = (contact: Contact) => {
    let err = '';

    if (contact.email === '') {
      err = '';
    } else if (!EMAIL_VALIDATION_REG.test(contact.email.toLowerCase())) {
      err = 'Please enter valid email address';
    } else if (
      contactsData.items.find(
        c =>
          c.email.toLowerCase() === contact.email.toLowerCase() &&
          c.contactId !== contact.contactId
      )
    ) {
      err = 'A contact with that email already exists';
    } else if (
      optOutContacts?.items?.find(
        c => c.email.toLowerCase() === contact.email.toLowerCase()
      )
    ) {
      err = 'A contact with that email opted out';
    } else if (!validateInputForCsvFormulaInjection(contact.email)) {
      err = 'Please enter valid email address';
    } else {
      setContact(contact);
    }
    return err;
  };

  /* SUS-908 changes */
  const validateFirstName = (contact: Contact) => {
    let err = '';
    if (contact.firstName && contact.firstName.length > 60) {
      err = 'First Name can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.firstName)) {
      err = 'Please enter valid first name';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateLastName = (contact: Contact) => {
    let err = '';
    if (contact.lastName && contact.lastName.length > 60) {
      err = 'Last Name can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.lastName)) {
      err = 'Please enter valid last name';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateCompanyName = (contact: Contact) => {
    let err = '';
    if (contact.companyName && contact.companyName.length > 60) {
      err = 'Company Name can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.companyName)) {
      err = 'Please enter valid company name';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateAddress = (contact: Contact) => {
    let err = '';
    if (contact.address && contact.address.length > 60) {
      err = 'Street can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.address)) {
      err = 'Please enter valid address';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateCity = (contact: Contact) => {
    let err = '';
    if (contact.city && contact.city.length > 60) {
      err = 'City can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.city)) {
      err = 'Please enter valid city';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateState = (contact: Contact) => {
    let err = '';
    if (contact.state && contact.state.length > 60) {
      err = 'State can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.state)) {
      err = 'Please enter valid state';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateZip = (contact: Contact) => {
    let err = '';
    if (contact.zip && contact.zip.length > 60) {
      err = 'Zip can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.zip)) {
      err = 'Please enter valid Zip';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateCountry = (contact: Contact) => {
    let err = '';
    if (contact.country && contact.country.length > 60) {
      err = 'Country can not contain more than 60 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.country)) {
      err = 'Please enter valid Country';
    } else {
      setContact(contact);
    }
    return err;
  };
  /******/

  const validatePhone = (contact: Contact) => {
    let err = '';
    if (contact.phone && contact.phone.length < 7) {
      err = 'Please enter valid phone number';
    } else if (!validateInputForCsvFormulaInjection(contact.phone)) {
      err = 'Please enter valid Phone';
    } else {
      setContact(contact);
    }
    return err;
  };

  const validateFax = (contact: Contact) => {
    /*** SUS-893 changes ***/
    let err = '';
    if (contact.fax && !FAX_VALIDATION_REG.test(contact.fax)) {
      err = 'Please enter a valid fax number';
    } else if (contact.fax.length > 13) {
      err = 'Fax can not contain more than 13 characters';
    } else if (!validateInputForCsvFormulaInjection(contact.fax)) {
      err = 'Please enter valid fax number';
    } else {
      setContact(contact);
    }
    return err;
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 44) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    if (offset > 45) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onUpdate = async () => {
    if (validateEmail(contact) != '' || validatePhone(contact) != '') {
      errorToast({
        title: `${contact.firstName} ${contact.lastName} could not be updated. Please fix any issue and try again.`,
      });
    } else if (
      validateFirstName(contact) !== '' ||
      validateLastName(contact) !== '' ||
      validateCompanyName(contact) !== '' ||
      validateFax(contact) !== '' ||
      validateAddress(contact) !== '' ||
      validateCity(contact) !== '' ||
      validateState(contact) !== '' ||
      validateZip(contact) !== '' ||
      validateCountry(contact) !== ''
    ) {
      /*** SUS-893 changes ***/
      errorToast({
        title: `Contact could not be updated. Please try again later.`,
      });
    } else {
      await updateContactMutation({ ...contact });
    }
  };

  const [activeTab, setActiveTab] = useState(getActiveTab(tabParams));

  useEffect(() => {
    if (activeTab === getActiveTab(tabParams)) {
      return;
    }
    //remove search params after clicking on other tab
    if (tabParams !== null) {
      return history.replace({ pathname: location.pathname, search: '' });
    }
  }, [activeTab]);

  let tabs: Tab[] = [
    {
      title: 'Details',
      component: (
        <ContactDetailsForm
          contact={contact}
          setContact={(contact: Contact) => {
            let errors = {
              firstName: validateFirstName(contact) /*** SUS-908 changes ***/,
              lastName: validateLastName(contact) /*** SUS-908 changes ***/,
              companyName:
                validateCompanyName(contact) /*** SUS-908 changes ***/,
              email: validateEmail(contact),
              phone: validatePhone(contact),
              fax: validateFax(contact) /*** SUS-893 changes ***/,
              address: validateAddress(contact) /*** SUS-908 changes ***/,
              city: validateCity(contact) /*** SUS-908 changes ***/,
              state: validateState(contact) /*** SUS-908 changes ***/,
              zip: validateZip(contact) /*** SUS-908 changes ***/,
              country: validateCountry(contact) /*** SUS-908 changes ***/,
            };
            setIsTouched(true);
            if (
              errors.firstName === '' &&
              errors.lastName === '' &&
              errors.companyName === '' &&
              errors.email === '' &&
              errors.phone === '' &&
              errors.fax === '' &&
              errors.address === '' &&
              errors.city === '' &&
              errors.state === '' &&
              errors.zip === '' &&
              errors.country === '' &&
              (contact.email !== '' || contact.phone !== '')
            )
              setContact(contact);
            return errors;
          }}
          onDelete={deleteContacts}
          setFormErrors={setFormErrors} /* SUS-908 changes */
        />
      ),
      disabled: false,
      isEnabled: true,
    },
    {
      title: 'Groups',
      component: <ContactGroups contact={contact} />,
      disabled: false,
      isEnabled: true,
    },
    {
      title: 'Activity History',
      component: (
        <ContactActivityHistory
          contact={contact}
          preloadData={activeTab === 2}
        />
      ),
      disabled: false,
      isEnabled: true,
    },
  ];

  const renderHeader = () => {
    const hasName = !!contact.firstName || !!contact.lastName;
    return (
      <Row>
        <Header>
          {/*SUS-908 changes */}
          {hasName ? (
            <HeaderInfo>
              {contact.firstName} {contact.lastName}
            </HeaderInfo>
          ) : (
            ''
          )}
        </Header>
        {activeTab === 0 && (
          <Button
            variant='primary'
            onClick={onUpdate}
            text='Save changes'
            icon={<MdSave color={themes.colors.white[100]} />}
            disabled={!isTouched || formErrors} /*SUS-908 changes */
          />
        )}
      </Row>
    );
  };

  if (loading) {
    return <LoadingIndicator isLoading={loading} />;
  }

  if (Object.keys(contact).length < 1) {
    return <NotFound />;
  }

  return (
    <MainContainer>
      {scrolled && <TopWrap visible={visible}>{renderHeader()}</TopWrap>}
      <MainWrapper>
        <NavLinkItem to='/contacts/list'>
          <FaChevronLeft
            style={{ paddingRight: 12, paddingTop: 1, fontSize: 13 }}
            width={24}
          />
          Back
        </NavLinkItem>
        {renderHeader()}
        <Tabs
          tabs={tabs}
          onChange={setActiveTab}
          defaultActiveTab={getActiveTab(tabParams)}
        />
      </MainWrapper>
    </MainContainer>
  );
};
