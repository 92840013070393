import { useQuery } from 'react-query';
import { GetLandingPagesResponse, GetLandingPagesParams } from '../types';
import { TEMPLATE_QUERY } from 'lib/const/SendAndShare';
import { EXPRESS_API } from 'configs/api/expressApi';

const getLandingPages = async (
  params?: GetLandingPagesParams
): Promise<GetLandingPagesResponse> => {
  const response = await EXPRESS_API.get('/templates', {
    params,
  });
  return response.data;
};

export const useGetTemplatesQuery = (
  params?: GetLandingPagesParams,
  enabled?: boolean
) => {
  return useQuery([TEMPLATE_QUERY], () => getLandingPages(params), {
    refetchOnMount: true,
    enabled: enabled || true,
  });
};
