import { AxiosError } from 'axios';
import { successToast } from 'lib/components/toasts/success';
import { droplrKeys } from './queryKeys';
import { CombinedBoard, CreateBoardBody, EditBoardBody } from './types';
import { checkDropAuth, client } from './droplrAuth';
import { pick } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useToastError } from 'lib/hooks';
import { EXPRESS_API } from 'configs/api/expressApi';

const editBoard = async (data: EditBoardBody) => {
  // update droplr board
  await checkDropAuth();
  let droplrBody = pick(data, [
    'accessList',
    'groupsAccessList',
    'isPublic',
    'name',
    'poweredBy',
    'privacy',
    'uploadPrivacy',
  ]) as Partial<CreateBoardBody>;
  if (!!data.password) {
    droplrBody.password = data.password;
  }
  const droplrResponse = await client.boards.update(data.id, droplrBody);

  // create missing covideo board
  const covideoBody = pick(data, ['userId', 'customerId', 'thumbnailUrl']);
  const covideoResponse = (
    await EXPRESS_API.post('/board', {
      ...covideoBody,
      droplrBoardId: droplrResponse.id,
    })
  ).data;

  return {
    ...droplrResponse,
    thumbnailUrl: covideoResponse?.board?.thumbnailUrl || '',
  } as CombinedBoard;
};

type Params = {
  userId: number;
  customerId: number;
};

export const useEditMissingCovideoBoard = (params: Params) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation((body: EditBoardBody) => editBoard(body), {
    onSuccess: () => {
      successToast({
        title: 'Board edited successfully!',
      });
      queryClient.invalidateQueries(droplrKeys.boards_list(params));
    },
    onError: (err: AxiosError) => {
      showError(err);
    },
  });
};
