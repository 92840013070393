import * as React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { EditorState, Modifier } from 'draft-js';
import { IoCaretDown } from 'react-icons/io5';
import { theme } from 'lib/style';
import { useState } from 'react';
import { useOnClickOutside } from 'lib/utils';
import { useAuth } from 'lib/context';
import {
  GENERAL_VARIABLES,
  LEGACY_VARIABLES,
  SALES_VARIABLES,
  SERVICE_VARIABLES,
} from 'app/pages/library/videos/videoList/components/SendAndShare';

const Wrapper = styled.div<{ position?: string }>`
  ${({ position }) =>
    position === 'bottom' &&
    css`
      position: absolute;
      top: calc(100% + 8px);
      left: 0;
    `}
`;
const DropdownWrapper = styled.div`
  position: relative;
`;
const DropdownContent = styled.div`
  position: absolute;
  z-index: 999;
  right: 0;
  top: 100%;
  width: 300px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(29, 30, 36, 0.06);
  border-radius: 5px;
  padding: 8px 0;
  height: 180px;
  overflow: auto;
`;
const DropdownItem = styled.div`
  cursor: pointer;
  padding: 8px 24px;
  b,
  span {
    display: block;
  }
  b {
    margin-bottom: 4px;
    color: ${theme.palette.primaryDarkBlue};
    font-weight: 600;
    font-size: 15px;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    color: ${theme.palette.primaryDarkBlue};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary['non_alpha']};
  }
`;
type DropdownButtonProps = {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  padding?: string;
  borderRadius?: string;
};
const DropdownButton = styled.button<DropdownButtonProps>`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || '10px 16px'};
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius || '5px'};
  color: ${({ textColor }) => textColor};
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
`;
const Caret = styled(IoCaretDown)`
  margin-left: 8px;
  &.up {
    transform: rotate(180deg);
  }
`;

type Props = {
  editorState?: EditorState;
  onEditorStateChange?: (
    editorState: EditorState,
    htmlEditorContent?: string
  ) => void;
  position?: string;
  text?: string;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  onTextChange?: (text: string) => void;
  buttonPadding?: string;
  buttonBorderRadius?: string;
  extendsStyles?: {
    wrapper: React.CSSProperties;
  };
};

export const AddVariableButton = ({
  editorState,
  onEditorStateChange,
  position,
  text = 'Variables',
  backgroundColor,
  textColor = theme.palette.primaryDarkBlue,
  borderColor = theme.palette.blue05,
  onTextChange,
  buttonPadding,
  buttonBorderRadius,
  extendsStyles,
}: Props) => {
  const themes = useTheme();
  const { ref } = useOnClickOutside(() => {
    setShowDropdown(false);
  });
  const { userData } = useAuth();
  let variables = GENERAL_VARIABLES;
  if (!userData.isAutomotive) {
    variables = [...variables, ...LEGACY_VARIABLES];
  }
  if (userData.isAutomotiveSalesRole) {
    variables = [...variables, ...SALES_VARIABLES];
  }
  if (userData.isAutomotiveServiceRole) {
    variables = [...variables, ...SERVICE_VARIABLES];
  }

  const [showDropdown, setShowDropdown] = useState(false);
  const addText = (text: string) => {
    if (onTextChange) {
      onTextChange(text);
    }
    if (!!onEditorStateChange && !!editorState) {
      onEditorStateChange(
        EditorState.push(
          editorState,
          Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
            editorState.getCurrentInlineStyle()
          ),
          'insert-characters'
        )
      );
    }
    setShowDropdown(false);
  };

  return (
    <Wrapper
      ref={ref}
      position={position}
      style={{ ...extendsStyles?.wrapper, zIndex: showDropdown ? 1 : 0 }}
    >
      <DropdownWrapper>
        <DropdownButton
          onClick={() => setShowDropdown(!showDropdown)}
          textColor={textColor}
          backgroundColor={
            backgroundColor || themes.colors.secondary['non_alpha']
          }
          borderColor={borderColor}
          padding={buttonPadding}
          borderRadius={buttonBorderRadius}
        >
          {text}
          <Caret
            size={10}
            color={textColor}
            className={showDropdown ? 'up' : 'down'}
          />
        </DropdownButton>
        {showDropdown && (
          <DropdownContent>
            {variables.map((d, i) => (
              <DropdownItem
                key={`dropdown-item-${i}`}
                onClick={() => addText(d.value)}
              >
                <b>{d.value}</b>
                <span>{d.text}</span>
              </DropdownItem>
            ))}
          </DropdownContent>
        )}
      </DropdownWrapper>
    </Wrapper>
  );
};
