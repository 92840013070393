import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { crmIntegrationsKeys } from './queryKeys';
import { CrmAccountManagerResponse } from './types';

export const getCrmAccountManagers =
  async (): Promise<CrmAccountManagerResponse> => {
    const response = await EXPRESS_API.get(`/crm/account-managers`);
    return response.data as CrmAccountManagerResponse;
  };

export const useGetAccountManagersQuery = ({
  enabled,
}: {
  enabled: boolean;
}) => {
  return useQuery(
    crmIntegrationsKeys.accountManagers(),
    () => getCrmAccountManagers(),
    { enabled }
  );
};
