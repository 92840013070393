import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { UpdateVideoDeleteParams } from './types';
import { videoDeletesKeys } from './queryKeys';

export const updateVideoDelete = async (data: UpdateVideoDeleteParams) => {
  const { deleteId, ...rest } = data;
  const response = await EXPRESS_API.put(`/video-deletes/${deleteId}`, rest);
  return response.data;
};

export const useUpdateVideoDeleteMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(updateVideoDelete, {
    onSuccess: () => {
      successToast({
        title: 'Video deletion updated!',
      });
      onSuccess();
      queryClient.refetchQueries(videoDeletesKeys.get_video_delete());
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred updating video deletion, please try again later!',
      });
    },
  });
};
