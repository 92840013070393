import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { guidesKeyes } from './guidesKeyes';

const togglePrivacy = async ({
  isPrivate,
  categoryId,
}: {
  isPrivate: boolean;
  categoryId: number;
}): Promise<{ message: string }> => {
  return (
    await EXPRESS_API.patch(`guide-category/${categoryId}/privacy`, {
      isPrivate,
    })
  ).data as { message: string };
};

export const usePrivateGuidesQueryMutation = () => {
  const queryClient = useQueryClient();

  const { showError } = useToastError();
  return useMutation(togglePrivacy, {
    onError: err => showError(err),
    onSuccess: async (_, variables) => {
      await queryClient.refetchQueries(
        guidesKeyes.singleCategory(`${variables.categoryId}`)
      );
      await queryClient.invalidateQueries(guidesKeyes.categorized());
      successToast({ title: 'You have successfully changed privacy!' });
    },
  });
};
