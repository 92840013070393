import styled from 'styled-components/macro';
import * as React from 'react';
import * as theme from 'lib/style/theme';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { Viewer } from 'lib/api/recipients/types';
import { Spinner } from 'lib/components';
import { useVideoViewersNotify } from 'lib/api/videos/useVideoViewersQuery';
import { Tooltip } from 'react-covideo-common';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
`;

type Props = {
  videoId: string;
  viewer: Viewer;
  showTooltip?: boolean;
};

export const CheckboxInputNotify = (props: Props) => {
  const { videoId, viewer, showTooltip = false } = props;
  const [checked, setChecked] = React.useState(
    viewer.shortUrl?.notifyUser || false
  );
  const { mutateAsync, isLoading: loading } = useVideoViewersNotify();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    setChecked(isChecked);
    mutateAsync({ videoId, recipientId: viewer.receiverId, notify: isChecked });
  };

  if (showTooltip) {
    return (
      <Tooltip
        position={'top' as any}
        hoverable={true}
        extendStyles={{
          popup: {
            width: '165px',
            left: '50%',
            lineHeight: '24px',
            textAlign: 'left',
          },
        }}
        popup={
          <div>
            You will be notified
            <br />
            when this recipient
            <br />
            views this video.
          </div>
        }
      >
        {' '}
        <CheckboxWrapper>
          {loading ? (
            <Spinner color={theme.palette.themeDark} size={10} thickness={2} />
          ) : (
            <CheckboxInput
              checked={checked}
              onChange={(e: any) => handleChange(e)}
            />
          )}
        </CheckboxWrapper>
      </Tooltip>
    );
  }
  return (
    <CheckboxWrapper>
      {loading ? (
        <Spinner color={theme.palette.themeDark} size={10} thickness={2} />
      ) : (
        <CheckboxInput
          checked={checked}
          onChange={(e: any) => handleChange(e)}
        />
      )}
    </CheckboxWrapper>
  );
};
