export const CSV_FORMULA_INJECTION_PATTERN = [
  '=',
  '+',
  '-',
  '@',
  '0x09',
  '0x0D',
];

export const CSV_FORMULA_INJECTION_REGEX = /^(?![=+\-@]).*$/;

const COMMAND_KEYWORDS = [
  // Shell  keywords
  'cd',
  'ls',
  'pwd',
  'mkdir',
  'rm',
  'mv',
  'cp',
  'touch',
  'cat',
  'grep',
  'find',
  'chmod',
  'chown',
  'ps',
  'kill',
  'ssh',
  'scp',
  'tar',
  'gzip',
  'gunzip',
  'uname',
  'ifconfig',
  'ip',
  'ping',
  'traceroute',
  'sed',
  'awk',
  'cut',
  'echo',
  'whoami',
  'wget',
  'curl',
  'tee',
  // Bash keywords
  'alias',
  'declare',
  'export',
  'source',
  'function',
  'exec',
  'local',
  'history',
  'jobs',
  'bg',
  'fg',
  'wait',
  'nohup',
  'disown',
  'trap',
  'eval',
  'shift',
  'getopts',
  'read',
  'select',
  'time',
  'ulimit',
  'readarray',
  'mapfile',
  'popd',
  'pushd',
  'dirs',
  'complete',
  'shopt',
  'logout',
  'bash',
  // Windows
  'dir',
  'copy',
  'xcopy',
  'robocopy',
  'move',
  'del',
  'ren',
  'md',
  'rd',
  'rmdir',
  'type',
  'find',
  'findstr',
  'for',
  'if',
  'goto',
  'call',
  'set',
  'setlocal',
  'endlocal',
  'cls',
  'color',
  'title',
  'timeout',
  'choice',
  'tasklist',
  'taskkill',
  'shutdown',
  'net',
  'sc',
  'reg',
  'wmic',
  'powershell',
  'wmic',
  'cmd',
];

export const BASE_COMMAND_KEYWORDS_REGEX =
  /(?:;|&&|\|\||`|\\|\$\(.*\)|<|>|>>|<<|!|\b(?:sh|nc|netcat|telnet|ftp|mysql|psql|id)\b)/i;

export const COMMAND_KEYWORDS_REGEX = new RegExp(
  BASE_COMMAND_KEYWORDS_REGEX.source + '|' + COMMAND_KEYWORDS.join('|'),
  'i'
);
