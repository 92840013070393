import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Main } from './main';

export const RecentlyDeleted = () => {
  return (
    <>
      <DocumentHead title="Recently deleted" />
      <PageTemplate main={<Main />} />
    </>
  );
};
