import * as React from 'react';
import styled from 'styled-components/macro';
import { VideoPlayer } from '../../videoPlayer';
import { Switch } from 'lib/components';
import { useAuth } from 'lib/context';
import RouteLeavingGuard from './RouteLeavingGuard';
import { useHistory } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {
  deleteVideoPassword,
  getVideoPassword,
  updateVideoPassword,
} from 'lib/api';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Heading } from 'lib/components/styles/typography';
import { InputLabel, InputWrapper, ItemContainer } from '../styles/layout';
import { HeaderWrapper } from 'lib/components/styles/layout';
import { useTheme } from 'styled-components/macro';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';
import { MdSave } from 'react-icons/md';

interface ComponentProps {
  videoTitle: string;
  videoId: string;
  personalThumbnail: string;
  playerBackgroundColor: string;
  playerIconsAndTextColor: string;
  playButtonPosition: string;
  videoSource: string;
  passwordEnabled?: boolean;
}

const FieldContainer = styled.section`
  position: relative;
`;

const PasswordInput = styled.input`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem;
  box-sizing: border-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  padding-right: 40px;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const EyeProperties = `
position: absolute;
right: 11px;
top: 7px;
&:hover {
  cursor: pointer;
}
`;

const EyeIcon = styled(FaEye)`
  ${EyeProperties}
`;

const EyeIconSlash = styled(FaEyeSlash)`
  ${EyeProperties}
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 468px;
`;
const SecurityItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const TitleSwitch = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #272a32;
  min-width: 180px;
`;
const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
  margin-bottom: 16px;
`;
const Divider = styled.div`
  height: 1px;
  background: #f2f4f6;
  margin: 31px 0px;
`;
const SecurityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
`;

export const Security = (props: ComponentProps) => {
  const {
    videoId,
    videoSource,
    playerBackgroundColor,
    playButtonPosition,
    playerIconsAndTextColor,
    videoTitle,
    passwordEnabled,
  } = props;

  const history = useHistory();

  const { userData } = useAuth();

  const [enabled, setEnabled] = React.useState(passwordEnabled);
  const [password, setPassword] = React.useState('');
  const [passwordId, setPasswordId] = React.useState('');
  const [type, setType] = React.useState('password');
  const [isDirty, setIsDirty] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);

  const videoRef = React.useRef<HTMLVideoElement>();

  const themes = useTheme();

  React.useEffect(() => {
    if (!passwordEnabled) {
      return;
    }
    getVideoPassword(videoId)
      .then(data => {
        if (data && data.password) {
          setEnabled(true);
          setPassword(data.password);
          setPasswordId(data.securityId);
        }
      })
      .catch(() => {
        setEnabled(false);
      });
  }, []);

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id) {
        const isPasswordAllowed = checkIfFeatureIsEnabled(
          userData,
          productFeature.PASSWORD_PROTECTION
        );
        if (!isPasswordAllowed) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, []);

  const handleSubmit = async () => {
    if (password) {
      await handlePasswordChange();
    }
  };

  const handlePasswordChange = async () => {
    if (enabled) {
      const res = await updateVideoPassword(videoId, password).catch(
        err => err
      );
      if (res instanceof Error) {
        errorToast({
          title: 'Whoops! Error saving password. Try again later!',
        });
      } else {
        setPasswordId(res && res.securityId);
        successToast({
          title: `Video security updated successfully!`,
        });
      }
    } else {
      const res = await deleteVideoPassword(videoId, passwordId).catch(
        err => err
      );
      if (res instanceof Error) {
        errorToast({
          title: 'Whoops! Error saving password. Try again later!',
        });
      } else {
        successToast({
          title: `Video security updated successfully!`,
        });
      }
    }
    setIsDirty(false);
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
    setIsDirty(true);
    setIsTouched(true);
  };

  const handleEnabledToggle = () => {
    setEnabled(!enabled);
    setIsDirty(true);
    setIsTouched(true);
  };

  const handleShowClick = () => {
    setType(type === 'text' ? 'password' : 'text');
  };

  return (
    <>
      <HelpDesk name={EHelpDesk.SECURITY} />
      <RouteLeavingGuard
        when={true}
        navigate={path => history.push(path)}
        onConfirm={handleSubmit}
        shouldBlockNavigation={() => {
          return isDirty;
        }}
      />
      <HeaderWrapper>
        <Heading>{videoTitle}</Heading>
        <Button
          text='Save Changes'
          icon={<MdSave size={18} />}
          onClick={handleSubmit}
          disabled={!isTouched || (enabled && !password)}
        />
      </HeaderWrapper>
      <SecurityContainer>
        <LeftSide>
          <SecurityItem>
            <TitleSwitch>
              <Title>Password protected</Title>
              <Switch
                id='password-protected'
                handleToggle={handleEnabledToggle}
                isOn={enabled}
                onColor={themes.colors.primary[100]}
              />
            </TitleSwitch>
            <Description>
              Viewers can access video only with the password you set
            </Description>
            {enabled && (
              <InputWrapper>
                <InputLabel>Video Password:</InputLabel>
                <FieldContainer>
                  <PasswordInput
                    type={type}
                    value={password}
                    autoComplete='new-password'
                    onChange={handleChangePassword}
                  />
                  {type === 'password' && (
                    <EyeIconSlash size={24} onClick={handleShowClick} />
                  )}
                  {type === 'text' && (
                    <EyeIcon size={24} onClick={handleShowClick} />
                  )}
                </FieldContainer>
              </InputWrapper>
            )}
          </SecurityItem>
          <Divider />
        </LeftSide>
        <ItemContainer>
          <VideoPlayer
            playerBackgroundColor={playerBackgroundColor}
            videoSource={videoSource}
            videoId={videoId}
            playButtonPosition={playButtonPosition}
            playerIconsColor={playerIconsAndTextColor}
            videoRef={videoRef}
            height='280px'
            preventWideSize={true}
          />
        </ItemContainer>
      </SecurityContainer>
    </>
  );
};
