import { FieldArray, Form, Formik, useField } from 'formik';
import { InteractiveCTA } from 'lib/api';
import { ButtonSwitch } from 'lib/components';
import { CREDIT_700_CTA_TITLE, CREDIT_700_CTA_TYPE } from 'lib/const/700credit';
import { theme } from 'lib/style';
import { reorder } from 'lib/utils/array';
import { getCTATypes } from 'lib/utils/functions';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-covideo-common';
import { IoMdReorder } from 'react-icons/io';
import { MdAdd, MdDeleteForever, MdEdit, MdOpenInNew } from 'react-icons/md';
import Select from 'react-select';
import styled from 'styled-components/macro';
import { CtaFilesModal, ItemTypes } from './CtaFilesModal';
import { CtaVideoModal } from './CtaVideoModal';

const CTA_TYPE = {
  VIDEO: 'video',
  FILE: 'library',
  BOARD: 'board',
  URL: 'url',
  EMAIL: 'email',
  CREDIT_700_CTA_TYPE: CREDIT_700_CTA_TYPE,
};

interface Props {
  interactiveCTAsData: InteractiveCTA[];
  setInteractiveCTAsFormState: Function;
  hasDroplrAccess: boolean;
  credit700Url: string;
  setIsInteractiveCtaDirty: (value: boolean) => void;
}
interface LabelProps {
  width?: string;
  padding?: string;
}
interface TextInputProps {
  placeholder?: string;
  name: string;
  type?: string;
  width?: string;
  maxLength?: number;
  onBlur?: () => void;
  disabled?: boolean;
  hasError?: boolean;
}
interface ThumbnailProps {
  image: string;
  width?: string;
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 442px;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const Input = styled.input<{ width?: string; hasError?: boolean }>`
  width: ${props => (props.width ? props.width : '100%')};
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  border: ${props => (props.hasError ? 'solid 1px red' : 'solid 1px #d0d3d9')};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: normal;
  padding: 8px 12px;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;
export const LinksHeader = styled.div`
  display: flex;
  margin-top: 16px;
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 15px;
`;
export const LinkItem = styled.div<{ height?: string }>`
  border-bottom: 1px solid #f4f4f4;
  height: ${props => (props.height ? props.height : '40px')};
  padding: 8px 0;
  display: flex;
`;
export const Label = styled.div<LabelProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
  width: ${props => (props.width ? props.width : 'auto')};
  padding: ${props => (props.padding ? props.padding : '0')};
  margin-right: 12px;
`;
const DraggableItem = styled.div`
  display: flex;
`;
const DragIconWrapper = styled.div`
  font-size: 16px;
  width: 48px;
  height: 56px;
  display: flex;
  align-items: center;
  min-width: 48px;
  margin-top: auto;
  margin-bottom: auto;
`;
export const FieldWrapper = styled.div<{ width: string }>`
  width: ${props => (props.width ? props.width : 'auto')};
  margin-right: 12px;
  &.align-center {
    display: flex;
    align-items: center;
  }
`;
const StyledButtonSwitch = styled(ButtonSwitch)`
  width: 131px;
  height: 40px;
`;
const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  cursor: pointer;
  height: 40px;
  position: relative;
`;
const FilesThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${theme.palette.white};
  width: ${props => (props.width ? props.width : '32px')};
  height: 32px;
  flex-shrink: 0;
  margin-left: 4px;
`;

const FilesTitle = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const switchOptions = [
  {
    value: 'show',
    text: 'Show',
  },
  {
    value: 'hide',
    text: 'Hide',
  },
];
const TextInput = (props: TextInputProps) => {
  const [field] = useField(props);

  if (!field.value) {
    field.value = '';
  }

  return <Input {...field} {...props} />;
};

export const InteractiveCTAs = (props: Props) => {
  const {
    interactiveCTAsData,
    setInteractiveCTAsFormState,
    hasDroplrAccess,
    credit700Url,
    setIsInteractiveCtaDirty,
  } = props;
  const initialValues = {
    data: interactiveCTAsData,
  };
  const [showCtaFilesModal, setShowCtaFilesModal] = useState(0);
  const ctaTypes = getCTATypes(hasDroplrAccess, !!credit700Url);
  const [view, setView] = useState(ItemTypes[0].value);
  const [showCtaVideoModal, setShowCtaVideoModal] = useState(0);

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {({ values, dirty, setFieldValue }) => {
          setIsInteractiveCtaDirty(dirty);
          setInteractiveCTAsFormState(values.data);
          const onDragEnd = async (result: any) => {
            const { source, destination } = result;
            if (!destination) {
              return;
            }
            const updatedItems = reorder(
              values.data,
              source.index,
              destination.index
            );
            setFieldValue(`data`, updatedItems);
          };
          return (
            <Form id='cta-form'>
              {values.data.length > 0 && (
                <LinksHeader>
                  <Label width={`200px`} padding='0 0 0 48px'>
                    Display Name
                  </Label>
                  <Label width='146px'>Link type</Label>
                  <Label width='261px'>URL/File</Label>
                  <Label width='64px'>Preview</Label>
                  <Label width='64px'>Visibility</Label>
                  <Label width='64px'></Label>
                </LinksHeader>
              )}
              <FieldArray
                name='data'
                render={arrayHelpers => (
                  <>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId='cta-dropp-container'>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {values?.data?.map((ctaValue, index: number) => (
                              <Draggable
                                draggableId={`cta-draggable-${index}`}
                                key={`cta-draggable-${index}`}
                                index={index}
                                isDragDisabled={
                                  !!showCtaFilesModal || !!showCtaVideoModal
                                }
                              >
                                {provided => (
                                  <DraggableItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <DragIconWrapper>
                                      <IoMdReorder
                                        color={theme.palette.gray40}
                                      />
                                    </DragIconWrapper>
                                    <LinkItem>
                                      <FieldWrapper width={`200px`}>
                                        <TextInput
                                          name={`data[${index}].title`}
                                          type='text'
                                          placeholder='Display Name'
                                          maxLength={35}
                                          hasError={
                                            values.data[index].title === ''
                                          }
                                        />
                                      </FieldWrapper>
                                      <FieldWrapper width='146px'>
                                        <Select
                                          styles={{
                                            control: (base: any) => ({
                                              ...base,
                                              height: '40px',
                                            }),
                                            indicatorSeparator: () => ({
                                              display: 'none',
                                            }),
                                            menuPortal: (base: any) => ({
                                              ...base,
                                              zIndex: 9999,
                                            }),
                                          }}
                                          menuPortalTarget={document.body}
                                          menuPosition='fixed'
                                          menuPlacement={'bottom'}
                                          options={ctaTypes}
                                          value={ctaTypes.find(o => {
                                            return (
                                              o.value ===
                                              (values.data[index].type ||
                                                CTA_TYPE.URL)
                                            );
                                          })}
                                          onChange={(option: any) => {
                                            setFieldValue(
                                              `data[${index}].type`,
                                              option.value
                                            );
                                            setFieldValue(
                                              `data[${index}].linkValue`,
                                              option.value ===
                                                CTA_TYPE.CREDIT_700_CTA_TYPE
                                                ? credit700Url
                                                : ''
                                            );
                                            if (
                                              option.value ===
                                              CTA_TYPE.CREDIT_700_CTA_TYPE
                                            ) {
                                              setFieldValue(
                                                `data[${index}].title`,
                                                CREDIT_700_CTA_TITLE
                                              );
                                            }

                                            setFieldValue(
                                              `data[${index}].fileTitle`,
                                              ''
                                            );
                                            setFieldValue(
                                              `data[${index}].fileThumbnail`,
                                              ''
                                            );
                                            setFieldValue(
                                              `data[${index}].itemType`,
                                              ''
                                            );
                                          }}
                                        />
                                      </FieldWrapper>

                                      <FieldWrapper width={`261px`}>
                                        {values.data[index].type ===
                                          CTA_TYPE.CREDIT_700_CTA_TYPE && (
                                          <FieldWrapper width={`261px`}>
                                            <Input
                                              type='text'
                                              disabled={true}
                                              value={credit700Url}
                                            />
                                          </FieldWrapper>
                                        )}
                                        {(values.data[index].type ===
                                          CTA_TYPE.URL ||
                                          values.data[index].type ===
                                            CTA_TYPE.EMAIL) && (
                                          <TextInput
                                            name={`data[${index}].url`}
                                            type={
                                              values.data[index].type ===
                                              CTA_TYPE.EMAIL
                                                ? 'email'
                                                : 'text'
                                            }
                                            placeholder={
                                              values.data[index].type ===
                                              CTA_TYPE.EMAIL
                                                ? 'john@covideo.com'
                                                : 'https://'
                                            }
                                            hasError={
                                              values.data[index].url === ''
                                            }
                                            disabled={
                                              values.data[index].type ===
                                              CTA_TYPE.CREDIT_700_CTA_TYPE
                                            }
                                          />
                                        )}
                                        {(values.data[index].type ===
                                          CTA_TYPE.FILE ||
                                          values.data[index].type ===
                                            CTA_TYPE.BOARD) && (
                                          <>
                                            {values.data[index].fileTitle && (
                                              <FileWrapper
                                                onClick={() => {
                                                  setShowCtaFilesModal(
                                                    index + 1
                                                  );
                                                }}
                                              >
                                                {!values.data[
                                                  index
                                                ].fileTitle.includes(
                                                  '.csv'
                                                ) && (
                                                  <FilesThumbnail
                                                    width={'64px'}
                                                    image={
                                                      values.data[index]
                                                        .fileThumbnail
                                                    }
                                                  />
                                                )}
                                                <FilesTitle>
                                                  {values.data[index].fileTitle}
                                                </FilesTitle>
                                                <MdEdit
                                                  size='18px'
                                                  color='#9297A2'
                                                  style={{
                                                    position: 'absolute',
                                                    right: '12px',
                                                  }}
                                                />
                                              </FileWrapper>
                                            )}
                                            {!values.data[index].fileTitle &&
                                              values.data[index].type ===
                                                CTA_TYPE.FILE && (
                                                <Button
                                                  variant='secondary'
                                                  text='Select File...'
                                                  onClick={() => {
                                                    setShowCtaFilesModal(
                                                      index + 1
                                                    );
                                                    setView('file');
                                                  }}
                                                />
                                              )}
                                            {!values.data[index].fileTitle &&
                                              values.data[index].type ===
                                                CTA_TYPE.BOARD && (
                                                <Button
                                                  variant='secondary'
                                                  text='Select Board...'
                                                  onClick={() => {
                                                    setShowCtaFilesModal(
                                                      index + 1
                                                    );
                                                    setView(CTA_TYPE.BOARD);
                                                  }}
                                                />
                                              )}
                                            {showCtaFilesModal ===
                                              index + 1 && (
                                              <CtaFilesModal
                                                hideToggle={true}
                                                view={view}
                                                selectedFile={
                                                  values.data[index]
                                                }
                                                handleModalClose={() => {
                                                  setShowCtaFilesModal(0);
                                                }}
                                                onSelect={(
                                                  drop: any,
                                                  itemType: string
                                                ) => {
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaFilesModal - 1
                                                    }].linkValue`,
                                                    drop.shortlink
                                                  );
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaFilesModal - 1
                                                    }].fileTitle`,
                                                    drop.title || drop.name
                                                  );
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaFilesModal - 1
                                                    }].fileThumbnail`,
                                                    drop.previewSmall ||
                                                      drop.thumbnailUrl ||
                                                      drop.content
                                                  );
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaFilesModal - 1
                                                    }].itemType`,
                                                    itemType
                                                  );
                                                  setShowCtaFilesModal(0);
                                                }}
                                              />
                                            )}
                                          </>
                                        )}
                                        {values.data[index].type ===
                                          CTA_TYPE.VIDEO && (
                                          <>
                                            {values.data[index].fileTitle && (
                                              <FileWrapper
                                                onClick={() => {
                                                  setShowCtaVideoModal(
                                                    index + 1
                                                  );
                                                }}
                                              >
                                                <FilesThumbnail
                                                  width={'48px'}
                                                  image={
                                                    values.data[index]
                                                      .fileThumbnail
                                                  }
                                                />
                                                <FilesTitle>
                                                  {values.data[index].fileTitle}
                                                </FilesTitle>
                                                <MdEdit
                                                  size='18px'
                                                  color={
                                                    theme.palette
                                                      .primaryDarkBlue
                                                  }
                                                  style={{
                                                    position: 'absolute',
                                                    right: '12px',
                                                  }}
                                                />
                                              </FileWrapper>
                                            )}
                                            {!values.data[index].fileTitle && (
                                              <Button
                                                variant='secondary'
                                                text='Select Video...'
                                                onClick={() =>
                                                  setShowCtaVideoModal(
                                                    index + 1
                                                  )
                                                }
                                              />
                                            )}
                                            {showCtaVideoModal ===
                                              index + 1 && (
                                              <CtaVideoModal
                                                handleModalClose={() => {
                                                  setShowCtaVideoModal(0);
                                                }}
                                                onSelect={(video: any) => {
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaVideoModal - 1
                                                    }].linkValue`,
                                                    video.videoSource
                                                  );
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaVideoModal - 1
                                                    }].fileTitle`,
                                                    video.title
                                                  );
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaVideoModal - 1
                                                    }].fileThumbnail`,
                                                    video.thumbnail
                                                  );
                                                  setFieldValue(
                                                    `data[${
                                                      showCtaVideoModal - 1
                                                    }].itemType`,
                                                    ''
                                                  );
                                                  setShowCtaVideoModal(0);
                                                }}
                                              />
                                            )}
                                          </>
                                        )}
                                      </FieldWrapper>
                                      <FieldWrapper width='fit-content'>
                                        <Button
                                          icon={<MdOpenInNew size='18px' />}
                                          variant='secondary'
                                          onClick={() => {
                                            if (
                                              values.data[index].type ===
                                              CTA_TYPE.CREDIT_700_CTA_TYPE
                                            ) {
                                              window.open(credit700Url);
                                              return;
                                            }

                                            let linkValue =
                                              values.data[index].linkValue;
                                            if (
                                              values.data[index].type ===
                                              CTA_TYPE.URL
                                            ) {
                                              linkValue =
                                                values.data[index].url;
                                            }

                                            window.open(
                                              values.data[index].type ===
                                                CTA_TYPE.EMAIL
                                                ? `mailto:${linkValue}`
                                                : linkValue.includes('http')
                                                  ? linkValue
                                                  : `http://${linkValue}`,
                                              '_blank'
                                            );
                                          }}
                                        />
                                      </FieldWrapper>
                                      <FieldWrapper width={`130px`}>
                                        <StyledButtonSwitch
                                          defaultValue={
                                            ctaValue.isVisible ? 'show' : 'hide'
                                          }
                                          values={switchOptions}
                                          onChange={(newValue: string) => {
                                            setFieldValue(
                                              `data[${index}].isVisible`,
                                              newValue === 'show' ? true : false
                                            );
                                          }}
                                        />
                                      </FieldWrapper>
                                      <FieldWrapper width='44px'>
                                        {
                                          <Button
                                            icon={
                                              <MdDeleteForever size='24px' />
                                            }
                                            variant='destructive'
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                          />
                                        }
                                      </FieldWrapper>
                                    </LinkItem>
                                  </DraggableItem>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <LinkItem>
                      <Button
                        variant='secondary'
                        disabled={values.data.length >= 3}
                        text='New CTA'
                        icon={<MdAdd size={20} />}
                        onClick={() => {
                          arrayHelpers.push({
                            title: '',
                            url: '',
                            isVisible: true,
                            type: CTA_TYPE.URL,
                            fileTitle: '',
                            fileThumbnail: '',
                            linkValue: '',
                          });
                        }}
                      />
                    </LinkItem>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
