import React, { ReactNode } from 'react';
import { Chip } from '../../socialProfiles/style';
import {
  CardWrapper,
  Header,
  TextInfo,
  Title,
  ButtonWrapper,
  ButtonContainer,
} from './style';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdEdit } from 'react-icons/md';
import { useIsMutating } from 'react-query';

interface IDeletionCard {
  icon: ReactNode;
  title: string;
  isActive: boolean;
  isEdit?: boolean;
  textInfo: string;
  activateFn?: () => void;
  deactivateFn?: () => void;
  editFn?: () => void;
  activateText: string;
  deactivateText: string;
}

const inactiveBgColor = theme.palette.destructiveBg;
const inactiveFontColor = 'rgba(232, 76, 61, 0.8)';
const activeBgColor = 'rgba(30, 163, 95, 0.05)';
const activeFontColor = 'rgba(30, 163, 95, 0.8)';

const Card = ({
  icon,
  title,
  isActive,
  isEdit,
  textInfo,
  activateFn,
  deactivateFn,
  editFn,
  activateText,
  deactivateText,
}: IDeletionCard) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  return (
    <CardWrapper>
      <Header>
        {icon}
        <Title>{title}</Title>
        <Chip
          bgColor={isActive ? activeBgColor : inactiveBgColor}
          fontColor={isActive ? activeFontColor : inactiveFontColor}
        >
          {isActive ? 'Active' : 'Inactive'}
        </Chip>
      </Header>
      <TextInfo>{textInfo}</TextInfo>
      <ButtonContainer>
        {isEdit && (
          <ButtonWrapper>
            <Button
              icon={<MdEdit size={15} />}
              onClick={editFn}
              variant='secondary'
              text='Edit'
              size='small'
              disabled={isDisabled}
            />
          </ButtonWrapper>
        )}
        {!isActive ? (
          <ButtonWrapper>
            <Button
              variant='secondary'
              onClick={activateFn}
              text={activateText}
              size='small'
              disabled={isDisabled}
            />
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <Button
              variant='destructive'
              size='small'
              text={deactivateText}
              onClick={deactivateFn}
              disabled={isDisabled}
            />
          </ButtonWrapper>
        )}
      </ButtonContainer>
    </CardWrapper>
  );
};

export { Card };
