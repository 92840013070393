import React, { useState } from 'react';
import { EditLocationCustomerModal } from '../../modals/EditChildCustomerLocationModal';
import { MultiItemHeader } from '../Header';
import { AddLocationView } from './AddLocationView';
import { EditLocationView } from './EditLocationView';
import { Gap } from 'lib/components/styles/layout';
import { useFormikContext } from 'formik';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { ParentCustomer } from 'lib/api/multiLocations/multiLocationQuery';
interface IProps {
  isEditMode: boolean;
}

const LocationsWrapper = styled.div<{ isTopLevel: boolean }>`
  min-width: 280px;
  max-width: 280px;
  background: ${theme.palette.white};
  padding-bottom: ${({ isTopLevel }) => (isTopLevel ? '76px' : '11px')};
  position: relative;
  :after {
    content: '';
    background: linear-gradient(90deg, #e9ecf2 0%, rgba(247, 249, 250, 0) 100%);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    right: -6px;
    top: 48px;
    bottom: 0;
    width: 6px;
  }
`;

export const LocationColumns = ({ isEditMode }: IProps) => {
  const { values } = useFormikContext<ParentCustomer>();
  const [showModal, setShowModal] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<null | {
    name: string | null;
    locationId: number | null;
  }>(null);

  const closeModalHandler = () => {
    setShowModal(false);
    setCurrentLocation(null);
  };

  const openModalHandler = (name: string | null, locationId: number | null) => {
    setShowModal(true);
    setCurrentLocation({ name, locationId });
  };

  return (
    <LocationsWrapper isTopLevel={!!values.business}>
      <MultiItemHeader headerName='Locations' />
      <Gap flexDirection='column' alignItems='flex-start' gap='0'>
        {isEditMode ? (
          <EditLocationView openModalHandler={openModalHandler} />
        ) : (
          <AddLocationView />
        )}

        {showModal && (
          <EditLocationCustomerModal
            closeModalHandler={closeModalHandler}
            currentLocation={currentLocation}
          />
        )}
      </Gap>
    </LocationsWrapper>
  );
};
