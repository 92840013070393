import { useMutation, useQueryClient } from 'react-query';
import { annotationsKeys } from './annotationsKeys';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';

export const deleteTemplate = async (templateId: string) => {
  await EXPRESS_API.delete(`/annotation-templates/${templateId}`);

  return { templateId };
};

export const useDeleteTemplateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(annotationsKeys.templates());
      successToast({ title: 'Template deleted successfully!' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred deleting the template, please try again later!',
      });
    },
  });
};
