import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { quickFilterKeys } from './quickFilterKey';
import { EXPRESS_API } from 'configs/api/expressApi';

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export interface IQuickFilterRule {
  [x: string]: {
    [x: string]: string;
  };
}

export interface IQuickFilterQueryValue {
  [key: string]: IQuickFilterRule[];
}

export type IQuickFilterQuery = PartialRecord<
  '$or' | '$and',
  IQuickFilterQueryValue[]
>;

export interface IQuickFilterValues {
  rules: IQuickFilterQuery;
  title: string;
  quickFilterId: number;
  customerId: number;
  userId: number;
}

const getQuickFilters = async (): Promise<IQuickFilterValues[]> => {
  return (await EXPRESS_API.get(`quick-filters`)).data as IQuickFilterValues[];
};

export const useQuickFiltersQuery = () => {
  const { showError } = useToastError();

  return useQuery(quickFilterKeys.all(), () => getQuickFilters(), {
    onError: showError,
    refetchOnMount: true,
  });
};
