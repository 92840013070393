import { theme } from 'lib/style';
import { SketchPicker } from 'react-color';
import styled from 'styled-components/macro';
import { PickerProps, ColorProps, TitleProps } from './ManageDefaultTypes';

export const OptionItems = styled.div`
  padding: 6px 10px 4px 0px;
  position: relative;
  .option-container {
    margin-top: 4px;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

export const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

export const Container = styled.div`
  display: flex;
`;

export const ContainerList = styled.div`
  flex: 4;
`;

export const ContainerArrow = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  align-self: center;
  margin-top: 100px;
`;

export const ContainerOptions = styled.div`
  flex: 2;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const ContainerListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0px;
`;

export const MainUserList = styled.div`
  margin-top: 10px;
  background: #f6f7f9;
  padding: 16px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 0px;
  .item--label {
    display: flex;
    align-items: center;
    label {
      padding-left: 5px;
    }
  }
  .email {
    color: #9297a2;
    font-size: smaller;
  }
`;

export const Picker = styled(SketchPicker)<PickerProps>`
  display: ${props => !props.isPickerOpen && 'none'};
  position: ${props => props.isPickerOpen && 'absolute'};
  top: ${props => props.isPickerOpen && '-283px'};
  left: ${props => props.isPickerOpen && '0'};
  right: ${props => props.isPickerOpen && '0'};
  z-index: 6;
  margin-bottom: 20px; /* just to make sure we have some margin on the bottom of the screen */
`;

export const ColorSelect = styled.div<PickerProps>`
  box-sizing: border-box;
  border: 1px solid
    ${props =>
      props.isPickerOpen
        ? theme.palette.covideoBlue100
        : theme.palette.covideoGray40};
  border-radius: 4px;
  height: 40px;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
  ${theme.mediaQueryMinWidth.cmd} {
    width: 268px;
  }
  color: rgb(51, 51, 51);
  cursor: pointer;
  :hover {
    border: 1px solid ${theme.palette.covideoBlue100};
  }
`;

export const Color = styled.div<ColorProps>`
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 2px;
  background-color: ${props => props.color};
`;

export const ColorTitle = styled.div<TitleProps>`
  position: relative;
  right: ${props => (props.title ? '0px' : '40px')};
`;
