import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useHistory } from 'react-router';

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 12px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;
const Manage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary[100]}; /** SUS-961 changes **/
`;
const Main = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.blue100};
`;
const Labels = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;

const Addons: any = {
  captions: 'Captions',
  droplr: 'File sharing',
  lpb: 'Landing page builder',
  sms: 'SMS',
  smsTierOne: 'SMS',
  smsTierTwo: 'SMS',
  smsTierThree: 'SMS',
};

type Props = {
  addonData: any;
};

export const AddonsOverview = ({ addonData }: Props) => {
  const history = useHistory();

  const getAddonLabels = () => {
    let addonStringArr: any = [];
    for (let i = 0; i < addonData.length; i++) {
      if (addonData[i].active) {
        addonStringArr.push(Addons[addonData[i].name]);
      }
    }
    return addonStringArr.join(', ');
  };

  return (
    <>
      <Header>
        <Title>Add-ons</Title>
        <Manage onClick={() => history.push('/profile/add-ons')}>Manage</Manage>
      </Header>
      <Main>{addonData.filter((e: any) => !!e.active).length} active</Main>
      <Labels>{getAddonLabels()}</Labels>
    </>
  );
};
