import { useVideoReactionsQuery } from 'lib/api/videos/useGetVideoReactions';
import {
  VideoReactionsDictionary,
  VideoReactionType,
} from 'lib/const/VideoReactionsDictionary';
import { theme } from 'lib/style';
import { summarizeReactionsSums } from 'lib/utils/functions';
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: ${theme.palette.white};
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 5px;
`;

const Sum = styled.div`
  min-width: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray80};
`;

const Icon = styled.div`
  margin: 0 12px;
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray80};
`;

export const ReactionSums = ({ videoId }: { videoId: string }) => {
  const { data: videoReactions } = useVideoReactionsQuery(videoId);
  const { commentSum, emoteSums } = summarizeReactionsSums(
    videoReactions || {}
  );

  return (
    <Wrapper>
      {Object.values(VideoReactionsDictionary).map(reaction => (
        <Row key={reaction.id}>
          <Sum>
            {reaction.type === VideoReactionType.COMMENT
              ? commentSum
              : emoteSums[reaction.id] || 0}
          </Sum>
          <Icon>{reaction.icon}</Icon>
          <Title>{reaction.title}</Title>
        </Row>
      ))}
    </Wrapper>
  );
};
