import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const MainContainer = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;
  min-height: 500px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 64px 24px 84px 24px;
  }
`;
