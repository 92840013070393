import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from 'lib/context';
import { List } from './List';
import { Details } from './Details';
import { EditResend } from './EditResend';
import { NotFoundTemplate } from 'app/pages/notFound'; /* SUS-1234 changes */
import { checkIfRepairOrderAccessible } from 'lib/utils/automotiveRolePermissionChecks'; /* SUS-1234 changes */

export const Main = () => {
  /* SUS-1234 changes */
  const { userData } = useAuth();
  const isRepairOrderAccessible = checkIfRepairOrderAccessible(userData);
  /******/

  return (
    <Switch>
      {/* SUS-1234 changes */}
      {isRepairOrderAccessible && (
        <Route path='/repair-orders' component={List} exact={true} />
      )}
      {/* SUS-1234 changes */}
      {isRepairOrderAccessible && (
        <Route
          path='/repair-orders/:repairOrderId'
          component={Details}
          exact={true}
        />
      )}
      {/* SUS-1234 changes */}
      {isRepairOrderAccessible && (
        <Route
          path='/repair-orders/edit-resend/:repairOrderId'
          component={EditResend}
          exact={true}
        />
      )}
      {/* SUS-1234 changes */}
      <Route path='*' component={NotFoundTemplate} />
    </Switch>
  );
};
