import { TextInput } from 'lib/components';
import { theme } from 'lib/style';
import Select from 'react-select';
import styled from 'styled-components/macro';

export const PageContainer = styled.div`
  text-align: center;

  margin: auto;
  margin-top: 60px;
  padding: 32px 0;

  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;

  display: flex;
  align-items: center;
  flex-flow: column;

  max-width: 575px;
`;

export const HeaderImage = styled.img`
  width: 180px;
  margin-bottom: 35px;
`;

export const HeaderText = styled.label`
  color: ${theme.palette.primaryDarkBlue};
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 30px;
`;

export const InformationText = styled.label`
  color: ${theme.palette.midGrey};
  font-size: 14px;
  margin-top: 5px;
`;

export const SectionText = styled.label`
  align-self: start;
  margin-left: 32px;
  margin-top: 60px;
  margin-bottom: 32px;
  color: ${theme.palette.primaryDarkBlue};
  font-size: 14px;
`;

export const InputText = styled.label`
  align-self: start;
  margin-left: 32px;
  margin-top: 5px;
  font-size: 12px;
  color: ${theme.palette.midGrey};
`;

export const InputField = styled(TextInput)`
  margin: 10px 32px;
  max-width: 515px;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row;
  margin-left: 32px;
  align-self: start;
`;

export const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  max-width: 200px;
  margin-right: 20px;
`;

export const SelectBox = styled(Select)`
  width: 100%;
  margin: 10px 32px;
  max-width: 235px;
  align-self: start;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  margin-left: 64px;
`;
