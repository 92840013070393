import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const badgeStyles = {
  private: {
    background: theme.palette.destructiveBg,
    color: theme.palette.tomatoRed,
    label: 'Private',
  },
  public: {
    background: theme.palette.covideoBlue02NonAlpha,
    color: theme.palette.covideoBlue80,
    label: 'Public',
  },
  mobile: {
    background: theme.palette.covideoGray100,
    color: theme.palette.white,
    label: 'Mobile',
  },
  desktop: {
    background: theme.palette.covideoGray100,
    color: theme.palette.white,
    label: 'Desktop',
  },
  both: {
    background: theme.palette.covideoGray100,
    color: theme.palette.white,
    label: 'Both',
  },
};

type BadgeType = 'private' | 'public' | 'mobile' | 'desktop' | 'both';

const BadgeContainer = styled.div<{
  background: string;
  color: string;
  badgeType: BadgeType;
}>`
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  border-radius: 6px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-right: ${({ badgeType }) =>
    badgeType === 'both' || badgeType === 'mobile' || badgeType === 'desktop'
      ? 'auto'
      : ''};
`;

type CategoryBadgeProps = {
  badgeType: BadgeType;
};

const CategoryBadge = ({ badgeType }: CategoryBadgeProps) => {
  const { background, color, label } = badgeStyles[badgeType];

  return (
    <BadgeContainer background={background} color={color} badgeType={badgeType}>
      {label}
    </BadgeContainer>
  );
};

export default CategoryBadge;
