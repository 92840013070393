import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';

import { ListWebsiteOverlay } from 'lib/api';
import { useLandingPageBuilderContext } from 'app/pages/design/landingPageBuilder/context';
import {
  getNewSection,
  DefaultSectionDetails,
} from 'app/pages/design/landingPageBuilder/components';
interface Props {
  onClose: (params?: any) => void;
  onMainButtonClick: (params?: any) => void;
  onContinueButtonClick: (params?: any) => void;
  data?: ListWebsiteOverlay;
  modalLoading: boolean;
  type: 'add' | 'edit';
}
type ImgProps = {
  img?: string;
  isSelected?: boolean;
};
interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}
const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 0 0 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SectionIconContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 0 25%;
  height: auto;
`;

const SectionImage = styled.img<ImgProps>`
  background-color: ${theme.palette.white};
  display: block;
  height: 5rem;
`;

const SectionDetailContainer = styled.div`
  display: inline-flex;
  padding: 0px 10px 0px 15px;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 0 75%;
  height: auto;
  line-height: 1.7;
`;

const SectionName = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 600;
  color: rgba(39, 42, 50, 1);
`;

const SectionDetails = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 400;

  color: rgba(78, 84, 97, 1);
`;

export const ModalLandingPageBuilderAddSection = (props: Props) => {
  // const [selectedLayout, setSelectedLayout] = useState('');
  const { onClose, onMainButtonClick, modalLoading } = props;
  const title = 'Insert section';
  const themes = useTheme();
  const { selectedLayoutData, setSelectedLayoutData } =
    useLandingPageBuilderContext();

  React.useEffect(() => {}, []);

  const onSectionClick = (e: any, sectionType: number) => {
    let keyArray = Object.keys(selectedLayoutData);
    let newKey = keyArray.length + 1;
    const data = { ...selectedLayoutData };
    data[newKey] = getNewSection(sectionType);
    setSelectedLayoutData(data);
    onMainButtonClick(data);
    onClose();
  };

  return (
    <NewModal
      closeModal={onClose}
      headerText={title}
      style={{
        content: {
          maxHeight: 560,
        },
      }}
    >
      {modalLoading ? (
        <LoaderWrapper>
          <MoonLoader
            size={40}
            color={themes.colors.primary[100]}
            loading={true}
          />
        </LoaderWrapper>
      ) : DefaultSectionDetails && DefaultSectionDetails.length > 0 ? (
        DefaultSectionDetails.map((value, index) => (
          <Row
            key={index}
            onClick={e => {
              onSectionClick(e, value.sectionType);
            }}
          >
            <SectionIconContainer>
              <SectionImage src={value.sectionIcon} />
            </SectionIconContainer>
            <SectionDetailContainer>
              <SectionName>{value.sectionName}</SectionName>
              <SectionDetails>{value.sectionDetails}</SectionDetails>
            </SectionDetailContainer>
          </Row>
        ))
      ) : (
        <></>
      )}
    </NewModal>
  );
};
