import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';
import CustomDateTimePicker from '../custom-time-picker/CustomDateTimePicker';
import { Button } from 'react-covideo-common';

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 24px;
  font-weight: 500;
  line-height: 20px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
  margin-bottom: 16px;
`;

type Props = {
  handleModalClose: () => void;
  handleSubmit: Function;
  setExpiration: (date: Date) => void;
  expiration: Date | null;
  buttonText: string;
};

export const ModalVideoExpiration = ({
  handleModalClose,
  handleSubmit,
  setExpiration,
  expiration,
  buttonText,
}: Props) => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  return (
    <NewModal
      headerText='Custom Link Expiration'
      closeModal={handleModalClose}
      style={{
        content: {
          overflow: 'visible',
        },
      }}
    >
      <Content>
        <Description>
          URLs you share won’t be accessible after this date and time
        </Description>
        {expiration && (
          <CustomDateTimePicker
            setDate={setExpiration}
            date={expiration}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
          />
        )}
        <ButtonsWrapper>
          <Button
            text={buttonText}
            onClick={() => handleSubmit()}
            disabled={!!errorMessage}
          />
        </ButtonsWrapper>
      </Content>
    </NewModal>
  );
};
