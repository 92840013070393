import { ScriptItem } from 'lib/context';
import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { teleprompterKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const shareScriptWithCompany = async (scriptId: number) => {
  const response = await EXPRESS_API.put(`/teleprompter/${scriptId}/share`);
  return response.data as ScriptItem;
};

export const useShareWithCompany = (scope: string) => {
  const queryClient = useQueryClient();
  return useMutation((scriptId: number) => shareScriptWithCompany(scriptId), {
    onSuccess: () => {
      queryClient.invalidateQueries(teleprompterKeys.list(scope));

      successToast({ title: 'Successfully shared with company!' });
    },
    onError: () => {
      errorToast({ title: 'There was an error with sharing your script.' });
    },
  });
};
