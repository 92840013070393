import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';

interface PresignedUrl {
  url: string;
  id?: number;
}

interface PresignedUrlParams {
  fileName: string;
  type: string;
  id?: number;
}

export const getAwsSignedUrl = async (
  data: PresignedUrlParams
): Promise<PresignedUrl> => {
  const { fileName, type } = data;
  const response = await EXPRESS_API.get(`/aws/signedurl`, {
    params: { filename: fileName, filetype: type },
  });
  return response.data;
};

export const useAwsSignedUrlQuery = (
  params: PresignedUrlParams,
  enabled: boolean
) => {
  return useQuery(
    ['AWS_SIGNED_URL', params.fileName],
    () => getAwsSignedUrl(params),
    {
      enabled,
      refetchOnMount: true,
      refetchOnReconnect: true,
      cacheTime: 5000,
    }
  );
};
