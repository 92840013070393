import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { useState } from 'react';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { IoMdClose } from 'react-icons/io';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 592px;
  padding: 32px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: ${theme.palette.coal};
`;
const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin-bottom: 8px;
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray40};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  height: 120px;
  resize: none;
  outline: 0;
  &:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;

type Props = {
  user?: string;
  onClickWhiteButton: () => void;
  onClickOrangeButton: (emailContent: any) => void;
  handleModalClose: () => void;
  whiteButtonText?: string;
  orangeButtonText?: string;
};
export const ModalDenyRequest = ({
  user = '',
  whiteButtonText = 'Continue to Video Library',
  orangeButtonText = 'Send Email',
  onClickWhiteButton,
  onClickOrangeButton,
  handleModalClose,
}: Props) => {
  const [emailContent, setEmailContent] = useState('');
  const title = `You’ve denied a video request from ${user}`;
  const text = `Write a note for ${user}`;
  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed' }}>
      <ModalItem>
        <Header>
          <Title>{title}</Title>
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Label>{text}</Label>
        <Textarea
          value={emailContent}
          onChange={(e: any) => setEmailContent(e.target.value)}
        />
        <Gap m='12px 0 0' justifyContent='flex-end'>
          <Button
            variant='secondary'
            text={whiteButtonText}
            onClick={onClickWhiteButton}
          />
          <Button
            disabled={!emailContent}
            text={orangeButtonText}
            onClick={() => onClickOrangeButton(emailContent)}
          />
        </Gap>
      </ModalItem>
    </Modal>
  );
};
