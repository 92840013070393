import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdFileUpload } from 'react-icons/md';
import { BoardModal } from './boardModal/BoardModal';
import { Button } from 'react-covideo-common';

const Container = styled.div`
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${theme.palette.coal};
  margin-bottom: 16px;
`;

interface Props {
  boardType?: string;
}
export const EmptyBoards = ({ boardType = 'owned' }: Props) => {
  const [showCreateBoardModal, setShowCreateBoardModal] = React.useState(false);
  const emptyTitle =
    boardType === 'owned'
      ? 'You don’t have created Boards.'
      : 'You don’t have shared Boards.';

  return (
    <Container>
      <Title>{emptyTitle}</Title>
      {boardType === 'owned' && (
        <Button
          text='Create Board'
          icon={<MdFileUpload size='18px' />}
          onClick={() => setShowCreateBoardModal(true)}
        />
      )}

      {showCreateBoardModal && (
        <BoardModal handleModalClose={() => setShowCreateBoardModal(false)} />
      )}
    </Container>
  );
};
