import { NewModal } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Formik, Form, FormikValues } from 'formik';
import { useRemoveChildCustomerMutation } from 'lib/api/multiLocations/useRemoveChildCustomerMutation';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
  customerId: number;
  business: string;
}

export const DeleteChildCustomerModal = ({
  closeModalHandler,
  customerId,
  business,
}: IProps) => {
  const { mutateAsync, isLoading } =
    useRemoveChildCustomerMutation(closeModalHandler);
  const onSubmitHandler = (values: FormikValues) => {
    mutateAsync({ customerId: values.customerId });
  };

  return (
    <NewModal
      headerText={`Remove ${business} from configuration?`}
      closeModal={closeModalHandler}
      style={{ content: { minWidth: 592, width: 592 } }}
    >
      <Formik initialValues={{ customerId }} onSubmit={onSubmitHandler}>
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  disabled={isLoading}
                  text='No, Keep'
                  variant='secondary'
                  onClick={async () => {
                    closeModalHandler();
                  }}
                />
                <Button
                  disabled={isLoading}
                  text={isLoading ? 'Deleting...' : 'Yes, Delete'}
                  variant='destructive'
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
