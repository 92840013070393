import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks/useToastError';

import { useMutation, useQueryClient } from 'react-query';
import { vdpQueryKeys } from './vdpQueryKeys';

const createVdp = async (data: {
  videoId: string;
  vin: string;
  integrationType: string;
}) => {
  return (await EXPRESS_API.post(`vdp`, data)).data;
};

export const useCreateVdp = () => {
  const queryClient = useQueryClient();

  const { showError } = useToastError();
  return useMutation(createVdp, {
    onSuccess: () => {
      queryClient.invalidateQueries(vdpQueryKeys.all);
      successToast({
        title: 'You have sent the video to the VDP successfully!',
      });
    },
    onError: err => {
      showError(err);
    },
  });
};
