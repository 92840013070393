import * as React from 'react';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';
import CreatableSelect from 'react-select/creatable';
import { VideoTag } from '../VideoTag';
import { getTags, Tag } from '../../api/videoTagsApi';
import { components } from 'react-select';
import { MdSearch } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import { TAG_LIMIT } from 'lib/const/VideoTags';
import selectors from '../../../cypress/selectors';
import { Button } from 'react-covideo-common';
import { FaHashtag } from 'react-icons/fa';

const Content = styled.div`
  .error-message {
    color: red;
    font-size: 12px;
  }
  .add-icon {
    margin-bottom: -2px;
    margin-right: 2px;
  }
`;

const SelectInput = styled(CreatableSelect)`
  position: relative;
  display: block;
  font-size: 16px;
  height: 40px;
  box-sizing: border-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border: none;
  box-sizing: border-box;
  &:focus {
    color: red;
    background-color: #fff;
    outline: 0;
  }
`;

const TagsContainer = styled.div`
  height: 150px;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  flex-flow: row wrap;
  align-content: flex-start;
  overflow-y: auto;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export const ModalVideoTags = ({
  videoTags = [],
  handleModalClose,
  onSubmit,
  title,
  buttonTitle,
}: any) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState([] as Tag[]);
  const [displayValues, setDisplayValues] = useState(videoTags);
  const [errorMessage, setErrorMessage] = useState('');
  const themes = useTheme();

  const sortTags = (unsortedTags: any) => {
    return unsortedTags.sort((a: any, b: any) => {
      return a.label?.localeCompare(b.label);
    });
  };

  const loadTags = async () => {
    const tags = await getTags({});
    setTags(
      sortTags(
        tags
          .map((t: Tag) => ({ value: t.tagId, label: t.tag }))
          .filter(
            (t: Tag) => !displayValues.find((dv: Tag) => dv.value === t.value)
          )
      )
    );
  };

  React.useEffect(() => {
    loadTags();
  }, []);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const removeTag = (tag: Tag) => {
    setDisplayValues(
      sortTags(displayValues.filter((t: Tag) => t.value !== tag.value))
    );
    setTags(sortTags([...tags, tag]));
  };

  const onInputChange = (v: string) => {
    if (v.length > TAG_LIMIT) {
      return;
    }
    setInputValue(v);
  };

  const onChange = (v: object[]) => {
    if (!v || !v.length) {
      return;
    }
    const val = v[0] as any;
    /* SUS-908 changes */
    if (!val || !val.value || !val?.value?.toString().trim().length) {
      setErrorMessage(() => `Tag can not be empty.`);
      return false;
    }
    if (val.value.length > TAG_LIMIT) {
      setErrorMessage(
        () => `Tag can not contain more than ${TAG_LIMIT} characters.`
      );
      return false;
    }
    /*****/
    const exists = displayValues.some((tag: any) => tag.label == val.value);
    if (exists) {
      setErrorMessage(() => 'Tag already exists.');
      return false;
    }
    setErrorMessage('');
    setDisplayValues(sortTags([...displayValues, val]));
    setTags(sortTags(tags.filter((t: Tag) => t.value !== val.value)));
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <MdSearch />
        </components.DropdownIndicator>
      )
    );
  };

  const Option = (props: any) => {
    return props.data.__isNew__ ? (
      <components.Option {...props}>
        <MdAdd className={'add-icon'} color={themes.colors.primary[100]} />
        {props.label}
      </components.Option>
    ) : (
      <components.Option {...props}>
        <FaHashtag
          color={theme.palette.blue60}
          className='hashtag-icon'
          size={'14px'}
        />
        {props.label}
      </components.Option>
    );
  };

  const checkIfButtonIsDisabled = () => {
    try {
      return JSON.stringify(videoTags) === JSON.stringify(displayValues);
    } catch (ex) {
      return false;
    }
  };

  return (
    <NewModal
      style={{ content: { width: '658px' } }}
      closeModal={handleModalClose}
      headerText={title}
    >
      <Content>
        <SelectInput
          inputValue={inputValue}
          onInputChange={onInputChange}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: DropdownIndicator,
            Option: Option,
          }}
          createOptionPosition={'first'}
          styles={{
            control: (base: any) => ({
              ...base,
              height: '40px',
              minHeight: '40px',
              maxHeight: '100px',
              border: '1px solid #D0D3D9',
              borderRadius: '4px',
              cursor: 'text',
              background: '#F6F7F9',
            }),
            option: (provided: any, state: any) => {
              return {
                ...provided,
                borderRadius: '5px',
                cursor: 'pointer',
                color: state.data.__isNew__
                  ? themes.colors.primary[100]
                  : theme.palette.blue100 /** SUS-961 changes **/,
                fontWeight: 600,
                padding: '6px 12px 6px 8px',
              };
            },
            valueContainer: (base: any) => ({
              ...base,
              height: 'auto',
              minHeight: '40px',
              maxHeight: '100px',
              overflow: 'auto',
            }),
            menu: (base: any) => ({
              ...base,
              zIndex: '900',
              maxHeight: '200px',
              background: '#FFFFFF',
              border: '1px solid #F7F8FA',
              boxSizing: 'border-box',
              borderRadius: '4px',
            }),
            menuList: (base: any) => ({
              ...base,
              zIndex: '900',
              maxHeight: '200px',
            }),
            placeholder: (base: any) => ({
              ...base,
              opacity: '0.6',
              color: theme.palette.black_1_100,
              fontFamily: theme.systemFontStack,
              display: 'block',
            }),
            multiValueRemove: (base: any) => ({
              ...base,
              display: 'none',
            }),
            multiValueLabel: (base: any) => ({
              ...base,
              display: 'none',
            }),
          }}
          placeholder={'Add tag(s)...'}
          value={[]}
          onChange={onChange}
          options={tags}
          noOptionsMessage={() => null}
          isMulti={true}
          clearable={false}
          optionHeight={40}
          id={selectors.modals.modalVideoTags.addTagDropdown}
          classNamePrefix='react-select'
        />
        {errorMessage != null && (
          <span className='error-message'>{errorMessage}</span>
        )}

        <TagsContainer>
          {displayValues &&
            displayValues.map((tag: Tag) => (
              <VideoTag
                key={tag.value}
                tag={tag}
                showRemove={true}
                onRemove={removeTag}
              />
            ))}
        </TagsContainer>

        <Footer>
          <Button
            onClick={() => onSubmit(displayValues)}
            text={buttonTitle}
            type='submit'
            disabled={checkIfButtonIsDisabled()}
            data-cy={selectors.modals.modalVideoTags.addTagsSubmitButton}
          />
        </Footer>
      </Content>
    </NewModal>
  );
};
