import { useQuery } from 'react-query';
import { youtubeKeys } from './queryKeys';
import { useToastError } from 'lib/hooks';
import { EXPRESS_API } from 'configs/api/expressApi';

export const queryYoutubeVideoAnalytics = async (id: number) => {
  const response = await EXPRESS_API.get(`/youtube/video/${id}/analytics`);
  return await response.data;
};

export const useQueryYoutubeVideoAnalytics = (id: number, enabled: boolean) => {
  const { showError } = useToastError();
  return useQuery(
    youtubeKeys.youtube_analytics(id),
    () => queryYoutubeVideoAnalytics(id),
    {
      onError: showError,
      refetchOnMount: true,
      enabled,
    }
  );
};
