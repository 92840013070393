import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path='/profile/templates' component={List} exact={true} />
      <Route
        path='/profile/templates/:snippetId'
        component={Details}
        exact={true}
      />
    </Switch>
  );
};
