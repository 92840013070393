import { EXPRESS_API } from 'configs/api/expressApi';

export const updatePlayerDesign = async (videoId: string, body: any) => {
  const data = body;
  const response = await EXPRESS_API.put(
    `/player-design/video/${videoId}`,
    data
  );
  return response.data;
};

export const updateUserPlayerDesign = async (body: any) => {
  const data = body;
  const response = await EXPRESS_API.put(`/player-design/user`, data);
  return response.data;
};
