import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'lib/context';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';
import { ListDrops } from '../../components';
import { Container, MainWrapper } from 'lib/components/styles/layout';

export const List = () => {
  const { userData } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (userData.user?.packageDetails?.id) {
      const isFileAllowed = checkIfFeatureIsEnabled(
        userData,
        productFeature.FILES_AND_BOARDS
      );
      if (!isFileAllowed) {
        window.location.href = '/upgrade-plan';
      }
    }
  }, []);

  if (!userData.droplrAccess) {
    history.push('/profile/add-ons');
    return null;
  }

  return (
    <Container>
      <MainWrapper resetPadding>
        <ListDrops excludeFilesTab />
      </MainWrapper>
    </Container>
  );
};
