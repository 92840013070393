import React from 'react';
import {
  FieldLabel,
  FormProps,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const Section700Credit = (props: FormProps) => {
  const { isSubmitting } = props;
  return (
    <Section>
      <SectionTitle>700 Credit</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>URL</FieldLabel>
          {getInputField('credit700Url', false, false, isSubmitting)}
        </Row>
      </RowWrapper>
    </Section>
  );
};
