import * as React from 'react';
import dayjs from 'dayjs';
import queryString from 'query-string';
import styled from 'styled-components/macro';
import { Dropdown } from 'lib/components';
import { useHistory, useLocation } from 'react-router-dom';

import { ModalDateRangeSelector } from './ModalDateRangeSelector';
import { STANDARD_DATE_FORMAT, USER_DATE_FORMAT } from 'lib/const/DateFormat';

interface ContainerProps {
  reverse: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
`;
type DropdownContainerProps = {
  width: string;
};
const DropdownContainer = styled.div<DropdownContainerProps>`
  width: ${props => props.width};
  margin-left: 12px;
`;

type RangeSelectorProps = {
  title?: string;
};

const RangeSelector = styled.div<RangeSelectorProps>`
  margin-left: 12px;
  margin-top: ${props => (props.title ? `20px` : `0`)};

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9297a2;
`;

const Paragraph = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 27, 83, 0.6);
  padding-bottom: 5px;
  margin: 0;
`;

export const timeRangeConstants = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_WEEK: 'last_7_days',
  LAST_MONTH: 'last_month',
  MONTH_TO_DATE: 'month_to_date',
  LAST_7_DAYS: 'previous_7_days',
  LAST_30_DAYS: 'last_30_days',
  YEAR_TO_DATE: 'year_to_date',
  CUSTOM: 'custom',
};

export const timeRangesOptions = [
  {
    value: timeRangeConstants.TODAY,
    label: 'Today',
  },
  {
    value: timeRangeConstants.YESTERDAY,
    label: 'Yesterday',
  },
  {
    value: timeRangeConstants.LAST_WEEK,
    label: 'Last Week',
  },
  {
    value: timeRangeConstants.LAST_MONTH,
    label: 'Last Month',
  },
  {
    value: timeRangeConstants.MONTH_TO_DATE,
    label: 'Month to Date',
  },
  {
    value: timeRangeConstants.LAST_7_DAYS,
    label: 'Last 7 days',
  },
  {
    value: timeRangeConstants.LAST_30_DAYS,
    label: 'Last 30 days',
  },
  {
    value: timeRangeConstants.YEAR_TO_DATE,
    label: 'Year to Date',
  },
  {
    value: timeRangeConstants.CUSTOM,
    label: 'Custom...',
  },
];

export const getTimeframeLabel = (value: string) => {
  const option = timeRangesOptions.find(option => option.value === value);
  return option ? option.label : null;
};

const DATE_RANGE_SELECTOR = 'DATE_RANGE_SELECTOR';

const storeDateRangeToLocalstorage = (
  startDate: Date,
  endDate: Date,
  range: string
) => {
  localStorage.setItem(
    DATE_RANGE_SELECTOR,
    JSON.stringify({
      startDate,
      endDate,
      range,
    })
  );
};

export const getDateRangeFromLocalstorage = () => {
  try {
    const { startDate, endDate, range } = JSON.parse(
      localStorage.getItem(DATE_RANGE_SELECTOR) || ''
    );

    const { start, end } = calculateDatesForRange(range, startDate, endDate);

    return {
      range,
      start,
      end,
    };
  } catch (error) {
    return null;
  }
};

export const getLastDaysDate = (days: number) =>
  new Date(new Date().getTime() - days * 24 * 60 * 60 * 1000);

const getYesterdayStartAndEnd = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  let startDate = new Date(yesterday);
  startDate.setHours(0, 0, 0, 0);
  let endDate = new Date(yesterday);
  endDate.setHours(23, 59, 59, 999);

  return { startDate, endDate };
};

const getLastWeek = () => {
  const start = new Date();
  start.setDate(start.getDate() - ((start.getDay() + 6) % 7)); // this week's monday
  start.setDate(start.getDate() - 7); // previous monday

  const end = new Date(
    start.getFullYear(),
    start.getMonth(),
    start.getDate() + 6
  );
  return { start, end };
};

export const calculateDatesForRange = (
  rangeValue: string,
  customStartDate?: Date,
  customEndDate?: Date
) => {
  // default value for today range
  let start = new Date();
  let end = new Date();

  if (rangeValue === timeRangeConstants.TODAY) {
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);
  }

  if (rangeValue === timeRangeConstants.YESTERDAY) {
    const { startDate, endDate } = getYesterdayStartAndEnd();
    start = startDate;
    end = endDate;
  }

  if (rangeValue === timeRangeConstants.LAST_7_DAYS) {
    start = getLastDaysDate(6);
  }

  if (rangeValue === timeRangeConstants.LAST_WEEK) {
    const lastWeek = getLastWeek();
    start = lastWeek.start;
    end = lastWeek.end;
  }

  if (rangeValue === timeRangeConstants.LAST_MONTH) {
    const date = new Date();
    start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    end = new Date(date.getFullYear(), date.getMonth(), 0);
  }

  if (rangeValue === timeRangeConstants.MONTH_TO_DATE) {
    start = new Date(start.getFullYear(), start.getMonth(), 1);
  }

  if (rangeValue === timeRangeConstants.LAST_30_DAYS) {
    start = getLastDaysDate(29);
  }

  if (rangeValue === timeRangeConstants.YEAR_TO_DATE) {
    start = new Date(start.getFullYear(), 0, 1);
  }

  if (
    rangeValue === timeRangeConstants.CUSTOM &&
    customStartDate &&
    customEndDate
  ) {
    start = customStartDate;
    end = customEndDate;
  }

  return {
    start,
    end,
  };
};

type Props = {
  onDateRangeChange: (startDate: Date, endDate: Date, range: string) => void;
  hideCustom?: boolean;
  initialValue?: string;
  reverse?: boolean;
  disabled?: boolean;
  dropdownClassName?: string;
  onTodayOnlyDate?: boolean;
  dropdownWidth?: string;
  customStartDate?: Date;
  customEndDate?: Date;
  title?: string;
};

const queryDateFormat = STANDARD_DATE_FORMAT;

export const DateRangeSelector = (props: Props) => {
  const {
    onDateRangeChange,
    hideCustom = false,
    initialValue = timeRangeConstants.LAST_7_DAYS,
    reverse = false,
    disabled = false,
    onTodayOnlyDate = false,
    dropdownWidth = '200px',
    customStartDate = new Date(),
    customEndDate = new Date(),
    title = '',
  } = props;

  const range = timeRangesOptions.find(
    option => option.value.toString() === initialValue?.toString()
  );
  const defaultRange = range || timeRangesOptions[5];
  const { start, end } = calculateDatesForRange(
    defaultRange.value,
    customStartDate,
    customEndDate
  );

  const [value, setValue] = React.useState(defaultRange);
  const [startDate, setStartDate] = React.useState(start);
  const [endDate, setEndDate] = React.useState(end);
  const [showRangeModal, setShowRangeModal] = React.useState(false);
  const [timeRanges, setTimeRanges] = React.useState(timeRangesOptions);
  const [initCalled, setInitCalled] = React.useState(false);

  const location = useLocation();
  const history = useHistory();

  const changeLink = (start: Date, end: Date, rangeValue: string) => {
    const queryParams: any = queryString.parse(location.search);
    queryParams.start = dayjs(start).format(queryDateFormat);
    queryParams.end = dayjs(end).format(queryDateFormat);
    queryParams.range = rangeValue;
    if (
      history.location.pathname !== location.pathname ||
      history.location.search !== queryString.stringify(queryParams)
    ) {
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(queryParams),
      });
    }
  };

  const calculateRangeDates = (rangeValue: string) => {
    const { start, end } = calculateDatesForRange(
      rangeValue,
      customStartDate,
      customEndDate
    );
    setStartDate(start);
    setEndDate(end);
    onDateRangeChange(start, end, rangeValue);
    storeDateRangeToLocalstorage(start, end, rangeValue);
    changeLink(start, end, rangeValue);
  };

  React.useEffect(() => {
    const queryParams: any = queryString.parse(location.search);
    if (queryParams.start && queryParams.end && queryParams.range) {
      const start = new Date(queryParams.start);
      const end = new Date(queryParams.end);
      const rangeValue = queryParams.range;

      const range = timeRanges.find(option => option.value === rangeValue);

      if (range && (range.value !== value.value || !initCalled)) {
        setStartDate(start);
        setEndDate(end);
        setValue(range);
        onDateRangeChange(start, end, rangeValue);
        storeDateRangeToLocalstorage(start, end, rangeValue);
      }
    } else {
      onDateRangeChange(startDate, endDate, value.value);
    }
    if (!initCalled) {
      setInitCalled(true);
    }
  }, [location.search]);

  React.useEffect(() => {
    if (hideCustom) {
      setTimeRanges(timeRangesOptions.slice(0, -1));
    }
  }, [hideCustom]);

  const onCustomRangeSelected = (
    customStartDate: Date,
    customEndDate: Date
  ) => {
    customStartDate = customStartDate || new Date();
    customEndDate = customEndDate || new Date();
    setStartDate(customStartDate);
    setEndDate(customEndDate);
    setShowRangeModal(false);
    onDateRangeChange(customStartDate, customEndDate, value.value);
    storeDateRangeToLocalstorage(customStartDate, customEndDate, value.value);

    changeLink(customStartDate, customEndDate, value.value);
  };

  const startDateString = dayjs(startDate).format(
    startDate.getFullYear() === endDate.getFullYear()
      ? 'MMM DD'
      : USER_DATE_FORMAT
  );
  const endDateString = dayjs(endDate).format(USER_DATE_FORMAT);

  const rangeString =
    onTodayOnlyDate && value === timeRangesOptions[0]
      ? endDateString
      : `${startDateString} - ${endDateString}`;

  return (
    <Container reverse={reverse}>
      <DropdownContainer width={dropdownWidth} className='dropdown-container'>
        {title && <Paragraph>{title}</Paragraph>}
        <Dropdown
          placeholder={'Filter by type...'}
          value={value}
          onChange={newValue => {
            setValue(newValue);
            if (newValue.value === timeRangeConstants.CUSTOM) {
              setShowRangeModal(true);
            } else {
              calculateRangeDates(newValue.value);
            }
          }}
          options={timeRanges}
          dropdownHeight={300}
          disabled={disabled}
          className={props.dropdownClassName || ''}
          isSearchable={false}
          creatable={false}
        />
      </DropdownContainer>
      <RangeSelector title={title}>{rangeString}</RangeSelector>

      {showRangeModal && (
        <ModalDateRangeSelector
          initStartDate={startDate}
          initEndDate={endDate}
          handleModalClose={() => setShowRangeModal(false)}
          onRangeSelected={onCustomRangeSelected}
        />
      )}
    </Container>
  );
};
