import { useQuery } from 'react-query';
import { accountChangesKeys } from './accountChangesKeys';
import {
  AccountChange,
  AccountChangesParams,
} from 'app/pages/admin/customers/components/accountChanges/types';
import { useToastError } from 'lib/hooks';
import { EXPRESS_API } from 'configs/api/expressApi';

interface AccountChangesResponse {
  accountChanges: AccountChange[];
  count: number;
}

const getAccountChanges = async (
  params: AccountChangesParams
): Promise<AccountChangesResponse> => {
  return (
    await EXPRESS_API.get(`superadmin/account-changes`, {
      params,
    })
  ).data;
};

export const useGetAccountChanges = (params: AccountChangesParams) => {
  const { showError } = useToastError();
  return useQuery(
    accountChangesKeys.get(params),
    () => getAccountChanges(params),
    {
      onError: showError,
    }
  );
};
