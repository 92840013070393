import React, { ReactNode } from 'react';

import { CovideoThemeProvider } from 'react-covideo-common';
import { useAuth } from '../auth';

export const Theme = ({ children }: { children: ReactNode }) => {
  const { themeName } = useAuth();

  return (
    <CovideoThemeProvider themeName={themeName}>
      {children}
    </CovideoThemeProvider>
  );
};
