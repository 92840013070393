import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useDropzone } from 'react-dropzone';
import { Button, useCovideoTheme } from 'react-covideo-common';

import { Dropdown, LoadingIndicator, Search } from 'lib/components';
import { theme } from 'lib/style';
import { formatBytes } from 'lib/utils/functions';
import {
  ExtendedFile,
  statuses,
} from '../../../app/pages/library/components/Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdInsertDriveFile, MdOutlineLaunch } from 'react-icons/md';
import {
  getStatusSize,
  getStatusText,
} from 'app/pages/library/components/uploadDropTabs/UploadDropTab';
import { DropItem } from 'lib/api/droplr/types';
import { getDrops } from 'lib/api/droplr/getDrops';
import { DROPTYPE_OPTIONS } from 'lib/const/Droplr';

const fields = [
  {
    value: 'createdAtASC',
    label: 'Uploaded on (ASC)',
    order: 'ASC',
    key: 'CREATION',
  },
  {
    value: 'createdAtDESC',
    label: 'Uploaded on (DESC)',
    order: 'DESC',
    key: 'CREATION',
  },
];
const DropContent = styled.div<{ isDragActive: boolean }>`
  margin-top: 24px;
  padding: 0 32px;
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${props => (props.isDragActive ? '#F6F7F9' : '#F6F7F9')};
  border: 1px dashed #d0d3d9;
  > p {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.coal};
  }
`;

const UploadFileContent = styled.div`
  margin-top: 24px;
  background: #f6f7f9;
  border-radius: 4px;
  display: flex;
  padding: 16px 32px;
`;

const UploadFileDescription = styled.div`
  width: 90%;
`;

const UploadFileAction = styled.div`
  width: 10%;
`;

const UploadFileTitle = styled.div`
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.coal};
  padding: 8px 12px;
`;

const UploadFileProgress = styled.progress`
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 2px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    border-radius: 2px;
    height: 4px;
  }
  &::-webkit-progress-value {
    display: inline-block;
    float: left;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    border-radius: 2px;
  }
`;

const UploadFileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const FileStatus = styled.div<{ color: string }>`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.color ? props.color : '#9297A2')};
`;

const FileSize = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${theme.palette.coal};
`;

const LibraryContainer = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  column-gap: 12px;
`;

const DropdownContainer = styled.div`
  width: 258px;
  height: 40px;
`;

const FilesSelector = styled.div`
  margin-top: 24px;
  height: 300px;
  overflow-y: auto;
`;

const EmptyFiles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const ListFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-title {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
  }
  .FileItem {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 15px;
    .FilePreview {
      width: 96px;
      height: 64px;
    }
    .FileLaunch {
      opacity: 0;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0px;
      left: 0px;
      background-color: #0000002e;
      width: 96px;
      height: 64px;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
  &:hover {
    border: 2px solid ${theme.palette.primaryThemeColor20Percent};
    border-radius: 4px;
  }
`;

const ButtonWrapper = styled.div`
  button {
    margin: 0px 5px 0px 2px;
  }
`;

const ItemTypes = [
  {
    value: 'file',
    text: 'Files',
  },
  {
    value: 'board',
    text: 'Boards',
  },
];

type Props = {
  selectedFile?: any;
  handleModalClose: () => void;
  onSelect: any;
  selectedVirtualBackgroundUrl: string;
};

export const DroplrFilesTabContent = (props: Props) => {
  const { handleModalClose, onSelect, selectedFile } = props;
  const [file, setFile] = React.useState({
    name: '',
    sizeText: '',
    size: 0,
    currentUpload: 0,
    status: '',
    progress: 0,
  } as ExtendedFile);
  const { colors } = useCovideoTheme();
  const [page, setPage] = React.useState(0);
  const [res, setRes] = React.useState({ count: '0', hasMore: 'false' } as any);
  const [drops, setDrops] = React.useState([] as DropItem[]);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [field, setField] = React.useState<any>(fields[0]);

  const itemType = selectedFile?.itemType || ItemTypes[0].value;

  const onDrop = useCallback(async acceptedFiles => {
    let f = {
      name: acceptedFiles[0].name,
      status: statuses.WAITING,
      sizeText: formatBytes(acceptedFiles[0].size, 2),
      size: acceptedFiles[0].size,
      progress: 0,
      currentUpload: 0,
    };
    setFile(f);

    const reader = new FileReader();

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = async () => {
      f = {
        ...f,
        status: statuses.UPLOADING,
      };
      setFile(f);
      f = {
        ...f,
        status: statuses.FINISHED,
        progress: 100,
      };
      setFile(f);
      setTimeout(() => {
        setFile({
          name: '',
          sizeText: '',
          size: 0,
          currentUpload: 0,
          status: '',
          progress: 0,
        });
        refreshDropsAndBoards();
      }, 1500);
    };
    reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    // accept: ['image/png', 'image/jpeg'],
  });

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const fetchDrops = async () => {
    const response = await getDrops({
      sort: field.key || '',
      order: field.order || '',
      search: searchQuery,
      page: page + 1,
      type: 'FILE',
      variant: 'text/plain',
      dropType: DROPTYPE_OPTIONS.OWNED,
    });

    setPage(page + 1);
    setRes(response);
    setDrops([...drops, ...response.drops]);
  };

  const refreshDropsAndBoards = async () => {
    if (itemType !== 'file') {
      return;
    }
    setLoading(true);
    const response = await getDrops({
      sort: field.key || '',
      order: field.order || '',
      search: searchQuery,
      type: 'FILE',
      variant: 'text/plain',
      dropType: DROPTYPE_OPTIONS.OWNED,
    });

    setRes(response);
    setLoading(false);
    setDrops(response.drops);
  };

  React.useEffect(() => {
    if (itemType !== 'file') {
      return;
    }
    refreshDropsAndBoards();
  }, [field, searchQuery, itemType]);

  const uploadingFiles = !!file.name;

  return (
    <>
      {!uploadingFiles && (
        <DropContent isDragActive={isDragActive} {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && (
            <>
              <p>Drag and drop files here to upload new file</p>
              <Button text='Browse files...' variant='white' />
            </>
          )}
          {isDragActive && <p>Drop files here</p>}
        </DropContent>
      )}
      {uploadingFiles && (
        <UploadFileContent>
          <UploadFileDescription>
            <UploadFileTitle>{file.name}</UploadFileTitle>
            <UploadFileProgress value={file.progress} max={100} />
            <UploadFileDetails>
              <FileStatus
                color={
                  file.status === statuses.WAITING
                    ? '9297A2'
                    : colors.primary[100]
                }
              >
                {getStatusText(file.status)}
              </FileStatus>
              <FileSize>{getStatusSize(file)}</FileSize>
            </UploadFileDetails>
          </UploadFileDescription>
          <UploadFileAction></UploadFileAction>
        </UploadFileContent>
      )}
      {loading && <LoadingIndicator isLoading={loading} height='200px' />}
      {!loading && (
        <LibraryContainer>
          <Menu>
            <div style={{ width: '280px' }}>
              <Search
                placeholder={`Search files...`}
                onSearch={onSearch}
                prevSearch={searchQuery}
              />
            </div>
            <div style={{ display: 'flex' }}>
              <DropdownContainer>
                <Dropdown
                  value={field}
                  onChange={newValue => {
                    setField(newValue);
                  }}
                  options={fields}
                  dropdownHeight={450}
                  height={40}
                />
              </DropdownContainer>
            </div>
          </Menu>

          <FilesSelector>
            <InfiniteScroll
              dataLength={page * parseInt(res.count)}
              next={fetchDrops}
              hasMore={res.hasMore === 'true'}
              loader={<h4>Loading...</h4>}
              height={300}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>No more files</b>
                </p>
              }
            >
              <Grid>
                {drops.map(drop => (
                  <ListFileItem key={`file-item-${drop.code}`}>
                    <div className='FileItem'>
                      {drop.previewMedium ? (
                        <img
                          className='FilePreview'
                          alt='preview'
                          src={drop.previewMedium}
                        />
                      ) : (
                        <MdInsertDriveFile size={32} />
                      )}
                      <div className='FileLaunch'>
                        <MdOutlineLaunch
                          size={18}
                          color='white'
                          onClick={() => {
                            window.open(drop.shortlink, '_blank');
                          }}
                        />
                      </div>
                      <div
                        className='file-title'
                        onClick={() => {
                          onSelect(drop.content, drop.code);
                          handleModalClose();
                        }}
                      >
                        {drop.title}
                      </div>
                    </div>
                    <ButtonWrapper>
                      <Button
                        text={'Select File'}
                        variant='secondary'
                        onClick={() => {
                          onSelect(drop.content, drop.code);
                          handleModalClose();
                        }}
                      />
                    </ButtonWrapper>
                  </ListFileItem>
                ))}
              </Grid>

              {!drops.length && <EmptyFiles>No files found.</EmptyFiles>}
            </InfiniteScroll>
          </FilesSelector>
        </LibraryContainer>
      )}
    </>
  );
};
