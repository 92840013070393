import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const Button = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  color: ${theme.palette.primaryDarkBlue};
  position: absolute;
  left: 0;
  bottom: 8px;
  cursor: pointer;
`;

type Props = {
  editorState: any;
  onEditorStateChange: any;
  showHtmlEditor: boolean;
  setShowHtmlEditor: (showHtmlEditor: boolean) => void;
};
export const SourceHTMLToggleButton = ({
  showHtmlEditor,
  setShowHtmlEditor,
}: Props) => {
  const toggleHtmlEditor = () => {
    setShowHtmlEditor(!showHtmlEditor);
  };
  return (
    <Button onClick={() => toggleHtmlEditor()}>
      {showHtmlEditor ? 'Switch to Text Editor' : 'Switch to HTML Editor'}
    </Button>
  );
};
