import * as React from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from 'lib/components';
import { theme } from '../../style';

interface ComponentProps {
  infoText?: string;
  icon?: string;
  iconHeight?: string;
  iconWidth?: string;
  iconBackgroundColor?: string;
}

interface IconProps {
  height?: string;
  width?: string;
  iconBackgroundColor?: string;
}
const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    figcaption {
      z-index: 2;
      opacity: 1;
      width: auto;
      position: absolute;
    }
  }
`;

const Icon = styled.span<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  background-color: ${props =>
    props.iconBackgroundColor || theme.palette.primaryDarkBlue};
  border: 2px solid ${theme.palette.black_1_100};
  border-radius: 50%;
  width: ${props => props.width || '20px'};
  height: ${props => props.height || '20px'};
  opacity: 0.55;
`;

export const InfoQuestionMark = ({
  infoText,
  icon,
  iconHeight,
  iconWidth,
  iconBackgroundColor,
}: ComponentProps) => {
  const text = infoText || 'Set this as default for all videos.';
  return (
    <Wrapper>
      <Icon
        height={iconHeight}
        width={iconWidth}
        iconBackgroundColor={iconBackgroundColor}
      >
        {icon ? icon : '?'}
      </Icon>
      <Tooltip text={text} topPixels={24} widthPixels={240} />
    </Wrapper>
  );
};
