import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FaDollarSign } from 'react-icons/fa';
import { TextInput } from '../../../../lib/components/inputs';
import { QuoteItem } from 'lib/api/repairOrders/types';
import { Button } from 'react-covideo-common';
import { FiCheck } from 'react-icons/fi';
import { MdDeleteForever } from 'react-icons/md';
import { IoMdReorder } from 'react-icons/io';

const ItemWrapper = styled.div`
  &.disabled {
    pointer-events: none;
  }
`;
const DraggableItem = styled.div`
  display: flex;
`;
const DragIconWrapper = styled.div`
  font-size: 16px;
  width: 48px;
  height: 56px;
  display: flex;
  align-items: center;
  min-width: 48px;
  margin-top: auto;
  margin-bottom: auto;
`;
const DragContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const InputWrapper = styled.div`
  position: relative;
`;
const Input = styled(TextInput)`
  box-shadow: none;
  font-size: 16px;
  line-height: 24px;

  color: ${theme.palette.gray100};
  margin-right: 8px;
  &.disabled {
    background: ${theme.palette.blue02};
    border: 1px solid transparent;
  }
  &:focus {
    box-shadow: none;
  }
`;
const PriceInput = styled(Input)`
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: 0px;
  color: ${theme.palette.gray100};
`;
const PriceIcon = styled(FaDollarSign)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
`;

type Props = {
  droppableId: string;
  quoteItems: QuoteItem[];
  handleDeleteItem: (droppableId: string, index: number) => void;
  handleChangeItem: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
    droppableId: string
  ) => void;
};

export const DroppableWrapper = ({
  droppableId,
  quoteItems,
  handleDeleteItem,
  handleChangeItem,
}: Props) => {
  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? theme.palette.babyBlue40 : 'transparent',
    border: isDraggingOver ? `1px dashed ${theme.palette.babyBlue100}` : 'none',
    borderRadius: '5px',
    minHeight: 56,
  });

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {quoteItems.map((item, index) => {
            return (
              <ItemWrapper
                key={`${droppableId}-${index}`}
                className={item.approved ? 'disabled' : ''}
              >
                <Draggable
                  draggableId={`${droppableId}-${index}`}
                  index={index}
                >
                  {provided => (
                    <DraggableItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DragIconWrapper>
                        {item.approved ? (
                          <FiCheck color={theme.palette.green100} />
                        ) : (
                          <IoMdReorder color={theme.palette.gray40} />
                        )}
                      </DragIconWrapper>
                      <DragContent>
                        <Input
                          value={item.title || ''}
                          name={'title'}
                          onChange={e =>
                            handleChangeItem(index, e, droppableId)
                          }
                          className={item.approved ? 'disabled' : ''}
                        />
                        <InputWrapper>
                          <PriceIcon color={theme.palette.gray60} size={18} />
                          <PriceInput
                            type={'number'}
                            name={'price'}
                            placeholder={'0.00'}
                            style={{ width: 134 }}
                            min='0'
                            value={item.price || 0}
                            onChange={e =>
                              handleChangeItem(index, e, droppableId)
                            }
                            className={item.approved ? 'disabled' : ''}
                          />
                        </InputWrapper>
                        <Button
                          variant='transparent'
                          icon={
                            <MdDeleteForever
                              size={18}
                              color={theme.palette.gray60}
                            />
                          }
                          disabled={item.approved}
                          onClick={
                            !item.approved
                              ? () => handleDeleteItem(droppableId, index)
                              : undefined
                          }
                        />
                      </DragContent>
                    </DraggableItem>
                  )}
                </Draggable>
              </ItemWrapper>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
