import { ModalVideoListVideo, NewModal } from 'lib/components';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import { BsCalendarEvent } from 'react-icons/bs';
import { FaPlay } from 'react-icons/fa';
import styled from 'styled-components/macro';

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;

  text-overflow: ellipsis;
  overflow: hidden;
  whitespace: nowrap;
  max-width: 300px;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
`;

type ThumbnailProps = {
  image: string;
};
const ItemThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${theme.palette.white};
  width: 96px;
  height: 56px;
  margin-right: 24px;
  position: relative;
  cursor: pointer;
`;

const Date = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.palette.neutral80};
  margin-left: 8px;
`;
const FilesSelectButton = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.covideoBlue80};
  margin: 22px 24px 22px 0px;
`;

type ItemObject = {
  id: string;
  thumbnail: string;
  title: string;
  createdAt: string;
  source: string;
  type: string;
};

type ItemProps = {
  data: ItemObject;
  disabled?: boolean;
  isGuide?: boolean;
  onFileClick?: (id: string) => void;
};

const ItemCard = ({ data, disabled, isGuide, onFileClick }: ItemProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const isVideoType = data.type.includes('video');
  return (
    <>
      <ItemContainer style={{ ...(disabled && { opacity: '0.5' }) }}>
        <ItemThumbnail
          image={data.thumbnail}
          onClick={() => setIsPreviewOpen(true)}
        >
          {isVideoType && (
            <FaPlay
              style={{ position: 'absolute', left: '40px', top: '20px' }}
              color='white'
              opacity={0.7}
            />
          )}
        </ItemThumbnail>
        <div>
          <Title>{data.title}</Title>
          <Details>
            <BsCalendarEvent color={theme.palette.gray40} size={16} />
            <Date>{data.createdAt}</Date>
          </Details>
        </div>
      </ItemContainer>
      {isGuide && (
        <FilesSelectButton onClick={() => onFileClick && onFileClick(data.id)}>
          Select File
        </FilesSelectButton>
      )}
      {isPreviewOpen && isVideoType && (
        <ModalVideoListVideo
          videoId={''}
          source={data.source}
          handleModalClose={() => setIsPreviewOpen(false)}
        />
      )}
      {isPreviewOpen && data.type.includes('image') && (
        <NewModal
          style={{ content: { padding: 0 } }}
          hideCloseButtonInHeader
          closeModal={() => setIsPreviewOpen(false)}
        >
          <img src={data.source} alt={data.title} />
        </NewModal>
      )}
    </>
  );
};

export default ItemCard;
