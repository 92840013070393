import {
  CONVERSATION_TYPES,
  ISingleConversation,
} from 'lib/api/conversations/getSingleConversation';
import { useAuth, useSockets } from 'lib/context';
import { CONVERSATION_STATUS } from 'lib/context/socket/types';
import { useParams } from 'react-router';
import { IDropDownItems } from '../conversations.types';

export const useTypeOfConversation = (
  singleConversation: ISingleConversation | undefined
) => {
  const { userData } = useAuth();
  const { userStatus } = useSockets();
  const { id } = useParams() as { id: string };

  const items: IDropDownItems[] = [
    { value: CONVERSATION_TYPES.SMS, label: 'SMS' },
    { value: CONVERSATION_TYPES.EMAIL, label: 'Email' },
    { value: CONVERSATION_TYPES.LIVE_CHAT, label: 'Chat' },
  ];
  const isOnline = userStatus[id] === CONVERSATION_STATUS.ONLINE;
  const smsEnabled = !!userData?.customer?.smsEnabled;
  const hasPhoneNumber = !!singleConversation?.contact?.phone;
  const hasEmail = !!singleConversation?.contact?.email;

  const filteredItems = items.filter((item: IDropDownItems) => {
    if (
      item.value === CONVERSATION_TYPES.SMS &&
      (!smsEnabled || !hasPhoneNumber)
    ) {
      return null;
    }
    if (item.value === CONVERSATION_TYPES.LIVE_CHAT && !isOnline) {
      return null;
    }
    if (item.value === CONVERSATION_TYPES.EMAIL && !hasEmail) {
      return null;
    }
    return item;
  });

  return filteredItems;
};
