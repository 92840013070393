import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { MdEmail, MdFileDownload, MdPlayArrow } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import { ModalVideoListVideo } from 'lib/components/modal';
import { reportTypes } from 'lib/const';
import {
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import {
  downloadReportData,
  getUsersDetailsMetrics,
  fetchCustomerUser,
  fetchCustomer,
  moveVideo,
  downloadVideo,
  REPORT_SCOPE,
} from 'lib/api';
import { addThousandCommaSeparator } from 'lib/utils/functions';

import {
  DateRangeSelector,
  timeRangeConstants,
  ReportCard,
  SendReportModal,
  calculateDatesForRange,
} from '../../components';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { successToast } from 'lib/components/toasts/success';
import {
  ORGANIZATION_PICKER_LINK,
  ORGANIZATION_PICKER_TITLE,
  getReportBaseUrl,
  getReportTitle,
} from './Main';
import { VideoTitle } from '../../users/main/Details';
import { Breadcrumbs, Crumb } from '../../components/Breadcrumbs';
import { useAuth } from 'lib/context';
import {
  checkIfHasAccessToMultipleOrg,
  getOrgNameFromAccess,
} from 'lib/utils/organization';
import queryString from 'query-string';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import { Button } from 'react-covideo-common';
import { NotFound } from 'app/pages/notFound/NotFound';

const tableFields = [
  {
    value: 'title',
    label: 'Video',
    type: 'string',
  },
  {
    value: 'cnt',
    label: 'Views',
    type: 'number',
  },
  {
    value: 'ctas',
    label: 'CTA Clicks',
    type: 'number',
  },
  {
    value: 'recordedAt',
    label: 'Date Recorded',
    type: 'date',
  },
];

const TableCell = styled.div`
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const ButtonsTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > button {
    margin-right: 12px;
  }
`;

const UserDetailsWrapper = styled.div`
  .dropdown-container {
    margin: 0;
  }
`;

type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: Date;
};

type Video = {
  videoId: number;
  title: string;
  recordedAt: Date;
  cnt: number;
};

export const UserDetails = (props: any) => {
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const { organizationId } = useParams<{ organizationId: string }>();
  const { userData } = useAuth();
  const { userOrganizationAccess = [] } = userData;
  const organizationName = getOrgNameFromAccess(
    organizationId,
    userOrganizationAccess
  );
  const hasMultipleOrgs = checkIfHasAccessToMultipleOrg(userOrganizationAccess);
  const { reportScope = REPORT_SCOPE.RESELLER } = props;

  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );
  const [startDate, setStartDate] = React.useState<Date>(start);
  const [endDate, setEndDate] = React.useState<Date>(end);
  const [dateRange, setDateRange] = React.useState(initRange);

  const [videosloading, setVideosLoading] = React.useState(false);
  const [userLoading, setUserLoading] = React.useState(true);
  const [company, setCompany] = React.useState({} as any);
  const [user, setUser] = React.useState({} as User);
  const [videos, setVideos] = React.useState([] as Video[]);
  const [filteredVideos, setFilteredVideos] = React.useState([] as Video[]);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showSendReportModal, setShowSendReportModal] = React.useState(false);
  const [showVideoPreviewModal, setShowVideoPreviewModal] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const history = useHistory();

  const { userId, companyId } = props.match.params;

  const fetchUser = async () => {
    setUserLoading(true);
    try {
      const customer = await fetchCustomer(companyId);
      setCompany(customer);
      setIsError(false);
    } catch (error) {
      setIsError(true);
      return;
    }
    fetchCustomerUser(userId)
      .then((data: User) => {
        setUser(data);
        setUserLoading(false);
        setIsError(false);
      })
      .catch(error => {
        console.log('error', error);
        setIsError(true);
        setUserLoading(false);
      });
  };

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);

    setVideosLoading(true);
    getUsersDetailsMetrics(userId, start, end)
      .then((data: Video[]) => {
        data.sort((videoA, videoB) => videoB.cnt - videoA.cnt);
        setVideos(data);
        setCount(data.length);
        setFilteredVideos([...data.slice(0, size)]);
        setPage(0);
        setVideosLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setVideosLoading(false);
      });
  };

  React.useEffect(() => {
    fetchUser();
  }, [userId, companyId]);

  React.useEffect(() => {
    const filtered = videos.filter((video: Video) => {
      const title = (video.title && video.title.toLowerCase()) || '';
      return title.indexOf(searchQuery) !== -1;
    });
    setCount(filtered.length);
    const start = page * size;
    setFilteredVideos([...filtered.slice(start, start + size)]);
  }, [page, size, searchQuery]);

  const onSearch = (query: string) => {
    setPage(0);
    setSearchQuery(query.toLowerCase());
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const downloadVideosList = () => {
    return downloadReportData(
      `/metrics/users/${userId}/download`,
      'users.csv',
      startDate,
      endDate
    );
  };

  const handleCopyToLibrary = async (
    event: MouseEvent,
    videoId: number | string
  ) => {
    if (event && event.detail > 1) {
      return;
    }
    await moveVideo(videoId?.toString(), {
      fromCompany: true,
    });
    successToast({
      title: 'Video copied successfully',
    });
  };

  const handleDownload = (
    event: MouseEvent,
    videoId: number | string,
    title: string
  ) => {
    if (event && event.detail > 1) {
      return;
    }
    downloadVideo(videoId.toString(), `${title}.mp4`);
  };

  let breadcrumbs: Crumb[] = [
    {
      label:
        reportScope === REPORT_SCOPE.ORGANIZATION && hasMultipleOrgs
          ? organizationName
          : getReportTitle(reportScope),
      link: `${getReportBaseUrl(reportScope, organizationId)}${
        location.search
      }`,
    },
    {
      label: company.business,
      link: `${getReportBaseUrl(reportScope, organizationId)}/${companyId}${
        location.search
      }`,
    },
    {
      label: `${user.firstName} ${user.lastName}`,
    },
  ];

  if (reportScope === REPORT_SCOPE.ORGANIZATION && hasMultipleOrgs) {
    breadcrumbs = [
      {
        label: ORGANIZATION_PICKER_TITLE,
        link: ORGANIZATION_PICKER_LINK,
      },
      ...breadcrumbs,
    ];
  }

  if (isError) {
    return <NotFound />;
  }

  return (
    <UserDetailsWrapper>
      {userLoading && (
        <LoadingIndicator isLoading={userLoading} height='300px' />
      )}
      {!userLoading && (
        <div>
          <Breadcrumbs crumbs={breadcrumbs} />
          <HeaderWrapper>
            <DateRangeSelector
              initialValue={dateRange}
              onDateRangeChange={onDateRangeChange}
            />
            <Gap>
              <Button
                text='Send'
                icon={<MdEmail size={20} />}
                onClick={() => setShowSendReportModal(true)}
              />
              <Button
                text='Download'
                icon={<MdFileDownload size={20} />}
                onClick={() => downloadVideosList()}
              />
            </Gap>
          </HeaderWrapper>
          <Gap flexWrap='nowrap' style={{ margin: '32px 0' }}>
            <ReportCard
              reportType={reportTypes.RECORDED}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
            <ReportCard
              reportType={reportTypes.SENT}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
            <ReportCard
              reportType={reportTypes.VIEWS}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
            <ReportCard
              reportType={reportTypes.CTA}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
          </Gap>
          <div style={{ width: '300px' }}>
            <Search placeholder='Search videos...' onSearch={onSearch} />
          </div>
          <div style={{ marginTop: '32px' }}>
            {videosloading && (
              <LoadingIndicator isLoading={videosloading} height='300px' />
            )}
            {!videosloading && (
              <>
                {!!filteredVideos.length && (
                  <TableContextProvider
                    total={count}
                    onChange={onPaginationChange}
                    initPage={page}
                    initSize={size}
                  >
                    <div style={{ overflowX: 'auto' }}>
                      <Table
                        compact={true}
                        headers={[
                          ...tableFields.map(item => {
                            return <TableCell>{item.label}</TableCell>;
                          }),
                          '',
                        ]}
                        hoverable={false}
                        rows={filteredVideos.map(
                          (video: any, index: number) => ({
                            key: index,
                            columns: [
                              ...tableFields.map(item => {
                                if (item.type === 'number') {
                                  return (
                                    <TableCell>
                                      {addThousandCommaSeparator(
                                        video[item.value] || 0
                                      )}
                                    </TableCell>
                                  );
                                }

                                if (item.value === 'title') {
                                  return (
                                    <ButtonsTableCell
                                      style={{
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setShowVideoPreviewModal(video.videoId);
                                      }}
                                    >
                                      <Button
                                        icon={<MdPlayArrow size={20} />}
                                      />
                                      <VideoTitle>
                                        {video.title || '-'}
                                      </VideoTitle>
                                    </ButtonsTableCell>
                                  );
                                }
                                return (
                                  <TableCell>
                                    {video[item.value] || '-'}
                                  </TableCell>
                                );
                              }),
                              <ButtonsTableCell>
                                <Button
                                  variant='secondary'
                                  text='Copy to Library'
                                  onClick={async (
                                    event: MouseEvent | React.SyntheticEvent
                                  ) => {
                                    await handleCopyToLibrary(
                                      event as MouseEvent,
                                      video.videoId
                                    );
                                  }}
                                />
                                <Button
                                  icon={<MdFileDownload size={20} />}
                                  onClick={e => {
                                    handleDownload(
                                      e as unknown as MouseEvent,
                                      video.videoId,
                                      video.title
                                    );
                                  }}
                                />
                                <FaChevronRight
                                  color='#9297a2'
                                  size={14}
                                  onClick={() =>
                                    history.push(
                                      `${getReportBaseUrl(
                                        reportScope,
                                        organizationId
                                      )}/${companyId}/users/${userId}/videos/${
                                        video.videoId
                                      }${location.search}`
                                    )
                                  }
                                />
                              </ButtonsTableCell>,
                            ],
                          })
                        )}
                      />
                    </div>
                    <TableFooter>
                      <TablePaginationNew />
                      <TablePaginationSizeNew globalName='videos_list' />
                    </TableFooter>
                  </TableContextProvider>
                )}
                {!filteredVideos.length && (
                  <div style={{ textAlign: 'center' }}>No data to show.</div>
                )}
              </>
            )}
          </div>

          {showSendReportModal && (
            <SendReportModal
              handleModalClose={() => {
                setShowSendReportModal(false);
              }}
              startDate={startDate}
              endDate={endDate}
              range={dateRange}
              reportUrl={`/metrics/users/${userId}`}
            />
          )}

          {!!showVideoPreviewModal && (
            <ModalVideoListVideo
              videoId={showVideoPreviewModal}
              handleModalClose={() => {
                setShowVideoPreviewModal('');
              }}
            />
          )}
        </div>
      )}
    </UserDetailsWrapper>
  );
};
