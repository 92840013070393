import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { annotationsKeys } from './annotationsKeys';

export const getTemplates = async () => {
  const response = await EXPRESS_API.get('/annotation-templates');

  return response.data;
};

export const useGetAnnotationTemplatesQuery = () => {
  return useQuery(annotationsKeys.templates(), getTemplates);
};
