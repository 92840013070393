import { useWheelsTvSourceQuery } from 'lib/api/wheelsTv/useWheelsTvSourceQuery';
import { WTVListItem } from 'lib/api/wheelsTv/useWheelsTvVideosQuery';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { WheelsTVTable } from '../wtvModal/WheelsTVTable';

const Content = styled.div`
  padding-top: 32px;
`;

interface Props {
  selectWheelsTvVideo?: (url: string) => void;
}

export const TabWheelsTV = ({ selectWheelsTvVideo }: Props) => {
  const [wtvVideoId, setWtvVideoId] = useState('');
  const [videoSource, setVideoSource] = useState('');
  useWheelsTvSourceQuery({ wtvVideoId, setVideoSource });
  const handleOnSelectVideo = (wtvItem: WTVListItem) => {
    const wtvVideoId = wtvItem?.wtvVideoId;
    if (wtvVideoId) {
      setWtvVideoId(wtvVideoId);
    }
  };
  useEffect(() => {
    if (videoSource) {
      selectWheelsTvVideo?.(videoSource);
    }
  }, [videoSource]);

  return (
    <Content>
      <WheelsTVTable
        onSelectVideo={handleOnSelectVideo}
        showAddToCovideo={false}
      />
    </Content>
  );
};
