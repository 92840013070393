import React, { useState, useEffect } from 'react';
import onboardingStepOneCompanyAnimation from 'lib/animations/OnBoarding_Step_2_company.json';
import onboardingStepOneMyselfAnimation from 'lib/animations/OnBoarding_Step_2_single.json';
import { ItemSelector, FreemiumOnboardingPageTemplate } from '../components';
import { freemiumOboardingFlows } from '../const';
import { LoadingIndicator } from 'lib/components';
import { useFreemiumOnboardingQuery } from 'lib/api/freemiumOnboarding/getFreemiumOnboarding';
import companyIcon from 'assets/images/freemiumOnboarding/company.svg';
import personalIcon from 'assets/images/freemiumOnboarding/personal.svg';
import { Gap } from 'lib/components/styles/layout';

export const StepOneChoosePath = () => {
  const { data: freemiumOnboardingData, isLoading } =
    useFreemiumOnboardingQuery();
  const [selectedFlow, setSelectedFlow] = useState(
    freemiumOnboardingData?.flow || freemiumOboardingFlows[0].value
  );
  const nextStepRoute =
    selectedFlow === 'Personal'
      ? '/onboarding-freemium/step-two/myself'
      : '/onboarding-freemium/step-two/my-team';

  useEffect(() => {
    if (selectedFlow && window && window.Intercom) {
      window.Intercom('update', {
        type_of_use: selectedFlow,
      });
    }
  }, [selectedFlow]);

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <FreemiumOnboardingPageTemplate
      currentStep={1}
      stepText='I plan to use Covideo for...'
      nextStepRoute={nextStepRoute}
      rightColumnAnimationSrc={
        selectedFlow === 'Personal'
          ? onboardingStepOneMyselfAnimation
          : onboardingStepOneCompanyAnimation
      }
      rightColumnChildren={
        <Gap justifyContent='center'>
          {selectedFlow === 'Personal' ? (
            <img alt='personal' width='476px' src={personalIcon} />
          ) : (
            <img alt='company' src={companyIcon} />
          )}
        </Gap>
      }
      dataToBeSaved={{ flow: selectedFlow }}
      leftColumnChildren={
        <ItemSelector
          itemHeight='152px'
          itemWidth='132px'
          items={freemiumOboardingFlows}
          selectedItem={selectedFlow}
          setSelectedValue={setSelectedFlow}
        />
      }
    />
  );
};
