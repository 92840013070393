import React from 'react';
import styled from 'styled-components/macro';
import { TableField, UsageReport } from 'lib/context';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
} from 'lib/components';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';
import dayjs from 'dayjs';
import {
  Layout,
  NormalText,
  PaginationWrapper,
  TableCell,
} from '../../index.styled';
import {
  UsageReportPackageNames,
  UsageReportPackageTypes,
} from 'lib/const/UsageReportPackageTypes';
import { SortButtons } from '../../components/SortButtons';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';

enum UsageReportsTableColumns {
  USERNAME = 'username',
  EMAIL = 'email',
  LAST_LOGIN = 'lastLogin',
  LAST_VIDEO = 'lastVideo',
  LAST_SENT = 'lastSent',
  VIDEOS = 'videos',
  PLAN = 'plan',
}

const tableFields: TableField[] = [
  {
    value: UsageReportsTableColumns.USERNAME,
    label: 'Username & ID',
    sortable: true,
    sortKey: 'ID',
    width: 200,
  },
  {
    value: UsageReportsTableColumns.EMAIL,
    label: 'Email',
    sortable: true,
    sortKey: 'EMAIL',
    width: 220,
  },
  {
    value: UsageReportsTableColumns.LAST_LOGIN,
    label: 'Last Login',
    sortable: true,
    sortKey: 'LAST_LOGIN',
    width: 120,
  },
  {
    value: UsageReportsTableColumns.LAST_VIDEO,
    label: 'Last Video',
    width: 96,
  },

  {
    value: UsageReportsTableColumns.LAST_SENT,
    label: 'Last Sent',
    width: 96,
  },
  {
    value: UsageReportsTableColumns.VIDEOS,
    label: 'Videos',
    width: 80,
  },
  {
    value: UsageReportsTableColumns.PLAN,
    label: 'Plan',
    width: 80,
  },
];

const TextWrapper = styled.div<{ width?: number }>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
`;
const Username = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${theme.palette.gray100};
`;

const Id = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${theme.palette.gray60};
`;

const Trial = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.palette.covideoOrange100};
`;

type Props = {
  reportCount: number;
  reportArray: UsageReport[];
  page: number;
  size: number;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  openPlanClick(report: UsageReport): void;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
};

export const UsageReportTable = ({
  reportCount,
  reportArray,
  page,
  size,
  sortKey,
  order,
  openPlanClick,
  onPaginationChange,
  onSortingUpdate,
}: Props) => {
  return (
    <Layout>
      <TableContextProvider
        total={reportCount}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div
          className='scroll--container'
          style={{ overflowX: 'auto', paddingBottom: '20px' }}
        >
          <Table
            compact={true}
            relative={true}
            headers={tableFields.map((item, index) => {
              return (
                <TableCell key={index} width={item.width} onClick={() => {}}>
                  {item.label}
                  {item.sortable && item.sortKey && (
                    <SortButtons
                      isSorted={sortKey === item.sortKey}
                      order={order}
                      onChange={value =>
                        onSortingUpdate(item.sortKey ?? '', value)
                      }
                    />
                  )}
                </TableCell>
              );
            })}
            hoverable={false}
            fixColumnIndex='left'
            rows={reportArray.map((report: UsageReport) => ({
              key: report.id,
              columns: tableFields.map((item, index) => {
                switch (item.value) {
                  case UsageReportsTableColumns.USERNAME:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      >
                        <TextWrapper width={item.width}>
                          <Username>{report.username}</Username>
                          <Id>ID: {report.id}</Id>
                        </TextWrapper>
                      </TableCell>
                    );
                  case UsageReportsTableColumns.EMAIL:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      >
                        <NormalText>{report.email}</NormalText>
                      </TableCell>
                    );
                  case UsageReportsTableColumns.LAST_LOGIN:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      >
                        <NormalText>
                          {report.loginDate
                            ? dayjs(new Date(report.loginDate * 1000)).format(
                                STANDARD_DATE_FORMAT
                              )
                            : '-'}
                        </NormalText>
                      </TableCell>
                    );
                  case UsageReportsTableColumns.LAST_VIDEO:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      >
                        <NormalText>
                          {report.lastVideoDate
                            ? dayjs(
                                new Date(report.lastVideoDate * 1000)
                              ).format(STANDARD_DATE_FORMAT)
                            : '-'}
                        </NormalText>
                      </TableCell>
                    );
                  case UsageReportsTableColumns.LAST_SENT:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      >
                        <NormalText>
                          {report.lastSentDate
                            ? dayjs(
                                new Date(report.lastSentDate * 1000)
                              ).format(STANDARD_DATE_FORMAT)
                            : '-'}
                        </NormalText>
                      </TableCell>
                    );
                  case UsageReportsTableColumns.VIDEOS:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      >
                        <NormalText>{report.videos}</NormalText>
                      </TableCell>
                    );
                  case UsageReportsTableColumns.PLAN:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        cursor={
                          report.plan === UsageReportPackageTypes.TRIAL
                            ? 'pointer'
                            : 'default'
                        }
                        onClick={() => {}}
                      >
                        {report.plan === UsageReportPackageTypes.TRIAL ? (
                          <Trial onClick={() => openPlanClick(report)}>
                            {UsageReportPackageNames[report.plan]}
                          </Trial>
                        ) : (
                          <NormalText>
                            {UsageReportPackageNames[report.plan]}
                          </NormalText>
                        )}
                      </TableCell>
                    );
                  default:
                    return (
                      <TableCell
                        key={index}
                        width={item.width}
                        onClick={() => {}}
                      ></TableCell>
                    );
                }
              }),
              onClick: () => {},
            }))}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
