export enum VIDE0_ACTIVITY_TYPE {
  QUOTE_SEND = 'Quote-Send',
  OPENED_EMAIL = 'Opened-Email',
  WATCHED_VIDEO = 'Watched-Video',
  APPROVED_ITEMS = 'Approved-Items',
  UPDATE_QUOTE = 'Update-Quote',
  RECORDED_VIDEO = 'Recorded-Video',
  APPROVED_VIDEO = 'Approved-Video',
  DENIED_VIDEO = 'Denied-Video',
  DELETE_VIDEO = 'Delete-Video',
  QUOTE_RESEND = 'Quote-Resend',
  UPDATE_RO = 'RO-Update',
  QUICKSHARE = 'Quickshare',
  COPY_SMS = 'COPY_SMS',
  COPY_EMAIL = 'COPY_EMAIL',
  CRM_SHARE = 'Crm-Share',
  WATCHED_VIDEO_UNKNOWN = 'WATCHED_VIDEO_UNKNOWN',
}
