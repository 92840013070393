import { CheckboxInput } from 'lib/components';

import { Gap } from 'lib/components/styles/layout';
import { ParagraphNormal } from 'lib/components/styles/typography';
import React from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';
import { VISIBILITY_TYPE } from '../SelectableArea';

const ItemWrapper = styled(Gap)<{ selected?: boolean }>`
  color: #272a32;
  padding: 8px 12px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;

  ${({ selected }) =>
    selected &&
    css`
      border-radius: 4px;
      background: #001b53;
      color: white;
    `}
`;

interface IProps {
  onItemClick: (id: number, item: VISIBILITY_TYPE) => void;
  type: VISIBILITY_TYPE;
  id: number;
  displayText: string;
  isSelected: boolean;
  isChecked: boolean;
  checkGroupIndicator?: boolean;
  onCheckboxClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: JSX.Element;
}

export const SelectableItem = ({
  onItemClick,
  type,
  id,
  displayText,
  isSelected,
  onCheckboxClick,
  checkGroupIndicator,
  isChecked,
  icon,
}: IProps) => {
  const isResellerVisibility = type === VISIBILITY_TYPE.RESELLERS;
  return (
    <ItemWrapper
      onClick={() => onItemClick(id, type)}
      flexDirection='row'
      gap='12px'
      flexWrap='nowrap'
      selected={isSelected}
      width='100%'
      justifyContent='space-between'
    >
      <Gap
        width={isResellerVisibility ? '80%' : '90%'}
        flexWrap='nowrap'
        gap='10px'
      >
        <div
          style={{
            background: 'rgb(247, 249, 250)',
            borderRadius: '6px',
          }}
        >
          <CheckboxInput
            checkGroupIndicator={checkGroupIndicator}
            checked={isChecked}
            onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
            }}
            onChange={onCheckboxClick}
          />
        </div>
        <ParagraphNormal
          color='inherit'
          ellipsis
          style={{
            whiteSpace: 'nowrap',
            gap: 4,
          }}
        >
          {icon}
          {displayText || 'No business name'}
        </ParagraphNormal>
      </Gap>
      {isResellerVisibility && (
        <ParagraphNormal color='inherit'>{id}</ParagraphNormal>
      )}
      {type !== VISIBILITY_TYPE.USERS && (
        <Gap gap='10px'>
          <MdArrowForwardIos />
        </Gap>
      )}
    </ItemWrapper>
  );
};
