import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { useGetAllVideoAttributesQuery } from 'lib/api/videoAttributes/useGetAllVideoAttributesQuery';
import { Gap } from 'lib/components/styles/layout';
import VideoUploadFolderDropdown from 'lib/components/videoUpload/VideoUploadFolderDropdown';
import { ICombinedUserData, useAuth } from 'lib/context';
import { getTitle } from 'lib/utils/functions';
import styled from 'styled-components/macro';
import { VideoPlayer } from '../../video/videoPlayer';
import { DetailsPreviewHeader } from './form/DetailsPreviewHeader';
import { DetailsRecordPreviewTags } from './form/DetailsRecordPreviewTags';
import { DetailsPreviewVideoAttributes } from './form/DetailsPreviewVideoAttributes';
import * as Yup from 'yup';
import { AutomotiveRole } from 'lib/const';
import { VideoRequestDetails } from './form/VideoRequestDetails';
import { DetailsPreviewAttributesHeader } from './form/DetailsPreviewAtrributesHeader';
import { useGetUsersByAutomotiveRolesQuery } from 'lib/api/users/automotive/useUsersByAutomotiveRolesQuery';
import {
  DetailsRecordingPreviewProps,
  DetailsPreviewRecordFormValues,
  FolderOption,
} from './types';
import { useVideoRecordUpload } from '../useVideoRecorderUpload';
import { FILE_EXTENSION } from 'lib/hooks/useMediaRecorder';
import { UploadLoader } from '../UploadLoader';
import { LoadingIndicator } from 'lib/components';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { useHistory } from 'react-router';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import {
  DEFAULT_UPLOAD_ID,
  DEFAULT_UPLOAD_PROGRESS,
} from 'lib/api/aws/useS3UploadMutation';

const DEFAULT_UPLOAD_FOLDER = {
  value: -2,
  label: 'Primary',
  isFolderShared: false,
  access: 'edit',
};

const Container = styled.div`
  padding-bottom: 64px;
  display: flex;
  position: relative;
  margin: 32px auto 0 auto;
  width: 1000px;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const VideoWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 488px;
  .fullscreen {
    height: 274px;
    display: block;
  }
`;

const FormStyles = styled(Form)`
  width: 380px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const FormBody = styled(Gap)`
  flex-flow: initial;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const RecorderLoaderWrapper = styled(Gap)`
  width: 770px;
  height: 437px;
  background: '#f6f7f9';
  margin-top: 90px;
`;

const generateName = (fileExt: string, userData: ICombinedUserData) => {
  const randomSuffix = (Math.random() + 1).toString(36).substring(2);
  const fileName = `${userData.userId}_${userData.customerId}_${randomSuffix}.${fileExt}`;
  return fileName;
};

export const DetailsRecordingPreview = ({
  videoBlob,
  handleGoToRecordHome,
}: DetailsRecordingPreviewProps) => {
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const { userData } = useAuth();
  const history = useHistory();
  const { customer } = userData;
  const { userId } = userData;
  const [continueToSendShare, setContinueToSendShare] = useState(false);
  const initialCovideoName = getTitle();
  const {
    setRecordingFormData,
    isLoading: isSubmittingForm,
    createProgress,
    encodeProgress,
    uploadToS3Progress,
    progressState,
  } = useVideoRecordUpload({ continueToSendShare });

  const uploadProgress =
    uploadToS3Progress[DEFAULT_UPLOAD_ID] || DEFAULT_UPLOAD_PROGRESS;
  const isAutomotive = userData.isAutomotive;
  const isAutomotiveService = userData.isAutomotiveServiceRole;
  const isTechnician = userData.automotiveRole === AutomotiveRole.TECHNICIAN;

  const { data, isLoading } = useGetAllVideoAttributesQuery({
    page: 0,
    size: 200, // attempt to fetch all on the first render
    filterByDepartment: true,
  });

  const params = [
    { customerId: customer.customerId, role: AutomotiveRole.SERVICE_ADVISOR },
    {
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_MANAGER,
    },
  ];

  const { isLoading: isGettingAutomotiveUsers, results } =
    useGetUsersByAutomotiveRolesQuery({
      params,
      enabled: isAutomotiveService,
    });

  useEffect(() => {
    const url = URL.createObjectURL(videoBlob);
    setVideoUrl(url);

    // Clean up the URL object when the component unmounts or the videoSource changes
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [videoBlob]);

  const onSubmitHandler = (values: DetailsPreviewRecordFormValues) => {
    const fileName = generateName(FILE_EXTENSION, userData);
    const videoFile = new File([videoBlob], fileName, { type: videoBlob.type });
    setRecordingFormData({ ...values, file: videoFile });
  };

  const attributes =
    data?.attributes?.map(field => {
      const defaultDropdownValue =
        field.type === 'dropdown' &&
        field.dropdownValues?.find(value => value.default)?.value;

      return {
        value: defaultDropdownValue || field?.default || '',
        ...field,
      };
    }) || [];

  const validationSchema = Yup.object().shape({
    attributes: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().when('isRequired', {
          is: 1,
          then: Yup.string().required('This field is required'),
          otherwise: Yup.string(),
        }),
      })
    ),
    title: Yup.string()
      .trim()
      .required('Title is required')
      .matches(/^[^.]*$/, 'Title should not contain a dot (.)'),
    ...(isAutomotive
      ? {
          videoRequest: Yup.object().shape({
            advisorId: Yup.string().when([], {
              is: () => isTechnician,
              then: Yup.string().nullable().required('Advisor ID is required'),
              otherwise: Yup.string(),
            }),
            repairOrderNumber: Yup.string().when([], {
              is: () => isTechnician,
              then: Yup.string().required('Repair Order Number is required'),
              otherwise: Yup.string(),
            }),
          }),
        }
      : {}),
  });

  if (isLoading || isGettingAutomotiveUsers) {
    return <LoadingIndicator isLoading />;
  }

  if (isSubmittingForm) {
    return (
      <RecorderLoaderWrapper>
        <UploadLoader
          progressState={progressState}
          encodeProgress={encodeProgress}
          uploadToS3Progress={uploadProgress}
          createProgress={createProgress}
        />
      </RecorderLoaderWrapper>
    );
  }

  const INITIAL_VALUES: DetailsPreviewRecordFormValues = {
    title: initialCovideoName,
    folder: DEFAULT_UPLOAD_FOLDER,
    videoTags: [],
    attributes,
    ...(isAutomotive
      ? {
          videoRequest: {
            customerName: '',
            videoType: '',
            vehicle: {
              vin: '',
              stockNumber: '',
            },
            //assign to self if service and not technician
            advisorId: isAutomotiveService && !isTechnician ? userId : '',
            repairOrderNumber: '',
            note: '',
          },
        }
      : {}),
  };
  return (
    <Container>
      <Formik
        initialValues={INITIAL_VALUES}
        validateOnMount={true}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <>
            <DetailsPreviewHeader
              handleGoToRecordHome={handleGoToRecordHome}
              setContinueToSendShare={setContinueToSendShare}
            />
            <FormBody flexDirection='column' width='100%' gap='36px'>
              <FormStyles>
                <FormikInputField name='title' label='Video Title' isRequired />
                <Field
                  name='folder'
                  component={VideoUploadFolderDropdown}
                  value={values.folder}
                  setValue={(val: FolderOption) => setFieldValue('folder', val)}
                  showAllFolderOption={false}
                />
                <DetailsRecordPreviewTags />
                <DetailsPreviewAttributesHeader />
                <VideoRequestDetails
                  results={results}
                  fetchSoldVehiclesForAutomotive={false}
                />
                <DetailsPreviewVideoAttributes />
              </FormStyles>
              <VideoWrapper>
                {videoUrl !== null && (
                  <VideoPlayer
                    videoRef={React.createRef()}
                    videoSource={videoUrl}
                  />
                )}
              </VideoWrapper>
            </FormBody>
          </>
        )}
      </Formik>
      <RouteLeavingGuard
        when={true}
        stay={true}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return true;
        }}
        title='Leave without saving the recording?'
        text='Your recording will not be saved. This action can’t be undone.'
        confirmButtonText='Continue'
        discardButtonText='Leave'
      />
    </Container>
  );
};
