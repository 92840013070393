import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { MeetingRecording } from './types';

const getMeetingRecordings = async (
  meetingId: string
): Promise<MeetingRecording[]> => {
  const response = await EXPRESS_API.get(`/meeting/${meetingId}/recordings`);
  return response.data as MeetingRecording[];
};

export const useGetMeetingRecordingsQuery = (meetingId: string) => {
  return useQuery(
    meetingKeys.meeting_recordings(meetingId),
    () => getMeetingRecordings(meetingId),
    {
      enabled: meetingId !== '',
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
};
