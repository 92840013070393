import * as React from 'react';
import { Formik, Form } from 'formik';
import styled, { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator, ButtonSwitch, RadioInput } from 'lib/components';
import { SubscriptionDetailModal } from './SubscriptionDetailModal';
import { updateUser } from 'lib/api';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { CaptionsNotEnabled } from './CaptionsNotEnabled';

interface RadioButtonsWrapperProps {
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
}

const FlexForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const RowInner = styled.div`
  display: block;
  width: 100%;
  padding: 0.5rem 0 0.5rem 50px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldLabel = styled.label`
  max-width: 170px;
  width: 100%;
  margin-right: 10px;
`;

const FieldLabelInner = styled.label`
  max-width: 170px;
  width: 100%;
  margin-right: 10px;
  color: #9297a2;
`;

const RadioButtonsWrapper = styled.div<RadioButtonsWrapperProps>`
  display: block;
  justify-content: ${props => props.justifyContent || 'space-between'};
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'center'};
  width: ${props => props.width || '100%'};
  height: 64px;
  box-sizing: border-box;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #eeeff2;
`;

const Processing = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2109094a;
  top: 0;
  right: 0;
`;

const ManageCaption = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: #001b53;
  text-decoration: underline;
  cursor: pointer;
`;

let fields = {
  autoTranscribe: false,
  transcriptionDefaultEnabled: false,
  transcriptionAccess: 'disabled',
};

const views = [
  {
    value: 'enabled',
    text: 'Enabled',
  },
  {
    value: 'disabled',
    text: 'Disabled',
  },
];

type UserUpdateProps = {
  setUpdateVerifyFunAddon?: React.Dispatch<React.SetStateAction<any>>;
  fetchedUserData?: any;
  updateVerifyFunAddon?: React.Dispatch<React.SetStateAction<any>>;
  setVerifiedStatus?: React.Dispatch<React.SetStateAction<any>>;
  setActionAddon?: React.Dispatch<React.SetStateAction<any>>;
  subscription?: any;
};

const loadFields = (data: any) => {
  return {
    autoTranscribe: data.autoTranscribe ? data.autoTranscribe : false,
    transcriptionDefaultEnabled: data.transcriptionDefaultEnabled
      ? data.transcriptionDefaultEnabled
      : false,
    transcriptionAccess: data.transcriptionAccess ? 'enabled' : 'disabled',
    access: data.access ? data.access : 1,
  };
};

export const UserAddons = (props: UserUpdateProps) => {
  const [verifiedVal, setVerifiedVal] = React.useState<any>(false);
  const [initialVerifiedVal, setInitialVerifiedVal] =
    React.useState<any>(false);
  const [user, setUser] = React.useState<any>(fields);
  const [loading, setLoading] = React.useState<any>(true);
  const [processing, setProcessing] = React.useState<any>(false);
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const { showError } = useToastError();
  const history = useHistory();
  const themes = useTheme();
  const {
    setUpdateVerifyFunAddon,
    fetchedUserData,
    setActionAddon,
    setVerifiedStatus,
    subscription,
  } = props;

  const prepareDataToSendForEdit = async (value: any) => {
    return new Promise(async resolve => {
      const payload: any = {};
      payload.active = verifiedVal ? 1 : 0;
      payload.transcriptionAccess =
        value.transcriptionAccess == 'enabled' ? true : false;
      payload.transcriptionDefaultEnabled = value.transcriptionDefaultEnabled
        ? true
        : false;
      payload.autoTranscribe = value.autoTranscribe ? true : false;
      return resolve(payload);
    });
  };

  const saveChanges = async (forceSave = false) => {
    const payload = await prepareDataToSendForEdit(user);
    try {
      // if(!forceSave && subscription && Object.keys(subscription).length && (subscription.totalActiveUsers >= subscription.totalAllowedUsers)) {
      //   if(!initialVerifiedVal && verifiedVal) {
      //     setShowSubscriptionModal(true);
      //     return;
      //   }
      // }
      if (
        !forceSave &&
        subscription &&
        Object.keys(subscription).length &&
        subscription.totalActiveUsers >= subscription.totalAllowedUsers
      ) {
        if (!initialVerifiedVal && verifiedVal && user.access != 4) {
          // initially user is not verfied after that user verified with button toggle and user type is not supervisor
          setShowSubscriptionModal(true);
          return;
        }
      }
      setProcessing(true);
      await updateUser(fetchedUserData.id, payload, true);
      localStorage.setItem('showTabChangePopup', '0');
      setProcessing(false);
      successToast({ title: `Data saved successfully` });
      history.push('/users/list');
    } catch (error) {
      setProcessing(false);
      showError(error);
    }
  };

  React.useEffect(() => {
    localStorage.setItem('showTabChangePopup', '0');
    if (setActionAddon) setActionAddon({ save: saveChanges });
    if (setUpdateVerifyFunAddon)
      setUpdateVerifyFunAddon({ update: setVerifiedVal });
    if (JSON.stringify(user) != JSON.stringify(fields)) {
      localStorage.setItem('showTabChangePopup', '1');
    }
  }, [user, verifiedVal, loading]);
  React.useEffect(() => {
    const handleFetchedUser = () => {
      if (Object.keys(fetchedUserData).length < 1) {
        setLoading(false);
      } else {
        fields = { ...fields, ...loadFields(fetchedUserData) };
        setUser(fields);
        if (setVerifiedStatus) {
          setVerifiedStatus(fetchedUserData.verified ? true : false);
          setVerifiedVal(fetchedUserData.verified ? true : false);
          setInitialVerifiedVal(fetchedUserData.verified ? true : false);
        }
      }
      setLoading(false);
    };
    handleFetchedUser();
  }, [fetchedUserData]);

  if (loading) {
    return <LoadingIndicator isLoading={loading} height='500px' />;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={fields}
        // initialErrors={error}//{validateUser(fields, usernameSearch)}
        // validationSchema={fieldsSchema}
        //{values => validateUser(values, usernameSearch)}
        onSubmit={() => {}}
      >
        {() => (
          <FlexForm>
            <Row>
              <FieldLabel>Captions:</FieldLabel>
              {!!fetchedUserData.transcriptionAccess && (
                <RowInner>
                  <Row>
                    <FieldLabelInner>Access:</FieldLabelInner>
                    <ButtonSwitch
                      defaultValue={user.transcriptionAccess}
                      values={views}
                      onChange={newView => {
                        setUser({ ...user, transcriptionAccess: newView });
                      }}
                    />
                    <ManageCaption
                      onClick={() => {
                        history.push('/profile/add-ons/captions/manage');
                      }}
                    >
                      Manage Captions
                    </ManageCaption>
                  </Row>
                  <Row>
                    <FieldLabelInner>Auto Transcription:</FieldLabelInner>
                    <RadioButtonsWrapper width={'90%'}>
                      <RadioInput
                        name={'Transcription'}
                        value={'#FFFFFF'}
                        text={'Let user choose videos to transcribe'}
                        checkedColor={themes.colors.primary[100]}
                        checked={!user.autoTranscribe}
                        onChange={() => {}}
                        onClick={() =>
                          setUser({
                            ...user,
                            autoTranscribe: !user.autoTranscribe,
                          })
                        }
                      />
                      <RadioInput
                        name={'Transcription'}
                        value={'#000000'}
                        text={'Automatically transcribe new video'}
                        checkedColor={themes.colors.primary[100]}
                        checked={user.autoTranscribe}
                        onChange={() => {}}
                        onClick={() =>
                          setUser({
                            ...user,
                            autoTranscribe: !user.autoTranscribe,
                          })
                        }
                      />
                    </RadioButtonsWrapper>
                  </Row>
                  <Row>
                    <FieldLabelInner>Visibility:</FieldLabelInner>
                    <RadioButtonsWrapper width={'90%'}>
                      <RadioInput
                        name={'Visibility'}
                        value={'#FFFFFF'}
                        text={'Let user verify captions before showing'}
                        checkedColor={themes.colors.primary[100]}
                        checked={!user.transcriptionDefaultEnabled}
                        onChange={() => {}}
                        onClick={() =>
                          setUser({
                            ...user,
                            transcriptionDefaultEnabled:
                              !user.transcriptionDefaultEnabled,
                          })
                        }
                      />
                      <RadioInput
                        name={'Visibility'}
                        value={'#000000'}
                        text={'Show captions by default on all videos'}
                        checkedColor={themes.colors.primary[100]}
                        checked={user.transcriptionDefaultEnabled}
                        onChange={() => {}}
                        onClick={() =>
                          setUser({
                            ...user,
                            transcriptionDefaultEnabled:
                              !user.transcriptionDefaultEnabled,
                          })
                        }
                      />
                    </RadioButtonsWrapper>
                  </Row>
                </RowInner>
              )}
              {!fetchedUserData.transcriptionAccess && <CaptionsNotEnabled />}
            </Row>
            <Divider />
          </FlexForm>
        )}
      </Formik>
      {processing && (
        <Processing>
          <LoadingIndicator
            isLoading={true}
            height='500px'
            zIndex={2}
            text='Updating'
          />
        </Processing>
      )}
      {showSubscriptionModal && (
        <SubscriptionDetailModal
          subscription={subscription}
          handleModalClose={force => {
            setShowSubscriptionModal(false);
            if (force) saveChanges(true);
          }}
        />
      )}
    </>
  );
};
