import React, { useState, useEffect } from 'react';
import { InventoryItem } from 'lib/context';
import {
  RecordedVideoItem,
  TableVideosRecordedListing,
} from './TableVideosRecordedListing';
import styled from 'styled-components/macro';
import { Dropdown } from 'lib/components';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

type FilterProps = {
  margin?: string;
};
const Filter = styled.div<FilterProps>`
  margin: ${props => (props.margin ? props.margin : '0')};
  min-width: 280px;
`;

type Option = {
  value: string;
  label: string;
};
type Props = {
  item: InventoryItem;
  isLoading: boolean;
  videoIdsToUpdate: string[];
  shareWithCompany: (videoId: string, videoRequestId: string) => void;
};
export const ItemVideosRecorded = ({
  item,
  shareWithCompany,
  isLoading,
  videoIdsToUpdate,
}: Props) => {
  const videos = !item?.videoRequests
    ? ([] as RecordedVideoItem[])
    : item.videoRequests.map(vr => {
        return {
          videoRequest: vr,
          video: vr.video,
          title: vr?.video?.title || '',
          user: `${vr.user.firstName} ${vr.user.lastName}`,
          customer: `${vr.customerName || ''}`,
          createdAt: `${vr.createdAt}`,
        };
      });
  let allCustomers: Option[] = [];
  let allSalespersons: Option[] = [];
  let allStatuses: Option[] = [];
  videos.forEach(v => {
    allCustomers.push({ label: v.customer, value: v.customer });
    allSalespersons.push({ label: v.user, value: v.user });
    if (v.videoRequest?.emailStatus) {
      const value = v.videoRequest.emailStatus;
      const label = (value.charAt(0).toUpperCase() + value.slice(1)).replace(
        /[^a-zA-Z ]/g,
        ' '
      );
      if (label) {
        allStatuses.push({
          label,
          value,
        });
      }
    }
  });
  const customerOptions = Array.from(
    new Set(allCustomers.map(option => option.value).filter(value => value))
  ).map(value => {
    return allCustomers.find(option => option.value === value.trim());
  });
  customerOptions.unshift({ value: '', label: 'All customers' });

  allSalespersons = videos.map(v => {
    return { label: v.user, value: v.user };
  });
  const salespersonOptions = Array.from(
    new Set(allSalespersons.map(a => a.value))
  ).map(value => {
    return allSalespersons.find(a => a.value === value);
  });
  salespersonOptions.unshift({ value: '', label: 'All sales representatives' });

  const statusOptions = Array.from(new Set(allStatuses.map(a => a.value))).map(
    value => {
      return allStatuses.find(a => a.value === value);
    }
  );
  statusOptions.unshift({ value: '', label: 'All statuses' });

  const [videosArray, setVideosArray] = useState<RecordedVideoItem[]>();
  const [sort, setSort] = useState('');

  const [filterCustomer, setFilterCustomer] = useState('');
  const [filterSalesperson, setFilterSalesperson] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const sortElement = (sortParam: any) => {
    let newSort = '';
    if (sort.length && sort.includes(sortParam)) {
      newSort = sort.includes('-') ? sort.replace('-', '') : `-${sort}`;
    } else if (!sort.includes(sortParam)) {
      newSort = `${sortParam}`;
    }
    setSort(newSort);
    if (!videosArray || !newSort) return;
    setVideosArray(
      videosArray.sort((a: any, b: any) => {
        const field = newSort.replace('-', '');
        return newSort.includes('-')
          ? b[field].localeCompare(a[field])
          : a[field].localeCompare(b[field]);
      })
    );
  };

  useEffect(() => {
    if (!videos) return;
    setVideosArray(videos);
  }, [item.videoRequests]);

  useEffect(() => {
    if (!videos) return;
    const filteredVideos = videos.filter(
      v =>
        (!filterCustomer || v.customer === filterCustomer) &&
        (!filterSalesperson || v.user === filterSalesperson) &&
        (!filterStatus || v.videoRequest.emailStatus === filterStatus)
    );
    setVideosArray(filteredVideos);
    setSort('');
  }, [filterCustomer, filterSalesperson, filterStatus]);

  return (
    <>
      <FiltersContainer>
        <Filter margin='0 12px 0 0'>
          <Dropdown
            width={280}
            options={salespersonOptions}
            value={salespersonOptions.find(o => {
              return o && o.value === filterSalesperson;
            })}
            onChange={(option: any) => setFilterSalesperson(option.value)}
            placeholder={'All sales representatives'}
          />
        </Filter>
        <Filter margin='0 12px 0 0'>
          <Dropdown
            width={280}
            options={customerOptions}
            value={customerOptions.find(o => {
              return o && o.value === filterCustomer;
            })}
            onChange={(option: any) => setFilterCustomer(option.value)}
            placeholder={'All customers'}
          />
        </Filter>
        <Filter>
          <Dropdown
            width={280}
            options={statusOptions}
            value={statusOptions.find(o => {
              return o && o.value === filterStatus;
            })}
            onChange={(option: any) => setFilterStatus(option.value)}
            placeholder={'All statuses'}
          />
        </Filter>
      </FiltersContainer>
      <TableVideosRecordedListing
        videos={videosArray || []}
        loading={false}
        disabled={isLoading}
        videoIdsToUpdate={videoIdsToUpdate}
        sort={sort}
        sortElement={sortElement}
        shareWithCompany={shareWithCompany}
      />
    </>
  );
};
