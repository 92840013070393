export const conversationsKeys = {
  all: () => ['conversations'] as const,
  lists: () => [...conversationsKeys.all(), 'list'] as const, // list of all items
  list: (filters: string, type: string) =>
    [...conversationsKeys.lists(), type, { filters }] as const, // list of items with specific filters,
  details: () => [...conversationsKeys.all(), 'detail'] as const, // all single items we visit
  detail: (conversationId: string) =>
    [...conversationsKeys.details(), conversationId] as const, // single item
  count: () => ['CONVERSATION_COUNT'],
  header_action: () => ['conversation_header_actions'],
};
