import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { MeetingRoutes } from './Routes';

export const Meetings = () => (
  <>
    <DocumentHead title='Meetings' />
    <PageTemplate main={<MeetingRoutes />} />
  </>
);
