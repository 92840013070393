import { LoadingIndicator } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import {
  Container,
  MainContainer,
  MainWrapper,
  NoList,
} from '../../index.styled';
import { SearchAndFilter } from '../../resellers/components/SearchAndFilter';
import { MultiLocationTable } from '../components/MultiLocationTable';
import { useMultilocationsQuery } from 'lib/api/multiLocations/multilocationsQuery';
import { DeleteConfigurationModal } from '../modals/DeleteConfigurationModal';
import { MULTILOCATIONS_SORT_KEYS } from '../const';
import { Button } from 'react-covideo-common';
export interface SelectedCustomer {
  customerId: number;
  business: string;
}

const MultiLocationList = () => {
  const history = useHistory();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [multiLocationSearch, setMultiLocationsSearch] = useState('');
  const [selectedCustomer, setSelectedCustomer] =
    useState<SelectedCustomer | null>(null);
  const [multiLocationsSortKey, setMultiLocationsSortKey] = React.useState(
    MULTILOCATIONS_SORT_KEYS.CUSTOMER_NAME
  );
  const [multiLocationsSortOrder, setMultiLocationsSortOrder] = React.useState(
    PaginationConstants.ASCENDING
  );
  const sort = {
    sortKey: multiLocationsSortKey,
    order: multiLocationsSortOrder || PaginationConstants.ASCENDING,
  };

  const { data, isLoading, refetch } = useMultilocationsQuery({
    search: multiLocationSearch,
    page,
    size,
    sort,
  });

  const onPaginationChange = async ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    setPage(page);
    setSize(size);
  };

  useEffect(() => {
    refetch();
  }, [page, size, multiLocationsSortKey, multiLocationsSortOrder]);

  const closeModalHandler = () => {
    setSelectedCustomer(null);
  };

  return (
    <MainContainer>
      {!!selectedCustomer && (
        <DeleteConfigurationModal
          selectedCustomer={selectedCustomer}
          handleModalClose={closeModalHandler}
        />
      )}
      <MainWrapper>
        <Container overflowX='visible'>
          <Gap
            alignItems='flex-start'
            flexDirection='column'
            gap='32px'
            width='100%'
          >
            <Gap
              alignItems='center'
              justifyContent='space-between'
              width='100%'
            >
              <Heading color={theme.palette.gray100}>
                Multi-location Manager
              </Heading>
              <Button
                onClick={() => history.push('/admin/multilocations/create')}
                text='New Configuration'
                icon={<MdAdd color={theme.palette.white} />}
              />
            </Gap>
            <SearchAndFilter
              onSearch={setMultiLocationsSearch}
              prevSearch={multiLocationSearch}
              placeholder='Search by business name, id, admin name or email…'
              width={536}
            />
          </Gap>
          {isLoading && (
            <div style={{ width: '100%', height: '300px' }}>
              <LoadingIndicator isLoading={true} height='300px' />
            </div>
          )}
          {!isLoading && !!data?.customers?.length && (
            <MultiLocationTable
              count={data?.count}
              data={data?.customers}
              page={page}
              size={size}
              onSortingUpdate={(connectionsSortKey, order) => {
                setMultiLocationsSortKey(
                  connectionsSortKey as MULTILOCATIONS_SORT_KEYS
                );
                setMultiLocationsSortOrder(order);
                setPage(DEFAULT_PAGE);
              }}
              order={sort.order}
              sortKey={sort.sortKey}
              onPaginationChange={onPaginationChange}
              setSelectedCustomer={setSelectedCustomer}
            />
          )}
          {!isLoading && !data?.customers.length && (
            <NoList>No data to show.</NoList>
          )}
        </Container>
      </MainWrapper>
    </MainContainer>
  );
};

export default MultiLocationList;
