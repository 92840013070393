import { formatSecondsToTime } from 'lib/utils/functions';
import React from 'react';
import styled from 'styled-components/macro';

const CustomTooltip = styled.div`
  padding: 8px 12px;
  border-radius: 4px;
  box-shadow: 0 8px 32px 0 #1d1e2414;
  border: solid 1px #f7f8fa;
  background-color: #fff;
`;

const CustomTooltipHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
`;

const CustomTooltipTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  margin-bottom: 4px;
`;

const TIME_WATCHED_LABEL = 'Time Watched';
export const TooltipChart = ({ active, payload }: any) => {
  if (active && payload && payload.length && payload[0].payload) {
    const data: any = payload[0].payload;
    const value =
      data.label === TIME_WATCHED_LABEL
        ? formatSecondsToTime(data.cnt)
        : data.cnt;
    return (
      <CustomTooltip>
        <CustomTooltipHeader>{data.date}</CustomTooltipHeader>
        <CustomTooltipTitle>{`${value} ${data.label}`}</CustomTooltipTitle>
      </CustomTooltip>
    );
  }

  return null;
};
