import { useQuery } from 'react-query';
import React from 'react';
import { EXPRESS_API } from 'configs/api/expressApi';

export const getSenders = async () => {
  const response = await EXPRESS_API.get(`/shared-reports/senders`);

  return response.data;
};

export const deleteSharedReport = async (id: number) => {
  const response = await EXPRESS_API.delete(`/shared-reports/${id}`);

  return response.data;
};

export const useSharedReportSenderQuery = ({
  enabled,
}: {
  enabled: boolean;
}) => {
  const { data, refetch, isLoading } = useQuery(
    ['shared-reports-senders'],
    async () => {
      return await getSenders().catch(ex => {
        throw ex;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
    }
  );

  const emptyArrayForSenders = React.useMemo(() => [], []);
  return {
    senders: data || emptyArrayForSenders,
    refetch,
    isLoading,
  };
};
