export enum EHelpDesk {
  VIDEOS = 'videos',
  RECORDED = 'recorded',
  ANALYTICS = 'analytics',
  WEBSITE_OVERLAY = 'website_overlay',
  CALLS_TO_ACTIONS = 'calls_to_actions',
  LANDING_PAGES = 'landing_pages',
  LANDING_PAGES_BUILDER = 'landing_pages_builder',
  MANAGE_USERS = 'manage_users',
  MY_STATS = 'my_stats',
  MY_TEAM = 'my_team',
  CAPTIONS = 'captions',
  TRIMMING = 'trimming',
  ANNOTATIONS = 'annotations',
  SECURITY = 'security',
  FILES = 'files',
  BOARDS = 'boards',
  CONTACTS = 'contacts',
  MERGE = 'merge',
  WHEELS = 'wheels',
  REACTIONS = 'reactions',
}

export type HelpDeskTypes =
  | EHelpDesk.VIDEOS
  | EHelpDesk.RECORDED
  | EHelpDesk.ANALYTICS
  | EHelpDesk.WEBSITE_OVERLAY
  | EHelpDesk.CALLS_TO_ACTIONS
  | EHelpDesk.LANDING_PAGES
  | EHelpDesk.LANDING_PAGES_BUILDER
  | EHelpDesk.MANAGE_USERS
  | EHelpDesk.MY_STATS
  | EHelpDesk.MY_TEAM
  | EHelpDesk.CAPTIONS
  | EHelpDesk.TRIMMING
  | EHelpDesk.ANNOTATIONS
  | EHelpDesk.SECURITY
  | EHelpDesk.FILES
  | EHelpDesk.BOARDS
  | EHelpDesk.CONTACTS
  | EHelpDesk.MERGE
  | EHelpDesk.WHEELS
  | EHelpDesk.REACTIONS;

export enum ItemsTypes {
  LINK = 'link',
  MODAL = 'modal',
}

export interface IHelpDeskItem {
  icon: JSX.Element;
  title: string;
  url: string;
  id: string;
  type: ItemsTypes;
  automotiveUrl?: string;
}

export interface IHelpDeskItems {
  [key: string]: IHelpDeskItem[];
}
