import axios from 'axios';
import { checkDropAuth, client } from './droplrAuth';
import { useQuery } from 'react-query';
import { droplrKeys } from './queryKeys';
import { DropActivity } from './types';

const getActivities = async (dropId: string) => {
  await checkDropAuth();
  const result = (
    await axios.get(
      `https://notifications.droplr.com/activities?dropId=${dropId}`,
      {
        headers: {
          authorization: `Bearer ${client.options.auth.token}`,
        },
      }
    )
  ).data;

  return {
    count: result.count,
    data: result.data as DropActivity[],
    hasMore: result.hasMore,
    totalCount: result.totalCount,
  };
};

export const useGetDropActivities = (dropId: string) => {
  return useQuery(droplrKeys.activity_list(dropId), () =>
    getActivities(dropId)
  );
};
