import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { videoKeys } from './videosKeys';

type Props = {
  videoId: number;
  enabled?: boolean;
};

export const getVideo = async (videoId: string | number) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}`);
  return response.data;
};

export const useSingleVideoQuery = ({ videoId, enabled = true }: Props) => {
  return useQuery(videoKeys.single(videoId), () => getVideo(videoId), {
    refetchOnMount: true,
    enabled,
  });
};
