import { theme } from 'lib/style';
import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components/macro';

type BreadCrumbLink = {
  title: string;
  url: string;
  value?: string;
};

type BreadCrumbProps = {
  links: Array<BreadCrumbLink>;
  queryParams?: string;
};

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const BreadCrumb = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.palette.covideoBlue80};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  div {
    text-decoration: underline;
  }
`;

const activeStyle = {
  color: theme.palette.covideoGray80,
  textDecoration: 'none',
};

export const BreadCrumbs = ({ links, queryParams }: BreadCrumbProps) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <Container>
      {links.map((item, index) => (
        <BreadCrumb key={'breadcrumb-' + index}>
          <div
            style={{ ...(item.url === location.pathname && activeStyle) }}
            onClick={() =>
              history.push(
                queryParams ? item.url + '?' + queryParams : item.url
              )
            }
          >
            {item.title}
          </div>
          {index !== links.length - 1 && (
            <FaChevronRight
              size={13}
              color={theme.palette.covideoGray40}
              style={{ margin: '0 5px' }}
            />
          )}
        </BreadCrumb>
      ))}
    </Container>
  );
};
