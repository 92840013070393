import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { ConversationsLayout } from './main';

export const Conversations = () => (
  <>
    <DocumentHead title='Conversations' />
    <PageTemplate main={<ConversationsLayout />} />
  </>
);
