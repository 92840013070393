import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { MANAGED_USERS_KEY } from './queryKeys';

export type UpdateMultipleUserTypeParams = {
  userIds: number[];
  accessRole: number;
};

export const updateMultipleUsersAccessRole = async ({
  userIds,
  accessRole,
}: UpdateMultipleUserTypeParams) => {
  const data = {
    userIds,
    accessRole,
  };
  const response = await EXPRESS_API.patch(`/users/access-role`, data);
  return response.data;
};

export const useUpdateUsersAccessRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMultipleUsersAccessRole, {
    onSuccess: (_, variables: UpdateMultipleUserTypeParams) => {
      queryClient.invalidateQueries([MANAGED_USERS_KEY]);
      successToast({
        title: `User type updated successfully for ${
          variables.userIds.length
        } user${variables.userIds.length > 1 ? 's' : ''}!`,
      });
    },
    onError: () => {
      queryClient.invalidateQueries([MANAGED_USERS_KEY]);
      errorToast({
        title: 'Error in updating users type',
      });
    },
  });
};
