import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 130px;
  padding: 8px 12px;
  color: ${theme.palette.gray100};
`;

export const LaunchPopup = () => {
  return <Wrapper>Launch AI Assist</Wrapper>;
};
