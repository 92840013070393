import * as React from 'react';
import styled from 'styled-components/macro';
import { LoadingIndicator, Search } from 'lib/components';

import { EmailBatchesList, NoBatches } from '../components';
import { useEffect, useState } from 'react';
import { DateRangeSelector } from '../../../../reports/components';
import { MdFileDownload } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { exportCSVFile } from '../../../../../../lib/utils/functions';
import dayjs from 'dayjs';
import { successToast } from '../../../../../../lib/components/toasts/success';
import {
  MainWrapper,
  Container,
  HeaderWrapper,
} from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { STANDARD_DATE_FORMAT } from '../../../../../../lib/const/DateFormat';
import { useGetEmailBatches } from 'lib/api/emailBatches/getEmailBatches';
import { getLastDaysDate } from 'app/pages/reports/components/DateRangeSelector/DateRangeSelector';

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  gap: 25px;
  margin-top: 3%;
  margin-bottom: 3%;
`;

const FilterByDate = styled.span`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 14%;
`;

const exportHeader = {
  subject: 'Subject',
  recipients: 'Recepients',
  dealerId: 'Dealer ID',
  messageId: 'Message ID',
  customer: 'Customer',
  sentAt: 'Date Sent',
};

export const Main = () => {
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState<Date>(getLastDaysDate(6));
  const [endDate, setEndDate] = useState<Date>();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState('DESC');

  const {
    data: batches,
    refetch: loadEmailBatches,
    isLoading: loading,
  } = useGetEmailBatches({
    size,
    page,
    sort,
    search,
    startDate,
    endDate,
  });

  const onSearch = (query: string) => {
    setSearch(query.toLowerCase());
  };

  const onDateRangeChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const exportEmailBatches = async () => {
    let temp: any = [];
    batches?.emailBatches?.forEach((batch: any) => {
      temp.push({
        subject: batch.subject,
        recipients: batch.recipients.length,
        dealerId: batch.dealerId,
        messageId: batch.messageId,
        customer: batch.customer.business,
        sentAt: dayjs(batch.sentAt).format(`${STANDARD_DATE_FORMAT} HH:mm A`),
      });
    });
    exportCSVFile(exportHeader, temp, 'email_batches');
    successToast({ title: 'File downloaded successfully!' });
  };

  const onPaginationChange = ({ page, size }: any) => {
    setPage(page);
    setSize(size);
  };

  useEffect(() => {
    if (startDate && endDate) {
      loadEmailBatches();
    }
  }, [search, page, size, sort, startDate, endDate]);

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <LoadingIndicator isLoading={loading} />
        {!loading && (
          <>
            <HeaderWrapper>
              <Heading>Email Batches</Heading>
              <Button
                onClick={() => exportEmailBatches()}
                text='Export as .csv'
                icon={<MdFileDownload />}
                variant='ghost'
              />
            </HeaderWrapper>

            <SearchAndFilterContainer>
              <Search
                prevSearch={search}
                placeholder='Search by customer or dealer id...'
                onSearch={onSearch}
                width={'380px'}
              />
              <FilterByDate>Filter by date</FilterByDate>
              <DateRangeSelector onDateRangeChange={onDateRangeChange} />
            </SearchAndFilterContainer>

            {!batches?.emailBatches?.length && <NoBatches />}
            {!!batches?.emailBatches?.length && (
              <EmailBatchesList
                emailBatches={batches?.emailBatches}
                count={batches?.count}
                onPaginationChange={onPaginationChange}
                sort={sort}
                setSort={setSort}
                loadEmailBatches={loadEmailBatches}
              />
            )}
          </>
        )}
      </MainWrapper>
    </Container>
  );
};
