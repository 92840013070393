import { checkDropAuth, client } from './droplrAuth';

export const uploadDrop = async (
  title: string,
  type: string,
  content: any,
  progressUpdate?: (total: number, uploaded: number, percent: number) => void
) => {
  await checkDropAuth();

  return client.drops.create(
    {
      type: 'FILE',
      variant: type,
      title,
      content,
    },
    {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        const progress: number = progressEvent.loaded / progressEvent.total;
        const percentage: number = Number(progress.toFixed(2)) * 100;

        if (progressUpdate !== undefined)
          progressUpdate(progressEvent.total, progressEvent.loaded, percentage);
      },
    }
  );
};
