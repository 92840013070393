import { VerificationStatus } from 'lib/const/VerificationStatus';

import { useQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IResponse {
  customers: Customer[];
  count: string;
}

export interface Customer {
  customerId: number;
  business: string;
  email: string;
  firstName: string;
  lastName: string;
  verified: number;
  automotiveAccess: number;
  resellerId: number;
  resellerName: string;
  userCount: string;
}

export type CustomerListParams = {
  start?: number;
  limit?: number;
  search?: string;
  sortKey?: string;
  order?: string;
  status:
    | VerificationStatus.ACTIVE
    | VerificationStatus.INACTIVE
    | VerificationStatus.ALL;
  resellerId?: number;
};

const getAllCustomersForSuperAdmin = async (params: CustomerListParams) => {
  return (
    await EXPRESS_API.get(`superadmin/customers`, {
      params,
    })
  ).data as IResponse;
};

export const useCustomersQuery = ({
  params,
  enabled,
}: {
  params: CustomerListParams;
  enabled: boolean;
}) => {
  return useQuery(
    superAdminKeys.customers(`${params?.resellerId}` || ''),
    () => getAllCustomersForSuperAdmin(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
    }
  );
};
