import React, { useMemo } from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { PreLoader } from './PreLoader';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';

const Wrapper = () => {
  return useMemo(() => <PreLoader />, []);
};

export const SmsOverview = () => {
  const { userData } = useAuth();
  React.useEffect(() => {
    if (userData?.user?.packageDetails?.id) {
      const smsOverviewAllowed = checkIfFeatureIsEnabled(
        userData,
        productFeature.SMS_ADMIN_CONTROLS
      );
      if (!smsOverviewAllowed) {
        window.location.href = '/upgrade-plan';
      }
    }
  });
  return (
    <>
      <DocumentHead title='SMS' />
      <PageTemplate main={<Wrapper />} />
    </>
  );
};
