import { Region } from 'lib/context';
import { useMutation } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';

export const createRegion = async (name: string): Promise<Region> => {
  return (
    await EXPRESS_API.post(`region`, {
      name,
    })
  ).data as Region;
};

export const useCreateRegion = (name: string) => {
  return useMutation(() => createRegion(name));
};
