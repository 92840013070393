import React from 'react';
import styled from 'styled-components/macro';
import { Modal } from 'lib/components';
import { theme } from 'lib/style';
import { logout } from 'lib/api';
import { Button } from 'react-covideo-common';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const Content = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: start;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: 12px;
  }
`;

type Props = {
  handleModalClose: () => void;
  style?: React.CSSProperties;
};

export const LoginAsModal = (props: Props) => {
  const [windowHandler, setWindowHandler] = React.useState<Window | null>(null);

  const { handleModalClose, style } = props;

  React.useEffect(() => {
    const newPage = window.open('/', '_crosslogin');
    setWindowHandler(newPage);
    const interval = setInterval(() => {
      if (newPage && newPage.closed) {
        clearInterval(interval);
        handleModalClose();
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Modal style={{ position: 'fixed', ...style }}>
      <div style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            You are currently signed into multiple accounts.
          </HeaderTitle>
        </Header>
        <Content>
          You'll need to sign out of other accounts before using your primary
          account.
        </Content>
        <Footer>
          <Button
            text='Sign out of other account(s).'
            onClick={async () => {
              await logout(true);
              if (windowHandler) {
                windowHandler.close();
              }
              handleModalClose();
            }}
          />
        </Footer>
      </div>
    </Modal>
  );
};
