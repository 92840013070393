import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { TablePaginationNumbers, useTableContext } from 'lib/components';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { MdOutlineSkipPrevious, MdOutlineSkipNext } from 'react-icons/md';

const InputWrapper = styled.div`
  display: flex;
  padding: 8px;
`;

export const PageButton = styled.button<{
  disabled?: boolean;
  isFullWidth?: boolean;
}>`
  ${theme.fontBold700}
  box-sizing: border-box;
  width: ${props => (props.isFullWidth ? '100%' : '40px')};
  height: 40px;
  font-size: 24px;
  align-items: center;
  background-color: ${props =>
    props.disabled ? '#fff' : 'rgba(0, 123, 255, .05)'};
  border: 0;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: inline-flex;
  justify-content: center;
  opacity: ${props => props.disabled && '.2'};
  overflow: hidden;
  padding: 8px;
  transition: 0.1s all;
  color: ${theme.palette.blue100};
  margin: 0 4px;

  &:hover:not(:active) {
    background-color: ${props =>
      props.disabled ? '#fff' : 'rgba(0, 123, 255, .1)'};
  }

  &:focus {
    outline: 0;
  }
`;

export const TablePagination = () => {
  const { page, lastPage, size, setPage } = useTableContext();
  useEffect(() => {}, [size]);

  const handleFirst = () => {
    setPage(0);
  };

  const handleLast = () => {
    setPage(lastPage - 1);
  };

  const handleIncreasePage = () => {
    setPage(page + 1);
  };

  const handleDecreasePage = () => {
    setPage(page > 0 ? page - 1 : page);
  };

  return (
    <>
      <InputWrapper>
        <PageButton
          onClick={handleFirst}
          disabled={page <= 0}
          aria-label='First Page'
        >
          <MdOutlineSkipPrevious
            color={theme.palette.covideoBlue100}
            width={'16px'}
            height={'16px'}
          />
        </PageButton>
        <PageButton
          onClick={handleDecreasePage}
          disabled={page <= 0}
          aria-label='Previous Page'
        >
          <IoMdArrowDropleft />
        </PageButton>
      </InputWrapper>
      <TablePaginationNumbers />
      <InputWrapper>
        <PageButton
          aria-label='Next Page'
          onClick={handleIncreasePage}
          disabled={page + 1 >= lastPage}
        >
          <IoMdArrowDropright />
        </PageButton>
        <PageButton
          onClick={handleLast}
          disabled={page + 1 >= lastPage}
          aria-label='Last Page'
        >
          <MdOutlineSkipNext
            width={'16px'}
            height={'16px'}
            color={theme.palette.covideoBlue100}
          />
        </PageButton>
      </InputWrapper>
    </>
  );
};
