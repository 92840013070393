import { CheckboxInput } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import {
  CheckboxLabel,
  CheckboxWrapper,
  FilterType,
  HorizontalLine,
} from './style';

interface FilterProps {
  filterOptions: string[]; // Pass the filter options as an array of strings
  setSelectedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFilters: string[];
  filterTypeText: string;
}

export const FilterOption = ({
  filterOptions,
  setSelectedFilters,
  selectedFilters,
  filterTypeText,
}: FilterProps) => {
  const handleFilterChange = (filter: string) => {
    setSelectedFilters(prevFilters => {
      return prevFilters.includes(filter)
        ? prevFilters.filter(selectedFilter => selectedFilter !== filter)
        : [...prevFilters, filter];
    });
  };
  return (
    <div>
      <Gap
        flexDirection='row'
        gap='12px'
        justifyContent='start'
        alignItems='center'
        m='0 0 12px 0'
      >
        <FilterType>{filterTypeText}</FilterType>
        <HorizontalLine />
      </Gap>
      {filterOptions.map(filter => (
        <CheckboxWrapper key={filter}>
          <CheckboxInput
            name={filter}
            id={filter}
            checked={selectedFilters.includes(filter)}
            onChange={() => handleFilterChange(filter)}
          />
          <CheckboxLabel
            title={filter.length > 16 ? filter : undefined}
            onClick={() => handleFilterChange(filter)}
          >
            {filter}
          </CheckboxLabel>
        </CheckboxWrapper>
      ))}
    </div>
  );
};
