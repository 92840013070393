import { useAuth } from 'lib/context';
import brandingLogoDark from 'assets/icons/logo/brandingLogoDark.svg';
// Define the type for the logo options
type LogoOption = 'dark' | 'dark-with-default';

export const useLogoUrl = (logoType?: LogoOption): { logoUrl: string } => {
  const { whitelabel } = useAuth();

  // Constants for logo URLs
  const DEFAULT_PATH = `https://${whitelabel?.domainV2}${whitelabel?.contentPath}`;

  const DEFAULT_LOGO_URL = `${DEFAULT_PATH}/images/logo-secondary.svg`;
  if (logoType === 'dark') {
    return { logoUrl: brandingLogoDark };
  }
  // Force dark logo URL instead of default when applicable
  if (logoType === 'dark-with-default') {
    return {
      logoUrl:
        whitelabel?.name === 'Covideo' ? brandingLogoDark : DEFAULT_LOGO_URL,
    };
  }

  // all whitelabels logos
  return { logoUrl: DEFAULT_LOGO_URL };
};
