import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
// import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path="/users/list" component={List} exact={true} />
      {/* <Route path="/users/:userId" component={Details} /> */}
    </Switch>
  );
};
