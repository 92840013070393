import { FileTypes } from 'app/pages/account/userAccount/recentlyDeleted/types';
import { EXPRESS_API } from 'configs/api/expressApi';

interface RecentlyDeletedResponse {
  recentlyDeletedItems: RecentlyDeletedItems[];
  count: number;
}

export interface RecentlyDeletedItems {
  recentlyDeletedItemId: number;
  userId: number;
  ItemId: number;
  title: string;
  type: FileTypes;
  data: Data;
  createdAt: string;
}
export interface Data {}
export type GetRecentlyDeletedParams = {
  start: number;
  limit: number;
  search?: string;
  type?: FileTypes | '';
};

export const getRecentlyDeleted = async (
  params: GetRecentlyDeletedParams
): Promise<RecentlyDeletedResponse> => {
  const response = await EXPRESS_API.get('/recently-deleted-item', { params });
  return response.data;
};

export const deleteRecentlyDeleted = async (data: number[]): Promise<any> => {
  const response = await EXPRESS_API.delete('/recently-deleted-item', {
    data,
  });
  return response.data;
};

export const restoreRecentlyDeleted = async (data: number[]): Promise<any> => {
  const response = await EXPRESS_API.post(
    '/recently-deleted-item/restore',
    data
  );
  return response.data;
};
