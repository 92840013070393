import { GroupParams, OptOutParams, QueryContactsParams } from './types';

export const contactsKeys = {
  all: () => ['contacts_list'] as const, // list of all items
  list: (params?: QueryContactsParams) => ['contacts_list', params] as const, // list of items with specific filters
  groups: (params: GroupParams) => ['groups', params] as const, // groups
  opt_out_list: (filters: OptOutParams) => ['opt_out', filters] as const,
  single_contact: (contactId: string | number) => ['SINGLE_CONTACT', contactId],
  contact_groups: (contactId: string) => ['CONTACT_GROUPS', contactId],
};
