import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { CustomReportResponse } from './useCreateCustomReportMutation';
import { customReportKeys } from './customReportKeys';
import { CustomReportsParams } from './useCustomReportsQuery';

const duplicateCustomReport = async (
  id: string
): Promise<CustomReportResponse> => {
  const response = await EXPRESS_API.post(`/custom-reports/${id}/duplicate`);
  return response.data;
};

export const useDuplicateCustomReportMutation = (
  params: CustomReportsParams
) => {
  const queryClient = useQueryClient();
  return useMutation(duplicateCustomReport, {
    onSuccess: () => {
      successToast({ title: 'Custom report duplicated successfully' });
      queryClient.invalidateQueries(customReportKeys.custom_reports(params));
    },
    onError: () => {
      errorToast({ title: 'Something went wrong duplicating the report' });
    },
  });
};
