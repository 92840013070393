import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CloseButton, Modal } from 'lib/components';
import { Spinner } from '../Spinner';
import { Button } from 'react-covideo-common';
import { MdSave } from 'react-icons/md';

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 100%;
  max-width: 592px;
  background-color: #fff;
`;

const Content = styled.div`
  width: 528px;
  height: 224px;
  padding: 32px;
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: block;
  float: right;
  margin-top: 24px;
  width: 88px;
  height: 40px;
  border-radius: 4px;
  color: white;
  button {
    border-radius: 4px;
    color: white;
  }
`;

const Input = styled.input`
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  outline: none !important;
  padding: 12px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  width: 502px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
`;

const Notice = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.palette.black_1_100};
  margin-top: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: ${theme.palette.label};
  margin-top: 32px;
`;

/**** SUS-906 changes ***/
const ErrorInfo = styled.div`
  ${theme.mediaQueryMaxWidth.md} {
    display: none;
  }
  span {
    color: rgb(232, 76, 61);
  }
`;

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
  title?: string;
  initTitle?: string;
  text?: any;
  handleModalClose: () => void;
  handleSubmit: (data: string) => void;
};

export const ModalTrimCreateNew = ({
  disabled = false,
  handleModalClose,
  handleSubmit,
  isLoading = false,
  initTitle = '',
}: Props) => {
  const [title, setTitle] = React.useState(initTitle + ' Trim');
  /* SUS-906 changes */
  const isTitleValid = !!title.trim() && title.length <= 100;
  return (
    <Modal style={{ position: 'fixed' }}>
      <ModalItem>
        <CloseButton onClick={handleModalClose} />
        <Content>
          <ContentBody>
            <Title>Save a trimmed version of your video</Title>
            <Notice>Your original video will not be replaced.</Notice>
            <Label>Name of the video</Label>
            <Input
              type='text'
              required={true}
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value)
              }
            />
            {/* SUS-906 changes */}
            {!isTitleValid && (
              <ErrorInfo>
                <span>
                  Video name is required with maximum 100 characters are
                  allowed.
                </span>
              </ErrorInfo>
            )}
          </ContentBody>
          <ButtonContainer>
            <Button
              disabled={disabled || !isTitleValid} /* SUS-906 changes */
              icon={isLoading ? <Spinner /> : <MdSave size={18} />}
              text={'Save'}
              onClick={() => handleSubmit(title)}
            />
          </ButtonContainer>
        </Content>
      </ModalItem>
    </Modal>
  );
};
