import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { multilocationKeys } from './multilocationsKeys';

export interface IFreeCustomers {
  customers: Customer[];
  count: number;
}

export interface Customer {
  customerId: number;
  resellerId: number;
  resellerName: null | string;
  business: string;
  hasMultiLocation: number;
  parentCustomerId: number;
}

interface IParams {
  search: string;
}

const getFreeCustomers = async ({
  search,
}: IParams): Promise<IFreeCustomers> => {
  return (
    await EXPRESS_API.get(`superadmin/multilocation/free-customers`, {
      params: {
        search,
        start: 0,
        limit: 50,
      },
    })
  ).data as IFreeCustomers;
};

export const useFreeCustomersQuery = ({ search }: IParams) => {
  return useQuery(
    multilocationKeys.customers_free(search),
    () => getFreeCustomers({ search }),
    {
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
};
