import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import Select from 'react-select';
import {
  AutomotiveRole,
  RESELLER_IDS,
  VideoEmailStatus,
  VideoType,
} from 'lib/const';
import { getUsersByAutomotiveRoles } from 'lib/api';
import { InventoryItem, useAuth, VideoRequest } from 'lib/context';
import { Option } from 'lib/components/dropdown/DropdownOptions';
import { checkIfVideoRequestFieldsRequired } from 'lib/utils/automotiveRolePermissionChecks';
import { VideoTypeSales } from 'lib/const/VideoType';
import { VehicleSearch } from 'app/pages/library/videos/videoList/components/SendAndShare';
import { isEmpty } from 'lodash';

const Wrapper = styled.div`
  margin-top: 24px;
`;
const VideoAttribute = styled.div`
  margin: 0 0 24px;
  display: flex;
  flex-direction: column;
`;
const Label = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;
const TextInput = styled.input`
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-right: 8px;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const Textarea = styled.textarea`
  padding-left: 8px;
  width: 242px;
  min-width: 242px;
  max-width: 242px;
  height: 100px;
  min-height: 100px;
  max-height: 300px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  &:focus {
    outline: 0;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 368px;
    min-width: 368px;
    max-width: 368px;
  }
`;

const RequiredIcon = styled.span`
  color: ${({ theme }) => theme.colors.primary[100]};
  :after {
    content: '*';
  }
`;

const selectStyles = {
  control: (base: any) => ({ ...base, height: '40px' }),
  indicatorSeparator: () => ({ display: 'none' }),
};
type Props = {
  onChangeVideoRequestData?: (data: any) => void;
  setFormIsTouched?: (touched: boolean) => void;
  videoRequest?: VideoRequest;
  emailStatus?: string;
  fetchSoldVehiclesForAutomotive?: boolean;
};
export const AutomotiveAttributesFields = ({
  onChangeVideoRequestData = () => {},
  setFormIsTouched = () => {},
  videoRequest,
  emailStatus,
  fetchSoldVehiclesForAutomotive = true,
}: Props) => {
  const { userData } = useAuth();
  const { customer, userId } = userData;

  const isAutomotiveSales = userData.isAutomotiveSalesRole;
  const isAutomotiveService = userData.isAutomotiveServiceRole;
  const isTechnician = userData.automotiveRole === AutomotiveRole.TECHNICIAN;
  const videoRequestFieldsRequired =
    checkIfVideoRequestFieldsRequired(userData);

  const [vehicle, setVehicle] = useState<InventoryItem>();

  const [advisorOptions, setAdvisorOptions] = useState<Option[]>([]);
  const isFieldDisabled = emailStatus === VideoEmailStatus.Deleted;
  const isPenskeUser =
    userData.resellerId?.toString() === RESELLER_IDS.PENSKE_AUTOMOTIVE;
  const videoTypeOptions = Object.values(
    isAutomotiveSales || isPenskeUser ? VideoTypeSales : VideoType
  )
    .filter((videoType: string) => !!videoType)
    .map((videoType: string) => {
      return {
        value: videoType,
        label: videoType,
      };
    });

  const getAssignees = async () => {
    const advisors = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_ADVISOR,
    });

    const managers = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_MANAGER,
    });

    const users = [...advisors, ...managers];

    const options = users.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    setAdvisorOptions(options);
  };

  useEffect(() => {
    if (isAutomotiveService) getAssignees();
  }, [isAutomotiveService]);

  useEffect(() => {
    //assign to self if service and not technician and new video (no vr)
    if (isAutomotiveService && !isTechnician && isEmpty(videoRequest))
      onChangeVideoRequestData({ advisorId: userId });
  }, [videoRequest]);

  return (
    <Wrapper>
      <VideoAttribute>
        <Label>Video Type</Label>
        <Select
          styles={selectStyles}
          options={videoTypeOptions}
          value={videoTypeOptions.find(o => {
            return o.value === videoRequest?.videoType;
          })}
          onChange={(option: any) => {
            onChangeVideoRequestData({
              ...videoRequest,
              videoType: option.value,
            });
            setFormIsTouched(true);
          }}
          isSearchable={true}
          placeholder={'Select...'}
          isDisabled={isFieldDisabled}
        />
      </VideoAttribute>
      {isAutomotiveSales && (
        <>
          <VideoAttribute>
            <Label>Search vehicle</Label>
            <VehicleSearch
              onChangeVehicle={(item: InventoryItem) => {
                onChangeVideoRequestData({
                  ...videoRequest,
                  vin: item?.vin || '',
                });
                setVehicle(item);
                setFormIsTouched(true);
              }}
              selectedVin={videoRequest?.vin || ''}
              placeholder='Search vehicle by name, VIN or stock number...'
              fetchSold={fetchSoldVehiclesForAutomotive}
            />
          </VideoAttribute>
          <VideoAttribute>
            <Label>VIN</Label>
            <TextInput value={videoRequest?.vin || ''} disabled={true} />
          </VideoAttribute>
          <VideoAttribute>
            <Label>Stock Number</Label>
            <TextInput value={vehicle?.stockNumber || ''} disabled={true} />
          </VideoAttribute>
        </>
      )}
      {isAutomotiveService && (
        <VideoAttribute>
          <Label>
            Assign To {videoRequestFieldsRequired && <RequiredIcon />}
          </Label>
          <Select
            styles={selectStyles}
            options={advisorOptions}
            value={advisorOptions.find(o => {
              return o.value === videoRequest?.advisorId + '';
            })}
            onChange={(option: any) => {
              onChangeVideoRequestData({
                ...videoRequest,
                advisorId: option.value,
              });
              setFormIsTouched(true);
            }}
            isSearchable={true}
            placeholder={'Select...'}
            isDisabled={isFieldDisabled}
          />
        </VideoAttribute>
      )}
      {isAutomotiveService && (
        <VideoAttribute>
          <Label>
            RO Number {videoRequestFieldsRequired && <RequiredIcon />}
          </Label>
          <TextInput
            value={videoRequest?.repairOrderNumber || ''}
            onChange={(e: any) => {
              onChangeVideoRequestData({
                ...videoRequest,
                repairOrderNumber: e.target.value,
              });
              setFormIsTouched(true);
            }}
            disabled={isFieldDisabled}
          />
        </VideoAttribute>
      )}
      <VideoAttribute>
        <Label>Customer Name</Label>
        <TextInput
          value={videoRequest?.customerName || ''}
          onChange={(e: any) => {
            onChangeVideoRequestData({
              ...videoRequest,
              customerName: e.target.value,
            });
            setFormIsTouched(true);
          }}
          disabled={isFieldDisabled}
        />
      </VideoAttribute>
      {isAutomotiveService && (
        <VideoAttribute>
          <Label>Note</Label>
          <Textarea
            value={videoRequest?.note || ''}
            onChange={(e: any) => {
              onChangeVideoRequestData({
                ...videoRequest,
                note: e.target.value,
              });
              setFormIsTouched(true);
            }}
            disabled={isFieldDisabled}
          />
        </VideoAttribute>
      )}
    </Wrapper>
  );
};
