import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const InsightsWrapper = styled.div<{ loading: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
  text-align: center;
  justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
`;

export const NoStats = styled.div`
  margin-top: 34px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
  font-weight: 500;
`;
