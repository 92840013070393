import { useFormikContext } from 'formik';
import {
  ParentCustomer,
  User,
} from 'lib/api/multiLocations/multiLocationQuery';
import { useFreeUsersQuery } from 'lib/api/multiLocations/useFreeUsersQuery';
import { Dropdown } from 'lib/components';
import { unionBy, debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ActionMeta } from 'react-select/src/types';
import { CustomUsersLabel, SingleValue } from './CustomUsersLabel';

export interface UserOption {
  label: string;
  value: string | number;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  parentUserId: string | number;
  verified: number;
}
interface IProps {
  user: User;
  onChange: (value: UserOption, actionMeta?: ActionMeta<UserOption>) => void;
  childCustomerId: number;
  parentUserId: string | number;
}

export const UserDropdown = ({
  user,
  onChange,
  childCustomerId,
  parentUserId,
}: IProps) => {
  const [availabelOptions, setAvailableOptions] = useState<User[]>([]);
  const [search, setSearchText] = useState('');
  const { values } = useFormikContext<ParentCustomer>();

  const newUserOption = {
    value: 'add',
    label: 'New user will be created',
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    userId: null,
    parentUserId,
    verified: 1,
  } as UserOption;

  const { data, isLoading } = useFreeUsersQuery({
    search,
    customerId: childCustomerId,
  });

  const debouncedInputChange = useCallback(
    debounce(inputValue => {
      setSearchText(inputValue);
    }, 300),
    [search]
  );

  const onInputChangeHandler = (newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  useEffect(() => {
    const combinedArray = unionBy(availabelOptions, data?.users || [], 'id');
    setAvailableOptions(combinedArray);
  }, [data]);

  const childUserIDs = values.childCustomers.flatMap(childCustomer =>
    childCustomer.users
      .filter(user => user.id !== 'add' && user.id !== 'remove')
      .map(user => user.id)
  );

  const options: UserOption[] =
    availabelOptions.map(user => ({
      label: user.email,
      value: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      verified: user.verified,
      parentUserId: parentUserId,
    })) || [];

  const initialValue =
    user.id === null
      ? null
      : options.find(option => option.value === user.id) || newUserOption;

  return (
    <Dropdown
      options={[newUserOption, ...options]
        .filter(user => !childUserIDs.includes(user.value))
        .filter(
          user =>
            user.username.includes(search) ||
            user.email.includes(search) ||
            user.value.toString().includes(search)
        )}
      value={initialValue}
      isClearable
      onChange={onChange}
      placeholder='Select a user to enable access'
      creatable={false}
      formatOptionLabel={CustomUsersLabel}
      customComponents={{
        SingleValue: SingleValue,
      }}
      extendStyles={{
        container: {
          width: '100%',
        },
        menu: {
          width: '450px',
        },
      }}
      menuPortalTarget={document.body}
      menuShouldBlockScroll={true}
      menuPosition='absolute'
      menuPlacement='bottom'
      filterOption={null}
      onInputChange={onInputChangeHandler}
      isLoading={isLoading}
    />
  );
};
