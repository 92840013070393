import * as React from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';

const BarWrap = styled.div`
  display: flex;
  border: 1px solid ${theme.palette.grayBorder};
  width: 100%;
  height: 8px;
  border-radius: 4px;
`;

const Bar = styled.div<Data>`
  height: 8px;
  width: ${props => props.percentage}%;
  background-color: ${props =>
    theme.hotspotPalette[props.type] || theme.hotspotPalette[4]};
  &:nth-of-type(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:nth-last-of-type(1) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

type Props = {
  data: string[];
};
type Data = {
  type: number;
  count: number;
  percentage: number;
};

export const HotspotBar = ({ data }: Props) => {
  let distibution: Data[] = [];
  let prevValue = data[0];
  let currentData: Data = {
    type: parseInt(prevValue),
    count: 0,
    percentage: 0,
  };
  for (let index = 0; index < data.length; index++) {
    const value = data[index];
    if (prevValue != value) {
      currentData.percentage = (currentData.count / data.length) * 100;
      distibution.push(currentData);
      currentData = { type: parseInt(value), count: 1, percentage: 0 };
      prevValue = value;
    } else {
      currentData.count++;
    }
  }
  currentData.percentage = (currentData.count / data.length) * 100;
  distibution.push(currentData);
  return (
    <BarWrap>
      {distibution.map((data, index) => {
        return (
          <Bar
            key={index}
            type={data.type}
            count={data.count}
            percentage={
              index === distibution.length - 1
                ? data.percentage + 0.1
                : data.percentage
            }
            title={data.type == 1 ? data.type + ' view' : data.type + ' views'}
          />
        );
      })}
    </BarWrap>
  );
};
