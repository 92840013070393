import React, { createRef } from 'react';
import styled from 'styled-components/macro';
import {
  TabWrapper,
  ModalHeader,
  HeaderSide,
  LinkWrapper,
  LinkBtn,
  HeaderCenter,
  ModalBody,
  ShareByEmbedding,
  getTabs,
} from '../';
import { useAuth, VideoRequest } from 'lib/context';
import { MdClose } from 'react-icons/md';
import { ButtonPillSwitch } from 'lib/components';
import { useGetQuickshareDetails } from 'lib/api/quickshare/useGetQuickshareDetails';
import { theme } from 'lib/style';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { useSendAndShare } from 'lib/context/send-and-share/provider';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  width: 592px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
`;

const Card = styled.div`
  padding: 20px 24px;
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
`;

const VideoWrapper = styled.div`
  .fullscreen {
    height: auto;
  }
`;

type Props = {
  setActiveTab: (tab: string) => void;
  handleModalClose: () => void;
  videoRequest?: VideoRequest;
};

export const EmbedTab = ({ setActiveTab, handleModalClose }: Props) => {
  const { video } = useSendAndShare();
  const { data: quickshareDetails, isFetching: videoShareLinkLoading } =
    useGetQuickshareDetails({ videoId: video.id });

  const { userData } = useAuth();
  const tabs = getTabs(userData);

  const videoShareLink = quickshareDetails?.data || { html: '', url: '' };

  const videoRef = createRef<HTMLVideoElement>();

  return (
    <TabWrapper display='block'>
      <ModalHeader>
        <HeaderSide>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter>
          <ButtonPillSwitch
            defaultValue={tabs.embed.value}
            values={tabs}
            onChange={newTab => setActiveTab(newTab)}
          />
        </HeaderCenter>
        <HeaderSide></HeaderSide>
      </ModalHeader>
      <ModalBody>
        <ContentWrapper>
          <Card>
            <ShareByEmbedding
              videoShareLink={videoShareLink}
              disableShare={videoShareLinkLoading}
            />
          </Card>
          <VideoWrapper>
            <VideoPlayer
              videoId={video.id}
              videoRef={videoRef}
              autoplay={false}
              videoSource={video.videoSource}
            />
          </VideoWrapper>
        </ContentWrapper>
      </ModalBody>
    </TabWrapper>
  );
};
