import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
// components
import SliderContent from './SliderContent';
import { Slide } from '.';
import Arrow from './Arrow';
import Dots from './Dots';
import { useLandingPageBuilderContext } from '../../context';
import { renderCovideoEmbed } from './Slide';

const SliderStyles = styled.div`
  position: relative;
  height: 395px;
  width: 540px;
  max-width: inherit;
  margin: 0 auto;
  overflow: hidden;
`;

const Slider = (props: any) => {
  const getWidth = () => window.innerWidth;

  const [state, setState] = useState({
    activeIndex: 0,
    translate: 0,
    transition: 0.45,
  });

  const id =
    'slideId' + new Date().getTime() + Math.random().toString(16).slice(2);

  const {
    selectedLayoutData,
    isFullScreen,
    pageBackgroundSize,
    pageBackgroundPosition,
    pageBackgroundImageUrl,
  } = useLandingPageBuilderContext();

  const { translate, transition, activeIndex } = state;

  const nextSlide = () => {
    if (activeIndex === props.slides.length - 1) {
      return setState({
        ...state,
        translate: 0,
        activeIndex: 0,
      });
    } else {
      setState({
        ...state,
        activeIndex: activeIndex + 1,
        translate: (activeIndex + 1) * getWidth(),
      });
    }
  };

  const prevSlide = () => {
    if (activeIndex === 0) {
      return setState({
        ...state,
        translate: (props.slides.length - 1) * getWidth(),
        activeIndex: props.slides.length - 1,
      });
    } else {
      setState({
        ...state,
        activeIndex: activeIndex - 1,
        translate: (activeIndex - 1) * getWidth(),
      });
    }
  };

  function adjustHeight() {
    const sliderElement = document.getElementById(id) as HTMLDivElement;
    if (!sliderElement) {
      return;
    }

    const activeSlide = sliderElement.querySelector(
      '.slideActive'
    ) as HTMLDivElement;
    const frame = activeSlide?.querySelector('iframe') as HTMLIFrameElement;
    if (!frame || !frame.style) {
      return;
    }

    let frameHeight = frame.style.height;
    if (
      frameHeight === undefined ||
      frameHeight === '' ||
      frameHeight === null
    ) {
      frameHeight = '350px';
    }

    let numArray = frameHeight.match(/\d/g);
    let num: any = numArray?.join('') || '';
    num = parseInt(num);
    if (isNaN(num)) {
      return;
    }

    num = num + 75;
    let newHeight = num.toString() + 'px';
    sliderElement.style.height = newHeight;
  }

  useEffect(() => {
    adjustHeight();
  }, [activeIndex]);

  useEffect(() => {
    const sliderElement = document.getElementById(id) as HTMLDivElement;
    if (!sliderElement) {
      return;
    }

    const activeSlide = sliderElement.querySelector(
      '.slideActive'
    ) as HTMLDivElement;

    if (!activeSlide) {
      return;
    }
    renderCovideoEmbed(activeSlide.id);
  }, [
    selectedLayoutData,
    isFullScreen,
    activeIndex,
    pageBackgroundSize,
    pageBackgroundPosition,
    pageBackgroundImageUrl,
  ]);

  useEffect(() => {
    window.addEventListener('message', function (e: MessageEvent) {
      let frame;
      if (e.origin.startsWith('https://www.covideo.com')) {
        const s = JSON.parse(e.data);
        let containers = document.querySelectorAll(
          '#LandingPageMainContainer [data-id="' + s.did + '"]'
        ) as NodeListOf<HTMLDivElement>;
        if (!containers || !containers.length) {
          return;
        }

        const count = containers.length;
        for (let i = 0; i < count; i++) {
          const container = containers[i] as HTMLDivElement;
          frame = container.querySelector('iframe') as HTMLIFrameElement;
          if (!frame) {
            return;
          }
          if (s.w < s.h && s.did) {
            container.style.setProperty('max-width', '79%');
            container.style.setProperty('width', '79%');
            container.style.setProperty('margin', '');
            container?.parentElement?.style.setProperty('display', 'flex');

            frame.style.setProperty('max-width', '245px');
          } else {
            frame.style.setProperty('height', s.h.toString() + 'px');
            adjustHeight();
          }
          frame?.contentWindow?.postMessage('received', frame.src);
        }
      }
    });
  }, []);

  useEffect(() => {
    setState({
      ...state,
      activeIndex: 0,
      translate: 0,
    });
  }, [selectedLayoutData, isFullScreen]);
  return (
    <SliderStyles id={id}>
      <SliderContent
        translate={translate}
        transition={transition}
        width={getWidth() * props.slides.length}
      >
        {props.slides.map((slide: any, i: number) => {
          if (slide && slide.trim() !== '') {
            return (
              <Slide
                className={activeIndex === i ? 'slideActive' : ''}
                key={'slide' + i}
                content={slide}
                index={i.toString()}
              />
            );
          }

          return null;
        })}
      </SliderContent>

      <Arrow direction='left' handleClick={prevSlide} />
      <Arrow direction='right' handleClick={nextSlide} />

      <Dots slides={props.slides} activeIndex={activeIndex} />
    </SliderStyles>
  );
};

export default Slider;
