import React from 'react';
import styled from 'styled-components/macro';
import { VariantData, VariantTypes } from '../types';

const HeaderContainer = styled.div`
  display: flex;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(242, 244, 246, 1);
  gap: 8px;
  :last-child {
    gap: 0px;
  }
`;

const Title = styled.p<{ flex?: string }>`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #9297a2;
  flex: ${({ flex }) => flex};
`;

interface HeaderProps {
  selectedLabel: string;
  variantData: VariantData;
  variant: string;
  hasAdditionalHeader?: boolean;
}

const Header = ({
  selectedLabel,
  variantData,
  variant,
  hasAdditionalHeader = true,
}: HeaderProps) => {
  const hasTabs =
    variant === VariantTypes.VIDEOS || variant === VariantTypes.USERS;

  const additionalHeader =
    hasTabs && hasAdditionalHeader ? [selectedLabel] : [];

  return (
    <HeaderContainer>
      {[...variantData[variant].tableHeaders, ...additionalHeader].map(
        (header, idx) => {
          const lastItem =
            variantData[variant].tableHeaders.length - (hasTabs ? 0 : 1);
          return (
            <Title
              key={idx}
              style={{
                textAlign: idx === lastItem ? 'right' : 'left',
              }}
              flex={variantData[variant].flexHeaders[idx]}
            >
              {header}
            </Title>
          );
        }
      )}
    </HeaderContainer>
  );
};

export default Header;
