import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Dropdown, Modal } from 'lib/components';
import React, { useEffect, useState } from 'react';
import { VehicleSearch } from '../videoList/components/SendAndShare';
import { createVDPIntegrationOptions } from 'lib/utils/vdp';
import { useAuth } from 'lib/context';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 780px;
  padding: 32px;
  min-height: 208px;
  background-color: ${theme.palette.white};
`;

const SelectContainer = styled.div`
  width: 300px;
`;

const Header = styled.div`
  display: flex;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin-top: 32px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin-bottom: 8px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 48px;
  gap: 8px;
`;

type Props = {
  defaultValue: string;
  handleSubmit: (arg1: string, arg2: string) => void;
  handleModalClose: () => void;
};

export const ModalVDP = ({
  defaultValue,
  handleModalClose,
  handleSubmit,
}: Props) => {
  const { userData } = useAuth();
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [integrationType, setIntegrationType] = useState('');

  useEffect(() => setInputValue(defaultValue), [defaultValue]);
  useEffect(() => {
    if (!!integrationTypeOptions.length)
      setIntegrationType(integrationTypeOptions[0].value);
  }, []);
  const integrationTypeOptions = createVDPIntegrationOptions(userData);

  return (
    <Modal>
      <ModalItem>
        <Header>
          Push Video to Vehicle Display Pages?
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <Label>VIN</Label>
          <VehicleSearch
            onChangeVehicle={item => setInputValue(item?.vin || '')}
            selectedVin={inputValue}
            placeholder='Search vehicle by name, VIN or stock number...'
            creatable={false}
            menuPlacement='auto'
            menuPosition='fixed'
          />
          <Label style={{ marginTop: '15px' }}>Destination</Label>
          <SelectContainer>
            <Dropdown
              options={integrationTypeOptions}
              value={integrationTypeOptions.find(
                option => option?.value === integrationType
              )}
              onChange={option => setIntegrationType(option?.value || '')}
              menuPlacement='auto'
              menuPosition='fixed'
            />
          </SelectContainer>

          <ButtonsWrapper>
            <Button
              text='Yes, Push'
              onClick={() => handleSubmit(inputValue, integrationType)}
              disabled={inputValue === ''}
            />
            <Button
              text='No, Cancel'
              onClick={handleModalClose}
              variant='secondary'
            />
          </ButtonsWrapper>
        </Content>
      </ModalItem>
    </Modal>
  );
};
