import {
  ButtonSwitch,
  CheckboxInput,
  LoadingIndicator,
  SelectionHeaderPopup,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Search,
} from 'lib/components';
import {
  Heading,
  ParagraphSmall,
  ParagraphSmallBold,
} from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import { Container, Gap, MainWrapper } from 'lib/components/styles/layout';
import { HeaderContainer } from '../../components/styles';
import { Thumbnail } from '../../Thumbnail';
import { useAuth } from 'lib/context/auth';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { IoMdEye } from 'react-icons/io';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { RenameCategoryModal } from '../../modals/RenameCategoryModal';
import {
  SingleCategoryResponse,
  useSingleCategorizedGuidesQuery,
} from 'lib/api/guides/singleCategoryQuery';
import { useParams } from 'react-router';
import { usePrivateGuidesQueryMutation } from 'lib/api/guides/privateGuidesQuery';
import { MoveGuideModal } from '../../modals/MoveGuideModal';
import { DeleteCategoryModal } from '../../modals/DeleteCategoryModal';
import { DeleteGuideModal } from '../../modals/DeleteGuideModal';
import { ListManager } from 'react-beautiful-dnd-grid';
import { Guides } from 'lib/api/guides/guidesQuery';
import { useQueryClient } from 'react-query';
import { guidesKeyes } from 'lib/api/guides/guidesKeyes';
import { useReorderGuidesMutation } from 'lib/api/guides/reorderGuidesMutation';
import { AddGuideModal } from '../../modals/AddGuideModal';
import { NotFound } from 'app/pages/notFound/NotFound';
import { VisibilityModal } from '../../modals/VisibilityModal';
import { VisibilityContextProvider } from '../../provider/context';
import { CategorizedEmpty } from '../CategorizedEmpty';
import GuideBreadcrumbs from '../../components/GuideBreadcrumbs';
import {
  privacy,
  privacyOptions,
  SelectedGuideCategoryType,
} from './constants';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';
import CategoryBadge from '../../components/CategoryBadge';

const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
`;
const Count = styled(ParagraphSmallBold).attrs({ as: 'span' })``;

const ListManagerWrapper = styled.div`
  > div {
    gap: 13px;
    margin-bottom: 13px;

    display: flex;
    flex-wrap: wrap;
  }
`;

export type SingleCategoryModalType =
  | ''
  | 'rename'
  | 'move'
  | 'delete-category'
  | 'delete-guides'
  | 'add-guides'
  | 'visibility';

export const SingleCategory = () => {
  const IS_PAGINATION_ENABLED = false;
  const { userData } = useAuth();
  const { id } = useParams() as { id: string };
  const queryClient = useQueryClient();
  const [selectedGuides, setSelectedGuides] = useState<number[]>([]);
  const [isReorderStart, setReorderStart] = useState<boolean>(false);
  const [modals, setModals] = useState<SingleCategoryModalType>('');
  const [isOrderActive, setIsOrderActive] = useState<boolean>(false);
  const { mutate: togglePrivacyMutation, isLoading: isUpdatingPrivacy } =
    usePrivateGuidesQueryMutation();
  const [selectedGuideCategory, setSelectedGuideCategory] =
    useState<SelectedGuideCategoryType>(null);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(12);
  const { data, isLoading, isFetching, isError, refetch } =
    useSingleCategorizedGuidesQuery(id, {
      page,
      size: IS_PAGINATION_ENABLED ? 12 : 100,
      search,
    });
  const isEditable = data?.guideCategory.isEditable;
  const isCompanyAdmin = userData.isCompanyAdmin;

  const isSmallScreen = window?.innerWidth < 1280;

  const setOrderUnactiveHandler = () => {
    setIsOrderActive(false);
    setReorderStart(false);
  };
  const { mutateAsync, isLoading: isReordering } = useReorderGuidesMutation(
    setOrderUnactiveHandler
  );

  const closeModalHandler = () => {
    setModals('');
    setSelectedGuideCategory(null);
  };

  const resetSelection = () => {
    setSelectedGuides([]);
  };

  const openModalAndUpdateSelection = (modalType: SingleCategoryModalType) => {
    if (!data) {
      return;
    }
    setModals(modalType);
    setSelectedGuideCategory({
      name: data.guideCategory.name,
      guideCategoryId: data.guideCategory.guideCategoryId,
      guidesCount: data.count,
      private: data.guideCategory.private,
      isEditable: data.guideCategory.isEditable,
      platform: data.guideCategory.platform,
    });
  };

  const handleGuideSelection = (videoId: number) => {
    setSelectedGuides((prevSelectedGuides: number[]) => {
      if (prevSelectedGuides.includes(videoId)) {
        return prevSelectedGuides.filter(id => id !== videoId);
      }
      return [...prevSelectedGuides, videoId];
    });
  };

  const handleDragEnd = (sourceIndex: number, destinationIndex: number) => {
    // Item dropped in the same position, no need to reorder
    if (sourceIndex === destinationIndex) {
      return;
    }
    setReorderStart(true);
    const schema = queryClient.getQueryData(
      guidesKeyes.singleCategory(id)
    ) as SingleCategoryResponse;

    const [movedItem] = schema.guides.splice(sourceIndex, 1);
    schema.guides.splice(destinationIndex, 0, movedItem);

    queryClient.setQueryData(guidesKeyes.singleCategory(id), schema);
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  useEffect(() => {
    refetch();
  }, [page, size, search]);

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }

  if (isError) {
    return <NotFound />;
  }

  const videoArray = data?.guides?.map(d => d.videoId) || [];

  const defaultPrivacyValue: string = data?.guideCategory.private
    ? privacyOptions[0].value
    : privacyOptions[1].value;
  return (
    <Container>
      {selectedGuides.length > 0 && (
        <SelectionHeaderPopup left='208px'>
          <SelectionWrapper>
            <Gap width='100%' justifyContent='space-between'>
              <Gap>
                <CheckboxInput
                  blueCheck={true}
                  checkGroupIndicator={
                    selectedGuides.length > 0 &&
                    videoArray.length !== selectedGuides.length
                  }
                  checked={videoArray.length == selectedGuides.length}
                  ignoreGrayForAllSelect={true}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                    let { checked } = event.target as HTMLInputElement;
                    if (checked)
                      setSelectedGuides(
                        videoArray.map((videoId: number) => videoId)
                      );
                    else setSelectedGuides([]);
                  }}
                />
                <Gap gap='4px'>
                  <ParagraphSmall>Training Videos selected:</ParagraphSmall>
                  <Count>{selectedGuides.length}</Count>
                </Gap>
              </Gap>
              <Gap>
                <Button
                  onClick={() => openModalAndUpdateSelection('move')}
                  text='Move'
                  icon={
                    <FaArrowRightArrowLeft
                      color={theme.palette.covideoBlue100}
                    />
                  }
                  variant='secondary'
                />
                <Button
                  onClick={() => openModalAndUpdateSelection('delete-guides')}
                  text='Delete'
                  variant='destructive'
                />
              </Gap>
            </Gap>
          </SelectionWrapper>
        </SelectionHeaderPopup>
      )}
      <MainWrapper resetPadding>
        <Gap
          alignItems='center'
          justifyContent='space-between'
          style={{ marginTop: '96px', marginBottom: '24px' }}
        >
          <GuideBreadcrumbs
            items={[
              { path: '/guides', name: 'Training', isDisabled: false },
              {
                path: null,
                name: data?.guideCategory?.name || '',
                isDisabled: true,
              },
            ]}
            styles={{ marginTop: '0', marginBottom: '0' }}
          />
          <Search
            width='240px'
            onSearch={(search: string) => setSearch(search)}
            placeholder='Search Training Videos'
            prevSearch={search}
          />
        </Gap>
        <HeaderContainer>
          <Gap justifyContent='space-between' width='100%'>
            <Gap gap='24px'>
              <Heading>{data?.guideCategory?.name || 'Training Video'}</Heading>
              {isEditable && (
                <React.Fragment>
                  <ButtonSwitch
                    disabled={isUpdatingPrivacy || isOrderActive}
                    defaultValue={defaultPrivacyValue}
                    values={privacyOptions}
                    onChange={newValue => {
                      if (!data) {
                        return;
                      }
                      const isPrivate = newValue === privacy.PRIVATE;
                      togglePrivacyMutation({
                        isPrivate,
                        categoryId: data?.guideCategory.guideCategoryId,
                      });
                    }}
                  />
                  <CategoryBadge badgeType={data?.guideCategory?.platform} />
                </React.Fragment>
              )}
            </Gap>
            {isCompanyAdmin && isEditable && (
              <Gap gap='8px'>
                <Button
                  onClick={() => openModalAndUpdateSelection('delete-category')}
                  variant='destructive'
                  icon={<MdDeleteForever size={18} />}
                  disabled={isOrderActive}
                />
                <Button
                  onClick={() => openModalAndUpdateSelection('visibility')}
                  icon={<IoMdEye size={18} />}
                  disabled={isOrderActive}
                  variant='secondary'
                />
                <Button
                  onClick={() => openModalAndUpdateSelection('rename')}
                  text='Edit'
                  icon={<MdEdit size={18} />}
                  variant='secondary'
                  disabled={isOrderActive}
                />

                <Button
                  variant='primary'
                  onClick={() => {
                    if (isOrderActive && isReorderStart) {
                      const videoIds = data?.guides.map(guide => guide.videoId);
                      mutateAsync({ payload: videoIds });
                    }
                    setIsOrderActive(!isOrderActive);
                  }}
                  text={
                    isReordering
                      ? 'Reordering...'
                      : isOrderActive
                        ? 'Finish Reordering'
                        : 'Reorder'
                  }
                  disabled={isReordering || data?.guides.length === 0}
                />

                <Button
                  onClick={() => {
                    openModalAndUpdateSelection('add-guides');
                  }}
                  text='Add Training Video'
                  variant='secondary'
                  disabled={isOrderActive}
                />
              </Gap>
            )}
          </Gap>
        </HeaderContainer>
        {modals === 'rename' && (
          <RenameCategoryModal
            closeModalHandler={closeModalHandler}
            selectedGuideCategory={selectedGuideCategory}
          />
        )}

        {modals === 'delete-category' && (
          <DeleteCategoryModal
            closeModalHandler={closeModalHandler}
            selectedGuideCategory={selectedGuideCategory}
          />
        )}

        {modals === 'move' && (
          <MoveGuideModal
            resetSelection={resetSelection}
            closeModalHandler={closeModalHandler}
            selectedGuideCategory={selectedGuideCategory}
            selectedGuides={selectedGuides}
          />
        )}
        {modals === 'delete-guides' && (
          <DeleteGuideModal
            resetSelection={resetSelection}
            closeModalHandler={closeModalHandler}
            selectedGuides={selectedGuides}
            guideCategoryId={selectedGuideCategory?.guideCategoryId || 0}
          />
        )}
        {modals === 'add-guides' && (
          <AddGuideModal
            closeModalHandler={closeModalHandler}
            selectedGuideCategory={selectedGuideCategory}
          />
        )}
        {modals === 'visibility' && (
          <VisibilityContextProvider>
            <VisibilityModal
              closeModalHandler={closeModalHandler}
              selectedGuideCategory={selectedGuideCategory}
            />
          </VisibilityContextProvider>
        )}
        {data?.guides.length === 0 ? (
          <CategorizedEmpty />
        ) : (
          <TableContextProvider
            total={data?.count || 0}
            onChange={onPaginationChange}
            initPage={page}
            initSize={size}
          >
            <>
              {!isOrderActive ? (
                <div style={{ display: 'flex', gap: 13, flexWrap: 'wrap' }}>
                  {data?.guides.map(guide => (
                    <Thumbnail
                      key={guide.videoId}
                      guide={guide}
                      hasActions={
                        !!data?.guideCategory?.isEditable && !isReordering
                      }
                      setModals={setModals}
                      handleGuideSelection={handleGuideSelection}
                      selectedGuides={selectedGuides}
                    />
                  ))}
                </div>
              ) : (
                <ListManagerWrapper>
                  <ListManager
                    items={data?.guides || []}
                    direction='horizontal'
                    maxItems={isSmallScreen ? 3 : 4}
                    render={(item: Guides) => (
                      <Thumbnail
                        key={item.videoId}
                        guide={item}
                        isDraggable
                        setModals={setModals}
                        handleGuideSelection={handleGuideSelection}
                        selectedGuides={selectedGuides}
                      />
                    )}
                    onDragEnd={handleDragEnd}
                  />
                </ListManagerWrapper>
              )}
            </>
            {IS_PAGINATION_ENABLED && (
              <TableFooter>
                <TablePaginationNew disabled={isFetching || isLoading} />
                <TablePaginationSizeNew text='' />
              </TableFooter>
            )}
          </TableContextProvider>
        )}
      </MainWrapper>
    </Container>
  );
};
