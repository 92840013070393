import React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Main } from './main';

export const Notifications = () => (
  <>
    <DocumentHead title='Notifications' />
    <PageTemplate main={<Main />} />
  </>
);
