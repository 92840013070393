import React, { useEffect, useState } from 'react';
import { theme } from 'lib/style';
import { Customer } from 'lib/context';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
  HoverPopup,
} from 'lib/components';
import { Layout } from '../../index.styled';
import { NormalText, PaginationWrapper, SyncAction, TableCell } from './style';
import { ThreeDotsDropdown } from 'app/pages/sms/components/threeDots';
import { threeDotsActions } from './threeDotsDropdown';
import { useSyncMutateIDIMSConnection } from 'lib/api';
import { IMS_PROVIDERS_LABELS, IMS_PROVIDERS_VALUES } from '../types';
import {
  PopupList,
  PopupTextStyle,
} from '../../organizations/components/Accordion/styles';
import { Gap } from 'lib/components/styles/layout';
import { InventoryTableColumns, tableFields } from '../const';
import { MdRefresh } from 'react-icons/md';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { SortButtons } from '../../components/SortButtons';

type Props = {
  connectionsCount: number;
  connectionsArray: Customer[];
  page: number;
  size: number;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  editAction: (customerId: string | number | null) => void;
  deleteAction: (connection: Customer) => void;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
};

export const InventoryTable = ({
  connectionsCount,
  connectionsArray,
  page,
  size,
  onPaginationChange,
  editAction,
  deleteAction,
  onSortingUpdate,
  sortKey,
  order,
}: Props) => {
  const [syncId, setSyncId] = useState<null | string>(null);
  const {
    mutate: mutateSyncConnection,
    isLoading,
  } = useSyncMutateIDIMSConnection();
  const [filteredArray, setFilteredArray] = useState<Customer[]>([]);

  useEffect(() => {
    constructInventoryArray();
  }, [connectionsArray]);

  const constructInventoryArray = () => {
    let tempArr = connectionsArray;
    if (tempArr.length) {
      tempArr.forEach(connection => {
        for (const key in connection) {
          if (
            (!Object.values(IMS_PROVIDERS_VALUES).includes(
              key as IMS_PROVIDERS_VALUES
            ) &&
              key !== 'customerId' &&
              key !== 'business') ||
            connection[key as keyof Customer] === null
          ) {
            delete connection[key as keyof Customer];
          }
        }
      });
      setFilteredArray(tempArr);
    }
  };

  const hasMoreInventories = (connection: Customer) => {
    const inventories = [];
    Object.keys(connection).forEach(key => {
      if (
        Object.values(IMS_PROVIDERS_VALUES).includes(
          key as IMS_PROVIDERS_VALUES
        )
      ) {
        inventories.push(key);
      }
    });

    return inventories.length > 1
      ? { overflow: 'initial', height: '100%', paddingTop: '20px' }
      : {
          overflow: 'initial',
        };
  };

  // checks if propery is IMS related
  const isPropertyIMS = (key: string) => {
    return Object.values(IMS_PROVIDERS_VALUES).includes(
      key as IMS_PROVIDERS_VALUES
    );
  };

  const getLabelIMS = (key: string) => {
    // find index
    const indexOf = Object.values(IMS_PROVIDERS_VALUES).indexOf(
      (key as unknown) as IMS_PROVIDERS_VALUES
    );
    // return label
    return Object.values(IMS_PROVIDERS_LABELS)[indexOf];
  };

  // split IDs by pipe
  const splitConnectionIds = (connection: Customer, key: string) => {
    let split: string[] = connection[key as keyof Customer];
    if (connection[key as keyof Customer]) {
      split = connection[key as keyof Customer].split('|');
    }
    return split;
  };

  // construct inventory ids
  // if there is more than one show as -> 'inventoryID + {restCount} more'
  const constructInventoryIdsText = (connection: Customer, key: string) => {
    let split = splitConnectionIds(connection, key);
    let text: string = '';

    if (Array.isArray(split)) {
      if (split.length > 1) {
        text = `${split[0]} + ${split.length - 1} more`;
      } else {
        text = split[0];
      }
    }
    return text;
  };

  // construct popup list which displays all of the ids
  const constructInventoryIdsPopupList = (
    connection: Customer,
    key: string
  ) => {
    let split = splitConnectionIds(connection, key);
    return (
      <PopupList>
        {Array.isArray(split) &&
          split.map((connection, index) => (
            <PopupTextStyle key={index}>{connection}</PopupTextStyle>
          ))}
      </PopupList>
    );
  };

  return (
    <Layout>
      <TableContextProvider
        total={connectionsCount}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div className='scroll--container' style={{ paddingBottom: '20px' }}>
          <Table
            compact={true}
            borderColor={theme.palette.neutral10}
            relative={true}
            headers={[
              ...tableFields.map((item, index) => {
                return (
                  <TableCell key={index} width={item.width} onClick={() => {}}>
                    {item.label}
                    {item.sortable && item.sortKey && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value =>
                          onSortingUpdate(item.sortKey ?? '', value)
                        }
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            rows={filteredArray?.map((connection: Customer, index: number) => ({
              key: index,
              columns: [
                ...tableFields.map((item, index) => {
                  const options = threeDotsActions({
                    deleteAction,
                    editAction,
                    connection,
                  });
                  let columnValue = <></>;
                  switch (item.value) {
                    case InventoryTableColumns.CUSTOMER_NAME:
                      columnValue = (
                        <NormalText>{connection.business}</NormalText>
                      );
                      break;
                    case InventoryTableColumns.CUSTOMER_ID:
                      columnValue = (
                        <NormalText>{connection.customerId}</NormalText>
                      );
                      break;
                    case InventoryTableColumns.INVENTORY_PROVIDER:
                      columnValue = (
                        <Gap
                          flexDirection='column'
                          gap='3px'
                          justifyContent='start'
                          alignItems='start'
                        >
                          {Object.keys(connection).map((key, index) => {
                            //if property is IMS, find its label and display it
                            if (isPropertyIMS(key)) {
                              return (
                                <NormalText key={index}>
                                  {getLabelIMS(key)}
                                </NormalText>
                              );
                            }
                            return null;
                          })}
                        </Gap>
                      );
                      break;
                    case InventoryTableColumns.INVENTORY_ID:
                      columnValue = (
                        <Gap
                          flexDirection='column'
                          gap='3px'
                          justifyContent='start'
                          alignItems='start'
                        >
                          {Object.keys(connection).map((key, index) => {
                            //if property is IMS, find its ids -> divide by pipe and display as a flex column
                            if (isPropertyIMS(key)) {
                              const text = constructInventoryIdsText(
                                connection,
                                key
                              );
                              return (
                                <HoverPopup
                                  key={index}
                                  width='max-content'
                                  maxWidth='267px'
                                  position='above'
                                  padding='8px 12px'
                                  hoverable={<NormalText>{text}</NormalText>}
                                  popup={constructInventoryIdsPopupList(
                                    connection,
                                    key
                                  )}
                                />
                              );
                            }
                            return null;
                          })}
                        </Gap>
                      );
                      break;
                    case InventoryTableColumns.SYNC:
                      columnValue = (
                        <Gap flexDirection='row' gap='8px' alignItems='start'>
                          <SyncAction
                            onClick={() => {
                              mutateSyncConnection(connection.customerId);
                              setSyncId(connection.customerId);
                            }}
                            isRotating={
                              isLoading && syncId === connection.customerId
                            }
                            isLoading={isLoading}
                          >
                            <MdRefresh
                              size={24}
                              color={theme.palette.primaryDarkBlue}
                            />
                          </SyncAction>
                          <ThreeDotsDropdown
                            items={options}
                            border={`1px solid ${theme.palette.blue05}`}
                            borderRadius='6px'
                            isLoading={isLoading}
                          />
                        </Gap>
                      );
                      break;
                    default:
                      columnValue = <></>;
                  }
                  return (
                    <TableCell
                      key={index}
                      width={item.width}
                      style={hasMoreInventories(connection)}
                    >
                      {columnValue}
                    </TableCell>
                  );
                }),
              ],
              onClick: () => {},
            }))}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
