import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { AxiosRequestConfig } from 'axios';
import { emailReceiverKeys } from './emailReceiverKeys';
import { EmailReceiverResponse } from './types';

export const queryEmailReceiver = async (
  config: AxiosRequestConfig
): Promise<EmailReceiverResponse> => {
  const response = await EXPRESS_API.get('/emailreceiver', config);
  return response.data;
};

export const useQueryEmailReceiver = (config: AxiosRequestConfig) => {
  const { showError } = useToastError();
  return useQuery(
    emailReceiverKeys.email_receiver(config),
    () => queryEmailReceiver(config),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
