import React, { useState, useRef, createRef } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { InventoryItemMedia } from 'lib/context';
import { BsPlayFill } from 'react-icons/bs';
import { InventoryItemMediaType } from 'lib/const/InventoryItemMediaType';
import { VideoPlayer } from 'app/pages/video/videoPlayer';

type ContainerProps = {
  width: string;
};

const Container = styled.div<ContainerProps>`
  width: ${({ width }) => width};
`;

const Main = styled.div`
  margin-bottom: 24px;
  position: relative;
  .fullscreen {
    height: auto;
  }
  video {
    max-height: 100%;
    object-fit: contain;
    object-position: top;
  }
  figure {
    width: 100%;
    background: ${theme.palette.black};
  }
`;

const ThumbnailsWrapper = styled.div`
  width: auto;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
  &:active {
    cursor: grabbing;
  }
`;

type ThumbnailProps = {
  img: string;
  size: string;
  active: boolean;
};
const Thumbnail = styled.div<ThumbnailProps>`
  position: relative;
  box-sizing: content-box;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  flex-shrink: 0;
  flex-grow: 0;
  background-color: ${theme.palette.black};
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: ${({ active }) =>
    active ? `inset 0 0 0 4px ${theme.palette.blue100}` : `none`};
  border-radius: 5px;
`;

const VideoPlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

type ChosenImageProps = {
  img: string;
  width: string;
  height: string;
};
const ChosenImage = styled.div<ChosenImageProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  flex-shrink: 0;
  flex-grow: 0;
  background-color: ${theme.palette.black};
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  border-radius: 5px;
  background-size: contain;
  background-position: center;
`;

type Props = {
  media: InventoryItemMedia[];
  width?: number;
};

export const MediaGallery = ({ media = [], width = 800 }: Props) => {
  const mainWidth = width;
  const mainHeight = width * 0.5625;
  const thumbnailSize = width * 0.08;

  const slider = useRef<HTMLDivElement>(null);
  const videoRef = createRef<HTMLVideoElement>();

  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [chosenMedia, setChosenMedia] = useState(
    media[0] || ({} as InventoryItemMedia)
  );

  const startDragging = (e: any) => {
    if (!slider || !slider.current) return;
    setMouseDown(true);
    setStartX(e.pageX - slider.current.offsetLeft);
    setScrollLeft(slider.current.scrollLeft);
  };

  const stopDragging = () => {
    setMouseDown(false);
  };

  const dragg = (e: any) => {
    e.preventDefault();
    if (!mouseDown || !slider || !slider.current) return;
    const x = e.pageX - slider.current.offsetLeft;
    const scroll = x - startX;
    slider.current.scrollLeft = scrollLeft - scroll;
  };

  return (
    <Container width={`${width}px`}>
      <Main>
        {chosenMedia &&
          chosenMedia.mediaType === InventoryItemMediaType.Image && (
            <ChosenImage
              img={chosenMedia.mediaSource}
              width={`${mainWidth}px`}
              height={`${mainHeight}px`}
            />
          )}
        {chosenMedia &&
          chosenMedia.mediaType === InventoryItemMediaType.Video && (
            <VideoPlayer
              videoRef={videoRef}
              width={`${mainWidth}px`}
              height={`${mainHeight}px`}
              videoSource={chosenMedia.mediaSource}
            />
          )}
      </Main>
      <ThumbnailsWrapper
        ref={slider}
        onMouseMove={(e: any) => dragg(e)}
        onMouseDown={(e: any) => startDragging(e)}
        onMouseUp={() => stopDragging()}
        onMouseLeave={() => stopDragging()}
      >
        {media.map((m, i) => (
          <Thumbnail
            key={i}
            img={
              m.mediaType === InventoryItemMediaType.Image ? m.mediaSource : ''
            }
            onClick={() => setChosenMedia(m)}
            size={`${thumbnailSize}px`}
            active={m === chosenMedia}
          >
            {m.mediaType === InventoryItemMediaType.Video && (
              <VideoPlay>
                <BsPlayFill color={theme.palette.white} size={30} />
              </VideoPlay>
            )}
          </Thumbnail>
        ))}
      </ThumbnailsWrapper>
    </Container>
  );
};
