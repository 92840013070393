import React from 'react';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal, RadioInput } from 'lib/components';
import { updateUser } from 'lib/api';

import { theme } from 'lib/style';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;
const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.coal};
  font-size: 18px;
  line-height: 24px;
`;
const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;
const RadioWrapper = styled.div`
  margin-bottom: 6px;
`;
const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedUser?: any;
};

export const ChangeStatusModal = ({
  handleModalClose,
  selectedUser,
}: Props) => {
  const [status, setStatus] = useState(2);
  const [loadingModal, setLoadingModal] = React.useState(false);
  const { ref } = useOnClickOutside(handleModalClose);
  const themes = useTheme();

  const handleUpdateStatus = async () => {
    setLoadingModal(true);
    let count = 0;
    let errorMessage = '';
    for (const userId of selectedUser) {
      try {
        await updateUser(userId, { active: status }, true);
        count++;
      } catch (error) {
        if (error instanceof Error) {
          errorMessage = error?.message;
        }
        errorMessage = 'Something went wrong';
        console.log('Error', error);
      }
    }
    handleModalClose(1);
    setLoadingModal(false);
    if (errorMessage) {
      errorToast({
        title: errorMessage,
      });
    } else {
      count &&
        successToast({
          title: `Status updated successfully for ${count} user${
            count > 1 ? 's' : ''
          }!`,
        });
    }
    setLoadingModal(false);
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            Change Status for {selectedUser.length} User
            {selectedUser.length > 1 && 's'}
          </HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <RadioWrapper>
            <RadioInput
              name={'Status'}
              value={1}
              text={'Active'}
              checkedColor={themes.colors.primary[100]}
              checked={status === 1}
              onChange={() => {}}
              onClick={() => setStatus(1)}
            />
          </RadioWrapper>
          <RadioWrapper>
            <RadioInput
              name={'Status'}
              value={0}
              text={'Inactive'}
              checkedColor={themes.colors.primary[100]}
              checked={status === 0}
              onChange={() => {}}
              onClick={() => setStatus(0)}
            />
          </RadioWrapper>
          <SubmitButtonWrapper>
            <Button
              text={'Update'}
              onClick={() => handleUpdateStatus()}
              disabled={!(status === 0 || status === 1) || loadingModal}
            />
          </SubmitButtonWrapper>
        </Content>
      </div>
    </Modal>
  );
};
