import { NewModal, TextInput } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Formik, Form, FormikValues } from 'formik';
import { InputField } from '../components/InputField';
import { useMultiLocationRenameMutation } from 'lib/api/multiLocations/useMultilocationRenameQuery';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
  currentLocation: {
    name: string | null;
    locationId: number | null;
  } | null;
}

export const EditLocationCustomerModal = ({
  closeModalHandler,
  currentLocation,
}: IProps) => {
  const { mutateAsync, isLoading } =
    useMultiLocationRenameMutation(closeModalHandler);
  const onSubmitHandler = (values: FormikValues) => {
    if (!currentLocation?.locationId) {
      return;
    }
    mutateAsync({ locationId: currentLocation.locationId, name: values.name });
  };

  return (
    <NewModal
      headerText='Edit location customer'
      closeModal={closeModalHandler}
      style={{ content: { minWidth: 592, width: 592 } }}
    >
      <Formik
        initialValues={{ name: currentLocation?.name || '' }}
        onSubmit={onSubmitHandler}
      >
        {({ handleSubmit, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='44px'
              >
                <InputField
                  style={{
                    margin: 0,
                    width: '100%',
                    maxWidth: '100%',
                    height: 40,
                  }}
                  name='name'
                  as={TextInput}
                  type='text'
                  disabled={isLoading}
                />
                <Button
                  text={isLoading ? 'Saving...' : 'Save Location'}
                  disabled={!values.name || isLoading}
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
