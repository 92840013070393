import React from 'react';
import styled from 'styled-components/macro';
import { Search, Dropdown } from 'lib/components';

import { VerificationStatusOptions } from 'lib/const/VerificationsStatusOptions';
import { CustomersDropdown } from '../../components/CustomersDropdown';
import { UsageReportPackageOptions } from 'lib/const/UsageReportPackageOptions';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  justify-content: space-between;
`;

type FilterProps = {
  marginRight?: number;
  width?: number;
};
const Filter = styled.div<FilterProps>`
  min-width: 224px;
  width: ${props => `${props.width}px` || 'auto'};
  margin-right: ${props => `${props.marginRight}px` || '0px'};
`;

type Props = {
  prevSearch?: string;
  statusFilter?: number;
  packageFilter?: number;
  customerFilter?: number;
  onSelectStatus(statusValue: number): void;
  onSelectPackage(packageValue: number): void;
  onSelectCustomer(customerId: number): void;
  onSearch(search: string): void;
};

export const SearchAndFilter = ({
  prevSearch,
  statusFilter,
  packageFilter,
  customerFilter,
  onSelectStatus,
  onSelectPackage,
  onSelectCustomer,
  onSearch,
}: Props) => {
  return (
    <FiltersContainer>
      <Filter width={404} marginRight={12}>
        <Search
          placeholder='Search by name, customer or email...'
          onSearch={onSearch}
          prevSearch={prevSearch}
        />
      </Filter>
      <Filter width={188} marginRight={12}>
        <Dropdown
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'Filter by Statuses'}
          value={VerificationStatusOptions.find(
            option => option.value === statusFilter
          )}
          onChange={value => onSelectStatus(value.value)}
          options={VerificationStatusOptions}
          zIndexProp={1}
        />
      </Filter>
      <Filter width={188} marginRight={12}>
        <Dropdown
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'All users'}
          value={UsageReportPackageOptions.find(
            option => option.value === packageFilter
          )}
          onChange={value => onSelectPackage(value.value)}
          options={UsageReportPackageOptions}
          zIndexProp={1}
        />
      </Filter>

      <Filter width={188}>
        <CustomersDropdown
          showAllCustomersOption={true}
          customerFilter={customerFilter}
          onSelectCustomer={onSelectCustomer}
          zIndexProp={1}
        />
      </Filter>
    </FiltersContainer>
  );
};
