import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const Container = styled.div`
  border-radius: 6px;
  border: ${theme.palette.neutral10};
  background: ${theme.palette.white};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 24px;
  margin: 16px 0px;
  min-width: 700px;
`;

export const Wrapper = styled.div`
  color: ${theme.palette.red100};
  font-size: ${theme.fontSizes.xxs};
  font-weight: ${theme.fontNormal600};
  margin-top: 2px;
`;
