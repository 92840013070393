import * as React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { MdDragHandle } from 'react-icons/md';
import { FaMinusCircle } from 'react-icons/fa';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ItemsWrapper } from './styles';
import {
  ParagraphExtraSmall,
  ParagraphSmall,
  ParagraphSmallBold,
} from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';
import { SelectedFieldsProps, SelectField } from './types';

const SelectedFieldsWrap = styled.div<{
  scrollableHeight: string;
  isEmpty: boolean;
}>`
    overflow-y: auto;
    position: relative;
    height: 100%;
    padding: 20px 24px;
    max-height: ${({ scrollableHeight }) => scrollableHeight || 'auto'};
    min-height: ${({ scrollableHeight }) => scrollableHeight || 'auto'};
    border: dashed 1px #d0d3d9;
    background-color:: ${({ theme }) => theme.colors.white[100]};
    display: flex;
    flex-direction: column;
    gap: 8px;
    ${({ isEmpty }) =>
      isEmpty &&
      css`
        align-items: center;
        justify-content: center;
      `}
  `;

const SelectedFieldWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.secondary['non_alpha']};
  position: relative;
`;
const reorderItems = (
  list: SelectField[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const SelectedFields = ({
  maxFieldConfig,
  onDragEnd,
  scrollableHeight,
  selectedFields,
  onRemoveClickHandler,
}: SelectedFieldsProps) => {
  const onDragEndHandler = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorderItems(
      selectedFields,
      result.source.index,
      result.destination.index
    );

    return onDragEnd(items);
  };

  const { colors } = useTheme();
  return (
    <ItemsWrapper style={{ maxWidth: 384 }}>
      <ParagraphSmallBold color={colors.neutral[80]}>
        Selected Data{' '}
        {!maxFieldConfig?.hideText ? `(max ${maxFieldConfig?.count})` : null}
      </ParagraphSmallBold>
      <DragDropContext onDragEnd={onDragEndHandler}>
        <Droppable droppableId='selectedFields'>
          {provided => (
            <SelectedFieldsWrap
              scrollableHeight={scrollableHeight}
              isEmpty={!selectedFields.length}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {!selectedFields.length ? (
                <ParagraphExtraSmall
                  textAlign='center'
                  width='85%'
                  color={colors.neutral[60]}
                  style={{ fontWeight: 500 }}
                >
                  No data selected. Select from available data on the left.
                </ParagraphExtraSmall>
              ) : (
                selectedFields.map((field, index) => (
                  <Draggable
                    key={field.value}
                    draggableId={field.value}
                    index={index}
                  >
                    {provided => (
                      <SelectedFieldWrap
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Gap gap='8px'>
                          <MdDragHandle color={colors.secondary[40]} />
                          <ParagraphSmall color={colors.secondary[80]}>
                            {field.label}
                          </ParagraphSmall>
                        </Gap>
                        {field.value !== 'email' && (
                          <FaMinusCircle
                            size={16}
                            color={colors.danger[40]}
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              right: 12,
                            }}
                            onClick={() => onRemoveClickHandler(field)}
                          />
                        )}
                      </SelectedFieldWrap>
                    )}
                  </Draggable>
                ))
              )}
              {provided.placeholder}
            </SelectedFieldsWrap>
          )}
        </Droppable>
      </DragDropContext>
    </ItemsWrapper>
  );
};
