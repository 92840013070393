import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { MeetingResponse } from './types';

const deleteMeeting = async (data: {
  meetingId: string;
}): Promise<MeetingResponse> => {
  const { meetingId } = data;

  const response = await EXPRESS_API.delete(`/meeting/${meetingId}`);
  return response.data;
};

export const useDeleteMeetingMutation = (
  handleCloseLiveMeetingModal: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(deleteMeeting, {
    onError: () => {
      errorToast({
        title:
          'An error occurred deleting the meeting, please try again later!',
      });
    },
    onSuccess: () => {
      handleCloseLiveMeetingModal();
      successToast({
        title: 'Meeting has been deleted.',
      });
      queryClient.invalidateQueries(meetingKeys.meetings_list());
    },
  });
};
