import { Form, Formik } from 'formik';
import {
  checkUsername,
  getDepartments,
  getProfileData,
  updateUser,
} from 'lib/api';
import { HeaderWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import {
  checkIfFeatureIsEnabled,
  isInternalCustomer,
  productFeature,
} from 'lib/utils/productFeature';
import * as React from 'react';
import { useState } from 'react';
import { IoMdSave } from 'react-icons/io';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { Tab, Tabs } from '../../../../../../lib/components/tabs';
import { theme } from '../../../../../../lib/style';
import { UserForm, UserValues, validateUser } from '../components';
import { AddOnsForm } from '../components/AddOnsForm';
import { IntegrationsForm } from '../components/IntegrationsForm';
import { PasswordForm } from '../components/PasswordForm';
import { PreferencesForm } from '../components/PreferencesForm';
import { SignatureForm } from '../components/SignatureForm';
import { WorkingHours } from '../components/WorkingHours';
import {
  hasEmail2FieldAccess,
  validateInputForCsvFormulaInjection,
} from 'lib/utils/functions';
import { useHistory } from 'react-router-dom';
import selectors from '../../../../../../cypress/selectors';
import { useUsernameExists } from 'lib/api/users/getUsernameExists';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { errorToast } from 'lib/components/toasts/error';
import { INotificationPreferences } from '../components/Notifications/common/types';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { AccessRole } from 'lib/const';

export interface IWorkingHours {
  sunday: IWorkingDay | null;
  monday: IWorkingDay | null;
  tuesday: IWorkingDay | null;
  wednesday: IWorkingDay | null;
  thursday: IWorkingDay | null;
  friday: IWorkingDay | null;
  saturday: IWorkingDay | null;
}

export interface IWorkingDay {
  from: IWorkingDayOptions;
  to: IWorkingDayOptions;
}

interface IWorkingDayOptions {
  hours: string;
  meridiem: string;
}

export type ReceivedData = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  email2?: string;
  signupDate: string;
  phone1: string;
  phone2?: string;
  cellPhone?: string;
  title?: string;
  autoTranscribe: string;
  transcriptionDefaultEnabled: string;
  eleadDMSEmployeeID: string;
  Dept?: string;
  website?: string;
  timezone?: string;
  playButtonPosition: string;
  signature?: string;
  nmls?: string;
  googleCalendarId?: string;
  calendarWebsiteUrl?: string;
  calendlyCalendarUrl?: string;
  calendlyAccessToken?: string;
  outlookRefreshToken?: string;
  platform?: number;
  reactionsPreference?: any;
  workingHours: IWorkingHours;
  resellerId?: number;
  videoShareLinkText?: string;
  notificationPreferences?: INotificationPreferences;
};

type ProfileProps = {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  data: ReceivedData;
  setProfileUser: Function;
};

const INIT_WORKING_HOURS: IWorkingHours = {
  sunday: null,
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  saturday: null,
};

let fields = {
  signupDate: '',
  firstName: '',
  lastName: '',
  username: '',
  newPassword: '',
  confirmPassword: '',
  email: '',
  email2: '',
  eleadEmployeeId: '',
  phone1: '',
  phone2: '',
  cellPhone: '',
  userTitle: '',
  department: '',
  website: '',
  timezone: '',
  playButtonPosition: '',
  captions: '',
  captionVisibility: '',
  signature: '',
  nmls: '',
  reactionsPreference: '',
  workingHours: INIT_WORKING_HOURS,
};

const getWorkingHours = (data: ReceivedData) => {
  if (data.workingHours === null) {
    return INIT_WORKING_HOURS;
  }

  const parsedData: IWorkingHours = JSON.parse(
    data.workingHours as unknown as string
  );

  if (!Object.keys(parsedData).length) {
    return INIT_WORKING_HOURS;
  }

  return parsedData;
};
const defaultVideoShareLinkText = (firstName: string, lastName: string) => {
  return `Click here to view your video from ${firstName} ${lastName}`;
};
const loadFields = (data: ReceivedData, hasEmail2Field: boolean) => {
  return {
    firstName: data.firstName,
    lastName: data.lastName || '',
    username: data.username,
    email: data.email || '',
    ...(hasEmail2Field ? { email2: data.email2 || '' } : {}),
    phone1: data.phone1 || '',
    phone2: data.phone2 || '',
    userTitle: data.title || '',
    cellPhone: data.cellPhone || '',
    captions: data.autoTranscribe || '0',
    captionVisibility: data.transcriptionDefaultEnabled || '',
    newPassword: '',
    confirmPassword: '',
    signupDate: data.signupDate,
    eleadEmployeeId: data.eleadDMSEmployeeID || '',
    department: data.Dept || '',
    website: data.website || '',
    timezone: data.timezone || '',
    playButtonPosition: data.playButtonPosition,
    signature: data.signature || '',
    platform: data.platform || 0,
    googleCalendarId: data.googleCalendarId || '',
    calendarWebsiteUrl: data.calendarWebsiteUrl || '',
    calendlyCalendarUrl: data.calendlyCalendarUrl || '',
    calendlyAccessToken: data.calendlyAccessToken || '',
    outlookRefreshToken: data.outlookRefreshToken || '',
    nmls: data.nmls || '',
    reactionsPreference: data.reactionsPreference,
    videoShareLinkText:
      data.videoShareLinkText?.trim() ||
      defaultVideoShareLinkText(data.firstName, data.lastName || ''),
    workingHours: getWorkingHours(data),
    resellerId: data.resellerId || 0,
    notificationPreferences:
      data.notificationPreferences ||
      ({
        sound: '',
      } as INotificationPreferences),
  };
};

const preparePatchData = (values: UserValues, hasEmail2Field: boolean) => {
  values.firstName = values.firstName.trim();
  values.lastName = values.lastName.trim();
  values.signature = signature_data;
  const videoShareLinkText =
    !!values.videoShareLinkText?.trim() &&
    defaultVideoShareLinkText(values.firstName, values.lastName || '') !==
      values.videoShareLinkText
      ? values.videoShareLinkText?.trim()
      : '';
  return {
    signupDate: values.signupDate,
    firstName: values.firstName,
    lastName: values.lastName,
    username: values.username.trim(),
    newPassword: values.newPassword,
    confirmPassword: values.confirmPassword,
    email: values.email || '',
    ...(hasEmail2Field ? { email2: values.email2 || null } : {}),
    eleadDMSEmployeeID: values.eleadEmployeeId || '',
    phone1: values.phone1 || '',
    phone2: values.phone2 || '',
    cellPhone: values.cellPhone || '',
    title: values.userTitle || '',
    departmentIds: values.department ? [values.department] : [],
    website: values.website || '',
    timezone: values.timezone,
    playButtonPosition: values.playButtonPosition,
    autoTranscribe: values.captions,
    transcriptionDefaultEnabled: values.captionVisibility,
    signature: values.signature,
    platform: values.platform || '0',
    googleCalendarId: values.googleCalendarId || '',
    calendarWebsiteUrl: values.calendarWebsiteUrl || '',
    calendlyCalendarUrl: values.calendlyCalendarUrl || '',
    calendlyAccessToken: values.calendlyAccessToken || '',
    outlookRefreshToken: values.outlookRefreshToken || '',
    nmls: values.nmls || '',
    active: true,
    reactionsPreference: values.reactionsPreference,
    workingHours: values.workingHours,
    notificationPreferences: values.notificationPreferences,
    videoShareLinkText,
  };
};

let signature_data = '';

const setSignature = (text: string) => {
  signature_data = text;
};

const TabsContainer = styled.div`
  .tab-container {
    max-width: 100%;
    width: 100%;
  }
  .tab-navigation {
    padding: 0;
    justify-content: flex-start;
  }
  .tab-content {
    padding: 24px 0 0;
    width: 100%;
  }
`;

const fieldsSchema = Yup.object({
  phone1: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(7, 'Please enter a phone number equal or longer than 7 characters.')
    .test('isNotSuspicious', 'phone1 is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
  phone2: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(7, 'Please enter a phone number equal or longer than 7 characters.')
    .test('isNotSuspicious', 'phone2 is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
  cellPhone: Yup.string()
    .nullable()
    .transform((v, o) => (o === '' ? undefined : v))
    .min(
      7,
      'Please enter a cell phone number equal or longer than 7 characters.'
    )
    .test('isNotSuspicious', 'cell phone is not valid', function (value = '') {
      if (!value) {
        return true;
      }

      return validateInputForCsvFormulaInjection(value);
    }),
});

export const EditUserProfile = (props: ProfileProps) => {
  const { data, activeTab, setActiveTab, setProfileUser } = props;
  const { userData, invalidateUser } = useAuth();

  const { customerId, userId, customer, resellerId } = userData;
  const hasEmail2Field = hasEmail2FieldAccess(resellerId, customerId);
  fields = { ...fields, ...loadFields(data, hasEmail2Field) };
  const history = useHistory();

  const isAutomotiveSales = userData.isAutomotiveSalesRole;
  const showWorkingHours = isInternalCustomer(userData);
  const [isVideoReplyEnabled, setIsVideoReplyEnabled] = useState(true);
  const [isUsernameValid, setUsernameValid] = React.useState(true);

  const packageId = userData?.user?.packageDetails?.id || PackageName.LEGACY;
  const isTrial = userData?.trialAccount;
  const { data: usernameExists } = useUsernameExists({
    search: fields.username,
  });

  // added to show error message for SUS-517
  const { showError } = useToastError();

  const submitData = async (values: UserValues) => {
    try {
      const data = preparePatchData(values, hasEmail2Field);
      const userExist = await checkUsername(data.username, userData.id);
      if (userExist.userExist) {
        errorToast({ title: 'Username is already taken.' });
        return;
      }
      await updateUser(userId, data, true, CHANGE_ORIGIN.myProfile);
      // UPDATE USER
      await invalidateUser();
      // Get data after form is submitted so that latest details will be shown to user
      let profileUser = await getProfileData(userId);
      profileUser = {
        ...profileUser,
        platform: data.platform,
        outlookRefreshToken: data.outlookRefreshToken,
      };
      setProfileUser(profileUser);
      successToast({ title: 'Your personal information has been saved.' });
    } catch (error) {
      // SUS-517 show error message from catch else show common error message, also imported toast notifications.
      showError(
        error ||
          'An error occurred saving your information, please try again later.'
      );
    }
  };

  const [deptLoading, setDeptLoading] = useState(true);
  const [deptList, setDeptList] = React.useState<any>([]);

  const usernameSearch =
    !!usernameExists?.userExist && fields.username !== data.username;

  const getDept = async () => {
    try {
      const dept = await getDepartments(customerId, {});
      setDeptList(dept.data);
      setDeptLoading(false);
    } catch (error) {
      setDeptLoading(false);
    }
  };

  React.useEffect(() => {
    if (userData.preventUserEditAccess) {
      history.push('/home');
    }
    const userRolesAccess = checkIfFeatureIsEnabled(
      userData,
      productFeature.USER_ROLES_AND_MANAGEMENT
    );
    if (userRolesAccess) {
      getDept();
    }
  }, []);

  React.useEffect(() => {
    setIsVideoReplyEnabled(
      checkIfFeatureIsEnabled(userData, productFeature.VIDEO_REPLY)
    );
  }, [userData]);

  // Added userId in UserForm for SUS-521

  const TabComponent = ({
    tab,
    isSubmitting,
    setFieldValue,
  }: {
    tab: number;
    isSubmitting: boolean;
    setFieldValue: any;
  }) => {
    switch (tab) {
      case 0:
        return (
          <UserForm
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            deptList={deptList || []}
            deptLoading={deptLoading}
            userId={userId}
            hasEmail2Field={hasEmail2Field}
            isUsernameValid={isUsernameValid}
            setUsernameValid={setUsernameValid}
          />
        );
      case 1:
        return <PasswordForm isSubmitting={isSubmitting} />;
      case 2:
        return (
          <SignatureForm
            setFieldValue={setFieldValue}
            setSignatureData={setSignature}
          />
        );
      case 3:
        return (
          <PreferencesForm
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            showReactionsPreference={
              customer?.reactionsPreference &&
              customer?.reactionsPreference.toString() !== '0' &&
              isVideoReplyEnabled
            }
            packageId={packageId}
          />
        );
      case 4:
        return <AddOnsForm isSubmitting={isSubmitting} />;
      case 5:
        return showWorkingHours ? <WorkingHours /> : null;
      case 6:
        return <IntegrationsForm isSubmitting={isSubmitting} />;

      default:
        return <div></div>;
    }
  };

  let tabs: Tab[] = [
    {
      title: 'User info',
      component: <></>,
      inActiveTextColor: theme.palette.gray60,
    },
    {
      title: 'Account',
      component: <></>,
      inActiveTextColor: theme.palette.gray60,
    },
    {
      title: 'Signature',
      component: <></>,
      inActiveTextColor: theme.palette.gray60,
    },
    {
      title: 'Preferences',
      component: <></>,
      inActiveTextColor: theme.palette.gray60,
    },
  ];
  const hasAddonsAccess =
    (packageId === PackageName.LEGACY ||
      packageId === PackageName.TEAMS ||
      packageId === PackageName.ENTERPRISE) &&
    !isTrial &&
    userData.access !== AccessRole.USER;
  if (hasAddonsAccess) {
    tabs.push({
      title: 'Add-ons',
      component: <></>,
      inActiveTextColor: theme.palette.gray60,
    });
  }

  if (showWorkingHours) {
    tabs.push({
      title: 'Working hours',
      component: <></>,
      inActiveTextColor: theme.palette.gray60,
    });
  }

  if (isAutomotiveSales) {
    tabs.push({
      title: 'Integrations',
      component: <></>,
      inActiveTextColor: theme.palette.gray60,
    });
  }

  return (
    <>
      <Formik
        initialValues={{
          ...fields,
          ...loadFields(data, hasEmail2Field),
        }}
        validate={values => validateUser(values, usernameSearch)}
        validationSchema={fieldsSchema}
        onSubmit={submitData}
        enableReinitialize
      >
        {({ isSubmitting, isValid, values, setFieldValue, dirty }) => {
          return (
            <Form autoComplete='new-password'>
              <HeaderWrapper>
                <Heading>Edit My Profile</Heading>
                <Button
                  text={isSubmitting ? 'Saving' : 'Save changes'}
                  type='submit'
                  disabled={
                    isSubmitting || !isValid || !dirty || !isUsernameValid
                  }
                  variant='primary'
                  icon={<IoMdSave />}
                  data-cy={selectors.editUserProfile.saveButton}
                />
              </HeaderWrapper>
              <TabsContainer>
                <Tabs
                  tabs={tabs}
                  defaultActiveTab={activeTab}
                  onChange={setActiveTab}
                />
                <TabComponent
                  tab={activeTab}
                  isSubmitting={isSubmitting}
                  setFieldValue={setFieldValue}
                />
              </TabsContainer>
              <RouteLeavingGuard
                when={true}
                navigate={path => {
                  history.push(path);
                }}
                onConfirm={async () => {
                  await submitData(values);
                }}
                onDiscard={() => {}}
                shouldBlockNavigation={() => {
                  return !!dirty;
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
