import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { contactsKeys } from './queryKeys';

export const deleteAllContacts = async (ids: string[]) => {
  const response = await EXPRESS_API.delete('/contacts/delete-all', {
    data: { ids },
  });
  return response.data;
};

export const useDeleteAllContactsMutation = (
  ids: string[],
  onDeleteContactsMutationSuccess: () => void
) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation(() => deleteAllContacts(ids), {
    onError: err => showError(err),
    onSuccess: async () => {
      successToast({
        title: `Contact${ids.length > 1 ? 's' : ''} successfully deleted!`,
      });
      await queryClient.invalidateQueries(contactsKeys.all());
      onDeleteContactsMutationSuccess();
    },
  });
};
