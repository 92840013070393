import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { videoDeletesKeys } from './queryKeys';

export const getVideosForCustomer = async (days: number) => {
  const response = await EXPRESS_API.get(`/video-deletes/videos`, {
    params: { days },
  });
  return response.data;
};

export const useVideosForCustomerQuery = (days: number) => {
  return useQuery(
    videoDeletesKeys.get_videos_for_customer(days),
    () => getVideosForCustomer(days),
    {
      refetchOnMount: true,
    }
  );
};
