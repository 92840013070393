import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { DepartmentDetails } from './DepartmentDetails';
// import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route
        path="/users/department/:id"
        component={DepartmentDetails}
        exact={true}
      />
    </Switch>
  );
};
