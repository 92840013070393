import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { NewModal, Search } from 'lib/components';
import { useHistory } from 'react-router';
import { EMAIL_REGEX, PHONE_REGEX } from 'lib/utils/regexes';
import { SEARCH_CONVERSATIONS_ENUMS } from '../conversations.types';
import {
  IStartConversationParams,
  useStartConversationMutation,
} from 'lib/api/conversations/startConversation';
import { useContactsQuery } from 'lib/api/contacts/useContactsQuery';
import { IContact } from 'lib/api/contacts/getSingleContact';
import { useAuth } from 'lib/context';
import { ILead, useLeadsQuery } from 'lib/api/leads/getLeads';
import { Button } from 'react-covideo-common';

const ModalBody = styled.div<{ isEmpty: boolean }>`
  background: #f6f7f9;
  border-radius: 6px;
  padding: 16px;
  width: 100%;
  gap: 12px;
  flex-direction: column;
  display: flex;
  box-sizing: border-box;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      align-items: center;
      justify-content: center;
      flex: 1;
    `}
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
`;

const Paragraph = styled.p<{ fontWeight: string; color: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: 14px;
  line-height: 20px;
  color: ${({ color }) => color};
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
`;

const SearchWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const EmptyMsg = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

const RowItem = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  max-width: ${({ width }) => width};
`;

const getSearchType = (searchValue: string) => {
  const isEmail = searchValue.match(EMAIL_REGEX);
  const isPhoneNum = searchValue.match(PHONE_REGEX);

  if (isEmail) {
    return SEARCH_CONVERSATIONS_ENUMS.E_MAIL;
  }
  if (isPhoneNum) {
    return SEARCH_CONVERSATIONS_ENUMS.PHONE;
  }
  return SEARCH_CONVERSATIONS_ENUMS.TEXT;
};

export interface IStartConversationBody extends IStartConversationParams {}

export const ModalStartConversation = ({ onModalClose }: any) => {
  const history = useHistory();
  const { userData } = useAuth();
  const leadsIntegrationActive = !!userData.customer.leadsIntegrationActive;
  const [searchValue, setSearch] = useState('');

  const { data, isLoading } = useContactsQuery(
    { size: 50, search: searchValue, conversation: true },
    !leadsIntegrationActive
  );

  const { data: leads } = useLeadsQuery(
    { size: 50, search: searchValue, conversation: true },
    leadsIntegrationActive
  );

  const dataToShow = leadsIntegrationActive ? leads?.leads : data?.contacts;

  const { mutateAsync } = useStartConversationMutation();

  const onModalCloseHandler = () => {
    onModalClose();
  };

  const startNewConversation = async (data: IStartConversationBody) => {
    const res = await mutateAsync(data);
    const { deleted } = res;
    history.replace({
      pathname: `/conversations/${!!deleted ? 'deleted' : 'all'}/${
        res.conversationId
      }`,
    });
    onModalCloseHandler();
  };

  const type = getSearchType(searchValue);

  return (
    <NewModal
      headerText='New Conversation'
      closeModal={onModalClose}
      style={{
        content: {
          maxWidth: '852px',
          minWidth: '852px',
          maxHeight: '570px',
          minHeight: '570px',
        },
      }}
    >
      <>
        <SearchWrapper>
          <Search
            prevSearch={searchValue}
            onSearch={value => {
              setSearch(value);
            }}
            placeholder='Search for customers or type phone number or email'
            width='100%'
          />
        </SearchWrapper>
        <ModalBody isEmpty={!dataToShow?.length || isLoading}>
          {isLoading ? (
            <EmptyMsg>Loading....</EmptyMsg>
          ) : (
            <>
              {dataToShow?.length ? (
                [...dataToShow]?.map(
                  (contact: IContact | ILead, idx: number) => {
                    return (
                      <ContactItem key={idx}>
                        <RowItem width='120px'>
                          <Paragraph fontWeight='600' color='#272A32'>
                            {contact.phone}
                          </Paragraph>
                        </RowItem>
                        <RowItem width='180px'>
                          <Paragraph fontWeight='400' color='#9297A2'>
                            {contact.firstName} {contact.lastName}
                          </Paragraph>
                        </RowItem>
                        <RowItem width='302px'>
                          <Paragraph fontWeight='600' color='#272A32'>
                            {contact.email}
                          </Paragraph>
                        </RowItem>

                        <RowItem width='154px'>
                          <Button
                            variant='white'
                            text={
                              contact.conversation
                                ? 'Open Conversation'
                                : 'Start Conversation'
                            }
                            onClick={() => {
                              if (!contact.conversation) {
                                startNewConversation({
                                  email: null,
                                  phoneNumber: null,
                                  contactId:
                                    'leadId' in contact
                                      ? contact?.leadId
                                      : contact.contactId,
                                } as IStartConversationBody);
                              } else {
                                history.replace({
                                  pathname: `/conversations/${
                                    !!contact.conversation?.deleted
                                      ? 'deleted'
                                      : 'all'
                                  }/${contact.conversation?.conversationId}`,
                                });
                                onModalCloseHandler();
                              }
                            }}
                          />
                        </RowItem>
                      </ContactItem>
                    );
                  }
                )
              ) : (
                <>
                  <EmptyMsg>
                    {type === SEARCH_CONVERSATIONS_ENUMS.TEXT
                      ? 'No matching contacts. You can type a phone number or email instead.'
                      : 'No matching contacts.'}
                  </EmptyMsg>
                  {type === SEARCH_CONVERSATIONS_ENUMS.PHONE && (
                    <Button
                      text={`+ Start Conversation with ${searchValue}`}
                      onClick={() =>
                        startNewConversation({
                          email: null,
                          phoneNumber: searchValue,
                          contactId: null,
                        } as IStartConversationBody)
                      }
                    />
                  )}
                  {type === SEARCH_CONVERSATIONS_ENUMS.E_MAIL && (
                    <Button
                      text={`+ Start Conversation with ${searchValue}`}
                      onClick={() =>
                        startNewConversation({
                          email: searchValue,
                          phoneNumber: null,
                          contactId: null,
                        } as IStartConversationBody)
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </ModalBody>
      </>
    </NewModal>
  );
};
