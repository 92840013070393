import dayjs from 'dayjs';
import { useSubscriptionQuery } from 'lib/api/billing/useSubscriptionQuery';
import { getManagedUsers } from 'lib/api/users/useManagedUsersQuery';
import { useSendUserOnboardingInviteMutation } from 'lib/api/users/useSendUserOnboardingInvite';
import { Tabs } from 'lib/components';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Heading, ParagraphNormal } from 'lib/components/styles/typography';
import { Tab } from 'lib/components/tabs';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import { getAutomotiveRoleAsString } from 'lib/utils/automotiveRolePermissionChecks';
import { exportCSVFile } from 'lib/utils/functions';
import { getUserAccessAsString } from 'lib/utils/userRoles';
import { isNull } from 'lodash';
import queryString from 'query-string';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { IoIosLogIn } from 'react-icons/io';
import { MdAdd, MdFileDownload } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router';
import styled, { useTheme } from 'styled-components/macro';
import { Department } from './Department';
import { ManageDefaultModal } from './ManageDefaultModal';
import { UserListing } from './UserListing';

const TotalUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  padding: 8px;
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  .hover--ele {
    display: flex;
    gap: 8px;
    position: relative;
    &:hover {
      .manage--user {
        display: flex;
      }
    }
  }
  .manage--user {
    display: none;
    border: 1px solid #f7f8fa;
    box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
    border-radius: 4px;
    background: white;
    width: 350px;
    padding: 20px;
    position: absolute;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    top: 40px;
    right: 0px;
    z-index: 1;
    &:hover {
      display: flex;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

export const exportHeaders = {
  fullName: 'Name',
  email: 'Email',
  username: 'Username',
  access: 'User Type',
  loginDate: 'Last Login',
  timezone: 'Time Zone',
};

export const ListManager = () => {
  const location = useLocation();
  const history = useHistory();
  // TO_DO refactor and move buttons to userListing component -> activeTab == 0
  const [functionUserObj, setFunctionUserObj] = useState({
    user: {
      create: () => {},
      usersValue: { total: 0, active: 0 },
      import: () => {},
    },
  });

  const [usersCount, setUsersCount] = useState(0);

  const [showManageDefaultModal, setShowManageDefaultModal] = useState(false);
  const [showAMModal, setShowAMModal] = useState(false);
  const { mutateAsync: sendOnboardinginvite } =
    useSendUserOnboardingInviteMutation();

  const { data: subscription, isLoading: isSubscriptionLoading } =
    useSubscriptionQuery();
  const [userActiveStatus, setUserActiveStatus] = useState('1');
  const themes = useTheme();
  const { userData } = useAuth();

  const [selectedDept, setSelectedDept] = useState(0);
  const { showError } = useToastError();

  const getActiveTab = (location: any) => {
    const queryParams: any = queryString.parse(location.search);
    if (queryParams.view && queryParams.view == 'department') {
      return 1;
    }
    return 0;
  };

  const handleSendOnboardingInvite = async (userId: number) => {
    await sendOnboardinginvite(userId);
  };

  const [activeTab, setActiveTab] = useState(getActiveTab(location));
  const tabs: Tab[] = [
    {
      title: 'Users',
      component:
        activeTab == 0 ? (
          <UserListing
            setFunctionUserObj={setFunctionUserObj}
            setUserActiveStatus={setUserActiveStatus}
            userActiveStatus={userActiveStatus}
            setDepartment={setSelectedDept}
            showOnboardingInviteOption={true}
            sendOnboardinginvite={handleSendOnboardingInvite}
            setUsersCount={setUsersCount}
          />
        ) : null,
    },
    {
      title: 'Departments',
      component:
        activeTab == 1 ? (
          <Department setFunctionUserObj={setFunctionUserObj} />
        ) : null,
    },
  ];

  const planType = (type: string) => {
    if (
      type == 'legacy' &&
      ((subscription && subscription.plan && !subscription.plan.valid) ||
        (userData.customer && !userData.customer.stripeId) ||
        Object.keys(subscription).length == 1)
    ) {
      return true;
    }
    if (
      type == 'current' &&
      subscription &&
      subscription.plan &&
      subscription.plan.valid
    ) {
      return true;
    }
    if (
      type == 'cancel' &&
      subscription &&
      subscription.cancelAt &&
      !isNull(subscription.cancelAt)
    ) {
      return true;
    }
    return false;
  };

  //As per ticket SUS-22, removing count condition
  /** functionUserObj.user.usersValue.active >=
  functionUserObj.user.usersValue.total; */
  const subscriptionCancelAndExceed = () => {
    const val = planType('cancel');
    return val;
  };

  const exportUserData = async () => {
    try {
      let { userList: allUsers } = await getManagedUsers({
        customerId: userData.customer.customerId,
        page: 0,
        size: usersCount,
        userActiveStatus,
        departmentId: selectedDept,
      });
      allUsers.forEach((user: any) => {
        user.access = getUserAccessAsString(user.access);
        user.loginDate =
          user.loginDate && user.verified
            ? dayjs(new Date(user.loginDate * 1000)).format('MMM DD YYYY')
            : '';

        if (userData.isAutomotive) {
          user.automotiveRole = getAutomotiveRoleAsString(user.automotiveRole);
        }

        user.fullName = `${user.firstName} ${user.lastName}`;
      });

      // @ts-ignore
      exportCSVFile(
        {
          ...exportHeaders,
          ...(userData.isAutomotive && { automotiveRole: 'Automotive Role' }),
        },
        allUsers,
        'managed_users'
      );
    } catch (error) {
      showError(error);
    }
  };

  return (
    <>
      <MainWrapper resetPadding={activeTab ? false : true}>
        {!activeTab && <HelpDesk name={EHelpDesk.MANAGE_USERS} />}

        {/* <ListWrapper> */}
        <HeaderWrapper>
          <LeftHeaderWrapper>
            <Heading>Manage {tabs[activeTab]?.title || ''}</Heading>
            <TotalUser>
              <ParagraphNormal>
                {`${functionUserObj.user.usersValue.active}/${functionUserObj.user.usersValue.total}`}{' '}
                users
              </ParagraphNormal>
            </TotalUser>
          </LeftHeaderWrapper>
          {activeTab == 0 && (
            <ButtonContainer>
              <Button
                variant='secondary'
                text={'Manage Defaults'}
                onClick={() => {
                  setShowManageDefaultModal(true);
                }}
              />
              <Button
                onClick={exportUserData}
                text='Export All'
                icon={<MdFileDownload />}
                variant='secondary'
              />
              <div className='hover--ele'>
                <Button
                  variant='secondary'
                  icon={<IoIosLogIn />}
                  text={'Import'}
                  disabled={
                    subscriptionCancelAndExceed() || isSubscriptionLoading
                  }
                  onClick={() =>
                    (planType('current') && !planType('cancel')) ||
                    planType('cancel') ||
                    planType('legacy')
                      ? functionUserObj.user.import()
                      : planType('legacy')
                        ? history.push('/upgrade')
                        : null
                  }
                />
                <Button
                  icon={<MdAdd color={themes.colors.white[100]} />}
                  text={'Add User'}
                  disabled={
                    subscriptionCancelAndExceed() || isSubscriptionLoading
                  }
                  onClick={() =>
                    (planType('current') && !planType('cancel')) ||
                    ((planType('cancel') || planType('legacy')) &&
                      functionUserObj.user.usersValue.active <
                        functionUserObj.user.usersValue.total)
                      ? functionUserObj.user.create()
                      : planType('legacy')
                        ? setShowAMModal(true)
                        : null
                  }
                />
                {subscriptionCancelAndExceed() ? (
                  <div className='manage--user'>
                    <div style={{ textAlign: 'center' }}>
                      Reactivate subscription to
                      <br /> add more users. Current limit reached.
                    </div>
                    <Button
                      text={`Manage Subscription`}
                      onClick={() => {
                        history.push('/billing');
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </ButtonContainer>
          )}
        </HeaderWrapper>
        <Content>
          <Tabs
            tabs={tabs}
            defaultActiveTab={activeTab}
            onChange={setActiveTab}
          />
        </Content>
        {showManageDefaultModal && (
          <ManageDefaultModal
            handleModalClose={() => {
              setShowManageDefaultModal(false);
            }}
          />
        )}
        {showAMModal && (
          <ModalContactAM
            handleModalClose={() => setShowAMModal(false)}
            subject='Covideo Legacy Plan Update'
            title='Would you like to manage your subscription?'
            description={
              <div>
                To make changes to your subscription, please contact your
                Covideo account manager or fill out the form below.
              </div>
            }
          />
        )}
      </MainWrapper>
    </>
  );
};
