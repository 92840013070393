import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { Field, FieldArray, FieldProps, Form, Formik, getIn } from 'formik';
import { CheckboxInput, Dropdown, TextInput } from 'lib/components';
import { setLockForSocialLink, setSocialLinks } from 'lib/api/designApi';
import { SocialLink } from 'lib/api/socialMediaLinks/types';
import { MdInfo } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { socialLinksOptions, SocialLinksTypes } from './const';
import { BsPlus } from 'react-icons/bs';
import { socialIcons } from './components/SocialIcons';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';

type SocialData = SocialLink & {
  lockedByAdmin: boolean;
};

interface Props {
  data: SocialData;
  isCompanyAdmin: boolean;
  onSubmit: Function;
  setIsSocialLinkDirty: (value: boolean) => void;
  setIsSocialLinkValid: (value: boolean) => void;
  setUpdatedData: (values: FormFields) => void;
}

type HandleSubmitParams = {
  previousLockValue: boolean;
  values: FormFields;
  setSubmitting: (params?: any) => void;
  onSubmit: Function;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  showError: (value: string) => void;
};

export type FormFields = {
  setAllUsers: boolean;
  lockSocialLinks: boolean;
  links?: { value: SocialLinksTypes; label: string; url: string }[];
};

// Styled Components
const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
`;

const CheckboxRow = styled.section`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FormContainer = styled(Form)`
  width: 100%;
`;

const CheckboxLabel = styled.label`
  margin: 0 0 0 12px;

  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgb(39, 42, 50);
`;

const InfoLabel = styled.label`
  margin-left: 8px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: ${theme.palette.coal};
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

const RowWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 64px;
`;

const FieldContainer = styled.div<any>`
  display: grid;
  max-width: 700px;
  ${({ center }) =>
    center &&
    css`
      display: flex;
      max-width: 700px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 40px 400px 40px;
  gap: 16px;
`;

const FieldArrayWrapper = styled.div<any>`
  display: grid;
  gap: 48px;
  margin-bottom: 48px;

  ${({ center }) =>
    center &&
    css`
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: center;
    `}
`;

const AddNewBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const NoLinksMessage = styled.p`
  color: #272a32;
  font-size: ${theme.fontSizes.md};
`;

const FieldArrayHeader = styled.p`
  font-size: ${theme.fontSizes.md};
  color: #9297a2;
  font-weight: 500;
`;

// Constants and Variables
let formFields: FormFields = {
  setAllUsers: false,
  lockSocialLinks: false,
};

Yup.addMethod(Yup.array, 'unique', function (message) {
  return this.test('unique', message, function (list) {
    const mapper = (x: { value: string }) => x.value;

    const isUnique = list.length === new Set(list.map(mapper)).size;
    if (isUnique) {
      return true;
    }
    return this.createError({
      path: 'isLinkUnique',
      message: message,
    });
  });
});

const URL_REGEX =
  /(^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*))|((mailto|tel|sms):([^\?]*))/;

const fieldsSchema = Yup.object({
  links: Yup.array()
    .of(
      Yup.object().shape({
        url: Yup.string()
          .required('Should not be empty')
          .matches(URL_REGEX, 'Enter correct url'),
        value: Yup.string(),
      })
    )
    .unique('Link should be unique and selected'),
});

const loadFields = (data: SocialData) => {
  return {
    whatsApp: data.whatsApp || '',
    pinterest: data.pinterest || '',
    tikTok: data.tikTok || '',
    facebook: data.facebook || '',
    linkedIn: data.linkedIn || '',
    twitter: data.twitter || '',
    bni: data.bni || '',
    edmunds: data.edmunds || '',
    instagram: data.instagram || '',
    youtube: data.youtube || '',
    yelp: data.yelp || '',
    dealerRater: data.dealerRater || '',
    carsdotcom: data.carsdotcom || '',
    zeemee: data.zeemee || '',
    setAllUsers: false,
    lockSocialLinks: data.lockedByAdmin,
  };
};

const formatUrl = (link: string = '') =>
  'https://' + (link || '').trim().replace(/(^\w+:|^)\/\//, '');

export const getFormattedData = (values: FormFields) => {
  const setAllUsers = values.setAllUsers;
  const lockSocialLinks = values.lockSocialLinks;

  const socialNetworksMapper = socialLinksOptions.map(data => data.value);

  const data: any = {};

  socialNetworksMapper.forEach(socialNetwork => {
    const linkItem = values.links?.find(link => socialNetwork === link.value);
    data[socialNetwork] = linkItem ? formatUrl(linkItem.url) : '';
  });

  return { data, setAllUsers, lockSocialLinks };
};

const handleSubmit = async ({
  values,
  previousLockValue,
  setSubmitting,
  onSubmit,
  setFieldValue,
  showError,
}: HandleSubmitParams): Promise<void> => {
  setSubmitting(true);
  const formValues = getFormattedData(values);
  const { lockSocialLinks, ...data } = formValues;

  try {
    const socialLinksRes = await setSocialLinks(data);
    successToast({ title: 'Social Links successfully updated!' });
    const bc = new BroadcastChannel('covideo_social_link_channel');
    bc.postMessage(socialLinksRes?.data);
    setSubmitting(false);
    setFieldValue('setAllUsers', false);
    onSubmit();
  } catch (error) {
    showError(error);
  }

  if (lockSocialLinks !== previousLockValue) {
    try {
      await setLockForSocialLink(lockSocialLinks);
    } catch (error) {
      showError(error);
    }
  }
};

// Component
const SocialLinks = (props: Props) => {
  const {
    data,
    isCompanyAdmin,
    onSubmit,
    setIsSocialLinkDirty,
    setIsSocialLinkValid,
    setUpdatedData,
  } = props;
  const [fields, setFields] = useState({ ...formFields });
  const [options, setOptions] = useState(socialLinksOptions);
  const [unsavedDeletions, setUnsavedDeletions] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { showError } = useToastError();

  useEffect(() => {
    const initialFields = loadFields(data);
    const { setAllUsers, lockSocialLinks, ...socialLinks } = initialFields;

    //set initial dropdown values
    const initialSocialLinkDropdown = socialLinksOptions.filter(
      link => socialLinks[link.value] === ''
    );
    setOptions(initialSocialLinkDropdown);

    //generate links fields by mapping socialLinksOptions
    const fieldLinks: {
      value: SocialLinksTypes;
      label: string;
      url: string;
    }[] = [];
    socialLinksOptions.forEach(option => {
      const optionValue = option.value as SocialLinksTypes;

      const URL = socialLinks[optionValue];
      if (URL) {
        fieldLinks.push({ value: optionValue, label: option.label, url: URL });
      }
    });
    setFields({
      links: fieldLinks,
      setAllUsers,
      lockSocialLinks,
    });
  }, [data]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={fields}
      validationSchema={fieldsSchema}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        setUnsavedDeletions(false);
        handleSubmit({
          previousLockValue: data.lockedByAdmin,
          values,
          setSubmitting,
          onSubmit,
          setFieldValue,
          showError,
        });
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        dirty,
        isValid,
        setFieldValue,
        setFieldError,
        setFieldTouched,
      }) => {
        const doesLinksExist = values.links && values?.links?.length > 0;
        setIsDisabled(!isValid || !dirty);
        setIsSocialLinkValid(isValid);
        setIsSocialLinkDirty(dirty);
        setUpdatedData(values);
        const centerAlignmentCheck =
          values?.links?.length === 0 && unsavedDeletions === false;
        return (
          <FormContainer>
            <FieldArray
              name='links'
              render={arrayHelpers => {
                return (
                  <FieldArrayWrapper center={centerAlignmentCheck}>
                    {isCompanyAdmin && doesLinksExist && (
                      <div>
                        <CheckboxRow>
                          <Field name={'setAllUsers'} readonly={isSubmitting}>
                            {({ field }: FieldProps) => {
                              return (
                                <>
                                  <CheckboxInput
                                    {...field}
                                    checked={field.value}
                                  />
                                  <CheckboxLabel>
                                    Set these social links for all users
                                  </CheckboxLabel>
                                </>
                              );
                            }}
                          </Field>
                        </CheckboxRow>
                        <CheckboxRow>
                          <Field
                            name={'lockSocialLinks'}
                            readonly={isSubmitting}
                          >
                            {({ field }: FieldProps) => {
                              return (
                                <>
                                  <CheckboxInput
                                    {...field}
                                    checked={field.value}
                                  />
                                  <CheckboxLabel>
                                    Prevent users from changing their social
                                    links
                                  </CheckboxLabel>
                                </>
                              );
                            }}
                          </Field>
                        </CheckboxRow>
                      </div>
                    )}
                    <FieldContainer center={centerAlignmentCheck}>
                      {doesLinksExist ? (
                        values?.links?.map((_, index) => {
                          const linkObj = values?.links?.[index];

                          const dropdownValue = linkObj?.value ? linkObj : null;
                          const inputPlaceholder = linkObj?.label;
                          const socialNetwork = linkObj?.value;

                          const inputName = `links.${index}.url`;
                          const isInputTouched = getIn(touched, inputName);
                          const hasInputError = getIn(errors, inputName);

                          return (
                            <React.Fragment key={index}>
                              {index === 0 && (
                                <FieldWrapper>
                                  <FieldArrayHeader>
                                    Social Network
                                  </FieldArrayHeader>
                                  <FieldArrayHeader>Icon</FieldArrayHeader>
                                  <FieldArrayHeader>URL</FieldArrayHeader>
                                </FieldWrapper>
                              )}
                              <FieldWrapper>
                                <Dropdown
                                  menuPortalTarget={document.body}
                                  className={'dropdown'}
                                  value={dropdownValue}
                                  onChange={newValue => {
                                    const newLinks = options.filter(
                                      option => option.value !== newValue.value
                                    );
                                    setFieldValue(`links[${index}]`, newValue);
                                    //reset dropdown field on dropdown change
                                    if (!!linkObj?.value) {
                                      //value exist -> remove current value and add old one
                                      setOptions([
                                        ...newLinks,
                                        {
                                          value: linkObj.value,
                                          label: linkObj.label,
                                        },
                                      ]);
                                    } else {
                                      setOptions(newLinks);
                                    }

                                    //reset input field on dropdown change
                                    setFieldValue(inputName, '');
                                    setFieldError(inputName, undefined);
                                    setFieldTouched(inputName, false);
                                  }}
                                  options={options}
                                  dropdownHeight={'auto'}
                                  height={40}
                                  placeholder='Select...'
                                />
                                <Field
                                  name={inputName}
                                  readOnly={isSubmitting || data.lockedByAdmin}
                                >
                                  {({ field }: FieldProps) => {
                                    return (
                                      <>
                                        {socialIcons({ socialNetwork })}
                                        <RowWrap>
                                          <TextInput
                                            {...field}
                                            type={'text'}
                                            disabled={!inputPlaceholder}
                                          />
                                          {isInputTouched && (
                                            <ErrorMessage className='error'>
                                              {hasInputError}
                                            </ErrorMessage>
                                          )}
                                        </RowWrap>
                                      </>
                                    );
                                  }}
                                </Field>
                                <Button
                                  icon={<IoMdTrash size={22} />}
                                  variant={'destructive'}
                                  data-id={socialNetwork}
                                  onClick={(e: any) => {
                                    const dataAtr =
                                      e.target.getAttribute('data-id');
                                    if (!!dataAtr) {
                                      const newLinks =
                                        socialLinksOptions.filter(
                                          option => option.value === dataAtr
                                        );
                                      setOptions([...options, ...newLinks]);
                                      setUnsavedDeletions(true);
                                    }
                                    arrayHelpers.remove(index);
                                  }}
                                ></Button>
                              </FieldWrapper>
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <NoLinksMessage>
                          You haven’t added any social links.
                        </NoLinksMessage>
                      )}
                      <AddNewBtnWrapper>
                        <Button
                          variant={
                            centerAlignmentCheck ? 'primary' : 'secondary'
                          }
                          text={'New Social Link  '}
                          icon={<BsPlus />}
                          disabled={
                            values?.links?.length === socialLinksOptions.length
                          }
                          onClick={() =>
                            arrayHelpers.push({ label: '', value: '', url: '' })
                          }
                        />

                        <ErrorMessage className='error'>
                          {
                            // @ts-ignore
                            errors?.isLinkUnique
                          }
                        </ErrorMessage>
                      </AddNewBtnWrapper>
                    </FieldContainer>
                  </FieldArrayWrapper>
                );
              }}
            />
            {doesLinksExist === false && unsavedDeletions && (
              <NoLinksMessage>You have unsaved changes.</NoLinksMessage>
            )}
            {(doesLinksExist || unsavedDeletions) && (
              <ButtonContainer>
                {data.lockedByAdmin && !isCompanyAdmin ? (
                  <>
                    <MdInfo size={24} color={theme.palette.label} />
                    <InfoLabel>
                      Only a Company Admin can change Social Links
                    </InfoLabel>
                  </>
                ) : (
                  <Button
                    disabled={!!isDisabled}
                    text={'Save Social Links'}
                    type={'submit'}
                  />
                )}
              </ButtonContainer>
            )}
          </FormContainer>
        );
      }}
    </Formik>
  );
};

export default SocialLinks;
