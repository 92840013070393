import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewModal } from 'lib/components';
import { VideoTag } from 'lib/components';
import { droplrModalsSelectStyle } from 'lib/style/commonStyles';
import { useGetDrop } from 'lib/api/droplr/getDrop';
import { useEditDrop } from 'lib/api/droplr/editDrop';
import { useGetUserTags } from 'lib/api/droplr/getUserTags';
import { useIsMutating } from 'react-query';
import { droplrKeys } from 'lib/api/droplr/queryKeys';
import { Button } from 'react-covideo-common';

const SelectInput = styled(CreatableSelect)`
  position: relative;
  display: block;
  width: 528px;
  font-size: 16px;
  height: 40px;
  box-sizing: border-box;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border: none;
  box-sizing: border-box;
  &:focus {
    color: #495057;
    background-color: ${theme.palette.white};
    outline: 0;
  }
`;

const TagsContainer = styled.div`
  height: 250px;
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  flex-flow: row wrap;
  align-content: flex-start;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  dropIds: string[];
  handleModalClose: () => void;
};

type Tag = {
  label: string;
  value: string;
};

export const TagsModal = ({ handleModalClose, dropIds }: Props) => {
  const [inputValue, onInputChange] = useState('');
  const [tags, setTags] = useState([] as Tag[]);
  const [selectedValues, setSelectedValues] = useState([] as Tag[]);
  const isSingularDrop = dropIds.length === 1;

  const { data: drop, refetch: refetchDrop } = useGetDrop({
    dropId: isSingularDrop ? dropIds[0] : '',
  });

  const { data: userTags, isLoading: areTagsLoading } = useGetUserTags();

  const { mutateAsync: editDrop } = useEditDrop();
  const isLoading =
    useIsMutating({
      mutationKey: droplrKeys.edit_drop_mutation(),
    }) > 0;

  useEffect(() => {
    if (!areTagsLoading && userTags) {
      setTags(
        (userTags.tags || []).map((tag: string) => ({
          label: tag,
          value: tag,
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (drop?.tags?.length) {
      setSelectedValues(
        drop.tags.map((tag: string) => ({
          label: tag,
          value: tag,
        }))
      );
    }
  }, [drop]);

  const removeTag = (tag: Tag) => {
    setSelectedValues(selectedValues.filter((t: Tag) => t.value !== tag.value));
  };

  const onChange = (newTags: Tag[]) => {
    setSelectedValues(newTags);
  };

  const updateTags = async () => {
    for (let dropId of dropIds) {
      await editDrop({
        dropId,
        tags: selectedValues.map(tag => tag.value),
      });
    }
    if (isSingularDrop) refetchDrop();
  };

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText='Manage Tags'
      style={{
        content: {
          minWidth: '528px',
          width: '528px',
          height: '400px',
        },
      }}
    >
      <div>
        <SelectInput
          inputValue={inputValue}
          onInputChange={onInputChange}
          styles={droplrModalsSelectStyle}
          placeholder={'Add tag(s)...'}
          value={selectedValues}
          onChange={onChange}
          options={tags}
          noOptionsMessage={() => null}
          isMulti={true}
          clearable={false}
          optionHeight={40}
        />

        <TagsContainer>
          {selectedValues &&
            !!selectedValues.length &&
            selectedValues.map((tag: Tag) => (
              <VideoTag
                key={tag.value}
                tag={tag}
                showRemove={true}
                onRemove={removeTag}
              />
            ))}
        </TagsContainer>

        <Footer>
          <Button
            onClick={async () => {
              await updateTags();
              handleModalClose();
            }}
            text='Save'
            type='submit'
            disabled={isLoading}
          />
        </Footer>
      </div>
    </NewModal>
  );
};
