import {
  addHours,
  addMonths,
  addOneYear,
  calculateDaysBetween,
} from 'lib/utils/datetime';

const initStartDate = new Date();

export const EXTEND_DELETION_TYPES = {
  '1_DAY': '1_DAY',
  '1_WEEK': '1_WEEK',
  '2_WEEKS': '2_WEEKS',
  '1_MONTH': '1_MONTH',
  '3_MONTHS': '3_MONTHS',
  '6_MONTHS': '6_MONTHS',
  '1_YEAR': '1_YEAR',
  FOREVER: 'FOREVER',
  CUSTOM: 'CUSTOM',
};

export const dropdownItems = [
  {
    id: EXTEND_DELETION_TYPES['1_DAY'],
    value: calculateDaysBetween(addHours(new Date(), 24)),
    label: '1 day',
  },
  {
    id: EXTEND_DELETION_TYPES['1_WEEK'],
    value: calculateDaysBetween(addHours(new Date(), 168)),
    label: '1 week',
  },
  {
    id: EXTEND_DELETION_TYPES['2_WEEKS'],
    value: calculateDaysBetween(addHours(new Date(), 336)),
    label: '2 weeks',
  },
  {
    id: EXTEND_DELETION_TYPES['1_MONTH'],
    value: calculateDaysBetween(addMonths(new Date(), 1)),
    label: '1 month',
  },
  {
    id: EXTEND_DELETION_TYPES['3_MONTHS'],
    value: calculateDaysBetween(addMonths(new Date(), 3)),
    label: '3 months',
  },
  {
    id: EXTEND_DELETION_TYPES['6_MONTHS'],
    value: calculateDaysBetween(addMonths(new Date(), 6)),
    label: '6 months',
  },
  {
    id: EXTEND_DELETION_TYPES['1_YEAR'],
    value: calculateDaysBetween(addOneYear(new Date())),
    label: '1 year',
  },
  {
    id: EXTEND_DELETION_TYPES.FOREVER,
    value: null,
    label: 'Forever',
  },
  {
    id: EXTEND_DELETION_TYPES.CUSTOM,
    value: calculateDaysBetween(initStartDate),
    label: 'Set custom date...',
  },
];
