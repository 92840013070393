import { UsernameExistsParams } from './types';
import { useQuery } from 'react-query';
import { usersKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getUsernameExists = async (params: UsernameExistsParams) => {
  return (
    await EXPRESS_API.get(`users/username-exists`, {
      params,
    })
  ).data as { userExist: boolean };
};

export const useUsernameExists = (params: UsernameExistsParams) => {
  return useQuery(usersKeys.username_exists(params), () =>
    getUsernameExists(params)
  );
};
