import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { metricsKeys } from './metricsKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import dayjs from 'dayjs';
import { ReportTypes } from 'lib/const/ReportData';
import { REPORT_SCOPE } from '../reportsApi';

const DATE_FORMAT = 'YYYY-MM-DD';

export type CustomersMetricsQueryParams = {
  from: Date;
  to: Date;
  start: number;
  limit: number;
  search: string;
  sort: string;
  order: string;
  customerIds: string[];
  regionId: number;
  organizationRegionId: number;
  reportScope: REPORT_SCOPE;
  organizationId?: string | number;
  reports?: ReportTypes[];
};

export type MetricsCustomer = {
  customerId: number;
  business: string;
  recorded: string;
  views: string;
  active: string;
  users: string;
};

interface UsersMetricsResponse {
  count: number;
  companies: MetricsCustomer[];
}

export const getCustomersMetrics = async (
  query: CustomersMetricsQueryParams
): Promise<UsersMetricsResponse> => {
  const params = {
    ...query,
    from: dayjs(query.from).format(DATE_FORMAT),
    to: dayjs(query.to).format(DATE_FORMAT),
    timezoneOffset: new Date().getTimezoneOffset(),
  };
  const response = await EXPRESS_API.get(`/metrics/companies`, {
    params,
  });
  return response.data;
};

export const useGetCustomersMetrics = (
  params: CustomersMetricsQueryParams,
  enabled: boolean
) => {
  const { showError } = useToastError();
  return useQuery(
    metricsKeys.customers(params),
    () => getCustomersMetrics(params),
    {
      onError: showError,
      refetchOnMount: true,
      enabled,
    }
  );
};
