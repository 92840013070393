import { useFormikContext } from 'formik';
import { ButtonPillSwitch } from 'lib/components';
import React from 'react';
import styled from 'styled-components/macro';
import { CustomReportModalFormikValues } from '../types';
import { timeRangeConstants } from '../const';
import { FREQUENCY_DETAIL, FREQUENCY_TYPE } from './constants';

const Title = styled.h3`
font-family: Work Sans;
font-size: 12px;
font-weight: 600;
line-height: 24px;
text-align: left;
color:${({ theme }) => theme.colors.neutral[80]}
margin-bottom:16px;

`;

interface IProps {
  setFrequencyType: React.Dispatch<React.SetStateAction<FREQUENCY_TYPE>>;
  frequencyType: FREQUENCY_TYPE;
  updateRangeValue: (rangeValue: string) => void;
}
export const SelectFrequency = ({
  setFrequencyType,
  frequencyType,
  updateRangeValue,
}: IProps) => {
  const { setFieldValue } = useFormikContext<CustomReportModalFormikValues>();

  const onChangeHandler = (newTab: FREQUENCY_TYPE) => {
    setFrequencyType(newTab);
    if (newTab === FREQUENCY_TYPE.DAILY) {
      setFieldValue(
        'recurrings.frequency',
        `${FREQUENCY_TYPE.DAILY};${FREQUENCY_DETAIL.EVERYDAY}`
      );
      updateRangeValue(timeRangeConstants.TODAY);
    }
    if (newTab === FREQUENCY_TYPE.WEEKLY) {
      setFieldValue('recurrings.frequency', `${FREQUENCY_TYPE.WEEKLY};`);
      updateRangeValue(timeRangeConstants.LAST_WEEK);
    }
    if (newTab === FREQUENCY_TYPE.MONTHLY) {
      setFieldValue('recurrings.frequency', `${FREQUENCY_TYPE.MONTHLY};1`);
      updateRangeValue(timeRangeConstants.LAST_MONTH);
    }
  };

  return (
    <div>
      <Title>Frequency</Title>
      <ButtonPillSwitch
        defaultValue={frequencyType}
        values={FREQUENCY_TYPE}
        onChange={newTab => onChangeHandler(newTab as FREQUENCY_TYPE)}
        pillStyle={{ width: 'calc(100% / 3 - 3px)' }}
        textStyle={{ width: '50%', textTransform: 'capitalize' }}
      />
    </div>
  );
};
