import {
  DuplicateVideoApiParams,
  TranscriptionData,
  TranscriptionResponse,
  TranslationData,
  TrimVideoData,
  VideoListItem,
  VideoListParams,
  MoveVideoApiParams,
} from '.';

import { saveAs } from 'file-saver';
import { ResponseType } from 'axios';
import { Folder, GetFolderParams } from 'lib/api/folders/getFolders';
import { ALL_FOLDERS } from '../const/Folders';
import { EXPRESS_API } from 'configs/api/expressApi';

interface ResponseData {
  recipient: Recipient;
  message: Message;
  shorturl: string;
}
interface Recipient {
  receiverId: string;
  videoId: string;
  emailAddress: string;
  dateSent: string;
  sentItemId: number;
}
interface Message {
  sentItemId: string;
  messageId: string;
  userId: string;
  videoId: string;
  subject: string;
  originalMessage: string;
  body: string;
  designId: string;
  emailIconId: string;
  isEmailTemplate: string;
  templateLinksGroupId: string;
  footerId: string;
  vin: string;
  notify: string;
  attachments: string;
  templateName: string;
  linksetName: string | undefined;
  html: string;
}

export const getTranscriptionInfo = async (videoId: string) => {
  let resp: TranscriptionData = {
    videoId: videoId,
    userId: '',
    originalTranscript: '',
    modifiedTranscript: '',
    enabled: 0,
    status: '',
  };
  const response = await EXPRESS_API.get(`/videos/${videoId}/transcripts`);
  if (response.data as TranscriptionResponse) {
    return response.data;
  }
  return resp;
};

export const getTranslationInfo = async (
  videoId: string,
  userId: string,
  language: string
) => {
  let resp: TranslationData = {
    videoId,
    userId,
    translatedText: '',
  };

  const response = await EXPRESS_API.get('/transcripts', {
    params: { language, userId, videoId },
  });
  if (response.data as TranslationData) {
    return response.data;
  }
  return resp;
};

export const createFolder = async (body: any) => {
  const response = await EXPRESS_API.post('/folders', body);
  return response.data;
};

export const updateFolder = async (folderId: number, body: any) => {
  const response = await EXPRESS_API.put(`/folders/${folderId}`, body);
  return response.data;
};

export const deleteFolder = async (folderId: number, data: any) => {
  const response = await EXPRESS_API.delete(`/folders/${folderId}`, {
    data,
  });
  return response.data;
};

export const getFolders = async (
  params?: GetFolderParams
): Promise<Folder[]> => {
  const response = await EXPRESS_API.get('/folders/new', { params });
  const folders: Folder[] = response?.data?.folders || [];
  folders.push({
    folderId: ALL_FOLDERS.value,
    name: ALL_FOLDERS.label,
    isDeletable: false,
  });
  return folders;
};

export const getVideos = async (props: VideoListParams) => {
  const start = (props.page || 0) * (props.size || 10);
  const params = {
    start,
    limit: props.size,
    search: props.search,
    folder: props.folder,
    sort: props.sort,
    userId: props.filter ? props.filter.userId : '',
    advisorId: props.filter ? props.filter.advisorId : '',
    videoType: props.filter ? props.filter.videoType : '',
    status: props.filter ? props.filter.status : '',
    emailStatus: props.filter ? props.filter.emailStatus : '',
  };
  const response = await EXPRESS_API.get('/videos', { params });
  const { data } = response;
  const videos: VideoListItem[] =
    (data && data.data && data.data.videos) || (data && data.videos) || [];
  const count: number =
    (data && data.data && data.data.count) || (data && data.count) || 0;
  return { count, videos };
};

export const trimVideo = async (videoId: string, body: TrimVideoData) => {
  const response = await EXPRESS_API.put(`/videos/${videoId}/trim`, body);
  return response.data;
};

export const createVideoAccessLog = async (videoId: string | number) => {
  const response = await EXPRESS_API.post(`/video-access-log`, {
    videoId,
  });
  return response.data;
};

export const getVideoPassword = async (videoId: string) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}/security`);
  return response.data;
};

export const updateVideoPassword = async (
  videoId: string,
  password: string
) => {
  const response = await EXPRESS_API.post(`/videos/${videoId}/security`, {
    password,
  });
  return response.data;
};

export const deleteVideoPassword = async (
  videoId: string,
  passwordId: string
) => {
  const response = await EXPRESS_API.delete(
    `/videos/${videoId}/security/${passwordId}`
  );
  return response.data;
};

export const moveVideo = async (videoId: string, data: MoveVideoApiParams) => {
  const response = await EXPRESS_API.put(`videos/${videoId}/move`, data);
  return response.data;
};

export const downloadVideo = async (videoId: string, fileName: string) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}/download`, {
    responseType: 'blob' as ResponseType,
  });
  return saveAs(new Blob([response.data], { type: 'video/mp4' }), fileName);
};

export const addTranscriptionInfo = async (
  videoId: string,
  transcriptionData: any,
  onUploadProgress: any
) => {
  const response = await EXPRESS_API.post(
    `/videos/${videoId}/transcripts`,
    transcriptionData,
    { onUploadProgress }
  );
  return response.data;
};

export const deleteTranscriptionInfo = async (videoId: string) => {
  const response = await EXPRESS_API.delete(`/videos/${videoId}/transcripts`);
  return response.data;
};

export const duplicateVideo = async ({
  videosToDuplicate,
}: DuplicateVideoApiParams): Promise<string> => {
  const duplicateMultiple: boolean = videosToDuplicate.length > 1;
  const videoIds: string[] = duplicateMultiple
    ? (videosToDuplicate as number[]).map((id: number) => `${id}`)
    : (videosToDuplicate as string[]);

  let data = undefined;
  let url = `/videos/${videoIds[0]}/duplicate`;
  if (duplicateMultiple) {
    url = '/videos/duplicate';
    data = { ids: videoIds };
  }
  const response = await EXPRESS_API.post(url, data);
  return response.data;
};

export const getVideosCount = async () => {
  const response = await EXPRESS_API.get('/videos/count/all');
  if (response?.data?.count) {
    if (window.Intercom) {
      window.Intercom('update', {
        num_videos: response.data.count,
      });
    }
  }
  return response.data;
};

export const getRecipientsMessageData = async (
  videoId: string,
  recipientId: number
): Promise<ResponseData | any> => {
  // any is used because return type of useQuery can be undefined.

  const response = await EXPRESS_API.get(
    `/videos/${videoId}/messages/${recipientId}`
  );
  return response.data;
};

export const pinVideos = async (
  videoIds: number[],
  folderName: string
): Promise<ResponseData | any> => {
  const response = await EXPRESS_API.patch(`/videos/pin`, {
    videoIds,
    folderName,
  });
  return response.data;
};

export const unpinVideos = async (
  videoIds: number[],
  folderName: string
): Promise<ResponseData | any> => {
  const response = await EXPRESS_API.patch(`/videos/unpin`, {
    videoIds,
    folderName,
  });
  return response.data;
};
