import React from 'react';
import { useAuth } from 'lib/context';
import { Route, Switch } from 'react-router-dom';
import { Integrations } from './Integrations';
import { checkIfSupervisorWithCDS } from 'lib/utils/automotiveRolePermissionChecks';
import { INTEGRATION_TYPE } from './main/constants';
import { useIntegrationTypeFilter } from './main/useIntegrationFilterType';

export const IntegrationRoutes = () => {
  const { userData } = useAuth();
  const isAutomotive = userData.isAutomotive;
  const filter = useIntegrationTypeFilter();
  const integrations = isAutomotive
    ? filter([INTEGRATION_TYPE.AUTOMOTIVE])
    : filter([INTEGRATION_TYPE.UNIVERSAL]);

  /* SUS-1234 changes integrations route are not accessible to Supervisor CDS user  */
  const isSupervisiorWithCDS = checkIfSupervisorWithCDS(userData);

  return (
    <Switch>
      {!isSupervisiorWithCDS && (
        <Route path='/integrations' component={Integrations} exact />
      )}
      {integrations.map(
        ({ path, component, enabled }) =>
          enabled && (
            <Route key={path} exact path={path} component={component} />
          )
      )}
    </Switch>
  );
};
