import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { ButtonSwitch, Dropdown, Modal } from 'lib/components';
import { URL_REGEX } from 'lib/utils/regexes';
import { QRCodeLibraryModal } from './QRCodeLibraryModal';
import QRCode from 'qrcode.react';
import { createQRVideo, updateQRVideo } from '../../api/qrVideosApi';
import {
  getCTASets,
  getLandingPages,
  getWebsiteOverlays,
  QRVideo,
} from '../../api';
import { keyBy } from 'lodash';
import { wrapWithHttp } from '../../utils/functions';
import { useAuth } from 'lib/context'; /***SUS-826 changes***/
import { getBoards } from 'lib/api/droplr/getBoards';
import { getDrop } from 'lib/api/droplr/getDrop';
import { Button } from 'react-covideo-common';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { IoMdClose } from 'react-icons/io';
import { MdDownload, MdEdit, MdOutlineQrCode2 } from 'react-icons/md';
import selectors from '../../../cypress/selectors';
interface Props {
  onClose: (params?: any) => void;
  onMainButtonClick: (params?: any) => void;
  data?: any;
  modalLoading: boolean;
  type: 'add' | 'edit';
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-bottom: 8px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 90%;
  padding: 16px;
`;

const Text = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.coal};
  margin: 0;
  padding: 0;
`;

const InputLabels = styled(Text)`
  color: #9297a2;

  font-weight: 600;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  padding: 0;
  margin-left: 1.5%;
  margin-top: 2%;

  font-weight: 600;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const QRCodePlaceHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 264px;
  height: 264px;
  margin-bottom: 24px;
  padding: 25px 16px;
  position: relative;
  background: #ffffff;
  border: 4px solid #f2f4f6;
  box-sizing: border-box;
  border-radius: 5px;
`;

const QRCodeUrl = styled.div`
  width: 264px;
  margin: auto;
  text-align: center;
  a {
    word-break: break-all;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #001b53;
  }
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  margin-top: 2%;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const TextInput = styled.input`
  padding-left: 8px;
  height: 35px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #272a32;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  cursor: pointer;
  height: 40px;
  width: 100% !important;
  position: relative;
`;

type ThumbnailProps = {
  image: string;
};

const FilesThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 64px;
  height: 32px;
  flex-shrink: 0;
  margin-left: 4px;
`;

const FilesTitle = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownContainer = styled.div`
  width: 100%;
`;
type HoverContentProps = {
  width: string;
};
const HoverContent = styled.div<HoverContentProps>`
  display: none;
  position: absolute;
  bottom: 125%;
  left: 150%;
  transform: translateX(-50%);
  width: ${props => props.width};
  padding: 8px 12px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
  border-radius: 4px;
  z-index: 3;
`;

const HoverElement = styled.div`
  position: relative;
  cursor: pointer;
  &:hover ${HoverContent} {
    display: block;
  }
`;

const DownloadIconWrapper = styled.div`
  position: relative;
  top: 8%;
  left: 135%;
  background: #eeeff2;
  border-radius: 5px;
  width: 32px;
  height: 32px;
  margin-top: 10px;
  svg {
    padding: 4px;
  }
`;

const ctaTypes = [
  {
    value: 'library',
    text: 'Your Library',
  },
  {
    value: 'url',
    text: 'Custom URL',
  },
];

type Links = {
  [index: string]: string;
};

const vidmailsLink: Links = {
  Covideo: 'https://vidmails.com',
  App2Vid: 'https://watch.app2vid.com',
};

export const ModalAddOrEditQrCodes = (props: Props) => {
  const { data, onClose, onMainButtonClick, modalLoading, type } = props;
  const { userData, whitelabel } = useAuth();

  const [webTitle, setWebTitle] = React.useState(data ? data.title : '');
  const [url, setUrl] = React.useState(
    data?.video || data?.itemType ? '' : data?.qrUrl || ''
  );
  const [fileUrl, setFileUrl] = React.useState(
    data?.video || data?.itemType ? data?.qrUrl || '' : ''
  );
  const [validUrl, setValidUrl] = React.useState(false);
  const [touched, setTouched] = React.useState(false);
  const [overlayType, setOverlayType] = React.useState(
    data?.video || data?.itemType ? 'library' : 'url'
  );
  const [showFilesModal, setShowFilesModal] = React.useState(false);
  const [fileTitle, setFileTitle] = React.useState(data?.video?.title || '');
  const [fileId, setFileId] = React.useState(data?.videoId || -1);
  const [fileThumbnail, setFileThumbnail] = React.useState(
    data?.video?.animatedGifPath || ''
  );
  const [itemType, setItemType] = React.useState(data?.itemType || '');
  const title = type === 'add' ? 'New QR Code' : 'Edit QR Code';
  const [isVideoDrop, setIsVideoDrop] = useState(!!data?.videoId || false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [newData, setNewData] = useState<QRVideo | undefined>(undefined);

  const [template, setTemplate] = useState(
    (data?.template
      ? { value: data.template.id, label: data.template.title }
      : null) as any
  );
  const [overlay, setOverlay] = useState(
    (data?.overlay
      ? { value: data.overlay.id, label: data.overlay.title }
      : null) as any
  );
  const [cta, setCta] = useState(
    (data?.cta ? { value: data.cta.id, label: data.cta.title } : null) as any
  );
  const [linksets, setLinksets] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [overlays, setOverlays] = useState([{ value: 0, label: '' }]);
  const [loading, setLoading] = useState(false);
  const isOverlayType = overlayType === 'url';
  const [vidmailsUrl, setVidmailsUrl] = useState(
    type === 'add'
      ? ''
      : `${
          vidmailsLink[whitelabel.name || WHITELABEL_NAME.COVIDEO]
        }/new_backoffice/qr.php?cvx=${data.url}&qox=slc`
  );

  const themes = useTheme();

  const fallBackThumbnail =
    'https://www.covideo.com/images/boards/folderSet.svg';
  const qrRef: any = useRef();

  const buttonText = () => {
    if (type !== 'add' || (type === 'add' && vidmailsUrl && unsavedChanges)) {
      return 'Save Changes';
    }

    if (type === 'add' && !vidmailsUrl) {
      return 'Generate QR Code';
    }

    return 'Close';
  };

  const fetchCTA = async () => {
    const res = await getCTASets({
      start: 0,
      limit: 0,
      search: '',
    }).catch(err => err);
    if (res instanceof Error) {
      return;
    }
    const { linksets, default: defaultCta } = res;
    setLinksets(
      (linksets || []).map((l: any) => ({ value: l?.id, label: l?.title }))
    );
    // set default cta
    type === 'add' &&
      defaultCta &&
      setCta({
        value: defaultCta.id,
        label: defaultCta.title,
      });
  };

  const fetchTemplates = async () => {
    const res = await getLandingPages({
      start: 0,
      limit: 500,
      search: '',
    }).catch(err => err);
    if (res instanceof Error) {
      return;
    }
    const { templates, default: defaultLandingPage } = res;
    const templatesDictonary = keyBy(templates, 'id');
    if (!templatesDictonary[-1]) {
      templates.unshift({
        id: -1,
        title: 'Website Overlay',
        thumbnailURL:
          'https://www.covideo.com/SampleTemplates/WebsiteOverlay_small.jpg',
        typeId: 18,
      });
    }
    setTemplates(
      (templates || []).map((t: any) => ({ value: t?.id, label: t?.title }))
    );
    // set default template
    type === 'add' &&
      defaultLandingPage &&
      setTemplate({
        value: defaultLandingPage.id,
        label: defaultLandingPage.title,
      });
  };

  const fetchOverlays = async () => {
    try {
      const res = await getWebsiteOverlays({
        start: 0,
        limit: 0,
        search: '',
      });
      const { websiteUrls, default: defaultWebsiteOverlay } = res;
      setOverlays(
        (websiteUrls || []).map((w: any) => ({ value: w?.id, label: w?.title }))
      );
      // set default overlay
      type === 'add' &&
        defaultWebsiteOverlay &&
        setOverlay({
          value: defaultWebsiteOverlay.id,
          label: defaultWebsiteOverlay.title,
        });
    } catch (error) {
      setOverlay(null);
    }
  };

  useEffect(() => {
    fetchCTA();
    fetchTemplates();
    fetchOverlays();
  }, []);

  useEffect(() => {
    if (data) {
      setValidUrl(URL_REGEX.test(data.qrUrl));
    }
  }, []);

  const selectFile = (drop: any) => {
    setFileUrl(drop.shortlink || (drop.videoSource as string));
    setValidUrl(true);
    setFileTitle(drop.title || drop.name);
    drop.recordDate !== undefined
      ? setIsVideoDrop(true)
      : setIsVideoDrop(false);
    setFileThumbnail(
      drop.previewSmall ||
        drop.thumbnailUrl ||
        drop.content ||
        drop.thumbnail ||
        fallBackThumbnail
    );
    setFileId(drop.id);
  };

  const loadFile = async () => {
    try {
      const drop = await getDrop(data.qrUrl.split('/').slice(-1));
      selectFile(drop);
    } catch (e) {
      console.log(e);
    }
  };

  const loadBoard = async () => {
    try {
      const { boards } = await getBoards({
        userId: Number(userData.id),
        customerId: Number(userData.customerId),
      });
      const board = (boards || []).find((r: any) => r.shortlink == data.qrUrl);
      selectFile(board);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!data || !data.itemType) {
      return;
    }
    if (data.itemType === 'file') {
      loadFile();
    }
    if (data.itemType === 'board') {
      loadBoard();
    }
  }, [data?.itemType]);

  const handleUrlInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.currentTarget.value);
    if (URL_REGEX.test(e.currentTarget.value)) {
      setValidUrl(true);
      setUnsavedChanges(true);
    } else {
      setValidUrl(false);
    }
    setTouched(true);
  };

  const handleAddOrEdit = async () => {
    let qrData = {
      title: webTitle,
      qrUrl: wrapWithHttp(isOverlayType ? url : fileUrl),
      videoId: !isOverlayType && itemType === 'video' ? fileId : -1,
      itemType: (!isOverlayType && itemType) || null,
      designId: isOverlayType ? -1 : (template && template.value) || -1,
      templateLinks: isOverlayType ? -1 : (cta && cta.value) || -1,
      overlayUrlId: isOverlayType ? -1 : (overlay && overlay.value) || -1,
      qrType: !isOverlayType && itemType === 'video' ? 0 : 1,
    } as any;

    if (type === 'add' && !newData) {
      setLoading(true);
      if (vidmailsUrl) {
        onClose();
        return;
      }
      const response = await createQRVideo(qrData);
      setLoading(false);
      setVidmailsUrl(
        `${
          vidmailsLink[whitelabel.name || WHITELABEL_NAME.COVIDEO]
        }/new_backoffice/qr.php?cvx=${response.url}&qox=slc`
      );
      setUnsavedChanges(false);
      setNewData(response);
    } else if (data) {
      qrData = {
        ...data,
        ...qrData,
      };
      delete qrData.video;
      delete qrData.cta;
      delete qrData.overlay;
      delete qrData.template;
      await updateQRVideo(qrData);
    } else if (newData) {
      if (!unsavedChanges) {
        onClose();
        return;
      }

      qrData = {
        ...newData,
        ...qrData,
      };

      delete qrData.video;
      delete qrData.cta;
      delete qrData.overlay;
      delete qrData.template;
      const response = await updateQRVideo(qrData);
      setUnsavedChanges(false);
      setNewData(response);
    }
    onMainButtonClick();
  };

  const downloadQRCode = (evt: React.FormEvent) => {
    evt.preventDefault();
    // @ts-ignore
    let canvas = qrRef.current.querySelector('canvas');
    let image = canvas.toDataURL('image/png');
    let anchor = document.createElement('a');
    anchor.href = image;
    anchor.download = `covideo-qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const qrCode = (
    <QRCode
      id='qrCodeElToRender'
      size={500}
      value={vidmailsUrl}
      bgColor='white'
      fgColor='#141926'
      level='H'
      style={{
        width: '90%',
        height: '70%',
        marginTop: '20%',
        marginLeft: '33%',
      }}
    />
  );

  return (
    <>
      <Modal widthOnDesktop={'55%'} overrideBodyHeight={true}>
        {showFilesModal && (
          <QRCodeLibraryModal
            selectedFile={{
              fileTitle,
              fileThumbnail,
              linkValue: url,
              itemType,
            }}
            handleModalClose={() => {
              setShowFilesModal(false);
            }}
            onSelect={(drop: any, itemType: string) => {
              selectFile(drop);
              setItemType(itemType);
              setShowFilesModal(false);
              setUnsavedChanges(true);
            }}
          />
        )}
        <Content>
          {modalLoading && (
            <LoaderWrapper>
              <MoonLoader
                size={40}
                color={themes.colors.primary[100]}
                loading={true}
              />
            </LoaderWrapper>
          )}
          <Row justifyContent={'space-between'}>
            <Title>{title}</Title>
            <CloseIconWrap>
              <IoMdClose
                size={24}
                onClick={onClose}
                color={theme.palette.midGrey}
              />
            </CloseIconWrap>
          </Row>
          <Row>
            <Col>
              <form
                id='formTestOverlay'
                name='formTestOverlay'
                method='post'
                action={`https://${
                  whitelabel.domainV2 || 'www.covideo.com'
                }/backoffice/Test_Overlay.php`}
                target='_blank'
                style={{
                  width: '100%',
                  height: '380px',
                }}
              >
                <Row>
                  <InputLabels>Title</InputLabels>
                </Row>
                <Row>
                  <TextInput
                    placeholder={'QR Code Title'}
                    value={webTitle}
                    type={'text'}
                    onChange={e => {
                      setWebTitle(e.currentTarget.value);
                      setUnsavedChanges(true);
                    }}
                  />
                </Row>
                <Row>
                  <ButtonSwitch
                    defaultValue={overlayType || 'url'}
                    values={ctaTypes}
                    onChange={newValue => {
                      if (newValue === 'url') {
                        setValidUrl(URL_REGEX.test(url));
                      } else {
                        setValidUrl(!!fileId);
                      }

                      setOverlayType(newValue);
                    }}
                  />
                </Row>
                {overlayType === 'library' && (
                  <Row>
                    <div
                      style={{
                        height: '40px',
                        width: '269px',
                        boxSizing: 'border-box',
                        marginLeft: 0,
                      }}
                    >
                      {!fileTitle && (
                        <Button
                          variant='secondary'
                          text={
                            !userData.customer.droplrEnabled ||
                            !userData.droplrAccess
                              ? 'Select Video'
                              : 'Select Video, File or Board...'
                          }
                          onClick={() => {
                            setShowFilesModal(true);
                          }}
                          data-cy={selectors.qrCodePage.selectVideoFileOrBoard}
                        />
                      )}
                      {fileTitle && (
                        <>
                          <FileWrapper onClick={() => setShowFilesModal(true)}>
                            <FilesThumbnail image={fileThumbnail} />
                            <FilesTitle>{fileTitle}</FilesTitle>
                            <input
                              style={{ display: 'none' }}
                              value={fileUrl}
                              id='txt_URL_Text'
                              name='txt_URL_Text'
                            />
                            <MdEdit
                              size='18px'
                              color='#9297A2'
                              style={{
                                position: 'absolute',
                                right: '12px',
                              }}
                            />
                          </FileWrapper>
                          {isVideoDrop && (
                            <>
                              <Row>
                                <InputLabels style={{ marginTop: '2%' }}>
                                  Landing Page
                                </InputLabels>
                              </Row>
                              <Row marginBottom={2}>
                                <DropdownContainer>
                                  <Dropdown
                                    value={template}
                                    placeholder={'Landing Page'}
                                    onChange={newValue => {
                                      setTemplate(newValue);
                                      setUnsavedChanges(true);
                                    }}
                                    options={templates}
                                    menuPlacement='top'
                                    height={35}
                                  />
                                </DropdownContainer>
                              </Row>
                              {template &&
                                template.label.includes('Overlay') && (
                                  <>
                                    <Row>
                                      <InputLabels style={{ marginTop: '2%' }}>
                                        Overlay URL
                                      </InputLabels>
                                    </Row>
                                    <Row>
                                      <DropdownContainer>
                                        <Dropdown
                                          value={overlay}
                                          placeholder={'Website Overlay'}
                                          onChange={newValue => {
                                            setOverlay(newValue);
                                            setUnsavedChanges(true);
                                          }}
                                          options={overlays}
                                          menuPlacement='top'
                                          height={35}
                                        />
                                      </DropdownContainer>
                                    </Row>
                                  </>
                                )}
                              {(!template ||
                                !template.label.includes('Overlay')) && (
                                <>
                                  <Row>
                                    <InputLabels style={{ marginTop: '2%' }}>
                                      CTA
                                    </InputLabels>
                                  </Row>
                                  <Row>
                                    <DropdownContainer>
                                      <Dropdown
                                        value={cta}
                                        placeholder={'Call To Action'}
                                        onChange={newValue => {
                                          setCta(newValue);
                                          setUnsavedChanges(true);
                                        }}
                                        options={linksets}
                                        menuPlacement='top'
                                        height={35}
                                      />
                                    </DropdownContainer>
                                  </Row>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Row>
                )}
                {isOverlayType && (
                  <>
                    <Row>
                      <TextInput
                        placeholder={'Enter Your URL '}
                        value={url}
                        type={'text'}
                        onChange={e => handleUrlInput(e)}
                        id='txt_URL_Text'
                        name='txt_URL_Text'
                      />
                    </Row>
                    {touched && !validUrl ? (
                      <ErrorMessage className='error'>
                        Please enter a valid url
                      </ErrorMessage>
                    ) : null}
                  </>
                )}
              </form>
            </Col>
            <Col>
              <QRCodePlaceHolder>
                {vidmailsUrl ? (
                  loading ? (
                    <MoonLoader
                      size={64}
                      color={themes.colors.primary[100]}
                      loading={true}
                    />
                  ) : (
                    <div ref={qrRef}>
                      {qrCode}
                      <HoverElement>
                        <HoverContent width='120px'>Save QR Image</HoverContent>
                        <DownloadIconWrapper>
                          <MdDownload onClick={downloadQRCode} size={'24px'} />
                        </DownloadIconWrapper>
                      </HoverElement>
                    </div>
                  )
                ) : (
                  <MdOutlineQrCode2
                    style={{
                      position: 'relative',
                      margin: 'auto',
                      color: '#D0D3D9',
                    }}
                  />
                )}
              </QRCodePlaceHolder>
              {(data || vidmailsUrl) && (
                <Row>
                  <QRCodeUrl>
                    <a href={vidmailsUrl}>{vidmailsUrl}</a>
                  </QRCodeUrl>
                </Row>
              )}
              <Row
                justifyContent={'flex-end'}
                style={{
                  marginTop: '6%',
                }}
              >
                <Button
                  text={buttonText()}
                  variant='primary'
                  onClick={(
                    e: React.MouseEvent<Element, MouseEvent> | undefined
                  ) =>
                    validUrl && e?.detail === 1 && !loading && handleAddOrEdit()
                  }
                  disabled={
                    !webTitle ||
                    !validUrl ||
                    loading ||
                    (overlayType === 'library' && fileId <= 0)
                  }
                />
              </Row>
            </Col>
          </Row>
        </Content>
      </Modal>
    </>
  );
};
