import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { theme } from 'lib/style';
import { HiArrowLeft } from 'react-icons/hi';

const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${theme.fontSizes.m};
  font-weight: ${theme.fontNormal600};
  color: ${theme.palette.themeDark};
  text-decoration: none;
  line-height: 24px;
`;
const Text = styled.div`
  margin-left: 8px;
`;

export const BackToIntegrations = () => {
  return (
    <Container to='/integrations'>
      <HiArrowLeft />
      <Text>Back to Integrations</Text>
    </Container>
  );
};
