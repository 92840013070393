import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from 'lib/context';
import { isAdminSession } from 'lib/utils/auth';

const Container = styled.div`
  position: sticky;
  top: 64px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.padding24};
  border-bottom: 1px solid ${theme.palette.grayBorder};
  cursor: default;
  height: 64px;
  background-color: #dc3545;
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex-grow: 2;
  ${theme.fontNormal600}
  font-size: 16px;
  color: ${theme.palette.white};
  z-index: 1;
  p {
    &:nth-child(1) {
      display: block;
    }
    &:nth-child(2) {
      display: none;
    }
    &:nth-child(3) {
      display: none;
    }
    ${theme.mediaQueryMinWidth.xs} {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
      &:nth-child(3) {
        display: none;
      }
    }
    ${theme.mediaQueryMinWidth.md} {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: block;
      }
    }
  }
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

const LogOutButton = styled.a`
  display: flex;
  align-items: center;
  width: auto;
  height: 40px;
  color: #000000;
  background-color: #ffffff;
  text-decoration: none;
  padding: 0 10px;
  &:focus {
    outline: 1px dotted ${theme.palette.secondaryBlue10Percent};
  }
`;

type Props = {
  userData: any;
};

export const AdminSession = ({ userData }: Props) => {
  let renderElem = null;
  const { whitelabel } = useAuth();

  try {
    userData.lastName = decodeURIComponent(escape(userData.lastName));
  } catch (e) {
    //
  }

  const name = `${userData.firstName} ${userData.lastName}`;

  if (isAdminSession() === true) {
    renderElem = (
      <Container>
        <LeftWrapper>
          You are currently signed in as {name} &lt;
          {userData.email}&gt;
        </LeftWrapper>
        <RightWrapper>
          <LogOutButton
            href={`https://${whitelabel.domainV2}/backoffice/incs/subuser_logout.php`}
          >
            <FaSignOutAlt />{' '}
            <span style={{ paddingLeft: 10 }}>
              Sign out of {name}'s account.
            </span>
          </LogOutButton>
        </RightWrapper>
      </Container>
    );
  }

  return renderElem;
};
