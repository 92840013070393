import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { messagesKeys } from './messagesKeys';

export const updateChat = async (chatID: string) => {
  const response = await EXPRESS_API.put(`chats/${chatID}`, {
    lastReadAt: new Date().toISOString(),
  });

  return response.data;
};

export const useUpdateMessagesMutation = (dateSelected: string) => {
  const history = useHistory();
  const isMessagesRoute = history.location.pathname === '/sms/messages';
  const queryClient = useQueryClient();
  return useMutation(updateChat, {
    onSuccess: async res => {
      await queryClient.refetchQueries(messagesKeys.all());
      if (isMessagesRoute) {
        history.push(`/sms/chats/${res.chatId}?range=${dateSelected}`);
      }
    },

    onError: () => {
      queryClient.refetchQueries(messagesKeys.all());
    },
  });
};
