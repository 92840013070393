import dayjs from 'dayjs';
import { getDrops, getSharedDrops } from 'lib/api/droplr/getDrops';
import { DropItem } from 'lib/api/droplr/types';
import { Dropdown, LoadingIndicator, Search } from 'lib/components';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { DROPTYPE_OPTIONS, SortFields } from 'lib/const/Droplr';
import React, { useEffect, useState } from 'react';
import { IoMdCalendar } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Bull,
  Col,
  EmptyFiles,
  FilesItem,
  FilesItemDate,
  FilesItemThumbnail,
  FilesItemTitle,
  FilesSelectButton,
  FilesSelector,
  ItemTypeValues,
  Menu,
  Row,
  Tab,
  TabContainer,
  fileTypes,
} from './Components';

type Props = {
  searchQuery: string;
  handleSearch(search: string): void;
  handleModalClose: () => void;
  onSelect: (drop: DropItem, itemType: string) => void;
};

export const FilesTab = (props: Props) => {
  const { searchQuery, handleSearch, handleModalClose, onSelect } = props;
  const [page, setPage] = useState(0);
  const [res, setRes] = useState<{ count: number; hasMore: string }>({
    count: 0,
    hasMore: 'false',
  });
  const [drops, setDrops] = useState([] as DropItem[]);
  const [loading, setLoading] = useState(true);
  const [field, setField] = useState(SortFields[1]);
  const [dropType, setDropType] = useState<DROPTYPE_OPTIONS>(
    DROPTYPE_OPTIONS.OWNED
  );
  const [type, setType] = useState(fileTypes[0]);
  const isDropOwned = dropType === DROPTYPE_OPTIONS.OWNED;
  const isDropShared = dropType === DROPTYPE_OPTIONS.SHARED;

  const fetchDrops = async () => {
    const data = {
      sort: field.key,
      order: field.order,
      search: searchQuery,
      page: page,
      type: type.value,
      dropType,
    };
    let response;
    if (isDropOwned) {
      response = await getDrops(data);
    } else {
      response = await getSharedDrops({
        page: data.page,
        size: 10,
      });
    }
    setPage(page + 1);
    setRes(response);
    setDrops([...drops, ...response.drops]);
  };

  const refreshDrops = async () => {
    setLoading(true);

    let response;
    const data = {
      sort: field.key,
      order: field.order,
      search: searchQuery,
      page: page,
      type: type.value,
      dropType,
    };

    if (isDropOwned) {
      response = await getDrops(data);
    } else {
      response = await getSharedDrops({
        page: data.page,
        size: 10,
      });
    }
    setDrops([...drops, ...response.drops]);
    setRes(response);
    setLoading(false);
    setDrops(response.drops);
  };

  useEffect(() => {
    refreshDrops();
  }, [field, searchQuery, dropType, type]);

  return (
    <>
      <TabContainer>
        <Tab
          className={isDropOwned ? 'activeTab' : ''}
          onClick={() => {
            setDropType(DROPTYPE_OPTIONS.OWNED);
          }}
          title={'myFiles'}
        >
          &nbsp;My Files
        </Tab>
        <Tab
          className={isDropShared ? 'activeTab' : ''}
          onClick={() => {
            setDropType(DROPTYPE_OPTIONS.SHARED);
          }}
          title={'sharedWithMe'}
        >
          &nbsp;Shared with me
        </Tab>
      </TabContainer>
      <Menu>
        <Row>
          <Search
            placeholder={`Search files and tags...`}
            onSearch={handleSearch}
            prevSearch={searchQuery}
            width={'100%'}
          />
        </Row>
        <Row style={{ marginTop: '12px' }}>
          <Col style={{}}>
            <Dropdown
              value={field}
              onChange={newValue => {
                setField(newValue);
              }}
              options={SortFields}
              dropdownHeight={450}
              height={40}
            />
          </Col>
          <Col style={{ marginLeft: '12px' }}>
            <Dropdown
              value={type}
              onChange={newValue => {
                setType(newValue);
              }}
              options={fileTypes}
              dropdownHeight={450}
              height={40}
            />
          </Col>
        </Row>
      </Menu>
      {loading && <LoadingIndicator isLoading={loading} height='200px' />}
      {!loading && (
        <FilesSelector>
          <InfiniteScroll
            dataLength={page * res.count}
            next={fetchDrops}
            hasMore={res.hasMore === 'true'}
            loader={<h4>Loading...</h4>}
            height={300}
          >
            {drops.map(drop => (
              <FilesItem
                key={drop.code}
                onClick={() => {
                  onSelect(drop, ItemTypeValues.FILE);
                  handleModalClose();
                }}
              >
                <FilesItemThumbnail image={drop.previewSmall} />
                <FilesItemTitle>
                  {drop.title} <br />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IoMdCalendar style={{ marginRight: '10px' }} />
                    <FilesItemDate>
                      {dayjs(drop.createdAt).format(STANDARD_DATE_FORMAT)}
                    </FilesItemDate>
                    <Bull />
                    <FilesItemDate>
                      {dayjs(drop.createdAt).format('hh:mm:ss A')}
                    </FilesItemDate>
                  </div>
                </FilesItemTitle>
                <FilesSelectButton className='btn btn-select-file'>
                  Select File
                </FilesSelectButton>
              </FilesItem>
            ))}
            {!drops.length && <EmptyFiles>No files found.</EmptyFiles>}
          </InfiniteScroll>
        </FilesSelector>
      )}
    </>
  );
};
