import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { IoCaretDown } from 'react-icons/io5';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';

const DropdownWrapper = styled.div`
  position: relative;
  margin-right: 8px;
`;
const DropdownContent = styled.div`
  position: absolute;
  z-index: 999;
  right: 0;
  top: 100%;
  width: 80px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(29, 30, 36, 0.06);
  border-radius: 5px;
  padding: 8px 0;
`;
const DropdownItem = styled.div`
  cursor: pointer;
  padding: 8px 24px;
  b,
  span {
    display: block;
  }
  b {
    margin-bottom: 4px;
    color: ${theme.palette.primaryDarkBlue};
    font-weight: 600;
    font-size: 15px;
  }
  span {
    font-size: 14px;
    line-height: 20px;
    color: ${theme.palette.primaryDarkBlue};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary['non_alpha']};
  }
`;
const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 16px;
  background: ${({ theme }) => theme.colors.secondary['non_alpha']};
  border: 1px solid rgba(0, 27, 83, 0.05);
  border-radius: 5px;
  color: ${theme.palette.primaryDarkBlue};
  font-family: Work Sans;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  width: 70px;
`;
const Caret = styled(IoCaretDown)`
  margin-left: 8px;
  &.up {
    transform: rotate(180deg);
  }
`;

type Props = {
  config: any;
  translations: any;
  onChange: any;
  expanded: any;
  onExpandEvent: any;
  doExpand: any;
  doCollapse: any;
  currentState: any;
};

export const FontSizeDropdown = ({
  config: { options },
  onChange,
  currentState,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { ref } = useOnClickOutside(() => {
    setShowDropdown(false);
  });

  const chooseFontFamily = (fontFamily: string) => {
    onChange(fontFamily);
    setShowDropdown(false);
  };

  return (
    <div ref={ref} className='toolbar-custom-fontFamily'>
      <DropdownWrapper>
        <DropdownButton
          type='button'
          onClick={() => setShowDropdown(!showDropdown)}
        >
          {currentState?.fontSize || '16'}
          <Caret
            size={10}
            color={theme.palette.primaryDarkBlue}
            className={showDropdown ? 'up' : 'down'}
          />
        </DropdownButton>
        {showDropdown && (
          <DropdownContent>
            {options.map((option: any, i: number) => (
              <DropdownItem
                key={`dropdown-item-${i}`}
                onClick={() => chooseFontFamily(option)}
              >
                <b>{option}</b>
              </DropdownItem>
            ))}
          </DropdownContent>
        )}
      </DropdownWrapper>
    </div>
  );
};
