import { RESELLER_STATUS_OPTIONS } from 'app/pages/admin/resellers/components/ChangeResellerStatusModal';
import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';

import { useMutation } from 'react-query';

interface IParams {
  resellerIds: number[];
  active: RESELLER_STATUS_OPTIONS | null;
}

const getAllCustomersForSuperAdmin = async (params: IParams) => {
  return (
    await EXPRESS_API.put(`superadmin/resellers/update-status`, { ...params })
  ).data;
};

export const useUpdateResellerStatusMutation = (
  onSuccessCallback: () => void
) => {
  return useMutation(getAllCustomersForSuperAdmin, {
    onSuccess: (_, variables: IParams) => {
      const status = variables.resellerIds.length > 1 ? 'statuses' : 'status';
      successToast({ title: `You have successfully updated ${status}!` });
      onSuccessCallback();
    },
    onError: () => {
      errorToast({
        title: `Something went wrong while updating reseller status!`,
      });
    },
  });
};
