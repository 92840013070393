import { NewModal, TextInput, SelectInput } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import {
  FieldAttributes,
  Field,
  Formik,
  Form,
  FormikValues,
  FieldProps,
} from 'formik';
import styled from 'styled-components/macro';
import { useRenameCategoryMutation } from 'lib/api/guides/renameGuideCategoryMutation';
import { CategorizedGuide } from 'lib/api/guides/guideCategoryQuery';
import * as Yup from 'yup';
import { Button } from 'react-covideo-common';
import { CategoryPlatformOptions } from './AddCategoryModal';

type InputFieldProps = FieldAttributes<{ name: string }>;

const InputField = (props: InputFieldProps) => {
  return <Field {...props} />;
};

interface IProps {
  closeModalHandler: () => void;
  selectedGuideCategory: Pick<
    CategorizedGuide,
    'guideCategoryId' | 'name' | 'platform'
  > | null;
}

const renameModalSchema = Yup.object({
  name: Yup.string().required(),
});

const Label = styled.label`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;

export const RenameCategoryModal = ({
  closeModalHandler,
  selectedGuideCategory,
}: IProps) => {
  const { mutateAsync, isLoading } =
    useRenameCategoryMutation(closeModalHandler);
  const onSubmitHander = (values: FormikValues) => {
    mutateAsync({
      name: values.name,
      platform: values.platform,
      categoryId: selectedGuideCategory!.guideCategoryId,
    });
  };
  return (
    <NewModal headerText='Edit Category' closeModal={closeModalHandler}>
      <Formik
        initialValues={{
          name: selectedGuideCategory?.name || '',
          platform: selectedGuideCategory?.platform,
        }}
        onSubmit={onSubmitHander}
        validationSchema={renameModalSchema}
      >
        {({ handleSubmit, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Gap>
                <Label htmlFor='name'>
                  Enter new name for “{selectedGuideCategory?.name}”
                </Label>

                <InputField
                  width='100%'
                  placeholder=''
                  name={'name'}
                  as={TextInput}
                  type='text'
                  disabled={isLoading}
                />
              </Gap>

              <Gap m='12px 0 0 0'>
                <Label htmlFor='platform'>Select platform</Label>

                <Field name='platform' readonly={isLoading}>
                  {({ field }: FieldProps) => {
                    const { value, ...rest } = field;
                    return (
                      <SelectInput
                        {...rest}
                        defaultValue={value}
                        disabled={isLoading}
                      >
                        {Object.values(CategoryPlatformOptions).map(
                          (option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          )
                        )}
                      </SelectInput>
                    );
                  }}
                </Field>
              </Gap>

              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  disabled={isLoading}
                  onClick={async () => {
                    closeModalHandler();
                  }}
                  text='Cancel'
                  variant='secondary'
                />
                <Button
                  text='Save'
                  disabled={!!errors.name || isLoading}
                  variant='primary'
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
