import React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { theme } from 'lib/style';

const Content = styled.div`
  width: 600px;
  line-height: 28px;
  color: #272a32;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  handleModalSubmit: Function;
  content: any;
  isCompanyVideo: boolean;
};

export const DeleteVideoModal = (props: Props) => {
  const { handleModalClose, handleModalSubmit, content, isCompanyVideo } =
    props;

  const [isDisabled, setIsDisabled] =
    React.useState(false); /** SUS-973 changes **/

  return (
    <NewModal
      headerText={
        isCompanyVideo
          ? 'Are you sure you want to delete this company video?'
          : 'Are you sure you want to move this video to recently deleted bin?'
      }
      headerTextStyle={{
        fontSize: theme.fontSizes.lg,
        lineHeight: '24px',
        color: theme.palette.themeDark,
      }}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      style={{
        content: {
          padding: '32px',
          minWidth: 'auto',
        },
      }}
    >
      <Content>{!isCompanyVideo && content}</Content>
      <Footer>
        <Button
          text='No, Keep Video'
          variant='white'
          onClick={async () => {
            handleModalClose();
          }}
        />
        <Button
          text={isCompanyVideo ? 'Yes, Delete Video' : 'Yes, Move Video'}
          variant='red'
          onClick={async () => {
            setIsDisabled(true); /** SUS-973 changes **/
            handleModalSubmit();
          }}
          disabled={isDisabled} /** SUS-973 changes **/
        />
      </Footer>
    </NewModal>
  );
};
