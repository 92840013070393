import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'formik';
import {
  FieldContainer,
  FormProps,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';
import { StatsCard } from '../StatsCard';
import { SMSSettingChangeConfirmationModal } from '../SMSSettingChangeConfirmationModal';
import { Button } from 'react-covideo-common';

const FieldWrapper = styled.div`
  flex: 1;
`;

export const SmsSettingSection = (props: FormProps) => {
  const { setFieldValue, values } = props;
  const [showDeactivationConfirmModal, setShowDeactivationConfirmModal] =
    React.useState<boolean>(false);

  return (
    <Section>
      <SectionTitle>SMS Settings</SectionTitle>
      <RowWrapper>
        <Row alignItem='normal' justifyContent='space-between'>
          {!!values.smsEnabled && (
            <>
              <StatsCard
                count={values.sentSmsCount ?? 0}
                text={'sent this month'}
              />
              <StatsCard
                count={values.remainingSmsCount ?? 0}
                text={'remaining'}
                marginLeft={8}
              />
            </>
          )}

          <FieldWrapper style={{ flex: 0 }}>
            <Field name='smsEnabled'>
              {() => {
                return (
                  <FieldContainer>
                    <Button
                      variant={values.smsEnabled ? 'red' : 'secondary'}
                      onClick={() => {
                        if (values.smsEnabled) {
                          setShowDeactivationConfirmModal(true);
                          return;
                        }
                        setFieldValue(`smsEnabled`, 1);
                        setFieldValue(`location`, '');
                        setFieldValue(`number`, '');
                        setFieldValue(`paidSmsPerMonth`, 0);
                        setFieldValue(`freeSmsPerMonth`, 0);
                      }}
                      text={
                        values.smsEnabled ? 'Deactivate SMS' : 'Activate SMS'
                      }
                    />
                  </FieldContainer>
                );
              }}
            </Field>
          </FieldWrapper>
        </Row>
      </RowWrapper>
      {showDeactivationConfirmModal && (
        <SMSSettingChangeConfirmationModal
          handleModalClose={deactivate => {
            if (deactivate) {
              setFieldValue(`smsEnabled`, 0);
              setFieldValue(`location`, '');
              setFieldValue(`number`, '');
              setFieldValue(`paidSmsPerMonth`, 0);
              setFieldValue(`freeSmsPerMonth`, 0);
            }
            setShowDeactivationConfirmModal(false);
          }}
        />
      )}
    </Section>
  );
};
