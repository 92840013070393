import { theme } from '.';
import { DefaultTheme } from 'styled-components/macro';

export const creatableSelectStyle = {
  control: (base: any) => ({
    ...base,
    height: '40px',
    minHeight: '40px',
    maxHeight: '100px',
    border: '1px solid #D0D3D9',
    borderRadius: '4px',
    cursor: 'text',
    backgroundColor: '#fff',
    maxWidth: '800px',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: 'auto',
    minHeight: '40px',
    maxHeight: '100px',
    overflow: 'auto',
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: '900',
    maxHeight: '200px',
  }),
  menuList: (base: any) => ({
    ...base,
    zIndex: '900',
    maxHeight: '200px',
  }),
  placeholder: (base: any) => ({
    ...base,
    opacity: '0.6',
    color: theme.palette.black_1_100,
    fontFamily: theme.systemFontStack,
    display: 'block',
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    display: 'none',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    display: 'none',
  }),
};

export const selectStyle = (themes: DefaultTheme) => ({
  control: (base: any) => ({
    ...base,
    height: '40px',
    boxShadow: 'none',
    borderColor: '#cccccc',
    cursor: 'pointer',
    ':hover': { borderColor: 'black' },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 999,
  }),
  menu: (base: any) => {
    return {
      ...base,
      marginTop: '0px',
    };
  },
  option: (styles: any, { isSelected, isFocused }: any) => {
    return {
      ...styles,
      color: isSelected
        ? themes.colors.primary[100]
        : theme.palette.covideoBlue100,
      backgroundColor: isFocused ? theme.palette.blue05 : theme.palette.white,
      cursor: 'pointer',
      position: 'relative',
      fontWeight: 500,
      fontSize: 15,
      ':after': {
        content: '" "',
        position: 'absolute',
        right: 15,
        top: 6,
        display: isSelected ? 'inline-block' : 'none',
        transform: 'rotate(45deg)',
        height: 13,
        width: 6,
        marginLeft: '60%',
        borderBottom: `2px solid ${themes.colors.primary[100]}`,
        borderRight: `2px solid ${themes.colors.primary[100]}`,
      },
    };
  },
});

export const secondarySelectStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    height: '40px',
    boxShadow: 'none',
    borderColor: state.isFocused
      ? theme.palette.covideoBlue100
      : theme.palette.gray40,
    ':hover': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':focus': {
      borderColor: theme.palette.covideoBlue100,
    },
    ':active': {
      borderColor: theme.palette.covideoBlue100,
    },
    cursor: 'pointer',
  }),
  option: (base: any) => ({
    ...base,
    backgroundColor: 'transparent',
    color: theme.palette.gray100,
    padding: '8px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
    ':focus': {
      backgroundColor: 'transparent',
      opacity: '.8',
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
};

export const droplrModalsSelectStyle = {
  indicatorsContainer: () => ({ display: 'none' }),
  control: (base: any) => ({
    ...base,
    height: '40px',
    minHeight: '40px',
    maxHeight: '100px',
    border: '1px solid #D0D3D9',
    borderRadius: '4px',
    cursor: 'text',
    backgroundColor: '#F6F7F9',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: 'auto',
    minHeight: '40px',
    maxHeight: '100px',
    overflow: 'auto',
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: '900',
    maxHeight: '200px',
  }),
  menuList: (base: any) => ({
    ...base,
    zIndex: '900',
    maxHeight: '200px',
  }),
  placeholder: (base: any) => ({
    ...base,
    opacity: '0.6',
    color: theme.palette.black_1_100,
    fontFamily: theme.systemFontStack,
    display: 'block',
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    display: 'none',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    display: 'none',
  }),
};
