import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { PackageNameLabel, productFeature } from 'lib/utils/productFeature';
import React from 'react';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { MeetingStatus } from 'lib/api/meetings/types';
import { Button } from 'react-covideo-common';
import { IoMdCalendar } from 'react-icons/io';

interface ScheduleActionProps {
  handleOpenLiveDetailsModal: (
    meetingId: string | null,
    meetingStatus: MeetingStatus | null
  ) => void;
}

export const ScheduleAction = ({
  handleOpenLiveDetailsModal,
}: ScheduleActionProps) => {
  const { userData } = useAuth();
  return (
    <>
      {userData.hasLiveVideoAccess ? (
        <Button
          text='Schedule'
          icon={<IoMdCalendar color={theme.palette.covideoBlue100} size={16} />}
          onClick={() => handleOpenLiveDetailsModal(null, null)}
          variant='secondary'
        />
      ) : (
        <ProductInfoTooltip
          productFeatureId={productFeature.LIVE_VIDEO}
          nextPlan={PackageNameLabel.TEAMS}
          style={{
            left: '0px',
            top: '45px',
          }}
        >
          <Button
            text='Schedule'
            icon={
              <IoMdCalendar color={theme.palette.covideoBlue100} size={16} />
            }
            variant='secondary'
            disabled
          />
        </ProductInfoTooltip>
      )}
    </>
  );
};
