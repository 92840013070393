import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { guidesKeyes } from './guidesKeyes';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IParams {
  resellerIds?: number[];
  customerIds?: number[];
  userIds: number[];
  sharedWithCovideo?: boolean;
  categoryId: number;
}

const visibilityPermission = async (
  params: IParams
): Promise<{ message: string }> => {
  const { categoryId, ...body } = params;

  return (
    await EXPRESS_API.patch(`guide-category/${categoryId}/visibility`, {
      ...body,
    })
  ).data;
};

export const useVisibilityPermissionMutation = (
  closeModalHandler: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(visibilityPermission, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: () => {
      queryClient.removeQueries(guidesKeyes.visibility());
      closeModalHandler();
      successToast({ title: 'Visibility updated successfully.' });
    },
  });
};
