import React from 'react';
import styled from 'styled-components/macro';
import { NewModal, Switch } from 'lib/components';
import { CommonTypography } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { SuperAdminCustomerDetails } from 'lib/context';
import { Button } from 'react-covideo-common';

const SubmitButtonWrapper = styled.div`
  text-align: right;
  display: flex;
  margin-top: 32px;
`;

const Title = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${theme.palette.covideoGray100};
`;

const ToggleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
`;

const ToggleParagraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
`;

type Props = {
  newResellerName: string;
  customer: SuperAdminCustomerDetails;
  handleModalClose(confirm: boolean): void;
};

export const ResellerChangeConfirmationModal = ({
  newResellerName,
  customer,
  handleModalClose,
}: Props) => {
  const [confirm, setConfirm] = React.useState(false);

  return (
    <NewModal
      headerText={'Change Reseller for this Customer?'}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose(false)}
      style={{
        content: {
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Title>
        Are you sure you want to move the '{customer.business}', and all it's
        users to '{newResellerName}'.
      </Title>

      <SubmitButtonWrapper>
        <ToggleWrapper>
          <ToggleParagraph>I confirm this action</ToggleParagraph>
          <Switch
            id={'switch-confirm-reseller-change'}
            isOn={confirm}
            onColor={theme.palette.covideoOrange100}
            handleToggle={() => {
              setConfirm(true);
            }}
          />
        </ToggleWrapper>
        <Button
          onClick={() => handleModalClose(true)}
          text={'Change Reseller'}
          disabled={!confirm}
        />
      </SubmitButtonWrapper>
    </NewModal>
  );
};
