import { EXPRESS_API } from 'configs/api/expressApi';

let users = {} as any;

export const fetchCustomerUsers = async (
  customer: string,
  fetchNew?: boolean
) => {
  if (!fetchNew && users[customer] && users[customer].length) {
    return users[customer];
  }

  const response = await EXPRESS_API.get('/users', {
    params: { ...(customer === 'all' ? {} : { customer }) },
  });

  const data = response.data;

  users[customer] = data;

  return data;
};

export const fetchCustomerUser = async (userId: string) => {
  const response = await EXPRESS_API.get(`/users/${userId}`);

  return response.data;
};

export const fetchCustomer = async (customerId: string) => {
  const response = await EXPRESS_API.get(`/customers/${customerId}`);

  return response.data;
};

export const fetchCustomers = async () => {
  const response = await EXPRESS_API.get(`/customers`);

  return response.data;
};

export const searchCustomers = async (
  search: string = '',
  includeUserSearch: boolean = false,
  page: number = 0,
  limit: number = 10
) => {
  const start = page * limit;
  const params = {
    search,
    start,
    limit,
    includeUserSearch,
  };

  const response = await EXPRESS_API.get(`/customers/search`, {
    params,
  });

  return response.data;
};
