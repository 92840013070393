import React from 'react';
import { VISIBILITY_TYPE } from '../SelectableArea';
import { SelectableItem } from './SelectableItem';
import { SelectableListItemWrapper } from './SelectableListItemWrapper';
import { IoCarSport } from 'react-icons/io5';

interface IProps {
  data: {
    id: number;
    checked: boolean;
    displayText: string;
    isPartiallySelected: boolean;
    dealer?: number;
  }[];

  onItemClick: (id: number, item: VISIBILITY_TYPE) => void;
  type: VISIBILITY_TYPE;
  selectedId?: number | undefined;
  selectedResellerId?: number | undefined;
  selectedCustomerId?: number | undefined;
  resetSelectedCustomers?: () => void;
  onCheckboxClick: (id: number, checked: boolean) => void;
}

export const SelectableListItem = ({
  data,
  onItemClick,
  type,
  selectedId,
  onCheckboxClick,
}: IProps) => {
  return (
    <SelectableListItemWrapper>
      {data?.map(singleItem => {
        return (
          <SelectableItem
            id={singleItem.id}
            onItemClick={onItemClick}
            type={type}
            checkGroupIndicator={singleItem?.isPartiallySelected || false}
            displayText={singleItem.displayText}
            key={singleItem.id}
            icon={
              !!singleItem.dealer ? (
                <IoCarSport
                  size={15}
                  color='inherit'
                  style={{
                    marginRight: 4,
                    position: 'relative',
                    top: 2,
                  }}
                />
              ) : (
                <></>
              )
            }
            isChecked={singleItem.checked}
            isSelected={selectedId === singleItem.id}
            onCheckboxClick={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              const { checked } = event.target as HTMLInputElement;
              onCheckboxClick(singleItem.id, checked);
            }}
          />
        );
      })}
    </SelectableListItemWrapper>
  );
};
