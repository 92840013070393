import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation, useQueryClient } from 'react-query';
import { folderAccessKeys } from './folderAccessKeys';
import { FolderAccessBody } from './types';
import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';

export const createFolderAccess = async (data: Partial<FolderAccessBody>) => {
  const response = await EXPRESS_API.post(
    `/folders/${data.folderId}/access`,
    data
  );
  return response.data;
};

export const useCreateFolderAccess = () => {
  const queryClient = useQueryClient();
  const { showError } = useToastError();

  return useMutation(
    (data: Partial<FolderAccessBody>) => createFolderAccess(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(folderAccessKeys.folder_access_list());
      },
      onError: (err: AxiosError) => {
        showError(
          err ||
            'An error occurred creating the new folder access, please try again later!'
        );
      },
    }
  );
};
