import { EXPRESS_API } from 'configs/api/expressApi';
import { useQueries, UseQueryResult } from 'react-query';
import { automotiveUsersKeys } from './queryKeys';

export const getUsersByAutomotiveRoles = async ({
  customerId,
  role,
}: {
  customerId: string;
  role: string;
}) => {
  const response = await EXPRESS_API.get(
    `/users/${customerId}/automotive-users`,
    {
      params: { role },
    }
  );
  return response.data;
};

interface IGetUsersByAutomotiveRoleParams {
  customerId: string;
  role: string;
}

export interface UsersByAutmotiveRolesResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const useGetUsersByAutomotiveRolesQuery = ({
  params,
  enabled,
}: {
  params: IGetUsersByAutomotiveRoleParams[];
  enabled: boolean;
}) => {
  const queries = params.map(({ customerId, role }) => ({
    queryKey: automotiveUsersKeys.customer_user(customerId, role),
    queryFn: () => getUsersByAutomotiveRoles({ customerId, role }),
    enabled: enabled,
  }));

  const results: UseQueryResult<UsersByAutmotiveRolesResponse[], unknown>[] =
    useQueries(queries);

  const isLoading = results.some(query => query.isLoading);
  const isError = results.some(query => query.isError);
  return { results, isLoading, isError };
};
