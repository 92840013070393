import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const AccordionContainer = styled.div`
  padding-top: 49px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const AccordionWrapper = styled.div`
  width: 100%;
`;

export const AccordionBody = styled.div<{ show: boolean }>`
  border-radius: 0px 0px 6px 6px;
  background: ${theme.palette.covideoBlue02NonAlpha};
  display: ${props => (props.show ? 'block' : 'none')};
  padding: 8px 0 10px 0;
`;

export const AccordionHeader = styled.div<{ show: boolean }>`
  border-radius: ${props => (props.show ? '6px 6px 0px 0px' : '6px')};
  background: ${theme.palette.white};
  box-shadow:
    0px 4px 8px 0px rgba(66, 79, 104, 0.03),
    0px 0px 2px 0px rgba(66, 79, 104, 0.08);
  padding: 12px 16px;
  border: 1px solid ${theme.palette.blue05};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const OrganizationName = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${theme.palette.blue100};
  cursor: pointer;
`;

export const ClickableWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const PopupList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 100%;
`;

export const PopupTextStyle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: ${theme.palette.neutral100};
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  justify-content: end;
`;

export const TooltipWrapper = styled.div`
  text-align: center;
`;

export const OrganizationData = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

export const Item = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

export const CustomerName = styled.div<{ width?: string }>`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${theme.palette.neutral100};
  width: ${props => props.width ?? 'fit-content'};
  text-align: initial;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmptyTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${theme.palette.neutral100};
`;

export const CustomersHeader = styled.div`
  display: grid;
  grid-template-columns: 240px 240px auto;
  padding: 4px 16px 8px 48px;
`;
export const HeaderItem = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${theme.palette.gray60};
`;
export const CustomersBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
`;
export const CustomersRow = styled.div`
  display: grid;
  grid-template-columns: 240px 240px auto;
  padding: 2px 16px 2px 48px;
  &:hover {
    background-color: ${theme.palette.gray20};
    ${CustomerName} {
      text-decoration: underline;
    }
  }
`;
