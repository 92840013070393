import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  getYoutubeAuthUrl,
  deleteIntegration,
  savePrivacySetting,
  checkYoutubeIntegration,
  saveYoutubeAccessToken,
  getChannelData,
} from 'lib/api/youtubeApi';
import Icon from '../assets/social-icons/google';
import { CONNECTION_TYPES, privacyOptions } from '../constants';
import { ProfileCard } from '../ProfileCard';
import { SocialButton } from '../SocialButton';
import { useToastError } from 'lib/hooks';
import { successToast } from 'lib/components/toasts/success';

const social = {
  id: 2,
  title: 'YouTube',
  description: `When you click Reconnect, you’ll be prompted to login into YouTube with your Google account. By connecting, you agree to and are bound by the YouTube Terms of Service.`,
};

export const Youtube = () => {
  const { showError } = useToastError();
  const history = useHistory();
  const [youtubeIntegration, setYoutubeIntegration] = useState<null | boolean>(
    null
  );
  const [youtubeChannel, setYoutubeChannel] = useState('');
  const [youtubeToken, setYoutubeToken] = useState('');
  const [youtubeConnection, setYoutubeConnection] = useState(
    CONNECTION_TYPES['NOT CONNECTED']
  );
  const [privacy, setPrivacy] = useState('');

  useEffect(() => {
    if (!!youtubeToken) {
      const saveToken = async () => {
        await saveYoutubeAccessToken(youtubeToken);
        history.push('/profile/social-profiles');
      };
      saveToken().catch(console.error);
    }
  }, [youtubeToken]);

  const checkYoutubeIntegrationStatus = async () => {
    const userStatus = await checkYoutubeIntegration();
    setYoutubeIntegration(userStatus?.success);
    setPrivacy(userStatus?.privacy);
  };

  useEffect(() => {
    if (youtubeIntegration) {
      const ytChannelName = async () => {
        const channel = await getChannelData();
        setYoutubeChannel(channel.title);
      };
      ytChannelName().catch(console.error);
      setYoutubeConnection(CONNECTION_TYPES['CONNECTED']);
    }
    let link = window.location.href;
    if (link.includes('youtube')) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      setYoutubeToken(params.code);

      if (params.error) {
        history.push('/profile/social-profiles');
      }
    }
  }, [window.location.search, youtubeIntegration]);

  useEffect(() => {
    if (youtubeIntegration === null) {
      checkYoutubeIntegrationStatus().catch(console.error);
    }
  }, []);

  const updatePrivacySetting = async (privacyString: string) => {
    try {
      if (privacyString) {
        await savePrivacySetting(privacyString);
        successToast({
          title: 'Privacy setting updated',
        });
      }
    } catch (error) {
      showError(error);
      console.log('ERROR', error);
    }
  };

  return (
    <ProfileCard
      key={social.id}
      title={social.title}
      description={social.description}
      connection={youtubeConnection}
      button={
        <SocialButton
          title='Sign in with Google'
          icon={Icon()}
          onClick={async () => {
            const url = await getYoutubeAuthUrl();
            window.open(url.url, '_self');
          }}
          color='#4285F4'
          padding='0px 24px 0px 0px'
          font="'Roboto', sans-serif"
          fontsize='14px'
        />
      }
      hasPrivacyToggle={true}
      hasPages={false}
      onSaveClick={() => console.log()}
      onDisconnect={async () => {
        deleteIntegration();
        setYoutubeConnection(CONNECTION_TYPES['NOT CONNECTED']);
        setYoutubeChannel('');
        setYoutubeToken('');
      }}
      onReconnect={async () => {
        const url = await getYoutubeAuthUrl();
        window.open(url.url, '_self');
      }}
      onToggleSwitch={(newPrivacy: any) => {
        updatePrivacySetting(newPrivacy);
      }}
      toggleItems={privacyOptions}
      channelName={youtubeChannel}
      loading={false}
      toggleValue={privacy}
    />
  );
};
