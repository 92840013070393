import React from 'react';
import { NewModal } from 'lib/components';
import { Form, Formik, useField } from 'formik';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { Gap, TextArea } from '../styles/modal.styles';
import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';

import { RecipientsSelector } from 'app/pages/reports/components/RecipientsSelector';
import {
  ISendTranscriptPayload,
  useSendTranscriptConversationMutation,
} from 'lib/api/conversations/sendConversationTranscript';
import { useClosedConversationMutation } from 'lib/api/conversations/setConversationAsClosed';
import { useParams } from 'react-router-dom';
import { ModalsType } from '../conversations.types';
import { Button } from 'react-covideo-common';
interface TextInputProps {
  name: string;
  id?: string;
}

interface IRepassingConversationModal {
  onModalClose: () => void;
  singleConversation: ISingleConversation;
  modals: false | ModalsType;
}

interface IValues extends ISendTranscriptPayload {}

const TextAreaInput = ({ ...props }: TextInputProps) => {
  const [field] = useField(props);
  return <TextArea {...field} {...props} />;
};

export const ModalSendTranscript = ({
  onModalClose,
  singleConversation,
  modals,
}: IRepassingConversationModal) => {
  const { id } = useParams() as { id: string };

  const { mutateAsync, isLoading } =
    useSendTranscriptConversationMutation(onModalClose);
  const { isLoading: isLoadingClosed, mutateAsync: markAsClosedAsync } =
    useClosedConversationMutation();

  const onSubmitHandler = async (values: IValues) => {
    modals === 'send_transcript_closed' && markAsClosedAsync(id);
    mutateAsync({
      conversationId: singleConversation.conversationId,
      payload: values,
    });
  };

  return (
    <NewModal
      headerText='Send Transcript'
      closeModal={onModalClose}
      style={{
        content: {
          maxWidth: '582px',
        },
      }}
    >
      <Formik
        initialValues={{
          emails: [],
          message: '',
        }}
        onSubmit={onSubmitHandler}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Gap direction='column' gap='16px'>
              <Gap direction='column' gap='8px'>
                <ParagraphSmall as='label' color='rgba(78, 84, 97, 1)'>
                  Recipients
                </ParagraphSmall>
                <RecipientsSelector
                  onChange={recipients => setFieldValue('emails', recipients)}
                  isClearable={false}
                />
              </Gap>
              <Gap direction='column' gap='8px'>
                <ParagraphSmall as='label' color='rgba(78, 84, 97, 1)'>
                  Message (optional)
                </ParagraphSmall>
                <TextAreaInput name='message' />
              </Gap>
              <Gap direction='row-reverse'>
                <Button
                  text={isLoading ? 'Sending...' : 'Send Transcript'}
                  type='submit'
                  disabled={!values['emails'].length}
                />
                {modals === 'send_transcript_closed' && (
                  <Button
                    text={
                      isLoadingClosed && !isLoading
                        ? 'Closing...'
                        : 'Close conversation'
                    }
                    onClick={async () => {
                      await markAsClosedAsync(id);
                      onModalClose();
                    }}
                    type='button'
                  />
                )}
              </Gap>
            </Gap>
          </Form>
        )}
      </Formik>
    </NewModal>
  );
};
