import { Formik } from 'formik';
import { useUserInvitesMutation } from 'lib/api/superadmin/useUserInvitesMutation';
import { UserInvite } from 'lib/api/superadmin/useUserInvitesQuery';
import { ButtonPillSwitch, NewModal } from 'lib/components';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import React, { useState } from 'react';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { MdScheduleSend } from 'react-icons/md';
import {
  getUserTimezone,
  getDeliveryTime,
  getCustomDateTimePickerValue,
  getDefaultManageInvitesType,
} from './utils';
import styled from 'styled-components/macro';
import { Gap } from 'lib/components/styles/layout';
import CustomDateTimePicker from 'lib/components/custom-time-picker/CustomDateTimePicker';
import dayjs from 'dayjs';
import { MANAGE_INVITES_TYPE } from './types';
import {
  TimezoneSelector,
  TimezonesOption,
  timezonesOptions,
} from 'lib/components/timezoneSelector/TimezoneSelector';

const LabelContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
`;

const LabelContainer = styled.div`
  width: 400px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  padding-left: 8px;
  align-items: center;
`;

interface IManageInvitesModal {
  handleModalClose: () => void;
  users: UserInvite[];
  customerId: number | undefined;
  setSelectedUsers: React.Dispatch<React.SetStateAction<UserInvite[]>>;
}

const CustomValueContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <LabelContainerWrapper>
      <LabelContainer>{children}</LabelContainer>
    </LabelContainerWrapper>
  );
};

interface IManageInvitesFormikValues {
  deliveryTime: string;
  preferedUserTimezone: TimezonesOption | null;
  customDateTimePicker: Date;
}

export const ManageInvitesModal = ({
  handleModalClose,
  users,
  customerId,
  setSelectedUsers,
}: IManageInvitesModal) => {
  const isMultipleUsers = users.length > 1;
  const singleUserDeliveryTime = users[0]?.userInvite?.deliveryTime;
  const { colors } = useCovideoTheme();

  const onSuccessCallback = () => {
    handleModalClose();
    setSelectedUsers([]);
  };

  const { mutateAsync: sendUserInvites, isLoading: isSendingInvite } =
    useUserInvitesMutation({ onSuccessCallback });

  const [manageInvitesType, setManageInvitesType] =
    useState<MANAGE_INVITES_TYPE>(
      getDefaultManageInvitesType(isMultipleUsers, users)
    );

  const onSubmit = async (values: IManageInvitesFormikValues) => {
    const invites = users.map(user => ({
      userId: user.id,
      ...(manageInvitesType === MANAGE_INVITES_TYPE.IMMEDIATELY
        ? {}
        : { deliveryTime: values.deliveryTime }),
    }));
    sendUserInvites({ customerId, invites });
  };
  const {
    userData: { timezone },
  } = useAuth();

  const getInitialDeliveryTime = () => {
    return isMultipleUsers
      ? getDeliveryTime(undefined, timezone)
      : getDeliveryTime(singleUserDeliveryTime, timezone);
  };

  const getInitialPreferredUserTimezone = () => {
    return isMultipleUsers
      ? getUserTimezone(undefined, timezone)
      : getUserTimezone(singleUserDeliveryTime, timezone);
  };

  const getInitialCustomDateTimePicker = () => {
    return isMultipleUsers
      ? getCustomDateTimePickerValue(undefined)
      : getCustomDateTimePickerValue(singleUserDeliveryTime);
  };
  const initialValues = {
    deliveryTime: getInitialDeliveryTime(),
    preferedUserTimezone: getInitialPreferredUserTimezone(),
    customDateTimePicker: getInitialCustomDateTimePicker(),
  };

  return (
    <NewModal
      closeModal={handleModalClose}
      headerText='Invite'
      style={{
        content: {
          minWidth: '464px',
          maxWidth: '464px',
          minHeight: '480px',
          maxHeight: '480px',
          overflow: 'visible',
        },
      }}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnMount>
        {({ submitForm, values, setFieldValue }) => (
          <>
            <ButtonPillSwitch
              defaultValue={manageInvitesType}
              values={MANAGE_INVITES_TYPE}
              onChange={(newTab: string) => {
                if (isSendingInvite) {
                  return;
                }
                setManageInvitesType(newTab as MANAGE_INVITES_TYPE);
              }}
              pillStyle={{ width: 'calc(100% / 2 - 5px)' }}
              textStyle={{ width: '50%', textTransform: 'capitalize' }}
              disableToggle={isSendingInvite}
            />
            <ParagraphSmall m='32px 0' width='100%' ellipsis>
              You will{' '}
              {manageInvitesType === MANAGE_INVITES_TYPE.SCHEDULED
                ? 'schedule'
                : 'send'}{' '}
              an invite for{' '}
              <strong>
                {users.length > 1
                  ? `${users.length} users.`
                  : `${users[0]?.firstName} ${users[0]?.lastName}.`}
              </strong>
            </ParagraphSmall>
            <div style={{ display: 'flex', flex: 1, width: '100%' }}>
              {manageInvitesType === MANAGE_INVITES_TYPE.SCHEDULED && (
                <Gap
                  gap='8px'
                  flexDirection='column'
                  width='100%'
                  m='0 0 32px 0'
                >
                  <Gap
                    flexDirection='column'
                    gap='4px'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    width='100%'
                  >
                    <ParagraphSmall color={colors.neutral[60]}>
                      Select Date
                    </ParagraphSmall>
                    <CustomDateTimePicker
                      disabled={isSendingInvite}
                      setDate={date => {
                        setFieldValue('customDateTimePicker', date);
                        setFieldValue(
                          'deliveryTime',
                          `${dayjs(date).format('YYYY-MM-DD;hh:mm;A')};${
                            values?.preferedUserTimezone?.utc[0] ||
                            timezonesOptions[0].utc[0]
                          }`
                        );
                      }}
                      date={values.customDateTimePicker}
                      useTimeSelectDropdown={true}
                    />
                  </Gap>
                  <Gap
                    flexDirection='column'
                    gap='4px'
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    width='100%'
                  >
                    <ParagraphSmall color={colors.neutral[60]}>
                      Timezone
                    </ParagraphSmall>
                    <TimezoneSelector
                      value={values.preferedUserTimezone?.value}
                      onChange={newValue => {
                        setFieldValue('preferedUserTimezone', newValue);
                        const newDeliveryTime = values.deliveryTime
                          .split(';')
                          .slice(0, -1)
                          .concat(newValue.utc[0] || timezonesOptions[0].utc[0])
                          .join(';');
                        setFieldValue('deliveryTime', `${newDeliveryTime}`);
                      }}
                      customComponents={{
                        ValueContainer: CustomValueContainer,
                      }}
                      disabled={isSendingInvite}
                      extendStyles={{
                        control: {
                          maxHeight: 40,
                          flexWrap: 'wrap',
                          width: '100%',
                        },
                        container: { width: '100%' },
                      }}
                    />
                  </Gap>
                </Gap>
              )}
            </div>
            <Button
              type='submit'
              text={`${
                manageInvitesType === MANAGE_INVITES_TYPE.SCHEDULED
                  ? 'Schedule'
                  : 'Send'
              } Invite`}
              icon={<MdScheduleSend size={18} />}
              onClick={submitForm}
              style={{ width: 'fit-content', marginLeft: 'auto' }}
              disabled={isSendingInvite}
            />
          </>
        )}
      </Formik>
    </NewModal>
  );
};
