import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdClose } from 'react-icons/md';

type TitleProps = {
  color?: string;
};
type CardProps = {
  top?: string;
  width?: string;
  backgroundColor?: string;
};
const Card = styled.div<CardProps>`
  position: absolute;
  width: ${props => (props.width ? props.width : 'auto')};
  top: ${props => (props.top ? props.top : '96px')};
  right: 32px;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : theme.palette.white};
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 12px 20px rgba(66, 79, 104, 0.06);
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 14px;
  z-index: 15;
  gap: 14px;
`;

const IconWrapper = styled.div`
  width: 20px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const CloseWrapper = styled.div`
  padding: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  width: auto;
  max-width: 460px;
`;

const Title = styled.div<TitleProps>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.color ? props.color : theme.palette.gray100)};
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blackRgb60};
`;

type Props = {
  icon?: React.ReactNode;
  title?: string;
  titleColor?: string;
  content?: React.ReactNode;
  handleClose: () => void;
  top?: string;
  width?: string;
  backgroundColor?: string;
};

export const CardAlert = ({
  icon,
  title,
  content,
  handleClose,
  titleColor,
  top,
  width,
  backgroundColor,
}: Props) => {
  return (
    <Card top={top} width={width} backgroundColor={backgroundColor}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <TextWrapper>
        {title && <Title color={titleColor}>{title}</Title>}
        {content && <Text>{content}</Text>}
      </TextWrapper>
      <CloseWrapper onClick={handleClose}>
        <MdClose size={20} color={theme.palette.gray40} />
      </CloseWrapper>
    </Card>
  );
};
