import styled from 'styled-components/macro';

export const CardWrapper = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 24px;
  width: 480px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
`;
export const Title = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #001b53;
`;
export const TextInfo = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #4e5461;
  max-width: 432px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 24px;
`;
