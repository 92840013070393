import { Gap } from 'lib/components/styles/layout';
import React from 'react';

export const EmptyMessageWrapper = () => {
  return (
    <Gap
      alignItems='center'
      justifyContent='center'
      m='0 auto'
      style={{ height: '100%', width: '80%' }}
    ></Gap>
  );
};
