import React from 'react';
import { Route, Switch } from 'react-router-dom';
import InventoryManagementSystem from '.';

export const ImsRoutes = () => {
  return (
    <Switch>
      <Route path='/admin/ims' component={InventoryManagementSystem} exact />
    </Switch>
  );
};
