import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  DAILY_TIME_RANGE_OPTIONS,
  MONTHLY_TIME_RANGE_OPTIONS,
  WEEKLY_TIME_RANGE_OPTIONS,
  YEARLY_TIME_RANGE_OPTIONS,
} from '../const';

import { CustomReportModalFormikValues, TIMEFRAME } from '../types';
import { CustomTimeframe } from './CustomTimeframe';
import { SelectTimeframe } from './SelectTimeframe';
import { TimeframeGroup } from './TimeframeGroup';
import { getDefaultTimeframeValue } from './utils';

interface IProps {
  updateRangeValue: (rangeValue: string) => void;
  currentRangeValue: string;
  timeframeOptions: Partial<typeof TIMEFRAME>;
}

export const Timeframe = ({
  currentRangeValue,
  updateRangeValue,
  timeframeOptions,
}: IProps) => {
  const { values } = useFormikContext<CustomReportModalFormikValues>();

  const [currentTimeframe, setCurrentTimeFrame] = useState<TIMEFRAME>(
    getDefaultTimeframeValue(currentRangeValue)
  );

  useEffect(() => {
    const newTab = getDefaultTimeframeValue(currentRangeValue);
    setCurrentTimeFrame(newTab);
  }, [values.staticRange, values.reccuringRange]);

  return (
    <>
      <SelectTimeframe
        setCurrentTimeFrame={setCurrentTimeFrame}
        currentTimeframe={currentTimeframe}
        updateRangeValue={updateRangeValue}
        timeframeOptions={timeframeOptions}
      />
      {currentTimeframe === TIMEFRAME.DAY && (
        <TimeframeGroup
          options={DAILY_TIME_RANGE_OPTIONS}
          currentRangeValue={currentRangeValue}
          updateRangeValue={updateRangeValue}
        />
      )}
      {currentTimeframe === TIMEFRAME.WEEK && (
        <TimeframeGroup
          options={WEEKLY_TIME_RANGE_OPTIONS}
          currentRangeValue={currentRangeValue}
          updateRangeValue={updateRangeValue}
        />
      )}
      {currentTimeframe === TIMEFRAME.MONTH && (
        <TimeframeGroup
          options={MONTHLY_TIME_RANGE_OPTIONS}
          currentRangeValue={currentRangeValue}
          updateRangeValue={updateRangeValue}
        />
      )}
      {currentTimeframe === TIMEFRAME.YEAR && (
        <TimeframeGroup
          options={YEARLY_TIME_RANGE_OPTIONS}
          currentRangeValue={currentRangeValue}
          updateRangeValue={updateRangeValue}
        />
      )}
      {currentTimeframe === TIMEFRAME.CUSTOM && <CustomTimeframe />}
    </>
  );
};
