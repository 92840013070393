import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

interface AuthData {
  username: string;
  password: string;
  remember?: boolean;
  jwt?: string;
  redirect?: string;
}

interface AuthResponse {
  tokenType: string;
  accessToken: string;
  refreshToken: string;
}

const authenticateUser = async (data: AuthData): Promise<AuthResponse> => {
  const url = data.redirect ? encodeURIComponent(data.redirect) : '';
  const response = await EXPRESS_API.post(`/auth/${url}`);
  return response.data;
};

export const useAuthUserMutation = () => {
  return useMutation(authenticateUser, {
    onSuccess: () => {
      successToast({ title: 'Authentication successful.' });
    },
    onError: () => {
      errorToast({ title: 'Authentication failed. Please try again.' });
    },
  });
};
