import React, { ReactElement, useState } from 'react';
import styled from 'styled-components/macro';
import { IoCaretDown } from 'react-icons/io5';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import { FaListOl, FaListUl } from 'react-icons/fa';
import { EditorCustomComponentProps } from './types';

const DropdownWrapper = styled.div`
  position: relative;
`;
const DropdownContent = styled.div`
  position: absolute;
  z-index: 999;
  right: 0;
  top: 100%;
  border: 1px solid #f1f1f1;
  width: 98%;
  background: ${theme.palette.white};
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(29, 30, 36, 0.06);
  border-radius: 5px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;
const DropdownItem = styled.div`
  cursor: pointer;
  padding: 0px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.palette.white};
  border: 1px solid #e1e2e5;
  border-radius: 4px;
  font-family: Work Sans;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  width: 43px;
  height: 26px;
  padding: 4px 8px;
  box-sizing: border-box;
`;
const Caret = styled(IoCaretDown)`
  &.up {
    transform: rotate(180deg);
  }
`;
type IconsType = { [key: string]: ReactElement };
const ICONS: IconsType = {
  unordered: <FaListUl color={theme.palette.primaryDarkBlue} size={12} />,
  ordered: <FaListOl color={theme.palette.primaryDarkBlue} size={12} />,
};

export const ListDropdown = ({
  config: { options },
  onChange,
  currentState,
}: EditorCustomComponentProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { ref } = useOnClickOutside(() => {
    setShowDropdown(false);
  });

  const chooseListStyle = (listStyle: string) => {
    onChange(listStyle);
    setShowDropdown(false);
  };

  return (
    <div ref={ref}>
      <DropdownWrapper>
        <DropdownButton
          onClick={() => setShowDropdown(!showDropdown)}
          className='toolbar-bullet-list-icon'
        >
          {ICONS[currentState?.listType as keyof IconsType] || ICONS.unordered}
          <Caret
            size={10}
            color={theme.palette.primaryDarkBlue}
            className={showDropdown ? 'up' : 'down'}
          />
        </DropdownButton>
        {showDropdown && (
          <DropdownContent>
            {options.map((option: string, i: number) => (
              <DropdownItem
                key={`dropdown-item-${i}`}
                onClick={() => chooseListStyle(option)}
              >
                {ICONS[option as keyof IconsType]}
              </DropdownItem>
            ))}
          </DropdownContent>
        )}
      </DropdownWrapper>
    </div>
  );
};
