import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/context';
import { checkIfFreemium } from 'lib/utils/functions';
import { AnnotationListItem } from '../types';
import { annotationsKeys } from './annotationsKeys';
import { wrapAnnotationWithProps } from './helper';

export const getVideoAnnotations = async (videoId: string) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}/annotations`);

  const { videoAnnotations, companyAnnotations } = response.data;

  const formattedVideoAnnotations = videoAnnotations?.map(
    (annotation: AnnotationListItem) => wrapAnnotationWithProps(annotation)
  );
  const formattedCompanyAnnotations = companyAnnotations?.map(
    (annotation: AnnotationListItem) =>
      wrapAnnotationWithProps(annotation, { isCompanyAnnotation: true })
  );

  return {
    videoAnnotations: formattedVideoAnnotations,
    companyAnnotations: formattedCompanyAnnotations,
  };
};

export const useGetAnnotationsQuery = (videoId: string) => {
  const { userData } = useAuth();
  const isFreemium = checkIfFreemium(userData);
  return useQuery(
    annotationsKeys.annotation(videoId),
    () => getVideoAnnotations(videoId),
    {
      enabled: !isFreemium && !!videoId,
    }
  );
};
