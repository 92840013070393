import {
  ChartDataPoint,
  HotspotResponse,
  VideoHotspotParams,
  VideoHotspotResponse,
} from './types';
import { useQuery } from 'react-query';
import { videoKeys } from './videosKeys';
import dayjs from 'dayjs';
import { utcAnalyticsTimeToUserTime } from 'lib/utils/time';
import { EXPRESS_API } from 'configs/api/expressApi';

const getVideoHotspots = async (
  params: VideoHotspotParams
): Promise<VideoHotspotResponse> => {
  const { videoId, onCountChange } = params;

  const data = (
    await EXPRESS_API.get(`videos/${videoId}/hotspots`, {
      params: {
        fetchRecipientDetails: true,
      },
    })
  ).data as HotspotResponse;

  let result = {
    views: 0,
    viewPercentage: '',
    hotspots: [],
    chart: [
      { x: 0, y: 0 },
      { x: 1000, y: 0 },
    ],
  } as VideoHotspotResponse;

  if (data && Array.isArray(data)) {
    onCountChange && onCountChange(data.length);
    const chart = [] as ChartDataPoint[];
    const percentages = [];

    for (let i = 0; i < data.length; i++) {
      let heatmap = data[i].heatMap.split(',');
      data[i].heatmapArray = heatmap;
      const splitDate = data[i].date.toString().split(' ');
      const date = splitDate[0].split('-');
      data[i].date =
        `${date[0]}/${date[1]}/${date[2]} ${splitDate[1]} ${splitDate[2]}`;
      const changeDateFormat = dayjs(data[i].date).format('YYYY-MM-DD HH:mm');
      data[i].date = utcAnalyticsTimeToUserTime(changeDateFormat);
      let viewPercentageCount = 0;

      for (let j = 0; j < heatmap.length; j++) {
        if (!chart[j]) {
          chart[j] = {
            x: (j + 1) * 1000,
            y: 0,
          };
        }
        if (heatmap[j] !== '0') {
          viewPercentageCount++;
          chart[j].y += parseInt(heatmap[j], 10);
        }
      }
      const vp = (viewPercentageCount / heatmap.length) * 100;
      data[i].percentage = vp.toFixed(1);
      percentages.push(vp);
    }

    if (chart.length) {
      chart.unshift({
        x: 0,
        y: chart[0].y,
      });
    }
    const views = data.length || 0;
    const viewPercentage = (
      percentages.reduce((sum, p) => sum + p, 0) / (percentages.length || 1)
    ).toFixed(1);

    // result update
    result.viewPercentage = viewPercentage;
    result.views = views;
    result.hotspots = data.reverse();
    result.chart = chart;
  }

  return result;
};

export const useVideoHotspots = (params: VideoHotspotParams) => {
  return useQuery(videoKeys.hotspots(params), () => getVideoHotspots(params));
};
