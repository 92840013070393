import { NewModal } from 'lib/components';
import { theme } from 'lib/style';
import React from 'react';

import { QuickFiltersBuilder } from '../QuickFiltersBuilder';

type ModalSelectFiltersProps = {
  handleClose: () => void;
};

const ModalSelectFilters = ({ handleClose }: ModalSelectFiltersProps) => {
  return (
    <NewModal
      style={{
        content: {
          padding: 0,
          top: 0,
          left: 'auto',
          right: 0,
          marginRight: 0,
          transform: 'translate(0, 0)',
          width: '75%',
          height: '100vh',
          borderRadius: 0,
          background: theme.palette.covideoBlue02NonAlpha,
        },
      }}
      closeModal={handleClose}
      hideHeader={true}
    >
      <QuickFiltersBuilder handleClose={handleClose} />
    </NewModal>
  );
};

export default ModalSelectFilters;
