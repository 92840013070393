import React, { useEffect, useState } from 'react';
import { ModalFullscreen } from './styles';
import { useHistory } from 'react-router-dom';
import { CustomReportModalHeader } from './CustomReportHeader';
import { CustomReportModalBreadcrumbs } from './CustomReportBreadcrumbs';
import {
  checkDuplicatesIn,
  getAdditionalRecipients,
  getSteps,
  getUserRecipients,
  timeRangeConstants,
} from './const';
import {
  CustomReportModalFormikValues,
  IRecipient,
  TYPE_OF_REPORT,
} from './types';
import { CustomReportBody } from './CustomReportBody';
import { Formik } from 'formik';
import { useCustomReportQuery } from 'lib/api/customReports/useCustomReportQuery';
import { useAuth } from 'lib/context';
import { LoadingIndicator } from 'lib/components';
import {
  ICreateCustomReportPayload,
  useCreateCustomReportMutation,
} from 'lib/api/customReports/useCreateCustomReportMutation';
import { useParams } from 'react-router';
import {
  ISendCustomReportPayload,
  useSendCustomReportMutation,
} from 'lib/api/customReports/useCustomReportSendMutation';
import {
  ICustomReportCreateReccuringPayload,
  useCreateReccuringReportMutation,
} from 'lib/api/customReports/useCreateReccuringReportMutation';
import { useEditCustomReportMutation } from 'lib/api/customReports/useEditCustomReportMutation';
import { useDeleteCustomReccuringReport } from 'lib/api/customReports/useDeleteCustomReccuringReport';
import { useEditReccuringReportMutation } from 'lib/api/customReports/useEditReccuringReportMutation';
import { useQueryClient } from 'react-query';
import { customReportKeys } from 'lib/api/customReports/customReportKeys';
import { calculateDatesForRange } from '../../components';
import * as Yup from 'yup';
import { timezonesOptions } from 'lib/components/timezoneSelector/TimezoneSelector';
import { NotFound } from 'app/pages/notFound/NotFound';

type Props = {
  selectedReportId?: string | undefined;
};

function validateUniqueReceivers(
  context: Yup.TestContext,
  value: IRecipient[],
  loggedInUserEmail: string
) {
  if (!value) {
    return true;
  }
  const userReceivers = getUserRecipients(value);
  const additionalRecipients = getAdditionalRecipients(value);
  const isLoggedInUserAdded = additionalRecipients.find(
    ar => ar.email === loggedInUserEmail
  );
  if (isLoggedInUserAdded) {
    return context.createError({
      path: `receivers`,
      message: `Additional recipients should not contains your email address.`,
    });
  }
  const isUnique =
    additionalRecipients.length ===
    new Set(additionalRecipients.map(r => r.email)).size;
  if (!isUnique) {
    return context.createError({
      path: `receivers`,
      message: `Additional recipients should be unique`,
    });
  }
  const hasDuplicates = checkDuplicatesIn(
    additionalRecipients,
    userReceivers.map(receiver => receiver.email)
  );
  if (hasDuplicates) {
    return context.createError({
      path: `receivers`,
      message: `User recipients and additional recipients should not have duplicate emails`,
    });
  }
  return true;
}
const customReportSchema = (loggedInUserEmail: string) => {
  return Yup.object().shape({
    title: Yup.string().required('Title is required!'),
    users: Yup.array().required('At least one user is required!').min(1),
    reports: Yup.array().required('At least one report is required!').min(1),
    isTimeFrameStep: Yup.boolean().required().oneOf([true]),
    receivers: Yup.array().test(
      'unique-email',
      'Emails must be unique',
      function (value) {
        return validateUniqueReceivers(this, value, loggedInUserEmail);
      }
    ),
  });
};

interface IPayload {
  values: CustomReportModalFormikValues;
  customReportPayload: ICreateCustomReportPayload;
  reccuringPayload: Omit<ICustomReportCreateReccuringPayload, 'reportId'>;
  sendReportPayload: Omit<ISendCustomReportPayload, 'reportId'>;
}

export const CreateCustomReportModal = ({ selectedReportId }: Props) => {
  const CURRENT_DATE = new Date();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const {
    mutateAsync: createCustomReportMutation,
    isLoading: creatingCustomReport,
  } = useCreateCustomReportMutation();

  const {
    mutateAsync: sendStaticReportMutation,
    isLoading: sendingStaticReport,
  } = useSendCustomReportMutation();

  const {
    mutateAsync: createReccuringReportMutation,
    isLoading: creatingReccuringReport,
  } = useCreateReccuringReportMutation();

  const {
    mutateAsync: editCustomReportMutation,
    isLoading: editingCustomReport,
  } = useEditCustomReportMutation();

  const {
    mutateAsync: deleteReccuringReportMutation,
    isLoading: deletingReccuringReport,
  } = useDeleteCustomReccuringReport();

  const {
    mutateAsync: editReccuringReportMutation,
    isLoading: editingReccuringReport,
  } = useEditReccuringReportMutation();
  const {
    userData: { isResellerAdmin, isCompanyAdmin, email, timezone, id },
  } = useAuth();

  const isLoading =
    creatingCustomReport ||
    sendingStaticReport ||
    creatingReccuringReport ||
    editingCustomReport ||
    deletingReccuringReport ||
    editingReccuringReport;

  const DEFAULT_TIMEZONE =
    timezonesOptions.find(option => !!option.utc.includes(timezone)) ||
    timezonesOptions[0];
  const { reportId } = useParams() as { reportId: string };

  const REPORT_ID = reportId || selectedReportId || undefined;

  const isEditing = !!REPORT_ID;

  const { data, isFetching } = useCustomReportQuery(REPORT_ID || '');

  const steps = getSteps({ isCompanyAdmin, isResellerAdmin });

  const goToPreviousStep = () => {
    setCurrentStepIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const goToNextStep = () => {
    setCurrentStepIndex(prevIndex => Math.min(prevIndex + 1, steps.length - 1));
  };

  const goToStep = (stepLabel: string) => {
    const stepIndex = steps.findIndex(step => step.label === stepLabel);
    setCurrentStepIndex(stepIndex);
  };

  const handleModalClose = () => {
    const url = !!reportId ? `/reports/custom/${reportId}` : '/reports/custom';
    history.push({ pathname: url });
  };

  const root = document.getElementById('root') as HTMLElement;
  useEffect(() => {
    // disable scroll of root when modal is active
    root.style.overflow = 'hidden';

    return () => {
      root.style.overflow = '';
    };
  }, []);

  if (isFetching) {
    return (
      <ModalFullscreen style={{ zIndex: 100 }}>
        <div>
          <LoadingIndicator isLoading={true} />
        </div>
      </ModalFullscreen>
    );
  }

  if (!data && isEditing) {
    return (
      <ModalFullscreen style={{ zIndex: 100 }}>
        <NotFound />
      </ModalFullscreen>
    );
  }
  const initUsers = isCompanyAdmin || isResellerAdmin ? [] : [id];

  const getRecipients = (values: string[] | IRecipient[]) => {
    const mappedRecipients = values.map((value: string | IRecipient) => {
      if (typeof value === 'string') {
        return {
          id: undefined,
          email: value,
        };
      }
      return value;
    });

    return mappedRecipients.filter(recipient => recipient.email !== email);
  };
  const recurrings = data?.recurrings || [];
  const isStaticReport = !recurrings[0]?.frequency;
  const receivers = !isStaticReport
    ? getRecipients(recurrings[0]?.recipients)
    : data?.recipients || [];

  const formikValues: CustomReportModalFormikValues = REPORT_ID
    ? {
        title: data.title,
        receivers: receivers,
        includeNewUsersInRecurring: !!recurrings[0]?.frequency
          ? !!recurrings[0]?.includeNewUsers
          : false,
        users: data.users,
        reports: data.reports || initUsers,
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate),
        typeOfReport: recurrings[0]?.frequency
          ? TYPE_OF_REPORT.RECURRING
          : TYPE_OF_REPORT.STATIC,
        staticRange: !recurrings[0]?.frequency
          ? data.range
          : timeRangeConstants.TODAY,
        reccuringRange: recurrings[0]?.frequency
          ? data.range
          : timeRangeConstants.TODAY,
        recurrings: {
          frequency: recurrings[0]?.frequency || 'daily;everyday',
          deliveryTime:
            recurrings[0]?.deliveryTime ||
            `01:00;AM;${DEFAULT_TIMEZONE.value};${DEFAULT_TIMEZONE.offset}`,
          recurringId: recurrings[0]?.recurringId || '',
          reportId: recurrings[0]?.reportId || '',
        },
        reportId: data?.reportId || undefined,
        usersData: data?.usersData || [],
        isTimeFrameStep: true,
        customerIds: data?.customerIds || [],
        includeNewUsers: !!data?.includeNewUsers,
      }
    : {
        includeNewUsersInRecurring: false,
        receivers: [],
        title: '',
        users: initUsers,
        reports: [],
        startDate: CURRENT_DATE,
        endDate: CURRENT_DATE,
        typeOfReport: TYPE_OF_REPORT.RECURRING,
        staticRange: timeRangeConstants.TODAY,
        reccuringRange: timeRangeConstants.TODAY,
        recurrings: {
          frequency: 'daily;everyday',
          deliveryTime: `01:00;AM;${DEFAULT_TIMEZONE.value};${DEFAULT_TIMEZONE.offset}`,
        },
        reportId: undefined,
        usersData: [],
        isTimeFrameStep: false,
        customerIds: [],
        includeNewUsers: true,
      };

  const createCustomReportHandler = async ({
    customReportPayload,
    sendReportPayload,
    reccuringPayload,
    values,
  }: IPayload) => {
    // create report
    const report = await createCustomReportMutation(customReportPayload);
    // If the report is static and has receivers, send an email.
    if (
      !!values.receivers.length &&
      TYPE_OF_REPORT.STATIC === values.typeOfReport
    ) {
      sendStaticReportMutation({
        reportId: report.reportId,
        ...sendReportPayload,
      });
    }
    // If the report is recurring, create a new recurring report.
    if (TYPE_OF_REPORT.RECURRING === values.typeOfReport) {
      createReccuringReportMutation({
        ...reccuringPayload,
        reportId: report.reportId,
      });
    }
    queryClient.invalidateQueries(customReportKeys.all());
    history.push({ pathname: `/reports/custom/${report.reportId}` });
  };

  const editCustomReportHandler = async ({
    customReportPayload,
    sendReportPayload,
    reccuringPayload,
    values,
  }: IPayload) => {
    // edit report
    const report = await editCustomReportMutation({
      reportId: REPORT_ID as string,
      ...customReportPayload,
    });
    // If the report is static and has receivers, send an email.
    if (
      !!values.receivers.length &&
      TYPE_OF_REPORT.STATIC === values.typeOfReport
    ) {
      await sendStaticReportMutation({
        reportId: report.reportId,
        ...sendReportPayload,
      });
    }
    // If the report is static, delete existing recurring report.
    if (TYPE_OF_REPORT.STATIC === values.typeOfReport) {
      if (values?.recurrings?.recurringId) {
        await deleteReccuringReportMutation({
          recurringId: `${values?.recurrings?.recurringId}`,
          reportId: REPORT_ID as string,
        });
      }
    }
    // Check if the report is recurring. If it is recurring, check if it already exists.
    // If it exists, edit the existing recurring report. If it doesn't exist, create a new recurring report.
    if (TYPE_OF_REPORT.RECURRING === values.typeOfReport) {
      if (!!values?.recurrings?.recurringId) {
        await editReccuringReportMutation({
          ...reccuringPayload,
          reportId: report.reportId,
          recurringId: `${values?.recurrings?.recurringId}`,
        });
      } else {
        await createReccuringReportMutation({
          ...reccuringPayload,
          reportId: report.reportId,
        });
      }
    }
    await queryClient.invalidateQueries(customReportKeys.all());
  };

  const onSubmit = async (values: CustomReportModalFormikValues) => {
    const range =
      TYPE_OF_REPORT.STATIC === values.typeOfReport
        ? values.staticRange
        : values.reccuringRange;

    const recipients = [...values.receivers, { id, email }];
    const { start, end } = calculateDatesForRange(range);

    const customReportPayload = {
      title: values.title,
      range: range,
      startDate: range === timeRangeConstants.CUSTOM ? values.startDate : start,
      endDate: range === timeRangeConstants.CUSTOM ? values.endDate : end,
      reports: values.reports,
      users: values.users,
      customerIds: values.customerIds,
      includeNewUsers: Object.keys(values).includes('includeNewUsers')
        ? values.includeNewUsers
        : true,
      recipients:
        TYPE_OF_REPORT.STATIC === values.typeOfReport ? recipients : [],
    };

    const reccuringPayload = {
      includeNewUsers: values.includeNewUsersInRecurring,
      frequency: values.recurrings.frequency,
      deliveryTime: values.recurrings.deliveryTime,
      recipients,
    };

    const sendReportPayload = {
      range:
        TYPE_OF_REPORT.STATIC === values.typeOfReport
          ? values.staticRange
          : values.reccuringRange,
      receivers: values.receivers,
      from: range === timeRangeConstants.CUSTOM ? values.startDate : start,
      to: range === timeRangeConstants.CUSTOM ? values.endDate : end,
    };

    if (REPORT_ID === undefined) {
      await createCustomReportHandler({
        values,
        customReportPayload,
        reccuringPayload,
        sendReportPayload,
      });
      return;
    }

    await editCustomReportHandler({
      values,
      customReportPayload,
      reccuringPayload,
      sendReportPayload,
    });

    history.push({ pathname: `/reports/custom/${reportId}` });
  };

  return (
    <ModalFullscreen style={{ zIndex: 100, overflow: 'scroll' }}>
      {isFetching ? (
        <div>
          <LoadingIndicator isLoading={true} />
        </div>
      ) : (
        <Formik
          initialValues={formikValues}
          onSubmit={onSubmit}
          validationSchema={customReportSchema(email)}
          validateOnMount
        >
          {({ submitForm }) => (
            <>
              <CustomReportModalHeader
                handleModalClose={handleModalClose}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
                submitForm={submitForm}
                currentStepIndex={currentStepIndex}
                isEdit={isEditing}
                isLoading={isLoading}
                steps={steps}
              />
              <CustomReportModalBreadcrumbs
                currentStepIndex={currentStepIndex}
                steps={steps}
                onBreadcrumbClick={step => goToStep(step)}
              />
              <CustomReportBody
                currentStepIndex={currentStepIndex}
                steps={steps}
              />
            </>
          )}
        </Formik>
      )}
    </ModalFullscreen>
  );
};
