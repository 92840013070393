import { deliveryStats } from 'lib/api';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Cell, Pie, PieChart } from 'recharts';
import { WidgetWrapper } from '../widgets/common/styles';
import styled from 'styled-components/macro';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import { calculateTotal } from '../../utils';
import { widget } from './keys';
import { REPORTS_STALE_TIME, WidgetLabels, WidgetValues } from './types';

const PieParagraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: rgba(0, 27, 83, 0.8);
`;

const PieDataParagraph = styled.p`
  position: absolute;
  color: #001b53;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  top: 37%;
  margin: 0;
`;

const MainPieWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const PieWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
const WidgetTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #001b53;
  margin: 0 0 16px 0;
`;
const AdditionalInfo = styled.div<{ mb?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ mb }) => mb || 0};
`;
const AdditionalInfoTitle = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;

  color: #272a32;
`;

const AdditionalInfoData = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #272a32;
`;

const pies = [
  { id: 'videos', label: 'recorded' },
  { id: 'sentEmails', label: 'sent' },
  { id: 'views', label: 'viewed' },
];

const VideoDelivery = ({ params, addDataForExport }: any) => {
  const { data: videoDeliveryStats, isLoading, isRefetching } = useQuery(
    widget.detail(WidgetValues.VIDEO_DELIVERY, params),
    () => deliveryStats({ ...params, users: 'all' }),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const clicks = calculateTotal(videoDeliveryStats?.clicks);

  useEffect(() => {
    if (videoDeliveryStats) {
      const sentEmails = videoDeliveryStats?.sentEmails;
      const views = videoDeliveryStats?.views;
      const videos = videoDeliveryStats?.videos;
      const engagements = videoDeliveryStats?.engagements;

      const xmlData = {
        title: WidgetLabels.VIDEO_DELIVERY,
        data: [
          {
            Recorded: videos,
            'Unique sent': sentEmails,
            Viewed: views,
            'Total CTA clicks': clicks?.total,
            'Av. Eng. Rate': `${engagements?.engagement}%`,
          },
        ],
      };
      addDataForExport(xmlData);
    }
  }, [videoDeliveryStats]);

  if (isLoading || !videoDeliveryStats) {
    return <SkeletonLoader imageName='three-circles' />;
  }

  const transformData: Record<string, number> = {
    views: videoDeliveryStats.views,
    videos: videoDeliveryStats.videos,
    engagements: +videoDeliveryStats.engagements?.engagement || 0,
    sentEmails: videoDeliveryStats.sentEmails,
  };

  return (
    <WidgetWrapper>
      <WidgetTitle>
        {WidgetLabels.VIDEO_DELIVERY}
        {isRefetching && <span> Updating ...</span>}
      </WidgetTitle>
      <MainPieWrapper>
        {pies.map(({ id, label }) => {
          const value: number = transformData?.[id];
          const total = transformData?.['videos'];

          const pieData = [
            {
              name: 'total',
              value: total === 0 ? 1 : id === 'videos' ? 0 : total,
            },
            {
              name: 'unused',
              value: value,
            },
          ];

          return (
            <PieWrapper key={id}>
              <PieDataParagraph>{value}</PieDataParagraph>
              <PieChart
                width={110}
                height={110}
                style={{ transform: 'rotate(270deg)' }}
              >
                <Pie
                  direction=''
                  data={pieData}
                  innerRadius={30}
                  outerRadius={45}
                  fill='#8884d8'
                  paddingAngle={0}
                  dataKey='value'
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.name !== 'total'
                          ? 'rgba(0, 27, 83, 1)'
                          : 'rgba(0, 27, 83, 0.2)'
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
              <PieParagraph>
                {label === 'sent' ? 'unique sent' : label}
              </PieParagraph>
            </PieWrapper>
          );
        })}
      </MainPieWrapper>

      <AdditionalInfo mb={'10px'}>
        <AdditionalInfoTitle>Average engagement rate</AdditionalInfoTitle>

        <AdditionalInfoData>{transformData?.engagements}%</AdditionalInfoData>
      </AdditionalInfo>
      <AdditionalInfo>
        <AdditionalInfoTitle>Total CTA clicks</AdditionalInfoTitle>
        <AdditionalInfoData>{clicks?.total ?? 'N?A'}</AdditionalInfoData>
      </AdditionalInfo>
    </WidgetWrapper>
  );
};

export default VideoDelivery;
