import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { MEETING_STATUSES, MeetingStatus } from 'lib/api/meetings/types';
import { Button } from 'react-covideo-common';

interface RecurringActionProps {
  handleOpenLiveDetailsModal: (
    meetingId: string | null,
    meetingStatus: MeetingStatus
  ) => void;
}

export const RecurringAction = ({
  handleOpenLiveDetailsModal,
}: RecurringActionProps) => {
  const onCreateRecurringClick = async () => {
    handleOpenLiveDetailsModal(null, MEETING_STATUSES.RECURRING);
  };

  return (
    <>
      <Button
        variant='primary'
        onClick={onCreateRecurringClick}
        icon={<FiPlus color='white' size={20} />}
        text='Create Recurring'
      />
    </>
  );
};
