import { useEffect, useRef, useState } from 'react';
import RecordRTC from 'recordrtc';
import { useCountdown } from './useRecorderCountdown';
import { useRecordingTime } from './useRecordingTime';
import { RECORDING_STATUS } from './types';
import { useRecordSettings } from 'lib/context/record/provider';
import { errorToast } from 'lib/components/toasts/error';

interface UseRecorderParams {
  screenStream: MediaStream;
  stopAllStreams?: () => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
  isSegmenterActive?: boolean;
  onStartUpladingVoiceOverHandler?: (blob: Blob) => void;
  shouldStartCountdown: boolean;
  handleGoToRecordHome: () => void;
}

const BITRATES: { [key: string]: number } = {
  low: 1000000,
  standard: 2500000,
  high: 5000000,
};

const combineVideoAndAudioStreams = (
  videoStream: MediaStream,
  audioStream: MediaStream
): MediaStream => {
  const combinedStream = new MediaStream();

  // Add the video track from the video stream
  const videoTracks = videoStream.getVideoTracks();
  videoTracks.forEach(track => combinedStream.addTrack(track));

  // Add the audio tracks from the audio stream
  const audioTracks = audioStream.getAudioTracks();
  audioTracks.forEach(track => combinedStream.addTrack(track));

  return combinedStream;
};

interface UseRecorderReturn {
  startRecording: () => void;
  stopRecording: () => void;
  pauseRecording: () => void;
  startCountDown: () => void;
  stopCountDown: () => void;
  status: RECORDING_STATUS;
  recordedBlob: Blob | null;
  countdown: number;
  timeInSeconds: number;
  setStatus: React.Dispatch<React.SetStateAction<RECORDING_STATUS>>;
}
const COUNTDOWN = 3;
const useRecorder = ({
  screenStream,
  stopAllStreams,
  videoRef,
  canvasRef,
  isSegmenterActive,
  onStartUpladingVoiceOverHandler,
  shouldStartCountdown,
  handleGoToRecordHome,
}: UseRecorderParams): UseRecorderReturn => {
  const { countdown, startRecordingCountdown, stopRecordingCountdown } =
    useCountdown(COUNTDOWN);
  const { startRecordingTime, stopRecordingTime, timeInSeconds } =
    useRecordingTime();
  const { userSettings } = useRecordSettings();
  const recorderRef = useRef<RecordRTC | null>(null);
  const [status, setStatus] = useState<RECORDING_STATUS>(RECORDING_STATUS.IDLE);
  const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
  const videoBitsPerSecond =
    BITRATES?.[userSettings?.videoQuality] || BITRATES.low;

  useEffect(() => {
    return () => {
      stopAllStreams?.();
      if (recorderRef.current) {
        recorderRef.current.stopRecording(() => {
          recorderRef.current!.destroy();
          recorderRef.current = null;
        });
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current && screenStream) {
      videoRef.current.srcObject = screenStream;

      videoRef.current.muted = true;
      videoRef.current.volume = 0;
      videoRef.current.onloadedmetadata = () => {
        setStatus(RECORDING_STATUS.CAMERA_READY);
        shouldStartCountdown && startCountDown();
      };
    }
  }, [screenStream]);

  // videoTrack.onended
  useEffect(() => {
    if (videoRef.current && screenStream) {
      const videoTrack = screenStream.getVideoTracks()[0];

      videoTrack.onended = () => {
        if (
          status === RECORDING_STATUS.RECORDING ||
          status === RECORDING_STATUS.PAUSED
        ) {
          return stopRecording();
        }
        handleGoToRecordHome?.();
      };
    }
  }, [screenStream, status]);

  useEffect(() => {
    if (countdown === 0 && status === RECORDING_STATUS.COUNTDOWN) {
      startRecording();
    }
  }, [countdown, status]);

  const getCombinedStream = (screenStream: MediaStream) => {
    if (isSegmenterActive) {
      const canvasStream = canvasRef?.current?.captureStream(30);
      const combinedStream = canvasStream
        ? combineVideoAndAudioStreams(canvasStream, screenStream)
        : screenStream;

      return combinedStream;
    }
    return screenStream;
  };

  const startRecording = () => {
    if (screenStream) {
      const currentStream = getCombinedStream(screenStream);
      try {
        recorderRef.current = new RecordRTC(currentStream, {
          videoBitsPerSecond,
          type: 'video',
        });

        recorderRef.current.startRecording();
        setStatus(RECORDING_STATUS.RECORDING);

        startRecordingTime();
      } catch (error) {
        console.error('Failed to start recording:', error);
        errorToast({ title: 'Failed to start recording' });
      }
    } else {
      errorToast({ title: 'Stream is not initialized.' });
    }
  };

  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        setRecordedBlob(recorderRef.current!.getBlob());
        stopAllStreams?.();
        setStatus(RECORDING_STATUS.DONE);
        onStartUpladingVoiceOverHandler?.(recorderRef.current!.getBlob());
        recorderRef.current!.destroy();
        recorderRef.current = null;
      });

      stopRecordingTime();
    }
  };

  const pauseRecording = () => {
    if (recorderRef.current && status === RECORDING_STATUS.RECORDING) {
      recorderRef.current.pauseRecording();
      setStatus(RECORDING_STATUS.PAUSED);
      stopRecordingTime();
    } else if (recorderRef.current && status === RECORDING_STATUS.PAUSED) {
      recorderRef.current.resumeRecording();
      setStatus(RECORDING_STATUS.RECORDING);
      startRecordingTime();
    }
  };

  const startCountDown = () => {
    setStatus(RECORDING_STATUS.COUNTDOWN);
    startRecordingCountdown();
  };

  const stopCountDown = () => {
    setStatus(RECORDING_STATUS.CAMERA_READY);
    stopRecordingCountdown();
  };

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    startCountDown,
    stopCountDown,
    setStatus,
    status,
    recordedBlob,
    countdown,
    timeInSeconds,
  };
};

export default useRecorder;
