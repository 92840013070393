import React, { useState } from 'react';
import { AutomotiveRole, VideoEmailStatus, VideoStatus } from 'lib/const';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { startCase, toLower } from 'lodash';
import { VideoItemType } from './VideoItem';
import { HoverPopup } from 'lib/components';
import { VideoActivities } from './VideoActivities';
import { useGetVideoActivities } from 'lib/api/videoActivities/getVideoActivities';
import { VideoActivity } from 'lib/context';

type StatusProps = {
  status: string | undefined;
};
const Wrapper = styled.div`
  display: block;
`;
const Status = styled.div<StatusProps>`
  ${props => {
    if (props.status === VideoStatus.Approved) {
      return `
      background:${theme.palette.green05};
      color:${theme.palette.green80};      
      `;
    } else if (props.status === VideoStatus.Denied) {
      return `
      background:${theme.palette.red05};
      color:${theme.palette.red80};      
      `;
    } else if (props.status === VideoStatus.Pending) {
      return `
      background:${theme.palette.gray10};
      color:${theme.palette.gray80};      
      `;
    } else if (props.status === VideoEmailStatus.Watched) {
      return `
      background:${theme.palette.green05};
      color:${theme.palette.green80};      
      `;
    } else if (props.status === VideoEmailStatus.Sent) {
      return `
      background:${theme.palette.blue05};
      color:${theme.palette.blue80};     
      `;
    } else if (
      props.status === VideoEmailStatus.NotSent ||
      props.status === VideoEmailStatus.Deleted
    ) {
      return `
      background:${theme.palette.red05};
      color:${theme.palette.red80};    
      `;
    } else {
      return `
      background:${theme.palette.gray10};
      color:${theme.palette.gray80};      
      `;
    }
  }};
  border-radius: 5px;
  padding: 2px 11px;
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
  position: relative;
`;
type Props = {
  status?: VideoStatus;
  emailStatus?: VideoEmailStatus;
  videoItemType?: VideoItemType;
  automotiveRole?: string;
  videoId: string;
};
export const VideoItemStatus = ({
  status,
  emailStatus,
  videoItemType,
  automotiveRole,
  videoId,
}: Props) => {
  const { refetch } = useGetVideoActivities(videoId);
  const [delayHandler, setDelayHandler] = useState<any>(null);
  const [activities, setActivities] = useState<VideoActivity[]>([]);

  const fetchActivities = async () => {
    setDelayHandler(
      setTimeout(async () => {
        const response = await refetch().catch(err => err);
        if (response instanceof Error) {
          setActivities([{ activityText: 'Error loading activities.' }]);
          return;
        }
        if (!response?.data?.length) {
          setActivities([{ activityText: 'No activities to show.' }]);
          return;
        }
        setActivities(response.data);
      }, 500)
    );
  };

  let selectedStatus: string | undefined = status;
  if (
    videoItemType === VideoItemType.Video &&
    automotiveRole !== AutomotiveRole.TECHNICIAN
  ) {
    selectedStatus = emailStatus;
  }

  const prepareStatusForDisplay = (status: string = '') => {
    if (status === VideoStatus.Pending) {
      status = 'Awaiting Approval';
    }
    if (status === VideoEmailStatus.Watched) {
      status = 'Viewed';
    }
    return startCase(toLower(status));
  };

  return (
    <Wrapper
      onMouseEnter={() => fetchActivities()}
      onMouseLeave={() => {
        clearTimeout(delayHandler);
      }}
    >
      <HoverPopup
        width='auto'
        position='right'
        padding='12px 16px'
        hoverable={
          !!status ? (
            <Status status={selectedStatus}>
              {prepareStatusForDisplay(selectedStatus)}
            </Status>
          ) : (
            <Status status={selectedStatus}>Video Activity</Status>
          )
        }
        popup={<VideoActivities activities={activities} />}
      />
    </Wrapper>
  );
};
