export const getRuleOptions = (ruleKey: string) => {
  switch (ruleKey) {
    case 'engagement_rate':
    case 'cta_clicks':
      return [
        { value: '$gt', label: 'Greater then' },
        { value: '$lt', label: 'Less then' },
      ];
    case 'lead_source':
      return [
        { value: '$eq', label: 'Equal' },
        { value: '$ne', label: 'Non Equal' },
      ];

    default:
      return [
        { value: '$gt', label: 'Greater then' },
        { value: '$lt', label: 'Less then' },
      ];
  }
};

export const getRulesValuesOptions = (ruleKey: string) => {
  switch (ruleKey) {
    case 'engagement_rate':
      return [
        { value: '25', label: '25%' },
        { value: '50', label: '50%' },
        { value: '75', label: '75%' },
      ];
    case 'cta_clicks':
      return [
        { value: '0', label: '0' },
        { value: '5', label: '5' },
        { value: '10', label: '10' },
      ];
    case 'lead_source':
      return [
        { value: 'elead', label: 'Elead' },
        { value: 'dominion', label: 'Dominion' },
        { value: 'dealer-socket', label: 'Dealer socket' },
        { value: 'vinsolutions', label: 'Vin solution' },
      ];
    default:
      return [
        { value: '25', label: '25%' },
        { value: '50', label: '50%' },
        { value: '75', label: '75%' },
      ];
  }
};

interface Option {
  value: string;
  label: string;
}
export const getInitialValues = (options: Option[], key: string) => {
  return options.find(query => query.value === key) || options[0];
};
