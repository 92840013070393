import React from 'react';
import { CONNECTION_BUTTON_TYPES } from './constants';
import { ConnectButton } from './style';

interface ConnectionButtonProps {
  type: CONNECTION_BUTTON_TYPES;
  handleClick: () => void;
}

export const ConnectionButton = ({
  type,
  handleClick,
}: ConnectionButtonProps) => {
  switch (type) {
    case CONNECTION_BUTTON_TYPES.RECONNECT:
      return (
        <ConnectButton
          onClick={handleClick}
          bgColor="#EEEFF2"
          fontColor="#272A32"
        >
          Reconnect
        </ConnectButton>
      );
    case CONNECTION_BUTTON_TYPES.DISCONNECT:
      return (
        <ConnectButton
          onClick={handleClick}
          bgColor="#FEF6F5"
          fontColor="#E84C3D"
        >
          Disconnect
        </ConnectButton>
      );
    default:
      return null;
  }
};
