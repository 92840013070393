import { LeftWrapper, MeetingDetailsBodyWrapper } from '../styles';
import MeetingDetailsForm from '../components/forms/MeetingDetailsForm';
import MeetingInfoBanner from '../components/MeetingInfoBanner';
import React from 'react';
import { MeetingResponse, MeetingStatus } from 'lib/api/meetings/types';

interface IProps {
  meetingDetails: MeetingResponse | undefined;
  userOptions: {
    value: string;
    label: string;
  }[];
  createMeetingStatus: MeetingStatus | null;
}

export const MeetingTabDetails = ({
  meetingDetails,
  userOptions,
  createMeetingStatus,
}: IProps) => {
  return (
    <MeetingDetailsBodyWrapper>
      <LeftWrapper>
        <MeetingDetailsForm
          userOptions={userOptions}
          meetingDetails={meetingDetails}
          createMeetingStatus={createMeetingStatus}
        />
      </LeftWrapper>
      <MeetingInfoBanner meetingId={meetingDetails?.meetingId ?? null} />
    </MeetingDetailsBodyWrapper>
  );
};
