import { Search, Dropdown } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import React, { useEffect, useState } from 'react';
import { ButtonSwitch } from '../../ButtonSwitch';

interface IProps {
  onSearch: (value: string) => void;
  departmentsOptions:
    | {
        value: number | null;
        label: string;
      }[]
    | undefined;
  onCustomerDropdownChangeHandler: (value: {
    label: string;
    value: number;
  }) => void;
  departmentId: number | null;
  isAllChecked: boolean;
  onSwitchButtonChange: (value: string) => void;
  defaultValue: 'all' | 'manually';
  isRefetching: boolean;
}

export const SelectableUsersHeader = ({
  onSearch,
  onCustomerDropdownChangeHandler,
  departmentsOptions,
  departmentId,
  isAllChecked,
  onSwitchButtonChange,
  defaultValue,
  isRefetching,
}: IProps) => {
  const hasOneDepartmentsItem = departmentsOptions?.length === 1;
  const searchWidth = hasOneDepartmentsItem ? '100%' : 'calc(50% - 6px)';
  const [switchDefaultValye, setSwitchDefaultValue] = useState(
    defaultValue || 'manually'
  );

  useEffect(() => {
    if (defaultValue) {
      setSwitchDefaultValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Gap flexDirection='row' gap='12px' width='100%'>
      <ButtonSwitch
        disabled={isRefetching}
        defaultValue={switchDefaultValye}
        onChange={onSwitchButtonChange}
        values={[
          { value: 'all', text: 'All' },
          { value: 'manually', text: 'Manually' },
        ]}
      />
      {!isAllChecked && (
        <Search
          onSearch={onSearch}
          width={searchWidth}
          disabled={isRefetching}
        />
      )}
      {!isAllChecked && !hasOneDepartmentsItem && (
        <Dropdown
          creatable={false}
          onChange={value => onCustomerDropdownChangeHandler?.(value)}
          options={departmentsOptions || null}
          value={departmentsOptions?.find(
            department => department.value === departmentId
          )}
          extendStyles={{ container: { width: 'calc(50% - 6px)' } }}
          placeholder='All Departments'
          className='dropdown'
        />
      )}
    </Gap>
  );
};
