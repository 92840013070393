import { useFormikContext } from 'formik';
import { Gap } from 'lib/components/styles/layout';
import { SubHeading, ParagraphSmall } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import { checkIfVideoRequestFieldsRequired } from 'lib/utils/automotiveRolePermissionChecks';
import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import { DetailsPreviewRecordFormValues } from '../types';

export const DetailsPreviewAttributesHeader = () => {
  const { userData } = useAuth();
  const { colors } = useCovideoTheme();
  const { values } = useFormikContext<DetailsPreviewRecordFormValues>();

  const videoRequestFieldsRequired =
    checkIfVideoRequestFieldsRequired(userData);

  const getRequiredAttributesCount = () => {
    let count = 0;
    values?.attributes?.forEach(attribute => {
      if (attribute && attribute.isRequired) {
        count++;
      }
    });

    if (videoRequestFieldsRequired)
      // two fields from video req Details are req
      count += 2;

    return count;
  };

  const totalRequiredAttributes = getRequiredAttributesCount();
  return (
    <Gap justifyContent='space-between' m='0 0 16px 0'>
      <SubHeading>Video Attributes </SubHeading>
      <ParagraphSmall color={colors.primary[100]}>
        {totalRequiredAttributes} required
      </ParagraphSmall>
    </Gap>
  );
};
