import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';

import { useToastError } from 'lib/hooks/useToastError';
import { successToast } from 'lib/components/toasts/success';
import { conversationsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const deleteConversation = async (conversationId: string): Promise<any> => {
  return (await EXPRESS_API.delete(`conversations/${conversationId}`)).data;
};

export const useDeleteConversationMutation = () => {
  const { showError } = useToastError();
  // TO DO -> use conversation controller - DONE
  // After the chat is deleted it can be reopened - talk about the logic how we will approach this => Currently if I reopen the closed chat and if I want to delete it again it says conversation already deleted
  const queryClient = useQueryClient();
  const history = useHistory();
  const { id } = useParams() as { id: string; type: string };

  return useMutation(deleteConversation, {
    onError: (err: AxiosError) => showError(err),
    onSuccess: async () => {
      await queryClient.invalidateQueries(conversationsKeys.detail(id));
      successToast({ title: 'Your conversation is deleted successfully' });
      queryClient.invalidateQueries(conversationsKeys.lists());

      history.replace({
        pathname: `/conversations`,
      });
    },
  });
};
