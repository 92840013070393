import { ResellerCustomer } from 'lib/api/superadmin/useCustomersResellersQuery';
import React from 'react';

import { Customer } from './Customer';

interface IProps {
  setSelectedCustomersHandler: (customer: ResellerCustomer) => void;
  setSelectedUsersHandler: (userId: string, customerId: string) => void;
  customerList: ResellerCustomer[];
  customerState: {
    [key: string]: string[];
  };
}

export const CustomerList = ({
  setSelectedCustomersHandler,
  setSelectedUsersHandler,
  customerList,
  customerState,
}: IProps) => {
  return (
    <>
      {customerList.map(customer => {
        return (
          <Customer
            key={customer.customerId}
            customerState={customerState}
            customer={customer}
            setSelectedCustomersHandler={setSelectedCustomersHandler}
            setSelectedUsersHandler={setSelectedUsersHandler}
          />
        );
      })}
    </>
  );
};
