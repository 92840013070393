import {
  IResellerCustomersResponse,
  ResellerCustomer,
  ResellerUser,
} from 'lib/api/superadmin/useCustomersResellersQuery';
import { CustomReportModalFormikValues } from '../../types';

export function calculateNonEmptyKeysAndTotalItems(data: {
  [key: string]: string[];
}) {
  let selectedUsers = 0;
  let selectedCompanies = 0;

  Object.keys(data).forEach(key => {
    if (data[key].length > 0) {
      selectedCompanies++;
      selectedUsers += data[key].length;
    }
  });

  return { selectedUsers, selectedCompanies };
}

export function getTotalUsers(
  customers: ResellerCustomer[] | undefined
): number {
  return !customers
    ? 0
    : customers.reduce(
        (total, customer) => total + customer?.users?.length || 0,
        0
      );
}

export function createInitialState(
  resellerCustomersResponse: IResellerCustomersResponse | undefined,
  userIds: string[]
): { [key: number]: string[] } {
  const initialState: { [key: number]: string[] } = {};

  if (!resellerCustomersResponse) {
    return initialState;
  }

  resellerCustomersResponse.customers.forEach((customer: ResellerCustomer) => {
    const customerId = customer.customerId;
    if (!initialState[customerId]) {
      initialState[customerId] = [];
    }
    customer.users.forEach((user: ResellerUser) => {
      if (userIds.includes(user.id.toString())) {
        initialState[customerId].push(user.id.toString());
      }
    });
  });

  return initialState;
}

export function getAllUserIds(updatedState: {
  [key: string]: string[];
}): string[] {
  return Object.values(updatedState).flat();
}

export const getInitialCustomersFromUserData = (
  values: CustomReportModalFormikValues
): ResellerCustomer[] => {
  const userMap: Map<number, ResellerUser[]> = new Map();

  values.usersData.forEach(item => {
    const user: ResellerUser = {
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      mlAutoCreated: !!item.mlAutoCreated,
      access: 0, // Set access level to a default value or determine based on data
      departmentId: item.deptId ?? 0,
      customerId: item.customerId,
      resellerId: 0,
    };

    if (userMap.has(item.customerId)) {
      userMap.get(item.customerId)?.push(user);
    } else {
      userMap.set(item.customerId, [user]);
    }
  });

  return Array.from(userMap.entries()).map(([customerId, users]) => {
    const business =
      values.usersData.find(item => item.customerId === customerId)?.business ??
      '';

    const departments = values.usersData
      .filter(item => item.customerId === customerId && item.deptId !== null)
      .map(item => ({
        id: item.deptId,
        name: item.dept ?? '',
        customerId: item.customerId,
        resellerId: 0,
      }));

    return {
      resellerId: 0,
      customerId,
      business,
      users,
      departments,
    };
  });
};
