import {
  CONVERSATION_TYPES,
  ISingleConversation,
  ISingleMessage,
} from 'lib/api/conversations/getSingleConversation';
import { useSendSMSMutation } from 'lib/api/conversations/sendSMSMessage';
import { useMarkAsReadConversationMutation } from 'lib/api/conversations/setConversationAsRead';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphExtraSmallBold } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import { URL_REGEX } from 'lib/utils/regexes';
import { calculateDiff } from 'lib/utils/time';
import { orderBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIsMutating } from 'react-query';
import { IConversationBody } from '../conversations.types';
import {
  ChatBody,
  MessagesContainer,
  ReceivedMessages,
  ReceivedMessageInfo,
  ChatBodyWithOutData,
  EmptyMsg,
  NewMessage,
  NewMessageWrapper,
} from '../styles/UsersConversationsLayout';
import {
  getIntegrationType,
  deleteHTMLTags,
  getConversationHeader,
  isAssignedToYou,
} from '../utils/helper';

const detectUrlAndText = (txt: any) => {
  const cleanTxt = deleteHTMLTags(txt);
  return (
    cleanTxt?.split(' ')?.map((part: any, i: any) =>
      URL_REGEX.test(part) ? (
        <a
          key={`links-${i}`}
          target='_blank'
          href={part}
          rel='noreferrer'
          style={{ color: 'inherit' }}
        >
          {part}{' '}
        </a>
      ) : (
        part + ' '
      )
    ) || 'Something went wrong with message'
  );
};

const getMessageUserInfo = (message: ISingleMessage | ISingleConversation) => {
  if ('type' in message) {
    // assigned user
    return `${getConversationHeader(message.user)} ●`;
  }
  // our contact
  return `${getConversationHeader(message.contact)} ●`;
};

export const ConversationBody = ({
  singleConversation,
  setSelectedConversation,
  setCopiedMessage,
  selectedConversation,
}: IConversationBody) => {
  const mutationCount = useIsMutating({ mutationKey: ['markAsRead'] });
  const { mutateAsync } = useSendSMSMutation();
  const { mutateAsync: markAsRead } = useMarkAsReadConversationMutation();
  const scrollableDiv = useRef<HTMLDivElement>(null);
  const { userData } = useAuth();
  const [unreadWidget, setUnreadWidget] = useState(!!singleConversation.unread);
  const canReply = !(singleConversation.deleted || singleConversation.closed);
  const MESSAGE_HEIGHT = 88;
  const isEmailSelected =
    selectedConversation === CONVERSATION_TYPES.EMAIL &&
    isAssignedToYou(singleConversation.userId, userData) &&
    canReply;
  const messagesLength = singleConversation?.messages?.length;

  useEffect(() => {
    if (
      isAssignedToYou(singleConversation.userId, userData) &&
      !!singleConversation.unread &&
      mutationCount === 0
    ) {
      markAsRead(`${singleConversation.conversationId}`);
    }
  }, []);

  useEffect(() => {
    if (isAssignedToYou(singleConversation.userId, userData)) {
      if (!singleConversation.unread) {
        setUnreadWidget(false);
        return;
      }
      if (scrollableDiv.current === null) {
        return;
      }
      // check if scrollTop is bigger then one message
      if (scrollableDiv.current.scrollTop > -MESSAGE_HEIGHT) {
        markAsRead(`${singleConversation.conversationId}`);
      } else {
        setUnreadWidget(true);
      }
    }
  }, [singleConversation.unread]);

  if (!messagesLength) {
    return (
      <ChatBodyWithOutData
        withHeader={!messagesLength}
        isEmailSelected={isEmailSelected}
      >
        <EmptyMsg>There are no messages</EmptyMsg>
      </ChatBodyWithOutData>
    );
  }

  const sortedMessages = orderBy(
    singleConversation?.messages,
    [obj => new Date(obj.createdAt)],
    ['desc']
  );

  return (
    <ChatBody
      isEmailSelected={isEmailSelected}
      id='scrollableDiv'
      ref={scrollableDiv}
    >
      <InfiniteScroll
        key={singleConversation?.conversationId}
        next={() => {}}
        dataLength={messagesLength}
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          paddingTop: 16,
        }}
        inverse={true}
        hasMore={false}
        onScroll={(e: MouseEvent) => {
          // on scroll
          if (isAssignedToYou(singleConversation.userId, userData)) {
            const target = e.target as HTMLDivElement;
            if (!singleConversation.unread) {
              return null;
            }
            if (target.scrollTop < -MESSAGE_HEIGHT) {
              setUnreadWidget(true);
            }
            if (target.scrollTop === 0) {
              setUnreadWidget(false);
              markAsRead(`${singleConversation.conversationId}`);
            }
          }
        }}
        scrollableTarget='scrollableDiv'
        loader={<h4>Loading...</h4>}
      >
        {sortedMessages.map((message: ISingleMessage) => {
          const messageData =
            message.type === 'inbound' ? singleConversation : message;
          const integrationType = getIntegrationType(message);
          const isSms = integrationType === 'SMS';
          const notSend = message.status === 'failed';
          const resend = isSms && notSend;

          return (
            <MessagesContainer key={message.messageId} type={message.type}>
              <ReceivedMessages type={message.type} resend={resend}>
                {message.images.map((e: any) => {
                  const link = `https://api.covideo.com/images/public/${e.fileName}`;
                  return (
                    <img
                      onClick={() => {
                        window.open(link, '_blank');
                      }}
                      alt='Chat part'
                      src={link}
                    />
                  );
                })}
                {detectUrlAndText(message.text)}
              </ReceivedMessages>
              {resend ? (
                <Gap flexDirection='column' alignItems='flex-end' gap='8px'>
                  <ParagraphExtraSmallBold color='#E84C3D'>
                    Not send
                  </ParagraphExtraSmallBold>
                  {isAssignedToYou(singleConversation.userId, userData) && (
                    <Gap>
                      <ParagraphExtraSmallBold
                        onClick={() =>
                          mutateAsync({
                            conversationId: message.conversationId,
                            text: message.text,
                            to: singleConversation.contact.phone,
                          })
                        }
                        color='#001B53'
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        Resend as SMS
                      </ParagraphExtraSmallBold>
                      <ParagraphExtraSmallBold
                        onClick={() => {
                          setSelectedConversation(CONVERSATION_TYPES.EMAIL);
                          setCopiedMessage(message.text);
                        }}
                        color='#001B53'
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        Resend as Email
                      </ParagraphExtraSmallBold>
                    </Gap>
                  )}
                </Gap>
              ) : (
                <ReceivedMessageInfo type={message.type}>
                  {getMessageUserInfo(messageData)}{' '}
                  {calculateDiff(message.deliveredAt || message.createdAt)} ●{' '}
                  {integrationType}
                </ReceivedMessageInfo>
              )}
            </MessagesContainer>
          );
        })}
        {unreadWidget &&
          messagesLength > 0 &&
          isAssignedToYou(singleConversation.userId, userData) && (
            <NewMessageWrapper>
              <NewMessage
                onClick={() => {
                  if (scrollableDiv.current === null) {
                    return;
                  }
                  markAsRead(`${singleConversation.conversationId}`);
                  scrollableDiv.current.scrollTop = 0;
                }}
              >
                <FaArrowDown size={12} />
                {'Latest messages'}{' '}
              </NewMessage>
            </NewMessageWrapper>
          )}
      </InfiniteScroll>
    </ChatBody>
  );
};
