import * as React from 'react';
import styled from 'styled-components/macro';

import { theme } from 'lib/style';
import { MdAdd } from 'react-icons/md';
import { IoIosLogIn } from 'react-icons/io';
import placeholder from 'assets/images/table/placeholderBig.webp';
import { Button } from 'react-covideo-common';

const NoContactLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
type Props = {
  onImport?: () => void;
  selectedDept?: string | number;
  title: string;
  usersValue?: any;
  createUser?: () => void;
  createDepartment?: () => void;
};

export const NoContent = ({
  onImport,
  selectedDept,
  title,
  usersValue,
  createUser,
  createDepartment,
}: Props) => {
  return (
    <>
      <NoContactLayout>
        <Text>{title}</Text>
        {selectedDept == 0 && usersValue.total > usersValue.active && (
          <Row>
            <Button
              text='Create'
              onClick={() => {
                if (createUser) createUser();
              }}
              icon={<MdAdd color={theme.palette.white || '#fff'} />}
            />
            <Button
              variant='secondary'
              icon={<IoIosLogIn color={theme.palette.black_1_100} />}
              text={'Import'}
              onClick={() => {
                if (onImport) onImport();
              }}
            />
          </Row>
        )}
        {createDepartment && (
          <Row>
            <Button
              text='Create Department'
              onClick={() => {
                if (createDepartment) createDepartment();
              }}
              icon={<MdAdd color={theme.palette.white || '#fff'} />}
            />
          </Row>
        )}
      </NoContactLayout>
    </>
  );
};
