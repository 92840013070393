import { Guides } from 'lib/api/guides/guidesQuery';
import React from 'react';
import { ModalsType } from './main/pages/MainGuidePage';
import { Thumbnail } from './Thumbnail';

export const ThumbnailGuidesList = ({
  guides,
  handleGuideSelection,
  setModals,
}: {
  guides: Guides[] | undefined;
  handleGuideSelection?: (videoId: number, categoryId?: number) => void;
  setModals?: React.Dispatch<React.SetStateAction<ModalsType>>;
}) => {
  return (
    <div style={{ display: 'flex', gap: 13, flexWrap: 'wrap' }}>
      {guides?.map((guide, index) => (
        <Thumbnail
          guide={guide}
          key={index}
          hasActions={guide.isEditable}
          hasCheckbox={false}
          selectedGuides={[]}
          setModals={setModals}
          handleGuideSelection={handleGuideSelection}
        />
      ))}
    </div>
  );
};
