import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdAdd } from 'react-icons/md';

interface Props {
  showModal: (params: any) => void;
  color?: string;
}

const NoVideoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  margin-top: 160px;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: ${theme.palette.black_1_100};
`;

export const NoAttributes = ({ showModal }: Props) => (
  <NoVideoContent>
    <Title>There are no Custom Video Attributes</Title>
    <br />
    <Button text='Create New' onClick={showModal} icon={<MdAdd size={18} />} />
  </NoVideoContent>
);
