import { theme } from 'lib/style';
import React, { useEffect, useRef } from 'react';
import { IoMdClose } from 'react-icons/io';
import Modal from 'react-modal';
import styled from 'styled-components/macro';

type ModalStyleObject = {
  overlay?: React.CSSProperties;
  content?: React.CSSProperties;
};

type Props = {
  children?: React.ReactNode;
  style?: ModalStyleObject;
  hideHeader?: boolean;
  hideCloseButtonInHeader?: boolean;
  headerTextStyle?: object;
  closeModalOnBackdropOrEscPress?: boolean;
  closeModal: () => void;
  headerText?: string;
  headerPosition?: string;
  headerMargin?: string;
  closeIcon?: JSX.Element;
  closeIconPosition?: string;
  shouldReturnFocusAfterClose?: boolean;
};

const defaultModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.blackRgba75,
    zIndex: 998,
  } as React.CSSProperties,
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: window.innerWidth > 592 ? '592px' : 'auto',
    borderRadius: '4px',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 999,
  } as React.CSSProperties,
};

type ModalHeaderProps = {
  margin?: string;
};
const ModalHeader = styled.div<ModalHeaderProps>`
  color: ${theme.palette.gray100};
  margin: ${props => (props.margin ? props.margin : '0 0 15px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    padding: 5px;
    cursor: pointer;
  }
`;

const HeaderText = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
`;

export const NewModal = ({
  children,
  style,
  hideHeader = false,
  hideCloseButtonInHeader = false,
  headerTextStyle,
  closeModalOnBackdropOrEscPress = true,
  headerText,
  headerMargin,
  closeModal,
  closeIcon,
  closeIconPosition = 'right',
  shouldReturnFocusAfterClose = true,
}: Props) => {
  const root = document.getElementById('root') as HTMLElement;
  useEffect(() => {
    // disable scroll of root when modal is active in order to keep dropdown in placement
    root.style.overflow = 'hidden';

    return () => {
      root.style.overflow = '';
    };
  }, []);

  const close = closeIcon || (
    <IoMdClose size={24} color={theme.palette.gray60} onClick={closeModal} />
  );
  const modalRef = useRef<any>(null);
  return (
    <Modal
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      ref={modalRef}
      isOpen={true}
      onRequestClose={closeModalOnBackdropOrEscPress ? closeModal : undefined}
      style={{
        overlay: { ...defaultModalStyles.overlay, ...style?.overlay },
        content: { ...defaultModalStyles.content, ...style?.content },
      }}
      ariaHideApp={false}
      contentLabel='Example Modal'
    >
      {!hideHeader && (
        <ModalHeader
          margin={headerMargin}
          style={{
            ...(closeIconPosition === 'left' && {
              justifyContent: 'left',
            }),
          }}
        >
          {!hideCloseButtonInHeader && closeIconPosition === 'left' && close}
          <HeaderText style={headerTextStyle}>{headerText}</HeaderText>
          {!hideCloseButtonInHeader && closeIconPosition === 'right' && close}
        </ModalHeader>
      )}
      {children}
    </Modal>
  );
};
