import { VideoAppearanceData as IVideoAppearanceData } from '../api/playerDesign/usePlayerDesignQuery';

export enum RecipientType {
  USER = '2',
  GROUP = '1',
}
export interface RecipientInterface {
  type: string;
  id: string;
  text: string;
}

export type Recipient = RecipientInterface;
export type VideoData = {
  id: string;
  title: string;
  recordDate: string;
  autogeneratedThumbnail: string;
  videoSource: string;
  personalThumbnail: string;
  playerBackgroundColor: string;
  playerIconsAndTextColor: string;
  playButtonPosition: string;
  wtvId?: number;
  emailStatus: string;
  protected?: boolean | string;
  videoLength?: number;
  reactionsPreference?: boolean;
  videoReplyEnabled?: boolean;
  userId?: number;
  isGuide?: boolean;
};
export type VideoAppearanceData = IVideoAppearanceData;

export { usePrevious } from './usePrevious';

export { useContainerDimensions } from './useContainerDimensions';

export { useGetReport } from './useGetReport';

// error toast
export { useToastError } from './useToastError';
