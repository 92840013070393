import { Gap } from 'lib/components/styles/layout';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { ICombinedUserData, useAuth } from 'lib/context';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { SelectableCustomers } from './SelectableCustomers';
import { SelectableReslleres } from './SelectableReslleres';
import { SelectableUsers } from './SelectableUsers';

export enum VISIBILITY_TYPE {
  RESELLERS = 'RESELLERS',
  CUSTOMERS = 'CUSTOMERS',
  USERS = 'USERS',
}

const Wrapper = styled.div`
  width: 1216px;
  border-radius: 7px;
  border: 1px solid #eeeff2;
  background: #fafafc;
  padding: 24px;
  box-sizing: border-box;
  max-height: 100vh;
  margin: 32px auto;
`;

const calculateInitView = (role: SuperAdminRole) => {
  if (role === SuperAdminRole.COVIDEO_SUPER_ADMIN) {
    return [VISIBILITY_TYPE.RESELLERS];
  }
  if (role === SuperAdminRole.RESELLER_ADMIN) {
    return [VISIBILITY_TYPE.CUSTOMERS];
  }
  if (role === SuperAdminRole.ACCESS) {
    return [VISIBILITY_TYPE.USERS];
  }
};

const calculateSelectedReseller = (
  role: SuperAdminRole,
  userData: ICombinedUserData
) => {
  if (role === SuperAdminRole.COVIDEO_SUPER_ADMIN) {
    return undefined;
  }
  if (role >= SuperAdminRole.RESELLER_ADMIN && role <= SuperAdminRole.ACCESS) {
    return Number(userData.resellerId);
  }
};

const calculateSelectedCustomer = (
  role: SuperAdminRole,
  userData: ICombinedUserData
) => {
  if (role === SuperAdminRole.COVIDEO_SUPER_ADMIN) {
    return undefined;
  }
  if (role === SuperAdminRole.ACCESS) {
    return Number(userData.customerId);
  }
};

interface IProps {
  openHowToShareModal: () => void;
}

export const SelectableArea = ({ openHowToShareModal }: IProps) => {
  const { userData } = useAuth();
  const currentReseller = calculateSelectedReseller(
    userData.superAdminRole,
    userData
  );
  const currentCustomer = calculateSelectedCustomer(
    userData.superAdminRole,
    userData
  );
  const [selectedResellerId, setSelectedResellerId] = useState(currentReseller);
  const [selectedCustomerId, setSelectedCustomerId] = useState(currentCustomer);

  const [view, setView] = useState(
    calculateInitView(userData.superAdminRole) || []
  );

  const onItemClick = (id: number, item: VISIBILITY_TYPE) => {
    if (item === VISIBILITY_TYPE.RESELLERS) {
      setSelectedResellerId(id);

      !view.includes(VISIBILITY_TYPE.CUSTOMERS) &&
        setView(prevState => [...prevState, VISIBILITY_TYPE.CUSTOMERS]);
    }
    if (item === VISIBILITY_TYPE.CUSTOMERS) {
      setSelectedCustomerId(id);
      !view.includes(VISIBILITY_TYPE.USERS) &&
        setView(prevState => [...prevState, VISIBILITY_TYPE.USERS]);
    }
  };

  const resetAllSelection = () => {
    setSelectedResellerId(undefined);
    setSelectedCustomerId(undefined);
  };

  const resetSelectedCustomers = () => {
    setSelectedCustomerId(undefined);
  };

  return (
    <Wrapper>
      <Gap
        flexDirection='row'
        alignItems='flex-start'
        justifyContent='flex-start'
        style={{ height: 'calc((100vh - 64px) - 118px' }}
      >
        {view.map(item => {
          if (item === VISIBILITY_TYPE.RESELLERS) {
            return (
              <SelectableReslleres
                selectedResellerId={selectedResellerId}
                key={item}
                item={item}
                onItemClick={onItemClick}
                openHowToShareModal={openHowToShareModal}
                resetAllSelection={resetAllSelection}
                resetSelectedCustomers={resetSelectedCustomers}
              />
            );
          }
          if (item === VISIBILITY_TYPE.CUSTOMERS) {
            return (
              <SelectableCustomers
                key={item}
                item={item}
                onItemClick={onItemClick}
                selectedResellerId={selectedResellerId}
                selectedCustomerId={selectedCustomerId}
                openHowToShareModal={openHowToShareModal}
                resetSelectedCustomers={resetSelectedCustomers}
              />
            );
          }
          if (item === VISIBILITY_TYPE.USERS) {
            return (
              <SelectableUsers
                key={item}
                item={item}
                onItemClick={onItemClick}
                selectedCustomerId={selectedCustomerId}
                selectedResellerId={selectedResellerId}
                openHowToShareModal={openHowToShareModal}
              />
            );
          }
          return null;
        })}
      </Gap>
    </Wrapper>
  );
};
