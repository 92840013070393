import * as React from 'react';
import styled from 'styled-components/macro';

interface Props {
  content: string;
  index: string;
  className?: string;
}

const SlideElement = styled.div`
  height: auto;
  width: 100%;
`;

export function renderCovideoEmbed(id: any) {
  let slide = document.getElementById(id) as HTMLDivElement;
  if (!slide) {
    return;
  }

  let container = slide.querySelector('.cv-embed') as HTMLDivElement;
  if (!container) {
    return;
  }

  if (container.classList.contains('initialized')) {
    return;
  }

  let wrap = document.createElement('div') as HTMLDivElement;
  let frame = document.createElement('iframe') as HTMLIFrameElement;

  container.style.cssText = `
      width:100%;
      margin:0 auto;`;

  wrap.style.cssText = `
  position: relative;
  width:538px;
  display:flex;
  justify-content: center;
  height:auto;`;

  frame.setAttribute('frameborder', '0');
  frame.setAttribute(
    'src',
    `https://www.covideo.com/api/embed/${container.dataset.id}?k=${container.dataset.key}`
  );
  frame.setAttribute('frameborder', '0');
  frame.setAttribute('allowfullscreen', 'true');
  frame.setAttribute('webkitallowfullscreen', 'true');
  frame.setAttribute('mozallowfullscreen', 'true');
  frame.setAttribute('allow', 'fullscreen');
  frame.style.cssText = `
    width:538px;
    min-height: 350px;`;

  wrap.appendChild(frame);

  container.appendChild(wrap);
  container.classList.add('initialized');
}

export const Slide = ({ content, index, className }: Props) => {
  const id = 'id' + new Date().getTime() + '-' + index;

  React.useEffect(() => {
    renderCovideoEmbed(id);
  }, []);
  return (
    <SlideElement
      className={className}
      id={id}
      dangerouslySetInnerHTML={{ __html: content }}
    ></SlideElement>
  );
};
