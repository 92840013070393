import { ColorResult } from 'react-color';

export const addCallToAction = (annotation: any) => {
  const props = {
    href: '',
    target: '_blank',
  };
  if (annotation.linkType === 'Web' && annotation.url) {
    props.href = annotation.url;
  }

  if (annotation.linkType === 'Email' && annotation.email) {
    props.href = `mailto:${annotation.email}`;
    props.target = 'mail';
  }

  if (annotation.linkType === 'Phone' && annotation.phone) {
    props.href = `tel:${annotation.phone}`;
  }

  return props;
};

export const calculateHexAlpha = (color: ColorResult) =>
  color.hex + Math.floor(color.rgb.a ? color.rgb.a * 255 : 1).toString(16);
