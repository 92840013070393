import { LoadingIndicator } from 'lib/components/LoadingIndicator';
import React from 'react';
import styled from 'styled-components/macro';

const RecordCamLoaderWrapper = styled.div`
  height: 440px;
  width: 100%;
  z-index: 4;
  background: rgb(246, 247, 249);
  position: absolute;
  border-radius: 6;
  display: flex;
  align-items: center;
  justify.content: center;
  top: 0;
`;

export const RecordCamLoader = ({ text }: { text: string }) => {
  return (
    <RecordCamLoaderWrapper>
      <div>
        <LoadingIndicator isLoading={true} text={text} />
      </div>
    </RecordCamLoaderWrapper>
  );
};
