import dayjs from 'dayjs';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';

import { METRICS_DATE_FORMAT } from 'lib/const/DateFormat';
import { useToastError } from 'lib/hooks';
import { superAdminKeys } from './superAdminKeys';
import { CustomerUsageQueryParams } from './types';

export const getCustomerUsageForSuperAdmin = async (
  query: CustomerUsageQueryParams
) => {
  const params = {
    ...query,
    from: dayjs(query.from).format(METRICS_DATE_FORMAT),
    to: dayjs(query.to).format(METRICS_DATE_FORMAT),
  };

  const response = await EXPRESS_API.get('/superadmin/customers/usage', {
    params,
  });
  return response.data;
};

export const useCustomerUsageQuery = ({
  params,
  enabled,
}: {
  params: CustomerUsageQueryParams;
  enabled: boolean;
}) => {
  const { showError } = useToastError();

  return useQuery(
    superAdminKeys.customer_usage(params),
    () => getCustomerUsageForSuperAdmin(params),
    {
      onError: showError,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
    }
  );
};
