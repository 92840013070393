import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const ItemTypeValues = {
  VIDEO: 'video',
  BOARD: 'board',
  FILE: 'file',
};

export const fileTypes = [
  {
    value: 'all',
    label: 'All Filetypes',
  },
  {
    value: 'IMAGE',
    label: 'Images',
  },
  {
    value: 'VIDEO',
    label: 'Videos',
  },
  {
    value: 'AUDIO',
    label: 'Sound',
  },
  {
    value: 'FILE',
    label: 'Other',
  },
];
export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseButtonWrap = styled.div`
  margin-left: 10px;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const Menu = styled.div`
  justify-content: space-between;
  margin-top: 24px;
`;

export const FilesSelector = styled.div`
  margin-top: 24px;
  height: 300px;
  overflow-y: auto;
`;

export const FilesItem = styled.div`
  display: flex;
  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 5px;
  margin-bottom: 8px;
  height: 64px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 24px;
  position: relative;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary[100]};
  }
  &:hover .btn-select-file {
    color: ${({ theme }) => theme.colors.primary[100]};
    background: rgba(255, 139, 34, 0.05);
    border: 1px solid ${({ theme }) => theme.colors.primary[100]};
  }
`;

type ThumbnailProps = {
  image: string;
};

export const FilesItemThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 96px;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 5px;
`;

export const FilesItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
`;

export const FilesItemDate = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;

export const FilesSelectButton = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
  background: #f6f7f9;
  padding: 8px 12px;
  border: 1px solid #eeeff2;
  box-sizing: border-box;
  border-radius: 5px;
  height: 40px;
  position: absolute;
  right: 16px;
  top: calc(50% - 20px);
  left: auto !important;
`;

export const EmptyFiles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #272a32;
`;

interface TabStyleProperties {
  width?: string;
}

export const Tab = styled.div<TabStyleProperties>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;
  color: ${theme.palette.coal};
  align-items: center;
  color: #9297a2;
  width: 45%;
  margin: 0;
  justify-content: center;
  height: 20px;
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 0 0 100%;
  flex-wrap: wrap;
  .activeTab {
    color: ${({ theme }) => theme.colors.primary[100]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary[100]};
  }
  border-bottom: 1px solid #eeeff2;
  margin-top: 25px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

export const FieldWrapper = styled.div<{ width: string }>`
  width: 100%;
  > div {
    width: 272px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }

  button {
    margin-left: 0;
  }
`;

export const Bull = styled.span`
  :after {
    content: '•';
    margin: 0 6px;
    font-size: 10px;
    color: ${theme.palette.gray80};
  }
`;

export const ItemTypes = [
  {
    value: ItemTypeValues.VIDEO,
    text: 'Videos',
    width: '90px',
  },
  {
    value: ItemTypeValues.FILE,
    text: 'Files',
    width: '90px',
  },
  {
    value: ItemTypeValues.BOARD,
    text: 'Boards',
    width: '90px',
  },
];
