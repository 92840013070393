import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { NewModal } from './NewModal';
import { Gap } from '../styles/layout';
import { Label } from '../folders/style';
import { theme } from 'lib/style';
import { useTheme } from 'styled-components/macro';
import Select from 'react-select';
import { EmailStatus, RECEIVE_METHOD } from 'lib/const/EmailReceivers';
import { selectStyle } from 'lib/style/commonStyles';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { ActivityFilter } from 'lib/api/activity/types';

type Props = {
  onClose: () => void;
  filter: ActivityFilter;
};

const StyledLabel = styled(Label)`
  color: ${theme.palette.neutral80};
  margin-bottom: 5px;
`;

const FilterContainer = styled.div`
  margin-bottom: 16px;
`;

const ActivityFilterModal = ({ onClose, filter }: Props) => {
  const themes = useTheme();
  const location = useLocation();
  const history = useHistory();

  const methodOptions = [
    {
      label: 'Any methods',
      value: '',
    },
    {
      label: 'SMS',
      value: RECEIVE_METHOD.SMS,
    },
    {
      label: 'Email',
      value: RECEIVE_METHOD.EMAIL,
    },
    {
      label: 'Quickshare',
      value: RECEIVE_METHOD.QUICKSHARE,
    },
  ];

  const statusOptions = [
    { label: 'Any statuses', value: '' },
    ...Object.keys(EmailStatus).map(key => {
      if (EmailStatus[key as keyof typeof EmailStatus] === EmailStatus.SPAM)
        return {
          label: 'Spam',
          value: EmailStatus[key as keyof typeof EmailStatus],
        };
      return {
        label:
          EmailStatus[key as keyof typeof EmailStatus].charAt(0).toUpperCase() +
          EmailStatus[key as keyof typeof EmailStatus].slice(1),
        value: EmailStatus[key as keyof typeof EmailStatus],
      };
    }),
  ];
  const [updatedFilter, setUpdatedFilter] = useState<ActivityFilter>(filter);

  const changeLink = (filter: ActivityFilter) => {
    const queryParams = queryString.parse(location.search);
    queryParams.receiveMethod = filter.receiveMethod || '';
    queryParams.emailStatus = filter.emailStatus || '';
    queryParams.page = '0'; // reset page

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const onSaveFilters = (filter: ActivityFilter) => {
    changeLink(filter);
    onClose();
  };
  return (
    <NewModal closeModal={onClose} headerText='Filter Table'>
      <FilterContainer>
        <StyledLabel>Method</StyledLabel>
        <Select
          menuPortalTarget={document.body}
          menuPosition='fixed'
          menuPlacement={'bottom'}
          options={methodOptions}
          styles={selectStyle(themes)}
          value={methodOptions.find(
            method => method.value === updatedFilter.receiveMethod
          )}
          onChange={option => {
            if (option)
              setUpdatedFilter(prev => ({
                ...prev,
                receiveMethod: option.value,
              }));
          }}
        />
      </FilterContainer>
      <FilterContainer>
        <StyledLabel>Status</StyledLabel>
        <Select
          menuPortalTarget={document.body}
          menuPosition='fixed'
          menuPlacement={'bottom'}
          options={statusOptions}
          styles={selectStyle(themes)}
          value={statusOptions.find(
            method => method.value === updatedFilter.emailStatus
          )}
          onChange={option => {
            if (option)
              setUpdatedFilter(prev => ({
                ...prev,
                emailStatus: option.value,
              }));
          }}
        />
      </FilterContainer>
      <Gap justifyContent='right' gap='12px' m='16px 0 0 0'>
        <Button text='Cancel' onClick={onClose} variant='secondary' />

        <Button
          text='Save Filters'
          onClick={() => onSaveFilters(updatedFilter)}
        />
      </Gap>
    </NewModal>
  );
};

export default ActivityFilterModal;
