import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

interface ComponentProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  handleNextStep: () => void;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 104px;
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  right: 24px;
  bottom: 0;
  border: 0;
  color: ${props => props.theme.colors.primary[100]};
  background-color: transparent;
  height: 24px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
`;

const ActionText = styled.p`
  color: ${props => props.theme.colors.primary[100]};
  opacity: 0.75;
  ${theme.fontNormal500}
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
`;

const ActionArrow = styled.svg`
  margin: 0 0 0 8px;
  width: 24px;
  height: 24px;
  object-fit: contain;
  opacity: 0.75;
  path {
    fill: ${props => props.theme.colors.primary[100]};
  }
`;

export const Header = (props: ComponentProps) => {
  const { step, handleNextStep } = props;
  return (
    <Container>
      {/*TODO: ENABLE WHEN PROBLEM IN THE BACKEND SAVING EXIT LINKS (CTAS) IS FIXED <StepsWrapper>
        <Step isSelected={step === 1} onClick={() => setStep(1)}>
          Step 1
        </Step>
        <Step isSelected={step === 2} onClick={() => setStep(2)}>
          Step 2
        </Step>
        <Step isSelected={step === 3} onClick={() => setStep(3)}>
          Step 3
        </Step>
      </StepsWrapper>*/}
      <ActionButton onClick={handleNextStep}>
        <ActionText>
          {
            /* TODO, PUT STEP TO 3 ONCE EXIT LINKS ARE FIXED ON THE BACKEND AND CTAS ARE SAVED PROPERLY IN THE DATABASE*/ step ===
            1
              ? 'Finish'
              : 'Next'
          }
        </ActionText>
        <ActionArrow xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
          <path
            d='M18.788 5.024L20 3.722 18.788 2.42 17.754 1.341 16.506 0 15.257 1.341 6.263 10.707 5 12.009 6.263 13.311 15.257 22.677 16.506 24 17.754 22.677 18.788 21.598 20 20.303 18.788 18.994 12.084 12.016z'
            transform='matrix(-1 0 0 1 24 0)'
          />
        </ActionArrow>
      </ActionButton>
    </Container>
  );
};
