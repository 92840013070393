import { useQuery } from 'react-query';
import { checkDropAuth, client } from './droplrAuth';
import { droplrKeys } from './queryKeys';

type SimplifiedUser = {
  id: string;
};

const getUserTags = async () => {
  await checkDropAuth();

  return client.users
    .current()
    .then((user: SimplifiedUser) => client.users.getTags(user.id));
};

export const useGetUserTags = () => {
  return useQuery(droplrKeys.user_tag_list(), () => getUserTags());
};
