import React, { useState } from 'react';
import { VideoListItem } from 'lib/api';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { ModalVideoListVideo } from 'lib/components';

const Wrapper = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const VideoThumbnail = styled.img`
  box-sizing: border-box;
  flex-grow: 0;
  height: 48px;
  border: 1px solid ${theme.palette.primaryBlack5Percent};
  border-radius: 4px;
  object-fit: cover;
  margin-right: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const VideoTitle = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.palette.blue100};
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  video: VideoListItem & { autogeneratedThumbnail: string };
  marginBottom?: string;
};

export const VideoTitleAndThumbnail = ({ video, marginBottom }: Props) => {
  const videoThumbnail = video.thumbnail || video.autogeneratedThumbnail;
  const videoId = video.id;
  const videoTitle = video.title;
  const videoSource = video.videoSource;
  const [showVideoPreviewModal, setShowVideoPreviewModal] = useState(false);

  const openVideoPreviewModal = () => {
    setShowVideoPreviewModal(true);
  };

  const closeVideoPreviewModal = () => {
    setShowVideoPreviewModal(false);
  };

  return (
    <>
      <Wrapper marginBottom={marginBottom}>
        {videoThumbnail && (
          <VideoThumbnail
            src={videoThumbnail}
            onClick={openVideoPreviewModal}
          />
        )}
        <VideoTitle onClick={openVideoPreviewModal}>{videoTitle}</VideoTitle>
      </Wrapper>
      {showVideoPreviewModal && (
        <ModalVideoListVideo
          videoId={videoId}
          source={videoSource}
          handleModalClose={closeVideoPreviewModal}
        />
      )}
    </>
  );
};
