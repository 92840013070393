import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Dropdown, Modal } from 'lib/components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { calculateDaysBetween } from 'lib/utils/datetime';
import {
  dropdownItems,
  EXTEND_DELETION_TYPES,
} from 'lib/const/ModalVideoDeletionApproval';
import { Button } from 'react-covideo-common';
import { Gap } from '../styles/layout';
import { useIsMutating } from 'react-query';
import { IoMdCalendar, IoMdClose } from 'react-icons/io';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 24px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Textarea = styled.textarea`
  padding-left: 8px;
  width: 528px;
  min-width: 528px;
  max-width: 528px;
  height: 100px;
  min-height: 100px;
  max-height: 300px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  &:focus {
    outline: 0;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;

const InputDateWrapper = styled.div<{ width?: string }>`
  position: relative;
  height: 40px;
  input {
    padding: 6px 8px 8px 40px;
    width: ${({ width }) => width};
    border-radius: 6px;
    border: 1px solid rgba(0, 27, 83, 0.2);

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #272a32;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  color: #9297a2;
  top: 8px;
  left: 8px;
  z-index: 1;
`;

const TimeframeWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const DropdownWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
  height: 40px;
`;

type Props = {
  videoDeletionRequest?: any;
  handleModalClose: () => void;
  handleSubmit: Function;
};

export const ModalVideoDeletionApproval = ({
  videoDeletionRequest,
  handleModalClose,
  handleSubmit,
}: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;

  const initStartDate = new Date();

  const [message, setMessage] = useState('');
  const [startDate, setStartDate] = useState(initStartDate);

  const [expiration, setExpiration] = useState({
    ...dropdownItems[0],
    value: dropdownItems[0].value,
  });

  const onStartDateChange = (date: Date) => {
    setStartDate(date);
  };

  const updateMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const isCustom = expiration.id === EXTEND_DELETION_TYPES.CUSTOM;

  useEffect(() => {
    if (isCustom)
      setExpiration({ ...expiration, value: calculateDaysBetween(startDate) });
  }, [startDate]);

  return (
    <Modal>
      <ModalItem>
        <Header>
          {`Review Request from ${videoDeletionRequest.user.firstName} ${videoDeletionRequest.user.lastName}`}
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <Item>
            <Title>Extend Video expiration for</Title>
            <TimeframeWrapper>
              <DropdownWrapper width={isCustom ? '300px' : '100%'}>
                <Dropdown
                  options={dropdownItems}
                  value={expiration}
                  onChange={(option: any) => {
                    option.id === EXTEND_DELETION_TYPES.CUSTOM
                      ? setExpiration({
                          ...option,
                          value: calculateDaysBetween(startDate),
                        })
                      : setExpiration(option);
                  }}
                  creatable={false}
                  disabled={dropdownItems.length === 0}
                  showIndicator={dropdownItems.length !== 0}
                />
              </DropdownWrapper>
              {isCustom && (
                <InputDateWrapper width='97px'>
                  <IconWrapper>
                    <IoMdCalendar size={18} />
                  </IconWrapper>
                  <DatePicker
                    onChange={onStartDateChange}
                    selected={startDate}
                  />
                </InputDateWrapper>
              )}
            </TimeframeWrapper>
          </Item>
          <Item>
            <Title>{`Add a note for ${videoDeletionRequest.user.firstName}`}</Title>
            <Textarea value={message} onChange={updateMessage} />
          </Item>
          <Gap justifyContent='flex-end' gap='12px'>
            <Button
              disabled={isDisabled}
              text={'Deny Request'}
              variant='red'
              onClick={() =>
                handleSubmit(
                  {
                    message,
                    requestId: videoDeletionRequest.requestId,
                  },
                  false
                )
              }
            />
            <Button
              disabled={isDisabled}
              text={'Extend Video Deletion'}
              onClick={() =>
                handleSubmit(
                  {
                    message,
                    requestId: videoDeletionRequest.requestId,
                    days: expiration.value,
                  },
                  true
                )
              }
            />
          </Gap>
        </Content>
      </ModalItem>
    </Modal>
  );
};
