import { TableField } from 'lib/context';
import {
  IMSProviderOption,
  IMS_PROVIDERS_LABELS,
  IMS_PROVIDERS_VALUES,
} from './types';

export const options: IMSProviderOption[] = Object.keys(
  IMS_PROVIDERS_VALUES
).map(key => ({
  value: IMS_PROVIDERS_VALUES[key as keyof typeof IMS_PROVIDERS_VALUES],
  label: IMS_PROVIDERS_LABELS[key as keyof typeof IMS_PROVIDERS_LABELS],
}));

export const IMS_IDS: string[] = Object.values(IMS_PROVIDERS_VALUES);

export enum InventoryTableColumns {
  CUSTOMER_NAME = 'business',
  CUSTOMER_ID = 'customerId',
  INVENTORY_PROVIDER = 'inventoryProvider',
  INVENTORY_ID = 'inventoryId',
  SYNC = 'sync',
}

export const tableFields: TableField[] = [
  {
    value: InventoryTableColumns.CUSTOMER_NAME,
    label: 'Customer',
    sortable: true,
    sortKey: 'CUSTOMER_NAME',
    width: 352,
    height: '100%',
  },
  {
    value: InventoryTableColumns.CUSTOMER_ID,
    label: 'Customer ID',
    sortable: true,
    sortKey: 'CUSTOMER_ID',
    width: 128,
    height: '100%',
  },
  {
    value: InventoryTableColumns.INVENTORY_PROVIDER,
    label: 'Inventory Provider',
    width: 176,
  },
  {
    value: InventoryTableColumns.INVENTORY_ID,
    label: 'Inventory ID',
    width: 224,
  },
  {
    value: InventoryTableColumns.SYNC,
    label: 'Sync',
    width: 92,
    height: '100%',
  },
];
