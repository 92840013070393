import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation } from 'react-query';
import { VideoTranscriptionResponse } from '../types';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';

const startTranscription = async (
  videoId: string
): Promise<VideoTranscriptionResponse> => {
  const response = await EXPRESS_API.post(
    `/videos/${videoId}/transcripts/start`
  );
  return response.data;
};

export const useVideoTranscriptionStart = () => {
  return useMutation(startTranscription, {
    onSuccess: () => {
      successToast({ title: 'Transcription Started' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred while starting transcription. Please try again later.',
      });
    },
  });
};
