import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { scheduleKeys } from './scheduleKeys';
import { scheduleListKeys } from './scheduleListKeys';
import { Schedule, UpdateSingleScheduleParams } from './types';

const updateSingleSchedule = async ({
  scheduleId,
  data,
}: UpdateSingleScheduleParams) => {
  const response = await EXPRESS_API.put(`/schedule/${scheduleId}`, data);

  return response.data;
};

export const useUpdateSingleScheduleMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation(updateSingleSchedule, {
    onSuccess: (response: Schedule) => {
      const toastTitle = response.sendEmail
        ? response.sendSms
          ? 'Scheduled Email and SMS are saved successfully'
          : 'Scheduled Email is saved successfully'
        : 'Scheduled SMS is saved successfully';
      successToast({ title: toastTitle });
      queryClient.invalidateQueries(scheduleKeys.detail(response.scheduleId));
      queryClient.invalidateQueries(scheduleListKeys.lists());
    },
    onError: (err: AxiosError) => {
      showError(err);
    },
  });
};
