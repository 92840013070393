import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { guidesKeyes } from './guidesKeyes';
import { errorToast } from 'lib/components/toasts/error';
import { useHistory } from 'react-router';
import { EXPRESS_API } from 'configs/api/expressApi';

type ReplaceResponse = {
  message: string;
  newVideoId: number;
};

export const replaceGuide = async ({
  videoId,
  newVideoId,
}: {
  videoId: number;
  newVideoId: number;
}): Promise<ReplaceResponse> => {
  return (
    await EXPRESS_API.patch(`guides/${videoId}/replace`, {
      newVideoId,
    })
  ).data;
};

export const useReplaceGuideMutation = (videoId: number, redirect = true) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(replaceGuide, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async (res: ReplaceResponse) => {
      await queryClient.invalidateQueries(guidesKeyes.details(videoId));
      successToast({ title: 'Training video successfully replaced.' });
      if (redirect) {
        history.push(`/classic/edit-video/${res.newVideoId}/details`);
      }
    },
  });
};
