import * as React from 'react';
import styled from 'styled-components/macro';
import { FaFileVideo } from 'react-icons/fa';

import { theme } from 'lib/style';

const Container = styled.header`
  background-color: ${({ theme }) => theme.colors.primary[100]};
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.padding24};
  grid-area: header;
  border-bottom: 1px solid ${theme.palette.grayBorder};
`;

const MenuSection = styled.div`
  display: none;

  ${theme.mediaQueryMinWidth.md} {
    display: flex;
  }
`;

const Text = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.md};
  color: ${theme.palette.blackRgb75};
  letter-spacing: 0.3px;
  text-transform: uppercase;
`;
const BtnText = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  color: ${theme.palette.whiteRgb75};
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-left: 5px;
`;
const NewVideo = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.primary[100]};
  color: ${theme.palette.whiteRgb75};
  padding: 10px;
`;

const PageHeader = () => {
  return (
    <Container>
      <MenuSection>
        <Text>Video details</Text>
      </MenuSection>
      <NewVideo>
        <FaFileVideo />
        <BtnText>add new video</BtnText>
      </NewVideo>
    </Container>
  );
};

PageHeader.defaultProps = {
  username: '',
  supportMail: 'support@covideo.com',
  telephoneNumber: '800.306.1445',
};

export { PageHeader };
