import { User } from './interfaces/widgets';

export const handleTypeReturn = (user: User, tabType: string) => {
  switch (tabType) {
    case 'views':
      return parseInt(user.views);
    case 'cta-clicks':
      return parseInt(user.clicks);
    case 'engagement':
      return parseFloat(user.engagements.replace(/,/g, '.'));
    case 'videos':
      return parseInt(user.recorded);
    default:
      return 0;
  }
};

const handleTopVideosDataReturn = (item: any, selected: any) => {
  switch (selected) {
    case 'views':
      return {
        id: item.id,
        title: item?.Title ? item.Title : 'N/A',
        name: `${item.FirstName} ${item.LastName}`,
        value: item.cnt,
      };
    case 'engagement':
      return {
        id: item.userId,
        title: item?.title ? item.title : 'N/A',
        name: item.name,
        value: `${item.watched}`,
      };
    case 'cta-clicks':
      return {
        id: item.videoId,
        title: item?.Title ? item.Title : 'N/A',
        name: item?.FirstName ? `${item.FirstName} ${item.LastName}` : 'N/A',
        value: item.cnt,
      };
    default:
      return {};
  }
};

export const generateWidgetData = (data: any, selected: any) => {
  if (data && Array.isArray(data) && data.length) {
    let mapper = data.map((item: any) =>
      handleTopVideosDataReturn(item, selected)
    );
    return mapper;
  }
  return [];
};
