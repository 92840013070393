import { useQuery } from 'react-query';
import { vdpQueryKeys } from './vdpQueryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getUniqueVins = async () => {
  return (await EXPRESS_API.get(`vdp/vins`)).data;
};

export const useGetUniqueVins = () => {
  return useQuery(vdpQueryKeys.vin_list(), getUniqueVins);
};
