import { Search } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import React, { useEffect, useState } from 'react';
import { ButtonSwitch } from '../../ButtonSwitch';

interface IProps {
  onSearch: (value: string) => void;
  isAllChecked?: boolean;
  onSwitchButtonChange: (value: string) => void;
  defaultValue: 'all' | 'manually';
  isRefetching: boolean;
}

export const SelectableResellersHeader = ({
  onSearch,
  isAllChecked,
  onSwitchButtonChange,
  defaultValue,
  isRefetching,
}: IProps) => {
  const [switchDefaultValue, setSwitchDefaultValue] = useState(
    defaultValue || 'manually'
  );

  useEffect(() => {
    if (defaultValue) {
      setSwitchDefaultValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Gap flexDirection='row' gap='12px' width='100%'>
      <ButtonSwitch
        className='btn-switch'
        disabled={isRefetching}
        defaultValue={switchDefaultValue}
        onChange={onSwitchButtonChange}
        values={[
          { value: 'all', text: 'All' },
          { value: 'manually', text: 'Manually' },
        ]}
      />
      {!isAllChecked && (
        <Search onSearch={onSearch} width={'100%'} disabled={isRefetching} />
      )}
    </Gap>
  );
};
