import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';

export const ModalFullscreen = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: white;
  overflow: hidden;
`;

export const ModalHeader = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 3;
`;
export const Title = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss02' on;
  color: ${theme.palette.covideoBlue100};
`;

// Breadcrumbs

export const BreadcrumbsWrapper = styled.div`
  height: 48px;
  background-color: rgba(247, 249, 250, 1);
  border-top: 1px solid rgba(238, 239, 242, 1);
  border-bottom: 1px solid rgba(238, 239, 242, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const Breadcrumb = styled.span<{ active: boolean; isValid: boolean }>`
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[40]};
  ${({ isValid, theme }) =>
    isValid &&
    css`
      color: ${theme.colors.neutral[80]};
    `}
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colors.primary[100]};
    `}
 
  cursor: pointer;
`;

//Body
export const CustomReportBodyWrapper = styled.div`
  padding: 32px 0;
  max-width: 1008px;
  box-sizing: border-box;
  margin: 0 auto;
`;
