import React from 'react';
import styled from 'styled-components/macro';
type PreviewVideoProps = {
  width: string;
  numberOfStills: number;
};
const PreviewVideo = styled.video<PreviewVideoProps>`
  height: auto;
  width: ${props => props.width};
  object-fit: cover;
  object-position: center;
  &:nth-child(1) {
    border-radius: 6px 0 0 6px;
  }
  &:nth-child(${({ numberOfStills }) => numberOfStills}) {
    border-radius: 0 6px 6px 0;
  }
`;
const PreviewVideoWrapper = styled.div<{ top?: any }>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${props => props.top};
  height: 48px;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
`;

type VideoPreviewTrackProps = {
  videoSource: string;
  videoDuration: number;
  numberOfStills: number;
  top?: string;
};

export const VideoPreviewTrack = ({
  videoSource,
  videoDuration,
  numberOfStills,
  top = '0px',
}: VideoPreviewTrackProps) => {
  let refArray = [] as any;
  const videoWidthPercent = 100 / numberOfStills;
  for (let i = 0; i < numberOfStills; i++) {
    refArray.push(React.createRef<HTMLVideoElement>());
  }
  React.useEffect(() => {
    refArray.forEach((ref: any, index: number) => {
      if (ref.current) {
        ref.current.currentTime = videoDuration * (index / numberOfStills);
      }
    });
  }, [refArray]);
  return (
    <PreviewVideoWrapper top={top}>
      {refArray.map((ref: any, index: number) => (
        <PreviewVideo
          key={`previewvideoRef-${index}`}
          ref={ref}
          src={videoSource}
          width={`${videoWidthPercent}%`}
          numberOfStills={numberOfStills}
        />
      ))}
    </PreviewVideoWrapper>
  );
};
