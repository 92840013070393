import React from 'react';
import { ModalPrompt } from 'lib/components/modal';
import { useQueryClient } from 'react-query';
import { guidesKeyes } from 'lib/api/guides/guidesKeyes';

interface LeaveWithoutSaveVisibilityProps {
  setOpen: (status: boolean) => void;
  closeModal: () => void;
}

export const LeaveWithoutSaveVisibility = ({
  setOpen,
  closeModal,
}: LeaveWithoutSaveVisibilityProps) => {
  const queryClient = useQueryClient();
  const close = () => setOpen(false);
  return (
    <ModalPrompt
      title={'Are you sure you want to leave?'}
      content={
        <>You may lose the info you've entered so far if you leave now.</>
      }
      secondaryButtonText={'Keep editing'}
      primaryButtonText={'Leave'}
      primaryButtonType={'destructive'}
      handleSubmit={() => {
        close();
        closeModal();
        queryClient.removeQueries(guidesKeyes.visibility());
      }}
      handleModalClose={close}
      style={{ height: '100%' }}
    />
  );
};
