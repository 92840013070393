import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { REPORT_SCOPE, fetchCustomer, fetchCustomerUser } from 'lib/api';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { LoadingIndicator } from 'lib/components';

import { Analytics } from '../../../video/videoDetails/main';
import {
  ORGANIZATION_PICKER_LINK,
  ORGANIZATION_PICKER_TITLE,
  getReportBaseUrl,
  getReportTitle,
} from './Main';
import { Breadcrumbs, Crumb } from '../../components/Breadcrumbs';
import {
  checkIfHasAccessToMultipleOrg,
  getOrgNameFromAccess,
} from 'lib/utils/organization';
import { useAuth } from 'lib/context';

type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: Date;
};

export const VideoDetails = (props: any) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { userData } = useAuth();
  const { userOrganizationAccess = [] } = userData;
  const hasMultipleOrgs = checkIfHasAccessToMultipleOrg(userOrganizationAccess);
  const organizationName = getOrgNameFromAccess(
    organizationId,
    userOrganizationAccess
  );
  const { reportScope = REPORT_SCOPE.RESELLER } = props;
  const [userLoading, setUserLoading] = React.useState(true);
  const [videoLoading, setVideoLoading] = React.useState(true);
  const [company, setCompany] = React.useState({} as any);
  const [user, setUser] = React.useState({} as User);
  const [video, setVideo] = React.useState<any>({});
  const { userId, companyId, videoId } = props.match.params;

  const fetchUser = async () => {
    setUserLoading(true);
    const customer = await fetchCustomer(companyId);
    setCompany(customer);
    fetchCustomerUser(userId)
      .then((data: User) => {
        setUser(data);
        setUserLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setUserLoading(false);
      });
  };

  React.useEffect(() => {
    fetchUser();
  }, [userId, companyId]);

  const fetchVideo = async (id: string) => {
    setVideoLoading(true);
    const data = await getVideo(id);
    if (data) {
      setVideo(data);
    }
    setVideoLoading(false);
  };

  React.useEffect(() => {
    if (videoId) {
      fetchVideo(videoId);
    }
  }, [videoId]);

  const location = useLocation();

  let breadcrumbs: Crumb[] = [
    {
      label:
        reportScope === REPORT_SCOPE.ORGANIZATION && hasMultipleOrgs
          ? organizationName
          : getReportTitle(reportScope),
      link: `${getReportBaseUrl(reportScope, organizationId)}${
        location.search
      }`,
    },
    {
      label: company.business,
      link: `${getReportBaseUrl(reportScope, organizationId)}/${companyId}${
        location.search
      }`,
    },
    {
      label: `${user.firstName} ${user.lastName}`,
      link: `${getReportBaseUrl(
        reportScope,
        organizationId
      )}/${companyId}/users/${userId}${location.search}`,
    },
    {
      label: video.title,
    },
  ];

  if (reportScope === REPORT_SCOPE.ORGANIZATION && hasMultipleOrgs) {
    breadcrumbs = [
      {
        label: ORGANIZATION_PICKER_TITLE,
        link: ORGANIZATION_PICKER_LINK,
      },
      ...breadcrumbs,
    ];
  }

  return (
    <>
      {userLoading && (
        <LoadingIndicator isLoading={userLoading} height='300px' />
      )}
      {!userLoading && (
        <div>
          <Breadcrumbs crumbs={breadcrumbs} />
          {videoLoading && (
            <LoadingIndicator isLoading={videoLoading} height='300px' />
          )}
          {!videoLoading && (
            <Analytics
              video={video}
              showActionsButtons={true}
              videoId={videoId}
            />
          )}
        </div>
      )}
    </>
  );
};
