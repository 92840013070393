export enum VISIBILITY_ACTIONS {
  SET_RESSELERS = 'SET_RESSELERS',
  SET_CUSTOMERS = 'SET_CUSTOMERS',
  SET_USERS = 'SET_USERS',
  UPDATE_RESELLER_CHECKED = 'UPDATE_RESELLER_CHECKED',
  UPDATE_CUSTOMER_CHECKED = 'UPDATE_CUSTOMER_CHECKED',
  UPDATE_USER_CHECKED = 'UPDATE_USER_CHECKED',
  GET_RESELLER_COUNT = 'GET_RESELLER_COUNT',
  INCREMENT_RESELLER_COUNT = 'INCREMENT_RESELLER_COUNT',
  DECREMENT_RESELLER_COUNT = 'DECREMENT_RESELLER_COUNT',
  SELECT_ALL_RESELLERS = 'SELECT_ALL_RESELLERS',
  DESELECT_ALL_RESELLERS = 'DESELECT_ALL_RESELLERS',
  GET_CUSTOMER_COUNT = 'GET_CUSTOMER_COUNT',
  INCREMENT_CUSTOMERS_COUNT = 'INCREMENT_CUSTOMERS_COUNT',
  DECREMENT_CUSTOMERS_COUNT = 'DECREMENT_CUSTOMERS_COUNT',
  SELECT_ALL_CUSTOMERS = 'SELECT_ALL_CUSTOMERS',
  DESELECT_ALL_CUSTOMERS = 'DESELECT_ALL_CUSTOMERS',
  GET_USERS_COUNT = 'GET_USERS_COUNT',
  INCREMENT_USERS_COUNT = 'INCREMENT_USERS_COUNT',
  DECREMENT_USERS_COUNT = 'DECREMENT_USERS_COUNT',
  SELECT_ALL_USERS = 'SELECT_ALL_USERS',
  DESELECT_ALL_USERS = 'DESELECT_ALL_USERS',
  SHARE_WITH_COVIDEO = 'SHARE_WITH_COVIDEO',
  SET_RESSELERS_PARTIAL = 'SET_RESSELERS_PARTIAL',
  SET_CUSTOMERS_PARTIAL = 'SET_CUSTOMERS_PARTIAL',
  SET_USERS_PARTIAL = 'SET_USERS_PARTIAL',
  RESELLERS_PAGE_COUNT = 'RESELLERS_PAGE_COUNT',
  CUSTOMERS_PAGE_COUNT = 'CUSTOMERS_PAGE_COUNT',
  USERS_PAGE_COUNT = 'USERS_PAGE_COUNT',
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  GET_INITIAL_CHECKED_RESSELLERS = 'GET_INITIAL_CHECKED_RESSELLERS',
  GET_INITIAL_CHECKED_CUSTOMERS = 'GET_INITIAL_CHECKED_CUSTOMERS',
  GET_INITIAL_CHECKED_USERS = 'GET_INITIAL_CHECKED_USERS',
}
