import Cookies from 'js-cookie';
import { COOKIE_NAME } from './types';
import { isEnvDevelopment } from './enviroments';

export const getCurrentToken = () => {
  if (isEnvDevelopment) {
    return {
      refreshToken: process.env.REACT_APP_REFRESH_JWT,
      token: process.env.REACT_APP_JWT,
    };
  }
  return {
    token: Cookies.get(COOKIE_NAME.JWT),
    refreshToken: Cookies.get(COOKIE_NAME.REFRESH_JWT),
  };
};
