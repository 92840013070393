import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MultiLocationList from './pages/MultiLocationList';
import { MultiLocationDetails } from './pages/MultiLocationDetails';

export const MultiLocationsRoutes = () => {
  return (
    <Switch>
      <Route path='/admin/multilocations' component={MultiLocationList} exact />
      <Route
        path='/admin/multilocations/create'
        component={MultiLocationDetails}
      />
      <Route
        path='/admin/multilocations/:customerId/edit'
        component={MultiLocationDetails}
      />
    </Switch>
  );
};
