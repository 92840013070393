import { IQuickShareMarkAsSentParams } from 'lib/api/quickshare/useQuickShareMarkAsSentMutation';
import { NewModal } from 'lib/components';
import { useAuth } from 'lib/context';
import * as React from 'react';
import { Button } from 'react-covideo-common';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';

const Text = styled.div`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #001b53;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 10px;
`;

const BlueBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 16px;
  border-radius: 5px;
  background-color: #f2f4f6;
`;

const Disclaimer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DisclaimerIconWrap = styled.section`
  margin-right: 14px;
  height: 100%;
`;

type Props = {
  handleModalClose: () => void;
  quickShareMarkAsSentHandler: (params: IQuickShareMarkAsSentParams) => void;
  isQuickShareLoading?: boolean;
  markAsSentPayload: Omit<IQuickShareMarkAsSentParams, 'markAsSentConfirmed'>;
};

export const ModalQuickShareMarkAsSent = ({
  handleModalClose,
  quickShareMarkAsSentHandler,
  isQuickShareLoading,
  markAsSentPayload,
}: Props) => {
  const history = useHistory();

  const { userData } = useAuth();
  const { isCompanyAdmin } = userData;

  const goToCompanySettings = () => {
    history.push(`/profile/Profile_EditCompanyInfo`);
  };
  return (
    <NewModal
      headerText={'Mark Video as ‘Sent’?'}
      closeModal={handleModalClose}
      style={{
        content: {
          width: '532px',
          minWidth: '532px',
        },
      }}
    >
      <BlueBox>
        <DisclaimerIconWrap>
          <AiFillInfoCircle size={24} color={'rgba(0, 27, 83, 0.4)'} />
        </DisclaimerIconWrap>
        <Disclaimer>
          <Text>
            If you don't want to answer this question with each quickshare,
            {isCompanyAdmin ? ' you' : ' your admin'} can change quickshare
            preferences
            {isCompanyAdmin && (
              <>
                <span> under</span>
                <span
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: '4px',
                  }}
                  onClick={goToCompanySettings}
                >
                  My Company settings.
                </span>
              </>
            )}
          </Text>
        </Disclaimer>
      </BlueBox>
      <ButtonsWrapper>
        <Button
          text={`Leave as ‘Not Sent’`}
          variant='white'
          disabled={isQuickShareLoading}
          onClick={() => {
            quickShareMarkAsSentHandler({
              markAsSentConfirmed: false,
              ...markAsSentPayload,
            });
          }}
        />
        <Button
          text={`Mark as Sent`}
          variant='primary'
          disabled={isQuickShareLoading}
          onClick={() => {
            quickShareMarkAsSentHandler({
              markAsSentConfirmed: true,
              ...markAsSentPayload,
            });
          }}
        />
      </ButtonsWrapper>
    </NewModal>
  );
};
