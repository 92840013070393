import { theme } from 'lib/style';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { reorder } from 'lib/utils/array';
import { DroppableWrapper } from '.';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { QuoteItemType } from 'lib/const';
import { ModalChooseService } from 'app/pages/services/components/ModalChooseService';
import { priceFormatter } from 'lib/utils/functions';
import { toNumber } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { QuoteItem } from 'lib/api/repairOrders/types';
import { Button } from 'react-covideo-common';
import { CarServiceItem } from 'lib/api/carService/types';

const ItemsWrapper = styled.div`
  background: ${theme.palette.white};
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 4px;
`;
const ItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const Title = styled.h3`
  color: ${theme.palette.covideoBlue100};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 4px;
`;
const Sum = styled.p`
  color: ${theme.palette.gray60};
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  > b {
    font-weight: 600;
  }
`;

type Props = {
  requiredQuoteItems: QuoteItem[];
  optionalQuoteItems: QuoteItem[];
  setRequiredQuoteItems: (requiredQuoteItems: QuoteItem[]) => void;
  setOptionalQuoteItems: (optionalQuoteItems: QuoteItem[]) => void;
};

type ItemTracker = {
  [key: string]: boolean;
};

export const MAX_QUOTE_PRICE_DIGITS = 10;
export const QuoteManager = ({
  requiredQuoteItems,
  optionalQuoteItems,
  setRequiredQuoteItems,
  setOptionalQuoteItems,
}: Props) => {
  const [currentQuoteItemType, setCurrentQuoteItemType] = useState(
    QuoteItemType.Required
  );
  const [requiredQuoteItemsSum, setRequiredQuoteItemsSum] = useState(0);
  const [optionalQuoteItemsSum, setOptionalQuoteItemsSum] = useState(0);
  const [showModalChooseService, setShowModalChooseService] = useState(false);
  const [itemsTrackerMap, setItemsTrackerMap] = useState<ItemTracker>({});

  useEffect(() => {
    setRequiredQuoteItems(requiredQuoteItems);
    setOptionalQuoteItems(optionalQuoteItems);
  }, [requiredQuoteItems, optionalQuoteItems]);

  useEffect(() => {
    const newRequiredQuoteItemsSum = requiredQuoteItems.reduce(
      (a, curr) => toNumber(a) + (curr.price ? toNumber(curr.price) : 0),
      0
    );
    const newOptionalQuoteItemsSum = optionalQuoteItems.reduce(
      (a, curr) => toNumber(a) + (curr.price ? toNumber(curr.price) : 0),
      0
    );
    setRequiredQuoteItemsSum(newRequiredQuoteItemsSum);
    setOptionalQuoteItemsSum(newOptionalQuoteItemsSum);

    const updateTracker: ItemTracker = {};
    requiredQuoteItems.forEach(item => {
      if (item.serviceId) {
        updateTracker[item.serviceId] = true;
      }
    });

    optionalQuoteItems.forEach(item => {
      if (item.serviceId) {
        updateTracker[item.serviceId] = true;
      }
    });

    setItemsTrackerMap(updateTracker);
  }, [requiredQuoteItems, optionalQuoteItems]);

  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result['source'] = sourceClone;
    result['destination'] = destClone;

    return result;
  };

  const onDragEnd = async (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === QuoteItemType.Required) {
      if (source.droppableId === destination.droppableId) {
        const updatedQuotes = reorder(
          requiredQuoteItems,
          source.index,
          destination.index
        );
        setRequiredQuoteItems(updatedQuotes);
      } else {
        const result = move(
          requiredQuoteItems,
          optionalQuoteItems,
          source,
          destination
        );
        setRequiredQuoteItems(result.source);
        setOptionalQuoteItems(result.destination);
      }
    } else if (source.droppableId === QuoteItemType.Optional) {
      if (source.droppableId === destination.droppableId) {
        const updatedQuotes = reorder(
          optionalQuoteItems,
          source.index,
          destination.index
        );
        setOptionalQuoteItems(updatedQuotes);
      } else {
        const result = move(
          optionalQuoteItems,
          requiredQuoteItems,
          source,
          destination
        );
        setOptionalQuoteItems(result.source);
        setRequiredQuoteItems(result.destination);
      }
    }
  };

  const handleAddItemClick = (quoteItemType: QuoteItemType) => {
    setCurrentQuoteItemType(quoteItemType);
    setShowModalChooseService(true);
  };

  const handleAddItem = (servicesToInsert: CarServiceItem[]) => {
    const quoteItemsToInsert = servicesToInsert.map(
      (service: CarServiceItem) => {
        if (!service.serviceId) {
          service.serviceId = uuidv4();
        }

        return {
          ...service,
          approved: false,
          required: currentQuoteItemType === QuoteItemType.Required,
          price: Number(service.price),
        };
      }
    );
    if (currentQuoteItemType == QuoteItemType.Required) {
      setRequiredQuoteItems([...requiredQuoteItems, ...quoteItemsToInsert]);
    } else if (currentQuoteItemType == QuoteItemType.Optional) {
      setOptionalQuoteItems([...optionalQuoteItems, ...quoteItemsToInsert]);
    }
  };

  const handleDeleteItem = (droppableId: string, index: number) => {
    let newItemValues: any[] = [];
    if (droppableId == QuoteItemType.Required) {
      newItemValues = [...requiredQuoteItems];
      newItemValues.splice(index, 1);
      setRequiredQuoteItems(newItemValues);
    } else if (droppableId == QuoteItemType.Optional) {
      newItemValues = [...optionalQuoteItems];
      newItemValues.splice(index, 1);
      setOptionalQuoteItems(newItemValues);
    }
  };

  const handleChangeItem = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
    droppableId: string
  ) => {
    let newItemValues: any[] = [];
    if (droppableId == QuoteItemType.Required) {
      newItemValues = [...requiredQuoteItems];
    } else if (droppableId == QuoteItemType.Optional) {
      newItemValues = [...optionalQuoteItems];
    }

    if (
      e.target.name === 'price' &&
      e.target.value.length > MAX_QUOTE_PRICE_DIGITS
    ) {
      return;
    }

    newItemValues[index][e.target.name] =
      e.target.name === 'price' ? e.target.valueAsNumber : e.target.value;
    if (droppableId == QuoteItemType.Required) {
      setRequiredQuoteItems(newItemValues);
    } else if (droppableId == QuoteItemType.Optional) {
      setOptionalQuoteItems(newItemValues);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <ItemsWrapper>
          <ItemsHeader>
            <div>
              <Title>Required Items</Title>
              <Sum>
                <b>Items</b> {requiredQuoteItems.length}, <b>Total</b>{' '}
                {priceFormatter(requiredQuoteItemsSum)}
              </Sum>
            </div>
            <Button
              variant='secondary'
              onClick={() => handleAddItemClick(QuoteItemType.Required)}
              text='Add items'
              icon={<HiOutlinePlusSm color={theme.palette.covideoBlue100} />}
            />
          </ItemsHeader>
          <DroppableWrapper
            droppableId={QuoteItemType.Required}
            quoteItems={requiredQuoteItems}
            handleDeleteItem={handleDeleteItem}
            handleChangeItem={handleChangeItem}
          />
        </ItemsWrapper>
        <ItemsWrapper>
          <ItemsHeader>
            <div>
              <Title>Optional Items</Title>
              <Sum>
                <b>Items</b> {optionalQuoteItems.length}, <b>Total</b>{' '}
                {priceFormatter(optionalQuoteItemsSum)}
              </Sum>
            </div>
            <Button
              variant='secondary'
              text='Add items'
              icon={<HiOutlinePlusSm color={theme.palette.covideoBlue100} />}
              onClick={() => handleAddItemClick(QuoteItemType.Optional)}
            />
          </ItemsHeader>
          <DroppableWrapper
            droppableId={QuoteItemType.Optional}
            quoteItems={optionalQuoteItems}
            handleDeleteItem={handleDeleteItem}
            handleChangeItem={handleChangeItem}
          />
        </ItemsWrapper>
      </DragDropContext>
      {showModalChooseService && (
        <ModalChooseService
          itemsTrackerMap={itemsTrackerMap}
          handleModalClose={() => setShowModalChooseService(false)}
          onSubmit={handleAddItem}
        />
      )}
    </>
  );
};
