import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { MeetingResponse, MEETING_STATUSES, MeetingStatus } from './types';

const getMeetingStatusList = async (
  status?: MeetingStatus
): Promise<MeetingResponse[]> => {
  const response = await EXPRESS_API.get(`/meeting`, {
    params: {
      meetingStatus: status || MEETING_STATUSES.SCHEDULED,
    },
  });
  return response.data;
};
export const useGetMeetingStatusList = (status: MeetingStatus) => {
  return useQuery(
    meetingKeys.meetings_list_tab(status),
    () => getMeetingStatusList(status),
    {
      refetchOnWindowFocus: true, // refetch when back from meeting
    }
  );
};
