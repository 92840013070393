import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from 'lib/components';
import { SendTab, ShareTab, tabs, ModalFullscreen, PublishTab } from './';
import { EmbedTab } from './embed/EmbedTab';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { MdOutlineSend } from 'react-icons/md';
import { theme } from 'lib/style';
import { CardAlert } from 'lib/components/CardAlert';
import { useGetSingleScheduleQuery } from 'lib/api/schedule/getSingleSchedule';
import { useSendAndShare } from 'lib/context/send-and-share/provider';
import { useAuth } from 'lib/context';
import { getNotificationPreference } from 'lib/api';
import selectors from '../../../../../../../cypress/selectors';

type Props = {
  editScheduleId?: number;
  handleModalClose: () => void;
};

export const SendAndShareModal = ({
  editScheduleId,
  handleModalClose,
}: Props) => {
  const { isLoading } = useSendAndShare();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [showFreemiumAlert, setShowFreemiumAlert] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs.send.value);
  const { userData } = useAuth();

  const {
    isRefetching: isRefetchingSingleSchedule,
    isLoading: isLoadingSingleSchedule,
    data: editSchedule,
  } = useGetSingleScheduleQuery(editScheduleId);

  const [notify, setNotify] = useState<boolean>(
    editSchedule ? !!editSchedule?.notifyWhenViewed : false
  );

  const getPreferences = async () => {
    // do not fetch preferences when editing scheduled Email/SMS because notify flag is set from edit schedule data
    if (editSchedule) {
      return;
    }
    const preferences = await getNotificationPreference();
    setNotify(!!preferences.videoViewsEmail);
  };

  useEffect(() => {
    getPreferences();
    if (document && document.body) {
      const orig = document.body.className;
      document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    }
    if (queryParams.freemium === 'true') {
      setShowFreemiumAlert(true);
    }

    if (!userData.webEmailEnabled) {
      tabs.send.isDisabled = true;
      setActiveTab(tabs.share.value);
    } else if (!userData.webShare) {
      tabs.share.isDisabled = true;
      tabs.embed.isDisabled = true;
      tabs.publish.isDisabled = true;
    }

    return () => {
      if (document && document.body) {
        document.body.className = document.body.className.replace(
          / ?modal-open/,
          ''
        );
      }
    };
  }, []);

  if (isLoading || isRefetchingSingleSchedule || isLoadingSingleSchedule) {
    return (
      <ModalFullscreen>
        <LoadingIndicator isLoading={true} height='300px' />
      </ModalFullscreen>
    );
  }

  return (
    <>
      <ModalFullscreen data-cy={selectors.libraryPage.sendAndShareModal.all}>
        {activeTab === tabs.send.value && (
          <SendTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleModalClose={handleModalClose}
            editSchedule={editSchedule}
            setShowFreemiumAlert={setShowFreemiumAlert}
            notify={notify}
            setNotify={setNotify}
          />
        )}

        {activeTab === tabs.share.value && (
          <ShareTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleModalClose={handleModalClose}
            notify={notify}
          />
        )}
        {activeTab === tabs.publish.value && (
          <PublishTab
            setActiveTab={setActiveTab}
            handleModalClose={handleModalClose}
          />
        )}
        {activeTab === tabs.embed.value && (
          <EmbedTab
            setActiveTab={setActiveTab}
            handleModalClose={handleModalClose}
          />
        )}
        {showFreemiumAlert && (
          <CardAlert
            handleClose={() => setShowFreemiumAlert(false)}
            title="Now add a recipient and click 'Send Email' to share your first video!"
            icon={<MdOutlineSend size={22} color={theme.palette.green100} />}
            backgroundColor={theme.palette.green02}
            width={'460px'}
            top={'96px'}
            titleColor={theme.palette.green100}
            content={<></>}
          />
        )}
      </ModalFullscreen>
    </>
  );
};
