import React from 'react';
import {
  FieldLabel,
  FormProps,
  getInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const AudiDealerSection = (props: FormProps) => {
  const { isSubmitting } = props;
  return (
    <Section>
      <SectionTitle>Audi Organization</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Audi Dealer Code</FieldLabel>
          {getInputField('audiDealerCode', false, false, isSubmitting)}
        </Row>

        <Row>
          <FieldLabel>Area</FieldLabel>
          {getInputField('audiAreaCode', false, false, isSubmitting)}
        </Row>

        <Row>
          <FieldLabel>Sub Area</FieldLabel>
          {getInputField('audiSubAreaCode', false, false, isSubmitting)}
        </Row>
      </RowWrapper>
    </Section>
  );
};
