import { useQuery } from 'react-query';
import { droplrKeys } from './queryKeys';
import { checkDropAuth, client } from './droplrAuth';
import { CombinedBoard, CovideoBoard, DroplrBoard } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';
import { DEFAULT_BOARD } from 'lib/const/Droplr';

export type GetBoardParams = {
  userId: number;
  customerId: number;
  boardId: string;
};

const getBoard = async (params: GetBoardParams) => {
  const { boardId, userId, customerId } = params;

  if (!boardId) return DEFAULT_BOARD;

  await checkDropAuth();
  // fetch board from droplr
  const droplrBoard = (await client.boards.get(boardId)) as DroplrBoard;

  // fetch board details from covideo
  const covideoResponse = (
    await EXPRESS_API.get('/board', {
      params: {
        userId,
        customerId,
        droplrBoardId: boardId,
      },
    })
  ).data;
  const covideoBoard = covideoResponse?.board as CovideoBoard;

  // append thumbnail to board
  return {
    ...droplrBoard,
    thumbnailUrl: covideoBoard?.thumbnailUrl || '',
  } as CombinedBoard;
};

export const useGetBoard = (params: GetBoardParams) => {
  return useQuery(droplrKeys.board_details(params), () => getBoard(params));
};
