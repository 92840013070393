import { Gap } from 'lib/components/styles/layout';
import React from 'react';

export const SelectableBodyWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Gap
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='flex-start'
      flexWrap='nowrap'
      width='100%'
      gap='0'
      style={{ height: 'calc(100vh - 324px)' }}
    >
      {children}
    </Gap>
  );
};
