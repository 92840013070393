import * as React from 'react';
import { Form, Formik, useField } from 'formik';
import { useTheme } from 'styled-components/macro';
import { ButtonSwitch, NewModal } from 'lib/components';
import { useAuth } from 'lib/context';
import {
  DefaultThumbnailUrls,
  ThumbnailTabContent,
} from '../ThumbnailTabContent';
import { FileListModal } from '../FileListModal';
import { SharingTabContent } from '../SharingTabContent';
import { generatePassword } from 'lib/utils/functions';
import {
  SendNotificationParams,
  saveNotification,
} from 'lib/api/notificationApi';
import Switch from '../../../design/landingPageBuilder/components/Switch';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import {
  NOTIFICATION_EMAILS,
  NOTIFICATION_IN_APP,
} from 'app/pages/notifications/const';
import { CombinedBoard, CreateBoardBody } from 'lib/api/droplr/types';
import {
  BOARDS_FALLBACK_THUMBNAIL,
  BOARD_PRIVACY,
  BOARD_PRIVACY_OPTIONS,
  DEFAULT_BOARD,
} from 'lib/const/Droplr';
import {
  ButtonSwitchWrapper,
  Content,
  Description,
  ErrorMessage,
  Footer,
  InfoMessage,
  Input,
  InputGroup,
  Label,
  PasswordWrapper,
  Tab,
  TabContainer,
  ToggleLabel,
  ToggleWrapper,
} from './style';
import { useCreateBoard } from 'lib/api/droplr/createBoard';
import { prepareBoardNotification } from 'lib/utils/drop';
import { useEditBoard } from 'lib/api/droplr/editBoard';
import { useSendShareNotificationEmail } from 'lib/api/droplr/useSendShareNotificationEmail';
import { Button } from 'react-covideo-common';
import { useEditMissingCovideoBoard } from 'lib/api/droplr/editMissingCovideoBoard';
import {
  MdImage,
  MdOutlineAddToPhotos,
  MdSupervisorAccount,
} from 'react-icons/md';
import { IoMdLink } from 'react-icons/io';
type Tabs = 'settings' | 'sharing' | 'thumbnail';

interface TextInputProps {
  label?: string;
  placeholder?: string;
  name: string;
  id?: string;
  type?: string;
  width?: string;
  mtop?: string;
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void;
  maxLength?: number;
  hasError?: boolean;
}

const TextInput = ({
  label,
  width = '',
  hasError,
  ...props
}: TextInputProps) => {
  const [field, meta] = useField(props);

  if (!field.value) {
    field.value = '';
  }

  const trimValueLength = field.value.trim().length;
  const errorCondition = hasError && !trimValueLength;
  if (errorCondition) {
    meta.error = `Board name cannot be empty.`;
  }

  return (
    <InputGroup width={width} mtop={props.mtop}>
      <Label htmlFor={props.id || props.name}>
        {label && <div>{label}</div>}
      </Label>
      <Input {...field} {...props} />
      {(meta.touched && meta.error) || errorCondition ? (
        <ErrorMessage className='error'>{meta.error}</ErrorMessage>
      ) : null}
    </InputGroup>
  );
};

type Props = {
  board?: CombinedBoard;
  handleModalClose: () => void;
};

export const BoardModal = ({ handleModalClose, board }: Props) => {
  const { userData } = useAuth();

  const defaultSelectedThumbnailUrl = DefaultThumbnailUrls.FolderSetIcon;
  const [currentTab, setCurrentTab] = React.useState<Tabs>('settings');
  const [croppedImageUrl, setCroppedImageUrl] = React.useState('');
  const [showFileListModal, setShowFileListModal] =
    React.useState<boolean>(false);
  const [thumbnailImageUrl, setThumbnailImageUrl] = React.useState<string>(
    defaultSelectedThumbnailUrl
  );
  const [isCustomImage, setIsCustomImage] = React.useState<boolean>(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [editorImage, setEditorImage] = React.useState(
    isCustomImage ? thumbnailImageUrl : ''
  );

  const [accessList, setAccessList] = React.useState<Array<any>>([]);
  const [covideoList, setCovideoList] = React.useState<any>({});
  const [defaultPassword] = React.useState(generatePassword(10));
  const themes = useTheme();

  const [showShareWithMessageModal, setShowShareWithMessageModal] =
    React.useState(false);

  const { mutateAsync: createBoard } = useCreateBoard({
    userId: Number(userData.id),
    customerId: Number(userData.customerId),
  });

  const { mutateAsync: editBoard } = useEditBoard({
    userId: Number(userData.id),
    customerId: Number(userData.customerId),
  });

  const { mutateAsync: editMissingCovideoBoard } = useEditMissingCovideoBoard({
    userId: Number(userData.id),
    customerId: Number(userData.customerId),
  });

  const { mutateAsync: sendShareNotificationEmail } =
    useSendShareNotificationEmail();
  const isEditMode = !!board;
  const isCreateMode = !isEditMode;

  let selectedBoard = board || DEFAULT_BOARD;
  if (isEditMode && !selectedBoard.password) {
    selectedBoard.password = defaultPassword;
  }

  React.useEffect(() => {
    if (selectedBoard && selectedBoard.accessList) {
      setAccessList(selectedBoard.accessList);
    }
  }, []);
  React.useEffect(() => {
    if (isCreateMode) {
      return;
    }

    let currentBoard = board;
    const boardThumbnail =
      currentBoard.thumbnailUrl || BOARDS_FALLBACK_THUMBNAIL;
    if (!boardThumbnail) {
      return;
    }

    setThumbnailImageUrl(boardThumbnail);
    const isCustomImageCase = isCustomThumbnailCase(boardThumbnail);
    setIsCustomImage(isCustomImageCase);
    if (isCustomImageCase) {
      setCroppedImageUrl(boardThumbnail);
      setShowPreview(true);
      setEditorImage(boardThumbnail);
    }
  }, []);

  const isCustomThumbnailCase = (url: string) => {
    if (!url) {
      return false;
    }

    try {
      const urlObject = new URL(url);
      if (!urlObject) {
        return false;
      }

      return (
        window.location.origin !== urlObject.origin &&
        urlObject.origin !== 'https://www.covideo.com'
      );
    } catch (ex) {
      return false;
    }
  };
  const copyPassword = (password: string) => {
    const cb = navigator.clipboard;

    cb.writeText(password).then(() => {
      successToast({ title: 'Password copied successfully!' });
    });
  };

  const sendEmail = async (data: any, toastMessage: string) => {
    if (isCreateMode) {
      return;
    }

    try {
      saveBoard(data);
      if (!data.recipientEmails || !data.recipientEmails.length) {
        successToast({ title: toastMessage || 'Success' });
        handleModalClose();
        return;
      }
      // add notification prop to check n.preferences
      data.notificationProp = 'boardsEmail';
      const response = await sendShareNotificationEmail(data);
      if (response && response.success) {
        successToast({ title: toastMessage });
      }

      handleModalClose();
    } catch (ex) {
      errorToast({ title: 'Some error occured.' });
    }
  };

  const storeNotification = async (
    notificationData: SendNotificationParams
  ) => {
    if (!notificationData.notificationProp) {
      notificationData.notificationProp = [
        NOTIFICATION_EMAILS.BOARDS,
        NOTIFICATION_IN_APP.BOARDS,
      ];
    }
    await saveNotification(notificationData);
    return;
  };

  const getRecipientIds = (userListHashMap: any) => {
    let finalUserIds = [];
    for (let i = 0; i < accessList.length; i++) {
      if (
        accessList[i] &&
        accessList[i].userId &&
        userListHashMap[accessList[i].userId]
      ) {
        finalUserIds.push(
          JSON.stringify(userListHashMap[accessList[i].userId].covideoUserId)
        );
      }
    }
    return finalUserIds;
  };
  const formatAndStoreNotification = (board: CombinedBoard) => {
    const notificationData = prepareBoardNotification(
      board,
      getRecipientIds(covideoList),
      userData
    );
    storeNotification(notificationData);
  };

  const saveBoard = async (data: CombinedBoard) => {
    const saveBoardData = {
      thumbnailUrl: thumbnailImageUrl,
      userId: parseInt(userData.userId, 10),
      customerId: parseInt(userData.customerId, 10),
      accessList: accessList,
      groupsAccessList: accessList.map(item => {
        return { id: item.userId };
      }),
      isPublic: data.isPublic,
      name: data.name,
      poweredBy: data.poweredBy,
      privacy: data.privacy,
      uploadPrivacy: data.uploadPrivacy,
    } as CreateBoardBody;

    if (data.privacy === BOARD_PRIVACY.PRIVATE) {
      saveBoardData.password = data.password;
    }

    if (isCreateMode) {
      return await createBoard(saveBoardData);
    }

    if (selectedBoard) {
      if (!board?.thumbnailUrl) {
        return await editMissingCovideoBoard({
          ...saveBoardData,
          id: selectedBoard.id,
        });
      }
      return await editBoard({
        ...saveBoardData,
        id: selectedBoard.id,
      });
    }

    return undefined;
  };

  const maxAllowedTitleLength: number = 25;
  return (
    <NewModal
      closeModal={handleModalClose}
      headerText={isEditMode ? 'Edit board' : 'Create board'}
    >
      <div>
        <Content>
          <Description>
            Organize the most important files around your projects, clients or
            interests.
          </Description>
          <TabContainer>
            <Tab
              active={currentTab === 'settings'}
              activeColor={themes.colors.primary[100]}
              beforeIconImage={<MdOutlineAddToPhotos />}
              onClick={() => {
                setCurrentTab('settings');
              }}
              title={'Settings'}
            >
              Settings
            </Tab>
            <Tab
              active={currentTab === 'sharing'}
              activeColor={themes.colors.primary[100]}
              onClick={() => {
                setCurrentTab('sharing');
              }}
              title={'Sharing'}
            >
              <MdSupervisorAccount size={24} />
              &nbsp;Sharing
            </Tab>
            <Tab
              active={currentTab === 'thumbnail'}
              activeColor={themes.colors.primary[100]}
              beforeIconImage={<MdImage />}
              onClick={() => {
                setCurrentTab('thumbnail');
              }}
              title={'Thumbnail'}
            >
              Thumbnail
            </Tab>
          </TabContainer>

          <>
            <Formik
              initialValues={selectedBoard}
              enableReinitialize={true}
              onSubmit={async (data: CombinedBoard) => {
                const savedBoard = await saveBoard(data);
                if (savedBoard && accessList.length) {
                  formatAndStoreNotification(savedBoard);
                }
                handleModalClose();
              }}
            >
              {({ isSubmitting, values, setFieldValue, dirty }) => {
                const boardName = values.name.trim();
                const isValid =
                  thumbnailImageUrl !== '' &&
                  !!boardName &&
                  boardName.length <= maxAllowedTitleLength &&
                  boardName.length > 0 &&
                  ((values.privacy === BOARD_PRIVACY.PRIVATE &&
                    !!values.password) ||
                    values.privacy === BOARD_PRIVACY.PUBLIC);

                const isPrivate = values.privacy === BOARD_PRIVACY.PRIVATE;
                return (
                  <>
                    {showFileListModal ? (
                      <FileListModal
                        editorImage={editorImage}
                        setEditorImage={setEditorImage}
                        setIsCustomImage={setIsCustomImage}
                        setThumbnailImageUrl={setThumbnailImageUrl}
                        setShowFileListModal={setShowFileListModal}
                      />
                    ) : (
                      <Form>
                        <>
                          {currentTab === 'settings' && (
                            <>
                              <TextInput
                                name='name'
                                type='text'
                                placeholder='My favorite board...'
                                label='Board Name'
                                maxLength={maxAllowedTitleLength}
                                mtop='24px'
                                hasError={!!dirty}
                              />
                              <ButtonSwitchWrapper>
                                <ButtonSwitch
                                  className={'alignSwitchButton'}
                                  defaultValue={values.privacy}
                                  values={BOARD_PRIVACY_OPTIONS}
                                  onChange={newValue =>
                                    setFieldValue('privacy', newValue)
                                  }
                                />
                              </ButtonSwitchWrapper>

                              {isPrivate && (
                                <PasswordWrapper>
                                  <TextInput
                                    name='password'
                                    type='text'
                                    placeholder='Password'
                                    label='Password'
                                    width='60%'
                                  />
                                  <Button
                                    variant='secondary'
                                    text='Copy to Clipboard'
                                    onClick={() =>
                                      copyPassword(values.password || '')
                                    }
                                    icon={<IoMdLink size={18} />}
                                  />
                                </PasswordWrapper>
                              )}

                              <ToggleWrapper>
                                <ToggleLabel>Allow public upload</ToggleLabel>
                                <Switch
                                  id={'switch-public-upload'}
                                  isOn={
                                    values.uploadPrivacy ===
                                    BOARD_PRIVACY.PUBLIC
                                  }
                                  onColor={themes.colors.primary[100]}
                                  handleToggle={() => {
                                    setFieldValue(
                                      'uploadPrivacy',
                                      values.uploadPrivacy ===
                                        BOARD_PRIVACY.PUBLIC
                                        ? BOARD_PRIVACY.PRIVATE
                                        : BOARD_PRIVACY.PUBLIC
                                    );
                                  }}
                                />
                              </ToggleWrapper>
                              <InfoMessage>
                                Request Files from Others. Recipients can upload
                                files and will see a content of Board.
                              </InfoMessage>
                            </>
                          )}
                          {currentTab === 'thumbnail' && (
                            <>
                              <ThumbnailTabContent
                                editorImage={editorImage}
                                setEditorImage={setEditorImage}
                                currentTab={currentTab}
                                croppedImageUrl={croppedImageUrl}
                                setCroppedImageUrl={setCroppedImageUrl}
                                showPreview={showPreview}
                                setShowPreview={setShowPreview}
                                thumbnailImageUrl={thumbnailImageUrl}
                                setThumbnailImageUrl={setThumbnailImageUrl}
                                isCustomImage={isCustomImage}
                                setIsCustomImage={setIsCustomImage}
                                setShowFileListModal={setShowFileListModal}
                              />
                            </>
                          )}

                          {currentTab === 'sharing' && (
                            <SharingTabContent
                              sendEmail={sendEmail}
                              storeNotification={storeNotification}
                              covideoList={covideoList}
                              setCovideoList={setCovideoList}
                              accessList={accessList}
                              setAccessList={setAccessList}
                              itemType={'board'}
                              setShowShareWithMessageModal={
                                setShowShareWithMessageModal
                              }
                              showShareWithMessageModal={
                                showShareWithMessageModal
                              }
                              thumbnailUrl={thumbnailImageUrl}
                              board={board}
                            />
                          )}

                          <Footer>
                            {isEditMode && currentTab === 'sharing' && (
                              <Button
                                variant='secondary'
                                text={'Share with Message'}
                                type='button'
                                disabled={false}
                                onClick={() => {
                                  setShowShareWithMessageModal(true);
                                }}
                              />
                            )}
                            <Button
                              text={values.id ? 'Update' : 'Create'}
                              type='submit'
                              disabled={!isValid || isSubmitting}
                            />
                          </Footer>
                        </>
                      </Form>
                    )}
                  </>
                );
              }}
            </Formik>
          </>
        </Content>
      </div>
    </NewModal>
  );
};
