import { useFormikContext } from 'formik';
import { useAuth } from 'lib/context';
import React from 'react';
import { CustomReportModalFormikValues } from '../../types';
import { SelectCustomReportUsers } from './SelectCustomReportUsers';
import { CustomReportBodyWrapper } from '../../styles';
import { SelectCustomReportCustomerUsers } from './SelectCustomReportCustomerUsers';
import { IResellerCustomersResponse } from 'lib/api/superadmin/useCustomersResellersQuery';
interface IProps {
  setResellerCustomersResponse: React.Dispatch<
    React.SetStateAction<IResellerCustomersResponse | undefined>
  >;
  resellerCustomersResponse: IResellerCustomersResponse | undefined;
}

export const CustomModalReportUsersStep = ({
  setResellerCustomersResponse,
  resellerCustomersResponse,
}: IProps) => {
  const {
    userData: { isResellerAdmin },
  } = useAuth();

  const { values } = useFormikContext<CustomReportModalFormikValues>();

  return (
    <CustomReportBodyWrapper>
      {isResellerAdmin ? (
        <SelectCustomReportCustomerUsers
          initUsers={values.users || []}
          setResellerCustomersResponse={setResellerCustomersResponse}
          resellerCustomersResponse={resellerCustomersResponse}
        />
      ) : (
        <SelectCustomReportUsers />
      )}
    </CustomReportBodyWrapper>
  );
};
