import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const NoVideoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  margin-top: 160px;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: ${theme.palette.black_1_100};
`;

export const NoBatches = () => (
  <NoVideoContent>
    <Title>There are no Email Batches</Title>
  </NoVideoContent>
);
