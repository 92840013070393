import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';

export const MainContainer = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  padding: 12px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 12px 12px 64px 220px;
  }
`;

export const MainWrapper = styled.div`
  margin-top: 38px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;

  ${theme.mediaQueryMinWidth.lg} {
    max-width: 1440px;
  }
`;

// TODO: Remove min-width after customer detail pages are completed
export const Container = styled.div<{ overflowX?: string }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: ${props => props.overflowX || 'hidden'};
  background-color: #fff;
  margin: 12px 0 0 0;
  padding: 12px;
  width: 1064px;
`;

export const NoList = styled.div`
  padding-left: 400px;
  margin-top: 200px;
`;

export const Card = styled.div`
  padding: 16px !important;
  width: 100%;
  border-radius: 16px;
  background: ${theme.palette.gray10};
`;

export const HoverElement = styled.div<{
  padding?: string;
  top?: string;
  right?: string;
  center?: boolean;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  padding: 8px;
  align-self: center;
  .hover-content {
    display: none;
  }
  &.padding--25 {
    padding-left: 25px;
  }
  &.padding--25--right {
    padding-left: 25px;
    padding-right: 25px;
  }
  &:hover {
    .hover-content {
      top: ${props => props.top || '40px'};
      right: ${props => props.right || '0px'};
      ${props =>
        props.center &&
        css`
          left: 50%;
          transform: translate(-50%, 0);
          width: fit-content;
        `}
      border: 1px solid #f7f8fa;
      background: white;
      padding: ${props => props.padding || '10px 10px 10px 10px'};
      display: block;
      position: absolute;
      border-radius: 5px;
      box-shadow: 0px 12px 32px rgba(29, 30, 36, 0.35);
      z-index: 10;
      .sub-content {
        display: flex;
        justify-content: center;
        padding-top: 5px;
      }
    }
  }
`;

export const TableCell = styled.div<{
  width?: number;
  cursor?: 'pointer' | 'default' | 'auto';
  alignItems?: string;
  textAlign?: string;
  justifyContent?: 'flex-end' | 'flex-start' | 'center';
  gap?: string;
  paddingLeft?: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  justify-content: ${props => props?.justifyContent || 'flex-start'};
  ${props => (props.gap ? `gap: ${props.gap}` : '')};
  ${props => (props.paddingLeft ? `padding: ${props.paddingLeft}` : '')};
`;

export const SortCell = styled.div<{
  cursor?: 'pointer' | 'default' | 'auto';
}>`
  padding-top: 2px;
  padding-left: 10px;
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
  .active {
    fill: black;
  }
`;

export const PaginationWrapper = styled.div`
  width: 100%;
`;

export const NormalText = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${theme.palette.gray100};
`;

export const LinkText = styled(NormalText)`
  cursor: pointer;
`;

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  span {
    color: ${theme.palette.gray80};
    font-weight: normal;
    font-size: 16px;
    margin-left: 8px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  max-width: 700px;
  margin-left: 0;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: #eeeff2;
`;

export const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
`;
