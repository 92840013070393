import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import {
  MdEmail,
  MdFileDownload,
  MdKeyboardArrowRight,
  MdPlayArrow,
} from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import { theme } from 'lib/style';
import { moveVideo, downloadVideo } from 'lib/api';
import { ModalVideoListVideo } from 'lib/components/modal';
import { reportTypes } from 'lib/const';
import {
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import {
  downloadReportData,
  getUsersDetailsMetrics,
  fetchCustomerUser,
} from 'lib/api';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import {
  DateRangeSelector,
  timeRangeConstants,
  ReportCard,
  SendReportModal,
  calculateDatesForRange,
} from '../../components';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { Heading, ParagraphNormal } from 'lib/components/styles/typography';
import { NotFound } from 'app/pages/notFound/NotFound';
import { successToast } from 'lib/components/toasts/success';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import queryString from 'query-string';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import { Button } from 'react-covideo-common';
import { CopyVideoAsOtherUserButton } from './CopyVideoAsOtherUserButton';

const LinkWrapper = styled.div`
  display: flex;
  font-size: 15px;
  color: #001b53cc;
  margin-bottom: 24px;
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;
const UsersDetailsWrapper = styled.div`
  .dropdown-container {
    margin: 0;
  }
`;
const TableCell = styled.div`
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const ButtonsTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > button {
    margin-right: 12px;
  }
`;

export const VideoTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 230px;
`;

type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: Date;
};

type Video = {
  videoId: number;
  title: string;
  recordedAt: Date;
  cnt: number;
};

const SORTING = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const Details = (props: any) => {
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const storedDateRange = getDateRangeFromLocalstorage();

  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );

  const [startDate, setStartDate] = React.useState<Date>(start);
  const [endDate, setEndDate] = React.useState<Date>(end);
  const [dateRange, setDateRange] = React.useState(initRange);

  const [videosloading, setVideosLoading] = React.useState(false);
  const [userLoading, setUserLoading] = React.useState(true);
  const [user, setUser] = React.useState({} as User);
  const [videos, setVideos] = React.useState([] as Video[] | null);
  const [filteredVideos, setFilteredVideos] = React.useState(
    [] as Video[] | null
  );
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [showSendReportModal, setShowSendReportModal] = React.useState(false);
  const [showVideoPreviewModal, setShowVideoPreviewModal] = React.useState('');
  const [activeTitleSort, setActiveTitleSort] = React.useState('');
  const [activeDateSort, setActiveDateSort] = React.useState('');
  const [activeCtaSort, setActiveCtaSort] = React.useState('');
  const [activeViewsSort, setActiveViewsSort] = React.useState('');

  const history = useHistory();

  const { userId } = props.match.params;

  const tableFields = [
    {
      value: 'title',
      label: 'Video Title',
      type: 'string',
      sortState: activeTitleSort,
      setSortState: setActiveTitleSort,
    },
    {
      value: 'cnt',
      label: 'Views',
      type: 'number',
      sortState: activeViewsSort,
      setSortState: setActiveViewsSort,
    },
    {
      value: 'ctas',
      label: 'CTA Clicks',
      type: 'number',
      sortState: activeCtaSort,
      setSortState: setActiveCtaSort,
    },
    {
      value: 'recordedAt',
      label: 'Date Recorded',
      type: 'date',
      sortState: activeDateSort,
      setSortState: setActiveDateSort,
    },
  ];

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);

    setVideosLoading(true);
    getUsersDetailsMetrics(userId, start, end)
      .then((data: Video[]) => {
        data.sort((videoA, videoB) => videoB.cnt - videoA.cnt);
        setVideos(data);
        setCount(data.length);
        setFilteredVideos([...data.slice(0, 10)]);
        setVideosLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setVideos(null);
        setVideosLoading(false);
      });
  };

  React.useEffect(() => {
    setUserLoading(true);
    fetchCustomerUser(userId)
      .then((data: User) => {
        setUser(data);
        setUserLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setUserLoading(false);
      });
  }, [userId]);

  React.useEffect(() => {
    const filtered = videos?.filter((video: Video) => {
      const title = (video.title && video.title.toLowerCase()) || '';
      return title.indexOf(searchQuery) !== -1;
    });
    if (filtered) {
      setCount(filtered.length);
      const start = page * size;
      setFilteredVideos([...filtered.slice(start, start + size)]);
    }
  }, [page, size, searchQuery]);

  const onSearch = (query: string) => {
    setPage(0);
    setSearchQuery(query.toLowerCase());
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const downloadVideosList = () => {
    return downloadReportData(
      `/metrics/users/${userId}/download`,
      'users.csv',
      startDate,
      endDate
    );
  };

  if ((!userLoading && !user?.id) || !videos) {
    return <NotFound position='fixed' />;
  }

  const onSort = (
    setSortState: (arg: string) => void,
    sortState: string,
    field: string,
    type: string
  ) => {
    const isAscending = sortState === SORTING.ASC;
    setSortState(isAscending ? SORTING.DESC : SORTING.ASC);
    const data = [...(filteredVideos || [])];

    switch (type) {
      case 'number':
        if (isAscending)
          data.sort((a: any, b: any) =>
            Number(a[field]) > Number(b[field])
              ? -1
              : Number(b[field]) > Number(a[field])
                ? 1
                : 0
          );
        else
          data.sort((a: any, b: any) =>
            Number(a[field]) > Number(b[field])
              ? 1
              : Number(b[field]) > Number(a[field])
                ? -1
                : 0
          );
        break;
      case 'string':
        if (isAscending)
          data.sort((a: any, b: any) =>
            a[field]?.toLowerCase() > b[field]?.toLowerCase()
              ? -1
              : b[field]?.toLowerCase() > a[field]?.toLowerCase()
                ? 1
                : 0
          );
        else
          data.sort((a: any, b: any) =>
            a[field]?.toLowerCase() > b[field]?.toLowerCase()
              ? 1
              : b[field]?.toLowerCase() > a[field]?.toLowerCase()
                ? -1
                : 0
          );
        break;
      case 'date':
        if (isAscending)
          data.sort((a: any, b: any) =>
            new Date(a[field]).toISOString() > new Date(b[field]).toISOString()
              ? -1
              : new Date(b[field]).toISOString() >
                  new Date(a[field]).toISOString()
                ? 1
                : 0
          );
        else
          data.sort((a: any, b: any) =>
            new Date(a[field]).toISOString() > new Date(b[field]).toISOString()
              ? 1
              : new Date(b[field]).toISOString() >
                  new Date(a[field]).toISOString()
                ? -1
                : 0
          );
        break;
      default:
        break;
    }

    setFilteredVideos(data);
  };

  const handleCopyToLibrary = async (
    event: MouseEvent,
    videoId: number | string
  ) => {
    if (event && event.detail > 1) {
      return;
    }
    await moveVideo(videoId?.toString(), {
      fromCompany: true,
    });
    successToast({
      title: 'Video copied successfully',
    });
  };

  const handleDownload = (
    event: MouseEvent,
    videoId: number | string,
    title: string
  ) => {
    if (event && event.detail > 1) {
      return;
    }
    downloadVideo(videoId.toString(), `${title}.mp4`);
  };

  return (
    <UsersDetailsWrapper>
      {userLoading && (
        <LoadingIndicator isLoading={userLoading} height='300px' />
      )}
      {!userLoading && (
        <div>
          <LinkWrapper>
            <LinkBtn
              onClick={() => history.push(`/reports/users${location.search}`)}
            >
              My Team
            </LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <ParagraphNormal color='#4e5461'>{`${user?.firstName} ${user?.lastName}`}</ParagraphNormal>
          </LinkWrapper>
          <Heading m='0 0 32px 0'>User Details</Heading>
          <HeaderWrapper>
            <DateRangeSelector
              initialValue={dateRange}
              onDateRangeChange={onDateRangeChange}
            />
            <Gap>
              <Button
                text='Send'
                icon={<MdEmail size={20} />}
                onClick={() => setShowSendReportModal(true)}
              />
              <Button
                text='Download'
                icon={<MdFileDownload size={20} />}
                onClick={() => downloadVideosList()}
              />
            </Gap>
          </HeaderWrapper>
          <Gap flexWrap='nowrap' style={{ margin: '32px 0' }}>
            <ReportCard
              reportType={reportTypes.RECORDED}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
            <ReportCard
              reportType={reportTypes.SENT}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
            <ReportCard
              reportType={reportTypes.VIEWS}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
            <ReportCard
              reportType={reportTypes.CTA}
              from={startDate}
              to={endDate}
              dateRange={dateRange}
              users={[userId]}
            />
          </Gap>
          <div style={{ width: '300px' }}>
            <Search placeholder='Search videos...' onSearch={onSearch} />
          </div>
          <div style={{ marginTop: '32px' }}>
            {videosloading && (
              <LoadingIndicator isLoading={videosloading} height='300px' />
            )}
            {!videosloading && (
              <>
                {filteredVideos && !!filteredVideos.length && (
                  <TableContextProvider
                    total={count}
                    onChange={onPaginationChange}
                    initPage={page}
                    initSize={size}
                  >
                    <div style={{ overflowX: 'auto' }}>
                      <Table
                        compact={true}
                        headers={[
                          ...tableFields.map(item => {
                            if (!!item.setSortState)
                              return (
                                <TableCell
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={() =>
                                    onSort(
                                      item.setSortState,
                                      item.sortState,
                                      item.value,
                                      item.type
                                    )
                                  }
                                >
                                  <div style={{ marginRight: '10px' }}>
                                    {item.label}
                                  </div>
                                  <HiArrowNarrowUp
                                    color={
                                      item.sortState === SORTING.ASC
                                        ? theme.palette.primaryDarkBlue
                                        : theme.palette.gray60
                                    }
                                    width={8}
                                    height={12}
                                  />
                                  <HiArrowNarrowDown
                                    color={
                                      item.sortState === SORTING.DESC
                                        ? theme.palette.primaryDarkBlue
                                        : theme.palette.gray60
                                    }
                                    width={8}
                                    height={12}
                                  />
                                </TableCell>
                              );
                            return <TableCell>{item.label}</TableCell>;
                          }),
                          '',
                        ]}
                        hoverable={false}
                        rows={filteredVideos.map(
                          (video: any, index: number) => ({
                            key: index,
                            columns: [
                              ...tableFields.map(item => {
                                if (item.type === 'number') {
                                  return (
                                    <TableCell>
                                      {addThousandCommaSeparator(
                                        video[item.value] || 0
                                      )}
                                    </TableCell>
                                  );
                                }

                                if (item.value === 'title') {
                                  return (
                                    <ButtonsTableCell
                                      style={{
                                        justifyContent: 'flex-start',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setShowVideoPreviewModal(video.videoId);
                                      }}
                                    >
                                      <Button
                                        icon={<MdPlayArrow size={20} />}
                                      />
                                      <VideoTitle>
                                        {video.title || '-'}
                                      </VideoTitle>
                                    </ButtonsTableCell>
                                  );
                                }
                                return (
                                  <TableCell>
                                    {video[item.value] || '-'}
                                  </TableCell>
                                );
                              }),
                              <ButtonsTableCell>
                                <Button
                                  variant='secondary'
                                  text='Copy to Library'
                                  onClick={async (
                                    event: MouseEvent | React.SyntheticEvent
                                  ) => {
                                    await handleCopyToLibrary(
                                      event as MouseEvent,
                                      video.videoId
                                    );
                                  }}
                                />
                                <Button
                                  icon={<MdFileDownload size={20} />}
                                  onClick={e => {
                                    handleDownload(
                                      e as unknown as MouseEvent,
                                      video.videoId,
                                      video.title
                                    );
                                  }}
                                />
                                <CopyVideoAsOtherUserButton
                                  videoId={video.videoId}
                                  userId={userId}
                                />
                                <FaChevronRight
                                  color='#9297a2'
                                  size={14}
                                  onClick={() =>
                                    history.push(
                                      `/reports/users/${userId}/videos/${video.videoId}${location.search}`
                                    )
                                  }
                                />
                              </ButtonsTableCell>,
                            ],
                          })
                        )}
                      />
                    </div>
                    <TableFooter>
                      <TablePaginationNew />
                      <TablePaginationSizeNew globalName='videos_list' />
                    </TableFooter>
                  </TableContextProvider>
                )}
                {!filteredVideos?.length && (
                  <div style={{ textAlign: 'center' }}>No data to show.</div>
                )}
              </>
            )}
          </div>

          {showSendReportModal && (
            <SendReportModal
              handleModalClose={() => {
                setShowSendReportModal(false);
                // if (showRecurring) {
                //   setShowRecurringReportModal(true);
                // }
              }}
              startDate={startDate}
              endDate={endDate}
              range={dateRange}
              reportUrl={`/metrics/users/${userId}`}
            />
          )}
          {!!showVideoPreviewModal && (
            <ModalVideoListVideo
              videoId={showVideoPreviewModal}
              handleModalClose={() => {
                setShowVideoPreviewModal('');
              }}
            />
          )}
        </div>
      )}
    </UsersDetailsWrapper>
  );
};
