import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  HeaderCenter,
  HeaderSide,
  LinkBtn,
  LinkWrapper,
  ModalBody,
  ModalFullscreen,
  ModalHeader,
} from 'app/pages/library/videos/videoList/components/SendAndShare';

import { MdClose } from 'react-icons/md';
import { LesaTable } from './LesaTable';

const Title = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-feature-settings:
    'tnum' on,
    'lnum' on,
    'ss02' on;
  color: ${theme.palette.covideoBlue100};
`;

type Props = {
  handleModalClose: () => void;
  onCopy?: () => void;
};

export const ModalLesaVideos = ({ handleModalClose, onCopy }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const root = document.getElementById('root') as HTMLElement;
  useEffect(() => {
    // disable scroll of root when modal is active
    root.style.overflow = 'hidden';

    return () => {
      root.style.overflow = '';
    };
  }, []);

  return (
    <ModalFullscreen style={{ zIndex: 100 }}>
      <ModalHeader>
        <HeaderSide style={{ flex: 0 }}>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter
          style={{ flex: 1, textAlign: 'center', marginRight: 136 }}
        >
          <Title>Available Lesa Videos</Title>
        </HeaderCenter>
      </ModalHeader>
      <ModalBody>
        <LesaTable handleModalClose={handleModalClose} onCopy={onCopy} />
      </ModalBody>
    </ModalFullscreen>
  );
};
