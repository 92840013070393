import * as React from 'react';
import { productFeature } from './productFeature';
import {
  MdAddLink,
  MdLocationPin,
  MdLaunch,
  MdSupervisorAccount,
  MdContacts,
  MdImage,
  MdOutlineBarChart,
  MdTaskAlt,
  MdExtension,
} from 'react-icons/md';
import styled from 'styled-components/macro';
import { CgOrganisation } from 'react-icons/cg';
import { FaShop } from 'react-icons/fa6';
import {
  ALLOWED_CUSTOMERS,
  getAdminAccessList,
  SuperAdminRole,
} from 'lib/const/SuperAdminRole';
import {
  ADMIN_CUSTOMER_IDS,
  CAMPING_WORLD_IDS,
  DEFAULT_RESELLER_ID,
  SCREEN_VALUES,
} from 'lib/const/SuperAdminConstants';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 19px;
    width: 19px;
  }
`;

export interface ProfileNavigationItem {
  onItemClick?: () => void;
  title: string;
  icon?: JSX.Element;
  path: string;
  customLink?: string;
  isDisabled?: boolean;
  productFeatureId?: number;
  isLabel?: boolean;
  iconColor?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
}

export interface NavigationItem {
  title: string;
  shortTitle?: string;
  icon?: JSX.Element;
  path: string;
  customLink?: string;
  innerMenu?: NavigationItem[];
  isDisabled?: boolean;
  productFeatureId?: number;
  onItemClick?: () => void;
}

export const horizontalItemsAll = [
  {
    title: 'Library',
    path: '/home',
    isDisabled: false,
  },
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    isDisabled: false,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Conversationsv2',
    path: '/conversations',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    isDisabled: false,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
];

export const horizontalItemsAllAssignment = [
  {
    title: 'Library',
    path: '/home',
    isDisabled: false,
  },
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    isDisabled: false,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Conversationsv2',
    path: '/conversations',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    isDisabled: false,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
  {
    title: 'Assignments',
    path: '/company/Company_ManageAssignedUsers',
    isDisabled: false,
  },
];

export const horizontalItemsSupervisor = [
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
];

export const horizontalItemsUser = [
  {
    title: 'Library',
    path: '/home',
    isDisabled: false,
  },
  {
    title: 'Reports',
    path: '/reports/overview',
    isDisabled: false,
    productFeatureId: productFeature.REPORTS,
  },
  {
    title: 'Contacts',
    path: '/contacts',
    isDisabled: false,
  },
  {
    title: 'Conversations',
    path: '/sms/overview',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Conversationsv2',
    path: '/conversations',
    isDisabled: false,
    productFeatureId: productFeature.CONVERSATIONS,
  },
  {
    title: 'Integrations',
    path: '/integrations',
    isDisabled: false,
  },
  {
    title: 'Design',
    path: '/design/landing-pages',
    isDisabled: false,
  },
];

export const horizontalItemsAutomotive = [
  {
    title: 'Repair Orders',
    path: '/repair-orders',
    isDisabled: false,
  },
];

export const horizontalItemsAutomotiveSales = [
  {
    title: 'Inventory',
    path: '/inventory',
    isDisabled: false,
  },
];

const MLM: NavigationItem = {
  title: 'Multi-Location Manager',
  shortTitle: 'MLM',
  icon: <MdLocationPin size={24} />,
  path: '/admin/multilocations',
  isDisabled: false,
};

const IMS: NavigationItem = {
  title: 'IMS',
  shortTitle: 'IMS',
  icon: <MdAddLink size={24} />,
  path: '/admin/ims',
  isDisabled: false,
};

const ORGANIZATION: NavigationItem = {
  title: 'Organizations',
  shortTitle: 'Organizations',
  icon: <CgOrganisation size={20} />,
  path: '/admin/organizations',
  isDisabled: false,
};

const RESELLER: NavigationItem = {
  title: 'Resellers',
  shortTitle: 'Resellers',
  icon: <FaShop size={20} />,
  path: '/admin/resellers',
  isDisabled: false,
};

const CUSTOMERS: NavigationItem = {
  title: 'Manage Customers',
  shortTitle: 'Customers',
  icon: <MdContacts size={24} />,
  path: '/admin/customers',
  isDisabled: false,
};
const USERS: NavigationItem = {
  title: 'Manage Users',
  shortTitle: 'Users',
  icon: <MdSupervisorAccount size={20} />,
  path: '/admin/users',
  isDisabled: false,
};

const ASSIGNMENTS: NavigationItem = {
  title: 'Manage Assignments',
  shortTitle: 'Assignments',
  icon: <MdTaskAlt size={18} />,
  path: '/company/Company_ManageAssignedUsers',
  isDisabled: false,
};

const USAGE_REPORT: NavigationItem = {
  title: 'Usage Reports',
  shortTitle: 'Usage Reports',
  icon: <MdOutlineBarChart size={20} />,
  path: '/admin/usage-reports',
  isDisabled: false,
};

const GRAPHICS: NavigationItem = {
  title: 'Graphics',
  shortTitle: 'Graphics',
  icon: <MdImage size={20} />,
  path: '/company/Company_Graphics',
  isDisabled: false,
};

const INTEGRATIONS: NavigationItem = {
  title: 'Integrations',
  shortTitle: 'Integrations',
  icon: <MdExtension size={20} />,
  path: '/admin/integrations',
  isDisabled: false,
};

const LOGIN_AS = (onClick: () => void) => {
  return {
    title: 'Login As',
    shortTitle: 'Login As',
    icon: (
      <IconContainer>
        <MdLaunch />
      </IconContainer>
    ),
    path: '#',
    isDisabled: false,
    onItemClick: onClick,
  };
};

export const getDevAdminItems = ({
  userId,
  customerId,
  superAdminRole,
  onLoginAsClick,
}: {
  userId: string;
  customerId: string;
  superAdminRole: SuperAdminRole;
  onLoginAsClick: () => void;
}) => {
  const {
    showCustomers,
    showResellers,
    showUsageReports,
    showUsers,
    showOrganizations,
    showIms,
    showMlm,
    showIntegrations,
    showLoginAs,
  } = getAdminAccessList(superAdminRole);
  const showAssignments =
    CAMPING_WORLD_IDS.includes(userId.toString()) ||
    ALLOWED_CUSTOMERS.concat(ADMIN_CUSTOMER_IDS).includes(
      customerId.toString()
    );

  let innerMenu = [];
  if (showMlm) {
    innerMenu.push(MLM);
  }

  if (showIms) {
    innerMenu.push(IMS);
  }

  if (showOrganizations) {
    innerMenu.push(ORGANIZATION);
  }

  if (showResellers) {
    innerMenu.push(RESELLER);
  }

  if (showCustomers) {
    innerMenu.push({
      ...CUSTOMERS,
      title:
        superAdminRole === SuperAdminRole.ADMIN
          ? 'Company Profile'
          : CUSTOMERS.title,
      path:
        superAdminRole === SuperAdminRole.ADMIN
          ? `/admin/customers/${customerId}/${SCREEN_VALUES.CUSTOMERS}/${DEFAULT_RESELLER_ID}`
          : CUSTOMERS.path,
    });
  }

  if (showUsers) {
    innerMenu.push(USERS);
  }

  if (showAssignments) {
    innerMenu.push(ASSIGNMENTS);
  }

  if (showUsageReports) {
    innerMenu.push(USAGE_REPORT);
  }
  innerMenu.push(GRAPHICS);

  if (showIntegrations) {
    innerMenu.push(INTEGRATIONS);
  }

  if (showLoginAs) {
    innerMenu.push(LOGIN_AS(onLoginAsClick));
  }

  return {
    title: 'Admin',
    path: '/admin/customers',
    innerMenu,
  };
};
