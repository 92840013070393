import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';
import { SERVICE } from './types';

interface ServiceSubscribeResponse {
  success: boolean;
  message: string;
}

interface ServiceSubscriptionData {
  eventType: string;
  service: SERVICE;
}

export const deleteServiceSubscription = async ({
  eventType,
  service,
}: ServiceSubscriptionData): Promise<ServiceSubscribeResponse> => {
  const response = await EXPRESS_API.delete(`/${service}/subscriptions`, {
    data: { eventType },
  });
  return response.data;
};

export const useServicesUnsubsciptionMutation = (service: SERVICE) => {
  return useMutation(
    (data: Omit<ServiceSubscriptionData, 'service'>) =>
      deleteServiceSubscription({ service, ...data }),
    {
      onSuccess: () => {
        successToast({ title: 'Successfully unsubscribed from service.' });
      },
      onError: () => {
        errorToast({
          title: 'Failed to unsubscribe from service. Please try again later.',
        });
      },
    }
  );
};
