import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import {
  SectionTitle,
  Search,
  TableContextProvider,
  Table,
  TablePaginationNew,
  TablePaginationSizeNew,
  TableFooter,
  Dropdown,
} from 'lib/components';
import { useEffect, useState } from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import {
  connectELead,
  connectVinSolution,
  getCrmCustomers,
  getResellers,
  getVinsolutionsDealers,
  connectDealersocket,
} from 'lib/api/crmIntegrationsApi';
import { CRMIntegrationModal } from 'lib/components/modal/CRMIntegrationModal';
import { PendingConnectionsModal } from 'lib/components/modal/PendingConnectionsModal';
import { useAuth } from 'lib/context';
import { ChangeCRMIntegrationModal } from 'lib/components/modal/ChangeCRMIntegrationModal';
import Switch from '../../design/landingPageBuilder/components/Switch';
import { successToast } from 'lib/components/toasts/success';
import { MainContainer, MainWrapper } from '../index.styled';
import { MdSettings } from 'react-icons/md';
import { useGetAccountManagersQuery } from 'lib/api/crmIntegrations/useGetAccountManagersQuery';
import { CrmAccountManager } from 'lib/api/crmIntegrations/types';

const Container = styled.div<{ overflowX?: string }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  margin: 12px 0 0 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  margin-right: 13px;
`;

const SearchAndFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  gap: 25px;
  margin-top: 3%;
`;

const TableCell = styled.div<{ width?: number; whiteSpace?: string }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 6px;
  cursor: pointer;
  white-space: ${props => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;

  .arrow--right {
    padding: 2px 0 0 30px;
  }
`;

const SortCell = styled.div`
  padding-top: 2px;
  padding-left: 10px;

  .active {
    fill: black;
  }
`;

export const INTEGRATIONS_TYPE = {
  ALL: -1,
  NONE: 0,
  VINSOLUTIONS: 1,
  ELEAD: 2,
  DEALERSOCKET: 3,
};

interface Option {
  label: string;
  value: number;
}

interface VinsolutionsDealer {
  adminVinUserId: number;
  dealerId: number;
  value: number;
}

interface AccountManager {
  agentId: number;
  userId: number;
  notes: string;
}

interface Reseller {
  resellerName: string;
  resellerId: number;
}

export interface IntegrationCustomer {
  customerId: number;
  business: string;
  eLeadEnabled: number;
  dealerSocketId: number;
  dealerSocketFranchiseId: number;
  vinSolutionsDealer: VinsolutionsDealer | null;
  accountM: AccountManager | null;
  reseller: Reseller | null;
}

export interface VinsolutionDealer {
  dealerId: number;
  customerId: number;
  name: string;
}

export const integrations: Option[] = [
  {
    label: 'All Integrations',
    value: INTEGRATIONS_TYPE.ALL,
  },
  {
    label: 'None',
    value: INTEGRATIONS_TYPE.NONE,
  },
  {
    label: 'VinSolutions',
    value: INTEGRATIONS_TYPE.VINSOLUTIONS,
  },
  {
    label: 'Elead',
    value: INTEGRATIONS_TYPE.ELEAD,
  },
  {
    label: 'DealerSocket',
    value: INTEGRATIONS_TYPE.DEALERSOCKET,
  },
];

const fields = {
  BUSINESS: 'business',
  ID: 'customerId',
  RESELLER: 'reseller',
  ACCOUNT_MANAGER: 'accountM',
  INTEGRATION: 'integration',
  ACTIONS: 'actions',
};

const tableFields = [
  {
    label: 'Company',
    value: fields.BUSINESS,
  },
  {
    label: 'ID',
    value: fields.ID,
  },
  {
    label: 'Reseller',
    value: fields.RESELLER,
  },
  {
    label: 'Account Manager',
    value: fields.ACCOUNT_MANAGER,
  },
  {
    label: 'CRM Integration',
    value: fields.INTEGRATION,
  },
  {
    label: '',
    value: fields.ACTIONS,
  },
];

const Integrations = () => {
  const [onlyMyCustomers, setOnlyMyCustomers] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [integrationType, setIntegrationType] = useState(
    INTEGRATIONS_TYPE.VINSOLUTIONS
  );
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [resellerId, setResellerSelected] = useState('');
  const [integrationId, setIntegrationSelected] = useState(
    INTEGRATIONS_TYPE.ALL
  );
  const [sort, setSort] = useState('');
  const [customers, setCustomers] = useState<IntegrationCustomer[]>([]);
  const [accountManagers, setAccountManagers] = useState<CrmAccountManager[]>(
    []
  );
  const [accountManagerId, setAccountManagerId] = useState('');
  const [activeCustomer, setActiveCustomer] =
    useState<IntegrationCustomer | null>(null);
  const [pendingConnectionsModal, setPendingConnectionsModal] = useState(false);
  const [resellers, setResellers] = useState<
    { resellerId: string; resellerName: string }[]
  >([]);
  const [vinsolutionDealers, setVinsolutionDealers] = useState<
    VinsolutionDealer[]
  >([]);
  const [changeModal, setChangeModal] = useState(false);

  const { userData } = useAuth();
  const themes = useTheme();

  const { data: crmAccountManagers } = useGetAccountManagersQuery({
    enabled: true,
  });

  const onSearch = (query: string) => {
    setSearch(query);
    setPage(0);
  };
  const sortElement = (sortParam: string) => {
    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('DESC')
        ? sort.replace('DESC', 'ASC')
        : sort.replace('ASC', 'DESC');
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}-DESC`;
      setSort(newSort);
    }
  };

  const onIntegrationChange = async (
    customer: IntegrationCustomer,
    integrationType: number
  ) => {
    // disconnect integration
    if (integrationType === INTEGRATIONS_TYPE.NONE) {
      if (customer.eLeadEnabled === 1) {
        await connectELead({ eleadEnabled: 0 }, customer.customerId).catch(
          error => console.log(error)
        );
      } else if (customer.vinSolutionsDealer !== null) {
        await connectVinSolution(
          {
            dealerId: customer.vinSolutionsDealer.dealerId,
            vinSolutionsEnabled: false,
          },
          customer.customerId
        ).catch(error => console.log(error));
      } else if (customer.dealerSocketId && customer.dealerSocketFranchiseId) {
        await connectDealersocket(customer.customerId, false);
      }
      successToast({
        title: 'Integration removed!',
      });
      fetchCustomers();
      return;
    }
    setActiveCustomer(customer);
    setIntegrationType(integrationType);
    setChangeModal(true);
  };

  const changeCRMIntegration = () => {
    setChangeModal(false);
    setShowSettingsModal(true);
  };

  const fetchCustomers = async () => {
    setLoading(true);
    const { count, customers } = await getCrmCustomers({
      page,
      size,
      search,
      sort,
      accountManagerId,
      integrationId,
      resellerId,
    });
    setLoading(false);
    setCount(count);
    setCustomers(customers);
  };

  const loadVinsolutionDealers = async () => {
    const { dealers } = await getVinsolutionsDealers();
    setVinsolutionDealers(dealers);
  };

  const loadResellers = async () => {
    const { resellers } = await getResellers();
    const resellerOptions = resellers.map((reseller: Reseller) => {
      return {
        resellerId: reseller.resellerId,
        resellerName: reseller.resellerName?.trim()
          ? reseller.resellerName.trim() + '-' + reseller.resellerId
          : reseller.resellerId,
      };
    });

    setResellers([
      { resellerId: '', resellerName: 'All Resellers' },
      ...resellerOptions,
    ]);
  };

  useEffect(() => {
    loadResellers();
    loadVinsolutionDealers();
  }, []);

  useEffect(() => {
    setAccountManagers([
      { agentId: '', notes: 'All Account Managers' },
      ...(crmAccountManagers?.accountManagers || []),
    ] as CrmAccountManager[]);
  }, [crmAccountManagers]);

  useEffect(() => {
    fetchCustomers();
  }, [page, size, search, sort, accountManagerId, integrationId, resellerId]);

  const onAccountManagerChange = (agentId: number) => {
    setAccountManagerId(agentId.toString());
  };

  const onResellerChange = (resellerId: number) => {
    setResellerSelected(resellerId.toString());
  };

  const handleShowOnlyMyCustomersChange = () => {
    setOnlyMyCustomers(!onlyMyCustomers);
    if (!onlyMyCustomers) {
      let accountManagerId = userData.userId;
      accountManagers.forEach((manager: CrmAccountManager) => {
        if (userData.userId === manager.userId?.toString()) {
          accountManagerId = manager.agentId + '';
        }
      });
      setAccountManagerId(accountManagerId);
    } else {
      setAccountManagerId('');
    }
  };

  const handleCRMIntegrationModalSubmit = async () => {
    fetchCustomers();
    setShowSettingsModal(false);
  };

  const onPaginationChange = async ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    if (page !== newPage || size !== newSize) {
      setPage(newPage);
      setSize(newSize);
    }
  };

  const handleModalPendingClose = () => {
    setPendingConnectionsModal(false);
  };

  const rows = customers?.map(
    (customer: IntegrationCustomer, index: number) => ({
      key: index,
      columns: [
        ...tableFields.map(item => {
          return (
            <TableCell
              width={
                item.value === fields.BUSINESS || item.value === fields.RESELLER
                  ? 200
                  : 0
              }
              whiteSpace={
                item.value === fields.BUSINESS || item.value === fields.RESELLER
                  ? 'break-spaces'
                  : 'nowrap'
              }
            >
              {item.value === fields.INTEGRATION && (
                <Dropdown
                  options={integrations.filter(
                    el => el.value !== INTEGRATIONS_TYPE.ALL
                  )}
                  menuPortalTarget={document.body}
                  menuPlacement={'bottom'}
                  getOptionValue={(option: Option) => option.value.toString()}
                  getOptionLabel={(option: Option) => option.label}
                  value={integrations.find((o: Option) => {
                    if (customer.vinSolutionsDealer) {
                      return o.value === INTEGRATIONS_TYPE.VINSOLUTIONS;
                    }
                    if (customer.eLeadEnabled === 1) {
                      return o.value === INTEGRATIONS_TYPE.ELEAD;
                    }
                    if (
                      !!customer.dealerSocketId &&
                      !!customer.dealerSocketFranchiseId
                    ) {
                      return o.value === INTEGRATIONS_TYPE.DEALERSOCKET;
                    }
                    return o.value === INTEGRATIONS_TYPE.NONE;
                  })}
                  onChange={option =>
                    onIntegrationChange(customer, option.value)
                  }
                  placeholder='None'
                  width={220}
                  zIndexProp={1}
                />
              )}
              {item.value === fields.RESELLER &&
                (customer.reseller?.resellerName || '')}
              {item.value === fields.ACCOUNT_MANAGER &&
                (customer.accountM === null
                  ? 'Not assigned'
                  : customer.accountM.notes)}
              {item.value === fields.ID && customer.customerId}
              {item.value === fields.BUSINESS && customer.business}
              {item.value === fields.ACTIONS && (
                <div>
                  {(customer.vinSolutionsDealer || customer.dealerSocketId) && (
                    <MdSettings
                      size={22}
                      onClick={() => {
                        // if dealerId exists fetch users and fill table
                        setIntegrationType(
                          customer.vinSolutionsDealer
                            ? INTEGRATIONS_TYPE.VINSOLUTIONS
                            : INTEGRATIONS_TYPE.DEALERSOCKET
                        );
                        setActiveCustomer(customer);
                        setShowSettingsModal(true);
                      }}
                    />
                  )}
                </div>
              )}
            </TableCell>
          );
        }),
      ],
      onClick: () => {},
    })
  );

  const columnWidths = [250, 100, 250, 180, 180];

  return (
    <MainContainer>
      <MainWrapper>
        <Container>
          <Header>
            <SectionTitle>Integrations</SectionTitle>
            <ToggleContainer>
              <ToggleLabel>Show only my customers</ToggleLabel>
              <Switch
                id={'switch-only-my-c'}
                isOn={onlyMyCustomers}
                onColor={themes.colors.primary[100]}
                handleToggle={() => {
                  handleShowOnlyMyCustomersChange();
                }}
              />
            </ToggleContainer>
          </Header>
          {changeModal && (
            <ChangeCRMIntegrationModal
              handleModalClose={() => setChangeModal(false)}
              handleModalConfirm={changeCRMIntegration}
              newSelection={integrationType}
              customer={activeCustomer}
            />
          )}
          {showSettingsModal && activeCustomer && (
            <CRMIntegrationModal
              handleModalClose={() => setShowSettingsModal(false)}
              onSubmit={handleCRMIntegrationModalSubmit}
              customer={activeCustomer}
              vinsolutionDealers={vinsolutionDealers}
              integrationType={integrationType}
            />
          )}
          {pendingConnectionsModal && (
            <PendingConnectionsModal
              handleModalClose={handleModalPendingClose}
            />
          )}
          <SearchAndFilterContainer>
            <Search
              prevSearch={search}
              placeholder='Search by company name or ID…'
              onSearch={onSearch}
              width={'300px'}
            />
            <Dropdown
              placeholder={'All Integrations'}
              options={integrations.filter(
                el => el.value !== INTEGRATIONS_TYPE.NONE
              )}
              menuPortalTarget={document.body}
              menuPlacement={'bottom'}
              getOptionValue={(option: Option) => option.value.toString()}
              getOptionLabel={(option: Option) => option.label}
              value={integrations.find((o: Option) => {
                return o.value == integrationId;
              })}
              onChange={(option: Option) => {
                setIntegrationSelected(option.value);
              }}
              width={215}
              zIndexProp={1}
            />
            <Dropdown
              placeholder={'All Resellers'}
              options={resellers}
              menuPortalTarget={document.body}
              menuPlacement={'bottom'}
              getOptionValue={option => option.resellerId}
              getOptionLabel={option => option.resellerName}
              value={resellers.find(o => {
                return o.resellerId == resellerId;
              })}
              onChange={option => {
                onResellerChange(option.resellerId);
              }}
              width={215}
              zIndexProp={1}
            />
            <Dropdown
              placeholder={'All Account Managers'}
              options={accountManagers}
              menuPortalTarget={document.body}
              menuPlacement={'bottom'}
              getOptionValue={option => option.agentId}
              getOptionLabel={option => option.notes}
              value={accountManagers.find(o => {
                return o.agentId.toString() == accountManagerId;
              })}
              onChange={option => {
                onAccountManagerChange(option.agentId);
              }}
              width={215}
              zIndexProp={1}
            />
          </SearchAndFilterContainer>
          <div>
            {customers.length === 0 && !loading && <h4>Customers not found</h4>}
            {!loading && !!customers.length && (
              <div style={{ overflowX: 'auto', marginTop: '24px' }}>
                <TableContextProvider
                  total={count}
                  onChange={onPaginationChange}
                  initPage={page}
                  initSize={size}
                >
                  <Table
                    compact={true}
                    relative
                    headers={[
                      ...tableFields.map((item, index) => {
                        return (
                          <TableCell
                            width={columnWidths[index]}
                            onClick={() => sortElement(item.value)}
                          >
                            {item.label}
                            {item.label !== '' &&
                              item.value !== fields.INTEGRATION && (
                                <SortCell>
                                  <IoMdArrowRoundUp
                                    className={
                                      sort == `${item.value}-ASC`
                                        ? 'active'
                                        : ''
                                    }
                                  />
                                  <IoMdArrowRoundDown
                                    className={
                                      sort == `${item.value}-DESC`
                                        ? 'active'
                                        : ''
                                    }
                                  />
                                </SortCell>
                              )}
                          </TableCell>
                        );
                      }),
                    ]}
                    hoverable={false}
                    rows={rows}
                  />
                  <TableFooter>
                    <TablePaginationNew />
                    <TablePaginationSizeNew globalName='integrations_list' />
                  </TableFooter>
                </TableContextProvider>
              </div>
            )}
          </div>
        </Container>
      </MainWrapper>
    </MainContainer>
  );
};

export default Integrations;
