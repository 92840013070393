import { LoadingIndicator } from 'lib/components';
import {
  Content,
  ContentBody,
  ContentHeader,
  ContentHeaderWrap,
  ModalItem,
} from 'lib/components/modal/importModal/Components';
import * as React from 'react';

export const LoadingView = () => {
  return (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Importing users...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );
};
