import { NewModal } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';

import { Button } from 'react-covideo-common';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { UserInvite } from 'lib/api/superadmin/useUserInvitesQuery';
import { useUserCancelInvitesMutation } from 'lib/api/superadmin/useUserCancelInvitesMutation';

interface IProps {
  handleModalClose: () => void;
  users: UserInvite[];
  customerId: number | undefined;
  setSelectedUsers: React.Dispatch<React.SetStateAction<UserInvite[]>>;
}

export const MangeInvitesCancelModal = ({
  handleModalClose,
  users,
  customerId,
  setSelectedUsers,
}: IProps) => {
  const onSuccessCallback = () => {
    handleModalClose();
    setSelectedUsers([]);
  };

  const { mutateAsync, isLoading } = useUserCancelInvitesMutation({
    onSuccessCallback,
  });

  const onCancelInvitesHandler = () => {
    const invites = users.map(user => ({ userId: user.id }));
    if (customerId) {
      mutateAsync({ customerId, invites });
    }
  };

  const invitesWording = users.length > 1 ? 'Invites' : 'Invite';
  return (
    <NewModal
      headerText={`Cancel Scheduled ${invitesWording}`}
      closeModal={handleModalClose}
      style={{
        content: {
          minWidth: '592px',
          maxWidth: '592px',
        },
      }}
    >
      <Gap gap='32px' flexDirection='column' alignItems='flex-start'>
        <ParagraphSmall>
          You’re about to cancel scheduled {invitesWording.toLowerCase()} for{' '}
          <strong>
            {users.length > 1
              ? `${users.length} users.`
              : `${users[0]?.firstName} ${users[0]?.lastName}.`}
          </strong>
        </ParagraphSmall>
        <Gap justifyContent='flex-end' width='100%'>
          <Button
            onClick={handleModalClose}
            text={`No, Keep ${invitesWording}`}
            variant='secondary'
            disabled={isLoading}
          />
          <Button
            text={`Yes, Cancel ${invitesWording}`}
            variant='destructive'
            onClick={onCancelInvitesHandler}
            disabled={isLoading}
          />
        </Gap>
      </Gap>
    </NewModal>
  );
};
