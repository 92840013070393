import React from 'react';
import styled from 'styled-components/macro';
import Select from 'react-select';
import { TextInput } from 'lib/components';
import { theme } from 'lib/style';
import { VideoRequest } from 'lib/context';
import { VideoType } from 'lib/const';

const FormGroup = styled.div`
  margin-bottom: 16px;
`;
const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
  margin: 0 0 8px 0;
`;
type InputProps = {
  borderColor?: string;
};
const Input = styled(TextInput)<InputProps>`
  font-family: 'Work Sans';
  border: 1px solid ${props => props.borderColor || theme.palette.gray40};
`;
type Props = {
  updatedVideoRequest: VideoRequest;
  setUpdatedVideoRequest: (updatedVideoRequest: VideoRequest) => void;
  handleFormChange: () => void;
};
export const ReviewForm = ({
  updatedVideoRequest,
  setUpdatedVideoRequest,
  handleFormChange,
}: Props) => {
  const videoTypeOptions = Object.values(VideoType)
    .filter((videoType: string) => !!videoType)
    .map((videoType: string) => {
      return {
        value: videoType,
        label: videoType ? videoType : 'All video types',
      };
    });
  return (
    <>
      <FormGroup>
        <Label>Original Video Title</Label>
        <Input
          borderColor={theme.palette.gray20}
          background={theme.palette.gray20}
          disabled={true}
          value={`${updatedVideoRequest.video.title}`}
        />
      </FormGroup>
      <FormGroup>
        <Label>Recorded by</Label>
        <Input
          borderColor={theme.palette.gray20}
          background={theme.palette.gray20}
          disabled={true}
          value={`${updatedVideoRequest.user.firstName} ${updatedVideoRequest.user.lastName}`}
        />
      </FormGroup>
      <FormGroup>
        <Label>RO#</Label>
        <Input
          onChange={(e: any) => {
            setUpdatedVideoRequest({
              ...updatedVideoRequest,
              repairOrderNumber: e.target.value,
            });
            handleFormChange();
          }}
          value={updatedVideoRequest.repairOrderNumber}
        />
      </FormGroup>
      <FormGroup>
        <Label>Customer name</Label>
        <Input
          onChange={(e: any) => {
            setUpdatedVideoRequest({
              ...updatedVideoRequest,
              customerName: e.target.value,
            });
            handleFormChange();
          }}
          value={updatedVideoRequest.customerName}
        />
      </FormGroup>
      <FormGroup>
        <Label>Video Type</Label>
        <Select
          styles={{
            control: (base: any) => ({ ...base, height: '40px' }),
            indicatorSeparator: () => ({ display: 'none' }),
          }}
          options={videoTypeOptions}
          defaultValue={videoTypeOptions.find(o => {
            return o.value === updatedVideoRequest.videoType;
          })}
          onChange={(o: any) => {
            setUpdatedVideoRequest({
              ...updatedVideoRequest,
              videoType: o.value,
            });
            handleFormChange();
          }}
          isSearchable={false}
          placeholder={'Select Video Type...'}
        />
      </FormGroup>
    </>
  );
};
