import { EXPRESS_API } from 'configs/api/expressApi';
import { VideoRequest } from 'lib/context';
import { useMutation, useQueryClient } from 'react-query';
import { videoRequestKeys } from './videoRequestKeys';

type EditVideoRequestParams = {
  data: Partial<VideoRequest>;
  videoRequestId?: string | number;
};

const editVideoRequest = async ({
  videoRequestId,
  data,
}: EditVideoRequestParams) => {
  const response = await EXPRESS_API.put(
    `/video-requests/${videoRequestId}`,
    data
  );
  return response.data;
};

export const useEditVideoRequestMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(editVideoRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(videoRequestKeys.all());
    },
  });
};
