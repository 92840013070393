import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Preloader } from './sections';

export const Onboarding = () => {
  return (
    <>
      <DocumentHead title="Welcome" />
      <PageTemplate main={<Preloader />} />
    </>
  );
};
