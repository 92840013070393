import styled from 'styled-components/macro';
import { theme } from 'lib/style';

type LabelProps = {
  isAutomotive?: boolean;
};

export const Label = styled.label<LabelProps>`
  display: flex;
  height: ${props => (props.isAutomotive ? '16px' : '20px')};
  color: ${theme.palette.label};
  margin-bottom: 8px;
  font-size: ${props => (props.isAutomotive ? '12px' : '15px')};
  font-weight: ${props => (props.isAutomotive ? 'normal' : '500')};
`;
