import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const DefaultLabel = styled.label`
  font-size: 14px;
  letter-spacing: 0.4px;
  color: ${theme.palette.themeDark75Percent};
  font-weight: 500;
  line-height: 1.71;
`;
