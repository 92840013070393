import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { theme } from 'lib/style';
import Select from 'react-select';
import { maxBy } from 'lodash';
import { calculateGraphData, calculateRange } from 'lib/utils/functions';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  Area,
} from 'recharts';
import { SmsOptions, SmsOptionsMapping } from 'lib/const';
import { TooltipChart } from './TooltipChart';
import { LoadingIndicator, MainContainer } from 'lib/components';
import { useQuerySmsOverview } from 'lib/api/sms/useQuerySmsOverview';
import {
  SMSGraphData,
  SmsOverviewParams,
  UnansweredChats,
} from 'lib/api/sms/types';

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Filter = styled.div`
  float: right;
  display: flex;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    width: 500px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${theme.mediaQueryMaxWidth.md} {
    flex-direction: column;
  }
`;

const ContentLeft = styled.div`
  width: 49%;
  ${theme.mediaQueryMaxWidth.md} {
    width: 100%;
  }
`;

const ContentRight = styled.div`
  width: 49%;
  ${theme.mediaQueryMaxWidth.md} {
    width: 100%;
  }
`;

const GraphContainer = styled.div`
  width: 100%;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  height: auto;
  min-height: 400px;
  position: relative;
  .graph--header {
    padding: 20px;
    max-height: 100px;
    .wrapper {
      display: flex;
      .wrapper--div {
        padding: 20px 20px 5px 20px;
      }
      h3 {
        color: ${theme.palette.primaryDarkBlue};
      }
    }
  }
  .graph--body {
    width: 100%;
    height: 400px;
  }
  .filter {
    position: absolute;
    right: 30px;
  }
`;

const BarGraphContainer = styled.div`
  width: 100%;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  height: auto;
  min-height: 400px;
  margin: 50px 0 0 0;
  .graph--body {
    height: 400px;
    padding: 20px;
  }
  .bargraph--header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
`;

const RowHeadRight = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    border: 1px solid #f7f8fa;
    box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
    padding: 20px;
    line-height: 1.5;
    width: 40%;
  }
`;

const MessageContainer = styled.div`
  width: 100%;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  margin-top: 30px;
  .message--header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    h3 {
      color: ${theme.palette.primaryRedDanger};
    }
    span {
      color: ${theme.palette.primaryDarkBlue};
    }
    label,
    h3 {
      padding: 0 20px 0 20px;
    }
    .sub--header {
      color: #9297a2;
    }
    .see--more {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const MessageBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0 20px 0;
  .message--header {
    padding-top: 10px;
  }
  .danger {
    color: ${theme.palette.buttonDanger};
  }
  &.no--record {
    justify-content: center;
  }
`;

const Badge = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  background: ${props => props.color};
  border-radius: 5px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
`;

const DateLabel = styled.span`
  color: #9297a2;
`;

export const SmsOverviewLayout = () => {
  const history = useHistory();
  const [intervalRange, setIntervalRange] = React.useState(
    localStorage.getItem('SMS_FILTER') || SmsOptions[1].value
  );
  const [graphData, setGraphData] = React.useState<any>([]);
  const [graphDomain, setGraphDomain] = React.useState(0);
  const [barDomain, setBarDomain] = React.useState(0);
  const [graphCount, setGraphCount] = React.useState<any>({
    sentCount: 0,
    receivedCount: 0,
  });
  const themes = useTheme();

  const [dateRange, setDateRange] = useState<SmsOverviewParams>(
    calculateRange(intervalRange)
  );

  const getOverviewStats = async () => {
    await loadSmsOverview();
  };

  const {
    data: overview,
    isLoading: loading,
    refetch: loadSmsOverview,
  } = useQuerySmsOverview({
    from: dateRange.from,
    to: dateRange.to,
  });

  const { sent, received, maxDomain } = calculateGraphData(
    overview?.graph as SMSGraphData[],
    graphData,
    intervalRange
  );

  const calculateDifferenceInTime = (value: any) => {
    const dateCurrent = dayjs();
    const day: any = dateCurrent.diff(value, 'day', true);
    const hour: any = dateCurrent.diff(value, 'hour', true);
    if (parseInt(day, 10) > 0) {
      return { value: `${parseInt(day, 10)} day(s)`, type: 'day' };
    }
    return { value: `${parseInt(hour, 10)} hour(s)`, type: 'hour' };
  };

  const getGraphStructure = () => {
    let startDate = 6;
    const today = dayjs();
    const dateArray: any = [];
    const monthArray: any = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    switch (intervalRange) {
      case '1d':
        startDate = 0;
        break;
      case '7d':
        startDate = 6;
        break;
      case '30d':
        startDate = 29;
        break;
      case 'MTD':
        const monthStartDate = dayjs(
          `${dayjs().year()}-${dayjs().month() + 1}-01`
        );
        startDate = today.diff(monthStartDate, 'days');
        break;
      case 'YTD':
        const startDateOfYear = dayjs(new Date(dayjs().year(), 0, 1));
        startDate = today.diff(startDateOfYear, 'days');
        break;
    }
    for (let i = 0; i <= startDate && intervalRange != 'YTD'; i++) {
      const date = dayjs(
        new Date(new Date().getTime() - (startDate - i) * 24 * 60 * 60 * 1000)
      ).format(intervalRange == 'YTD' ? 'MMM' : 'MM/DD');
      dateArray.push({ dateDisplay: date, sentCount: 0, receivedCount: 0 });
    }
    for (let i = 0; i < monthArray.length && intervalRange == 'YTD'; i++) {
      dateArray.push({
        dateDisplay: monthArray[i],
        sentCount: 0,
        receivedCount: 0,
      });
    }
    return dateArray;
  };

  useEffect(() => {
    const newGraphData: any = getGraphStructure();
    setGraphDomain(maxDomain);
    if (overview?.mostActiveUsers?.length) {
      setBarDomain(
        parseInt(
          maxBy(overview?.mostActiveUsers, function (activeUser) {
            return parseInt(activeUser?.sentCount || '0');
          })?.sentCount || '0'
        )
      );
    }
    setGraphCount({ sentCount: sent, receivedCount: received });
    setGraphData(newGraphData);
  }, [overview?.graph]);

  useEffect(() => {
    setDateRange(calculateRange(intervalRange));
  }, [intervalRange]);

  useEffect(() => {
    getOverviewStats();
  }, [dateRange]);

  if (loading) {
    return <LoadingIndicator isLoading={loading} height='750px' />;
  }

  return (
    <>
      <MainContainer>
        <FiltersContainer>
          <Filter>
            <h3>Overview</h3>
          </Filter>
        </FiltersContainer>
        <Content>
          <ContentLeft>
            <GraphContainer>
              <div className='graph--header'>
                <div className='wrapper'>
                  <div className='wrapper--div'>
                    <Flex>
                      <Badge color={themes.colors.primary[100]} />
                      &nbsp; Received
                    </Flex>{' '}
                    <h3>{graphCount.receivedCount}</h3>
                  </div>
                  <div className='wrapper--div'>
                    <Flex>
                      <Badge color='#B6E0EC' />
                      &nbsp; Sent
                    </Flex>{' '}
                    <h3>{graphCount.sentCount}</h3>
                  </div>
                  <div className='filter'>
                    <Select
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          height: '40px',
                          width: '150px',
                        }),
                        indicatorSeparator: () => ({ display: 'none' }),
                      }}
                      options={SmsOptions}
                      menuPortalTarget={document.body}
                      menuPlacement={'bottom'}
                      isSearchable={false}
                      value={SmsOptions.find(o => {
                        return o.value === intervalRange;
                      })}
                      onChange={(option: any) => {
                        setIntervalRange(option.value);
                        localStorage.setItem('SMS_FILTER', option.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className='graph--body'>
                <ResponsiveContainer>
                  <ComposedChart
                    width={500}
                    height={300}
                    data={graphData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='dateDisplay' />
                    <YAxis allowDecimals={false} domain={[0, graphDomain]} />
                    <Tooltip
                      content={
                        <TooltipChart
                          headerKey='dateDisplay'
                          isAreaChart={true}
                        />
                      }
                    />
                    {/* <Legend /> */}
                    <Area
                      type='linear'
                      dataKey='receivedCount'
                      stroke={themes.colors.primary[100]}
                      strokeWidth={2}
                      fillOpacity={0}
                    />
                    <Area
                      type='linear'
                      dataKey='sentCount'
                      stroke='#B6E0EC'
                      strokeWidth={2}
                      fillOpacity={0}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </GraphContainer>
            <BarGraphContainer>
              <div className='bargraph--header'>
                <div>Most Active Users (by sent)</div>
                <DateLabel>{SmsOptionsMapping[intervalRange]}</DateLabel>
              </div>
              <br />
              <div className='graph--body'>
                <ResponsiveContainer>
                  {overview?.mostActiveUsers?.length ? (
                    <BarChart
                      width={150}
                      height={40}
                      data={overview?.mostActiveUsers}
                    >
                      <Bar
                        dataKey='sentCount'
                        fill='rgba(0, 27, 83, 0.8)'
                        radius={[10, 10, 0, 0]}
                      />
                      <XAxis dataKey='firstName' />
                      <YAxis allowDecimals={false} domain={[0, barDomain]} />
                      <Tooltip
                        cursor={{ fill: 'transparent' }}
                        content={
                          <TooltipChart
                            displayKey='sentCount'
                            headerKey='firstName'
                          />
                        }
                      />
                    </BarChart>
                  ) : (
                    <NoData>No record found</NoData>
                  )}
                </ResponsiveContainer>
              </div>
            </BarGraphContainer>
          </ContentLeft>
          <ContentRight>
            <RowHeadRight>
              <div>
                Active Chats
                <br />
                <strong
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/sms/messages')}
                >
                  {overview?.chatCount}
                </strong>
              </div>
              {/* <div>
                Avg. Speed of Answer
                <br />
                <strong>3:21</strong> min
              </div> */}
            </RowHeadRight>
            <MessageContainer>
              <div className='message--header' style={{ paddingTop: '20px' }}>
                <label>Unanswered messages</label>
                <label
                  className='see--more'
                  onClick={() => history.push('/sms/messages')}
                >
                  See more
                </label>
              </div>
              <div className='message--header'>
                <h3>{overview?.unansweredChats?.[1]}</h3>
              </div>
              <div className='message--header'>
                <label className='sub--header'>Customer</label>
                <label className='sub--header'>No response for</label>
              </div>
              <MessageBody
                className={`${
                  !overview?.unansweredChats?.[0].length ? 'no--record' : ''
                }`}
              >
                {overview?.unansweredChats?.[0].length ? (
                  overview?.unansweredChats?.[0].map(
                    (e: UnansweredChats, i: number) => {
                      const diffObj = calculateDifferenceInTime(e.createdAt);
                      return (
                        <div className='message--header'>
                          <label>
                            <span className='sub--header'>{i + 1}</span> &nbsp;
                            <span>
                              {e.contactFirstName || e.contactLastName
                                ? `${e.contactFirstName} ${e.contactLastName}`
                                : e.contactPhone}
                            </span>
                          </label>
                          <label
                            className={diffObj.type === 'day' ? 'danger' : ''}
                          >
                            {diffObj.value}
                          </label>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div>No record found.</div>
                )}
              </MessageBody>
            </MessageContainer>
          </ContentRight>
        </Content>
      </MainContainer>
    </>
  );
};
