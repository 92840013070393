import * as React from 'react';
import styled from 'styled-components/macro';

import { IoIosVolumeHigh, IoMdCheckmark } from 'react-icons/io';
import {
  MdClosedCaption,
  MdClosedCaptionDisabled,
  MdFullscreen,
  MdOutlineSpeed,
  MdPause,
  MdPlayArrow,
  MdVolumeOff,
} from 'react-icons/md';
import { ProgressBar } from './';
import { useAuth } from 'lib/context';
import { theme } from '../../../../../lib/style';
import '../../../../../../node_modules/flag-icons/css/flag-icons.min.css';
import { VideoChapterItemExtended } from 'lib/api/types';
import videoPlayerIcon from 'assets/icons/logo/videoPlayerIcon.svg';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
type DimensionProps = {
  small?: boolean;
};

const VideoControlsLayout = styled.div<DimensionProps>`
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  flex-direction: column;
  justify-content: space-between;
`;

const BottomControlsLayout = styled.div<DimensionProps>`
  margin-top: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 14px;
`;

const LeftControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlayButton = styled.div<DimensionProps>`
  width: ${props => `${props.small ? '16px' : '20px'}`};
  height: ${props => `${props.small ? '16px' : '20px'}`};
  cursor: pointer;
  margin-right: ${props => `${props.small ? '6px' : '8px'}`};
  transition: all 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
`;

const VolRange = styled.input<DimensionProps>`
  /* RESET */
  width: 74px;
  opacity: 1;
  visibility: visible;
  transition: all 0.4s;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */

  &:focus {
    outline: none; /* Removes the blue border. We should probably do some kind of focus styling for accessibility reasons though. */
  }
  &::-ms-track {
    width: 96px;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
    height: ${props => `${props.small ? '2px' : '2px'}`};
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: ${props => `${props.small ? '2px' : '2px'}`};
    cursor: pointer;
    opacity: 0.75;
    background: ${props => props.color};
    border-radius: ${props => `${props.small ? '2px' : '2px'}`};
    border: 0.2px solid transparent;
  }

  &::-webkit-slider-thumb {
    border: 1px solid transparent;
    height: ${props => `${props.small ? '6px' : '6px'}`};
    width: ${props => `${props.small ? '6px' : '6px'}`};
    border-radius: 50%;
    background: ${props => props.color};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -3px;
  }
`;

const VolControls = styled.div`
  display: flex;
  width: 100px;
  align-items: center;
  overflow: hidden;
  transition: all 0.4s;
  margin-right: 8px;
`;

const VolumeIconWrapper = styled.div<DimensionProps>`
  height: ${props => `${props.small ? '15px' : '20px'}`};
  width: ${props => `${props.small ? '15px' : '20px'}`};
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
  }
`;

const TimeDisplay = styled.div<DropDownProps>`
  margin-right: 16px;
  color: ${props => props.color};

  ${props => {
    if (props.width && props.width < 330) {
      return `font-size: 11px;`;
    }
  }}
`;

interface ButtonProps {
  active?: boolean;
  color?: string;
}

const ControlsButton = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: 3px;
  border-radius: 5px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  ${props => {
    if (props.active && props.color === '#000000') {
      return `
    background-color: white;
    &:hover {
      background: white;
    }
    path {
      fill: ${props.color};
    }`;
    }
  }}
`;

const Logo = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding-left: 16px;
`;

const Img = styled.img`
  width: 100%;
  fill: white;
`;

interface DropDownProps {
  width?: number;
}

const PlaybackSpeed = styled.div<DropDownProps>`
  z-index: 200;
  overflow-y: auto;
  margin-top: -340px;
  margin-left: -140px;
  min-width: 110px;
  max-width: 160px;
  max-height: 292px;
  cursor: pointer;
  position: absolute;
  background-color: white;
  border: 1px solid #f7f8fa;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(29, 30, 36, 0.06);
  border-radius: 5px;
  color: ${theme.palette.primaryDarkBlue};
  display: flex;
  flex-direction: column;
  ${props => {
    if (props.width && props.width < 330) {
      return `max-width: 120px;
              max-height: 142px;
              margin-top: -198px;
              margin-left: -105px;`;
    }
    if (props.width && props.width < 500) {
      return `max-width: 150px;
              max-height: 192px;
              margin-top: -250px;
              margin-left: -135px;`;
    }
  }}

  p {
    padding: 8px 24px;
    height: 24px;
    color: #9297a2;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    ${props => {
      if (props.width && props.width < 330) {
        return `font-size: 9px;
                line-height: 12px;
                padding: 8px 16px;`;
      }
      if (props.width && props.width < 500) {
        return `font-size: 11px;
                line-height: 15px;`;
      }
    }}
  }

  div {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #272a32;
    padding: 8px 24px;
    height: 24px;
    ${props => {
      if (props.width && props.width < 330) {
        return `font-size: 10px;
                line-height: 13px;
                height: 13px;`;
      }
      if (props.width && props.width < 500) {
        return `font-size: 12px;
                line-height: 16px;
                height: 18px;`;
      }
    }}
    path {
      fill: #272a32;
    }

    &:hover {
      background-color: ${theme.palette.lightgray40};
    }
  }
`;

const Captions = styled.div<DropDownProps>`
  z-index: 200;
  overflow-y: auto;
  margin-top: -350px;
  margin-left: -140px;
  min-width: 200px;
  max-width: 220px;
  max-height: 292px;
  cursor: pointer;
  position: absolute;
  background-color: white;
  border: 1px solid #f7f8fa;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(29, 30, 36, 0.06);
  border-radius: 5px;
  color: ${theme.palette.primaryDarkBlue};
  display: flex;
  flex-direction: column;
  ${props => {
    if (props.width && props.width < 330) {
      return `max-width: 140px;
              max-height: 142px;
              margin-top: -198px;
              margin-left: -85px;`;
    }
    if (props.width && props.width < 500) {
      return `max-width: 180px;
              max-height: 192px;
              margin-top: -250px;
              margin-left: -120px;`;
    }
  }}
  p {
    padding: 8px 24px;
    height: 24px;
    color: #9297a2;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    ${props => {
      if (props.width && props.width < 330) {
        return `font-size: 9px;
                    line-height: 12px;`;
      }
      if (props.width && props.width < 500) {
        return `font-size: 11px;
                    line-height: 15px;`;
      }
    }}
  }

  div {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #272a32;
    padding: 8px 24px;
    height: 24px;
    ${props => {
      if (props.width && props.width < 330) {
        return `font-size: 10px;
                    line-height: 13px;
                    height: 13px;`;
      }
      if (props.width && props.width < 500) {
        return `font-size: 12px;
                    line-height: 16px;
                    height: 18px;`;
      }
    }}
    path {
      fill: #272a32;
    }

    &:hover {
      background-color: ${theme.palette.lightgray40};
    }
  }
`;

const PLAYBACK_SPEEDS = [
  {
    value: 2,
    label: '2.0x',
  },
  {
    value: 1.5,
    label: '1.5x',
  },
  {
    value: 1.25,
    label: '1.25x',
  },
  {
    value: 1,
    label: '1.0x',
  },
  {
    value: 0.75,
    label: '0.75x',
  },
  {
    value: 0.5,
    label: '0.5x',
  },
];

const CAPTIONS = [
  {
    value: 'off',
    label: 'Off',
  },
  {
    value: 'auto',
    label: 'Auto',
  },
];

const TRANSLATIONS = [
  // { label: 'Afrikaans', value: 'af' },
  // { label: 'Albanian', value: 'sq' },
  // { label: 'Amharic', value: 'am' },
  { label: 'Arabic', value: 'ar', flag: 'sa' },
  // { label: 'Armenian', value: 'hy' },
  // { label: 'Azerbaijani', value: 'az' },
  // { label: 'Bengali', value: 'bn' },
  // { label: 'Bosnian', value: 'bs' },
  // { label: 'Bulgarian', value: 'bg' },
  // { label: 'Catalan', value: 'ca' },
  { label: 'Chinese', value: 'zh', flag: 'cn' },
  // { label: 'Chinese (Traditional)', value: 'zh-TW' },
  { label: 'Croatian', value: 'hr', flag: 'hr' },
  // { label: 'Czech', value: 'cs' },
  // { label: 'Danish', value: 'da' },
  // { label: 'Dari', value: 'fa-AF' },
  { label: 'Dutch', value: 'nl', flag: 'nl' },
  { label: 'English', value: 'en', flag: 'gb' },
  // { label: 'Estonian', value: 'et' },
  // { label: 'Farsi (Persian)', value: 'fa' },
  // { label: 'Filipino, Tagalog', value: 'tl' },
  // { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr', flag: 'fr' },
  // { label: 'French (Canada)', value: 'fr-CA' },
  // { label: 'Georgian', value: 'ka' },
  { label: 'German', value: 'de', flag: 'de' },
  // { label: 'Greek', value: 'el' },
  // { label: 'Gujarati', value: 'gu' },
  // { label: 'Haitian Creole', value: 'ht' },
  // { label: 'Hausa', value: 'ha' },
  // { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi', flag: 'in' },
  // { label: 'Hungarian', value: 'hu' },
  // { label: 'Icelandic', value: 'is' },
  { label: 'Indonesian', value: 'id', flag: 'id' },
  // { label: 'Irish', value: 'ga' },
  { label: 'Italian', value: 'it', flag: 'it' },
  { label: 'Japanese', value: 'ja', flag: 'jp' },
  // { label: 'Kannada', value: 'kn' },
  // { label: 'Kazakh', value: 'kk' },
  // { label: 'Korean', value: 'ko' },
  // { label: 'Latvian', value: 'lv' },
  // { label: 'Lithuanian', value: 'lt' },
  // { label: 'Macedonian', value: 'mk' },
  { label: 'Malay', value: 'ms', flag: 'my' },
  // { label: 'Malayalam', value: 'ml' },
  // { label: 'Maltese', value: 'mt' },
  // { label: 'Marathi', value: 'mr' },
  // { label: 'Mongolian', value: 'mn' },
  // { label: 'Norwegian', value: 'no' },
  // { label: 'Pashto', value: 'ps' },
  { label: 'Polish', value: 'pl', flag: 'pl' },
  { label: 'Portuguese', value: 'pt', flag: 'pt' },
  // { label: 'Portuguese (Portugal)', value: 'pt-PT' },
  // { label: 'Punjabi', value: 'pa' },
  // { label: 'Romanian', value: 'ro' },
  { label: 'Russian', value: 'ru', flag: 'ru' },
  // { label: 'Serbian', value: 'sr' },
  // { label: 'Sinhala', value: 'si' },
  // { label: 'Slovak', value: 'sk' },
  // { label: 'Slovenian', value: 'sl' },
  // { label: 'Somali', value: 'so' },
  { label: 'Spanish', value: 'es', flag: 'es' },
  // { label: 'Spanish (Mexico)', value: 'es-MX' },
  // { label: 'Swahili', value: 'sw' },
  { label: 'Swedish', value: 'sv', flag: 'se' },
  // { label: 'Tamil', value: 'ta' },
  // { label: 'Telugu', value: 'te' },
  { label: 'Tagalog/Filipino', value: 'tl', flag: 'ph' },
  { label: 'Thai', value: 'th', flag: 'th' },
  { label: 'Turkish', value: 'tr', flag: 'tr' },
  // { label: 'Ukrainian', value: 'uk' },
  // { label: 'Urdu', value: 'ur' },
  // { label: 'Uzbek', value: 'uz' },
  { label: 'Vietnamese', value: 'vi', flag: 'vn' },
  // { label: 'Welsh', value: 'cy' },
];

type Props = {
  isVideoPaused?: boolean;
  togglePlay?: () => void;
  isVideoMuted?: boolean;
  handleMute?: () => void;
  handleFullScreen?: () => void;
  setPlayBack?: (rate: number) => void;
  setTranslate?: (rate: string) => void;
  captionsEnabled?: boolean;
  captions?: string;
  duration?: number;
  currentTime?: number;
  handleVolumeControl: (
    e: any,
    progressRef: React.RefObject<HTMLInputElement>
  ) => void;
  volumeRange: number;
  playerIconsColor: string;
  small?: boolean;
  isVisible?: boolean;
  scrub: (
    e: any,
    progressRef: React.RefObject<HTMLProgressElement>,
    offset?: number,
    duration?: number
  ) => void;
  playerBackgroundColor: string;
  videoRef?: any;
  videoChaptersData?: VideoChapterItemExtended[];
};

export const VideoControls = (props: Props) => {
  const {
    isVideoPaused,
    isVideoMuted,
    togglePlay,
    handleMute,
    handleFullScreen,
    duration = 0,
    currentTime = 0,
    handleVolumeControl,
    volumeRange,
    playerIconsColor,
    playerBackgroundColor,
    setPlayBack,
    setTranslate,
    captionsEnabled,
    captions,
    isVisible,
    small,
    videoRef,
    scrub,
    videoChaptersData,
  } = props;
  const [showPlaybackSpeed, setShowPlaybackSpeed] = React.useState(false);
  const [showCaptions, setShowCaptions] = React.useState(false);
  const [playbackSpeed, setPlaybackSpeed] = React.useState(1);
  const [captionOption, setCaptionOption] = React.useState(
    !!captions ? 'auto' : 'off'
  );

  const { whitelabel } = useAuth();

  const formatTimeToString = (time: number) => {
    let timeMinutes = Math.floor(time / 60);
    let timeMinutesString = timeMinutes.toString();
    let timeSeconds = `${Math.floor(time - timeMinutes * 60)}`;
    if (timeMinutesString.length === 1) timeMinutesString = `0${timeMinutes}`;
    if (timeSeconds.length === 1) timeSeconds = `0${timeSeconds}`;
    return `${timeMinutes}:${timeSeconds}`;
  };

  const currentTimeString = formatTimeToString(currentTime);
  const volRangeRef = React.createRef<HTMLInputElement>();
  const logoSrc =
    whitelabel.name === WHITELABEL_NAME.COVIDEO
      ? videoPlayerIcon
      : 'https://' +
        whitelabel.domainV2 +
        whitelabel.contentPath +
        '/images/square-icon-dark.svg';
  const volumeMultiplied = volumeRange * 100;

  React.useEffect(() => {
    if (!isVisible) {
      setShowCaptions(false);
      setShowPlaybackSpeed(false);
    }
  }, [isVisible]);

  React.useEffect(() => {
    if (captionOption === 'auto' || captionOption === 'off') {
      setCaptionOption(!!captions ? 'auto' : 'off');
    }
  }, [captionOption, captions]);

  const setPlaybackRate = (rate: number) => {
    setPlayBack && setPlayBack(rate);
    setPlaybackSpeed(rate);
  };

  const setCaption = (c: string) => {
    setTranslate && setTranslate(c);
    setCaptionOption(c);
  };

  const openPlaybackSpeed = () => {
    setShowPlaybackSpeed(!showPlaybackSpeed);
    setShowCaptions(false);
  };

  const openCaptions = () => {
    setShowCaptions(!showCaptions);
    setShowPlaybackSpeed(false);
  };

  return (
    <VideoControlsLayout small={small}>
      <ProgressBar
        duration={duration}
        currentTime={currentTime}
        scrub={scrub}
        color={playerIconsColor}
        small={small}
        videoChaptersData={videoChaptersData}
      />
      <BottomControlsLayout>
        <LeftControls>
          <PlayButton small={small} onClick={togglePlay}>
            {isVideoPaused ? (
              <MdPlayArrow color={playerIconsColor} size={18} />
            ) : (
              <MdPause size={18} color={playerIconsColor} />
            )}
          </PlayButton>
          {!small && (
            <>
              <VolControls>
                <VolumeIconWrapper small={small} onClick={handleMute}>
                  {isVideoMuted ? (
                    <MdVolumeOff size={18} />
                  ) : (
                    <IoIosVolumeHigh
                      style={{
                        color: playerIconsColor,
                        fontSize: 20,
                      }}
                    />
                  )}
                </VolumeIconWrapper>
                <VolRange
                  ref={volRangeRef}
                  aria-label='Volume Range'
                  type='range'
                  min='0'
                  value={volumeMultiplied.toString()}
                  onChange={event => handleVolumeControl(event, volRangeRef)}
                  max='100'
                  step='1'
                  color={playerIconsColor}
                  small={small}
                />
              </VolControls>
              <TimeDisplay
                width={videoRef.current && videoRef.current.clientWidth}
                color={playerIconsColor}
              >
                {currentTimeString}
                {' / '}
                {formatTimeToString(duration)}
              </TimeDisplay>
            </>
          )}
        </LeftControls>
        {!small && (
          <RightControls>
            <ControlsButton
              active={showPlaybackSpeed}
              color={playerBackgroundColor}
              onClick={openPlaybackSpeed}
            >
              <MdOutlineSpeed size={18} color={playerIconsColor} />
            </ControlsButton>
            {showPlaybackSpeed && (
              <PlaybackSpeed
                width={videoRef.current && videoRef.current.clientWidth}
              >
                <p>Playback Speed</p>
                {PLAYBACK_SPEEDS.map(ps => (
                  <div key={ps.value} onClick={() => setPlaybackRate(ps.value)}>
                    <span>{ps.label}</span>{' '}
                    {playbackSpeed == ps.value && (
                      <IoMdCheckmark
                        size={
                          videoRef.current && videoRef.current.clientWidth < 500
                            ? '16px'
                            : '20px'
                        }
                      />
                    )}
                  </div>
                ))}
              </PlaybackSpeed>
            )}
            {captionsEnabled && (
              <ControlsButton
                active={showCaptions}
                color={playerBackgroundColor}
                onClick={openCaptions}
              >
                {captionOption === 'off' ? (
                  <MdClosedCaptionDisabled color={playerIconsColor} size={22} />
                ) : (
                  <MdClosedCaption color={playerIconsColor} size={22} />
                )}
              </ControlsButton>
            )}
            {showCaptions && (
              <Captions
                width={videoRef.current && videoRef.current.clientWidth}
              >
                <p>Closed Captions</p>
                {CAPTIONS.map(c => (
                  <div key={c.value} onClick={() => setCaption(c.value)}>
                    <span>{c.label}</span>{' '}
                    {captionOption == c.value && (
                      <IoMdCheckmark
                        size={
                          videoRef.current && videoRef.current.clientWidth < 500
                            ? '16px'
                            : '20px'
                        }
                      />
                    )}
                  </div>
                ))}
                <p>Translate</p>
                {TRANSLATIONS.map(t => (
                  <div
                    style={{ justifyContent: 'space-between' }}
                    key={t.value}
                    onClick={() => setCaption(t.value)}
                  >
                    <span>
                      <span
                        style={{ marginRight: '8px' }}
                        className={`fi fi-${t.flag}`}
                      ></span>
                      <span>{t.label}</span>{' '}
                    </span>
                    {captionOption == t.value && (
                      <IoMdCheckmark
                        size={
                          videoRef.current && videoRef.current.clientWidth < 500
                            ? '16px'
                            : '20px'
                        }
                      />
                    )}
                  </div>
                ))}
              </Captions>
            )}
            <ControlsButton onClick={handleFullScreen}>
              <MdFullscreen
                style={{
                  color: playerIconsColor,
                  fontSize: 20,
                }}
              />
            </ControlsButton>
            <Logo>
              <Img aria-label='Logo' src={logoSrc} />
            </Logo>
          </RightControls>
        )}
      </BottomControlsLayout>
    </VideoControlsLayout>
  );
};
