import * as React from 'react';
import { useMemo } from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { PreLoader } from './PreLoader';

const Wrapper = () => {
  return useMemo(() => <PreLoader />, []);
};

export const VideoAttributes = () => (
  <>
    <DocumentHead title='Video Attributes' />
    <PageTemplate main={<Wrapper />} />
  </>
);
