import React from 'react';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import { Dropdown } from 'lib/components';
import { handleScriptSpeedUsingScriptSize } from 'lib/const';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { useTeleprompter } from 'lib/context/teleprompter/provider';

export const RecordingTeleprompterActions = () => {
  const {
    teleprompterSettings,
    isUsingTeleprompter,
    toggleTeleprompterPlaying,
    isTeleprompterPlaying,
    onDropdownTeleprompterSpeedChange,
  } = useTeleprompter();

  if (!isUsingTeleprompter) {
    return <></>;
  }

  return (
    <>
      <Gap m='0 0 0 auto'>
        <Dropdown
          placeholder={'Set teleprompter speed'}
          value={teleprompterSettings?.speed}
          onChange={newValue => onDropdownTeleprompterSpeedChange(newValue)}
          options={handleScriptSpeedUsingScriptSize(
            teleprompterSettings?.scriptSize
          )}
          height={40}
          zIndexProp={9999}
          menuZIndexProp={9}
          extendStyles={{ container: { width: 180 } }}
        />
        <Button
          text={`${isTeleprompterPlaying ? 'Pause' : 'Play'} Teleprompter`}
          icon={
            isTeleprompterPlaying ? (
              <MdPause size={20} />
            ) : (
              <MdPlayArrow size={20} />
            )
          }
          onClick={toggleTeleprompterPlaying}
        />
      </Gap>
    </>
  );
};
