import React from 'react';
import styled from 'styled-components/macro';
import { Gap } from 'lib/components/styles/layout';
import { DailyHours } from './DailyHours';

const Flex = styled.div`
  display: flex;
  gap: 132px;
`;

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  max-width: 200px;
  line-height: 24px;
`;

export type DayValues =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';

export const workingDays: {
  value: DayValues;
  label: string;
}[] = [
  { value: 'sunday', label: 'Sunday' },
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
];

export const WorkingHours = () => {
  return (
    <Flex>
      <SectionTitle>Working Hours</SectionTitle>
      <Gap style={{ width: 811 }}>
        {workingDays.map(day => (
          <DailyHours key={day.value} day={day} />
        ))}
      </Gap>
    </Flex>
  );
};
