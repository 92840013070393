import {
  FieldLabel,
  FormProps,
  getDropdownField,
  getNumberInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from 'app/pages/admin/components/AdminFormComponents';
import {
  SmsPackageOptions,
  SmsPackageOptionsDefault,
} from 'lib/const/SmsPackageOptions';
import React from 'react';

export const SmsLimitsSection = (props: FormProps) => {
  const { isSubmitting, values, setFieldValue } = props;

  return (
    <Section style={{ paddingTop: '0.5rem' }}>
      <SectionTitle>Limits</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Free SMS per month</FieldLabel>
          {getNumberInputField('freeSmsPerMonth', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>Paid SMS per month</FieldLabel>
          {getDropdownField(
            'paidSmsPerMonth',
            'Select a package',
            SmsPackageOptions,
            values.paidSmsPerMonth ?? SmsPackageOptionsDefault,
            option => setFieldValue('paidSmsPerMonth', option.value)
          )}
        </Row>
      </RowWrapper>
    </Section>
  );
};
