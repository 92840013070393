import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { droplrKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const removeBoard = async (boardId: string) => {
  return (await EXPRESS_API.delete(`/board/${boardId}`)).data;
};

type Params = {
  userId: number;
  customerId: number;
};

export const useRemoveBoard = (params: Params) => {
  const queryClient = useQueryClient();
  const { showError } = useToastError();
  return useMutation(
    ({ boardId }: { boardId: string }) => removeBoard(boardId),
    {
      onError: (err: AxiosError) => {
        showError(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(droplrKeys.boards_list(params));
        successToast({
          title: 'You successfully moved files to recently deleted bin',
        });
      },
    }
  );
};
