import * as React from 'react';
import styled from 'styled-components/macro';
import { Field, FieldProps } from 'formik';
import { SelectInput } from 'lib/components';
import { theme } from '../../../../../../lib/style';
import { useAuth } from 'lib/context';
import { CaptionsNotEnabled } from 'app/pages/management/components/CaptionsNotEnabled';

interface FormProps {
  isSubmitting?: boolean;
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  padding: 0.5rem 0;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FieldLabel = styled.label`
  width: 120px;
  margin-right: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;

const SectionTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
  width: 100%;
  max-width: 200px;
  line-height: 24px;
`;

const RowWrapper = styled.div`
  width: 100%;
  div:first-child,
  p:first-child {
    padding-top: 0px;
  }
`;

export const AddOnsForm = (props: FormProps) => {
  const { isSubmitting } = props;

  const { userData } = useAuth();

  return (
    <>
      {/* Captions */}
      <div style={{ display: 'flex', maxWidth: 700 }}>
        <SectionTitle>Captions</SectionTitle>
        {!!userData.transcriptionAccess && (
          <RowWrapper>
            {/* Captions */}
            <Row>
              <FieldLabel>Captions</FieldLabel>
              <Field name='captions'>
                {({ field }: FieldProps) => {
                  const { value, ...rest } = field;
                  return (
                    <SelectInput
                      {...rest}
                      defaultValue={value}
                      disabled={
                        isSubmitting ||
                        (!userData.transcriptionAccess &&
                          userData.isCompanyAdmin)
                      }
                    >
                      <option value={'0'}>
                        Let me choose which videos to transcribe (default)
                      </option>
                      <option value={'1'}>
                        Automatically transcribe every new video
                      </option>
                    </SelectInput>
                  );
                }}
              </Field>
            </Row>

            {/* Caption Visibility */}
            <Row>
              <FieldLabel>Caption Visibility</FieldLabel>
              <Field name='captionVisibility'>
                {({ field }: FieldProps) => {
                  const { value, ...rest } = field;
                  return (
                    <SelectInput
                      {...rest}
                      defaultValue={value}
                      disabled={
                        isSubmitting ||
                        (!userData.transcriptionAccess &&
                          userData.isCompanyAdmin)
                      }
                    >
                      <option value={'0'}>
                        Let me verify captions before showing on videos
                        (default)
                      </option>
                      <option value={'1'}>
                        Automatically show captions on all videos
                      </option>
                    </SelectInput>
                  );
                }}
              </Field>
            </Row>
          </RowWrapper>
        )}
        {!userData.transcriptionAccess && <CaptionsNotEnabled />}
      </div>
    </>
  );
};
