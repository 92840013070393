import React from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from 'lib/context';
import { ListDrops } from '../../components';
import { LoadingIndicator } from 'lib/components';
import { NotFound } from 'app/pages/notFound/NotFound';
import { useGetBoard } from 'lib/api/droplr/getBoard';
import { DROPTYPE_OPTIONS } from 'lib/const/Droplr';

const Container = styled.div`
  margin: 0px 0px 0px 200px;
  padding: 80px 120px;
  min-height: 500px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  font-size: 15px;
  color: #001b53cc;
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const LinkText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

export const Details = () => {
  const { boardId, currentTab } = useParams<{
    boardId: '';
    currentTab: DROPTYPE_OPTIONS.OWNED;
  }>();
  const { userData } = useAuth();
  const history = useHistory();
  const { isLoading, data: selectedBoard } = useGetBoard({
    boardId,
    userId: Number(userData.id),
    customerId: Number(userData.customerId),
  });

  const goToBoards = () => history.push('/boards');

  if (!userData.droplrAccess) {
    history.push('/profile/add-ons');
    return null;
  }

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  if (!selectedBoard) {
    return <NotFound />;
  }

  return (
    <Container>
      <LinkWrapper>
        <LinkBtn onClick={goToBoards}>Boards</LinkBtn>
        <MdKeyboardArrowRight size={24} />
        <LinkText>{selectedBoard.name}</LinkText>
      </LinkWrapper>
      <ListDrops boardId={boardId} currentTab={currentTab} />
    </Container>
  );
};
