import { EXPRESS_API } from 'configs/api/expressApi';
import { QueryListParams, VideoAttribute } from '../types';
import { useQuery } from 'react-query';
import { videoAttributesKeys } from './videoAttributesKeys';
import { useAuth } from 'lib/context';
import { checkIfFreemium } from 'lib/utils/functions';

export interface IAttributesResponse {
  count: number;
  attributes: VideoAttribute[];
}

export const getAttributes = async ({
  page = 0,
  size = 10,
  filterByDepartment = false,
}: QueryListParams): Promise<IAttributesResponse> => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    filterByDepartment,
  };

  const { data } = await EXPRESS_API.get('/video-attributes', {
    params,
  });

  const { videoAttributes = [], count = 0 } = data || {};

  return { count, attributes: videoAttributes };
};

export const useGetAllVideoAttributesQuery = (params: QueryListParams) => {
  const { userData } = useAuth();
  const isFreemium = checkIfFreemium(userData);
  return useQuery(
    videoAttributesKeys.all(params),
    () => getAttributes(params),
    {
      enabled: !isFreemium,
    }
  );
};
