import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { LeadItem } from 'lib/context/types';

export const createLead = async (data: Partial<LeadItem>) => {
  const response = await EXPRESS_API.post(`/leads`, data);
  return response.data;
};

export const useAddLeadMutation = () => {
  return useMutation(createLead, {
    onSuccess: () => {
      successToast({
        title: 'You created a new customer!',
      });
    },
    onError: () => {
      errorToast({ title: 'An error occurred while creating lead.' });
    },
  });
};
