import React, { useState } from 'react';
import { NewModal } from './NewModal';
import { loginAsForSuperAdmin } from 'lib/api';
import { SuperAdminUser } from 'lib/context';
import SuperAdminSearchDropdown from 'app/pages/admin/components/SuperAdminSearchDropdown';
import { SelectInputContainer } from 'app/pages/admin/organizations/styles';
import { LoginAsModal } from 'app/pages/reports/components';
import { errorToast } from '../toasts/error';

type Props = {
  handleClose: () => void;
};

const ModalSuperAdminLoginAs = ({ handleClose }: Props) => {
  const [openSignoutModal, setOpenSignoutModal] = useState(false);
  const [loginAsLoading, setLoginAsLoading] = useState(false);

  const onSelectUser = async (user: SuperAdminUser) => {
    if (!user.username) {
      return;
    }
    setLoginAsLoading(true);
    try {
      await loginAsForSuperAdmin({ userId: user.id, username: user.username });
      setOpenSignoutModal(true);
    } catch (error) {
      errorToast({ title: 'Error while logging in. try againa later.' });
      console.error('Failed to login as', error);
    }
    setLoginAsLoading(false);
  };

  return openSignoutModal ? (
    <LoginAsModal
      handleModalClose={() => {
        setOpenSignoutModal(false);
        window.location.reload();
      }}
    />
  ) : (
    <NewModal
      closeModal={handleClose}
      headerText='Login As User'
      style={{
        content: {
          height: '300px',
          minWidth: '600px',
        },
      }}
    >
      Search for a user and click to login as them.
      <SelectInputContainer style={{ marginTop: '10px' }}>
        <SuperAdminSearchDropdown
          onSelectUser={onSelectUser}
          style={{
            scrollStyle: {
              height: '200px',
            },
            searchResultStyle: {
              maxHeight: '200px',
              maxWidth: '600px',
              width: '100%',
            },
            endMessageStyle: {
              marginTop: '90px',
            },
          }}
          loading={loginAsLoading}
        />
      </SelectInputContainer>
    </NewModal>
  );
};

export default ModalSuperAdminLoginAs;
