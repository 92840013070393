import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdArrowDropDown } from 'react-icons/md';
import { HiChevronLeft } from 'react-icons/hi';

interface Tab {
  title: any;
  component: React.ReactNode;
  icon?: React.ReactNode;
  iconActive?: React.ReactNode;
  disabled?: boolean;
  notClickable?: boolean;
  onClick?: Function;
}

type TabProps = {
  isActive: boolean;
  borderStyleOnActive?: Function;
  disabled?: boolean;
  notClickable?: boolean;
  tabsLength: number;
};

type Props = {
  tabs: Array<Tab>;
  navbarPadding?: number | string;
  isMobileNavbarEnabled?: boolean;
  borderStyleOnActive?: Function;
  defaultActiveTab?: number;
};

type NavigationProps = {
  padding?: number | string;
  isMobileNavbarEnabled?: boolean;
};

type DropdownProps = {
  isOpen?: boolean;
};

const DEFAULT_STYLE_ON_ACTIVE = (isActive: boolean) =>
  isActive ? `2px solid ${theme.palette.primaryThemeColor}` : 'none';

const BackButton = styled(Link)`
  border: none;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 48px;
  font-size: 16px;
  color: ${theme.palette.primaryDarkBlue};
  font-weight: 500;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
  ${theme.mediaQueryMinWidth.mb} {
    display: none;
  }
`;

const NavItem = styled.div<TabProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-right: 0;
  padding: 0 56px 0 0;
  width: ${({ tabsLength }) => (theme.modalWidthPx - 48) / tabsLength};
  color: ${({ isActive }) =>
    isActive
      ? props => props.theme.colors.primary[100]
      : theme.palette.primaryDarkBlue};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  border-bottom: ${({ borderStyleOnActive = () => {}, isActive }) =>
    borderStyleOnActive(isActive)};
  cursor: ${props => (props.disabled ? 'unset' : 'pointer')};
  ${theme.mediaQueryMinWidth.mb} {
    padding: 0 12px 0 12px;
  }
`;

const DesktopNavbar = styled.nav<NavigationProps>`
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  ${props => {
    let padding = `${theme.padding16} ${theme.padding24} 0`;

    if (typeof props.padding === 'number') {
      padding = `${props.padding}px;`;
    } else if (typeof props.padding === 'string') {
      padding = props.padding;
    }

    if (props.isMobileNavbarEnabled) {
      return `
        padding: 0;
        ${theme.mediaQueryMinWidth.mb} {
          display: flex;
          padding: 0 3% 0;
        }
        ${theme.mediaQueryMinWidth.xs} {
          display: flex;
          padding: 0 7% 0;
        }
        ${theme.mediaQueryMinWidth.sm} {
          padding: ${padding};
        }
      `;
    }

    return `
      display: flex;
      padding: ${padding};
    `;
  }}
`;

const MobileNavbar = styled.nav<NavigationProps>`
  display: none;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 16px 0;
  ${props => {
    if (props.isMobileNavbarEnabled) {
      return `
        display: flex;
        ${theme.mediaQueryMinWidth.mb} {
          display: none;
          padding: 0;
        }
      `;
    }
  }}
`;

const MobileDropdownMenu = styled.div<DropdownProps>`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  border: ${props =>
    props.isOpen ? `1px solid ${props.theme.colors.primary[100]}` : 'none'};
  position: absolute;
  background-color: #fff;
  top: 30px;
  right: 0;
  z-index: 9;
  div {
    padding: 4px 8px;
    &:hover {
      color: ${props => props.theme.colors.primary[100]};
    }
  }
`;

const DropdownArrow = styled.span<DropdownProps>`
  color: ${props => props.theme.colors.primary[100]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  margin: 0 8px 0 0;
`;

const MobileVisibleTab = styled.span`
  color: ${props => props.theme.colors.primary[100]};
  padding: 0 0 4px 0;
  flex-grow: 2;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 0 32px;
`;

const ContentContainer = styled.div<TabProps>`
  ${props => (props.isActive ? 'display: block' : 'display:none')};
`;

const Title = styled.div`
  margin: 0 0 8px 0;
  text-align: center;
  font-weight: 500;
  font-size: ${theme.fontSizes.md};
  ${theme.mediaQueryMinWidth.mb} {
    font-size: ${theme.fontSizes.sm};
  }
  ${theme.mediaQueryMinWidth.xs} {
    font-size: ${theme.fontSizes.md};
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const DetailsTabs = ({
  tabs,
  navbarPadding,
  borderStyleOnActive = DEFAULT_STYLE_ON_ACTIVE,
  isMobileNavbarEnabled,
  defaultActiveTab,
}: Props) => {
  const [activeTab, setActiveTab] = React.useState(defaultActiveTab || 0);
  const [isOpen, setIsOpen] = React.useState(false);
  const onItemClick = (
    index: number,
    disabled?: boolean,
    onClick?: Function,
    notClickable?: boolean
  ) => {
    setIsOpen(false);
    if (!disabled && !notClickable) {
      setActiveTab(index);
      onClick && onClick(index);
    }
  };

  if (tabs.length === 0) return null;

  return (
    <TabsContainer>
      <DesktopNavbar
        padding={navbarPadding}
        isMobileNavbarEnabled={isMobileNavbarEnabled}
      >
        {tabs.map((tab, index) => {
          const isActive = activeTab === index;
          return (
            <NavItem
              tabsLength={tabs.length}
              disabled={tab.disabled}
              key={index}
              isActive={isActive}
              borderStyleOnActive={borderStyleOnActive}
              onClick={() =>
                !tab.notClickable &&
                onItemClick(index, tab.disabled, tab.onClick, tab.notClickable)
              }
            >
              <Title>{tab.title}</Title>
            </NavItem>
          );
        })}
      </DesktopNavbar>
      <MobileNavbar isMobileNavbarEnabled={isMobileNavbarEnabled}>
        <BackButton to={'/'}>
          <HiChevronLeft
            color={theme.palette.covideoBlue100}
            width={24}
            height={24}
          />
        </BackButton>
        <MobileVisibleTab>
          {tabs.map((tab, index) => {
            const isActive = activeTab === index;
            if (isActive) {
              return (
                <NavItem
                  tabsLength={tabs.length}
                  disabled={tab.disabled}
                  key={index}
                  isActive={isActive}
                  borderStyleOnActive={borderStyleOnActive}
                  onClick={() =>
                    onItemClick(
                      index,
                      tab.disabled,
                      tab.onClick,
                      tab.notClickable
                    )
                  }
                >
                  <Title>{tab.title}</Title>
                </NavItem>
              );
            }
            return null;
          })}
        </MobileVisibleTab>
        <DropdownArrow
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          onBlur={() => setIsOpen(false)}
        >
          <MdArrowDropDown />
        </DropdownArrow>
        <MobileDropdownMenu isOpen={isOpen}>
          {tabs.map((tab, index) => {
            const isActive = activeTab === index;
            if (isActive) return null;
            return (
              <NavItem
                tabsLength={tabs.length}
                disabled={tab.disabled}
                key={index}
                isActive={isActive}
                borderStyleOnActive={borderStyleOnActive}
                onClick={() => {
                  setIsOpen(false);
                  onItemClick(
                    index,
                    tab.disabled,
                    tab.onClick,
                    tab.notClickable
                  );
                }}
              >
                <Title>{tab.title}</Title>
              </NavItem>
            );
          })}
        </MobileDropdownMenu>
      </MobileNavbar>
      {tabs.map((tab, index) => (
        <ContentContainer
          key={`${tab.title}Content`}
          tabsLength={tabs.length}
          isActive={activeTab === index}
        >
          {tab.component}
        </ContentContainer>
      ))}
    </TabsContainer>
  );
};
