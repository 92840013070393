import { CategorizedGuide } from 'lib/api/guides/guideCategoryQuery';
import { useRevokeVisibilityAccess } from 'lib/api/guides/revokeVisibilityMutation';
import { ModalPrompt } from 'lib/components/modal';
import { Gap } from 'lib/components/styles/layout';

import React from 'react';
import styled from 'styled-components/macro';

const ModalParagraph = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

interface RevokeAccessModalProps {
  closeModal: () => void;
  setOpen: (status: boolean) => void;
  selectedGuideCategory: Pick<
    CategorizedGuide,
    'name' | 'guideCategoryId'
  > | null;
}

export const RevokeAccessModal = ({
  closeModal,
  setOpen,
  selectedGuideCategory,
}: RevokeAccessModalProps) => {
  const colose = () => {
    closeModal();
    close();
  };
  const { mutateAsync } = useRevokeVisibilityAccess(colose);
  const close = () => setOpen(false);
  const revokeAccess = () => {
    if (selectedGuideCategory) {
      mutateAsync({
        categoryId: selectedGuideCategory.guideCategoryId,
      });
    }
  };

  return (
    <ModalPrompt
      title='Do you want to revoke access to all users?'
      content={
        <Gap flexDirection='column' gap='16px' alignItems='flex-start'>
          <ModalParagraph>
            If you proceed, all users will lose access to “
            {selectedGuideCategory?.name}” category and will not be able to
            access its video guides.
          </ModalParagraph>
          <ModalParagraph> This action cannot be undone.</ModalParagraph>
        </Gap>
      }
      secondaryButtonText='No, Go Back'
      primaryButtonText='Yes, Revoke Access'
      primaryButtonType={'red'}
      handleSubmit={revokeAccess}
      handleModalClose={close}
      style={{ height: '100%' }}
    />
  );
};
