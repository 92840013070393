import * as React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { theme } from 'lib/style';
import { exportCSVFile } from 'lib/utils/functions';

import { ModalDelete } from 'lib/components/modal';
import { useState } from 'react';
import { CheckboxInput } from 'lib/components';
import { MdDeleteForever, MdFileDownload } from 'react-icons/md';
import { IoIosLogOut } from 'react-icons/io';
import { ContactListItem } from 'lib/api';
import { useAuth } from 'lib/context';
import { isAdminSession } from 'lib/utils/auth';
import { useDeleteContactsMutation } from 'lib/api/contacts/useDeleteContactsMutation';
import { useRemoveGroupContactMutation } from 'lib/api/group/useRemoveGroupContactMutation';
import {
  DeleteContactParams,
  QueryContactsParams,
} from 'lib/api/contacts/types';

const exportHeader = {
  email: 'Email',
  lastName: 'Last Name',
  firstName: 'First Name',
  phone: 'Phone',
};

interface SelectedContacts {
  count: number;
  contacts: Map<string, ContactListItem | undefined>;
}

type HeaderProps = {
  isSingleElement: boolean;
  isTrial?: boolean;
  isAdmin?: boolean /*  added changes for  SUS-686  */;
};

interface ComponentProps {
  selectedContacts: SelectedContacts;
  setSelectedContacts: (value: React.SetStateAction<SelectedContacts>) => void;
  checkIndicator: Function;
  isAllSelected: boolean;
  selectAll: Function;
  groupId: any;
  queryParams: QueryContactsParams;
}

const Container = styled.div<HeaderProps>`
  display: none;
  width: 100%;
  position: ${props =>
    props.isTrial || props.isAdmin
      ? 'relative'
      : 'absolute'}; /*  added changes for  SUS-686  */
  left: 0;
  top: ${props =>
    props.isTrial || props.isAdmin
      ? '40px'
      : '64px'}; /*  added changes for  SUS-686  */
  background: white;
  height: 40px;
  justify-content: ${props =>
    props.isSingleElement ? 'flex-end' : 'space-between'};
  align-items: center;
  padding: 12px 0 12px 0;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
  ${theme.mediaQueryMinWidth.sm} {
    display: flex;
    justify-content: center;
  }
`;

const LayoutOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 6px;
  max-width: 1008px;
  width: 100%;
  margin-left: 208px;
  gap: 10px;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;

const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;

const Component = (props: ComponentProps) => {
  const {
    selectedContacts,
    setSelectedContacts,
    checkIndicator,
    isAllSelected,
    selectAll,
    groupId,
    queryParams,
  } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { userData } = useAuth();

  const resetContactsCount = () => {
    setSelectedContacts({
      count: 0,
      contacts: new Map<string, ContactListItem | undefined>(),
    });
  };

  const onDeleteContactsMutationSuccess = () => {
    setShowDeleteModal(false);
    resetContactsCount();
  };

  const ids: string[] = Array.from(selectedContacts.contacts)
    .filter(([, value]) => value !== undefined)
    .map(([key]) => key);

  const deleteContactsParams: DeleteContactParams = {
    ids,
    onDeleteContactsMutationSuccess,
  };

  const { mutateAsync: deleteContactsMutation } =
    useDeleteContactsMutation(deleteContactsParams);

  const { mutateAsync: removeGroupContact } = useRemoveGroupContactMutation(
    undefined,
    resetContactsCount,
    queryParams
  );

  const onRemove = async () => {
    await removeGroupContact({ contactId: ids, groupId });
  };

  const onDeleteContact = async () => {
    await deleteContactsMutation();
  };

  let modalDeleteText = 'This action can’t be undone.';
  let whiteButtonText = 'Yes, Delete';
  let orangeButtonText = "No, Don't Delete";
  let modalDeleteTitle = 'Delete Contact';
  if (selectedContacts.count > 1) {
    modalDeleteText = `${selectedContacts.count} contacts will be permanently deleted. Are you sure you want to delete them?`;
    whiteButtonText = 'Yes, Delete';
    orangeButtonText = "No, Don't Delete";
    modalDeleteTitle = `Delete ${selectedContacts.count} Contacts`;
  }

  // added to check if user has trialAccount or not SUS-474
  const isTrialAccount =
    userData.trialAccount && !window.location.pathname.includes('/upgrade');

  return (
    <>
      {selectedContacts.count > 0 && (
        <Container
          isTrial={isTrialAccount}
          isAdmin={isAdminSession()} /*  added changes for  SUS-686  */
          isSingleElement={selectedContacts.count === 0}
        >
          <LayoutOption>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={checkIndicator()}
              checked={isAllSelected}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                selectAll({ event })
              }
            />
            <SelectionCountWrap>
              <SelectionCountText>Contacts selected:</SelectionCountText>
              <span>{selectedContacts.count}</span>
            </SelectionCountWrap>
            <Button
              onClick={onRemove}
              text='Remove from Group'
              variant='ghost'
              icon={<IoIosLogOut />}
            ></Button>
            <Button
              onClick={() =>
                exportCSVFile(
                  exportHeader,
                  Array.from(selectedContacts.contacts.values()),
                  'selected_contacts'
                )
              }
              text='Export'
              variant='ghost'
              icon={<MdFileDownload />}
            ></Button>
            <Button
              onClick={() => setShowDeleteModal(true)}
              text='Delete Contact'
              variant='destructive'
              icon={<MdDeleteForever color='#e84c3d' width={20} />}
            ></Button>
          </LayoutOption>
        </Container>
      )}
      {showDeleteModal && (
        <ModalDelete
          whiteButtonText={whiteButtonText}
          orangeButtonText={orangeButtonText}
          title={modalDeleteTitle}
          text={modalDeleteText}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickWhiteButton={onDeleteContact}
          onClickOrangeButton={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export const GroupContactsHeader = React.memo(Component);
