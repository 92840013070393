import { downloadVideo, moveVideo } from 'lib/api';
import { useVideoHotspots } from 'lib/api/videos/getVideoHotspots';
import { useGetVideoLinks } from 'lib/api/videos/getVideoLinks';
import { TableContextProvider, useTableContext } from 'lib/components';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import {
  Heading,
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import * as React from 'react';
import { Button, Tooltip } from 'react-covideo-common';
import { BsFillBellFill, BsFillBellSlashFill } from 'react-icons/bs';
import { MdFileDownload } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
} from 'recharts';
import styled, { css } from 'styled-components/macro';
import { usePageContext } from '../../../../PageContext';
import {
  CardStatsExitLinks,
  CardStatsHotspot,
  CardStatsViewers,
} from '../components';
import { ItemsWrapper } from '../styles/layout';
import SocialInsights from './socialInsights/SocialInsights';
import { useUpdateVideoViewNotificationsMutation } from 'lib/api/videos/useUpdateVideoViewNotificationsMutation';

type itemProps = {
  isActive: boolean;
};

const NavItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid ${theme.palette.grayBorder};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  color: ${theme.palette.label};
`;

const Item = styled.div<itemProps>`
  ${theme.fontNormal500};
  padding: 10px 20px;
  font-size: ${theme.fontSizes.lg};
  &:before {
    font-weight: normal;
    margin-right: 10px;
  }
  ${props =>
    props.isActive &&
    css`
      opacity: 1;
      color: ${props.theme.colors.primary[100]};
      border-bottom: 4px solid ${props.theme.colors.primary[100]};
    `}
  cursor: pointer;
`;

const Stats = styled.div`
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: center;
  justify-content: space-between;
`;
const StatsContainer = styled.div`
  width: 100%;
  ${theme.mediaQueryMinWidth.md} {
    width: 282px;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  font-size: ${theme.fontSizes.xs};
  margin-top: 16px;
  .recharts-cartesian-grid {
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
    border: solid 1px #f7f8fa;
  }
  ${theme.mediaQueryMinWidth.md} {
    margin-top: 0;
  }
  text {
    fill: ${theme.palette.label};
  }
`;
const NoDataContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 60px;
`;

type CardBodyProps = {
  isFirstActive: boolean;
};

const CardBody = styled.div<CardBodyProps>``;

type Props = {
  video: any;
  videoId: string;
  view?: string;
  showActionsButtons?: boolean;
  from?: string;
  to?: string;
};

const msToTime = (s: number) => {
  if (isNaN(s)) {
    return '00:00';
  }
  const ms = s % 1000;
  s = (s - ms) / 1000;
  const secs = s % 60;
  s = (s - secs) / 60;
  const seconds = secs >= 10 ? secs : '0' + secs;
  const mins = s % 60;
  let minutes = mins >= 10 ? mins : '0' + mins;
  return minutes + ':' + seconds;
};

const statsTabItems = [
  {
    title: 'Hotspot',
    isActive: true,
  },
  {
    title: 'Recipients',
    isActive: false,
  },
  {
    title: 'Calls-to-action',
    isActive: false,
  },
  {
    title: 'Social',
    isActive: false,
  },
];

export const Analytics = (props: Props) => {
  const { video, showActionsButtons, videoId, from, to } = props;
  // @ts-ignore
  let { receiverID } = useParams();
  const tableContext = useTableContext();
  const { folder } = usePageContext();
  const isCompany = folder?.name === 'Company' ? 1 : 0;
  const { userData } = useAuth();
  const search = useLocation().search;
  const pathname = useLocation().pathname;
  const tab = new URLSearchParams(search).get('tab');
  const [isSocialFeatureAccessible, setIsSocialFeatureAccessible] =
    React.useState(false);
  const [disableNotificationButtons, setDisableNotificationButtons] =
    React.useState(true);
  const { mutateAsync, isLoading: isUpdatingNotifications } =
    useUpdateVideoViewNotificationsMutation();
  const handleUpdateNotification = async (notify: boolean) => {
    await mutateAsync({ videoId: Number(videoId), notify });
  };

  React.useEffect(() => {
    if (tab) {
      handleSetIsNavItemActive(parseInt(tab));
    }
  }, [tab]);

  const {
    data: hotspotsDataResponse,
    isLoading: loading,
    isError: error,
  } = useVideoHotspots({
    videoId: Number(videoId),
    params: {
      size: 500,
      page: 0,
      search: tableContext.search,
      from,
      to,
      isCompany: isCompany,
    },
    onCountChange: tableContext.setCount,
    userData: userData,
  });
  let hotspotsResponse = hotspotsDataResponse || {
    hotspots: [],
    chart: [],
    views: 0,
    viewPercentage: '',
  };

  const { data: linksResponse } = useGetVideoLinks({
    videoId: videoId,
    params: {
      size: 500,
      page: 0,
      search: tableContext.search,
      isCompany: isCompany,
    },
    onCountChange: tableContext.setCount,
  });

  const ctaResponse = linksResponse || { links: [] };

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id) {
        const isAnalticsAllowed = checkIfFeatureIsEnabled(
          userData,
          productFeature.HOTSPOTS_AND_ANALYTICS
        );
        if (!isAnalticsAllowed) {
          window.location.href = '/upgrade-plan';
        }
      }

      setIsSocialFeatureAccessible(
        checkIfFeatureIsEnabled(userData, productFeature.FACEBOOK) ||
          checkIfFeatureIsEnabled(userData, productFeature.YOUTUBE)
      );
    }
  }, []);

  React.useEffect(() => {
    if (receiverID) {
      handleSetIsNavItemActive(parseInt(receiverID));
    }
  }, [receiverID]);

  React.useEffect(() => {
    if (tab) {
      handleSetIsNavItemActive(parseInt(tab));
    }
  }, []);

  const [isFirstActive, setIsFirstActive] = React.useState(true);
  const [isActive, setIsNavItemActive] = React.useState(
    statsTabItems.map(x => x)
  );

  const handleSetIsNavItemActive = (i: number) => {
    let activeTabItems = statsTabItems.map(x => {
      x.isActive = false;
      return x;
    });
    activeTabItems[i].isActive = true;
    setIsNavItemActive(activeTabItems);

    // We need to do know if first tab is Active
    // because of top-left border radius on <NavItems>
    activeTabItems[0].isActive
      ? setIsFirstActive(true)
      : setIsFirstActive(false);
  };

  const isRecipientsActive = isActive[1].isActive;

  const Cards = (i: number) => {
    switch (i) {
      case 0:
        return (
          <TableContextProvider>
            <CardStatsHotspot
              key={i}
              loading={loading}
              hotspots={hotspotsResponse.hotspots}
              error={error}
            />
          </TableContextProvider>
        );
      case 1:
        return (
          <CardStatsViewers
            key={i}
            videoId={videoId}
            setDisableNotificationButtons={setDisableNotificationButtons}
          />
        );
      case 2:
        return (
          <TableContextProvider>
            <CardStatsExitLinks key={i} ctas={ctaResponse.links} />
          </TableContextProvider>
        );
      case 3:
        return <SocialInsights videoId={videoId} />;

      default:
        return <h1>Nothing to display</h1>;
    }
  };

  const isAnalytics = pathname.includes('analytics');
  const handleCopyToLibrary = async (
    event: MouseEvent,
    videoId: number | string
  ) => {
    if (event && event.detail > 1) {
      return;
    }
    await moveVideo(videoId?.toString(), {
      fromCompany: true,
    });
    successToast({
      title: 'Video copied successfully',
    });
  };

  const handleDownload = (
    event: MouseEvent,
    videoId: number | string,
    title: string
  ) => {
    if (event && event.detail > 1) {
      return;
    }
    downloadVideo(videoId.toString(), `${title}.mp4`);
  };

  return (
    <>
      {isAnalytics && <HelpDesk name={EHelpDesk.ANALYTICS} />}
      <HeaderWrapper>
        <Heading>{video.title}</Heading>

        <Gap>
          {isRecipientsActive && (
            <>
              <Tooltip
                position={'bottom' as any}
                hoverable={true}
                extendStyles={{
                  popup: {
                    width: '165px',
                    left: '50%',
                    lineHeight: '24px',
                  },
                }}
                popup={
                  <div>
                    You will be notified when the current recipients view this
                    video.
                  </div>
                }
              >
                <Button
                  text='Enable Notifications'
                  icon={<BsFillBellFill size={16} />}
                  disabled={
                    disableNotificationButtons || isUpdatingNotifications
                  }
                  onClick={() => {
                    handleUpdateNotification(true);
                  }}
                  variant='secondary'
                />
              </Tooltip>
              <Tooltip
                position={'bottom' as any}
                hoverable={true}
                extendStyles={{
                  popup: {
                    width: '165px',
                    left: '50%',
                    lineHeight: '24px',
                  },
                }}
                popup={
                  <div>
                    You will no longer be notified when current recipients view
                    this video.
                  </div>
                }
              >
                <Button
                  text='Disable Notifications'
                  disabled={
                    disableNotificationButtons || isUpdatingNotifications
                  }
                  icon={<BsFillBellSlashFill size={16} />}
                  onClick={() => {
                    handleUpdateNotification(false);
                  }}
                  variant='destructive'
                />
              </Tooltip>
            </>
          )}
          {showActionsButtons && (
            <>
              <Button
                text='Download'
                icon={<MdFileDownload size={20} />}
                onClick={e => {
                  handleDownload(
                    e as unknown as MouseEvent,
                    video?.id,
                    video.title
                  );
                }}
              />
              <Button
                text='Copy to Library'
                onClick={async (event: MouseEvent | React.SyntheticEvent) => {
                  await handleCopyToLibrary(event as MouseEvent, video?.id);
                }}
              />
            </>
          )}
        </Gap>
      </HeaderWrapper>
      {!loading && hotspotsResponse.hotspots && (
        <ItemsWrapper gap='60px'>
          <StatsContainer>
            <Stats>
              <ParagraphNormalBold>
                {hotspotsResponse.views}
              </ParagraphNormalBold>
              <ParagraphSmall>Total Views</ParagraphSmall>
            </Stats>
            <Stats style={{ margin: '16px 0' }}>
              <ParagraphNormalBold>
                {hotspotsResponse.viewPercentage}%
              </ParagraphNormalBold>
              <ParagraphSmall>Avg. Engagement</ParagraphSmall>
            </Stats>
            <Stats>
              <ParagraphNormalBold>
                {ctaResponse.links.length}
              </ParagraphNormalBold>
              <ParagraphSmall>Total CTA Clicks</ParagraphSmall>
            </Stats>
          </StatsContainer>
          <ChartContainer>
            {!hotspotsResponse.hotspots.length && (
              <NoDataContainer>
                <ParagraphSmall>No data to show.</ParagraphSmall>
              </NoDataContainer>
            )}
            <ComposedChart
              width={700}
              height={280}
              data={hotspotsResponse.chart}
            >
              <defs>
                <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                  <stop
                    offset='45%'
                    stopColor='rgba(0, 27, 83, 0.8)'
                    stopOpacity={0.5}
                  />
                  <stop
                    offset='95%'
                    stopColor='rgba(0, 27, 83, 0.3)'
                    stopOpacity={0.5}
                  />
                </linearGradient>
              </defs>
              <XAxis dataKey='x' tickFormatter={value => msToTime(value)} />
              <YAxis allowDecimals={false} width={30} />
              <CartesianGrid stroke={theme.palette.gray} />
              <Line
                type='monotone'
                unit='M'
                strokeLinecap='round'
                strokeWidth={1}
                dataKey='y'
                stroke='rgba(0, 27, 83, 1)'
                dot={false}
                legendType='none'
              />
              <Area
                type='monotone'
                dataKey='y'
                stroke={''}
                strokeWidth={2}
                fillOpacity={1}
                fill='url(#colorUv)'
              />
            </ComposedChart>
          </ChartContainer>
        </ItemsWrapper>
      )}
      <NavItems>
        {statsTabItems.map((x, i) => {
          if (!isSocialFeatureAccessible && x.title === 'Social') {
            return <React.Fragment key={i} />;
          }

          return (
            <Item
              key={i}
              isActive={isActive[i].isActive}
              onClick={() => handleSetIsNavItemActive(i)}
            >
              {x.title}
            </Item>
          );
        })}
      </NavItems>
      <CardBody isFirstActive={isFirstActive}>
        {statsTabItems.map((x, i) => {
          if (x.isActive) {
            return Cards(i);
          }
          return null;
        })}
      </CardBody>
    </>
  );
};
