import { EXPRESS_API } from 'configs/api/expressApi';

export const getCovideoBoards = async (params: {
  userId: number;
  customerId: number;
}) => {
  const { userId = -1, customerId = -1 } = params;
  const response = await EXPRESS_API.get('/board/all', {
    params: {
      userId,
      customerId,
    },
  });
  return response.data;
};
