import * as React from 'react';
import { SidebarNavigation } from 'lib/components/SidebarNavigation';
import { FaBan } from 'react-icons/fa';
import { MdFolder, MdContacts } from 'react-icons/md';
import { ContactRoutes } from './routes';

export const Main = (): React.ReactElement => {
  const iconSize = '24px';
  const items = [
    {
      title: 'Contacts',
      icon: <MdContacts size={iconSize} />,
      path: `/contacts/list`,
    },
    {
      title: 'Groups',
      icon: <MdFolder size={iconSize} />,
      path: `/contacts/groups`,
    },
    /* {
      title: 'Custom Fields',
      icon: <FaFileAlt size={iconSize} />,
      path: `/contacts/custom-fields`,
    },*/
    {
      title: 'Opt-Outs',
      icon: <FaBan size={iconSize} />,
      path: `/contacts/opt-outs`,
    },
  ];

  return (
    <>
      <SidebarNavigation items={items} hasBack={false} />
      <ContactRoutes />
    </>
  );
};
