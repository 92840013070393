import React from 'react';
import { LoadingIndicator } from 'lib/components';
import { Heading } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import noRecordings from 'assets/images/meetings/noRecordings.webp';
import { useGetMeetingRecordingsQuery } from 'lib/api/meetings/useGetMeetingRecordingsQuery';
import styled from 'styled-components/macro';
import { MeetingRecordingListItem } from '../components/MeetingRecordingListItem';
interface IProps {
  meetingId: string | null;
}

const Body = styled.div`
  width: 1008px;
  max-width: 100%;
  margin: 0 auto;
`;

export const MeetingTabRecording = ({ meetingId }: IProps) => {
  const { data: meetingRecordings, isLoading } = useGetMeetingRecordingsQuery(
    meetingId || ''
  );

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }

  if (meetingRecordings?.length === 0) {
    return (
      <div style={{ marginTop: 126 }}>
        <img src={noRecordings} alt='no recordings'></img>
        <Heading
          color={theme.palette.covideoBlue100}
          textAlign='center'
          m='70px 0 0'
        >
          No Recordings Yet
        </Heading>
      </div>
    );
  }

  return (
    <Body>
      {meetingRecordings?.map(recording => (
        <MeetingRecordingListItem
          key={recording.id}
          meetingRecording={recording}
        />
      ))}
    </Body>
  );
};
