import { useMutation } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';

const deleteDrop = async (dropId: string) => {
  return (await EXPRESS_API.delete(`/droplr/file/${dropId}`)).data;
};

export const useDeleteDrop = () => {
  return useMutation((dropId: string) => deleteDrop(dropId));
};
