import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { customReportKeys } from './customReportKeys';
import { CustomReportsParams } from './useCustomReportsQuery';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';

const deleteCustomReport = async (reportId: string) => {
  await EXPRESS_API.delete(`/custom-reports/${reportId}`);
  return { reportId };
};
export const useDeleteCustomReport = (params: CustomReportsParams) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(deleteCustomReport, {
    onError: err => showError(err),
    onSuccess: data => {
      queryClient.invalidateQueries(customReportKeys.custom_reports(params));
      queryClient.removeQueries(customReportKeys.custom_report(data.reportId));
      successToast({ title: `You've successfully deleted Custom Report` });
    },
  });
};
