import React, { useState } from 'react';
import { MdPeople, MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { InviteFriendsModal } from '../modal/ModalInviteFriends';
import { useAuth } from 'lib/context';
import dayjs from 'dayjs';

const InviteFriendsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;

  position: absolute;
  width: 360px;
  right: 32px;
  top: 92px;

  background: #ffffff;

  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 12px 20px rgba(66, 79, 104, 0.06);
  border-radius: 6px;
`;
const HeaderParagraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  margin: 0;
  color: #272a32;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const Body = styled.div`
  display: flex;
  padding-left: 36px;
  flex-wrap: wrap;
  gap: 12px;
`;

const BodyParagraph = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(39, 42, 50, 0.6);
  margin: 0px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const INVITE_FRIENDS = 'INVITE_FRIENDS';
const DISMISS = 'DISMISS';
const LATER = 'LATER';
const SEVEN_DAYS = dayjs().add(7, 'days').toISOString();
const INDEFINITELY = dayjs().add(10, 'year').toISOString();
const PAST = dayjs().subtract(7, 'millisecond').toISOString();

enum CLICKED {
  NONE = 'none',
  CLICKED = 'clicked',
}

interface IState {
  [key: string]: {
    type: typeof DISMISS | typeof LATER | null;
    expires: string;
    clicked: CLICKED.NONE | CLICKED.CLICKED;
  };
}

const getFromLocalStorage = (userId: string) => {
  const item = localStorage.getItem(INVITE_FRIENDS);
  const newItem = {
    type: null,
    userId: userId,
    expires: PAST,
    clicked: CLICKED.NONE,
  };
  if (!item) {
    return { [userId]: newItem };
  }
  const parsedItem = JSON.parse(item) as IState;
  const userExist = !!parsedItem[userId];

  return {
    ...parsedItem,
    [userId]: { ...(userExist ? parsedItem[userId] : newItem) },
  };
};

export const InviteFriends = () => {
  const {
    userData: { totalLogins, userId, user },
  } = useAuth();
  const [modal, setModal] = useState<boolean>(false);
  const [isDismissed, setDismissed] = useState<IState>(() =>
    getFromLocalStorage(userId)
  );

  const isHidden = dayjs(isDismissed?.[userId]?.expires).diff() > 0;
  const isSecondLogin = Number(totalLogins) >= 2;
  const isFreemium = user?.packageDetails?.id === 1;

  if (!isFreemium || !isSecondLogin || isHidden) {
    return null;
  }

  const onDismissHandler = () => {
    const type = {
      type: DISMISS,
      expires: INDEFINITELY,
      clicked: CLICKED.CLICKED,
    } as const;
    const payload = { ...isDismissed, [userId]: type };
    setDismissed(payload);
    return localStorage.setItem(INVITE_FRIENDS, JSON.stringify(payload));
  };

  const onMaybeLaterHandler = () => {
    if (isDismissed[userId].clicked === CLICKED.CLICKED) {
      return onDismissHandler();
    }
    const type = {
      type: LATER,
      expires: SEVEN_DAYS,
      clicked: CLICKED.CLICKED,
    } as const;
    const payload = { ...isDismissed, [userId]: type };
    setDismissed(payload);
    return localStorage.setItem(INVITE_FRIENDS, JSON.stringify(payload));
  };

  return (
    <>
      <InviteFriendsWrapper>
        <HeaderWrapper>
          <HeaderInfoWrapper>
            <MdPeople size={22} color='#4E5461' />
            <HeaderParagraph>Invite your friends!</HeaderParagraph>
          </HeaderInfoWrapper>
          <div onClick={onDismissHandler}>
            <MdClose color='rgba(39, 42, 50, 0.2)' size={20} />
          </div>
        </HeaderWrapper>
        <Body>
          <BodyParagraph>Invite others to join Covideo.</BodyParagraph>
          <ButtonsWrapper>
            <Button
              text='Invite Friends'
              onClick={() => setModal(true)}
              variant='secondary'
            />
            <Button
              text='Maybe Later'
              onClick={onMaybeLaterHandler}
              variant='secondary'
            />
          </ButtonsWrapper>
        </Body>
      </InviteFriendsWrapper>

      {modal && (
        <InviteFriendsModal
          handleModalClose={() => setModal(false)}
          onDismissHandler={onDismissHandler}
          onMaybeLaterHandler={onMaybeLaterHandler}
        />
      )}
    </>
  );
};
