import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Voiceover } from './Voiceover';
import { useSingleVideoQuery } from 'lib/api/videos/useSingleVideoQuery';
import styled from 'styled-components/macro';

const MainContainer = styled.main`
  width: 1240px;
  margin: 64px auto 0;
  padding: 32px 0;
  min-height: 688px;
`;
interface MatchParams {
  videoId: string;
}

export const Main = withRouter((props: RouteComponentProps<MatchParams>) => {
  const { videoId } = props.match.params;
  const {
    data: video,
    isError,
    isLoading,
  } = useSingleVideoQuery({
    videoId: parseInt(videoId, 10),
  });

  if (!video || isError || isLoading) {
    return null;
  }

  return (
    <>
      <MainContainer>
        <Voiceover video={video} />
      </MainContainer>
    </>
  );
});
