import * as React from 'react';
import { DocumentHead } from 'lib/components';

import { Signup as Register } from './components';

export const Signup = () => {
  return (
    <>
      <DocumentHead title="Signup" />
      <Register />
    </>
  );
};
