import React, { useMemo } from 'react';

import { DocumentHead, PageTemplate } from 'lib/components';
import ActivityFeed from './main/ActivityFeed';

const Wrapper = () => {
  return useMemo(() => <ActivityFeed />, []);
};

export const Activity = () => (
  <>
    <DocumentHead title='Activity Feed' />
    <PageTemplate main={<Wrapper />} />
  </>
);

export default Activity;
