import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { FormikHelpers } from 'formik';
import { conversationsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface ISMSParams {
  text: string;
  to: string;
  conversationId: number;
}

interface IMutationData extends ISMSParams {
  formik?: FormikHelpers<ISMSParams>;
}

export interface IResponse {
  status: string;
  type: string;
  text: string;
  smsId: string;
  videos: any[];
  images: any[];
  customerId: number;
  userId: number;
  chatId: number;
  conversationId: number;
  messageId: number;
  createdAt: string;
}

const sendSMS = async (params: ISMSParams) => {
  const { to, text, conversationId } = params;

  const body = {
    to,
    text,
  };

  const { data } = await EXPRESS_API.post(
    `conversations/${conversationId}/sms/send`,
    body
  );

  return data as IResponse;
};

export const useSendSMSMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation((data: IMutationData) => sendSMS(data), {
    onSuccess: (_, { formik, conversationId }) => {
      successToast({ title: 'Your message is sent successfully!' });
      formik?.resetForm();

      queryClient.invalidateQueries(
        conversationsKeys.detail(`${conversationId}`)
      );
      queryClient.invalidateQueries(conversationsKeys.lists());
    },
    onError: (err: AxiosError) => showError(err),
  });
};
