import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { crmIntegrationsKeys } from './queryKeys';

export const getVinsolutionUsers = async (dealerId: number) => {
  const response = await EXPRESS_API.get(
    `/crm/vinsolution/dealers/${dealerId}/users`
  );
  return response.data;
};

export const useGetVinsolutionUsersQuery = (
  dealerId: number,
  enabled = false
) => {
  return useQuery(
    crmIntegrationsKeys.vinsolutionUsersList(dealerId),
    () => getVinsolutionUsers(dealerId),
    { enabled }
  );
};
