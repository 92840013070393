import { uniq } from 'lodash';
import * as Yup from 'yup';
import { IMS_PROVIDERS_VALUES } from './types';

const arrayNotEmpty = (fieldName: string) =>
  Yup.array().of(
    Yup.string()
      .trim()
      .strict(true)
      .required(`${fieldName} cannot have empty values`)
  );

const uniqueArrayItems = (_: string) => (array: string[]) => {
  const isUnique = uniq(array)?.length === array?.length;

  return isUnique;
};

export const validationSchema = Yup.object().shape({
  customerId: Yup.number().required('Customer ID is required'),
  // set all ims fields as not empty and unique
  ...Object.values(IMS_PROVIDERS_VALUES).reduce(
    (acc, imsProviderValue) => {
      acc[imsProviderValue] = arrayNotEmpty(imsProviderValue).test(
        'unique-items',
        `${imsProviderValue} must have unique values`,
        uniqueArrayItems(imsProviderValue)
      );
      return acc;
    },
    {} as { [key: string]: Yup.ArraySchema<string> }
  ),
});
