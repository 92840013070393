export const SCREEN_VALUES = {
  CUSTOMERS: '1',
  USERS: '2',
  RESELLERS: '3',
};

export const DEFAULT_RESELLER_ID = 0;

export const ORGANIZATIONS_KEY = ['super-admin-organizations'];
export const SORTKEYS = {
  BUSINESS: 'BUSINESS',
};

export const SUPER_ADMIN_IMS_SINGLE = 'super-admin-ims-single';
export const SUPER_ADMIN_IMS_UNCONNECTED = 'super-admin-ims-unconnected';
export const SUPER_ADMIN_IMS_CONNECTED = 'super-admin-ims-connected';
export const SUPER_ADMIN_IMS_AVAILABILITY = 'ims-availability';
export const AUTOMOTIVE_RESELLER_ID = 37;
export const COVIDEO_RESELLER_ID = 1;
export const DEFAULT_PLAYBACK_URL_VALUE = 'vidmails.com';
export const PASSWORD_ERROR_MESSAGE =
  'Password must contain at least 8 characters, one upper case, one lower case, one number and special characters (only !@#$%^&* special characters can be used).';
export const NAPLETON_AUTOMOTIVE_RESELLER_ID = 441;

export const ADMIN_CUSTOMER_IDS = ['3', '1210'];
export const CAMPING_WORLD_RESELLER_ID = '138';
export const CAMPING_WORLD_IDS = [
  '27676',
  '72515',
  '105568',
  '113955',
  '116067',
];
