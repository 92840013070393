import { useQuery } from 'react-query';
import { billingKeys } from './billingKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export type Invoice = {
  id: string;
  amount_due: number;
  created: number;
  invoice_pdf: string;
};

export type GetInvoicesResponse = {
  invoices: Invoice[];
  hasMore: boolean;
};

type Params = {
  startingAfter?: string;
  limit?: number;
};

export const getInvoices = async (
  params: Params
): Promise<GetInvoicesResponse> => {
  const response = await EXPRESS_API.get<GetInvoicesResponse>(
    '/register/payment/invoices',
    {
      params,
    }
  );
  return response.data;
};

export const useInvoicesQuery = (params: Params) => {
  return useQuery<GetInvoicesResponse, Error>(billingKeys.invoices(), () =>
    getInvoices(params)
  );
};
