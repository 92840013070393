import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CommonTypography } from '../styles/typography';
import { UpgradeRequestModal } from '../free-trial/UpgradeRequestModal';
import { useHistory } from 'react-router';
import { useAuth } from 'lib/context';

const ContainerNew = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${theme.padding24};
  border-bottom: 1px solid ${theme.palette.grayBorder};
  cursor: default;
  height: 50px;
  top: 64px;
`;

const UpgradeButton = styled.a<{ width?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${theme.palette.covideoBlue10};
  width: ${({ width }) => (width ? `${width}px` : '141px')} ;
  height: 40px;
  margin: 0 0 0 24px;
  padding: 8px, 16px, 8px, 16px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px
  color: #272a32;
  background-color: #f7f9fa;
  color: ${theme.palette.covideoBlue100};
  text-decoration: none;
  cursor: pointer;
  border-radius: 6px;
  &:focus {
    outline: 1px dotted #272a32;
  }
  z-index: 1;
  ${theme.mediaQueryMinWidth.md} {
    width: ${({ width }) => (width ? `${width}px` : '142px')};
  }
`;

const Main = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  color: ${theme.palette.covideoBlue100};
`;

export const FreeTrial = () => {
  const [showUpgradeModal, setShowUpgradeModal] = React.useState(false);
  const { userData, invalidateUser } = useAuth();
  const {
    trialExpired,
    trialDays,
    upgradeRequestedOn,
    firstName,
    isAutomotive,
    trialAccount,
  } = userData;
  const isVisible =
    trialAccount && !window.location.pathname.includes('/upgrade');
  const upgradeRequested = !!upgradeRequestedOn && !!upgradeRequestedOn.length;

  const history = useHistory();

  React.useEffect(() => {
    if (
      isVisible &&
      trialExpired &&
      !window.location.pathname.includes('/home')
    ) {
      history.push('/home');
    }
  }, []);

  const handleModalClose = async (success: boolean) => {
    if (success) {
      await invalidateUser();
    }

    setShowUpgradeModal(false);
  };

  if (isVisible)
    return (
      <ContainerNew>
        <Main>
          {!trialExpired ? (
            <Title>{`You have ${trialDays} days left in your free trial.`}</Title>
          ) : upgradeRequested ? (
            <Title>
              You have sent a request for upgrade. Our team will reach out with
              next steps.
            </Title>
          ) : (
            <Title>Your Covideo free trial has expired.</Title>
          )}
          <div>
            <UpgradeButton
              href={'#'}
              onClick={() => setShowUpgradeModal(true)}
              width={upgradeRequested ? 193 : 141}
            >
              {upgradeRequested ? 'Send another request' : 'Upgrade Today'}
            </UpgradeButton>
          </div>
        </Main>
        {showUpgradeModal && (
          <UpgradeRequestModal
            firstName={firstName}
            isAutomotive={isAutomotive}
            handleModalClose={handleModalClose}
          />
        )}
      </ContainerNew>
    );
  return null;
};
