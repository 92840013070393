import { Region } from 'lib/context';
import { useQuery } from 'react-query';
import { regionKeys } from './regionKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getResellerRegions = async (): Promise<Region[]> => {
  return (await EXPRESS_API.get(`region`)).data as Region[];
};

export const useGetRegionsQuery = (enabled: boolean) => {
  return useQuery(regionKeys.regions(), () => getResellerRegions(), {
    enabled,
  });
};
