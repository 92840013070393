export const GOOGLE_MAPS_API_KEY = 'AIzaSyBCJcXY5rtnqAtU6ABPK_3_VDh671xO0ac';

interface GooglePlaceValue {
  description: string;
  place_id: string;
  // ...
}

export interface GooglePlace {
  label: string;
  value: GooglePlaceValue;
}
export const generateGoogleMapsPreview = (
  mapIframeEmbedCode: string
): string => {
  // no preview if empty
  if (!mapIframeEmbedCode) {
    return '';
  }
  // preview directly if iframe in placeId
  const iframePattern = /<iframe[\s\S]*?<\/iframe>/i;
  if (iframePattern.test(mapIframeEmbedCode)) {
    return mapIframeEmbedCode;
  }
  // generate iframe from placeid
  return `<iframe style="border:0" loading="lazy" src="https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=place_id:${mapIframeEmbedCode}"></iframe>`;
};
