import React, { useEffect, useState } from 'react';
import { GuidesEmpty } from '../GudesEmpty';
import { ThumbnailGuidesList } from '../../ThumbnailGuidesList';
import { useGuidesQuery } from 'lib/api/guides/guidesQuery';
import {
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { ModalsType } from './MainGuidePage';
import { DeleteGuideModal } from '../../modals/DeleteGuideModal';
import { useIsMutating } from 'react-query';
import { guidesKeyes } from 'lib/api/guides/guidesKeyes';

interface IProps {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  search: string;
  setModals?: React.Dispatch<React.SetStateAction<ModalsType>>;
}

export const Guides = (props: IProps) => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(12);
  const [internalModal, setInternalModal] = useState<ModalsType>('');
  const [currentVideoId, setCurrentVideoId] = useState<
    | {
        videoId: number;
        categoryId: number;
      }
    | undefined
  >(undefined);

  const { search } = props;
  const { data: guides, isLoading, refetch, isFetching } = useGuidesQuery(
    { search: props.search, page, size },
    !!search
  );
  const deleteMutationCount = useIsMutating({
    mutationKey: guidesKeyes.deleteMutationKey(currentVideoId?.categoryId ?? 0),
  });

  const isEmpty = !guides?.guides.length;

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  useEffect(() => {
    refetch();
  }, [page, size]);

  const closeModalHandler = () => {
    setInternalModal('');
    setCurrentVideoId(undefined);
  };
  const handleGuideSelection = (videoId: number, categoryId?: number) => {
    if (!categoryId) {
      return;
    }
    setCurrentVideoId({ videoId, categoryId });
  };

  const loading = isLoading || isFetching || deleteMutationCount > 0;

  if (loading) {
    return <LoadingIndicator isLoading={loading} height='500px' />;
  }

  return isEmpty ? (
    <GuidesEmpty {...props} />
  ) : (
    <TableContextProvider
      total={guides!.count}
      onChange={onPaginationChange}
      initPage={page}
      initSize={size}
    >
      <ThumbnailGuidesList
        guides={guides!.guides}
        setModals={setInternalModal}
        handleGuideSelection={handleGuideSelection}
      />
      <TableFooter>
        <TablePaginationNew disabled={isFetching || isLoading} />
        <TablePaginationSizeNew text='' />
      </TableFooter>
      {internalModal === 'delete-guides' && (
        <DeleteGuideModal
          closeModalHandler={closeModalHandler}
          guideCategoryId={currentVideoId?.categoryId || 0}
          selectedGuides={[currentVideoId?.videoId || 0]}
          search={search}
        />
      )}
    </TableContextProvider>
  );
};
