import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const MainContainer = styled.div`
  margin: 61px 0 60px 208px;
  padding: 32px 112px;
  box-sizing: border-box;
`;

export const RecurringReportContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.palette.covideoSecondaryButtonBg};
  border-radius: 12px;
  padding: 16px;
  gap: 16px;
  margin-bottom: 24px;
`;
