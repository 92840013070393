import { EXPRESS_API } from 'configs/api/expressApi';
import { QueryListParams } from './types';

export const getQRVideos = async ({
  page = 0,
  size = 10,
  search,
  sort,
}: QueryListParams) => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    search: search,
    sort: sort,
  };
  const response = await EXPRESS_API.get(`/qr-videos`, { params });
  return response.data;
};

export const createQRVideo = async (data: any) => {
  const response = await EXPRESS_API.post(`/qr-videos`, data);
  return response.data;
};

export const updateQRVideo = async (data: any) => {
  const response = await EXPRESS_API.put(`/qr-videos/${data.qrId}`, data);
  return response.data;
};

export const deleteQRVideo = async (data: any) => {
  const response = await EXPRESS_API.delete(`/qr-videos/${data.qrId}`, {
    data,
  });
  return response.data;
};
