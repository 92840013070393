import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { theme } from 'lib/style';
import { DropItem } from 'lib/api/droplr/types';
import { useEditDrop } from 'lib/api/droplr/editDrop';
import { useIsMutating } from 'react-query';
import { droplrKeys } from 'lib/api/droplr/queryKeys';
import { Button } from 'react-covideo-common';

const Content = styled.div`
  margin-top: 24px;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin-bottom: 8px;
`;

const Input = styled.input`
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #001b53;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  drop: DropItem;
  handleModalClose: (reportId?: string) => void;
  setUpdatedTitle?: (title: string) => void;
};

export const RenameFileModal = ({
  handleModalClose,
  drop,
  setUpdatedTitle,
}: Props) => {
  const [title, setTitle] = useState(drop.title);

  const { mutateAsync: editDrop } = useEditDrop({
    dropId: drop.code,
  });

  const mutateCount = useIsMutating({
    mutationKey: droplrKeys.edit_drop_mutation(),
  });

  const isLoading = mutateCount > 0;

  const updateTitle = async () => {
    await editDrop({
      dropId: drop.code,
      title,
    });
    if (setUpdatedTitle) {
      setUpdatedTitle(title);
    }
  };

  return (
    <NewModal closeModal={handleModalClose} headerText='Rename File'>
      <Content>
        <InputWrapper>
          <Label>New name</Label>
          <Input
            type='text'
            value={title}
            onChange={event => setTitle(event.target.value)}
          />
        </InputWrapper>
      </Content>
      <Footer>
        <Button
          text='Save Changes'
          onClick={async () => {
            await updateTitle();
            handleModalClose();
          }}
          disabled={!title.trim() || isLoading}
        />
      </Footer>
    </NewModal>
  );
};
