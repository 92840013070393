export const MERGE_VIDEO_SOURCE_TABS = {
  library: {
    value: 'Library',
    isHidden: false,
    isLocked: false,
    isDisabled: false,
    isLive: true,
  },
  wheelsTv: {
    value: 'WheelsTV',
    isHidden: false,
    isLocked: false,
    isDisabled: false,
    isLive: true,
  },
  lesa: {
    value: 'Lesa',
    isHidden: false,
    isLocked: false,
    isDisabled: false,
    isLive: true,
  },
};
