import React from 'react';
import styled from 'styled-components/macro';
import { Heading } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { MdAdd } from 'react-icons/md';
import { CustomerStatistics } from 'lib/context';
import { CardType, StatsCard } from '../../customers/components/StatsCard';
import { MdFileDownload } from 'react-icons/md';
import { Button } from 'react-covideo-common';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 32px;
`;

const LeftHeaderWrapper = styled.div`
  display: flex;
  gap: 48px;
`;

const RightHeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StatsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

type Props = {
  statistics: CustomerStatistics;
  loading: boolean;
  onAddNewUser(): void;
  exportUsers(): void;
};

export const UsersHeader = ({
  statistics,
  loading,
  onAddNewUser,
  exportUsers,
}: Props) => {
  return (
    <HeaderWrapper>
      <LeftHeaderWrapper>
        <Heading color={theme.palette.gray100}>Users</Heading>
        <StatsWrapper>
          <StatsCard
            count={!loading && statistics?.active ? statistics?.active : 0}
            text={'active users'}
          />
          <StatsCard
            count={!loading && statistics?.inActive ? statistics?.inActive : 0}
            text={'inactive users'}
            cardType={CardType.SECONDARY}
          />
        </StatsWrapper>
      </LeftHeaderWrapper>
      <RightHeaderWrapper>
        <Button
          icon={<MdFileDownload color={theme.palette.black_1_100} />}
          onClick={exportUsers}
          text='Export Users'
          variant='secondary'
        />

        <Button
          icon={<MdAdd color={theme.palette.white || '#fff'} />}
          onClick={onAddNewUser}
          text='New User'
        />
      </RightHeaderWrapper>
    </HeaderWrapper>
  );
};
