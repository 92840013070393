import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { customReportKeys } from './customReportKeys';

const getCustomReport = async (reportId: string) => {
  const response = await EXPRESS_API.get(`/custom-reports/${reportId}`);
  return response.data;
};

export const useCustomReportQuery = (reportId: string) => {
  return useQuery(
    customReportKeys.custom_report(reportId),
    () => getCustomReport(reportId),
    {
      enabled: !!reportId,
      refetchOnMount: true,
    }
  );
};
