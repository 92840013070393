import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { ModalDelete } from 'lib/components/modal';
import { useState } from 'react';
import { GroupListItem, ContactListItem } from 'lib/api/types';
import { exportCSVFile } from 'lib/utils/functions';
import { CheckboxInput, SelectionHeaderPopup } from 'lib/components';
import { Button } from 'react-covideo-common';
import { MdDeleteForever, MdAddToPhotos, MdFileDownload } from 'react-icons/md';
import { ModalMergeGroup } from 'lib/components/modal/ModalMergeGroup';
import { useDeleteGroupsMutation } from 'lib/api/group/useDeleteGroupsMutation';
import { useMergeGroupsMutation } from 'lib/api/group/useMergeGroupsMutation';
import { QueryParams } from 'lib/api/group/useQueryGroups';

const exportHeader = {
  email: 'Email',
  lastName: 'Last Name',
  firstName: 'First Name',
  phone: 'Phone',
};

type SelectedGroups = {
  count: number;
  groups: Map<string, GroupListItem | undefined>;
};

interface ComponentProps {
  selectedGroups: SelectedGroups;
  onDelete: Function;
  checkIndicator: Function;
  isAllSelected: boolean;
  selectAll: Function;
  isVisible?: boolean;
  queryParams: QueryParams;
}

const LayoutOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;

const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;

const Component = (props: ComponentProps) => {
  const {
    selectedGroups,
    onDelete,
    checkIndicator,
    isAllSelected,
    selectAll,
    isVisible = false,
    queryParams,
  } = props;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isModalMergeGroupsVisible, setIsModalMergeGroupsVisible] =
    useState(false);

  const ids: number[] = Array.from(selectedGroups.groups)
    .filter(([, value]) => value !== undefined)
    .map(([key]) => parseInt(key));

  const onDeleteGroupsMutationSuccess = () => {
    onDelete();
    setShowDeleteModal(false);
  };

  const { mutateAsync: deleteGroupsMutation } = useDeleteGroupsMutation(
    ids.map((id: number) => id.toString()),
    onDeleteGroupsMutationSuccess,
    queryParams
  );

  const onMergeGroupsMutationSuccess = () => {
    onDelete();
    setIsModalMergeGroupsVisible(false);
  };

  const { mutateAsync: mergeGroupsMutation } = useMergeGroupsMutation(
    onMergeGroupsMutationSuccess,
    queryParams
  );

  const handleActionClick = async () => {
    await deleteGroupsMutation();
  };

  const onMergeGroup = async (data: { name: string }) => {
    await mergeGroupsMutation({ ids: ids, name: data.name });
  };

  let modalDeleteText = 'This action can’t be undone.';
  let whiteButtonText = 'Yes, Delete';
  let orangeButtonText = "No, Don't Delete";
  let modalDeleteTitle = 'Delete group';
  if (selectedGroups.count > 1) {
    modalDeleteText = `${selectedGroups.count} groups will be permanently deleted. Are you sure you want to delete them?`;
    whiteButtonText = 'Yes, Delete';
    orangeButtonText = "No, Don't Delete";
    modalDeleteTitle = `Delete ${selectedGroups.count} groups`;
  }
  const getContactsFromGroups = (groups: (GroupListItem | undefined)[]) => {
    let result: ContactListItem[] = [];
    groups.forEach(a => {
      if (a && a.contacts) result = result.concat(a.contacts);
    });
    return result;
  };

  return (
    <>
      {(selectedGroups.count > 0 || isVisible) && (
        <SelectionHeaderPopup left='208px' fixedWidth='1032px'>
          <LayoutOption>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={checkIndicator()}
              checked={isAllSelected}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                selectAll({ event })
              }
            />
            <SelectionCountWrap>
              <SelectionCountText>Groups selected:</SelectionCountText>
              <b>{selectedGroups.count}</b>
            </SelectionCountWrap>
            <Button
              onClick={() => setIsModalMergeGroupsVisible(true)}
              text='Merge Groups'
              variant='ghost'
              disabled={selectedGroups.count < 2}
              icon={<MdAddToPhotos />}
            ></Button>
            <Button
              onClick={() =>
                exportCSVFile(
                  exportHeader,
                  getContactsFromGroups(
                    Array.from(selectedGroups.groups.values())
                  ),
                  'selected_contacts'
                )
              }
              text='Export'
              variant='ghost'
              icon={<MdFileDownload />}
            ></Button>
            <Button
              onClick={() => setShowDeleteModal(true)}
              text='Delete'
              variant='destructive'
              icon={<MdDeleteForever color='#e84c3d' width={20} />}
            ></Button>
          </LayoutOption>
        </SelectionHeaderPopup>
      )}
      {showDeleteModal && (
        <ModalDelete
          whiteButtonText={whiteButtonText}
          orangeButtonText={orangeButtonText}
          hasConfirm={true}
          title={modalDeleteTitle}
          text={modalDeleteText}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickWhiteButton={handleActionClick}
          onClickOrangeButton={() => setShowDeleteModal(false)}
        />
      )}
      {isModalMergeGroupsVisible && (
        <ModalMergeGroup
          title='Merge Groups'
          text=''
          handleModalClose={() => setIsModalMergeGroupsVisible(false)}
          handleSubmit={onMergeGroup}
        />
      )}
    </>
  );
};

export const GroupsHeader = React.memo(Component);
