import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { hex } from 'color-convert';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  createVideoAccessLog,
  getTranscriptionInfo,
  getTranslationInfo,
} from 'lib/api';

import { OnScreenPlayButton, VideoControls } from './components';
import { theme } from 'lib/style';
import { LoadingIndicator } from 'lib/components';
import { useAuth } from 'lib/context';
import { addCallToAction } from 'lib/utils/annotations';
import { useContainerDimensions } from 'lib/hooks';
import { usePlayerDesignQuery } from 'lib/api/playerDesign/usePlayerDesignQuery';
import { MAX_IMAGE_HEIGHT } from '../videoDetails/main/videoAnnotations/constants/style';
import {
  AnnotationListItem,
  VideoChapterItem,
  VideoChapterItemExtended,
} from 'lib/api/types';
import { formatTimeToString } from 'lib/utils/functions';
import { isAdminSession } from 'lib/utils/auth';
import { useGetAnnotationsQuery } from 'lib/api/annotations/getAnnotations';
import { isSafari } from 'lib/utils/browserChecks';
import { IoMdLink } from 'react-icons/io';

type Props = {
  playerBackgroundColor?: string;
  playerIconsColor?: string;
  playButtonPosition?: string;
  thumbnail?: string;
  videoSource: string;
  videoId?: string;
  videoRef?: any;
  small?: boolean;
  autoplay?: boolean;
  playlist?: any[];
  preventWideSize?: boolean;
  selectedAnnotationId?: string | undefined;
  previewAnnotation?: any;
  hideContext?: boolean;
  startWithControls?: boolean;
  height?: string;
  width?: string;
  margin?: string;
  id?: string;
  disableControls?: boolean;
  videoChapters?: VideoChapterItem[];
  borderRadius?: string;
  isFreemium?: boolean;
  style?: object;
};

type PlayerLayoutProps = {
  height?: string;
  width?: string;
  small?: boolean;
  margin?: string;
  disablePointerEvents?: boolean;
};
type ControlProps = {
  r: number;
  g: number;
  b: number;
  hex: string;
  small?: boolean;
  isVisible: boolean;
};
type FigureProps = {
  small?: boolean;
  preventWideSize?: boolean;
};

const PlayerLayout = styled.div<PlayerLayoutProps>`
  box-sizing: border-box;
  overflow: overlay;
  display: flex;
  justify-content: center;
  padding-right: 0px;
  height: ${props => (props.height ? props.height : '100%')};
  width: ${props => (props.width ? props.width : '')};
  margin: ${props => (props.margin ? props.margin : '')};
  ${({ disablePointerEvents }) =>
    disablePointerEvents &&
    css`
      pointer-events: none;
    `}
`;

const Figure = styled.figure<FigureProps>`
  border-radius: 4px;
  margin: 0;
  position: relative;
  height: 100%;
  /* TODO - check if this is ok */
  max-width: 800px;
  overflow: hidden;

  .fullscreen {
    height: 405px;
    display: flex;
  }

  ${props => {
    if (props.preventWideSize) {
      return `width: auto;`;
    }
    return `
      ${theme.mediaQueryMinWidth.lg} {
        width: 720px;
      }
    `;
  }}
`;

const FullscreenWrapper = styled(FullScreen)`
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;

const Video = styled.video<any>`
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '4px')};
  position: relative;
  width: 100%;
  height: ${props => (props.isFullScreen ? '100%' : props.height)};
  object-fit: contain; /*object-fit: contain to fix the issue of vertical video SUS-687*/
  max-height: ${props => (props.isFullScreen ? '100%' : '405px')};
`;

const Overlay = styled.div`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.palette.whiteRgb75};
  z-index: 4;
`;

const Controls = styled.div<ControlProps>`
  transition: all 0.15s;
  position: absolute;
  bottom: 0;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  left: 0;
  right: 0;
  background-color: ${props => props.hex};
  padding: ${props => `${props.small ? '0px 2px 1px' : '8px'}`};
`;

const LinkWrapper = styled.div`
  margin-right: 5px;
  margin-top: 2px;
`;

const Annotation = styled.div<any>`
  opacity: ${props => (props.show ? '1' : '0')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  z-index: ${props => (props.show ? 8 : 0)};
`;

const AnnotationText = styled(Annotation)<any>`
  padding: ${props => props.padding || '12px 16px'};
  z-index: 9;
  font-size: ${props => props.fontSize}px;
  font-family: ${props => props.fontFamily};
  color: ${props => props.textColor};
  background: ${props => props.backgroundColor};
  border-color: ${props => props.borderColor};
  border-width: ${props => props.borderWidth}px;
  border-style: ${props => (props.borderWidth ? 'solid' : '')};
  text-decoration: none;
  outline: none;
  white-space: pre-wrap;
  ${props => (props.textBold ? `font-weight: 700;` : '')}
  ${props => (props.textItalic ? `font-style: italic;` : '')}
  ${props => (props.textUnderline ? `text-decoration: underline;` : '')}

  &:active {
    color: ${props => props.textColor};
  }

  .formatText {
    inline-size: 430px;
    overflow-wrap: break-word;
    padding: 15px;
  }

  ${props => props.isLinkEnabled && 'cursor: pointer;'}
  ${props =>
    props.position === 'Top Left' &&
    css`
      top: 12px;
      left: 0px;
    `}
  ${props =>
    props.position === 'Top Center' &&
    css`
      top: 12px;
      left: 50%;
      transform: translate(-50%, 0);
    `}
  ${props =>
    props.position === 'Center' &&
    css`
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `}
  ${props =>
    props.position === 'Top Right' &&
    css`
      top: 12px;
      right: 0px;
    `}
  ${props =>
    props.position === 'Bottom Left' &&
    css`
      bottom: ${props.startWithControls ? '46px' : '12px'};
      left: 0px;
    `}
  ${props =>
    props.position === 'Bottom Center' &&
    css`
      bottom: ${props.startWithControls ? '46px' : '12px'};
      left: 50%;
      transform: translate(-50%, 0);
    `}
  ${props =>
    props.position === 'Bottom Right' &&
    css`
      bottom: ${props.startWithControls ? '46px' : '12px'};
      right: 0px;
    `}

    ${props =>
    props.bottom &&
    css`
      bottom: ${props.startWithControls
        ? `calc(${props.bottom} + 46px)`
        : `calc(${props.bottom} + 15px)`};
    `}

    & > div {
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
  }
`;

const AnnotationImageWrapper = styled.div<any>`
  width: 100%;
  height: calc(100% - ${({ hasControls }) => (hasControls ? '45px' : '0px')});
  position: absolute;
  top: 0px;
`;

const AnnotationImage = styled(Annotation)<any>`
  width: ${props =>
    `${
      props.imageWidthPercentage
        ? props.imageWidthPercentage
        : props.calcImageWidth
    }`};
  height: ${props =>
    `${
      props.imageWidthPercentage
        ? props.imageWidthPercentage
        : props.calcImageHeight
    }`};
  opacity: ${props => (props.show ? props.imageOpacity : '0')};
  display: inline-block;
  background-image: url("${props => props.src}");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 0px;

  ${props =>
    props.position === 'Top Left' &&
    css`
      top: 15px;
      left: 5px;
      background-position: top left;
    `}
  ${props =>
    props.position === 'Top Center' &&
    css`
      top: 15px;
      left: 50%;
      transform: translate(-50%, 0);
      background-position: top center;
    `}
  ${props =>
    props.position === 'Center' &&
    css`
      left: 50%;
      top: ${props.startWithControls ? 'calc(50% + 5px)' : '50%'};
      transform: translate(-50%, -50%);
      background-position: center;
    `}
  ${props =>
    props.position === 'Top Right' &&
    css`
      top: 15px;
      right: 5px;
      background-position: top right;
    `}
  ${props =>
    props.position === 'Bottom Left' &&
    css`
      bottom: ${props.startWithControls ? '5px' : '15px'};
      left: 5px;
      background-position: bottom left;
    `}
  ${props =>
    props.position === 'Bottom Center' &&
    css`
      bottom: ${props.startWithControls ? '5px' : '15px'};
      left: 50%;
      transform: translate(-50%, 0);
      background-position: bottom center;
    `}
  ${props =>
    props.position === 'Bottom Right' &&
    css`
      bottom: ${props.startWithControls ? '5px' : '15px'};
      right: 5px;
      background-position: bottom right;
    `}
  }
`;

const RETRY_DELAY = 1000; //ms

const openNewTab = (url: string, type: string) =>
  window.open(url, type, 'noopener,noreferrer');

const CAPTIONS_CACHE: { [key: string]: string } = {};

export const VideoPlayer = (props: Props) => {
  const {
    playerBackgroundColor,
    videoSource,
    playButtonPosition,
    thumbnail,
    playerIconsColor,
    small,
    videoRef,
    autoplay,
    playlist,
    preventWideSize,
    selectedAnnotationId = '',
    hideContext = false,
    startWithControls = true,
    videoId = '',
    previewAnnotation,
    height,
    borderRadius,
    width,
    margin,
    disableControls = false,
    videoChapters,
    isFreemium = false,
    style,
  } = props;
  const [playerBgColor, setPlayerBgColor] = React.useState(
    playerBackgroundColor || ''
  );
  const [playBtnPosition, setPlayBtnPosition] = React.useState(
    playButtonPosition || ''
  );
  const [playerIcColor, setPlayerIcColor] = React.useState(
    playerIconsColor || ''
  );
  const [isVideoPaused, setIsVideoPaused] = React.useState(true);
  const [isVideoMuted, setIsVideoMuted] = React.useState(false);
  const [volumeRange, setVolumeRange] = React.useState(1);
  const [duration, setDuration] = React.useState(0);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [hasControls, setHasControls] = React.useState(startWithControls);
  const [isError, setIsError] = React.useState(false);
  const [retryTimeout, setRetryTimeout] = React.useState(0);
  const [captions, setCaptions] = React.useState('');
  const [captionsEnabled, setCaptionsEnabled] = React.useState(false);
  const [videoChaptersData, setVideoChaptersData] = React.useState<
    VideoChapterItemExtended[]
  >([]);
  const [played, setPlayed] = React.useState<boolean>(false);

  const { userData } = useAuth();
  const { isAutomotive } = userData;

  const hasControlsRef = React.useRef(hasControls);

  let playlistIndex = React.useRef(0);

  const [retryCount, setRetryCount] = React.useState(0);
  const controlsRef = React.createRef<HTMLDivElement>();

  const { data: annotationsData, refetch } = useGetAnnotationsQuery(videoId);

  const annotations = annotationsData?.videoAnnotations || [];
  const companyAnnotations = annotationsData?.companyAnnotations || [];

  let videoLevelAnnotations = annotations?.filter(
    (annotation: AnnotationListItem) => annotation.videoId === parseInt(videoId)
  );

  let companyLevelAnnotations = companyAnnotations?.filter(
    (annotation: AnnotationListItem) =>
      annotation.customerId?.toString() === userData.customerId
  );

  let videoAnnotations = [];
  if (videoLevelAnnotations.length)
    videoAnnotations.push(...videoLevelAnnotations);
  if (companyLevelAnnotations.length)
    videoAnnotations.push(...companyLevelAnnotations);

  if (previewAnnotation) {
    videoAnnotations = [previewAnnotation];
  }

  const { width: playerWidth, height: playerHeight } =
    useContainerDimensions(videoRef);

  const { data: videoAppearanceData } = usePlayerDesignQuery();

  React.useEffect(() => {
    if (videoAppearanceData) {
      const newPlayerBgColor =
        playerBgColor || videoAppearanceData.playerBackgroundColor || '#FF8C21';
      setPlayerBgColor(newPlayerBgColor);

      const newPlayBtnPosition =
        playBtnPosition || videoAppearanceData.playButtonPosition || 'center';
      setPlayBtnPosition(newPlayBtnPosition);

      const newPlayerIcColor =
        playerIcColor || videoAppearanceData.playerIconsAndTextColor || 'black';
      setPlayerIcColor(newPlayerIcColor);
    }
  }, [videoAppearanceData]);

  React.useEffect(() => {
    setPlayerIcColor(playerIconsColor || '');
    setPlayBtnPosition(playButtonPosition || '');
    setPlayerBgColor(playerBackgroundColor || '');
  }, [playerIconsColor, playButtonPosition, playerBackgroundColor]);

  React.useEffect(() => {
    handleDurationChange();
  }, [videoChapters]);

  React.useEffect(() => {
    if (videoId && !previewAnnotation && !isFreemium) {
      refetch();
    }
  }, [videoId, refetch]);

  React.useEffect(() => {
    if (videoId) {
      getTranscriptionInfo(videoId)
        .then(data => {
          setCaptionsEnabled(true);
          CAPTIONS_CACHE.auto =
            data.modifiedTranscript || data.originalTranscript || '';
          const enabled =
            typeof data.enabled === 'string'
              ? Number(data.enabled)
              : data.enabled;
          if (enabled) {
            setCaptions(CAPTIONS_CACHE.auto);
          }
        })
        .catch(err => {
          setCaptionsEnabled(false);
          console.log(err);
        });
    }
  }, [videoId]);

  React.useEffect(() => {
    const { current: video } = videoRef;

    const cueChangeHandler = () => {
      const line = hasControlsRef.current ? -4 : -1;
      if (video.textTracks[0].activeCues[0]) {
        video.textTracks[0].activeCues[0].line = line;
      }

      let cues = video.textTracks[0].cues; // array of current cues
      for (var i = 0; i < cues.length; i++) {
        cues[i].line = line;
      }
    };

    if (video) {
      const file: File = new File([new Blob([captions])], 'subtitle.vtt', {
        type: 'text/vtt',
      });

      const url = window.URL.createObjectURL(file);
      if (video.textTracks.length > 0) {
        video.removeChild(video.children[1]);
      }
      const track = document.createElement('track');
      track.kind = 'captions';
      track.label = 'English';
      track.srclang = 'en';
      track.default = true;
      track.src = url;

      track.addEventListener('cuechange', function () {
        try {
          // @ts-ignore
          track.activeCues[0].line = hasControls ? -4 : -1;
        } catch (e) {}
      });

      video.appendChild(track);
      video.textTracks[0].mode = 'showing';

      video.textTracks[0].addEventListener('cuechange', cueChangeHandler);
    }

    return () => {
      if (video && video.textTracks && video.textTracks[0]) {
        video.textTracks[0].removeEventListener('cuechange', cueChangeHandler);
      }
    };
  }, [captions]);

  const togglePlay = async () => {
    const currentVideo = videoRef.current;
    if (currentVideo) {
      if (isAdminSession() && !played) {
        createVideoAccessLog(videoId);
        setPlayed(true);
      }

      // Check if video is paused or has ended
      if (isVideoPaused || currentVideo.ended) {
        if (currentVideo.ended) {
          currentVideo.currentTime = 0;
        }

        try {
          await currentVideo.play();
        } catch (error) {
          // Handle error, for example, autoplay was prevented
          console.log('Error during playback:', error);
        }
      } else {
        currentVideo.pause();
      }
    }
  };

  const contextMenu = (e: any) => {
    if (hideContext) e.preventDefault();
  };

  const fullScreenHandle = useFullScreenHandle();
  const toggleFullScreen = () => {
    if (fullScreenHandle.active) {
      return fullScreenHandle.exit();
    }
    return fullScreenHandle.enter();
  };

  React.useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
  }, [fullScreenHandle.active]);

  React.useEffect(() => {
    const video = videoRef.current;
    if (video.textTracks.length && video.textTracks[0]) {
      const line = hasControls ? -4 : -1;

      let cues = video.textTracks[0].cues; // array of current cues
      for (var i = 0; i < cues.length; i++) {
        cues[i].line = line;
      }
      if (video.textTracks[0].activeCues[0]) {
        video.textTracks[0].activeCues[0].line = line;
      }
    }
  }, [hasControls]);

  const handleMute = () => {
    const currentVideo = videoRef.current;
    if (currentVideo) {
      if (isVideoMuted) {
        setVolumeRange(1);
        setIsVideoMuted(false);
        currentVideo.volume = 1;
      } else {
        setVolumeRange(0);
        setIsVideoMuted(true);
        currentVideo.volume = 0;
      }
    }
  };

  const handleVolumeControl = (
    e: any,
    volumeRef: React.RefObject<HTMLInputElement>
  ) => {
    const volumeRefWidth = volumeRef.current && volumeRef.current.offsetWidth;
    let eventOffset = e.target.value; // e.nativeEvent.offsetX;

    // make sure that offset is never over 100 or under 0;
    if (eventOffset > 100) eventOffset = 100;
    if (eventOffset < 0) eventOffset = 0;

    if (videoRef.current && volumeRefWidth) {
      let volume = Number((eventOffset / volumeRefWidth).toFixed(2));

      if (Math.floor(videoRef.current.volume * 100) == e.target.valueAsNumber) {
        return;
      }

      setVolumeRange(volume);

      if (volume < 0) {
        volume = 0;
        setVolumeRange(0);
      }
      if (volume > 1) {
        setVolumeRange(1);
        volume = 1;
      }

      if (volume === 0) setIsVideoMuted(true);
      if (volume !== 1) setIsVideoMuted(false);

      videoRef.current.volume = volume;
    }
  };

  const scrub = (
    e: any,
    progressRef: React.RefObject<HTMLProgressElement>,
    offset: number = 0,
    duration?: number
  ) => {
    const progressElWidth =
      progressRef.current && !!progressRef.current.offsetWidth
        ? progressRef.current.offsetWidth
        : 640;
    const durationValue = duration ? duration : videoRef.current.duration;
    if (videoRef.current) {
      const scrubTime =
        (e.nativeEvent.offsetX / progressElWidth) * durationValue + offset;
      if (isFinite(scrubTime)) {
        videoRef.current.currentTime = scrubTime;
      }
    }
  };

  const handleDurationChange = () => {
    if (videoRef.current && isFinite(videoRef.current.duration)) {
      setDuration(videoRef.current.duration);
      if (videoChapters && videoChapters?.length > 0) {
        let videoChapterExtendedList: VideoChapterItemExtended[] = [];
        let lastPlaybackPosition = videoRef.current.duration;

        for (let i = videoChapters.length - 1; i >= 0; i--) {
          const progressRef = React.createRef<HTMLProgressElement>();
          const videoChapterDuration =
            lastPlaybackPosition - videoChapters[i].playbackPosition;
          const videoChapterPlaybackPosition =
            videoChapters[i].playbackPosition;
          const videoChapterMidPosition =
            videoChapterPlaybackPosition + videoChapterDuration / 2;
          let bubblePosition = Math.round(
            (videoChapterMidPosition / videoRef.current.duration) * 100
          );
          bubblePosition = bubblePosition > 90 ? 90 : bubblePosition;

          const newVideoChapter: VideoChapterItemExtended = {
            playbackPosition: videoChapterPlaybackPosition,
            title: videoChapters[i].title,
            ref: progressRef,
            duration: videoChapterDuration,
            bubblePosition: bubblePosition,
            playbackPositionText: formatTimeToString(
              videoChapterPlaybackPosition
            ),
          };
          videoChapterExtendedList.push(newVideoChapter);

          lastPlaybackPosition = videoChapterPlaybackPosition;
        }

        setVideoChaptersData([...videoChapterExtendedList.reverse()]);
      } else {
        setVideoChaptersData([]);
      }
    }
  };

  const handleError = () => {
    if (
      videoRef.current &&
      videoRef.current.networkState == HTMLMediaElement.NETWORK_NO_SOURCE &&
      !isError
    ) {
      console.log('error loading mp4');
      setIsError(true);
    }
  };

  const handleLoadedMetadata = () => {
    setIsError(false);
    clearTimeout(retryTimeout);
    // fixed issue when recording and screen video play on preview page we can skip and forward the video (SUS-466)
    if (videoRef.current && videoRef.current.duration === Infinity) {
      videoRef.current.currentTime = 1e101;
      videoRef.current.ontimeupdate = function () {
        this.ontimeupdate = () => {
          return;
        };
        if (videoRef && videoRef.current) {
          videoRef.current.currentTime = 0;
        }
      };
    }
  };

  React.useEffect(() => {
    if (isError) {
      setRetryCount(retryCount + 1);
    }
  }, [isError]);

  React.useEffect(() => {
    if (retryCount > 0 && isError) {
      clearTimeout(retryTimeout);
      const timeout = setTimeout(
        setRetryCount,
        RETRY_DELAY * retryCount,
        retryCount + 1
      );
      setRetryTimeout(timeout);
      videoRef.current?.load();
    }
  }, [retryCount]);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener(
        'durationchange',
        handleDurationChange,
        true
      );
      videoRef.current.addEventListener(
        'loadedmetadata',
        handleLoadedMetadata,
        true
      );
      videoRef.current.addEventListener('error', handleError, true);
      videoRef.current.addEventListener(
        'pause',
        () => setIsVideoPaused(true),
        true
      );
      videoRef.current.addEventListener(
        'play',
        () => setIsVideoPaused(false),
        true
      );

      if (controlsRef && controlsRef.current) {
        videoRef.current.addEventListener('mouseenter', showControls);
        videoRef.current.addEventListener('mouseleave', hideControls);
        controlsRef.current.addEventListener('mouseenter', showControls);
        controlsRef.current.addEventListener('mouseleave', hideControls);
      }
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          'durationchange',
          handleDurationChange,
          true
        );
        videoRef.current.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata,
          true
        );
        videoRef.current.removeEventListener('error', handleError, true);
      }
    };
  }, [videoRef]);

  const hideControls = () => {
    if (!videoRef.current) {
      return;
    }
    if (videoRef.current.paused && !!videoRef?.current?.currentTime) {
      return;
    }
    setHasControls(false);
    hasControlsRef.current = false;
  };

  const showControls = () => {
    if (disableControls) {
      return;
    }
    setHasControls(true);
    hasControlsRef.current = true;
  };

  React.useEffect(() => {
    if (playlist && playlist.length > 0) {
      playlistIndex.current = 0;
      setCurrentTime(0);
      setIsVideoPaused(true);
    }
  }, [playlist]);

  React.useEffect(() => {
    const handleTimeUpdate = () => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.currentTime);
        if (playlistIndex && playlist && playlist[playlistIndex.current]) {
          if (
            videoRef.current.currentTime >=
            playlist[playlistIndex.current].endTime
          ) {
            const videoEndEvent = new Event('ended');
            videoRef.current.dispatchEvent(videoEndEvent);
          }
        }
      }
    };
    const handleVideoEnded = () => {
      if (videoRef.current && playlist) {
        playlistIndex.current++;
        if (
          playlistIndex.current < playlist.length &&
          playlist[playlistIndex.current].videoSource
        ) {
          videoRef.current.poster =
            playlist[playlistIndex.current].autogeneratedThumbnail;
          let time = '';
          const start = playlist[playlistIndex.current].startTime;
          const end = playlist[playlistIndex.current].endTime;
          if (start || end) {
            time = `#t=${start ? start : 0}${end ? ',' + end : ''}`;
          }
          let newSrc =
            ('' + playlist[playlistIndex.current].videoSource).split('#t=')[0] +
            time;
          videoRef.current.src = newSrc;
          videoRef.current.play().catch((err: Error) => console.log(err));
        }
      }
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleTimeUpdate, true);
    }
    if (videoRef.current) {
      videoRef.current.addEventListener('ended', handleVideoEnded, true);
    }
    if (videoRef.current && autoplay) {
      videoRef.current.play().catch((err: Error) => console.log(err));
      if (isAdminSession() && !played) {
        createVideoAccessLog(videoId);
        setPlayed(true);
      }
      setIsVideoPaused(false);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          'timeupdate',
          handleTimeUpdate,
          true
        );
        videoRef.current.removeEventListener('ended', handleVideoEnded, true);
      }
    };
  }, [videoRef]);

  const setPlayBack = (rate: number) => {
    videoRef.current.playbackRate = rate;
  };

  const setTranslate = (language: string) => {
    if (!videoId) {
      return;
    }

    if (language === 'off') {
      setCaptions('');
      return;
    }

    if (language in CAPTIONS_CACHE) {
      setCaptions(CAPTIONS_CACHE[language]);
      return;
    }

    getTranslationInfo(videoId, userData.userId, language)
      .then(data => {
        const captionHeader = `WEBVTT FILE`;
        const placeholder = `{xazqby}`;

        if (data && data.translatedText) {
          if (data.translatedText.substring(0, 11) === 'वेबवीटीटी\n\n') {
            data.translatedText =
              'WEBVTT\n\n' + data.translatedText.substring(11);
          }
          let result = data.translatedText.replaceAll(
            placeholder,
            captionHeader
          );
          result = result.replaceAll(' .', '.');
          result = result.replaceAll('—>', '-->');

          CAPTIONS_CACHE[language] = result;
          setCaptions(result);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const newColor = hex.rgb(playerBgColor) || [];

  const determineImageDimensions = (annotation: any) => {
    const MAX_IMAGE_WIDTH = fullScreenHandle.active ? 340 : 416;

    annotation.calcImageWidth =
      (annotation.imageWidth / MAX_IMAGE_WIDTH) * playerWidth + 'px';
    annotation.calcImageHeight =
      (annotation.imageHeight / MAX_IMAGE_HEIGHT) * (playerHeight - 65) + 'px';

    if (annotation.calcImageWidth > playerWidth) {
      annotation.calcImageWidth = playerWidth - 20 + 'px';
    }

    if (annotation.calcImageHeight > playerHeight) {
      annotation.calcImageHeight = playerHeight - 20 + 'px';
    }
  };

  return (
    <PlayerLayout
      className='video-player-layout'
      small={small}
      height={height}
      width={width}
      margin={margin}
      disablePointerEvents={disableControls && !isFreemium}
      style={style}
    >
      <Figure small={small} preventWideSize={preventWideSize}>
        <FullscreenWrapper handle={fullScreenHandle}>
          <Video
            onContextMenu={contextMenu}
            borderRadius={borderRadius}
            key={videoSource}
            poster={isError ? '' : thumbnail}
            {...(!isSafari && { preload: 'metadata' })}
            ref={videoRef}
            onClick={togglePlay}
            isFullScreen={fullScreenHandle.active}
            height={height || 'auto'}
          >
            <source
              key={retryCount + playlistIndex.current + videoSource}
              src={videoSource}
              type='video/mp4'
            />
          </Video>
          {videoAnnotations.map((annotation: any) => {
            let show;
            if (annotation.customerId) show = true;
            else
              show =
                (parseFloat(annotation.startTime) < currentTime &&
                  parseFloat(annotation.endTime) > currentTime) ||
                (isVideoPaused &&
                  (!selectedAnnotationId ||
                    annotation.annotationId === selectedAnnotationId));

            const cta = addCallToAction(annotation);

            let bottomPosition = '50px';

            if (annotation.type === 'text') {
              return (
                <>
                  {annotation.text && (
                    <AnnotationText
                      {...annotation}
                      startWithControls={hasControls}
                      isFullScreen={fullScreenHandle.active}
                      show={show}
                      bottomPosition={bottomPosition}
                      key={annotation.annotationId + 'text'}
                      onClick={() => {
                        if (cta.href) {
                          openNewTab(cta.href, cta.target);
                        }
                      }}
                      bottom={
                        annotation.text2 &&
                        (Number(annotation.borderWidth) || 0) + 40 + 'px'
                      }
                      padding={isAutomotive && '8px'}
                    >
                      {annotation.isLinkEnabled && (
                        <LinkWrapper>
                          <IoMdLink
                            color={
                              annotation.textColor || theme.palette.black_1_100
                            }
                            size='14px'
                          />
                        </LinkWrapper>
                      )}

                      <div>{annotation.text}</div>
                    </AnnotationText>
                  )}
                  {!annotation.text && !annotation.text2 && (
                    <AnnotationText
                      {...annotation}
                      bottomPosition={bottomPosition}
                      isFullScreen={fullScreenHandle.active}
                      startWithControls={hasControls}
                      show={show}
                    >
                      Annotation Text
                    </AnnotationText>
                  )}
                  {annotation.text2 && (
                    <AnnotationText
                      {...annotation}
                      startWithControls={hasControls}
                      isFullScreen={fullScreenHandle.active}
                      show={show}
                      bottomPosition={bottomPosition}
                      key={annotation.annotationId + 'text2'}
                      padding={isAutomotive && '8px'}
                      onClick={() => {
                        if (cta.href) {
                          openNewTab(cta.href, cta.target);
                        }
                      }}
                    >
                      {annotation.isLinkEnabled && (
                        <LinkWrapper>
                          <IoMdLink
                            color={
                              annotation.textColor || theme.palette.black_1_100
                            }
                            size='14px'
                          />
                        </LinkWrapper>
                      )}

                      <div>{annotation.text2}</div>
                    </AnnotationText>
                  )}
                </>
              );
            }

            if (annotation.type === 'image' || !!annotation.customerId) {
              determineImageDimensions(annotation);

              // set image opacity to 0 if annotation is hidden
              let imageOpacity =
                annotation.imageOpacity !== '0%'
                  ? annotation.imageOpacity
                  : '100%';
              if (!show) {
                imageOpacity = '0%';
              }

              return (
                <AnnotationImageWrapper
                  hasControls={hasControls}
                  key={annotation.annotationId}
                >
                  <AnnotationImage
                    {...annotation}
                    isFullScreen={fullScreenHandle.active}
                    show={show}
                    src={annotation.imageUrl}
                    bottomPosition={bottomPosition}
                    imageOpacity={imageOpacity}
                    onClick={() => {
                      if (cta.href) {
                        openNewTab(cta.href, cta.target);
                      }
                    }}
                    startWithControls={hasControls}
                  />
                </AnnotationImageWrapper>
              );
            }

            return null;
          })}

          {isError && (
            <Overlay>
              <LoadingIndicator
                height={'100%'}
                isLoading={isError}
                text={'processing...'}
              />
            </Overlay>
          )}
          {/*Customizable video controls */}
          {!disableControls && (
            <Controls
              ref={controlsRef}
              r={newColor[0]}
              g={newColor[1]}
              b={newColor[2]}
              hex={playerBgColor}
              small={small}
              isVisible={hasControls}
            >
              <VideoControls
                isVideoPaused={isVideoPaused}
                togglePlay={togglePlay}
                isVideoMuted={isVideoMuted}
                handleMute={handleMute}
                handleFullScreen={toggleFullScreen}
                setPlayBack={setPlayBack}
                setTranslate={setTranslate}
                duration={duration}
                currentTime={currentTime}
                handleVolumeControl={handleVolumeControl}
                volumeRange={volumeRange}
                playerIconsColor={playerIcColor}
                small={small}
                scrub={scrub}
                playerBackgroundColor={playerBgColor}
                captionsEnabled={captionsEnabled}
                captions={captions}
                isVisible={hasControls}
                videoRef={videoRef}
                videoChaptersData={videoChaptersData}
              />
            </Controls>
          )}
        </FullscreenWrapper>
        {/* On screen button */}
        {(!disableControls || isFreemium) && (
          <OnScreenPlayButton
            playerBackgroundColor={playerBgColor}
            playButtonPosition={playBtnPosition}
            isVideoPaused={isVideoPaused}
            togglePlay={togglePlay}
            playerIconsColor={playerIcColor}
            small={small}
            isFreemium={isFreemium}
          />
        )}
      </Figure>
    </PlayerLayout>
  );
};
