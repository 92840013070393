import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';

const Message = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: () => void;
  onSubmit: () => void;
  message: string;
  buttonText: string;
};

export const ConfirmDelete = ({
  handleModalClose,
  onSubmit,
  message,
  buttonText,
}: Props) => {
  return (
    <>
      <Message>
        Are you sure you want to <b>{message}</b>
        <br />
        This action can’t be undone.
      </Message>
      <ContentFooter>
        <Button
          text={'Cancel'}
          variant='secondary'
          onClick={handleModalClose}
        />
        <Button text={buttonText} variant='red' onClick={onSubmit} />
      </ContentFooter>
    </>
  );
};
