import { EXPRESS_API } from 'configs/api/expressApi';

export const updateOnboardingStatus = async (userId: string) => {
  const response = await EXPRESS_API.patch(`/users/${userId}/showWelcome`);
  return response.data;
};

// added new param userId for SUS-521
export const checkUsername = async (
  username: string,
  userId: string | undefined
) => {
  const response = await EXPRESS_API.get(`users/username-check`, {
    params: { username, userId },
  });
  return response.data;
};

export const deleteUserAccount = async (userId: string) => {
  const response = await EXPRESS_API.delete(`users/${userId}`);
  return response.data;
};

export const fetchDiscountCoupons = async (params: {
  code: string;
  testmode: number;
}) => {
  const { code, testmode = 0 } = params;
  const response = await EXPRESS_API.get(`register/discount-coupons`, {
    params: { code, testmode },
  });
  return response.data;
};

export const postToCheckout = async (data: any) => {
  const response = await EXPRESS_API.post('/register/stripe', data);
  return response.data;
};

export const getProfileData = async (userId: any) => {
  const response = await EXPRESS_API.get(`/users/${userId}`);
  return response.data;
};

export const fetchUserData = async () => {
  const response = await EXPRESS_API.get('/auth/user');
  return response.data;
};

export const logout = async (isSubuser: boolean) => {
  const response = await EXPRESS_API.delete(`/auth/logout`, {
    withCredentials: true,
    params: { isSubuser },
  });
  return response.data;
};

export const createUser = async (payload: any) => {
  const response = await EXPRESS_API.post(`/customers`, payload, {
    withCredentials: true,
  });
  return response.data;
};

const roles: { [key: string]: [] } = {};

export const getUsersByAutomotiveRoles = async ({
  customerId,
  role,
}: {
  customerId: string;
  role: string;
}) => {
  if (roles[role]) {
    return roles[role];
  }
  const response = await EXPRESS_API.get(
    `/users/${customerId}/automotive-users`,
    {
      params: { role },
    }
  );
  roles[role] = response.data;
  return response.data;
};

export const inviteUser = async (payload: any) => {
  const response = await EXPRESS_API.post(`/users/resend-invitation`, payload, {
    withCredentials: true,
  });
  return response.data;
};
