import { useQuery } from 'react-query';
import { videoActivityKeys } from './videoActivityKeys';
import { useToastError } from 'lib/hooks/useToastError';
import { EXPRESS_API } from 'configs/api/expressApi';

const getVideoActivity = async (videoId: string) => {
  const response = await EXPRESS_API.get(`/video-activity`, {
    params: {
      videoId: videoId,
    },
  });
  return response.data;
};
export const useGetVideoActivities = (videoId: string) => {
  const { showError } = useToastError();
  return useQuery(
    [videoActivityKeys.video_activities(), videoId],
    () => getVideoActivity(videoId),
    {
      enabled: false,
      onError: showError,
    }
  );
};
