import { IRepairOrderMutationData } from './types';
import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { repairOrderKeys } from './repairOrderKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const updateRepairOrderQuoteItems = async ({
  data,
  repairOrderId,
}: IRepairOrderMutationData) => {
  return (
    await EXPRESS_API.put(
      `repair-orders/${repairOrderId}/quote-items-update`,
      data
    )
  ).data;
};

export const useUpdateRepairOrderQuoteItems = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRepairOrderQuoteItems, {
    onSuccess: res => {
      queryClient.invalidateQueries(
        repairOrderKeys.repairOrder(res.repairOrderId)
      );
      successToast({
        title: `Repair order successfully updated.`,
      });
    },
  });
};
