import { useMutation, useQueryClient } from 'react-query';
import { annotationsKeys } from './annotationsKeys';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';

type DeleteAnnotationParams = {
  videoId: string;
  annotationId: string;
  isCompanyAnnotation?: string;
};

const deleteAnnotation = async ({
  videoId,
  annotationId,
  isCompanyAnnotation,
}: DeleteAnnotationParams) => {
  await EXPRESS_API.delete(`/videos/${videoId}/annotations/${annotationId}`, {
    params: { isCompanyAnnotation },
  });

  return { annotationId };
};

export const useDeleteAnnotationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAnnotation, {
    onSuccess: () => {
      queryClient.invalidateQueries(annotationsKeys.annotations());
      successToast({ title: 'Annotation deleted successfully!' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred deleting the annotation, please try again later!',
      });
    },
  });
};
