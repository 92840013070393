import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { NewModal } from 'lib/components';
import { CommonTypography } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { requestAddon } from 'lib/api/addonsApi';
import cdsCompanySize from 'assets/images/upgradeModal/cdsCompany.svg';
import covideoCompanySize from 'assets/images/upgradeModal/covideoCompany.svg';
import cdsSuccess from 'assets/images/upgradeModal/cdsSuccess.svg';
import covideoSuccess from 'assets/images/upgradeModal/covideoSuccess.svg';
import { useIntercom } from 'react-use-intercom';
import { companySizeOptionsForCds } from 'app/pages/onboardingFreemium/const';

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
  width: 100%;
  gap: 20px;
`;

const Content = styled.div``;

export const Label = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin: 16px 0 8px 0;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  max-width: 404px;
`;

export const Image = styled.img`
  width: 100%;
`;

type SelectCompanySizeItemProps = {
  selected?: boolean;
  selectedColor: string;
};
const SelectCompanySizeOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

const SelectCompanySizeItem = styled.div<SelectCompanySizeItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  background: ${props =>
    props.selected ? props.selectedColor : theme.palette.blue05};
  border: 1px solid ${theme.palette.covideoGray40};
  border-radius: 5px;
  flex: none;
  &:hover {
    cursor: pointer;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    flex: 1;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    min-width: 58px;
  }
  span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    white-space: pre;
    color: ${props =>
      props.selected ? theme.palette.white : theme.palette.covideoGray60};
  }
`;

const MessageWrapper = styled.div`
  max-width: 404px;
`;

export const Message = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: ${theme.palette.covideoGray100};
  padding: 10px 0 10px 0;
`;

type Props = {
  firstName: string;
  isAutomotive?: boolean;
  handleModalClose(success: boolean): void;
};

export const UpgradeRequestModal = ({
  firstName,
  isAutomotive,
  handleModalClose,
}: Props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [isRequestSent, setIsRequestSent] = React.useState(false);
  const [companySize, setCompanySize] = React.useState('');
  const { hide, show } = useIntercom();

  const themes = useTheme();
  const sendUpgradeRequest = async () => {
    setLoadingModal(true);
    try {
      const res = await requestAddon({
        subject: 'Upgrade your Covideo plan',
        notes: `Upgrade request for team size: ${companySize}`,
        email: true,
        phone: true,
        isBilling: false,
        isUpgradeRequest: true,
      });

      if (res) {
        setIsRequestSent(true);
        successToast({
          title: 'Upgrade Request Sent',
          additionalInfo:
            'You have sent a request for upgrade. Our team will reach out with next steps.',
        });
      }
    } catch (error) {
      errorToast({ title: `Error while upgrading. Try again later.` });
      console.log('Error', error);
      return;
    }
    setLoadingModal(false);
  };

  const firstStep = () => {
    return (
      <Content>
        <Message>Let's create your custom package.</Message>
        <ImageWrapper>
          <Image
            src={!!isAutomotive ? cdsCompanySize : covideoCompanySize}
            alt='icon'
          />
        </ImageWrapper>
        <Label>What's your team size?</Label>
        <SelectCompanySizeOptionsWrapper>
          {companySizeOptionsForCds.map((item, index) => (
            <SelectCompanySizeItem
              key={`company-select-${index}`}
              onClick={() => setCompanySize(item)}
              selected={item === companySize}
              selectedColor={themes.colors.primary[100]}
            >
              <span>{item}</span>
            </SelectCompanySizeItem>
          ))}
        </SelectCompanySizeOptionsWrapper>
        <SubmitButtonWrapper>
          <Button
            text='Submit'
            disabled={companySize === '' || loadingModal}
            onClick={sendUpgradeRequest}
            variant='primary'
          />
        </SubmitButtonWrapper>
      </Content>
    );
  };

  const secondStep = () => {
    return (
      <Content>
        <ImageWrapper>
          <Image
            src={!!isAutomotive ? cdsSuccess : covideoSuccess}
            alt='icon'
          />
        </ImageWrapper>
        <MessageWrapper>
          <Message>
            We can't wait for you to get started! One of our team members will
            be reaching out to assist you with next steps.
          </Message>

          <Message>
            Questions? Use our chat bubble in the lower right-hand corner to
            connect with a live rep.
          </Message>
        </MessageWrapper>

        <SubmitButtonWrapper>
          <Button
            text='Close'
            onClick={() => {
              hide();
              handleModalClose(true);
            }}
            variant='secondary'
          />
          <Button
            text='Chat'
            onClick={() => {
              show();
              handleModalClose(true);
            }}
          />
        </SubmitButtonWrapper>
      </Content>
    );
  };
  return (
    <NewModal
      headerText={
        isRequestSent ? `Thank you, ${firstName}!` : 'Ready to get started?'
      }
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose(false)}
      style={{
        content: {
          minWidth: '404px',
          paddingLeft: '32px',
        },
      }}
    >
      {isRequestSent ? secondStep() : firstStep()}
    </NewModal>
  );
};
