import { ICombinedUserData } from 'lib/context';
import { checkIfFreemium } from 'lib/utils/functions';
import { NOTIFICATION_TYPES } from './types';

export const getNotificationFilterOptions = (userData: ICombinedUserData) => {
  const isFreemium = checkIfFreemium(userData);

  const vdpField = userData.isVdpAllowed
    ? [
        {
          value: NOTIFICATION_TYPES.PUSHED_TO_VDP,
          label: 'Video Pushed to VDP',
        },
      ]
    : [];

  const salesField = userData.isAutomotiveSalesRole
    ? [
        {
          value: NOTIFICATION_TYPES.SALES,
          label: 'Test Drive Schedule',
        },
      ]
    : [];

  const serviceField = userData.isAutomotiveServiceRole
    ? [
        {
          value: NOTIFICATION_TYPES.VIDEO_REQUEST,
          label: 'Video Requests / Service Videos',
        },
        {
          value: NOTIFICATION_TYPES.QUOTES,
          label: 'Quote Updates',
        },
      ]
    : [];

  const videoDeletesField =
    userData.customer.videoDeletionAccess === '1'
      ? [
          {
            value: NOTIFICATION_TYPES.VIDEO_DELETE,
            label: 'Video Deletes',
          },
        ]
      : [];

  if (isFreemium) {
    return [
      {
        value: 'ALL',
        label: 'All notifications',
      },
      {
        value: NOTIFICATION_TYPES.EMAIL_NOT_DELIVERED,
        label: 'Email not Delivered',
      },
      {
        value: NOTIFICATION_TYPES.VIEW,
        label: 'Video Viewed',
      },
    ];
  }

  return [
    {
      value: 'ALL',
      label: 'All notifications',
    },
    {
      value: NOTIFICATION_TYPES.EMAIL_NOT_DELIVERED,
      label: 'Email not Delivered',
    },
    {
      value: NOTIFICATION_TYPES.EMAIL_OPENED,
      label: 'Email Opened',
    },
    {
      value: NOTIFICATION_TYPES.VIEW,
      label: 'Video Viewed',
    },
    {
      value: NOTIFICATION_TYPES.CTA,
      label: 'CTA Clicked',
    },
    {
      value: NOTIFICATION_TYPES.REPLY,
      label: 'Video Replies',
    },
    {
      value: NOTIFICATION_TYPES.REACTIONS_COMMENTS,
      label: 'Comments and Reactions',
    },
    ...serviceField,
    ...vdpField,
    ...salesField,
    {
      value: NOTIFICATION_TYPES.FILES,
      label: 'File Shared',
    },
    {
      value: NOTIFICATION_TYPES.BOARDS,
      label: 'Board Shared',
    },
    {
      value: NOTIFICATION_TYPES.MESSAGES,
      label: 'Messages',
    },
    ...videoDeletesField,
    {
      value: NOTIFICATION_TYPES.REPORTS,
      label: 'Reports',
    },
  ];
};

export const NOTIFICATION_EMAILS = {
  FILES: 'filesEmail',
  BOARDS: 'boardsEmail',
  QUOTE_UPDATE: 'quoteUpdateEmail',
  REACTIONS: 'reactionsEmail',
  TEST_DRIVE: 'testDriveScheduleEmail',
  VDP: 'pushedToVdpEmail',
  CTAS: 'ctaClicksEmail',
  VIDEO_REPLY: 'videoReplyEmail',
  VIDEO_VIEW: 'videoViewsEmail',
  EMAIL_STATUS: 'videoEmailDeliveryStatusEmail',
  LIVE: 'liveEmail',
  EMAIL_OPEN_STATUS: 'videoEmailOpenStatusEmail',
  VIDEO_REQUEST: 'videoRequestEmail',
};
export const NOTIFICATION_IN_APP = {
  FILES: 'filesInApp',
  BOARDS: 'boardsInApp',
  QUOTE_UPDATE: 'quoteUpdateInApp',
  TEST_DRIVE: 'testDriveScheduleInApp',
  REACTIONS: 'reactionsInApp',
  VDP: 'pushedToVdpInApp',
  CTAS: 'ctaClicksInApp',
  VIDEO_REPLY: 'videoReplyInApp',
  VIDEO_VIEW: 'videoViewsInApp',
  EMAIL_STATUS: 'videoEmailDeliveryStatusInApp',
  LIVE: 'liveInApp',
  EMAIL_OPEN_STATUS: 'videoEmailOpenStatusInApp',
  VIDEO_REQUEST: 'videoRequestInApp',
};

export const NOTIFICATION_CATEGORIES = {
  VIDEO: {
    categoryName: 'Videos',
    notifications: [
      {
        id: 2,
        name: 'Email not Delivered',
        valueEmail: NOTIFICATION_EMAILS.EMAIL_STATUS,
        valueInApp: NOTIFICATION_IN_APP.EMAIL_STATUS,
      },
      {
        id: 16,
        name: 'Email Opened',
        valueEmail: NOTIFICATION_EMAILS.EMAIL_OPEN_STATUS,
        valueInApp: NOTIFICATION_IN_APP.EMAIL_OPEN_STATUS,
      },
      {
        id: 3,
        name: 'Video Viewed',
        valueEmail: NOTIFICATION_EMAILS.VIDEO_VIEW,
        valueInApp: NOTIFICATION_IN_APP.VIDEO_VIEW,
      },
      {
        id: 4,
        name: 'CTA Clicked',
        valueEmail: NOTIFICATION_EMAILS.CTAS,
        valueInApp: NOTIFICATION_IN_APP.CTAS,
      },
      {
        id: 9,
        name: 'Video Replies',
        valueEmail: NOTIFICATION_EMAILS.VIDEO_REPLY,
        valueInApp: NOTIFICATION_IN_APP.VIDEO_REPLY,
      },
      {
        id: 10,
        name: 'Comments and Reactions',
        valueEmail: NOTIFICATION_EMAILS.REACTIONS,
        valueInApp: NOTIFICATION_IN_APP.REACTIONS,
      },
    ],
  },
  SERVICE: {
    categoryName: 'Service',
    notifications: [
      {
        id: 17,
        name: 'Video Requests / Service Videos',
        valueEmail: NOTIFICATION_EMAILS.VIDEO_REQUEST,
        valueInApp: NOTIFICATION_IN_APP.VIDEO_REQUEST,
      },
      {
        id: 4,
        name: 'Quote Updates',
        valueEmail: NOTIFICATION_EMAILS.QUOTE_UPDATE,
        valueInApp: NOTIFICATION_IN_APP.QUOTE_UPDATE,
      },
    ],
  },
  SALES: {
    categoryName: 'Sales',
    notifications: [
      {
        id: 11,
        name: 'Pushed To VDP',
        valueEmail: NOTIFICATION_EMAILS.VDP,
        valueInApp: NOTIFICATION_IN_APP.VDP,
      },
      {
        id: 5,
        name: 'Test Drive Schedule',
        valueEmail: NOTIFICATION_EMAILS.TEST_DRIVE,
        valueInApp: NOTIFICATION_IN_APP.TEST_DRIVE,
      },
    ],
  },
  FILE_AND_BOARDS: {
    categoryName: 'Files and Boards',
    notifications: [
      {
        id: 5,
        name: 'File Shared',
        valueEmail: NOTIFICATION_EMAILS.FILES,
        valueInApp: NOTIFICATION_IN_APP.FILES,
      },
      {
        id: 6,
        name: 'Board Shared',
        valueEmail: NOTIFICATION_EMAILS.BOARDS,
        valueInApp: NOTIFICATION_IN_APP.BOARDS,
      },
    ],
  },
};

export const getNotificationCategoriesWithNotifications = (
  userData: ICombinedUserData
) => {
  const isFreemium = checkIfFreemium(userData);

  if (isFreemium) {
    const category = { ...NOTIFICATION_CATEGORIES.VIDEO };
    const notifications = category.notifications.filter(item => {
      return item.id !== 4 && item.id !== 9 && item.id !== 10;
    });
    category.notifications = notifications;
    return [{ ...category }];
  }

  const { isAutomotive, isAutomotiveSalesRole, isAutomotiveServiceRole } =
    userData;
  if (isAutomotive && isAutomotiveServiceRole) {
    return [
      NOTIFICATION_CATEGORIES.VIDEO,
      NOTIFICATION_CATEGORIES.SERVICE,
      NOTIFICATION_CATEGORIES.FILE_AND_BOARDS,
    ];
  }

  if (isAutomotive && isAutomotiveSalesRole) {
    const vdpField = userData.isVdpAllowed;
    const salesField = { ...NOTIFICATION_CATEGORIES.SALES };
    if (!vdpField) {
      salesField.notifications = salesField.notifications.filter(
        notification => notification.id !== 11
      );
    }
    return [
      NOTIFICATION_CATEGORIES.VIDEO,
      salesField,
      NOTIFICATION_CATEGORIES.FILE_AND_BOARDS,
    ];
  }

  return [
    NOTIFICATION_CATEGORIES.VIDEO,
    NOTIFICATION_CATEGORIES.FILE_AND_BOARDS,
  ];
};
