import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const IntegrationCard = styled.div`
  display: flex;
  width: 277px;
  min-height: 156px;
  max-height: 230px;
  max-width: 309px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex-shrink: 0;
  padding: 16px;
  border-radius: 6px;
  background: ${theme.palette.white};
  cursor: pointer;
  box-shadow:
    0px 4px 8px 0px rgba(66, 79, 104, 0.03),
    0px 0px 2px 0px rgba(66, 79, 104, 0.08);
  transition: all 0.2s ease-in-out;
  position: relative;
`;

export const ImageContainer = styled.div`
  height: 64px;
`;
export const Image = styled.img`
  max-height: 64px;
  max-width: 250px;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
export const Title = styled.div`
  color: ${theme.palette.covideoBlue100};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
export const Subtitle = styled.div`
  color: ${theme.palette.neutral100};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  height: 40px;
`;
export const Tags = styled.div`
  display: flex;
  justify-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`;
