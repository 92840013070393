import React, { useState } from 'react';
import { FrequencyContainer } from './style';
import { FREQUENCY_TYPE } from './constants';
import { SelectFrequency } from './SelectFrequency';
import { useFormikContext } from 'formik';
import { CustomReportModalFormikValues } from '../types';
import { getDefaultFrequencyType } from '../timeframe/utils';
import { DailyFrequency } from './DailyFrequency';
import { MonthlyFrequency } from './MonthlyFrequency';
import { WeeklyFrequency } from './WeeklyFrequency';

interface IProps {
  updateRangeValue: (rangeValue: string) => void;
}

export const CustomReportFrequency = ({ updateRangeValue }: IProps) => {
  const { values } = useFormikContext<CustomReportModalFormikValues>();

  const [frequencyType, setFrequencyType] = useState<FREQUENCY_TYPE>(
    getDefaultFrequencyType(values.recurrings.frequency)
  );

  return (
    <>
      <SelectFrequency
        updateRangeValue={updateRangeValue}
        setFrequencyType={setFrequencyType}
        frequencyType={frequencyType}
      />
      <FrequencyContainer>
        {frequencyType === FREQUENCY_TYPE.DAILY && <DailyFrequency />}
        {frequencyType === FREQUENCY_TYPE.WEEKLY && <WeeklyFrequency />}
        {frequencyType === FREQUENCY_TYPE.MONTHLY && <MonthlyFrequency />}
      </FrequencyContainer>
    </>
  );
};
