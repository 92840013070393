import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components/macro';

const Container = styled.div`
  height: 100vh;
  /* enable smooth (inertial) scroll on mobile safari */
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  /* the smallest screen we support */
  min-width: 320px;
`;

type ViewportProps = {
  children: React.ReactNode;
};

export const Viewport = ({ children, ...rest }: ViewportProps) => {
  return ReactDOM.createPortal(
    <Container {...rest}>{children}</Container>,
    document.getElementById('root') as HTMLElement
  );
};
