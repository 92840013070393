import { ILesaItem } from 'lib/api/lesa/useLesaTvQuery';
import React from 'react';
import styled from 'styled-components/macro';
import { LesaTable } from '../lesaModal/LesaTable';

const Content = styled.div`
  padding-top: 32px;
`;

interface Props {
  selectLesaVideo?: (url: string) => void;
}

export const TabLesa = ({ selectLesaVideo }: Props) => {
  const handleOnSelectVideo = (lesaItem: ILesaItem) => {
    const videoUrl = lesaItem?.video?.url;
    if (videoUrl) {
      selectLesaVideo?.(videoUrl);
    }
  };
  return (
    <Content>
      <LesaTable onSelectVideo={handleOnSelectVideo} showAddToCovideo={false} />
    </Content>
  );
};
