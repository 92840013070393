import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { playerDesignKeys } from './playerDesignKey';

export interface VideoAppearanceData {
  playerBackgroundColor: string;
  playerIconsAndTextColor: string;
  playButtonPosition: string;
}

export const getPlayerDesign = async () => {
  const response = await EXPRESS_API.get(`/player-design`);
  return response.data;
};

const fetchVideoAppearance = async (): Promise<VideoAppearanceData> => {
  const response = await getPlayerDesign();
  return response;
};

export const usePlayerDesignQuery = () => {
  return useQuery<VideoAppearanceData, Error>(
    playerDesignKeys.all(),
    fetchVideoAppearance
  );
};
