import { EXPRESS_API } from 'configs/api/expressApi';

interface AutoMotiveResponse {
  bgColor: string;
  additionalTextForSales: string;
  additionalTextColorForSales: string;
  additionalTextForService: string;
  additionalTextColorForService: string;
  imageUrl: string;
}
interface AutoMotivePayload {
  bgColor: string;
  additionalTextForSales: string | null;
  additionalTextColorForSales: string;
  additionalTextForService: string | null;
  additionalTextColorForService: string;
  imageUrl: string | null;
}

export const getAutoMotiveLanding = async (): Promise<AutoMotiveResponse> => {
  const response = await EXPRESS_API.get('/automotive-landing');
  return response.data;
};

export const updateAutoMotiveLanding = async (
  payload: AutoMotivePayload
): Promise<AutoMotiveResponse> => {
  const response = await EXPRESS_API.put('/automotive-landing', payload);
  return response.data;
};
