import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal, NewTextInput, DefaultLabel } from 'lib/components';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

type Props = {
  disabled?: boolean;
  title?: string;
  whiteButtonText?: string;
  redButtonText?: string;
  text?: string;
  text1?: React.ReactNode;
  hasConfirm?: boolean;
  onClickWhiteButton: () => void;
  onClickRedButton: () => void;
  handleModalClose: () => void;
};

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

const ButtonsWrapper = styled.section`
  display: flex;
  gap: 12px;
  flex-direction: row-reverse;
  margin-top: 48px;
`;

const DELETE = 'DELETE';

export const ModalDeleteCaptions = ({
  title = '',
  text1,
  text = '',
  whiteButtonText = 'Keep video',
  redButtonText = 'Delete video',
  hasConfirm = false,
  onClickWhiteButton,
  onClickRedButton,
  handleModalClose,
}: Props) => {
  const [deleteConfirm, setDeleteConfirm] = React.useState('');

  return (
    <Modal disableFullScreen={true} style={{ position: 'fixed' }}>
      <ModalItem>
        <Header>
          {title}
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Content>
          {text1}
          <p>{text}</p>
          {hasConfirm && (
            <>
              <DefaultLabel>{`Enter '${DELETE}' to confirm`}</DefaultLabel>
              <NewTextInput
                style={{ marginTop: 4 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDeleteConfirm(e.target.value)
                }
                value={deleteConfirm}
                placeholder={DELETE}
                autoFocus={true}
              />
            </>
          )}
        </Content>
        <ButtonsWrapper>
          <Button
            variant='red'
            text={redButtonText}
            onClick={onClickRedButton}
          />
          <Button
            variant='secondary'
            text={whiteButtonText}
            onClick={onClickWhiteButton}
          />
        </ButtonsWrapper>
      </ModalItem>
    </Modal>
  );
};
