import React from 'react';
import {
  MdChat,
  MdChatBubbleOutline,
  MdDeleteForever,
  MdNoAccounts,
  MdRefresh,
} from 'react-icons/md';
import { Search } from 'lib/components';

import {
  ImageWrapper,
  Messages,
  MessagesInfoWrapper,
  MsgData,
  MsgTime,
  MsgTitle,
  NoMatchingConversations,
  RefreshIconWrapper,
  SearchNewWrapper,
  UserListBox,
} from '../styles/UsersConversationsLayout';
import { useHistory, useParams } from 'react-router';
import { calculateDiff } from 'lib/utils/time';
import {
  CONVERSATION_ROUTE_TYPES,
  MatchParamsWithId,
  ModalsType,
} from '../conversations.types';
import {
  deleteHTMLTags,
  getConversationHeader,
  isAssignedToYou,
  isNotAssignedToYou,
  isUnassigned,
} from '../utils/helper';
import { useAuth } from 'lib/context';
import styled from 'styled-components/macro';
import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';
import { InfiniteData } from 'react-query';
import { IConversation } from 'lib/api/conversations/getConversations';
import { useMarkAsReadConversationMutation } from 'lib/api/conversations/setConversationAsRead';
import { Button } from 'react-covideo-common';

const LoadMore = styled.p`
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
`;

interface IConversationsListProps {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  searchValue: string;
  setModals: React.Dispatch<React.SetStateAction<false | ModalsType>>;
  isRefetchingSingleConversations: boolean;
  isRefetchingMultipeConversations: boolean;
  data: InfiniteData<IConversation> | undefined;
  fetchNextPage: any;
}

const getIconType = (message: ISingleConversation) => {
  const iconSize = '24px';
  if (message.deleted) {
    return <MdDeleteForever size={iconSize} />;
  }
  if (message.closed) {
    return <MdChatBubbleOutline size={iconSize} />;
  }
  if (message.userId === null) {
    return <MdNoAccounts size={iconSize} />;
  }

  return <MdChat size={iconSize} />;
};

export const ConversationsList = ({
  setSearch,
  searchValue,
  setModals,
  data,
  fetchNextPage,
  isRefetchingSingleConversations,
  isRefetchingMultipeConversations,
}: IConversationsListProps) => {
  const { userData } = useAuth();
  const { type, id } = useParams() as MatchParamsWithId;
  const history = useHistory();
  const { mutateAsync } = useMarkAsReadConversationMutation();

  if (!data) {
    return (
      <UserListBox style={{ textAlign: 'center' }}>
        <MsgTitle>There is no data</MsgTitle>
      </UserListBox>
    );
  }

  const totalCount = +data?.pages?.[0].count;
  const pageTotal = data?.pages?.reduce(
    (acc, page) => acc + page?.conversations?.length || 0,
    0
  );

  const checkType = type === undefined ? CONVERSATION_ROUTE_TYPES.ALL : type;

  const openMessage = (message: ISingleConversation) => {
    const { conversationId, unread, userId } = message;

    history.push(`/conversations/${checkType}/${conversationId}`);
    if (isUnassigned(userId) || type === CONVERSATION_ROUTE_TYPES.UNASSIGNED) {
      return null;
    }
    if (isNotAssignedToYou(userId, userData)) {
      return null;
    }
    // IF CONVERSATION IS ASSIGNED TO YOU
    if (isAssignedToYou(userId, userData) && !!unread) {
      return mutateAsync(`${conversationId}`);
    }
  };

  return (
    <UserListBox>
      <SearchNewWrapper>
        <Search
          prevSearch={searchValue}
          onSearch={value => {
            setSearch(value);
          }}
          placeholder='Search conversations…'
          width='248px'
          bgColor='#FFFFFF'
        />
        <Button
          variant='secondary'
          text='+ New Conversation'
          onClick={() => setModals('add')}
        />
      </SearchNewWrapper>
      <div className='divider' style={{ marginTop: 126 }}></div>

      {data?.pages?.map((group: IConversation, i: number) => (
        <React.Fragment key={i}>
          {group.conversations.length ? (
            group.conversations.map((conversation: ISingleConversation) => {
              const { lastMessageAt, conversationId, unread, messages } =
                conversation;

              return (
                <Messages
                  key={conversationId}
                  onClick={() => openMessage(conversation)}
                  isActive={conversationId === +id}
                >
                  <ImageWrapper isUnread={!!unread}>
                    {getIconType(conversation)}
                  </ImageWrapper>
                  <MessagesInfoWrapper>
                    <MsgTitle>
                      {getConversationHeader(conversation.contact)}
                    </MsgTitle>
                    <MsgTime>{calculateDiff(lastMessageAt)} </MsgTime>
                    <MsgData>
                      {!!messages?.[0]?.text
                        ? deleteHTMLTags(messages?.[0]?.text)
                        : 'No messages'}
                    </MsgData>
                  </MessagesInfoWrapper>
                  {`${conversationId}` === `${id}` &&
                    isRefetchingSingleConversations && (
                      <RefreshIconWrapper>
                        <MdRefresh size={16} />
                      </RefreshIconWrapper>
                    )}
                </Messages>
              );
            })
          ) : (
            <NoMatchingConversations>
              No matching conversations or customers.
            </NoMatchingConversations>
          )}
        </React.Fragment>
      ))}
      {pageTotal < totalCount && (
        <LoadMore onClick={fetchNextPage}>
          {isRefetchingMultipeConversations ? 'Loading... ' : 'Show more'}
        </LoadMore>
      )}
    </UserListBox>
  );
};
