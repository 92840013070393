import { useQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IDepartmentsResponse {
  message: string;
  data: Department[];
}

export interface Department {
  id: number;
  customerId: number;
  name: string;
  isDefault: boolean;
}

const getAllDepartmentsForCustomer = async (customerId: string) => {
  return (await EXPRESS_API.get(`departments/${customerId}/all`))
    .data as IDepartmentsResponse;
};

export const useDepartmentsQuery = ({ customerId }: { customerId: string }) => {
  return useQuery(
    superAdminKeys.departments(`${customerId}` || ''),
    () => getAllDepartmentsForCustomer(customerId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: !!customerId,
    }
  );
};
