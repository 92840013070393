import React, { useEffect, useState, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components/macro';
import dayjs from 'dayjs';

import {
  CheckboxInput,
  LoadingIndicator,
  ModalVideoListVideo,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';

import { InfoMsg } from '../Info';
import { CTAHeader } from '../CTAHeader';
import { MdOutlineRestore, MdPlayCircleFilled } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { ModalRecentlyDeleted } from 'lib/components/modal/ModalRecentlyDeleted';
import { ModalRestoreRecentlyDeleted } from 'lib/components/modal/ModalRestoreRecentlyDeleted';
import { SearchInputs } from '../SearchInputs';
import {
  deleteRecentlyDeleted,
  getRecentlyDeleted,
  GetRecentlyDeletedParams,
  RecentlyDeletedItems,
  restoreRecentlyDeleted,
} from 'lib/api/recentlyDeletedApi';
import {
  FileTypes,
  IOnPaginationChange,
  ISelectedItems,
  LandingPageData,
  ModalTypes,
  SearchParams,
  TableFieldsValue,
} from '../types';
import {
  typesOptions,
  checkIfContainsEmptyString,
  tableFields,
} from '../const';
import { successToast } from 'lib/components/toasts/success';
import { getLandingPagePreview } from 'lib/api/designApi';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { useToastError } from 'lib/hooks';
import { ModalRestoreRecentlyDeletedError } from 'lib/components/modal/ModalRestoreRecentlyDeletedError';
import { getVideosCount } from 'lib/api';
import { useAuth } from 'lib/context';
import { isVideoCountValid } from 'lib/utils/productFeature';

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  padding: 8px;
`;

const TableHeader = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0px;
  padding-left: 24px;
  color: #9297a2;
  text-align: left;
  :last-child {
    padding-right: 16px;
  }
`;

const TableCellParagraphWrapper = styled.div<{ justifyContent?: string }>`
  width: 100%;
  justify-content: ${({ justifyContent }) => justifyContent};
  display: flex;
  align-items: center;
  color: inherit;
  > p {
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    width: 250px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin: 0;
  }
  > svg {
    margin-right: 6px;
  }
`;
const TableCell = styled.div<{
  width?: number;
  label?: string;
  justifyContent?: string;
}>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  max-width:250px;
  padding-left: 24px;
  text-align: center;
  display: flex;
  align-items:center;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  ${({ label }) =>
    !!label &&
    css`
  :hover{
    position:relative;
    cursor:pointer;
    >${IconWrapper}{
        background: #F2F4F6;
    
    :after{
      color:#272A32;
      font-weight:400;
      position:absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top:-45px;
      left:0;
      border-radius:4px;
      padding:8px 12px;
      max-height:24px;
      height:24px;
    
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      background-color: white;
      box-shadow: 0 2px 4px 0 rgba(52, 58, 64, 0.1),
        0 4px 24px 0 rgba(52, 58, 64, 0.15);
      content:"${label}"
  }`} 
 
  }
`;

const NoResults = styled.p`
  font-weight: bold;
  text-align: center;
  margin: 36px 0;
`;

export const Main = () => {
  const { showError } = useToastError();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const { userData } = useAuth();
  const [searchParams, setSearchParams] = useState<SearchParams | {}>({});
  const [modalStatus, setModalStatus] = useState<ModalTypes>(null);
  const [initialData, setInitialData] = useState<RecentlyDeletedItems[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<ISelectedItems>({
    count: 0,
    data: new Map(),
  });

  const [videoForPreview, setVideoForPreview] = useState<any>(null);
  const openDeleteModal = () => setModalStatus('DELETE');
  const openRestoreModal = () => setModalStatus('RESTORE');
  const openPreviewModal = () => setModalStatus('PREVIEW');
  const openRestoreErrorModal = () => setModalStatus('RESTORE_ERROR');
  const closeModal = () => setModalStatus(null);

  const start = page * size;
  const paginationParams = {
    start,
    limit: size,
  };

  const showLandingPagePreview = async (landingPageId: number) => {
    const data = await getLandingPagePreview(landingPageId);
    return window.open(data.url, '_blank', 'noopener');
  };

  const getToastMsgData = (action: string) => {
    let item;
    let totalNumber;
    if (action === 'single') {
      item = 'item';
      totalNumber = 1;
    } else {
      item = selectedItems.count === 1 ? 'item' : 'items';
      totalNumber = selectedItems.count;
    }
    return { item, totalNumber };
  };

  const refreshItems = async (
    type: 'restored' | 'deleted',
    action: 'single' | 'bulk'
  ) => {
    await getRecentlyDeletedItems({
      ...searchParams,
      ...paginationParams,
    });
    const { item, totalNumber } = getToastMsgData(action);
    successToast({
      title: `You have successfully ${type} ${totalNumber} ${item}!`,
    });
  };

  //restore_items handler
  const restoreItemsHandler = async (id?: number) => {
    const recentDeletedIds = Array.from(selectedItems.data.values())
      .filter(item => !!item)
      .map(item => item.recentlyDeletedItemId);
    const payload = [...(!!id ? [id] : recentDeletedIds)];
    setLoading(true);
    closeModal();
    try {
      await restoreRecentlyDeleted(payload);
      const action = !!id ? 'single' : 'bulk';
      await refreshItems('restored', action);

      if (!!id) {
        let selected = new Map(selectedItems.data);
        let count = selectedItems.count;
        count--;
        selected.delete(id);
        setSelectedItems({
          count: count <= 0 ? 0 : count,
          data: selected,
        });
      } else {
        setSelectedItems({
          count: 0,
          data: new Map(),
        });
      }
      setLoading(false);
    } catch (error) {
      showError(error);
      setLoading(false);
    }
  };

  //delete_items handler
  const deleteItemsHandler = async () => {
    setLoading(true);
    closeModal();
    try {
      await deleteRecentlyDeleted([...selectedItems.data.keys()]);
      await refreshItems('deleted', 'bulk');
      setSelectedItems({
        count: 0,
        data: new Map(),
      });
      setLoading(false);
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  //get_data
  const getRecentlyDeletedItems = async (params: GetRecentlyDeletedParams) => {
    setLoading(true);
    const payload = checkIfContainsEmptyString(params);
    try {
      const { recentlyDeletedItems, count } = await getRecentlyDeleted(payload);
      setInitialData(recentlyDeletedItems);
      setCount(count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error("Can't retrieve recently deleted API!");
    }
  };

  useEffect(() => {
    getRecentlyDeletedItems({ ...searchParams, ...paginationParams });
  }, [page, size]);

  // on params change
  useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      getRecentlyDeletedItems({ ...searchParams, ...paginationParams });
    }
  }, [searchParams]);

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: IOnPaginationChange) => {
    if (page !== newPage || size !== newSize) {
      setPage(newPage);
      setSize(newSize);
    }
  };

  const onCheckboxChangeHandler = (
    e: SyntheticEvent,
    item: RecentlyDeletedItems
  ) => {
    e.stopPropagation();
    let { checked } = e.target as HTMLInputElement;
    let selected = new Map(selectedItems.data);
    let count = selectedItems.count;
    if (checked) {
      selected.set(item.recentlyDeletedItemId, item);
      count++;
    } else {
      selected.set(item.recentlyDeletedItemId, undefined);
      count--;
    }
    setSelectedItems({
      count: count,
      data: selected,
    });
  };

  //CELLS
  const getCellOptions = (type: string) => {
    switch (type) {
      case FileTypes.VIDEO:
        return {
          title: 'Video preview',
          icon: <MdPlayCircleFilled size={18} />,
        };
      case FileTypes.LANDING_PAGE:
        return { title: 'Landing page preview', icon: <IoMdEye size={18} /> };
      default:
        return { title: '', icon: null };
    }
  };

  const preview = async (item: RecentlyDeletedItems) => {
    if (item.type === FileTypes.VIDEO) {
      setVideoForPreview(item.data);
      openPreviewModal();
    }
    if (item.type === FileTypes.LANDING_PAGE) {
      const landingPage = item.data as LandingPageData;
      showLandingPagePreview(landingPage.designId);
    }
    return null;
  };

  //HEADERS
  const headers = [
    '',
    ...tableFields.map(({ label }) => {
      return <TableHeader>{label}</TableHeader>;
    }),
  ];

  //COLUMNS
  const getColumns = (item: RecentlyDeletedItems) => [
    <CheckboxInput
      width='24px'
      blueCheck={true}
      checked={!!selectedItems.data.get(item.recentlyDeletedItemId)}
      onChange={(e: SyntheticEvent) => onCheckboxChangeHandler(e, item)}
    />,

    ...tableFields.map(({ value }, idx) => {
      const { title, icon } = (idx === 0 && getCellOptions(item.type)) || {};
      if (value === TableFieldsValue.RESTORE) {
        return (
          <TableCell
            label='Restore'
            onClick={async () => {
              if (item?.type === 'video') {
                const userVideos = await getVideosCount();
                const totalVideoCount = userVideos.count + 1;
                const isValidCount = isVideoCountValid(
                  userData,
                  totalVideoCount
                );
                if (!isValidCount) {
                  openRestoreErrorModal();
                  return;
                }
              }

              restoreItemsHandler(item.recentlyDeletedItemId);
            }}
          >
            <IconWrapper>
              <MdOutlineRestore color='#9297A2' size={18} />
            </IconWrapper>
          </TableCell>
        );
      }
      if (value === TableFieldsValue.CREATED_AT) {
        return (
          <TableCell width={100}>
            {dayjs(item[value]).format('YYYY-MM-DD hh:mm A')}
          </TableCell>
        );
      }
      if (value === TableFieldsValue.TYPE) {
        const cellLabel = typesOptions.find(
          option => option.value === item[value]
        )?.label;
        return <TableCell>{cellLabel}</TableCell>;
      }
      if (value === TableFieldsValue.DAYS) {
        const createdAtField = dayjs(item['createdAt']);
        const today = dayjs();
        const diff = 30 - today.diff(createdAtField, 'day');
        return (
          <TableCell
            width={150}
            style={{
              color: diff <= 5 ? 'rgba(232, 76, 61, 1)' : 'inherit',
            }}
            justifyContent='center'
          >
            <TableCellParagraphWrapper justifyContent='center'>
              <p style={{ textAlign: 'center' }}>{diff}</p>
            </TableCellParagraphWrapper>
          </TableCell>
        );
      }
      return (
        <TableCell label={title} onClick={() => preview(item)}>
          <TableCellParagraphWrapper>
            {icon}
            <p>{item[value]}</p>
          </TableCellParagraphWrapper>
        </TableCell>
      );
    }),
  ];

  //ROWS
  const rows =
    initialData?.map((item: RecentlyDeletedItems, index: number) => ({
      key: index,
      columns: getColumns(item),
    })) || [];

  const restoreModalToOpen = async () => {
    let isVideoSelected = false;
    let selectedVideoCount = 0;
    selectedItems.data.forEach(item => {
      if (item?.type === 'video') {
        isVideoSelected = true;
        selectedVideoCount++;
      }
    });
    const userVideos = await getVideosCount();
    const totalVideoCount = userVideos.count + selectedVideoCount;
    const isValidCount = isVideoCountValid(userData, totalVideoCount);
    isVideoSelected && !isValidCount
      ? openRestoreErrorModal()
      : openRestoreModal();
  };

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <CTAHeader
          selectedItems={selectedItems}
          initialData={initialData || []}
          changeSelectedItems={setSelectedItems}
          openDeleteModal={openDeleteModal}
          restoreModalToOpen={restoreModalToOpen}
        />
        <>
          <InfoMsg />
          <SearchInputs setParams={setSearchParams} params={searchParams} />
          {!loading ? (
            <>
              {!initialData?.length && (
                <NoResults>No matching results found. </NoResults>
              )}
              {!!initialData?.length && (
                <TableContextProvider
                  total={count}
                  initSize={size}
                  initPage={page}
                  onChange={onPaginationChange}
                >
                  <Table
                    compact={true}
                    headers={headers}
                    rows={rows}
                    columnWidths={[
                      '25px',
                      '325px',
                      '150px',
                      'auto',
                      'auto',
                      '75px',
                    ]}
                  />
                  <TableFooter>
                    <TablePaginationNew />
                    <TablePaginationSizeNew />
                  </TableFooter>
                </TableContextProvider>
              )}
            </>
          ) : (
            <LoadingIndicator isLoading={loading} />
          )}
        </>
        {modalStatus === 'DELETE' && (
          <ModalRecentlyDeleted
            count={selectedItems.count}
            handleModalClose={closeModal}
            deleteItemsHandler={deleteItemsHandler}
          />
        )}
        {modalStatus === 'RESTORE' && (
          <ModalRestoreRecentlyDeleted
            count={selectedItems.count}
            handleModalClose={closeModal}
            restoreItemsHandler={restoreItemsHandler}
          />
        )}
        {modalStatus === 'RESTORE_ERROR' && (
          <ModalRestoreRecentlyDeletedError handleModalClose={closeModal} />
        )}
        {modalStatus === 'PREVIEW' && (
          <ModalVideoListVideo
            videoId={''}
            source={videoForPreview?.videoSource ?? ''}
            handleModalClose={() => {
              closeModal();
            }}
          />
        )}
      </MainWrapper>
    </Container>
  );
};
