import { EXPRESS_API } from 'configs/api/expressApi';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { CreateCustomerParams } from '.';

export const updateCustomer = async (
  { customerId, data }: CreateCustomerParams,
  changeOrigin?: CHANGE_ORIGIN
) => {
  const response = await EXPRESS_API.put(`/customers/${customerId}`, data, {
    params: { changeOrigin },
    withCredentials: true,
  });
  return response.data;
};

export const updateVideoExpiration = async ({
  customerId,
  data,
}: CreateCustomerParams) => {
  const response = await EXPRESS_API.patch(
    `/customers/${customerId}/video-expiration`,
    data
  );
  return response.data;
};
