import React from 'react';
import {
  ANDROID_GOOGLE_PLAY_STORE_URL,
  IOS_APP_STORE_URL,
  MOBILE_COVIDEO_URL,
} from '../const';
import { DocumentHead } from 'lib/components';
import { Gap, Stack } from 'lib/components/styles/layout';
import styled from 'styled-components/macro';
import {
  ParagraphNormal,
  SubHeading,
  ParagraphNormalBold500,
} from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { Badge, BadgeType } from 'lib/components/Badge';
import mobileAppPreviewImage from 'assets/icons/mobilePreview.png';
import { Button } from 'react-covideo-common';
import { PiGlobe } from 'react-icons/pi';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';

const AccentText = styled.div`
  display: inline;
  color: ${({ theme }) => theme.colors.primary[100]};
`;

const Box = styled.div`
  padding: 24px;
  box-shadow:
    0px 0px 4px 0px rgba(66, 79, 104, 0.08),
    0px 6px 12px 0px rgba(66, 79, 104, 0.06);
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  margin: 16px auto;
`;

const Line = styled.div`
  background: ${theme.palette.neutral20};
  height: 1px;
  flex-grow: 1;
  width: 100%;
`;

const MobilePreviewImage = styled.img`
  display: block;
  margin: 0 auto 16px auto;
`;

const BadgePositioning = styled.div`
  position: absolute;
  top: -16px;
`;

export const WelcomeMobile = () => {
  const userAgent = navigator.userAgent;
  const isIos = /iPad|iPhone|iPod/.test(userAgent);

  const { logoUrl } = useLogoUrl('dark');

  return (
    <>
      <DocumentHead title='Welcome' />
      <Stack p='24px 22px' m='0 auto' width='375px' maxWidth='100%'>
        <img width='140' src={logoUrl} alt='Covideo' />
        <SubHeading color={theme.palette.covideoBlue100} textAlign='center'>
          Grab the Covideo app for the{' '}
          <AccentText>best mobile experience!</AccentText>
        </SubHeading>
        <Box>
          <Stack gap={'0'}>
            <BadgePositioning>
              <Badge text='Fast & Easy' type={BadgeType.GREEN} />
            </BadgePositioning>
            <ParagraphNormalBold500 textAlign='center'>
              Covideo Mobile App
            </ParagraphNormalBold500>
            <MobilePreviewImage
              src={mobileAppPreviewImage}
              alt='Covideo App Preview'
            />
            {isIos && (
              <a href={IOS_APP_STORE_URL}>
                <Button text={'Open App Store'} />
              </a>
            )}
            {!isIos && (
              <a href={ANDROID_GOOGLE_PLAY_STORE_URL}>
                <Button text={'Open Google Play Store'} />
              </a>
            )}
          </Stack>
        </Box>
        <Gap alignItems='center' flexWrap='nowrap' width='100%'>
          <Line />
          <ParagraphNormal>or</ParagraphNormal>
          <Line />
        </Gap>
        <Box>
          <Stack>
            <BadgePositioning>
              <Badge text='Limited' type={BadgeType.DESTRUCTIVE} width='auto' />
            </BadgePositioning>
            <Gap>
              <PiGlobe color={theme.palette.blue40} size={32} />
              <a href={MOBILE_COVIDEO_URL}>
                <Button text={'Use Covideo in Browser'} variant='secondary' />
              </a>
            </Gap>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
