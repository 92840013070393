import React, { useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { Search } from '../inputs';
import { shareUsersCtaSets } from 'lib/api';
import { useAuth } from 'lib/context';
import { successToast } from '../toasts/success';
import { useToastError } from 'lib/hooks';
import { Button } from 'react-covideo-common';
import { getManagedUsers } from 'lib/api/users/useManagedUsersQuery';
import { IoMdClose } from 'react-icons/io';

interface Props {
  onClose: (p?: any) => void;
  data: any;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const ListWrapper = styled.div<{ bgColor?: string }>`
  padding: 8px;
  border: 1px solid #eeeff2;
  border-radius: 8px;
  width: 100%;
  max-height: 374px;
  height: 374px;
  background-color: ${({ bgColor }) => bgColor || 'white'};
  .search,
  .search > div,
  .search input {
    background-color: white;
  }
`;

const UserList = styled.div`
  margin-top: 16px;
`;

const UserItem = styled.div<{ selected: boolean }>`
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ selected }) => (selected ? '#F6F7F9' : 'white')};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const UserParagraph = styled.p<{ selected: boolean }>`
  margin: 0;
  color: ${({ selected }) =>
    selected ? 'rgba(146, 151, 162, 1)' : 'rgba(39, 42, 50, 1)'};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const SuperVisor = styled.p`
  margin: 0;
  color: rgba(0, 27, 83, 0.4);
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
`;
const InfoMsg = styled.p`
  color: rgba(78, 84, 97, 1);
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
`;

const BodyWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 32px;
`;
const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export interface GetUserResponse {
  userList: User[];
  totalCount: number;
  totalAllowedUsers: number;
  totalActiveUsers: number;
}

interface SelectedUser {
  [key: string]: User;
}

export interface User {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phone1: string;
  verified: number;
  loginDate: string;
  transcriptionAccess: number;
  mobileSMSAccess: number;
  droplrAccess: number;
  access: number;
  departmentName: null | string;
}

const loadTypes = { MORE: 'more', INITIAL: 'initial' };

export const ModalCtaSetUsers = (props: Props) => {
  const ACCESS_SUPERVISOR = '4';
  const { onClose, data } = props;

  const {
    userData: { customerId },
  } = useAuth();
  const { showError } = useToastError();

  const [selectedUsers, setSelectedUsers] = useState<SelectedUser>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [totalUser, setTotalCount] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState<number>(0);

  const selectedUsersKeys = Object.keys(selectedUsers);

  //state handlers
  const removeSelectedUser = (userId: string) => {
    const { [userId]: _, ...rest } = selectedUsers;
    setSelectedUsers(rest);
  };
  const addSelectedUser = (user: User) => {
    const { userId, access } = user;
    const isSuperVisor = access.toString() === ACCESS_SUPERVISOR;
    return isSuperVisor
      ? null
      : setSelectedUsers({ [userId]: user, ...selectedUsers });
  };
  const onUsersSearch = (inputValue: string) => {
    setSearchQuery(inputValue);
  };
  const nextPage = () => setPage(page + 1);

  //async handlers
  const loadUsers = async (loadType: string) => {
    const isInitial = loadType === loadTypes.INITIAL;
    isInitial && setLoading(true);
    try {
      const response = await getManagedUsers({
        customerId,
        page: isInitial ? 0 : page,
        size: 10,
        search: searchQuery,
      });
      isInitial && setTotalCount(response.totalCount);

      if (isInitial) {
        setPage(0);
        setUsersList([...response.userList]);
      } else {
        setUsersList([...usersList, ...response.userList]);
      }
    } catch (error) {
      showError(error, 'Something went wrong, please try again later');
    }
    isInitial && setLoading(false);
  };

  const updateUserCTASets = async () => {
    setLoading(true);
    try {
      await shareUsersCtaSets(data.id, { userIds: selectedUsersKeys });
      setLoading(false);
      onClose();
      successToast({ title: 'Your changes have been successfully saved!' });
    } catch (e) {
      showError(e);
    }
  };

  useEffect(() => {
    //initial load, on searchQuery and on selectedDept change load
    loadUsers(loadTypes.INITIAL);
  }, [searchQuery]);

  useEffect(() => {
    if (page > 0) {
      //infinite load
      loadUsers(loadTypes.MORE);
    }
  }, [page]);

  return (
    <Modal widthOnDesktop={'852px'} maxHeight='576px'>
      <Content>
        <Row justifyContent={'space-between'}>
          <Title>Send copy of CTA set to users</Title>
          <CloseIconWrap>
            <IoMdClose
              size={24}
              onClick={onClose}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <BodyWrapper>
          <ListWrapper bgColor='#EEEFF2'>
            <Search onSearch={onUsersSearch} className='search' />
            <UserList>
              <InfiniteScroll
                dataLength={usersList.length}
                next={nextPage}
                hasMore={totalUser > usersList.length}
                loader={<h4>Loading...</h4>}
                height={300}
                endMessage={''}
              >
                {usersList.map((user: User) => {
                  const { userId, username, access } = user;
                  const isSuperVisor = access.toString() === ACCESS_SUPERVISOR;
                  const selected =
                    selectedUsers?.[userId]?.userId === userId || isSuperVisor;
                  const iconColor = selected
                    ? 'rgba(208, 211, 217, 1)'
                    : 'rgba(78, 84, 97, 1)';

                  return (
                    <UserItem
                      selected={selected}
                      onClick={() => addSelectedUser(user)}
                    >
                      <UserParagraph selected={selected}>
                        {username}
                      </UserParagraph>
                      {isSuperVisor ? (
                        <SuperVisor>Supervisor</SuperVisor>
                      ) : (
                        <BsFillPlusCircleFill size={16} color={iconColor} />
                      )}
                    </UserItem>
                  );
                })}
              </InfiniteScroll>
            </UserList>
          </ListWrapper>
          <ListWrapper style={{ overflow: 'scroll' }}>
            {selectedUsersKeys.map((userId: string) => (
              <UserItem selected onClick={() => removeSelectedUser(userId)}>
                <UserParagraph selected={false}>
                  {selectedUsers[userId].username}
                </UserParagraph>
                <BsFillPlusCircleFill
                  style={{ transform: 'rotate(45deg)' }}
                  size={16}
                  color={'rgba(232, 76, 61, 0.8)'}
                />
              </UserItem>
            ))}
          </ListWrapper>
        </BodyWrapper>
        <FooterWrapper>
          <InfoMsg>{selectedUsersKeys.length} user selected</InfoMsg>
          <Button
            text={'Share with selected users'}
            disabled={!selectedUsersKeys.length || loading}
            onClick={updateUserCTASets}
          />
        </FooterWrapper>
      </Content>
    </Modal>
  );
};
