import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { leadsKeys } from './queryKeys';
import { QueryListParams } from '../types';
import { ISingleConversation } from '../conversations/getSingleConversation';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface ILead {
  phone: string;
  phone2: string;
  fax: string;
  notes: string;
  email: string;
  userId: number;
  firstName: string;
  lastName: string;
  companyName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  type: string;
  source: string | null;
  country: string | null;
  conversation?: ISingleConversation;
  smsPreferred: number;
  emailPreferred: number;
  leadId: number;
  leadStatus?: string;
  leadStatusType?: string;
  leadType?: string;
  salesRep?: string;
  sold?: number;
}

export interface ILeadResponse {
  count: number;
  leads: ILead[];
}

const getLeads = async ({
  groupId,
  page = 0,
  size = 10,
  search,
  sort,
  conversation,
  startDate,
  endDate,
  sendsFilter,
}: QueryListParams): Promise<ILeadResponse> => {
  const start = page * size;
  const params = {
    start,
    groupId,
    limit: size,
    search: search,
    sort: sort,
    conversation,
    startDate,
    endDate,
    sendsFilter,
  };

  return (
    await EXPRESS_API.get(`leads`, {
      params,
    })
  ).data as ILeadResponse;
};

export const useLeadsQuery = (options: QueryListParams, enabled: boolean) => {
  const { showError } = useToastError();

  const searchValue = !options.search ? '' : options.search;

  return useQuery(leadsKeys.list(searchValue), () => getLeads(options), {
    onError: showError,
    enabled,
    refetchOnMount: true,
  });
};
