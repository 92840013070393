import { CheckboxInput } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphNormal } from 'lib/components/styles/typography';
import React from 'react';

interface IProps {
  onSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checkGroupIndicator?: boolean;
  checked?: boolean;
  message?: string;
}

export const SelectableBodySearch = ({
  onSelectAll,
  checkGroupIndicator,
  checked,
  message,
}: IProps) => {
  return (
    <Gap
      flexDirection='row'
      gap='12px'
      m='0 0 14px 0'
      style={{ padding: '8px 12px' }}
    >
      <CheckboxInput
        checkGroupIndicator={checkGroupIndicator}
        checked={checked}
        onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
          event.stopPropagation();
        }}
        onChange={onSelectAll}
      />
      <ParagraphNormal color='#272A32'>Selected: {message}</ParagraphNormal>
    </Gap>
  );
};
