import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { freemiumOnboardingKeys } from './freemiumOnboardingKeys';
import { FreemiumOnboardingData } from './types';

const createOrUpdateFreemiumOnboardingData = async (
  data: Partial<FreemiumOnboardingData>
) => {
  const response = await EXPRESS_API.post(`/freemium-onboarding`, data);
  return response.data;
};

export const useFreemiumOnboardingMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation(
    (data: Partial<FreemiumOnboardingData>) =>
      createOrUpdateFreemiumOnboardingData(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          freemiumOnboardingKeys.freemium_onboarding()
        );
      },
      onMutate: (res: Partial<FreemiumOnboardingData>) => {
        queryClient.cancelQueries(freemiumOnboardingKeys.freemium_onboarding());
        const oldData: FreemiumOnboardingData | undefined =
          queryClient.getQueryData(
            freemiumOnboardingKeys.freemium_onboarding()
          );
        const newData = { ...(!oldData ? {} : oldData), ...res };
        queryClient.setQueryData(
          freemiumOnboardingKeys.freemium_onboarding(),
          newData
        );
      },
      onError: (err: AxiosError, _, previousData) => {
        queryClient.setQueryData(
          freemiumOnboardingKeys.freemium_onboarding(),
          previousData
        );
        showError(err);
      },
    }
  );
};
