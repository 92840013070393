import React from 'react';
import { CONNECTION_TYPES } from './constants';
import { Chip } from './style';

interface ConnectionChipProps {
  type: CONNECTION_TYPES;
}

export const ConnectionChip = ({ type }: ConnectionChipProps) => {
  switch (type) {
    case CONNECTION_TYPES.CONNECTED:
      return (
        <Chip
          bgColor={' rgba(30, 164, 95, 0.05)'}
          fontColor={'rgba(30, 164, 95, 0.8);'}
        >
          CONNECTED
        </Chip>
      );
    case CONNECTION_TYPES['NOT CONNECTED']:
      return (
        <Chip
          bgColor={'rgba(232, 76, 61, 0.05)'}
          fontColor={'rgba(232, 76, 61, 0.8)'}
        >
          NOT CONNECTED
        </Chip>
      );
    case CONNECTION_TYPES.EXPIRED:
      return (
        <Chip
          bgColor={'rgba(255, 139, 34, 0.05)'}
          fontColor={'rgba(255, 139, 34, 0.8)'}
        >
          EXPIRED
        </Chip>
      );
    default:
      return null;
  }
};
