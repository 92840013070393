import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const Layout = styled.div`
  margin: 16px 0 0 0;
  background-color: ${theme.palette.white};
  box-sizing: border-box;
  overflow: overlay;
`;

export const IntegrationsList = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: start;
`;

export const Heading = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const Content = styled.div`
  padding-top: 48px;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
