import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { snippetsKeys } from './queryKeys';
import { decodeText } from './utils';
import { SnippetItem } from './types';

export const querySnippet = async (snippetId: string): Promise<SnippetItem> => {
  const response = await EXPRESS_API.get(`/snippets/${snippetId}`);
  const decodedSnippet = await decodeText(response.data);
  return decodedSnippet;
};

export const useQuerySnippet = (snippetId: string) => {
  return useQuery(
    snippetsKeys.single_snippet(snippetId),
    () => querySnippet(snippetId),
    {
      refetchOnMount: true,
      enabled: snippetId !== 'create',
    }
  );
};
