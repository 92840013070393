import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { snippetsKeys } from './queryKeys';
import { decodeText } from './utils';
import { QuerySnippetsParams, SnippetItem } from './types';

export const querySnippets = async (params: QuerySnippetsParams) => {
  const response = await EXPRESS_API.get(`/snippets`, { params });
  const decodedData = response.data.snippets.map((snippet: SnippetItem) => {
    return decodeText(snippet);
  });

  return { snippets: decodedData, count: response.data.count };
};

export const useQuerySnippets = (params: QuerySnippetsParams) => {
  const { showError } = useToastError();
  return useQuery(snippetsKeys.snippets(params), () => querySnippets(params), {
    onError: showError,
    refetchOnMount: true,
  });
};
