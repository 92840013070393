import * as React from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';
import { useTableContext } from './TableContext';
import { useEffect } from 'react';
import Select from 'react-select';

const DEFAULT_OPTIONS = [10, 20, 50, 100];

type Props = {
  initSize?: number;
  onSizeChange?: Function;
  sizeOptions?: number[];
  globalName?: string;
  text?: string;
};

const PaginationLimitText = styled.div<{ margin: string }>`
  margin: ${props => props.margin};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  color: #4e5461;
  font-size: ${theme.fontSizes.sm};
`;

const TablePaginationSize = ({
  initSize,
  onSizeChange = () => {},
  sizeOptions = DEFAULT_OPTIONS,
  globalName = btoa(window.location + '_size'),
  text,
}: Props) => {
  const { size, setSize, count, page, setPage } = useTableContext();

  useEffect(() => {
    /*
    if (!initSize) {
      setSize(parseInt(localStorage.getItem(globalName) || '10'));
      return;
    }
    */
    setSize(size);
  }, [initSize]);

  useEffect(() => {
    localStorage.setItem(globalName, size.toString());
    onSizeChange(size);
    if (page * size + 1 > count) {
      const newPage = Math.floor(count / size) - 1;
      setPage(newPage >= 0 ? newPage : 0);
    }
  }, [size]);

  return (
    <>
      {text && (
        <PaginationLimitText margin="0">{text}&nbsp;</PaginationLimitText>
      )}
      <Select
        menuPlacement="auto"
        styles={{
          control: (base: any) => ({
            ...base,
            boxShadow: 'none',
            height: '40px',
            border: '1px solid #d0d3d9',
            borderRadius: '4px',
            width: '80px',
            '&:hover': {
              border: '1px solid #d0d3d9',
            },
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
        }}
        value={{
          value: size,
          label: size,
        }}
        onChange={(option: any, _actionMeta: any) => {
          setSize(option.value);
        }}
        options={sizeOptions.map((f: any) => ({
          value: f,
          label: f,
        }))}
        menuPortalTarget={document.body}
      />
      {!text && (
        <PaginationLimitText margin="0 24px 0 8px">
          Results per page
        </PaginationLimitText>
      )}
    </>
  );
};

export const TablePaginationSizeNew = React.memo(TablePaginationSize);
