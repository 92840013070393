import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { useMutation } from 'react-query';
import { UpdateVideoParams } from './types';

export const updateVideo = async ({
  videoId,
  videoBody,
}: UpdateVideoParams) => {
  const response = await EXPRESS_API.put(`/videos/${videoId}`, videoBody);
  return response.data;
};

export const useUpdateVideoMutation = () => {
  return useMutation((data: UpdateVideoParams) => updateVideo(data), {
    onError: () => {
      errorToast({
        title: 'Failed to update video. Please try again later.',
      });
    },
  });
};
