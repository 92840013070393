import { ITableFields, TableFieldsValue } from './types';

export const pageTitle = 'Schedules';

export const tableFields: ITableFields[] = [
  {
    value: TableFieldsValue.RECIPIENTS,
    label: 'Recipient(s)',
  },
  {
    value: TableFieldsValue.SCHEDULED_FOR,
    label: 'Scheduled For',
  },
  {
    value: TableFieldsValue.VIDEO,
    label: 'Video Title',
  },
  {
    value: TableFieldsValue.SEND_EMAIL,
    label: 'Email',
  },
  {
    value: TableFieldsValue.SEND_SMS,
    label: 'SMS',
  },
];

export const smsRecipientBadgeText = 'SMS recipient';

export const invalidTimeMessage = 'Invalid time';

export const editScheduleButtonPopupText = 'Edit & Preview';
export const deleteScheduledEmailButtonPopupText = 'Delete Scheduled Email';
export const deleteScheduledSmsButtonPopupText = 'Delete Scheduled SMS';
export const deleteScheduledEmailAndSmsButtonPopupText =
  'Delete Scheduled Email & SMS';

export const noSchedulesMessage = 'No scheduled SMS/Emails found.';

export enum ScheduleStatus {
  SCHEDULED = 1,
  SENT = 2,
  FAILED = 3,
}
