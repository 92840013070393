import dayjs from 'dayjs';
import { invalidTimeMessage } from './const';
import { utcTimeToUserTime } from 'lib/utils/time';
import { ICombinedUserData } from 'lib/context';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import {
  TimezonesOption,
  timezonesOptions,
} from 'lib/components/timezoneSelector/TimezoneSelector';

export const isTimeValid = (time: string = '') => {
  const timeArray = time.split(':');

  const hours = parseInt(timeArray[0], 10);
  const minutes = parseInt(timeArray[1], 10);

  return (
    !isNaN(hours) &&
    !isNaN(minutes) &&
    hours >= 0 &&
    hours <= 12 &&
    minutes >= 0 &&
    minutes < 60
  );
};

export const parseDeliveryTimeToScheduledTimezone = (deliveryTime: string) => {
  const tempList = deliveryTime.split(';');
  if (tempList.length >= 4) {
    let timezoneFormatted = '';
    let tempTimezoneList: string[] = [];
    const selectedTimezone = getTimezoneByUtc(tempList[3]);
    if (selectedTimezone) {
      tempTimezoneList = selectedTimezone.label.split(')');
    }
    if (tempTimezoneList.length > 0) {
      timezoneFormatted = `${tempTimezoneList[0]})`;
    }
    const dateTimeFormatted = dayjs(
      `${tempList[0]} ${tempList[1]} ${tempList[2]}`
    ).format(`${STANDARD_DATE_FORMAT} hh:mm:ss A`);

    return `${dateTimeFormatted} ${timezoneFormatted}`;
  }
  return invalidTimeMessage;
};

export const parseDeliveryUnixTimestampToUserTimezone = (
  deliveryUnixTimestamp: number,
  userData: ICombinedUserData
) => {
  return utcTimeToUserTime(
    dayjs(new Date(deliveryUnixTimestamp * 1000)).format(),
    userData,
    `${STANDARD_DATE_FORMAT} hh:mm:ss A`
  );
};

export const getTimezoneByUtc = (utcValue: string): TimezonesOption | null => {
  const filteredTimezoneList = timezonesOptions.filter(tz => {
    for (const utc of tz.utc) {
      if (utc === utcValue) {
        return tz;
      }
    }
    return null;
  });
  if (filteredTimezoneList.length > 0) {
    return filteredTimezoneList[0];
  }
  return null;
};

export const formatMeetingTime = (
  date: Date,
  duration: number,
  userData: ICombinedUserData
): { meetingDuration: string; day: string } => {
  const userFormattedDate = utcTimeToUserTime(
    date.toISOString(),
    userData,
    `${STANDARD_DATE_FORMAT} hh:mm A`
  );
  const day = userFormattedDate.split(' ')[0];

  const formatTime = (time: string): string => {
    const timeParts = time.split(' ');
    return timeParts[1] + ' ' + timeParts[2];
  };

  const startTime = formatTime(userFormattedDate);
  const endDate = new Date(date.getTime() + duration * 60 * 1000);
  const userFormattedEndDate = utcTimeToUserTime(
    endDate.toISOString(),
    userData,
    'DD/MM/YYYY hh:mm A'
  );
  const endTime = formatTime(userFormattedEndDate);

  return {
    meetingDuration: `${startTime} - ${endTime}`,
    day: day,
  };
};

export const formatDeliveryTime = (deliveryTime: string): string => {
  const parts = deliveryTime.split(';');

  if (parts.length !== 4) {
    console.log(
      'Invalid input string. Expected format: YYYY-MM-DD;HH:mm;AM/PM;Timezone'
    );
    return deliveryTime;
  }

  const datePart = parts[0];
  const timePart = `${parts[1]} ${parts[2]}`;
  const timeZonePart = getTimezoneByUtc(parts[3])?.label;

  const formattedDate = dayjs(datePart).format(STANDARD_DATE_FORMAT);

  return `${formattedDate}, ${timePart}, ${timeZonePart}`;
};

export const identifyDate = (inputDate: Date): string => {
  const today = new Date();
  const tomorrow = new Date(today);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);
  inputDate.setHours(0, 0, 0, 0);

  if (inputDate.valueOf() === today.valueOf()) {
    return 'Today';
  }
  if (inputDate.valueOf() === tomorrow.valueOf()) {
    return 'Tomorrow';
  }
  return '';
};

export function updateDateToNext15MinuteInterval(date: Date) {
  const newDate = new Date(date);
  const minutes = newDate.getMinutes();
  if (minutes % 15 !== 0) {
    const minutesToAdd = 15 - (minutes % 15);
    newDate.setMinutes(minutes + minutesToAdd);
  }
  return newDate;
}
