import * as React from 'react';

export const text = {
  playerIconsPopUp: (
    <>
      We automatically set the color of icons and text based on your color
      choice to either white or black. Make sure there is{' '}
      <strong>enough contrast</strong> when switching from the default setting.
    </>
  ),
  downloadModal: {
    downloadModalTitle: 'Download Video',
  },
  deleteModal: {
    deleteModalTitle: 'Move video to recently deleted bin?',
    deleteModalBody: (
      <>
        <br />
        This video will be permanently deleted after 30 days, removed from the
        company folder if shared, and recipients will no longer be able to view
        this video.
        <br />
      </>
    ),
    deleteModalMultipleSelectedBody: (
      <>
        <br />
        These videos will be permanently deleted after 30 days, removed from the
        company folder if shared, and recipients will no longer be able to view
        this video. If the video was sent with a repair order, the RO that was
        created with it will be locked and you will not be able to edit it or
        Send&Share it.
        <br /> <br />
      </>
    ),
    deleteMultipleModalBody: (
      <>
        <br />
        This videos will be permanently deleted after 30 days, removed from the
        company folder if shared, and recipients will no longer be able to view
        this video.
        <br />
      </>
    ),
    deleteModalWithROBody:
      'Deleting the video, the RO that was created with it gets locked and you will not be able to edit it or Send&Share it.',
    deleteModalCompanyBody: (
      <>
        This will delete the video from the Company Folder, but leave it in the
        primary folder.
        <br />
      </>
    ),
  },
  moveCopyModal: {
    MoveCopyModalTitle: 'Move/Copy Video',
    checkboxText: 'Keep a copy of this video in the current folder',
  },
};
