import { toMMSS } from 'lib/utils/functions';
import { AnnotationListItem } from '../types';

export const wrapAnnotationWithProps = (
  annotation: AnnotationListItem,
  options?: {
    isCompanyAnnotation: boolean;
  }
) => {
  annotation.startTimeText = toMMSS(annotation.startTime, 0);
  annotation.endTimeText = toMMSS(annotation.endTime, 0);

  annotation.isLinkEnabled = !!(
    (annotation.linkType === 'Web' && annotation.url) ||
    (annotation.linkType === 'Email' && annotation.email) ||
    (annotation.linkType === 'Phone' && annotation.phone)
  );

  if (!annotation.url) {
    annotation.url = '';
  }

  if (!annotation.email) {
    annotation.email = '';
  }

  if (!annotation.phone) {
    annotation.phone = '';
  }

  if (options) {
    const { isCompanyAnnotation } = options;
    if (isCompanyAnnotation) {
      annotation.type = 'image';
    }
  }

  return annotation;
};
