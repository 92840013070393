import * as React from 'react';
import { matchPath } from 'react-router';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { EditMyProfile } from './userAccount/editMyProfile';
import { BecomeAffiliate, UpgradeToPaid } from './userAccount';
import { VideoAttributes } from './userAccount/videoAttributes';
import { useAuth } from 'lib/context';
import { Users } from './../management/users';
import { Snippets } from './../snippets';
import { UserEntry } from './../management/userEntry';
import { UserDepartment } from './../management/userDepartment';
import { Billings } from './../billing/billings';
import { Cards } from './../billing/cards';
import {
  SidebarItem,
  SidebarNavigation,
  addons,
  affiliate,
  billing,
  company,
  emailBatches,
  emails,
  help,
  logout,
  manageUsers,
  others,
  profile,
  recentlyDeleted,
  scheduleItem,
  servicesItem,
  snippetsItem,
  socialProfile,
  teleprompterItem,
  videoAttributes,
  videoExpiration,
  videos,
} from 'lib/components/SidebarNavigation';

import { AddOns } from './userAccount/addOns/AddOns';
import { Teleprompter } from './userAccount/teleprompter';
import { Service } from './../services';
import { VideoDeletion } from './userAccount/videoDeletion';
import { EmailBatches } from './userAccount/emailBatches';
import { RecentlyDeleted } from './userAccount/recentlyDeleted';

import {
  checkIfServicesAccessible,
  checkIfTemplatesAccessible,
  checkIfScheduleAccessible,
  checkIfUserIsTechnician,
} from 'lib/utils/automotiveRolePermissionChecks'; /** SUS-1234 changes **/
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { SocialProfiles } from './userAccount/socialProfiles/SocialProfiles';
import { YoutubeIntegration } from './userAccount/socialProfiles/YoutubeIntegration';
import { NotFoundTemplate } from '../notFound';
import { Container } from 'lib/components/styles/layout';
import { Schedule } from './userAccount/schedule';
import { CAMPING_WORLD_RESELLER_ID } from 'lib/const/SuperAdminConstants';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { AccessRole } from 'lib/const';

export const AccountRoutes = () => {
  const { userData, whitelabel } = useAuth();

  const isTrial = userData?.trialAccount;
  const isAttributesDisabled = !checkIfFeatureIsEnabled(
    userData,
    productFeature.VIDEO_ATTRIBUTES
  );
  const isTeleprompterDisabled = !checkIfFeatureIsEnabled(
    userData,
    productFeature.TELEPROMPTER
  );
  const isManageUsersDisabled = !checkIfFeatureIsEnabled(
    userData,
    productFeature.MANAGE_USERS_AS_TEAMS
  );
  const isExpirationDisabled = !checkIfFeatureIsEnabled(
    userData,
    productFeature.VIDEO_EXPIRATION
  );
  const isScheduleDisabled = !checkIfFeatureIsEnabled(
    userData,
    productFeature.SCHEDULES
  );
  const isTemplatesDisabled = !checkIfFeatureIsEnabled(
    userData,
    productFeature.TEMPLATES
  );

  const isSocialProfileDisabled = !(
    checkIfFeatureIsEnabled(userData, productFeature.FACEBOOK) &&
    checkIfFeatureIsEnabled(userData, productFeature.LINKEDIN) &&
    checkIfFeatureIsEnabled(userData, productFeature.YOUTUBE)
  );

  const { access, admin, resellerId, customerId, isCompanyAdmin } = userData;

  const showTeleprompter = [
    WHITELABEL_NAME.COVIDEO,
    WHITELABEL_NAME.APP_2_VID,
    WHITELABEL_NAME.PRO_VIDEO_NOW,
  ].includes(whitelabel.name);

  /** SUS-1234 changes profile/templates route are accessible to all CDS role but not with TECHNICIAN role **/
  const isTemplatesAccessible = checkIfTemplatesAccessible(userData);
  /** SUS-1234 changes profile/services route are accessible to CDS account with SERVICE_MANAGER and SERVICE_ADVISOR role only **/
  const isServicesAccessible = checkIfServicesAccessible(userData);
  const isScheduleAccessible = checkIfScheduleAccessible(userData) && !isTrial;
  const hasAddonsAccess =
    userData.resellerId !== CAMPING_WORLD_RESELLER_ID &&
    userData.access !== AccessRole.USER;

  let items: SidebarItem[] = [
    profile,
    company,
    { ...manageUsers, isDisabled: isManageUsersDisabled },
    billing,
    ...(hasAddonsAccess ? [addons] : []),
    videos,
    { ...videoAttributes, isDisabled: isAttributesDisabled },
    { ...videoExpiration, isDisabled: isExpirationDisabled },
    emails,
    emailBatches,
    others,
    { ...socialProfile, isDisabled: isSocialProfileDisabled },
    help,
    affiliate,
    recentlyDeleted,
    logout,
  ];

  // Show Video Deletion only for specific Customer
  if (items && !isCompanyAdmin) {
    items = items.filter(item => item.title !== videoExpiration.title);
  }

  // Show Affiliate only for Reseller ID 1.
  if (items && resellerId !== '1') {
    items = items.filter(item => item.title !== affiliate.title);
  }

  const adminItems = [
    company.title,
    manageUsers.title,
    videoAttributes.title,
    billing.title,
  ];
  const supervisorItems = [company.title, manageUsers.title, billing.title];

  const location = useLocation();
  const isAddOnDetailsPage = !!matchPath(location.pathname, {
    path: '/profile/add-ons/:type/:action',
    exact: true,
  });

  const isTeleprompterDetailsPage = !!matchPath(location.pathname, {
    path: '/profile/teleprompter/:scriptId',
  });

  const showSideNav = !isAddOnDetailsPage && !isTeleprompterDetailsPage;

  if (admin !== '1' || customerId !== '3') {
    items = items.filter(item => item.title !== emailBatches.title);
  }

  // SUS-435 remove Affiliate menu item in case of freemium user
  if (userData?.user?.packageDetails?.id === PackageName.FREE) {
    items = items.filter(item => item.title !== affiliate.title);
  }

  if (
    isManageUsersDisabled &&
    userData &&
    userData.user &&
    userData.user.packageDetails &&
    userData.user.packageDetails.id &&
    (userData.user.packageDetails.id === PackageName.PRO ||
      userData.user.packageDetails.id === PackageName.FREE)
  ) {
    items = items.filter(item => item.title !== manageUsers.title);
  }

  if (showTeleprompter) {
    const teleprompterLink = {
      ...teleprompterItem,
      isDisabled: isTeleprompterDisabled,
    };
    const i = items.find(i => i.title.includes(videos.title));
    const idx = i && items.indexOf(i);
    idx && idx > -1 && items.splice(idx + 1, 0, teleprompterLink);
  }

  if (isServicesAccessible) {
    const i = items.find(i => i.title.includes(emails.title));
    const idx = i && items.indexOf(i);
    idx && idx > -1 && items.splice(idx + 1, 0, servicesItem);
  }

  if (isScheduleAccessible) {
    const scheduleLink = {
      ...scheduleItem,
      isDisabled: isScheduleDisabled,
    };

    const i = items.find(i => i.title.includes(emails.title));
    const idx = i && items.indexOf(i);
    idx && idx > -1 && items.splice(idx + 1, 0, scheduleLink);
  }

  if (isTemplatesAccessible) {
    const snippetsLink = {
      ...snippetsItem,
      isDisabled: isTemplatesDisabled,
    };
    const i = items.find(i => i.title.includes(emails.title));
    const idx = i && items.indexOf(i);
    idx && idx > -1 && items.splice(idx + 1, 0, snippetsLink);
  }

  if (access !== '3') {
    if (access === '4') {
      items = items.filter(
        i => !adminItems.includes(i.title) || supervisorItems.includes(i.title)
      );
    } else {
      items = items.filter(i => !adminItems.includes(i.title));
    }
  }

  if (userData.preventUserEditAccess) {
    items = items.filter(item => item.title !== profile.title);
  }

  if (checkIfUserIsTechnician(userData)) {
    items = items.filter(item => item.title !== emails.title);
  }

  if (items && isTrial) {
    items = items.filter(item => item.title !== addons.title);
  }

  return (
    <>
      {showSideNav && <SidebarNavigation items={items} hasBack={false} />}
      <Container>
        <Switch>
          <Route path='/profile/upgrade-account' component={UpgradeToPaid} />
          <Route path='/profile/Profile_EditUserInfo'>
            <EditMyProfile edit={'user'} />
          </Route>

          {isCompanyAdmin && (
            <Route path='/profile/Profile_EditCompanyInfo'>
              <EditMyProfile edit={'company'} />
            </Route>
          )}
          {isCompanyAdmin && !isManageUsersDisabled && (
            <Route
              path='/profile/Profile_ManageUsers'
              component={() => {
                if (!isManageUsersDisabled) {
                  window.location.href = `https://${whitelabel.domainV2}/backoffice/Profile_ManageUsers.php`;
                  return null;
                } else {
                  return <Redirect to='/upgrade-plan' />;
                }
              }}
            />
          )}
          {access === '3' && (
            <Route
              path='/profile/Profile_VideoAttributes'
              component={
                !isAttributesDisabled
                  ? VideoAttributes
                  : () => <Redirect to='/upgrade-plan' />
              }
            />
          )}

          {isCompanyAdmin && (
            <Route
              path='/profile/Profile_VideoDeletion'
              component={VideoDeletion}
            />
          )}
          <Route
            path='/profile/Company_Reports'
            component={() => {
              window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_AdminReports.php`;
              return null;
            }}
          />
          <Route
            path='/profile/Profile_EditCreditCard'
            component={() => {
              window.location.href = `https://${whitelabel.domainV2}/backoffice/Profile_EditCreditCard.php`;
              return null;
            }}
          />
          {hasAddonsAccess && (
            <Route path='/profile/add-ons' component={AddOns} />
          )}
          <Route
            path='/profile/teleprompter'
            component={
              isTeleprompterDisabled
                ? () => <Redirect to={'/upgrade-plan'} />
                : Teleprompter
            }
          />
          <Route path='/profile/recently-deleted' component={RecentlyDeleted} />
          <Route
            path='/profile/social-profiles'
            component={
              !isSocialProfileDisabled
                ? SocialProfiles
                : () => <Redirect to='/upgrade-plan' />
            }
          />
          <Route path='/profile/youtube' component={YoutubeIntegration} />
          {/* SUS-1234 changes */}
          {isServicesAccessible && (
            <Route path='/profile/services' component={Service} />
          )}
          {/* SUS-1234 changes */}
          {isTemplatesAccessible && (
            <Route path='/profile/templates' component={Snippets} />
          )}
          {isScheduleAccessible && (
            <Route path='/profile/schedules' component={Schedule} />
          )}

          <Route
            path='/profile/Profile_AffiliateApply'
            component={BecomeAffiliate}
          />
          {admin === '1' && customerId === '3' && (
            <Route path='/profile/Email_Batches' component={EmailBatches} />
          )}

          {isCompanyAdmin && (
            <Route
              path='/profile/Profile_BillingInfo'
              component={() => {
                window.location.href = `https://${whitelabel.domainV2}/backoffice/Profile_BillingInfo.php`;
                return null;
              }}
              exact
            />
          )}
          {isCompanyAdmin && (
            <Route
              path='/users/list'
              component={
                isManageUsersDisabled
                  ? () => <Redirect to={'/upgrade-plan'} />
                  : Users
              }
            />
          )}
          {isCompanyAdmin && (
            <Route path='/users/entry' component={UserEntry} />
          )}
          {isCompanyAdmin && (
            <Route path='/users/entry/:id' component={UserEntry} />
          )}
          {isCompanyAdmin && (
            <Route path='/users/department/:id' component={UserDepartment} />
          )}
          {isCompanyAdmin && (
            <Route path='/users/department' component={UserEntry}>
              <Redirect to='/users/list' />
            </Route>
          )}
          {isCompanyAdmin && (
            <Route path='/users' component={UserEntry} exact>
              <Redirect to='/users/list' />
            </Route>
          )}

          {isCompanyAdmin && (
            <Route path='/billing' component={() => <Billings />} exact />
          )}
          {isCompanyAdmin && (
            <Route path='/billing/cards' component={() => <Cards />} exact />
          )}

          <Route path='/profile' exact>
            <Redirect to='/profile/Profile_EditUserInfo' />
          </Route>

          <Route path='*' component={NotFoundTemplate} />
        </Switch>
      </Container>
    </>
  );
};
