import * as React from 'react';
import { DocumentHead, LoadingIndicator, PageTemplate } from 'lib/components';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { text } from 'lib/utils';
import { VideoData } from 'lib/hooks';
import { usePlayerDesignQuery } from 'lib/api/playerDesign/usePlayerDesignQuery';
import { PreLoader } from '.';
import { NotFound } from 'app/pages/notFound/NotFound';
import { DeleteVideoModal } from 'lib/components/modal/DeleteVideoModal';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { useDeleteVideoMutation } from 'lib/api/videos/useDeleteVideoMutation';

interface MatchParams {
  videoId: string;
  view: string;
}

export const VideoDetails = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const { videoId, view } = props.match.params;
    const { history } = props;
    const [videoIdState, setVideoIdState] = React.useState(videoId);
    const [showVideoDeleteModal, setShowVideoDeleteModal] =
      React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [reloadVideoDetails, setReloadVideoDetails] = React.useState(false);
    const [videoDetails, setVideoDetails] = React.useState({} as VideoData);
    const { mutateAsync: deleteVideos } = useDeleteVideoMutation();

    const { data: videoAppearanceData, isLoading: videoAppearanceLoading } =
      usePlayerDesignQuery();

    const deleteVideo = async (videoId: string, history: History) => {
      await deleteVideos({ videoIds: [videoId], companyVideos: false });
      closeVideoDeleteModal();
      history.push('/');
    };

    const openVideoDeleteModal = () => {
      setShowVideoDeleteModal(true);
    };

    const closeVideoDeleteModal = () => {
      setShowVideoDeleteModal(false);
    };

    const renderDeleteVideoModal = () => {
      return (
        <DeleteVideoModal
          content={text.deleteModal.deleteModalBody}
          handleModalClose={closeVideoDeleteModal}
          handleModalSubmit={() => deleteVideo(videoIdState, history)}
          isCompanyVideo={false}
        />
      );
    };

    React.useEffect(() => {
      const getVideoDetails = async () => {
        setLoading(true);
        const video = await getVideo(videoId).catch(() => null);
        setVideoDetails(video);
        setLoading(false);
      };
      getVideoDetails();
    }, [reloadVideoDetails]);

    if (loading || videoAppearanceLoading || !videoAppearanceData) {
      return <LoadingIndicator isLoading={true} />;
    }

    if (!videoDetails) {
      return <NotFound />;
    }

    return (
      <>
        <DocumentHead title='Video Details' />
        <PageTemplate
          modal={showVideoDeleteModal ? [renderDeleteVideoModal()] : [null]}
          main={
            <PreLoader
              videoDetails={videoDetails}
              setReloadVideoDetails={setReloadVideoDetails}
              videoAppearanceData={videoAppearanceData}
              view={view}
              openVideoDeleteModal={openVideoDeleteModal}
              setVideoIdState={setVideoIdState}
            />
          }
        />
      </>
    );
  }
);
