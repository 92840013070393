import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { Main } from './main';
import { useAuth } from 'lib/context';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';

export const VideoMerge = () => {
  const { userData } = useAuth();

  React.useEffect(() => {
    if (userData.user?.packageDetails?.id) {
      const isMergeAllowed = checkIfFeatureIsEnabled(
        userData,
        productFeature.VIDEO_MERGE
      );
      if (!isMergeAllowed) {
        window.location.href = '/upgrade-plan';
      }
    }
  });

  return (
    <>
      <DocumentHead title='Merge' />
      <PageTemplate modal={[]} main={<Main />} />
    </>
  );
};
