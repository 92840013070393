import * as React from 'react';
import styled from 'styled-components/macro';
import { Dropdown } from 'lib/components';

interface Props {
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange(date: Date): void;
  extendStyles?: {
    container?: React.CSSProperties;
    menu?: React.CSSProperties;
    control?: React.CSSProperties;
    menuList?: React.CSSProperties;
    valueContainer?: React.CSSProperties;
    input?: React.CSSProperties;
    singleValue?: React.CSSProperties;
    option?: React.CSSProperties;
  };
}

const DatePickerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 1;
`;

const DropdownContainer = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  z-index: 9;
`;

const MIN_YEAR = 2010;
const MAX_YEAR = 2050;

const MIN_MONTH = 1;
const MAX_MONTH = 12;

const getYearOptions = (min: number, max: number) => {
  const options = [];
  for (let year = min; year <= max; year++) {
    options.push({
      label: `${year}`,
      value: year,
    });
  }

  return options;
};

const monthLabels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getMonthOptions = (min?: number, max?: number) => {
  const options = [
    {
      label: `Month`,
      value: -1,
    },
  ];
  for (let month = min ?? 1; month <= (max ?? 12); month++) {
    options.push({
      label: monthLabels[month - 1],
      value: month,
    });
  }

  return options;
};

const maxDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const getLeapYear = (year: number) => {
  // leap year if perfectly divisible by 400
  if (year % 400 == 0) {
    return true;
  } else if (year % 100 == 0) {
    return false;
  } else if (year % 4 == 0) {
    return true;
  } else {
    return false;
  }
};

const getDayOptions = (month: number, year: number) => {
  const options = [];

  const max =
    month === -1 || year === -1
      ? 31
      : month === 2 && getLeapYear(year)
        ? 29
        : maxDays[month];
  for (let day = 1; day <= max; day++) {
    options.push({
      label: `${day}`,
      value: day,
    });
  }

  return options;
};

export const DateDropdownPicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  extendStyles = undefined,
}: Props) => {
  const day = value ? value.getDate() : -1;
  const month = value ? value.getMonth() : -1;
  const year = value ? value.getFullYear() : -1;
  const minYear = minDate ? minDate.getFullYear() : MIN_YEAR;
  const maxYear = maxDate ? maxDate.getFullYear() : MAX_YEAR;

  const minMonth = minDate ? minDate.getMonth() : MIN_MONTH;
  const maxMonth = maxDate ? maxDate.getMonth() : MAX_MONTH;

  const yearOptions = getYearOptions(minYear, maxYear);
  const monthOptions = getMonthOptions(minMonth, maxMonth);
  const dayOptions = getDayOptions(month, year);

  return (
    <DatePickerContainer>
      <DropdownContainer width={136}>
        <Dropdown
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'Month'}
          value={monthOptions.find(option => option.value === month)}
          onChange={monthValue => {
            const newDate = value || new Date();
            newDate.setMonth(monthValue.value);
            onChange(newDate);
          }}
          options={monthOptions}
          extendStyles={extendStyles}
        />
      </DropdownContainer>
      <DropdownContainer width={118}>
        <Dropdown
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'Day'}
          value={dayOptions.find(option => option.value === day)}
          onChange={dayValue => {
            const newDate = value || new Date();
            newDate.setDate(dayValue.value);
            onChange(newDate);
          }}
          options={dayOptions}
        />
      </DropdownContainer>

      <DropdownContainer width={118}>
        <Dropdown
          height={40}
          creatable={false}
          className='dropdown'
          placeholder={'Year'}
          value={yearOptions.find(option => option.value === year)}
          onChange={yearValue => {
            const newDate = value || new Date();
            newDate.setFullYear(yearValue.value);
            onChange(newDate);
          }}
          options={yearOptions}
        />
      </DropdownContainer>
    </DatePickerContainer>
  );
};
