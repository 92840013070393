import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { InventoryItem } from 'lib/context';
import { toNumber } from 'lodash';

const CountVideosRecorded = styled.div`
  width: 120px;
  height: 24px;
  background: ${theme.palette.covideoSecondaryButtonBg};
  color: ${theme.palette.blue80};
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoVideosRecorded = styled.div`
  width: 120px;
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.palette.blackRgb60};
`;

type Props = {
  inventoryItem: InventoryItem;
};

export const TableFieldVideosRecorded = ({ inventoryItem }: Props) => {
  const videosRecorded = inventoryItem.videosRecorded
    ? toNumber(inventoryItem.videosRecorded)
    : 0;
  return (
    <>
      {videosRecorded ? (
        <CountVideosRecorded>{`${videosRecorded} video${
          videosRecorded > 1 ? 's' : ''
        }`}</CountVideosRecorded>
      ) : (
        <NoVideosRecorded>No Videos</NoVideosRecorded>
      )}
    </>
  );
};
