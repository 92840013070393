import React, { createContext, ReactNode, useContext, useState } from 'react';
import { ITeleprompterSettings, Speed } from './types';

export const TeleprompterContext = createContext(
  {} as {
    isUsingTeleprompter: boolean;
    showTeleprompterModal: boolean;
    teleprompterSettings: ITeleprompterSettings | null;
    isTeleprompterPlaying: boolean;
    isTeleprompterDisabled: boolean;
    saveTeleprompterSettingsHandler: (
      currentSettings: ITeleprompterSettings
    ) => void;
    onEditOrTurnOnTeleprompterHandler: () => void;
    onDropdownTeleprompterSpeedChange: (newValue: Speed) => void;
    toggleTeleprompterPlaying: () => void;
    onTeleprompterModalClose: () => void;
    turnOffTeleprompter: () => void;
    turnOnTeleprompter: () => void;
    turnOnTeleprompterPlaying: () => void;
    disableOrEnableTeleprompter: (isEnabled: boolean) => void;
  }
);

const fetchSettingsFromStorage = (): ITeleprompterSettings | null => {
  const settingsFromStorage = localStorage.getItem('TeleprompterSettings');
  if (settingsFromStorage === null) {
    return null;
  }

  try {
    const parsedData: ITeleprompterSettings = JSON.parse(settingsFromStorage);
    return parsedData;
  } catch (error) {
    console.error('Error parsing localStorage data:', error);
    return null;
  }
};

const saveSettingsToStorage = (data: ITeleprompterSettings | null): void => {
  localStorage.setItem('TeleprompterSettings', JSON.stringify(data));
};

export const TeleprompterProvider = ({ children }: { children: ReactNode }) => {
  // teleprompter state
  const [isUsingTeleprompter, setIsUsingTeleprompter] = useState(false);
  const [showTeleprompterModal, setShowTeleprompterModal] = useState(false);
  const [teleprompterSettings, setTeleprompterSettings] =
    useState<ITeleprompterSettings | null>(fetchSettingsFromStorage());
  const [isTeleprompterPlaying, setIsTeleprompterPlaying] = useState(false);
  const [isTeleprompterDisabled, setTeleprompterDisabled] = useState(false);

  const saveTeleprompterSettingsHandler = (
    currentSettings: ITeleprompterSettings
  ) => {
    setTeleprompterSettings(currentSettings);

    saveSettingsToStorage({
      ...currentSettings,
      activeScriptId: '',
      content: '',
    });
    setShowTeleprompterModal(false);
  };

  const onEditOrTurnOnTeleprompterHandler = () => {
    if (!isTeleprompterDisabled) {
      setIsUsingTeleprompter(true);
      setShowTeleprompterModal(true);
    }
  };

  const onDropdownTeleprompterSpeedChange = (newValue: Speed) => {
    setIsTeleprompterPlaying(false);
    setTeleprompterSettings({
      ...teleprompterSettings,
      speed: newValue,
      scriptSize: teleprompterSettings?.scriptSize || '',
    });
    setIsTeleprompterPlaying(true);
  };

  const toggleTeleprompterPlaying = () => {
    setIsTeleprompterPlaying(!isTeleprompterPlaying);
  };

  const onTeleprompterModalClose = () => {
    setShowTeleprompterModal(false);
    setIsUsingTeleprompter(false);
  };

  const turnOffTeleprompter = () => {
    setIsUsingTeleprompter(false);
  };
  const turnOnTeleprompter = () => {
    setIsUsingTeleprompter(true);
  };

  const turnOnTeleprompterPlaying = () => {
    setIsTeleprompterPlaying(true);
  };

  const disableOrEnableTeleprompter = (isEnabled: boolean) => {
    setTeleprompterDisabled(isEnabled);
  };

  return (
    <TeleprompterContext.Provider
      value={{
        isUsingTeleprompter,
        showTeleprompterModal,
        teleprompterSettings,
        isTeleprompterPlaying,
        isTeleprompterDisabled,
        saveTeleprompterSettingsHandler,
        onEditOrTurnOnTeleprompterHandler,
        onDropdownTeleprompterSpeedChange,
        turnOnTeleprompterPlaying,
        toggleTeleprompterPlaying,
        onTeleprompterModalClose,
        turnOffTeleprompter,
        turnOnTeleprompter,
        disableOrEnableTeleprompter,
      }}
    >
      {children}
    </TeleprompterContext.Provider>
  );
};

export const useTeleprompter = () => {
  const context = useContext(TeleprompterContext);
  if (context === undefined) {
    throw new Error(
      'useTeleprompter must be used within a TeleprompterProvider'
    );
  }
  return context;
};
