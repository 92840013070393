import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { contactsKeys } from './queryKeys';
import { IContact } from './getSingleContact';
import { EXPRESS_API } from 'configs/api/expressApi';
import { QueryContactsParams } from './types';

export interface IContacts {
  count: number;
  contacts: IContact[];
}

export const getContacts = async ({
  groupId,
  page = 0,
  size = 10,
  search,
  sort,
  conversation,
}: QueryContactsParams): Promise<IContacts> => {
  const start = page * size;
  const params = {
    start,
    groupId,
    limit: size,
    search: search,
    sort: sort,
    conversation,
  };

  return (
    await EXPRESS_API.get(`contacts`, {
      params,
    })
  ).data as IContacts;
};

export const useContactsQuery = (
  options: QueryContactsParams,
  enabled: boolean
) => {
  const { showError } = useToastError();

  return useQuery(contactsKeys.list(options), () => getContacts(options), {
    onError: showError,
    enabled,
    refetchOnMount: true,
  });
};
