import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { CheckboxInput, SelectionHeaderPopup } from 'lib/components';
import { MdDeleteForever } from 'react-icons/md';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { ISelectedItems } from './types';
import { RecentlyDeletedItems } from 'lib/api/recentlyDeletedApi';
import { useAuth } from 'lib/context';
import { RESELLER_IDS } from 'lib/const';

const BtnWrapper = styled.div`
  display: flex;
  gap: 19px;
`;

const CounterWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

type Props = {
  selectedItems: ISelectedItems;
  openDeleteModal: () => void;
  restoreModalToOpen: () => void;
  initialData: RecentlyDeletedItems[];
  changeSelectedItems: React.Dispatch<React.SetStateAction<ISelectedItems>>;
};

export const CTAHeader = ({
  selectedItems,
  openDeleteModal,
  restoreModalToOpen,
  initialData,
  changeSelectedItems,
}: Props) => {
  const { userData } = useAuth();
  const showDeleteForeverButton =
    userData?.resellerId?.toString() !== RESELLER_IDS.MOSS_AUTO_GROUP;
  const selectAll = () => {
    const selected = new Map();
    (initialData || []).map((item: any) =>
      selected.set(item.recentlyDeletedItemId, item)
    );
    changeSelectedItems({
      count: selected.size,
      data: new Map(selected),
    });
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    let { checked } = event.target as HTMLInputElement;
    if (checked) {
      selectAll();
      return;
    }
    changeSelectedItems({
      count: 0,
      data: new Map(),
    });
  };

  return (
    <>
      {!!selectedItems.count && (
        <SelectionHeaderPopup left='208px'>
          <CounterWrapper>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={
                selectedItems.count > 0 &&
                selectedItems.count !== initialData.length
              }
              checked={selectedItems.count === initialData.length}
              ignoreGrayForAllSelect={true}
              grayCheck={true}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={onCheckboxChange}
            />
            Items selected: <b>{selectedItems.count}</b>
          </CounterWrapper>
          <BtnWrapper>
            {showDeleteForeverButton && (
              <Button
                text={'Delete forever'}
                onClick={openDeleteModal}
                variant='destructive'
                icon={<MdDeleteForever />}
              />
            )}
            <Button
              text={'Restore'}
              onClick={restoreModalToOpen}
              variant='primary'
              icon={<FaArrowUpRightFromSquare />}
            />
          </BtnWrapper>
        </SelectionHeaderPopup>
      )}
    </>
  );
};
