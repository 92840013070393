import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';

type InputProps = {
  width: string;
  margin: string;
  radius: string;
};

const Input = styled.input.attrs({
  type: 'text',
})<InputProps>`
  box-sizing: border-box;
  height: 40px;
  box-shadow: none;
  outline: none;
  font-size: 16px;

  padding: 8px 12px;
  border: 1px solid ${theme.palette.gray40};
  border-radius: ${props => props.radius};
  width: ${props => props.width};
  margin: ${props => props.margin};
  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;
        background: ${theme.palette.gray10};
        border: 1px solid ${theme.palette.gray10};
      `;
    }
  }};
  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
    border: 1px solid ${theme.palette.covideoBlue100};
  }
`;

type Props = {
  margin?: string;
  width?: string;
  disabled?: boolean;
  value: string;
  onChange: any;
  radius?: string;
  placeholder?: string;
};

export const AutomotiveInput = ({
  value,
  onChange,
  width = '100%',
  margin = '0',
  disabled = false,
  radius = '4px',
  placeholder = '',
}: Props) => {
  return (
    <Input
      value={value}
      onChange={onChange}
      width={width}
      margin={margin}
      disabled={disabled}
      radius={radius}
      placeholder={placeholder}
    />
  );
};
