import React from 'react';
import { utcTimeToUserTime } from 'lib/utils/time';
import { useAuth } from 'lib/context';
import { NOTIFICATION_TYPES } from '../types';
import { ISingleNotification } from 'lib/api/notifications/getNotifications';
import { checkIfFreemium } from 'lib/utils/functions';

export const NotificationText = ({
  notification,
}: {
  notification: ISingleNotification;
}) => {
  const { userData } = useAuth();
  const isFreemium = checkIfFreemium(userData);
  const createdAt = utcTimeToUserTime(
    notification.createdAt,
    userData,
    'YYYY-MM-DDTHH:mm:ss.000Z'
  );
  switch (notification.type?.toUpperCase()) {
    case NOTIFICATION_TYPES.QUOTES:
      return (
        <>
          <b>
            Your service quote {notification.meta?.repairOrderId} has been
            updated.
          </b>
        </>
      );
    case NOTIFICATION_TYPES.FILES:
      return (
        <>
          <b>
            {`${notification.senderName}`} shared a file with you in your
            Covideo library.
          </b>
        </>
      );
    case NOTIFICATION_TYPES.BOARDS:
      return (
        <>
          <b>
            {`${notification.senderName}`} shared a board with you in your
            Covideo library.
          </b>
        </>
      );
    case NOTIFICATION_TYPES.SALES:
      return (
        <>
          <b>
            {`${notification.senderName}`} has scheduled a test drive with you.
          </b>
        </>
      );
    case NOTIFICATION_TYPES.CTA:
      return (
        <>
          {notification.senderName !== '' ? (
            <b>{`${notification.senderName} clicked a call-to-action on your video, ${notification.entityName}.`}</b>
          ) : (
            <b>{`A call-to-action on your video, ${notification.entityName}, was just clicked!`}</b>
          )}
        </>
      );
    case NOTIFICATION_TYPES.REPLY:
      return (
        <>
          {notification.senderName !== '' ? (
            <b>{`${notification.senderName} replied to your video, ${
              notification?.entityName
            }.`}</b>
          ) : (
            <b>
              {`Your video ${notification?.entityName} just received a reply!`}
              .`
            </b>
          )}
        </>
      );
    case NOTIFICATION_TYPES.VIEW:
      return (
        <>
          {notification.senderName !== '' && !isFreemium ? (
            <b>{`${notification.senderName} viewed your video, ${notification.entityName}.`}</b>
          ) : (
            <b>{`Your video ${notification.entityName} has just been viewed.`}</b>
          )}
        </>
      );
    case NOTIFICATION_TYPES.EMAIL_NOT_DELIVERED:
      return (
        <>
          {notification.senderName !== '' ? (
            <b>{`Email could not be delivered to ${notification.senderName}.`}</b>
          ) : (
            <b>{`Email could not be delivered.`}</b>
          )}
        </>
      );

    case NOTIFICATION_TYPES.MESSAGES:
      return (
        <>
          {notification.meta.header && <b>{notification.meta.header}</b>}
          {notification.meta.text && <div>{notification.meta.text}</div>}
        </>
      );
    case NOTIFICATION_TYPES.REACTIONS_COMMENTS:
      return (
        <>
          <b>
            There are new comments and reactions on your video,{' '}
            {notification.entityName}.
          </b>
        </>
      );
    case NOTIFICATION_TYPES.PUSHED_TO_VDP:
      return (
        <>
          <b>{`${notification.senderName} pushed the video, ${notification.entityName}, to a VDP.`}</b>
        </>
      );

    case NOTIFICATION_TYPES.LIVE:
      return (
        <>
          <b>{`Your guest just joined ${notification.entityName}.`}</b>
        </>
      );
    case NOTIFICATION_TYPES.VIDEO_DELETE:
      return (
        <>
          <b>{`${notification.senderName} requested video deletion to be prolonged for video: ${notification?.entityName}.`}</b>
        </>
      );
    case NOTIFICATION_TYPES.REPORTS:
      return (
        <>
          <b>{`The report ${
            notification.actionId
          } was shared by you on ${createdAt.substring(
            0,
            10
          )} at ${createdAt.substring(11, 16)}.`}</b>
        </>
      );
    case NOTIFICATION_TYPES.EMAIL_OPENED:
      return (
        <>
          <b>{`${notification.senderName} opened your video email.`}</b>
        </>
      );
    case NOTIFICATION_TYPES.VIDEO_REQUEST:
      return (
        <>
          <b>{`${notification.senderName} has sent you a service video request.`}</b>
        </>
      );
    default:
      return null;
  }
};
