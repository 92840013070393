import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9297a2;
  margin-bottom: 8px;
`;

export const FrequencyContainer = styled.div`
  display: flex;
`;

export const Container = styled.div<{ margin?: string }>`
  margin: ${props => props.margin || '24px 0 0 0'};
`;

export const DropdownContainer = styled.div`
  margin-left: 12px;
  & > .recurringDropdown > div {
    border-radius: 4px;
    > div {
      flex-wrap: nowrap;
    }
  }
  & > .recurringMonthlyDropdown {
    margin: 0 10px;
  }
  & > .recurringMonthlyDropdown > div {
    border-radius: 4px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  cursor: pointer;
  height: 24px;
  margin-right: 32px;
  > span {
    margin-left: 12px;
    margin-top: 2px;
  }
`;

export const DayContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Day = styled.div<{ activeBackgroundColor?: string }>`
  width: 40px;
  height: 40px;
  margin-left: 12px;
  padding: 8px 12px;
  border-radius: 5px;
  border: solid 1px #d0d3d9;
  background-color: #d0d3d9;
  box-sizing: border-box;

  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #9297a2;
  cursor: pointer;
  &.active {
    background-color: ${props => props.activeBackgroundColor || '#ff8b22'};
    color: #fff;
  }
`;
