import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { Gap } from 'lib/components/styles/layout';

const LinkBtn = styled(ParagraphNormal)`
  text-decoration: underline;
  cursor: pointer;
  color: ${theme.palette.blue100};
`;

export interface Crumb {
  label: string;
  link?: string;
}

interface Props {
  crumbs: Crumb[];
}

export const Breadcrumbs = ({ crumbs }: Props) => {
  const history = useHistory();
  return (
    <Gap m='0 0 24px' gap='1px'>
      {crumbs.map((crumb, i) => {
        if (crumbs.length - 1 !== i) {
          return (
            <Gap gap='8px' key={i}>
              <LinkBtn onClick={() => !!crumb.link && history.push(crumb.link)}>
                {crumb.label}
              </LinkBtn>
              <MdKeyboardArrowRight size={24} />
            </Gap>
          );
        }
        return (
          <ParagraphNormal color={theme.palette.gray80} key={i}>
            {crumb.label}
          </ParagraphNormal>
        );
      })}
    </Gap>
  );
};
