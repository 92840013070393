import { SOCKET_CONVERSATION_ACTIONS } from './actions';
import { CONVERSATION_STATUS, IConversationActions } from './types';

const setUsersOnline = (items: number[] | null) => {
  if (!items) {
    return {};
  }
  return items.reduce(
    (acc, curr) => ({ ...acc, [`${curr}`]: CONVERSATION_STATUS.ONLINE }),
    {}
  );
};

export const conversationReducer = (
  state: Record<string, string>,
  action: IConversationActions
) => {
  switch (action.type) {
    case SOCKET_CONVERSATION_ACTIONS.ONLINE:
      return {
        ...state,
        [action.payload]: CONVERSATION_STATUS.ONLINE,
      };
    case SOCKET_CONVERSATION_ACTIONS.OFFLINE:
      return {
        ...state,
        [action.payload]: CONVERSATION_STATUS.OFFLINE,
      };
    case SOCKET_CONVERSATION_ACTIONS.INIT:
      return {
        ...state,
        ...setUsersOnline(action.payload),
      };
    default:
      return { ...state };
  }
};
