import { useField } from 'formik';
import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { Label } from './Label';

interface TextInputProps {
  label?: string;
  showLabel?: boolean;
  placeholder?: string;
  name: string;
  id?: string;
  type?: string;
  width?: string;
  disabled?: boolean;
  isAutomotive?: boolean;
  onKeyUp?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void;
  limit?: number;
}

const InputGroup = styled.div<{ width: string }>`
  width: ${props => (props.width ? props.width : '100%')};
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: Work Sans;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
  padding: 8px 12px;
  &:disabled {
    border: 0;
    background-color: #f6f7f9;
  }
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;
export const TextInput = ({
  label,
  width = '',
  showLabel = true,
  isAutomotive,
  limit,
  ...props
}: TextInputProps) => {
  const [field, meta] = useField(props);

  if (!field.value) {
    field.value = '';
  }
  if (limit && field.value.length >= limit) {
    field.value = field.value.substr(0, limit);
  }

  return (
    <InputGroup width={width}>
      {showLabel && (
        <Label htmlFor={props.id || props.name} isAutomotive={isAutomotive}>
          {label && <div>{label}</div>}
        </Label>
      )}
      <Input {...field} {...props} maxLength={limit} />
      {meta.touched && meta.error ? (
        <ErrorMessage className="error">{meta.error}</ErrorMessage>
      ) : null}
    </InputGroup>
  );
};
