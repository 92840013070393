import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { guidesKeyes } from './guidesKeyes';

export interface EditableGuides {
  guideCategoryId: number;
  userId: number;
  customerId: number;
  name: string;
  order: number;
  private: boolean;
  createdAt: string;
  updatedAt: string;
}

const reorderCategories = async ({
  payload,
}: {
  payload: number[];
}): Promise<EditableGuides[]> => {
  return (
    await EXPRESS_API.patch(`guide-category/reorder`, {
      payload,
    })
  ).data as EditableGuides[];
};
export const useReorderCategoriesMutation = (closeModalHandler: () => void) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(reorderCategories, {
    onError: err => showError(err),
    onSuccess: async () => {
      await queryClient.refetchQueries(guidesKeyes.categorized());
      successToast({ title: 'You have successfully reorder categories!' });
      closeModalHandler();
    },
  });
};
