import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

interface Props {
  noPadding?: boolean;
  src: string;
  name?: string;
  id?: string;
  alt?: string;
}

const StyledLogo = styled.img<Props>`
  height: 16px;
  padding: ${props => (props.noPadding ? 0 : '20px 25px 12px 0')};
  ${theme.mediaQueryMinWidth.mb} {
    height: 30px;
  }
`;

export const Logo = (props: Props) => {
  return <StyledLogo {...props} />;
};
