import { PageHeader } from 'app/pages/account/userAccount/addOns/components/PageElements';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import React from 'react';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components/macro';
import { Modal } from './Modal';

interface Field {
  id: number;
  firstName: string;
  lastName: string;
  selectable: boolean;
}

type RemoveUserProps = {
  handleModalClose: () => void;
  handleModalConfirm: () => void;
  user: Field;
  type: string;
};

export const ModalRemoveUser = ({
  handleModalClose,
  handleModalConfirm,
  user,
  type,
}: RemoveUserProps) => {
  const ModalBody = styled.div`
    width: 592px;
    height: 196px;
  `;

  const ModalHeader = styled(PageHeader)`
    font-size: 18px;
    padding-right: 32px;
    margin-bottom: 16px;
    justify-content: space-between;
  `;

  const CloseButtonWrapper = styled.label`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: ${theme.palette.themeDark50Percent};
    &:hover {
      cursor: pointer;
    }
  `;

  const ModalMessage = styled.div`
    margin: 0 32px 0 32px;
  `;

  const ButtonsWrapper = styled.section`
    display: flex;
    flex-direction: row-reverse;
    margin-top: 48px;
    margin-right: 32px;
    button {
      margin-left: 15px;
    }
  `;

  const { ref } = useOnClickOutside(handleModalClose);

  return (
    <Modal>
      <div ref={ref}>
        <ModalBody>
          <ModalHeader>
            Remove {user.firstName} {user.lastName} from {type}?
            <CloseButtonWrapper>
              <IoMdClose size={24} onClick={handleModalClose} />
            </CloseButtonWrapper>
          </ModalHeader>
          <ModalMessage>
            {user.firstName} {user.lastName} will lose access to {type}
          </ModalMessage>
          <ButtonsWrapper>
            <Button
              text='Remove User'
              variant='red'
              onClick={handleModalConfirm}
            />
          </ButtonsWrapper>
        </ModalBody>
      </div>
    </Modal>
  );
};
