import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button } from 'react-covideo-common';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Dropdown } from 'lib/components/dropdown/Dropdown';
import { MainContainer, CheckboxInput } from 'lib/components';
import { fetchDiscountCoupons, postToCheckout } from 'lib/api';
import { CouponFromStripe, calculateFinalPrice } from '../utils';
import queryString from 'query-string';
import { useAuth } from 'lib/context';
import { errorToast } from 'lib/components/toasts/error';
import { isEnvDevelopment, isEnvProduction } from 'configs/api/enviroments';

enum BillingPackage {
  annual = 'annual',
  monthly = 'monthly',
}

type PeriodProps = {
  isSelected?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  background-color: #fff;
  border: 1px solid #dde1e8;
  margin: 16px 0 0 0;
  background: red;
`;

const Title = styled.h1`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${theme.palette.primaryDarkBlue};
  margin-top: 24px;
`;

const UsersInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  max-width: 608px;
  width: 100%;
`;

const NoOfUsersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 300px;
  width: 100%;
  height: 48px;
  p {
    margin: 0 8px 0 0;
    text-align: end;
  }
`;

const MoreThanFiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  max-width: 300px;
  width: 100%;
  padding: 0 0 0 16px;
  height: 48px;
  p {
    margin: 0;
    a {
      text-decoration: none;
    }
  }
`;

const BillingPeriodsWrapper = styled.section`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-top: 64px;
`;

const Row = styled.section`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-top: 48px;
  max-width: 608px;
  width: 100%;
  justify-content: flex-start;
  p {
    margin: 0 12px 0 0;
  }
  label {
    &:nth-child(2) {
      font-weight: 500;
      margin: 0 0 0 16px;
    }
  }
  &:nth-child(5) {
    margin-top: 32px;
  }
`;

const TotalAndButtonWrapper = styled.section`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: flex-end;
  width: 100%;
  padding: 40px 32px 32px 32px;
  p {
    margin: 0;
  }
  button {
    margin-left: 32px;
  }
`;

const PeriodSelector = styled.button<PeriodProps>`
  box-sizing: border-box;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 0;
  width: 300px;
  height: 48px;
  font-size: 24px;
  font-weight: 500;
  background-color: ${props =>
    props.isSelected ? theme.palette.primaryDarkBlue : 'transparent'};
  color: ${props =>
    props.isSelected ? '#FFF' : theme.palette.primaryDarkBlue};
  border: 1px solid ${theme.palette.primaryDarkBlue};
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:nth-child(2) {
    margin: 0 0 0 8px;
  }
  &:focus {
    outline: 0;
  }
  p {
    display: block;
    margin: 0 0 0 8px;
    padding: 0;
    font-size: 16px;
  }
`;

const TotalAmount = styled.h2`
  margin: 0 0 0 16px;
  padding: 0;
  color: ${theme.palette.themeDark};
`;

const Main = withRouter((props: RouteComponentProps) => {
  const parsed = queryString.parse(props.location.search);
  const { code } = parsed ? parsed : { code: null };
  const [numberOfUsers, setNumberOfUsers] = React.useState({
    value: 1,
    label: 1,
  });
  const [billingPeriod, setBillingPeriod] = React.useState(
    BillingPackage.annual
  );
  const [addAutoCaption, setAddAutoCaption] = React.useState(false);
  const [addTemplate, setAddTemplate] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [withCoupon, setWithCoupon] = React.useState(
    null as CouponFromStripe | null
  );
  const [finalPrice, setFinalPrice] = React.useState(0);

  const autoCaptioningPrice =
    billingPeriod === BillingPackage.annual ? 240 : 20;
  const basePrice = billingPeriod === BillingPackage.annual ? 588 : 69;
  const templatePrice = addTemplate ? 250 : 0;

  const calculatePrice = async () => {
    setIsProcessing(true);
    let couponData: CouponFromStripe | null = null;
    if (code && !Array.isArray(code) && !withCoupon) {
      couponData = await fetchDiscountCoupons({ code, testmode: 1 }).catch(
        err => err
      );
      if (couponData instanceof Error) {
        setIsProcessing(false);
        const result = calculateFinalPrice({
          basePrice,
          autoCaptionPrice: addAutoCaption ? autoCaptioningPrice : 0,
          templatePrice: addTemplate ? templatePrice : 0,
          users: numberOfUsers.value,
          couponData: null,
        });
        setFinalPrice(result);
        errorToast({
          title:
            'It seems the coupon code you provided is expired. If this is an error, please contact support.',
        });
        return;
      }
      const result = calculateFinalPrice({
        basePrice,
        autoCaptionPrice: addAutoCaption ? autoCaptioningPrice : 0,
        templatePrice: addTemplate ? templatePrice : 0,
        users: numberOfUsers.value,
        couponData,
      });
      setFinalPrice(result);
      setWithCoupon(couponData);
      setIsProcessing(false);
      return;
    }
    const result = calculateFinalPrice({
      basePrice,
      autoCaptionPrice: addAutoCaption ? autoCaptioningPrice : 0,
      templatePrice,
      users: numberOfUsers.value,
      couponData: withCoupon,
    });
    setFinalPrice(result);
    setIsProcessing(false);
    return;
  };

  type CheckoutBody = {
    upgrade: number;
    package: string;
    userCount: string;
    caption: string;
    template: string;
    code?: string;
  };

  const handleProceedToCheckout = async () => {
    const data: CheckoutBody = {
      upgrade: 1,
      package: billingPeriod,
      userCount: numberOfUsers.value.toString(),
      caption: addAutoCaption ? '1' : '0',
      template: addTemplate ? '1' : '0',
    };

    if (withCoupon && code && !Array.isArray(code)) {
      data.code = code;
    }
    const result = await postToCheckout(data).catch(err => err);
    if (result instanceof Error) {
      if (isEnvDevelopment) {
        console.error('\nDetails of the error posting to checkout ::', result);
      }
      errorToast({
        title: 'Something went wrong! Wait a few minutes and try again.',
      });
      return;
    }
    if (Number(result.err) === 0 && result.id !== null) {
      const sessionId = result.id;
      const stripeKey = isEnvProduction
        ? 'pk_live_Jk8BOP6Bf1Ln1oCPaUifbXKQ00EzbZw1S7'
        : 'pk_test_QjuUkxhZrwPP9ZsLQMR9mPYh00xrzsVGWH';
      const stripe = await loadStripe(stripeKey).catch(err => err);
      if (stripe instanceof Error) {
        const message = isEnvProduction
          ? 'Something went wrong with Stripe! Wait a few minutes and try again.'
          : `Error with stripe :: ${stripe.message}`;
        errorToast({ title: message });
        return;
      }
      if (stripe !== null) {
        try {
          stripe.redirectToCheckout({ sessionId });
        } catch (stripeError) {
          const message = isEnvProduction
            ? 'Something went wrong with Stripe! Wait a few minutes and try again.'
            : `Stripe Error :: ${stripeError.message}`;
          errorToast({ title: message });
        }

        return;
      }
      errorToast({
        title: 'Something went wrong! Wait a few minutes and try again.',
      });
      return;
    } else {
      if (isEnvDevelopment) {
        console.error(
          '\nDetails of the error result AFTER posting to checkout ::',
          result
        );
      }
      errorToast({
        title: 'Something went wrong! Wait a few minutes and try again.',
      });
      return;
    }
  };

  React.useEffect(() => {
    calculatePrice();
  }, [addTemplate, addAutoCaption, billingPeriod, numberOfUsers]);

  const userOptions = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 3,
      label: 3,
    },
    {
      value: 4,
      label: 4,
    },
    {
      value: 5,
      label: 5,
    },
  ];

  const { whitelabel } = useAuth();

  return (
    <MainContainer>
      <Container>
        <Title>Let's get to work</Title>
        <BillingPeriodsWrapper>
          <PeriodSelector
            onClick={() => setBillingPeriod(BillingPackage.annual)}
            isSelected={billingPeriod === BillingPackage.annual}
          >
            Annual <p>(29% Discount)</p>
          </PeriodSelector>
          <PeriodSelector
            onClick={() => setBillingPeriod(BillingPackage.monthly)}
            isSelected={billingPeriod === BillingPackage.monthly}
          >
            Monthly
          </PeriodSelector>
        </BillingPeriodsWrapper>
        <UsersInfoWrapper>
          <NoOfUsersWrapper>
            <p>Number of Users: </p>
            <Dropdown
              value={{ value: numberOfUsers.value, label: numberOfUsers.label }}
              options={userOptions}
              placeholder={'How many users?'}
              onChange={value => setNumberOfUsers(value)}
            />
          </NoOfUsersWrapper>
          <MoreThanFiveWrapper>
            <p>Need more than 5 users?</p>
            <p>
              <a href={whitelabel.homepage + '/contact'}>Contact us</a> for a
              group discount pricing
            </p>
          </MoreThanFiveWrapper>
        </UsersInfoWrapper>
        <Row>
          <CheckboxInput
            onChange={() => setAddAutoCaption(!addAutoCaption)}
            checked={addAutoCaption}
          />
          <label>
            Add Automatic Captioning{' '}
            {billingPeriod === BillingPackage.monthly
              ? `(+$${autoCaptioningPrice} / month)`
              : `(+$${autoCaptioningPrice} / year)`}
          </label>
        </Row>
        <Row>
          <CheckboxInput
            onChange={() => setAddTemplate(!addTemplate)}
            checked={addTemplate}
          />
          <label>Add a beautifully designed video template for $250</label>
        </Row>
        <TotalAndButtonWrapper>
          <p>
            Total {withCoupon ? '(including discounts from coupons):' : ':'}{' '}
          </p>
          <TotalAmount>$ {finalPrice}</TotalAmount>
          <Button
            disabled={isProcessing}
            onClick={handleProceedToCheckout}
            text={'Proceed To Checkout'}
          />
        </TotalAndButtonWrapper>
      </Container>
    </MainContainer>
  );
});

export default Main;
