import * as React from 'react';
import styled from 'styled-components/macro';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { theme } from 'lib/style';
import { PageButton } from 'lib/components/table/TablePagination';
import {
  PaginationInfoWrapper,
  Pages,
  PageNumber,
} from 'lib/components/table/TablePaginationNumbers';

interface Props {
  limit: number;
  page: number;
  totalCount: number;
  lastPage: number;
  currentCount: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

interface GeneratePagesParams {
  page: number;
  lastPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

interface GeneratedPages {
  pages: React.ReactNode[];
  pageOffset: number;
  lastIndex: number;
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 32px;
  width: calc(100vw - 376px);
  ${theme.mediaQueryMinWidth.xxlg} {
    width: 1396px;
  }
`;

const LeftSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
`;

const RightSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

const LimitText = styled.label`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: #4e5461;
`;

const Pagination = (props: Props) => {
  const { limit, page, totalCount, currentCount, lastPage, setPage } = props;

  const disableDecreaseButton = page === 0;
  const disableIncreaseButton = page * limit + limit >= totalCount;
  const { pages, pageOffset, lastIndex } = generatePages({
    page,
    lastPage,
    setPage,
  });

  const handleIncreasePage = increasePage(disableIncreaseButton, page, setPage);
  const handleDecreasePage = decreasePage(disableDecreaseButton, page, setPage);

  return (
    <Container>
      <LeftSection>
        <LimitText>
          Showing&nbsp;<b>{currentCount}</b>&nbsp;elements
        </LimitText>
      </LeftSection>
      <RightSection>
        <PageButton
          aria-label='Previous Page'
          onClick={handleDecreasePage}
          disabled={disableDecreaseButton}
        >
          <IoMdArrowDropleft />
        </PageButton>
        <PaginationInfoWrapper numberOfElements={lastIndex > 5 ? 5 : lastIndex}>
          <Pages offset={pageOffset}>{pages}</Pages>
        </PaginationInfoWrapper>
        <PageButton
          aria-label='Next Page'
          onClick={handleIncreasePage}
          disabled={disableIncreaseButton}
        >
          <IoMdArrowDropright />
        </PageButton>
      </RightSection>
    </Container>
  );
};

export default Pagination;

/*****************************************************************************
 ******************************* METHODS *************************************
 *****************************************************************************/

function increasePage(
  disableIncreaseButton: boolean,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>
) {
  return () => {
    if (!disableIncreaseButton) {
      const newValue = page + 1;
      setPage(newValue);
    }
  };
}

function decreasePage(
  disableDecreaseButton: boolean,
  page: number,
  setPage: React.Dispatch<React.SetStateAction<number>>
) {
  return () => {
    if (!disableDecreaseButton) {
      const newValue = page > 0 ? page - 1 : page;
      setPage(newValue);
    }
  };
}

function generatePages({
  page,
  lastPage,
  setPage,
}: GeneratePagesParams): GeneratedPages {
  let pages = [];
  let lastIndex = lastPage > page + 10 ? page + 10 : lastPage;
  let firstIndex = page > 3 ? page - 3 : page;
  if (lastPage < 6) {
    firstIndex = 0;
  } else if (lastPage - page < 4 && page - 4 > 0) {
    firstIndex = page - 4;
  }
  if (page === 3 && lastPage > 6) {
    firstIndex = page - 1;
  }

  for (let index = firstIndex; index < lastIndex; index++) {
    const element = (
      <PageNumber
        key={index}
        isSelected={index === page}
        onClick={() => setPage(index)}
      >
        {index + 1}
      </PageNumber>
    );
    pages.push(element);
  }

  let currentPagePosition = 0;
  if (page < 4) {
    currentPagePosition = 0;
  } else if (page > lastPage - 5) {
    currentPagePosition = 0;
  } else {
    currentPagePosition = 1;
  }
  const pageOffset = currentPagePosition * -40;
  return { pages, pageOffset, lastIndex };
}
