import React from 'react';
import { useAuth } from 'lib/context';
import { calculateCloseRatio } from 'lib/utils/automotive';
import { QUOTE_SETTINGS } from './RepairOrderListing';
import { QuoteItem } from 'lib/api/repairOrders/types';

type Props = {
  quoteItems: QuoteItem[];
};

export const TableCellCloseRatio = ({ quoteItems = [] }: Props) => {
  const {
    userData: { customer },
  } = useAuth();
  const { dollarCloseRatio, itemCloseRatio } = calculateCloseRatio(quoteItems);

  return (
    <b>
      {customer.quoteSettings === QUOTE_SETTINGS.ITEM
        ? itemCloseRatio
        : dollarCloseRatio}
    </b>
  );
};
