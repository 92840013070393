import * as React from 'react';
import styled from 'styled-components/macro';

import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { useLandingPageBuilderContext } from '../context';
import { MdDeleteForever } from 'react-icons/md';
import {
  sectionTypeAndIconMapping,
  defaultDisplayNamesForSections,
} from './Constants';
import {
  EditLogoSection,
  EditCTAsSection,
  EditEmbedVideoSection,
  EditLinkSection,
  EditSocialLinksSection,
  EditVideoPlayerSection,
  ChangeBackgroundComponent,
  ChangePositionComponent,
  EditImageSection,
} from './editSectionComponents';
import { EditTextSection } from './editSectionComponents/EditTextSection';
import { ModalDeleteSection } from './modal/ModalDeleteSection';
import { Button } from 'react-covideo-common';

interface Props {
  width?: string;
  setShowBackButton?: any;
}

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
  gap?: string;
}

interface TextProps {
  fontWeight?: number;
  fontSize?: string;
}

const Container = styled.div`
  width: 100%;
  flex-wrap: wrap;
  padding-left: 10px;
  .close-icon {
    margin-left: 15px;
    cursor: pointer;
  }
  padding-right: 10px;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 20px;
`;

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px')};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
  gap: ${props => props.gap || 0};
`;

const Text = styled.span<TextProps>`
  font-weight: ${props => props.fontWeight || '400'};
  font-size: ${props => props.fontSize || '14px'};

  line-height: 20px;
`;

export const EditSectionView = (props: Props) => {
  const [showDeleteSectionModal, setShowDeleteSectionModal] =
    React.useState(false);
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    elementToEdit,
    setElementToEdit,
    elementSectionKey,
    setElementSectionKey,
    elementIndex,
    setElementIndex,
  } = useLandingPageBuilderContext();
  const { setShowBackButton } = props;

  const data = { ...selectedLayoutData };
  if (!elementSectionKey || !data) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  if (!sectionDetails) {
    return <></>;
  }

  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }

  const sectionType = sectionDetails.sectionType;
  const isVisible = isElementCase
    ? data[elementSectionKey].childSections[elementIndex]?.isVisible
    : data[elementSectionKey]?.isVisible;
  const IconElement = sectionTypeAndIconMapping[sectionType];

  const toggleVisibility = () => {
    sectionDetails.isVisible = !isVisible;
    if (isElementCase) {
      data[elementSectionKey].childSections[elementIndex] = sectionDetails;
    } else {
      data[elementSectionKey] = sectionDetails;
    }
    setSelectedLayoutData(data);
    setElementToEdit(sectionDetails);
  };

  const deleteSection = () => {
    if (isElementCase) {
      data[elementSectionKey].childSections.splice(elementIndex, 1);
    } else {
      reShuffleLayoutDataAndDelete();
    }

    setSelectedLayoutData(data);
    setElementToEdit(null);
    setElementSectionKey(null);
    setElementIndex(null);
  };

  function reShuffleLayoutDataAndDelete() {
    let key = elementSectionKey;
    let dataLength = Object.keys(data).length;
    try {
      key = parseInt(key);
    } catch (ex) {}

    // i is less than or equal to dataLength because in LPB keys are starting from 1 not 0;
    for (let i = key + 1; i <= dataLength; i++) {
      data[key] = data[i];
      key = i;
    }
    delete data[key];
    return;
  }

  const getEditView = () => {
    return (
      <>
        {showDeleteSectionModal == true ? (
          <ModalDeleteSection
            onDelete={() => {
              deleteSection();
            }}
            modalLoading={false}
            data={{
              title:
                sectionDetails.sectionDisplayName ||
                defaultDisplayNamesForSections[sectionType] ||
                'Section',
            }}
            onClose={() => {
              setShowDeleteSectionModal(false);
            }}
          />
        ) : (
          <></>
        )}
        <Row alignItems={'center'} justifyContent={'space-between'}>
          <Row
            padding={'0px'}
            width={'auto'}
            display={'flex'}
            alignItems={'center'}
          >
            <IconElement />
            &nbsp;
            <Text fontWeight={600}>
              {sectionDetails.sectionDisplayName ||
                defaultDisplayNamesForSections[sectionType] ||
                'Section'}
            </Text>
          </Row>
          {sectionType != 2 ? (
            <Row
              padding={'0px'}
              width={'auto'}
              display={'flex'}
              alignItems={'center'}
              gap={'8px'}
            >
              <Button
                variant='secondary'
                icon={isVisible ? <IoMdEyeOff /> : <IoMdEye />}
                text={isVisible ? 'Hide' : 'Show'}
                onClick={toggleVisibility}
              />
              <Button
                variant='destructive'
                icon={<MdDeleteForever size={24} />}
                onClick={() => {
                  setShowDeleteSectionModal(true);
                }}
              />
            </Row>
          ) : (
            <></>
          )}
        </Row>
        {getViewForSection(sectionType)}
      </>
    );
  };

  function getViewForSection(sectionType: number) {
    switch (sectionType) {
      case 1: {
        return (
          <>
            {/* <EditHeaderSection /> */}
            <ChangeBackgroundComponent setShowBackButton={setShowBackButton} />
          </>
        );
      }

      case 2: {
        return (
          <>
            <EditVideoPlayerSection />
            <ChangePositionComponent />
            <ChangeBackgroundComponent setShowBackButton={setShowBackButton} />
          </>
        );
      }

      case 3: {
        return (
          <>
            <EditCTAsSection />
            <ChangePositionComponent />
            {!isElementCase && (
              <ChangeBackgroundComponent
                setShowBackButton={setShowBackButton}
              />
            )}
          </>
        );
      }
      case 4: {
        return (
          <>
            <EditSocialLinksSection />
            <ChangePositionComponent />
            {!isElementCase && (
              <ChangeBackgroundComponent
                setShowBackButton={setShowBackButton}
              />
            )}
          </>
        );
      }
      case 5: {
        return (
          <>
            <EditTextSection />
            <ChangePositionComponent />
            {!isElementCase && (
              <ChangeBackgroundComponent
                setShowBackButton={setShowBackButton}
              />
            )}
          </>
        );
      }
      case 6: {
        return (
          <>
            <EditImageSection setShowBackButton={setShowBackButton} />
          </>
        );
      }
      case 7: {
        return (
          <>
            <EditEmbedVideoSection />
            <ChangePositionComponent />
            <ChangeBackgroundComponent setShowBackButton={setShowBackButton} />
          </>
        );
      }
      case 8: {
        return (
          <>
            <EditLinkSection />
            <ChangePositionComponent />
            <ChangeBackgroundComponent setShowBackButton={setShowBackButton} />
          </>
        );
      }
      case 9: {
        return (
          <>
            <EditLogoSection setShowBackButton={setShowBackButton} />
            <ChangePositionComponent />
          </>
        );
      }
      default:
        return (
          <>
            <ChangePositionComponent />
            <ChangeBackgroundComponent setShowBackButton={setShowBackButton} />
          </>
        );
    }
  }

  return (
    <>
      <Container>{getEditView()}</Container>
    </>
  );
};
