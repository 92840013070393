import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { isEqual } from 'lodash';
import RouteLeavingGuard from 'app/pages/video/videoDetails/main/RouteLeavingGuard';
import { ButtonSwitch, LoadingIndicator, NewModal } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphNormalBold } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';
import { HOW_TO_SHARE_MODAL } from '../const';
import { useVisibilityContextProvider } from '../provider/context';
import { SelectableArea } from '../selectable/SelectableArea';
import { HowToShareModal } from './HowToShareModal';
import { RevokeAccessModal } from './RevokeAccessModal';
import { extractCheckedIds } from '../selectable/utils';
import { LeaveWithoutSaveVisibility } from './LeaveWithoutSaveVisibility';
import { useVisibilityPermissionMutation } from 'lib/api/guides/visibilityMutation';
import { useVisibilityQuery } from 'lib/api/guides/visibilityQuery';
import { useQueryClient } from 'react-query';
import { guidesKeyes } from 'lib/api/guides/guidesKeyes';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { useAuth } from 'lib/context';
import { usePrivateGuidesQueryMutation } from 'lib/api/guides/privateGuidesQuery';
import {
  SelectedGuideCategoryType,
  privacy,
  privacyOptions,
} from '../main/pages/constants';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
  selectedGuideCategory: SelectedGuideCategoryType;
}

export const ModalHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 37px;
  background: ${theme.palette.white};
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 3;
`;
export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: ${theme.palette.coal};
  cursor: pointer;
`;

const preparePayload = (
  role: SuperAdminRole,
  payload: {
    resellerIds: number[];
    customerIds: number[];
    userIds: number[];
    sharedWithCovideo: boolean;
  }
) => {
  if (role === SuperAdminRole.COVIDEO_SUPER_ADMIN) {
    return payload;
  }
  if (role >= SuperAdminRole.RESELLER_ADMIN && role < SuperAdminRole.ACCESS) {
    return {
      resellerIds: payload.resellerIds,
      customerIds: payload.customerIds,
      userIds: payload.userIds,
    };
  }
  if (role === SuperAdminRole.ACCESS) {
    return {
      customerIds: payload.customerIds,
      userIds: payload.userIds,
    };
  }
};

export const VisibilityModal = ({
  closeModalHandler,
  selectedGuideCategory,
}: IProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { userData } = useAuth();
  const { data, isLoading } = useVisibilityQuery(
    selectedGuideCategory?.guideCategoryId
  );

  const { mutate: togglePrivacyMutation, isLoading: isUpdatingPrivacy } =
    usePrivateGuidesQueryMutation();

  const { mutateAsync, isLoading: isSaving } =
    useVisibilityPermissionMutation(closeModalHandler);
  const showModalForTheFirstTime = !!localStorage.getItem(HOW_TO_SHARE_MODAL)
    ? false
    : true;
  const [showHowToShareModal, setShowHowToShareModal] = useState<boolean>(
    showModalForTheFirstTime
  );
  const [showRevokeAccessPrompt, setShowRevokeAccessPrompt] =
    useState<boolean>(false);
  const [showLeaveWithoutSavingPrompt, setShowLeaveWithoutSavingPrompt] =
    useState<boolean>(false);

  const { state } = useVisibilityContextProvider();

  const closeHowToShareModal = () => setShowHowToShareModal(false);
  const openHowToShareModal = () => setShowHowToShareModal(true);

  const defaultPrivacyValue: string = selectedGuideCategory?.private
    ? privacyOptions[0].value
    : privacyOptions[1].value;

  const isEditable = selectedGuideCategory?.isEditable;

  if (isLoading || !data) {
    return <></>;
  }

  return (
    <>
      <RouteLeavingGuard
        when={true}
        stay={true}
        onConfirm={() => {}}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => {
          return showLeaveWithoutSavingPrompt;
        }}
        title='Leave without saving the visibility settings?'
        text='Your visibility settings will not be saved. This action can’t be undone.'
        confirmButtonText='Continue'
        discardButtonText='Leave'
      />
      <NewModal
        hideHeader
        closeModal={closeModalHandler}
        style={{ content: { width: '100vw', height: '100vh' } }}
      >
        {showLeaveWithoutSavingPrompt && (
          <LeaveWithoutSaveVisibility
            setOpen={setShowLeaveWithoutSavingPrompt}
            closeModal={closeModalHandler}
          />
        )}
        {showRevokeAccessPrompt && (
          <RevokeAccessModal
            closeModal={closeModalHandler}
            setOpen={setShowRevokeAccessPrompt}
            selectedGuideCategory={selectedGuideCategory}
          />
        )}
        <ModalHeader>
          <Gap gap='48px'>
            <CloseWrapper
              onClick={() => {
                const init = extractCheckedIds(data.resellers);
                const initialData = {
                  ...init,
                  sharedWithCovideo: data.sharedWithCovideo,
                };
                const current = extractCheckedIds(state.resellers);
                const currentData = {
                  ...current,
                  sharedWithCovideo: data.sharedWithCovideo,
                };

                const isDataEqual = isEqual(initialData, currentData);
                if (isDataEqual) {
                  queryClient.removeQueries(guidesKeyes.visibility());
                  return closeModalHandler();
                }
                setShowLeaveWithoutSavingPrompt(true);
              }}
            >
              <MdClose size='18' />
            </CloseWrapper>
            <Button
              text='How to Share?'
              onClick={openHowToShareModal}
              variant='white'
            />
          </Gap>

          <ParagraphNormalBold color={theme.palette.gray100}>
            Show “{selectedGuideCategory?.name}” to...
          </ParagraphNormalBold>
          <Gap flexDirection='row' gap='8px'>
            {isEditable && (
              <ButtonSwitch
                disabled={isUpdatingPrivacy}
                defaultValue={defaultPrivacyValue}
                values={privacyOptions}
                onChange={newValue => {
                  if (selectedGuideCategory?.guideCategoryId) {
                    const isPrivate = newValue === privacy.PRIVATE;
                    togglePrivacyMutation({
                      isPrivate,
                      categoryId: selectedGuideCategory?.guideCategoryId,
                    });
                  }
                }}
              />
            )}
            <Button
              text='Revoke Access To All'
              onClick={() => setShowRevokeAccessPrompt(true)}
              disabled={isSaving}
              variant='destructive'
            />
            <Button
              text='Add View Permission'
              disabled={isSaving}
              variant='primary'
              onClick={() => {
                const { resellerIds, customerIds, userIds } = extractCheckedIds(
                  state.resellers
                );
                const payload = preparePayload(userData.superAdminRole, {
                  resellerIds,
                  customerIds,
                  userIds,
                  sharedWithCovideo: state.sharedWithCovideo,
                }) as {
                  resellerIds?: number[];
                  customerIds?: number[];
                  userIds: number[];
                  sharedWithCovideo: boolean;
                };

                if (selectedGuideCategory) {
                  const categoryId = selectedGuideCategory.guideCategoryId;
                  mutateAsync({ categoryId, ...payload });
                }
              }}
            />
          </Gap>
        </ModalHeader>

        {isSaving ? (
          <div>
            <LoadingIndicator isLoading={true} />
          </div>
        ) : (
          <SelectableArea openHowToShareModal={openHowToShareModal} />
        )}
        {showHowToShareModal && (
          <HowToShareModal closeModalHandler={closeHowToShareModal} />
        )}
      </NewModal>
    </>
  );
};
