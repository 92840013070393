import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

type UpdateVideoAttributeByVideoProps = {
  videoId: string;
  data: object[];
};

export const putVideoAttributes = async ({
  videoId,
  data,
}: UpdateVideoAttributeByVideoProps) => {
  const { data: videoAttributes } = await EXPRESS_API.put(
    `/videos/${videoId}/video-attributes`,
    data
  );

  return { videoAttributes };
};

export const useEditVideoAttributeByVideoId = () => {
  return useMutation(putVideoAttributes, {
    onSuccess: () => {
      successToast({ title: 'Details successfully saved' });
    },
    onError: () => {
      errorToast({ title: 'Details update failed' });
    },
  });
};
