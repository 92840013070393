import styled from 'styled-components/macro';

export const SectionTitle = styled.div`
  display: flex;
  align-items: left;
  flex-direction: row;
  justify-content: left;
  width: 100%;

  font-weight: 800;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  color: #1d1e24;
  margin-right: auto;
  max-width: 360px;
  overflow: hidden;
  text-align: left !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
