import { APP_ENVIROMENT, CurrentEnvProps } from './types';

const APP_ENVIRONMENT = {
  DEVELOPMENT: APP_ENVIROMENT.DEVELOPMENT,
  STAGING: APP_ENVIROMENT.STAGING,
  SANDBOX: APP_ENVIROMENT.SANDBOX,
  PRODUCTION: APP_ENVIROMENT.PRODUCTION,
  SUSTAINING: APP_ENVIROMENT.SUSTAINING,
};

const getEnv = (env: string) =>
  process.env.REACT_APP_ENV ===
  (APP_ENVIRONMENT as Record<string, string>)[env];

const isEnvDevelopment =
  process.env.REACT_APP_ENV === APP_ENVIROMENT.DEVELOPMENT ||
  getEnv(APP_ENVIROMENT.DEVELOPMENT);
const isEnvStaging = getEnv(APP_ENVIROMENT.STAGING);
const isEnvSandbox = getEnv(APP_ENVIROMENT.SANDBOX);
const isEnvProduction = getEnv(APP_ENVIROMENT.PRODUCTION);
const isEnvSustaining = getEnv(APP_ENVIROMENT.SUSTAINING);

const getCurrentEnvUrl = ({
  defaultValue,
  ...envOptions
}: CurrentEnvProps): string => {
  const env = process.env.REACT_APP_ENV;
  const envValue = envOptions[env as keyof typeof envOptions];
  return envValue !== undefined ? envValue : defaultValue;
};

export {
  isEnvDevelopment,
  isEnvStaging,
  isEnvSandbox,
  isEnvProduction,
  isEnvSustaining,
  getCurrentEnvUrl,
};
