import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';

export const Main = () => {
  return (
    <Switch>
      <Route path='/profile/services' component={List} exact={true} />
    </Switch>
  );
};
