import * as React from 'react';
import styled from 'styled-components/macro';
import { IoMdCloseCircle } from 'react-icons/io';
import { FaHashtag } from 'react-icons/fa';

const Tag = styled.span`
  height: 18px;

  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  border-radius: 5px;
  padding: 4px 8px;
  background: rgba(0, 27, 83, 0.05);
  color: #4e5461;
  flex: none;
  margin: 6px 8px 0 0;
  .hashtag-icon {
    margin-bottom: -2px;
    margin-right: 2px;
  }
  .close-icon {
    margin-bottom: -3px;
    margin-left: 3px;
    &:hover {
      cursor: pointer;
      path {
        fill: rgba(0, 27, 83, 0.6);
      }
    }
  }
`;

export const VideoTag = ({
  tag,
  showRemove = false,
  onRemove = () => {},
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  children,
}: any) => {
  return (
    <Tag
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className='video-tag'
      key={tag.value}
    >
      {!!tag.value && <FaHashtag className='hashtag-icon' />}
      {tag.label}
      {showRemove && (
        <IoMdCloseCircle
          onClick={() => onRemove(tag)}
          className='close-icon'
          color='rgba(0, 27, 83, 0.4)'
        />
      )}
      {children}
    </Tag>
  );
};
