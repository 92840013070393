import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router';
import { guidesKeyes } from './guidesKeyes';

const deleteGuide = async ({
  videoIds,
}: {
  videoIds: number[];
}): Promise<void> => {
  return (
    await EXPRESS_API.delete(`guides`, {
      data: { videoIds },
    })
  ).data;
};

export const useDeleteGuidesMutation = (
  resetAndCloseModal: () => void,
  search: string,
  guideCategoryId: number,
  page?: number,
  setPage?: (value: number) => void,
  availableGuides?: number | undefined
) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  const { id } = useParams() as { id: string };
  const { pathname } = useLocation();
  const history = useHistory();
  return useMutation(deleteGuide, {
    onError: err => showError(err),
    onSuccess: async () => {
      await queryClient.refetchQueries(
        guidesKeyes.singleCategory(guideCategoryId + '-slider-' + page)
      );
      resetAndCloseModal();
      if (page && setPage && availableGuides === 1) {
        setPage(page - 1);
      }
      queryClient.invalidateQueries(guidesKeyes.search(search));
      id && (await queryClient.refetchQueries(guidesKeyes.singleCategory(id)));
      await queryClient.invalidateQueries(guidesKeyes.categorized());
      queryClient.invalidateQueries(guidesKeyes.new_count());
      pathname.includes('classic') &&
        history.push(`/guides/${guideCategoryId}`);
      successToast({ title: 'You have successfully deleted guides!' });
    },
    mutationKey: guidesKeyes.deleteMutationKey(guideCategoryId),
  });
};
