export const SmsOptions = [
  {
    value: '1d',
    label: 'Today',
  },
  {
    value: '7d',
    label: 'Last 7 days',
  },
  {
    value: 'MTD',
    label: 'Month to Date',
  },
  {
    value: '30d',
    label: 'Last 30 Days',
  },
  {
    value: 'YTD',
    label: 'Year to Date',
  },
];

export const SmsOptionsMapping: any = {
  '1d': 'Today',
  '7d': 'Last 7 days',
  MTD: 'Month to Date',
  '30d': 'Last 30 Days',
  YTD: 'Year to Date',
};
