import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CloseButton, Modal } from 'lib/components';
import { Spinner } from '../Spinner';
import { Button } from 'react-covideo-common';
import { Gap } from '../styles/layout';
import { MdSave } from 'react-icons/md';
import selectors from '../../../cypress/selectors';

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 100%;
  max-width: 592px;
  background-color: #fff;
`;

const Content = styled.div`
  width: 528px;
  height: 224px;
  padding: 32px;
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  outline: none !important;
  padding: 12px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  width: 502px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: ${theme.palette.black_1_100};
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: ${theme.palette.label};
  margin-top: 32px;
`;

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
  title?: string;
  initTitle?: string;
  text?: any;
  handleModalClose: () => void;
  handleSubmit: (data: string) => void;
};

export const ModalMergeCreateNew = ({
  disabled = false,
  handleModalClose,
  handleSubmit,
  isLoading = false,
  initTitle = '',
}: Props) => {
  const [title, setTitle] = React.useState(initTitle);

  // Added changes for SUS-520
  const cleanedTitle = (title || '').trim();

  return (
    <Modal style={{ position: 'fixed' }}>
      <ModalItem>
        <CloseButton onClick={handleModalClose} />
        <Content>
          <ContentBody>
            <Title>Save your merged video</Title>
            <Label>Name of the video</Label>
            <Input
              type='text'
              required={true}
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value)
              }
              data-cy={selectors.mergeVideosPage.mergedVideoNameInput}
            />
          </ContentBody>
          <Gap justifyContent='flex-end' m='24px 0 0'>
            <Button
              onClick={() => handleSubmit(title)}
              text={'Save'}
              variant='primary'
              disabled={disabled || !cleanedTitle || isLoading}
              icon={isLoading ? <Spinner /> : <MdSave size={18} />}
              data-cy={selectors.mergeVideosPage.saveNewVideo}
            />
          </Gap>
        </Content>
      </ModalItem>
    </Modal>
  );
};
