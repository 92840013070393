import axios from 'axios';
import { useMutation } from 'react-query';

export type UploadToAwsSignedUrlParams = {
  uploadURL: string;
  file: File;
  mimeType: string;
};
interface IUploadToAwsSignedUrlResponse {
  uploadUrl: string;
  downloadUrl: string;
}
export const uploadToAwsSignedUrl = async (
  params: UploadToAwsSignedUrlParams
): Promise<IUploadToAwsSignedUrlResponse> => {
  // upload to aws signed url, do not use EXPRESS_API
  const response = await axios.put(params.uploadURL, params.file, {
    headers: {
      'Content-Type': params.mimeType,
    },
  });
  return response.data;
};

export const useUploadToAwsSignedUrlMutation = () => {
  return useMutation(uploadToAwsSignedUrl);
};
