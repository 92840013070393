import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

interface IEditCustomReportPayload {
  title: string;
  range: string;
  startDate: Date;
  endDate: Date;
  reports: string[];
  users: string[];
  reportId: string;
}

interface CustomReportResponse {
  title: string;
  range: string;
  startDate: string;
  endDate: string;
  reports: string[];
  users: string[];
  includeNewUsers: boolean;
  userId: number;
  reportId: number;
}

const editCustomReport = async (
  payload: IEditCustomReportPayload
): Promise<CustomReportResponse> => {
  const { reportId } = payload;
  const response = await EXPRESS_API.put(`/custom-reports/${reportId}`, {
    ...payload,
    startDate: payload.startDate?.toISOString(),
    endDate: payload.endDate?.toISOString(),
  });
  return response.data;
};

export const useEditCustomReportMutation = () => {
  return useMutation(editCustomReport, {
    onSuccess: () => {
      successToast({ title: 'Custom report edited successfully' });
    },
    onError: () => {
      errorToast({ title: 'Something went wrong editing the report' });
    },
  });
};
