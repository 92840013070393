import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path="/files" component={List} exact={true} />
      <Route path="/files/:dropId/:dropType" component={Details} />
    </Switch>
  );
};
