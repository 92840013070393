import { saveAs } from 'file-saver';
import { ResponseType } from 'axios';
import { VideoTranscriptionBody } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

export const downloadTranscript = async (
  videoId: string,
  format: string,
  fileName: string
) => {
  const response = await EXPRESS_API.get(`/transcripts/${videoId}/download`, {
    params: {
      format,
    },
    responseType: 'blob' as ResponseType,
  });

  return saveAs(
    new Blob([response.data], { type: `text/${format}` }),
    fileName
  );
};

export const createTranscription = async (
  videoId: string,
  data: VideoTranscriptionBody
) => {
  const response = await EXPRESS_API.post(`/transcripts/${videoId}`, data);
  return response.data;
};
