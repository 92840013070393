import { EXPRESS_API } from 'configs/api/expressApi';

export type SmsUsersParams = {
  search?: string;
  page?: number;
  size?: number;
  onlyUsersUsingSMS?: boolean;
  sFrom?: string;
  rFrom?: string;
  sTo?: string;
  rTo?: string;
};

export const getSmsOverview = async (params: any) => {
  params.utcOffset = new Date().getTimezoneOffset();

  const response = await EXPRESS_API.get(`/sms/overview`, {
    params,
  });

  return response.data;
};

export interface IChatHistory {
  chatId: number;
  userId: number;
  contactPhone: string;
  ownerFirstName: string | null;
  ownerLastName: string | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  email: string | null;
  contactId: null;
  sentCount: string;
  receivedCount: string;
  lastMessageCreatedAt: string;
  lastMessageText: string;
  userPhone: string;
  lastReadAt: string | null;
}

export interface ISingleChat extends IChatHistory {
  messages: [];
}

export const getSmsUsers = async ({
  search = '',
  page = 0,
  size = 10,
  onlyUsersUsingSMS = false,
  sFrom,
  rFrom,
  sTo,
  rTo,
}: SmsUsersParams) => {
  const params = {
    start: page * size,
    limit: size,
    onlyUsersUsingSMS,
    sFrom,
    rFrom,
    sTo,
    rTo,
  };

  if (search) {
    // @ts-ignore
    params.search = search;
  }

  const response = await EXPRESS_API.get(`/sms/users`, { params });

  return response.data;
};

export const reassignSmsChat = async (
  payload: {
    userId: string;
    note: string;
  },
  chatId: string
) => {
  const response = await EXPRESS_API.put(`/chats/${chatId}/reassign`, payload);

  return response.data;
};

export const updateDefaultUser = async (data: { userId: number }) => {
  const response = await EXPRESS_API.put(`/sms/default-user`, data);

  return response.data;
};

export const sendSms = async (phone: string, text: string) => {
  const data = {
    to: phone,
    text,
  };
  const response = await EXPRESS_API.post(`/sms/send`, data);

  return response.data;
};

export const recipientAndSender = async ({
  from,
  to,
}: {
  from: string;
  to: string;
}) => {
  const data = {
    from,
    to,
  };
  const response = await EXPRESS_API.post(`/chats/recipientAndSender`, data);

  return response.data;
};

export const getChatHistory = async (chatId: any) => {
  const params = { unansweredMessages: true };

  const response = await EXPRESS_API.get(`/chats/${chatId}`, {
    params,
  });

  return response.data;
};
