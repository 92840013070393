import { guidesKeyes } from 'lib/api/guides/guidesKeyes';
import { Guides } from 'lib/api/guides/guidesQuery';
import { useSingleCategorizedGuidesQuery } from 'lib/api/guides/singleCategoryQuery';
import React, { useEffect, useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { useIsMutating } from 'react-query';
import styled from 'styled-components/macro';
import { CategorizedLoader } from './main/CategorizedLoader';
import { DeleteGuideModal } from './modals/DeleteGuideModal';
import { Thumbnail } from './Thumbnail';
import { Button } from 'react-covideo-common';

const SliderContainer = styled.div`
  top: calc(50% - 30px);
  position: absolute;
  z-index: 1;
`;

const PrevSliderContainer = styled(SliderContainer)`
  left: -20px;

  @media (max-width: 1280px) {
    display: none;
  }
`;
const NextSliderContainer = styled(SliderContainer)`
  right: -20px;

  @media (max-width: 1280px) {
    display: none;
  }
`;

interface IProps {
  guides: Guides[];
  guideCategoryId: number;
  count: number;
  isEditable?: boolean;
}

export const ThumbnailCategorizedList = ({
  guides,
  guideCategoryId,
  count,
  isEditable,
}: IProps) => {
  const SIZE = 4;
  const [page, setPage] = useState(0);
  const [isArrowClicked, setArrowClicked] = useState(false);
  const [internalModal, setInternalModal] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState<number[] | undefined>(
    undefined
  );
  const deleteMutationCount = useIsMutating({
    mutationKey: guidesKeyes.deleteMutationKey(guideCategoryId),
  });
  const { data, isLoading, refetch, isFetching } =
    useSingleCategorizedGuidesQuery(
      `${guideCategoryId}`,
      {
        page,
        size: SIZE,
      },
      `slider-${page}`
    );

  useEffect(() => {
    if (!isArrowClicked) {
      return;
    }
    refetch();
  }, [page]);

  const dataToShow = data?.guides ? data.guides : guides;

  const decrementPage = () => setPage(prevState => prevState - 1);
  const incrementPage = () => {
    setPage(prevState => prevState + 1);
    setArrowClicked(true);
  };

  if (isLoading || isFetching || deleteMutationCount > 0) {
    return <CategorizedLoader />;
  }
  const totalPages = Math.ceil(count / SIZE);
  const isNextDisabled = page + 1 < totalPages || deleteMutationCount > 0;

  const closeModalHandler = () => {
    setInternalModal(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      {page !== 0 && (
        <PrevSliderContainer>
          <Button
            variant='ghost'
            onClick={decrementPage}
            icon={<MdArrowBack />}
          />
        </PrevSliderContainer>
      )}
      <div style={{ display: 'flex', gap: 13 }}>
        {dataToShow?.map(guide => (
          <Thumbnail
            guide={guide}
            key={guide.videoId}
            hasActions={isEditable}
            selectedGuides={[]}
            hasCheckbox={false}
            setModals={() => setInternalModal(true)}
            handleGuideSelection={() => {
              setCurrentVideoId([guide.videoId]);
            }}
            skipLast
          />
        ))}
      </div>
      {isNextDisabled && (
        <NextSliderContainer>
          <Button
            icon={<MdArrowForward />}
            onClick={incrementPage}
            variant='ghost'
          />
        </NextSliderContainer>
      )}
      {internalModal && (
        <DeleteGuideModal
          closeModalHandler={closeModalHandler}
          guideCategoryId={guideCategoryId}
          page={page}
          setPage={setPage}
          selectedGuides={currentVideoId}
          availableGuides={dataToShow.length}
        />
      )}
    </div>
  );
};
