import React, { useState } from 'react';
import onboardingStepThreeAnimationForMyself from 'lib/animations/OnBoarding_Step_3.json';
import myself from 'assets/images/freemiumOnboarding/mySelf.svg';
import { ItemSelector, FreemiumOnboardingPageTemplate } from '../components';
import { shareVideoOptionsForMyself } from '../const';
import { useFreemiumOnboardingQuery } from 'lib/api/freemiumOnboarding/getFreemiumOnboarding';
import { LoadingIndicator } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';

export const StepThreeForMyself = () => {
  const { data: freemiumOnboardingData, isLoading } =
    useFreemiumOnboardingQuery();
  const [selectedShareValues, setSelectedShareValues] = useState<string[]>(
    freemiumOnboardingData?.sharing?.split(',') || []
  );

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <FreemiumOnboardingPageTemplate
      currentStep={3}
      stepText='I will share my videos with...'
      nextStepRoute='/onboarding-freemium/well-done'
      goToNextStepDisabled={selectedShareValues.length < 1}
      rightColumnAnimationSrc={onboardingStepThreeAnimationForMyself}
      dataToBeSaved={{
        sharing: selectedShareValues.join(','),
      }}
      rightColumnChildren={
        <Gap justifyContent='center'>
          <img alt='myself' src={myself} />
        </Gap>
      }
      leftColumnChildren={
        <ItemSelector
          infoText='Select all that applies'
          itemHeight='152px'
          itemWidth='132px'
          items={shareVideoOptionsForMyself}
          multiselect={true}
          selectedItems={selectedShareValues}
          setSelectedValues={setSelectedShareValues}
        />
      }
    />
  );
};
