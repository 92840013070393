import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { emailIconsKeys } from './queryKeys';
import { GetEmailThumbnailsResponse } from '../types';
import { EXPRESS_API } from 'configs/api/expressApi';

type QueryParams = {
  start?: number;
  limit?: number;
  search?: string;
};

const getEmailIcons = async ({
  start = 0,
  limit = 10,
  search,
}: QueryParams): Promise<GetEmailThumbnailsResponse> => {
  const params = {
    start,
    limit,
    search,
  };

  return (
    await EXPRESS_API.get(`emailicons`, {
      params,
    })
  ).data as GetEmailThumbnailsResponse;
};

export const useGetEmailIconsQuery = (options: QueryParams) => {
  const { showError } = useToastError();
  return useQuery(emailIconsKeys.all(), () => getEmailIcons(options), {
    onError: showError,
    refetchOnMount: true,
  });
};
