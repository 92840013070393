import {
  ActivateFiles,
  DeactivateFiles,
  GetFilesStats,
} from 'lib/api/addonsApi';
import { Modal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { ModalConfirmSubscription } from 'lib/components/modal/ModalConfirmSubscription';

import { LoadingIndicator } from 'lib/components';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import React from 'react';
import { IoMdClose, IoMdInformationCircle } from 'react-icons/io';
import styled from 'styled-components/macro';
import {
  ActiveHeader,
  AddonFooter,
  OptionContainer,
  PageContainer,
  PageDescription,
  PageHeader,
} from '../components/PageElements';
import { ManageFilesInactive } from './ManageFilesInactive';
import { ManageFilesActive } from './ManageFilesActive';
import { ManageSeats } from '../components/ManageSeats';
import { errorToast } from 'lib/components/toasts/error';

const SeatsContainer = styled(OptionContainer)`
  height: 320px;
`;

export const ManageFiles = () => {
  const [loading, setLoading] = React.useState(true);
  const [globals, setGlobals] = React.useState({
    active: false,
    currentTotalUsers: 1,
    currentCostPerUser: 10,
    users: [],
  });

  const [updatedGlobals, setUpdatedGlobals] = React.useState<{
    active: boolean;
    currentTotalUsers: number;
    currentCostPerUser: number;
  }>();

  const checkFileAndBoardsIntegration = () => {
    setLoading(true);
    GetFilesStats()
      .then(data => {
        setGlobals({
          active: data.activated,
          currentTotalUsers: data?.licenses?.total,
          currentCostPerUser: 10,
          users: data?.licenses?.users,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        errorToast({ title: 'Something went wrong. Try again later' });
      });
  };

  React.useEffect(() => {
    checkFileAndBoardsIntegration();
  }, []);

  const FilesActiveHeader = styled(ActiveHeader)`
    position: initial;
    width: 45px;
    margin-left: 10px;
  `;

  const SeatsHeader = styled.div`
    font-style: SemiBold;
    font-size: 16px;
    line-height: 24px;
    text-align: end;
    width: 100%;
    button {
      margin-left: 15px;
    }
  `;

  const ModalBody = styled.div`
    width: 592px;
    height: 504px;
  `;

  const ModalHeader = styled(PageHeader)`
    font-size: 18px;
    padding-right: 32px;
    justify-content: space-between;
  `;

  const ModalDescription = styled(PageDescription)`
    background-color: #b6e0ec40;
    padding-left: 0px;
    margin: 0 36px 0 36px;
    padding: 12px 12px 12px 12px;
    font-size: 15px;
    color: #272a32;
  `;

  const InfoIcon = styled.div`
    font-size: 24px;
    margin-right: 8px;
    opacity: 0.6;
    color: #001b53;
  `;

  const ModalDescriptionLabel = styled.div``;

  const CloseButtonWrapper = styled.label`
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: ${theme.palette.themeDark50Percent};
    &:hover {
      cursor: pointer;
    }
  `;

  const ModalChangeSubscription = styled(SeatsContainer)`
    max-width: none;
    width: 100%;
    border: none;
    box-shadow: none;
  `;

  const [showChangeSubscription, setShowChangeSubscription] =
    React.useState(false);
  const { ref } = useOnClickOutside(() => {
    setShowChangeSubscription(false);
  });

  const UpdateGlobals = () => {
    checkFileAndBoardsIntegration();
  };

  if (loading) {
    return <LoadingIndicator isLoading={loading} height='500px' />;
  }

  return (
    <PageContainer>
      {updatedGlobals && (
        <ModalConfirmSubscription
          subscriptionType={3}
          oldSelection={globals.currentTotalUsers}
          newSelection={
            updatedGlobals.active ? updatedGlobals.currentTotalUsers : 0
          }
          handleModalClose={() => {
            setUpdatedGlobals(undefined);
          }}
          handleModalConfirm={() => {
            let tempGlobals = updatedGlobals;
            setUpdatedGlobals(undefined);

            if (tempGlobals.active) {
              ActivateFiles(tempGlobals.currentTotalUsers).then(() => {
                //
              });
            } else {
              DeactivateFiles().then(() => {
                //
              });
            }

            setGlobals({
              currentTotalUsers: tempGlobals.currentTotalUsers,
              currentCostPerUser: tempGlobals.currentCostPerUser,
              active: tempGlobals.active,
              users: globals.users,
            });
          }}
        />
      )}
      {showChangeSubscription && (
        <Modal>
          <div ref={ref}>
            <ModalBody>
              <ModalHeader>
                Change subscription
                <CloseButtonWrapper>
                  <IoMdClose
                    size={'24px'}
                    onClick={() => {
                      setShowChangeSubscription(false);
                    }}
                  />
                </CloseButtonWrapper>
              </ModalHeader>
              <ModalDescription>
                <InfoIcon>
                  <IoMdInformationCircle width={'24px'} height={'24px'} />
                </InfoIcon>
                <ModalDescriptionLabel>
                  Next month’s invoice will reflect the largest number of seats
                  you had active this month.
                </ModalDescriptionLabel>
              </ModalDescription>
              <ModalChangeSubscription>
                <ManageSeats
                  Type='files'
                  SaveButtonText='Save subscription'
                  Globals={globals}
                  Update={(userCount, costPer, active) => {
                    setShowChangeSubscription(false);
                    setUpdatedGlobals({
                      active: active,
                      currentTotalUsers: userCount,
                      currentCostPerUser: costPer,
                    });
                    checkFileAndBoardsIntegration();
                  }}
                  AutoSave={false}
                />
                <Button
                  text='Cancel subscription for Files & Boards'
                  variant='destructive'
                  onClick={() => {
                    setShowChangeSubscription(false);
                    setUpdatedGlobals({
                      active: false,
                      currentTotalUsers: globals.currentTotalUsers,
                      currentCostPerUser: globals.currentCostPerUser,
                    });
                  }}
                />
              </ModalChangeSubscription>
            </ModalBody>
          </div>
        </Modal>
      )}
      <PageHeader>
        <div style={{ width: '250px' }}>Files & Boards</div>
        {globals.active && (
          <>
            <FilesActiveHeader>Active</FilesActiveHeader>
            <SeatsHeader>
              {globals.currentTotalUsers} seat
              {globals.currentTotalUsers > 1 ? 's' : ''} - $
              {globals.currentTotalUsers * globals.currentCostPerUser}/mo
              <Button
                text='Change subscription'
                variant='secondary'
                onClick={() => {
                  setShowChangeSubscription(true);
                }}
              />
            </SeatsHeader>
          </>
        )}
      </PageHeader>
      {!globals.active ? (
        <ManageFilesInactive Globals={globals} Update={UpdateGlobals} />
      ) : (
        <ManageFilesActive Globals={globals} />
      )}
      <AddonFooter>
        Have a question? Contact your account manager, contact us at
        support@covideo.com or give us a call at 1.800.306.1445.
      </AddonFooter>
    </PageContainer>
  );
};
