import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { multilocationKeys } from './multilocationsKeys';
import { useParams } from 'react-router';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IRemoveChildCustomer {
  customerId: number;
}

const removeChildCustomer = async (
  params: IRemoveChildCustomer
): Promise<{ name: string }> => {
  const { customerId } = params;

  return (
    await EXPRESS_API.delete(`superadmin/multilocation/${customerId}/location`)
  ).data;
};

export const useRemoveChildCustomerMutation = (
  closeModalHandler: () => void
) => {
  const { customerId } = useParams() as { customerId: string };
  const queryClient = useQueryClient();
  return useMutation(removeChildCustomer, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(multilocationKeys.single(customerId));
      successToast({ title: 'You have successfully deleted location!' });
      closeModalHandler();
    },
  });
};
