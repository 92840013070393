import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { multilocationKeys } from './multilocationsKeys';
import { useParams } from 'react-router';
import { EXPRESS_API } from 'configs/api/expressApi';

interface ILocationRename {
  locationId: number;
  name: string;
}

const renameLocation = async (
  params: ILocationRename
): Promise<{ name: string }> => {
  const { locationId, ...body } = params;

  return (
    await EXPRESS_API.patch(`superadmin/multilocation/${locationId}/location`, {
      ...body,
    })
  ).data;
};

export const useMultiLocationRenameMutation = (
  closeModalHandler: () => void
) => {
  const { customerId } = useParams() as { customerId: string };
  const queryClient = useQueryClient();
  return useMutation(renameLocation, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(multilocationKeys.single(customerId));
      successToast({ title: 'You have successfully changed location name!' });
      closeModalHandler();
    },
  });
};
