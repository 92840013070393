import * as React from 'react';
import styled from 'styled-components/macro';

interface RadioProps {
  name: string;
  disabled?: boolean;
  text?: string | React.ReactNode;
  removePadding?: boolean;
  onChange?: (e?: any) => void;
  checked?: any;
  value: string | number | string[];
  onClick?: (e?: any) => void;
  checkedColor?: string;
}

interface RadioButtonProps {
  checkedColor?: string;
}

interface ContainerProps {
  removePadding?: boolean;
  htmlFor: string;
  onClick?: () => void;
  value: string | number | string[];
}

const Container = styled.label<ContainerProps>`
  display: flex;
  padding: ${({ removePadding }) => (removePadding === true ? '0' : '5px 0')};
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  &:hover ~ span {
    opacity: 0.5;
  }
`;

const Dot = styled.span<RadioProps>`
  box-sizing: border-box;
  position: relative;
  height: 24px;
  width: 24px;
  background-color: rgba(0, 27, 83, 0.05);
  border-radius: 50%;
  border: solid 1px #d0d3d9;
  margin-right: 0.5rem;
  margin-left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InsideDot = styled.span`
  background: #fff;
  border-radius: 50%;
  height: 8px;
  width: 8px;
`;

const Radio = styled.input.attrs({ type: 'radio' })<RadioButtonProps>`
  appearance: none;
  width: 0px;
  cursor: pointer;
  &:checked ~ span {
    background-color: ${props =>
      props.checkedColor
        ? props.checkedColor
        : props.theme.colors.primary[100]};
    border: 3px solid
      ${props =>
        props.checkedColor
          ? props.checkedColor
          : props.theme.colors.primary[100]};
  }
`;

export const RadioInput = (props: RadioProps) => {
  const { value, checkedColor, text, removePadding, ...rest } = props;
  return (
    <Container
      onClick={props.onClick}
      htmlFor={rest.name}
      removePadding={removePadding}
      value={value}
    >
      <Radio checkedColor={checkedColor} value={value} {...rest} />
      <Dot value={value} {...rest}>
        {props.checked && <InsideDot />}
      </Dot>
      {text}
    </Container>
  );
};
