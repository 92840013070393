import React from 'react';
import styled from 'styled-components/macro';
import { ButtonSwitch } from 'lib/components';
import { theme } from 'lib/style';
import { successToast } from 'lib/components/toasts/success';
import { DropItem } from 'lib/api/droplr/types';
import { Button } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import { MdOutlineContentCopy } from 'react-icons/md';

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const FileTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4e5461;
`;

const ButtonSwitchWrapper = styled.div`
  margin-top: 16px;
  .privacy-switch > div {
    width: 33%;
    > button {
      width: 100%;
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
    }
  }
  > div {
    margin-left: 0;
    > span {
      width: 33%;
    }
  }
`;

const PrivacyDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  text-align: center;
  margin-top: 16px;
`;

const PasswordWrapper = styled.div`
  margin-top: 16px;
`;

const Label = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.midGrey};
  margin-bottom: 8px;
`;

const Password = styled.input`
  box-sizing: border-box;
  width: 70%;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 8px 12px;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const ButtonContainer = styled.div<any>`
  width: 144px;
  height: 48px;
  margin-left: auto;
  button {
    color: white;
    border-radius: 4px;
  }
`;

const URLContainer = styled.input.attrs({
  type: 'text',
})<any>`
  display: none;
  background: #ffffff;

  border: 1px solid #d0d3d9;
  border-radius: 4px;
  width: 100%;
  height: 24px;
  max-width: 376px;
  font-size: 16px;
  padding: 12px;
  &:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
  }
  ${theme.mediaQueryMinWidth.mb} {
    display: block;
  }
`;

const privacy = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  TEAM: 'TEAM',
};

const privacyOptions = [
  {
    value: privacy.PUBLIC,
    text: 'Public',
  },
  {
    value: privacy.PRIVATE,
    text: 'Private',
  },
  {
    value: privacy.TEAM,
    text: 'Team Only',
  },
];

type Props = {
  drop: DropItem | null;
  dropPrivacy: string;
  setDropPrivacy: any;
  password: string;
  setPassword: any;
  showFileTitle?: boolean;
  displayCopyUrl?: boolean;
};

const getShortLink = (url: string, privacyOption: string): string => {
  if (privacyOption === privacy.TEAM) {
    return `${url}?type=team`;
  }

  if (privacyOption === privacy.PRIVATE) {
    return `${url}?type=private`;
  }

  return url;
};

export const FilePrivacySection = (props: Props) => {
  const {
    drop,
    dropPrivacy,
    setDropPrivacy,
    password,
    setPassword,
    showFileTitle = true,
    displayCopyUrl = false,
  } = props;

  const [displayUrl, setDisplayUrl] = React.useState(
    getShortLink(drop?.shortlink || '', dropPrivacy)
  );

  React.useEffect(() => {
    setDisplayUrl(getShortLink(drop?.shortlink || '', dropPrivacy));
  }, [dropPrivacy]);

  const listener = (e: ClipboardEvent) => {
    if (e.clipboardData) {
      e.clipboardData.setData('text/html', displayUrl || '');
      e.clipboardData.setData('text/plain', displayUrl || '');
    }
    e.preventDefault();
  };

  const onClickCopy = () => {
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    successToast({ title: 'Link Copied!' });
  };

  const copyPassword = () => {
    const cb = navigator.clipboard;
    cb.writeText(password || '').then(() => {
      successToast({ title: 'Password copied successfully!' });
    });
  };

  return (
    <Content>
      {showFileTitle && drop && <FileTitle>Filename: {drop.title}</FileTitle>}

      <ButtonSwitchWrapper>
        <ButtonSwitch
          className='privacy-switch'
          defaultValue={dropPrivacy}
          values={privacyOptions}
          onChange={newValue => {
            setDropPrivacy(newValue);
          }}
        />
      </ButtonSwitchWrapper>

      {dropPrivacy === privacy.PRIVATE && (
        <PasswordWrapper>
          <Label>Password</Label>
          <Gap gap='8px'>
            <Password
              type='text'
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            <Button
              variant='secondary'
              text='Copy password'
              onClick={() => {
                copyPassword();
              }}
            />
          </Gap>
        </PasswordWrapper>
      )}

      <PrivacyDescription>
        {dropPrivacy === privacy.PUBLIC && (
          <>Your file will be viewable to anyone with a link.</>
        )}
        {dropPrivacy === privacy.PRIVATE && (
          <>Your file will only be viewable to people who have this password.</>
        )}
        {dropPrivacy === privacy.TEAM && (
          <>Your file will be viewable to anyone in your team.</>
        )}
      </PrivacyDescription>
      {drop && displayCopyUrl && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: '75px',
          }}
        >
          <URLContainer value={displayUrl} readOnly />
          <ButtonContainer>
            <Button
              icon={<MdOutlineContentCopy size={18} />}
              onClick={onClickCopy}
              text={'Copy URL'}
              disabled={!drop.shortlink}
            />
          </ButtonContainer>
        </div>
      )}
    </Content>
  );
};
