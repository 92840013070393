import React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { Dropdown } from 'lib/components';
import { Reseller } from 'lib/context';
import { useUpdateResellerStatusMutation } from 'lib/api/superadmin/useUpdateResellerStatusMutation';
import { Button } from 'react-covideo-common';

export enum RESELLER_STATUS_OPTIONS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export const activeStatusOptions = [
  { value: RESELLER_STATUS_OPTIONS.ACTIVE, label: 'Active' },
  { value: RESELLER_STATUS_OPTIONS.INACTIVE, label: 'Inactive' },
];

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedResellers: Reseller[];
  onSuccessfullUpdate(): void;
  setSelectedResselers: (selectedCustomers: Reseller[]) => void;
};

type OptionType = {
  value: RESELLER_STATUS_OPTIONS;
  label: string;
};

export const ChangeResellerStatusModal = ({
  handleModalClose,
  selectedResellers,
  onSuccessfullUpdate,
  setSelectedResselers,
}: Props) => {
  const [resellerStatus, setResellersStatus] =
    useState<null | RESELLER_STATUS_OPTIONS>(null);

  const headerText = `Change Status for ${selectedResellers.length} reseller${
    selectedResellers.length > 1 ? 's' : ''
  }!`;

  const onSuccessCallback = () => {
    handleModalClose();
    onSuccessfullUpdate();
    setSelectedResselers([]);
  };

  const { mutateAsync: mutateUpdateResellerStatus, isLoading } =
    useUpdateResellerStatusMutation(onSuccessCallback);

  const onUpdateResellerMutationHandler = () => {
    if (resellerStatus === null) {
      return;
    }
    mutateUpdateResellerStatus({
      resellerIds: selectedResellers.map(reseller => reseller.resellerId),
      active: resellerStatus,
    });
  };

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose()}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Header>
        <Content>You are about to {headerText}</Content>
      </Header>
      <Content>
        <Dropdown
          menuPortalTarget={document.body}
          menuPosition='fixed'
          menuPlacement={'bottom'}
          options={activeStatusOptions}
          value={activeStatusOptions.find(option => {
            return option && option.value === resellerStatus;
          })}
          onChange={(option: OptionType) => setResellersStatus(option.value)}
          placeholder={'Select New Status'}
        />
        <SubmitButtonWrapper>
          <Button
            text={'Change Status'}
            onClick={onUpdateResellerMutationHandler}
            disabled={isLoading || resellerStatus === null}
          />
        </SubmitButtonWrapper>
      </Content>
    </NewModal>
  );
};
