import React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { Modal, TextInput } from 'lib/components';
import { theme } from 'lib/style';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  onSubmit: (title: string) => void;
  handleModalClose: () => void;
};

export const ModalTemplateName = (props: Props) => {
  const [title, setTitle] = React.useState('');
  const { handleModalClose, onSubmit } = props;

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  return (
    <Modal>
      <div style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Name the Annotation Template</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <TextInput
            value={title}
            onChange={event => setTitle(event.target.value)}
          />
        </Content>
        <Footer>
          <Button
            text='Save'
            disabled={!title.trim()}
            onClick={() => {
              handleModalClose();
              onSubmit(title);
            }}
          />
        </Footer>
      </div>
    </Modal>
  );
};
