import * as React from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';
import { Arrow } from 'lib/components';
import { DropdownOptions, Option } from './DropdownOptions';

type SelectProps = {
  isOpen: boolean;
};

const Select = styled.div<SelectProps>`
  box-sizing: border-box;
  position: relative;
  border: 1px solid
    ${props =>
      props.isOpen
        ? props.theme.colors.primary[100]
        : theme.palette.grayBorder};
  height: 48px; /* TODO remove */
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SelectedItem = styled.div`
  ${theme.fontNormal500};
  color: ${theme.palette.primaryDarkBlue};
  font-size: ${theme.fontSizes.m};
  padding: 12px 16px;
  transition: all 0.3s;

  &:hover {
    color: ${props => props.theme.colors.primary[100]};
  }
`;

const None = styled(SelectedItem)`
  color: ${theme.palette.blackRgb75};
`;

type Props = {
  value: number | string;
  options: Array<Option>;
  placeholder?: string;
  onChange: (value: string, index?: number) => void;
};

export const DropdownSelector = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { options, value, placeholder = '', onChange } = props;

  const selectedOption = options.find(
    option => option.value.toString() === value.toString()
  );

  return (
    <Select isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      {value ? (
        <SelectedItem>{selectedOption && selectedOption.label}</SelectedItem>
      ) : (
        <None>{placeholder}</None>
      )}
      <Arrow
        style={{ marginLeft: 'auto', marginRight: '12px' }}
        rotate={isOpen ? 'up' : 'down'}
      />
      {isOpen && (
        <DropdownOptions
          options={options}
          onSelect={onChange}
          onClickOutside={() => setIsOpen(false)}
        />
      )}
    </Select>
  );
};
