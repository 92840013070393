import { GetSignedUrlsParams, GetSignedUrlsResponse } from '.';
import axios, { CancelToken } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';

export const getSignedURLs = async (
  data: GetSignedUrlsParams
): Promise<GetSignedUrlsResponse> => {
  const response = await EXPRESS_API.post('/aws/signedurl/user-images', data);
  return response.data;
};

export const uploadAttachmentExpress = async (
  file: FormData,
  progressUpdate?: (total: number, uploaded: number, percent: number) => void,
  cancelToken?: CancelToken
) => {
  const response = await EXPRESS_API.post('/aws/attachments', file, {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      let progress: any = progressEvent.loaded / progressEvent.total;
      let percentage: any = progress.toFixed(2) * 100;

      if (progressUpdate !== undefined)
        progressUpdate(progressEvent.total, progressEvent.loaded, percentage);
    },
    cancelToken,
  });

  const isCanceled = axios.isCancel(response);
  if (isCanceled) {
    throw response;
  }
  return response.data;
};

export const removeAttachmentExpress = async (fileName: string) => {
  await EXPRESS_API.delete(`/aws/attachments/${fileName}`);
};
