import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { MdAdd, MdCheck, MdClear } from 'react-icons/md';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { useAuth } from 'lib/context';
import { Table, Search, Tooltip } from 'lib/components';
import { LoadingIndicator } from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import { Button } from 'react-covideo-common';
import { NoContent } from './NoContent';
import { CreateDepartmentModal } from './CreateDepartmentModal';
import { DeleteDepartmentModal } from './DeleteDepartmentModal';

import { theme } from 'lib/style';
import { useToastError } from 'lib/hooks';
import { getManagedUsers } from 'lib/api/users/useManagedUsersQuery';
import { useManagedDepartmentsQuery } from 'lib/api/departments/useManagedDepartmentsQuery';
import { useUpdateDepartmentDefaultMutation } from 'lib/api/departments/useUpdateDepartmentDefaultMutation';

type HeaderProps = {
  isSingleElement: boolean;
};

const ListContactLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;

  ${theme.mediaQueryMinWidth.mb} {
    width: 100%;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Filter = styled.div`
  float: right;
  ${theme.mediaQueryMinWidth.sm} {
    width: 220px;
  }
`;

const Customise = styled.div`
  float: right;
  color: #001b53;
  .icon-wrapper {
    width: 13%;
  }
`;

const TableCell = styled.div<{ width?: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  .arrow--right {
    padding: 2px 0 0 30px;
  }
`;

const SortCell = styled.div`
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;

const Container = styled.div<HeaderProps>`
  display: none;
  width: 100%;
  position: fixed;
  left: 230px;
  top: 64px;
  background: white;
  height: 40px;
  z-index: 10;
  justify-content: ${props =>
    props.isSingleElement ? 'flex-end' : 'space-between'};
  align-items: center;
  padding: 12px 0 12px 0;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
  ${theme.mediaQueryMinWidth.sm} {
    display: flex;
  }
`;

const LayoutOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1008px;
  width: 100%;
  gap: 10px;
  margin-left: 60px;
`;

const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;

const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: end;
  &:hover {
    cursor: pointer;
    figcaption {
      z-index: 2;
      opacity: 1;
      width: auto;
      position: absolute;
      white-space: normal;
    }
  }
`;

const HoverElement = styled.div<{ padding?: string; top?: string }>`
  .hover-content {
    display: none;
  }
  .ecllips {
    display: block;
    white-space: nowrap;
    width: 550px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    .hover-content {
      top: ${props => props.top || '40px'};
      border: 1px solid #f7f8fa;
      background: white;
      padding: ${props => props.padding || '2px 3px 2px 3px'};
      display: block;
      position: absolute;
      border-radius: 5px;
      z-index: 10;
      .sub-content {
        display: flex;
        justify-content: center;
        padding-top: 5px;
      }
    }
  }
  svg {
    transform: scale(1.3);
  }
`;

const allTableFields = [
  {
    value: 'name',
    label: 'Department Name',
  },
  {
    value: 'userCount',
    label: '# of Active Users',
  },
];

type DeptListProps = {
  setFunctionUserObj?: React.Dispatch<React.SetStateAction<any>>;
};

export const Department = (props: DeptListProps) => {
  const [selectedDept, setSelectedDept] = useState<any>([]);
  const [isMerge, setIsMerge] = useState<any>(false);
  const [showCreateModal, setShowCreateModal] = useState<any>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [sort, setSort] = useState('');
  const [deptExist, setDeptExist] = useState(false);
  const [defaultHovered, setDefaultHovered] = useState(false);
  const {
    userData: { customerId },
  } = useAuth();

  const {
    data,
    isLoading: loading,
    refetch: refreshDepartment,
  } = useManagedDepartmentsQuery({
    customerId,
    sort: sort,
    search: searchQuery,
  });

  const { mutateAsync: updateDepartmentDefault, isLoading: updatingDefault } =
    useUpdateDepartmentDefaultMutation();
  const count = data?.length || 0;

  const { setFunctionUserObj } = props;
  const { showError } = useToastError();
  const columnWidths = [50, 550, 155];
  const history = useHistory();

  const createDept = () => {
    setIsMerge(false);
    setShowCreateModal(true);
  };

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };
  const sortElement = (sortParam: any) => {
    if (!['name', 'userCount'].includes(sortParam)) return;
    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('DESC')
        ? sort.replace('DESC', 'ASC')
        : sort.replace('ASC', 'DESC');
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}-DESC`;
      setSort(newSort);
    }
  };

  useEffect(() => {
    refreshDepartment();
  }, [searchQuery, sort]);

  useEffect(() => {
    const getUserCount = async () => {
      if (setFunctionUserObj) {
        try {
          const response = await getManagedUsers({
            customerId,
            page: 0,
            size: 1,
            departmentId: 0,
          });
          setFunctionUserObj({
            user: {
              create: null,
              usersValue: {
                total: response.totalAllowedUsers,
                active: response.totalActiveUsers,
              },
              import: null,
            },
          });
        } catch (error) {
          showError(error);
        }
      }
    };
    getUserCount();
  }, []);

  useEffect(() => {
    if (!searchQuery.length && count != 0) {
      setDeptExist(true);
    }
    if (!searchQuery.length && count == 0) {
      setDeptExist(false);
    }
  }, [count]);

  return (
    <>
      {selectedDept.length ? (
        <Container isSingleElement={false}>
          <LayoutOption>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={
                selectedDept.length && data?.length != selectedDept.length
              }
              checked={data?.length == selectedDept.length}
              ignoreGrayForAllSelect={true}
              grayCheck={true}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                let { checked } = event.target as HTMLInputElement;
                if (checked) setSelectedDept(data?.map(e => e.departmentId));
                else setSelectedDept([]);
              }}
            />
            <SelectionCountWrap>
              <SelectionCountText>Department selected:</SelectionCountText>
              <span>{selectedDept.length}</span>
            </SelectionCountWrap>
            <Button
              onClick={() => {
                setIsMerge(true);
                setShowCreateModal(true);
              }}
              text='Merge Departments'
              variant='primary'
              disabled={selectedDept.length < 2}
            />
            <Button
              onClick={() => {
                setShowDeleteModal(true);
              }}
              text='Delete'
              variant='destructive'
            />
          </LayoutOption>
        </Container>
      ) : null}
      <ListContactLayout>
        <FiltersContainer>
          <Filter>
            <Search placeholder='Search Departments' onSearch={onSearch} />
          </Filter>
          {deptExist ? (
            <Customise>
              <Button
                icon={<MdAdd color={theme.palette.white || '#fff'} />}
                text={'Create Department'}
                onClick={createDept}
              />
            </Customise>
          ) : null}
        </FiltersContainer>
        {data?.length && !loading ? (
          <>
            <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
              <Table
                compact={true}
                relative
                headers={[
                  '',
                  ...allTableFields.map((item, index) => {
                    return (
                      <TableCell
                        width={columnWidths[index + 1]}
                        onClick={() => sortElement(item.value)}
                      >
                        {item.label}
                        {['name', 'userCount'].includes(item.value) ? (
                          <SortCell>
                            <IoMdArrowRoundUp
                              className={
                                sort == `${item.value}-ASC` ? 'active' : ''
                              }
                            />
                            <IoMdArrowRoundDown
                              className={
                                sort == `${item.value}-DESC` ? 'active' : ''
                              }
                            />
                          </SortCell>
                        ) : null}
                      </TableCell>
                    );
                  }),
                  <TableCell
                    width={columnWidths[allTableFields.length]}
                  ></TableCell>,
                  <TableCell></TableCell>,
                ]}
                hoverable={false}
                columnWidths={columnWidths}
                rows={data?.map((department, index: number) => ({
                  key: index,
                  columns: [
                    <CheckboxInput
                      width='24px'
                      blueCheck={true}
                      checked={selectedDept.includes(department.departmentId)}
                      onChange={(e: React.SyntheticEvent) => {
                        e.stopPropagation();
                        let { checked } = e.target as HTMLInputElement;
                        if (checked)
                          setSelectedDept([
                            ...selectedDept,
                            department.departmentId,
                          ]);
                        else
                          setSelectedDept(
                            selectedDept.filter(
                              (e: any) => e != department.departmentId
                            )
                          );
                      }}
                    />,

                    ...allTableFields.map((item, index) => {
                      return (
                        <TableCell
                          width={columnWidths[index + 1]}
                          onClick={() => {
                            history.push(
                              `/users/department/${department.departmentId}`
                            );
                          }}
                        >
                          {
                            // @ts-ignore
                            item.value != 'name' ? department[item.value] : null
                          }
                          {item.value == 'name' ? (
                            <HoverElement
                              padding='10px'
                              top='-30px'
                              className='hover--div'
                            >
                              <div className='ecllips'>
                                {department[item.value]}
                              </div>
                              {department[item.value].length > 55 && (
                                <div className='hover-content'>
                                  {department[item.value]}
                                </div>
                              )}
                            </HoverElement>
                          ) : null}
                        </TableCell>
                      );
                    }),
                    <TooltipWrapper>
                      <TableCell
                        style={{
                          textAlign: 'right',
                          justifyContent: 'end',
                          width: 'auto',
                          minWidth: '230px',
                        }}
                        width={columnWidths[allTableFields.length]}
                      >
                        {!department.isDefault && (
                          <Tooltip
                            text={
                              'Default Department will be pre-selected on all users list'
                            }
                            topPixels={40}
                            widthPixels={230}
                            textTransform={'capitalize'}
                          />
                        )}
                        {department.isDefault ? (
                          <Button
                            onMouseEnter={() => setDefaultHovered(true)}
                            onMouseLeave={() => setDefaultHovered(false)}
                            onClick={() => {
                              if (defaultHovered) {
                                updateDepartmentDefault({
                                  id: department.departmentId,
                                  criteria: 1,
                                });
                              }
                            }}
                            text={
                              defaultHovered ? 'Remove as Default' : 'Default'
                            }
                            variant={defaultHovered ? 'destructive' : 'white'}
                            icon={
                              defaultHovered ? (
                                <MdClear
                                  color={theme.palette.primaryRedDanger}
                                />
                              ) : (
                                <MdCheck color={'#1EA45F'} />
                              )
                            }
                            disabled={updatingDefault}
                          />
                        ) : (
                          <Button
                            onClick={() => {
                              updateDepartmentDefault({
                                id: department.departmentId,
                                criteria: 0,
                              });
                            }}
                            disabled={updatingDefault}
                            text='Make Default'
                            variant='secondary'
                          ></Button>
                        )}
                        {/* <FaChevronRight size={13} className="arrow--right" onClick={() => {}} /> */}
                      </TableCell>
                    </TooltipWrapper>,
                    <TableCell
                      style={{
                        textAlign: 'right',
                        justifyContent: 'end',
                        width: 'auto',
                      }}
                      width={columnWidths[allTableFields.length]}
                      onClick={() => {
                        history.push(
                          `/users/department/${department.departmentId}`
                        );
                      }}
                    >
                      <FaChevronRight size={13} className='arrow--right' />
                    </TableCell>,
                  ],
                  onClick: () => {},
                }))}
              />
            </div>
          </>
        ) : !data?.length && !loading ? (
          <NoContent
            title={
              searchQuery.length
                ? 'No matching results found.'
                : `You don't have any departments created yet.`
            }
            createDepartment={
              !searchQuery.length ? () => setShowCreateModal(true) : undefined
            }
          />
        ) : (
          <LoadingIndicator isLoading={loading} height='500px' />
        )}
      </ListContactLayout>

      {showCreateModal && (
        <CreateDepartmentModal
          isMerge={isMerge}
          selectedDept={selectedDept}
          customerId={customerId}
          handleModalClose={shouldRefresh => {
            setShowCreateModal(false);
            setIsMerge(false);
            if (shouldRefresh) {
              setSelectedDept([]);
              refreshDepartment();
            }
          }}
        />
      )}
      {showDeleteModal && (
        <DeleteDepartmentModal
          handleModalClose={(shouldRefresh: boolean = false) => {
            setShowDeleteModal(false);
            if (shouldRefresh) {
              setSelectedDept([]);
              refreshDepartment();
            }
          }}
          deptObj={{ dept: 'selected', ids: selectedDept }}
        />
      )}
    </>
  );
};
