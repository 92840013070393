import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import onboardingStepTwoAnimationForMyTeam from 'lib/animations/OnBoarding_Step_3.json';
import { FreemiumOnboardingPageTemplate } from '../components';
import { LoadingIndicator, NewTextInput } from 'lib/components';
import { companySizeOptions } from '../const';
import { useFreemiumOnboardingQuery } from 'lib/api/freemiumOnboarding/getFreemiumOnboarding';
import myself from 'assets/images/freemiumOnboarding/mySelf.svg';
import { Gap } from 'lib/components/styles/layout';

const TextInputField = styled(NewTextInput)`
  box-sizing: border-box;
  max-width: 280px;
  height: 40px;
  background: theme.palette.white;
  border: 1px solid ${theme.palette.covideoBlue20};
  borderradius: '6px';
  margin: 0;
  &:focus {
    border: 1px solid ${theme.palette.covideoBlue100};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    width: 100%;
    max-width: 100%;
  }
`;
type LabelProps = {
  margin: string;
};
const Label = styled.div<LabelProps>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin: ${props => props.margin};
`;
type SelectCompanySizeItemProps = {
  selected?: boolean;
};
const SelectCompanySizeOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;
const SelectCompanySizeItem = styled.div<SelectCompanySizeItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  background: ${props =>
    props.selected
      ? props.theme.colors.primary[100]
      : props.theme.colors.primary[5]};
  border: 1px solid ${theme.palette.covideoGray40};
  border-radius: 5px;
  flex: none;
  &:hover {
    cursor: pointer;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    flex: 1;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    min-width: 58px;
  }
  span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    white-space: pre;
    color: ${props =>
      props.selected ? theme.palette.white : theme.palette.covideoGray60};
  }
`;

export const StepTwoFroMyTeam = () => {
  const { data: freemiumOnboardingData, isLoading } =
    useFreemiumOnboardingQuery();
  const [companyName, setCompanyName] = useState(
    freemiumOnboardingData?.companyName || ''
  );
  const [companySize, setCompanySize] = useState(
    freemiumOnboardingData?.companySize || ''
  );

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <FreemiumOnboardingPageTemplate
      nextStepRoute='/onboarding-freemium/step-three/my-team'
      currentStep={2}
      stepText='About my company...'
      rightColumnAnimationSrc={onboardingStepTwoAnimationForMyTeam}
      rightColumnChildren={
        <Gap justifyContent='center'>
          <img alt='myself' src={myself} />
        </Gap>
      }
      goToNextStepDisabled={companyName === '' || companySize === ''}
      dataToBeSaved={{
        companyName: companyName,
        companySize: companySize,
        flow: 'Business',
      }}
      leftColumnChildren={
        <>
          <Label margin='0 0 8px 0'>Company Name</Label>
          <TextInputField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCompanyName(e.target.value)
            }
            value={companyName}
            autoFocus={true}
          />
          <Label margin='16px 0 8px 0'>How big is your company?</Label>
          <SelectCompanySizeOptionsWrapper>
            {companySizeOptions.map((item, index) => (
              <SelectCompanySizeItem
                key={`company-select-${index}`}
                onClick={() => setCompanySize(item)}
                selected={item === companySize}
              >
                <span>{item}</span>
              </SelectCompanySizeItem>
            ))}
          </SelectCompanySizeOptionsWrapper>
        </>
      }
    />
  );
};
