import { parseDeliveryTimeToScheduledTimezone } from 'app/pages/account/userAccount/schedule/utils';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import React from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import styled from 'styled-components/macro';

const Container = styled(Gap)`
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  background: ${theme.palette.covideoSecondaryButtonBg};
  border: 1px solid ${theme.palette.primaryBlack5Percent};
  border-radius: 6px;
`;

const Text = styled(ParagraphSmallBold)`
  color: ${theme.palette.covideoBlue100};
`;

type Props = {
  deliveryTime: string;
};
export const EditScheduleDeliveryTimeCard = ({ deliveryTime }: Props) => {
  const formattedDeliveryTime = parseDeliveryTimeToScheduledTimezone(
    deliveryTime || ''
  );

  return (
    <Container gap='12px' flexDirection='row' alignItems='flex-start'>
      <IoMdInformationCircle color={theme.palette.covideoBlue60} size={18} />
      <Text>
        {`This email is scheduled to be sent on ${formattedDeliveryTime}`}
      </Text>
    </Container>
  );
};
