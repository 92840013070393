import React, { useEffect, useState } from 'react';
import { LoadingIndicator } from '../../../../lib/components';
import { BsPlus } from 'react-icons/bs';
import { QRVideo } from '../../../../lib/api';
import { ModalAddOrEditQrCodes } from '../../../../lib/components/modal/ModalAddOrEditQrCodes';
import { QRCodesTable } from './components/QRCodesTable';
import { deleteQRVideo, getQRVideos } from '../../../../lib/api/qrVideosApi';
import { Heading } from 'lib/components/styles/typography';
import { HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Button } from 'react-covideo-common';
import selectors from '../../../../cypress/selectors';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';

interface ModalWithData {
  toOpen: 'add' | 'edit' | 'delete' | 'none';
  data?: QRVideo;
}

const QrCodes = () => {
  const [data, setData] = useState({ qrVideos: [], count: 0 });
  const [modalWithData, setModalWithData] = useState<ModalWithData>({
    toOpen: 'none',
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const fetchQrVideos = async () => {
    setLoading(true);
    const { qrVideos, count } = await getQRVideos({
      page,
      size,
      sort: '-',
    });
    setLoading(false);
    setData({ qrVideos, count });
  };

  const onCloseModal = () =>
    setModalWithData({ toOpen: 'none', data: undefined });

  const onCreate = () => {
    if (modalWithData.toOpen === 'edit') {
      onCloseModal();
    }
    fetchQrVideos();
  };

  const onDelete = async (qrVideo: any) => {
    try {
      await deleteQRVideo(qrVideo);
      successToast({ title: 'QR Code deleted successfully' });
    } catch (e) {
      errorToast({ title: 'Error deleting QR Code' });
    }
    fetchQrVideos();
  };

  const onEdit = async (qrVideo: any) => {
    setModalWithData({ toOpen: 'edit', data: qrVideo });
  };

  useEffect(() => {
    fetchQrVideos();
  }, [page, size]);

  const onPaginationChange = async ({ page: newPage, size: newSize }: any) => {
    if (page !== newPage || size !== newSize) {
      setPage(newPage);
      setSize(newSize);
    }
  };

  return (
    <MainWrapper resetPadding={false}>
      {modalWithData.toOpen === 'add' && (
        <ModalAddOrEditQrCodes
          type={modalWithData.toOpen}
          modalLoading={false}
          onClose={onCloseModal}
          onMainButtonClick={onCreate}
        />
      )}
      {modalWithData.toOpen === 'edit' && modalWithData.data && (
        <ModalAddOrEditQrCodes
          data={modalWithData.data}
          type={modalWithData.toOpen}
          modalLoading={false}
          onClose={onCloseModal}
          onMainButtonClick={onCreate}
        />
      )}
      <HeaderWrapper>
        <Heading>QR Codes</Heading>
        <Button
          text={'New QR Code'}
          icon={<BsPlus />}
          onClick={() => setModalWithData({ toOpen: 'add' })}
          data-cy={selectors.qrCodePage.new}
        />
      </HeaderWrapper>

      {loading && <LoadingIndicator isLoading={true} height='300px' />}
      {!loading && (
        <>
          {data.qrVideos.length <= 0 ? (
            <p
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                margin: '36px 0',
              }}
            >
              No matching results found.{' '}
            </p>
          ) : (
            <QRCodesTable
              data={data}
              onDelete={onDelete}
              onEdit={onEdit}
              onPaginationChange={onPaginationChange}
              page={page}
              size={size}
            />
          )}
        </>
      )}
    </MainWrapper>
  );
};
export default QrCodes;
