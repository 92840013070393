import { REPORT_SCOPE } from 'lib/api';
import { reportData } from '../const';
import dayjs from 'dayjs';
import { useQueryReport } from 'lib/api/metrics/queryReport';

const DATE_FORMAT = 'YYYY-MM-DD';

export interface Props {
  reportType: string;
  from: Date;
  to: Date;
  dateRange: string;
  customer?: string;
  users?: string[];
  retryCount?: number;
  reportScope?: REPORT_SCOPE;
  organizationId?: string | number;
  organizationRegionId?: string | number;
  allowDecimals?: boolean;
  reportId?: string;
}

type ResponseData = {
  date: string;
  cnt: string;
  views: string;
};

type Data = {
  date: string;
  cnt: number;
  label: string;
};

type ReportData = {
  cnt: number;
  average: number;
  stats: Data[];
  views: number;
  max: number;
};

export const useGetReport = (props: Props) => {
  const {
    reportType,
    from,
    to,
    users,
    retryCount,
    customer,
    reportScope = REPORT_SCOPE.RESELLER,
    organizationId,
    organizationRegionId,
    allowDecimals = false,
    reportId,
  } = props;

  // @ts-ignore
  let report = reportData[reportType];

  if (!report) {
    report = reportData.views;
  }

  const {
    data,
    error,
    isFetching: loading,
  } = useQueryReport({
    reportType: reportType,
    from: dayjs(from).format(DATE_FORMAT),
    to: dayjs(to).format(DATE_FORMAT),
    users: (users || []).join(','),
    retryCount,
    customer,
    timezoneOffset: new Date().getTimezoneOffset(),
    reportScope,
    organizationId,
    organizationRegionId,
    reportId,
  });

  let statisticsData: any = [];

  if (data) {
    // engagement rate is returned in different format { data, engagement
    statisticsData = data.data ? [...data.data] : [...data];
  }

  const getReportData: ReportData = {
    cnt: 0,
    stats: [],
    average: 0,
    views: 0,
    max: 0,
  };

  if (statisticsData && statisticsData.length) {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const isSameYear = fromDate.getFullYear() === toDate.getFullYear();
    statisticsData.forEach((item: ResponseData) => {
      const value = item.cnt
        ? allowDecimals
          ? parseFloat(item.cnt)
          : parseInt(item.cnt, 10)
        : 0;
      const views = parseInt(item.views, 10) || 0;
      getReportData.cnt += value;
      getReportData.views += views;
      getReportData.stats.push({
        date: dayjs(item.date).format(isSameYear ? 'MM/DD' : 'YYYY/MM/DD'),
        cnt: value,
        label: report.label,
      });
      if (getReportData.max < value) {
        getReportData.max = value;
      }
    });

    getReportData.average = data.engagement || data.average || 0;
  }

  return { data: getReportData, error, loading, label: report.label };
};
