import * as React from 'react';
import { EditCompanyProfile } from './EditCompanyProfile';
import { EditUserProfile, ReceivedData } from './EditUserProfile';
import { LoadingIndicator, JumboMessage } from 'lib/components';
import { useAuth } from 'lib/context';
import { getProfileData } from 'lib/api';
import styled from 'styled-components/macro';
import { getQueryParamValue, getQueryVariable } from 'lib/utils/functions';
import { CalendarPlatform } from '../components/IntegrationsForm';
import { Container, MainWrapper } from 'lib/components/styles/layout';

const PageContainer = styled.div<{ LeftMargin: any }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0px 60px ${props => props.LeftMargin}px;
  padding: 32px 112px;
  box-sizing: border-box;
`;
interface IProps {
  edit: 'user' | 'company';
}

export const Main = ({ edit }: IProps) => {
  const { userData } = useAuth();
  const [profileUser, setProfileUser] = React.useState({} as ReceivedData);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const newRefreshToken = getQueryVariable('microsoft-graph-token');
  const tabInUrl = getQueryParamValue('tab') === 'preferences' ? 3 : 0;
  const defaultActiveTab = newRefreshToken ? 5 : tabInUrl;

  React.useEffect(() => {
    if (userData && userData?.userId) {
      loadUserProfile();
    }
  }, []);

  const loadUserProfile = async () => {
    try {
      setLoading(true);
      let profileUser = await getProfileData(userData.userId);
      if (profileUser && newRefreshToken) {
        profileUser = {
          ...profileUser,
          platform: CalendarPlatform.OUTLOOK.value,
          outlookRefreshToken: newRefreshToken,
        };
      }
      setProfileUser(profileUser);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  // List of resellers that do not want their users to be able to edit the company name
  const resellerList = [138];
  // List of users who can edit the company name within resellerList
  const userList = [105568];
  const editCompanyName =
    !resellerList.includes(Number(userData.resellerId)) ||
    (resellerList.includes(Number(userData.resellerId)) &&
      userList.includes(Number(userData.userId)));

  const [activeTab, setActiveTab] = React.useState(defaultActiveTab);

  if (error) {
    return (
      <PageContainer LeftMargin={208}>
        <JumboMessage success={false}>
          An error occurred retrieving your information, please try again later
          or login again.
        </JumboMessage>
      </PageContainer>
    );
  }

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        {loading ? (
          <div>
            <LoadingIndicator isLoading={loading} />
          </div>
        ) : (
          (edit === 'user' && (
            <EditUserProfile
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={profileUser || ({} as ReceivedData)}
              setProfileUser={setProfileUser}
            />
          )) ||
          (edit === 'company' && (
            <>
              {(userData.access === '3' || userData.access === '4') && (
                <EditCompanyProfile editCompanyName={editCompanyName} />
              )}
            </>
          ))
        )}
      </MainWrapper>
    </Container>
  );
};
