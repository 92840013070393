import { ParagraphSmall } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context/auth';
import React, { useEffect, useState } from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components/macro';
import { MAX_EMAIL_RECIPIENTS } from '../const';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black[100]};
  padding: 10px 10px 10px 20px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LimitExceedInfoBox = ({ emailLimit }: { emailLimit: number }) => {
  const {
    userData: { customer },
  } = useAuth();
  const emailsSentLimit =
    parseInt(customer?.emailsSentLimit as unknown as string, 10) ||
    MAX_EMAIL_RECIPIENTS;
  const [isLimitExceeded, setIsLimitExceeded] = useState(emailLimit);
  const { colors } = useCovideoTheme();

  useEffect(() => {
    setIsLimitExceeded(emailLimit);
  }, [emailLimit]);

  if (isLimitExceeded < emailsSentLimit) {
    return null;
  }

  return (
    <Wrapper>
      <ParagraphSmall color={colors.white[100]}>
        {isLimitExceeded === emailsSentLimit
          ? 'You had reached your daily limit of sent emails.'
          : `You can send maximum of ${emailsSentLimit} emails per day.`}
      </ParagraphSmall>

      <MdClose
        style={{ cursor: 'pointer' }}
        onClick={() => setIsLimitExceeded(0)}
        color={colors.white[100]}
        size={18}
      />
    </Wrapper>
  );
};
