import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewTextInput } from 'lib/components';

export const MainHeading = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  color: ${theme.palette.covideoBlue100};
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const Heading = styled.h2`
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.palette.covideoBlue100};
  span {
    color: ${theme.palette.primaryColor};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const TextInputField = styled(NewTextInput)`
  box-sizing: border-box;
  max-width: 488px;
  height: 40px;
  background: theme.palette.white;
  border: 1px solid ${theme.palette.covideoBlue20};
  borderradius: '6px';
  margin: 0;
  &:focus {
    border: 1px solid ${theme.palette.covideoBlue100};
  }
  ${theme.mediaQueryMaxWidth.sm} {
    width: 100%;
    max-width: 100%;
  }
`;
type LabelProps = {
  margin: string;
};
export const Label = styled.div<LabelProps>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.primary[100]};
  margin: ${props => props.margin};
`;
