import { theme } from 'lib/style';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components/macro';

type Props = {
  content: ReactElement | string;
  onClick: () => void;
  active: boolean;
  padding?: string;
};
type WrapperProps = {
  padding: string;
  active: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding};
  border-radius: 5px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      color: ${theme.palette.white};
      background-color: ${({ theme }) => theme.colors.primary[100]};
      border: 1px solid ${({ theme }) => theme.colors.primary[100]};
    `};
  ${({ active }) =>
    !active &&
    css`
      color: ${theme.palette.gray60};
      background-color: ${theme.palette.blue05};
      border: 1px solid ${theme.palette.gray40};
    `};
`;

export const ButtonCheckboxInput = ({
  content,
  onClick,
  active,
  padding = '8px 12px',
}: Props) => {
  return (
    <Wrapper onClick={onClick} padding={padding} active={active}>
      {content}
    </Wrapper>
  );
};
