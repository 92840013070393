import * as React from 'react';
import styled from 'styled-components/macro';
import { MdAdd } from 'react-icons/md';
import placeholder from 'assets/images/table/placeholderBig.webp';

import { theme } from 'lib/style';
import { ModalAddGroup } from 'lib/components/modal/ModalAddGroup';
import { Button } from 'react-covideo-common';

const NoContactLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const AddGroupContainer = styled.div`
  z-index: 999;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 40px;
  align-items: center;
  padding: 12px 0 12px 0;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px #f7f8fa;
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
type Props = {
  onCreateGroup: (data: { name: string }) => void;
};

export const NoGroups = ({ onCreateGroup }: Props) => {
  const [isCreateVisible, setIsCreateVisible] = React.useState(false);

  return (
    <NoContactLayout>
      {isCreateVisible && (
        <AddGroupContainer>
          <ModalAddGroup
            title='Create Group'
            text=''
            handleModalClose={() => setIsCreateVisible(false)}
            handleSubmit={onCreateGroup}
          />
        </AddGroupContainer>
      )}
      <Text>You have no Groups</Text>
      <Row>
        <Button
          text='Create'
          onClick={() => {
            setIsCreateVisible(true);
          }}
          icon={<MdAdd color='#fff' />}
        />
      </Row>
    </NoContactLayout>
  );
};
