import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import parse from 'html-react-parser';
import { RepairOrderActivity } from 'lib/api/repairOrders/types';

const Container = styled.div`
  width: 280px;
  max-width: 100%;
  padding-bottom: 24px;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 24px;
  color: ${theme.palette.gray80};
`;
const Activity = styled.div`
  margin-bottom: 24px;
`;
const ActivityTitle = styled.div`
  color: ${theme.palette.gray80};
  font-size: 14px;
  margin-bottom: 4px;
`;
const ActivityDate = styled.div`
  color: ${theme.palette.gray60};
  font-size: 12px;
`;

const ActivityItemContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;
type Props = {
  activities: RepairOrderActivity[];
};
export const ActivityList = ({ activities }: Props) => {
  dayjs.extend(localizedFormat);

  return (
    <Container>
      <Title>Activity</Title>
      <ActivityItemContainer>
        {activities.map((a: RepairOrderActivity, i: number) => (
          <Activity key={`activity-${i}`}>
            <ActivityTitle>{parse(a.activityText)}</ActivityTitle>
            <ActivityDate>
              {dayjs(new Date(a.createdAt)).format('lll')}
            </ActivityDate>
          </Activity>
        ))}
      </ActivityItemContainer>
    </Container>
  );
};
