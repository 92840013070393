import { TableField } from 'lib/context';

export enum ACCOUNT_CHANGES_COLUMNS {
  CURRENT_USER_ID = 'currentUserId',
  TARGET_USER_ID = 'targetUserId',
  OLD_VALUE = 'oldValue',
  NEW_VALUE = 'newValue',
  CHANGE_ORIGIN = 'changeOrigin',
  CHANGE_TYPE = 'changeType',
  CREATED_AT = 'createdAt',
}

export const tableFields: TableField[] = [
  {
    value: ACCOUNT_CHANGES_COLUMNS.CREATED_AT,
    label: 'Date',
    sortable: true,
    sortKey: 'createdAt',
    width: 120,
  },
  {
    value: ACCOUNT_CHANGES_COLUMNS.CURRENT_USER_ID,
    label: 'User',
    width: 144,
  },
  {
    value: ACCOUNT_CHANGES_COLUMNS.TARGET_USER_ID,
    label: 'Target User',
    sortable: true,
    sortKey: 'targetUserId',
    width: 144,
  },
  {
    value: ACCOUNT_CHANGES_COLUMNS.CHANGE_TYPE,
    label: 'Change Type',
    width: 144,
  },
  {
    value: ACCOUNT_CHANGES_COLUMNS.OLD_VALUE,
    label: 'Old Value',
    width: 144,
  },
  {
    value: ACCOUNT_CHANGES_COLUMNS.NEW_VALUE,
    label: 'New Value',
    width: 144,
  },
  {
    value: ACCOUNT_CHANGES_COLUMNS.CHANGE_ORIGIN,
    label: 'Change Origin',
    width: 144,
  },
];
