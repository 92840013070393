import { useFormikContext } from 'formik';
import { ButtonPillSwitch } from 'lib/components';
import React from 'react';
import styled from 'styled-components/macro';
import { timeRangeConstants } from '../const';

import { CustomReportModalFormikValues, TIMEFRAME } from '../types';

const Title = styled.h3`
font-family: Work Sans;
font-size: 12px;
font-weight: 600;
line-height: 24px;
text-align: left;
color:${({ theme }) => theme.colors.neutral[80]}
margin-bottom:16px;
`;

interface IProps {
  setCurrentTimeFrame: React.Dispatch<React.SetStateAction<TIMEFRAME>>;
  currentTimeframe: TIMEFRAME;
  updateRangeValue: (rangeValue: string) => void;
  timeframeOptions: Partial<typeof TIMEFRAME>;
}

const getPillWidth = (timeframeOptions: Partial<typeof TIMEFRAME>) => {
  if (Object.keys(timeframeOptions).length === 2) {
    return 'calc(50% - 4px)';
  }
  if (Object.keys(timeframeOptions).length === 3) {
    return 'calc(33.33% - 3px)';
  }
  if (Object.keys(timeframeOptions).length === 4) {
    return 'calc(25% - 2px)';
  }
  if (Object.keys(timeframeOptions).length === 5) {
    return 'calc(20% - 1.5px)';
  }
  return 'auto';
};

export const SelectTimeframe = ({
  setCurrentTimeFrame,
  currentTimeframe,
  updateRangeValue,
  timeframeOptions,
}: IProps) => {
  const { setFieldValue } = useFormikContext<CustomReportModalFormikValues>();

  const onChangeHandler = (newTab: TIMEFRAME) => {
    setCurrentTimeFrame(newTab);
    setFieldValue('startDate', new Date());
    setFieldValue('endDate', new Date());

    // change formik range value on tab click
    if (newTab === TIMEFRAME.DAY) {
      updateRangeValue(timeRangeConstants.TODAY);
    }
    if (newTab === TIMEFRAME.WEEK) {
      updateRangeValue(timeRangeConstants.LAST_WEEK);
    }
    if (newTab === TIMEFRAME.MONTH) {
      updateRangeValue(timeRangeConstants.LAST_MONTH);
    }
    if (newTab === TIMEFRAME.YEAR) {
      updateRangeValue(timeRangeConstants.YEAR_TO_DATE);
    }
    if (newTab === TIMEFRAME.CUSTOM) {
      updateRangeValue(timeRangeConstants.CUSTOM);
    }
  };

  return (
    <div>
      <Title>Timeframe</Title>
      <ButtonPillSwitch
        defaultValue={currentTimeframe}
        values={timeframeOptions}
        onChange={newTab => onChangeHandler(newTab as TIMEFRAME)}
        pillStyle={{
          width: getPillWidth(timeframeOptions),
        }}
        textStyle={{
          textAlign: 'center',
          flex: 1,
        }}
      />
    </div>
  );
};
