import { EXPRESS_API } from 'configs/api/expressApi';
import { CreateTeleprompterScriptParams } from '.';

export type GetScriptsParams = {
  start?: number;
  limit?: number;
  search?: string;
  field?: string;
  order?: string;
};

export const getScripts = async (params: GetScriptsParams): Promise<any> => {
  const response = await EXPRESS_API.get(`/teleprompter`, { params });
  return response.data;
};

export const getScript = async (scriptId: string): Promise<any> => {
  const response = await EXPRESS_API.get(`/teleprompter/${scriptId}`);
  return response.data;
};

export const createScript = async ({
  data,
}: CreateTeleprompterScriptParams) => {
  const response = await EXPRESS_API.post(`/teleprompter`, data);
  return response.data;
};

export const updateScript = async ({
  scriptId,
  data,
}: CreateTeleprompterScriptParams): Promise<any> => {
  const response = await EXPRESS_API.put(`/teleprompter/${scriptId}`, data);
  return response.data;
};

export const deleteScript = async (scriptId: string): Promise<any> => {
  const response = await EXPRESS_API.delete(`/teleprompter/${scriptId}`);
  return response.data;
};
