import { SOCKET_CONVERSATION_ACTIONS } from './actions';

export interface INotification {
  sound: string;
  type: string;
}

export enum ISOCKET_ACTIONS {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_USER = 'connect-user',
  LANDING_PAGE_ONLINE = 'landing-page.conversation-connected',
  LANDING_PAGE_OFFLINE = 'landing-page.conversation-disconnected',
  NOTIFICATION_NEW = 'notification.new',
  MESSAGE_RECEIVED = 'message.received',
  CONVERSATION_RECEIVED = 'conversation-message.received',
  INIT_RETRIEVE_CONVERSATION = 'retrieve-user-conversations',
  RETRIEVE_CONVERSATION = 'retrieve.user-conversations',
}

export type ISOCKET_ON_TYPES = {
  CONNECT: ISOCKET_ACTIONS.CONNECT;
  DISCONNECT: ISOCKET_ACTIONS.DISCONNECT;
  CONNECT_USER: ISOCKET_ACTIONS.CONNECT_USER;
  LANDING_PAGE_ONLINE: ISOCKET_ACTIONS.LANDING_PAGE_ONLINE;
  LANDING_PAGE_OFFLINE: ISOCKET_ACTIONS.LANDING_PAGE_OFFLINE;
  NOTIFICATION_NEW: ISOCKET_ACTIONS.NOTIFICATION_NEW;
  MESSAGE_RECEIVED: ISOCKET_ACTIONS.MESSAGE_RECEIVED;
  CONVERSATION_RECEIVED: ISOCKET_ACTIONS.CONVERSATION_RECEIVED;
  INIT_RETRIEVE_CONVERSATION: ISOCKET_ACTIONS.INIT_RETRIEVE_CONVERSATION;
  RETRIEVE_CONVERSATION: ISOCKET_ACTIONS.RETRIEVE_CONVERSATION;
};

type ISOCKET_EMIT_TYPES = {
  CONNECT_USER: ISOCKET_ACTIONS.CONNECT_USER;
  INIT_RETRIEVE_CONVERSATION: ISOCKET_ACTIONS.INIT_RETRIEVE_CONVERSATION;
};

type IValueOF<T> = T[keyof T];

export interface ISocket {
  on: <T>(name: IValueOF<ISOCKET_ON_TYPES>, cb: (id: T) => void) => void;
  emit: (name: IValueOF<ISOCKET_EMIT_TYPES>, id: number) => void;
  disconnect: () => void;
}

interface IOfflineAction {
  type: SOCKET_CONVERSATION_ACTIONS.OFFLINE;
  payload: number;
}

interface IOnlineAction {
  type: SOCKET_CONVERSATION_ACTIONS.ONLINE;
  payload: number;
}

interface IInitActions {
  type: SOCKET_CONVERSATION_ACTIONS.INIT;
  payload: number[];
}

export type IConversationActions =
  | IOnlineAction
  | IOfflineAction
  | IInitActions;

export enum CONVERSATION_STATUS {
  ONLINE = 'online',
  OFFLINE = 'offline',
}
