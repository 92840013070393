import React, { useState } from 'react';
import {
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';
import { LoginAsModal } from 'app/pages/reports/components';
import { MdLaunch } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';
import { useFormikContext } from 'formik';
import { ModalPrompt } from 'lib/components/modal';
import { loginAsForSuperAdmin } from 'lib/api/superAdminApi';

type Props = {
  userId: number;
  username: string;
  onSave: (reloadUserOnSave?: boolean) => Promise<boolean>;
};

export const LoginAsSection = ({ userId, username, onSave }: Props) => {
  const { dirty } = useFormikContext();
  const { showError } = useToastError();
  const [showLoginAsModal, setShowLoginAsModal] = useState(false);
  const [loginAsLoading, setLoginAsLoading] = useState(false);
  const [showLoginAsPrompt, setShowLoginAsPrompt] = useState(false);

  const handleLoginAsClick = async () => {
    if (dirty) {
      setShowLoginAsPrompt(true);
      return;
    }
    handleLoginAs();
  };

  const handleLoginAs = async () => {
    if (loginAsLoading) {
      return;
    }
    setLoginAsLoading(true);
    try {
      await loginAsForSuperAdmin({ userId, username });
      setShowLoginAsModal(true);
    } catch (error) {
      showError(error);
    }
    setLoginAsLoading(false);
  };

  const handleLoginAsSaveChanges = async () => {
    setShowLoginAsPrompt(false);
    try {
      // save without reload to loginAs right after save
      const saveSuccessfull = await onSave(false);
      if (saveSuccessfull) {
        handleLoginAs();
      }
    } catch (error) {
      showError(error);
    }
  };

  const handleLoginAsProceedWithoutSaving = async () => {
    setShowLoginAsPrompt(false);
    handleLoginAs();
  };

  return (
    <>
      <Section id={'loginAs'}>
        <SectionTitle>Log In As User</SectionTitle>
        <RowWrapper>
          <Row>
            <Button
              text='Log In As User'
              variant='primary'
              icon={<MdLaunch size={18} />}
              onClick={() => handleLoginAsClick()}
              disabled={loginAsLoading}
            />
          </Row>
        </RowWrapper>
      </Section>

      {showLoginAsPrompt && (
        <ModalPrompt
          title={'Before logging in...'}
          content={<>You have unsaved changes on this page.</>}
          secondaryButtonText={'Proceed Without Saving'}
          handleSecondarySubmit={handleLoginAsProceedWithoutSaving}
          primaryButtonText={'Save Changes'}
          handleSubmit={handleLoginAsSaveChanges}
          handleModalClose={() => setShowLoginAsPrompt(false)}
        />
      )}

      {showLoginAsModal && (
        <LoginAsModal
          handleModalClose={() => {
            setShowLoginAsModal(false);
            window.location.reload();
          }}
        />
      )}
    </>
  );
};
