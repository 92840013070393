import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import React from 'react';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: ${theme.palette.white};
`;

const Header = styled.div`
  display: flex;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin-top: 32px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  margin-top: 32px;
`;

type Props = {
  title: string;
  content: React.ReactNode;
  secondaryButtonText: string;
  primaryButtonText: string;
  handleSubmit: (e?: React.MouseEvent) => void;
  handleModalClose: () => void;
  primaryButtonType?: 'destructive' | 'primary' | 'red';
  isPrimaryButtonDisabled?: boolean;
  style?: React.CSSProperties;
  handleSecondarySubmit?: (e?: React.MouseEvent) => void;
};

export const ModalPrompt = ({
  title,
  content,
  primaryButtonText,
  secondaryButtonText,
  handleModalClose,
  handleSubmit,
  handleSecondarySubmit,
  primaryButtonType = 'primary',
  isPrimaryButtonDisabled = false,
  style,
}: Props) => {
  return (
    <Modal style={style}>
      <ModalItem>
        <Header>
          {title}
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Content>
          {content}
          <ButtonsWrapper>
            <Button
              text={primaryButtonText}
              disabled={isPrimaryButtonDisabled}
              variant={primaryButtonType}
              onClick={handleSubmit}
            />
            <Button
              text={secondaryButtonText}
              onClick={handleSecondarySubmit || handleModalClose}
              variant='secondary'
            />
          </ButtonsWrapper>
        </Content>
      </ModalItem>
    </Modal>
  );
};
