import { INTEGRATION_TYPE } from './constants';
import { useIntegrationData } from './data';

export const useIntegrationTypeFilter = () => {
  const { INTEGRATIONS } = useIntegrationData();
  const filter = (showTypes: INTEGRATION_TYPE[]) => {
    return INTEGRATIONS.filter(integration => {
      // Check if at least one integration type in showTypes array matches the current integration's show property
      return showTypes.some(type => integration.show.includes(type));
    });
  };
  return filter;
};
