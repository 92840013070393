export const TEMPLATE_QUERY = 'TEMPLATES';
export const EXIT_LINKS_QUERY = 'EXIT_LINK_SETS';
export const OVERLAY_QUERY = 'OVERLAY';
export const MAX_TRIAL_EMAILS = 10;

export const canSendMoreTrialEmails = (
  numberOfRecipients: number,
  trialEmailsSent: number
) => {
  return numberOfRecipients + trialEmailsSent <= MAX_TRIAL_EMAILS;
};
