import { NotFound } from 'app/pages/notFound/NotFound';
import {
  calculateDatesForRange,
  getDateRangeFromLocalstorage,
  timeRangeConstants,
} from 'app/pages/reports/components/DateRangeSelector/DateRangeSelector';
import { Formik, FormikProps } from 'formik';
import { downloadReportData } from 'lib/api';
import {
  createCustomer,
  editCustomer,
  exportUsersData,
  getCustomer,
  getResellerDetails,
} from 'lib/api/superAdminApi';
import { LoadingIndicator } from 'lib/components';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { ReportTypes } from 'lib/const/ReportData';
import {
  AUTOMOTIVE_RESELLER_ID,
  NAPLETON_AUTOMOTIVE_RESELLER_ID,
  PASSWORD_ERROR_MESSAGE,
  SCREEN_VALUES,
} from 'lib/const/SuperAdminConstants';
import { ACTIVE_UNTIL } from 'lib/const/SuperAdminOptions';
import {
  ManageOptOutReportsRoles,
  SuperAdminRole,
} from 'lib/const/SuperAdminRole';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import {
  Reseller,
  SuperAdminCustomerDetails,
  TableField,
  useAuth,
} from 'lib/context';
import { useToastError } from 'lib/hooks';
import { theme } from 'lib/style';
import { PASSWORD_REGEX } from 'lib/utils/regexes';
import React from 'react';
import { MdOutlineChevronRight } from 'react-icons/md';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { UserListing } from '../../components/UserListing';
import { UserTableColumns } from '../../components/UserTable';
import {
  Breadcrumbs,
  Container,
  MainContainer,
  MainWrapper,
} from '../../index.styled';
import { CustomerDetails } from '../components/CustomerDetails';
import {
  CustomersDetailsHeader,
  TABS,
} from '../components/CustomersDetailsHeader';
import { OptOutReports } from '../components/OptOutReports';
import { StatusChangeConfirmationModal } from '../components/StatusChangeConfirmationModal';
import { TabsManager } from '../components/TabsManager';
import { Usage, CustomerUsageRole } from '../components/Usage';
import { AccountChanges } from '../components/accountChanges/AccountChanges';
import { PASSWORD_FORMAT_ERROR } from 'app/pages/account/userAccount/editMyProfile/components';
import { SuperAdminManageDefaultModal } from 'lib/components/modal/manageDefaults/SuperAdminManageDefaultModal';
import { ManageInvites } from '../components/manageInvites/ManageInvites';

const tableFields: TableField[] = [
  {
    value: UserTableColumns.ACCESS,
    label: '',
    width: 50,
  },
  {
    value: UserTableColumns.FIRST_NAME,
    label: 'First Name',
    sortable: true,
    sortKey: 'FIRST_NAME',
    width: 136,
  },
  {
    value: UserTableColumns.LAST_NAME,
    label: 'Last Name',
    sortable: true,
    sortKey: 'LAST_NAME',
    width: 150,
  },
  {
    value: UserTableColumns.LOGINAS,
    label: '',
    width: 40,
  },
  {
    value: UserTableColumns.STATUS,
    label: 'Status',
    width: 120,
  },
  {
    value: UserTableColumns.EMAIL,
    label: 'Email',
    width: 284,
  },
  {
    value: UserTableColumns.CONTACT,
    label: 'Contact',
    width: 110,
  },
  {
    value: UserTableColumns.LAST_LOGIN,
    label: 'Last Login',
    width: 118,
  },
];

const getTabs = (
  showSuperAdminOptions: boolean,
  showOptOutReports: boolean
) => {
  return [
    { value: TABS.CUSTOMER_DETAILS, label: 'Customer Details', showTab: true },
    { value: TABS.USERS, label: 'Users', showTab: showSuperAdminOptions },
    { value: TABS.USAGE, label: 'Usage', showTab: showSuperAdminOptions },
    { value: TABS.ACTIVITY, label: 'Activity', showTab: showSuperAdminOptions },
    {
      value: TABS.OPT_OUT_REPORTS,
      label: 'Opt-out Reports',
      showTab: showOptOutReports,
    },
    {
      value: TABS.MANAGE_INVITES,
      label: 'Manage Invites',
      showTab: showSuperAdminOptions,
    },
  ];
};

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: ${theme.palette.covideoBlue100};
  font-weight: 500;
  font-size: 15px;
  margin-right: 8px;
  cursor: pointer;
`;
const convertStringToDate = (value?: Date) => {
  if (!value) {
    return value;
  }
  const dateString = value.toString();
  const parts = dateString.split('T');
  const dateParts = parts[0].split('-');
  const activeEndDate = new Date();
  activeEndDate.setFullYear(parseInt(dateParts[0], 10));
  activeEndDate.setMonth(parseInt(dateParts[1], 10) - 1);
  activeEndDate.setDate(parseInt(dateParts[2], 10));
  return activeEndDate;
};

const loadEditFields = (customerDataValues?: SuperAdminCustomerDetails) => {
  return {
    customerId: customerDataValues?.customerId,
    resellerId: customerDataValues?.resellerId,
    business: customerDataValues?.business,
    firstName: customerDataValues?.firstName,
    lastName: customerDataValues?.lastName,
    email: customerDataValues?.email,
    emailsSentLimit: customerDataValues?.emailsSentLimit,
    verified: customerDataValues?.verified
      ? customerDataValues?.activeEndDate
        ? ACTIVE_UNTIL
        : VerificationStatus.ACTIVE
      : VerificationStatus.INACTIVE,
    newMaxUsers: customerDataValues?.newMaxUsers,
    maxUsers: customerDataValues?.maxUsers,
    phone1: customerDataValues?.phone1,
    phone2: customerDataValues?.phone2,
    url: customerDataValues?.url,
    referredBy: customerDataValues?.referredBy,
    referralAgent: customerDataValues?.referralAgent,
    accountManager: customerDataValues?.accountManager,
    crmProviderId: customerDataValues?.crmProviderId,
    customerLaunchDate: customerDataValues?.customerLaunchDate,
    activeEndDate: convertStringToDate(customerDataValues?.activeEndDate),
    wheelsTV: customerDataValues?.wheelsTV,
    liveAccess: customerDataValues?.liveAccess,
    hasCovideoMagic: customerDataValues?.hasCovideoMagic,
    freeTrial: customerDataValues?.freeTrial ?? 0,
    smsEnabled: customerDataValues?.smsEnabled ?? 0,
    freeSmsPerMonth: customerDataValues?.freeSmsPerMonth,
    paidSmsPerMonth: customerDataValues?.paidSmsPerMonth,
    versionTwoEnabled: customerDataValues?.versionTwoEnabled,
    versionThreeEnabled: customerDataValues?.versionThreeEnabled,
    versionCDSEnabled: customerDataValues?.versionCDSEnabled,
    transcriptions: customerDataValues?.transcriptions ?? 0,
    transcriptionLicenses: customerDataValues?.transcriptionLicenses,
    location: customerDataValues?.location,
    number: customerDataValues?.number,
    covideoPackageId: Number(customerDataValues?.packageId),
    remainingSmsCount: customerDataValues?.remainingSmsCount,
    sentSmsCount: customerDataValues?.sentSmsCount,
    customerOrganizations: customerDataValues?.customerOrganizations,
    salesforceAccountId: customerDataValues?.salesforceAccountId,
    credit700Url: customerDataValues?.credit700Url,
    vdpHomeNetEnabled: customerDataValues?.vdpHomeNetEnabled,
    vdpIPacketEnabled: customerDataValues?.vdpIPacketEnabled,
    lesaAccess: customerDataValues?.lesaAccess ?? 0,
    autominerEnabled: customerDataValues?.autominerEnabled,
    weeklyReportEnabled: customerDataValues?.weeklyReportEnabled,
    autominerGroupId: customerDataValues?.autominerGroupId,
    audiDealerCode: customerDataValues?.audiDealerCode,
    audiAreaCode: customerDataValues?.audiAreaCode,
    audiSubAreaCode: customerDataValues?.audiSubAreaCode,
    timezone: customerDataValues?.timezone,
    automotiveAccess: customerDataValues?.automotiveAccess,
    jdPowerVDPEnabled: customerDataValues?.jdPowerVDPEnabled,
  };
};

const INIT_CUSTOMER_FIELDS = {
  resellerId: AUTOMOTIVE_RESELLER_ID,
  verified: VerificationStatus.ACTIVE,
  maxUsers: 1,
  wheelsTV: 0,
  freeTrial: 0,
  smsEnabled: 0,
  versionTwoEnabled: 0,
  versionThreeEnabled: 0,
  versionCDSEnabled: 1,
  covideoPackageId: PackageName.LEGACY,
  transcriptions: 0,
  liveAccess: 0,
  hasCovideoMagic: 0,
  vdpHomeNetEnabled: 0,
  vdpIPacketEnabled: 0,
  lesaAccess: 0,
  jdPowerVDPEnabled: 0,
};

const fieldsSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().when(['resellerId'], (resellerId: number) => {
    if (resellerId === NAPLETON_AUTOMOTIVE_RESELLER_ID) {
      return Yup.string()
        .nullable()
        .transform((v, o) => (o === '' ? undefined : v))
        .min(2, 'Last name is too small');
    }
    return Yup.string()
      .required('Last name is required')
      .min(2, 'Last name is too small');
  }),
  email: Yup.string().email('Email is not valid').required('Email is required'),
  covideoPackageId: Yup.string().required('Package is required'),
  phone1: Yup.string()
    .min(7, 'Please enter a phone number equal or longer than 7 characters.')
    .required('Your phone no. cannot be empty'),
  maxUsers: Yup.number().when(['verified'], (verified: boolean) => {
    if (verified) {
      return Yup.number().positive();
    }
    return Yup.number()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable();
  }),
  freeTrial: Yup.number(),
  transcriptions: Yup.number(),
  transcriptionLicenses: Yup.number().when(
    ['transcriptions', 'maxUsers', 'verified'],
    (transcriptions: number, maxUsers: number, verified: boolean) => {
      if (transcriptions) {
        if (verified) {
          return Yup.number()
            .positive()
            .max(maxUsers, 'Caption Licenses cannot be more than user seats')
            .required('Caption Licenses are required.')
            .typeError('Caption Licenses are required');
        }
        return Yup.number()
          .transform(value => (Number.isNaN(value) ? null : value))
          .nullable();
      }
    }
  ),
  smsEnabled: Yup.number(),
  location: Yup.string().when(['smsEnabled'], (smsEnabled: number) => {
    if (smsEnabled) {
      return Yup.string().required('SMS settings: Location is required');
    }
  }),
  number: Yup.string().when(['smsEnabled'], (smsEnabled: number) => {
    if (smsEnabled) {
      return Yup.string().required('SMS settings: Phone number is required');
    }
  }),
  freeSmsPerMonth: Yup.number().when(['smsEnabled'], (smsEnabled: number) => {
    if (smsEnabled) {
      return Yup.number()
        .required('SMS settings: Free SMS per month field is required.')
        .moreThan(
          -1,
          'SMS settings: Free SMS per month must be greater than or equal to 0'
        )
        .typeError('Free SMS per month field is required.');
    }
  }),
  paidSmsPerMonth: Yup.number().when(['smsEnabled'], (smsEnabled: number) => {
    if (smsEnabled) {
      return Yup.number()
        .required('SMS settings: Paid SMS per month field is required.')
        .positive(
          'SMS settings: Paid SMS per month field must be greater than 0'
        )
        .typeError('SMS settings: Paid SMS per month field is required.');
    }
  }),
  username: Yup.string()
    .trim()
    .when(['customerId'], (customerId: number) => {
      if (!customerId) {
        return Yup.string().trim().required('Please enter a valid username.');
      }
    }),
  password: Yup.string().when(['customerId'], (customerId: number) => {
    if (!customerId) {
      return Yup.string()
        .min(8, PASSWORD_ERROR_MESSAGE)
        .matches(PASSWORD_REGEX, PASSWORD_FORMAT_ERROR)
        .required('Please enter a valid password.');
    }
  }),
  salesforceAccountId: Yup.string().required(
    'Salesforce account Id is required'
  ),
});

export const Details = () => {
  const { id, fromScreen, resId } = useParams() as {
    id: string;
    fromScreen: string;
    resId: string;
  };
  const isNewCustomer = id === 'create';
  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange = storedDateRange?.range || timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );
  const { showError } = useToastError();
  const history = useHistory();
  const { userData, invalidateUser } = useAuth();
  const showOptOutReports = ManageOptOutReportsRoles.includes(
    userData.superAdminRole
  );
  const showSuperAdminOptions =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.NON_COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;
  const showCustomerBreadcrumb =
    userData.superAdminRole !== SuperAdminRole.ADMIN &&
    userData.superAdminRole !== SuperAdminRole.ACCESS;

  // Customer form ref
  const formRef = React.useRef<FormikProps<SuperAdminCustomerDetails>>();

  const [activeTab, setActiveTab] = React.useState(TABS.CUSTOMER_DETAILS);
  const [showManageDefaultModal, setShowManageDefaultModal] =
    React.useState(false);
  // Customer params
  const [loading, setLoading] = React.useState(!isNewCustomer);
  const [customer, setCustomer] = React.useState<SuperAdminCustomerDetails>();
  const [showImportModal, setShowImportModal] = React.useState(false);
  // Change customer status params
  const [newStatus, setNewStatus] = React.useState<
    VerificationStatus | undefined
  >(undefined);
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  // User listing params
  const [userSearch, setUserSearch] = React.useState('');
  const [userStatus, setUserStatus] = React.useState(VerificationStatus.ACTIVE);
  const [userSortKey, setUserSortKey] = React.useState('');
  const [userPage, setUserPage] = React.useState(DEFAULT_PAGE);
  const [userSize, setUserSize] = React.useState(DEFAULT_SIZE);
  const [userSortOrder, setUserSortOrder] = React.useState(
    PaginationConstants.ASCENDING
  );
  // Usage params
  const [usageStartDate, setUsageStartDate] = React.useState<Date>(start);
  const [usageEndDate, setUsageEndDate] = React.useState<Date>(end);
  const [usageDateRange, setUsageDateRange] = React.useState(initRange);
  const [customReportTypes, setCustomReportTypes] = React.useState<
    ReportTypes[]
  >([]);
  const [showUsageSendReportModal, setShowUsageSendReportModal] =
    React.useState(false);
  // Reseller params
  const [reseller, setReseller] = React.useState({} as Reseller);
  const [resellerId, setResellerId] = React.useState(0);
  const [usageRole, setUsageRole] = React.useState(CustomerUsageRole.ALL);
  const [disableSaveButton, setDisableSaveButton] = React.useState(false);
  const [customerFields, setCustomerFields] =
    React.useState<SuperAdminCustomerDetails>(INIT_CUSTOMER_FIELDS);
  const goToList = () => history.push(`/admin/customers`);
  const goToResellerList = () => history.push(`/admin/resellers`);
  const goToResellerDetails = () =>
    history.push(`/admin/resellers/${resellerId}`);

  const showEditRoleButton =
    (userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
      userData.superAdminRole === SuperAdminRole.NON_COVIDEO_SUPER_ADMIN ||
      userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN) &&
    !!customer?.automotiveAccess;
  const tabs = getTabs(showSuperAdminOptions, showOptOutReports);
  const loadCustomer = async () => {
    setLoading(true);
    try {
      const res = await getCustomer(parseInt(id, 10));
      setCustomer(res);
      const newCustomerFields = loadEditFields(res);
      setCustomerFields(newCustomerFields);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const loadReseller = async () => {
    try {
      const resellerId = parseInt(resId, 10);
      const reseller = await getResellerDetails(resellerId);
      setReseller(reseller);
      setResellerId(resellerId);
      if (isNewCustomer) {
        const newCustomerFields = {
          ...INIT_CUSTOMER_FIELDS,
          resellerId,
        };

        setCustomerFields(newCustomerFields);
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    if (!isNewCustomer) {
      loadCustomer();
    }

    if (fromScreen === SCREEN_VALUES.RESELLERS && resId !== '0') {
      loadReseller();
    }
  }, []);

  const onUsageDateRangeChange = (start: Date, end: Date, range: string) => {
    setUsageStartDate(start);
    setUsageEndDate(end);
    setUsageDateRange(range);
  };

  const downloadUsage = async () => {
    try {
      const response = await downloadReportData(
        `/superadmin/customers/usage/download`,
        'users.csv',
        usageStartDate,
        usageEndDate,
        undefined,
        customer?.customerId?.toString(),
        undefined,
        {
          usageRole: usageRole,
        },
        undefined,
        undefined,
        customReportTypes
      );
      return response;
    } catch (error) {
      showError(error);
    }
  };

  const handleExportUsers = async () => {
    try {
      const result = await exportUsersData(
        `users.csv`,
        userStatus,
        userSearch,
        userSortKey,
        userSortOrder,
        parseInt(id, 10)
      );
      return result;
    } catch (error) {
      errorToast({ title: `Error in exporting Users.` });
    }
  };

  const handleSubmit = async () => {
    if (formRef.current) {
      const customerData = formRef.current.values;
      customerData.maxUsers =
        customerData.covideoPackageId !== PackageName.LEGACY &&
        customerData.covideoPackageId !== PackageName.TEAMS &&
        customerData.covideoPackageId !== PackageName.ENTERPRISE
          ? 1
          : customerData.maxUsers;
      customerData.verified = customerData.verified > 0 ? 1 : 0;
      if (
        customerData.covideoPackageId &&
        customerData.covideoPackageId !== PackageName.TEAMS &&
        customerData.covideoPackageId !== PackageName.ENTERPRISE
      ) {
        customerData.transcriptionLicenses = 0;
        customerData.transcriptions = 0;
        customerData.smsEnabled = 0;
        customerData.freeTrial = 0;
        customerData.wheelsTV = 0;
      }
      if (!customerData.url) {
        delete customerData.url;
      }
      if (!customerData.verified) {
        if (!customerData.maxUsers) {
          customerData.maxUsers = 0;
        }
        if (!customerData.newMaxUsers) {
          customerData.newMaxUsers = 0;
        }
        if (!customerData.transcriptionLicenses) {
          customerData.transcriptionLicenses = 0;
        }
      }
      await fieldsSchema
        .validate(customerData)
        .then(async function () {
          customerData.username = customerData?.username?.trim();
          setLoading(true);
          try {
            if (!isNewCustomer) {
              const res = await editCustomer(
                customer?.customerId || 0,
                customerData
              );
              if (res) {
                successToast({ title: 'Customer updated successfully' });

                await loadCustomer();
              }
            } else {
              const res = await createCustomer(customerData);
              if (res) {
                successToast({ title: 'Customer created successfully' });
              }
            }
            if (`${customer?.customerId}` === userData.customer.customerId) {
              await invalidateUser();
            }
          } catch (error) {
            errorToast({ title: `Error while updating customer data.` });
          }

          if (isNewCustomer) {
            goToList();
          }
          setLoading(false);
        })
        .catch(function (err) {
          errorToast({
            title: err.message || `Some field have error or missing`,
          });
        });
    }
  };

  const getFormComponent = () => {
    return (
      <Formik
        initialValues={customerFields}
        validationSchema={fieldsSchema}
        onSubmit={() => {}}
        innerRef={ref => {
          if (ref) {
            formRef.current = ref as FormikProps<SuperAdminCustomerDetails>;
          }
        }}
        enableReinitialize
      >
        {(props: FormikProps<SuperAdminCustomerDetails>) => (
          <CustomerDetails
            {...props}
            onFormDirty={(isFormDirty: boolean) =>
              setDisableSaveButton(!isFormDirty)
            }
            isNewCustomer={isNewCustomer}
          />
        )}
      </Formik>
    );
  };

  if (isNewCustomer && !showSuperAdminOptions) {
    return <NotFound />;
  }

  if (loading) {
    return <LoadingIndicator isLoading={loading} left='57%' />;
  }

  return (
    <>
      <MainContainer>
        <MainWrapper>
          <Container
            overflowX={activeTab === TABS.MANAGE_INVITES ? 'none' : 'hidden'}
          >
            {showCustomerBreadcrumb && (
              <Breadcrumbs>
                <Button
                  onClick={() =>
                    fromScreen === SCREEN_VALUES.RESELLERS
                      ? goToResellerList()
                      : goToList()
                  }
                >
                  {fromScreen === SCREEN_VALUES.RESELLERS
                    ? 'Resellers'
                    : 'Customers'}
                </Button>
                <MdOutlineChevronRight
                  size={16}
                  color={theme.palette.covideoBlue100}
                />
                {fromScreen === SCREEN_VALUES.RESELLERS && (
                  <Button onClick={() => goToResellerDetails()}>
                    {reseller.resellerName}
                  </Button>
                )}
                {fromScreen === SCREEN_VALUES.RESELLERS && (
                  <MdOutlineChevronRight
                    size={16}
                    color={theme.palette.covideoBlue100}
                  />
                )}
                <span>
                  {isNewCustomer ? 'New Customer' : customer?.business}
                </span>
              </Breadcrumbs>
            )}
            <CustomersDetailsHeader
              isNewCustomer={isNewCustomer}
              customer={customer}
              tab={activeTab}
              showStatusSwitch={showSuperAdminOptions}
              importUsers={() => setShowImportModal(true)}
              exportUsers={handleExportUsers}
              sendUsage={() => setShowUsageSendReportModal(true)}
              downloadUsage={downloadUsage}
              manageDefaults={() => setShowManageDefaultModal(true)}
              disableSaveButton={disableSaveButton}
              onAddNewUser={() => {
                if (
                  !isNewCustomer &&
                  activeTab !== TABS.CUSTOMER_DETAILS &&
                  customer
                ) {
                  history.push(
                    `/admin/users/entry/${
                      fromScreen === SCREEN_VALUES.CUSTOMERS
                        ? SCREEN_VALUES.CUSTOMERS
                        : SCREEN_VALUES.RESELLERS
                    }/${customer.customerId}`
                  );
                  return;
                }
                handleSubmit();
              }}
              onStatusChange={(value: VerificationStatus) => {
                setNewStatus(value);
                setShowStatusModal(true);
              }}
            />
            {isNewCustomer && getFormComponent()}
            {!isNewCustomer && customer && (
              <>
                <TabsManager
                  tabs={tabs.filter(tab => tab.showTab)}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  tabTitlePadding='0px 0px 2px 0px'
                  tabTitleMargin='0'
                  roundedBorder={false}
                />
                {activeTab === TABS.USERS && (
                  <UserListing
                    fromScreen={fromScreen}
                    customer={customer}
                    prevSearch={userSearch}
                    statusFilter={userStatus}
                    sortKey={userSortKey}
                    order={userSortOrder}
                    tableFields={tableFields}
                    page={userPage}
                    size={userSize}
                    showSuperAdminOptions={showSuperAdminOptions}
                    showEditRoleButton={showEditRoleButton}
                    showImportModal={showImportModal}
                    closeUploadModal={() => setShowImportModal(false)}
                    onPageAndSizeChange={(page, size) => {
                      setUserPage(page);
                      setUserSize(size);
                    }}
                    onSearch={value => {
                      setUserSearch(value.trim());
                      setUserPage(DEFAULT_PAGE);
                    }}
                    onSelectStatus={value => {
                      setUserStatus(value);
                      setUserPage(DEFAULT_PAGE);
                    }}
                    onSorting={(sortKey, order) => {
                      setUserSortKey(sortKey);
                      setUserSortOrder(order);
                      setUserPage(DEFAULT_PAGE);
                    }}
                    refreshCustomer={loadCustomer}
                  />
                )}
                {activeTab === TABS.CUSTOMER_DETAILS && getFormComponent()}
                {activeTab === TABS.OPT_OUT_REPORTS && (
                  <OptOutReports
                    customer={customer}
                    prevSearch={userSearch}
                    sortKey={userSortKey}
                    order={userSortOrder}
                    page={userPage}
                    size={userSize}
                    onPageAndSizeChange={(page, size) => {
                      setUserPage(page);
                      setUserSize(size);
                    }}
                    onSearch={value => {
                      setUserSearch(value.trim());
                      setUserPage(DEFAULT_PAGE);
                    }}
                    onSorting={(sortKey, order) => {
                      setUserSortKey(sortKey);
                      setUserSortOrder(order);
                      setUserPage(DEFAULT_PAGE);
                    }}
                  />
                )}
                {activeTab === TABS.ACTIVITY && (
                  <AccountChanges targetCustomerId={customer.customerId} />
                )}
                {activeTab === TABS.MANAGE_INVITES && (
                  <ManageInvites customerId={customer.customerId} />
                )}
                {activeTab === TABS.USAGE &&
                  customer &&
                  customer.customerId && (
                    <Usage
                      customerId={customer.customerId}
                      isAutomotive={!!customer.automotiveAccess}
                      startDate={usageStartDate}
                      endDate={usageEndDate}
                      customReportTypes={customReportTypes}
                      dateRange={usageDateRange}
                      showSendReportModal={showUsageSendReportModal}
                      setShowSendReportModal={setShowUsageSendReportModal}
                      onDateRangeChange={onUsageDateRangeChange}
                      setCustomReportTypes={setCustomReportTypes}
                      usageRole={usageRole}
                      setUsageRole={setUsageRole}
                    />
                  )}
              </>
            )}
          </Container>
        </MainWrapper>
      </MainContainer>
      {showStatusModal && customer && (
        <StatusChangeConfirmationModal
          customer={customer}
          newStatus={newStatus ?? VerificationStatus.ACTIVE}
          handleModalClose={refresh => {
            setNewStatus(undefined);
            setShowStatusModal(false);
            if (refresh) {
              loadCustomer();
            }
          }}
        />
      )}
      {showManageDefaultModal && customer && (
        <SuperAdminManageDefaultModal
          customerId={customer.customerId}
          handleModalClose={() => {
            setShowManageDefaultModal(false);
          }}
        />
      )}
    </>
  );
};
