import React, { ReactNode } from 'react';
import { Button } from 'react-covideo-common';
import {
  MainToastWrapper,
  ToastTextWrapper,
  ToastRightWrapper,
  ToastLeftWrapper,
  Title,
  AdditionalInfo,
} from './index.styled';
import { ToastProps } from './types';

interface IBaseNotification extends ToastProps {
  children: ReactNode;
}

export const BaseNotificationBody = (props: IBaseNotification) => {
  const { title, additionalInfo, button, children, type } = props;
  return (
    <MainToastWrapper>
      <ToastLeftWrapper>{children}</ToastLeftWrapper>
      <ToastRightWrapper>
        <ToastTextWrapper>
          <Title type={type}>{title}</Title>
          {additionalInfo && <AdditionalInfo>{additionalInfo}</AdditionalInfo>}
        </ToastTextWrapper>
        {button && (
          <Button
            text={button.text}
            variant={button.variant}
            onClick={button.action}
          />
        )}
      </ToastRightWrapper>
    </MainToastWrapper>
  );
};
