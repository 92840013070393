import React from 'react';
import { CombinedBoard, DropItem } from 'lib/api/droplr/types';
import { FaFileCsv, FaFileWord, FaHtml5 } from 'react-icons/fa';
import { GoFileZip } from 'react-icons/go';
import { SiCsswizardry, SiMicrosoftexcel } from 'react-icons/si';
import { RiFilePpt2Fill } from 'react-icons/ri';
import { FiFileText } from 'react-icons/fi';
import { BsFileRichtext } from 'react-icons/bs';
import {
  AiOutlineFile,
  AiOutlineFileGif,
  AiOutlineFileImage,
} from 'react-icons/ai';

export const BOARDS_FALLBACK_THUMBNAIL =
  'https://www.covideo.com/images/emails/folderSet.png';
export const DEFAULT_FILE_THUMBNAIL =
  'https://www.covideo.com/images/emails/defaultEmailFileIcon.png';

export const BOARD_PRIVACY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
};

export const BOARD_PRIVACY_OPTIONS = [
  {
    value: BOARD_PRIVACY.PUBLIC,
    text: 'Public',
  },
  {
    value: BOARD_PRIVACY.PRIVATE,
    text: 'Password protected',
  },
];

export const DROP_PRIVACY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  TEAM: 'TEAM',
};

export const DEFAULT_BOARD = {
  accessList: [],
  creator: {
    email: '',
    userId: '',
  },
  deleted: false,
  groupsAccessList: [],
  id: '',
  isPublic: false,
  name: '',
  password: '',
  poweredBy: false,
  privacy: BOARD_PRIVACY.PUBLIC,
  shortlink: '',
  uploadPrivacy: BOARD_PRIVACY.PUBLIC,
  userStatus: '',
  thumbnailUrl: '',
} as CombinedBoard;

export const DEFAULT_DROP = {} as DropItem;

export const DropType = {
  FILE: 'FILE',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
};

export const DROP_FILE_TYPES = [
  {
    value: 'all',
    label: 'All Filetypes',
  },
  {
    value: 'IMAGE',
    label: 'Images',
  },
  {
    value: 'VIDEO',
    label: 'Videos',
  },
  {
    value: 'AUDIO',
    label: 'Sound',
  },
  {
    value: 'FILE',
    label: 'Other',
  },
];

export const enum DROPTYPE_OPTIONS {
  SHARED = 'shared',
  OWNED = 'owned',
}

export const SortFields = [
  {
    value: 'uploadedDESC',
    label: 'Uploaded (DESC)',
    order: 'DESC',
    key: 'UPLOAD',
  },
  {
    value: 'uploadedASC',
    label: 'Uploaded (ASC)',
    order: 'ASC',
    key: 'UPLOAD',
  },
  {
    value: 'createdAtASC',
    label: 'Created on (ASC)',
    order: 'ASC',
    key: 'CREATION',
  },
  {
    value: 'createdAtDESC',
    label: 'Created on (DESC)',
    order: 'DESC',
    key: 'CREATION',
  },
  {
    value: 'titleASC',
    label: 'Title (ASC)',
    order: 'ASC',
    key: 'TITLE',
  },
  {
    value: 'titleDESC',
    label: 'Title (DESC)',
    order: 'DESC',
    key: 'TITLE',
  },
  {
    value: 'sizeASC',
    label: 'Size (ASC)',
    order: 'ASC',
    key: 'SIZE',
  },
  {
    value: 'sizeDESC',
    label: 'Size (DESC)',
    order: 'DESC',
    key: 'SIZE',
  },
  {
    value: 'viewsASC',
    label: 'Views (ASC)',
    order: 'ASC',
    key: 'VIEWS',
  },
  {
    value: 'viewsDESC',
    label: 'Views (DESC)',
    order: 'DESC',
    key: 'VIEWS',
  },
  {
    value: 'activityASC',
    label: 'Activity (ASC)',
    order: 'ASC',
    key: 'ACTIVITY',
  },
  {
    value: 'activityDESC',
    label: 'Activity (DESC)',
    order: 'DESC',
    key: 'ACTIVITY',
  },
];

export type SortField = {
  value: string;
  label: string;
  order: string;
  key: string;
};

export const getIconForFileType = (
  variant: string,
  viewType: string,
  color: string = '#001B53'
) => {
  let icon;
  let className =
    viewType === 'grid' ? 'gridThumbnailIcons' : 'listThumbnailIcons';
  switch (variant) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      icon = <FaFileWord className={className} color={color} />;
      break;
    }

    case 'application/zip':
    case 'application/gzip': {
      icon = <GoFileZip className={className} color={color} />;
      break;
    }

    case 'text/csv': {
      icon = <FaFileCsv className={className} color={color} />;
      break;
    }

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      icon = <SiMicrosoftexcel className={className} color={color} />;
      break;
    }

    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint': {
      icon = <RiFilePpt2Fill className={className} color={color} />;
      break;
    }

    case 'text/plain': {
      icon = <FiFileText className={className} color={color} />;
      break;
    }

    case 'text/rtf': {
      icon = <BsFileRichtext className={className} color={color} />;
      break;
    }

    case 'text/html': {
      icon = <FaHtml5 className={className} color={color} />;
      break;
    }

    case 'text/css': {
      icon = <SiCsswizardry className={className} color={color} />;
      break;
    }

    case 'image/svg+xml':
    case 'image/webp': {
      icon = <AiOutlineFileImage className={className} color={color} />;
      break;
    }

    case 'image/gif': {
      icon = <AiOutlineFileGif className={className} color={color} />;
      break;
    }

    default: {
      icon = <AiOutlineFile className={className} color={color} />;
      break;
    }
  }

  return <>{icon}</>;
};

export const enum DROP_ACTIVITY_TYPE {
  VIEWED = 'DROP_VIEWED',
  DOWNLOADED = 'DROP_DOWNLOADED',
}

export const DroplrViews = [
  {
    value: 'grid',
    text: 'Grid',
  },
  {
    value: 'list',
    text: 'List',
  },
];

export const LIBRARY_VIEW = 'library_view';
