import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { DefaultLabel, LoadingIndicator } from 'lib/components';
import { EditableRow } from './EditableRow';

type Cue = {
  orgTime: string;
  displayStartTime: string;
  start: number;
  end: number;
  text: string;
};

interface ComponentProps {
  updateSubtitleText: (cueText: string, index: number) => void;
  isProcessing?: boolean;
  currentTime: number;
  cues: Cue[];
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 16px;
  ${theme.mediaQueryMinWidth.sm} {
    max-width: 488px;
  }
  ${theme.mediaQueryMinWidth.md} {
    width: calc(50% - 24px);
    padding-right: 24px;
    margin-top: 0;
  }
  ${theme.mediaQueryMinWidth.xlg} {
    margin: 0;
  }
`;

const Headers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  label {
    &:nth-of-type(1) {
      margin: 0 64px 0 0;
    }
    &:nth-of-type(2) {
      flex-grow: 2;
    }
  }
`;

const SubtitlesOverlay = styled.div`
  margin: 8px 0 0 0;
  border-radius: 4px;
  background-color: ${theme.palette.gray};
  max-height: 520px;
`;

const SubtitlesWrapper = styled.div`
  width: calc(100% - 8px);
  max-height: 520px;
  height: 100%;
  border-radius: 4px;
  background-color: ${theme.palette.gray};
  overflow-y: scroll;
  scrollbar-width: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.palette.title};
  -ms-overflow-style: scrollbar;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.gray};
  }
  ::-webkit-scrollbar-thumb {
    height: 48px;
    border-radius: 4px;
    background-color: ${theme.palette.primaryTmBlue50Percent};
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.palette.primaryTmBlue70Percent};
  }
`;

export const EditableSubs = (props: ComponentProps) => {
  const { cues, currentTime, updateSubtitleText, isProcessing } = props;

  return (
    <Container>
      <Headers>
        <DefaultLabel htmlFor="checkbox">Time</DefaultLabel>
        <DefaultLabel htmlFor="checkbox">Text</DefaultLabel>
      </Headers>
      <SubtitlesOverlay>
        <SubtitlesWrapper>
          <LoadingIndicator
            isLoading={isProcessing}
            text="Your video is being transcribed. This may take a few minutes..."
            zIndex={1}
          />
          {cues.map((cue, index: number) => (
            <EditableRow
              key={index}
              updateSubtitleText={updateSubtitleText}
              index={index}
              currentTime={currentTime}
              cue={cue}
            />
          ))}
        </SubtitlesWrapper>
      </SubtitlesOverlay>
    </Container>
  );
};
