import { useMutation, useQueryClient } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { IUserInvitesResponse } from './useUserInvitesQuery';
import { superAdminKeys } from './superAdminKeys';

interface IInvites {
  userId: number;
  deliveryTime?: string;
}

interface UserInvitesParams {
  customerId?: number;
  invites: IInvites[];
}

const updateCustomerUserInvites = async (
  params: UserInvitesParams
): Promise<IUserInvitesResponse> => {
  const { customerId, invites } = params;

  return (
    await EXPRESS_API.post(`/superadmin/customers/${customerId}/user-invites`, {
      invites,
    })
  ).data;
};

interface IUseUserInvitesMutation {
  onSuccessCallback: () => void;
}

export const useUserInvitesMutation = ({
  onSuccessCallback,
}: IUseUserInvitesMutation) => {
  const queryClient = useQueryClient();
  return useMutation(updateCustomerUserInvites, {
    onSuccess: (_, params) => {
      const inviteWording = params.invites.length > 1 ? 'invites' : 'invite';
      const scheduleType = !!params?.invites[0]?.deliveryTime
        ? 'scheduled'
        : 'sent';
      successToast({
        title: `User ${inviteWording} ${scheduleType} successfully.`,
      });
      queryClient.invalidateQueries(superAdminKeys.all_invite_users());
      onSuccessCallback();
    },
    onError: (_, params) => {
      const inviteWording = params.invites.length > 1 ? 'invites' : 'invite';
      errorToast({
        title: `Failed to send user ${inviteWording}. Please try again.`,
      });
    },
  });
};
