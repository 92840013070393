import { QuoteItem } from 'lib/api/repairOrders/types';
import { toNumber } from 'lodash';

export const calculateCloseRatio = (
  quoteItems: QuoteItem[] = []
): { itemCloseRatio: string; dollarCloseRatio: string } => {
  const approved =
    quoteItems?.filter((quoteItem: QuoteItem) => quoteItem?.approved) || [];
  const calculatePrice = (a: any, curr: any) => a + toNumber(curr.price);

  const totalPrice = quoteItems?.reduce(calculatePrice, 0) || 0;
  const totalApprovedPrice: number = approved?.reduce(calculatePrice, 0) || 0;

  const dollarCloseRatio = `${
    totalPrice ? Math.round((totalApprovedPrice / totalPrice) * 100) : 0
  }%`;
  const itemCloseRatio = `${
    quoteItems
      ? Math.round((approved.length / quoteItems.length || 0) * 100)
      : 0
  }%`;

  return { dollarCloseRatio, itemCloseRatio };
};
