import { theme } from 'lib/style';
import * as React from 'react';
import styled from 'styled-components/macro';

interface ComponentProps {
  text?: string;
  textTransform?: string;
  topPixels?: number;
  leftPixels?: number;
  widthPixels?: number;
  hideTriangle?: boolean;
  subtext?: string;
  button?: JSX.Element;
}

const Wrapper = styled.figcaption<ComponentProps>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: ${props => props.topPixels}px;
  left: ${props => props.leftPixels}px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  z-index: -1;
  width: 0;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 6px 12px rgba(66, 79, 104, 0.06);
  border-radius: 6px;
  padding: 8px 12px;
`;

const Triangle = styled.span`
  border-top: 0;
  border-bottom: 8px solid #fff;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`;
const InfoBox = styled.div<ComponentProps>`
  width: ${props => (props.widthPixels ? `${props.widthPixels}px` : 'auto')};
  text-align: center;
  font-size: 13px;
  color: ${theme.palette.black_1_100};
  font-weight: normal;
  text-transform: ${props =>
    props.textTransform ? props.textTransform : 'uppercase'};
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
`;

export const Tooltip = (props: ComponentProps) => {
  const {
    topPixels,
    leftPixels,
    widthPixels,
    textTransform,
    hideTriangle = false,
    subtext = '',
    button,
  } = props;
  return (
    <Wrapper topPixels={topPixels} leftPixels={leftPixels}>
      {!hideTriangle && <Triangle />}
      <InfoBox widthPixels={widthPixels} textTransform={textTransform}>
        {props.text}
        {subtext.length ? <br /> : null}
        {subtext.length ? subtext : null}
        {button && button}
      </InfoBox>
    </Wrapper>
  );
};
