import myTeamIcon from 'assets/images/freemiumOnboarding/myTeam.webp';
import myselfIcon from 'assets/images/freemiumOnboarding/myself.webp';
import hrIcon from 'assets/images/freemiumOnboarding/hr.webp';
import salesIcon from 'assets/images/freemiumOnboarding/sales.webp';
import supportIcon from 'assets/images/freemiumOnboarding/support.webp';
import marketingIcon from 'assets/images/freemiumOnboarding/marketing.webp';
import fundraisingIcon from 'assets/images/freemiumOnboarding/fundraising.webp';
import dealershipIcon from 'assets/images/freemiumOnboarding/dealership.webp';
import educationIcon from 'assets/images/freemiumOnboarding/education.webp';
import otherIcon from 'assets/images/freemiumOnboarding/other.webp';
import classmatesIcon from 'assets/images/freemiumOnboarding/classmates.webp';
import familyIcon from 'assets/images/freemiumOnboarding/family.webp';
import colleaguesIcon from 'assets/images/freemiumOnboarding/colleagues.webp';
import clientsIcon from 'assets/images/freemiumOnboarding/clients.webp';
import wontShareIcon from 'assets/images/freemiumOnboarding/wontShare.webp';
import { SelectorItem } from './types';

export const freemiumOboardingFlows: SelectorItem[] = [
  {
    value: 'Business',
    iconSrc: myTeamIcon,
  },
  {
    value: 'Personal',
    iconSrc: myselfIcon,
  },
];

export const covideoUsageOptionsForMyTeams: SelectorItem[] = [
  {
    value: 'Dealership',
    iconSrc: dealershipIcon,
  },
  {
    value: 'Sales',
    iconSrc: salesIcon,
  },
  {
    value: 'Support',
    iconSrc: supportIcon,
  },
  {
    value: 'Marketing',
    iconSrc: marketingIcon,
  },
  {
    value: 'Education',
    iconSrc: educationIcon,
  },
  {
    value: 'HR',
    iconSrc: hrIcon,
  },
  {
    value: 'Fundraising',
    iconSrc: fundraisingIcon,
  },
  {
    value: 'Other',
    iconSrc: otherIcon,
  },
];

export const covideoUsageOptionsForMyself: SelectorItem[] = [
  ...covideoUsageOptionsForMyTeams,
];

export const shareVideoOptionsForMyself: SelectorItem[] = [
  {
    value: 'Classmates',
    iconSrc: classmatesIcon,
  },
  {
    value: 'Family',
    iconSrc: familyIcon,
  },
  {
    value: 'Colleagues',
    iconSrc: colleaguesIcon,
  },
  {
    value: 'Clients',
    iconSrc: clientsIcon,
  },
  {
    value: "I won't share",
    iconSrc: wontShareIcon,
  },
  {
    value: 'Other',
    iconSrc: otherIcon,
  },
];

export const companySizeOptionsForCds: string[] = [
  '2-4',
  '5-10',
  '11-20',
  '21-50',
  '51-100',
  '100+',
];

export const companySizeOptions: string[] = [
  'Just me',
  ...companySizeOptionsForCds,
];

export const INDUSTRIES = {
  AUTOMOTIVE: 'automotive',
  MARINE: 'marine',
  RV: 'rv',
  OTHER: 'other',
};

export const CDS_DEPARTMENTS = {
  SALES: 'Sales',
  SERVICE: 'Service',
  BDC: 'BDC',
  OTHER: 'Other',
};

export const cdsDepartmentOptions = [
  {
    value: CDS_DEPARTMENTS.SALES,
    label: CDS_DEPARTMENTS.SALES,
  },
  {
    value: CDS_DEPARTMENTS.SERVICE,
    label: CDS_DEPARTMENTS.SERVICE,
  },
  {
    value: CDS_DEPARTMENTS.BDC,
    label: CDS_DEPARTMENTS.BDC,
  },
  {
    value: CDS_DEPARTMENTS.OTHER,
    label: CDS_DEPARTMENTS.OTHER,
  },
];

export const covideoIndustryOptions = [
  {
    value: INDUSTRIES.AUTOMOTIVE,
    label: 'Automotive',
  },
  {
    value: INDUSTRIES.MARINE,
    label: 'Marine',
  },
  {
    value: INDUSTRIES.RV,
    label: 'RV',
  },
  {
    value: INDUSTRIES.OTHER,
    label: 'Other',
  },
];

export const ANDROID_GOOGLE_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=covideo.com&feature=nav_result#?t=W251bGwsMSwyLDNd';
export const IOS_APP_STORE_URL =
  'https://itunes.apple.com/us/app/covideo/id507458417?mt=8';
export const browserExtensionURL =
  'https://chrome.google.com/webstore/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc';
export const MOBILE_COVIDEO_URL = 'https://mobile.covideo.com';
