import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { conversationsKeys } from './queryKeys';
import { successToast } from 'lib/components/toasts/success';
import { ISingleConversation } from './getSingleConversation';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IStartConversationParams {
  email: string | null;
  phoneNumber: string | null;
  contactId: number | null;
}

export interface IResponseStartConversation extends ISingleConversation {}

const startConversation = async ({
  phoneNumber,
  email,
  contactId,
}: IStartConversationParams) => {
  const body = {
    ...(!!phoneNumber ? { phoneNumber: phoneNumber } : {}),
    ...(!!email ? { email: email } : {}),
    ...(!!contactId ? { contactId: contactId } : {}),
  };

  const { data } = await EXPRESS_API.post(`conversations`, body);

  return data as IResponseStartConversation;
};

export const useStartConversationMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation(
    (data: IStartConversationParams) => startConversation(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(conversationsKeys.lists());
        successToast({ title: 'Your chat is created successfully!' });
      },
      onError: (err: AxiosError) => showError(err),
    }
  );
};
