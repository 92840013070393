import styled, { keyframes } from 'styled-components/macro';

// Taken from https://loading.io/css/

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
type Props = {
  size?: number;
  thickness?: number;
};
export const Spinner = styled.div<Props>`
  display: inline-block;
  width: ${props => (props.size ? '15' : '24')}px;
  height: ${props => (props.size ? '15' : '24')}px;

  &:after {
    content: ' ';
    display: block;
    width: ${props => (props.size ? props.size : '14')}px;
    height: ${props => (props.size ? props.size : '14')}px;
    margin: 1px;
    border-radius: 50%;
    border: ${props => (props.thickness ? props.thickness : '3')}px solid
      ${props => (props.color ? props.color : '#fff')};
    border-color: ${props => (props.color ? props.color : '#fff')} transparent
      ${props => (props.color ? props.color : '#fff')} transparent;
    animation: ${animation} 1.2s linear infinite;
  }
`;
