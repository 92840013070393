import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { theme } from 'lib/style';
import {
  AccordionBody,
  AccordionHeader,
  AccordionWrapper,
  CustomerName,
  Left,
  OrganizationData,
  OrganizationName,
  Right,
  ActionButtons,
  TooltipWrapper,
  Item,
  PopupList,
  EmptyTable,
  ClickableWrapper,
  CustomersRow,
  CustomersHeader,
  CustomersBody,
  HeaderItem,
  PopupTextStyle,
} from './styles';
import { Chip } from 'app/pages/account/userAccount/socialProfiles/style';
import { MdClose, MdEdit, MdUnfoldLess, MdUnfoldMore } from 'react-icons/md';
import { HoverPopup } from 'lib/components';
import { Button } from 'react-covideo-common';
import {
  CustomerOrganization,
  OrganizationRegion,
  SuperAdminOrganization,
  UserOrganizationAccess,
} from 'lib/context';
import { HiOutlinePlusSm } from 'react-icons/hi';
import {
  useDeleteCustomerFromOrganization,
  useDeleteOrganization,
  useOrganizationsQuery,
} from 'lib/api';
import { ModalPrompt } from 'lib/components/modal';
import {
  DEFAULT_RESELLER_ID,
  SCREEN_VALUES,
} from 'lib/const/SuperAdminConstants';
import LinkCustomersModal from '../LinkCustomersModal';

interface OrganizationProps {
  organization: SuperAdminOrganization;
}

const Accordion = ({ organization }: OrganizationProps) => {
  const history = useHistory();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);
  const [customerId, setCustomerId] = useState<number | null>(null);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  const { refetch: refetchOrganizations } = useOrganizationsQuery({
    params: {
      start: 0,
      limit: 500,
    },
    enabled: true,
  });

  const {
    name,
    customerOrganizations,
    userOrganizationAccesses,
    organizationRegions,
  } = organization;
  const { isLoading: isLoadingDelete, mutateAsync: deleteOrganization } =
    useDeleteOrganization(organization.organizationId);
  const {
    isLoading: isLoadingCustomerDelete,
    mutateAsync: deleteCustomerFromOrganization,
  } = useDeleteCustomerFromOrganization();

  const handleOrganizationEdit = () => {
    history.push(`/admin/organizations/${organization.organizationId}`);
  };
  const handleOrganizationDelete = async () => {
    await deleteOrganization();
    refetchOrganizations();
  };
  const handleCustomerDelete = (customerId: number) => {
    if (customerId) {
      deleteCustomerFromOrganization({
        organizationId: organization.organizationId,
        customerId,
      });
      refetchOrganizations();
      setCustomerId(null);
    }
  };
  const handleCustomerEdit = (customerId: number) => {
    history.push(
      `/admin/customers/${customerId}/${SCREEN_VALUES.RESELLERS}/${DEFAULT_RESELLER_ID}`
    );
  };

  const hasCustomers =
    customerOrganizations && customerOrganizations?.length > 0;
  const hasAdmins = !!userOrganizationAccesses?.length;
  const hasRegions = !!organizationRegions?.length;

  const findCustomerInfo = (cid: number) => {
    const info = customerOrganizations?.find(
      (customerOrg: CustomerOrganization) => customerOrg.customerId === cid
    );
    return info?.customer?.business || '-';
  };

  return (
    <AccordionWrapper>
      {showDeleteConfirmationModal && (
        <ModalPrompt
          title={'Delete Organization?'}
          content={
            <>
              Are you sure you want to delete <b>{organization.name}</b>? All
              Customers will be unlinked from it.
            </>
          }
          secondaryButtonText={'No, Keep Organization'}
          primaryButtonText={'Yes, Delete Organization'}
          primaryButtonType={'destructive'}
          handleSubmit={() => {
            setShowDeleteConfirmationModal(false);
            handleOrganizationDelete();
          }}
          handleModalClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
      {customerId && (
        <ModalPrompt
          title={'Delete Customer?'}
          content={
            <>
              Are you sure you want to remove{' '}
              <b>{findCustomerInfo(customerId)}</b> from{' '}
              <b>{organization.name}</b>?
            </>
          }
          secondaryButtonText={'No, Keep Customer'}
          primaryButtonText={'Yes, Remove Customer'}
          primaryButtonType={'destructive'}
          handleSubmit={() => {
            handleCustomerDelete(customerId);
          }}
          handleModalClose={() => setCustomerId(null)}
        />
      )}
      <AccordionHeader show={showMore}>
        <Left>
          <ClickableWrapper onClick={() => setShowMore(prev => !prev)}>
            {showMore ? (
              <MdUnfoldLess fontSize={24} color={theme.palette.covideoBlue60} />
            ) : (
              <MdUnfoldMore fontSize={24} color={theme.palette.covideoBlue60} />
            )}
            <OrganizationName>{name}</OrganizationName>
          </ClickableWrapper>
          <HoverPopup
            width='max-content'
            maxWidth='267px'
            position='above'
            padding='8px 12px'
            showTriangle
            disableHover={!hasCustomers}
            hoverable={
              <Chip
                bgColor={theme.palette.covideoBlue02NonAlpha}
                fontColor={theme.palette.covideoBlue80}
              >
                {customerOrganizations?.length} customer
                {customerOrganizations?.length !== 1 ? 's' : ''}
              </Chip>
            }
            popup={
              <PopupList>
                {customerOrganizations?.map(
                  (organization: CustomerOrganization) => (
                    <PopupTextStyle key={organization.customerId}>
                      {organization.customer?.business}
                    </PopupTextStyle>
                  )
                )}
              </PopupList>
            }
          />
          <HoverPopup
            width='max-content'
            maxWidth='267px'
            position='above'
            padding='8px 12px'
            showTriangle
            hoverable={
              <Chip
                bgColor={theme.palette.covideoBlue02NonAlpha}
                fontColor={theme.palette.covideoBlue80}
              >
                {userOrganizationAccesses?.length} admin
                {userOrganizationAccesses?.length !== 1 ? 's' : ''}
              </Chip>
            }
            disableHover={!hasAdmins}
            popup={
              <PopupList>
                {userOrganizationAccesses?.map(
                  (admin: UserOrganizationAccess) => (
                    <PopupTextStyle key={admin.id}>
                      {admin.user.firstName} {admin.user.lastName}
                    </PopupTextStyle>
                  )
                )}
              </PopupList>
            }
          />
          <HoverPopup
            width='max-content'
            maxWidth='267px'
            position='above'
            padding='8px 12px'
            showTriangle
            hoverable={
              <Chip
                bgColor={theme.palette.covideoBlue02NonAlpha}
                fontColor={theme.palette.covideoBlue80}
              >
                {organizationRegions?.length} region
                {organizationRegions?.length !== 1 ? 's' : ''}
              </Chip>
            }
            disableHover={!hasRegions}
            popup={
              <PopupList>
                {organizationRegions?.map((region: OrganizationRegion) => (
                  <PopupTextStyle key={region.organizationRegionId}>
                    {region.name}
                  </PopupTextStyle>
                ))}
              </PopupList>
            }
          />
        </Left>
        <Right>
          <Button
            variant='white'
            icon={<MdEdit />}
            onClick={handleOrganizationEdit}
            disabled={isLoadingDelete || isLoadingCustomerDelete}
            text={'Edit'}
          />
          <Button
            text='Delete'
            variant='destructive'
            onClick={() => setShowDeleteConfirmationModal(true)}
            disabled={isLoadingDelete || isLoadingCustomerDelete}
          />
        </Right>
      </AccordionHeader>
      <AccordionBody show={showMore}>
        {hasCustomers && (
          <OrganizationData>
            <CustomersHeader>
              <HeaderItem>Customer Name</HeaderItem>
              <HeaderItem>Region</HeaderItem>
            </CustomersHeader>
            <CustomersBody>
              {customerOrganizations?.map((customer: CustomerOrganization) => (
                <CustomersRow key={customer.customerId}>
                  <Item>
                    <CustomerName
                      onClick={() => handleCustomerEdit(customer.customerId)}
                    >
                      {customer.customer?.business}
                    </CustomerName>
                  </Item>
                  <Item>
                    {customer.organizationRegion && (
                      <Chip
                        width='fit-content'
                        height='24px'
                        bgColor={theme.palette.white}
                        fontColor={theme.palette.neutral80}
                      >
                        {customer.organizationRegion?.name}
                      </Chip>
                    )}
                  </Item>
                  <Item>
                    <ActionButtons>
                      <HoverPopup
                        width='137px'
                        position='above'
                        padding='8px 12px'
                        showTriangle
                        hoverable={
                          <Button
                            variant='white'
                            size='small'
                            icon={<MdEdit fontSize={20} />}
                            onClick={() =>
                              handleCustomerEdit(customer.customerId)
                            }
                            disabled={
                              isLoadingDelete || isLoadingCustomerDelete
                            }
                          />
                        }
                        popup={<TooltipWrapper>Edit Customer</TooltipWrapper>}
                      />
                      <HoverPopup
                        width='208px'
                        position='above'
                        padding='8px 12px'
                        showTriangle
                        hoverable={
                          <Button
                            variant='white'
                            size='small'
                            icon={<MdClose fontSize={20} />}
                            onClick={() => {
                              setCustomerId(customer.customerId);
                            }}
                            disabled={
                              isLoadingDelete || isLoadingCustomerDelete
                            }
                          />
                        }
                        popup={
                          <TooltipWrapper>
                            Remove from Organization
                          </TooltipWrapper>
                        }
                      />
                    </ActionButtons>
                  </Item>
                </CustomersRow>
              ))}
            </CustomersBody>
          </OrganizationData>
        )}
        {!hasCustomers && (
          <EmptyTable>
            There are no Customers linked to the Organization yet.
          </EmptyTable>
        )}
        <Button
          text='Link Customers'
          size='small'
          icon={<HiOutlinePlusSm color={theme.palette.blue100} />}
          onClick={() => setIsLinkModalOpen(true)}
          variant='white'
        />
      </AccordionBody>
      {isLinkModalOpen && (
        <LinkCustomersModal
          handleModalClose={() => {
            document.body.style.overflow = 'auto';
            setIsLinkModalOpen(false);
          }}
          organization={organization}
        />
      )}
    </AccordionWrapper>
  );
};

export default Accordion;
