import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { useOnClickOutside } from 'lib/utils';
import { NewModal } from 'lib/components';
import { OptOutContact, removeOptInContact } from 'lib/api';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
const Header = styled.div`
  display: flex;
  margin-top: -30px;
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;
type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  optOutRecord: OptOutContact;
  hasConfirm?: boolean;
};

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

export const OptInModal = ({ handleModalClose, optOutRecord }: Props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const { ref } = useOnClickOutside(handleModalClose);

  const handleOptInUser = async () => {
    setLoadingModal(true);
    try {
      await removeOptInContact({ contactId: optOutRecord.contactId });
      successToast({
        title: `${optOutRecord.user.email} has been opted in successfully`,
      });
    } catch (error) {
      errorToast({ title: `Error in opting in user` });
      console.log('Error', error);
    }
    handleModalClose(1);
    setLoadingModal(false);
  };

  return (
    <NewModal
      headerText={`Opt Back In`}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose()}
      headerMargin='0 0 0 32px'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          {!loadingModal && (
            <Content>
              You are about to opt-in{' '}
              <span style={{ fontWeight: 600 }}>
                {optOutRecord.user.firstName} {optOutRecord.user.lastName} (
                {optOutRecord.email})
              </span>
            </Content>
          )}
        </Header>
        <SubmitButtonWrapper>
          <Button text={'Opt-in this user'} onClick={() => handleOptInUser()} />
        </SubmitButtonWrapper>
      </div>
    </NewModal>
  );
};
