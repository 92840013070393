import * as React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { Field, FieldProps, Formik } from 'formik';
import { TextInput } from '../../inputs';
import { Button } from 'react-covideo-common';
import { Folder, useGetFolders } from 'lib/api/folders/getFolders';

const ContentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9297a2;
  margin-bottom: 8px;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 8px;
`;

type Props = {
  loading: boolean;
  handleModalClose: () => void;
  onSubmit: (folder: Partial<Folder>) => void;
  presentFolders?: Folder[];
  edit?: { currentFolder?: Partial<Folder> };
};

export const CEModalFolders = ({
  handleModalClose,
  loading,
  presentFolders,
  edit = { currentFolder: { name: '' } },
  onSubmit,
}: Props) => {
  const { data: folders = [] } = useGetFolders();

  return (
    <NewModal
      headerText={edit?.currentFolder?.name ? 'Rename Folder' : 'New Folder'}
      headerTextStyle={{ fontSize: '20px' }}
      closeModalOnBackdropOrEscPress={false}
      closeModal={handleModalClose}
      shouldReturnFocusAfterClose={false}
    >
      <Formik
        initialValues={{ name: edit.currentFolder?.name }}
        onSubmit={() => console.log(edit)}
      >
        {({ isSubmitting, values }) => {
          const folderName = (values.name || '').trim();
          const invalidFolderName = !folderName;
          const folderAlreadyExist = presentFolders
            ? !!presentFolders.find(folder => folder.name === folderName) &&
              !loading
            : !!folders?.find(folder => folder.name === folderName);
          let errorMessage = '';
          if (values.name && invalidFolderName) {
            errorMessage = 'Invalid folder name!';
          } else if (
            folderAlreadyExist &&
            folderName !== edit.currentFolder?.name
          ) {
            errorMessage = 'Folder already exists!';
          }
          return (
            <>
              <Label htmlFor='name'>Folder Name</Label>
              <Field name='name'>
                {({ field }: FieldProps) => {
                  return (
                    <TextInput
                      {...field}
                      autoFocus={true}
                      maxLength={45}
                      placeholder='Example folder'
                      type={'text'}
                    />
                  );
                }}
              </Field>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <ContentFooter>
                <Button
                  text={
                    edit?.currentFolder?.name ? 'Save Changes' : 'Create folder'
                  }
                  disabled={
                    loading ||
                    isSubmitting ||
                    invalidFolderName ||
                    folderAlreadyExist
                  }
                  onClick={() => onSubmit(values)}
                />
              </ContentFooter>
            </>
          );
        }}
      </Formik>
    </NewModal>
  );
};
