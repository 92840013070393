import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { repairOrderKeys } from './repairOrderKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const deleteMultipleRepairOrders = async (data: string[]): Promise<void> => {
  return (
    await EXPRESS_API.delete(`repair-orders`, {
      data,
    })
  ).data;
};

export const useDeleteMultipleRepairOrders = (
  data: string[],
  setSelectedRepairOrder: (arg: string[]) => void,
  setShowDeleteModal: (arg: boolean) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteMultipleRepairOrders(data), {
    onSuccess: () => {
      successToast({
        title: `Repair Order${
          data.length > 1 ? 's' : ''
        } successfully deleted!`,
      });
      setSelectedRepairOrder([]);
      setShowDeleteModal(false);

      // invalidate ro list
      queryClient.invalidateQueries(repairOrderKeys.repairOrders());
    },
  });
};
