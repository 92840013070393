import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Main } from './main';

export const VideoVoiceover = () => {
  return (
    <>
      <DocumentHead title="Voiceover" />
      <PageTemplate modal={[]} main={<Main />} />
    </>
  );
};
