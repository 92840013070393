import { headTags } from 'lib/const/favicon';
import { IWhiteLabel } from './getWhiteLabel';

export const createLinksInHeadTagLinks = (whitelabel: IWhiteLabel) => {
  const headID = document.getElementsByTagName('head')[0];

  const baseUrl = 'https://' + whitelabel.domainV2 + whitelabel.contentPath;
  headTags.forEach(tag => {
    const tagType = Object.keys(tag)[0];
    const link = document.createElement(tagType);
    // @ts-ignore
    const attributes = tag[tagType];
    for (const key in attributes) {
      // @ts-ignore
      key == 'href'
        ? // @ts-ignore
          (link[key] = baseUrl + attributes[key])
        : // @ts-ignore
          (link[key] = attributes[key]);
    }
    headID.appendChild(link);
  });
};
