import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';
import { useAuth } from 'lib/context';
import { NotFoundTemplate } from 'app/pages/notFound'; /* SUS-1234 changes */
import { checkIfInventoryAccessible } from 'lib/utils/automotiveRolePermissionChecks'; /* SUS-1234 changes */

export const Main = () => {
  /** SUS-1234 changes profile/templates route are accessible to CDS account with SALESPERSON and SALES_MANAGER role only **/
  const { userData } = useAuth();
  const isInventoryAccessible = checkIfInventoryAccessible(userData);

  return (
    <Switch>
      <Route path="/inventory" component={List} exact={true} />
      {/* SUS-1234 changes */}
      {isInventoryAccessible && (
        <Route path="/inventory/:itemId" component={Details} exact={true} />
      )}
      {/* SUS-1234 changes */}
      <Route path="*" component={NotFoundTemplate} />
    </Switch>
  );
};
