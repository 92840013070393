import React, { useState } from 'react';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import WidgetTabs from '../tabs';
import { WidgetWrapper } from './common/styles';
import {
  IParams,
  REPORTS_STALE_TIME,
  VariantTypes,
  WidgetLabels,
  WidgetValues,
  WIDGET_TABS,
} from './types';
import { EmptyWidget } from '../EmptyWidget';
import Header from './common/Header';
import {
  Body,
  BodyParagraph,
  ItemsWrapper,
  WidgetTitle,
} from './styles/topVariants';
import { getReports } from 'lib/api';
import { useQuery } from 'react-query';
import { widget } from './keys';
import { ReportTypes } from 'lib/const/ReportData';

interface ITopVariantsProps {
  params: IParams;
  variant: string;
  addDataForExport: (data: any) => void;
}
interface VariantProps {
  label: string;
  tabItems: { id: string; label: string }[];
  tableHeaders: string[];
  flexHeaders: string[];
}

interface VariantData {
  [key: string]: VariantProps;
}

const variantData = {
  [VariantTypes.USERS]: {
    label: 'Top Users',
    tabItems: WIDGET_TABS.VARIANTS_USERS,
    tableHeaders: ['Rank', 'User'],
    flexHeaders: ['0.1', '0.8', '0.1'],
  },
} as VariantData;

const TopUsers = ({ variant, params, addDataForExport }: ITopVariantsProps) => {
  const [selected, setSelected] = useState(variantData[variant].tabItems[0]);
  const queryParams = {
    ...params,
    start: 0,
    limit: 1000,
    reports: [
      ReportTypes.RECORDED,
      ReportTypes.VIEWS,
      ReportTypes.CTA,
      ReportTypes.ENGAGEMENT,
    ],
  };
  const {
    data: topUsers,
    isLoading,
    isRefetching,
  } = useQuery(
    widget.detail(WidgetValues.TOP_USERS, queryParams),
    () => getReports(queryParams, 'metrics/users'),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const mapDataForExport = (data: any) => {
    let exportData: any = { title: '', data: [], arr: [] };

    exportData.title = WidgetLabels.TOP_USERS;
    let dataObjects: any[] = [];
    WIDGET_TABS.VARIANTS_USERS.forEach((item: any) => {
      let tmpObj: any = { data: [] };
      tmpObj.title = item.id;

      const sortedData = data?.topUsers?.users
        ?.sort((a: any, b: any) => +a[item.id] - +b[item.id])
        .reverse();

      const dataForMap = sortedData
        ?.sort((a: any, b: any) => {
          if (+a[item.id] - +b[item.id] === 0)
            return a.firstName.localeCompare(b.firstName);
          return 0;
        })
        .slice(0, 10);

      dataForMap?.forEach((el: any) => {
        const obj: any = {};
        obj['User by ' + item.label] = el.firstName + ' ' + el.lastName;
        obj[item.label] = el[item.id];
        obj['|'] = '';
        tmpObj.data.push(obj);
      });

      dataObjects.push(tmpObj.data);
    });
    const maxLength = Math.max(...dataObjects.map(e => e.length));
    for (let i = 0; i < maxLength; i++) {
      let singleObject = {
        'User by Recorded': dataObjects[0][i]['User by Recorded'] ?? '',
        Recorded: dataObjects[0][i]['Recorded'] ?? '',
        '': '',
        'User by Engagements': dataObjects[1][i]['User by Engagements'] ?? '',
        Engagements: dataObjects[1][i]['Engagements'] ?? '',
        ' ': '',
        'User by Views': dataObjects[2][i]['User by Views'] ?? '',
        Views: dataObjects[2][i]['Views'] ?? '',
        '  ': '',
        'User by Clicks': dataObjects[3][i]['User by Clicks'] ?? '',
        Clicks: dataObjects[3][i]['Clicks'] ?? '',
      };
      exportData.data.push(singleObject);
    }

    return exportData;
  };
  React.useEffect(() => {
    addDataForExport(
      mapDataForExport({
        topUsers,
      })
    );
  }, [topUsers]);

  if (isLoading) {
    return <SkeletonLoader imageName='line-report' />;
  }

  const TopUsersTableBody = (data: any) => {
    const sortedData = data?.data
      ?.sort((a: any, b: any) => +a[selected.id] - +b[selected.id])
      .reverse();

    const dataForMap = sortedData
      ?.sort((a: any, b: any) => {
        if (+a[selected.id] - +b[selected.id] === 0)
          return a.firstName.localeCompare(b.firstName);
        return 0;
      })
      .slice(0, 10);

    return dataForMap.map((item: any, index: number) => (
      <Body key={index} lastRow={index === data?.data?.length - 1}>
        <BodyParagraph flex='0.1' bold>
          {index + 1}
        </BodyParagraph>
        <BodyParagraph flex='0.75'>
          {item.firstName + ' ' + item.lastName}
        </BodyParagraph>
        <BodyParagraph bold textAlign='right' flex='0.15'>
          {item[selected.id]}
          {selected.id === 'engagements' && '%'}
        </BodyParagraph>
      </Body>
    ));
  };

  return (
    <WidgetWrapper>
      <ItemsWrapper mb='0px'>
        <WidgetTitle>
          {variantData[variant].label}{' '}
          {isRefetching && <span>Updating ...</span>}
        </WidgetTitle>
      </ItemsWrapper>

      {variantData[variant].tabItems.length ? (
        <WidgetTabs
          items={variantData[variant].tabItems}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        <></>
      )}
      {topUsers?.users.length ? (
        <>
          <Header
            selectedLabel={selected?.label}
            variantData={variantData}
            variant={variant}
          />
          <TopUsersTableBody data={topUsers?.users} />
        </>
      ) : (
        <EmptyWidget />
      )}
    </WidgetWrapper>
  );
};

export default TopUsers;
