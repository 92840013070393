import React, { useEffect, useMemo, useState } from 'react';
import {
  TextEditor,
  FontSizeDropdown,
  ListDropdown,
} from 'lib/components/textEditorEmailBuilder';
import styled, { useTheme } from 'styled-components/macro';
import {
  ButtonCheckboxInput,
  CheckboxInput,
  Dropdown,
  Spinner,
  Switch,
} from 'lib/components';
import { theme } from 'lib/style';
import { CheckboxLabel, Label } from '../styles';
import { EmailBuilderElementType, EmailContent } from '../types';
import { VideoListItem, uploadImage } from 'lib/api';
import { useAuth } from 'lib/context';
import {
  Aligments,
  BUILDER_IDS,
  EMAIL_BUILDER_BORDER_RADIUS,
  Sizes,
  thumbnailOptionsDictionary,
} from '../const';
import {
  generateImageBoxHTML,
  generateVideoThumbnailHTML,
  getAlignmentIcon,
  getThumbnailFromOptionAndVideo,
} from '../utils';
import { useGetEmailIconsQuery } from 'lib/api/emailIcons/getEmailIcons';
import { MdDeleteForever, MdFileUpload, MdFolder } from 'react-icons/md';
import { ModalFilesChooseImage } from 'lib/components/modal';
import { errorToast } from 'lib/components/toasts/error';
import { useCreateUpdateSignature } from 'lib/api/signature/createUpdateSignature';
import { Button } from 'react-covideo-common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const CheckboxWrapper = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: center;
`;

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

type FormGroupProps = {
  margin?: string;
  width?: string;
};

const FormGroup = styled.div<FormGroupProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => width || '100%'};
`;

const UploadPreviewWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const UploadPreview = styled.img`
  width: 217px;
  height: auto;
`;

const HTMLEditor = styled.textarea`
  display: block;
  min-height: 260px;
  box-sizing: border-box;
  resize: vertical;
  width: 100%;
  background: ${theme.palette.gray100};
  color: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray40};
  border-radius: 5px;
  padding: 16px 24px;
  font-family: 'Courier New', monospace;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
`;

const emailEditorToolbar = {
  options: ['fontSize', 'inline', 'remove', 'textAlign', 'list', 'link'],
  fontSize: {
    options: [10, 12, 14, 16, 18, 24, 30, 48, 72, 96],
    component: FontSizeDropdown,
  },
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon',
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon',
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon',
    },
  },
  remove: {
    className: 'toolbar-custom-icon toolbar-remove-icon',
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right'],
    left: {
      className: 'toolbar-custom-icon toolbar-align-left-icon',
    },
    center: {
      className: 'toolbar-custom-icon toolbar-align-center-icon',
    },
    right: {
      className: 'toolbar-custom-icon toolbar-align-right-icon',
    },
  },
  list: {
    inDropdown: true,
    options: ['unordered', 'ordered'],
    component: ListDropdown,
  },
  link: {
    inDropdown: true,
    className: 'toolbar-custom-dropdown toolbar-link-icon small-icon',
    options: ['link', 'unlink'],
    link: {
      className:
        'toolbar-custom-icon add-content-link toolbar-link-icon small-icon',
    },
    unlink: {
      className: 'toolbar-custom-icon toolbar-unlink-icon small-icon',
    },
    showOpenOptionOnHover: false,
  },
};
const sizeOptions = Object.values(Sizes);
const aligmentOptions = Object.values(Aligments);

type Props = {
  emailContent: EmailContent;
  setEmailContent: React.Dispatch<React.SetStateAction<EmailContent>>;
  video: VideoListItem;
  elementBeingEditedId: string;
  changeElementBeingEdited: (value: string) => void;
};

export const EmailBuilderEditor = ({
  emailContent,
  setEmailContent,
  video,
  changeElementBeingEdited,
  elementBeingEditedId,
}: Props) => {
  const themes = useTheme();
  const { userData } = useAuth();
  const { droplrAccess } = userData;
  const from =
    userData.firstName + (userData.lastName ? ' ' + userData.lastName : '');
  const element = useMemo(() => {
    let elements = emailContent?.elements || [];
    if (emailContent.signature) {
      elements = [...elements, emailContent.signature];
    }
    return elements.find(element => element.id === elementBeingEditedId);
  }, [emailContent]);
  const { data } = useGetEmailIconsQuery({ limit: 100 });
  const customThumbnails = (data?.icons || [])
    .map(icon => ({ label: icon.title, value: icon.thumbnail }))
    .filter(Boolean);
  let thumbnailOptions = Object.values(thumbnailOptionsDictionary);
  thumbnailOptions = [...thumbnailOptions, ...customThumbnails];

  const [selectedCustomImagePreview, setSelectedCustomImagePreview] = useState(
    element?.customImageUrl || ''
  );
  const [selectedImage, setSelectedImage] = useState('');
  const [content, setContent] = useState(element?.html || '');
  const [selectedThumbnailOption, setSelectedThumbnailOption] = useState(
    element?.thumbnailOption ||
      thumbnailOptionsDictionary.ANIMATED_SNAPSHOT.value
  );
  const [selectedSizeOption, setSelectedSizeOption] = useState(
    element?.sizeOption || Sizes.THREE_QUARTER
  );
  const [selectedAlignmentOption, setSelectedAlignmentOption] = useState(
    element?.alignmentOption || Aligments.LEFT
  );
  const [isBorderRounded, setIsBorderRounded] = useState(
    !!element?.isBorderRounded
  );
  const [updateSignatureFlag, setUpdateSignatureFlag] = useState(false);
  const [showModalFilesChooseImage, setShowModalFilesChooseImage] =
    useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);

  const { mutateAsync: updateSignature, isLoading } =
    useCreateUpdateSignature();

  const handleSubmit = async () => {
    if (!element) {
      return;
    }
    if (updateSignatureFlag) {
      await updateSignature(content);
    }
    changeElementBeingEdited(BUILDER_IDS.EMPTY);
  };

  const handleLiveUpdate = async () => {
    if (!element) {
      return;
    }
    let html = content;
    let imageUrl = '';
    const isImage = [
      EmailBuilderElementType.VIDEO_THUMB,
      EmailBuilderElementType.IMAGE,
      EmailBuilderElementType.IMAGE_EMPTY,
    ].includes(element.type);
    let thumbnailOption =
      element?.type === EmailBuilderElementType.VIDEO_THUMB
        ? selectedThumbnailOption
        : undefined;
    let alignmentOption = isImage ? selectedAlignmentOption : undefined;
    let sizeOption = isImage ? selectedSizeOption : undefined;
    let borderRounded = isImage ? isBorderRounded : undefined;
    // if image selected from file
    if (selectedImage) {
      imageUrl = selectedImage;
      html = generateImageBoxHTML({
        imageUrl: selectedImage,
        width: selectedSizeOption,
        borderRadius: isBorderRounded ? EMAIL_BUILDER_BORDER_RADIUS : '0',
        align:
          selectedSizeOption === Sizes.FULL
            ? Aligments.CENTER
            : selectedAlignmentOption,
      });
    }
    // if image already selected previously
    if (!selectedImage && selectedCustomImagePreview) {
      imageUrl = selectedCustomImagePreview;
    }
    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])].map(element => {
        if (element.id !== elementBeingEditedId) {
          return element;
        }
        element.html = html;
        element.thumbnailOption = thumbnailOption;
        element.alignmentOption = alignmentOption;
        element.sizeOption = sizeOption;
        element.isBorderRounded = borderRounded;
        element.customImageUrl = imageUrl;
        // transform empty image to image if not empty anymore
        if (imageUrl && element.type === EmailBuilderElementType.IMAGE_EMPTY) {
          element.type = EmailBuilderElementType.IMAGE;
        }
        // transform image to empty image if no image anymore
        if (
          [
            EmailBuilderElementType.IMAGE_EMPTY,
            EmailBuilderElementType.IMAGE,
          ].includes(element.type) &&
          !imageUrl
        ) {
          element.type = EmailBuilderElementType.IMAGE_EMPTY;
        }
        return element;
      });
      let newSignature = prev?.signature;
      if (newSignature && elementBeingEditedId === BUILDER_IDS.SIGNATURE) {
        newSignature.html = content;
      }
      return { ...prev, elements: newElements, signature: newSignature };
    });
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    try {
      setIsImageUploading(true);
      const imageUrl = await uploadImage(file, userData.id);
      setIsImageUploading(false);
      setSelectedImage(imageUrl);
    } catch (error) {
      errorToast({
        title: 'Failed to upload image.',
      });
      setIsImageUploading(false);
    }
  };

  const handleUploadButtonClick = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.onchange = (event: Event) =>
      handleImageChange(
        event as unknown as React.ChangeEvent<HTMLInputElement>
      );
    inputElement.click();
  };

  useEffect(() => {
    handleLiveUpdate();
  }, [content, selectedImage]);

  useEffect(() => {
    setSelectedThumbnailOption(
      element?.thumbnailOption ||
        thumbnailOptionsDictionary.ANIMATED_SNAPSHOT.value
    );
    setSelectedSizeOption(element?.sizeOption || Sizes.THREE_QUARTER);
    setSelectedAlignmentOption(element?.alignmentOption || Aligments.LEFT);
    setSelectedCustomImagePreview(element?.customImageUrl || '');
    setIsBorderRounded(!!element?.isBorderRounded);
  }, [elementBeingEditedId, element]);

  useEffect(() => {
    if (!selectedImage) {
      return;
    }
    setSelectedCustomImagePreview(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    if (
      !element?.type ||
      ![
        EmailBuilderElementType.IMAGE,
        EmailBuilderElementType.VIDEO_THUMB,
      ].includes(element.type)
    ) {
      return;
    }
    if (element.type === EmailBuilderElementType.VIDEO_THUMB) {
      let thumbnail = getThumbnailFromOptionAndVideo({
        thumbnailOption: selectedThumbnailOption,
        video,
      });
      const html = generateVideoThumbnailHTML({
        thumbnail,
        from,
        width: selectedSizeOption,
        borderRadius: isBorderRounded ? EMAIL_BUILDER_BORDER_RADIUS : '0',
        align:
          selectedSizeOption === Sizes.FULL
            ? Aligments.CENTER
            : selectedAlignmentOption,
        maxWidth: element.maxWidth,
        isPortrait: element.isPortrait,
        customerId: userData?.customerId,
        videoShareLinkText: userData?.videoShareLinkText,
      });
      setContent(html);
      return;
    }
    if (
      [
        EmailBuilderElementType.IMAGE,
        EmailBuilderElementType.IMAGE_EMPTY,
      ].includes(element.type)
    ) {
      const html = generateImageBoxHTML({
        imageUrl: selectedCustomImagePreview,
        width: selectedSizeOption,
        borderRadius: isBorderRounded ? EMAIL_BUILDER_BORDER_RADIUS : '0',
        align:
          selectedSizeOption === Sizes.FULL
            ? Aligments.CENTER
            : selectedAlignmentOption,
      });
      setContent(html);
      return;
    }
  }, [
    element,
    selectedCustomImagePreview,
    selectedThumbnailOption,
    selectedAlignmentOption,
    selectedSizeOption,
    isBorderRounded,
  ]);

  if (!element) {
    return null;
  }

  return (
    <>
      <Wrapper>
        {[EmailBuilderElementType.HTML].includes(element.type) && (
          <HTMLEditor
            onChange={e => setContent(e.target.value)}
            value={content}
          />
        )}
        {[
          EmailBuilderElementType.TEXT,
          EmailBuilderElementType.VIDEO_URL,
          EmailBuilderElementType.SIGNATURE,
        ].includes(element.type) && (
          <TextEditor
            initialContent={content}
            onTextEditorChange={setContent}
            height={'288px'}
            toolbar={emailEditorToolbar}
            forceSoftNewLine={true}
            showVariablesButton={
              element.type !== EmailBuilderElementType.SIGNATURE
            }
          />
        )}
        {[
          EmailBuilderElementType.VIDEO_THUMB,
          EmailBuilderElementType.IMAGE_EMPTY,
          EmailBuilderElementType.IMAGE,
        ].includes(element.type) && (
          <>
            {element.type === EmailBuilderElementType.VIDEO_THUMB && (
              <FormGroup>
                <Label margin='0'>Video Thumbnail</Label>
                <Dropdown
                  width={352}
                  options={thumbnailOptions}
                  value={thumbnailOptions.find(o => {
                    return o.value === selectedThumbnailOption;
                  })}
                  onChange={option => setSelectedThumbnailOption(option.value)}
                  placeholder={'Video Thumbnail Options'}
                />
              </FormGroup>
            )}
            {[
              EmailBuilderElementType.IMAGE_EMPTY,
              EmailBuilderElementType.IMAGE,
            ].includes(element.type) && (
              <FormGroup>
                <Label margin='0'>Image</Label>
                {isImageUploading && (
                  <UploadPreviewWrapper>
                    {isImageUploading && (
                      <Spinner color={theme.palette.gray100} />
                    )}
                  </UploadPreviewWrapper>
                )}
                {!isImageUploading && selectedCustomImagePreview && (
                  <UploadPreviewWrapper>
                    {!isImageUploading && (
                      <UploadPreview src={selectedCustomImagePreview} />
                    )}
                    {isImageUploading && (
                      <Spinner color={theme.palette.gray100} />
                    )}
                    <Button
                      onClick={() => setSelectedCustomImagePreview('')}
                      variant='secondary'
                      icon={<MdDeleteForever />}
                      text='Remove'
                    />
                  </UploadPreviewWrapper>
                )}
                {!isImageUploading && !selectedCustomImagePreview && (
                  <ButtonsWrapper>
                    <Button
                      onClick={() => handleUploadButtonClick()}
                      variant='secondary'
                      icon={<MdFileUpload />}
                      text='Upload File...'
                    />
                    {droplrAccess && (
                      <Button
                        onClick={() => setShowModalFilesChooseImage(true)}
                        variant='secondary'
                        icon={<MdFolder />}
                        text='Select from Files'
                      />
                    )}
                  </ButtonsWrapper>
                )}
              </FormGroup>
            )}
            <FormGroup>
              <Label margin='0'>Size</Label>
              <ButtonsWrapper>
                {sizeOptions.map((option, i: number) => (
                  <ButtonCheckboxInput
                    key={i}
                    content={option}
                    onClick={() => setSelectedSizeOption(option)}
                    active={selectedSizeOption === option}
                  />
                ))}
              </ButtonsWrapper>
            </FormGroup>
            {selectedSizeOption !== Sizes.FULL && (
              <FormGroup>
                <Label margin='0'>Alignment</Label>
                <ButtonsWrapper>
                  {aligmentOptions.map((option, i: number) => (
                    <ButtonCheckboxInput
                      key={i}
                      content={getAlignmentIcon(option)}
                      onClick={() => setSelectedAlignmentOption(option)}
                      active={selectedAlignmentOption === option}
                      padding='10px'
                    />
                  ))}
                </ButtonsWrapper>
              </FormGroup>
            )}
            <FormGroup>
              <Label margin='0'>Theme options</Label>
              <SwitchWrapper>
                <span>Rounded corners</span>
                <Switch
                  id={'border-radius'}
                  isOn={isBorderRounded}
                  onColor={themes.colors.primary[100]}
                  handleToggle={() => setIsBorderRounded(prev => !prev)}
                />
              </SwitchWrapper>
            </FormGroup>
          </>
        )}
        {element.type === EmailBuilderElementType.SIGNATURE && (
          <CheckboxWrapper>
            <CheckboxInput
              id={`update-sig`}
              width='24px'
              blueCheck={true}
              checked={updateSignatureFlag}
              onChange={(e: React.SyntheticEvent) => {
                const { checked } = e.target as HTMLInputElement;
                setUpdateSignatureFlag(checked);
              }}
            />
            <CheckboxLabel htmlFor={`update-sig`}>
              Update signature in my profile settings
            </CheckboxLabel>
          </CheckboxWrapper>
        )}
        <ButtonsWrapper>
          <Button
            text='Save & Close'
            variant='primary'
            onClick={handleSubmit}
            disabled={updateSignatureFlag && isLoading}
          />
        </ButtonsWrapper>
      </Wrapper>
      {showModalFilesChooseImage && (
        <ModalFilesChooseImage
          handleModalClose={() => setShowModalFilesChooseImage(false)}
          onSelect={(selectedImageUrl: string) =>
            setSelectedImage(selectedImageUrl)
          }
        />
      )}
    </>
  );
};
