import { getVideosCount } from 'lib/api';
import { useAuth } from 'lib/context';
import { isVideoCountValid } from 'lib/utils/productFeature';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { errorToast } from '../toasts/error';
import { LoadingIndicator } from '../LoadingIndicator';
import DragAndDrop from '../inputs/DragAndDrop';
import { MultipleUploadList } from '../lists/VideoMultipleUploadList';
import ProductInfoTooltip from '../ProductInfoTooltip/ProductInfoTooltip';
import {
  CancelContainer,
  Content,
  ContentBody,
  ContentFooter,
  ContentHeader,
  DisabledInputContainer,
  Input,
  InputLabel,
  LockIconContainer,
  Overlay,
} from './styles';
import { FiPlus } from 'react-icons/fi';
import {
  CreateVideoResponse,
  useCreateVideoMutation,
} from 'lib/api/videos/useCreateVideoMutation';
import { useEncodeVideoMutation } from 'lib/api/videos/useEncodeVideoMutation';
import { Button } from 'react-covideo-common';
import { useAwsSignedUrlMutation } from 'lib/api/aws/useAwsSignedUrlMutation';
import { useS3UploadMutation } from 'lib/api/aws/useS3UploadMutation';
import { MdLock } from 'react-icons/md';

export enum View {
  FORM = 0,
  UPLOADING = 1,
  CREATE = 2,
  ENCODE = 3,
}

const parseOptions = {
  accept: 'video/*,.mkv',
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
};

export const DEFAULT_UPLOAD_FOLDER = {
  value: -2,
  label: 'Primary',
  isFolderShared: false,
  access: 'edit',
};

interface IUploadingData {
  [key: string]: any;
}

type ContentStyle = {
  main?: React.CSSProperties;
  body?: React.CSSProperties;
  cancelBody?: React.CSSProperties;
  progress?: React.CSSProperties;
  footer?: React.CSSProperties;
};

type VideoUploadProps = {
  handleModalClose: () => void;
  progress: number;
  setProgress: (arg: number) => void;
  canceling: boolean;
  setCanceling: (arg: boolean) => void;
  onSuccess?: (videoData: CreateVideoResponse | undefined) => Promise<void>;

  isGuide?: boolean;
  isMultiple?: boolean;
  setShowConfirmModal?: (arg: boolean) => void;
  setChosenVideosCount?: (arg: number) => void;
  proceedUpload?: boolean;

  hideHeader?: boolean;
  contentStyle?: ContentStyle;
  showProgressHeaderInline?: boolean;
  noButtonPadding?: boolean;
  dndWidth?: number;
  dndHeight?: number;
  placeholder?: string;
  uploadButtonText?: string;
  guideCategoryId?: number;
  disableCancel?: boolean;
};

const VideoUpload = ({
  handleModalClose,
  setProgress,
  progress,
  canceling,
  setCanceling,
  hideHeader,
  contentStyle,
  dndWidth,
  dndHeight,
  placeholder = 'Browse files...',
  uploadButtonText = 'Upload',
  noButtonPadding = false,
  showProgressHeaderInline = false,
  isGuide = false,
  setShowConfirmModal,
  proceedUpload,
  isMultiple = true,
  onSuccess,
  guideCategoryId,
  setChosenVideosCount,
  disableCancel,
}: VideoUploadProps) => {
  const history = useHistory();

  const [uploadingData, setUploadingData] = useState<IUploadingData>({});
  const [currentView, setCurrentView] = useState(View.FORM);
  const [uploadedVideoArray, setUploadedVideoArray] = useState([]);
  const { userData } = useAuth();
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [createVideoData, setCreateVideoData] = useState<
    CreateVideoResponse | undefined
  >(undefined);

  const hasConfirmation = !!setShowConfirmModal;

  const [isAddMoreEnabled, setIsAddMoreEnabled] = useState(true);
  const [existingVideoCount, setExistingVideoCount] = useState(0);
  const [disableUpload, setDisableUpload] = useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const {
    data: presignedUrl,
    mutateAsync: getPresignedUrl,
    isLoading: isLoadingUrl,
    error: urlError,
  } = useAwsSignedUrlMutation();

  const {
    mutateAsync: uploadVideo,
    progress: uploadProgress,
    cancelS3Upload,
  } = useS3UploadMutation();

  const {
    mutateAsync: createVideo,
    isLoading: isCreating,
    data: videoData,
  } = useCreateVideoMutation();

  const {
    mutateAsync: encodeVideo,
    isLoading: isEncoding,
    data: encodeData,
  } = useEncodeVideoMutation();

  const handleCancelUpload = () => {
    cancelS3Upload();

    setUploadingData({});
    handleModalClose();
  };

  useEffect(() => {
    getVideosCount()
      .then(data => {
        const { count: existingCount } = data;
        setExistingVideoCount(existingCount);
      })
      .catch(ex => {
        console.log(ex);
      });
  }, []);

  useEffect(() => {
    setUploadedFileCount(Object.keys(uploadingData).length);
  }, [uploadingData]);

  useEffect(() => {
    if (proceedUpload && hasConfirmation) handleSubmit(); // resume upload after confirmation, was && isReplace
  }, [proceedUpload]);

  useEffect(() => {
    if (setChosenVideosCount) setChosenVideosCount(uploadedFileCount);
    const newTotalCount = existingVideoCount + uploadedFileCount;
    setIsAddMoreEnabled(isVideoCountValid(userData, newTotalCount, true));
  }, [uploadedFileCount, existingVideoCount]);

  useEffect(() => {
    if (!isLoadingUrl && !urlError && presignedUrl) {
      const currentUpload = Object.keys(uploadingData).find(
        id => uploadingData[id].status === View.FORM
      );
      if (currentUpload !== undefined) {
        const id = parseInt(currentUpload, 10);
        uploadingData[id].status = View.UPLOADING;
        setUploadingData({ ...uploadingData });

        uploadVideo({
          url: presignedUrl.url,
          file: uploadingData[id].file,
          id,
        })
          .then(() => {
            uploadingData[id].status = View.CREATE;
            setUploadingData({ ...uploadingData });

            const data = uploadingData[id];
            setProgress(View.CREATE);

            createVideo({
              filename: data.file.name,
              title: data.title,
              id: data.id,
              isGuide,
            }).then(data => {
              setCreateVideoData({ ...data });
            });
          })
          .catch(error => {
            console.error('Upload failed:', error);
          });

        setCurrentView(View.UPLOADING);
        setProgress(View.UPLOADING);
      }
    }
  }, [presignedUrl, isLoadingUrl, urlError]);

  useEffect(() => {
    if (uploadProgress) {
      for (const uploadProgressId of Object.keys(uploadProgress)) {
        const currentUpload = Object.keys(uploadingData).find(
          id => id === uploadProgressId
        );
        if (currentUpload !== undefined) {
          const id = parseInt(currentUpload, 10);
          const uploadData = { ...uploadingData };
          uploadData[id].progressEvent = uploadProgress[uploadProgressId];
          setUploadingData(uploadData);
        }
      }
    }
  }, [uploadProgress]);

  useEffect(() => {
    if (
      currentView !== View.FORM &&
      (!uploadingData || !Object.keys(uploadingData).length)
    ) {
      setCurrentView(View.FORM);
    }

    // Added code for video title validation before video uploading SUS-512
    const fileTitles: any = [];
    const fileTitlesLength: any = []; // SUS-797-changes
    Object.keys(uploadingData).forEach(id => {
      fileTitles.push(
        !!(uploadingData[id].title && uploadingData[id].title.trimStart())
      );
      fileTitlesLength.push(
        uploadingData[id].title.length > 100 ? true : false
      ); // SUS-797-changes
      fileTitles.push(!uploadingData[id].title.includes('.'));
    });
    if (fileTitles.includes(false)) {
      setDisableUpload(true);
    } else if (fileTitlesLength.includes(true)) {
      // SUS-797-changes
      errorToast({
        title: 'Video Title can not be more than 100 characters!',
      });
      setDisableUpload(true);
    } else {
      setDisableUpload(false);
    }
  }, [uploadingData]);

  useEffect(() => {
    if (createVideoData && Object.keys(uploadingData).length) {
      setProgress(View.ENCODE);
      const currentUpload = Object.keys(uploadingData).find(
        id => uploadingData[id].status === View.CREATE
      );
      if (currentUpload !== undefined) {
        const id = parseInt(currentUpload, 10);
        uploadingData[id].status = View.ENCODE;
        setUploadingData({ ...uploadingData });
        setUploadedVideoArray((prevValue: any) => {
          prevValue.push(createVideoData.videoId);
          return prevValue;
        });

        encodeVideo({
          videoId: createVideoData.videoId,
          customerId: userData.customerId,
        });
      }
    }
  }, [createVideoData]);

  useEffect(() => {
    if (
      !isEncoding &&
      encodeData &&
      uploadedVideoArray.length === Object.keys(uploadingData).length &&
      !Object.values(uploadingData).some(f => f.status < 3)
    ) {
      onSuccess ? onSuccess(videoData) : navigate();
    }
  }, [isCreating, encodeData, uploadedVideoArray]);

  const handleClose = () => {
    if (progress === View.FORM) {
      return handleModalClose();
    }
    setCanceling(true);
  };

  const handleSubmit = async () => {
    if (!proceedUpload && setShowConfirmModal) {
      setShowConfirmModal(true);
      return;
    }
    for (const id in uploadingData) {
      const file = uploadingData[id].file;
      await getPresignedUrl({
        fileName: file.name,
        type: file.type,
        id: parseInt(id, 10),
      });
    }
  };

  const appendFilesToQueue = async (files: any) => {
    const filesCount = files.length;
    const maxVideoCount = userData?.user?.packageDetails?.maxVideosCreated;

    const newTotalVideoCount =
      existingVideoCount + uploadedFileCount + filesCount;
    const isCountValid = isVideoCountValid(userData, newTotalVideoCount);

    if (!isCountValid) {
      errorToast({
        title: `You have crossed your maximum video created limit, only ${
          maxVideoCount - existingVideoCount > 0
            ? maxVideoCount - existingVideoCount
            : 0
        } files can be uploaded`,
      });

      return;
    }

    for (let i = 0; i < files.length; i++) {
      const isValidType =
        /.*\.(avchd|swf|flv|mov|qt|wmv|mkv|m4v|m4p|mpv|mp2|mpe|mpg|ogg|mp4|avi|webm|mpeg)/i.test(
          files[i].name
        );

      if (!isValidType) {
        errorToast({
          title: `${files[i].name} is of invalid type and won't be uploaded.`,
        });
        continue;
      }

      const filename = files[i].name.split('.');
      const file_type =
        filename[1] === 'mkv' ? 'video/x-matroska' : files[i].type;

      const file = new File(
        [files[i]],
        generateName(filename[filename.length - 1]),
        {
          type: file_type,
          lastModified: files[i].lastModified,
        }
      );

      setUploadingData((ud: IUploadingData) => {
        const u = { ...ud };
        let id = 0;
        if (Object.keys(ud).length) {
          const keys = Object.keys(ud);
          id = parseInt(keys[keys.length - 1], 10) + 1;
        }
        u[id] = {
          id,
          file: file,
          title: filename[0],
          progressEvent: {
            loaded: 0,
            total: 0,
            percentage: 0,
          },
          status: 0,
          showActiveVideo: 1,
        };

        return u;
      });
    }
  };

  const handleSelectedFile = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.dataTransfer || e.target;
    if (target && target.files && target.files.length) {
      await appendFilesToQueue(target.files);
      if (!!fileInputRef && !!fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } else {
      errorToast({
        title: 'Oops! Something went wrong, try again in a few moments',
      });
    }
  };

  const generateName = (fileExt: string) => {
    return (
      userData.userId +
      '_' +
      userData.customerId +
      '_' +
      (Math.random() + 1).toString(36).substring(2) +
      '.' +
      fileExt
    );
  };

  const getFormInput = () => {
    if (!isMultiple) return <></>;

    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <Input
          ref={fileInputRef}
          type='file'
          id='file-upload1'
          accept={parseOptions.accept}
          multiple
          style={{ opacity: '0' }}
          onChange={handleSelectedFile}
          disabled={!isAddMoreEnabled}
        />
        {isAddMoreEnabled ? (
          <InputLabel
            noButtonPadding={noButtonPadding}
            isAddMoreEnabled={isAddMoreEnabled}
            htmlFor='file-upload1'
          >
            <FiPlus style={{ marginRight: '5px' }} />
            Add more files
          </InputLabel>
        ) : (
          <ProductInfoTooltip
            style={{
              top: isAddMoreEnabled ? '-150px' : '-170px',
              left: noButtonPadding ? '130px' : '150px',
            }}
          >
            <DisabledInputContainer isAddMoreEnabled={isAddMoreEnabled}>
              <LockIconContainer noButtonPadding={noButtonPadding}>
                <MdLock size={18} color={'#001B53'} />
              </LockIconContainer>
              <InputLabel
                noButtonPadding={noButtonPadding}
                isAddMoreEnabled={isAddMoreEnabled}
                htmlFor='file-upload1'
                disabled
              >
                Add more files
              </InputLabel>
            </DisabledInputContainer>
          </ProductInfoTooltip>
        )}
      </div>
    );
  };

  const navigate = () => {
    if (isGuide) {
      history.push({
        pathname: '/review-guides',
        state: {
          videos: uploadedVideoArray,
          category: guideCategoryId || 0,
        },
      });
      return;
    }
    history.push({
      pathname: '/review-videos',
      state: {
        videos: uploadedVideoArray,
      },
    });
  };

  const uploadFormView = (
    <Content style={contentStyle?.main}>
      {progress === View.FORM && !hideHeader && (
        <ContentHeader>Upload new videos</ContentHeader>
      )}
      <ContentBody
        showBackground={!!Object.keys(uploadingData).length}
        style={contentStyle?.body}
      >
        {isLoadingUrl && (
          <Overlay>
            <LoadingIndicator height={'100%'} isLoading={isLoadingUrl} />
          </Overlay>
        )}

        {!Object.keys(uploadingData).length ? (
          <DragAndDrop
            accept={parseOptions.accept}
            marginTop={0}
            width={dndWidth || 532}
            height={dndHeight || 338}
            showIcon={false}
            button={true}
            text='Drag and drop video files here to upload'
            onDrop={handleSelectedFile}
            parserOptions={parseOptions}
            placeholder={placeholder}
            isMultiple={isMultiple}
          />
        ) : (
          <MultipleUploadList
            uploadingData={uploadingData}
            setUploadingData={setUploadingData}
            progressState={progress}
          />
        )}
      </ContentBody>
      {Object.keys(uploadingData).length ? (
        <ContentFooter
          fileLength={Object.keys(uploadingData).length}
          style={contentStyle?.footer}
        >
          <div style={{ display: 'flex' }}>
            {getFormInput()}
            <div
              style={{
                display: 'flex',
                padding: `${isAddMoreEnabled ? '0px' : '10px'} ${
                  noButtonPadding ? 0 : '24px'
                } 0 0`,
              }}
            >
              <Button
                text={'Cancel'}
                variant='secondary'
                onClick={handleClose}
                disabled={disableCancel}
                data-cy='upload-cancel-button'
                style={{
                  margin: '0 12px 0 0',
                }}
              />

              <Button
                text={uploadButtonText}
                onClick={handleSubmit}
                disabled={disableUpload}
                data-cy='upload-button'
              />
            </div>
          </div>
        </ContentFooter>
      ) : (
        <ContentFooter style={contentStyle?.footer} />
      )}
    </Content>
  );

  const uploadingContent = (
    <>
      <ContentBody
        showBackground={true}
        showProgressHeaderInline={showProgressHeaderInline}
        style={{ overflowX: 'auto', ...contentStyle?.body }}
      >
        <MultipleUploadList
          uploadingData={uploadingData}
          setUploadingData={setUploadingData}
          progressState={progress}
        />
      </ContentBody>
      <ContentFooter
        fileLength={Object.keys(uploadingData).length}
        style={contentStyle?.footer}
      >
        <div
          style={{
            display: 'inline-flex',
            marginRight: noButtonPadding ? 0 : '30px',
          }}
        >
          <Button
            text={'Cancel'}
            variant='secondary'
            onClick={handleClose}
            style={{
              margin: '0 12px 0 0',
            }}
          />

          <Button disabled={true} text={'Upload'} onClick={handleSubmit} />
        </div>
      </ContentFooter>
    </>
  );

  const cancelingContent = (
    <>
      <ContentBody showBackground={true} style={contentStyle?.cancelBody}>
        <CancelContainer>
          You will have to restart your video upload from the beginning if you
          cancel now. Are you sure you want to cancel this upload?
        </CancelContainer>
      </ContentBody>
      <ContentFooter
        fileLength={Object.keys(uploadingData).length}
        style={contentStyle?.footer}
      >
        <Button
          text={'Cancel Upload'}
          variant='secondary'
          onClick={handleCancelUpload}
          style={{
            margin: '0 12px 0 0',
          }}
        />

        <Button
          style={{
            margin: noButtonPadding ? 0 : '0 30px 0 0',
          }}
          text={'Continue Upload'}
          onClick={() => setCanceling(false)}
        />
      </ContentFooter>
    </>
  );

  const uploadProgressView = (
    <Content style={contentStyle?.progress}>
      {canceling ? cancelingContent : uploadingContent}
    </Content>
  );

  const views = [uploadFormView, uploadProgressView];
  return views[currentView];
};

export default VideoUpload;
