import * as React from 'react';

import { BaseTeleprompterActions } from './BaseTeleprompterActions';
import { RECORDING_STATUS } from '../types';
import { RecordingTeleprompterActions } from './RecordingTeleprompterActions';

interface TeleprompterProps {
  status: RECORDING_STATUS;
}

export const TeleprompterActions = ({ status }: TeleprompterProps) => {
  const isRecording =
    status === RECORDING_STATUS.RECORDING || status === RECORDING_STATUS.PAUSED;
  return (
    <>
      {!isRecording ? (
        <BaseTeleprompterActions status={status} />
      ) : (
        <RecordingTeleprompterActions />
      )}
    </>
  );
};
