import React, { useEffect, useState } from 'react';
import { useCustomersQuery } from 'lib/api/superadmin/customersQuery';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { useVisibilityContextProvider } from '../provider/context';
import { VisibilityMessage } from '../VisibilityMessage';
import { SelectableBodySearch } from './components/SelectableBodySearch';
import { SelectableBodyWrapper } from './components/SelectableBodyWrapper';
import { SelectableMainWrapper } from './components/SelectableMainWrapper';
import { SelectableListItem } from './components/SeletableListItem';
import { VISIBILITY_TYPE } from './SelectableArea';
import { SelectableLoadingWrapper } from './components/SelectableLoaderWrapper';
import { LoadingIndicator } from 'lib/components';
import { EmptyMessageWrapper } from './components/EmptyMessageWrapper';
import { Button } from 'react-covideo-common';
import { MdRefresh } from 'react-icons/md';
import { sortItemsByChecked } from '../provider/const';
import { ResellerCustomer } from '../provider/types';
import { SelectableCustomersHeader } from './components/SelectableCustomersHeader';

interface IProps {
  item: VISIBILITY_TYPE;
  onItemClick: (id: number, item: VISIBILITY_TYPE) => void;
  selectedResellerId: number | undefined;
  selectedCustomerId: number | undefined;
  openHowToShareModal: () => void;
  resetSelectedCustomers: () => void;
}

export const SelectableCustomers = ({
  item,
  onItemClick,
  selectedResellerId,
  selectedCustomerId,
  openHowToShareModal,
  resetSelectedCustomers,
}: IProps) => {
  const SIZE = 1000;
  const {
    setCustomers,
    state,
    updateResellerChecked,
    incrementCustomerPageCount,
    deselectAllCustomers,
    selectAllCustomers,
    updateCustomerChecked,
  } = useVisibilityContextProvider();
  const [search, setSearch] = useState('');
  const [isInitiated, setIsInitiated] = useState(false);
  const currentPage =
    (selectedResellerId && state?.customersCurrentPage?.[selectedResellerId]) ||
    0;
  const { data, refetch, isLoading, isRefetching } = useCustomersQuery({
    enabled: !!selectedResellerId,
    params: {
      status: VerificationStatus.ALL,
      search,
      start: currentPage * SIZE,
      limit: SIZE,
      resellerId: selectedResellerId,
    },
  });

  useEffect(() => {
    if (data && selectedResellerId) {
      setCustomers(data?.customers, selectedResellerId, isInitiated);
      setIsInitiated(true);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [search, currentPage]);

  const onSearchHandler = (value: string) => {
    setSearch(value);
  };

  const onCheckboxClickHandler = (id: number, checked: boolean) => {
    if (selectedResellerId) {
      updateCustomerChecked(selectedResellerId, id, checked);
    }
  };

  const onSwitchButtonChangeHandler = (value: string) => {
    if (selectedResellerId) {
      updateResellerChecked(selectedResellerId, value === 'all' ? true : false);
    }
    resetSelectedCustomers();
  };

  if (!selectedResellerId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <SelectableLoadingWrapper>
        <LoadingIndicator isLoading={true} />
      </SelectableLoadingWrapper>
    );
  }

  const customers = state?.resellers?.[selectedResellerId]?.customers || {};
  const customersLength = state?.customerCount?.[selectedResellerId]?.length;

  const { initialCheckedCustomers } = state;

  const sortedCustomers = sortItemsByChecked(
    customers,
    initialCheckedCustomers,
    'customerId'
  );

  const transfomedCustomers = !!Object.keys(customers).length
    ? sortedCustomers.map((customer: ResellerCustomer) => {
        return {
          displayText: customer?.business,
          checked: customer?.checked,
          id: customer?.customerId,
          isPartiallySelected: customer?.isPartiallySelected,
        };
      })
    : [];

  const isAllChecked = state?.resellers?.[selectedResellerId]?.checked;

  const checkGroupIndicator =
    customersLength > 0 && Object.keys(customers).length !== customersLength;

  const checked = Object.keys(customers).length === customersLength;

  return (
    <SelectableMainWrapper>
      {!Object.keys(customers).length ? (
        <EmptyMessageWrapper />
      ) : (
        <>
          <SelectableCustomersHeader
            isRefetching={isRefetching}
            onSearch={onSearchHandler}
            defaultValue={!!isAllChecked ? 'all' : 'manually'}
            isAllChecked={isAllChecked}
            onSwitchButtonChange={onSwitchButtonChangeHandler}
          />
          {isAllChecked ? (
            <VisibilityMessage
              openHowToShareModal={openHowToShareModal}
              message='This guide will be shared with all current and new customers.'
            />
          ) : (
            <>
              <SelectableBodyWrapper>
                <SelectableBodySearch
                  onSelectAll={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                    const { checked } = event.target as HTMLInputElement;
                    if (checked) {
                      return selectAllCustomers(selectedResellerId);
                    }
                    return deselectAllCustomers(selectedResellerId);
                  }}
                  message={`${
                    state?.customerCount?.[selectedResellerId]?.length || 0
                  } customers`}
                  checkGroupIndicator={checkGroupIndicator}
                  checked={checked}
                />
                <SelectableListItem
                  onCheckboxClick={onCheckboxClickHandler}
                  data={
                    !!search
                      ? transfomedCustomers.filter(customer =>
                          customer.displayText
                            .toLocaleLowerCase()
                            .includes(search.toLocaleLowerCase())
                        )
                      : transfomedCustomers
                  }
                  onItemClick={onItemClick}
                  type={item}
                  selectedId={selectedCustomerId}
                  selectedResellerId={selectedResellerId}
                />
                {data &&
                Number(data.count) <= Object.keys(customers).length ? null : (
                  <Button
                    onClick={() => {
                      incrementCustomerPageCount(selectedResellerId);
                    }}
                    text={isRefetching ? 'Loading...' : 'Load more'}
                    icon={<MdRefresh size={16} />}
                    variant='secondary'
                    disabled={isRefetching}
                  />
                )}
              </SelectableBodyWrapper>
            </>
          )}
        </>
      )}
    </SelectableMainWrapper>
  );
};
