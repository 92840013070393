import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CountDown } from 'lib/components/CountDown';
import { MIME_TYPE, useMediaRecorder } from 'lib/hooks/useMediaRecorder';
import { useAuth } from 'lib/context';
import { toMMSS } from 'lib/utils/functions';
import * as workerTimers from 'worker-timers';
import { Button } from 'react-covideo-common';

type RecordCamFreemiumProps = {
  isError: boolean;
  isRecording: boolean;
  setIsRecording: Function;
  onRecordingUrlGeneration: Function;
};
const Timer = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  min-width: 70px;
`;
type WrapperProps = {
  isTrial?: boolean;
};
const RecordBtnWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  width: 770px;
  z-index: 9;
  top: 424px;
  margin-top: 12px;
  gap: 10px;
`;
const FinishWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
`;
const MainDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;
const CountdownWrapper = styled.div<{ display: boolean }>`
  position: absolute;
  top: 0;
  width: 610px;
  height: 340px;
  margin: 0 auto;
  display: ${({ display }) => (display ? 'flex' : 'none')};
  justify-content: center;
`;
const VideoWrapper = styled.video`
  width: 594px;
  height: 340px;
  top: 240px;
  border-radius: 24px;
`;
const ErrorDiv = styled.div`
  position: relative;
  top: 0;
  width: 600px;
  height: 340px;
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  background: ${theme.palette.covideoGray20};
  border-radius: 24px;
`;
const AllowText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.gray80};
  margin: auto;
`;

const QUALITY_MAP: { [key: string]: any } = {
  low: { width: { ideal: 640 }, height: { ideal: 360 } },
  standard: { width: { ideal: 1280 }, height: { ideal: 720 } },
  high: { width: { ideal: 1920 }, height: { ideal: 1080 } },
};

const DEFAULT_SETTINGS = {
  videoSource: { value: '' },
  videoQuality: 'standard',
  audioSource: { value: 'default' },
};

let countdownBeforeStart: any = null;

export const RecordCamFreemium = ({
  onRecordingUrlGeneration,
  isError,
  isRecording,
  setIsRecording,
}: RecordCamFreemiumProps) => {
  const canvasRef = React.useRef<any>(null);
  const previewRef = useRef<any>(null);
  const backgroundRef = useRef<any>(null);
  const [countdown, setCountdown] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const videoWidth = 594;
  const videoHeight = 340;

  const settings = {
    videoSource: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).videoSource as any,
    videoQuality: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).videoQuality,
    audioSource: JSON.parse(
      localStorage.getItem('record_settings') ||
        JSON.stringify(DEFAULT_SETTINGS)
    ).audioSource as any,
  };
  const [showCanvas, setShowCanvas] = React.useState(true);
  const { userData } = useAuth();
  const [isCallbackRegistered, setIsCallbackRegistered] = React.useState(false);

  let audio: any;
  try {
    audio = { deviceId: settings.audioSource.value };
  } catch (e) {}
  if (!settings || !settings.audioSource || !settings.audioSource.value) {
    audio = false;
  }

  const quality =
    settings && settings.videoQuality && settings.videoQuality.value
      ? QUALITY_MAP[settings.videoQuality]
      : QUALITY_MAP.standard;
  const {
    status,
    startRecording,
    startRecordingForCanvas,
    stopRecording,
    mediaBlobUrl,
    previewStream,
  } = useMediaRecorder({
    video: {
      ...quality,
      frameRate: 24,
      deviceId: settings.videoSource && settings.videoSource.value,
    },
    audio,
    quality: settings.videoQuality,
    blobPropertyBag: { type: MIME_TYPE },
    mimeType: MIME_TYPE,
  });

  React.useEffect(() => {
    localStorage.setItem('record_error', '');
    if (!settings.videoSource || !settings.videoSource.value) {
      localStorage.setItem(
        'record_error',
        'No camera detected. Please check your recording settings and make sure the camera is plugged in.'
      );
    }
    return () => {
      closeAndClear();
    };
  }, []);

  useEffect(() => {
    if (isRecording) {
      return;
    }
    if (previewRef && previewRef.current && previewStream) {
      previewRef.current.srcObject = previewStream;
    }
  }, [previewStream, previewRef]);

  useEffect(() => {
    if (isRecording) {
      return;
    }
    if (mediaBlobUrl && !previewStream) {
      setShowCanvas(true);
    } else {
      setShowCanvas(false);
      backgroundRef.current = null;
    }
  }, [previewStream, mediaBlobUrl]);

  useEffect(() => {
    let myInterval = workerTimers.setInterval(() => {
      if (isRecording && !countdown) {
        setSeconds(seconds + 1);
      }
    }, 1000);
    return () => {
      workerTimers.clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (
      userData &&
      userData.user?.packageDetails &&
      userData.user?.packageDetails?.maxLength &&
      seconds >= userData.user?.packageDetails?.maxLength
    ) {
      handleStopRecording();
    }
  }, [seconds, userData]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleStopRecording = async () => {
    setIsRecording(false);
    await stopRecording();
    try {
      //@ts-ignore
      const streams = [...window.streams];
      //@ts-ignore
      streams.filter(Boolean).forEach(s => stopStream(s));
      //@ts-ignore
      window.streams = [];
    } catch (e) {}
  };

  React.useEffect(() => {
    if (countdown > 0 && isRecording) {
      countdownBeforeStart = setTimeout(
        () => setCountdown(countdown - 1),
        1000
      );
    } else if (isRecording) {
      if (showCanvas) {
        try {
          startRecordingForCanvas(canvasRef.current.captureStream(30));
          if (window.Intercom) {
            window.Intercom('trackEvent', 'virtual-background-used');
          }
        } catch (ex) {
          console.log('EXCEPTION :: ', ex);
          startRecording();
        }
      } else {
        startRecording();
      }
    }
  }, [isRecording, countdown]);

  React.useEffect(() => {
    if (!isCallbackRegistered && previewRef.current && previewStream) {
      previewRef.current.srcObject = previewStream;
      inits();
    }
  }, [isCallbackRegistered, previewRef, previewStream]);

  React.useEffect(() => {
    if (mediaBlobUrl) {
      onRecordingUrlGeneration(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  const inits = async () => {
    setIsCallbackRegistered(true);
    const videoTrack = previewStream?.getVideoTracks()[0];
    if (
      !videoTrack ||
      !videoTrack.getSettings().width ||
      videoTrack.readyState !== 'live'
    ) {
      inits();
      return;
    }

    setTimeout(async () => {
      previewRef.current.requestVideoFrameCallback(processFrame);
    }, 250);
  };

  React.useEffect(() => {
    return () => {
      closeAndClear();
    };
  }, []);
  // TODO: handle this
  const closeAndClear = () => {
    //@ts-ignore
    const streams = [...window.streams];
    //@ts-ignore
    streams.filter(Boolean).forEach(s => stopStream(s));
    //@ts-ignore
    window.streams = [];
    setIsRecording(false);
    if (countdownBeforeStart) {
      clearTimeout(countdownBeforeStart);
    }
  };

  const stopStream = (s: any) => {
    try {
      s.getTracks().forEach((t: any) => t.stop());
    } catch (e) {}
  };

  const processFrame = async () => {
    if (previewRef && previewRef.current) {
      previewRef.current.requestVideoFrameCallback(processFrame);
      return true;
    }
  };

  const body = React.useMemo(
    () => (
      <>
        {!mediaBlobUrl && previewStream && !isError && (
          <>
            <VideoWrapper
              hidden={showCanvas}
              ref={previewRef}
              width={videoWidth}
              height={videoHeight}
              autoPlay
            />
            <canvas
              ref={canvasRef}
              hidden={!showCanvas}
              width={videoWidth}
              height={videoHeight}
            />
          </>
        )}
        {isError && (
          <ErrorDiv>
            <AllowText>
              Allow access to your camera and microphone to get started!
            </AllowText>
          </ErrorDiv>
        )}
        {!isError && (
          <RecordBtnWrapper>
            {status === 'recording' && (
              <FinishWrapper>
                <Button text={'Finish'} onClick={handleStopRecording} />
                <Timer>{toMMSS(seconds, 0)}</Timer>
              </FinishWrapper>
            )}
            {!isRecording && status === 'idle' && (
              <Button
                variant='red'
                text={'Start recording'}
                onClick={handleStartRecording}
                disabled={isError}
              />
            )}
            {status === 'stopped' && <></>}
            {status === 'stopped' && <></>}
          </RecordBtnWrapper>
        )}
      </>
    ),
    [status, seconds, isRecording, mediaBlobUrl, settings]
  );

  return (
    <>
      <MainDiv>
        <CountdownWrapper display={countdown > 0 && isRecording}>
          <CountDown
            height={`${videoHeight}px`}
            width={`${videoWidth}px`}
            counter={countdown}
            willStart={countdown > 0 && isRecording}
            onClick={() => {}}
            hide={!countdown}
            isFreemium={true}
          />
        </CountdownWrapper>
        {body}
      </MainDiv>
    </>
  );
};
