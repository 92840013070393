import React, { ReactNode, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { TextInput } from 'lib/components/inputs';
import { FaDollarSign } from 'react-icons/fa';
import { Button } from 'react-covideo-common';
import { CarServiceItem } from 'lib/api/carService/types';
import { MAX_QUOTE_PRICE_DIGITS } from 'app/pages/repairOrders/components/QuoteManager';

const InputWrapper = styled.div`
  position: relative;
`;

const PriceIcon = styled(FaDollarSign)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
`;

const Input = styled(TextInput)`
  box-shadow: none;
  font-size: 15px;

  &:focus {
    box-shadow: none;
  }
`;

const PriceInput = styled(Input)`
  text-align: right;
`;

const Label = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${theme.palette.gray60};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Asterix = styled.span`
  &:before {
    content: '*';
    color: ${props => props.theme.colors.primary[100]};
  }
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 48px;
`;

/**** SUS-796 changes ***/
const ErrorInfo = styled.div`
  font-size: 14px;
  ${theme.mediaQueryMaxWidth.md} {
    display: none;
  }
  span {
    color: rgb(232, 76, 61);
  }
`;

type Props = {
  serviceData: CarServiceItem;
  handleSubmit: (data: CarServiceItem) => void;
  buttonText?: string;
  buttonIcon?: ReactNode;
};

export const ServiceForm = ({
  serviceData,
  handleSubmit,
  buttonText = '',
  buttonIcon,
}: Props) => {
  const [title, setTitle] = useState(serviceData.title);
  const [price, setPrice] = useState(serviceData.price);
  const text = buttonText
    ? buttonText
    : serviceData.serviceId
      ? 'Update'
      : 'Create';
  return (
    <>
      <Row>
        <div>
          <Label>
            Title
            <Asterix />
          </Label>
          <Input
            data-cy='service-title'
            autoFocus={true}
            style={{ width: 352 }}
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTitle(e.target.value)
            }
          />
          {/* SUS-796 changes */}
          {title && title.length > 100 && (
            <ErrorInfo>
              <span>
                Service title can not contain more than 100 characters.
              </span>
            </ErrorInfo>
          )}
        </div>
        <div>
          <Label>
            Price
            <Asterix />
          </Label>
          <InputWrapper>
            <PriceIcon color={theme.palette.gray60} size={18} />
            <PriceInput
              data-cy='service-price'
              type={'number'}
              placeholder={'0.00'}
              style={{ width: 160 }}
              value={price}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (
                  e.target.value.replace('.', '').length >
                  MAX_QUOTE_PRICE_DIGITS
                ) {
                  e.preventDefault();
                  return;
                }
                setPrice(e.target.value);
              }}
            />
          </InputWrapper>
          {!!price &&
            price.toString().replace('.', '').length >=
              MAX_QUOTE_PRICE_DIGITS && (
              <ErrorInfo>
                <span>
                  {MAX_QUOTE_PRICE_DIGITS} digit limit reached for Quote Item's
                  price.
                </span>
              </ErrorInfo>
            )}
        </div>
      </Row>
      <ButtonsWrapper>
        <Button
          data-cy='service-save-button'
          text={text}
          variant='primary'
          onClick={() =>
            handleSubmit({ ...serviceData, title: title, price: price })
          }
          disabled={title === '' || title.length > 100}
          icon={(buttonIcon as JSX.Element) || undefined}
        />
      </ButtonsWrapper>
    </>
  );
};
