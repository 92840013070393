import { EXPRESS_API } from 'configs/api/expressApi';
import { useAuth } from 'lib/context';
import { useMutation } from 'react-query';
import { WHITELABEL_NAME } from '../whitelabel/getWhiteLabel';

export interface LoginAsUserParams {
  userId: string;
  timestamp: number;
  key: string;
}

const loginAsUser = async ({ userId, timestamp, key }: LoginAsUserParams) => {
  const response = await EXPRESS_API.post(
    '/auth/loginas',
    {
      userId,
      timestamp,
      key,
    },
    { withCredentials: true }
  );
  return response.data;
};

export const useLoginAsUser = () => {
  const { setJwtAndUpdateToken, whitelabel } = useAuth();
  return useMutation(loginAsUser, {
    onSuccess: data => {
      if (!!whitelabel && whitelabel.name !== WHITELABEL_NAME.COVIDEO) {
        setJwtAndUpdateToken(
          {
            accessToken: data.jwt,
            adminJwt: data.adminjwt,
          },
          true
        );
      }
    },
  });
};
