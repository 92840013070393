import { getEmailsForReport } from 'lib/api';
import {
  LoadingIndicator,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { AccessRole, reportData } from 'lib/const';
import { useAuth } from 'lib/context';
import { capitalize } from 'lodash';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Header from './Header';
import { FaChevronRight } from 'react-icons/fa';
import { theme } from 'lib/style';
import { useHistory, useLocation } from 'react-router';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import FilterStrip from './FilterStrip';
import {
  loadFilteringData,
  SORTING,
  VIDEO_ATTRIBUTES,
} from 'lib/utils/videoAttributes';
import queryString from 'query-string';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import { NotFound } from 'app/pages/notFound/NotFound';

const videoAttributeTypes = [
  {
    value: '',
    label: 'All Types',
  },
  {
    value: 'dropdown',
    label: 'Dropdown',
  },
  {
    value: 'text',
    label: 'Text Field',
  },
  {
    value: 'textarea',
    label: 'Comment',
  },
];

const TableCell = styled.div`
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
`;

const VideoAttributeList = () => {
  const [departments, setDepartments] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedAttributeType, setSelectedAttributeType] = useState('');
  const [search, setSearch] = useState('');
  const [activeTotalVideosSort, setActiveTotalVideosSort] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [data, setData] = useState<
    { [key: string]: number | string | boolean }[] | null
  >([]);
  const [filteredData, setFilteredData] = useState<
    { [key: string]: number | string | boolean }[]
  >([]);

  const { userData } = useAuth();
  const isAdmin = !(userData.access.toString() === AccessRole.USER);

  const history = useHistory();
  const report = reportData[VIDEO_ATTRIBUTES];

  const location = useLocation();

  const queryParams: any = queryString.parse(location.search);
  const [selectedUser, setSelectedUser] = useState(queryParams.user || '');

  const selectData = [
    {
      data: videoAttributeTypes,
      value: selectedAttributeType,
      queryParam: 'attributeType',
      setValue: setSelectedAttributeType,
      onChange: (option: any) => {
        setPage(0);
        setSelectedAttributeType(option.value);
      },
    },
    ...(isAdmin
      ? [
          {
            data: departments,
            value: selectedDepartment,
            queryParam: 'department',
            setValue: setSelectedDepartment,
            onChange: (option: any) => {
              setPage(0);
              setSelectedDepartment(option.value);
            },
          },
          {
            data: users,
            value: selectedUser,
            queryParam: 'user',
            setValue: setSelectedUser,
            onChange: (option: any) => {
              setPage(0);
              setSelectedUser(option.value);
            },
          },
        ]
      : []),
  ];
  selectData.forEach(select => {
    queryParams[select.queryParam] = select.value;
  });

  const [startDate, setStartDate] = React.useState<Date>(
    queryParams.start || new Date()
  );
  const [endDate, setEndDate] = React.useState<Date>(
    queryParams.end || new Date()
  );

  const loadVideoAttributeData = async (start: Date, end: Date) => {
    setLoading(true);
    try {
      const videoAttributeData = (await getEmailsForReport(
        `${report.url}/details`,
        start,
        end,
        [],
        {
          department: selectedDepartment,
          attributeType: selectedAttributeType,
          user: selectedUser,
          search,
          sort: activeTotalVideosSort,
        }
      )) as any;

      setData(videoAttributeData);
    } catch (e) {
      setData(null);
    }
    setLoading(false);
  };

  const onSearch = (term: string) => {
    setSearch(term);
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const onDateRangeChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const onSort = () => {
    setActiveTotalVideosSort(
      activeTotalVideosSort === SORTING.ASC ? SORTING.DESC : SORTING.ASC
    );
  };

  const formatTableData = (dataItem: any) => {
    return report.tableFields.map((type: any, index: number) => {
      const value = dataItem[type.value];
      const isAttributeType = type.value === 'attributeType';
      const isEmpty = !value;
      const isLast = index === report.tableFields.length - 1;
      const isFirstOrSecond = !index || index === 1;
      const isNumber = !isNaN(Number(value));

      let displayValue = value;
      let tableCellStyle = {};

      if (isEmpty) displayValue = '-';
      else if (isAttributeType) displayValue = capitalize(value);
      else if (isNumber) {
        const numValue = Number(value).toFixed(2);
        displayValue = addThousandCommaSeparator(Number(numValue));
      }

      if (isFirstOrSecond) tableCellStyle = { textAlign: 'left' };
      if (isLast) {
        tableCellStyle = {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        };

        displayValue = (
          <>
            <div>{displayValue}</div>
            <FaChevronRight
              size={13}
              color={theme.palette.covideoGray40}
              onClick={() => {
                history.push(
                  '/reports/overview/video-attributes/' +
                    dataItem.videoAttributeId +
                    '?' +
                    queryString.stringify(queryParams)
                );
              }}
              style={{ cursor: 'pointer', marginLeft: '15px' }}
            />
          </>
        );
      }

      return <TableCell style={tableCellStyle}>{displayValue}</TableCell>;
    });
  };

  const formatTableHeaders = () => {
    return report.tableFields.map((item: any, index: number) => {
      const isLast = index === report.tableFields.length - 1;
      const isFirstOrSecond = !index || index === 1;

      if (isFirstOrSecond)
        return (
          <TableCell style={{ textAlign: 'left' }}>{item.label}</TableCell>
        );
      if (isLast)
        return (
          <TableCell
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              outline: 'none !important',
              userSelect: 'none',
              marginRight: '20px',
            }}
            onClick={onSort}
          >
            <div style={{ marginRight: '10px' }}>{item.label}</div>
            <HiArrowNarrowUp
              color={
                activeTotalVideosSort === SORTING.ASC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
              width={8}
              height={12}
            />
            <HiArrowNarrowDown
              color={
                activeTotalVideosSort === SORTING.DESC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
              width={8}
              height={12}
            />
          </TableCell>
        );
      return <TableCell>{item.label}</TableCell>;
    });
  };

  useEffect(() => {
    loadFilteringData(userData.customerId, setDepartments, setUsers);
  }, []);

  useEffect(() => {
    loadVideoAttributeData(startDate, endDate);
  }, [
    startDate,
    endDate,
    selectedDepartment,
    selectedUser,
    selectedAttributeType,
    search,
    activeTotalVideosSort,
  ]);

  useEffect(() => {
    const start = page * size;
    if (data) {
      setFilteredData([...data.slice(start, start + size)]);
    }
  }, [data, page, size]);

  if (!data) {
    return <NotFound />;
  }

  return (
    <div>
      <Header
        onDateRangeChange={onDateRangeChange}
        downloadData={{
          reportTitle: `video-attributes-report.csv`,
          url: `${report.url}/download`,
          from: startDate,
          to: endDate,
          data: {
            search,
            sort: activeTotalVideosSort,
            department: selectedDepartment,
            attributeType: selectedAttributeType,
            user: selectedUser,
            downloadType: 'DETAILS',
          },
        }}
        sendUrl={report.url}
        title={'Video Attributes'}
      />
      <FilterStrip onSearch={onSearch} selectData={selectData}></FilterStrip>
      {loading ? (
        <LoadingIndicator isLoading={loading} height='300px' />
      ) : data.length ? (
        <TableContextProvider
          total={data.length}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          <div style={{ marginTop: '20px' }}>
            <Table
              compact={true}
              hoverable={false}
              headers={[...formatTableHeaders()]}
              rows={filteredData.map((dataItem: any, index: number) => ({
                key: index,
                columns: [...formatTableData(dataItem)],
                onClick: () => {},
              }))}
            ></Table>
          </div>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew globalName='video-attributes-list' />
          </TableFooter>
        </TableContextProvider>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
          No data to show.
        </div>
      )}
    </div>
  );
};

export default VideoAttributeList;
