import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FilePrivacySection } from './FilePrivacySection';
import { successToast } from 'lib/components/toasts/success';
import { DROP_PRIVACY } from 'lib/const/Droplr';
import { NewModal } from 'lib/components';
import { useGetDrop } from 'lib/api/droplr/getDrop';
import { useEditDrop } from 'lib/api/droplr/editDrop';
import { UpdateDropBody } from 'lib/api/droplr/types';
import { useIsMutating } from 'react-query';
import { droplrKeys } from 'lib/api/droplr/queryKeys';
import { Button } from 'react-covideo-common';

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  dropIds: string[];
  handleModalClose: (reportId?: string) => void;
};

export const SetPasswordModal = ({ dropIds, handleModalClose }: Props) => {
  const [password, setPassword] = useState('');
  const [dropPrivacy, setDropPrivacy] = useState(DROP_PRIVACY.PUBLIC);

  const isSingularDrop = dropIds.length === 1;

  const { data: drop, refetch: refetchDrop } = useGetDrop({
    dropId: isSingularDrop ? dropIds[0] : '',
  });

  const { mutateAsync: editDrop } = useEditDrop();
  const isLoading =
    useIsMutating({
      mutationKey: droplrKeys.edit_drop_mutation(),
    }) > 0;

  React.useEffect(() => {
    if (drop && isSingularDrop) {
      if (drop.privacy) {
        setDropPrivacy(drop.privacy);
      }
      // password is set only if file is private
      if (
        drop.password &&
        drop.password !== password &&
        drop.privacy === DROP_PRIVACY.PRIVATE
      ) {
        setPassword(drop.password);
      }
    }
  }, [drop]);

  const updatePrivacy = async () => {
    const privacyData = {
      privacy: dropPrivacy,
    } as Partial<UpdateDropBody>;

    if (dropPrivacy === DROP_PRIVACY.PRIVATE) {
      privacyData.password = password;
    }

    for (let dropId of dropIds) {
      await editDrop({
        dropId,
        ...privacyData,
      });
    }
    if (isSingularDrop) refetchDrop();
    successToast({
      title: 'Privacy updated successfully!',
    });
  };

  const isPrivate = dropPrivacy === DROP_PRIVACY.PRIVATE;
  const isPasswordValid = /^[a-z\d]{4,32}$/i.test(password);

  return (
    <NewModal closeModal={handleModalClose} headerText='Set File Privacy'>
      <FilePrivacySection
        drop={drop || null}
        password={password}
        setPassword={setPassword}
        dropPrivacy={dropPrivacy}
        setDropPrivacy={setDropPrivacy}
        showFileTitle={true}
      />
      <Footer>
        <Button
          text='Save'
          onClick={async () => {
            await updatePrivacy();
            handleModalClose();
          }}
          disabled={isLoading || (isPrivate && !isPasswordValid)}
        />
      </Footer>
    </NewModal>
  );
};
