import * as React from 'react';
import styled from 'styled-components/macro';
import placeholder from 'assets/images/table/placeholderBig.webp';
import { theme } from 'lib/style';

const NoContactLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
`;

export const NoHistory = () => {
  return (
    <>
      <NoContactLayout>
        <Text>No Activity History Available</Text>
      </NoContactLayout>
    </>
  );
};
