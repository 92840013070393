import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { groupKeys } from './groupKeys';

export const getGroup = async (groupId: string) => {
  const response = await EXPRESS_API.get(`/groups/${groupId}`);
  const group = response?.data || {};
  return { group };
};

export const useQuerySingleGroup = (groupId: string) => {
  return useQuery(groupKeys.single_group(groupId), () => getGroup(groupId), {
    refetchOnMount: true,
  });
};
