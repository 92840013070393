import { FileTypes, ITableFields, TableFieldsValue } from './types';
import { GetRecentlyDeletedParams } from 'lib/api/recentlyDeletedApi';
export const typesOptions = [
  { value: '', label: 'All content types' },
  { value: FileTypes.VIDEO, label: 'Video' },
  { value: FileTypes.FILE, label: 'File' },
  { value: FileTypes.BOARD, label: 'Board' },
  { value: FileTypes.LANDING_PAGE, label: 'Landing Page' },
  { value: FileTypes.CONTACT, label: 'Contacts' },
  { value: FileTypes.TELE_PROMPTER_SCRIPT, label: 'Teleprompter script' },
  { value: FileTypes.CTA_SET, label: 'CTA set' },
];

export const tableFields: ITableFields[] = [
  {
    value: TableFieldsValue.TITLE,
    label: 'Title or name',
  },
  {
    value: TableFieldsValue.TYPE,
    label: 'Type',
  },
  {
    value: TableFieldsValue.CREATED_AT,
    label: 'Deleted on',
  },
  {
    value: TableFieldsValue.DAYS,
    label: 'Days Until Deleted',
  },
  {
    value: TableFieldsValue.RESTORE,
    label: 'Restore',
  },
];

export const checkIfContainsEmptyString = (params: any) =>
  Object.keys(params)
    .filter(key => params[key] !== '')
    .reduce(
      (obj, key) => {
        return {
          ...obj,
          [key]: params[key],
        };
      },
      { start: 0, limit: 10 }
    ) as GetRecentlyDeletedParams;
