import React from 'react';
import { Dropdown } from '../dropdown/Dropdown';
import styled from 'styled-components/macro';
import { timezones } from 'lib/const';
import { SelectComponents } from 'react-select/src/components';

export type TimezonesOption = {
  value: string;
  label: string;
  offset: number;
  utc: string[];
};

const DropdownContainer = styled.div`
  width: 100%;
`;

// Sort timezones to have popular timezones at top
export const timezonesOptions: TimezonesOption[] = timezones
  .sort((tz1, tz2) => {
    const tz1Popular = tz1.popular ? 1 : 0;
    const tz2Popular = tz2.popular ? 1 : 0;
    if (tz2Popular !== tz1Popular) {
      return tz2Popular - tz1Popular;
    }
    return tz1.offset - tz2.offset;
  })
  .map(timezone => ({
    value: timezone.value,
    label: timezone.text,
    offset: timezone.offset,
    utc: timezone.utc,
  }));

type Props = {
  onChange: (value: TimezonesOption) => void;
  value?: string;
  disabled?: boolean;
  useUtcValue?: boolean;
  placeholder?: string;
  extendStyles?: {
    container?: React.CSSProperties;
    menu?: React.CSSProperties;
    control?: React.CSSProperties;
    menuList?: React.CSSProperties;
    valueContainer?: React.CSSProperties;
    input?: React.CSSProperties;
  };
  customComponents?: Partial<SelectComponents<any, any>> | undefined;
  className?: string;
};

export const TimezoneSelector = ({
  onChange,
  value = '',
  disabled,
  useUtcValue = false,
  placeholder = 'Timezone',
  extendStyles,
  customComponents,
  className = 'scheduleDropdown',
}: Props) => {
  const selectedTimezone = timezonesOptions.find(timezone => {
    // Check with UTC value for user preferences form
    if (useUtcValue && !!timezone.utc.length && timezone.utc.includes(value)) {
      return true;
    }

    return timezone.value === value;
  });

  return (
    <DropdownContainer>
      <Dropdown
        placeholder={placeholder}
        value={selectedTimezone}
        creatable={false}
        onChange={onChange}
        options={timezonesOptions}
        className={className}
        disabled={disabled}
        customComponents={customComponents}
        extendStyles={
          extendStyles || {
            valueContainer: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              flexWrap: 'nowrap',
              maxWidth: 350,
            },
          }
        }
      />
    </DropdownContainer>
  );
};
