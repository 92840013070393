import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-covideo-common';

import Switch from 'app/pages/design/landingPageBuilder/components/Switch';
import {
  Search,
  CheckboxInput,
  ModalReassign,
  SelectionHeaderPopup,
} from 'lib/components';
import { useAuth } from 'lib/context';
import { getUsersByAutomotiveRoles } from 'lib/api';
import { DateRangeSelector } from 'app/pages/reports/components';
import { RepairOrderStatus } from 'lib/const';
import { TableListing } from '.';
import { AutomotiveRole } from 'lib/const/AutomotiveRole';
import Select from 'react-select';
import { ModalDeleteRepairOrders } from './ModalDeleteRepairOrders';
import { useGetRepairOrdersQuery } from 'lib/api/repairOrders/getRepairOrders';
import { useDeleteMultipleRepairOrders } from 'lib/api/repairOrders/deleteRepairOrder';
import { useReassignAdvisor } from 'lib/api/repairOrders/reassignAdvisor';
import { successToast } from 'lib/components/toasts/success';
import { useGetRepairOrderStatistics } from 'lib/api/repairOrders/getStatistics';
import { MdDeleteForever, MdOutlineAutorenew } from 'react-icons/md';

export enum QUOTE_SETTINGS {
  DOLLAR = 'dollar',
  ITEM = 'item',
}
const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;
const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;
const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
  margin-bottom: 32px;
`;
type FilterProps = {
  margin?: string;
};
const Filter = styled.div<FilterProps>`
  margin: ${props => (props.margin ? props.margin : '0')};
  min-width: 215px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;
const HeaderSide = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderBox = styled.div`
  box-sizing: border-box;
  width: 186px;
  padding: 8px 16px 8px 16px;
  margin-left: 16px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 7px;
  > p {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    color: ${theme.palette.covideoBlue100};
    margin: 0;
    line-height: 24px;
  }
  > span {
    font-size: 13px;
    line-height: 24px;
    color: ${theme.palette.gray80};
  }
`;
type HeaderNumberProps = {
  negative: boolean;
};
const HeaderNumber = styled.span<HeaderNumberProps>`
  color: ${props => (props.negative ? 'red' : 'green')};
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  margin-right: 20px;
  white-space: nowrap;
  color: ${theme.palette.coal};
`;

const SwitchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    position: relative;
  }
  > span {
    margin-right: 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    color: ${theme.palette.gray100};
  }
  input {
    position: absolute;
  }
`;

const DateRangeSelectorContainer = styled.div`
  .repair-order-dropdown > div,
  .repair-order-dropdown > div:hover,
  .repair-order-dropdown > div:focus {
    border-radius: 6px !important;
  }
`;

type Option = {
  value: string | number;
  label: string | number;
};

export const RepairOrderListing = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filterByStatus, setFilterByStatus] = useState(
    RepairOrderStatus.NoStatus
  );
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState('-createdAt');
  const [selectedRepairOrder, setSelectedRepairOrder] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [advisorId, setAdvisorId] = useState('');
  const [showModalReassign, setShowModalReassign] = useState(false);
  const [advisorOptions, setAdvisorOptions] = useState<Option[]>([]);
  const { userData } = useAuth();
  const { customer } = userData;
  const location = useLocation();
  const queryParams: any = queryString?.parse(location?.search);
  const start = new Date(queryParams?.start);
  const end = new Date(queryParams?.end);
  const checkValidStartDate = start instanceof Date && !isNaN(start.valueOf());
  const checkValidEndDate = end instanceof Date && !isNaN(end.valueOf());

  const [startDate, setStartDate] = React.useState<Date>(
    checkValidStartDate ? start : new Date()
  );
  const [endDate, setEndDate] = React.useState<Date>(
    checkValidEndDate ? end : new Date()
  );

  // fetch repair orders
  const {
    data,
    isLoading: loading,
    refetch: refetchRepairOrders,
  } = useGetRepairOrdersQuery({
    start: page,
    limit: size,
    search: searchQuery,
    sort,
    filterByStatus,
    advisorId,
    startDate,
    endDate,
  });
  // fetch statistics
  const { data: statistics, refetch: refetchStatistics } =
    useGetRepairOrderStatistics({
      advisorId,
      startDate,
      endDate,
    });

  const { mutateAsync: deleteRepairOrders } = useDeleteMultipleRepairOrders(
    selectedRepairOrder,
    setSelectedRepairOrder,
    setShowDeleteModal
  );
  const { mutateAsync: editAdvisorRepairOrder } = useReassignAdvisor();

  const { order, repairOrders, count } = data;

  const {
    sentTotal,
    respondedTotal,
    avgCloseRatioDollars,
    avgCloseRatioItems,
  } = statistics;

  const themes = useTheme();

  const isServiceManager =
    userData.automotiveRole === AutomotiveRole.SERVICE_MANAGER;

  useEffect(() => {
    refetchRepairOrders();
  }, [
    searchQuery,
    page,
    size,
    sort,
    startDate,
    endDate,
    filterByStatus,
    advisorId,
  ]);

  useEffect(() => {
    refetchStatistics();
  }, [advisorId, startDate, endDate]);

  const getAssignees = async () => {
    const advisors = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_ADVISOR,
    });

    const managers = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_MANAGER,
    });

    const users = [...advisors, ...managers];

    const options = users.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    setAdvisorOptions(options);
  };

  useEffect(() => {
    getAssignees();
  }, []);

  const onSearch = (query: string) => {
    setPage(0);
    setSearchQuery(query.toLowerCase());
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
    if (page !== newPage) {
      setSelectedRepairOrder([]);
    }
  };

  const sortElement = (sortParam: any) => {
    if (!['repairOrderNumber', 'createdAt'].includes(sortParam)) return;

    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('-') ? sort.replace('-', '') : `-${sort}`;
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}`;
      setSort(newSort);
    }
  };

  const handleDeleteClick = async () => {
    await deleteRepairOrders();
  };

  const repairOrdersArray = order.map(
    repairOrderId => repairOrders[repairOrderId]
  );

  const handleReassignSubmit = async (
    advisorId: string,
    assignToIds: string[],
    advisor: any
  ) => {
    const advisorName =
      advisor.firstName && advisor.lastName
        ? `${advisor.firstName} ${advisor.lastName}`
        : 'New Advisor';
    await Promise.all(
      assignToIds.map(async (repairOrderId: string) => {
        editAdvisorRepairOrder({ advisorId, repairOrderId });
      })
    );

    successToast({
      title: `${advisorName} is now assigned to ${
        selectedRepairOrder.length > 1 ? 'these orders' : 'this order'
      }!`,
    });
  };

  const onDateRangeChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  //TODO: calculate change from previous period
  const showChange = false;
  const sentChange = 0;
  const approvedChange = 0;
  const avgCloseRatioChange = 0;

  return (
    <>
      {!!selectedRepairOrder.length && (
        <SelectionHeaderPopup fixedWidth='1216px'>
          <CheckboxSelectionWrapper>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={
                selectedRepairOrder.length > 0 &&
                repairOrdersArray.length !== selectedRepairOrder.length
              }
              checked={repairOrdersArray.length == selectedRepairOrder.length}
              ignoreGrayForAllSelect={true}
              grayCheck={true}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                let { checked } = event.target as HTMLInputElement;
                if (checked)
                  setSelectedRepairOrder(
                    repairOrdersArray.map((e: any) => e.repairOrderId)
                  );
                else setSelectedRepairOrder([]);
              }}
            />
            <SelectionCountWrap>
              <SelectionCountText>Repair Orders selected:</SelectionCountText>
              <b>{selectedRepairOrder.length}</b>
            </SelectionCountWrap>
          </CheckboxSelectionWrapper>
          <ButtonsWrapper>
            {isServiceManager && (
              <Button
                onClick={() => setShowModalReassign(true)}
                text='Reassign'
                icon={<MdOutlineAutorenew size={18} />}
                variant='secondary'
              />
            )}
            <Button
              onClick={() => setShowDeleteModal(true)}
              text='Delete'
              variant='destructive'
              icon={
                <MdDeleteForever size={18} color={theme.palette.buttonDelete} />
              }
            />
          </ButtonsWrapper>
        </SelectionHeaderPopup>
      )}
      <Header>
        <HeaderSide>
          <Title>Repair Orders</Title>
          <DateRangeSelectorContainer>
            <DateRangeSelector
              dropdownClassName='repair-order-dropdown'
              onDateRangeChange={onDateRangeChange}
              onTodayOnlyDate={true}
            />
          </DateRangeSelectorContainer>
        </HeaderSide>
        <HeaderSide>
          <HeaderBox>
            <p>
              <b>
                {sentTotal} {RepairOrderStatus.Sent}
              </b>
              {showChange && (
                <HeaderNumber negative={sentChange < 0}>
                  {sentChange}
                </HeaderNumber>
              )}
            </p>
            <span>orders</span>
          </HeaderBox>
          <HeaderBox>
            <p>
              <b>
                {respondedTotal} {RepairOrderStatus.Responded}
              </b>
              {showChange && (
                <HeaderNumber negative={approvedChange < 0}>
                  {approvedChange}
                </HeaderNumber>
              )}
            </p>
            <span>orders</span>
          </HeaderBox>
          <HeaderBox>
            <p>
              <b>
                {userData.customer.quoteSettings === QUOTE_SETTINGS.ITEM
                  ? avgCloseRatioItems
                  : avgCloseRatioDollars}
                %
              </b>
              {showChange && (
                <HeaderNumber negative={avgCloseRatioChange < 0}>
                  {avgCloseRatioChange > 0 && '+'}
                  {avgCloseRatioChange}%
                </HeaderNumber>
              )}
            </p>
            <span>avg. close ratio</span>
          </HeaderBox>
        </HeaderSide>
      </Header>
      <FiltersContainer>
        <Filter>
          <Search
            width={'372px'}
            placeholder='Search by RO# or customer name...'
            onSearch={onSearch}
          />
        </Filter>
        <Filter margin='0 0 0 auto'>
          <SwitchInputWrapper>
            <span>Show only not responded</span>
            <div>
              <Switch
                id={'switch-1'}
                isOn={filterByStatus === RepairOrderStatus.Sent}
                onColor={themes.colors.primary[100]}
                handleToggle={() => {
                  setFilterByStatus(
                    filterByStatus === RepairOrderStatus.Sent
                      ? RepairOrderStatus.NoStatus
                      : RepairOrderStatus.Sent
                  );
                  setPage(0);
                }}
              />
            </div>
          </SwitchInputWrapper>
        </Filter>
        {isServiceManager && (
          <Filter margin='0 0 0 15px'>
            <Select
              styles={{
                control: (base: any) => ({ ...base, height: '40px' }),
                indicatorSeparator: () => ({ display: 'none' }),
                menuPortal: provided => ({
                  ...provided,
                  zIndex: 999,
                }),
              }}
              menuPortalTarget={document.body}
              menuPlacement={'bottom'}
              options={advisorOptions}
              value={advisorOptions.find(o => {
                return o.value === advisorId;
              })}
              onChange={(option: any) => setAdvisorId(option?.value || '')}
              isSearchable={true}
              placeholder={'All Advisors...'}
              isClearable={true}
            />
          </Filter>
        )}
      </FiltersContainer>
      <TableListing
        repairOrdersArray={repairOrdersArray}
        loading={loading}
        count={count}
        page={page}
        size={size}
        sort={sort}
        selectedRepairOrder={selectedRepairOrder}
        sortElement={sortElement}
        setSelectedRepairOrder={setSelectedRepairOrder}
        onPaginationChange={onPaginationChange}
      />
      {showDeleteModal && (
        <ModalDeleteRepairOrders
          count={selectedRepairOrder.length}
          handleModalClose={() => setShowDeleteModal(false)}
          onClickPrimaryButton={handleDeleteClick}
          onClickSecondaryButton={() => setShowDeleteModal(false)}
        />
      )}
      {showModalReassign && (
        <ModalReassign
          handleSubmit={handleReassignSubmit}
          handleModalClose={() => setShowModalReassign(false)}
          title={`Reassign order${
            selectedRepairOrder.length > 1 ? 's' : ''
          } to another advisor`}
          assignTo={selectedRepairOrder}
        />
      )}
    </>
  );
};
