import { useMutation, useQueryClient } from 'react-query';
import { annotationsKeys } from './annotationsKeys';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';
import { wrapAnnotationWithProps } from './helper';
import { CreateAnnotationParams } from './createAnnotation';

export const editAnnotation = async ({
  videoId,
  annotationId,
  data,
}: CreateAnnotationParams) => {
  const response = await EXPRESS_API.put(
    `/videos/${videoId}/annotations/${annotationId}`,
    data
  );

  const annotation = response.data;

  const isCompanyAnnotation = !!data.customerId;
  if (isCompanyAnnotation) {
    return wrapAnnotationWithProps(annotation, { isCompanyAnnotation });
  }
  return wrapAnnotationWithProps(annotation);
};

export const useEditAnnotationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(editAnnotation, {
    onSuccess: () => {
      queryClient.invalidateQueries(annotationsKeys.annotations());
      successToast({ title: 'Annotation edited successfully!' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred editing the annotation, please try again later!',
      });
    },
  });
};
