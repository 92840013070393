import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { CheckboxInput } from 'lib/components';

const PublishItemContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 16px;
  width: 100%
  height: 84px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray10};
  border-radius: 5px;
`;

type FlexRowContainerProps = {
  justifyContent?: string;
};
const FlexRowContainer = styled.div<FlexRowContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px 0 19px;
`;

const PublishTextContainer = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

const PublishingPlaceTextContainer = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;

const PrivatePublishing = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.red100};
`;

type Props = {
  itemKey: string;
  isChecked: boolean;
  onChange: () => void;
  icon: any;
  publishText: string;
  publishPlace: string;
  privatePublishing?: boolean;
  social: string;
};

export const PublishItem = ({
  itemKey,
  isChecked,
  onChange,
  icon,
  publishText,
  publishPlace,
  privatePublishing = false,
  social,
}: Props) => {
  const isLinkedin = social === 'LinkedIn';
  return (
    <PublishItemContainer key={itemKey}>
      <FlexRowContainer>
        <CheckboxInput
          checked={isChecked}
          onChange={onChange}
          disabled={isLinkedin ? false : !publishPlace}
        />
        <IconWrapper>{icon}</IconWrapper>
        <PublishTextContainer>{publishText}</PublishTextContainer>
      </FlexRowContainer>
      {publishPlace && (
        <>
          <PublishingPlaceTextContainer>
            Publishing on <b>{publishPlace}</b>
          </PublishingPlaceTextContainer>
          {privatePublishing && (
            <PrivatePublishing>
              {social} does not allow private publishing. The post will be
              visible to all.
            </PrivatePublishing>
          )}
        </>
      )}
      {!publishPlace && !isLinkedin && (
        <PrivatePublishing>
          Please connect page or channel to your account.
        </PrivatePublishing>
      )}
    </PublishItemContainer>
  );
};
