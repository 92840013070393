import React, { useState } from 'react';

import { LoadingIndicator } from 'lib/components';
import { ModalStartConversation } from '../modals/ModalStartConversation';

import {
  Content,
  MainChatBox,
  MainContainer,
  UserChatBox,
  Container,
  ChatBodyWithOutData,
  EmptyMsg,
} from '../styles/UsersConversationsLayout';
import ProfileInfoSection from '../components/ProfileInfoSection';

import { ConversationsList } from '../components/ConversationsList';
import { ConversationsArea } from '../components/ConversationArea';
import { ModalsType } from '../conversations.types';
import { useGetConversationsQuery } from 'lib/api/conversations/getConversations';
import { useGetSingleConversationQuery } from 'lib/api/conversations/getSingleConversation';
import { ReassignConversationModal } from '../modals/ModalReassignConversation';
import { ModalSendTranscript } from '../modals/ModalSendTranscript';
import { useTypeOfConversation } from '../hooks/useTypeOfConversation';

export const ConversationsLayout = () => {
  const [modals, setModals] = useState<ModalsType | false>(false);
  const [searchValue, setSearch] = useState('');

  const {
    isLoading,
    data,
    fetchNextPage,
    isRefetching: isRefetchingMultipeConversations,
  } = useGetConversationsQuery({
    search: searchValue,
  });

  const {
    isRefetching: isRefetchingSingleConversations,
    data: singleConversation,
    isLoading: isLoadingConversation,
  } = useGetSingleConversationQuery();

  //CALCULATE DROPDOWN VALUES
  const items = useTypeOfConversation(singleConversation);

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <Container>
      <MainContainer>
        <Content>
          <MainChatBox>
            <ConversationsList
              setSearch={setSearch}
              searchValue={searchValue}
              setModals={setModals}
              data={data}
              fetchNextPage={fetchNextPage}
              isRefetchingSingleConversations={isRefetchingSingleConversations}
              isRefetchingMultipeConversations={
                isRefetchingMultipeConversations
              }
            />
            <UserChatBox>
              {!data ? (
                <ChatBodyWithOutData>
                  <EmptyMsg>
                    Something went wrong with loading list of conversations
                  </EmptyMsg>
                </ChatBodyWithOutData>
              ) : (
                <ConversationsArea
                  data={data}
                  singleConversation={singleConversation}
                  setModals={setModals}
                  modals={modals}
                  isLoadingConversation={isLoadingConversation}
                  dropdownItems={items}
                  isRefetchingSingleConversations={
                    isRefetchingSingleConversations
                  }
                />
              )}
            </UserChatBox>
          </MainChatBox>
          {singleConversation && (
            <ProfileInfoSection
              singleConversation={singleConversation}
              setModals={setModals}
            />
          )}
        </Content>
        {modals === 'add' && (
          <ModalStartConversation onModalClose={() => setModals(false)} />
        )}
        {modals === 'reassign' && singleConversation && (
          <ReassignConversationModal
            singleConversation={singleConversation}
            onModalClose={() => setModals(false)}
          />
        )}
        {(modals === 'send_transcript' ||
          modals === 'send_transcript_closed') &&
          singleConversation && (
            <ModalSendTranscript
              singleConversation={singleConversation}
              onModalClose={() => setModals(false)}
              modals={modals}
            />
          )}
      </MainContainer>
    </Container>
  );
};
