import { useOnClickOutside } from 'lib/utils';
import React, { useState } from 'react';
import {
  ThreeDots,
  ThreeDotsWrapper,
  MainWrapper,
  ItemsWrapper,
  Item,
  ItemIcon,
  ItemParagraph,
} from './index.styled';

interface IItem {
  text: string;
  onClick: any;
  id: string;
  icon?: React.ReactNode | undefined;
  disabled: boolean;
}

export const ThreeDotsDropdown = ({
  items,
  border,
  borderRadius,
  isLoading,
}: {
  items: IItem[];
  border?: string;
  borderRadius?: string;
  isLoading?: boolean;
}) => {
  const [isVisible, setVisibility] = useState(false);

  const openDropdown = () => setVisibility(true);
  const closeDropdown = () => setVisibility(false);

  const { ref } = useOnClickOutside(closeDropdown);

  const onCloseDropdown = (
    e: React.MouseEvent<HTMLElement>,
    cb: () => void
  ) => {
    e.stopPropagation();
    setVisibility(false);
    cb();
  };

  return (
    <MainWrapper onClick={openDropdown} ref={ref} isLoading={isLoading}>
      <ThreeDotsWrapper border={border} borderRadius={borderRadius}>
        <ThreeDots />
      </ThreeDotsWrapper>
      {isVisible && (
        <ItemsWrapper>
          {items.map((item: IItem) => (
            <Item
              id={item.id}
              key={item.id}
              onClick={e => onCloseDropdown(e, item.onClick)}
              disabled={item.disabled}
            >
              {item?.icon ? (
                <ItemIcon className={`icon ${item.id}`}>{item.icon}</ItemIcon>
              ) : null}
              <ItemParagraph className={`text ${item.id}`}>
                {item.text}
              </ItemParagraph>
            </Item>
          ))}
        </ItemsWrapper>
      )}
    </MainWrapper>
  );
};
