import { SendData } from './types';
import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';

const sendActivity = async (body: SendData) => {
  return (await EXPRESS_API.post(`activity-feed/send`, body)).data;
};

export const useSendActivity = () => {
  return useMutation((data: SendData) => sendActivity(data), {
    onError: () => {
      errorToast({
        title:
          'There was a problem with sending your report. Please try again.',
      });
    },
    onSuccess: () => {
      successToast({ title: 'Activity report sent!' });
    },
  });
};
