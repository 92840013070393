import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdEmail } from 'react-icons/md';
import { ModalContactAM } from 'lib/components/modal/ModalContactAM';
import { Gap } from 'lib/components/styles/layout';

const Header = styled.div`
  justify-content: space-between;
  display: flex;
  margin-bottom: 12px;
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;

const Main = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
`;

export const PaymentMethodEnterpriseOverview = () => {
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  return (
    <>
      <Header>
        <Title>Payment Method</Title>
      </Header>
      <Main>
        To update your payments or subscription, contact your account manager.
      </Main>
      <Gap>
        <Button
          onClick={() => setShowContactUsModal(true)}
          icon={<MdEmail />}
          text='Contact AM'
          variant='secondary'
        />
      </Gap>
      {showContactUsModal && (
        <ModalContactAM
          handleModalClose={() => setShowContactUsModal(false)}
          subject={'Covideo Subscription Update Request'}
          title='Manage Subscription'
        />
      )}
    </>
  );
};
