import { useFormikContext } from 'formik';
import { ParentCustomer } from 'lib/api/multiLocations/multiLocationQuery';
import React from 'react';
import { LocationItem } from './LocationItem';
import { ItemContainer } from '../ItemContainer';
import { theme } from 'lib/style';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { MdError } from 'react-icons/md';
import { Gap } from 'lib/components/styles/layout';
interface IProps {
  openModalHandler: (name: string | null, locationId: number | null) => void;
}
export const EditLocationView = ({ openModalHandler }: IProps) => {
  const { values } = useFormikContext<ParentCustomer>();
  return (
    <>
      {values.business && (
        <LocationItem
          customerId={values.customerId}
          label={values?.location?.name}
          openModalHandler={() => {
            if (values?.location?.locationId) {
              openModalHandler(
                values?.location?.name,
                values?.location?.locationId
              );
            }
          }}
        />
      )}
      {values.childCustomers.map((childCustomer, index) => {
        if (!childCustomer.location) {
          return (
            <ItemContainer width='100%'>
              <Gap center gap='4px'>
                <MdError color={theme.palette.red100} />
                <ParagraphSmall color={theme.palette.red100}>
                  Location Missing
                </ParagraphSmall>
              </Gap>
            </ItemContainer>
          );
        }
        const { name, locationId, customerId } = childCustomer.location;
        return (
          <LocationItem
            customerId={customerId}
            key={index}
            label={name}
            openModalHandler={() => {
              if (locationId) {
                openModalHandler(name, locationId);
              }
            }}
          />
        );
      })}
    </>
  );
};
