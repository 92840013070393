import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';

import { servicesKeys } from './servicesKeys';
import { SERVICE } from './types';

interface ServiceInfoResponse {
  error: boolean;
  message: any;
}

const fetchServicesInformations = async (
  service: SERVICE
): Promise<ServiceInfoResponse> => {
  const response = await EXPRESS_API.get(`/${service}/info`);
  return response.data?.data;
};

export const useServicesInformationsQuery = (service: SERVICE) => {
  return useQuery(
    servicesKeys.info(),
    () => fetchServicesInformations(service),
    {
      enabled: false,
    }
  );
};
