import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Overview } from './Overview';
// import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path="/sms/overview" component={Overview} exact={true} />
      {/* <Route path="/users/:userId" component={Details} /> */}
    </Switch>
  );
};
