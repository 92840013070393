import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

const sendEmailVerification = async () => {
  const { data } = await EXPRESS_API.post(`/email-verifications`);
  return data;
};

export const useSendEmailVerification = (
  setResent: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { showError } = useToastError();
  return useMutation(() => sendEmailVerification(), {
    onError: (err: AxiosError) => showError(err),
    onSuccess: () => {
      successToast({
        title: 'Your verification email has been successfully sent.',
      });
      setResent(true);
    },
  });
};
