import React from 'react';
import styled, { css } from 'styled-components/macro';

const TabsWrapper = styled.div`
  max-width: 450px;
  border-radius: 24px;
  padding: 4px;
  background-color: rgba(242, 244, 246, 1);
  display: flex;
  margin-bottom: 16px;
`;

const TabItem = styled.div<{ selected: any }>`
  order: 0;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  color: rgb(110, 126, 154);

  ${({ selected }) =>
    selected &&
    css`
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(29, 30, 36, 0.04);
      color: #001b53;
      border-radius: 16px;
    `}
`;

const TabParagraph = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 0;
  color: inherit;
`;

interface IItems {
  id: string;
  label: string;
}

interface Props {
  items: IItems[];
  selected: any;
  setSelected: any;
}

const WidgetTabs = ({ items, selected, setSelected }: Props) => {
  return (
    <TabsWrapper>
      {items.map((item: IItems) => (
        <TabItem
          key={item.id}
          selected={selected.id === item.id}
          onClick={() => setSelected(item)}
        >
          <TabParagraph>{item.label}</TabParagraph>
        </TabItem>
      ))}
    </TabsWrapper>
  );
};

export default WidgetTabs;
