import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { StepOneChoosePath } from './stepOne';
import { StepTwoFroMyTeam, StepTwoForMyself } from './stepTwo';
import { StepThreeForMyTeam, StepThreeForMyself } from './stepThree';
import { Welcome, MyTeam, Myself } from './welcome';

export const OnboardingFreemiumRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path='/onboarding-freemium' component={Welcome} />
      <Route
        exact={true}
        path='/onboarding-freemium/step-one/choose-path'
        component={StepOneChoosePath}
      />
      <Route
        exact={true}
        path='/onboarding-freemium/step-two/myself'
        component={StepTwoForMyself}
      />
      <Route
        exact={true}
        path='/onboarding-freemium/step-three/myself'
        component={StepThreeForMyself}
      />
      <Route
        exact={true}
        path='/onboarding-freemium/step-two/my-team'
        component={StepTwoFroMyTeam}
      />
      <Route
        exact={true}
        path='/onboarding-freemium/step-three/my-team'
        component={StepThreeForMyTeam}
      />
      <Route exact={true} path='/onboarding/my-team' component={MyTeam} />
      <Route exact={true} path='/onboarding/myself' component={Myself} />
      <Route path='*'>
        <Redirect to='/onboarding/my-team' />
      </Route>
    </Switch>
  );
};
