import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { snippetsKeys } from './queryKeys';
import { decodeText, encodeText } from './utils';
import { QuerySnippetsParams, UpdateSnippetData } from './types';

export const updateSnippet = async (data: UpdateSnippetData) => {
  const { emailText, smsText } = data;
  const encodedData = encodeText(emailText, smsText);
  const response = await EXPRESS_API.put(`/snippets/${data.snippetId}`, {
    ...data,
    emailText: encodedData.emailText,
    smsText: encodedData.smsText,
  });
  const decodedSnippet = await decodeText(response.data);
  return decodedSnippet;
};

export const useUpdateSnippetMutation = (
  queryParams?: QuerySnippetsParams,
  snippetId?: string
) => {
  const queryClient = useQueryClient();
  return useMutation(updateSnippet, {
    onSuccess: () => {
      successToast({
        title: `Template successfully updated.`,
      });
      queryClient.invalidateQueries(snippetsKeys.snippets(queryParams));
      if (snippetId)
        queryClient.invalidateQueries(snippetsKeys.single_snippet(snippetId));
    },
    onError: () => {
      errorToast({
        title: 'An error occurred updating template, please try again later!',
      });
    },
  });
};
