import styled, { css } from 'styled-components/macro';

import { theme } from 'lib/style';

type TableCellProps = {
  maxWidth?: number;
  width?: string;
  justifyContent?: string;
};

export const TableCell = styled.div<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || 'space-between'};
  ${props =>
    !!props.maxWidth &&
    css`
      display: block;
      width: ${props.maxWidth}px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  ${props =>
    !!props.width &&
    css`
      width: ${props.width};
    `}
`;

export const TableHeader = styled.div`
  text-align: start;
  display: flex;
`;

export const SortContainer = styled.div`
  margin-left: 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :focus {
    outline: none;
  }
`;

type EllipsisDivProps = {
  width: string;
};
export const EllipsisDiv = styled.div<EllipsisDivProps>`
  width: ${props => props.width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export enum BadgeVariant {
  BASE = 'base',
  SUCCESS = 'success',
  DANGER = 'danger',
}
type BadgeProps = {
  variant?: BadgeVariant;
};

export const Badge = styled.div<BadgeProps>`
  font-size: 12px;
  padding: 2px 8px;
  font-weight: 600;
  border-radius: 6px;
  height: 24px;
  display: flex;
  align-items: center;

  ${props =>
    props.variant === BadgeVariant.SUCCESS &&
    css`
      color: ${theme.palette.green100};
      background-color: ${theme.palette.green05};
    `}

  ${props =>
    props.variant === BadgeVariant.DANGER &&
    css`
      color: ${theme.palette.red80};
      background-color: ${theme.palette.destructiveBg};
    `}
  
      ${props =>
    props.variant === BadgeVariant.BASE &&
    css`
      background-color: ${theme.palette.covideoBlue02NonAlpha};
      color: ${theme.palette.covideoBlue80};
    `}
`;

export const Recipient = styled.div`
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
