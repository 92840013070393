import { GetSignedUrlsParams, GetSignedUrlsResponse } from '.';
import { EXPRESS_API } from 'configs/api/expressApi';
import axios from 'axios';

export type UploadVoiceoverAudioParams = {
  data: {
    uploadURL: string;
    file: File;
    mimeType: string;
  };
};

export const getVoiceoverSignedURLs = async (
  data: GetSignedUrlsParams
): Promise<GetSignedUrlsResponse> => {
  const result = await EXPRESS_API.post('aws/signedurl/voiceover-audio', data);
  return result.data;
};

export const uploadVoiceoverAudio = async ({
  data,
}: UploadVoiceoverAudioParams): Promise<any> => {
  // upload to aws signed url, do not use EXPRESS_API
  const response = await axios.put(data.uploadURL, data.file, {
    headers: {
      'Content-Type': data.mimeType,
    },
  });
  return response.data;
};

export const addVoiceoverToVideo = async ({
  videoId,
  audioFileName,
  newVideoTitle = '',
  overwriteOriginal = false,
}: {
  videoId: string;
  audioFileName: string;
  newVideoTitle?: string;
  overwriteOriginal?: boolean;
}): Promise<any> => {
  const response = await EXPRESS_API.post(`/videos/${videoId}/voiceover`, {
    newVideoTitle,
    audioFileName,
    overwriteOriginal,
  });
  return response.data;
};
