import {
  ACCOUNT_MANAGER_REG,
  BUSINESS_REG,
  CRM_PROVIDER_REG,
  CUSTOMER_FIRST_NAME_REG,
  EMAIL_REG,
  LAST_NAME_REG,
  PACKAGE_REG,
  PASSWORD_REG,
  PHONE_REG,
  REFERRAL_AGENT_REG,
  RESELLER_REG,
  SF_ACCOUNT_ID_REG,
  URL_REG,
  USER_NAME_REG,
  USER_SEATS_REG,
  VERSION_ACCESS_REG,
} from 'lib/utils/regexes';
import { colOptionProps, csvDataProps } from './Types';

export const NONE = 'None';
export const ColumnValues = {
  NONE: 'None',
  RESELLER: 'resellerId',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  USERNAME: 'username',
  PHONE1: 'phone1',
  EMAIL: 'email',
  PASSWORD: 'password',
  BUSINESS: 'business',
  URL: 'url',
  SALESFORCE_ACCOUNT_ID: 'salesforceAccountId',
  PACKAGE: 'covideoPackageId',
  REFERRAL_AGENT: 'referralAgent',
  ACCOUNT_MANAGER: 'accountManager',
  CRM_PROVIDER: 'crmProviderId',
  MAX_USERS: 'maxUsers',
  VERSION_ACCESS: 'versionAccess',
};
export const CUSTOMER_HEADER_REG_VALUES = [
  { field: ColumnValues.RESELLER, reg: RESELLER_REG },
  { field: ColumnValues.FIRST_NAME, reg: CUSTOMER_FIRST_NAME_REG },
  { field: ColumnValues.LAST_NAME, reg: LAST_NAME_REG },
  { field: ColumnValues.USERNAME, reg: USER_NAME_REG },
  { field: ColumnValues.PHONE1, reg: PHONE_REG },
  { field: ColumnValues.EMAIL, reg: EMAIL_REG },
  { field: ColumnValues.PASSWORD, reg: PASSWORD_REG },
  { field: ColumnValues.BUSINESS, reg: BUSINESS_REG },
  { field: ColumnValues.URL, reg: URL_REG },
  { field: ColumnValues.SALESFORCE_ACCOUNT_ID, reg: SF_ACCOUNT_ID_REG },
  { field: ColumnValues.PACKAGE, reg: PACKAGE_REG },
  { field: ColumnValues.REFERRAL_AGENT, reg: REFERRAL_AGENT_REG },
  { field: ColumnValues.ACCOUNT_MANAGER, reg: ACCOUNT_MANAGER_REG },
  { field: ColumnValues.CRM_PROVIDER, reg: CRM_PROVIDER_REG },
  { field: ColumnValues.MAX_USERS, reg: USER_SEATS_REG },
  { field: ColumnValues.VERSION_ACCESS, reg: VERSION_ACCESS_REG },
];

export const CustomerFieldOptions = [
  { value: ColumnValues.NONE, label: 'Choose...' },
  { value: ColumnValues.RESELLER, label: 'reseller' },
  { value: ColumnValues.FIRST_NAME, label: 'First Name' },
  { value: ColumnValues.LAST_NAME, label: 'Last Name' },
  { value: ColumnValues.BUSINESS, label: 'Business' },
  { value: ColumnValues.USERNAME, label: 'Username' },
  { value: ColumnValues.PHONE1, label: 'Phone Number' },
  { value: ColumnValues.EMAIL, label: 'Email' },
  { value: ColumnValues.PASSWORD, label: 'Password' },
  { value: ColumnValues.URL, label: 'URL' },
  { value: ColumnValues.SALESFORCE_ACCOUNT_ID, label: 'Salesforce Account Id' },
  { value: ColumnValues.PACKAGE, label: 'Package' },
  { value: ColumnValues.REFERRAL_AGENT, label: 'Referral Agent' },
  { value: ColumnValues.ACCOUNT_MANAGER, label: 'Account Manager' },
  { value: ColumnValues.CRM_PROVIDER, label: 'CRM Provider' },
  { value: ColumnValues.MAX_USERS, label: 'Number of User Seats' },
  { value: ColumnValues.VERSION_ACCESS, label: 'Version Access' },
];

export const FieldLength: { [key: string]: number } = {
  firstName: 50,
  lastName: 50,
  business: 100,
  email: 100,
  phone1: 50,
  username: 100,
  password: 50,
};

export enum Views {
  MAPPING = 1,
  NON_IMPORTED_ENTITIES = 2,
  IMPORT = 3,
  LOADING = 5,
  MAPPING_IMPORT = 6,
}

export enum VersionAccess {
  TWO = 2,
  THREE = 3,
  CDS = 4,
}

export const initialCSVData: csvDataProps = { data: [], uploaded: false };
export const initialColOptions: colOptionProps[] = [];
export const initialCustomerColMapping: { [key: string]: string } = {
  reseller: ColumnValues.NONE,
  firstName: ColumnValues.NONE,
  lastName: ColumnValues.NONE,
  username: ColumnValues.NONE,
  phone1: ColumnValues.NONE,
  email: ColumnValues.NONE,
  password: ColumnValues.NONE,
  business: ColumnValues.NONE,
  url: ColumnValues.NONE,
  salesforceAccountId: ColumnValues.NONE,
  covideoPackageId: ColumnValues.NONE,
  referralAgent: ColumnValues.NONE,
  accountManager: ColumnValues.NONE,
  crmProviderId: ColumnValues.NONE,
  maxUsers: ColumnValues.NONE,
  versionAccess: ColumnValues.NONE,
};

export const CUSTOMER_REQUIRED_FIELDS = [
  ColumnValues.FIRST_NAME,
  ColumnValues.LAST_NAME,
  ColumnValues.USERNAME,
  ColumnValues.EMAIL,
  ColumnValues.PASSWORD,
  ColumnValues.PHONE1,
];

export const initialColSampleData: { [key: string]: string[] } = {};
export const initialInvalidRows: { [key: string]: boolean } = {};
export const initialDuplicatedRows: { [key: string]: string[] } = {};

export const parseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
};
