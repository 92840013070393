import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { facebookKeys } from './queryKeys';

export const queryFacebookIntegrationStatus = async () => {
  const response = await EXPRESS_API.get('/facebook/auth/integration');
  return response.data;
};

export const useQueryFacebookIntegrationStatus = () => {
  const { showError } = useToastError();
  return useQuery(
    facebookKeys.facebook_integration_status(),
    () => queryFacebookIntegrationStatus(),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
