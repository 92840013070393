import React from 'react';
import { DocumentHead } from 'lib/components';
import { MultiLocationsRoutes } from './routes';

export const MultiLocationsPages = () => (
  <>
    <DocumentHead title='Multilocations' />
    <MultiLocationsRoutes />
  </>
);
