import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps } from 'react-router';
import { withRouter, useHistory } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { MessageConatiner } from './MessageContainer';
import { MainContainer } from 'lib/components';
import { fetchCustomer, fetchCustomerUser } from 'lib/api';
import { SetDefaultModal } from './SetDefaultModal';
import { Button } from 'react-covideo-common';

interface MatchParams {
  id: string;
}

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${theme.mediaQueryMaxWidth.md} {
    flex-direction: column;
  }
`;

const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 100%;
  .arrow {
    position: relative;
    top: 4px;
    right: 4px;
  }
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
  color: ${theme.palette.primaryDarkBlue75Percent};
`;

const LinkText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const RowChild = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
`;

const UserDetail = styled.div`
  width: 300px;

  font-weight: 800;
  font-size: 24px;
`;

export const MessageLayoutIndividual = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const [showDefaultModal, setShowDefaultModal] = React.useState(false);
    const [chatOwnerName, setChatOwnerName] = React.useState('');
    const [isDefaultUser, setIsDefaultUser] = React.useState(false);
    const [defaultUser, setDefaultUser] = React.useState('');

    const { id } = props.match.params;
    const {
      userData: { customerId },
    } = useAuth();
    const history = useHistory();

    const loadCustomer = async () => {
      const customer = await fetchCustomer(customerId);
      if (customer.defaultSMSUserId) {
        const defaultUser = await fetchCustomerUser(customer.defaultSMSUserId);
        setDefaultUser(defaultUser.firstName || defaultUser.email);
      }
      if (customer && customer.defaultSMSUserId == id) {
        setIsDefaultUser(true);
      } else {
        setIsDefaultUser(false);
      }
    };

    useEffect(() => {
      loadCustomer();
    }, []);

    return (
      <>
        <MainContainer>
          <BreadcrumbsContainer>
            <LinkBtn
              onClick={() => {
                history.push('/sms/overview');
              }}
            >
              SMS
            </LinkBtn>
            <div className='arrow'>
              <MdKeyboardArrowRight size={16} />
            </div>
            <LinkBtn
              onClick={() => {
                history.push('/sms/users');
              }}
            >
              Users
            </LinkBtn>
            <div className='arrow'>
              <MdKeyboardArrowRight size={16} />
            </div>
            <LinkText>{chatOwnerName}</LinkText>
          </BreadcrumbsContainer>
          <Row style={{ marginTop: 10 }}>
            <UserDetail>User Details</UserDetail>
            <RowChild>
              {isDefaultUser ? (
                <Button
                  variant='destructive'
                  text={'Remove as Default User'}
                  onClick={() => {
                    setShowDefaultModal(true);
                  }}
                />
              ) : (
                <Button
                  variant='secondary'
                  text={'Set as Default User'}
                  onClick={() => {
                    setShowDefaultModal(true);
                  }}
                />
              )}
            </RowChild>
          </Row>
          <Content>
            <MessageConatiner id={id} setChatOwnerName={setChatOwnerName} />
          </Content>
          {showDefaultModal && (
            <SetDefaultModal
              handleModalClose={refresh => {
                setShowDefaultModal(false);
                if (refresh) loadCustomer();
              }}
              userId={id}
              chatOwnerName={chatOwnerName}
              isDefaultUser={isDefaultUser}
              customerId={customerId}
              defaultUser={defaultUser}
            />
          )}
        </MainContainer>
      </>
    );
  }
);
