import { getUsersByAutomotiveRoles } from 'lib/api';
import { VideoRequestListFilter } from 'lib/api/videoRequests/useVideoRequestsQuery';
import { Dropdown, Search } from 'lib/components';
import { AutomotiveRole, VideoStatus, VideoType } from 'lib/const';
import { useAuth } from 'lib/context';
import { startCase, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const FiltersWrapper = styled.div`
  display: flex;
`;

const Filter = styled.div`
  margin-left: 12px;
  min-width: 232px;
`;

const videoTypeOptions = Object.values(VideoType).map((videoType: string) => {
  return {
    value: videoType,
    label: videoType ? videoType : 'All video types',
  };
});

const statusOptions = Object.values(VideoStatus).map((status: string) => {
  return {
    value: status,
    label:
      status === VideoStatus.Pending
        ? 'Awaiting Approval'
        : status
          ? startCase(toLower(status))
          : 'All statuses',
  };
});

type Option = {
  value: string | number;
  label: string | number;
};

type Props = {
  filter: VideoRequestListFilter;
  updateFilter: (filter: VideoRequestListFilter) => void;
};

export const Filters = ({ updateFilter, filter }: Props) => {
  const { userData } = useAuth();
  const { customer } = userData;

  const [technicianOptions, setTechnicianOptions] = useState<Option[]>([]);
  const [advisorOptions, setAdvisorOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (customer && customer.customerId) getAssignees();
  }, [customer]);

  const isServiceAdvisor =
    userData.automotiveRole === AutomotiveRole.SERVICE_ADVISOR;
  const isServiceManager =
    userData.automotiveRole === AutomotiveRole.SERVICE_MANAGER;

  const handleTechnicianChange = (userId: string) => {
    updateFilter({
      ...filter,
      userId: userId,
    });
  };

  const handleAdvisorChange = (advisorId: string) => {
    updateFilter({
      ...filter,
      advisorId: advisorId,
    });
  };

  const handleStatusChange = (status: VideoStatus) => {
    updateFilter({
      ...filter,
      statuses: status,
    });
  };

  const handleVideoTypeChange = (videoType: string) => {
    updateFilter({
      ...filter,
      videoType: videoType,
    });
  };

  const handleSearch = (query: string) => {
    updateFilter({
      ...filter,
      search: query,
    });
  };

  const getAssignees = async () => {
    const advisors = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_ADVISOR,
    });

    const advOptions = advisors.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    const managers = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.SERVICE_MANAGER,
    });

    const mngOptions = managers.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    setAdvisorOptions([
      { label: 'All Advisors', value: '' },
      ...advOptions,
      ...mngOptions,
    ]);

    const technicians = await getUsersByAutomotiveRoles({
      customerId: customer.customerId,
      role: AutomotiveRole.TECHNICIAN,
    });

    const techOptions = technicians.map((assignee: any) => {
      return {
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      };
    });

    setTechnicianOptions([
      { label: 'All Technicians', value: '' },
      ...techOptions,
    ]);
  };

  return (
    <FiltersWrapper>
      {isServiceManager && (
        <Filter>
          <Search
            width={'232px'}
            placeholder='Search videos...'
            onSearch={handleSearch}
          />
        </Filter>
      )}
      {isServiceAdvisor && (
        <Filter>
          <Dropdown
            options={statusOptions}
            value={statusOptions.find(o => {
              return o.value === filter.statuses;
            })}
            onChange={(option: any) => handleStatusChange(option.value)}
            placeholder='...'
            creatable={false}
          />
        </Filter>
      )}
      <Filter>
        <Dropdown
          options={technicianOptions}
          value={technicianOptions.find(o => {
            return o.value === filter.userId;
          })}
          onChange={(option: any) => handleTechnicianChange(option.value)}
          placeholder='...'
          creatable={false}
        />
      </Filter>
      {isServiceManager && (
        <Filter>
          <Dropdown
            options={advisorOptions}
            value={advisorOptions.find(o => {
              return o.value === filter.advisorId;
            })}
            onChange={(option: any) => handleAdvisorChange(option.value)}
            placeholder='...'
            creatable={false}
          />
        </Filter>
      )}
      {isServiceAdvisor && (
        <Filter>
          <Dropdown
            options={videoTypeOptions}
            value={videoTypeOptions.find(o => {
              return o.value === filter.videoType;
            })}
            onChange={(option: any) => handleVideoTypeChange(option.value)}
            placeholder='...'
            creatable={false}
          />
        </Filter>
      )}
    </FiltersWrapper>
  );
};
