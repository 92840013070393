import * as React from 'react';
import { useState, useEffect } from 'react';
import { isNull } from 'lodash';
import styled, { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router';
import {
  MdAdd,
  MdRemove,
  MdOutlineArrowBack,
  MdSupervisorAccount,
} from 'react-icons/md';
import dayjs from 'dayjs';
import { theme } from 'lib/style';
import { Modal, LoadingIndicator, RadioInput } from 'lib/components';
import { CheckboxInput } from 'lib/components/inputs/CheckboxInput';
import {
  SubscriptionMapping,
  SubscriptionPrice,
  SubscriptionIndex,
} from 'lib/const';
import { cancelAccountSubscription, updateAccountSubscription } from 'lib/api';
import { preparePrice } from 'lib/utils/invoice';
import { useToastError } from 'lib/hooks';
import { successToast } from 'lib/components/toasts/success';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  subscriptionData?: any;
};

const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: auto;
  background-color: #fff;
  margin: 32px;
`;

const Content = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;
const ContentHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  &.list {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.black_1_100};
  overflow-wrap: break-word;
  max-width: 100%;
  overflow: none;
  &.list--body {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
  }
`;

const CloseButtonWrap = styled.div<{ position?: string }>`
  margin-left: 10px;
  color: ${theme.palette.black_1_100};
  height: 24px;
  width: 24px;
  position: ${props => (props.position ? props.position : 'relative')};
  &.list--bth--wrap {
    left: 0;
    display: flex;
    align-items: center;
    width: 150px;
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 15px;
`;

const Description = styled.label`
  display: flex;
  font-size: 16px;
`;

const Alert = styled.label`
  display: flex;
  color: ${theme.palette.buttonDanger};
  padding-top: 20px;
`;

const ReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 20px;
`;

const ReasonItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Input = styled.input`
  padding: 8px 12px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c8cfd9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const Row = styled.div<{ width?: string }>`
  display: flex;
  width: ${props => props.width || 'auto'};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  &.list--max {
    width: 100%;
  }
  &.list--footer {
    width: 100%;
    max-width: 830px;
  }
  .list--price {
    font-size: 25px;
    color: ${theme.palette.primaryDarkBlue};
  }
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #f6f7f9;
  position: fixed;
  bottom: 0;
  padding: 20px;
  margin-left: -50px;
  .price--btn {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

const HeadTitle = styled.div`
  font-weight: 700;
  color: ${theme.palette.primaryDarkBlue};
  font-size: 20px;

  font-weight: 800;
`;

const PlanContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  .single--plan {
    display: flex;
    position: relative;
    row-gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 380px;
    border: 1px solid #f7f8fa;
    box-sizing: border-box;
    box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
    border-radius: 5px;
    &.active {
      border: 1px solid ${props => props.theme.colors.primary[100]};
      .single--plan--head {
        background: ${props => props.theme.colors.primary[100]};
        color: ${theme.palette.white};
      }
    }
    .plan--name--div {
      font-weight: bold;
      color: ${theme.palette.primaryDarkBlue};
    }
    .single--plan--head {
      position: absolute;
      top: -13px;
      background: #eeeff2;
      border-radius: 4px;
      padding: 0 10px;
      color: #272a32;
      font-size: 12px;
    }
  }
  .annual--desc {
    position: absolute;
    bottom: -30px;
    right: 90px;
  }
`;

const SeatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  .plan--seat--counter {
    width: 140px;
    background: #f2f4f6;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px;
    svg {
      cursor: pointer;
    }
    .minus--div {
      display: flex;
      &.icon--minus {
        opacity: 0.4;
      }
    }
    .icon--minus:hover ~ .manage--user {
      display: flex;
    }
    .manage--user {
      display: none;
      border: 1px solid #f7f8fa;
      box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
      border-radius: 4px;
      background: white;
      width: 250px;
      padding: 10px;
      position: absolute;
      flex-direction: column;
      row-gap: 10px;
      align-items: center;
      top: -102px;
      right: 0px;
      &:hover {
        display: flex;
      }
    }
  }
`;

const InputSeat = styled.input`
  box-shadow: none;
  display: block;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: normal;
  font-size: 16px;
  outline: none !important;
  border: 1px solid #d0d3d9;
  height: 30px;
  border-radius: 4px;
  width: 40px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  &:disabled {
    background: white;
  }
`;

const AddonMainHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .addon--header--left {
    font-weight: 800;
    font-size: 18px;
  }
  .addon--header--right {
    color: ${props => props.theme.colors.primary[100]};
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
  }
`;

const AddonTable = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const AddonHeader = styled.div`
  display: flex;
  padding: 20px 20px 0px 20px;
  .addon--left {
    width: 435px;
  }
  .addon--right {
    display: flex;
    .addon--right--price {
      width: 110px;
      text-align: right;
    }
    .addon--right--quantity {
      width: 95px;
      text-align: right;
    }
    .addon--right--total {
      width: 135px;
      text-align: right;
    }
  }
`;

const AddonBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  background: #f6f7f9;
  padding: 20px;
  .addon--body--list {
    display: flex;
    .black--color {
      color: black;
    }
    .addon--left {
      width: 435px;
    }
    .addon--right {
      display: flex;
      .addon--right--price {
        width: 110px;
        text-align: right;
      }
      .addon--right--quantity {
        width: 95px;
        text-align: right;
      }
      .addon--right--total {
        width: 135px;
        text-align: right;
      }
    }
  }
`;

enum Views {
  LIST = 1,
  LOADING = 2,
  CONFIRM = 3,
  CANCEL = 4,
  PLAN_SWITCH = 5,
}

enum Plans {
  MONTHLY = 1,
  ANNUALLY = 2,
  CANCEL = 3,
}

const reasonOptions = [
  'It doesn’t work how I expected',
  'Technical problems',
  'Don’t need it anymore',
  'Too expensive',
];

export const ManageSubscriptionModal = ({
  handleModalClose,
  subscriptionData,
}: Props) => {
  const { logoUrl } = useLogoUrl('dark');
  const [currentView, setCurrentView] = useState<Views>(Views.LIST);
  const [switchingPlan, setSwitchingPlan] = useState<Plans>(Plans.MONTHLY);
  const [reason, setReason] = useState<any>('');
  const [subscribedAddon, setSubscribedAddon] = useState<any>([]);
  const [seatToSubscribe, setSeatToSubscribe] = useState<any>(0);
  const [nextPlanDate, setNextPlanDate] = useState<any>(null);
  const { showError } = useToastError();
  const {
    view,
    isTrial,
    currentSubscriptionType,
    activateSubscription,
    currentPeriodEnd,
    subscribedAddons,
    totalActiveUsers,
    totalAllowedUsers,
    nextPeriodStart,
  } = subscriptionData;

  const history = useHistory();
  const themes = useTheme();

  const getTotalAmount = () => {
    const planType = getPlanSelected();
    if (planType == Plans.MONTHLY) {
      let price = SubscriptionPrice.monthly * parseInt(seatToSubscribe);

      subscribedAddon.forEach((addon: any) => {
        if (addon.active)
          price +=
            SubscriptionMapping.monthly[SubscriptionIndex[addon.name]].price *
            addon.quantity;
      });
      return price;
    } else {
      let price = SubscriptionPrice.annual * parseInt(seatToSubscribe) * 12;
      subscribedAddon.forEach((addon: any) => {
        if (addon.active)
          price +=
            SubscriptionMapping.annual[SubscriptionIndex[addon.name]].price *
            addon.quantity;
      });
      return price;
    }
  };

  const cancelOrActivateSubscription = async (activate: boolean) => {
    const typeOfPlan =
      currentSubscriptionType == Plans.ANNUALLY ? 'Annual' : 'Monthly';

    const endPeriod = currentPeriodEnd
      ? dayjs(new Date(currentPeriodEnd * 1000)).format(STANDARD_DATE_FORMAT)
      : '--';
    try {
      setCurrentView(Views.LOADING);
      await cancelAccountSubscription({
        active: activate,
        cancellationReason: reason,
      });
      if (!activate) {
        successToast({
          title: `Subscription plan cancelled! Covideo ${typeOfPlan} will be active until ${endPeriod}`,
        });
      } else {
        successToast({ title: `Subscription activated` });
      }

      handleModalClose(true);
    } catch (error) {
      activate ? handleModalClose() : setCurrentView(Views.CANCEL);
      showError(error);
    }
  };

  const updateSubscription = async (plan?: any) => {
    try {
      setCurrentView(Views.LOADING);
      const planType = getPlanSelected();
      await updateAccountSubscription({
        quantity: seatToSubscribe,
        interval: plan
          ? plan == Plans.MONTHLY
            ? 'month'
            : 'year'
          : planType == Plans.MONTHLY
            ? 'month'
            : 'year',
      });
      const oldPlan = plan == Plans.MONTHLY ? 'Monthly' : 'Annual';
      const newPlan = planType == Plans.MONTHLY ? 'monthly' : 'annual';
      const endPeriod = currentPeriodEnd
        ? dayjs(new Date(currentPeriodEnd * 1000)).format(STANDARD_DATE_FORMAT)
        : '--';
      successToast({
        title: plan
          ? `Subscription plan won’t change! Covideo ${oldPlan} will
          stay as your active plan.`
          : `Subscription plan updated!  Covideo ${newPlan} will
          will be active from ${endPeriod}.`,
      });

      if (plan) {
        setNextPlanDate(null);
        setSwitchingPlan(plan);
        setCurrentView(Views.LIST);
      } else {
        handleModalClose(true);
      }
    } catch (error) {
      setCurrentView(Views.LIST);
      showError(error);
    }
  };

  const getPlanSelected = () => {
    if (
      (switchingPlan == Plans.MONTHLY && isNull(nextPlanDate)) ||
      (currentSubscriptionType == Plans.ANNUALLY && !isNull(nextPlanDate))
    ) {
      return Plans.MONTHLY;
    } else if (
      (switchingPlan == Plans.ANNUALLY && isNull(nextPlanDate)) ||
      (currentSubscriptionType == Plans.MONTHLY && !isNull(nextPlanDate))
    ) {
      return Plans.ANNUALLY;
    }
    return switchingPlan;
  };

  useEffect(() => {
    if (currentSubscriptionType) {
      setSwitchingPlan(
        currentSubscriptionType == Plans.ANNUALLY
          ? Plans.ANNUALLY
          : Plans.MONTHLY
      );
    }
  }, [currentSubscriptionType]);

  useEffect(() => {
    if (activateSubscription) {
      cancelOrActivateSubscription(true);
    }
  }, [activateSubscription]);

  useEffect(() => {
    if (view) {
      setCurrentView(view);
    }
    if (subscribedAddons.length) {
      setSubscribedAddon(subscribedAddons);
    } else {
      setSubscribedAddon([]);
    }
    setSeatToSubscribe(totalAllowedUsers);
  }, []);

  useEffect(() => {
    setNextPlanDate(nextPeriodStart);
  }, [nextPeriodStart]);

  const List = (
    <ModalItem>
      <Content style={{ marginBottom: '100px', userSelect: 'none' }}>
        <ContentHeaderWrap className='list'>
          <CloseButtonWrap
            onClick={() => handleModalClose(true)}
            className='list--bth--wrap'
            title={'Close'}
            position='absolute'
          >
            <MdOutlineArrowBack size={24} color={theme.palette.label} />
            Back to Billing
          </CloseButtonWrap>
          <ContentHeader>
            <img src={logoUrl} alt='dark logo' />
          </ContentHeader>
        </ContentHeaderWrap>
        <ContentBody className='list--body'>
          <Row>
            <HeadTitle>Covideo Subscription</HeadTitle>
          </Row>
          <Row width='800px'>
            <PlanContainer>
              <div
                className={`single--plan ${
                  (switchingPlan == Plans.MONTHLY && isNull(nextPlanDate)) ||
                  (currentSubscriptionType == Plans.ANNUALLY &&
                    !isNull(nextPlanDate))
                    ? 'active'
                    : ''
                }`}
              >
                <RadioInput
                  name={'planMonthly'}
                  value={Plans.MONTHLY}
                  text={<div className='plan--name--div'>Covideo Monthly</div>}
                  checkedColor={themes.colors.primary[100]}
                  checked={
                    (switchingPlan == Plans.MONTHLY && isNull(nextPlanDate)) ||
                    (currentSubscriptionType == Plans.ANNUALLY &&
                      !isNull(nextPlanDate))
                  }
                  onClick={() => {
                    currentSubscriptionType == Plans.MONTHLY &&
                    !isNull(nextPlanDate)
                      ? setCurrentView(Views.PLAN_SWITCH)
                      : setSwitchingPlan(Plans.MONTHLY);
                  }}
                  onChange={() => {
                    currentSubscriptionType == Plans.MONTHLY &&
                    !isNull(nextPlanDate)
                      ? setCurrentView(Views.PLAN_SWITCH)
                      : setSwitchingPlan(Plans.MONTHLY);
                  }}
                />
                <div>
                  <span className='list--price'>
                    ${SubscriptionPrice.monthly}
                  </span>{' '}
                  /user/month
                </div>
                {currentSubscriptionType == Plans.MONTHLY ? (
                  <div className='single--plan--head'>
                    <b>CURRENT PLAN</b>
                  </div>
                ) : null}
                {currentSubscriptionType == Plans.ANNUALLY &&
                !isNull(nextPlanDate) ? (
                  <div className='single--plan--head'>
                    <b>
                      From{' '}
                      {dayjs(new Date(nextPlanDate * 1000)).format(
                        STANDARD_DATE_FORMAT
                      )}
                    </b>
                  </div>
                ) : null}
              </div>
              <div
                className={`single--plan ${
                  (switchingPlan == Plans.ANNUALLY && isNull(nextPlanDate)) ||
                  (currentSubscriptionType == Plans.MONTHLY &&
                    !isNull(nextPlanDate))
                    ? 'active'
                    : ''
                }`}
              >
                <RadioInput
                  name={'planAnnual'}
                  value={Plans.ANNUALLY}
                  text={<div className='plan--name--div'>Covideo Annual</div>}
                  checkedColor={themes.colors.primary[100]}
                  checked={
                    (switchingPlan == Plans.ANNUALLY && isNull(nextPlanDate)) ||
                    (currentSubscriptionType == Plans.MONTHLY &&
                      !isNull(nextPlanDate))
                  }
                  onClick={() => {
                    currentSubscriptionType == Plans.ANNUALLY &&
                    !isNull(nextPlanDate)
                      ? setCurrentView(Views.PLAN_SWITCH)
                      : setSwitchingPlan(Plans.ANNUALLY);
                  }}
                  onChange={() => {
                    currentSubscriptionType == Plans.ANNUALLY &&
                    !isNull(nextPlanDate)
                      ? setCurrentView(Views.PLAN_SWITCH)
                      : setSwitchingPlan(Plans.ANNUALLY);
                  }}
                />
                <div>
                  <span className='list--price'>
                    ${SubscriptionPrice.annual}
                  </span>{' '}
                  /user/month
                </div>
                {currentSubscriptionType == Plans.ANNUALLY ? (
                  <div className='single--plan--head'>
                    <b>CURRENT PLAN</b>
                  </div>
                ) : currentSubscriptionType == Plans.MONTHLY &&
                  isNull(nextPlanDate) ? (
                  <div className='single--plan--head'>
                    <b>
                      SAVE{' '}
                      {preparePrice(
                        240 * 100 * parseInt(seatToSubscribe),
                        'USD',
                        true
                      )}
                    </b>
                  </div>
                ) : null}
              </div>
              <div className='annual--desc'>$588 per user billed yearly</div>
            </PlanContainer>
          </Row>
          <Row>
            <SeatContainer>
              <div className='plan--seat'>Seats</div>
              <div className='plan--seat--counter'>
                <div
                  className={
                    seatToSubscribe == totalActiveUsers
                      ? 'minus--div icon--minus'
                      : 'minus--div'
                  }
                >
                  <MdRemove
                    size={24}
                    color={theme.palette.black_1_100}
                    onClick={() =>
                      seatToSubscribe == totalActiveUsers
                        ? null
                        : setSeatToSubscribe(seatToSubscribe - 1)
                    }
                  />
                </div>
                <InputSeat
                  name='seats'
                  type='text'
                  disabled
                  value={seatToSubscribe}
                  onClick={e => e.stopPropagation()}
                  onChange={() => {}}
                />
                <MdAdd
                  size={24}
                  color={theme.palette.black_1_100}
                  onClick={() => setSeatToSubscribe(seatToSubscribe + 1)}
                />
                <div className='manage--user'>
                  <div style={{ textAlign: 'center' }}>
                    You can’t have less seats than currently active users (
                    {totalActiveUsers}).
                  </div>
                  <Button
                    variant='secondary'
                    icon={<MdSupervisorAccount />}
                    text={`Manage Users`}
                    onClick={() => {
                      history.push('/users/list');
                    }}
                  />
                </div>
              </div>
            </SeatContainer>
          </Row>
          <Row width='800px'>
            <AddonMainHeader>
              <div className='addon--header--left'>Add-ons Summary</div>
              <div
                className='addon--header--right'
                onClick={() => history.push('/profile/add-ons')}
              >
                Manage Add-ons
              </div>
            </AddonMainHeader>
          </Row>
          <Row width='800px'>
            <AddonTable>
              <AddonHeader>
                <div className='addon--left'>Item</div>
                <div className='addon--right'>
                  <div className='addon--right--price'>
                    {getPlanSelected() == Plans.MONTHLY ? 'Monthly' : 'Annual'}{' '}
                    price
                  </div>
                  <div className='addon--right--quantity'>Quantity</div>
                  <div className='addon--right--total'>Total</div>
                </div>
              </AddonHeader>
              <AddonBody>
                {subscribedAddon.map((e: any) => {
                  const planType = getPlanSelected();
                  const addon =
                    SubscriptionMapping[
                      planType == Plans.ANNUALLY ? 'annual' : 'monthly'
                    ][SubscriptionIndex[e.name]];
                  return (
                    <div
                      className='addon--body--list'
                      key={`addon-item-${e.name}`}
                    >
                      <div className='addon--left black--color'>
                        {addon.title}
                      </div>
                      <div className='addon--right'>
                        <div className='addon--right--price'>
                          {addon.symbol + addon.price}
                        </div>
                        <div className='addon--right--quantity'>
                          {e.quantity} {addon.type}
                        </div>
                        <div className='addon--right--total black--color'>
                          <b>
                            {preparePrice(
                              addon.price * e.quantity * 100,
                              'USD',
                              true
                            )}
                          </b>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </AddonBody>
            </AddonTable>
          </Row>
        </ContentBody>
        <ContentFooter>
          <Row className='list--footer'>
            <Button
              text='Cancel Subscription'
              variant='transparent'
              onClick={() => {
                setCurrentView(Views.CANCEL);
              }}
            />
            <div className='price--btn'>
              <div>
                {switchingPlan == Plans.MONTHLY ? 'monthly' : 'annual'}{' '}
                <b>{preparePrice(getTotalAmount() * 100, 'USD', true)}</b>
              </div>
              <Button
                text='Update Subscription'
                onClick={() => {
                  currentSubscriptionType !== switchingPlan
                    ? setCurrentView(Views.CONFIRM)
                    : updateSubscription();
                }}
              />
            </div>
          </Row>
        </ContentFooter>
      </Content>
    </ModalItem>
  );

  const ConfirmView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>
            Switch to {switchingPlan == Plans.MONTHLY ? 'Monthly' : 'Annual'}{' '}
            Subscription?
          </ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          {switchingPlan !== Plans.MONTHLY ? 'Monthly' : 'Annual'} Subscription
          will remain active until{' '}
          <b>
            {currentPeriodEnd
              ? dayjs(new Date(currentPeriodEnd * 1000)).format(
                  STANDARD_DATE_FORMAT
                )
              : '--'}
          </b>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          variant='secondary'
          text={`Cancel`}
          onClick={() => setCurrentView(Views.LIST)}
        />
        <Button
          text={`Activate ${
            switchingPlan == Plans.MONTHLY ? 'Monthly' : 'Annual'
          }`}
          onClick={() => updateSubscription()}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const CancelView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          {isTrial ? (
            <ContentHeader>Cancel Free Trial?</ContentHeader>
          ) : (
            <ContentHeader>Cancel Subscription?</ContentHeader>
          )}
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              onClick={() => handleModalClose()}
              size={24}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </ContentHeaderWrap>
        <ContentBody>
          <Description>
            {isTrial ? (
              <>
                Your free trial will remain active until{' '}
                {currentPeriodEnd
                  ? dayjs(new Date(currentPeriodEnd * 1000)).format(
                      STANDARD_DATE_FORMAT
                    )
                  : '--'}
                .
              </>
            ) : (
              <>
                Your{' '}
                {currentSubscriptionType == Plans.MONTHLY
                  ? 'monthly'
                  : 'annual'}{' '}
                subscription will remain active until &nbsp;
                <b>
                  {currentPeriodEnd
                    ? dayjs(new Date(currentPeriodEnd * 1000)).format(
                        STANDARD_DATE_FORMAT
                      )
                    : '--'}
                </b>
                .
              </>
            )}
          </Description>
          <Alert>
            What’s the reason you’re cancelling{' '}
            {isTrial ? 'free trial' : 'subscription'}?
          </Alert>
          <ReasonContainer>
            {reasonOptions.map((reasonItem, i) => (
              <ReasonItem key={`reason-item-${i}`}>
                <CheckboxInput
                  blueCheck={true}
                  checkGroupIndicator={false}
                  checked={reason == reasonItem}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                  }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                    let { checked } = event.target as HTMLInputElement;
                    if (checked) setReason(reasonItem);
                    else setReason('');
                  }}
                />
                {reasonItem}
              </ReasonItem>
            ))}
            <ReasonItem>
              <Input
                type='text'
                value={reasonOptions.includes(reason) ? '' : reason}
                placeholder='Or enter your own'
                onChange={event => {
                  setReason(event.target.value);
                }}
              />
            </ReasonItem>
          </ReasonContainer>
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          variant='secondary'
          text={isTrial ? `Stay on Free Trial` : `No, Keep Subscription`}
          onClick={() => setCurrentView(Views.LIST)}
        />
        <Button
          variant='red'
          disabled={!reason.length}
          text='Yes, Cancel Subscription'
          onClick={() => cancelOrActivateSubscription(false)}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const SwitchView = (
    <ModalItem>
      <Content style={{ width: 600 }}>
        <ContentHeaderWrap>
          <ContentHeader>
            Stay on{' '}
            {currentSubscriptionType == Plans.ANNUALLY ? 'Annual' : 'Monthly'}{' '}
            Plan?
          </ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          Current subscription will remain active, and won’t be changed.
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          variant='secondary'
          text={`No, Switch to ${
            currentSubscriptionType == Plans.MONTHLY ? 'Annual' : 'Monthly'
          } from ${dayjs(new Date(nextPlanDate * 1000)).format(
            STANDARD_DATE_FORMAT
          )}`}
          onClick={() => setCurrentView(Views.LIST)}
        />
        <Button
          text={`Yes, Stay on ${
            currentSubscriptionType == Plans.ANNUALLY ? 'Annual' : 'Monthly'
          }`}
          onClick={() => {
            updateSubscription(currentSubscriptionType);
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );

  const LoadingView = (
    <ModalItem>
      <Content style={{ width: 200 }}>
        <ContentHeaderWrap>
          <ContentHeader></ContentHeader>
        </ContentHeaderWrap>
        <ContentBody>
          <LoadingIndicator isLoading={true} text='Processing...' />
        </ContentBody>
      </Content>
    </ModalItem>
  );

  let CurrentVievComponent = null;
  switch (currentView) {
    case Views.LIST:
      CurrentVievComponent = List;
      break;
    case Views.LOADING:
      CurrentVievComponent = LoadingView;
      break;
    case Views.CONFIRM:
      CurrentVievComponent = ConfirmView;
      break;
    case Views.CANCEL:
      CurrentVievComponent = CancelView;
      break;
    case Views.PLAN_SWITCH:
      CurrentVievComponent = SwitchView;
      break;
  }
  return (
    <Modal
      maxHeight={
        currentView == Views.CONFIRM
          ? '200px'
          : currentView == Views.LIST
            ? '100%'
            : currentView == Views.CANCEL
              ? 'auto'
              : undefined
      }
      widthOnDesktop={currentView == Views.LIST ? '100%' : undefined}
      overrideBodyHeight={true}
    >
      {CurrentVievComponent}
    </Modal>
  );
};
