import React from 'react';
import styled from 'styled-components/macro';

type ComponentProps = {
  content: string;
  fontSize: string;
};

interface ScrollProps {
  fontSize: string;
}

const ScrollContainer = styled.div<ScrollProps>`
  overflow-wrap: break-word;
  max-width: 600px;
  font-size: ${props => props.fontSize};
`;

export const Scroller = ({ content, fontSize }: ComponentProps) => {
  React.useEffect(() => {}, []);
  return (
    <>
      <ScrollContainer fontSize={fontSize}>{content}</ScrollContainer>
    </>
  );
};
