import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { Contact } from '../types';
import { contactsKeys } from './queryKeys';

export const updateContact = async (data: any) => {
  const response = await EXPRESS_API.put(`/contacts/${data.contactId}`, data);
  return response.data;
};

export const useUpdateContactMutation = (
  contactId: number | string,
  onSuccess?: (contact: Contact) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(updateContact, {
    onSuccess: async (res: Contact) => {
      successToast({
        title: `Contact successfully updated.`,
      });
      if (contactId) {
        await queryClient.invalidateQueries(
          contactsKeys.single_contact(contactId)
        );
      }
      onSuccess && onSuccess(res);
    },
    onError: () => {
      errorToast({
        title: 'An error occurred updating contact, please try again later!',
      });
    },
  });
};
