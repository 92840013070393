import React from 'react';
import styled from 'styled-components/macro';
import { NewModal, Switch } from 'lib/components';
import { editUserExtendTrial } from 'lib/api/superAdminApi';
import { theme } from 'lib/style';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { Button } from 'react-covideo-common';

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ToggleParagraph = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
`;

type Props = {
  user: { id?: number; firstName: string; lastName?: string };
  handleModalClose(refresh: boolean): void;
};

export const UserExtendFreeTrialModal = ({ user, handleModalClose }: Props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  const handleUpdateStatus = async () => {
    if (!user.id) {
      return;
    }

    setLoadingModal(true);
    try {
      const res = await editUserExtendTrial(user.id, 7);
      if (res) {
        successToast({ title: 'Extended Trial successfully' });
      }
    } catch (error) {
      errorToast({ title: `Error while extending trial.` });
      console.log('Error', error);
    }

    handleModalClose(true);
    setLoadingModal(false);
  };

  return (
    <NewModal
      headerText={`Extend Trial for ${
        (user.firstName || '') + ' ' + (user.lastName || '')
      }`}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose(false)}
      style={{
        content: {
          minWidth: '592px',
          paddingLeft: '32px',
        },
      }}
    >
      <div>
        Extend Trial for <span style={{ fontWeight: 600 }}>7</span> days
      </div>

      <SubmitButtonWrapper>
        <ToggleWrapper style={{ width: '100%' }}>
          <ToggleParagraph>I confirm this.</ToggleParagraph>
          <Switch
            id={`user-confirmation`}
            isOn={isConfirmed}
            onColor={theme.palette.covideoOrange100}
            handleToggle={() => {
              setIsConfirmed(prevValue => !prevValue);
            }}
          />
        </ToggleWrapper>

        <Button
          variant='red'
          onClick={handleUpdateStatus}
          text='Extend Trial'
          disabled={loadingModal || !isConfirmed}
        />
      </SubmitButtonWrapper>
    </NewModal>
  );
};
