import { INTEGRATION_TYPE } from 'app/pages/integrations/main/constants';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { integrationsKeys } from './integrations.keys';

export interface Integration {
  name: string;
  description: string;
  imgSrc?: string;
  productFeatureId?: number;
  thumbnail?: string;
  videoSource?: string;
  show: INTEGRATION_TYPE[];
  categories: string[];
}

export interface IntegrationResponse {
  integrationDetails: Integration[];
  integrations: { [key: string]: string };
  filters: { [key: string]: string };
  cdsCategories: { [key: string]: string };
  categories: { [key: string]: string };
}

const getAllIntegrations = async () => {
  return (await EXPRESS_API.get(`integrations`)).data as IntegrationResponse;
};

export const useGetAllIntegrations = () => {
  return useQuery(integrationsKeys.all(), getAllIntegrations);
};
