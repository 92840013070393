import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { contactsKeys } from '../contacts/queryKeys';
import { QueryContactsParams } from '../contacts/types';

export const removeGroupContact = async ({
  contactId,
  groupId,
}: {
  contactId: string[] | string;
  groupId: number;
}) => {
  const response = await EXPRESS_API.delete(`/groups/${groupId}/contact`, {
    data: contactId,
  });
  return response.data;
};

export const useRemoveGroupContactMutation = (
  contactId?: string[] | string,
  onSuccess?: () => void,
  queryParams?: QueryContactsParams
) => {
  const queryClient = useQueryClient();
  return useMutation(removeGroupContact, {
    onError: () => {
      errorToast({
        title:
          'An error occurred removing contact from a group, please try again later!',
      });
    },
    onSuccess: async () => {
      successToast({
        title: 'You have successfully removed contact from a group!',
      });
      if (queryParams) {
        await queryClient.invalidateQueries(contactsKeys.list(queryParams));
      }
      if (contactId && !Array.isArray(contactId)) {
        queryClient.invalidateQueries(contactsKeys.contact_groups(contactId));
      }
      onSuccess && onSuccess();
    },
  });
};
