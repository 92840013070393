import { AxiosError } from 'axios';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { FormikHelpers } from 'formik';
import { conversationsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IEmailParams {
  text: string;
  subject: string;
  conversationId: number;
  attachment?: {
    filename: string;
    type: string;
    url: string;
  };
}

interface IMutationData extends IEmailParams {
  formik: FormikHelpers<IEmailParams>;
}
export interface IResponse {
  customerId: number;
  userId: number;
  text: string;
  conversationId: number;
  integrationType: string;
  emailSubject: string;
  messageId: number;
  createdAt: string;
  attachment: null | {
    filename: string;
    type: string;
    url: string;
  };
}

const sendEmail = async (params: IEmailParams) => {
  const { subject, text, attachment, conversationId } = params;

  const body = {
    subject,
    text,
    attachment,
  };

  const { data } = await EXPRESS_API.post(
    `conversations/${conversationId}/email/send`,
    body
  );

  return data as IResponse;
};

export const useSendEmailMutation = (cb?: any) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();

  return useMutation((data: IMutationData) => sendEmail(data), {
    onSuccess: ({ messageId }, { formik, conversationId }) => {
      successToast({ title: 'Your message is sent successfully!' });
      formik.setFieldValue('text', undefined);
      cb(`${messageId}`);
      formik.resetForm();
      queryClient.invalidateQueries(
        conversationsKeys.detail(`${conversationId}`)
      );
      queryClient.invalidateQueries(conversationsKeys.lists());
    },
    onError: (err: AxiosError) => showError(err),
  });
};
