import { CheckboxInput, Modal } from 'lib/components';
import { MdArrowForward } from 'react-icons/md';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const BodyContainer = styled.div`
  margin-top: 24px;
  font-size: 14px;
  width: 800px;
`;

const Counter = styled.div`
  font-weight: bold;
  margin-top: 10px;
  font-size: 16px;
`;

const ComboboxContainer = styled.div`
  margin-top: 24px;
  height: 300px;
  overflow-y: auto;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-item: center;
  gap: 12px;
  margin-bottom: 12px;

  div {
    line-height: 24px;
    user-select: none;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  width: 100%;
  justify-content: end;
`;

type ModalProps = {
  handleModalClose: () => void;
  customersArray: Array<any>;
  setCustomersArray: (arg1: any) => void;
  onContinue: () => void;
  title: string;
  resetStates: () => void;
};

const MAX_CUSTOMERS = 15;

const SelectCustomersModal = ({
  handleModalClose,
  customersArray,
  setCustomersArray,
  onContinue,
  title,
  resetStates,
}: ModalProps) => {
  const closeWithReset = () => {
    resetStates();
    handleModalClose();
  };

  const { ref } = useOnClickOutside(closeWithReset);
  const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);

  return (
    <Modal>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>{title}</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={closeWithReset}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <BodyContainer>
          {`Select location(s) for which you want to receive the report. ${
            customersArray.length > MAX_CUSTOMERS
              ? `You can select up to ${MAX_CUSTOMERS} locations.`
              : ``
          }`}
          <Counter>
            Selected {customersArray.filter(item => item.checked).length} /{' '}
            {MAX_CUSTOMERS}
          </Counter>
        </BodyContainer>
        <ComboboxContainer>
          {customersArray.length <= MAX_CUSTOMERS && (
            <CheckboxContainer>
              <CheckboxInput
                blueCheck={true}
                checked={isSelectAllClicked}
                onChange={(e: any) => {
                  const newCustomersArray = [...customersArray];

                  newCustomersArray.forEach((item, index) => {
                    newCustomersArray[index].checked = e.target.checked;
                  });
                  setCustomersArray(newCustomersArray);
                  setIsSelectAllClicked(e.target.checked);
                }}
              />
              <div>All Locations</div>
            </CheckboxContainer>
          )}
          {customersArray.map((item, index) => (
            <CheckboxContainer key={index + item.business}>
              <CheckboxInput
                blueCheck={true}
                checked={item.checked}
                onChange={(e: any) => {
                  const newCustomersArray = [...customersArray];
                  const numberChecked = newCustomersArray.filter(
                    item => item.checked
                  ).length;

                  if (
                    numberChecked === MAX_CUSTOMERS &&
                    !newCustomersArray[index].checked
                  )
                    return;

                  newCustomersArray[index].checked = e.target.checked;
                  setCustomersArray(newCustomersArray);
                }}
              />
              <div>{item.business}</div>
            </CheckboxContainer>
          ))}
        </ComboboxContainer>
        <ButtonContainer>
          <Button
            onClick={onContinue}
            disabled={
              !isSelectAllClicked && !customersArray.find(item => item.checked)
            }
            icon={<MdArrowForward size={20} />}
            text='Continue'
          />
        </ButtonContainer>
      </div>
    </Modal>
  );
};

export default SelectCustomersModal;
