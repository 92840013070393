import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { IoMdCard } from 'react-icons/io';
import { IoDocumentTextSharp } from 'react-icons/io5';

import { Tabs } from 'lib/components';
import { Tab } from 'lib/components/tabs';
import { BillingOverview } from './BillingOverview';
import { Invoices } from './Invoices';
import { HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 0;
    nav {
      &:nth-of-type(1) {
        display: flex;
      }
      width: 100%;
      height: 48px;
      > div {
        float: left;
        display: block;
        background-color: ${theme.palette.white};
        border-top: 1px solid ${theme.palette.grayBorder};
        border-left: 1px solid ${theme.palette.grayBorder};
        border-right: 1px solid ${theme.palette.grayBorder};
        height: 48px;
        box-sizing: border-box;
        line-height: 1.5;
        padding-top: 12px;
        &:nth-of-type(1) {
          width: 152px;
        }
        &:nth-of-type(2) {
          width: 115px;
          border-left: none;
          border-right: none;
        }
        &:nth-of-type(3) {
          width: 100px;
        }
        &:nth-of-type(4) {
          flex-grow: 2;
          border-left: none;
          border-right: none;
          border-top: none;
          border-bottom: 1px solid ${theme.palette.grayBorder};
          background-color: #fbfbfb;
          &:hover {
            cursor: default;
          }
          disabled: true;
        }
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

export const ListManager = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const themes = useTheme();

  let tabs: Tab[] = [
    {
      title: 'Subscription',
      component: activeTab == 0 ? <BillingOverview /> : null,
      icon: <IoMdCard color='#9297A2' />,
      iconActive: <IoMdCard color={themes.colors.primary[100]} />,
      inActiveTextColor: 'rgba(146, 151, 162, 1)',
      isEnabled: true,
    },
    {
      title: 'Invoices',
      component: activeTab == 1 ? <Invoices /> : null,
      icon: <IoDocumentTextSharp color='#9297A2' />,
      iconActive: <IoDocumentTextSharp color={themes.colors.primary[100]} />,
      inActiveTextColor: '#9297A2',
      isEnabled: true,
    },
  ];

  return (
    <MainWrapper resetPadding={false}>
      <TabsContainer>
        <HeaderWrapper>
          <Heading>Billing</Heading>
        </HeaderWrapper>
      </TabsContainer>
      <Content>
        <Tabs
          tabs={tabs}
          defaultActiveTab={activeTab}
          onChange={setActiveTab}
        />
      </Content>
    </MainWrapper>
  );
};
