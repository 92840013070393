import React, { useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router';
import { withRouter } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdPlayArrow, MdPause } from 'react-icons/md';
import { Dropdown, LoadingIndicator } from 'lib/components';
import { theme } from 'lib/style';

import { RecordCam } from '../../../../record/recordCam/main/recordCam';
import { Scroller } from 'lib/components/Scroller';
import {
  handleScriptSpeedUsingScriptSize,
  sizeToSpeedRatioMedium,
} from 'lib/const';
import { NotFound } from 'app/pages/notFound/NotFound';
import { decodeAndReplaceText } from 'lib/utils/functions';
import { Button } from 'react-covideo-common';
import { useQueryScript } from 'lib/api/teleprompter/useQueryScript';

const Container = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  margin-top: 32px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  > svg {
    cursor: pointer;
  }
`;

const Title = styled.div`
  margin-left: 16px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  color: ${theme.palette.coal};
`;

const Actions = styled.div`
  display: flex;
`;

const DropdownContainer = styled.div`
  width: 180px;
  margin-right: 12px;
  z-index: 999;
`;

const Teleprompter = styled.div`
  scroll-behavior: smooth;
  box-sizing: border-box;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #fff;
  z-index: 2;
  border-radius: 8px;
  padding: 32px 128px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  white-space: pre-line;
  position: absolute;
  overflow: auto;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  .marquee-container {
    width: 500px;
  }
  .marquee-container:not(.horizontal)::before {
    background: transparent;
  }
  .marquee-container:not(.horizontal)::after {
    background: transparent;
  }
`;

const TeleprompterShadow = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  &.top {
    top: 0;
    bottom: 55%;
    background: linear-gradient(
      180deg,
      rgba(39, 42, 50, 0.54) 0%,
      rgba(39, 42, 50, 0.48) 47.4%,
      rgba(39, 42, 50, 0) 100%
    );
  }
  &.bottom {
    top: 30%;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(39, 42, 50, 0) 0%,
      rgba(39, 42, 50, 0.48) 47.4%,
      rgba(39, 42, 50, 0.54) 100%
    );
  }
`;

const RecordCamWrapper = styled.div`
  margin-top: 32px;
  position: relative;
`;

interface MatchParams {
  scriptId: string;
}

export const Preview = withRouter(
  (props: RouteComponentProps<MatchParams>): React.ReactElement<any> => {
    const [title, setTitle] = React.useState('');
    const [content, setContent] = React.useState('');
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [speed, setSpeed] = React.useState(sizeToSpeedRatioMedium[2]);

    const scrollRef = React.useRef(null);
    const history = useHistory();
    const [scriptPositionState, setScriptPositionState] = React.useState<any>(
      history.location.state
    );

    let interval: any = '';
    const { scriptId } = props.match.params;
    const isEditorPreview = scriptId === 'editor';

    const { data: script, refetch: loadScript } = useQueryScript(
      scriptId,
      !isEditorPreview
    );

    React.useEffect(() => {
      window.onbeforeunload = function () {
        localStorage.removeItem('editor_preview_state');
        localStorage.removeItem('editor_preview_title');
        localStorage.removeItem('editor_preview_content');
        return null;
      };

      return () => {
        window.onbeforeunload = null;
      };
    }, []);

    React.useEffect(() => {
      if (isEditorPreview) {
        const editorPreviewState = localStorage.getItem('editor_preview_state');
        const editorTitle = localStorage.getItem('editor_preview_title');
        const editorContent = localStorage.getItem('editor_preview_content');
        if (editorPreviewState) {
          setScriptPositionState(JSON.parse(editorPreviewState));
        }

        if (editorTitle) {
          setTitle(editorTitle);
        }

        if (editorContent) {
          setContent(editorContent);
        }
      } else {
        loadScript();
      }
    }, [scriptId]);

    React.useEffect(() => {
      if (script) {
        setTitle(script.title || '');
        setContent(decodeAndReplaceText(script.content || ''));
      }
    }, [script]);

    const handleGoToRecordHome = () => {
      history.push('/record/home');
    };

    const showLoading = !isEditorPreview && !script;

    const play = () => {
      animateScroll.scrollMore(20, {
        containerId: 'teleprompter',
        duration: 50,
        smooth: true,
        offset: 50,
      });
    };

    useEffect(() => {
      if (isPlaying) {
        if (interval) clearInterval(interval);
        interval = setInterval(play, speed.value);
        return () => {
          clearInterval(interval);
        };
      }
    }, [isPlaying, speed.value]);

    if (showLoading) {
      return (
        <Container>
          <LoadingIndicator isLoading={showLoading} height='300px' />
        </Container>
      );
    }

    if ((!isEditorPreview && !script) || !scriptPositionState) {
      return <NotFound />;
    }

    return (
      <Container>
        <Content>
          <Header>
            <TitleWrapper>
              <MdKeyboardArrowLeft
                color={theme.palette.label}
                size={24}
                onClick={() => history.goBack()}
              />
              <Title>{title}</Title>
            </TitleWrapper>
            <Actions>
              <DropdownContainer>
                <Dropdown
                  placeholder={'Set teleprompter speed'}
                  value={speed}
                  onChange={newValue => {
                    setSpeed(newValue);
                  }}
                  options={handleScriptSpeedUsingScriptSize(
                    scriptPositionState.size
                  )}
                  height={40}
                />
              </DropdownContainer>
              <Button
                text={`${isPlaying ? 'Pause' : 'Play'} Teleprompter`}
                icon={
                  isPlaying ? <MdPause size={20} /> : <MdPlayArrow size={20} />
                }
                onClick={() => {
                  isPlaying ? setIsPlaying(false) : setIsPlaying(true);
                }}
              />
            </Actions>
          </Header>
          <RecordCamWrapper>
            <TeleprompterShadow className={scriptPositionState.position} />
            <Teleprompter
              ref={scrollRef}
              id='teleprompter'
              style={{
                top: scriptPositionState.position === 'top' ? '0px' : '40%',
                height: '45%',
              }}
            >
              <Scroller
                content={content}
                fontSize={scriptPositionState.size}
              ></Scroller>
            </Teleprompter>
            <RecordCam
              showRecordingButton={false}
              handleGoToRecordHome={handleGoToRecordHome}
            ></RecordCam>
          </RecordCamWrapper>
        </Content>
      </Container>
    );
  }
);
