import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { videoDeletesKeys } from './queryKeys';
import { VideoDeleteResponse } from './types';

export const getVideoDelete = async (): Promise<VideoDeleteResponse> => {
  const response = await EXPRESS_API.get('/video-deletes');
  return response.data || {};
};

export const useVideoDeleteQuery = (enabled: boolean = true) => {
  return useQuery(videoDeletesKeys.get_video_delete(), getVideoDelete, {
    refetchOnMount: true,
    enabled,
  });
};
