import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { TextInput } from 'lib/components';
import { EMAIL_REGEX, NAME_REGEX } from 'lib/utils/regexes';
import { ContactListItem } from 'lib/api';
import { Button } from 'react-covideo-common';
import { MdSave } from 'react-icons/md';

const AddCustomerWrapper = styled.div`
  margin-bottom: 32px;
`;

const Row = styled.div<{
  justifyContent?: string;
  disableLastPassIcon?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  margin-bottom: 8px;
  div {
    display: ${({ disableLastPassIcon }) =>
      disableLastPassIcon ? 'none' : 'block'};
  }
`;

const Input = styled(TextInput)`
  font-family: 'Work Sans';
  width: 372px;
  max-width: 100%;
  margin: 0;
  &:focus {
    border: 1px solid ${theme.palette.gray40};
    box-shadow: none;
  }
`;

type Props = {
  handleSubmit: () => void;
  newCustomerData: ContactListItem | undefined;
  changeCustomerData: (newCustomerData: ContactListItem | undefined) => void;
  handleCancel: () => void;
};
export const AddCustomer = ({
  handleSubmit,
  newCustomerData,
  changeCustomerData,
  handleCancel,
}: Props) => {
  const [firstName, setFirstName] = useState(
    newCustomerData ? newCustomerData.firstName || '' : ''
  );
  const [lastName, setLastName] = useState(
    newCustomerData ? newCustomerData.lastName || '' : ''
  );
  const [email, setEmail] = useState(
    newCustomerData ? newCustomerData.email || '' : ''
  );
  const [phone, setPhone] = useState(
    newCustomerData ? newCustomerData.phone || '' : ''
  );
  const [nameValid, setNameValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);

  const validateEmail = (email: string) => {
    return email === '' || EMAIL_REGEX.test(email);
  };

  const validatePhone = (phone: string) => {
    return phone === '' || phone.length >= 7;
  };

  const validateName = (name: string) => {
    return name === '' || NAME_REGEX.test(name);
  };

  useEffect(() => {
    setNameValid(validateName(firstName));
  }, [firstName]);

  useEffect(() => {
    setPhoneValid(validatePhone(phone));
  }, [phone]);

  useEffect(() => {
    setEmailValid(validateEmail(email));
  }, [email]);

  useEffect(() => {
    changeCustomerData({
      firstName,
      lastName,
      email,
      phone,
      contactId: '',
      companyName: '',
    });
  }, [firstName, lastName, email, phone]);

  return (
    <AddCustomerWrapper>
      <Row disableLastPassIcon={true}>
        <Input
          autoComplete='off'
          onChange={(e: any) => setFirstName(e.target.value)}
          value={firstName}
          margin={'0 0 32px 0'}
          placeholder='First Name'
        />
        <Input
          autoComplete='off'
          onChange={(e: any) => setLastName(e.target.value)}
          value={lastName}
          margin={'0 0 32px 0'}
          placeholder='Last Name'
        />
      </Row>
      <Row disableLastPassIcon={true}>
        <Input
          autoComplete='off'
          onChange={(e: any) => setEmail(e.target.value)}
          value={email}
          margin={'0 0 32px 0'}
          placeholder='Email Address'
        />
        <Input
          autoComplete='off'
          onChange={(e: any) => setPhone(e.target.value)}
          value={phone}
          margin={'0 0 32px 0'}
          placeholder='Phone'
        />
      </Row>
      <Row justifyContent='flex-start'>
        <Button
          text='Save Customer'
          icon={<MdSave size={18} />}
          disabled={
            !nameValid ||
            !emailValid ||
            !phoneValid ||
            (email === '' && phone === '')
          }
          onClick={handleSubmit}
          variant='primary'
        />
        <Button text='Cancel' onClick={handleCancel} variant='secondary' />
      </Row>
    </AddCustomerWrapper>
  );
};
