import { IUserSettings } from 'lib/context/record/types';
import { IVideoQuality, IVideoSettings } from '../types';
const FRAME_RATE = 24;

export const getAudioSettings = (
  userSettings: IUserSettings
): { deviceId: string } | false => {
  const deviceId = userSettings?.audioSource?.value as string | undefined;
  if (!deviceId) {
    return false;
  }
  return { deviceId };
};

const QUALITY_MAP: { [key: string]: IVideoQuality } = {
  low: { width: { ideal: 640 }, height: { ideal: 360 } },
  standard: { width: { ideal: 1280 }, height: { ideal: 720 } },
  high: { width: { ideal: 1920 }, height: { ideal: 1080 } },
};

export const getVideoSettings = (
  userSettings: IUserSettings
): IVideoSettings => {
  const quality = userSettings?.videoQuality
    ? QUALITY_MAP[userSettings.videoQuality]
    : QUALITY_MAP.standard;

  return {
    ...quality,
    frameRate: FRAME_RATE,
    deviceId: !!userSettings?.videoSource?.value
      ? (userSettings?.videoSource?.value as string)
      : undefined,
  };
};
