import styled, { css, keyframes } from 'styled-components/macro';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';

export const TableCell = styled.div<{
  width?: number;
  cursor?: 'pointer' | 'default' | 'auto';
  alignItems?: string;
  textAlign?: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

export const PaginationWrapper = styled.div`
  width: 100%;
`;

export const NormalText = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${theme.palette.gray100};
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SyncAction = styled.div<{
  isRotating?: boolean;
  isLoading?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  border: 1px solid ${theme.palette.blue05};
  background: ${theme.palette.white};
  cursor: pointer;
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  ${({ isRotating }) =>
    isRotating &&
    css`
      svg {
        animation: ${rotateAnimation} 1s linear infinite;
      }
    `}
`;
