import { notificationKeys } from './queryKeys';
import { useToastError } from 'lib/hooks/useToastError';
import { useQuery } from 'react-query';
import { ICombinedUserData } from 'lib/context';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IResponse {
  count: number;
}

const getUnreadNotificationCount = async (): Promise<IResponse> => {
  return (await EXPRESS_API.get(`notification/unReadNotificationCount`))
    .data as IResponse;
};

export const useUnreadNotificationQuery = (
  data: ICombinedUserData | undefined
) => {
  const { showError } = useToastError();
  const isSignUp = window.location.pathname.includes('/signup');

  return useQuery(notificationKeys.count(), getUnreadNotificationCount, {
    onError: showError,
    enabled: !isSignUp && !!data,
  });
};
