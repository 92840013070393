import * as React from 'react';
import styled from 'styled-components/macro';

import { theme } from 'lib/style';

const TableFooterWrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  margin-top: 8px;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: ${theme.palette.white};
  }
`;

const TableFooterNode = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  children: React.ReactNode;
};

export const TableFooter = ({ children }: Props) => {
  return (
    <TableFooterWrapper>
      {React.Children.map(children, reactNode => (
        <TableFooterNode>{reactNode}</TableFooterNode>
      ))}
    </TableFooterWrapper>
  );
};
