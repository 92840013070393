import React from 'react';
import { Dropdown, Search } from 'lib/components';
import styled from 'styled-components/macro';
import { typesOptions } from './const';
import { SearchParams } from './types';

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .dropdown {
    width: 280px;
  }
`;

interface Props {
  setParams: React.Dispatch<React.SetStateAction<SearchParams>>;
  params: SearchParams;
}

export const SearchInputs = ({ setParams, params }: Props) => {
  const { type, search } = params;
  const setSearchParams = (key: string, value: string) => {
    setParams((state: any) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };
  const defaultValue =
    typesOptions.find(({ value }) => value === type) || typesOptions[0];

  return (
    <InputWrapper>
      <Search
        onSearch={value => setSearchParams('search', value)}
        placeholder="Search bin..."
        width="280px"
        prevSearch={search || ''}
      />
      <Dropdown
        creatable={false}
        onChange={item => setSearchParams('type', item.value)}
        options={typesOptions}
        value={defaultValue}
        placeholder="All content types"
        className="dropdown"
      />
    </InputWrapper>
  );
};
