import React from 'react';
import styled from 'styled-components/macro';
import { Field, FieldProps } from 'formik';
import { SelectInput, CheckboxInput } from 'lib/components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FieldLabel,
  FormProps,
  getCheckboxField,
  getNumberInputField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { CustomerStatusOptions } from 'lib/const/SuperAdminOptions';
import { IoMdCalendar } from 'react-icons/io';
import { useCovideoTheme } from 'react-covideo-common';

const InputDateWrapper = styled.div`
  position: relative;
  input {
    padding: 8px 0px 8px 8px;
    border-radius: 4px;
    border: solid 1px #d0d3d9;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #272a32;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  color: #9297a2;
  top: 8px;
  right: 8px;
  z-index: 1;
`;

const CheckboxRow = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const CheckboxLabel = styled.label`
  margin: 0 0 0 12px;

  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgb(39, 42, 50);
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const LicenseSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  const { colors } = useCovideoTheme();
  return (
    <Section>
      <SectionTitle>License</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Active</FieldLabel>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div style={{ width: '184px' }}>
              <Field type='select' name='verified'>
                {({ field }: FieldProps) => {
                  const { value, ...rest } = field;
                  return (
                    <SelectInput
                      {...rest}
                      value={value}
                      disabled={isSubmitting}
                      onChange={event => {
                        const activeValue = parseInt(event.target.value, 10);
                        if (activeValue === 2) {
                          setFieldValue(`activeEndDate`, new Date());
                        } else {
                          setFieldValue(`activeEndDate`, undefined);
                        }
                        setFieldValue(`verified`, activeValue);
                      }}
                    >
                      {CustomerStatusOptions.map(option => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </SelectInput>
                  );
                }}
              </Field>
            </div>
            <div style={{ width: '184px' }}>
              {!!values.activeEndDate && (
                <Field type='date' name='activeEndDate'>
                  {({ field }: FieldProps) => {
                    return (
                      <InputDateWrapper>
                        <IconWrapper>
                          <IoMdCalendar size={24} />
                        </IconWrapper>
                        <DatePicker
                          dateFormat='MM/dd/yyyy'
                          {...field}
                          onChange={(value: Date) => {
                            if (!!value) {
                              setFieldValue(`activeEndDate`, value);
                            }
                          }}
                          selected={values.activeEndDate || new Date()}
                        />
                      </InputDateWrapper>
                    );
                  }}
                </Field>
              )}
            </div>
          </div>
        </Row>
        <Row>
          <FieldLabel>Number of User Seats</FieldLabel>
          {getNumberInputField(
            'maxUsers',
            true,
            values.covideoPackageId !== PackageName.LEGACY &&
              values.covideoPackageId !== PackageName.TEAMS &&
              values.covideoPackageId !== PackageName.ENTERPRISE,
            isSubmitting
          )}
        </Row>

        <Row>
          <FieldLabel>Email Limit</FieldLabel>
          {getNumberInputField('emailsSentLimit', false, false, isSubmitting)}
        </Row>

        <Row>
          <FieldLabel>Version Access</FieldLabel>
          <CheckboxRow>
            <Field name={'versionTwoEnabled'} readonly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <CheckboxWrapper>
                    <CheckboxInput
                      {...field}
                      checked={field.value}
                      onChange={() => {
                        setFieldValue(
                          `versionTwoEnabled`,
                          values.versionTwoEnabled ? 0 : 1
                        );
                      }}
                      disabled={!!values.versionCDSEnabled}
                    />
                    <CheckboxLabel>2.0</CheckboxLabel>
                  </CheckboxWrapper>
                );
              }}
            </Field>
            <Field name={'versionThreeEnabled'} readonly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <CheckboxWrapper>
                    <CheckboxInput
                      {...field}
                      checked={field.value}
                      onChange={() => {
                        setFieldValue(
                          `versionThreeEnabled`,
                          values.versionThreeEnabled ? 0 : 1
                        );
                      }}
                      disabled={!!values.versionCDSEnabled}
                    />
                    <CheckboxLabel>3.0</CheckboxLabel>
                  </CheckboxWrapper>
                );
              }}
            </Field>
            <Field name={'versionCDSEnabled'} readonly={isSubmitting}>
              {({ field }: FieldProps) => {
                return (
                  <CheckboxWrapper>
                    <CheckboxInput
                      {...field}
                      checked={field.value}
                      onChange={() => {
                        if (!values.versionCDSEnabled) {
                          setFieldValue(`covideoPackageId`, PackageName.LEGACY);
                        }

                        setFieldValue(
                          `versionCDSEnabled`,
                          values.versionCDSEnabled ? 0 : 1
                        );
                      }}
                    />
                    <CheckboxLabel>CDS</CheckboxLabel>
                  </CheckboxWrapper>
                );
              }}
            </Field>
          </CheckboxRow>
        </Row>
        {values.covideoPackageId !== PackageName.PRO &&
          values.covideoPackageId !== PackageName.FREE && (
            <Row>
              {getCheckboxField(
                'freeTrial',
                'Enable trial',
                !!values.freeTrial,
                () => {
                  setFieldValue('freeTrial', values.freeTrial ? 0 : 1);
                },
                isSubmitting,
                undefined,
                colors.primary[100]
              )}
            </Row>
          )}
        <Row>
          {getCheckboxField(
            'lesaAccess',
            'Lesa Video',
            !!values.lesaAccess,
            () => {
              setFieldValue('lesaAccess', values.lesaAccess ? 0 : 1);
            },
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>
        {values.covideoPackageId !== PackageName.PRO &&
          values.covideoPackageId !== PackageName.FREE && (
            <Row>
              {getCheckboxField(
                'wheelsTV',
                'Wheels TV',
                !!values.wheelsTV,
                () => {
                  setFieldValue('wheelsTV', values.wheelsTV ? 0 : 1);
                },
                isSubmitting,
                undefined,
                colors.primary[100]
              )}
            </Row>
          )}
        <Row>
          {getCheckboxField(
            'liveAccess',
            'Live Access',
            !!values.liveAccess,
            () => {
              setFieldValue('liveAccess', values.liveAccess ? 0 : 1);
            },
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>
        <Row>
          {getCheckboxField(
            'hasCovideoMagic',
            'AI Enabled',
            !!values.hasCovideoMagic,
            () => {
              setFieldValue('hasCovideoMagic', values.hasCovideoMagic ? 0 : 1);
            },
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>
      </RowWrapper>
    </Section>
  );
};
