import { useQuery } from 'react-query';
import { foldersKeys } from './foldersKeys';
import { ALL_FOLDERS } from 'lib/const/Folders';
import { EXPRESS_API } from 'configs/api/expressApi';

export type Folder = {
  access?: string;
  folderId: number | string;
  name: string;
  isDeletable: boolean;
  userId?: number;
  isFolderShared?: boolean;
  sharedBy?: string;
  includeNewUsers?: boolean;
  folderAccessPermission?: string;
};

export type GetFolderParams = {
  getShared: boolean;
};

type FolderResponse = {
  folders: Folder[];
};

const getFolders = async (
  params?: GetFolderParams
): Promise<FolderResponse> => {
  return (await EXPRESS_API.get(`folders/new`, { params })).data;
};

export const useGetFolders = (params?: GetFolderParams) => {
  return useQuery(foldersKeys.all(), () => getFolders(params), {
    select: (data: FolderResponse) => {
      const folders: Folder[] = (data && data.folders) || [];

      return [
        ...folders,
        {
          folderId: ALL_FOLDERS.value,
          name: ALL_FOLDERS.label,
          isDeletable: false,
        },
      ];
    },
  });
};
