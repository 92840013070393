import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const Counter = styled.button`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  border: none;
  background: none;
  display: block;
  min-width: 70px;
  text-align: left;
  padding: 0;
  margin-top: 14px;
  margin-right: auto;
`;

type Props = {
  count: number;
};
export const CharacterCounter = ({ count }: Props) => {
  const max = 160;
  return (
    <Counter>
      {count}/{max}
    </Counter>
  );
};
