import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { theme } from 'lib/style';
import { useDeleteMutateIDIMSConnection } from 'lib/api';
import { Customer } from 'lib/context';
import { Button } from 'react-covideo-common';

const Content = styled.div`
  width: 600px;
  line-height: 28px;
  color: #272a32;
  margin-bottom: 32px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 22px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  connection: Pick<Customer, 'business' | 'customerId'> | null;
};

export const ModalDeleteIMSConnection = (props: Props) => {
  const { handleModalClose } = props;
  const { mutate, isLoading } =
    useDeleteMutateIDIMSConnection(handleModalClose);
  return (
    <NewModal
      headerText='Delete Connection?'
      headerTextStyle={{
        fontSize: theme.fontSizes.lg,
        lineHeight: '24px',
        color: theme.palette.themeDark,
      }}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      style={{
        content: {
          padding: '32px',
          minWidth: 'auto',
        },
      }}
    >
      <Content>
        Are you sure you want to delete{' '}
        {props.connection ? props.connection.business : ''} connection?
      </Content>
      <Footer>
        <Button
          text='Yes, Delete Connection'
          variant='destructive'
          disabled={isLoading}
          onClick={async () => {
            if (props.connection) {
              mutate(props.connection.customerId);
            }
          }}
        />
        <Button
          text='No, Keep Connection'
          variant='secondary'
          onClick={async () => {
            handleModalClose();
          }}
        />
      </Footer>
    </NewModal>
  );
};
