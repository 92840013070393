import React, { useState } from 'react';
import { useGetMostViewedVideosQuery } from 'lib/api/metrics/queryTopVideos';
import { DATE_FORMAT } from 'lib/api/repairOrders/const';
import dayjs from 'dayjs';
import { TableScoreboard } from './TableScoreboard';

type MostViewedVideosProps = {
  from: Date;
  to: Date;
  users?: string[];
  label: string;
};

export const MostViewedVideos = ({
  from,
  to,
  users = [],
  label,
}: MostViewedVideosProps) => {
  const [retryCount, setRetryCount] = useState(0);

  const params = {
    from: dayjs(from).format(DATE_FORMAT),
    to: dayjs(to).format(DATE_FORMAT),
    users: users.join(','),
    retryCount,
  };

  const {
    data: videos,
    error,
    isLoading,
    isRefetching,
  } = useGetMostViewedVideosQuery({ params, enabled: true });

  return (
    <TableScoreboard
      data={videos?.map(video => ({
        title: video.title,
        cnt: video.cnt,
      }))}
      label={label}
      setRetryCount={setRetryCount}
      retryCount={retryCount}
      isLoading={isLoading}
      isRefetching={isRefetching}
      error={error}
    />
  );
};
