import styled from 'styled-components/macro';

export const TableContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
  border-radius: 5px;
  padding: 16px 24px;
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Social = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const SocialName = styled.div<{ color: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: ${props => props.color};
`;

export const PublishingDate = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #9297a2;
`;

export const TableBody = styled.div`
  font-family: 'Work Sans';
  color: #272a32;
  display: flex;
  gap: 32px;
  font-weight: 600;
  font-size: 14px;
  width: 272px;
`;

export const TableRow = styled.div<{ last?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 12px;
`;

export const RowName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #9297a2;
`;

export const RowValue = styled.div`
  align-self: start;
  margin-left: 2px;
`;
