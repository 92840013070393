import React from 'react';
import styled from 'styled-components/macro';

const TooltipWrapper = styled.div`
  display: flex;
  gap: 4px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 6px 12px rgba(66, 79, 104, 0.06);
  border-radius: 5px;
  padding: 8px 16px;
`;

const TooltipValue = styled.div`
  color: #272a32;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const TooltipName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #4e5461;
`;

const CustomTooltip = ({
  active,
  payload,
  customName = null,
  pluralActive = false,
  percentage = false,
}: any) => {
  if (active && payload && payload.length) {
    const { value, name } = payload[0].payload;
    const isPlural = pluralActive && value > 1 ? 's' : '';
    return (
      <TooltipWrapper>
        <TooltipValue>{`${value}${percentage ? '%' : ''}`}</TooltipValue>
        <TooltipName>
          {customName ? `${customName}${isPlural}` : name.toLowerCase()}
        </TooltipName>
      </TooltipWrapper>
    );
  }
  return null;
};

export default CustomTooltip;
