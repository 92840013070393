import React from 'react';
import styled from 'styled-components/macro';
import { Search } from 'lib/components';
import { theme } from 'lib/style';
import { MdFileDownload } from 'react-icons/md';
import { Button } from 'react-covideo-common';

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  justify-content: space-between;
`;

type FilterProps = {
  marginRight?: number;
  width?: number;
};
const Filter = styled.div<FilterProps>`
  min-width: 224px;
  width: ${props => `${props.width}px` || 'auto'};
  margin-right: ${props => `${props.marginRight}px` || '0px'};
`;

type Props = {
  onSearch(search: string): void;
  prevSearch?: string;
  downloadOptOutReports(): void;
};

export const SearchAndFilterForOptOuts = ({
  prevSearch,
  onSearch,
  downloadOptOutReports,
}: Props) => {
  return (
    <FiltersContainer>
      <Filter width={536} marginRight={12}>
        <Search
          placeholder='Search by name or email...'
          onSearch={onSearch}
          prevSearch={prevSearch}
        />
      </Filter>
      <Button
        text='Download .csv'
        icon={<MdFileDownload color={theme.palette.covideoBlue100} />}
        onClick={downloadOptOutReports}
        variant='secondary'
      />
    </FiltersContainer>
  );
};
