import { isMessageRead } from './time';

export const countMessageNotifications = (chats: any, userId: string) => {
  const unreadMessagesCount = chats?.reduce(
    (total: number, currentValue: any) => {
      const isUsersChat =
        `${currentValue.userId}` === `${userId}` || +userId === -1;
      if (
        isUsersChat &&
        !isMessageRead(
          currentValue?.lastReadAt,
          currentValue?.lastMessageCreatedAt
        )
      ) {
        return (total += 1);
      }
      return total;
    },
    0
  );

  return unreadMessagesCount;
};
