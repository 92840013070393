import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { smsKeys } from './smsKeys';
import { SmsUsersParams, SmsUsersResponse } from './types';

export const getSmsUsers = async ({
  search = '',
  page = 0,
  size = 10,
  onlyUsersUsingSMS = false,
  sFrom,
  rFrom,
  sTo,
  rTo,
}: SmsUsersParams) => {
  const params = {
    start: page * size,
    limit: size,
    onlyUsersUsingSMS,
    sFrom,
    rFrom,
    sTo,
    rTo,
    ...(search ? { search } : {}),
  };

  const response = await EXPRESS_API.get(`/sms/users`, { params });

  return response.data as SmsUsersResponse;
};

export const useQuerySmsUsers = (params: SmsUsersParams) => {
  const { showError } = useToastError();
  return useQuery(smsKeys.all(), () => getSmsUsers(params), {
    onError: showError,
    refetchOnMount: true,
  });
};
