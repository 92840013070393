import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 108px;
  padding: 8px 12px;
  color: ${theme.palette.gray100};
`;

export const CRMPopup = () => {
  return <Wrapper>AI Assist CRM</Wrapper>;
};
