import * as React from 'react';
import styled from 'styled-components/macro';
import editorCTAIcon from 'assets/images/landingPage/pageCta.svg';
import editorImageIcon from 'assets/images/landingPage/editorImage.svg';
import editorSocialIcon from 'assets/images/landingPage/socialLinks.svg';
import editorVideoIcon from 'assets/images/landingPage/videoPlayer.svg';
import editorTextIcon from 'assets/images/landingPage/editorText.svg';
import editorHeaderIcon from 'assets/images/landingPage/header.svg';
import draganddropIcon from 'assets/images/landingPage/dragndrop.svg';
import { useLandingPageBuilderContext } from '../context';
import { defaultDisplayNamesForSections } from './Constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface ListItemProps {
  beforeIconImage?: any;
  afterIconImage?: any;
  beforeHoverIconImage?: any;
  dataLinkId?: string;
  onHover?: any;
  sectionKey?: any;
  elementIndex?: any;
}
type keyed = {
  [key: number]: any;
};

const sectionTypeAndIconMapping: keyed = {
  1: editorHeaderIcon,
  2: editorVideoIcon,
  3: editorCTAIcon,
  4: editorSocialIcon,
  5: editorTextIcon,
  6: editorImageIcon,
  7: editorVideoIcon,
  8: editorCTAIcon,
  9: editorImageIcon,
};

const List = styled.ul`
  padding: 0px;
  color: #4e5461;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  list-style-type: none;
`;

const ListItem = styled.li.attrs((props: ListItemProps) => ({
  'data-link-id': props.dataLinkId,
  'data-section-key': props.sectionKey,
  'data-element-index': props.elementIndex,
}))<ListItemProps>`
  padding: 10px 0px 10px 15px;
  color: rgba(0, 27, 83, 0.8);
  font-size: 15px;
  font-weight: 600;
  &::before {
    padding: 10px;
    content: url('${props =>
      props.beforeIconImage ? props.beforeIconImage : ''}');
    vertical-align: middle;
  }
  &:hover::before {
    padding: 10px;
    content: url('${props =>
      props.beforeHoverIconImage
        ? props.beforeHoverIconImage
        : props.beforeIconImage}');
    vertical-align: middle;
  }

  &::after {
    content: url('${props =>
      props.afterIconImage ? props.afterIconImage : ''}');
    vertical-align: middle;
    float: right;
  }

  &:hover {
    background: #eeeff2;
    cursor: pointer;
  }
`;

export const SectionTabContent = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    setElementToEdit,
    setElementSectionKey,
    setElementIndex,
  } = useLandingPageBuilderContext();
  const data = { ...selectedLayoutData };
  const getListItemForSection = (
    sectionType: number,
    id: string,
    displayName: string,
    key: string
  ) => {
    switch (sectionType) {
      case 1: {
        return (
          <ListItem
            key={Date.now() + displayName + key + sectionType}
            beforeIconImage={sectionTypeAndIconMapping[sectionType]}
            beforeHoverIconImage={draganddropIcon}
            afterIconImage={''}
            dataLinkId={''}
            onClick={() => {
              onMouseOutHandler(id);
              setElementToEdit(selectedLayoutData[key]);
              setElementSectionKey(key);
            }}
          >
            {displayName}
            <List>{headerChildList(key, id)}</List>
          </ListItem>
        );
      }
      // @ts-ignore
      case 2:
        return (
          <>
            <ListItem
              onMouseOver={() => onMouseOverHandler(id)}
              onMouseOut={() => onMouseOutHandler(id)}
              key={Date.now() + displayName + key + sectionType}
              beforeIconImage={sectionTypeAndIconMapping[sectionType]}
              beforeHoverIconImage={draganddropIcon}
              dataLinkId={id}
              onClick={() => {
                onMouseOutHandler(id);
                setElementToEdit(selectedLayoutData[key]);
                setElementSectionKey(key);
              }}
            >
              {displayName}
              <List>{NEW_childList(key, id)}</List>
            </ListItem>
          </>
        );
      case 10:
        return (
          <>
            <ListItem
              onMouseOver={() => onMouseOverHandler(id)}
              onMouseOut={() => onMouseOutHandler(id)}
              key={Date.now() + displayName + key + sectionType}
              beforeIconImage={sectionTypeAndIconMapping[sectionType]}
              beforeHoverIconImage={draganddropIcon}
              dataLinkId={id}
              onClick={() => {
                onMouseOutHandler(id);
                setElementToEdit(selectedLayoutData[key]);
                setElementSectionKey(key);
              }}
            >
              {displayName}
              <List>{NEW_childList(key, id)}</List>
            </ListItem>
          </>
        );
      default: {
        return (
          <>
            <ListItem
              onMouseOver={() => onMouseOverHandler(id)}
              onMouseOut={() => onMouseOutHandler(id)}
              key={Date.now() + displayName + key + sectionType}
              beforeIconImage={sectionTypeAndIconMapping[sectionType]}
              beforeHoverIconImage={draganddropIcon}
              dataLinkId={id}
              onClick={() => {
                onMouseOutHandler(id);
                setElementToEdit(selectedLayoutData[key]);
                setElementSectionKey(key);
              }}
            >
              {displayName}
            </ListItem>
          </>
        );
      }
    }
  };

  function headerChildList(key: string, parentId: string) {
    const headerSectionDetails: any = selectedLayoutData[key];
    let childArray: Array<{ [key: string]: any }> =
      headerSectionDetails.childSections;
    if (!childArray || !childArray.length) {
      return <></>;
    }

    const sectionType = headerSectionDetails.sectionType;
    const elementArray = childArray.map(
      (child: { [key2: string]: any }, index: number) => {
        let icon =
          sectionTypeAndIconMapping[child.sectionType] || editorHeaderIcon;
        let id = parentId + '-' + child.sectionType + '-' + index.toString();

        return (
          <ListItem
            onMouseOver={() => onMouseOverHandler(id)}
            onMouseOut={() => onMouseOutHandler(id)}
            key={Date.now() + index + child.sectionType + sectionType}
            beforeIconImage={icon}
            beforeHoverIconImage={sectionType != 1 ? draganddropIcon : null}
            dataLinkId={id}
            sectionKey={key}
            elementIndex={index}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onMouseOutHandler(id);
              setElementToEdit(selectedLayoutData[key].childSections[index]);
              setElementSectionKey(key);
              setElementIndex(index);
            }}
          >
            {child.sectionDisplayName}
          </ListItem>
        );
      }
    );

    return elementArray;
  }

  function NEW_childList(key: string, parentId: string) {
    const headerSectionDetails: any = selectedLayoutData[key];
    let childArray: Array<{ [key: string]: any }> =
      headerSectionDetails.childSections;
    if (!childArray || !childArray.length) {
      return <></>;
    }

    const sectionType = headerSectionDetails.sectionType;
    const elementArray = childArray.map(
      (child: { [key2: string]: any }, index: number) => {
        let icon =
          sectionTypeAndIconMapping[child.sectionType] || editorHeaderIcon;
        let id = parentId + '-' + child.sectionType + '-' + index.toString();

        return (
          <ListItem
            onMouseOver={() => onMouseOverHandler(id)}
            onMouseOut={() => onMouseOutHandler(id)}
            key={Date.now() + index + child.sectionType + sectionType}
            beforeIconImage={icon}
            beforeHoverIconImage={sectionType != 1 ? draganddropIcon : null}
            dataLinkId={id}
            sectionKey={key}
            elementIndex={index}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onMouseOutHandler(id);
              setElementToEdit(selectedLayoutData[key].childSections[index]);
              setElementSectionKey(key);
              setElementIndex(index);
            }}
          >
            {child.sectionDisplayName}
          </ListItem>
        );
      }
    );

    return elementArray;
  }

  const onDragEnd = async (result: any) => {
    if (!result.destination || !result.source) {
      return;
    }

    let sourceIndex = result.source.index + 1;
    let destinationIndex = result.destination.index + 1;
    let temp = data[sourceIndex];
    data[sourceIndex] = data[destinationIndex];
    data[destinationIndex] = temp;
    setSelectedLayoutData(data);
    onMouseOutHandler(result.draggableId);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='sections'>
        {provided => (
          <div
            style={{ width: '100%' }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <List>
              {selectedLayoutData &&
              Object.keys(selectedLayoutData).length > 0 ? (
                Object.keys(selectedLayoutData).map((key, index) => {
                  let sectionDetails = selectedLayoutData[key];
                  const sectionType = sectionDetails.sectionType;
                  let displayName = sectionDetails.sectionDisplayName;
                  if (!displayName) {
                    displayName =
                      defaultDisplayNamesForSections[sectionType] || 'Section';
                  }

                  let id =
                    key.toString() +
                    '-' +
                    sectionType.toString() +
                    '-' +
                    index.toString();

                  // let Element =

                  return (
                    <Draggable draggableId={id} index={index} key={index + 1}>
                      {provided => (
                        <div
                          style={{ width: '100%' }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {getListItemForSection(
                            sectionType,
                            id,
                            displayName,
                            key
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <></>
              )}
            </List>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const onMouseOutHandler = (id: string) => {
  let domElement = document.querySelector(`[id="` + id + `"]`);
  if (domElement) {
    domElement.classList.add('sectionListItem');
    domElement.classList.remove('sectionListItemActive');
  }
};

const onMouseOverHandler = (id: string) => {
  let domElement = document.querySelector(`[id="` + id + `"]`);
  if (domElement) {
    domElement.classList.add('sectionListItemActive');
    domElement.classList.remove('sectionListItem');
  }
};
