import { useAuth } from 'lib/context';
import { useQuery } from 'react-query';
import { repairOrderKeys } from './repairOrderKeys';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import {
  IRepairOrdersParams,
  IRepairOrdersResponse,
  RepairOrderItem,
} from './types';
import { AutomotiveRole } from 'lib/const';
import { DATE_FORMAT, initialState, RO_REFETCH_INTERVAL } from './const';
import { EXPRESS_API } from 'configs/api/expressApi';

const getRepairOrders = async (
  data: IRepairOrdersParams
): Promise<IRepairOrdersResponse> => {
  const params = {
    ...data,
    startDate: dayjs(data.startDate).format(DATE_FORMAT),
    endDate: dayjs(data.endDate).add(1, 'day').format(DATE_FORMAT),
  };

  return (
    await EXPRESS_API.get(`repair-orders`, {
      params,
    })
  ).data as IRepairOrdersResponse;
};

export const useGetRepairOrdersQuery = (data: IRepairOrdersParams) => {
  const { userData } = useAuth();
  const {
    data: result,
    isLoading,
    refetch,
  } = useQuery(repairOrderKeys.repairOrders(), () => getRepairOrders(data), {
    enabled:
      userData.automotiveRole === AutomotiveRole.SERVICE_ADVISOR ||
      userData.automotiveRole === AutomotiveRole.SERVICE_MANAGER,
    refetchInterval: RO_REFETCH_INTERVAL,
  });

  if (result) {
    return {
      data: {
        ...result,
        order: result.repairOrders.map(
          (repairOrder: RepairOrderItem) => repairOrder.repairOrderId
        ),
        repairOrders: keyBy(result.repairOrders, 'repairOrderId'),
      },
      isLoading,
      refetch,
    };
  }
  return { data: initialState, isLoading, refetch };
};
