import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { QueryListParams, EmailBatchItem } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';
import { emailBatchesKeys } from './emailBatchKeys';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '../repairOrders/const';

const getEmailBatches = async ({
  page = 0,
  size = 10,
  search,
  sort,
  startDate,
  endDate,
}: QueryListParams) => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    search: search,
    sort: sort,
    startDate: dayjs(startDate).format(DATE_FORMAT),
    endDate: dayjs(endDate).add(1, 'day').format(DATE_FORMAT),
  };

  const { data } = await EXPRESS_API.get('/email-batches', {
    params,
  });
  return {
    emailBatches: (data?.emailBatches as EmailBatchItem[]) || [],
    count: data?.count || 0,
  };
};

export const useGetEmailBatches = (options: QueryListParams) => {
  const { showError } = useToastError();

  return useQuery(emailBatchesKeys.list(), () => getEmailBatches(options), {
    onError: showError,
    refetchOnMount: true,
  });
};
