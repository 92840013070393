import React, { useRef } from 'react';
import { DetailsRecordingPreview } from './detailsPreview/DetailsPreview';
import { RecordingHeaderWithRouteGuard } from './RecordingHeaderWithRouteGuard';
import { RecorderBody } from './RecorderBody';
import { RECORDING_STATUS } from './types';
import useRecorder from './useRecorder';
import VideoRecorderActions from './VideoRecorderActions';

interface ScreenRecorderProps {
  screenStream: MediaStream;
  stopAllStreams: () => void;
  handleGoToRecordHome: () => void;
  showPreview?: boolean;
  onStartUpladingVoiceOverHandler?: (blob: Blob) => void;
}

export const ScreenRecorder = ({
  screenStream,
  stopAllStreams,
  handleGoToRecordHome,
  showPreview = true,
  onStartUpladingVoiceOverHandler,
}: ScreenRecorderProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const {
    stopRecording,
    pauseRecording,
    startCountDown,
    stopCountDown,
    status,
    recordedBlob,
    countdown,
    timeInSeconds,
  } = useRecorder({
    screenStream,
    stopAllStreams,
    onStartUpladingVoiceOverHandler,
    shouldStartCountdown: true,
    videoRef,
    handleGoToRecordHome,
  });

  if (status === RECORDING_STATUS.DONE && recordedBlob && showPreview) {
    return (
      <DetailsRecordingPreview
        videoBlob={recordedBlob}
        handleGoToRecordHome={handleGoToRecordHome}
      />
    );
  }

  return (
    <>
      <RecordingHeaderWithRouteGuard
        status={status}
        handleGoToRecordHome={handleGoToRecordHome}
      />

      <RecorderBody videoRef={videoRef} countdown={countdown} status={status} />
      <VideoRecorderActions
        startCountDown={startCountDown}
        stopCountDown={stopCountDown}
        pauseRecording={pauseRecording}
        stopRecording={stopRecording}
        status={status}
        timeInSeconds={timeInSeconds}
      />
    </>
  );
};
