export const NOTIFICATION_TYPES = {
  FILES: 'FILES',
  BOARDS: 'BOARDS',
  SALES: 'SALES',
  QUOTES: 'QUOTES',
  CTA: 'CTA',
  VIEW: 'VIEW',
  EMAIL_NOT_DELIVERED: 'EMAIL_NOT_DELIVERED',
  MESSAGES: 'MESSAGES',
  REPLY: 'REPLY',
  REACTIONS_COMMENTS: 'REACTIONS_COMMENTS',
  PUSHED_TO_VDP: 'PUSHED_TO_VDP',
  VIDEO_DELETE: 'VIDEO_DELETE',
  LIVE: 'LIVE',
  REPORTS: 'REPORTS',
  EMAIL_OPENED: 'EMAIL_OPENED',
  VIDEO_REQUEST: 'VIDEO_REQUEST',
};
