import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { guidesKeyes } from './guidesKeyes';
import { Links } from './guidesQuery';

export interface GuideUploadProps {
  guideCategoryId: number | null;
  name: string;
  videoId: number | string;
  description: string | null;
  videoSource: string;
  links: Links[];
  playerBackgroundColor: string;
  playButtonPosition: string;
  playerIconsAndTextColor: string;
}

type UploadedGuide = {
  userId: number;
  customerId: number;
  videoId: number;
  videoSource: string;
  updatedAt: string;
  createdAt: string;
  name: string;
  description: string;
  links: Links[];
  playerBackgroundColor: string;
  playButtonPosition: string;
  playerIconsAndTextColor: string;
  guideCategoryId: number;
};

const createGuides = async (body: GuideUploadProps[]) => {
  const { data } = await EXPRESS_API.post(`guides/bulk`, { payload: body });
  return data;
};

export const useCreateGuides = () => {
  const queryClient = useQueryClient();
  return useMutation((data: GuideUploadProps[]) => createGuides(data), {
    onError: () => {
      errorToast({
        title: 'An error occurred creating guides, please try again later!',
      });
    },
    onSuccess: (res: UploadedGuide[]) => {
      const isPlural = res.length > 1;
      successToast({
        title: `You have successfully created${
          isPlural ? '' : ' a'
        } video guide${isPlural ? 's' : ''}!`,
      });
      queryClient.refetchQueries(guidesKeyes.categorized());
      queryClient.invalidateQueries(guidesKeyes.new_count());
    },
  });
};
