import React from 'react';
import queryString from 'query-string';
import styled from 'styled-components/macro';
import { useHistory, useLocation } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { fetchCustomerUser } from 'lib/api';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { LoadingIndicator } from 'lib/components';

import { Analytics } from '../../../video/videoDetails/main';

const LinkWrapper = styled.div`
  display: flex;
  font-size: 15px;
  color: #001b53cc;
  margin-bottom: 24px;
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const LinkText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

type User = {
  id: number;
  email: string;
  firstName: string;
  lastName: Date;
};

export const VideoDetails = (props: any) => {
  const [userLoading, setUserLoading] = React.useState(true);
  const [videoLoading, setVideoLoading] = React.useState(true);
  const [user, setUser] = React.useState({} as User);
  const [video, setVideo] = React.useState<any>({});
  const { userId, videoId } = props.match.params;

  const history = useHistory();

  React.useEffect(() => {
    setUserLoading(true);
    fetchCustomerUser(userId)
      .then((data: User) => {
        setUser(data);
        setUserLoading(false);
      })
      .catch(error => {
        console.log('error', error);
        setUserLoading(false);
      });
  }, [userId]);

  const fetchVideo = async (id: string) => {
    setVideoLoading(true);
    const data = await getVideo(id);
    if (data) {
      setVideo(data);
    }
    setVideoLoading(false);
  };

  React.useEffect(() => {
    if (videoId) {
      fetchVideo(videoId);
    }
  }, [videoId]);

  const location = useLocation();

  const queryParams: any = queryString.parse(location.search);

  return (
    <>
      {userLoading && (
        <LoadingIndicator isLoading={userLoading} height='300px' />
      )}
      {!userLoading && (
        <div>
          <LinkWrapper>
            <LinkBtn
              onClick={() => history.push(`/reports/users${location.search}`)}
            >
              My Team
            </LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <LinkBtn
              onClick={() =>
                history.push(`/reports/users/${userId}${location.search}`)
              }
            >
              {`${user.firstName} ${user.lastName}`}
            </LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <LinkText>{video.title}</LinkText>
          </LinkWrapper>
          {videoLoading && (
            <LoadingIndicator isLoading={videoLoading} height='300px' />
          )}
          {!videoLoading && (
            <Analytics
              video={video}
              showActionsButtons={true}
              videoId={videoId}
              from={queryParams.start}
              to={queryParams.end}
            />
          )}
        </div>
      )}
    </>
  );
};
