import { ActivateCaptions, ActivateFiles } from 'lib/api/addonsApi';
import { useAuth } from 'lib/context';
import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { AddonAmountList, AddonPricing, HorizontalRule } from './PageElements';
import { ModalActivateSubscription } from 'lib/components/modal/ModalActivateSubscription';
import { Button } from 'react-covideo-common';

type Props = {
  SaveButtonText: string;
  AutoSave?: boolean;
  Type: string;
  Globals: {
    currentTotalUsers: number;
    currentCostPerUser: number;
    active: boolean;
    users: any;
  };
  Update: (
    currentTotalUsers: number,
    currentCostPerUser: number,
    active: boolean,
    users: any
  ) => void;
};

const CaptionsPricingText = styled(AddonPricing)`
  position: initial;
  color: #001b53;
  margin-top: 10px;
`;

const CaptionsPricingCount = styled(AddonAmountList)`
  width: 96px;
  font-size: 16px;
  margin-top: 10px;
`;

const CaptionsPricingAmount = styled(AddonPricing)`
  position: initial;
  color: #001b53;
  font-size: 40px;
  margin-top: 20px;
`;

const CaptionsPricingTotalText = styled(AddonPricing)`
  position: initial;
  color: #001b53;
`;

const SaveButtonContainer = styled.div`
  margin-top: 20px;
`;

const ChangeSubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ManageSeats = ({
  SaveButtonText,
  AutoSave,
  Globals,
  Update,
  Type,
}: Props) => {
  const {
    userData: { trialAccount, customer },
  } = useAuth();

  const domain = window.location.hostname;

  const [maxUsers, setMaxUsers] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [activateModalTitle, setActivateModalTitle] = React.useState('');

  const history = useHistory();

  React.useEffect(() => {
    setMaxUsers(parseInt(customer?.maxUsers || '0', 10));
  }, [customer]);

  const [purchaseCost, setPurchaseCost] = React.useState({
    totalUsers: Globals.currentTotalUsers > 0 ? Globals.currentTotalUsers : 1,
    totalValue:
      Globals.currentTotalUsers > 0
        ? Globals.currentTotalUsers * Globals.currentCostPerUser
        : Globals.currentCostPerUser,
    costPerUser:
      Type === 'files'
        ? 10
        : Globals.currentTotalUsers < 10
          ? domain === 'app.moov.ie'
            ? 25
            : 20
          : Globals.currentTotalUsers >= 10 && Globals.currentTotalUsers < 25
            ? domain === 'app.moov.ie'
              ? 20
              : 15
            : Globals.currentTotalUsers >= 25 && Globals.currentTotalUsers < 50
              ? domain === 'app.moov.ie'
                ? 15
                : 10
              : domain === 'app.moov.ie'
                ? 10
                : 5,
  });

  const onButtonClick = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    if (trialAccount) {
      if (Type === 'captions') {
        setActivateModalTitle('Captions & Translations');
      }
      if (Type === 'files') {
        setActivateModalTitle('Files & Boards');
      }
      return;
    }
    if (AutoSave === undefined || AutoSave) {
      if (Type === 'captions') {
        ActivateCaptions(purchaseCost.totalUsers);
      }
      if (Type === 'files') {
        await ActivateFiles(purchaseCost.totalUsers);
      }
    }

    setLoading(false);
    Update(
      purchaseCost.totalUsers,
      purchaseCost.costPerUser,
      true,
      Globals.users
    );
  };

  const userCountItems = Array(maxUsers)
    .fill(0)
    .map((e, i) => i + 1);

  return (
    <ChangeSubscriptionContainer>
      <CaptionsPricingText>How many seats do you need?</CaptionsPricingText>
      <CaptionsPricingCount
        onChange={e => {
          let user_count = Number(e.target.value);
          let cost = 0;
          if (Type === 'captions') {
            cost =
              user_count < 10
                ? domain === 'app.moov.ie'
                  ? 25
                  : 20
                : user_count >= 10 && user_count < 25
                  ? domain === 'app.moov.ie'
                    ? 20
                    : 15
                  : user_count >= 25 && user_count < 50
                    ? domain === 'app.moov.ie'
                      ? 15
                      : 10
                    : domain === 'app.moov.ie'
                      ? 10
                      : 5;
          } else if (Type === 'files') {
            cost = 10;
          }

          setPurchaseCost({
            totalUsers: user_count,
            totalValue: user_count * cost,
            costPerUser: cost,
          });
        }}
      >
        {userCountItems.map(item => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </CaptionsPricingCount>
      <CaptionsPricingAmount>
        {domain === 'app.moov.ie' ? '£' : '$'}
        {purchaseCost.costPerUser}
      </CaptionsPricingAmount>
      <CaptionsPricingText>per seat per month</CaptionsPricingText>
      <HorizontalRule />
      <CaptionsPricingTotalText>
        {domain === 'app.moov.ie' ? '£' : '$'}
        {purchaseCost.totalValue} in total for {purchaseCost.totalUsers} user
        {purchaseCost.totalUsers > 1 ? 's' : ''}
      </CaptionsPricingTotalText>
      <SaveButtonContainer>
        <Button
          text={SaveButtonText}
          disabled={loading}
          onClick={onButtonClick}
        />
      </SaveButtonContainer>

      {activateModalTitle.length ? (
        <ModalActivateSubscription
          handleModalClose={() => setActivateModalTitle('')}
          handleSubmit={addon =>
            history.push({ pathname: '/upgrade', state: { addon } })
          }
          title={activateModalTitle}
          handleCheckBox={true}
        />
      ) : null}
    </ChangeSubscriptionContainer>
  );
};
