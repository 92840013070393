import React from 'react';
import { ProfilesWrapper } from '../style';
import { InfoBox } from '../InfoBox';
import Linkedin from 'app/pages/linkedinIntegration/Linkedin';
import { Facebook } from 'app/pages/account/facebook/Facebook';
import { Youtube } from '../youtube';
import {
  Container,
  HeaderWrapper,
  MainWrapper,
} from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';

export const Main = () => {
  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <HeaderWrapper>
          <Heading>Social Profiles</Heading>
        </HeaderWrapper>

        <ProfilesWrapper>
          <Facebook />
          <Youtube />
          <Linkedin />
        </ProfilesWrapper>
        <InfoBox />
      </MainWrapper>
    </Container>
  );
};
