import * as React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import TextareaAutosize from 'react-autosize-textarea';

/*** SUS-884 changes ***/
type SaveButtonProps = {
  backgroundColor?: string;
};

type ItemProps = {
  isActive?: boolean;
};

type Cue = {
  orgTime: string;
  displayStartTime: string;
  start: number;
  end: number;
  text: string;
};

interface ComponentProps {
  updateSubtitleText: (cueText: string, index: number) => void;
  index: number;
  currentTime: number;
  cue: Cue;
}

const Row = styled.div<ItemProps>`
  transition: all 0.1s;
  display: flex;
  padding: 8px 14px 8px 14px;
  ${props =>
    props.isActive &&
    css`
      background-color: ${theme.palette.primaryDarkBlue10Percent};
    `}
`;

const Time = styled.div`
  padding-top: 4px;
  display: flex;
  justify-content: center;
  min-width: 63px;
  margin: 0 28px 0 0;
  cursor: default;
`;

const SubtitleDiv = styled.div`
  background-color: transparent !important;
  border: none !important;
  border-radius: 0%;
  width: 100%;
  resize: none;
  font-size: 14px;
  line-height: 1.43;
  padding: 2px;
  color: ${theme.palette.title};
  &:hover {
    + figure {
      opacity: 1;
    }
    cursor: pointer;
  }
`;

const SubtitleText = styled(TextareaAutosize)`
  background-color: white !important;
  border: none !important;
  border-radius: 0%;
  width: 100%;
  height: ${props => (props.height ? props.height : 'auto')};
  resize: none;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.palette.title};
  &:hover {
    + figure {
      opacity: 1;
    }
  }
  &:focus {
    + figure {
      opacity: 1;
    }
    outline: 1px solid #007bff;
    -moz-outline-radius: 0;
  }
`;

const SaveButton = styled.div<SaveButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  width: 59px;
  height: 32px;
  /* Covideo/Orange/100 */
  background-color: ${props => props.backgroundColor}; /*** SUS-884 changes ***/
  border-radius: 5px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 50px;
  margin-left: 5px;
  font-weight: 600;
  color: white;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

export const EditableRow = (props: ComponentProps) => {
  const { cue, index, currentTime, updateSubtitleText } = props;
  const [focus, setFocus] = React.useState(false);
  const [text, setText] = React.useState(cue.text);

  const handleTextAreaChange = () => {
    updateSubtitleText(text, index);
    setFocus(false);
  };

  const handleClick = (ref: React.RefObject<HTMLTextAreaElement>) => {
    setFocus(true);
    const { current } = ref;
    if (current) {
      current.focus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const textAreaRef = React.createRef<HTMLTextAreaElement>();
  const themes = useTheme(); /*** SUS-884 changes ***/
  return (
    <Row isActive={currentTime >= cue.start && currentTime < cue.end}>
      <Time>{cue.displayStartTime}</Time>
      {!focus && (
        <SubtitleDiv onClick={() => handleClick(textAreaRef)}>
          {text}
        </SubtitleDiv>
      )}
      {focus && (
        <SubtitleText
          autoFocus
          ref={textAreaRef}
          onBlur={() => handleTextAreaChange()}
          onChange={handleChange}
          value={text}
        />
      )}
      {focus && (
        /*** SUS-884 changes ***/
        <SaveButton
          backgroundColor={themes.colors.primary[100]}
          onClick={() => handleClick(textAreaRef)}
        >
          Save
        </SaveButton>
      )}
    </Row>
  );
};
