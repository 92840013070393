import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Dropdown, NewModal, RadioInput } from 'lib/components';
import { Formik } from 'formik';
import { ConfirmDelete } from './confirmationModals/ConfirmDelete';
import { ALL_FOLDERS } from 'lib/const/Folders';
import { Button } from 'react-covideo-common';
import { Folder } from 'lib/api/folders/getFolders';

const ContentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
`;

const RadioText = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin: 0;
`;

type Props = {
  handleModalClose: () => void;
  onSubmit: (data: any) => void;
  currentFolder?: Folder;
  folders?: Folder[];
};

export const ModalDeleteFolder = ({
  handleModalClose,
  onSubmit,
  folders,
  currentFolder,
}: Props) => {
  const [displayConfirmationModalBody, setDisplayConfirmationModalBody] =
    useState<boolean>(false);
  const themes = useTheme();

  const hideConfirmationModalBody = () => {
    setDisplayConfirmationModalBody(false);
  };

  const showConfirmationModalBody = () => {
    setDisplayConfirmationModalBody(true);
  };

  return (
    <NewModal
      headerText={
        displayConfirmationModalBody
          ? 'Delete folder and videos'
          : `Delete folder "${currentFolder?.name}"`
      }
      headerTextStyle={{
        fontSize: displayConfirmationModalBody ? '24px' : '20px',
      }}
      hideCloseButtonInHeader={true}
      closeModalOnBackdropOrEscPress={false}
      closeModal={handleModalClose}
    >
      <Formik
        initialValues={{
          delete: true,
          folder: { value: -2, label: 'Primary' },
        }}
        onSubmit={() => {}}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <>
            {!displayConfirmationModalBody && (
              <>
                <div>
                  <RadioInput
                    name={'delete'}
                    value={'#FFFFFF'}
                    text={
                      <RadioText>
                        <b>Delete</b> all videos and the folder
                      </RadioText>
                    }
                    checkedColor={themes.colors.primary[100] || '#FF8B22'}
                    checked={values.delete}
                    onChange={() => {
                      setFieldValue('delete', true);
                    }}
                    onClick={() => setFieldValue('delete', true)}
                  />
                  <RadioInput
                    name={'delete'}
                    value={'#FFFFFF'}
                    text={
                      <RadioText>
                        <b>Move</b> all videos to another folder
                      </RadioText>
                    }
                    checkedColor={themes.colors.primary[100] || '#FF8B22'}
                    checked={!values.delete}
                    onChange={() => {
                      setFieldValue('delete', false);
                    }}
                    onClick={() => setFieldValue('delete', false)}
                  />
                </div>
                {!values.delete && (
                  <div style={{ marginTop: '16px', paddingLeft: '32px' }}>
                    <Dropdown
                      height={40}
                      dropdownHeight={90}
                      onChange={item => setFieldValue('folder', item)}
                      name='folder'
                      className='dropdown'
                      placeholder={'Filter by type...'}
                      value={values.folder}
                      options={folders
                        ?.filter(
                          (f: Folder) =>
                            f.folderId !== currentFolder?.folderId &&
                            f.folderId !== ALL_FOLDERS.value &&
                            !f.access
                        )
                        .map(f => ({
                          value: f.folderId,
                          label: f.name,
                        }))}
                    />
                  </div>
                )}

                <ContentFooter>
                  <Button
                    text={'Cancel'}
                    variant='secondary'
                    onClick={handleModalClose}
                  />
                  <Button
                    text={
                      !values.delete
                        ? 'Move Videos & Delete Folder'
                        : 'Delete folder'
                    }
                    disabled={
                      isSubmitting || (!values.delete && !values.folder)
                    }
                    variant={!values.delete ? 'primary' : 'red'}
                    onClick={showConfirmationModalBody}
                  />
                </ContentFooter>
              </>
            )}
            {displayConfirmationModalBody && (
              <ConfirmDelete
                buttonText={
                  values.delete
                    ? 'Yes, Delete Folder and All Videos'
                    : 'Yes, Delete Folder and Move All Videos'
                }
                message={
                  values.delete
                    ? 'delete the folder and all videos in it?'
                    : `delete the folder and move all videos to ${values.folder.label} folder?`
                }
                onSubmit={async () => {
                  await onSubmit(values);
                  hideConfirmationModalBody();
                }}
                handleModalClose={hideConfirmationModalBody}
              />
            )}
          </>
        )}
      </Formik>
    </NewModal>
  );
};
