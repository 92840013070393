import { fetchCustomerUsers, getAllDepartmentsForCustomer } from 'lib/api';
import { capitalize } from 'lodash';

export const loadFilteringData = async (
  customerId: string,
  setDepartments: (arg1: any[]) => void,
  setUsers: (arg1: any[]) => void
) => {
  // departments
  const departments = await getAllDepartmentsForCustomer(customerId);
  if (departments.data) {
    const formattedDepartments = departments.data.map((item: any) => {
      return { value: item.id, label: capitalize(item.name) };
    });
    setDepartments([
      { value: '', label: 'All Departments' },
      ...formattedDepartments,
    ]);
  }

  const allUserData = await fetchCustomerUsers(customerId);
  if (allUserData.length) {
    const formattedUserList = allUserData.map((item: any) => {
      return {
        value: item.id,
        label: item.lastName + ' ' + item.firstName,
      };
    });
    formattedUserList.sort((a: any, b: any) => a.label.localeCompare(b.label));
    setUsers([{ value: '', label: 'All Users' }, ...formattedUserList]);
  }
};

export const SORTING = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const VIDEO_ATTRIBUTES = 'video-attributes';
