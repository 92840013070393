import { EXPRESS_API } from 'configs/api/expressApi';

export const getAttribute = async (id: number) => {
  const response = await EXPRESS_API.get(`/video-attributes/${id}`);

  return response.data;
};

export const getAttributeValueDetailsMetric = async (
  id: string,
  value: string,
  {
    page = 0,
    size = 10,
    sort,
    startDate,
    endDate,
    department,
    user,
    search,
    videoType,
  }: any
) => {
  const response = await EXPRESS_API.get(
    `metrics/reports/video-attributes-details/${id}/value`,
    {
      params: {
        value,
        start: page * size,
        limit: size,
        sort,
        from: startDate,
        to: endDate,
        department,
        user,
        search,
        videoType,
      },
    }
  );

  return response.data;
};

export const getAttributeValueMetrics = async (id: string, params?: any) => {
  const response = await EXPRESS_API.get(
    `metrics/reports/video-attributes-details/${id}`,
    { params }
  );

  return response.data;
};

export const getAttributeValueRecord = async (id: string) => {
  const response = await EXPRESS_API.get(`video-attributes/value/${id}`);

  return response.data;
};
