import { TIMEFRAME } from '../types';
import { timeRangeConstants } from '../const';
import { FREQUENCY_TYPE } from '../frequency/constants';

export const customReportsDayOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map(day => ({
  value: day.toLowerCase(),
  label: day.substring(0, 3),
}));

const isToday = (range: string) => {
  return (
    range === timeRangeConstants.TODAY || range === timeRangeConstants.YESTERDAY
  );
};

const isWeek = (range: string) => {
  return (
    range === timeRangeConstants.LAST_WEEK ||
    range === timeRangeConstants.LAST_7_DAYS
  );
};

const isMonth = (range: string) => {
  return (
    range === timeRangeConstants.LAST_MONTH ||
    range === timeRangeConstants.MONTH_TO_DATE ||
    range === timeRangeConstants.LAST_30_DAYS
  );
};

const isYear = (range: string) => {
  return range === timeRangeConstants.YEAR_TO_DATE;
};

const isCustom = (range: string) => {
  return range === timeRangeConstants.CUSTOM;
};

export const getDefaultTimeframeValue = (range: string) => {
  if (isToday(range)) {
    return TIMEFRAME.DAY;
  }
  if (isWeek(range)) {
    return TIMEFRAME.WEEK;
  }
  if (isMonth(range)) {
    return TIMEFRAME.MONTH;
  }
  if (isYear(range)) {
    return TIMEFRAME.YEAR;
  }
  if (isCustom(range)) {
    return TIMEFRAME.CUSTOM;
  }
  return TIMEFRAME.DAY;
};

export const getDefaultFrequencyType = (frequency: string) => {
  if (frequency.includes(FREQUENCY_TYPE.DAILY)) {
    return FREQUENCY_TYPE.DAILY;
  }
  if (frequency.includes(FREQUENCY_TYPE.WEEKLY)) {
    return FREQUENCY_TYPE.WEEKLY;
  }
  if (frequency.includes(FREQUENCY_TYPE.MONTHLY)) {
    return FREQUENCY_TYPE.MONTHLY;
  }
  return FREQUENCY_TYPE.DAILY;
};
