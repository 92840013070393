import { EXPRESS_API } from 'configs/api/expressApi';
import { useState } from 'react';
import { useMutation } from 'react-query';

interface EncodeData {
  videoId: string;
  customerId: string;
}

interface EncodeVideoResponse {
  success: boolean;
  job_id: number;
}
interface IProgress {
  loaded: number;
  total: number;
  percentage: number;
}

const encodeVideo = async (
  data: EncodeData,
  onProgress?: (progress: IProgress) => void
): Promise<EncodeVideoResponse> => {
  const { videoId } = data;
  const config = {
    onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
      if (onProgress) {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        onProgress({
          loaded: Math.floor(loaded / 1024),
          total: Math.floor(total / 1024),
          percentage: percentCompleted,
        });
      }
    },
  };

  const response = await EXPRESS_API.post(
    `/videos/${videoId}/encode`,
    {},
    config
  );
  return response.data;
};

export const useEncodeVideoMutation = () => {
  const [encodeProggress, setEncodeProggress] = useState({
    loaded: 0,
    total: 0,
    percentage: 0,
  });

  return {
    ...useMutation((data: EncodeData) => encodeVideo(data, setEncodeProggress)),
    progress: encodeProggress,
  };
};
