import React from 'react';
import profileInfoSkeleton from 'assets/images/profile/infoSkeleton.webp';
import profileImagePlaceholder from 'assets/images/profile/imagePlaceholder.webp';
import { MdEmail, MdPhone } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';
import { useHistory } from 'react-router';
import { Button } from 'react-covideo-common';
import { useQueryContact } from 'lib/api/contacts/useQueryContact';

const ProfileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 296px;
  width: 100%;
  gap: 8px;
  padding: 24px;
  box-sizing: border-box;
`;

const CommonParagraphStyles = css`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  text-align: center;

  margin: 0 0 27px 0;

  color: #001b53;
`;

const ProfileFullName = styled.p`
  ${CommonParagraphStyles}

  margin:0 0 27px 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;

  margin: 0px -24px 16px -24px;
  padding: 0 24px;
  background: #f2f4f6;
`;

const MoreIDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 11px;
  justify-content: flex-start;
  > p {
    ${CommonParagraphStyles};
    margin: 0;
  }
`;

const UserChatProfileInfo = ({
  contactId,
  isActive,
}: {
  contactId: number | undefined | null;
  isActive: boolean;
}) => {
  const history = useHistory();

  const { data } = useQueryContact(
    contactId?.toString() ?? '',
    !!contactId,
    500_000_000
  );

  if (!isActive) {
    return (
      <div>
        <img src={profileInfoSkeleton} alt='profileInfoSkeleton' />
      </div>
    );
  }

  return (
    <ProfileInfoWrapper>
      <img src={profileImagePlaceholder} alt='profileImagePlaceholder' />

      <ProfileFullName>
        {data?.firstName} {data?.lastName}
      </ProfileFullName>
      {contactId ? (
        <>
          <Button
            variant='secondary'
            text='Open Contact Details'
            onClick={() => history.push(`/contacts/list/${data?.contactId}`)}
          />

          <Divider />
          <MoreIDetailsWrapper>
            <MdPhone color='rgba(0, 27, 83, 0.2)' size={23} />
            <p>{data?.phone}</p>=
          </MoreIDetailsWrapper>
          {data?.email && (
            <MoreIDetailsWrapper>
              <MdEmail size={23} color='rgba(0, 27, 83, 0.2)' />
              <p>{data.email}</p>
            </MoreIDetailsWrapper>
          )}
        </>
      ) : (
        <Button
          variant='secondary'
          text='+ Add to contacts'
          onClick={() => history.push(`/contacts/list/`)}
        />
      )}
    </ProfileInfoWrapper>
  );
};

export default UserChatProfileInfo;
