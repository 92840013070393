import React from 'react';

import { useToastError } from 'lib/hooks';
import { ModalHeader } from 'app/pages/guides/modals/VisibilityModal';
import { HeaderSide } from 'app/pages/library/videos/videoList/components/SendAndShare';
import { ChooseRecordingOptions } from 'app/pages/recordv1/ChooseRecordingOptions';
import { LoadingIndicator, NewModal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { MdClose } from 'react-icons/md';
import { useVoiceOverSignUrlWithAudioUpload } from './useVoiceOverSignUrlWithAudioUpload';
import { RecordSettingsProvider } from 'lib/context/record/provider';

type Props = {
  handleAddExternalVideo: (uploadedUrl: string, previewUrl: string) => void;
  onModalCloseHandler: () => void;
};

export const AddRecordingToMerge = ({
  handleAddExternalVideo,
  onModalCloseHandler,
}: Props) => {
  const { showError } = useToastError();

  const { startUpoladingVoiceover, isLoading } =
    useVoiceOverSignUrlWithAudioUpload();

  const onStartUpladingVoiceOverHandler = async (blob: Blob) => {
    try {
      const response = await startUpoladingVoiceover(blob);
      handleAddExternalVideo(response.downloadUrl, response.url);
    } catch (error) {
      showError(error);
      onModalCloseHandler();
    }
  };

  return (
    <NewModal
      hideHeader
      headerText=''
      closeModal={onModalCloseHandler}
      style={{ content: { width: '100%', height: '100vh' } }}
    >
      <>
        {isLoading ? (
          <div>
            <LoadingIndicator isLoading={true} />
          </div>
        ) : (
          <>
            <ModalHeader>
              <HeaderSide>
                <Button
                  text='Close'
                  icon={<MdClose size='18' />}
                  onClick={onModalCloseHandler}
                  variant='transparent'
                />
              </HeaderSide>
            </ModalHeader>
            <RecordSettingsProvider>
              <ChooseRecordingOptions
                showLegacyRecorderSwitch={false}
                showHelpDesk={false}
                showPreview={false}
                onStartUpladingVoiceOverHandler={
                  onStartUpladingVoiceOverHandler
                }
                extendStyles={{
                  showRecorderWrapper: {
                    padding: 32,
                  },
                }}
              />
            </RecordSettingsProvider>
          </>
        )}
      </>
    </NewModal>
  );
};
