import { useQuery } from 'react-query';
import { socialMediaLinksKeys } from './socialMediaLinksKeys';
import { GetSocialLinksResponse } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';

export const getSocialMediaLinks =
  async (): Promise<GetSocialLinksResponse> => {
    const response = await EXPRESS_API.get('/social-link');
    return response.data;
  };

export const useSocialLinksQuery = () => {
  const { showError } = useToastError();

  return useQuery(socialMediaLinksKeys.get(), () => getSocialMediaLinks(), {
    onError: error => {
      showError(error || 'Error in fetching social links');
    },
    refetchOnMount: true,
  });
};
