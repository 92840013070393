import { useAuth } from 'lib/context';
import React, { ReactNode, useReducer, useState } from 'react';
import { io } from 'socket.io-client';
import { SOCKETS_ON_TYPES, SOCKETS_EMIT_TYPES } from './const';
import { INotification, ISocket } from './types';
import { conversationReducer } from './reducers';
import { useQueryClient } from 'react-query';
import { conversationsKeys } from 'lib/api/conversations/queryKeys';
import { ISingleMessage } from 'lib/api/conversations/getSingleConversation';
import { notificationKeys } from 'lib/api/notifications/queryKeys';
import { soundType } from 'lib/const/notifications';
import { SOCKET_CONVERSATION_ACTIONS } from './actions';
import { apiExpressUrl } from 'configs/api/expressApi';
import { isEnvDevelopment } from 'configs/api/enviroments';

type ISocketProvider = {
  children: ReactNode;
};

const config = {
  path: '/socket.io',
  rejectUnauthorized: false,
  reconnectionAttempts: 5,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 10000,
  transports: ['websocket'],
};

interface ISocketState {
  socket: ISocket | null;
  userStatus: Record<string, string>;
}

export const SocketContext = React.createContext({} as ISocketState);

const URL_PATH = isEnvDevelopment ? 'https://localhost:3000' : apiExpressUrl;

export const SocketProvider = ({ children }: ISocketProvider) => {
  const [socket, setSocket] = useState<ISocket | null>(null);
  const [state, dispatch] = useReducer(conversationReducer, {});
  const { invalidateNotificationCount, userData } = useAuth();
  const queryClient = useQueryClient();

  // SOCKET ACTIONS
  const handleReceivedConversationMessage = async (message: ISingleMessage) => {
    await queryClient.refetchQueries(conversationsKeys.lists());
    await queryClient.invalidateQueries(
      conversationsKeys.detail(`${message.conversationId}`)
    );
    queryClient.invalidateQueries(conversationsKeys.count());
  };

  const handleNewNotification = async (data: INotification) => {
    const sound = data?.sound?.toLowerCase().replaceAll(' ', '');
    if (soundType[sound]) {
      const currentSound = new Audio(soundType[sound]);
      currentSound.muted = false;
      currentSound.play();
    }
    await queryClient.invalidateQueries(notificationKeys.all());
    invalidateNotificationCount();
  };

  const setConversationOnline = (conversationId: number) => {
    dispatch({
      type: SOCKET_CONVERSATION_ACTIONS.ONLINE,
      payload: conversationId,
    });
  };
  const setConversationOffline = (conversationId: number) => {
    dispatch({
      type: SOCKET_CONVERSATION_ACTIONS.OFFLINE,
      payload: conversationId,
    });
  };

  const initConversations = (items: number[]) => {
    dispatch({
      type: SOCKET_CONVERSATION_ACTIONS.INIT,
      payload: items,
    });
  };

  React.useEffect(() => {
    if (!userData?.id) {
      return;
    }
    if (socket === null) {
      setSocket(io(URL_PATH, config));
    }
  }, [userData?.id]);

  React.useEffect(() => {
    if (socket === null || !userData?.id) {
      return;
    }

    socket.on(SOCKETS_ON_TYPES.CONNECT, () => {
      socket?.emit(SOCKETS_EMIT_TYPES.CONNECT_USER, Number(userData.userId));
      socket?.emit(
        SOCKETS_EMIT_TYPES.INIT_RETRIEVE_CONVERSATION,
        Number(userData.userId)
      );
      process.env.NODE_ENV === 'development' &&
        console.log('connect user emit with user Id ->', userData.userId);
    });
    socket.on(SOCKETS_ON_TYPES.DISCONNECT, () => {
      console.log('socket disconnected');
    });
    socket?.on(
      SOCKETS_ON_TYPES.CONVERSATION_RECEIVED,
      handleReceivedConversationMessage
    );
    socket?.on(SOCKETS_ON_TYPES.NOTIFICATION_NEW, handleNewNotification);
    socket?.on(SOCKETS_ON_TYPES.LANDING_PAGE_ONLINE, setConversationOnline);
    socket?.on(SOCKETS_ON_TYPES.LANDING_PAGE_OFFLINE, setConversationOffline);
    socket?.on(SOCKETS_ON_TYPES.RETRIEVE_CONVERSATION, initConversations);
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={{ socket, userStatus: state }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSockets = () => {
  const context = React.useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSockets must be used within a SocketProvider');
  }
  return context;
};
