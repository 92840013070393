import * as React from 'react';
import { Formik } from 'formik';
import { CompanyForm, validateCompany, CompanyValues } from '../components';
import { Region, useAuth } from 'lib/context';
import { updateCustomer } from 'lib/api';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { useCreateRegion } from 'lib/api/region/createRegion';
import { useGetRegionsQuery } from 'lib/api/region/getResellerRegions';
import { MarkAsSentOptions } from 'lib/const/MarkAsSentOptions';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
interface ReceivedData {
  business: string;
  firstName: string;
  lastName?: string;
  phone1: string;
  phone2?: string;
  fax1: string;
  email: string;
  url: string;
  allowReplies: number;
  quoteSettings: string;
  reactionsPreference: number;
  regionId: number;
  markVideosAsSent?: string;
  credit700Url?: string;
  mapIframeEmbedCode: string | null;
  autominerGroupId?: string;
}

type ProfileProps = {
  editCompanyName?: boolean;
};

let fields = {
  businessName: '',
  firstNameBusiness: '',
  lastNameBusiness: '',
  businessPhone1: '',
  businessPhone2: '',
  businessFax: '',
  businessEmail: '',
  businessUrl: '',
  allowReplies: false,
  quoteSettings: '',
  markVideosAsSent: '',
  credit700Url: '',
  region: {
    value: 0,
    label: '',
  },
};

const preparePatchData = (values: CompanyValues) => {
  values.businessName = values.businessName.trim();
  values.firstNameBusiness = values.firstNameBusiness.trim();
  values.lastNameBusiness = values.lastNameBusiness.trim();

  const data: ReceivedData = {
    business: values.businessName || '',
    firstName: values.firstNameBusiness,
    lastName: values.lastNameBusiness,
    phone1: values.businessPhone1,
    phone2: values.businessPhone2,
    fax1: values.businessFax || '',
    email: values.businessEmail,
    url: values.businessUrl || '',
    mapIframeEmbedCode: values.mapIframeEmbedCode || null,
    allowReplies: values.allowReplies === true ? 1 : 0,
    quoteSettings: values.quoteSettings,
    reactionsPreference: values.reactionsPreference,
    markVideosAsSent: values.markVideosAsSent,
    credit700Url: values.credit700Url,
    regionId: Number(values?.region?.value) || 0,
    autominerGroupId: values.autominerGroupId || '',
  };
  return data;
};

const loadFields = (data: any) => {
  return {
    businessName: data.business || '',
    firstNameBusiness: data.firstName || '',
    lastNameBusiness: data.lastName || '',
    businessPhone1: data.phone1 || '',
    businessPhone2: data.phone2 || '',
    businessFax: data.fax1 || '',
    businessEmail: data.email || '',
    businessUrl: data.url || '',
    allowReplies: data.allowReplies === '1' ? true : false,
    quoteSettings: data.quoteSettings,
    reactionsPreference: data.reactionsPreference,
    mapIframeEmbedCode: data.mapIframeEmbedCode || '',
    credit700Url: data.credit700Url || '',
    autominerGroupId: data.autominerGroupId || '',
    markVideosAsSent:
      data.markVideosAsSent ?? MarkAsSentOptions.ASK_VERY_TIME.value.toString(),
    region: {
      value: data.regionId,
      label:
        data.regions.find(
          (reg: Region) =>
            reg.regionId?.toString() === data.regionId?.toString()
        )?.name || '',
    },
    resellerId: data.resellerId || 0,
  };
};

export const EditCompanyProfile = (props: ProfileProps) => {
  const { userData, invalidateUser } = useAuth();

  const { editCompanyName = true } = props;
  const [newRegionName, setNewRegionName] = React.useState('');
  const { mutateAsync: createRegion } = useCreateRegion(newRegionName);

  const { data: regions } = useGetRegionsQuery(userData.hasRegions);

  const submitData = async (values: CompanyValues) => {
    try {
      const data = preparePatchData(values);
      if (
        userData.hasRegions &&
        values.region &&
        !values.region.value &&
        values.region.label.toString().length
      ) {
        // create new region first
        setNewRegionName(values.region.label.toString());
        const region = await createRegion();

        data.regionId = region.regionId;
      }
      await updateCustomer(
        {
          customerId: userData.customerId,
          data,
        },
        CHANGE_ORIGIN.companyProfile
      );
      successToast({ title: 'Your company information has been saved.' });
      await invalidateUser();
    } catch {
      errorToast({
        title:
          'An error occurred saving your information, please try again later.',
      });
    }
  };

  return (
    <Formik
      initialValues={{
        ...fields,
        ...loadFields({ ...userData.customer, regions: regions || [] }),
      }}
      validate={validateCompany}
      onSubmit={submitData}
      enableReinitialize
    >
      {props => (
        <CompanyForm
          {...props}
          regions={regions || []}
          editCompanyName={editCompanyName}
          isAutomotiveServiceRole={userData.isAutomotiveServiceRole}
          isAutomotive={userData.isAutomotive}
          resellerId={userData.customer.resellerId}
          submitData={submitData}
        />
      )}
    </Formik>
  );
};
