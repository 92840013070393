import { ISingleConversation } from 'lib/api/conversations/getSingleConversation';
import { useAuth } from 'lib/context';
import React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { ModalsType } from '../conversations.types';
import { isUnassigned, isNotAssignedToYou } from '../utils/helper';

export const InfoMsg = styled.div`
  font-family: 'Work Sans';
  ont-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 27, 83, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 27, 83, 0.06);
  gap: 16px;
  text-align: center;
  padding: 0 16px;
`;

export const FooterInfoMsg = ({
  singleConversation,
  setModals,
}: {
  singleConversation: ISingleConversation;
  setModals: React.Dispatch<React.SetStateAction<false | ModalsType>>;
}) => {
  const { userData } = useAuth();
  const { userId, closed, deleted } = singleConversation;

  const getType = () => {
    if (isUnassigned(userId)) {
      return 'You can not respond to this message as the conversation is unassigned.';
    }
    if (isNotAssignedToYou(userId, userData)) {
      return 'You can not respond to this message as the conversation is not assigned to you.';
    }
    if (closed) {
      return 'You can not respond to this message. The conversation is closed. To respond, reopen the conversation.';
    }
    if (deleted) {
      return 'You can not respond to this message as the conversation is deleted.';
    }
  };

  return (
    <InfoMsg>
      {getType()}
      {isUnassigned(userId) ? (
        <Button text='Assign To ...' onClick={() => setModals('reassign')} />
      ) : null}
    </InfoMsg>
  );
};
