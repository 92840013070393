import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const Table = styled.div`
  display: block;
`;

export const TableHead = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.palette.gray20};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray60};
  padding: 0 0 8px;

  user-select: none;
  -moz-user-select: none;
`;

export const TableRowStyles = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.palette.gray20};
  padding: 6px 0 6px;
  box-sizing: border-box;
`;

type TableCellProps = {
  padding?: string;
  width?: number;
  align?: string;
};

export const TableCell = styled.div<TableCellProps>`
  width: ${({ width }) => `${width}px` || 'auto'};
  padding: ${({ padding }) => padding || '0'};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: ${({ align }) => align || 'flex-start'};
`;

export const VideoTitleField = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blue100};
`;

export const RecordedByField = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;

export const DoubleCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CellPrimary = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;

export const CellSecondary = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
`;

export const PromptContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
`;

export const ReorderCellWrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Order = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray100};
`;

export const SortCell = styled.div`
  cursor: pointer;
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;

type TooltipProps = {
  top?: string;
};
export const Tooltip = styled.div<TooltipProps>`
  position: absolute;
  z-index: 9;
  top: ${props => props.top || '-10px'};
  padding: 10px;
  background-color: white;
  box-shadow:
    0px 0px 2px rgba(66, 79, 104, 0.08),
    0px 4px 8px rgba(66, 79, 104, 0.03);
`;
