import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router';
import { conversationsKeys } from './queryKeys';
import { useToastError } from 'lib/hooks/useToastError';
import { ISingleConversation } from './getSingleConversation';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IConversationParams {
  search?: string;
  page?: number;
  size?: number;
  type?: string;
}
export interface IConversation {
  conversations: ISingleConversation[];
  count: number;
}

const getConversations = async ({
  search = '',
  page = 0,
  size = 10,
  type = undefined,
}: any): Promise<IConversation> => {
  const params = {
    start: page * size,
    limit: size,
    type,
    ...(!!search ? { search: search } : {}),
  };

  return (await EXPRESS_API.get(`conversations`, { params }))
    .data as IConversation;
};

export const useGetConversationsQuery = (
  options: Omit<Partial<IConversationParams>, 'type'> = { page: 0 }
) => {
  const { showError } = useToastError();
  const { type } = useParams() as { type: string | undefined };
  const checkedType = typeof type === 'undefined' ? 'all' : type;

  return useInfiniteQuery(
    conversationsKeys.list(options.search || '', checkedType),
    ({ pageParam = 0 }) =>
      getConversations({
        size: 10,
        type: type,
        ...options,
        //react query provide to us page param
        page: pageParam,
      }),
    {
      refetchOnMount: true,
      onError: showError,
      getNextPageParam: (_, allPages) => {
        return allPages.length;
      },
    }
  );
};
