import React, { useEffect, useRef, useState } from 'react';
import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';
import Select, { MenuPlacement, MenuPosition, components } from 'react-select';
import { IoMdCar } from 'react-icons/io';
import { InventoryItem, useAuth } from 'lib/context';
import { useInventoryQuery } from 'lib/api/inventoryItem';
import CreatableSelect from 'react-select/creatable';
import { debounce } from 'lodash';
import { noInventoryItemsMsg } from '../const';

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;
type DropdownOptionLabelProps = {
  maxWidth: string;
};
const OptionLabel = styled.div<DropdownOptionLabelProps>`
  margin-left: 0px;
  width: 900px;
  max-width: ${props => props.maxWidth || '100%'};
`;
const IconWrapper = styled.div`
  flex-shrink: 0;
  display: inline;
  display: flex;
  align-items: center;
`;
const CustomOptionLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Title = styled.div`
  max-width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 300px;
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.palette.gray100};
`;
const Vin = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray60};
  text-align: right;
`;
const StockNumber = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray60};
  text-align: right;
  width: 120px;
  margin-left: auto;
`;
const SingleValueWrapper = styled.div<{ limitedWidth?: string }>`
  padding-left: 6px;
  ${({ limitedWidth }) =>
    !!limitedWidth &&
    css`
      width: ${limitedWidth};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

const createNew = {
  value: '0',
  label: 'Create New Customer',
  phone: '',
  name: '',
};
const DropdownIndicator = () => {
  return <></>;
};

const filterOptions = (
  candidate: { label: string; value: string; data: any },
  input: string
) => {
  if (candidate.value === createNew.value) {
    return true;
  }
  const label = candidate.label.toLowerCase();
  const vin = candidate.data.vin ? candidate.data.vin.toLowerCase() : '';
  const stockNumber = candidate.data.stockNumber
    ? candidate.data.stockNumber.toLowerCase()
    : '';
  const haystack = `${label} ${vin} ${stockNumber}`;
  return haystack.includes(input.toLowerCase());
};

const Option = (props: any) => {
  return (
    <OptionWrapper>
      <IconWrapper>
        <IoMdCar color={theme.palette.blue40} size={20} />
      </IconWrapper>
      <OptionLabel maxWidth={props.maxWidth}>
        <components.Option {...props} />
      </OptionLabel>
    </OptionWrapper>
  );
};

type OptionLabelProps = {
  value: string;
  label: string;
  vin: string;
  stockNumber: string;
};
const formatOptionLabel = ({ label, vin, stockNumber }: OptionLabelProps) => (
  <CustomOptionLabel>
    <Title>{label}</Title>
    <Vin>&nbsp;{vin}</Vin>
    <StockNumber>&nbsp;{stockNumber}</StockNumber>
  </CustomOptionLabel>
);

const SingleValue = ({ ...props }: any) => {
  return (
    <SingleValueWrapper limitedWidth={props.limitedWidth}>
      {props.data.label}
    </SingleValueWrapper>
  );
};

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      No vehicles found, try entering a different name, VIN or stock number.
    </components.NoOptionsMessage>
  );
};

const LoadingMessage = (props: any) => {
  return (
    <components.LoadingMessage {...props}>
      Searching for vehicles...
    </components.LoadingMessage>
  );
};

type SelectStyleObject = {
  control?: (styles: any, state: any) => any;
  option?: (base: any) => any;
  indicatorSeparator?: () => { display: string };
  valueContainer?: (base: any) => any;
  menu?: (base: any) => any;
  placeholder?: (base: any) => any;
  input?: (base: any) => any;
};

type Props = {
  onChangeVehicle: (item: InventoryItem) => void;
  selectedVin?: string;
  placeholder?: string;
  creatable?: boolean;
  fetchSold?: boolean;
  customSelectStyle?: SelectStyleObject; // These custom styles override all previously set values for atributes that are customized
  dropdownOptionLabelMaxWidth?: string;
  menuPlacement?: MenuPlacement | undefined;
  menuPosition?: MenuPosition | undefined;
  borderRadius?: string;
  menuPortalTarget?: HTMLElement | null | undefined;
  menuZindex?: number;
  limitedSingleValueWidth?: string;
};

export const VehicleSearch = ({
  onChangeVehicle,
  selectedVin = '',
  placeholder = 'Search vehicle by name, VIN or stock number...',
  creatable = false,
  fetchSold = true,
  customSelectStyle,
  dropdownOptionLabelMaxWidth,
  menuPlacement,
  menuPosition,
  borderRadius = '4px',
  menuPortalTarget,
  menuZindex,
  limitedSingleValueWidth,
}: Props) => {
  const { userData } = useAuth();
  const [searchInventoryQuery, setSearchInventoryQuery] = useState(selectedVin);
  const selectRef = useRef<any>(null);

  const selectStyle = {
    control: (styles: any, state: any) => ({
      ...styles,
      height: '40px',
      boxShadow: 'none',
      borderRadius,
      cursor: 'pointer',
      borderColor: state.isFocused
        ? theme.palette.covideoBlue100
        : theme.palette.gray40,
      ':hover': {
        borderColor: theme.palette.covideoBlue100,
      },
      ':focus': {
        borderColor: theme.palette.covideoBlue100,
      },
      ':active': {
        borderColor: theme.palette.covideoBlue100,
      },
    }),
    option: (base: any) => ({
      ...base,
      backgroundColor: 'transparent',
      color: theme.palette.gray100,
      padding: '8px',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'transparent',
        opacity: '.8',
      },
      ':focus': {
        backgroundColor: 'transparent',
        opacity: '.8',
      },
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    valueContainer: (base: any) => ({
      ...base,
      padding: 4,
      overflow: 'show',
    }),
    menu: (base: any) => ({
      ...base,
      minWidth: 710,
      zIndex: menuZindex,
    }),
  };

  const { data, isFetching } = useInventoryQuery({
    searchQuery: searchInventoryQuery,
    countRecorded: false,
    enabled: userData.isIMSEnabled,
    soldFilter: fetchSold ? '2' : '0',
  });
  const items = data ? data.items : [];

  useEffect(() => {
    setSearchInventoryQuery(selectedVin);
  }, [selectedVin]);

  useEffect(() => {
    // auto select first if matching vin
    if (!items || !items.length || selectedVin !== items[0].vin) {
      return;
    }
    onChangeVehicle(items[0]);
  }, [items]);

  const debouncedSearch = useRef(
    debounce((input: string) => setSearchInventoryQuery(input), 500)
  ).current;

  const onInputChange = (input: string) => {
    if (input.length < 2) return;
    debouncedSearch(input);
  };

  const options = items.map((inventoryItem: InventoryItem) => {
    // remove options from inventory item if present
    const { options: _, ...rest } = inventoryItem;
    return {
      ...rest,
      value: inventoryItem.vin,
      label: inventoryItem.title,
    };
  });

  if (!userData.isIMSEnabled) {
    return noInventoryItemsMsg;
  }

  if (creatable) {
    return (
      <CreatableSelect
        ref={selectRef}
        components={{
          Option: optionProps => (
            <Option {...optionProps} maxWidth={dropdownOptionLabelMaxWidth} />
          ),
          DropdownIndicator,
          SingleValue: singleValueProps => (
            <SingleValue
              {...singleValueProps}
              limitedWidth={limitedSingleValueWidth}
            />
          ),
          NoOptionsMessage: isFetching ? LoadingMessage : NoOptionsMessage,
        }}
        styles={{ ...selectStyle, ...customSelectStyle }}
        options={options}
        value={options.find((o: any) => {
          return o.value === selectedVin;
        })}
        onChange={(option: any) => {
          if (option?.__isNew__) {
            onChangeVehicle(
              ({ vin: option.value } as InventoryItem) || undefined
            );
            return;
          }
          onChangeVehicle(option || undefined);
        }}
        isSearchable={true}
        isClearable={true}
        placeholder={placeholder}
        filterOption={filterOptions}
        formatOptionLabel={formatOptionLabel}
        onInputChange={onInputChange}
        formatCreateLabel={inputValue => `Use "${inputValue}"`}
        menuPlacement={menuPlacement}
        menuPosition={menuPosition}
        menuPortalTarget={menuPortalTarget}
      />
    );
  }

  return (
    <Select
      ref={selectRef}
      components={{
        Option: optionProps => (
          <Option {...optionProps} maxWidth={dropdownOptionLabelMaxWidth} />
        ),
        DropdownIndicator,
        SingleValue: singleValueProps => (
          <SingleValue
            {...singleValueProps}
            limitedWidth={limitedSingleValueWidth}
          />
        ),
        NoOptionsMessage: isFetching ? LoadingMessage : NoOptionsMessage,
      }}
      styles={{ ...selectStyle, ...customSelectStyle }}
      options={options}
      value={options.find((o: any) => {
        return o.value === selectedVin;
      })}
      onChange={(option: any) => onChangeVehicle(option || undefined)}
      isSearchable={true}
      isClearable={true}
      placeholder={placeholder}
      filterOption={filterOptions}
      formatOptionLabel={formatOptionLabel}
      onInputChange={onInputChange}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      menuPortalTarget={menuPortalTarget}
    />
  );
};
