import React from 'react';
import { Container, MainContainer, MainWrapper } from '../../index.styled';
import styled from 'styled-components/macro';
import { BsPlus } from 'react-icons/bs';
import { useHistory } from 'react-router';
import Accordion from '../components/Accordion/Accordion';
import { AccordionContainer } from '../components/Accordion/styles';
import { useOrganizationsQuery } from 'lib/api/superAdminApi';
import { LoadingIndicator } from 'lib/components';
import { Button } from 'react-covideo-common';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Header = styled.div`
  font-size: 24px;
  font-weight: 800;
`;

const List = () => {
  const history = useHistory();
  const { organizations, isLoading: isLoadingOrganizations } =
    useOrganizationsQuery({
      params: {
        start: 0,
        limit: 500,
      },
      enabled: true,
    });

  return (
    <MainContainer>
      <MainWrapper>
        <Container overflowX='visible'>
          <HeaderContainer>
            <Header>Organizations</Header>
            <Button
              text='New Organization'
              onClick={() => history.push('/admin/organizations/add')}
              icon={<BsPlus color='white' />}
            />
          </HeaderContainer>
          <AccordionContainer>
            {isLoadingOrganizations ? (
              <LoadingIndicator isLoading={isLoadingOrganizations} />
            ) : (
              organizations.map(organization => (
                <Accordion
                  key={organization.organizationId}
                  organization={organization}
                />
              ))
            )}
          </AccordionContainer>
        </Container>
      </MainWrapper>
    </MainContainer>
  );
};

export default List;
