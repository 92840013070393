import { calculateDatesForRange } from 'app/pages/reports/components';
import dayjs from 'dayjs';
import { RadioInput } from 'lib/components';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import { timeRangeConstants } from '../const';

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RangeLabel = styled.span`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
`;

const RadioLabel = styled.label`
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  gap: 8px;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  cursor: pointer;
  height: 24px;
  margin-bottom: 24px;
  > span {
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #272a32;
  }
`;

interface IProps {
  options: { value: string; label: string }[];
  currentRangeValue: string;
  updateRangeValue: (rangeValue: string) => void;
}

export const TimeframeGroup = ({
  options,
  currentRangeValue,
  updateRangeValue,
}: IProps) => {
  return (
    <RadioGroup role='group' aria-labelledby='type-group'>
      {options.map(range => {
        const { start, end } = calculateDatesForRange(range.value);
        const startDate = dayjs(start).format(STANDARD_DATE_FORMAT);
        const endDate = dayjs(end).format(STANDARD_DATE_FORMAT);

        return (
          <RadioLabel
            key={range.value}
            style={{
              marginBottom: 32,
            }}
          >
            <RadioInput
              name='range'
              value={range.value}
              checked={range.value === currentRangeValue}
              onClick={() => updateRangeValue(range.value)}
              onChange={() => updateRangeValue(range.value)}
            />
            <RangeWrapper>
              <RangeLabel>{range.label}</RangeLabel>
              <span>
                {range.value === timeRangeConstants.TODAY ||
                range.value === timeRangeConstants.YESTERDAY
                  ? startDate
                  : startDate + ' - ' + endDate}
              </span>
            </RangeWrapper>
          </RadioLabel>
        );
      })}
    </RadioGroup>
  );
};
