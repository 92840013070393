import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotFoundTemplate } from '../notFound';
import { ContactDetails, ContactList } from './contact';

import { GroupDetails } from './group/details/GroupDetails';
import { GroupList } from './group/list/GroupList';
import { OptOuts } from './optOuts/OptOuts';

export const ContactRoutes = () => {
  return (
    <Switch>
      <Route path='/contacts/list/:id' component={ContactDetails} />
      <Route path='/contacts/list' component={ContactList} />
      <Route path='/contacts/groups/:id' component={GroupDetails} />
      <Route path='/contacts/groups' component={GroupList} />
      <Route path='/contacts/opt-outs' component={OptOuts} />
      <Route exact path='/contacts'>
        <Redirect to='/contacts/list' />
      </Route>
      <Route path='*' component={NotFoundTemplate} />
    </Switch>
  );
};
