export enum CHANGE_ORIGIN {
  unknown = 'unknown',
  companyProfile = 'companyProfile',
  myProfile = 'myProfile',
  manageUsers = 'manageUsers',
  adminManageCustomers = 'adminManageCustomers',
  adminCustomerDetails = 'adminCustomerDetails',
  adminManageUsers = 'adminManageUsers',
  adminUserDetails = 'adminUserDetails',
}
