import {
  IQuickFilterQuery,
  IQuickFilterQueryValue,
  IQuickFilterRule,
} from 'lib/api/quickFilters/getQuickFilters';
import { Dropdown } from 'lib/components';
import React from 'react';
import { IQuickQueryValues } from './QuickFiltersBuilder';
import { QuickFilterItem } from './QuickFilterItem';
import { Button } from 'react-covideo-common';
import { MdAdd } from 'react-icons/md';

interface Option {
  value: string;
  label: string;
}

const conditions: Option[] = [
  { label: 'AND', value: '$and' },
  { label: 'OR', value: '$or' },
];

interface Props {
  values: IQuickQueryValues;
  root: keyof IQuickFilterQuery;
  push: (obj: any) => void;
  remove: <T>(index: number) => T | undefined;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  INITIAL_RULE: IQuickFilterRule;
}

export const QuickFilterGroup = ({
  values,
  root,
  setFieldValue,
  push,
  remove,
  INITIAL_RULE,
}: Props) => {
  return (
    <div style={{ overflow: 'scroll', height: '100%' }}>
      {values.rules[root]?.map(
        (query: IQuickFilterQueryValue, index: number) => {
          //grab rules.root[key]
          const [queryKey] = Object.keys(query);
          //grab value of rules.root[key]
          const ruleArray = query[queryKey];

          return (
            <React.Fragment key={`${queryKey}_${index}`}>
              {/* CONDITIONS */}
              {index === 1 && (
                <Dropdown
                  options={conditions}
                  value={conditions.find(query => query.value === root)}
                  menuPortalTarget={document.body}
                  menuPosition='fixed'
                  menuPlacement='bottom'
                  onChange={(e: Option) => {
                    setFieldValue(`rules`, {
                      [e.value]: [...(values.rules?.[root] || [])],
                    });
                  }}
                  width={80}
                  extendStyles={{
                    container: { margin: '20px 0' },
                  }}
                />
              )}
              <QuickFilterItem
                root={root}
                index={index}
                ruleArray={ruleArray}
                queryKey={queryKey}
                values={values}
                remove={remove}
                setFieldValue={setFieldValue}
                INITIAL_RULE={INITIAL_RULE}
              />
            </React.Fragment>
          );
        }
      )}
      <Button
        variant='white'
        icon={<MdAdd size={20} />}
        text={'New filter group'}
        onClick={() =>
          push({
            $and: [INITIAL_RULE],
          })
        }
        disabled={values.rules?.[root]?.length === 2}
      />
    </div>
  );
};
