import { EXPRESS_API } from 'configs/api/expressApi';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { messagesKeys } from './messagesKeys';
import { IMessagesParams, MessageUsersParams } from './types';

export const getSmsMessages = async ({
  search = '',
  page = 0,
  size = 10,
  userId = undefined,
  from,
  to = new Date().toISOString(),
  type = undefined,
}: MessageUsersParams): Promise<any> => {
  const params: MessageUsersParams = {
    start: page * size,
    limit: size,
    from,
    to,
    type,
  };

  if (search) {
    params.search = search;
  }

  if (userId) {
    params.userId = userId;
  }

  if (type) {
    params.type = type;
  }

  const response = await EXPRESS_API.get(`/sms/messages`, {
    params: { ...params, to: new Date().toISOString() },
  });

  return response.data;
};

export const useMessagesQuery = (params: IMessagesParams) => {
  const { userData } = useAuth();
  const { showError } = useToastError();
  return useQuery(messagesKeys.messages(params), () => getSmsMessages(params), {
    onError: showError,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: userData?.customer?.smsEnabled?.toString() === '1',
  });
};
