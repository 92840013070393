import styled from 'styled-components/macro';

export const WidgetWrapper = styled.div`
  margin-bottom: 20px;
  background: white;
  padding: 16px 24px;
  display: inline-block;
  width: calc(100% - 48px);
  position: relative;
  border-radius: 8px;
  .recharts-wrapper {
    width: 100% !important;
  }
`;
