import { EXPRESS_API } from 'configs/api/expressApi';

export const addEmailReceiver = async (
  videoId: string,
  phone: string,
  text: string
) => {
  const response = await EXPRESS_API.post(`/emailreceiver/${videoId}`, {
    email: phone,
    messageBody: text,
  });
  return response.data;
};

export const getEmailReceiver = async (videoId: string) => {
  const response = await EXPRESS_API.get(`/emailreceiver/${videoId}`);
  return response.data;
};
