import { errorToast } from 'lib/components/toasts/error';
import { useMutation, useQueryClient } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { successToast } from 'lib/components/toasts/success';

import { EXPRESS_API } from 'configs/api/expressApi';

interface ICancelMeetingParas {
  sendEmail: boolean;
  emailNote: string;
  meetingId: string;
}

const cancelMeeting = async (data: ICancelMeetingParas) => {
  const { meetingId, ...rest } = data;
  const response = await EXPRESS_API.patch(`/meeting/${meetingId}/cancel`, {
    ...rest,
  });
  return response.data;
};

export const useUpdateMeetingCancelStatusMutation = (
  handleModalClose: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation((data: ICancelMeetingParas) => cancelMeeting(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(meetingKeys.meetings_list());
      successToast({
        title: 'Meeting cancelled successfully!',
      });
      handleModalClose();
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred updating the live meeting, please try again later!',
      });
    },
  });
};
