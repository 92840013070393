import { theme } from 'lib/style';
import React from 'react';
import { MdInfo } from 'react-icons/md';
import styled from 'styled-components/macro';

const InfoMsgWrapper = styled.div`
  width: 100%;
  display: flex;
  grid-template-columns: auto-fill;
  gap: 5px;
  background: rgba(182, 224, 236, 0.2);
  border-radius: 5px;
  padding: 12px;
  flex-direction: row;
  margin-bottom: 32px;
  box-sizing: border-box;
`;

export const InfoMsg = () => (
  <InfoMsgWrapper>
    <MdInfo color={theme.palette.blue60} size={20} /> Items in the{' '}
    <b>Recently Deleted</b>bin are permanently deleted after 30 days.
  </InfoMsgWrapper>
);
