import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';

export const CovideoHeader = styled.div`
  display: flex;
  justify-items: start;
  padding: 20px 0 20px 112px;
  border-bottom: 1px solid ${theme.palette.neutral20};
`;

export const HeaderImage = styled.img`
  width: 160px;
  cursor: pointer;
  height: 32px;
`;

export const EmailVerificationWrapper = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-items: center;
  align-items: center;
  height: 100%;
`;

export const TextDetails = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`;

export const Heading = styled.div`
  font-size: 24px;
  line-height: 40px;
  color: ${theme.palette.covideoBlue100};
  font-weight: 800;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.neutral80};
  font-weight: 400;
`;

export const Resend = styled.div<{ disabled: boolean }>`
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.covideoBlue80};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;
