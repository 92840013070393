import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const MainContainer = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  margin: 12px 0 0 0;
  padding: 12px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 12px 12px 64px 220px;
  }
`;
