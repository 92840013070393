import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

export interface IResponse {
  name: string;
  size: number;
  url: string;
  type: string;
}

const sendConversationAttachment = async (item: File): Promise<IResponse> => {
  const data = new FormData();

  data.append('file', item);

  const headers = { 'Content-Type': 'multipart/form-data' };

  const res = await EXPRESS_API.post(`aws/attachments`, data, {
    headers,
  });

  return res.data as IResponse;
};

export const useSendConversationAttachment = () => {
  const { showError } = useToastError();

  return useMutation((data: File) => sendConversationAttachment(data), {
    onError: (err: AxiosError) => showError(err),
  });
};
