import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';
import { StopVideoDeleteParams } from './types';

export const createStopVideoDeleteRequest = async (
  data: StopVideoDeleteParams
) => {
  const response = await EXPRESS_API.post('/stop-video-deletes', data);
  return response.data;
};

export const useCreateStopVideoDeleteMutation = (
  closeVideoDeletionRequest: () => void,
  setPendingRequest: (value: React.SetStateAction<boolean>) => void
) => {
  return useMutation(
    (data: StopVideoDeleteParams) => createStopVideoDeleteRequest(data),
    {
      onError: () => {
        errorToast({
          title:
            'An error occurred creating stopping video delete request, please try again later!',
        });
      },
      onSuccess: () => {
        closeVideoDeletionRequest();
        setPendingRequest(true);
        successToast({
          title: `You have successfully stopped video delete request`,
        });
      },
    }
  );
};
