import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

const sendUseronboardinginvite = async (userId: number) => {
  return (await EXPRESS_API.patch(`/users/${userId}/send-invite`)).data;
};

export const useSendUserOnboardingInviteMutation = () => {
  return useMutation(sendUseronboardinginvite, {
    onSuccess: () => {
      successToast({
        title: `You have successfully sent the onboarding invite!`,
      });
    },
    onError: () => {
      errorToast({
        title: `Something went wrong while sending the onboarding invite!`,
      });
    },
  });
};
