import React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdEmail, MdPhone } from 'react-icons/md';

const Name = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.blue100};
  margin-bottom: 16px;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type EmailProps = {
  maxWidth?: string;
};
const Email = styled.a<EmailProps>`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  margin-bottom: 8px;
  text-decoration: none;

  ${props =>
    props.maxWidth &&
    css`
      span {
        max-width: ${props.maxWidth};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}
`;
const Phone = styled.a`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  text-decoration: none;
`;
type Props = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  onClick?: (arg: string, arg2: string) => void;
  maxWidth?: string;
};
export const CustomerHover = ({
  firstName,
  lastName,
  email,
  phone,
  onClick,
  maxWidth,
}: Props) => {
  return (
    <>
      {(firstName || lastName) && (
        <Name>
          {firstName} {lastName}
        </Name>
      )}
      {email && (
        <Email
          maxWidth={maxWidth}
          href={`mailto:${email}`}
          onClick={e => {
            if (onClick) {
              e.preventDefault();
              onClick(email || '', 'Email');
            }
          }}
        >
          <MdEmail
            size={20}
            color={theme.palette.blue40}
            style={{ marginRight: 10 }}
          />
          <span>{email}</span>
        </Email>
      )}
      {phone && (
        <Phone
          href={`tel:${email}`}
          onClick={e => {
            if (onClick) {
              e.preventDefault();
              onClick(phone || '', 'Phone');
            }
          }}
        >
          <MdPhone
            size={20}
            color={theme.palette.blue40}
            style={{ marginRight: 10 }}
          />
          {phone}
        </Phone>
      )}
    </>
  );
};
