import { IMS_PROVIDERS_VALUES } from 'app/pages/admin/ims/types';
import { MERGE_VIDEO_SOURCE_TABS } from 'app/pages/video/videoMerge/const';
import { IUserData } from 'lib/api/users/getUser';
import { AccessRole, AutomotiveRole } from 'lib/const';
import { automotiveRoleOptions } from 'lib/const/AutomotiveRole';
import { ICombinedUserData } from 'lib/context';

export const getAutomotiveFlags = (data: IUserData | undefined) => {
  const automotiveRole = data?.automotiveRole || 0;
  const isAutomotiveServiceRole =
    data?.customer.automotiveAccess.toString() === '1' &&
    [
      AutomotiveRole.TECHNICIAN.toString(),
      AutomotiveRole.SERVICE_ADVISOR.toString(),
      AutomotiveRole.SERVICE_MANAGER.toString(),
    ].includes(automotiveRole.toString());

  const isAutomotiveSalesRole =
    data?.customer.automotiveAccess.toString() === '1' &&
    [
      AutomotiveRole.SALESPERSON.toString(),
      AutomotiveRole.BDC.toString(),
      AutomotiveRole.SALES_MANAGER.toString(),
    ].includes(automotiveRole.toString());

  const isAutomotive = isAutomotiveServiceRole || isAutomotiveSalesRole;
  const customIMSLinkCustomers = [
    '64604', // Adonis Auto Group
    '65425', // McDonald Nissan
  ];
  const hasCustomIMSLink =
    !!data?.customerId &&
    customIMSLinkCustomers.includes(data.customerId.toString());
  const hasIMSField = Object.values(IMS_PROVIDERS_VALUES).some(
    key => !!data?.customer[key]
  );
  const isIMSEnabled =
    isAutomotiveSalesRole && (hasIMSField || hasCustomIMSLink);

  // VDP enabled for all automotive sales with either IMS or one of the VDP integrations
  const isVdpEnabled =
    isAutomotiveSalesRole &&
    (isIMSEnabled ||
      !!data?.customer?.dealerComId ||
      !!data?.customer?.iPacketDealerId ||
      !!data?.customer?.jdPowerVDPEnabled);

  // VDP allowed if enabled both on customer and on user level
  const isVdpAllowed = isVdpEnabled && !!data?.vdpEnabled;

  return {
    isAutomotive,
    isAutomotiveSalesRole,
    isAutomotiveServiceRole,
    isVdpAllowed,
    isVdpEnabled,
    isIMSEnabled,
  };
};

export const getAutomotiveRoleValueFromLabel = (
  automotiveRoleLabel: string
) => {
  let roleValue = '';
  automotiveRoleOptions.forEach(role => {
    if (role.label.toLowerCase() === automotiveRoleLabel.toLowerCase()) {
      roleValue = role.value;
    }
  });
  return roleValue;
};

export const checkIfVideoRequestFieldsRequired = (
  userData: ICombinedUserData
) => {
  const { customer, automotiveRole } = userData;
  return (
    customer?.automotiveAccess === '1' &&
    automotiveRole === AutomotiveRole.TECHNICIAN
  );
};

export const validateAutomotiveFields = (
  userData: ICombinedUserData,
  data: any
) => {
  const { automotiveRole } = userData;
  if (userData.isAutomotiveSalesRole) {
    return true;
  }
  if (automotiveRole === AutomotiveRole.TECHNICIAN) {
    return !!(data.advisorId && data.repairOrderNumber);
  }
  return true;
};

export const getAutomotiveRoleAsString = (automotiveRole: number) => {
  let role;
  switch (automotiveRole?.toString()) {
    case AutomotiveRole.SALESPERSON:
      role = 'SALESPERSON';
      break;
    case AutomotiveRole.BDC:
      role = 'BDC';
      break;
    case AutomotiveRole.SALES_MANAGER:
      role = 'SALES MANAGER';
      break;
    case AutomotiveRole.SERVICE_ADVISOR:
      role = 'SERVICE ADVISOR';
      break;
    case AutomotiveRole.SERVICE_MANAGER:
      role = 'SERVICE MANAGER';
      break;
    case AutomotiveRole.TECHNICIAN:
      role = 'TECHNICIAN';
      break;
    default:
      role = '';
  }
  return role;
};

/****   SUS-1234 Changes    ****/
export const checkIfHubspotAndElementAccessible = (
  userData: ICombinedUserData
) => {
  const { customer } = userData;
  return (
    checkIfSupervisorWithCDS(userData) ||
    customer.automotiveAccess.toString() === '0'
  );
};

export const checkIfDealerSocketAccessible = (userData: ICombinedUserData) => {
  return userData.isAutomotive || checkIfSupervisorWithCDS(userData);
};

export const checkIfVideoRequestAccessible = (userData: ICombinedUserData) => {
  const { automotiveRole } = userData;
  return (
    userData.isAutomotiveServiceRole &&
    automotiveRole !== AutomotiveRole.TECHNICIAN
  );
};

export const checkIfInventoryAccessible = (userData: ICombinedUserData) => {
  return userData.isAutomotiveSalesRole;
};

export const checkIfRepairOrderAccessible = (userData: ICombinedUserData) => {
  const { automotiveRole } = userData;
  return (
    userData.isAutomotiveServiceRole &&
    automotiveRole !== AutomotiveRole.TECHNICIAN
  );
};

export const checkIfSMSoverviewAndSMSusersAccessible = (
  userData: ICombinedUserData
) => {
  const { access } = userData;
  return access === AccessRole.ADMIN || access === AccessRole.SUPERVISOR;
};

export const checkIfServicesAccessible = (userData: ICombinedUserData) => {
  const { automotiveRole } = userData;
  return (
    userData.isAutomotiveServiceRole &&
    automotiveRole !== AutomotiveRole.TECHNICIAN
  );
};

export const checkIfTemplatesAccessible = (userData: ICombinedUserData) => {
  const { automotiveRole } = userData;
  return automotiveRole !== AutomotiveRole.TECHNICIAN;
};

export const checkIfScheduleAccessible = (userData: ICombinedUserData) => {
  return !userData.isAutomotiveServiceRole;
};

export const checkIfSupervisorWithCDS = (userData: ICombinedUserData) => {
  const { access, customer } = userData;
  return (
    access === AccessRole.SUPERVISOR &&
    customer.automotiveAccess.toString() === '1'
  );
};

export const checkIfUserIsTechnician = (userData: ICombinedUserData) => {
  const { automotiveRole } = userData;
  return (
    userData.isAutomotiveServiceRole &&
    automotiveRole === AutomotiveRole.TECHNICIAN
  );
};

export const checkIfUserIsServiceAdvisor = (userData: ICombinedUserData) => {
  const { automotiveRole } = userData;
  return (
    userData.isAutomotiveServiceRole &&
    automotiveRole === AutomotiveRole.SERVICE_ADVISOR
  );
};

export const checkIfUserIsServiceManager = (userData: ICombinedUserData) => {
  const { automotiveRole } = userData;
  return (
    userData.isAutomotiveServiceRole &&
    automotiveRole === AutomotiveRole.SERVICE_MANAGER
  );
};

export const getAvailableMergeVideoSources = (userData: ICombinedUserData) => {
  let availableTabs = { ...MERGE_VIDEO_SOURCE_TABS };
  const showWheelsTV = !!Number(userData?.customer?.wheelsTV);
  const showLesa = !!Number(userData?.customer?.lesaAccess);
  if (!showWheelsTV) {
    availableTabs.wheelsTv.isHidden = true;
  }
  if (!showLesa) {
    availableTabs.lesa.isHidden = true;
  }
  return availableTabs;
};
