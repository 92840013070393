import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { VideoDetails } from './videoDetails';
import { VideoMerge } from './videoMerge';
import { VideoVoiceover } from './videoVoiceover';

export const VideoRoutes = () => {
  return (
    <Switch>
      <Route
        path="/classic/edit-video/:videoId/:view/:receiverID"
        component={VideoDetails}
      />
      <Route path="/classic/edit-video/:videoId" component={VideoDetails} />
      <Route path="/classic/merge" component={VideoMerge} />
      <Route path="/classic/voiceover/:videoId" component={VideoVoiceover} />
    </Switch>
  );
};
