import { useQuery } from 'react-query';
import { convertToHttps } from 'lib/utils/functions';
import { EXPRESS_API } from 'configs/api/expressApi';

const getWTVSource = async (videoSourceUrl: string) => {
  const params = {
    videoRef: videoSourceUrl,
  };

  return (
    await EXPRESS_API.get(`wtv/source`, {
      params,
    })
  ).data;
};

export const useWheelsTvSourceQuery = ({
  wtvVideoId,
  setVideoSource,
}: {
  wtvVideoId: string;
  setVideoSource: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return useQuery([wtvVideoId], () => getWTVSource(wtvVideoId), {
    onSuccess: data => {
      const videoSourceUrl = convertToHttps(data.source);
      setVideoSource(videoSourceUrl);
    },
    enabled: !!wtvVideoId,
  });
};
