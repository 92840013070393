import { EXPRESS_API } from 'configs/api/expressApi';

export const listSocialUploadLogs = async (
  limit: number,
  sort: string,
  videoId: string
) => {
  const response = await EXPRESS_API.get(`/social-analytics`, {
    params: { limit, sort, videoId },
  });
  return response.data;
};
