import React, { useState } from 'react';
import onboardingStepThreeAnimationForMyTeam from 'lib/animations/OnBoarding_Step_4.json';
import { FreemiumOnboardingPageTemplate } from '../components';
import { ItemSelector } from '../components/ItemSelector';
import { covideoUsageOptionsForMyTeams } from '../const';
import { useFreemiumOnboardingQuery } from 'lib/api/freemiumOnboarding/getFreemiumOnboarding';
import { LoadingIndicator } from 'lib/components';
import laptopIcon from 'assets/images/freemiumOnboarding/laptop.svg';
import { Gap } from 'lib/components/styles/layout';

export const StepThreeForMyTeam = () => {
  const { data: freemiumOnboardingData, isLoading } =
    useFreemiumOnboardingQuery();
  const [selectedUsage, setSelectedUsage] = useState(
    freemiumOnboardingData?.usage || ''
  );

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <FreemiumOnboardingPageTemplate
      stepText='I will use Covideo for...'
      currentStep={3}
      nextStepRoute='/onboarding-freemium/well-done'
      goToNextStepDisabled={selectedUsage === ''}
      rightColumnAnimationSrc={onboardingStepThreeAnimationForMyTeam}
      animationLoop={true}
      dataToBeSaved={{
        usage: selectedUsage,
      }}
      rightColumnChildren={
        <Gap justifyContent='center'>
          <img alt='laptop' src={laptopIcon} />
        </Gap>
      }
      leftColumnChildren={
        <ItemSelector
          itemHeight='152px'
          itemWidth='110px'
          items={covideoUsageOptionsForMyTeams}
          selectedItem={selectedUsage}
          setSelectedValue={setSelectedUsage}
        />
      }
    />
  );
};
