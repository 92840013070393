import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';

export const Main = () => {
  const { userData } = useAuth();

  useEffect(() => {
    if (userData && userData.user?.packageDetails?.id) {
      const isBoardAllowed = checkIfFeatureIsEnabled(
        userData,
        productFeature.FILES_AND_BOARDS
      );
      if (!isBoardAllowed) {
        window.location.href = '/upgrade-plan';
      }
    }
  }, []);

  return (
    <Switch>
      <Route path='/boards' component={List} exact={true} />
      <Route path='/boards/:boardId/:currentTab' component={Details} />
    </Switch>
  );
};
