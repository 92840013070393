import React from 'react';
import { LoadingIndicator } from 'lib/components';
import { UserListFilter, Reseller, TableField } from 'lib/context';
import { useHistory } from 'react-router';
import { SearchAndFilter } from '../components/SearchAndFilter';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { debounce } from 'lodash';
import { getResellers } from 'lib/api/superAdminApi';
import {
  Container,
  MainContainer,
  MainWrapper,
  NoList,
} from '../../index.styled';
import { ResellersHeader } from '../components/ResellersHeaders';
import {
  ResellerTable,
  ResellerTableColumns,
} from '../components/ResellerTable';
import { SCREEN_VALUES } from 'lib/const/SuperAdminConstants';
import { SelectedResellers } from '../components/SelectedResellers';
import { RESELLER_STATUS_OPTIONS } from '../components/ChangeResellerStatusModal';

const tableFields: TableField[] = [
  {
    value: ResellerTableColumns.ACCESS,
    label: '',
  },
  {
    value: ResellerTableColumns.RESELLER_NAME,
    label: 'Name',
    sortable: true,
    sortKey: 'RESELLER_NAME',
    width: 180,
  },
  {
    value: ResellerTableColumns.RESELLER_ID,
    label: 'ID',
    sortable: true,
    sortKey: 'RESELLER_ID',
    width: 65,
  },
  {
    value: ResellerTableColumns.STATUS,
    label: 'Status',
    width: 230,
  },
  {
    value: ResellerTableColumns.URL,
    label: 'Url',
    width: 240,
  },
  {
    value: ResellerTableColumns.PHONE,
    label: 'Phone',
    width: 144,
  },
  {
    value: ResellerTableColumns.PLAYBACK_URL,
    label: 'Playback URL',
    width: 240,
  },
];

export const List = () => {
  const history = useHistory();

  const [resellerSearch, setResellerSearch] = React.useState('');
  const [resellersCount, setResellersCount] = React.useState(0);
  const [resellerSortKey, setResellerSortKey] = React.useState('');
  const [resellerSortOrder, setResellerSortOrder] = React.useState(
    PaginationConstants.ASCENDING
  );
  const [resellerPage, setResellerPage] = React.useState(DEFAULT_PAGE);
  const [resellerSize, setResellerSize] = React.useState(DEFAULT_SIZE);
  const [loadingResellers, setLoadingResellers] = React.useState(false);
  const [resellersArray, setResellersArray] = React.useState<Reseller[]>([]);

  const [selectedResellersData, setSelectedResellersData] = React.useState<
    Reseller[]
  >([]);

  const [selectedResellerActiveStatus, setSelectedResellerActiveStatus] =
    React.useState<RESELLER_STATUS_OPTIONS | null>(
      RESELLER_STATUS_OPTIONS.ACTIVE
    );

  const filter: UserListFilter = {
    sortKey: resellerSortKey,
    order: resellerSortOrder || PaginationConstants.ASCENDING,
    page: resellerPage || DEFAULT_PAGE,
    size: resellerSize || DEFAULT_SIZE,
    searchQuery: resellerSearch,
    status: VerificationStatus.ALL,
  };

  const fetchResellers = async () => {
    setLoadingResellers(true);
    try {
      const limit = resellerSize || 10;
      const start = resellerPage * limit;
      const [_resellers, count] = await getResellers(
        resellerSearch,
        start,
        limit,
        resellerSortOrder,
        resellerSortKey,
        selectedResellerActiveStatus === null
          ? undefined
          : selectedResellerActiveStatus
      );
      setResellersArray(_resellers);
      setResellersCount(count);
      setLoadingResellers(false);
    } catch (error) {
      setLoadingResellers(false);
    }
  };

  React.useEffect(() => {
    fetchResellers();
  }, [
    resellerPage,
    resellerSize,
    resellerSearch,
    resellerSortKey,
    resellerSortOrder,
    selectedResellerActiveStatus,
  ]);

  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      setResellerPage(newPage);
      setResellerSize(newSize);
    },
    300
  );

  const onSelectResellerStatusHandler = (
    value: RESELLER_STATUS_OPTIONS | null
  ) => {
    setSelectedResellerActiveStatus(value);
  };

  return (
    <MainContainer>
      {!!selectedResellersData.length && (
        <SelectedResellers
          selectedResellersData={selectedResellersData}
          setSelectedResselers={(selectedCustomers: Reseller[]) => {
            setSelectedResellersData(selectedCustomers);
          }}
          resellersArray={resellersArray}
          onSuccessfullUpdate={() => {
            fetchResellers();
          }}
        />
      )}
      <MainWrapper>
        <Container>
          <ResellersHeader
            onAddNewReseller={() => {
              history.push(`/admin/resellers/entry`);
            }}
          />
          <SearchAndFilter
            onSearch={setResellerSearch}
            prevSearch={filter.searchQuery}
            selectedResellerActiveStatus={selectedResellerActiveStatus}
            onSelectResellerStatusHandler={onSelectResellerStatusHandler}
          />
          {loadingResellers && (
            <div style={{ width: '100%', height: '300px' }}>
              <LoadingIndicator isLoading={loadingResellers} height='300px' />
            </div>
          )}
          {!loadingResellers && !!resellersArray.length && (
            <ResellerTable
              resellerCount={resellersCount}
              resellersArray={resellersArray}
              page={filter.page}
              size={filter.size}
              sortKey={filter.sortKey}
              order={filter.order}
              tableFields={tableFields}
              onPaginationChange={onPaginationChange}
              onSortingUpdate={(resellerSortKey, order) => {
                setResellerSortKey(resellerSortKey);
                setResellerSortOrder(order);
                setResellerPage(DEFAULT_PAGE);
              }}
              openResellerDetails={(reseller: Reseller) => {
                history.push(
                  `/admin/resellers/${reseller.resellerId}/${SCREEN_VALUES.RESELLERS}`
                );
              }}
              selectedResellersData={selectedResellersData}
              onResellerSelect={(reseller: Reseller, checked: boolean) => {
                setSelectedResellersData(
                  checked
                    ? [...selectedResellersData, reseller]
                    : selectedResellersData.filter(
                        (e: Reseller) => e.resellerId != reseller.resellerId
                      )
                );
              }}
            />
          )}
          {!loadingResellers && !resellersArray.length && (
            <NoList>No Resellers to show.</NoList>
          )}
        </Container>
      </MainWrapper>
    </MainContainer>
  );
};
