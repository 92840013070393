import React from 'react';
import { ResellerSection } from './customerDetails/ResellerSection';
import { BusinessSection } from './customerDetails/BusinessSection';
import { CovideoSection } from './customerDetails/CovideoSection';
import { LicenseSection } from './customerDetails/LicenseSection';
import { CaptionSection } from './customerDetails/CaptionSection';
import { SmsSettingSection } from './customerDetails/SmsSettingSection';
import { SmsLimitsSection } from './customerDetails/SmsLimitsSection';
import { FlexForm, FormProps } from '../../components/AdminFormComponents';
import { SmsPhoneNumberSection } from './customerDetails/SmsPhoneNumberSection';
import { useAuth } from 'lib/context';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { Divider } from '../../index.styled';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { OrganizationSection } from './customerDetails/OrganizationSection';
import { AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';
import { ACTIVE_UNTIL } from 'lib/const/SuperAdminOptions';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { VdpSection } from './customerDetails/VdpSection';
import { Section700Credit } from './customerDetails/Section700Credit';
import { AutominerSection } from './customerDetails/AutominerSection';
import { AudiDealerSection } from './customerDetails/AudiDealerSection';
import { WeeklyReportSection } from './customerDetails/WeeklyReportSection';

export const CustomerDetails = (props: FormProps) => {
  const { values, dirty, onFormDirty } = props;
  const [initialResellerId, setInitialResellerId] = React.useState<
    number | undefined
  >(0);
  const [initialVersionCDS, setInitialVersionCDS] = React.useState<number>();
  const [initialVersionTwo, setInitialVersionTwo] = React.useState<number>();
  const [initialVersionThree, setInitialVersionThree] =
    React.useState<number>();
  const { userData } = useAuth();
  const isAutomotive = values.automotiveAccess || values.versionCDSEnabled;
  const showResellerSection =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;
  const showOrganizationSection =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN;
  const showOtherSections = userData.superAdminRole !== SuperAdminRole.ADMIN;

  values.maxUsers =
    values.covideoPackageId !== PackageName.LEGACY &&
    values.covideoPackageId !== PackageName.TEAMS &&
    values.covideoPackageId !== PackageName.ENTERPRISE
      ? 1
      : values.maxUsers;

  values.smsEnabled =
    values.covideoPackageId &&
    values.covideoPackageId !== PackageName.TEAMS &&
    values.covideoPackageId !== PackageName.ENTERPRISE
      ? 0
      : values.smsEnabled;

  values.versionTwoEnabled = !!values.versionCDSEnabled
    ? 0
    : values.versionTwoEnabled;
  values.versionThreeEnabled = !!values.versionCDSEnabled
    ? 0
    : values.versionThreeEnabled;
  values.verified = values?.verified
    ? values.activeEndDate
      ? ACTIVE_UNTIL
      : VerificationStatus.ACTIVE
    : VerificationStatus.INACTIVE;

  React.useEffect(() => {
    setInitialResellerId(values.resellerId);
    setInitialVersionCDS(values.versionCDSEnabled);
    setInitialVersionTwo(values.versionTwoEnabled);
    setInitialVersionThree(values.versionThreeEnabled);
  }, []);

  React.useEffect(() => {
    if (
      (initialResellerId !== 0 &&
        initialResellerId !== values.resellerId &&
        values.resellerId === AUTOMOTIVE_RESELLER_ID) ||
      initialResellerId !== values.resellerId
    ) {
      values.versionCDSEnabled = 1;
      values.versionThreeEnabled = 0;
      values.versionTwoEnabled = 0;
    } else {
      values.versionCDSEnabled = initialVersionCDS;
      values.versionThreeEnabled = initialVersionThree;
      values.versionTwoEnabled = initialVersionTwo;
    }
  }, [
    values.resellerId,
    initialVersionCDS,
    initialVersionCDS,
    initialVersionCDS,
  ]);

  React.useEffect(() => {
    if (onFormDirty) {
      onFormDirty(!!dirty);
    }
  }, [dirty]);

  const partOfAudiOrganization = !!values?.customerOrganizations?.find(
    organization => organization.organizationId === 4
  );

  return (
    <FlexForm>
      {showResellerSection && (
        <>
          <ResellerSection {...props} />
          <Divider />
        </>
      )}
      {showOrganizationSection && (
        <>
          <OrganizationSection {...props} />
        </>
      )}
      <BusinessSection {...props} />
      {showOtherSections && (
        <>
          <Divider />
          <CovideoSection {...props} />
          <Divider />
          <LicenseSection {...props} />
          <Divider />
          <VdpSection {...props} />
          {values.covideoPackageId !== PackageName.PRO &&
            values.covideoPackageId !== PackageName.FREE && <Divider />}
          {values.covideoPackageId !== PackageName.PRO &&
            values.covideoPackageId !== PackageName.FREE && (
              <CaptionSection {...props} />
            )}
          {values.covideoPackageId !== PackageName.PRO &&
            values.covideoPackageId !== PackageName.FREE && <Divider />}
          {values.covideoPackageId !== PackageName.PRO &&
            values.covideoPackageId !== PackageName.FREE && (
              <SmsSettingSection {...props} />
            )}
          {!!values.smsEnabled && (
            <>
              <SmsPhoneNumberSection {...props} />
              <SmsLimitsSection {...props} />
            </>
          )}
          <Divider />
          <Section700Credit {...props} />
          <Divider />
          <AutominerSection {...props} />
          <Divider />
          {!!isAutomotive && <WeeklyReportSection {...props} />}
          {partOfAudiOrganization && (
            <>
              <Divider />
              <AudiDealerSection {...props} />
            </>
          )}
        </>
      )}
    </FlexForm>
  );
};
