export const USER_TYPE_HELP_TEXT =
  'Admin is for recording, sending, reports, and user management. Supervisor is for reports, and user management only. Does not count against your user allotment.';
export const UPLOAD_CSV_TEXT =
  'Uploaded file is not in .csv format. Try again!';
export const EMPTY_CSV_TEXT = 'Uploaded file is empty';
export const USERNAME_NOT_PRESENT =
  'Please check username column label in .csv file and ensure username column mapping with username column field';
export const USERNAME_REQUIRED =
  'The Username field is required for importing users into Covideo. Please check your file to ensure you have created and labeled a column for Username.';
export const FIELD_REQUIRED_TEXT =
  ' field is required for importing users into Covideo. Please check your file to ensure you have created and labeled a column for ';
export const BULK_IMPORT_TEXT =
  'To automatically import your users, click to download the user list template, fill it out, then drag and drop it into window below.';
export const USER_IMPORT_EMAIL_VALIDATION_REG =
  /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const AUTOMOTIVE_ROLE_LABEL = 'automotiveRoleLabel';
export const AUTOMOTIVE_ROLE_OPTION = {
  value: AUTOMOTIVE_ROLE_LABEL,
  label: 'Automotive Role',
};
