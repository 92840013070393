import React from 'react';
import { MainContainer } from 'lib/components';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

const Layout = styled.div`
  margin: 16px 0 0 0;
  border-radius: 6px;
  border: ${theme.palette.neutral10};
  background: ${theme.palette.white};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 24px;
  ${theme.mediaQueryMinWidth.cmd} {
    width: 650px;
  }
`;

type Props = {
  children?: React.ReactNode;
};
export const Main = ({ children }: Props) => {
  return (
    <MainContainer>
      <Layout>
        <Wrapper>{children}</Wrapper>
      </Layout>
    </MainContainer>
  );
};
