import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { CheckboxInput, SelectionHeaderPopup } from 'lib/components';
import { SelectedVideosActions } from '.';
import { VideoListAutomotiveItem } from 'lib/context';
import { VideoLimitStatus } from 'lib/utils/productFeature';
import { Folder } from 'lib/api/folders/getFolders';

const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  align-items: end;
  color: ${theme.palette.black_1_100};
`;
const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;
type Props = {
  selectedVideos: VideoListAutomotiveItem[];
  setSelectedVideos: (selectedVideos: any[]) => void;
  videoArray: VideoListAutomotiveItem[];
  handleReassignSubmit: (
    advisorId: string,
    videoIds: string[],
    advisor: any
  ) => void;
  handleDeleteSubmit: () => void;
  handleMerge: (videoIds: string) => void;
  handleDownload: (downloadData: { videoId: string; title: string }[]) => void;
  handleMoveCopyButtonClick?: (videoIds: string[]) => void;
  disableDownloadButton?: boolean /* SUS-1282 changes */;
  refreshVideoList: () => void;
  folderAccess: string;
  isFolderShared: boolean;
  videoLimitStatus?: VideoLimitStatus;
  disablePinButton?: boolean;
  currentFolder?: Folder;
};
export const SelectedVideos = ({
  selectedVideos,
  setSelectedVideos,
  videoArray,
  handleReassignSubmit,
  handleDeleteSubmit,
  handleMerge,
  handleDownload,
  handleMoveCopyButtonClick,
  disableDownloadButton /* SUS-1282 changes */,
  refreshVideoList,
  folderAccess,
  isFolderShared,
  videoLimitStatus,
  disablePinButton,
  currentFolder,
}: Props) => {
  return (
    <SelectionHeaderPopup left='208px'>
      <CheckboxSelectionWrapper>
        <CheckboxInput
          blueCheck={true}
          checkGroupIndicator={
            selectedVideos.length > 0 &&
            videoArray.length !== selectedVideos.length
          }
          checked={videoArray.length == selectedVideos.length}
          ignoreGrayForAllSelect={true}
          grayCheck={true}
          onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            let { checked } = event.target as HTMLInputElement;
            if (checked) setSelectedVideos(videoArray.map((e: any) => e));
            else setSelectedVideos([]);
          }}
        />
        <SelectionCountWrap>
          <SelectionCountText>Videos selected:</SelectionCountText>
          <b>{selectedVideos.length}</b>
        </SelectionCountWrap>
      </CheckboxSelectionWrapper>
      <SelectedVideosActions
        videoArray={videoArray}
        selectedVideos={selectedVideos}
        setSelectedVideos={setSelectedVideos}
        handleReassignSubmit={handleReassignSubmit}
        handleDeleteSubmit={handleDeleteSubmit}
        handleMerge={handleMerge}
        handleDownload={handleDownload}
        handleMoveCopyButtonClick={handleMoveCopyButtonClick}
        disableDownloadButton={disableDownloadButton} /* SUS-1282 changes */
        refreshVideoList={refreshVideoList}
        folderAccess={folderAccess}
        isFolderShared={isFolderShared}
        videoLimitStatus={videoLimitStatus}
        disablePinButton={disablePinButton}
        currentFolder={currentFolder}
      />
    </SelectionHeaderPopup>
  );
};
