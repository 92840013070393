import * as React from 'react';
import { MdSubtitles, MdPowerSettingsNew } from 'react-icons/md';

import { Button, Tooltip, useCovideoTheme } from 'react-covideo-common';
import { Gap } from 'lib/components/styles/layout';
import {
  ParagraphSmall,
  ParagraphSmallBold,
} from 'lib/components/styles/typography';
import { IoMdUnlock } from 'react-icons/io';
import { useHistory } from 'react-router';
import { RECORDING_STATUS } from '../types';
import { useTeleprompter } from 'lib/context/teleprompter/provider';

interface TeleprompterProps {
  status: RECORDING_STATUS;
}

const DisabledTooltipPopup = () => {
  const history = useHistory();

  return (
    <>
      <ParagraphSmallBold>Teleprompter</ParagraphSmallBold>
      <ParagraphSmall>
        Create and read your own scripts while recording videos so you can nail
        it on your first take.
      </ParagraphSmall>
      <Button
        variant='primary'
        text={'Upgrade to Pro'}
        style={{ margin: '0 auto', width: 160 }}
        icon={<IoMdUnlock />}
        onClick={() => {
          history.push('/upgrade-plan');
        }}
      />
    </>
  );
};

export const BaseTeleprompterActions = ({ status }: TeleprompterProps) => {
  const { colors } = useCovideoTheme();
  const {
    turnOffTeleprompter,
    onEditOrTurnOnTeleprompterHandler,
    isUsingTeleprompter,
    isTeleprompterDisabled,
  } = useTeleprompter();

  return (
    <Gap>
      <Gap gap='4px'>
        <MdSubtitles color={colors.neutral[60]} size={20} />
        <ParagraphSmallBold color={colors.neutral[60]}>
          Teleprompter
        </ParagraphSmallBold>
      </Gap>
      <Tooltip
        position='bottom'
        children={
          <Button
            variant='secondary'
            disabled={
              isTeleprompterDisabled || status === RECORDING_STATUS.COUNTDOWN
            }
            text={isUsingTeleprompter ? 'Edit' : 'Turn on'}
            onClick={onEditOrTurnOnTeleprompterHandler}
          />
        }
        popup={isTeleprompterDisabled ? <DisabledTooltipPopup /> : null}
        extendStyles={{
          popup: isTeleprompterDisabled
            ? {
                width: 210,
                gap: 8,
                display: 'flex',
                flexDirection: 'column',
                padding: '10px 24px',
              }
            : { display: 'none' },
        }}
      />

      {isUsingTeleprompter && (
        <Button
          title='Turn Off Teleprompter'
          icon={<MdPowerSettingsNew size={20} />}
          onClick={turnOffTeleprompter}
          variant='destructive'
        />
      )}
    </Gap>
  );
};
