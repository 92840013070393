import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Dropdown, Search } from 'lib/components';
import { ListDropdownMenu } from 'lib/components/ListDropdownMenu';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import {
  PackageNameLabel,
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { MdDeleteForever, MdEdit, MdSupervisorAccount } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { ShareFoldersEditModal } from './folders/ShareFoldersEditModal';
import { useGetFolderAccess } from 'lib/api/folderAccess/getFolderAccess';
import { ALL_FOLDERS } from 'lib/const/Folders';
import { ShareFoldersModal } from './folders/ShareFoldersModal';
import { ResellerModal } from './folders/ResellerModal';
import ResellerEditModal from './folders/ResellerEditModal';
import { Button } from 'react-covideo-common';
import { AccessRole } from 'lib/const';
import { Folder } from 'lib/api/folders/getFolders';

interface Props {
  handleSearch: (params: any) => void;
  handleEmptySearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectFolder?: (value: any, actionMeta?: any) => void;
  prevSearch?: string;
  currentFolder?: Folder;
  folders?: Folder[];
  areVideosSelected?: boolean;
  searchDisabled?: boolean;
  mergeButton?: JSX.Element;
  wtvButton?: JSX.Element;
  useTags?: boolean;
  createFolderModal?: boolean;
  openCreateFolderModal?: () => void;
  openEditFolderModal?: () => void;
  openDeleteFolderModal?: () => void;
  totalCount?: any;
  hideVideoCount?: boolean;
  hideNewFolder?: boolean;
  isVideoList?: boolean;
}

const VideoFilters = styled.div<{ selected: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 16px 0;
  button {
    margin-left: 0;
    margin-top: 12px;
  }
  ${theme.mediaQueryMinWidth.sm} {
    button {
      margin-left: 10px;
      margin-top: 0;
    }
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  gap: 16px;
  ${theme.mediaQueryMinWidth.mb} {
    width: 100%;
  }
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: flex-end;
  }
  .countRecord {
    margin: auto;
    min-width: 100px;
    font-size: 12px;
    margin-right: auto;
    margin-left: 5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
  }
  .textRed {
    color: red;
  }
  .textAmber {
    color: #ff8b21;
  }
`;
type FilterProps = {
  width: string;
};

type MergeWrapperProps = {
  isMergeAllowed?: any;
};

const Filter = styled.div<FilterProps>`
  display: block;
  width: 160px;
  margin: 24px 0 0 0;
  border-radius: 4px;
  .dropdown {
    width: ${props => (props.width ? props.width : '200px')};
    & > div {
      width: ${props => (props.width ? props.width : '200px')};
      border-radius: 4px;
    }
  }
  ${theme.mediaQueryMinWidth.sm} {
    margin: 0;
    &:nth-child(n + 2) {
      margin: 0 0 0 24px;
    }
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: ${props => (props.width ? props.width : '240px')};
  }
`;

const EmptyBox = styled.div`
  display: none;
  margin: 24px 0 0 0;
  border-radius: 4px;

  ${theme.mediaQueryMinWidth.sm} {
    display: block;
    width: calc(100%);
  }

  ${theme.mediaQueryMinWidth.lg} {
    display: block;
    width: calc(100%-476px);
  }
`;

const MergeWrapper = styled.div<MergeWrapperProps>`
  margin: 24px 0 0 0;

  ${theme.mediaQueryMinWidth.sm} {
    margin: 0 0 0 24px;
    .text {
      display: none;
    }
    .icon-wrapper {
      width: 100%;
      padding: 0 6px 0 10px;
    }
  }

  ${theme.mediaQueryMinWidth.lg} {
    margin: 0 0 0 6px;
    button {
      width: 180px;
      opacity: ${props => (props.isMergeAllowed == true ? '1' : '0.4')};
      cursor: ${props =>
        props.isMergeAllowed == true ? 'pointer' : 'not-allowed !important'};
    }
    .text {
      display: block;
    }
    .icon-wrapper {
      width: 20%;
      padding: 0 0 0 10px;
    }
  }
`;

const ShowingResult = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${theme.palette.blackRgb60};
  margin-bottom: 24px;
`;

const ShowingResultSpan = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${theme.palette.primaryDarkBlue};
  cursor: pointer;
  text-decoration: underline;
`;

const FoldersWrapper = styled.div`
  display: flex;
  gap: 6px;
  margin-left: 6px;
  border-radius: 6px;
  .delete,
  .edit {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 8px;
  }
  .edit:hover {
    background: #eeeff2;
    color: #272a32 !important;
  }
  .delete:hover {
    background: #fef6f5;
    color: #e84c3d !important;
  }
  div[id$='option-0'] {
    color: ${theme.palette.blue100};
    margin-top: -4px;
    height: 44px;
    border-bottom: 1px solid ${theme.palette.lightgray};
  }
`;

const DropdownElement = styled.div`
  position: relative;
  cursor: pointer;
  .show-more-dropdown {
    right: 0;
  }
`;

const IconWrapper = styled.span`
  margin-left: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  border: 1px solid ${theme.palette.blue05};
  border-radius: 5px;
  svg {
    fill: ${theme.palette.primaryDarkBlue};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary['non_alpha']};
    border: 1px solid ${theme.palette.blue05};
    svg {
      fill: ${theme.palette.primaryDarkBlue};
    }
  }
`;
const CustomListDropdownMenu = styled(ListDropdownMenu)`
  right: 0px;
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 6px 12px rgba(66, 79, 104, 0.06);
  border-radius: 8px;
  padding: 8px 0px;
`;

const SearchAndFolders = (props: Props) => {
  const {
    openCreateFolderModal,
    openEditFolderModal,
    openDeleteFolderModal,
    handleSearch,
    handleEmptySearch,
    onSelectFolder,
    currentFolder,
    folders,
    areVideosSelected = false,
    searchDisabled,
    mergeButton = undefined,
    wtvButton = undefined,
    prevSearch,
    useTags = false,
    totalCount = 0,
    hideVideoCount = false,
    hideNewFolder = false,
    isVideoList = false,
  } = props;

  const [videoCount, setVideoCount] = useState('');
  const [isUpgradeVisible, setUpgradeVisible] = useState(false);
  const [isFolderOrganize, setFolderOrganize] = useState(true);
  const [isVideoMerge, setVideoMerge] = useState(true);
  const [videoLimit, setVideoLimit] = useState(0);
  const [showVideoCount, setShowVideoCount] = useState(false);
  const [placeHolder, setPlaceHolder] = useState('Search videos and tags...');
  const [countColor, setCountColor] = useState('');
  const [showShareFoldersUsers, setShowShareFoldersUsers] =
    useState<boolean>(false);
  const [showShareFoldersResellers, setShowShareFoldersResellers] =
    useState<boolean>(false);
  const [showEditShareFolderModal, setShowEditShareFolderModal] =
    useState<boolean>(false);
  const [showEditResellerModal, setShowEditResellerModal] = useState(false);
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);

  const selectedUsers: string[] | undefined = [];
  const { userData } = useAuth();
  const { isResellerAdmin } = userData;

  const [currentFolderId, setCurrentFolderId] = useState<string>();

  useEffect(() => {
    if (currentFolder) {
      setCurrentFolderId(currentFolder.folderId.toString());
    }
  }, [currentFolder]);

  const { data: folderAccess, refetch } = useGetFolderAccess(
    Number(currentFolderId) || 0
  );

  const isFolderShared = () => {
    if (Array.isArray(folderAccess)) {
      if (folderAccess.length !== 0) {
        return true;
      }
      return false;
    }
  };
  const isFolderSharingEnabled = checkIfFeatureIsEnabled(
    userData,
    productFeature.FOLDER_SHARING
  );

  const hideFolderSharingOption =
    userData.reseller.restrictFolderSharingToAdmins?.toString() === '1' &&
    userData.access !== AccessRole.ADMIN;
  const options =
    folders
      ?.filter(Boolean)
      .filter(f => f.folderId !== ALL_FOLDERS.value)
      .map((f: any) => ({
        value: f.folderId,
        label: f.name,
      }))
      .concat([
        {
          value: ALL_FOLDERS.value,
          label: ALL_FOLDERS.label,
        },
      ]) || [];

  useEffect(() => {
    if (
      userData &&
      userData.user &&
      userData.user.packageDetails &&
      userData.user.packageDetails.id
    ) {
      const userPackageDetails = userData.user.packageDetails;
      setVideoCount(
        `${totalCount} / ${userPackageDetails.maxVideosCreated} Videos`
      );
      setCountColor('');
    }
  }, [totalCount, userData]);

  useEffect(() => {
    setFolderOrganize(
      checkIfFeatureIsEnabled(userData, productFeature.FOLDER_ORGANIZATION)
    );
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      setVideoLimit(userPackageDetails.maxVideosCreated);
      if (userPackageDetails.id) {
        setShowVideoCount(userPackageDetails.id === 1 && !hideVideoCount);
        setVideoMerge(
          checkIfFeatureIsEnabled(userData, productFeature.VIDEO_MERGE)
        );
        setVideoCount(
          `${totalCount} / ${userPackageDetails.maxVideosCreated} Videos`
        );
        if (userPackageDetails.maxVideosCreated) {
          const usedLimitQuotaPercentage =
            (parseInt(totalCount) /
              parseInt(`${userPackageDetails.maxVideosCreated}`)) *
            100;
          if (usedLimitQuotaPercentage > 80 && usedLimitQuotaPercentage < 100) {
            setCountColor('textAmber');
          } else if (usedLimitQuotaPercentage >= 100) {
            setCountColor('textRed');
          }
        }
        setUpgradeVisible(
          totalCount >= userPackageDetails.maxVideosCreated ? true : false
        );
      }
    }
  }, [totalCount, userData]); /** SUS-980 changes **/

  useEffect(() => {
    if (!useTags) {
      setPlaceHolder('Search videos...');
    }
  }, [useTags]);

  const history = useHistory();

  const getDropdownOptions = (
    dropDownOptions: { value: string; label: string }[]
  ) => {
    if (hideNewFolder) {
      return [...dropDownOptions];
    }
    return [
      {
        label: 'Create New Folder',
        value: 'create',
      },
      ...dropDownOptions,
    ];
  };

  const handleOpenModal = () => {
    if (Array.isArray(folderAccess)) {
      if (folderAccess.length !== 0) {
        isResellerAdmin
          ? setShowEditResellerModal(true)
          : setShowEditShareFolderModal(true);
        return;
      }
    }

    !isResellerAdmin
      ? setShowShareFoldersUsers(true)
      : setShowShareFoldersResellers(true);
  };

  let moreDropdownItems = [
    {
      title: 'Rename',
      icon: (
        <MdEdit
          color={theme.palette.midGrey}
          style={{ width: 20, height: 20 }}
        />
      ),
      onClick: () => openEditFolderModal && openEditFolderModal(),
    },
    {
      title: 'Share',
      subtitle: isFolderShared()
        ? `Shared with ${folderAccess && folderAccess?.length} users`
        : 'Not Shared',
      icon: (
        <MdSupervisorAccount
          color={theme.palette.blue40}
          style={{ width: 20, height: 20 }}
        />
      ),
      onClick: () => openEditFolderModal && handleOpenModal(),
      productFeatureId: productFeature.FOLDER_SHARING,
      isDisabled: !isFolderSharingEnabled,
      productTooltipStyle: {
        right: '0px',
        left: '250px',
        top: '77px',
      },
      nextPlan: PackageNameLabel.TEAMS,
    },
    {
      title: 'Delete',
      titleColor: theme.palette.primaryRedDanger,
      icon: (
        <MdDeleteForever
          color={theme.palette.primaryRedDanger}
          style={{ width: 20, height: 20 }}
        />
      ),
      onClick: () => openDeleteFolderModal && openDeleteFolderModal(),
    },
  ];

  if (hideFolderSharingOption) {
    moreDropdownItems = moreDropdownItems.filter(
      item => item.title !== 'Share'
    );
  }

  return (
    <>
      {showShareFoldersUsers && currentFolder && (
        <ShareFoldersModal
          handleModalClose={() => {
            setShowShareFoldersUsers(false);
            refetch();
          }}
          initUsers={selectedUsers}
          folderId={currentFolder.folderId.toString()}
        />
      )}
      {showShareFoldersResellers && currentFolder && (
        <ResellerModal
          handleModalClose={() => {
            setShowShareFoldersResellers(false);
            refetch();
          }}
          initUsers={selectedUsers}
          folderId={currentFolder.folderId.toString()}
        />
      )}
      {showEditShareFolderModal && folderAccess && currentFolder && (
        <ShareFoldersEditModal
          handleModalClose={() => {
            setShowEditShareFolderModal(false);
            refetch();
          }}
          initUsers={selectedUsers}
          accessUsers={folderAccess}
          folderId={currentFolder.folderId.toString()}
          isResellerAdmin={isResellerAdmin}
          folder={currentFolder}
        />
      )}
      {showEditResellerModal && currentFolder && (
        <ResellerEditModal
          handleModalClose={() => {
            setShowEditResellerModal(false);
          }}
          folderId={currentFolder.folderId.toString() || ''}
          folder={currentFolder}
        />
      )}
      <VideoFilters selected={areVideosSelected}>
        <FiltersContainer>
          <Search
            placeholder={placeHolder}
            onSearch={handleSearch}
            onChange={handleEmptySearch}
            disabled={searchDisabled}
            prevSearch={prevSearch}
            width='300px'
            currentFolder={currentFolder}
            useTags={useTags}
          />
          {folders && folders.length && isFolderOrganize ? (
            <FoldersWrapper>
              <Filter width='205px'>
                <Dropdown
                  disabled={!isFolderOrganize}
                  height={40}
                  icon={!hideNewFolder ? 'folder' : ''}
                  creatable={false}
                  className='dropdown'
                  placeholder={'Filter by type...'}
                  value={{
                    value: currentFolder?.folderId,
                    label: currentFolder?.name || ALL_FOLDERS.label,
                  }}
                  onChange={(value, actionMeta) => {
                    const folder = value.value;

                    if (folder === 'create' && openCreateFolderModal) {
                      return openCreateFolderModal();
                    }

                    if (onSelectFolder) {
                      onSelectFolder(folder, actionMeta);
                    }
                  }}
                  options={getDropdownOptions(options)}
                />
              </Filter>
              {currentFolder &&
                Number(currentFolder?.folderId) > 0 &&
                String(currentFolder.userId) === userData.userId &&
                isVideoList &&
                isFolderSharingEnabled && (
                  <DropdownElement onClick={() => setShowMoreDropdown(true)}>
                    <IconWrapper>
                      <BiDotsVerticalRounded
                        size={20}
                        color={theme.palette.covideoBlue40}
                      />
                    </IconWrapper>
                    <CustomListDropdownMenu
                      isMenuOpen={showMoreDropdown}
                      items={moreDropdownItems}
                      id={'show-more-dropdown-folder-one'}
                      setIsMenuOpen={() => setShowMoreDropdown(false)}
                      width='280px'
                    />
                  </DropdownElement>
                )}
            </FoldersWrapper>
          ) : null}

          {showVideoCount && ( //Show Video count only for freemium member
            <div className={`countRecord ${countColor}`}>{videoCount}</div>
          )}
          {isUpgradeVisible && videoLimit ? (
            <Button
              text={'Upgrade'}
              onClick={() => {
                history.push('/upgrade-plan');
              }}
            />
          ) : (
            ''
          )}
          {isVideoMerge == true ? (
            <MergeWrapper
              style={{ display: 'block', margin: '0 0 0 auto' }}
              isMergeAllowed={isVideoMerge}
            >
              {mergeButton != undefined && mergeButton}
            </MergeWrapper>
          ) : (
            <>
              <EmptyBox />
              <ProductInfoTooltip
                width={'fit-content'}
                style={{
                  left: '-70px',
                  top: '-50px',
                }}
                productFeatureId={productFeature.VIDEO_MERGE}
                nextPlan={PackageNameLabel.TEAMS}
              >
                <MergeWrapper
                  style={{ display: 'block', margin: '0 0 0 auto' }}
                  isMergeAllowed={isVideoMerge}
                >
                  {mergeButton !== undefined && mergeButton}
                </MergeWrapper>
              </ProductInfoTooltip>
            </>
          )}
          {/* <EmptyBox /> */}
          {/* <MergeWrapper style={{ display: 'block' }}>
            {mergeButton != undefined && mergeButton}
          </MergeWrapper> */}
        </FiltersContainer>
        {wtvButton != undefined && wtvButton}
      </VideoFilters>
      {prevSearch && currentFolder?.name !== ALL_FOLDERS.label && (
        <ShowingResult>
          Showing results from the '{currentFolder?.name}' folder.{' '}
          {isFolderOrganize && (
            <ShowingResultSpan
              onClick={() =>
                onSelectFolder && onSelectFolder(ALL_FOLDERS.value)
              }
            >
              Search all folders.
            </ShowingResultSpan>
          )}
        </ShowingResult>
      )}
    </>
  );
};

export default SearchAndFolders;
