import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { leadsKeys } from './queryKeys';
import { QueryListParams } from '../types';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IKeyMetricsStatisticsResponse {
  count: number;
  views: number;
  conversionRateWithViews: number;
  conversionRateWithoutViews: number;
}

const getKeyMetricsStatistics = async ({
  startDate,
  endDate,
}: Partial<QueryListParams>): Promise<IKeyMetricsStatisticsResponse> => {
  const params = {
    startDate,
    endDate,
  };

  return (
    await EXPRESS_API.get(`leads/statistics`, {
      params,
    })
  ).data as IKeyMetricsStatisticsResponse;
};

export const useKeyMetricsStatisticsQuery = (
  options: Partial<QueryListParams>,
  enabled: boolean
) => {
  const { showError } = useToastError();

  return useQuery(leadsKeys.stats(), () => getKeyMetricsStatistics(options), {
    onError: showError,
    enabled,
    refetchOnMount: true,
  });
};
