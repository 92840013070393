import * as React from 'react';
import styled from 'styled-components/macro';
import * as theme from 'lib/style/theme';

import { Spinner } from 'lib/components';

type Props = {
  isLoading?: boolean;
  error?: boolean | null | unknown;
  text?: string;
  color?: string;
  height?: number | string;
  zIndex?: number;
  left?: string;
  onRetry?: () => void;
};

type ContainerProps = {
  height?: number | string;
  zIndex?: number;
  left?: string;
};

const LoadingOverlay = styled.div<ContainerProps>`
  ${theme.fontBold700};
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  position: absolute;
  top: 50%;
  left: ${props => (props.left !== undefined ? props.left : '50%')};
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  line-height: 50px;
  margin: 0;
  z-index: ${props => (props.zIndex !== undefined ? props.zIndex : 99)};
`;

const LoadingSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  h1 {
    margin: 0;
    font-size: ${theme.fontSizes.md};
    line-height: ${theme.fontSizes.lg};
    letter-spacing: 1px;
  }
`;

const LoadingContainer = styled.div<ContainerProps>`
  position: ${props => (props.height ? 'relative' : 'inherit')};
  height: ${props => {
    if (typeof props.height === 'string') {
      return props.height;
    } else if (typeof props.height === 'number') {
      return `${props.height}px`;
    } else if (typeof props.height === 'undefined') {
      return 0;
    }
  }};
`;

export const LoadingIndicator = ({
  isLoading = false,
  error = false,
  text = 'Loading...',
  color,
  height,
  zIndex,
  left,
  onRetry,
}: Props) => {
  return (
    <LoadingContainer height={height}>
      {isLoading && !error && (
        <LoadingOverlay left={left} zIndex={zIndex}>
          <LoadingSection>
            <div>
              <Spinner color={color ? color : theme.palette.themeDark} />
            </div>
            {!!text && <h1 style={{ color: color }}>{text}</h1>}
          </LoadingSection>
        </LoadingOverlay>
      )}
      {error && (
        <LoadingOverlay zIndex={zIndex}>
          <LoadingSection
            style={{ cursor: onRetry ? 'pointer' : 'auto' }}
            onClick={() => onRetry && onRetry()}
          >
            <h1>Try Again...</h1>
          </LoadingSection>
        </LoadingOverlay>
      )}
    </LoadingContainer>
  );
};
