import { CustomReportsParams } from './useCustomReportsQuery';

export const customReportKeys = {
  all: () => ['CUSTOM_REPORTS'] as const,
  custom_reports: (params: CustomReportsParams) =>
    ['CUSTOM_REPORTS', params] as const,
  delete_report: (customReportId: number) =>
    ['DELETE_REPORT', customReportId] as const,
  custom_report: (reportId: string) => ['CUSTOM_REPORT', reportId],
};
