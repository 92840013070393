import { EXPRESS_API } from 'configs/api/expressApi';

export const verifyAndResetPassword = async (payload: {
  token: string;
  password: string;
}) => {
  const response = await EXPRESS_API.put(`/users/reset/password`, payload);

  return response.data;
};
