import { useEffect, useRef, useState } from 'react';

export const useCountdown = (initialCount: number) => {
  const [countdown, setCountdown] = useState(initialCount);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  const startRecordingCountdown = () => {
    setCountdown(initialCount);
    countdownIntervalRef.current = setInterval(() => {
      setCountdown(prevCount => {
        if (prevCount <= 1) {
          clearInterval(countdownIntervalRef.current!);
          countdownIntervalRef.current = null;
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const stopRecordingCountdown = () => {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
      countdownIntervalRef.current = null;
    }
    setCountdown(initialCount);
  };

  return { countdown, startRecordingCountdown, stopRecordingCountdown };
};
