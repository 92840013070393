import React from 'react';
import styled from 'styled-components/macro';
import { NewModal, TextInput } from 'lib/components';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';
import { InformationBox } from 'lib/components/InformationBox';
import { Gap, Stack } from 'lib/components/styles/layout';
import { UpdateUsersFastPasswordRequest } from 'lib/context';
import { generatePrettyPassword } from 'lib/utils/functions';
import { MdAutorenew } from 'react-icons/md';
import { CustomPasswordErrorMessage } from 'app/pages/admin/components/PasswordTooltip';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PASSWORD_REGEX } from 'lib/utils/regexes';
import { PASSWORD_FORMAT_ERROR } from 'app/pages/account/userAccount/editMyProfile/components';
import { useUpdateUsersFastPasswordsMutation } from 'lib/api';
import { useToastError } from 'lib/hooks';

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const passwordValidationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, PASSWORD_FORMAT_ERROR)
    .max(50, PASSWORD_FORMAT_ERROR)
    .matches(PASSWORD_REGEX, PASSWORD_FORMAT_ERROR)
    .required(PASSWORD_FORMAT_ERROR),
});

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedUserIds: number[];
};

export const FastPasswordResetModal = ({
  handleModalClose,
  selectedUserIds,
}: Props) => {
  const { showError } = useToastError();
  const { mutateAsync: updateUsersFastPasswords } =
    useUpdateUsersFastPasswordsMutation();

  const initialValues: UpdateUsersFastPasswordRequest = {
    newPassword: generatePrettyPassword(),
    userIds: selectedUserIds,
  };

  const handleSubmit = async (values: UpdateUsersFastPasswordRequest) => {
    try {
      await updateUsersFastPasswords(values);
      successToast({
        title: `Password has been reset for ${
          selectedUserIds.length
        } user${selectedUserIds.length > 1 ? 's' : ''}!`,
      });
    } catch (error) {
      showError(error);
    }
    handleModalClose(1);
  };

  const newPasswordResetInfo = `User${selectedUserIds.length > 1 ? 's' : ''} will be required to immediately reset the password on login. Password is valid for 12 hours.`;
  const headerText = `Fast Reset Password${selectedUserIds.length > 1 ? ` for ${selectedUserIds.length} users` : ''}`;

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => {
        handleModalClose();
      }}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Content>
        <Formik
          initialValues={initialValues}
          validationSchema={passwordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, isValid, isSubmitting }) => (
            <Form>
              <Stack gap='24px'>
                <InformationBox text={newPasswordResetInfo} />
                <Stack gap='2px' width='100%' alignItems='flex-start'>
                  <Gap width='100%' flexWrap='nowrap'>
                    <Field
                      as={TextInput}
                      name='newPassword'
                      autoFocus={true}
                      width='100%'
                    />
                    <Button
                      icon={<MdAutorenew size={18} />}
                      variant='secondary'
                      onClick={() =>
                        setFieldValue('newPassword', generatePrettyPassword())
                      }
                      title='Click to generate a new password'
                    />
                  </Gap>
                  <div>
                    <CustomPasswordErrorMessage name='newPassword' />
                  </div>
                </Stack>
                <Gap justifyContent='flex-end' width='100%'>
                  <Button
                    text={'Save Changes'}
                    type='submit'
                    disabled={!isValid || isSubmitting}
                  />
                </Gap>
              </Stack>
            </Form>
          )}
        </Formik>
      </Content>
    </NewModal>
  );
};
