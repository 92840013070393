import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components/macro';
import Header from './Header';
import Departments from './widgets/Departments';
import VideoDelivery from './widgets/VideoDelivery';
import PieChartDesc from './widgets/PieChartDesc';
import VideoEngagements from './widgets/VideoEngagements';
import TopUsersComparison from './widgets/TopUsersComparison';
import { widget } from './widgets/keys';
import dayjs from 'dayjs';
import { ModalCustomTableFields } from 'lib/components/modal';
import {
  GENERAL_WIDGETS,
  AUTOMOTIVE_WIDGETS,
  WidgetValues,
  WidgetLabels,
  Field,
  REPORTS_STALE_TIME,
  DEFAULT_WIDGETS,
} from './widgets/types';
import Masonry from 'react-masonry-css';
import { IoMdOptions } from 'react-icons/io';
import { theme } from 'lib/style';
import { FiPlus, FiRefreshCw } from 'react-icons/fi';
import VideoPieChart from './widgets/VideoPieChart';
import { useAuth } from 'lib/context';
import DeliveryPieChart from './widgets/DeliveryPieChart';
import ViewRateByDelivery from './widgets/ViewRateByDelivery';
import TopCtaLinks from './widgets/TopCtaLinks';
import TopCtaSets from './widgets/TopCtaSets';
import TopUsers from './widgets/TopUsers';
import Leaderboard from './widgets/Leaderboard';
import TopVideos from './widgets/TopVideos';
import { differenceBy } from 'lodash';
import {
  calculateDatesForRange,
  getDateRangeFromLocalstorage,
  timeRangeConstants,
} from '../../components/DateRangeSelector/DateRangeSelector';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Button } from 'react-covideo-common';

const DATE_FORMAT = 'YYYY-MM-DD';

const WidgetContainer = styled.div`
  max-width: 1008px;
  margin: 0 auto;
  @media screen and (min-width: 1280px) {
    max-width: 1600px;
  }
`;

const RefreshWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  > button {
    margin: 0;
    > .text {
      color: ${theme.palette.black_1_100};
    }
  }
`;

const StickyContainter = styled.div`
  height: 200px;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: #4e5461;
`;

const AddWidgets = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed rgba(0, 27, 83, 0.2);
  border-radius: 7px;
  margin-bottom: 16px;
  margin-top: 60px;
  > button {
    margin: 0;
    > .text {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      padding-left: 0px;
      background-color: rgba(242,244,246,1);
    }
`;

const AUTOMOTIVE_LOCALSTORAGE_FIELDS = 'CovideoTableFieldsAutomotive';
const COVIDEO_LOCALSTORAGE_FIELDS = 'CovideoTableFieldsGeneral';

const getWidgetsFromLocalStorage = (isAutomotive: boolean) => {
  const localStorageKey = isAutomotive
    ? AUTOMOTIVE_LOCALSTORAGE_FIELDS
    : COVIDEO_LOCALSTORAGE_FIELDS;
  const widgets = localStorage.getItem(localStorageKey);
  if (widgets) {
    const widgetsData = JSON.parse(widgets) as Field[];
    if (!widgetsData || !Array.isArray(widgetsData) || !widgetsData.length) {
      localStorage.setItem(localStorageKey, JSON.stringify(DEFAULT_WIDGETS));
      return DEFAULT_WIDGETS;
    }

    return widgetsData;
  }
  localStorage.setItem(localStorageKey, JSON.stringify(DEFAULT_WIDGETS));
  return DEFAULT_WIDGETS;
};

const Dashboard = () => {
  const { userData } = useAuth();

  const isAutomotive =
    userData.customer && userData.customer.automotiveAccess === '1';

  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);
  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );

  const queryClient = useQueryClient();
  const [lastFetched, setLastFetched] = useState<Record<string, Date>>({});
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showAddWidgetsModal, setShowAddWidgetsModal] = useState(false);
  const [widgets, setWidgets] = React.useState<Field[]>(
    getWidgetsFromLocalStorage(isAutomotive)
  );
  const [timestamp, setTimeStamp] = useState({
    current: { start, end, range: initRange },
  });

  const params = {
    from: dayjs(timestamp?.current?.start).format(DATE_FORMAT),
    to: dayjs(timestamp?.current?.end).format(DATE_FORMAT),
  };

  useEffect(() => {
    if (!lastFetched[params.from]) {
      return setLastFetched(prevState => {
        return { ...prevState, [params.from]: new Date() };
      });
    }
    const currentDate = dayjs(new Date());

    let staledData: number[] = [];

    //check if one of active widget queries is stale
    widgets.forEach(item => {
      const widgetUpdated = queryClient.getQueryState(
        widget.detail(item.value, { ...params })
      )?.dataUpdatedAt;
      const widgetDate = dayjs(widgetUpdated);

      if (
        widgetUpdated &&
        currentDate.diff(widgetDate, 'seconds') > REPORTS_STALE_TIME
      ) {
        queryClient.refetchQueries(widget.detail(item.value, { ...params }), {
          active: true,
        });
        staledData.push(widgetUpdated);
      }

      if (!!staledData.length) {
        return setLastFetched(prevState => {
          return { ...prevState, [params.from]: new Date() };
        });
      }
    });
  }, [params.from]);

  const refetchWidgets = async () => {
    queryClient.refetchQueries(widget.details(), { active: true });
    setLastFetched(prevState => {
      return { ...prevState, [params.from]: new Date() };
    });
  };

  const widgetContainerRef = React.useRef(null);
  const [dataForExport, setDataForExport] = React.useState<any>([]);

  const addDataForExport = (data: any) => {
    setDataForExport((prevValues: any) => {
      const newData = prevValues.filter(
        (item: any) => item.title !== data.title
      );
      return [...newData, data];
    });
  };

  const onModalSave = (fields: Field[]) => {
    if (fields.length === 0) {
      queryClient.removeQueries(widget.details());
      return setWidgets(DEFAULT_WIDGETS);
    }

    if (fields.length > widgets.length) {
      return setWidgets(fields);
    }

    const removedWidgets = differenceBy(widgets, fields, 'value');

    removedWidgets.forEach(widgetItem => {
      queryClient.removeQueries(widget.detail(widgetItem.value));
    });
    setWidgets(fields);
  };

  const onSaveCustomTableFields = (fields: Field[]) => {
    const localStorageKey = isAutomotive
      ? AUTOMOTIVE_LOCALSTORAGE_FIELDS
      : COVIDEO_LOCALSTORAGE_FIELDS;
    onModalSave(fields);
    localStorage.setItem(localStorageKey, JSON.stringify(fields));
    setShowCustomizeModal(false);
  };

  return (
    <>
      <StickyContainter>
        <Header
          timeStamp={timestamp}
          setTimeStamp={setTimeStamp}
          dataForExport={dataForExport}
          widgets={widgets}
        />
      </StickyContainter>
      <WidgetContainer>
        {widgets?.length >= 1 ? (
          <ActionsWrapper>
            <RefreshWrapper onClick={refetchWidgets}>
              <Paragraph>
                Last fetched on{' '}
                {dayjs(lastFetched[params.from]).format(DATE_FORMAT)} at{' '}
                {dayjs(lastFetched[params.from]).format(`h:mm A`)}
              </Paragraph>
              <FiRefreshCw color='#4E5461' />
            </RefreshWrapper>
            <Button
              text='Customize'
              icon={<IoMdOptions width={20} />}
              onClick={() => setShowCustomizeModal(true)}
              variant='white'
            />
          </ActionsWrapper>
        ) : (
          <AddWidgets>
            <Button
              text='Add Widgets'
              icon={<FiPlus size={20} />}
              onClick={() => setShowAddWidgetsModal(true)}
              variant='white'
            />
          </AddWidgets>
        )}
        <Masonry
          className='my-masonry-grid'
          columnClassName='my-masonry-grid_column'
          breakpointCols={{ default: 3, 1600: 2 }}
          ref={widgetContainerRef}
        >
          {widgets.map(({ value }) => {
            switch (value) {
              case WidgetValues.VIDEO_DELIVERY:
                return (
                  <VideoDelivery
                    key={value}
                    params={params}
                    addDataForExport={(val: any) => {
                      addDataForExport(val);
                    }}
                  />
                );
              case WidgetValues.DEPARTMENTS:
                return (
                  <Departments
                    key={value}
                    params={params}
                    addDataForExport={(val: any) => {
                      addDataForExport(val);
                    }}
                  />
                );
              case WidgetValues.VIDEO_ENGAGEMENTS:
                return (
                  <VideoEngagements
                    key={value}
                    params={params}
                    addDataForExport={(val: any) => {
                      addDataForExport(val);
                    }}
                  />
                );
              case WidgetValues.NEW_VIDEOS:
                return (
                  <VideoPieChart
                    key={value}
                    title={WidgetLabels.NEW_VIDEOS}
                    params={params}
                    addDataForExport={addDataForExport}
                  />
                );
              case WidgetValues.DELIVERY_METHOD:
                return (
                  <DeliveryPieChart
                    key={value}
                    title={WidgetLabels.DELIVERY_METHOD}
                    params={params}
                    addDataForExport={addDataForExport}
                  />
                );
              case WidgetValues.VIEW_RATE_BY_DELIVERY_METHOD:
                return (
                  <ViewRateByDelivery
                    key={value}
                    params={params}
                    addDataForExport={addDataForExport}
                  />
                );
              case WidgetValues.TOP_CTA_SETS:
                return (
                  <TopCtaSets
                    key={value}
                    params={params}
                    variant='cta-sets'
                    addDataForExport={(val: any) => addDataForExport(val)}
                  />
                );
              case WidgetValues.TOP_CTA_LINKS:
                return (
                  <TopCtaLinks
                    key={value}
                    params={params}
                    variant='cta-links'
                    addDataForExport={(val: any) => addDataForExport(val)}
                  />
                );
              case WidgetValues.TOP_USERS:
                return (
                  <TopUsers
                    key={value}
                    params={params}
                    variant='users'
                    addDataForExport={(val: any) => addDataForExport(val)}
                  />
                );
              case WidgetValues.TOP_FIVE_USER_COMPARISON:
                return (
                  <TopUsersComparison
                    key={value}
                    params={params}
                    addDataForExport={addDataForExport}
                  />
                );
              case WidgetValues.TOP_VIDEOS:
                return (
                  <TopVideos
                    key={value}
                    params={params}
                    variant='videos'
                    addDataForExport={(val: any) => addDataForExport(val)}
                  />
                );
              case WidgetValues.VIEWS_BY_VIDEO_TYPE: {
                if (isAutomotive) {
                  return (
                    <PieChartDesc
                      key={value}
                      title={WidgetLabels.VIEWS_BY_VIDEO_TYPE}
                      params={params}
                      addDataForExport={addDataForExport}
                    />
                  );
                }
                return null;
              }
              case WidgetValues.LEADERBOARD:
                if (isAutomotive) {
                  return (
                    <Leaderboard
                      key={value}
                      params={params}
                      variant='users'
                      addDataForExport={(val: any) => addDataForExport(val)}
                    />
                  );
                }
                return null;

              default:
                throw Error('Something went wrong');
            }
          })}
        </Masonry>
      </WidgetContainer>
      {showCustomizeModal && (
        <ModalCustomTableFields
          title='Customize Overviews'
          onClose={() => setShowCustomizeModal(false)}
          allFields={isAutomotive ? AUTOMOTIVE_WIDGETS : GENERAL_WIDGETS}
          initialFields={widgets}
          actionButtons={[
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
      {showAddWidgetsModal && (
        <ModalCustomTableFields
          title='Add Widgets'
          onClose={() => {
            setShowAddWidgetsModal(false);
          }}
          allFields={isAutomotive ? AUTOMOTIVE_WIDGETS : GENERAL_WIDGETS}
          initialFields={widgets}
          actionButtons={[
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
    </>
  );
};
export default Dashboard;
