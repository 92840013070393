import React from 'react';
import { MdDone } from 'react-icons/md';
import styled from 'styled-components/macro';
import { ManageSeats } from '../components/ManageSeats';
import {
  Feature,
  FeatureIcon,
  FeatureList,
  FeatureText,
  OptionContainer,
  Options,
  PageDescription,
} from '../components/PageElements';

const SeatsContainer = styled(OptionContainer)`
  height: 320px;
`;

type Props = {
  Globals: {
    currentTotalUsers: number;
    currentCostPerUser: number;
    active: boolean;
    users: any;
  };
  Update: (
    currentTotalUsers: number,
    currentCostPerUser: number,
    active: boolean,
    users: any
  ) => void;
};

export const ManageCaptionsInactive = ({ Globals, Update }: Props) => {
  return (
    <>
      <PageDescription>
        The Captions feature will let you record and transcribe all your video
        emails in real-time! So regardless of sound, your recipient will receive
        your message with video captions.
      </PageDescription>
      <Options>
        <SeatsContainer>
          <ManageSeats
            Type="captions"
            SaveButtonText="Activate"
            Globals={Globals}
            Update={Update}
          />
        </SeatsContainer>
      </Options>
      <FeatureList>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize="28px" />
          </FeatureIcon>
          <FeatureText>
            <label>
              Captioning your video message will improve your message’s
              accessibility, convenience, and comprehension
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize="28px" />
          </FeatureIcon>
          <FeatureText>
            <label>
              Applying Captions will help all of your viewers understand and
              enjoy your video to the fullest
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize="28px" />
          </FeatureIcon>
          <FeatureText>
            <label>
              Our captioning feature is powered by AI, so it will continuously
              learn and improve with every use
            </label>
          </FeatureText>
        </Feature>
        <Feature>
          <FeatureIcon>
            <MdDone fontSize="28px" />
          </FeatureIcon>
          <FeatureText>
            <label>Check and edit the text before sending out a video</label>
          </FeatureText>
        </Feature>
      </FeatureList>
    </>
  );
};
