import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { guidesKeyes } from './guidesKeyes';
import { Links, Video } from './guidesQuery';
import { toHHMMSS } from 'lib/utils/functions';
import { GuideCategory } from './singleCategoryQuery';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface GuideDetails {
  videoId: number;
  userId: number;
  customerId: number;
  guideCategoryId: number;
  name: string;
  description: null;
  order: number | null;
  links: Links[] | null;
  video: Video;
  isEditable: boolean;
  isGuideRated: boolean;
  createdAt: Date;
  guideCategory: GuideCategory;
  customThumbnail?: string | null;
}
const getGuideDetails = async (videoId: number): Promise<GuideDetails> => {
  return (await EXPRESS_API.get(`guides/${videoId}`)).data;
};

export const useGuideDetailsQuery = () => {
  const { videoId } = useParams() as { videoId: string };
  const parsedVideoId = Number(videoId);
  return useQuery(guidesKeyes.details(parsedVideoId), async () => {
    const results = await getGuideDetails(parsedVideoId);
    if (results.video.chapters) {
      results.video.chapters = results.video.chapters.map(chapter => ({
        ...chapter,
        minNextChapterValue: chapter.playbackPosition + 1,
        maxPreviousChapterValue: Math.max(chapter.playbackPosition - 1, 0),
        timestamp: toHHMMSS(chapter.playbackPosition),
        isError: false,
      }));
    }
    return results;
  });
};
