export enum CUSTOM_REPORT_STEPS {
  BASICS = 'BASICS',
  USERS = 'USERS',
  DATA = 'DATA',
  TIMEFRAME = 'TIMEFRAME',
}

export type STEPS = {
  value: CUSTOM_REPORT_STEPS;
  label: string;
  errorName?: string;
}[];
export enum TYPE_OF_REPORT {
  RECURRING = 'Recurring Email Reports',
  STATIC = 'Static (One-time Report)',
}

export interface Recurrings {
  frequency: string;
  deliveryTime: string;
  recurringId?: number;
  reportId?: number;
}

export interface CustomReportModalFormikValues {
  title: string;
  includeNewUsersInRecurring: boolean;
  receivers: IRecipient[];
  users: string[];
  reports: string[];
  startDate: Date;
  endDate: Date;
  recurrings: Recurrings;
  staticRange: string;
  reccuringRange: string;
  typeOfReport: TYPE_OF_REPORT;
  reportId: string | undefined;
  isTimeFrameStep: boolean;
  usersData: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    customerId: number;
    business: string;
    phone1: string;
    mlAutoCreated: number;
    dept: string;
    deptId: number;
    loginDate: string;
  }[];
  customerIds?: string[];
  includeNewUsers?: boolean;
}

export enum TIMEFRAME {
  DAY = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
  CUSTOM = 'Custom',
}
export enum CUSTOM_REPORT_USERS_VIEW {
  USERS = 'USERS',
}

export interface IRecipient {
  id?: string;
  email: string;
}
