import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation } from 'react-query';

const createVideoFromDrop = async (dropId: string) => {
  return (await EXPRESS_API.post(`/droplr/drop/${dropId}/videos`)).data;
};

export const useCreateVideoFromDrop = () => {
  const { showError } = useToastError();
  return useMutation(createVideoFromDrop, {
    onError: (err: AxiosError) => {
      showError(err);
    },
    onSuccess: () => {
      successToast({ title: `Successfully created video!` });
    },
  });
};
