import styled from 'styled-components/macro';

export const CardWrapper = styled.div`
  background: #ffffff;
  padding: 22px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f6f7f9;
  box-sizing: border-box;
  box-shadow: 0 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 5px;
  width: 100%;
`;

export const Content = styled.div``;
export const ButtonsWrapper = styled.div`
  position: relative;
  max-width: 280px;
`;

export const ProfilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SocialDescription = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
  max-width: 616px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 8px;
`;

export const HeaderTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #001b53;
`;

export const Title = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  color: #001b53;
  margin-bottom: 20px;
`;

export const SocialButtonWrapper = styled.div<{
  color: string;
  padding?: string;
  font?: string;
  fontsize?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${props => (props.padding ? props.padding : '8px 24px 8px 12px')};
  border-radius: 5px;
  background: ${props => props.color};
  color: #ffffff;
  font-family: ${props => (props.font ? props.font : 'Work Sans')};
  font-style: normal;
  font-weight: 600;
  font-size: ${props => (props.fontsize ? props.fontsize : '15px')};
  line-height: 24px;
  cursor: pointer;
`;

export const ButtonTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 228px;
`;

export const Chip = styled.div<{
  bgColor: string;
  fontColor: string;
  radius?: string;
  padding?: string;
  fontSize?: string;
  lineHeight?: string;
  width?: string;
  height?: string;
}>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: ${props => props.fontSize ?? '12px'};
  line-height: ${props => props.lineHeight ?? '16px'};
  height: ${props => props.height ?? 'auto'};
  width: ${props => props.width ?? 'auto'};
  background: ${props => props.bgColor};
  color: ${props => props.fontColor};
  border-radius: 6px;
  padding: ${props => props.padding ?? '2px 8px'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const InfoBoxWrapper = styled.div`
  background: rgba(182, 224, 236, 0.2);
  border-radius: 5px;
  padding: 12px;
  display: flex;
  gap: 10px;
  margin: 24px;
`;

export const BoxContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BoxContent = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: rgba(39, 42, 50, 0.8);
  display: flex;
`;

export const ChannelWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 12px;
`;

export const Channel = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;

export const PrivacyTitle = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;

export const ChannelName = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;

export const ConnectButton = styled.div<{ bgColor: string; fontColor: string }>`
  background: ${props => props.bgColor};
  color: ${props => props.fontColor};
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
`;

export const ConnectionBtnWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  display: flex;
  gap: 12px;
  width: ${props => (props.isLoading ? '100px' : 'auto')};
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const ButtonSwitchWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  gap: 8px;
`;

export const DropdownLabel = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4e5461;
`;
