import * as React from 'react';
import styled from 'styled-components/macro';
import { TablePagination } from 'lib/components';

const PaginationGroupNode = styled.div`
  display: flex;
  align-items: center;
`;

export const TablePaginationGroup = () => {
  return (
    <PaginationGroupNode>
      <TablePagination />
    </PaginationGroupNode>
  );
};
