import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation } from 'react-query';

interface IDeleteCustomReccurringReportPayload {
  recurringId: string;

  reportId: string;
}

const deleteReccuringReport = async (
  payload: IDeleteCustomReccurringReportPayload
): Promise<{}> => {
  const { reportId, recurringId } = payload;
  const response = await EXPRESS_API.delete(
    `/custom-reports/${reportId}/recurrings/${recurringId}`
  );
  return response.data;
};

export const useDeleteCustomReccuringReport = () => {
  return useMutation(deleteReccuringReport);
};
