import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';

type HoverContentProps = {
  width: string;
  position: string;
  padding: string;
  showTriangle: boolean;
  maxWidth?: string;
};
const HoverContent = styled.div<HoverContentProps>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  ${props =>
    props.position === 'bottom'
      ? css`
          top: 100%;
          left: 50%;
          transform: translate(-50%);
        `
      : props.position === 'below'
        ? css`
            top: calc(100% + 12px);
            left: 50%;
            transform: translate(-50%);
          `
        : props.position === 'bottom-left'
          ? css`
              top: 100%;
              left: 0%;
            `
          : props.position === 'right'
            ? css`
                top: 50%;
                left: 100%;
                transform: translateY(-50%);
              `
            : props.position === 'top-left'
              ? css`
                  bottom: 100%;
                  left: 0%;
                `
              : props.position === 'top'
                ? css`
                    bottom: 100%;
                    left: 50%;
                    transform: translate(-50%);
                  `
                : props.position === 'above'
                  ? css`
                      bottom: calc(100% + 12px);
                      left: 50%;
                      transform: translate(-50%);
                    `
                  : null}
  height: auto;
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
  border-radius: 5px;
  z-index: 99;
  cursor: initial;
  ${({ showTriangle }) =>
    showTriangle &&
    css`
      &:after {
        content: '';
        display: block;
        position: absolute;
      }
    `}
  // TODO: add support for other triangle positions when needed
    ${({ showTriangle, position }) =>
    showTriangle &&
    (position === 'above' || position === 'top') &&
    css`
      &:after {
        border-bottom: 0;
        border-top: 8px solid ${theme.palette.white};
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    `}
      ${({ showTriangle, position }) =>
    showTriangle &&
    position === 'below' &&
    css`
      &:after {
        border-top: 0;
        border-bottom: 8px solid ${theme.palette.white};
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    `}
`;
type HoverElementProps = {
  disableHover: boolean;
  cursor: string;
};
const HoverElement = styled.div<HoverElementProps>`
  position: relative;
  ${({ disableHover, cursor }) =>
    !disableHover
      ? css`
          cursor: ${cursor};
          &:hover ${HoverContent} {
            visibility: visible;
            opacity: 1;
            transition-delay: 300ms;
          }
        `
      : null}
`;
type StyleObject = {
  content?: React.CSSProperties;
};
type Props = {
  hoverable: ReactNode;
  popup: ReactNode;
  className?: string;
  position?:
    | 'top'
    | 'bottom'
    | 'above'
    | 'bottom-left'
    | 'top-left'
    | 'right'
    | 'below';
  width?: string;
  maxWidth?: string;
  padding?: string;
  disableHover?: boolean;
  showTriangle?: boolean;
  cursor?: string;
  style?: StyleObject;
};
export const HoverPopup = ({
  hoverable,
  className,
  popup,
  position = 'bottom',
  padding = '24px',
  width = '267px',
  maxWidth = '',
  disableHover = false,
  showTriangle = false,
  cursor = 'pointer',
  style,
}: Props) => {
  return (
    <HoverElement
      disableHover={disableHover}
      className={className}
      cursor={cursor}
    >
      <HoverContent
        width={width}
        position={position}
        padding={padding}
        showTriangle={showTriangle}
        maxWidth={maxWidth || width}
        style={style?.content}
      >
        {popup}
      </HoverContent>
      {hoverable}
    </HoverElement>
  );
};
