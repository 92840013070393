/* eslint-disable no-loop-func */
import React, { useCallback, useState } from 'react';
import { ExtendedFile, acceptedFormats, statuses } from '../Constants';
import {
  DropContent,
  FileContent,
  FileDescription,
  FileItem,
  FileProgress,
  FileDetails,
  FileStatus,
  FileSize,
  FileFooter,
} from '../styles';
import { useDropzone } from 'react-dropzone';
import { formatBytes } from 'lib/utils/functions';
import { theme } from 'lib/style';
import { FiPlus } from 'react-icons/fi';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { InputLabel, Input } from 'lib/components/videoUpload/styles';
import { useCreateVideoFromDrop } from 'lib/api/droplr/createVideoFromDrop';
import { DropItem } from 'lib/api/droplr/types';
import styled from 'styled-components/macro';
import { editDrop } from 'lib/api/droplr/editDrop';
import { uploadDrop } from 'lib/api/droplr/uploadDrop';
import { IoMdClose } from 'react-icons/io';

type Styles = {
  content?: React.CSSProperties;
  footer?: React.CSSProperties;
};

const ErrorInfo = styled.div`
  margin-bottom: 14px;
  ${theme.mediaQueryMaxWidth.md} {
    display: none;
  }
  span {
    color: rgb(232, 76, 61);
  }
`;

type Props = {
  uploadingFiles: boolean;
  handleModalClose: () => void;
  files: ExtendedFile[];
  setFiles: (arg: ExtendedFile[]) => void;

  handleUploadComplete?: (drop: DropItem) => void;
  boardId?: string;
  dropContentStyle?: React.CSSProperties;
  uploadButtonText?: string;
  style?: Styles;
  isGuide?: boolean;
  acceptedFileTypes?: string[];
};

const UploadDropTab = ({
  uploadingFiles,
  handleModalClose,
  handleUploadComplete,
  boardId,
  files,
  setFiles,
  dropContentStyle,
  uploadButtonText,
  style,
  isGuide,
  acceptedFileTypes,
}: Props) => {
  const [dropFiles, setDropFiles] = useState<File[]>([]);
  const { colors } = useCovideoTheme();
  const { mutateAsync: createVideoFromDrop } = useCreateVideoFromDrop();

  const uploadText = isGuide
    ? 'Drag and drop file here to upload.'
    : 'Drag and drop files here to upload.';

  const waitingFiles = files.filter(i => i.status === statuses.WAITING).length;
  const undefinedFiles = files.filter(i => i !== undefined).length;
  const shouldDisable = files.filter(i => !i.name.trim());

  const editTitle = (i: number, title: string) => {
    if (i >= 0 && i < files.length) {
      let tmpFiles = files;
      tmpFiles[i].name = title.trimStart();
      setFiles(tmpFiles.filter(i => i !== undefined));
    }
  };

  const deleteVideoFromList = (file: ExtendedFile) => {
    let tmpFiles = files;
    let tmpDropFiles = dropFiles;
    let index = files.indexOf(file);

    if (tmpFiles[index].status === statuses.UPLOADING) {
      tmpFiles[index].status = statuses.CANCELING;
    } else {
      delete tmpFiles[index];
      delete tmpDropFiles[index];
    }

    setFiles(tmpFiles.filter(i => i !== undefined));
    setDropFiles(tmpDropFiles.filter(i => i !== undefined));
  };

  const onDrop = useCallback(
    async acceptedFiles => {
      let items = acceptedFiles.map((file: File) => ({
        name: file.name,
        status: statuses.WAITING,
        sizeText: formatBytes(file.size, 2),
        size: file.size,
        progress: 0,
        currentUpload: 0,
      }));

      setFiles([...files, ...items]);
      setDropFiles([...dropFiles, ...acceptedFiles]);
    },
    [files, dropFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes
      ? acceptedFileTypes.join(', ')
      : acceptedFormats.join(', '),
    multiple: !isGuide,
  });

  const uploadFiles = async () => {
    let fileIndex = 0;
    let items = [...dropFiles];
    let itemFiles = [...files];

    let codes: string[] = [];
    for (const file of items) {
      await new Promise<void>(resolve => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          itemFiles[fileIndex] = {
            ...itemFiles[fileIndex],
            status: statuses.UPLOADING,
          };
          setFiles(itemFiles);

          const uploadedDrop: DropItem = await uploadDrop(
            itemFiles[fileIndex].name,
            file.type,
            reader.result,
            (total: any, uploaded: any, progress: any) => {
              if (itemFiles[fileIndex].status === statuses.CANCELING) {
                itemFiles = [...itemFiles];
                itemFiles[fileIndex] = {
                  ...itemFiles[fileIndex],
                  status: statuses.CANCELED,
                };
                setFiles(itemFiles);
                fileIndex += 1;
                resolve();
              }

              itemFiles = [...itemFiles];
              itemFiles[fileIndex] = {
                ...itemFiles[fileIndex],
                currentUpload: Number(formatBytes(uploaded, 2).split(' ')[0]),
                progress: progress,
              };
              setFiles(itemFiles);
            }
          );

          if (handleUploadComplete) handleUploadComplete(uploadedDrop);

          if (
            itemFiles[fileIndex].status !== statuses.CANCELED &&
            itemFiles[fileIndex].status !== statuses.CANCELING
          ) {
            // add file to board
            if (boardId) {
              await editDrop({
                dropId: uploadedDrop.code,
                boards: [boardId],
              });
            }

            // files to be added to covideo
            if (uploadedDrop.type.toLowerCase() === 'video') {
              codes.push(uploadedDrop.code);
            }
          }

          itemFiles = [...itemFiles];
          itemFiles[fileIndex] = {
            ...itemFiles[fileIndex],
            status: statuses.FINISHED,
            progress: 100,
          };
          setFiles(itemFiles);
          fileIndex += 1;
          resolve();
        };

        let readerBlob = new Blob([file]);
        reader.readAsArrayBuffer(readerBlob);
      });
    }

    if (!!codes.length) {
      // upload to covideo
      Promise.allSettled(
        codes.map(async code => {
          await createVideoFromDrop(code);
        })
      );
    }

    !isGuide && handleModalClose();
  };

  return (
    <>
      {!uploadingFiles && (
        <DropContent
          isDragActive={isDragActive}
          {...getRootProps()}
          style={{ height: '300px', ...dropContentStyle }}
        >
          <input {...getInputProps()} />
          {!isDragActive && (
            <>
              <p>{uploadText}</p>
              <Button
                text={uploadButtonText || 'Choose File'}
                icon={<FiPlus />}
              />
            </>
          )}
          {isDragActive && <p>Drop files here</p>}
        </DropContent>
      )}
      {uploadingFiles && (
        <>
          <FileContent style={style?.content}>
            {files
              .filter(i => i !== undefined)
              .map((file, index) => (
                <FileItem
                  key={index}
                  style={{ padding: '0 30px', marginTop: '24px' }}
                >
                  <FileDescription>
                    <Input
                      maxLength={100}
                      style={{
                        display: 'inline',
                        width:
                          file.status === statuses.WAITING ? '82%' : '100%',
                        fontWeight: 500,
                        border: `1px solid ${theme.palette.grayBorder}`,
                      }}
                      type='text'
                      value={file.name}
                      readOnly={!!file.progress}
                      onChange={e => editTitle(index, e.target.value)}
                    />
                    {!file.name.trim().length && (
                      <ErrorInfo>
                        <span>Video Title is required!</span>
                      </ErrorInfo>
                    )}
                    {waitingFiles !== undefinedFiles && (
                      <>
                        <FileProgress value={file.progress} max={100} />
                        <FileDetails>
                          <FileStatus
                            color={
                              file.status === statuses.WAITING
                                ? '9297A2'
                                : colors.primary[100]
                            }
                          >
                            {getStatusText(file.status)}
                          </FileStatus>
                          <FileSize>{getStatusSize(file)}</FileSize>
                        </FileDetails>
                      </>
                    )}
                  </FileDescription>
                  {waitingFiles === undefinedFiles && (
                    <Button
                      text=''
                      variant='destructive'
                      icon={<IoMdClose size={24} />}
                      onClick={() => deleteVideoFromList(file)}
                    />
                  )}
                </FileItem>
              ))}
          </FileContent>
          <FileFooter style={style?.footer}>
            {!isGuide && (
              <div {...getRootProps()} style={{ position: 'relative' }}>
                {waitingFiles === undefinedFiles && (
                  <>
                    <Input
                      {...getInputProps()}
                      type='file'
                      multiple={!isGuide}
                      style={{ opacity: '0' }}
                      id='drop-upload'
                    />
                    <InputLabel
                      noButtonPadding={true}
                      isAddMoreEnabled={true}
                      htmlFor='drop-upload'
                      onClick={e => e.preventDefault()}
                    >
                      <FiPlus style={{ marginRight: '5px' }} />
                      Add more
                    </InputLabel>
                  </>
                )}
              </div>
            )}

            <div style={{ display: 'flex', gap: '8px' }}>
              <Button
                variant='secondary'
                text={'Cancel'}
                onClick={handleModalClose}
              />

              <Button
                text={'Upload'}
                onClick={() => uploadFiles()}
                disabled={
                  waitingFiles !== undefinedFiles || !!shouldDisable.length
                }
              />
            </div>
          </FileFooter>
        </>
      )}
    </>
  );
};

export default UploadDropTab;

export const getStatusText = (status: string) => {
  if (status === statuses.WAITING) {
    return 'Waiting…';
  }

  if (status === statuses.UPLOADING) {
    return 'Uploading…';
  }

  if (status === statuses.CANCELING) {
    return 'Canceling…';
  }

  if (status === statuses.CANCELED) {
    return 'Canceled';
  }

  if (status === statuses.GENERATING_PREVIEW) {
    return 'Generating Preview URL...';
  }

  return 'Finished';
};

export const getStatusSize = (file: ExtendedFile) => {
  if (file.status === statuses.UPLOADING) {
    return `${file.currentUpload} / ${file.sizeText}`;
  }

  return file.sizeText;
};
