import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import {
  FormProps,
  getCheckboxField,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from '../../../components/AdminFormComponents';

export const WeeklyReportSection = (props: FormProps) => {
  const { isSubmitting, setFieldValue, values } = props;
  const { colors } = useCovideoTheme();
  return (
    <Section>
      <SectionTitle>Weekly Report</SectionTitle>
      <RowWrapper>
        <Row>
          {getCheckboxField(
            'weeklyReportEnabled',
            'Enable Weekly Report',
            !!values.weeklyReportEnabled,
            () =>
              setFieldValue('weeklyReportEnabled', !values.weeklyReportEnabled),
            isSubmitting,
            undefined,
            colors.primary[100]
          )}
        </Row>
      </RowWrapper>
    </Section>
  );
};
