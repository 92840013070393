import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useAuth } from 'lib/context';
import { useMutation } from 'react-query';
export interface ILoggedUser {
  accessToken: string;
  refreshToken: string;
}
interface ISignIntoParams {
  locationId: number;
}

const signIntoLocation = async ({
  locationId,
}: ISignIntoParams): Promise<ILoggedUser> => {
  return (
    await EXPRESS_API.post(`locations/sign-in`, {
      locationId,
    })
  ).data as ILoggedUser;
};

export const useSignIntoLocation = () => {
  const { setJwtAndUpdateToken } = useAuth();
  return useMutation(signIntoLocation, {
    onSuccess: data => {
      localStorage.setItem('chosenLocation', '1');

      setJwtAndUpdateToken(data);
    },
    onError: (err: AxiosError) => {
      console.log(err);
      localStorage.removeItem('chosenLocation');
    },
  });
};
