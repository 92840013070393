import { LeadItem } from 'lib/context';
import { EXPRESS_API } from 'configs/api/expressApi';

export type GetLeadsParams = {
  start?: number;
  limit?: number;
  search?: string;
};

export const getLeads = async (params: GetLeadsParams) => {
  const response = await EXPRESS_API.get(`/leads`, { params });
  return response.data;
};

export const createLead = async (data: Partial<LeadItem>) => {
  const response = await EXPRESS_API.post(`/leads`, data);
  return response.data;
};
