import { useQuery } from 'react-query';
import { getInventoryItems } from 'lib/api';
import { inventoryItemKeys } from './inventoryItemKeys';

type Props = {
  size?: number;
  page?: number;
  sort?: string;
  searchQuery?: string;
  soldFilter?: string;
  videosRecordedFilter?: string;
  countRecorded?: boolean;
  enabled?: boolean;
};

export const useInventoryQuery = ({
  size = 10,
  page = 0,
  sort = '',
  searchQuery = '',
  soldFilter = '',
  videosRecordedFilter = '',
  countRecorded = false,
  enabled = true,
}: Props) => {
  return useQuery(
    [
      inventoryItemKeys.all(),
      size,
      page,
      sort,
      searchQuery,
      soldFilter,
      videosRecordedFilter,
      enabled,
      countRecorded,
    ],
    async () =>
      getInventoryItems({
        limit: size,
        start: page * size,
        sort,
        search: searchQuery || '',
        soldFilter,
        videosRecordedFilter,
        countRecorded,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: !countRecorded ? 3600000 : 0, // 1 hour if video counter not necessary
      enabled,
    }
  );
};
