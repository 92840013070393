import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { LoadingIndicator, Table, ModalVideoListVideo } from 'lib/components';
import { VideoListAutomotiveItem, VideoRequest } from 'lib/context';
import dayjs from 'dayjs';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import { TableFieldVideo } from '.';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';

const Wrapper = styled.div`
  margin-bottom: 75px;
`;
type TableCellProps = {
  justifyContent?: string;
  fontWeight?: number;
  color?: string;
  padding?: string;
  width: number;
  display?: string;
  overflow?: string;
};
const TableCell = styled.div<TableCellProps>`
  box-sizing: border-box;
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  display: ${({ display }) => display || 'flex'};
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  color: ${({ color }) => color || theme.palette.gray80};
  padding: ${({ padding }) => padding || 0};
  white-space: nowrap;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ellipsis;
`;
const SortCell = styled.div`
  cursor: pointer;
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;
const SharedWithCompany = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 8px 16px;
  color: ${theme.palette.blue60};
`;
export type RecordedVideoItem = {
  videoRequest: VideoRequest;
  video?: VideoListAutomotiveItem;
  title: string;
  user: string;
  customer: string;
  createdAt: string;
};
type Props = {
  videos: RecordedVideoItem[];
  loading: boolean;
  disabled: boolean;
  videoIdsToUpdate: string[];
  sort: string;
  sortElement: (sortParam: any) => void;
  shareWithCompany: (videoId: string, videoRequestId: string) => void;
};
export const TableVideosRecordedListing = ({
  videos,
  loading,
  disabled,
  videoIdsToUpdate,
  sortElement,
  sort,
  shareWithCompany,
}: Props) => {
  const [showModalPreviewVideo, setShowModalPreviewVideo] = useState(false);
  const [videoId, setVideoId] = useState<string>();
  const columnWidths = [154, 296, 176, 176, 228];

  const previewVideo = (videoId: string) => {
    setVideoId(videoId);
    setShowModalPreviewVideo(true);
  };

  const tableFields = [
    {
      value: 'video',
      label: '',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: false,
      overflow: 'initial',
    },
    {
      value: 'title',
      label: 'Video Title',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
      fontWeight: 600,
      color: theme.palette.gray100,
    },
    {
      value: 'user',
      label: 'Sales Rep.',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
    },
    {
      value: 'customer',
      label: 'Customer',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
    },
    {
      value: 'createdAt',
      label: 'Sent',
      headerAlign: 'flex-start',
      valueAlign: 'flex-start',
      sortable: true,
    },
    {
      value: 'actions',
      label: '',
      headerAlign: 'center',
      valueAlign: 'flex-end',
      sortable: false,
    },
  ];

  return (
    <Wrapper>
      {videos.length && !loading ? (
        <Table
          columnWidths={columnWidths}
          compact={true}
          relative={false}
          headers={[
            ...tableFields.map((field, index) => {
              return (
                <TableCell
                  width={columnWidths[index]}
                  onClick={() =>
                    field.sortable ? sortElement(field.value) : null
                  }
                  justifyContent={field.headerAlign}
                  color={theme.palette.gray60}
                  padding={
                    index + 1 === tableFields.length ? '0' : '0 15px 0 0'
                  }
                >
                  {field.label}
                  {field.sortable ? (
                    <SortCell>
                      <HiArrowNarrowUp
                        color={
                          sort == `${field.value}`
                            ? theme.palette.covideoBlue100
                            : theme.palette.blue20
                        }
                        width={8}
                        height={12}
                      />
                      <HiArrowNarrowDown
                        color={
                          sort == `-${field.value}`
                            ? theme.palette.covideoBlue100
                            : theme.palette.blue20
                        }
                        width={8}
                        height={12}
                      />
                    </SortCell>
                  ) : null}
                </TableCell>
              );
            }),
          ]}
          hoverable={false}
          rows={videos.map((item: RecordedVideoItem, index: number) => ({
            key: index,
            columns: [
              ...tableFields.map((field, index: number) => {
                return (
                  <TableCell
                    width={columnWidths[index]}
                    justifyContent={field.valueAlign}
                    color={field.color}
                    fontWeight={field.fontWeight}
                    padding={
                      index + 1 === tableFields.length ? '0' : '0 15px 0 0'
                    }
                    display={field.value === 'title' ? 'block' : 'flex'}
                    overflow={field.overflow}
                  >
                    {field.value === 'video' ? (
                      <TableFieldVideo
                        previewVideo={previewVideo}
                        videoId={item.video?.id || ''}
                        thumbnail={item.videoRequest.video?.thumbnail || ''}
                        videoLength={item.video?.videoLength || 0}
                        processing={
                          item.video?.processing || VideoEncodingStatus.READY
                        }
                      />
                    ) : field.value === 'createdAt' ? (
                      <>{dayjs(item.createdAt).format('hh:mm A MMM D, YYYY')}</>
                    ) : field.value === 'actions' &&
                      !item.videoRequest?.sharedWithCompany ? (
                      <Button
                        text='Share with Company'
                        variant='secondary'
                        disabled={
                          !!item.video?.id &&
                          videoIdsToUpdate.includes(item.video?.id) &&
                          disabled
                        }
                        onClick={() =>
                          shareWithCompany(
                            item.video?.id || '',
                            item?.videoRequest?.videoRequestId
                          )
                        }
                      />
                    ) : field.value === 'actions' &&
                      item.videoRequest?.sharedWithCompany ? (
                      <SharedWithCompany>Shared with Company</SharedWithCompany>
                    ) : (
                      <>{item[field.value as keyof RecordedVideoItem]}</>
                    )}
                  </TableCell>
                );
              }),
            ],
            onClick: () => {},
          }))}
        />
      ) : !videos.length && !loading ? (
        <>No videos to show. </>
      ) : (
        <LoadingIndicator isLoading={loading} height='500px' />
      )}
      {showModalPreviewVideo && (
        <ModalVideoListVideo
          videoId={videoId || ''}
          handleModalClose={() => setShowModalPreviewVideo(false)}
        />
      )}
    </Wrapper>
  );
};
