import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { DocumentHead, Dropdown, PageTemplate } from 'lib/components';
import { Heading } from 'lib/components/styles/typography';
import { Option } from 'lib/components/dropdown/Dropdown';
import { useAuth } from 'lib/context/auth';
import { Container, MainWrapper } from 'lib/components/styles/layout';
import { ORGANIZATION_PICKER_TITLE } from './Main';

const AllTeamsWrapper = styled.div`
  .dropdown-container {
    margin: 0;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const OrganizationPicker = () => {
  const { userData } = useAuth();
  const { userOrganizationAccess } = userData;
  const organizationOptions = (userOrganizationAccess || []).map(
    organizationAccess => ({
      value: organizationAccess.organizationId.toString(),
      label: organizationAccess.organization?.name || '',
      regions: organizationAccess.limitToRegions || [],
    })
  );
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>(
    ''
  );
  const history = useHistory();

  useEffect(() => {
    if (!selectedOrganizationId) {
      return;
    }
    history.push({
      pathname: `/reports/organization/${selectedOrganizationId}/companies`,
    });
  }, [selectedOrganizationId]);

  return (
    <>
      <Container>
        <MainWrapper resetPadding={false}>
          <AllTeamsWrapper>
            <Heading m='0 0 32px 0'>{ORGANIZATION_PICKER_TITLE}</Heading>
            <FilterContainer>
              <Dropdown
                value={organizationOptions.find(
                  (r: Option) => r.value === selectedOrganizationId
                )}
                options={organizationOptions}
                onChange={(opt: Option) => {
                  setSelectedOrganizationId(opt?.value.toString() || '0');
                }}
                width={200}
                placeholder={ORGANIZATION_PICKER_TITLE}
                isClearable={false}
              />
            </FilterContainer>
          </AllTeamsWrapper>
        </MainWrapper>
      </Container>
    </>
  );
};

export const OrganizationPickerPage = () => (
  <>
    <DocumentHead title='Key Metrics Report' />
    <PageTemplate main={<OrganizationPicker />} />
  </>
);
