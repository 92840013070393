import React from 'react';
import styled from 'styled-components/macro';
import Dashboard from './Dashboard';

const MainWrapper = styled.div`
  margin: 60px 0 60px 208px;
  padding: 0 129px;
  box-sizing: border-box;
  min-height: 100vh;

  @media print {
    margin: 0;
    padding: 20px;
  }
  background: rgba(242, 244, 246, 1);
`;

export const Main = () => {
  return (
    <MainWrapper>
      <Dashboard />
    </MainWrapper>
  );
};
