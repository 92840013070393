import styled, { css } from 'styled-components/macro';

interface ContainerProps {
  noLeftPadding?: boolean;
}

//Main
export const Container = styled.div<ContainerProps>`
  padding-left: ${props => (props.noLeftPadding ? '0px' : '208px')};
`;
interface IMainWrapper {
  resetPadding: boolean;
  resetMargin?: boolean;
  fixedWidth?: string;
}

export const MAIN_WRAPPER_MAX_WIDTH = '768px';
export const MAIN_WRAPPER_MAX_WIDTH_LG = '1024px';
export const MAIN_WRAPPER_MAX_WIDTH_XL = '1280px';

export const MainWrapper = styled.div<IMainWrapper>`
  /*** SUS-902 changes ***/
  min-height: calc(100vh - 83px);
  margin: 64px auto 0 auto;
  padding-top: ${({ resetPadding }) => (resetPadding ? '0px' : '83px')};
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 120px;
  position: relative;
  width: 100%;
  max-width: ${({ fixedWidth }) =>
    fixedWidth ? fixedWidth : MAIN_WRAPPER_MAX_WIDTH};

  @media (min-width: 1280px) {
    max-width: ${({ fixedWidth }) =>
      fixedWidth ? fixedWidth : MAIN_WRAPPER_MAX_WIDTH_LG};
  }
  @media (min-width: 1640px) {
    max-width: ${({ fixedWidth }) =>
      fixedWidth ? fixedWidth : MAIN_WRAPPER_MAX_WIDTH_XL};
  }
`;

interface IGap {
  gap?: string;
  center?: boolean;
  m?: string;
  flexWrap?: string;
  flexDirection?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
  height?: string;
}

interface IEnhancedGap {
  maxWidth: string;
  overflow: string;
  textOverflow: string;
  whiteSpace: string;
}

export const Gap = styled.div<IGap>`
  display: flex;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'wrap')};
  margin: ${({ m }) => m || '0px'};
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  gap: ${({ gap }) => gap || '16px'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  ${({ center }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export const EnhancedGap = styled(Gap)<IEnhancedGap>`
  max-width: ${({ maxWidth }) => maxWidth};
  overflow: ${({ overflow }) => overflow};
  text-overflow: ${({ textOverflow }) => textOverflow};
  white-space: ${({ whiteSpace }) => whiteSpace};
`;

//Header
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  flex-wrap: wrap;
  gap: 32px;
`;

interface IStack {
  gap?: string;
  m?: string;
  p?: string;
  flexWrap?: string;
  alignItems?: string;
  justifyContent?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
}

export const Stack = styled.div<IStack>`
  display: flex;
  box-sizing: border-box;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : 'wrap')};
  margin: ${({ m }) => m || '0px'};
  padding: ${({ p }) => p || '0px'};
  width: ${({ width }) => width || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  height: ${({ height }) => height || 'auto'};
  gap: ${({ gap }) => gap || '16px'};
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`;
