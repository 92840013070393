import * as React from 'react';
import styled from 'styled-components/macro';
import {
  Table,
  TableFooter,
  TablePaginationGroup,
  TablePaginationSize,
  useTableContext,
} from 'lib/components';
import { theme } from 'lib/style';
import { ExitLink } from 'lib/api/videos/types';

const CardStatsLayout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  min-height: 300px;
`;

const NoStats = styled.div`
  margin-top: 34px;
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.black_1_100};
`;

const TData = styled.div`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  ctas: ExitLink[];
};

export const CardStatsExitLinks = (props: Props) => {
  const { ctas } = props;
  const [data, setData] = React.useState<ExitLink[]>([]);
  const { page, size, setCount } = useTableContext();
  const [paginationCounter, setPaginationCounter] = React.useState(false);

  /*** SUS-798 changes ***/
  React.useEffect(() => {
    if (ctas && paginationCounter) {
      setCount(ctas.length);
    }
  }, [paginationCounter]);

  React.useEffect(() => {
    if (ctas) {
      setCount(ctas.length);
      const start = page * size;
      setData([...ctas.slice(start, start + size)]);
      setPaginationCounter(true);
    }
  }, [ctas, page, size]);
  /******/

  return (
    <CardStatsLayout>
      {!ctas.length ? (
        <>
          <NoStats>No data to show.</NoStats>
        </>
      ) : (
        <>
          <Table
            headers={[
              '',
              'Click Date',
              'Email Address',
              'CTA',
              'CTA Set',
              'Landing Page',
              '',
            ]}
            hoverable={false}
            rows={data.map((x: ExitLink, index: number) => ({
              key: index,
              columns: [
                '',
                x.clickDate,
                <TData>{x.email}</TData>,
                <TData>
                  <a
                    target='_blank'
                    title={x.linkText}
                    href={x.linkURL}
                    rel='noopener noreferrer'
                  >
                    {x.linkText}
                  </a>
                </TData>,
                <TData>{x.linkSet}</TData>,
                x.template,
                '',
              ],
              onClick: () => {},
            }))}
            isLoading={false}
            error={false}
          />
          <TableFooter>
            <TablePaginationSize />
            <TablePaginationGroup />
          </TableFooter>
        </>
      )}
    </CardStatsLayout>
  );
};
