import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import {
  ParagraphNormal,
  ParagraphNormalBold,
} from 'lib/components/styles/typography';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import { useCovideoTheme } from 'react-covideo-common';

export enum CardType {
  PRIMARY = 0,
  SECONDARY = 1,
}

const Stats = styled.div<{ marginLeft?: number }>`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 8px 8px;
  align-items: end;
  height: 40x;
  ${props => (props.marginLeft ? `margin-left: ${props.marginLeft}px` : '')};
  background-color: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px ${theme.palette.boxShadow};
`;

type Props = {
  count: number;
  text: string;
  cardType?: CardType;
  marginLeft?: number;
};

export const StatsCard = ({
  count,
  text,
  cardType = CardType.PRIMARY,
  marginLeft,
}: Props) => {
  const { colors } = useCovideoTheme();
  const CountColorMap = {
    0: colors.primary[100],
    1: theme.palette.covideoGray100,
  };
  return (
    <Stats marginLeft={marginLeft}>
      <ParagraphNormalBold color={CountColorMap[cardType]}>
        {addThousandCommaSeparator(count)}
      </ParagraphNormalBold>
      <ParagraphNormal color={theme.palette.gray100}>
        &nbsp;{text}
      </ParagraphNormal>
    </Stats>
  );
};
