import React from 'react';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { IntegrationsList } from '../main/style';
import { IntegrationProps } from '../main/constants';
import { Integration } from '../components';
import selectors from '../../../../cypress/selectors';

interface IntegrationListProps {
  integrations: IntegrationProps[];
}

export const IntegrationList = ({ integrations }: IntegrationListProps) => {
  return !integrations?.length ? (
    <div>
      <ParagraphSmallBold
        data-cy={selectors.integrationsPage.integrationsNoResults}
      >
        There are no integrations to show.
      </ParagraphSmallBold>
    </div>
  ) : (
    <IntegrationsList data-cy={selectors.integrationsPage.integrationsList}>
      {integrations.map((item, index) => {
        return <Integration key={index} integration={item} />;
      })}
    </IntegrationsList>
  );
};
