import React from 'react';
import { MdBlockFlipped } from 'react-icons/md';
import { toast } from 'react-toastify';

import { BaseNotificationBody } from './base';
import { CloseToastIcon } from './close-icon';
import { ToastProps } from './types';

const BlockNotification = (props: ToastProps) => {
  return (
    <BaseNotificationBody {...props}>
      <MdBlockFlipped
        color='#E84C3D'
        size={26}
        style={{ minWidth: 26, maxWidth: 26 }}
      />
    </BaseNotificationBody>
  );
};

export const blockToast = (props: ToastProps) =>
  toast(<BlockNotification {...props} />, {
    progressStyle: { background: 'transparent' },
    position: props.position || 'top-right',
    closeButton: CloseToastIcon,
  });
