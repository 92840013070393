import { CONVERSATION_TYPES } from 'lib/api/conversations/getSingleConversation';
import { useSockets } from 'lib/context';
import { CONVERSATION_STATUS } from 'lib/context/socket/types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  IConversationArea,
  SelectedConversationType,
} from '../conversations.types';
import { ModalUserLeftLandingPage } from '../modals/UserLeftLandingPage';
import {
  EmptyMsg,
  ChatBodyWithOutData,
} from '../styles/UsersConversationsLayout';

import { ConversationBody } from './ConversationBody';
import { ConversationFooter } from './ConversationFooter';
import { ConversationsHeader } from './ConversationsHeader';

export const ConversationsArea = ({
  singleConversation,
  data,
  setModals,
  modals,
  isLoadingConversation,
  isRefetchingSingleConversations,
  dropdownItems,
}: IConversationArea) => {
  const { id } = useParams() as { id: string | undefined };
  const { userStatus } = useSockets();

  const messagesLength = singleConversation?.messages.length || 0;
  const lastMessage = messagesLength === 0 ? 0 : messagesLength - 1;

  const defaultConversationType =
    dropdownItems.find(
      item =>
        item.value ===
        singleConversation?.messages[lastMessage]?.integrationType
    )?.value || dropdownItems[0]?.value;

  const [selectedConversation, setSelectedConversation] = useState<
    SelectedConversationType
  >(defaultConversationType);

  const [copiedMessage, setCopiedMessage] = useState<null | string>(null);

  const [userLeftLandingPage, setUserLeftLandingPage] = useState(false);

  useEffect(() => {
    // on conversation id change reset fields
    setSelectedConversation(defaultConversationType);
    setCopiedMessage(null);
  }, [id]);

  useEffect(() => {
    setSelectedConversation(defaultConversationType);
  }, [isLoadingConversation, isRefetchingSingleConversations]);

  useEffect(() => {
    if (!id) {
      return;
    }
    if (selectedConversation === CONVERSATION_TYPES.LIVE_CHAT) {
      const isOffline = userStatus[id] === CONVERSATION_STATUS.OFFLINE;
      setUserLeftLandingPage(isOffline);
    }
  }, [userStatus]);

  //check for /conversations
  if (data?.pages?.[0]?.count === 0) {
    return (
      <ChatBodyWithOutData>
        <EmptyMsg>There are no conversations for selected type</EmptyMsg>
      </ChatBodyWithOutData>
    );
  }

  if (!id) {
    return (
      <ChatBodyWithOutData>
        <EmptyMsg>
          Start a conversation or select an existing one on the left.
        </EmptyMsg>
      </ChatBodyWithOutData>
    );
  }

  if (isLoadingConversation) {
    return (
      <ChatBodyWithOutData>
        <EmptyMsg>Loading...</EmptyMsg>
      </ChatBodyWithOutData>
    );
  }

  if (!singleConversation) {
    return (
      <ChatBodyWithOutData>
        <EmptyMsg>
          {' '}
          Something went wrong with loading list of conversations
        </EmptyMsg>
      </ChatBodyWithOutData>
    );
  }

  return (
    <>
      <ConversationsHeader
        setModals={setModals}
        singleConversation={singleConversation}
      />
      <ConversationBody
        setCopiedMessage={setCopiedMessage}
        setSelectedConversation={setSelectedConversation}
        singleConversation={singleConversation}
        selectedConversation={selectedConversation}
      />
      <ConversationFooter
        setSelectedConversation={setSelectedConversation}
        setModals={setModals}
        modals={modals}
        isLoadingConversation={isLoadingConversation}
        singleConversation={singleConversation}
        selectedConversation={selectedConversation}
        copiedMessage={copiedMessage}
        setCopiedMessage={setCopiedMessage}
        dropdownItems={dropdownItems}
      />
      {userLeftLandingPage && (
        <ModalUserLeftLandingPage
          singleConversation={singleConversation}
          onModalClose={() => {
            setSelectedConversation(defaultConversationType);
            setUserLeftLandingPage(false);
          }}
          setSelectedConversation={setSelectedConversation}
        />
      )}
    </>
  );
};
