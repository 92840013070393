import {
  getExitLinksSetsForSuperAdmin,
  getLandingPagesForSuperAdmin,
} from 'lib/api';
import { Dropdown, Option } from 'lib/components/dropdown/Dropdown';
import { debounce, uniqBy } from 'lodash';
import React from 'react';
import {
  FieldContainer,
  FieldLabel,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
  getDropdownField,
} from '../../../components/AdminFormComponents';

const limitTemplate = 100;

export const DefaultSection = (props: UserFormProps) => {
  const { values, setFieldValue } = props;
  const [exitlinkOptions, setExitlinkOptions] = React.useState<Option[]>([]);
  const [templateOptions, setTemplateOptions] = React.useState<Option[]>([]);
  const [pageTemplate, setPageTemplate] = React.useState(0);
  const [searchTemplate, setSearchTemplate] = React.useState('');
  const [templateLoading, setTemplateLoading] = React.useState<boolean>(false);
  const [templateCount, setTemplateCount] = React.useState<number>(0);
  const [pageTemplateCopy, setPageTemplateCopy] = React.useState(0);

  const searchTemplateInput = debounce((e: string) => {
    setSearchTemplate(e);
    e ? setPageTemplate(0) : setPageTemplate(pageTemplateCopy);
  }, 600);

  const fetchTemplates = async () => {
    setTemplateLoading(true);
    const templates = await getLandingPagesForSuperAdmin({
      start: pageTemplate * limitTemplate,
      limit: limitTemplate,
      search: searchTemplate,
      customerId: values.customerId,
    });
    let options: Array<Option> = JSON.parse(JSON.stringify(templateOptions));
    templates.templates.forEach(e => {
      options = [...options, { label: e.title, value: e.id }];
    });
    options = uniqBy(options, 'value');
    setTemplateOptions(options);
    setTemplateCount(templates.count);
    setTemplateLoading(false);
  };

  const loadExitlinks = async () => {
    const linksetsData = await getExitLinksSetsForSuperAdmin({
      customerId: values.customerId,
      limit: 1000,
    });
    let options = JSON.parse(JSON.stringify(exitlinkOptions));
    const filterArray = linksetsData?.linksets
      .filter(
        (e: { title: string; id: number; company: number }) => e.company != 0
      )
      .map(e => {
        return {
          value: e.id,
          label: e.title,
        };
      });

    const ctaData = [...options, ...(filterArray || [])];
    setExitlinkOptions(ctaData);
  };

  React.useEffect(() => {
    if (values.customerId <= 0) {
      return;
    }

    fetchTemplates();
    loadExitlinks();
  }, [pageTemplate, searchTemplate, values.customerId]);

  return (
    <Section id={'jobDetailsSection'}>
      <SectionTitle>Set Defaults</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>Landing Page</FieldLabel>
          <FieldContainer>
            <Dropdown
              creatable={false}
              menuPosition='fixed'
              dropdownHeight={300}
              options={templateOptions}
              value={templateOptions.find(o => {
                return o.value === values.template;
              })}
              onChange={option => {
                setFieldValue('template', option.value);
                setTemplateLoading(false);
              }}
              onMenuScrollToBottom={() => {
                if (templateOptions.length < templateCount)
                  setPageTemplate(pageTemplate + 1);
                if (!searchTemplate) setPageTemplateCopy(pageTemplate + 1);
              }}
              onInputChange={searchTemplateInput}
              isLoading={templateLoading}
              onMenuClose={() => setTemplateLoading(false)}
              className='option-container'
              placeholder='Select...'
            />
          </FieldContainer>
        </Row>
        <Row>
          <FieldLabel>CTA set</FieldLabel>
          {getDropdownField(
            'exitlink',
            'Select...',
            exitlinkOptions,
            values.exitlink || '',
            option => setFieldValue('exitlink', option.value)
          )}
        </Row>
      </RowWrapper>
    </Section>
  );
};
