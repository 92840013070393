import { EXPRESS_API } from 'configs/api/expressApi';

export const checkZoomIntegration = async () => {
  const response = await EXPRESS_API.get(`/zoom/check`);
  return response.data;
};

export const disconnectZoomIntegration = async () => {
  const response = await EXPRESS_API.get(`/zoom/disconnect`);
  return response.data;
};
