import React from 'react';

import bni from 'assets/images/socialIcons/bni.webp';
import cars from 'assets/images/socialIcons/carscom.webp';
import dealerReater from 'assets/images/socialIcons/dealerreater.webp';
import edmunds from 'assets/images/socialIcons/edmunds.webp';
import facebook from 'assets/images/socialIcons/facebook.webp';
import instagram from 'assets/images/socialIcons/instagram.webp';
import linkedIn from 'assets/images/socialIcons/linkedin.webp';
import pinterest from 'assets/images/socialIcons/pinterest.webp';
import tikTok from 'assets/images/socialIcons/tiktok.webp';
import twitter_new from 'assets/images/socialIcons/twitter.webp';
import whatsapp from 'assets/images/socialIcons/whatsapp.webp';
import yelp from 'assets/images/socialIcons/yelp.webp';
import youtube from 'assets/images/socialIcons/youtube.webp';
import zeemee from 'assets/images/socialIcons/zeemee.webp';
import { SocialLinksTypes } from '../const';

interface ISocialIcons {
  socialNetwork: SocialLinksTypes | undefined;
}

const socialIconProps = {
  instagram: { src: instagram, alt: 'Instagram icon' },
  bni: { src: bni, alt: 'Instagram icon' },
  carsdotcom: { src: cars, alt: 'Cars.com icon' },
  edmunds: { src: edmunds, alt: 'Edmunds icon' },
  dealerRater: { src: dealerReater, alt: 'Instagram icon' },
  linkedIn: { src: linkedIn, alt: 'Dealer Rater icon' },
  tikTok: { src: tikTok, alt: 'TikTok icon' },
  twitter: { src: twitter_new, alt: 'Twitter icon' },
  whatsApp: { src: whatsapp, alt: 'Whatsapp icon' },
  youtube: { src: youtube, alt: 'Youtube icon' },
  yelp: { src: yelp, alt: 'Yelp icon' },
  pinterest: { src: pinterest, alt: 'Pinterest icon' },
  facebook: { src: facebook, alt: 'Facebook icon' },
  zeemee: { src: zeemee, alt: 'ZeeMee icon' },
};

const commonStyles = {
  width: '100%',
  height: '100%',
  maxWidth: '40px',
  maxHeight: '40px',
  borderRadius: '4px',
  cursor: 'pointer',
};

export const socialIcons = ({ socialNetwork }: ISocialIcons) => {
  if (!socialNetwork || !socialIconProps[socialNetwork])
    return (
      <div style={{ ...commonStyles, background: 'rgba(244,243,241, .7)' }} />
    );

  const { alt, src } = socialIconProps[socialNetwork];

  return <img src={src} alt={alt} style={commonStyles} />;
};
