import { OrganizationRegion } from 'lib/context';
import { useQuery } from 'react-query';
import { organizationKeys } from './organizationKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getOrganizationRegions = async (
  organizationId: string | number | undefined
): Promise<OrganizationRegion[]> => {
  return (await EXPRESS_API.get(`organization/${organizationId}/region`))
    .data as OrganizationRegion[];
};

export const useGetOrganizationRegionsQuery = (
  organizationId: string | number | undefined
) => {
  return useQuery(
    organizationKeys.organizationRegions(organizationId),
    () => getOrganizationRegions(organizationId),
    {
      enabled: !!organizationId,
    }
  );
};
