import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';
import { IoMdArrowDropdown, IoMdClose } from 'react-icons/io';

const SelectContainer = styled.div`
  display: flex;
  width: 200px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  border-radius: 6px;
  border: 1px solid ${theme.palette.covideoBlue20};
  background: ${theme.palette.white};
  cursor: pointer;

  position: relative;
`;

const SelectElement = styled.select<OptionProps>`
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  height: 50px;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;

  color: ${props => (!!props.value ? `black` : theme.palette.neutral60)};

  padding: 8px 8px 8px 12px;
`;
type OptionProps = {
  value: number;
};

const OptionElement = styled.option<OptionProps>`
  color: ${props => (!!props.value ? `black` : theme.palette.neutral60)};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  right: 7px;
`;

const SvgContainer = styled.div`
  margin-right: 7px;
  position: absolute;
  right: 12px;
  top: 1px;
`;

type Option = {
  value: number;
  label: string;
};

type SelectProps = {
  options: Option[];
  value: Option;
  placeholder?: string;
  isClearable: boolean;
  onSelect: (arg: Option) => void;
};

const CustomSelect = ({
  options,
  value,
  isClearable,
  onSelect,
}: SelectProps) => {
  return (
    <SelectContainer>
      <SelectElement
        value={value.value}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const regionId = Number(e.target.value) || 0;
          const option = options.find((op: Option) => op.value === regionId);
          if (!option) return;
          onSelect(option);
        }}
      >
        {options.map((option: Option) => (
          <OptionElement key={`${option.value}`} value={option.value}>
            {option.label}
          </OptionElement>
        ))}
      </SelectElement>

      <IconContainer>
        {isClearable && (
          <SvgContainer
            onClick={() => {
              onSelect({ value: 0, label: 'Select Region' });
            }}
          >
            <IoMdClose color={theme.palette.neutral60} />
          </SvgContainer>
        )}
        <IoMdArrowDropdown color={theme.palette.neutral60} />
      </IconContainer>
    </SelectContainer>
  );
};

export default CustomSelect;
