import { IWhiteLabel } from 'lib/api/whitelabel/getWhiteLabel';

export const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=`;
export const twitterUrl = `https://twitter.com/intent/tweet?text=`;
export const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=`;
type Links = {
  [index: string]: string;
};

export const youtubeLinks: Links = {
  Covideo: 'https://www.covideo.com/backoffice/yt_share.php',
  App2Vid: 'https://members.app2vid.com/backoffice/yt_share_app2vid.php',
};

export const getYoutubeUrl = (whitelabel: IWhiteLabel, videoId?: string) => {
  let youtubeUrl = youtubeLinks[whitelabel?.name || ''];

  if (youtubeUrl) {
    youtubeUrl += `?vid=${videoId}`;
  }
  return youtubeUrl;
};
