import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';

import { Main } from './details';
import { SalesforceIntegration } from './components';

export const Salesforce = () => {
  return (
    <>
      <DocumentHead title='Integrations-Salesforce ' />
      <PageTemplate
        modal={[]}
        main={
          <Main>
            <SalesforceIntegration />
          </Main>
        }
      />
    </>
  );
};
