import React from 'react';
import styled from 'styled-components/macro';
import { Circle } from 'rc-progress';
import { useCovideoTheme } from 'react-covideo-common';

const CircleText = styled.div`
  display: flex;
  flex-direction: column;
  &:nth-child(2) {
    align-items: center;
    justify-content: space-between;
  }
  &:nth-child(3) {
    align-items: flex-end;
    justify-content: flex-end;
  }

  .checkmark {
    border-radius: 20px;
    position: absolute;
    top: 106px;
    z-index: 4;
    color: white;
  }
  .rc-progress-circle {
    background-color: #f6f7f9;
  }
  & > div {
    z-index: 3;
    border-radius: 50px;
    position: absolute;
    top: 105px;
  }
`;

const Text = styled.span<{ isInProgressOrDone: boolean }>`
  top: 0px;
  font-size: 16px;
  line-height: 1.5;
  color: ${props => {
    if (!props.isInProgressOrDone) {
      return `rgba(213,214,217,1);`;
    }
    return `${props.theme.colors.primary[100]};`;
  }}

  margin-bottom: 8px;
`;

const getProgressStatusText = (progressType: string, percentage: number) => {
  switch (progressType) {
    case 'encode':
      if (percentage === 0) return 'Waiting...';
      if (percentage > 1 && percentage < 99) return 'Encoding';
      if (percentage === 100) return 'Encoded';
      break;
    case 'create':
      if (percentage === 0) return 'Waiting...';
      if (percentage > 1 && percentage < 99) return 'Creating';
      if (percentage === 100) return 'Created';
      break;
    case 'upload':
      if (percentage === 0) return 'Waiting...';
      if (percentage > 1 && percentage < 99) return 'Uploading';
      if (percentage === 100) return 'Uploaded';
      break;
    default:
      return '';
  }
};

export const ProgressCircle = ({
  progress,
  progressType,
}: {
  progress: {
    loaded: number;
    total: number;
    percentage: number;
  };
  progressType: string;
}) => {
  const themes = useCovideoTheme();
  const circleStyle = {
    width: '24px',
    zIndex: 2,
    backgroundColor: 'f6f7f9',
  };
  const { percentage } = progress;

  const isInProgressOrDone = percentage > 1 && percentage <= 100;

  return (
    <CircleText>
      <Text isInProgressOrDone={isInProgressOrDone}>
        {getProgressStatusText(progressType, percentage)}
      </Text>

      <Circle
        style={circleStyle}
        percent={percentage}
        trailWidth={10}
        strokeWidth={10}
        strokeColor={themes.colors.primary[100]}
      />
    </CircleText>
  );
};
