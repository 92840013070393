import * as React from 'react';
import { DocumentHead, PageTemplate, LoadingIndicator } from 'lib/components';
import { Main } from './main';

interface IProps {
  edit: 'user' | 'company';
}

export const EditMyProfile = ({ edit }: IProps) => {
  const getModal = () => {
    const loading = false;
    if (loading) {
      return <LoadingIndicator isLoading={loading} />;
    }
    return null;
  };
  return (
    <>
      <DocumentHead title='Edit your profile' />
      <PageTemplate modal={[getModal()]} main={<Main edit={edit} />} />
    </>
  );
};
