import React from 'react';
import './componentCss/Switch.css';

interface Props {
  isOn: any;
  handleToggle: any;
  onColor: string;
  id: string;
  disabled?: boolean;
}
export const Switch = (props: Props) => {
  const { id, isOn, handleToggle, onColor, disabled } = props;
  return (
    <>
      <input
        disabled={disabled}
        checked={isOn}
        onChange={handleToggle}
        className='react-switch-checkbox'
        id={id}
        type='checkbox'
      />
      <label
        style={{ background: isOn && onColor }}
        className='react-switch-label'
        htmlFor={id}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};
