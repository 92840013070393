import { theme } from 'lib/style';
import React from 'react';
import { MdInfo } from 'react-icons/md';
import styled from 'styled-components/macro';

const InfoMsgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: rgba(242, 244, 246, 1);
  border-radius: 5px;
  border: 1px solid rgba(0, 27, 83, 0.05);
  padding: 12px 0px;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

export const EmptyWidget = () => (
  <InfoMsgWrapper>
    <MdInfo color={theme.palette.blue40} size={20} />
    <Paragraph>No data to show for the selected timeframe.</Paragraph>
  </InfoMsgWrapper>
);
