import { useFormikContext } from 'formik';
import {
  ParentCustomer,
  User,
} from 'lib/api/multiLocations/multiLocationQuery';
import { useFreeUsersQuery } from 'lib/api/multiLocations/useFreeUsersQuery';
import {
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import React from 'react';
import { ItemContainer } from '../ItemContainer';
import { UserDropdown, UserOption } from './UserDropdown';
interface IProps {
  user: User;
  userIdx: number;
}

export const AddUsersView = ({ user, userIdx }: IProps) => {
  const { values, setFieldValue } = useFormikContext<ParentCustomer>();

  const { data } = useFreeUsersQuery({
    search: '',
    customerId: user.customerId,
  });
  const options = data?.users.map(user => ({
    label: user.email,
    value: user.id,
  }));

  const currentItem = options?.find(item => item.value === user.id);

  return (
    <>
      <ItemContainer>
        <ParagraphNormalBold
          color={theme.palette.gray100}
          ellipsis
          style={{ whiteSpace: 'nowrap', width: 210 }}
        >
          {currentItem?.label}
        </ParagraphNormalBold>
        <ParagraphSmall
          color={theme.palette.gray60}
          ellipsis
          textAlign='right'
          style={{ whiteSpace: 'nowrap', width: 80, textAlign: 'right' }}
        >
          {currentItem?.value}
        </ParagraphSmall>
      </ItemContainer>

      {values.childCustomers.map((childCustomer, childCustomerIdx) => {
        const onChildUserDropdownChangeHandler = (option: UserOption) => {
          const userInfo =
            option === null
              ? {
                  id: null,
                  customerId: null,
                  parentUserId: null,
                  parentCustomerId: null,
                  email: '',
                  username: '',
                  lastName: '',
                  firstName: '',
                }
              : {
                  id: option.value,
                  customerId: childCustomer.customerId,
                  parentUserId: option.parentUserId,
                  parentCustomerId: values.customerId,
                  email: option.email,
                  username: option.username,
                  lastName: option.lastName,
                  firstName: option.lastName,
                };

          setFieldValue(
            `childCustomers[${childCustomerIdx}].users.${[userIdx]}`,
            userInfo
          );
        };
        const user = childCustomer?.users?.[userIdx];
        return (
          <ItemContainer width='100%'>
            <UserDropdown
              key={childCustomerIdx}
              user={user}
              onChange={onChildUserDropdownChangeHandler}
              childCustomerId={childCustomer.customerId}
              parentUserId={values.users[userIdx].id}
            />
          </ItemContainer>
        );
      })}
    </>
  );
};
