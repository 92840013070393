import { Dictionary, keyBy, isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { HoverPopup } from 'lib/components';
import { Recipient, RecipientTypeEnum } from '../types';

const Badge = styled.div<{ type?: string }>`
  padding: 2px 8px;
  background: #e6e8ee;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.secondary[5]};
  color: ${({ theme }) => theme.colors.secondary[100]};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`;

const RecipientsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RecipientRow = styled.div<{ striketrough: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${({ striketrough }) =>
    striketrough &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        border-bottom: 1px solid ${theme.palette.gray100};
      }
    `}
`;

const RecipientName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 335px;
`;

const RecipientBadgeWrapper = styled.div`
  margin-left: 16px;
  flex-shrink: 0;
`;

type Props = {
  recipientsWithType: Dictionary<Recipient>;
  recipientsOther: Dictionary<Recipient>;
  type: RecipientTypeEnum;
  onlyWithoutOther?: boolean;
};

export const RecipientsBadge = ({
  recipientsWithType,
  recipientsOther,
  type,
  onlyWithoutOther = false,
}: Props) => {
  const recipientsWithBadge = Object.values(recipientsWithType).map(
    (recipient: Recipient) => {
      const hasOtherType = !!recipientsOther[recipient.id];
      const name = `${recipient.firstName || ''} ${
        recipient.lastName || ''
      } - ${
        type === RecipientTypeEnum.SMS ? recipient.phone : recipient.email
      }`;
      let badge = '';
      if (hasOtherType) {
        badge = `${
          type === RecipientTypeEnum.SMS
            ? RecipientTypeEnum.EMAIL
            : RecipientTypeEnum.SMS
        } recipient`;
      }
      return { id: recipient.id, name, badge };
    }
  );

  const actualRecipients = useMemo(
    () =>
      recipientsWithBadge.filter(
        recipient => !onlyWithoutOther || !recipient.badge
      ),
    [recipientsWithBadge]
  );

  const actualRecipientsDictionary = useMemo(
    () => keyBy(actualRecipients, 'id'),
    [actualRecipients]
  );

  const Popup = () => {
    return (
      <RecipientsWrapper>
        {recipientsWithBadge.map((recipient, i) => (
          <RecipientRow
            key={i}
            striketrough={!actualRecipientsDictionary[recipient.id]}
          >
            <RecipientName>{recipient.name.trim() || '-'}</RecipientName>
            {recipient.badge && (
              <RecipientBadgeWrapper>
                <Badge type='orange'>{recipient.badge}</Badge>
              </RecipientBadgeWrapper>
            )}
          </RecipientRow>
        ))}
      </RecipientsWrapper>
    );
  };

  return (
    <HoverPopup
      width='450px'
      position='above'
      popup={<Popup />}
      hoverable={
        <Badge>{`${actualRecipients.length} recipient${
          actualRecipients.length !== 1 ? 's' : ''
        }`}</Badge>
      }
      disableHover={isEmpty(recipientsWithType)}
      showTriangle={true}
    />
  );
};
