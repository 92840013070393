import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';

export const ProfileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 296px;
  width: 100%;
  gap: 8px;
  padding: 24px;
  box-sizing: border-box;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  padding: 0px 24px;
  background: ${theme.palette.covideoSecondaryButtonBg};
`;

export const BlueParagraphWrapper = styled.div<{ width?: string }>`
  max-width: ${({ width }) => width};
`;

export const BlueParagraph = styled.div<{ width?: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #001b53;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => width || 'auto'};
`;
export const NoOneChip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;

  background: #fef6f5;
  border-radius: 4px;
  color: rgba(232, 76, 61, 0.8);
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
`;

export const Title = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 27, 83, 0.6);
`;

export const Chip = styled.div<{ isOnline: boolean }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  border-radius: 5px;
  padding: 2px 8px;
  ${({ isOnline }) =>
    isOnline &&
    css`
      background: rgba(30, 164, 95, 0.05);
      color: rgba(30, 164, 95, 0.8);
    `}
  ${({ isOnline }) =>
    !isOnline &&
    css`
      background: rgba(232, 76, 61, 0.05);
      color: rgba(232, 76, 61, 0.8);
    `}
`;
