import { useContactUsModal } from 'lib/context';
import { theme } from 'lib/style';
import { productFeatureData } from 'lib/utils/productFeature';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { IoMdLock, IoMdUnlock } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

type ProductInfoTooltipProps = {
  productFeatureId?: number;
  delay?: number;
  nextPlan?: string;
  hideToolTip?: boolean;
  width?: string;
  isPaid?: boolean;
  paidUrl?: string;
  direction?: string;
  style?: React.CSSProperties;
  isNonFreemiumTooltip?: boolean;
  tooltipText?: string;
  visibility?: boolean;
};

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  position: relative;
  align-items: center;
  gap: 4px;
  width: ${props => (props.width ? props.width : 'auto')};
`;

const Tip = styled.div`
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px 12px 24px;
  color: var(--tooltip-text-color);
  background: #fff;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  width: 210px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: 1px solid #f2f4f6;
  box-shadow:
    0px 0px 2px rgb(66 79 104 / 8%),
    0px 4px 8px rgb(66 79 104 / 3%);
  top: -8px;
  left: 309px;

  &.top {
    top: calc(var(--tooltip-margin) * -1);
    &:before {
      top: 100%;
      border-top-color: var(--tooltip-background-color);
    }
  }

  &.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &:before {
      left: calc(var(--tooltip-arrow-size) * -1);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: var(--tooltip-background-color);
    }
  }

  &.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
    &:before {
      bottom: 100%;
      border-bottom-color: var(--tooltip-background-color);
    }
  }

  &.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &:before {
      left: auto;
      right: calc(var(--tooltip-arrow-size) * -2);
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: var(--tooltip-background-color);
    }
  }
`;

const Title = styled.div<{ color?: string }>`
  font-weight: 700;
  margin-right: auto;
  color: ${props => (props.color ? props.color : theme.palette.covideoGray100)};
`;

const Content = styled.div<{ color?: string; textOpacity?: string }>`
  width: 100%;
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${props => (props.color ? props.color : theme.palette.covideoGray100)};
  opacity: ${props => (props.textOpacity ? props.textOpacity : '0.6')};
  text-align: initial;
  margin: 8px 0;
`;

const ProductInfoTooltip: React.FC<ProductInfoTooltipProps> = ({
  productFeatureId,
  delay = 600,
  nextPlan = 'Pro',
  hideToolTip,
  width,
  isPaid,
  paidUrl,
  children,
  direction,
  style,
  isNonFreemiumTooltip = false,
  tooltipText = null,
  visibility = true,
}) => {
  let timeout: NodeJS.Timeout;
  const defaultTitleDescription = {
    title: 'Upgrade',
    description: 'Upgrade your account to enable this feature',
  };

  const [active, setActive] = useState<boolean>(false);
  const history = useHistory();
  const { setShowContactUsModal } = useContactUsModal();

  const { title, description } = productFeatureId
    ? productFeatureData[productFeatureId] || defaultTitleDescription
    : defaultTitleDescription;

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    clearInterval(timeout);
    timeout = setTimeout(() => {
      setActive(false);
    }, delay);
  };

  if (!visibility) {
    return <>{children}</>;
  }

  return (
    <Wrapper onMouseEnter={showTip} onMouseLeave={hideTip} width={width}>
      {children}
      {active && !hideToolTip && (
        <Tip className={direction ? direction : ''} style={style}>
          {isNonFreemiumTooltip && (
            <Content
              color={style?.color || ''}
              textOpacity={(style?.opacity as string) || ''}
            >
              {tooltipText}
            </Content>
          )}
          {!isNonFreemiumTooltip && (
            <>
              <Title>{title}</Title>
              <Content>{description}</Content>

              {isPaid ? (
                <Button
                  text='Activate'
                  icon={<IoMdLock />}
                  onClick={() => {
                    history.push(paidUrl!);
                  }}
                />
              ) : (
                <Button
                  text={`Upgrade to ${nextPlan}`}
                  icon={<IoMdUnlock />}
                  onClick={() => {
                    setShowContactUsModal(true);
                  }}
                />
              )}
            </>
          )}
        </Tip>
      )}
    </Wrapper>
  );
};

export default ProductInfoTooltip;
