import React from 'react';
import styled from 'styled-components/macro';
import { InventoryListing } from '../components';

const Container = styled.div`
  padding: 64px 0;
  width: 1216px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: calc(100vh - 200px);
`;

export const List = () => {
  return (
    <Container>
      <InventoryListing />
    </Container>
  );
};
