import { useFormikContext } from 'formik';
import React from 'react';
import { MdArrowForwardIos } from 'react-icons/md';
import { Breadcrumb, BreadcrumbsWrapper } from './styles';
import { CustomReportModalFormikValues, STEPS } from './types';

interface IProps {
  currentStepIndex: number;
  steps: STEPS;
  onBreadcrumbClick: (stepLabel: string) => void;
}

export const CustomReportModalBreadcrumbs = ({
  currentStepIndex,
  steps,
  onBreadcrumbClick,
}: IProps) => {
  const { errors } = useFormikContext<CustomReportModalFormikValues>();

  const isBreadcrumbValid = (index: number) => {
    if (index === 0) {
      return true;
    }

    return steps
      .slice(0, index)
      .every(
        step =>
          !step.errorName ||
          !errors[step.errorName as keyof CustomReportModalFormikValues]
      );
  };

  return (
    <BreadcrumbsWrapper>
      {steps.map((step, index) => (
        <React.Fragment key={step.value}>
          <Breadcrumb
            active={currentStepIndex === index}
            onClick={() => {
              if (!!isBreadcrumbValid(index)) {
                return onBreadcrumbClick(step.label);
              }
            }}
            isValid={!!isBreadcrumbValid(index)}
          >
            {step.label}
          </Breadcrumb>
          {index !== steps.length - 1 && (
            <MdArrowForwardIos size={10} color={'#D0D3D9'} />
          )}
        </React.Fragment>
      ))}
    </BreadcrumbsWrapper>
  );
};
