import { useQuery } from 'react-query';
import { videoRequestKeys } from './videoRequestKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { VideoStatus } from 'lib/const/VideoStatus';

// TODO: separate endpoint since only count needed
const countPendingVideoRequests = async () => {
  const params = {
    statuses: VideoStatus.Pending,
    start: 0,
    limit: 100,
  };

  const response = await EXPRESS_API.get(`/video-requests`, { params });
  return response.data;
};

export const useCountPendingVideoRequestsQuery = (enabled: boolean) => {
  return useQuery(
    videoRequestKeys.count_pending(),
    () => countPendingVideoRequests(),
    {
      refetchOnMount: true,
      enabled,
    }
  );
};
