import { useQuery } from 'react-query';
import { billingKeys } from './billingKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks';

export type BillingDetailsAddress = {
  line1: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
};

export type BillingDetails = {
  name: string;
  address: BillingDetailsAddress;
};
export type Card = {
  id: string;
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  billing_details: BillingDetails;
  isDefault?: boolean;
};

export type GetCardsResponse = {
  methods: Card[];
  hasMore: boolean;
};

type Params = { limit?: number };

export const getCards = async (
  params: Params,
  stripeEnabled: boolean
): Promise<GetCardsResponse> => {
  if (!stripeEnabled) {
    return { methods: [], hasMore: false } as GetCardsResponse;
  }
  const response = await EXPRESS_API.get<GetCardsResponse>(
    '/register/payment/methods',
    {
      params,
    }
  );
  return response.data;
};

export const useCardsQuery = (params: Params, stripeEnabled: boolean) => {
  const { showError } = useToastError();
  return useQuery<GetCardsResponse, Error>(
    billingKeys.cards(params),
    () => getCards(params, stripeEnabled),
    {
      onError: error => showError(error || 'Error in fetching cards.'),
    }
  );
};
