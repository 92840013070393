import { decodeAndReplaceText } from 'lib/utils/functions';
import { SnippetItem } from './types';

export const encodeText = (
  emailText: string | undefined,
  smsText: string | undefined
) => {
  const encodeEmailText = encodeURIComponent(emailText || '');
  const encodeSmsText = encodeURIComponent(smsText || '');
  return { emailText: encodeEmailText, smsText: encodeSmsText };
};

export const decodeText = (snippet: SnippetItem) => {
  snippet.emailText = decodeAndReplaceText(snippet.emailText || '', true);
  snippet.smsText = decodeAndReplaceText(snippet?.smsText || '', true);
  return snippet;
};
