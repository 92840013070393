import { useParams } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useToastError } from 'lib/hooks';
import { guidesKeyes } from './guidesKeyes';
import { EXPRESS_API } from 'configs/api/expressApi';

type RateValue = 'up' | 'down';

const rateGuide = async (videoId: number, rate: RateValue): Promise<void> => {
  return (
    await EXPRESS_API.patch(`guides/${videoId}/rate`, {
      rate,
    })
  ).data;
};

export const useRateGuideQuery = () => {
  const { videoId } = useParams() as { videoId: string };
  const parsedVideoId = Number(videoId);
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation((rate: RateValue) => rateGuide(parsedVideoId, rate), {
    onError: err => showError(err),
    onSuccess: async () => {
      await queryClient.invalidateQueries(guidesKeyes.details(parsedVideoId));
    },
  });
};
