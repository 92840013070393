import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { multilocationKeys } from './multilocationsKeys';

const multilocationConfigurationDelete = async ({
  customerId,
}: {
  customerId: number | undefined;
}): Promise<void> => {
  if (!customerId) {
    return;
  }
  return (await EXPRESS_API.delete(`superadmin/multilocation/${customerId}`))
    .data;
};
export const useMultilocationConfigurationDelete = (
  closeModalHandler: () => void
) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(multilocationConfigurationDelete, {
    onError: err => showError(err),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries(multilocationKeys.list());
      queryClient.removeQueries(
        multilocationKeys.single(`${variables.customerId}`)
      );
      successToast({
        title:
          'You have successfully deleted the Multi-location configuration!',
      });
      closeModalHandler();
    },
  });
};
