import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { locationKeys } from './queryKeys';

export interface ILocation {
  locationId: number;
  customerId: number;
  name: string;
  streetAddress?: string;
  state?: string;
}

const getUserLocations = async (
  userId: number | string
): Promise<ILocation[]> => {
  return (await EXPRESS_API.get(`locations/user/${userId}`))
    .data as ILocation[];
};

export const useGetLocationsQuery = (
  enabled: boolean,
  userId: number | string
) => {
  const { data, isLoading, refetch } = useQuery(
    locationKeys.details(userId),
    () => getUserLocations(userId),
    {
      enabled,
    }
  );

  return { data: data || [], isLoading, refetch };
};
