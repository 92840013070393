import React from 'react';
import { useCovideoTheme } from 'react-covideo-common';
import styled, { css } from 'styled-components/macro';

const CommonStyles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.neutral[60]};
`;

const StyledLabel = styled.label`
  ${CommonStyles}
  display: block;
`;

const StyledDiv = styled.div`
  ${CommonStyles}
`;

interface IFormikLabelProps {
  name?: string;
  label: string;
  isRequired: boolean;
}

export const FormikLabel: React.FC<IFormikLabelProps> = ({
  name,
  label,
  isRequired,
}) => {
  const themes = useCovideoTheme();

  if (name) {
    return (
      <StyledLabel htmlFor={name}>
        {label}{' '}
        {isRequired && (
          <span style={{ color: themes.colors.primary[100] }}>*</span>
        )}
      </StyledLabel>
    );
  }
  return (
    <StyledDiv>
      {label}{' '}
      {isRequired && (
        <span style={{ color: themes.colors.primary[100] }}>*</span>
      )}
    </StyledDiv>
  );
};
