import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { guidesKeyes } from './guidesKeyes';

const revokeVisibilityAccess = async ({
  categoryId,
}: {
  categoryId: number | undefined;
}): Promise<void> => {
  return (await EXPRESS_API.delete(`guide-category/${categoryId}/visibility`))
    .data;
};
export const useRevokeVisibilityAccess = (closeModalHandler: () => void) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { showError } = useToastError();
  return useMutation(revokeVisibilityAccess, {
    onError: err => showError(err),
    onSuccess: async () => {
      successToast({ title: 'You have revoked access to all users!' });
      queryClient.removeQueries(guidesKeyes.visibility());
      closeModalHandler();
      history.push('/guides');
    },
  });
};
