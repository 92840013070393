import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Country } from 'lib/const';
import Select from 'react-select';
import { useToastError } from 'lib/hooks';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { IoMdCalendar } from 'react-icons/io';

type Props = {
  handleModalClose?: (shouldRefresh?: boolean) => void;
  onSubmit: React.Dispatch<React.SetStateAction<any>>;
  signupPage?: boolean;
  subscriptionData: any;
  onBackButtonPress: () => void;
};

interface ContentHeaderProps {
  textAlign?: string;
}

interface ContentProps {
  minWidth?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow:
    0px 0px 4px rgba(66, 79, 104, 0.08),
    0px 12px 20px rgba(66, 79, 104, 0.06);
  border-radius: 16px;
  min-height: 392px;
  height: auto;
  box-sizing: border-box;
  margin-top: 25px;
  min-width: ${props => (props.minWidth ? props.minWidth : '100%')};
  @media screen and (max-width: 480px) {
    min-width: 100%;
  }
`;
const ContentHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    align-self: center;
  }
`;

const ContentHeader = styled.div<ContentHeaderProps>`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.xl};
  color: ${theme.palette.covideoBlue100};

  font-weight: 800;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  width: 100%;
`;

const ContentBody = styled.div`
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.m};
  line-height: ${theme.fontSizes.xl};
  color: ${theme.palette.blackRgb75};
  overflow-wrap: break-word;
  overflow: none;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;

  .StripeForm__fieldsContainer {
    background: rgba(122, 80, 199, 0.1);
    border-radius: 4px;
    padding: 10px;

    .form-group {
      margin: 0 0 15px;
    }
  }

  .form-group {
    min-height: 40px;
    width: 100%;
  }

  .form-group {
    label,
    .form-group__label {
      display: block;
      font-size: 14px;
      &.dark--blue {
        color: ${theme.palette.primaryDarkBlue};
      }
      &.label--grey {
        color: rgba(146, 151, 162, 1);
        font-weight: 500;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .form-group span {
    display: inline-block;
    margin-top: 22px;

    &.small {
      color: #5e646e;
      font-size: 12px;

      a {
        color: #5e646e;
        text-decoration: underline;
        font-weight: normal;
      }
    }
  }

  .form-group.has-error input,
  .has-error input {
    background: #ffffff url('/dist/images/invalid.svg') calc(100% - 14px) center
      no-repeat;
    border: 1px solid #f94c4c;
  }

  .form-group.has-success input,
  .has-success input {
    border: 1px solid #008000;
  }

  .form-group.has-error input:focus,
  .has-error input:focus {
    background-position: calc(100% - 12px) center;
  }

  .form-group.has-success input:focus,
  .has-success input:focus {
    background-position: calc(100% - 12px) center;
  }

  .form-group div.help-block {
    position: absolute;
    line-height: 16px;

    span {
      font-size: 12px;
      font-weight: 700;
      color: #c8cfd9;
      margin: 0;
    }
  }

  .form-group.has-error div.help-block span {
    color: #f94c4c;
  }

  .form-group.has-success div.help-block span {
    color: #008000;
  }
`;

const FormWrapper = styled.div``;

const InternalUI = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  &.flex--start {
    justify-content: start;
    column-gap: 20px;
  }
  &.padding--align {
    padding: 0 0 0 22px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 480px) {
    align-self: center;
  }
`;

const Input = styled.input<{ width?: string; padding?: string }>`
  padding: ${props => (props.padding ? props.padding : '8px 12px')};
  box-sizing: border-box;
  width: ${props => (props.width ? props.width : '100%')};
  height: 40px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c8cfd9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  &:focus {
    outline: 0;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  color: #9297a2;
  text-align: center;
  margin-top: 30px;
`;

const GoBackText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: #272a32;
  display: flex;
  margin: 25px auto 0 auto;
  cursor: pointer;
`;

export const AddCard = ({
  onSubmit,
  subscriptionData,
  onBackButtonPress,
}: Props) => {
  const { isProPackage } = subscriptionData;
  const [cardData, setCardData] = useState<any>({
    holderName: '',
    postCode: '',
    country: 'US',
    cardNumber: '',
    expiry: '',
    cvc: '',
  });

  const [errors, setErrors] = useState<any>({});
  const { showError } = useToastError();

  const handleErrors = (event: any, param?: string | number) => {
    setErrors({ ...errors, [param ? param : event.elementType]: '' });
    if (event.error && !param) {
      setErrors({ ...errors, [event.elementType]: event.error.message });
    } else {
      if (event.length && param) {
        setCardData({ ...cardData, [param]: event });
      } else if (param) {
        setCardData({ ...cardData, [param]: '' });
        setErrors({ ...errors, [param]: 'Invalid input' });
      }
    }
  };

  const handleCardInput = (e: any, param?: string) => {
    if (param == 'cardNumber') {
      if (
        /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})|(62[0-9]{14,17})|((5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}))$/.test(
          e
        )
      ) {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: '' });
      } else {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: 'Invalid card number' });
      }
    }
    if (param == 'expiry') {
      const format = new Date().getFullYear().toString().substr(2).split('');
      const split1 = parseInt(format[0]);
      const expDateFormatter =
        e.replace(/\//g, '').substring(0, 2) +
        (e.length > 2 ? '/' : '') +
        e.replace(/\//g, '').substring(2, 4);
      if (
        new RegExp(`^(0[1-9]|1[0-2])\/?([` + split1 + `-9][0-9])$`).test(
          expDateFormatter
        )
      ) {
        setCardData({ ...cardData, [param]: expDateFormatter });
        setErrors({ ...errors, [param]: '' });
      } else {
        setCardData({ ...cardData, [param]: expDateFormatter });
        setErrors({ ...errors, [param]: 'Invalid expiry date' });
      }
    }
    if (param == 'cvc') {
      if (e.length > 2 && e.length < 5) {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: '' });
      } else {
        setCardData({ ...cardData, [param]: e });
        setErrors({ ...errors, [param]: 'Invalid cvc' });
      }
    }
  };

  const handleCardAdd = async () => {
    try {
      const { holderName, postCode, country, cardNumber, expiry, cvc } =
        cardData;
      const payload = {
        cardNumber,
        expMonth: expiry.split('/')[0],
        expYear: '20' + expiry.split('/')[1],
        cvc,
        billingCountry: country,
        billingPostalCode: postCode,
        billingName: holderName,
      };
      onSubmit(payload);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Container>
      <ContentHeaderWrap>
        <ContentHeader textAlign={isProPackage ? 'center' : 'left'}>
          {'Enter payment details'}
        </ContentHeader>
      </ContentHeaderWrap>
      {isProPackage && (
        <Description>
          <div
            style={{ color: theme.palette.covideoOrange100, fontWeight: 600 }}
          >{`Credit card will be charged ${subscriptionData.amount}`}</div>
          <div style={{ marginTop: 10, color: theme.palette.gray60 }}>{`${
            subscriptionData.annual ? 'Annual' : 'Monthly'
          } subscription renews automatically. Cancel any time.`}</div>
        </Description>
      )}
      <Content minWidth={isProPackage ? '100%' : '592px'}>
        <ContentBody>
          <FormWrapper className='StripeForm'>
            <InternalUI>
              <Row>
                <div
                  className={`form-group ${
                    errors && errors.holderName ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label dark--blue`}>
                    Card Information
                  </label>
                  <div>
                    <Input
                      type='text'
                      value={cardData.holderName}
                      placeholder='Holder Name'
                      onChange={event =>
                        handleErrors(event.target.value, 'holderName')
                      }
                    />
                  </div>
                  {errors && errors.holderName && (
                    <div className='help-block'>
                      <span>{errors.holderName}</span>
                    </div>
                  )}
                </div>
              </Row>
              <Row>
                <div
                  className={`form-group ${
                    errors && errors.cardNumber ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>Card Number</label>
                  <div>
                    <Input
                      type='text'
                      value={cardData.cardNumber}
                      placeholder='Card Number'
                      onChange={event =>
                        handleCardInput(event.target.value, 'cardNumber')
                      }
                    />
                  </div>
                  {errors && errors.cardNumber && (
                    <div className='help-block'>
                      <span>{errors.cardNumber}</span>
                    </div>
                  )}
                </div>
              </Row>
              <Row className='flex--start'>
                <div
                  style={{ width: 104 }}
                  className={`form-group ${
                    errors && errors.expiry ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>Exp. Date</label>
                  <div style={{ position: 'relative' }}>
                    <IoMdCalendar
                      style={{
                        position: 'absolute',
                        top: 6,
                        left: 10,
                      }}
                    />

                    <Input
                      width={'104px'}
                      type='text'
                      value={cardData.expiry}
                      placeholder='MM/YY'
                      onChange={event =>
                        handleCardInput(event.target.value, 'expiry')
                      }
                      padding='0px 0px 0px 40px'
                    />
                  </div>
                  {errors && errors.expiry && (
                    <div className='help-block'>
                      <span>{errors.expiry}</span>
                    </div>
                  )}
                </div>
                <div
                  style={{ width: 64 }}
                  className={`form-group ${
                    errors && errors.cvc ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>CVV</label>
                  <div>
                    <Input
                      type='number'
                      value={cardData.cvc}
                      placeholder='CVV'
                      onChange={event =>
                        handleCardInput(event.target.value, 'cvc')
                      }
                    />
                  </div>
                  {errors && errors.cvc && (
                    <div className='help-block'>
                      <span>{errors.cvc}</span>
                    </div>
                  )}
                </div>
              </Row>

              <Row className='flex--start'>
                <div
                  className={`form-group ${
                    errors && errors.country ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>Country or a region</label>
                  <div>
                    <Select
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          height: '40px',
                          width: '100%',
                        }),
                        indicatorSeparator: () => ({ display: 'none' }),
                        container: () => ({ width: '100%' }),
                        menuPortal: (base: any) => ({ ...base, zIndex: 1000 }),
                        option: (base: any) => ({
                          ...base,
                          borderBottom: '1px solid #EEEFF2',
                        }),
                      }}
                      options={Country}
                      menuPortalTarget={document.body}
                      menuPosition='fixed'
                      menuPlacement={'bottom'}
                      getOptionValue={option => option.countryShortCode}
                      getOptionLabel={option => option.countryName}
                      value={Country.find((o: any) => {
                        return o.countryShortCode == cardData.country;
                      })}
                      onChange={(option: any) => {
                        setCardData({
                          ...cardData,
                          country: option.countryShortCode,
                        });
                      }}
                      placeholder='Select Country'
                    />
                  </div>
                  {errors && errors.country && (
                    <div className='help-block'>
                      <span>{errors.country}</span>
                    </div>
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors && errors.postCode ? 'has-error' : ''
                  }`}
                >
                  <label className={`control-label`}>ZIP</label>
                  <div>
                    <Input
                      type='text'
                      value={cardData.postCode}
                      placeholder=''
                      onChange={event =>
                        handleErrors(event.target.value, 'postCode')
                      }
                      width='100%'
                    />
                  </div>
                  {errors && errors.postCode && (
                    <div className='help-block'>
                      <span>{errors.postCode}</span>
                    </div>
                  )}
                </div>
              </Row>
              <Row className='flex--start'>
                <Button
                  disabled={
                    Object.keys(errors).some(e => errors[e].length) ||
                    Object.keys(cardData).some(e => !cardData[e].length)
                  }
                  text={`Finish Checkout`}
                  onClick={handleCardAdd}
                />
              </Row>
            </InternalUI>
          </FormWrapper>
        </ContentBody>
      </Content>
      <ButtonWrapper>
        {isProPackage ? (
          <GoBackText onClick={onBackButtonPress}>Go Back to Plans</GoBackText>
        ) : (
          <Button
            icon={<MdKeyboardBackspace />}
            variant='secondary'
            text={`Back to Plans`}
            onClick={onBackButtonPress}
          />
        )}
      </ButtonWrapper>
    </Container>
  );
};
