import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { sharedReportKeys } from './sharedReportKeys';
import { CustomReportItem } from 'lib/context';

export type SharedReportResponse = {
  id: number;
  userId: number;
  reportId: number;
  report: string;
  email: string;
  from: string;
  to: string;
  range: string;
  dateSent: string;
  recurring: number;
  snapshotUrl: string | null;
  customReport: CustomReportItem;
};

export type SharedReportParams = {
  page?: number;
  size?: number;
  search?: string;
  sort?: string;
  senderId?: number | null;
  recurring?: string;
};

export const getSharedReports = async ({
  page = 0,
  size = 10,
  ...rest
}: SharedReportParams): Promise<{
  count: number;
  sharedReports: SharedReportResponse[];
}> => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    ...rest,
  };

  const response = await EXPRESS_API.get(`/shared-reports`, {
    params,
  });

  return response.data;
};

export const useSharedReportsQuery = ({
  params,
  enabled,
}: {
  params: SharedReportParams;
  enabled: boolean;
}) => {
  return useQuery(
    sharedReportKeys.shared_reprots(params),
    () => getSharedReports(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
    }
  );
};
