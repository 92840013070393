import { ResponseType } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';

export type PaymentCardType = {
  cardNumber: string;
  expMonth: string;
  expYear: string;
  cvc: string | number;
  billingCountry: string;
  billingPostalCode: string;
  billingAddress?: string;
  billingCity?: string;
  billingState?: string;
  billingName: string;
};

export const CreateTrialSubscription = async (data: {
  cardNumber: string;
  expMonth: string;
  expYear: string;
  cvc: string;
  billingCountry: string;
  billingPostalCode: string;
  billingEmail: string;
  fullName: string;
  emailAddress: string;
}) => {
  const response = await EXPRESS_API.post('/register/payment/create', data);
  return response.data;
};

export const AddNewCard = async (data: PaymentCardType) => {
  const response = await EXPRESS_API.post('/register/payment/methods', data);
  return response.data;
};

export const removeCard = async (cardId: string) => {
  const data = { id: cardId };
  const response = await EXPRESS_API.delete('/register/payment/subscriptions', {
    data,
  });
  return response.data;
};

export const defaultCardUpdate = async (cardId: string) => {
  const data = { defaultMethod: cardId };
  const response = await EXPRESS_API.put(
    '/register/payment/subscriptions',
    data
  );
  return response.data;
};

export const cancelAccountSubscription = async (data: any) => {
  const response = await EXPRESS_API.put(
    '/register/payment/subscriptions',
    data
  );
  return response.data;
};

export const updateAccountSubscription = async (data: {
  quantity: string | number;
  interval: string;
}) => {
  const response = await EXPRESS_API.put(
    '/register/payment/subscriptions/items',
    data
  );
  return response.data;
};

export const upgradeOrCreateSubscription = async (data: {
  paymentId: string;
  addons: any;
  quantity: number;
  interval: string;
  coupon?: string;
}) => {
  const response = await EXPRESS_API.post(
    '/register/payment/subscriptions',
    data
  );
  return response.data;
};

export const getCouponDetails = async (coupon: string) => {
  const response = await EXPRESS_API.get(`/register/payment/coupons`, {
    params: { code: coupon },
  });
  return response.data;
};

export const addOrUpdatePlanSubscription = async (data: {
  paymentId?: string;
  packageId: number;
  quantity?: number;
  interval?: string | '';
  addons?: string[];
  cancellationReason?: string | '';
}) => {
  const response = await EXPRESS_API.post('/register/payment/plan', data);
  return response.data;
};

export const getInvoiceData = async (previewUrl: string) => {
  const response = await EXPRESS_API.get(previewUrl, {
    responseType: 'arraybuffer' as ResponseType,
  });
  return response.data;
};
