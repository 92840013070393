// webglUtils.ts
export const VIEWPORT_HEIGHT = 720;
export const VIEWPORT_WIDTH = 1280;
export type WebGlState = {
  gl: WebGL2RenderingContext | null;
  program: WebGLProgram | null;
};

// Helper function to create a WebGL shader
export const createShader = (
  gl: WebGLRenderingContext,
  type: number,
  source: string
) => {
  const shader = gl.createShader(type)!;
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    console.error('Error compiling shader:', gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
    return null;
  }
  return shader;
};

// Helper function to create a WebGL program
export const createProgram = (
  gl: WebGLRenderingContext,
  vertexShaderSource: string,
  fragmentShaderSource: string
) => {
  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = createShader(
    gl,
    gl.FRAGMENT_SHADER,
    fragmentShaderSource
  );
  if (!vertexShader || !fragmentShader) {
    return null;
  }

  const program = gl.createProgram()!;
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);

  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error('Error linking program:', gl.getProgramInfoLog(program));
    gl.deleteProgram(program);
    return null;
  }

  // Use the program immediately after linking
  gl.useProgram(program);

  return program;
};

export const updateTexture = (
  gl: WebGLRenderingContext,
  texture: WebGLTexture,
  source: HTMLVideoElement | Uint8Array | HTMLImageElement,
  width: number,
  height: number
) => {
  gl.bindTexture(gl.TEXTURE_2D, texture);
  if (source instanceof Uint8Array) {
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.ALPHA,
      width,
      height,
      0,
      gl.ALPHA,
      gl.UNSIGNED_BYTE,
      source
    );
  } else {
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, source);
  }
};

// Helper function to create a texture from an image
export const createTexture = (gl: WebGLRenderingContext) => {
  const texture = gl.createTexture()!;
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  return texture;
};

// Initialize WebGL context and shaders
export const initWebGL = (canvas: HTMLCanvasElement): WebGlState => {
  const gl = canvas.getContext('webgl2');
  if (!gl) {
    console.error('WebGL not supported');
    return { gl: null, program: null };
  }
  // Set the viewport to cover the entire canvas
  gl.viewport(0, 0, VIEWPORT_WIDTH, VIEWPORT_HEIGHT);

  const vertexShaderSource = `
    precision highp float;

    attribute vec4 a_position;
    attribute vec2 a_texCoord;
    varying vec2 v_texCoord;

    void main() {
      gl_Position = a_position;  // Pass the position through to gl_Position
      // Flip the texture coordinates vertically by inverting the Y axis
      v_texCoord = vec2(a_texCoord.x, 1.0 - a_texCoord.y);
    }
  `;

  const fragmentShaderSource = `
    precision highp float;

    uniform sampler2D u_foreground;
    uniform sampler2D u_background;
    uniform sampler2D u_confidenceMask;
    uniform sampler2D u_confidenceMaskHair;
    uniform sampler2D u_confidenceMaskBody;
    uniform sampler2D u_confidenceMaskFace;
    uniform sampler2D u_confidenceMaskClothes;
    uniform sampler2D u_confidenceMaskOther;
    uniform vec2 u_backgroundScale;

    varying vec2 v_texCoord;

    // Function to blend two colors
    vec4 blendColors(vec4 base, vec4 added) {
      float mixAlpha = 1.0 - (1.0 - added.a) * (1.0 - base.a);

      vec3 mixRGB;
      if (mixAlpha == 0.0) {
          mixRGB = vec3(0.0, 0.0, 0.0);
      } else {
          mixRGB.r = (added.r * added.a / mixAlpha) + (base.r * base.a * (1.0 - added.a) / mixAlpha);
          mixRGB.g = (added.g * added.a / mixAlpha) + (base.g * base.a * (1.0 - added.a) / mixAlpha);
          mixRGB.b = (added.b * added.a / mixAlpha) + (base.b * base.a * (1.0 - added.a) / mixAlpha);
      }

      return vec4(mixRGB, 1.0);
    }

    void main() {

      // Adjust texture coordinates to scale the background, ensuring it's centered
      vec2 centeredCoord = (v_texCoord - 0.5) * u_backgroundScale + 0.5;

      vec4 fgColor = texture2D(u_foreground, v_texCoord);  // Foreground color
      vec4 bgColor = texture2D(u_background, centeredCoord);  // Scaled and centered background color

       //float confidence = 1.0 - texture2D(u_confidenceMask, v_texCoord).r;
      float confidenceSum = texture2D(u_confidenceMaskHair, v_texCoord).r + texture2D(u_confidenceMaskBody, v_texCoord).r + texture2D(u_confidenceMaskFace, v_texCoord).r + texture2D(u_confidenceMaskClothes, v_texCoord).r + texture2D(u_confidenceMaskOther, v_texCoord).r;
      float confidence = min(confidenceSum, 1.0);
      float minConfidence = 0.1;
      float maxConfidence = 0.95;

      // Full background when below min confidence
      if (confidence < minConfidence) {
        gl_FragColor = bgColor;
      } 
      // Full foreground when above max confidence
      else if (confidence > maxConfidence) {
        gl_FragColor = fgColor;
      } 
      // Blending between foreground and background
      else {
        vec4 blended = blendColors(vec4(fgColor.rgb, confidence), vec4(bgColor.rgb, 1.0 - confidence));
        gl_FragColor = blended;
      }
    }
  `;

  const program = createProgram(gl, vertexShaderSource, fragmentShaderSource);

  if (!program) {
    console.error('Failed to create WebGL program');
    return { gl: null, program: null };
  }

  return { gl, program };
};

// Function to set up positions and texture coordinates for a rectangle
export const setupRectangle = (
  gl: WebGLRenderingContext,
  program: WebGLProgram
) => {
  const positionLocation = gl.getAttribLocation(program, 'a_position');
  const texCoordLocation = gl.getAttribLocation(program, 'a_texCoord');

  // Full-screen quad positions (covering the entire clip space from -1 to 1)
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  const positions = new Float32Array([
    -1,
    -1, // Bottom-left
    1,
    -1, // Bottom-right
    -1,
    1, // Top-left
    1,
    1, // Top-right
  ]);
  gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

  gl.enableVertexAttribArray(positionLocation);
  gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

  // Texture coordinates (mapping the texture from (0,0) to (1,1))
  const texCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  const texCoords = new Float32Array([
    0,
    0, // Bottom-left
    1,
    0, // Bottom-right
    0,
    1, // Top-left
    1,
    1, // Top-right
  ]);
  gl.bufferData(gl.ARRAY_BUFFER, texCoords, gl.STATIC_DRAW);

  gl.enableVertexAttribArray(texCoordLocation);
  gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);
};

// Function to render using the WebGL program
export const render = (
  gl: WebGLRenderingContext,
  program: WebGLProgram,
  fgTexture: WebGLTexture,
  bgTexture: WebGLTexture,
  confidenceMaskTexture: WebGLTexture,
  confidenceMaskHairTexture: WebGLTexture,
  confidenceMaskBodyTexture: WebGLTexture,
  confidenceMaskFaceTexture: WebGLTexture,
  confidenceMaskClothesTexture: WebGLTexture,
  confidenceMaskOtherTexture: WebGLTexture,
  backgroundScale: number[] = [1.0, 1.0]
) => {
  // Bind the program before rendering
  gl.useProgram(program);

  // Activate the textures and bind them
  gl.activeTexture(gl.TEXTURE0);
  gl.bindTexture(gl.TEXTURE_2D, fgTexture);

  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, bgTexture);

  // Bind Mask textures
  gl.activeTexture(gl.TEXTURE2);
  gl.bindTexture(gl.TEXTURE_2D, confidenceMaskTexture);
  gl.activeTexture(gl.TEXTURE3);
  gl.bindTexture(gl.TEXTURE_2D, confidenceMaskHairTexture);
  gl.activeTexture(gl.TEXTURE4);
  gl.bindTexture(gl.TEXTURE_2D, confidenceMaskBodyTexture);
  gl.activeTexture(gl.TEXTURE5);
  gl.bindTexture(gl.TEXTURE_2D, confidenceMaskFaceTexture);
  gl.activeTexture(gl.TEXTURE6);
  gl.bindTexture(gl.TEXTURE_2D, confidenceMaskClothesTexture);
  gl.activeTexture(gl.TEXTURE7);
  gl.bindTexture(gl.TEXTURE_2D, confidenceMaskOtherTexture);

  // Set the textures to the uniform locations
  const fgLocation = gl.getUniformLocation(program, 'u_foreground');
  const bgLocation = gl.getUniformLocation(program, 'u_background');
  const maskLocation = gl.getUniformLocation(program, 'u_confidenceMask');
  const maskLocationHair = gl.getUniformLocation(
    program,
    'u_confidenceMaskHair'
  );
  const maskLocationBody = gl.getUniformLocation(
    program,
    'u_confidenceMaskBody'
  );
  const maskLocationFace = gl.getUniformLocation(
    program,
    'u_confidenceMaskFace'
  );
  const maskLocationClothes = gl.getUniformLocation(
    program,
    'u_confidenceMaskClothes'
  );
  const maskLocationOther = gl.getUniformLocation(
    program,
    'u_confidenceMaskOther'
  );

  gl.uniform1i(fgLocation, 0); // Foreground texture bound to TEXTURE0
  gl.uniform1i(bgLocation, 1); // Background texture bound to TEXTURE1
  gl.uniform1i(maskLocation, 2); // Confidence mask texture bound to TEXTURE2
  gl.uniform1i(maskLocationHair, 3); // ...
  gl.uniform1i(maskLocationBody, 4);
  gl.uniform1i(maskLocationFace, 5);
  gl.uniform1i(maskLocationClothes, 6);
  gl.uniform1i(maskLocationOther, 7);

  // Clear the color buffer
  gl.clear(gl.COLOR_BUFFER_BIT);

  // Scale values to maintain background texture aspect ratio
  const backgroundScaleLocation = gl.getUniformLocation(
    program,
    'u_backgroundScale'
  );
  gl.uniform2fv(backgroundScaleLocation, backgroundScale);

  // Draw the full-screen quad (2 triangles)
  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
};

export const clear = (gl: WebGLRenderingContext) => {
  gl.useProgram(null);
  gl.clearColor(0, 0, 0, 0);
  // Clear the color buffer
  gl.clear(gl.COLOR_BUFFER_BIT);
};
