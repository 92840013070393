import { capitalizeFirstLetter } from '../notifications/utils';
import { FREQUENCY_TYPE } from 'lib/components/frequency/constants';

export const dayOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map(day => ({
  value: day.toLowerCase(),
  label: day.substring(0, 1),
}));

export const monthlyDaysOptions = Array.from({ length: 31 }, (v, k) => ({
  value: (k + 1).toString(),
  label: (k + 1).toString(),
}));

export const hoursOptions = Array.from({ length: 12 }, (v, k) => {
  const option = k < 9 ? `0${k + 1}` : (k + 1).toString();
  return {
    value: option,
    label: option,
  };
});

export const isTimeValid = (time: string = '') => {
  const timeArray = time.split(':');

  const hours = parseInt(timeArray[0], 10);
  const minutes = parseInt(timeArray[1], 10);

  return (
    !isNaN(hours) &&
    !isNaN(minutes) &&
    hours >= 0 &&
    hours <= 12 &&
    minutes >= 0 &&
    minutes < 60
  );
};

export const timeFormats = [
  {
    value: 'AM',
    text: 'AM',
  },
  {
    value: 'PM',
    text: 'PM',
  },
];

export const minutesOptions = ['00', '15', '30', '45'].map(item => {
  return { value: item, label: item };
});

const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const processWeeklyString = (input: string) => {
  const [, daysString] = input.split(';');
  return daysString.split(',').filter(str => str.trim() !== '');
};

export const getFrequencyType = (
  input: string,
  capitalized: boolean = false
) => {
  if (!input || !input.length) {
    return '';
  }
  const parts = input.split(';');
  const frequency = parts[0];
  if (capitalized) {
    return capitalizeFirstLetter(frequency);
  }
  return frequency;
};

export const capitalizeFirstLetterWithS = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1) + 's';
};

export const capitalizeDays = (days: string[]) => {
  const capitalizedDays = days.map(day => capitalizeFirstLetterWithS(day));
  return capitalizedDays.join(', ');
};

export const getFrequencySchedule = (input: string) => {
  const parts = input.split(';');
  const frequency = parts[0];

  switch (frequency) {
    case FREQUENCY_TYPE.WEEKLY:
      return `Weekly on ${capitalizeDays(processWeeklyString(input))}`;
    case FREQUENCY_TYPE.MONTHLY: {
      const day = parts[1];
      const daySuffix = `${day}${getDaySuffix(parseInt(parts[1]))}`;
      return `Monthly on the ${daySuffix} day of each month`;
    }
  }
};
