import React from 'react';
import { theme } from 'lib/style';
import { IoMdEye, IoMdUnlock } from 'react-icons/io';
import { MdCreateNewFolder } from 'react-icons/md';
import { DropdownLabelWrapper, LabelText } from './style';

export type OptionType = {
  value: string;
  label: JSX.Element;
};

export const dropdownAccessData: OptionType[] = [
  {
    value: 'view',
    label: (
      <DropdownLabelWrapper>
        <IoMdEye
          color={theme.palette.covideoBlue40}
          style={{ width: '20px', height: '20px' }}
        />
        <LabelText>Can View</LabelText>
      </DropdownLabelWrapper>
    ),
  },
  {
    value: 'copy',
    label: (
      <DropdownLabelWrapper>
        <MdCreateNewFolder
          color={theme.palette.covideoBlue40}
          style={{ width: '20px', height: '20px' }}
        />
        <LabelText>Can Copy to Library</LabelText>
      </DropdownLabelWrapper>
    ),
  },
  {
    value: 'edit',
    label: (
      <DropdownLabelWrapper>
        <IoMdUnlock
          color={theme.palette.covideoBlue40}
          style={{ width: '20px', height: '20px' }}
        />
        <LabelText>Can Edit/Upload Videos</LabelText>
      </DropdownLabelWrapper>
    ),
  },
];
