import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { Field, FieldArray, Form, Formik, useField } from 'formik';
import { theme } from 'lib/style';
import { CheckboxInput, Modal, RadioInput, Tooltip } from 'lib/components';
import { useOnClickOutside } from 'lib/utils';
import { useAuth } from 'lib/context';
import { IoSettingsSharp } from 'react-icons/io5';
import { IoMdBriefcase, IoMdClose } from 'react-icons/io';
import { DepartmentLimitTabContent } from 'app/pages/account/userAccount/videoAttributes/components/DepartmentLimitTabContent';
import { AccessRole } from '../../const';
import { FiPlus } from 'react-icons/fi';
import { Button } from 'react-covideo-common';
import { useCreateVideoAttribute } from 'lib/api/videoAttributes/useCreateVideoAttribute';
import { useUpdateAttribute } from 'lib/api/videoAttributes/useEditVideoAttribute';
import { MdDeleteForever } from 'react-icons/md';
import { BsFillQuestionCircleFill } from 'react-icons/bs';

const Header = styled.div`
  display: flex;
  padding: 32px;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 0 32px;

  .checkbox {
    width: 24px;
    height: 24px;
    background: rgba(0, 27, 83, 0.05);
    border: 1px solid #d0d3d9;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

const InputGroup = styled.div`
  margin: 5px 0; /* SUS-796 changes */
`;

const Label = styled.label`
  display: flex;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
`;

const TooltipWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    figcaption {
      z-index: 2;
      opacity: 1;
      width: auto;
      position: absolute;
    }
  }
`;

const Input = styled.input<InputProps>`
  width: ${props => props.width};
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};

  &:focus {
    outline: 0;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};

  &:focus {
    outline: 0;
  }
`;

const RadioGroup = styled.div`
  padding-top: 16px;
  display: inline-flex;
  gap: 10px;
`;

const CheckBoxContainer = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1; /*** SUS-796 changes ***/
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

const DropdownValues = styled.div`
  margin: 16px 0;
`;

const DropdownValue = styled.div`
  display: flex;
  margin: 8px 0;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
`;

const DropdownTextInput = styled.input`
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  flex-grow: 1;
  margin-right: 8px;
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }
`;

const DeleteButton = styled.button`
  margin-left: 16px;
  cursor: pointer;
  background-color: ${theme.palette.destructiveBg};
  border: none;

  &:focus {
    outline: 0;
  }

  ${props => (props.disabled ? 'opacity: 0.4;' : '')};
`;

const TabContainer = styled.div<any>`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;

  .activeTab {
    color: ${props => props.color};
    border-bottom: 5px solid ${props => props.color};
  }

  border-bottom: 1px solid #eeeff2;
`;

interface TabStyleProperties {
  width?: string;
  beforeIconImage?: any;
  color?: any;
}

interface InputProps {
  width?: string;
}

const Tab = styled.div<TabStyleProperties>`
  display: inline-flex;
  text-align: center;
  font-size: 18px;
  padding: 10px 24px 10px 20px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-weight: 600;
  color: ${theme.palette.covideoGray60};
  align-items: center;
  border-bottom: 5px solid transparent;
  width: ${props => (props.width ? props.width : 'fit-content')};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

const Col = styled.div`
  height: 100px; /* SUS-796 changes */
  overflow: hidden;
  width: 100%;
`;

interface TextInputProps {
  label: string;
  name: string;
  id?: string;
  type?: string;
  showTooltip?: boolean;
  tooltipText?: string;
  inputWidth?: string;
  checkedColor?: string;
}

const TextInput = ({
  label,
  showTooltip = false,
  tooltipText,
  inputWidth,
  ...props
}: TextInputProps) => {
  const [field, meta] = useField(props);
  return (
    <InputGroup>
      <Label htmlFor={props.id || props.name}>
        <div>{label}</div>
        {showTooltip && (
          <TooltipWrapper style={{ marginLeft: '8px' }}>
            <BsFillQuestionCircleFill
              size='18px'
              color={theme.palette.primaryDarkBlue50Percent}
            />
            <Tooltip
              text={tooltipText}
              leftPixels={0}
              topPixels={20}
              widthPixels={240}
            />
          </TooltipWrapper>
        )}
      </Label>
      <Input {...field} {...props} width={inputWidth} />
      {meta.touched && meta.error ? (
        <ErrorMessage className='error'>{meta.error}</ErrorMessage>
      ) : null}
    </InputGroup>
  );
};

const CheckBox = ({
  label,
  showTooltip = false,
  tooltipText,
  checkedColor,
  ...props
}: TextInputProps) => {
  const [field] = useField(props);
  return (
    <CheckBoxContainer>
      <Field
        {...field}
        {...props}
        as={CheckboxInput}
        className={'checkbox'}
        value={field.value || false}
        checkedColor={checkedColor}
      />
      &nbsp;{label}
      {showTooltip && (
        <TooltipWrapper style={{ marginLeft: '8px' }}>
          <BsFillQuestionCircleFill
            width='18px'
            color={theme.palette.primaryDarkBlue50Percent}
          />
          <Tooltip
            text={tooltipText}
            topPixels={-75}
            widthPixels={240}
            textTransform={'none'}
          />
        </TooltipWrapper>
      )}
    </CheckBoxContainer>
  );
};
const TextAreaInput = ({ label, ...props }: TextInputProps) => {
  const [field] = useField(props);
  return (
    <InputGroup>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Textarea {...field} {...props} />
    </InputGroup>
  );
};

const Dropdown = ({ label, ...props }: TextInputProps) => {
  const [field, meta] = useField(props);
  const { value: dropdownValues } = field;

  return (
    <InputGroup>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      {meta.error && typeof meta.error === 'string' ? (
        <ErrorMessage className='error'>{meta.error}</ErrorMessage>
      ) : null}
      <FieldArray
        name={props.name}
        render={arrayHelpers => (
          <DropdownValues>
            {field.value.map((value: any, index: number) => (
              <DropdownValue key={index}>
                <Field
                  name={`${props.name}.${index}.value`}
                  as={DropdownTextInput}
                  type='text'
                  maxLength={
                    60
                  } /* SUS-796 changes (Changing limit to 60 SUS-908) */
                />
                <Button
                  variant='secondary'
                  text={value.default ? 'Default' : 'Set as Default'}
                  disabled={value.default}
                  onClick={() => {
                    dropdownValues.forEach((dropdown: any, index: number) => {
                      if (dropdown.default) {
                        arrayHelpers.replace(index, {
                          ...dropdown,
                          default: false,
                        });
                      }
                    });
                    arrayHelpers.replace(index, {
                      ...value,
                      default: true,
                    });
                  }}
                />
                <DeleteButton
                  onClick={() => arrayHelpers.remove(index)}
                  disabled={value.default}
                >
                  <MdDeleteForever
                    size={18}
                    color={theme.palette.buttonDelete}
                  />
                </DeleteButton>
              </DropdownValue>
            ))}
            <Button
              text='New Value'
              variant='secondary'
              icon={<FiPlus size={20} />}
              onClick={() =>
                arrayHelpers.push({
                  value: '',
                  default: !dropdownValues.find((val: any) => val.default),
                })
              }
            />
          </DropdownValues>
        )}
      />
    </InputGroup>
  );
};

interface FooterProps {
  justifyContent?: string;
}

const Footer = styled.div<FooterProps>`
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-end'};
  padding: 32px 0;
`;

export const ModalVideoAttributes = ({
  attributes = [],
  selectedAttribute,
  handleModalClose,
}: any) => {
  selectedAttribute.isRequired = !!selectedAttribute.isRequired;
  selectedAttribute.isReadOnly = !!selectedAttribute.isReadOnly;
  selectedAttribute.default = selectedAttribute.default || '';
  const [currentTab, setCurrentTab] = React.useState<
    'settings' | 'departments'
  >('settings');
  const [departments, setDepartments] = React.useState(
    selectedAttribute.departments || []
  );
  const [radioValue, setRadioValue] = React.useState(selectedAttribute.type);
  const { ref } = useOnClickOutside(handleModalClose);

  const { userData } = useAuth();
  const { mutateAsync: createAttribute } = useCreateVideoAttribute();
  const { mutateAsync: updateAttribute } = useUpdateAttribute();
  const themes = useTheme();
  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };
  const showReadOnlyAttribute =
    userData.customer.hasReadOnlyVideoAttributeConstraint === '1';

  const showRequiredAttribute = userData.access === AccessRole.ADMIN;

  const showDepartments =
    userData.customer.hasVideoAttributeDepartmentLimit === '1';
  const showLockableAttribute =
    userData.customer.hasLockableVideoAttribute === '1';

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const validate = (values: any) => {
    const { title, dropdownValues, slug, type, isReadOnly } = values;
    const errors = {} as any;
    const isDropdown = type === 'dropdown';

    // validate title
    if (!title) errors.title = 'Title is required';
    else if (title.length > 60)
      errors.title = 'Title can not contain more than 60 characters.';

    // validate slug
    if (!slug) {
      errors.slug = 'Slug is required';
    } else if (!new RegExp('^[A-Za-z0-9-]+$', 'g').test(slug)) {
      errors.slug = 'May only contain letters, numbers and dashes.';
    } else if (
      !!attributes.find(
        (attr: any) =>
          attr.slug === slug &&
          attr.attributeId !== selectedAttribute.attributeId
      )
    ) {
      errors.slug = 'This attribute ID already exists.';
    } else if (slug.length > 60) {
      errors.slug = 'Slug can not contain more than 60 characters.';
    }

    // validate dropdown values
    if (isDropdown) {
      if (!dropdownValues.length) {
        errors.dropdownValues =
          'At least one option is required for dropdown attributes.';
      } else if (
        !!dropdownValues.find(
          (v: { value: string; default: string }) => !v.value
        )
      ) {
        errors.dropdownValues = 'Value is required ';
      }
    }

    // validate default
    if (isReadOnly) {
      if (!values.default) {
        errors.default = 'Default value is required for read-only attributes.';
      } else if (values.default.length > 60) {
        errors.default =
          'Default value can not contain more than 60 characters.';
      }
    }

    return errors;
  };

  return (
    <Modal widthOnDesktop={'640px'}>
      <div ref={ref}>
        <Header>
          <HeaderTitle>
            {selectedAttribute.attributeId
              ? 'Edit Video Attribute'
              : 'New Video Attribute'}
          </HeaderTitle>
          <CloseButtonWrap title={'Close video attributes'}>
            <IoMdClose
              size={24}
              onClick={handleModalClose}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <TabContainer color={themes.colors.primary[100]}>
            <Tab
              className={currentTab === 'settings' ? 'activeTab' : ''}
              onClick={() => {
                setCurrentTab('settings');
              }}
              title={'Settings'}
              color={themes.colors.primary[100]}
            >
              <IoSettingsSharp />
              &nbsp;Settings
            </Tab>
            {showDepartments && (
              <Tab
                className={currentTab === 'departments' ? 'activeTab' : ''}
                onClick={() => {
                  setCurrentTab('departments');
                }}
                title={'Department Limits'}
                color={themes.colors.primary[100]}
              >
                <IoMdBriefcase />
                &nbsp;Department Limits
              </Tab>
            )}
          </TabContainer>
          <Formik
            initialValues={{ ...selectedAttribute }}
            validate={validate}
            onSubmit={async data => {
              data.departments = departments;
              data.type = radioValue;
              if (data.attributeId) {
                await updateAttribute(data);
              } else {
                await createAttribute({ data });
              }
              handleModalClose();
            }}
          >
            {({ isValid, isSubmitting, values, setFieldValue }) => (
              <Form>
                {currentTab === 'settings' && (
                  <>
                    <Row>
                      <Col>
                        <TextInput
                          label='Title'
                          name='title'
                          type='text'
                          inputWidth={'96%'}
                        />
                      </Col>
                      <Col>
                        <TextInput
                          label='ID (Slug)'
                          name='slug'
                          type='text'
                          showTooltip={true}
                          tooltipText=' May only contain letters, numbers and dashes'
                          inputWidth={'96%'}
                        />
                      </Col>
                    </Row>
                    <InputGroup>
                      <Label htmlFor='type'>Type</Label>
                      <RadioGroup role='group' aria-labelledby='type-group'>
                        <Field
                          type='radio'
                          as={RadioInput}
                          name='type'
                          value='text'
                          checkedColor={themes.colors.primary[100] || '#FF8B22'}
                          onChange={() => {
                            setRadioValue('text');
                            setFieldValue('type', 'text');
                          }}
                          onClick={() => {
                            setRadioValue('text');
                            setFieldValue('type', 'text');

                            setFieldValue('default', '');
                          }}
                          checked={radioValue === 'text'}
                          text={'Text Field'}
                        />
                        <Field
                          type='radio'
                          as={RadioInput}
                          name='type'
                          value='dropdown'
                          checkedColor={themes.colors.primary[100] || '#FF8B22'}
                          onChange={() => {
                            setRadioValue('dropdown');
                            setFieldValue('type', 'dropdown');
                          }}
                          onClick={() => {
                            setRadioValue('dropdown');
                            setFieldValue('type', 'dropdown');
                          }}
                          checked={radioValue === 'dropdown'}
                          text={'Dropdown'}
                        />
                        <Field
                          type='radio'
                          as={RadioInput}
                          name='type'
                          value='textarea'
                          checkedColor={themes.colors.primary[100] || '#FF8B22'}
                          onChange={() => {
                            setRadioValue('textarea');
                            setFieldValue('type', 'textarea');
                          }}
                          onClick={() => {
                            setRadioValue('textarea');
                            setFieldValue('type', 'textarea');
                            setFieldValue('default', '');
                          }}
                          checked={radioValue === 'textarea'}
                          text={'Textarea'}
                        />
                      </RadioGroup>
                    </InputGroup>
                    {radioValue === 'text' && (
                      <TextInput
                        label='Default Value (Optional)'
                        name='default'
                        type='text'
                        inputWidth={'99%'}
                      />
                    )}
                    {radioValue === 'dropdown' && (
                      <Dropdown label='Dropdown Values' name='dropdownValues' />
                    )}

                    {radioValue === 'textarea' && (
                      <TextAreaInput
                        label='Default Value (Optional)'
                        name='default'
                      />
                    )}
                  </>
                )}
                {currentTab === 'departments' && (
                  <DepartmentLimitTabContent
                    selectedFields={departments}
                    setSelectedFields={setDepartments}
                  />
                )}
                <Footer
                  justifyContent={
                    showRequiredAttribute || showReadOnlyAttribute
                      ? 'space-between'
                      : 'flex-end'
                  }
                >
                  {showRequiredAttribute && (
                    <CheckBox
                      label={'Required'}
                      name={'isRequired'}
                      type={'checkbox'}
                      showTooltip={true}
                      tooltipText={
                        'Users have to enter a value after recording or uploading new videos'
                      }
                      checkedColor={themes.colors.primary[100] || '#FF8B22'}
                    />
                  )}
                  {showReadOnlyAttribute && (
                    <CheckBox
                      label={'Read-only'}
                      name={'isReadOnly'}
                      type={'checkbox'}
                      showTooltip={true}
                      tooltipText={
                        'Attribute will be locked for editing after the initial value has been set'
                      }
                      checkedColor={themes.colors.primary[100] || '#FF8B22'}
                    />
                  )}

                  {showLockableAttribute && (
                    <CheckBox
                      label={'Lockable'}
                      name={'isLocked'}
                      type={'checkbox'}
                      showTooltip={true}
                      tooltipText={
                        'Attribute will be locked for editing after the initial value has been set'
                      }
                      checkedColor={themes.colors.primary[100] || '#FF8B22'}
                    />
                  )}

                  <Button
                    text={
                      selectedAttribute.attributeId
                        ? 'Save Attribute'
                        : 'Create Attribute'
                    }
                    disabled={
                      !values.title ||
                      !values.slug ||
                      !values.type ||
                      !isValid ||
                      isSubmitting
                    }
                    type='submit'
                  />
                </Footer>
              </Form>
            )}
          </Formik>
        </Content>
      </div>
    </Modal>
  );
};
