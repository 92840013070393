import * as React from 'react';
import { DocumentHead, PageTemplate, LoadingIndicator } from 'lib/components';
import Main from './main/Main';

export const UpgradeToPaid = () => {
  const getModal = () => {
    const loading = false;
    if (loading) {
      return <LoadingIndicator isLoading={loading} />;
    }
    return null;
  };

  return (
    <>
      <DocumentHead title="Upgrade your account" />
      <PageTemplate modal={[getModal()]} main={<Main />} />
    </>
  );
};
