import React from 'react';
import './Switch.css';

interface Props {
  isOn: any;
  handleToggle: any;
  onColor: string;
  id: string;
  disabled?: boolean;
}
const Switch = (props: Props) => {
  const { id, isOn, handleToggle, onColor, disabled = false } = props;
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className='react-switch-checkbox'
        id={id}
        type='checkbox'
        disabled={disabled}
      />
      <label
        style={{ background: isOn && onColor ? onColor : 'grey' }}
        className='react-switch-label'
        htmlFor={id}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;
