import { useMutation, useQueryClient } from 'react-query';
import { annotationsKeys } from './annotationsKeys';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { CreateTemplateParams } from '../types';
import { EXPRESS_API } from 'configs/api/expressApi';

export const editTemplate = async (data: CreateTemplateParams) => {
  const response = await EXPRESS_API.put(
    `/annotation-templates/${data.templateId}`,
    data
  );

  return response.data;
};

export const useEditTemplateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(editTemplate, {
    onSuccess: () => {
      queryClient.invalidateQueries(annotationsKeys.templates());
      successToast({ title: 'Template edited successfully!' });
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred editing the template, please try again later!',
      });
    },
  });
};
