import { getNotificationCategoriesWithNotifications } from 'app/pages/notifications/const';
import { Field, FieldProps } from 'formik';
import { CheckboxInput } from 'lib/components';
import { useAuth } from 'lib/context';
import * as React from 'react';
import styled from 'styled-components/macro';
import { Container, FieldLabel, Row, SectionTitle } from './common/components';

interface FormProps {
  isSubmitting?: boolean;
  setFieldValue?: (field: string, value: boolean) => void;
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NotificationLabel = styled.div`
  display: flex;
  width: 260px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 112px;
  align-items: center;
`;
const MarginTop = styled.div`
  margin-top: 20px;
`;

const CheckboxInputField = (
  fieldName: string,
  isSubmitting: boolean = false,
  setFieldValue?: (field: string, value: boolean) => void
) => {
  return (
    <Field type='checkbox' name={fieldName}>
      {({ field }: FieldProps) => {
        const { value, ...rest } = field;
        return (
          <CheckboxInput
            {...rest}
            checked={value}
            disabled={isSubmitting}
            onChange={(e: { target: { checked: boolean } }) => {
              if (setFieldValue) setFieldValue(field.name, e.target.checked);
            }}
          />
        );
      }}
    </Field>
  );
};
export const NotificationPreferencesComponent = ({
  isSubmitting,
  setFieldValue,
}: FormProps) => {
  const { userData } = useAuth();
  const notifcationCategories = React.useMemo(
    () => getNotificationCategoriesWithNotifications(userData),
    []
  );

  return (
    <MarginTop>
      <Container>
        <SectionTitle>Send Notifications</SectionTitle>
        <NotificationLabel style={{ marginLeft: '132px' }} />
        <ActionsWrapper>
          <div>Email</div>
          <div>Mobile</div>
        </ActionsWrapper>
      </Container>
      <Container>
        <SectionTitle />
        <Column style={{ flexDirection: 'column' }}>
          {notifcationCategories.map((category, index) => {
            const notifications = category.notifications;
            return (
              <Row key={index}>
                <Column>
                  <FieldLabel style={{ padding: '7px 0px' }}>
                    {category.categoryName}
                  </FieldLabel>
                </Column>
                <Column>
                  {notifications.map((notification, index) => {
                    const emailFieldName = `notificationPreferences.${notification.valueEmail}`;
                    const mobileFieldName = `notificationPreferences.${notification.valueInApp}`;
                    return (
                      <Row key={index}>
                        <NotificationLabel>
                          {notification.name}
                        </NotificationLabel>

                        <ActionsWrapper
                          style={{
                            width: '90px',
                            marginRight: '11px',
                            marginLeft: '11px',
                          }}
                        >
                          {CheckboxInputField(
                            emailFieldName,
                            isSubmitting,
                            setFieldValue
                          )}
                          {CheckboxInputField(
                            mobileFieldName,
                            isSubmitting,
                            setFieldValue
                          )}
                        </ActionsWrapper>
                      </Row>
                    );
                  })}
                </Column>
              </Row>
            );
          })}
        </Column>
      </Container>
    </MarginTop>
  );
};
