import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { ModalVideoTags } from 'lib/components/modal/ModalVideoTags';
import { useState } from 'react';
import { VideoTag } from 'lib/components/VideoTag';
import { useAuth } from 'lib/context';
import { productFeature } from 'lib/utils/productFeature';
import { Button } from 'react-covideo-common';
import { MdDeleteForever } from 'react-icons/md';
import { FaHashtag } from 'react-icons/fa';

type ComponentProps = {
  videoTags: any[];
  onUpdate: Function;
  onDelete: (arg: string) => Promise<void>;
  id: string;
};

const Content = styled.div`
  width: 100px;
  margin: 16px 0 24px 0;
  ${theme.mediaQueryMinWidth.xs} {
    width: 248px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    width: 380px;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-content: flex-start;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
  .icon-wrapper {
    width: auto;
  }
`;

export const VideoTagsForReviewScreen = ({
  videoTags,
  onUpdate,
  id,
  onDelete,
}: ComponentProps) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onSave = (values: object[]) => {
    onUpdate(values);
    closeModal();
  };
  const { userData } = useAuth();

  const [isTagFeatureAvailable, setIsTagFeatureAvailable] =
    React.useState(true);

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const packageDetails = userData.user.packageDetails;
      if (packageDetails && packageDetails.id) {
        const isTagEnabled = packageDetails.products.includes(
          productFeature.VIDEO_TAGS
        );
        setIsTagFeatureAvailable(isTagEnabled);
      }
    }
  }, [userData]);

  return (
    <Content>
      <ActionButtonContainer>
        <Button
          variant='destructive'
          icon={<MdDeleteForever size={24} />}
          text={'Remove this video'}
          onClick={async () => {
            await onDelete(id);
          }}
        />
        {isTagFeatureAvailable && (
          <Button
            variant='secondary'
            icon={<FaHashtag className='hashtag-icon' size={'16px'} />}
            text={'Add Tags...'}
            onClick={openModal}
          />
        )}
      </ActionButtonContainer>
      {!!videoTags.length && (
        <TagsContainer>
          {videoTags.map(tag => (
            <VideoTag key={tag.value} tag={tag} />
          ))}
        </TagsContainer>
      )}
      {showModal && (
        <ModalVideoTags
          videoTags={videoTags}
          handleModalClose={closeModal}
          onSubmit={onSave}
          title={'Manage Tags'}
          buttonTitle={'Save Changes'}
        />
      )}
    </Content>
  );
};
