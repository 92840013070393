import * as React from 'react';
import styled from 'styled-components/macro';
import { VideoPlayer } from 'app/pages/video/videoPlayer';
import { useAuth } from 'lib/context';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';

interface Props {
  thumbnail: string;
  videoSource: string;
}
const VideoWrap = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const Video = ({ thumbnail, videoSource }: Props) => {
  const { whitelabel } = useAuth();

  if (whitelabel.name !== WHITELABEL_NAME.COVIDEO) {
    return null;
  }

  const ref = React.createRef();
  return (
    <VideoWrap>
      <VideoPlayer
        videoRef={ref}
        playButtonPosition='center'
        playerBackgroundColor='#000'
        playerIconsColor='#ffffff'
        thumbnail={thumbnail}
        videoSource={videoSource}
        borderRadius='6px'
      />
    </VideoWrap>
  );
};
