import styled from 'styled-components/macro';

interface NumberInputProps {
  width?: string;
}

export const NumberInputField = styled.input.attrs({
  type: 'number',
  step: 1,
  min: 1,
})<NumberInputProps>`
  box-sizing: border-box;
  height: 40px;
  width: ${props => props.width || '96px'};
  background: #ffffff;
  border: 1px solid #d0d3d9;
  border-radius: 4px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: start;
  padding-left: 0.75rem;
  color: #272a32;
  &::-webkit-inner-spin-button {
    -webkit-appearance: auto;
    opacity: 1 !important;
    width: 24px;
    height: 32px;
    background: #eeeff2;
    border-radius: 4px;
    color: #9297a2;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: auto;
    opacity: 1 !important;
    width: 24px;
    height: 32px;
    background: #eeeff2;
    border-radius: 4px;
    color: #9297a2;
  }
  &:focus {
    outline: 0;
  }
`;
