import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import { guidesKeyes } from './guidesKeyes';

const deleteCategory = async ({
  categoryId,
  newCategoryId,
}: {
  newCategoryId: number | null;
  categoryId: number;
}): Promise<void> => {
  const params = newCategoryId === null ? {} : { newCategoryId };

  return (
    await EXPRESS_API.delete(`guide-category/${categoryId}`, {
      params,
    })
  ).data;
};
export const useDeleteCategory = (closeModalHandler: () => void) => {
  const history = useHistory();
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(deleteCategory, {
    onError: err => showError(err),
    onSuccess: async (_, variables) => {
      const title =
        variables.newCategoryId !== null
          ? 'You have successfully moved and deleted the category!'
          : 'You have successfully deleted the category!';
      await queryClient.invalidateQueries(guidesKeyes.categorized());
      successToast({ title: title });
      history.push('/guides');
      queryClient.invalidateQueries(guidesKeyes.editableCategories());
      closeModalHandler();
    },
  });
};
