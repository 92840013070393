import React from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal } from 'lib/components';
import { theme } from 'lib/style';
import {
  SubscriptionPrice,
  SubscriptionMapping,
  SubscriptionIndex,
} from 'lib/const';
import { preparePrice } from 'lib/utils/invoice';
import dayjs from 'dayjs';
import { isNull } from 'lodash';
import { updateAccountSubscription } from 'lib/api';
import { STANDARD_DATE_FORMAT, USER_DATE_FORMAT } from 'lib/const/DateFormat';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  font-family: Work Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 900px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  .content--left,
  .content--right {
    width: 435px;
    .content--container {
      display: flex;
      flex-direction: column;
      .content--head {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        .plan {
          color: ${theme.palette.black_1_100};
          font-weight: 700;
        }
        .interval {
          color: #9297a2;
          font-size: 13px;
        }
      }
      .content--body {
        background: #f6f7f9;
        margin-top: 15px;
        padding: 10px;
        .main--head {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 27, 83, 0.1);
          padding-bottom: 10px;
          .seats {
            color: ${theme.palette.primaryDarkBlue};
            font-weight: 700;
          }
          .price {
            color: ${theme.palette.primaryDarkBlue};
          }
        }
        .main--content {
          display: flex;
          flex-direction: column;
          padding-top: 12px;
          font-size: 13px;
          color: #4e5461;
          .single--row {
            display: flex;
            justify-content: space-between;
            .addon {
              padding: 10px 0px;
            }
            .addon--price {
              padding: 10px 0px;
            }
          }
        }
      }
      .total {
        display: flex;
        justify-content: end;
        padding: 10px 10px 0px 0px;
        color: ${theme.palette.primaryDarkBlue};
        font-weight: 700;
      }
    }
  }
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
  subscription: any;
};

export const SubscriptionDetailModal = (props: Props) => {
  const { handleModalClose, subscription } = props;

  const { plan, upcomingInvoice, totalActiveUsers, addons, nextPeriodStart } =
    subscription;
  const [loading, setLoading] = React.useState(false);

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const manageSubscription = async () => {
    setLoading(true);
    try {
      setLoading(false);
      await updateAccountSubscription({
        quantity: parseInt(totalActiveUsers) + 1,
        interval: plan.interval,
      });
      handleModalClose(true);
    } catch (error) {
      //   toast({ message: error.message });
      setLoading(false);
    }
    setLoading(false);
  };

  const calculateDifferenceInTime = (value: any) => {
    const dateCurrent = dayjs();
    const date = dayjs(value);
    const day: any = date.diff(dateCurrent, 'day', true);
    const month: any = date.diff(dateCurrent, 'month', true);
    if (parseInt(day, 10) < 31) {
      return `${parseInt(day, 10)} day${parseInt(day, 10) > 1 ? 's' : ''}`;
    }
    return `${parseInt(month, 10)} month${parseInt(month, 10) > 1 ? 's' : ''}`;
  };

  const getPlanSelected = () => {
    if (plan.interval == 'year' && !isNull(nextPeriodStart)) {
      return 'monthly';
    } else if (plan.interval == 'month' && !isNull(nextPeriodStart)) {
      return 'annual';
    }
    return plan.interval == 'year' ? 'annual' : 'monthly';
  };

  const getPriceTotal = (type: string) => {
    if (plan && plan.interval && plan.valid) {
      const planType = getPlanSelected();
      if (planType == 'annual') {
        let price =
          SubscriptionPrice.annual * parseInt(totalActiveUsers.toString()) * 12;
        addons
          .filter((e: any) => e.active)
          .forEach((addon: any) => {
            if (addon.active)
              price +=
                SubscriptionMapping.annual[SubscriptionIndex[addon.name]].price;
          });
        if (type != 'current') price += SubscriptionPrice.annual * 12;
        return price * 100;
      } else {
        let price =
          SubscriptionPrice.monthly * parseInt(totalActiveUsers.toString());
        addons
          .filter((e: any) => e.active)
          .forEach((addon: any) => {
            if (addon.active)
              price +=
                SubscriptionMapping.monthly[SubscriptionIndex[addon.name]]
                  .price;
          });
        if (type != 'current') price += SubscriptionPrice.monthly;
        return price * 100;
      }
    }
  };

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>
            Add an additional user to your{' '}
            {getPlanSelected() == 'annual' ? 'annual' : 'monthly'} subscription?
          </HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <div className='content--left'>
            <div className='content--container'>
              <div className='content--head'>
                <div className='plan'>Current Plan</div>
                <div className='interval'>
                  {getPlanSelected() == 'annual' ? 'Yearly' : 'Monthly'} Total
                </div>
              </div>
              <div className='content--body'>
                <div className='main--head'>
                  <div className='seats'>
                    Covideo{' '}
                    {getPlanSelected() == 'annual' ? 'Annual' : 'Monthly'} (
                    {totalActiveUsers} seats)
                  </div>
                  <div className='price'>
                    {preparePrice(
                      subscription.totalActiveUsers *
                        100 *
                        SubscriptionPrice[
                          getPlanSelected() == 'annual' ? 'annual' : 'monthly'
                        ] *
                        (getPlanSelected() == 'annual' ? 12 : 1),
                      'USD',
                      true
                    )}
                  </div>
                </div>
                <div className='main--content'>
                  {addons
                    .filter((e: any) => e.active)
                    .map((e: any) => {
                      const addon =
                        SubscriptionMapping[
                          getPlanSelected() == 'annual' ? 'annual' : 'monthly'
                        ][SubscriptionIndex[e.name]];
                      return (
                        <div className='single--row'>
                          <div className='addon'>
                            {addon.title}
                            {addon.type == 'team' ? '' : ` (${e.quantity})`}
                          </div>
                          <div className='addon--price'>
                            {preparePrice(
                              addon.price * e.quantity * 100,
                              'USD',
                              true
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='total'>
                {preparePrice(getPriceTotal('current'), 'USD', true)}
              </div>
            </div>
          </div>
          <div className='content--right'>
            <div className='content--container'>
              <div className='content--head'>
                <div className='plan'>
                  New Plan from{' '}
                  {dayjs(new Date(upcomingInvoice.created * 1000)).format(
                    STANDARD_DATE_FORMAT
                  )}
                </div>
                <div className='interval'>
                  {getPlanSelected() == 'annual' ? 'Yearly' : 'Monthly'} Total
                </div>
              </div>
              <div className='content--body'>
                <div className='main--head'>
                  <div className='seats'>
                    Covideo{' '}
                    {getPlanSelected() == 'annual' ? 'Annual' : 'Monthly'} (
                    {totalActiveUsers + 1} seats)
                  </div>
                  <div className='price'>
                    {preparePrice(
                      (totalActiveUsers + 1) *
                        100 *
                        SubscriptionPrice[
                          getPlanSelected() == 'annual' ? 'annual' : 'monthly'
                        ] *
                        (getPlanSelected() == 'annual' ? 12 : 1),
                      'USD',
                      true
                    )}
                  </div>
                </div>
                <div className='main--content'>
                  {addons
                    .filter((e: any) => e.active)
                    .map((e: any) => {
                      const addon =
                        SubscriptionMapping[
                          getPlanSelected() == 'annual' ? 'annual' : 'monthly'
                        ][SubscriptionIndex[e.name]];
                      return (
                        <div className='single--row'>
                          <div className='addon'>
                            {addon.title}
                            {addon.type == 'team' ? '' : ` (${e.quantity})`}
                          </div>
                          <div className='addon--price'>
                            {preparePrice(
                              addon.price * e.quantity * 100,
                              'USD',
                              true
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='total'>
                {preparePrice(getPriceTotal('final'), 'USD', true)}
              </div>
            </div>
            {getPlanSelected() == 'annual' && false ? (
              <div className='content--container' style={{ marginTop: '25px' }}>
                <div className='content--head'>
                  <div className='plan'>One-time payment</div>
                  <div className='interval'>
                    Billed on{' '}
                    {dayjs(new Date(upcomingInvoice.created * 1000)).format(
                      USER_DATE_FORMAT
                    )}
                  </div>
                </div>
                <div className='content--body'>
                  <div className='main--head' style={{ borderBottom: 'none' }}>
                    <div className='seats'>
                      Covideo{' '}
                      {getPlanSelected() == 'annual' ? 'Annual' : 'Monthly'} (1
                      seats)
                    </div>
                    <div className='price'>
                      <b>
                        {preparePrice(
                          1 *
                            100 *
                            SubscriptionPrice[
                              getPlanSelected() == 'annual'
                                ? 'annual'
                                : 'monthly'
                            ] *
                            (getPlanSelected() == 'annual' ? 12 : 1),
                          'USD',
                          true
                        )}
                      </b>
                    </div>
                  </div>
                  <div className='main--content' style={{ paddingTop: '0px' }}>
                    <div className='single--row'>
                      <div className='addon'>
                        for remaining{' '}
                        {calculateDifferenceInTime(
                          upcomingInvoice.created * 1000
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Content>
        <Footer>
          <Button
            text={`Cancel`}
            variant='secondary'
            disabled={loading}
            onClick={() => handleModalClose()}
          />
          &nbsp;
          <Button
            text={`Add Additional User`}
            disabled={loading}
            onClick={async () => {
              await manageSubscription();
            }}
          />
        </Footer>
      </div>
    </Modal>
  );
};
