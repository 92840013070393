import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ContentState, convertToRaw, EditorState, RichUtils } from 'draft-js';
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import './customEditorStyles.css';
import { theme } from 'lib/style';
import { AddVariableButton } from '../textEditorAutomotive';

type EditorWrapperProps = {
  margin: string;
};

const EditorWrapper = styled.div<EditorWrapperProps>`
  margin: ${({ margin }) => margin};
  width: 100%;
  position: relative;
`;

type Props = {
  onTextEditorChange: (text: string) => void;
  setTextEditorBody?: (text: string) => void;
  initialContent?: string;
  height?: string;
  customPadding?: string;
  placeholder?: string;
  editorWrapperClassname?: string;
  toolbar: {};
  forceSoftNewLine?: boolean /** SUS-1003 changes **/;
  showVariablesButton?: boolean;
};

export const TextEditor = (props: Props) => {
  const [focused, setFocused] = useState(false);
  const {
    toolbar,
    onTextEditorChange,
    initialContent,
    height,
    customPadding,
    placeholder,
    editorWrapperClassname = 'email-builder-wrapper',
    forceSoftNewLine = false /** SUS-1003 changes **/,
    showVariablesButton = true,
  } = props;

  const blocksFromHTML = htmlToDraft(initialContent || '');
  const initialData = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialData)
  );

  useEffect(() => {
    // move cursor to end of input when auto focused
    if (!focused) {
      return;
    }
    setEditorState(EditorState.moveFocusToEnd(editorState));
  }, [focused]);

  const onEditorStateChange = (
    editorState: EditorState,
    htmlEditorContent = ''
  ) => {
    setEditorState(editorState);
    const editorContent =
      htmlEditorContent ||
      draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onTextEditorChange(editorContent);
  };

  const updateEditorReference = (ref: HTMLElement) => {
    // focus automaticaly just once so we can remove focus
    if (!ref || focused) {
      return;
    }
    ref.focus();
    setFocused(true);
  };

  /** SUS-1003 changes **/
  const handleReturn = (
    e: SyntheticKeyboardEvent,
    editorState2: EditorState
  ) => {
    if (forceSoftNewLine) {
      const newEditorState = RichUtils.insertSoftNewline(editorState2);
      if (newEditorState !== editorState2) {
        onEditorStateChange(newEditorState);
      }
      return true;
    }
    return false;
  };
  /******/

  let toolbarCustomButtons: ReactElement[] = [];
  if (showVariablesButton) {
    toolbarCustomButtons = [
      ...toolbarCustomButtons,
      <AddVariableButton
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        position='bottom'
        text='Insert Variables'
        backgroundColor={theme.palette.white}
        borderColor={theme.palette.blue20}
      />,
    ];
  }

  return (
    <>
      <EditorWrapper margin={showVariablesButton ? '0 0 48px' : '0'}>
        <Editor
          editorStyle={{
            minHeight: height || 300,
            background: theme.palette.white,
            border: `1px solid ${theme.palette.gray40}`,
            borderRadius: '5px',
            padding: customPadding || '16px 20px',
            fontFamily: 'Work Sans',
            marginTop: 0,
            cursor: 'text',
          }}
          placeholder={placeholder}
          editorState={editorState}
          wrapperClassName={editorWrapperClassname}
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbar}
          handlePastedText={() => false}
          handleReturn={handleReturn} /** SUS-1003 changes **/
          editorRef={updateEditorReference}
          toolbarCustomButtons={toolbarCustomButtons}
        />
      </EditorWrapper>
    </>
  );
};
