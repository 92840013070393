import { Folder } from 'lib/api/folders/getFolders';
import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from 'react';

// TODO: remove any
interface IContextProps {
  count: number;
  limit: number;
  offset: number;
  search: string;
  sort: string;
  folder?: Folder;
  setCount: any;
  setLimit: any;
  setOffset: any;
  setSearch: any;
  setSort: any;
  setFolder: any;
}

const PageContext = createContext({} as IContextProps);

type Props = {
  children: ReactNode;
};

export const PageContextProvider = ({ children }: Props) => {
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('DESC');
  const [folder, setFolder] = useState(undefined);

  useEffect(() => {
    setOffset(0);
  }, [limit]);

  const value = {
    count,
    limit,
    offset,
    search,
    sort,
    folder,
    setCount,
    setLimit,
    setOffset,
    setSearch,
    setSort,
    setFolder,
  };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = () => {
  const value = useContext(PageContext);

  return value;
};
