import {
  FontFamilyDropdown,
  FontSizeDropdown,
} from 'lib/components/textEditorAutomotive';

export const emailEditorToolbar = {
  options: ['fontFamily', 'fontSize', 'inline', 'remove', 'link'],
  fontFamily: {
    options: ['Work Sans', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Verdana'],
    component: FontFamilyDropdown,
  },
  fontSize: {
    options: [10, 12, 14, 16, 18, 24, 30, 48, 72, 96],
    component: FontSizeDropdown,
  },
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon large-icon',
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon large-icon',
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon large-icon',
    },
  },
  remove: {
    className: 'toolbar-custom-icon toolbar-remove-icon medium-icon',
  },
  link: {
    inDropdown: false,
    className: 'toolbar-custom-icon toolbar-link-icon small-icon',
    options: ['link'],
    link: {
      className: 'toolbar-custom-icon add-content-link toolbar-link-icon',
    },
  },
};
