import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { CustomReportModalFormikValues, TYPE_OF_REPORT } from '../types';
import { RecurringCustomReport } from '../RecurringCustomReport';
import { StaticCustomReport } from '../StaticCustomReport';
import { CustomReportBodyWrapper } from '../styles';
import { TypeOfReport } from '../TypeOfReport';
import { useIsMutating } from 'react-query';

const TimeframeWrapper = styled(CustomReportBodyWrapper)<{
  isMutating: boolean;
}>`
  min-width: 615px;
  max-width: 615px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ isMutating, theme }) =>
    isMutating &&
    css`
      :after {
        width: 100%;
        height: 100%;
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${theme.colors.white[100]};
        opacity: 0.4;
        z-index: 5;
      }
    `}
`;

export const CustomModalReportTimeframeStep = () => {
  const { values, setFieldValue } =
    useFormikContext<CustomReportModalFormikValues>();
  const isMutating = useIsMutating();

  const currentRangeValue =
    values.typeOfReport === TYPE_OF_REPORT.STATIC
      ? values.staticRange
      : values.reccuringRange;

  const rangeName =
    values.typeOfReport === TYPE_OF_REPORT.STATIC
      ? 'staticRange'
      : 'reccuringRange';

  const updateRangeValue = (rangeValue: string) => {
    setFieldValue(rangeName, rangeValue);
  };

  useEffect(() => {
    setFieldValue('isTimeFrameStep', true);
  }, []);

  return (
    <TimeframeWrapper isMutating={!!isMutating}>
      <TypeOfReport />
      {values.typeOfReport === TYPE_OF_REPORT.STATIC && (
        <StaticCustomReport
          currentRangeValue={currentRangeValue}
          updateRangeValue={updateRangeValue}
        />
      )}
      {values.typeOfReport === TYPE_OF_REPORT.RECURRING && (
        <RecurringCustomReport
          currentRangeValue={currentRangeValue}
          updateRangeValue={updateRangeValue}
        />
      )}
    </TimeframeWrapper>
  );
};
