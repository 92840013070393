import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { servicesKeys } from './servicesKeys';
import { SERVICE } from './types';

interface ServiceConnectResponse {
  error: boolean;
  message: string;
}

const fetchServices = async (
  service: SERVICE
): Promise<ServiceConnectResponse> => {
  const response = await EXPRESS_API.get(`/${service}/connect`);
  return response.data?.data;
};

export const useServicesConnectQuery = (service: SERVICE) => {
  return useQuery(servicesKeys.connect(), () => fetchServices(service), {
    enabled: false,
  });
};
