import React from 'react';
import { NewModal } from 'lib/components';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Button } from 'react-covideo-common';

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const Content = styled.div`
  width: 528px;
  margin-bottom: 12px;
`;

const MessageTextBox = styled.textarea`
  width: 528px;
  height: 460px;
  background: #ffffff;
  border: 1px solid #d0d3d9;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
  outline: none;

  padding: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  onBackButtonPress: any;
  onMainButtonClick?: any;
};
export const ShareWithMessageModal = ({
  onBackButtonPress,
  onMainButtonClick,
}: Props) => {
  const [messageContent, setMessageContent] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(false);
  return (
    <NewModal
      closeModal={onBackButtonPress}
      hideCloseButtonInHeader
      hideHeader
      style={{
        content: {
          minWidth: '528px',
          width: '528px,',
        },
      }}
    >
      <Header>
        <Button icon={<MdKeyboardBackspace />} onClick={onBackButtonPress} />
        &nbsp;&nbsp;
        <HeaderTitle>Share with Message</HeaderTitle>
      </Header>
      <Content>
        <MessageTextBox
          placeholder={'Add Message'}
          value={messageContent}
          onChange={(e: any) => {
            setMessageContent(e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          text={'Send'}
          type='button'
          disabled={messageContent.trim() === '' || isDisabled}
          onClick={() => {
            setIsDisabled(true);
            onMainButtonClick(messageContent);
            return;
          }}
        />
      </Footer>
    </NewModal>
  );
};
