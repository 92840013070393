import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

export const deleteAttribute = async (attributeId: string) => {
  await EXPRESS_API.delete(`/video-attributes/${attributeId}`);

  return { attributeId };
};

export const useRemoveAttribute = () => {
  return useMutation(deleteAttribute, {
    onSuccess: () => {
      successToast({
        title: 'Video Attribute deleted successfully.',
      });
    },
  });
};
