import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { WidgetWrapper } from '../main/widgets/common/styles';
import { images, ImagesTypes } from './const';

const SkeletonLoaderWrapper = styled.div`
  ${WidgetWrapper}
  background: transparent;
  position: relative;
  overflow: hidden;
  padding: 0;
`;

const loading = keyframes`
 0% { transform: translateX(-150%) }
 50% { transform: translateX(-60%) }
 100% { transform: translateX(150%) }
`;
const ShimmerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${loading} 2s infinite;
`;

const Shimmer = styled.div`
  position: relative;
  top: 4px;
  width: 30%;
  height: calc(100% - 20px);
  background-color: rgba(255, 255, 255, 0.8);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
`;

const SkeletonLoader = ({ imageName }: { imageName: ImagesTypes }) => {
  return (
    <SkeletonLoaderWrapper>
      <img
        width="100%"
        src={images[imageName].svg}
        alt={images[imageName].svg.id}
      ></img>

      <ShimmerWrapper>
        <Shimmer />
      </ShimmerWrapper>
    </SkeletonLoaderWrapper>
  );
};
export default SkeletonLoader;
