import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  Search,
  LoadingIndicator,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
  CheckboxInput,
  SelectionHeaderPopup,
} from 'lib/components';
import { Button } from 'react-covideo-common';
import Select from 'react-select';
import { CarServiceItem } from 'lib/api/carService/types';
import {
  IoMdArchive,
  IoMdArrowRoundDown,
  IoMdArrowRoundUp,
} from 'react-icons/io';
import { RiPencilFill } from 'react-icons/ri';
import { BiLinkExternal } from 'react-icons/bi';
import { ModalArchive } from '.';
import { ServiceActive } from 'lib/const';
import { priceFormatter } from 'lib/utils/functions';
import { successToast } from 'lib/components/toasts/success';
import { useCarServicesQuery } from 'lib/api/carService/useCarServiceQuery';
import { keyBy } from 'lodash';
import { useUpdateCarServicesMutation } from 'lib/api/carService/useUpdateCarServicesMutation';
import { errorToast } from 'lib/components/toasts/error';

const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ClickableElement = styled.div`
  cursor: pointer;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;
const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;
const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
`;
const Filter = styled.div`
  min-width: 215px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CheckWrapper = styled.div`
  margin-right: 16px;
`;
type TableCellProps = {
  justifyContent?: string;
  padding?: string;
};
const TableCell = styled.div<TableCellProps>`
  width: auto;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  padding: ${props => props.padding || '0'};
  text-align: left;
  display: flex;
  position: relative;
`;
const SortCell = styled.div`
  cursor: pointer;
  padding-top: 2px;
  padding-left: 10px;
  .active {
    fill: black;
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px;
  color: ${theme.palette.blue80};
  background-color: ${theme.palette.blue05};
  margin-left: 16px;
`;

const EmptyList = styled.p`
  margin: 0;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  margin-left: 12px;
`;

const columnWidths = [380, 380, 165, 35, 20];
const tableFields = [
  {
    value: 'title',
    label: 'Title',
  },
  {
    value: 'status',
    label: '',
  },
  {
    value: 'price',
    label: 'Price',
  },
  {
    value: 'archive',
    label: '',
  },
  {
    value: 'edit',
    label: '',
  },
];

type Props = {
  handleUpdateServiceClick: (service: CarServiceItem) => void;
  filterByActive: ServiceActive;
  setFilterByActive: (active: ServiceActive) => void;
};

export const ServiceListing = ({
  handleUpdateServiceClick,
  filterByActive,
  setFilterByActive,
}: Props) => {
  const { mutateAsync: updateCarServices } = useUpdateCarServicesMutation();
  const [searchQuery, setSearchQuery] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState('');
  const [selectedService, setSelectedService] = useState<string[]>([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const { data, isLoading, refetch } = useCarServicesQuery({
    limit: limit,
    start: page * limit,
    sort: sort,
    search: searchQuery,
    filterByActive: filterByActive,
  });
  const carServices = data?.services || [];
  const carServicesDictionary = keyBy(carServices, 'serviceId');
  const count = data?.count || 0;

  useEffect(() => {
    setSelectedService([]);
  }, [filterByActive]);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
    setPage(0);
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setLimit(newSize);
    setPage(newSize !== limit ? 0 : newPage);
    if (page !== newPage) {
      setSelectedService([]);
    }
  };

  const sortElement = (sortParam: any) => {
    if (!['title'].includes(sortParam)) {
      return;
    }
    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('-') ? sort.replace('-', '') : `-${sort}`;
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}`;
      setSort(newSort);
    }
  };

  const handleSingleArchiveClick = (serviceId: string) => {
    setSelectedService([serviceId]);
    setShowArchiveModal(true);
  };

  const handleArchiveClick = async () => {
    const active = filterByActive === '1' ? 0 : 1;
    const data = selectedService.map((serviceId: string) => {
      return { serviceId: serviceId, active: active };
    });
    const count = selectedService.length;
    try {
      await updateCarServices({ data });
      successToast({
        title: `${count} service${count > 1 ? 's' : ''} successfully ${
          active ? 'restored' : 'archived'
        }!`,
      });
      setSelectedService([]);
      setShowArchiveModal(false);
      refetch();
    } catch (error) {
      errorToast({
        title: `Failed to ${active ? 'restore' : 'archive'} services.`,
      });
    }
  };

  const activeOptions = Object.values(ServiceActive).map((active: string) => {
    return {
      value: active,
      label: active === '1' ? 'Only active' : 'Only archived',
    };
  });

  return (
    <>
      {!!selectedService.length && (
        <SelectionHeaderPopup left='208px'>
          <CheckboxSelectionWrapper>
            <CheckboxInput
              blueCheck={true}
              checkGroupIndicator={
                selectedService.length > 0 &&
                carServices.length !== selectedService.length
              }
              checked={carServices.length == selectedService.length}
              ignoreGrayForAllSelect={true}
              grayCheck={true}
              onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                event.stopPropagation();
                let { checked } = event.target as HTMLInputElement;
                if (checked)
                  setSelectedService(carServices.map((e: any) => e.serviceId));
                else setSelectedService([]);
              }}
            />
            <SelectionCountWrap>
              <SelectionCountText>Services selected:</SelectionCountText>
              <b>{selectedService.length}</b>
            </SelectionCountWrap>
          </CheckboxSelectionWrapper>
          <ButtonWrapper>
            {filterByActive === '1' ? (
              <Button
                data-cy='service-archive-button'
                onClick={() => setShowArchiveModal(true)}
                text='Archive'
                variant='secondary'
                icon={<IoMdArchive />}
              />
            ) : (
              <Button
                onClick={() => setShowArchiveModal(true)}
                text='Restore'
                variant='secondary'
                icon={<BiLinkExternal />}
              />
            )}
          </ButtonWrapper>
        </SelectionHeaderPopup>
      )}
      <FiltersContainer>
        <Filter>
          <Search placeholder='Search services...' onSearch={onSearch} />
        </Filter>
        <Filter>
          <Select
            styles={{
              control: (base: any) => ({ ...base, height: '40px' }),
              indicatorSeparator: () => ({ display: 'none' }),
              menuPortal: provided => ({
                ...provided,
                zIndex: 5,
              }),
            }}
            options={activeOptions}
            menuPortalTarget={document.body}
            menuPlacement={'bottom'}
            value={activeOptions.find(o => {
              return o.value === filterByActive;
            })}
            onChange={(option: any) => {
              setPage(0);
              setFilterByActive(option.value);
            }}
            isSearchable={false}
          />
        </Filter>
      </FiltersContainer>
      {carServices.length && !isLoading ? (
        <TableContextProvider
          total={count}
          onChange={onPaginationChange}
          initPage={page}
          initSize={limit}
        >
          <Table
            dataCy='services-table'
            columnWidths={columnWidths}
            compact={true}
            relative={true}
            headers={[
              ...tableFields.map(item => {
                return (
                  <TableCell
                    onClick={() => sortElement(item.value)}
                    justifyContent={
                      item.value == 'price' ? 'flex-end' : 'flex-start'
                    }
                    padding={item.value == 'price' ? '0 107px 0 0' : '0'}
                  >
                    {item.label}
                    {item.value == 'title' ? (
                      <SortCell>
                        <IoMdArrowRoundUp
                          className={sort == `${item.value}` ? 'active' : ''}
                        />
                        <IoMdArrowRoundDown
                          className={sort == `-${item.value}` ? 'active' : ''}
                        />
                      </SortCell>
                    ) : null}
                  </TableCell>
                );
              }),
            ]}
            hoverable={false}
            fixColumnIndex='left'
            rows={carServices.map((service: any, index: number) => ({
              key: index,
              columns: [
                ...tableFields.map(item => {
                  return (
                    <TableCell
                      justifyContent={
                        item.value == 'price' ? 'flex-end' : 'flex-start'
                      }
                      padding={item.value == 'price' ? '0 107px 0 0' : '0'}
                    >
                      {item.value === 'title' ? (
                        <TitleWrapper>
                          <CheckWrapper>
                            <CheckboxInput
                              dataCy='services-checkbox'
                              width='24px'
                              blueCheck={true}
                              checked={selectedService.includes(
                                service.serviceId
                              )}
                              onChange={(e: React.SyntheticEvent) => {
                                let { checked } = e.target as HTMLInputElement;
                                if (checked)
                                  setSelectedService([
                                    ...selectedService,
                                    service.serviceId,
                                  ]);
                                else
                                  setSelectedService(
                                    selectedService.filter(
                                      (e: any) => e != service.serviceId
                                    )
                                  );
                              }}
                            />
                          </CheckWrapper>
                          {service[item.value].length > 35
                            ? service[item.value].substring(0, 35) + '...'
                            : service[item.value]}
                          {/* SUS-796 changes */}
                        </TitleWrapper>
                      ) : item.value === 'status' && !service.active ? (
                        <Badge>Archived</Badge>
                      ) : item.value === 'archive' && service.active ? (
                        <ClickableElement
                          onClick={() =>
                            handleSingleArchiveClick(service.serviceId)
                          }
                        >
                          <IoMdArchive size={20} color={theme.palette.gray60} />
                        </ClickableElement>
                      ) : item.value === 'archive' &&
                        !service.active ? null : item.value === 'edit' &&
                        service.active ? (
                        <ClickableElement
                          onClick={() => handleUpdateServiceClick(service)}
                        >
                          <RiPencilFill
                            data-cy='service-edit-button'
                            size={20}
                            color={theme.palette.gray60}
                          />
                        </ClickableElement>
                      ) : item.value === 'edit' && !service.active ? (
                        <ClickableElement
                          onClick={() =>
                            handleSingleArchiveClick(service.serviceId)
                          }
                        >
                          <BiLinkExternal
                            size={20}
                            color={theme.palette.gray60}
                          />
                        </ClickableElement>
                      ) : item.value === 'price' ? (
                        <>{priceFormatter(service[item.value])}</>
                      ) : (
                        <>{service[item.value]}</>
                      )}
                    </TableCell>
                  );
                }),
              ],
              onClick: () => {},
            }))}
          />
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show services:' />
          </TableFooter>
        </TableContextProvider>
      ) : !carServices.length && !isLoading ? (
        <EmptyList>No services to show...</EmptyList>
      ) : (
        <LoadingIndicator isLoading={isLoading} height='500px' />
      )}
      {showArchiveModal && (
        <ModalArchive
          handleModalClose={() => setShowArchiveModal(false)}
          onClickSecondaryButton={() => setShowArchiveModal(false)}
          onClickPrimaryButton={() => handleArchiveClick()}
          archive={filterByActive === '1'}
          count={selectedService.length}
          servicesDictionary={carServicesDictionary}
          selectedServices={selectedService}
        />
      )}
    </>
  );
};
