import * as React from 'react';
import { DocumentHead, PageTemplate } from 'lib/components';
import { Main } from './Main';

export const Contacts = () => (
  <>
    <DocumentHead title="Contacts" />
    <PageTemplate main={<Main />} />
  </>
);
