import * as React from 'react';
import { IntercomProps, IntercomProvider } from 'react-use-intercom';
import dayjs from 'dayjs';

import { useAuth } from 'lib/context/auth';
import { AccessRole } from '../const';

type Props = {
  children: JSX.Element;
};

export const IntercomWrap = ({ children }: Props) => {
  const { userData, whitelabel } = useAuth();

  if (!whitelabel?.intercomId || !userData?.intercom?.app_id) {
    return <>{children}</>;
  }

  let automotive = '';

  if (userData.isAutomotiveServiceRole) {
    automotive = 'service';
  }

  if (userData.isAutomotiveSalesRole) {
    automotive = 'sales';
  }

  const intercomProps: IntercomProps | undefined = {
    customAttributes: {
      ...userData.intercom,
      cv_user_id: userData.id,
      signup_date: dayjs(userData.signupDate).format('MM/DD/YYYY'),
      free_trial: userData.trialAccount,
      reseller_id: userData.resellerId,
      reseller_name: userData.reseller?.resellerName || '',
      auto: userData.reseller?.dealer?.toString() === '1',
      admin: userData.admin.toString() === '1',
      user_active: userData.verified.toString() === '1',
      company_admin:
        userData.access.toString() === AccessRole.ADMIN ||
        userData.access.toString() === AccessRole.SUPERVISOR,
      covideo_3: !userData.isAutomotive,
      covideo_4: userData.isAutomotive,
      covideo_4_service: userData.isAutomotiveServiceRole,
      covideo_4_sales: userData.isAutomotiveSalesRole,
      freemium: userData?.user?.packageDetails?.id === 1,
      plan_type: userData?.user?.packageDetails?.title || 'Legacy',
      automotive,
      phone: userData.phone1,
    },
    company: {
      companyId: userData.customerId,
      name: userData.customer?.business || '',
    },
  };

  return (
    <IntercomProvider
      appId={userData.intercom.app_id}
      autoBoot={true}
      autoBootProps={intercomProps}
    >
      {children}
    </IntercomProvider>
  );
};
