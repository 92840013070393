import { ReactNode } from 'react';

export type IRecordSettingsProvider = {
  children: ReactNode;
};
export type Option = {
  label: string;
  value: boolean | string;
};

export enum DEVICE_KIND {
  AUDIO_INPUT = 'audioinput',
  VIDEO_INPUT = 'videoinput',
}

export enum VIDEO_QUALITY {
  LOW = 'low',
  STANDARD = 'standard',
  HIGH = 'high',
}

export type Device = {
  deviceId: string;
  kind: DEVICE_KIND.AUDIO_INPUT | DEVICE_KIND.VIDEO_INPUT;
  label: string;
  groupId: string;
};

export interface IUserSettings {
  audioSource: Option | null;
  videoSource: Option | null;
  videoQuality: string;
}

export interface IRecordConfigSettings {
  audioSource: Option[];
  videoSource: Option[];
}
