import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { LabelHTMLAttributes } from 'react';

interface ICommonTypography {
  m?: string;
  textAlign?: string;
  ellipsis?: boolean;
  width?: string;
  maxWidth?: string;
}

export const CommonTypography = styled.h1<ICommonTypography>`
  font-family: 'Work Sans';

  margin: ${({ m }) => m || 0};

  color: ${({ color }) => color || theme.palette.gray100};

  text-align: ${({ textAlign }) => textAlign || 'left'};

  width: ${({ width }) => width || 'auto'};

  ${({ maxWidth }) => maxWidth && css({ maxWidth })}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

// add missing typography
export const CHeading = css`
  font-weight: 800;
  font-size: 24px;
  line-height: 40px;
  line-break: anywhere; /*** SUS-797 changes ***/
`;

export const Heading = styled(CommonTypography).attrs({ as: 'h1' })`
  ${CHeading}
`;

export const SubHeading = styled(CommonTypography).attrs({ as: 'h2' })`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
`;

export const ParagraphNormal = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export const ParagraphNormalBold = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;
export const ParagraphNormalBold600 = styled(CommonTypography).attrs({
  as: 'p',
})`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;
export const ParagraphNormalBold500 = styled(CommonTypography).attrs({
  as: 'p',
})`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;
export const ParagraphExtraSmall = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
`;

export const ParagraphSmall = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const ParagraphSmallBold = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

export const ParagraphExtraSmallBold = styled(CommonTypography).attrs({
  as: 'p',
})`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`;

export const CheckboxLabel = styled(CommonTypography).attrs({ as: 'label' })<
  LabelHTMLAttributes<HTMLLabelElement>
>`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
`;
