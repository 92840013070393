import { EXPRESS_API } from 'configs/api/expressApi';
import { QueryListParams } from '.';

const cachedTags = {
  all: [] as object[],
  user: [] as object[],
};

export type Tag = {
  id?: number;
  tagId?: string;
  tag?: string;
  label?: string;
  value?: string;
};

export const getTags = async ({
  page = 0,
  size = 50,
  search = '',
  all = false,
  sort,
  refresh = true,
}: QueryListParams) => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    search: search,
    sort: sort,
    all: '',
  };

  if (all) {
    params.all = 'true';
  }

  if (!refresh) {
    if (all && cachedTags?.all?.length) {
      return cachedTags.all;
    }
    if (!all && cachedTags?.user?.length) {
      return cachedTags.user;
    }
  }

  const response = await EXPRESS_API.get('/video-tags', { params });
  if (all) {
    cachedTags.all = response.data;
  } else {
    cachedTags.user = response.data;
  }
  return response.data;
};

export const getVideoTags = async ({ videoId }: any) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}/video-tags`);
  const videoTags: object[] = response.data || [];
  return { videoTags };
};

export const putVideoTags = async (videoId: string, data: object[]) => {
  const response = await EXPRESS_API.put(`/videos/${videoId}/video-tags`, data);
  return { videoTags: response.data };
};
