import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { videoRequestKeys } from './videoRequestKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export type VideoRequestListFilter = {
  videoType?: string;
  userId?: string;
  advisorId?: string;
  statuses?: string;
  videoId?: string;
  search?: string;
};

export type VideoRequestsParams = {
  start?: number;
  limit?: number;
  filter?: VideoRequestListFilter;
};

const getVideoRequests = async (props: VideoRequestsParams) => {
  const params = {
    search: props.filter?.search ?? '',
    advisorId: props.filter?.advisorId ?? '',
    createdByUserId: props.filter?.userId ?? '',
    videoType: props.filter?.videoType ?? '',
    statuses: props.filter?.statuses ?? '',
    videoId: props.filter?.videoId ?? '',
    start: props.start ?? 0,
    limit: props.limit ?? 100,
  };

  const response = await EXPRESS_API.get(`/video-requests`, { params });
  return response.data;
};

export const useVideoRequestsQuery = (
  params: VideoRequestsParams,
  enabled: boolean
) => {
  const { showError } = useToastError();
  return useQuery(
    videoRequestKeys.list(params),
    () => getVideoRequests(params),
    {
      onError: showError,
      refetchOnMount: true,
      enabled,
    }
  );
};
