import React from 'react';
import styled from 'styled-components/macro';
import { CountDown } from './CountDown';
import { RECORDING_STATUS } from './types';

interface RecorderBodyProps {
  countdown: number;
  videoRef: React.RefObject<HTMLVideoElement>;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
  teleprompter?: React.ReactNode;
  status: RECORDING_STATUS;
}

const RecorderBodyWrapper = styled.div`
  width: 100%;
  height: 440px;
  position: relative;
  border-radius: 8px;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const VideoStyles = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  border-radius: 8px;
`;

const CanvasStyles = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 8px;
`;

export const RecorderBody = ({
  countdown,
  videoRef,
  teleprompter,
  status,
  canvasRef,
}: RecorderBodyProps) => {
  return (
    <RecorderBodyWrapper>
      {teleprompter}
      <CountDown
        counter={countdown}
        willStart={countdown > 0}
        hide={!countdown || status === RECORDING_STATUS.CAMERA_READY}
      />

      <VideoStyles ref={videoRef} controls={false} autoPlay />
      {canvasRef && <CanvasStyles ref={canvasRef} />}
    </RecorderBodyWrapper>
  );
};
