import { useQuery } from 'react-query';
import { checkDropAuth, client } from './droplrAuth';
import { droplrKeys } from './queryKeys';
import {
  BoardsResponse,
  CombinedBoard,
  CovideoBoard,
  DroplrBoard,
} from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

export type GetBoardsParams = {
  userId: number;
  customerId: number;
};

export const getBoards = async (
  params: GetBoardsParams
): Promise<BoardsResponse> => {
  await checkDropAuth();

  // fetch boards from droplr
  const droplrResponse = await client.boards.list();
  const droplrBoards = (droplrResponse?.results as DroplrBoard[]) || [];

  // fetch board details from covideo
  const covideoResponse = (
    await EXPRESS_API.get('/board/all', {
      params,
    })
  ).data;
  const covideoBoards = (covideoResponse?.boards as CovideoBoard[]) || [];

  // add thumbnail to response
  const boards = [] as CombinedBoard[];
  droplrBoards.forEach((droplrBoard: DroplrBoard) => {
    const covideoBoard = covideoBoards.find(
      cb => cb.droplrBoardId === droplrBoard.id
    );
    boards.push({
      ...droplrBoard,
      thumbnailUrl: covideoBoard?.thumbnailUrl || '',
    });
  });

  return {
    boards,
    count: boards.length,
  };
};

export const useGetBoards = (params: GetBoardsParams) => {
  return useQuery(droplrKeys.boards_list(params), () => getBoards(params));
};
