import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';
import { VideoDetails } from './VideoDetails';
import { Container, MainWrapper } from 'lib/components/styles/layout';

export const Main = () => {
  const location = useLocation();
  const hasPadding = location.pathname === '/reports/users';

  return (
    <Container>
      <MainWrapper resetPadding={hasPadding}>
        <Switch>
          <Route path="/reports/users" component={List} exact={true} />
          <Route
            path="/reports/users/:userId"
            component={Details}
            exact={true}
          />
          <Route
            path="/reports/users/:userId/videos/:videoId"
            component={VideoDetails}
            exact={true}
          />
        </Switch>
      </MainWrapper>
    </Container>
  );
};
