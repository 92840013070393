import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { NewTextInput } from 'lib/components';
import { useAuth } from 'lib/context';

type CTA = {
  id: number;
  actionText: string;
  url: string;
  isSaved: boolean;
};

type WrapperProps = {
  withScrollbar?: boolean;
};

interface ComponentProps {
  ctas: CTA[];
  setCtas: React.Dispatch<React.SetStateAction<CTA[]>>;
}

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FieldNames = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 48px;
  &:nth-of-type(n + 1) {
    margin-top: 24px;
  }
`;

const Fields = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  input {
    max-width: 372px;
  }
`;

const FieldsWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => (props.withScrollbar ? '783px' : '768px')};
  max-height: 264px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const CTAName = styled.label`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 372px;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 1.71;
  color: ${theme.palette.themeDark75Percent};
  font-weight: 500;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 768px;
  width: 100%;
  margin-top: 24px;
`;

const AddCTAButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 194px;
  height: 48px;
  border: 1px solid ${theme.palette.primaryDarkBlue};
  background-color: #fff;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

const CtaButtonText = styled.p`
  flex-grow: 2;
  ${theme.fontBold700}
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 31, 104);
`;

const CtaButtonIcon = styled.svg`
  margin-left: 16px;
  width: 24px;
  height: 24px;
  path {
    fill: ${theme.palette.primaryDarkBlue};
    opacity: 0.5;
  }
`;

export const CallToActionForm = (props: ComponentProps) => {
  const { ctas, setCtas } = props;

  const handleAddCTA = () => {
    const newCta = {
      id: ctas.length + 1,
      actionText: '',
      url: '',
      isSaved: false,
    };
    setCtas(ctas.concat(newCta));
  };

  const editCta = ({
    event,
    id,
    field,
  }: {
    event: React.ChangeEvent<HTMLInputElement>;
    id: number;
    field: string;
  }): void => {
    const { value } = event.target;
    const withEdited = ctas.map((cta: CTA) => {
      if (cta.id === id) {
        cta = { ...cta, [field]: value, isSaved: false };
      }
      return cta;
    });
    setCtas(withEdited);
  };
  const { whitelabel } = useAuth();

  return (
    <Container>
      <FieldsWrapper withScrollbar={ctas.length > 2}>
        {ctas.map(cta => {
          return (
            <div key={cta.id}>
              <FieldNames>
                <CTAName>Call to Action Text</CTAName>
                <CTAName>URL</CTAName>
              </FieldNames>
              <Fields>
                <NewTextInput
                  value={cta.actionText}
                  placeholder={'e.g. Contact Us'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    editCta({ event, id: cta.id, field: 'actionText' })
                  }
                />
                <NewTextInput
                  value={cta.url}
                  placeholder={'e.g. ' + whitelabel.homepage}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    editCta({ event, id: cta.id, field: 'url' })
                  }
                />
              </Fields>
            </div>
          );
        })}
      </FieldsWrapper>
      <ButtonRow>
        <AddCTAButton onClick={handleAddCTA}>
          <CtaButtonIcon xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
            <defs>
              <filter id='prefix__a'>
                <feColorMatrix
                  in='SourceGraphic'
                  values='0 0 0 0 0.000000 0 0 0 0 0.105882 0 0 0 0 0.325490 0 0 0 1.000000 0'
                />
              </filter>
            </defs>
            <g
              fill='none'
              fillRule='evenodd'
              filter='url(#prefix__a)'
              transform='translate(-16 -12)'
            >
              <path
                d='M24 8.211L15.789 8.211 15.789 0 8.211 0 8.211 8.211 0 8.211 0 15.789 8.211 15.789 8.211 24 15.789 24 15.789 15.789 24 15.789z'
                transform='translate(16 12)'
              />
            </g>
          </CtaButtonIcon>
          <CtaButtonText>Add Another CTA</CtaButtonText>
        </AddCTAButton>
      </ButtonRow>
    </Container>
  );
};
