import { theme } from 'lib/style';
import * as React from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';

const Switch = styled.div`
  box-sizing: border-box;
  height: 40px;
  padding: 4px;
  border-radius: 6px;
  border: solid 1px ${theme.palette.gray40};
  background-color: rgba(0, 27, 83, 0.05);
  display: flex;
  align-items: center;
`;

type Value = {
  value: string;
  icon: React.ReactNode;
};

type Props = {
  defaultValue: string;
  onChange: (value: string) => void;
  values: Value[];
};

export const ButtonIconSwitch = (props: Props) => {
  const { defaultValue, values, onChange } = props;
  const [value, setValue] = React.useState(defaultValue);

  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Switch>
      {values.map(btn => {
        if (btn.value === value) {
          return (
            <Button
              icon={btn.icon as JSX.Element}
              variant='primary'
              size='small'
            />
          );
        }
        return (
          <Button
            key={btn.value}
            icon={btn.icon as JSX.Element}
            size='small'
            variant='transparent'
            onClick={() => {
              setValue(btn.value);
              onChange(btn.value);
            }}
          />
        );
      })}
    </Switch>
  );
};
