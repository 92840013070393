import { Dropdown, NewModal } from 'lib/components';
import React, { useCallback, useEffect, useState } from 'react';

import { Gap } from 'lib/components/styles/layout';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { useFreeUsersQuery } from 'lib/api/multiLocations/useFreeUsersQuery';
import {
  ChildCustomer,
  ParentCustomer,
} from 'lib/api/multiLocations/multiLocationQuery';
import { useUpdateChildUserAccessMutation } from 'lib/api/multiLocations/useUpdateChildUserMutation';
import { CustomUserSelectionLabel } from '../components/CustomUsersSelectionLabel';
import { Button } from 'react-covideo-common';

interface Option {
  value: number | string;
  label: string;
  username: string;
  lastName: string;
  firstName: string;
  email: string;
  customerId: number;
}

interface IProps {
  closeModalHandler: () => void;
  parentUserId: string | number;
  childCustomer: ChildCustomer | undefined;
}

export const EditUserAccessModal = ({
  closeModalHandler,
  parentUserId,
  childCustomer,
}: IProps) => {
  const customerId = childCustomer?.customerId || null;
  const { values } = useFormikContext<ParentCustomer>();

  const [search, setSearchText] = useState('');
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const { mutateAsync: mutateEditionAsync, isLoading: isUpdatingChildUser } =
    useUpdateChildUserAccessMutation(closeModalHandler);

  const {
    data,
    isLoading: isFetching,
    refetch,
  } = useFreeUsersQuery({
    search,
    customerId: customerId,
  });

  const debouncedInputChange = useCallback(
    debounce(inputValue => {
      setSearchText(inputValue);
    }, 300),
    []
  );

  const onInputChangeHandler = (newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  useEffect(() => {
    refetch();
  }, [search]);

  const addNewUserToAll = (value: Option) => {
    setSelectedValue(value);
  };

  const childUserIDs = values.childCustomers.flatMap(childCustomer =>
    childCustomer.users
      .filter(user => user.id !== 'add' && user.id !== 'remove')
      .map(user => user.id)
  );

  const optionsValues =
    data?.users?.map(user => ({
      value: user.id,
      label: user.email,
      username: user.username,
      lastName: user.lastName,
      firstName: user.firstName,
      email: user.email,
      customerId: user.customerId,
    })) || [];

  return (
    <NewModal
      headerText='Create or Connect Username'
      closeModal={closeModalHandler}
      style={{ content: { minWidth: 592, width: 592 } }}
    >
      <Dropdown
        options={[
          { value: 'add', label: 'Create New User' },
          ...optionsValues.filter(
            option => !childUserIDs.includes(option.value)
          ),
        ]}
        value={selectedValue}
        onChange={(value: Option) => {
          addNewUserToAll(value);
        }}
        placeholder={isFetching ? '' : 'Select child user'}
        isClearable={true}
        extendStyles={{
          container: {
            width: '100%',
            margin: '10px 0 15px 0',
          },
        }}
        isSearchable={true}
        creatable={false}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        menuPosition='absolute'
        menuPlacement='bottom'
        filterOption={null}
        isLoading={isFetching}
        onInputChange={onInputChangeHandler}
        disabled={isUpdatingChildUser}
        formatOptionLabel={CustomUserSelectionLabel}
      />
      <Gap
        m='32px 0 0 0'
        alignItems='center'
        justifyContent='flex-end'
        gap='12px'
      >
        <Button
          text={isUpdatingChildUser ? 'Connecting...' : 'Connect'}
          disabled={values.customerId === null || isUpdatingChildUser}
          type='submit'
          onClick={() => {
            if (!!childCustomer && !!selectedValue && values.customerId) {
              mutateEditionAsync({
                parentUserId: `${parentUserId}`,
                userId: `${selectedValue.value}`,
                customerId: childCustomer.customerId,
              });
            }
          }}
        />
      </Gap>
    </NewModal>
  );
};
