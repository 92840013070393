import React from 'react';
import styled from 'styled-components/macro';
import { UsersChatLayout } from '../../components';

const Container = styled.div`
  margin: 0px 0px 0px 150px;
  padding: 15px 20px 0px 80px;
  height: 100%;
`;

export const Chat = () => {
  return (
    <Container>
      <UsersChatLayout />
    </Container>
  );
};
