import React from 'react';
import { Dropdown } from '../dropdown/Dropdown';

interface ICustomReportTypeDropdown {
  value: string | null;
  label: string;
}

export enum CUSTOM_REPORT_TYPE {
  STATIC = '0',
  RECURRING = '1',
}

const dropdownTypeOptions: ICustomReportTypeDropdown[] = [
  { value: null, label: 'All' },
  { value: CUSTOM_REPORT_TYPE.STATIC, label: 'Static' },
  { value: CUSTOM_REPORT_TYPE.RECURRING, label: 'Recurring' },
];

type Props = {
  onDropdownChangeHandler: (value: string) => void;
  selectedTypeOption: string | null;
  extendStyles?: {
    container?: React.CSSProperties;
    menu?: React.CSSProperties;
    control?: React.CSSProperties;
    menuList?: React.CSSProperties;
    valueContainer?: React.CSSProperties;
    input?: React.CSSProperties;
  };
};

export const CustomReportTypeDropdown = ({
  onDropdownChangeHandler,
  selectedTypeOption,
  extendStyles,
}: Props) => {
  return (
    <Dropdown
      extendStyles={extendStyles}
      menuPortalTarget={document.body}
      zIndexProp={1}
      menuPlacement={'bottom'}
      height={40}
      creatable={false}
      className='dropdown'
      placeholder={'Type'}
      value={dropdownTypeOptions.find(
        option => option.value === selectedTypeOption && !!selectedTypeOption
      )}
      onChange={value => onDropdownChangeHandler(value.value)}
      options={dropdownTypeOptions}
    />
  );
};
