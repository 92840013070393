export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export const isOpera =
  //@ts-ignore
  (!!window.opr && !!opr.addons) ||
  //@ts-ignore
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;

//@ts-ignore
export const isFirefox = typeof InstallTrigger !== 'undefined';
