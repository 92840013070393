import Cookies from 'js-cookie';
import { EXPRESS_API } from 'configs/api/expressApi';

const Droplr = require('droplr-api');

export let client: any;

export const checkDropAuth = async () => {
  if (!client) {
    const { data } = await EXPRESS_API.get('/droplr/auth');
    Cookies.set('jwtToken', data.jwt, {
      domain: '.covideo.com',
      sameSite: 'strict',
      secure: true,
    });
    client = new Droplr.Client({
      auth: new Droplr.JwtAuth(data.jwt),
    });

    client.sharedDrop = new SharedDrop(client);
  }
};

export class SharedDrop {
  path: string;
  client: any;
  constructor(client: any) {
    this.client = client;
    this.path = 'drops/groups/shared';
  }
  async get(id: string, options = {}) {
    const response = await this.client.get(`${this.path}/${id}`, options);
    return response.data;
  }
  async list(params = {}, options = {}) {
    const localOptions = {
      params,
    };
    const response = await this.client.get(
      this.path,
      Object.assign(localOptions, options)
    );
    return {
      count: response.headers['x-results-count'],
      hasMore: response.headers['x-has-more'],
      results: response.data,
    };
  }
}
