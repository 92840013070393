import { EXPRESS_API } from 'configs/api/expressApi';
import { CreateCarServiceBulkParams } from 'lib/api/carService/types';
import { useMutation, useQueryClient } from 'react-query';
import { carServiceKeys } from './carServiceKeys';

const updateCarServices = async ({ data }: CreateCarServiceBulkParams) => {
  const response = await EXPRESS_API.put(`/services`, { services: data });
  return response.data;
};

export const useUpdateCarServicesMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCarServices, {
    onSuccess: () => {
      queryClient.invalidateQueries(carServiceKeys.all());
    },
  });
};
