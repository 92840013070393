import { EMAIL_REGEX, URL_REGEX } from 'lib/utils/regexes';
import * as Yup from 'yup';

export const linkTypes = ['No URL', 'Web', 'Email', 'Phone'];

export const annotationTypes = {
  ANNOTATION_TEXT: 'text',
  ANNOTATION_IMAGE: 'image',
};
export const typeValues = [
  {
    value: annotationTypes.ANNOTATION_TEXT,
    text:
      annotationTypes.ANNOTATION_TEXT.charAt(0).toUpperCase() +
      annotationTypes.ANNOTATION_TEXT.slice(1),
  },
  {
    value: annotationTypes.ANNOTATION_IMAGE,
    text: 'Logo',
  },
];

export const annotationsSchema = Yup.object({
  position: Yup.string().required('Required'),
  url: Yup.string().test('isUrl', 'URL is not valid', function (value = '') {
    if (!value) {
      return true;
    }
    if (this.parent && this.parent.linkType !== 'Web') {
      return true;
    }
    return value.match(URL_REGEX);
  }),
  email: Yup.string().test(
    'isEmail',
    'Email is not valid',
    function (value = '') {
      if (!value) {
        return true;
      }

      if (this.parent && this.parent.linkType !== 'Email') {
        return true;
      }
      return value.match(EMAIL_REGEX);
    }
  ),
  phone: Yup.string().test(
    'isPhone',
    'Phone is not valid',
    function (value = '') {
      if (!value) {
        return true;
      }

      if (this.parent && this.parent.linkType !== 'Phone') {
        return true;
      }
      return value.match(/^[0-9()\-.\s]+$/i);
    }
  ),
});
