import React, { useState } from 'react';
import { theme } from '../../../../../lib/style';
import styled from 'styled-components/macro';
import {
  Container,
  Gap,
  HeaderWrapper,
  MainWrapper,
} from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';

import { LoadingIndicator, ModalDelete, Search, Tabs } from 'lib/components';
import { Tab } from 'lib/components/tabs';
import { List } from '../../sharedReports/main/List';
import { CreatedByMe } from './CreatedByMe';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { useCustomReportsQuery } from 'lib/api/customReports/useCustomReportsQuery';
import { NoList } from 'app/pages/admin/index.styled';
import { useDeleteCustomReport } from 'lib/api/customReports/deleteCustomReport';
import { CustomReportButton } from '../CustomReportButton';
import { RecurringWeeklyMonthly } from './RecurringWeeklyMonthly';
import { CustomReportTypeDropdown } from 'lib/components/CustomReportTypeDropdown/CustomReportTypeDropdown';

enum CUSTOM_REPORT_TAB {
  CREATED_BY_ME = 0,
  SHARED_WITH_ME = 1,
}

let tabs: Tab[] = [
  {
    title: 'Created By Me',
    component: <></>,
    inActiveTextColor: theme.palette.gray60,
  },
  {
    title: 'Shared With Me',
    component: <></>,
    inActiveTextColor: theme.palette.gray60,
  },
];

const TabsContainer = styled.div`
  width: 290px;
`;

const ContentWrapper = styled.div`
  margin-top: 32px;
`;

export const CustomReports = () => {
  const [activeTab, setActiveTab] = useState(CUSTOM_REPORT_TAB.CREATED_BY_ME);
  const [reportsPage, setReportsPage] = useState(DEFAULT_PAGE);
  const [reportsSize, setReportsSize] = useState(DEFAULT_SIZE);
  const [search, setReportsSearch] = useState('');

  const [reportsSortKey, setReportsSortKey] = useState('');
  const [reportsSortOrder, setConnectionsSortOrder] = useState(
    PaginationConstants.ASCENDING
  );

  const [selectedTypeOption, setSelectedTypeOption] = React.useState<
    string | null
  >(null);

  const sort = {
    sortKey: reportsSortKey,
    order: reportsSortOrder || PaginationConstants.ASCENDING,
  };
  const [showDeleteModal, setShowDeleteModal] = useState<string | null>(null);

  const customReportsQueryParams = {
    start: search === '' ? reportsPage * reportsSize : DEFAULT_PAGE,
    limit: reportsSize,
    search,
    sort,
  };

  const { data, isLoading } = useCustomReportsQuery({
    ...customReportsQueryParams,
    ...(selectedTypeOption !== null ? { recurring: selectedTypeOption } : {}),
  });

  const { mutateAsync: deleteCustomReport, isLoading: isDeletingCustomReport } =
    useDeleteCustomReport(customReportsQueryParams);

  const onPaginationChange = async ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    setReportsPage(page);
    setReportsSize(size);
  };
  const onDropdownChangeHandler = (value: string) => {
    setSelectedTypeOption(value);
  };

  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <HeaderWrapper>
          <Heading>Custom Reports</Heading>

          <CustomReportButton text='Create Report' />
        </HeaderWrapper>
        <RecurringWeeklyMonthly />
        <TabsContainer>
          <Tabs
            tabs={tabs}
            defaultActiveTab={activeTab}
            onChange={setActiveTab}
            navbarPadding={0}
          />
        </TabsContainer>
        <ContentWrapper>
          {activeTab === CUSTOM_REPORT_TAB.CREATED_BY_ME && (
            <>
              <Gap justifyContent='space-between' m='0 0 16px 0'>
                <Search
                  onSearch={setReportsSearch}
                  prevSearch={search}
                  placeholder='Search reports…'
                  width={'250px'}
                />
                <CustomReportTypeDropdown
                  selectedTypeOption={selectedTypeOption}
                  onDropdownChangeHandler={onDropdownChangeHandler}
                  extendStyles={{
                    valueContainer: {
                      overflow: 'hidden',
                      height: '40px',
                      width: 200,
                    },
                  }}
                />
              </Gap>
              {isLoading && (
                <div style={{ width: '100%', height: '300px' }}>
                  <LoadingIndicator isLoading={isLoading} height='300px' />
                </div>
              )}
              {!isLoading && !!data?.customReports?.length && (
                <CreatedByMe
                  sortKey={sort.sortKey}
                  order={sort.order}
                  reportsArray={data.customReports}
                  page={reportsPage}
                  reportsCount={data.count}
                  size={reportsSize}
                  onPaginationChange={onPaginationChange}
                  deleteAction={setShowDeleteModal}
                  isDeletingCustomReport={isDeletingCustomReport}
                  onSortingUpdate={(connectionsSortKey, order) => {
                    setReportsSortKey(connectionsSortKey);
                    setConnectionsSortOrder(order);
                    setReportsPage(DEFAULT_PAGE);
                  }}
                  setLimit={setReportsSize}
                  customReportsQueryParams={customReportsQueryParams}
                />
              )}
              {!isLoading && !data?.customReports?.length && (
                <NoList>No Custom Reports to show.</NoList>
              )}
            </>
          )}
          {activeTab === CUSTOM_REPORT_TAB.SHARED_WITH_ME && <List />}
        </ContentWrapper>
        {showDeleteModal && (
          <ModalDelete
            whiteButtonText='Yes, Delete'
            orangeButtonText='No, Cancel'
            title='Delete custom report?'
            text={`This action can’t be undone.`}
            handleModalClose={() => setShowDeleteModal(null)}
            loading={isDeletingCustomReport}
            onClickWhiteButton={async () => {
              await deleteCustomReport(showDeleteModal);
              setShowDeleteModal(null);
            }}
            onClickOrangeButton={() => setShowDeleteModal(null)}
          />
        )}
      </MainWrapper>
    </Container>
  );
};
