import { Field, FieldProps, useFormikContext } from 'formik';
import { Switch } from 'lib/components';
import React from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { Gap } from 'lib/components/styles/layout';
import { ParagraphNormal } from 'lib/components/styles/typography';
import { MeridiemPicker } from './MeridiemPicker';
import { UserValues } from '../userValidator';
import { DayValues } from '.';
import { IWorkingDay } from '../../main/EditUserProfile';

const MainWrapper = styled(Gap)`
  height: 42px;
  padding: 10px 0;
  border-bottom: 1px solid #eeeff2;
  .btn-switch {
    margin: 0;
  }
`;

const DatePickerWrapper = styled(Gap)<{ disabled: boolean }>`
  position: relative;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1.0')};
  :after {
    ${({ disabled }) =>
      disabled &&
      css`
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      `}
  }
`;

const ParagraphWrapper = styled.div`
  width: 95px;
`;

interface IDailyHours {
  day: {
    value: DayValues;
    label: string;
  };
}
export const DailyHours = ({ day }: IDailyHours) => {
  const { value, label } = day;
  const { values, setFieldValue, errors } = useFormikContext<UserValues>();

  const switchValue = `workingHours.${value}`;
  const isNullValue = values?.workingHours?.[value] === null;

  const currentError = errors?.workingHours?.[value] as unknown as IWorkingDay;
  const isFromHoursError = !!currentError?.from?.hours;
  const isToHoursError = !!currentError?.to?.hours;

  const themes = useTheme() as any;
  return (
    <MainWrapper gap='90px'>
      <Gap>
        <ParagraphWrapper>
          <ParagraphNormal color='#272A32'>{label}</ParagraphNormal>
        </ParagraphWrapper>

        <Field name={switchValue}>
          {({ field }: FieldProps) => {
            return (
              <Switch
                id={`switch-${value}`}
                {...field}
                isOn={!isNullValue}
                onColor={themes.colors.primary[100]}
                handleToggle={() => {
                  if (isNullValue) {
                    return setFieldValue(switchValue, {
                      from: { hours: '', meridiem: 'AM' },
                      to: { hours: '', meridiem: 'AM' },
                    } as IWorkingDay);
                  }
                  return setFieldValue(switchValue, null);
                }}
              />
            );
          }}
        </Field>
      </Gap>
      <DatePickerWrapper gap='4px' disabled={isNullValue}>
        <MeridiemPicker
          hoursName={`workingHours.${value}.from.hours`}
          meridiemValue={`workingHours.${value}.from.meridiem`}
          defaultMeridiemValue={
            values?.workingHours?.[value]?.from?.meridiem || 'AM'
          }
          isError={isFromHoursError}
        />
        <ParagraphNormal color='#9297A2'>-</ParagraphNormal>
        <MeridiemPicker
          hoursName={`workingHours.${value}.to.hours`}
          meridiemValue={`workingHours.${value}.to.meridiem`}
          defaultMeridiemValue={
            values?.workingHours?.[value]?.to?.meridiem || 'AM'
          }
          isError={isToHoursError}
        />
      </DatePickerWrapper>
    </MainWrapper>
  );
};
