import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { CheckboxInput } from 'lib/components';
import { SuperAdminCustomer, useAuth } from 'lib/context';
import { isAdminSession } from 'lib/utils/auth';
import { SelectedCustomersActions } from './SelectedCustomersActions';

type ContainerProps = {
  padding?: number;
};

const SelectionWrapper = styled.div<ContainerProps>`
  position: fixed;
  left: 0;
  right: 0;
  top: 65px;
  background: ${theme.palette.white};
  height: 40px;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.padding || 12}px 0 12px 208px;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px ${theme.palette.gray};
`;
const SelectionContainer = styled.div`
  width: 1048px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;
const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;
type Props = {
  selectedCustomers: SuperAdminCustomer[];
  customerArray: SuperAdminCustomer[];
  showResellerButton: boolean;
  setSelectedCustomers(selectedCustomers: SuperAdminCustomer[]): void;
  onSuccessfullUpdate(): void;
};

export const SelectedCustomers = ({
  selectedCustomers,
  customerArray,
  showResellerButton,
  setSelectedCustomers,
  onSuccessfullUpdate,
}: Props) => {
  const { userData } = useAuth();

  return (
    <SelectionWrapper
      padding={isAdminSession() || userData.trialAccount ? 75 : 12}
    >
      <SelectionContainer>
        <CheckboxSelectionWrapper>
          <CheckboxInput
            blueCheck={true}
            checkGroupIndicator={
              selectedCustomers.length > 0 &&
              customerArray.length !== selectedCustomers.length
            }
            checked={customerArray.length == selectedCustomers.length}
            ignoreGrayForAllSelect={true}
            grayCheck={true}
            onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              const { checked } = event.target as HTMLInputElement;
              setSelectedCustomers(checked ? customerArray : []);
            }}
          />
          <SelectionCountWrap>
            <SelectionCountText>Customers selected:</SelectionCountText>
            <b>{selectedCustomers.length}</b>
          </SelectionCountWrap>
        </CheckboxSelectionWrapper>
        <SelectedCustomersActions
          selectedCustomers={selectedCustomers}
          showResellerButton={showResellerButton}
          onSuccessfullUpdate={onSuccessfullUpdate}
        />
      </SelectionContainer>
    </SelectionWrapper>
  );
};
