import styled from 'styled-components/macro';
import { theme } from 'lib/style';

export const PaginationWrapper = styled.div`
  width: 100%;
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ViewerText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 0;
  font-weight: 400;
  color: ${theme.palette.neutral60};
`;
