import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { CreateVideoDeleteParams } from './types';
import { videoDeletesKeys } from './queryKeys';

export const createVideoDelete = async (data: CreateVideoDeleteParams) => {
  const response = await EXPRESS_API.post('/video-deletes', data);
  return response.data;
};

export const useCreateVideoDeleteMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: CreateVideoDeleteParams) => createVideoDelete(data),
    {
      onError: () => {
        errorToast({
          title:
            'An error occurred creating video delete, please try again later!',
        });
      },
      onSuccess: () => {
        successToast({
          title: `You have successfully created a new video deletion`,
        });
        queryClient.refetchQueries(videoDeletesKeys.get_video_delete());
        onSuccess();
      },
    }
  );
};
