import * as React from 'react';
import { MoonLoader } from 'react-spinners';
import { FcCheckmark } from 'react-icons/fc';

import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdClose } from 'react-icons/md';
interface Props {
  isDefault?: boolean;
  isHeader?: boolean;
  fields: string[];
  mainButtonText: string;
  allowDefaultRemoval?: boolean;
  optionButtons: React.ReactNode[];
  onClickMainButton?: (params?: any) => Promise<void>;
  onClickRemoveDefault?: (params?: any) => Promise<void>;
  isAdminView?: boolean /* SUS-1299 changes */;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #eeeff2;
`;

const ButtonsContainer = styled.div<{
  isHeader?: boolean;
  isAdminView?: boolean;
}>`
  /* SUS-1299 changes */
  display: ${props => (props.isHeader ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: ${props =>
    props.isAdminView ? '35%' : 'auto'}; /* SUS-1299 changes */
  height: 100%;
`;

const OptionsContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  width: ${props => 52 * props.itemsNumber}px;
  justify-content: space-between;
  button {
    margin-left: 12px;
  }
`;

const DefaultText = styled.label<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;

  font-weight: 600;
  font-size: 15px;
  color: ${theme.palette.coal};
  &:hover {
    cursor: ${props => (props.allowRemoval ? 'pointer' : 'default')};
  }
  svg {
    margin-right: 7px;
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainButtonContainer = styled.div<{ isAdminView?: boolean }>`
  /* SUS-1299 changes */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: ${props =>
    props.isAdminView ? '171px' : '140px'}; /* SUS-1299 changes */
  margin: ${props =>
    props.isAdminView ? '0 16px' : '0 0px'}; /* SUS-1299 changes */
`;

const TextRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 65%;
`;

const TextCell = styled.section<{ isHeader?: boolean }>`
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-weight: ${props => (props.isHeader ? '500' : '400')};
  font-size: ${props => (props.isHeader ? '14px' : '16px')};
  color: ${props => (props.isHeader ? '#9297a2' : theme.palette.coal)};
`;
// Methods

// Component
export const SimpleRow = (props: Props) => {
  const {
    isDefault,
    isHeader,
    fields,
    allowDefaultRemoval,
    mainButtonText,
    optionButtons,
    onClickMainButton,
    onClickRemoveDefault,
    isAdminView = true /* SUS-1299 changes */,
  } = props;
  const themes = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [showRemoveDefault, setShowRemoveDefault] = React.useState(false);

  const unsetAsDefault = handleUnsetDefault(
    allowDefaultRemoval,
    onClickRemoveDefault,
    setLoading
  );

  const setAsDefault = handleDefaultClick(onClickMainButton, setLoading);

  const onHover = handleOnHover(allowDefaultRemoval, setShowRemoveDefault);

  return (
    <Container>
      <TextRow>
        {fields.map((item, index) => (
          <TextCell key={index} isHeader={isHeader}>
            {item}
          </TextCell>
        ))}
      </TextRow>
      <ButtonsContainer isHeader={isHeader} isAdminView={isAdminView}>
        <MainButtonContainer isAdminView={isAdminView}>
          {isDefault ? (
            loading ? (
              <LoaderWrapper>
                <MoonLoader size={20} color={themes.colors.primary[100]} />
              </LoaderWrapper>
            ) : (
              <DefaultText
                onMouseOver={() => onHover(true)}
                onMouseOut={() => onHover(false)}
                allowRemoval={allowDefaultRemoval}
                onClick={() => unsetAsDefault()}
                aria-label={showRemoveDefault ? 'Remove default' : 'Default'}
                name={'defaultRowElementLabel'}
                id={'defaultRowElementLabel'}
              >
                {showRemoveDefault ? (
                  <>
                    <MdClose size={20} color={theme.palette.tomatoRed} />
                    Remove default
                  </>
                ) : (
                  <>
                    <FcCheckmark size={20} />
                    Default
                  </>
                )}
              </DefaultText>
            )
          ) : (
            <Button
              text={loading ? '' : mainButtonText}
              aria-label={loading ? 'Main button is loading.' : mainButtonText}
              icon={
                loading ? (
                  <MoonLoader size={20} color={themes.colors.primary[100]} />
                ) : undefined
              }
              variant='secondary'
              onClick={setAsDefault}
            />
          )}
        </MainButtonContainer>
        <OptionsContainer itemsNumber={optionButtons.length}>
          {optionButtons.map((item, index) => (
            <React.Fragment key={index}>{item}</React.Fragment>
          ))}
        </OptionsContainer>
      </ButtonsContainer>
    </Container>
  );
};

// Methods
function handleDefaultClick(
  onClickMainButton: ((params?: any) => Promise<void>) | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async () => {
    if (onClickMainButton) {
      setLoading(true);
      await onClickMainButton();
      setLoading(false);
    }
  };
}

function handleOnHover(
  allowDefaultRemoval: boolean | undefined,
  setShowRemoveDefault: React.Dispatch<React.SetStateAction<boolean>>
) {
  return (hover: boolean) => {
    if (allowDefaultRemoval && hover) {
      setShowRemoveDefault(true);
    } else {
      setShowRemoveDefault(false);
    }
  };
}

function handleUnsetDefault(
  allowDefaultRemoval: boolean | undefined,
  onClickRemoveDefault: ((params?: any) => Promise<void>) | undefined,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  return async () => {
    if (allowDefaultRemoval && onClickRemoveDefault) {
      setLoading(true);
      await onClickRemoveDefault();
      setLoading(false);
    }
  };
}
