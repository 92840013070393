import React from 'react';
import { EmptyWidget } from '../EmptyWidget';
import Header from './common/Header';
import { generateWidgetData } from './helper';
import {
  Body,
  BodyParagraph,
  Tooltip,
  TopVariantsTooltipWrapper,
} from './styles/topVariants';
import { VariantData } from './types';

interface TopVideosTableBodyProps {
  views: any;
  engagement: any;
  cta: any;
  selected: any;
  variantData: VariantData;
  variant: string;
}

const TopVideosTableBody = ({
  views,
  engagement,
  cta,
  selected,
  variantData,
  variant,
}: TopVideosTableBodyProps) => {
  //Setting the correct values based on three different API calls

  const widgetData: any = {
    views: generateWidgetData(views, selected.id),
    engagement: generateWidgetData(engagement, selected.id),
    'cta-clicks': generateWidgetData(cta, selected.id),
  };

  const chartData = widgetData[selected.id];
  return chartData?.length ? (
    <>
      <Header
        selectedLabel={selected?.label}
        variantData={variantData}
        variant={variant}
      />
      {chartData?.map((item: any, index: number) => (
        <Body key={index} lastRow={index === chartData?.length - 1}>
          <BodyParagraph flex="0.1" bold>
            {index + 1}
          </BodyParagraph>
          <TopVariantsTooltipWrapper flex="0.3">
            <BodyParagraph>{item.title}</BodyParagraph>
            <Tooltip className="tooltip">{item.title}</Tooltip>
          </TopVariantsTooltipWrapper>
          <TopVariantsTooltipWrapper flex="0.3">
            <BodyParagraph opacity>{item.name}</BodyParagraph>
            <Tooltip className="tooltip">{item.name}</Tooltip>
          </TopVariantsTooltipWrapper>

          <BodyParagraph bold flex="0.3" textAlign="right">
            {item.value}
          </BodyParagraph>
        </Body>
      ))}
    </>
  ) : (
    <EmptyWidget />
  );
};

export default TopVideosTableBody;
