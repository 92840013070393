import * as React from 'react';
import { IoMdArrowRoundDown, IoMdArrowRoundUp } from 'react-icons/io';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { SortCell } from '../index.styled';

type Props = {
  isSorted: boolean;
  order?: PaginationConstants;
  onChange: (value: PaginationConstants) => void;
};

export const SortButtons = (props: Props) => {
  const { isSorted, order, onChange } = props;
  return (
    <SortCell cursor='pointer'>
      <IoMdArrowRoundUp
        className={
          isSorted && order === PaginationConstants.ASCENDING ? 'active' : ''
        }
        onClick={() => onChange(PaginationConstants.ASCENDING)}
      />
      <IoMdArrowRoundDown
        className={
          isSorted && order === PaginationConstants.DESCENDING ? 'active' : ''
        }
        onClick={() => onChange(PaginationConstants.DESCENDING)}
      />
    </SortCell>
  );
};
