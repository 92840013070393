import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ContactUs } from './ContactUs';

export const Main = () => {
  return (
    <Switch>
      <Route path='/upgrade-plan' component={ContactUs} exact={true} />
    </Switch>
  );
};
