export type SocialLinksTypes =
  | 'facebook'
  | 'tikTok'
  | 'instagram'
  | 'twitter'
  | 'whatsApp'
  | 'linkedIn'
  | 'pinterest'
  | 'youtube'
  | 'yelp'
  | 'bni'
  | 'dealerRater'
  | 'edmunds'
  | 'carsdotcom'
  | 'zeemee';

interface Options {
  label: string;
  value: SocialLinksTypes;
}

export const socialLinksOptions: Options[] = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'TikTok', value: 'tikTok' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Whatsapp', value: 'whatsApp' },
  { label: 'LinkedIn', value: 'linkedIn' },
  { label: 'Pinterest', value: 'pinterest' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'Yelp', value: 'yelp' },
  { label: 'BNI', value: 'bni' },
  { label: 'Dealer Rater', value: 'dealerRater' },
  { label: 'Edmunds', value: 'edmunds' },
  { label: 'Cars.com', value: 'carsdotcom' },
  { label: 'ZeeMee', value: 'zeemee' },
];
