export const meetingKeys = {
  meetings_list: () => ['MEETINGS_LIST'] as const,
  meetings_list_tab: (status: string) => ['MEETINGS_LIST', status] as const,
  meeting_details: (meetingId: string) =>
    ['MEETING_DETAILS', meetingId] as const,
  chat_meeeting: (meetingId: string) => ['CHAT_MEETING', meetingId] as const,
  meeting_recordings: (meetingId: string) =>
    ['MEETING_RECORDINGS', meetingId] as const,
  meeting_is_started: () => ['MEETING_IS_STARTED'] as const,
};
