import { useState } from 'react';

interface IProps {
  id: 'clicks' | 'views' | 'engagements';
  label: string;
}

export const useTabs = (initialState: IProps) => {
  const [selected, setSelected] = useState(initialState);
  return { selected, setSelected };
};
