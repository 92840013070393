import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { useMutation } from 'react-query';

interface MergeVideoItem {
  start: string;
  end: string;
  id: string;
  flvName: string;
}

interface MergeParams {
  videos: MergeVideoItem[];
  videoName: string;
  duration: number;
}

const mergeVideoEntities = async (body: MergeParams) => {
  const response = await EXPRESS_API.post(`/videos/merge`, body);
  return response.data;
};

export const useVideosMergeMutation = () => {
  return useMutation(mergeVideoEntities, {
    onError: () => {
      errorToast({
        title: 'Error in merging videos!',
      });
    },
  });
};
