import { NewModal } from 'lib/components';
import React from 'react';

import { Gap } from 'lib/components/styles/layout';
import { useFormikContext } from 'formik';
import { ParentCustomer } from 'lib/api/multiLocations/multiLocationQuery';
import { useRemoveChildUserMutation } from 'lib/api/multiLocations/useRemoveChildUserMutation';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
  userIdx: number;
  isEditMode: boolean;
}

export const RemoveUserFromCustomerModal = ({
  closeModalHandler,
  userIdx,
  isEditMode,
}: IProps) => {
  const { values, setValues } = useFormikContext<ParentCustomer>();

  const { mutateAsync, isLoading } =
    useRemoveChildUserMutation(closeModalHandler);

  const removeUsers = () => {
    if (isEditMode) {
      mutateAsync({ userId: values.users[userIdx].id });
      return;
    }
    const updatedChildCustomers = [...values.childCustomers];

    updatedChildCustomers.forEach(item => {
      item.users.splice(userIdx, 1);
    });

    const updatedUsers = [...values.users];
    updatedUsers.splice(userIdx, 1);

    setValues({
      ...values,
      users: updatedUsers,
      childCustomers: updatedChildCustomers,
    });
    closeModalHandler();
  };

  return (
    <NewModal
      headerText={`Remove User #${userIdx + 1} from configuration?`}
      closeModal={closeModalHandler}
      style={{ content: { minWidth: 592, width: 592 } }}
    >
      <Gap
        m='32px 0 0 0'
        alignItems='center'
        justifyContent='flex-end'
        gap='12px'
      >
        <Button
          variant='secondary'
          onClick={() => {
            closeModalHandler();
          }}
          disabled={isLoading}
          text='No, Keep'
        />
        <Button
          variant='destructive'
          text={isLoading ? 'Deleting...' : 'Yes, Remove'}
          onClick={removeUsers}
          disabled={isLoading}
        />
      </Gap>
    </NewModal>
  );
};
