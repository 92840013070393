import styled from 'styled-components/macro';

export const ItemsWrapper = styled.div`
  flex: 1 1 0%;
  flex-direction: column;
  flex-wrap: initial;
  width: 100%;
  height: auto;
  gap: 16px;
  display: flex;
`;
