import React, { Fragment } from 'react';
import { GetRailProps } from 'react-compound-slider';
import styled from 'styled-components/macro';
const RailOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 48px;
  cursor: pointer;
`;
const RailInner = styled.div`
  position: absolute;
  width: 100%;
  height: 48px;
  pointer-events: none;
  background-color: rgba(0, 27, 83, 0.15);
  border-radius: 6px;
`;
interface SliderRailProps {
  getRailProps: GetRailProps;
}
export const SliderRail: React.FC<SliderRailProps> = ({ getRailProps }) => (
  <Fragment>
    <RailOuter {...getRailProps()} />
    <RailInner />
  </Fragment>
);
