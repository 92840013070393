import React, { useEffect } from 'react';
import { SearchAndFilter } from 'app/pages/admin/resellers/components/SearchAndFilter';
import { Filter } from 'app/pages/admin/users/components/SearchAndFilter';
import { Gap } from 'lib/components/styles/layout';
import { Dropdown, LoadingIndicator } from 'lib/components';
import {
  IUsersCustomersResponse,
  useGetUsersCustomerQuery,
} from 'lib/api/users/getUsersCustomers';
import { useGetAccountChanges } from 'lib/api/accountChanges/queryAccountChanges';
import { AccountChangesParams, TargetUserOption } from './types';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { NoList } from 'app/pages/admin/index.styled';
import { debounce } from 'lodash';
import { AccountChangesTable } from './AccountChangesTable';

interface AccountChangesProps {
  targetCustomerId?: number;
  targetUserId?: number;
}

export const AccountChanges = ({
  targetCustomerId,
  targetUserId,
}: AccountChangesProps) => {
  const [selectedTargetUser, setSelectedTargetUser] = React.useState<
    string | undefined
  >(undefined);

  const [accountChangesSearch, setAccountChangesSearch] = React.useState('');
  const [accountChangesPage, setAccountChangesPage] =
    React.useState(DEFAULT_PAGE);
  const [accountChangesSize, setAccountChangesSize] =
    React.useState(DEFAULT_SIZE);
  const [accountChangesSortKey, setAccountChangesKey] =
    React.useState('createdAt');
  const [accountChangesSortOrder, setAccountChangesSortOrder] =
    React.useState<PaginationConstants>(PaginationConstants.DESCENDING);

  const { data: usersCustomer, isLoading: loadingUsers } =
    useGetUsersCustomerQuery({
      customerId: targetCustomerId?.toString() ?? '',
      sort: PaginationConstants.ASCENDING,
    });

  const parseSelectedTargetUser = selectedTargetUser
    ? parseInt(selectedTargetUser ?? '')
    : undefined;

  const params: AccountChangesParams = {
    sort: accountChangesSortKey,
    order: accountChangesSortOrder || PaginationConstants.DESCENDING,
    start: accountChangesPage * accountChangesSize || DEFAULT_PAGE,
    limit: accountChangesSize || DEFAULT_SIZE,
    search: accountChangesSearch || '',
    targetCustomerId,
    targetUserId: targetUserId ? targetUserId : parseSelectedTargetUser,
  };

  const {
    data,
    isLoading: isLoadingAccountChanges,
    isFetching: isFetchingAccountChanges,
    refetch,
  } = useGetAccountChanges(params);

  const loadingAccountChanges =
    isLoadingAccountChanges || isFetchingAccountChanges;

  const customerOptions = [
    { value: '0', label: 'No target user' },
    ...(usersCustomer?.map((item: IUsersCustomersResponse) => ({
      value: item.id.toString(),
      label: `${item.firstName} ${item.lastName}`,
    })) || []),
  ];

  const onSortingUpdate = (
    accountChangesSortKey: string,
    order: PaginationConstants
  ) => {
    setAccountChangesKey(accountChangesSortKey);
    setAccountChangesSortOrder(order);
    setAccountChangesPage(DEFAULT_PAGE);
  };

  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      setAccountChangesPage(newPage);
      setAccountChangesSize(newSize);
    },
    300
  );

  useEffect(() => {
    refetch();
  }, [accountChangesPage, accountChangesSize]);

  if (loadingUsers) {
    return <LoadingIndicator isLoading={loadingUsers} left='57%' />;
  }

  return (
    <>
      <Gap alignItems='top' gap='0px'>
        <Filter width={240} marginRight={0}>
          <SearchAndFilter
            onSearch={setAccountChangesSearch}
            prevSearch={accountChangesSearch}
            placeholder='Search activity...'
          />
        </Filter>
        {!targetUserId && (
          <Filter width={224}>
            <Dropdown
              isClearable
              height={40}
              creatable={false}
              className='dropdown'
              placeholder={'All users'}
              value={customerOptions?.find(
                (user: TargetUserOption) => user.value === selectedTargetUser
              )}
              onChange={(value: TargetUserOption) => {
                setSelectedTargetUser(value ? value.value : undefined);
              }}
              options={customerOptions}
              zIndexProp={1}
            />
          </Filter>
        )}
      </Gap>
      {loadingAccountChanges && (
        <div style={{ width: '100%', height: '300px' }}>
          <LoadingIndicator isLoading={loadingAccountChanges} height='300px' />
        </div>
      )}
      {!loadingAccountChanges && !!data?.accountChanges.length && (
        <AccountChangesTable
          accountChanges={data?.accountChanges}
          count={data.count}
          page={accountChangesPage}
          size={accountChangesSize}
          sortKey={accountChangesSortKey}
          order={accountChangesSortOrder}
          onSortingUpdate={onSortingUpdate}
          onPaginationChange={onPaginationChange}
        />
      )}
      {!loadingAccountChanges && !data?.accountChanges.length && (
        <NoList>No Account Changes to show.</NoList>
      )}
    </>
  );
};
