import * as React from 'react';
import { useAuth } from 'lib/context';
import { SuperAdminRole } from 'lib/const/SuperAdminRole';
import { FlexForm, UserFormProps } from '../../components/AdminFormComponents';
import { Divider } from '../../index.styled';
import { CustomerSection } from './userDetails/CustomerSection';
import { PersonalInfoSection } from './userDetails/PersonalInfoSection';
import { PasswordSection } from './userDetails/PasswordSection';
import { ContactSection } from './userDetails/ContactSection';
import { CovideoSection } from './userDetails/CovideoSection';
import { JobDescriptionSection } from './userDetails/JobDescriptionSection';
import { AddonSection } from './userDetails/AddonSection';
import { DefaultSection } from './userDetails/DefaultSection';
import { getCustomer } from 'lib/api/superAdminApi';
import { AccessRole } from 'lib/const';
import { AutomotiveRole } from 'lib/const/AutomotiveRole';
import { hasEmail2FieldAccess } from 'lib/utils/functions';
import { LoginAsSection } from './userDetails/LoginAsSection';
import { FastPasswordSection } from './userDetails/FastPasswordSection';
import { checkIfNewManageUsersChangesVisible } from 'lib/utils/productFeature';

export const UserDetailsForm = (
  props: UserFormProps & {
    onSave: (reloadUserOnSave?: boolean) => Promise<boolean>;
  }
) => {
  const { isNewUser, dirty, onFormDirty, setFieldValue, values, onSave } =
    props;
  const [isVerifiedDisabled, setIsVerifiedDisabled] = React.useState(false);
  const [isAutomotive, setIsAutomotive] = React.useState(false);
  const [hasEmail2Field, setHasEmail2Field] = React.useState(false);
  const { userData } = useAuth();
  const showCustomerSection =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.NON_COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;
  const showOtherSections =
    userData.superAdminRole !== SuperAdminRole.ADMIN &&
    userData.superAdminRole !== SuperAdminRole.ACCESS;

  // For enabling Save button on value change
  React.useEffect(() => {
    onFormDirty(dirty);
  }, [dirty]);

  const loadCustomer = async () => {
    const customerDetails = await getCustomer(values.customerId);
    if (customerDetails) {
      if (isNewUser) {
        const verified = customerDetails.userCount < customerDetails.maxUsers;
        const stringifiedAutomotiveRole = values.automotiveRole?.toString();
        setIsVerifiedDisabled(!verified);
        setFieldValue(`verified`, verified);
        setFieldValue(
          `automotiveRole`,
          stringifiedAutomotiveRole
            ? stringifiedAutomotiveRole
            : values.access === Number(AccessRole.ADMIN)
              ? AutomotiveRole.SALES_MANAGER
              : AutomotiveRole.SALESPERSON
        );
      }
      setIsAutomotive(!!customerDetails.versionCDSEnabled);
    }

    return customerDetails;
  };

  React.useEffect(() => {
    if (values.customerId > 0) {
      loadCustomer().then(customerDetails => {
        if (customerDetails) {
          setHasEmail2Field(
            hasEmail2FieldAccess(
              customerDetails.resellerId?.toString(),
              customerDetails.customerId?.toString()
            )
          );
        }
      });
    }
  }, [values.customerId]);

  // show fast password only for super admin and when editing existing users
  const showFastPasswordSection =
    !isNewUser &&
    values.id &&
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN &&
    checkIfNewManageUsersChangesVisible(userData);

  // show login as only when editing existing users
  const showLoginAsSection = !isNewUser && values?.id && values?.username;

  return (
    <>
      <FlexForm>
        {showCustomerSection && (
          <>
            <CustomerSection {...props} />
            <Divider />
          </>
        )}
        {showLoginAsSection && (
          <>
            <LoginAsSection
              userId={Number(values.id)}
              username={values.username}
              onSave={onSave}
            />
            <Divider />
          </>
        )}
        <>
          <PersonalInfoSection {...props} hasEmail2Field={hasEmail2Field} />
          <Divider />
        </>

        {showFastPasswordSection ? (
          <>
            <FastPasswordSection userId={values.id as number} />
            <Divider />
          </>
        ) : (
          <>
            <PasswordSection {...props} />
            <Divider />
          </>
        )}
        <>
          <ContactSection {...props} hasEmail2Field={hasEmail2Field} />
          <Divider />
        </>
        {showOtherSections && (
          <>
            <CovideoSection
              {...props}
              isAutomotive={isAutomotive}
              isVerifiedDisabled={isVerifiedDisabled}
            />
            <Divider />
          </>
        )}
        <>
          <JobDescriptionSection {...props} />
          <Divider />
        </>

        {isNewUser && (
          <>
            <DefaultSection {...props} />
            <Divider />
          </>
        )}
        {showOtherSections && (
          <>
            {!isNewUser && (
              <>
                <AddonSection {...props} />
                <Divider />
              </>
            )}
          </>
        )}
      </FlexForm>
    </>
  );
};
