import React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { CheckboxInput } from 'lib/components';
import { Reseller } from 'lib/context';
import { ChangeResellerStatusModal } from './ChangeResellerStatusModal';
import { Button } from 'react-covideo-common';

type ContainerProps = {
  padding?: number;
};

const SelectionWrapper = styled.div<ContainerProps>`
  position: fixed;
  left: 0;
  right: 0;
  top: 65px;
  background: ${theme.palette.white};
  height: 40px;
  z-index: 5;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.padding || 12}px 0 12px 208px;
  box-shadow: 0 4px 12px 0 rgba(29, 30, 36, 0.04);
  border: solid 1px ${theme.palette.gray};
`;
const SelectionContainer = styled.div`
  width: 1048px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`;
const CheckboxSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SelectionCountWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 15px;
  font-size: 14px;
  color: ${theme.palette.black_1_100};
`;
const SelectionCountText = styled.div`
  margin: 0 8px 0 16px;
  font-size: 14px;
`;
type Props = {
  selectedResellersData: Reseller[];
  resellersArray: Reseller[];
  setSelectedResselers(selectedCustomers: Reseller[]): void;
  onSuccessfullUpdate(): void;
};

export const SelectedResellers = ({
  selectedResellersData,
  resellersArray,
  setSelectedResselers,
  onSuccessfullUpdate,
}: Props) => {
  const [showResellerStatusModal, setShowResellerStatusModal] =
    React.useState(false);

  return (
    <SelectionWrapper>
      <SelectionContainer>
        <CheckboxSelectionWrapper>
          <CheckboxInput
            blueCheck={true}
            checkGroupIndicator={
              selectedResellersData.length > 0 &&
              resellersArray.length !== selectedResellersData.length
            }
            checked={resellersArray.length == selectedResellersData.length}
            ignoreGrayForAllSelect={true}
            grayCheck={true}
            onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.stopPropagation();
              const { checked } = event.target as HTMLInputElement;
              setSelectedResselers(checked ? resellersArray : []);
            }}
          />
          <SelectionCountWrap>
            <SelectionCountText>Resellers selected:</SelectionCountText>
            <b>{selectedResellersData.length}</b>
          </SelectionCountWrap>
        </CheckboxSelectionWrapper>
        <Button
          onClick={() => {
            setShowResellerStatusModal(true);
          }}
          text='Change Status'
          variant='secondary'
        />

        {showResellerStatusModal && (
          <ChangeResellerStatusModal
            selectedResellers={selectedResellersData}
            handleModalClose={() => setShowResellerStatusModal(false)}
            onSuccessfullUpdate={onSuccessfullUpdate}
            setSelectedResselers={setSelectedResselers}
          />
        )}
      </SelectionContainer>
    </SelectionWrapper>
  );
};
