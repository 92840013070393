import { getAutomotiveRoleAsString } from 'lib/utils/automotiveRolePermissionChecks';
import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { capitalizeWords } from 'lib/utils/functions';
import { HoverPopup } from 'lib/components';
import { AccessRole } from 'lib/const';

const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px;
`;

const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background: ${theme.palette.gray100};
  border-radius: 6px;
`;

const BadgeText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.white};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const AutomotiveRoleBadge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 10px;
  background: ${({ theme }) => theme.colors.secondary['non_alpha']};
  border-radius: 6px;
`;

const AutomotiveRoleBadgeText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.primaryDarkBlue};
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const BadgeHoverPopupText = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: ${theme.palette.primaryDarkBlue};
  flex: none;
  flex-grow: 0;
  z-index: 1;
`;

type Props = {
  isAutomotive: boolean;
  automotiveRole: number;
  access?: string;
  mlAutoCreated: boolean;
};
export const UserAccessBadge = ({
  isAutomotive,
  automotiveRole,
  access,
  mlAutoCreated,
}: Props) => {
  const getBadge = ({
    className,
    text,
    hoverText,
  }: {
    className: string;
    text: string;
    hoverText: string;
  }) => {
    return (
      <HoverPopup
        width='auto'
        position='above'
        padding='8px 12px'
        showTriangle={true}
        cursor='default'
        hoverable={
          <Badge className={className}>
            <BadgeText>{text}</BadgeText>
          </Badge>
        }
        popup={<BadgeHoverPopupText>{hoverText}</BadgeHoverPopupText>}
      />
    );
  };
  return (
    <BadgeWrapper>
      {isAutomotive && getAutomotiveRoleAsString(automotiveRole) !== '' && (
        <AutomotiveRoleBadge>
          <AutomotiveRoleBadgeText>
            {capitalizeWords(getAutomotiveRoleAsString(automotiveRole))}
          </AutomotiveRoleBadgeText>
        </AutomotiveRoleBadge>
      )}
      {access === AccessRole.ADMIN &&
        getBadge({ className: 'admin', text: 'A', hoverText: 'Admin' })}
      {access === AccessRole.SUPERVISOR &&
        getBadge({
          className: 'supervisor',
          text: 'S',
          hoverText: 'Supervisor',
        })}
      {!!mlAutoCreated &&
        getBadge({
          className: 'supervisor',
          text: 'MLM',
          hoverText: 'Multilocation',
        })}
    </BadgeWrapper>
  );
};
