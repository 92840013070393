import React, { useState } from 'react';
import styled from 'styled-components/macro';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import { theme } from 'lib/style';
import { IoMdEye, IoMdDownload } from 'react-icons/io';
import { preparePrice } from 'lib/utils/invoice';
import { Button } from 'react-covideo-common';

import {
  TableFooter,
  Table,
  LoadingIndicator,
  MainContainer,
} from 'lib/components';
import { PreviewModal } from './PreviewModal';
import { useInvoicesQuery } from 'lib/api/billing/useInvoicesQuery';

const MainContainerWrapper = styled(MainContainer)`
  align-items: start;
  padding: 20px 0px 20px 0px;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 20px;
  & .tab-container {
    max-width: 100%;
  }
  & .tab-navigation {
    margin-top: 32px;
    padding: 0;
    justify-content: flex-start;
    max-width: 100%;
  }
  & .tab {
    margin-right: 32px;

    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  & .tab-content {
    padding: 0;
    width: 100%;
  }
`;

const ListInvoicesLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;

  ${theme.mediaQueryMinWidth.mb} {
    width: 100%;
  }
`;

const TableCell = styled.div<{ width: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
`;

const HoverElement = styled.div<{ padding?: string; top?: string }>`
  padding-left: 0px;
  cursor: pointer;
  .hover-content {
    display: none;
  }
  &.padding--25 {
    padding-left: 25px;
  }
  &.padding--15 {
    padding-right: 15px;
  }
  &:hover {
    .hover-content {
      top: ${props => props.top || '35px'};
      right: 0px;
      border: 1px solid #f7f8fa;
      background: white;
      padding: ${props => props.padding || '10px 10px 10px 10px'};
      display: block;
      position: absolute;
      border-radius: 5px;
      box-shadow: 0px 8px 32px rgba(29, 30, 36, 0.08);
      z-index: 10;
      .sub-content {
        display: flex;
        justify-content: center;
        padding-top: 5px;
      }
    }
  }
  svg {
    transform: scale(1.3);
  }
`;

const allTableFields = [
  {
    value: 'created',
    label: 'Month',
  },
  {
    value: 'amount_due',
    label: 'Amount',
  },
];
const initialTableFields = [allTableFields[0], allTableFields[1]];

export const Invoices = withRouter(() => {
  const [page, setPage] = useState(0);
  const [tableFields] = useState(initialTableFields);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewUrl] = useState('');
  const [paginationParam, setPaginationParam] = useState<any>({});

  const { data, isLoading: loading } = useInvoicesQuery({
    limit: 10,
    ...paginationParam,
  });

  const items = data?.invoices || [];
  const hasMore = data?.hasMore || false;
  const columnWidths = [300];

  const onPaginationChange = (action: any) => {
    if (action === 2) {
      setPaginationParam({
        key: 'startingAfter',
        value: items[items.length - 1].id,
      });
      setPage(page + 1);
    } else if (action === 1) {
      setPaginationParam({ key: 'endingBefore', value: items[0].id });
      setPage(page - 1);
    }
  };

  return (
    <>
      <MainContainerWrapper>
        <Content>
          <ListInvoicesLayout>
            {items.length && !loading ? (
              <>
                <div style={{ overflowX: 'auto', paddingBottom: '20px' }}>
                  <Table
                    compact={true}
                    relative={true}
                    headers={[
                      ...tableFields.map((item, index) => {
                        return (
                          <TableCell
                            width={columnWidths[index]}
                            onClick={() => {}}
                          >
                            {item.label}
                          </TableCell>
                        );
                      }),
                      <TableCell
                        width={columnWidths[tableFields.length]}
                      ></TableCell>,
                    ]}
                    hoverable={false}
                    columnWidths={columnWidths}
                    rows={items.map((invoice: any, index: number) => ({
                      key: index,
                      columns: [
                        ...tableFields.map((item, index) => {
                          return (
                            <TableCell
                              width={columnWidths[index]}
                              onClick={() => {}}
                            >
                              {
                                // @ts-ignore
                                item.value === 'created'
                                  ? dayjs(
                                      new Date(invoice[item.value] * 1000)
                                    ).format('MM/YYYY')
                                  : preparePrice(
                                      invoice[item.value],
                                      'USD',
                                      true
                                    )
                              }
                            </TableCell>
                          );
                        }),
                        <TableCell
                          style={{
                            textAlign: 'right',
                            justifyContent: 'end',
                          }}
                          width={columnWidths[tableFields.length]}
                        >
                          <HoverElement className='hover--div padding--25'>
                            <IoMdEye
                              onClick={() => {
                                window.open(
                                  `https://docs.google.com/viewer?url=${invoice.invoice_pdf}&embedded=true`,
                                  '_blank'
                                );
                              }}
                            />
                            <div className='hover-content'>Preview</div>
                          </HoverElement>
                          <HoverElement
                            className='hover--div padding--25  padding--15'
                            onClick={() => {
                              window.open(invoice.invoice_pdf);
                            }}
                          >
                            <div className='hover-content'>Download</div>
                            <IoMdDownload />
                          </HoverElement>
                        </TableCell>,
                      ],
                      onClick: () => {},
                    }))}
                  />
                </div>
                <TableFooter>
                  <div>
                    <Button
                      variant='secondary'
                      text={'Previous'}
                      disabled={page === 0}
                      onClick={() => {
                        onPaginationChange(1);
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant='secondary'
                      text={'Next'}
                      disabled={!hasMore}
                      onClick={() => {
                        onPaginationChange(2);
                      }}
                    />
                  </div>
                </TableFooter>
              </>
            ) : !items.length && !loading ? (
              <div>No data available</div>
            ) : (
              <LoadingIndicator isLoading={loading} height='500px' />
            )}
          </ListInvoicesLayout>
        </Content>
        {showPreviewModal && (
          <PreviewModal
            previewUrl={previewUrl}
            handleModalClose={() => {
              setShowPreviewModal(false);
            }}
          />
        )}
      </MainContainerWrapper>
    </>
  );
});
