import { Button } from 'react-covideo-common';
import { ParagraphSmall } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import React, { useState } from 'react';
import { MdClose, MdLaunch } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';
import { RemoveUserFromCustomerModal } from '../modals/RemoveUserFromCustomerModal';
import { loginAsForSuperAdmin } from 'lib/api';
import { LoginAsModal } from 'app/pages/reports/components';
import { User } from 'lib/api/multiLocations/multiLocationQuery';
import { errorToast } from 'lib/components/toasts/error';

const HeaderWrapper = styled.div`
  display: flex;
  height: 48px;
  padding: 8px 20px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${theme.palette.neutral10};
  background: ${theme.palette.neutral20};
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CommonHoverStyle = css`
  :hover {
    :after {
      position: absolute;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #272a32;
      left: -140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      top: 2px;
      box-sizing: border-box;
      border-radius: 6px;
      height: 30px;
      padding: 0 8px;
      width: 120px;
    }
    :before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      bottom: 12px;
      left: -24px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      transform: rotate(-90deg);
      border-top: 7px solid white;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  ${CommonHoverStyle};
  :hover {
    :after {
      content: 'Remove user';
    }
  }
`;
type LoginAsContainerProps = {
  disabled: boolean;
};
const LoginAsContainer = styled.div<LoginAsContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 19px;
  svg {
    width: 19px;
    height: 19px;
    :hover {
      opacity: 0.3;
    }
  }

  ${CommonHoverStyle};
  :hover {
    :after {
      content: 'Login As user';
      top: -5px;
    }
    :before {
      bottom: 6px;
    }
  }
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      svg {
        opacity: 0.3;
      }
    `}
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const UsersHeader = ({
  headerName,
  userIdx,
  user,
  isEditMode,
}: {
  headerName: string;
  userIdx: number;
  isEditMode: boolean;
  user: User;
}) => {
  const [modal, setModal] = useState(false);
  const openModalHandler = () => setModal(true);
  const closeModalHandler = () => setModal(false);
  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = useState(false);

  const onLoginAs = async () => {
    try {
      await loginAsForSuperAdmin({
        userId: Number(user.id),
        username: user.username,
      });
      setIsLoginAsModalOpen(true);
    } catch (e) {
      errorToast({
        title: 'There was an error with logging in. Please try again.',
      });
    }
  };

  return (
    <HeaderWrapper>
      <ParagraphSmall color={theme.palette.neutral80}>
        {headerName}
      </ParagraphSmall>
      <Buttons>
        {isEditMode && (
          <LoginAsContainer
            onClick={() => (user.verified ? onLoginAs() : {})}
            disabled={!user.verified}
            title={user.verified ? '' : 'User not active'}
          >
            <MdLaunch />
          </LoginAsContainer>
        )}
        <ButtonWrapper>
          <Button
            onClick={openModalHandler}
            variant='secondary'
            size='small'
            icon={<MdClose color={theme.palette.neutral100} size={18} />}
          />
        </ButtonWrapper>
      </Buttons>
      {modal && (
        <RemoveUserFromCustomerModal
          closeModalHandler={closeModalHandler}
          userIdx={userIdx}
          isEditMode={isEditMode}
        />
      )}
      {isLoginAsModalOpen && (
        <LoginAsModal
          style={{ height: '108vh' }}
          handleModalClose={() => {
            setIsLoginAsModalOpen(false);
            window.location.reload();
          }}
        />
      )}
    </HeaderWrapper>
  );
};
