import * as React from 'react';
import styled from 'styled-components/macro';
import {
  LoadingIndicator,
  Table,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  TableContextProvider,
  Search,
} from 'lib/components';

import { theme } from 'lib/style';
import { NoOptOuts } from './components/NoOptOuts';
import { ContactOptOutListItem } from 'lib/api';
import { MainContainer } from '../index.styled';
import { useQueryOptOuts } from 'lib/api/optouts/queryOptOuts';
import { useState } from 'react';

const MainWrapper = styled.div`
  margin-top: 52px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 24px 32px 32px 32px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    max-width: 1064px;
    margin-top: 96px;
  }
`;

const Layout = styled.div`
  ${theme.fontNormal500};
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const OptOuts = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const queryParams = {
    search,
    size: size,
    page: 0,
  };

  const { data, isLoading } = useQueryOptOuts(queryParams);
  const contacts = data?.optOuts ?? [];
  const count = data?.count;

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  return (
    <MainContainer>
      <MainWrapper>
        <Layout>
          {isLoading && contacts.length == 0 ? (
            <LoadingIndicator
              isLoading={isLoading}
              text='Loading Opt-Outs...'
              height={400}
            />
          ) : !isLoading && contacts.length == 0 && !search ? (
            <>
              <NoOptOuts />
            </>
          ) : (
            <>
              <Row style={{ marginBottom: 32 }}>
                <div style={{ width: 300 }}>
                  <Search
                    placeholder='Search Contacts...'
                    onSearch={(value: string) => setSearch(value)}
                  />
                </div>
              </Row>
              <TableContextProvider
                total={count}
                onChange={onPaginationChange}
                initPage={page}
                initSize={size}
              >
                <Table
                  compact={true}
                  headers={['', 'Email', 'Opt-Out Date', '']}
                  hoverable={false}
                  rows={contacts.map(
                    (contact: ContactOptOutListItem, index: number) => ({
                      key: index,
                      columns: [
                        '',
                        contact.email,
                        new Date(contact.dateOpted).toDateString(),
                        '',
                      ],
                      onClick: () => {},
                    })
                  )}
                  isLoading={isLoading}
                />
                {contacts.length === 0 && (
                  <p
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      margin: '36px 0',
                    }}
                  >
                    No matching results found.{' '}
                  </p>
                )}
                <TableFooter>
                  <TablePaginationNew />
                  <TablePaginationSizeNew />
                </TableFooter>
              </TableContextProvider>
            </>
          )}
        </Layout>
      </MainWrapper>
    </MainContainer>
  );
};
