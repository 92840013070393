import * as React from 'react';
import { DocumentHead } from 'lib/components';

import { LoginContent } from './components';

export const Login = () => {
  return (
    <>
      <DocumentHead title="Login" />
      <LoginContent />
    </>
  );
};
