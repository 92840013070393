import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { DefaultLabel, Modal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: #fff;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  color: ${theme.palette.black_1_100};
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 24px;
  font-weight: 500;
  line-height: 20px;
`;

const ButtonsWrapper = styled.section`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 48px;
`;

interface Props {
  handleModalClose: () => void;
  count: number;
  restoreItemsHandler: (id?: number) => Promise<void>;
}

export const ModalRestoreRecentlyDeleted = ({
  handleModalClose,
  count,
  restoreItemsHandler,
}: Props) => {
  return (
    <Modal>
      <ModalItem>
        <Header>
          Restore {count} items?
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <DefaultLabel style={{ color: '#000' }}>
            Are you sure that you want to restore{' '}
            <b>
              {count} selected {count === 1 ? 'item' : 'items'}?
            </b>
            <br />
          </DefaultLabel>
          <ButtonsWrapper>
            <Button
              text={`No, Keep in Bin`}
              onClick={handleModalClose}
              variant='ghost'
            />
            <Button
              text={`Yes, Restore`}
              onClick={() => restoreItemsHandler()}
            />
          </ButtonsWrapper>
        </Content>
      </ModalItem>
    </Modal>
  );
};
