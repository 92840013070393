import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { videoRequestKeys } from './videoRequestKeys';

const getVideoRequestByVideoId = async (videoId: string | number) => {
  const response = await EXPRESS_API.get(
    `/video-requests/${videoId}/get-by-videoid`
  );
  return response.data;
};

export const useVideoRequestByVideoIdQuery = (
  videoId: string | number,
  enabled: boolean
) => {
  return useQuery(
    videoRequestKeys.single_by_video_id(videoId),
    () => getVideoRequestByVideoId(videoId),
    {
      enabled: !!videoId && enabled,
      refetchOnMount: true,
    }
  );
};
