import { theme } from 'lib/style';
import styled, { css } from 'styled-components/macro';

export const ItemContainer = styled.div<{
  analytics?: boolean;
  gap?: string;
  width?: string;
}>`
  display: flex;
  width: ${({ width }) => width || '50%'};
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: ${({ gap }) => gap || 0};
  ${({ analytics }) =>
    analytics &&
    css`
      flex: 1;
      width: auto;
    `}
`;

//Body
export const ItemsWrapper = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  width: 100%;
  gap: ${({ gap }) => gap || '115px'};
  flex-direction: row;
  figure {
    width: auto;
    min-width: 350px;
  }
`;

//Form Parts
export const FormGroup = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  margin: 0;
  width: 100%;
`;

export const InputWrapper = styled.div<{
  direction?: 'row' | 'column';
  marginTop?: string;
}>`
  display: flex;
  gap: 8px;
  margin-top: ${({ marginTop }) => marginTop || '0px'};
  flex-direction: ${({ direction }) => direction || 'column'};
  ${({ direction }) =>
    direction === 'row' &&
    css`
      align-items: center;
      justify-content: space-between;
    `}
`;

export const InputLabel = styled.label`
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: ${theme.palette.themeDark75Percent};
  font-weight: 500;
`;

export const InputPlaceholder = styled.div<{
  minWidth?: string;
}>`
  min-width: ${({ minWidth }) => minWidth || '140px'};
  border-radius: 4px;
  background-color: ${theme.palette.covideoGray20};
  padding: 8px 12px;
`;

export const InputPlaceholderText = styled.span`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.palette.gray60};
`;
