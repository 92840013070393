import React from 'react';
import { MdDone } from 'react-icons/md';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';

import { ManageSeats } from '../components/ManageSeats';
import { VideoPlayer } from '../../../../video/videoPlayer';
import {
  Feature,
  FeatureIcon,
  FeatureList,
  FeatureText,
  OptionContainer,
  Options,
  PageDescription,
} from '../components/PageElements';

const SeatsContainer = styled(OptionContainer)`
  height: 320px;
`;

const VideoWrapper = styled.div`
  margin-top: 20px;
`;

type Props = {
  Globals: {
    currentTotalUsers: number;
    currentCostPerUser: number;
    active: boolean;
    users: any;
  };
  Update: (
    currentTotalUsers: number,
    currentCostPerUser: number,
    active: boolean,
    users: any
  ) => void;
};

export const ManageFilesInactive = ({ Globals, Update }: Props) => {
  const files_features = [
    'Upload and host practically any file type like documents, images, audio, and video files',
    'Use your file’s short link for easy sharing and collaborating',
    'Turn your documents into calls-to-action for your videos, without having to host them on external sites',
    'Store and organize your screenshots in one secure place',
  ];

  const boards_features = [
    'Use boards to group your assets by project, client, or topic and share them in bulk with a single link',
    'Need to update or change docs? No worries - just upload the new file to your board and anyone you’ve shared the link with will be able to access the updated materials in real-time.',
    'Store and organize your videos, screenshots, and files in one secure place',
    'Easily collect large files and documents from people outside of your company or organization by sharing a password-protected link to your board',
  ];

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { userData } = useAuth();

  React.useEffect(() => {
    if (userData.user?.packageDetails?.id) {
      const isAllowed = checkIfFeatureIsEnabled(
        userData,
        productFeature.FILES_AND_BOARDS
      );
      if (!isAllowed) {
        window.location.href = '/upgrade-plan';
      }
    }
  }, []);

  return (
    <>
      <PageDescription>
        Use Covideo to snap, store and share all of your digital assets with
        screenshots, files, and boards.
      </PageDescription>
      <VideoWrapper>
        <VideoPlayer
          playerBackgroundColor={theme.palette.primaryDarkBlue}
          videoSource='https://videos.covideo.com/videos/1675_3_twqmx2hp3o1631720807.mp4'
          playButtonPosition={'none'}
          playerIconsColor={'#fff'}
          videoRef={videoRef}
        />
      </VideoWrapper>
      <Options>
        <SeatsContainer>
          <ManageSeats
            Type='files'
            SaveButtonText='Activate'
            Globals={Globals}
            Update={Update}
          />
        </SeatsContainer>
      </Options>
      <FeatureList>
        <b>Files:</b>
        {files_features.map((feature, index) => (
          <Feature key={index}>
            <FeatureIcon>
              <MdDone fontSize='24px' />
            </FeatureIcon>
            <FeatureText>
              <label>{feature}</label>
            </FeatureText>
          </Feature>
        ))}
      </FeatureList>
      <FeatureList>
        <b>Boards:</b>
        {boards_features.map((feature, index) => (
          <Feature key={index}>
            <FeatureIcon>
              <MdDone fontSize='24px' />
            </FeatureIcon>
            <FeatureText>
              <label>{feature}</label>
            </FeatureText>
          </Feature>
        ))}
      </FeatureList>
    </>
  );
};
