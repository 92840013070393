import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { InfoQuestionMark, RadioInput } from 'lib/components'; //IoIosAdd
import { useLandingPageBuilderContext } from '../../context';

interface RowProps {
  width?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

interface RadioButtonsWrapperProps {
  flexDirection?: string;
  alignItems?: string;
}

const Row = styled.div<RowProps>`
  display: ${props => (props.display ? props.display : 'flex')};
  width: ${props => (props.width ? props.width : '100%')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
  padding: ${props => (props.padding ? props.padding : '10px 10px 0px 10px')};
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
`;

const TopLabel = styled.label`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #9297a2;
  font-weight: 500;
  width: 100%;
  // margin: 25px auto 0 auto;
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  background: rgba(182, 224, 236, 0.2);
  border-radius: 5px;
  padding: 10px;
  line-height: 24px;
  height: 48px;
  align-items: center;
  justify-content: center;
`;

const RadioButtonsWrapper = styled.div<RadioButtonsWrapperProps>`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: ${props => props.alignItems || 'center'};
  width: 100%;
  height: auto;
  box-sizing: border-box;
`;

export const EditSocialLinksSection = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    setElementToEdit,
    elementSectionKey,
    elementToEdit,
    elementIndex,
  } = useLandingPageBuilderContext();
  const themes = useTheme();

  const data = { ...selectedLayoutData };
  if (!elementSectionKey || !data) {
    return <></>;
  }

  const sectionDetails = elementToEdit;
  let isElementCase = false;
  if (
    elementIndex !== null &&
    elementIndex !== '' &&
    elementIndex !== undefined
  ) {
    isElementCase = true;
  }
  let isVisible = true;
  if (isElementCase) {
    isVisible = data[elementSectionKey].childSections[elementIndex]?.isVisible;
  } else {
    isVisible = data[elementSectionKey]?.isVisible;
  }
  let buttonShape = sectionDetails.elementShape || 'rounded';

  if (!sectionDetails) {
    return <></>;
  }

  if (!isVisible) {
    return <></>;
  }

  const updateContext = () => {
    if (isElementCase == true) {
      data[elementSectionKey].childSections[elementIndex] = sectionDetails;
    } else {
      data[elementSectionKey] = sectionDetails;
    }
    setElementToEdit(sectionDetails);
    setSelectedLayoutData(data);
  };

  const handleUpdateShape = (shape: string) => {
    sectionDetails.elementShape = shape;
    buttonShape = shape;
    updateContext();
  };
  return (
    <>
      <Row>
        <InfoWrapper>
          <InfoQuestionMark
            icon={'i'}
            infoText={'Edit your CTA sets here.'}
            iconHeight={'17px'}
            iconWidth={'17px'}
            iconBackgroundColor={'rgba(0, 27, 83, 0.8);'}
          />
          &nbsp;&nbsp;Edit&nbsp;
          <a href='/design/calls-to-action#socialLinks' target='_blank'>
            {' '}
            Social links here.
          </a>
        </InfoWrapper>
      </Row>
      <Row>
        <TopLabel>Shape style</TopLabel>
        <RadioButtonsWrapper flexDirection={'column'} alignItems='flex-start'>
          <RadioInput
            name={'buttonShape'}
            value={'sharp'}
            text={'Sharp'}
            checkedColor={themes.colors.primary[100]}
            checked={buttonShape == 'sharp'}
            onChange={() => {
              handleUpdateShape('sharp');
            }}
            onClick={() => {
              handleUpdateShape('sharp');
            }}
          />
          <RadioInput
            name={'buttonShape'}
            value={'rounded'}
            text={'Rounded'}
            checkedColor={themes.colors.primary[100]}
            checked={buttonShape == 'rounded'}
            onChange={() => {
              handleUpdateShape('rounded');
            }}
            onClick={() => {
              handleUpdateShape('rounded');
            }}
          />
          <RadioInput
            name={'buttonShape'}
            value={'circular'}
            text={'Circular'}
            checkedColor={themes.colors.primary[100]}
            checked={buttonShape == 'circular'}
            onChange={() => {
              handleUpdateShape('circular');
            }}
            onClick={() => {
              handleUpdateShape('circular');
            }}
          />
        </RadioButtonsWrapper>
      </Row>
    </>
  );
};
