import { useQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export type ResellerUsersParams = {
  resellerId: string;
  customerId: string;
};

export interface ResellerUsersResponse {
  resellerId: number;
  customerId: number;
  business: string;
  users: IResselerUser[];
  departments: Department[];
}

export interface Department {
  id: number;
  name: string;
  customerId: number;
  resellerId: number;
}

export interface IResselerUser {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  access: number;
  departmentId: number;
  customerId: number;
  resellerId: number;
}

const getAllResellerUsers = async (
  params: ResellerUsersParams
): Promise<ResellerUsersResponse> => {
  const { resellerId, customerId } = params;
  return (
    await EXPRESS_API.get(
      `/superadmin/reseller/${resellerId}/customers/${customerId}/users`
    )
  ).data;
};

export const useUsersResellersQuery = ({
  params,
  enabled,
}: {
  params: ResellerUsersParams;
  enabled: boolean;
}) => {
  return useQuery(
    superAdminKeys.reseller_users(`${params?.resellerId}` || ''),
    () => getAllResellerUsers(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
    }
  );
};
