import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { FcCheckmark } from 'react-icons/fc';

type PageContainerProps = {
  hasTrialPopup?: boolean;
};
export const PageContainer = styled.div<PageContainerProps>`
  align-items: left;
  margin-top: ${props => (props.hasTrialPopup ? '94px' : '64px')};
  margin-bottom: 60px;
  padding: 32px 300px;
  box-sizing: border-box;
`;

export const PageHeader = styled.div<{ gap?: string }>`
  height: 40px;
  font-size: 24px;
  ${theme.fontBold700}
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  ${props => (!!props.gap ? `gap: ${props.gap}` : '')};
`;

export const AddonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-flow: wrap;
  align-items: left;
  box-sizing: border-box;
  gap: 15px;
  .addonForWhitelabel {
    height: min-content;
  }
`;

export const Options = styled.div<{ flexFlow?: string }>`
  padding: 75px 32px 20px 32px;
  place-content: center;
  display: flex;
  flex-flow: ${props => (props.flexFlow ? props.flexFlow : 'wrap')};
  button: {
    margin: 20px 12px 0px 12px;
  }
`;

export const OptionContainer = styled.div`
  width: 315px;
  max-width: 315px;
  height: 184px;

  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-flow: column;

  background: #ffffff;
  border: 1px solid #f7f8fa;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  place-content: center;
  align-items: center;
  line-height: 40px;
  margin: 10px;
`;

export const PopularOptionContainer = styled.div<any>`
  width: 315px;
  max-width: 315px;
  height: 184px;

  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-flow: column;

  background: #ffffff;
  border: 1px solid ${props => props.color};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  place-content: center;
  align-items: center;
  line-height: 40px;
  margin: 10px;
`;

export const PopularHeader = styled.div<any>`
  position: relative;
  top: -20px;
  background-color: ${props => props.color};
  border-radius: 3px;
  height: 24px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;

  padding: 0 8px;
`;

export const ActiveOptionContainer = styled.div`
  width: 315px;
  max-width: 315px;
  height: 184px;

  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-flow: column;

  background: #ffffff;
  border: 1px solid #001b53;
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  place-content: center;
  align-items: center;
  line-height: 40px;
  margin: 10px;
`;

export const ActiveHeader = styled.div`
  position: relative;
  top: -20px;
  background-color: #001b53;
  border-radius: 3px;
  height: 24px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;

  padding: 0 8px;
`;

export const Addon = styled.div`
  width: 315px;
  height: 464px;
  filter: drop-shadow(0px 4px 12px rgba(29, 30, 36, 0.04));
  background: #ffffff;
  border: 1px solid #f7f8fa;
  border-radius: 4px;
`;

export const AddonHeader = styled.div`
  width: 100%;
  height: 204px;
  .fullscreen {
    height: auto !important;
  }
`;

export const AddonContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 230px;
`;

export const AddonTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #272a32;
`;

export const AddonAmountList = styled.select`
  border-color: #d0d3d9;
  color: #272a32;
  border-radius: 4px;
  height: 40px;
`;

export const AddonPricing = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #9297a2;
  margin-top: 4px;
`;

export const AddonDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #272a32;
  margin-top: 16px;
`;

export const PageDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 0 32px;
  color: #272a32;
  display: flex;
`;

export const AddonActions = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin: 12px;
  }
`;

export const FeatureList = styled.div`
  padding: 50px 250px 0 250px;
  line-height: 32px;
  font-size: 18px;
`;

export const Feature = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const FeatureIcon = styled.div`
  font-size: 24px;
  position: relative;
  top: 2px;
`;

export const FeatureText = styled.div`
  margin-left: 20px;
`;

export const AddonFooter = styled.div`
  position: relative;
  width: 100%;
  margin-top: 60px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: #9297a2;
`;

export const HorizontalRule = styled.hr`
  width: 232px;
  background-color: #eeeff2;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 1px;
  border: none;
`;

export const AddonActive = styled.label`
  display: flex;
  font-weight: 600;
  font-size: 15px;
  color: '#272A32';
  height: 40px;
  width: 100px;
  background-color: 'transparent';
  justify-content: center;
  align-items: center;
`;

export const AddonCheckmark = styled(FcCheckmark)`
  font-size: 20px;
  padding-right: 12px;
`;
