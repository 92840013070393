import { useQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';
import { SuperAdminUserResponse, UserSearchParams } from './types';
import { EXPRESS_API } from 'configs/api/expressApi';

const getUserSearchForSuperAdmin = async ({
  limit = 20,
  start = 0,
  search = '',
}: UserSearchParams) => {
  return (
    await EXPRESS_API.get(`superadmin/users-search`, {
      params: {
        limit,
        start,
        search,
      },
    })
  ).data as SuperAdminUserResponse;
};

export const useGetUserSearchForSuperAdmin = ({
  params,
  enabled,
}: {
  params: UserSearchParams;
  enabled: boolean;
}) => {
  return useQuery(
    superAdminKeys.user_search(params),
    () => getUserSearchForSuperAdmin(params),
    {
      enabled,
    }
  );
};
