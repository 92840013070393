import React from 'react';
import styled from 'styled-components/macro';
import { ListCardManager } from '../../components';

const Container = styled.div`
  margin: 0px 0px 0px 150px;
  padding: 30px 20px 80px 120px;
  min-height: 700px;
`;

export const List = () => {
  return (
    <Container>
      <ListCardManager />
    </Container>
  );
};
