import { EXPRESS_API } from 'configs/api/expressApi';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { videoDeletesKeys } from './queryKeys';

export const deleteVideoDelete = async (videoDeleteId: string) => {
  await EXPRESS_API.delete(`/video-deletes/${videoDeleteId}`);
  return { videoDeleteId };
};

export const useRemoveVideoDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { showError } = useToastError();
  return useMutation(deleteVideoDelete, {
    onError: err => showError(err),
    onSuccess: async () => {
      successToast({
        title: 'You have successfully deactivated video deletion!',
      });
      queryClient.invalidateQueries(videoDeletesKeys.get_video_delete());
    },
  });
};
