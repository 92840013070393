import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import { theme } from 'lib/style';
import Select from 'react-select';
import { SmsOptions } from 'lib/const';
import { MessageConatiner } from './MessageContainer';
import { MainContainer } from 'lib/components';
import { calculateRange } from 'lib/utils/functions';
import { USER_DATE_FORMAT } from 'lib/const/DateFormat';
import { Button } from 'react-covideo-common';

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${theme.mediaQueryMinWidth.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Filter = styled.div`
  float: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-weight: 700;
  }
  ${theme.mediaQueryMinWidth.sm} {
    width: 480px;
  }
`;

const MainWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${theme.mediaQueryMinWidth.mb} {
    padding: 24px 32px 32px 32px;
  }
  ${theme.mediaQueryMinWidth.lg} {
    max-width: 1064px;
  }
  .tab-container {
    max-width: 100%;
  }
  .tab-content {
    width: 100%;
  }
  .tab-navigation {
    padding: 0;
    margin: 0 32px 0 32px;
    justify-content: left;
    .tab {
      & > div {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.33;
      }
      justify-content: center;
      align-items: center;
      margin-left: 0;
      margin-right: 32px;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  ${theme.mediaQueryMaxWidth.md} {
    flex-direction: column;
  }
`;

const DateLabel = styled.span`
  color: #9297a2;
`;

export const MessageLayout = () => {
  const history = useHistory();
  const [range, setRange] = React.useState(
    localStorage.getItem('SMS_FILTER') || SmsOptions[1].value
  );
  const [rangeChange, setRangeChange] = React.useState<any>({ action: null });
  const [datelabel, setDateLael] = React.useState('');

  useEffect(() => {
    const date = calculateRange(range, {
      formatFrom: 'MMM DD',
      formatTo: USER_DATE_FORMAT,
    });
    setDateLael(`${date.formatFrom} - ${date.formatTo}`);
  }, [range]);

  return (
    <>
      <MainContainer>
        {/* <TabsContainer> */}
        <MainWrapper>
          {/* {showLoading && (
            <LoadingIndicator isLoading={showLoading} height="500px" />
          )} */}
          <FiltersContainer>
            <Filter>
              <h2>Messages</h2>
              <Select
                styles={{
                  control: (base: any) => ({
                    ...base,
                    height: '40px',
                    width: '160px',
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                }}
                options={SmsOptions}
                menuPortalTarget={document.body}
                menuPlacement={'bottom'}
                value={SmsOptions.find(o => {
                  return o.value === range;
                })}
                isSearchable={false}
                onChange={(option: any) => {
                  setRange(option.value);
                  rangeChange.action(option.value);
                  localStorage.setItem('SMS_FILTER', option.value);
                }}
              />
              <DateLabel>{datelabel}</DateLabel>
            </Filter>
            <Button
              text='+ New Conversation'
              onClick={() =>
                history.push(`/sms/chats?modalType=add&range=${range}`)
              }
            />
          </FiltersContainer>
          <Content>
            <MessageConatiner setRangeChange={setRangeChange} />
          </Content>
        </MainWrapper>
      </MainContainer>
    </>
  );
};
