import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { googleFontsKeys } from './queryKeys';
import axios from 'axios';

export interface IFonts {
  kind: string;
  items: Item[];
}

interface Item {
  family: string;
  variants: string[];
  subsets: string[];
  version: string;
  lastModified: string;
  files: { [key: string]: string };
  category: Category;
  kind: Kind;
  menu: string;
}

enum Category {
  Display = 'display',
  Handwriting = 'handwriting',
  Monospace = 'monospace',
  SansSerif = 'sans-serif',
  Serif = 'serif',
}

enum Kind {
  WebfontsWebfont = 'webfonts#webfont',
}

const LP_GOOGLE_FONTS = [
  // Sans Serif fonts
  'Work Sans',
  'Open Sans',
  'Oswald',
  'Roboto',
  'Rubik',
  'Poppins',
  'Cabin',
  'Montserrat',
  'Chivo',
  'Lato',
  'Inter',
  'Noto Sans',
  'Raleway',
  'Pt Sans',
  'Jost',

  // Serif fonts
  'Roboto Slab',
  'Lora',
  'Noto Serif',
  'Bitter',
  'IBM Plex Serif',
  'Playfair Display',
  'Quattrocento',
  'Libre Baskerville',
  'Lustria',
  'Cormorant Garamond',
  'Josefin Slab',
  'Zilla Slab',
  'Unna',
  'Crimson Text',
  'Cardo',
];

const filterFonts = (fonts: IFonts, fontNames: string[]) => {
  const items = fonts.items.filter((font: any) =>
    fontNames.includes(font.family)
  );
  return { kind: fonts.kind, items };
};

const getGoogleFonts = async () => {
  const params = {
    key: process.env.REACT_APP_GOOGLE_FONTS_API_KEY,
  };
  const response = await axios.get(
    'https://www.googleapis.com/webfonts/v1/webfonts',
    {
      params,
    }
  );
  return response.data;
};

export const useGetGoogleFontsQuery = () => {
  const { showError } = useToastError();
  return useQuery(googleFontsKeys.all(), () => getGoogleFonts(), {
    onError: showError,
    select: data => filterFonts(data, LP_GOOGLE_FONTS),
  });
};
