import * as React from 'react';
import styled from 'styled-components/macro';

type WrapperProps = {
  hide?: boolean;
  width?: string;
  height?: string;
};

const Wrapper = styled.div<WrapperProps>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: ${props => (props.width ? props.width : '710px')};
  height: ${props => (props.height ? props.height : '400px')};
  z-index: 100;
`;

type CounterProps = {
  willStart?: boolean;
  counter: number;
  isFreemium?: boolean;
};

const colorMap: { [key: number]: string } = {
  3: 'rgba(0, 27, 83, 0.8)',
  2: 'rgba(0, 27, 83, 0.4)',
  1: 'rgba(0, 27, 83, 0.2)',
};

const Counter = styled.div<CounterProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 136px;
  font-weight: 900;
  background-color: ${props => colorMap[props.counter]};
  width: 100%;
  height: 100%;
  opacity: ${props => (props.willStart ? 0.5 : 0)};
  transition: opacity 0.2s ease-in;
  border-radius: ${props => (props.isFreemium ? '24px' : '8px')};
  &:hover {
    cursor: pointer;
  }
`;

export const CountDown = ({
  counter,
  willStart,
  onClick,
  hide,
  height,
  width,
  isFreemium,
}: any) => {
  return (
    <Wrapper height={height} width={width} hide={hide}>
      <Counter
        counter={counter}
        willStart={willStart}
        onClick={onClick}
        isFreemium={isFreemium}
      >
        {counter}
      </Counter>
    </Wrapper>
  );
};
