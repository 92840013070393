import React, { useEffect, useRef, useState } from 'react';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { LoadingIndicator, ModalDelete } from 'lib/components';
import { VideoPlayer } from '../../../videoPlayer';
import { ModalVideoAnnotations } from './components/ModalVideoAnnotations';
import { AnnotationItem } from 'lib/context';
import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { ModalAutomotiveVideoAnnotations } from './components/ModalAutomotiveVideoAnnotations';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { AccessRole } from 'lib/const';
import {
  Heading,
  ParagraphNormal,
  SubHeading,
} from 'lib/components/styles/typography';
import { ItemContainer, ItemsWrapper } from '../../styles/layout';
import { hasAnnotationCRUDPrivileges } from './util/functions';
import { EnhancedGap, Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { Button } from 'react-covideo-common';
import { useGetAnnotationsQuery } from 'lib/api/annotations/getAnnotations';
import { useDeleteAnnotationMutation } from 'lib/api/annotations/deleteAnnotation';
import { useGetAnnotationTemplatesQuery } from 'lib/api/annotations/getTemplate';
import { MdAdd, MdDeleteForever, MdEdit } from 'react-icons/md';
import { IoMdLink } from 'react-icons/io';
import selectors from '../../../../../../cypress/selectors';

const AnnotationListItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 #1d1e240a;
  border: solid 1px #f7f8fa;
  padding: 20px;
  min-height: 100px;
  margin-bottom: 16px;
  cursor: pointer;
  gap: 8px;
  &.active {
    border: solid 1px #ff8b2233;
  }
`;

const AnnotationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const defaultAnnotation = {
  type: 'text',
  startTimeText: '',
  endTimeText: '',
  position: '',
  useClassicEditor: false,
};

const EDITORS = {
  CLASSIC: 'classic',
  SIMPLIFIED: 'simplified',
};

export const VideoAnnotations = ({ videoDetails }: any) => {
  const [showAnnotationsModal, setShowAnnotationsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] =
    useState<AnnotationItem>(defaultAnnotation);

  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [openClassicEditor, setOpenClassicEditor] = useState(false);
  const [activeEditor, setActiveEditor] = useState('');

  const { userData } = useAuth();

  const { data: annotationsData, isLoading: loadingAnnotations } =
    useGetAnnotationsQuery(videoDetails.id);
  const { data: templatesData } = useGetAnnotationTemplatesQuery();
  const { mutateAsync: deleteAnnotation } = useDeleteAnnotationMutation();

  const videoAnnotations = annotationsData?.videoAnnotations || [];
  const companyLevelAnnotations = annotationsData?.companyAnnotations || [];
  const sortedVideoAnnotations = sortBy(videoAnnotations, 'startTime');
  const isCompanyAdmin = userData.access === AccessRole.ADMIN;

  const openCreateAnnotationModal = () => {
    if (userData.isAutomotive) setOpenClassicEditor(false);
    setSelectedAnnotation(defaultAnnotation);
    setShowAnnotationsModal(true);
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const onloadedmetadata = () => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      setVideoDuration(duration);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onloadedmetadata = onloadedmetadata;
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.onloadedmetadata = null;
      }
    };
  }, []);

  useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id) {
        const isPasswordAllowed = checkIfFeatureIsEnabled(
          userData,
          productFeature.ANNOTATIONS
        );
        if (!isPasswordAllowed) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    let editor;
    if (selectedAnnotation) {
      editor = selectedAnnotation.useClassicEditor
        ? EDITORS.CLASSIC
        : EDITORS.SIMPLIFIED;
    } else {
      if (userData.isAutomotive) {
        editor = openClassicEditor ? EDITORS.CLASSIC : EDITORS.SIMPLIFIED;
      } else {
        editor = EDITORS.CLASSIC;
      }
    }
    setActiveEditor(editor);
  }, [userData, openClassicEditor, selectedAnnotation]);

  const renderAnnotationListItem = (annotation: any, text: string) => {
    const isEditable = hasAnnotationCRUDPrivileges(
      userData,
      videoDetails,
      annotation
    );
    return (
      <AnnotationListItem
        key={annotation.annotationId}
        onClick={() => setSelectedAnnotation(annotation)}
        className={
          selectedAnnotation.annotationId === annotation.annotationId
            ? 'active'
            : ''
        }
      >
        <AnnotationHeader>
          <EnhancedGap
            maxWidth='80%'
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='nowrap'
            gap='8px'
            center
          >
            {annotation.isLinkEnabled && (
              <IoMdLink color={theme.palette.black_1_100} size='18px' />
            )}
            <SubHeading ellipsis>{text}</SubHeading>
          </EnhancedGap>
          <Gap>
            {isEditable && (
              <>
                <MdEdit
                  size={18}
                  onClick={() => {
                    setActiveEditor(
                      annotation.useClassicEditor
                        ? EDITORS.CLASSIC
                        : EDITORS.SIMPLIFIED
                    );
                    setSelectedAnnotation(annotation);
                    setShowAnnotationsModal(true);
                  }}
                  color='#4e5461'
                  data-cy={selectors.videoDetailsPage.editAnnotation}
                />
                <MdDeleteForever
                  size={20}
                  onClick={() => {
                    setSelectedAnnotation(annotation);
                    setShowDeleteModal(true);
                  }}
                  color={theme.palette.buttonDelete}
                  data-cy={selectors.videoDetailsPage.deleteAnnotation}
                />
              </>
            )}
          </Gap>
        </AnnotationHeader>
        <ParagraphNormal color='#4e5461'>
          {annotation.startTimeText} - {annotation.endTimeText}
          <span style={{ margin: '0 12px' }}>&#183;</span>
          {annotation.position}
        </ParagraphNormal>
      </AnnotationListItem>
    );
  };

  const canCreateAnnotation = hasAnnotationCRUDPrivileges(
    userData,
    videoDetails
  );

  return (
    <>
      <HelpDesk name={EHelpDesk.ANNOTATIONS} />
      <HeaderWrapper>
        <Heading>{videoDetails.title}</Heading>
        {canCreateAnnotation && (
          <Button
            text='New Annotation'
            icon={<MdAdd size={18} />}
            onClick={() => openCreateAnnotationModal()}
            data-cy={selectors.videoDetailsPage.newAnnotationButton}
          />
        )}
      </HeaderWrapper>
      <ItemsWrapper>
        {!loadingAnnotations ? (
          <>
            <ItemContainer width='45%'>
              {!sortedVideoAnnotations.length &&
                (!isCompanyAdmin ||
                  (isCompanyAdmin && !companyLevelAnnotations.length)) && (
                  <ParagraphNormal m='0 0 32px 0'>
                    This video has no annotations.
                  </ParagraphNormal>
                )}
              {sortedVideoAnnotations.map((annotation: any) =>
                renderAnnotationListItem(
                  annotation,
                  annotation.type === 'text'
                    ? annotation.text
                    : annotation.imageName
                )
              )}
              {!!(isCompanyAdmin && companyLevelAnnotations.length) && (
                <>
                  <ParagraphNormal m='0 0 20px 0'>
                    Company level annotations
                  </ParagraphNormal>
                  {companyLevelAnnotations.map((annotation: any) =>
                    renderAnnotationListItem(annotation, annotation.imageName)
                  )}
                </>
              )}
              {showAnnotationsModal &&
                (activeEditor === EDITORS.CLASSIC ? (
                  <ModalVideoAnnotations
                    videoDetails={videoDetails}
                    videoDuration={videoDuration}
                    selectedAnnotation={selectedAnnotation}
                    templates={templatesData || []}
                    handleModalClose={() => setShowAnnotationsModal(false)}
                    setOpenClassicEditor={setOpenClassicEditor}
                    setSelectedAnnotation={setSelectedAnnotation}
                  />
                ) : (
                  <ModalAutomotiveVideoAnnotations
                    videoDetails={videoDetails}
                    videoDuration={videoDuration}
                    selectedAnnotation={{
                      ...selectedAnnotation,
                      padding: '8px',
                    }}
                    handleModalClose={() => setShowAnnotationsModal(false)}
                    setOpenClassicEditor={setOpenClassicEditor}
                    setSelectedAnnotation={setSelectedAnnotation}
                  />
                ))}
              {showDeleteModal && (
                <ModalDelete
                  whiteButtonText='Yes, Delete'
                  orangeButtonText='No, Cancel'
                  title='Delete annotation?'
                  text={`This action can’t be undone.`}
                  handleModalClose={() => setShowDeleteModal(false)}
                  onClickWhiteButton={async () => {
                    const isCompanyAnnotation = !!companyLevelAnnotations.find(
                      (annotation: AnnotationItem) =>
                        annotation.annotationId ===
                        selectedAnnotation.annotationId
                    );
                    await deleteAnnotation({
                      videoId: videoDetails.id,
                      annotationId: selectedAnnotation.annotationId || '',
                      isCompanyAnnotation: isCompanyAnnotation
                        ? 'true'
                        : undefined,
                    });
                    setShowDeleteModal(false);
                    setSelectedAnnotation(defaultAnnotation);
                  }}
                  onClickOrangeButton={() => setShowDeleteModal(false)}
                />
              )}
            </ItemContainer>
            <ItemContainer>
              <VideoPlayer
                playerBackgroundColor={videoDetails.playerBackgroundColor}
                videoId={videoDetails.id}
                videoSource={videoDetails.videoSource}
                playButtonPosition={videoDetails.playButtonPosition}
                playerIconsColor={videoDetails.playerIconsAndTextColor}
                thumbnail={videoDetails.personalThumbnail}
                videoRef={videoRef}
                selectedAnnotationId={selectedAnnotation.annotationId}
                height='280px'
              />
            </ItemContainer>
          </>
        ) : (
          <LoadingIndicator isLoading={loadingAnnotations} />
        )}
      </ItemsWrapper>
    </>
  );
};
