import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { MdDeleteForever } from 'react-icons/md';
import Select from 'react-virtualized-select';
import { deleteCustomLandingPage } from 'lib/api/designApi';
import { useQueryClient } from 'react-query';
import { TEMPLATE_QUERY } from 'lib/const/SendAndShare';
import { useGetTemplatesQuery } from 'lib/api/templates/getTemplatesQuery';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';
import { IoMdClose } from 'react-icons/io';

interface Props {
  onClose: (params?: any) => void;
  onDelete?: (params?: any) => void;
  data?: any;
  modalLoading: boolean;
  selectedLandingPage: any;
}

type Option = {
  value: string;
  label: string;
};

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  padding?: string;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const Row = styled.section<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  padding: ${props => (props.padding ? props.padding : '')};
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const Text = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.coal};
  margin: 0;
  padding: 0;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalDeleteLandingPage = (props: Props) => {
  const { onClose, onDelete, modalLoading, selectedLandingPage } = props;
  const [selectedBackupTemplate, setSelectedBackupTemplate] =
    React.useState<Option>({
      value: '',
      label: '',
    });

  const [templateOptions, setTemplateOptions] = React.useState([] as Option[]);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const themes = useTheme();
  const queryClient = useQueryClient();
  const { showError } = useToastError();

  const {
    data: templatesData,
    isLoading: templatesLoading,
    error: templatesError,
  } = useGetTemplatesQuery({ limit: 1000 });

  const deleteLandingPage = async () => {
    setIsDeleting(true);
    try {
      await deleteCustomLandingPage(
        selectedLandingPage.id,
        selectedBackupTemplate.value
      );
    } catch (error) {
      showError(error || 'Something went wrong deleting Landing Page');
    }

    setIsDeleting(false);
    onClose();
    queryClient.invalidateQueries([TEMPLATE_QUERY]);
  };
  React.useEffect(() => {
    setTemplateOptions(
      (templatesData?.templates || []).map(
        ({ id, title }: { id: number; title: string }) => ({
          value: id.toString(),
          label: title,
        })
      )
    );
  }, [templatesData]);

  React.useEffect(() => {
    if (
      selectedBackupTemplate.value !== '' ||
      templatesLoading ||
      templatesError ||
      !templatesData ||
      !templatesData.templates
    )
      return;
    templatesData.templates.length > 0 &&
      setSelectedBackupTemplate({
        value: templatesData.templates[0].id.toString(),
        label: templatesData.templates[0].title,
      });
  }, [selectedBackupTemplate, templatesData, templatesLoading, templatesError]);

  return (
    <Modal widthOnDesktop={'670px'}>
      <Content>
        {modalLoading && (
          <LoaderWrapper>
            <MoonLoader
              size={40}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        <Row justifyContent={'space-between'}>
          <Title>Move Landing page design to recently deleted bin?</Title>
          <CloseIconWrap>
            <IoMdClose
              size={24}
              onClick={onClose}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        {/* <Row>
          <Text>
            Are you sure you want to delete the
            <BoldText> '{data.title}' </BoldText> Section?
          </Text>
        </Row> */}
        <Row>
          <Text>
            Choose template to override for already sent videos that uses this
            template you’re about to delete.
          </Text>
        </Row>
        <br />
        <Row>
          <Select
            className='dropdown'
            value={selectedBackupTemplate}
            clearable={false}
            placeholder={'No Landing Pages'}
            style={{
              fontSize: '16px',
              borderRadius: '4px',
              maxHeight: '40px',
              minWidth: '250px',
            }}
            options={templateOptions}
            onChange={(value: any) => {
              if (value) {
                setSelectedBackupTemplate(value);
              }
            }}
            isLoading={templatesLoading}
          />
        </Row>
        <Row justifyContent={'flex-end'} padding={'60px 0px 10px 0px'}>
          <Button
            variant='destructive'
            icon={<MdDeleteForever />}
            text={'Delete Design'}
            onClick={() => (onDelete ? onDelete() : deleteLandingPage())}
            disabled={isDeleting}
          />
          <Button
            text={'Keep Design'}
            onClick={() => onClose()}
            disabled={isDeleting}
          />
        </Row>
      </Content>
    </Modal>
  );
};
