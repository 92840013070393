import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import { SnippetListing } from '.';
import { MdAdd } from 'react-icons/md';
import { Heading } from 'lib/components/styles/typography';
import { HeaderWrapper } from 'lib/components/styles/layout';
import { Button } from 'react-covideo-common';

const IconButtonWrapper = styled.div`
  > button {
    margin-right: 0;
  }
`;

export const ListManager = () => {
  const history = useHistory();

  const goToSnippet = (snippetId: string) =>
    history.push(`/profile/templates/${snippetId}`);

  const createSnippet = async () => {
    goToSnippet('create');
  };

  const updateSnippet = (id: string) => {
    goToSnippet(id);
  };

  const createBtn = (
    <IconButtonWrapper>
      <Button
        data-cy='create-template-button'
        text='Create Template'
        icon={<MdAdd size={20} />}
        onClick={() => createSnippet()}
      />
    </IconButtonWrapper>
  );

  return (
    <>
      <HeaderWrapper>
        <Heading>Templates</Heading>
        {createBtn}
      </HeaderWrapper>
      <SnippetListing handleUpdateSnippet={updateSnippet} />
    </>
  );
};
