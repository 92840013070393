import { ParagraphNormal } from 'lib/components/styles/typography';
import { theme } from 'lib/style';
import React from 'react';
import styled from 'styled-components/macro';

const HeaderWrapper = styled.div`
  display: flex;
  height: 48px;
  padding: 8px 20px;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${theme.palette.neutral10};
  background: ${theme.palette.blue05};
  box-sizing: border-box;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const MultiItemHeader = ({
  headerName,
  additionalInfo,
}: {
  headerName: string;
  additionalInfo?: string;
}) => {
  return (
    <HeaderWrapper>
      <ParagraphNormal color={theme.palette.gray80}>
        {headerName}
      </ParagraphNormal>
      {additionalInfo && (
        <ParagraphNormal color={theme.palette.gray80}>
          {additionalInfo}
        </ParagraphNormal>
      )}
    </HeaderWrapper>
  );
};
