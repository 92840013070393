import { EXPRESS_API } from 'configs/api/expressApi';

export type SendNotificationParams = {
  entityName: string;
  entityUrl: string;
  senderId: number;
  recipientIds: any;
  senderName: string;
  type: string;
  entityId: string;
  notificationProp?: string[];
  sendEmail?: Function;
};

export const saveNotification = async ({
  entityName,
  entityUrl,
  recipientIds,
  type,
  entityId,
  senderId,
  senderName,
  notificationProp,
  sendEmail,
}: SendNotificationParams) => {
  const data = {
    entityName,
    actionId: entityId,
    actionUrl: entityUrl,
    type,
    meta: {
      senderId,
    },
    senderName,
    recipientIds,
    notificationProp,
    sendEmail,
  };
  const response = await EXPRESS_API.post('/notification/store', data);
  return response.data;
};

export const getNotificationPreference = async () => {
  const response = await EXPRESS_API.get(`/notification/preferences`);
  return response.data;
};

export const saveNotificationPreference = async (id: any, data: any) => {
  const response = await EXPRESS_API.put(
    `/notification/preferences/${id}`,
    data
  );
  return response.data;
};
