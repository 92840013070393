import { GetBoardParams } from './getBoard';
import { GetBoardsParams } from './getBoards';
import { GetDropParams } from './getDrop';
import { GetDropsParams } from './getDrops';

export const droplrKeys = {
  boards_list: (params: GetBoardsParams) => ['BOARDS', params] as const,
  board_details: (params: GetBoardParams) => ['BOARD', params] as const,
  drop_list: (params: GetDropsParams) => ['DROPS', params] as const,
  drop_details: (params: GetDropParams) => ['DROP', params] as const,
  current_user: () => ['CURRENT_DROPLR_USER'] as const,
  activity_list: (dropId: string) => ['DROPLR_ACTIVITY', dropId] as const,
  user_tag_list: () => ['USER_TAGS'] as const,
  edit_drop_mutation: () => ['EDIT_DROP'] as const,
};
