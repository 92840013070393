import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { carServiceKeys } from './carServiceKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { GetCarServicesParams } from './types';

export const getCarServices = async (params: GetCarServicesParams) => {
  const response = await EXPRESS_API.get(`/services`, {
    params,
  });

  return response.data;
};

export const useCarServicesQuery = (params: GetCarServicesParams) => {
  const { showError } = useToastError();
  return useQuery(carServiceKeys.list(params), () => getCarServices(params), {
    onError: showError,
    refetchOnMount: true,
  });
};
