import { EXPRESS_API } from 'configs/api/expressApi';
import { FormikHelpers } from 'formik';
import { useToastError } from 'lib/hooks/useToastError';
import { useMutation, useQueryClient } from 'react-query';
import { quickFilterKeys } from './quickFilterKey';

interface IQuickFilterParas {
  payload: {
    title: string;
    rules: any;
    id: number;
  };
  actions: {
    resetFormikValues: () => void;
    setShowSaveFilters: React.Dispatch<React.SetStateAction<boolean>>;
    formik: FormikHelpers<any>;
  };
}

const updateQuickFilter = async (params: IQuickFilterParas): Promise<any> => {
  const { id, ...body } = params.payload;
  return (await EXPRESS_API.put(`quick-filters/${id}`, { ...body })).data;
};

export const useUpdateQuickQueryMutation = () => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(updateQuickFilter, {
    onSuccess: (_data, variables) => {
      const { resetFormikValues, setShowSaveFilters, formik } =
        variables.actions;
      resetFormikValues();
      setShowSaveFilters(false);
      formik.resetForm();
      queryClient.invalidateQueries(quickFilterKeys.all());
    },
    onError: err => showError(err),
  });
};
