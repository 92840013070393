import React from 'react';
import Select from 'react-virtualized-select';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { fetchCustomerUsers } from 'lib/api';
import { usePrevious } from 'lib/hooks';

type Option = {
  value: string;
  label: string;
};

type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  Dept: string;
  DeptId: number;
};

type Props = {
  onUserSelected: (value: string) => void;
  initialUser?: string;
  customer: string;
  department?: string;
  disabled?: boolean;
  width?: string;
};

function UserOptionRenderer({
  focusOption,
  focusedOption,
  key,
  option,
  selectValue,
  style,
}: any) {
  style = {
    width: '200px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '10px 16px' /* SUS-1136 changes */,
    cursor: 'pointer',
    ...style,
  };

  if (option === focusedOption) {
    style.color = '#007bff';
  }

  return (
    <div
      key={key}
      onClick={() => selectValue(option)}
      onMouseEnter={() => focusOption(option)}
      style={style}
      title={option.label}
    >
      {option.label}
    </div>
  );
}

export const UsersDropdown = (props: Props) => {
  const {
    onUserSelected,
    initialUser,
    customer,
    department,
    disabled = false,
    width,
  } = props;

  const [selectedUser, setSelectedUser] = React.useState(initialUser || 'all');
  const [usersOptions, setUsersOptions] = React.useState([] as Option[]);
  const [users, setUsers] = React.useState([] as User[]);

  const prevCustomer = usePrevious(customer);

  const location = useLocation();
  const history = useHistory();

  const renderOptions = (usersArray: User[], department?: string) => {
    const options = [] as Option[];
    usersArray.forEach((user: User) => {
      options.push({
        value: user.id.toString(),
        label: `${user.lastName}, ${user.firstName}` || user.email,
      });
    });
    options.sort((a, b) => a.label.localeCompare(b.label));
    if (department && department === 'all') {
      options.unshift({
        value: 'all',
        label: 'All Employees',
      });
    }
    options.unshift({
      value: 'all',
      label: 'All Users',
    });
    setUsersOptions(options);
  };

  const fetchUsers = async () => {
    const usersArray = await fetchCustomerUsers(customer);

    setUsers(usersArray);
    renderOptions(usersArray);
  };

  React.useEffect(() => {
    if (customer !== prevCustomer) {
      setSelectedUser('all');
      onUserSelected('all');
    }
    fetchUsers();
  }, [customer]);

  React.useEffect(() => {
    const queryParams: any = queryString.parse(location.search);
    if (queryParams.user && queryParams.user !== initialUser) {
      setSelectedUser(queryParams.user);
      onUserSelected(queryParams.user);
    } else if (initialUser) {
      setSelectedUser(initialUser);
      onUserSelected(initialUser);
    }
  }, [location.search, initialUser]);

  React.useEffect(() => {
    if (department) {
      const usersArray = users.filter(
        user =>
          department === 'all' ||
          (!!user.DeptId && user.DeptId.toString() === department)
      );
      renderOptions(usersArray, department);
    }
  }, [users, department]);

  return (
    <Select
      className='UsersSelect'
      value={selectedUser}
      clearable={false}
      placeholder='Select user'
      style={{
        width: width || 'auto',
        fontSize: '16px',
        borderRadius: '4px',
        maxHeight: '40px',
      }}
      options={usersOptions}
      optionRenderer={UserOptionRenderer}
      disabled={disabled}
      onChange={(option: any) => {
        if (option && option.value) {
          const user = option.value;
          setSelectedUser(user);
          onUserSelected(user);
          const queryParams: any = queryString.parse(location.search);
          queryParams.user = user;
          history.push({
            pathname: location.pathname,
            search: queryString.stringify(queryParams),
          });
        }
      }}
    />
  );
};
