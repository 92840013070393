import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import {
  LandingPageLayoutContainer,
  PageEditorSection,
  PageBuilderActionMenu,
  defaultStyleProperties,
  getLayoutForLayoutType,
  playButtonPositionMapping,
} from './components';
import { createLandingPage, getCTASets } from 'lib/api/designApi';
import { useLandingPageBuilderContext } from 'app/pages/design/landingPageBuilder/context';
import { ModalLandingPageBuilderAddSection } from './components/modal/ModalLandingPageBuilderAddSection';
import { MoonLoader } from 'react-spinners';
import { ModalSaveDesign } from './components/modal/ModalSaveDesign';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth } from 'lib/context';
import { GetLPBStats } from 'lib/api/addonsApi';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { useQueryClient } from 'react-query';
import { TEMPLATE_QUERY } from 'lib/const/SendAndShare';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { getSocialMediaLinks } from 'lib/api/socialMediaLinks/useSocialLinksQuery';
import { useToastError } from 'lib/hooks';
import { CTA } from 'lib/api/types';
import { CTA_TYPES } from 'lib/const/CallsToAction';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';

interface ContainerProps {
  padding?: string;
  flex?: string;
  height?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  pageBackgroundSize?: string;
  pageBackgroundPosition?: string;
}

type GetSocialLinksResponse = {
  success: boolean;
  message: string;
  errors?: string[];
  data: any;
  lockedByAdmin: boolean;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  overflow-x: hidden;
  padding: ${props => (props.padding ? props.padding : '64px 0 20px 0')};
  flex: ${props => (props.flex ? props.flex : '1 1 100%')};
  flex-wrap: wrap;
  .fullScreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 9999;
  }
  .rdw-colorpicker-modal {
    max-height: max-content !important;
    top: 100%;
    left: -550%;
  }
  .word-wrap-fix {
    word-break: break-all !important;
  }
  ${props => (props.height ? 'min-height:' + props.height + ';' : '')};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : ''};
  background-image: ${props =>
    props.backgroundImage ? 'url(' + props.backgroundImage + ')' : ''};

  background-repeat: no-repeat;
  background-size: ${props =>
    props.pageBackgroundSize ? props.pageBackgroundSize : 'cover'};
  background-position: ${props =>
    props.pageBackgroundPosition ? props.pageBackgroundPosition : 'left top'};
`;

const LoaderWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled(Container)`
  padding: 0;
  position: relative;
  z-index: 99;
  padding: 0;
  background: white;
`;

const ExitFullScreenMessage = styled.div`
  position: fixed;
  bottom: 35px;
  text-align: center;
  left: 0;
  right: 0;
`;

const MessageText = styled.span`
  z-index: 20;
  background: rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 20px;
  color: rgba(255, 255, 255, 0.45);
  font-size: 16px;
  max-width: fit-content;
  border-color: rgba(255, 255, 255, 0.45);
  border: 0.5px solid;
`;

export type DevicePreviewType = 'desktop' | 'tablet' | 'mobile';

export const LandingPageBuilder = () => {
  const {
    selectedLayoutData,
    setSelectedLayoutData,
    showAddSectionModal,
    setShowAddSectionModal,
    pageBackgroundColor,
    setDefaultSocialLinks,
    setDefaultCta,
    defaultCta,
    defaultSocialLinks,
    isFullScreen,
    selectedLayout,
    selectedStyle,
    setSelectedStyle,
    setSelectedStyleProperties,
    setIsFullScreen,
    pageBackgroundImageUrl,
    pageBackgroundPosition,
    pageBackgroundSize,
    showSaveDesignModal,
    setShowSaveDesignModal,
    selectedStyleProperties,
    setPageBackgroundColor,
    setIsEnabled,
    setSelectedLayout,
    userProfile,
    pageId,
    pageTitle,
    isDefault,
    isShared,
    builderVersion,
    designId,
    allowDuplicates,
    setPageTitle,
    setPageId,
    setDesignId,
    undoState,
    setUndoState,
    redoState,
    setRedoState,
    setPageBackgroundPosition,
    setPageBackgroundSize,
    setPageBackgroundImageUrl,
    elementIndex,
    elementSectionKey,
    setElementToEdit,
  } = useLandingPageBuilderContext();
  const { logoUrl } = useLogoUrl();

  const exportRef = useRef(null as null | HTMLDivElement);
  const [devicePreview, setDevicePreview] =
    React.useState<DevicePreviewType>('desktop');
  const [showLoader, setShowLoader] = React.useState(true);
  const { userData } = useAuth();
  const [isUndo, setIsUndo] = React.useState(false);
  const [forceRedirect, setForceRedirect] = React.useState(false);
  const history = useHistory();
  const themes = useTheme();
  const queryClient = useQueryClient();
  const { showError } = useToastError();

  const escFunction = React.useCallback(event => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
      history.push('/design/landing-page-builder');
    }
  }, []);

  React.useEffect(() => {
    if (pageId) {
      return;
    }
  }, [userProfile]);

  React.useEffect(() => {
    GetLPBStats()
      .then(data => {
        if (data && data.activated) {
          setIsEnabled(true);
          setForceRedirect(false);
        } else {
          setIsEnabled(false);
          setForceRedirect(true);
        }
      })
      .catch(() => {
        setIsEnabled(false);
        setForceRedirect(true);
      });
  }, []);

  React.useEffect(() => {
    setIsEnabled(true);
    if (!window.location.pathname.includes('landing-page-preview')) {
      setCTAandSocialLinks();
      if (pageId) {
        return;
      }
      const playButtonPosition = userData.playButtonPosition || '0';
      setDefaultPlayButtonPosition(
        playButtonPositionMapping[playButtonPosition]
      );
    }
  }, []);

  React.useEffect(() => {
    if (showLoader) {
      return;
    }

    saveLandingPage();
  }, [
    selectedLayoutData,
    showLoader,
    selectedStyle,
    pageBackgroundColor,
    pageBackgroundImageUrl,
    pageBackgroundPosition,
    pageBackgroundSize,
  ]);

  React.useEffect(() => {
    setIsEnabled(true);
    if (selectedLayoutData == null) {
      const defaultLayoutName = selectedLayout ? selectedLayout : 'standard';
      const defaultStyleName = selectedStyle ? selectedStyle : 'BOLD';
      const defaultProperties = defaultStyleProperties[defaultStyleName];
      const defaultLayoutData = getLayoutForLayoutType(
        logoUrl,
        defaultLayoutName,
        defaultCta,
        defaultSocialLinks
      );

      setSelectedLayout(defaultLayoutName);
      setSelectedStyle(defaultStyleName);
      setSelectedStyleProperties(defaultProperties);
      setSelectedLayoutData(defaultLayoutData);
      setPageBackgroundColor(
        pageBackgroundColor || defaultProperties.backgroundColor
      );
    }

    if (window.location.pathname.includes('landing-page-preview')) {
      setIsFullScreen(true);
      setShowLoader(false);
    }
  }, [selectedLayout, selectedStyle, showLoader]);

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  React.useEffect(() => {
    setSocialLinksForLayoutData(defaultSocialLinks);
  }, [defaultSocialLinks, selectedLayout]);

  React.useEffect(() => {
    const bc: any = new BroadcastChannel('covideo_social_link_channel');
    bc.addEventListener('message', (event: any) => {
      let updatedSocialLinks = event?.data || {};
      updatedSocialLinks = clean(updatedSocialLinks);
      setDefaultSocialLinks(updatedSocialLinks);
      setSocialLinksForLayoutData(updatedSocialLinks);
    });
  }, []);

  React.useEffect(() => {
    if (!selectedLayoutData || showLoader || !userData) return;
    if (isUndo) {
      setIsUndo(false);
      return;
    }
    const newState: any = {
      pageBackgroundSize,
      pageBackgroundColor,
      pageBackgroundPosition,
      pageBackgroundImageUrl,
    };
    newState.selectedLayoutData = JSON.parse(
      JSON.stringify(selectedLayoutData)
    );
    const stringifyState = JSON.stringify(newState);
    const lastUndoState = JSON.stringify(undoState[undoState?.length - 1]);
    if (lastUndoState !== stringifyState) {
      const newUndoState = [...undoState, newState];
      setIsUndo(false);
      setUndoState(newUndoState);
    }
  }, [
    selectedLayoutData,
    pageBackgroundSize,
    pageBackgroundColor,
    pageBackgroundPosition,
    pageBackgroundImageUrl,
    showLoader,
    userData,
  ]);

  React.useEffect(() => {
    return () => {
      setUndoState([]);
      setRedoState([]);
    };
  }, []);

  const changeSelectedLayoutHandler = (
    previousSelectLayoutData: any,
    changeState: any
  ) => {
    const previouspageBackgroundSize = changeState?.pageBackgroundSize;
    const previouspageBackgroundPosition = changeState?.pageBackgroundPosition;
    const previouspageBackgroundImageUrl = changeState?.pageBackgroundImageUrl;
    const previousPageBackgroundColor = changeState?.pageBackgroundColor;
    setSelectedLayoutData({ ...previousSelectLayoutData });
    setPageBackgroundPosition(previouspageBackgroundPosition);
    setPageBackgroundSize(previouspageBackgroundSize);
    setPageBackgroundImageUrl(previouspageBackgroundImageUrl);
    setPageBackgroundColor(previousPageBackgroundColor);
    if (elementSectionKey) {
      if (elementIndex !== '' && elementIndex >= 0) {
        setElementToEdit(
          previousSelectLayoutData[elementSectionKey].childSections[
            elementIndex
          ]
        );
      } else {
        setElementToEdit(previousSelectLayoutData[elementSectionKey]);
      }
    }
  };

  // Function which will execute when user click on undo button.
  const undoActionHandler = () => {
    setIsUndo(true);
    setRedoState([...redoState, undoState[undoState?.length - 1]]);
    const newUndoState = undoState.slice(0, undoState.length - 1);
    const leftStateUndo =
      undoState?.length >= 2
        ? undoState[undoState?.length - 2]
        : undoState[undoState?.length - 1];
    const previousSelectLayoutData = JSON.parse(
      JSON.stringify(leftStateUndo?.selectedLayoutData)
    );
    changeSelectedLayoutHandler(previousSelectLayoutData, leftStateUndo);
    setUndoState(newUndoState);
  };

  // Function which will execute when user click on redo button.
  const redoActionHandler = () => {
    setUndoState([...undoState, redoState[redoState?.length - 1]]);
    const newRedoState = redoState.slice(0, redoState.length - 1);
    const leftStateRedo = redoState[redoState?.length - 1];
    const previousSelectLayoutData = JSON.parse(
      JSON.stringify(leftStateRedo?.selectedLayoutData)
    );
    changeSelectedLayoutHandler(previousSelectLayoutData, leftStateRedo);
    setRedoState(newRedoState);
  };

  let backgroundColor =
    pageBackgroundColor || selectedStyleProperties.backgroundColor;

  const saveLandingPage = async () => {
    let title = pageTitle;
    if (!title) {
      const dateOfCreation = dayjs(new Date()).format(
        `${STANDARD_DATE_FORMAT} hh:mm:ss A`
      );
      title = 'Custom Landing Page ' + dateOfCreation;
      setPageTitle(title);
    }
    let requestData: { [keyed: string]: any } = {
      title: title,
      pageData: {
        selectedLayoutData: selectedLayoutData,
        pageDetails: {
          backgroundColor: pageBackgroundColor,
          backgroundImageUrl: pageBackgroundImageUrl,
          pageBackgroundSize: pageBackgroundSize,
          pageBackgroundPosition: pageBackgroundPosition,
          selectedStyleProperties: selectedStyleProperties,
        },
      },
      isDefault: isDefault,
      allowDuplicates: allowDuplicates,
      isShared: isShared,
      layoutName: selectedLayout,
      styleName: selectedStyle,
      builderVersion: builderVersion,
      userId: Number(userData.userId),
    };

    if (pageId) {
      requestData['id'] = pageId;
    }

    if (designId) {
      requestData['designId'] = designId;
    }
    try {
      const response = await createLandingPage(requestData);
      if (!pageId && response.landingPage) {
        setPageId(response.landingPage.id);
      }

      if (!designId && response.design) {
        setDesignId(response.design.id);
      }

      queryClient.invalidateQueries([TEMPLATE_QUERY]);
    } catch (error) {
      showError(error || 'Error creating landing page');
    }
  };
  async function getAndSetSocialLink() {
    try {
      const res: GetSocialLinksResponse = await getSocialMediaLinks();
      const { ...requiredData } = res.data;
      const finalData = clean(requiredData) || {};
      setDefaultSocialLinks(finalData);
      setSocialLinksForLayoutData(finalData || {});
      return finalData;
    } catch (error) {
      setDefaultSocialLinks({});
      return null;
    }
  }

  async function setCTAandSocialLinks() {
    let socialLinks =
      defaultSocialLinks && Object.keys(defaultSocialLinks).length
        ? defaultSocialLinks
        : await getAndSetSocialLink();
    let ctas =
      defaultCta && defaultCta.length ? defaultCta : await getAndSetCta();

    setDefaultSocialLinks(socialLinks);
    setSocialLinksForLayoutData(socialLinks || {});

    setDefaultCta(ctas);
    setCtaLinksForLayoutData(ctas);
    setShowLoader(false);
  }

  function clean(obj: { [key: string]: any }) {
    for (let propName in obj) {
      if (
        !obj[propName] ||
        (obj[propName] && obj[propName].toString().trim() === '')
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  async function getAndSetCta() {
    const res = await getCTASets({
      start: 0,
      limit: 0,
      search: '',
    }).catch(err => err);
    if (res instanceof Error) {
      setDefaultCta([]);
      return [];
    }

    const ctas: CTA[] =
      res.default && res.default.links ? res.default.links : [];
    const filteredCtas = ctas
      .map(cta => {
        if (cta.type === CTA_TYPES.PERSONAL_EMAIL) {
          cta.linkValue = `mailto:${!!cta.linkValue ? cta.linkValue : userData.email2}`;
        }

        if (cta.type === CTA_TYPES.PERSONAL_PHONE) {
          cta.linkValue = `tel:${!!cta.linkValue ? cta.linkValue : userData.phone1}`;
        }

        return cta;
      })
      .filter(cta => !!cta.linkText && !!cta.linkValue);
    setDefaultCta(filteredCtas);
    setCtaLinksForLayoutData(filteredCtas);
    return filteredCtas;
  }

  function setDefaultPlayButtonPosition(playButtonPosition: string) {
    let data = selectedLayoutData;
    if (!data) {
      return;
    }

    for (let key in data) {
      let sectionData = data[key];
      if (
        sectionData &&
        sectionData.sectionType == 2 &&
        sectionData.videoPlayerProperties
      ) {
        data[key]['videoPlayerProperties'].playerButtonPosition =
          playButtonPosition;
      }
    }

    setSelectedLayoutData(data);
  }
  function setCtaLinksForLayoutData(defaultCtaSet: object[]) {
    let data = selectedLayoutData;
    if (!data) {
      return;
    }

    for (let key in data) {
      let sectionData = data[key];
      let childArray = sectionData.childSections || [];
      if (childArray && childArray.length) {
        for (let i = 0; i < childArray.length; i++) {
          let childSection = childArray[i];
          if (childSection && childSection.sectionType == 3) {
            data[key]['childSections'][i]['links'] = defaultCtaSet;
          }
        }
      }

      if (sectionData && sectionData.sectionType == 3) {
        data[key]['links'] = defaultCtaSet;
      }
    }

    setSelectedLayoutData(data);
  }

  function setSocialLinksForLayoutData(socialLinks: object) {
    let data = selectedLayoutData;
    if (!data) {
      return;
    }

    for (let key in data) {
      let sectionData = data[key];
      let childArray = sectionData.childSections || [];
      if (childArray && childArray.length) {
        for (let i = 0; i < childArray.length; i++) {
          let childSection = childArray[i];
          if (childSection && childSection.sectionType == 4) {
            data[key]['childSections'][i]['socialLinks'] = socialLinks;
          }
        }
      }

      if (sectionData && sectionData.sectionType == 4) {
        data[key]['socialLinks'] = socialLinks;
      }
    }

    setSelectedLayoutData(data);
  }

  if (forceRedirect) {
    // setIsEnabled(false);
    return <Redirect to='/design/landing-pages' />;
  }
  if (showLoader) {
    return (
      <LoaderWrapper>
        <MoonLoader
          size={64}
          color={themes.colors.primary[100]}
          loading={true}
        />
      </LoaderWrapper>
    );
  }

  return (
    <MainContainer>
      {showAddSectionModal == true ? (
        <ModalLandingPageBuilderAddSection
          type={'add'}
          modalLoading={false}
          onClose={() => {
            setShowAddSectionModal(false);
          }}
          onContinueButtonClick={setSelectedLayoutData}
          onMainButtonClick={setSelectedLayoutData}
        />
      ) : (
        <></>
      )}

      {showSaveDesignModal ? (
        <ModalSaveDesign
          type={'add'}
          modalLoading={false}
          onClose={() => {
            setShowSaveDesignModal(false);
          }}
          onContinueButtonClick={setSelectedLayoutData}
          onMainButtonClick={setSelectedLayoutData}
          exportRef={exportRef}
        />
      ) : (
        <></>
      )}

      <Container padding={'10px 0 10px 0'}>
        <PageBuilderActionMenu
          setDevicePreview={setDevicePreview}
          undoActionHandler={undoActionHandler}
          redoActionHandler={redoActionHandler}
        />
      </Container>
      <Container
        ref={exportRef}
        height={'calc(100vh - 60px)'}
        padding={'0px 0px 0px 0px'}
        flex={'0 0 80%'}
        backgroundColor={'#eee'}
        className={isFullScreen ? 'fullScreen' : ''}
      >
        <LandingPageLayoutContainer
          backgroundColor={backgroundColor}
          backgroundImage={pageBackgroundImageUrl}
          pageBackgroundSize={pageBackgroundSize}
          pageBackgroundPosition={pageBackgroundPosition}
          devicePreview={devicePreview}
        />
        {isFullScreen ? (
          <ExitFullScreenMessage>
            <MessageText>
              Press <b>'ESC'</b> to exit preview.
            </MessageText>
          </ExitFullScreenMessage>
        ) : (
          <></>
        )}
      </Container>
      <Container
        height={'calc(100vh - 130px)'}
        padding={'0px 0px 0px 0px'}
        flex={'0 0 20%'}
      >
        <PageEditorSection />
      </Container>
    </MainContainer>
  );
};
