import { IContact } from 'lib/api/contacts/getSingleContact';
import {
  CONVERSATION_TYPES,
  IConversationUser,
  ISingleMessage,
} from 'lib/api/conversations/getSingleConversation';
import { ICombinedUserData } from 'lib/context';

export const getIntegrationType = (chat: ISingleMessage) => {
  const { integrationType, smsId } = chat;
  // support for old system
  if (smsId) {
    return smsId ? 'SMS' : 'MMS';
  }
  switch (integrationType) {
    case CONVERSATION_TYPES.SMS:
      return smsId ? 'SMS' : 'MMS';
    case CONVERSATION_TYPES.EMAIL:
      return 'Email';
    case CONVERSATION_TYPES.LIVE_CHAT:
      return 'Live chat';
    default:
      return '';
  }
};

export const deleteHTMLTags = (txt: string) => {
  return txt
    ?.replace(/<[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .trim();
};

export const getConversationHeader = (
  contact: IContact | IConversationUser
) => {
  if (!!contact?.firstName) return `${contact?.firstName} ${contact?.lastName}`;
  if (contact?.email) {
    return contact?.email;
  }
  if (contact?.phone) {
    return contact?.phone;
  }
  return '';
};

export const isNotAssignedToYou = (
  userId: number | null,
  userData: ICombinedUserData
) => `${userId}` !== userData.userId;
export const isAssignedToYou = (
  userId: number | null,
  userData: ICombinedUserData
) => `${userId}` === userData.userId;
export const isUnassigned = (userId: number | null) => userId === null;
