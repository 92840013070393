import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { contactsKeys } from './queryKeys';

export const getContact = async (id: string) => {
  const respose = await EXPRESS_API.get(`/contacts/${id}`);
  return respose.data;
};

export const useQueryContact = (
  contactId: string,
  enabled: boolean,
  staleTime?: number
) => {
  return useQuery(
    contactsKeys.single_contact(contactId),
    () => getContact(contactId),
    {
      refetchOnMount: true,
      enabled,
      staleTime,
    }
  );
};
