import { theme } from 'lib/style';
import React from 'react';
import { Button } from 'react-covideo-common';
import { BsPlus } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  padding: 24px;
  background-color: white;
  border-radius: 6px;
  width: 50%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.palette.blue100};
  font-weight: bold;
  gap: 12px;
  font-size: 16px;
`;

const StatusContainer = styled.div<{ isActive: boolean }>`
  background-color: #fef6f5;
  border-radius: 6px;
  color: ${theme.palette.red80};
  padding: 2px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${theme.palette.green50};
      color: ${theme.palette.green100};
    `}
`;

const BodyContainer = styled.div`
  margin-top: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  button {
    font-weight: bold;
  }
  gap: 8px;
`;

type RecurringReportProps = {
  title: string;
  isActive?: boolean;
  icon?: any;
  onClick: () => void;
  onDeactivate: () => void;
};

const RecurringReportItem = ({
  title,
  onClick,
  icon,
  isActive,
  onDeactivate,
}: RecurringReportProps) => {
  return (
    <Container>
      <TitleContainer>
        {icon} {title}
        <StatusContainer isActive={!!isActive}>
          {isActive ? 'Active' : 'Inactive'}
        </StatusContainer>
      </TitleContainer>
      <BodyContainer>
        {`Recurring report with summarized ${title.toLowerCase()} stats for your team or company.`}
      </BodyContainer>
      <ButtonContainer>
        <Button
          variant='secondary'
          text={isActive ? `Edit Report` : `Activate ${title}`}
          icon={
            isActive ? (
              <MdEdit color={theme.palette.blue100} height={15} />
            ) : (
              <BsPlus color={theme.palette.blue100} height={20} />
            )
          }
          onClick={onClick}
        />
        {isActive && (
          <Button
            variant='destructive'
            text='Deactivate'
            onClick={onDeactivate}
          />
        )}
      </ButtonContainer>
    </Container>
  );
};

export default RecurringReportItem;
