import React from 'react';
import { MdWarning } from 'react-icons/md';
import { toast } from 'react-toastify';
import { theme } from 'lib/style';
import { BaseNotificationBody } from './base';
import { ToastProps } from './types';
import { CloseToastIcon } from './close-icon';

const ErrorNotification = (props: ToastProps) => {
  return (
    <BaseNotificationBody {...props}>
      <MdWarning
        color={theme.palette.buttonDanger}
        size={26}
        style={{ minWidth: 26, maxWidth: 26 }}
      />
    </BaseNotificationBody>
  );
};

export const errorToast = (props: ToastProps) =>
  toast(<ErrorNotification {...props} />, {
    progressStyle: { background: 'transparent' },
    position: props.position || 'top-right',
    closeButton: CloseToastIcon,
  });
