import { DropdownProps } from './types';

export const HOURS: DropdownProps[] = [
  {
    value: 0,
    label: '0h',
  },
  {
    value: 1,
    label: '1h',
  },
  {
    value: 2,
    label: '2h',
  },
  {
    value: 3,
    label: '3h',
  },
  {
    value: 4,
    label: '4h',
  },
  {
    value: 5,
    label: '5h',
  },
  {
    value: 6,
    label: '6h',
  },
  {
    value: 7,
    label: '7h',
  },
  {
    value: 8,
    label: '8h',
  },
  {
    value: 9,
    label: '9h',
  },
  {
    value: 10,
    label: '10h',
  },
];

export const MINUTES: DropdownProps[] = [
  {
    value: 0,
    label: '0 min',
  },
  {
    value: 5,
    label: '5 min',
  },
  {
    value: 10,
    label: '10 min',
  },
  {
    value: 15,
    label: '15 min',
  },
  {
    value: 20,
    label: '20 min',
  },
  {
    value: 25,
    label: '25 min',
  },
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 35,
    label: '35 min',
  },
  {
    value: 40,
    label: '40 min',
  },
  {
    value: 45,
    label: '45 min',
  },
  {
    value: 50,
    label: '50 min',
  },
  {
    value: 55,
    label: '55 min',
  },
];

export const MEETING_TABS = {
  DETAILS: {
    value: 'Details',
    isHidden: false,
    isLocked: false,
    isLive: true,
    isDisabled: false,
  },
  RECORDING: {
    value: 'Recording',
    isHidden: false,
    isLocked: false,
    isLive: true,
    isDisabled: false,
  },
  CHAT: {
    value: 'Chat',
    isHidden: false,
    isLocked: false,
    isLive: true,
    isDisabled: false,
  },
};
