import {
  CheckboxInput,
  Dropdown,
  LoadingIndicator,
  NewModal,
} from 'lib/components';
import React, { LabelHTMLAttributes } from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Formik, Form, FormikValues } from 'formik';
import styled from 'styled-components/macro';
import { CategorizedGuide } from 'lib/api/guides/guideCategoryQuery';
import {
  EditableGuides,
  useEditableGuideCategoriesQuery,
} from 'lib/api/guides/editableGuideCategoriesQuery';
import { CommonTypography } from 'lib/components/styles/typography';
import { useMoveGuideMutation } from 'lib/api/guides/moveGuidesMutation';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
  resetSelection: () => void;
  selectedGuideCategory: Pick<
    CategorizedGuide,
    'guideCategoryId' | 'name'
  > | null;
  selectedGuides: number[];
}

interface IDropdown {
  value: number;
  label: string;
}

const Label = styled.label`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #272a32;
`;

const CheckboxLabel = styled(CommonTypography).attrs({ as: 'label' })<
  LabelHTMLAttributes<HTMLLabelElement>
>`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
`;

export const MoveGuideModal = ({
  closeModalHandler,
  selectedGuideCategory,
  selectedGuides,
  resetSelection,
}: IProps) => {
  const { data, isLoading } = useEditableGuideCategoriesQuery();

  const resetAndCloseModal = () => {
    resetSelection();
    closeModalHandler();
  };

  const { mutate, isLoading: isMoving } =
    useMoveGuideMutation(resetAndCloseModal);

  const onSubmitHander = (values: FormikValues) => {
    mutate({
      videoIds: values.videoIds,
      guideCategoryId: values.guideCategoryId,
      keepCopy: values.keepCopy,
    });
  };

  const dropdownOptions =
    (data || [])
      .map((editableGuide: EditableGuides) => ({
        value: editableGuide.guideCategoryId,
        label: editableGuide.name,
      }))
      .filter(
        (item: IDropdown) =>
          item.value !== selectedGuideCategory?.guideCategoryId
      ) || [];

  return (
    <NewModal
      headerText='Move Training Video'
      closeModal={closeModalHandler}
      style={{ content: { maxHeight: 250, height: 250 } }}
    >
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={true} />
        </div>
      ) : (
        <Formik
          initialValues={{
            keepCopy: '0',
            guideCategoryId: null,
            videoIds: selectedGuides,
          }}
          onSubmit={onSubmitHander}
        >
          {({ handleSubmit, values, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Gap
                  flexDirection='column'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  m='0 0 16px 0'
                >
                  <Label>Select Category</Label>
                  <Dropdown
                    options={dropdownOptions}
                    value={
                      dropdownOptions.find(
                        item => item.value === values.guideCategoryId
                      ) || null
                    }
                    onChange={(item: IDropdown) =>
                      setFieldValue('guideCategoryId', item.value)
                    }
                    creatable={false}
                    extendStyles={{
                      container: {
                        width: '100%',
                      },
                    }}
                    isSearchable={false}
                    placeholder='Select category'
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    menuPosition='absolute'
                    menuPlacement='bottom'
                    disabled={isMoving}
                  />
                </Gap>
                <Gap alignItems='center' gap='12px'>
                  <CheckboxInput
                    disabled={isMoving}
                    name='keepCopy'
                    id='keepCopy'
                    checked={values.keepCopy === '1'}
                    onChange={() => {
                      setFieldValue(
                        'keepCopy',
                        values.keepCopy === '0' ? '1' : '0'
                      );
                    }}
                  />
                  <CheckboxLabel htmlFor='keepCopy'>
                    Keep a copy of video(s) in the current category
                  </CheckboxLabel>
                </Gap>
                <Gap
                  m='32px 0 0 0'
                  alignItems='center'
                  justifyContent='flex-end'
                  gap='12px'
                >
                  <Button
                    disabled={isMoving}
                    text='Keep'
                    onClick={async () => {
                      closeModalHandler();
                    }}
                    variant='secondary'
                  />
                  <Button
                    type='submit'
                    text={isMoving ? 'Moving...' : 'Move'}
                    disabled={values.guideCategoryId === null || isMoving}
                  />
                </Gap>
              </Form>
            );
          }}
        </Formik>
      )}
    </NewModal>
  );
};
