import { theme } from 'lib/style';
import React, { useState } from 'react';
import { Button } from 'react-covideo-common';
import { MdAdd } from 'react-icons/md';
import styled from 'styled-components/macro';
import { CreateOrConectUserNameModal } from '../../modals/CreateOrConectUserNameModal';

const AddWrapper = styled.div`
  padding: 8px 20px;
  background: ${theme.palette.neutral20};
  margin-left: 1px;
  min-height: 48px;
  max-height: 48px;
  box-sizing: border-box;
`;

export const AddUsersColumns = ({ isEditMode }: { isEditMode: boolean }) => {
  const [showModal, setShowModal] = useState(false);

  const closeModalHandler = () => setShowModal(false);
  const openModalHandler = () => setShowModal(true);

  return (
    <AddWrapper>
      <Button
        text='User'
        icon={<MdAdd size={22} />}
        variant='white'
        size='small'
        onClick={openModalHandler}
      />
      {showModal && (
        <CreateOrConectUserNameModal
          closeModalHandler={closeModalHandler}
          isEditMode={isEditMode}
        />
      )}
    </AddWrapper>
  );
};
