import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { multilocationKeys } from './multilocationsKeys';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface MultilocationResponse {
  count: number;
  customers: MultilocationData[];
}

export type QueryListParams = {
  page?: number;
  size?: number;
  search?: string;
  sort: {
    sortKey: string;
    order: PaginationConstants;
  };
};

export interface MultilocationData {
  business: string;
  customerId: number;
  hasMultiLocation: number;
  locationCount: number;
  parentCustomerId: number;
  resellerId: number;
  resellerName: string;
  userCount: number;
}

const getMultilocations = async ({
  page = 0,
  size = 10,
  search,
  sort,
}: QueryListParams): Promise<MultilocationResponse> => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    search: search,
    ...sort,
  };

  return (
    await EXPRESS_API.get(`superadmin/multilocation`, {
      params,
    })
  ).data as MultilocationResponse;
};

export const useMultilocationsQuery = (options: QueryListParams) => {
  const { showError } = useToastError();
  const searchValue = options?.search || '';
  return useQuery(
    multilocationKeys.search(searchValue),
    () => getMultilocations(options),
    {
      onError: showError,
    }
  );
};
