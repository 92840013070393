import { VideoHotspotParams, VideoLinksParams } from './types';

export const videoKeys = {
  list: (folder: string, search?: string) =>
    ['VIDEO_LIST', folder, search] as const,
  ctas: (params: VideoLinksParams) => ['CTAS', params] as const,
  hotspots: (params: VideoHotspotParams) => ['HOTSPOTS', params] as const,
  inventoryItem: (itemId: number | string | undefined) =>
    ['DETAILS', itemId] as const,
  single: (videoId: number) => ['SINGLE_VIDEO', videoId] as const,
  video_count: () => ['VIDEOS_COUNT'] as const,
  reactions: (videoId: string) => ['VIDEO_REACTIONS', videoId] as const,
};
