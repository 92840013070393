import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation } from 'react-query';

export interface ICreateCustomReportPayload {
  title: string;
  range: string;
  startDate: Date;
  endDate: Date;
  reports: string[];
  users: string[];
}

export interface CustomReportResponse {
  title: string;
  range: string;
  startDate: string;
  endDate: string;
  reports: string[];
  users: string[];
  includeNewUsers: boolean;
  customerIds: string[];
  userId: number;
  reportId: number;
}

const createCustomReport = async (
  payload: ICreateCustomReportPayload
): Promise<CustomReportResponse> => {
  const response = await EXPRESS_API.post(`/custom-reports`, payload);
  return response.data;
};

export const useCreateCustomReportMutation = () => {
  return useMutation(createCustomReport, {
    onSuccess: () => {
      successToast({ title: 'Custom report created successfully' });
    },
    onError: () => {
      errorToast({ title: 'Something went wrong creating the report' });
    },
  });
};
