export enum VideoType {
  NoType = '',
  AdditionalRepair = 'Additional Repair',
  RepairInquiry = 'Repair inquiry',
  RegularService = 'Regular service',
  ServiceReminder = 'Service reminder',
  QuoteRequest = 'Quote request',
}

export enum VideoTypeSales {
  NoType = '',
  Marketing = 'Marketing',
  Prospecting = 'Prospecting',
  RespondToInquiry = 'Respond to Inquiry',
  ThanksForYourBusiness = 'Thanks For Your Business',
}
