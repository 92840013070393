import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

type Props = {
  imgSrc: string;
  title: string;
};

const Container = styled.div`
  max-width: 650px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 24px 0;
`;
const ImageWrap = styled.div`
  box-shadow: 0 16px 64px 0 rgba(0, 0, 0, 0.08);
  height: 80px;
  width: 80px;
  border: 1px solid ${theme.palette.blue05};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
`;
const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 16px;
`;
const Image = styled.img`
  max-height: 48px;
  max-width: 75px;
`;
const Title = styled.span`
  font-size: ${theme.fontSizes.xl};
  font-weight: ${theme.fontBold700};
  line-height: 1.33;
  letter-spacing: -0.24px;
  color: ${theme.palette.primaryDarkBlue};
  display: block;
  margin-bottom: 8px;
`;
export const IntegrationHeader = ({ imgSrc, title }: Props) => {
  return (
    <Container>
      <ImageWrap>
        <Image src={imgSrc} />
      </ImageWrap>
      <Content>
        <Title>{title}</Title>
      </Content>
    </Container>
  );
};
