import React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { MdInfo } from 'react-icons/md';
import { Tooltip } from 'react-covideo-common';

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  top: 2px;
`;

const GuideWrapper = styled.div<{ color: string }>`
  padding-top: 10px;
  font-size: 14px;
  color: ${({ color }) => (color ? color : theme.palette.gray100)};
`;

export const getUserTypeTooltip = () => {
  return (
    <TooltipWrapper>
      <Tooltip
        position={'bottom' as any}
        hoverable={true}
        extendStyles={{
          popup: {
            padding: '10px',
            position: 'absolute',
            width: '350px',
            top: '100%',
            left: '50%',
            backgroundColor: 'fff',
            borderRadius: '4px',
            transition: 'opacity 0.2s ease-in-out',
            zIndex: 9,
            height: '200px',
          },
        }}
        popup={
          <>
            <GuideWrapper color={theme.palette.gray100}>
              'Standard' access allows the user to only record and send videos.
            </GuideWrapper>
            <GuideWrapper color={theme.palette.gray100}>
              'Admin' access allows the user to both record and send videos, as
              well as pull reports and manage users. This is the highest level
              of access.
            </GuideWrapper>
            <GuideWrapper color={theme.palette.gray100}>
              'Supervisor' access allows the user to pull reports and manage
              users, but they cannot record or send videos. This type of account
              does not count against your allotted users.
            </GuideWrapper>
          </>
        }
      >
        <MdInfo
          style={{
            top: '2px',
          }}
        />
      </Tooltip>
    </TooltipWrapper>
  );
};
