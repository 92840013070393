import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { useToastError } from 'lib/hooks';
import { EXPRESS_API } from 'configs/api/expressApi';

export type WTVCopyParams = {
  title: string;
  flvName: string;
  animatedGifPath: string;
  year: number;
  make: string;
  model: string;
  wtvId: number;
};

const copyWTVVideo = async (props: WTVCopyParams) => {
  const data = {
    title: props.title,
    flvName: props.flvName,
    wtvId: parseInt(props.wtvId.toString(), 10),
    animatedGifPath: props.animatedGifPath,
    notes:
      props.year + ';' + props.make + ';' + props.model + ';' + props.title,
  };

  return await EXPRESS_API.post(`/wtv/videos`, { ...data });
};

export const useWheelsTvCopyMutation = ({
  onSuccesCallback,
}: {
  onSuccesCallback: () => void;
}) => {
  const { showError } = useToastError();
  return useMutation(copyWTVVideo, {
    onSuccess: () => {
      successToast({ title: 'Video has been added!' });
      onSuccesCallback();
    },
    onError: error =>
      showError(error, 'An error occured while trying to add the video!'),
  });
};
