import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { CloseButton } from 'lib/components';
import { Viewer } from 'lib/api/recipients/types';
import { Button } from 'react-covideo-common';

const ModalContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5;
  text-align: left;
  padding-top: 40px;
`;

const Flex = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalItem = styled.div`
  display: block;
  position: relative;
  width: 592px;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 16px;
`;

const Content = styled.div`
  padding: 30px 30px 15px;
  display: flex;
  flex-direction: column;
`;

const ContentHeader = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  margin-bottom: 15px;
`;

const ContentBody = styled.div`
  overflow: auto;
  ${theme.fontNormal400}
  font-size: ${theme.fontSizes.sm};
  color: ${theme.palette.blackRgb75};
  margin: 0;
  width: 100%;
  margin-bottom: 40px;
  opacity: 1;
  transition: all 0.3s;
`;

const ContentFooter = styled.div`
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 35px;
`;

type Props = {
  handleModalClose: (block?: boolean) => void;
  recepient: Viewer;
};

export const ModalBlockRecepient = (props: Props) => {
  const { handleModalClose, recepient } = props;
  return (
    <ModalContainer>
      <Flex>
        <ModalItem>
          <CloseButton onClick={() => handleModalClose(false)} />
          <Content>
            <>
              <ContentHeader>{'Block recipient?'}</ContentHeader>
              <ContentBody>
                {'Are you sure you want to block the recipient (' +
                  recepient.email +
                  ')?'}
              </ContentBody>
              <ContentFooter>
                <Button
                  variant='secondary'
                  text={'No, Cancel'}
                  onClick={() => handleModalClose(false)}
                />
                <Button
                  variant='destructive'
                  text={'Yes, Block'}
                  onClick={() => handleModalClose(true)}
                />
              </ContentFooter>
            </>
          </Content>
        </ModalItem>
      </Flex>
    </ModalContainer>
  );
};
