import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { CreateTeleprompterScriptParams } from './types';

export const createScript = async ({
  data,
}: CreateTeleprompterScriptParams) => {
  const response = await EXPRESS_API.post(`/teleprompter`, data);
  return response.data;
};

export const useAddScript = () => {
  return useMutation(createScript, {
    onSuccess: () => {
      successToast({
        title: 'Successfully saved script!',
      });
    },
    onError: () => {
      errorToast({ title: 'An error occurred while creating script.' });
    },
  });
};
