import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Main } from './main/Main';

export const LinkedinRoutes = () => {
  return (
    <Switch>
      <Route path="/linkedin" component={Main} />
    </Switch>
  );
};
