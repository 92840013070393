import { errorToast } from 'lib/components/toasts/error';
import { useMutation, useQueryClient } from 'react-query';
import { MeetingResponse } from './types';
import { meetingKeys } from './meetingKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

interface IUpdateMeetingParams
  extends Omit<Partial<MeetingResponse>, 'attendees'> {
  attendees?: { email: string }[];
}

export const updateMeeting = async (
  data: IUpdateMeetingParams,
  sendEmail?: boolean
) => {
  const params = sendEmail ? { sendEmail: true } : {};

  const response = await EXPRESS_API.put(`/meeting/${data.meetingId}`, data, {
    params,
  });
  return response.data;
};

export const useUpdateMeetingMutation = (
  handleCloseLiveMeetingModal: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { meetingData: IUpdateMeetingParams; sendEmail?: boolean }) =>
      updateMeeting(data.meetingData, data.sendEmail),
    {
      onSuccess: (meeting: MeetingResponse) => {
        queryClient.refetchQueries(
          meetingKeys.meeting_details(meeting.meetingId || '')
        );
        queryClient.invalidateQueries(meetingKeys.meetings_list());

        handleCloseLiveMeetingModal();
      },
      onError: () => {
        errorToast({
          title:
            'An error occurred updating the live meeting, please try again later!',
        });
      },
    }
  );
};
