import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { TextInput } from 'lib/components/inputs';
import { Button } from 'react-covideo-common';
import { MdAdd } from 'react-icons/md';
import { SnippetType } from 'lib/const';
import { useAuth } from 'lib/context';
import { TextEditor } from 'lib/components/textEditorAutomotive';
import { LaunchPopup } from 'lib/components/magic/popups/LaunchPopup';
import { useIsMutating } from 'react-query';

const Input = styled(TextInput)`
  box-shadow: none;
  font-size: 15px;

  width: 100%;
  &:focus {
    box-shadow: none;
  }
`;

const Label = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${theme.palette.gray60};
`;

const ButtonsWrapper = styled.section`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 32px;
`;

const Text = styled.div`
  color: ${theme.palette.buttonDanger};
`;

const emailEditorToolbar = {
  options: ['inline', 'remove', 'link'],
  inline: {
    options: ['bold', 'italic', 'underline'],
    bold: {
      className: 'toolbar-custom-icon toolbar-bold-icon large-icon',
    },
    italic: {
      className: 'toolbar-custom-icon toolbar-italic-icon large-icon',
    },
    underline: {
      className: 'toolbar-custom-icon toolbar-underline-icon large-icon',
    },
  },
  remove: {
    className: 'toolbar-custom-icon toolbar-remove-icon medium-icon',
  },
  link: {
    inDropdown: true,
    className:
      'toolbar-custom-icon toolbar-link-icon small-icon toolbar-custom-link-dropdown-option',
    options: ['link', 'unlink'],
    link: {
      className: 'toolbar-custom-icon add-content-link toolbar-link-icon',
    },
    unlink: {
      className: 'toolbar-custom-icon add-content-unlink toolbar-unlink-icon',
    },
  },
};
const smsEditorToolbar = {
  options: [],
};
type SnippetInitialData = {
  title: string;
  content: string;
  subject?: string;
};
type Props = {
  handleSubmit: (snippetData: SnippetInitialData) => void;
  snippetType: SnippetType;
};
export const SnippetSimplifiedForm = ({ handleSubmit, snippetType }: Props) => {
  const isMutating = useIsMutating();
  const isDisabled = isMutating > 0;
  const {
    userData: { droplrAccess },
  } = useAuth();

  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const inValidTitleLength = title && title.length > 60; /* SUS-908 changes */

  return (
    <>
      <Label>{snippetType === SnippetType.Email && 'Template'} Title</Label>
      <Input
        autoFocus={true}
        value={title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTitle(e.target.value)
        }
        margin={inValidTitleLength ? '0px' : '0 0 16px'} /* SUS-908 changes */
      />
      {snippetType === SnippetType.Email && (
        <>
          <Label>Subject</Label>
          <Input
            value={subject}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSubject(e.target.value)
            }
            margin={
              inValidTitleLength ? '0px' : '0 0 16px'
            } /* SUS-908 changes */
          />
        </>
      )}
      {/* SUS-908 changes */}
      {inValidTitleLength && (
        <Text>
          <span>Title can not contain more than 60 characters.</span>
        </Text>
      )}
      <Label>Content</Label>
      {snippetType === SnippetType.Email && (
        <TextEditor
          height={'176px'}
          onTextEditorChange={newContent => setContent(newContent)}
          initialContent={content}
          toolbar={emailEditorToolbar}
          showFilesButton={droplrAccess}
          editorWrapperClassname='automotive-wrapper snippet-modal'
          showMagicButton={true}
          magicHoverPopup={<LaunchPopup />}
        />
      )}
      {snippetType === SnippetType.Sms && (
        <TextEditor
          height={'176px'}
          onTextEditorChange={newContent => setContent(newContent)}
          initialContent={content}
          toolbar={smsEditorToolbar}
          showCharacterCounter={true}
          showFilesButton={droplrAccess}
          editorWrapperClassname='automotive-wrapper snippet-modal'
          snippetType={snippetType}
          showMagicButton={true}
          magicHoverPopup={<LaunchPopup />}
        />
      )}
      <ButtonsWrapper>
        <Button
          text={`Save & Insert`}
          variant='primary'
          icon={<MdAdd />}
          onClick={() =>
            handleSubmit({ content: content, title: title, subject: subject })
          }
          disabled={
            title === '' || title.length > 60 || isDisabled
          } /* SUS-908 changes */
        />
      </ButtonsWrapper>
    </>
  );
};
