import React, { useEffect } from 'react';
import { CustomerOrganization, Option } from 'lib/context';
import {
  FieldLabel,
  FormProps,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
} from 'app/pages/admin/components/AdminFormComponents';
import { SelectInput } from 'lib/components';
import { MdAdd } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { Gap } from 'lib/components/styles/layout';
import { useOrganizationsQuery } from 'lib/api/superAdminApi';
import { Card, Divider } from 'app/pages/admin/index.styled';
import { Button } from 'react-covideo-common';

export const OrganizationSection = (props: FormProps) => {
  const { setFieldValue, values } = props;
  const { customerOrganizations: oldCustomerOrganizations = [] } = values;
  const { organizations, isLoading: isLoadingOrganizations } =
    useOrganizationsQuery({
      params: {
        start: 0,
        limit: 500,
      },
      enabled: true,
    });

  const [customerOrganizations, setCustomerOrganizations] = React.useState<
    CustomerOrganization[]
  >(oldCustomerOrganizations);

  useEffect(() => {
    setFieldValue('customerOrganizations', customerOrganizations);
  }, [customerOrganizations]);

  const orgIdToRegions = organizations.reduce<Record<number, Option[]>>(
    (acc, org) => {
      acc[org.organizationId] = org.organizationRegions
        ? org.organizationRegions.map(region => ({
            label: region.name,
            value: region.organizationRegionId.toString(),
          }))
        : [];
      return acc;
    },
    {}
  );

  const updateCustomerOrganization = (
    id: string | number,
    newData: CustomerOrganization
  ) => {
    setCustomerOrganizations(prev =>
      prev.map(item => (item.id === id ? { ...item, ...newData } : item))
    );
  };

  const addOrganization = () => {
    const id = `new-${uuidv4()}`;
    const organizationId = getDefaultOrganization();
    setCustomerOrganizations(prev => [
      ...(prev || []),
      { id, organizationId } as CustomerOrganization,
    ]);
  };

  const removeOrganization = (id: number | string) => {
    setCustomerOrganizations(prev => prev.filter(item => item.id !== id));
  };

  const generateOrganizationOptions = (
    currentOrganization: CustomerOrganization
  ) => {
    return organizations
      .filter(organization => {
        const isChosen = customerOrganizations.some(
          customerOrg =>
            customerOrg.organizationId === organization.organizationId &&
            customerOrg.id !== currentOrganization.id
        );

        return !isChosen;
      })
      .map(organization => ({
        value: organization.organizationId.toString(),
        label: organization.name,
      }));
  };

  const generateRegionOptions = (id: number | string) => {
    const organizationId = customerOrganizations.find(
      customerOrganizations => customerOrganizations.id === id
    )?.organizationId;
    if (!organizationId) {
      return [];
    }
    return orgIdToRegions[organizationId];
  };

  const getDefaultOrganization = () => {
    const takenOrganizationIds = customerOrganizations.map(
      customerOrganizations => customerOrganizations.organizationId
    );
    return (
      organizations.find(
        organization =>
          !takenOrganizationIds.includes(organization.organizationId)
      )?.organizationId ||
      organizations[0]?.organizationId ||
      ''
    );
  };

  if (!organizations.length) {
    return null;
  }

  return (
    <>
      <Section>
        <SectionTitle>Administration</SectionTitle>
        <RowWrapper>
          <Row flexDirection='column' gap='16px'>
            {customerOrganizations.map(customerOrganization => {
              const organizationOptions =
                generateOrganizationOptions(customerOrganization);
              return (
                <Card key={customerOrganization.id}>
                  <Row>
                    <FieldLabel>Organization</FieldLabel>
                    <SelectInput
                      defaultValue={customerOrganization.organizationId}
                      disabled={isLoadingOrganizations}
                      onChange={event => {
                        updateCustomerOrganization(customerOrganization.id, {
                          ...customerOrganization,
                          organizationId: parseInt(event.target.value, 10),
                        });
                      }}
                    >
                      {organizationOptions.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </SelectInput>
                  </Row>
                  <Row>
                    <FieldLabel>Region</FieldLabel>
                    <SelectInput
                      defaultValue={customerOrganization.organizationRegionId}
                      disabled={isLoadingOrganizations}
                      onChange={event => {
                        updateCustomerOrganization(customerOrganization.id, {
                          ...customerOrganization,
                          organizationRegionId: parseInt(
                            event.target.value,
                            10
                          ),
                        });
                      }}
                    >
                      <option value=''>None</option>
                      {generateRegionOptions(customerOrganization.id).map(
                        option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        )
                      )}
                    </SelectInput>
                  </Row>
                  <Gap justifyContent='flex-end'>
                    <Button
                      variant='destructive'
                      onClick={() =>
                        removeOrganization(customerOrganization.id)
                      }
                      text={'Remove'}
                    />
                  </Gap>
                </Card>
              );
            })}
          </Row>
          {organizations.length > customerOrganizations.length && (
            <Row>
              <Button
                icon={<MdAdd />}
                text={`Add ${
                  !!customerOrganizations.length ? 'Another ' : ''
                }Organization`}
                onClick={() => addOrganization()}
              />
            </Row>
          )}
        </RowWrapper>
      </Section>
      <Divider />
    </>
  );
};
