import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const SelectionWrapper = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CheckboxInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectionText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.coal};
  margin-left: 16px;
  text-transform: capitalize;
`;

export const Menu = styled.div`
  display: flex;
  .dropTypeToggle {
    width: fit-content;
    float: right;
  }
  justify-content: space-between;
`;

export const DropdownContainer = styled.div`
  width: 200px;
  margin-left: 12px;
  & > div > div {
    border-radius: 4px;
  }
`;

export const GridView = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  .gridThumbnailIcons {
    width: 100px;
    height: 100px;
    margin: auto;
    display: block;
    padding: 8px;
  }

  & > div:nth-child(4n) {
    margin-right: 0;
  }
`;

const Item = styled.div`
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  box-sizing: border-box;
`;

export const GridItem = styled(Item)`
  position: relative;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  width: 22%;
  height: 244px;
  margin: 0 4% 32px 0;
`;

type ThumbnailProps = {
  image: string;
};

const Thumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
`;

export const GridThumbnail = styled(Thumbnail)`
  height: 120px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
`;

export const CheckboxGridWrapper = styled.div`
  position: absolute;
  .whiteCheckBox {
    background: white;
    border-radius: 4px;
  }
  top: 8px;
  left: 8px;
`;

export const ListThumbnail = styled(Thumbnail)`
  width: 96px;
  height: 100%;
  flex-shrink: 0;
  cursor: pointer;
`;

export const GridContent = styled.div`
  margin: 16px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.coal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const Url = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.blue60};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  cursor: pointer;
  &:hover {
    background: ${theme.palette.blue05};
    border-radius: 5px;
  }
`;

export const LinkButton = styled(ButtonWrapper)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${theme.palette.blue80};
`;

export const ListView = styled.div`
  margin-top: 40px;
  .listThumbnailIcons {
    width: 24px;
    height: auto;
    margin: auto;
    display: block;
  }
`;

export const ListItem = styled(Item)`
  margin-bottom: 8px;
  height: 64px;
  display: flex;
  align-items: center;
`;

export const ListTitle = styled.div`
  margin-left: 24px;
  width: 20%;
  cursor: pointer;
`;

export const ListUrl = styled.div`
  margin-left: 48px;
  width: 20%;
`;

export const ListAction = styled.div`
  margin-left: auto;
  margin-right: 30px;
  width: 20%;
`;

export const CheckboxListWrapper = styled.div`
  display: flex;
  align-items: center;
  .whiteCheckBox {
    background: white;
    border-radius: 4px;
  }
  justify-content: center;
  margin-left: 16px;
  margin-right: 20px;
`;

export const EmptySearchContainer = styled.div`
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: ${theme.fontSizes.md};
    line-height: ${theme.fontSizes.lg};
    letter-spacing: 1px;
    margin-bottom: 12px;
  }
`;

type ThumbnailContainerProps = {
  width: string;
  height: string;
};

export const ThumbnailContainer = styled.div<ThumbnailContainerProps>`
  width: ${props => props.width};
  cursor: pointer;
  height: ${props => props.height};
`;

export const TabContainer = styled.div<any>`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  .activeTab {
    color: ${props => props.color};
    border-bottom: 5px solid ${props => props.color};
  }
  border-bottom: 1px solid #eeeff2;
  margin-top: 25px;
`;

interface TabStyleProperties {
  width?: string;
}

export const Tab = styled.div<TabStyleProperties>`
  display: inline-flex;
  text-align: center;
  font-size: 18px;
  padding: 5px 15px;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  font-size: 18px;
  font-weight: 600;
  color: ${theme.palette.coal};
  align-items: center;
  width: ${props => (props.width ? props.width : 'fit-content')};
`;

export const DropContent = styled.div<{ isDragActive: boolean }>`
  margin: 0px 30px 60px;
  padding: 60px 30px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  background: ${props => (props.isDragActive ? '#F6F7F9' : '#FFFFFF')};
  border: 1px dashed #d0d3d9;
  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.coal};
  }
`;

export const FileContent = styled.div`
  background: #f6f7f9;
  height: 325px;
  overflow-y: auto;
`;
export const FileItem = styled.div`
  display: flex;
  padding: 16px 32px;
`;

export const FileDescription = styled.div`
  width: 90%;
`;

export const FileFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 30px;
`;

export const FileProgress = styled.progress`
  background-color: ${theme.palette.lightgray};
  border: none;
  border-radius: 2px;
  height: 4px;
  width: 100%;
  &::-webkit-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.lightgray};
    border-radius: 2px;
    height: 4px;
  }
  &::-moz-progress-bar {
    margin: 0 auto;
    background-color: ${theme.palette.primaryDarkBlue};
    border-radius: 2px;
    height: 4px;
  }
  &::-webkit-progress-value {
    display: inline-block;
    float: left;
    height: 4px;
    background: ${theme.palette.primaryDarkBlue};
    border-radius: 2px;
  }
`;

export const FileDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const FileStatus = styled.div<{ color: string }>`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.color ? props.color : '#9297A2')};
`;

export const FileSize = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${theme.palette.coal};
`;
