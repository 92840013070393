import { EXPRESS_API } from 'configs/api/expressApi';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { groupKeys } from './groupKeys';
import { MergeGroupsReuqest } from './types';
import { QueryParams } from './useQueryGroups';

export const mergeGroups = async (data: MergeGroupsReuqest) => {
  const response = await EXPRESS_API.post('/groups/merge', data);
  return response.data;
};

export const useMergeGroupsMutation = (
  onSuccess?: () => void,
  queryParams?: QueryParams
) => {
  const queryClient = useQueryClient();
  return useMutation((data: MergeGroupsReuqest) => mergeGroups(data), {
    onError: () => {
      errorToast({
        title: 'An error occurred mergeing groups, please try again later!',
      });
    },
    onSuccess: async () => {
      successToast({
        title: `You have successfully merged groups`,
      });
      await queryClient.invalidateQueries(groupKeys.groups(queryParams));
      onSuccess && onSuccess();
    },
  });
};
