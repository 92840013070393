import {
  DepartmentParams,
  ManagedDepartment,
  ManagedDepartmentResponse,
} from './types';
import { useQuery } from 'react-query';
import { departmentKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getManagedDepartments = async ({
  customerId,
  search = '',
  sort = '',
}: DepartmentParams): Promise<ManagedDepartment[]> => {
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sort ? { sort } : {}),
  };

  const results = (
    await EXPRESS_API.get(`departments/${customerId}/managed-departments`, {
      params,
    })
  ).data.data;

  return results.map((managedDepartment: ManagedDepartmentResponse) => ({
    departmentId: managedDepartment.DepartmentID,
    isDefault: !!managedDepartment.IsDefault,
    name: managedDepartment.Name,
    userCount: Number(managedDepartment.userCount),
  }));
};

export const useManagedDepartmentsQuery = (params: DepartmentParams) => {
  return useQuery(departmentKeys.managed(params), () =>
    getManagedDepartments(params)
  );
};
