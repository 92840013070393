import { VideoListItem } from 'lib/api';
import { getVideo } from 'lib/api/videos/useSingleVideoQuery';
import { VideoEncodingStatus } from 'lib/const/VideoEncoding';
import { useEffect, useState } from 'react';

type Props = {
  videoId: string | number;
  enabled: boolean;
};

const useVideoLoadRetry = ({ videoId, enabled }: Props) => {
  const MAX_RETRIES = 30;
  const MAX_DELAY = 5_000;
  const [videoData, setVideoData] = useState<VideoListItem | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const fetchData = async () => {
      try {
        const video: VideoListItem = await getVideo(videoId);
        if (
          video?.processing === VideoEncodingStatus.FAILED ||
          video?.processing === VideoEncodingStatus.READY
        ) {
          setVideoData(video);
          return;
        }
        if (retryCount < MAX_RETRIES) {
          let timeout = Math.pow(2, retryCount) * 1000;
          if (timeout > MAX_DELAY) {
            timeout = MAX_DELAY;
          }
          setTimeout(() => setRetryCount(retryCount + 1), timeout);
          return;
        }
        setError(true);
      } catch (error) {
        setError(true);
      }
    };

    fetchData();
  }, [videoId, retryCount, enabled]);

  return { videoData, retryCount, error };
};

export default useVideoLoadRetry;
