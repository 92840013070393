import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { useHistory } from 'react-router';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Field,
  FieldAttributes,
  Form,
  Formik,
  FormikHelpers,
  FormikValues,
} from 'formik';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  MdKeyboardArrowRight,
  MdOutlineAttachFile,
  MdPerson,
  MdRefresh,
  MdSend,
} from 'react-icons/md';
import { theme } from 'lib/style';
import { IChatHistory, ISingleChat, sendSms } from 'lib/api';
import { getChatHistory } from 'lib/api/smsApi';
import { Button } from 'react-covideo-common';
import {
  MainContainer,
  Search,
  TextInput,
  LoadingIndicator,
} from 'lib/components';
import { ReassignChatModal } from './ReassignChatModal';
import msgIcon from 'assets/images/single/msgIcon.webp';
import UserChatProfileInfo from './UserChatProfileInfo';
import { ChatsModal } from 'lib/components/modal/chatModal/ChatsModal';
import { ModalAddSMS } from 'lib/components/modal/ModalAddSMS';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { useAuth } from 'lib/context';
import { calculateDiff, isMessageRead } from 'lib/utils/time';
import { orderBy } from 'lodash';
import { calculateRange } from 'lib/utils/functions';
import { errorToast } from 'lib/components/toasts/error';
import { useToastError } from 'lib/hooks';
import { useUpdateMessagesMutation } from 'lib/api/messages/useUpdateMessagesMutation';
import { useMessagesQuery } from 'lib/api/messages/useMessagesQuery';
import { FaArrowRightArrowLeft } from 'react-icons/fa6';

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height:calc(100vh - 300px) ${theme.mediaQueryMaxWidth.md} {
    flex-direction: column;
  }
`;
const BreadcrumbsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0 20px 0;
`;

const LinkBtn = styled.div`
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 8px;
`;

const LinkText = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4e5461;
  margin-left: 8px;
`;

const MainChatBox = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #f6f7f9;
`;

const UserListBox = styled.div`
  width: 320px;
  color: #001b5399;
  height: calc(100vh - 240px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .list--header {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #f6f7f9;
    padding: 20px 20px 12px 20px;
    button {
      width: 100%;
    }
  }
  .divider {
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid #f6f7f9;
  }
`;

const UserChatBox = styled.div`
  width: 70%;
  color: #001b5399;
  padding-bottom: 20px;
  .divider {
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid #f6f7f9;
  }
`;

const Messages = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding: 8px 24px;
  max-width: 320px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f4f6;
  cursor: pointer;
  position: relative;

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #001b530d;
    `}
`;

const ImageWrapper = styled.div<{ isUnread: boolean }>`
  position: relative;
  margin-top: 6px;
  ${({ isUnread, theme }) =>
    isUnread &&
    css`
      :after {
        content: '';
        width: 6px;
        height: 6px;
        background: ${theme.colors.primary[100]};
        position: absolute;
        border-radius: 6px;
        right: -4px;
        top: -2px;
      }
    `}
`;

const RefreshIconWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: calc(50% - 8px);
`;

const MessagesInfoWrapper = styled.div`
  overflow: hidden;
`;

const MsgTitle = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #001b53;
  margin: 0;
`;

const MsgTime = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

const MsgData = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #001b53;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmptyMsg = styled.p`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

const MessagesContainer = styled.div<{ type: string }>`
  padding: 0px 10px 20px 10px;
  display: flex;
  align-items: ${({ type }) =>
    type === 'inbound' ? 'flex-start' : 'flex-end'};
  flex-direction: column;
`;

const ReceivedMessages = styled.div<{ type: string }>`
  padding: 12px 16px;
  max-width: 352px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  white-space: pre-line;
  margin-bottom: 8px;
  ${({ type }) =>
    type !== 'inbound' &&
    css`
      background: rgba(0, 27, 83, 1);
      color: rgba(255, 255, 255, 1);
      border-radius: 8px 8px 0px 8px;
    `}
  ${({ type }) =>
    type === 'inbound' &&
    css`
      background: white;
      color: #001b53;
      border-radius: 8px 8px 8px 0px;
    `}

    > img {
    max-width: 100%;
  }
`;

const ReceivedMessageInfo = styled.div<{ type: string }>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 27, 83, 0.6);
  text-align: ${({ type }) => (type !== 'inbound' ? 'right' : 'left')};
  width: 260px;
`;
const FormStyles = styled(Form)`
  display: flex;
  margin-top: 12px;
  gap: 12px;
  > input {
    flex: 1;
  }
  > button {
    margin: 0;
    height: 42px;
  }
`;

const AttachmentWrapper = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 27, 83, 0.4);
  border-radius: 6px;
  background: #f2f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  :hover > div {
    display: block;
  }
`;
const Tooltip = styled.div`
  display: none;
  position: absolute;
  color: rgba(39, 42, 50, 1);
  z-index: 90;
  width: 220px;
  text-align: center;
  left: calc(50% - 120px);
  top: -44px;
  padding: 8px 12px;

  font-size: 16px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 8px 32px 0 rgba(29, 30, 36, 0.08);
  :after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -7px;
    left: calc(50% - 7px);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;

    border-top: 7px solid white;
  }
`;

const NoMatchingConversations = styled.p`
  margin: 16px 0 0 0;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: rgba(0, 27, 83, 0.6);
`;

const ChatBody = styled.div<{ isEmpty: boolean; withInput: boolean }>`
  background: #001b530d;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  height: calc(100vh - 360px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      justify-content: center;
      align-items: center;
      height: calc(100vh - 245px);
    `}
  ${({ withInput }) =>
    withInput &&
    css`
      justify-content: center;
      align-items: center;
      height: calc(100vh - 360px);
    `}
`;

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid #f6f7f9;
  padding: 12px;
  position: relative;
  justify-content: space-between;
  max-height: 72px;
  height: 100%;
  box-sizing: border-box;
`;

const ChatOwnerText = styled.p`
  color: #9297a2;
  font-size: smaller;
  margin: 0;
  > span {
    color: ${theme.palette.primaryDarkBlue};
  }
`;

const ChatName = styled.p<{ clickable: boolean }>`
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
  margin: 0px;
  font-weight: 600;
  color: #001b53;
  font-size: 16px;
  color: ${theme.palette.primaryDarkBlue};
`;

type ModalsType = 'chooseFile' | 'reassign' | 'add';

const InputField = (props: FieldAttributes<any>) => {
  return <Field {...props} />;
};
interface MatchParams {
  id: string;
}

const detectUrlAndText = (txt: any) => {
  const URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return txt?.split(' ')?.map((part: any, i: any) =>
    URL_REGEX.test(part) ? (
      <a
        key={`links-${i}`}
        target='_blank'
        href={part}
        rel='noreferrer'
        style={{ color: 'inherit' }}
      >
        {part}{' '}
      </a>
    ) : (
      part + ' '
    )
  );
};

export const UsersChatLayout = withRouter(
  (props: RouteComponentProps<MatchParams>) => {
    const { userData } = useAuth();
    // check if leads integration active, if active use leads instead of contacts
    const leadsIntegrationActive = !!userData?.customer?.leadsIntegrationActive;
    const { id } = props.match.params;
    const query = new URLSearchParams(props.location.search);

    const modalType = query.get('modalType') as 'add' | null;
    const range = (query.get('range') || 'YTD').trim();

    const queryClient = useQueryClient();
    const { showError } = useToastError();
    const history = useHistory();
    const [modals, setModals] = useState<ModalsType | false>(
      modalType || false
    );
    const [chatData, setChatData] = useState<ISingleChat | null>(null);
    const [searchValue, setSearch] = useState('');
    const [selectedMsg, setSelectedMsg] = useState<any>(null);

    const dateObj = calculateRange(range);
    const { mutateAsync: updateChatAsync } = useUpdateMessagesMutation(range);

    const { mutateAsync, isLoading: isSendingMessage } = useMutation<
      any,
      any,
      {
        from: string;
        text: string;
        formik: FormikHelpers<{ data: string; phone: string }>;
      }
    >((data: any) => sendSms(data.from, data.text), {
      onSuccess: (res, { formik }) => {
        if (res.httpCode === 500) {
          errorToast({ title: res.message });
          return;
        }

        successToast({ title: 'Your message is sent successfully!' });
        formik.resetForm();

        if (id === 'new') {
          history.replace({
            pathname: `/sms/chats/${res.chatId}`,
            search: `?range=${range}`,
          });
        }

        queryClient.invalidateQueries(['singleChat', `${res.chatId}`], {
          active: true,
        });
        queryClient.invalidateQueries(['messages']);
      },
      onError: err => showError(err),
    });

    const isAdmin = userData.access === '3' || userData.access === '4';

    const { isLoading, data: messages } = useMessagesQuery({
      size: 100,
      search: searchValue,
      from: dateObj.from,
      ...(isAdmin ? {} : { userId: userData.userId }),
    });

    const { isRefetching: isRefetchingChats } = useQuery(
      ['singleChat', id],
      () => getChatHistory(id),
      {
        enabled: !!id && id !== 'new',
        onSuccess: res => {
          setChatData({ ...res, messages: res.messages.reverse() });
        },
      }
    );

    if (isLoading) {
      return <LoadingIndicator isLoading={isLoading} />;
    }

    const submitForm = async (
      values: FormikValues,
      formik: FormikHelpers<{
        data: string;
        phone: string;
      }>
    ) => {
      if (!chatData) {
        return null;
      }
      mutateAsync({ from: values.phone, text: values.data, formik });
    };

    const chatMessages = chatData?.messages || [];
    const isChatIdActive =
      chatData?.chatId || chatData?.contactPhone || id === 'new';

    const currentMessage = [
      ...(messages?.chats || []),
      ...(!selectedMsg ? [] : [selectedMsg]),
    ].find((msg: IChatHistory) => `${msg.chatId}` === `${id}`);

    const selectedName = currentMessage?.contactFirstName
      ? `${currentMessage?.contactFirstName} ${currentMessage?.contactLastName}`
      : currentMessage?.contactPhone;
    const newChatName = chatData?.contactFirstName
      ? `${chatData?.contactFirstName} ${chatData?.contactLastName}`
      : chatData?.contactPhone;

    const chatHeaderName = id === 'new' ? newChatName : selectedName;
    const chatHeaderOwner = currentMessage?.ownerFirstName
      ? `${currentMessage?.ownerFirstName} ${currentMessage?.ownerLastName}`
      : '';

    const allMessages = orderBy(
      messages?.chats,
      function (dateObj: any) {
        return new Date(dateObj.lastMessageCreatedAt);
      },
      ['desc']
    );

    return (
      <>
        <MainContainer>
          <BreadcrumbsContainer>
            <LinkBtn
              onClick={() => {
                history.push('/sms/overview');
              }}
            >
              SMS
            </LinkBtn>
            <MdKeyboardArrowRight size={24} />
            <LinkBtn
              onClick={() => {
                history.push('/sms/messages');
              }}
            >
              Messages
            </LinkBtn>
            {isChatIdActive && (
              <>
                <MdKeyboardArrowRight size={24} />
                <LinkText>{chatHeaderName}</LinkText>
              </>
            )}
          </BreadcrumbsContainer>
          <Content>
            <MainChatBox>
              <UserListBox>
                <div className='list--header'>
                  <Search
                    prevSearch={searchValue}
                    onSearch={value => {
                      setSearch(value);
                    }}
                    placeholder='Search conversations…'
                    width='280px'
                  />
                  <Button
                    text='+ New Conversation'
                    onClick={() => setModals('add')}
                    variant='secondary'
                  />
                </div>
                <div className='divider'></div>
                {messages?.chats?.length ? (
                  allMessages.map((message: IChatHistory) => {
                    const {
                      contactFirstName,
                      contactLastName,
                      contactPhone,
                      lastMessageCreatedAt,
                      lastMessageText,
                      chatId,
                      lastReadAt,
                      userId,
                    } = message;

                    const isUnread = !isMessageRead(
                      lastReadAt,
                      lastMessageCreatedAt
                    );
                    const isUsersChat =
                      `${userId}` === `${userData.userId}` || userId === -1;

                    return (
                      <Messages
                        key={chatId}
                        onClick={async () => {
                          history.replace({
                            pathname: `/sms/chats/${chatId}`,
                            search: `?range=${range}`,
                          });
                          setSelectedMsg(message);
                          if (isUnread && isUsersChat) {
                            updateChatAsync(`${chatId}`);
                          }
                        }}
                        isActive={chatId === isChatIdActive}
                      >
                        <ImageWrapper isUnread={isUsersChat && isUnread}>
                          <img
                            width={20}
                            height={20}
                            src={msgIcon}
                            alt={`icon`}
                          />
                        </ImageWrapper>
                        <MessagesInfoWrapper>
                          <MsgTitle>
                            {contactFirstName
                              ? `${contactFirstName} ${contactLastName}`
                              : contactPhone}
                          </MsgTitle>
                          <MsgTime>
                            {calculateDiff(lastMessageCreatedAt)}{' '}
                          </MsgTime>
                          <MsgData>{lastMessageText}</MsgData>
                        </MessagesInfoWrapper>
                        {`${chatId}` === `${id}` && isRefetchingChats && (
                          <RefreshIconWrapper>
                            <MdRefresh size={16} />
                          </RefreshIconWrapper>
                        )}
                      </Messages>
                    );
                  })
                ) : (
                  <NoMatchingConversations>
                    No matching conversations or customers.
                  </NoMatchingConversations>
                )}
              </UserListBox>
              <UserChatBox>
                {isChatIdActive && (
                  <ChatHeader>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <div style={{ display: 'flex', gap: 12 }}>
                        <MdPerson />
                        <ChatName
                          clickable={
                            !leadsIntegrationActive &&
                            !!currentMessage?.contactId
                          }
                          onClick={() => {
                            if (
                              !leadsIntegrationActive &&
                              !!currentMessage?.contactId
                            ) {
                              return history.push(
                                `/contacts/list/${currentMessage.contactId}`
                              );
                            }
                            return;
                          }}
                        >
                          {chatHeaderName}
                        </ChatName>
                      </div>
                      {chatHeaderOwner && (
                        <ChatOwnerText className='chat--header--subtext'>
                          Chat owner:
                          <span> {chatHeaderOwner}</span>
                        </ChatOwnerText>
                      )}
                    </div>
                    <Button
                      onClick={() => {
                        setModals('reassign');
                      }}
                      text='Reassign Owner'
                      variant='ghost'
                      icon={<FaArrowRightArrowLeft size={18} />}
                    ></Button>
                  </ChatHeader>
                )}
                <div className='divider'></div>
                <ChatBody
                  isEmpty={!chatMessages.length}
                  withInput={
                    (id === 'new' && !!chatData?.contactPhone) ||
                    isRefetchingChats
                  }
                >
                  {!isRefetchingChats && chatMessages.length ? (
                    <InfiniteScroll
                      key={chatData?.chatId}
                      next={() => {}}
                      dataLength={chatMessages.length}
                      style={{
                        height: 'calc(100vh - 250px)',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }} //To put endMessage and loader to the top.
                      inverse={false}
                      hasMore={false}
                      loader={<h4>Loading...</h4>}
                    >
                      {chatMessages.map((chat: any) => {
                        const firstName = chat?.user?.firstName;
                        return (
                          <MessagesContainer key={chat.chatId} type={chat.type}>
                            <ReceivedMessages type={chat.type}>
                              {chat.images.map((e: any) => {
                                const link = `https://api.covideo.com/images/public/${e.fileName}`;
                                return (
                                  <img
                                    onClick={() => {
                                      window.open(link, '_blank');
                                    }}
                                    alt='Chat part'
                                    src={link}
                                  />
                                );
                              })}
                              {detectUrlAndText(chat.text)}
                            </ReceivedMessages>
                            <ReceivedMessageInfo type={chat.type}>
                              {chat.type === 'inbound'
                                ? `${selectedName} ●`
                                : firstName && `${firstName} ●`}{' '}
                              {calculateDiff(
                                chat.deliveredAt || chat.createdAt
                              )}{' '}
                              ● {chat.smsId ? 'SMS' : 'MMS'}
                            </ReceivedMessageInfo>
                          </MessagesContainer>
                        );
                      })}
                    </InfiniteScroll>
                  ) : (
                    <EmptyMsg>
                      {!isRefetchingChats ? (
                        <>
                          Start a conversation or select an existing one on the
                          left
                        </>
                      ) : (
                        'Loading chat...'
                      )}
                    </EmptyMsg>
                  )}
                </ChatBody>
                {isChatIdActive && (
                  <Formik
                    initialValues={{
                      data: '',
                      phone: chatData?.contactPhone || '',
                    }}
                    enableReinitialize
                    onSubmit={submitForm}
                  >
                    {({ setFieldValue, submitForm, isSubmitting, values }) => (
                      <FormStyles>
                        <AttachmentWrapper
                          onClick={() => setModals('chooseFile')}
                        >
                          <MdOutlineAttachFile
                            height={25}
                            color='rgba(0, 27, 83, 1)'
                          />
                          <Tooltip>Attach video, image or file</Tooltip>
                        </AttachmentWrapper>
                        <InputField
                          style={{ margin: 0, width: '100%', maxWidth: '100%' }}
                          placeholder='Write a reply...'
                          name='data'
                          as={TextInput}
                          type='text'
                        />
                        <Button
                          text={isSendingMessage ? 'Sending' : 'Send SMS'}
                          icon={<MdSend size={20} />}
                          onClick={submitForm}
                          disabled={isSubmitting || !values.data}
                        />
                        {modals === 'chooseFile' && (
                          <ChatsModal
                            handleModalClose={() => {
                              setModals(false);
                            }}
                            onSelect={(item: any, itemType: string) => {
                              if (itemType === 'video' && item?.data?.url) {
                                return setFieldValue('data', item.data.url);
                              }
                              if (itemType === 'video' && item?.url) {
                                return setFieldValue('data', item?.url);
                              }
                              if (itemType === 'file' || itemType === 'board') {
                                return setFieldValue('data', item.shortlink);
                              }
                            }}
                          />
                        )}
                      </FormStyles>
                    )}
                  </Formik>
                )}
              </UserChatBox>
            </MainChatBox>
            {!leadsIntegrationActive && (
              <UserChatProfileInfo
                contactId={currentMessage?.contactId}
                isActive={!!isChatIdActive}
              />
            )}
          </Content>
          {modals === 'reassign' && (
            <ReassignChatModal
              handleModalClose={chatId => {
                setModals(false);

                if (chatId) {
                  queryClient.refetchQueries(['MESSAGES']);
                  queryClient.refetchQueries(['singleChat', id], {
                    active: true,
                  });
                }
              }}
              messageObj={chatData}
            />
          )}

          {modals === 'add' && (
            <ModalAddSMS
              setChatData={setChatData}
              onModalClose={() => setModals(false)}
            />
          )}
        </MainContainer>
      </>
    );
  }
);
