import React from 'react';
import SkeletonLoader from '../../skeletonLoaders/Loader';
import { WidgetWrapper } from './common/styles';
import {
  IParams,
  REPORTS_STALE_TIME,
  VariantTypes,
  WidgetLabels,
  WidgetValues,
} from './types';
import { EmptyWidget } from '../EmptyWidget';
import Header from './common/Header';
import {
  Body,
  BodyParagraph,
  ItemsWrapper,
  Tooltip,
  TopVariantsTooltipWrapper,
  WidgetTitle,
} from './styles/topVariants';
import { getReports } from 'lib/api';
import { useQuery } from 'react-query';
import { widget } from './keys';

interface ITopVariantsProps {
  params: IParams;
  variant: string;
  addDataForExport: (data: any) => void;
}
interface VariantProps {
  label: string;
  tabItems: { id: string; label: string }[];
  tableHeaders: string[];
  flexHeaders: string[];
}

interface VariantData {
  [key: string]: VariantProps;
}

const variantData = {
  [VariantTypes.CTA_SETS]: {
    label: 'Top CTA Sets',
    tabItems: [],
    tableHeaders: ['', 'CTA Set', 'Most Clicked Link', 'Total Set Clicks'],
    flexHeaders: ['0.1', '0.3', '0.3', '0.3'],
  },
} as VariantData;

const TopCtaSets = ({
  variant,
  params,
  addDataForExport,
}: ITopVariantsProps) => {
  const {
    data: topCtaSets,
    isLoading,
    isRefetching,
  } = useQuery(
    widget.detail(WidgetValues.TOP_CTA_SETS, params),
    () => getReports({ ...params, users: 'all' }, 'metrics/top/cta-sets'),
    {
      staleTime: REPORTS_STALE_TIME,
    }
  );

  const mapDataForExport = (data: any) => {
    let exportData: any = { title: '', data: [], arr: [] };
    exportData.title = WidgetLabels.TOP_CTA_SETS;
    data?.topCtaSets?.forEach((el: any) => {
      exportData.data.push({
        'CTA Set': el.setName,
        'Most Clicked Link': el.mostClickedLink,
        'Total Set Clicks': el.cnt,
      });
    });

    return exportData;
  };
  React.useEffect(() => {
    addDataForExport(
      mapDataForExport({
        topCtaSets,
      })
    );
  }, [topCtaSets]);

  if (isLoading) {
    return <SkeletonLoader imageName='line-report' />;
  }

  const TopCtaSetsTableBody = (data: any) => {
    return data?.data?.map((item: any, index: number) => (
      <Body key={index} lastRow={index === data?.data?.length - 1}>
        <BodyParagraph flex='0.1' bold>
          {index + 1}
        </BodyParagraph>
        <TopVariantsTooltipWrapper flex='0.3'>
          <BodyParagraph>{item.setName}</BodyParagraph>
          <Tooltip className='tooltip'>{item.setName}</Tooltip>
        </TopVariantsTooltipWrapper>

        <TopVariantsTooltipWrapper flex='0.3'>
          <BodyParagraph>{item.mostClickedLink}</BodyParagraph>
          <Tooltip className='tooltip'>{item.mostClickedLink}</Tooltip>
        </TopVariantsTooltipWrapper>
        <BodyParagraph flex='0.3' bold textAlign='right'>
          {item.cnt}
        </BodyParagraph>
      </Body>
    ));
  };

  return (
    <WidgetWrapper>
      <ItemsWrapper mb='0px'>
        <WidgetTitle>
          Top CTA Sets {isRefetching && <span>Updating ...</span>}
        </WidgetTitle>
      </ItemsWrapper>

      {topCtaSets?.length ? (
        <>
          <Header
            selectedLabel='Top CTA Sets'
            variantData={variantData}
            variant={variant}
          />
          <TopCtaSetsTableBody data={topCtaSets} />
        </>
      ) : (
        <EmptyWidget />
      )}
    </WidgetWrapper>
  );
};

export default TopCtaSets;
