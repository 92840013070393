export enum AccessRole {
  USER = '1',
  ADMIN = '3',
  SUPERVISOR = '4',
}

export const accessTypeOptions = [
  { value: 1, label: 'Standard' },
  { value: 3, label: 'Admin' },
  { value: 4, label: 'Supervisor' },
];
