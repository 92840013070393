import React from 'react';
import {
  FieldLabel,
  Red,
  Row,
  RowWrapper,
  Section,
  SectionTitle,
  UserFormProps,
  getInputField,
} from '../../../components/AdminFormComponents';
import {
  EmailAndUsernameInput,
  USERNAME,
} from 'app/pages/admin/components/EmailAndUsernameInput';
import { checkUsername } from 'lib/api';
import { NAPLETON_AUTOMOTIVE_RESELLER_ID } from 'lib/const/SuperAdminConstants';

export const PersonalInfoSection = (props: UserFormProps) => {
  const { isSubmitting, isNewUser, setFieldValue, values } = props;
  const [usernameValid, setUsernameValid] = React.useState(true);

  const checkExistingUser = async (value: string) => {
    const trimmedUserName = value.trim();
    const userValid = await checkUsername(trimmedUserName, '');
    const usernameValid =
      !!trimmedUserName &&
      (!userValid.userExist ||
        (userValid.userExist && values.id && userValid.id === values.id));
    setUsernameValid(usernameValid);
  };

  return (
    <Section id={'personalInfo'}>
      <SectionTitle>Personal info</SectionTitle>
      <RowWrapper>
        <Row>
          <FieldLabel>First Name {isNewUser && <Red>*</Red>}</FieldLabel>
          {getInputField('firstName', true, false, isSubmitting)}
        </Row>
        <Row>
          <FieldLabel>
            Last Name{' '}
            {props.values.resellerId !== NAPLETON_AUTOMOTIVE_RESELLER_ID && (
              <Red>*</Red>
            )}
          </FieldLabel>
          {getInputField('lastName', true, false, isSubmitting)}
        </Row>
        {isNewUser && (
          <Row>
            <FieldLabel>Email {isNewUser && <Red>*</Red>}</FieldLabel>
            <EmailAndUsernameInput
              name='email'
              required={true}
              readOnly={false}
              isSubmitting={isSubmitting}
              onChange={() => {
                setFieldValue(USERNAME, values.email);
                checkExistingUser(values.email);
              }}
            />
          </Row>
        )}
        {isNewUser && props.hasEmail2Field && (
          <Row>
            <FieldLabel>Email 2 </FieldLabel>
            <EmailAndUsernameInput
              name='email2'
              required={false}
              readOnly={false}
              isSubmitting={isSubmitting}
            />
          </Row>
        )}
        <Row>
          <FieldLabel>
            Username <Red>*</Red>
          </FieldLabel>
          <EmailAndUsernameInput
            name={USERNAME}
            required={true}
            readOnly={false}
            isSubmitting={isSubmitting}
            value={values.username}
            onChange={() => {
              checkExistingUser(values.username.trim());
            }}
            isUsernameValid={usernameValid}
          />
        </Row>
      </RowWrapper>
    </Section>
  );
};
