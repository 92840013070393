import { AxiosError } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks/useToastError';
import { useQuery } from 'react-query';
import { scheduleKeys } from './scheduleKeys';
import { Schedule } from './types';

const getSingleSchedule = async (scheduleId: number): Promise<Schedule> => {
  const response = await EXPRESS_API.get(`/schedule/${scheduleId}`);
  return response.data;
};

export const useGetSingleScheduleQuery = (scheduleId: number | undefined) => {
  const { showError } = useToastError();

  return useQuery(
    scheduleKeys.detail(scheduleId),
    () => (!!scheduleId ? getSingleSchedule(scheduleId) : null),
    {
      enabled: !!scheduleId,
      onError: (err: AxiosError) => showError(err),
    }
  );
};
