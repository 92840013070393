import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { customReportKeys } from './customReportKeys';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface CustomReportsParams {
  start?: number;
  limit?: number;
  sort?: {
    sortKey: string;
    order: PaginationConstants;
  };
  search?: string;
  recurring?: string;
}

export const getCustomReports = async (params: CustomReportsParams) => {
  const { sort, ...rest } = params;
  const response = await EXPRESS_API.get(`/custom-reports`, {
    params: { ...sort, ...rest },
  });
  return response.data;
};

export const useCustomReportsQuery = (params: CustomReportsParams) => {
  const { showError } = useToastError();
  return useQuery(
    customReportKeys.custom_reports(params),
    () => getCustomReports(params),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
