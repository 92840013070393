import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { TextInput } from 'lib/components/inputs';
import { Button } from 'react-covideo-common';
import React, { useState } from 'react';
import { Gap } from '../styles/layout';
import { IoMdClose } from 'react-icons/io';

const ModalItem = styled.div`
  box-sizing: border-box;
  width: 600px;
  padding: 32px;
  min-height: 208px;
  background-color: ${theme.palette.white};
`;

const Header = styled.div`
  display: flex;
  color: ${theme.palette.black_1_100};
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${theme.palette.themeDark50Percent};
  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin-top: 32px;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray80};
  margin-bottom: 8px;
`;

type Props = {
  title: string;
  label: string;
  defaultValue: string;
  secondaryButtonText: string;
  primaryButtonText: string;
  handleSubmit: (value: string) => void;
  handleModalClose: () => void;
};

export const ModalInputText = ({
  title,
  label,
  defaultValue,
  primaryButtonText,
  secondaryButtonText,
  handleModalClose,
  handleSubmit,
}: Props) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  return (
    <Modal>
      <ModalItem>
        <Header>
          {title}
          <CloseButtonWrapper>
            <IoMdClose size={24} onClick={handleModalClose} />
          </CloseButtonWrapper>
        </Header>
        <Content>
          <Label>{label}</Label>
          <TextInput
            autoFocus={true}
            value={inputValue}
            onChange={e => setInputValue(e.currentTarget.value)}
          />
          <Gap m='12px 0 0' gap='12px' justifyContent='flex-end'>
            <Button
              text={secondaryButtonText}
              onClick={handleModalClose}
              variant='secondary'
            />
            <Button
              text={primaryButtonText}
              variant='primary'
              onClick={() => handleSubmit(inputValue)}
              disabled={inputValue === ''}
            />
          </Gap>
        </Content>
      </ModalItem>
    </Modal>
  );
};
