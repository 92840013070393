import { useQuery } from 'react-query';
import { checkDropAuth, client } from './droplrAuth';
import { droplrKeys } from './queryKeys';
import { DropItem, DroplrGetDropsParams } from './types';
import { DROPTYPE_OPTIONS, DropType } from 'lib/const/Droplr';

export type GetDropsParams = {
  dropType: DROPTYPE_OPTIONS;
  search?: string;
  sort?: string;
  order?: string;
  page?: number;
  size?: number;
  boardId?: string;
  type?: string;
  variant?: string;
};

export const getDrops = async (params: GetDropsParams) => {
  await checkDropAuth();
  const {
    order,
    sort,
    page = 0,
    size = 10,
    type = '',
    variant = '',
    search = '',
    boardId,
  } = params;
  let body = {
    order: order,
    sort_by: sort,
    offset: page * size,
    amount: size,
    board: '',
  } as DroplrGetDropsParams;

  if (type) {
    body.type = type;
    if (type === DropType.FILE && variant) {
      body.variant = variant;
    }
  }

  if (search) {
    body.search = search;
  }
  if (boardId) {
    body.board = boardId;
  }

  const response = await client.drops.list(body);
  return {
    drops: response.results as DropItem[],
    count: Number(response.count) || 0,
    hasMore: response?.hasMore || false,
  };
};

export const getSharedDrops = async ({
  page = 0,
  size = 0,
}: {
  page: number;
  size: number;
}) => {
  await checkDropAuth();
  const response = await client.sharedDrop.list({
    offset: page * size,
    amount: size,
  });

  return {
    drops: response.results as DropItem[],
    count: Number(response.count) || 0,
    hasMore: response?.hasMore || false,
  };
};

export const useGetDrops = (params: GetDropsParams) => {
  return useQuery(droplrKeys.drop_list(params), () =>
    params.dropType === DROPTYPE_OPTIONS.OWNED
      ? getDrops(params)
      : getSharedDrops({
          page: params.page || 0,
          size: params.size || 10,
        })
  );
};
