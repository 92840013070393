import * as React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { VideoPlayer } from '../../../video/videoPlayer';
import { useLandingPageBuilderContext } from '../context';
import Slider from './Slider/Slider';
import { useHistory } from 'react-router-dom';
import { getSocialIconObject } from './Constants';
interface ImageElementProps {
  maxWidth?: string;
  margin?: string;
  height?: string;
  width?: string;
  src?: string;
  content?: any;
  color?: string;
  display?: string;
  float?: string;
  isVisible?: boolean;
  backgroundColor?: string;
  backgroundImage?: string;
  padding?: string;
  gridTemplateColumns?: string;
  gridGap?: string;
  justifyItems?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: string;
  flexDirection?: string;
  overflowX?: string;
  name?: string;
  minWidth?: string;
  id?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  flexWrap?: string;
  justifyContent?: string;
  alignItems?: string;
  isFullScreen?: boolean;
}

type ObjectProperties = {
  [key: string]: any;
};

interface ContainerProps {
  backgroundColor?: string;
  backgroundImage?: string;
  pageBackgroundSize?: string;
  pageBackgroundPosition?: string;
  devicePreview: string;
}
const GlobalStyle = createGlobalStyle<{ url: string; fontFamily: string }>`
  @font-face {
    font-family: '${({ fontFamily }) => fontFamily}';
    src: url('${({ url }) => url}') format('truetype');
  }
`;

const shapeToClassMapping: ObjectProperties = {
  rounded: 'roundedShape',
  circular: 'circularShape',
  sharp: 'sharpShape',
};
const positionToMarginMapping: ObjectProperties = {
  left: '0px auto 0px 10px',
  center: '0px auto 0px auto',
  right: '0px 10px 0px auto',
};

const positionToFloatMapping: ObjectProperties = {
  left: 'left',
  center: '',
  right: 'right',
};
const Container = styled.div<ContainerProps>`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  position: relative;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : ''};
  background-image: ${props =>
    props.backgroundImage ? 'url(' + props.backgroundImage + ')' : ''};

  background-repeat: no-repeat;
  background-size: ${props =>
    props.pageBackgroundSize ? props.pageBackgroundSize : 'cover'};
  background-position: ${props =>
    props.pageBackgroundPosition ? props.pageBackgroundPosition : 'left top'};
  ${({ devicePreview }) => {
    if (devicePreview === 'dektop') {
      return css`
        max-width: 100%;
      `;
    }
    if (devicePreview === 'tablet') {
      return css`
        max-width: 780px;
        margin: 0 auto;
        .row-header-section.multiple-header {
          width: auto;
          margin: 0 auto;
          .social-links {
            position: absolute;
            right: 0;
          }
        }
        .video-element {
          width: 100%;
        }
        .video-element-sibling {
          width: 100%;
          > div {
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      `;
    }
    if (devicePreview === 'mobile') {
      return css`
        max-width: 420px;
        margin: 0 auto;
        .row-header-section.multiple-header {
          width: auto;
          margin: 0 auto;
          .social-links {
            position: absolute;
            right: 0;
          }
        }
        .video-element {
          width: 100%;
        }
        .video-player-layout {
          width: unset;
          height: unset;
          figure {
            width: 100%;
          }
        }
        .video-element-sibling {
          width: 100%;
          margin: 0 auto;
          > div {
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      `;
    }
  }}
  height: fit-content;
  .sharpShape {
    border-radius: 0px;
  }

  .circularShape {
    border-radius: 20px;
  }

  .roundedShape {
    border-radius: 5px;
  }
  .zoomin {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
  }

  @-webkit-keyframes zoom {
    from {
      -webkit-transform: scale(0);
    }

    to {
      -webkit-transform: scale(1);
    }
  }

  @keyframes zoom {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
`;

type CTAButtonProps = {
  shape: string;
  backgroundColor: string;
  textColor: string;
  fontSize: string;
  fontWeight: string;
  fontFamily: string;
  isVisible?: boolean;
  hoverTextColor?: string;
  hoverBackgroundColor?: string;
};

const CTAButton = styled.button<CTAButtonProps>`
  margin: 10px;
  background-color: ${props => props.backgroundColor};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.fontFamily};
  color: ${props => props.textColor};
  border-radius: 5px;
  border: none;
  min-width: 142px;
  height: 40px;
  visibility: ${props => (props.isVisible == false ? 'hidden' : 'visible')};
  transition: 0.3s all;
  cursor: pointer;
  padding: 0 20px;

  overflow: hidden;
  white-space: none;
  text-overflow: ellipsis;

  ${props =>
    props.shape === shapeToClassMapping.rounded &&
    css`
      border-radius: 5px;
    `}

  ${props =>
    props.shape === shapeToClassMapping.circular &&
    css`
      border-radius: 20px;
    `}

  ${props =>
    props.shape === shapeToClassMapping.sharp &&
    css`
      border-radius: 0px;
    `}

    :hover {
    ${props =>
      props.hoverTextColor &&
      css`
        color: ${props.hoverTextColor};
      `}

    ${props =>
      props.hoverBackgroundColor &&
      css`
        background-color: ${props.hoverBackgroundColor};
      `}
  }
`;

const Row = styled.div<ImageElementProps>`
  display: ${props => (props.display ? props.display : 'block')};
  width: ${props => (props.width ? props.width : '100%')};
  padding: ${props => (props.padding ? props.padding : '15px 5px 15px 5px')};
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  height: ${props => (props.height ? props.height : 'fit-content')};
  float: ${props => (props.float ? props.float : '')};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : 'transparent'};
  background-image: ${props =>
    props.backgroundImage ? 'url("' + props.backgroundImage + '")' : ''};
  visibility: ${props => (props.isVisible == false ? 'hidden' : 'visible')};
  grid-template-columns: ${props =>
    props.gridTemplateColumns ? props.gridTemplateColumns : ''};
  grid-gap: ${props => (props.gridGap ? props.gridGap : '')};
  justify-items: ${props => (props.justifyItems ? props.justifyItems : '')};
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  margin: ${props => (props.margin ? props.margin : '')};
  overflow-x: ${props => (props.overflowX ? props.overflowX : '')};
  background-repeat: no-repeat;
  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : 'cover'};
  background-position: ${props =>
    props.backgroundPosition ? props.backgroundPosition : 'left top'};
  word-wrap: break-word;
  align-items: ${props => (props.alignItems ? props.alignItems : '')};
`;

const ElementContainer = styled.div<ImageElementProps>`
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  float: ${props => (props.float ? props.float : '')};
  margin: ${props => (props.margin ? props.margin : 'auto')};
  padding: ${props => (props.padding ? props.padding : '5px')};
  box-sizing: border-box;
  visibility: ${props => (props.isVisible == false ? 'hidden' : 'visible')};
  text-align: ${props => (props.textAlign ? props.textAlign : '')};
  display: ${props => (props.display ? props.display : '')};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : '')};
  flex-wrap: ${props => (props.flexWrap ? props.flexWrap : '')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : ''};
  video {
    height: ${props => (props.isFullScreen ? '100%' : '100%')};
    max-height: fit-content;
  }
  figure {
    width: ${props => (props.isFullScreen ? '800px' : '575px')};
  }

  figure div.fullscreen {
    height: auto;
  }
`;

const ImageElement = styled.img<ImageElementProps>`
  width: ${props => (props.width ? props.width : 'auto')};
  min-width: ${props => (props.minWidth ? props.minWidth : '')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  margin: ${props => (props.margin ? props.margin : '')};
  display: ${props => (props.display ? props.display : 'block')};
  visibility: ${props => (props.isVisible == false ? 'hidden' : 'visible')};
  cursor: pointer;
`;

const LogoElement = styled.img<ImageElementProps>`
  width: ${props => (props.width ? props.width : 'auto')};
  min-width: ${props => (props.minWidth ? props.minWidth : '')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  margin: ${props => (props.margin ? props.margin : '')};
  display: ${props => (props.display ? props.display : 'block')};
  visibility: ${props => (props.isVisible == false ? 'hidden' : 'visible')};
  max-height: 100%;
`;

const TextElement = styled.div<ImageElementProps>`
  width: ${props => (props.width ? props.width : 'auto')} !important;
  min-height: ${props => (props.height ? props.height : 'auto')} !important;
  margin: ${props => (props.margin ? props.margin : '')} !important;
  color: ${props => (props.color ? props.color : 'white')} !important;
  display: ${props => (props.display ? props.display : 'block')} !important;
  float: ${props => (props.float ? props.float : '')} !important;
  visibility: ${props =>
    props.isVisible == false ? 'hidden' : 'visible'} !important;
  font-family: ${props =>
    props.fontFamily ? props.fontFamily : 'inherit'} !important;
  font-size: ${props =>
    props.fontSize ? props.fontSize : 'inherit'} !important;
  p {
    color: ${props => (props.color ? props.color : 'white')} !important;
    display: ${props => (props.display ? props.display : 'block')} !important;
    float: ${props => (props.float ? props.float : '')} !important;
    visibility: ${props =>
      props.isVisible == false ? 'hidden' : 'visible'} !important;
    font-family: ${props =>
      props.fontFamily ? props.fontFamily : 'inherit'} !important;
    font-size: ${props =>
      props.fontSize ? props.fontSize : 'inherit'} !important;
    margin: 0 !important;
  }
  a {
    text-decoration: none !important;
    font-family: ${props =>
      props.fontFamily ? props.fontFamily : 'inherit'} !important;
    font-size: ${props =>
      props.fontSize ? props.fontSize : 'inherit'} !important;
  }
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : 'inherit !important'};
`;

const ImageModal = styled.div`
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
`;

const ImageModalContent = styled.img`
  margin: auto;
  display: block;
  width: fit-content;
  max-width: 700px;
`;

const ImageModalClose = styled.span`
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.6s;
  cursor: pointer;
`;

interface Props {
  devicePreview: string;
  backgroundColor?: string;
  backgroundImage?: string;
  pageBackgroundSize?: string;
  pageBackgroundPosition?: string;
}

export const LandingPageLayoutContainer = ({
  devicePreview,
  backgroundColor,
  pageBackgroundPosition,
  pageBackgroundSize,
  backgroundImage,
}: Props) => {
  const {
    selectedLayoutData,
    selectedStyleProperties,
    isFullScreen,
    setIsFullScreen,
    defaultSocialLinks,
    userProfile,
  } = useLandingPageBuilderContext();

  const history = useHistory();

  const templateVariablesObject: any = {
    '{{firstname}}': userProfile?.firstName || '',
    '{{lastname}}': userProfile?.lastName || '',
    '{{email}}': userProfile?.email || '',
    '{{phone1}}': userProfile?.phone1 ? userProfile.phone1.trim() : '',
    '{{phone2}}': userProfile?.phone2 ? userProfile.phone2.trim() : '',
    '{{department}}': userProfile?.dept || '',
  };

  React.useEffect(() => {
    if (history.action === 'POP') {
      setIsFullScreen(false);
    }
  }, [history]);

  function openImage(event: any) {
    const modal = document.getElementById('myModal') as HTMLDivElement;
    if (!modal) {
      return;
    }
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    const img: HTMLImageElement = event.target as HTMLImageElement;
    const modalImg: HTMLImageElement = document.getElementById(
      'img01'
    ) as HTMLImageElement;
    if (!modalImg) {
      return;
    }

    modalImg.classList.add('zoomin');

    modalImg.src = img.src;
    modal.style.display = 'block';
    const span = document.getElementById('imageModalClose') as HTMLSpanElement;
    if (!span) {
      return;
    }

    span.onclick = function () {
      modalImg.classList.remove('zoomin');
      modalImg.src = '';
      modal.style.display = 'none';
    };

    return false;
  }

  const renderSocialLinks = (
    id: string,
    key: string,
    index: number = -1,
    isHeaderCase: boolean = false,
    childId: any = null
  ) => {
    let sectionDetails;
    if (index !== null && index !== undefined && index >= 0) {
      sectionDetails = selectedLayoutData[key].childSections[index];
    } else {
      sectionDetails = selectedLayoutData[key];
    }

    if (!sectionDetails) {
      return <></>;
    }

    const elementContainerPosition = sectionDetails.sectionPosition || 'center';
    let socialLinks = defaultSocialLinks || sectionDetails.socialLinks || {};
    const shape = sectionDetails.elementShape;
    let keyArray = Object.keys(socialLinks);
    const socialIconObject: { [key: string]: any } = getSocialIconObject(
      shapeToClassMapping[shape] || ''
    );
    const anchorStyle = {
      textDecoration: 'none',
      display: 'inline-flex',
    };
    return (
      <ElementContainer
        id={isHeaderCase == true ? childId : id}
        width={'fit-content'}
        margin={positionToMarginMapping[elementContainerPosition]}
        className={'sectionListItem social-links'}
        isVisible={sectionDetails.isVisible}
        display={'flex'}
        flexWrap={'wrap'}
        justifyContent={'center'}
      >
        {keyArray.map(keyName => {
          if (socialIconObject[keyName]) {
            let element = socialIconObject[keyName];
            return (
              <a
                style={anchorStyle}
                key={keyName + new Date().getTime()}
                href={socialLinks[keyName]}
                target={'_blank'}
                rel='noreferrer'
              >
                {element}
              </a>
            );
          }
          return null;
        })}
      </ElementContainer>
    );
  };

  const renderCTA = (
    id: string,
    key: string = '',
    sectionDetails: any = null
  ) => {
    if (!sectionDetails) {
      sectionDetails = selectedLayoutData[key];
    }
    if (!sectionDetails) {
      return <></>;
    }

    const elementContainerPosition = sectionDetails.sectionPosition || 'center';
    let ctaSetArray = sectionDetails.links || [];
    if (
      sectionDetails.sectionType == '8' ||
      sectionDetails.isOverrideCTA === true
    ) {
      ctaSetArray = sectionDetails.links;
    }
    const elementProperties = sectionDetails.elementProperties || {};
    const shape = elementProperties.elementShape || 'rounded';
    const shapeClassName = shapeToClassMapping[shape] || '';
    const isVertical = sectionDetails.isVertical || false;

    const textColor =
      elementProperties.elementTextColor ||
      selectedStyleProperties.backgroundColor ||
      '#FFFFFF';
    const fontFamily =
      elementProperties.fontFamily || selectedStyleProperties.fontFamily;
    const fontWeight =
      elementProperties.fontStyle || selectedStyleProperties.fontWeight;
    const fontUrl = elementProperties?.fontUrl;
    const backgroundColor =
      elementProperties.elementBackgroundColor ||
      selectedStyleProperties.textColor;
    return (
      <ElementContainer
        display={isVertical == true ? 'flex' : ''}
        flexDirection={isVertical == true ? 'column' : ''}
        id={id}
        width={'fit-content'}
        margin={positionToMarginMapping[elementContainerPosition]}
        className={'sectionListItem'}
        isVisible={sectionDetails.isVisible}
      >
        {fontUrl && fontFamily && (
          <GlobalStyle
            key={`style-${id}`}
            url={fontUrl}
            fontFamily={fontFamily}
          />
        )}
        {ctaSetArray &&
          ctaSetArray.map((ctaItem: any, index: number) => {
            return (
              <CTAButton
                key={index.toString() + new Date().getTime()}
                shape={shapeClassName}
                hoverBackgroundColor={
                  elementProperties.elementHoverBackgroundColor || ''
                }
                hoverTextColor={elementProperties.elementHoverTextColor || ''}
                textColor={textColor}
                fontSize={elementProperties.fontSize}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
                backgroundColor={backgroundColor}
                isVisible={sectionDetails.isVisible}
                onClick={() => window.open(ctaItem.linkValue, '_blank')}
              >
                {ctaItem.linkText}
              </CTAButton>
            );
          })}
      </ElementContainer>
    );
  };
  const renderSections = (sectionKey: string, index: number) => {
    const sectionDetails = selectedLayoutData[sectionKey];

    const backgroundColor = sectionDetails.sectionBackgroundColor;
    if (sectionDetails.sectionType != 2 && sectionDetails.isVisible == false) {
      return <></>;
    }
    const sectionType = sectionDetails.sectionType;
    const id = sectionKey + '-' + sectionType + '-' + index.toString();
    let SectionElement;
    switch (sectionType) {
      case 1: {
        SectionElement = renderHeaderSection(sectionKey, id);
        break;
      }
      case 2: {
        SectionElement = renderCovideoSection(sectionKey, id);
        break;
      }
      case 3: {
        SectionElement = renderCTA(id, sectionKey);
        break;
      }
      case 4: {
        SectionElement = renderSocialLinks(id, sectionKey);
        break;
      }
      case 5: {
        SectionElement = renderTextSection(sectionKey, id);
        break;
      }
      case 6: {
        SectionElement = renderImageSection(sectionKey, id);
        break;
      }
      case 7: {
        SectionElement = renderEmbedVideoSection(sectionKey, id);
        break;
      }
      case 8: {
        SectionElement = renderCTA(id, sectionKey);
        break;
      }
      case 9: {
        SectionElement = renderLogoSection(sectionKey, id);
        break;
      }
      case 10: {
        SectionElement = NEW_renderColumnSection(sectionKey, id);
        break;
      }
    }

    return (
      <Row
        key={id + '-' + new Date().getTime()}
        backgroundImage={
          selectedLayoutData[sectionKey].sectionBackgroundImageUrl
        }
        backgroundPosition={
          selectedLayoutData[sectionKey].sectionBackgroundPosition
        }
        backgroundSize={selectedLayoutData[sectionKey].sectionBackgroundSize}
        backgroundColor={backgroundColor}
        isVisible={selectedLayoutData[sectionKey].isVisible}
        textAlign={
          sectionType == 8 || sectionType == 3
            ? sectionDetails.sectionPosition
            : ''
        }
        className={
          sectionType == 4 || sectionType == 1 ? 'sectionListItem' : ''
        }
        id={sectionType == 1 ? id : ''}
      >
        {SectionElement}
      </Row>
    );
  };

  const renderImageSection = (sectionKey: string, id: string) => {
    const sectionDetails = selectedLayoutData[sectionKey];
    if (sectionDetails.isVisible == false) {
      return <></>;
    }

    const imageArray = sectionDetails.images || [];
    const gridSize = sectionDetails.gridSize || 4;
    const arr = Array(gridSize).fill('auto');
    return (
      <Row>
        <Row
          display={'grid'}
          gridGap={'12px'}
          justifyItems={'center'}
          gridTemplateColumns={arr.join(' ')}
          width={'fit-content'}
          overflowX={'auto'}
          margin={'auto'}
          name={'imageSectionContainer'}
          id={id}
        >
          {imageArray.map((item: any, index: number) => {
            if (item && item.url) {
              let isSvg = false;
              isSvg = /.*\.(svg)/i.test(item.url);
              return (
                <ImageElement
                  src={item.url}
                  width={'auto'}
                  height={'auto'}
                  maxWidth={'320px'}
                  key={item.url + index}
                  alt={item.altText || ''}
                  minWidth={isSvg === true ? '135px' : ''}
                  isVisible={sectionDetails.isVisible}
                  onClick={e => {
                    openImage(e);
                  }}
                />
              );
            }
            return null;
          })}
        </Row>
      </Row>
    );
  };
  const renderEmbedVideoSection = (sectionKey: string, id: string) => {
    const sectionDetails = selectedLayoutData[sectionKey];
    if (sectionDetails.isVisible == false) {
      return <></>;
    }
    const embedVideoElementArray = selectedLayoutData[sectionKey].videos || [];
    const filteredArray = embedVideoElementArray.filter(
      (video: string) => video && video.trim() !== ''
    );
    return (
      <Row padding={'0px'} height={'500px'}>
        <ElementContainer
          id={id}
          width={'fit-content'}
          margin={positionToMarginMapping[sectionDetails.sectionPosition]}
          className={'sectionListItem'}
          isVisible={selectedLayoutData[sectionKey].isVisible}
        >
          {filteredArray.length > 0 && <Slider slides={filteredArray} />}
        </ElementContainer>
      </Row>
    );
  };
  function renderLogoSection(
    sectionKey: string,
    id: string,
    isHeaderCase: boolean = false,
    childIndex: number = -1
  ) {
    let sectionDetails = selectedLayoutData[sectionKey];
    let childId = id;
    if (isHeaderCase && childIndex >= 0) {
      sectionDetails = selectedLayoutData[sectionKey].childSections[childIndex];
      childId =
        id + '-' + sectionDetails.sectionType + '-' + childIndex.toString();
    }

    return (
      <LogoElement
        margin={
          positionToMarginMapping[sectionDetails.sectionPosition || 'center']
        }
        id={isHeaderCase == true ? childId : id}
        name={'logoImage'}
        src={sectionDetails.logoUrl}
        maxWidth={sectionDetails.logoWidth || 'auto'}
        width={sectionDetails.logoWidth || 'auto'}
        height={sectionDetails.logoHeight || 'auto'}
        display={sectionDetails.logoDisplay}
        className={`sectionListItem logo`}
        alt={sectionDetails.altText}
        isVisible={sectionDetails.isVisible}
      />
    );
  }

  const replaceTemplateVariables = (textContent: string) => {
    Object.keys(templateVariablesObject).forEach((templateString: string) => {
      textContent = textContent.replace(
        templateString,
        templateVariablesObject[templateString].toString()
      );
    });

    return textContent;
  };
  const renderTextSection = (
    sectionKey: string,
    id: string,
    isHeaderCase: boolean = false,
    childIndex: number = -1
  ) => {
    let sectionDetails = selectedLayoutData[sectionKey];
    let childId = id;
    if (isHeaderCase && childIndex >= 0) {
      sectionDetails = selectedLayoutData[sectionKey].childSections[childIndex];
      childId =
        id + '-' + sectionDetails.sectionType + '-' + childIndex.toString();
    }

    const textColor =
      sectionDetails.textColor ||
      selectedStyleProperties.textColor ||
      '#FFFFFF';
    const fontFamily =
      sectionDetails.textFont || selectedStyleProperties.fontFamily;
    const fontWeight =
      sectionDetails.textWeight || selectedStyleProperties.fontWeight;
    const fontUrl = sectionDetails?.fontUrl;

    let textContent = replaceTemplateVariables(
      decodeURIComponent(sectionDetails.textContent || '')
    );

    return (
      <ElementContainer
        className={'sectionListItem text'}
        id={isHeaderCase == true ? childId : id}
        width={'fit-content'}
        margin={
          positionToMarginMapping[sectionDetails.sectionPosition || 'center']
        }
      >
        {fontUrl && fontFamily && (
          <GlobalStyle
            key={`style-${isHeaderCase == true ? childId : id}`}
            url={fontUrl}
            fontFamily={fontFamily}
          />
        )}
        <TextElement
          isVisible={sectionDetails.isVisible}
          width={sectionDetails.textWidth || 'auto'}
          height={sectionDetails.textHeight || 'auto'}
          fontFamily={fontFamily}
          fontSize={sectionDetails.textSize || '16px'}
          fontWeight={fontWeight}
          color={textColor}
          dangerouslySetInnerHTML={{
            __html: textContent,
          }}
        />
      </ElementContainer>
    );
  };

  const renderCovideoSection = (
    sectionKey: string,
    id: string,
    childId: any = null
  ) => {
    const sectionDetails = selectedLayoutData[sectionKey];
    const VideoPlayerElement = renderVideoPlayerSection(sectionKey, id);
    let siblingSection = null;
    if (
      sectionDetails &&
      sectionDetails.childSections &&
      sectionDetails.childSections[0] &&
      sectionDetails.childSections[0].isVisible == true
    ) {
      siblingSection = sectionDetails.childSections[0];
      childId = id + '-' + siblingSection.sectionType + '-0';
      siblingSection.isVertical = true;
      let SiblingElement =
        sectionDetails.name === 'video-with-nested-image'
          ? renderImageAsSibling(childId, '', siblingSection)
          : renderCTA(childId, '', siblingSection);
      return (
        <Row
          display={'flex'}
          alignItems={'center'}
          padding={'0px'}
          style={{ flexWrap: 'wrap' }}
        >
          {sectionDetails.isVisible && (
            <Row
              width={siblingSection.isVisible ? '75%' : '100%'}
              padding={'0px'}
              float={'left'}
              className='video-element'
            >
              {VideoPlayerElement}
            </Row>
          )}
          {siblingSection.isVisible && (
            <Row
              width={sectionDetails.isVisible ? '25%' : '100%'}
              padding={'0px'}
              float={'left'}
              className='video-element-sibling'
            >
              {SiblingElement}
            </Row>
          )}
        </Row>
      );
    } else {
      return <>{VideoPlayerElement}</>;
    }
  };
  const renderVideoPlayerSection = (sectionKey: string, id: string) => {
    const sectionDetails = selectedLayoutData[sectionKey];
    const ref = React.createRef();
    const isOverrideVideoSettings =
      sectionDetails.overrideVideoSettings || false;
    const videoPlayerProperties = sectionDetails.videoPlayerProperties || {};
    let playerMainColor = '#585B5D';
    let playerButtonPosition =
      videoPlayerProperties.playerButtonPosition || 'center';
    let playerIconsColor = '#ffffff';
    if (isOverrideVideoSettings == true) {
      playerMainColor =
        videoPlayerProperties.playerBackgroundColor || '#585B5D';
      playerButtonPosition =
        videoPlayerProperties.playerButtonPosition || 'center';
      playerIconsColor = videoPlayerProperties.playerIconsColor || '#ffffff';
    }

    const placeholderVideoUrl =
      'https://videos.covideo.com/videos/19416_3_ixndt70uwrg1615232518.mp4';
    const placeholderVideoThumbnailUrl =
      'https://image.covideo.com/thumbnails/19416_3_ixndt70uwrg1615232518_0001.png';

    return (
      <ElementContainer
        isVisible={sectionDetails.isVisible}
        id={id}
        width={'fit-content'}
        className={'sectionListItem video-player-container'}
        margin={positionToMarginMapping[sectionDetails.sectionPosition]}
        isFullScreen={isFullScreen}
      >
        <VideoPlayer
          margin={'auto'}
          width={isFullScreen ? '800px !important' : '575px'}
          height={isFullScreen ? '450px !important' : '320px'}
          videoRef={ref}
          playButtonPosition={playerButtonPosition}
          playerBackgroundColor={playerMainColor}
          playerIconsColor={playerIconsColor}
          thumbnail={placeholderVideoThumbnailUrl}
          videoSource={placeholderVideoUrl}
        />
      </ElementContainer>
    );
  };

  const renderImageAsSibling = (
    id: string,
    key: string = '',
    sectionDetails: any = null
  ) => {
    if (!sectionDetails) {
      sectionDetails = selectedLayoutData[key];
    }
    if (!sectionDetails) {
      return <></>;
    }

    const imageArray = sectionDetails.images || [];
    const gridSize = sectionDetails.gridSize || 4;
    const arr = Array(gridSize).fill('auto');
    return (
      <Row>
        <Row
          display={'grid'}
          gridGap={'12px'}
          justifyItems={'center'}
          gridTemplateColumns={arr.join(' ')}
          width={'fit-content'}
          overflowX={'auto'}
          margin={'auto'}
          name={'imageSectionContainer'}
          id={id}
        >
          {imageArray.map((item: any, index: number) => {
            if (item && item.url) {
              let isSvg = false;
              isSvg = /.*\.(svg)/i.test(item.url);
              return (
                <ImageElement
                  src={item.url}
                  width={'auto'}
                  height={'auto'}
                  maxWidth={'320px'}
                  key={item.url + index}
                  alt={item.altText || ''}
                  minWidth={isSvg === true ? '135px' : ''}
                  isVisible={sectionDetails.isVisible}
                  onClick={e => {
                    openImage(e);
                  }}
                />
              );
            }
            return null;
          })}
        </Row>
      </Row>
    );
  };

  const NEW_renderImageSection = (
    id: string,
    key: string,
    index: number,
    isChildCase: boolean,
    childId: string
  ) => {
    let sectionDetails: any;
    if (index !== null && index !== undefined && index >= 0) {
      sectionDetails = selectedLayoutData[key].childSections[index];
    } else {
      sectionDetails = selectedLayoutData[key];
    }

    const imageArray = sectionDetails.images || [];
    const gridSize = sectionDetails.gridSize || 4;
    const arr = Array(gridSize).fill('auto');
    return (
      <Row className='image-section'>
        <Row
          display={'grid'}
          gridGap={'12px'}
          justifyItems={'center'}
          gridTemplateColumns={arr.join(' ')}
          width={'fit-content'}
          overflowX={'auto'}
          margin={'auto'}
          name={'imageSectionContainer'}
          id={isChildCase ? childId : id}
        >
          {imageArray.map((item: any, index: number) => {
            if (item && item.url) {
              let isSvg = false;
              isSvg = /.*\.(svg)/i.test(item.url);
              return (
                <ImageElement
                  src={item.url}
                  width={'auto'}
                  height={'auto'}
                  maxWidth={'320px'}
                  key={item.url + index}
                  alt={item.altText || ''}
                  minWidth={isSvg === true ? '135px' : ''}
                  isVisible={sectionDetails.isVisible}
                  onClick={e => {
                    openImage(e);
                  }}
                />
              );
            }
            return null;
          })}
        </Row>
      </Row>
    );
  };

  const NEW_renderColumnSection = (sectionKey: string, id: string) => {
    const sectionDetails = selectedLayoutData[sectionKey];
    let childSectionsArray = sectionDetails.childSections || [];
    if (!childSectionsArray.length) {
      return <></>;
    }

    const visibleChildrenArray: any[] = [];
    for (let i = 0; i < childSectionsArray.length; i++) {
      if (childSectionsArray[i].isVisible == true) {
        visibleChildrenArray.push(childSectionsArray[i]);
      }
    }
    let rowWidth = 100 / (visibleChildrenArray.length || 1) + '%';
    return (
      <>
        {childSectionsArray.map((item: any, index: number) => {
          if (item.isVisible == false) {
            return <></>;
          }
          let childId = id + '-' + item.sectionType + '-' + index.toString();
          let Element = <></>;
          switch (item.sectionType) {
            case 4: {
              Element = renderSocialLinks(childId, sectionKey, index);
              break;
            }
            case 5: {
              Element = renderTextSection(sectionKey, id, true, index);
              break;
            }
            case 6: {
              Element = NEW_renderImageSection(
                id,
                sectionKey,
                index,
                true,
                childId
              );
              break;
            }
            default: {
              Element = <></>;
              break;
            }
          }
          return (
            <Row
              display={item.isVisible == false ? 'none' : ''}
              key={'HEADER-' + index}
              width={rowWidth}
              float={'left' || positionToFloatMapping[item.sectionPosition]}
            >
              {Element}
            </Row>
          );
        })}
      </>
    );
  };

  const renderHeaderSection = (sectionKey: string, id: string) => {
    const sectionDetails = selectedLayoutData[sectionKey];
    let childSectionsArray = sectionDetails.childSections || [];
    if (!childSectionsArray.length) {
      return <></>;
    }

    const visibleChildrenArray: any[] = [];
    for (let i = 0; i < childSectionsArray.length; i++) {
      if (childSectionsArray[i].isVisible == true) {
        visibleChildrenArray.push(childSectionsArray[i]);
      }
    }

    let rowWidth = 100 / (visibleChildrenArray.length || 1) + '%';
    return (
      <>
        {childSectionsArray.map((item: any, index: number) => {
          if (item.isVisible == false) {
            return <></>;
          }
          let childId = id + '-' + item.sectionType + '-' + index.toString();
          let Element = <></>;
          switch (item.sectionType) {
            case 4: {
              Element = renderSocialLinks(childId, sectionKey, index);
              break;
            }
            case 5: {
              Element = renderTextSection(sectionKey, id, true, index);
              break;
            }
            case 9: {
              Element = renderLogoSection(sectionKey, id, true, index);
              break;
            }
            default: {
              Element = <></>;
              break;
            }
          }

          const additonalHeaderClass =
            visibleChildrenArray.length > 1
              ? 'multiple-header'
              : 'single-header';
          return (
            <Row
              className={`row-header-section ${additonalHeaderClass}`}
              display={item.isVisible == false ? 'none' : ''}
              key={'HEADER-' + index}
              width={rowWidth}
              float={'left' || positionToFloatMapping[item.sectionPosition]}
            >
              {Element}
            </Row>
          );
        })}
      </>
    );
  };

  return (
    <Container
      id={'LandingPageMainContainer'}
      devicePreview={devicePreview}
      className={devicePreview}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      pageBackgroundSize={pageBackgroundSize}
      pageBackgroundPosition={pageBackgroundPosition}
    >
      <ImageModal id={'myModal'}>
        <ImageModalClose id={'imageModalClose'}>&times;</ImageModalClose>
        <ImageModalContent id={'img01'}></ImageModalContent>
      </ImageModal>
      {selectedLayoutData && Object.keys(selectedLayoutData).length > 0 ? (
        Object.keys(selectedLayoutData).map((key, index) => {
          return renderSections(key, index);
        })
      ) : (
        <></>
      )}
    </Container>
  );
};
