export const calculateTotal = (data: any) => {
  if (!data) return { total: 0 };
  let count = 0;

  data.forEach((element: any) => {
    const value = parseInt(element['cnt'], 10) || 0;
    count += value;
  });

  return { total: count };
};

export const percentage = (partialValue: number, totalValue = 100) => {
  if (partialValue === 0 && totalValue === 0) {
    return 0;
  }
  return (100 * partialValue) / totalValue;
};
