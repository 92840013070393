import React from 'react';
import { FREQUENCY_TYPE } from './constants';
import { CustomReportModalFormikValues } from '../types';
import { useFormikContext } from 'formik';
import { CheckboxInput } from 'lib/components';
import { customReportsDayOptions } from '../timeframe/utils';
import { WeeklyContainer, WeeklyItem } from './style';
import { processWeeklyString } from 'app/pages/reports/util';

export const WeeklyFrequency = () => {
  const { setFieldValue, values } =
    useFormikContext<CustomReportModalFormikValues>();

  const currentWeekValue = processWeeklyString(values.recurrings.frequency);

  const onClickHandler = (day: { value: string; label: string }) => {
    if (currentWeekValue.includes(day.value)) {
      const weekValue = currentWeekValue
        .filter(item => item !== day.value)
        .join(',');
      return setFieldValue(
        'recurrings.frequency',
        `${FREQUENCY_TYPE.WEEKLY};${weekValue}`
      );
    }

    const weekValue = [...currentWeekValue, day.value].join(',');

    setFieldValue(
      'recurrings.frequency',
      `${FREQUENCY_TYPE.WEEKLY};${weekValue}`
    );
  };
  return (
    <WeeklyContainer>
      {customReportsDayOptions.map(day => (
        <WeeklyItem key={day.value}>
          <CheckboxInput
            checked={currentWeekValue.includes(day.value)}
            onChange={() => {
              onClickHandler(day);
            }}
          />

          <span
            onClick={() => {
              onClickHandler(day);
            }}
          >
            {day.label}
          </span>
        </WeeklyItem>
      ))}
    </WeeklyContainer>
  );
};
