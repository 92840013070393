import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'lib/components';

import { Link } from 'react-router-dom';

import { CheckboxInput } from 'lib/components';
import { useAuth } from 'lib/context/auth';
import { useAuthUserMutation } from 'lib/api/auth/useAuthUserMutation';
import { useLogoUrl } from 'lib/hooks/useLogoUrl';

const Layout = styled.div`
  height: 100vh;
  background-color: ${theme.palette.primaryDarkBlue};
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 48px;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ErrorMsg = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
`;

const Image = styled.img`
  padding-top: 48px;
  padding-bottom: 64px;
  cursor: pointer;
`;

const Forgot = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 6px;
`;

const Input = styled.input`
  margin-bottom: 16px;
  background: transparent;
  border: none;
  border-bottom: 2px solid #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  color: #ffffff !important;
  display: block;
  height: 38px;
  padding: 6px 12px;
  font-size: 18px;
  outline: none !important;

  &::placeholder {
    color: white;
    opacity: 1;
  }
`;

const Button = styled.input`
  width: 150px;
  height: 56px;
  background-image: radial-gradient(circle at 50% 64%, #ff8b22, #ee4842);
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0;
  margin-left: 20px;
  border: none;
  outline: none;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const CheckboxLabel = styled.label`
  color: #ff8b22;
  padding-left: 12px;
  cursor: pointer;
`;

export const LoginContent = () => {
  const { setJwtAndUpdateToken, whitelabel } = useAuth();

  const {
    isError: error,
    isLoading: loading,
    mutateAsync: authenticateUser,
    data: loginData,
  } = useAuthUserMutation();
  const [userName, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [remember, setRemember] = React.useState(false);
  const { logoUrl } = useLogoUrl();

  let history = useHistory();
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    authenticateUser({
      username: userName,
      password: password,
    });
  };

  React.useEffect(() => {
    if (loginData) {
      setJwtAndUpdateToken(loginData, remember);
      history.push('/');
    }
  }, [loginData]);

  return (
    <Layout>
      <Wrapper>
        <a href={whitelabel.homepage}>
          <Image src={logoUrl} />
        </a>
        <Form onSubmit={onSubmit}>
          {error && <ErrorMsg>Incorrect Login</ErrorMsg>}
          <Input
            name='username'
            placeholder='Username'
            type='text'
            required={true}
            value={userName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUserName(e.target.value)
            }
          />
          <Input
            name='password'
            placeholder='Password'
            type='password'
            required={true}
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
          <CheckboxWrapper>
            <CheckboxInput
              id='checkbox'
              checked={remember}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                let { checked } = e.target;
                setRemember(checked);
              }}
            />
            <CheckboxLabel htmlFor='checkbox'>
              Remember login information.
            </CheckboxLabel>
          </CheckboxWrapper>
          <Row>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <Forgot to={'/forgot-password'}>Forgot password?</Forgot>
                <Button
                  name='submit'
                  type='submit'
                  id='login-submit'
                  value='Login'
                />
              </>
            )}
          </Row>
        </Form>
      </Wrapper>
    </Layout>
  );
};
