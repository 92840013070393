import { useFormikContext } from 'formik';
import {
  ChildCustomer,
  ParentCustomer,
  User,
} from 'lib/api/multiLocations/multiLocationQuery';
import React, { useState } from 'react';
import { EditUserAccessModal } from '../../modals/EditUserAccessModal';
import { RemoveUserAccessModal } from '../../modals/RemoveUserAccessModal';
import { UserItem } from './UserItem';
interface CurrentItem {
  childCustomer: ChildCustomer;
  itemPosition: 'child' | 'parent';
}

export const EditUsersView = ({
  userIdx,
  user,
}: {
  userIdx: number;
  user: User;
}) => {
  const { values } = useFormikContext<ParentCustomer>();

  const [modals, setModals] = useState<
    null | 'remove-access' | 'edit-access' | 'add-access'
  >(null);
  const [currentItem, setCurrentItem] = useState<CurrentItem | null>(null);

  const openModalHandler = (
    type: 'remove-access' | 'edit-access' | 'add-access',
    item: CurrentItem
  ) => {
    setModals(type);
    setCurrentItem(item);
  };
  const closeModalHandler = () => {
    setModals(null);
    setCurrentItem(null);
  };

  const isEditOrCreating = modals === 'edit-access' || modals === 'add-access';

  return (
    <>
      <UserItem
        customerId={values.customerId}
        label={values.users[userIdx].email}
        id={`${values.users[userIdx].id}`}
        isParentItem
      />
      {values.childCustomers.map(childCustomer => {
        const mappedUser = childCustomer?.users?.find(
          childUser => childUser.parentUserId === user.id
        );
        return (
          <UserItem
            customerId={childCustomer.customerId}
            key={childCustomer.customerId}
            label={mappedUser?.username || null}
            id={mappedUser?.id.toString()}
            onEditClick={() =>
              openModalHandler('edit-access', {
                itemPosition: 'child',
                childCustomer,
              })
            }
            onRemoveClick={() =>
              openModalHandler('remove-access', {
                itemPosition: 'child',
                childCustomer,
              })
            }
            onCreateClick={() =>
              openModalHandler('add-access', {
                itemPosition: 'child',
                childCustomer,
              })
            }
          />
        );
      })}
      {modals === 'remove-access' && (
        <RemoveUserAccessModal
          closeModalHandler={closeModalHandler}
          parentUserId={values.users[userIdx].id}
          childCustomer={currentItem?.childCustomer}
        />
      )}
      {isEditOrCreating && (
        <EditUserAccessModal
          closeModalHandler={closeModalHandler}
          childCustomer={currentItem?.childCustomer}
          parentUserId={values.users[userIdx].id}
        />
      )}
    </>
  );
};
