import React from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { theme } from 'lib/style';
import { useField } from 'formik';
import {
  ParagraphNormal,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { IQuickQueryValues } from '../QuickFiltersBuilder';
import { Button } from 'react-covideo-common';

interface TextInputProps {
  name: string;
  id?: string;
  placeholder: string;
}

interface Props {
  onModalClose: () => void;
  submitForm: (() => Promise<void>) & (() => Promise<any>);
  values: IQuickQueryValues;
}

const TextInput = styled.input`
  display: flex;
  padding-left: 8px;
  height: 40px;
  width: 264px;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-right: 8px;
  margin-bottom: 10px; /*SUS-908 changes */
  box-sizing: border-box;
  &:focus {
    outline: 0;
    border-color:${theme.palette.covideoBlue100};
    
  }
  ::placeholder
  color: rgba(146, 151, 162, 1);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  color: rgba(146, 151, 162, 1);
}

::-ms-input-placeholder {
  color: rgba(146, 151, 162, 1);
}
`;

const Gap = styled.div<{
  gap?: string;
  center?: boolean;
  m?: string;
  direction?: string;
}>`
  display: flex;
  margin: ${({ m }) => m || '0px'};
  gap: ${({ gap }) => gap || '16px'};
  flex-direction: ${({ direction }) => direction || 'row'};
`;

const TextAreaInput = ({ ...props }: TextInputProps) => {
  const [field] = useField(props);
  return <TextInput {...field} {...props} />;
};

export const ModalSaveFilters = ({
  onModalClose,
  submitForm,
  values,
}: Props) => {
  return (
    <NewModal
      headerText='Save filters'
      closeModal={onModalClose}
      style={{
        content: {
          maxWidth: '582px',
        },
      }}
    >
      <Gap direction='column' gap='16px'>
        <Gap direction='column' gap='8px'>
          <ParagraphNormal color='rgba(78, 84, 97, 1)'>
            Save current filters as Quick filter.
          </ParagraphNormal>
        </Gap>
        <Gap direction='column' gap='8px'>
          <ParagraphSmall as='label' color='rgba(78, 84, 97, 1)'>
            Name
          </ParagraphSmall>
          <TextAreaInput
            name='name'
            placeholder='Give the new quick filter a name'
          />
        </Gap>
        <Gap direction='row-reverse'>
          <Button
            text={'Save filters'}
            onClick={submitForm}
            disabled={!values.name.length}
            type='submit'
          />
        </Gap>
      </Gap>
    </NewModal>
  );
};
