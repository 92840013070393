import { DropItem } from 'lib/api/droplr/types';
import { ButtonSwitch, Modal } from 'lib/components';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import { useOnClickOutside } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';
import { MdKeyboardBackspace } from 'react-icons/md';
import { CtaBoardsModalContent } from '../../../../app/pages/design/callsToAction/CtaBoardsModalContent';
import {
  CloseButtonWrap,
  FieldWrapper,
  Header,
  ItemTypeValues,
  ItemTypes,
} from './Components';
import { FilesTab } from './FilesTab';
import { VideosTab } from './VideosTab';

type Props = {
  handleModalClose: () => void;
  onSelect: (drop: DropItem, itemType: string) => void;
};

export const ChatsModal = (props: Props) => {
  const { userData } = useAuth();
  const { droplrAccess } = userData;
  const { handleModalClose, onSelect } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFile] = useState(null);

  const [itemType, setItemType] = useState(ItemTypeValues.VIDEO);
  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };
  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  useEffect(() => {
    setSearchQuery('');
  }, [itemType]);

  const handleSearch = async (newSearchTerm: string = '') => {
    setSearchQuery(newSearchTerm);
  };

  return (
    <Modal
      style={{ position: 'fixed' }}
      widthOnDesktop={'37%'}
      maxHeight={'550px'}
      overrideBodyHeight={true}
    >
      <div ref={ref} style={{ padding: '20px' }}>
        <Header>
          <Button
            icon={<MdKeyboardBackspace />}
            text={''}
            onClick={handleModalClose}
            variant='white'
          />
          <FieldWrapper width={'225px'}>
            {droplrAccess && (
              <ButtonSwitch
                defaultValue={itemType}
                values={ItemTypes}
                onChange={newValue => {
                  setItemType(newValue);
                }}
              />
            )}
          </FieldWrapper>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        {itemType === ItemTypeValues.VIDEO && (
          <VideosTab
            searchQuery={searchQuery}
            handleSearch={handleSearch}
            handleModalClose={handleModalClose}
            onSelect={onSelect}
          />
        )}
        {itemType === ItemTypeValues.FILE && (
          <FilesTab
            searchQuery={searchQuery}
            handleSearch={onSearch}
            handleModalClose={handleModalClose}
            onSelect={onSelect}
          />
        )}
        {itemType === ItemTypeValues.BOARD && (
          <>
            <CtaBoardsModalContent
              onSelect={onSelect}
              selectedFile={selectedFile}
              isQrModal={true}
              handleModalClose={handleModalClose}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
