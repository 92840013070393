import React from 'react';
import styled from 'styled-components/macro';
import { AutomotiveRole, VideoType } from 'lib/const';
import { InventoryItem, useAuth } from 'lib/context';
import { VideoTypeSales } from 'lib/const/VideoType';
import { VehicleSearch } from 'app/pages/library/videos/videoList/components/SendAndShare';
import { useFormikContext } from 'formik';
import { FormikDropdownField } from '../../../../../lib/components/formik/FormikDropdown';
import { UsersByAutmotiveRolesResponse } from 'lib/api/users/automotive/useUsersByAutomotiveRolesQuery';
import { UseQueryResult } from 'react-query';
import { DetailsPreviewRecordFormValues } from '../types';
import { FormikInputField } from 'lib/components/formik/FormikInputField';
import { FormikLabel } from 'lib/components/formik/FormikLabel';
import { FormikTextAreaField } from 'lib/components/formik/FormikTextAreaField';

const Wrapper = styled.div`
  margin-top: 24px;
`;

const getvideoTypeOptions = (isAutomotiveSales: boolean) =>
  Object.values(isAutomotiveSales ? VideoTypeSales : VideoType)
    .filter(Boolean)
    .map(videoType => ({
      value: videoType,
      label: videoType,
    }));

interface IVideoRequestDetailsProps {
  fetchSoldVehiclesForAutomotive?: boolean;
  results: UseQueryResult<UsersByAutmotiveRolesResponse[], unknown>[];
}

export const VideoRequestDetails = ({
  fetchSoldVehiclesForAutomotive = true,
  results,
}: IVideoRequestDetailsProps) => {
  const { userData } = useAuth();

  const { setFieldValue, values } =
    useFormikContext<DetailsPreviewRecordFormValues>();
  const showVideoRequestFields = userData.isAutomotive;
  const isAutomotiveSales = userData.isAutomotiveSalesRole;
  const isAutomotiveService = userData.isAutomotiveServiceRole;
  const isTechnician = userData.automotiveRole === AutomotiveRole.TECHNICIAN;

  const videoTypeOptions = getvideoTypeOptions(isAutomotiveSales);

  const advisorOptions = results.flatMap(result => {
    if (result.data) {
      return result.data.map(assignee => ({
        label: `${assignee.firstName} ${assignee.lastName}`,
        value: `${assignee.id}`,
      }));
    }
    return [];
  });

  if (!showVideoRequestFields) {
    return <></>;
  }

  return (
    <Wrapper>
      <FormikDropdownField
        name={`videoRequest.videoType`}
        label={'Video Type'}
        disabled={false}
        isRequired={false}
        options={videoTypeOptions}
      />

      {isAutomotiveSales && (
        <>
          <div style={{ marginBottom: 16 }}>
            <FormikLabel label='Search vehicle' isRequired={false} />
            <VehicleSearch
              onChangeVehicle={(item: InventoryItem) => {
                setFieldValue('videoRequest.vehicle', {
                  vin: item?.vin || '',
                  stockNumber: item?.stockNumber || '',
                });
              }}
              selectedVin={values.videoRequest?.vehicle?.vin || ''}
              placeholder='Search vehicle by name, VIN or stock number...'
              fetchSold={fetchSoldVehiclesForAutomotive}
            />
          </div>

          <FormikInputField
            name={`videoRequest.vehicle.vin`}
            label='VIN'
            isRequired={false}
            disabled={true}
          />
          <FormikInputField
            name={`videoRequest.vehicle.stockNumber`}
            label='Stock number'
            isRequired={false}
            disabled={true}
          />
        </>
      )}
      {isAutomotiveService && (
        <FormikDropdownField
          name={`videoRequest.advisorId`}
          label={'Assign To'}
          disabled={false}
          isRequired={isTechnician}
          options={advisorOptions}
        />
      )}
      {isAutomotiveService && (
        <FormikInputField
          name='videoRequest.repairOrderNumber'
          label='RO Number'
          isRequired={isTechnician}
        />
      )}
      <FormikInputField
        name='videoRequest.customerName'
        label='Customer Name'
        isRequired={false}
      />
      {isAutomotiveService && (
        <FormikTextAreaField
          name='videoRequest.note'
          label='Note'
          isRequired={false}
        />
      )}
    </Wrapper>
  );
};
