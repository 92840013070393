import { useQuery } from 'react-query';
import { wheelsTvKeys } from './wheelsTvKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { PaginationConstants } from 'lib/const/PaginationConstants';

export type WTVListItem = {
  wtvId: number;
  year: string;
  make: string;
  model: string;
  style: string;
  trim: string;
  wtvVideoId: string;
  bodyStyle: string;
  vehicleClass: string;
  animatedGifPath: string;
  createdAt: string;
};

export type WTVListParams = {
  limit: number;
  page?: number;
  search?: string;
  year?: number;
  order?: PaginationConstants;
  start: number;
};

export const getWTVVideos = async (
  props: WTVListParams
): Promise<{
  videos: WTVListItem[];
  count: number;
}> => {
  const params = {
    start: props.start,
    limit: props.limit,
    search: props.search,
    year: props.year,
    order: props.order,
  };

  return (
    await EXPRESS_API.get(`/wtv`, {
      params,
    })
  ).data;
};

export const useWheelsTvVideosQuery = (params: WTVListParams) => {
  return useQuery(wheelsTvKeys.all(), () => getWTVVideos(params), {
    refetchOnMount: true,
  });
};
