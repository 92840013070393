import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { List } from './List';
import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path='/admin/users' component={List} exact={true} />
      <Route
        path='/admin/users/entry/:fromScreen/:cusId'
        component={Details} // 1-Customers, 2-Users, 3-Resellers
        exact={true}
      />
      <Route path='/admin/users/:id/:fromScreen' component={Details} />
    </Switch>
  );
};
