import { EXPRESS_API } from 'configs/api/expressApi';
import { useToastError } from 'lib/hooks/useToastError';
import { useQuery } from 'react-query';
import { scheduleListKeys } from './scheduleListKeys';
import { GetSchedulesResponse, QueryParams } from './types';

const getSchedules = async ({
  start = 0,
  limit = 10,
}: QueryParams): Promise<GetSchedulesResponse> => {
  const response = await EXPRESS_API.get(`/schedule`, {
    params: {
      start,
      limit,
    },
  });
  return response.data;
};

export const useGetSchedulesQuery = (options: QueryParams) => {
  const { showError } = useToastError();
  return useQuery(scheduleListKeys.list(options), () => getSchedules(options), {
    onError: showError,
    refetchOnMount: true,
  });
};
