import { theme } from 'lib/style';
import styled from 'styled-components/macro';

interface TextParams {
  cursor?: string;
  onClick?: any;
}

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  margin-bottom: 32px;
`;

export const CategoryDropdownLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.palette.neutral60};
`;

export const Text = styled.div<TextParams>`
  color: ${theme.palette.coal};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding: 0px;
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
`;

export const Label = styled.div`
  height: auto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

export const ToggleContainer = styled.div<{ margin?: string }>`
  margin: ${props => (props.margin ? props.margin : '0px 0px 0px auto')};
  display: inline-flex;
  .react-toggle-track {
    width: 40px !important;
  }
  align-self: flex-end;
  width: max-content;
  justify-content: space-between;
`;
