import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import { components, SingleValueProps } from 'react-select';
import styled from 'styled-components/macro';
import { UserOption } from './UserDropdown';

const CustomSpanStyles = styled.span`
  width: 210px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const CustomUsersLabel = (user: UserOption) => {
  return (
    <Gap justifyContent='space-between' width='calc(100% - 6px)'>
      <CustomSpanStyles>
        {!!user.username ? user.username : user.label}{' '}
      </CustomSpanStyles>
      {user.value !== 'add' && (
        <CustomSpanStyles style={{ width: 85, textAlign: 'right' }}>
          {user.value}
        </CustomSpanStyles>
      )}
    </Gap>
  );
};
export const SingleValue = ({ ...props }: SingleValueProps<UserOption>) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value !== 'add' ? (
        <>
          <Gap justifyContent='space-between' width='calc(100% - 6px)'>
            <CustomSpanStyles style={{ width: 114 }}>
              {props.data.username}
            </CustomSpanStyles>
            <CustomSpanStyles style={{ width: 80, textAlign: 'right' }}>
              {props.data.value}
            </CustomSpanStyles>
          </Gap>
        </>
      ) : (
        <CustomSpanStyles>{props.data.label}</CustomSpanStyles>
      )}
    </components.SingleValue>
  );
};
