import React from 'react';
import { SliderItem, GetTrackProps } from 'react-compound-slider';

interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled?: boolean;
}

export const Track: React.FC<TrackProps> = ({
  source,
  target,
  getTrackProps,
  disabled,
}) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          height: 48,
          zIndex: 1,
          backgroundColor: disabled ? '#999' : 'rgba(0,0,0,.7)',
          cursor: 'pointer',
          left: `0`,
          width: `${source.percent + 1}%`,
          borderRadius: '6px 0 0 6px',
        }}
        {...getTrackProps()}
      />
      <div
        style={{
          position: 'absolute',
          height: 48,
          zIndex: 1,
          backgroundColor: disabled ? '#999' : 'transparent',
          cursor: 'pointer',
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
          borderRadius: 6,
        }}
        {...getTrackProps()}
      />
      <div
        style={{
          position: 'absolute',
          height: 48,
          zIndex: 1,
          backgroundColor: disabled ? '#999' : 'rgba(0,0,0,.7)',
          cursor: 'pointer',
          left: `${target.percent}%`,
          width: `${100 - target.percent}%`,
          borderRadius: '0 6px 6px 0',
        }}
        {...getTrackProps()}
      />
    </>
  );
};
