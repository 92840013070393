import { MainWrapper } from 'lib/components/styles/layout';
import { RecordSettingsProvider } from 'lib/context/record/provider';
import React from 'react';
import { ChooseRecordingOptions } from './ChooseRecordingOptions';

export const RecordHome = () => {
  return (
    <RecordSettingsProvider>
      <MainWrapper resetPadding className='recordHomeMainWrapper'>
        <ChooseRecordingOptions showHelpDesk showLegacyRecorderSwitch />
      </MainWrapper>
    </RecordSettingsProvider>
  );
};
