import { useMutation, useQueryClient } from 'react-query';
import { annotationsKeys } from './annotationsKeys';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';
import { wrapAnnotationWithProps } from './helper';

export type CreateAnnotationParams = {
  videoId: string;
  data: any;
  annotationId?: string;
};

export const createAnnotation = async ({
  videoId,
  data,
}: CreateAnnotationParams) => {
  const response = await EXPRESS_API.post(
    `/videos/${videoId}/annotations`,
    data
  );

  const annotation = response.data;
  const isCompanyAnnotation = !!data.customerId;

  if (isCompanyAnnotation) {
    return wrapAnnotationWithProps(annotation, { isCompanyAnnotation });
  }
  return wrapAnnotationWithProps(annotation);
};

export const useCreateAnnotationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (params: CreateAnnotationParams) => createAnnotation(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(annotationsKeys.annotations());
        successToast({ title: 'Annotation created successfully!' });
      },
      onError: () => {
        errorToast({
          title:
            'An error occurred creating the annotation, please try again later!',
        });
      },
    }
  );
};
