import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { multilocationKeys } from './multilocationsKeys';
import { useParams } from 'react-router-dom';
import { User } from './multiLocationQuery';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IUpdateChildUser {
  userId: string | number;
  customerId: number;
  parentUserId: string;
}

export const updateChildUserAccess = async (
  params: IUpdateChildUser
): Promise<User> => {
  const { parentUserId, ...body } = params;

  return (
    await EXPRESS_API.patch(`superadmin/multilocation/${parentUserId}/user`, {
      ...body,
    })
  ).data as User;
};

export const useUpdateChildUserAccessMutation = (
  closeModalHandler: () => void
) => {
  const queryClient = useQueryClient();
  const { customerId } = useParams() as { customerId: string };
  return useMutation(updateChildUserAccess, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(
        multilocationKeys.single(`${customerId}`)
      );
      successToast({ title: 'You have successfully updated user!' });
      closeModalHandler();
    },
  });
};
