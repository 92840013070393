import React from 'react';
import styled from 'styled-components/macro';
import { TableField } from 'lib/context';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
} from 'lib/components';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';
import dayjs from 'dayjs';
import { OptOutContact } from 'lib/api';
import { SortButtons } from '../../components/SortButtons';
import { Layout } from '../../index.styled';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { Button, useCovideoTheme } from 'react-covideo-common';
import { IoMdShareAlt } from 'react-icons/io';

const TableCell = styled.div<{
  width?: number;
  cursor?: 'pointer' | 'default' | 'auto';
  alignItems?: string;
  textAlign?: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

const PaginationWrapper = styled.div`
  width: 100%;
`;

const TextWrapper = styled.div<{ width?: number }>`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  padding-left: 0;
`;

const OptOutTitle = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${theme.palette.gray100};
`;

const NormalText = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${theme.palette.gray100};
`;

const ActionWrapper = styled.div`
  padding: 12px;
`;

type Props = {
  count: number;
  optOutReportsArray: OptOutContact[];
  page: number;
  size: number;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  tableFields: TableField[];
  setSelectedOptOutReport(report: OptOutContact): void;
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
  setShowOptInModal(optInModal: boolean): void;
};

export const OptOutTable = ({
  count,
  optOutReportsArray,
  setSelectedOptOutReport,
  page,
  size,
  sortKey,
  order,
  tableFields,
  onPaginationChange,
  onSortingUpdate,
  setShowOptInModal,
}: Props) => {
  const { colors } = useCovideoTheme();
  return (
    <Layout>
      <TableContextProvider
        total={count}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div
          className='scroll--container'
          style={{ overflowX: 'auto', paddingBottom: '20px' }}
        >
          <Table
            compact={true}
            relative={true}
            headers={[
              ...tableFields.map((item, index) => {
                return (
                  <TableCell
                    key={index}
                    width={
                      item.value === `firstName` || item.value === 'lastName'
                        ? 150
                        : 215
                    }
                    onClick={() => {}}
                  >
                    {item.label}
                    {item.sortable && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value =>
                          onSortingUpdate(item.sortKey ?? '', value)
                        }
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            fixColumnIndex='left'
            rows={optOutReportsArray.map(
              (optOutContact: OptOutContact, index: number) => ({
                key: index,
                columns: [
                  ...tableFields.map((item, index) => {
                    return (
                      <TableCell
                        key={index}
                        width={150}
                        textAlign={item.value === 'users' ? 'right' : 'left'}
                        onClick={() => {}}
                      >
                        {item.value === 'firstName' && (
                          <TextWrapper width={150}>
                            <OptOutTitle>
                              {optOutContact.user.firstName}
                            </OptOutTitle>
                          </TextWrapper>
                        )}
                        {item.value === 'lastName' && (
                          <TextWrapper width={150}>
                            <OptOutTitle>
                              {optOutContact.user.lastName}
                            </OptOutTitle>
                          </TextWrapper>
                        )}
                        {item.value === 'email' && (
                          <>
                            <TextWrapper width={215}>
                              <NormalText>
                                {optOutContact.user.email}
                              </NormalText>
                            </TextWrapper>
                          </>
                        )}
                        {item.value === 'optOutEmail' && (
                          <TextWrapper width={215} padding-left={10}>
                            <NormalText>{optOutContact.email}</NormalText>
                          </TextWrapper>
                        )}

                        {item.value === 'optOutDate' && (
                          <TextWrapper width={220}>
                            <NormalText>
                              {dayjs(optOutContact.dateOpted).format(
                                STANDARD_DATE_FORMAT
                              )}
                            </NormalText>
                          </TextWrapper>
                        )}
                      </TableCell>
                    );
                  }),
                  <TableCell
                    cursor='pointer'
                    textAlign='center'
                    alignItems='center'
                  >
                    <ActionWrapper>
                      <Button
                        variant='ghost'
                        text='Opt-In'
                        style={{
                          color: colors.primary[100],
                          border: 'none',
                        }}
                        icon={<IoMdShareAlt size={24} />}
                        onClick={() => {
                          setShowOptInModal(true);
                        }}
                      />
                    </ActionWrapper>
                  </TableCell>,
                ],
                onClick: () => {
                  setSelectedOptOutReport(optOutContact);
                },
              })
            )}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
