import * as React from 'react';
import styled from 'styled-components/macro';
import { IoMdClose, IoMdEye } from 'react-icons/io';
import { ButtonIconSwitch } from 'lib/components';
import { useLandingPageBuilderContext } from '../context';
import { ModalExitLandingPageBuilder } from './modal/ModalExitLandingPageBuilder';
import { useHistory } from 'react-router-dom';
import {
  MdLaptop,
  MdRedo,
  MdSmartphone,
  MdTablet,
  MdUndo,
} from 'react-icons/md';
import { ParagraphNormalBold } from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';
import { DevicePreviewType } from '../LandingPageBuilder';
import { Button } from 'react-covideo-common';

interface Props {
  undoActionHandler: any;
  redoActionHandler: any;
  setDevicePreview: React.Dispatch<React.SetStateAction<DevicePreviewType>>;
}

const Container = styled.section`
  flex: 1;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
`;

const ActionButtons = styled.div<{ opacity: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid rgba(0, 27, 83, 0.05);
  padding: 8px 16px 8px 10px;
  cursor: pointer;
  color: rgba(0, 27, 83, 1);
  opacity: ${({ opacity }) => (opacity ? '0.3' : '1')};
`;

const PREVIEWS = {
  desktop: 'desktop',
  tablet: 'tablet',
  mobile: 'mobile',
};
const previewOptions = [
  {
    value: PREVIEWS.desktop,
    icon: <MdLaptop size={20} />,
  },
  {
    value: PREVIEWS.tablet,
    icon: <MdTablet size={20} />,
  },
  {
    value: PREVIEWS.mobile,
    icon: <MdSmartphone size={20} />,
  },
];

export const PageBuilderActionMenu = (props: Props) => {
  const [showExitLandingPageBuilderModal, setShowExitLandingPageBuilderModal] =
    React.useState(false);
  const { undoActionHandler, redoActionHandler } = props;
  const {
    setIsEnabled,
    setSelectedStyle,
    setSelectedLayout,
    setSelectedLayoutData,
    setIsFullScreen,
    setPageBackgroundColor,
    setPageBackgroundImageUrl,
    setPageTitle,
    setPageId,
    setIsShared,
    setIsDefault,
    setAllowDuplicates,
    setDesignId,
    setSelectedStyleProperties,
    setDefaultCta,
    setDefaultSocialLinks,
    setElementToEdit,
    setElementIndex,
    setElementSectionKey,
    isFullScreen,
    setShowSaveDesignModal,
    setIsUpdateMode,
    setIsImageUploadInProgress,
    undoState,
    redoState,
  } = useLandingPageBuilderContext();

  const history = useHistory();

  const resetLandingPageBuilder = () => {
    setIsEnabled(false);
    setSelectedStyle('');
    setSelectedLayout('');
    setSelectedLayoutData({});
    setIsFullScreen(false);
    setPageBackgroundColor(null);
    setPageBackgroundImageUrl(null);
    setPageTitle('');
    setPageId(null);
    setDesignId(null);
    setAllowDuplicates(false);
    setIsDefault(false);
    setIsShared(false);
    setSelectedStyleProperties({});
    setElementToEdit(null);
    setElementIndex('');
    setElementSectionKey('');
    setDefaultCta(null);
    setDefaultSocialLinks(null);
    setIsUpdateMode(false);
    setIsImageUploadInProgress(false);
    history.push('/design/landing-pages');
  };

  return (
    <Container>
      {showExitLandingPageBuilderModal == true ? (
        <ModalExitLandingPageBuilder
          onDelete={() => {
            resetLandingPageBuilder();
          }}
          modalLoading={false}
          onClose={() => {
            setShowExitLandingPageBuilderModal(false);
          }}
        />
      ) : (
        <></>
      )}
      <ButtonWrapper>
        <IoMdClose
          size={24}
          onClick={() => {
            setShowExitLandingPageBuilderModal(true);
          }}
          color={'#272A32'}
        />
        <div style={{ display: 'flex', gap: '16px' }}>
          <ActionButtons
            onClick={undoState?.length <= 1 ? null : undoActionHandler}
            opacity={undoState?.length <= 1}
          >
            <MdUndo size={26} color='currentColor' />
            <ParagraphNormalBold color='inherit'>Undo</ParagraphNormalBold>
          </ActionButtons>
          <ActionButtons
            onClick={redoState?.length == 0 ? null : redoActionHandler}
            opacity={redoState?.length == 0}
          >
            <ParagraphNormalBold color='inherit'>Redo</ParagraphNormalBold>
            <MdRedo size={26} color='currentColor' />
          </ActionButtons>
        </div>
      </ButtonWrapper>
      <ButtonIconSwitch
        defaultValue={PREVIEWS.desktop}
        onChange={e => props.setDevicePreview(e as DevicePreviewType)}
        values={previewOptions}
      />

      <Gap>
        <Button
          variant='secondary'
          icon={<IoMdEye size={16} />}
          text={'Preview'}
          onClick={() => {
            history.push('/design/landing-page-preview');
            setIsFullScreen(!isFullScreen);
          }}
        />
        <Button
          text={'Save design'}
          onClick={() => setShowSaveDesignModal(true)}
        />
      </Gap>
    </Container>
  );
};
