import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { multilocationKeys } from './multilocationsKeys';
import { useParams } from 'react-router-dom';
import { User } from './multiLocationQuery';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IAddChildUser {
  userId: string | number;
  parentCustomerId: string | number;
}

const addChildUser = async (params: IAddChildUser): Promise<User> => {
  const { userId, parentCustomerId } = params;

  return (
    await EXPRESS_API.post(
      `superadmin/multilocation/${parentCustomerId}/user`,
      { userId }
    )
  ).data;
};

export const useAddChildUserMutation = (closeModalHandler: () => void) => {
  const queryClient = useQueryClient();
  const { customerId } = useParams() as { customerId: string };
  return useMutation(addChildUser, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(
        multilocationKeys.single(`${customerId}`)
      );
      successToast({ title: 'You have successfully added user!' });
      closeModalHandler();
    },
  });
};
