import React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import { CheckboxInput, RadioInput } from 'lib/components';
import { SelectorItem } from '../types';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  max-width: 100%;
  gap: 16px;
`;
type IconContainerProps = {
  height: string;
  width: string;
  totalNumberOfItems: number;
};
const ItemContainer = styled.div<IconContainerProps>`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  gap: 16px;
  height: ${props => props.height};
  width: ${props => props.width};
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.covideoOrange100};
  border-radius: 6px;
  flex: none;
  flex-grow: 0;
  &:hover {
    cursor: pointer;
  }
  ${theme.mediaQueryMaxWidth.sm} {
    width: calc(
      100% /
        ${props =>
          props.totalNumberOfItems < 4
            ? `${props.totalNumberOfItems} - ${
                ((props.totalNumberOfItems - 1) * 16) / props.totalNumberOfItems
              }px`
            : '4 - 12px'}
    );
  }
  ${theme.mediaQueryMaxWidth.xs} {
    width: calc(
      100% /
        ${props =>
          props.totalNumberOfItems < 3
            ? `${props.totalNumberOfItems} - ${
                ((props.totalNumberOfItems - 1) * 16) / props.totalNumberOfItems
              }px`
            : '3 - 11px'}
    );
  }
  ${theme.mediaQueryMaxWidth.mb} {
    width: calc(
      100% /
        ${props =>
          props.totalNumberOfItems < 2
            ? `${props.totalNumberOfItems} - ${
                ((props.totalNumberOfItems - 1) * 16) / props.totalNumberOfItems
              }px`
            : '2 - 8px'}
    );
  }
`;
const TitleCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px;
  gap: 8px;
  height: 56px;
  flex: none;
  flex-grow: 0;
`;
const Title = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${theme.palette.gray100};
  white-space: nowrap;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
const IconWrapper = styled.div`
  margin: 0 16px;
`;
const RadioInputField = styled(RadioInput)`
  margin: auto;
`;
const InfoText = styled.span`
  margin: -30px 0 8px 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.covideoBlue60};
`;

type Props = {
  itemHeight: string;
  itemWidth: string;
  items: SelectorItem[];
  multiselect?: boolean;
  infoText?: string;
  selectedItem?: string; // Use when multiselect = false
  setSelectedValue?: (value: string) => void; // Use when multiselect = false
  selectedItems?: string[]; // Use when multiselect = true
  setSelectedValues?: (value: string[]) => void; // Use when multiselect = true
};
export const ItemSelector = (props: Props) => {
  const {
    items = [],
    itemWidth,
    itemHeight,
    selectedItem = '',
    infoText = '',
    setSelectedValue,
    multiselect = false,
    selectedItems = [],
    setSelectedValues,
  } = props;
  const themes = useTheme();

  return (
    <>
      {infoText !== '' && <InfoText>{infoText}</InfoText>}
      <Container>
        {items.map((item, index) => (
          <ItemContainer
            onClick={() => {
              if (multiselect) {
                let checked = selectedItems.includes(item.value);
                if (!checked) {
                  if (setSelectedValues)
                    setSelectedValues([...selectedItems, item.value]);
                  return;
                }
                if (setSelectedValues)
                  setSelectedValues(
                    selectedItems.filter((e: any) => e != item.value)
                  );
                return;
              }
              if (setSelectedValue) setSelectedValue(item.value);
            }}
            key={`item-container-${index}`}
            height={itemHeight}
            width={itemWidth}
            totalNumberOfItems={items.length}
          >
            <IconWrapper>
              {item.iconSrc && item.iconSrc !== '' && (
                <img
                  src={item.iconSrc}
                  alt={item.value}
                  width={48}
                  height={48}
                />
              )}
            </IconWrapper>
            <TitleCheckboxWrapper>
              <Title>{item.value}</Title>
              {multiselect && (
                <CheckboxInput
                  checked={selectedItems.includes(item.value)}
                  onChange={(e: React.SyntheticEvent) => {
                    let { checked } = e.target as HTMLInputElement;
                    if (checked) {
                      if (setSelectedValues)
                        setSelectedValues([...selectedItems, item.value]);
                      return;
                    }
                    if (setSelectedValues)
                      setSelectedValues(
                        selectedItems.filter((e: any) => e != item.value)
                      );
                  }}
                  onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.stopPropagation();
                  }}
                />
              )}
              {!multiselect && (
                <RadioInputField
                  name={item.value}
                  value={item.value}
                  checkedColor={themes.colors.primary[100]}
                  checked={selectedItem === item.value}
                  onClick={() => {
                    if (setSelectedValue) setSelectedValue(item.value);
                  }}
                  onChange={() => {}}
                />
              )}
            </TitleCheckboxWrapper>
          </ItemContainer>
        ))}
      </Container>
    </>
  );
};
