import React from 'react';
import styled from 'styled-components/macro';
import { CheckboxInput } from 'lib/components';
import { useAuth, VideoRequest } from 'lib/context';
import {
  CheckboxInputWrapper,
  CheckboxLabel,
  HyperlinkText,
  Input,
  Label,
  NotifyMeTooltip,
} from '../';
import { Tooltip } from 'react-covideo-common';
import { useHistory } from 'react-router';

type FormGroupProps = {
  margin?: string;
  width?: string;
};
const FormGroup = styled.div<FormGroupProps>`
  margin: ${props => (props.margin ? props.margin : '16px 0 0 0')};
  width: ${props => (props.width ? props.width : 'auto')};
`;

type Props = {
  showNotifyToggle?: boolean;
  videoRequest?: VideoRequest;
  changeVideoRequest?: (videoRequest: VideoRequest) => void;
  notify?: boolean;
  setNotify?: Function;
  allowReactions?: boolean;
  reactionsEnabled?: boolean;
  videoReplyEnabled?: boolean;
  setAllowReactions?: Function;
  allowVideoReply?: boolean;
  setAllowVideoReply?: Function;
};
export const SendShareVideoForm = ({
  showNotifyToggle = true,
  videoRequest,
  changeVideoRequest,
  allowReactions,
  setAllowReactions,
  allowVideoReply,
  setAllowVideoReply,
  reactionsEnabled = false,
  videoReplyEnabled = false,
  notify = false,
  setNotify,
}: Props) => {
  const { userData } = useAuth();
  const history = useHistory();

  const handleRepairOrderNumberChange = (repairOrderNumber: string) => {
    if (!videoRequest || !changeVideoRequest) {
      return;
    }
    changeVideoRequest({
      ...videoRequest,
      repairOrderNumber: repairOrderNumber,
    });
  };

  return (
    <>
      {userData.isAutomotiveServiceRole &&
        !!videoRequest &&
        !!changeVideoRequest && (
          <FormGroup>
            <Label>RO#</Label>
            <Input
              onChange={(e: any) =>
                handleRepairOrderNumberChange(e.target.value)
              }
              value={videoRequest.repairOrderNumber}
              hasError={videoRequest.repairOrderNumber === ''}
            />
          </FormGroup>
        )}
      {showNotifyToggle && (
        <FormGroup margin='24px 0 0 0'>
          <Tooltip
            position={'top' as any}
            hoverable={true}
            extendStyles={{
              popup: {
                left: '12px',
              },
            }}
            popup={
              <NotifyMeTooltip>
                Adjusting the 'Notify me' setting will override your general
                settings and apply the new preference to this video.
              </NotifyMeTooltip>
            }
          >
            <CheckboxInputWrapper key='check-notify-on-view'>
              <CheckboxInput
                id='check-notify-on-view'
                width='24px'
                blueCheck={true}
                checked={notify}
                onChange={(e: React.SyntheticEvent) => {
                  let { checked } = e.target as HTMLInputElement;
                  setNotify && setNotify(checked);
                }}
              />
              <CheckboxLabel htmlFor='check-notify-on-view'>
                Notify me when video is viewed
              </CheckboxLabel>
            </CheckboxInputWrapper>
          </Tooltip>
          <HyperlinkText
            onClick={() =>
              history.push('/profile/Profile_EditUserInfo?tab=preferences')
            }
          >
            Adjust Notification Settings
          </HyperlinkText>
        </FormGroup>
      )}
      {reactionsEnabled && (
        <FormGroup margin='18px 0 0'>
          <CheckboxInputWrapper>
            <CheckboxInput
              id={'check-reactions-comments'}
              width={'24px'}
              blueCheck={true}
              checked={allowReactions}
              onChange={(e: React.SyntheticEvent) => {
                let { checked } = e.target as HTMLInputElement;
                setAllowReactions && setAllowReactions(checked);
              }}
            />
            <CheckboxLabel htmlFor='check-reactions-comments'>
              Enable video reactions & comments
            </CheckboxLabel>
          </CheckboxInputWrapper>
        </FormGroup>
      )}
      {videoReplyEnabled && (
        <FormGroup margin='0'>
          <CheckboxInputWrapper>
            <CheckboxInput
              id={'check-video-reply'}
              width={'24px'}
              blueCheck={true}
              checked={allowVideoReply}
              onChange={(e: React.SyntheticEvent) => {
                let { checked } = e.target as HTMLInputElement;
                setAllowVideoReply && setAllowVideoReply(checked);
              }}
            />
            <CheckboxLabel htmlFor='check-video-reply'>
              Viewer can reply with video
            </CheckboxLabel>
          </CheckboxInputWrapper>
        </FormGroup>
      )}
    </>
  );
};
