import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { debounce } from 'lodash';
import queryString from 'query-string';
import { useLocation, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router';
import { MdFileDownload, MdEmail, MdKeyboardArrowLeft } from 'react-icons/md';
import {
  LoadingIndicator,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { reportData, reportTypes } from 'lib/const';
import {
  downloadReportData,
  getCustomReport,
  getEmailsForReport,
} from 'lib/api';
import { useAuth } from 'lib/context';
import {
  calculateDatesForRange,
  ColumnChart,
  DateRangeSelector,
  SendReportModal,
  timeRangeConstants,
} from '../../components';
import VideoAttributeList from './VideoAttributeDetails/VideoAttributeList';
import { NotFound } from 'app/pages/notFound/NotFound';
import { serverTimeToUserTime } from 'lib/utils/time';
import { Gap, HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';
import { formatSecondsToTime } from 'lib/utils/functions';

dayjs.extend(customParseFormat);

const parseDateFormats = [
  'MM/DD/YYYY h:mm A',
  'MM/DD/YYYY h:mm:ss A',
  'YYYY-MM-DD h:mm A',
];

const TableCell = styled.div`
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

const ArrowWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface MatchParams {
  reportType: string;
}

export const Details = withRouter((props: RouteComponentProps<MatchParams>) => {
  const { userData } = useAuth();
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);

  const storedDateRange = getDateRangeFromLocalstorage();

  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );
  const [startDate, setStartDate] = React.useState<Date>(start);
  const [endDate, setEndDate] = React.useState<Date>(end);
  const [dateRange, setDateRange] = React.useState(initRange);

  const [showSendReportModal, setShowSendReportModal] = React.useState(false);

  const [contacts, setContacts] = React.useState<
    { [key: string]: number | string | boolean }[] | null
  >([]);
  const [filteredContacts, setFilteredContacts] = React.useState<
    { [key: string]: number | string | boolean }[]
  >([]);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const history = useHistory();
  const { reportType } = props.match.params;
  const { showError } = useToastError();

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  const downloadReport = () => {
    if (report) {
      try {
        return downloadReportData(
          `${report.url}/download`,
          `${reportType}.csv`,
          startDate,
          endDate,
          !queryParams.reportId ? users : [],
          undefined,
          undefined,
          !!queryParams.reportId
            ? {
                reportId: queryParams.reportId,
              }
            : undefined
        );
      } catch (error) {
        showError(error);
      }
    }
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const report = reportData[reportType];

  const initialReports = [];

  if (report) {
    initialReports.push({
      value: reportType,
      label: report.label,
    });
  }

  const loadContacts = React.useCallback(
    debounce(async (start: Date, end: Date, usersArray: string[]) => {
      setLoading(true);
      try {
        const data = await getEmailsForReport(
          `${report.url}/details`,
          start,
          end,
          usersArray,
          undefined,
          queryParams.reportId
        );
        data.reverse();
        data.forEach((d: any) => {
          d.date = dayjs(d.date, parseDateFormats).format(STANDARD_DATE_FORMAT);

          if (d.lastViewDate) {
            d.lastViewDate = dayjs(d.lastViewDate, parseDateFormats).format(
              STANDARD_DATE_FORMAT
            );
          }
        });
        setContacts(data);
        setLoading(false);
      } catch (error) {
        setContacts(null);
        setLoading(false);
        showError(error);
      }
    }, 200),
    []
  );

  const isVideoAttributes = reportType.includes(reportTypes.VIDEO_ATTRIBUTES);

  React.useEffect(() => {
    if (queryParams && !isVideoAttributes) {
      if (queryParams.reportId) {
        getCustomReport(queryParams.reportId).then(report => {
          setUsers(report.users);
        });
      } else if (queryParams.users) {
        setUsers(queryParams.users);
      } else if (queryParams.user) {
        /*  SUS-607 Engagement issue fix check */
        const userId = queryParams.user.split(' ');
        setUsers(userId);
      }
    }
  }, [queryParams.reportId, queryParams.users]);

  React.useEffect(() => {
    if (!isVideoAttributes) {
      const start = page * size;
      if (contacts) {
        setFilteredContacts([...contacts.slice(start, start + size)]);
      }
    }
  }, [contacts, page, size]);

  React.useEffect(() => {
    if (!isVideoAttributes) loadContacts(startDate, endDate, users || []);
  }, [startDate, endDate, users]);

  if (!contacts || !report || !report.label) {
    return <NotFound />;
  }

  if (isVideoAttributes)
    return (
      <MainWrapper resetPadding={false}>
        <VideoAttributeList />
      </MainWrapper>
    );

  return (
    <MainWrapper resetPadding={false}>
      <>
        <Gap gap='2px' m='0 0 32px 0'>
          <ArrowWrapper onClick={() => history.goBack()}>
            <MdKeyboardArrowLeft size={24} />
          </ArrowWrapper>
          <Heading>{report.label}</Heading>
        </Gap>
        <>
          <HeaderWrapper>
            <DateRangeSelector
              initialValue={dateRange}
              onDateRangeChange={onDateRangeChange}
            />
            <Gap>
              <Button
                text='Send'
                icon={<MdEmail size={20} />}
                onClick={() => setShowSendReportModal(true)}
              />
              <Button
                text='Download'
                icon={<MdFileDownload size={20} />}
                onClick={() => downloadReport()}
              />
            </Gap>
          </HeaderWrapper>

          <ColumnChart
            reportType={reportType}
            from={startDate}
            to={endDate}
            dateRange={dateRange}
            users={users}
            customer={queryParams.customer || ''}
            reportId={queryParams.reportId}
          />

          <div
            style={{
              marginTop: '100px',
            }}
          >
            {loading && <LoadingIndicator isLoading={loading} height='300px' />}
            {!loading && (
              <>
                {!!contacts.length && (
                  <TableContextProvider
                    total={contacts.length}
                    onChange={onPaginationChange}
                    initPage={page}
                    initSize={size}
                  >
                    <div style={{ overflowX: 'auto' }}>
                      <Table
                        compact={true}
                        headers={[
                          ...report.tableFields.map(item => {
                            return <TableCell>{item.label}</TableCell>;
                          }),
                        ]}
                        hoverable={false}
                        rows={filteredContacts.map(
                          (contact: any, index: number) => ({
                            key: index,
                            columns: [
                              ...report.tableFields.map(item => {
                                // status is viewed if it also has lastViewDate field
                                if (item.value === 'status') {
                                  return (
                                    <TableCell>
                                      {!!contact.viewed &&
                                      !!contact.lastViewDate
                                        ? 'viewed'
                                        : contact.status}
                                    </TableCell>
                                  );
                                }
                                if (item.value === 'cta') {
                                  if (!contact.linkText) {
                                    return <TableCell>-</TableCell>;
                                  }
                                  return (
                                    <TableCell>
                                      <a
                                        target='_blank'
                                        title={contact.linkText}
                                        href={contact.linkURL}
                                        rel='noopener noreferrer'
                                      >
                                        {contact.linkText}
                                      </a>
                                    </TableCell>
                                  );
                                }
                                if (item.value === 'recordedAt') {
                                  const clientTime = serverTimeToUserTime(
                                    contact.recordedAt,
                                    userData,
                                    `${STANDARD_DATE_FORMAT} hh:mm:ss A`
                                  );
                                  return <TableCell>{clientTime}</TableCell>;
                                }
                                if (item.value === reportTypes.TIME_WATCHED) {
                                  return (
                                    <TableCell>
                                      {formatSecondsToTime(contact[item.value])}
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell>
                                    {contact[item.value] || '-'}
                                  </TableCell>
                                );
                              }),
                            ],
                            onClick: () => {},
                          })
                        )}
                      />
                    </div>
                    <TableFooter>
                      <TablePaginationNew />
                      <TablePaginationSizeNew globalName='contact_list' />
                    </TableFooter>
                  </TableContextProvider>
                )}
                {!contacts.length && (
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    No data to show.
                  </div>
                )}
              </>
            )}
          </div>
        </>
        {showSendReportModal && (
          <SendReportModal
            handleModalClose={() => {
              setShowSendReportModal(false);
            }}
            startDate={startDate}
            endDate={endDate}
            reportUrl={report.url}
            range={dateRange}
            extraParams={{
              reportId: queryParams.reportId,
            }}
          />
        )}
      </>
    </MainWrapper>
  );
});
