import * as React from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { BsPlus } from 'react-icons/bs';
import { MdModeEdit, MdDeleteForever } from 'react-icons/md';
import emptyDesign from 'assets/icons/design/emptyDesignBg.svg';
import {
  ModalDeleteWebsiteOverlay,
  ModalWebsiteOverlayAddOrEdit,
} from 'lib/components/modal';

import { theme } from 'lib/style';
import { SimpleRow } from 'lib/components';

import {
  setDefaultWebsiteOverlay,
  getWebsiteOverlays,
  createWebsiteOverlay,
  updateWebsiteOverlay,
  deleteWebsiteOverlay,
} from 'lib/api/designApi';
import {
  GetWebsiteOverlaysResponse,
  ListWebsiteOverlay,
  SetDefaultWebsiteOverlayResponse,
} from 'lib/api';

import { useAuth } from 'lib/context';
import {
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { HelpDesk } from 'lib/components/helpDesk';
import { EHelpDesk } from 'lib/components/helpDesk/utils';
import { HeaderWrapper, MainWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { useQueryClient } from 'react-query';
import { OVERLAY_QUERY } from 'lib/const/SendAndShare';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';

interface ModalWithData {
  toOpen: 'add' | 'edit' | 'delete' | 'none';
  data?: ListWebsiteOverlay;
}

interface OnCreateParams {
  title: string;
  url: string;
  isDefault: boolean;
  fileThumbnail: string;
  fileTitle: string;
  type: string;
  itemType: string;
}

interface OnUpdateParams extends OnCreateParams {
  id: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
  margin-top: 32px;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  position: fixed;
  height: 50vh;
  width: 50vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${emptyDesign});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${theme.palette.white};
  width: 100%;
  height: 430px;
`;

const EmptyTitle = styled.p`
  font-size: 18px;

  font-weight: 700;
  margin-bottom: 32px;
`;

const WebsiteOverlays = () => {
  const themes = useTheme();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [modalLoading, setModalLoading] = React.useState<boolean>(false);
  const [modalWithData, setModalWithData] = React.useState<ModalWithData>({
    toOpen: 'none',
  });
  const [items, setItems] = React.useState<ListWebsiteOverlay[]>([]);

  const { userData } = useAuth();
  const queryClient = useQueryClient();
  const { showError } = useToastError();

  const runFetch = async () => {
    setLoading(true);
    const res = await getWebsiteOverlays({
      start: 0,
      limit: 0,
      search: '',
    }).catch(err => err);
    if (res instanceof Error) {
      setLoading(false);
      return;
    }

    const { websiteUrls, default: dft } = res as GetWebsiteOverlaysResponse;
    const listItems = websiteUrls.map(t => {
      const listItem = { ...t, isDefault: false };
      if (dft && listItem.id === dft.id) {
        listItem.isDefault = true;
      } else {
        listItem.isDefault = false;
      }
      return listItem;
    });
    setLoading(false);

    listItems.unshift({
      title: 'Website Title',
      url: 'URL',
      isDefault: false,
      defaultflag: 0,
      id: -1,
      fileThumbnail: '',
      fileTitle: '',
      type: '',
      itemType: '',
    });

    setItems(listItems);
  };

  const onCloseModal = () =>
    setModalWithData({ toOpen: 'none', data: undefined });

  const onItemDeletion = async (id: number) => {
    setModalLoading(true);
    try {
      await deleteWebsiteOverlay(id);
      setModalLoading(false);
      onCloseModal();
      setItems(items.filter(i => i.id !== id));
      successToast({ title: 'Website Url deleted successfully' });
      queryClient.invalidateQueries([OVERLAY_QUERY]);
    } catch (error) {
      setModalLoading(false);
      showError(error || 'Something went wrong deleting Website Url');
    }
  };

  const onCreate = async (params: OnCreateParams) => {
    setModalLoading(true);
    try {
      const res = await createWebsiteOverlay(params);
      const newItem = {
        ...params,
        id: res.websiteUrl.id,
        defaultflag: res.websiteUrl.defaultflag,
        isDefault: !!res.websiteUrl.defaultflag,
        fileTitle: res.websiteUrl.fileTitle || '',
        fileThumbnail: res.websiteUrl.fileThumbnail || '',
        type: res.websiteUrl.type || 'url',
        itemType: res.websiteUrl.itemType || '',
      };
      let newItems = items;
      if (newItem.isDefault) {
        newItems = items.map(i => {
          i.isDefault = false;
          i.defaultflag = 0;
          return i;
        });
      }
      newItems.push(newItem);
      setModalLoading(false);
      setItems(newItems);
      onCloseModal();
      successToast({
        title: 'Website URL successfully created!',
      });
      queryClient.invalidateQueries([OVERLAY_QUERY]);
    } catch (error) {
      setModalLoading(false);
      showError(error || 'Something went wrong setting Website Url as default');
    }
  };

  const onUpdate = async (params: OnUpdateParams) => {
    setModalLoading(true);
    const updateData = {
      id: params.id,
      title: params.title,
      url: params.url,
      isDefault: params.isDefault,
      fileTitle: params.fileTitle || '',
      fileThumbnail: params.fileThumbnail || '',
      type: params.type || 'url',
      itemType: params.itemType || '',
    };
    try {
      await updateWebsiteOverlay(updateData);
      const itemsWithUpdate = items.map(overlay => {
        if (overlay.id !== params.id && params.isDefault) {
          overlay.isDefault = false;
          overlay.defaultflag = 0;
        } else if (overlay.id === params.id && params.isDefault) {
          overlay.isDefault = true;
          overlay.defaultflag = 1;
          overlay.title = params.title;
          overlay.url = params.url;
          overlay.fileTitle = params.fileTitle;
          overlay.type = params.type;
          overlay.itemType = params.itemType;
          overlay.fileThumbnail = params.fileThumbnail;
        } else if (overlay.id === params.id) {
          overlay.title = params.title;
          overlay.url = params.url;
          overlay.fileTitle = params.fileTitle;
          overlay.type = params.type;
          overlay.itemType = params.itemType;
          overlay.fileThumbnail = params.fileThumbnail;
        }

        return overlay;
      });
      setModalLoading(false);
      setItems(itemsWithUpdate);
      onCloseModal();
      successToast({
        title: 'Website URL successfully updated!',
      });
      queryClient.invalidateQueries([OVERLAY_QUERY]);
    } catch (error) {
      setModalLoading(false);
      showError(error || 'Something went wrong setting Website Url as default');
    }
  };

  const setAsDefault = async (id: number): Promise<void> => {
    try {
      const response: SetDefaultWebsiteOverlayResponse =
        await setDefaultWebsiteOverlay(id);

      if (response.newDefaultWebsiteOverlayId) {
        const newDefaultId = response.newDefaultWebsiteOverlayId;
        const listItems = items.map(t => {
          const listItem = { ...t, isDefault: false };
          if (listItem.id === newDefaultId) {
            listItem.isDefault = true;
          } else {
            listItem.isDefault = false;
          }
          return listItem;
        });
        setItems(listItems);
      }
    } catch (error) {
      showError(error || 'Something went wrong setting Website Url as default');
    }
  };

  const RowOptionButtons = React.useCallback((item: ListWebsiteOverlay) => {
    return [
      <Button
        variant='secondary'
        icon={<MdModeEdit size={24} />}
        onClick={() => setModalWithData({ toOpen: 'edit', data: item })}
      />,
      <Button
        variant='destructive'
        icon={<MdDeleteForever size={24} />}
        onClick={() => setModalWithData({ toOpen: 'delete', data: item })}
      />,
    ];
  }, []);

  React.useEffect(() => {
    runFetch();
  }, []);

  React.useEffect(() => {
    if (userData && userData.user && userData.user.packageDetails) {
      const userPackageDetails = userData.user.packageDetails;
      if (userPackageDetails.id) {
        const isOverlayAllowed = checkIfFeatureIsEnabled(
          userData,
          productFeature.WEBSITE_OVERLAY
        );
        if (!isOverlayAllowed) {
          window.location.href = '/upgrade-plan';
        }
      }
    }
  }, []);

  return (
    <MainWrapper resetPadding>
      {modalWithData.toOpen === 'add' && (
        <ModalWebsiteOverlayAddOrEdit
          type={modalWithData.toOpen}
          modalLoading={modalLoading}
          onClose={onCloseModal}
          onMainButtonClick={onCreate}
        />
      )}
      {modalWithData.toOpen === 'edit' && modalWithData.data && (
        <ModalWebsiteOverlayAddOrEdit
          type={modalWithData.toOpen}
          modalLoading={modalLoading}
          data={modalWithData.data}
          onClose={onCloseModal}
          onMainButtonClick={onUpdate}
        />
      )}
      {modalWithData.toOpen === 'delete' && modalWithData.data && (
        <ModalDeleteWebsiteOverlay
          data={modalWithData.data}
          modalLoading={modalLoading}
          onClose={onCloseModal}
          onDelete={onItemDeletion}
        />
      )}
      <HelpDesk name={EHelpDesk.WEBSITE_OVERLAY} />
      <HeaderWrapper>
        <Heading>Website Overlays</Heading>
        <Button
          text={'Add New'}
          icon={<BsPlus />}
          onClick={() => setModalWithData({ toOpen: 'add' })}
        />
      </HeaderWrapper>
      <Content>
        {loading && (
          <LoaderWrapper>
            <MoonLoader
              size={64}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        {items.length > 0
          ? items.map((item, index) => {
              return (
                <SimpleRow
                  key={index}
                  isHeader={item.id === -1}
                  isDefault={item.isDefault}
                  fields={[item.title, item.url]}
                  mainButtonText={'Set as Default'}
                  onClickMainButton={() => setAsDefault(item.id)}
                  optionButtons={RowOptionButtons(item)}
                />
              );
            })
          : !loading && (
              <EmptyListContainer>
                <EmptyTitle>You don’t have any Overlay URLs</EmptyTitle>
                <Button
                  text={'Add New'}
                  icon={<BsPlus />}
                  onClick={() => setModalWithData({ toOpen: 'add' })}
                />
              </EmptyListContainer>
            )}
      </Content>
    </MainWrapper>
  );
};

export default WebsiteOverlays;
