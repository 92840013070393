import axios, { AxiosError } from 'axios';
import { errorToast } from 'lib/components/toasts/error';

type IShowError = AxiosError<{ message: string }> | unknown;
/**
 * @desc
 * accepts two arguments
 *
 * first - AxiosError or Error object as unknown or "custom-error"
 *
 * second -  optional and used for custom message
 *
 */

export const useToastError = () => {
  const showError = (err: IShowError, customMessage?: string): void => {
    if (axios.isAxiosError(err)) {
      const errMsg = customMessage || err?.response?.data?.message;
      errorToast({ title: errMsg || 'Something went wrong' });
      return;
    }
    if (err instanceof Error) {
      const errMsg = customMessage || err?.message;
      errorToast({ title: errMsg || 'Something went wrong' });
      return;
    }
    errorToast({ title: customMessage || 'Something went wrong' });
    return;
  };

  return { showError };
};
