import * as React from 'react';
import { DocumentHead } from 'lib/components';

import { ResetContent } from './components';

export const ResetPassword = () => {
  return (
    <>
      <DocumentHead title="Reset" />
      <ResetContent />
    </>
  );
};
