import React, { useState } from 'react';
import { BsFillPinFill } from 'react-icons/bs';
import {
  MdDeleteForever,
  MdDownload,
  MdOutlineAddToPhotos,
  MdOutlineAutorenew,
  MdOutlineContentCopy,
  MdSwapHoriz,
} from 'react-icons/md';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { useAuth, VideoListAutomotiveItem, VideoTag } from 'lib/context';
import { text } from 'lib/utils';
import { ModalReassign } from 'lib/components';
import keyBy from 'lodash/keyBy';
import { RESELLER_IDS, VideoStatus } from 'lib/const';
import { DeleteVideoModal } from 'lib/components/modal/DeleteVideoModal';
import { ModalVideoTags } from 'lib/components/modal/ModalVideoTags';
import { successToast } from 'lib/components/toasts/success';
import { duplicateVideo, pinVideos, putVideoTags, unpinVideos } from 'lib/api';
import { useToastError } from 'lib/hooks';
import { FOLDER_ACCESS } from '../constants';
import {
  VIDEO_LIMIT_REACHED,
  VideoLimitStatus,
  checkIfFeatureIsEnabled,
  productFeature,
} from 'lib/utils/productFeature';
import { checkIfUserIsTechnician } from 'lib/utils/automotiveRolePermissionChecks';
import {
  ALL_FOLDERS,
  DEFAULT_COMPANY_FOLDER,
  DefaultFolderIds,
  SHARED_FOLDER_PREFIX,
} from 'lib/const/Folders';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import selectors from '../../../../../../cypress/selectors';
import { Button } from 'react-covideo-common';
import { Folder } from 'lib/api/folders/getFolders';
import { CovideoIcon } from 'lib/components/CovideoIcon';

type ActionProps = {
  selectedVideosLength: number;
  hidePinButton: number | boolean;
};

const ActionsWrapper = styled.div<ActionProps>`
  display: flex;
  gap: ${props =>
    props.selectedVideosLength > 1 && !props.hidePinButton ? '5px' : '8px'};
  // justify-content: space-between;
  .disableDownload {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

type TagOption = { value: string | number; label: string; __isNew__?: boolean };

type Props = {
  selectedVideos: VideoListAutomotiveItem[];
  setSelectedVideos?(selectedVideos: any[]): void;
  videoArray: VideoListAutomotiveItem[];
  handleReassignSubmit: (
    advisorId: string,
    videoIds: string[],
    advisor: any
  ) => void;
  handleDeleteSubmit: () => void;
  handleMerge: (videoIds: string) => void;
  handleDownload: (downloadData: { videoId: string; title: string }[]) => void;
  handleMoveCopyButtonClick?: (videoIds: string[]) => void;
  disableDownloadButton?: boolean /* SUS-1282 changes */;
  refreshVideoList: () => void;
  folderAccess: string;
  isFolderShared: boolean;
  videoLimitStatus?: VideoLimitStatus;
  disablePinButton?: boolean;
  currentFolder?: Folder | null;
};
export const SelectedVideosActions = ({
  videoArray,
  selectedVideos,
  setSelectedVideos,
  handleReassignSubmit,
  handleDeleteSubmit,
  handleMerge,
  handleDownload,
  handleMoveCopyButtonClick,
  disableDownloadButton /* SUS-1282 changes */,
  refreshVideoList,
  folderAccess,
  isFolderShared,
  videoLimitStatus = VideoLimitStatus.NOT_REACHED,
  disablePinButton = false,
  currentFolder = null,
}: Props) => {
  const { showError } = useToastError();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalReassign, setShowModalReassign] = useState(false);
  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const { userData } = useAuth();
  const isAutomotive = userData.isAutomotive;
  const hasUserCopyAccessAndShared =
    folderAccess === FOLDER_ACCESS.COPY && isFolderShared;
  const hasUserEditAccessAndShared =
    folderAccess === FOLDER_ACCESS.EDIT && isFolderShared;
  const isTechnician = checkIfUserIsTechnician(userData);
  const VideosDictionary = keyBy(videoArray, 'id');
  const getSelectedVideoIds = () => {
    let videoIds = '';
    let isFirst = true;
    selectedVideos.forEach((value: any) => {
      if (!isFirst && value.id) {
        videoIds += ',';
      }
      if (value.id) videoIds += value.id;
      isFirst = false;
    });
    return videoIds;
  };
  const checkIfHasDenied = () => {
    const deniedVrs = selectedVideos.filter((video: any) => {
      return (
        VideosDictionary[video.id]?.videoRequest?.status === VideoStatus.Denied
      );
    });
    return deniedVrs.length === 0;
  };
  const checkForCompanyVideos = () => {
    return selectedVideos.some(
      (video: VideoListAutomotiveItem) =>
        VideosDictionary[video.id]?.folder === DEFAULT_COMPANY_FOLDER
    );
  };

  const checkForUnpinnedVideos = () => {
    return selectedVideos.some(
      (video: VideoListAutomotiveItem) =>
        !VideosDictionary[video.id]?.pinnedVideo?.pinnedAt
    );
  };

  const checkForSharedVideos = () => {
    const userId = parseInt(userData.id, 10);
    // Check if selected videos contains other user's videos
    return selectedVideos.some((video: VideoListAutomotiveItem) => {
      const videoDetails = VideosDictionary[video.id];
      if (!!videoDetails?.videoRequest) {
        return (
          videoDetails?.folder === DEFAULT_COMPANY_FOLDER &&
          parseInt(videoDetails?.videoRequest?.userId ?? '0', 10) !== userId
        );
      }

      return (
        videoDetails?.folder === DEFAULT_COMPANY_FOLDER &&
        videoDetails?.user?.id !== userId
      );
    });
  };
  const handleMergeClick = () => {
    handleMerge(getSelectedVideoIds());
  };
  const handleDownloadClick = async () => {
    const downloadData = selectedVideos.map((video: any) => {
      return { videoId: video.id, title: VideosDictionary[video.id].title };
    });
    handleDownload(downloadData);
    setSelectedVideos && setSelectedVideos([]);
  };
  const handleDeleteClick = async () => {
    handleDeleteSubmit();
  };
  const handleMoveCopyClick = async () => {
    const videoIds = selectedVideos.map((v: any) => v.id);
    handleMoveCopyButtonClick?.(videoIds);
  };

  const onTagsSave = async (values: TagOption[]) => {
    try {
      for (const selectedVideo of selectedVideos) {
        const oldVideoTags = (selectedVideo.tags || []).map(t => ({
          value: t.tagId,
          label: t.tag,
        }));
        const videoTagsValues = [...values];

        for (const oldVideo of oldVideoTags) {
          const value = videoTagsValues.find(
            val => val.value === oldVideo.value
          );
          if (value) {
            const index = videoTagsValues.findIndex(
              val => val.value === value.value
            );
            if (index >= 0) {
              videoTagsValues.splice(index, 1);
            }
          }
        }

        const savedTags = await putVideoTags(selectedVideo.id, [
          ...oldVideoTags,
          ...videoTagsValues,
        ]);
        values = assignIdstoNewTags(values, savedTags.videoTags);
      }
      setShowAddTagsModal(false);
      refreshVideoList();
      successToast({
        title: `${values.length} ${
          values.length === 1 ? 'tag was' : 'tags were'
        } added to ${selectedVideos.length} videos`,
      });
    } catch (error) {
      showError(error);
    }
  };

  const assignIdstoNewTags = (newTags: TagOption[], savedTags: VideoTag[]) => {
    for (const newTag of newTags) {
      const savedTag = savedTags.find(tag => tag.tag === newTag.label);
      if (savedTag) {
        newTag.value = savedTag.tagId;
        delete newTag.__isNew__;
      }
    }

    return newTags;
  };

  const handleDuplicateClick = async () => {
    setDuplicateLoading(true);
    try {
      await duplicateVideo({
        videosToDuplicate: selectedVideos
          .filter(selectedVideo => selectedVideo.id)
          .map(selectedVideo => selectedVideo.id.toString()),
      });
      successToast({
        title: 'Duplicated successfully',
      });
    } catch (error) {
      showError(error);
    }
    setDuplicateLoading(false);
    refreshVideoList();
  };

  const handleVideoPinning = async (
    e: React.MouseEvent<Element, MouseEvent> | undefined
  ) => {
    if (e && e.detail > 1) {
      return;
    }

    if (!currentFolder) {
      showError('Folder not found');
      return;
    }

    const folderName = isFolderShared
      ? `${SHARED_FOLDER_PREFIX}${currentFolder.folderId}`
      : currentFolder.name;
    const isPinnedCase = checkForUnpinnedVideos();
    const videoIds = selectedVideos.map((v: VideoListAutomotiveItem) =>
      parseInt(v.id, 10)
    );
    try {
      isPinnedCase
        ? await pinVideos(videoIds, folderName)
        : await unpinVideos(videoIds, folderName);
      successToast({
        title: `Video${videoIds.length > 1 ? 's' : ''} ${
          isPinnedCase ? 'pinned' : 'unpinned'
        } successfully`,
      });
    } catch (error) {
      showError(error);
    }
    refreshVideoList();
  };
  const hasCompanyVideos = checkForCompanyVideos();
  const showReassignButton =
    !hasCompanyVideos && userData.isAutomotiveServiceRole && checkIfHasDenied();

  const showMergeButton =
    !hasCompanyVideos &&
    !isTechnician &&
    selectedVideos.length > 1 &&
    !hasUserCopyAccessAndShared &&
    checkIfFeatureIsEnabled(userData, productFeature.VIDEO_MERGE);

  const showMoveCopyButton =
    ((!hasCompanyVideos &&
      !isTechnician &&
      (hasUserEditAccessAndShared || !isFolderShared)) ||
      currentFolder?.folderId === DefaultFolderIds.COMPANY) &&
    checkIfFeatureIsEnabled(userData, productFeature.FOLDER_ORGANIZATION);
  const showDeleteButton =
    !checkForSharedVideos() &&
    (!isFolderShared || hasUserEditAccessAndShared) &&
    userData?.resellerId?.toString() !== RESELLER_IDS.MOSS_AUTO_GROUP;
  const showDuplicateButton =
    !hasCompanyVideos &&
    !hasUserCopyAccessAndShared &&
    !isTechnician &&
    !VIDEO_LIMIT_REACHED.includes(videoLimitStatus);

  const showAddTagsButton =
    checkIfFeatureIsEnabled(userData, productFeature.VIDEO_TAGS) &&
    !hasCompanyVideos &&
    selectedVideos.length >= 1 &&
    (!isFolderShared || hasUserEditAccessAndShared);

  const pinnedSelectedVideos = selectedVideos.filter(
    video => !!video?.pinnedVideo?.pinnedAt
  );
  const unPinnedSelectedVideos = selectedVideos.filter(
    video => !video?.pinnedVideo?.pinnedAt
  );
  const hidePinButton =
    currentFolder?.folderId === ALL_FOLDERS.value ||
    (pinnedSelectedVideos.length && unPinnedSelectedVideos.length);

  const shouldDisableButton =
    disablePinButton && !!unPinnedSelectedVideos.length;

  const processingVideos = selectedVideos.filter(video => !!video.processing);
  const hideDownloadButton = !!processingVideos.length;

  return (
    <>
      <ActionsWrapper
        selectedVideosLength={selectedVideos.length}
        hidePinButton={hidePinButton}
      >
        {!hidePinButton && (
          <ProductInfoTooltip
            direction='bottom'
            style={{
              padding: '0px 15px',
              width: 'max-content',
              top: '50px',
              left: 35,
              color: theme.palette.black,
              opacity: 1,
            }}
            key={'Pin-Unpin'}
            isNonFreemiumTooltip={true}
            tooltipText={'You can pin up to 5 videos'}
            visibility={shouldDisableButton}
          >
            <Button
              variant='secondary'
              text={unPinnedSelectedVideos.length ? 'Pin' : 'Unpin'}
              onClick={(e: React.MouseEvent<Element, MouseEvent> | undefined) =>
                handleVideoPinning(e)
              }
              disabled={shouldDisableButton}
              icon={<BsFillPinFill size={16} />}
            />
          </ProductInfoTooltip>
        )}

        {showAddTagsButton && (
          <Button
            variant='secondary'
            onClick={() => setShowAddTagsModal(true)}
            text='Add Tags'
            icon={<CovideoIcon name='tag-dots' height={16} />}
            data-cy={
              selectors.modals.modalVideoTags.selectedVideosAddTagsHeaderAction
            }
          />
        )}

        {showReassignButton && (
          <Button
            variant='secondary'
            onClick={() => setShowModalReassign(true)}
            text='Reassign'
            icon={<MdOutlineAutorenew size={20} />}
          />
        )}
        {showMergeButton && (
          <Button
            variant='secondary'
            onClick={handleMergeClick}
            text='Merge'
            icon={<MdOutlineAddToPhotos size={20} />}
            data-cy={selectors.modals.modalVideoTags.mergeVideoButton}
          />
        )}
        {showMoveCopyButton && (
          <Button
            variant='secondary'
            onClick={() => handleMoveCopyClick()}
            text='Move / Copy'
            icon={<MdSwapHoriz size={20} />}
          />
        )}
        <Button
          variant='secondary'
          onClick={handleDownloadClick}
          text='Download'
          icon={<MdDownload size={20} />}
          disabled={disableDownloadButton || hideDownloadButton}
        />
        {showDuplicateButton && (
          <Button
            variant='secondary'
            onClick={() => handleDuplicateClick()}
            text='Duplicate'
            icon={<MdOutlineContentCopy size={'24px'} />}
            disabled={duplicateLoading}
            data-cy={selectors.libraryPage.selectedVideosDuplicateAction}
          />
        )}
        {showDeleteButton && (
          <Button
            variant='destructive'
            onClick={() => setShowModalDelete(true)}
            text='Delete'
            icon={<MdDeleteForever size={20} color={theme.palette.red100} />}
            disabled={duplicateLoading}
            data-cy={selectors.libraryPage.selectedVideosDuplicateAction}
          />
        )}
      </ActionsWrapper>
      {showModalReassign && (
        <ModalReassign
          assignTo={selectedVideos}
          handleSubmit={handleReassignSubmit}
          handleModalClose={() => setShowModalReassign(false)}
          title={'Reassign video to another advisor'}
        />
      )}
      {showModalDelete && (
        <DeleteVideoModal
          content={
            isAutomotive
              ? text.deleteModal.deleteModalMultipleSelectedBody
              : text.deleteModal.deleteMultipleModalBody
          }
          handleModalClose={() => setShowModalDelete(false)}
          handleModalSubmit={() => handleDeleteClick()}
          isCompanyVideo={currentFolder?.folderId === DefaultFolderIds.COMPANY}
        />
      )}
      {showAddTagsModal && (
        <ModalVideoTags
          videoTags={[]}
          handleModalClose={() => setShowAddTagsModal(false)}
          onSubmit={onTagsSave}
          title={`Add Tags for ${selectedVideos.length} Videos`}
          buttonTitle={'Add Tags'}
        />
      )}
    </>
  );
};
