import React, { useState } from 'react';

import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { LoadingIndicator, Modal } from 'lib/components';
import { theme } from 'lib/style';
import { sendXLSXAnalyticsReport } from 'lib/api';
import { StatusOptionsValues } from 'app/pages/video/videoDetails/components/const';
import { successToast } from 'lib/components/toasts/success';
import { RecipientsSelector } from '../../components/RecipientsSelector';
import { MdInfo } from 'react-icons/md';
import { useAuth } from 'lib/context';
import { useToastError } from 'lib/hooks';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #272a32;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const ErrorMessage = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

const Caption = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  margin-top: 14px;
`;

const InfoMsgWrapper = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  background: rgba(182, 224, 236, 0.2);
  border-radius: 5px;
  border: 1px solid rgba(0, 27, 83, 0.05);
  padding: 12px;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 27, 83, 0.6);
  margin: 0;
`;

type Props = {
  handleModalClose: (modals: null) => void;
  params?:
    | {
        search: string;
        emailStatus: StatusOptionsValues;
        SMS: number;
      }
    | {};
  videoId?: string;
  data?: any;
  widgets?: any;
};

export const SendReportModal = (props: Props) => {
  const [recipients, setRecipients] = useState([] as string[]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { handleModalClose } = props;
  const { userData } = useAuth();
  const { ref } = useOnClickOutside(handleModalClose);
  const { showError } = useToastError();

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose(null);
    }
  };
  React.useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const sendReport = async () => {
    setLoading(true);

    try {
      let widgetsExportData = props.data.filter(
        (data: any) =>
          props.widgets.find((widget: any) => data.title === widget.label) &&
          data.data.length
      );

      await sendXLSXAnalyticsReport(widgetsExportData, recipients);
      handleModalClose(null);
      successToast({ title: 'You have successfully sent a report' });
    } catch (error) {
      //TO_DO -> remove old toast and better error handling
      showError(error);
    }
    setLoading(false);
  };

  return (
    <Modal>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Send Report</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose(null)}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>

        <Content>
          {loading ? (
            <LoadingIndicator isLoading={loading} height='50px' />
          ) : (
            <>
              <InfoMsgWrapper>
                <MdInfo color={theme.palette.blue40} size={20} />
                <Paragraph>
                  The exported file will be sent to <b>{userData.email}</b>.
                </Paragraph>
              </InfoMsgWrapper>
              <Caption>Share the report with additional recipients</Caption>
              <RecipientsSelector
                onChange={recipients => setRecipients(recipients)}
                onError={email => {
                  if (email) {
                    setError('Invalid email address.');
                  } else {
                    setError('');
                  }
                }}
                showIcon
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </>
          )}
        </Content>
        <Footer>
          <Button
            variant='primary'
            text={loading ? 'Sending' : 'Generate and Send'}
            disabled={loading}
            onClick={sendReport}
          />
        </Footer>
      </div>
    </Modal>
  );
};
