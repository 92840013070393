import styled from 'styled-components/macro';

interface Props {
  translate: any;
  transition: any;
  width: any;
}
const SliderContent = styled.div<Props>`
  transform: translateX(-${props => props.translate}px);
  transition: transform ease-out ${props => props.transition}s;
  height: 100%;
  width: ${props => props.width}px;
  display: flex;
`;
export default SliderContent;
