import * as React from 'react';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { Link, NavLink } from 'react-router-dom';
import { HiChevronLeft } from 'react-icons/hi';
import { useAuth } from '../context';
import { useLandingPageBuilderContext } from '../../app/pages/design/landingPageBuilder/context';
import ProductInfoTooltip from 'lib/components/ProductInfoTooltip/ProductInfoTooltip';
import { isAdminSession } from 'lib/utils/auth';
import { InviteFriendsModal } from './modal/ModalInviteFriends';
import { Button } from 'react-covideo-common';
import { IoMdPeople } from 'react-icons/io';
import { checkIfFreemium } from 'lib/utils/functions';
import { PackageNameLabel, productFeature } from 'lib/utils/productFeature';
import {
  MdDeleteForever,
  MdCreditCard,
  MdExtension,
  MdHelp,
  MdSubtitles,
  MdEmail,
  MdAutoDelete,
  MdScheduleSend,
  MdWork,
  MdDirectionsRun,
  MdPerson,
  MdSupervisorAccount,
  MdLabel,
  MdLock,
} from 'react-icons/md';
import { BiWorld } from 'react-icons/bi';
import { FaStar } from 'react-icons/fa';
import { CommonTypography } from './styles/typography';
import { PackageName } from 'lib/const/PackageAdditionalInfo';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { CovideoIcon } from './CovideoIcon';
import { AccessRole } from 'lib/const';

type ContainerProps = {
  padding?: number;
  overflow?: string;
};

const Container = styled.nav<ContainerProps>`
  z-index: 6;
  display: none;
  justify-content: flex-start;
  flex-direction: column;
  height: 89vh;
  position: fixed;
  left: 0;
  top: 0;
  .disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    color: rgba(255, 255, 255, 0.5);
  }
  padding-top: ${props => props.padding || 100}px;
  min-width: 225px;
  background-color: ${theme.palette.covideoBlue02NonAlpha};
  ${theme.mediaQueryMinWidth.mb} {
    display: flex;
  }
  overflow-y: ${props => props.overflow || 'visible'};
`;

type NavItemProps = {
  isDisabled?: boolean;
};

const NavItem = styled(NavLink)<NavItemProps>`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  display: flex;
  padding: 10px 16px 10px 28px;
  justify-content: flex-start;
  min-height: 20px;
  height: 40px;
  align-items: center;
  text-align: center; /* to center icons */
  opacity: 1;
  transition: all 0.2s;
  ${theme.fontNormal500};
  font-size: ${theme.fontSizes.md};
  color: ${theme.palette.primaryDarkBlue75Percent};
  text-decoration: none;

  border-left: 2px solid transparent;
  svg {
    color: rgba(0, 27, 83, 0.4);
    fill: rgba(0, 27, 83, 0.4);
  }
  svg.logout {
    color: ${theme.palette.red40};
    fill: ${theme.palette.red40};
  }
  g > path {
    fill: rgba(0, 27, 83, 0.4);
  }
  .notificationCount {
    background-color: ${theme.palette.primaryDarkBlue50Percent};
  }

  ${props =>
    !props.isDisabled &&
    `
    &:hover {
      color: ${theme.palette.primaryDarkBlue};
      background-color: ${theme.palette.white};
    }
  `}

  &.active {
    background-color: ${theme.palette.white};
    color: ${theme.palette.primaryDarkBlue};
    border-left: 2px solid ${theme.palette.primaryDarkBlue};
    svg {
      color: ${theme.palette.primaryDarkBlue};
      fill: ${theme.palette.primaryDarkBlue};
    }
    g > path {
      fill: ${theme.palette.primaryDarkBlue};
    }
    .notificationCount {
      background-color: ${theme.palette.primaryDarkBlue};
    }
  }
`;

const Icon = styled.span<{ color?: string }>`
  margin: 0;
  font-size: ${theme.iconSizeS};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${props => (props.color ? props.color : 'rgba(0, 27, 83, 0.4)')};
`;

const IconWrap = styled.div`
  position: relative;
`;

const Title = styled.span<{ hasNotifications?: any; color?: string }>`
  ${theme.fontNormal600}
  // margin-left: 14px;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0;
  color: ${props =>
    props.color ? props.color : theme.palette.primaryDarkBlue};
  position: relative;

  ${({ hasNotifications, theme }) =>
    hasNotifications &&
    css`
      :after {
        content: '';
        width: 6px;
        height: 6px;
        background: ${theme.colors.primary[100]};
        position: absolute;
        border-radius: 6px;
        left: -18px;
        top: 2px;
      }
    `}

  ${theme.mediaQueryMinWidth.lg} {
    display: block;
  }
`;

const BackButton = styled(Link)`
  border: none;
  text-decoration: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  padding: 10px 16px 10px 32px;
  color: ${theme.palette.primaryDarkBlue};
  font-weight: 500;
  min-width: 160px;
  text-decoration: underline;
  &:hover {
    color: ${theme.palette.primaryDarkBlue};
    background-color: ${theme.palette.white};
  }
  ${theme.mediaQueryMinWidth.mb} {
    aside {
      display: none;
    }
  }
  ${theme.mediaQueryMinWidth.sm} {
    width: 100px;
    aside {
      display: block;
    }
    img {
      margin: 0 16px 0 0;
    }
  }
`;

const AdditionalIcon = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.palette.secondaryGrey};
`;

const AdditionalIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  background-color: #e6e6e6;
  border-radius: 6px;
  position: absolute;
  right: -10px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Badge = styled.span`
  font-size: 10px;
  padding: 2px 4px;
  margin-left: 4px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary[100]}; /** SUS-961 changes **/
`;

const Notification = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #001b53;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  width: 24px;
  height: 24px;
`;

const TooltipMain = styled.div`
  width: 100%;
`;

const EnabledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
`;

const EnabledDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DisabledItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  opacity: 0.5;
`;

const DisabledDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 16px 0px 28px;
  gap: 12px;
`;

const Label = styled(CommonTypography).attrs({ as: 'p' })`
  font-size: 11px;
  line-height: 16px;
  font-weight: 600;
  color: ${theme.palette.covideoBlue40};
`;

const Line = styled.div`
  flex: 1;
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.blue05};
`;

const ItemsWrapper = styled.div<{ paddingBottom?: string }>`
  padding-bottom: ${props => props.paddingBottom || '0px'};
  text-align: center;
`;

const getNextPlanForSidebarItems = (title: string) => {
  return [
    'Video Attributes',
    'Files',
    'Boards',
    'Captions',
    'Security',
    'Schedules',
    'Templates',
    'Appearance',
    'Annotations',
    'Overlay URLs',
    'QR Codes',
    'Video Expiration',
    'Lives',
    'Social Profiles',
  ].includes(title)
    ? PackageNameLabel.TEAMS
    : PackageNameLabel.PRO;
};

const getTootipTextByTitleForStandardUsers = (title: string) => {
  const defaultText = 'Please contact your Covideo Admin to request access.';
  const tooltipText: { [key: string]: string } = {
    Files:
      'Files feature is not enabled for your account. Please contact your Covideo admin to request access.',
    Boards:
      'Boards feature is not enabled for your account. Please contact your Covideo admin to request access.',
    Captions:
      'Captions aren’t enabled for your account. Please contact your Admin to obtain the necessary license.',
  };

  return tooltipText[title] || defaultText;
};
export interface SidebarItem {
  productFeatureId?: number;
  title: string;
  path?: string;
  icon?: any;
  exact?: boolean;
  additionalIcon?: any;
  badge?: string;
  notificationCount?: string;
  isDisabled?: boolean;
  isPaid?: boolean;
  paidUrl?: string;
  isLabel?: boolean;
  iconColor?: string;
  color?: string;
}

interface Props {
  hasBack?: boolean;
  items: SidebarItem[];
  removeBackButton?: boolean;
}

const iconSize = '24px';
const additionalIconSize = '16px';

export const profile = {
  title: 'My Profile',
  icon: <MdPerson size={iconSize} />,
  path: '/profile/Profile_EditUserInfo',
  isDisabled: false,
};
export const company = {
  title: 'Company Profile',
  icon: <MdWork size={iconSize} />,
  path: '/profile/Profile_EditCompanyInfo',
  isDisabled: false,
};
export const manageUsers = {
  title: 'Manage Users',
  icon: <MdSupervisorAccount size={iconSize} />,
  path: '/users/list',
  additionalIcon: <MdLock size={additionalIconSize} color={'#001B53'} />,
  isDisabled: false,
};
export const billing = {
  title: 'Billing',
  icon: <MdCreditCard size={iconSize} />,
  // path: `/profile/Profile_EditCreditCard`,
  path: '/billing',
  isDisabled: false,
};
export const addons = {
  title: 'Add-ons',
  icon: <MdExtension size={iconSize} />,
  path: '/profile/add-ons',
  isDisabled: false,
};
export const videos = {
  title: 'Videos',
  isLabel: true,
};
export const teleprompterItem = {
  title: 'Teleprompter',
  icon: <MdSubtitles size={iconSize} />,
  path: '/profile/teleprompter',
  additionalIcon: <MdLock size={additionalIconSize} color={'#001B53'} />,
  productFeatureId: productFeature.TELEPROMPTER,
};
export const videoAttributes = {
  title: 'Video Attributes',
  icon: <MdLabel size={iconSize} />,
  path: '/profile/Profile_VideoAttributes',
  additionalIcon: <MdLock size={additionalIconSize} color={'#001B53'} />,
  productFeatureId: productFeature.VIDEO_ATTRIBUTES,
};
export const videoExpiration = {
  title: 'Video Expiration',
  icon: <MdAutoDelete size={iconSize} />,
  path: '/profile/Profile_VideoDeletion',
  additionalIcon: <MdLock size={additionalIconSize} color={'#001B53'} />,
  productFeatureId: productFeature.VIDEO_EXPIRATION,
};
export const emails = {
  title: 'Emails',
  isLabel: true,
};
export const scheduleItem = {
  title: 'Schedules',
  icon: <MdScheduleSend size={iconSize} />,
  path: '/profile/schedules',
  additionalIcon: <MdLock size={additionalIconSize} color={'#001B53'} />,
  productFeatureId: productFeature.SCHEDULES,
};
export const snippetsItem = {
  title: 'Templates',
  icon: <CovideoIcon name='snippets' height={17} />,
  path: '/profile/templates',
  additionalIcon: <MdLock size={additionalIconSize} color={'#001B53'} />,
  productFeatureId: productFeature.TEMPLATES,
};
export const emailBatches = {
  title: 'Email Batches',
  icon: <MdEmail size={iconSize} />,
  path: '/profile/Email_Batches',
  isDisabled: false,
};
export const servicesItem = {
  title: 'Services',
  icon: <CovideoIcon name='services' height={'17px'} />,
  path: '/profile/services',
  isDisabled: false,
};
export const others = {
  title: 'Others',
  isLabel: true,
};
export const socialProfile = {
  title: 'Social Profiles',
  icon: <BiWorld size={iconSize} />,
  path: '/profile/social-profiles',
  additionalIcon: <MdLock size={additionalIconSize} color={'#001B53'} />,
  productFeatureId: productFeature.YOUTUBE,
};
export const help = {
  title: 'Help Center',
  icon: <MdHelp size={iconSize} />,
  path: '/Help',
  isDisabled: false,
};
export const affiliate = {
  title: 'Affiliate',
  icon: <FaStar size={iconSize} />,
  path: '/profile/Profile_AffiliateApply',
  isDisabled: false,
};
export const recentlyDeleted = {
  title: 'Recently Deleted',
  icon: <MdDeleteForever size={iconSize} />,
  path: '/profile/recently-deleted',
  isDisabled: false,
};
export const logout = {
  title: 'Log Out',
  icon: <MdDirectionsRun size={iconSize} className='logout' />,
  path: '/Sign_Out',
  isDisabled: false,
  iconColor: theme.palette.red40,
  color: theme.palette.red100,
};

//BACK ICON
const BackIcon = ({ hasBack }: Pick<Props, 'hasBack'>) => {
  if (hasBack) {
    <IconWrap>
      <BackButton to={'/'}>
        <HiChevronLeft
          color={theme.palette.covideoBlue100}
          width={16}
          height={16}
        />
        <aside>Back</aside>
      </BackButton>
    </IconWrap>;
  }
  return null;
};

const DisabledItemComponent = ({ item }: { item: SidebarItem }) => {
  const { title, icon, additionalIcon, badge, notificationCount } = item;
  return (
    <DisabledItemWrapper>
      <Icon>{icon}</Icon>
      <DisabledDataWrapper>
        <Title>
          {title}
          {!!badge && <Badge>{badge}</Badge>}
          {!!notificationCount && (
            <Notification>{notificationCount}</Notification>
          )}
        </Title>
        {!!additionalIcon && (
          <AdditionalIconWrapper>
            <AdditionalIcon>{additionalIcon}</AdditionalIcon>
          </AdditionalIconWrapper>
        )}
      </DisabledDataWrapper>
    </DisabledItemWrapper>
  );
};
//DISABLED ITEM
const DisabledItem = ({
  item,
  hasUserLevelAccessForFeature,
}: {
  item: SidebarItem;
  hasUserLevelAccessForFeature: boolean;
}) => {
  const { title, isDisabled, isPaid, paidUrl } = item;
  const nextPlan = getNextPlanForSidebarItems(title);

  const isNonFreemiumTooltip =
    !hasUserLevelAccessForFeature && !isDisabled ? true : false;
  return (
    <TooltipMain>
      <ProductInfoTooltip
        nextPlan={nextPlan}
        isPaid={isPaid}
        paidUrl={paidUrl}
        productFeatureId={item.productFeatureId}
        isNonFreemiumTooltip={isNonFreemiumTooltip}
        tooltipText={getTootipTextByTitleForStandardUsers(title)}
        style={{
          left: 'calc(100% + 150px)',
          ...(isNonFreemiumTooltip ? { opacity: 1 } : {}),
        }}
      >
        <DisabledItemComponent item={item} />
      </ProductInfoTooltip>
    </TooltipMain>
  );
};

//ENABLED ITEM
const EnabledItem = ({ item }: { item: SidebarItem }) => {
  const { title, icon, badge, notificationCount } = item;
  const hasNotificationsCount = !!notificationCount;
  const shouldShowNotification =
    (title === 'Training' && parseInt(notificationCount ?? '0') > 0) ||
    (title !== 'Training' && hasNotificationsCount);
  return (
    <EnabledItemWrapper>
      <Icon color={item.iconColor}>{icon}</Icon>
      <EnabledDataWrapper>
        <Title color={item.color}>
          {title}
          {!!badge && <Badge>{badge}</Badge>}
        </Title>
        {shouldShowNotification && (
          <Notification className={'notificationCount'}>
            {notificationCount}
          </Notification>
        )}
      </EnabledDataWrapper>
    </EnabledItemWrapper>
  );
};

//SIDEBAR NAVIGATION
export const SidebarNavigation = ({ items, hasBack = true }: Props) => {
  const { userData, whitelabel } = useAuth();
  const { trialExpired, droplrAccess, customer, transcriptionAccess } =
    userData;
  const { isEnabled } = useLandingPageBuilderContext();
  const [inviteFriends, setInviteFriends] = React.useState(false);

  if (isEnabled) {
    return <></>;
  }

  const onNavItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: SidebarItem,
    hasUserLevelAccessForFeature: boolean = true
  ) => {
    const { isDisabled, title } = item;
    if (
      isDisabled ||
      trialExpired ||
      title === help.title ||
      !hasUserLevelAccessForFeature
    ) {
      e.preventDefault();
    }

    if (title === help.title) {
      window
        .open(whitelabel?.supportURL || 'https://support.covideo.com', '_blank')
        ?.focus();
    }
  };

  const isItemInvisible = (item: SidebarItem) => {
    const { title } = item;
    if (title === help.title && !whitelabel.supportURL) {
      return true;
    }
    if (
      title === billing.title &&
      whitelabel.name !== WHITELABEL_NAME.COVIDEO
    ) {
      return true;
    }
    return false;
  };

  const setModal = (isOpen: boolean) => {
    setInviteFriends(isOpen);
  };

  const hasItemAccess = (item: SidebarItem) => {
    const { title } = item;
    const isAdmin = userData.access === AccessRole.ADMIN;
    if (isAdmin || whitelabel.name !== WHITELABEL_NAME.COVIDEO) {
      return true;
    }
    if (title === 'Files' || title === 'Boards') {
      return !!droplrAccess && !!customer.droplrEnabled;
    }

    if (title === 'Captions') {
      return !!transcriptionAccess && !!customer.transcriptions;
    }

    return true;
  };

  const isLegacyPackage =
    parseInt(userData.customer.packageId.toString(), 10) === PackageName.LEGACY;
  const isDroplrOrCaptionsCase = items.some(
    item =>
      item.title === 'Files' ||
      item.title === 'Boards' ||
      item.title === 'Captions'
  );
  const isUserAccessTooltipRequired =
    isDroplrOrCaptionsCase && (!droplrAccess || !transcriptionAccess);
  return (
    <Container
      padding={isAdminSession() || userData.trialAccount ? 136 : 96}
      overflow={
        isLegacyPackage && !isUserAccessTooltipRequired ? 'auto' : 'visible'
      }
    >
      <BackIcon hasBack={hasBack} />
      <ItemsWrapper
        paddingBottom={
          isLegacyPackage || isUserAccessTooltipRequired ? '65px' : '0px'
        }
      >
        {items.map((item: SidebarItem, index) => {
          const { path, exact, isDisabled } = item;

          const isInvisible = isItemInvisible(item);
          const hasUserLevelAccessForFeature = hasItemAccess(item);
          if (isInvisible) {
            return <React.Fragment key={index} />;
          }

          if (!!item.isLabel) {
            return (
              <LabelContainer key={index}>
                <Label>{item.title}</Label>
                <Line />
              </LabelContainer>
            );
          }
          const isNavItemDisabled =
            isDisabled || trialExpired || !hasUserLevelAccessForFeature;
          return (
            <IconWrap key={index}>
              <NavItem
                onClick={(
                  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  onNavItemClick(e, item, hasUserLevelAccessForFeature);
                }}
                className={isNavItemDisabled ? 'disabled' : ''}
                key={index}
                to={isNavItemDisabled || !path ? index.toString() : path}
                exact={exact}
                isDisabled={isNavItemDisabled}
              >
                {isDisabled || !hasUserLevelAccessForFeature ? (
                  <DisabledItem
                    item={item}
                    hasUserLevelAccessForFeature={hasUserLevelAccessForFeature}
                  />
                ) : (
                  <EnabledItem item={item} />
                )}
              </NavItem>
            </IconWrap>
          );
        })}

        {checkIfFreemium(userData) && (
          <>
            {inviteFriends && (
              <InviteFriendsModal
                handleModalClose={() => setModal(false)}
                onDismissHandler={() => null}
                onMaybeLaterHandler={() => null}
              />
            )}
            <Button
              style={{
                margin: '56px auto 0 auto',
              }}
              text='Invite Friends'
              variant='white'
              icon={
                <IoMdPeople color={theme.palette.covideoBlue100} size={20} />
              }
              onClick={() => {
                setModal(true);
              }}
            />
          </>
        )}
      </ItemsWrapper>
    </Container>
  );
};
