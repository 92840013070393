import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  TabWrapper,
  ModalHeader,
  HeaderSide,
  LinkWrapper,
  LinkBtn,
  HeaderCenter,
  ModalBody,
  Actions,
  getTabs,
} from '../';
import { MdClose } from 'react-icons/md';
import { Button } from 'react-covideo-common';
import { ButtonPillSwitch, LoadingIndicator, TextInput } from 'lib/components';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { IoSend } from 'react-icons/io5';
import { TextEditor } from 'lib/components/textEditorAutomotive';
import { FaFacebookF, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { successToast } from 'lib/components/toasts/success';
import {
  checkYoutubeIntegration,
  getChannelData,
  uploadVideo,
} from 'lib/api/youtubeApi';
import {
  getTokenStatus,
  hasFacebookIntegration,
  removeFacebookIntegration,
  uploadVideoFacebook,
} from 'lib/api/facebookApi';
import { getLinkedinIntegration } from 'lib/api/linkedinApi';
import { PublishNotAvailable } from './PublishNotAvailable';
import { PublishItem } from './PublishItem';
import { useAuth } from 'lib/context';
import { VideoTitleAndThumbnail } from '../components';
import { useHistory } from 'react-router';
import { errorToast } from 'lib/components/toasts/error';
import { listSocialUploadLogs } from 'lib/api/socialAnalyticsApi';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { capitalizeWords, removeHTMLTags } from 'lib/utils/functions';
import { useSendAndShare } from 'lib/context/send-and-share/provider';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { EXPRESS_API } from 'configs/api/expressApi';
import { IAnalyticsLogsProps } from 'lib/api/social/types';

const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  justify-content: flex-end;
  align-items: flex-start;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  width: 384px;
  max-width: 100%;
  gap: 8px;
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 384px;
  max-width: 100%;
  padding: 16px;
  gap: 24px;
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
`;

type FormGroupProps = {
  disabled?: boolean;
};

const FormGroup = styled.div<FormGroupProps>`
  width: 100%;
  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;
        opacity: 25%;
      `;
    }
  }}
`;

type LabelProps = {
  disabled?: boolean;
};

const Label = styled.p<LabelProps>`
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: ${theme.palette.gray80};
  margin: 0 0 8px 0;
`;

type InputProps = {
  hasError?: boolean;
  disabled?: boolean;
};

const Input = styled(TextInput)<InputProps>`
  font-family: 'Work Sans';
  border: 1px solid ${theme.palette.gray40};
  &:focus {
    border: 1px solid ${theme.palette.gray40};
    box-shadow: none;
  }
  ${props => {
    if (props.hasError && !props.disabled) {
      return `border-color: ${theme.palette.red100} !important;`;
    }
  }}}
`;

const PublishHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
  gap: 8px;
`;

const SeeAnalyticsText = styled.div`
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  color: ${theme.palette.blue100};
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
`;

const PublishHistoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 16px;
  background: ${theme.palette.covideoSecondaryButtonBg};
  border-radius: 5px;
`;

const PublishHistoryItemText = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${theme.palette.covideoBlue80};
`;

type FlexRowContainerProps = {
  justifyContent?: string;
};

const FlexRowContainer = styled.div<FlexRowContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: center;
`;

const videoDescriptionToolbar = {
  options: [],
};

type Props = {
  setActiveTab: (tab: string) => void;
  handleModalClose: () => void;
};

export const PublishTab = ({ setActiveTab, handleModalClose }: Props) => {
  const { video } = useSendAndShare();
  const history = useHistory();
  const { userData } = useAuth();
  const tabs = getTabs(userData);

  const [videoTitleForPublish, setVideoTitleForPublish] = useState(video.title);
  const [videoDescriptionForPublish, setVideoDescriptionForPublish] =
    useState('');
  const [facebookPublishSelected, setFacebookPublishSelected] = useState(false);
  const [youtubePublishSelected, setYoutubePublishSelected] = useState(false);
  const [linkedinPublishSelected, setLinkedinPublishSelected] = useState(false);
  const [numberOfPublishItemsSelected, setNumberOfPublishItemsSelected] =
    useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [publishButtonText, setPublishButtonText] = useState('Publish to All');
  const [hasIntegrations, setHasIntegrations] = useState({
    facebook: false,
    youtube: false,
    linkedin: false,
  });

  //Facebook
  const [facebookConnected, setFacebookConnected] = useState<boolean>(false);
  const [facebookPage, setFacebookPage] = useState<string>('');
  const [facebookUploading, setFacebookUploading] = useState(false);

  //YouTube
  const [youtubeUploading, setYoutubeUploading] = useState(false);
  const [youtubeChannel, setYoutubeChannel] = useState<string>('');

  //LinkedIn
  const [linkedinUploading, setLinkedinUploading] = useState(false);
  const [linkedinPage, setLinkedinPage] = useState<string>('');

  useEffect(() => {
    refetchLogs();
  }, []);

  useEffect(() => {
    if (hasIntegrations.youtube) {
      ytChannelName();
    }
  }, [hasIntegrations]);

  const ytChannelName = async () => {
    const channel = await getChannelData();
    if (channel && channel.title) {
      setYoutubeChannel(channel.title);
    }
  };

  useEffect(() => {
    const checkintegrations = async () => {
      setIsLoading(true);
      const [youtubeStatus, linkedinStatus] = await Promise.all([
        checkYoutubeIntegration(),
        checkLinkedInIntegration(),
        checkFacebookIntegration(),
      ]);

      setHasIntegrations((prevValues: any) => ({
        ...prevValues,
        youtube: !!youtubeStatus?.success,
        linkedin: !!linkedinStatus,
      }));
      setIsLoading(false);
    };
    checkintegrations();
  }, []);

  useEffect(() => {
    setHasIntegrations((prevValues: any) => ({
      ...prevValues,
      facebook: !!facebookConnected,
    }));
  }, [facebookConnected]);

  const checkFacebookConnection = async (accessToken: string) => {
    if (accessToken) {
      await window.FB.getLoginStatus(async function (response: any) {
        const tokenStatus = await getTokenStatus(accessToken);
        // if tokenStatus has an id, it means that token is valid
        if (tokenStatus && tokenStatus.id && response.status === 'connected') {
          setFacebookConnected(true);
        } else {
          await removeFacebookIntegration();
          setFacebookConnected(false);
        }
      });
    }
  };

  const checkFacebookIntegration = async () => {
    setIsLoading(true);
    try {
      const hasIntegration = await hasFacebookIntegration();
      if (hasIntegration.accessToken) {
        setFacebookPage(hasIntegration.pageName);
        return await checkFacebookConnection(hasIntegration.accessToken);
      }
    } catch (error) {
      return undefined;
    }
  };

  const checkLinkedInIntegration = async () => {
    const linkedinIntegrationData = await getLinkedinIntegration();
    if (linkedinIntegrationData?.accessToken) {
      setLinkedinPage(linkedinIntegrationData.organizationName);
      return linkedinIntegrationData;
    } else {
      return undefined;
    }
  };

  const handleVideoTitleForPublishChange = (newTitle: string) => {
    setVideoTitleForPublish(newTitle);
  };
  const handleVideoDescriptionChange = (newDescription: string) => {
    const cleanTxt = removeHTMLTags(newDescription);
    setVideoDescriptionForPublish(cleanTxt);
  };

  useEffect(() => {
    let tempNumberOfSelectedPublishItems = 0;
    let tempPublishButtonText = 'Publish to All';

    if (facebookPublishSelected) {
      tempNumberOfSelectedPublishItems++;
      tempPublishButtonText = 'Publish to Facebook';
    }
    if (youtubePublishSelected) {
      tempNumberOfSelectedPublishItems++;
      tempPublishButtonText = 'Publish to YouTube';
    }
    if (linkedinPublishSelected) {
      tempNumberOfSelectedPublishItems++;
      tempPublishButtonText = 'Publish to LinkedIn';
    }
    if (tempNumberOfSelectedPublishItems === 3) {
      tempPublishButtonText = 'Publish to All';
    }
    if (tempNumberOfSelectedPublishItems === 2) {
      tempPublishButtonText = 'Publish to Selected';
    }
    setPublishButtonText(tempPublishButtonText);
    setNumberOfPublishItemsSelected(tempNumberOfSelectedPublishItems);
  }, [
    facebookPublishSelected,
    youtubePublishSelected,
    linkedinPublishSelected,
  ]);

  const handlePublish = async () => {
    let toastTitle = 'Your video is being published to ';
    if (numberOfPublishItemsSelected === 1) {
      if (facebookPublishSelected) {
        await handleFacebookVideoUpload();
        toastTitle = `${toastTitle}Facebook!`;
      } else if (youtubePublishSelected) {
        await handleYoutubeVideoUpload();
        toastTitle = `${toastTitle}Youtube!`;
      } else if (linkedinPublishSelected) {
        await shareVideoToLinkedin(
          video.id,
          videoTitleForPublish,
          videoDescriptionForPublish
        );
        toastTitle = `${toastTitle}LinkedIn!`;
      }
    } else if (numberOfPublishItemsSelected === 2) {
      if (facebookPublishSelected) {
        await handleFacebookVideoUpload();
        toastTitle = `${toastTitle}Facebook `;
        if (youtubePublishSelected) {
          await handleYoutubeVideoUpload();
          toastTitle = `${toastTitle}and Youtube!`;
        }
        if (linkedinPublishSelected) {
          await shareVideoToLinkedin(
            video.id,
            videoTitleForPublish,
            videoDescriptionForPublish
          );
          toastTitle = `${toastTitle}and LinkedIn!`;
        }
      } else {
        await handleYoutubeVideoUpload();
        await shareVideoToLinkedin(
          video.id,
          videoTitleForPublish,
          videoDescriptionForPublish
        );
        toastTitle = `${toastTitle}YouTube and LinkedIn!`;
      }
    } else if (numberOfPublishItemsSelected === 3) {
      await handleFacebookVideoUpload();
      await handleYoutubeVideoUpload();
      await shareVideoToLinkedin(
        video.id,
        videoTitleForPublish,
        videoDescriptionForPublish
      );
      toastTitle =
        'Your video is being published to Facebook, Youtube, and Linkedin!';
    }
    successToast({ title: toastTitle });
    setFacebookPublishSelected(false);
    setLinkedinPublishSelected(false);
    setYoutubePublishSelected(false);
    handleModalClose();
  };

  const isPublishAvailable =
    hasIntegrations.facebook ||
    hasIntegrations.youtube ||
    hasIntegrations.linkedin;

  const handleFacebookVideoUpload = async () => {
    setFacebookUploading(true);
    const upload = await uploadVideoFacebook(
      parseInt(video.id),
      videoTitleForPublish,
      videoDescriptionForPublish
    );
    if (upload.status === 404) {
      setFacebookUploading(false);
      errorToast({
        title: upload.data.message,
      });
      history.push('/profile/social-profiles');
    }
  };

  const handleYoutubeVideoUpload = async () => {
    setYoutubeUploading(true);
    const upload = await uploadVideo(
      video.id,
      videoTitleForPublish,
      videoDescriptionForPublish
    );
    if (!!upload) {
      if (upload.status === 404) {
        setYoutubeUploading(false);
        errorToast({
          title: 'Video not found',
        });
      }
    } else {
      errorToast({
        title: 'Upload failed',
      });
      setYoutubeUploading(false);
    }
  };

  const shareVideoToLinkedin = async (
    videoId: string,
    title: string,
    message: string
  ) => {
    setLinkedinUploading(true);

    try {
      const response = await EXPRESS_API.post('/linkedin/share-video', {
        videoId,
        title,
        message,
      });

      if (response.data && response.data.postId) {
        setLinkedinUploading(false);
      }
    } catch (error) {
      errorToast({
        title: 'There was a problem uploading video to LinkedIn.',
      });

      setLinkedinUploading(false);
    }
  };

  const { data: uploadLogs, refetch: refetchLogs } = useQuery(
    'social-analytics',
    () => listSocialUploadLogs(5, 'DESC', video.id)
  );

  return (
    <TabWrapper display='block'>
      <ModalHeader>
        <HeaderSide>
          <LinkWrapper>
            <LinkBtn onClick={handleModalClose}>
              <MdClose size='18' />
              <span>Close</span>
            </LinkBtn>
          </LinkWrapper>
        </HeaderSide>
        <HeaderCenter>
          <ButtonPillSwitch
            defaultValue={tabs.publish.value}
            values={tabs}
            onChange={newTab => setActiveTab(newTab)}
          />
        </HeaderCenter>
        <HeaderSide>
          <Actions>
            {isPublishAvailable && (
              <Button
                disabled={
                  numberOfPublishItemsSelected < 1 ||
                  videoTitleForPublish === '' ||
                  facebookUploading ||
                  linkedinUploading ||
                  youtubeUploading
                }
                variant='primary'
                text={publishButtonText}
                onClick={handlePublish}
                icon={<IoSend />}
              />
            )}
          </Actions>
        </HeaderSide>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <LoadingIndicator isLoading={isLoading} />
        ) : !isPublishAvailable ? (
          <PublishNotAvailable />
        ) : (
          <ContentWrapper>
            <Main>
              {hasIntegrations.facebook && (
                <PublishItem
                  itemKey='facebook-publish-item'
                  isChecked={facebookPublishSelected}
                  onChange={() =>
                    setFacebookPublishSelected(!facebookPublishSelected)
                  }
                  icon={<FaFacebookF size='30' color='#1877F2' />}
                  publishText='Publish to Facebook'
                  publishPlace={facebookPage}
                  social='Facebook'
                  privatePublishing
                />
              )}
              {hasIntegrations.youtube && (
                <PublishItem
                  itemKey='youtube-publish-item'
                  isChecked={youtubePublishSelected}
                  onChange={() =>
                    setYoutubePublishSelected(!youtubePublishSelected)
                  }
                  icon={<FaYoutube size='30' color='#EF3E37' />}
                  publishText='Publish to YouTube'
                  publishPlace={youtubeChannel}
                  social='YouTube'
                />
              )}
              {hasIntegrations.linkedin && (
                <PublishItem
                  itemKey='linkedin-publish-item'
                  isChecked={linkedinPublishSelected}
                  onChange={() =>
                    setLinkedinPublishSelected(!linkedinPublishSelected)
                  }
                  icon={<FaLinkedin size='30' color='#007AB6' />}
                  publishText='Publish to LinkedIn'
                  publishPlace={linkedinPage}
                  social='LinkedIn'
                />
              )}
              {uploadLogs && (
                <PublishHistoryContainer>
                  <FlexRowContainer justifyContent='space-between'>
                    <Label>Publish history</Label>
                    <SeeAnalyticsText
                      onClick={() =>
                        history.push(
                          `/classic/edit-video/${video.id}/analytics?tab=3`
                        )
                      }
                    >
                      See Analytics
                    </SeeAnalyticsText>
                  </FlexRowContainer>
                  {uploadLogs &&
                    uploadLogs.map(
                      (item: IAnalyticsLogsProps, index: number) => (
                        <PublishHistoryItem
                          key={`publish-history-item-${index}`}
                        >
                          <PublishHistoryItemText>
                            Published to {capitalizeWords(item.integrationType)}{' '}
                            on{' '}
                            {dayjs(new Date(item.publishedAt)).format(
                              STANDARD_DATE_FORMAT
                            )}{' '}
                            at{' '}
                            {dayjs(new Date(item.publishedAt)).format(
                              'hh:mm A'
                            )}
                          </PublishHistoryItemText>
                        </PublishHistoryItem>
                      )
                    )}
                </PublishHistoryContainer>
              )}
            </Main>
            <Side>
              <VideoTitleAndThumbnail video={video} />
              <FormGroup disabled={numberOfPublishItemsSelected < 1}>
                <Label disabled={numberOfPublishItemsSelected < 1}>
                  Video Title
                </Label>
                <Input
                  onChange={(e: any) =>
                    handleVideoTitleForPublishChange(e.target.value)
                  }
                  value={videoTitleForPublish}
                  hasError={videoTitleForPublish === ''}
                  placeholder={'Enter video title'}
                  disabled={numberOfPublishItemsSelected < 1}
                />
              </FormGroup>
              <FormGroup disabled={numberOfPublishItemsSelected < 1}>
                <Label>Video Description</Label>
                <TextEditor
                  height={'160px'}
                  customPadding={'10px 12px'}
                  onTextEditorChange={handleVideoDescriptionChange}
                  initialContent={''}
                  placeholder={'Describe your video'}
                  toolbar={videoDescriptionToolbar}
                  hideAddVariableButton={true}
                />
              </FormGroup>
            </Side>
          </ContentWrapper>
        )}
      </ModalBody>
    </TabWrapper>
  );
};
