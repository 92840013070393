import React from 'react';
import {
  EmailBuilderElement,
  EmailBuilderElementType,
  EmailContent,
} from '../types';
import styled, { css } from 'styled-components/macro';
import {
  generateVideoThumbnailHTML,
  getMaxHeight,
  getThumbnailFromOptionAndVideo,
} from '../utils';
import { VideoListItem } from 'lib/api';
import { theme } from 'lib/style';
import { Spinner } from 'lib/components';
import {
  Aligments,
  Sizes,
  EMAIL_BUILDER_BORDER_RADIUS,
  EMAIL_DESKTOP_WIDTH,
  MAX_THUMBNAIL_WIDTH,
  MAX_THUMBNAIL_MOBILE_WIDTH,
} from '../const';
import useImageLoadRetry from 'lib/hooks/useImageLoadRetry';
import { useAuth } from 'lib/context';

const Wrapper = styled.div`
  cursor: pointer;
  display: block;
  width: 100%;
  word-break: break-word;
  a {
    pointer-events: none;
  }
  .covideo-signature-class {
    margin: 0;
    font-size: 16px;
  }
  .ql-font-serif {
    font-family:
      Georgia,
      Times New Roman,
      serif;
  }
  .ql-font-monospace {
    font-family:
      Monaco,
      Courier New,
      monospace;
  }
  .ql-indent-1 {
    padding-left: 3em;
  }
  .ql-indent-2 {
    padding-left: 6em;
  }
  .ql-indent-3 {
    padding-left: 9em;
  }
  .ql-indent-4 {
    padding-left: 12em;
  }
  .ql-indent-5 {
    padding-left: 15em;
  }
  .ql-indent-6 {
    padding-left: 18em;
  }
  .ql-indent-7 {
    padding-left: 21em;
  }
  .ql-indent-8 {
    padding-left: 24em;
  }
  .ql-size-small {
    font-size: 0.75em;
  }
  .ql-size-large {
    font-size: 1.5em;
  }
  .ql-size-huge {
    font-size: 2.5em;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
    margin-left: 0;
  }
`;

type ImageWrapperProps = {
  textAlign: string;
  maxWidth: string;
};

const ImageWrapper = styled.div<ImageWrapperProps>`
  text-align: ${({ textAlign }) => textAlign};
  max-width: ${({ maxWidth }) => maxWidth};
`;

type ImageElementProps = {
  size: string;
  borderRadius: string;
  visible: boolean;
  maxHeight: string;
  maxWidth: string;
  isPortrait: boolean;
};

const ImageElement = styled.img<ImageElementProps>`
  ${props =>
    !!props.isPortrait
      ? `max-height:${props.maxHeight};
          width: auto;
        `
      : `
       width:${props.size};
       max-width:${props.maxWidth};
       height:auto;
      `}
  border-radius: ${({ borderRadius }) => borderRadius};
  ${({ visible }) =>
    !visible &&
    css`
      opacity: 0;
      position: absolute;
      height: 1px;
      width: 1px;
    `};
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  gap: 12px;
  background-color: ${theme.palette.gray100};
  > span {
    color: ${theme.palette.white};
  }
`;

type Props = {
  video: VideoListItem;
  element: EmailBuilderElement;
  onClick: () => void;
  setEmailContent: React.Dispatch<React.SetStateAction<EmailContent>>;
  from: string;
};

function getThumbnailUrl(thumbnail: string = '') {
  const isLegacyURL = thumbnail.startsWith('user_logos');
  if (isLegacyURL) {
    return `https://www.covideo.com/${thumbnail}`;
  }
  const isFullURL = thumbnail.startsWith('https');
  if (isFullURL) {
    return thumbnail;
  }
  // added condition to check if thumbnail exists or not SUS-648
  if (thumbnail) {
    return `https://www.covideo.com/EmailTemplateThumbs/${thumbnail}`;
  }

  return thumbnail;
}

export const EmailBuilderElementPreview = ({
  element,
  onClick,
  video,
  setEmailContent,
  from,
}: Props) => {
  const [aspectRatio, setAspectRatio] = React.useState<number | null>(null);
  const { userData } = useAuth();
  const isVideoThumbElement =
    element.type === EmailBuilderElementType.VIDEO_THUMB;
  let thumbnail = isVideoThumbElement
    ? getThumbnailFromOptionAndVideo({
        thumbnailOption: element.thumbnailOption,
        video,
      })
    : '';

  thumbnail = getThumbnailUrl(thumbnail);
  const src = `${thumbnail}${
    thumbnail.indexOf('?') > -1 ? '&' : '?'
  }timeStamp=${Date.now()}`;
  const { imgRef, retryCount, error } = useImageLoadRetry({
    src,
    onLoad: (width: number, height: number) => {
      if (width > 0 && height > 0) {
        setAspectRatio(width / height);
      }
    },
  });
  const thumbnailAvailable = !error && retryCount === 0;
  const processingMessage = error
    ? 'Thumbnail not available, try changing video thumbnail option. '
    : 'Processing...';

  React.useEffect(() => {
    if (!aspectRatio || (aspectRatio >= 1 && !element.maxWidth)) {
      return;
    }

    const isPortrait = aspectRatio < 1;
    const maxWidth = isPortrait ? MAX_THUMBNAIL_MOBILE_WIDTH : undefined;
    const html = generateVideoThumbnailHTML({
      from,
      thumbnail,
      width: element.sizeOption,
      borderRadius: element.isBorderRounded ? EMAIL_BUILDER_BORDER_RADIUS : '0',
      align:
        element.sizeOption === Sizes.FULL
          ? Aligments.CENTER
          : element.alignmentOption,
      maxWidth,
      isPortrait,
      customerId: userData?.customerId,
      videoShareLinkText: userData?.videoShareLinkText,
    });

    setEmailContent(prev => {
      const newElements = [...(prev.elements || [])].map(ele => {
        if (ele.type === EmailBuilderElementType.VIDEO_THUMB) {
          ele.thumbnailOption = element.thumbnailOption;
          ele.alignmentOption =
            element.sizeOption === Sizes.FULL
              ? Aligments.CENTER
              : Aligments.LEFT;
          ele.html = html;
          ele.maxWidth = maxWidth;
          ele.isPortrait = isPortrait;
        }
        return ele;
      });
      return { ...prev, elements: newElements };
    });
  }, [aspectRatio]);

  return (
    <Wrapper onClick={onClick}>
      {!isVideoThumbElement && (
        <div dangerouslySetInnerHTML={{ __html: element.html }}></div>
      )}
      {isVideoThumbElement && (
        <ImageWrapper
          textAlign={element.alignmentOption || Aligments.LEFT}
          maxWidth={
            (aspectRatio || 1) < 1
              ? `${MAX_THUMBNAIL_MOBILE_WIDTH}px`
              : `${EMAIL_DESKTOP_WIDTH}px`
          }
        >
          <ImageElement
            src={src}
            key={retryCount}
            ref={imgRef}
            alt='Video Thumbnail'
            width={element.sizeOption}
            maxWidth={
              !!element.isPortrait
                ? `${MAX_THUMBNAIL_MOBILE_WIDTH}px`
                : `${MAX_THUMBNAIL_WIDTH}px`
            }
            maxHeight={`${getMaxHeight({
              size: element.sizeOption || Sizes.THREE_QUARTER,
            })}px`}
            isPortrait={!!element.isPortrait}
            size={element.sizeOption || Sizes.THREE_QUARTER}
            borderRadius={
              element.isBorderRounded ? EMAIL_BUILDER_BORDER_RADIUS : '0'
            }
            visible={thumbnailAvailable}
          />
          {!thumbnailAvailable && (
            <SpinnerWrapper>
              <span>{processingMessage}</span>
              <Spinner />
            </SpinnerWrapper>
          )}
        </ImageWrapper>
      )}
    </Wrapper>
  );
};
