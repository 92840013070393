import React from 'react';
import styled from 'styled-components/macro';
import { TableField } from 'lib/context';
import {
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
  Table,
  CheckboxInput,
} from 'lib/components';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import { FaChevronRight } from 'react-icons/fa';
import { theme } from 'lib/style';
import { CommonTypography } from 'lib/components/styles/typography';
import { Reseller } from 'lib/context';
import { SortButtons } from '../../components/SortButtons';
import { Layout } from '../../index.styled';
import { BadgeType, Badge } from 'lib/components/Badge';

export enum ResellerTableColumns {
  ACCESS = 'access',
  RESELLER_NAME = 'resellerName',
  RESELLER_ID = 'resellerId',
  STATUS = 'status',
  URL = 'url',
  PHONE = 'phone',
  PLAYBACK_URL = 'playbackUrl',
}
const TableCell = styled.div<{
  width?: number;
  cursor?: 'pointer' | 'default' | 'auto';
  alignItems?: string;
  textAlign?: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  cursor: ${props => (props.cursor ? props.cursor : 'auto')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
`;

const PaginationWrapper = styled.div`
  width: 100%;
`;

const ResellerTitle = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: ${theme.palette.gray100};
`;

const NormalText = styled(CommonTypography).attrs({ as: 'p' })`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${theme.palette.gray100};
`;

const ActionWrapper = styled.div`
  padding: 12px;
`;

type Props = {
  resellerCount: number;
  resellersArray: Reseller[];
  page: number;
  size: number;
  sortKey?: string;
  order?: PaginationConstants.ASCENDING | PaginationConstants.DESCENDING;
  tableFields: TableField[];
  onPaginationChange({ page, size }: { page: number; size: number }): void;
  openResellerDetails(reseller: Reseller): void;
  onSortingUpdate(sortKey: string, order: PaginationConstants): void;
  onResellerSelect: (reseller: Reseller, checked: boolean) => void;
  selectedResellersData: any[];
};

export const ResellerTable = ({
  resellerCount,
  resellersArray,
  page,
  size,
  sortKey,
  order,
  tableFields,
  onPaginationChange,
  openResellerDetails,
  onSortingUpdate,
  onResellerSelect,
  selectedResellersData,
}: Props) => {
  return (
    <Layout>
      <TableContextProvider
        total={resellerCount}
        onChange={onPaginationChange}
        initPage={page}
        initSize={size}
      >
        <div
          className='scroll--container'
          style={{ overflowX: 'auto', paddingBottom: '20px' }}
        >
          <Table
            compact={true}
            relative={true}
            headers={[
              ...tableFields.map((item, index) => {
                return (
                  <TableCell key={index} width={item.width} onClick={() => {}}>
                    {item.label}
                    {item.sortable && item.sortKey && (
                      <SortButtons
                        isSorted={sortKey === item.sortKey}
                        order={order}
                        onChange={value =>
                          onSortingUpdate(item.sortKey ?? '', value)
                        }
                      />
                    )}
                  </TableCell>
                );
              }),
              '',
            ]}
            hoverable={false}
            rows={resellersArray.map((reseller: Reseller, index: number) => ({
              key: index,
              columns: [
                ...tableFields.map((item, index) => {
                  let columnValue = <></>;
                  switch (item.value) {
                    case ResellerTableColumns.ACCESS:
                      columnValue = (
                        <TableCell width={40} alignItems='center'>
                          <CheckboxInput
                            width='24px'
                            blueCheck={true}
                            checked={selectedResellersData.includes(reseller)}
                            onChange={(e: React.SyntheticEvent) => {
                              e.stopPropagation();
                              let { checked } = e.target as HTMLInputElement;
                              onResellerSelect(reseller, checked);
                            }}
                          />
                        </TableCell>
                      );
                      break;
                    case ResellerTableColumns.RESELLER_NAME:
                      columnValue = (
                        <ResellerTitle>{reseller.resellerName}</ResellerTitle>
                      );
                      break;
                    case ResellerTableColumns.RESELLER_ID:
                      columnValue = (
                        <NormalText>{reseller.resellerId}</NormalText>
                      );
                      break;
                    case ResellerTableColumns.STATUS:
                      columnValue = (
                        <Badge
                          type={
                            !!reseller.active
                              ? BadgeType.LIGHT_ORANGE
                              : BadgeType.GRAY
                          }
                          text={!!reseller.active ? 'ACTIVE' : 'INACTIVE'}
                        />
                      );
                      break;
                    case ResellerTableColumns.URL:
                      columnValue = <NormalText>{reseller.url}</NormalText>;
                      break;
                    case ResellerTableColumns.PHONE:
                      columnValue = (
                        <NormalText>{reseller.phoneNumber}</NormalText>
                      );
                      break;
                    case ResellerTableColumns.PLAYBACK_URL:
                      columnValue = (
                        <NormalText>{reseller.playbackUrl}</NormalText>
                      );
                      break;
                    default:
                      columnValue = <></>;
                  }
                  return (
                    <TableCell
                      key={index}
                      width={item.width}
                      onClick={() => {}}
                    >
                      {columnValue}
                    </TableCell>
                  );
                }),
                <TableCell
                  textAlign='center'
                  alignItems='center'
                  cursor='pointer'
                  onClick={() => openResellerDetails(reseller)}
                >
                  <ActionWrapper>
                    <FaChevronRight size={12} />
                  </ActionWrapper>
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
        </div>
        <PaginationWrapper>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew text='Show rows:' />
          </TableFooter>
        </PaginationWrapper>
      </TableContextProvider>
    </Layout>
  );
};
