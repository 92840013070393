import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Gap } from '../../styles/layout';
import { IoMdCloseCircle } from 'react-icons/io';
import { ParagraphSmallBold } from '../../styles/typography';
import { emailFormatCheck } from 'lib/utils/emailFormatCheck';
import {
  Email,
  EmailItem,
  InputContainer,
  InputWrapper,
  MultiEmailInputWrapper,
  StyledInput,
  ValueItem,
  ValuesContainer,
} from './styles';
import { theme } from 'lib/style';
import { useAuth } from 'lib/context';
import { debounce } from 'lodash';
import { useContactsQuery } from 'lib/api/contacts/useContactsQuery';
import { useLeadsQuery } from 'lib/api/leads/getLeads';
import { LoadingIndicator } from 'lib/components';
import { checkAttendeeLimit } from 'lib/utils/productFeature';

interface IMultiEmailInput {
  emails: string[];
  onEmailsChange: (emails: string[]) => void;
  disabled?: boolean;
}

const MultiEmailInput = ({
  disabled = false,
  onEmailsChange,
  emails,
}: IMultiEmailInput) => {
  const { userData } = useAuth();
  const { customer } = userData;
  const attendeeLimit = checkAttendeeLimit(userData);
  const leadsIntegrationActive = !!customer?.leadsIntegrationActive;
  const [emailInput, setEmailInput] = useState('');
  const [currentEmails, setCurrentEmails] = useState<string[]>(emails);
  const [error, setError] = useState('');
  const [recipients, setRecipients] = useState<string[] | undefined>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    refetch: refetchContacts,
  } = useContactsQuery(
    { size: 50, search },
    !leadsIntegrationActive && !!search.trim().length
  );

  const {
    data: leads,
    refetch: refetchLeads,
    isLoading: isLoadingLeads,
  } = useLeadsQuery(
    { size: 50, search },
    leadsIntegrationActive && !!search.trim().length
  );

  const fetchRecipientsDebounced = useCallback(
    debounce(async searchValue => {
      setSearch(searchValue);
    }, 200),
    []
  );

  useEffect(() => {
    if (emailInput.length === 0) {
      setError('');
    }
  }, [emailInput]);

  useEffect(() => {
    fetchRecipientsDebounced(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (!search.trim()) {
      return;
    }
    if (leadsIntegrationActive) {
      refetchLeads();
    }
    if (!leadsIntegrationActive) {
      refetchContacts();
    }
  }, [search, leadsIntegrationActive]);

  const leadEmails = leads?.leads.map(lead => lead.email);
  const contactEmails = contacts?.contacts.map(contact => contact.email);

  const handleRecipientInputChange = (inputValue: string) => {
    setSearchValue(inputValue);
  };

  const filterSelectedRecipients = (recipients: string[] | undefined) => {
    if (!recipients) {
      return;
    }
    const filtered = recipients.filter(email => {
      return !currentEmails.includes(email ?? '');
    });
    setRecipients(filtered);
  };

  useEffect(() => {
    if (leadsIntegrationActive) {
      filterSelectedRecipients(leadEmails);
    }
    if (!leadsIntegrationActive) {
      filterSelectedRecipients(contactEmails);
    }
  }, [leadsIntegrationActive, leads, contacts]);

  const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmailInput(value);
    handleRecipientInputChange(value);
    if (!emailFormatCheck(value) && value !== '') {
      setError('Email address format is not recognized');
    } else {
      setError('');
    }
  };

  const hasExceededLimit = () => {
    return currentEmails.length + 1 >= attendeeLimit;
  };

  const handleSuccessfulValidation = (emailInput: string) => {
    setCurrentEmails(prev => {
      const newState = [...prev, emailInput.trim()];
      onEmailsChange(newState);
      return newState;
    });
    setEmailInput('');
    setError('');
    setRecipients([]);
  };

  const checkEmailFormat = (emailInput: string) => {
    const trimmedEmail = emailInput.trim();
    if (emailInput.length === 0) {
      return;
    }
    if (currentEmails.includes(trimmedEmail)) {
      setError('Email address already added');
      return;
    }
    if (emailFormatCheck(trimmedEmail)) {
      handleSuccessfulValidation(emailInput);
    } else {
      setError('Email address format is not valid');
    }
  };

  const handleEmailDropdownValueClick = (recipient: string) => {
    checkEmailFormat(recipient);
  };

  const handleEmailInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Enter' || e.key === ' ') && emailInput) {
      checkEmailFormat(emailInput);
    }
  };

  const handleEmailDelete = (emailToDelete: string) => {
    setCurrentEmails(prevState => {
      const newState = prevState.filter(
        currentEmail => currentEmail !== emailToDelete
      );
      onEmailsChange(newState);
      return newState;
    });
  };

  const loading = isLoadingContacts || isLoadingLeads;

  return (
    <>
      <MultiEmailInputWrapper>
        <div style={{ opacity: error !== '' ? '100%' : '0%', height: '20px' }}>
          <ParagraphSmallBold color={theme.palette.red100} m='0 0 0 0'>
            {error}
          </ParagraphSmallBold>
        </div>
        <InputContainer>
          <InputWrapper disabled={disabled}>
            <Gap flexDirection='row' gap='8px' flexWrap='wrap'>
              {currentEmails.map((email, i) => (
                <EmailItem key={`${email}${i}`}>
                  <Email>{email}</Email>
                  <IoMdCloseCircle
                    color={theme.palette.blue40}
                    size={13}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEmailDelete(email)}
                  />
                </EmailItem>
              ))}
              {!hasExceededLimit() && (
                <StyledInput
                  id='multiEmailInput'
                  type='email'
                  placeholder='Ex. john@covideo.com'
                  value={emailInput}
                  onChange={handleEmailInputChange}
                  onKeyDown={handleEmailInputKeyDown}
                  error={error}
                  multiple={true}
                  disabled={disabled}
                  onBlur={() => checkEmailFormat(emailInput)}
                />
              )}
            </Gap>
          </InputWrapper>
          {emailInput && recipients && recipients.length !== 0 && (
            <ValuesContainer hasPadding={loading}>
              {!loading ? (
                recipients.map((recipient, i) => (
                  <ValueItem
                    key={`${recipient}${i}`}
                    onClick={() =>
                      handleEmailDropdownValueClick(recipient ?? '')
                    }
                  >
                    {recipient}
                  </ValueItem>
                ))
              ) : (
                <LoadingIndicator isLoading={loading} height='60px' />
              )}
            </ValuesContainer>
          )}
        </InputContainer>
      </MultiEmailInputWrapper>
    </>
  );
};

export default MultiEmailInput;
