import {
  IOrganizationRegion,
  IUserOrganizationAccess,
} from 'lib/api/organizations/types';
import { NewModal, Switch } from 'lib/components';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { theme } from 'lib/style';
import {
  useDeleteOrganiztionAccess,
  useUpdateOrganizationAccess,
} from 'lib/api';
import { ModalPrompt } from 'lib/components/modal';
import { Button } from 'react-covideo-common';

const Body = styled.div``;

const LoginAsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const PillContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-left: 10px;
  }
  font-weight: 600;
`;

const RegionContainer = styled.div`
  border-radius: 6px;
  margin-bottom: 32px;
  max-height: 350px;
  overflow-y: auto;
`;

type RegionProps = {
  isTitle?: boolean;
};

const RegionCard = styled.div<RegionProps>`
  background-color: ${theme.palette.covideoBlue02NonAlpha};
  color: ${props =>
    props.isTitle
      ? `${theme.palette.neutral60}`
      : `${theme.palette.neutral100}`};

  font-size: 14px;
  padding: ${props => (props.isTitle ? '16px' : '12px 16px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: ${props =>
    props.isTitle ? 'none' : `1px solid ${theme.palette.covideoBlack05}`};

  input[type='checkbox'] {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type ConfigureProps = {
  handleModalClose: () => void;
  userAccessInfo: Partial<IUserOrganizationAccess>;
  organizationRegions: Partial<IOrganizationRegion>[];
  organizationId: number;
  organizationName: string;
};

const ConfigureAccessModal = ({
  handleModalClose,
  userAccessInfo,
  organizationRegions,
  organizationId,
  organizationName,
}: ConfigureProps) => {
  const { firstName, lastName } = userAccessInfo?.user || {};
  const themes = useTheme();
  const [isLoginAsEnabled, setIsLoginasEnabled] = useState(
    !!userAccessInfo?.enableSignInAs
  );
  const [selectedRegions, setSelectedRegions] = useState<number[]>(
    userAccessInfo?.limitToRegions || []
  );
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const { mutateAsync: updateOrganizationAccess } =
    useUpdateOrganizationAccess(organizationId);
  const { mutateAsync: deleteOrganizationAccess } =
    useDeleteOrganiztionAccess(organizationId);

  const onSaveChanges = async () => {
    await updateOrganizationAccess({
      userOrganizationAccessId: userAccessInfo.id || 0,
      limitToRegions: selectedRegions,
      enableSignInAs: isLoginAsEnabled,
      organizationId,
    });

    handleModalClose();
  };

  const handleRegionToggle = (regionId: number) => {
    const exists = selectedRegions.some((regId: number) => regId === regionId);
    if (exists) {
      setSelectedRegions(
        selectedRegions.filter((regId: number) => regId !== regionId)
      );
      return;
    }

    setSelectedRegions([...selectedRegions, regionId]);
  };

  const handleSelectAll = () => {
    setSelectedRegions(
      organizationRegions.map(
        orgRegion => Number(orgRegion.organizationRegionId) || 0
      )
    );
  };

  return isRemoveModalOpen ? (
    <ModalPrompt
      title={`Remove ${firstName} ${lastName} from Organization`}
      content={`Are you sure you want to remove access to ${organizationName} for ${firstName} ${lastName}?`}
      primaryButtonText='Yes, Remove Access'
      primaryButtonType='destructive'
      secondaryButtonText='No, Keep Access'
      handleSubmit={() => {
        deleteOrganizationAccess({
          organizationId,
          userOrganizationAccessId: userAccessInfo.id || 0,
        });
        handleModalClose();
      }}
      handleModalClose={() => setIsRemoveModalOpen(false)}
    />
  ) : (
    <NewModal
      closeModal={handleModalClose}
      headerText={`Configure Access for ${firstName} ${lastName}`}
    >
      <Body>
        <LoginAsContainer>
          <PillContainer>
            Log in as other users
            <Switch
              id='loginas'
              handleToggle={() => setIsLoginasEnabled(!isLoginAsEnabled)}
              isOn={isLoginAsEnabled}
              onColor={themes.colors.primary[100]}
            />
          </PillContainer>
          <Button
            size='small'
            onClick={handleSelectAll}
            text='Give Access to All'
            variant='secondary'
            disabled={selectedRegions.length === organizationRegions.length}
          />
        </LoginAsContainer>

        <RegionContainer>
          <RegionCard isTitle>
            <div>Region</div>
            <div>Region Access </div>
          </RegionCard>

          {organizationRegions.map((region: Partial<IOrganizationRegion>) => (
            <RegionCard key={`region-${region.organizationRegionId}`}>
              <div>{region.name || ''}</div>
              <div>
                <Switch
                  id={`region-${region.organizationRegionId}`}
                  handleToggle={() =>
                    handleRegionToggle(Number(region.organizationRegionId) || 0)
                  }
                  isOn={selectedRegions.some(
                    (selectedRegionId: number) =>
                      Number(region.organizationRegionId) === selectedRegionId
                  )}
                  onColor={themes.colors.primary[100]}
                />
              </div>
            </RegionCard>
          ))}
        </RegionContainer>

        <ButtonContainer>
          <Button
            onClick={() => setIsRemoveModalOpen(true)}
            text='Remove from Organization'
            variant='destructive'
          />

          <Button onClick={onSaveChanges} text='Save Changes' />
        </ButtonContainer>
      </Body>
    </NewModal>
  );
};

export default ConfigureAccessModal;
