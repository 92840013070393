import { useDeleteMeetingMutation } from 'lib/api/meetings/useDeleteMeetingMutation';
import { NewModal } from 'lib/components/modal';
import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import { Button } from 'react-covideo-common';

interface Props {
  meetingId: string;
  handleModalClose: () => void;
  handleCloseAllModals: () => void;
}

export const DeleteMeetingModal = ({
  meetingId,
  handleModalClose,
  handleCloseAllModals,
}: Props) => {
  const { mutateAsync: deleteMeeting, isLoading: isLoadingDelete } =
    useDeleteMeetingMutation(handleCloseAllModals);

  const deleteHandler = async () => {
    await deleteMeeting({
      meetingId,
    });
  };

  return (
    <NewModal closeModal={handleModalClose} headerText={'Delete meeting'}>
      Are you sure you want to delete the meeting?
      <Gap m='24px 0 0' gap='12px' justifyContent='flex-end'>
        <Button
          text='Close'
          variant='secondary'
          onClick={handleModalClose}
          disabled={isLoadingDelete}
        />
        <Button
          text={isLoadingDelete ? 'Deleting...' : 'Delete Meeting'}
          variant='destructive'
          onClick={deleteHandler}
          disabled={isLoadingDelete}
        />
      </Gap>
    </NewModal>
  );
};
