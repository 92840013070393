import { UserOrganizationAccess } from 'lib/context';
import { useQuery } from 'react-query';
import { organizationKeys } from './organizationKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getOrganizationAccess = async (): Promise<UserOrganizationAccess[]> => {
  return (await EXPRESS_API.get(`organization/access`))
    .data as UserOrganizationAccess[];
};

export const useGetOrganizationAccessQuery = () => {
  return useQuery(organizationKeys.organizationAccess(), () =>
    getOrganizationAccess()
  );
};
