import { EXPRESS_API } from 'configs/api/expressApi';
import { AttributeListItem } from '../types';
import { useMutation } from 'react-query';

export const updateOrderAttributes = async (data: AttributeListItem[]) => {
  const response = await EXPRESS_API.put('/video-attributes', data);

  return response.data;
};

export const useUpdateOrderAttributes = () => {
  return useMutation(updateOrderAttributes);
};
