import React from 'react';
import { Field, FieldAttributes } from 'formik';

type FormValuesType = {
  [key: string]: string | number | boolean | Record<string, string | number>;
};

export const InputField = (props: FieldAttributes<FormValuesType>) => {
  return <Field {...props} />;
};
