import { EXPRESS_API } from 'configs/api/expressApi';
import { CreateCarServiceParams } from 'lib/api/carService/types';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { useMutation, useQueryClient } from 'react-query';
import { carServiceKeys } from './carServiceKeys';

const createCarService = async ({ data }: CreateCarServiceParams) => {
  const response = await EXPRESS_API.post(`/services`, data);
  return response.data;
};

export const useCreateCarServiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(createCarService, {
    onSuccess: () => {
      queryClient.invalidateQueries(carServiceKeys.all());
      successToast({
        title: `Service successfully created!`,
      });
    },
    onError: () => {
      errorToast({
        title: `Failed to create service.`,
      });
    },
  });
};
