import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

interface CheckboxI {
  id?: string;
  className?: string;
  boxSize?: 'sm' | 'lg' | null;
  onChange?: any;
  onClick?: any;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  blueCheck?: boolean;
  grayCheck?: boolean;
  checkGroupIndicator?: boolean;
  width?: string;
  ignoreGrayForAllSelect?: boolean;
  dataCy?: string;
}

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 5px;
  display: flex;
`;

const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  margin: 0 auto;
  width: 24px;
  height: 24px;
  ${theme.mediaQueryMinWidth.mb} {
    width: 24px;
    height: 24px;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  border: 0;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
  top: -1px;
  left: -5px;
  &:focus {
    outline: 0;
  }
`;

const CheckboxWrapper = styled.div<CheckboxI>`
  width: ${props => (props.width ? props.width : 'auto')};
`;

const StyledCheckbox = styled.div<CheckboxI>`
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${props =>
    props.checked
      ? `${
          props.blueCheck
            ? props.grayCheck && !props.ignoreGrayForAllSelect
              ? theme.palette.label
              : props.theme.colors.primary[100]
            : props.theme.colors.primary[100]
        }`
      : 'rgba(0, 27, 83, 0.05)'};
  border: ${props =>
    props.checked
      ? props.grayCheck && !props.ignoreGrayForAllSelect
        ? 'none'
        : `3px solid ${
            props.blueCheck ? props.theme.colors.primary[100] : '#ff8c21'
          }`
      : `3px solid ${theme.palette.primaryBlack25Percent}`};
  ${props =>
    props.checkGroupIndicator &&
    `background-color: ${
      props.grayCheck ? '#9297a2' : props.theme.colors.primary[100]
    };
    border: ${
      props.grayCheck ? 'none' : `3px solid ${props.theme.colors.primary[100]}`
    };`}
  position: absolute;
  margin-right: 0.25rem;
  top: 0;
  left: 0px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  cursor: ${props => (props.disabled ? 'normal' : 'pointer')};
  color: white;
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    ${props => props.checkGroupIndicator && 'visibility: visible;'}
  }
  ${theme.mediaQueryMinWidth.mb} {
    width: 24px;
    height: 24px;
    border: ${props =>
      props.checked
        ? props.grayCheck && !props.ignoreGrayForAllSelect
          ? 'none'
          : `2px solid ${props.theme.colors.primary[100]}`
        : props.grayCheck && !props.ignoreGrayForAllSelect
          ? 'none'
          : `2px solid ${theme.palette.primaryBlack25Percent}`};
  }
`;

export const CheckboxInput = (props: CheckboxI) => {
  const {
    boxSize,
    checked,
    blueCheck = false,
    checkGroupIndicator = false,
    grayCheck = false,
    disabled,
    width,
    ignoreGrayForAllSelect = false,
    onClick,
    dataCy,
  } = props;

  return (
    <CheckboxWrapper width={width}>
      <CheckboxContainer
        className={props.className}
        onClick={event => {
          if (onClick) {
            onClick(event);
          }
        }}
      >
        <HiddenCheckbox
          type='checkbox'
          {...props}
          disabled={disabled}
          aria-label='Hidden'
        />
        <StyledCheckbox
          boxSize={boxSize}
          checked={checked}
          blueCheck={blueCheck}
          grayCheck={grayCheck}
          checkGroupIndicator={checkGroupIndicator}
          disabled={disabled}
          ignoreGrayForAllSelect={ignoreGrayForAllSelect}
          data-cy={dataCy}
        >
          {checkGroupIndicator ? (
            <Icon viewBox='0 0 24 24'>
              <polyline points='4,12 20,12' />
            </Icon>
          ) : (
            <Icon viewBox='0 0 24 24'>
              <polyline points='20 6 9 17 4 12' />
            </Icon>
          )}
        </StyledCheckbox>
      </CheckboxContainer>
    </CheckboxWrapper>
  );
};
