import React from 'react';
import { Customer } from 'lib/context';
import { theme } from 'lib/style';
import { MdDeleteForever, MdEdit } from 'react-icons/md';

interface IProps {
  editAction: (customerId: string | number | null) => void;
  deleteAction: (connection: Customer) => void;
  connection: Customer;
}

export const threeDotsActions = ({
  deleteAction,
  editAction,
  connection,
}: IProps) => {
  return [
    {
      id: 'edit',
      text: 'Edit',
      onClick: () => editAction(connection.customerId),
      icon: <MdEdit size='20px' />,
      disabled: false,
    },
    {
      id: 'delete',
      text: 'Delete',
      onClick: () => deleteAction(connection),
      icon: <MdDeleteForever size={20} color={theme.palette.red100} />,
      disabled: false,
    },
  ];
};
