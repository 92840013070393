import React from 'react';
import { useState } from 'react';
import { Button } from 'react-covideo-common';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { updateMultipleCustomers } from 'lib/api';
import { SuperAdminCustomer } from 'lib/context';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { ResellersDropdown } from './ResellersDropdown';

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedCustomers: SuperAdminCustomer[];
};

export const ChangeCustomerResellerModal = ({
  handleModalClose,
  selectedCustomers,
}: Props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [resellerId, setCustomerReseller] = useState<number>(0);
  const headerText = `Change Reseller for ${selectedCustomers.length} customer${
    selectedCustomers.length > 1 ? 's' : ''
  }`;

  const handleUpdateReseller = async () => {
    setLoadingModal(true);
    const count = selectedCustomers.length;
    let errorMessage = '';
    try {
      let customerIds = selectedCustomers.map(customer => customer.customerId);
      await updateMultipleCustomers({
        customerIds: customerIds,
        resellerId,
      });
    } catch (error) {
      errorMessage = `Error in updating customers Reseller.`;
      console.log('Error', error);
    }
    handleModalClose(1);
    if (errorMessage) {
      errorToast({ title: errorMessage });
    } else {
      count &&
        successToast({
          title: `Reseller updated successfully for ${count} customer${
            count > 1 ? 's' : ''
          }`,
        });
    }
    setLoadingModal(false);
  };

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose()}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Header>
        <Content>You are about to {headerText}</Content>
      </Header>
      <Content>
        {!loadingModal && (
          <ResellersDropdown
            menuPosition='fixed'
            showAllResellerOption={false}
            onSelectReseller={setCustomerReseller}
            placeholder={'Change Reseller'}
          />
        )}
        <SubmitButtonWrapper>
          <Button
            text={'Change Reseller'}
            onClick={() => handleUpdateReseller()}
            disabled={!resellerId || loadingModal}
          />
        </SubmitButtonWrapper>
      </Content>
    </NewModal>
  );
};
