import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Details from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path='/admin/organizations' component={List} exact />
      <Route path='/admin/organizations/:id' component={Details} exact />
    </Switch>
  );
};
