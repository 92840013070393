import * as React from 'react';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { MoonLoader } from 'react-spinners';
import { theme } from 'lib/style';
import { Modal } from 'lib/components';
import { ListWebsiteOverlay, uploadImage } from 'lib/api';
import { useLandingPageBuilderContext } from 'app/pages/design/landingPageBuilder/context';
import { createLandingPage } from 'lib/api/designApi';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'lib/context';
import Switch from '../Switch';
import { generateImageFromHTML } from 'lib/utils/generateImageFromHTML';
import { useQueryClient } from 'react-query';
import { TEMPLATE_QUERY } from 'lib/const/SendAndShare';
import { Button } from 'react-covideo-common';
import { useToastError } from 'lib/hooks';
import { IoMdClose } from 'react-icons/io';
interface Props {
  onClose: (params?: any) => void;
  onMainButtonClick: (params?: any) => void;
  onContinueButtonClick: (params?: any) => void;
  data?: ListWebsiteOverlay;
  modalLoading: boolean;
  type: 'add' | 'edit';
  exportRef: any;
}

interface RowProps {
  justifyContent?: string;
  maxWidth?: string;
  marginBottom?: number;
  marginTop?: number;
}

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100%;
  min-height: 100%;
  .containerWidth {
    width: 45%;
  }
  align-items: center;
`;

const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 0 0 100%;
  align-items: center;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 16)}px;
  margin-top: ${props => (props.marginTop ? props.marginTop : 16)}px;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 0;
  }
  button {
    margin-left: 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  color: ${theme.palette.coal};
`;

const CloseIconWrap = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  margin-left: auto;
`;

const Label = styled.div`
  height: auto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${theme.palette.label};
  margin-bottom: 8px;
`;

const TextInput = styled.input`
  display: flex;
  padding-left: 8px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d0d3d9;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
  margin-bottom: 16px
  box-sizing: border-box;
  &:focus {
    outline: 0;
    border-color: ${theme.palette.primaryDarkBlue}
  }
`;

const Form = styled.form`
  width: 100%;
`;

const Col = styled.div`
  height: auto;
  overflow: hidden;
  width: 100%;
`;

const Text = styled.div`
  color: #272a32;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const ModalSaveDesign = (props: Props) => {
  // const [selectedLayout, setSelectedLayout] = useState('');
  const { onClose, modalLoading, exportRef } = props;
  const {
    selectedLayoutData,
    selectedStyle,
    selectedLayout,
    setIsEnabled,
    setSelectedStyle,
    setSelectedLayout,
    setSelectedLayoutData,
    setIsFullScreen,
    pageBackgroundColor,
    pageBackgroundImageUrl,
    setPageBackgroundColor,
    setPageBackgroundImageUrl,
    pageTitle,
    pageId,
    isShared,
    isDefault,
    allowDuplicates,
    selectedStyleProperties,
    setPageTitle,
    setPageId,
    setIsShared,
    setIsDefault,
    setAllowDuplicates,
    setDesignId,
    designId,
    setSelectedStyleProperties,
    setDefaultCta,
    setDefaultSocialLinks,
    setElementToEdit,
    setElementIndex,
    setElementSectionKey,
    builderVersion,
    setIsUpdateMode,
    setIsImageUploadInProgress,
    pageBackgroundSize,
    pageBackgroundPosition,
    setPageBackgroundPosition,
    setPageBackgroundSize,
    setUndoState,
    setRedoState,
  } = useLandingPageBuilderContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { showError } = useToastError();

  const { userData } = useAuth();
  const { userId } = userData;

  const modalTitle = 'Save Landing Page Design';
  const [errors] = useState({
    designName: '',
  });
  const themes = useTheme();

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        save(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [pageTitle]);

  async function save(e: any) {
    e?.preventDefault();
    if (!pageTitle) {
      return;
    }

    const thumbnail = await generateImageFromHTML(
      exportRef.current,
      `landing_page_design_thumbnail_${pageTitle}`
    );
    const thumbnailUrl = await uploadImage(thumbnail, userId);

    let requestData: { [keyed: string]: any } = {
      title: pageTitle,
      pageData: {
        selectedLayoutData: selectedLayoutData,
        pageDetails: {
          backgroundColor: pageBackgroundColor,
          backgroundImageUrl: pageBackgroundImageUrl,
          pageBackgroundSize: pageBackgroundSize,
          pageBackgroundPosition: pageBackgroundPosition,
          selectedStyleProperties: selectedStyleProperties,
        },
      },
      isDefault: isDefault || false,
      allowDuplicates: allowDuplicates,
      isShared: isShared,
      layoutName: selectedLayout,
      styleName: selectedStyle,
      builderVersion: builderVersion,
      userId: Number(userData.userId),
      thumbnailSrc: thumbnailUrl,
    };

    if (pageId) {
      requestData['id'] = pageId;
    }

    if (designId) {
      requestData['designId'] = designId;
    }
    try {
      await createLandingPage(requestData);
      onClose();
      setIsEnabled(false);
      history.push('/design/landing-pages');
      setSelectedStyle('');
      setSelectedLayout('');
      setSelectedLayoutData(null);
      setIsFullScreen(false);
      setPageBackgroundColor(null);
      setPageBackgroundImageUrl(null);
      setPageTitle('');
      setPageId(null);
      setDesignId(null);
      setAllowDuplicates(false);
      setIsDefault(false);
      setIsShared(false);
      setSelectedStyleProperties({});
      setElementToEdit(null);
      setElementIndex('');
      setElementSectionKey('');
      setDefaultCta(null);
      setDefaultSocialLinks(null);
      setIsUpdateMode(false);
      setIsImageUploadInProgress(false);
      setPageBackgroundSize('cover');
      setPageBackgroundPosition('left top');
      setUndoState([]);
      setRedoState([]);
      queryClient.invalidateQueries([TEMPLATE_QUERY]);
    } catch (error) {
      showError(error || 'Error creating landing page');
    }
  }

  return (
    <Modal widthOnDesktop={'45%'}>
      <Content>
        {modalLoading && (
          <LoaderWrapper>
            <MoonLoader
              size={40}
              color={themes.colors.primary[100]}
              loading={true}
            />
          </LoaderWrapper>
        )}
        <Row marginBottom={5}>
          <Title>{modalTitle}</Title>
          <CloseIconWrap>
            <IoMdClose
              size={24}
              onClick={onClose}
              color={theme.palette.midGrey}
            />
          </CloseIconWrap>
        </Row>
        <Row marginTop={5}>
          <Form>
            <Row marginTop={5}>
              <Col className='input' style={{ height: 'auto', flex: 1 }}>
                <Label>Design Name</Label>
                <div style={{ display: 'flex' }}>
                  <TextInput
                    name='designName'
                    type='text'
                    autoFocus={true}
                    required={true}
                    maxLength={30}
                    placeholder={modalTitle}
                    defaultValue={pageTitle || ''}
                    onChange={(e: any) => {
                      setPageTitle(e.target.value);
                    }}
                  />
                </div>
                {errors.designName && (
                  <Label>
                    <Text>{errors.designName}</Text>
                  </Label>
                )}
              </Col>
            </Row>
            <Row justifyContent={'space-between'}>
              <Label>
                <Text>Share with company</Text>
              </Label>
              <Switch
                id={'switch-share-w-c'}
                isOn={isShared}
                onColor={themes.colors.primary[100]}
                handleToggle={(e: any) => {
                  let isChecked = e.target.checked;
                  setIsShared(isChecked);
                  if (!isChecked) {
                    setAllowDuplicates(isChecked);
                    setIsDefault(isChecked);
                  }
                }}
              />
            </Row>

            <Row marginTop={35} justifyContent={'flex-end'}>
              <Button
                text={'Save Design'}
                onClick={(e: any) => {
                  save(e);
                }}
                disabled={pageTitle.trim() == ''}
              />
            </Row>
          </Form>
        </Row>
      </Content>
    </Modal>
  );
};
