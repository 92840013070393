import { useQuery } from 'react-query';
import { droplrKeys } from './queryKeys';
import { checkDropAuth, client } from './droplrAuth';
import { DropItem } from './types';

export type GetDropParams = {
  dropId: string;
};

export const getDrop = async (dropId: string) => {
  if (!dropId) {
    return;
  }
  await checkDropAuth();

  return client.drops.get(dropId) as DropItem;
};

export const useGetDrop = (params: GetDropParams) => {
  return useQuery(droplrKeys.drop_details(params), () =>
    getDrop(params.dropId)
  );
};
