import { positionsWithIcons } from './positions';

export const initialTemplate = {
  type: 'text',
  title: '',
  position: positionsWithIcons[0].value,
  linkType: 'No URL',
  imageWidth: '',
  imageHeight: '',
  imageOpacity: '50%',
  fontFamily: 'Verdana',
  fontSize: '14',
  textColor: '#FFFFFF',
  backgroundColor: '#000000',
  borderColor: '#000000',
};

export const annotationSteps = {
  SELECT_TEMPLATE: 'select_template',
  STYLE: 'style',
  SAVE_TEMPLATE: 'save_template',
};
