import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { metricsKeys } from './metricsKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import dayjs from 'dayjs';
import { ReportTypes } from 'lib/const/ReportData';

const DATE_FORMAT = 'YYYY-MM-DD';

export type UsersMetricsQueryParams = {
  from: Date;
  to: Date;
  customerId: number | string;
  search: string;
  reports?: ReportTypes[];
  start: number;
  limit: number;
  departmentId?: number | null;
};

export type MetricsUser = {
  recorded: string;
  views: string;
  engagements: string;
  clicks: number;
  sentEmails: number;
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  access: number;
  timestamp: number;
  key: string;
  loginDate: string;
};

interface UsersMetricsResponse {
  count: number;
  users: MetricsUser[];
}

export const getUsersMetrics = async (
  query: UsersMetricsQueryParams
): Promise<UsersMetricsResponse> => {
  const { departmentId, ...rest } = query;
  const params = {
    ...rest,
    from: dayjs(query.from).format(DATE_FORMAT),
    to: dayjs(query.to).format(DATE_FORMAT),
    ...(!!departmentId ? { departmentId } : {}),
  };
  const response = await EXPRESS_API.get(`/metrics/users`, {
    params,
  });
  return response.data;
};

export const useGetUsersMetrics = (
  params: UsersMetricsQueryParams,
  enabled: boolean
) => {
  const { showError } = useToastError();
  return useQuery(metricsKeys.users(params), () => getUsersMetrics(params), {
    onError: showError,
    refetchOnMount: true,
    enabled,
  });
};
