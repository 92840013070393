import { useToastError } from 'lib/hooks';
import { useQuery } from 'react-query';
import { EXPRESS_API } from 'configs/api/expressApi';
import { QueryListParams } from '../types';
import { optoutsKeys } from './optoutsKeys';

export const getOptOuts = async ({
  page = 0,
  size = 10,
  search,
  sort,
}: QueryListParams) => {
  const start = page * size;
  const params = {
    start,
    limit: size,
    search,
    sort,
  };

  const { data } = await EXPRESS_API.get('/opt-outs', { params });

  const optOuts = data?.optOutContacts || [];
  const count = data?.count || 0;
  return { optOuts, count };
};

export const useQueryOptOuts = (params: QueryListParams) => {
  const { showError } = useToastError();
  return useQuery(optoutsKeys.optouts(params), () => getOptOuts(params), {
    onError: showError,
    refetchOnMount: true,
  });
};
