import { ModalUpload, Viewport } from 'lib/components';
import { UploadAndRecordButtons } from 'lib/components/buttons';
import { LoadingIndicator } from 'lib/components/LoadingIndicator';
import { useAuth } from 'lib/context/auth';
import { theme } from 'lib/style';
import { getClassicLayoutVisibility } from 'lib/utils/functions';
import * as React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { useLandingPageBuilderContext } from '../../../app/pages/design/landingPageBuilder/context';
import { AdminSession } from './AdminSession';
import { FreeTrial } from './FreeTrial';
import { PageBodyTemplate } from './PageBodyTemplate';
import { PageHeader } from './PageHeader';
import { SwitchToClassicDesktop } from './SwitchToClassic';
import { showUploadModal, Topbar } from './Topbar';
import { UpgradeToday } from './UpgradeToday';

type Props = {
  children?: never;
  main: React.ReactNode;
  rightSidebar?: React.ReactNode;
  modal?: React.ReactNode[];
  showNavigation?: boolean;
  showUpgradeToday?: boolean;
};

const BottomButtons = styled.div`
  display: block;
  ${theme.mediaQueryMinWidth.md} {
    display: none;
  }
`;

const UploadAndRecordContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
  transition: width 0.2s ease;
  ${theme.mediaQueryMinWidth.md} {
    position: static;
  }
`;

export const PageTemplate = (props: Props) => {
  const { pathname } = useLocation();
  const [isSideNavOpen, setIsSideNavOpen] = React.useState(false);
  const [isLocationChanging, setIsLocationChanging] = React.useState(false);
  const { userData } = useAuth();

  const [isUploadVisible, setIsUploadVisible] = React.useState(false);
  const isModalOpen = props.modal && props.modal.some(element => !!element);
  const handleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };
  const showNavigation = props.showNavigation;
  const showUpgradeToday = props.showUpgradeToday || false;

  const { isFullScreen } = useLandingPageBuilderContext();

  const isNotFound = pathname === '/not-found';
  const isUpgradePage = pathname === '/upgrade';

  const customer = userData.customer;
  let isClassicLayoutVisible = false;

  if (!isNotFound && !isUpgradePage) {
    isClassicLayoutVisible = getClassicLayoutVisibility(customer);
  }

  const locationsEnabled = userData.isMultiLocation;
  return (
    <Viewport>
      <PageHeader username={userData.firstName} />
      {!isFullScreen && (
        <Topbar
          isSideNavOpen={isSideNavOpen}
          handleSideNav={handleSideNav}
          username={userData.firstName}
          showNavigation={showNavigation}
          isLocationChanging={isLocationChanging}
          setIsLocationChanging={setIsLocationChanging}
        />
      )}
      {isUploadVisible && (
        <ModalUpload handleModalClose={() => setIsUploadVisible(false)} />
      )}
      <AdminSession userData={userData} />
      <FreeTrial />
      <UpgradeToday isVisible={showUpgradeToday} />
      {locationsEnabled && isLocationChanging ? (
        <div>
          <LoadingIndicator
            text='Changing location, please wait...'
            color={theme.palette.covideoBlue100}
            isLoading={locationsEnabled && isLocationChanging}
          />
        </div>
      ) : (
        <PageBodyTemplate {...props} />
      )}
      <SwitchToClassicDesktop
        isVisible={isClassicLayoutVisible}
        isModalOpen={isModalOpen}
      />
      <BottomButtons>
        <UploadAndRecordContainer>
          <UploadAndRecordButtons
            onClick={() => showUploadModal(setIsUploadVisible)}
            isBottom={true}
            isModalOpen={isModalOpen}
          />
        </UploadAndRecordContainer>
      </BottomButtons>
    </Viewport>
  );
};
