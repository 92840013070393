import { EXPRESS_API } from 'configs/api/expressApi';
import { useMutation } from 'react-query';

export type GetSignedUrlsResponse = {
  uploadUrl: string;
  downloadUrl: string;
};

export type GetSignedUrlsParams = {
  fileName: string;
  mimeType: string;
  folder: string;
};

export const getVoiceoverSignedURLs = async (
  data: GetSignedUrlsParams
): Promise<GetSignedUrlsResponse> => {
  const result = await EXPRESS_API.post('aws/signedurl/voiceover-audio', data);
  return result.data;
};

export const useAwsVoiceoverAudioSignedUrlMutation = () => {
  return useMutation(getVoiceoverSignedURLs);
};
