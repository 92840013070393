import React from 'react';
import dayjs from 'dayjs';
import { deleteSharedReport, useSharedReportSenderQuery } from 'lib/api';
import {
  LoadingIndicator,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import queryString from 'query-string';

import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { theme } from 'lib/style';
import { MdDeleteForever, MdFileDownload } from 'react-icons/md';
import styled from 'styled-components/macro';
import { SharedReportSearchAndFilter } from '../../components/SharedReportSearchAndFilter';
import { Button, Tooltip } from 'react-covideo-common';
import { useHistory } from 'react-router';
import { timeRangeConstants } from '../../components';
import { Chip } from 'app/pages/account/userAccount/socialProfiles/style';
import {
  SharedReportResponse,
  useSharedReportsQuery,
} from 'lib/api/sharedReports/useSharedReportsQuery';
import { LinkText } from 'app/pages/admin/index.styled';

export type SenderDropdownOptionProps = {
  label: string;
  value: number | null;
};

const TableCell = styled.div<{ clicked?: boolean }>`
  /*** SUS-926 changes ***/
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  max-width: 450px;
  width: fit-content;
`;

const EmptyReports = styled.div`
  text-align: center;
  margin-top: 150px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: end;
`;

const DetailsButtonContainer = styled.div<{ visible: boolean }>`
  height: 42px;
  width: 40px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const tableFields = {
  report: 'Report Name',
  type: 'Type',
  userId: 'Sender',
  dateRange: 'Timeframe',
};

const fields = ['report', 'type', 'userId', 'dateRange'];
const DEFAULT_PAGE = 0;
export const List = () => {
  const history = useHistory();
  const emptyArrayForDropdown = React.useMemo(() => [], []);
  const emptyObjectForSendersMap = React.useMemo(() => ({}), []);
  const [page, setPage] = React.useState(0);
  const [size, setSize] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [sendersMap, setSendersMap] = React.useState<{ [key: number]: string }>(
    emptyObjectForSendersMap
  );
  const [senderDropdownOptions, setSenderDropdownOptions] = React.useState<
    SenderDropdownOptionProps[]
  >(emptyArrayForDropdown);
  const [selectedSenderId, setSelectedSenderId] = React.useState<number | null>(
    null
  );

  const [selectedTypeOption, setSelectedTypeOption] = React.useState<
    string | null
  >(null);

  const [isDownloading, setIsDownloading] = React.useState(false);
  const {
    data,
    isLoading: loading,
    refetch: fetchSharedReports,
  } = useSharedReportsQuery({
    params: {
      page,
      size,
      search,
      senderId: selectedSenderId,
      ...(selectedTypeOption !== null ? { recurring: selectedTypeOption } : {}),
    },
    enabled: true,
  });

  const {
    senders,
    refetch: fetchSenders,
    isLoading: isSendersLoading,
  } = useSharedReportSenderQuery({
    enabled: true,
  });

  React.useEffect(() => {
    if (!senders || !senders.length) {
      setSendersMap(emptyObjectForSendersMap);
      setSenderDropdownOptions(emptyArrayForDropdown);
      return;
    }

    let dropDownData: SenderDropdownOptionProps[] = [
      { label: 'All', value: null },
    ];
    let map: { [key: number]: string } = {};
    senders.forEach((sender: { name: string; userId: number }) => {
      map[sender.userId] = sender.name;
      dropDownData.push({
        label: sender.name,
        value: sender.userId,
      });
    });

    setSendersMap(map);
    setSenderDropdownOptions(dropDownData);
  }, [senders]);

  const onPaginationChange = async ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    if (page !== newPage || size !== newSize) {
      setPage(newPage);
      setSize(newSize);
    }
  };

  const onDropdownSelect = (selectedOption: SenderDropdownOptionProps) => {
    setSelectedSenderId(selectedOption.value);
    setPage(DEFAULT_PAGE);
  };

  const onSearch = (newSearchValue: string) => {
    setSearch(newSearchValue);
    setPage(DEFAULT_PAGE);
  };

  const download = (report: SharedReportResponse, e?: React.MouseEvent) => {
    if (e && e.detail && e.detail > 1) {
      return;
    }

    if (!report.snapshotUrl) {
      errorToast({ title: 'No snapshot available' });
      return;
    }

    setIsDownloading(true);
    const link = document.createElement('a');
    link.href = report.snapshotUrl;
    link.download = report.report;
    link.click();
    setIsDownloading(false);
  };

  const deleteReport = async (reportId: number, event?: React.MouseEvent) => {
    if (event && event.detail && event.detail > 1) {
      return;
    }
    try {
      await deleteSharedReport(reportId);
      await fetchSharedReports();
      await fetchSenders();
      successToast({ title: 'Report deleted successfully' });
    } catch (ex) {
      errorToast({ title: 'Error deleting report' });
    }
  };

  const getActionButtons = (report: SharedReportResponse) => {
    return (
      <ActionButtonContainer>
        <Tooltip popup='Download .csv'>
          <Button
            variant='secondary'
            icon={<MdFileDownload size={24} />}
            disabled={isDownloading}
            onClick={() => {
              download(report);
            }}
          />
        </Tooltip>
        &nbsp;
        <Tooltip popup='Delete'>
          <Button
            variant='destructive'
            icon={<MdDeleteForever size={24} />}
            onClick={(e?: MouseEvent | React.SyntheticEvent) => {
              deleteReport(report.id, e as React.MouseEvent);
              return;
            }}
          />
        </Tooltip>
        &nbsp;
        <DetailsButtonContainer
          visible={!!report.reportId && !!report.customReport}
        >
          <Tooltip popup='Report details'>
            <Button
              variant='primary'
              text='View'
              onClick={() => {
                const params = {
                  start: dayjs(report.from).format(STANDARD_DATE_FORMAT),
                  end: dayjs(report.to).format(STANDARD_DATE_FORMAT),
                  range: timeRangeConstants.CUSTOM,
                  showOwner: true,
                };
                history.push({
                  pathname: `/reports/custom/${report.reportId}`,
                  search: queryString.stringify(params),
                });
              }}
            />
          </Tooltip>
        </DetailsButtonContainer>
      </ActionButtonContainer>
    );
  };

  const rows = (data?.sharedReports || []).map((report, index: number) => ({
    key: index,
    columns: [
      ...fields.map(field => {
        let value = report[field as keyof SharedReportResponse] || <></>;

        if (field === 'dateRange') {
          if (!report.from || !report.to) {
            value = '-';
          } else {
            value = `${dayjs(report.from).format(
              STANDARD_DATE_FORMAT
            )} - ${dayjs(report.to).format(STANDARD_DATE_FORMAT)}`;
          }
        }
        if (field === 'userId') {
          value = `${sendersMap[report.userId] || ''}`;
        }
        if (field === 'type') {
          value = !!report.recurring ? (
            <Chip
              bgColor={theme.palette.covideoBlue02NonAlpha}
              fontColor={theme.palette.covideoBlue80}
              width={'fit-content'}
            >
              Recurring
            </Chip>
          ) : (
            <Chip
              bgColor={theme.palette.covideoBlue02NonAlpha}
              fontColor={theme.palette.covideoBlue80}
              width={'fit-content'}
            >
              Static
            </Chip>
          );
        }
        if (field === 'report') {
          value = (
            <Tooltip popup='Report details' position={'right' as any}>
              <LinkText
                onClick={() => {
                  const params = {
                    start: dayjs(report.from).format(STANDARD_DATE_FORMAT),
                    end: dayjs(report.to).format(STANDARD_DATE_FORMAT),
                    range: timeRangeConstants.CUSTOM,
                    showOwner: true,
                  };
                  history.push({
                    pathname: `/reports/custom/${report.reportId}`,
                    search: queryString.stringify(params),
                  });
                }}
              >
                {value}
              </LinkText>
            </Tooltip>
          );
        }

        return <TableCell clicked={false}>{value}</TableCell>;
      }),
      getActionButtons(report as SharedReportResponse),
    ],
  }));

  return (
    <>
      <SharedReportSearchAndFilter
        onSearch={onSearch}
        dropdownOptions={senderDropdownOptions}
        selectedOption={selectedSenderId}
        prevSearch={search}
        onSelect={onDropdownSelect}
        setSelectedTypeOption={setSelectedTypeOption}
        selectedTypeOption={selectedTypeOption}
      />
      {(loading || isSendersLoading) && (
        <LoadingIndicator
          isLoading={loading || isSendersLoading}
          height='300px'
        />
      )}
      {!loading && !isSendersLoading && (
        <>
          {!!data?.sharedReports?.length && (
            <TableContextProvider
              total={data?.count || 0}
              initSize={size}
              initPage={page}
              onChange={onPaginationChange}
            >
              <Table
                compact={true}
                headers={[
                  ...fields.map(field => {
                    // @ts-ignore
                    return <TableCell>{tableFields[field]}</TableCell>;
                  }),
                  '',
                ]}
                hoverable={false}
                rows={rows}
              />
              <TableFooter>
                <TablePaginationNew />
                <TablePaginationSizeNew globalName='shared_reports_list' />
              </TableFooter>
            </TableContextProvider>
          )}
          {!data?.sharedReports?.length && (
            <EmptyReports style={{ textAlign: 'center', marginTop: 150 }}>
              No data to show.
            </EmptyReports>
          )}
        </>
      )}
    </>
  );
};
