import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Search, Dropdown, LoadingIndicator } from 'lib/components';
import { useAuth } from 'lib/context';
import { TableListing } from '.';
import { AutomotiveRole } from 'lib/const/AutomotiveRole';
import { theme } from 'lib/style';
import { useInventoryQuery } from 'lib/api/inventoryItem';
import { noInventoryItemsMsg } from 'app/pages/library/videos/videoList/components/SendAndShare';

const Content = styled.div`
  margin-top: 32px;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

const NoItems = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: ${theme.palette.gray100};
`;

type FilterProps = {
  margin?: string;
};

const Filter = styled.div<FilterProps>`
  margin: ${props => (props.margin ? props.margin : '0')};
  min-width: 215px;
`;

type InventoryFilters = {
  [key: string]: {
    label: string;
    value: string;
    sold: string;
    recorded: string;
  };
};

const inventoryFilters: InventoryFilters = {
  ONLY_NOT_SOLD: {
    label: 'Only not sold vehicles',
    value: 'ONLY_NOT_SOLD',
    sold: '0',
    recorded: '',
  },
  ALL: {
    label: 'Show All',
    value: 'ALL',
    sold: '2',
    recorded: '',
  },
  ONLY_WITH_VIDEOS: {
    label: 'Only with recorded videos',
    value: 'ONLY_WITH_VIDEOS',
    sold: '2',
    recorded: '1',
  },
  ONLY_WITHOUT_VIDEOS: {
    label: 'Only without recorded videos',
    value: 'ONLY_WITHOUT_VIDEOS',
    sold: '2',
    recorded: '0',
  },
  ONLY_SOLD: {
    label: 'Only sold vehicles',
    value: 'ONLY_SOLD',
    sold: '1',
    recorded: '',
  },
};

const filterOptions = Object.values(inventoryFilters);

export const InventoryListing = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sort, setSort] = useState('');
  const [filterValue, setFilterValue] = useState(
    inventoryFilters.ONLY_NOT_SOLD.value
  );
  const { userData } = useAuth();

  const soldFilter =
    inventoryFilters[filterValue as keyof InventoryFilters].sold;
  const videosRecordedFilter =
    inventoryFilters[filterValue as keyof InventoryFilters].recorded;

  const { data, isLoading: loading } = useInventoryQuery({
    size,
    page,
    sort,
    searchQuery,
    soldFilter,
    videosRecordedFilter,
    countRecorded: true,
    enabled: userData.isIMSEnabled,
  });

  const { count, items } = data || { count: 0, items: [] };

  const onSearch = (query: string) => {
    setPage(0);
    setSearchQuery(query.toLowerCase());
  };

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const sortElement = (sortParam: any) => {
    if (sort.length && sort.includes(sortParam)) {
      const newSort = sort.includes('-') ? sort.replace('-', '') : `-${sort}`;
      setSort(newSort);
    } else if (!sort.includes(sortParam)) {
      const newSort = `${sortParam}`;
      setSort(newSort);
    }
  };

  const isSalesManager =
    userData.automotiveRole === AutomotiveRole.SALES_MANAGER;

  if (!userData.isIMSEnabled) {
    return (
      <Content>
        <NoItems>{noInventoryItemsMsg}</NoItems>
      </Content>
    );
  }

  if (loading) {
    return (
      <Content>
        <LoadingIndicator isLoading={true} height={50} text='' />
      </Content>
    );
  }

  return (
    <Content>
      <FiltersContainer>
        <Filter>
          <Search
            width={'487px'}
            placeholder='Search vehicle by name, VIN or stock number....'
            onSearch={onSearch}
          />
        </Filter>
        {isSalesManager && (
          <Filter margin='0 0 0 15px'>
            <Dropdown
              width={280}
              options={filterOptions}
              value={filterOptions.find(o => {
                return o.value === filterValue;
              })}
              onChange={(option: any) => setFilterValue(option.value)}
              placeholder={'Show all'}
            />
          </Filter>
        )}
      </FiltersContainer>
      <TableListing
        inventoryItemsArray={items}
        loading={loading}
        count={count}
        page={page}
        size={size}
        sort={sort}
        sortElement={sortElement}
        onPaginationChange={onPaginationChange}
      />
    </Content>
  );
};
