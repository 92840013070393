import * as React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'react-covideo-common';
import { NavLink } from 'react-router-dom';
import { theme } from 'lib/style';
import { MdContacts } from 'react-icons/md';
import placeholder from 'assets/images/table/placeholderBig.webp';

const NoContactLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 450px;
  background-image: url(${placeholder});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Text = styled.p`
  font-size: 18px;
  color: ${theme.palette.themeDark};
  text-align: center;
  margin-bottom: 32px;
  margin-top: 0;
`;

const Title = styled(Text)`
  font-weight: bold;
  margin-bottom: 8px;
  color: ${theme.palette.black_1_100};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const NavLinkWrap = styled(NavLink)`
  text-decoration: none;
`;

export const NoGroupContacts = () => {
  return (
    <NoContactLayout>
      <Title>This group has no contacts</Title>
      <Text>Add Contacts to this Group from your Contacts list</Text>
      <Row>
        <NavLinkWrap to='/contacts/list'>
          <Button
            text='View Contacts'
            onClick={() => {}}
            icon={<MdContacts />}
          />
        </NavLinkWrap>
      </Row>
    </NoContactLayout>
  );
};
