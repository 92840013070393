import { useInfiniteQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export type CustomerListParams = {
  limit: number;
  search?: string;
  resellerId?: string;
  start?: number;
};

export interface IResellerCustomersResponse {
  resellerId: number;
  resellerName: string;
  customers: ResellerCustomer[];
  customersCount: number;
}

export interface ResellerCustomer {
  resellerId: number;
  customerId: number;
  business: string;
  users: ResellerUser[];
  departments: Department[];
}

interface Department {
  id: number;
  name: string;
  customerId: number;
  resellerId: number;
}

export interface ResellerUser {
  id: number;
  firstName: string;
  lastName: string;
  mlAutoCreated: boolean;
  access: number;
  departmentId: number;
  customerId: number;
  resellerId: number;
}

const getAllResellerCustomers = async (
  params: CustomerListParams
): Promise<IResellerCustomersResponse> => {
  const { resellerId, ...rest } = params;
  return (
    await EXPRESS_API.get(`/superadmin/reseller/${resellerId}/customers`, {
      params: rest,
    })
  ).data;
};

export const useCustomersResellersQuery = ({
  params,
  enabled,
}: {
  params: CustomerListParams;
  enabled: boolean;
}) => {
  return useInfiniteQuery(
    superAdminKeys.reseller_departments(
      `${params?.resellerId}` || '',
      `${params?.search}` || ''
    ),
    ({ pageParam = 0 }) =>
      getAllResellerCustomers({
        ...params,
        //react query provide to us page param
        start: pageParam * params.limit,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
      getNextPageParam: (_, allPages) => {
        return allPages.length;
      },
    }
  );
};
