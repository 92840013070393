import {
  IIMSConnection,
  IMSConnectedResponse,
} from 'app/pages/admin/ims/types';
import { RESELLER_STATUS_OPTIONS } from 'app/pages/admin/resellers/components/ChangeResellerStatusModal';
import { AxiosError, ResponseType } from 'axios';
import { EXPRESS_API } from 'configs/api/expressApi';
import { saveAs } from 'file-saver';
import { DefaultDataPayload } from 'lib/components/modal/manageDefaults/ManageDefaultTypes';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';
import { CHANGE_ORIGIN } from 'lib/const/AccountChange';
import { PaginationConstants } from 'lib/const/PaginationConstants';
import {
  ORGANIZATIONS_KEY,
  SUPER_ADMIN_IMS_AVAILABILITY,
  SUPER_ADMIN_IMS_CONNECTED,
  SUPER_ADMIN_IMS_SINGLE,
  SUPER_ADMIN_IMS_UNCONNECTED,
} from 'lib/const/SuperAdminConstants';
import {
  CustomerListFilter,
  CustomerStatistics,
  UpdateUsersFastPasswordRequest,
  OptOutListFilter,
  SuperAdminCustomer,
  SuperAdminCustomerDetails,
  SuperAdminOrganization,
  SuperAdminReseller,
  SuperAdminUser,
  UsageReport,
  UsageReportsListFilter,
  UsageReportsStatistics,
  UserDetailsData,
  UserListFilter,
} from 'lib/context';
import { useToastError } from 'lib/hooks';
import { generateLoginAsDetails } from 'lib/utils/superAdmin';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CustomerListParams,
  GetCtaSetResponse,
  GetLandingPagesResponse,
  GetWebsiteOverlaysResponse,
  OptOutContact,
  OptOutListParams,
  OrganizationListParams,
  SuperAdminSearchParams,
  UsageReportsListParams,
  UserListParams,
  UserStatisticsParams,
} from '.';
import { organizationKeys } from './organizations/organizationKeys';
import {
  ICreateOrganization,
  IDeleteUserAccess,
  ILinkCustomer,
  IOrganization,
  IUpdateOrganization,
  IUpdateOrganizationAccess,
  IUserOrganizationAccess,
} from './organizations/types';
import { superAdminKeys } from './superadmin/superAdminKeys';
import dayjs from 'dayjs';

export interface UpdateMultipleUsersBody {
  userIds: number[];
  status?: number;
  customerId?: number;
}
interface UpdateMultipleCustomersBody {
  customerIds: number[];
  status?: number;
  resellerId?: number;
}

interface RemoveOptOutBody {
  contactId: number | string;
}

interface EditUserData {
  id: string;
  data: UserDetailsData;
  changeOrigin: CHANGE_ORIGIN;
}

export const getAllCustomersForSuperAdmin = async (
  params: CustomerListParams
) => {
  const { data } = await EXPRESS_API.get('/superadmin/customers', { params });
  const customers: SuperAdminCustomer[] =
    (data && data.data && data.data.customers) ||
    (data && data.customers) ||
    [];
  const count: number =
    (data && data.data && data.data.count) || (data && data.count) || 0;

  return { customers, count };
};

export const useCustomersQuery = ({
  params,
  filter,
  enabled,
  onSuccess,
}: {
  params: CustomerListParams;
  filter: CustomerListFilter;
  enabled: boolean;
  onSuccess: () => void;
}) => {
  const { data, refetch, isLoading } = useQuery(
    ['super-admin-customers', filter],
    async () => {
      return await getAllCustomersForSuperAdmin(params).catch(ex => {
        throw ex;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
      onSuccess: onSuccess,
    }
  );

  const { count, customers } = data || {
    count: 0,
    customers: [],
  };
  const customerArray: SuperAdminCustomer[] = customers as SuperAdminCustomer[];

  return {
    count,
    customerArray,
    refetch,
    isLoading,
  };
};

export const loginAsForSuperAdmin = async ({
  userId,
  username,
}: {
  userId: number;
  username: string;
}) => {
  const { timestamp, hexKey } = generateLoginAsDetails(userId, username);

  const result = (
    await EXPRESS_API.post(
      `/superadmin/loginas`,
      {
        userId,
        timestamp,
        key: hexKey,
      },
      {
        withCredentials: true,
      }
    )
  ).data;
  return result;
};

export const getAllOrganizationsForSuperAdmin = async (
  params: OrganizationListParams
) => {
  const result = (
    await EXPRESS_API.get(`/superadmin/organizations`, {
      params,
    })
  ).data as {
    organizations: SuperAdminOrganization[];
    count: number;
  };
  const { organizations = [], count = 0 } = result;
  return { organizations, count };
};

export const useOrganizationsQuery = ({
  params,
  enabled,
}: {
  params: OrganizationListParams;
  enabled: boolean;
}) => {
  const { showError } = useToastError();
  const { data, refetch, isLoading } = useQuery(
    ORGANIZATIONS_KEY,
    () => getAllOrganizationsForSuperAdmin(params),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled,
      onError: showError,
    }
  );

  const { count, organizations } = data || {
    count: 0,
    organizations: [],
  };

  return {
    count,
    organizations,
    refetch,
    isLoading,
  };
};

export const getUsageReportsForSuperAdmin = async (
  params: UsageReportsListParams
) => {
  const { data } = await EXPRESS_API.get('/superadmin/usage-reports', {
    params,
  });
  const reports: UsageReport[] =
    (data && data.data && data.data.reports) || (data && data.reports) || [];
  const count: number =
    (data && data.data && data.data.count) || (data && data.count) || 0;

  return { reports, count };
};

export const useUsageReportQuery = ({
  params,
  filter,
  enabled,
}: {
  params: UsageReportsListParams;
  filter: UsageReportsListFilter;
  enabled: boolean;
}) => {
  const { data, refetch, isLoading } = useQuery(
    ['super-admin-usage-reports', filter],
    async () => {
      return await getUsageReportsForSuperAdmin(params).catch(ex => {
        throw ex;
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
    }
  );

  const { count, reports } = data || {
    count: 0,
    reports: [],
  };

  const reportArray: UsageReport[] = reports as UsageReport[];

  return {
    count,
    reportArray,
    refetch,
    isLoading,
  };
};

export const useUsageReportsStatisticsQuery = ({
  params,
  filter,
}: {
  params: UserStatisticsParams;
  filter: UserStatisticsParams;
}) => {
  const {
    data: statsData,
    refetch,
    isLoading,
  } = useQuery(
    ['super-admin-usage-reports-statistics', filter],
    async () => {
      const { data } = await EXPRESS_API.get(
        '/superadmin/usage-reports/statistics',
        { params }
      );
      return (data && data.data) || data || [];
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const statistics = statsData as UsageReportsStatistics;

  return {
    statistics,
    refetch,
    isLoading,
  };
};

export const useCustomersStatisticsQuery = ({
  params,
  filter,
}: {
  params: CustomerListParams;
  filter: CustomerListFilter;
}) => {
  const {
    data: statsData,
    refetch,
    isLoading,
  } = useQuery(
    ['super-admin-customers-statistics', filter],
    async () => {
      const { data } = await EXPRESS_API.get(
        '/superadmin/customers/statistics',
        { params }
      );
      const customerStatistics: SuperAdminCustomer[] =
        (data && data.data) || data || [];
      return customerStatistics;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const statistics = statsData as CustomerStatistics;

  return {
    statistics,
    refetch,
    isLoading,
  };
};

export const useUsersStatisticsQuery = ({
  params,
  filter,
}: {
  params: UserStatisticsParams;
  filter: UserStatisticsParams;
}) => {
  const {
    data: statsData,
    refetch,
    isLoading,
  } = useQuery(
    ['super-admin-users-statistics', filter],
    async () => {
      const { data } = await EXPRESS_API.get('/superadmin/users/statistics', {
        params,
      });
      const userStatistics: SuperAdminUser[] =
        (data && data.data) || data || [];
      return userStatistics;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const statistics = statsData as CustomerStatistics;

  return {
    statistics,
    refetch,
    isLoading,
  };
};

export const useOptOutReportsQuery = ({
  params,
  filter,
  enabled,
  onSuccess,
}: {
  params: OptOutListParams;
  filter: OptOutListFilter;
  enabled: boolean;
  onSuccess: () => void;
}) => {
  const { data, refetch, isLoading } = useQuery(
    ['super-admin-opt-outs', filter],
    async () => {
      const { data } = await EXPRESS_API.get('/superadmin/opt-out-reports', {
        params,
      });
      const optOutReports: OptOutContact[] = data[0] || [];
      const count: number = data[1] || 0;
      return { count, optOutReports };
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
      onSuccess: onSuccess,
    }
  );

  const { count, optOutReports } = data || {
    count: 0,
    optOutReportsArray: [],
  };
  const optOutReportsArray: OptOutContact[] = optOutReports as OptOutContact[];

  return {
    count,
    optOutReportsArray,
    refetch,
    isLoading,
  };
};

export const getCustomer = async (id: number) => {
  const response = await EXPRESS_API.get(`/superadmin/customers/${id}`);
  return response.data;
};

export const editCustomer = async (
  id: number,
  payload: SuperAdminCustomerDetails
) => {
  const data = {
    ...payload,
    activeEndDate: !!payload.activeEndDate
      ? dayjs(payload.activeEndDate).format('YYYY-MM-DD')
      : undefined,
  };
  const response = await EXPRESS_API.put(`/superadmin/customers/${id}`, data, {
    withCredentials: true,
    params: { changeOrigin: CHANGE_ORIGIN.adminCustomerDetails },
  });
  return response.data;
};

export const createCustomer = async (payload: SuperAdminCustomerDetails) => {
  const data = {
    ...payload,
    activeEndDate: !!payload.activeEndDate
      ? dayjs(payload.activeEndDate).format('YYYY-MM-DD')
      : undefined,
  };
  const response = await EXPRESS_API.post(`/superadmin/customers`, data);
  return response.data;
};

export const editUser = async (updatedUserData: EditUserData) => {
  const { id, data, changeOrigin } = updatedUserData;

  const response = await EXPRESS_API.put(`/superadmin/users/${id}`, data, {
    withCredentials: true,
    params: { changeOrigin },
  });
  return response.data;
};

export const updateMultipleCustomers = async (
  data: UpdateMultipleCustomersBody
) => {
  const response = await EXPRESS_API.put(
    `/superadmin/customers/status-and-reseller`,
    data,
    {
      withCredentials: true,
      params: { changeOrigin: CHANGE_ORIGIN.adminManageCustomers },
    }
  );
  return response.data;
};

export const fetchUsers = async (params: UserListParams) => {
  const response = await EXPRESS_API.get('/superadmin/users', { params });
  return response.data;
};

export const useUserQuery = ({
  params,
  filter,
  enabled,
  onSuccess,
}: {
  params: UserListParams;
  filter: UserListFilter;
  enabled: boolean;
  onSuccess: () => void;
}) => {
  const { data, refetch, isLoading, isFetching } = useQuery(
    superAdminKeys.filtered_users_for_superadmin(filter),
    async () => {
      const data = await fetchUsers(params);

      const users: SuperAdminUser[] =
        (data && data.data && data.data.users) || (data && data.users) || [];
      const count: number =
        (data && data.data && data.data.count) || (data && data.count) || 0;
      return { count, users };
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: enabled,
      onSuccess: onSuccess,
    }
  );

  const { count, users } = data || {
    count: 0,
    users: [],
  };
  const userArray: SuperAdminUser[] = users as SuperAdminUser[];

  return {
    count,
    userArray,
    refetch,
    isLoading,
    isFetching,
  };
};

export const updateMultipleUsers = async (data: UpdateMultipleUsersBody) => {
  const response = await EXPRESS_API.put(
    `/superadmin/users/status-and-customer`,
    data,
    {
      withCredentials: true,
      params: { changeOrigin: CHANGE_ORIGIN.adminManageUsers },
    }
  );

  return response.data;
};

export const removeOptInContact = async (data: RemoveOptOutBody) => {
  const response = await EXPRESS_API.delete(`/opt-outs`, {
    data: {
      ids: [data],
    },
  });
  return response.data;
};

export const getUserDetails = async (id: number) => {
  const response = await EXPRESS_API.get(`/superadmin/users/${id}`);
  return response.data;
};

export const getAccountManagers = async () => {
  const response = await EXPRESS_API.get(`/superadmin/account-managers`);
  return response.data;
};

export const getAgents = async () => {
  const response = await EXPRESS_API.get(`/superadmin/agents`);
  return response.data;
};

export const getPackages = async () => {
  const response = await EXPRESS_API.get(`/superadmin/product-packages`);
  return response.data;
};

export const getCrmProviders = async () => {
  const response = await EXPRESS_API.get(`/superadmin/crm-providers`);
  return response.data;
};

export const searchPhoneNumberByLocation = async (
  city: string,
  state: string,
  country: string
) => {
  const response = await EXPRESS_API.post(`/admin/phones/search`, {
    city,
    state,
    country,
  });
  return response.data;
};

export const addUser = async (
  data: UserDetailsData,
  changeOrigin?: CHANGE_ORIGIN
) => {
  const response = await EXPRESS_API.post(`/superadmin/users`, data, {
    params: { changeOrigin },
    withCredentials: true,
  });
  return response.data;
};

export const getResellers = async (
  search: string = '',
  start: number = 0,
  limit: number,
  resellerSortOrder?: string,
  resellerSortKey?: string,
  active?: RESELLER_STATUS_OPTIONS
) => {
  const params = {
    search,
    start,
    limit,
    resellerSortOrder,
    resellerSortKey,
    active,
  };
  const response = await EXPRESS_API.get(`/superadmin/resellers`, { params });
  return response.data;
};

export const getResellerDetails = async (id: number) => {
  const response = await EXPRESS_API.get(`/superadmin/reseller/${id}`);
  return response.data;
};

export const editReseller = async (id: number, data: SuperAdminReseller) => {
  const response = await EXPRESS_API.put(`/superadmin/reseller/${id}`, data);
  return response.data;
};

export const addReseller = async (data: SuperAdminReseller) => {
  const response = await EXPRESS_API.post(`/superadmin/reseller`, data);
  return response.data;
};

export const editUserExtendTrial = async (id: number, numberOfDays: number) => {
  const response = await EXPRESS_API.patch(
    `/superadmin/users/${id}/extend-trial`,
    {
      numberOfDays,
    }
  );
  return response.data;
};

export const exportOptOutReportsData = async (
  name: string,
  params: OptOutListParams
) => {
  const response = await EXPRESS_API.get(
    `/superadmin/opt-out-reports/download`,
    { params, responseType: 'blob' as ResponseType }
  );
  return saveAs(new Blob([response.data]), name);
};

export const exportUsersData = async (
  name: string,
  status: number,
  searchQuery?: string,
  sortKey?: string | undefined,
  order?: string,
  customerId?: number
) => {
  const params = {
    search: searchQuery,
    customerId,
    sortKey,
    order,
    status,
  };
  const response = await EXPRESS_API.get(`/superadmin/users/download`, {
    params,
    responseType: 'blob' as ResponseType,
  });
  return saveAs(new Blob([response.data]), name);
};

export const exportUsageReportsData = async (
  params: UsageReportsListParams
) => {
  const response = await EXPRESS_API.get(`/superadmin/usage-reports/send`, {
    params,
  });
  return response.data;
};

/// organization administration

export const createOrganization = async (
  data: ICreateOrganization
): Promise<IOrganization> => {
  const response = await EXPRESS_API.post(`/superadmin/organizations`, data);
  return response.data;
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrganization, {
    onError: () => {
      errorToast({ title: 'There was an error creating your organization.' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(organizationKeys.list());
      queryClient.invalidateQueries(organizationKeys.organizationAccess());
      successToast({ title: 'Successfully created organization!' });
    },
  });
};

export const getSingleOrganization = async (
  organizationId: number
): Promise<IOrganization> => {
  if (!organizationId) return {} as IOrganization;

  const response = await EXPRESS_API.get(
    `/superadmin/organizations/${organizationId}`
  );
  return response.data;
};
export const useGetSingleOrganization = (organizationId: number) => {
  return useQuery(organizationKeys.single(organizationId), () =>
    getSingleOrganization(organizationId)
  );
};

export const updateOrganization = async ({
  data,
  organizationId,
}: IUpdateOrganization): Promise<IOrganization> => {
  const response = await EXPRESS_API.put(
    `/superadmin/organizations/${organizationId}`,
    data
  );
  return response.data;
};
export const useUpdateOrganization = (organizationId: number) => {
  const queryClient = useQueryClient();
  return useMutation(updateOrganization, {
    onError: () => {
      errorToast({ title: 'There was an error updating your organization.' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(organizationKeys.list());
      queryClient.invalidateQueries(organizationKeys.single(organizationId));
      queryClient.invalidateQueries(organizationKeys.organizationAccess());
      successToast({ title: 'Successfully updated organization!' });
    },
  });
};

export const deleteOrganization = async (
  organizationId: number
): Promise<IOrganization> => {
  const response = await EXPRESS_API.delete(
    `/superadmin/organizations/${organizationId}`
  );
  return response.data;
};

export const useDeleteOrganization = (organizationId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    'organization-removal',
    () => deleteOrganization(organizationId),
    {
      onError: () => {
        errorToast({
          title:
            'An error occurred deleting the organization, please try again later!',
        });
      },
      onSuccess: () => {
        successToast({
          title: 'Organization has been deleted.',
        });
        queryClient.invalidateQueries(organizationKeys.list());
        queryClient.invalidateQueries(organizationKeys.organizationAccess());
      },
    }
  );
};

export const deleteCustomerFromOrganization = async ({
  organizationId,
  customerId,
}: {
  organizationId: number;
  customerId: number;
}) => {
  const response = await EXPRESS_API.delete(
    `/superadmin/organizations/${organizationId}/customers/${customerId}`
  );
  return response.data;
};

export const useDeleteCustomerFromOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCustomerFromOrganization, {
    onError: () => {
      errorToast({
        title:
          'An error occurred deleting the customer from the organization, please try again later!',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ORGANIZATIONS_KEY);
      successToast({
        title: 'Customer has been deleted from the organization.',
      });
    },
  });
};

export const updateOrganizationAccess = async ({
  limitToRegions,
  enableSignInAs,
  organizationId,
  userOrganizationAccessId,
}: IUpdateOrganizationAccess): Promise<IUserOrganizationAccess> => {
  const response = await EXPRESS_API.put(
    `/superadmin/organizations/${organizationId}/access/${userOrganizationAccessId}`,
    { enableSignInAs, limitToRegions }
  );
  return response.data;
};
export const useUpdateOrganizationAccess = (organizationId: number) => {
  const queryClient = useQueryClient();
  return useMutation(updateOrganizationAccess, {
    onError: () => {
      errorToast({
        title: 'There was an error updating access to this organization.',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(organizationKeys.list());
      queryClient.invalidateQueries(organizationKeys.single(organizationId));
      queryClient.invalidateQueries(organizationKeys.organizationAccess());
      successToast({ title: 'Successfully updated organization access!' });
    },
  });
};

export const deleteOrganizationAccess = async ({
  organizationId,
  userOrganizationAccessId,
}: IDeleteUserAccess) => {
  const response = await EXPRESS_API.delete(
    `/superadmin/organizations/${organizationId}/access/${userOrganizationAccessId}`
  );
  return response.data;
};

export const useDeleteOrganiztionAccess = (organizationId: number) => {
  const queryClient = useQueryClient();
  return useMutation(deleteOrganizationAccess, {
    onError: () => {
      errorToast({
        title: 'There was an error deleting access to this organization.',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(organizationKeys.list());
      queryClient.invalidateQueries(organizationKeys.single(organizationId));
      queryClient.invalidateQueries(organizationKeys.organizationAccess());
      successToast({ title: 'Successfully removed user from organization.' });
    },
  });
};

export const linkCustomersToOrganization = async ({
  organizationId,
  data,
}: ILinkCustomer) => {
  const response = await EXPRESS_API.post(
    `/superadmin/organizations/${organizationId}/customers`,
    data
  );
  return response.data;
};

export const useLinkCustomers = () => {
  const queryClient = useQueryClient();
  return useMutation(linkCustomersToOrganization, {
    onError: () => {
      errorToast({
        title: 'There was an error with linking this customer.',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ORGANIZATIONS_KEY);
      successToast({ title: 'Successfully linked customer to organization.' });
    },
  });
};

export const getSingleIMSConnection = async (
  customerId: string | number | null
): Promise<IIMSConnection> => {
  if (!customerId) return {} as IIMSConnection;

  const { data } = await EXPRESS_API.get(
    `/superadmin/ims/${customerId}/connection`
  );
  return data as IIMSConnection;
};

export interface IUnconnectedIMS {
  customers: Array<{ customerId: number; business: string }>;
  count: number;
}

export const getUnconnectedIMSConnections = async (
  search: string
): Promise<IUnconnectedIMS> => {
  const { data } = await EXPRESS_API.get(`/superadmin/ims/unconnected`, {
    params: {
      search,
    },
  });
  return data as IUnconnectedIMS;
};

export const useGetSingleIMSConnection = (
  customerId: string | number | null
) => {
  const { showError } = useToastError();
  return useQuery(
    [SUPER_ADMIN_IMS_SINGLE, customerId],
    () => getSingleIMSConnection(customerId),
    {
      onError: (err: AxiosError) => showError(err),
      enabled: !!customerId,
      refetchOnMount: true,
    }
  );
};

export const useGetUnconnectedIMSConnection = (search: string) => {
  const { showError } = useToastError();
  return useQuery(
    [SUPER_ADMIN_IMS_UNCONNECTED],
    () => getUnconnectedIMSConnections(search),
    {
      onError: (err: AxiosError) => showError(err),
    }
  );
};

export const updateSingleIMSConnection = async (params: {
  [key: string]: string | number | null;
}): Promise<IIMSConnection> => {
  const { customerId, ...body } = params;

  const { data } = await EXPRESS_API.patch(
    `/superadmin/ims/${customerId}`,
    body
  );
  return data as IIMSConnection;
};

export const useMutateSingleIMSConnection = (handleModalClose: () => void) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(updateSingleIMSConnection, {
    onError: (err: AxiosError) => showError(err),
    onSuccess: data => {
      queryClient.invalidateQueries([SUPER_ADMIN_IMS_SINGLE, data.customerId]);
      queryClient.invalidateQueries([SUPER_ADMIN_IMS_UNCONNECTED]);
      queryClient.invalidateQueries([SUPER_ADMIN_IMS_CONNECTED]);
      successToast({ title: 'Successfully updated connection.' });
      handleModalClose();
    },
  });
};

export const getConnectedIMSConnections = async (
  search: string,
  start: number,
  limit: number,
  sort: {
    sortKey: string;
    order: PaginationConstants;
  }
): Promise<IMSConnectedResponse> => {
  const { data } = await EXPRESS_API.get(`/superadmin/ims/connected`, {
    params: {
      search,
      start,
      limit,
      ...sort,
    },
  });
  return data;
};

export const useGetConnectedIMSConnections = (
  search: string,
  start: number,
  limit: number,
  sort: {
    sortKey: string;
    order: PaginationConstants;
  }
) => {
  const { showError } = useToastError();
  return useQuery(
    [SUPER_ADMIN_IMS_CONNECTED],
    () => getConnectedIMSConnections(search, start, limit, sort),
    {
      onError: (err: AxiosError) => showError(err),
    }
  );
};

export const checkIDIMSConnection = async (
  params: null | Record<string, string>
): Promise<IIMSConnection> => {
  const { data } = await EXPRESS_API.get(
    `/superadmin/ims/check-inventory-existence`,
    {
      params: {
        ...params,
      },
    }
  );
  return data as IIMSConnection;
};

export const useCheckIDIMSConnection = (
  params: null | Record<string, string>
) => {
  const { showError } = useToastError();
  return useQuery(
    [SUPER_ADMIN_IMS_AVAILABILITY],
    () => checkIDIMSConnection(params),
    {
      enabled: !!params,
      onError: (err: AxiosError) => showError(err),
      onSuccess: (data: { message: string; exists: boolean }) => {
        if (!data.exists) {
          return errorToast({ title: data.message });
        }
        successToast({ title: data.message });
      },
    }
  );
};

export const deleteIDIMSConnection = async (
  customerId: string
): Promise<unknown> => {
  const { data } = await EXPRESS_API.delete(
    `/superadmin/ims/${customerId}`,
    {}
  );
  return data as unknown;
};

export const useDeleteMutateIDIMSConnection = (
  handleModalClose: () => void
) => {
  const { showError } = useToastError();
  const queryClient = useQueryClient();
  return useMutation(deleteIDIMSConnection, {
    onError: (err: AxiosError) => showError(err),
    onSuccess: () => {
      queryClient.invalidateQueries(['super-admin-ims-connected']);
      queryClient.invalidateQueries([SUPER_ADMIN_IMS_UNCONNECTED]);
      successToast({ title: 'Successfully deleted connection.' });
      handleModalClose();
    },
  });
};

export const syncIDIMSConnection = async (
  customerId: string
): Promise<IIMSConnection> => {
  const { data } = await EXPRESS_API.post(
    `/superadmin/ims/${customerId}/sync`,
    {}
  );
  return data as IIMSConnection;
};

export const useSyncMutateIDIMSConnection = () => {
  const { showError } = useToastError();
  return useMutation(syncIDIMSConnection, {
    onError: (err: AxiosError) => showError(err),
    onSuccess: () => {
      successToast({ title: 'Connection was successfully synced.' });
    },
  });
};

export const importSuperAdminUsers = async (payload: {
  users: { [key: string]: string | number | boolean }[];
  customerId: number;
}) => {
  const response = await EXPRESS_API.post(`/superadmin/users/import`, payload, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
};

export const getOverlaysForSuperAdmin = async (
  params: SuperAdminSearchParams
): Promise<GetWebsiteOverlaysResponse> => {
  const response = await EXPRESS_API.get('/superadmin/overlays', {
    params,
  });
  return response.data;
};

export const getLandingPagesForSuperAdmin = async (
  params: SuperAdminSearchParams
): Promise<GetLandingPagesResponse> => {
  const response = await EXPRESS_API.get('/superadmin/templates', {
    params,
  });
  return response.data;
};

export const getExitLinksSetsForSuperAdmin = async (
  params?: SuperAdminSearchParams
): Promise<GetCtaSetResponse> => {
  const { search = '', start = 0, limit = 0, customerId } = params || {};
  const response = await EXPRESS_API.get('/superadmin/exitlinksets', {
    params: {
      search,
      start,
      limit,
      customerId,
    },
  });
  return response.data;
};

export const updateDefaultsForSuperAdmin = async (
  customerId: number,
  data: DefaultDataPayload
) => {
  const response = await EXPRESS_API.put(
    `/superadmin/customers/${customerId}/set-defaults`,
    data
  );
  return response.data;
};

export const importSuperAdminCustomers = async (payload: {
  customers: SuperAdminCustomerDetails[];
}) => {
  const response = await EXPRESS_API.post(
    `/superadmin/customers/import`,
    payload,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );
  return response.data;
};

export const updateUsersFastPasswords = async (
  body: UpdateUsersFastPasswordRequest
): Promise<void> => {
  await EXPRESS_API.patch(`/superadmin/users/fast-password/`, body, {
    params: { changeOrigin: CHANGE_ORIGIN.adminManageUsers },
  });
};

export const useUpdateUsersFastPasswordsMutation = () => {
  return useMutation(updateUsersFastPasswords);
};
