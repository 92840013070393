import React from 'react';

import { useAuth } from '../context';
import { useQueryClient } from 'react-query';
import { useSockets } from 'lib/context';
import { useUnreadNotificationQuery } from 'lib/api/notifications/getUnreadNotificationCount';

export const NotificationBell = () => {
  const queryClient = useQueryClient();
  const { userData } = useAuth();
  const { data: notificationCount } = useUnreadNotificationQuery(userData);
  const { socket } = useSockets();

  React.useEffect(() => {
    const handleNewMessage = async (message: any) => {
      console.log('Received: ' + JSON.stringify(message));
      await queryClient.invalidateQueries(['singleChat', message.chatId]);
      await queryClient.refetchQueries(['messages']);
    };
    // @ts-ignore
    socket?.on('message.received', handleNewMessage);

    // removing listeners
    return () => {
      // @ts-ignore
      socket?.off('message.received');
      // @ts-ignore
      socket?.off('connect_error');
    };
  }, [socket]);

  return (
    <>
      {notificationCount ? (
        <div className='highlight-circle'>
          <div>
            {notificationCount.count > 9 ? `9+` : notificationCount.count}
          </div>
        </div>
      ) : null}
    </>
  );
};
