import { useMutation, useQueryClient } from 'react-query';
import { errorToast } from 'lib/components/toasts/error';
import { useHistory } from 'react-router-dom';
import { multilocationKeys } from './multilocationsKeys';
import { successToast } from 'lib/components/toasts/success';
import { ParentCustomer } from './multiLocationQuery';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IMultilocationsParams {
  customerId: number;
  users: number[] | string[];
  childCustomers: ChildCustomer[];
  location: string;
}

export interface ChildCustomer {
  location: string;
  customerId: number;
  parentCustomerId: number;
  users: User[];
}

export interface User {
  id: number | string;
  parentUserId: number;
}

const createMultilocation = async (
  params: IMultilocationsParams
): Promise<ParentCustomer> => {
  const { ...body } = params;

  return (await EXPRESS_API.post(`superadmin/multilocation`, { ...body })).data;
};

export const useMultiLocationsMutation = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(createMultilocation, {
    onError: (err: { message: string }) => {
      errorToast({ title: err?.message });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries(multilocationKeys.search(''));
      history.push('/admin/multilocations');
      successToast({ title: 'You have sucesfully created multilocation!' });
    },
  });
};
