import { useMutation, useQueryClient } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { errorToast } from 'lib/components/toasts/error';
import { EXPRESS_API } from 'configs/api/expressApi';
import { VideoDeletionApprovalProps } from './types';
import { videoDeletesKeys } from './queryKeys';

export const approveVideoDelete = async (data: VideoDeletionApprovalProps) => {
  const response = await EXPRESS_API.put(
    `/stop-video-deletes/${data.requestId}/approve`,
    data
  );
  return response.data;
};

export const useApproveVideoDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(approveVideoDelete, {
    onSuccess: () => {
      successToast({
        title: 'Video deletion successfully approved!',
      });
      queryClient.refetchQueries(videoDeletesKeys.get_video_delete());
    },
    onError: () => {
      errorToast({
        title:
          'An error occurred upon video deletion approval, please try again later!',
      });
    },
  });
};
