import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import {
  Container,
  Gap,
  HeaderWrapper,
  MainWrapper,
} from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import {
  LoadingIndicator,
  ModalVideoListVideo,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { FaFilter } from 'react-icons/fa';
import { useTheme } from 'styled-components/macro';
import { Activity, ActivityHeader, useAuth } from 'lib/context';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { theme } from 'lib/style';
import { SortContainer, TableHeader } from './styles';
import { SORTING } from 'lib/utils/videoAttributes';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import { useToastError, VideoData } from 'lib/hooks';
import { Field, ModalCustomTableFields } from 'lib/components/modal';
import { useSendActivity } from 'lib/api/activity/useSendActivity';
import {
  DateRangeSelector,
  SendReportModal,
  calculateDatesForRange,
  timeRangeConstants,
} from '../../components';
import {
  getUserActivities,
  useGetUserActivitiesQuery,
} from 'lib/api/activity/getUserActivities';
import ActivityFilterModal from 'lib/components/modal/ActivityFilterModal';
import {
  ActivityTableColumns,
  baseFields,
  filterTableFields,
  formatColumns,
  prepareCsvData,
} from './ActivityTableUtils';
import Select from 'react-select';
import { selectStyle } from 'lib/style/commonStyles';
import { useGetUsersCustomerQuery } from 'lib/api/users/getUsersCustomers';
import { useLocation } from 'react-router';
import { useManagedDepartmentsQuery } from 'lib/api/departments/useManagedDepartmentsQuery';
import { Option } from 'lib/components/dropdown/DropdownOptions';
import { exportCSVFile } from 'lib/utils/functions';
import { Button } from 'react-covideo-common';
import dayjs from 'dayjs';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';

const ActivityFeed = () => {
  const { showError } = useToastError();
  const themes = useTheme();
  const { userData } = useAuth();
  const location = useLocation();

  const queryParams: any = queryString.parse(location.search);
  const storedDateRange = getDateRangeFromLocalstorage();

  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );

  const paramsPage = queryParams.page;
  const paramsReceiveMethod = queryParams.receiveMethod;
  const paramsEmailStatus = queryParams.emailStatus;

  const { mutateAsync: sendEmail } = useSendActivity();

  const [page, setPage] = useState(Number(paramsPage) || DEFAULT_PAGE);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [activeSort, setActiveSort] = useState(SORTING.DESC);
  const [tableFields, setTableFields] = useState<Field[]>(baseFields);
  const [allTableFields, setAllTableFields] = useState<Field[]>(baseFields);
  const [startDate, setStartDate] = useState<Date>(start);
  const [endDate, setEndDate] = useState<Date>(end);
  const [dateRange, setDateRange] = useState(initRange);
  const [showVideoPreview, setShowVideoPreview] = useState<VideoData | null>(
    null
  );
  const [exportHeaders, setExportHeaders] = useState<object>({});
  const [openSelectColumnsModal, setOpenSelectColumnsModal] = useState(false);
  const [openRecipientsModal, setOpenRecipientsModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const filter = {
    receiveMethod: paramsReceiveMethod || '',
    emailStatus: paramsEmailStatus || '',
  };

  const { data: users, isLoading: areUsersLoading } = useGetUsersCustomerQuery({
    customerId: userData.customerId,
    sort: PaginationConstants.ASCENDING,
  });

  const { data: departments, isLoading: areDepartmentsLoading } =
    useManagedDepartmentsQuery({
      customerId: userData.customerId,
    });

  const userSelectOptions = [
    { label: 'All Users', value: '' },
    ...(users || []).map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })),
  ];

  const departmentSelectOptions = (departments || []).map(department => ({
    label: department.name,
    value: department.departmentId,
  }));

  const [selectedUsers, setSelectedUsers] = useState<number[]>([
    Number(userSelectOptions[0].value),
  ]); // for the table
  const [selectedOption, setSelectedOption] = useState(userSelectOptions[0]); // for the dropdown

  const fetchParams = {
    start: page * size,
    limit: size,
    date: activeSort,
    from: startDate ? dayjs(startDate).format('MM/DD/YYYY') : '',
    to: endDate ? dayjs(endDate).format('MM/DD/YYYY') : '',
    senderIds: selectedUsers || [],
    ...filter,
  };

  const { data, isLoading } = useGetUserActivitiesQuery({
    ...fetchParams,
  });
  const { activities, count } = data || { activities: [], count: 0 };

  const filterCount =
    (!!filter.emailStatus ? 1 : 0) + (!!filter.receiveMethod ? 1 : 0);

  const onPaginationChange = ({
    page: newPage,
    size: newSize,
  }: {
    page: number;
    size: number;
  }) => {
    setSize(newSize);
    setPage(newSize !== size ? 0 : newPage);
  };

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  const onSort = () => {
    setActiveSort(activeSort === SORTING.ASC ? SORTING.DESC : SORTING.ASC);
  };

  const isDepartment = (option: Option) =>
    departmentSelectOptions.find(
      departmentOption =>
        departmentOption.value === option.value &&
        departmentOption.label === option.label
    );

  const headers = tableFields.map(item => {
    const isDate = item.value === ActivityTableColumns.DATE;
    return (
      <TableHeader
        onClick={() => {
          if (isDate) onSort();
        }}
      >
        {item.label}
        {isDate && (
          <SortContainer>
            <HiArrowNarrowUp
              color={
                activeSort === SORTING.ASC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
              rotate={180}
            />
            <HiArrowNarrowDown
              color={
                activeSort === SORTING.DESC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
            />
          </SortContainer>
        )}
      </TableHeader>
    );
  });

  const rows =
    activities.map((activity: Activity, index: number) => ({
      key: index,
      columns: formatColumns(
        activity,
        tableFields,
        userData,
        setShowVideoPreview,
        allTableFields
      ),
    })) || [];

  const showNoResultsMessage =
    !count || (isDepartment(selectedOption) && !selectedUsers.length);

  const exportData = async () => {
    try {
      setIsExporting(true);
      const allActivity = await getUserActivities({
        ...fetchParams,
        limit: count,
      });
      const data = prepareCsvData(allActivity.activities, userData);
      exportCSVFile(exportHeaders, data, 'activity-report');
      setIsExporting(false);
    } catch (error) {
      setIsExporting(false);
      showError(
        error || 'An error occurred exporting the data, please try again later.'
      );
    }
  };

  const sendActivityReport = async (recipients: string[]) => {
    const allActivity = await getUserActivities({
      ...fetchParams,
      limit: count,
    });
    const csvData = prepareCsvData(allActivity.activities, userData);
    await sendEmail({
      csvData,
      recipients,
      headers: Object.keys(exportHeaders).map(key => ({
        key,
        header: exportHeaders[key as keyof typeof exportHeaders],
      })),
    });
  };

  useEffect(() => {
    if (
      userData.isCompanyAdmin &&
      tableFields[1].value !== ActivityTableColumns.USER
    ) {
      const updatedFields = [...tableFields];
      updatedFields.splice(1, 0, {
        label: 'User',
        value: ActivityTableColumns.USER,
      });
      setTableFields(updatedFields);
      setAllTableFields(updatedFields);
    }
  }, []);

  useEffect(() => {
    let expHeaders = {} as ActivityHeader;
    filterTableFields(tableFields).forEach(field => {
      expHeaders[field.value as keyof typeof expHeaders] = field.label;
    });
    expHeaders.email = 'Email';
    expHeaders.phone = 'Phone';
    setExportHeaders(expHeaders);
  }, [tableFields]);

  useEffect(() => {
    if (!selectedOption.value) {
      setSelectedUsers([]);
      return;
    }

    const department = isDepartment(selectedOption);
    if (department) {
      const usersInDepartment = (users || []).filter(
        userInDept =>
          userInDept.DeptId?.toString() === department.value.toString()
      );
      setSelectedUsers(
        usersInDepartment.map(userInDept => Number(userInDept.id))
      );
      return;
    }
    setSelectedUsers([Number(selectedOption.value)]);
  }, [selectedOption]);

  const areFiltersDisabled = isLoading || isExporting;

  const onSaveCustomTableFields = (fields: Field[]) => {
    setTableFields(fields);
    setOpenSelectColumnsModal(false);
  };

  const renderTableComponent = () => {
    if (isLoading)
      return <LoadingIndicator isLoading={true} height={'300px'} />;
    if (isExporting)
      return (
        <LoadingIndicator
          isLoading={true}
          height={'300px'}
          text='File is being exported. This might take a while.'
        />
      );
    return (
      <TableContextProvider
        total={count}
        initSize={size}
        initPage={page}
        onChange={onPaginationChange}
      >
        {showNoResultsMessage ? (
          <Gap m='50px 0 0 0' justifyContent='center'>
            There is no data to show
          </Gap>
        ) : (
          <>
            <Table relative headers={headers} compact rows={rows} />
            <TableFooter>
              <TablePaginationNew />
              <TablePaginationSizeNew text='Show items:' />
            </TableFooter>
          </>
        )}
      </TableContextProvider>
    );
  };
  return (
    <Container>
      <MainWrapper resetPadding={false}>
        <HeaderWrapper>
          <Gap>
            <Heading>Activity Feed</Heading>
            <DateRangeSelector
              onDateRangeChange={onDateRangeChange}
              initialValue={dateRange}
              disabled={areFiltersDisabled}
            />
            {userData.isCompanyAdmin && (
              <div style={{ width: '200px' }}>
                <Select
                  isDisabled={
                    areUsersLoading || areDepartmentsLoading || isExporting
                  }
                  styles={selectStyle(themes)}
                  options={[
                    {
                      label: 'Departments',
                      options: departmentSelectOptions,
                    },
                    {
                      label: 'Users',
                      options: userSelectOptions,
                    },
                  ]}
                  value={selectedOption}
                  onChange={option => {
                    if (option) {
                      setSelectedOption(option);
                    }
                  }}
                />
              </div>
            )}
          </Gap>
          <Button
            text={
              filterCount
                ? `${filterCount} Active Filter${filterCount > 1 ? 's' : ''}`
                : 'Filter'
            }
            icon={<FaFilter size={14} />}
            onClick={() => setOpenFilterModal(true)}
            disabled={areFiltersDisabled}
          />
        </HeaderWrapper>

        <Gap gap='8px' justifyContent='right'>
          <Button
            onClick={exportData}
            text='Export'
            variant='secondary'
            disabled={areFiltersDisabled}
          />
          <Button
            onClick={() => setOpenRecipientsModal(true)}
            text='Send'
            variant='secondary'
            disabled={areFiltersDisabled}
          />
          <Button
            onClick={() => setOpenSelectColumnsModal(true)}
            text='Change Columns'
            variant='secondary'
            disabled={areFiltersDisabled}
          />
        </Gap>
        {renderTableComponent()}
      </MainWrapper>
      {!!showVideoPreview && !!showVideoPreview.id && (
        <ModalVideoListVideo
          videoId={showVideoPreview.id}
          source={showVideoPreview.videoSource}
          handleModalClose={() => setShowVideoPreview(null)}
        />
      )}
      {openSelectColumnsModal && (
        <ModalCustomTableFields
          title='Change Columns'
          onClose={() => setOpenSelectColumnsModal(false)}
          initialFields={filterTableFields(tableFields)}
          allFields={filterTableFields(allTableFields)}
          actionButtons={[
            {
              onClick: onSaveCustomTableFields,
              text: 'Update Columns',
            },
          ]}
        />
      )}
      {openRecipientsModal && (
        <SendReportModal
          sendDataFunction={(recipients: string[]) =>
            sendActivityReport(recipients)
          }
          startDate={new Date()}
          endDate={new Date()}
          range=''
          handleModalClose={() => setOpenRecipientsModal(false)}
        />
      )}
      {openFilterModal && (
        <ActivityFilterModal
          onClose={() => setOpenFilterModal(false)}
          filter={filter}
        />
      )}
    </Container>
  );
};

export default ActivityFeed;
