import { useMultilocationConfigurationDelete } from 'lib/api/multiLocations/multilocationConfigurationDelete';
import { NewModal } from 'lib/components/modal';
import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import { SelectedCustomer } from '../pages/MultiLocationList';
import { Button } from 'react-covideo-common';

interface Props {
  selectedCustomer: SelectedCustomer;
  handleModalClose: () => void;
}

export const DeleteConfigurationModal = ({
  handleModalClose,
  selectedCustomer,
}: Props) => {
  const { mutateAsync, isLoading: isDeleting } =
    useMultilocationConfigurationDelete(handleModalClose);

  const onDeleteHandler = () => {
    mutateAsync({ customerId: selectedCustomer?.customerId });
  };
  return (
    <NewModal
      closeModal={handleModalClose}
      headerText='Remove Multi-location Config?'
    >
      <>
        Are you sure you want to remove Multi-location Config for
        <span>
          <b>{selectedCustomer.business}</b>?
        </span>
      </>
      <Gap m='24px 0 0' gap='12px' justifyContent='flex-end'>
        <Button
          text='Cancel'
          onClick={handleModalClose}
          variant='secondary'
          disabled={isDeleting}
        />
        <Button
          text={isDeleting ? 'Deleting...' : 'Delete'}
          variant='destructive'
          onClick={onDeleteHandler}
          disabled={isDeleting}
        />
      </Gap>
    </NewModal>
  );
};
