import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { metricsKeys } from './metricsKeys';
import { REPORT_SCOPE } from '../reportsApi';
import { reportData } from 'lib/const';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface ReportParams {
  reportType: string;
  from: string;
  to: string;
  customer?: string;
  users?: string;
  retryCount?: number;
  reportScope?: REPORT_SCOPE;
  organizationId?: string | number;
  organizationRegionId?: string | number;
  allowDecimals?: boolean;
  timezoneOffset: number;
  reportId?: string;
}

const getReport = async ({
  reportType,
  from,
  to,
  users,
  retryCount,
  customer,
  reportScope = REPORT_SCOPE.RESELLER,
  organizationId,
  organizationRegionId,
  timezoneOffset,
  reportId,
}: ReportParams): Promise<any> => {
  // @ts-ignore
  let report = reportData[reportType];

  if (!report) {
    report = reportData.views;
  }

  return (
    await EXPRESS_API.get(`${report.url}`, {
      params: {
        from,
        to,
        users,
        retryCount,
        customer,
        timezoneOffset,
        reportScope,
        organizationId,
        organizationRegionId,
        reportId,
      },
    })
  ).data;
};

export const useQueryReport = (params: ReportParams) => {
  const { showError } = useToastError();
  return useQuery(
    metricsKeys.report(params, params.reportType),
    () => getReport(params),
    {
      onError: showError,
      refetchOnMount: true,
    }
  );
};
