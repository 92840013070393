import { EXTERNAL_VIDEO_PREFIX } from 'app/pages/record/const';
import { useQueries } from 'react-query';
import { getVideo } from './useSingleVideoQuery';
import { videoKeys } from './videosKeys';

export const useGetMultipleVideos = (videoIds: string[]) => {
  const queries = videoIds.map(id => ({
    queryKey: videoKeys.single(parseInt(id, 10)),
    queryFn: () => getVideo(id),
    enabled: !!id && !id.includes(EXTERNAL_VIDEO_PREFIX),
  }));

  const results = useQueries(queries);

  return results;
};
