import * as React from 'react';
import { theme } from 'lib/style';
import styled from 'styled-components/macro';
import { useAuth } from 'lib/context';
import { useEffect, useState } from 'react';
import { MdOutlineContentCopy } from 'react-icons/md';
import { successToast } from 'lib/components/toasts/success';
import { Button } from 'react-covideo-common';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const Info = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray60};
  width: 252px;
  text-align: right;
`;
type URLContainerProps = {
  disabled: boolean;
};
const URLContainer = styled.textarea<URLContainerProps>`
  cursor: pointer;
  box-sizing: border-box;
  resize: none;
  padding: 16px 24px;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray40};
  border-radius: 5px;
  display: block;
  width: 100%;
  min-height: 100px;
  height: auto;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'normal')};
  &:focus {
    border: 1px solid ${theme.palette.gray40};
    outline: 0;
  }
`;

type VideoShareLinkData = {
  url: string;
  html: string;
  key?: string;
};

type Props = {
  videoShareLink: VideoShareLinkData;
  disableShare: boolean;
};

export const ShareByEmbedding = ({ disableShare, videoShareLink }: Props) => {
  const [frame, setFrame] = useState('');
  const { whitelabel } = useAuth();

  const prepareFrame = () => {
    if (!videoShareLink || !videoShareLink.url) return;

    const videoSourceId = videoShareLink.url.split('v/')[1];

    const script = `<script defer src='https://${whitelabel.codeCDN}/embed/js/init.js'></script>`;

    const container = document.createElement('div');
    container.classList.add('cv-embed');
    container.setAttribute('data-id', videoSourceId);
    container.setAttribute('data-key', videoShareLink.key || '');

    setFrame(container.outerHTML + '\n' + script);
  };

  useEffect(() => {
    prepareFrame();
  });

  const frameRef = React.createRef<HTMLTextAreaElement>();

  const copyEmbedCode = async () => {
    await navigator.clipboard.writeText(frame);
    successToast({
      title: 'The code is successfully copied into the Clipboard.',
    });
  };

  return (
    <>
      <URLContainer
        defaultValue={frame}
        ref={frameRef}
        onClick={() => copyEmbedCode()}
        disabled={disableShare}
      />
      <Row>
        <Button
          text='Copy to Clipboard'
          disabled={disableShare}
          onClick={() => copyEmbedCode()}
          icon={<MdOutlineContentCopy color={theme.palette.white} />}
        />
        <Info>For the embed sharing, send and share settings won’t apply.</Info>
      </Row>
    </>
  );
};
