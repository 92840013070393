import { getDevices } from 'lib/utils/functions';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  IUserSettings,
  IRecordSettingsProvider,
  IRecordConfigSettings,
  VIDEO_QUALITY,
  Device,
  DEVICE_KIND,
  Option,
} from './types';

export const RecordSettingsContext = createContext(
  {} as {
    recorderSettings: { audioSource: Option[]; videoSource: Option[] };
    setUserSettings: React.Dispatch<React.SetStateAction<IUserSettings>>;
    userSettings: IUserSettings;
    recordingError: string | null;
    updateErrorMessage: () => void;
    initialUserSettings: IUserSettings;
    onSubmitModalHandler: () => void;
  }
);

const LOCAL_STORAGE_KEY = 'record_settings';

const initialSettings = {
  audioSource: null,
  videoSource: null,
  videoQuality: VIDEO_QUALITY.STANDARD,
};

const getUserSettings = (
  defaultAudio: Option,
  defaultVideo: Option
): IUserSettings => {
  try {
    const item = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (item) {
      return JSON.parse(item) as IUserSettings;
    }
    return {
      videoQuality: VIDEO_QUALITY.STANDARD,
      videoSource: defaultVideo,
      audioSource: defaultAudio,
    };
  } catch (error) {
    console.error('Error parsing user settings from localStorage:', error);
    return {
      videoQuality: VIDEO_QUALITY.STANDARD,
      videoSource: defaultVideo,
      audioSource: defaultAudio,
    };
  }
};

export const RecordSettingsProvider = ({
  children,
}: IRecordSettingsProvider) => {
  const noAudio: Option = { label: 'No Audio', value: false };
  const noVideo: Option = { label: 'No Video', value: false };

  const [recorderSettings, setRecorderSettings] =
    useState<IRecordConfigSettings>({
      audioSource: [],
      videoSource: [],
    });

  const [userSettings, setUserSettings] =
    useState<IUserSettings>(initialSettings);
  const [recordingError, setRecordingError] = useState<string | null>(null);
  const [initialUserSettings, setInitialUserSettings] =
    useState<IUserSettings>(initialSettings);

  const updateErrorMessage = () => {
    setRecordingError(
      'You do not have the necessary permissions to access this feature. Please check your browser permissions.'
    );
  };

  const fetchDevices = async () => {
    try {
      const devicesResponse = await getDevices();
      const devices: Device[] = devicesResponse as Device[];
      const newConfig = devices.reduce(
        (config, device) => {
          if (device.kind === DEVICE_KIND.AUDIO_INPUT) {
            config.audio = [
              ...config.audio,
              { label: device.label, value: device.deviceId },
            ];
          } else if (device.kind === DEVICE_KIND.VIDEO_INPUT) {
            config.video = [
              ...config.video,
              { label: device.label, value: device.deviceId },
            ];
          }
          return config;
        },
        { audio: [] as Option[], video: [] as Option[] }
      );
      const defaultAudio =
        newConfig.audio.find(item => item.value === 'default') || noAudio;
      const defaulVideo = newConfig?.video?.[0] || noVideo;

      const initialUserSettings = getUserSettings(defaultAudio, defaulVideo);
      setRecorderSettings({
        audioSource: [...newConfig.audio, noAudio],
        videoSource: newConfig.video,
      });
      setUserSettings(initialUserSettings);
      setInitialUserSettings(initialUserSettings);
      setRecordingError(null);
    } catch (error) {
      console.error('Error fetching devices:', error);
      updateErrorMessage();
      setRecorderSettings({
        audioSource: [noAudio],
        videoSource: [noVideo],
      });
    }
  };

  const onSubmitModalHandler = () => {
    setInitialUserSettings(userSettings);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userSettings));
  };

  useEffect(() => {
    const handleDeviceChange = () => {
      fetchDevices();
    };

    // Check if the navigator.mediaDevices API is supported
    if (navigator?.mediaDevices?.ondevicechange !== undefined) {
      navigator.mediaDevices.addEventListener(
        'devicechange',
        handleDeviceChange
      );

      fetchDevices();

      // Cleanup event listener on component unmount
      return () => {
        navigator.mediaDevices.removeEventListener(
          'devicechange',
          handleDeviceChange
        );
      };
    } else {
      console.log(
        'The navigator.mediaDevices API is not supported in this browser.'
      );
      setRecorderSettings({
        audioSource: [noAudio],
        videoSource: [noVideo],
      });
    }
  }, []);

  return (
    <RecordSettingsContext.Provider
      value={{
        recorderSettings,
        userSettings,
        setUserSettings,
        recordingError,
        updateErrorMessage,
        initialUserSettings,
        onSubmitModalHandler,
      }}
    >
      {children}
    </RecordSettingsContext.Provider>
  );
};

export const useRecordSettings = () => {
  const context = useContext(RecordSettingsContext);
  if (context === undefined) {
    throw new Error(
      'useRecordSettings must be used within a RecordSettingsProvider'
    );
  }
  return context;
};
