import {
  ModalHeader,
  CloseWrapper,
} from 'app/pages/guides/modals/VisibilityModal';
import { NotFound } from 'app/pages/notFound/NotFound';
import { FieldArray, Form, Formik } from 'formik';
import {
  ParentCustomer,
  useGetSingleMultiLocation,
} from 'lib/api/multiLocations/multiLocationQuery';
import {
  IMultilocationsParams,
  useMultiLocationsMutation,
} from 'lib/api/multiLocations/multilocationsMutations';
import { LoadingIndicator, NewModal } from 'lib/components';
import { Gap } from 'lib/components/styles/layout';
import React from 'react';
import { Button } from 'react-covideo-common';
import { MdClose } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { CustomerColumns } from '../components/customers/CustomerColumns';
import { LocationColumns } from '../components/locations/LocationColumns';
import { UsersColumns } from '../components/users/UsersColumns';
import { INITIAL_VALUES } from '../const';

const Wrapper = styled.div`
  padding: 40px;
  margin: 0 auto;
`;

export const MultiLocationDetails = () => {
  const history = useHistory();

  const { customerId } = useParams() as {
    customerId: string;
  };

  const isEditMode = !!customerId;

  const { data, isLoading } = useGetSingleMultiLocation(customerId);

  const { mutateAsync, isLoading: isCreatingMultiLocation } =
    useMultiLocationsMutation();

  const onSubmitHandler = (values: ParentCustomer) => {
    if (values.customerId && values.location.name) {
      const preparedValues: IMultilocationsParams = {
        users: values.users.map(user => user.id) as number[] | string[],
        customerId: values.customerId,
        location: values.location.name,
        childCustomers: values.childCustomers.map(child => ({
          location: child.location.name as string,
          customerId: child.customerId,
          parentCustomerId: child.parentCustomerId,
          users: child.users.map(user => ({
            id: user.id,
            parentUserId: user.parentUserId as number,
          })),
        })),
      };
      mutateAsync(preparedValues);
    }
  };

  if (isEditMode && !data && !isLoading) {
    return <NotFound />;
  }

  return (
    <NewModal
      hideHeader
      closeModal={() => history.push('/admin/multilocations')}
      style={{
        content: {
          width: '100vw',
          height: '100vh',
          background: '#F7F9FA',
        },
      }}
    >
      <Formik
        initialValues={data ? data : INITIAL_VALUES}
        onSubmit={onSubmitHandler}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <Form>
              <ModalHeader>
                <Gap gap='48px'>
                  <CloseWrapper
                    onClick={() => history.push('/admin/multilocations')}
                  >
                    <MdClose size='18' />
                  </CloseWrapper>
                </Gap>

                {!isEditMode && (
                  <Button
                    text='Save All'
                    type='submit'
                    disabled={
                      !values.childCustomers.length ||
                      !values.users.length ||
                      isLoading ||
                      isCreatingMultiLocation
                    }
                  />
                )}
              </ModalHeader>
              {isLoading || isCreatingMultiLocation ? (
                <div>
                  <LoadingIndicator isLoading={true} />
                </div>
              ) : (
                <Wrapper>
                  <Gap flexWrap='noWrap' gap='0px' alignItems='flex-start'>
                    <FieldArray name='childCustomers'>
                      {arrayHelpers => (
                        <>
                          <CustomerColumns
                            arrayHelpers={arrayHelpers}
                            isEditMode={isEditMode}
                          />
                          <LocationColumns isEditMode={isEditMode} />
                          {values.customerId && (
                            <UsersColumns isEditMode={isEditMode} />
                          )}
                        </>
                      )}
                    </FieldArray>
                  </Gap>
                </Wrapper>
              )}
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
