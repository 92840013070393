import { CloseButton, Table } from 'lib/components';
import { Option } from 'lib/components/dropdown/Dropdown';
import {
  ButtonContainer,
  Content,
  ContentBody,
  ContentHeader,
  ContentHeaderWrap,
  ErrorMessage,
  Label,
  ModalItem,
  Row,
  SelectInput,
  TableCell,
  TableCellHeader,
  Text,
} from 'lib/components/modal/importModal/Components';
import {
  colOptionProps,
  csvDataProps,
} from 'lib/components/modal/importModal/Types';
import { theme } from 'lib/style';
import * as React from 'react';
import { Button } from 'react-covideo-common';
import { MdWarning } from 'react-icons/md';
import {
  AUTOMOTIVE_ROLE_LABEL,
  AUTOMOTIVE_ROLE_OPTION,
  FIELD_REQUIRED_TEXT,
} from './Constants';

export const initialColOptions: colOptionProps[] = [];
export const SAMPLE_LENGTH = 1;

const getFieldOptions = (
  hasEmail2FieldAccess: boolean,
  isAutomotive: boolean
) => {
  const fieldOptions = [
    { value: 'None', label: 'Choose...' },
    { value: 'firstName', label: 'First Name' },
    { value: 'lastName', label: 'Last Name' },
    { value: 'username', label: 'Username' },
    { value: 'phone1', label: 'Phone Number' },
    { value: 'cellPhone', label: 'Cell Phone Number' },
    { value: 'email', label: 'Email' },
    { value: 'password', label: 'Password' },
    // { value: 'phone2', label: 'Phone Number-2' },
    { value: 'title', label: 'Title' },
    { value: 'departmentName', label: 'Department' },
    { value: 'isAdmin', label: 'Admin' },
    { value: 'nmls', label: 'NMLS' },
    { value: 'userType', label: 'User Type' },
    // Super admin user fields
    { value: 'active', label: 'Active' },
    { value: 'transcriptionAccess', label: 'Enable Captions' },
    { value: 'mobileSMSAccess', label: 'Mobile SMS' },
  ];
  if (hasEmail2FieldAccess) {
    fieldOptions.push({ value: 'email2', label: 'Email2' });
  }
  if (isAutomotive) {
    fieldOptions.push(AUTOMOTIVE_ROLE_OPTION);
  }
  return fieldOptions;
};

const getRequiredFields = (
  isAutomotive: boolean,
  fieldOptions: {
    value: string;
    label: string;
  }[]
) => {
  const requiredFields = ['firstName', 'lastName', 'email'];
  if (isAutomotive && fieldOptions.includes(AUTOMOTIVE_ROLE_OPTION)) {
    requiredFields.push(AUTOMOTIVE_ROLE_LABEL);
  }
  return requiredFields;
};

type Props = {
  title: string;
  disabled: boolean;
  isAutomotive: boolean;
  colOptions: colOptionProps[];
  colSamples: { [key: string]: string[] };
  colMapping: { [key: string]: string };
  invalidCount: number;
  CSVData: csvDataProps;
  duplicatedRows: { [key: string]: string[] };
  onColChange(field: string, value: string): void;
  initializeListView(): void;
  handleModalClose(): void;
  hasEmail2FieldAccess?: boolean;
};

export const MapImported = ({
  title,
  disabled,
  isAutomotive,
  colOptions,
  colSamples,
  colMapping,
  invalidCount,
  CSVData,
  duplicatedRows,
  onColChange,
  handleModalClose,
  initializeListView,
  hasEmail2FieldAccess = false,
}: Props) => {
  const fieldOptions = getFieldOptions(hasEmail2FieldAccess, isAutomotive);
  const requiredFields = getRequiredFields(isAutomotive, fieldOptions);
  const getSampleData = (index: string) => {
    if (!colSamples[index]) {
      return '';
    }
    return colSamples[index].filter(Boolean).slice(0, SAMPLE_LENGTH);
  };

  const printDuplicatedRows = React.useCallback(() => {
    const result: React.ReactElement[] = [];
    for (let username in duplicatedRows) {
      if (duplicatedRows[username].length > 1) {
        result.push(
          <Label key={`error-key-${username}`}>
            <Text>
              {' '}
              {username || '""'} has multiple entries( line{' '}
              {duplicatedRows[username].join(', ')})
            </Text>
          </Label>
        );
      }
    }
    return result;
  }, [duplicatedRows]);

  return (
    <ModalItem>
      <Content style={{ width: 580 }}>
        <ContentHeaderWrap>
          <ContentHeader>{title}</ContentHeader>
          <CloseButton disabled={disabled} onClick={handleModalClose} />
        </ContentHeaderWrap>
        <ContentBody>
          <div style={{ marginBottom: 42 }}>
            Connect columns from .csv file to user fields
          </div>
          <Table
            compact={true}
            headers={[
              <TableCellHeader>Column Label (csv)</TableCellHeader>,
              <TableCellHeader>Example</TableCellHeader>,
              <TableCellHeader>User Field</TableCellHeader>,
            ]}
            hoverable={false}
            rows={colOptions.slice(1).map((x, index: number) => ({
              key: index,
              columns: [
                <TableCell>{x.label}</TableCell>,
                <TableCell>{getSampleData(index.toString())}</TableCell>,
                <TableCell>
                  <SelectInput
                    menuPlacement='auto'
                    menuPosition='fixed'
                    placeholder='Choose...'
                    styles={{
                      control: (base: React.CSSProperties) => ({
                        ...base,
                        height: '40px',
                      }),
                      indicatorSeparator: () => ({ display: 'none' }),
                    }}
                    options={fieldOptions}
                    value={fieldOptions.find(
                      option =>
                        option.value ==
                        (Object.keys(colMapping).find(
                          key => colMapping[key] === index.toString()
                        ) || 'None')
                    )}
                    onChange={(option: Option) =>
                      onColChange(option.value.toString(), index.toString())
                    }
                  />
                </TableCell>,
              ],
              onClick: () => {},
            }))}
          />
          {invalidCount > 0 && invalidCount < CSVData.data.length && (
            <>
              <Row>
                <ErrorMessage>
                  <span>
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={32}
                    />
                    {invalidCount}/{CSVData.data.length} rows contain invalid
                    data. Those rows will be ignored.
                  </span>
                </ErrorMessage>
              </Row>
              <div style={{ maxHeight: 84, overflowY: 'auto' }}>
                {printDuplicatedRows()}
              </div>
            </>
          )}
          {invalidCount == CSVData.data.length && (
            <>
              <div style={{ maxHeight: 84, overflowY: 'auto' }}>
                {printDuplicatedRows()}
              </div>
            </>
          )}
          {requiredFields.some(e => colMapping[e] == 'None') && (
            <>
              <Row>
                <ErrorMessage style={{ minHeight: '48px', height: 'auto' }}>
                  <span
                    style={{
                      margin: '10px',
                      alignItems: 'center',
                      lineHeight: 'normal',
                    }}
                  >
                    <MdWarning
                      color={theme.palette.primaryRedDanger}
                      size={24}
                      style={{ marginTop: '0px' }}
                    />
                    {requiredFields
                      .filter(e => colMapping[e] == 'None')
                      .join(', ') +
                      FIELD_REQUIRED_TEXT +
                      requiredFields
                        .filter(e => colMapping[e] == 'None')
                        .join(', ')}{' '}
                  </span>
                </ErrorMessage>
              </Row>
            </>
          )}
        </ContentBody>
      </Content>
      <ButtonContainer>
        <Button
          disabled={
            CSVData.data.length - invalidCount == 0 ||
            requiredFields.some(e => colMapping[e] == 'None')
          }
          text={'Continue'}
          onClick={() => {
            initializeListView();
          }}
        />
      </ButtonContainer>
    </ModalItem>
  );
};
