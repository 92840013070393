import { useQuery } from 'react-query';
import { meetingKeys } from './meetingKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export interface IChatMeetingResponse {
  meetingMessageId: number;
  meetingId: string;
  userId: number | null;
  connectionId: string;
  nickname: string;
  text: string;
  createdAt: string;
}

const getMeetingChat = async (
  meetingId: string
): Promise<IChatMeetingResponse[]> => {
  const response = await EXPRESS_API.get(`/meeting/${meetingId}/messages`);
  return response.data as IChatMeetingResponse[];
};

export const useMeetingsChatQuery = (meetingId: string) => {
  return useQuery(
    meetingKeys.chat_meeeting(meetingId),
    () => getMeetingChat(meetingId),
    {
      enabled: meetingId !== '',
    }
  );
};
