import { useAwsSignedUrlQuery } from 'lib/api/aws/useAwsSignedUrlQuery';
import { useS3UploadMutation } from 'lib/api/aws/useS3UploadMutation';
import { useCreateVideoMutation } from 'lib/api/videos/useCreateVideoMutation';
import { useEncodeVideoMutation } from 'lib/api/videos/useEncodeVideoMutation';
import { VideoPages } from 'lib/const';
import { useAuth } from 'lib/context';
import { CONTENT_TYPE } from 'lib/hooks/useMediaRecorder';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DetailsPreviewRecordFormValues } from './detailsPreview/types';
import { RECORD_LOADER_STATUS } from './types';

interface IUseRecordUploadFormDataProps extends DetailsPreviewRecordFormValues {
  file: File;
}

const removeEmptyValues = (
  obj: { [s: string]: unknown } | ArrayLike<unknown>
) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== null && v !== '')
  );
};

export const useVideoRecordUpload = ({
  continueToSendShare,
}: {
  continueToSendShare: boolean;
}) => {
  const { userData } = useAuth();
  const isAutomotive = userData.isAutomotive;
  const history = useHistory();
  const [progressState, setProgressState] = useState(
    RECORD_LOADER_STATUS.UPLOADING
  );
  const [loadingStarted, setLoadingIsStared] = useState(false);
  const [recordingFormData, setRecordingFormData] =
    useState<IUseRecordUploadFormDataProps | null>(null);

  const {
    data: presignedUrlData,
    isLoading: isLoadingUrl,
    error: urlErrorData,
  } = useAwsSignedUrlQuery(
    {
      fileName: recordingFormData?.file?.name || '',
      type: recordingFormData?.file?.type || '',
    },
    !!recordingFormData?.title
  );

  const {
    mutateAsync: uploadVideoToS3,
    isLoading: isUploading,
    error: uploadError,
    progress: uploadToS3Progress,
  } = useS3UploadMutation();

  const {
    mutateAsync: createVideo,
    isLoading: isCreating,
    error: createError,
    progress: createProgress,
  } = useCreateVideoMutation();

  const {
    mutateAsync: encodeVideo,
    progress: encodeProgress,
    isLoading: isEncoding,
    isError: isErrorEncoding,
  } = useEncodeVideoMutation();

  useEffect(() => {
    if (presignedUrlData?.url && recordingFormData) {
      startUpload();
    }
  }, [presignedUrlData, recordingFormData]);

  const startUpload = async () => {
    setLoadingIsStared(true);
    try {
      if (presignedUrlData?.url && recordingFormData) {
        // Upload to S3
        setProgressState(RECORD_LOADER_STATUS.UPLOADING);
        await uploadVideoToS3({
          url: presignedUrlData.url,
          file: recordingFormData.file,
          contentType: CONTENT_TYPE,
        });

        // Create video
        setProgressState(RECORD_LOADER_STATUS.CREATE);
        const createResponse = await createVideo({
          filename: recordingFormData.file.name,
          title: recordingFormData.title,
          folder: recordingFormData.folder.label,
          isFreemium: false,
          videoTags: recordingFormData.videoTags,
          videoAttributeValues: recordingFormData.attributes.map(attr => ({
            attributeId: attr.attributeId,
            value: attr.value,
          })),
          ...(isAutomotive &&
            recordingFormData.videoRequest && {
              videoRequest: removeEmptyValues({
                repairOrderNumber:
                  recordingFormData.videoRequest.repairOrderNumber,
                advisorId: !!recordingFormData.videoRequest.advisorId
                  ? Number(recordingFormData.videoRequest.advisorId)
                  : '',
                videoType: recordingFormData.videoRequest.videoType,
                vin: recordingFormData.videoRequest.vehicle.vin,
                note: recordingFormData.videoRequest.note,
                customerName: recordingFormData.videoRequest.customerName,
              }),
            }),
        });

        // Encode video if creation successful
        if (createResponse) {
          setProgressState(RECORD_LOADER_STATUS.ENCODE);
          await encodeVideo({
            videoId: createResponse.id,
            customerId: userData.customerId,
          });
        }

        // Final upload completion state
        setProgressState(RECORD_LOADER_STATUS.UPLOAD_COMPLETED);
        if (continueToSendShare) {
          history.push(
            `/home/${VideoPages.SEND_AND_SHARE}/${createResponse.id}/`
          );
          return;
        }
        history.push(`/home`);
        setLoadingIsStared(false);
      }
    } catch (error) {
      console.error('Error during upload:', error);
      setProgressState(RECORD_LOADER_STATUS.ERROR);
      setLoadingIsStared(false);
    }
  };

  const isLoading =
    isLoadingUrl || isUploading || isCreating || isEncoding || loadingStarted;
  const isError = uploadError || createError || urlErrorData || isErrorEncoding;

  return {
    isError,
    setRecordingFormData,
    isLoading,
    progressState,
    uploadToS3Progress,
    createProgress,
    encodeProgress,
  };
};
