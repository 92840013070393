import * as React from 'react';
import styled from 'styled-components/macro';
import { theme } from 'lib/style';

interface Props {
  layoutImageSource?: string;
  isSelected?: boolean;
  layoutName?: string;
  selectedLayoutName?: string;
  onLayoutSelect?: (params?: any) => void;
}

const LayoutWrapper = styled.div`
  box-sizing: border-box;
  height: max-content;
  width: max-content;
  ${theme.mediaQueryMinWidth.sm} {
    margin: 0;
    min-width: 208px;
  }
`;

const LayoutDiv = styled.div<Props>`
  display: flex;
  min-width: 45px;
  justify-content: center;
  height: 100%;
`;

type ImgProps = {
  img?: string;
  isSelected?: boolean;
  layoutName?: string;
};

const LayoutImage = styled.img.attrs((props: ImgProps) => ({
  'data-layout-name': props.layoutName,
}))<ImgProps>`
  background-color: ${theme.palette.white};
  display: block;
  height: 15rem;
  border-radius: 16px;
  border: 3px solid
    ${props =>
      ({ theme }: any) =>
        props.isSelected ? theme.colors.primary[100] : 'transparent'};
  cursor: pointer;
`;

// Component
export const PageLayoutListItem = (props: Props) => {
  const { layoutImageSource, layoutName, isSelected, onLayoutSelect } = props;

  return (
    <LayoutWrapper>
      <LayoutDiv>
        <LayoutImage
          isSelected={isSelected}
          layoutName={layoutName}
          onClick={onLayoutSelect}
          src={layoutImageSource}
        />
      </LayoutDiv>
    </LayoutWrapper>
  );
};
