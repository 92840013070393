import {
  authenticateFB,
  getAccountPages,
  getTokenStatus,
  hasFacebookIntegration,
  removeFacebookIntegration,
  updateAnalyticsPageTokens,
  updateIntegration,
} from 'lib/api/facebookApi';
import React, { useEffect, useState } from 'react';
import { CONNECTION_TYPES } from '../userAccount/socialProfiles/constants';
import { ProfileCard } from '../userAccount/socialProfiles/ProfileCard';
import { SocialButton } from '../userAccount/socialProfiles/SocialButton';
import { FacebookIcon } from '../userAccount/socialProfiles/assets/social-icons';
import { FACEBOOK_SCOPES } from './constants';
import { successToast } from 'lib/components/toasts/success';

const social = {
  id: 1,
  title: 'Facebook',
  description:
    "Select a Facebook page to which we will publish your videos. Videos won't be published automatically without your permission or knowledge.",
};

interface IDropdownItem {
  value: number;
  label: string;
  id: string;
  accessToken: string;
}

export const Facebook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [facebookConnected, setFacebookConnected] = useState(
    CONNECTION_TYPES['NOT CONNECTED']
  );
  const [integrationExists, setIntegrationExists] = useState<boolean>(false);
  const [facebookUserId, setFacebookUserId] = useState<string>();
  const [accessToken, setAccessToken] = useState<string>();
  const [dropdownItems, setDropdownItems] = useState<IDropdownItem[] | null>(
    null
  );
  const [selectedItem, setSelectedItem] = useState<string>();
  const [disabledSave, setDisabledSave] = useState<boolean>();

  //Check if integrations exists in the database — if yes, set selected dropdown item as well
  const hasIntegration = async () => {
    try {
      const facebookIntegration = await hasFacebookIntegration();
      if (facebookIntegration.accessToken) {
        // check Facebook connection and set account details
        setAccessToken(facebookIntegration.accessToken);
        await checkFacebookConnection(facebookIntegration.accessToken);
        setIntegrationExists(true);
        if (facebookIntegration.pageId) {
          setSelectedItem(facebookIntegration.pageId);
        }
      } else {
        await window.FB.getLoginStatus(function (response: any) {
          if (response.status === 'connected') {
            logout();
          }
        });
        setIntegrationExists(false);
      }
    } catch (error) {
      setIntegrationExists(false);
    }
  };

  //Get all available FB pages connected to logged account
  const getPages = async (userId: string, accessToken: string) => {
    const pages = await getAccountPages(userId, accessToken);
    if (pages.data) {
      const dropdownItems = pages.data.map((page: any) => ({
        value: page.id,
        label: page.name,
        id: page.id,
        accessToken: page.access_token,
      }));

      setDropdownItems(dropdownItems ?? null);
      await updatePage();
      // If user is reconnecting or logging in —> update pages tokens in SocialIntegrationsAnalytics table
      if (isReconnecting || isLoggingIn) {
        await updatePagesTokens(dropdownItems);
        // Update existing selected page token in FacebookIntegrations table
        await updatePage(false);
        setIsReconnecting(false);
        setIsLoggingIn(false);
      }
    }
  };

  // Update page in FacebookIntegrations table
  const updatePage = async (clicked?: boolean) => {
    if (selectedItem) {
      const selectedPage = dropdownItems?.find(
        item => item.id === selectedItem
      );
      if (selectedPage) {
        await updateIntegration({
          pageName: selectedPage.label,
          pageToken: selectedPage.accessToken,
          pageId: selectedPage.id,
        });
        setDisabledSave(true);
        if (clicked) {
          successToast({
            title: 'Page has been sucessfully saved!',
          });
        }
      }
    } else if (dropdownItems && dropdownItems.length) {
      await updateIntegration({
        pageName: dropdownItems[0]?.label,
        pageToken: dropdownItems[0]?.accessToken,
        pageId: dropdownItems[0]?.id,
      });
    }
  };

  const updatePagesTokens = async (dropdownItems: IDropdownItem[]) => {
    if (dropdownItems && dropdownItems.length !== 0) {
      await updateAnalyticsPageTokens(
        dropdownItems.map((item: IDropdownItem) => ({
          pageId: item.id,
          pageToken: item.accessToken,
        }))
      );
    }
  };

  const checkFBConnectionAsync = async (accessToken: string, response: any) => {
    const tokenStatus = await getTokenStatus(accessToken).catch(err => err);
    // if tokenStatus has an id, it means that token is valid
    if (tokenStatus && tokenStatus.id && response.status === 'connected') {
      setFacebookConnected(CONNECTION_TYPES['CONNECTED']);
      setFacebookUserId(tokenStatus.id);
    } else {
      await removeFacebookIntegration();
      setFacebookConnected(CONNECTION_TYPES['NOT CONNECTED']);
    }
  };
  //Check if Facebook is connected with SDK and if integration exists in the database
  const checkFacebookConnection = (accessToken: string) => {
    if (accessToken) {
      window.FB.getLoginStatus(function (response: any) {
        checkFBConnectionAsync(accessToken, response);
      });
    }
  };

  useEffect(() => {
    hasIntegration();
  }, [isLoggingIn]);

  useEffect(() => {
    const integrationDetails = async () => {
      const integration = await hasFacebookIntegration();
      setDisabledSave(
        !selectedItem ||
          (!!integration.pageId && integration.pageId === selectedItem)
      );
    };
    integrationDetails();
  }, [selectedItem]);

  useEffect(() => {
    if (
      facebookConnected === CONNECTION_TYPES['CONNECTED'] &&
      accessToken &&
      facebookUserId
    ) {
      getPages(facebookUserId, accessToken);
    }
  }, [
    facebookUserId,
    accessToken,
    facebookConnected,
    integrationExists,
    isReconnecting,
    isLoggingIn,
  ]);

  const loginFunction = async (res: any) => {
    if (res.authResponse && res.status === 'connected') {
      await authenticateFB(res.authResponse).catch(err => err);
      setFacebookConnected(CONNECTION_TYPES['CONNECTED']);
      setIsLoggingIn(true);
    }
  };
  //Login through Facebook SDK and saving the integration into database if sucessfully logged in
  const logIn = () => {
    window.FB.login(
      function (res: any) {
        loginFunction(res);
      },
      {
        scope: FACEBOOK_SCOPES,
      }
    );
  };

  const logoutFunction = async () => {
    await removeFacebookIntegration();
    setSelectedItem(undefined);
    setIsLoading(false);
    setFacebookConnected(CONNECTION_TYPES['NOT CONNECTED']);
  };
  const logout = (isReconnecting?: boolean) => {
    setIsLoading(true);
    window.FB.getLoginStatus(function (response: any) {
      if (response && response.status === 'connected') {
        window.FB.logout(() => {
          logoutFunction();
          if (isReconnecting && typeof isReconnecting === 'boolean') {
            logIn();
          }
        });
      }
    });
  };

  const reconnect = async () => {
    logout(true);
  };

  return (
    <ProfileCard
      key={social.id}
      title={social.title}
      description={social.description}
      connection={facebookConnected}
      button={
        <SocialButton
          title='Login with Facebook'
          icon={<FacebookIcon />}
          onClick={() => logIn()}
          color='#1877F2'
        />
      }
      loading={isLoading}
      onSaveClick={() => updatePage(true)}
      onSaveDisabled={disabledSave}
      onDisconnect={logout}
      onReconnect={() => reconnect()}
      onDropdownChange={setSelectedItem}
      selectedItem={selectedItem}
      dropdown={{
        label: 'Facebook page',
        items: dropdownItems,
      }}
    />
  );
};
