import { VDP_IntegrationTypes } from 'lib/const/vdp';
import { ICombinedUserData } from 'lib/context';

export const createVDPIntegrationOptions = (userData: ICombinedUserData) => {
  const hasHomenet = !!userData.customer.dealerComId;
  const hasIPacket = !!userData.customer.iPacketDealerId;
  const hasJDPower = !!userData.customer.jdPowerVDPEnabled;

  const options = [];
  if (hasHomenet) {
    options.push(VDP_IntegrationTypes.homenet);
  }

  if (hasIPacket) {
    options.push(VDP_IntegrationTypes.ipacket);
  }

  if (hasJDPower) {
    options.push(VDP_IntegrationTypes.jdpower);
  }

  options.push(VDP_IntegrationTypes.salespages);

  return options;
};
