import { LoadingIndicator, NewModal } from 'lib/components';
import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import drag from '../assets/images/drag.png';
import ReactDOM from 'react-dom';
import { Gap } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import { useQueryClient } from 'react-query';
import {
  EditableGuides,
  useEditableGuideCategoriesQuery,
} from 'lib/api/guides/editableGuideCategoriesQuery';
import { guidesKeyes } from 'lib/api/guides/guidesKeyes';
import { useReorderCategoriesMutation } from 'lib/api/guides/reoderCategoriesMutation';
import { ParagraphSmallBold } from 'lib/components/styles/typography';
import { Button } from 'react-covideo-common';

const Content = styled.div<{ isLoading?: boolean }>`
  position: relative;
  display: flex;
  height: 342px;
  max-height: 342px;
  padding: 8px 0px 8px 0px;
  align-items: flex-start;
  gap: 12px;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 6px;
  background-color: ${theme.palette.gray10};
  ${({ isLoading }) =>
    isLoading &&
    css`
      position: relative;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      pointer-events: none;
    `}
`;

const ContentWrapper = styled(Content)`
  align-items: center;
  justify-content: center;
  margin-bottom: 72px;
`;

const DragItem = styled.div`
  display: flex;
  gap: 12px;
  display: relative;
  width: 100%;
  height: 32px;
  padding: 0 8px;
  box-sizing: border-box;
  align-items: center;
  :hover {
    background-color: rgba(0, 27, 83, 0.05);
  }
`;

const DragParagrph = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${theme.palette.gray100};
  margin: 0;
`;

interface IProps {
  closeModalHandler: () => void;
}
interface IDraggableProps {
  draggableProvided: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
  item: EditableGuides;
}

const portal = document.createElement('div');
document.body.appendChild(portal);

export const ReorderCategoryModal = ({ closeModalHandler }: IProps) => {
  const { data, isLoading: isLoadingInitData } =
    useEditableGuideCategoriesQuery();

  const { mutateAsync, isLoading } =
    useReorderCategoriesMutation(closeModalHandler);

  return (
    <NewModal
      headerText='Reorder Categories for All Users'
      closeModal={closeModalHandler}
    >
      {isLoadingInitData ? (
        <ContentWrapper>
          <LoadingIndicator isLoading={true} />
        </ContentWrapper>
      ) : (
        <>
          {!data?.length ? (
            <ContentWrapper>
              <ParagraphSmallBold color={theme.palette.gray80}>
                There is no categories to reoreder!
              </ParagraphSmallBold>
            </ContentWrapper>
          ) : (
            <>
              <ReorderDragList
                data={data as EditableGuides[]}
                isLoading={isLoading}
              />
              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  text='Cancel'
                  variant='secondary'
                  disabled={isLoading}
                  onClick={async () => {
                    closeModalHandler();
                  }}
                />
                <Button
                  text={isLoading ? 'Reordering...' : 'Save Changes'}
                  variant='primary'
                  disabled={isLoading}
                  onClick={async () => {
                    if (!data) {
                      return;
                    }
                    const ids = data.map(value => value.guideCategoryId);
                    mutateAsync({ payload: ids });
                  }}
                />
              </Gap>
            </>
          )}
        </>
      )}
    </NewModal>
  );
};

const DraggableItemPortal = ({
  draggableProvided,
  draggableSnapshot,
  item,
}: IDraggableProps) => {
  const usePortal = draggableSnapshot.isDragging;

  const child = (
    <DragItem
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
    >
      <img src={drag} alt='drag-icon' />
      <DragParagrph>{item.name}</DragParagrph>
    </DragItem>
  );

  if (!usePortal) {
    return child;
  }

  // if dragging - put the item in a portal
  return ReactDOM.createPortal(child, portal);
};

const ReorderDragList = ({
  data,
  isLoading,
}: {
  data: EditableGuides[];
  isLoading: boolean;
}) => {
  const queryClient = useQueryClient();
  const handleDragEnd = (result: DropResult) => {
    // No valid destination, exit early
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // Item dropped in the same position, no need to reorder
    if (source.index === destination.index) {
      return;
    }
    const schema = queryClient.getQueryData(
      guidesKeyes.editableCategories()
    ) as EditableGuides[];

    const [movedItem] = schema.splice(source.index, 1);
    schema.splice(destination.index, 0, movedItem);

    queryClient.setQueryData(guidesKeyes.editableCategories(), schema);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='vertical-list'>
        {droppableProvided => (
          <Content
            isLoading={isLoading}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {data?.map((item: EditableGuides, index: number) => (
              <Draggable
                key={item.guideCategoryId}
                draggableId={item?.guideCategoryId?.toString()}
                index={index}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <DraggableItemPortal
                    item={item}
                    draggableProvided={draggableProvided}
                    draggableSnapshot={draggableSnapshot}
                  />
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </Content>
        )}
      </Droppable>
    </DragDropContext>
  );
};
