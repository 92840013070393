import { Dropdown } from 'lib/components';
import * as React from 'react';
import { OptionItems } from './ManageDefaultComponents';

export type IdOption = { title: string; id: number };

type Props = {
  title: string;
  options: Array<IdOption>;
  defaultValue?: number;
  onChange: (option: IdOption) => void;
};

export const OptionsSelector = (props: Props) => {
  const { defaultValue, options, onChange, title } = props;
  return (
    <OptionItems>
      {title}
      <Dropdown
        options={options}
        getOptionLabel={option => option.title}
        getOptionValue={option => option.id}
        menuPosition='fixed'
        creatable={false}
        width={270}
        value={options.find(o => {
          return o.id == defaultValue;
        })}
        onChange={onChange}
        className='option-container'
        extendStyles={{
          valueContainer: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            flexWrap: 'nowrap',
          },
        }}
      />
    </OptionItems>
  );
};
