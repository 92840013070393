import { RouteComponentProps, StaticContext } from 'react-router';

export enum INTEGRATION_TYPE {
  AUTOMOTIVE = 'automotive',
  UNIVERSAL = 'universal',
}

export interface IntegrationProps {
  key: string;
  name: string;
  categories: string[];
  description: string | JSX.Element | JSX.Element[];
  path: string;
  component:
    | React.ComponentType<any>
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>;
  imgSrc: string;
  productFeatureId?: number;
  enabled?: boolean;
  thumbnail?: string;
  videoSource?: string;
  actionButton?: JSX.Element;
  onClick?: any;
  automotiveHidden?: boolean;
  show: INTEGRATION_TYPE[];
}

// chrome
export const CHROME_DOWNLOAD_LINK =
  'https://chromewebstore.google.com/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc';
// gmail
export const GMAIL_INSTALL_LINK =
  'https://chromewebstore.google.com/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc';
// linkedin
export const LINKEDIN_INSTALL_LINK =
  'https://chromewebstore.google.com/detail/video-screen-recorder-for/hpinghefcjjgfegpbpcigbgcdmboadjc ';
// zoom
export const ZOOM_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/5645195-set-up-the-covideo-for-zoom-integration';
// salesforce
export const SALESFORCE_HELP_DESK_LINK =
  'https://support.covideo.com/en/articles/4859415-how-to-use-the-covideo-salesforce-integration';
// office365
export const OFFICE_365_HELP_DESK_LINK =
  'https://appsource.microsoft.com/en-us/product/office/WA200002558?tab=Overview';
// mobile iOS/Android
export const IOS_URL = 'https://apps.apple.com/us/app/covideo/id507458417';
export const ANDROID_INSTALL_LINK =
  'https://play.google.com/store/apps/details?id=covideo.com&feature=nav_result#?t=W251bGwsMSwyLDNd';
