import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { superAdminKeys } from './superAdminKeys';

export interface IResseler {
  resellerId: number;
  resellerName: string;
  url: string;
  email: string;
  phoneNumber: string;
  pls: number;
  customerId: number | null;
  playbackUrl: string;
  integration: number;
  fullReportAccess: number;
  easyCare: number;
  dealer: number;
  resellerReportingEnabled: number;
}

export const getResellers = async (
  search: string = '',
  start: number = 0,
  limit: number,
  resellerSortOrder?: string,
  resellerSortKey?: string
) => {
  const params = {
    search,
    start,
    limit,
    resellerSortOrder,
    resellerSortKey,
    active: 1,
  };

  return (
    await EXPRESS_API.get(`superadmin/resellers`, {
      params,
    })
  ).data as [IResseler[], number];
};

export const useGetRessellers = (
  search: string,
  start: number,
  limit: number,
  enabled: boolean
) => {
  return useQuery(
    superAdminKeys.reseller(),
    () => getResellers(search, start, limit),
    {
      refetchOnMount: true,
      enabled,
    }
  );
};
