import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from 'lib/context';

export const CompanyRoutes = () => {
  const { whitelabel } = useAuth();

  return (
    <Switch>
      <Route
        path='/company/Company_AdminReports'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_AdminReports.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_ManageCustomers'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_ManageCustomers.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_ManageUsers'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_ManageUsers.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_ManageAssignedUsers'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_ManageAssignedUsers.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_ChangeReseller'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_ChangeReseller.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_UsageReports'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_UsageReports.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_Graphics'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_Graphics.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_Resellers'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_Resellers.php`;
          return null;
        }}
      />
      <Route
        path='/company/Company_OptOutReports'
        component={() => {
          window.location.href = `https://${whitelabel.domainV2}/backoffice/Company_OptOutReports.php`;
          return null;
        }}
      />
    </Switch>
  );
};
