import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Chat } from './Chat';
// import { Details } from './Details';

export const Main = () => {
  return (
    <Switch>
      <Route path="/sms/chats/:id" component={Chat} />
      <Route path="/sms/chats" component={Chat} />
      {/* <Route path="/users/:userId" component={Details} /> */}
    </Switch>
  );
};
