import React, { useState } from 'react';
import {
  ParagraphNormalBold,
  ParagraphSmall,
} from 'lib/components/styles/typography';
import { Gap } from 'lib/components/styles/layout';
import { theme } from 'lib/style';
import { Button } from 'react-covideo-common';
import { MdClose } from 'react-icons/md';
import { DeleteChildCustomerModal } from '../../modals/DeleteChildCustomerModal';
import { ItemContainer } from '../ItemContainer';

interface IProps {
  onRemove?: () => void | undefined;
  business: string;
  customerId: number;
  isEditMode: boolean;
}

export const CustomerItem = ({
  onRemove,
  business,
  customerId,
  isEditMode,
}: IProps) => {
  const [showModal, setShowModal] = useState(false);

  const closeModalHandler = () => setShowModal(false);
  const openModalHandler = () => setShowModal(true);

  const onBtnClickHandler = () => {
    if (isEditMode) {
      return openModalHandler();
    }
    onRemove?.();
  };

  return (
    <>
      <ItemContainer
        flexDirection='row'
        width='100%'
        alignItems='center'
        justifyContent='flex-start'
        data-set={customerId}
      >
        <>
          {!!onRemove && (
            <Button
              onClick={onBtnClickHandler}
              variant='destructive'
              icon={<MdClose color={theme.palette.buttonDelete} size={18} />}
              size='small'
            />
          )}
        </>
        <Gap justifyContent='space-between' style={{ flex: 1 }}>
          <ParagraphNormalBold
            color={theme.palette.gray100}
            ellipsis
            style={{ maxWidth: !onRemove ? 230 : 170, whiteSpace: 'nowrap' }}
          >
            {business}
          </ParagraphNormalBold>
          <ParagraphSmall color={theme.palette.gray60}>
            {customerId}
          </ParagraphSmall>
        </Gap>
      </ItemContainer>
      {showModal && (
        <DeleteChildCustomerModal
          closeModalHandler={closeModalHandler}
          customerId={customerId}
          business={business}
        />
      )}
    </>
  );
};
