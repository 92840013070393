import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { contactsKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

export const getContactGroups = async (id: string) => {
  const response = await EXPRESS_API.get(`/contacts/${id}/groups`);
  return response.data;
};

export const useQueryContactGroups = (id: string) => {
  const { showError } = useToastError();

  return useQuery(contactsKeys.contact_groups(id), () => getContactGroups(id), {
    onError: showError,
    refetchOnMount: true,
  });
};
