import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal, LoadingIndicator, Search, Dropdown } from 'lib/components';
import { theme } from 'lib/style';
import { VideoListItem } from 'lib/api/types';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsCalendarEvent } from 'react-icons/bs';
import { getVideos } from 'lib/api';
import { useGetFolders } from 'lib/api/folders/getFolders';
import { DEFAULT_FOLDER, SHARED_FOLDER_PREFIX } from 'lib/const/Folders';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 520px;
  max-width: 100%;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  margin: 0 auto;
`;

const CloseButtonWrap = styled.div`
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;
const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const DropdownContainer = styled.div`
  width: 254px;
  .border-radius > div {
    border-radius: 5px;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  height: 24px;
  bottom: 10px;
`;

const TooltipContent = styled.div`
  z-index: 99999;
  position: absolute;
  width: 100%;
  height: auto;
  display: inline-flex;
  padding: 4px 12px 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  right: 0%;
  top: 150%;
  left: -8%;

  border-radius: 1px;
  background: var(--white-100, #fff);

  background-color: ${theme.palette.gray10};
  box-shadow: 0 8px 32px 0 rgba(29, 30, 36, 0.2);
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
`;

const TooltipTrigger = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;

  &:hover + ${TooltipContent} {
    opacity: 1;
    visibility: visible;
  }
`;

type ThumbnailProps = {
  image: string;
};
const VideoList = styled.div`
  height: 350px;
  padding-right: 10px;
  overflow-y: auto;
`;
const VideoSelectButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.palette.gray80};
  background-color: ${theme.palette.gray10};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 5px;
  padding: 8px 12px;
`;
const VideoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 520px;
  max-width: 100%;
  background: ${theme.palette.white};
  border: 1px solid ${theme.palette.gray};
  box-shadow: 0px 4px 12px rgba(29, 30, 36, 0.04);
  border-radius: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${theme.palette.primaryThemeColor20Percent};
    ${VideoSelectButton} {
      border: 1px solid ${theme.palette.primaryThemeColor20Percent};
      background: ${theme.palette.primaryThemeColor5Percent};
      color: ${props => props.theme.colors.primary[100]};
    }
  }
`;
const VideoItemThumbnail = styled.div<ThumbnailProps>`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${theme.palette.white};
  width: 96px;
  height: 64px;
  margin-right: 24px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;
const VideoItemTitle = styled.h3`
  color: ${theme.palette.coal};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 268px;
  margin: 8px 0 4px 0;
`;
const VideoItemDetails = styled.div`
  color: ${theme.palette.gray80};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
`;
const VideoItemButtonWrapper = styled.div`
  margin-right: 12px;
`;
const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 200px;
  max-width: 100%;
`;

type Props = {
  handleModalClose: () => void;
  onSelect: (video: any) => void;
};

export const CtaVideoModal = (props: Props) => {
  const { handleModalClose, onSelect } = props;
  const { ref } = useOnClickOutside(handleModalClose);
  const [searchQuery, setSearchQuery] = useState('');
  const [videos, setVideos] = useState([] as VideoListItem[]);
  const [loading, setLoading] = useState(true);
  const { data: folders } = useGetFolders({ getShared: true });

  const [selectedFolder, setSelectedFolder] = useState(
    folders?.at(0) ?? DEFAULT_FOLDER
  );

  const onSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  const fetchVideos = async () => {
    setLoading(true);
    const folder = !!selectedFolder?.access
      ? `${SHARED_FOLDER_PREFIX}${selectedFolder.folderId}`
      : selectedFolder.name;
    const response = await getVideos({
      page: 0,
      size: searchQuery === '' ? 100 : 999,
      search: searchQuery,
      folder,
    });
    setVideos(response.videos);
    setLoading(false);
  };

  useEffect(() => {
    if (folders && folders.length > 0) {
      setSelectedFolder(folders[0]);
    }
  }, [folders]);

  useEffect(() => {
    fetchVideos();
  }, [searchQuery, selectedFolder]);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));
    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  return (
    <Modal style={{ position: 'fixed' }}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <CloseButtonWrap title={'Close'}>
            <AiOutlineArrowLeft
              onClick={() => handleModalClose()}
              color={theme.palette.label}
              size={24}
            />
          </CloseButtonWrap>
          <HeaderTitle>Select video</HeaderTitle>
        </Header>
        <Menu>
          <div style={{ width: '258px' }}>
            <Search
              placeholder={`Search videos and tags...`}
              onSearch={onSearch}
              prevSearch={searchQuery}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <DropdownContainer>
              <Dropdown
                className='border-radius'
                value={{
                  value: selectedFolder.folderId,
                  label: selectedFolder.name,
                }}
                onChange={option => {
                  setSelectedFolder(
                    folders?.find(folder => folder.folderId === option.value) ||
                      DEFAULT_FOLDER
                  );
                }}
                options={folders?.map(folder => ({
                  value: folder.folderId,
                  label: folder.name,
                }))}
                dropdownHeight={500}
                height={40}
              />
            </DropdownContainer>
          </div>
        </Menu>
        <VideoList>
          {loading && <LoadingIndicator isLoading={loading} height='200px' />}
          {!loading && videos.length > 0 && (
            <>
              {videos.map((video: any) => (
                <VideoItem key={video.id} onClick={() => onSelect(video)}>
                  <VideoItemThumbnail image={video.thumbnail} />
                  <div>
                    <TooltipWrapper>
                      <TooltipTrigger>
                        <VideoItemTitle>{video.title}</VideoItemTitle>
                      </TooltipTrigger>
                      <TooltipContent>{video.title}</TooltipContent>
                    </TooltipWrapper>
                    <VideoItemDetails>
                      <BsCalendarEvent color={theme.palette.gray40} size={10} />
                      <span>{video.recordDate}</span>
                    </VideoItemDetails>
                  </div>
                  <VideoItemButtonWrapper>
                    <VideoSelectButton>Select Video</VideoSelectButton>
                  </VideoItemButtonWrapper>
                </VideoItem>
              ))}
            </>
          )}
          {!loading && !videos.length && (
            <EmptyList>No videos found.</EmptyList>
          )}
        </VideoList>
      </div>
    </Modal>
  );
};
