import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import styled from 'styled-components/macro';
import { MdArrowDownward, MdArrowUpward, MdEmail } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import { theme } from 'lib/style';
import {
  Field,
  ModalCustomTableFields,
  TABLE_NAME_PREFIX,
} from 'lib/components/modal';
import { reportData, reportTypes } from 'lib/const';
import {
  Dropdown,
  LoadingIndicator,
  Search,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { REPORT_SCOPE } from 'lib/api';
import {
  addThousandCommaSeparator,
  formatSecondsToTime,
} from 'lib/utils/functions';
import queryString from 'query-string';
import {
  DateRangeSelector,
  ReportCard,
  GenerateReportModal,
  timeRangeConstants,
  calculateDatesForRange,
} from '../../components';
import { Gap, HeaderWrapper } from 'lib/components/styles/layout';
import { Heading } from 'lib/components/styles/typography';
import { successToast } from 'lib/components/toasts/success';
import { OrganizationRegion, Region, useAuth } from 'lib/context';
import { Option } from 'lib/components/dropdown/Dropdown';
import { useGetRegionsQuery } from 'lib/api/region/getResellerRegions';
import {
  ORGANIZATION_PICKER_LINK,
  ORGANIZATION_PICKER_TITLE,
  getReportBaseUrl,
  getReportTitle,
} from './Main';
import { useGetOrganizationRegionsQuery } from 'lib/api/organizations/getOrganizationRegions';
import { NotFound } from 'app/pages/notFound/NotFound';
import {
  checkIfHasAccessToMultipleOrg,
  getOrgNameFromAccess,
} from 'lib/utils/organization';
import { Breadcrumbs, Crumb } from '../../components/Breadcrumbs';
import { STANDARD_DATE_FORMAT } from 'lib/const/DateFormat';
import { getDateRangeFromLocalstorage } from '../../components/DateRangeSelector/DateRangeSelector';
import { Button } from 'react-covideo-common';
import {
  MetricsCustomer,
  useGetCustomersMetrics,
} from 'lib/api/metrics/getCustomerMetrics';
import { ReportTypes } from 'lib/const/ReportData';
import { IoMdOptions } from 'react-icons/io';

const CUSTOM_TABLE_COLUMNS_KEY = 'AllTeamsReportCustomTableFields';

const order = {
  ASC: 'asc',
  DESC: 'desc',
};
type TableFieldType = {
  value: string;
  label: string;
  type: string;
  formatter?: (value?: string | number) => string;
};

const fieldValues = {
  BUSINESS: 'business',
  LAST_LOGIN: 'loginDate',
  REGION: 'region',
  DETAILS: 'details',
  RECORDED: 'recorded',
  SENT_EMAILS: 'sentEmails',
  VIEWS: ReportTypes.VIEWS,
  UNIQUE_VIEWS: ReportTypes.UNIQUE_VIEWS,
  CLICKS: ReportTypes.CTA,
  ENGAGEMENT: ReportTypes.ENGAGEMENT,
  OPENED: ReportTypes.OPENED,
  DELIVERED: ReportTypes.DELIVERED,
  DEFERRED: ReportTypes.DEFERRED,
  SPAM: ReportTypes.SPAM,
  BOUNCED: ReportTypes.BOUNCED,
  DROPPED: ReportTypes.DROPPED,
  UNSUBSCRIBED: ReportTypes.UNSUBSCRIBED,
  AVERAGE_VIDEO_DURATION: ReportTypes.AVERAGE_VIDEO_DURATION,
  TIME_WATCHED: ReportTypes.TIME_WATCHED,
};

const allDynamicTableFields: TableFieldType[] = [
  {
    value: fieldValues.RECORDED,
    label: 'Recorded',
    type: 'number',
  },
  {
    value: fieldValues.SENT_EMAILS,
    label: 'Sent',
    type: 'number',
  },
  {
    value: fieldValues.VIEWS,
    label: 'Views',
    type: 'number',
  },
  {
    value: fieldValues.UNIQUE_VIEWS,
    label: 'Unique Views',
    type: 'number',
  },
  {
    value: fieldValues.CLICKS,
    label: 'CTA Clicks',
    type: 'number',
  },
  {
    value: fieldValues.LAST_LOGIN,
    label: 'Last Login',
    type: 'string',
  },
  {
    value: fieldValues.ENGAGEMENT,
    label: 'Engagement',
    type: 'number',
  },
  {
    value: fieldValues.OPENED,
    label: 'Opened',
    type: 'number',
  },
  {
    value: fieldValues.DELIVERED,
    label: 'Delivered',
    type: 'number',
  },
  {
    value: fieldValues.DEFERRED,
    label: 'Deferred',
    type: 'number',
  },
  {
    value: fieldValues.SPAM,
    label: 'Spam',
    type: 'number',
  },
  {
    value: fieldValues.BOUNCED,
    label: 'Bounced',
    type: 'number',
  },
  {
    value: fieldValues.DROPPED,
    label: 'Dropped',
    type: 'number',
  },
  {
    value: fieldValues.UNSUBSCRIBED,
    label: 'Unsubscribed',
    type: 'number',
  },
  {
    value: fieldValues.AVERAGE_VIDEO_DURATION,
    label: 'Avg. Video Duration',
    type: 'number',
  },
  {
    value: fieldValues.TIME_WATCHED,
    label: 'Time Watched',
    type: 'number',
    formatter: value => formatSecondsToTime(parseInt(value?.toString() || '0')),
  },
];

const fixedTableFields = {
  business: {
    value: fieldValues.BUSINESS,
    label: 'Companies',
    type: 'string',
    orderKey: fieldValues.BUSINESS,
    defaultOrder: order.ASC,
  },
  region: {
    value: fieldValues.REGION,
    label: 'Region',
    type: 'string',
  },
  details: {
    value: fieldValues.DETAILS,
    label: '',
    type: 'string',
  },
};

// fix report and custom column mismatch
const tableFieldToReportTypeMapper = {
  [fieldValues.RECORDED]: ReportTypes.RECORDED,
  [fieldValues.SENT_EMAILS]: ReportTypes.SENT,
  [fieldValues.LAST_LOGIN]: ReportTypes.LAST_LOGIN,
};

const TableCell = styled.div<{ clicked?: boolean }>`
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: ${props =>
    !!props.clicked ? 'pointer' : 'auto'}; /**** SUS-926 changes ****/
`;

const ClearIds = styled.div`
  margin-right: 32px;
  font-size: 16px;
  font-weight: 800;
  color: ${theme.palette.gray60};
  cursor: pointer;
`;

const AllTeamsWrapper = styled.div`
  .dropdown-container {
    margin: 0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const queryDateFormat = STANDARD_DATE_FORMAT;

const ALL_USERS = ['all'];
const ALL_CUSTOMERS = 'all';
const DEFAULT_REPORTS = [
  reportTypes.RECORDED,
  reportTypes.SENT,
  reportTypes.VIEWS,
  reportTypes.CTA,
];

interface Props {
  reportScope: REPORT_SCOPE;
}

export const List = ({ reportScope = REPORT_SCOPE.RESELLER }: Props) => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const location = useLocation();
  const { userData } = useAuth();
  const { userOrganizationAccess = [], hasRegions, isAutomotive } = userData;
  const allReports = useMemo(() => {
    return Object.keys(reportData)
      .filter(
        reportValue =>
          !reportData[reportValue].isAutomotive ||
          (!!isAutomotive && !!reportData[reportValue].isAutomotive)
      )
      .filter(report =>
        allDynamicTableFields.some(field => {
          const fieldReportValueKey =
            tableFieldToReportTypeMapper[field.value] || field.value;
          return fieldReportValueKey === report;
        })
      )
      .map(reportValue => ({
        value: reportValue,
        label: reportData[reportValue].label,
        description: reportData[reportValue]?.description || '',
      }));
  }, [reportData, isAutomotive, allDynamicTableFields]);

  const defaultReports = useMemo(() => {
    return allReports.filter(report => DEFAULT_REPORTS.includes(report.value));
  }, [allReports, DEFAULT_REPORTS]);
  const hasMultipleOrgs = checkIfHasAccessToMultipleOrg(userOrganizationAccess);
  const organizationName = getOrgNameFromAccess(
    organizationId,
    userOrganizationAccess
  );
  const hasOrganizationAccess = userOrganizationAccess.some(
    access => organizationId && access.organizationId === organizationId
  );
  const isOrganizationReport =
    !!organizationId && reportScope === REPORT_SCOPE.ORGANIZATION;

  const showResellerRegion =
    reportScope === REPORT_SCOPE.RESELLER && hasRegions;
  const queryParams: any = queryString.parse(location.search);
  let customerIds = queryParams.customerIds || '';
  const storedDateRange = getDateRangeFromLocalstorage();
  const initRange =
    queryParams.range ||
    storedDateRange?.range ||
    timeRangeConstants.LAST_7_DAYS;
  const { start, end } = calculateDatesForRange(
    initRange,
    storedDateRange?.start,
    storedDateRange?.end
  );

  const [startDate, setStartDate] = useState<Date>(start);
  const [endDate, setEndDate] = useState<Date>(end);
  const [dateRange, setDateRange] = useState(initRange);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sortField, setSortField] = useState(fieldValues.BUSINESS);
  const [sortOrder, setSortOrder] = useState(order.ASC);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSendReportModal, setShowSendReportModal] = useState(false);
  const [showDownloadReportModal, setShowDownloadReportModal] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('0');
  const [limitToOrganizationRegions, setLimitToOrganizationRegions] =
    useState<string[]>();
  const [selectedOrganizationRegionId, setSelectedOrganizationRegionId] =
    useState('0');
  const [reports, setReports] = useState<Field[]>([]);
  const [showCustomizeTableFieldsModal, setShowCustomizeTableFieldsModal] =
    useState(false);

  const history = useHistory();
  const { data: regions } = useGetRegionsQuery(showResellerRegion);
  const { data: organizationRegions } = useGetOrganizationRegionsQuery(
    isOrganizationReport ? organizationId : ''
  );

  const customReportTypes = (reports || []).map(
    report => report.value
  ) as ReportTypes[];

  const { data, isLoading } = useGetCustomersMetrics(
    {
      from: startDate,
      to: endDate,
      search: searchQuery,
      reports: customReportTypes,
      sort: sortField,
      order: sortOrder,
      start: page * size,
      limit: size,
      customerIds,
      regionId: Number(selectedRegion),
      organizationRegionId: Number(selectedOrganizationRegionId),
      reportScope,
      organizationId,
    },
    !!customReportTypes?.length
  );

  const companies = useMemo(() => {
    return (data?.companies || []).map((customer: MetricsCustomer) => customer);
  }, [data?.companies]);
  const count = data?.count;

  useEffect(() => {
    try {
      const selectedReports = localStorage.getItem(
        TABLE_NAME_PREFIX + CUSTOM_TABLE_COLUMNS_KEY
      );
      if (!selectedReports) {
        setReports(defaultReports);
        return;
      }
      const localStorageReports: Field[] = JSON.parse(selectedReports) || [];
      setReports(
        localStorageReports.filter((report: Field) =>
          allReports.some(r => r.value === report.value)
        )
      );
    } catch (error) {
      setReports(defaultReports);
    }
  }, [allReports]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    history.push({
      pathname: `/reports/organization/${organizationId}/companies`,
    });
  }, [organizationId]);

  useEffect(() => {
    if (!organizationId) {
      return;
    }
    const currentOrgAccess = userOrganizationAccess.find(
      org => org.organizationId.toString() === organizationId.toString()
    );
    setLimitToOrganizationRegions(
      (currentOrgAccess?.limitToRegions || []).map(orgRegion =>
        orgRegion.toString()
      ) || []
    );
  }, [organizationId, userOrganizationAccess]);

  const onDateRangeChange = (start: Date, end: Date, range: string) => {
    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  const onSearch = (query: string) => {
    query = query.toLowerCase();
    setPage(0);
    setSearchQuery(query);
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };

  const onEmailsSent = () => {
    successToast({
      title: 'The report will be generated and emailed shortly.',
    });
  };

  const clearCustomerIds = () => {
    const queryParams: any = queryString.parse(location.search);
    queryParams.customerIds = '';
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const handleSortClick = (newSortField: string, defaultOrder: string) => {
    setSortField(newSortField);
    if (sortField !== newSortField) {
      setSortOrder(defaultOrder);
    } else {
      setSortOrder(sortOrder === order.ASC ? order.DESC : order.ASC);
    }
  };

  const handleCustomerCellClick = (customerId: number | string) => {
    history.push(
      `${getReportBaseUrl(reportScope, organizationId)}/${customerId}?start=${dayjs(
        startDate
      ).format(queryDateFormat)}&end=${dayjs(endDate).format(
        queryDateFormat
      )}&range=${dateRange}`
    );
  };

  const onSaveCustomTableFields = (fields: Field[]) => {
    localStorage.setItem(
      `${TABLE_NAME_PREFIX + CUSTOM_TABLE_COLUMNS_KEY}`,
      JSON.stringify(fields)
    );
    setReports(fields);
    setShowCustomizeTableFieldsModal(false);
  };

  const regionOptions = showResellerRegion
    ? (regions || []).map((region: Region) => ({
        label: region.name,
        value: region.regionId,
      }))
    : [];

  const organizationRegionOptions = isOrganizationReport
    ? (organizationRegions || [])
        .filter(
          orgRegion =>
            !limitToOrganizationRegions?.length ||
            limitToOrganizationRegions.some(
              orgRegionId =>
                orgRegionId.toString() ===
                orgRegion.organizationRegionId.toString()
            )
        )
        .map((organizationRegion: OrganizationRegion) => ({
          label: organizationRegion.name,
          value: organizationRegion.organizationRegionId.toString(),
        }))
    : [];

  const showOrganizationRegionDropdown =
    isOrganizationReport && organizationRegionOptions.length > 1;

  let breadcrumbs: Crumb[] = [];
  if (reportScope === REPORT_SCOPE.ORGANIZATION && hasMultipleOrgs) {
    breadcrumbs = [
      {
        label: ORGANIZATION_PICKER_TITLE,
        link: ORGANIZATION_PICKER_LINK,
      },
      {
        label: organizationName || getReportTitle(reportScope),
        link: `${getReportBaseUrl(reportScope, organizationId)}${
          location.search
        }`,
      },
    ];
  }

  const filteredTableFields: TableFieldType[] = useMemo(() => {
    const filteredFields: TableFieldType[] = [];
    reports.forEach(report => {
      const tableField = allDynamicTableFields.find(field => {
        const fieldReportValueKey =
          tableFieldToReportTypeMapper[field.value] || field.value;
        return fieldReportValueKey === report.value;
      });
      if (tableField) {
        filteredFields.push(tableField);
      }
    });
    return filteredFields;
  }, [allDynamicTableFields, reports]);

  if (organizationId && !hasOrganizationAccess) {
    return <NotFound />;
  }

  return (
    <AllTeamsWrapper>
      {reportScope === REPORT_SCOPE.RESELLER && (
        <>
          {!customerIds ? (
            <Heading m='0 0 32px 0'>{getReportTitle(reportScope)}</Heading>
          ) : (
            <div style={{ margin: '0 0 32px 0' }}>
              <Heading>Selected teams</Heading>
              <ClearIds onClick={() => clearCustomerIds()}>(show all)</ClearIds>
            </div>
          )}
        </>
      )}
      {reportScope === REPORT_SCOPE.ORGANIZATION && (
        <>
          {!breadcrumbs.length ? (
            <Heading m='0 0 32px 0'>{getReportTitle(reportScope)}</Heading>
          ) : (
            <Breadcrumbs crumbs={breadcrumbs} />
          )}
        </>
      )}
      <HeaderWrapper>
        <DateRangeSelector
          initialValue={dateRange}
          onDateRangeChange={onDateRangeChange}
        />
        <Gap>
          <Button
            text='Send'
            icon={<MdEmail size={20} />}
            onClick={() => setShowSendReportModal(true)}
          />
        </Gap>
      </HeaderWrapper>
      <Gap flexWrap='nowrap' style={{ margin: '32px 0px' }}>
        <ReportCard
          reportType={reportTypes.RECORDED}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={customerIds || ALL_CUSTOMERS}
          reportScope={reportScope}
          organizationId={organizationId}
          organizationRegionId={selectedOrganizationRegionId}
        />
        <ReportCard
          reportType={reportTypes.SENT}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={customerIds || ALL_CUSTOMERS}
          reportScope={reportScope}
          organizationId={organizationId}
          organizationRegionId={selectedOrganizationRegionId}
        />
        <ReportCard
          reportType={reportTypes.VIEWS}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={customerIds || ALL_CUSTOMERS}
          reportScope={reportScope}
          organizationId={organizationId}
          organizationRegionId={selectedOrganizationRegionId}
        />
        <ReportCard
          reportType={reportTypes.CTA}
          from={startDate}
          to={endDate}
          dateRange={dateRange}
          users={ALL_USERS}
          customer={customerIds || ALL_CUSTOMERS}
          reportScope={reportScope}
          organizationId={organizationId}
          organizationRegionId={selectedOrganizationRegionId}
        />
      </Gap>
      <FilterContainer>
        <SearchContainer>
          <Search
            width='300px'
            placeholder='Search companies...'
            onSearch={onSearch}
          />
        </SearchContainer>

        {showResellerRegion && (
          <Dropdown
            value={regionOptions.find(
              (r: Option) => r.value === selectedRegion
            )}
            options={regionOptions}
            onChange={(opt: any) => {
              setSelectedRegion(opt?.value || '0');
            }}
            width={200}
            placeholder='No Region'
            isClearable={true}
          />
        )}
        {showOrganizationRegionDropdown && (
          <Dropdown
            value={organizationRegionOptions.find(
              (r: Option) => r.value === selectedOrganizationRegionId
            )}
            options={organizationRegionOptions}
            onChange={(opt: { value: string; label: string }) => {
              setSelectedOrganizationRegionId(opt?.value.toString() || '0');
            }}
            width={200}
            placeholder='Select Region'
            isClearable={true}
          />
        )}
        <Button
          text='Customize'
          icon={<IoMdOptions size={20} />}
          onClick={() => setShowCustomizeTableFieldsModal(true)}
          style={{ marginLeft: 'auto' }}
        />
      </FilterContainer>

      <div style={{ marginTop: '32px' }}>
        {isLoading && <LoadingIndicator isLoading={isLoading} height='561px' />}
        {!isLoading && (
          <>
            <TableContextProvider
              total={count}
              onChange={onPaginationChange}
              initPage={page}
              initSize={size}
            >
              <div style={{ overflowX: 'auto' }}>
                <Table
                  compact={true}
                  headers={[
                    <TableCell
                      style={{ paddingLeft: 0 }}
                      clicked={true}
                      onClick={() =>
                        handleSortClick(
                          fixedTableFields.business.orderKey,
                          fixedTableFields.business.defaultOrder
                        )
                      }
                    >
                      {sortField === fixedTableFields.business.orderKey && (
                        <div>
                          {sortOrder === order.ASC && (
                            <MdArrowUpward size={16} />
                          )}
                          {sortOrder === order.DESC && (
                            <MdArrowDownward size={16} />
                          )}
                        </div>
                      )}
                      <div style={{ marginLeft: '4px' }}>
                        {fixedTableFields.business.label}
                      </div>
                    </TableCell>,
                    showResellerRegion && (
                      <TableCell clicked={false}>
                        {fixedTableFields.region.label}
                      </TableCell>
                    ),
                    ...filteredTableFields.map(item => {
                      return (
                        <TableCell clicked={false}>
                          {sortField === item.value && (
                            <div>
                              {sortOrder === order.ASC && (
                                <MdArrowUpward size={16} />
                              )}
                              {sortOrder === order.DESC && (
                                <MdArrowDownward size={16} />
                              )}
                            </div>
                          )}
                          <div style={{ marginLeft: '4px' }}>{item.label}</div>
                        </TableCell>
                      );
                    }),
                    <TableCell clicked={false}>
                      {fixedTableFields.details.label}
                    </TableCell>,
                  ]}
                  hoverable={false}
                  rows={companies.map((customer: any, index: number) => ({
                    key: index,
                    columns: [
                      <TableCell style={{ paddingLeft: 0 }} clicked={true}>
                        <div
                          onClick={() =>
                            handleCustomerCellClick(customer.customerId)
                          }
                          style={{ flexGrow: 1 }}
                        >
                          {customer[fixedTableFields.business.value] || '-'}
                        </div>
                      </TableCell>,
                      showResellerRegion && (
                        <TableCell clicked={true}>
                          <div
                            onClick={() =>
                              handleCustomerCellClick(customer.customerId)
                            }
                            style={{ flexGrow: 1 }}
                          >
                            {customer[fixedTableFields.region.value] || '-'}
                          </div>
                        </TableCell>
                      ),
                      ...filteredTableFields.map(item => {
                        if (item.formatter) {
                          return (
                            <TableCell
                              clicked={true}
                              onClick={() =>
                                handleCustomerCellClick(customer.customerId)
                              }
                            >
                              {item.formatter(customer[item.value] || 0)}
                            </TableCell>
                          );
                        }
                        if (item.type === 'number') {
                          return (
                            <TableCell
                              clicked={true}
                              onClick={() =>
                                handleCustomerCellClick(customer.customerId)
                              }
                            >
                              {addThousandCommaSeparator(
                                customer[item.value] || 0
                              )}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            clicked={true}
                            onClick={() =>
                              handleCustomerCellClick(customer.customerId)
                            }
                          >
                            {customer[item.value] || '-'}
                          </TableCell>
                        );
                      }),
                      <TableCell
                        clicked={true}
                        style={{ textAlign: 'right' }}
                        onClick={() =>
                          handleCustomerCellClick(customer.customerId)
                        }
                      >
                        <Button
                          variant='transparent'
                          title='View details'
                          icon={<FaChevronRight color='#9297a2' size={14} />}
                          style={{ marginLeft: 'auto' }}
                        />
                      </TableCell>,
                    ],
                  }))}
                />
              </div>
              <TableFooter>
                <TablePaginationNew />
                <TablePaginationSizeNew globalName='companies_list' />
              </TableFooter>
            </TableContextProvider>
            {!companies.length && !isLoading && (
              <div style={{ textAlign: 'center' }}>No data to show.</div>
            )}
          </>
        )}
      </div>

      {showDownloadReportModal && (
        <GenerateReportModal
          handleModalClose={() => {
            setShowDownloadReportModal(false);
          }}
          onSent={() => {
            setShowDownloadReportModal(false);
            onEmailsSent();
          }}
          title='Download Report'
          startDate={startDate}
          endDate={endDate}
          range={dateRange}
          reportUrl='/metrics/companies'
          showRecipientsSelector={false}
          regionId={Number(selectedRegion) || 0}
          organizationRegionId={Number(selectedOrganizationRegionId) || 0}
          reportScope={reportScope}
          organizationId={organizationId}
        />
      )}

      {showSendReportModal && (
        <GenerateReportModal
          handleModalClose={() => {
            setShowSendReportModal(false);
          }}
          onSent={() => {
            setShowSendReportModal(false);
            onEmailsSent();
          }}
          title='Send Report'
          startDate={startDate}
          endDate={endDate}
          range={dateRange}
          reportUrl='/metrics/companies'
          showRecipientsSelector={true}
          regionId={Number(selectedRegion) || 0}
          organizationRegionId={Number(selectedOrganizationRegionId) || 0}
          reportScope={reportScope}
          organizationId={organizationId}
          reports={customReportTypes}
        />
      )}

      {showCustomizeTableFieldsModal && (
        <ModalCustomTableFields
          title='Customize Overviews list'
          onClose={() => setShowCustomizeTableFieldsModal(false)}
          allFields={allReports}
          initialFields={reports}
          actionButtons={[
            {
              onClick: () => setShowCustomizeTableFieldsModal(false),
              text: 'Back',
              variant: 'secondary',
            },
            {
              onClick: onSaveCustomTableFields,
              text: 'Update',
            },
          ]}
        />
      )}
    </AllTeamsWrapper>
  );
};
