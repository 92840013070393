import { Dropdown, NewModal } from 'lib/components';
import React, { useCallback, useEffect, useState } from 'react';

import { Gap } from 'lib/components/styles/layout';
import { Formik, Form, FormikValues, useFormikContext } from 'formik';
import {
  Customer,
  useFreeCustomersQuery,
} from 'lib/api/multiLocations/useFreeCustomersQuery';
import debounce from 'lodash/debounce';
import { CustomLabel } from '../components/customers/CustomDropdownLabel';
import { useAddChildCustomerMutation } from 'lib/api/multiLocations/useAddChildCustomerMutation';
import { useParams } from 'react-router';
import { ParentCustomer } from 'lib/api/multiLocations/multiLocationQuery';
import { Button } from 'react-covideo-common';

interface IProps {
  closeModalHandler: () => void;
}

export const AddChildCustomerModal = ({ closeModalHandler }: IProps) => {
  const { values } = useFormikContext<ParentCustomer>();

  const { customerId: parentCustomerId } = useParams() as {
    customerId: string;
  };

  const [search, setSearchText] = useState('');

  const { mutateAsync, isLoading: isCreatingChildCustomer } =
    useAddChildCustomerMutation(closeModalHandler);

  const { data, isLoading, refetch } = useFreeCustomersQuery({
    search,
  });

  const debouncedInputChange = useCallback(
    debounce(inputValue => {
      setSearchText(inputValue);
    }, 300),
    []
  );

  const onInputChangeHandler = (newInputValue: string) => {
    debouncedInputChange(newInputValue);
  };

  useEffect(() => {
    refetch();
  }, [search]);

  const onSubmitHandler = (values: FormikValues) => {
    mutateAsync({
      customerId: values.customerId,
      parentCustomerId: Number(parentCustomerId),
    });
  };

  const selectedCustomers = values?.childCustomers?.map(
    customer => customer.customerId
  );

  const optionsValues =
    data?.customers?.map((customer: Customer) => ({
      value: customer.customerId,
      label: customer.business,
      business: customer.business,
      resellerName: customer.resellerName,
      customerId: customer.customerId,
    })) || [];

  return (
    <NewModal
      headerText='Connect child customer'
      closeModal={closeModalHandler}
      style={{ content: { minWidth: 592, width: 592 } }}
    >
      <Formik initialValues={{ customerId: null }} onSubmit={onSubmitHandler}>
        {({ handleSubmit, setFieldValue, values }) => {
          const initialDropdownValue = optionsValues.find(
            option => option.value == values.customerId
          );
          return (
            <Form onSubmit={handleSubmit}>
              <Dropdown
                options={optionsValues.filter(
                  option => !selectedCustomers.includes(option.value)
                )}
                value={initialDropdownValue}
                name='customerId'
                onChange={value =>
                  setFieldValue('customerId', value?.value || null)
                }
                placeholder={isLoading ? '' : 'Select child customer'}
                isClearable={true}
                extendStyles={{
                  container: {
                    width: '100%',
                    margin: '10px 0 15px 0',
                  },
                }}
                isSearchable={true}
                creatable={false}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={true}
                menuPosition='absolute'
                menuPlacement='bottom'
                filterOption={null}
                isLoading={isLoading}
                disabled={isCreatingChildCustomer}
                onInputChange={onInputChangeHandler}
                formatOptionLabel={CustomLabel}
              />
              <Gap
                m='32px 0 0 0'
                alignItems='center'
                justifyContent='flex-end'
                gap='12px'
              >
                <Button
                  text={isCreatingChildCustomer ? 'Connecting...' : 'Connect'}
                  disabled={
                    values.customerId === null || isCreatingChildCustomer
                  }
                  type='submit'
                />
              </Gap>
            </Form>
          );
        }}
      </Formik>
    </NewModal>
  );
};
