import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const Content = styled.div`
  margin-top: 64px;
  padding: 32px 112px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  flex-direction: column;
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  align-items: center;
  position: absolute;
  justify-content: space-between;
  order: 4;
  ${theme.mediaQueryMinWidth.md} {
    width: 100%;
    order: 0;
    flex-direction: row;
    justify-content: end;
    height: 40px;
  }
  a {
    justify-content: center;
    margin: 0;
    height: 40px;
    &:nth-of-type(n + 2) {
      margin: 20px 0 0 0;
    }
    ${theme.mediaQueryMinWidth.xs} {
      width: 30vw;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 12px;
      }
    }
    ${theme.mediaQueryMinWidth.md} {
      width: 30%;
      max-width: 200px;
      &:nth-of-type(n + 2) {
        margin: 0 0 0 24px;
      }
    }
    div {
      width: auto;
      &:nth-of-type(1) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 18px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 24px;
        }
      }
      &:nth-of-type(2) {
        ${theme.mediaQueryMinWidth.xs} {
          font-size: 14px;
        }
        ${theme.mediaQueryMinWidth.md} {
          font-size: 16px;
        }
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  ${theme.mediaQueryMinWidth.md} {
    margin-top: 0;
  }
  width: auto;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0px;
  margin-top: 0;
`;

export const GuidesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  position: relative;
`;

export const VideoWrapper = styled.div`
  margin-bottom: 24px;
  max-width: 488px;
  max-height: 320px;
`;

export const GuideForm = styled.label`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  border-bottom: 1px solid ${theme.palette.neutral20};
  padding-bottom: 56px;
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const InputWrapper = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div<{ marginBottom?: string }>`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 24px;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0px')};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${theme.palette.black_1_100};
`;

export const TextInput = styled.input`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #e1e2e5;
  padding: 8px 12px;
  &:focus {
    outline: none;
    border: solid 1px #80bdff;
  }
`;

export const TextArea = styled.textarea<{
  textLength: number;
  maxRowLength: number;
}>`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #272a32;
  border-radius: 4px;
  height: ${props =>
    props.textLength > props.maxRowLength ? '160px' : '40px'};
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #e1e2e5;
  padding: 8px 12px;
  resize: none;
  &:focus {
    outline: none;
    border: solid 1px #80bdff;
    height: 160px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const VideoTitle = styled.div`
  color: ${theme.palette.neutral100};
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  height: 40px;
`;

export const ErrorMessageText = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e84c3d;
  padding-top: 4px;
`;

export const DetailsWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  color: ${theme.palette.red100};
  font-size: ${theme.fontSizes.xxs};
  font-weight: ${theme.fontNormal600};
  margin-top: 2px;
`;
