import { IStatisticsParams, IStatisticsResponse } from './types';
import dayjs from 'dayjs';
import { DATE_FORMAT, initialStatistics } from './const';
import { useQuery } from 'react-query';
import { repairOrderKeys } from './repairOrderKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const getRepairOrderStatistics = async (
  data: IStatisticsParams
): Promise<IStatisticsResponse> => {
  const params = {
    ...data,
    startDate: dayjs(data.startDate).format(DATE_FORMAT),
    endDate: dayjs(data.endDate).add(1, 'day').format(DATE_FORMAT),
  };

  return (
    await EXPRESS_API.get(`repair-orders/statistics`, {
      params,
    })
  ).data as IStatisticsResponse;
};

export const useGetRepairOrderStatistics = (data: IStatisticsParams) => {
  const {
    data: result,
    isLoading,
    refetch,
  } = useQuery(repairOrderKeys.repairOrderStatistics(), () =>
    getRepairOrderStatistics(data)
  );
  if (result) {
    return { data: result, isLoading, refetch };
  }
  return { data: initialStatistics, isLoading, refetch };
};
