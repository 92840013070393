import { RepairOrderItem } from './types';
import { useQuery } from 'react-query';
import { repairOrderKeys } from './repairOrderKeys';
import { initialRepairOrder } from './const';
import { EXPRESS_API } from 'configs/api/expressApi';

const getSingleRepairOrder = async (
  repairOrderId: number | string
): Promise<RepairOrderItem> => {
  return (await EXPRESS_API.get(`repair-orders/${repairOrderId}`))
    .data as RepairOrderItem;
};

export const useGetSingleRepairOrder = (repairOrderId: string | number) => {
  const { data, isLoading, refetch } = useQuery(
    repairOrderKeys.repairOrder(repairOrderId),
    () => getSingleRepairOrder(repairOrderId),
    {
      refetchOnMount: true,
    }
  );

  if (data) {
    return { data, isLoading, refetch };
  }

  return { data: initialRepairOrder, isLoading, refetch };
};
