import { useQuery } from 'react-query';
import { teleprompterKeys } from './queryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { TeleprompterResponse } from './types';

export const getScript = async (
  scriptId: string
): Promise<TeleprompterResponse> => {
  const response = await EXPRESS_API.get(`/teleprompter/${scriptId}`);
  return response.data;
};

export const useQueryScript = (scriptId: string, enabled: boolean) => {
  return useQuery(
    teleprompterKeys.script(scriptId || ''),
    () => getScript(scriptId),
    {
      enabled: enabled,
    }
  );
};
