import React, { useEffect, useState } from 'react';
import { ContentWrapper } from 'app/pages/library/videos/videoList/components/SendAndShare/send/SendTab';
import {
  LoadingIndicator,
  ModalVideoListVideo,
  TableContextProvider,
  Search,
  TablePaginationNew,
} from 'lib/components';
import { Button } from 'react-covideo-common';
import { BsPlayFill } from 'react-icons/bs';
import { Gap } from '../../styles/layout';
import { ParagraphSmallBold } from '../../styles/typography';
import { HoverPopup } from 'lib/components';
import styled, { css } from 'styled-components/macro';
import { theme } from 'lib/style';
import { ILesaItem, useLesaTvVideosQuery } from 'lib/api/lesa/useLesaTvQuery';
import { useLesaVideosCopyMutation } from 'lib/api/lesa/useLesaVideosMutations';
import { getDisplayName } from 'lib/utils/functions';
import imagePlaceholder from 'assets/images/single/imagePlaceholder.webp';
import { VideoItemThumbnail } from 'app/pages/library/videos/components';
import { debounce } from 'lodash';

const Content = styled(ContentWrapper)`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 956px;
  padding-bottom: 0;
`;

const VideosList = styled.div`
  width: 100%;
  min-height: 530px;
`;

const Video = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid rgb(223, 227, 230);
`;

const BaseModalText = styled.div`
  height: 24px;
  margin-left: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left !important;
  color: ${theme.palette.title};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

const BaseHeaderText = styled(BaseModalText)`
  font-size: 14px;
  color: ${theme.palette.blackRgb60};
`;

// vehicle year
const VehicleYearWidth = css`
  max-width: 180px;
  min-width: 40px;
`;
const VehicleYear = styled(BaseModalText)`
  ${VehicleYearWidth}
`;

const VehicleYearHeader = styled(BaseHeaderText)`
  ${VehicleYearWidth}
`;
// vehicle make
const VehicleMakeWidth = css`
  max-width: 180px;
  min-width: 75px;
`;
const VehicleMake = styled(BaseModalText)`
  ${VehicleMakeWidth}
`;
const VehicleMakeHeader = styled(BaseHeaderText)`
  ${VehicleMakeWidth}
`;

// vehicle stock
const VehicleStockNumberWidth = css`
  min-width: 120px;
  max-width: 120px;
`;
const VehicleStockNumber = styled(BaseModalText)`
  ${VehicleStockNumberWidth}
`;
const VehicleStockNumberHeader = styled(BaseHeaderText)`
  ${VehicleStockNumberWidth}
`;

const PreviewModelHeader = styled(BaseHeaderText)`
  width: 105px;
  margin-left: 0;
`;

// vehicle model
const VehicleModelWidth = css`
  width: inherit;
`;
const VehicleModel = styled(BaseModalText)`
  ${VehicleModelWidth}
`;

const VehicleModelHeader = styled(BaseHeaderText)`
  ${VehicleModelWidth}
`;

const EmptySearch = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  min-height: 530px;
`;

const Footer = styled(ContentWrapper)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  padding-bottom: 0;
`;

type Props = {
  showAddToCovideo?: boolean;
  handleModalClose?: () => void;
  onCopy?: () => void;
  onSelectVideo?: (lesaVideo: ILesaItem) => void;
};

const DEFAULT_PAGE = 0;
const DEFAULT_SIZE = 10;

export const LesaTable = ({
  handleModalClose,
  onCopy,
  showAddToCovideo = true,
  onSelectVideo,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [videoSource, setVideoSource] = useState('');
  const [page, setPage] = useState(DEFAULT_PAGE);

  const resetPage = () => {
    setPage(0);
  };

  const onSuccesCallback = () => {
    onCopy?.();
    handleModalClose?.();
  };

  //queries
  const {
    data,
    refetch,
    isLoading: isLoadingLesaTv,
  } = useLesaTvVideosQuery(
    {
      start: page * DEFAULT_SIZE,
      limit: DEFAULT_SIZE,
      page,
      search: searchTerm,
    },
    handleModalClose
  );

  // mutations
  const { mutateAsync: copyLesaVideosMutation, isLoading: isCopyingVideo } =
    useLesaVideosCopyMutation({
      onSuccesCallback,
    });

  const isLoading = isLoadingLesaTv || isCopyingVideo;
  const isNotSearchable = searchTerm.length >= 1 && searchTerm.length < 3;

  const onSearchChangeHandler = async (search: string = '') => {
    setSearchTerm(search);
    resetPage();
  };

  const onPaginationChangeHandler = debounce(
    async ({ page: newPage }: { page: number }) => {
      if (page !== newPage) {
        setPage(newPage);
      }
    },
    300
  );

  const copyLesaMutationHandler = (video: ILesaItem) => {
    if (!video.video) {
      return;
    }

    const title = getDisplayName([
      video?.year.toString(),
      video.make,
      video.model,
      video.stockNumber,
    ]);

    copyLesaVideosMutation({
      url: video.video?.url,
      fileName: title,
    });
  };

  useEffect(() => {
    if (isNotSearchable) {
      return;
    }
    refetch();
  }, [searchTerm, page]);

  const showSelectVideo = !!onSelectVideo;

  return (
    <Content>
      <Gap m='0 0 20px auto'>
        <Search
          placeholder='Search videos...'
          onSearch={onSearchChangeHandler}
          width='300px'
          disabled={isCopyingVideo}
        />
      </Gap>
      {isLoading ? (
        <div>
          <LoadingIndicator isLoading={isLoading} />
        </div>
      ) : (
        <>
          {isNotSearchable ? (
            <EmptySearch>
              <ParagraphSmallBold color={theme.palette.blue60}>
                Please enter at least three characters to search
              </ParagraphSmallBold>
            </EmptySearch>
          ) : (
            <>
              {data?.videos?.length === 0 ? (
                <>
                  {searchTerm !== '' ? (
                    <EmptySearch>
                      <ParagraphSmallBold color={theme.palette.blue60}>
                        There are no videos with “{searchTerm}” in the title
                      </ParagraphSmallBold>
                      <ParagraphSmallBold color={theme.palette.blue60}>
                        Try using a different search term
                      </ParagraphSmallBold>
                    </EmptySearch>
                  ) : (
                    <EmptySearch>
                      <ParagraphSmallBold color={theme.palette.blue60}>
                        Unfortunately, there aren't any videos available at the
                        moment.
                      </ParagraphSmallBold>
                    </EmptySearch>
                  )}
                </>
              ) : (
                <>
                  <VideosList>
                    <Video>
                      <PreviewModelHeader>Preview</PreviewModelHeader>
                      <VehicleStockNumberHeader>
                        Stock #
                      </VehicleStockNumberHeader>
                      <VehicleYearHeader>Year</VehicleYearHeader>
                      <VehicleMakeHeader>Make</VehicleMakeHeader>
                      <VehicleModelHeader>Model</VehicleModelHeader>
                    </Video>
                    {data?.videos?.map(video => (
                      <Video key={video.postingId}>
                        <VideoItemThumbnail
                          handleClick={() => {
                            if (!!video.video?.url) {
                              setVideoSource(video.video?.url);
                            }
                          }}
                          thumbnail={video.thumbnailUrl || imagePlaceholder}
                          videoId={video?.video?.uuid || ''}
                          processing={0}
                          thumbnailHeight={'80%'}
                          thumbnailWidth={'15%'}
                        />
                        <VehicleStockNumber>
                          {video.stockNumber}
                        </VehicleStockNumber>
                        <VehicleYear>{video.year}</VehicleYear>
                        <VehicleMake>{video.make}</VehicleMake>
                        <VehicleModel>{video.model}</VehicleModel>
                        <Gap flexWrap='noWrap' gap='10px'>
                          {video.video === null ? (
                            <>
                              <HoverPopup
                                width='170px'
                                position='above'
                                padding='8px 12px'
                                hoverable={
                                  <Button
                                    disabled={true}
                                    variant='secondary'
                                    icon={<BsPlayFill />}
                                    onClick={() => null}
                                  />
                                }
                                popup={<>Preview not available!</>}
                              />
                              <HoverPopup
                                width='155px'
                                position='above'
                                padding='8px 12px'
                                hoverable={
                                  <Button
                                    disabled={true}
                                    variant='secondary'
                                    text='Add to Covideo'
                                    onClick={() => null}
                                  />
                                }
                                popup={<>Video not available!</>}
                              />
                            </>
                          ) : (
                            <>
                              {showAddToCovideo && (
                                <Button
                                  variant='secondary'
                                  text='Add to Covideo'
                                  onClick={() => copyLesaMutationHandler(video)}
                                />
                              )}
                              {showSelectVideo && (
                                <Button
                                  variant='secondary'
                                  text='Select Video'
                                  onClick={() => onSelectVideo(video)}
                                />
                              )}
                            </>
                          )}
                        </Gap>
                      </Video>
                    ))}
                  </VideosList>
                  <Footer>
                    <TableContextProvider
                      total={data?.count || 0}
                      initSize={DEFAULT_SIZE}
                      onChange={onPaginationChangeHandler}
                      initPage={page}
                    >
                      <TablePaginationNew
                        disabled={isLoading || isNotSearchable}
                      />
                    </TableContextProvider>
                  </Footer>
                </>
              )}

              {!!videoSource && (
                <ModalVideoListVideo
                  videoId={''}
                  source={videoSource}
                  handleModalClose={() => {
                    setVideoSource('');
                  }}
                  hideContext={true}
                />
              )}
            </>
          )}
        </>
      )}
    </Content>
  );
};
