import { EXPRESS_API } from 'configs/api/expressApi';
import { useQuery } from 'react-query';
import { videoKeys } from './videosKeys';

export const getVideosCount = async (): Promise<{ count: number }> => {
  const response = await EXPRESS_API.get('/videos/count/all');

  return response.data;
};

export const useVideosCountQuery = () => {
  return useQuery(videoKeys.video_count(), getVideosCount, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
};
