import { useQuery } from 'react-query';
import { useToastError } from 'lib/hooks/useToastError';
import { metricsKeys } from './metricsKeys';
import { TopVideosCtasParams } from './queryTopCta';
import { EXPRESS_API } from 'configs/api/expressApi';

export type MostViewedVideosResponseData = {
  videoId: number;
  title: string;
  cnt: string;
};

const getMostViewedVideos = async (
  params: TopVideosCtasParams
): Promise<MostViewedVideosResponseData[]> => {
  return (
    await EXPRESS_API.get(`metrics/top/videos`, {
      params,
    })
  ).data;
};

export const useGetMostViewedVideosQuery = ({
  params,
  enabled,
}: {
  params: TopVideosCtasParams;
  enabled: boolean;
}) => {
  const { showError } = useToastError();
  return useQuery(
    metricsKeys.topVideos(params),
    () => getMostViewedVideos(params),
    {
      onError: showError,
      refetchOnMount: true,
      enabled,
    }
  );
};
