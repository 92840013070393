import { ParagraphSmall } from 'lib/components/styles/typography';
import { useAuth } from 'lib/context';
import { theme } from 'lib/style';
import React from 'react';
import { MdInfo } from 'react-icons/md';
import styled from 'styled-components/macro';

const InfoMsgWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background: rgba(182, 224, 236, 0.2);
  border-radius: 5px;
  border: 1px solid rgba(0, 27, 83, 0.05);
  padding: 12px;
`;

export const SendInfoMessage = () => {
  const { userData } = useAuth();
  const currentUserEmail = userData?.email;

  return (
    <InfoMsgWrapper>
      <MdInfo color={theme.palette.blue40} size={28} />
      <ParagraphSmall color='rgba(0, 27, 83, 0.6)'>
        The report will be emailed to <b>{currentUserEmail}</b> as part of the
        recurring report setup.
      </ParagraphSmall>
    </InfoMsgWrapper>
  );
};
