import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useOnClickOutside } from 'lib/utils';
import { Modal, Table, TableContextProvider } from 'lib/components';
import { theme } from 'lib/style';
import Select from 'react-select';
import { integrations } from '../../../app/pages/admin/integrations/Main';
import { Button } from 'react-covideo-common';
import { IoMdClose } from 'react-icons/io';

const Header = styled.div`
  display: flex;
`;

const HeaderTitle = styled.div`
  ${theme.fontBold700}
  font-size: ${theme.fontSizes.lg};
  color: ${theme.palette.themeDark};
  font-family: Work Sans;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
`;

const CloseButtonWrap = styled.div`
  margin-left: auto;
  color: ${theme.palette.white};
  height: 24px;
  width: 24px;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Work Sans', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272a32;
`;

const Footer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
`;

const TableCell = styled.div<{ width?: number }>`
  width: ${props => (props.width ? props.width + 'px' : 'auto')};
  padding-left: 24px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: flex;

  .arrow--right {
    padding: 2px 0 0 30px;
  }
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean) => void;
};

const tableFields = [
  {
    label: 'CRM Integration',
    value: 'integration',
  },
  {
    label: 'ID',
    value: 'id',
  },
  {
    label: 'Company Name',
    value: 'company',
  },
  {
    label: '',
    value: 'action',
  },
];

const connections = [
  {
    id: 'MED37RBE31',
    integration: 'VinSolutions',
    company: 'Wyman Inc',
  },
  {
    id: 'FN382DPA39',
    integration: 'VinSolutions',
    company: 'Lind-Nader',
  },
];

const resellers = [
  {
    label: 'Reseller 1',
    value: 1,
  },
  {
    label: 'Reseller 2',
    value: 2,
  },
  {
    label: 'Reseller 3',
    value: 3,
  },
];

export const PendingConnectionsModal = (props: Props) => {
  const [resellerSelected, setResellerSelected] = useState(0);

  const { handleModalClose } = props;

  const { ref } = useOnClickOutside(handleModalClose);

  const escPress = (key: string) => {
    if (key === 'Escape') {
      handleModalClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', e => escPress(e.key));

    return () =>
      document.removeEventListener('keyup', e => escPress(e.key), true);
  }, []);

  const rows = connections?.map((department: any, index: number) => ({
    key: index,
    columns: [
      ...tableFields.map(item => {
        return (
          <TableCell>
            {department[item.value]}
            {item.value === 'action' && (
              <Select
                //components={{ Option: customSingleValue }}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    height: '40px',
                    width: '215px',
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                  menuPortal: (base: any) => ({
                    ...base,
                    zIndex: 1000,
                  }),
                  option: (styles, { isSelected }) => ({
                    ...styles,
                    borderBottom: '1px solid #EEEFF2',
                    fontFamily: 'Work Sans',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '15px',
                    lineHeight: '24px',
                    color: isSelected ? '#FF8B22' : '#272A32',
                    backgroundColor: isSelected ? '#FFFFF' : '',
                  }),
                }}
                options={integrations}
                menuPosition='fixed'
                menuPlacement={'bottom'}
                getOptionValue={(option: any) => option.value}
                getOptionLabel={(option: any) => option.label}
                placeholder='None'
              />
            )}
          </TableCell>
        );
      }),
    ],
    onClick: () => {},
  }));

  return (
    <Modal style={{ position: 'fixed' }} widthOnDesktop={'63%'}>
      <div ref={ref} style={{ padding: '32px' }}>
        <Header>
          <HeaderTitle>Pending Connections</HeaderTitle>
          <CloseButtonWrap title={'Close'}>
            <IoMdClose
              size={24}
              onClick={() => handleModalClose()}
              color={theme.palette.label}
            />
          </CloseButtonWrap>
        </Header>
        <Content>
          <TextWrapper>
            2 dealerships are not connected. Select not connected dealership
            from the left side and assign it to a Customer on the right side.
          </TextWrapper>
          <Select
            styles={{
              control: (base: any) => ({
                ...base,
                height: '40px',
                width: '215px',
                marginTop: '3%',
                marginBottom: '3%',
              }),
              indicatorSeparator: () => ({ display: 'none' }),
              menuPortal: (base: any) => ({
                ...base,
                zIndex: 1000,
              }),
              option: (base: any) => ({
                ...base,
                borderBottom: '1px solid #EEEFF2',
              }),
            }}
            options={resellers}
            menuPosition='fixed'
            menuPlacement={'bottom'}
            getOptionValue={(option: any) => option.value}
            getOptionLabel={(option: any) => option.label}
            value={resellers.find((o: any) => {
              return o.value == resellerSelected;
            })}
            onChange={(option: any) => {
              setResellerSelected(option.value);
            }}
            placeholder='All Resellers'
          />
          <TableContextProvider
            total={connections.length}
            initPage={0}
            initSize={10}
          >
            <Table
              compact={true}
              relative
              headers={[
                ...tableFields.map(item => {
                  return <TableCell>{item.label}</TableCell>;
                }),
              ]}
              hoverable={false}
              rows={rows}
            />
          </TableContextProvider>
        </Content>
        <Footer>
          <Button
            text='Connect & Save'
            variant='primary'
            onClick={async () => {
              handleModalClose();
            }}
          />
        </Footer>
      </div>
    </Modal>
  );
};
