import { useQuery } from 'react-query';
import { videoKeys } from './videosKeys';
import { EXPRESS_API } from 'configs/api/expressApi';
import { keyBy } from 'lodash';
export type GetVideoReactionsParams = {
  videoId: string;
};

export const getVideoReactions = async ({
  videoId,
}: GetVideoReactionsParams) => {
  const response = await EXPRESS_API.get(`/videos/${videoId}/reactions`);
  const data = keyBy(response.data, 'reactionId');
  return data;
};

export const useVideoReactionsQuery = (videoId: string) => {
  return useQuery(videoKeys.reactions(videoId), () =>
    getVideoReactions({
      videoId,
    })
  );
};
