import React, { useState } from 'react';
import { LoadingIndicator } from 'lib/components';
import { SuperAdminCustomer, CustomerListFilter, useAuth } from 'lib/context';
import { SelectedCustomers } from '../components/SelectedCustomers';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { SearchAndFilter } from '../components/SearchAndFilter';
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  PaginationConstants,
} from 'lib/const/PaginationConstants';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { debounce } from 'lodash';
import {
  useCustomersQuery,
  useCustomersStatisticsQuery,
} from 'lib/api/superAdminApi';
import { AllResellerOption } from 'lib/const/SuperAdminOptions';
import { Customers } from '../components/Customers';
import { CustomersHeader } from '../components/CustomersHeader';
import {
  Container,
  MainContainer,
  MainWrapper,
  NoList,
} from '../../index.styled';
import { checkParamForString } from 'lib/utils/functions';
import {
  DEFAULT_RESELLER_ID,
  SCREEN_VALUES,
} from 'lib/const/SuperAdminConstants';
import { ManageCustomerRoles, SuperAdminRole } from 'lib/const/SuperAdminRole';
import { ImportCustomerModal } from 'lib/components/modal/importModal/ImportCustomerModal';

export const List = () => {
  const [showImportModal, setShowImportModal] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const { userData } = useAuth();
  const showResellerFilter =
    userData.superAdminRole === SuperAdminRole.COVIDEO_SUPER_ADMIN ||
    userData.superAdminRole === SuperAdminRole.COVIDEO_ADMIN;

  const showNewCustomerButton =
    ManageCustomerRoles.includes(userData.superAdminRole) &&
    userData.superAdminRole !== SuperAdminRole.ADMIN;
  const queryParams = queryString.parse(location.search);

  const [selectedCustomersData, setSelectedCustomersData] = useState<
    SuperAdminCustomer[]
  >([]);

  // All filters are fetched from the URL
  const filter: CustomerListFilter = {
    status:
      checkParamForString(queryParams.status) &&
      parseInt(queryParams.status!.toString(), 10) in VerificationStatus
        ? parseInt(queryParams.status!.toString(), 10)
        : VerificationStatus.ACTIVE,
    resellerId: checkParamForString(queryParams.resellerId)
      ? parseInt(queryParams.resellerId!.toString(), 10)
      : AllResellerOption.value,
    sortKey: checkParamForString(queryParams.sortKey)
      ? queryParams.sortKey!.toString()
      : undefined,
    order:
      checkParamForString(queryParams.order) &&
      Object.values(PaginationConstants).includes(
        queryParams.order!.toString() as PaginationConstants
      )
        ? (queryParams.order as PaginationConstants)
        : PaginationConstants.ASCENDING,
    page: checkParamForString(queryParams.page)
      ? parseInt(queryParams.page!.toString(), 10)
      : DEFAULT_PAGE,
    size: checkParamForString(queryParams.size)
      ? parseInt(queryParams.size!.toString(), 10)
      : DEFAULT_SIZE,
    searchQuery: checkParamForString(queryParams.searchQuery)
      ? queryParams.searchQuery!.toString()
      : undefined,
  };

  const {
    statistics,
    refetch: refreshCustomerStats,
    isLoading: statsLoading,
  } = useCustomersStatisticsQuery({
    params: {
      status: VerificationStatus.ALL,
      resellerId: filter.resellerId >= 0 ? filter.resellerId : undefined,
    },
    filter,
  });

  const {
    count: customerCount,
    customerArray,
    refetch: refreshCustomerList,
    isLoading: showLoading,
  } = useCustomersQuery({
    params: {
      start: (filter.page || DEFAULT_PAGE) * (filter.size || DEFAULT_SIZE),
      limit: filter.size || DEFAULT_SIZE,
      search: filter.searchQuery,
      status: filter.status ?? VerificationStatus.ALL,
      sortKey: filter.sortKey,
      order: filter.order,
      resellerId: filter.resellerId >= 0 ? filter.resellerId : undefined,
    },
    filter,
    enabled: true,
    onSuccess: () => setSelectedCustomersData([]),
  });

  const onPaginationChange = debounce(
    async ({
      page: newPage,
      size: newSize,
    }: {
      page: number;
      size: number;
    }) => {
      queryParams.page = `${newPage}`;
      queryParams.size = `${newSize}`;
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(queryParams),
      });
    },
    300
  );

  const addStatusToUrl = (status: number) => {
    // Reset to first page in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.status = `${status}`;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const addResellerToUrl = (resellerId: number) => {
    // Reset to first page in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.resellerId = `${resellerId}`;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const addSortingToUrl = (sortKey: string, order: string) => {
    // Reset to first page in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.sortKey = sortKey;
    queryParams.order = order;
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  const onSearch = (query: string) => {
    // Reset to first page if search set in case filtered results don't have multiple pages
    queryParams.page = `${DEFAULT_PAGE}`;
    queryParams.searchQuery = query.trim();
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(queryParams),
    });
  };

  return (
    <MainContainer>
      {!!selectedCustomersData.length && (
        <SelectedCustomers
          customerArray={customerArray}
          selectedCustomers={selectedCustomersData}
          showResellerButton={showResellerFilter}
          setSelectedCustomers={(selectedCustomers: SuperAdminCustomer[]) => {
            setSelectedCustomersData(selectedCustomers);
          }}
          onSuccessfullUpdate={() => {
            refreshCustomerList();
            refreshCustomerStats();
          }}
        />
      )}
      <MainWrapper>
        <Container>
          <CustomersHeader
            statistics={statistics}
            loading={statsLoading}
            showNewCustomerButton={showNewCustomerButton}
            importCustomers={() => setShowImportModal(true)}
            onAddNewCustomer={() => {
              history.push(
                `/admin/customers/create/${SCREEN_VALUES.CUSTOMERS}/${DEFAULT_RESELLER_ID}`
              );
            }}
          />
          <SearchAndFilter
            resellerFilter={filter.resellerId}
            statusFilter={filter.status}
            prevSearch={filter.searchQuery}
            showResellerFilter={showResellerFilter}
            onSelectReseller={addResellerToUrl}
            onSelectStatus={addStatusToUrl}
            onSearch={onSearch}
          />
          {showLoading && (
            <div style={{ width: '100%', height: '300px' }}>
              <LoadingIndicator isLoading={showLoading} height='300px' />
            </div>
          )}
          {!showLoading && !!customerArray.length && (
            <Customers
              customerCount={customerCount}
              customerArray={customerArray}
              selectedCustomersData={selectedCustomersData}
              page={filter.page}
              size={filter.size}
              sortKey={filter.sortKey}
              order={filter.order}
              onPaginationChange={onPaginationChange}
              onSortingUpdate={addSortingToUrl}
              openCustomerDetails={(customer: SuperAdminCustomer) => {
                history.push(
                  `/admin/customers/${customer.customerId}/${SCREEN_VALUES.CUSTOMERS}/${DEFAULT_RESELLER_ID}`
                );
              }}
              onEmailIconClick={(customer: SuperAdminCustomer) => {
                history.push(
                  `/admin/customers/${customer.customerId}/${SCREEN_VALUES.CUSTOMERS}/${DEFAULT_RESELLER_ID}`
                );
              }}
              onCustomerSelect={(
                customer: SuperAdminCustomer,
                checked: boolean
              ) => {
                setSelectedCustomersData(
                  checked
                    ? [...selectedCustomersData, customer]
                    : selectedCustomersData.filter(
                        (e: SuperAdminCustomer) =>
                          e.customerId != customer.customerId
                      )
                );
              }}
            />
          )}
          {!showLoading && !customerArray.length && (
            <NoList>No Customers to show.</NoList>
          )}
          {showImportModal && (
            <ImportCustomerModal
              handleModalClose={shouldRefresh => {
                if (shouldRefresh) {
                  refreshCustomerList();
                }
                setShowImportModal(false);
              }}
              handleSubmit={() => {}}
            />
          )}
        </Container>
      </MainWrapper>
    </MainContainer>
  );
};
