import { errorToast } from 'lib/components/toasts/error';
import { useMutation } from 'react-query';
import { successToast } from 'lib/components/toasts/success';
import { EXPRESS_API } from 'configs/api/expressApi';

const sendInvite = async (meetingId: string) => {
  const response = await EXPRESS_API.post(`/meeting/${meetingId}/send-invite`);
  return response.data;
};

export const useSendInviteMeetingMutation = () => {
  return useMutation((meetingId: string) => sendInvite(meetingId), {
    onError: () => {
      errorToast({
        title:
          'An error occurred sending the invites for live meeting, please try again later!',
      });
    },
    onSuccess: () => {
      successToast({
        title: 'Invite sent successfully.',
      });
    },
  });
};
