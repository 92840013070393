import * as React from 'react';
import queryString from 'query-string';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import {
  MdImageSearch,
  MdLayers,
  MdLock,
  MdOutlineQrCode2,
  MdWeb,
} from 'react-icons/md';

import { DocumentHead, PageTemplate, SidebarNavigation } from 'lib/components';

import LandingPages from './landingPages/Main';
import WebsiteOverlays from './websiteOverlays/Main';
import { Main as CallsToAction } from './callsToAction/Main';
import Thumbnails from './thumbnails/Main';
import { LandingPageBuilderContextProvider } from './landingPageBuilder/context';
import { LandingPageBuilder } from './landingPageBuilder/LandingPageBuilder';
import QrCodes from './qrCodes/Main';
import { useAuth } from 'lib/context';
import {
  productFeature,
  checkIfFeatureIsEnabled,
} from 'lib/utils/productFeature';
import { LandingQuote } from './landingPages/ServicePages';
import { AutomotiveRole, RESELLER_IDS } from 'lib/const';
import LandingQuoteIcon from 'assets/images/landingPage/LandingQuote';
import { NotFoundTemplate } from '../notFound';
import { Container } from 'lib/components/styles/layout';
import { WHITELABEL_NAME } from 'lib/api/whitelabel/getWhiteLabel';
import { IoMdMegaphone } from 'react-icons/io';

export const DesignRoutes = () => {
  const { userData, whitelabel } = useAuth();
  const [isCTASEnabled, setCTASEnabled] = React.useState(true);
  const [isWebsiteOverlayEnabled, setWebsiteOverlayEnabled] =
    React.useState(true);
  const [isLandingPageBuilder, setLandingPageBuilder] = React.useState(true);
  const [isQRCodeEnabled, setIsQRCodeEnabled] = React.useState(true);

  React.useEffect(() => {
    setCTASEnabled(checkIfFeatureIsEnabled(userData, productFeature.CTAS));
    setWebsiteOverlayEnabled(
      checkIfFeatureIsEnabled(userData, productFeature.WEBSITE_OVERLAY)
    );
    setIsQRCodeEnabled(
      checkIfFeatureIsEnabled(userData, productFeature.QR_CODE)
    );
    setLandingPageBuilder(
      checkIfFeatureIsEnabled(userData, productFeature.LANDING_PAGE_BUILDER)
    );
  }, [userData]);

  const sidebarItems = [
    {
      title: 'Landing Pages',
      icon: <MdWeb size={'24px'} />,
      path: '/design/landing-pages',
    },
    {
      isDisabled: !isWebsiteOverlayEnabled,
      title: 'Overlay URLs',
      productFeatureId: productFeature.WEBSITE_OVERLAY,
      icon: <MdLayers size={24} />,
      additionalIcon: <MdLock width={18} color='#001B53' />,
      path: '/design/overlay-urls',
    },
    {
      isDisabled: !isCTASEnabled,
      title: 'Calls-to-Action',
      productFeatureId: productFeature.CTAS,
      icon: <IoMdMegaphone size={24} />,
      additionalIcon: <MdLock width={18} color='#001B53' />,
      path: '/design/calls-to-action',
    },
    {
      title: 'Thumbnails',
      icon: <MdImageSearch size={24} />,
      path: '/design/thumbnails',
    },
    {
      isDisabled: !isQRCodeEnabled,
      title: 'QR Codes',
      productFeatureId: productFeature.QR_CODE,
      icon: <MdOutlineQrCode2 size={24} />,
      additionalIcon: <MdLock width={18} color='#001B53' />,
      path: '/design/qr-codes',
    },
  ];

  const domain = window.location.hostname;
  const isCovideo =
    whitelabel?.name === WHITELABEL_NAME.COVIDEO ||
    domain.includes('localhost');
  const isApp2Vid = whitelabel?.name === WHITELABEL_NAME.APP_2_VID;
  const location = useLocation();
  const queryParams: any = queryString.parse(location.search);

  const showServicePages =
    userData.automotiveRole === AutomotiveRole.SERVICE_MANAGER ||
    userData.automotiveRole === AutomotiveRole.SALES_MANAGER ||
    queryParams.automotive !== undefined;

  if (showServicePages) {
    sidebarItems.splice(1, 0, {
      title: `${userData.isAutomotiveServiceRole ? 'Service' : 'Sales'} Pages`,
      icon: <LandingQuoteIcon width={24} height={19} />,
      path: '/design/service-pages',
    });
  }

  const filteredSidebarItems =
    sidebarItems?.filter(item => {
      // Hide Overlay URLs for Moss Auto (Reseller 447)
      if (
        userData?.resellerId?.toString() === RESELLER_IDS.MOSS_AUTO_GROUP &&
        item.title === 'Overlay URLs'
      ) {
        return false;
      }

      // Hide QR codes for whitelabels
      if (!isCovideo && !isApp2Vid && item.title === 'QR Codes') {
        return false;
      }

      return true;
    }) || [];
  const lastItem = location.pathname.split('/').pop() || '';
  const noLeftPaddingForContainer =
    lastItem.includes('landing-page-builder') ||
    lastItem.includes('landing-page-preview');

  return (
    <>
      <LandingPageBuilderContextProvider>
        <DocumentHead title='Design' />

        <PageTemplate
          main={
            <>
              <SidebarNavigation items={filteredSidebarItems} hasBack={false} />
              <Container noLeftPadding={noLeftPaddingForContainer}>
                <Switch>
                  <Route
                    path='/design/landing-pages'
                    component={() => <LandingPages />}
                  />
                  <Route
                    path='/design/landing-page-builder'
                    component={
                      !isLandingPageBuilder
                        ? () => <Redirect to={'/upgrade-plan'} />
                        : LandingPageBuilder
                    }
                  />
                  <Route
                    path='/design/landing-page-preview'
                    component={() => <LandingPageBuilder />}
                  />
                  <Route
                    path='/design/overlay-urls'
                    component={WebsiteOverlays}
                  />
                  <Route
                    path='/design/calls-to-action'
                    component={
                      !isCTASEnabled
                        ? () => <Redirect to={'/upgrade-plan'} />
                        : CallsToAction
                    }
                  />
                  {showServicePages && (
                    <Route
                      path='/design/service-pages'
                      component={() => <LandingQuote />}
                    />
                  )}
                  <Route path='/design/thumbnails' component={Thumbnails} />
                  <Route
                    path='/design/qr-codes'
                    component={() => <QrCodes />}
                  />
                  <Route path='/design' exact>
                    <Redirect to='/design/landing-pages' />
                  </Route>
                  <Route path='*' component={NotFoundTemplate} />
                </Switch>
              </Container>
            </>
          }
        />
      </LandingPageBuilderContextProvider>
    </>
  );
};
