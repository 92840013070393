import { useMutation, useQueryClient } from 'react-query';
import { vdpQueryKeys } from './vdpQueryKeys';
import { EXPRESS_API } from 'configs/api/expressApi';

const updateOrderVdps = async (data: number[]) => {
  return (await EXPRESS_API.put(`vdp`, data)).data;
};

export const useUpdateOrderVdps = () => {
  const queryClient = useQueryClient();
  return useMutation(updateOrderVdps, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(vdpQueryKeys.all);
    },
  });
};
