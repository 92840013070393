import { theme } from 'lib/style';
import styled from 'styled-components/macro';

export const ModalTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #272a32;
`;

export const Card = styled.div`
  background: ${theme.palette.blue02};
  border: 1px solid ${theme.palette.gray20};
  border-radius: 7px;
  padding: 24px;
  height: fit-content;
`;

export const CardTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  color: ${theme.palette.covideoBlue100};
`;

export const MeetingDetailsBodyWrapper = styled.div`
  display: flex;
  gap: 136px;
  justify-content: center;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 384px;
`;

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const CopyItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CopyTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.neutral80};
`;

export const CopyLinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 42px;
`;

export const CopyValue = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${theme.palette.covideoBlue100};
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const Label = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  color: ${theme.palette.gray80};
`;

export const DetailsWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 592px;
`;

export const DurationWrapper = styled.span`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const Duration = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${theme.palette.neutral100};
  margin-left: 8px;
`;

export const LeftWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 100px;
`;

export const Textarea = styled.textarea`
  box-sizing: border-box;
  padding: 8px 12px;
  height: 80px;
  border-radius: 6px;
  width: 100%;
  border: solid 1px ${theme.palette.gray40};
  resize: vertical;
  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
    border: 1px solid ${theme.palette.covideoBlue100};
  }
`;
