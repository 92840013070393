import React from 'react';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { NewModal } from 'lib/components';
import { Button } from 'react-covideo-common';
import { updateMultipleCustomers } from 'lib/api';
import { Dropdown } from 'lib/components';
import { SuperAdminCustomer } from 'lib/context';
import { VerificationStatusOptions } from 'lib/const/VerificationsStatusOptions';
import { VerificationStatus } from 'lib/const/VerificationStatus';
import { errorToast } from 'lib/components/toasts/error';
import { successToast } from 'lib/components/toasts/success';

const filteredStatusOptions = VerificationStatusOptions.filter(
  option => option.value !== VerificationStatus.ALL
);

const Header = styled.div`
  display: flex;
`;

const Content = styled.div`
  width: 600px;
  margin-top: 24px;
`;

const SubmitButtonWrapper = styled.div`
  text-align: right;
  margin-top: 32px;
`;

type Props = {
  handleModalClose: (shouldRefresh?: boolean | number) => void;
  selectedCustomers: SuperAdminCustomer[];
};

type OptionType = {
  value: string;
  label: string;
};

export const ChangeCustomerStatusModal = ({
  handleModalClose,
  selectedCustomers,
}: Props) => {
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [customerStatus, setCustomerStatus] = useState(2);

  const headerText = `Change Status for ${selectedCustomers.length} customer${
    selectedCustomers.length > 1 ? 's' : ''
  }!`;

  const handleUpdateStatus = async () => {
    setLoadingModal(true);
    const count = selectedCustomers.length;
    let errorMessage = '';
    try {
      const customerIds = selectedCustomers.map(
        customer => customer.customerId
      );
      await updateMultipleCustomers({
        customerIds,
        status: customerStatus,
      });
    } catch (error) {
      errorMessage = `Error in updating customers status.`;
      console.log('Error', error);
    }
    handleModalClose(1);
    setLoadingModal(false);
    if (errorMessage) {
      errorToast({ title: errorMessage });
    } else {
      count &&
        successToast({
          title: `Status updated successfully for ${count} customer${
            count > 1 ? 's' : ''
          }!`,
        });
    }
    setLoadingModal(false);
  };

  return (
    <NewModal
      headerText={headerText}
      hideHeader={false}
      closeModalOnBackdropOrEscPress={true}
      closeModal={() => handleModalClose()}
      headerMargin='0 0 0 0'
      style={{
        content: {
          padding: '24px 24px 16px 24px',
          minWidth: 'auto',
          paddingLeft: '32px',
        },
      }}
    >
      <Header>
        <Content>You are about to {headerText}</Content>
      </Header>
      <Content>
        <Dropdown
          menuPortalTarget={document.body}
          menuPosition='fixed'
          menuPlacement={'bottom'}
          options={filteredStatusOptions}
          value={filteredStatusOptions.find(option => {
            return option && option.value === customerStatus;
          })}
          onChange={(option: OptionType) =>
            setCustomerStatus(parseInt(option.value, 10))
          }
          placeholder={'Select New Status'}
        />
        <SubmitButtonWrapper>
          <Button
            text={'Change Status'}
            onClick={() => handleUpdateStatus()}
            disabled={
              !(
                customerStatus === VerificationStatus.ACTIVE ||
                customerStatus === VerificationStatus.INACTIVE
              ) || loadingModal
            }
          />
        </SubmitButtonWrapper>
      </Content>
    </NewModal>
  );
};
