import {
  getAttribute,
  getAttributeValueDetailsMetric,
  getAttributeValueRecord,
} from 'lib/api';
import {
  LoadingIndicator,
  Table,
  TableContextProvider,
  TableFooter,
  TablePaginationNew,
  TablePaginationSizeNew,
} from 'lib/components';
import { BreadCrumbs } from 'lib/components/breadcrumbs/BreadCrumbs';
import { useAuth } from 'lib/context';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import { theme } from 'lib/style';
import { addThousandCommaSeparator } from 'lib/utils/functions';
import {
  loadFilteringData,
  SORTING,
  VIDEO_ATTRIBUTES,
} from 'lib/utils/videoAttributes';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import FilterStrip from './FilterStrip';
import Header from './Header';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { AccessRole, reportData } from 'lib/const';
import { MainWrapper } from 'lib/components/styles/layout';
import { NotFound } from 'app/pages/notFound/NotFound';
import dayjs from 'dayjs';

const TableCell = styled.div`
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
`;
const VideoStatusLegend = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const VideoStatusContainer = styled.div`
  margin-left: 20px;
  display: flex;
`;

const VideoStatusLabel = styled.div`
  margin-left: 4px;
  font-weight: 400;
`;

const VideoStatus = styled.div`
  border-radius: 5px;
  background-color: #f2f4f6;
  width: 24px;
  height: 20px;
  color: ${theme.palette.covideoBlue60};
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
`;

const VideoAttributeValueDetails = () => {
  const [videoAttribute, setVideoAttribute] = useState<any>({});
  const [valueData, setValueData] = useState<any>({});
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [loading, setLoading] = useState(false);
  // data
  const [data, setData] = useState<any>([]);
  const [departments, setDepartments] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const types = [
    { value: '', label: 'All Types' },
    { value: 'recorded', label: 'New Recorded' },
    { value: 'merged', label: 'Merged' },
    { value: 'duplicated', label: 'Duplicated' },
  ];
  // filtering
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [search, setSearch] = useState('');
  // sorting
  const [activeTotalViewsSort, setActiveTotalViewsSort] = useState('');
  const [activeCtaSort, setActiveCtaSort] = useState('');
  const report = reportData[VIDEO_ATTRIBUTES];

  const { userData } = useAuth();
  const isAdmin = !(userData.access.toString() === AccessRole.USER);
  const location = useLocation();

  const queryParams: any = queryString.parse(location.search);
  const [startDate, setStartDate] = useState<Date>(
    queryParams.start ? new Date(queryParams.start) : new Date()
  );
  const [endDate, setEndDate] = useState<Date>(
    queryParams.end ? new Date(queryParams.end) : new Date()
  );
  const [selectedUser, setSelectedUser] = useState(queryParams.user || '');
  const selectData = [
    {
      data: types,
      value: selectedType,
      queryParam: 'type',
      setValue: setSelectedType,
      onChange: (option: any) => {
        setPage(0);
        setSelectedType(option.value);
      },
    },
    ...(isAdmin
      ? [
          {
            data: departments,
            value: selectedDepartment,
            queryParam: 'department',
            setValue: setSelectedDepartment,
            onChange: (option: any) => {
              setPage(0);
              setSelectedDepartment(option.value);
            },
          },
          {
            data: users,
            value: selectedUser,
            queryParam: 'user',
            setValue: setSelectedUser,
            onChange: (option: any) => {
              setPage(0);
              setSelectedUser(option.value);
            },
          },
        ]
      : []),
  ];

  selectData.forEach(select => {
    queryParams[select.queryParam] = select.value;
  });

  const breadCrumbList = [
    {
      url: '/reports/data/all',
      title: 'All Data',
    },
    {
      url: '/reports/overview/video-attributes',
      title: 'Video Attributes',
    },
    {
      url: '/reports/overview/video-attributes/' + videoAttribute?.attributeId,
      title: videoAttribute?.title,
    },
    {
      url:
        '/reports/overview/video-attributes/' +
        videoAttribute?.attributeId +
        '/' +
        valueData.id,
      title: valueData.value,
    },
  ];

  const tableFields = [
    {
      value: 'title',
      label: 'Video Name',
      sortState: null,
      setSortState: null,
    },
    {
      value: 'firstName',
      label: 'Owner',
      sortState: null,
      setSortState: null,
    },
    {
      value: 'videoType',
      label: '',
      sortState: null,
      setSortState: null,
    },
    {
      value: 'totalViews',
      label: 'Total Views',
      sortState: activeTotalViewsSort,
      setSortState: setActiveTotalViewsSort,
    },
    {
      value: 'ctaClicks',
      label: 'Cta Clicks',
      sortState: activeCtaSort,
      setSortState: setActiveCtaSort,
    },
    {
      value: 'engagement',
      label: 'Eng. Rate',
      sortState: null,
      setSortState: null,
    },
  ];

  const loadData = async () => {
    setLoading(true);
    const locationArray = window.location.pathname.split('/');
    const valueId = decodeURI(locationArray.pop() || '');
    // fetch attribute value
    const valueData = await getAttributeValueRecord(valueId);
    if (valueData && valueData.length) setValueData(valueData[0]);
    else return;

    const id = locationArray.pop() || '';

    try {
      const attributeData = await getAttribute(Number(id));

      const sortString = tableFields
        .filter(item => item.sortState)
        .map(item => `${item.value} ${item.sortState}`)
        .join(',');

      const valuesData = await getAttributeValueDetailsMetric(
        id,
        valueData[0].value || '',
        {
          page,
          size,
          sort: sortString,
          startDate: dayjs(startDate).format('YYYY-MM-DD'),
          endDate: dayjs(endDate).format('YYYY-MM-DD'),
          user: selectedUser,
          department: selectedDepartment,
          search,
          videoType: selectedType,
        }
      );

      if (valuesData) {
        setData(valuesData?.data);
        setCount(valuesData?.count);
      }
      if (attributeData) setVideoAttribute(attributeData);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setVideoAttribute(null);
      setLoading(false);
    }
  };

  const onPaginationChange = ({ page, size }: any) => {
    setSize(size);
    setPage(page);
  };
  const onDateRangeChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const onSort = (item: any) => {
    item.setSortState(
      item.sortState === SORTING.ASC ? SORTING.DESC : SORTING.ASC
    );
  };

  const formatTableData = (dataField: any) => {
    return tableFields.map((item, index) => {
      const value = dataField[item.value];
      const isFirst = !index;
      const isSecond = index === 1;
      const isNumber = !isNaN(Number(value));
      const isEmpty = !value;
      const isVideoType = item.value === 'videoType';
      const isEngagement = item.value === 'engagement';
      const isUserField = item.value === 'firstName';

      let displayValue = value;
      let tableCellStyle = {};

      if (isEmpty) displayValue = '-';
      if (isNumber) {
        const numValue = Number(value).toFixed(2);
        displayValue = addThousandCommaSeparator(Number(numValue));
        tableCellStyle = { fontWeight: 600 };
      }

      if (isFirst) tableCellStyle = { textAlign: 'left', fontWeight: 600 };
      if (isSecond) tableCellStyle = { textAlign: 'left', fontWeight: 400 };

      if (isEngagement) displayValue += '%';

      if (isVideoType) {
        displayValue = (
          <VideoStatus>{value.charAt(0).toUpperCase()}</VideoStatus>
        );
      }
      if (isUserField)
        displayValue = dataField.firstName + ' ' + dataField.lastName;

      return <TableCell style={tableCellStyle}>{displayValue}</TableCell>;
    });
  };

  const formatTableHeaders = () => {
    return tableFields.map((item, index) => {
      const isFirst = !index;
      const isFirstOrSecond = isFirst || index === 1;

      const isSortable = !!item.setSortState;

      if (isSortable)
        return (
          <TableCell
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              outline: 'none !important',
              userSelect: 'none',
            }}
            onClick={() => onSort(item)}
          >
            <div style={{ marginRight: '10px' }}>{item.label}</div>
            <HiArrowNarrowUp
              color={
                item.sortState === SORTING.ASC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
              width={8}
              height={12}
            />
            <HiArrowNarrowDown
              color={
                item.sortState === SORTING.DESC
                  ? theme.palette.primaryDarkBlue
                  : theme.palette.gray60
              }
              width={8}
              height={12}
            />
          </TableCell>
        );

      return (
        <TableCell
          style={{
            ...(isFirstOrSecond && { textAlign: 'left' }),
          }}
        >
          {item.label}
        </TableCell>
      );
    });
  };

  useEffect(() => {
    loadFilteringData(userData.customerId, setDepartments, setUsers);
  }, []);

  useEffect(() => {
    loadData();
  }, [
    startDate,
    endDate,
    selectedDepartment,
    selectedUser,
    selectedType,
    search,
    page,
    size,
    activeTotalViewsSort,
    activeCtaSort,
  ]);

  if (!videoAttribute) {
    return <NotFound />;
  }

  return (
    <MainWrapper resetPadding={false}>
      <BreadCrumbs
        links={breadCrumbList}
        queryParams={queryString.stringify(queryParams)}
      />
      <Header
        onDateRangeChange={onDateRangeChange}
        title={'Video Attributes'}
        downloadData={{
          reportTitle: `video-attributes-${videoAttribute.title}-${valueData.value}.csv`,
          url: `${report.url}/download`,
          from: startDate,
          to: endDate,
          data: {
            user: selectedUser,
            department: selectedDepartment,
            search,
            sort: tableFields
              .filter(item => item.sortState)
              .map(item => `${item.value} ${item.sortState}`)
              .join(','),
            downloadType: 'VALUE_METRICS',
            videoAttributeId: videoAttribute.attributeId,
            value: valueData.value,
            limit: size,
            start: page,
            videoType: selectedType,
          },
        }}
        sendUrl={report.url}
      />
      <FilterStrip
        onSearch={(term: string) => setSearch(term)}
        selectData={selectData}
      />
      {loading ? (
        <LoadingIndicator
          isLoading={loading}
          height='300px'
          text='This might take a while...'
        />
      ) : data?.length ? (
        <TableContextProvider
          total={count}
          onChange={onPaginationChange}
          initPage={page}
          initSize={size}
        >
          <div style={{ marginTop: '20px' }}>
            <Table
              compact={true}
              hoverable={false}
              headers={[...formatTableHeaders()]}
              rows={data.map((dataField: any, index: number) => ({
                key: index,
                columns: [...formatTableData(dataField)],
              }))}
            ></Table>
          </div>
          <VideoStatusLegend>
            <VideoStatusContainer>
              <VideoStatus>N</VideoStatus>
              <VideoStatusLabel>Newly Recorded</VideoStatusLabel>
            </VideoStatusContainer>
            <VideoStatusContainer>
              <VideoStatus>D</VideoStatus>
              <VideoStatusLabel>Duplicated</VideoStatusLabel>
            </VideoStatusContainer>
            <VideoStatusContainer>
              <VideoStatus>M</VideoStatus>
              <VideoStatusLabel>Merged</VideoStatusLabel>
            </VideoStatusContainer>
          </VideoStatusLegend>
          <TableFooter>
            <TablePaginationNew />
            <TablePaginationSizeNew globalName='video_attributes_report_values' />
          </TableFooter>
        </TableContextProvider>
      ) : (
        <div style={{ textAlign: 'center', marginTop: '150px' }}>
          No data to show.
        </div>
      )}
    </MainWrapper>
  );
};

export default VideoAttributeValueDetails;
